import React, {useEffect, useContext} from 'react';
import {IConnectedSupplier} from '../../model/supplier/ConnectedSupplier';
import useConnectedSupplierAPI from '../../services/useConnectedSuppliersAPI';
import {useParams} from 'react-router-dom';
import {PaymentOutcomeStatus, PaymentProcessStatus} from '../../model/constants/Constants';
import PurchasingContext from '../../context/purchasing/purchasingContext';
import AppContext from '../../context/app/appContext';
import {ISupplierMarket} from '../../model/SupplierMarket';
import {AccountsReceivable} from '../AccountsReceivable/AccountsReceivable';

export const SupplierInvoice = () => {
  const pCtx = useContext(PurchasingContext);  
  const {supplierId} = useParams<{supplierId?: string; invoiceId?: string}>();  

  const {getConnectedSupplier} = useConnectedSupplierAPI();  
  const {marketplaceSupplier, setMarketplaceSupplier} = useContext(AppContext);

  useEffect(() => {
    if (!supplierId) {
      return;
    }

    let cancelled = false;

    const fetchSupplierData = (): Promise<IConnectedSupplier> => getConnectedSupplier(supplierId);

    if (
      pCtx.paymentProcessStatus === PaymentProcessStatus.NEW ||
      pCtx.paymentOutcome === PaymentOutcomeStatus.APPROVED
    ) {
      fetchSupplierData().then(connectedSupplier => {
        const updatedmpSupplier = {...marketplaceSupplier, ...connectedSupplier};
        if (!cancelled) {
          // save to context
          setMarketplaceSupplier(updatedmpSupplier as ISupplierMarket);
        }
      });
    }

    return () => {
      cancelled = true;
    };
  }, [pCtx.paymentOutcome]);

  return (
    <>
      <div className={`h-full relative overflow-hidden bg-spenda-newbg`}>
        <AccountsReceivable />
      </div>
    </>
  );
};
