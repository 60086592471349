import React from 'react';
import {Tab, TabPanel, Tabs, TabsBody, TabsHeader} from 'spenda-ui-react';

import {Layout} from '../../../components/layout/Layout';
import CustomerInfo from './customer-info-tab/CustomerInfo';
import UserIcon from '../../../assets/svg/service-management/UserIcon';

const ServiceDetailsDashboard = () => {
  const tabData = [
    {
      label: 'Customer info',
      value: 'customerInfo',
      icon: <UserIcon />,
      desc: <CustomerInfo />,
    },
  ];

  const _serviceDetails = (
    <Tabs value="customerInfo" orientation="vertical" className="h-full gap-2">
      <div className="h-[calc(100vh-120px)] min-w-[180px] bg-white">
        <TabsHeader
          className="w-full bg-transparent p-0"
          indicatorProps={{
            className: 'bg-primary/10 rounded-none',
          }}
        >
          {tabData.map(({label, value, icon}) => (
            <Tab key={value} value={value} className="justify-start p-4 text-left font-medium">
              <div className="flex items-center justify-start gap-4">
                {icon}
                {label}
              </div>
            </Tab>
          ))}
        </TabsHeader>
      </div>
      <TabsBody>
        {tabData.map(({value, desc}) => (
          <TabPanel key={value} value={value} className="p-0">
            <div className="flex h-[calc(100vh-120px)] flex-col items-start justify-between rounded bg-white p-2.5">
              {desc}
            </div>
          </TabPanel>
        ))}
      </TabsBody>
    </Tabs>
  );

  return (
    <div className={`relative h-full overflow-hidden bg-[rgba(211,229,239,0.5)]`}>
      <Layout leftPanel={_serviceDetails} splitWidthType={4} />
    </div>
  );
};

export default ServiceDetailsDashboard;
