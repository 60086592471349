import React from 'react';
import {Switch} from 'react-router-dom';
import {PrivateRoute} from './PrivateRoute';
import {MenuTypes, ModuleTypes} from '../model/constants/Constants';
import {AddCustomer} from '../screens/customers/AddCustomer';
import {AddCustomerClasses} from '../screens/customers/AddCustomerClasses';
import {AddAccountCustomer} from '../screens/customers/AddAccountCustomer';
import {CustomerClasses} from '../screens/customers/CustomerClasses';
import {AccountCustomers} from '../screens/customers/AccountCustomers';
import {OperationalCustomers} from '../screens/customers/OperationalCustomers';

export const AUTH_CUSTOMER_OPERATIONAL_CUSTOMERS_LIST = '/customers/operational';
export const AUTH_CUSTOMER_OPERATIONAL_CUSTOMERS_ADD = '/customers/operational/add';
export const AUTH_CUSTOMER_OPERATIONAL_CUSTOMERS_EDIT = '/customers/operational/edit/:customerID';
export const AUTH_CUSTOMER_ACCOUNT_CUSTOMERS_LIST = '/customers/account';
export const AUTH_CUSTOMER_CUSTOMERS_CLASSES_LIST = '/customers/classes';
export const AUTH_CUSTOMER_CUSTOMERS_CLASSES_ADD = '/customers/classes/add';
export const AUTH_CUSTOMER_CUSTOMERS_CLASSES_EDIT = '/customers/classes/edit/:classID';
export const AUTH_CUSTOMER_ACCOUNT_CUSTOMERS_EDIT = '/customers/account/edit/:customerID';

export const CustomerRoutes = () => {
  return (
    <Switch>
      <PrivateRoute
        path={AUTH_CUSTOMER_OPERATIONAL_CUSTOMERS_LIST}
        exact
        moduleIds={[ModuleTypes.CustomerManagement]}
        Component={OperationalCustomers}
        MenuFilters={{
          MenuType: MenuTypes.Selling,
          ModuleID: ModuleTypes.CustomerManagement,
          ModuleDependency: [ModuleTypes.CustomerManagement],
        }}
      />
      <PrivateRoute
        path={AUTH_CUSTOMER_ACCOUNT_CUSTOMERS_LIST}
        exact
        moduleIds={[ModuleTypes.CustomerManagement]}
        Component={AccountCustomers}
        MenuFilters={{
          MenuType: MenuTypes.Selling,
          ModuleID: ModuleTypes.CustomerManagement,
          ModuleDependency: [ModuleTypes.CustomerManagement],
        }}
      />
      <PrivateRoute
        path={AUTH_CUSTOMER_CUSTOMERS_CLASSES_LIST}
        exact
        moduleIds={[ModuleTypes.CustomerManagement]}
        Component={CustomerClasses}
        MenuFilters={{
          MenuType: MenuTypes.Selling,
          ModuleID: ModuleTypes.CustomerManagement,
          ModuleDependency: [ModuleTypes.CustomerManagement],
        }}
      />
      <PrivateRoute
        path={AUTH_CUSTOMER_OPERATIONAL_CUSTOMERS_ADD}
        exact
        moduleIds={[ModuleTypes.CustomerManagement]}
        Component={AddCustomer}
        MenuFilters={{
          MenuType: MenuTypes.Selling,
          ModuleID: ModuleTypes.CustomerManagement,
          ModuleDependency: [ModuleTypes.CustomerManagement],
        }}
      />
      <PrivateRoute
        path={AUTH_CUSTOMER_CUSTOMERS_CLASSES_ADD}
        exact
        moduleIds={[ModuleTypes.CustomerManagement]}
        Component={AddCustomerClasses}
        MenuFilters={{
          MenuType: MenuTypes.Selling,
          ModuleID: ModuleTypes.CustomerManagement,
          ModuleDependency: [ModuleTypes.CustomerManagement],
        }}
      />
      <PrivateRoute
        path={AUTH_CUSTOMER_ACCOUNT_CUSTOMERS_EDIT}
        exact
        moduleIds={[ModuleTypes.CustomerManagement]}
        Component={AddAccountCustomer}
        MenuFilters={{
          MenuType: MenuTypes.Selling,
          ModuleID: ModuleTypes.CustomerManagement,
          ModuleDependency: [ModuleTypes.CustomerManagement],
        }}
      />
      <PrivateRoute
        path={AUTH_CUSTOMER_OPERATIONAL_CUSTOMERS_EDIT}
        exact
        moduleIds={[ModuleTypes.CustomerManagement]}
        Component={AddCustomer}
        MenuFilters={{
          MenuType: MenuTypes.Selling,
          ModuleID: ModuleTypes.CustomerManagement,
          ModuleDependency: [ModuleTypes.CustomerManagement],
        }}
      />
      <PrivateRoute
        path={AUTH_CUSTOMER_CUSTOMERS_CLASSES_EDIT}
        exact
        moduleIds={[ModuleTypes.CustomerManagement]}
        Component={AddCustomerClasses}
        MenuFilters={{
          MenuType: MenuTypes.Selling,
          ModuleID: ModuleTypes.CustomerManagement,
          ModuleDependency: [ModuleTypes.CustomerManagement],
        }}
      />
    </Switch>
  );
};
