import React, {useState, useEffect} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {css} from 'glamor';
import moment from 'moment';
import {PurchaseInvoiceListFilter} from '../../components/purchasing/PurchaseInvoiceListFilter';
import {IPurchaseInvoice, ISupplier} from '../../model/purchase-invoice/purchaseInvoice';
import {
  PurchaseInvoiceRequestedList,
  PurchaseInvoiceViewTypes,
  SupplierViewSortingTypes,
  TimelineCustomRange,
} from '../../model/constants/Constants';
import {getMessage, SelectToggler, SupplierStatement} from './CommonComponents';
import {APInvoiceDetailView} from './APInvoiceDetailView';
import {PriceFormat} from '../../utils/formatter';

import supplierViewDropdown from '../../assets/svg/accounts-payable/supplierViewDropdown.svg';

import {
  makeStyles,
  Collapse,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  ClickAwayListener,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import APDisableSelectTogglerIcon from '../../assets/svg/accounts-payable/APDisableSelectTogglerIcon';
import APTooltip from '../../components/data-display/APTootip';
import SelectedBillsDetails from '../../assets/svg/accounts-payable/selectedBillsDetails';
import ARTooltip from '../../components/data-display/ARTootip';
import IconScheduledPayments from '../../assets/svg/IconScheduledPayments';

const useAPInvoiceSupplierViewList = makeStyles({
  supplierInvoicesWrap: {
    padding: '0px',
    borderBottom: '1px solid #f1f1f1',
  },
  supplierListcard: {
    '& .invoice-no': {
      fontSize: '12px',
    },
    '& .total-container': {
      width: '230px',
      margin: '0px',
      padding: '0px',
      '& .supplier-name': {
        fontSize: '16px',
        width: 'auto',
        '&::after': {
          display: 'none',
        },
      },
    },
    '& .overdue-container': {
      background: '#F8E1DC',
      borderRadius: '6px',
      width: 'auto',
      padding: '9px 20px',
      border: '0px',
      '& p': {
        color: '#C55D44',
        '&:first-child': {
          fontSize: '10px!important',
        },
        '&:last-child': {
          fontSize: '14px',
          fontWeight: 700,
        },
      },
    },
    '& .due-container': {
      background: '#D3E5EF50',
      borderRadius: '6px',
      width: 'auto',
      padding: '9px 20px',
      border: '0px',
      '& p': {
        color: '#1C78AD',
        '&:first-child': {
          fontSize: '10px!important',
        },
        '&:last-child': {
          fontSize: '14px',
          fontWeight: 700,
        },
      },
    },
  },
  supplierListCol: {
    minWidth: '240px',
    maxWidth: '330px',
    paddingRight: '20px',
    '& .supplier-name': {
      fontSize: '16px',
      width: '100%',
      '& span': {
        display: 'inline-block',
        '& img': {
          width: '18px',
          height: '16px',
          position: 'relative',
          top: '2px',
        },
      },
    },
    '& .selectedInvoicesNumber': {
      color: '#3C9F78!important',
      fontSize: '10px',
      fontWeight: 500,
      fontFamily: 'poppins',
      lineHeight: '16px',
    },
    '& .alreadyExistError': {
      color: '#C55D44',
      fontSize: '10px',
    },
  },
  duePara: {
    fontSize: '12px!important',
  },
  expandArrow: {
    minWidth: '20px',
    height: '20px',
    border: '1px solid #1579af',
    borderRadius: '3px',
    margin: '0px 10px',
    '& img': {
      width: '9px',
      transform: 'rotate(180deg)',
    },
    '& img.rotate-expand-more-icon': {
      transform: 'rotate(0deg)',
    },
  },
});

const supplierListBreakdowntable = css({
  borderBottom: '1px solid #f1f1f1',
  '& table': {
    borderBottom: '2px solid #F8F8F8',
    '& th': {
      color: '#999999',
      fontWeight: 600,
      fontSize: '14px',
      fontFamily: 'Poppins',
      width: '180px',

      '& .MuiTableSortLabel-root': {
        paddingRight: '15px',
        '& .MuiTableSortLabel-icon': {
          margin: '0px -7px 0px 0px',
          position: 'absolute',
          right: '0',
          transform: 'rotate(0deg)',
          opacity: '1',
          fontSize: '20px',
          '&.MuiTableSortLabel-iconDirectionAsc': {
            color: '#999',
          },
          '&.MuiTableSortLabel-iconDirectionDesc': {
            color: '#999',
          },
        },
        '&.MuiTableSortLabel-active': {
          color: '#999999',
          '& .MuiTableSortLabel-iconDirectionDesc': {
            color: '#9e9e9e',
          },
          '& .MuiTableSortLabel-iconDirectionAsc': {
            color: '#1c78ad!important',
          },
        },
      },
    },
    '& .MuiTableHead-root': {
      background: '#F1F1F1',
    },
    '& .MuiTableCell-root': {
      borderBottom: '1px solid #F1F1F1',
      borderLeft: '0px',
      padding: '10px',
    },
    '& .MuiTableCell-body': {
      padding: '10px',
      color: '#333333',
      fontWeight: 500,
      fontFamily: 'poppins',
      fontSize: '14px',
      '&:nth-child(odd)': {
        background: '#FAFAFA',
      },
      '& .select-symbol-icon': {
        margin: '0 auto',
      },
      '&.due-by-date span': {
        color: '#C55D44!important',
        fontWeight: 600,
        fontSize: '14px',
      },
      '&.overdue-by-date span': {
        color: '#1579af!important',
        fontWeight: 600,
        fontSize: '14px',
      },
      '&.invoice-total': {
        color: '#999999!important',
      },
    },
    '& .invoiceDisable': {
      '& td': {
        color: '#333',
        opacity: '30%',
        '&:first-child': {
          opacity: '100%',
        },
      },
    },
  },
});
interface APInvoiceSupplierViewListProps {
  totalPayable: number;
  onSelectAll: (isAllSuppliersSelected: boolean) => void;
  toggleSupplierViewMode: (type: PurchaseInvoiceViewTypes) => void;
  supplierViewMode: PurchaseInvoiceViewTypes;
  purchaseInvoiceViewMode: PurchaseInvoiceViewTypes;
  togglePurchaseInvoiceViewMode: (type: PurchaseInvoiceViewTypes) => void;
  selectedBucket: PurchaseInvoiceRequestedList;
  suppliersList: Array<ISupplier>;
  isSearching?: boolean;
  handleBulkInvoices: (supplierId: number) => void;
  selectedInvoices: any;
  getPurchaseInvoicesList: (SupplierID: number) => void;
  handleInvoices: (invoicesId: number, supplierId: number) => void;
  handleDebitNotes: (debitNotesId: number, supplierId: number) => void;
  toggleTimelineViewMode: (type: PurchaseInvoiceViewTypes) => void;
  timelineViewMode: PurchaseInvoiceViewTypes;
  timeLineCustomRangeMode?: TimelineCustomRange;
  toggletimeLineCustomRangeMode: (type: TimelineCustomRange) => void;
  SupplierSortingViewMode: SupplierViewSortingTypes;
  toggleSupplierViewSortingMode: (type: SupplierViewSortingTypes) => void;
  isSavingBatch?: boolean;
  setIsSavingBatch: React.Dispatch<React.SetStateAction<boolean>>;
  isT2TPhaseTwo?: boolean;
  isScheduledPaymentsV2?: boolean;
  t2TV3?: boolean;
}

export interface IAPInvoiceId {
  transId: number;
  supplierId: number;
}

const dateFormater = (date: string) => moment(date).format('Do MMM YYYY');

const listContainer = css({
  maxHeight: 'calc( 100vh - 20.5em )',
  borderRadius: '6px',
  boxShadow: '0px 0px 6px 0px #D3E5EF',
});

const width = css({
  width: '180px',
  '@media(max-width: 1100px)': {width: '100px'},
});

export const APInvoiceSupplierViewList = (props: APInvoiceSupplierViewListProps) => {
  const {
    totalPayable,
    onSelectAll,
    selectedInvoices,
    supplierViewMode,
    purchaseInvoiceViewMode,
    toggleSupplierViewMode,
    togglePurchaseInvoiceViewMode,
    selectedBucket,
    suppliersList,
    isSearching,
    handleBulkInvoices,
    getPurchaseInvoicesList,
    handleInvoices,
    handleDebitNotes,
    timelineViewMode,
    toggleTimelineViewMode,
    timeLineCustomRangeMode,
    toggletimeLineCustomRangeMode,
    toggleSupplierViewSortingMode,
    SupplierSortingViewMode,
    isSavingBatch,
    setIsSavingBatch,
    isT2TPhaseTwo,
    t2TV3,
  } = props;

  const classes = useAPInvoiceSupplierViewList();

  const [isAllSuppliersSelected, setIsAllSuppliersSelected] = useState<boolean>(false);
  const [breakDownSupplierId, setBreakSupplierId] = useState<Number | undefined>();
  const [showInvoice, setShowInvoice] = useState<boolean>(false);
  const [invoiceId, setInvoiceId] = useState<IAPInvoiceId>();

  let {transactionId} = useParams<{transactionId: string}>();
  const history = useHistory();
  const [showConnectedSupplierTooltipId, setShowConnectedSupplierTooltipId] = useState<number>();

  useEffect(() => {
    let ids: number[] = [];
    if (selectedInvoices) {
      Object.keys(selectedInvoices).forEach(key => {
        if (Array.isArray(selectedInvoices[key]?.purchaseInvoiceIds)) {
          ids = [...ids, ...selectedInvoices[key].purchaseInvoiceIds];
        }
      });
    }
  }, [selectedInvoices]);

  useEffect(() => {
    setBreakSupplierId(undefined);
  }, [selectedBucket]);

  useEffect(() => {
    Object.keys(selectedInvoices).forEach(supplierId => {
      const selectedSupplier: any = selectedInvoices[supplierId];

      if (!selectedSupplier) {
        return;
      }
    });

    setIsAllSuppliersSelected(getIsAllSuppliersSelected());
  }, [selectedInvoices, suppliersList]);

  const getIsAllSuppliersSelected = () => {
    if (!suppliersList.length) {
      return false;
    }

    if (Object.keys(selectedInvoices).length < suppliersList.length) {
      return false;
    }

    return !Boolean(Object.keys(selectedInvoices).find(key => !selectedInvoices[key].allSelected));
  };
  if (!suppliersList.length || (isSearching && !suppliersList.length)) {
    return (
      <div
        className={`${listContainer} invoice-list-col flex min-h-full flex-col overflow-y-auto rounded`}
        id="purchase-invoice-list"
      >
        <p className="no-result-found font-poppins">
          {isSearching
            ? 'LOADING...'
            : selectedBucket === PurchaseInvoiceRequestedList.Search
              ? `NO RESULTS FOUND.`
              : `${getMessage(selectedBucket)}`}
        </p>
      </div>
    );
  }

  const isOverDueList = selectedBucket === PurchaseInvoiceRequestedList.Overdue;
  const isPayableList = selectedBucket === PurchaseInvoiceRequestedList.Payable;
  return (
    <>
      {showInvoice && (
        <APInvoiceDetailView open={showInvoice} onClose={() => setShowInvoice(false)} apInvoiceId={invoiceId} />
      )}
      <div
        className={`${listContainer} flex min-h-full flex-col overflow-y-auto !p-2.5 font-poppins`}
        id="purchase-invoice-list"
      >
        <PurchaseInvoiceListFilter
          selectedInvoiceTotal={totalPayable}
          onSelectAll={onSelectAll}
          isAllSuppliersSelected={isAllSuppliersSelected}
          purchaseInvoiceViewMode={purchaseInvoiceViewMode}
          supplierViewMode={supplierViewMode}
          togglePurchaseInvoiceViewMode={togglePurchaseInvoiceViewMode}
          toggleSupplierViewMode={toggleSupplierViewMode}
          selectedBucket={selectedBucket}
          toggleTimelineViewMode={toggleTimelineViewMode}
          timelineViewMode={timelineViewMode}
          timeLineCustomRangeMode={timeLineCustomRangeMode}
          toggletimeLineCustomRangeMode={toggletimeLineCustomRangeMode}
          toggleSupplierViewSortingMode={toggleSupplierViewSortingMode}
          SupplierSortingViewMode={SupplierSortingViewMode}
          isSavingBatch={isSavingBatch}
          setIsSavingBatch={setIsSavingBatch}
          t2TV3={t2TV3}
        />

        {suppliersList?.map((supplier: ISupplier) => {
          const totalBill = isOverDueList
            ? isT2TPhaseTwo
              ? supplier.TotalIncOverdueInvoices - (supplier.TotalIncDebitNotes || 0)
              : supplier.TotalIncOverdueInvoices
            : isPayableList
              ? isT2TPhaseTwo
                ? supplier.TotalIncOverdueInvoices +
                  supplier.TotalIncDueSoonInvoices -
                  (supplier.TotalIncDebitNotes || 0)
                : supplier.TotalIncOverdueInvoices + supplier.TotalIncDueSoonInvoices
              : isT2TPhaseTwo
                ? supplier.TotalIncDueSoonInvoices - (supplier.TotalIncDebitNotes || 0)
                : supplier.TotalIncDueSoonInvoices;
          const outstandingAmount = isOverDueList
            ? isT2TPhaseTwo
              ? supplier.ValueOverdueInvoices - (supplier.ValueDebitNotes || 0)
              : supplier.ValueOverdueInvoices
            : isPayableList
              ? isT2TPhaseTwo
                ? supplier.ValueOverdueInvoices + supplier.ValueDueSoonInvoices - (supplier.ValueDebitNotes || 0)
                : supplier.ValueOverdueInvoices - (supplier.ValueDebitNotes || 0) + supplier.ValueDueSoonInvoices
              : isT2TPhaseTwo
                ? supplier.ValueDueSoonInvoices
                : supplier.ValueDueSoonInvoices;
          return (
            <div key={supplier.SupplierID}>
              <div className="flex w-full justify-between ">
                <div className={`section-purchaseInvoices flex w-full ${classes.supplierInvoicesWrap}`}>
                  <div className={`flex min-w-[36px] items-center justify-center bg-spenda-cream`}>
                    <div
                      data-autoid={`chkSupplierList-${supplier.SupplierID}`}
                      onClick={() => {
                        if (!isSavingBatch) {
                          setIsSavingBatch(true);
                          getPurchaseInvoicesList(supplier.SupplierID);
                          setBreakSupplierId(supplier.SupplierID);
                          handleBulkInvoices(supplier.SupplierID);
                        }
                      }}
                    >
                      <SelectToggler
                        isSelected={
                          selectedInvoices?.[supplier.SupplierID.toString()]?.purchaseInvoiceIds?.length ===
                          supplier?.PurchaseInvoicesID?.length
                        }
                        isAccountsPayable={true}
                        dataAutoId={`imgSelectToggler-${supplier.SupplierID}`}
                      />
                    </div>
                  </div>
                  <div
                    className={`invoice-list-card flex w-full items-center justify-between py-2.5 ${classes.supplierListcard}`}
                  >
                    <span
                      onClick={() => {
                        getPurchaseInvoicesList(supplier.SupplierID);
                        setBreakSupplierId(
                          supplier.SupplierID === breakDownSupplierId ? undefined : supplier.SupplierID,
                        );
                      }}
                      data-autoid={`ExpandArrow-${supplier.SupplierID}`}
                      className={`flex cursor-pointer items-center justify-center ${classes.expandArrow}`}
                    >
                      <img
                        src={supplierViewDropdown}
                        className={`w-full ${
                          Boolean(breakDownSupplierId === supplier.SupplierID)
                            ? 'rotate-expand-more-icon'
                            : 'rotate-expand-less-icon'
                        }`}
                        data-autoid={`imgExpandArrow-${supplier.SupplierID}`}
                      />
                    </span>
                    <div className={classes.supplierListCol}>
                      <p className="supplier-name flex">
                        <span className="mr-1"></span>
                        {supplier.SupplierName}
                        {isT2TPhaseTwo && supplier?.IsLinked && (
                          <div className="mt-1">
                            <ClickAwayListener
                              onClickAway={e => {
                                e.preventDefault();
                                e.stopPropagation();
                                setShowConnectedSupplierTooltipId(undefined);
                              }}
                            >
                              <APTooltip
                                id={'APBillsTooltip'}
                                arrow
                                interactive
                                open={
                                  showConnectedSupplierTooltipId !== undefined &&
                                  supplier.SupplierID === showConnectedSupplierTooltipId
                                }
                                placement="top-start"
                                title={
                                  <React.Fragment>
                                    <p className="w-[220px] px-3 text-center  font-poppins text-[10px] font-medium text-black-800">
                                      To pay this supplier blocked invoices please visit:
                                      <span
                                        className="cursor-pointer text-primary"
                                        onClick={() => {
                                          supplier.MarketPlacePath !== ''
                                            ? history.replace(`/${supplier.MarketPlacePath}?redirectedFromAPBills=true`)
                                            : null;
                                        }}
                                      >
                                        {' '}
                                        {supplier?.SupplierName}{' '}
                                      </span>
                                    </p>
                                  </React.Fragment>
                                }
                              >
                                <div
                                  onClick={e => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    e.nativeEvent.stopImmediatePropagation();
                                    setShowConnectedSupplierTooltipId(supplier.SupplierID);
                                  }}
                                >
                                  <SelectedBillsDetails className="ml-2 cursor-pointer" />
                                </div>
                              </APTooltip>
                            </ClickAwayListener>
                          </div>
                        )}
                      </p>

                      <p className="invoice-no">
                        No. of Purchase Invoice:{' '}
                        {selectedBucket === PurchaseInvoiceRequestedList.Overdue ? supplier.NoOverdueInvoices : null}
                        {selectedBucket === PurchaseInvoiceRequestedList.Due ? supplier.NoDueSoonInvoices : null}
                        {selectedBucket === PurchaseInvoiceRequestedList.Payable
                          ? supplier.NoOverdueInvoices + supplier.NoDueSoonInvoices
                          : null}
                      </p>
                      <p className="invoice-no">
                        Overdue Invoices:{' '}
                        {selectedBucket === PurchaseInvoiceRequestedList.Overdue
                          ? `${
                              (supplier.NoOverdueInvoices / (supplier.NoDueSoonInvoices + supplier.NoOverdueInvoices)) *
                              100
                            }%`
                          : null}
                        {selectedBucket === PurchaseInvoiceRequestedList.Due
                          ? `${
                              (supplier.NoOverdueInvoices / (supplier.NoDueSoonInvoices + supplier.NoOverdueInvoices)) *
                              100
                            }%`
                          : null}
                        {selectedBucket === PurchaseInvoiceRequestedList.Payable
                          ? `${
                              (supplier.NoOverdueInvoices / (supplier.NoDueSoonInvoices + supplier.NoOverdueInvoices)) *
                              100
                            }%`
                          : null}
                      </p>
                      <p style={{lineHeight: '10px'}}>
                        {selectedInvoices?.[supplier.SupplierID.toString()]?.purchaseInvoiceIds?.length ? (
                          <span className="selectedInvoicesNumber">
                            {selectedInvoices?.[supplier.SupplierID.toString()]?.purchaseInvoiceIds?.length} Invoice
                            selected
                          </span>
                        ) : null}
                      </p>
                    </div>
                    <SupplierStatement
                      isOverDueList={isOverDueList}
                      totalBill={totalBill}
                      outstandingAmount={outstandingAmount}
                      APSupplierView={true}
                    />
                    <div
                      className={`total-container ${width} ${
                        supplier.NoOverdueInvoices ? 'overdue-container' : 'due-container'
                      }`}
                    >
                      {supplier.NoOverdueInvoices ? (
                        <p className={classes.duePara}>Oldest Due on {dateFormater(supplier.OldestInvoiceDate)}</p>
                      ) : (
                        <p className={classes.duePara}>
                          Closest Due on {dateFormater(supplier.OldestDueSoonInvoiceDate)}
                        </p>
                      )}
                      <p>
                        {supplier.NoOverdueInvoices
                          ? `Overdue by ${supplier.OldestInvoiceDays} Days`
                          : supplier.OldestDueSoonInvoiceDays === 0
                            ? `Due Today`
                            : `Due in - ${supplier.OldestDueSoonInvoiceDays} Days`}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <SupplierInvoiceBreakDown
                open={Boolean(breakDownSupplierId === supplier.SupplierID)}
                invoices={supplier.PurchaseInvoices}
                debitNotes={supplier.DebitNotes}
                selectedInvoices={
                  selectedInvoices?.[supplier.SupplierID.toString()]?.purchaseInvoiceIds
                    ? selectedInvoices[supplier.SupplierID.toString()]?.purchaseInvoiceIds
                    : []
                }
                selectedDebitNotes={
                  selectedInvoices?.[supplier.SupplierID.toString()]?.debitNotesIds
                    ? selectedInvoices[supplier.SupplierID.toString()]?.debitNotesIds
                    : []
                }
                handleInvoices={invoiceIds => {
                  if (!isSavingBatch) {
                    setIsSavingBatch(true);
                    handleInvoices(invoiceIds, supplier.SupplierID);
                  }
                }}
                handleDebitNotes={debitNoteIds => {
                  if (!isSavingBatch) {
                    setIsSavingBatch(true);
                    handleDebitNotes(debitNoteIds, supplier.SupplierID);
                  }
                }}
                transactionId={transactionId}
                supplierId={supplier.SupplierID}
                selectedBucket={selectedBucket}
                setShowInvoice={setShowInvoice}
                setInvoiceId={setInvoiceId}
                isT2TPhaseTwo={isT2TPhaseTwo}
                t2TV3={t2TV3}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};
interface ISupplierBreakDownProps {
  open: boolean;
  invoices: Array<IPurchaseInvoice> | undefined;
  debitNotes: Array<IPurchaseInvoice> | undefined;
  selectedInvoices: Array<Number>;
  selectedDebitNotes: Array<Number>;
  handleInvoices: (invoiceIds: number) => void;
  handleDebitNotes: (debitNoteIds: number) => void;
  transactionId: string | undefined;
  supplierId: number;
  selectedBucket: PurchaseInvoiceRequestedList;
  setShowInvoice: (x: boolean) => void;
  setInvoiceId: (x: IAPInvoiceId) => void;
  isT2TPhaseTwo?: boolean;
  isScheduledPaymentsV2?: boolean;
  t2TV3?: boolean;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<T>(order: Order, orderBy: keyof T) {
  return order === 'desc'
    ? (a: T, b: T) => descendingComparator(a, b, orderBy)
    : (a: T, b: T) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array?.map((el, index) => [el, index] as [T, number]);
  stabilizedThis &&
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
  return stabilizedThis.map(el => el[0]);
}

const SupplierInvoiceBreakDown = (props: ISupplierBreakDownProps) => {
  const {
    open,
    invoices,
    selectedInvoices,
    handleInvoices,
    handleDebitNotes,
    transactionId,
    selectedBucket,
    setShowInvoice,
    setInvoiceId,
    debitNotes,
    isT2TPhaseTwo,
    isScheduledPaymentsV2,
    t2TV3,
    selectedDebitNotes,
  } = props;
  const isOverDue: boolean = Boolean(selectedBucket === PurchaseInvoiceRequestedList.Overdue);

  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof IPurchaseInvoice>('SupplierName');

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof IPurchaseInvoice) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const createSortHandler = (property: keyof IPurchaseInvoice) => (event: React.MouseEvent<unknown>) => {
    handleRequestSort(event, property);
  };

  return (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <TableContainer className={`${supplierListBreakdowntable} py-5 pl-[46px]`}>
        <Table className="invoiceTableCell">
          <TableHead>
            <TableRow>
              <TableCell align="left" style={{width: '4.5%'}}></TableCell>
              <TableCell align="left" style={{width: '21%'}}>
                Purchase Invoice
              </TableCell>
              <TableCell align="left" style={{width: '20%'}}>
                No. Of Line Items
              </TableCell>
              <TableCell align="left" style={{width: '19%'}}>
                <TableSortLabel
                  IconComponent={ArrowDropDownIcon}
                  active={orderBy === 'DueDate'}
                  direction={orderBy === 'DueDate' ? order : 'asc'}
                  onClick={createSortHandler('DueDate')}
                >
                  {isOverDue ? 'OverDue Date' : 'Due Date'}
                </TableSortLabel>
              </TableCell>
              <TableCell align="right" style={{width: '16%'}}>
                <TableSortLabel
                  IconComponent={ArrowDropDownIcon}
                  active={orderBy === 'TransID'}
                  direction={orderBy === 'TransID' ? order : 'asc'}
                  onClick={createSortHandler('TransID')}
                >
                  Invoice Total
                </TableSortLabel>
              </TableCell>
              <TableCell align="right" style={{width: '19%'}}>
                <TableSortLabel
                  IconComponent={ArrowDropDownIcon}
                  active={orderBy === 'TransID'}
                  direction={orderBy === 'TransID' ? order : 'asc'}
                  onClick={createSortHandler('TransID')}
                >
                  Outstanding
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(invoices) &&
              stableSort<IPurchaseInvoice>(invoices, getComparator<IPurchaseInvoice>(order, orderBy)).map(
                (inv: IPurchaseInvoice) => (
                  <TableRow
                    key={inv.TransID}
                    className={`${Boolean(parseInt(transactionId!) === inv.TransID) ? '' : ''} ${
                      inv.LinkedDocumentGUID !== '' && inv.LinkedDocumentGUID !== null && isT2TPhaseTwo && !t2TV3
                        ? `invoiceDisable`
                        : ''
                    }`}
                  >
                    <TableCell
                      align="center"
                      onClick={() => handleInvoices(inv.TransID)}
                      style={{background: '#fff'}}
                      data-autoid={`chkInvoiceList-${inv.TransID}`}
                    >
                      {inv.LinkedDocumentGUID !== '' && inv.LinkedDocumentGUID !== null && isT2TPhaseTwo && !t2TV3 ? (
                        <APDisableSelectTogglerIcon className="min-w-[22px]" />
                      ) : (
                        <SelectToggler
                          isSelected={selectedInvoices.includes(inv.TransID)}
                          isAccountsPayable={true}
                          dataAutoId={`imgInvoiceList-${inv.TransID}`}
                        />
                      )}
                    </TableCell>
                    <TableCell
                      align="left"
                      className="cursor-pointer !font-semibold !text-primary underline"
                      onClick={() => {
                        if (inv.LinkedDocumentGUID !== '' && inv.LinkedDocumentGUID !== null && isT2TPhaseTwo && !t2TV3)
                          return;
                        setInvoiceId({
                          transId: inv.TransID,
                          supplierId: inv.SupplierID,
                        });
                        setShowInvoice(true);
                      }}
                      data-autoid={`lnkRefNumber-${inv.TransID}`}
                    >
                      <div className="flex flex-row items-center justify-between">
                        <>
                          {`${inv.RefNumber}${
                            inv.LinkedDocumentGUID &&
                            inv.LinkedDocumentGUID !== '' &&
                            inv.SupplierName !== inv.SourceSupplierName &&
                            inv?.SupplierName?.trim()?.length > 1
                              ? '(' + inv.SupplierName + ')'
                              : ''
                          }`}
                        </>
                        {isScheduledPaymentsV2 && false && (
                          <ARTooltip
                            arrow
                            title={
                              <React.Fragment>
                                <h4 className="text-center font-poppins text-[10px] font-medium text-spenda-primarytext">
                                  Payment Scheduled
                                </h4>
                                <ul className="mt-3">
                                  <li className="flex items-center justify-start gap-2 pb-1">
                                    <p className="whitespace-nowrap font-poppins text-[10px] font-medium text-[#B2B2B2]">
                                      Payment Date:
                                    </p>
                                    <p
                                      data-autoid={`lblDateScheduledPayment${inv.TransID}APInvoiceSupplierViewList`}
                                      className="font-poppins text-[10px] font-semibold text-black-800"
                                    >
                                      12/10/12
                                    </p>
                                    <p
                                      data-autoid={`lblAmountScheduledPayment${inv.TransID}APInvoiceSupplierViewList`}
                                      className="font-poppins text-[10px] font-semibold text-black-800"
                                    >
                                      $10,000
                                    </p>
                                  </li>
                                </ul>
                              </React.Fragment>
                            }
                          >
                            <button className="ml-2 flex h-[42px] w-9 cursor-pointer items-center justify-center bg-[#e6e4f5]">
                              <IconScheduledPayments />
                            </button>
                          </ARTooltip>
                        )}
                      </div>
                    </TableCell>
                    <TableCell align="left" data-autoid={`lnkNoLines-${inv.TransID}`}>
                      {inv.NoLines}
                    </TableCell>
                    <TableCell
                      align="left"
                      className={`${isOverDue ? 'overdue-by-date' : 'due-by-date'}`}
                      data-autoid={`lnkDueDate-${inv.TransID}`}
                    >
                      <span>{dateFormater(inv.DueDate)}</span>
                    </TableCell>
                    <TableCell align="right" className="invoice-total" data-autoid={`lnkTotalInc-${inv.TransID}`}>
                      {PriceFormat(inv.TotalInc)}
                    </TableCell>
                    <TableCell align="right" data-autoid={`lnkBalance-${inv.TransID}`}>
                      {PriceFormat(inv.Balance)}
                    </TableCell>
                  </TableRow>
                ),
              )}
            {isT2TPhaseTwo &&
              Array.isArray(debitNotes) &&
              stableSort<IPurchaseInvoice>(debitNotes, getComparator<IPurchaseInvoice>(order, orderBy)).map(
                (debitNote: IPurchaseInvoice) => (
                  <TableRow
                    key={debitNote.TransID}
                    className={
                      debitNote.LinkedDocumentGUID !== '' &&
                      debitNote.LinkedDocumentGUID !== null &&
                      isT2TPhaseTwo &&
                      !t2TV3
                        ? `invoiceDisable`
                        : ''
                    }
                  >
                    <TableCell
                      align="center"
                      style={{background: '#fff'}}
                      onClick={() => t2TV3 && handleDebitNotes(debitNote.TransID)}
                      data-autoid={`chkInvoiceList-${debitNote.TransID}`}
                    >
                      {debitNote.LinkedDocumentGUID !== '' &&
                      debitNote.LinkedDocumentGUID !== null &&
                      isT2TPhaseTwo &&
                      !t2TV3 ? (
                        <APDisableSelectTogglerIcon className="min-w-[22px]" />
                      ) : (
                        <SelectToggler
                          isSelected={selectedDebitNotes.includes(debitNote.TransID)}
                          isAccountsPayable={true}
                          dataAutoId={`imgInvoiceList-${debitNote.TransID}`}
                        />
                      )}
                    </TableCell>
                    <TableCell align="left" data-autoid={`lnkRefNumber-${debitNote.TransID}`}>
                      {`${debitNote.RefNumber}${
                        debitNote.LinkedDocumentGUID && debitNote.LinkedDocumentGUID !== '' ? '(DebitNote)' : ''
                      }`}
                    </TableCell>
                    <TableCell align="left" data-autoid={`lnkNoLines-${debitNote.TransID}`}>
                      {debitNote.NoLines}
                    </TableCell>
                    <TableCell
                      align="left"
                      className={`${isOverDue ? 'overdue-by-date' : 'due-by-date'}`}
                      data-autoid={`lnkDueDate-${debitNote.TransID}`}
                    >
                      <span>{dateFormater(debitNote.DueDate)}</span>
                    </TableCell>
                    <TableCell align="right" className="invoice-total" data-autoid={`lnkTotalInc-${debitNote.TransID}`}>
                      -{PriceFormat(debitNote.TotalInc)}
                    </TableCell>
                    <TableCell align="right" data-autoid={`lnkBalance-${debitNote.TransID}`}>
                      -{PriceFormat(debitNote.Balance)}
                    </TableCell>
                  </TableRow>
                ),
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </Collapse>
  );
};
