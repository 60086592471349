import React, {useEffect, useState} from 'react';
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography,
  Divider,
  Tooltip,
  Fade,
  TooltipProps,
  IconButton,
} from '@material-ui/core';
import {SDialog} from '../modals/modalSpendaMeterialUI';
import {SButton} from '../buttons/Button';
import {SelectToggler} from '../../screens/purchasing/CommonComponents';
import {css} from 'glamor';
import {PriceFormat} from '../../utils/formatter';
import moment from 'moment';
import {CreateClaimDialog} from './CreateClaimDialog';
import {SelectedCreditNotes} from '../../model/credit-notes/SelectedCreditNotes';
import EditIcon from '../../assets/png/edit-icon.png';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import LoadingIndicator from '../ui/LoadingIndicator';
import {PaymentWidgetPaymentSteps} from '../../screens/pay-by-link/payment-widget/PaymentWidget';
import {PrimaryButton, SecondaryButton} from '../buttons/DefaultButtons';
import IconFilter from '../../assets/svg/IconFilter';
import {FilterMenu, FilterMenuItem} from '../menu/FilterMenu';
import {bindMenu, bindTrigger, usePopupState} from 'material-ui-popup-state/hooks';
import {DatTypes, PaymentWidgetScope, TransTypes} from '../../model/constants/Constants';
import tickBlank from '../../assets/svg/accounts-payable/batchCircleCheckboxBlank.svg';
import {PencilIcon} from '../../assets/svg/Pencil';
import tickSelected from '../../assets/svg/accounts-payable/batchCircleCheckboxSelected.svg';
import {useFeatureFlags} from '../../hooks/useFeatureFlags';
import {AdHocQuickClaimDialog} from '../AccountsReceivable/AdHocQuickClaimDialog';
import {useParams} from 'react-router-dom';

const oldColumnWidth = {
  select: '75px',
  cnRef: '100px',
  description: '200px',
  vendorName: '160px',
  vendorRef: '100px',
  credit: '90px',
  balance: '90px',
  issueDate: '140px',
  button: '130px',
};
const columnWidth = {
  select: '25px',
  cnRef: '100px',
  description: '160px',
  vendorName: '100px',
  vendorRef: '85px',
  credit: '90px',
  balance: '90px',
  button: '95px',
  editIcon: '25px',
};
export interface ICreditNotesListDialogProps {
  handleDialog?: () => void;
  applyCredit: (selectedCredits: Partial<SelectedCreditNotes>[], closeDialog?: boolean) => void;
  creditList: Partial<SelectedCreditNotes>[];
  creditsToApply: Partial<SelectedCreditNotes>[];
  isAutoApprovedClaimEnabled: boolean;
  getCreditNotesAndClaims: () => Promise<Partial<SelectedCreditNotes>[]>;
  isLoading?: boolean;
  editClaim: (credit: Partial<SelectedCreditNotes>) => void;
  handleClaimDialog: () => void;
  handleEditClaim: (claimCode: string) => Promise<void>;
  isSelectedCreditGreaterThanInvoiceAmount: boolean;
  handleCreditsToApply: (credit: Partial<SelectedCreditNotes>) => void;
  handleAddClaim: (claimCode: string) => Promise<void>;
  totalCreditSelected: number;
  totalInvoiceAmount: number;
  handleDeleteClaim: (claimCode: String) => Promise<void>;
  selectedCredits: Partial<SelectedCreditNotes>[];
  showClaimDialog: boolean;
  claimId: number;
  claimCode: string;
  selectedCreditAndClaimsIds?: number[];
  prepayments72511?: boolean;
  handleSelectAll?: (status: FilterCredits) => void;
  isAllSelected?: any;
  widgetScope?: PaymentWidgetScope;
}
export interface ICreditNotesHeaderProps {
  totalInvoiceAmount: number;
  isAutoApprovedClaimEnabled: boolean;
  totalCreditSelected: number;
  handleClaimDialog?: () => void;
  isPIBL?: boolean;
  handleFilterCredits?: (type: FilterCredits) => void;
  filteredListType?: FilterCredits;
  prepayments72511?: boolean;
  handleSelectAll?: (status: FilterCredits) => void;
  isAllSelected?: any;
  creditsAndPrepayments?: Partial<SelectedCreditNotes>[];
  creditList?: Partial<SelectedCreditNotes>[];
}

export interface ICreditNotesTableHeaderProps {
  isPIBL?: boolean;
  prepayments72511?: boolean;
}

export interface ICreditNotesTableBodyProps {
  showClaimDialog: boolean;
  claimId: number;
  claimCode: string;
  handleClaimDialog: () => void;
  handleDeleteClaim: (claimCode: string) => void;
  handleAddClaim: (claimCode: string) => void;
  handleEditClaim: (claimCode: string) => void;
  creditList: Partial<SelectedCreditNotes>[];
  handleCreditsToApply: (credit: Partial<SelectedCreditNotes>) => void;
  selectedCredits: Partial<SelectedCreditNotes>[];
  isAutoApprovedClaimEnabled: boolean;
  classes?: any;
  isSelectedCreditGreaterThanInvoiceAmount: boolean;
  editClaim: (credit: Partial<SelectedCreditNotes>) => void;
  isPIBL?: boolean;
  selectedCreditAndClaimsIds?: number[];
  prepayments72511?: boolean;
  widgetScope?: PaymentWidgetScope;
}

export interface ICreditNotesFooterProps {
  isLoading?: boolean;
  applyCredit: (selectedCredits: Partial<SelectedCreditNotes>[], closeDialog?: boolean) => void;
  handleDialog?: () => void;
  selectedCredits: Partial<SelectedCreditNotes>[];
  isPIBL?: boolean;
  setStep?: (step: PaymentWidgetPaymentSteps) => void;
  handleClaimDialog?: () => void;
  isAutoApprovedClaimEnabled?: boolean;
  prepayments72511?: boolean;
}

const creditsHeader = css({
  '& .selectSymbol': {margin: '0px 7px 0px 0px', width: '17px', height: '17px'},
  '& .filterIcon': {
    border: '1px solid #1C78AD',
    borderRadius: '6px',
    padding: '5px',
    width: 40,
    height: 40,
  },
});

export const CreditNotesHeader = (props: ICreditNotesHeaderProps) => {
  const {
    totalInvoiceAmount,
    isAutoApprovedClaimEnabled,
    totalCreditSelected,
    handleClaimDialog,
    isPIBL,
    handleFilterCredits,
    filteredListType,
    handleSelectAll,
    prepayments72511,
    isAllSelected,
    creditsAndPrepayments,
    creditList,
  } = props;
  const isSelectedCreditGreaterThanInvoiceAmount = Boolean(totalCreditSelected >= totalInvoiceAmount);
  const filterPopupState = usePopupState({variant: 'popover', popupId: 'filterPopupState'});
  const {isAllTypesCreditsSelected, isAllPrepaymentsSelected, isAllCreditsAndClaimsSelected} = isAllSelected ?? {};

  const isSelectedAll =
    filteredListType === FilterCredits.ALL
      ? isAllTypesCreditsSelected
      : filteredListType === FilterCredits.CREDIT_AND_CLAIMS
        ? isAllCreditsAndClaimsSelected
        : filteredListType === FilterCredits.PREPAYMENTS
          ? isAllPrepaymentsSelected
          : false;

  return !prepayments72511 ? (
    <Box
      className={`flex ${isPIBL ? 'justify-start font-poppins' : 'justify-between'}`}
      flexDirection={isPIBL && 'column'}
      alignItems={!isPIBL && 'end'}
      bgcolor={isPIBL && '#1C78AD'}
    >
      <Box
        color={isPIBL ? '#FFFFFF' : '#333333'}
        fontSize={isPIBL ? '1.375rem' : '26px'}
        marginTop={isPIBL ? '26px' : ''}
        marginBottom={isPIBL ? '1rem' : ''}
      >
        Select Credits{' '}
      </Box>
      {isSelectedCreditGreaterThanInvoiceAmount ? (
        <Box
          color={isPIBL ? '#FFFFFF' : '#934532'}
          fontSize={isPIBL ? '1rem' : '23px'}
          style={{backgroundColor: isPIBL ? '#00405C' : ''}}
        >
          <p>Invoice Total Reached</p>
        </Box>
      ) : (
        <Box className={`${isPIBL ? 'flex' : 'contents'} w-fit justify-between`}>
          <Box
            className={isPIBL ? 'w-1/2 break-all px-1' : ''}
            color={isPIBL ? '#FFFFFF' : '#Black'}
            fontSize={isPIBL ? '14px' : '23px'}
            textAlign={isPIBL ? 'left' : ''}
          >
            <p className={isPIBL ? 'flex justify-start' : ''}>
              <span style={{width: isPIBL ? '72px' : 'auto'}}>Selected:</span>
              <CustomTooltip
                disableHoverListener={!isPIBL}
                TransitionComponent={Fade}
                TransitionProps={{timeout: 600}}
                title={PriceFormat(Number(totalCreditSelected.toFixed(2))) || ''}
                placement="bottom"
              >
                <span className={isPIBL ? 'truncate' : ''}>{PriceFormat(Number(totalCreditSelected.toFixed(2)))}</span>
              </CustomTooltip>
            </p>
          </Box>
          <Box
            className={isPIBL ? 'w-1/2 break-all  px-1' : ''}
            color={isPIBL ? '#FFFFFF' : '#Black'}
            fontSize={isPIBL ? '14px' : '23px'}
          >
            <p className={isPIBL ? 'flex justify-end truncate' : ''}>
              <span style={{width: isPIBL ? '72px' : 'auto'}}>Balance:</span>
              <CustomTooltip
                disableHoverListener={!isPIBL}
                TransitionComponent={Fade}
                TransitionProps={{timeout: 600}}
                title={PriceFormat(Number((totalInvoiceAmount - totalCreditSelected).toFixed(2))) || ''}
                placement="bottom"
              >
                <span className={isPIBL ? 'truncate' : ''}>
                  {PriceFormat(Number((totalInvoiceAmount - totalCreditSelected).toFixed(2)))}
                </span>
              </CustomTooltip>
            </p>
          </Box>
        </Box>
      )}
      <Box>
        {!isPIBL && isAutoApprovedClaimEnabled && (
          <>
            <PrimaryButton label="New Credit Claim" onClick={() => handleClaimDialog && handleClaimDialog()} />
          </>
        )}
      </Box>
    </Box>
  ) : (
    <Box className={`flex flex-col font-poppins ${!isPIBL ? creditsHeader : ''}`} bgcolor={isPIBL && '#1C78AD'}>
      <Box
        className={`flex justify-center ${isPIBL ? '' : 'pb-3 font-light'}`}
        color={isPIBL ? '#FFFFFF' : '#333333'}
        fontSize={isPIBL ? '1.375rem' : '22px'}
        marginTop={isPIBL ? '26px' : ''}
        marginBottom={isPIBL ? '1rem' : ''}
        borderBottom={isPIBL ? '' : '1px solid #CCCCCC'}
      >
        {isPIBL ? 'Select Credits' : 'Apply payments and credit notes'}
      </Box>
      <Box className={isPIBL ? '' : 'flex flex-row justify-between pt-2'}>
        {!isPIBL && (
          <Box
            className={`flex w-full max-w-[30%] flex-row items-center justify-start ${
              creditList?.length ? 'cursor-pointer' : ''
            }`}
            onClick={() => creditList?.length && handleSelectAll?.(filteredListType!)}
          >
            <img
              src={isSelectedAll ? tickSelected : tickBlank}
              className={`selectSymbol`}
              data-autoid={'imgUnselected'}
            />
            <p className="text-xs text-primary">Select All</p>
          </Box>
        )}
        {isSelectedCreditGreaterThanInvoiceAmount ? (
          <Box
            color={isPIBL ? '#FFFFFF' : '#934532'}
            fontSize={isPIBL ? '1rem' : '23px'}
            style={{backgroundColor: isPIBL ? '#00405C' : ''}}
          >
            <p>Invoice Total Reached</p>
          </Box>
        ) : (
          <Box className={`flex w-fit justify-between ${isPIBL ? '' : 'items-center'}`}>
            <Box
              className={isPIBL ? 'w-1/2 break-all px-1' : 'mr-5'}
              color={isPIBL ? '#FFFFFF' : '#333'}
              fontSize={isPIBL ? '14px' : '12px'}
              textAlign={isPIBL ? 'left' : ''}
            >
              <p className={isPIBL ? 'flex justify-start' : 'font-medium'}>
                <span style={{width: isPIBL ? '72px' : 'auto'}}>Selected: </span>
                <CustomTooltip
                  disableHoverListener={!isPIBL}
                  TransitionComponent={Fade}
                  TransitionProps={{timeout: 600}}
                  title={PriceFormat(Number(totalCreditSelected.toFixed(2))) || ''}
                  placement="bottom"
                >
                  <span className={isPIBL ? 'truncate' : ''}>
                    {PriceFormat(Number(totalCreditSelected.toFixed(2)))}
                  </span>
                </CustomTooltip>
              </p>
            </Box>
            <Box
              className={isPIBL ? 'w-1/2 break-all  px-1' : 'ml-5'}
              color={isPIBL ? '#FFFFFF' : '#333'}
              fontSize={isPIBL ? '14px' : '12px'}
            >
              <p className={isPIBL ? 'flex justify-end truncate' : 'font-medium'}>
                <span style={{width: isPIBL ? '72px' : 'auto'}}>Balance: </span>
                <CustomTooltip
                  disableHoverListener={!isPIBL}
                  TransitionComponent={Fade}
                  TransitionProps={{timeout: 600}}
                  title={PriceFormat(Number((totalInvoiceAmount - totalCreditSelected).toFixed(2))) || ''}
                  placement="bottom"
                >
                  <span className={isPIBL ? 'truncate' : ''}>
                    {PriceFormat(Number((totalInvoiceAmount - totalCreditSelected).toFixed(2)))}
                  </span>
                </CustomTooltip>
              </p>
            </Box>
          </Box>
        )}
        {!isPIBL && (
          <Box className="w-full max-w-[30%]">
            <>
              {isAutoApprovedClaimEnabled && (
                <SecondaryButton
                  margin="0px 10px 0px 0px"
                  label="New Claim"
                  onClick={() => handleClaimDialog && handleClaimDialog()}
                />
              )}
              <>
                <IconButton
                  className={'filterIcon'}
                  disableRipple
                  data-autoid="btnFilterSalesOrder"
                  {...bindTrigger(filterPopupState)}
                  disabled={!creditsAndPrepayments?.length}
                >
                  <IconFilter width="20" height="22" />
                </IconButton>
                <FilterMenu
                  {...bindMenu(filterPopupState)}
                  anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                  transformOrigin={{vertical: 'top', horizontal: 'right'}}
                >
                  <FilterMenuItem
                    selected={filteredListType === FilterCredits.ALL}
                    onClick={() => {
                      handleFilterCredits?.(FilterCredits.ALL);
                      filterPopupState.close();
                    }}
                  >
                    Show everything
                  </FilterMenuItem>
                  <FilterMenuItem
                    selected={filteredListType === FilterCredits.PREPAYMENTS}
                    onClick={() => {
                      handleFilterCredits?.(FilterCredits.PREPAYMENTS);
                      filterPopupState.close();
                    }}
                  >
                    Show only Payments
                  </FilterMenuItem>
                  <FilterMenuItem
                    selected={filteredListType === FilterCredits.CREDIT_AND_CLAIMS}
                    onClick={() => {
                      handleFilterCredits?.(FilterCredits.CREDIT_AND_CLAIMS);
                      filterPopupState.close();
                    }}
                  >
                    Show only Credit notes
                  </FilterMenuItem>
                </FilterMenu>
              </>
            </>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export const CreditNotesTableHeader = ({isPIBL, prepayments72511}: ICreditNotesTableHeaderProps) => {
  const customPadding = css({
    padding: '11px 36px 0',
  });
  return (
    <>
      {!isPIBL && !prepayments72511 ? (
        <Box className={`flex ${customPadding}`} fontSize="12px" color="#979797">
          <Box minWidth={oldColumnWidth.select} width={oldColumnWidth.select}></Box>
          <Box minWidth={oldColumnWidth.cnRef} width={oldColumnWidth.cnRef}>
            CN Ref
          </Box>
          <Box minWidth={oldColumnWidth.description} width={oldColumnWidth.description}>
            Description
          </Box>
          <Box minWidth={oldColumnWidth.vendorName} width={oldColumnWidth.vendorName}>
            Vendor Name
          </Box>
          <Box minWidth={oldColumnWidth.vendorRef} width={oldColumnWidth.vendorRef}>
            Vendor Ref
          </Box>
          <Box minWidth={oldColumnWidth.credit} width={oldColumnWidth.credit}>
            Credit Total
          </Box>
          <Box minWidth={oldColumnWidth.balance} width={oldColumnWidth.balance}>
            Balance
          </Box>
          <Box minWidth={oldColumnWidth.issueDate} width={oldColumnWidth.issueDate}>
            Issue Date
          </Box>
          <Box minWidth={oldColumnWidth.button} width={oldColumnWidth.button}></Box>
        </Box>
      ) : !isPIBL && prepayments72511 ? (
        <Box className={`flex px-3 font-poppins`} fontSize="12px" color="#333">
          <Box className="pr-2" minWidth={columnWidth.select} width={columnWidth.select}></Box>
          <Box className="px-2" minWidth={columnWidth.cnRef} width={columnWidth.cnRef}>
            Ref no.
          </Box>
          <Box className="px-2" minWidth={columnWidth.description} width={columnWidth.description}>
            Description
          </Box>
          <Box className="px-2" minWidth={columnWidth.vendorName} width={columnWidth.vendorName}>
            Vendor
          </Box>
          <Box className="px-2" minWidth={columnWidth.vendorRef} width={columnWidth.vendorRef}>
            Vendor Ref
          </Box>
          <Box className="flex justify-end px-2" minWidth={columnWidth.credit} width={columnWidth.credit}>
            Total
          </Box>
          <Box className="flex justify-end px-2" minWidth={columnWidth.balance} width={columnWidth.balance}>
            Balance
          </Box>
          <Box className="flex justify-end px-2" minWidth={columnWidth.button} width={columnWidth.button}>
            Status
          </Box>
          <Box className="flex justify-end pl-2" minWidth={columnWidth.editIcon} width={columnWidth.editIcon}></Box>
        </Box>
      ) : (
        <Box
          className={`flex font-poppins text-xs text-spenda-primarytext `}
          fontSize="10px"
          style={{background: '#EAEAEA'}}
          px="0.24rem"
        >
          <Box width={'54%'} justifyContent={'center'} />
          <Box display={'flex'} width={'24%'} justifyContent={'center'}>
            Total
          </Box>
          <Box display={'flex'} width={'24%'} marginRight="12px" justifyContent={'right'}>
            Balance
          </Box>
        </Box>
      )}
    </>
  );
};

export const CreditNotesTableBody = (props: ICreditNotesTableBodyProps) => {
  const {
    showClaimDialog,
    claimId,
    claimCode,
    handleClaimDialog,
    handleDeleteClaim,
    handleAddClaim,
    handleEditClaim,
    creditList,
    handleCreditsToApply,
    selectedCredits,
    isAutoApprovedClaimEnabled,
    classes,
    isSelectedCreditGreaterThanInvoiceAmount,
    editClaim,
    isPIBL,
    selectedCreditAndClaimsIds,
    prepayments72511,
    widgetScope,
  } = props;

  // Feature Flags
  const {claimAndReturnsV272602: claimAndReturnsV272602TenantOwned} = useFeatureFlags().tenantOwned();
  const {claimAndReturnsV272602: claimAndReturnsV272602SuppDriven} = useFeatureFlags().supplierDriven();
  const claimAndReturnsV272602 = claimAndReturnsV272602TenantOwned && claimAndReturnsV272602SuppDriven;

  const {supplierId} = useParams<{supplierId?: string}>();

  return (
    <>
      {showClaimDialog && (
        <>
          {claimAndReturnsV272602 && widgetScope === PaymentWidgetScope.PSBL ? (
            supplierId && (
              <AdHocQuickClaimDialog
                supplierId={parseInt(supplierId)}
                isShowModal={showClaimDialog}
                onClose={() => handleClaimDialog?.()}
                onSuccess={handleAddClaim}
              />
            )
          ) : (
            <CreateClaimDialog
              claimId={claimId}
              claimCode={claimCode}
              handleDialog={handleClaimDialog}
              handleDeleteClaim={handleDeleteClaim}
              handleAddClaim={handleAddClaim}
              handleEditClaim={handleEditClaim}
              isCompactView={isPIBL}
            />
          )}
        </>
      )}
      {creditList.length ? (
        creditList.map((credit: Partial<SelectedCreditNotes>) => (
          <CreditNoteLineItems
            credit={credit}
            key={credit.RefNumber}
            handleCreditsToApply={() => handleCreditsToApply(credit)}
            isSelected={
              isPIBL
                ? Boolean(selectedCredits.find(cr => cr.RefNumber === credit.RefNumber))
                : Boolean(selectedCreditAndClaimsIds?.includes(credit.TransID!))
            }
            isAutoApprovedClaimEnabled={isAutoApprovedClaimEnabled}
            disableSelection={
              (credit.Type === 'CLAIM' && !isAutoApprovedClaimEnabled) ||
              (isSelectedCreditGreaterThanInvoiceAmount &&
                (isPIBL
                  ? !Boolean(selectedCredits.find(cr => cr.RefNumber === credit.RefNumber))
                  : !Boolean(selectedCreditAndClaimsIds?.includes(credit.TransID!))))
            }
            editClaim={() => editClaim(credit)}
            tooltip={classes?.tooltip}
            isPIBL={isPIBL}
            prepayments72511={prepayments72511}
          />
        ))
      ) : (
        <p className="my-1 flex justify-center font-poppins font-normal text-spenda-primarytext">
          No credits or payments available
        </p>
      )}
    </>
  );
};

export const CreditNotesFooter = (props: ICreditNotesFooterProps) => {
  const {
    isLoading,
    applyCredit,
    handleDialog,
    selectedCredits,
    isPIBL,
    setStep,
    handleClaimDialog,
    isAutoApprovedClaimEnabled,
    prepayments72511,
  } = props;

  const creditNotesFooterStyles = makeStyles({
    addCredits: {
      width: '2.188rem',
      height: '2.063rem',
      borderRadius: '6px',
      border: '1px solid rgba(28,120,173, 1)',
      opacity: 1,
      backgroundColor: 'rgba(255,255,255, 1)',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      alignSelf: 'center',
    },
  });
  const classes = creditNotesFooterStyles();

  if (!isPIBL) {
    return prepayments72511 ? (
      <PrimaryButton
        onClick={() => (isLoading ? undefined : handleDialog && handleDialog())}
        label="Close"
        width="100px"
      />
    ) : (
      <SecondaryButton onClick={() => (isLoading ? undefined : handleDialog && handleDialog())} label="Close" />
    );
  }
  return (
    <>
      {!isPIBL ? (
        <SecondaryButton
          onClick={() =>
            isLoading
              ? undefined
              : isPIBL && setStep
                ? setStep(PaymentWidgetPaymentSteps.NEW)
                : handleDialog && handleDialog()
          }
          label="Cancel"
        />
      ) : (
        <SButton
          border={isPIBL ? '1px solid #1C78AD' : ''}
          textColor={isPIBL ? 'blue' : 'white'}
          color={isPIBL ? 'white' : 'yellow'}
          fontSize={isPIBL ? '0.875rem' : ''}
          lineHeight="l"
          fontFamily={isPIBL ? 'Poppins' : ''}
          width="100px"
          borderRadius="6px"
          height={isPIBL ? '40px' : ''}
          onClick={() =>
            isLoading
              ? undefined
              : isPIBL && setStep
                ? setStep(PaymentWidgetPaymentSteps.NEW)
                : handleDialog && handleDialog()
          }
        >
          Cancel
        </SButton>
      )}
      {isPIBL && isAutoApprovedClaimEnabled && (
        <div
          data-autoid="btnAddNewClaim"
          className={classes.addCredits}
          onClick={() => handleClaimDialog && handleClaimDialog()}
        >
          <AddRoundedIcon style={{color: '#1C78AD'}} />
        </div>
      )}
      {!isPIBL ? (
        <PrimaryButton
          label="Save"
          disabled={isLoading}
          isSubmitting={isLoading}
          onClick={() => applyCredit(selectedCredits, true)}
        />
      ) : (
        <SButton
          color="darkBlue"
          lineHeight="l"
          fontFamily={isPIBL ? 'Poppins' : ''}
          width="100px"
          onClick={() => applyCredit(selectedCredits, true)}
          disabled={isLoading}
          fontSize={isPIBL ? '0.875rem' : ''}
          height={isPIBL ? '40px' : ''}
          borderRadius="6px"
        >
          Save
          {isLoading && <LoadingIndicator isLoading={isLoading} size="sm" color={'#FFF'} position={{right: '5px'}} />}
        </SButton>
      )}
    </>
  );
};

const customTootipStyles = makeStyles({
  arrow: {
    color: '#a8d6f0',
  },
  tooltip: {
    backgroundColor: '#a8d6f0',
    color: '#333',
    width: '100%',
    fontSize: '10px',
    fontWeight: 500,
    fontFamily: 'poppins',
    textAlign: 'center',
  },
});

function CustomTooltip(props: TooltipProps) {
  const classes = customTootipStyles();
  return <Tooltip arrow classes={classes} {...props} />;
}

const useStyles = makeStyles({
  dialog: {
    fontSize: 18,
    fontWeight: 500,
    justifyContent: 'center',
    maxWidth: 1150,
    minWidth: 1150,
    minHeight: 577,
    maxHeight: 577,
    position: 'absolute',
    zIndex: 1000,
  },
  dialogActions: {
    flexDirection: 'initial',
    padding: 15,
  },
  tooltip: {
    fontSize: '1rem',
    padding: '.5rem',
  },
});

export enum FilterCredits {
  ALL,
  PREPAYMENTS,
  CREDIT_AND_CLAIMS,
}

export const CreditNotesListDialog = (props: ICreditNotesListDialogProps) => {
  const {
    handleDialog,
    creditList: creditsAndPrepayments,
    applyCredit,
    isAutoApprovedClaimEnabled,
    isLoading,
    editClaim,
    handleClaimDialog,
    handleEditClaim,
    isSelectedCreditGreaterThanInvoiceAmount,
    handleCreditsToApply,
    handleAddClaim,
    totalInvoiceAmount,
    totalCreditSelected,
    handleDeleteClaim,
    selectedCredits,
    showClaimDialog,
    claimId,
    claimCode,
    selectedCreditAndClaimsIds,
    prepayments72511,
    handleSelectAll,
    isAllSelected,
    widgetScope,
  } = props;

  const classes = useStyles();

  useEffect(() => {
    if (creditsAndPrepayments) {
      handleFilterCredits(filerdCreditList.type);
    }
  }, [creditsAndPrepayments]);

  const [filerdCreditList, setFilteredCreditList] = useState({
    type: FilterCredits.ALL,
    creditList: creditsAndPrepayments,
  });

  const handleFilterCredits = (type: FilterCredits) => {
    switch (type) {
      case FilterCredits.ALL:
        setFilteredCreditList({type: FilterCredits.ALL, creditList: creditsAndPrepayments});
        break;
      case FilterCredits.PREPAYMENTS:
        const prepaymentsList = creditsAndPrepayments.filter(a => a.DatTypeID === DatTypes.Payment);
        setFilteredCreditList({type: FilterCredits.PREPAYMENTS, creditList: prepaymentsList});
        break;
      case FilterCredits.CREDIT_AND_CLAIMS:
        const creditsAndClaimsList = creditsAndPrepayments.filter(a => a.DatTypeID !== DatTypes.Payment);
        setFilteredCreditList({type: FilterCredits.CREDIT_AND_CLAIMS, creditList: creditsAndClaimsList});
        break;
      default:
        break;
    }
  };

  return (
    <>
      <SDialog
        id={prepayments72511 ? 'creditListDialog' : ''}
        open
        maxWidth="sm"
        fullWidth
        classes={{paper: !prepayments72511 ? classes.dialog : ''}}
        disableBackdropClick={true}
        onClose={handleDialog}
      >
        <DialogTitle className="text-right">
          <CreditNotesHeader
            totalInvoiceAmount={totalInvoiceAmount}
            isAutoApprovedClaimEnabled={isAutoApprovedClaimEnabled}
            totalCreditSelected={totalCreditSelected}
            handleClaimDialog={handleClaimDialog}
            handleFilterCredits={handleFilterCredits}
            filteredListType={filerdCreditList.type}
            prepayments72511={prepayments72511}
            handleSelectAll={handleSelectAll}
            isAllSelected={isAllSelected}
            creditList={filerdCreditList.creditList}
            creditsAndPrepayments={creditsAndPrepayments}
          />
        </DialogTitle>
        <CreditNotesTableHeader prepayments72511={prepayments72511} />
        <DialogContent>
          <CreditNotesTableBody
            showClaimDialog={showClaimDialog}
            claimId={claimId}
            claimCode={claimCode}
            handleClaimDialog={handleClaimDialog}
            handleDeleteClaim={handleDeleteClaim}
            handleAddClaim={handleAddClaim}
            handleEditClaim={handleEditClaim}
            creditList={filerdCreditList.creditList}
            handleCreditsToApply={handleCreditsToApply}
            selectedCredits={selectedCredits}
            isAutoApprovedClaimEnabled={isAutoApprovedClaimEnabled}
            classes={classes}
            isSelectedCreditGreaterThanInvoiceAmount={isSelectedCreditGreaterThanInvoiceAmount}
            editClaim={editClaim}
            selectedCreditAndClaimsIds={selectedCreditAndClaimsIds}
            prepayments72511={prepayments72511}
            widgetScope={widgetScope}
          />
        </DialogContent>
        <DialogActions classes={{root: classes.dialogActions}}>
          <CreditNotesFooter
            isLoading={isLoading}
            handleDialog={handleDialog}
            applyCredit={applyCredit}
            selectedCredits={selectedCredits}
            prepayments72511={prepayments72511}
          />
        </DialogActions>
      </SDialog>
    </>
  );
};

const marginZero = css({
  margin: '0 !important',
});
const selectToggler = css({
  width: '17px',
  height: '17px',
});

const horizontalLine = css({
  width: '77%',
  backgroundColor: '#f5d6d6 !important',
  margin: 'auto !important',
});

const image = css({
  width: '30px',
  height: '30px',
  cursor: 'pointer',
});

const smallImage = css({
  width: '24px',
  height: '24px',
  cursor: 'pointer',
});

const descriptionCss = css({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  paddingRight: '10px',
});

export const CreditNoteLineItems = (props: {
  credit: Partial<SelectedCreditNotes>;
  handleCreditsToApply: () => void;
  isSelected: boolean;
  disableSelection?: boolean;
  editClaim: () => void;
  isAutoApprovedClaimEnabled?: boolean;
  tooltip: any;
  isPIBL?: boolean;
  prepayments72511?: boolean;
}) => {
  const {
    credit,
    handleCreditsToApply,
    isSelected,
    disableSelection,
    editClaim,
    isAutoApprovedClaimEnabled,
    tooltip,
    isPIBL,
    prepayments72511,
  } = props;

  const isCredit = credit.TransType === TransTypes.CreditNote;
  const isPrepayment = credit.TransType === TransTypes.Prepayment;
  const isClaim = credit.TransType === TransTypes.Claim;

  const customButton = ({
    label,
    bgcolor,
    color,
    isPIBL,
  }: {
    label: string;
    bgcolor: string;
    color: string;
    isPIBL?: boolean;
  }) => {
    return (
      <Box
        color={color}
        border={`1px solid ${color}`}
        bgcolor={bgcolor}
        className={`${isPIBL ? 'mx-1 flex h-6 w-6 justify-center font-poppins' : 'h-8 w-20 px-3 py-1'} rounded-md `}
      >
        <Typography
          color="inherit"
          style={{fontSize: `${isPIBL ? '0.875rem' : ''}`, fontFamily: `${isPIBL ? 'poppins' : ''}`}}
        >
          {label}
        </Typography>
      </Box>
    );
  };

  return (
    <>
      {!isPIBL && !prepayments72511 ? (
        <>
          <Box
            className={`flex items-center p-3 ${disableSelection ? 'opacity-50' : ''}`}
            fontSize="18px"
            color="#333333"
          >
            <Box minWidth={oldColumnWidth.select} width={oldColumnWidth.select}>
              <span
                data-autoid={`check-${credit.RefNumber || credit.ClaimCode}`}
                onClick={() => (disableSelection ? {} : handleCreditsToApply())}
              >
                <SelectToggler
                  dataAutoId={'chk' + credit.RefNumber || credit.ClaimCode}
                  className={`${marginZero}`}
                  isSelected={isSelected}
                />
              </span>
            </Box>
            <Box minWidth={oldColumnWidth.cnRef} width={oldColumnWidth.cnRef}>
              {credit.RefNumber || credit.ClaimCode}
            </Box>
            <Box minWidth={oldColumnWidth.description} width={oldColumnWidth.description}>
              {Boolean(credit.Description) && (
                <Tooltip
                  classes={{
                    tooltip: tooltip,
                  }}
                  TransitionComponent={Fade}
                  TransitionProps={{timeout: 600}}
                  title={credit.Description || ''}
                  arrow
                >
                  <p className={`${descriptionCss}`}>{credit.Description}</p>
                </Tooltip>
              )}
            </Box>
            <Box minWidth={oldColumnWidth.vendorName} width={oldColumnWidth.vendorName}>
              <p className={`${descriptionCss}`}>{credit.VendorName}</p>
            </Box>
            <Box minWidth={oldColumnWidth.vendorRef} width={oldColumnWidth.vendorRef}>
              <p className={`${descriptionCss}`}>{credit.VendorRefNumber}</p>
            </Box>
            <Box minWidth={oldColumnWidth.credit} width={oldColumnWidth.credit}>
              {PriceFormat(credit.TotalInc)}
            </Box>
            <Box minWidth={oldColumnWidth.balance} width={oldColumnWidth.balance}>
              {PriceFormat(credit.Balance)}
            </Box>
            <Box minWidth={oldColumnWidth.issueDate} width={oldColumnWidth.issueDate}>
              {(credit.CreatedDate && moment(credit.CreatedDate).format('Do MMM YYYY')) ||
                (credit.CreatedDateTime && moment(credit.CreatedDateTime).format('Do MMM YYYY'))}
            </Box>
            <Box minWidth={oldColumnWidth.button} width={oldColumnWidth.button}>
              {isCredit ? (
                customButton({label: 'Approved', color: '#4DA128', bgcolor: 'rgba(77, 161, 40, 0.2)'})
              ) : isPrepayment ? (
                customButton({label: 'Payment', color: '#3D3FD8', bgcolor: 'rgba(61, 63, 216, 0.2)'})
              ) : (
                <Box className="flex items-center justify-between">
                  {customButton({label: 'Requested', color: '#C68A19', bgcolor: 'rgba(198, 138, 25, 0.2)'})}
                  <img
                    src={EditIcon}
                    className={`${image}`}
                    onClick={() => (isAutoApprovedClaimEnabled ? editClaim() : {})}
                  />
                </Box>
              )}
            </Box>
          </Box>
          <Divider className={`${horizontalLine}`} />
        </>
      ) : !isPIBL && prepayments72511 ? (
        <>
          <Box
            className={`flex items-center py-3 font-poppins font-medium ${disableSelection ? 'opacity-50' : ''}`}
            fontSize="12px"
            color="#333333"
          >
            <Box minWidth={columnWidth.select} width={columnWidth.select}>
              <span
                data-autoid={`check-${credit.RefNumber || credit.ClaimCode}`}
                onClick={() => (disableSelection ? {} : handleCreditsToApply())}
              >
                <SelectToggler
                  dataAutoId={'chk' + credit.RefNumber || credit.ClaimCode}
                  className={`${marginZero} ${selectToggler}`}
                  isSelected={isSelected}
                  isAccountsPayable={true}
                />
              </span>
            </Box>
            <Box className="px-2" minWidth={columnWidth.cnRef} width={columnWidth.cnRef}>
              {credit.RefNumber || credit.ClaimCode}
            </Box>
            <Box className="px-2" minWidth={columnWidth.description} width={columnWidth.description}>
              {Boolean(credit.Description) && (
                <Tooltip
                  classes={{
                    tooltip: tooltip,
                  }}
                  TransitionComponent={Fade}
                  TransitionProps={{timeout: 600}}
                  title={credit.Description || ''}
                  arrow
                >
                  <p className={`${descriptionCss}`}>{credit.Description}</p>
                </Tooltip>
              )}
            </Box>
            <Box className="px-2" minWidth={columnWidth.vendorName} width={columnWidth.vendorName}>
              <p className={`${descriptionCss}`}>{credit.VendorName}</p>
            </Box>
            <Box className="px-2" minWidth={columnWidth.vendorRef} width={columnWidth.vendorRef}>
              <p className={`${descriptionCss}`}>{credit.VendorRefNumber}</p>
            </Box>
            <Box className="flex justify-end px-2" minWidth={columnWidth.credit} width={columnWidth.credit}>
              {PriceFormat(credit.TotalInc)}
            </Box>
            <Box className="flex justify-end px-2" minWidth={columnWidth.balance} width={columnWidth.balance}>
              {PriceFormat(credit.Balance)}
            </Box>
            <Box className="flex justify-end px-2" minWidth={columnWidth.button} width={columnWidth.button}>
              {isCredit ? (
                <span className="text-xs font-medium text-spenda-sGreen">Credit Note</span>
              ) : isPrepayment ? (
                <span className="text-xs font-medium" style={{color: '#3D3FD8'}}>
                  Payment
                </span>
              ) : (
                <span className="text-xs font-medium" style={{color: '#CC9733'}}>
                  Credit Claim
                </span>
              )}
            </Box>
            <Box className="flex justify-end pl-2" minWidth={columnWidth.editIcon} width={columnWidth.editIcon}>
              {isClaim && (
                <PencilIcon
                  className="cursor-pointer"
                  onClick={() => (isAutoApprovedClaimEnabled ? editClaim() : {})}
                />
              )}
            </Box>
          </Box>
          <Divider style={{backgroundColor: '#D8D8D8'}} />
        </>
      ) : (
        <>
          <Box
            className={`flex items-start ${disableSelection ? 'opacity-50' : ''} font-poppins font-medium`}
            fontSize="16px"
            color="#333333"
            px="0.25rem"
            py="0.4rem"
          >
            <Box width={'8%'} px="3px" className="self-center">
              <span
                data-autoid={`check-${credit.RefNumber || credit.ClaimCode}`}
                onClick={() => (disableSelection ? {} : handleCreditsToApply())}
              >
                <SelectToggler className={`${marginZero}`} isSelected={isSelected} isAccountsPayable={true} />
              </span>
            </Box>
            <Box flexDirection={'column'} width="46%" px="1px">
              <Box minWidth={oldColumnWidth.cnRef} width={oldColumnWidth.cnRef}>
                {isCredit ? credit.RefNumber : credit.ClaimCode}
              </Box>
              <Box flexDirection={'column'} style={{fontSize: '10px'}}>
                <label>
                  <span>Vendor:</span>
                </label>
                <label>
                  <span>Vendor Ref:</span>
                </label>
                {Boolean(credit.Description) && (
                  <label>
                    <span>Description: {credit.Description}</span>
                  </label>
                )}
                <label>
                  <span>
                    Issue Date:{' '}
                    {isCredit
                      ? credit.CreatedDate && moment(credit.CreatedDate).format('Do MMM YYYY')
                      : credit.CreatedDateTime && moment(credit.CreatedDateTime).format('Do MMM YYYY')}
                  </span>
                </label>
              </Box>
            </Box>

            <Box width={'24%'} className="flex justify-center truncate px-px text-xs">
              <CustomTooltip
                TransitionComponent={Fade}
                TransitionProps={{timeout: 600}}
                title={PriceFormat(credit.TotalInc) || ''}
                placement="bottom"
              >
                <p className="truncate">{PriceFormat(credit.TotalInc)}</p>
              </CustomTooltip>
            </Box>
            <Box width={'24%'} className="flex justify-end px-px text-xs">
              <Box className="truncate text-right">
                <CustomTooltip
                  TransitionComponent={Fade}
                  TransitionProps={{timeout: 600}}
                  title={PriceFormat(credit.Balance) || ''}
                  placement="bottom"
                >
                  <p className="truncate text-right">{PriceFormat(credit.Balance)}</p>
                </CustomTooltip>
                <Box className="mt-2 flex items-center justify-end">
                  {isCredit ? (
                    <>
                      {customButton({
                        label: 'A',
                        color: '#4DA128',
                        bgcolor: 'rgba(77, 161, 40, 0.2)',
                        isPIBL: true,
                      })}
                    </>
                  ) : (
                    <Box className="flex items-center justify-end">
                      {customButton({
                        label: 'R',
                        color: '#C68A19',
                        bgcolor: 'rgba(198, 138, 25, 0.2)',
                        isPIBL: true,
                      })}
                      <img
                        className={`${smallImage}`}
                        src={EditIcon}
                        onClick={() => (isAutoApprovedClaimEnabled ? editClaim() : {})}
                      />
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
          <Divider style={{backgroundColor: '#EAEAEA', height: '3px'}} />
        </>
      )}
    </>
  );
};
