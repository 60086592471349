import {css} from 'glamor';
import React, {PropsWithChildren, useState} from 'react';
import {useSwipeable} from 'react-swipeable';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {useEffect} from 'react';

interface ICarouselItemProps {
  width?: string;
}

export const CarouselItem = (props: PropsWithChildren<ICarouselItemProps>) => {
  const carouselItem = css({
    height: '150px',
    marginTop: '13px',
  });

  return (
    <div
      className={`${carouselItem} m-0 inline-flex w-full items-center justify-center p-0 text-white`}
      style={{width: props.width}}
    >
      {props.children}
    </div>
  );
};

interface ICarouselProps {
  onSlideChanged?: (value: number) => void;
  activeIndex?: number;
  nickName?: string;
  isPaymentFeeLoading?: boolean;
}

export const Carousel = (props: PropsWithChildren<ICarouselProps>) => {
  const carouselInner = css({
    whiteSpace: 'nowrap',
    transition: 'transform 0.25s',
  });

  const [activeIndex, setActiveIndex] = useState(props.activeIndex || 0);

  useEffect(() => {
    if (typeof props.activeIndex === 'number') {
      updateIndex(props.activeIndex);
    }
  }, [props.activeIndex]);

  const updateIndex = (newIndex: number) => {
    if (newIndex < 0) {
      newIndex = 0;
    } else if (newIndex >= React.Children.count(props.children)) {
      newIndex = React.Children.count(props.children) - 1;
    }

    setActiveIndex(newIndex);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => onSwapSlide(activeIndex + 1),
    onSwipedRight: () => onSwapSlide(activeIndex - 1),
  });

  const onSwapSlide = (newIndex: number) => {
    if (props.onSlideChanged) {
      props.onSlideChanged(newIndex);
    }
  };

  const circleDotStyle = css({
    height: '10px',
    width: '10px',
    borderRadius: '50%',
    display: 'inline-block',
  });

  return (
    <div {...handlers} data-autoid={`payementWidgetCarousel`} className="carousel relative overflow-hidden">
      <div className={`${carouselInner}`} style={{transform: `translateX(-${activeIndex * 100}%)`}}>
        {React.Children.map(props.children, (child: any) => {
          return React.cloneElement(child, {width: '100%'});
        })}
      </div>
      {activeIndex !== 0 && (
        <button
          disabled={props.isPaymentFeeLoading}
          data-autoid="btn-chevron-left"
          className="absolute rounded-full border border-gray-400 bg-gray-200 p-2 font-bold opacity-10"
          style={{top: '31%', left: '10px'}}
          onClick={() => {
            onSwapSlide(activeIndex - 1);
          }}
        >
          <ChevronLeftIcon />
        </button>
      )}
      {activeIndex !== React.Children.count(props.children) - 1 && (
        <button
          disabled={props.isPaymentFeeLoading}
          data-autoid="btn-chevron-right"
          className="absolute rounded-full border border-gray-400 bg-gray-200 p-2 font-bold opacity-10"
          style={{top: '31%', right: '10px'}}
          onClick={() => {
            onSwapSlide(activeIndex + 1);
          }}
        >
          <ChevronRightIcon />
        </button>
      )}

      {props?.nickName && (
        <p style={{fontFamily: 'poppins', fontSize: '12px', position: 'relative'}}>{props?.nickName}</p>
      )}

      <div className={`indicators relative flex justify-center`}>
        {React.Children.map(props.children, (child: any, index) => {
          return (
            <button
              data-autoid={`btn-item-${index}`}
              style={{margin: '0.084rem'}}
              onClick={() => {
                onSwapSlide(index);
              }}
            >
              {index === activeIndex ? (
                <span className={`${circleDotStyle} bg-spenda-sBlue`}></span>
              ) : (
                <span
                  className={`${circleDotStyle} border-solid`}
                  {...css({borderWidth: '1px', borderColor: '#1C78AD'})}
                ></span>
              )}
            </button>
          );
        })}
      </div>
    </div>
  );
};
