import {useContext} from 'react';
import PurchasingContext from '../context/purchasing/purchasingContext';
import useHttp from '../hooks/useHttp';
import {usePaymentUtilities} from '../hooks/usePaymentUtilities';
import {IBatchOperation, IARBatchResponse, ISetPaymentAccountGUIDReq} from '../model/accounts-receivable/batch';

export const useAccountReceivableBatchAPI = ({linkedSupplierID}: {linkedSupplierID: number}) => {
  //#region APIs
  const {GET, POST, isLoading} = useHttp();
  const {setPsblBatch, psblBatch} = useContext(PurchasingContext);
  const _pu = usePaymentUtilities();

  const getBatchDetailAPI = (): Promise<IARBatchResponse> => {
    return GET(`Spenda/Payments/AccountsReceivable/Buyer/InvoiceBatch/${linkedSupplierID}`).then(
      (data: IARBatchResponse) => data
    );
  };

  const saveBatchAPI = (payload: IBatchOperation[]): Promise<IARBatchResponse> => {
    return POST(`Spenda/Payments/AccountsReceivable/Buyer/InvoiceBatch/${linkedSupplierID}`, {
      OperationActions: payload,
    }).then((data: IARBatchResponse) => data);
  };
  const cancelBatchAPI = (invoicePaymentAllocationID: number): Promise<IARBatchResponse> => {
    return POST(`Spenda/Payments/AccountsReceivable/Buyer/InvoiceBatch/${linkedSupplierID}/cancel`, {
      invoicePaymentAllocationID: invoicePaymentAllocationID,
    }).then((data: IARBatchResponse) => data);
  };
  const retryBatchPaymentAPI = (invoicePaymentAllocationID: number): Promise<IARBatchResponse> => {
    return POST(`Spenda/Payments/AccountsReceivable/Buyer/InvoiceBatch/${linkedSupplierID}/retry`, {
      invoicePaymentAllocationID: invoicePaymentAllocationID,
    }).then((data: IARBatchResponse) => data);
  };

  const setPaymentAccountGUIDAPI = (payload: ISetPaymentAccountGUIDReq): Promise<IARBatchResponse> => {
    return POST(
      `Spenda/Payments/AccountsReceivable/Buyer/InvoiceBatch/${linkedSupplierID}/setPaymentAccountGUID`,
      payload
    ).then((data: IARBatchResponse) => data);
  };

  //#endregion

  //#custom Functions
  const saveBatch = async (payload: IBatchOperation[]) => {
    const result = await saveBatchAPI(payload);
    saveBatchResponse(result);
  };

  const getBatchDetail = async () => {
    const result = await getBatchDetailAPI();
    saveBatchResponse(result);
  };
  const retryBatchPayment = async (invoicePaymentAllocationID: number) => {
    await retryBatchPaymentAPI(invoicePaymentAllocationID);
    const result = await getBatchDetailAPI();
    saveBatchResponse(result);
  };

  const cancelBatch = async (invoicePaymentAllocationID: number) => {
    await cancelBatchAPI(invoicePaymentAllocationID);
    const result = await getBatchDetailAPI();
    saveBatchResponse(result);
  };

  const saveBatchResponse = (batchResponse: IARBatchResponse) => {
    const isBatchCpp = _pu.partialBatchPaymentInProgress(psblBatch?.invoices ?? []);

    if (isBatchCpp && batchResponse?.invoices.length === 1) {
      batchResponse.invoices[0].amountToPay = isBatchCpp.amountToPay;
    }

    setPsblBatch(batchResponse);
  };

  const setPaymentAccountGUID = async (payload: ISetPaymentAccountGUIDReq) => {
    await setPaymentAccountGUIDAPI(payload);
    await getBatchDetail();

    return;
  };
  //#endregion

  return {
    isLoading,
    getBatchDetail,
    saveBatch,
    saveBatchResponse,
    setPaymentAccountGUID,
    cancelBatch,
    retryBatchPayment,
    getBatchDetailAPI,
    saveBatchAPI,
    setPaymentAccountGUIDAPI,
    cancelBatchAPI,
    retryBatchPaymentAPI,
  };
};
