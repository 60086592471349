import React, {useContext, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import {useAuth0} from '@auth0/auth0-react';
import {withLDProvider} from 'launchdarkly-react-client-sdk';
import 'react-toastify/dist/ReactToastify.css';

import './App.css';
import {AuthenticatedApp} from './AuthenticatedApp';
import UnauthenticatedApp from './UnauthenticatedApp';
import AppContext from './context/app/appContext';
import Theme from './Theme';
import {MuiThemeProvider} from '@material-ui/core/styles';
import environment from './utils/environment';
import LoadingIndicator from './components/ui/LoadingIndicator';
import {LogoName} from './components/LogoName';
import {IUser} from './model/user/User';
import {useAuth} from './services/useAuth';
import ThemeContext from './context/theme-context/ThemeContext';
import {ErrorBoundary} from './exception/ErrorBoundary';
import {ZohoContext} from './context/zoho-context/ZohoContext';

const App = (props: any) => {
  const {user, loadUserSession} = useContext(AppContext);
  const zohoChat = useContext(ZohoContext);
  const [isLoadingSession, setIsLoadingSession] = useState(false);

  const {user: auth0User, isAuthenticated, isLoading: isAuthenticating} = useAuth0();
  const auth = useAuth();
  const history = useHistory();

  useEffect(() => {
    // User session has loaded, unhiding zoho chat widget
    if (!isAuthenticating && !isLoadingSession && zohoChat) {
      zohoChat?.showFloatingButton();
    }
  }, [isAuthenticating, isLoadingSession, zohoChat]);

  useEffect(() => {
    if (isAuthenticated && auth0User) {
      setIsLoadingSession(true);
      setAuth0User();
    }

    if (!isAuthenticated && !user) {
      setIsLoadingSession(false);
    }
  }, [isAuthenticated, auth0User]);

  const setAuth0User = async () => {
    try {
      await auth.setToken(auth0User);
      const loadedSession = await loadUserSession(auth0User as IUser);

      if (!loadedSession) {
        console.error("Couldn't load user session");
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoadingSession(false);
    }
  };

  const {logo: Logo} = useContext(ThemeContext);

  if (isAuthenticating || isLoadingSession) {
    return (
      <div className="flex h-full flex-col items-center justify-center bg-spenda-newbg">
        {Logo ? <Logo /> : <LogoName />}
        <div className="flex items-center gap-2">
          <LoadingIndicator isLoading position={{position: 'relative'}} size="sm" />
          <p className="font-poppins font-light text-primary">Loading...</p>
        </div>
      </div>
    );
  }

  return (
    <>
      <MuiThemeProvider theme={Theme}>
        <ToastContainer style={{zIndex: 99999}}></ToastContainer>
        {user ? (
          <ErrorBoundary onReset={() => history.push('/menu')}>
            <AuthenticatedApp {...props} />
          </ErrorBoundary>
        ) : (
          <UnauthenticatedApp />
        )}
      </MuiThemeProvider>
    </>
  );
};

// Update the export default to use your environment-specific client ID and a user:
export default withLDProvider({
  clientSideID: environment.LD_CLIENT_ID!,
})(App);
