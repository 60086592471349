import React from 'react';

export const DownloadIconChat = (props: {width?: string; height?: string; className?: string}) => (
  <svg
    width="24px"
    height="24px"
    className={props.className}
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Download</title>
    <defs>
      <path
        d="M14.0944,0.256 C14.0245333,0.224 13.9488,0.202666667 13.8666667,0.202666667 C13.5722667,0.202666667 13.3333333,0.437333333 13.3333333,0.736 C13.3333333,1.02933333 13.5722667,1.26933333 13.8666667,1.26933333 L13.8666667,1.28 C18.7354667,2.26666667 22.4,6.57066667 22.4,11.7333333 C22.4,17.6266667 17.6245333,22.4 11.7333333,22.4 C5.84213333,22.4 1.06666667,17.6266667 1.06666667,11.7333333 C1.06666667,6.57066667 4.7312,2.26666667 9.6,1.28 L9.6,1.26933333 C9.8944,1.26933333 10.1333333,1.02933333 10.1333333,0.736 C10.1333333,0.437333333 9.8944,0.202666667 9.6,0.202666667 C9.51786667,0.202666667 9.44213333,0.224 9.37226667,0.256 C4.02666667,1.34933333 0,6.064 0,11.7333333 C0,18.2133333 5.25333333,23.4666667 11.7333333,23.4666667 C18.2133333,23.4666667 23.4666667,18.2133333 23.4666667,11.7333333 C23.4666667,6.064 19.44,1.34933333 14.0944,0.256 Z M7.46666667,10.6666667 C7.17226667,10.6666667 6.93333333,10.9066667 6.93333333,11.2 C6.93333333,11.3493333 6.99306667,11.4826667 7.0896,11.5786667 L11.3562667,15.8453333 C11.4528,15.9413333 11.5861333,16 11.7333333,16 C11.8805333,16 12.0138667,15.9413333 12.1104,15.8453333 L16.3770667,11.5786667 C16.4736,11.4826667 16.5333333,11.3493333 16.5333333,11.2 C16.5333333,10.9066667 16.2944,10.6666667 16,10.6666667 C15.8528,10.6666667 15.7194667,10.7253333 15.6229333,10.8213333 L12.2666667,14.1813333 L12.2666667,0.533333333 C12.2666667,0.24 12.0277333,-1.77635684e-15 11.7333333,-1.77635684e-15 C11.4389333,-1.77635684e-15 11.2,0.24 11.2,0.533333333 L11.2,14.1813333 L7.84373333,10.8213333 C7.7472,10.7253333 7.61386667,10.6666667 7.46666667,10.6666667 Z"
        id="path-Attach"
      ></path>
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Buyer-screen/start-conv-Copy-11" transform="translate(-1284.000000, -546.000000)">
        <g id="Group-11" transform="translate(986.000000, 268.000000)">
          <g id="Group-3" transform="translate(119.000000, 265.000000)">
            <g id="Arrows/-15" transform="translate(179.011733, 13.453333)">
              <mask id="mask-2" fill="white">
                <use xlinkHref="#path-Attach"></use>
              </mask>
              <use id="Mask" fill="#FFFFFF" xlinkHref="#path-Attach"></use>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
