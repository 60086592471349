import React from 'react';
import {Switch} from 'react-router-dom';
import {PrivateRoute} from './PrivateRoute';
import {MenuTypes, ModuleTypes} from '../model/constants/Constants';
import {APBatchUpload} from '../screens/purchasing/APBatchUpload';
import {APReports} from '../screens/purchasing/APReports';
import {APReportDetail} from '../screens/purchasing/APReportDetail';
import {APSettings} from '../screens/purchasing/APSettings';
import {APBatchApproval} from '../screens/purchasing/APBatchApproval';
import {APAuthorisePayment} from '../screens/purchasing/APAuthorisePayment';
import {APBatchView} from '../screens/purchasing/APBatchView';
import {APBatchPreview} from '../screens/purchasing/APBatchPreview';
import {Receive} from '../screens/purchasing/Receive';
import {RequiresAttention} from '../screens/purchasing/RequiresAttention';
import {RequiresAttentionInvoiceDetails} from '../screens/purchasing/RequiresAttentionInvoiceDetails';

export const AUTH_BUYING_AP_BATCH = '/accounts-payable/batch';
export const AUTH_BUYING_AP_BATCH_BATCH_VIEW = '/accounts-payable/batch/:batchID';
export const AUTH_BUYING_AP_APPROVE_REQUESTS = '/accounts-payable/approve-request';
export const AUTH_BUYING_AP_AUTHORISE_PAYMENT = '/accounts-payable/authorise-payment';
export const AUTH_BUYING_AP_APPROVE_REQUESTS_BATCH_VIEW = '/accounts-payable/approve-request/:batchID';
export const AUTH_BUYING_AP_APPROVE_REQUESTS_ABA_BATCH_VIEW = '/accounts-payable/approve-request/aba/:batchID';
export const AUTH_BUYING_AP_AUTHORISE_PAYMENT_BATCH_VIEW = '/accounts-payable/authorise-payment/:batchID';
export const AUTH_BUYING_AP_AUTHORISE_PAYMENT_ABA_BATCH_VIEW = '/accounts-payable/authorise-payment/aba/:batchID';
export const AUTH_BUYING_AP_REPORTS = '/accounts-payable/reports';
export const AUTH_BUYING_AP_REPORTS_VIEW_DETAILS = '/accounts-payable/reports/detail/:batchID';
export const AUTH_BUYING_AP_USER_SETTINGS = '/accounts-payable/settings';
export const AUTH_BUYING_AP_REQUIRES_ATTENTION_ROUTE = '/accounts-payable/requires-attention';
export const AUTH_BUYING_AP_REQUIRES_ATTENTION_INVOICE_DETAILS_ROUTE =
  '/accounts-payable/requires-attention/invoice/:invoiceID';
export const AUTH_BUYING_AP_REQUIRES_ATTENTION_DEBIT_NOTE_DETAILS_ROUTE =
  '/accounts-payable/requires-attention/debit-notes/:invoiceID';
export const AUTH_PURCHASING_PAY_ROUTE = '/accounts-payable';
export const AUTH_PURCHASING_PAY_BILL_ROUTE = '/accounts-payable/bill';
export const AUTH_PURCHASING_PAY_BILL_EDIT_ROUTE = '/accounts-payable/bill/:batchID';
export const AUTH_PURCHASING_PAY_BILL_PREVIEW = '/accounts-payable/bill/preview/:batchID';
export const AUTH_XERO_ADAPTER_SYNC_DETAILS = '/accounts-payable/settings/sync-details/:adaptorLogId';
import {FeatureFlagKeys} from '../hooks/useFeatureFlags';

export const AccountsPayableRoutes = () => {
  return (
    <Switch>
      <PrivateRoute
        path={AUTH_PURCHASING_PAY_ROUTE}
        exact
        moduleIds={[ModuleTypes.SpendaPay]}
        Component={Receive}
        MenuFilters={{
          MenuType: MenuTypes.Buying,
          ModuleID: ModuleTypes.SpendaPay,
          ModuleDependency: [ModuleTypes.SpendaPay],
        }}
      />
      <PrivateRoute
        path={AUTH_PURCHASING_PAY_BILL_ROUTE}
        exact
        moduleIds={[ModuleTypes.SpendaPay]}
        Component={Receive}
        MenuFilters={{
          MenuType: MenuTypes.Buying,
          ModuleID: ModuleTypes.SpendaPay,
          ModuleDependency: [ModuleTypes.SpendaPay],
        }}
      />
      <PrivateRoute
        path={AUTH_BUYING_AP_REQUIRES_ATTENTION_ROUTE}
        exact
        moduleIds={[ModuleTypes.SpendaPay]}
        Component={RequiresAttention}
        MenuFilters={{
          MenuType: MenuTypes.Buying,
          ModuleID: ModuleTypes.SpendaPay,
          ModuleDependency: [ModuleTypes.SpendaPay],
        }}
      />
      <PrivateRoute
        path={AUTH_BUYING_AP_REQUIRES_ATTENTION_INVOICE_DETAILS_ROUTE}
        exact
        moduleIds={[ModuleTypes.SpendaPay]}
        Component={RequiresAttentionInvoiceDetails}
        MenuFilters={{
          MenuType: MenuTypes.Buying,
          ModuleID: ModuleTypes.SpendaPay,
          ModuleDependency: [ModuleTypes.SpendaPay],
        }}
        featureFlag={FeatureFlagKeys.T2TPhase280729}
      />
      <PrivateRoute
        path={AUTH_BUYING_AP_REQUIRES_ATTENTION_DEBIT_NOTE_DETAILS_ROUTE}
        exact
        moduleIds={[ModuleTypes.SpendaPay]}
        Component={RequiresAttentionInvoiceDetails}
        MenuFilters={{
          MenuType: MenuTypes.Buying,
          ModuleID: ModuleTypes.SpendaPay,
          ModuleDependency: [ModuleTypes.SpendaPay],
        }}
        featureFlag={FeatureFlagKeys.T2TPhase280729}
      />
      <PrivateRoute
        path={AUTH_PURCHASING_PAY_BILL_EDIT_ROUTE}
        exact
        moduleIds={[ModuleTypes.SpendaPay]}
        Component={Receive}
        MenuFilters={{
          MenuType: MenuTypes.Buying,
          ModuleID: ModuleTypes.SpendaPay,
          ModuleDependency: [ModuleTypes.SpendaPay],
        }}
      />
      <PrivateRoute
        path={AUTH_PURCHASING_PAY_BILL_PREVIEW}
        exact
        moduleIds={[ModuleTypes.SpendaPay]}
        Component={APBatchPreview}
        MenuFilters={{
          MenuType: MenuTypes.Buying,
          ModuleID: ModuleTypes.SpendaPay,
          ModuleDependency: [ModuleTypes.SpendaPay],
        }}
      />
      <PrivateRoute
        path={AUTH_BUYING_AP_BATCH}
        exact
        moduleIds={[ModuleTypes.SpendaPay]}
        Component={APBatchUpload}
        MenuFilters={{
          MenuType: MenuTypes.Buying,
          ModuleID: ModuleTypes.SpendaPay,
          ModuleDependency: [ModuleTypes.SpendaPay],
        }}
      />
      <PrivateRoute
        path={AUTH_BUYING_AP_REPORTS}
        exact
        moduleIds={[ModuleTypes.SpendaPay]}
        Component={APReports}
        MenuFilters={{
          MenuType: MenuTypes.Buying,
          ModuleID: ModuleTypes.SpendaPay,
          ModuleDependency: [ModuleTypes.SpendaPay],
        }}
      />
      <PrivateRoute
        path={AUTH_BUYING_AP_REPORTS_VIEW_DETAILS}
        exact
        moduleIds={[ModuleTypes.SpendaPay]}
        Component={APReportDetail}
        MenuFilters={{
          MenuType: MenuTypes.Buying,
          ModuleID: ModuleTypes.SpendaPay,
          ModuleDependency: [ModuleTypes.SpendaPay],
        }}
      />
      <PrivateRoute
        path={AUTH_BUYING_AP_USER_SETTINGS}
        exact
        moduleIds={[ModuleTypes.SpendaPay]}
        Component={APSettings}
        MenuFilters={{
          MenuType: MenuTypes.Buying,
          ModuleID: ModuleTypes.SpendaPay,
          ModuleDependency: [ModuleTypes.SpendaPay],
        }}
      />
      <PrivateRoute
        path={AUTH_XERO_ADAPTER_SYNC_DETAILS}
        exact
        moduleIds={[ModuleTypes.SpendaPay]}
        Component={APSettings}
        MenuFilters={{
          MenuType: MenuTypes.Buying,
          ModuleID: ModuleTypes.SpendaPay,
          ModuleDependency: [ModuleTypes.SpendaPay],
        }}
      />
      <PrivateRoute
        path={AUTH_BUYING_AP_APPROVE_REQUESTS}
        exact
        moduleIds={[ModuleTypes.SpendaPay]}
        Component={APBatchApproval}
        MenuFilters={{
          MenuType: MenuTypes.Buying,
          ModuleID: ModuleTypes.SpendaPay,
          ModuleDependency: [ModuleTypes.SpendaPay],
        }}
      />
      <PrivateRoute
        path={AUTH_BUYING_AP_AUTHORISE_PAYMENT}
        exact
        moduleIds={[ModuleTypes.SpendaPay]}
        Component={APAuthorisePayment}
        MenuFilters={{
          MenuType: MenuTypes.Buying,
          ModuleID: ModuleTypes.SpendaPay,
          ModuleDependency: [ModuleTypes.SpendaPay],
        }}
      />
      <PrivateRoute
        key={AUTH_BUYING_AP_APPROVE_REQUESTS_ABA_BATCH_VIEW}
        path={AUTH_BUYING_AP_APPROVE_REQUESTS_ABA_BATCH_VIEW}
        exact
        moduleIds={[ModuleTypes.SpendaPay]}
        Component={APBatchView}
        MenuFilters={{
          MenuType: MenuTypes.Buying,
          ModuleID: ModuleTypes.SpendaPay,
          ModuleDependency: [ModuleTypes.SpendaPay],
        }}
      />
      <PrivateRoute
        key={AUTH_BUYING_AP_APPROVE_REQUESTS_BATCH_VIEW}
        path={AUTH_BUYING_AP_APPROVE_REQUESTS_BATCH_VIEW}
        exact
        moduleIds={[ModuleTypes.SpendaPay]}
        Component={APBatchPreview}
        MenuFilters={{
          MenuType: MenuTypes.Buying,
          ModuleID: ModuleTypes.SpendaPay,
          ModuleDependency: [ModuleTypes.SpendaPay],
        }}
      />
      <PrivateRoute
        key={AUTH_BUYING_AP_AUTHORISE_PAYMENT_ABA_BATCH_VIEW}
        path={AUTH_BUYING_AP_AUTHORISE_PAYMENT_ABA_BATCH_VIEW}
        exact
        moduleIds={[ModuleTypes.SpendaPay]}
        Component={APBatchView}
        MenuFilters={{
          MenuType: MenuTypes.Buying,
          ModuleID: ModuleTypes.SpendaPay,
          ModuleDependency: [ModuleTypes.SpendaPay],
        }}
      />
      <PrivateRoute
        key={AUTH_BUYING_AP_AUTHORISE_PAYMENT_BATCH_VIEW}
        path={AUTH_BUYING_AP_AUTHORISE_PAYMENT_BATCH_VIEW}
        exact
        moduleIds={[ModuleTypes.SpendaPay]}
        Component={APBatchPreview}
        MenuFilters={{
          MenuType: MenuTypes.Buying,
          ModuleID: ModuleTypes.SpendaPay,
          ModuleDependency: [ModuleTypes.SpendaPay],
        }}
      />
      <PrivateRoute
        path={AUTH_BUYING_AP_BATCH_BATCH_VIEW}
        exact
        moduleIds={[ModuleTypes.SpendaPay]}
        Component={APBatchView}
        MenuFilters={{
          MenuType: MenuTypes.Buying,
          ModuleID: ModuleTypes.SpendaPay,
          ModuleDependency: [ModuleTypes.SpendaPay],
        }}
      />
    </Switch>
  );
};
