import useConnectedSupplierAPI from '../../../services/useConnectedSuppliersAPI';
import {IInvoice} from '../../../model/invoice/Invoice';
import {useSupplierTransactionsAPI} from '../../../services/useSupplierTransactionsAPI';
import {
  AccountUsage,
  DatTypes,
  InvoicePaymentAllocationStatus,
  PaymentMethodTypes,
  PaymentProviderName,
  PaymentWidgetScope,
} from '../../../model/constants/Constants';
import {MerchantSurcharge, IMerchantProcessingFee, IRefBusTrans} from '../../../model/payment/PaymentT';
import useSpendaPaymentServicesAPI, {
  IAccountResponse,
  IAirPlusAccountResponse,
  INewAccountResponse,
  INewAirPlusAccountRequest,
  INewCreditCardAccountRequest,
  INewCreditCardAccountResponse,
  UpdateAccountRequest,
} from '../../../services/useSpendaPaymentServicesAPI';
import {ICreditCardT, ITenantSupplierPaymentMethod} from '../../../model/payment/PaymentMethod';
import {IAddBankAccountDetailsRequest} from '../../../services/useSpendaPaymentServicesAPI';
import {IActionResults} from '../../../model/ActionResults';
import {ISupplierPaymentResponse} from '../../../model/payment/SupplierPaymentResponse';
import {IARPaymentRequest, IRepaymentRequest} from '../../../model/accounts-receivable/AccountsReceivable';
import {useAccountReceivablePaymentAPI} from '../../../services/useAccountReceivablePaymentAPI';
import {IAppliedCredit} from '../../../model/credit-notes/CreditClaims';
import {useCreditClaimsAPI} from '../../../services/useCreditClaimsAPI';
import {IGetSurchargeProps} from '../../../model/payment-widget/PaymentWidget';
import {useContext} from 'react';
import ARContext from '../../../context/ar-context/ARContext';
import {usePaymentUtilities} from '../../../hooks/usePaymentUtilities';
import {netPayableAmount} from '../../../utils/formatter';
import moment from 'moment';
import {IARBatchResponse} from '../../../model/accounts-receivable/batch';
import {IAirPlusDbiData} from '../../../model/payment/AirPlusDBI';

interface IPaymentFromBuyer {
  linkedSupplierId?: number;
  paymentAuth72488?: boolean;
  prepayments72511?: boolean;
  scheduledPayments83107?: boolean;
  airplus79131?: boolean;
  fees88078?: boolean;
}

export const usePaymentsFromBuyer = (props?: IPaymentFromBuyer) => {
  const {scheduledPayments83107, prepayments72511, paymentAuth72488, airplus79131, linkedSupplierId, fees88078} =
    props || {};

  const {
    getPaymentMethods: getPaymentMethodsAPI,
    savePaymentMethod: saveCreditCardAPI,
    deletePaymentMethod: deletePaymentMethodAPI,
    updatePaymentMethodDetails: updatePaymentMethodDetailsAPI,
  } = useConnectedSupplierAPI();

  const {
    saveCreditCard: saveBPSPCreditCardAPI,
    addBankAccount: addBankTransferPayment,
    saveAirPlusAccount: saveAirPlusAccountAPI,
    refreshAccountState: bankTransferAccountState,
    getAccount,
    initiateVerification: initiateVerificationAPI,
    verify: codeVerificationAPI,
    deleteBankAccount: deleteSPSPaymentAccount,
    retryVerification: onRetryVerificationAPI,
    updateAccountDetails: updateAccountDetailsAPI,
    addBankAccountDetails: addBankAccountDetailsAPI,
    isLoading: isLoadingSpendaPaymentServices,
  } = useSpendaPaymentServicesAPI();

  const {
    ARBuyerPayment: ARBuyerPaymentAPI,
    ARBuyercheckBatchPaymentStatus: ARBuyercheckBatchPaymentStatusAPI,
    makeFacilityRepayment,
    checkLendingFacilityRepaymentStatus,
  } = useAccountReceivablePaymentAPI();

  const {psblBatch} = useContext(ARContext);
  const {
    totalCreditClaimsPrepaymentsSelectedAmount,
    totalInvoiceAmount,
    selectedTxList,
    invoicePaymentAllocationID,
    versionDateTimeUtc,
  } = psblBatch;
  const invoices = selectedTxList?.filter(a => a.transactionTypeID === DatTypes.Invoice);
  const _pu = usePaymentUtilities();
  const isBatchPartialPayment = _pu.arBatchPartialPaymentInProgress(invoices ?? []);

  const {getMerchantSurcharge: getSurchargeAPI, getMerchantProcessingFee: getMerchantProcessingFeeAPI} =
    useSupplierTransactionsAPI();

  useCreditClaimsAPI();

  const getPaymentMethodsForSupplier: () => Promise<ITenantSupplierPaymentMethod[]> = async () => {
    if (!linkedSupplierId) return [];
    const res = await getPaymentMethodsAPI(linkedSupplierId);
    return res.IsSuccess ? res.Value : [];
  };

  const saveCreditCard: (
    data: ICreditCardT,
    isBPSPMerchant?: boolean,
  ) => Promise<ITenantSupplierPaymentMethod | INewCreditCardAccountResponse | undefined> = async (
    data: ICreditCardT,
    isBPSPMerchant?: boolean,
  ) => {
    if (!linkedSupplierId) return undefined;

    if (
      isBPSPMerchant &&
      data.CardType &&
      [PaymentMethodTypes.Visa, PaymentMethodTypes.Mastercard].some(pm => pm.toString() === data.CardType)
    ) {
      // Call BPSP endpoint to save card
      // TODO: SaveCard for BPSP has different request payload than Fiserv
      const bpspres = await saveBPSPCreditCardAPI(PaymentProviderName.Spenda_Payment_Services, {
        cardType: data.CardType,
        cardNumber: data.CardNumber,
        cardholderName: data.CardHolderName,
        expiry: data.ExpiryMMYY,
        ccv: data.CardVerificationNumber,
        isInvigoDefault: data.IsInvigoDefault,
      } as INewCreditCardAccountRequest);

      return bpspres;
    } else {
    }

    const res = await saveCreditCardAPI(linkedSupplierId, data);
    return res.Value;
  };

  const addBankTransfer = async (): Promise<INewAccountResponse> => {
    return addBankTransferPayment(AccountUsage.BUYER, PaymentProviderName.Spenda_Payment_Services);
  };

  const saveAirPlusAccount = async (data: INewAirPlusAccountRequest): Promise<IAirPlusAccountResponse> => {
    return saveAirPlusAccountAPI(PaymentProviderName.Spenda_Payment_Services, data);
  };

  const checkBankTransferAccountState = async (
    accountGUID: string,
    agreementRefNum: string,
  ): Promise<IAccountResponse> => {
    return bankTransferAccountState(PaymentProviderName.Spenda_Payment_Services, accountGUID, agreementRefNum);
  };

  const removeCreditCard: (paymentOption: ITenantSupplierPaymentMethod) => Promise<boolean | undefined> = async (
    paymentOption: ITenantSupplierPaymentMethod,
  ) => {
    if (!linkedSupplierId || !paymentOption.SupplierPaymentOptionID) return false;

    if (paymentOption.PaymentAccountGUID) {
      return deleteSPSPaymentAccount(PaymentProviderName.Spenda_Payment_Services, paymentOption.PaymentAccountGUID);
    } else {
      return deletePaymentMethodAPI(linkedSupplierId, paymentOption.SupplierPaymentOptionID);
    }
  };

  const getSurcharge: (
    props: IGetSurchargeProps,
  ) => Promise<MerchantSurcharge | IMerchantProcessingFee | undefined> = async (props: IGetSurchargeProps) => {
    if (!linkedSupplierId) return undefined;

    let {refBusTrans, ccType, nonInvoicePaymentAmount, isPayerFeeApplicable} = props;
    if (!refBusTrans) {
      refBusTrans = [];
    }
    if (isPayerFeeApplicable) {
      let paymentAmount;
      if (prepayments72511 && nonInvoicePaymentAmount) {
        paymentAmount = nonInvoicePaymentAmount;
      } else {
        paymentAmount = refBusTrans?.reduce((amt, trans) => amt + (trans.AppliedAmount || 0) * 100, 0) / 100;
        paymentAmount = parseFloat(paymentAmount.toFixed(2));
      }

      return getMerchantProcessingFeeAPI(linkedSupplierId, ccType || '', paymentAmount as number);
    }

    return getSurchargeAPI(linkedSupplierId, ccType || PaymentMethodTypes.Unknown, refBusTrans);
  };

  const getInvoiceAmount = (inv: Partial<IInvoice>): number | undefined => {
    if (!inv.AmountToPay || inv.AmountToPay === inv.Balance) {
      return undefined;
    }

    return inv.AmountToPay;
  };

  const getPSBLSurcharge = async (
    selectedPaymentMethod: ITenantSupplierPaymentMethod,
    selectedTransactions: IARBatchResponse,
  ) => {
    let paymentMethod: string | undefined = PaymentMethodTypes.Unknown;
    if (selectedPaymentMethod) {
      // Saved Card
      paymentMethod = selectedPaymentMethod?.PaymentMethod || PaymentMethodTypes.Unknown;
    } else {
      // Once-off Payment
      return;
    }

    let paymentAmount = 0;

    if (paymentMethod === PaymentMethodTypes.Unknown || !linkedSupplierId) {
      return undefined;
    }

    const getProcessingFee = selectedPaymentMethod?.IsPayerFeeApplicable || false;

    if (isBatchPartialPayment && !getProcessingFee) {
      const refBusTrans =
        invoices.map(i => {
          return {
            ID: i.id,
            AppliedDate: moment().local().format(),
            AppliedAmount: i.amountToPay || i.balance, // It is not invoice's balance, it should be Split payment AmountToPay
            IsSyncBackRequired: false,
            IsUpdate: false,
          } as IRefBusTrans;
        }) || [];

      const result = await getSurchargeAPI(linkedSupplierId, paymentMethod, refBusTrans);
      return result;
    }

    if (getProcessingFee) {
      if (isBatchPartialPayment) {
        paymentAmount = invoices ? invoices?.reduce((amt, trans) => amt + (trans.amountToPay || 0) * 100, 0) / 100 : 0;

        paymentAmount = parseFloat(paymentAmount?.toFixed(2));
      } else {
        paymentAmount =
          (netPayableAmount(totalInvoiceAmount!, totalCreditClaimsPrepaymentsSelectedAmount!) * 100) / 100;
      }
    } else {
      return undefined;
    }

    if (paymentAmount === 0) {
      return undefined;
    }
    const paymentAmountWithMerchantFee = (paymentAmount + (selectedTransactions?.merchantSurcharge || 0)).toFixed(2);
    const result = await getMerchantProcessingFeeAPI(
      linkedSupplierId,
      paymentMethod,
      parseFloat(paymentAmountWithMerchantFee),
    );
    return result;
  };

  const submitFacilityRepayment = async (facilityGuid: string, repaymentPayload: IRepaymentRequest) => {
    const res = await makeFacilityRepayment(facilityGuid, repaymentPayload);
    const {value} = res ?? {};

    const formatRes: IActionResults<ISupplierPaymentResponse> = {
      IsSuccess: true,
      Messages: [],
      Value: {
        RefNumber: value.refNumber,
        WorkflowStatus: value.repaymentStatus as InvoicePaymentAllocationStatus, //repaymentStatus
        ErrorMessage: value.errorMessage,
        paymentGUID: value.repaymentGUID, //repaymentGUID
      },
    };
    return formatRes;
  };

  const submitPaymentAllocationV2 = async (
    pm: ITenantSupplierPaymentMethod,
    merchantSurcharge: MerchantSurcharge | IMerchantProcessingFee | undefined,
    invoicesToPay: Partial<IInvoice>[],
    _temporalCard?: ICreditCardT,
    isCreditNotesAppliedGreaterThanInvoiceTotal?: boolean,
    creditStatementSummary?: IAppliedCredit | undefined,
    isLoyaltyPointsToggleOn?: boolean,
    authorisationToken?: string,
    nonInvoicePaymentAmount?: number,
    paymentDescription?: string,
    widgetScope?: PaymentWidgetScope,
    facilityGuid?: string,
    scheduledPaymentDate?: moment.Moment,
    airPlusDbiData?: IAirPlusDbiData | null,
    psblBatchAPIResponse?: IARBatchResponse,
  ) => {
    if (!pm && !isCreditNotesAppliedGreaterThanInvoiceTotal) {
      // There should always be at least one selected payment method at this point
      return Promise.reject(`Payment method hasn't been selected`);
    }

    const getSurchargeAmount = () => {
      if (fees88078) {
        return !pm?.IsPayerFeeApplicable ? pm?.Fees?.find(f => f?.feeName === 'Merchant')?.buyerFeeAmount || 0 : 0;
      }
      return !pm?.IsPayerFeeApplicable ? (merchantSurcharge as MerchantSurcharge)?.SurchargeAmount || 0 : 0;
    };

    const getTransactionFeeAmount = () => {
      if (fees88078) {
        return pm?.IsPayerFeeApplicable ? pm?.Fees?.find(f => f?.feeName === 'Buyer')?.buyerFeeAmount || 0 : 0;
      }
      return pm?.IsPayerFeeApplicable ? (merchantSurcharge as IMerchantProcessingFee)?.PayerFeeAmount || 0 : 0;
    };

    let paymentAmount = fees88078 ? pm?.TotalAmount || 0 : (merchantSurcharge as MerchantSurcharge)?.TotalAmount;

    paymentAmount = pm.IsPayerFeeApplicable
      ? fees88078
        ? pm?.PaymentAmount || 0
        : merchantSurcharge?.PaymentAmount || 0
      : paymentAmount;

    if (widgetScope === PaymentWidgetScope.SPENDA_FINANCE) {
      return submitFacilityRepayment(facilityGuid as string, {
        paymentAccountGUID: pm?.PaymentAccountGUID!,
        authorisationToken: authorisationToken ?? '',
        amount: paymentAmount,
        payerFeeAmount: getTransactionFeeAmount(),
        payerFeeDescription: '',
        payeeTransactionFeeAmount: 0,
        paymentMethod: pm?.PaymentMethod as PaymentMethodTypes,
      });
    }

    let req: IARPaymentRequest = {
      linkedSupplierID: linkedSupplierId,
    };

    if (paymentAuth72488 && authorisationToken) {
      req.authorisation = {
        token: authorisationToken,
      };
    }

    if (widgetScope !== PaymentWidgetScope.PSBL) {
      req = {
        ...req,
        invoices: (invoicesToPay || []).map(i => {
          return {
            id: i.ID,
            appliedAmount: getInvoiceAmount(i),
          };
        }),
        creditNotes: (creditStatementSummary?.CreditNotes || []).map(cr => {
          return {
            id: cr.TransID,
          };
        }),
        claims: (creditStatementSummary?.ClaimDetails || []).map(c => {
          return {id: c.ID};
        }),
      };
    } else {
      if (!isBatchPartialPayment) {
        req.invoiceBatch = {
          invoicePaymentAllocationID: fees88078
            ? psblBatchAPIResponse?.invoicePaymentAllocationID
            : invoicePaymentAllocationID,
          versionDateTimeUtc: fees88078 ? psblBatchAPIResponse?.versionDateTimeUtc : versionDateTimeUtc,
        };
      } else {
        req = {
          ...req,
          invoices: (invoices || []).map(i => {
            return {
              id: i.id,
              appliedAmount: i.amountToPay,
            };
          }),
        };
      }
    }

    if (paymentAmount > 0) {
      req.payment = {
        amount: paymentAmount,
        surchargeAmount: getSurchargeAmount(),
        transactionFeeAmount: getTransactionFeeAmount(),
        paymentMethod: pm?.PaymentMethod || undefined,
        isLoyaltyPoints: isLoyaltyPointsToggleOn,
      };

      if (prepayments72511 && (nonInvoicePaymentAmount || 0) > 0) {
        req.payment = {
          ...req.payment,
          isPrepayment: true,
          paymentDescription,
        };
      }

      // Schedule Payment date added to the request
      if (
        scheduledPayments83107 &&
        scheduledPaymentDate &&
        [PaymentWidgetScope.PSBL, PaymentWidgetScope.PIBL, PaymentWidgetScope.PREPAYMENT]?.includes(
          widgetScope as PaymentWidgetScope,
        )
      ) {
        req.payment = {
          ...req.payment,
          scheduledDateTimeUtc: moment(scheduledPaymentDate).format('YYYY-MM-DDT00:00:00'),
        };
      }

      if (airplus79131 && pm.PaymentMethod === PaymentMethodTypes.Airplus && airPlusDbiData !== undefined) {
        req.payment = {
          ...req.payment,
          metadata: {
            airplusDetails: [
              {
                paymentAccountGUID: pm.PaymentAccountGUID!,

                dbiFields: airPlusDbiData,
              },
            ],
          },
        };
      }

      req.payment = {
        ...req.payment,
        paymentAccount: {
          paymentAccountGUID: pm?.PaymentAccountGUID!,
        },
      };
    }

    const res = await ARBuyerPaymentAPI(req);

    const formatRes: IActionResults<ISupplierPaymentResponse> = {
      IsSuccess: true,
      Messages: [],
      Value: {
        PaymentAllocationID: res.invoicePaymentAllocationID,
        RefNumber: res.refNumber,
        WorkflowStatus: res.status as InvoicePaymentAllocationStatus,
        ErrorMessage: res.logMessage,
        paymentGUID: res.paymentGUID,
      },
    };

    return formatRes;
  };

  const submitPayment = async (
    pm: ITenantSupplierPaymentMethod,
    merchantSurcharge: MerchantSurcharge | IMerchantProcessingFee | undefined,
    invoicesToPay: Partial<IInvoice>[],
    temporalCard?: ICreditCardT,
    isCreditNotesAppliedGreaterThanInvoiceTotal?: boolean,
    creditStatementSummary?: IAppliedCredit | undefined,
    isLoyaltyPointsToggleOn?: boolean,
    authorisationToken?: string,
    nonInvoicePaymentAmount?: number,
    paymentDescription?: string,
    widgetScope?: PaymentWidgetScope,
    facilityGuid?: string,
    scheduledPaymentDate?: moment.Moment,
    airPlusDbiData?: IAirPlusDbiData | null,
    psblBatchAPIResponse?: IARBatchResponse,
  ) => {
    return submitPaymentAllocationV2(
      pm,
      merchantSurcharge,
      invoicesToPay,
      temporalCard,
      isCreditNotesAppliedGreaterThanInvoiceTotal,
      creditStatementSummary,
      isLoyaltyPointsToggleOn,
      authorisationToken,
      nonInvoicePaymentAmount,
      paymentDescription,
      widgetScope,
      facilityGuid,
      scheduledPaymentDate,
      airPlusDbiData,
      psblBatchAPIResponse,
    );
  };

  const ARcheckBatchPaymentStatus = async (invoicePaymentAllocationID: number, supplierID?: number) => {
    const result = await ARBuyercheckBatchPaymentStatusAPI(supplierID ?? linkedSupplierId!, invoicePaymentAllocationID);
    const formatedResult = {
      IsSuccess: true,
      Messages: [],
      Value: {
        PaymentAllocationID: result.invoicePaymentAllocationID,
        RefNumber: result.refNumber,
        PaymentStatus: result.status,
        ErrorMessage: result.logMessage,
        WorkflowStatus: result.status,
        paymentGUID: result.paymentGUID,
        ScheduledDateTimeUtc: result.scheduledDateTimeUtc,
      },
    } as IActionResults<ISupplierPaymentResponse>;
    return Promise.resolve(formatedResult);
  };

  const fetchRepaymentStatus = async (repaymentGUID: string, facilityGuid: string) => {
    const result = await checkLendingFacilityRepaymentStatus(facilityGuid, repaymentGUID);
    const {value} = result ?? {};
    const formatedResult = {
      IsSuccess: true,
      Messages: [],
      Value: {
        RefNumber: value.refNumber,
        PaymentStatus: value.repaymentStatus,
        ErrorMessage: value.errorMessage,
        WorkflowStatus: value.repaymentStatus,
        paymentGUID: value.repaymentGUID,
      },
    } as IActionResults<ISupplierPaymentResponse>;
    return Promise.resolve(formatedResult);
  };

  const fetchSPSAccount: (accountGUI: string) => Promise<IAccountResponse> = async (accountGUID: string) => {
    return getAccount(PaymentProviderName.Spenda_Payment_Services, accountGUID);
  };

  const initiateVerification: (accountGUID: string) => Promise<IAccountResponse> = (accountGUID: string) => {
    return initiateVerificationAPI(PaymentProviderName.Spenda_Payment_Services, accountGUID);
  };

  const onVerifyCode: (code: string, accountGUID: string) => Promise<IAccountResponse> = (
    code: string,
    accountGUID: string,
  ) => {
    return codeVerificationAPI(PaymentProviderName.Spenda_Payment_Services, accountGUID, {verificationCode: code});
  };

  const onRetryVerification: (accountGUID: string) => Promise<IAccountResponse> = (accountGUID: string) => {
    return onRetryVerificationAPI(PaymentProviderName.Spenda_Payment_Services, accountGUID);
  };

  const updatePaymentMethodDetails: (supplierPaymentOptionID: string, req: UpdateAccountRequest) => Promise<any> = (
    supplierPaymentOptionID: string,
    req: UpdateAccountRequest,
  ) => {
    return updatePaymentMethodDetailsAPI(linkedSupplierId!.toString(), supplierPaymentOptionID, req);
  };

  const updateAccountDetails: (accountGUID: string, req: UpdateAccountRequest) => Promise<any> = (
    accountGUID: string,
    req: UpdateAccountRequest,
  ) => {
    return updateAccountDetailsAPI(PaymentProviderName.Spenda_Payment_Services, accountGUID, req);
  };

  const addBankAccountDetails = (payload: IAddBankAccountDetailsRequest): Promise<INewAccountResponse> => {
    return addBankAccountDetailsAPI(payload, AccountUsage.BUYER, PaymentProviderName.Spenda_Payment_Services);
  };

  return {
    getPaymentMethodsForSupplier,
    addBankTransfer,
    checkBankTransferAccountState,
    saveCreditCard,
    saveAirPlusAccount,
    removeCreditCard,
    getSurcharge,
    submitPayment,
    fetchSPSAccount,
    initiateVerification,
    onVerifyCode,
    onRetryVerification,
    updateAccountDetails,
    updatePaymentMethodDetails,
    addBankAccountDetails,
    ARcheckBatchPaymentStatus,
    isLoadingSpendaPaymentServices,
    getPSBLSurcharge,
    fetchRepaymentStatus,
  };
};
