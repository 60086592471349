import React, {useContext, FunctionComponent, useEffect, ChangeEvent, useState} from 'react';
import PurchasingContext from '../../context/purchasing/purchasingContext';
import {css} from 'glamor';
import {IRequisition, IRequisitionLine} from '../../model/requisition/Requisition';
import AppContext from '../../context/app/appContext';
import {AlertDialogSlide} from '../dialog/AlertDialogSlide';
import {UpdateTemplateDialog} from '../dialog/UpdateTemplateDialog';
import {TransactionLineItems} from '../transactions/TransactionLineItems';
import {PriceFormat} from '../../utils/formatter';
import {Toast} from '../../utils/Toast';
import {SupplierInventory} from '../../model/inventory/SupplierInventory';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import TuneIcon from '@material-ui/icons/Tune';
import useTemplateAPI from '../../services/useTemplateAPI';
import {IRequisitionTemplate} from '../../model/template/IRequisitionTemplate';
import CreateTemplateDialog from '../dialog/CreateTemplateDialog';
import {SButton} from '../buttons/Button';

interface IRequisitionEditPanelProps {
  visible?: boolean;
  requisition: Partial<IRequisition> | undefined;
}

export const RequisitionEditPanel: FunctionComponent<IRequisitionEditPanelProps> = ({requisition}) => {
  const ctx = useContext(PurchasingContext);
  const appCtx = useContext(AppContext);

  const templateAPI = useTemplateAPI();

  const labelStyles = css({
    // fontFamily: "Saira Extra Condensed",
    fontWeight: 'bold',
    fontSize: '1.5rem',
    whiteSpace: 'nowrap',
    '@media(min-width: 1280px)': {
      fontSize: '2rem',
    },
  });

  const [isShowDeleteConfirmation, setIsShowDeleteConfirmation] = useState(false);
  const [isShowModifyConfirmation, setIsShowModifyConfirmation] = useState(false);
  const [isShowSaveTemplateDialog, setIsShowSaveTemplateDialog] = useState(false);
  const [isShowUpdateTemplateDialog, setIsShowUpdateTemplateDialog] = useState(false);
  const [hasModifiedLines, setHasModifiedLines] = useState(false);

  const onChangeQuantity = (e: ChangeEvent<HTMLInputElement>, line: IRequisitionLine) => {
    const item: SupplierInventory = {
      SKU: line.SKU,
      StandardSellPriceEx: line.StandardSellPriceEx,
      ShortDescription: line.ShortDescription,
    };

    ctx.addOrUpdateOrderLine(item, undefined, parseFloat(e.target.value));
  };

  const onRemoveLine = (line: IRequisitionLine, isAvailable?: boolean) => {
    ctx.removeLine(line, isAvailable);
  };

  const onDeleteRequisition = () => {
    // ctx.setRequisition(undefined);
    ctx.deleteRequisition(requisition);
  };

  const onFinaliseRequisition = () => {
    ctx.updateRequisitionStatus(requisition, 'Processed');
  };

  const onConfirmPrices = () => {
    if (!requisition || !requisition.Lines) {
      return;
    }

    const ZeroQtyLines = requisition.Lines.find(l => !l.Quantity || l.Quantity <= 0);

    if (ZeroQtyLines) {
      Toast.info(`Please provide a valid Quantity for Item Code ${ZeroQtyLines.Code}`);
      return;
    }

    ctx.saveRequisitionLines();
  };

  const onSaveAndClose = () => {
    setIsShowModifyConfirmation(false);
    ctx.setRequisition(requisition, true, true);
  };

  const onUpdateTemplate = async (template: Partial<IRequisitionTemplate>) => {
    if (!requisition || !requisition.Name || !requisition.ID) {
      return;
    }

    // 1. Save pending changes to requisition if any
    if (hasModifiedLines) {
      ctx.saveRequisitionLines();
    }

    // 2. Save/Update Template
    const templateToUpdate: Partial<IRequisitionTemplate> = Object.assign(template, {RequisitionID: requisition.ID});
    templateAPI.save(templateToUpdate).then((res: IRequisitionTemplate | undefined) => {
      if (res) {
        Toast.info(`Template "${templateToUpdate.Name}" ${templateToUpdate.ID ? 'updated' : 'created'}`);

        // if (templateToUpdate.ID) {
        // } else {
        requisition.RequisitionTemplateID = res.ID;
        ctx.setRequisition(requisition);
        setIsShowSaveTemplateDialog(false);
        setIsShowUpdateTemplateDialog(false);
        // }
      }
    });
  };

  useEffect(() => {
    if (requisition && requisition.Lines && requisition.Lines.length) {
      const hml = requisition.Lines.some(line => line.IsModified);
      setHasModifiedLines(hml);
    }

    if (!requisition) {
      setIsShowDeleteConfirmation(false);
    }
  }, [requisition]);

  useEffect(() => {
    if (ctx.productToAdd) {
      ctx.addOrUpdateOrderLine(ctx.productToAdd, appCtx.marketplaceSupplier);
    }
  }, [ctx.productToAdd, ctx.requisition, appCtx.marketplaceSupplier]);

  const calculatedSubTotal = () => {
    let st = requisition && requisition.TotalEx;

    if (hasModifiedLines && requisition && requisition.Lines) {
      st = requisition.Lines.filter(l => l.IsActive).reduce((prev, curr) => {
        return prev + (curr.Quantity || 0) * (curr.CostPriceEx || 0);
      }, 0);
    }

    return st && isNaN(st) ? 0 : st && PriceFormat(st);
  };

  const onClickBackButton = () => {
    if (hasModifiedLines) {
      setIsShowModifyConfirmation(true);
    } else {
      ctx.setRequisition(undefined);
    }
  };

  const onChangeConfirmation = () => {
    setIsShowModifyConfirmation(false);
    ctx.setRequisition(undefined);
  };

  const onOpenSaveTemplateDialog = (isUpdate: boolean = false) => {
    if (hasModifiedLines) {
      ctx.saveRequisitionLines();
    }

    if (isUpdate) {
      setIsShowUpdateTemplateDialog(true);
    } else {
      setIsShowSaveTemplateDialog(true);
    }
  };

  return (
    <>
      <div className="p-2 rounded bg-white h-full relative">
        <div className="flex mb-2">
          <div className="mr-2 py-3 float-left">
            <SButton textColor="blue" variant="outlined" onClick={onClickBackButton}>
              <ChevronLeftIcon />
              Back
            </SButton>
          </div>
          <div className="w-4/5 float-left flex items-center">
            {requisition && (
              <label className={`-mt-1 md:text-xl lg:text-4xl ${labelStyles}`}>Draft Order : {requisition.Name}</label>
            )}
          </div>
          <div className="w-2/4 float-left flex justify-end p-3">
            <SButton textColor="blue" variant="outlined" onClick={() => ctx.setIsShowRequisitionDetailsDialog(true)}>
              <TuneIcon />
              Order Details
            </SButton>
          </div>
        </div>
        <hr />

        <TransactionLineItems
          unavailableLines={requisition && requisition.LinesNoLongerAvailable}
          lines={(requisition && requisition.Lines) || []}
          onChangeQuantity={onChangeQuantity}
          onRemoveLine={onRemoveLine}
          isCalculateLineTotal={hasModifiedLines}
        />

        <hr />
        <div className="flex justify-end mt-4">
          <div className="flex-row text-right mr-10">
            <label>
              Sub Total: <p className="float-right font-bold text-lg ml-12"> {calculatedSubTotal() || ''}</p>
            </label>
          </div>
        </div>

        <div className="absolute flex justify-between w-full bottom-0 pt-0 pr-4 pb-2 pl-0">
          <SButton color="red" onClick={() => setIsShowDeleteConfirmation(true)}>
            Delete
          </SButton>
          <div className="flex justify-between pl-4">
            <div className="mr-2">
              {requisition && requisition.Lines && requisition.Lines.length > 0 && (
                <SButton onClick={onSaveAndClose}>Save &amp; Close</SButton>
              )}
            </div>
            {requisition && requisition.Lines && requisition.Lines.length > 0 && !requisition.RequisitionTemplateID && (
              <div className="mr-2">
                <SButton color="green" onClick={() => onOpenSaveTemplateDialog(false)}>
                  Create Template
                </SButton>
              </div>
            )}
            {requisition &&
              requisition.Lines &&
              requisition.Lines.length > 0 &&
              requisition.RequisitionTemplateID &&
              hasModifiedLines && (
                <div className="mr-2">
                  <SButton color="green" onClick={() => onOpenSaveTemplateDialog(true)}>
                    Update Template
                  </SButton>
                </div>
              )}
            {requisition && requisition.Lines && requisition.Lines.length > 0 && !hasModifiedLines ? (
              <div className="mr-2">
                <SButton color="yellow" onClick={onFinaliseRequisition}>
                  Finalise
                </SButton>
              </div>
            ) : (
              <span></span>
            )}
            {hasModifiedLines && (
              <SButton color="yellow" onClick={onConfirmPrices}>
                Confirm Prices
              </SButton>
            )}
          </div>
        </div>
      </div>

      {isShowDeleteConfirmation && (
        <AlertDialogSlide
          title="Delete Draft Order"
          actions={[
            {label: 'Delete', action: onDeleteRequisition},
            {label: 'Cancel', variant: 'outlined', action: () => setIsShowDeleteConfirmation(false)},
          ]}
        >
          <p>Are you sure you want to delete draft order "{requisition && requisition.Name}" ?</p>
        </AlertDialogSlide>
      )}

      {isShowModifyConfirmation && (
        <AlertDialogSlide
          title="Confirm Exit"
          actions={[
            {label: 'Exit', action: onChangeConfirmation},
            {label: 'Cancel', variant: 'outlined', action: () => setIsShowModifyConfirmation(false)},
          ]}
        >
          <p>Are you sure you want to exit without saving changes to order :"{requisition && requisition.Name}" ?</p>
        </AlertDialogSlide>
      )}

      {requisition && isShowSaveTemplateDialog && (
        <CreateTemplateDialog
          onSaveTemplate={onUpdateTemplate}
          onCancel={() => {
            setIsShowSaveTemplateDialog(false);
          }}
          templateName={requisition.Name}
        />
      )}

      {requisition && isShowUpdateTemplateDialog && (
        <UpdateTemplateDialog
          templateID={requisition.RequisitionTemplateID}
          onUpdateTemplate={onUpdateTemplate}
          onCloseDialog={() => {
            setIsShowUpdateTemplateDialog(false);
          }}
        />
      )}
    </>
  );
};
