import * as React from 'react';
const APOnbUserSettingIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg {...props}>
    <path
      fill="hsl(var(--primary))"
      fillRule="evenodd"
      d="M.833 3.959h2.28a3.107 3.107 0 0 0 5.996 0h10.058a.833.833 0 0 0 0-1.667H9.109a3.107 3.107 0 0 0-5.996 0H.833a.833.833 0 0 0 0 1.667Zm5.278-2.292a1.458 1.458 0 1 1 0 2.917 1.458 1.458 0 0 1 0-2.917ZM19.167 9.168h-2.28a3.106 3.106 0 0 0-5.995 0H.833a.833.833 0 1 0 0 1.666h10.059a3.106 3.106 0 0 0 5.995 0h2.28a.833.833 0 0 0 0-1.666Zm-5.278 2.291a1.458 1.458 0 1 1 0-2.917 1.458 1.458 0 0 1 0 2.917ZM19.167 16.042H9.109a3.107 3.107 0 0 0-5.996 0H.833a.833.833 0 1 0 0 1.666h2.28a3.107 3.107 0 0 0 5.996 0h10.058a.833.833 0 1 0 0-1.666ZM6.11 18.333a1.458 1.458 0 1 1 0-2.916 1.458 1.458 0 0 1 0 2.917Z"
      clipRule="evenodd"
    />
  </svg>
);
export default APOnbUserSettingIcon;
