import React from 'react';
import {ARDialogTemplate} from '../ARDialogTemplate';
import {Button, Typography} from 'spenda-ui-react';

interface ISendClaimModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  isSubmitting: boolean;
}

export const SendClaimModal = (props: ISendClaimModalProps) => {
  //Props
  const {isOpen, onClose, onConfirm, isSubmitting} = props;

  return (
    <ARDialogTemplate
      dialogProps={{size: 'xs', open: isOpen, className: '!min-w-[460px] !max-w-[460px]'}}
      isFullScreen
      header={<p className=" font-poppins text-xl font-light text-[#333333]">Send Claim</p>}
      body={
        <div className="my-12">
          <Typography variant="paragraph" className="text-center font-light text-black-800">
            Once the claim is sent, you will not be able to edit it any further. Are you sure you want to send the claim
            now?
          </Typography>
        </div>
      }
      footer={
        <div className="flex w-full flex-row items-center justify-between">
          <Button
            data-autoid={`btnCancel`}
            variant="outlined"
            className="bg-white"
            color="primary"
            onClick={() => onClose()}
          >
            Cancel
          </Button>
          <Button
            disabled={isSubmitting}
            data-autoid={`btnSendClaim`}
            loading={isSubmitting}
            variant="filled"
            color="primary"
            onClick={() => onConfirm()}
          >
            Send Claim
          </Button>
        </div>
      }
      handleClose={() => onClose()}
    />
  );
};
