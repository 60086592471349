import {MenuItem, Popover, makeStyles} from '@material-ui/core';
import moment from 'moment';
import React, {useContext, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {Typography} from 'spenda-ui-react';
import ARCreditHoldIcon from '../../../assets/svg/ARCreditHoldIcon';
import ARCreditStoppedIcon from '../../../assets/svg/ARCreditStoppedIcon';
import IconScheduledPayments from '../../../assets/svg/IconScheduledPayments';
import PaymentScheduled from '../../../assets/svg/PaymentScheduled';
import TableDropChange from '../../../assets/svg/TableDropChange';
import {IconDropdownSupplierView} from '../../../assets/svg/accounts-payable/IconDropdownSupplierView';
import {ARSelectToggler} from '../../../components/AccountsReceivable/ARSelectToggler';
import {ARTable} from '../../../components/AccountsReceivable/ARTable';
import {ITxData} from '../../../components/AccountsReceivable/TransactionView';
import StartConvo from '../../../components/conversationChatBox/StartConvo';
import ARTooltip from '../../../components/data-display/ARTootip';
import ARContext from '../../../context/ar-context/ARContext';
import PurchasingContext from '../../../context/purchasing/purchasingContext';
import {useFeatureFlags} from '../../../hooks/useFeatureFlags';
import {ARAgeReceivableDateRange, ConversationType, DatTypes, StartConvoType} from '../../../model/constants/Constants';
import {
  IAgedTransactionGroupList,
  IAgedTransactionResponse,
  IAgedTxCustOutstandingStatementList,
  ICustomerOutstandingStatement,
} from '../../../model/customer/CustomerStatements';
import {AUTH_SELLING_COLLECT_ROUTE} from '../../../routes/AuthenticatedRoutes';
import {PriceFormat} from '../../../utils/formatter';
import {useAutomatedStatementsAPI} from '../../../services/useAutomatedStatementsAPI';
import {ILastNotificationSendDetails} from '../../../model/statements/Statements';
import {IEmailBatchList} from '../../../model/accounts-receivable/AccountsReceivable';

type THeaderTextObject = {
  [key: number]: {[key in ARAgeReceivableDateRange]: string};
};

interface ICustomerGroupAgedReceivableViewProps {
  agedTransactions?: IAgedTransactionResponse | undefined;
  isLoading: boolean;
  ageReceivableDateRange: ARAgeReceivableDateRange;
  expandedCustomer: IAgedTxCustOutstandingStatementList | undefined;
  setExpandedCustomer?: React.Dispatch<React.SetStateAction<IAgedTxCustOutstandingStatementList | undefined>>;
  customerId?: number;
  onSelect?: (record: ICustomerOutstandingStatement) => Promise<void>;
  onViewTransaction: (currenViewingTx: ITxData) => void;
  scope: 'CUST_GROUP' | 'CUST_FOCUSED';
}

const useARStyle = makeStyles({
  changeSortValueStyles: {
    background: 'transparent !important',
    '& .MuiPaper-root': {
      border: '1px solid hsl(var(--primary))',
      boxShadow: 'none',
    },
    '& .MuiButtonBase-root': {
      color: '#333333',
      fontSize: '14px',
      fontWeight: '600',
      fontFamily: 'poppins',
      lineHeight: '16px',
      padding: '0px 40px 0px 10px',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      height: '40px',
      '& .MuiRadio-root': {
        display: 'none',
      },
    },
    '& .MuiButtonBase-root:hover': {
      backgroundColor: 'hsl(var(--primary-header))',
    },
  },
});

export const CustomerGroupAgedReceivableView = (props: ICustomerGroupAgedReceivableViewProps) => {
  // Props
  const {
    agedTransactions,
    isLoading,
    expandedCustomer,
    ageReceivableDateRange,
    customerId,
    onSelect,
    setExpandedCustomer,
    onViewTransaction,
    scope,
  } = props;

  // Styles
  const classes = useARStyle();

  // Feature Flags
  const {statementsAndAutomatedStatement83193} = useFeatureFlags().tenantOwned();
  const {scheduledPaymentsV2} = useFeatureFlags().tenantOwned();

  // Context
  const {psblStatement, setEmailBatch} = useContext(ARContext);
  const {conversations} = useContext(PurchasingContext);

  // State
  const [anchorEl, setAnchorEl] = React.useState<HTMLSpanElement | null>(null);
  const [sortByKey, setSortByKey] = useState('name');
  const [lastNotificationSendDetails, setLastNotificationSendDetails] = useState<ILastNotificationSendDetails[]>();

  // APIs
  const {getLastNotficationSendDetails} = useAutomatedStatementsAPI();

  // contants
  const open = Boolean(anchorEl);
  const id = open ? 'change-sort-value' : undefined;

  useEffect(() => {
    !customerId && fetchLastNotifcationSendData();
  }, [customerId]);

  // Functions
  const fetchLastNotifcationSendData = async () => {
    try {
      const response = await getLastNotficationSendDetails();
      setLastNotificationSendDetails(response);
    } catch {}
  };

  const getAgedInvoicesGroupColumnsHeader = (colNumber: number) => {
    const HeaderTextObject: THeaderTextObject = {
      1: {
        [ARAgeReceivableDateRange.DAYS_7_14_28]: 'Upto 7 Days',
        [ARAgeReceivableDateRange.DAYS_30_60_90]: 'Upto 30 Days',
        [ARAgeReceivableDateRange.WEEKS_1_2_4]: 'Upto 1 Week',
        [ARAgeReceivableDateRange.MONTHS_1_2_3]: 'Upto 1 Month',
        [ARAgeReceivableDateRange.MONTHS_2_4_6]: 'Upto 2 Months',
        [ARAgeReceivableDateRange.MONTHS_3_6_9]: 'Upto 3 Months',
        [ARAgeReceivableDateRange.BY_MONTH]: moment().format('MMM'),
      },
      2: {
        [ARAgeReceivableDateRange.DAYS_7_14_28]: '14 Days',
        [ARAgeReceivableDateRange.DAYS_30_60_90]: '60 Days',
        [ARAgeReceivableDateRange.WEEKS_1_2_4]: '2 Weeks',
        [ARAgeReceivableDateRange.MONTHS_1_2_3]: '2 Months',
        [ARAgeReceivableDateRange.MONTHS_2_4_6]: '4 Months',
        [ARAgeReceivableDateRange.MONTHS_3_6_9]: '6 Months',
        [ARAgeReceivableDateRange.BY_MONTH]: moment().subtract(1, 'month').format('MMM'),
      },
      3: {
        [ARAgeReceivableDateRange.DAYS_7_14_28]: '28 Days',
        [ARAgeReceivableDateRange.DAYS_30_60_90]: '90 Days',
        [ARAgeReceivableDateRange.WEEKS_1_2_4]: '4 Weeks',
        [ARAgeReceivableDateRange.MONTHS_1_2_3]: '3 Months',
        [ARAgeReceivableDateRange.MONTHS_2_4_6]: '6 Months',
        [ARAgeReceivableDateRange.MONTHS_3_6_9]: '9 Months',
        [ARAgeReceivableDateRange.BY_MONTH]: moment().subtract(2, 'month').format('MMM'),
      },
      4: {
        [ARAgeReceivableDateRange.DAYS_7_14_28]: 'Older than 28 Days',
        [ARAgeReceivableDateRange.DAYS_30_60_90]: 'Older than 90 Days',
        [ARAgeReceivableDateRange.WEEKS_1_2_4]: 'Older than 4 Weeks',
        [ARAgeReceivableDateRange.MONTHS_1_2_3]: 'Older than 3 Months',
        [ARAgeReceivableDateRange.MONTHS_2_4_6]: 'Older than 6 Months',
        [ARAgeReceivableDateRange.MONTHS_3_6_9]: 'Older than 9 Months',
        [ARAgeReceivableDateRange.BY_MONTH]: `older than ${moment().subtract(2, 'month').format('MMM')}`,
      },
    };

    const headerText = HeaderTextObject?.[colNumber]?.[ageReceivableDateRange] || '';
    return headerText;
  };

  const handleSelectCustomers = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, timelineSubGroupId: number) => {
    e.stopPropagation();
    const selectedCustomer = agedTransactions?.agedCustomerOutstandingStatementGroupByList?.reduce(
      (acc: IEmailBatchList[], tx) => {
        const accountCustomerId = tx?.groupedList?.[0]?.accountCustomerID;
        const lastNotificationDetail = lastNotificationSendDetails?.find(
          l => l?.accountCustomerID === accountCustomerId,
        );

        let totalOverdue = 0;
        switch (timelineSubGroupId) {
          case 1:
            totalOverdue = tx?.totalValueFirstGroupInvoices;
            break;
          case 2:
            totalOverdue = tx?.totalValueSecondGroupInvoices;
            break;
          case 3:
            totalOverdue = tx?.totalValueThirdGroupInvoices;
            break;
          case 4:
            totalOverdue = tx?.totalValueFourthGroupInvoices;
            break;
          case 5:
            totalOverdue = tx?.totalValueFifthGroupInvoices;
            break;
          default:
            break;
        }

        if (totalOverdue <= 0) {
          return acc;
        }

        acc.push({
          customerId: tx?.groupedList?.[0]?.customerID,
          accountCustomerId: accountCustomerId,
          customerName: tx?.name,
          totalOverdue: totalOverdue,
          totalOwing: tx?.totalBalance,
          lastNotificationSent: lastNotificationDetail?.lastCustomerStatementSentDateTime_utc,
          lastTemplateUsed: lastNotificationDetail?.templateName,
        });

        return acc;
      },
      [],
    );

    selectedCustomer?.length &&
      setEmailBatch({emailBatchList: selectedCustomer, timelineGroup: ageReceivableDateRange, timelineSubGroupId});
  };

  const handleRenderNestedTable = (
    row: IAgedTxCustOutstandingStatementList,
    order: 'asc' | 'desc',
    orderBy: string,
    isPaginated?: boolean,
  ) => {
    if (expandedCustomer?.name !== row?.name) return;
    let _orderBy: string | undefined = undefined;
    switch (orderBy) {
      case 'name':
        _orderBy = 'refNumber';
        break;
      case 'totalValueFirstGroupInvoices':
        _orderBy = 'valueFirstGroupInvoices';
        break;
      case 'totalValueSecondGroupInvoices':
        _orderBy = 'valueSecondGroupInvoices';
        break;
      case 'totalValueThirdGroupInvoices':
        _orderBy = 'valueThirdGroupInvoices';
        break;
      case 'totalValueFourthGroupInvoices':
        _orderBy = 'valueFourthGroupInvoices';
        break;
      case 'totalValueFifthGroupInvoices':
        _orderBy = 'valueFifthGroupInvoices';
        break;
      case 'totalBalance':
        _orderBy = 'balance';
        break;
      default:
        break;
    }
    return (
      <ARTable
        isHighlightRowOnHover
        isNestedTable
        rows={expandedCustomer?.groupedList || []}
        columns={agedInvoicesGroupNestedColumns}
        isLoading={isLoading}
        order={order}
        orderBy={_orderBy}
        isPaginated={isPaginated}
        dataAutoId="AgedInvoicesGroupNested"
        getRowDataAutoId={rowData => `rowAgedInvoicesGroupNested${rowData?.refNumber}`}
      />
    );
  };

  const agedInvoicesGroupColumns = [
    {
      title: 'Customer',
      key: sortByKey,
      width: '16%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'pl-2.5',
      isSortable: true,
      getRowClassConditionally: (rowData: IAgedTxCustOutstandingStatementList) =>
        rowData?.name === expandedCustomer?.name ? '!bg-[#F4F9FB]' : '',
      headerRenderer: () => (
        <div className="flex items-center justify-center gap-1">
          <Typography data-autoid="lblCustomers" variant="paragraph" className="ml-auto text-left">
            Customers
          </Typography>
          {scope === 'CUST_GROUP' && (
            <div className="flex items-center justify-center" onClick={e => e.stopPropagation()}>
              <span onClick={e => setAnchorEl(e.currentTarget)} aria-describedby={id} className="absolute -right-7 ">
                <TableDropChange />
              </span>
              <Popover
                className={`${classes.changeSortValueStyles} font-poppins text-black-800`}
                id={open ? id : undefined}
                open={open}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <MenuItem
                  onClick={() => {
                    setSortByKey('name');
                    setAnchorEl(null);
                  }}
                >
                  Sort by name
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setSortByKey('refNumber');
                    setAnchorEl(null);
                  }}
                >
                  Sort by ref ID
                </MenuItem>
              </Popover>
            </div>
          )}
        </div>
      ),
      rowRenderer: (rowData: IAgedTxCustOutstandingStatementList) => (
        <div className="relative flex flex-row items-center justify-between">
          <div className="">
            <div className="flex w-[300px] flex-row items-center justify-between ">
              <div className="flex items-center justify-center">
                <div
                  className={`togglerIcon mr-[8px] flex !h-[14px] !max-h-[14px] !min-h-[14px] !w-[14px] !min-w-[14px] !max-w-[14px] cursor-pointer items-center justify-center rounded-[3px] border border-primary ${
                    rowData?.name !== expandedCustomer?.name ? '' : '!rotate-180'
                  }`}
                  onClick={() => {
                    if (setExpandedCustomer) {
                      if (rowData?.name === expandedCustomer?.name) setExpandedCustomer(undefined);
                      else setExpandedCustomer(rowData);
                    }
                  }}
                >
                  <IconDropdownSupplierView className="w-[7px] rotate-180 transform" />
                </div>
                <div className="flex flex-col">
                  <Link
                    data-autoid={`lnkAgedViewCustomer`}
                    key={`btnAgedViewCustomerLink${rowData.groupedList?.[0].accountCustomerID}`}
                    to={`${AUTH_SELLING_COLLECT_ROUTE}/${rowData?.groupedList?.[0]?.accountCustomerID}`}
                  >
                    <Typography
                      data-autoid={`lblCustomerName`}
                      variant="paragraph"
                      className="cursor-pointer overflow-hidden overflow-ellipsis whitespace-nowrap font-medium text-primary hover:text-spenda-title"
                    >
                      {rowData.name}
                    </Typography>
                  </Link>
                  {scope === 'CUST_GROUP' && <p className="text-xs text-spenda-labeltext">{rowData?.refNumber}</p>}
                </div>
              </div>
              <div>
                {rowData?.isOnCreditStop && (
                  <div className="flex h-[42px] w-9 items-center justify-center bg-[rgba(197,93,68,.1)]">
                    <ARCreditStoppedIcon />
                  </div>
                )}
                {rowData?.isOnCreditHold && (
                  <div className="flex h-[42px] w-9 items-center justify-center bg-[rgba(198,138,25,0.125)]">
                    <ARCreditHoldIcon />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      title: 'Due',
      key: 'totalValueFirstGroupInvoices',
      width: '14%',
      align: 'right',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: true,
      getRowClassConditionally: (rowData: IAgedTxCustOutstandingStatementList) =>
        rowData?.name === expandedCustomer?.name ? '!bg-[#F4F9FB]' : '',
      headerRenderer: () => (
        <div className={`ml-auto text-right text-black-800`}>
          <Typography variant="paragraph" data-autoid="lblFirstGroupInvoices" className="text-spenda-labeltext">
            Due
          </Typography>
          <Typography variant="paragraph" data-autoid={`lblTotalOverDue`}>
            {statementsAndAutomatedStatement83193 ? (
              <button
                data-autoid={`btnFirstGroupTotals`}
                onClick={e => handleSelectCustomers(e, 1)}
                className=" text-primary underline"
              >
                {PriceFormat(agedTransactions?.grandTotalValueFirstGroupInvoices)}
              </button>
            ) : (
              PriceFormat(agedTransactions?.grandTotalValueFirstGroupInvoices)
            )}
          </Typography>
        </div>
      ),
      rowRenderer: (rowData: IAgedTxCustOutstandingStatementList) => (
        <Typography
          variant="paragraph"
          data-autoid={`lblFirst`}
          className={`inline overflow-ellipsis font-medium ${
            rowData?.totalValueFirstGroupInvoices == 0 ? 'opacity-50' : ''
          }`}
        >
          {PriceFormat(rowData?.totalValueFirstGroupInvoices)}
        </Typography>
      ),
    },
    {
      title: 'Upto 7 Days',
      key: 'totalValueSecondGroupInvoices',
      width: '14%',
      align: 'right',
      columClassName: 'text-spenda-labeltext bg-[#E8F1F8]',
      rowClassName: 'p-2.5',
      isSortable: true,
      getRowClassConditionally: (rowData: IAgedTxCustOutstandingStatementList) =>
        rowData?.name === expandedCustomer?.name ? '!bg-[#F4F9FB]' : '',
      headerRenderer: () => (
        <div className={`ml-auto text-right text-black-800`}>
          <Typography variant="paragraph" data-autoid="lblSecondGroupInvoices" className="text-spenda-labeltext">
            {getAgedInvoicesGroupColumnsHeader(1)}
          </Typography>
          <Typography variant="paragraph" data-autoid={`lblTotalOverDue`}>
            {statementsAndAutomatedStatement83193 ? (
              <button
                data-autoid={`btnSecondGroupTotals`}
                onClick={e => handleSelectCustomers(e, 2)}
                className=" text-primary underline"
              >
                {PriceFormat(agedTransactions?.grandTotalValueSecondGroupInvoices)}
              </button>
            ) : (
              PriceFormat(agedTransactions?.grandTotalValueSecondGroupInvoices)
            )}
          </Typography>
        </div>
      ),
      rowRenderer: (rowData: IAgedTxCustOutstandingStatementList) => (
        <Typography
          variant="paragraph"
          data-autoid={`lblSecond`}
          className={`inline overflow-ellipsis font-medium text-black-800  ${
            rowData?.totalValueSecondGroupInvoices == 0 ? 'opacity-50' : ''
          }`}
        >
          {PriceFormat(rowData?.totalValueSecondGroupInvoices)}
        </Typography>
      ),
    },
    {
      title: '14 days',
      key: 'totalValueThirdGroupInvoices',
      width: '14%',
      align: 'right',
      columClassName: 'text-spenda-labeltext bg-[#E8F1F8]',
      rowClassName: 'p-2.5',
      isSortable: true,
      getRowClassConditionally: (rowData: IAgedTxCustOutstandingStatementList) =>
        rowData?.name === expandedCustomer?.name ? '!bg-[#F4F9FB]' : '',
      headerRenderer: () => (
        <div className={`ml-auto text-right text-black-800`}>
          <Typography variant="paragraph" data-autoid="lblthirdGroupInvoices" className="text-spenda-labeltext">
            {getAgedInvoicesGroupColumnsHeader(2)}
          </Typography>
          <Typography variant="paragraph" data-autoid={`lblTotalOverDue`}>
            {statementsAndAutomatedStatement83193 ? (
              <button
                data-autoid={`btnthirdGroupTotals`}
                onClick={e => handleSelectCustomers(e, 3)}
                className=" text-primary underline"
              >
                {PriceFormat(agedTransactions?.grandTotalValueThirdGroupInvoices)}
              </button>
            ) : (
              PriceFormat(agedTransactions?.grandTotalValueThirdGroupInvoices)
            )}
          </Typography>
        </div>
      ),
      rowRenderer: (rowData: IAgedTxCustOutstandingStatementList) => (
        <Typography
          variant="paragraph"
          data-autoid={`lblThird`}
          className={`inline overflow-ellipsis font-medium text-black-800  ${
            rowData?.totalValueThirdGroupInvoices == 0 ? 'opacity-50' : ''
          }`}
        >
          {PriceFormat(rowData?.totalValueThirdGroupInvoices)}
        </Typography>
      ),
    },
    {
      title: '28 Days',
      key: 'totalValueFourthGroupInvoices',
      width: '14%',
      align: 'right',
      columClassName: 'text-spenda-labeltext bg-[#E8F1F8]',
      rowClassName: 'p-2.5',
      isSortable: true,
      getRowClassConditionally: (rowData: IAgedTxCustOutstandingStatementList) =>
        rowData?.name === expandedCustomer?.name ? '!bg-[#F4F9FB]' : '',
      headerRenderer: () => (
        <div className={`ml-auto text-right text-black-800`}>
          <Typography variant="paragraph" data-autoid="lblFourthGroupInvoices" className="text-spenda-labeltext">
            {getAgedInvoicesGroupColumnsHeader(3)}
          </Typography>
          <Typography variant="paragraph" data-autoid={`lblTotalOverDue`}>
            {statementsAndAutomatedStatement83193 ? (
              <button
                data-autoid={`btnFourthGroupTotals`}
                onClick={e => handleSelectCustomers(e, 4)}
                className=" text-primary underline"
              >
                {PriceFormat(agedTransactions?.grandTotalValueFourthGroupInvoices)}
              </button>
            ) : (
              PriceFormat(agedTransactions?.grandTotalValueFourthGroupInvoices)
            )}
          </Typography>
        </div>
      ),
      rowRenderer: (rowData: IAgedTxCustOutstandingStatementList) => (
        <Typography
          variant="paragraph"
          data-autoid={`lblFourth`}
          className={`inline overflow-ellipsis font-medium text-black-800  ${
            rowData?.totalValueFourthGroupInvoices == 0 ? 'opacity-50' : ''
          }`}
        >
          {PriceFormat(rowData?.totalValueFourthGroupInvoices)}
        </Typography>
      ),
    },
    {
      title: 'Older than 28 Days',
      key: 'totalValueFifthGroupInvoices',
      width: '14%',
      align: 'right',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: true,
      getRowClassConditionally: (rowData: IAgedTxCustOutstandingStatementList) =>
        rowData?.name === expandedCustomer?.name ? '!bg-[#F4F9FB]' : '',
      headerRenderer: () => (
        <div className={`ml-auto text-right text-black-800`}>
          <Typography variant="paragraph" data-autoid="lblFifthGroupInvoices" className="text-spenda-labeltext">
            {getAgedInvoicesGroupColumnsHeader(4)}
          </Typography>
          <Typography variant="paragraph" data-autoid={`lblTotalOverDue`}>
            {statementsAndAutomatedStatement83193 ? (
              <button
                data-autoid={`btnFifthGroupTotals`}
                onClick={e => handleSelectCustomers(e, 5)}
                className=" text-primary underline"
              >
                {PriceFormat(agedTransactions?.grandTotalValueFifthGroupInvoices)}
              </button>
            ) : (
              PriceFormat(agedTransactions?.grandTotalValueFifthGroupInvoices)
            )}
          </Typography>
        </div>
      ),
      rowRenderer: (rowData: IAgedTxCustOutstandingStatementList) => (
        <Typography
          variant="paragraph"
          data-autoid={`lblOlderThanFifth`}
          className={`inline overflow-ellipsis font-medium text-black-800  ${
            rowData?.totalValueFifthGroupInvoices == 0 ? 'opacity-50' : ''
          }`}
        >
          {PriceFormat(rowData?.totalValueFifthGroupInvoices)}
        </Typography>
      ),
    },
    {
      title: 'Amount',
      key: 'totalBalance',
      width: '14%',
      align: 'right',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: true,
      getRowClassConditionally: (rowData: IAgedTxCustOutstandingStatementList) =>
        rowData?.name === expandedCustomer?.name ? '!bg-[#F4F9FB]' : '',
      rowRenderer: (rowData: IAgedTxCustOutstandingStatementList) => (
        <Typography
          variant="paragraph"
          data-autoid={`lblTotalBalance`}
          className={`inline overflow-ellipsis font-medium text-black-800  ${
            rowData?.totalBalance == 0 ? 'opacity-50' : ''
          }`}
        >
          {PriceFormat(rowData?.totalBalance)}
        </Typography>
      ),
    },
  ];

  const agedInvoicesGroupNestedColumns = [
    {
      title: 'Ref Number',
      key: 'refNumber',
      width: '16%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: `${customerId ? '' : '!bg-[#F8FBFD]'}`,
      getRowClassConditionally: (rowData: IAgedTransactionGroupList) =>
        rowData?.scheduledPaymentMethodLast4 ? 'pl-2.5' : 'px-2.5',
      isSortable: true,
      rowRenderer: (rowData: IAgedTransactionGroupList) => (
        <div className="relative flex flex-row items-center justify-between">
          <div className="flex w-full flex-row items-center ">
            <div
              data-autoid={`lblRefNumber`}
              className="flex w-full cursor-pointer flex-row items-center justify-between overflow-hidden overflow-ellipsis whitespace-nowrap font-poppins text-base font-medium text-primary underline"
            >
              <Typography
                variant="paragraph"
                onClick={() =>
                  onViewTransaction({
                    txDatType: rowData.transactionTypeID,
                    txGuid: rowData.transGUID,
                    txId: rowData.transID,
                  })
                }
                className="cursor-pointer font-medium text-primary underline"
              >
                {rowData?.refNumber}
              </Typography>
              <div className="flex items-center justify-center">
                {scheduledPaymentsV2 && rowData?.scheduledInvoicePaymentAllocationSummary?.length && (
                  <ARTooltip
                    arrow
                    title={
                      <React.Fragment>
                        <h4 className="text-center font-poppins text-[10px] font-medium text-spenda-primarytext">
                          Payment Scheduled
                        </h4>
                        <ul className="mt-3">
                          {rowData?.scheduledInvoicePaymentAllocationSummary?.map(s => (
                            <li key={s?.scheduledDateTime_utc} className="flex items-center justify-start gap-2 pb-1">
                              <p className="whitespace-nowrap font-poppins text-[10px] font-medium text-[#B2B2B2]">
                                Payment Date:
                              </p>
                              <p
                                data-autoid={`lblDateScheduledPayment${s?.invoicePaymentAllocationID}`}
                                className="font-poppins text-[10px] font-semibold text-black-800"
                              >
                                {moment(s?.scheduledDateTime_utc)?.format('DD/MM/YYYY')}
                              </p>
                              <p
                                data-autoid={`lblAmountScheduledPayment${s?.invoicePaymentAllocationID}`}
                                className="font-poppins text-[10px] font-semibold text-black-800"
                              >
                                {PriceFormat(s?.amount)}
                              </p>
                            </li>
                          ))}
                        </ul>
                      </React.Fragment>
                    }
                  >
                    <button className="ml-2 mr-2 flex h-[42px] w-9 cursor-pointer items-center justify-center bg-[#e6e4f5]">
                      <IconScheduledPayments />
                    </button>
                  </ARTooltip>
                )}

                {rowData.transactionTypeID === DatTypes.Invoice && rowData.accountCustomerID && (
                  <StartConvo
                    operationUniqueId={rowData.transGUID}
                    conversationWith={rowData?.accountCustomerName || ''}
                    refNumber={rowData.refNumber}
                    customerID={rowData.accountCustomerID || null}
                    supplierID={null}
                    datTypeID={rowData.transactionTypeID}
                    guid={rowData.transGUID}
                    unreadMessages={rowData.unreadMessages}
                    conversationId={rowData.conversationID}
                    contextConvos={conversations}
                    startConvoType={StartConvoType.ICON}
                    convoType={ConversationType.DOCUMENT}
                    transactionId={rowData?.transID}
                  />
                )}

                {rowData?.scheduledPaymentMethodLast4 && (
                  <ARTooltip
                    title={
                      <React.Fragment>
                        <Typography variant="h3" className="text-center text-[10px] font-medium">
                          Payment Scheduled
                        </Typography>
                        <ul className="mt-3">
                          <li className="flex items-center justify-start pb-1">
                            <Typography variant="xsmall" className="w-[80px] font-medium text-[#B2B2B2]">
                              Due in
                            </Typography>
                            <Typography variant="xsmall" className="font-semibold">
                              {rowData?.scheduledInNoOfDays}{' '}
                              <Typography className="font-poppins font-medium text-[#B2B2B2]">days(s)</Typography>
                            </Typography>
                          </li>
                        </ul>
                      </React.Fragment>
                    }
                  >
                    <button className="ml-2 flex h-[42px] w-9 cursor-pointer items-center justify-center bg-[rgba(60,159,120,.1)]">
                      <PaymentScheduled className="h-[24px] w-[26px]" />
                    </button>
                  </ARTooltip>
                )}
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      title: 'Due',
      key: 'valueFirstGroupInvoices',
      width: '14%',
      align: 'right',
      columClassName: 'text-spenda-labeltext',
      rowClassName: `p-2.5 !max-w-[50px] !min-w-[50px] ${customerId ? '' : '!bg-[#F4F7F8]'}`,
      isSortable: true,
      headerRenderer: () => (
        <div className={`ml-auto text-right text-black-800`}>
          <Typography variant="paragraph" className="text-spenda-labeltext">
            Due
          </Typography>
          <Typography data-autoid={`lblTotalOverDue`} variant="paragraph">
            {PriceFormat(expandedCustomer?.totalValueFirstGroupInvoices)}
          </Typography>
        </div>
      ),
      rowRenderer: (rowData: IAgedTransactionGroupList) => (
        <Typography
          variant="paragraph"
          data-autoid={`lblFirst`}
          className={`overflow-ellipsis font-medium text-black-800 ${
            rowData?.valueFirstGroupInvoices == 0 ? 'text-opacity-50' : ''
          }`}
        >
          {PriceFormat(rowData?.valueFirstGroupInvoices)}
        </Typography>
      ),
    },
    {
      title: 'Upto 7 Days',
      key: 'valueSecondGroupInvoices',
      width: '14%',
      align: 'right',
      columClassName: `text-spenda-labeltext ${customerId ? 'bg-[#E8F1F8]' : ''}`,
      rowClassName: `p-2.5 !max-w-[50px] !min-w-[50px] ${customerId ? '' : '!bg-[#F8FBFD]'}`,
      isSortable: true,
      headerRenderer: () => (
        <div className={`ml-auto text-right text-black-800`}>
          <Typography variant="paragraph" className="text-spenda-labeltext">
            {getAgedInvoicesGroupColumnsHeader(1)}
          </Typography>
          <Typography data-autoid={`lblTotalOverDue`} variant="paragraph">
            {PriceFormat(expandedCustomer?.totalValueSecondGroupInvoices)}
          </Typography>
        </div>
      ),
      rowRenderer: (rowData: IAgedTransactionGroupList) => (
        <Typography
          variant="paragraph"
          data-autoid={`lblSecond`}
          className={`overflow-ellipsis font-medium text-black-800 ${
            rowData?.valueSecondGroupInvoices == 0 ? 'text-opacity-50' : ''
          }`}
        >
          {PriceFormat(rowData?.valueSecondGroupInvoices)}
        </Typography>
      ),
    },
    {
      title: '14 days',
      key: 'valueThirdGroupInvoices',
      width: '14%',
      align: 'right',
      columClassName: `text-spenda-labeltext ${customerId ? 'bg-[#E8F1F8]' : ''}`,
      rowClassName: `p-2.5 !max-w-[50px] !min-w-[50px] ${customerId ? '' : '!bg-[#F4F7F8]'}`,
      isSortable: true,
      headerRenderer: () => (
        <div className={`ml-auto text-right text-black-800`}>
          <Typography variant="paragraph" className="text-spenda-labeltext">
            {getAgedInvoicesGroupColumnsHeader(2)}
          </Typography>
          <Typography data-autoid={`lblTotalOverDue`} variant="paragraph">
            {PriceFormat(expandedCustomer?.totalValueThirdGroupInvoices)}
          </Typography>
        </div>
      ),
      rowRenderer: (rowData: IAgedTransactionGroupList) => (
        <Typography
          variant="paragraph"
          data-autoid={`lblThird`}
          className={`overflow-ellipsis font-medium text-black-800 ${
            rowData?.valueThirdGroupInvoices == 0 ? 'text-opacity-50' : ''
          }`}
        >
          {PriceFormat(rowData?.valueThirdGroupInvoices)}
        </Typography>
      ),
    },
    {
      title: '28 Days',
      key: 'valueFourthGroupInvoices',
      width: '14%',
      align: 'right',
      columClassName: `text-spenda-labeltext ${customerId ? 'bg-[#E8F1F8]' : ''}`,
      rowClassName: `p-2.5 !max-w-[50px] !min-w-[50px] ${customerId ? '' : '!bg-[#F8FBFD]'}`,
      isSortable: true,
      headerRenderer: () => (
        <div className={`ml-auto text-right text-black-800`}>
          <Typography variant="paragraph" className="text-spenda-labeltext">
            {getAgedInvoicesGroupColumnsHeader(3)}
          </Typography>
          <Typography data-autoid={`lblTotalOverDue`} variant="paragraph">
            {PriceFormat(expandedCustomer?.totalValueFourthGroupInvoices)}
          </Typography>
        </div>
      ),
      rowRenderer: (rowData: IAgedTransactionGroupList) => (
        <Typography
          variant="paragraph"
          data-autoid={`lblFourth`}
          className={`overflow-ellipsis font-medium text-black-800 ${
            rowData?.valueFourthGroupInvoices == 0 ? 'text-opacity-50' : ''
          }`}
        >
          {PriceFormat(rowData?.valueFourthGroupInvoices)}
        </Typography>
      ),
    },
    {
      title: 'Older than 28 Days',
      key: 'valueFifthGroupInvoices',
      width: '14%',
      align: 'right',
      columClassName: 'text-spenda-labeltext',
      rowClassName: `p-2.5 !max-w-[50px] !min-w-[50px] ${customerId ? '' : '!bg-[#F4F7F8]'}`,
      isSortable: true,
      headerRenderer: () => (
        <div className={`ml-auto text-right text-black-800`}>
          <Typography variant="paragraph" className="text-spenda-labeltext">
            {getAgedInvoicesGroupColumnsHeader(4)}
          </Typography>
          <Typography data-autoid={`lblTotalOverDue`} variant="paragraph">
            {PriceFormat(expandedCustomer?.totalValueFifthGroupInvoices)}
          </Typography>
        </div>
      ),
      rowRenderer: (rowData: IAgedTransactionGroupList) => (
        <Typography
          variant="paragraph"
          data-autoid={`lblOlderThanFifth`}
          className={`overflow-ellipsis font-medium text-black-800 ${
            rowData?.valueFifthGroupInvoices == 0 ? 'text-opacity-50' : ''
          }`}
        >
          {PriceFormat(rowData?.valueFifthGroupInvoices)}
        </Typography>
      ),
    },
    {
      title: 'Amount',
      key: 'balance',
      width: '14%',
      align: 'right',
      columClassName: 'text-spenda-labeltext',
      rowClassName: `p-2.5 !max-w-[50px] !min-w-[50px] ${customerId ? '' : '!bg-[#F8FBFD]'}`,
      isSortable: true,
      rowRenderer: (rowData: IAgedTransactionGroupList) =>
        scheduledPaymentsV2 && typeof rowData?.balanceIncludingScheduledIPAs === 'number' ? (
          <ARTooltip
            arrow
            title={
              <React.Fragment>
                <h4 className="whitespace-nowrap text-center font-poppins text-[10px] font-medium text-spenda-primarytext">
                  Balance including scheduled payments
                </h4>
                <p
                  data-autoid={`lblBalanceIncludingScheduledPayments${rowData.transID}`}
                  className="mt-1 whitespace-nowrap text-center font-poppins text-[10px] font-medium text-spenda-primarytext"
                >
                  {PriceFormat(rowData?.balanceIncludingScheduledIPAs)}
                </p>
              </React.Fragment>
            }
          >
            <span
              data-autoid={`lblAmount${rowData.accountCustomerID}`}
              className={`overflow-ellipsis font-poppins text-base font-medium text-black-800 ${
                rowData?.balance == 0 ? 'opacity-50' : ''
              }`}
            >
              {PriceFormat(rowData?.balance)}
            </span>
          </ARTooltip>
        ) : (
          <span
            data-autoid={`lblAmount${rowData.accountCustomerID}`}
            className={`overflow-ellipsis font-poppins text-base font-medium text-black-800 ${
              rowData?.balance == 0 ? 'opacity-50' : ''
            }`}
          >
            {PriceFormat(rowData?.balance)}
          </span>
        ),
    },
  ];

  if (customerId) {
    agedInvoicesGroupNestedColumns?.splice(0, 0, {
      title: '',
      width: '',
      key: 'checkbox',
      columClassName: 'text-spenda-labeltext z-[9] !max-w-[50px] !min-w-[50px]',
      rowClassName: `p-0 !max-w-[50px] !min-w-[50px] ${customerId ? '' : '!bg-[#F4F7F8]'}`,
      align: 'left',
      isSortable: false,
      rowRenderer: (rowData: IAgedTransactionGroupList) => {
        const isNotSelectable =
          scheduledPaymentsV2 &&
          typeof rowData?.balanceIncludingScheduledIPAs === 'number' &&
          rowData?.balanceIncludingScheduledIPAs <= 0;

        return isNotSelectable ? (
          <ARTooltip
            arrow
            title={
              <React.Fragment>
                <h4 className="min-w-[200px] text-center font-poppins text-[10px] font-medium text-spenda-primarytext">
                  This {rowData?.transactionType} can not be selected as it’s already scheduled for payment.
                </h4>
              </React.Fragment>
            }
          >
            <span
              className={`cursor-pointer items-center font-poppins text-base font-normal opacity-30 grayscale`}
              data-autoid={`chkTransactionRow${rowData.transID}`}
            >
              <ARSelectToggler
                key={`checkbox${rowData.transID}`}
                isSelected={psblStatement?.selectedTxList?.findIndex(st => st.id === rowData.transID) > -1}
              />
            </span>
          </ARTooltip>
        ) : (
          <span
            className={`cursor-pointer items-center font-poppins text-base font-normal`}
            onClick={() => onSelect?.(rowData)}
            data-autoid={`chkTransactionRow${rowData.transID}`}
          >
            <ARSelectToggler
              key={`checkbox${rowData.transID}`}
              isSelected={psblStatement?.selectedTxList?.findIndex(st => st.id === rowData.transID) > -1}
            />
          </span>
        );
      },
    });
  }

  return (
    <>
      {customerId ? (
        <ARTable
          isHighlightRowOnHover
          tableClass="mb-12"
          rows={expandedCustomer?.groupedList || []}
          columns={agedInvoicesGroupNestedColumns}
          isLoading={isLoading}
          isPaginated
          dataAutoId="AgedInvoicesGroup"
          getRowDataAutoId={rowData => `rowAgedInvoicesGroup${rowData?.refNumber}`}
        />
      ) : (
        <ARTable
          isHighlightRowOnHover
          tableClass="mb-12"
          rows={agedTransactions?.agedCustomerOutstandingStatementGroupByList || []}
          columns={agedInvoicesGroupColumns}
          isLoading={isLoading}
          renderNestedTable={handleRenderNestedTable}
          dataAutoId="AgedCustomerOutstandingStatementGroup"
          getRowDataAutoId={rowData => `rowAgedCustomerOutstandingStatementGroup${rowData?.refNumber}`}
        />
      )}
    </>
  );
};
