import React, {useState} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {ICategory} from '../../model/inventory/Category';
import {Layout} from '../../components/layout/Layout';
// import {SButton} from '../../components/buttons/Button';
import {STable} from '../../components/tables/STable';
import {CategoryToolbar} from '../../components/inventory/InventoryToolbar';
import {CategoryDialog} from '../../components/dialog/CategoryDialog';
import {SPagination} from '../../components/tables/SPagination';
import useCategoryAPI, {useLoadCategory} from '../../services/useCategoryAPI';
// import KeyboardBackspaceRoundedIcon from '@material-ui/icons/KeyboardBackspaceRounded';
import {makeStyles} from '@material-ui/core';
import {AUTH_INVENTORY_PRODUCTS_LIST} from '../../routes/InventoryRoutes';
import {Button, Typography} from 'spenda-ui-react';
import IconKeyboardBackspaceRounded from '../../assets/svg/IconKeyboardBackspaceRounded';

const useCategoriesListStyles = makeStyles(() => ({
  root: {
    maxHeight: 'calc( 100vh - 20.5em )',
  },
  title: {
    fontWeight: 500,
    fontSize: '1.5em',
    color: '#4D4D4D',
  },
}));

export const initialCategoryData = {
  Name: '',
  Description: '',
  ParentID: '',
  ParentName: '',
  IsActive: true,
} as ICategory;

export const CategoriesList = () => {
  const classes = useCategoriesListStyles();
  const [open, setOpen] = useState(false);
  const [openParent, setOpenParent] = useState(false);
  const [categoryToEdit, setCategoryToEdit] = React.useState<ICategory>(initialCategoryData);
  const [selected, setSelected] = useState<number[]>([]);

  const {
    loading,
    totalCount,
    pageSize,
    currentPage,
    items: categories,
    searchFilter,
    setSearchFilter,
    getCategoriesList,
  } = useLoadCategory();

  const {getCategoryById} = useCategoryAPI();

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = (refetchList?: boolean, category?: ICategory) => {
    if (openParent) {
      if (category) {
        setCategoryToEdit({
          ...categoryToEdit,
          ParentID: category.ID || '',
          ParentName: category.Name || '',
        });
      }
      setOpenParent(false);
      setOpen(true);
    } else {
      setOpen(false);
      setCategoryToEdit(initialCategoryData);
    }
    if (refetchList) {
      getCategoriesList();
    }
  };

  const handleAddNewClick = () => {
    setOpen(false);
    setOpenParent(true);
  };

  const handleRowClick = (rowData: Partial<ICategory>) => {
    getCategoryById(rowData).then((res: Partial<ICategory>) => {
      if (res) {
        setCategoryToEdit({
          ID: res.ID!,
          Name: res.Name || '',
          Description: res.Description || '',
          ParentID: res.ParentID || '',
          ParentName: res.ParentName || '',
          GUID: res.GUID || '',
          InventoryCount: res.InventoryCount!,
          MediaFiles: res.MediaFiles || [],
          IsActive: res.IsActive!,
        });
        openDialog();
      }
    });
  };

  const handlePageChange = (newPage: number) => {
    setSearchFilter({
      ...searchFilter,
      LastRowNumber: (newPage - 1) * pageSize,
    });
  };

  const handleRowsChange = (rows: number) => {
    setSearchFilter({
      ...searchFilter,
      MaxResults: rows,
      LastRowNumber: 0,
    });
  };

  const categoriesListing = (
    <div
      className={`${classes.root} flex min-h-full w-full flex-col overflow-y-auto rounded-lg bg-white px-5 pt-4`}
      id="categories-list"
    >
      <div className={'flex w-full justify-between px-0 py-4'}>
        <div className="flex">
          <RouterLink to={AUTH_INVENTORY_PRODUCTS_LIST}>
            <Button variant="text">
              <IconKeyboardBackspaceRounded />
            </Button>
          </RouterLink>
          <Typography className="mt-1 text-xl font-medium text-[#4D4D4D]">Categories</Typography>
        </div>
        <div className="flex">
          <Button size="sm" onClick={openDialog}>
            Add New Category
          </Button>
        </div>
      </div>
      <CategoryToolbar
        loading={loading}
        title={`Categories (${totalCount})`}
        searchFilter={searchFilter}
        data={categories}
        selected={selected}
        setSelected={setSelected}
        setSearchFilter={setSearchFilter}
      />
      <STable
        columns={[
          {title: 'Name', field: 'Name'},
          {title: 'Description', field: 'Description'},
          {title: 'Parent', field: 'ParentName'},
        ]}
        data={categories}
        emptyDataMessage={'No Category'}
        stickyHeader={true}
        // selection={true}
        // selected={selected}
        // setSelected={setSelected}
        loading={loading}
        onRowClick={handleRowClick}
      ></STable>
      <SPagination
        currentPage={currentPage}
        totalCount={totalCount}
        pageSize={pageSize}
        onPageChange={handlePageChange}
        rowsPerPageOptions={[10, 15, 25]}
        onRowsPerPageChange={handleRowsChange}
      />
    </div>
  );

  return (
    <>
      <CategoryDialog
        category={categoryToEdit}
        open={open}
        onClose={closeDialog}
        handleAddNewClick={handleAddNewClick}
      />
      <CategoryDialog type="parent" category={initialCategoryData} open={openParent} onClose={closeDialog} />
      <div className={`relative h-full overflow-hidden bg-spenda-newbg`}>
        <Layout leftPanel={categoriesListing} splitWidthType={4} />
      </div>
    </>
  );
};
