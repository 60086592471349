import * as React from 'react';

const SendPaymentIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width={44} height={43} viewBox="0 0 44 43" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.755 7.798c-3.376.441-6.2 2.669-7.286 5.749-1.087 3.08-.25 6.482 2.16 8.78l3.087 2.941c.338.322.527.758.527 1.213v5.427c.004.764.188 1.516.539 2.202l-.015.012.047.044a5.273 5.273 0 002.447 2.323l.047.045.012-.014c.72.335 1.509.51 2.31.514h5.691c.476 0 .933.18 1.27.5l3.085 2.942c1.672 1.611 3.948 2.52 6.325 2.523a9.475 9.475 0 002.883-.452c3.202-1.002 5.53-3.648 6.009-6.829l6.064-27.47c.456-1.744-.085-3.588-1.423-4.853-1.31-1.264-3.218-1.784-5.031-1.37l-9.249 1.857M8.26 22.849l-3.088-2.941c-1.469-1.366-1.974-3.42-1.297-5.266.656-1.895.812-2.796 2.892-3.016l1.493-.376.393-3.633 18.093-3.634s.646 3.369 1.315 3.234l8.767-1.76L9.832 31.201v-4.72A4.984 4.984 0 008.26 22.85zm25.102 12.284c-.281 1.93-1.683 3.542-3.62 4.163-1.937.621-4.076.145-5.523-1.23l-3.09-2.947a5.486 5.486 0 00-3.808-1.504h-4.95l27-25.735-6.009 27.253z"
        className="fill-primary"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.302 7.333c.814.001 1.474.63 1.475 1.406a.47.47 0 00.48.459.47.47 0 00.481-.459v-.03-.026c-.033-1.26-1.114-2.265-2.436-2.266h-.448v-.459a.47.47 0 00-.48-.458.47.47 0 00-.482.458v.459h-.448c-1.194 0-2.21.827-2.402 1.951-.19 1.124.5 2.214 1.633 2.575l1.217.387v3.337h-.448c-.814-.001-1.473-.63-1.474-1.406a.47.47 0 00-.481-.459.47.47 0 00-.48.459V13.316c.033 1.26 1.113 2.265 2.435 2.266h.448v.459a.47.47 0 00.481.458.47.47 0 00.48-.458v-.459h.449c1.194 0 2.211-.827 2.402-1.951.191-1.124-.5-2.214-1.633-2.575l-1.217-.387V7.333h.448zm.466 4.593c.687.218 1.106.879.99 1.56-.116.68-.733 1.18-1.456 1.18h-.448v-3.03l.914.29zm-1.876-1.562l-.913-.29c-.686-.218-1.106-.878-.99-1.56.116-.68.732-1.18 1.455-1.18h.448v3.03z"
        className="fill-primary"
      />
      <path
        d="M27.39 11.25c0 4.722-4.05 8.75-9.299 8.75s-9.299-4.028-9.299-8.75 4.05-8.75 9.3-8.75c5.248 0 9.298 4.028 9.298 8.75z"
        strokeWidth={5}
        className="stroke-primary"
      />
    </svg>
  );
};

export default SendPaymentIcon;
