import React from 'react';
export const Settings = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="40px" height="40px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>Settings</title>
      <g id="Order-Listing" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Spenda-Collect-Supplier-Interface-Copy" transform="translate(-1320.000000, -84.000000)">
          <g id="Buttons/IconButton" transform="translate(1320.000000, 84.000000)">
            <rect
              id="Background"
              fill="#FFFFFF"
              x="0.5"
              y="0.5"
              width="39"
              height="39"
              rx="6"
              className="fill-white stroke-primary"
            ></rect>
            <rect
              id="Background-Overlay"
              fillOpacity="0"
              fill="#000000"
              x="0"
              y="0"
              width="40"
              height="40"
              rx="6"
            ></rect>
            <rect id="Overlay" fillOpacity="0" fill="#000000" x="0" y="0" width="40" height="40" rx="6"></rect>
            <g id="Icon" transform="translate(9.000000, 9.000000)" className="fill-primary">
              <path
                d="M0.916666667,4.35440395 L3.42466667,4.35440395 C3.82912983,5.8425854 5.18020948,6.87547456 6.722375,6.87547456 C8.26454052,6.87547456 9.61562017,5.8425854 10.0200833,4.35440395 L21.0833333,4.35440395 C21.5895944,4.35440395 22,3.9439983 22,3.43773728 C22,2.93147626 21.5895944,2.52107062 21.0833333,2.52107062 L10.0200833,2.52107062 C9.61562017,1.03288916 8.26454052,0 6.722375,0 C5.18020948,0 3.82912983,1.03288916 3.42466667,2.52107062 L0.916666667,2.52107062 C0.410405654,2.52107063 1.93173041e-08,2.93147627 1.93173041e-08,3.43773728 C1.93173041e-08,3.9439983 0.410405654,4.35440394 0.916666667,4.35440395 Z M6.72191667,1.83357062 C7.60787345,1.83357062 8.32608333,2.5517805 8.32608333,3.43773728 C8.32608333,4.32369407 7.60787345,5.04190395 6.72191667,5.04190395 C5.83595988,5.04190395 5.11775,4.32369407 5.11775,3.43773728 C5.11876031,2.55219932 5.8363787,1.83458093 6.72191667,1.83357062 Z"
                id="Shape"
              ></path>
              <path
                d="M21.0833333,10.0835706 L18.5753333,10.0835706 C18.1715289,8.59501272 16.8204395,7.56160645 15.2780833,7.56160645 C13.7357271,7.56160645 12.3846378,8.59501272 11.9808333,10.0835706 L0.916666667,10.0835706 C0.410405646,10.0835706 0,10.4939763 0,11.0002373 C0,11.5064983 0.410405646,11.9169039 0.916666667,11.9169039 L11.9808333,11.9169039 C12.3846378,13.4054618 13.7357271,14.4388681 15.2780833,14.4388681 C16.8204395,14.4388681 18.1715289,13.4054618 18.5753333,11.9169039 L21.0833333,11.9169039 C21.5895944,11.9169039 22,11.5064983 22,11.0002373 C22,10.4939763 21.5895944,10.0835706 21.0833333,10.0835706 L21.0833333,10.0835706 Z M15.2780833,12.6044039 C14.3921265,12.6044039 13.6739167,11.8861941 13.6739167,11.0002373 C13.6739167,10.1142805 14.3921265,9.39607062 15.2780833,9.39607062 C16.1640401,9.39607062 16.88225,10.1142805 16.88225,11.0002373 C16.8812397,11.8857752 16.1636213,12.6033936 15.2780833,12.6044039 Z"
                id="Shape"
              ></path>
              <path
                d="M21.0833333,17.6460706 L10.0200833,17.6460706 C9.61562017,16.1578892 8.26454052,15.125 6.722375,15.125 C5.18020948,15.125 3.82912983,16.1578892 3.42466667,17.6460706 L0.916666667,17.6460706 C0.410405646,17.6460706 0,18.0564763 0,18.5627373 C0,19.0689983 0.410405646,19.4794039 0.916666667,19.4794039 L3.42466667,19.4794039 C3.82912983,20.9675854 5.18020948,22.0004746 6.722375,22.0004746 C8.26454052,22.0004746 9.61562017,20.9675854 10.0200833,19.4794039 L21.0833333,19.4794039 C21.5895944,19.4794039 22,19.0689983 22,18.5627373 C22,18.0564763 21.5895944,17.6460706 21.0833333,17.6460706 L21.0833333,17.6460706 Z M6.72191667,20.1669039 C5.83595988,20.1669039 5.11775,19.4486941 5.11775,18.5627373 C5.11775,17.6767805 5.83595988,16.9585706 6.72191667,16.9585706 C7.60787345,16.9585706 8.32608333,17.6767805 8.32608333,18.5627373 C8.32456952,19.4480663 7.60724565,20.1653901 6.72191667,20.1669039 L6.72191667,20.1669039 Z"
                id="Shape"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
