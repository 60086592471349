import React from 'react';
const IconCrossClaims = (props: {width?: string; height?: string; className?: string}) => {
  return (
    <svg
      className={props.className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6278 0.372219C15.1313 -0.124073 14.3266 -0.124073 13.8302 0.372219L8 6.20239L2.16983 0.372219C1.67339 -0.124073 0.86866 -0.124073 0.372219 0.372219C-0.124073 0.86866 -0.124073 1.67339 0.372219 2.16983L6.20239 8L0.372219 13.8302C-0.124073 14.3266 -0.124073 15.1313 0.372219 15.6278C0.86866 16.1241 1.67339 16.1241 2.16983 15.6278L8 9.79761L13.8302 15.6278C14.3266 16.1241 15.1313 16.1241 15.6278 15.6278C16.1241 15.1313 16.1241 14.3266 15.6278 13.8302L9.79761 8L15.6278 2.16983C16.1241 1.67339 16.1241 0.86866 15.6278 0.372219Z"
        fill="#C55D44"
      />
    </svg>
  );
};

export default IconCrossClaims;
