import {ApplicationInfo} from './../../model/constants/ApplicationInfo';
import {RegistrationDetails} from '../../model/RegistrationDetails';

export class RegistrationConverter {
  public static CreateRegistrationOut(rego: RegistrationDetails): any {
    return {
      // Tenant and user are supposed to default to 1 until they are registered?
      TenantID: 1,
      UserID: 1,
      WebsiteID: ApplicationInfo.WebsiteId,

      EmailAddress: rego.emailAddress,
      MarketPlacePath: rego.marketPlacePath,
      Password: rego.password,
      PasswordConfirm: rego.passwordConfirm,
      ReferrerURL: window.location.origin,
      InviteCode: rego.inviteCode,
      StatementInvitationId: rego.statementInvitationId,

      Customer: {
        IsIndividual: rego.isIndividual,
        Name: rego.isIndividual ? `${rego.lastName} Family` : rego.companyName,
        Phone1: rego.companyPhone,
        Contacts: [
          {
            FirstName: rego.firstName,
            LastName: rego.lastName,
            PhoneMobile: rego.mobile,
          },
        ],
        Locations: [
          {
            TimeZoneName: rego.timeZoneName,
            UtcOffset: rego.utcOffset,
          },
        ],
      },
    };
  }
}
