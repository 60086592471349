import React from 'react';
import {useHistory} from 'react-router-dom';
import {Button, IconButton, Typography, Upload} from 'spenda-ui-react';

import {ExcelSheetIcon, UploadCatalogueIcon} from '../../assets/svg';
import {AUTH_INVENTORY_CATALOGUE_ID} from '../../routes/InventoryRoutes';
import {AlertDialog, AlertDialogSlideV2} from './AlertDialogSlideV2';
import {FileType} from './QuoteAttachmentDialog';
import {WidgetDeleteLogo} from '../../assets/svg/WidgetDeleteLogo';

interface IDownloadAndUploadSheetProps {
  handleCancel?: () => void;
}

export const CatalogueDownloadAndUploadSheetDialog = (props: IDownloadAndUploadSheetProps) => {
  const [onDeleteSheet, setOnDeleteSheet] = React.useState(false);
  const [addSheetToUpload, setAddSheetToUpload] = React.useState<FileType | null>(null);
  const {handleCancel} = props;

  const history = useHistory();

  const handleUploadDocs = () => {
    history.push(AUTH_INVENTORY_CATALOGUE_ID);
  };

  const deleteFile = () => {
    setAddSheetToUpload(null);
    setOnDeleteSheet(false);
  };

  const handleFileChange = (files: FileList) => {
    if (files && files.length > 0) {
      const newFile: FileType = {
        file: files[0],
        uri: URL.createObjectURL(files[0]),
      };
      setAddSheetToUpload(newFile);
    }
  };

  const UploadedSheetShow = ({name, onDelete}: {name: string; onDelete?: () => void;}) => {
    return (
      <div className="upload-img-field flex !h-full w-[521px] items-center justify-center">
        <div className="flex !h-[133px] !w-auto items-center justify-center">
          <ExcelSheetIcon />,
        </div>
        <div className="overlay-img">
          <IconButton variant="text" className="ml-5" onClick={onDelete} data-autoid={`btnDelete${name}`}>
            <WidgetDeleteLogo />
          </IconButton>
        </div>
        <Typography
          variant="xsmall"
          className="absolute bottom-0 w-full truncate rounded-b-lg bg-[#212121] bg-opacity-30 px-2.5 text-center text-sm text-white"
        >
          {name}
        </Typography>
      </div>
    );
  };

  return (
    <>
      <AlertDialogSlideV2
        dialogActionsAlignment="justify-between"
        dialogClassess="!w-full !min-w-[20%] !max-w-[730px]"
        dialogBodyClassess="flex flex-col items-center justify-start gap-y-8 p-10 text-black-800"
        headingTextSize="h1"
        title="Download & upload spreadsheet"
        actions={[
          {
            action: handleCancel,
            label: 'Cancel',
            variant: 'outlined',
          },
          {
            action: handleUploadDocs,
            label: 'Continue',
            disabled: !addSheetToUpload,
          },
        ]}
      >
        <Button color="success" data-autoid="btnDownload">
          <div className="flex items-center gap-x-2">
            <UploadCatalogueIcon data-autoid="imgUpload" />
            Download Catalogue
          </div>
        </Button>
        {!addSheetToUpload ? (
          <Upload
            className="h-[133px] !w-full !max-w-[521px] text-sm"
            label="Drop your spreadsheet here or click to upload"
            accept=".xls,.xlsx"
            onChange={handleFileChange}
            data-autoid="uploadSheet"
          />
        ) : (
          <>
            <UploadedSheetShow
              name={addSheetToUpload.file.name}
              onDelete={() => setOnDeleteSheet(true)}
            />
          </>
        )}

        <Typography variant="small" className="text-center">
          At any point you can upload additional products, edit product details and add existing product within Spenda
          so don’t worry if your shared catalogue is not yet complete.
        </Typography>
      </AlertDialogSlideV2>
      {onDeleteSheet && (
        <AlertDialog
          id="discardPopup"
          title="Remove Sheet"
          size="xs"
          actions={[
            {
              label: 'No',
              action: () => setOnDeleteSheet(false),
              variant: 'outlined',
            },
            {
              label: 'Yes',
              action: () => deleteFile(),
            },
          ]}
          content="Would you like to delete this sheet ?"
        />
      )}
    </>
  );
};
