import React from 'react';

export const EmptyPackage = (props: {width?: string; height?: string}) => {
  return (
    <svg
      width={props.width || '104px'}
      height={props.height || '75px'}
      viewBox="0 0 104 75"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Group</title>
      <g id="Updated-Sales-Order" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="0.600000024">
        <g id="Pack-Items-Copy-7" transform="translate(-668.000000, -490.000000)" fill="#97751D" fillRule="nonzero">
          <g id="Group-6" transform="translate(423.000000, 271.000000)">
            <g id="Group" transform="translate(245.000000, 219.000000)">
              <path
                d="M51.7732172,0.021394895 L14.6186294,7.48637518 C14.3322912,7.54563516 14.0771694,7.7063588 13.9004838,7.93879823 L0.255716588,25.8095086 C0.0146771616,26.1178657 -0.0616139311,26.523615 0.0510267963,26.8981382 C0.163667524,27.2726614 0.451272407,27.5695169 0.822673674,27.6946046 L13.2957296,31.6910082 L13.2957296,61.5509294 C13.3021977,62.0664317 13.636305,62.5208849 14.1272666,62.681987 L51.6220286,74.7466016 C51.8675389,74.8271562 52.1324611,74.8271562 52.3779714,74.7466016 L89.8727334,62.681987 C90.363695,62.5208849 90.6978023,62.0664317 90.7042704,61.5509294 L90.7042704,31.6910082 L103.177326,27.6946046 C103.548728,27.5695169 103.836332,27.2726614 103.948973,26.8981382 C104.061614,26.523615 103.985323,26.1178657 103.744283,25.8095086 L90.0995162,7.93879823 C89.9228306,7.7063588 89.6677088,7.54563516 89.3813706,7.48637518 L52.2267828,0.021394895 C52.0769467,-0.00713163168 51.9230533,-0.00713163168 51.7732172,0.021394895 Z M52,2.43431782 L85.148091,9.10755777 L52,16.8364515 L18.851909,9.10755777 L52,2.43431782 Z M14.8454123,10.6533365 L50.7148973,19.0608648 L38.2040442,37.1954886 L3.20389344,25.9226144 L14.8454123,10.6533365 Z M89.1545877,10.6533365 L100.796107,25.9226144 L65.7959558,37.1954886 L53.2851027,19.0608648 L89.1545877,10.6533365 Z M50.7904915,23.2080761 L50.7904915,71.9566595 L15.7147465,60.6460833 L15.7147465,32.4827486 L38.3174356,39.7592193 C38.8213865,39.9218312 39.3727856,39.7384945 39.6781327,39.3067962 L50.7904915,23.2080761 Z M53.2095085,23.2080761 L64.3218673,39.3067962 C64.6272144,39.7384945 65.1786135,39.9218312 65.6825644,39.7592193 L88.2852535,32.4827486 L88.2852535,60.6460833 L53.2095085,71.9566595 L53.2095085,23.2080761 Z"
                id="Shape"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
