import React from 'react';

import {Layout} from '../../../../components/layout/Layout';
import {ManageCatalogueHeader} from './ManageCatalogueHeader';
import {ManageCatalogueList} from './ManageCatalogueList';

export const ManageCatalogue = () => {
  const _leftPanel = (
    <div className="h-full rounded-md bg-white p-2.5 text-black-800 shadow-[0_0_6px_0_#D3E5EF]">
      <ManageCatalogueHeader />
      <ManageCatalogueList />
    </div>
  );

  return (
    <div className={`relative h-full overflow-hidden bg-spenda-newbg font-poppins`}>
      <Layout leftPanel={_leftPanel} splitWidthType={4} />
    </div>
  );
};
