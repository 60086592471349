import purchasing_add from './purchasing-add.png';
import purchasing_list from './purchasing-list.png';
import purchasing_newDetails from './purchasing-newdetails.png';
import purchasing_orderDetails from './purchasing-orderdetails.png';
import purchasing_quantity from './purchasing-quantity.png';

export const PurchasingOnboadingImages = {
  purchasing_add,
  purchasing_list,
  purchasing_newDetails,
  purchasing_orderDetails,
  purchasing_quantity,
};
