import React from 'react';
import {useFeatureFlags} from '../../../hooks/useFeatureFlags';
import {ARPayments} from './ARPayments';
import {ARPaymentHistory} from '../payment-history/ARPaymentHistory';
import {useTenantInfo} from '../../../hooks/useTenantInfo';
import {MarketplaceLayout} from '../../purchasing/MarketplaceLayout';
import {Layout} from '../../../components/layout/Layout';
import {PSBLFooter} from '../../../components/AccountsReceivable/PSBLFooter';

function ARPaymentsScreen() {
  // Hooks
  const {isInBuyerContext, isInSupplierContext} = useTenantInfo();

  // Featue Flags
  const {scheduledPaymentsV2: scheduledPaymentV2Supplier} = useFeatureFlags().tenantOwned();
  const {scheduledPaymentsV2: scheduledPaymentV2Buyer} = useFeatureFlags().supplierDriven();
  const scheduledPaymentsV2 = isInBuyerContext ? scheduledPaymentV2Buyer : scheduledPaymentV2Supplier;

  if (scheduledPaymentsV2) {
    return <ARPayments />;
  }

  return (
    <div className={`h-full relative overflow-hidden bg-spenda-newbg`}>
      {isInBuyerContext && (
        <div className="h-full w-full">
          <MarketplaceLayout leftPanel={<ARPaymentHistory />} splitWidthType={4} />
          <PSBLFooter />
        </div>
      )}
      {isInSupplierContext && <Layout leftPanel={<ARPaymentHistory />} splitWidthType={4} />}
    </div>
  );
}

export default ARPaymentsScreen;
