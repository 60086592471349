import React from 'react';
import {SModal} from '../modals/modalSpendaMeterialUI';
import {Box} from '@material-ui/core';
import {SButton} from '../buttons/Button';
import CloseIcon from '@material-ui/icons/Close';
import DisclaimerIcon from '../../assets/svg/accounts-payable/DisclaimerIcon';

export interface IDisclaimerModalProps {
  handleClose: () => void;
}

export default function DisclaimerModal(props: IDisclaimerModalProps) {
  return (
    <SModal open id="disclaimerModal">
      <div className={`text-xl spenda-color flex justify-center items-center h-full max-w-full`}>
        <div className={`modal-box max-w-[500px] !h-auto px-5 pb-5 mx-6`}>
          <Box className={`flex justify-between -mx-5 p-2 rounded-t-xl bg-[rgba(227,238,245,0.6)]`}>
            <span style={{minWidth: '40px', height: '40px'}}></span>
            <h2 className={`text-center w-full font-poppins font-light text-[24px] text-spenda-primarytext`}>
              Disclaimer
            </h2>
            <Box className="text-right flex justify-end">
              <span className="ap-onboarding-close-icon">
                <CloseIcon data-autoid="btnClose" onClick={props.handleClose} />
              </span>
            </Box>
          </Box>
          <div className={`max-w-[372px] py-[40px] relative w-full mx-auto`}>
            <Box className="flex flex-col justify-center items-center text-center">
              <DisclaimerIcon width="109px" data-autoid="imgDisclaimerIcon" />
              <p className="font-poppins text-spenda-labeltext text-base font-medium">
                The page you are about to visit is not optimised for mobile devices. We recommend using it on a desktop
                or laptop for the best experience. Some features may not display or function correctly on mobile
                devices.
              </p>
            </Box>
          </div>
          <Box className={`w-full flex justify-center items-center`}>
            <SButton
              borderRadius="6px"
              textColor="white"
              lineHeight="l"
              height="40px"
              boxShadow="none"
              fontFamily="poppins"
              fontSize="14px"
              border="1px solid #1C78AD"
              width="164px"
              backgroundColor="#1C78AD"
              data-autoid="btnProceedAnyway"
              onClick={props.handleClose}
            >
              Proceed Anyway
            </SButton>
          </Box>
        </div>
      </div>
    </SModal>
  );
}
