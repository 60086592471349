import React from 'react';

export const IconStockTransfer = (props: {width?: string; height?: string}) => (
  <svg
    width={props.width || '32px'}
    height={props.height || '28px'}
    viewBox="0 0 32 28"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>{'SIT'}</title>
    <g id="Order-Listing" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <g
        id="Product-Management/Add-Products/Warehouse"
        transform="translate(-772.000000, -661.000000)"
        fill="#1C78AD"
        fillRule="nonzero"
      >
        <g id="Group" transform="translate(772.000000, 659.000000)">
          <g id="Group-2" transform="translate(0.000000, 2.844444)">
            <g id="Group" transform="translate(7.822222, 9.955556)">
              <path
                d="M7.1221254,8.68123498 C6.90697096,8.68123498 6.74560512,8.57237937 6.63802789,8.40909595 C6.47666206,8.13695692 6.58423928,7.81039009 6.85318234,7.64710667 L12.5547752,4.32701054 L7.1221254,1.22462562 L0.82885777,4.81686079 C0.559914709,4.9801442 0.237183035,4.87128859 0.0758171986,4.59914957 C-0.0855486382,4.32701054 0.0220285863,4.0004437 0.290971648,3.83716029 L6.85318234,0.0816417083 C7.01454818,-0.0272139028 7.22970263,-0.0272139028 7.39106847,0.0816417083 L13.9532792,3.83716029 C14.114645,3.9460159 14.2222222,4.10929932 14.2222222,4.32701054 C14.2222222,4.54472176 14.114645,4.70800518 13.9532792,4.81686079 L7.39106847,8.68123498 C7.28349124,8.68123498 7.17591402,8.68123498 7.1221254,8.68123498 Z"
                id="Path"
              />
              <path
                d="M7.1221254,16.3555556 C7.01454818,16.3555556 6.90697096,16.3555556 6.85318234,16.3011278 L0.0220285863,12.2734701 L0.0220285863,4.27258273 C0.0220285863,3.9460159 0.290971648,3.72830468 0.559914709,3.72830468 C0.882646382,3.72830468 1.09780083,4.0004437 1.09780083,4.27258273 L1.09780083,11.6203365 L7.39106847,15.3214273 C7.66001153,15.4847107 7.76758875,15.8112775 7.60622291,16.0834165 C7.49864569,16.2466999 7.28349124,16.3555556 7.1221254,16.3555556 Z"
                id="Path"
              />
              <path
                d="M7.1221254,16.3555556 C6.90697096,16.3555556 6.74560512,16.2466999 6.63802789,16.0834165 C6.47666206,15.8112775 6.58423928,15.4847107 6.85318234,15.3214273 L13.14645,11.6203365 L13.14645,4.27258273 C13.14645,3.9460159 13.415393,3.72830468 13.6843361,3.72830468 C14.0070678,3.72830468 14.2222222,4.0004437 14.2222222,4.27258273 L14.2222222,12.2734701 L7.39106847,16.3011278 C7.28349124,16.3555556 7.17591402,16.3555556 7.1221254,16.3555556 Z"
                id="Path"
              />
              <path
                d="M7.1221254,13.7974487 C6.79939373,13.7974487 6.58423928,13.5253097 6.58423928,13.2531706 L6.58423928,8.13695692 C6.58423928,7.81039009 6.85318234,7.59267887 7.1221254,7.59267887 C7.44485708,7.59267887 7.66001153,7.8648179 7.66001153,8.13695692 L7.66001153,13.2531706 C7.66001153,13.5253097 7.39106847,13.7974487 7.1221254,13.7974487 Z"
                id="Path"
              />
            </g>
            <g id="Group">
              <path
                d="M0.552612504,10.8770565 C0.498418541,10.8770565 0.390030615,10.8770565 0.335836652,10.8214209 C0.0648668382,10.7101495 -0.0977150502,10.3206996 0.0648668382,10.0425212 C1.09455213,7.65018638 2.77456498,5.42475868 4.83393557,3.70005222 C7.38105182,1.64153159 10.3075258,0.361910668 13.3965817,0.0837322056 C19.1953357,-0.528260412 25.1024777,2.25352421 28.4083094,7.14946515 C28.5708913,7.42764361 28.5166973,7.76145777 28.2457275,7.98400054 C27.9747577,8.15090761 27.6495939,8.09527192 27.4328181,7.81709346 C24.3979561,3.31060237 18.8701719,0.751360515 13.5049696,1.30771744 C10.6868836,1.5858959 7.81460352,2.80988114 5.53845708,4.70149468 C3.58747442,6.31492976 2.0158495,8.31781469 1.09455213,10.5988781 C0.986164206,10.7657852 0.769388355,10.8770565 0.552612504,10.8770565 Z"
                id="Path"
              />
              <path
                d="M30.5218739,11.3777778 L25.5360294,10.5432424 C25.2108656,10.4876067 24.9940897,10.2094282 25.0482837,9.87561408 C25.1024777,9.54179993 25.3734475,9.31925716 25.6986113,9.37489285 L29.5463826,9.98688547 L30.1425162,6.20365838 C30.1967102,5.86984422 30.46768,5.64730145 30.7928438,5.70293715 C31.1180075,5.75857284 31.3347834,6.0367513 31.2805894,6.37056546 L30.5218739,11.3777778 Z"
                id="Path"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
