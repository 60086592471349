import * as React from 'react';
import {SVGProps} from 'react';

const APOnbProcessingArrowIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={8} height={12} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M.64 11.084V.916A.91.91 0 0 1 2.198.269l5.08 5.09a.91.91 0 0 1 0 1.283l-5.08 5.089a.91.91 0 0 1-1.556-.647Z"
      className="fill-primary"
    />
  </svg>
);

export default APOnbProcessingArrowIcon;
