import React from 'react';

export const IconProductsRebranding = (props: {width?: string; height?: string}) => {
  return (
    <svg
      width={props.width || '20px'}
      height={props.height || '20px'}
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Products</title>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Products" className="fill-primary">
          <path
            d="M17.5,5 L15,5 C15,2.23857625 12.7614237,0 10,0 C7.23857625,0 5,2.23857625 5,5 L2.5,5 C1.11928813,5 0,6.11928813 0,7.5 L0,15.8333333 C0.00275509695,18.1333776 1.86662237,19.9972449 4.16666667,20 L15.8333333,20 C18.1333776,19.9972449 19.9972449,18.1333776 20,15.8333333 L20,7.5 C20,6.11928813 18.8807119,5 17.5,5 Z M10,1.66666667 C11.8409492,1.66666667 13.3333333,3.15905083 13.3333333,5 L6.66666667,5 C6.66666667,3.15905083 8.15905083,1.66666667 10,1.66666667 Z M18.3333333,15.8333333 C18.3333333,17.2140452 17.2140452,18.3333333 15.8333333,18.3333333 L4.16666667,18.3333333 C2.78595479,18.3333333 1.66666667,17.2140452 1.66666667,15.8333333 L1.66666667,7.5 C1.66666667,7.03976271 2.03976271,6.66666667 2.5,6.66666667 L5,6.66666667 L5,8.33333333 C5,8.79357062 5.37309604,9.16666667 5.83333333,9.16666667 C6.29357062,9.16666667 6.66666667,8.79357062 6.66666667,8.33333333 L6.66666667,6.66666667 L13.3333333,6.66666667 L13.3333333,8.33333333 C13.3333333,8.79357062 13.7064294,9.16666667 14.1666667,9.16666667 C14.626904,9.16666667 15,8.79357062 15,8.33333333 L15,6.66666667 L17.5,6.66666667 C17.9602373,6.66666667 18.3333333,7.03976271 18.3333333,7.5 L18.3333333,15.8333333 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </svg>
  );
};
