import React, {useEffect, useState} from 'react';
import {Formik, FormikHelpers, Form, FormikProps} from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import {isEmpty} from 'lodash';

import {Inventory as IInventory} from '../../model/inventory/Inventory';
import {SDialog} from '../modals/modalSpendaMeterialUI';
import {InventoryClass} from '../../model/inventory/InventoryClass';
import {DatTypes, InventoryType} from '../../model/constants/Constants';
import {ICategory} from '../../model/inventory/Category';
import {IActionResults} from '../../model/ActionResults';
import {IAccounts} from '../../model/accounts/Accounts';
import {ISupplier} from '../../model/supplier/Supplier';
import {FileDetails} from '../../model/FileDetails';
import {IBrand} from '../../model/inventory/Brand';
import {PrimaryButton, SecondaryButton} from '../buttons/DefaultButtons';
import {useInventoryClassAPI} from '../../services/useInventoryClassesAPI';
import {useInventoryAPI} from '../../services/useInventoryAPI';
import useCategoryAPI from '../../services/useCategoryAPI';
import {useSupplierAPI} from '../../services/useSupplierAPI';
import useAccountAPI from '../../services/useAccountAPI';
import useBrandAPI from '../../services/useBrandAPI';
import {CurrencyTextField, STextField} from '../inputs/STextField';
import {MediaUploader} from '../inputs/MediaUploader';
import {SCheckbox} from '../inputs/SCheckbox';
import {SwitchSlider} from '../form/SwitchSlider';
import {FilterMenuItem} from '../menu/FilterMenu';
import {STabs} from '../tabs/STabs';
import LoadingOverLay from '../ui/LoadingOverlay';
import {Toast} from '../../utils/Toast';
import {Image} from '../ui/Image';
import PriceCalculator from '../../utils/priceCalculator';
import UnitofMeasure from '../../assets/data/UnitofMeasure.json';

import {DialogActions, DialogContent, DialogTitle, InputAdornment, makeStyles} from '@material-ui/core';
import UploadFileIcon from '../../assets/svg/upload.svg';

interface IEditInventoryDialog {
  open: boolean;
  onClose: (inventory?: IInventory) => void;
  inventoryId?: any;
}

const useCreateSupplierDialog = makeStyles(() => ({
  dialogPaper: {
    minWidth: '1050px',
    minHeight: '595px',
    fontFamily: 'Poppins, sans-serif',
    zIndex: 9999,
  },
  dialogTitle: {
    borderBottom: '1px solid #EAEAEA',
    '& h2': {
      fontSize: '22px',
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 300,
    },
  },
  dialogContent: {
    paddingLeft: '112px',
    paddingRight: '112px',
  },
  dialogActions: {
    margin: '8px',
    borderRadius: '8px',
    backgroundColor: '#ADADAD20',
    padding: '8px 12px',
    justifyContent: 'space-between',
  },
}));

const newProduct: Partial<IInventory> = {
  ShortDescription: '',
  InventoryCode: '',
  InventoryClassID: '',
  InventoryClassName: '',
  SKU: '',
  UoM: '',
  UoMDescription: '',
  Barcode: '',
  Type: '',
  Description: '',
  ExpenseAccount: '',
  RevenueAccount: '',
  HTMLDescription: '',
  IsSold: false,
  SellPriceEx: 0,
  RRP: 0,
  StandardSellPriceEx: 0,
  StandardSellPriceInc: 0,
  LastBuyPriceEx: 0,
  IsOrderTaxExempt: false,
  IsPublished: false,
  IsPurchased: false,
  IsSOHTracked: false,
  IsVariantMaster: false,
  CostPriceEx: 0,
  IsPhysical: false,
  Width_m: '',
  Length_m: '',
  Depth_m: '',
  Weight_kg: '',
  Volume: '',
  IsActive: true,
};

const validationSchema = Yup.object({});

export const EditInventoryDialog = (props: IEditInventoryDialog) => {
  const classes = useCreateSupplierDialog();
  const {inventoryId, open, onClose} = props;

  const [inventoryToEdit, setInventoryToEdit] = useState<IInventory>(newProduct);
  const [btnClicked, setBtnClicked] = useState<string>('');
  const [productTab, setProductTab] = useState<number>(0);
  const [inventoryClass, setInventoryClass] = useState<InventoryClass[]>([]);
  const [brand, setBrand] = useState<IBrand[]>([]);
  const [category, setCategory] = useState<ICategory[]>([]);
  const [supplier, setSupplier] = useState<ISupplier[]>([]);
  const [expenseAccount, setExpenseAccount] = useState<IAccounts[]>([]);
  const [revenueAccount, setRevenueAccount] = useState<IAccounts[]>([]);

  const {getInventoryById, updateInventory, isLoading} = useInventoryAPI();
  const {dashboard: dashboardInventoryClass} = useInventoryClassAPI();
  const {dashboard: dashboardBrand} = useBrandAPI();
  const {dashboard: dashboardCategory} = useCategoryAPI();
  const {search: searchSupplier} = useSupplierAPI();
  const {listAccounts} = useAccountAPI();

  useEffect(() => {
    if (open && inventoryId) {
      getInventory(inventoryId);
      setProductTab(0);
    }
  }, [open, inventoryId]);

  const getInventory = async (id: any) => {
    const inventory = await getInventoryById(id);
    getInventoryAttributes(inventory);
    setInventoryToEdit(inventory);
  };

  const getInventoryAttributes = (_inventory: IInventory) => {
    Promise.all([
      dashboardInventoryClass({
        DatTypeID: DatTypes.InventoryClass,
        MaxResults: 15,
      }),
      dashboardBrand({
        DatTypeID: DatTypes.Brands,
        MaxResults: 15,
      }),
      dashboardCategory({
        DatTypeID: DatTypes.Category,
        MaxResults: 15,
      }),
      searchSupplier({
        MaxResults: 15,
      }),
      listAccounts({
        MaxResults: 15,
        ['Types[]' as string]: ['Expense', 'Direct_Costs'],
      }),
      listAccounts({
        MaxResults: 15,
        ['Types[]' as string]: ['Revenue', 'Sales'],
      }),
    ]).then(([_inventoryClass, _brand, _category, _supplier, _expenseAccount, _revenueAccount]) => {
      setInventoryClass(_inventoryClass.Value || []);
      setBrand(_brand.Value || []);
      setCategory(_category.Value || []);
      setSupplier(_supplier || []);
      setExpenseAccount(_expenseAccount.Value || []);
      setRevenueAccount(_revenueAccount.Value || []);
    });
  };

  const onKeyDown = (keyEvent: React.KeyboardEvent<HTMLFormElement>) => {
    if (keyEvent && keyEvent.key === 'Enter') {
      keyEvent.preventDefault();
    }
  };

  const onSubmit = async (values: IInventory, helpers: FormikHelpers<IInventory>): Promise<void> => {
    return updateInventory(values).then(res => {
      if (res) {
        Toast.info(`Supplier '${values.ShortDescription}' updated.`);
        if (btnClicked == 'save_and_close') {
          onClose(res.Value);
          helpers.resetForm();
        }
      }
    });
  };

  return (
    <SDialog classes={{paper: classes.dialogPaper}} fullWidth maxWidth="md" open={open}>
      <LoadingOverLay isLoading={isLoading} />
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={inventoryToEdit}
        onSubmit={onSubmit}
      >
        {props => (
          <>
            <DialogTitle className={clsx(classes.dialogTitle, 'text-center font-light text-spenda-primarytext')}>
              Purchase invoice details
            </DialogTitle>
            <DialogContent className={clsx(classes.dialogContent)}>
              <Form id="supplier-form" onKeyDown={onKeyDown} onSubmit={props.handleSubmit} className="mt-7">
                <STabs
                  v2
                  value={productTab}
                  handleChange={newValue => setProductTab(newValue)}
                  className="mt-10 flex-1"
                  tabs={[
                    {
                      label: 'Basic info',
                      content: (
                        <BasicDetails {...props} inventoryClass={inventoryClass} brand={brand} category={category} />
                      ),
                    },
                    {
                      label: 'Pricing',
                      content: (
                        <PriceDetails
                          {...props}
                          supplier={supplier}
                          expenseAccount={expenseAccount}
                          revenueAccount={revenueAccount}
                        />
                      ),
                    },
                    {
                      label: 'Media',
                      content: <MediaDetails {...props} />,
                    },
                    {
                      label: 'Warehousing',
                      content: <WarehousingDetails {...props} />,
                    },
                  ]}
                ></STabs>
              </Form>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              <SecondaryButton
                label="Cancel"
                onClick={() => {
                  setInventoryToEdit({});
                  props.resetForm();
                  onClose();
                }}
              />
              <div>
                <SecondaryButton
                  isSubmitting={isLoading}
                  disabled={isLoading}
                  label="Save"
                  onClick={() => {
                    setBtnClicked('save');
                    props.handleSubmit();
                  }}
                />
                <PrimaryButton
                  isSubmitting={isLoading}
                  disabled={isLoading}
                  form="supplier-form"
                  margin="0 0 0 8px"
                  label="Save & Close"
                  onClick={() => {
                    setBtnClicked('save_and_close');
                    props.handleSubmit();
                  }}
                />
              </div>
            </DialogActions>
          </>
        )}
      </Formik>
    </SDialog>
  );
};

const BasicDetails = (
  props: FormikProps<IInventory> & {inventoryClass: InventoryClass[]; brand: IBrand[]; category: ICategory[]},
) => {
  const {inventoryCodeUnique} = useInventoryAPI();

  const {
    values,
    touched,
    errors,
    status,
    handleChange,
    handleBlur,
    setFieldValue,
    setStatus,
    setValues,
    inventoryClass,
    brand,
    category,
  } = props;

  const handleInventoryClass = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const invClass = inventoryClass.find(invClass => String(invClass.ID) === String(e.target.value));
    if (invClass) {
      setValues(Object.assign(values, {InventoryClassID: invClass.ID, InventoryClassName: invClass.Name}));
    }
  };

  const handleCategory = (e: React.ChangeEvent<{value: unknown}>) => {
    const cat = category.filter(cat => ((e.target.value as Number[]) || []).includes(Number(cat.ID)));
    if (cat) {
      setFieldValue('Categories', cat);
    } else {
      setFieldValue('Categories', []);
    }
  };

  const handleBrand = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const br = brand.find(br => String(br.ID) === String(e.target.value));
    if (br) {
      setValues(Object.assign(values, {Brand: br}));
    }
  };

  const handleUoM = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const uom = UnitofMeasure.find((uom: {name: string; value: string}) => uom.value === e.target.value);
    if (uom) {
      setValues(Object.assign(values, {UoM: uom.value, UoMDescription: uom?.name.split(' - ')[1]}));
    }
  };

  const checkInventoryCode = () => {
    inventoryCodeUnique({InventoryCode: values.InventoryCode, InventoryID: values?.ID || undefined}).then(
      (res: IActionResults<any>) => {
        if (res.Value > 0) {
          setStatus({Name: 'Inventory code already exists'});
        } else {
          setStatus(null);
        }
      },
    );
  };

  return (
    <div className="grid grid-cols-12 gap-x-[22px] gap-y-[18px]">
      <STextField
        v2
        fullWidth
        className="col-span-5"
        id="ShortDescription"
        name="ShortDescription"
        placeholder="Product name"
        label="Product name"
        value={values.ShortDescription?.trimStart() || ''}
        onChange={handleChange}
        onBlur={handleBlur}
        helperText={touched?.ShortDescription && errors?.ShortDescription}
        error={touched?.ShortDescription && Boolean(errors?.ShortDescription)}
      />
      <STextField
        v2
        fullWidth
        className="col-span-3"
        id="InventoryCode"
        name="InventoryCode"
        placeholder="Code"
        label="Code"
        value={values.InventoryCode?.trimStart() || ''}
        onChange={handleChange}
        onBlur={checkInventoryCode}
        helperText={(touched?.InventoryCode && errors?.InventoryCode) || (!!status && status?.Name)}
        error={(touched?.InventoryCode && Boolean(errors?.InventoryCode)) || !!status}
        inputProps={{className: 'text-right'}}
      />
      <STextField
        v2
        select
        fullWidth
        defaultValue=""
        className="col-span-4"
        id="InventoryClassID"
        name="InventoryClassID"
        label="Select a product class"
        placeholder="Select a product class"
        value={values.InventoryClassID || ''}
        onChange={handleInventoryClass}
        onBlur={handleBlur}
        helperText={touched?.InventoryClassID && (errors?.InventoryClassID as string)}
        error={touched?.InventoryClassID && Boolean(errors?.InventoryClassID)}
        SelectProps={{
          MenuProps: {
            anchorOrigin: {vertical: 'bottom', horizontal: 'right'},
            transformOrigin: {vertical: 'top', horizontal: 'right'},
            getContentAnchorEl: null,
          },
        }}
      >
        <FilterMenuItem value="" disabled>
          Select a product class
        </FilterMenuItem>
        {inventoryClass.map(invClass => (
          <FilterMenuItem key={invClass.ID} value={invClass.ID}>
            {invClass.Name}
          </FilterMenuItem>
        ))}
      </STextField>
      <STextField
        v2
        fullWidth
        multiline
        minRows={9}
        className="col-span-5 row-span-3"
        id="Description"
        name="Description"
        placeholder="Sales description"
        label="Sales description"
        value={values.Description || ''}
        onChange={handleChange}
        helperText={touched?.Description && errors?.Description}
        error={touched?.Description && Boolean(errors?.Description)}
      />
      <STextField
        v2
        fullWidth
        className="col-span-3"
        id="Barcode"
        name="Barcode"
        placeholder="0"
        label="Barcode"
        value={values.Barcode?.trimStart() || ''}
        onChange={handleChange}
        helperText={touched?.Barcode && errors?.Barcode}
        error={touched?.Barcode && Boolean(errors?.Barcode)}
        inputProps={{className: 'text-right'}}
      />
      <STextField
        v2
        select
        fullWidth
        defaultValue=""
        className="col-span-4"
        id="Type"
        name="Type"
        label="Select a type"
        placeholder="Select a type"
        value={values.Type || ''}
        onChange={handleChange}
        onBlur={handleBlur}
        helperText={touched?.Type && errors?.Type}
        error={touched?.Type && Boolean(errors?.Type)}
        SelectProps={{
          MenuProps: {
            anchorOrigin: {vertical: 'bottom', horizontal: 'right'},
            transformOrigin: {vertical: 'top', horizontal: 'right'},
            getContentAnchorEl: null,
          },
        }}
      >
        <FilterMenuItem value="" disabled>
          Select a type
        </FilterMenuItem>
        <FilterMenuItem key={InventoryType.Inventory} value={InventoryType.Inventory}>
          {InventoryType.Inventory}
        </FilterMenuItem>
        <FilterMenuItem key={InventoryType.Fee} value={InventoryType.Fee}>
          {InventoryType.Fee}
        </FilterMenuItem>
      </STextField>
      <STextField
        v2
        select
        fullWidth
        defaultValue=""
        className="col-span-3"
        id="UoM"
        name="UoM"
        label="Select a UoM"
        placeholder="Select a UoM"
        value={values.UoM || ''}
        onChange={handleUoM}
        onBlur={handleBlur}
        helperText={touched?.UoM && errors?.UoM}
        error={touched?.UoM && Boolean(errors?.UoM)}
        SelectProps={{
          MenuProps: {
            anchorOrigin: {vertical: 'bottom', horizontal: 'right'},
            transformOrigin: {vertical: 'top', horizontal: 'right'},
            getContentAnchorEl: null,
          },
        }}
      >
        <FilterMenuItem value="" disabled>
          Select a UoM
        </FilterMenuItem>
        {UnitofMeasure.map((uom: {name: string; value: string}) => (
          <FilterMenuItem key={uom.value} value={uom.value}>
            {uom.name}
          </FilterMenuItem>
        ))}
      </STextField>
      <STextField
        v2
        select
        fullWidth
        defaultValue=""
        className="col-span-4"
        id="Brand"
        name="Brand"
        label="Select a brand"
        placeholder="Select a brand"
        value={values?.Brand?.ID || ''}
        onChange={handleBrand}
        onBlur={handleBlur}
        helperText={touched?.Brand && errors?.Brand}
        error={touched?.Brand && Boolean(errors?.Brand)}
        SelectProps={{
          MenuProps: {
            anchorOrigin: {vertical: 'bottom', horizontal: 'right'},
            transformOrigin: {vertical: 'top', horizontal: 'right'},
            getContentAnchorEl: null,
          },
        }}
      >
        <FilterMenuItem value="" disabled>
          Select a brand
        </FilterMenuItem>
        {brand.map(br => (
          <FilterMenuItem key={br.ID} value={br.ID}>
            {br.Name}
          </FilterMenuItem>
        ))}
      </STextField>
      <STextField
        v2
        fullWidth
        className="col-span-3"
        id="SKU"
        name="SKU"
        placeholder="SKU"
        label="SKU"
        value={values.SKU?.trimStart() || ''}
        onChange={handleChange}
        helperText={touched?.SKU && errors?.SKU}
        error={touched?.SKU && Boolean(errors?.SKU)}
        inputProps={{className: 'text-right'}}
      />
      <STextField
        v2
        select
        fullWidth
        defaultValue=""
        className="col-span-4"
        id="Categories"
        name="Categories"
        label="Select a category"
        placeholder="Select a category"
        value={values.Categories || []}
        onChange={handleCategory}
        onBlur={handleBlur}
        helperText={touched?.Categories && errors?.Categories}
        error={touched?.Categories && Boolean(errors?.Categories)}
        SelectProps={{
          multiple: true,
          renderValue: (value: unknown): React.ReactNode =>
            (value as ICategory[]).map(cat => cat?.Name || '').join(', '),
          MenuProps: {
            anchorOrigin: {vertical: 'bottom', horizontal: 'right'},
            transformOrigin: {vertical: 'top', horizontal: 'right'},
            getContentAnchorEl: null,
          },
        }}
      >
        <FilterMenuItem value="" disabled>
          Select a category
        </FilterMenuItem>
        {category.map(cat => (
          <FilterMenuItem key={cat.ID} value={cat.ID}>
            {cat.Name}
          </FilterMenuItem>
        ))}
      </STextField>
      <div className="col-span-3">
        <SCheckbox
          name="IsActive"
          fontFamily="Poppins"
          label="Archive product"
          lableFontWeight={400}
          lableFontSize="12px"
          checked={!values.IsActive}
          onChange={event => setFieldValue('IsActive', !event.target.checked)}
        />
      </div>
    </div>
  );
};

interface IMarginMarkup {
  markupPercent: number | string;
  marginPrice: number | string;
  marginPercent: number | string;
}

const PriceDetails = (
  props: FormikProps<IInventory> & {supplier: ISupplier[]; expenseAccount: IAccounts[]; revenueAccount: IAccounts[]},
) => {
  const [marginMarkup, setMarginMarkup] = useState<IMarginMarkup>({
    markupPercent: '',
    marginPrice: '',
    marginPercent: '',
  });

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    setValues,
    supplier,
    expenseAccount,
    revenueAccount,
  } = props;

  useEffect(() => {
    setNewMarginMarkup(values.CostPriceEx || 0, values.StandardSellPriceEx || 0);
  }, []);

  const handlePriceBlur = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.target) {
      switch (event.target.name) {
        case 'StandardSellPriceEx':
          setNewMarginMarkup(parseFloat(values.CostPriceEx + ''), parseFloat(event.target.value));
          setFieldValue(
            'StandardSellPriceInc',
            PriceCalculator.getSellIncFromEx(parseFloat(event.target.value), values.IsOrderTaxExempt ? 0 : 0.1),
          );
          break;
        case 'StandardSellPriceInc':
          const newSellEx = PriceCalculator.getSellExFromInc(
            parseFloat(event.target.value),
            values.IsOrderTaxExempt ? 0 : 0.1,
          );
          setNewMarginMarkup(parseFloat(values.CostPriceEx + ''), newSellEx);
          setFieldValue('StandardSellPriceEx', newSellEx);
          break;
        case 'CostPriceEx':
          setNewMarginMarkup(parseFloat(event.target.value), values.StandardSellPriceEx || 0);
          setFieldValue('CostPriceEx', event.target.value);
          break;
      }
    }
  };

  const handleMarkupBlur = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.target) {
      let newSellEx;
      switch (event.target.name) {
        case 'markupPercent':
          newSellEx = PriceCalculator.getSellExByMarkupPercent(
            parseFloat(values.CostPriceEx + ''),
            parseFloat(event.target.value),
          );
          setNewSellPrice(newSellEx, 'markupPercent');
          break;
        case 'marginPrice':
          newSellEx = PriceCalculator.getSellExByMarginAmount(
            parseFloat(values.CostPriceEx + ''),
            parseFloat(event.target.value),
          );
          setNewSellPrice(newSellEx, 'marginPrice');
          break;
        case 'marginPercent':
          newSellEx = PriceCalculator.getSellExByMarginPercent(
            parseFloat(values.CostPriceEx + ''),
            parseFloat(event.target.value),
          );
          setNewSellPrice(newSellEx, 'marginPercent');
          break;
      }
    }
  };

  const handleMarginMarkupChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target && /^\d+(\.\d{0,2})?$|^$/.test(event.target.value)) {
      setMarginMarkup({
        ...marginMarkup,
        [event.target.name]: event.target.value,
      });
    }
  };

  const setNewMarginMarkup = (cost: number, sell: number) => {
    setMarginMarkup({
      markupPercent: PriceCalculator.getMarkupPercent(cost, sell),
      marginPrice: PriceCalculator.getMarginAmount(cost, sell),
      marginPercent: PriceCalculator.getMarginPercent(cost, sell),
    });
  };

  const setNewSellPrice = (newSellEx: number, name: 'markupPercent' | 'marginPrice' | 'marginPercent') => {
    const newMarginMarkup = {
      markupPercent: PriceCalculator.getMarkupPercent(parseFloat(values.CostPriceEx + ''), newSellEx),
      marginPrice: PriceCalculator.getMarginAmount(parseFloat(values.CostPriceEx + ''), newSellEx),
      marginPercent: PriceCalculator.getMarginPercent(parseFloat(values.CostPriceEx + ''), newSellEx),
    } as IMarginMarkup;
    delete newMarginMarkup[name];
    setMarginMarkup({
      ...marginMarkup,
      ...newMarginMarkup,
    });
    setFieldValue('StandardSellPriceEx', newSellEx);
    setFieldValue(
      'StandardSellPriceInc',
      PriceCalculator.getSellIncFromEx(newSellEx, values.IsOrderTaxExempt ? 0 : 0.1),
    );
  };

  const handleTaxExempt = (checked: boolean) => {
    if (checked) {
      setFieldValue('StandardSellPriceInc', values.StandardSellPriceEx);
    } else {
      const newSellInc = PriceCalculator.getSellIncFromEx(parseFloat(values.StandardSellPriceEx + ''), 0.1);
      setFieldValue('StandardSellPriceInc', newSellInc);
      setNewMarginMarkup(parseFloat(values.CostPriceEx + ''), values.StandardSellPriceEx || 0);
    }
  };

  const changeCheckbox = (
    name: 'IsSold' | 'IsPurchased' | 'IsOrderTaxExempt' | 'IsPublished' | 'IsApproved',
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    e.persist();
    setFieldValue(name, e.target.checked);
    setFieldTouched(name, true, false);
    if (name === 'IsOrderTaxExempt' && values.StandardSellPriceEx) {
      handleTaxExempt(e.target.checked);
    }
  };

  const handleSupplier = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const sup = supplier.find(sup => String(sup.ID) === String(e.target.value));
    if (sup) {
      setValues(Object.assign(values, {DefaultSupplierID: sup.ID, DefaultSupplierName: sup.Name}));
    }
  };

  const handleExpenseAccount = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setFieldValue('ExpenseAccount', e.target.value || null);
  };

  const handleRevenueAccount = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setFieldValue('RevenueAccount', e.target.value || null);
  };

  return (
    <div className="grid grid-cols-10 gap-x-[22px] gap-y-[18px]">
      <div className="col-span-10">
        <SwitchSlider
          label={<span className="ml-2 font-poppins text-base font-medium">I sell this product</span>}
          name="IsSold"
          checked={Boolean(values.IsSold)}
          handleChange={changeCheckbox.bind(null, 'IsSold')}
          labelPlacement="end"
        />
      </div>
      {values.IsSold ? (
        <>
          <CurrencyTextField
            fullWidth
            className="col-span-2"
            id="LastBuyPriceEx"
            name="LastBuyPriceEx"
            placeholder="$0.00"
            label="Last cost (ex)"
            value={values.LastBuyPriceEx}
            onChange={handleChange}
            onBlur={handlePriceBlur}
            inputProps={{className: 'text-right'}}
          />
          <CurrencyTextField
            fullWidth
            className="col-span-2"
            id="CostPriceEx"
            name="CostPriceEx"
            label="Cost (ex)"
            placeholder="$0.00"
            value={values.CostPriceEx}
            onChange={handleChange}
            onBlur={handlePriceBlur}
            inputProps={{className: 'text-right'}}
          />
          <CurrencyTextField
            fullWidth
            className="col-span-2"
            id="StandardSellPriceEx"
            name="StandardSellPriceEx"
            label="Sell price (ex)"
            placeholder="$0.00"
            value={values.StandardSellPriceEx}
            onChange={handleChange}
            onBlur={handlePriceBlur}
            inputProps={{className: 'text-right'}}
          />
          <CurrencyTextField
            fullWidth
            className="col-span-2"
            id="RRP"
            name="RRP"
            placeholder="$0.00"
            label="RRP (inc)"
            value={values.RRP}
            onChange={handleChange}
            onBlur={handlePriceBlur}
            inputProps={{className: 'text-right'}}
          />
          <CurrencyTextField
            fullWidth
            className="col-span-2"
            id="StandardSellPriceInc"
            name="StandardSellPriceInc"
            placeholder="$0.00"
            label="Sell price (inc)"
            value={values.StandardSellPriceInc}
            onChange={handleChange}
            onBlur={handlePriceBlur}
            inputProps={{className: 'text-right'}}
          />
          <div className="col-span-6 -mb-2">
            <SCheckbox
              name="IsOrderTaxExempt"
              fontFamily="Poppins"
              lableFontWeight={400}
              lableFontSize="12px"
              label="Is Tax Exempt"
              checked={values.IsOrderTaxExempt}
              onChange={changeCheckbox.bind(null, 'IsOrderTaxExempt')}
            />
          </div>
          <div className="col-span-4 row-span-3 grid grid-cols-3 rounded bg-[#D3E5EF50] p-4">
            <div className="mr-2.5 flex flex-col items-center">
              <p className="mb-3 text-center text-xs font-bold text-primary">Markup</p>
              <STextField
                v2
                fullWidth
                id="markupPercent"
                name="markupPercent"
                placeholder="%"
                value={marginMarkup.markupPercent}
                onChange={handleMarginMarkupChange}
                onBlur={handleMarkupBlur}
                inputProps={{className: 'text-right'}}
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
              />
            </div>
            <div className="col-span-2 ml-2.5 flex flex-col items-center">
              <p className="mb-3 text-center text-xs font-bold text-primary">Margin</p>
              <div className="flex flex-row gap-2">
                <CurrencyTextField
                  fullWidth
                  id="marginPrice"
                  name="marginPrice"
                  placeholder="$0.00"
                  value={marginMarkup.marginPrice}
                  onChange={handleMarginMarkupChange}
                  onBlur={handleMarkupBlur}
                />
                <STextField
                  v2
                  fullWidth
                  id="marginPercent"
                  name="marginPercent"
                  placeholder="%"
                  value={marginMarkup.marginPercent}
                  onChange={handleMarginMarkupChange}
                  onBlur={handleMarkupBlur}
                  inputProps={{style: {textAlign: 'center'}}}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-span-6 -mb-2">
            <SCheckbox
              name="IsPublished"
              fontFamily="Poppins"
              lableFontWeight={400}
              lableFontSize="12px"
              label="I publish this product on B2B e-commerce"
              checked={values.IsPublished}
              onChange={changeCheckbox.bind(null, 'IsPublished')}
            />
          </div>
          <div className="col-span-6 -mb-2">
            <SCheckbox
              name="IsApproved"
              fontFamily="Poppins"
              lableFontWeight={400}
              lableFontSize="12px"
              label="I publish this product on third party e-commerce"
              checked={values.IsApproved}
              onChange={changeCheckbox.bind(null, 'IsApproved')}
            />
          </div>
        </>
      ) : null}
      <div className="col-span-10 mt-8">
        <SwitchSlider
          label={<span className="ml-2 font-poppins text-base font-medium">I Purchase this product</span>}
          name="IsSold"
          checked={Boolean(values.IsPurchased)}
          handleChange={changeCheckbox.bind(null, 'IsPurchased')}
          labelPlacement="end"
        />
      </div>
      {values.IsPurchased ? (
        <>
          <CurrencyTextField
            fullWidth
            className="col-span-3"
            id="CostPriceEx"
            name="CostPriceEx"
            placeholder="$0.00"
            label="Cost price (ex)"
            value={values.CostPriceEx}
            onChange={handleChange}
            inputProps={{className: 'text-right'}}
          />
          <STextField
            v2
            select
            fullWidth
            defaultValue=""
            className="col-span-5"
            id="DefaultSupplierID"
            name="DefaultSupplierID"
            label="Select a Supplier"
            placeholder="Select a Supplier"
            value={values.DefaultSupplierID || ''}
            onChange={handleSupplier}
            onBlur={handleBlur}
            helperText={touched?.DefaultSupplierID && (errors?.DefaultSupplierID as string)}
            error={touched?.DefaultSupplierID && Boolean(errors?.DefaultSupplierID)}
            SelectProps={{
              MenuProps: {
                anchorOrigin: {vertical: 'bottom', horizontal: 'right'},
                transformOrigin: {vertical: 'top', horizontal: 'right'},
                getContentAnchorEl: null,
              },
            }}
          >
            <FilterMenuItem value="" disabled>
              Select a Supplier
            </FilterMenuItem>
            {supplier.map(sup => (
              <FilterMenuItem key={sup.ID} value={sup.ID}>
                {sup.Name}
              </FilterMenuItem>
            ))}
          </STextField>
          <div className="col-span-2 row-span-2" />
          <STextField
            v2
            select
            fullWidth
            defaultValue=""
            className="col-span-4"
            id="ExpenseAccount"
            name="ExpenseAccount"
            label="Select an expense account"
            placeholder="Select an expense account"
            value={values.ExpenseAccount || ''}
            onChange={handleExpenseAccount}
            onBlur={handleBlur}
            helperText={touched?.ExpenseAccount && errors?.ExpenseAccount}
            error={touched?.ExpenseAccount && Boolean(errors?.ExpenseAccount)}
            SelectProps={{
              MenuProps: {
                anchorOrigin: {vertical: 'bottom', horizontal: 'right'},
                transformOrigin: {vertical: 'top', horizontal: 'right'},
                getContentAnchorEl: null,
              },
            }}
          >
            <FilterMenuItem value="" disabled>
              Select an expense account
            </FilterMenuItem>
            {expenseAccount.map(expAcc => (
              <FilterMenuItem key={expAcc.ID} value={expAcc.ID}>
                {`${expAcc.Code} - ${expAcc.Name}`}
              </FilterMenuItem>
            ))}
          </STextField>
          <STextField
            v2
            select
            fullWidth
            defaultValue=""
            className="col-span-4"
            id="RevenueAccount"
            name="RevenueAccount"
            label="Select an revenue account"
            placeholder="Select an revenue account"
            value={values.RevenueAccount || ''}
            onChange={handleRevenueAccount}
            onBlur={handleBlur}
            helperText={touched?.RevenueAccount && errors?.RevenueAccount}
            error={touched?.RevenueAccount && Boolean(errors?.RevenueAccount)}
            SelectProps={{
              MenuProps: {
                anchorOrigin: {vertical: 'bottom', horizontal: 'right'},
                transformOrigin: {vertical: 'top', horizontal: 'right'},
                getContentAnchorEl: null,
              },
            }}
          >
            <FilterMenuItem value="" disabled>
              Select an revenue account
            </FilterMenuItem>
            {revenueAccount.map(revAcc => (
              <FilterMenuItem key={revAcc.ID} value={revAcc.ID}>
                {`${revAcc.Code} - ${revAcc.Name}`}
              </FilterMenuItem>
            ))}
          </STextField>
        </>
      ) : null}
    </div>
  );
};

const MediaDetails = (props: FormikProps<IInventory>) => {
  const {values, setFieldValue} = props;

  const onMediaUpload = (files: FileDetails[]) => {
    if (Array.isArray(values.MediaFiles)) {
      setFieldValue('MediaFiles', [...values.MediaFiles, ...files]);
    } else {
      setFieldValue('MediaFiles', files);
    }
  };

  const deleteMedia = (id: number | undefined) => {
    setFieldValue(
      'MediaFiles',
      (values.MediaFiles || []).filter((media: FileDetails) => media?.ID !== id),
    );
  };

  const setDefault = (id: number | undefined) => {
    setFieldValue(
      'MediaFiles',
      (values.MediaFiles || []).map((media: FileDetails) => {
        if (media.ID === id) {
          return Object.assign(media, {IsDefault: true});
        } else {
          return Object.assign(media, {IsDefault: false});
        }
      }),
    );
  };

  return (
    <>
      {values.MediaFiles && !isEmpty(values.MediaFiles) ? (
        <div className="grid grid-cols-4 gap-4">
          {values.MediaFiles.map((image: FileDetails, index: number) => (
            <Image
              v2
              key={`product-image-${index}`}
              alt={image.Name}
              src={image?.ThumbnailURL || ''}
              isDefault={image?.IsDefault}
              className={`flex justify-center`}
              onDelete={() => deleteMedia(image.ID)}
              onSetDefault={() => setDefault(image.ID)}
            />
          ))}
          <MediaUploader
            onChange={file => onMediaUpload(file)}
            className="h-full w-full rounded-[20px] border-[1px] border-dashed border-spenda-sBlue bg-[#EAEFF250]"
          >
            <div className="flex flex-col gap-4">
              <img src={UploadFileIcon} className="max-h-[45px]" />
              <div className="flex justify-center">
                <PrimaryButton label="Upload" />
              </div>
            </div>
          </MediaUploader>
        </div>
      ) : (
        <MediaUploader
          onChange={file => onMediaUpload(file)}
          className="m-auto h-[284px] w-[400px] rounded-[20px] border-[1px] border-dashed border-spenda-sBlue bg-[#EAEFF250]"
        >
          <div className="flex flex-col gap-10">
            <img src={UploadFileIcon} className="max-h-[75px]" />
            <span className="text-[17px] font-light text-spenda-primarytext">Drop or upload photos here</span>
            <div className="flex justify-center">
              <PrimaryButton label="Upload" />
            </div>
          </div>
        </MediaUploader>
      )}
    </>
  );
};

const WarehousingDetails = (props: FormikProps<IInventory>) => {
  const {values, touched, errors, handleChange, setFieldValue, setFieldTouched} = props;

  const changeCheckbox = (name: 'IsPhysical' | 'IsSOHTracked', e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    setFieldValue(name, e.target.checked);
    setFieldTouched(name, true, false);
  };

  return (
    <div className="grid grid-cols-10 gap-x-[22px] gap-y-[18px]">
      <div className="col-span-10">
        <SwitchSlider
          label={<span className="ml-2 font-poppins text-base font-medium">Is this product a physical item?</span>}
          name="IsPhysical"
          checked={Boolean(values.IsPhysical)}
          handleChange={changeCheckbox.bind(null, 'IsPhysical')}
          labelPlacement="end"
        />
      </div>
      {values.IsPhysical ? (
        <>
          <STextField
            v2
            fullWidth
            className="col-span-2"
            id="Weight_kg"
            name="Weight_kg"
            placeholder="0"
            label="Weight"
            value={values.Weight_kg}
            onChange={handleChange}
            helperText={touched?.Weight_kg && (errors?.Weight_kg as string)}
            error={touched?.Weight_kg && Boolean(errors?.Weight_kg)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <span className="font-poppins text-base font-semibold text-[#bbbbbb]">kg</span>
                </InputAdornment>
              ),
            }}
            inputProps={{className: 'text-right'}}
          />
          <STextField
            v2
            fullWidth
            className="col-span-2"
            id="Width_m"
            name="Width_m"
            placeholder="0.00"
            label="Width"
            value={values.Width_m}
            onChange={handleChange}
            helperText={touched?.Width_m && (errors?.Width_m as string)}
            error={touched?.Width_m && Boolean(errors?.Width_m)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <span className="font-poppins text-base font-semibold text-[#bbbbbb]">m</span>
                </InputAdornment>
              ),
            }}
            inputProps={{className: 'text-right'}}
          />
          <STextField
            v2
            fullWidth
            className="col-span-2"
            id="Length_m"
            name="Length_m"
            placeholder="0.00"
            label="Length"
            value={values.Length_m}
            onChange={handleChange}
            helperText={touched?.Length_m && (errors?.Length_m as string)}
            error={touched?.Length_m && Boolean(errors?.Length_m)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <span className="font-poppins text-base font-semibold text-[#bbbbbb]">m</span>
                </InputAdornment>
              ),
            }}
            inputProps={{className: 'text-right'}}
          />
          <STextField
            v2
            fullWidth
            className="col-span-2"
            id="Depth_m"
            name="Depth_m"
            placeholder="0.00"
            label="Depth"
            value={values.Depth_m}
            onChange={handleChange}
            helperText={touched?.Depth_m && (errors?.Depth_m as string)}
            error={touched?.Depth_m && Boolean(errors?.Depth_m)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <span className="font-poppins text-base font-semibold text-[#bbbbbb]">m</span>
                </InputAdornment>
              ),
            }}
            inputProps={{className: 'text-right'}}
          />
          <STextField
            v2
            fullWidth
            className="col-span-2"
            id="Volume"
            name="Volume"
            placeholder="0.00"
            label="Volume"
            value={values.Volume}
            onChange={handleChange}
            helperText={touched?.Volume && (errors?.Volume as string)}
            error={touched?.Volume && Boolean(errors?.Volume)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <span className="font-poppins text-base font-semibold text-[#bbbbbb]">
                    m<sup>3</sup>
                  </span>
                </InputAdornment>
              ),
            }}
            inputProps={{className: 'text-right'}}
          />
          <div className="col-span-10">
            <SCheckbox
              name="IsSOHTracked"
              fontFamily="Poppins"
              label="Archive product track SOH on this product"
              lableFontWeight={400}
              lableFontSize="12px"
              checked={values.IsSOHTracked}
              onChange={changeCheckbox.bind(null, 'IsSOHTracked')}
            />
          </div>
        </>
      ) : null}
    </div>
  );
};
