import {useEffect, useState} from 'react';
import {DatTypes} from '../model/constants/Constants';
import {IBrand} from '../model/inventory/Brand';
import {IActionResults, IPagedActionResults, IDashboardActionResults} from '../model/ActionResults';
import {IBrandFilter} from '../model/search-filters/BrandFilter';
import useHttp from '../hooks/useHttp';

const useBrandAPI = () => {
  const {isLoading, GET, PUT, POST, DELETE} = useHttp();

  const search = (filter: any): Promise<IBrand[]> => {
    return GET('supplierbrand', filter).then((data: IActionResults<IBrand[]>) => data.Value);
  };

  const dashboard = (filter: any): Promise<IDashboardActionResults<IBrand[]>> => {
    return GET('dashboard', filter).then((data: IDashboardActionResults<IBrand[]>) => data);
  };

  const listBrands = (filter: any): Promise<IPagedActionResults<IBrand[]>> => {
    return GET('brand', filter).then((data: IPagedActionResults<IBrand[]>) => data);
  };

  const createBrand = (payload: Partial<IBrand>): Promise<IBrand> => {
    return POST('brand', {Object: payload}).then((data: IActionResults<IBrand>) => data.Object!);
  };

  const getBrandById = (payload: Partial<IBrand>): Promise<IBrand> => {
    return GET(`brand/${payload.ID}`).then((data: IActionResults<IBrand>) => data.Value);
  };

  const updateBrand = (payload: Partial<IBrand>): Promise<IBrand> => {
    return PUT(`brand/${payload.ID}`, {Object: payload}).then((data: IActionResults<IBrand>) => data.Value);
  };

  const deleteBrand = (ID: number): Promise<IBrand> => {
    return DELETE(`brand/${ID}`);
  };

  return {
    search,
    dashboard,
    listBrands,
    createBrand,
    getBrandById,
    updateBrand,
    deleteBrand,
    isLoading,
  };
};

export default useBrandAPI;

export const useLoadBrand = () => {
  let pageSize = 15;

  const initialFilterState = {
    DatTypeID: DatTypes.Brands,
    IsExactMatch: false,
    IsShowActiveOnly: true,
    LastRowNumber: 0,
    MaxResults: pageSize,
    SortAsc: true,
    SortField: 'Name',
  } as IBrandFilter;

  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<IBrand[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [moreToGet, setMoreToGet] = useState<boolean>(true);
  const [error, setError] = useState<any>();
  const [searchFilter, setSearchFilter] = useState<IBrandFilter>(initialFilterState);

  const {dashboard} = useBrandAPI();

  const getBrandsList = async () => {
    setLoading(true);

    if (searchFilter.StartRow === 1) {
      setItems([]);
      setMoreToGet(true);
    }

    try {
      const {Value, IsMore, TotalRecordCount, LastRowNo} = await dashboard(searchFilter);
      let items: IBrand[] = Value || [];

      setItems(items);
      setTotalCount(TotalRecordCount);
      setMoreToGet(IsMore);
      setCurrentPage(Math.ceil(LastRowNo / (searchFilter.MaxResults || pageSize)));
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getBrandsList();
  }, [searchFilter]);

  return {
    loading,
    items,
    totalCount,
    pageSize: searchFilter.MaxResults || pageSize,
    currentPage,
    moreToGet,
    error,
    searchFilter,
    getBrandsList,
    setSearchFilter,
  };
};
