import React, {FunctionComponent, PropsWithChildren} from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import {TransitionProps} from '@material-ui/core/transitions';
import {makeStyles} from '@material-ui/core/styles';
import {SButton, color, lineHeight, width} from '../buttons/Button';
import {SDialog} from '../modals/modalSpendaMeterialUI';
import CloseIcon from '@material-ui/icons/Close';
import {ButtonType} from '../../model/constants/Constants';
import {DestructiveButton, PrimaryButton, SecondaryButton} from '../buttons/DefaultButtons';

export interface IDialogAction {
  label: string;
  action: (value?: any) => void;
  variant?: 'outlined' | 'text' | 'contained';
  color?: color;
  textColor?: color;
  disabled?: boolean;
  hidden?: boolean;
  lineHeight?: lineHeight;
  width?: width | string;
  border?: string;
  backgroundColor?: string;
  className?: string;
  fontSize?: string;
  fontFamily?: string;
  height?: string;
  borderRadius?: string;
  buttonType?: string;
  padding?: string;
  margin?: string;
}

interface IDialogProps extends PropsWithChildren {
  svg?: any;
  title: string | React.ReactNode;
  titleColor?: color;
  actions?: IDialogAction[];
  materialStyles?: any;
  fullWidth?: boolean;
  overflowY?: 'auto' | 'hidden' | 'scroll';
  dialogActionsAlignment?: 'flex-end' | 'flex-start' | 'center' | 'space-between';
  paddingX?: number;
  paddingY?: number;
  paddingTopHeader?: number;
  maxWidth?: false | 'md' | 'xs' | 'sm' | 'lg' | 'xl' | undefined;
  footer?: React.ReactNode | undefined;
  header?: React.ReactNode | undefined;
  showCancel?: boolean;
  onCancel?: () => void;
  isAccountsPayable?: boolean;
  dialogHeight?: string;
  dialogWidth?: string;
  fontFamily?: string;
  headerFontWeight?: number;
  alignContentText?: string;
  alignContentItems?: string;
  showTitleBottomBorder?: boolean;
  id?: string | undefined;
  className?: any;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {children?: React.ReactElement<any, any>},
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const AlertDialogSlide: FunctionComponent<IDialogProps> = ({
  svg,
  title,
  titleColor,
  fullWidth,
  actions,
  children,
  dialogActionsAlignment,
  materialStyles,
  overflowY,
  paddingX,
  paddingTopHeader,
  maxWidth,
  footer,
  showCancel,
  onCancel,
  isAccountsPayable,
  dialogWidth,
  dialogHeight,
  fontFamily,
  headerFontWeight,
  alignContentText,
  alignContentItems,
  showTitleBottomBorder,
  paddingY,
  id,
  className,
}) => {
  const [open, setOpen] = React.useState(true);

  const defaultStyles = {
    root: {
      textAlign: 'center',
      color: titleColor,
      '& h2': {
        display: 'flex',
        fontSize: '24px',
        justifyContent: 'center',
        letterSpacing: '-1px',
        wordSpacing: '-1px',
        paddingTop: `${paddingTopHeader || 0}px`,
        fontFamily: fontFamily,
        fontWeight: headerFontWeight,
      },
    },
    MuiDialog: {
      '& .MuiDialogActions-root': {
        justifyContent: dialogActionsAlignment || 'flex-end',
        '& .MuiButton-contained.Mui-disabled': {
          backgroundColor: '#2F97BC !important',
        },
        '& .MuiButtonBase-root.MuiButton-root[hidden]': {
          display: 'none',
        },
      },
      '& .MuiDialog-paper': {
        height: dialogHeight,
        width: dialogWidth,
      },
    },
    MuiDialogContent: {
      padding: `${paddingY || 0}px ${paddingX || 60}px`,
      overflowY: overflowY || 'hidden',
      fontFamily: fontFamily,
      textAlign: alignContentText,
      alignItems: alignContentItems,
      // maxHeight: "calc(100vh - 500px)",
      '& .MuiInputBase-input': {
        color: '#000',
        fontFamily: 'sans-serif',
      },
      '& .MuiFormLabel-root': {
        fontFamily: 'sans-serif',
      },
      '& .MuiFormLabel-root.Mui-focused': {
        color: '#2F97BC',
      },
      '& .MuiFormLabel-root.Mui-focused.Mui-error': {
        color: 'red',
      },
      '& .MuiFormLabel-error': {
        color: 'red',
      },
      '& .MuiFormLabel-error.Mui-focused': {
        color: 'red',
      },
      '& .MuiInput-underline:before': {
        borderBottomColor: '#000',
      },
    },
    CloseIconClass: {
      position: 'absolute',
      right: '24px',
      top: '24px',
      cursor: 'pointer',
    },
  };

  const useStyles = makeStyles(materialStyles || defaultStyles);

  const classes = useStyles();

  const handleClose = (_event: {}, reason: string) => {
    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
      setOpen(false);
    }
  };

  return (
    <div>
      <SDialog
        classes={{root: classes.MuiDialog || classes.className ? classes.MuiDialog && className : ''}}
        fullWidth={fullWidth}
        disableEscapeKeyDown
        open={open}
        maxWidth={maxWidth || 'md'}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        id={isAccountsPayable ? 'APCardModal' : `${id}`}
      >
        <div
          className="flex-column flex justify-center"
          style={{borderBottom: showTitleBottomBorder ? '1px solid #EAEAEA' : 'none'}}
        >
          <DialogTitle
            id="alert-dialog-slide-title"
            className={`align-self-center !p-3 text-center text-primary`}
            classes={{root: classes.root}}
          >
            {svg && <img className="mr-2" src={svg} alt="TimeOut" />}
            {title}
          </DialogTitle>
          {showCancel ? <CloseIcon classes={{root: classes.CloseIconClass}} onClick={onCancel} /> : null}
        </div>
        <DialogContent classes={{root: classes.MuiDialogContent}}>{children}</DialogContent>
        <DialogActions>
          {footer}
          {!footer &&
            actions?.map((a: IDialogAction, index: number) =>
              a.buttonType ? (
                <React.Fragment key={index}>
                  {a.buttonType === ButtonType.PRIMARY && <PrimaryButton label={a.label} onClick={a.action} />}
                  {a.buttonType === ButtonType.SECONDARY && <SecondaryButton label={a.label} onClick={a.action} />}
                  {a.buttonType === ButtonType.DESTRUCTIVE && <DestructiveButton label={a.label} onClick={a.action} />}
                </React.Fragment>
              ) : (
                <SButton
                  lineHeight={a.lineHeight}
                  width={a.width}
                  label={a.textColor}
                  textColor={a.textColor}
                  color={a.color}
                  key={index}
                  onClick={a.action}
                  disabled={a.disabled}
                  hidden={a.hidden}
                  border={a.border}
                  backgroundColor={a.backgroundColor}
                  fontFamily={a.fontFamily || 'poppins'}
                  fontSize={a.fontSize}
                  className={a.className}
                  height={a.height}
                  borderRadius={a.borderRadius}
                  variant={a.variant}
                  padding={a.padding}
                  margin={a.margin}
                >
                  {a.label}
                </SButton>
              ),
            )}
        </DialogActions>
      </SDialog>
    </div>
  );
};
