import React, {useState, useEffect} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {Formik, FormikProps} from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import {cloneDeep} from 'lodash';
import moment from 'moment';
import Skeleton from 'react-loading-skeleton';

import {
  AUTH_PURCHASING_PURCHASE_DASHBOARD_LIST,
  AUTH_PURCHASING_PURCHASE_ORDER_DETAIL,
} from '../../routes/PurchasingRoutes';
import {
  DatTypes,
  DeliveryMethods,
  PurchaseOrderDashboardViewMode,
  PurchaseOrderStatus,
} from '../../model/constants/Constants';
import {IPurchaseOrder} from '../../model/purchase-order/PurchaseOrder';
import {IRequisition} from '../../model/requisition/Requisition';
import {IAddress} from '../../model/address/Address';
import {DestructiveButton, PrimaryButton, SecondaryButton} from '../../components/buttons/DefaultButtons';
import {PurchaseOrderLocationDialog} from '../../components/purchase-order/PurchaseOrderLocationDialog';
import {PurchaseOrderNotesDialog} from '../../components/purchase-order/PurchaseOrderNotesDialog';
import {PurchaseOrderSendDialog} from '../../components/purchase-order/PurchaseOrderSendDialog';
import {AlertDialogSlide} from '../../components/dialog/AlertDialogSlide';
import SDatePicker from '../../components/pickers/DatePicker';
import {StyledCustomCheckbox} from '../../components/inputs/SCheckbox';
import LoadingIndicator from '../../components/ui/LoadingIndicator';
import {STextField} from '../../components/inputs/STextField';
import {Layout} from '../../components/layout/Layout';
import usePurchaseOrderAPI from '../../services/usePurchaseOrderAPI';
import useRequisitionAPI from '../../services/useRequisitionAPI';
import {PriceFormat} from '../../utils/formatter';
import {Toast} from '../../utils/Toast';

import {IconButton, MenuItem, makeStyles, withStyles} from '@material-ui/core';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';

export const usePurchaseOrderDetailStyle = makeStyles(() => ({
  backIconButton: {
    color: '#1C78AD',
    border: '1px solid #1C78AD',
    borderRadius: '6px',
    padding: '8px',
    marginRight: '6px',
  },
  selectLocation: {
    padding: '0px 12px 0px 0px',
    marginTop: '20px',
    '& .MuiInputBase-root': {
      width: '100%!important',
      '& .MuiIconButton-root': {
        background: 'transparent',
      },
      '& .MuiOutlinedInput-notchedOutline legend': {
        fontSize: '9px!important',
      },
    },
  },
}));

const StyledMenuItem = withStyles({
  root: {
    fontFamily: 'Poppins !important',
    '&:hover': {
      backgroundColor: '#1C78AD20 !important',
    },
  },
  selected: {
    backgroundColor: '#1C78AD50 !important',
    '&:hover': {
      backgroundColor: '#1C78AD50 !important',
    },
  },
})(MenuItem);

const statusRenderer = (_status: PurchaseOrderStatus) => {
  let style = '';
  let status = '';
  switch (_status) {
    case PurchaseOrderStatus.Open:
      style = 'bg-[#C68A1920] text-[#C68A19]';
      status = 'Not sent';
      break;
    case PurchaseOrderStatus.Sending:
      style = 'bg-[#8178CF20] text-[#8178CF] whitespace-nowrap';
      status = 'Sending';
      break;
    case PurchaseOrderStatus.Sent:
    case PurchaseOrderStatus.Sent_as_Unlinked:
      style = 'bg-[#8178CF20] text-[#8178CF] whitespace-nowrap';
      status = 'Sent';
      break;
    case PurchaseOrderStatus.Review:
      style = 'bg-[#8819A820] text-[#9D5CB3]';
      status = 'Review';
      break;
    case PurchaseOrderStatus.Approved:
    case PurchaseOrderStatus.Closed:
      style = 'bg-[#8819A820] text-[#8819A8]';
      status = 'Completed';
      break;
    case PurchaseOrderStatus.Cancelled:
      style = 'bg-[#C55D4420] text-[#C55D44]';
      status = 'Cancelled';
      break;
    default:
      break;
  }
  return (
    <span className={clsx('block whitespace-nowrap rounded px-6 py-1 font-poppins text-xs font-medium', style)}>
      {status}
    </span>
  );
};

const renderPOTitle = (_status: PurchaseOrderStatus, supplierName: string) => {
  switch (_status) {
    case PurchaseOrderStatus.Open:
      return `Order not sent for ${supplierName}`;
    case PurchaseOrderStatus.Sent:
      return `Order sent for ${supplierName}`;
    case PurchaseOrderStatus.Review:
      return `Order review for ${supplierName}`;
    case PurchaseOrderStatus.Approved:
      return `Order completed for ${supplierName}`;
    default:
      return `Order ${_status} for ${supplierName}`;
  }
};

const validationSchema = Yup.object().shape({
  BillingAddress: Yup.object().test('idRequired', 'Billing address is required', function () {
    return (
      Boolean(this.parent?.BillingAddress?.StreetAddress) &&
      Boolean(this.parent?.BillingAddress?.Country) &&
      Boolean(this.parent?.BillingAddress?.City) &&
      Boolean(this.parent?.BillingAddress?.State) &&
      Boolean(this.parent?.BillingAddress?.PostCode)
    );
  }),
  ShippingAddress: Yup.object().test('idRequired', 'Shipping address is required', function () {
    return (
      Boolean(this.parent?.ShippingAddress?.StreetAddress) &&
      Boolean(this.parent?.ShippingAddress?.Country) &&
      Boolean(this.parent?.ShippingAddress?.City) &&
      Boolean(this.parent?.ShippingAddress?.State) &&
      Boolean(this.parent?.ShippingAddress?.PostCode)
    );
  }),
});

export const PurchaseOrderDetail = () => {
  const history = useHistory();
  const classes = usePurchaseOrderDetailStyle();
  const {requisitionID, purchaseOrderID} = useParams<{
    requisitionID: string | undefined;
    purchaseOrderID: string | undefined;
  }>();

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState<boolean>(false);
  const [deleteConfirmationDialog, setDeleteConfirmationDialog] = useState<boolean>(false);
  const [notesDialog, setNotesDialog] = useState<boolean>(false);
  const [sendDialog, setSendDialog] = useState<boolean>(false);
  const [addressDialog, setAddressDialog] = useState<Partial<IAddress> | null>(null);
  const [addressType, setAddressType] = useState<'ShippingAddress' | 'BillingAddress'>('BillingAddress');
  const [purchaseOrderToEdit, setPurchaseOrderToEdit] = useState<Partial<IPurchaseOrder>>({
    RefNumber: '',
    DeliveryMethod: '',
    DueDate: null,
    TransDate: null,
  });
  const [requisitionDetails, setRequisitionDetails] = useState<Partial<IRequisition>>({});
  const [editMode, setEditMode] = useState<boolean>(true);
  const [lineItemSelected, setLineItemSelected] = useState<Set<number>>(new Set([]));

  const {
    get: getPurchaseOrderById,
    save: savePO,
    updateStatus: updatePOStatus,
    isLoading: loadingPO,
  } = usePurchaseOrderAPI();
  const {get: getRequisitionById, isLoading: loadingRequisition} = useRequisitionAPI();

  const isLoading = loadingPO || loadingRequisition;

  useEffect(() => {
    if (requisitionID && purchaseOrderID) {
      getPurchaseOrder();
    }
  }, [requisitionID, purchaseOrderID]);

  const getPurchaseOrder = async () => {
    setEditMode(true);
    const [purchaseOrder, requisition] = await Promise.all([
      getPurchaseOrderById(Number(purchaseOrderID)),
      getRequisitionById(Number(requisitionID)),
    ]);
    if (purchaseOrder.Status !== PurchaseOrderStatus.Open) {
      setEditMode(false);
      const selectedSet = new Set<number>();
      for (let _data of purchaseOrder.Lines || []) {
        if (Number(_data.Quantity) !== Number(_data.QuantityReceived)) {
          selectedSet.add(_data.LineID);
        }
      }
      setLineItemSelected(selectedSet);
    }
    setRequisitionDetails(requisition);
    setPurchaseOrderToEdit(purchaseOrder);
  };

  const handleCloseClick = () => {
    if (editMode) {
      setOpenConfirmationDialog(true);
    } else {
      history.push({
        pathname: AUTH_PURCHASING_PURCHASE_DASHBOARD_LIST,
        state: {ViewMode: PurchaseOrderDashboardViewMode.PurchaseOrders},
      });
    }
  };

  const handleDeleteRequisition = async () => {
    if (purchaseOrderID) {
      updatePOStatus(Number(purchaseOrderID), PurchaseOrderStatus.Cancelled).then(res => {
        if (res && requisitionID) {
          Toast.info('Purchase order deleted!');
          getPurchaseOrder();
        } else {
          Toast.error('Something went wrong!');
        }
        setDeleteConfirmationDialog(false);
      });
    }
  };

  const onSubmit = async (): Promise<void> => {
    if (editMode) {
      setSendDialog(true);
    } else {
      history.push({
        pathname: AUTH_PURCHASING_PURCHASE_DASHBOARD_LIST,
        state: {
          ViewMode: PurchaseOrderDashboardViewMode.DeliveryReceipts,
          createDeliveryReceiptDialog: true,
          deliveryReceiptData: JSON.stringify({
            RefNumber: '',
            DeliveryDate: new Date(),
            LineIDs: Array.from(lineItemSelected),
            Lines: [],
            WarehouseID: purchaseOrderToEdit.WarehouseID,
            SupplierID: purchaseOrderToEdit.SupplierID,
          }),
        },
      });
    }
  };

  const savePurchaseOrder = (po: Partial<IPurchaseOrder>) => {
    return savePO(po);
  };

  const handleChangePO = (poID: number) => {
    if (requisitionID) {
      history.replace(
        AUTH_PURCHASING_PURCHASE_ORDER_DETAIL.replace(/:requisitionID/g, String(requisitionID || '')).replace(
          /:purchaseOrderID/g,
          String(poID || ''),
        ),
      );
    }
  };

  const handleSelectAddress = async (address: Partial<IAddress>, fProps: FormikProps<Partial<IPurchaseOrder>>) => {
    const values = cloneDeep(fProps.values);
    if (addressType === 'BillingAddress') {
      values.BillingAddress = address;
    } else if (addressType === 'ShippingAddress') {
      values.ShippingAddress = address;
    }
    await savePurchaseOrder(values);
    fProps.setFieldValue(addressType, address);
    setAddressDialog(null);
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedSet = cloneDeep(lineItemSelected);

    if (event.target.checked) {
      selectedSet.add(parseInt(event.target.name));
    } else {
      selectedSet.delete(parseInt(event.target.name));
    }
    setLineItemSelected(selectedSet);
  };

  const handleSelectAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Array.isArray(purchaseOrderToEdit?.Lines)) {
      const selectedSet = new Set<number>(lineItemSelected);

      for (let _data of purchaseOrderToEdit.Lines) {
        if (event.target.checked && Number(_data.Quantity) !== Number(_data.QuantityReceived)) {
          selectedSet.add(_data.LineID);
        } else {
          selectedSet.delete(_data.LineID);
        }
      }
      setLineItemSelected(selectedSet);
    }
  };

  const _poDetailLeftPanel = (
    <div
      id="purchase-order-detail-left"
      className="relative flex h-full w-full flex-col overflow-y-auto rounded-lg bg-white p-3 shadow-[0_0_6px_0_#D3E5EF]"
    >
      <div className="flex flex-row items-center pb-2" style={{borderBottom: '1px solid #D8D8D8'}}>
        <IconButton disableRipple className={classes.backIconButton} onClick={handleCloseClick} data-autoid="btnBack">
          <ArrowBackRoundedIcon fontSize="small" />
        </IconButton>
        <span className="ml-2 text-xl font-light text-spenda-primarytext">
          Requisition - {requisitionDetails.RefNumber}
        </span>
      </div>
      <div className="mt-4 flex-1">
        <div className="rounded-md bg-[#F1F1F1] p-2">
          <table className="w-full">
            <tbody>
              <tr className="text-xs font-bold text-spenda-primarytext">
                <td>{requisitionDetails.Name}</td>
                <td align="right">{requisitionDetails.RefNumber}</td>
              </tr>
              <tr className="text-xs text-spenda-primarytext">
                <td>
                  Created date:{' '}
                  {moment.utc(requisitionDetails.CreatedDateTime_utc).local().format('DD MMM YYYY, hh:mm a')}
                </td>
                <td align="right">Lines: {requisitionDetails.LineCount}</td>
              </tr>
              <tr className="text-xs text-spenda-primarytext">
                <td>Assigned to: {requisitionDetails.AssignedToUserName}</td>
                <td align="right">est. Cost (ex): {PriceFormat(requisitionDetails.TotalEx)}</td>
              </tr>
            </tbody>
          </table>
          <table className="mt-2 w-full rounded bg-white">
            <tbody>
              {Array.isArray(requisitionDetails.LinkedTrans)
                ? requisitionDetails.LinkedTrans.filter(lt => lt.DatTypeID === DatTypes.PurchaseOrder).map(lt => (
                    <tr
                      key={lt.ID}
                      className={clsx(
                        'cursor-pointer border-b-[1px] border-spenda-scream text-xs text-spenda-primarytext last:border-b-0',
                      )}
                      onClick={() => handleChangePO(lt.ID)}
                      data-autoid={`btnRequisition${lt.ID}`}
                    >
                      <td className="whitespace-nowrap p-2.5">{lt.RefNumber}</td>
                      <td className="w-1/2 bg-spenda-cream p-2.5">{lt.RecipientName}</td>
                      <td className="min-w-[100px] p-2.5" align="center">
                        {lt.LineCount}
                      </td>
                      <td className="min-w-[100px] bg-spenda-cream p-2.5" align="right">
                        {PriceFormat(lt.TotalEx)}
                      </td>
                      <td className="min-w-[100px] p-2.5" align="center">
                        {statusRenderer(lt.Status as PurchaseOrderStatus)}
                      </td>
                    </tr>
                  ))
                : null}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );

  const _purchaseOrderDetail = (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={purchaseOrderToEdit}
      onSubmit={onSubmit}
    >
      {props => (
        <div
          id="purchase-order-detail-lines"
          className={clsx('relative h-full overflow-y-auto rounded-lg bg-white p-3 shadow-[0_0_6px_0_#D3E5EF]')}
        >
          <div className="py-2" style={{borderBottom: '1px solid #D8D8D8'}}>
            <span className="ml-2 text-xl font-light text-spenda-primarytext">
              {renderPOTitle((props.values.Status || '') as PurchaseOrderStatus, props.values.SupplierName || '')}
            </span>
          </div>
          <div>
            <div className={clsx('mt-3 grid grid-cols-4 gap-x-2.5 gap-y-4')}>
              <STextField
                v2
                fullWidth
                id="RefNumber"
                name="RefNumber"
                variant="outlined"
                label="Ref no."
                placeholder="Ref no."
                value={props.values.RefNumber}
                onBlur={() => savePurchaseOrder(props.values)}
                onChange={props.handleChange}
                helperText={props.touched?.RefNumber && props.errors?.RefNumber}
                error={props.touched?.RefNumber && Boolean(props.errors?.RefNumber)}
                disabled={!editMode}
              />
              <SDatePicker
                v2
                autoOk
                fullWidth
                variant="inline"
                id="DueDate"
                name="DueDate"
                inputVariant="outlined"
                label="Required date"
                format="dd MMM, yyyy"
                value={props.values.DueDate || null}
                onChange={date => props.setFieldValue('DueDate', date)}
                onBlur={() => savePurchaseOrder(props.values)}
                helperText={props.touched?.DueDate as boolean}
                // @ts-ignore
                error={props.errors?.DueDate as boolean}
                disabled={!editMode}
              />
              <PurchaseOrderLocationDialog
                open={addressDialog}
                setOpen={setAddressDialog}
                handleSelectAddress={a => handleSelectAddress(a, props)}
              />
              <div className="row-span-2 flex h-full w-full flex-col">
                <button
                  className={clsx(
                    'relative flex h-full w-full items-center justify-center rounded border-[1px] border-spenda-sBlue bg-white pt-4',
                    {
                      'cursor-default': !editMode,
                    },
                  )}
                  onClick={() => {
                    if (editMode) {
                      setAddressDialog(props.values?.BillingAddress || {});
                      setAddressType('BillingAddress');
                    }
                  }}
                  data-autoid="btnBillToAddress"
                >
                  <span className="absolute left-1 top-1 rounded-lg bg-[#D3E5EF50] px-3 py-0.5 text-[10px] font-medium text-primary">
                    Bill to
                  </span>
                  <span className="text-center text-base font-semibold text-primary">
                    {[
                      props.values?.BillingAddress?.StreetAddress || '',
                      props.values?.BillingAddress?.City || '',
                      props.values?.BillingAddress?.State || '',
                      props.values?.BillingAddress?.Country || '',
                      props.values?.BillingAddress?.PostCode || '',
                    ]
                      .filter(Boolean)
                      .join(', ')}
                  </span>
                </button>
                {props.errors?.BillingAddress && (
                  <span className="text-xs text-spenda-error">{props.errors?.BillingAddress}</span>
                )}
              </div>
              <div className="row-span-2 flex h-full w-full flex-col">
                <button
                  className={clsx(
                    'relative flex h-full w-full items-center justify-center rounded border-[1px] border-spenda-sBlue bg-white pt-4',
                    {
                      'cursor-default': !editMode,
                    },
                  )}
                  onClick={() => {
                    if (editMode) {
                      setAddressDialog(props.values?.ShippingAddress || {});
                      setAddressType('ShippingAddress');
                    }
                  }}
                  data-autoid="btnShipToAddress"
                >
                  <span className="absolute left-1 top-1 rounded-lg bg-[#D3E5EF50] px-3 py-0.5 text-[10px] font-medium text-primary">
                    Ship to
                  </span>
                  <span className="text-center text-base font-semibold text-primary">
                    {[
                      props.values?.ShippingAddress?.StreetAddress || '',
                      props.values?.ShippingAddress?.City || '',
                      props.values?.ShippingAddress?.State || '',
                      props.values?.ShippingAddress?.Country || '',
                      props.values?.ShippingAddress?.PostCode || '',
                    ]
                      .filter(Boolean)
                      .join(', ')}
                  </span>
                </button>
                {props.errors?.ShippingAddress && (
                  <span className="text-xs text-spenda-error">{props.errors?.ShippingAddress}</span>
                )}
              </div>
              <STextField
                v2
                select
                fullWidth
                id="DeliveryMethod"
                name="DeliveryMethod"
                variant="outlined"
                label="Delivery method"
                placeholder="Delivery method"
                value={props.values.DeliveryMethod}
                onBlur={() => savePurchaseOrder(props.values)}
                onChange={props.handleChange}
                helperText={props.touched?.DeliveryMethod && props.errors?.DeliveryMethod}
                error={props.touched?.DeliveryMethod && Boolean(props.errors?.DeliveryMethod)}
                SelectProps={{displayEmpty: true}}
                disabled={!editMode}
              >
                <StyledMenuItem key={DeliveryMethods.Deliver} value={DeliveryMethods.Deliver}>
                  {DeliveryMethods.Deliver}
                </StyledMenuItem>
                <StyledMenuItem key={DeliveryMethods.Warehouse_Pickup} value={DeliveryMethods.Warehouse_Pickup}>
                  {DeliveryMethods.Warehouse_Pickup}
                </StyledMenuItem>
                <StyledMenuItem key={DeliveryMethods.Dropship} value={DeliveryMethods.Dropship}>
                  {DeliveryMethods.Dropship}
                </StyledMenuItem>
              </STextField>
              <SDatePicker
                v2
                autoOk
                fullWidth
                variant="inline"
                id="TransDate"
                name="TransDate"
                inputVariant="outlined"
                label="Order date"
                format="dd MMM, yyyy"
                value={props.values.TransDate || null}
                onChange={date => props.setFieldValue('TransDate', date)}
                onBlur={() => savePurchaseOrder(props.values)}
                helperText={props.touched?.TransDate && props.errors?.TransDate}
                error={props.touched?.TransDate && Boolean(props.errors?.TransDate)}
                disabled={!editMode}
              />
            </div>
            <table className="mt-3 w-full rounded bg-[#f1f1f1]">
              <thead>
                <tr className="text-base font-semibold text-spenda-labeltext">
                  {!editMode ? (
                    <th className="w-[36px]" align="center">
                      <StyledCustomCheckbox
                        name={'SelectAll'}
                        checked={(props.values?.Lines || [])
                          .filter(line => Number(line.Quantity) !== Number(line.QuantityReceived))
                          .every(line => Array.from(lineItemSelected).includes(Number(line.LineID)))}
                        onClick={e => e.stopPropagation()}
                        onChange={handleSelectAllChange}
                      />
                    </th>
                  ) : null}
                  <th className="whitespace-nowrap p-2.5" align="left">
                    Code
                  </th>
                  <th className="w-1/2 p-2.5" align="left">
                    Products
                  </th>
                  <th className="min-w-[100px] p-2.5" align="center">
                    Qty
                  </th>
                  <th className="min-w-[100px] p-2.5" align="right">
                    Line total
                  </th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(props.values?.Lines)
                  ? props.values.Lines.map(line => (
                      <tr
                        key={line.LineID}
                        className="border-b-[1px] border-spenda-scream bg-white text-xs text-spenda-primarytext"
                      >
                        {!editMode ? (
                          <td className="w-[36px] bg-spenda-cream" align="center">
                            <StyledCustomCheckbox
                              name={line.LineID + ''}
                              disabled={Number(line.Quantity) === Number(line.QuantityReceived)}
                              checked={Boolean(lineItemSelected.has(Number(line.LineID)))}
                              onClick={e => e.stopPropagation()}
                              onChange={handleSelectChange}
                            />
                          </td>
                        ) : null}
                        <td className="p-2.5">{line.Code}</td>
                        <td className="bg-spenda-cream p-2.5">{line.ShortDescription}</td>
                        <td className="p-2.5" align="center">
                          {line.Quantity}
                        </td>
                        <td className="bg-spenda-cream p-2.5" align="right">
                          {line.LineTotalExString}
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </div>
          <div className="absolute px-0.5" style={{bottom: 10, width: 'calc(100% - 1.5rem)'}}>
            {isLoading ? (
              <Skeleton height={55} />
            ) : (
              <div className="flex w-full flex-row p-2" style={{backgroundColor: '#ADADAD50', borderRadius: '8px'}}>
                <DestructiveButton
                  label="Delete"
                  onClick={() => setDeleteConfirmationDialog(true)}
                  margin="0 8px 0 0"
                />
                <div className="flex-1" />
                <PurchaseOrderNotesDialog
                  {...props}
                  savePurchaseOrder={savePurchaseOrder}
                  open={notesDialog}
                  setOpen={setNotesDialog}
                />
                {editMode ? <SecondaryButton label="Add Notes" onClick={() => setNotesDialog(true)} /> : null}
                <PurchaseOrderSendDialog
                  {...props}
                  open={sendDialog}
                  setOpen={setSendDialog}
                  refetch={getPurchaseOrder}
                />
                {editMode ? (
                  <PrimaryButton
                    onClick={() => props.handleSubmit()}
                    margin="0 0 0 8px"
                    label="Send Order"
                    isSubmitting={props.isSubmitting}
                    disabled={props.isSubmitting || !editMode}
                  />
                ) : (
                  <PrimaryButton
                    onClick={() => props.handleSubmit()}
                    margin="0 0 0 8px"
                    label="Receive stock"
                    isSubmitting={props.isSubmitting}
                    disabled={props.isSubmitting || !lineItemSelected.size}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </Formik>
  );

  return (
    <>
      {openConfirmationDialog ? (
        <AlertDialogSlide
          showTitleBottomBorder={true}
          titleColor="black"
          headerFontWeight={300}
          paddingY={60}
          paddingX={85}
          dialogWidth={'451px'}
          fontFamily="Poppins"
          title="Discard Changes"
          footer={
            <div className="flex w-full justify-between rounded bg-[#EFEFEF] px-4 py-2">
              <SecondaryButton label="Cancel" onClick={() => setOpenConfirmationDialog(false)} />
              <DestructiveButton
                label="Discard"
                onClick={() =>
                  history.push({
                    pathname: AUTH_PURCHASING_PURCHASE_DASHBOARD_LIST,
                    state: {ViewMode: PurchaseOrderDashboardViewMode.PurchaseOrders},
                  })
                }
              />
            </div>
          }
        >
          <p className="text-center text-xs">
            You have unsaved changes, Would you like to discard them or return to the previous screen?
          </p>
        </AlertDialogSlide>
      ) : null}
      {deleteConfirmationDialog ? (
        <AlertDialogSlide
          showTitleBottomBorder={true}
          titleColor="black"
          headerFontWeight={300}
          paddingY={60}
          paddingX={85}
          dialogWidth={'451px'}
          fontFamily="Poppins"
          title="Delete Requisition"
          footer={
            <div className="flex w-full justify-between rounded bg-[#EFEFEF] px-4 py-2">
              <SecondaryButton label="Cancel" onClick={() => setDeleteConfirmationDialog(false)} />
              <DestructiveButton
                label="Delete"
                onClick={() => handleDeleteRequisition()}
                isSubmitting={isLoading}
                disabled={isLoading}
              />
            </div>
          }
        >
          <p className="text-center text-xs">Are you sure you want to DELETE Purchase Order, or go back ? </p>
        </AlertDialogSlide>
      ) : null}
      <div className="relative h-full overflow-hidden bg-spenda-newbg font-poppins">
        <Layout leftPanel={_poDetailLeftPanel} mainPanel={_purchaseOrderDetail} splitWidthType={1} />
        <LoadingIndicator isLoading={isLoading} size="md" color="#1C78AD" />
      </div>
    </>
  );
};
