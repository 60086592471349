import {useCallback} from 'react';
import useHttp from '../hooks/useHttp';
import {APOnboardingSteps} from '../model/constants/Constants';
import {IOnboardingV1Step, IOnboardingV1StepResponse} from '../model/OnboardingV1Step';

type NextStepType = {
  action: 'next';
  completeSteps: APOnboardingSteps[];
};
type SkipStepType = {
  action: 'skip';
  skipSteps: APOnboardingSteps[];
};

type StartStepType = {
  action: 'start';
};

type TabChangeType = {
  nextStep?: APOnboardingSteps;
} & (SkipStepType | NextStepType | StartStepType);

export const useOnboarding = () => {
  const {GET, POST, isLoading} = useHttp();

  const getOnboardingStep = async (): Promise<IOnboardingV1Step[]> => {
    return await GET('/Spenda/Payments/AccountsPayable/OnboardingStepProgress').then(
      (data: IOnboardingV1StepResponse) => data.value,
    );
  };

  const updateOnboardingStep = async (payload: IOnboardingV1Step[]) => {
    return await POST('/Spenda/Payments/AccountsPayable/OnboardingStepProgress', payload);
  };
  const handleTabChange = useCallback(async (param: TabChangeType) => {
    const {action, nextStep} = param;

    let payload: IOnboardingV1Step[] = [];
    switch (action) {
      case 'next':
        const {completeSteps} = param;
        for (let step of completeSteps) {
          payload.push({
            stepID: step,
            isCompleted: true,
          });
        }
        if (nextStep)
          payload.push({
            stepID: nextStep,
            isCompleted: false,
          });
        break;
      case 'skip':
        const {skipSteps} = param;
        if (skipSteps) {
          for (let step of skipSteps) {
            payload.push({
              stepID: step,
              isSkipped: true,
            });
          }
        }
        if (nextStep)
          payload.push({
            stepID: nextStep,
            isCompleted: false,
          });
        break;
      case 'start':
        if (nextStep) {
          payload.push({
            stepID: nextStep,
            isCompleted: false,
          });
        }
        break;
    }
    await updateOnboardingStep(payload);
  }, []);

  return {getOnboardingStep, updateOnboardingStep, handleTabChange, isLoading};
};
