import React, {useState, PropsWithChildren, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import clsx from 'clsx';

import {PurchaseOrderDashboardViewMode} from '../../model/constants/Constants';
import {CreateDeliveryReceiptDialog} from '../../components/purchase-order/CreateDeliveryReceiptDialog';
import {CreateRequisitionDialog} from '../../components/purchase-order/CreateRequisitionDialog';
import {PurchaseOrderToolbar} from '../../components/purchase-order/PurchaseOrderToolbar';
import {PurchaseOrderList} from '../../components/purchase-order/PurchaseOrderList';
import {RequisitionList} from '../../components/purchase-order/RequisitionList';
import {DeliveryReceiptList} from '../../components/purchase-order/DeliveryReceiptList';
import {SPagination} from '../../components/tables/SPagination';
import {Layout} from '../../components/layout/Layout';
import {useLoadPurchaseOrder} from '../../services/usePurchaseOrderAPI';
import {useLoadRequisition} from '../../services/useRequisitionAPI';
import {useLoadDeliveryReceipt} from '../../services/useDeliveryReceiptAPI';

import {makeStyles} from '@material-ui/core';

const usePurchaseOrderDashboard = makeStyles(() => ({
  root: {
    maxHeight: 'calc( 100vh - 12em )',
    boxShadow: '0 0 6px 0 #D3E5EF',
  },
  toolbar: {
    width: '100%',
    minHeight: '70px',
    padding: '9px 12px',
    backgroundColor: '#ffffff',
    marginBottom: '10px',
    borderRadius: '5px',
    display: 'grid',
    gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
    columnGap: '12px',
    boxShadow: '0 0 6px 0 #D3E5EF',
  },
}));

const getTitle = (viewMode: PurchaseOrderDashboardViewMode) => {
  switch (viewMode) {
    case PurchaseOrderDashboardViewMode.Requisitions:
      return 'Requisitions';
    case PurchaseOrderDashboardViewMode.PurchaseOrders:
      return 'Purchase Orders';
    case PurchaseOrderDashboardViewMode.DeliveryReceipts:
      return 'Deliveries';
    default:
      return '';
  }
};

enum HookResultKeys {
  Requisitions = 'loadRequisitionRes',
  PurchaseOrders = 'loadPurchaseOrderRes',
  DeliveryReceipts = 'loadDeliveryReceiptRes',
}

const useDynamicHook = () => {
  const loadRequisitionRes = useLoadRequisition();
  const loadPurchaseOrderRes = useLoadPurchaseOrder();
  const loadDeliveryReceiptRes = useLoadDeliveryReceipt();
  return {
    loadRequisitionRes,
    loadPurchaseOrderRes,
    loadDeliveryReceiptRes,
  };
};

export const PurchaseOrderDashboard = () => {
  const location = useLocation();
  const classes = usePurchaseOrderDashboard();

  const [viewMode, setViewMode] = useState<PurchaseOrderDashboardViewMode>(PurchaseOrderDashboardViewMode.Requisitions);
  const [createDialog, setCreateDialog] = useState<boolean>(false);
  const [createDeliveryReceiptDialog, setCreateDeliveryReceiptDialog] = useState<boolean>(false);
  const [deliveryReceiptData, setDeliveryReceiptData] = useState<string>('');

  const loadHook = useDynamicHook();

  const {loadRequisitionRes, loadPurchaseOrderRes, loadDeliveryReceiptRes} = loadHook;

  const hookRes = loadHook[HookResultKeys[viewMode]]; 

  useEffect(() => {
    if (location?.state && (location.state as any)?.ViewMode) {
      setViewMode((location?.state as any)?.ViewMode || PurchaseOrderDashboardViewMode.Requisitions);
    }
  }, []);

  useEffect(() => {
    if (location?.state && (location.state as any)?.createDeliveryReceiptDialog) {
      setCreateDeliveryReceiptDialog(true);
      setDeliveryReceiptData((location.state as any)?.deliveryReceiptData as string);
    }
  }, [location?.state]);

  useEffect(() => {
    if (hookRes.refetch) {
      hookRes.refetch();
    }
  }, [viewMode]);

  const handleBucketClick = (viewMode: PurchaseOrderDashboardViewMode) => {
    setViewMode(viewMode);
  };

  const isBucketActive = (ViewMode: string) => {
    return viewMode === ViewMode;
  };

  const handlePageChange = (newPage: number) => {
    hookRes.setSearchFilter({
      ...hookRes.searchFilter,
      LastRowNumber: (newPage - 1) * hookRes.pageSize,
    });
  };

  const handleRowsPerPageChange = (rows: number) => {
    hookRes.setSearchFilter({
      ...hookRes.searchFilter,
      MaxResults: rows,
      LastRowNumber: 0,
    });
  };

  const handleCreate = () => {
    if (viewMode === PurchaseOrderDashboardViewMode.DeliveryReceipts) {
      setCreateDeliveryReceiptDialog(true);
    } else {
      setCreateDialog(true);
    }
  };

  const handleCreateDialogClose = (_refetchList?: boolean) => {
    setCreateDialog(false);
    setCreateDeliveryReceiptDialog(false);
  };

  const purchaseOrderDashboard = (
    <>
      <div className={classes.toolbar}>
        <Bucket
          isActive={isBucketActive(PurchaseOrderDashboardViewMode.Requisitions)}
          onClick={() => handleBucketClick(PurchaseOrderDashboardViewMode.Requisitions)}
          dataAutoId="btnRequisitions"
        >
          <div className="flex flex-col ">
            <p className="text-[10px] font-medium">Requisitions</p>
            <p className="text-base font-semibold">{`${loadRequisitionRes.totalCount} Requisitions`}</p>
          </div>
        </Bucket>
        <Bucket
          isActive={isBucketActive(PurchaseOrderDashboardViewMode.PurchaseOrders)}
          onClick={() => handleBucketClick(PurchaseOrderDashboardViewMode.PurchaseOrders)}
          dataAutoId="btnPurchaseOrders"
        >
          <div className="flex flex-col">
            <p className="text-[10px] font-medium">Purchase Orders</p>
            <p className="text-base font-semibold">{`${loadPurchaseOrderRes.totalCount} Orders`}</p>
          </div>
        </Bucket>
        <Bucket
          isActive={isBucketActive(PurchaseOrderDashboardViewMode.DeliveryReceipts)}
          onClick={() => handleBucketClick(PurchaseOrderDashboardViewMode.DeliveryReceipts)}
          dataAutoId="btnDeliveryReceipts"
        >
          <div className="flex flex-col">
            <p className="text-[10px] font-medium">Delivery Receipts</p>
            <p className="text-base font-semibold">{`${loadDeliveryReceiptRes.totalCount} Receipts`}</p>
          </div>
        </Bucket>
      </div>
      <div
        className={clsx(
          classes.root,
          'flex h-full w-full flex-col overflow-y-auto rounded-lg bg-white p-2 pb-10 font-poppins',
        )}
        id="purchase-order-list"
      >
        <PurchaseOrderToolbar
          viewMode={viewMode}
          title={getTitle(viewMode)}
          searchFilter={hookRes.searchFilter}
          loading={hookRes.loading}
          setSearchFilter={hookRes.setSearchFilter}
          handleCreate={handleCreate}
        />
        {viewMode === PurchaseOrderDashboardViewMode.Requisitions ? (
          <RequisitionList items={loadRequisitionRes.items} loading={loadRequisitionRes.loading} />
        ) : null}
        {viewMode === PurchaseOrderDashboardViewMode.PurchaseOrders ? (
          <PurchaseOrderList items={loadPurchaseOrderRes.items} loading={loadPurchaseOrderRes.loading} />
        ) : null}
        {viewMode === PurchaseOrderDashboardViewMode.DeliveryReceipts ? (
          <DeliveryReceiptList items={loadDeliveryReceiptRes.items} loading={loadDeliveryReceiptRes.loading} />
        ) : null}
        <SPagination
          currentPage={hookRes.currentPage}
          totalCount={hookRes.totalCount}
          pageSize={hookRes.pageSize}
          rowsPerPageOptions={[10, 15, 25]}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </div>
    </>
  );

  return (
    <>
      <CreateRequisitionDialog open={createDialog} onClose={handleCreateDialogClose} />
      <CreateDeliveryReceiptDialog
        open={createDeliveryReceiptDialog}
        onClose={handleCreateDialogClose}
        deliveryReceiptData={deliveryReceiptData}
      />
      <div className={`relative h-full overflow-hidden bg-spenda-newbg font-poppins`}>
        <Layout leftPanel={purchaseOrderDashboard} splitWidthType={4} />
      </div>
    </>
  );
};

const Bucket = (
  props: PropsWithChildren<{
    isActive?: boolean;
    onClick: () => void;
    dataAutoId: string;
  }>,
) => {
  let darkColor = '#1C78AD';
  let lightColor = '#FFFFFF';

  const bucketColor = {
    backgroundColor: props.isActive ? darkColor : lightColor,
    border: `1px solid ${props.isActive ? lightColor : darkColor}`,
    color: props.isActive ? '#FFFFFF' : darkColor,
  };

  return (
    <button
      className={clsx(
        bucketColor,
        'relative flex cursor-pointer items-stretch justify-center rounded-lg text-center	leading-4',
      )}
      onClick={props.onClick}
      data-autoid={props.dataAutoId}
      style={bucketColor}
    >
      <div className="flex flex-initial items-center justify-center">
        {props.isActive && (
          <svg viewBox="0 0 459 29" className="absolute bottom-0 left-0 w-full">
            <title>{'Path 7'}</title>
            <g id="bucket-wave" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd" fillOpacity={0.1}>
              <path
                d="M52.4946697,13 C100.346782,13 132.88438,0.39871785 198.066837,0.39871785 C263.249293,0.39871785 295.559157,15.7772368 342.740121,15.7772368 C374.194096,15.7772368 412.888412,13.6401165 458.823067,9.36587588 L458.823067,25 C458.823067,27.209139 457.032206,29 454.823067,29 L0.0190409027,29 L0.0190409027,29 L0.0190409027,9.36587588 C3.10138503,11.7886253 20.5932613,13 52.4946697,13 Z"
                id="Path-7"
                fill="#FFFFFF"
              />
            </g>
          </svg>
        )}
        {props.children}
      </div>
    </button>
  );
};
