import React from 'react';
import {Switch} from 'react-router-dom';
import {MenuTypes, ModuleTypes} from '../model/constants/Constants';
import {SupplierInvoice} from '../screens/purchasing/SupplierInvoice';
import {MarketplaceRoute} from './MarketplaceRoute';
import {PSBLSettings} from '../screens/AccountsReceivable/settings/PSBLSettings';

export const AUTH_FINANCE_SERVICES = '/finance';
export const AUTH_FINANCE_SERVICES_TRANSACTION = '/finance/:supplierId/statements';
export const AUTH_FINANCE_SERVICES_PAYMENT_OPTION = '/finance/:supplierId/payment-options';

export const FinanceServiceRoutes = () => {
  return (
    <Switch>
      <MarketplaceRoute
        path={AUTH_FINANCE_SERVICES_TRANSACTION}
        exact
        supplierModuleIds={[ModuleTypes.SpendaCollectConnectedStatements]}
        Component={SupplierInvoice}
        MenuFilters={{
          MenuType: MenuTypes.Buying,
          ModuleID: ModuleTypes.TradeFinance,
          ModuleDependency: [
            ModuleTypes.SpendaMarketBuying,
            ModuleTypes.SpendaCollectConnectedStatements,
            ModuleTypes.ConnectedPayments,
          ],
        }}
      />
      <MarketplaceRoute
        path={AUTH_FINANCE_SERVICES_PAYMENT_OPTION}
        exact
        Component={PSBLSettings}
        supplierModuleIds={[ModuleTypes.SpendaCollectConnectedStatements]}
        MenuFilters={{
          MenuType: MenuTypes.Buying,
          ModuleID: ModuleTypes.TradeFinance,
          ModuleDependency: [
            ModuleTypes.SpendaMarketBuying,
            ModuleTypes.SpendaCollectConnectedStatements,
            ModuleTypes.ConnectedPayments,
          ],
        }}
      />
    </Switch>
  );
};
