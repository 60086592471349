import React from 'react';
import {AlertDialogSlide} from '../dialog/AlertDialogSlide';
import {
  TextField,
  withStyles,
  FormControl,
  InputLabel,
  createStyles,
  Theme,
  InputBase,
  Divider,
  Typography,
  Grid,
} from '@material-ui/core';
import {SupplierInventory} from '../../model/inventory/SupplierInventory';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import {IOSSwitch} from '../form/SwitchSlider';
import {css} from 'glamor';
import toMaterialStyle from 'material-color-hash';
import VideocamOffTwoToneIcon from '@material-ui/icons/VideocamOffTwoTone';
import {PriceFormat} from '../../utils/formatter';
import price from '../../assets/svg/Price.svg';
import general from '../../assets/svg/General.svg';
import workflow from '../../assets/svg/Workflow.svg';
import availibility from '../../assets/svg/Availibility.svg';
import {fade, makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';

const CssTextField = withStyles({
  root: {
    '& .MuiFormControl-root': {},
    '& label.Mui-focused': {
      color: '#0092a8',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#0092a8',
    },
    '& .MuiOutlinedInput-input': {
      height: '34px',
      padding: '8px',
      fontSize: '14px',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#0092a8',
      },
      '&:hover fieldset': {
        borderColor: '#0092a8',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#0092a8',
      },
    },
    '& .MuiFilledInput-root': {
      '&.Mui-disabled fieldset': {
        backgroundColor: 'gainsboro',
      },
    },
    '& .MuiInputBase-input': {
      fontSize: '14px',
      '&.Mui-disabled': {
        backgroundColor: '#DDDD',
        color: 'grey',
        fontSize: '14px',
      },
    },
    '& .MuiInputLabel-filled': {
      color: 'grey !important',
    },
  },
})(TextField);

interface IProductDetailsDialogProps {
  product: SupplierInventory;
  onClose: () => void;
  // onSave: (product: SupplierInventory) => void
}
// actions: { label: string, action: (address?: IAddress) => void}[];

const ProductDetailsDialog: React.FunctionComponent<IProductDetailsDialogProps> = ({
  product,
  onClose,
}: IProductDetailsDialogProps) => {
  const [state, setState] = React.useState<SupplierInventory>(product);

  const actions = [
    {label: 'Close', action: onClose},
    // { label: "Save", action: () => onSave(state) }
  ];

  const handleChange = (event: React.FormEvent) => {
    const t = event.currentTarget as HTMLInputElement;
    setState({
      ...state,
      [t.name]: t.value,
    });
  };

  const colorsStyle = toMaterialStyle(product.ShortDescription || '', 100);

  const hasImage = product.DefaultImageMediaFileThumbnailURL;

  const style = css({
    background: 'lightgrey',
    opacity: '50%',
  });

  const watermark = css({
    opacity: '30%',
    paddingTop: '10px',
    paddingLeft: '5px',
    paddingRight: '5px',
  });

  const body = css({
    backgroundColor: product.IsLoading ? '#FFF' : hasImage ? '#FFF' : colorsStyle.backgroundColor,
    cursor: 'pointer',
    height: hasImage ? '100%' : 200,
    width: hasImage ? '' : 200,
    textAlign: 'center',
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    '& p': {padding: '0 3px'},
  });

  const image = css({
    padding: hasImage ? '' : '81px',
    border: '1px solid #0092a8',
    borderRadius: '4px',
    margin: '74px',
  });

  const useStyles = makeStyles({
    root: {
      // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
      color: '#0092a8',
      borderTop: '1px solid #0092a8',
      height: 48,
      // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    },
    divider: {
      backgroundColor: 'lightgrey',
      marginTop: '30px',
      marginBottom: '30px',
    },
  });

  const classes = useStyles();

  const BootstrapInput = withStyles((theme: Theme) =>
    createStyles({
      root: {
        'label + &': {
          marginTop: '12px',
        },
      },
      input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.common.white,
        border: '1px solid #0092a8',
        fontSize: 14,
        minWidth: '330px',
        padding: '10px 12px',
        //   transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
          '-apple-system',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif',
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
          boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
          borderColor: theme.palette.primary.main,
        },
      },
    })
  )(InputBase);

  const FilledBootstrapInput = withStyles((theme: Theme) =>
    createStyles({
      root: {
        'label + &': {
          marginTop: '22px',
        },
      },
      input: {
        textAlign: 'center',
        minWidth: '150px',
        marginTop: '12px',
        borderRadius: 4,
        color: '#0092a8 !important',
        position: 'relative',
        backgroundColor: '#D6EBF3',
        border: '1px solid #0092a8',
        fontSize: 14,
        // padding: '10px 12px',
        //   transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
          '-apple-system',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif',
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
          boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
          borderColor: theme.palette.primary.main,
        },
      },
    })
  )(InputBase);

  const [stateCheck, setStateCheck] = React.useState({
    checkedA: true,
    checkedB: true,
    checkedC: true,
  });

  const handleChangeCheck = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setStateCheck({...stateCheck, [name]: event.target.checked});
  };

  const dialogStyles = {
    root: {
      minWidth: '900px',
      textAlign: 'left',
      marginLeft: '28px',
      color: '#006c91',
      '& h2': {
        fontFamily: 'inherit',
        fontWeight: '200',
        fontSize: '27px',
        letterSpacing: '-1px',
        wordSpacing: '-1px',
        position: 'absolute',
        top: '2em',
        paddingLeft: '8px',
      },
    },
    MuiDialog: {
      '& .MuiDialog-paper': {
        maxHeight: '960px',
        minHeight: '691px',
      },
    },
    MuiDialogContent: {
      padding: '0px 60px',
      overflowY: 'hidden',
      '& .MuiInputBase-input': {
        color: '#000',
        fontFamily: 'sans-serif',
        fontSize: '14px',
      },
      '& .MuiFormLabel-root': {
        color: '#000',
        fontFamily: 'sans-serif',
      },
      '& .MuiInput-underline:before': {
        borderBottomColor: '#000',
      },
    },
  };

  return (
    <AlertDialogSlide
      materialStyles={dialogStyles}
      title={product.ShortDescription ? product.ShortDescription : ''}
      actions={actions}
      fullWidth={true}
    >
      <Tabs forceRenderTabPanel defaultIndex={3}>
        <TabList>
          <div className="flex flex-row-reverse">
            <Tab className={`${watermark}`} disabled>
              {' '}
              <img alt="Workflow" style={{marginLeft: '20px', paddingBottom: '6px'}} src={workflow} /> Workflow
            </Tab>
            <Tab className={`${watermark}`} disabled>
              {' '}
              <img alt="Availability" style={{marginLeft: '22px', paddingBottom: '6px'}} src={availibility} />{' '}
              Availibility
            </Tab>
            <Tab>
              {' '}
              <img alt="Price" style={{marginLeft: '4px', paddingBottom: '2px'}} src={price} /> Price
            </Tab>
            <Tab>
              {' '}
              <img alt="General" style={{marginLeft: '14px', paddingBottom: '2px'}} src={general} /> General
            </Tab>
          </div>
        </TabList>
        <TabPanel></TabPanel>
        <TabPanel></TabPanel>
        <TabPanel>
          <div className="flex justify-center pt-3">
            <div style={{paddingTop: '40px'}}>
              <div className="md:flex my-5">
                <div id="#responsive-header" className="mr-2">
                  <TextField
                    disabled
                    name="YourLastCostEx"
                    label="CURRENT COST (ex)"
                    className="mr-4"
                    style={{minWidth: 237}}
                    value={
                      state && state.SpecialSellPriceEx !== 0
                        ? state.SpecialSellPriceEx && PriceFormat(state.SpecialSellPriceEx)
                        : state.StandardSellPriceEx && PriceFormat(state.StandardSellPriceEx)
                    }
                    onChange={handleChange}
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </div>
                <div className="ml-2">
                  <TextField
                    disabled
                    name="YourAverageCostEx"
                    label="YOUR AVERAGE COST (ex)"
                    className="mr-4"
                    style={{minWidth: 237}}
                    value="N/A"
                    onChange={handleChange}
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </div>
                <div className="ml-2">
                  <TextField
                    disabled
                    name="YourSellEx"
                    label="YOUR SELL (ex)"
                    className="mr-4"
                    style={{minWidth: 237}}
                    value="N/A"
                    onChange={handleChange}
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </div>
              </div>
              <Divider classes={{root: classes.divider}} variant="fullWidth" />
              <div className="md:flex justify-center my-5">
                <div className="mr-2">
                  <FormControl>
                    <InputLabel
                      style={{paddingLeft: '74px', textAlign: 'center', color: '#0092a8', fontSize: '22px'}}
                      htmlFor="markup-input"
                    >
                      MARKUP
                    </InputLabel>
                    <div style={{marginTop: '20px'}}>
                      <FilledBootstrapInput
                        className={clsx(classes.root)}
                        style={{
                          borderRadius: 3,
                          borderRight: '1px solid #0092a8',
                          borderLeft: '1px solid #0092a8',
                          padding: '0 30px',
                        }}
                        disabled={true}
                        defaultValue="0%"
                        id="markup-input"
                      />
                    </div>
                  </FormControl>
                </div>
                <div className="ml-5">
                  <FormControl>
                    <InputLabel
                      style={{paddingLeft: '122px', textAlign: 'center', color: '#0092a8', fontSize: '22px'}}
                      htmlFor="margin-input"
                    >
                      MARGIN
                    </InputLabel>
                    <div className="flex" style={{marginTop: '20px'}}>
                      <FilledBootstrapInput
                        className={clsx(classes.root)}
                        style={{
                          borderTopLeftRadius: 3,
                          borderLeft: '1px solid #0092a8',
                          paddingLeft: '10px',
                        }}
                        disabled={true}
                        defaultValue="$ 0.00"
                        id="margin-input"
                      />
                      <FilledBootstrapInput
                        className={clsx(classes.root)}
                        style={{
                          borderTopRightRadius: 3,
                          borderRight: '1px solid #0092a8',
                          paddingRight: '10px',
                        }}
                        disabled={true}
                        defaultValue="0%"
                        id="margin-per-input"
                      />
                    </div>
                  </FormControl>
                </div>
              </div>
              <Divider classes={{root: classes.divider}} variant="fullWidth" />
              <div className="my-5 md:flex justify-center">
                <div id="#responsive-header" className="mr-2">
                  <TextField
                    disabled
                    name="RrpInc"
                    label="RRP INC"
                    className="mr-4"
                    style={{minWidth: 237}}
                    value="N/A"
                    onChange={handleChange}
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </div>
                <div className="ml-5">
                  <TextField
                    disabled
                    name="SellInc"
                    label="SELL INC"
                    className="mr-4"
                    style={{minWidth: 237}}
                    value="N/A"
                    onChange={handleChange}
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </div>
              </div>
              <Divider classes={{root: classes.divider}} variant="fullWidth" />
              <div className="my-5 md:flex">
                <div className="md:w-full flex">
                  <InputLabel style={{paddingTop: '13px', paddingLeft: '200px'}} htmlFor="margin-input">
                    Is this item tax exempt ?
                  </InputLabel>
                  <Typography component="div" style={{display: 'contents'}}>
                    <Grid component="label" container alignItems="center" spacing={1}>
                      <Grid item>NO</Grid>
                      <Grid item>
                        <IOSSwitch
                          disabled
                          checked={stateCheck.checkedB}
                          onChange={handleChangeCheck('checkedB')}
                          value="checkedB"
                        />
                      </Grid>
                      <Grid item>YES</Grid>
                    </Grid>
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="flex">
            <div>
              <div className="md:flex my-4">
                <div id="#responsive-header" className="mr-2">
                  <TextField
                    name="Code"
                    label="CODE"
                    className="mr-4"
                    style={{fontSize: 14}}
                    value={state && state.InventoryCode ? state.InventoryCode : 'N/A'}
                    onChange={handleChange}
                    fullWidth
                    InputProps={{
                      disabled: true,
                    }}
                  />
                </div>
                <div className="ml-2">
                  <TextField
                    name="Sku"
                    label="SKU"
                    className="mr-4"
                    value={state && state.SKU ? state.SKU : 'N/A'}
                    onChange={handleChange}
                    InputProps={{
                      disabled: true,
                    }}
                  />
                </div>
              </div>
              <div className="md:flex">
                <div className="mr-2">
                  <TextField
                    name="Barcode"
                    label="BARCODE"
                    className="mr-4"
                    value={state && state.Barcode ? state.Barcode : 'N/A'}
                    onChange={handleChange}
                    InputProps={{
                      disabled: true,
                    }}
                  />
                </div>
                <div className="ml-2">
                  <TextField
                    disabled={true}
                    name="UnitOfMeasure"
                    label="UNIT OF MEASURE"
                    className="mr-4"
                    value={state && state.UoMDescription ? state.UoMDescription : 'N/A'}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="my-5 md:flex">
                <div className="md:w-full">
                  <FormControl>
                    <InputLabel shrink htmlFor="salesDescription-input">
                      SALES DESCRIPTION
                    </InputLabel>
                    <BootstrapInput
                      multiline
                      rowsMax="3"
                      disabled={true}
                      defaultValue={state && state.Description ? state.Description : 'N/A'}
                      id="salesDescription-input"
                    />
                  </FormControl>
                </div>
              </div>
              <div className="my-5 md:flex">
                <div className="md:w-full">
                  <FormControl>
                    <InputLabel shrink htmlFor="brand-input">
                      BRAND
                    </InputLabel>
                    <BootstrapInput
                      multiline
                      rowsMax="3"
                      disabled={true}
                      defaultValue={state && state.BrandName ? state.BrandName : 'N/A'}
                      id="brand-input"
                    />
                  </FormControl>
                </div>
              </div>
              <div className="my-5 md:flex">
                <div className="md:w-full">
                  <CssTextField
                    disabled
                    name="Class"
                    label="CLASS"
                    value="N/A"
                    onChange={handleChange}
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                    style={{color: 'grey !important'}}
                    // variant="outlined"
                    variant="filled"
                  />
                </div>
              </div>
              <div className="my-5 md:flex">
                <div className="md:w-full">
                  <FormControl>
                    <InputLabel shrink htmlFor="categories-input">
                      CATEGORIES
                    </InputLabel>
                    <BootstrapInput
                      multiline
                      rowsMax="3"
                      fullWidth
                      disabled={true}
                      defaultValue={
                        state && state.Categories && state.Categories[0].Name ? state.Categories[0].Name : 'N/A'
                      }
                      id="categories-input"
                    />
                  </FormControl>
                </div>
              </div>
            </div>
            <div className={`${image}`}>
              <div className={`flex justify-center ${hasImage || 'items-center'} ${body}`} style={colorsStyle}>
                {hasImage ? (
                  <img
                    style={{width: '380px', height: '368px'}}
                    alt={product.ShortDescription}
                    src={product.DefaultImageMediaFileThumbnailURL}
                    title={product.ShortDescription}
                  />
                ) : (
                  <div className={`flex justify-center items-center mx-auto text-grey h-8 ${style}`}>
                    <VideocamOffTwoToneIcon></VideocamOffTwoToneIcon> NO IMAGE
                  </div>
                )}
              </div>
            </div>
          </div>
        </TabPanel>
      </Tabs>
    </AlertDialogSlide>
  );
};

export default ProductDetailsDialog;
