import * as React from 'react';
const ARCreditStoppedIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="20" height="16" {...props}>
    <path
      fill="#c55d44"
      fillRule="evenodd"
      d="M19 10.9c0 2.4-2 4.4-4.4 4.4-1.8 0-3.4-1.1-4.1-2.6H2c-1.1 0-2-.9-2-2v-8c0-1.1.9-2 2-2h14c1 0 1.9.9 1.9 2V8c.7.8 1.1 1.8 1.1 2.9zm-4.4 3.5c1.9 0 3.5-1.6 3.5-3.5 0-.8-.3-1.5-.7-2.1l-5 4.9c.6.4 1.4.7 2.2.7zm0-7c-2 0-3.5 1.6-3.5 3.5 0 .8.2 1.5.7 2.1l4.9-4.9c-.6-.4-1.3-.7-2.1-.7zm-4.5 3.5c0-2.4 2-4.4 4.5-4.4.9 0 1.7.3 2.4.7V2.7c0-.6-.5-1.1-1-1.1H2c-.6 0-1.1.5-1.1 1.1v8c0 .6.5 1.1 1.1 1.1h8.2q-.1-.5-.1-.9zm-7.3-.4c-.6 0-.6-.9 0-.9h5.7c.6 0 .6.9 0 .9zm3.5-2.2c-.6 0-.6-1 0-1h2c.6 0 .6 1 0 1zm-3.6 0c-.6 0-.6-1 0-1h2c.6 0 .6 1 0 1zm-.6-5.6h4.1V6H2.1zm1 2.4h2.2V3.6H3.1z"
    />
  </svg>
);
export default ARCreditStoppedIcon;
