import React from 'react';

export const DownloadIcon = (props: {width?: string; height?: string; className?: string}) => {
  return (
    <svg
      width={props.width || '19px'}
      className={props.className}
      height={props.height || '20px'}
      viewBox="0 0 19 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Download</title>
      <g id="Order-Listing" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Ready-to-Pick" transform="translate(-1254.000000, -502.000000)" fill="#fff" fillRule="nonzero">
          <g id="Download" transform="translate(1254.000000, 502.000000)">
            <path
              d="M0.633333333,14.916875 C0.283552992,14.916875 0,15.196697 0,15.541875 L0,19.375 C0,19.720178 0.283552992,20 0.633333333,20 L18.3666667,20 C18.716447,20 19,19.720178 19,19.375 L19,15.541875 C19,15.196697 18.716447,14.916875 18.3666667,14.916875 C18.0168863,14.916875 17.7333333,15.196697 17.7333333,15.541875 L17.7333333,18.75 L1.26666667,18.75 L1.26666667,15.541875 C1.26666667,15.196697 0.983113675,14.916875 0.633333333,14.916875 Z"
              id="Path"
            ></path>
            <path
              d="M9.5,0 C9.15021966,0 8.86666667,0.279822031 8.86666667,0.625 L8.86666667,14.06875 L4.84943333,10.239375 C4.68674649,10.0842994 4.45258271,10.026654 4.23514922,10.0881533 C4.01771573,10.1496526 3.85004585,10.3209534 3.79529921,10.5375283 C3.74055257,10.7541033 3.80704648,10.9830494 3.96973333,11.138125 L9.05096667,15.9825 L9.05223333,15.98375 L9.05983333,15.990625 C9.08326667,16.013125 9.11303333,16.0225 9.13836667,16.040625 C9.17763333,16.06875 9.21436667,16.1 9.25996667,16.118125 C9.41326902,16.1806975 9.58546432,16.1806975 9.73876667,16.118125 C9.7812,16.10125 9.81476667,16.071875 9.8515,16.04625 C9.88063333,16.026875 9.91356667,16.015 9.93953333,15.99 L15.029,11.136875 C15.2804921,10.8968037 15.2871556,10.5009955 15.0438833,10.2528125 C14.8006111,10.0046295 14.3995254,9.99805372 14.1480333,10.238125 L10.1333333,14.06875 L10.1333333,0.625 C10.1333333,0.279822031 9.84978034,0 9.5,0 Z"
              id="Path"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
