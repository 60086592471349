import React, {useState} from 'react';
import {Layout} from '../../../components/layout/Layout';
import ServiceUninvoiceJobList from './ServiceUninvoiceJobList';
import ServiceUninvoiceHeader from './ServiceUninvoiceHeader';
import {UninvoicedProvider} from './context/UninvoicedProvider';

export const ServiceUninvoice = () => {
  const [columnOptions, setColumnOptions] = useState<string[]>([
    'Completion date',
    'Company name',
    'Primary tech',
    'Asset details',
    'Customer email',
    'Phone no.',
    'No. of lines',
    'Total inc',
    'Total line qty',
  ]);

  const _serviceHistory = (
    <div className="flex h-[calc(100vh-120px)] flex-col items-start justify-between rounded bg-white p-2">
      <ServiceUninvoiceHeader columnOptions={columnOptions} setColumnOptions={setColumnOptions} />
      <ServiceUninvoiceJobList />
    </div>
  );

  return (
    <UninvoicedProvider>
      <Layout leftPanel={_serviceHistory} splitWidthType={4} />
    </UninvoicedProvider>
  );
};
