import React from 'react';

export const IconSort = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg
            width={23}
            height={23}
            viewBox="0 0 23 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.17.791H4.422C2.401.791.762 2.415.762 4.418v2.72c0 2.002 1.639 3.626 3.661 3.626H7.17c2.023 0 3.662-1.624 3.662-3.627v-2.72c0-2.002-1.64-3.626-3.662-3.626zM9 7.137c0 1.002-.82 1.814-1.83 1.814H4.422a1.822 1.822 0 01-1.83-1.814v-2.72c0-1 .82-1.813 1.83-1.813H7.17C8.18 2.604 9 3.416 9 4.418v2.72zM7.17 12.577H4.422c-2.022 0-3.661 1.624-3.661 3.626v2.72c0 2.003 1.639 3.627 3.661 3.627H7.17c2.023 0 3.662-1.624 3.662-3.627v-2.72c0-2.002-1.64-3.626-3.662-3.626zM9 18.923c0 1.002-.82 1.813-1.83 1.813H4.422a1.822 1.822 0 01-1.83-1.813v-2.72c0-1.001.82-1.813 1.83-1.813H7.17C8.18 14.39 9 15.202 9 16.203v2.72zM21.168 18.061l-2.1 2.076V3.178l2.1 2.076a.922.922 0 00.884.235.911.911 0 00.647-.642.9.9 0 00-.237-.875L20.094 1.63a2.765 2.765 0 00-3.881 0l-2.368 2.342a.9.9 0 00-.238.875.911.911 0 00.648.642.922.922 0 00.884-.235l2.099-2.076v16.96l-2.1-2.077a.921.921 0 00-.883-.234.912.912 0 00-.648.641.9.9 0 00.238.876l2.368 2.341a2.765 2.765 0 003.88 0l2.369-2.341a.9.9 0 00.237-.876.911.911 0 00-.647-.641.921.921 0 00-.884.234z"
                fill="currentColor"
            />
        </svg>
    )
}
