import React, {useState} from 'react';
import {useHistory, Link as RouterLink} from 'react-router-dom';

import {PriceFormat} from '../../utils/formatter';
import {Inventory} from '../../model/inventory/Inventory';
import {ICategory} from '../../model/inventory/Category';
import {Layout} from '../../components/layout/Layout';
import {STable} from '../../components/tables/STable';
import {SPagination} from '../../components/tables/SPagination';
import {InventoryToolbar} from '../../components/inventory/InventoryToolbar';
import {UploadProductsDialog} from '../../components/dialog/UploadProductsDialog';
import {useLoadInventory} from '../../services/useInventoryAPI';
import {Button, Typography} from 'spenda-ui-react';

import {
  AUTH_INVENTORY_BRAND_MANAGEMENT,
  AUTH_INVENTORY_CATEGORY_MANAGEMENT,
  AUTH_INVENTORY_PRODUCT_ADD,
  AUTH_INVENTORY_PRODUCT_EDIT,
  AUTH_INVENTORY_PRODUCT_UPLOAD,
} from '../../routes/InventoryRoutes';
import {useFeatureFlags} from '../../hooks/useFeatureFlags';

const columnsRender = {
  InventoryCode: {
    disabled: true,
    title: 'Code',
    field: 'InventoryCode',
    render: (rowData: Inventory) => {
      // if (rowData?.variants) {
      //   return (
      //     <div {...css({position: 'relative'})}>
      //       <span>{rowData.InventoryCode}</span>
      //       <span
      //         {...css({
      //           padding: '0 0.25rem',
      //           lineHeight: '2',
      //           backgroundColor: '#C68A1920',
      //           borderRadius: '0.1rem',
      //           color: '#C68A19',
      //           fontWeight: 500,
      //           fontSize: '0.5rem',
      //           position: 'absolute',
      //           top: '-0.75rem',
      //           left: 0,
      //         })}
      //       >{`${rowData?.variants} variants`}</span>
      //     </div>
      //   );
      // }
      return <span className='text-sm font-poppins'>{rowData.InventoryCode}</span>
    },
  },
  ShortDescription: {disabled: true, title: 'Product Name', field: 'ShortDescription', className: 'ellpsisCol'},
  UoM: {title: 'UoM', field: 'UoM'},
  soh: {title: 'SOH', field: 'soh'},
  category: {
    title: 'Category',
    field: 'category',
    className: 'ellpsisCol',
    render: (rowData: Inventory) =>
      Array.isArray(rowData?.Categories) ? rowData.Categories.map((c: ICategory) => c.Name).join(', ') : '',
  },
  brand: {
    title: 'Brand',
    field: 'brand',
    render: (rowData: Inventory) => rowData?.Brand?.Name || '',
  },
  // warehouse: {
  //   title: 'Warehouse',
  //   field: 'warehouse',
  //   render: (rowData: Inventory) => rowData?.Brand?.Name || '',
  // },
  InventoryClassName: {title: 'Product Class', field: 'InventoryClassName'},
  CostPriceEx: {
    title: 'Cost Price',
    field: 'CostPriceEx',
    render: (rowData: Inventory) => PriceFormat(rowData.CostPriceEx || 0),
  },
  StandardSellPriceEx: {
    title: 'Sell Price(Ex.)',
    field: 'StandardSellPriceEx',
    render: (rowData: Inventory) => PriceFormat(rowData.StandardSellPriceEx || 0),
  },
  StandardSellPriceInc: {
    title: 'Sell Price(Inc.)',
    field: 'StandardSellPriceInc',
    render: (rowData: Inventory) => PriceFormat(rowData.StandardSellPriceInc || 0),
  },
  IsPublished: {
    title: 'Published',
    field: 'IsPublished',
    render: (rowData: Inventory) => {
      switch (rowData.IsPublished) {
        case true:
          return <span className="text-[#3C9F78]">True</span>;
        case false:
          return <span className="text-[#938032]">False</span>;
        default:
          return rowData.IsPublished;
      }
    },
  },
  IsPostingInventoryItem: {
    title: 'Posting Item',
    field: 'IsPostingInventoryItem',
    render: (rowData: Inventory) => {
      switch (rowData.IsPostingInventoryItem) {
        case true:
          return <span className="text-[#3C9F78]">True</span>;
        case false:
          return <span className="text-[#938032]">False</span>;
        default:
          return rowData.IsPostingInventoryItem;
      }
    },
  },
  DefaultSupplierName: {title: 'Supplier', field: 'DefaultSupplierName'},
  IsActive: {
    title: 'Status',
    field: 'IsActive',
    render: (rowData: Inventory) => {
      switch (rowData.IsActive) {
        case true:
          return <span className="text-[#3C9F78]">Active</span>;
        case false:
          return <span className="text-[#934532]">In Active</span>;
        default:
          return rowData.IsActive;
      }
    },
  },
};

export const ProductsList = () => {
  const history = useHistory();

  const [selected, setSelected] = useState<number[]>([]);
  const [isShowUploadProductsDialog, setIsShowUploadProductsDialog] = useState<boolean>(false);
  const [selectedColumns, setSelectedColumns] = useState<any>([
    'InventoryCode',
    'ShortDescription',
    'UoM',
    'category',
    'brand',
    'soh',
    'CostPriceEx',
    'StandardSellPriceEx',
    'IsActive',
  ]);
  const {QuoteV289367} = useFeatureFlags().tenantOwned();
  const {
    loading,
    totalCount,
    pageSize,
    currentPage,
    items: inventoryList,
    searchFilter,
    getInventoryList,
    setSearchFilter,
  } = useLoadInventory();

  const handleRowClick = (rowData: Inventory) => {
    history.push(AUTH_INVENTORY_PRODUCT_EDIT.replace(/:productID/g, rowData.ID));
  };

  const handlePageChange = (newPage: number) => {
    setSearchFilter({
      ...searchFilter,
      LastRowNumber: (newPage - 1) * pageSize,
    });
  };

  const handleRowsChange = (rows: number) => {
    setSearchFilter({
      ...searchFilter,
      MaxResults: rows,
      LastRowNumber: 0,
    });
  };

  const handleContinue = () => {
    setIsShowUploadProductsDialog(false);
    history.push(AUTH_INVENTORY_PRODUCT_UPLOAD);
  };

  const productsList = (
    <div
      className={` flex max-h-[calc(100vh-20.5em)] min-h-full w-full flex-col overflow-y-auto rounded-lg bg-white px-5 pt-4`}
      id="products-list"
    >
      <div className={'flex w-full justify-between px-1 py-4'}>
        <Typography className="text-xl font-medium text-[#4D4D4D]">Products</Typography>
        <div className="flex">
          <RouterLink to={AUTH_INVENTORY_BRAND_MANAGEMENT}>
            <Button variant="outlined" size="sm" className="mr-2">
              Edit Brands
            </Button>
          </RouterLink>
          <RouterLink to={AUTH_INVENTORY_CATEGORY_MANAGEMENT}>
            <Button variant="outlined" size="sm" className="mr-2">
              Edit Categories
            </Button>
          </RouterLink>
          {QuoteV289367 && (
            <Button variant="outlined" size="sm" className="mr-2" onClick={() => setIsShowUploadProductsDialog(true)}>
              Upload Products
            </Button>
          )}
          <RouterLink to={AUTH_INVENTORY_PRODUCT_ADD}>
            <Button size="sm">Create Product</Button>
          </RouterLink>
        </div>
      </div>
      <InventoryToolbar
        loading={loading}
        title={`Product Listing (${totalCount})`}
        searchFilter={searchFilter}
        columns={columnsRender}
        selectedColumns={selectedColumns}
        data={inventoryList}
        selected={selected}
        refetchList={getInventoryList}
        setSelected={setSelected}
        setSelectedColumns={setSelectedColumns}
        setSearchFilter={setSearchFilter}
      />
      <STable
        columns={Object.entries(columnsRender)
          .map(([key, value]: [string, any]) => {
            if (selectedColumns.includes(key)) {
              return value;
            }
          })
          .filter(value => value)}
        data={inventoryList}
        emptyDataMessage={'No Products'}
        stickyHeader={true}
        selection={true}
        selected={selected}
        setSelected={setSelected}
        loading={loading}
        onRowClick={handleRowClick}
        newUI
      ></STable>
      <SPagination
        currentPage={currentPage}
        totalCount={totalCount}
        pageSize={pageSize}
        onPageChange={handlePageChange}
        rowsPerPageOptions={[10, 15, 25]}
        onRowsPerPageChange={handleRowsChange}
      />
    </div>
  );

  return (
    <div className={`relative h-full overflow-hidden bg-spenda-newbg`}>
      <Layout leftPanel={productsList} splitWidthType={4} />
      {isShowUploadProductsDialog && <UploadProductsDialog handleCancel={() => setIsShowUploadProductsDialog(false)} handleContinue={handleContinue}/>}
    </div>
  );
};
