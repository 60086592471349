import React, {FunctionComponent, ReactNode, ChangeEvent} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {Tabs, Tab, TabProps} from '@material-ui/core';
import {createAutoIdAttributeFromName} from '../../utils/createAutoIdAttribute';

interface TabData {
  label: string;
  content: ReactNode;
}

interface ISTabsDialog {
  tabs: TabData[];
  value: number;
  handleChange(newValue: number): any;
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props: {value: number; index: number; children: ReactNode}) {
  const {children, value, index, ...other} = props;

  return (
    <div
      className="w-full"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const StyledTabs = withStyles({
  indicator: {
    display: 'none',
  },
  root: {
    minHeight: '30px',
    backgroundColor: 'transparent',
    borderRadius: '5px',
    padding: '2px',
    marginBottom: '15px',
  },
})((props: any) => <Tabs {...props} TabIndicatorProps={{children: <span />}} />);

const StyledTab = withStyles(() => ({
  textColorInherit: {
    color: '#999999',
  },
  selected: {
    color: '#333333 !important',
    borderBottom: '3px solid hsl(var(--primary))',
    borderRadius: '0px',
  },
  root: {
    color: '#999999',
    textTransform: 'none',
    padding: '0px',
    minWidth: 'fit-content',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '1.5',
    minHeight: '30px',
    marginRight: '40px',
  },
}))((props: TabProps) => <Tab disableRipple {...props} />);

export const STabsDialog: FunctionComponent<ISTabsDialog> = props => {
  const {tabs, value, handleChange} = props;

  const handleTabClick = (event: ChangeEvent<{}>, value: number) => {
    handleChange(value);
  };

  return (
    <>
      <StyledTabs scrollButtons="auto" value={value} onChange={handleTabClick}>
        {tabs.map((tab: TabData, index) => (
          <StyledTab
            label={tab.label}
            key={`stab-${index}`}
            {...a11yProps(index)}
            {...createAutoIdAttributeFromName(tab.label, 'btn')}
          />
        ))}
      </StyledTabs>
      {tabs.map((tab: TabData, index) => (
        <TabPanel key={`stab-panel-${index}`} value={value} index={index}>
          {tab.content}
        </TabPanel>
      ))}
    </>
  );
};
