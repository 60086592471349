import {FileDetails} from './../model/FileDetails';
import {IActionResults} from '../model/ActionResults';
import useHttp from '../hooks/useHttp';

const useMediaAPI = () => {
  const {POST, isLoading} = useHttp();

  const upload = (data: any, header: {[key: string]: string} | undefined = undefined): Promise<FileDetails[]> => {
    return POST('media/upload', data, true, false, header).then((data: IActionResults<FileDetails[]>) => data.Value);
  };

  return {
    upload,
    isLoading,
  };
};

export default useMediaAPI;
