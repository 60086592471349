import React, {useContext, useEffect, useState} from 'react';
import AddIcon from '../../assets/svg/accounts-payable/add.svg';
import {ARDialogTemplate} from './ARDialogTemplate';
import {CreateEmailTemplateDialog} from './CreateEmailTemplateDialog';
import {PreviewModal} from './modals/PreviewModal';
import {Toast} from '../../utils/Toast';
import LoadingIndicator from '../ui/LoadingIndicator';
import {ISendMailPayload, useAutomatedStatementsAPI} from '../../services/useAutomatedStatementsAPI';
import {IEmailTemplate} from '../../model/statements/Statements';
import {Button} from 'spenda-ui-react';
import clsx from 'clsx';
import ARContext from '../../context/ar-context/ARContext';
import {DatTypes} from '../../model/constants/Constants';
import IconBinTrash from '../../assets/svg/IconBinTrash';
import {WarningDialog} from './modals/WarningDialog';

export enum SelectEmailTemplateScope {
  SelectTemplate = 'SelectTemplate',
  SendStatement = 'SendStatement',
  SendInvoice = 'SendInvoice',
}

interface ISelectEmailTemplateDialogProps {
  isShowModal: boolean;
  onClose: () => void;
  scope: SelectEmailTemplateScope;
  onConfirm?: (selectedTemplate: IEmailTemplate) => void;
  invoiceId?: number;
  accountCustomerId?: number;
}

export const SelectEmailTemplateDialog = (props: ISelectEmailTemplateDialogProps) => {
  // Props
  const {isShowModal, invoiceId, accountCustomerId, scope, onClose, onConfirm} = props;

  // State
  const [isShowCreateTemplateDialog, setIsShowCreateTemplateDialog] = useState(false);
  const [showPreviewTemplateDialog, setShowPreviewTemplateDialog] = useState(false);
  const [templateData, setTemplateData] = useState<IEmailTemplate[]>();
  const [selectedTemplate, setSelectedTemplate] = useState<IEmailTemplate>();
  const [confirmDeleteTemplate, setConfirmDeleteTemplate] = useState<{isShowDialog: boolean; alertId: number}>();

  // Context
  const {emailBatch} = useContext(ARContext);

  // API
  const {getEmailTemplate, isLoading: isGetTemplateLoading} = useAutomatedStatementsAPI();
  const {deleteEmailTemplate, isLoading: isDeleteTemplateLoading} = useAutomatedStatementsAPI();
  const {sendMail, isLoading: isSendMailLoading} = useAutomatedStatementsAPI();

  useEffect(() => {
    fetchTemplateDetails();
  }, []);

  const fetchTemplateDetails = async () => {
    try {
      const query = {
        datTypeID: DatTypes.CustomerStatements,
        websiteID: 13,
      };
      const templateDetailsResponse = await getEmailTemplate(query);
      setTemplateData(templateDetailsResponse);
      const sTemplate = templateDetailsResponse?.find(t => t?.alertID === selectedTemplate?.alertID);
      setSelectedTemplate(sTemplate || templateDetailsResponse?.[0]);
    } catch {}
  };

  const handleCreateEmailTemplateClose = async () => {
    setIsShowCreateTemplateDialog(false);
    await fetchTemplateDetails();
  };

  const handleDone = () => {
    if (selectedTemplate) {
      onConfirm?.(selectedTemplate);
    } else {
      Toast.error('Please select a template');
    }
  };

  const onClickAddEmailTemplate = () => {
    setSelectedTemplate(undefined);
    setIsShowCreateTemplateDialog(true);
  };

  const handleSendEmail = async () => {
    try {
      if (!selectedTemplate) {
        Toast.error('Please select a template');
        return;
      }

      let payload: ISendMailPayload = {
        alertID: selectedTemplate?.alertID,
      };

      if (scope === SelectEmailTemplateScope.SendStatement) {
        const accountCustomerIds = emailBatch?.emailBatchList?.map(e => e?.accountCustomerId);
        payload = {
          ...payload,
          accountCustomerIDs: accountCustomerIds,
          timelineGroup: emailBatch?.timelineGroup,
          timelineSubGroupID: emailBatch?.timelineSubGroupId,
        };
      }
      if (scope === SelectEmailTemplateScope.SendInvoice) {
        if (!accountCustomerId) return;
        payload = {
          ...payload,
          accountCustomerIDs: [accountCustomerId],
          invoiceID: invoiceId,
        };
      }

      await sendMail(payload);
      Toast.info('Success');
      onClose();
    } catch {}
  };

  const handleDeleteEmailTemplate = async () => {
    try {
      if (!confirmDeleteTemplate) return;
      await deleteEmailTemplate(confirmDeleteTemplate?.alertId);
      setConfirmDeleteTemplate(undefined);
      Toast.info('Email Template Deleted Successfully');
      await fetchTemplateDetails();
    } catch {}
  };

  const handlePreviewTemplate = (template: IEmailTemplate) => {
    setSelectedTemplate(template);
    setShowPreviewTemplateDialog(true);
  };

  return (
    <div className="relative">
      <ARDialogTemplate
        dialogProps={{size: 'md', open: isShowModal, className: '!min-w-[810px] !max-w-[810px]'}}
        isFullScreen
        footerClass="!h-[60px]"
        header={<p className="from-spenda-primarytext font-poppins text-xl font-light">Select email template</p>}
        body={
          <div className="relative flex !h-[240px] w-auto flex-wrap items-start justify-start !gap-x-2.5 !gap-y-2.5 overflow-y-auto">
            {isGetTemplateLoading ? (
              <LoadingIndicator size="md" isLoading={isGetTemplateLoading} />
            ) : (
              <>
                {templateData?.map(t => (
                  <div data-autoid={`rowTemplate${t?.alertID}`} key={t?.alertID} className="relative">
                    <button
                      key={t?.alertID}
                      data-autoid={`btnTemplate${t?.alertID}`}
                      className={`flex h-[110px] w-[180px] cursor-pointer flex-col items-start justify-between gap-[8px] rounded-[6px] border px-2.5 pb-5 pt-2.5 ${
                        t?.alertID == selectedTemplate?.alertID ? 'border-[#1C78AD]' : 'border-[#F1F1F1]'
                      } bg-[#FAFAFA]`}
                      onClick={() => setSelectedTemplate(t)}
                    >
                      <div
                        data-autoid={`lblTemplateName${t?.alertID}`}
                        className={clsx(
                          `mr-3 flex items-center justify-center rounded-[6px] px-[8px] py-[4px] font-poppins text-xs font-medium`,
                          t?.colourCode && `font-bold`,
                        )}
                        style={{
                          background: `${t?.colourCode}40`,
                          filter: 'alpha(opacity=25)',
                          color: t?.colourCode,
                        }}
                      >
                        {t?.name}
                      </div>
                      <p
                        data-autoid={`lblDescription${t?.alertID}`}
                        className="flex items-center justify-center text-left font-poppins text-xs font-normal text-[#666666]"
                      >
                        {t?.description}
                      </p>
                    </button>
                    <button
                      data-autoid={`btnDeleteTemplate${t?.alertID}`}
                      type="button"
                      onClick={() => setConfirmDeleteTemplate({alertId: t?.alertID, isShowDialog: true})}
                      className="absolute right-1 top-2 flex cursor-pointer flex-row items-center justify-end"
                    >
                      <IconBinTrash />
                    </button>
                  </div>
                ))}
                <div
                  data-autoid="btnAddTemplate"
                  onClick={onClickAddEmailTemplate}
                  className="flex h-[110px] w-[180px] cursor-pointer flex-row items-center justify-center rounded-[6px] bg-[#E3EEF5]"
                >
                  <img alt="Add" width="30px" src={AddIcon} data-autoid="imgAddATemplate"></img>
                </div>
              </>
            )}
          </div>
        }
        footer={
          <div className=" flex h-[60px] w-full items-center justify-between">
            <div className="flex flex-row items-center justify-start gap-3">
              <Button
                data-autoid={`btnCancel`}
                className="bg-white"
                onClick={onClose}
                variant="outlined"
                color="primary"
              >
                Cancel
              </Button>
              <Button
                disabled={isGetTemplateLoading}
                data-autoid={`btnEditTemplate`}
                className="bg-white"
                onClick={() => setIsShowCreateTemplateDialog(true)}
                variant="outlined"
                color="primary"
              >
                Edit Template
              </Button>
            </div>
            <div className="flex flex-row items-center justify-end gap-3">
              <Button
                disabled={isGetTemplateLoading}
                data-autoid={`btnPreview`}
                className="bg-white"
                onClick={() => setShowPreviewTemplateDialog(true)}
                variant="outlined"
                color="primary"
              >
                Preview
              </Button>
              {(scope === SelectEmailTemplateScope.SendInvoice || scope === SelectEmailTemplateScope.SendStatement) && (
                <Button
                  onClick={handleSendEmail}
                  loading={isSendMailLoading}
                  disabled={isGetTemplateLoading}
                  variant="filled"
                  color="primary"
                  data-autoid={`btnSendMail`}
                >
                  Send Mail
                </Button>
              )}
              {scope === SelectEmailTemplateScope.SelectTemplate && (
                <Button
                  disabled={isGetTemplateLoading}
                  variant="filled"
                  color="primary"
                  onClick={() => handleDone()}
                  data-autoid={`btnDone`}
                >
                  Done
                </Button>
              )}
            </div>
          </div>
        }
        handleClose={onClose}
      />
      {confirmDeleteTemplate?.isShowDialog && (
        <WarningDialog
          conrfirmBtn={{
            title: 'Confirm',
            isLoading: isDeleteTemplateLoading,
            onClick: () => handleDeleteEmailTemplate(),
          }}
          cancelBtn={{title: 'Cancel', onClick: () => setConfirmDeleteTemplate(undefined)}}
          title="Warning"
          message="Are you sure want to delete email template?"
          onClose={() => setConfirmDeleteTemplate(undefined)}
          isOpen={confirmDeleteTemplate?.isShowDialog}
        />
      )}
      {isShowCreateTemplateDialog && !showPreviewTemplateDialog && (
        <CreateEmailTemplateDialog
          onClickPreviewButton={handlePreviewTemplate}
          alertId={selectedTemplate?.alertID}
          isShowModal={isShowCreateTemplateDialog}
          onClose={handleCreateEmailTemplateClose}
        />
      )}
      {showPreviewTemplateDialog && (
        <PreviewModal
          onClickSendButton={() => handleSendEmail()}
          onClickEditButton={() => {
            setShowPreviewTemplateDialog(false);
            setIsShowCreateTemplateDialog(true);
          }}
          isShowModal={showPreviewTemplateDialog}
          onClose={() => setShowPreviewTemplateDialog(false)}
          scope={scope}
          invoiceId={invoiceId}
          accountCustomerId={accountCustomerId}
          selectedTemplate={selectedTemplate}
        />
      )}
    </div>
  );
};
