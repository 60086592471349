import React from 'react';

export const IconUser = (props: {width?: string; height?: string; fill: string; viewBox: string}) => {
  return (
    <svg width={props.width} height={props.height} viewBox="0 0 36 33" xmlns="http://www.w3.org/2000/svg">
      <title>{'User Icon'}</title>
      <desc>{'Created with Sketch.'}</desc>
      <g id="LOGIN" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Suppliers" transform="translate(-1376.000000, -18.000000)">
          <g id="Group">
            <g id="General/-90" transform="translate(1376.000000, 18.000000)">
              <mask id="topmenu-usericon-2" fill="white">
                <path
                  d="M29.2832,26.774 C28.3272,25.938 26.4856,24.442 24.652,23.65 C23.14,22.99 22.2736,22.33 22.004,21.6186667 C21.8176,21.1273333 21.9008,20.57 22.26,19.9026667 C22.5312,19.3966667 22.7872,18.9786667 23.024,18.59 C24.0216,16.9546667 24.7928,15.7813333 24.7928,11.6013333 C24.7928,5.346 20.8552,5.03066667 20.076,5.03066667 C19.4376,5.03066667 19.0384,5.16266667 18.652,5.28733333 C18.2296,5.42666667 17.792,5.566 16.796,5.58066667 C14.9936,5.61 11.2176,5.66866667 11.2176,11.418 C11.2176,15.444 12.36,17.292 13.2584,18.7733333 C13.488,19.1546667 13.7048,19.514 13.8768,19.8733333 C14.6408,21.4573333 14.0832,22.572 12.12,23.364 C9.64,24.3686667 8.472,24.8966667 6.036,26.8546667 C3.3048,24.2293333 1.6176,20.6726667 1.6176,16.7566667 C1.6176,8.65333333 8.7808,2.09 17.6176,2.09 C26.4544,2.09 33.6176,8.65333333 33.6176,16.7566667 C33.6176,20.636 31.964,24.1486667 29.2832,26.774 Z M17.6176,31.4233333 C13.6344,31.4233333 10,30.0813333 7.1992,27.874 C9.3552,26.1433333 10.3856,25.674 12.7664,24.706 C15.524,23.5913333 16.4624,21.6113333 15.3408,19.2793333 C15.1392,18.8613333 14.9048,18.4726667 14.6576,18.062 C13.8064,16.654 12.8176,15.0626667 12.8176,11.418 C12.8176,7.106 15.0496,7.07666667 16.8224,7.04733333 C18.08,7.02533333 18.6944,6.82733333 19.1864,6.666 C19.5152,6.56333333 19.7136,6.49733333 20.076,6.49733333 C21.5584,6.49733333 23.1928,7.83933333 23.1928,11.6013333 C23.1928,15.3926667 22.572,16.3313333 21.628,17.8713333 C21.3792,18.282 21.1096,18.722 20.8256,19.25 C20.2744,20.2766667 20.1632,21.2373333 20.4936,22.1026667 C20.9176,23.2173333 22.0216,24.134 23.968,24.9773333 C25.5616,25.6666667 27.2344,27.016 28.1312,27.7933333 C25.3176,30.0446667 21.6464,31.4233333 17.6176,31.4233333 Z M17.6176,0.623333333 C7.8976,0.623333333 0.0176,7.84666667 0.0176,16.7566667 C0.0176,25.6666667 7.8976,32.89 17.6176,32.89 C27.3376,32.89 35.2176,25.6666667 35.2176,16.7566667 C35.2176,7.84666667 27.3376,0.623333333 17.6176,0.623333333 Z"
                  id="topmenu-usericon-1"
                />
              </mask>
              <g id="Overrides/ColourBlack" mask="url(#topmenu-usericon-2)" fill="#FFFFFF" fillRule="evenodd">
                <rect id="Rectangle-Copy" x={0} y={0} width={35.7142857} height={32.7380952} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
