import React, {useState, useEffect, useRef, FunctionComponent} from 'react';
import {useLocation, matchPath} from 'react-router-dom';
import {Box, makeStyles} from '@material-ui/core';
import {motion, useAnimation} from 'framer-motion';
import {Button} from 'spenda-ui-react';
import AddIcon from '@material-ui/icons/Add';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';

import {AUTH_SELLING_SALES_ORDER_PICK} from '../../routes/SalesOrderRoutes';
import {IConsignmentNotePackage, IConsignmentNotePackageLine} from '../../model/sales-order/ConsignmentNote';
import {CONSIGNMENT_NOTE_STATUS} from '../../model/constants/Constants';
import {STabsDialog} from '../tabs/STabsDialog';
import {STableCustom} from '../tables/STableCustom';
import PriceCalculator from '../../utils/priceCalculator';
import {SelectShippingAddressDialog} from '../../components/dialog/SelectShippingAddressDialog';
import {useConsignmentNoteAPI} from '../../services/useConsignmentNoteAPI';

const useOrderBottomBarStyles = makeStyles(() => ({
  bottomBoxActive: {
    borderRadius: '0 0 6px 6px',
    color: 'white',
    backgroundColor: 'hsl(var(--primary))',
    minWidth: '150px',
    textAlign: 'center',
  },
  bottomBox: {
    borderRadius: '0 0 6px 6px',
    color: 'hsl(var(--primary))',
    backgroundColor: 'hsl(var(--primary-background))',
    minWidth: '150px',
    textAlign: 'center',
  },
  bottomInput: {
    borderRadius: '4px',
    border: '1px solid #DDDDDD',
    backgroundColor: '#FFFFFF',
  },
  bottomTableBar: {
    padding: '20px 18px 0px',
    maxHeight: '125px',
    overflowY: 'auto',
  },
  bottomBarActionBtns: {
    background: '#fafafa',
  },
}));

export const SalesOrderBottomBar = (props: any) => {
  const location = useLocation();
  const {values, customerDetails, defaultOpenTab} = props;
  const [tabValue, setTabValue] = useState<number>(0);
  const [addressType, setAddressType] = useState<string>();
  const [locationDialog, setLocationDialog] = useState<boolean>(false);

  const isPicking = !!matchPath(location.pathname, {path: AUTH_SELLING_SALES_ORDER_PICK});

  const hendleDialog = () => {
    setLocationDialog(!locationDialog);
  };

  useEffect(() => {
    if (!isNaN(defaultOpenTab)) {
      setTabValue(defaultOpenTab);
    }
  }, [props.defaultOpenTab]);

  return (
    <Box className={`-mb-4 flex min-h-[200px] w-full bg-[#F9F9F9] px-3 py-5`}>
      <Box flex="3 1 auto" borderRight="1px solid #D3D3D3">
        <STabsDialog
          value={tabValue}
          handleChange={newValue => setTabValue(newValue)}
          tabs={[
            {
              label: 'Address',
              content: !values.CustomerID ? (
                <Box display="flex" justifyContent="center" marginBottom="5px" marginTop="30px">
                  <p className={`text-base font-semibold text-[#999999]`}>Please select customer</p>
                </Box>
              ) : (
                <Box display="flex" marginBottom="5px" marginTop="30px">
                  <Box flex={1} display="flex" className="relative mr-4 pl-2">
                    <table className="w-full">
                      <tbody>
                        {values?.ID && isPicking ? (
                          <>
                            <tr className="align-top">
                              <td className="pb-3">
                                <span className="font-medium text-spenda-primarytext">Customer Name-</span>
                              </td>
                              <td className="pb-3">
                                <span className="text-spenda-primarytext">{values?.CustomerName || '-'}</span>
                              </td>
                            </tr>
                            <tr className="align-top ">
                              <td className="pb-3">
                                <span className="font-medium text-spenda-primarytext">Phone Number-</span>
                              </td>
                              <td className="pb-3">
                                <span className="text-spenda-primarytext">{values?.ContactPhone || '-'}</span>
                              </td>
                            </tr>
                          </>
                        ) : null}
                        <tr className="relative  align-top">
                          <td width="145px" className="pb-3">
                            <span className="font-medium text-spenda-primarytext">Shipping Address-</span>
                          </td>
                          <td width="250px" className="pb-3">
                            <ul className="list-none">
                              <li>
                                <span className="text-spenda-labeltext">{values.ShippingAddress?.StreetAddress}</span>
                              </li>
                              <li>
                                <span className="text-spenda-labeltext">{values.ShippingAddress?.City}</span>
                              </li>
                              <li>
                                <span className="text-spenda-labeltext">{values.ShippingAddress?.State}</span>
                              </li>
                              <li>
                                <span className="text-spenda-labeltext">{values.ShippingAddress?.Country}</span>
                              </li>
                              <li>
                                <span className="text-spenda-labeltext">{values.ShippingAddress?.PostCode}</span>
                              </li>
                            </ul>
                          </td>
                          <div className="absolute cursor-pointer" onClick={() => {}}>
                            {!values?.Status ? (
                              !values.ShippingAddress?.City &&
                              !values.ShippingAddress?.Country &&
                              !values.ShippingAddress?.StreetAddress ? (
                                <AddIcon
                                  className="text-primary"
                                  onClick={() => {
                                    setAddressType('ShippingAddress');
                                    hendleDialog();
                                  }}
                                />
                              ) : (
                                <EditOutlinedIcon
                                  fontSize="inherit"
                                  width="14px"
                                  height="14px"
                                  className="text-primary"
                                  onClick={() => {
                                    setAddressType('ShippingAddress');
                                    hendleDialog();
                                  }}
                                />
                              )
                            ) : null}
                          </div>
                        </tr>
                      </tbody>
                      {props?.errors?.ShippingAddress && (
                        <div className="w-max text-sm text-error">
                          <p>Shipping Address is required</p>
                        </div>
                      )}
                    </table>
                    {locationDialog && addressType == 'ShippingAddress' && (
                      <SelectShippingAddressDialog
                        {...props}
                        addressType={addressType}
                        values={values}
                        open={locationDialog}
                        onClose={hendleDialog}
                        handleUpdateOpCustomer={props.handleUpdateOpCustomer}
                        handleSetAddress={props.handleSetAddress}
                        customerDetails={customerDetails}
                      ></SelectShippingAddressDialog>
                    )}
                  </Box>
                  <Box flex={1} display="flex" className="mr-4">
                    <table className="w-full">
                      <tbody>
                        {values?.ID && isPicking ? (
                          <>
                            <tr className="align-top">
                              <td className="pb-3">
                                <span className="font-medium text-spenda-primarytext">Company Name-</span>
                              </td>
                              <td className="pb-3">
                                <span className="text-spenda-primarytext">{customerDetails?.CompanyName || ''}</span>
                              </td>
                            </tr>
                            <tr className="align-top">
                              <td className="pb-3">
                                <span className="font-medium text-spenda-primarytext">Email-</span>
                              </td>
                              <td className="pb-3">
                                <span className="text-spenda-primarytext">{values?.ContactEmailAddress || '-'}</span>
                              </td>
                            </tr>{' '}
                          </>
                        ) : null}
                        <tr className="relative align-top">
                          <td width="145px" className="pb-3">
                            <span className="font-medium text-spenda-primarytext">Billing Address-</span>
                          </td>
                          <td width="250px" className="pb-3">
                            <ul className="list-none">
                              <li>
                                <span className="text-spenda-labeltext">
                                  {values?.BillStreetAddress || values.BillingAddress?.StreetAddress}
                                </span>
                              </li>
                              <li>
                                <span className="text-spenda-labeltext">{values.BillingAddress?.City}</span>
                              </li>
                              <li>
                                <span className="text-spenda-labeltext">{values.BillingAddress?.State}</span>
                              </li>
                              <li>
                                <span className="text-spenda-labeltext">{values.BillingAddress?.Country}</span>
                              </li>
                              <li>
                                <span className="text-spenda-labeltext">{values.BillingAddress?.PostCode}</span>
                              </li>
                            </ul>
                          </td>
                          <div className="absolute cursor-pointer" onClick={() => {}}>
                            {!values?.Status ? (
                              !values.BillingAddress?.City &&
                              !values.BillingAddress?.Country &&
                              !values.BillingAddress?.StreetAddress ? (
                                <AddIcon
                                  className="text-primary"
                                  onClick={() => {
                                    setAddressType('BillingAddress');
                                    hendleDialog();
                                  }}
                                />
                              ) : (
                                <EditOutlinedIcon
                                  fontSize="inherit"
                                  width="14px"
                                  height="14px"
                                  className="text-primary"
                                  onClick={() => {
                                    setAddressType('BillingAddress');
                                    hendleDialog();
                                  }}
                                />
                              )
                            ) : null}
                          </div>
                        </tr>
                      </tbody>
                      {props?.errors?.BillingAddress && (
                        <div className="w-max text-sm text-error">
                          <p>Billing Address is required</p>
                        </div>
                      )}
                    </table>
                    {locationDialog && addressType == 'BillingAddress' && (
                      <SelectShippingAddressDialog
                        {...props}
                        addressType={addressType}
                        values={values}
                        open={locationDialog}
                        onClose={hendleDialog}
                        customerDetails={customerDetails}
                        handleUpdateOpCustomer={props.handleUpdateOpCustomer}
                        handleSetAddress={props.handleSetAddress}
                      ></SelectShippingAddressDialog>
                    )}
                  </Box>
                </Box>
              ),
            },
          ]}
        />
      </Box>

      <Box flex="2 1 auto" className="pl-5"></Box>
    </Box>
  );
};

export interface IBottomSheet {
  isOpen: boolean;
  children: React.ReactNode;
}

function BottomSheet({isOpen, children}: IBottomSheet) {
  const prevIsOpen = usePrevious(isOpen);
  const controls = useAnimation();

  useEffect(() => {
    if (prevIsOpen && !isOpen) {
      controls.start('hidden');
    } else if (!prevIsOpen && isOpen) {
      controls.start('visible');
    }
  }, [controls, isOpen, prevIsOpen]);

  return (
    <motion.div
      initial="hidden"
      animate={controls}
      transition={{
        type: 'spring',
        damping: 40,
        stiffness: 400,
      }}
      variants={{
        visible: {y: 0, display: 'block'},
        hidden: {y: '100%', display: 'none'},
      }}
    >
      {children}
    </motion.div>
  );
}

function usePrevious(value: any) {
  const previousValueRef = useRef();

  useEffect(() => {
    previousValueRef.current = value;
  }, [value]);

  return previousValueRef.current;
}

export const SalesOrderBottomBarSlide: FunctionComponent<any> = props => {
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [defaultOpenTab, setDefaultOpenTab] = useState<number>(0);

  const toggleFilter = (defaultOpen: number) => {
    setDefaultOpenTab(defaultOpen);
    setShowFilter(!showFilter);
  };

  return (
    <Box
      height={showFilter ? '280px' : '46px'}
      bgcolor="#F9F9F9"
      border="1px solid #EAEAEA"
      boxShadow="0 2px 20px 0 #00000020"
      borderRadius="0 0 8px 8px"
      width="calc(100% + 40px)"
      bottom="-20px"
      left="-20px"
      className="absolute"
    >
      <Box
        className="absolute flex cursor-pointer"
        marginLeft="50%"
        top="-20px"
        height="20px"
        width="90px"
        bgcolor="hsl(var(--primary-header))"
        boxShadow="0 -1px 2px 0 #00000010"
        borderRadius="6px 6px 0 0"
        onClick={() => toggleFilter(0)}
      >
        <KeyboardArrowDownRoundedIcon fontSize="small" className="mx-auto text-primary" />
      </Box>
      {!showFilter ? (
        <Box className="absolute flex cursor-pointer" left={0} marginLeft="10px">
          <span
            onClick={() => toggleFilter(0)}
            className={`mx-2 mt-1 rounded-t-md border border-b-0 border-primary px-3 py-2 font-medium text-spenda-primarytext`}
          >
            Address
          </span>
        </Box>
      ) : null}
      <BottomSheet isOpen={showFilter}>
        <SalesOrderBottomBar {...props} defaultOpenTab={defaultOpenTab} />
      </BottomSheet>
    </Box>
  );
};

export const SalesOrderBottomBarPackaging: FunctionComponent<{
  conNoteStatus: string;
  consignmentNoteID: string;
  consignmentNotePackages: IConsignmentNotePackage[];
  packageSelected: IConsignmentNotePackage | undefined;
  setPackageSelected: (arg0: IConsignmentNotePackage) => void;
  refetchConsignmentNote: () => void;
  checkReadyToShip: (packages: IConsignmentNotePackage[]) => void;
}> = props => {
  const classes = useOrderBottomBarStyles();
  const {
    consignmentNoteID,
    consignmentNotePackages,
    packageSelected,
    conNoteStatus,
    setPackageSelected,
    refetchConsignmentNote,
    checkReadyToShip,
  } = props;

  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [tableData, setTableData] = useState<IConsignmentNotePackageLine[]>([]);

  const {updateConsignmentNote} = useConsignmentNoteAPI();

  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  useEffect(() => {
    if (consignmentNotePackages?.length > 0) {
      setPackageSelected(consignmentNotePackages?.[consignmentNotePackages.length - 1]);
      setShowFilter(true);
    }
  }, [consignmentNotePackages]);

  useEffect(() => {
    if (packageSelected) {
      setTableData(packageSelected.Lines);
    }
  }, [packageSelected]);

  const handleDeleteConNoteLine = async (id: number) => {
    const packageIndex = consignmentNotePackages.findIndex(
      (_package: IConsignmentNotePackage) => _package.ID === packageSelected?.ID,
    );
    const packages = [...consignmentNotePackages];
    const lineIndex = packages[packageIndex].Lines.findIndex(
      (_line: IConsignmentNotePackageLine) => _line.InventoryID === id,
    );
    if (lineIndex > -1) {
      packages[packageIndex].Lines[lineIndex].IsActive = false;
    }
    const conDeleteRes = await updateConsignmentNote({
      ID: consignmentNoteID,
      IsActive: true,
      Status: CONSIGNMENT_NOTE_STATUS.OPEN,
      Packages: packages,
    });
    if (conDeleteRes.IsSuccess) {
      refetchConsignmentNote();
    }
  };

  const handleDeleteConNotePackage = async () => {
    const packageIndex = consignmentNotePackages.findIndex(
      (_package: IConsignmentNotePackage) => _package.ID === packageSelected?.ID,
    );
    const packages = [...consignmentNotePackages];
    packages[packageIndex].IsActive = false;
    const conDeleteRes = await updateConsignmentNote({
      ID: consignmentNoteID,
      IsActive: true,
      Status: CONSIGNMENT_NOTE_STATUS.OPEN,
      Packages: packages,
    });
    if (conDeleteRes.IsSuccess) {
      if (!packages[packageIndex].Lines.length) {
        checkReadyToShip(packages.filter(_pack => _pack.ID !== packages[packageIndex].ID));
      }
      refetchConsignmentNote();
    }
  };

  const renderBoxes = () => (
    <Box
      bgcolor="#FAFAFA"
      className="flex cursor-pointer"
      paddingBottom="0px"
      paddingLeft="10px"
      marginTop="0px"
      display="flex"
      flexDirection="column"
    >
      <div className="packItemOverflowX ml-2 flex overflow-x-auto pb-2">
        {consignmentNotePackages.map(pack => (
          <span
            key={pack.ID}
            onClick={() => {
              setPackageSelected(pack);
            }}
            className={`${
              packageSelected?.ID === pack.ID ? classes.bottomBoxActive : classes.bottomBox
            } mr-1 px-6 py-2 font-medium`}
          >
            {pack.PackageTypeName}
          </span>
        ))}
      </div>
    </Box>
  );

  return (
    <Box
      className="relative -mx-5 -mb-4"
      height={showFilter ? '280px' : '70px'}
      bgcolor="#fff"
      border="1px solid #EAEAEA"
      boxShadow="0 2px 20px 0 #00000020"
      borderRadius="0 0 8px 8px"
      position="absolute"
      bottom="16px"
      width="100%"
    >
      <Box
        className="absolute flex cursor-pointer"
        marginLeft="50%"
        top="-20px"
        height="20px"
        width="90px"
        bgcolor="#EAEFF2"
        boxShadow="0 -1px 2px 0 #00000010"
        borderRadius="6px 6px 0 0"
        onClick={toggleFilter}
      >
        <KeyboardArrowDownRoundedIcon fontSize="small" className="mx-auto text-primary" />
      </Box>
      {!showFilter ? renderBoxes() : null}
      <BottomSheet isOpen={showFilter}>
        <Box className="flex flex-col" left={0}>
          {renderBoxes()}
          {packageSelected?.ID ? (
            <>
              <div className={`${classes.bottomBarActionBtns} flex flex-row justify-between px-5 pb-5 pt-3`}>
                <div className="mt-5 flex justify-between">
                  <div className={`${classes.bottomInput} relative mr-1 w-[194px]`}>
                    <input
                      disabled
                      className="mb-0 border-0 p-1 text-base"
                      placeholder={packageSelected?.PackageTypeName || ''}
                      value={packageSelected?.PackageTypeName || ''}
                    />
                    <span className="absolute left-0 top-0 -mt-5 h-full text-xs font-medium text-spenda-labeltext">
                      Shipping Box Name
                    </span>
                  </div>
                  <div className={`${classes.bottomInput} relative mx-1 w-[160px]`}>
                    <input
                      disabled
                      className="mb-0 border-0 p-1 text-base"
                      value={
                        (packageSelected?.TotalLength_m || 0) *
                        (packageSelected?.TotalHeight_m || 0) *
                        (packageSelected?.TotalWidth_m || 0)
                      }
                    />
                    <span className="absolute right-0 top-0 h-full px-3 py-1 font-medium">
                      m<sup>3</sup>
                    </span>
                    <span className="absolute left-0 top-0 -mt-5 h-full text-xs font-medium text-spenda-labeltext">
                      Volume
                    </span>
                  </div>
                  <div className={`${classes.bottomInput} relative mx-1 w-[56px]`}>
                    <input
                      disabled
                      className="mb-0 border-0 p-1 text-base"
                      value={
                        Array.isArray(tableData)
                          ? tableData?.reduce((acc: number, line: any) => {
                              return acc + parseFloat(line.Weight_kg || 0) * parseFloat(line.PackedQuantity || 0);
                            }, packageSelected.TotalWeight_kg || 0)
                          : ''
                      }
                    />
                    <span className="absolute right-0 top-0 h-full px-3 py-1 font-medium">kg</span>
                    <span className="absolute left-0 top-0 -mt-5 h-full text-xs font-medium text-spenda-labeltext">
                      Weight
                    </span>
                  </div>
                </div>
                <div className="mt-1 flex items-center">
                  <span className={`text-base text-spenda-labeltext`}>Package ID-</span>
                  <span className={`mr-2 text-base text-spenda-primarytext`}>{packageSelected?.ID}</span>
                  {conNoteStatus === CONSIGNMENT_NOTE_STATUS.OPEN ? (
                    <Button
                      variant="outlined"
                      onClick={() => {
                        handleDeleteConNotePackage();
                      }}
                    >
                      Delete
                    </Button>
                  ) : null}
                </div>
              </div>
              <Box className={`${classes.bottomTableBar}`}>
                <STableCustom
                  columns={[
                    {
                      title: 'Code',
                      field: 'Code',
                      render: (rowData: any) => (
                        <span className="text-base text-spenda-primarytext">{rowData.Code}</span>
                      ),
                    },
                    {
                      title: 'Items',
                      field: 'ShortDescription',
                      render: (rowData: any) => (
                        <>
                          <b>{rowData.Code}</b> {rowData.ShortDescription}
                        </>
                      ),
                    },
                    {
                      title: 'Dimensions & Weight',
                      field: 'DimensionAndWeight',
                      align: 'right',
                      render: (rowData: any) => (
                        <span className="text-base text-spenda-primarytext">
                          {`${PriceCalculator.roundNumber(rowData.Depth_m)}m x ${PriceCalculator.roundNumber(
                            rowData.Depth_m,
                          )}m x ${PriceCalculator.roundNumber(rowData.Depth_m)}m`}{' '}
                          | {PriceCalculator.roundNumber(rowData.Weight_kg)}kg
                        </span>
                      ),
                    },
                    {
                      title: 'Order Qty',
                      field: 'QuantityOrdered',
                      align: 'right',
                    },
                    {
                      title: 'Pick Qty',
                      field: 'QuantityPicked',
                      align: 'right',
                    },
                    {
                      title: 'Pack Qty',
                      field: 'QuantityPacked',
                      align: 'right',
                    },
                  ]}
                  hideDeleteButton={conNoteStatus !== CONSIGNMENT_NOTE_STATUS.OPEN}
                  onDelete={handleDeleteConNoteLine}
                  data={tableData}
                  emptyDataMessage={'No Items in Package'}
                />
              </Box>
            </>
          ) : null}
        </Box>
      </BottomSheet>
    </Box>
  );
};
