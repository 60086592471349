import * as React from 'react';
const APOnbBusinessInfoIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg {...props}>
    <path
      fill="hsl(var(--primary))"
      fillRule="evenodd"
      d="M20 8.333a.827.827 0 0 0-.02-.18l-1.083-4.89A4.14 4.14 0 0 0 14.827 0H5.173a4.14 4.14 0 0 0-4.066 3.263L.02 8.153a.823.823 0 0 0-.02.18v.834c0 .811.296 1.595.833 2.202v4.464A4.172 4.172 0 0 0 5 20h10a4.172 4.172 0 0 0 4.167-4.167V11.37A3.32 3.32 0 0 0 20 9.167v-.834Zm-18.333.091 1.066-4.8a2.485 2.485 0 0 1 2.44-1.958h.66v2.5a.833.833 0 0 0 1.667 0v-2.5h5v2.5a.833.833 0 1 0 1.667 0v-2.5h.66a2.485 2.485 0 0 1 2.44 1.958l1.066 4.8v.742c0 .92-.746 1.667-1.666 1.667h-.834c-.92 0-1.666-.746-1.666-1.667a.833.833 0 0 0-1.667 0c0 .92-.746 1.667-1.667 1.667H9.167c-.92 0-1.667-.746-1.667-1.667a.833.833 0 0 0-1.667 0c0 .92-.746 1.667-1.666 1.667h-.834c-.92 0-1.666-.746-1.666-1.667v-.742ZM15 18.334H5a2.5 2.5 0 0 1-2.5-2.5v-3.44c.272.07.552.106.833.106h.834c.957 0 1.868-.412 2.5-1.131a3.325 3.325 0 0 0 2.5 1.13h1.666c.958.001 1.869-.41 2.5-1.13a3.325 3.325 0 0 0 2.5 1.13h.834c.28.001.56-.035.833-.105v3.44a2.5 2.5 0 0 1-2.5 2.5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default APOnbBusinessInfoIcon;
