import {useMediaQuery, useTheme} from '@material-ui/core';
import {css} from 'glamor';
import React, {useEffect} from 'react';
import {SButton} from '../../components/buttons/Button';
import {SavePDFButton} from '../../components/buttons/SavePDFButton';
import {PaymentStatusType} from '../../model/constants/Constants';
import {IInvoice} from '../../model/invoice/Invoice';
import {createPayButtonAutoIdAttribute} from '../../utils/createAutoIdAttribute';
import {PriceFormat} from '../../utils/formatter';
import {SplitPaymentButton} from '../../components/AccountsReceivable/SplitPaymentButton';

export type InvoiceFooterInvoiceData = Pick<IInvoice, 'GUID' | 'ID' | 'Balance' | 'Status' | 'AmountToPay'>;

export interface InvoiceFooterProps {
  hasAmountError?: boolean;
  invoiceData?: InvoiceFooterInvoiceData;
  isPaymentInProgress?: boolean;
  redirectUrl?: string;
  isAutoOpenWidget?: boolean;
  onSavePDFClick: () => void;
  onPayClick: () => void;
  onAmountChanged?: (updatedAmount?: number | undefined) => void;
  supplierId: number | undefined;
  invoiceLoading?: boolean;
}

export const InvoiceFooter: React.FunctionComponent<InvoiceFooterProps> = props => {
  const {
    hasAmountError,
    invoiceData,
    isAutoOpenWidget,
    isPaymentInProgress,
    redirectUrl,
    onSavePDFClick,
    onPayClick,
    onAmountChanged,
    invoiceLoading,
  } = props;
  const amountToPay = invoiceData?.AmountToPay || invoiceData?.Balance;

  const priceFormat = (amount?: number) => PriceFormat(amount, '');

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.between(320, 415));

  const isPaid =
    (invoiceData?.Balance || 0) <= 0 &&
    invoiceData?.Status.toLocaleLowerCase() === PaymentStatusType.Paid.toLocaleLowerCase();

  useEffect(() => {
    if (isAutoOpenWidget) {
      onPayClick();
    }
  }, [onPayClick, isAutoOpenWidget]);

  const mobileView = (
    <div className="flex flex-wrap items-center justify-between">
      <SavePDFButton
        lineHeight="m"
        fontSize="m"
        variant="contained"
        color="blue"
        onClick={onSavePDFClick}
      ></SavePDFButton>
      {redirectUrl ? (
        <a className="ml-2 text-lg font-semibold text-spenda-btnbg hover:underline" href={redirectUrl} target="_self">
          Back to Shop
        </a>
      ) : (
        ''
      )}
      {!isPaid && !isPaymentInProgress && (
        <>
          <SplitPaymentButton
            title={''}
            actualInvoiceBalance={invoiceData?.Balance || 0}
            disabled={hasAmountError}
            onAmountChanged={onAmountChanged}
          />
          <SButton
            {...createPayButtonAutoIdAttribute()}
            height="100%"
            lineHeight="m"
            fontSize="m"
            variant="contained"
            color="green"
            onClick={onPayClick}
            disabled={hasAmountError}
          >
            Pay ${priceFormat(amountToPay)}
          </SButton>
        </>
      )}
    </div>
  );

  const desktopView = (
    <div className="flex justify-between">
      <div className="flex items-center">
        <SavePDFButton
          height="100%"
          lineHeight="m"
          fontSize="m"
          variant="contained"
          color="blue"
          onClick={onSavePDFClick}
        />
        {redirectUrl && (
          <a className="ml-2 text-lg font-semibold text-spenda-btnbg hover:underline" href={redirectUrl} target="_self">
            Back to Shop
          </a>
        )}
      </div>
      {!isPaid && !isPaymentInProgress && (
        <>
          <div className={`flex justify-between ${matches && css({width: '63%'})}`}>
            <SplitPaymentButton
              title={''}
              actualInvoiceBalance={invoiceData?.Balance || 0}
              disabled={hasAmountError}
              onAmountChanged={onAmountChanged}
            />
            <div className="ml-1">
              <SButton
                {...createPayButtonAutoIdAttribute()}
                height="100%"
                lineHeight="m"
                fontSize="m"
                variant="contained"
                color="green"
                onClick={onPayClick}
                disabled={hasAmountError || invoiceLoading}
                isSubmitting={invoiceLoading}
              >
                Pay ${priceFormat(amountToPay)}
              </SButton>
            </div>
          </div>
        </>
      )}
    </div>
  );

  return matches ? mobileView : desktopView;
};
