import React from 'react';

import {SModal} from '../modals/modalSpendaMeterialUI';
import {SButton} from '../buttons/Button';
import {Box, makeStyles} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useMIDialogStyles = makeStyles({
  containerModal: {
    maxWidth: '460px',
    height: '340px',
  },
  header: {
    borderBottom: '1px solid #CCCCCC',
    '& h2': {
      fontFamily: 'Poppins, sans-serif !important',
      fontWeight: 300,
      fontSize: '22px',
      color: '#333333',
    },
  },
  boxHeight: {
    maxWidth: '372px',
    padding: '83px 0',
    '& p': {
      color: '#333333',
      fontSize: '14px',
      fontWeight: 400,
    },
  },
  footer: {
    maxWidth: '440px',
    background: '#ECECEC',
    borderRadius: '6px',
    left: '10px',
    justifyContent: 'space-between',
  },
});
export interface IManageInvoicesDialogProps {
  isLoading: boolean;
  handleClose: () => void;
  handleImportInvoices: () => void;
}

export const ManageInvoicesDialog = (props: IManageInvoicesDialogProps) => {
  const classes = useMIDialogStyles();

  return (
    <SModal open>
      <div className={`text-xl spenda-color flex justify-center items-center h-full max-w-full`}>
        <div className={`modal-box ${classes.containerModal} px-5 pt-3 pb-5`}>
          <Box className={`${classes.header} flex justify-between -mx-5 px-3 pb-3`}>
            <h2 className={`text-center w-full self-center`}>Manage supplier’s invoices</h2>
            <Box className="text-right flex justify-end">
              <span className="ap-onboarding-close-icon">
                <CloseIcon onClick={props.handleClose} data-autoid="btnClose" />
              </span>
            </Box>
          </Box>
          <div className={`${classes.boxHeight} relative py-8 w-full mx-auto`}>
            <Box className="flex flex-col justify-center items-center text-center">
              <p className="font-poppins">
                Do you want to use AP(Accounts Payable) module to manage this supplier’s invoices?
              </p>
            </Box>
          </div>
          <Box
            className={`${classes.footer} w-full flex flex-row justify-center items-center absolute bottom-3 py-2 pl-2`}
          >
            <SButton
              borderRadius="6px"
              margin="0 10px 0 0"
              textColor="#1C78AD"
              lineHeight="l"
              height="40px"
              boxShadow="none"
              fontWeight={600}
              fontFamily="poppins"
              fontSize="14px"
              border="1px solid #1C78AD"
              width="60px"
              backgroundColor="white"
              data-autoid="btnNo"
              onClick={props.handleClose}
            >
              No
            </SButton>
            <SButton
              borderRadius="6px"
              margin="0 10px 0 0"
              textColor="white"
              lineHeight="l"
              height="40px"
              boxShadow="none"
              fontWeight={600}
              fontFamily="poppins"
              fontSize="14px"
              border="1px solid #1C78AD"
              width="183px"
              backgroundColor="#1C78AD"
              data-autoid="btnImportInvoices"
              onClick={props.handleImportInvoices}
            >
              Yes, Import Invoices
            </SButton>
          </Box>
        </div>
      </div>
    </SModal>
  );
};
