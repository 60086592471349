import * as React from 'react';

export const PencilIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={props?.width || '13px'} height={props?.height || '13px'} fill={props?.fill || '#D8D8D8'} {...props}>
    <path
      d="M8.134 2 .76 9.278l-.721 3.064c-.052.335-.052.537 0 .606.05.07.201.07.45 0l3.11-.83L11 4.811 8.134 2ZM9 1.323 11.627 4l1.084-1.138a2.5 2.5 0 0 0 .243-.422c.057-.152.045-.297.045-.392a1.3 1.3 0 0 0-.045-.33c-.023-.087-.104-.22-.243-.395L11.627.288a1.203 1.203 0 0 0-.33-.218 1.392 1.392 0 0 0-.408-.07c-.11 0-.322.002-.493.07a1.29 1.29 0 0 0-.319.218L9 1.323Z"
      fillRule="evenodd"
    />
  </svg>
);
