import React from 'react';
import {SVGProps} from 'react';

export const ArrowBackIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width={'22px'} height={'22px'} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g className="fill-primary">
        <path
          style={{
            stroke: 'none',
            fillRule: 'evenodd',
            fillOpacity: 1,
          }}
          d="M16.5143 10.0841H7.34766L10.3635 7.06823C10.537 6.89611 10.6346 6.66181 10.6346 6.41739C10.6346 6.17298 10.537 5.93868 10.3635 5.76656C10.006 5.41115 9.42852 5.41115 9.07099 5.76656L5.13849 9.70823C4.7942 10.0505 4.5997 10.5153 4.59766 11.0007C4.60212 11.483 4.79643 11.9441 5.13849 12.2841L9.07099 16.2257C9.30351 16.4566 9.64154 16.5459 9.95775 16.46C10.274 16.3741 10.5203 16.126 10.604 15.8091C10.6877 15.4923 10.596 15.1549 10.3635 14.9241L7.34766 11.9174H16.5143C17.0206 11.9174 17.431 11.507 17.431 11.0007C17.431 10.4945 17.0206 10.0841 16.5143 10.0841Z"
        />
      </g>
    </svg>
  );
};
