import {FormControl, FormControlLabel, FormLabel, makeStyles, Radio, RadioGroup} from '@material-ui/core';
import React, {useContext, useEffect, useState} from 'react';
import {useParams, useHistory} from 'react-router-dom';
import {DestructiveButton, PrimaryButton, SecondaryButton} from '../../../components/buttons/DefaultButtons';
import {SCheckbox} from '../../../components/inputs/SCheckbox';
import {STextField} from '../../../components/inputs/STextField';
import {usePaymentUtilities} from '../../../hooks/usePaymentUtilities';
import {AuthorisationRequestMode, AuthorisationStatus, PaymentWidgetScope} from '../../../model/constants/Constants';
import {
  AuthorisationRulesResponse,
  Authorisers,
  PaymentAuthorisationDetails,
} from '../../../model/payment/PaymentAuthority';
import {Toast} from '../../../utils/Toast';
import {PaymentWidgetContext, PaymentWidgetPaymentSteps, PaymentWidgetStep} from './PaymentWidget';
import {EnterSecurityCode} from './pw-components/EnterSecurityCode';
import PaymentDeclined from '../../../assets/svg/PaymentDeclined.svg';
import PaymentSuccessful from '../../../assets/svg/PaymentSuccessful.svg';
import {ZohoContext} from '../../../context/zoho-context/ZohoContext';

enum AuthorisePaymentRulesStep {
  CHANGE_REQUEST,
  REVIEW_CHANGE_REQUEST,
  ENTER_OTP,
  REJECT_CHANGE_REQUEST,
  APPROVE_CHANGE_REQUEST,
}

const useStyles = makeStyles({
  radioButton: {
    '& .MuiFormGroup-row': {
      flexWrap: 'nowrap',
    },
    '& .MuiRadio-colorPrimary.Mui-checked': {
      color: '#1C78AD',
    },
  },
  label: {
    color: '#000 !important',
    fontFamily: 'poppins',
    fontSize: '12px',
    justifyContent: 'center',
    '& .MuiTypography-body1': {
      fontSize: '12px',
      fontFamily: 'poppins',
    },
  },
  label2: {
    color: '#000 !important',
    fontFamily: 'poppins',
    fontSize: '12px',
    height: '30px',
    paddingTop: '20px',
    '& .MuiTypography-body1': {
      fontSize: '12px',
      fontFamily: 'poppins',
    },
  },
  icon: {
    borderRadius: 6,
    width: 24,
    height: 24,
    margin: '2px',
    border: '1.5px solid #1C78AD',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedicon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#1C78AD',
    height: 24,
    width: 24,
    borderRadius: '6px',
    paddingBottom: '3px',
    marginRight: '4px',
    '& .check': {
      display: 'inline-block',
      transform: 'rotate(45deg)',
      height: 16,
      width: 8,
      borderBottom: '3px solid #FFF',
      borderRight: '3px solid #FFF',
    },
  },
  rejectReasonBody: {
    paddingBottom: '0px!important',
    '& .MuiOutlinedInput-multiline': {
      backgroundColor: '#ffffff!important',
    },
    position: 'relative',
    '& .MuiOutlinedInput-input:-webkit-autofill': {
      '-webkit-box-shadow': '0 0 0 100px #fff inset !important',
    },
    '& .MuiInputAdornment-root': {
      display: 'none',
    },
    '& .MuiOutlinedInput-root': {
      padding: '10px 10px',
      height: '100%',
      '&.Mui-focused': {
        '& .MuiInputAdornment-root': {
          display: 'contents',
        },
      },
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 16px) scale(1)',
      fontFamily: 'Poppins !important',
      fontSize: '14px',
      fontWeight: 500,
      width: 'auto',
      color: '#333 !important',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -4.7px) scale(1)',
      fontSize: '11px ',
    },
    '& textarea, input': {
      padding: '0px',
      fontFamily: 'Poppins !important',
      fontSize: '14px',
    },
    '& .MuiFormHelperText-root.Mui-error': {
      fontFamily: 'Poppins !important',
      position: 'relative !important',
      top: '0px !important',
    },
    '& .MuiInputAdornment-positionEnd button': {
      padding: '0px !important',
    },

    '& .MuiOutlinedInput-input': {
      height: '132px',
    },
    '& fieldset legend': {fontSize: '16px !important'},
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#333!important',
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#1C78AD!important',
    },
  },
});

export const PaymentWidgetStepApprovePaymentAuthorisationRules = () => {
  const {code: authorisationID} = useParams<any>();
  const {
    getPaymentMethodAuthorisationRules,
    userID,
    approvePaymentAuthorisation,
    resetAuthenticationCode,
    setPaymentAuthorisationDetails,
    paymentAuthorisationDetails,
    rejectPaymentAuthorisation,
    paymentAuthRules,
    setPaymentAuthorisationRules,
    setStep: setPWStep,
    widgetScope,
    getPaymentMethodAuthorisationDetails,
    onCancel,
  } = useContext(PaymentWidgetContext);
  const zohoChatContext = useContext(ZohoContext)!;
  const {showChatWindow} = zohoChatContext;
  const {transformPaymentAuthorisationUserInfo, getAuthenticationCodeErrorMsg} = usePaymentUtilities();
  const [allUsers, setAllUsers] = useState<Authorisers[]>();
  const [securityCode, setSecurityCode] = useState<string>('');
  const [securityCodeErrorMsg, setSecuityCodeErrorMsg] = useState('');
  const [rejectionMsg, setRejectionMsg] = useState<string>('');

  const handleChangeSecurityCode = (code: any) => {
    if (securityCodeErrorMsg) setSecuityCodeErrorMsg('');
    return setSecurityCode(code);
  };

  const isUserAuthoriser = paymentAuthRules?.activeRules.authoriserUserIDs?.includes(userID as number);

  const zeroNewCode = paymentAuthorisationDetails?.newCodesRemainingCount === 0;
  const zeroCodeAttempts = paymentAuthorisationDetails?.attemptsRemainingCount === 0;
  const isAtemptsExhausted = zeroNewCode && zeroCodeAttempts;

  const classes = useStyles();
  const [step, setStep] = useState<AuthorisePaymentRulesStep | undefined>();
  const history = useHistory();
  const handleHome = () => {
    if (widgetScope === PaymentWidgetScope.AUHTORISE_PAYMENT) {
      history.push(`/menu`);
    } else if (widgetScope === PaymentWidgetScope.CONFIGURE_PAYMENT_OPTIONS) {
      onCancel && onCancel();
    } else {
      setPWStep && setPWStep(PaymentWidgetPaymentSteps.NEW);
    }
  };

  // We are using this authID instead of authorisationID when we edit payment menthod (as there are no url params)
  const authID = paymentAuthRules?.authorisation?.authorisationID as number;

  useEffect(() => {
    if ([AuthorisationStatus.Complete, AuthorisationStatus.Approved].includes(paymentAuthorisationDetails?.status!)) {
      setStep(AuthorisePaymentRulesStep.APPROVE_CHANGE_REQUEST);
    } else if ([AuthorisationStatus.Rejected].includes(paymentAuthorisationDetails?.status!)) {
      setStep(AuthorisePaymentRulesStep.REJECT_CHANGE_REQUEST);
    } else {
      setStep(AuthorisePaymentRulesStep.CHANGE_REQUEST);
    }
  }, []);

  useEffect(() => {
    getAuthorisationRulesDetails();
  }, [authorisationID]);

  useEffect(() => {
    if (widgetScope === PaymentWidgetScope.PIBL && authID) {
      getPaymentAuthDetails(authID);
    }
  }, [authID]);

  const getPaymentAuthDetails = async (id: number) => {
    const paymnetAuthdetails = await getPaymentMethodAuthorisationDetails?.(id);
    setPaymentAuthorisationDetails?.(paymnetAuthdetails?.authorisation as PaymentAuthorisationDetails);
  };

  const getUsersList = (rules: AuthorisationRulesResponse) => {
    const pendingUsers = rules?.pendingRules?.authoriserUserIDs?.map(a => rules?.users?.find(b => a === b.userID));
    const activeUsers = rules?.activeRules?.authoriserUserIDs?.map(a => rules?.users?.find(b => a === b.userID));
    const transformedPendingUsers = transformPaymentAuthorisationUserInfo(pendingUsers as Authorisers[], userID);
    const transformedActiveUsers = transformPaymentAuthorisationUserInfo(activeUsers as Authorisers[], userID);

    const listUsers = transformedActiveUsers?.reduce((acc, cur, i) => {
      if (!transformedPendingUsers?.some(a => a.userID === cur.userID)) {
        cur.strikedAuthorisers = true;
        acc?.splice(i, 0, cur);
      }
      return acc;
    }, transformedPendingUsers);
    setAllUsers(listUsers);
  };

  const getAuthorisationRulesDetails = async () => {
    if (authorisationID && getPaymentMethodAuthorisationRules) {
      const paymentGuid = paymentAuthorisationDetails?.data?.paymentAccountGUID;
      if (paymentGuid && !paymentAuthRules) {
        const rules = await getPaymentMethodAuthorisationRules(paymentGuid);
        getUsersList(rules);
        setPaymentAuthorisationRules?.(rules);
      }
    } else if (paymentAuthRules) {
      getUsersList(paymentAuthRules);
    }
  };

  const isRequiredSequenceSame =
    paymentAuthRules?.activeRules.authorisationRequestMode === paymentAuthRules?.pendingRules?.authorisationRequestMode;
  const isRequiredApprovalCountSame =
    paymentAuthRules?.activeRules.requiredApprovalCount === paymentAuthRules?.pendingRules?.requiredApprovalCount;
  const isRequiredAuthenticationSame =
    paymentAuthRules?.activeRules.isApprovalCodeRequired === paymentAuthRules?.pendingRules?.isApprovalCodeRequired;

  const handleAuthorisePayment = async () => {
    // if user already got security code and refreshed the screen:- no need to call the api again to generate security code
    if (paymentAuthRules?.authorisation.status === AuthorisationStatus.AwaitingCode) {
      setStep(AuthorisePaymentRulesStep.ENTER_OTP);
    } else if (approvePaymentAuthorisation) {
      const authorisePaymentResponse = await approvePaymentAuthorisation({approvalCode: null!}, authID);
      if (authorisePaymentResponse?.authorisation.status === AuthorisationStatus.AwaitingCode) {
        setStep(AuthorisePaymentRulesStep.ENTER_OTP);
      }
      if (
        authorisePaymentResponse?.authorisation.status === AuthorisationStatus.Complete ||
        authorisePaymentResponse?.authorisation.status === AuthorisationStatus.Approved
      ) {
        setStep(AuthorisePaymentRulesStep.APPROVE_CHANGE_REQUEST);
      }
      setPaymentAuthorisationDetails?.(authorisePaymentResponse.authorisation);
    }
  };

  const handleResetSecurityCode = async () => {
    if (authID && resetAuthenticationCode) {
      const resetAuthenticationCodeResponse = await resetAuthenticationCode(authID);
      Toast.info(`Successfully sent a new security code.`);
      setPaymentAuthorisationDetails?.(resetAuthenticationCodeResponse?.authorisation);
      handleChangeSecurityCode('');
    }
  };

  const verifySecurityCode = async () => {
    if (isAtemptsExhausted) {
      showChatWindow();
      return;
    }
    if (approvePaymentAuthorisation) {
      const authorisePaymentResponse = await approvePaymentAuthorisation({approvalCode: securityCode!}, authID);
      if (
        authorisePaymentResponse?.authorisation?.status === AuthorisationStatus.Complete ||
        authorisePaymentResponse?.authorisation?.status === AuthorisationStatus.Approved
      ) {
        setStep(AuthorisePaymentRulesStep.APPROVE_CHANGE_REQUEST);
      }
      setPaymentAuthorisationDetails?.(authorisePaymentResponse.authorisation);
      const errors = authorisePaymentResponse?.error?.errors;
      if (errors.length) {
        const erroMsg = getAuthenticationCodeErrorMsg(authorisePaymentResponse);
        setSecuityCodeErrorMsg(erroMsg);
      }
    }
  };

  const handleRejectAuthorisation = async () => {
    if (step === AuthorisePaymentRulesStep.REVIEW_CHANGE_REQUEST) {
      setStep(AuthorisePaymentRulesStep.REJECT_CHANGE_REQUEST);
    } else if (rejectPaymentAuthorisation && step === AuthorisePaymentRulesStep.REJECT_CHANGE_REQUEST) {
      const rejectAuthorisationResponse = await rejectPaymentAuthorisation({rejectionReason: rejectionMsg}, authID);
      setPaymentAuthorisationDetails?.(rejectAuthorisationResponse.authorisation);
    }
  };

  const rulesCahangeRequester = `${paymentAuthRules?.authorisation?.requestedByUser?.firstName} ${paymentAuthRules?.authorisation?.requestedByUser?.lastName}`;
  const body = (
    <div className="font-poppins">
      {step === AuthorisePaymentRulesStep.CHANGE_REQUEST && (
        <div className="font-poppins ">
          <h1 className="text-xxl mb-24 mt-12 flex justify-center font-light">Change request</h1>

          <p className="flex justify-center px-12 py-7 text-xs font-normal ">
            As a security measure we require secondary authorisation to any changes make to the authorisation and/or
            authenication settings.
          </p>
          <p className="mt-1 flex justify-center px-12 text-xs font-normal">
            {rulesCahangeRequester}
            <br /> has made changes, please review and either authorise or reject.
          </p>
        </div>
      )}
      {step === AuthorisePaymentRulesStep.REVIEW_CHANGE_REQUEST && (
        <div className="ml-5 mr-4 mt-10 font-poppins ">
          <div className={isRequiredApprovalCountSame ? 'opacity-50' : ''}>
            <FormControl component="fieldset" className={classes.radioButton}>
              <FormLabel className={classes.label}>How many people need to authorise?</FormLabel>
              <RadioGroup
                row
                aria-label="position"
                name="position"
                defaultValue="top"
                value={paymentAuthRules?.pendingRules?.requiredApprovalCount}
              >
                <FormControlLabel
                  className={classes.label}
                  value={1}
                  control={<Radio color="primary" />}
                  label="One"
                  labelPlacement="end"
                />
                <FormControlLabel
                  className={classes.label}
                  value={2}
                  control={<Radio color="primary" />}
                  label="Two"
                  labelPlacement="end"
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div className={`${isRequiredSequenceSame ? 'pb-10 pt-8 opacity-50' : 'py-12'}`}>
            <FormControl component="fieldset" className={classes.radioButton}>
              <FormLabel className={classes.label}>What is the order of the authorisation process?</FormLabel>
              <RadioGroup
                aria-label="position"
                name="position"
                defaultValue="top"
                value={paymentAuthRules?.pendingRules?.authorisationRequestMode}
              >
                <FormControlLabel
                  className={classes.label2}
                  value={AuthorisationRequestMode.SEQUENTIAL}
                  control={<Radio color="primary" />}
                  label="Authorisation must be sequential"
                  labelPlacement="end"
                />
                <FormControlLabel
                  className={classes.label2}
                  value={AuthorisationRequestMode.PARALLEL}
                  control={<Radio color="primary" />}
                  label="Authorisation can happen in any order"
                  labelPlacement="end"
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div className={`h-52 overflow-hidden`}>
            <p className="text-start text-xs">People who are allowed to authorise:</p>
            {allUsers?.map((a, i) => (
              <p key={i} className="px-3 pt-3 text-start text-xs">
                {i + 1}. <span className={`ml-1 ${a.strikedAuthorisers && 'line-through'}`}>{a.name}</span>
              </p>
            ))}
          </div>
          <div className={`${isRequiredAuthenticationSame ? 'px-2 pt-5 opacity-50' : 'pt-10'}`}>
            <SCheckbox
              label="Yes please, enable authentication on this payment option"
              checked={paymentAuthRules?.pendingRules?.isApprovalCodeRequired}
              dat-autoid="chkAuthenticationSettings"
              className="text-spenda-primarytext"
              fontFamily="Poppins"
              lableFontSize="0.75rem"
              lableFontWeight={500}
              labelAlign="start"
              icon={<span className={classes.icon} />}
              checkedIcon={
                <span className={classes.checkedicon}>
                  <span className="check" />
                </span>
              }
            />
          </div>
        </div>
      )}
      {step === AuthorisePaymentRulesStep.ENTER_OTP && (
        <EnterSecurityCode
          securityCode={securityCode}
          setSecurityCode={code => handleChangeSecurityCode(code)}
          errorMsg={securityCodeErrorMsg}
          handleResetSecurityCode={handleResetSecurityCode}
          paymentAuthorisationDetails={paymentAuthorisationDetails!}
          onEnterVerificationCode={verifySecurityCode}
        />
      )}
      {step === AuthorisePaymentRulesStep.REJECT_CHANGE_REQUEST && (
        <RejectChangeRequest
          rulesCahangeRequester={rulesCahangeRequester}
          rejectionMsg={rejectionMsg}
          setRejectionMsg={setRejectionMsg}
          paymentAuthorisationDetails={paymentAuthorisationDetails!}
        />
      )}
      {step === AuthorisePaymentRulesStep.APPROVE_CHANGE_REQUEST && (
        <RulesChangeRequestApproved paymentAuthorisationDetails={paymentAuthorisationDetails!} />
      )}
    </div>
  );

  const footer = (
    <>
      {step === AuthorisePaymentRulesStep.CHANGE_REQUEST && (
        <>
          <SecondaryButton label="Cancel" onClick={handleHome} width="100px" />
          <PrimaryButton
            label="Review"
            width="100px"
            onClick={() => setStep(AuthorisePaymentRulesStep.REVIEW_CHANGE_REQUEST)}
          />
        </>
      )}
      {step === AuthorisePaymentRulesStep.REVIEW_CHANGE_REQUEST &&
        (isUserAuthoriser &&
        paymentAuthorisationDetails?.status !== AuthorisationStatus.Approved &&
        paymentAuthRules?.authorisation?.status !== AuthorisationStatus.Approved ? (
          <>
            <DestructiveButton label="Reject" width="90px" onClick={handleRejectAuthorisation} />
            <div className="mx-1">
              <SecondaryButton label="Close" onClick={handleHome} width="90px" />
            </div>
            <PrimaryButton label="Authorise" width="90px" onClick={handleAuthorisePayment} />
          </>
        ) : (
          <div className="w-full justify-center">
            <SecondaryButton label="Close" onClick={handleHome} width="90px" />
          </div>
        ))}
      {step === AuthorisePaymentRulesStep.ENTER_OTP && (
        <>
          <SecondaryButton label="Cancel" onClick={() => setStep(AuthorisePaymentRulesStep.REVIEW_CHANGE_REQUEST)} />
          <PrimaryButton
            label={isAtemptsExhausted ? 'Support' : 'Verify'}
            onClick={verifySecurityCode}
            disabled={securityCode.trim().length < 6 || (!zeroNewCode && zeroCodeAttempts)}
          />
        </>
      )}
      {step === AuthorisePaymentRulesStep.REJECT_CHANGE_REQUEST &&
        paymentAuthorisationDetails?.status !== AuthorisationStatus.Rejected && (
          <>
            <SecondaryButton label="Cancel" onClick={() => setStep(AuthorisePaymentRulesStep.REVIEW_CHANGE_REQUEST)} />
            <PrimaryButton label="Reject" onClick={handleRejectAuthorisation} />
          </>
        )}
      {((paymentAuthorisationDetails?.status === AuthorisationStatus.Rejected &&
        step === AuthorisePaymentRulesStep.REJECT_CHANGE_REQUEST) ||
        ((paymentAuthorisationDetails?.status === AuthorisationStatus.Approved ||
          paymentAuthorisationDetails?.status === AuthorisationStatus.Complete) &&
          step === AuthorisePaymentRulesStep.APPROVE_CHANGE_REQUEST)) && (
        <div className="w-full">
          <PrimaryButton label="Done" onClick={handleHome} />
        </div>
      )}
    </>
  );

  return <PaymentWidgetStep body={body} footer={footer} />;
};

const RulesChangeRequestApproved = (props: {paymentAuthorisationDetails: PaymentAuthorisationDetails}) => {
  const {paymentAuthorisationDetails} = props;
  const status = paymentAuthorisationDetails?.status;
  const users = paymentAuthorisationDetails?.authorisers.filter(a => a.status === AuthorisationStatus.InProgress);
  return (
    <>
      <div className="mb-8 mt-12 flex justify-center p-2">
        <img alt="PaymentSuccessful" style={{width: '83px', height: '86px'}} src={PaymentSuccessful} />
      </div>

      <h1 className="text-xxl px-5 text-primary">Authorised change request</h1>
      <p className="my-1 flex h-32 flex-col justify-center px-8 text-xs ">
        Your change request has been successfully processed.{' '}
        {status === AuthorisationStatus.Approved ? (
          <>
            Waiting for:
            {users?.map((a, i) => (
              <p className="font-bold" key={i}>
                {`${a.firstName} ${a.lastName}`}
              </p>
            ))}
            for final authorisation.
          </>
        ) : null}
      </p>
    </>
  );
};

const RejectChangeRequest = (props: {
  rulesCahangeRequester: string;
  rejectionMsg: string;
  setRejectionMsg: (rejectionMsg: string) => void;
  paymentAuthorisationDetails?: PaymentAuthorisationDetails;
}) => {
  const {rulesCahangeRequester, rejectionMsg, setRejectionMsg, paymentAuthorisationDetails} = props;
  const classes = useStyles();
  const status = paymentAuthorisationDetails?.status;
  const rejectionReason = paymentAuthorisationDetails?.authorisers?.find(a => a.rejectionReason)?.rejectionReason;
  return (
    <>
      <div className="mb-7 mt-16 flex justify-center p-1">
        <img alt="FailedPayment" style={{width: '100px', height: '75px'}} src={PaymentDeclined} />
      </div>

      <h1 className="text-xxl px-5 text-spenda-error">
        {status === AuthorisationStatus.InProgress ? `Reject` : `Rejected`} change request
      </h1>
      {status !== AuthorisationStatus.Rejected ? (
        <>
          <div className="center flex h-32 items-center justify-center px-6 text-xs">
            <p>
              This message will be sent to:
              <br />
              {rulesCahangeRequester}
              <br />
            </p>
          </div>
          <div className="mx-8">
            <STextField
              fullWidth
              id="rejectionReason"
              name="rejectionReason"
              variant="outlined"
              label="Why is the request rejected?"
              rows={10}
              multiline
              className={`${classes.rejectReasonBody}`}
              data-autoid="txtRejectionReason"
              inputProps={{style: {fontFamily: 'Poppins'}}}
              autoComplete="off"
              value={rejectionMsg}
              onChange={e => setRejectionMsg(e.target.value)}
              onBlur={() => {}}
              helperText={''}
              error={Boolean(false)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        </>
      ) : (
        <>
          <div className="center flex h-32 flex-col items-center justify-center px-6 text-xs">
            <p className="pb-2">Your rules change request has been rejected</p>

            {rejectionReason ? <p>"{rejectionReason}"</p> : null}
          </div>
        </>
      )}
    </>
  );
};
