import React, {FunctionComponent, useEffect, useState} from 'react';
import {css} from 'glamor';

import {IConsignmentNotePackage, IConsignmentNote} from '../../model/sales-order/ConsignmentNote';
import {IPackageType} from '../../model/sales-order/PackageType';
import {SButton} from '../buttons/Button';
import {CustomStepper} from '../Stepper/Stepper';
import {STableCustom} from '../tables/STableCustom';
import {FilterMenu, FilterMenuItem} from '../menu/FilterMenu';
import {usePackageTypeAPI} from '../../services/usePackageTypeAPI';
import PriceCalculator from '../../utils/priceCalculator';

import {usePopupState, bindTrigger, bindMenu} from 'material-ui-popup-state/hooks';
import {Box, makeStyles} from '@material-ui/core';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import {CONSIGNMENT_NOTE_STATUS} from '../../model/constants/Constants';

const useOrderDetailsStyles = makeStyles(() => ({
  boxActive: {
    backgroundColor: '#1C78AD',
    color: '#FFFFFF',
    borderRadius: '8px',
    padding: '10px 16px',
  },
  box: {
    backgroundColor: '#EAEAEA',
    color: '#444343',
    borderRadius: '8px',
    padding: '10px 16px',
  },
  trackingColor: {
    color: '#666666',
    marginRight: '20px',
  },
  fontWeight: {
    fontWeight: 500,
  },
  packageTypeBtnText: {
    width: 'calc(100% - 98px)',
    textAlign: 'left',
    '& span': {
      display: 'block',
      fontSize: '15px',
    },
    '& svg': {
      float: 'right',
    },
  },
  awaitingTable: {
    maxHeight: '200px',
    overflowY: 'auto',
  },
  filterMenu: {
    width: '158px;',
    display: 'block',
    ' & svg': {
      float: 'right',
    },
  },
}));

export const SalesOrderShipItems: FunctionComponent<{
  consignmentNote: IConsignmentNote;
  trackingNumber: string;
  setTrackingNumber: (x: string) => void;
  trackingUrl: string;
  setTrackingUrl: (x: string) => void;
}> = props => {
  const classes = useOrderDetailsStyles();
  const {consignmentNote, trackingNumber, trackingUrl, setTrackingNumber, setTrackingUrl} = props;

  const salesOrderPopupState = usePopupState({variant: 'popover', popupId: 'brandOrderPopupState'});

  const {listPackageType} = usePackageTypeAPI();

  const [packageSelected, setPackageSelected] = useState<IConsignmentNotePackage>();
  const [packageTypeList, setPackageTypeList] = useState<IPackageType[]>([]);
  const [packageTypeSelected, setPackageTypeSelected] = useState<IPackageType>();

  const [showAll, setShowAll] = useState<boolean>(true);

  useEffect(() => {
    getAllPackages();
  }, []);

  const getAllPackages = async () => {
    const packageTypeList = await listPackageType();
    setPackageTypeList(packageTypeList);
    if (!packageTypeSelected) {
      setPackageTypeSelected(packageTypeList?.[0]);
    }

    const packageTypeIDs = new Set();

    setPackageTypeList(packageTypeList?.filter((_packageType: IPackageType) => packageTypeIDs.has(_packageType.ID)));
    const PackageTypeID = packageTypeList?.[0]?.ID;

    const pack = consignmentNote?.Packages?.filter(
      (_pack: IConsignmentNotePackage) => _pack.PackageTypeID === PackageTypeID,
    );
    setPackageSelected(showAll ? consignmentNote?.Packages?.[0] : pack?.[0]);
  };

  const getActiveStep = () => {
    switch (consignmentNote?.Status) {
      case CONSIGNMENT_NOTE_STATUS.APPROVED:
        return 5;
      case CONSIGNMENT_NOTE_STATUS.AWAITING_PICKUP:
        return 6;
      case CONSIGNMENT_NOTE_STATUS.DELIVERED:
        return 7;
      default:
        return 5;
    }
  };

  const handlePackTypeChange = (packageType: IPackageType) => {
    const pack = consignmentNote?.Packages?.filter(
      (_pack: IConsignmentNotePackage) => _pack.PackageTypeID === packageType?.ID,
    );
    setPackageTypeSelected(packageType);
    setPackageSelected(pack?.[0]);
    setShowAll(false);
    salesOrderPopupState.close();
  };

  return (
    <Box height="100%" width="100%" display="flex" flexDirection="column">
      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <Box bgcolor="#FAFAFA" className="flex w-3/5 items-center justify-between rounded-sm p-2">
          <div className="flex-1 text-lg font-medium text-spenda-headingtext">
            <p>
              All Packages <span className="text-spenda-labeltext">{`(${consignmentNote?.RefNumber || ''})`}</span>
            </p>
            <p>
              Delivery Method: <b>{consignmentNote.IsCustomerPickup ? 'Pickup' : 'Delivery'}</b>
            </p>
          </div>
          <div className="flex-1 text-base font-medium text-spenda-headingtext">
            <p>
              Customer Name: <span className="text-spenda-primarytext">{consignmentNote.CustomerName || '-'}</span>
            </p>
            <p>
              Contact Name:{' '}
              <span className="text-spenda-primarytext">{consignmentNote.Delivery?.ContactName || '-'}</span>
            </p>
            <p>
              Phone Number:{' '}
              <span className="text-spenda-primarytext">{consignmentNote.Delivery?.ContactPhone || '-'}</span>
            </p>
            <p>
              Email: <span className="text-spenda-primarytext">{consignmentNote.Delivery?.ContactEmail || '-'}</span>
            </p>
          </div>
          <div className="flex-1 text-base font-medium text-spenda-headingtext">
            <p>Shipping Address:</p>
            <p className="text-spenda-labeltext">
              <span className="text-spenda-primarytext">
                {`${consignmentNote.Delivery?.StreetAddress || ''} ${consignmentNote.Delivery?.Suburb || ''} ${
                  consignmentNote.Delivery?.State || ''
                } ${consignmentNote.Delivery?.Postcode || ''} ${consignmentNote.Delivery?.Country || ''}`}
              </span>
            </p>
          </div>
        </Box>
        <Box marginTop="-84px">
          <CustomStepper
            steps={[
              'Ready To Pick',
              'In Picking',
              'Picked',
              'Ready to Pack',
              'In Packing',
              'Packed',
              'Awaiting Collection',
              'Completed',
            ]}
            activeStep={getActiveStep()}
          />
        </Box>
      </Box>
      <Box flex={1} display="flex" flexDirection="row" marginRight="5px" marginTop="28px" height="500px">
        <Box width="20%" display="flex" flexDirection="column" height="100%">
          <div>
            <span className="mr-3 text-base text-spenda-primarytext">Package Type </span>
            <SButton
              margin="0px"
              color="white"
              border="1px solid #f1f1f1"
              textColor="greyTint"
              backgroundColor="#FAFAFA"
              boxShadow="none"
              lineHeight="m"
              type="button"
              width="calc(100% - 98px)"
              fontSize="m"
              className={`${classes.packageTypeBtnText}`}
              {...bindTrigger(salesOrderPopupState)}
            >
              {showAll ? 'All Packages' : packageTypeSelected?.Name}{' '}
              <KeyboardArrowDownRoundedIcon {...css({color: '#1C78AD', fontSize: '1.25rem'})} />
            </SButton>
            <FilterMenu {...bindMenu(salesOrderPopupState)}>
              <FilterMenuItem onClick={() => setShowAll(true)} className={`${classes.filterMenu}`}>
                All Packages
              </FilterMenuItem>
              {packageTypeList.map((a: IPackageType) => (
                <FilterMenuItem key={a.ID} onClick={() => handlePackTypeChange(a)} className={`${classes.filterMenu}`}>
                  {a?.Name || ''}
                </FilterMenuItem>
              ))}
            </FilterMenu>
          </div>
          <Box
            marginTop="5px"
            height="420px"
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            bgcolor="#FAFAFA"
            border="1px solid #F1F1F1"
            boxShadow="0 0 4px 0 #00000012"
            borderRadius="8px"
            padding="16px"
            className="overflow-y-auto"
          >
            {consignmentNote?.Packages.filter(
              (packageList: IConsignmentNotePackage) =>
                showAll || packageList.PackageTypeID === packageTypeSelected?.ID,
            ).map((_pack: IConsignmentNotePackage) => (
              <Box
                key={_pack.ID}
                onClick={() => {
                  setPackageSelected(_pack);
                  const packType = packageTypeList?.find((_type: IPackageType) => _type.ID === _pack.PackageTypeID);
                  setPackageTypeSelected(packType);
                }}
                height="60px"
                className={`mb-4 flex items-center justify-between rounded ${
                  packageSelected?.ID === _pack.ID ? classes.boxActive : classes.box
                }`}
              >
                <span className="m-2">{_pack?.PackageTypeName}</span>
                <span className="m-2 text-xs">
                  {_pack?.Lines?.length} {_pack?.Lines?.length === 1 ? 'Product' : 'Products'}
                </span>
              </Box>
            ))}
          </Box>
        </Box>
        <Box width="80%" display="flex" flexDirection="column" height="100%" marginLeft="10px">
          {consignmentNote.Status === CONSIGNMENT_NOTE_STATUS.AWAITING_PICKUP ? (
            <Box
              display="flex"
              alignItems="center"
              height="80px"
              paddingX="20px"
              paddingY="10px"
              bgcolor="#FAFAFA"
              border="1px solid #F1F1F1"
              marginBottom="10px"
              borderRadius="8px"
              marginLeft="10px"
            >
              <div className="flex w-full flex-row justify-center">
                <div className="mx-2 flex w-full flex-row items-center">
                  <span className={`${classes.trackingColor} pr-1 text-lg font-medium`}>Tracking URL-</span>
                  <input
                    pattern="[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)"
                    placeholder="Tracking URL"
                    className="my-auto flex-1 p-2"
                    onChange={(e: any) => setTrackingUrl(e?.target?.value)}
                    value={trackingUrl}
                  />
                </div>
              </div>
              <div className="flex w-full flex-row justify-center">
                <div className="mx-2 flex w-full flex-row items-center">
                  <span className={`${classes.trackingColor} pr-1 text-lg font-medium`}>Tracking Number-</span>
                  <input
                    placeholder="Tracking Number"
                    className="my-auto flex-1 p-2"
                    onChange={(e: any) => setTrackingNumber(e?.target?.value)}
                    value={trackingNumber}
                  />
                </div>
              </div>
            </Box>
          ) : null}
          {consignmentNote.TrackingNumber ? (
            <Box
              display="flex"
              alignItems="center"
              height="80px"
              paddingX="20px"
              paddingY="10px"
              bgcolor="#FAFAFA"
              border="1px solid #F1F1F1"
              marginBottom="10px"
              borderRadius="8px"
              marginLeft="10px"
            >
              <div className="mx-2 flex w-full flex-row items-center">
                <span className={`${classes.trackingColor} pr-1 text-lg font-medium`}>Tracking URL-</span>
                <span className="pr-1 text-lg font-medium text-spenda-primarytext">{consignmentNote.TrackingUrl}</span>
              </div>
              <div className="mx-2 flex w-full flex-row items-center">
                <span className={`${classes.trackingColor} pr-1 text-lg font-medium`}>Tracking Number-</span>
                <span className="pr-1 text-lg font-medium text-spenda-primarytext">
                  {consignmentNote.TrackingNumber}
                </span>
              </div>
            </Box>
          ) : null}
          <Box
            display="flex"
            flexDirection="column"
            bgcolor="#FFFFFF"
            border="1px solid #F1F1F1"
            boxShadow="0 0 6px 0 #00000014"
            borderRadius="8px"
            height="100%"
            marginLeft="10px"
          >
            <Box padding="30px">
              <Box display="flex" justifyContent="space-between">
                <span className={`${classes.fontWeight} text-xl text-primary`}>
                  {packageSelected?.PackageTypeName || ''}
                </span>
                <span className={`${classes.fontWeight} text-base text-spenda-primarytext`}>{`${
                  packageTypeSelected?.DefaultLength_m || ' '
                }m x ${packageTypeSelected?.DefaultWidth_m || ' '}m x ${
                  packageTypeSelected?.DefaultHeight_m || ' '
                }m | ${packageTypeSelected?.MaxWeight_kg || ' '}kg`}</span>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <span className="text-base text-spenda-primarytext">
                  {packageSelected?.Lines?.length} {packageSelected?.Lines?.length === 1 ? 'Product' : 'Products'}
                </span>
                <div>
                  <span className={`text-base text-spenda-primarytext`}>Package ID-</span>
                  <span className={`mr-2 text-base text-spenda-primarytext`}>{packageSelected?.PackageTypeID}</span>
                </div>
              </Box>
            </Box>
            <Box flex={1} padding="0px 30px 30px">
              <Box className={`${classes.awaitingTable}`}>
                <STableCustom
                  columns={[
                    {
                      title: 'Code',
                      field: 'Code',
                    },
                    {
                      title: 'Products',
                      field: 'ShortDescription',
                      render: (rowData: any) => (
                        <>
                          <b>{rowData.Code}</b> {rowData.ShortDescription}
                        </>
                      ),
                    },
                    {
                      title: 'Dimensions & Weight',
                      field: 'DimensionAndWeight',
                      align: 'right',
                      render: (rowData: any) => (
                        <span className="text-base text-spenda-primarytext">
                          {`${PriceCalculator.roundNumber(rowData.Depth_m)}m x ${PriceCalculator.roundNumber(
                            rowData.Depth_m,
                          )}m x ${PriceCalculator.roundNumber(rowData.Depth_m)}m`}{' '}
                          | {PriceCalculator.roundNumber(rowData.Weight_kg)}kg
                        </span>
                      ),
                    },
                    {
                      title: 'Ordered Qty',
                      field: 'QuantityOrdered',
                      align: 'right',
                      render: (rowData: any) => rowData.QuantityOrdered || '',
                    },
                    {
                      title: 'Pick Qty',
                      field: 'PickQuantity',
                      align: 'right',
                      render: (rowData: any) => rowData.QuantityPicked || '',
                    },
                    {
                      title: 'Pack Qty',
                      field: 'PackQuantity',
                      align: 'right',
                      render: (rowData: any) => rowData.QuantityPacked || '',
                    },
                  ]}
                  data={packageSelected?.Lines || []}
                  emptyDataMessage={'No Order Items'}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
