import React, {useState} from 'react';
import {Box} from '@material-ui/core';
import {SButton} from '../buttons/Button';
import NextArrowIcon from '../../assets/svg/next-arrow.svg';
import CloseIcon from '@material-ui/icons/Close';
import {CircularProgressbarWithChildren, buildStyles} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {PurchaseNowDialog} from './PurchaseNowDialog';
import {SModal} from '../modals/modalSpendaMeterialUI';
import {GetBackgroundCSS} from './Onboarding';

interface DaysLeftDialogProps {
  handleClose: () => void;
  daysLeft: number;
}

export const DaysLeftDialog: React.FunctionComponent<DaysLeftDialogProps> = ({
  handleClose,
  daysLeft,
}: DaysLeftDialogProps) => {
  const [showPurchaseNowScreen, setShowPurchaseNowScreen] = useState<Boolean>(false);

  return (
    <>
      {showPurchaseNowScreen ? (
        <PurchaseNowDialog handleClose={handleClose} />
      ) : (
        <SModal open>
          <div
            className={`text-xl spenda-color flex justify-center items-center h-full max-w-full ${GetBackgroundCSS()}`}
          >
            <div className="flex flex-col max-w-3xl modal-box" style={{padding: '50px'}}>
              <span className="closeIcon" onClick={handleClose}>
                <CloseIcon />
              </span>
              <div className="flex flex-row h-full">
                <Box className="bg-white-inner modal-right-content flex flex-col justify-between" alignItems="center">
                  {daysLeft === 0 ? (
                    <h2 className="text-4xl trial-heading text-center w-10/12 mx-auto">
                      It looks like you are still on trial period and your trial period <span>will expire today!</span>
                    </h2>
                  ) : (
                    <h2 className="text-4xl trial-heading text-center w-10/12 mx-auto">
                      It looks like you are still on trial period and you just got <span>few days left!</span>
                    </h2>
                  )}
                  <div style={{width: '200px', height: '200px'}}>
                    <CircularProgressbarWithChildren
                      value={(daysLeft / 30) * 100}
                      strokeWidth={6}
                      counterClockwise
                      styles={buildStyles({
                        pathColor: '#E3A600',
                        trailColor: 'rgb(236 236 236)',
                      })}
                    >
                      <div className="trial-time">
                        {' '}
                        <span>{daysLeft}</span> Days Left
                      </div>
                    </CircularProgressbarWithChildren>
                  </div>
                  <Box display="flex" justifyContent="center">
                    <SButton
                      className="butt-1 alt primaryBtn"
                      type="submit"
                      width="m"
                      lineHeight="m"
                      fontSize="s"
                      textColor="blueShade"
                      onClick={handleClose}
                    >
                      Later
                    </SButton>
                    <SButton
                      color={'blueShade'}
                      type="submit"
                      width="m"
                      lineHeight="m"
                      fontSize="m"
                      onClick={() => setShowPurchaseNowScreen(true)}
                      className="common-next-btn purchase-now-btn"
                    >
                      {' '}
                      Purchase Now
                      <img src={NextArrowIcon} alt="Next Arrow Icon" className="" />
                    </SButton>
                  </Box>
                </Box>
                {/* </div> */}
              </div>
            </div>
          </div>
        </SModal>
      )}
    </>
  );
};
