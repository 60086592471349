import React from 'react';
import {Typography} from 'spenda-ui-react';
import {AlertDialogSlideV2} from './AlertDialogSlideV2';

export const QuoteDeleteDialog = (props: {onCancel: () => void; isSubmitting: boolean; deleteQuote: () => void}) => {
  return (
    <AlertDialogSlideV2
      title={'Delete quote'}
      data-autoid="dlgDeleteQuote"
      headingTextSize="h2"
      headingClassess="justify-center"
      dialogActionsAlignment="justify-between"
      dialogClassess="!min-w-[20%] !max-w-[496px] !w-full"
      dialogBodyClassess="flex justify-center items-center"
      actions={[
        {
          label: 'Cancel',
          action: () => {
            props.onCancel();
          },
          disabled: props.isSubmitting,
          color: 'primary',
          variant: 'outlined',
        },
        {
          label: 'Yes, Delete quote',
          action: () => {
            props.deleteQuote();
          },
          disabled: props.isSubmitting,
          color: 'error',
          variant: 'filled',
        },
      ]}
    >
      <Typography
        variant="small"
        className="py-10 flex items-center justify-center text-black-800 font-normal text-center max-w-[244px] w-full"
      >
        Are you sure you want to delete this quote? This action cannot be undone.
      </Typography>
    </AlertDialogSlideV2>
  );
};
