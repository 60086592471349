import React, {useState} from 'react';
import {AlertDialogSlideV2} from './AlertDialogSlideV2';
import {Button, IconButton, Typography} from 'spenda-ui-react';
import {AddVariantDialog} from './AddVariantDialog';
import {ARTable} from '../AccountsReceivable/ARTable';
import {DragIcon, PlusButton} from '../../assets/svg';
import {AddAttributeDialog} from './AddAttributeDialog';

interface ICreateMasterVariantDialogProps {
  open?: boolean;
  onClose: () => void;
}

interface MasterVariantColumn {
  title: string;
  key: string;
  width?: string;
  align?: string;
  rowClassName?: string;
  headerRenderer?: () => JSX.Element;
  rowRenderer: (index: number) => JSX.Element;
}

interface AttributeButtonProps {
  label: string;
  shortLabel: string;
  onClick: (label: string, shortLabel: string) => void;
}

interface VariantAttributeProps {
  label: string;
  shortLabel: string;
  onClick: (label: string, shortLabel: string) => void;
  color?: string;
  imageUrl?: string;
  sellPrice?: string;
}

const CreateMasterVariantDialog: React.FunctionComponent<ICreateMasterVariantDialogProps> = ({onClose}) => {
  const [openVariantDialog, setOpenVariantDialog] = useState<boolean>(false);
  const [openAttributeDialog, setOpenAttributeDialog] = useState<boolean>(false);
  const [selectedAttribute, setSelectedAttribute] = useState<{label: string; shortLabel: string} | undefined>(
    undefined,
  );

  const handleAttributeClick = (label: string, shortLabel: string) => {
    setSelectedAttribute({label, shortLabel});
    setOpenAttributeDialog(true);
  };

  const handlePlusButtonClick = () => {
    setSelectedAttribute(undefined);
    setOpenAttributeDialog(true);
  };

  const AttributeButton: React.FC<AttributeButtonProps> = ({label, shortLabel, onClick}) => (
    <div
      className="flex cursor-pointer items-center rounded-bl-md rounded-tl-md bg-gray-200 py-1 text-sm"
      onClick={() => onClick(label, shortLabel)}
      role="button"
      tabIndex={0}
      data-autoid="btnAttribute"
    >
      <Typography
        variant="small"
        className="flex !h-2 items-center justify-between bg-gray-200 !px-2 text-sm text-black hover:no-underline"
        data-autoid="txtAttributeName"
      >
        {label}
      </Typography>
      <Typography variant="small" className="ml-4 mr-4 text-gray-500" data-autoid="txtAttributeAbbr">
        {shortLabel}
      </Typography>
    </div>
  );

  const VariantAttribute: React.FC<VariantAttributeProps> = ({
    label,
    shortLabel,
    onClick,
    color,
    imageUrl,
    sellPrice,
  }) => (
    <div className="flex flex-col ">
      <div className="flex items-center gap-1">
        <AttributeButton label={label} shortLabel={shortLabel} onClick={onClick} />
        {color && <div className="h-10 w-10 rounded-md" style={{backgroundColor: color}}></div>}
        {imageUrl && <img src={imageUrl} alt={label} className="h-10 w-10 rounded-md" />}
      </div>
      {sellPrice && (
        <Typography variant="xsmall" className="font-medium text-spenda-sGreen">
          {sellPrice}
        </Typography>
      )}
    </div>
  );

  const columns: MasterVariantColumn[] = [
    {
      title: 'Variations',
      key: 'variant',
      width: '25%',
      align: 'left',
      rowClassName: 'p-2.5',
      rowRenderer: (index: number) => {
        return (
          <>
            <Typography variant="h3" className="text-spenda-primarytext" data-autoid={`txtVariantColor-${index}`}>
              Color
            </Typography>
            <Typography variant="xsmall" data-autoid={`txtVariantColorAbbr-${index}`}>
              CR
            </Typography>
          </>
        );
      },
    },
    {
      title: 'Attributes',
      key: 'attribute',
      width: '72%',
      align: 'left',
      rowClassName: 'p-2.5',
      rowRenderer: () => {
        return (
          <>
            <div className="flex gap-2">
              <IconButton
                variant="outlined"
                className="border-none shadow-none"
                ripple={false}
                onClick={handlePlusButtonClick}
              >
                <PlusButton className="cursor-pointer" data-autoid="btnAddNewVariant" width={35} height={35} />
              </IconButton>
              <div className="flex  max-w-[550px] flex-wrap gap-3">
                <VariantAttribute label="Yellow" shortLabel="Y" onClick={handleAttributeClick} color="#FFD700" />
                <VariantAttribute
                  label="Red"
                  shortLabel="R"
                  onClick={handleAttributeClick}
                  color="#FF5F52"
                  sellPrice="+ $1.00 (ex)"
                />
                <VariantAttribute label="Blue" shortLabel="B" onClick={handleAttributeClick} color="#1686EE" />
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: '',
      key: '',
      width: '3%',
      align: 'right',
      rowClassName: 'p-2.5',
      rowRenderer: (index: number) => {
        return (
          <span data-autoid={`lnkDragIcon-${index}`} className="cursor-move">
            <DragIcon data-autoid={`imgDrag-${index}`} />
          </span>
        );
      },
    },
  ];

  return (
    <>
      <AlertDialogSlideV2
        dialogClassess="!mx-5 !w-full !max-w-[1091px]"
        dialogBodyClassess="h-[666px]  "
        title="Brake Master Cylinder - 210A0372"
        headingClassess="font-semibold justify-center flex flex-col gap-1"
        headerChildren={
          <Typography className="text-spenda-labeltext" variant="paragraph">
            Base sell price $166.14(ex) - $182.75(inc)
          </Typography>
        }
        actions={[
          {label: 'Cancel', action: () => onClose(), variant: 'outlined', className: 'mr-auto'},
          {
            label: 'Add Variant',
            variant: 'outlined',
            className: 'ml-auto',
            action: () => {
              setOpenVariantDialog(true);
            },
          },
          {
            label: 'Done',
            type: 'submit',
            className: 'ml-3',
            action: () => onClose(),
          },
        ]}
        dialogActionsAlignment="justify-between"
      >
        <div className="mx-4 mt-3">
          <Typography className="ml-3 text-spenda-labeltext" variant="paragraph">
            Variation combination count 9/50
          </Typography>
          <div className={`mt-2`}>
            <ARTable columns={columns} rows={Array(1).fill(1)} isLoading={false} tableClass="w-full" />
          </div>

          <div className="px-1 py-0">
            <Button
              variant="text"
              ripple={false}
              className="bg-spenda-bckgrndBlue px-2 font-semibold hover:no-underline"
              onClick={() => setOpenVariantDialog(true)}
            >
              + Add Variant
            </Button>
          </div>
        </div>
      </AlertDialogSlideV2>
      {openVariantDialog && (
        <AddVariantDialog
          open={openVariantDialog}
          onClose={() => {
            setOpenVariantDialog(false);
          }}
        />
      )}
      {openAttributeDialog && (
        <AddAttributeDialog
          open={openAttributeDialog}
          onClose={() => {
            setOpenAttributeDialog(false);
            setSelectedAttribute(undefined);
          }}
          initialData={selectedAttribute}
        />
      )}
    </>
  );
};

export default CreateMasterVariantDialog;
