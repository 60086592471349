import * as React from 'react';

function SelectedBillsDetails(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={12} height={13} viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.718 0a3.48 3.48 0 012.475 1.025l1.742 1.743a3.475 3.475 0 011.026 2.475V6.82a.5.5 0 01-1 .025V5.243c0-.083-.016-.162-.024-.243H7.461a1.5 1.5 0 01-1.5-1.5V1.024C5.88 1.016 5.8 1 5.718 1H3.461a1.5 1.5 0 00-1.5 1.5v7a1.5 1.5 0 001.5 1.5h3.48a.5.5 0 010 1H3.46a2.503 2.503 0 01-2.5-2.5v-7A2.503 2.503 0 013.46 0h2.257zm.743 8.5a.5.5 0 010 1h-2.5a.5.5 0 110-1h2.5zm1.5-2a.5.5 0 010 1h-4a.5.5 0 110-1h4zm-1-5.158V3.5a.5.5 0 00.5.5h2.158a2.492 2.492 0 00-.39-.525L7.485 1.732a2.505 2.505 0 00-.525-.39z"
        fill="#C68A19"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.792 7.92a2.04 2.04 0 100 4.08 2.04 2.04 0 000-4.08zm0 3.6a1.56 1.56 0 110-3.12 1.56 1.56 0 010 3.12z"
        fill="#C68A19"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.792 8.77a.17.17 0 00-.17.17v1.36a.17.17 0 00.34 0V8.94a.17.17 0 00-.17-.17z"
        fill="#C68A19"
      />
      <path d="M9.622 10.98a.17.17 0 11.34 0 .17.17 0 01-.34 0z" fill="#C68A19" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.68 9.96a2.112 2.112 0 114.224 0 2.112 2.112 0 01-4.224 0zm2.112-1.968a1.968 1.968 0 100 3.936 1.968 1.968 0 000-3.936zm0 .408c-.822 0-1.56.738-1.56 1.56 0 .821.738 1.56 1.56 1.56.821-.001 1.607-.739 1.608-1.56 0-.822-.787-1.56-1.608-1.56zM8.16 9.96a1.632 1.632 0 113.264 0 1.632 1.632 0 01-3.264 0zm1.39-1.02a.242.242 0 11.484 0v1.36a.242.242 0 01-.484 0V8.94zm.242-.098a.098.098 0 00-.098.098v1.36a.098.098 0 00.196 0V8.94a.098.098 0 00-.098-.098zM9.55 10.98a.242.242 0 11.484 0 .242.242 0 01-.484 0zm.242-.098a.098.098 0 100 .196.098.098 0 000-.196z"
        fill="#C68A19"
      />
    </svg>
  );
}

export default SelectedBillsDetails;
