import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {Formik} from 'formik';
import clsx from 'clsx';
import * as Yup from 'yup';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';
import {isNumber} from 'lodash';

import {IDeliveryReceipt} from '../../model/delivery-receipt/DeliveryReceipt';
import {DeliveryReceiptStatusTypes, PurchaseOrderDashboardViewMode} from '../../model/constants/Constants';
import {AUTH_PURCHASING_PURCHASE_DASHBOARD_LIST} from '../../routes/PurchasingRoutes';
import {DestructiveButton, PrimaryButton, SecondaryButton} from '../../components/buttons/DefaultButtons';
import {DeliveryReceiptCounted, DeliveryReceiptLines} from '../../components/purchase-order/DeliveryReceiptLines';
import {CreateDeliveryReceiptDialog} from '../../components/purchase-order/CreateDeliveryReceiptDialog';
import {AlertDialogSlide} from '../../components/dialog/AlertDialogSlide';
import {Layout} from '../../components/layout/Layout';
import useDeliveryReceiptAPI from '../../services/useDeliveryReceiptAPI';
import {Toast} from '../../utils/Toast';

import {IconButton, makeStyles} from '@material-ui/core';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';

const useDeliveryReceiptDetailStyle = makeStyles(() => ({
  backIconButton: {
    color: '#1C78AD',
    border: '1px solid #1C78AD',
    borderRadius: '6px',
    padding: '8px',
    marginRight: '6px',
  },
}));

const validationSchema = Yup.object({});

export interface ICreateDeliveryReceiptValues extends Partial<IDeliveryReceipt> {}

const newDeliveryReceipt: ICreateDeliveryReceiptValues = {};

export const DeliveryReceiptDetail = () => {
  const history = useHistory();
  const {deliveryReceiptID} = useParams<{deliveryReceiptID: string | undefined}>();
  const classes = useDeliveryReceiptDetailStyle();

  const [deliveryReceiptToEdit, setDeliveryReceiptToEdit] = useState<ICreateDeliveryReceiptValues>(newDeliveryReceipt);
  const [editMode, setEditMode] = useState<boolean>(true);
  const [createDialog, setCreateDialog] = useState<boolean>(false);
  const [deleteConfirmationDialog, setDeleteConfirmationDialog] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');

  const {get: getDeliveryReceiptByID, deleteDeliveryReceipt, updateStatus, isLoading} = useDeliveryReceiptAPI();

  useEffect(() => {
    if (deliveryReceiptID) {
      getDeliveryReceipt(deliveryReceiptID);
    }
  }, []);

  const getDeliveryReceipt = async (deliveryReceiptID: string) => {
    const deliveryReceipt = await getDeliveryReceiptByID(Number(deliveryReceiptID));

    if (deliveryReceipt.Status !== DeliveryReceiptStatusTypes.In_Progress) {
      setEditMode(false);
    }
    setDeliveryReceiptToEdit(deliveryReceipt);
  };

  const handleCloseClick = () => {
    history.push({
      pathname: AUTH_PURCHASING_PURCHASE_DASHBOARD_LIST,
      state: {ViewMode: PurchaseOrderDashboardViewMode.DeliveryReceipts},
    });
  };

  const handleCreateDialogOpen = (_isSupplierSelect: boolean = false) => {
    setCreateDialog(true);
  };

  const handleCreateDialogClose = (refetch?: boolean) => {
    setCreateDialog(false);
    if (refetch && deliveryReceiptID) {
      getDeliveryReceipt(deliveryReceiptID);
    }
  };

  const handleDeleteDeliveryReceipt = async () => {
    if (deliveryReceiptID) {
      await deleteDeliveryReceipt(Number(deliveryReceiptID));
      history.push({
        pathname: AUTH_PURCHASING_PURCHASE_DASHBOARD_LIST,
        state: {ViewMode: PurchaseOrderDashboardViewMode.DeliveryReceipts},
      });
    }
  };

  const onSubmit = async (): Promise<void> => {
    return updateStatus(Number(deliveryReceiptID), DeliveryReceiptStatusTypes.Verified).then(() => {
      Toast.info('Delivery receipt counted');
      history.push({
        pathname: AUTH_PURCHASING_PURCHASE_DASHBOARD_LIST,
        state: {ViewMode: PurchaseOrderDashboardViewMode.DeliveryReceipts},
      });
    });
  };

  const _deliveryDetailLeftPanel = (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={deliveryReceiptToEdit}
      onSubmit={onSubmit}
    >
      {props => {
        const submitDisabled =
          (props.values?.Lines || []).some(line => !isNumber(line.QuantityReceived)) ||
          !(props.values?.Lines || []).length;
        return (
          <div className="flex h-full flex-col">
            <div className="mb-2.5 grid grid-cols-[1fr_150px_1fr] items-center rounded-lg bg-white p-2.5 shadow-[0_0_6px_0_#D3E5EF]">
              <div className="flex items-center justify-start">
                <IconButton
                  disableRipple
                  className={classes.backIconButton}
                  onClick={handleCloseClick}
                  data-autoid="btnBack"
                >
                  <ArrowBackRoundedIcon fontSize="small" />
                </IconButton>
                <span className="text-xl font-light text-spenda-primarytext">Delivery receipt</span>
              </div>
              <p className="text-center text-base font-medium text-spenda-primarytext">Delivery details</p>
              <div className="flex items-center justify-end gap-12">
                <p className="whitespace-nowrap text-center leading-[0.75rem] text-[#4D4D4D]">
                  <span className="text-[10px] font-medium">Consignment reference</span>
                  <br />
                  <span className="text-base font-semibold">{props.values.RefNumber || ''}</span>
                </p>
                <p className="whitespace-nowrap text-center leading-[0.75rem] text-[#4D4D4D]">
                  <span className="text-[10px] font-medium">Warehouse</span>
                  <br />
                  <span className="text-base font-semibold">{props.values.Warehouse?.Name || ''}</span>
                </p>
                <p className="whitespace-nowrap text-center leading-[0.75rem] text-[#4D4D4D]">
                  <span className="text-[10px] font-medium">Delivery date</span>
                  <br />
                  <span className="text-base font-semibold">
                    {moment(props.values.TransDate).format('Do MMM YYYY')}
                  </span>
                </p>
                <SecondaryButton
                  label="Delivery Details"
                  margin="0 0 0 8px"
                  className="whitespace-nowrap"
                  onClick={() => handleCreateDialogOpen()}
                />
              </div>
            </div>
            <div className="flex h-[100px] flex-1">
              <div
                id="delivery-receipt-detail-lines"
                className={clsx('h-full flex-1 overflow-y-auto rounded-lg bg-white p-3 shadow-[0_0_6px_0_#D3E5EF]')}
              >
                {isLoading && !deliveryReceiptToEdit.ID ? (
                  <>
                    <Skeleton height={30} />
                    <Skeleton count={5} />
                  </>
                ) : (
                  <DeliveryReceiptLines
                    {...props}
                    editMode={editMode}
                    searchText={searchText}
                    setSearchText={setSearchText}
                  />
                )}
              </div>
              <div
                id="delivery-receipt-received-lines"
                className="ml-2 flex h-full w-[515px] flex-col justify-between overflow-y-auto rounded-lg bg-white p-3 shadow-[0_0_6px_0_#D3E5EF]"
              >
                {isLoading && !deliveryReceiptToEdit.ID ? (
                  <div>
                    <Skeleton height={50} />
                    <Skeleton count={3} height={20} />
                  </div>
                ) : (
                  <DeliveryReceiptCounted
                    {...props}
                    editMode={editMode}
                    searchText={searchText}
                    setSearchText={setSearchText}
                  />
                )}
              </div>
              {editMode ? (
                <div className="fixed bottom-2.5 w-[calc(100%-575px)] px-0.5">
                  {isLoading && !deliveryReceiptToEdit.ID ? (
                    <Skeleton height={55} />
                  ) : (
                    <div
                      className="flex w-full flex-row p-2"
                      style={{backgroundColor: '#ADADAD50', borderRadius: '8px'}}
                    >
                      <DestructiveButton
                        label="Delete"
                        onClick={() => setDeleteConfirmationDialog(true)}
                        margin="0 8px 0 0"
                      />
                      <SecondaryButton label="Cancel" onClick={handleCloseClick} />
                      <div className="flex-1" />
                      <SecondaryButton disabled label="Auto Count" onClick={() => {}} />
                      <PrimaryButton
                        onClick={props.handleSubmit}
                        margin="0 0 0 8px"
                        label="Complete Receipt"
                        isSubmitting={props.isSubmitting}
                        disabled={props.isSubmitting || submitDisabled}
                      />
                    </div>
                  )}
                </div>
              ) : null}
            </div>
          </div>
        );
      }}
    </Formik>
  );

  return (
    <>
      {deleteConfirmationDialog ? (
        <AlertDialogSlide
          showTitleBottomBorder={true}
          titleColor="black"
          headerFontWeight={300}
          paddingY={60}
          paddingX={85}
          dialogWidth={'451px'}
          fontFamily="Poppins"
          title="Delete delivery receipt"
          footer={
            <div className="flex w-full justify-between rounded bg-[#EFEFEF] px-4 py-2">
              <SecondaryButton label="Cancel" onClick={() => setDeleteConfirmationDialog(false)} />
              <DestructiveButton
                label="Delete"
                onClick={() => handleDeleteDeliveryReceipt()}
                isSubmitting={isLoading}
                disabled={isLoading}
              />
            </div>
          }
        >
          <p className="text-center text-xs">Are you sure you would like to delete the delivery receipt? </p>
        </AlertDialogSlide>
      ) : null}
      <CreateDeliveryReceiptDialog
        isReadOnly={!editMode}
        open={createDialog}
        onClose={handleCreateDialogClose}
        deliveryReceiptID={deliveryReceiptID}
      />
      <div className="relative h-full overflow-hidden bg-spenda-newbg font-poppins">
        <Layout leftPanel={_deliveryDetailLeftPanel} mainPanel={<></>} splitWidthType={4} />
      </div>
    </>
  );
};
