import React, {useEffect, useState} from 'react';
import {useParams, useHistory} from 'react-router-dom';
import {Formik, Form, FormikProps, FormikValues} from 'formik';
import moment from 'moment';

import {AUTH_SELLING_SALES_ORDER_PACK, AUTH_SELLING_SALES_ORDER_LIST} from '../../routes/SalesOrderRoutes';
import {ApplicationInfo} from '../../model/constants/ApplicationInfo';
import {DatTypes, IAlertTypes, DISCOUNT_MODE, PICK_SLIP_STATUS, DeliveryMethods} from '../../model/constants/Constants';
import {ICustomer} from '../../model/customer/Customer';
import {IPickSlipLine} from '../../model/sales-order/PickSlip';
import {Layout} from '../../components/layout/Layout';
import LoadingIndicator from '../../components/ui/LoadingIndicator';
import {SalesOrderLineItems} from '../../components/sales-orders/SalesOrderLineItems';
import {useSalesOrderAPI} from '../../services/useSalesOrderAPI';
import {usePickSlipAPI} from '../../services/usePickSlipAPI';
import {useCustomersAPI} from '../../services/useCustomersAPI';
import useAlertAPI from '../../services/useAlertAPI';
import {Toast} from '../../utils/Toast';

import {Divider} from '@material-ui/core';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import {Button, Chip, Typography} from 'spenda-ui-react';

export const SalesOrderPick = () => {
  const history = useHistory();
  let {pickSlipID} = useParams<{pickSlipID?: string | undefined}>();

  const [orderDetails, setOrderDetails] = useState<any>({});
  const [customerDetails, setCustomerDetails] = useState<ICustomer>();

  const {getOrderById, isLoading: salesLoading} = useSalesOrderAPI();
  const {getPrintOut, isLoading: alertLoading} = useAlertAPI();
  const {getPickSlipById, upatePickSlipLines, upatePickSlipStatus, isLoading: pickLoading} = usePickSlipAPI();
  const {getCustomer, isLoading: customerLoading} = useCustomersAPI();

  const isLoading = salesLoading || alertLoading || pickLoading || customerLoading;

  useEffect(() => {
    if (pickSlipID) {
      getPickSlip();
    }
  }, [pickSlipID]);

  const getPickSlip = async () => {
    const pickSlipRes = await getPickSlipById(pickSlipID);
    const order = await getOrderById(pickSlipRes.SalesOrderID);
    const customer = await getCustomer(order?.CustomerID);
    order.Lines = pickSlipRes.Lines?.map(_line => ({
      ..._line,
      QuantityPicked: _line.Status === 'Complete' ? _line?.QuantityPicked || 0 : _line?.QuantityRequired || 0,
    }));
    order.Status = pickSlipRes.Status;
    order.CreatedDateTime_utc = pickSlipRes.CreatedDateTime_utc;
    const orderResp = {
      ...order,
      BillingAddress: {
        City: order?.BillCity,
        Country: order?.BillCountry,
        StreetAddress: order?.BillStreetAddress,
        PostCode: order?.BillPostCode,
        State: order?.BillState,
      },
      BillingAddressID: order.BillingAddressID,
      ShippingAddress: {
        City: order?.ShipCity,
        Country: order?.ShipCountry,
        StreetAddress: order?.ShipStreetAddress,
        PostCode: order?.ShipPostCode,
        State: order?.ShipState,
      },
      ShippingAddressID: order.ShippingAddressID,
      DiscountMode:
        order.DiscountMode?.toLowerCase() === 'none' || !order.DiscountMode
          ? DISCOUNT_MODE.PERCENT
          : order.DiscountMode,
    };
    setCustomerDetails(customer);
    setOrderDetails(orderResp);
  };

  const onSubmit = (): void => {};

  const onKeyDown = (keyEvent: React.KeyboardEvent<HTMLFormElement>) => {
    if (keyEvent && keyEvent.key === 'Enter') {
      keyEvent.preventDefault();
    }
  };

  const handleSetAddress = async (addressID: string, addressString: string, addressType: string) => {
    setOrderDetails({
      ...orderDetails,
      ...(addressType === 'ShippingAddress'
        ? {
            ShippingAddress: addressString,
            ShippingAddressID: addressID,
          }
        : {}),
      ...(addressType === 'BillingAddress'
        ? {
            BillingAddress: addressString,
            BillingAddressID: addressID,
          }
        : {}),
    });
  };

  const handlePrintPickSlip = async () => {
    if (pickSlipID) {
      const data = await getPrintOut({
        DocIDs: [Number(pickSlipID)],
        DatTypeID: DatTypes.PickSlip,
        AlertType: IAlertTypes.Pdf,
        WebsiteID: ApplicationInfo.WebsiteId,
      });
      if (data?.AlertMsg) {
        const pdf = data.AlertMsg.AttachmentFileNames[0];
        window.open(pdf, '_blank');
      }
    }
  };

  const handleMovetoPackaging = async (fProps: FormikProps<FormikValues>) => {
    const {values} = fProps;
    const pickSlipLine = await upatePickSlipLines(pickSlipID, {
      Lines: values.Lines.filter((_line: IPickSlipLine) => _line.DeliveryMethod !== DeliveryMethods.Cash_and_Carry).map(
        (_line: IPickSlipLine) =>
          ({
            PickSlipLineID: _line.PickSlipLineID,
            SalesOrderLineID: _line.SalesOrderLineID,
            QuantityPicked: _line.QuantityPicked,
            IsActive: true,
          }) as any,
      ),
    });
    if (pickSlipLine.IsSuccess) {
      const pickSlipStatus = await upatePickSlipStatus(pickSlipID, {
        Status: PICK_SLIP_STATUS.PICKED,
      });
      const consignmentNote = pickSlipStatus.Value?.LinkedTrans?.find(
        trans => trans.DatTypeID === DatTypes.ConsignmentNote,
      );
      Toast.info('Items Picked');
      if (pickSlipStatus.IsSuccess && pickSlipID && consignmentNote) {
        history.push(
          AUTH_SELLING_SALES_ORDER_PACK.replace(/:pickSlipID/g, pickSlipID + '').replace(
            /:consignmentNoteID\?/g,
            consignmentNote.ID + '',
          ),
        );
      } else {
        history.push(AUTH_SELLING_SALES_ORDER_LIST);
      }
    }
  };

  const _orderDetails = (
    <div
      className={`flex h-full max-h-[calc(100vh-20.5em)] min-h-full w-full flex-col overflow-y-auto rounded-lg bg-white`}
      id="sales-orders-list"
    >
      <Formik enableReinitialize initialValues={orderDetails} onSubmit={onSubmit}>
        {props => {
          return (
            <Form onKeyDown={onKeyDown} onSubmit={props.handleSubmit} className="flex h-full flex-col">
              <div className={`flex w-full justify-between px-5 pb-3 pt-4`}>
                <div className="flex">
                  <Typography variant="h2" className={`text-#4D4D4D font-poppin my-auto font-medium`}>
                    {props.values.Status === PICK_SLIP_STATUS.COMPLETE ? 'Pick Slip' : 'Ready to Pick'}
                  </Typography>
                  {props.values?.ID ? (
                    <Chip
                      className="my-auto ml-4 flex !h-6 items-center gap-5 border-[#C55D44] px-1 py-0 font-poppins text-[#C55D44]"
                      variant="outlined"
                      icon={<QueryBuilderIcon className="text-#C55D44 pb-1 pr-2 font-poppins" />}
                      value={moment.utc(props?.values?.CreatedDateTime_utc).local().fromNow()}
                    ></Chip>
                  ) : null}
                </div>
                <div className="flex">
                  <Button loading={isLoading} onClick={handlePrintPickSlip}>
                    <PrintOutlinedIcon className="mr-1 pr-1 font-poppins text-white" />
                    Print Pick Slip
                  </Button>
                  {props.values.Status !== PICK_SLIP_STATUS.PICKED &&
                  props.values.Status !== PICK_SLIP_STATUS.COMPLETE ? (
                    <Button
                      className="ml-2"
                      type="button"
                      loading={isLoading}
                      disabled={
                        isLoading ||
                        props?.values?.Lines?.filter(
                          (_line: IPickSlipLine) => _line.DeliveryMethod !== DeliveryMethods.Cash_and_Carry,
                        )?.some((a: any) => isNaN(parseFloat(a?.QuantityPicked))) ||
                        !Boolean(
                          props?.values?.Lines?.filter(
                            (_line: IPickSlipLine) => _line.DeliveryMethod !== DeliveryMethods.Cash_and_Carry,
                          )?.reduce((acc: any, a: any) => acc + parseFloat(a?.QuantityPicked) || 0, 0),
                        )
                      }
                      onClick={() => handleMovetoPackaging(props)}
                    >
                      Move to Packaging
                    </Button>
                  ) : null}
                </div>
              </div>
              <Divider className="bg-[#EAEAEA]" />
              <div className="relative flex flex-1 flex-col items-start justify-start px-5 py-5">
                <div className="h-full w-full">
                  <SalesOrderLineItems
                    {...props}
                    customerDetails={customerDetails}
                    editMode={false}
                    setEditMode={() => {}}
                    handleSetAddress={handleSetAddress}
                  />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );

  return (
    <>
      <div className={`relative h-full overflow-hidden bg-spenda-newbg`}>
        <Layout leftPanel={_orderDetails} splitWidthType={4} />
        <LoadingIndicator isLoading={isLoading} size="md" color="#1C78AD" />
      </div>
    </>
  );
};
