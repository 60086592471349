import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import {Transaction} from './Transaction';
import {IAPInvoiceId} from './APInvoicesSingleViewList';
import {Dialog, IconButton} from 'spenda-ui-react';
import downloadCsv from '../../assets/svg/accounts-payable/download-csv.svg';
import {usePaymentBatchAPI} from '../../services/usePaymentBatchAPI';
import {DatTypes, IAlertTypes} from '../../model/constants/Constants';
import {ApplicationInfo} from '../../model/constants/ApplicationInfo';

interface IFullInvoiceProps {
  open: boolean;
  onClose: () => void;
  apInvoiceId: IAPInvoiceId | undefined;
  isServiceJobInvoice?: boolean;
}

export const APInvoiceDetailView = ({open, onClose, apInvoiceId, isServiceJobInvoice}: IFullInvoiceProps) => {
  const downloadInvoiceasPdf = usePaymentBatchAPI();

  const handleDownloadInvoiceAsPdf = async () => {
    try {
      if (!apInvoiceId) return;

      const payload = {
        DatTypeID: DatTypes.AccountsPayablePurchaseInvoice,
        DocIDs: [apInvoiceId.transId],
        AlertType: IAlertTypes.Pdf,
        WebsiteID: ApplicationInfo.WebsiteId,
      };
      return downloadInvoiceasPdf.downloadInvoiceasPdf(payload).then(data => {
        if (data) {
          data.map(pdf => {
            window.open(pdf);
          });
        }
      });
    } catch (error) {
      console.error('Error downloading PDF:', error);
    }
  };

  return (
    <Dialog
      handler={() => {}}
      open={open}
      backdropClassName="fixed bg-[rgb(0,0,0,0.3)] backdrop-blur-[10px] max-h-[100%] overflow-y-auto"
      className="w-full min-w-[100vw]"
    >
      <div className="fixed right-0 top-0 mr-[4rem] mt-[2rem] flex justify-end gap-2">
        {!isServiceJobInvoice && (
          <div className="font-poppins text-xl font-light text-black-800" onClick={handleDownloadInvoiceAsPdf}>
            <IconButton
              variant="outlined"
              className="bg-[rgba(255,255,255,0.5)] text-primary hover:bg-[rgba(255,255,255,0.5)]"
            >
              <img src={downloadCsv} className="mr-3 cursor-pointer" data-autoid="imgPDF" alt="Download PDF" />
            </IconButton>
          </div>
        )}
        <div className="font-poppins text-xl font-light text-black-800" onClick={onClose}>
          <IconButton
            variant="outlined"
            className="bg-[rgba(255,255,255,0.5)] text-primary hover:bg-[rgba(255,255,255,0.5)]"
          >
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <div className={'mb-[7vh] flex items-center justify-center'}>
        <Transaction isAPInvoice={true} isServiceJobInvoice={isServiceJobInvoice} apInvoiceId={apInvoiceId} />
      </div>
    </Dialog>
  );
};
