import React, {useEffect, useState} from 'react';
import {SDialog} from '../modals/modalSpendaMeterialUI';
import {AddLocationForm} from '../form/AddLocationForm';
import {Box, DialogActions, DialogContent, DialogTitle, IconButton, makeStyles} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {FormikProps} from 'formik';
import {ILocation} from '../../model/address/Location';
import clsx from 'clsx';
import {Button} from 'spenda-ui-react';

type ILocationProps = FormikProps<{Locations?: ILocation[]}> & {
  setOpen: (open: boolean) => void;
  open: boolean;
  isEdit: boolean;
  addressIndex: number;
  locationIndex: number;
  handleDefaultBilling?: (locationIndex: number, addressIndex: number, defaultTypeParameter: string) => void;
  newUI?: boolean;
  isLatestDesign?: boolean;
};

const useBrandDialogStyles = makeStyles(theme => ({
  dialogActions: {
    borderTop: '1px solid lightgrey',
    margin: '0 1rem',
    padding: '1rem 0',
  },
  newDialogActions: {
    margin: '8px',
    borderRadius: '8px',
    backgroundColor: '#ADADAD20',
    padding: '8px 12px',
    justifyContent: 'space-between',
  },
  dialogContent: {
    padding: '14px',
    flex: 1,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogPaper: {
    minHeight: '421px',
    minWidth: '898px',
  },
  header: {
    borderBottom: '1px solid #EAEAEA',
    '& h2': {
      fontFamily: 'Poppins, sans-serif !important',
      fontWeight: 300,
      fontSize: '22px',
      color: '#333333',
      textAlign: 'center',
    },
  },
}));

export const LocationDialog: React.FunctionComponent<ILocationProps> = ({
  setOpen,
  open,
  addressIndex,
  locationIndex,
  isEdit,
  handleDefaultBilling,
  newUI,
  isLatestDesign,
  ...props
}) => {
  const classes = useBrandDialogStyles();
  const [valuesObject, setValuesObject] = useState<ILocation[] | undefined>();

  useEffect(() => {
    if (open) {
      setValuesObject(props?.values?.Locations);
    }
  }, [open]);

  const handleDialogClose = () => {
    if (isEdit === false) {
      props.values?.Locations?.[locationIndex]?.Addresses?.pop() && props.values?.Locations?.[addressIndex]?.Addresses;
      setOpen(false);
    } else {
      props.setFieldValue(`Locations`, valuesObject);
      setOpen(false);
    }
  };

  return (
    <>
      <SDialog
        classes={{paper: classes.dialogPaper}}
        maxWidth="md"
        fullWidth
        open={open}
        onClose={() => setOpen(false)}
        className="!z-[999999]"
      >
        <DialogTitle className={clsx({[classes.header]: newUI})}>
          {newUI && (isEdit ? 'Edit Address' : 'Add Address')}
          {!newUI && (
            <Box display="flex" justifyContent="center" marginTop="10px">
              {`${isEdit ? 'Edit Address' : 'Add New Address'}`}
              <IconButton aria-label="close" className={classes.closeButton} onClick={() => handleDialogClose()}>
                <CloseIcon />
              </IconButton>
            </Box>
          )}
        </DialogTitle>
        <DialogContent classes={{root: classes.dialogContent}}>
          <AddLocationForm
            {...props}
            addressIndex={addressIndex}
            locationIndex={locationIndex}
            handleDefaultBilling={handleDefaultBilling}
            valuesObject={valuesObject}
            newUI={newUI}
            isLatestDesign={isLatestDesign}
          />
        </DialogContent>
        <DialogActions
          className={clsx({[classes.newDialogActions]: newUI})}
          classes={!newUI ? {root: classes.dialogActions} : {}}
        >
          {newUI ? (
            <>
              <Button variant="outlined" className="bg-white" onClick={() => handleDialogClose()}>
                Cancel
              </Button>
              <Button
                loading={props.isSubmitting}
                disabled={
                  props.isSubmitting ||
                  !props.values.Locations?.[locationIndex]?.Addresses?.[addressIndex]?.PostCode ||
                  !props.values.Locations?.[locationIndex]?.Addresses?.[addressIndex]?.Country
                }
                form="supplier-form"
                type="submit"
                onClick={() => {
                  setOpen(false);
                }}
              >
                Save & Close
              </Button>
            </>
          ) : (
            <Box display="flex" justifyContent="space-between" width="100%" paddingY="8px">
              <Button type="button" onClick={() => handleDialogClose()}>
                Cancel
              </Button>
              <Button
                type="submit"
                onClick={() => {
                  setOpen(false);
                }}
                loading={props.isSubmitting}
                disabled={
                  props.isSubmitting ||
                  !props.values.Locations?.[locationIndex]?.Addresses?.[addressIndex]?.PostCode ||
                  !props.values.Locations?.[locationIndex]?.Addresses?.[addressIndex]?.Country
                }
              >
                {'Save'}
              </Button>
            </Box>
          )}
        </DialogActions>
      </SDialog>
    </>
  );
};
