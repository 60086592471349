import React from 'react';
import {PaymentStatusType} from '../../model/constants/Constants';
import Skeleton from 'react-loading-skeleton';

interface IPaymentStatusBadgeProps {
  status?: string;
  isLoading: boolean;
}

export const PaymentStatusBadge = (props: IPaymentStatusBadgeProps) => {
  const {status, isLoading} = props;

  let statusColor: string = '';
  let statusBorderColor: string = '';
  switch (status) {
    case PaymentStatusType.Cleared:
    case PaymentStatusType.Paid:
      statusColor = '#3C9F78';
      statusBorderColor = '#3C9F7830';
      break;
    case PaymentStatusType.InProgress:
    case PaymentStatusType.Scheduled:
      statusColor = '#C68A19';
      statusBorderColor = '#C68A1930';
      break;
    case PaymentStatusType.CancellationInProgress:
      statusColor = '#C68A19';
      statusBorderColor = '#C68A1930';
      break;
    case PaymentStatusType.Failed:
    case PaymentStatusType.Cancelled:
      statusColor = '#B9624B';
      statusBorderColor = '#B9624B30';
      break;
    case PaymentStatusType.AwaitingAuthorisation:
      statusColor = '#C68A19';
      statusBorderColor = '#C68A1930';
      break;
    default:
      break;
  }

  const transactionStatus = (status: any) => {
    switch (status) {
      case 'Paid':
        return <p className="text-lg font-semibold ">Paid</p>;
      case 'InProgress':
        return <p className="text-lg font-semibold ">In Progress</p>;
      case 'Cleared':
      case 'Cancelled':
      case 'Failed':
        return <p className="text-lg font-semibold ">{status}</p>;
      case 'AwaitingAuthorisation':
        return <p className="text-lg font-semibold ">Awaiting Authorisation</p>;
      default:
        return <p className="text-lg font-semibold ">{status}</p>;
    }
  };

  return (
    <>
      {isLoading ? (
        <div className={`flex flex-col self-end h-[60px] w-[160px] mb-2 border-none`}>
          <Skeleton className="" width={'100%'} height={'60px'} />
        </div>
      ) : (
        <div
          data-autoid="txtTransactionStatus"
          className={`flex flex-col self-end justify-center items-center h-[60px] w-[160px] rounded-[8px] font-poppins text-[${statusColor}] border-[${statusBorderColor}] border-2 text-center mb-2`}
        >
          {transactionStatus(status)}
        </div>
      )}
    </>
  );
};
