import React from 'react';
//Icon
const IconCheckboxChecked = (props: {width?: string; height?: string}) => {
  return (
    <svg
      width={props.width || '20px'}
      height={props.height || '20px'}
      viewBox="0 0 25 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>General/-23 Copy</title>
      <defs>
        <path
          d="M22.5354667,7.69066667 C22.3258667,7.48266667 21.9877333,7.488 21.7813333,7.696 C21.6346667,7.84533333 21.5989333,8.05866667 21.6629333,8.24533333 L21.6512,8.25066667 C22.1365333,9.46666667 22.4117333,10.7946667 22.4117333,12.1866667 C22.4117333,18.08 17.6362667,22.8533333 11.7450667,22.8533333 C5.8544,22.8533333 1.0784,18.08 1.0784,12.1866667 C1.0784,6.29333333 5.8544,1.52 11.7450667,1.52 C14.76,1.52 17.4784,2.77333333 19.4170667,4.78933333 L19.4234667,4.77866667 C19.6346667,4.97066667 19.9573333,4.96533333 20.1584,4.76266667 C20.3648,4.54933333 20.3621333,4.21333333 20.1525333,4.00533333 C20.1328,3.98933333 20.1066667,3.97866667 20.0848,3.96266667 C17.96,1.80266667 15.0154667,0.453333333 11.7450667,0.453333333 C5.26506667,0.453333333 0.0117333333,5.70666667 0.0117333333,12.1866667 C0.0117333333,18.6666667 5.26506667,23.92 11.7450667,23.92 C18.2250667,23.92 23.4784,18.6666667 23.4784,12.1866667 C23.4784,10.6773333 23.1834667,9.24266667 22.6650667,7.91466667 C22.64,7.83466667 22.6010667,7.75466667 22.5354667,7.69066667 Z M11.736,15.1573333 L6.7888,10.208 C6.69226667,10.112 6.55893333,10.0533333 6.41173333,10.0533333 C6.1168,10.0533333 5.8784,10.2933333 5.8784,10.5866667 C5.8784,10.736 5.9376,10.8693333 6.03466667,10.9653333 L11.368,16.2986667 C11.4650667,16.3946667 11.5978667,16.4533333 11.7450667,16.4533333 C11.8970667,16.4533333 12.032,16.3893333 12.1296,16.288 L21.0368,6.96 C21.0373333,6.96 21.0384,6.96 21.0389333,6.96 L21.8,6.16533333 C21.7994667,6.16 21.7994667,6.16 21.7989333,6.16 L23.3312,4.55466667 C23.4208,4.45866667 23.4784,4.33066667 23.4784,4.18666667 C23.4784,3.89333333 23.24,3.65333333 22.9450667,3.65333333 C22.7930667,3.65333333 22.6581333,3.71733333 22.5605333,3.81866667 L21.1888,5.25333333 C21.1877333,5.25333333 21.1866667,5.25333333 21.1856,5.248 L20.4421333,6.032 L11.736,15.1573333 Z"
          id="path-checkbox-checked"
        ></path>
      </defs>
      <g id="Order-Listing" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Order-Listing-Concept/Picking-State-Copy-2" transform="translate(-35.000000, -442.000000)">
          <g id="InvoiceRowItemExpanded-row2" transform="translate(10.000000, 423.000000)">
            <g id="General/-23-Copy" transform="translate(25.866667, 19.000000)">
              <mask id="mask-2" fill="white">
                <use xlinkHref="#path-checkbox-checked"></use>
              </mask>
              <use id="Mask" fill="#0082BA" xlinkHref="#path-checkbox-checked"></use>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default IconCheckboxChecked;
