import React, {useRef, useState, useEffect} from 'react';
import {Form, Formik, FormikHelpers, FormikProps} from 'formik';
import {Box, DialogContent, DialogTitle, makeStyles} from '@material-ui/core';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import {Button} from 'spenda-ui-react';
import * as Yup from 'yup';

import {ICustomer} from '../../model/customer/Customer';
import {SDialog} from '../modals/modalSpendaMeterialUI';
import {AddCustomerForm} from '../form/CustomerForm';
import {useCustomersAPI} from '../../services/useCustomersAPI';
import {Toast} from '../../utils/Toast';
import {ILocation} from '../../model/address/Location';
import {IAddress} from '../../model/address/Address';
import {useFeatureFlags} from '../../hooks/useFeatureFlags';
import {useCustomerClassesAPI} from '../../services/useCustomerClassesAPI';
import {ICreateCustomerClassValues} from '../../model/customer/CustomerClasses';

interface ICustomerDialogProps {
  customer?: ICustomer;
  customerId?: any;
  open: boolean;
  onClose: (refetchList?: boolean, newCustomer?: ICustomer) => void;
  setOpenAccountCustomer: (openAccountCustomer: boolean) => void;
  setCustomer: (customer: ICustomer) => void;
  isQuoteCustomer?: boolean;
  onCustomerCreate?: () => void;
  onCustomerUpdate?: () => void;
}

const useCustomerDialogStyles = makeStyles(() => ({
  dialogContent: {
    padding: '0px 20px 30px 20px',
    flex: 1,
  },
  dialogPaper: {
    minHeight: '60vh',
  },
  header: {
    borderBottom: '1px solid #CCCCCC',
    '& h2': {
      fontFamily: 'Poppins, sans-serif !important',
      fontWeight: 300,
      fontSize: '28px',
      color: '#333333',
    },
  },
}));

const newCustomer: ICustomer = {
  IsActive: true,
  Name: '',
  Phone1: '',
  ClassID: null,
  ABN: '',
  RefNumber: '',
  IsIndividual: 'false',
  IsExemptFromMerchantSurcharge: 'false',
  Locations: [
    {
      Addresses: [
        {
          Name: '',
          Country: '',
          State: '',
          StreetAddress: '',
          City: '',
          PostCode: '',
          FullAddress: '',
          IsDefaultDeliverTo: true,
          IsDefaultBilling: true,
          IsActive: true,
        },
      ],
      IsActive: true,
      Name: '',
      IsDefaultLocation: true,
    },
  ],

  Contacts: [
    {
      IsPrimaryContact: true,
      FirstName: '',
      IsActive: true,
      LastName: '',
      EmailAddress: '',
      Phone1: '',
    },
  ],
} as ICustomer;

export const CustomerDialog = ({
  open,
  customerId,
  onClose,
  setOpenAccountCustomer,
  setCustomer,
  isQuoteCustomer,
  onCustomerCreate,
  onCustomerUpdate,
}: ICustomerDialogProps) => {
  const formRef = useRef<FormikProps<ICustomer>>(null);
  const classes = useCustomerDialogStyles();
  const {getCustomer, saveCustomer, updateCustomer} = useCustomersAPI();
  const {getList} = useCustomerClassesAPI();
  const {QuoteV289367} = useFeatureFlags().tenantOwned();
  const [refNumberChange, setRefNumberChange] = useState();
  const [disableLinkAccount, setDisbleLinkAccount] = useState(true);
  const [disableCreate, setDisableCreate] = useState(false);
  const [customerEdit, setCustomerEdit] = React.useState<ICustomer>(newCustomer);
  const [customerClasses, setCustomerClasses] = useState<ICreateCustomerClassValues[]>();
  const [hasPreSelectedClass, setHasPreSelectedClass] = useState<number>();

  const validationSchema = Yup.object({
    ABN: Yup.string().matches(
      /^(?:\d{2}-\d{3}-\d{3}-\d{3}|\d{11})$/,
      'ABN must be of 11 characters without any blank space',
    ),
    Contacts: Yup.array().of(
      Yup.object()
        .nullable(true)
        .shape({
          EmailAddress: Yup.string().email('Invalid Email address').nullable(true),
          FirstName: Yup.string()
            .nullable(true)
            .required('First Name is required')
            .max(50, 'First Name cannot be longer than 50 characters.')
            // .matches(/([a-z][a-z0-9 ]+|[a-z0-9 ]+[a-z]|[a-z0-9 ][a-z]+[a-z0-9 ])/, 'Please enter valid First name'),

            .matches(/^[A-zÀ-ú ]*$/, 'Please enter valid  first name'),
          LastName: Yup.string()
            .nullable(true)
            .required('Last Name is required')
            .max(50, 'Last Name cannot be longer than 50 characters.')
            // .matches(/([a-z][a-z0-9 ]+|[a-z0-9 ]+[a-z]|[a-z0-9 ][a-z]+[a-z0-9 ])/, 'Please enter valid Last name'),
            .matches(/^[A-zÀ-ú ]*$/, 'Please enter valid  last name'),

          Phone1: Yup.string()
            .matches(
              /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
              'Not a valid phone number',
            )
            .min(10, 'Not a valid phone number')
            .max(10, 'Not a valid phone number'),
        }),
    ),
    Phone1: Yup.string()
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        'Not a valid phone number',
      )
      .min(10, 'Not a valid phone number')
      .max(10, 'Not a valid phone number'),
    Name: Yup.string()
      // .matches(/([a-z][a-z0-9 ]+|[a-z0-9 ]+[a-z]|[a-z0-9 ][a-z]+[a-z0-9 ])/, 'Please enter valid Company name')
      .when(['IsIndividual'], (IsIndividual: string, schema: any) => {
        return IsIndividual === 'false' ? schema.required(' Company Name is required') : schema.nullable();
      }),
    RefNumber: Yup.string().test({
      name: 'RefNumber',
      test: function () {
        return refNumberChange
          ? this.createError({message: 'Unique Reference number require', path: 'RefNumber'})
          : true;
      },
    }),
  });

  const classValidationSchema = validationSchema.shape({
    ClassID: Yup.string().required('Please select a customer class (required)'),
  });

  useEffect(() => {
    const fetchData = async () => {
      const results = await Promise.all([
        customerId && getCustomer(customerId),
        isQuoteCustomer &&
          QuoteV289367 &&
          getList({
            PostingBehaviours: ['Account'],
            MaxResults: 100,
            IsShowActiveOnly: true,
          }),
      ]);
      const res: ICustomer = results[0];
      if (res) {
        let loc = res?.Locations?.map((d: ILocation, _index: number) => ({
          Addresses: d?.Addresses?.map((b: IAddress, _addIndex: number) => ({
            Name: b.Name,
            Country: b.Country,
            State: b.State,
            StreetAddress: b.StreetAddress,
            City: b.City,
            PostCode: b.PostCode,
            FullAddress: b.FullAddress,
            IsDefaultDeliverTo: b.IsDefaultDeliverTo,
            IsDefaultBilling: b.IsDefaultBilling,
            IsActive: b.IsActive,
            ID: b.ID,
          })),
          IsActive: d?.IsActive,
          Name: d?.Name,
          IsDefaultLocation: d?.IsDefaultLocation,
          ID: d?.ID,
        }));

        let con = res?.Contacts?.map((d: any) => ({
          BusinessContactID: d?.BusinessContactID,
          FirstName: d?.FirstName,
          LastName: d?.LastName,
          EmailAddress: d?.EmailAddress,
          Phone1: d?.Phone1,
          IsActive: true,
        }));

        const _customers = {
          IsActive: res.IsActive,
          Name: res?.IsIndividual === true ? '' : res.Name,
          Phone1: res.Phone1,
          ABN: res.ABN,
          BillToCustomerID: res.BillToCustomerID,
          ClassID: res.ClassID,
          RefNumber: res.RefNumber,
          IsIndividual: res.IsIndividual ? 'true' : 'false',
          IsExemptFromMerchantSurcharge: res.IsExemptFromMerchantSurcharge ? 'true' : 'false',
          Locations: loc,

          Contacts: con,
        } as ICustomer;
        setCustomerEdit(_customers);
        if (res.AccountCustomerClassID && res.AccountCustomerClassID != null) {
          setHasPreSelectedClass(res.AccountCustomerClassID);
        }
      }
      if (QuoteV289367 && isQuoteCustomer && results[1]) {
        setCustomerClasses(results[1].Value || []);
      }
    };
    if (open) {
      fetchData();
    }
  }, [open, customerId]);

  useEffect(() => {
    if (customerClasses?.length && QuoteV289367 && !hasPreSelectedClass && customerId) {
      if (formRef && typeof formRef.current?.setFieldTouched === 'function') {
        formRef?.current?.setFieldTouched('ClassID');
      }
    }
  }, [customerClasses]);

  const onSubmit = (values: ICustomer, _helpers: FormikHelpers<ICustomer>): Promise<void> => {
    let data: any = values;
    if (data.IsIndividual === 'true') {
      data.Name = '';
      data.Phone1 = '';
      data.CompanyName = '';
    }
    let i = 0;
    for (i = data?.Locations?.length || -1; i >= 0; i--) {
      if (data?.Locations?.[i]?.Addresses?.[0]?.FullAddress) {
        delete data?.Locations?.[i]?.Addresses?.[0]?.FullAddress;
      }
      if (
        !data?.Locations?.[i]?.Addresses?.[0]?.Name &&
        !data?.Locations?.[i]?.Addresses?.[0]?.Country &&
        !data?.Locations?.[i]?.Addresses?.[0]?.State &&
        !data?.Locations?.[i]?.Addresses?.[0]?.StreetAddress &&
        !data?.Locations?.[i]?.Addresses?.[0]?.City &&
        !data?.Locations?.[i]?.Addresses?.[0]?.PostCode &&
        data?.Locations?.[i]?.tempFlag
      ) {
        data = data?.Locations?.splice(i, 1) && data;
      }
      if (data?.Locations?.[i]?.tempFlag) {
        delete data?.Locations?.[i]?.tempFlag;
      }
      for (let j = 0; j < data?.Locations?.[i]?.AddAddress?.length; j++) {
        if (data?.Locations?.[i]?.AddAddress?.[j]?.addressFlag) {
          delete data?.Locations?.[i]?.Addresses?.[j]?.addressFlag;
        }
      }
    }

    if (customerId) {
      return updateCustomer(customerId, data, 13).then(_res => {
        if (isQuoteCustomer) {
          onCustomerUpdate?.();
          data.ID = customerId;
          setCustomer(data);
        } else {
          setCustomer({ID: customerId});
        }
        Toast.info('Customer Updated');
        setDisableCreate(true);
        setDisbleLinkAccount(false);
      });
    } else {
      return saveCustomer(data, 13).then(res => {
        if (isQuoteCustomer) {
          data.ID = res.Value.ID;
          setCustomer(data);
        } else {
          setCustomer(res.Value);
        }
        Toast.info('Customer created');
        setDisableCreate(true);
        setDisbleLinkAccount(false);
        isQuoteCustomer && onCustomerCreate?.();
      });
    }
  };

  const handleCloseClick = () => {
    if (formRef && typeof formRef.current?.resetForm === 'function') {
      const resetForm = formRef.current?.resetForm;
      resetForm();
    }
    setDisableCreate(false);
    onClose();
  };

  const handleOpenLinkDialog = () => {
    setOpenAccountCustomer(true);
    handleCloseClick();
    setDisbleLinkAccount(true);
  };

  return (
    <>
      <Formik
        enableReinitialize
        validationSchema={QuoteV289367 && !hasPreSelectedClass ? classValidationSchema : validationSchema}
        initialValues={customerEdit}
        onSubmit={onSubmit}
        innerRef={formRef}
      >
        {props => (
          <Form>
            <SDialog
              classes={{paper: classes.dialogPaper}}
              maxWidth="md"
              fullWidth
              open={open!}
              onClose={() => onClose()}
              className="relative !z-[99999]"
              data-autoid={'dlgCustomerDialog'}
            >
              <DialogTitle className="!p-0">
                <Box display="flex" justifyContent={'justify-between'} marginTop="10px">
                  {
                    <Box className={`${classes.header} mb-2 flex w-full justify-between px-3 pb-3`}>
                      <span> </span>
                      {customerId ? (
                        <h2 className={`w-full text-center`}>Edit customer</h2>
                      ) : (
                        <h2 className={`w-full text-center`}>Create a new customer</h2>
                      )}
                      <Box className="flex justify-end text-right">
                        <span className="ap-onboarding-close-icon" onClick={handleCloseClick} data-autoid="btnCloseBtn">
                          <CloseRoundedIcon style={{color: 'hsl(var(--primary))'}} />
                        </span>
                      </Box>
                    </Box>
                  }
                </Box>
              </DialogTitle>
              <DialogContent classes={{root: classes.dialogContent}}>
                <AddCustomerForm
                  setRefNumberChange={setRefNumberChange}
                  formType={'salesOrderDialog'}
                  isQuoteCustomer={isQuoteCustomer && QuoteV289367}
                  customerClasses={customerClasses}
                  hasPreSelectedClass={hasPreSelectedClass}
                  {...props}
                />
                {
                  <div className="absolute z-[999]" style={{bottom: 10, width: 926, marginLeft: -5}}>
                    <div
                      className={`flex w-full flex-row ${!isQuoteCustomer ? 'justify-between' : 'justify-end'} rounded-lg bg-spenda-footerBg p-2`}
                    >
                      {!isQuoteCustomer && (
                        <Button
                          variant="outlined"
                          className={`bg-white`}
                          onClick={handleOpenLinkDialog}
                          disabled={disableLinkAccount}
                        >
                          Link Account
                        </Button>
                      )}
                      <Button
                        disabled={disableCreate}
                        data-autoid="btnSaveCustomer"
                        onClick={() => props.handleSubmit()}
                        loading={props.isSubmitting}
                      >
                        {customerId ? 'Update Customer' : 'Create Customer'}
                      </Button>
                    </div>
                  </div>
                }
              </DialogContent>
            </SDialog>
          </Form>
        )}
      </Formik>
    </>
  );
};
