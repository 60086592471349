import {Form, Formik, getIn} from 'formik';
import React from 'react';
import {Button, Dialog, DialogBody, DialogFooter, DialogHeader, Input, Typography} from 'spenda-ui-react';
import {ILocation} from '../../model/address/Location';
import * as Yup from 'yup';
import {useCustomersAPI} from '../../services/useCustomersAPI';
import {FormikHelpers} from 'formik';
import {useServiceContext} from '../../context/serviceManagement/ServiceContext';
import {IServiceJob} from '../../model/service-management/serviceJob';

interface ServiceJobUpdateContactDialogPropTypes {
  contacts?: ILocation[];
  handler: (value: boolean) => void;
  isPrimaryMissing?: boolean;
  formValues: IServiceJob;
}

const validationSchema = Yup.object().shape({
  contacts: Yup.array().of(
    Yup.object().shape({
      EmailAddress: Yup.string().email('Invalid email').required('Email is required'),
      Phone1: Yup.string().nullable().min(9, 'Invalid number').max(11, 'Invalid number'),
    }),
  ),
});

const ServiceJobContactUpdateDialog = (props: ServiceJobUpdateContactDialogPropTypes) => {
  const {handler, contacts} = props;
  const {customerDetails, setCustomerDetails} = useServiceContext();
  const {saveContacts, getCustomer} = useCustomersAPI();

  const onSubmit = async (values: {contacts: ILocation[]}, helper: FormikHelpers<{contacts: ILocation[]}>) => {
    const payload = values.contacts.map(contact => {
      return {
        ...contact,
        PhoneMobile: contact.Phone1,
        Phone: contact.Phone1,
        ID: contact.BusinessContactID,
      };
    });
    const response = await saveContacts(customerDetails?.ID!, payload);
    const latestCustomer = await getCustomer(customerDetails?.ID!);

    if (response) {
      handler(true);
      setCustomerDetails(latestCustomer);
    }
    helper.setSubmitting(false);
  };

  return (
    <Dialog open handler={() => {}}>
      <Formik
        initialValues={{
          contacts: contacts ? [...contacts] : [],
        }}
        validationSchema={validationSchema}
        onSubmit={(values, helper) => {
          onSubmit(values, helper);
        }}
      >
        {({values, handleSubmit, handleBlur, handleChange, touched, errors, isSubmitting}) => {
          return (
            <Form
              onSubmit={e => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <DialogHeader data-autoid="dlgMissingInformation" className="justify-center border-b border-b-[#EAEAEA]">
                <Typography variant="h2">Missing important information</Typography>
              </DialogHeader>
              <DialogBody className="py-7">
                <div className="flex flex-col items-center justify-center gap-y-9">
                  <Typography className="w-[300px] text-center text-black-900" variant="small">
                    we are missing some vital customer details please provide the following:
                  </Typography>
                  <div className="flex flex-col gap-y-2.5">
                    {contacts?.map((contact, index) => (
                      <React.Fragment key={index}>
                        <div className="flex items-center gap-x-2.5">
                          {!contact.EmailAddress && (
                            <Input
                              name={`contacts.${index}.EmailAddress`}
                              label="Email"
                              className="w-full"
                              value={values.contacts[index].EmailAddress ?? ''}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              helperText={
                                getIn(touched, `contacts.[${index}].EmailAddress`) &&
                                getIn(errors, `contacts.[${index}].EmailAddress`)
                              }
                              error={
                                getIn(touched, `contacts.[${index}].EmailAddress`) &&
                                Boolean(getIn(errors, `contacts.[${index}].EmailAddress`))
                              }
                            />
                          )}
                          {!contact.Phone1 && (
                            <Input
                              containerProps={{
                                className: '!min-w-[135px] !max-w-[135px] w-full',
                              }}
                              name={`contacts.${index}.Phone1`}
                              label="Phone"
                              type="number"
                              className="w-full"
                              value={values.contacts[index].Phone1}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              helperText={
                                getIn(touched, `contacts.[${index}].Phone1`) &&
                                getIn(errors, `contacts.[${index}].Phone1`)
                              }
                              error={
                                getIn(touched, `contacts.[${index}].Phone1`) &&
                                Boolean(getIn(errors, `contacts.[${index}].Phone1`))
                              }
                            />
                          )}
                        </div>
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              </DialogBody>
              <DialogFooter className="mx-2.5 mb-2.5 justify-between rounded-lg bg-spenda-footerBg p-2.5">
                <Button variant="outlined" onClick={() => handler(false)}>
                  Cancel
                </Button>
                <Button type="submit" disabled={isSubmitting} loading={isSubmitting}>
                  Send & Save
                </Button>
              </DialogFooter>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};
export default ServiceJobContactUpdateDialog;
