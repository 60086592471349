import React, {useEffect, useState} from 'react';
import {useHistory, Link as RouterLink, RouteProps} from 'react-router-dom';
import {Box, makeStyles, Grid} from '@material-ui/core';
import {css} from 'glamor';

import {AUTH_CUSTOMER_ACCOUNT_CUSTOMERS_LIST, AUTH_CUSTOMER_ACCOUNT_CUSTOMERS_EDIT} from '../../routes/CustomerRoutes';
import {Layout} from '../../components/layout/Layout';
import {STable} from '../../components/tables/STable';
import {SPagination} from '../../components/tables/SPagination';
import {OperationalCustomerToolbar} from '../../components/customer/CustomerToolbar';
import LoadingIndicator from '../../components/ui/LoadingIndicator';
import xeroLogo from '../../assets/png/xero-logo.png';
import myobLogo from '../../assets/png/myob.png';
import quickBooksLogo from '../../assets/png/quick-books.png';
import {ICustomer} from '../../model/customer/Customer';
import {useLoadCustomers} from '../../services/useCustomersAPI';
import {useCustomersAPI} from '../../services/useCustomersAPI';
import {Toast} from '../../utils/Toast';

const columnsRender = () => ({
  RefNumber: {
    disabled: true,
    title: 'Account Ref',
    field: 'RefNumber',
    render: (rowData: ICustomer) => {
      return (
        <div {...css({position: 'relative'})}>
          <p>{rowData.RefNumber || ' - '}</p>
        </div>
      );
    },
  },
  CompanyName: {
    disabled: true,
    title: 'Company/Customer Name',
    field: 'CompanyName',
    render: (rowData: ICustomer) => {
      if (rowData.CompanyName || rowData.PrimaryContactFullName) {
        return (
          <div {...css({position: 'relative'})}>
            <p {...css({lineHeight: '1.357', color: '#999999', fontWeight: 500, fontSize: '0.875em'})}>
              {rowData.CompanyName}
            </p>
            <p>{rowData.PrimaryContactFullName}</p>
          </div>
        );
      } else {
        return (
          <div {...css({position: 'relative'})}>
            <p {...css({lineHeight: '1.357', color: '#999999', fontWeight: 500, fontSize: '0.875em'})}>{' - '}</p>
          </div>
        );
      }
    },
  },
  Phone1: {
    title: 'Contact Info',
    field: 'Phone1',
    render: (rowData: ICustomer) => {
      if (rowData.Phone1 || rowData.PrimaryContactEmailAddress) {
        return (
          <div {...css({position: 'relative'})}>
            <p {...css({lineHeight: '1.357', color: '#999999', fontWeight: 500, fontSize: '0.875em'})}>
              {rowData.Phone1}
            </p>
            <p>{rowData.PrimaryContactEmailAddress}</p>
          </div>
        );
      } else {
        return (
          <div {...css({position: 'relative'})}>
            <p {...css({lineHeight: '1.357', color: '#999999', fontWeight: 500, fontSize: '0.875em'})}>{' - '}</p>
          </div>
        );
      }
    },
  },
  DefaultLocation: {
    title: 'Billing Address',
    field: 'DefaultLocation',
    render: (rowData: ICustomer) => rowData?.DefaultLocation?.Addresses?.[0]?.FullAddress || ' - ',
  },
  AccountBalance: {
    title: 'Acc Balance',
    field: 'AccountBalance',
    render: (rowData: ICustomer) => {
      return (
        <div {...css({position: 'relative'})}>
          <p>${rowData.AccountBalance}</p>
        </div>
      );
    },
  },

  ClassName: {
    title: 'Class',
    field: 'ClassName',
    render: (rowData: ICustomer) => rowData?.ClassName || ' - ',
  },
  CountOfLinkedCustomers: {
    title: '#Linked Opt Cust',
    field: 'CountOfLinkedCustomers',
    render: (rowData: ICustomer) => {
      return (
        <div {...css({position: 'relative'})}>
          <p>{rowData.CountOfLinkedCustomers || ' - '}</p>
        </div>
      );
    },
  },
});

const useAccountCustomersStyles = makeStyles(() => ({
  root: {
    maxHeight: 'calc( 100vh - 20.5em )',
  },
  title: {
    fontWeight: 500,
    fontSize: '1.5em',
    color: '#4D4D4D',
  },
  xeroBox: {
    height: '62px',
    width: '249px',
    borderRadius: '6px',
    border: '1px solid #1c78ad',
    padding: '10px 20px',
    cursor: 'pointer',
  },
  xeroImage: {
    marginRight: '20px',
  },
  xeroBoxText: {
    fontWeight: 500,
    fontSize: '1.25em',
    lineHeight: 1.1,
    color: '#1c78ad',
    textAlign: 'center',
  },
}));

export const AccountCustomers = (_props: RouteProps) => {
  const history = useHistory();
  const classes = useAccountCustomersStyles();
  const [selected, setSelected] = useState<number[]>([]);
  const [refetch, setRefetch] = React.useState(0);
  const [tennatName, setTennatName] = React.useState();
  const [tennatId, setTennatId] = React.useState();
  const [tennatDataTypeID, setTennatDatTypeID] = React.useState();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [selectedColumns, setSelectedColumns] = useState<any>([
    'RefNumber',
    'AccountBalance',
    'CountOfLinkedCustomers',
    'CompanyName',
    'Phone1',
    'ClassName',
    'category',
    'DefaultLocation',
    'IsActive',
  ]);

  const {
    loading,
    totalCount,
    pageSize,
    currentPage,
    items: CustomersList,
    searchFilter,
    setSearchFilter,
    getCustomersList,
  } = useLoadCustomers(true);
  const {adaptorTypes, tennatUpdate, tennatDetailUpdate, getTennatById} = useCustomersAPI();

  useEffect(() => {
    getCustomersList();
  }, [refetch]);

  const handleRowClick = (rowData: any) => {
    history.push(AUTH_CUSTOMER_ACCOUNT_CUSTOMERS_EDIT.replace(/:customerID/g, rowData.ID));
  };
  useEffect(() => {
    adaptorTypes().then(res => {
      setTennatName(res?.Value?.Adaptor?.Name);
      setTennatId(res?.Value?.Adaptor?.ID);
      setTennatDatTypeID(res?.Value?.Adaptor?.MasterAdaptorID);
    });
  }, []);
  const handlePageChange = (newPage: number) => {
    setSearchFilter({
      ...searchFilter,
      LastRowNumber: (newPage - 1) * pageSize,
    });
  };
  const xeroIconClick = () => {
    setIsLoading(true);
    tennatUpdate(tennatId || 0, tennatName || '0', 13).then(res => {
      if (res?.IsAuthorized === false) {
        Toast.info('You are disconnected from Xero. please connect and try again');
        setIsLoading(false);
      } else {
        xeroAuthorizedAction();
      }
    });
  };

  const xeroAuthorizedAction = () => {
    tennatDetailUpdate(tennatId || 0, tennatName || '0', tennatDataTypeID || 0, 13).then(res => {
      if (res?.IsSuccess === true) {
        xeroQueueCall(res?.Value?.WorkflowID);
      } else {
        Toast.info('You are disconnected from Xero. please connect and try again');
        setIsLoading(false);
      }
    });
  };

  const xeroQueueCall = (id: any) => {
    if (id) {
      getTennatById(parseInt(id || '0')).then(res => {
        if (res.Status === 'Complete') {
          setIsLoading(false);
        } else {
          setTimeout(() => {
            xeroQueueCall(id);
          }, 5000);
        }
      });
    }
  };

  const productsList = (
    <div
      className={`${classes.root} flex min-h-full w-full flex-col overflow-y-auto rounded-lg bg-white p-4 px-5`}
      id="products-list"
    >
      {isLoading ? (
        <LoadingIndicator isLoading size={'md'} />
      ) : (
        <>
          <div className={'flex w-full justify-between px-0 py-4'}>
            <p className={`${classes.title}`}>Customer Management</p>
            <div className="flex">
              <RouterLink to={AUTH_CUSTOMER_ACCOUNT_CUSTOMERS_LIST}>
                {tennatName === 'Xero' && (
                  <Box
                    onClick={() => {
                      xeroIconClick();
                    }}
                    display="flex"
                    flexDirection="row"
                    className={`${classes.xeroBox}`}
                  >
                    <Grid container item={true} sm={2} alignContent="center" alignItems="center" justify="center">
                      <img src={xeroLogo} width="40px" height="40px" className={`${classes.xeroImage}`} />
                    </Grid>
                    <Grid container item={true} sm={10}>
                      <p className={`${classes.xeroBoxText}`}>Update Customers From Xero</p>
                    </Grid>
                  </Box>
                )}
                {tennatName === 'MyObAccountRightLive' && (
                  <Box
                    onClick={() => {
                      xeroIconClick();
                    }}
                    display="flex"
                    flexDirection="row"
                    className={`${classes.xeroBox}`}
                  >
                    <Grid container item={true} sm={2} alignContent="center" alignItems="center" justify="center">
                      <img src={myobLogo} width="40px" height="40px" className={`${classes.xeroImage}`} />
                    </Grid>
                    <Grid container item={true} sm={10}>
                      <p className={`${classes.xeroBoxText}`}>Update Customers From MyObAccountRightLive</p>
                    </Grid>
                  </Box>
                )}
                {tennatName === 'QuickBooksOnline' && (
                  <Box
                    onClick={() => {
                      xeroIconClick();
                    }}
                    display="flex"
                    flexDirection="row"
                    className={`${classes.xeroBox}`}
                  >
                    <Grid container item={true} sm={2} alignContent="center" alignItems="center" justify="center">
                      <img src={quickBooksLogo} width="40px" height="40px" className={`${classes.xeroImage}`} />
                    </Grid>
                    <Grid container item={true} sm={10}>
                      <p className={`${classes.xeroBoxText}`}>Update Customers From QuickBooksOnline</p>
                    </Grid>
                  </Box>
                )}
              </RouterLink>
            </div>
          </div>
          <OperationalCustomerToolbar
            isAccountCustomer={true}
            loading={loading}
            title={`Customers List (${totalCount})`}
            searchFilter={searchFilter}
            columns={columnsRender()}
            data={CustomersList}
            selected={selected}
            selectedColumns={selectedColumns}
            setSelected={setSelected}
            setSelectedColumns={setSelectedColumns}
            setSearchFilter={setSearchFilter}
            refetch={refetch}
            setRefetch={setRefetch}
          />

          <STable
            columns={Object.entries(columnsRender())
              .map(([key, value]: [string, any]) => {
                if (selectedColumns.includes(key)) {
                  return value;
                }
              })
              .filter(value => value)}
            data={CustomersList}
            selected={selected}
            setSelected={setSelected}
            emptyDataMessage={'No Customers'}
            stickyHeader={true}
            isUpdatedSelect={true}
            selection={true}
            loading={loading}
            onRowClick={handleRowClick}
          ></STable>
          <SPagination
            currentPage={currentPage}
            totalCount={totalCount}
            pageSize={pageSize}
            onPageChange={handlePageChange}
          />
        </>
      )}
    </div>
  );

  return (
    <div className={`relative h-full overflow-hidden bg-spenda-newbg`}>
      <Layout leftPanel={productsList} splitWidthType={4} />
    </div>
  );
};
