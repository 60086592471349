import React, {useContext, useEffect, useState} from 'react';
import {useHistory, useParams, Link} from 'react-router-dom';

import {
  DestructiveButton,
  PositiveButton,
  PrimaryButton,
  SecondaryButton,
} from '../../../components/buttons/DefaultButtons';
import {PaymentWidgetContext, PaymentWidgetPaymentSteps, PaymentWidgetStep} from './PaymentWidget';
import './scss/PaymentWidgetStepPaymentAuthority.css';
import {IconPaymentSubmit} from '../../../assets/svg/IconPaymentSubmit';
import PaymentDeclined from '../../../assets/svg/PaymentDeclined.svg';
import {STextField} from '../../../components/inputs/STextField';
import {makeStyles} from '@material-ui/core';
import {Authorisers, PaymentAuthorisationDetails} from '../../../model/payment/PaymentAuthority';
import {
  AuthorisationStatus,
  InvoicePaymentAllocationStatus,
  PaymentAuthorisationPurpose,
} from '../../../model/constants/Constants';
import PaymentSuccessful from '../../../assets/svg/PaymentSuccessful.svg';
import {usePaymentUtilities} from '../../../hooks/usePaymentUtilities';
import {Toast} from '../../../utils/Toast';
import {EnterSecurityCode} from './pw-components/EnterSecurityCode';
import {ZohoContext} from '../../../context/zoho-context/ZohoContext';
import moment from 'moment';
import {PriceFormat} from '../../../utils/formatter';
import {ProcessingAnimation} from '../../../assets/svg/animations/LottieAnimations';
import {IActionResults} from '../../../model/ActionResults';
import {ISupplierPaymentResponse} from '../../../model/payment/SupplierPaymentResponse';

enum AuthorisePaymentSteps {
  PAYMENT_INFO,
  ENTER_OTP,
  REJECT_PAYMENT,
  PAYMENT_SUBMITTED,
  PAYMENT_PROCESSING,
  AUTHORISE_PAYMENT,
}

const useStyles = makeStyles({
  listHeader: {
    background: 'rgba(211, 229, 239, 0.5)',
    padding: '7px',
    borderRadius: '6px',
  },
  listBody: {
    padding: '12px 33px 22px',
  },
  subBody: {
    color: '#BFBFBF',
  },
  dividerStyle: {
    height: '1px',
    background: '#f1f1f1',
    width: '20rem',
  },
  rejectReasonBody: {
    paddingBottom: '0px!important',
    '& .MuiOutlinedInput-multiline': {
      backgroundColor: '#ffffff!important',
    },
    position: 'relative',
    '& .MuiOutlinedInput-input:-webkit-autofill': {
      '-webkit-box-shadow': '0 0 0 100px #fff inset !important',
    },
    '& .MuiInputAdornment-root': {
      display: 'none',
    },
    '& .MuiOutlinedInput-root': {
      padding: '10px 10px',
      height: '100%',
      '&.Mui-focused': {
        '& .MuiInputAdornment-root': {
          display: 'contents',
        },
      },
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 16px) scale(1)',
      fontFamily: 'Poppins !important',
      fontSize: '14px',
      fontWeight: 500,
      width: 'auto',
      color: '#333 !important',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -4.7px) scale(1)',
      fontSize: '11px ',
    },
    '& textarea, input': {
      padding: '0px',
      fontFamily: 'Poppins !important',
      fontSize: '14px',
    },
    '& .MuiFormHelperText-root.Mui-error': {
      fontFamily: 'Poppins !important',
      position: 'relative !important',
      top: '0px !important',
    },
    '& .MuiInputAdornment-positionEnd button': {
      padding: '0px !important',
    },

    '& .MuiOutlinedInput-input': {
      height: '132px',
    },
    '& fieldset legend': {fontSize: '16px !important'},
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#333!important',
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#1C78AD!important',
    },
  },
});

export const PaymentWidgetStepAuthorisePayment = () => {
  const {
    getPaymentMethodAuthorisationDetails,
    approvePaymentAuthorisation,
    rejectPaymentAuthorisation,
    resetAuthenticationCode,
    tenantName,
    setStep: setPWStep,
    paymentAuthorisationDetails,
    setPaymentAuthorisationDetails,
    authorisationId: authId,
    isAccountsPayable,
    onCancel,
    onDone,
    checkPaymentStatus,
    checkRepaymentStatus,
  } = useContext(PaymentWidgetContext);
  const {transformPaymentAuthorisationUserInfo, getAuthenticationCodeErrorMsg} = usePaymentUtilities();
  const history = useHistory();
  const classes = useStyles();
  const {code: authorisationID = authId} = useParams<any>() || authId;
  const [securityCode, setSecurityCode] = useState<string>('');
  const [securityCodeErrorMsg, setSecuityCodeErrorMsg] = useState('');
  const [rejectionMsg, setRejectionMsg] = useState<string>('');
  const zohoChatContext = useContext(ZohoContext)!;
  const {showChatWindow} = zohoChatContext;
  const [step, setStep] = useState<AuthorisePaymentSteps | undefined>();
  const [paymentStatusRes, setPaymentStatusRes] = useState<IActionResults<ISupplierPaymentResponse>>();
  const [paymentstatusCount, setPaymentStatusCount] = useState<number>(0);

  const handleChangeSecurityCode = (code: any) => {
    if (securityCodeErrorMsg) setSecuityCodeErrorMsg('');
    return setSecurityCode(code);
  };

  const authorisersList = transformPaymentAuthorisationUserInfo(
    paymentAuthorisationDetails?.authorisers || [],
    undefined,
  );

  const zeroNewCode = paymentAuthorisationDetails?.newCodesRemainingCount === 0;
  const zeroCodeAttempts = paymentAuthorisationDetails?.attemptsRemainingCount === 0;
  const isAtemptsExhausted = zeroNewCode && zeroCodeAttempts;

  const {approvalCount: approvalCountsRequiredLeft, requiredApprovalCount: totalApprovalCountsRequired} =
    paymentAuthorisationDetails ?? {};
  const isFinalAuthoriser = (totalApprovalCountsRequired || 0) - (approvalCountsRequiredLeft || 0) <= 1;
  const {invoicePaymentAllocationID, supplierID, facilityGUID, paymentGUID} =
    paymentAuthorisationDetails?.data?.merchantPayment ?? {};
  const {paymentBatchID} = paymentAuthorisationDetails?.data.paymentBatch ?? {};

  const checkStatus = async ({
    facilityGUID,
    supplierID,
    invoicePaymentAllocationID,
    paymentGUID,
    paymentBatchID,
  }: {
    facilityGUID?: string;
    supplierID?: string;
    invoicePaymentAllocationID?: string;
    paymentGUID?: string;
    paymentBatchID?: string;
  }): Promise<IActionResults<ISupplierPaymentResponse> | undefined> => {
    if (paymentBatchID) {
      // AP batch payment
      const res = await checkPaymentStatus?.(Number(paymentBatchID));
      if (res) {
        setPaymentStatusRes(res);
        return res;
      }
    } else if (facilityGUID) {
      // SpendaFinance Payment
      const res = await checkRepaymentStatus?.(paymentGUID!, facilityGUID);
      if (res) {
        setPaymentStatusRes(res);
        return res;
      }
    } else {
      // PSBL Batch payment
      const res = await checkPaymentStatus?.(Number(invoicePaymentAllocationID), Number(supplierID));
      if (res) {
        setPaymentStatusRes(res);
        return res;
      }
    }
  };

  useEffect(() => {
    getAuthorisationDetails();
  }, [authorisationID]);

  const getAuthorisationDetails = async () => {
    if (authorisationID && getPaymentMethodAuthorisationDetails && setPaymentAuthorisationDetails) {
      const paymentAuthorisationDetails = await getPaymentMethodAuthorisationDetails(authorisationID);
      setPaymentAuthorisationDetails(paymentAuthorisationDetails.authorisation);
      if (
        paymentAuthorisationDetails.authorisation.purpose === PaymentAuthorisationPurpose.AuthoriseRules &&
        setPWStep
      ) {
        setPWStep(PaymentWidgetPaymentSteps.APPROVE_AUTHORISATION_RULES);
      } else {
        if (paymentAuthorisationDetails.authorisation.status === AuthorisationStatus.Complete) {
          const {invoicePaymentAllocationID, supplierID, facilityGUID, paymentGUID} =
            paymentAuthorisationDetails.authorisation.data.merchantPayment ?? {};
          const {paymentBatchID} = paymentAuthorisationDetails.authorisation.data.paymentBatch ?? {};
          const paymentStatusRes = await checkStatus({
            //PSBL payments
            invoicePaymentAllocationID,
            supplierID,

            //SpendaFinance
            facilityGUID,
            paymentGUID,

            // AP payments
            paymentBatchID,
          });
          setPaymentStatusRes(paymentStatusRes);
          return;
        }
        setStep(AuthorisePaymentSteps.PAYMENT_INFO);
      }
    }
  };

  useEffect(() => {
    if (!paymentStatusRes) return;
    if (paymentStatusRes?.Value?.ScheduledDateTimeUtc) setPaymentStatusCount(0);
    switch (paymentStatusRes.Value.WorkflowStatus) {
      case InvoicePaymentAllocationStatus.Complete:
      case InvoicePaymentAllocationStatus.Paid:
      case InvoicePaymentAllocationStatus.Cleared:
        setPaymentStatusCount(0);
        return;
      case InvoicePaymentAllocationStatus.Failed:
        setStep(AuthorisePaymentSteps.REJECT_PAYMENT);
        setPaymentStatusCount(0);
        return;
    }
    const interval = setInterval(() => {
      setPaymentStatusCount(prev => prev + 1);

      if (paymentstatusCount > 0 && paymentstatusCount <= 5) {
        checkStatus({
          invoicePaymentAllocationID,
          supplierID,
          facilityGUID,
          paymentGUID,
          paymentBatchID,
        }).then(res => {
          if (!res) return;
          setPaymentStatusRes(res);
        });
      } else {
        clearInterval(interval);
        setPaymentStatusCount(0);
      }
    }, 6000);

    return () => {
      clearInterval(interval);
    };
  }, [paymentStatusRes]);

  const handleCheckPaymentStatus = () => {
    if ((!invoicePaymentAllocationID || !supplierID) && !facilityGUID && !paymentBatchID) return;
    setStep(AuthorisePaymentSteps.PAYMENT_PROCESSING);
    checkStatus({
      paymentGUID,
      facilityGUID,
      invoicePaymentAllocationID,
      supplierID,
      paymentBatchID,
    });
  };

  const handleAuthorisePayment = async () => {
    setStep(AuthorisePaymentSteps.AUTHORISE_PAYMENT);
    // if user already got security code and refreshed the screen:- no need to call the api again to generate security code
    if (paymentAuthorisationDetails?.status === AuthorisationStatus.AwaitingCode && approvePaymentAuthorisation) {
      setStep(AuthorisePaymentSteps.ENTER_OTP);
    } else if (approvePaymentAuthorisation) {
      const authorisePaymentResponse = await approvePaymentAuthorisation({approvalCode: null!}, authorisationID);
      if (authorisePaymentResponse?.authorisation.status === AuthorisationStatus.AwaitingCode) {
        setStep(AuthorisePaymentSteps.ENTER_OTP);
      } else if (isFinalAuthoriser) {
        setPaymentStatusCount(1);
        handleCheckPaymentStatus();
      }
      setPaymentAuthorisationDetails?.(authorisePaymentResponse.authorisation);
    }
  };

  const handleResetSecurityCode = async () => {
    if (paymentAuthorisationDetails?.authorisationID && resetAuthenticationCode) {
      const resetAuthenticationCodeResponse = await resetAuthenticationCode(
        paymentAuthorisationDetails?.authorisationID,
      );
      Toast.info(`Successfully sent a new securitity code.`);
      setPaymentAuthorisationDetails?.(resetAuthenticationCodeResponse?.authorisation);
      handleChangeSecurityCode('');
    }
  };

  const verifySecurityCode = async () => {
    if (isAtemptsExhausted) {
      showChatWindow();
      return;
    }
    if (approvePaymentAuthorisation) {
      const authorisePaymentResponse = await approvePaymentAuthorisation(
        {approvalCode: securityCode!},
        authorisationID,
      );
      if (
        authorisePaymentResponse?.authorisation.status !== AuthorisationStatus.AwaitingCode &&
        isFinalAuthoriser &&
        !authorisePaymentResponse?.authorisation.data.paymentBatch
      ) {
        setPaymentStatusCount(1);
        handleCheckPaymentStatus();
      }
      setPaymentAuthorisationDetails?.(authorisePaymentResponse.authorisation);
      const errors = authorisePaymentResponse?.error?.errors;
      if (errors.length) {
        const erroMsg = getAuthenticationCodeErrorMsg(authorisePaymentResponse);
        setSecuityCodeErrorMsg(erroMsg);
      }
    }
  };

  const handleRejectAuthorisation = async () => {
    if (step === AuthorisePaymentSteps.PAYMENT_INFO) {
      setStep(AuthorisePaymentSteps.REJECT_PAYMENT);
    } else if (rejectPaymentAuthorisation && step === AuthorisePaymentSteps.REJECT_PAYMENT) {
      const rejectAuthorisationResponse = await rejectPaymentAuthorisation(
        {rejectionReason: rejectionMsg},
        authorisationID,
      );
      setPaymentAuthorisationDetails?.(rejectAuthorisationResponse.authorisation);
    }
  };

  const handleHome = () => {
    if (isAccountsPayable) {
      onCancel && onCancel();
    } else {
      history.push(`/menu`);
    }
  };

  const PaymentInfo = () => {
    return (
      <div className="px-7">
        <h1 className="text-xxl mt-7 py-5 font-light">Authorise payment</h1>
        <p className="px-8 pb-5 text-center text-xs">
          Authorise a payment from <br /> {tenantName} for
        </p>
        {/* <div className="flex flex-col justify-around" style={{height: '374px'}}> */}
        {paymentAuthorisationDetails?.data.paymentAmount && (
          <h2 className="text-3xl font-light">
            {PriceFormat(parseFloat(paymentAuthorisationDetails?.data.paymentAmount?.toFixed(2)))}
          </h2>
        )}
        <div className="pb-3">
          <Link
            to={{
              pathname: `/supplier/${supplierID}/payment-history`,
              search: `txDatType=79&txId=${invoicePaymentAllocationID}`,
              state: {isGoBack: true},
            }}
            className="cursor-pointer text-left font-poppins text-xs font-normal leading-loose text-primary underline"
          >
            View Details
          </Link>
        </div>
        <div>
          <h3 className={`${classes.listHeader} text-xs`}>Payment method</h3>
          <p className={`${classes.listBody} text-xs`}>{paymentAuthorisationDetails?.data.paymentAccountDescription}</p>
        </div>
        <div>
          <h3 className={`${classes.listHeader} text-xs`}>Recipent</h3>
          <p className={`${classes.listBody} text-xs`}>{paymentAuthorisationDetails?.data.recipientName}</p>
        </div>
        <div>
          <h3 className={`${classes.listHeader} text-xs`}>Requested by</h3>
          <p className={`${classes.listBody} text-xs`}>
            {paymentAuthorisationDetails?.requestedBy} <br />
            <span className={`${classes.subBody} text-xs`}>
              {moment.utc(paymentAuthorisationDetails?.createdDateTime_utc).local().format(`Do MMMM YYYY, h.mm a`)}
            </span>
          </p>
        </div>
      </div>
    );
  };

  const isPaymentStatusRejected = [
    InvoicePaymentAllocationStatus.Failed,
    InvoicePaymentAllocationStatus.Cancelled,
    InvoicePaymentAllocationStatus.Declined,
  ].includes(paymentStatusRes?.Value.WorkflowStatus!);

  const paymentInfoScreen =
    step == AuthorisePaymentSteps.PAYMENT_INFO &&
    (paymentAuthorisationDetails?.status === AuthorisationStatus.InProgress ||
      paymentAuthorisationDetails?.status === AuthorisationStatus.AwaitingCode);

  const otpScreen =
    step == AuthorisePaymentSteps.ENTER_OTP && paymentAuthorisationDetails?.status === AuthorisationStatus.AwaitingCode;

  const paymentSubmittedScreen =
    paymentstatusCount === 0 &&
    !isPaymentStatusRejected &&
    (paymentAuthorisationDetails?.status === AuthorisationStatus.Approved ||
      paymentAuthorisationDetails?.status === AuthorisationStatus.Complete);

  const rejectAuthorisationScreen =
    paymentstatusCount === 0 &&
    (isPaymentStatusRejected ||
      step == AuthorisePaymentSteps.REJECT_PAYMENT ||
      paymentAuthorisationDetails?.status === AuthorisationStatus.Rejected ||
      paymentAuthorisationDetails?.status === AuthorisationStatus.Failed);

  const paymentStatusProcessingStatusScreen =
    step === AuthorisePaymentSteps.PAYMENT_PROCESSING && paymentstatusCount !== 0;

  const body = (
    <div className="font-poppins">
      {paymentInfoScreen && <PaymentInfo />}
      {otpScreen && (
        <EnterSecurityCode
          securityCode={securityCode}
          setSecurityCode={code => handleChangeSecurityCode(code)}
          errorMsg={securityCodeErrorMsg}
          handleResetSecurityCode={handleResetSecurityCode}
          paymentAuthorisationDetails={paymentAuthorisationDetails!}
          onEnterVerificationCode={verifySecurityCode}
        />
      )}
      {paymentSubmittedScreen && (
        <PaymentSubmitted
          paymentAuthorisationDetails={paymentAuthorisationDetails!}
          authorisersList={authorisersList}
          paymentStatusRes={paymentStatusRes?.Value}
          step={step}
        />
      )}
      {rejectAuthorisationScreen && (
        <RejectPayment
          paymentAuthorisationDetails={paymentAuthorisationDetails!}
          rejectionMsg={rejectionMsg}
          setRejectionMsg={setRejectionMsg}
          authorisersList={authorisersList}
          step={step!}
          isPaymentStatusRejected={isPaymentStatusRejected}
        />
      )}
      {paymentStatusProcessingStatusScreen && (
        <>
          <div className="mb-6 mt-10 flex justify-center p-2">
            <ProcessingAnimation width="114px" height="114px" />
          </div>
          <h1 className={`pt-1 font-poppins text-xl text-primary`}>Payment Processing</h1>
          <p className={`h-24 px-6 pt-3 font-poppins text-xs text-spenda-greytext`}>
            Please wait while your payment gets processed. Do not press the back button on your browser or leave this
            page.
          </p>
          <div className={`${classes.dividerStyle}`} />
          <PaymentBreakDown paymentAuthorisationDetails={paymentAuthorisationDetails!} />
        </>
      )}
    </div>
  );

  const footer = (
    <>
      {paymentInfoScreen && (
        <>
          <DestructiveButton label="Reject" width="90px" onClick={handleRejectAuthorisation} />
          <div className="mx-1">
            <SecondaryButton label="Cancel" width="90px" onClick={handleHome} />
          </div>
          <PositiveButton label="Authorise" width="90px" onClick={handleAuthorisePayment} />
        </>
      )}
      {otpScreen && (
        <>
          <SecondaryButton label="Cancel" onClick={() => setStep(AuthorisePaymentSteps.PAYMENT_INFO)} />
          <PrimaryButton
            label={isAtemptsExhausted ? 'Support' : 'Verify'}
            onClick={verifySecurityCode}
            disabled={securityCode.trim().length < 6 || (!zeroNewCode && zeroCodeAttempts)}
          />
        </>
      )}
      {paymentSubmittedScreen && (
        <div className="w-full">
          <PrimaryButton label="Done" onClick={isAccountsPayable ? onDone : handleHome} />
        </div>
      )}
      {paymentStatusProcessingStatusScreen && (
        <div className="w-full">
          <SecondaryButton label="Cancel" disabled />
        </div>
      )}
      {step == AuthorisePaymentSteps.REJECT_PAYMENT &&
        paymentAuthorisationDetails?.status !== AuthorisationStatus.Rejected &&
        !isPaymentStatusRejected && (
          <>
            <SecondaryButton label="Cancel" onClick={() => setStep(AuthorisePaymentSteps.PAYMENT_INFO)} />
            <PrimaryButton label="Reject" onClick={handleRejectAuthorisation} />
          </>
        )}
      {(paymentAuthorisationDetails?.status === AuthorisationStatus.Rejected ||
        isPaymentStatusRejected ||
        paymentAuthorisationDetails?.status === AuthorisationStatus.Failed) && (
        <div className="w-full">
          <PrimaryButton label="Done" onClick={isAccountsPayable ? onDone : handleHome} />
        </div>
      )}
    </>
  );

  return <PaymentWidgetStep body={body} footer={footer} />;
};

const PaymentSubmitted = (props: {
  paymentAuthorisationDetails: PaymentAuthorisationDetails;
  authorisersList: Authorisers[];
  paymentStatusRes?: ISupplierPaymentResponse;
  step?: AuthorisePaymentSteps;
}) => {
  const classes = useStyles();
  const {paymentAuthorisationDetails, authorisersList, paymentStatusRes, step} = props;
  const status = paymentAuthorisationDetails?.status;
  const nextAuthorisers = authorisersList?.filter(a => a.status === AuthorisationStatus.InProgress);
  return (
    <>
      <div className="mb-6 mt-10 flex justify-center p-2">
        {status === AuthorisationStatus.Approved ? (
          <IconPaymentSubmit width="83px" height="86px" />
        ) : (
          <img
            alt="PaymentSuccessful"
            style={{width: '83px', height: '86px'}}
            data-autoid="imgPaymentSuccessful"
            src={PaymentSuccessful}
          />
        )}
      </div>
      <h1 className="text-xxl text-primary">
        {`Payment ${
          step === AuthorisePaymentSteps.AUTHORISE_PAYMENT
            ? 'authorised'
            : paymentStatusRes?.WorkflowStatus === InvoicePaymentAllocationStatus.InProgress
              ? 'submitted'
              : 'successful'
        }`}
      </h1>
      <p className="my-1 flex h-24 flex-col justify-center px-5 text-xs ">
        Your payment has been successfully{' '}
        {status === AuthorisationStatus.Approved ? (
          <p className="leading-normal">
            authorised, we are waiting for:
            <div className={nextAuthorisers.length > 5 ? 'flex flex-wrap' : ''}>
              {nextAuthorisers?.map((a, i) => (
                <div key={a.userID} className={`${nextAuthorisers.length > 5 ? 'w-6/12 px-1' : ''}`}>
                  <p className="font-bold" key={i}>
                    {a.name}
                  </p>
                </div>
              ))}
            </div>
            for final authorisation.
          </p>
        ) : paymentStatusRes?.WorkflowStatus === InvoicePaymentAllocationStatus.InProgress ? (
          `submitted.`
        ) : (
          `processed.`
        )}
      </p>
      <div className={` ${classes.dividerStyle}`} />
      {status === AuthorisationStatus.Approved || step !== AuthorisePaymentSteps.AUTHORISE_PAYMENT ? (
        <DetailedPaymentBreakDown paymentAuthorisationDetails={paymentAuthorisationDetails} />
      ) : (
        <PaymentBreakDown paymentAuthorisationDetails={paymentAuthorisationDetails} />
      )}
    </>
  );
};

const RejectPayment = (props: {
  paymentAuthorisationDetails: PaymentAuthorisationDetails;
  rejectionMsg: string;
  setRejectionMsg: (rejectionMsg: string) => void;
  authorisersList?: Authorisers[];
  step: AuthorisePaymentSteps;
  isPaymentStatusRejected: boolean;
}) => {
  const {paymentAuthorisationDetails, rejectionMsg, setRejectionMsg, authorisersList, step, isPaymentStatusRejected} =
    props;
  const status = paymentAuthorisationDetails?.status;
  const classes = useStyles();
  const rejectionReason = authorisersList?.find(a => a.rejectionReason)?.rejectionReason;
  return (
    <>
      <div className="mb-5 mt-16 flex justify-center p-1">
        <img
          alt="FailedPayment"
          data-autoid="imgFailedPayment"
          style={{width: '100px', height: '75px'}}
          src={PaymentDeclined}
        />
      </div>

      <h1 className="text-xxl text-spenda-error">
        {isPaymentStatusRejected
          ? `Payment declined`
          : status === AuthorisationStatus.Failed
            ? `Payment failed`
            : status === AuthorisationStatus.InProgress
              ? `Reject payment`
              : `Rejected payment`}
      </h1>
      {status !== AuthorisationStatus.Rejected && status !== AuthorisationStatus.Failed && !isPaymentStatusRejected ? (
        <>
          <div className="center flex h-32 items-center justify-center px-6 text-xs">
            <p>
              This message will be sent to:
              <br />
              {paymentAuthorisationDetails?.requestedBy}
              {authorisersList
                ?.filter(
                  a =>
                    a.name !== paymentAuthorisationDetails?.requestedBy && a.status !== AuthorisationStatus.InProgress,
                )
                .map(a => {
                  return (
                    <>
                      <br />
                      {a.name}
                    </>
                  );
                })}
              <br />
            </p>
          </div>
          <div className="mx-8">
            <STextField
              fullWidth
              id="rejectionReason"
              name="rejectionReason"
              variant="outlined"
              label="Why is the request rejected?"
              rows={10}
              multiline
              className={`${classes.rejectReasonBody}`}
              data-autoid="txtRejectionReason"
              inputProps={{style: {fontFamily: 'Poppins'}}}
              autoComplete="off"
              value={rejectionMsg}
              onChange={e => setRejectionMsg(e.target.value)}
              onBlur={() => {}}
              helperText={''}
              error={Boolean(false)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        </>
      ) : (
        <>
          <div className="center flex h-24 flex-col items-center  justify-center px-6 text-xs">
            <p className="pb-2">
              {isPaymentStatusRejected
                ? "Sorry, your payment couldn't be processed at this time"
                : status === AuthorisationStatus.Failed
                  ? 'Your payment has been failed'
                  : 'Your payment has been rejected'}
            </p>
            {rejectionReason ? <p>"{rejectionReason}"</p> : null}
          </div>
          <div className={`${classes.dividerStyle}`} />
          {step === AuthorisePaymentSteps.REJECT_PAYMENT ? (
            <PaymentBreakDown paymentAuthorisationDetails={paymentAuthorisationDetails} />
          ) : (
            <DetailedPaymentBreakDown paymentAuthorisationDetails={paymentAuthorisationDetails} />
          )}
        </>
      )}
    </>
  );
};

const PaymentBreakDown = (props: {paymentAuthorisationDetails: PaymentAuthorisationDetails}) => {
  const {data} = props?.paymentAuthorisationDetails ?? {};

  return (
    <div className="px-5 py-5 text-xs">
      <h3 className="pb-3">Payment Breakdown</h3>
      <div className="flex justify-between pb-2">
        <span>Amount to pay</span>
        <span data-autoid="txtPaymentAmount">{PriceFormat(parseFloat(data?.paymentAmount?.toFixed(2)))}</span>
      </div>
      {data?.transactionFeeAmount ? (
        <div className="flex justify-between pb-2">
          <span data-autoid="txtTransactionFeeName">
            {data?.feeName ? `${data?.feeName} (${data?.payerFeeDescription})` : `Transaction Fee`}
          </span>
          <span data-autoid="txtTransactionFeeAmount">
            {PriceFormat(parseFloat(data?.transactionFeeAmount?.toFixed(2)))}
          </span>
        </div>
      ) : null}
      <div className="flex justify-between pb-2 font-bold text-spenda-sGreen">
        <span>Total Amount</span>
        <span data-autoid="txtTotalAmount">{PriceFormat(parseFloat(data?.totalAmount?.toFixed(2)))}</span>
      </div>
    </div>
  );
};

const DetailedPaymentBreakDown = (props: {paymentAuthorisationDetails: PaymentAuthorisationDetails}) => {
  const {data, authorisers} = props?.paymentAuthorisationDetails ?? {};

  const approvedAuths = authorisers.filter(a => a.status === AuthorisationStatus.Approved);
  const rejectedauths = authorisers.filter(a => a.status === AuthorisationStatus.Rejected);

  return (
    <div className="px-5 py-5 text-xs">
      <h3 className="pb-3">Payment confirmation</h3>
      <div className="flex justify-between pb-2">
        <span>Amount to pay</span>
        <span data-autoid="lblAmountToPay">{PriceFormat(parseFloat(data.paymentAmount?.toFixed(2)))}</span>
      </div>
      <div className="flex justify-between pb-2">
        <span>Payment method</span>
        <span data-autoid="lblPaymentMethod">{data.paymentAccountDescription}</span>
      </div>
      <div className="flex justify-between pb-2">
        <span>Recipent</span>
        <span data-autoid="lblRecipientName">{data.recipientName}</span>
      </div>
      <div className="flex justify-between pb-2">
        <span>Ref number</span>
        <span data-autoid="lblRefnum">{data.refNumber}</span>
      </div>
      {approvedAuths.length ? (
        <div className="flex justify-between pb-2">
          <span>Authorised by</span>
          <span>
            {approvedAuths.map((a, idx) => (
              <p className="text-end" key={a.userID}>{`${idx === 1 ? 'and ' : ''}${a.firstName} ${a.lastName}`}</p>
            ))}
          </span>
        </div>
      ) : null}
      {rejectedauths.length ? (
        <div className="flex justify-between pb-2">
          <span>Rejected by</span>
          <div>
            {rejectedauths.map((a, idx) => (
              <div key={a.userID}>{`${idx === 1 ? 'and ' : ''}${a.firstName} ${a.lastName}`}</div>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};
