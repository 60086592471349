import {css} from 'glamor';
import React from 'react';

const SBadge = ({
  children,
  color,
  backgroundColor,
}: {
  children: React.ReactNode;
  color: string;
  backgroundColor: string;
}) => (
  <span
    {...css({color, backgroundColor})}
    className="text-xs font-medium px-3 py-1 rounded-sm inline-block cursor-default min-w-[96px]"
  >
    {children}
  </span>
);

export default SBadge;
