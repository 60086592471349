import React, {useEffect} from 'react';
import {Formik, FormikProps} from 'formik';
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography,
  Checkbox,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUncheckedOutlined';
import RadioButtonCheckedOutlinedIcon from '@material-ui/icons/RadioButtonCheckedOutlined';
import * as Yup from 'yup';

import {SDialog} from '../modals/modalSpendaMeterialUI';
import {IconBankWithoutOutline} from '../../assets/svg/payment-methods/IconBankWithoutOutline';
import {STextField} from '../inputs/STextField';
import {IAddBankAccountDetailsRequest} from '../../services/useSpendaPaymentServicesAPI';
import {SButton} from '../../components/buttons/Button';
import {StripNonNumericCharacters} from '../../utils/formatter';

const validationSchema = Yup.object({
  BSBNumber: Yup.string()
    .required('BSB Number is Required')
    .typeError('Please enter valid BSB Number')
    .test('len', 'Please enter valid BSB Number', val => val?.toString()?.trim()?.length === 6),
  AccountNumber: Yup.string()
    .required('Account Number is Required')
    .typeError('Please enter valid Account Number')
    .test('len', 'Please enter valid Account Number', val => (val?.toString()?.trim()?.length || 0) < 10),
  TsAndCsZeptoDirectDebitRequestAndServiceAgreementAgreedTo: Yup.boolean().oneOf(
    [true],
    `Please read and agree to Zepto's Direct Debit Request and Service Agreement to proceed.`,
  ),
});

const initialValues = {
  businessName: '',
  friendlyName: '',
  BSBNumber: '',
  AccountNumber: '',
  email: '',
  TsAndCsZeptoDirectDebitRequestAndServiceAgreementAgreedTo: false,
};

const useStyles = makeStyles({
  dialog: {
    alignItems: 'center',
    maxWidth: 380,
    minWidth: 380,
    minHeight: 650,
    maxHeight: 700,
    borderRadius: 22,
  },
  dialogTitle: {
    textAlign: 'center',
    fontSize: '1.4rem',
    fontWeight: 600,
  },
  dialogContent: {
    width: '100%',
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    padding: '10px 20px',
    borderTop: '1px solid #ede0e0',
  },
  textField: {
    paddingTop: '.5rem',
    '& .MuiInputBase-root': {
      color: 'black',
    },
    '& .MuiFormLabel-root': {
      color: 'black',
    },
    '& .MuiInput-underline': {
      borderColor: 'red',
    },
    '& .Mui-error': {
      color: 'red',
    },
  },
  iconRoot: {
    color: '#BBB',
    fontSize: '1.25rem',
    '& .MuiSvgIcon-root': {
      color: '#BBB !important',
    },
  },
  crossButton: {
    width: 'auto !important',
  },
  bankTextField: {
    marginBottom: '12px',
    position: 'relative',
    '& .MuiOutlinedInput-input:-webkit-autofill': {
      '-webkit-box-shadow': '0 0 0 100px #fff inset !important',
    },
    '& .MuiInputAdornment-root': {
      display: 'none',
    },
    '& .MuiOutlinedInput-root': {
      padding: '3px 12px',
      height: '100%',
      '&.Mui-focused': {
        '& .MuiInputAdornment-root': {
          display: 'contents',
        },
      },
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 16px) scale(1)',
      fontFamily: 'Poppins !important',
      fontSize: '14px',
      fontWeight: 500,
      width: 'auto',
      color: '#bbbbbb !important',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -5px) scale(1)',
      fontSize: '10px ',
    },
    '& textarea, input': {
      padding: '0px',
      fontFamily: 'Poppins !important',
      fontSize: '14px',
    },
    '& .MuiFormHelperText-root.Mui-error': {
      fontFamily: 'Poppins !important',
      position: 'relative !important',
      top: '0px !important',
    },
    '& .MuiInputAdornment-positionEnd button': {
      padding: '0px !important',
    },
  },
  radioButton: {
    '& .MuiCheckbox-colorSecondary.Mui-checked': {
      color: '#1C78AD',
    },
    '&.MuiCheckbox-root': {
      color: '#1C78AD',
    },
  },
});

interface IAddBankAccountDialogProps {
  onCloseAddBankAccount: () => void;
  onSubmitAddBankAccount: (payload: IAddBankAccountDetailsRequest) => void;
  isLoading: boolean;
  userName: string;
  tenantName: string;
  dialogTitle?: () => JSX.Element;
}

type AddBankAccountFormikFormProps = Omit<
  IAddBankAccountDetailsRequest,
  'TsAndCsZeptoDirectDebitRequestURLAgreedTo' | 'TsAndCsZeptoDirectDebitRequestServiceAgreementURLAgreedTo'
>;

export const AddBankAccountDialog = ({
  onCloseAddBankAccount,
  onSubmitAddBankAccount,
  isLoading,
  tenantName,
  userName,
  dialogTitle,
}: IAddBankAccountDialogProps) => {
  const classes = useStyles();

  return (
    <SDialog open maxWidth="sm" fullWidth classes={{paper: classes.dialog}} disableBackdropClick={true}>
      <DialogTitle className="flex w-full max-w-full flex-col bg-white">
        {dialogTitle && dialogTitle()}
        <Typography className={`${classes.dialogTitle} m-3 font-poppins text-xl`}>
          {' '}
          Enter your Bank Account Details
        </Typography>
      </DialogTitle>
      <DialogContent className={'w-full'}>
        <AddBankAccountForm onSubmit={onSubmitAddBankAccount} userName={userName} tenantName={tenantName} />
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Footer isLoading={isLoading} onClose={onCloseAddBankAccount} />
      </DialogActions>
    </SDialog>
  );
};

interface AddBankAccountFormProps {
  onSubmit: (payload: IAddBankAccountDetailsRequest) => void;
  userName: string;
  tenantName: string;
}

export const AddBankAccountForm = ({onSubmit, tenantName, userName}: AddBankAccountFormProps) => {
  useEffect(() => {
    initialValues.businessName = tenantName ?? '';
    initialValues.email = userName ?? '';
  }, []);
  const classes = useStyles();
  const handleSubmit = (values: AddBankAccountFormikFormProps) => {
    if (!values.TsAndCsZeptoDirectDebitRequestAndServiceAgreementAgreedTo) return;

    const payload: IAddBankAccountDetailsRequest = {
      ...values,
      TsAndCsZeptoDirectDebitRequestURLAgreedTo: 'https://www.zepto.com.au/ddr',
      TsAndCsZeptoDirectDebitRequestServiceAgreementURLAgreedTo: 'https://www.zepto.com.au/ddrsa',
    };
    onSubmit(payload);
  };

  const redirectToLink = (url: string) => {
    window.open(url, '_blank');
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        values,
        setFieldValue,
        handleSubmit,
        handleChange,
        touched,
        errors,
        handleBlur,
      }: FormikProps<AddBankAccountFormikFormProps>) => (
        <form onSubmit={handleSubmit} id="add-bank-account-form">
          <Box className="m-6 flex justify-center">
            <IconBankWithoutOutline width="74.5px" height="74.49px" />
          </Box>
          <Box className={`py-2 font-poppins`}>
            <Box className={`${classes.bankTextField} font-poppins`}>
              <STextField
                size="small"
                id="friendlyName"
                name="friendlyName"
                helperText={touched.friendlyName ? errors.friendlyName : ''}
                error={touched.friendlyName && Boolean(errors.friendlyName)}
                autoComplete="off"
                value={values.friendlyName}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                label="Nickname"
                variant="outlined"
                backgroundColor="#FFF"
                borderColor="#333"
                labelColor={touched.friendlyName && Boolean(errors?.friendlyName) ? '#FF0000' : '#333'}
                InputProps={{
                  style: {fontFamily: 'Poppins', fontSize: '10px'},
                  endAdornment: values?.friendlyName?.length ? (
                    <InputAdornment position="end">
                      <IconButton
                        className={classes.crossButton}
                        data-autoid="clearFriendlyName"
                        onClick={() => setFieldValue('friendlyName', '')}
                        onMouseDown={e => e.preventDefault()}
                      >
                        <ClearRoundedIcon className={`${classes.iconRoot} `} />
                      </IconButton>
                    </InputAdornment>
                  ) : (
                    ''
                  ),
                }}
              />
            </Box>
            <Box className={`${classes.bankTextField} font-poppins`}>
              <STextField
                size="small"
                id="BSBNumber"
                name="BSBNumber"
                error={touched.BSBNumber && Boolean(errors.BSBNumber)}
                autoComplete="off"
                value={values.BSBNumber}
                onChange={e => setFieldValue('BSBNumber', StripNonNumericCharacters(e.target.value))}
                onBlur={handleBlur}
                fullWidth
                label={(touched.BSBNumber && errors?.BSBNumber) ?? 'BSB Number'}
                labelColor={touched.BSBNumber && Boolean(errors?.BSBNumber) ? '#FF0000' : '#333'}
                variant="outlined"
                backgroundColor="#FFF"
                borderColor="#333"
                InputLabelProps={{
                  shrink: Boolean((touched.BSBNumber && errors.BSBNumber) || values?.BSBNumber),
                }}
                InputProps={{
                  style: {fontFamily: 'Poppins', fontSize: '10px'},
                  endAdornment: values?.BSBNumber ? (
                    <InputAdornment position="end">
                      <IconButton
                        className={classes.crossButton}
                        data-autoid="clearBSBNumber"
                        onClick={() => setFieldValue('BSBNumber', '')}
                        onMouseDown={e => e.preventDefault()}
                      >
                        <ClearRoundedIcon className={`${classes.iconRoot} `} />
                      </IconButton>
                    </InputAdornment>
                  ) : (
                    ''
                  ),
                }}
              />
            </Box>
            <Box className={`${classes.bankTextField} font-poppins`}>
              <STextField
                size="small"
                id="AccountNumber"
                name="AccountNumber"
                error={touched.AccountNumber && Boolean(errors.AccountNumber)}
                autoComplete="off"
                value={values.AccountNumber}
                onChange={e => setFieldValue('AccountNumber', StripNonNumericCharacters(e.target.value))}
                onBlur={handleBlur}
                fullWidth
                label={(touched.AccountNumber && errors?.AccountNumber) ?? 'Account Number'}
                labelColor={touched.AccountNumber && Boolean(errors?.AccountNumber) ? '#FF0000' : '#333'}
                variant="outlined"
                backgroundColor="#FFF"
                borderColor="#333"
                InputLabelProps={{
                  shrink: Boolean((touched.AccountNumber && errors.AccountNumber) || values?.AccountNumber),
                }}
                InputProps={{
                  style: {fontFamily: 'Poppins', fontSize: '10px'},
                  endAdornment: values?.AccountNumber ? (
                    <InputAdornment position="end">
                      <IconButton
                        className={classes.crossButton}
                        data-autoid="clearAccountNumber"
                        onClick={() => setFieldValue('AccountNumber', '')}
                        onMouseDown={e => e.preventDefault()}
                      >
                        <ClearRoundedIcon className={`${classes.iconRoot} `} />
                      </IconButton>
                    </InputAdornment>
                  ) : (
                    ''
                  ),
                }}
              />
            </Box>

            <Box className="flex items-start" fontSize="10px" alignItems="center">
              <Checkbox
                data-autoid="chkZeptoAgreement"
                id="TsAndCsZeptoDirectDebitRequestAndServiceAgreementAgreedTo"
                name="TsAndCsZeptoDirectDebitRequestAndServiceAgreementAgreedTo"
                checked={values.TsAndCsZeptoDirectDebitRequestAndServiceAgreementAgreedTo}
                onChange={e =>
                  setFieldValue('TsAndCsZeptoDirectDebitRequestAndServiceAgreementAgreedTo', e.target.checked)
                }
                className={classes.radioButton}
                onBlur={handleBlur}
                icon={<RadioButtonUncheckedOutlinedIcon />}
                checkedIcon={<RadioButtonCheckedOutlinedIcon />}
              />
              <Box>
                I have read and agree to the{' '}
                <span
                  onClick={() => redirectToLink('https://www.zepto.com.au/ddr/')}
                  className="cursor-pointer text-primary"
                >
                  Direct Debit Request
                </span>{' '}
                and{' '}
                <span
                  onClick={() => redirectToLink('http://www.zepto.com.au/ddrsa')}
                  className="cursor-pointer text-primary"
                >
                  Direct Debit Service Agreement{' '}
                </span>
              </Box>
            </Box>
            {errors.TsAndCsZeptoDirectDebitRequestAndServiceAgreementAgreedTo &&
              touched.TsAndCsZeptoDirectDebitRequestAndServiceAgreementAgreedTo && (
                <Typography color="error">
                  <p className="px-2 text-center font-poppins text-xs">
                    {errors.TsAndCsZeptoDirectDebitRequestAndServiceAgreementAgreedTo}
                  </p>
                </Typography>
              )}
          </Box>
        </form>
      )}
    </Formik>
  );
};

interface IFooterProps {
  isLoading?: boolean;
  onClose?: () => void;
}
export const Footer = ({isLoading, onClose}: IFooterProps) => {
  return (
    <>
      <SButton
        height="40px"
        fontFamily="poppins"
        color="white"
        width="100px"
        textColor="blue"
        type="button"
        border="1px solid #3977a8"
        borderRadius="6px"
        onClick={onClose ?? undefined}
        disabled={isLoading}
      >
        Cancel
      </SButton>
      <SButton
        width="100px"
        height="40px"
        borderRadius="6px"
        fontSize="0.875rem"
        fontFamily="Poppins"
        type="submit"
        form="add-bank-account-form"
        color="darkBlue"
        disabled={isLoading}
        isSubmitting={isLoading}
      >
        Save
      </SButton>
    </>
  );
};
