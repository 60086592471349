import React, {useMemo} from 'react';
import {useParams, useLocation, matchPath} from 'react-router-dom';

import {AUTH_PURCHASING_ONBOARDING} from '../../routes/PurchasingRoutes';
import APOnboardingComponent from '../../components/accountsPayableOnboarding/APOnboarding';

const APOnboarding = () => {
  const {onboardingAccount}: any = useParams();
  const location = useLocation();

  const isPurchasingOnboarding = useMemo(
    () =>
      Boolean(
        matchPath(location.pathname, {
          path: AUTH_PURCHASING_ONBOARDING,
        })
      ),
    [location.pathname]
  );

  return (
    <APOnboardingComponent
      isPurchasingOnboarding={isPurchasingOnboarding}
      onboardingAccount={onboardingAccount}
      onBoardingAccountRedirectUrl={onboardingAccount ? window.location.href : ''}
    />
  );
};

export default APOnboarding;
