import React from 'react';
import {css} from 'glamor';
import {PriceFormat} from '../../utils/formatter';
import {ITxDeduction} from '../../model/payment/PaymentT';
import {PaymentSummary} from './PaymentSummary';
import {DatTypes, PaymentMethodTypes, TransactionSubType} from '../../model/constants/Constants';
import {ICustomerTrans} from '../../model/invoice/Invoice';
import {ITenantSupplierPaymentMethod} from '../../model/payment/PaymentMethod';
import currency from 'currency.js';

interface ITransactionTotal {
  subtotal?: number;
  tax?: number;
  total?: number;
  position?: 'left' | 'right';
  deductions?: ITxDeduction[];
  onClickPayment?: (paymentOrCredit: ITxDeduction) => void;
  tx?: ICustomerTrans;
  paymentMethods?: ITenantSupplierPaymentMethod[];
}

const TransactionTotal = (props: ITransactionTotal) => {
  const isLeftPositioned = props.position && props.position === 'left';
  const deductions = props.deductions || [];

  const isBankTransfer = props.paymentMethods?.find(bt => bt.PaymentMethod === PaymentMethodTypes.BankTransfer);

  const alignment = css({
    textAlign: isLeftPositioned ? 'left' : 'right',
  });

  const isPayment = props.tx?.DatTypeID === DatTypes.Payment;
  const startingNumber = 0;
  const appliedAmount =
    props.tx?.RefBusTrans?.reduce((currentTotal, currentItem) => {
      return (
        currentTotal +
        (currentItem.TransSubType === TransactionSubType.Invoice || currentItem.TransSubType === null
          ? currentItem.AppliedAmount
          : 0)
      );
    }, startingNumber) || 0;
  const surchargeTotal =
    props.tx?.RefBusTrans?.reduce((currentTotal, currentItem) => {
      return (
        currentTotal +
        (currentItem.TransSubType === TransactionSubType.MerchantSurcharge ? currentItem.AppliedAmount : 0)
      );
    }, startingNumber) || 0;

  const isShowSurcharge = !isBankTransfer && surchargeTotal > 0;
  const processingFee = props.tx?.Fee || 0;
  const isShowProcessingFees = processingFee > 0;
  let subtotal = props.subtotal || 0;
  if (isShowProcessingFees) {
    subtotal = currency(subtotal).add(processingFee).value;
  }

  return (
    <div>
      {isPayment ? (
        <div className={`${alignment} w-full`}>
          <div className="flex">
            <div className="flex-1">
              <p>Total Applied</p>
            </div>
            <div style={{flex: '0 1 25%'}}>
              <h3>{PriceFormat(appliedAmount || 0)}</h3>
            </div>
          </div>
          {isShowSurcharge && (
            <div className="flex">
              <div className="flex-1">
                <p>Surcharge</p>
              </div>
              <div style={{flex: '0 1 25%'}}>
                <h3> {PriceFormat(surchargeTotal || 0)}</h3>
              </div>
            </div>
          )}
          {isShowProcessingFees && (
            <div className="flex">
              <div className="flex-1">
                <p>Processing Fee ({props.tx?.PayerFeeDescription})</p>
              </div>
              <div style={{flex: '0 1 25%'}}>
                <h3> {PriceFormat(processingFee)}</h3>
              </div>
            </div>
          )}
          <div className="flex mt-5 mb-3 xl:text-lg font-semibold">
            <div className="flex-1">
              <h3>Payment Total</h3>
            </div>
            <div style={{flex: '0 1 25%'}}>
              <h3>{PriceFormat(subtotal)}</h3>
            </div>
          </div>
          <div>
            <p></p>
          </div>
        </div>
      ) : (
        <div className={`${alignment} w-full`}>
          <div className="flex">
            <div className="flex-1">
              <p>Subtotal</p>
            </div>
            <div style={{flex: '0 1 25%'}}>
              <p>{PriceFormat(props.subtotal || 0)}</p>
            </div>
          </div>
          <div className="flex">
            <div className="flex-1">
              <p>Tax</p>
            </div>
            <div style={{flex: '0 1 25%'}}>
              <p>{PriceFormat(props.tax || 0)}</p>
            </div>
          </div>
          <div className="flex mb-1">
            <div className="flex-1">
              <h3 className="xl:text-lg font-semibold">Total</h3>
            </div>
            <div style={{flex: '0 1 25%'}}>
              <h3>
                <b>{PriceFormat(props.total || 0)}</b>
              </h3>
            </div>
          </div>
          <PaymentSummary onClickPayment={props.onClickPayment} payments={deductions}></PaymentSummary>
        </div>
      )}
    </div>
  );
};

export default TransactionTotal;
