import React from 'react';
const IconLink = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.92214 8.63372L5.29114 10.2647C4.30577 11.2238 2.73273 11.2132 1.7603 10.2411C0.78787 9.26891 0.776886 7.69586 1.73564 6.71022L3.36664 5.07772C3.56188 4.88235 3.56176 4.56571 3.36639 4.37047C3.17102 4.17524 2.85438 4.17535 2.65914 4.37072L1.02864 6.00322C-0.343369 7.37593 -0.34281 9.60096 1.02989 10.973C2.4026 12.345 4.62763 12.3444 5.99964 10.9717L7.63064 9.34072C7.82013 9.14453 7.81742 8.83268 7.62455 8.63981C7.43169 8.44695 7.11983 8.44424 6.92364 8.63372H6.92214Z"
        fill="#3D9F78"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9716 1.03051C10.3144 0.368811 9.41972 -0.00229145 8.48712 1.23372e-05C7.55503 -0.00246372 6.66065 0.367857 6.00312 1.02851L4.36962 2.66001C4.17425 2.85525 4.17414 3.17189 4.36937 3.36726C4.56461 3.56263 4.88125 3.56275 5.07662 3.36751L6.70912 1.73651C7.1793 1.26351 7.81919 0.998296 8.48612 1.00001C9.50275 1.00035 10.4191 1.61294 10.808 2.55222C11.197 3.49151 10.9819 4.57258 10.2631 5.29151L8.63212 6.92251C8.43675 7.11788 8.43675 7.43464 8.63212 7.63001C8.82749 7.82538 9.14425 7.82538 9.33962 7.63001L10.9706 6.00001C12.3407 4.62697 12.3411 2.40411 10.9716 1.03051Z"
        fill="#3D9F78"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.14619 4.1467L4.14619 7.1467C4.01621 7.27224 3.96408 7.45814 4.00984 7.63296C4.0556 7.80777 4.19212 7.94429 4.36693 7.99005C4.54175 8.03581 4.72765 7.98368 4.85319 7.8537L7.85319 4.8537C8.04267 4.65751 8.03996 4.34566 7.8471 4.15279C7.65423 3.95993 7.34238 3.95722 7.14619 4.1467Z"
        fill="#3D9F78"
      />
    </svg>
  );
};

export default IconLink;
