import React from 'react';
import {css} from 'glamor';
import FilterButton from '../form/FilterButton';
import {IProductsFilter} from '../../model/search-filters/ProductsFilter';
import {BrandSelector} from './BrandSelector';
import {IBrand} from '../../model/inventory/Brand';
import SearchForm from '../form/SearchForm';
import {Formik} from 'formik';
import * as Yup from 'yup';

interface IProductsFilterProps {
  clearFilter: () => void;
  totalProductsNumber: number;
  pagedProductsNumber: number;
  brands: IBrand[];
  searchFilter: IProductsFilter;
  selectedBrand: IBrand | undefined;
  updateFilter: (filter: IProductsFilter) => void;
  updateCategoryFilter: (filter: IProductsFilter) => void;
  onSelectedBrand: (brand: IBrand) => void;
}

export type SearchFormValues = {
  onFreeTextSearch?: (event: any) => void | undefined;
  freeTextInput: string;
  searchFilter?: IProductsFilter;
  updateFilter?: (filter: IProductsFilter) => void;
  placeholder?: string;
};

export const ProductsFilter = (props: IProductsFilterProps) => {
  const style = css({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0.5em 0',
  });

  const deselectBrand = () => {
    props.updateFilter({...props.searchFilter, SearchString: ''});
  };

  const validationSchema = Yup.object({
    freeTextInput: Yup.string().trim().min(3, 'Must be at least 3 characters.'),
  });

  const onSubmit = (values: SearchFormValues) => {
    props.updateFilter({
      ...props.searchFilter,
      LastRowNumber: 0,
      SearchString: values.freeTextInput,
      IsFreeTextSearch: true,
    });
  };

  return (
    <div>
      <div>
        <div id="products-filter" className={`${style}`}>
          <BrandSelector
            isShowFavourites={props.searchFilter.IsShowFavourites}
            selectedBrand={props.selectedBrand}
            setSelectedBrand={props.onSelectedBrand}
            deselectBrand={deselectBrand}
            brands={props.brands}
          ></BrandSelector>

          <div style={{marginLeft: '0.5em'}}>
            <Formik
              initialValues={{freeTextInput: '', placeholder: 'Search and hit enter'}}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
            >
              {props => <SearchForm {...props} />}
            </Formik>
          </div>

          <div style={{flex: '1 1 3%', marginLeft: '0.5em', marginRight: '0.5em'}}>
            <FilterButton label={'Clear Filter'} onClick={props.clearFilter}></FilterButton>
          </div>

          <h2 className="bold" style={{flex: '1 1 0%', color: '#1C78AD', textAlign: 'center'}}>
            {props.pagedProductsNumber} of {props.totalProductsNumber} Products
          </h2>
        </div>
      </div>
    </div>
  );
};
