import React from 'react';
import {Typography} from 'spenda-ui-react';

import {ICustomer} from '../../../model/customer/Customer';
import {NameInitials} from '../../../utils/formatter';

interface ICustomerDetailsSection {
  customerDetails?: ICustomer;
}

const CustomerDetailsSection = (props: ICustomerDetailsSection) => {
  const {customerDetails} = props;
  return (
    <div className="mb-7 flex w-full items-center justify-between px-10">
      <div className="flex basis-[45%] items-center justify-start gap-5">
        <div
          className="flex h-[105px] min-w-[105px] items-center justify-center rounded-full bg-warning/10 text-2xl"
          data-autoid={`txtNameInitials-${customerDetails?.RefNumber}`}
        >
          {NameInitials(customerDetails?.PrimaryContactFullName)}
        </div>
        <div>
          <Typography
            variant="h3"
            className="mb-3 font-medium text-black-800"
            data-autoid={`txtCompanyName-${customerDetails?.RefNumber}`}
          >
            {customerDetails?.CompanyName}
          </Typography>
          <Typography
            variant="small"
            className="font-medium text-black-800"
            data-autoid={`txtRefNumber-${customerDetails?.RefNumber}`}
          >
            Ref #: <span className="font-normal">{customerDetails?.RefNumber}</span>
          </Typography>
          <Typography
            variant="small"
            className="font-medium text-black-800"
            data-autoid={`txtPhone1-${customerDetails?.RefNumber}`}
          >
            Phone: <span className="font-normal">{customerDetails?.Phone1}</span>
          </Typography>
          <Typography
            variant="small"
            className="font-medium text-black-800"
            data-autoid={`txtAbn-${customerDetails?.RefNumber}`}
          >
            ABN: <span className="font-normal">{customerDetails?.ABN}</span>
          </Typography>
        </div>
      </div>
      <div className="flex basis-[45%] gap-6 rounded-md bg-spenda-cream pl-5 py-3">
        <div className="basis-[45%]">
          <Typography
            className="mb-4 font-medium text-black-800"
            data-autoid={`txtCustomerName-${customerDetails?.RefNumber}`}
          >
            {customerDetails?.PrimaryContactFullName}
          </Typography>
          <Typography
            variant="small"
            className="font-medium text-black-800"
            data-autoid={`txtPhone-${customerDetails?.RefNumber}`}
          >
            Phone: <span className="font-normal">{customerDetails?.PrimaryContactPhone}</span>
          </Typography>
          <Typography
            variant="small"
            className="font-medium text-black-800"
            data-autoid={`txtEmail-${customerDetails?.RefNumber}`}
          >
            Email: <span className="font-normal">{customerDetails?.PrimaryContactEmailAddress}</span>
          </Typography>
        </div>
        <div className="basis-[55%]">
          <Typography className="mb-4 font-medium text-black-800">Billing Address</Typography>
          <Typography
            variant="small"
            className="w-[68%] text-black-800"
            data-autoid={`txtBillingAddress-${customerDetails?.RefNumber}`}
          >
            {customerDetails?.Locations?.map(loc => {
              return (
                loc.Addresses &&
                loc.Addresses.map(add => {
                  return add.IsDefaultBilling === true && <span> {add.FullAddress}</span>;
                })
              );
            })}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default CustomerDetailsSection;
