import * as React from 'react';
import {SVGProps} from 'react';

export const Calendar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <title>{'Icons/Time/calendar'}</title>
    <g id="Spenda-Pay-Onboarding" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <g id="Spenda-Collect-Supplier-Interface-Copy-23" transform="translate(-1355.000000, -279.000000)" fill="#1C78AD">
        <g id="Icons/Time/calendar" transform="translate(1355.000000, 279.000000)">
          <path
            d="M19,2 L18,2 L18,1 C18,0.44771525 17.5522847,0 17,0 C16.4477153,0 16,0.44771525 16,1 L16,2 L8,2 L8,1 C8,0.44771525 7.55228475,0 7,0 C6.44771525,0 6,0.44771525 6,1 L6,2 L5,2 C2.23994685,2.00330612 0.00330611633,4.23994685 0,7 L0,19 C0.00330611633,21.7600532 2.23994685,23.9966939 5,24 L19,24 C21.7600532,23.9966939 23.9966939,21.7600532 24,19 L24,7 C23.9966939,4.23994685 21.7600532,2.00330612 19,2 Z M2,7 C2,5.34314575 3.34314575,4 5,4 L19,4 C20.6568542,4 22,5.34314575 22,7 L22,8 L2,8 L2,7 Z M19,22 L5,22 C3.34314575,22 2,20.6568542 2,19 L2,10 L22,10 L22,19 C22,20.6568542 20.6568542,22 19,22 Z"
            id="Shape"
          />
          <circle id="Oval" cx={12} cy={15} r={1.5} />
          <circle id="Oval" cx={7} cy={15} r={1.5} />
          <circle id="Oval" cx={17} cy={15} r={1.5} />
        </g>
      </g>
    </g>
  </svg>
);
