import {Box} from '@material-ui/core';
import React from 'react';
import LoadingIndicator from '../ui/LoadingIndicator';
import {useContext} from 'react';
import AppContext from '../../context/app/appContext';
import {PaymentMethodTypes, PaymentServiceType} from '../../model/constants/Constants';
import {IconBankWithoutOutline} from '../../assets/svg/payment-methods/IconBankWithoutOutline';
import AirPlusThumbnailIcon from '../../assets/svg/AirPlusThumbnail.svg';

import {IconCreditCard} from '../../assets/svg/payment-methods/IconCreditCard';
interface IPaymentOptionsToAdd {
  onClickAddCc?: () => void;
  onClickAddAirPlus?: () => void;
  onClickAddBT?: () => void;
  isLoadingInvitation?: boolean;
  isAccountsPayable?: boolean;
  airplus79131?: boolean;
}

export const PaymentOptionsToAdd = (props: IPaymentOptionsToAdd) => {
  const {availablePaymentMethodTypes} = useContext(AppContext);

  //Oct 11 2022: New way of getting available payment options:
  const isCCSupported = availablePaymentMethodTypes?.some(a => a.serviceType === PaymentServiceType.CREDIT_CARD);
  const isBTSupported = availablePaymentMethodTypes?.some(a => a.serviceType === PaymentServiceType.BANK_ACCOUNT);
  const isAirPlusSupported = availablePaymentMethodTypes?.some(a => a.paymentMethod === PaymentMethodTypes.Airplus);

  return (
    <>
      <div className="flex h-full justify-between">
        {props.isLoadingInvitation ? (
          <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
            <LoadingIndicator isLoading size="md" position={{position: 'absolute'}} />
          </Box>
        ) : (
          paymentMethodButton({
            buttonType: IPaymentButtonType.BANK_ACCOUNT,
            isButtonEnabled: isBTSupported || props.isAccountsPayable,
            handleClick: props.onClickAddBT,
          })
        )}
        {paymentMethodButton({
          buttonType: IPaymentButtonType.CREDIT_CARD,
          isButtonEnabled: isCCSupported || props.isAccountsPayable,
          handleClick: props.onClickAddCc,
        })}
        {props?.airplus79131 &&
          paymentMethodButton({
            buttonType: IPaymentButtonType.AIR_PLUS,
            isButtonEnabled: props.airplus79131 && (isAirPlusSupported || props.isAccountsPayable),
            handleClick: props.onClickAddAirPlus,
          })}
      </div>
    </>
  );
};

enum IPaymentButtonType {
  BANK_ACCOUNT = 'BANK_ACCOUNT',
  CREDIT_CARD = 'CREDIT_CARD',
  AIR_PLUS = 'AIR_PLUS',
}

const paymentMethodButton = (props: {
  buttonType: IPaymentButtonType;
  isButtonEnabled?: boolean;
  handleClick: (() => void) | undefined;
}) => {
  const {isButtonEnabled, buttonType, handleClick} = props;
  let icon;
  let title = '';
  let dataAutoID = '';
  switch (buttonType) {
    case IPaymentButtonType.AIR_PLUS:
      title = 'AirPlus';
      icon = <img src={AirPlusThumbnailIcon} alt="AirPlus" className="mt-1 h-[22px] w-[39px]" />;
      dataAutoID = 'btnAddAirPlus';
      break;
    case IPaymentButtonType.BANK_ACCOUNT:
      title = 'Bank Account';
      icon = <IconBankWithoutOutline width="24px" />;
      dataAutoID = 'btnAddBankAccount';
      break;
    case IPaymentButtonType.CREDIT_CARD:
      title = 'Credit Card';
      icon = <IconCreditCard width="24px" />;
      dataAutoID = 'btnAddCreditCard';
      break;

    default:
      title = 'Credit Card';
      icon = <IconCreditCard width="24px" />;
      dataAutoID = 'btnAddCreditCard';
  }
  return (
    <div
      onClick={isButtonEnabled ? handleClick : undefined}
      className={`${
        isButtonEnabled ? 'cursor-pointer' : 'opacity-35 cursor-default'
      } my-auto flex h-[70px] w-[82px] flex-col items-center justify-between rounded-[6px] border border-solid border-[#EAEAEA] bg-[#fafafa] px-4 py-2 text-lg`}
      data-autoid={dataAutoID}
    >
      {icon}
      <div className="flex h-full items-center">
        <h4 className="mt-1 text-center font-poppins text-xs font-medium leading-3">{title}</h4>
      </div>
    </div>
  );
};
