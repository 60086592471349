import React, {useState, useEffect} from 'react';
import {SuggestionSelectedEventData} from 'react-autosuggest';
import {SAutocomplete} from '../inputs/SAutocomplete';
import {IPagedActionResults} from '../../model/ActionResults';
import {useCustomerClassesAPI} from '../../services/useCustomerClassesAPI';
import {useCustomersAPI} from '../../services/useCustomersAPI';
import {ICustomer} from '../../model/customer/Customer';

interface IAccountCustomerAutoComplete<T> {
  id?: string;
  name?: string;
  value?: any;
  placeholder?: string;
  types?: string;
  multiple?: boolean;
  disabled?: boolean;
  selected?: any;
  addNewClick?: ((value: string) => void) | boolean | undefined;
  clearClick?: (() => void) | boolean | undefined;
  onSuggestionSelected: (event: React.FormEvent<Object>, selected: SuggestionSelectedEventData<T>) => void;
}

export const AccountCustomerAutoComplete = (props: IAccountCustomerAutoComplete<Partial<ICustomer>>) => {
  const [suggestions, setSuggestions] = useState<ICustomer[]>([]);
  const {isLoading} = useCustomerClassesAPI();
  const {list: getCustomerList} = useCustomersAPI();

  const {placeholder, selected, addNewClick, clearClick, onSuggestionSelected} = props;

  const accountCustomerList = (newValue: string = '') => {
    if (!selected) {
      getCustomerList({
        MaxResults: 5,
        IsShowAccountCustomersOnly: true,
        ...(newValue ? {Search: newValue} : {}),
      }).then((res: IPagedActionResults<ICustomer[]>) => {
        setSuggestions(res.Value);
      });
    }
  };

  useEffect(() => {
    if (selected) {
      setSuggestions([selected]);
    }
  }, [selected]);

  const handleSuggestionSelected = (
    event: React.FormEvent<any>,
    _selected: SuggestionSelectedEventData<Partial<ICustomer>>
  ) => {
    onSuggestionSelected(event, _selected);
    setSuggestions([_selected.suggestion]);
  };

  return (
    <SAutocomplete<Partial<ICustomer>>
      async
      loading={isLoading}
      placeholder={placeholder || 'Search here'}
      addNewClick={addNewClick}
      clearClick={clearClick}
      suggestions={suggestions}
      selected={selected}
      getSuggestions={(value: string) => {
        if (value) {
          accountCustomerList(value);
        }
      }}
      renderSuggestion={(item: Partial<ICustomer>) => (
        <>
          <p>{item.CompanyName}</p>
        </>
      )}
      getSuggestionValue={(suggestion: Partial<ICustomer>) => {
        return `${suggestion.CompanyName}`;
      }}
      onSearchChange={(value: string) => {
        if (value) accountCustomerList(value);
      }}
      onSuggestionSelected={handleSuggestionSelected}
    />
  );
};
