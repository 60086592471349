import React from 'react';
import toMaterialStyle from 'material-color-hash';
import {css} from 'glamor';

export const LabelAndCounter = (props: {id: number; label: string; count?: number; onClick: () => void}) => {
  const colorStyles = toMaterialStyle(props.label, 400);

  const container = css({
    // fontFamily: "Saira Extra Condensed",
    width: '104px',
    backgroundColor: '#1C78AD!important',
    '@media(min-width: 1280px)': {
      width: '130px',
    },
  });

  const label = css({
    color: 'white',
    whiteSpace: 'normal',
    lineHeight: 1,
    width: '87%',
    wordBreak: 'break-word',
  });

  const counter = css({
    backgroundColor: 'white',
    opacity: 0.7,
    color: 'black',
    display: 'none',
    '@media(min-width: 1280px)': {
      display: 'flex',
    },
  });

  return (
    <div
      onClick={props.onClick}
      style={{...colorStyles}}
      className={`${container} border p-1 flex items-center ml-1 rounded relative`}
    >
      <h4 className={`${label}`}>{props.label}</h4>
      <span className={`${counter} rounded-full h-8 w-10 items-center justify-center text-cs relative`}>
        {props.count}
      </span>
    </div>
  );
};
