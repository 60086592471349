import React, {useContext, useEffect, useState} from 'react';
import AppContext from '../../context/app/appContext';
import {useFeatureFlags} from '../../hooks/useFeatureFlags';
import {PaymentProviderName, PaymentWidgetScope} from '../../model/constants/Constants';
import {IPaymentBatch} from '../../model/payment-batch/PaymentBatch';
import {IGetSurchargeProps} from '../../model/payment-widget/PaymentWidget';
import {ICreditCardT} from '../../model/payment/PaymentMethod';
import {IDefaultDbiDetails, PaymentWidgetModal} from '../pay-by-link/payment-widget/PaymentWidget';
import {usePaymentMethods} from '../pay-by-link/payment-widget/usePaymentMethods';
import {usePaymentsFromAP} from '../pay-by-link/payment-widget/usePaymentsFromAP';
interface IPayBatchProps {
  batchID: number;
  onClose: () => void;
  onDone?: () => void;
  onPaymentApproved: () => void;
  onPaymentFailed: () => void;
}
export const PayBatch = (props: IPayBatchProps) => {
  //#region destructures
  const {batchID, onClose, onPaymentApproved, onPaymentFailed, onDone} = props;
  const {user} = useContext(AppContext);
  const {paymentAuth72488, capricornDemo77857, airplus79131, onboardingUpgradeV1, fees88078} =
    useFeatureFlags().tenantOwned();

  //#endregion

  //#region useStates, useEffects
  const [batchDetail, setBatchDetail] = useState<IPaymentBatch>();

  useEffect(() => {
    if (batchID) {
      fetchBatchDetails();
    }
  }, [batchID]);

  //#endregion

  //#region Destructure APIs
  const {
    getPaymentMethods,
    getPaymentBatch,
    getSurcharge: getSurchargeAPI,
    submitPayment,
    checkBatchPaymentStatus,
    authorisePayment,
    updatePaymentBatch,
  } = usePaymentsFromAP({paymentBatch: batchDetail, setBatchDetail, paymentAuth72488, airplus79131, fees88078});

  const defaultDbiDetails: IDefaultDbiDetails = {
    AU: batchDetail?.refNumber ?? '',
    IK: batchDetail?.name ?? '',
  };

  const {
    fetchSPSAccount,
    initiateVerification,
    onRetryVerification,
    onVerifyCode,
    removeCreditCard,
    saveCreditCard,
    updateAccountDetails,
    getPaymentMethodAuthorisationRules,
    savePaymentAuthorisationRules,
    approvePaymentAuthorisation,
    getPaymentMethodAuthorisationDetails,
    resetAuthenticationCode,
    rejectPaymentAuthorisation,
    fetchAirPlusDBIConfig,
  } = usePaymentMethods({fees88078});

  //#region Methods
  const fetchBatchDetails = async () => {
    const batchDetails = await getPaymentBatch(batchID);
    setBatchDetail(batchDetails);
  };

  const onSaveCreditCard = (card: ICreditCardT) => {
    const isBPSPMerchant = true;
    return saveCreditCard(card, isBPSPMerchant);
  };

  const getSurcharge = (props: IGetSurchargeProps) => {
    const {paymentAccountGUID} = props;
    //change Payload as per the API requirnents
    return getSurchargeAPI(PaymentProviderName.Spenda_Payment_Services, paymentAccountGUID, batchDetail?.paymentAmount);
  };

  const handleCheckBatchPaymentStatus = () => {
    const transctionGUID = batchDetail?.debits?.[0]?.transactionGUID;
    return checkBatchPaymentStatus(transctionGUID);
  };

  //#endregion

  return (
    <PaymentWidgetModal
      userEmailAddress={user?.UserName}
      userID={user?.UserID}
      fetchSPSAccount={fetchSPSAccount}
      getSurcharge={getSurcharge}
      initiateVerification={initiateVerification}
      onRetryVerification={onRetryVerification}
      loadPaymentOptions={getPaymentMethods}
      onClose={onClose}
      onPaymentApproved={onPaymentApproved}
      onPaymentFailed={onPaymentFailed}
      onVerifyCode={onVerifyCode}
      open={Boolean(batchDetail?.paymentAmount)}
      removeCreditCard={removeCreditCard}
      saveCreditCard={onSaveCreditCard}
      submitPayment={submitPayment}
      updateAccountDetails={updateAccountDetails}
      tenantName={user?.TenantName}
      checkPaymentStatus={handleCheckBatchPaymentStatus}
      totalPayableAmount={batchDetail?.paymentAmount}
      widgetScope={PaymentWidgetScope.ACCOUNT_PAYABLE}
      onDone={onDone}
      isAccountsPayable={true}
      paymentAuth72488={paymentAuth72488}
      getPaymentMethodAuthorisationRules={getPaymentMethodAuthorisationRules}
      savePaymentAuthorisationRules={savePaymentAuthorisationRules}
      authorisePayment={authorisePayment}
      approvePaymentAuthorisation={approvePaymentAuthorisation}
      getPaymentMethodAuthorisationDetails={getPaymentMethodAuthorisationDetails}
      resetAuthenticationCode={resetAuthenticationCode}
      rejectPaymentAuthorisation={rejectPaymentAuthorisation}
      updatePaymentBatch={updatePaymentBatch}
      capricornDemo77857={capricornDemo77857}
      airplus79131={airplus79131}
      fetchAirPlusDBIConfig={fetchAirPlusDBIConfig}
      isAPOnboardingV1={onboardingUpgradeV1}
      defaultDbiDetails={defaultDbiDetails}
      fees88078={fees88078}
    />
  );
};
