import React, {Dispatch, SetStateAction} from 'react';
import {Button, IconButton, Menu, MenuHandler, MenuList, Textarea, Typography} from 'spenda-ui-react';
import {KebabMenu} from '../../assets/svg';
import {ICustomer} from '../../model/customer/Customer';
import QuoteDetailsCard, {ICardContent} from './QuoteDetailsCard';
import {IQuotes, IQuotesPackage} from '../../model/quotes/quotes';
import {PriceFormat} from '../../utils/formatter';
import {QuoteStatus} from '../../model/constants/Constants';
import WarningIcon from '../../assets/svg/quotes-management/WarningIcon';
import moment from 'moment';
import clsx from 'clsx';

interface IQuoteVariant {
  setQuoteVariantShow?: () => void;
  selectedCustomer?: ICustomer;
  updateQuotePackage?: () => void;
  internalNote?: string;
  setInternalNote?: Dispatch<SetStateAction<string>>;
  quotePackage: IQuotesPackage;
  setSelectedVariant: (id: number) => void;
  selectedVariant: number;
  handleDeleteQuote?: (quoteID: number) => void;
  isQuoteSent?: boolean;
  isLoading?: boolean;
  selectedQuoteDetails?: IQuotes;
  selectedVariantTotalAmount?: number;
  handleDuplicateQuote?: (quoteID: number) => void;
  isSaving?: boolean;
}

const QuoteVariantButton = (props: {
  index: number;
  active: boolean;
  quote?: IQuotes;
  setSelectedQuote: Dispatch<SetStateAction<number>>;
  handleDeleteQuote: (quoteID: number) => void;
  handleDuplicateQuote: (quoteID: number) => void;
  isQuoteSent?: boolean;
  isLoading?: boolean;
  selectedVariant?: number;
  selectedVariantTotalAmount?: number;
  isSaving?: boolean;
}) => {
  const {
    active,
    index,
    quote,
    setSelectedQuote,
    isQuoteSent,
    isLoading,
    selectedVariant,
    selectedVariantTotalAmount,
    isSaving,
  } = props;
  const bgColor = active ? 'bg-[#1C78AD]' : 'bg-[#ECECEC]';
  const textColor = active ? 'text-white hover:text-white' : 'text-black hover:text-black';
  const containerClass = `flex items-center justify-between cursor-pointer ${bgColor} rounded-md py-2.5 pr-2.5 pl-3.5 ${isSaving && !active && 'opacity-40'}`;

  return (
    <div
      data-autoid={`btnQuoteVariant-${quote?.quoteID}`}
      className={containerClass}
      onClick={() => {
        if ((isQuoteSent && isLoading) || selectedVariant === quote?.quoteID || isSaving) return;
        setSelectedQuote(quote?.quoteID || -1);
      }}
    >
      <Button
        ripple={false}
        variant="text"
        data-autoid={`txtQuoteName-${quote?.quoteID}`}
        className={`${textColor} cursor-pointer truncate p-0 text-lg font-medium hover:no-underline focus:bg-transparent active:bg-transparent`}
        title={quote?.name}
      >
        {quote?.name || `Quote ${index + 1}`}
      </Button>
      <div className="flex items-center gap-x-2">
        <Typography
          data-autoid={`txtTotal-${quote?.quoteID}`}
          variant="small"
          className={`${!active ? 'text-black-800' : 'text-white'} ml-2.5`}
        >
          {PriceFormat(selectedVariantTotalAmount || 0)}
        </Typography>
        {quote && active && !isQuoteSent ? (
          <Menu offset={-5}>
            <MenuHandler>
              <IconButton
                variant="text"
                ripple={false}
                color={active ? 'white' : 'blue-gray'}
                name={`Menu-${quote?.quoteID}`}
                className={`hover:bg-transparent active:bg-transparent`}
              >
                <KebabMenu />
              </IconButton>
            </MenuHandler>
            <MenuList className="flex flex-col p-0">
              <button
                data-autoid={`btnDeleteQuoteVariant-${quote?.quoteID}`}
                className="min-w-[180px] rounded-none border-b border-[#ECECEC] px-3 py-2.5 text-sm font-normal text-black-900 hover:!bg-primary/10 hover:text-black-900 active:!bg-primary/10"
                onClick={() => props.handleDeleteQuote(quote?.quoteID!)}
              >
                Discard quote variant
              </button>
              <button
                data-autoid={`btnDuplicateQuoteVariant-${quote?.quoteID}`}
                className="min-w-[180px] rounded-none border-b border-[#ECECEC] px-3 py-2.5 text-sm font-normal text-black-900 hover:!bg-primary/10 hover:text-black-900 active:!bg-primary/10"
                onClick={() => props.handleDuplicateQuote(quote?.quoteID!)}
              >
                Duplicate quote variant
              </button>
            </MenuList>
          </Menu>
        ) : (
          <div className="py-5"></div>
        )}
      </div>
    </div>
  );
};

const QuoteVariant = (props: IQuoteVariant) => {
  const {
    setQuoteVariantShow,
    selectedCustomer,
    updateQuotePackage,
    internalNote,
    setInternalNote,
    quotePackage,
    setSelectedVariant,
    selectedVariant,
    handleDeleteQuote,
    isQuoteSent,
    isLoading,
    selectedQuoteDetails,
    selectedVariantTotalAmount,
    handleDuplicateQuote,
    isSaving,
  } = props;

  const customerInfo: ICustomer = {
    IsIndividual: selectedCustomer?.IsIndividual,
    CompanyName: selectedCustomer?.CompanyName,
    Name: selectedCustomer?.Name,
    RefNumber: selectedCustomer?.RefNumber,
    PrimaryContactFullName: selectedCustomer?.PrimaryContactFullName,
    PrimaryContactEmailAddress: selectedCustomer?.PrimaryContactEmailAddress,
    PrimaryContactPhone: selectedCustomer?.PrimaryContactPhone,
  };

  const cardContent: ICardContent[] = [
    {
      contentHeading: 'Customer',
      contentValue: customerInfo.PrimaryContactFullName,
    },
    {
      contentHeading: 'Company',
      contentValue: !customerInfo.IsIndividual ? customerInfo?.CompanyName : '-',
    },
    {
      contentHeading: 'Ref no.',
      contentValue: customerInfo.RefNumber,
    },
    {
      contentHeading: 'Email',
      contentValue: customerInfo.PrimaryContactEmailAddress,
    },
    {
      contentHeading: 'Phone',
      contentValue: customerInfo.PrimaryContactPhone,
    },
  ];
  return (
    <div className="h-screen rounded-md bg-white p-2.5">
      <QuoteDetailsCard
        customerInfo={cardContent}
        mainHeading={'Customer details'}
        isQuoteSent={isQuoteSent}
        setQuoteVariantShow={() => setQuoteVariantShow?.()}
      />
      <div className="my-6">
        <div className="flex flex-row">
          <Typography variant="h3" className="mb-4 leading-6 text-black-900">
            Quote Package :&nbsp;
          </Typography>
          <Typography
            data-autoid={`txtQuotePackage-${quotePackage?.refNumber}`}
            variant="h3"
            className="mb-4 leading-6 text-[#666666]"
          >
            {quotePackage?.refNumber}
          </Typography>
        </div>
        {selectedQuoteDetails && selectedQuoteDetails.status === QuoteStatus.Approved && (
          <Typography data-autoid={`txtApprovedBy`} variant="paragraph" className="mb-4 leading-6 text-[#666666]">
            Approved by:{' '}
            {selectedQuoteDetails?.approvedByUserName || selectedQuoteDetails?.approvedByBusinessContactName}
          </Typography>
        )}
        <div
          className={`${
            quotePackage && quotePackage?.quotes && quotePackage?.quotes?.length > 1 ? 'pr-1' : ''
          } flex max-h-[130px] flex-col gap-y-2.5 overflow-y-auto`}
        >
          {quotePackage?.quotes?.map((quote, index) => {
            return (
              <QuoteVariantButton
                active={selectedVariant === quote?.quoteID}
                quote={quote}
                index={index}
                key={index}
                handleDeleteQuote={quoteID => handleDeleteQuote?.(quoteID)}
                setSelectedQuote={setSelectedVariant}
                isQuoteSent={isQuoteSent}
                isLoading={isLoading}
                selectedVariant={selectedVariant}
                handleDuplicateQuote={quoteID => handleDuplicateQuote?.(quoteID)}
                selectedVariantTotalAmount={
                  selectedVariant === quote?.quoteID && !isQuoteSent ? selectedVariantTotalAmount : quote?.totalInc
                }
                isSaving={isSaving}
              />
            );
          })}
        </div>
      </div>
      <div
        className={clsx('h-[calc(100vh-474px)] overflow-y-auto', {
          '!h-[calc(100vh-545px)]': quotePackage?.quotes?.length! > 1,
        })}
      >
        <div className="flex h-full flex-1 flex-col justify-between gap-y-2.5 py-1.5">
          <div>
            <Textarea
              label="Internal notes"
              color="primary"
              name="InternalNotes"
              displayLength={!isQuoteSent}
              maxLength={500}
              value={internalNote}
              className={'min-h-[132px] leading-[1.35rem]'}
              onChange={e => setInternalNote?.(e.target.value)}
              onBlur={() => {
                updateQuotePackage?.();
              }}
            />
          </div>
          {isQuoteSent && selectedQuoteDetails?.status === QuoteStatus.Draft && (
            <div>
              <div className="flex justify-center rounded-md bg-warning/10 py-2.5">
                <Typography className="font-bold text-warning" data-autoid={'txtExpiryDate'}>
                  Expiry Date: {moment(quotePackage?.expiryDate).format('DD MMMM')}
                </Typography>
              </div>
            </div>
          )}
          {isQuoteSent && selectedQuoteDetails?.status === QuoteStatus.Rejected && (
            <div className="flex flex-grow flex-col gap-y-3 rounded-md bg-error/10 p-2.5">
              <div className="flex items-center gap-x-2">
                <WarningIcon />
                <Typography className="font-medium" variant="paragraph">
                  Quote Rejected
                </Typography>
              </div>
              {selectedQuoteDetails?.rejectReason && (
                <Typography className="mb-3" variant="xsmall">
                  Customer reason - {selectedQuoteDetails?.rejectReason}
                </Typography>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default QuoteVariant;
