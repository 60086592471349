import * as React from 'react';
const RequiresAttentionIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={40} height={40} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x={0.5} y={0.5} width={39} height={39} rx={5.5} stroke="#1C78AD" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 9C13.925 9 9 13.925 9 20s4.925 11 11 11 11-4.925 11-11c-.007-6.072-4.928-10.993-11-11zm0 20.167A9.167 9.167 0 1129.167 20 9.177 9.177 0 0120 29.167z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 13.583a.917.917 0 00-.916.917v7.333a.917.917 0 101.833 0V14.5a.917.917 0 00-.917-.917z"
      fill="currentColor"
    />
    <rect x={19.0835} y={24.5833} width={1.83333} height={1.83333} rx={0.916667} fill="currentColor" />
  </svg>
);
export default RequiresAttentionIcon;
