import * as React from 'react';
const ARExpandBlueIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg {...props}>
    <path
      className="fill-primary"
      fillRule="evenodd"
      d="M15.411.588a1.977 1.977 0 0 0-1.867-.534L2.878 2.301A3.33 3.33 0 0 0 .976 7.955L2.12 9.1a.667.667 0 0 1 .195.472v2.112c.002.297.07.59.2.856l-.005.005.017.017a2 2 0 0 0 .908.904l.018.018.004-.006c.267.13.56.199.857.2h2.112c.177 0 .346.07.471.195l1.145 1.145a3.309 3.309 0 0 0 3.417.806 3.289 3.289 0 0 0 2.229-2.658l2.25-10.69a1.983 1.983 0 0 0-.528-1.888ZM3.065 8.158 1.919 7.014a1.96 1.96 0 0 1-.481-2.05 1.985 1.985 0 0 1 1.666-1.35L13.665 1.39 3.648 11.408V9.572a1.985 1.985 0 0 0-.583-1.414Zm9.314 4.78a2 2 0 0 1-3.393 1.142L7.84 12.933a1.985 1.985 0 0 0-1.413-.585H4.59L14.608 2.333 12.38 12.938Z"
    />
  </svg>
);
export default ARExpandBlueIcon;
