import React, {useState, useEffect, useContext} from 'react';
import {Formik, FormikProps} from 'formik';
import clsx from 'clsx';
import * as Yup from 'yup';

import AppContext from '../../context/app/appContext';
import {IPurchaseOrder} from '../../model/purchase-order/PurchaseOrder';
import {ApplicationInfo} from '../../model/constants/ApplicationInfo';
import {DatTypes, PurchaseOrderStatus} from '../../model/constants/Constants';
import {IAlertInfo} from '../../model/alert/AlertInfo';
import {usePurchaseOrderDetailStyle} from '../../screens/purchase-order/PurchaseOrderDetail';
import {PrimaryButton} from '../buttons/DefaultButtons';
import {SDialog} from '../modals/modalSpendaMeterialUI';
import {STextField} from '../inputs/STextField';
import {SwitchSlider} from '../form/SwitchSlider';
import useAlertAPI from '../../services/useAlertAPI';
import LoadingIndicator from '../ui/LoadingIndicator';
import {Toast} from '../../utils/Toast';

import {DialogContent, DialogTitle, IconButton} from '@material-ui/core';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

const addressValidationSchema = Yup.object({});

interface IPurchaseOrderSendDialog extends FormikProps<Partial<IPurchaseOrder>> {
  open: boolean;
  setOpen: (x: boolean) => void;
  refetch: () => void;
}

export const PurchaseOrderSendDialog = (props: IPurchaseOrderSendDialog) => {
  const {user, tenantInfo} = useContext(AppContext);
  const {open, setOpen, values, refetch} = props;
  const classes = usePurchaseOrderDetailStyle();

  const {getAlertRequest, sendEmailAlert, isLoading} = useAlertAPI();

  const [alertRequest, setAlertRequest] = useState<Partial<IAlertInfo>>({
    Bcc: '',
    To: '',
    Cc: '',
  });

  useEffect(() => {
    if (open) {
      getAlertRequest({
        DocID: values.ID,
        DatTypeID: DatTypes.PurchaseOrder,
      }).then(res => {
        setAlertRequest(res);
      });
    }
  }, [open]);

  const handleSend = async (values: Partial<IAlertInfo>) => {
    return sendEmailAlert({
      ToList: [],
      Bcc: values.Bcc,
      IsSendStatusNotifications: values.IsSendStatusNotifications,
      Subject: values.Subject,
      IsSetDocStatus: true,
      To: values.To,
      DocID: values.DocID,
      IsSendAll: values.IsSendAll,
      TenantID: tenantInfo?.TenantUserDetails?.TenantID,
      WebsiteID: ApplicationInfo.WebsiteId,
      Cc: values.Cc,
      IsResponseRequired: values.IsResponseRequired,
      IsUpdateAsEmailed: values.IsUpdateAsEmailed,
      DocStatus: PurchaseOrderStatus.Sent_as_Unlinked,
      DatTypeID: DatTypes.PurchaseOrderInviteWithPDF,
      UserID: user?.UserID,
    }).then(res => {
      if (res) {
        Toast.info('Sending email');
        setOpen(false);
        refetch();
      }
    });
  };

  return (
    <SDialog classes={{paper: 'relative'}} maxWidth="md" fullWidth open={Boolean(open)} onClose={() => setOpen(false)}>
      <DialogTitle className="border-b-[1px] border-b-gray-200">
        <p className="font-poppins text-center w-full">Email your order to</p>
        <div className="absolute top-3 right-2 font-poppins">
          <IconButton
            disableRipple
            className={classes.backIconButton}
            data-autoid="btnSearch"
            onClick={() => setOpen(false)}
          >
            <CloseRoundedIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent classes={{root: clsx('mt-3 mb-16 font-poppins')}}>
        <LoadingIndicator isLoading={isLoading} size="md" color="#1C78AD" />
        <Formik
          enableReinitialize
          validationSchema={addressValidationSchema}
          initialValues={alertRequest}
          onSubmit={handleSend}
        >
          {props => (
            <div className="max-w-[423px] mx-auto">
              <p className="text-center text-base text-spenda-primarytext mb-10">
                Verify your supplier's email address:
              </p>
              <div className="relative flex items-center mb-5">
                <span className="text-base text-spenda-primarytext absolute left-[-36px]">To:</span>
                <STextField
                  v2
                  fullWidth
                  id="To"
                  name="To"
                  variant="outlined"
                  label="To"
                  placeholder="To"
                  value={props.values.To}
                  onBlur={props.handleBlur}
                  onChange={props.handleChange}
                  helperText={props.touched?.To && props.errors?.To}
                  error={props.touched?.To && Boolean(props.errors?.To)}
                />
              </div>
              <div className="relative flex items-center mb-2">
                <span className="text-base text-spenda-primarytext absolute left-[-50px]">BCC:</span>
                <STextField
                  v2
                  fullWidth
                  id="Bcc"
                  name="Bcc"
                  variant="outlined"
                  label="BCC"
                  placeholder="BCC"
                  value={props.values.Bcc}
                  onBlur={props.handleBlur}
                  onChange={props.handleChange}
                  helperText={props.touched?.Bcc && props.errors?.Bcc}
                  error={props.touched?.Bcc && Boolean(props.errors?.Bcc)}
                  disabled={!Boolean(props.values.Bcc)}
                />
              </div>
              <div className="relative flex items-center mb-5">
                <span className="text-base text-spenda-primarytext absolute left-2">Send a copy to me:</span>
                <div className="flex-1" />
                <SwitchSlider
                  name="SendACopyToMe"
                  checked={Boolean(props.values.Bcc)}
                  handleChange={(e: any, checked: boolean) => {
                    if (checked) {
                      props.setFieldValue('Bcc', user?.email || '');
                    } else {
                      props.setFieldValue('Bcc', '');
                    }
                  }}
                  labelPlacement="start"
                />
              </div>
              <div className="absolute px-0.5" style={{bottom: 10, width: 'calc(100% - 32px)', left: '16px'}}>
                <div className="p-2 flex justify-center w-full">
                  <PrimaryButton
                    margin="0 0 0 8px"
                    label="Send"
                    onClick={props.handleSubmit}
                    disabled={isLoading}
                    isSubmitting={isLoading || props.isSubmitting}
                  />
                </div>
              </div>
            </div>
          )}
        </Formik>
      </DialogContent>
    </SDialog>
  );
};
