import React, {useState, useEffect} from 'react';
import {Box, FormControlLabel, Radio, RadioGroup, makeStyles} from '@material-ui/core';
import {SButton} from '../buttons/Button';
import NextArrowIcon from '../../assets/svg/next-arrow.svg';
import {IMasterAdaptor} from '../../model/MasterAdaptor';
import _ from 'lodash';
import useSynkAPI from '../../services/useSynkAPI';
import {ISynkAuthorizeResponse, ISynkIsAuthorizedResponse} from '../../model/synkd/SynkAuthorizeResponse';
import BackIcon from '../../assets/svg/back.svg';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import LoadingIndicator from '../ui/LoadingIndicator';
import {STextField} from '../inputs/STextField';
import {SModal} from '../modals/modalSpendaMeterialUI';
import {ChatWidget} from './ChatWidget';
import {Toast} from '../../utils/Toast';
import {DatTypes} from '../../model/constants/Constants';
import useWorkflowAPI from '../../services/useWorkflowAPI';
import CloseIcon from '@material-ui/icons/Close';
import {GetBackgroundCSS} from './Onboarding';
import APOnboarding from '../accountsPayableOnboarding/APOnboarding';
import {APFooter} from '../accountsPayableOnboarding/APFooter';
import XeroLogo from '../../assets/png/xero-logo.png';
import QBOLogo from '../../assets/png/QuickBooksOnline-logo.png';
import MyobLogo from '../../assets/png/MyObAccountRightLive-logo.png';
import ParodyLogo from '../../assets/png/Parody-logo.png';

const useAuthorizeAccountingSystemStyles = makeStyles({
  apModalBox: {
    maxWidth: '800px',
  },
  boxHeight: {
    fontFamily: 'Poppins, sans-serif !important',
    height: 'calc(100% - 104px)',
  },
  apOndoardingTitle: {
    fontFamily: 'Poppins, sans-serif !important',
    fontSize: '28px',
    lineHeight: '39px',
  },
  nextBtn: {
    fontFamily: 'Poppins, sans-serif !important',
    position: 'absolute',
    bottom: '0px',
  },
  apOnboardingDesc: {
    fontFamily: 'Poppins, sans-serif !important',
    fontSize: '16px',
    color: '#333',
    lineHeight: '23px',
  },
  apAccountBox: {
    height: '170px',
    width: '190px',
    borderRadius: '10px',
    backgroundColor: '#e9eff3',
    '& button': {
      fontSize: '14px',
      width: '120px',
    },
  },
  apAuthorizationBot: {
    margin: '-70px 0 0 -20px',
  },
  apOrganisationListBox: {
    width: '50%',
    marginRight: '10%',
    background: '#e9eff3',
    minHeight: '315px',
    borderRadius: '10px',
    textAlign: 'center',
    overflow: 'hidden',
  },
});
interface AuthorizeAccountingSystemProps {
  onNextClick: () => void;
  onboardingAccount?: string;
  selectedAccount?: IMasterAdaptor;
  onBoardingAccountRedirectUrl?: string;
  userName?: string;
  websiteId?: number;
  submitSynkAuthorizeResponse?: (response: ISynkAuthorizeResponse) => void;
  onBackPress: () => void;
  setOrganizationName: (organizationName: string) => void;
  synkConnect: () => void;
  isSyncConnectLoading: boolean;
  handleClose: () => void;
  isBuyer: boolean;
  isAPOnboarding?: boolean;
  onCancelClick?: () => void;
  isPurchasingOnboarding?: boolean;
}

export const AuthorizeAccountingSystem: React.FunctionComponent<AuthorizeAccountingSystemProps> = ({
  onNextClick,
  onBackPress,
  onboardingAccount,
  selectedAccount,
  onBoardingAccountRedirectUrl,
  userName,
  websiteId,
  submitSynkAuthorizeResponse,
  setOrganizationName,
  synkConnect,
  isSyncConnectLoading,
  handleClose,
  isBuyer,
  isAPOnboarding,
  onCancelClick,
  isPurchasingOnboarding,
}: AuthorizeAccountingSystemProps) => {
  const classes = useAuthorizeAccountingSystemStyles();

  let accountType = '';
  switch (selectedAccount?.Name) {
    case 'Xero':
      accountType = 'xero';
      break;
    case 'QuickBooksOnline':
      accountType = 'quickbooks';
      break;
    case 'MyObAccountRightLive':
      accountType = 'myob';
      break;
    case 'Parody':
      accountType = 'parody';
      break;
  }

  const location = window.location.origin;
  const xeroAuthorizeRedirectLink = isPurchasingOnboarding
    ? `${location}/purchasing/onboarding/${accountType}`
    : isAPOnboarding
      ? location + '/pay/onboarding/' + accountType
      : location + '/onboarding/' + accountType;
  const {synkAuthorize, synkIsAuthorize, getOrgName, onAuthorised} = useSynkAPI();
  const [isAuthorized, setIsAuthorized] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOrgLoading, setIsOrgLoading] = useState(false);
  const [organisationsList, setOrganisationsList] = useState<any[]>([]);
  const [showSelectOrganisations, setShowSelectOrganisations] = useState(false);
  const [organisationData, setOrganisationData] = useState({
    OrganisationId: '',
    OrganisationName: '',
  });
  const [orgUserName, setOrgUserName] = useState('');
  const [password, setPassword] = useState('');
  const {synkDataRead} = useWorkflowAPI();

  const getChartsOfAccounts = () => {
    return synkDataRead(false, 'Read', selectedAccount?.Name!, selectedAccount?.ID!, DatTypes.Accounts, 29);
  };

  const authorizeAccount = async () => {
    //authorize synkd
    if (
      onBoardingAccountRedirectUrl &&
      onBoardingAccountRedirectUrl != '' &&
      userName &&
      selectedAccount &&
      selectedAccount.Name &&
      onboardingAccount &&
      websiteId
    ) {
      setIsLoading(true);
      synkAuthorize(userName, selectedAccount.Name, onBoardingAccountRedirectUrl, xeroAuthorizeRedirectLink, websiteId)
        .then(async data => {
          setIsLoading(false);

          if (data.IsSuccess) {
            if (selectedAccount.ID && selectedAccount.Name === 'MyObAccountRightLive' && !data.OrganisationName) {
              setIsLoading(true);
              getOrgName(selectedAccount.ID).then(data => {
                setOrganisationsList(data?.AuthOrganisationDetails);
                data?.AuthOrganisationDetails &&
                  data?.AuthOrganisationDetails?.length &&
                  setOrganisationData(data?.AuthOrganisationDetails[0]);
                setShowSelectOrganisations(true);
                setIsLoading(false);
              });
            } else {
              setIsLoading(true);
              await getChartsOfAccounts();
              setIsLoading(false);
            }
            submitSynkAuthorizeResponse && submitSynkAuthorizeResponse(data);
            setIsAuthorized(true);
            setOrganizationName(data.OrganisationName || '');
          } else {
            setIsAuthorized(false);
          }
        })
        .catch(() => {
          setIsLoading(false);
          setIsAuthorized(false);
        });
    }
  };

  useEffect(() => {
    setIsAuthorized(
      onboardingAccount === 'xero' ||
        onboardingAccount === 'quickbook' ||
        onboardingAccount === 'myob' ||
        onboardingAccount === 'parody',
    );
  }, [onboardingAccount]);

  useEffect(() => {
    if (
      selectedAccount &&
      onboardingAccount &&
      selectedAccount.Name &&
      selectedAccount.ID &&
      selectedAccount?.MasterAdaptorID
    ) {
      setIsLoading(true);
      //check if is authorized else authorize again
      synkIsAuthorize(selectedAccount.Name, selectedAccount.ID, selectedAccount.MasterAdaptorID).then(
        async (response: ISynkIsAuthorizedResponse) => {
          setIsLoading(false);
          if (!response.IsAuthorized) {
            authorizeAccount();
          } else if (
            selectedAccount.ID &&
            selectedAccount.Name === 'MyObAccountRightLive' &&
            !response?.TokenDetails?.OrgName
          ) {
            setIsLoading(true);
            getOrgName(selectedAccount.ID).then(data => {
              setIsLoading(false);
              setOrganisationsList(data?.AuthOrganisationDetails);
              data?.AuthOrganisationDetails &&
                data?.AuthOrganisationDetails?.length &&
                setOrganisationData(data?.AuthOrganisationDetails[0]);
              setShowSelectOrganisations(true);
            });
          } else {
            if (!APOnboarding) {
              setIsLoading(true);
              await getChartsOfAccounts();
              setIsLoading(false);
            }
          }
          setOrganizationName(response?.TokenDetails?.OrgName || '');
        },
      );
    }
  }, [onBoardingAccountRedirectUrl, selectedAccount]);

  const submitMyobOrg = () => {
    if (
      selectedAccount &&
      selectedAccount.ID &&
      organisationData &&
      organisationData.OrganisationName &&
      organisationData.OrganisationId
    ) {
      setIsOrgLoading(true);
      onAuthorised(
        selectedAccount.ID,
        organisationData.OrganisationName,
        orgUserName,
        password,
        organisationData.OrganisationId,
      )
        .then(async res => {
          if (res.IsSuccess) {
            setIsLoading(true);
            await getChartsOfAccounts();
            setIsLoading(false);
            setOrganizationName(res?.OrganisationName);
            setShowSelectOrganisations(false);
            onNextClick();
          }
          setIsOrgLoading(false);
        })
        .catch(() => {
          setIsOrgLoading(false);
        });
    } else {
      Toast.error('Please Select Organisation');
    }
  };

  const onGoBack = () => {
    onBackPress();
  };

  return (
    <SModal open>
      <div className={`spenda-color flex h-full max-w-full items-center justify-center text-xl ${GetBackgroundCSS()}`}>
        <div
          className={`modal-box max-w-3xl ${
            isAPOnboarding ? `${classes.apModalBox} px-3 py-3` : 'select-account-modal px-5 py-5 '
          }`}
        >
          {showSelectOrganisations && (
            <>
              {isAPOnboarding && (
                <Box className="flex justify-between">
                  <Box className="flex w-full justify-end text-right">
                    <span className="ap-onboarding-close-icon" onClick={handleClose} data-autoid="btnClose">
                      <CloseIcon />
                    </span>
                  </Box>
                </Box>
              )}
              <div>
                {isBuyer && !isAPOnboarding && (
                  <span className="closeIcon onboarding-close-icon" onClick={handleClose} data-autoid="btnClose">
                    <CloseIcon />
                  </span>
                )}
                {!isAPOnboarding && (
                  <span
                    className="cursor-pointer"
                    onClick={onBackPress}
                    style={{position: 'relative'}}
                    data-autoid="btnBack"
                  >
                    <img src={BackIcon} alt="Next Arrow Icon" className="back-btn-bg-image" data-autoid="imgBackIcon" />
                    <ChevronLeftIcon className="back-btn-icon" />
                  </span>
                )}
                <p
                  id="simple-modal-description"
                  className={`simple-modal-title  mt-2 pb-3 text-center ${
                    isAPOnboarding ? classes.apOndoardingTitle : 'text-4xl'
                  }`}
                >
                  "Select the organisation you wish to connect."
                </p>
                <div className="flex justify-between px-16">
                  <div className={`${isAPOnboarding ? classes.apOrganisationListBox : 'organisation-list-box'} flex-1`}>
                    <div
                      className={`flex flex-col ${
                        !isAPOnboarding ? 'h-full items-center overflow-y-scroll' : 'items-start'
                      }`}
                    >
                      {isAPOnboarding && (
                        <span className="ml-4 mt-4 items-start font-poppins text-base font-medium text-spenda-labeltext">
                          Select organisation:
                        </span>
                      )}
                      {isAPOnboarding ? (
                        <RadioGroup
                          id="org"
                          onChange={e => {
                            const selectedOrg = organisationsList.find(org => org?.OrganisationId === e.target.value);
                            selectedOrg && setOrganisationData(selectedOrg);
                          }}
                          className="ml-4"
                          value={organisationData?.OrganisationId}
                        >
                          {Array.isArray(organisationsList) &&
                            organisationsList.map((org: any | undefined) => {
                              return (
                                <FormControlLabel
                                  key={org?.OrganisationId}
                                  control={<Radio color="secondary" size="small" />}
                                  className="ml-4 mt-4 font-poppins text-base font-medium text-black-800"
                                  label={
                                    <span className="font-poppins text-base font-medium text-black-800">
                                      {org?.OrganisationName}
                                    </span>
                                  }
                                  value={org?.OrganisationId}
                                />
                              );
                            })}
                        </RadioGroup>
                      ) : (
                        Array.isArray(organisationsList) &&
                        organisationsList.map((org: any | undefined) => (
                          <p
                            key={org?.OrganisationId}
                            className={`organistion-name-text my-1 ${
                              _.isEqual(org, organisationData) ? 'selected-organisation' : ''
                            }`}
                            onClick={() => setOrganisationData(org)}
                            data-autoid="lblOrganisationName"
                          >
                            {org?.OrganisationName}
                          </p>
                        ))
                      )}
                    </div>
                  </div>
                  <div className="flex-1">
                    <p
                      className={`${
                        isAPOnboarding ? 'mt-4 font-poppins text-base font-medium text-black-800' : 'org-login-text'
                      }`}
                    >
                      Please enter organisations login details.
                    </p>
                    <div className={`${isAPOnboarding ? 'mt-8' : 'pl-4'}`}>
                      <STextField
                        id="username"
                        name="username"
                        label="User Name"
                        value={orgUserName}
                        variant={isAPOnboarding ? 'outlined' : undefined}
                        onChange={e => setOrgUserName(e.target.value)}
                        fullWidth
                        v2={isAPOnboarding}
                        data-autoid="txtCompanyUserName"
                        className={`${isAPOnboarding && '!mb-4'}`}
                      />
                      <STextField
                        id="password"
                        name="password"
                        label="Password"
                        v2={isAPOnboarding}
                        value={password}
                        variant={isAPOnboarding ? 'outlined' : undefined}
                        onChange={e => setPassword(e.target.value)}
                        data-autoid="txtCompanyPassword"
                        fullWidth
                      />
                    </div>
                  </div>
                </div>
                {!isAPOnboarding && (
                  <Box display="flex" justifyContent="center" marginTop="15px">
                    <SButton
                      className="common-next-btn authorize-account-next-btn"
                      type="submit"
                      color={'blueShade'}
                      width="m"
                      lineHeight="m"
                      fontSize="m"
                      disabled={isOrgLoading || Boolean(!orgUserName)}
                      onClick={() => submitMyobOrg()}
                      data-autoid="btnLogin"
                    >
                      Login{' '}
                      {isOrgLoading ? (
                        <LoadingIndicator isLoading={isOrgLoading} size="sm" color="#FFF" position={{right: '5px'}} />
                      ) : (
                        <img src={NextArrowIcon} alt="Next Arrow Icon" className="" data-autoid="imgNextArrow" />
                      )}
                    </SButton>
                  </Box>
                )}
              </div>
            </>
          )}
          {!showSelectOrganisations && (
            <>
              {isAPOnboarding && (
                <Box className="flex justify-between">
                  <Box className="flex w-full justify-end text-right">
                    <span className="ap-onboarding-close-icon" onClick={handleClose} data-autoid="btnClose">
                      <CloseIcon />
                    </span>
                  </Box>
                </Box>
              )}
              <div className={isAPOnboarding ? `${classes.boxHeight} px-12` : ''}>
                {isBuyer && !isAPOnboarding && (
                  <span className="closeIcon onboarding-close-icon" onClick={handleClose} data-autoid="btnClose">
                    <CloseIcon />
                  </span>
                )}
                {!isAPOnboarding && (
                  <span
                    className="cursor-pointer"
                    onClick={onGoBack}
                    style={{position: 'relative'}}
                    data-autoid="btnBack"
                  >
                    <img src={BackIcon} alt="Next Arrow Icon" className="back-btn-bg-image" data-autoid="imgBackIcon" />
                    <ChevronLeftIcon className="back-btn-icon" />
                  </span>
                )}
                <p
                  id="select-system-title"
                  className={`simple-modal-title text-center ${
                    isAPOnboarding ? `${classes.apOndoardingTitle} mb-3` : 'mx-12 -mt-5 pb-3'
                  } `}
                >
                  {isAuthorized
                    ? `“Connected to ${selectedAccount && selectedAccount.Name}”`
                    : `"Ohh we love ${selectedAccount && selectedAccount.Name}, too.
                    Before we can continue you will need to authorise your ${
                      selectedAccount && selectedAccount.Name
                    } account"`}
                </p>
                {isAuthorized && (
                  <p
                    id="simple-modal-description"
                    className={`mx-4 mb-8 mt-4 text-center ${
                      isAPOnboarding ? `${classes.apOnboardingDesc}` : 'customer-description-para color-gray'
                    }`}
                  >
                    Successfully connected to {selectedAccount && selectedAccount.Name}! Press{' '}
                    <span className="font-semibold"> “Next” </span>
                    to continue.
                  </p>
                )}
                <div
                  className={`row mx-auto flex w-full flex-col justify-between ${
                    isAPOnboarding ? `mt-16 pt-2` : 'mb-10 mt-12 px-2'
                  }`}
                >
                  <div className="flex flex-col items-center justify-center">
                    <div
                      className={`flex flex-col items-center justify-center ${
                        isAPOnboarding ? `${classes.apAccountBox}` : 'account-box '
                      }`}
                    >
                      {selectedAccount && selectedAccount.Name === 'MyObAccountRightLive' && (
                        <img
                          className={isAPOnboarding ? 'mb-7' : 'pb-4'}
                          style={{width: '45%'}}
                          src={MyobLogo}
                          alt="Account"
                          data-autoid="imgMyob"
                        />
                      )}
                      {selectedAccount && selectedAccount.Name === 'QuickBooksOnline' && (
                        <img
                          className={isAPOnboarding ? 'mb-7' : 'pb-4'}
                          style={{width: '45%'}}
                          src={QBOLogo}
                          alt="Account"
                          data-autoid="imgQuickBook"
                        />
                      )}

                      {selectedAccount && selectedAccount.Name === 'Xero' && (
                        <img
                          className={isAPOnboarding ? 'mb-5' : 'pb-4'}
                          style={{width: '45%'}}
                          src={XeroLogo}
                          alt="Account"
                          data-autoid="imgXero"
                        />
                      )}

                      {selectedAccount && selectedAccount.Name === 'Parody' && (
                        <img
                          className={isAPOnboarding ? 'mb-7' : 'pb-4'}
                          style={{width: '45%'}}
                          src={ParodyLogo}
                          alt="Account"
                          data-autoid="imgXero"
                        />
                      )}

                      <SButton
                        color={'white'}
                        textColor={!isAuthorized ? 'blueShade' : 'greyTint'}
                        width={isAPOnboarding ? '120px' : 'm'}
                        height={isAPOnboarding ? '40px' : 'auto'}
                        lineHeight="m"
                        fontSize="m"
                        onClick={() => {
                          synkConnect();
                        }}
                        boxShadow="none"
                        padding={isAPOnboarding ? '9px 35px !important' : '6px 16px'}
                        backgroundColor="#E9EFF3"
                        variant={isAuthorized ? 'outlined' : 'contained'}
                        border={!isAuthorized ? '1px solid #1C78AD' : '1px solid transparent'}
                        disabled={isAuthorized || isSyncConnectLoading || isLoading}
                        borderRadius={isAPOnboarding ? '6px' : undefined}
                        fontWeight={isAPOnboarding ? 600 : undefined}
                        fontFamily={isAPOnboarding ? 'Poppins, sans-serif !important' : 'auto'}
                        data-autoid="btnAuthorise"
                      >
                        {!isAuthorized ? 'Authorise' : 'Authorised'}
                        {isSyncConnectLoading && (
                          <LoadingIndicator
                            isLoading={isSyncConnectLoading}
                            size="sm"
                            color="#2f97bc"
                            position={{right: '5px'}}
                          />
                        )}
                      </SButton>
                    </div>
                    {!isAuthorized && !isAPOnboarding && (
                      <p
                        id="simple-modal-description"
                        className="simple-modal-description-note p-2 text-center text-xs text-gray-600"
                        style={{lineHeight: '20px'}}
                      >
                        Please "<span className="font-semibold">Authorise</span>" to continue
                      </p>
                    )}
                  </div>
                </div>
                {!isAPOnboarding && (
                  <Box display="flex" justifyContent="center">
                    <SButton
                      className={'common-next-btn authorize-account-next-btn'}
                      type="submit"
                      color={'blueShade'}
                      width={'m'}
                      lineHeight={'m'}
                      fontSize={'m'}
                      disabled={(!isAuthorized ? true : false) || isLoading}
                      onClick={() => onNextClick()}
                      data-autoid="btnNext"
                    >
                      Next{' '}
                      {isLoading ? (
                        <LoadingIndicator isLoading={isLoading} size="sm" color="#FFF" position={{right: '5px'}} />
                      ) : (
                        <img src={NextArrowIcon} alt="Next Arrow Icon" className="" />
                      )}
                    </SButton>
                  </Box>
                )}
              </div>
            </>
          )}
          {!isAPOnboarding && (
            <div className="live-chat-section">
              <ChatWidget />
            </div>
          )}
          {isAPOnboarding && !showSelectOrganisations ? (
            <APFooter
              onNextClick={onNextClick}
              isDisabled={(!isAuthorized ? true : false) || isLoading}
              handleCancelClick={onCancelClick}
              secondaryButtonLabel="Skip"
              isCancelButton={!isAuthorized ? true : false}
              isLoading={isLoading}
            />
          ) : (
            isAPOnboarding &&
            showSelectOrganisations && (
              <APFooter
                onNextClick={() => submitMyobOrg()}
                isDisabled={isOrgLoading || Boolean(!orgUserName)}
                isLoading={isOrgLoading}
                buttonLabel="Login"
              />
            )
          )}
        </div>
      </div>
    </SModal>
  );
};
