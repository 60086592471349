import React from 'react';
import {ARDialogTemplate} from '../ARDialogTemplate';
import {Button, Typography} from 'spenda-ui-react';
import {useHistory, useParams} from 'react-router-dom';

interface ICOAWarningDialogProps {
  isOpen: boolean;
  onClose: () => void;
}
export const COAWarningDialog = (props: ICOAWarningDialogProps) => {
  // Props
  const {isOpen, onClose} = props;
  const history = useHistory();
  const {supplierId} = useParams<{supplierId?: string}>();

  return (
    <ARDialogTemplate
      dialogProps={{size: 'xs', open: isOpen, className: '!min-w-[460px] !max-w-[460px]'}}
      isFullScreen
      header={
        <p data-autoid={'lblWarningTitle'} className="font-poppins text-xl font-light text-[#333333]">
          Warning
        </p>
      }
      body={
        <div className="my-12">
          <Typography className="text-center font-light text-black-800" variant="paragraph">
            Rounding COA needs to be configured first
          </Typography>
        </div>
      }
      footer={
        <div className="flex w-full flex-row items-center justify-between">
          <Button
            data-autoid={`btnNo`}
            variant="outlined"
            className="bg-white"
            color="primary"
            onClick={() => onClose()}
          >
            No
          </Button>
          <Button
            data-autoid={`btnYes`}
            variant="filled"
            color="primary"
            onClick={() => history.push(`/supplier/${supplierId}/settings?tab=CustomPrefixSuffix`)}
          >
            Yes
          </Button>
        </div>
      }
    />
  );
};

export default COAWarningDialog;
