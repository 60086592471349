import React from 'react';
import {Box} from '@material-ui/core';
import {SModal} from '../../modals/modalSpendaMeterialUI';
import CloseIcon from '@material-ui/icons/Close';
import {APFooter} from '.././APFooter';

export interface ISkipAutorisationProps {
  isAPOnboardingV1?: boolean;
  handleSkip: () => void;
  handleClose: () => void;
}

export const SkipAutorisationModal = (props: ISkipAutorisationProps) => {
  const {isAPOnboardingV1, handleClose, handleSkip} = props;

  return (
    <SModal open>
      <div className={`flex h-full max-w-full items-center justify-center font-poppins text-xl`}>
        <div className={`modal-box !h-full max-h-[340px] max-w-[460px] px-5 pb-5 pt-3 text-spenda-primarytext`}>
          <Box className={`-mx-5 flex justify-between border-b border-[#cccccc] px-3 pb-3`}>
            <h2 className={`w-full text-center text-xl font-light`}>Skip authorisation</h2>
            <Box className="flex justify-end text-right">
              <span className="ap-onboarding-close-icon">
                <CloseIcon onClick={handleClose} data-autoid="btnClose" className="text-primary" />
              </span>
            </Box>
          </Box>
          <div className={`relative flex h-[64%] flex-row items-center justify-center font-poppins`}>
            <Box className={`w-10/12`}>
              <p className={`text-center text-sm text-[#707070]`}>
                Skipping this step will mean your invoices will not be displayed in the dashboard. To pay off invoices
                your accounts payable team will be required to upload ABA files for each payment.
              </p>
            </Box>
          </div>
          <APFooter
            isCancelButton={true}
            buttonLabel={'Yes, Skip'}
            onNextClick={() => handleSkip()}
            handleCancelClick={handleClose}
            isT2TSupplierModule={true}
            conditionallyClass={true}
            APFooterClass="!bottom-[10px] !left-[10px]"
            style={{width: 'calc(100% - 20px)'}}
            isAPOnboardingV1={isAPOnboardingV1}
          />
        </div>
      </div>
    </SModal>
  );
};
