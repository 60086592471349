import React, {FunctionComponent, useState, useContext, useEffect} from 'react';
import {ProductsCatalogue} from './ProductsCatalogue';
import useSupplierInventoryAPI from '../../services/useSupplierInventoryAPI';
import {IPagedActionResults} from '../../model/ActionResults';
import AppContext from '../../context/app/appContext';
import {IProductsFilter} from '../../model/search-filters/ProductsFilter';
import {SupplierInventory} from '../../model/inventory/SupplierInventory';
import {ICategory} from '../../model/inventory/Category';
import useCategoryAPI from '../../services/useCategoryAPI';
import {CategoryList} from './CategoryList';
import {ProductsFilter} from './ProductsFilter';
import {ICategoryFilter} from '../../model/SearchFilter';
import useBrandAPI from '../../services/useBrandAPI';
import {IBrand} from '../../model/inventory/Brand';
import {IBrandFilter} from '../../model/search-filters/BrandFilter';
import {Favourites} from './Favourites';
import {useParams} from 'react-router-dom';

interface ISupplierCataloguePanelProps {}

export const SupplierCataloguePanel: FunctionComponent<ISupplierCataloguePanelProps> = () => {
  const initialSelectedBrandFilterState = {
    ID: 0,
    Name: 'All Brands',
  } as IBrandFilter;

  const maxResults = 50;

  const appContext = useContext(AppContext);
  const {supplierId} = useParams<{supplierId: string}>();
  const [isShowFavourites, setIsShowFavourites] = useState(true);
  const [haveCheckedFavouritesExist, setHaveCheckedFavouritesExist] = useState(false);
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [brands, setBrands] = useState<IBrand[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<ICategory[]>([]);
  const [selectedBrand, setSelectedBrand] = useState<IBrand>({...initialSelectedBrandFilterState} as IBrand);
  const [isSearching, setIsSearching] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(true);

  const brandAPI = useBrandAPI();
  const categoryAPI = useCategoryAPI();
  const supplierInventoryAPI = useSupplierInventoryAPI();

  const initialCategoryFilterState = {
    MaxResults: 40,
    LinkedSupplierID: parseInt(supplierId),
    ParentID: undefined,
    // IsShowFavourites: isShowFavourites
  } as ICategoryFilter;

  const initialBrandFilter = {
    BrandID: 0,
    MaxResults: 100,
    LinkedSupplierID: parseInt(supplierId),
    // IsShowFavourites: isShowFavourites
  } as IBrandFilter;

  const initialProductFilterState = {
    // IsFirstSearch: true,
    MaxResults: maxResults,
    IsShowFavourites: isShowFavourites,
    LinkedSupplierID: parseInt(supplierId),
    SortAsc: true,
    SortField: 'ShortDescription',
    LastRowNumber: 0,
    IsShowMasterVariants: undefined,
    IsShowChildVariants: undefined,
    IsSold: true,
  } as IProductsFilter;

  const [products, setProducts] = useState({
    Value: [] as SupplierInventory[],
  } as IPagedActionResults<SupplierInventory[]>);

  const [inventoryFilter, setInventoryFilter] = useState<Partial<IProductsFilter>>(initialProductFilterState);
  const [categoryFilter, setCategoryFilter] = useState<Partial<ICategoryFilter>>(initialCategoryFilterState);
  const [brandFilter, setBrandFilter] = useState<Partial<ICategoryFilter>>(initialBrandFilter);

  const toggleFavourites = () => {
    setIsShowFavourites(!isShowFavourites);
  };

  const clearFilter = () => {
    setSelectedBrand({ID: 0, Name: 'All Brands'} as IBrand);
    setBrandFilter({...initialBrandFilter, IsShowFavourites: false});
    setSelectedCategories([]);
    setCategoryFilter({...initialCategoryFilterState, IsShowFavourites: false});
    if (isShowFavourites) {
      setInventoryFilter({
        ...initialProductFilterState,
        IsFreeTextSearch: true,
        SearchString: '',
        IsShowFavourites: true,
      });
    } else {
      setInventoryFilter({
        ...initialProductFilterState,
        IsFreeTextSearch: true,
        SearchString: '',
        IsShowFavourites: false,
      });
    }
  };

  const onSelectCategory = (cat: ICategory) => {
    setCategoryFilter({...categoryFilter, ParentID: cat && cat.ID});
    setInventoryFilter({
      ...inventoryFilter,
      MaxResults: 50,
      LastRowNumber: 0,
      CategoryID: cat && cat.ID,
      IsFreeTextSearch: true,
    });
    setSelectedCategories([...selectedCategories, cat]);
  };

  const onClearCategory = (cat: ICategory) => {
    const index = selectedCategories.findIndex(c => c.ID === cat.ID);
    const slice = index === 0 ? [] : selectedCategories.slice(0, index);
    const catId = slice.length ? slice[slice.length - 1].ID : undefined;

    setCategoryFilter({...categoryFilter, ParentID: catId});
    setInventoryFilter({...inventoryFilter, LastRowNumber: 0, CategoryID: catId, IsFreeTextSearch: true});
    setSelectedCategories(slice);
  };

  const onSelectBrand = (brand: IBrand) => {
    setSelectedBrand(brand);
    setBrandFilter({...brandFilter, BrandID: brand && brand.ID ? brand.ID : undefined});
    setInventoryFilter({
      ...inventoryFilter,
      LastRowNumber: 0,
      BrandID: brand && brand.ID ? brand.ID : undefined,
      IsFreeTextSearch: true,
    });
  };

  useEffect(() => {
    if (inventoryFilter.IsFreeTextSearch) {
      setIsSearching(true);
    }
    supplierInventoryAPI.supplierInventorySearch(inventoryFilter).then(results => {
      setTimeout(() => setIsSearching(false), 500);
      setProducts(prev => {
        return {
          ...results,
          Value: inventoryFilter.LastRowNumber === 0 ? results.Value : prev.Value.concat(results.Value),
        };
      });
      if (results.Value?.length === 0 && isShowFavourites && !haveCheckedFavouritesExist) {
        setHaveCheckedFavouritesExist(true);
        setIsShowFavourites(false);
      }
    });
  }, [inventoryFilter]);

  useEffect(() => {
    categoryAPI.search(categoryFilter).then(categories => setCategories(categories));
  }, [categoryFilter]);

  useEffect(() => {
    brandAPI.search(brandFilter).then(brands => setBrands([{...initialSelectedBrandFilterState} as IBrand, ...brands]));
  }, [brandFilter]);

  useEffect(() => {
    if (selectedBrand.ID) {
      setSelectedBrand({ID: 0, Name: 'All Brands'} as IBrand);
      setBrandFilter({...initialBrandFilter, IsShowFavourites: false});
    }
    if (selectedCategories?.length) {
      setSelectedCategories([]);
      setCategoryFilter({...initialCategoryFilterState, IsShowFavourites: false});
    }
    setInventoryFilter({
      ...initialProductFilterState,
      SearchString: '',
      IsShowFavourites: isShowFavourites,
      IsFreeTextSearch: true,
    });
  }, [isShowFavourites]);

  useEffect(() => {
    if (!appContext.marketplaceSupplier?.SupplierID) {
      return;
    }

    setInventoryFilter(filter => {
      return {...filter, LinkedSupplierID: appContext.marketplaceSupplier?.SupplierID};
    });
    setCategoryFilter(filter => {
      return {...filter, LinkedSupplierID: appContext.marketplaceSupplier?.SupplierID};
    });
    setBrandFilter(filter => {
      return {...filter, LinkedSupplierID: appContext.marketplaceSupplier?.SupplierID};
    });
  }, [appContext.marketplaceSupplier]);

  const searchMoreListProducts = (): Promise<void> => {
    if (products.MoreToGet) {
      setInventoryFilter(prev => {
        return {
          ...prev,
          LastRowNumber: (prev.LastRowNumber || 0) + maxResults,
          IsFreeTextSearch: false,
        };
      });
    }
    return Promise.resolve();
  };

  const toggleCategories = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div id="supplier-catalogue-panel" className="flex h-full flex-col rounded bg-white p-2">
      <div className="flex items-center justify-between">
        {/* <h1 style={{ fontFamily: "Saira Extra Condensed", fontSize: "x-large" }}>{ctx.marketplaceSupplier && ctx.marketplaceSupplier.TenantName} Catalogue</h1> */}
        {/* <img style={{ height: "80px" }} src="https://www.asca.com.au/sites/default/files/styles/member_image/public/logos/Craven%20Foods%20Logo.jpg?itok=58mQ3TdI" alt="Supplier Logo" /> */}
      </div>
      <Favourites
        searchFilter={inventoryFilter}
        updateFilter={setInventoryFilter}
        toggleFavourites={toggleFavourites}
      />

      <CategoryList
        categories={categories}
        selectedCategories={selectedCategories}
        selectCategory={onSelectCategory}
        deselectCategory={onClearCategory}
        updateFilter={setCategoryFilter}
        toggleCategories={toggleCategories}
        isCollapsed={isCollapsed}
      />

      <ProductsFilter
        clearFilter={clearFilter}
        totalProductsNumber={products.TotalRecordCount}
        pagedProductsNumber={products.Value?.length}
        searchFilter={inventoryFilter}
        updateFilter={setInventoryFilter}
        updateCategoryFilter={setCategoryFilter}
        brands={brands}
        onSelectedBrand={onSelectBrand}
        selectedBrand={selectedBrand}
      ></ProductsFilter>

      <ProductsCatalogue
        totalProductsNumber={products.TotalRecordCount}
        getMoreProducts={searchMoreListProducts}
        isShowFavourites={isShowFavourites}
        products={products.Value}
        isSearching={isSearching}
        toggleCategories={toggleCategories}
        isCollapsed={isCollapsed}
      />
    </div>
  );
};
