import React from 'react';
import {FormControlLabel, createStyles, withStyles, Theme} from '@material-ui/core';
import {Styles} from 'jss';
import Switch, {SwitchProps} from '@material-ui/core/Switch';
import {createAutoIdAttributeFromReactProps} from '../../utils/createAutoIdAttribute';

interface Props extends SwitchProps {
  classes: Styles;
}

export const IOSSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 42,
      height: 24,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(16px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: '#3C9F78',
          opacity: 1,
          border: 'none',
        },
      },
      '&$disabled': {
        cursor: 'not-allowed',
      },
    },
    thumb: {
      width: '23px',
      height: '22px',
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    disabled: {
      background: 'red',
    },
    //  focusVisible: {},
  })
)(({classes, ...props}: Props) => {
  const {autoIdAttribute} = createAutoIdAttributeFromReactProps(props, 'chk');
  return <Switch inputProps={{...props.inputProps, ...autoIdAttribute}} disableRipple classes={classes} {...props} />;
});

export const SwitchSlider = (props: {
  label?: string | React.ReactNode;
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  checked: boolean;
  disabled?: boolean;
  name: string;
  labelPlacement?: 'bottom' | 'top' | 'end' | 'start' | undefined;
  bgColor?: 'green' | 'blue';
  className?: string;
  dataAutoId?: string;
}) => {
  return (
    <FormControlLabel
      control={
        <IOSSwitch
          onChange={props.handleChange}
          className={`w-auto m-0 pt-4 ${props.disabled ? 'opacity-50' : ''} ${props.className}`}
          value="checkedB"
          checked={props.checked}
          name={props.name}
          disabled={props.disabled!}
          // bgColor={props.bgColor}
        />
      }
      label={props.label}
      labelPlacement={props.labelPlacement}
    />
  );
};
