import React, {useState, useEffect, useContext, useRef} from 'react';
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
} from '@material-ui/core';
import {SDialog} from '../modals/modalSpendaMeterialUI';
import {SButton} from '../buttons/Button';
import {css} from 'glamor';
import {AlertDialogSlide} from './AlertDialogSlide';
import {CurrencyTextField, STextField} from '../inputs/STextField';
import {useCreditClaimsAPI} from '../../services/useCreditClaimsAPI';
import {ICreditClaim} from '../../model/credit-notes/CreditClaims';
import AppContext from '../../context/app/appContext';
import _ from 'lodash';
import LoadingIndicator from '../ui/LoadingIndicator';
import {toFixedWithoutRounding} from '../../utils/formatter';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import CloseIcon from '@material-ui/icons/Close';
import {DestructiveButton, PrimaryButton, SecondaryButton} from '../buttons/DefaultButtons';
interface ICreateCliamDialogProps {
  claimCode?: string;
  claimId?: number;
  handleDialog: () => void;
  handleDeleteClaim: (claimCode: string) => void;
  handleAddClaim: (claimCode: string) => void;
  handleEditClaim: (claimCode: string) => void;
  isCompactView?: boolean;
}

export const CreateClaimDialog = (props: ICreateCliamDialogProps) => {
  const {handleDialog, claimCode, claimId, handleDeleteClaim, handleAddClaim, handleEditClaim, isCompactView} = props;
  const {marketplaceSupplier} = useContext(AppContext);
  const [showAlertDialog, setShowAlertDialog] = useState<boolean>(false);
  const [description, setDescription] = useState<string>('');
  const [descriptionError, setDescriptionError] = useState<string>('');
  const [amount, setAmount] = useState<string>('0.00');
  const [amountError, setAmountError] = useState<string>('');
  const [claim, setClaim] = useState<ICreditClaim>();
  const {getClaim, saveClaim: saveClaimAPI, deleteClaim} = useCreditClaimsAPI();

  const isLoadingSaveClaim = useRef(false);
  const isLoadingDeleteClaim = useRef(false);

  useEffect(() => {
    getClaimData();
  }, [claimId]);

  const getClaimData = async () => {
    const _claim = await getClaim(marketplaceSupplier?.SupplierID!, claimId, claimCode);
    setAmount(_claim?.TotalInc?.toFixed(2)?.toString() ?? '0.00');
    setDescription(_claim?.Lines?.[0]?.Description);
    setClaim(_claim);
  };

  const customMargin = css({
    marginRight: '10px !important',
  });

  const handleAlertDialog = () => {
    setShowAlertDialog(prevState => !prevState);
  };

  const handleInputChange = (type: string, value: string) => {
    if (type === 'description') {
      setDescription(value);
      if (!value?.trim()) {
        setDescriptionError('Please enter a description');
      } else {
        setDescriptionError('');
      }
    } else if (type === 'amount') {
      const valueString = value.toString();
      const valueStringArray = valueString.split('.');
      const decimalPlacesString = valueStringArray.length > 1 ? valueStringArray[valueStringArray.length - 1] : '';

      if (!value?.trim() || isNaN(parseFloat(value)) || parseFloat(value) <= 0.0) {
        setAmountError('Please enter an amount');
        setAmount(value);
      } else {
        if (decimalPlacesString?.length > 2) {
          setAmount(toFixedWithoutRounding(parseFloat(value)));
        } else {
          setAmount(value);
        }
        setAmountError('');
      }
    }
  };

  const handleDelete = async (confirm: boolean) => {
    if (skipAction()) {
      return;
    }

    handleAlertDialog();
    if (!confirm) {
      // handleDialog();
      return;
    }

    isLoadingDeleteClaim.current = true;

    if (!claimCode || !claimId) {
      return;
    }

    await deleteClaim(claimId);
    await handleDeleteClaim(claimCode);

    isLoadingDeleteClaim.current = false;
  };

  const saveClaim = async () => {
    if (skipAction()) {
      return;
    }

    isLoadingSaveClaim.current = true;
    const tempClaim = _.cloneDeep(claim);
    if (tempClaim) {
      const claimLineItem = tempClaim?.Lines?.[0];
      if (claimLineItem) {
        claimLineItem.AmountInc = parseFloat(amount);

        // update description on claim and line items
        claimLineItem.Description = description;
        claimLineItem.ShortDescription = description;
        tempClaim.Description = description;

        claimLineItem.AmountEx = 0;
        tempClaim.Lines[0] = claimLineItem;
      }

      await saveClaimAPI(tempClaim);

      if (claimCode) {
        await handleEditClaim(tempClaim.ClaimCode);
      } else {
        await handleAddClaim(tempClaim.ClaimCode);
      }
    }
    isLoadingSaveClaim.current = false;
  };

  const skipAction = () => {
    return isLoadingDeleteClaim.current || isLoadingSaveClaim.current;
  };
  const useStyles = makeStyles({
    dialog: {
      width: isCompactView ? '20rem' : '',
      height: isCompactView ? '15.875rem' : '',
    },
    title: {
      '& .MuiTypography-h6': {
        fontSize: '22px',
        fontFamily: 'Poppins',
        fontWeight: 300,
      },
    },
    inputField: {
      '& .MuiOutlinedInput-multiline': {
        padding: '8px 14px',
      },
      '& .MuiInputAdornment-root': {
        display: 'none',
      },
      '& .MuiOutlinedInput-root': {
        '&.Mui-focused': {
          '& .MuiInputAdornment-root': {
            display: 'contents',
          },
        },
      },
      '& .MuiInputLabel-outlined': {
        transform: 'translate(14px, 16px) scale(1)',
        fontFamily: 'Poppins !important',
        fontSize: '14px',
        fontWeight: 500,
        width: 'auto',
        color: '#bbbbbb !important',
      },
      '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        transform: 'translate(14px, -4.7px) scale(1)',
        fontSize: '10px',
        color: '#333 !important',
      },
      '& legend': {
        width: '140px',
      },
      '& textarea, input': {
        fontFamily: 'Poppins !important',
      },
      '& .MuiFormHelperText-root.Mui-error': {
        fontFamily: 'Poppins !important',
        position: 'relative !important',
        top: '0px !important',
      },
      '& .MuiInputAdornment-positionEnd button': {
        padding: '0px !important',
      },
    },
    inputText2: {
      '& legend': {
        width: '78px',
      },
      '& .MuiTypography-colorTextSecondary': {
        fontFamily: 'poppins',
      },
      '& .MuiTextField-root': {
        paddingBottom: '2px',
      },
    },
    iconRoot: {
      color: '#BBB!important',
      fontSize: '1.25rem',
      marginRight: '-15px',
      '& .MuiSvgIcon-root': {
        color: 'red !important',
      },
    },
    deleteButton: {
      margin: '0px !important',
    },
    labelError: {
      '& .MuiInputLabel-root': {
        transform: 'translate(14px, 9px) scale(1)!important',
        '&.MuiInputLabel-shrink': {
          transform: 'translate(14px, -6px) scale(1)!important',
        },
      },
      '& .MuiInputAdornment-positionEnd': {
        display: 'none',
      },
      '& legend': {
        width: '130px',
      },
    },
  });

  const classes = useStyles();

  //TODO: remove isCompact check for color, fontFamily...and for other attributes wherever possible while making changes for rebranding the website

  const CancelButton = () => {
    return !isCompactView ? (
      <SecondaryButton label="Cancel" className={`${customMargin}`} onClick={skipAction() ? undefined : handleDialog} />
    ) : (
      <SButton
        className={isCompactView ? '' : `${customMargin}`}
        lineHeight="l"
        width={isCompactView ? '6rem' : 'm'}
        height={isCompactView ? '40px' : undefined}
        borderRadius={isCompactView ? '6px' : undefined}
        fontFamily={isCompactView ? 'Poppins' : undefined}
        border={isCompactView ? '1px solid #1C78AD' : ''}
        textColor={isCompactView ? 'blueShade' : 'white'}
        color={isCompactView ? 'white' : 'yellow'}
        fontSize={isCompactView ? '0.875rem' : '1rem'}
        onClick={skipAction() ? undefined : handleDialog}
      >
        Cancel
      </SButton>
    );
  };

  const SaveButton = () => {
    return !isCompactView ? (
      <PrimaryButton
        data-autoid="btnSaveCreditClaim"
        label="Save"
        onClick={saveClaim}
        width={'6rem'}
        disabled={
          Boolean(!description) ||
          Boolean(!amount) ||
          Boolean(descriptionError) ||
          Boolean(amountError) ||
          isLoadingSaveClaim.current
        }
        isSubmitting={isLoadingSaveClaim.current}
      />
    ) : (
      <SButton
        data-autoid="btnSaveCreditClaim"
        color={isCompactView ? 'blueShade' : 'darkBlue'}
        lineHeight="l"
        width={isCompactView ? '6rem' : 'm'}
        height={isCompactView ? '40px' : undefined}
        borderRadius={isCompactView ? '6px' : undefined}
        fontFamily={isCompactView ? 'Poppins' : undefined}
        fontSize={isCompactView ? '0.875rem' : '1rem'}
        onClick={saveClaim}
        disabled={
          Boolean(!description) ||
          Boolean(!amount) ||
          Boolean(descriptionError) ||
          Boolean(amountError) ||
          isLoadingSaveClaim.current
        }
      >
        Save
        {isLoadingSaveClaim && (
          <LoadingIndicator isLoading={isLoadingSaveClaim.current} size="sm" color={'#FFF'} position={{right: '5px'}} />
        )}
      </SButton>
    );
  };

  const DeleteButton = () => {
    return (
      <DestructiveButton
        label="Delete"
        onClick={handleAlertDialog}
        disabled={isLoadingDeleteClaim.current}
        isSubmitting={isLoadingDeleteClaim.current}
        className={classes.deleteButton}
      />
    );
  };

  return (
    <>
      <SDialog open maxWidth="sm" fullWidth classes={{paper: classes.dialog}}>
        {isCompactView ? (
          <DialogTitle className={`text-center ${classes.title}`}>
            {claimCode && claimId ? 'Edit Credit Claim' : 'New Credit Claim'}
          </DialogTitle>
        ) : (
          <Box className={`mb-12 flex justify-between border !border-[#ccc] border-b-default p-3`}>
            <span></span>
            <DialogTitle className={`!p-0 text-center`}>
              <h2 className={`spenda-primarytext font-poppins text-[24px] font-light`}>
                {claimCode && claimId ? 'Edit credit request' : 'Create new claim request'}
              </h2>
            </DialogTitle>
            <Box className="flex justify-end text-right">
              <span
                className="ap-onboarding-close-icon"
                data-autoid="btnCloseBtn"
                onClick={skipAction() ? undefined : handleDialog}
              >
                <CloseIcon className="text-[#1c78ad]" />
              </span>
            </Box>
          </Box>
        )}

        <DialogContent
          style={{padding: isCompactView ? '6px 8px 2px' : '8px 60px 15px', overflow: isCompactView ? '' : 'hidden'}}
        >
          {showAlertDialog && <AlertDialog isCompactView={isCompactView || false} handleDelete={handleDelete} />}
          {isCompactView ? (
            <Grid container className={`w-full flex-col ${classes.inputField}`}>
              <Grid item>
                <Box>
                  <STextField
                    backgroundColor="#FFF"
                    variant="outlined"
                    name="description"
                    helperText={descriptionError}
                    error={Boolean(descriptionError)}
                    label="Claim Request Description"
                    value={description}
                    onChange={e => handleInputChange('description', e.target.value)}
                    fullWidth
                    inputProps={{
                      maxLength: 500,
                    }}
                    InputProps={{
                      endAdornment: description.length ? (
                        <InputAdornment position="end">
                          <IconButton
                            data-autoid="clearDescription"
                            disabled={!description.length}
                            onClick={() => setDescription('')}
                            onMouseDown={e => e.preventDefault()}
                          >
                            <ClearRoundedIcon className={`${classes.iconRoot} `} />
                          </IconButton>
                        </InputAdornment>
                      ) : (
                        ''
                      ),
                    }}
                    multiline
                  />
                </Box>
              </Grid>
              <Grid item>
                <Box className={classes.inputText2}>
                  <STextField
                    backgroundColor="#FFF"
                    variant="outlined"
                    name="amount"
                    helperText={amountError}
                    error={Boolean(amountError)}
                    label="Amount (INC)"
                    value={amount}
                    type="number"
                    onChange={e => handleInputChange('amount', e.target.value)}
                    fullWidth
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      endAdornment: amount !== '0.00' && amount !== undefined && (
                        <InputAdornment position="end">
                          <IconButton
                            data-autoid="clearAmount"
                            disabled={amount === '0.00'}
                            onClick={() => setAmount('0.00')}
                            onMouseDown={e => e.preventDefault()}
                          >
                            <ClearRoundedIcon className={`${classes.iconRoot} `} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Box>
                  <STextField
                    v2
                    name="description"
                    variant="outlined"
                    error={Boolean(descriptionError)}
                    data-autoid="txtClaimDesc"
                    label={Boolean(descriptionError) ? descriptionError : 'Description'}
                    value={description}
                    onChange={e => {
                      handleInputChange('description', e.target.value);
                    }}
                    fullWidth
                    maxLength={500}
                    InputProps={{
                      endAdornment: description.length ? (
                        <InputAdornment position="end">
                          <IconButton
                            data-autoid="clearDescription"
                            disabled={!description.length}
                            onClick={() => setDescription('')}
                            onMouseDown={e => e.preventDefault()}
                          >
                            <ClearRoundedIcon className={`${classes.iconRoot} `} />
                          </IconButton>
                        </InputAdornment>
                      ) : (
                        ''
                      ),
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={4} className={classes.inputText2}>
                <Box className={Boolean(amountError) ? `${classes.labelError}` : ''}>
                  <CurrencyTextField
                    variant="outlined"
                    name="amount"
                    label={Boolean(amountError) ? amountError : 'Amount (Inc)'}
                    error={Boolean(amountError)}
                    data-autoid="txtClaimAmount"
                    value={amount}
                    onChange={e => handleInputChange('amount', e.target.value)}
                    fullWidth
                    InputProps={{
                      endAdornment: amount !== '0.00' && amount !== undefined && (
                        <InputAdornment position="end">
                          <IconButton
                            data-autoid="clearAmount"
                            disabled={amount === '0.00'}
                            onClick={() => setAmount('0.00')}
                            onMouseDown={e => e.preventDefault()}
                          >
                            <ClearRoundedIcon className={`${classes.iconRoot} `} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions className={isCompactView ? '' : 'h-15 mb-3 mt-8 w-full'}>
          {!isCompactView ? (
            <Box
              className={`${
                claimId && claimCode
                  ? 'absolute left-2.5 flex-row justify-between rounded-[8px] bg-[#f2f2f2] p-2'
                  : 'w-full justify-center'
              } flex`}
              style={{width: claimId && claimCode ? 'calc(100% - 20px)' : ''}}
            >
              <Box>{claimId && claimCode ? <DeleteButton /> : ''}</Box>
              <Box>
                <SaveButton />
              </Box>
            </Box>
          ) : (
            <Box className="flex w-full justify-between">
              <CancelButton />
              {claimId && claimCode ? <DeleteButton /> : null}
              <SaveButton />
            </Box>
          )}
        </DialogActions>
      </SDialog>
    </>
  );
};

export const AlertDialog = (props: {isCompactView: boolean; handleDelete: (confirm: boolean) => void}) => {
  const {isCompactView} = props;
  return (
    <AlertDialogSlide
      title={isCompactView ? 'Delete Credit Claim' : 'Delete Claim Request'}
      fontFamily={isCompactView ? 'poppins' : undefined}
      dialogActionsAlignment={isCompactView ? 'space-between' : 'flex-end'}
      dialogHeight={isCompactView ? '15.875rem' : undefined}
      dialogWidth={isCompactView ? '20rem' : undefined}
      paddingX={isCompactView ? 48 : undefined}
      headerFontWeight={isCompactView ? 300 : undefined}
      alignContentText={isCompactView ? 'center' : undefined}
      alignContentItems={isCompactView ? 'center' : undefined}
      actions={[
        {
          label: 'Cancel',
          variant: 'outlined',
          action: () => {
            props.handleDelete(false);
          },
          color: isCompactView ? 'white' : 'yellow',
          textColor: isCompactView ? 'blue' : undefined,
          width: isCompactView ? '6rem' : undefined,
          height: isCompactView ? '40px' : undefined,
          fontFamily: isCompactView ? 'Poppins' : undefined,
          fontSize: isCompactView ? '0.875rem' : undefined,
          border: isCompactView ? '1px solid #1C78AD' : '',
          borderRadius: isCompactView ? '6px' : undefined,
        },
        {
          label: 'Delete',
          action: () => {
            props.handleDelete(true);
          },
          color: isCompactView ? 'red' : 'blue',
          width: isCompactView ? '6rem' : undefined,
          height: isCompactView ? '40px' : undefined,
          borderRadius: isCompactView ? '6px' : undefined,
          fontFamily: isCompactView ? 'Poppins' : undefined,
          fontSize: isCompactView ? '0.875rem' : undefined,
        },
      ]}
    >
      <p className="pb-3">Are you sure you would like to delete this claim request?</p>
    </AlertDialogSlide>
  );
};
