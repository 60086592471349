import {IActionResultsList} from '../model/ActionResults';
import useHttp from '../hooks/useHttp';
import {IScheduledTask} from '../model/scheduled-task/ScheduledTask';
import {ISearchFilter} from '../model/search-filters/SearchFilter';

const useScheduledTaskAPI = () => {
  const {isLoading, GET, POST} = useHttp();

  const getScheduledTask = async (filter: ISearchFilter): Promise<IScheduledTask[]> => {
    return GET('scheduledtasks', filter).then((data: IActionResultsList<IScheduledTask>) => data.Items);
  };

  const updateScheduledTask = async (payload: Partial<Partial<IScheduledTask>[]>): Promise<void> => {
    return POST('scheduledtasks', payload);
  };

  return {
    getScheduledTask,
    updateScheduledTask,
    isLoading,
  };
};

export default useScheduledTaskAPI;
