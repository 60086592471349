import React, {useEffect, useState} from 'react';
import {Typography} from 'spenda-ui-react';
import {Link} from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';

import {ARTable, IARColumns} from '../../../components/AccountsReceivable/ARTable';
import {AUTH_SELLING_SERVICE_CUSTOMERS_DETAILS} from '../../../routes/ServiceManagementRoutes';
import {ICustomerList} from '../../../model/service-management/serviceJob';
import {SPagination} from '../../../components/tables/SPagination';

interface IServiceHistoryList {
  columnOptions: string[];
  customers: ICustomerList[];
  pageSize: number;
  totalCount: number;
  currentPage: number;
  handlePageChange: (newPage: number) => void;
  handleRowsPerPageChange: (rows: number) => void;
}

const ServiceHistoryList = (props: IServiceHistoryList) => {
  const {columnOptions, customers, pageSize, totalCount, currentPage, handlePageChange, handleRowsPerPageChange} =
    props;

  const [customerListColumns, setCustomerListColumns] = useState<IARColumns[]>();

  useEffect(() => {
    if (
      customerListColumns
        ?.map(item => item.key)
        .sort()
        .join('.') != columnOptions?.sort().join(',')
    ) {
      const columns = (customerListColumns && [...customerListColumns]) || [];
      columns && _.remove(columns);
      if (columnOptions?.includes('Customer name')) {
        columns?.push({
          title: 'Customer name',
          key: 'customerName',
          width: '12%',
          align: 'left',
          rowClassName: 'p-2.5',
          rowRenderer: (row: ICustomerList) => {
            return (
              <Typography variant="small" className="cursor-pointer text-primary underline">
                <Link
                  to={`${AUTH_SELLING_SERVICE_CUSTOMERS_DETAILS.replace(':customerID', row?.customerID.toString())}`}
                >
                  {row.customerName}
                </Link>
              </Typography>
            );
          },
        });
      }
      if (columnOptions?.includes('Company name')) {
        columns?.push({
          title: 'Company name',
          key: 'companyName',
          width: '9%',
          align: 'left',
          rowClassName: 'p-2.5',
          rowRenderer: (row: ICustomerList) => {
            return (
              <Typography variant="small" className="text-black-800">
                {row.customerName ? row.companyName : '-'}
              </Typography>
            );
          },
        });
      }
      if (columnOptions?.includes('Customer ABN')) {
        columns?.push({
          title: 'Customer ABN',
          key: 'customerABN',
          width: '8%',
          rowClassName: 'p-2.5',
          rowRenderer: (row: ICustomerList) => {
            return (
              <Typography variant="small" className="text-black-800">
                {row.abn ? row.abn : '-'}
              </Typography>
            );
          },
        });
      }
      if (columnOptions?.includes('Service address')) {
        columns?.push({
          title: 'Service address',
          key: 'serviceAddress',
          width: '14%',
          rowClassName: 'p-2.5',
          rowRenderer: (row: ICustomerList) => {
            return (
              <Typography variant="small" className="text-black-800">
                {row.siteStreetName} {row.siteCity} {row.siteCountry} {row.siteState} {row.sitePostCode}
              </Typography>
            );
          },
        });
      }
      if (columnOptions?.includes('Email address')) {
        columns?.push({
          title: 'Email address',
          key: 'emailAddress',
          width: '16%',
          rowClassName: 'p-2.5',
          rowRenderer: (row: ICustomerList) => {
            return (
              <Typography variant="small" className="text-black-800">
                {row.onSiteContactEmailAddress ? row.onSiteContactEmailAddress : '-'}
              </Typography>
            );
          },
        });
      }
      if (columnOptions?.includes('Phone number')) {
        columns?.push({
          title: 'Phone number',
          key: 'phoneNumber',
          width: '10%',
          align: 'center',
          rowClassName: 'p-2.5',
          rowRenderer: (row: ICustomerList) => {
            return (
              <Typography variant="small" className="text-black-800">
                {row.onSiteContactPhone ? row.onSiteContactPhone : '-'}
              </Typography>
            );
          },
        });
      }
      if (columnOptions?.includes('Open job count')) {
        columns?.push({
          title: 'Open job count',
          key: 'openJobCount',
          width: '8%',
          align: 'right',
          rowClassName: 'p-2.5',
          rowRenderer: (row: ICustomerList) => {
            return (
              <Typography variant="small" className="text-black-800">
                {row.countOpen}
              </Typography>
            );
          },
        });
      }
      if (columnOptions?.includes('In progress jobs')) {
        columns?.push({
          title: 'In progress jobs',
          key: 'inProgressJobs',
          width: '8%',
          align: 'right',
          rowClassName: 'p-2.5',
          rowRenderer: (row: ICustomerList) => {
            return (
              <Typography variant="small" className="text-black-800">
                {row.countInProgress}
              </Typography>
            );
          },
        });
      }
      if (columnOptions?.includes('Asset count')) {
        columns?.push({
          title: 'Asset count',
          key: 'assetCount',
          width: '8%',
          align: 'right',
          rowClassName: 'p-2.5',
          rowRenderer: (row: ICustomerList) => {
            return (
              <Typography variant="small" className="text-black-800">
                {row.countAssets}
              </Typography>
            );
          },
        });
      }
      if (columnOptions?.includes('Last service date')) {
        columns?.push({
          title: 'Last service date',
          key: 'lastServiceDate',
          width: '12%',
          align: 'right',
          rowClassName: 'p-2.5',
          rowRenderer: (row: ICustomerList) => {
            return (
              <Typography variant="small" className="text-black-800">
                {row.lastServiceDate ? moment(row.lastServiceDate).format('DD-MM-YYYY') : '-'}
              </Typography>
            );
          },
        });
      }
      setCustomerListColumns(columns);
    }
  }, [columnOptions]);

  return (
    <div className="h-full w-full">
      <div className="my-2 h-1 w-full border-b border-b-[#ECECEC]" />
      <div className={`h-[calc(100vh-260px)]`}>
        {customerListColumns && (
          <ARTable
            isHighlightRowOnHover
            columns={customerListColumns}
            scope="AR"
            rows={customers}
            isLoading={false}
            tableClass="!mx-0 mt-2.5"
            getRowDataAutoId={(rowData: ICustomerList) => `rowServiceID-${rowData?.customerID}`}
          />
        )}
      </div>
      <SPagination
        currentPage={currentPage}
        totalCount={totalCount}
        pageSize={pageSize}
        rowsPerPageOptions={[10, 15, 25]}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
    </div>
  );
};

export default ServiceHistoryList;
