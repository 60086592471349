import React from 'react';
import {Switch} from 'react-router-dom';
import {PrivateRoute} from './PrivateRoute';
import {MenuTypes, ModuleTypes} from '../model/constants/Constants';
import {SalesOrderList} from '../screens/sales-order/SalesOrderList';
import {CreateSalesOrder} from '../screens/sales-order/CreateSalesOrder';
import {SalesOrderDetails} from '../screens/sales-order/SalesOrderDetails';
import {SalesOrderPick} from '../screens/sales-order/SalesOrderPick';
import {SalesOrderPack} from '../screens/sales-order/SalesOrderPack';
import {SalesOrderShip} from '../screens/sales-order/SalesOrderShip';

// Sales Order Management Routes
export const AUTH_SELLING_SALES_ORDER_LIST = '/sales-orders';
export const AUTH_SELLING_SALES_ORDER_CREATE = '/sales-orders/create';
export const AUTH_SELLING_SALES_ORDER_VIEW = '/sales-orders/edit/:orderID';
export const AUTH_SELLING_SALES_ORDER_PICK = '/sales-orders/pick/:pickSlipID';
export const AUTH_SELLING_SALES_ORDER_PACK = '/sales-orders/pack/:pickSlipID/:consignmentNoteID?';
export const AUTH_SELLING_SALES_ORDER_SHIP = '/sales-orders/ship/:consignmentNoteID';

export const SalesOrderRoutes = () => {
  return (
    <Switch>
      <PrivateRoute
        path={AUTH_SELLING_SALES_ORDER_LIST}
        exact
        moduleIds={[ModuleTypes.SalesOrderManagement]}
        Component={SalesOrderList}
        MenuFilters={{
          MenuType: MenuTypes.Selling,
          ModuleID: ModuleTypes.SalesOrderManagement,
          ModuleDependency: [ModuleTypes.SalesOrderManagement],
        }}
      />
      <PrivateRoute
        path={AUTH_SELLING_SALES_ORDER_CREATE}
        exact
        moduleIds={[ModuleTypes.SalesOrderManagement]}
        Component={CreateSalesOrder}
        MenuFilters={{
          MenuType: MenuTypes.Selling,
          ModuleID: ModuleTypes.SalesOrderManagement,
          ModuleDependency: [ModuleTypes.SalesOrderManagement],
        }}
      />
      <PrivateRoute
        path={AUTH_SELLING_SALES_ORDER_VIEW}
        exact
        moduleIds={[ModuleTypes.SalesOrderManagement]}
        Component={SalesOrderDetails}
        MenuFilters={{
          MenuType: MenuTypes.Selling,
          ModuleID: ModuleTypes.SalesOrderManagement,
          ModuleDependency: [ModuleTypes.SalesOrderManagement],
        }}
      />
      <PrivateRoute
        path={AUTH_SELLING_SALES_ORDER_PICK}
        exact
        moduleIds={[ModuleTypes.SalesOrderManagement]}
        Component={SalesOrderPick}
        MenuFilters={{
          MenuType: MenuTypes.Selling,
          ModuleID: ModuleTypes.SalesOrderManagement,
          ModuleDependency: [ModuleTypes.SalesOrderManagement],
        }}
      />
      <PrivateRoute
        path={AUTH_SELLING_SALES_ORDER_PACK}
        exact
        moduleIds={[ModuleTypes.SalesOrderManagement]}
        Component={SalesOrderPack}
        MenuFilters={{
          MenuType: MenuTypes.Selling,
          ModuleID: ModuleTypes.SalesOrderManagement,
          ModuleDependency: [ModuleTypes.SalesOrderManagement],
        }}
      />
      <PrivateRoute
        path={AUTH_SELLING_SALES_ORDER_SHIP}
        exact
        moduleIds={[ModuleTypes.SalesOrderManagement]}
        Component={SalesOrderShip}
        MenuFilters={{
          MenuType: MenuTypes.Selling,
          ModuleID: ModuleTypes.SalesOrderManagement,
          ModuleDependency: [ModuleTypes.SalesOrderManagement],
        }}
      />
    </Switch>
  );
};
