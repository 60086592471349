import React, {useState} from 'react';
import {SDialog} from '../modals/modalSpendaMeterialUI';
import {Box, DialogActions, DialogContent, DialogTitle, makeStyles} from '@material-ui/core';
import {SupportDialog} from './SupporDialog';
import {ErrorAnimation, SuccessAnimation} from '../../assets/svg/animations/LottieAnimations';
import useSpendaPaymentServicesAPI, {IAccountResponse} from '../../services/useSpendaPaymentServicesAPI';
import {PaymentProviderName} from '../../model/constants/Constants';
import {PennyVerificationDialogStages} from './PennyVerificationDialog';
import {Toast} from '../../utils/Toast';
import LiveChatIcon from '../../assets/svg/live-chat-blue-icon.svg';
import {SButton} from '../buttons/Button';

interface IFailedPennyVerificationDialogProps {
  onClose: () => void;
  onRemoveAccount?: () => void;
  bankAccountDetails: IAccountResponse;
  dialogStage: PennyVerificationDialogStages;
  setDialogStage: (newStage: PennyVerificationDialogStages) => void;
  supplierId?: number;
  reloadAccount: () => void;
  isCreditCard: boolean;
}

export const FailedPennyVerificationDialog = (props: IFailedPennyVerificationDialogProps) => {
  const [isShowSupportDialog, setIsShowSupportDialog] = useState(false);

  const useStyles = makeStyles({
    dialog: {
      fontSize: 22,
      fontWeight: 500,
      justifyContent: 'center',
      maxWidth: 600,
      minWidth: 600,
      minHeight: 670,
      position: 'absolute',
      textAlign: 'center',
    },
    dialogTitle: {
      color: '#0082BA',
      paddingTop: 38,
    },
    dialogActions: {
      justifyContent: 'space-around',
      paddingBottom: 34,
    },
  });
  const classes = useStyles();

  const {retryVerification} = useSpendaPaymentServicesAPI();

  const det = props.bankAccountDetails.cardDetails;

  const onSupport = () => {
    setIsShowSupportDialog(true);
  };
  const onRemoveAccount = () => {
    if (props.onRemoveAccount) {
      props.onRemoveAccount();
    } else {
      // Should never be hit
      Toast.error("An error occured: didn't have required properties to remove account.");
    }
    props.setDialogStage(PennyVerificationDialogStages.NONE);
  };

  const bankDetails = props.bankAccountDetails?.bankDetails;

  const onRetry = () => {
    const accountGUID = props.bankAccountDetails?.accountGUID!;
    props.setDialogStage(PennyVerificationDialogStages.CHECKING_BANK_ACCOUNT_STATUS);
    retryVerification(PaymentProviderName.Spenda_Payment_Services, accountGUID).then(() => {
      props.reloadAccount();
    });
  };

  const SpendaFail = () => {
    return (
      <>
        <DialogContent>
          <Box className="py-2 font-poppins p-1">
            <Box display="flex" justifyContent="center">
              <Box width="35%" className="py-2 font-poppins pt-10">
                <ErrorAnimation />
              </Box>
            </Box>
            {props.isCreditCard ? (
              <Box>
                <Box paddingTop={2}>We were unable to charge your card.</Box>
                <Box paddingTop={2}>
                  The details you provided appear to be incorrect: <br />
                  Last four digits: {det.last4} <br /> Expiry date: {det.expiry} <br /> Name on card:{' '}
                  {det.accountHolderName}
                  <br />
                </Box>
                <Box paddingTop={2}>Would you like to remove this card and try again ?</Box>
              </Box>
            ) : (
              <Box>
                <Box paddingTop={2}>Payment to your nominated bank account failed.</Box>
                <Box paddingTop={2}>
                  The details you provided appear to be incorrect: <br />
                  <p>BSB: {bankDetails?.bankBSB}</p>
                  <br />
                  <p>Account number: {bankDetails?.bankAccountNumber}</p>
                </Box>
                <Box paddingTop={2}>Would you like to remove this account and try again ?</Box>
              </Box>
            )}
          </Box>
        </DialogContent>
      </>
    );
  };

  const UserFail = () => {
    return (
      <>
        {
          <DialogContent>
            <Box>
              <Box display="flex" justifyContent="center">
                <Box width="62%">
                  <ErrorAnimation />
                </Box>
              </Box>
              <Box paddingTop={4}>
                We received a technical failure when <br />
                {props.isCreditCard ? 'charging your card.' : 'processing your Account Verification.'}
              </Box>
              <Box padding={2}>Please click the button below to retry.</Box>
            </Box>
          </DialogContent>
        }
      </>
    );
  };

  const NoDeposit = () => {
    return (
      <>
        {
          <DialogContent>
            <Box display="flex" justifyContent="center">
              <Box width="25%">
                <SuccessAnimation />
              </Box>
            </Box>
            {props.isCreditCard ? (
              <Box>
                <Box>
                  <Box fontSize={18} paddingTop={2}>
                    We have successfully charged your card.
                  </Box>
                  <Box paddingTop={2}>
                    Last four digits: {det.last4}
                    <br /> Expiry date: {det.expiry}
                    <br /> Name on card: {det.accountHolderName}
                  </Box>
                  <Box fontSize={18} paddingTop={2} paddingBottom={2}>
                    If these details are incorrect you <br />
                    can remove this card and try again.
                  </Box>
                  <SButton color="red" lineHeight="m" width="m" onClick={onRemoveAccount}>
                    Remove Card
                  </SButton>
                </Box>
                <Box fontSize={18} paddingTop={2}>
                  A pending charge should appear on your card statement
                  <br /> within a few minutes, but it can take longer
                  <br /> depending on your bank.
                </Box>
              </Box>
            ) : (
              <Box>
                <Box>
                  <Box fontSize={18} paddingTop={2}>
                    Payment to your nominated bank <br />
                    account was successful.
                  </Box>
                  <Box paddingTop={2}>
                    BSB: {bankDetails?.bankBSB} <br />
                    Account number: {bankDetails?.bankAccountNumber} <br />
                  </Box>
                  <Box fontSize={18} paddingTop={2} paddingBottom={2}>
                    If these details are incorrect you <br />
                    can remove this account and try again.
                  </Box>
                  <SButton color="red" lineHeight="m" width="m" onClick={onRemoveAccount}>
                    Remove Account
                  </SButton>
                </Box>
                <Box fontSize={18} paddingTop={2}>
                  Verification usually takes 30 seconds to process <br /> into your nominated bank account, but can{' '}
                  <br /> take longer in some circumstances.
                </Box>
              </Box>
            )}
          </DialogContent>
        }
      </>
    );
  };

  return (
    <>
      <SDialog open={true} classes={{paper: classes.dialog}}>
        <DialogTitle classes={{root: classes.dialogTitle}}>
          <Box
            display="flex"
            alignItems="center"
            fontSize="34px"
            justifyContent="center"
            style={{color: props.dialogStage === PennyVerificationDialogStages.NO_DEPOSIT ? '' : '#C55D44'}}
          >
            {props.dialogStage === PennyVerificationDialogStages.NO_DEPOSIT
              ? `${props.isCreditCard ? 'Charge' : 'Payment'} was successful`
              : `${props.isCreditCard ? 'Charge' : 'Payment'}  Failed`}
          </Box>
        </DialogTitle>
        {props.dialogStage === PennyVerificationDialogStages.FAILED && <SpendaFail />}
        {props.dialogStage === PennyVerificationDialogStages.RETRY_REQUIRED && <UserFail />}
        {props.dialogStage === PennyVerificationDialogStages.NO_DEPOSIT && <NoDeposit />}
        <DialogActions classes={{root: classes.dialogActions}}>
          {props.dialogStage === PennyVerificationDialogStages.NO_DEPOSIT && (
            <SButton color="blue" lineHeight="m" width="m" onClick={props.onClose}>
              Done
            </SButton>
          )}
          {props.dialogStage === PennyVerificationDialogStages.RETRY_REQUIRED && (
            <SButton color="yellow" label="white" lineHeight="m" width="m" onClick={onRetry}>
              Retry
            </SButton>
          )}
          {props.dialogStage === PennyVerificationDialogStages.FAILED && (
            <SButton color="red" lineHeight="m" width="m" onClick={onRemoveAccount}>
              Remove Account
            </SButton>
          )}
          <Box className="live-chat-section">
            <button onClick={onSupport} type="button" className="liveChatBtn" style={{flexShrink: 0}}>
              <>
                <img src={LiveChatIcon} className="mr-2 mt-1" />
                <div>Support</div>
              </>
            </button>
          </Box>
        </DialogActions>
      </SDialog>
      {isShowSupportDialog && (
        <SupportDialog bankAccountDetails={props.bankAccountDetails} onClose={props.onClose}></SupportDialog>
      )}
    </>
  );
};
