import React from 'react';
import {Typography} from 'spenda-ui-react';
import {AlertDialogSlideV2} from './AlertDialogSlideV2';

interface IQuoteServiceDialog {
  handleGoBack: () => void;
  handleCreate: () => void;
  isLoading?: boolean;
}

export const QuoteServiceDialog = (props: IQuoteServiceDialog) => {
  const {handleGoBack, handleCreate, isLoading} = props;

  return (
    <>
      <AlertDialogSlideV2
        title={'Creating a service job'}
        headingTextSize="h2"
        headingClassess="justify-center"
        dialogActionsAlignment="justify-between"
        dialogClassess="!min-w-[20%] !max-w-[498px] !w-full"
        dialogBodyClassess="flex justify-center items-center"
        data-autoid={"dlgCreateServiceJob"}
        actions={[
          {
            label: 'Go back',
            action: handleGoBack,
            color: 'primary',
            variant: 'outlined',
            disabled: isLoading,
          },
          {
            label: 'Create Service Job',
            action: handleCreate,
            color: 'primary',
            loading: isLoading,
            disabled: isLoading,
          },
        ]}
      >
        <Typography
          variant="small"
          className="flex w-full max-w-[294px] items-center justify-center py-10 text-center font-normal text-black-800"
        >
          Please ensure you have all necessary materials before creating a service job. If any materials or equipment
          are needed, please generate a sales order or purchase order beforehand.{' '}
        </Typography>
      </AlertDialogSlideV2>
    </>
  );
};
