import React from 'react';
import {
  PurchaseInvoiceRequestedList,
  PurchaseInvoiceViewTypes,
  TimelineCustomRange,
  SupplierViewSortingTypes,
} from '../../model/constants/Constants';

import UnTick from '../../assets/svg/accounts-payable/batchCircleCheckboxBlank.svg';
import Tick from '../../assets/svg/accounts-payable/batchCircleCheckboxSelected.svg';
import {makeStyles, MenuItem, Select, Box} from '@material-ui/core';
import {css} from 'glamor';
import {APCrossIcon} from '../../assets/svg/accounts-payable/APCrossIcon';
import {Typography} from 'spenda-ui-react';

interface IPurchaseInvoiceListFilterProps {
  isAllSuppliersSelected: boolean;
  onSelectAll: (isAllSuppliersSelected: boolean) => void;
  selectedInvoiceTotal: number;
  togglePurchaseInvoiceViewMode: (type: PurchaseInvoiceViewTypes) => void;
  selectedBucket?: PurchaseInvoiceRequestedList;
  purchaseInvoiceViewMode?: PurchaseInvoiceViewTypes;
  supplierViewMode?: PurchaseInvoiceViewTypes;
  toggleSupplierViewMode: (type: PurchaseInvoiceViewTypes) => void;
  timelineViewMode?: PurchaseInvoiceViewTypes;
  toggleTimelineViewMode: (type: PurchaseInvoiceViewTypes) => void;
  timeLineCustomRangeMode?: TimelineCustomRange;
  toggletimeLineCustomRangeMode: (type: TimelineCustomRange) => void;
  SupplierSortingViewMode?: SupplierViewSortingTypes;
  toggleSupplierViewSortingMode: (type: SupplierViewSortingTypes) => void;
  isSavingBatch?: boolean;
  setIsSavingBatch: React.Dispatch<React.SetStateAction<boolean>>;
  t2TV3?: boolean;
  showDeselectOption?: boolean;
  handleDeselectClick?: () => void;
}

const savingBatchBtn = css({
  color: '#0082BA',
  fontSize: '18px',
  fontWeight: 500,
  marginRight: '10px',
  width: '150px',
  textAlign: 'right',
});

const usePurchaseInvoiceListFilterStyles = makeStyles({
  batchSelect: {
    minWidth: '185px',
    height: '40px',
    border: '1px solid #333',
    borderRadius: '6px',
    marginLeft: '10px',
    '& .MuiSelect-select': {
      color: '#333333',
      fontSize: '14px',
      fontWeight: '600',
      fontFamily: 'poppins',
      lineHeight: '16px',
      padding: '0px 40px 0px 10px',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      height: '40px',
      '& .MuiRadio-root': {
        display: 'none',
      },
    },
    '& svg': {
      color: '#1c78ad',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: '0px!important',
    },
  },
  selected: {
    backgroundColor: '#1C78AD20 !important',
  },
  sortingBtn: {
    fontWeight: 600,
    '& span': {
      justifyContent: 'space-between',
    },
  },
  datePickerWrap: {
    padding: '0px 15px 0px 2px',
    '& .MuiInputAdornment-root': {
      display: 'none',
    },
  },
});

export const PurchaseInvoiceListFilter = (props: IPurchaseInvoiceListFilterProps) => {
  const {
    isAllSuppliersSelected,
    onSelectAll,
    purchaseInvoiceViewMode,
    togglePurchaseInvoiceViewMode,
    timeLineCustomRangeMode,
    toggletimeLineCustomRangeMode,
    SupplierSortingViewMode,
    toggleSupplierViewSortingMode,
    isSavingBatch,
    setIsSavingBatch,
    t2TV3,
    showDeselectOption,
    handleDeselectClick,
  } = props;

  const classes = usePurchaseInvoiceListFilterStyles();

  return (
    <div>
      <div
        className={`flex h-[49px] items-center justify-between border-[#D8D8D8] border-b-default pb-2.5 ${
          PurchaseInvoiceViewTypes.TimelineView === purchaseInvoiceViewMode && !t2TV3 ? '' : 'pl-[7px]'
        }`}
      >
        {purchaseInvoiceViewMode === PurchaseInvoiceViewTypes.TimelineView && t2TV3 && showDeselectOption && (
          <span
            className={`select-symbol cursor-pointer pt-1 font-poppins !text-base font-medium`}
            onClick={handleDeselectClick}
            data-autoid={`chkDeselect`}
          >
            <APCrossIcon />
            <Typography className="ml-2 cursor-pointer" variant="h3">
              {'Deselect'}
            </Typography>
          </span>
        )}
        {purchaseInvoiceViewMode !== PurchaseInvoiceViewTypes.TimelineView && !t2TV3 ? (
          <span
            className={`select-symbol !ml-0 items-center font-poppins !text-base font-normal`}
            onClick={() => {
              if (!isSavingBatch) {
                setIsSavingBatch(true);
                onSelectAll(isAllSuppliersSelected);
              }
            }}
            data-autoid={`chkToggler`}
          >
            <SelectToggler isSelected={isAllSuppliersSelected} dataAutoId="imgSelectAllToggler" />
            <span className="cursor-pointer">{'Select All'}</span>
          </span>
        ) : (
          <span />
        )}
        <Box className="flex items-center">
          <Box className={`${savingBatchBtn}`}>{isSavingBatch ? 'Saving...' : 'Saved'}</Box>
          {PurchaseInvoiceViewTypes.TimelineView === purchaseInvoiceViewMode && (
            <Select
              className={`${classes.batchSelect} border font-poppins text-black-800`}
              variant="outlined"
              value={timeLineCustomRangeMode}
              onChange={e => e.target.value && toggletimeLineCustomRangeMode(e.target.value as TimelineCustomRange)}
              MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
              }}
              inputProps={{
                name: 'selectTimelineRange',
              }}
              data-autoid="selectTimelineCustomRange"
            >
              <MenuItem
                classes={{selected: classes.selected}}
                key={TimelineCustomRange.SevenToTwentyEightDays}
                value={TimelineCustomRange.SevenToTwentyEightDays}
              >
                7, 14, 28 Days
              </MenuItem>
              <MenuItem
                classes={{selected: classes.selected}}
                key={TimelineCustomRange.ThirtyToNinetyDays}
                value={TimelineCustomRange.ThirtyToNinetyDays}
              >
                30, 60, 90 Days
              </MenuItem>
              <MenuItem
                classes={{selected: classes.selected}}
                key={TimelineCustomRange.TwoToSixMonths}
                value={TimelineCustomRange.TwoToSixMonths}
              >
                2, 4, 6 Months
              </MenuItem>
              <MenuItem
                classes={{selected: classes.selected}}
                key={TimelineCustomRange.ThreeToNineMonths}
                value={TimelineCustomRange.ThreeToNineMonths}
              >
                3, 6, 9 Months
              </MenuItem>
            </Select>
          )}
          {PurchaseInvoiceViewTypes.SupplierView === purchaseInvoiceViewMode && (
            <Select
              className={`${classes.batchSelect} border font-poppins text-black-800`}
              variant="outlined"
              value={SupplierSortingViewMode}
              onChange={e =>
                e.target.value && toggleSupplierViewSortingMode(e.target.value as SupplierViewSortingTypes)
              }
              MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
              }}
              inputProps={{
                name: 'selectBatchType',
              }}
              data-autoid="selectSupplierSorting"
            >
              <MenuItem
                key={SupplierViewSortingTypes.OverduePercentage}
                value={SupplierViewSortingTypes.OverduePercentage}
              >
                No of overdue invoices
              </MenuItem>
              <MenuItem key={SupplierViewSortingTypes.OverdueInvoice} value={SupplierViewSortingTypes.OverdueInvoice}>
                Oldest overdue invoices
              </MenuItem>
              <MenuItem key={SupplierViewSortingTypes.InvoiceTotal} value={SupplierViewSortingTypes.InvoiceTotal}>
                Total invoice amount
              </MenuItem>
              <MenuItem key={SupplierViewSortingTypes.SupplierName} value={SupplierViewSortingTypes.SupplierName}>
                Alphabetically
              </MenuItem>
            </Select>
          )}
          <Select
            className={`${classes.batchSelect} border font-poppins text-black-800`}
            variant="outlined"
            value={purchaseInvoiceViewMode}
            onChange={e => e.target.value && togglePurchaseInvoiceViewMode(e.target.value as PurchaseInvoiceViewTypes)}
            MenuProps={{
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
            }}
            inputProps={{
              name: 'selectBatchType',
            }}
            data-autoid="selectPurchaseView"
          >
            {(Object.keys(PurchaseInvoiceViewTypes) as Array<keyof typeof PurchaseInvoiceViewTypes>).map(
              (key, index) => {
                return (
                  <MenuItem classes={{selected: classes.selected}} key={index} value={PurchaseInvoiceViewTypes[key]}>
                    {PurchaseInvoiceViewTypes[key]}
                  </MenuItem>
                );
              },
            )}
          </Select>
        </Box>
      </div>
    </div>
  );
};

export const SelectToggler = (props: {isSelected: boolean; className?: string; dataAutoId?: string}) => {
  return (
    <img
      src={props.isSelected ? Tick : UnTick}
      className={`apSelect-symbol-icon ${props.className || ''}`}
      data-autoid={props.dataAutoId}
    />
  );
};
