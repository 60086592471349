import {useEffect} from 'react';
import useServiceJobAPI from '../services/useServiceJobAPI';
import {IServiceJob, ServiceJobStatus} from '../model/service-management/serviceJob';
import {useCalculatePagination} from './useCalculatePagination';
import {ISearchFilter} from '../model/search-filters/SearchFilter';
import {Toast} from '../utils/Toast';

export const useLoadUninvoicedJob = () => {
  const {getJobsList, isLoading} = useServiceJobAPI();

  const {
    setItems,
    items,
    setSearchFilter,
    setTotalCount,
    setMoreToGet,
    currentPage,
    totalCount,
    setCurrentPageFromFilter,
    searchFilter,
    handleRowsPerPageChange,
    handlePageChange,
  } = useCalculatePagination<IServiceJob, ISearchFilter>({
    initialFilterState: {
      StartRow: 1,
      MaxResults: 15,
      Statuses: [ServiceJobStatus.Completed],
      SortOrder: 'DESC',
      SortField: 'CompletedDate',
    },
  });

  const getJobs = async () => {
    const result = await getJobsList(searchFilter);
    if (result === undefined) return;
    let items = result.items || [];

    setItems(items);
    setTotalCount(result.totalRecordCount || 0);
    setMoreToGet(result.moreToGet || false);
    setCurrentPageFromFilter(searchFilter.StartRow, searchFilter.MaxResults);
  };

  useEffect(() => {
    try {
      getJobs();
    } catch (err) {
      Toast.error('Failed to load jobs to Invoice list');
    }
  }, [searchFilter]);

  return {
    isLoading,
    items,
    pageSize: searchFilter.MaxResults || 15,
    setSearchFilter,
    searchFilter,
    currentPage,
    totalCount,
    handleRowsPerPageChange,
    handlePageChange,
  };
};
