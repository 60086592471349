import React, {useEffect, useState, memo} from 'react';
import {useConversations} from '../../hooks/useConversations';
import {IConversationChatbox, IConvoInfo} from '../../model/conversation/Conversation';
import {DatTypes, StartConvoType} from '../../model/constants/Constants';
import {useConversationAPI} from '../../services/useConversationAPI';
import {ARChatIcon} from '../../assets/svg/ARChatIcon';
import {Button, IconButton} from 'spenda-ui-react';

interface IStartConvoProps extends IConversationChatbox {
  contextConvos: IConversationChatbox[] | undefined;
  startConvoType: StartConvoType;
  scope?: 'CR_CreateAndEdit';
}

const StartConvo = (props: IStartConvoProps) => {
  const {
    operationUniqueId,
    refNumber,
    guid,
    datTypeID,
    supplierID,
    customerID,
    conversationWith,
    convoType,
    contextConvos,
    startConvoType,
    transactionId,
    scope,
  } = props;

  const [convoData, setConvoData] = useState<IConvoInfo>();
  const {startConversation} = useConversations();

  useEffect(() => {
    setConvoData({
      conversationId: props.conversationId,
      unreadMessages: props.unreadMessages,
      messageCount: props.messageCount,
    });
  }, [props.conversationId, props.unreadMessages, props.messageCount]);

  useEffect(() => {
    const convo = contextConvos?.find((convo: IConversationChatbox) => convo.operationUniqueId === operationUniqueId);
    if (convo) {
      setConvoData({
        conversationId: convo.conversationId,
        unreadMessages: convo.unreadMessages,
        messageCount: convo.messageCount,
      });
    }
  }, [contextConvos]);

  const handleStartConvoClick = () => {
    startConversation({
      operationUniqueId: operationUniqueId,
      conversationWith: conversationWith || '',
      refNumber: refNumber,
      guid: guid,
      datTypeID: datTypeID,
      supplierID: supplierID || null,
      customerID: customerID || null,
      conversationId: convoData?.conversationId || undefined,
      messageCount: convoData?.messageCount || 0,
      unreadMessages: convoData?.unreadMessages || 0,
      transactionId: transactionId,
      convoType: convoType,
    });
  };

  return (
    <>
      {startConvoType === StartConvoType.ICON && (
        <StartConvoIcon
          operationUniqueId={operationUniqueId}
          unreadMessages={convoData?.unreadMessages}
          conversationId={convoData?.conversationId}
          messageCount={convoData?.messageCount}
          handleStartConvoClick={handleStartConvoClick}
          scope={scope}
        />
      )}
      {startConvoType === StartConvoType.BUTTON && guid && datTypeID && (
        <StartConvoBtn
          operationUniqueId={operationUniqueId}
          conversationId={convoData?.conversationId}
          handleStartConvoClick={handleStartConvoClick}
          setConvoData={setConvoData}
          txGuid={guid}
          txDatType={datTypeID}
        />
      )}
    </>
  );
};

export default memo(StartConvo);

interface StartConvoIconProps extends IConvoInfo {
  operationUniqueId: string;
  handleStartConvoClick: () => void;
  scope?: 'CR_CreateAndEdit';
}
const StartConvoIcon = (props: StartConvoIconProps) => {
  const {operationUniqueId, conversationId, unreadMessages, handleStartConvoClick, scope} = props;
  return (
    <>
      <div className="cursor-pointer" data-autoid={`btnChatIcon-${operationUniqueId}`} onClick={handleStartConvoClick}>
        {unreadMessages && conversationId ? (
          <>
            {scope === 'CR_CreateAndEdit' ? (
              <div className="relative flex h-10 w-10 cursor-pointer items-center justify-center rounded-[6px] border border-spenda-sBlue focus:outline-none">
                <ARChatIcon className=" cursor-pointer" width="22px" height="auto" />
                <div
                  data-autoid="customerChat-badge"
                  className={`absolute -right-2.5 -top-2.5 h-6 min-w-[24px] rounded-full bg-[#C55D44] p-1 text-center font-poppins text-xs text-white`}
                >
                  {unreadMessages}
                </div>
              </div>
            ) : (
              <div className="relative flex cursor-pointer items-center justify-center focus:outline-none">
                <ARChatIcon className="cursor-pointer" width="18px" height="15" />
                <div
                  data-autoid="customerChat-badge"
                  className={`absolute -right-2.5 -top-2.5 h-[16px] min-w-[16px] rounded-full bg-[#C55D44] p-1 text-center font-poppins text-[10px] leading-[1] text-white`}
                >
                  {unreadMessages}
                </div>
              </div>
            )}
          </>
        ) : scope === 'CR_CreateAndEdit' ? (
          <IconButton variant="outlined" data-autoid="btnChatIcon">
            <ARChatIcon
              color={conversationId ? '' : '#33333375'}
              className=" cursor-pointer"
              width="22px"
              height="auto"
            />
          </IconButton>
        ) : (
          <ARChatIcon color={conversationId ? '' : '#33333375'} className=" cursor-pointer" width="18px" height="15" />
        )}
      </div>
    </>
  );
};

interface StartConvoBtnProps {
  operationUniqueId: string;
  conversationId: number | undefined;
  handleStartConvoClick: () => void;
  setConvoData: React.Dispatch<React.SetStateAction<IConvoInfo | undefined>>;
  txGuid: string;
  txDatType: DatTypes;
}
const StartConvoBtn = (props: StartConvoBtnProps) => {
  const {operationUniqueId, conversationId, handleStartConvoClick, setConvoData, txDatType, txGuid} = props;

  const {getMessagesDataByGuidIds, isLoading} = useConversationAPI();

  useEffect(() => {
    getCurrentOpenConvoData(txGuid, txDatType);
  }, [txGuid, txDatType]);

  const getCurrentOpenConvoData = async (txGuid: string, txDatType: DatTypes) => {
    try {
      const convoData = await getMessagesDataByGuidIds([txGuid], txDatType);
      if (convoData?.documentMessageSummaryList?.length && convoData.documentMessageSummaryList[0].messageSummary) {
        setConvoData({
          conversationId: convoData.documentMessageSummaryList[0].messageSummary.conversationID,
          messageCount: convoData.documentMessageSummaryList[0].messageSummary.messageCount,
          unreadMessages: convoData.documentMessageSummaryList[0].messageSummary.unreadMessages,
        });
      } else {
        setConvoData(undefined);
      }
    } catch {}
  };

  return (
    <>
      {
        <Button
          data-autoid={`btnStartOrOpenConversation-${operationUniqueId}`}
          className="bg-white"
          onClick={handleStartConvoClick}
          variant="outlined"
          color="primary"
          loading={isLoading}
          disabled={isLoading}
        >
          {conversationId ? 'Open Conversation' : 'Start Conversation'}
        </Button>
      }
    </>
  );
};
