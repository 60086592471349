import React, {useState, FunctionComponent, ChangeEvent, useEffect} from 'react';
import {useParams, useHistory} from 'react-router-dom';
import {usePopupState, bindTrigger, bindMenu} from 'material-ui-popup-state/hooks';
import {Box, makeStyles} from '@material-ui/core';
import {FormikProps} from 'formik';
import {css} from 'glamor';
import {uniqBy} from 'lodash';
import {Input} from 'spenda-ui-react';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';

import {AUTH_SELLING_SALES_ORDER_PACK} from '../../routes/SalesOrderRoutes';
import {CONSIGNMENT_NOTE_STATUS} from '../../model/constants/Constants';
import {IPickSlip} from '../../model/sales-order/PickSlip';
import {IPackageType} from '../../model/sales-order/PackageType';
import {
  IConsignmentNote,
  IConsignmentNotePackage,
  IConsignmentNotePackageLine,
} from '../../model/sales-order/ConsignmentNote';
import {STextField} from '../inputs/STextField';
import {CustomStepper} from '../Stepper/Stepper';
import {STableCustom} from '../tables/STableCustom';
import {AddPackageDialog} from '../dialog/AddPackageDialog';
import {FilterMenu, FilterMenuItem} from '../menu/FilterMenu';
import {SalesOrderBottomBarPackaging} from './SalesOrderBottomBar';
import {useConsignmentNoteAPI} from '../../services/useConsignmentNoteAPI';
import {usePackageTypeAPI} from '../../services/usePackageTypeAPI';
import PriceCalculator from '../../utils/priceCalculator';

import {EmptyBox} from '../../assets/svg/EmptyBox';
import packIconWhite from '../../assets/svg/pack-white.svg';
import selectedIconBlue from '../../assets/svg/selected-box-blue.svg';
import packIconBlack from '../../assets/svg/pack-black.svg';
import selectedIconBlack from '../../assets/svg/open-box-black.svg';
import IconKeyboardArrowDownRounded from '../../assets/svg/IconKeyboardArrowDownRounded';
import {SButton} from '../buttons/Button';

const useOrderDetailsStyles = makeStyles(() => ({
  packingTable: {
    margin: '10px 0 0 0',
    ' & .MuiTableContainer-root': {
      maxHeight: '40vh',
      overflowY: 'auto',
    },
  },
  packIcon: {
    margin: '0px 10px 3px 0px',
  },
}));

export const SalesOrderPackItems: FunctionComponent<
  FormikProps<IPickSlip> & {
    setReadyToshipDisable: (x: boolean) => void;
    conNote: IConsignmentNote;
    getConsignmentNote: () => void;
  }
> = props => {
  const classes = useOrderDetailsStyles();
  const {pickSlipID, consignmentNoteID} = useParams<{
    pickSlipID?: string | undefined;
    consignmentNoteID?: string | undefined;
  }>();
  const history = useHistory();

  const {values, conNote, setFieldValue, getConsignmentNote, setReadyToshipDisable} = props;

  const [editPackageTypeID, setEditPackageTypeID] = useState<string | null>();
  const [packageTypeSelected, setPackageTypeSelected] = useState<IPackageType>();
  const [packageTypeList, setPackageTypeList] = useState<IPackageType[]>([]);
  const [addPackageDialog, setAddPackageDialog] = useState<boolean>(false);

  const [consignmentNotePackages, setConsignmentNotePackages] = useState<IConsignmentNotePackage[]>([]);
  const [packageSelected, setPackageSelected] = useState<IConsignmentNotePackage>();
  const [lineItemSelected, setLineItemSelected] = useState<any>([]);

  const [quantityPacked, setQuantityPacked] = useState<Map<any, any>>(new Map());
  const [searchText, setSearchText] = useState<string>('');

  const packageTypePopupState = usePopupState({variant: 'popover', popupId: 'packageTypePopupState'});

  const {createConsignmentNote, updateConsignmentNote, isLoading: consignmentLoader} = useConsignmentNoteAPI();
  const {listPackageType} = usePackageTypeAPI();

  useEffect(() => {
    getPackageTypes();
  }, []);

  useEffect(() => {
    setConNotePackages();
  }, [conNote]);

  const getPackageTypes = async () => {
    const packageTypes = await listPackageType();
    setPackageTypeList(packageTypes);
    if (!packageTypeSelected) {
      setPackageTypeSelected(packageTypes?.[0]);
    }
  };

  const setConNotePackages = async () => {
    if (conNote) {
      const boxMap = new Map();
      setConsignmentNotePackages(conNote.Packages);
      setPackageSelected(conNote.Packages?.[0]);
      conNote.Packages.map((box: IConsignmentNotePackage) => {
        return box.Lines.map(item => {
          const newItem = item.PickSlipLineID;
          const oldQuant = boxMap.get(`${newItem}`) ?? 0;
          boxMap.set(`${newItem}`, PriceCalculator.roundNumber(oldQuant + item.QuantityPacked));
        });
      });
      setQuantityPacked(boxMap);
    }
  };

  useEffect(() => {
    if (
      values.Lines.filter(
        (a: any) =>
          (a?.ShortDescription?.toLocaleLowerCase().includes(searchText) ||
            a?.Code?.toString()?.includes(searchText)) &&
          a.QuantityPicked - (quantityPacked.get(`${a.PickSlipLineID}`) || 0),
      ).length ||
      consignmentNotePackages?.some((_pack: any) => !_pack?.Lines?.length)
    ) {
      setReadyToshipDisable(true);
    } else {
      setReadyToshipDisable(false);
    }
  }, [
    values.Lines.filter(
      (a: any) =>
        (a?.ShortDescription?.toLocaleLowerCase().includes(searchText) || a?.Code?.toString()?.includes(searchText)) &&
        a.QuantityPicked - (quantityPacked.get(`${a.PickSlipLineID}`) || 0),
    ).length,
    quantityPacked,
  ]);

  const checkReadyToShip = (packages: IConsignmentNotePackage[]) => {
    if (
      values.Lines.filter(
        (a: any) =>
          (a?.ShortDescription?.toLocaleLowerCase().includes(searchText) ||
            a?.Code?.toString()?.includes(searchText)) &&
          a.QuantityPicked - (quantityPacked.get(`${a.PickSlipLineID}`) || 0),
      ).length ||
      packages?.some((_pack: any) => !_pack?.Lines?.length)
    ) {
      setReadyToshipDisable(true);
    } else {
      setReadyToshipDisable(false);
    }
  };

  const handleQuantityPackedChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, PickSlipLineID: any) => {
    if (Array.isArray(values.Lines)) {
      let packQty = e.target.value;
      const index = values.Lines.findIndex(li => li.PickSlipLineID === PickSlipLineID);
      const lines = [...values.Lines];
      lines[index].QuantityPacked = parseFloat(packQty);
      const _index = lineItemSelected.findIndex((li: any) => li.PickSlipLineID === PickSlipLineID);
      if (_index < 0) {
        setLineItemSelected([...lineItemSelected, lines[index]]);
      }
      setFieldValue('Lines', lines);
    }
  };

  const addPackage = async () => {
    if (!consignmentNoteID) {
      const conNoteRes = await createConsignmentNote({
        IsActive: true,
        CustomerID: values.CustomerID,
        WarehouseID: values.WarehouseID,
        Status: CONSIGNMENT_NOTE_STATUS.OPEN,
        Packages: [
          ...consignmentNotePackages,
          {
            IsActive: true,
            PackageTypeID: packageTypeSelected?.ID,
            Lines: lineItemSelected.map((inventory: IConsignmentNotePackageLine) => ({
              IsActive: true,
              PickSlipLineID: inventory.PickSlipLineID,
              QuantityPacked: inventory.QuantityPacked,
            })),
          },
        ],
      });
      if (conNoteRes.IsSuccess && pickSlipID) {
        history.replace({
          pathname: AUTH_SELLING_SALES_ORDER_PACK.replace(/:pickSlipID/g, pickSlipID).replace(
            /:consignmentNoteID\?/g,
            conNoteRes.Value.ID,
          ),
        });
      }
    } else {
      await updateConsignmentNote({
        ID: consignmentNoteID,
        IsActive: true,
        Status: CONSIGNMENT_NOTE_STATUS.OPEN,
        Packages: [
          ...consignmentNotePackages,
          {
            IsActive: true,
            PackageTypeID: packageTypeSelected?.ID,
            Lines: lineItemSelected.map((inventory: IConsignmentNotePackageLine) => ({
              IsActive: true,
              PickSlipLineID: inventory.PickSlipLineID,
              QuantityPacked: inventory.QuantityPacked,
            })),
          },
        ],
      });
    }
    getConsignmentNote();
    setFieldValue(
      'Lines',
      values.Lines.map(line => ({...line, QuantityPacked: 0})),
    );
    setLineItemSelected([]);
  };

  const handlePack = async () => {
    const packageLineItems = lineItemSelected?.map((inventory: IConsignmentNotePackageLine) => ({
      IsActive: true,
      PickSlipLineID: inventory.PickSlipLineID,
      QuantityPacked: inventory.QuantityPacked,
    }));
    const packageIndex = consignmentNotePackages.findIndex(_package => _package.ID === packageSelected?.ID);
    const packages = [...consignmentNotePackages];
    if (packageIndex > -1) {
      packageLineItems.forEach((line: IConsignmentNotePackageLine) => {
        const lineIndex = packages[packageIndex].Lines.findIndex(
          (_line: IConsignmentNotePackageLine) => _line.PickSlipLineID === line.PickSlipLineID,
        );
        if (lineIndex > -1) {
          packages[packageIndex].Lines[lineIndex].QuantityPacked =
            (packages[packageIndex].Lines[lineIndex].QuantityPacked || 0) + (line.QuantityPacked || 0);
        } else {
          packages[packageIndex].Lines.push(line);
        }
      });
    }
    await updateConsignmentNote({
      ID: consignmentNoteID,
      IsActive: true,
      Status: CONSIGNMENT_NOTE_STATUS.OPEN,
      Packages: packages,
    });
    getConsignmentNote();
    setFieldValue(
      'Lines',
      values.Lines.map(line => ({...line, QuantityPacked: 0})),
    );
    setLineItemSelected([]);
  };

  const handleAddNewPackageDialog = (data?: IPackageType) => {
    setEditPackageTypeID(data?.ID || null);
    setAddPackageDialog(true);
  };

  const handleAddNewPackageDialogClose = (newPack: any) => {
    if (newPack) {
      setPackageTypeSelected(newPack);
    }
    setAddPackageDialog(false);
    getPackageTypes();
    packageTypePopupState.close();
  };

  const handleSearchText = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value.toLocaleLowerCase());
  };

  const handleSelectAll = () => {
    if (Array.isArray(values.Lines)) {
      const inventory = values.Lines.filter(
        (a: any) =>
          (a?.ShortDescription?.toLocaleLowerCase().includes(searchText) ||
            a?.InventoryID?.toString()?.includes(searchText)) &&
          (a.QuantityPicked || a.QuantityRequired) - (quantityPacked.get(`${a.PickSlipLineID}`) || 0),
      );
      if (
        inventory?.length &&
        inventory?.every(v => lineItemSelected?.some((_i: any) => _i.PickSlipLineID === v?.PickSlipLineID))
      ) {
        setLineItemSelected([]);
      } else {
        setLineItemSelected(uniqBy([...lineItemSelected, ...inventory], _i => _i.PickSlipLineID));
      }
    }
  };

  const onEnterPress = (ev: React.KeyboardEvent, field: string, rowData: any) => {
    if (ev.key === 'Enter') {
      ev.preventDefault();
      // Do code here
      const {Lines} = values;
      const nextIndex = Lines.findIndex((item: any) => item.PickSlipLineID === rowData.PickSlipLineID) + 1;

      if (nextIndex >= Lines.length) {
        return;
      }

      const nextItem = Lines[nextIndex];
      setTimeout(() => {
        const ele = document.getElementById(`${field}-${nextItem.PickSlipLineID}`);
        if (ele) {
          ele.focus();
        }
      }, 0);
    }
  };

  const getActiveStep = () => {
    if (conNote?.Status === CONSIGNMENT_NOTE_STATUS.OPEN && consignmentNotePackages.length) {
      return 4;
    } else if (conNote?.Status === CONSIGNMENT_NOTE_STATUS.OPEN && !consignmentNotePackages.length) {
      return 3;
    } else {
      return 5;
    }
  };

  return (
    <>
      <AddPackageDialog
        onClose={(newPack: any) => handleAddNewPackageDialogClose(newPack)}
        open={addPackageDialog}
        packageTypeID={editPackageTypeID}
      />
      <Box height="100%" width="100%" display="flex" flexDirection="column">
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="flex-start" className="pt-3">
          <span className="text-xl font-medium text-spenda-headingtext">
            Pack Items
            <span color="#999999" className="mb-5 mt-4 text-xl text-spenda-labeltext">
              {values.RefNumber ? ` (${values.RefNumber})` : ''}
            </span>
            {consignmentNotePackages?.some((_pack: any) => !_pack?.Lines?.length) ? (
              <span className="ml-2 text-lg">
                <WarningRoundedIcon className="text-spenda-warning" /> One or more Packages are empty
              </span>
            ) : null}
          </span>
          <Box>
            <CustomStepper
              steps={[
                'Ready To Pick',
                'In Picking',
                'Picked',
                'Ready to Pack',
                'In Packing',
                'Packed',
                'Awaiting Collection',
                'Completed',
              ]}
              activeStep={getActiveStep()}
            />
          </Box>
        </Box>
        <Box
          marginTop="11px"
          marginBottom="11px"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="flex-end"
          width="calc(100% - 150px)"
        >
          <Box className={`flex w-full max-w-[500px] items-end`}>
            <span className="pr-5 pt-1 text-spenda-primarytext">Search: </span>
            <Input
              id="search"
              name="search"
              variant="static"
              placeholder="Search Items, code"
              className="!pb-0"
              onChange={handleSearchText}
              disabled={
                !Boolean(
                  values?.Lines?.filter((a: any) => a.QuantityPicked - (quantityPacked.get(`${a.ID}`) || 0))?.length,
                )
              }
            />
          </Box>
          <Box>
            <span className={`mr-3 text-lg font-medium text-spenda-primarytext`}>Select Package Type:</span>
            <SButton
              margin="0px 4px"
              color="white"
              border="1px solid #F1F1F1"
              textColor="greyTint"
              boxShadow="none"
              lineHeight="m"
              type="button"
              width="195px"
              className={`!justify-between text-lg`}
              data-autoid="btnPackageType"
              {...bindTrigger(packageTypePopupState)}
            >
              {packageTypeSelected?.Name || ''} <IconKeyboardArrowDownRounded />
            </SButton>
            <FilterMenu {...bindMenu(packageTypePopupState)} data-autoid="mnuPackageType">
              {packageTypeList.map((a: IPackageType) => (
                <FilterMenuItem
                  key={a.ID}
                  onClick={() => {
                    setPackageTypeSelected(a);
                    packageTypePopupState.close();
                  }}
                  className="!block w-[188px]"
                  data-autoid={`btnPackageType${a.ID}`}
                >
                  {a?.Name || ''}
                  <EditOutlinedIcon
                    fontSize="small"
                    width="10px"
                    height="10px"
                    className="float-right text-primary"
                    data-autoid={`btnEditPackageType${a.ID}`}
                    onClick={() => {
                      handleAddNewPackageDialog(a);
                    }}
                  />
                </FilterMenuItem>
              ))}
              <FilterMenuItem
                onClick={() => {
                  handleAddNewPackageDialog();
                }}
                data-autoid="btnAddNewPackage"
              >
                <div className="w-full">
                  <span
                    className={`block h-6 w-full rounded border border-primary bg-primary/10 px-2 text-center text-xs font-medium leading-6 text-primary`}
                  >
                    Add New Package
                  </span>
                </div>
              </FilterMenuItem>
            </FilterMenu>
            <SButton
              margin="0px 4px"
              color="blueShade"
              textColor="white"
              boxShadow="none"
              lineHeight="m"
              type="button"
              width="m"
              fontSize="m"
              disabled={
                consignmentLoader ||
                !Boolean(lineItemSelected.length) ||
                lineItemSelected.some((a: any) => (a.QuantityPacked ? Number(a.QuantityPacked) <= 0 : true))
              }
              onClick={addPackage}
              data-autoid="btnPack"
            >
              <img
                src={
                  !Boolean(lineItemSelected.length) ||
                  lineItemSelected.some((a: any) => (a.QuantityPacked ? Number(a.QuantityPacked) <= 0 : true))
                    ? packIconBlack
                    : packIconWhite
                }
                className={`${classes.packIcon}`}
              />
              Pack
            </SButton>
            <SButton
              margin="0px 4px"
              color="transparent"
              border="1px solid #1C78AD"
              textColor="blueShade"
              lineHeight="m"
              type="button"
              width="m"
              fontSize="m"
              disabled={
                consignmentLoader ||
                !Boolean(consignmentNotePackages.length) ||
                !Boolean(lineItemSelected.length) ||
                lineItemSelected.some((a: any) => (a.QuantityPacked ? Number(a.QuantityPacked) <= 0 : true))
              }
              onClick={handlePack}
              data-autoid="btnInExistingBox"
            >
              <img
                src={
                  !Boolean(lineItemSelected.length) ||
                  lineItemSelected.some((a: any) => (a.QuantityPacked ? Number(a.QuantityPacked) <= 0 : true))
                    ? selectedIconBlack
                    : selectedIconBlue
                }
                className={`${classes.packIcon}`}
              />
              In Existing Box
            </SButton>
          </Box>
        </Box>
        <Box flex={1}>
          {!values.Lines.filter(
            (a: any) =>
              (a?.ShortDescription?.toLocaleLowerCase().includes(searchText) ||
                a?.InventoryID?.toString()?.includes(searchText)) &&
              (a.QuantityPicked || a.QuantityRequired) - (quantityPacked.get(`${a.PickSlipLineID}`) || 0),
          ).length ? (
            <Box flex={1} className="-mt-10 flex flex-col" alignItems="center">
              <EmptyBox />
              <p className="-mt-6 text-center text-lg font-medium text-spenda-primarytext">No More Items to allocate</p>
            </Box>
          ) : (
            <Box className={`${classes.packingTable}`}>
              <STableCustom
                columns={[
                  {
                    title: 'Code',
                    field: 'Code',
                    render: (rowData: any) => {
                      return !rowData.ShortDescription ? (
                        <span className="text-base text-spenda-labeltext">Code</span>
                      ) : (
                        <span className="text-base text-spenda-primarytext">{rowData.InventoryID}</span>
                      );
                    },
                  },
                  {
                    title: 'Items',
                    field: 'ShortDescription',
                    render: (rowData: any) => (
                      <>
                        <b>{rowData.Code}</b> {rowData.ShortDescription}
                      </>
                    ),
                  },
                  {
                    title: 'Dimensions & Weight',
                    field: 'DimensionAndWeight',
                    align: 'right',
                    render: (rowData: any) => (
                      <span className="text-base text-spenda-primarytext">
                        {`${PriceCalculator.roundNumber(rowData.Depth_m)} x ${PriceCalculator.roundNumber(
                          rowData.Depth_m,
                        )} x ${PriceCalculator.roundNumber(rowData.Depth_m)}`}{' '}
                        m | {PriceCalculator.roundNumber(rowData.Weight_kg)}kg
                      </span>
                    ),
                  },
                  {
                    title: 'Order Qty',
                    field: 'QuantityRequired',
                    align: 'right',
                    render: (rowData: any) => rowData.QuantityRequired,
                  },
                  {
                    title: 'Pick Qty',
                    field: 'QuantityPicked',
                    align: 'right',
                    render: (rowData: any) => rowData.QuantityPicked,
                  },
                  {
                    title: 'Remaining Qty',
                    field: 'Remaining',
                    align: 'right',
                    render: (rowData: any) => {
                      return PriceCalculator.roundNumber(
                        rowData.QuantityPicked -
                          (rowData.QuantityPacked || 0) -
                          (quantityPacked.get(`${rowData.PickSlipLineID}`) || 0),
                      );
                    },
                  },
                  {
                    title: 'Pack Qty',
                    field: 'PackQty',
                    align: 'right' as 'right',
                    render: (rowData: any) => {
                      return (
                        <STextField
                          {...css({maxWidth: '100px'})}
                          id={`QuantityPacked-${rowData.PickSlipLineID}`}
                          type="number"
                          placeholder="0"
                          InputProps={{
                            inputProps: {
                              step: 1,
                              min: 0,
                              max: rowData.QuantityPicked - (quantityPacked.get(`${rowData.PickSlipLineID}`) || 0),
                              'data-autoid': `txtQuantityPacked${rowData.PickSlipLineID}`,
                            },
                          }}
                          size="small"
                          value={rowData?.QuantityPacked}
                          onKeyDown={ev => onEnterPress(ev, 'QuantityPacked', rowData)}
                          onChange={e => {
                            if (
                              e.target.value.length === 0 ||
                              (Number(e.target.value) <=
                                PriceCalculator.roundNumber(
                                  rowData.QuantityPicked - (quantityPacked.get(`${rowData.PickSlipLineID}`) || 0),
                                ) &&
                                Number(e.target.value) >= 0)
                            ) {
                              handleQuantityPackedChange(e, rowData?.PickSlipLineID);
                            }
                          }}
                        />
                      );
                    },
                  },
                ]}
                data={
                  (Array.isArray(values.Lines) &&
                    values.Lines.filter(
                      (a: any) =>
                        (a?.ShortDescription?.toLocaleLowerCase().includes(searchText) ||
                          a?.InventoryID?.toString()?.includes(searchText)) &&
                        (a.QuantityPicked || a.QuantityRequired) - (quantityPacked.get(`${a.PickSlipLineID}`) || 0),
                    )) ||
                  []
                }
                selection={true}
                selected={lineItemSelected}
                setSelected={setLineItemSelected}
                handleSelectAll={handleSelectAll}
                emptyDataMessage={'No Order Items'}
              />
            </Box>
          )}
        </Box>
        {consignmentNoteID ? (
          <SalesOrderBottomBarPackaging
            {...props}
            conNoteStatus={conNote.Status}
            consignmentNoteID={consignmentNoteID}
            consignmentNotePackages={consignmentNotePackages}
            packageSelected={packageSelected}
            setPackageSelected={setPackageSelected}
            refetchConsignmentNote={getConsignmentNote}
            checkReadyToShip={checkReadyToShip}
          />
        ) : null}
      </Box>
    </>
  );
};
