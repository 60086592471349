import React from 'react';

export const IconArrowDown = (props: {width?: string; height?: string; className?: string; name?: string}) => {
  return (
    <svg
      width={props.width || '23'}
      height={props.height || '23'}
      className={props.className}
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{props.name}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9462 8.32449C17.7662 8.14306 17.5213 8.04102 17.2657 8.04102C17.0102 8.04102 16.7653 8.14306 16.5853 8.32449L12.1962 12.7137C12.0162 12.8951 11.7713 12.9971 11.5157 12.9971C11.2602 12.9971 11.0153 12.8951 10.8353 12.7137L6.44615 8.32449C6.26621 8.14306 6.02126 8.04102 5.76573 8.04102C5.51021 8.04102 5.26526 8.14306 5.08532 8.32449C4.71375 8.69827 4.71375 9.30195 5.08532 9.67574L9.48407 14.0745C10.6065 15.1956 12.4249 15.1956 13.5474 14.0745L17.9462 9.67574C18.3177 9.30195 18.3177 8.69827 17.9462 8.32449Z"
        fill="#444444"
      />
    </svg>
  );
};
