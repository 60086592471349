import useHttp from '../hooks/useHttp';
import {
  AccountType,
  AccountUsage,
  PaymentMethodTypes,
  PaymentProviderName,
  PaymentServiceType,
  TradingTermName,
  VerificationStatus,
} from '../model/constants/Constants';
import {IDbiReply, IGetAirPlusDBIConfigResponse} from '../model/payment/AirPlusDBI';
import {IPaymentMethodTypes} from '../model/payment/PaymentMethod';
import {IMerchantProcessingFee} from '../model/payment/PaymentT';
import {Toast} from '../utils/Toast';

export interface errorDetails {
  code: string;
  message: string;
}

export interface bankDetails {
  bankBSB: string;
  bankAccountNumber: string;
  bankAccountName: string;
  bankName?: string | null;
}

export interface cardDetails {
  friendlyName: string;
  expiry: string;
  last4: string;
  accountHolderName: string;
}
export interface virtualCardDetails {
  friendlyName: string;
  expiry: string;
  last4: string;
  accountHolderName: string;
}

export interface updateAccountRequest {
  description: string;
  bankBSB: string;
  bankAccountNumber: string;
}

interface VerificationCode {
  verificationCode: string;
}

export interface INewAccountResponse {
  accountGUID: string;
  invitationURL: string;
  expiresAt: string;
  invitationRefNumber: string;
  error: errorDetails;
}

export interface IPayLaterDetails {
  tradingTermName?: TradingTermName;
  tradingTermDays?: number;
  fundingRate: number;
  creditAvailable: string | number;
  defaultRepaymentMethodDescription: string;
  defaultRepaymentMethodType: PaymentMethodTypes;
}
export interface IMarketplaceFacilityDeatails {
  availableBalance: number;
  creditLimit: number;
  facilityStatus: string;
  facilityTradingTermDays: number;
  facilityTradingTermID: number;
  facilityTradingTermName: TradingTermName;
  marketplaceName: string;
  paymentAccountGUID: string;
  facilityID: number;
  repaymentAccountConfigIssues: string;
  repaymentLast4: number;
  repaymentServiceType: PaymentServiceType;
}

export interface IAccountResponse {
  accountGUID: string;
  accountType: AccountType;
  bankDetails: bankDetails;
  cardDetails: cardDetails;
  virtualCard?: virtualCardDetails;
  error: errorDetails;
  friendlyName?: string | undefined;
  isInvigoDefault: boolean;
  paymentMethod?: string | undefined;
  paymentService: string;
  retriesLeft: number;
  usage: AccountUsage;
  verificationStatus: VerificationStatus;
  isPayerFeeApplicable?: boolean;
  payLaterDetails?: IPayLaterDetails;
  marketplaceFacility?: IMarketplaceFacilityDeatails;
  isAttentionRequired?: boolean;
  attentionRequiredReason?: string;
  paymentAmount?: number;
  buyerTotalAmount?: number;
  fees?: [];
}

export interface IAccountResponseWithFee {
  accountGUID: string;
  paymentService: string;
  friendlyName: string;
  isDefault: boolean;
  isInvigoDefault: boolean;
  usage: string;
  verificationStatus: string;
  retriesLeft: number;
  serviceType: string;
  accountType: string;
  paymentMethod: string;
  isPayerFeeApplicable: boolean;
  isAuthorisationRequired: boolean;
  isAttentionRequired: boolean;
  attentionRequiredReason?: string;
  isSalesOrderReservationPermitted: boolean;
  bankDetails?: {
    bankBSB: string;
    bankAccountNumber: string;
    bankAccountName: string;
    bankName?: string;
  };
  cardDetails?: {
    expiry: string;
    last4: string;
    accountHolderName: string;
    bankIdentificationNumber?: string;
  };
  payLaterDetails: any;
  isoMerchantDetails: any;
  marketplaceFacility: any;
  marketplaceMerchant: any;
  marketplaceProvider: any;
  virtualCard?: {
    last4: string;
  };
  error: any;
  paymentAmount?: number;
  buyerTotalAmount?: number;
  fees?: IAccountFee[];
}

export interface IAccountFee {
  buyerFeeAmount: number;
  buyerFeeDescription?: string;
  feeName: any;
  merchantFeeAmount: number;
  merchantFeeDescription?: string;
  paymentServiceName: string;
  transactionFeeStructureID: number;
}

export interface INewCreditCardAccountRequest {
  friendlyName?: string;
  cardholderName?: string;
  cardType?: string;
  cardNumber?: string;
  expiry?: string;
  ccv?: string;
  merchantGlobalID?: string;
}
export interface INewAirPlusAccountRequest {
  MemberNumber: string;
  FriendlyName: string;
}
export interface IAirPlusAccountResponse {
  accountGUID: string;
}

export interface UpdateAccountRequest {
  friendlyName?: string;
  isInvigoDefault: boolean;
}

export interface INewCreditCardAccountResponse {
  accountGUID: string;
  error: {
    code: string;
    message: string;
  };
}

export interface IAddBankAccountDetailsRequest {
  businessName: string;
  friendlyName?: string;
  email: string;
  BSBNumber: string;
  AccountNumber: string;
  TsAndCsZeptoDirectDebitRequestAndServiceAgreementAgreedTo: boolean;
  TsAndCsZeptoDirectDebitRequestURLAgreedTo: string;
  TsAndCsZeptoDirectDebitRequestServiceAgreementURLAgreedTo: string;
}

// let retryCalled = true;

const useSpendaPaymentServicesAPI = () => {
  const {POST, GET, DELETE, isLoading} = useHttp();

  const addBankAccount = (accountUsage: string, paymentProviderName: string): Promise<INewAccountResponse> => {
    return POST(`PaymentServices/BankTransfer/Account/${accountUsage}/${paymentProviderName}`, false, true, true).then(
      (data: INewAccountResponse) => data,
    );
  };

  const addBankAccountDetails = (
    payload: IAddBankAccountDetailsRequest,
    accountUsage: string,
    paymentProviderName: string,
  ): Promise<INewAccountResponse> => {
    return POST(
      `PaymentServices/BankTransfer/Account/TrustedAgreement/${accountUsage}/${paymentProviderName}`,
      payload,
    ).then((data: INewAccountResponse) => data);
  };

  const getAccount = (paymentProviderName: string, accountGUID: string): Promise<IAccountResponse> => {
    return GET(`PaymentServices/Account/${paymentProviderName}/${accountGUID}`).then((data: IAccountResponse) => {
      return data;
    });
  };

  const getAccounts = (
    paymentProviderName: string,
    query?: {
      accountUsage: string | undefined;
      merchantGlobalID?: string;
      creditPaymentServiceName?: string;
      linkedSupplierID?: string;
      verificationStatus?: VerificationStatus;
    },
  ): Promise<IAccountResponse[]> => {
    return GET(`PaymentServices/Account/${paymentProviderName}`, query).then((data: IAccountResponse[]) => {
      return data;
    });
  };

  const getAccountsWithFee = (
    paymentProviderName: string,
    query?: {
      paymentAmount: number;
      accountUsage?: string;
      merchantGlobalID?: string;
      creditPaymentServiceName?: string;
      verificationStatus?: VerificationStatus;
    },
  ): Promise<IAccountResponseWithFee[]> => {
    return GET(`PaymentServices/Payments/Account/${paymentProviderName}/WithFees`, query);
  };

  const getAvailablePaymentMethodAPI = (
    paymentProviderName: string,
    query: {
      MerchantGlobalID?: string;
    },
  ) => {
    return GET(`PaymentServices/Merchant/PaymentMethods/${paymentProviderName}`, query).then(
      (data: {paymentMethods: IPaymentMethodTypes[]}) => {
        return data.paymentMethods;
      },
    );
  };

  const refreshAccountState = (
    paymentProviderName: string,
    accountGUID: string,
    agreementRefNum: string,
  ): Promise<IAccountResponse> => {
    return GET(
      `PaymentServices/BankTransfer/Account/${paymentProviderName}/${accountGUID}/checkstate/${agreementRefNum}`,
    ).then((data: IAccountResponse) => data);
  };

  const updateAccountDetails = (paymentProviderName: string, accountGUID: string, req: UpdateAccountRequest) => {
    return POST(`PaymentServices/Account/${paymentProviderName}/${accountGUID}`, req);
  };

  const deleteBankAccount = (paymentProviderName: string, accountGUID: string): Promise<undefined> => {
    return DELETE(`PaymentServices/Account/${paymentProviderName}/${accountGUID}`);
  };

  const verify = (
    paymentProviderName: string,
    accountGUID: string,
    verificationCode: VerificationCode,
  ): Promise<IAccountResponse> => {
    return POST(`PaymentServices/Account/${paymentProviderName}/${accountGUID}/verify`, verificationCode).then(
      (data: IAccountResponse) => {
        if (data.error && data.error.message) {
          Toast.info(data.error.message);
        }
        return data;
      },
    );
  };

  const retryVerification = (paymentProviderName: string, accountGUID: string): Promise<IAccountResponse> => {
    // retryCalled = true;
    return POST(
      `PaymentServices/Account/${paymentProviderName}/${accountGUID}/retryverification`,
      false,
      true,
      true,
    ).then((data: IAccountResponse) => {
      if (data.error && data.error.message) {
        Toast.info(data.error.message);
      }
      return data;
    });
  };

  const saveCreditCard = (
    paymentProviderName: string,
    req: INewCreditCardAccountRequest,
  ): Promise<INewCreditCardAccountResponse> => {
    return POST(`PaymentServices/CreditCard/Account/${paymentProviderName}`, req).then(
      (req: INewCreditCardAccountResponse) => req,
    );
  };
  const saveAirPlusAccount = (paymentProviderName: string, req: INewAirPlusAccountRequest): Promise<any> => {
    return POST(`PaymentServices/CreditCard/Account/${paymentProviderName}/AirPlus`, req).then((req: any) => req);
  };

  const initiateVerification = (paymentProviderName: string, accountGUID: string): Promise<IAccountResponse> => {
    return POST(
      `PaymentServices/Account/${paymentProviderName}/${accountGUID}/InitiateVerification`,
      false,
      true,
      true,
    );
  };

  const approveLoan = (loanId: number): Promise<void> => {
    return POST(`PaymentServices/Invigo/Loan/${loanId}/Approve`, false);
  };

  const rejectLoan = (loanId: number) => {
    return POST(`PaymentServices/Invigo/Loan/${loanId}/Reject`, false);
  };

  const getMerchantProcessingFee = (
    paymentMethod: string,
    paymentAccountGUID: string,
    paymentAmount: number,
  ): Promise<IMerchantProcessingFee> => {
    return GET(`PaymentServices/BuyerPayment/Fee/${paymentMethod}/${paymentAccountGUID}/${paymentAmount}`).then(
      (data: IMerchantProcessingFee) => data,
    );
  };

  const getAPMerchantProcessingFee = (
    paymentMethod: string,
    paymentAccountGUID: string,
    paymentAmount: number,
  ): Promise<IMerchantProcessingFee> => {
    return GET(`PaymentServices/NonMerchantPayment/Fee/${paymentMethod}/${paymentAccountGUID}/${paymentAmount}`).then(
      (data: IMerchantProcessingFee) => data,
    );
  };

  const getAirPlusDBIConfig = async (
    paymentProviderName: PaymentProviderName,
    paymentAcoountGuid: string,
  ): Promise<IDbiReply> => {
    return GET(
      `PaymentServices/CreditCard/Account/${paymentProviderName}/AirPlus/${paymentAcoountGuid}/DBIConfig`,
    ).then((data: IGetAirPlusDBIConfigResponse) => data.dbiReply);
  };

  return {
    addBankAccount,
    addBankAccountDetails,
    approveLoan,
    deleteBankAccount,
    getAccount,
    getAccounts,
    initiateVerification,
    isLoading,
    refreshAccountState,
    rejectLoan,
    retryVerification,
    saveCreditCard,
    saveAirPlusAccount,
    updateAccountDetails,
    verify,
    getMerchantProcessingFee,
    getAPMerchantProcessingFee,
    getAvailablePaymentMethodAPI,
    getAirPlusDBIConfig,
    getAccountsWithFee,
  };
};

export default useSpendaPaymentServicesAPI;
