import React, {FunctionComponent, useState, useCallback, useEffect} from 'react';
import ReactQuill, {Quill} from 'react-quill';
import {Delta, Sources} from 'quill';
import {debounce} from 'lodash';
import {css} from 'glamor';
import Divider from '@material-ui/core/Divider';
import 'react-quill/dist/quill.snow.css';

const fontSizeArr = [
  '8px',
  '9px',
  '10px',
  '12px',
  '14px',
  '16px',
  '20px',
  '24px',
  '32px',
  '42px',
  '54px',
  '68px',
  '84px',
  '98px',
];

// configure Quill to use inline styles
var DirectionAttribute = Quill.import('attributors/attribute/direction');
Quill.register(DirectionAttribute, true);

var AlignClass = Quill.import('attributors/class/align');
Quill.register(AlignClass, true);

var BackgroundClass = Quill.import('attributors/class/background');
Quill.register(BackgroundClass, true);

var ColorClass = Quill.import('attributors/class/color');
Quill.register(ColorClass, true);

var DirectionClass = Quill.import('attributors/class/direction');
Quill.register(DirectionClass, true);

var FontClass = Quill.import('attributors/class/font');
Quill.register(FontClass, true);

var SizeClass = Quill.import('attributors/class/size');
Quill.register(SizeClass, true);

var AlignStyle = Quill.import('attributors/style/align');
Quill.register(AlignStyle, true);

var BackgroundStyle = Quill.import('attributors/style/background');
Quill.register(BackgroundStyle, true);

var ColorStyle = Quill.import('attributors/style/color');
Quill.register(ColorStyle, true);

var DirectionStyle = Quill.import('attributors/style/direction');
Quill.register(DirectionStyle, true);

var FontStyle = Quill.import('attributors/style/font');
Quill.register(FontStyle, true);

var SizeStyle = Quill.import('attributors/style/size');
Quill.register(SizeStyle, true);

var Size = Quill.import('attributors/style/size');
Size.whitelist = fontSizeArr;
Quill.register(Size, true);

const formats = [
  'header',
  'code-block',
  'bold',
  'italic',
  'underline',
  'color',
  'size',
  'align',
  'bullet',
  'list',
  'link',
  'image',
];

const modules = {
  toolbar: {
    container: '#toolbar',
  },
};

const editorStyle = css({
  position: 'relative',
  '& .ql-toolbar.ql-snow': {
    position: 'absolute',
    zIndex: 1,
    border: '1px solid #EAEAEA',
    backgroundColor: '#FFFFFF',
    borderRadius: '4px',
    boxShadow: '0 0 3px 0 #00000006',
    padding: '2px 5px',
    bottom: '0',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    '& .ql-picker.ql-size': {
      fontSize: '0.75rem',
      width: '55px',
      '& .ql-picker-label': {
        margin: '0px 2px',
        paddingLeft: '4px',
        paddingRight: '2px',
        backgroundColor: '#F8F8F8',
        border: '0.5px solid #F1F1F1',
        borderRadius: '2px',
      },
      '& .ql-picker-options': {
        maxHeight: '100px',
        overflowY: 'auto',
      },
    },
    '& svg': {
      transform: 'scale(0.9)',
      '& .ql-stroke': {
        stroke: '#CCCCCC',
      },
      '& .ql-fill': {
        stroke: '#CCCCCC',
        fill: '#CCCCCC',
      },
    },
  },
  '& .quill': {
    '& .ql-container.ql-snow': {
      border: '1px solid #EAEAEA',
      backgroundColor: '#FAFAFA',
      borderRadius: '5px',
      '& .ql-editor': {
        height: '230px',
        overflowY: 'auto',
        paddingBottom: '50px',
        fontFamily: "'Saira Condensed', sans-serif",
      },
      '& .ql-editor.ql-blank::before': {
        color: '#CCC',
        fontSize: '16px',
        fontStyle: 'normal',
        fontFamily: "'Saira Condensed', sans-serif",
      },
    },
  },
});

const dividerStyle = css({
  margin: '4px 1px !important',
  backgroundColor: '#99999910 !important',
  width: '0.5px !important',
});

/*
 * Custom toolbar component
 */
const EditorToolbar = () => (
  <div id="toolbar" className="flex">
    <button className="ql-code-block" />
    <Divider orientation="vertical" flexItem className={`${dividerStyle}`} />
    <button className="ql-bold" />
    <Divider orientation="vertical" flexItem className={`${dividerStyle}`} />
    <button className="ql-italic" />
    <Divider orientation="vertical" flexItem className={`${dividerStyle}`} />
    <button className="ql-underline" />
    <Divider orientation="vertical" flexItem className={`${dividerStyle}`} />
    <select className="ql-color" />
    <Divider orientation="vertical" flexItem className={`${dividerStyle}`} />
    <span className="m-auto text-xs ml-2 whitespace-nowrap" {...css({color: '#999999'})}>
      Font Size
    </span>
    <select className="ql-size mr-2">
      {Array.from(fontSizeArr, size => (
        <option key={size} value={size}>
          {size}
        </option>
      ))}
    </select>
    <Divider orientation="vertical" flexItem className={`${dividerStyle}`} />
    <button className="ql-align" value="" />
    <button className="ql-align" value="center" />
    <button className="ql-align" value="right" />
    <button className="ql-align" value="justify" />
    <Divider orientation="vertical" flexItem className={`${dividerStyle}`} />
    <button className="ql-list" value="ordered" />
    <Divider orientation="vertical" flexItem className={`${dividerStyle}`} />
    <button className="ql-list" value="bullet" />
    <Divider orientation="vertical" flexItem className={`${dividerStyle}`} />
    <button className="ql-link" />
    <Divider orientation="vertical" flexItem className={`${dividerStyle}`} />
    <button className="ql-image" />
  </div>
);

interface ISRichTextEditor {
  placeholder?: string;
  value: string;
  handleChange: (newValue: string) => void;
}

export const SRichTextEditor: FunctionComponent<ISRichTextEditor> = props => {
  const [editorValue, setEditorValue] = useState<string>('');

  useEffect(() => {
    if (props.value && props.value !== editorValue) {
      setEditorValue(props.value);
    }
  }, [props.value]);

  const debouncedSave = useCallback(
    debounce((newValue: string) => {
      props.handleChange(newValue);
    }, 750),
    []
  );

  const change = (eValue: string, delta: Delta, sources: Sources) => {
    if (sources === 'user') {
      setEditorValue(eValue);
      debouncedSave(eValue);
    }
  };

  return (
    <div className={`w-full ${editorStyle}`}>
      <EditorToolbar />
      <ReactQuill
        theme="snow"
        value={editorValue}
        onChange={change}
        placeholder={props.placeholder || 'Write something...'}
        modules={modules}
        formats={formats}
      />
    </div>
  );
};
