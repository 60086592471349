import * as React from 'react';

export const IconSalesOrderListingRebranding = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <title>Sales Order</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Sales-Order" className="fill-primary">
        <path
          d="M10,0 C4.4771525,0 0,4.4771525 0,10 C0,15.5228475 4.4771525,20 10,20 C15.5228475,20 20,15.5228475 20,10 C19.9940293,4.47962754 15.5203725,0.00597070634 10,0 Z M17.9775,7.6 L10.5541667,9.04333333 C10.4373378,8.86301236 10.3348025,8.67382347 10.2475,8.4775 L7.46416667,2.06083333 C9.59272488,1.37858464 11.9062477,1.58122881 13.8837851,2.62313523 C15.8613226,3.66504165 17.3366287,5.45862022 17.9775,7.6 Z M10,18.3333339 C6.20729,18.3347305 2.89254031,15.7736811 1.93664567,12.1034064 C0.980751025,8.43313165 2.62511239,4.58052019 5.93666667,2.73166667 L8.71833333,9.14083333 C9.11413668,10.0518153 9.73565288,10.8468512 10.5241667,11.4508333 L16.0683333,15.6941667 C14.4985151,17.3765706 12.3010457,18.3322676 10,18.3333339 L10,18.3333339 Z M17.0833333,14.3708333 L11.9791667,10.4641667 L18.2966667,9.23666667 C18.3191667,9.48666667 18.335,9.7425 18.335,10 C18.3342777,11.5452637 17.9006712,13.0594222 17.0833333,14.3708333 Z"
          id="Shape"
        ></path>
      </g>
    </g>
  </svg>
);
