import useHttp from '../hooks/useHttp';
import {IInvoicePaymentAllocation} from '../model/invoice/InvoicePaymentAllocation';

export const useCommonTransactionAPI = () => {
  const {GET} = useHttp();

  const getInvoicePaymentAllocation = (transactionId: number): Promise<IInvoicePaymentAllocation> => {
    return GET(`Spenda/Payments/AccountsReceivable/InvoicePaymentAllocationDetails/${transactionId}`).then(
      (data: IInvoicePaymentAllocation) => data
    );
  };

  return {
    getInvoicePaymentAllocation,
  };
};
