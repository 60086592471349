import React from 'react';
import {Typography} from 'spenda-ui-react';

type SkeletonProps = {
  className?: string;
  count?: number;
  height?: string;
  gapY?: number;
};

const Skeleton = (props: SkeletonProps) => {
  const elements = Array.from({length: props.count || 1});
  const {height, gapY = 2} = props;
  return (
    <div className={`max-w-full animate-pulse flex flex-col gap-y-${gapY} ${props.className}`}>
      {elements.map((_, index) => (
        <Typography
          key={index}
          as="div"
          variant="h1"
          className={`rounded-md bg-gray-300`}
          style={{height: height ?? '12px'}}
        >
          &nbsp;
        </Typography>
      ))}
    </div>
  );
};

export default Skeleton;
