import React, {useContext, useState} from 'react';
import PurchasingContext from '../../context/purchasing/purchasingContext';
import AppContext from '../../context/app/appContext';
import {css} from 'glamor';
import toMaterialStyle from 'material-color-hash';
import {PriceFormat} from '../../utils/formatter';
import {SupplierInventory} from '../../model/inventory/SupplierInventory';
import Skeleton from 'react-loading-skeleton';
import {ProductCardFavourite} from './ProductCardFavourite';
import Box from '@material-ui/core/Box/Box';
import ProductDetailsDialog from '../dialog/ProductDetailsDialog';
import {OpenInNew} from '@material-ui/icons';
import useSupplierInventoryAPI from '../../services/useSupplierInventoryAPI';

interface IProductCardProps {
  product: SupplierInventory;
}

export const ProductCard = ({product}: IProductCardProps) => {
  const colorsStyle = toMaterialStyle(product.ShortDescription || '', 100);
  const hasImage = product.DefaultImageMediaFileThumbnailURL;

  const [productToEdit, setProductToEdit] = React.useState<SupplierInventory | undefined>(undefined);

  const card = css({
    backgroundColor: 'white',
    border: product.IsLoading ? '2px solid #ccc' : '1px solid #1C78AD',
    borderRadius: 4,
    cursor: 'pointer',
    fontSize: '0.875em',
    position: 'relative',
  });

  const header = css({
    backgroundColor: product.IsLoading ? '#fff' : '#D6EBF3',
    borderBottom: '1px solid #1C78AD',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    // fontFamily: "Saira Extra Condensed",
    padding: 3,
  });

  const imgMinWidth = css({
    minWidth: '100%',
  });

  const body = css({
    backgroundColor: product.IsLoading ? '#FFF' : hasImage ? '#FFF' : colorsStyle.backgroundColor,
    cursor: 'pointer',
    height: 160,
    textAlign: 'center',
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    '& p': {padding: '0 3px'},
    '@media(min-width: 1280px)': {
      height: 180,
    },
  });

  const footer = css({
    padding: 3,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    backgroundColor: product.IsLoading ? '#FFF' : hasImage ? '#FFF' : colorsStyle.backgroundColor,
    color: colorsStyle.color,
    opacity: hasImage ? '0.85' : '1',
  });

  const pContext = useContext(PurchasingContext);
  const appContext = useContext(AppContext);

  const supplierInventoryAPI = useSupplierInventoryAPI();

  const [isTempFavourite, setIsTempFavourite] = useState(!!product.IsFavourite);

  const addToOrder = () => {
    pContext.addOrUpdateOrderLine(product, appContext.marketplaceSupplier);
  };

  const onSetFavourite = () => {
    supplierInventoryAPI.setAsFavourite(product, !isTempFavourite).then(isSuccess => {
      if (isSuccess) {
        setIsTempFavourite(!isTempFavourite);
      }
    });
  };

  const openProduct = (product: SupplierInventory) => {
    setProductToEdit(product);
  };

  let cardElm = document.querySelector('#card');
  let cardWidth = cardElm ? cardElm.clientWidth : undefined;
  let cardHeight = cardElm ? cardElm.clientHeight : undefined;
  let skeletonHeaderWidth, skeletonBodyWidth, skeletonBodyHeight, skeletonfooterWidth;
  if (cardWidth && cardWidth < 110 && cardHeight && cardHeight < 175) {
    skeletonHeaderWidth = cardWidth / 2;
    skeletonBodyWidth = cardWidth - 10;
    skeletonBodyHeight = cardHeight && cardHeight - 70;
    skeletonfooterWidth = cardWidth / 2 - 10;
  } else {
    skeletonHeaderWidth = cardWidth && cardWidth / 2;
    skeletonBodyWidth = cardWidth && cardWidth / 2 + 60;
    skeletonBodyHeight = cardHeight && cardHeight - 70;
    skeletonfooterWidth = cardWidth && cardWidth / 2 - 10;
  }

  return (
    <div id="card" className={`${card}`}>
      <div className={`flex items-center justify-between ${header}`}>
        <div className={`justrify-between flex items-start`}>
          <OpenInNew color="secondary" onClick={() => openProduct(product)} style={{color: '#1C78AD'}} />
          {productToEdit && (
            <ProductDetailsDialog product={productToEdit} onClose={() => setProductToEdit(undefined)} />
          )}
          <ProductCardFavourite isFavourite={isTempFavourite} setFavourite={onSetFavourite} />
        </div>
        <div className={`flex items-end justify-between`}>
          {product.IsLoading ? (
            <Skeleton height={16} width={skeletonHeaderWidth} />
          ) : (
            <span className="text-base">
              {' '}
              {product.SpecialSellPriceEx !== 0
                ? product.SpecialSellPriceEx && PriceFormat(product.SpecialSellPriceEx)
                : product.StandardSellPriceEx && PriceFormat(product.StandardSellPriceEx)}
            </span>
          )}
        </div>
      </div>
      <div onClick={addToOrder}>
        <div className={`flex justify-center ${hasImage || 'items-center'} ${body}`} style={colorsStyle}>
          {product.IsLoading ? (
            <div>
              <Skeleton width={skeletonBodyWidth} height={skeletonBodyHeight} />
              <Skeleton width={skeletonBodyWidth} height={10} />
            </div>
          ) : hasImage ? (
            <img
              className={`${imgMinWidth}`}
              alt={product.ShortDescription}
              src={product.DefaultImageMediaFileThumbnailURL}
              title={product.ShortDescription}
            />
          ) : (
            <Box component="div" style={{width: 140, overflow: 'hidden', padding: '0 5px'}} textOverflow="ellipsis">
              {product.ShortDescription}
            </Box>
          )}
        </div>
        <div className={`absolute bottom-0 left-0 right-0 flex flex-col items-center justify-between ${footer}`}>
          {hasImage ? (
            product.IsLoading ? (
              <Skeleton width={110} height={13} />
            ) : (
              <h5 className="text-center">{product.ShortDescription}</h5>
            )
          ) : (
            ' '
          )}
          <div className="flex w-full justify-between sm:text-xs">
            {product.IsLoading ? (
              <Skeleton width={skeletonfooterWidth} height={16}></Skeleton>
            ) : (
              <Box component="div" style={{width: 90, overflow: 'hidden'}} textOverflow="ellipsis">
                {product.SKU}
              </Box>
            )}
            {product.IsLoading ? (
              <Skeleton width={skeletonfooterWidth} height={16}></Skeleton>
            ) : (
              <Box component="div" textOverflow="ellipsis">
                {product.UoMDescription}
              </Box>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
