import * as React from 'react';
const APLogoIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg {...props}>
    <path
      fill="#FFB21F"
      d="M35.285 1.112a1.797 1.797 0 0 0-1.273-.526h-2.694v8.437h3.77c.4 0 .724-.323.724-.722V2.383a1.79 1.79 0 0 0-.527-1.27Z"
    />
    <path fill="#fff" d="m9.223 8.164-5.036 5.703v21.984c0 .415.337.75.752.75h18.193V8.165H9.223Z" />
    <path
      fill="#333"
      fillRule="evenodd"
      d="M16.918 13.253v-1.778c.3.047.502.148.665.23.14.07.25.126.374.126.396 0 .568-.48.568-.724 0-.522-.826-.763-1.607-.816v-.108a.499.499 0 0 0-.5-.495c-.276 0-.5.222-.5.495v.139c-1.194.197-1.878.919-1.878 1.98 0 1.307 1.08 1.717 1.877 2.014v1.995c-.439-.082-.725-.301-.956-.478v-.001h-.001c-.188-.145-.336-.259-.507-.259-.34 0-.598.445-.598.734 0 .545.846 1.199 2.062 1.273v.05c0 .153.071.295.194.388a.426.426 0 0 0 .29.107h.017c.276 0 .5-.222.5-.495v-.105c1.139-.24 1.79-1.02 1.79-2.14 0-1.269-.857-1.775-1.79-2.132Zm.46 2.28a.736.736 0 0 1-.47.717v-1.484c.325.195.47.433.47.766Zm-1.487-2.642v-1.329c-.359.11-.548.328-.548.631 0 .377.26.56.548.698Z"
      clipRule="evenodd"
    />
    <path
      fill="#E1C085"
      d="M23.523.586h10.49c-.994 0-1.8.805-1.8 1.797v17.031h-10.49V2.383c0-.992.806-1.797 1.8-1.797Z"
    />
    <path
      fill="#C68A19"
      d="M24.62 2.383c0-.992.805-1.797 1.8-1.797h-2.897c-.994 0-1.8.805-1.8 1.797v17.031h2.896V2.383Z"
    />
    <path
      fill="#24A5EF"
      d="M35.264 19.728v18.59c0 .605-.492 1.096-1.098 1.096h-14.4c-.606 0-1.098-.49-1.098-1.095v-18.59c0-.605.492-1.096 1.097-1.096h14.401c.606 0 1.098.49 1.098 1.095Z"
    />
    <path
      fill="#1C78AD"
      d="M24.696 36.661a2.815 2.815 0 0 1-2.819-2.812V18.633h-2.112c-.606 0-1.097.49-1.097 1.095v18.59c0 .606.491 1.096 1.097 1.096h14.401c.606 0 1.098-.49 1.098-1.095V36.66H24.696Z"
    />
    <path fill="#24A5EF" d="M9.223 8.164 9.2 13.538c0 .182-.15.33-.332.33h-4.68l5.036-5.704Z" />
    <path
      fill="#7FC0A6"
      d="M32.838 21.46v2.549c0 .353-.288.64-.641.64h-10.46a.641.641 0 0 1-.641-.64V21.46c0-.353.287-.64.64-.64h10.46c.355 0 .642.287.642.64Z"
    />
    <path
      fill="#1E9D71"
      d="M25.273 23.322a2.505 2.505 0 0 1-2.507-2.502h-1.03a.64.64 0 0 0-.64.64v2.549c0 .353.287.64.64.64h10.46a.64.64 0 0 0 .642-.64v-.687h-7.565Z"
    />
    <path
      fill="#C68A19"
      d="M32.837 27.53v9.237c0 .34-.276.616-.616.616h-1.428a.617.617 0 0 1-.617-.616V27.53c0-.34.276-.616.617-.616h1.428c.34 0 .616.276.616.616ZM28.22 35.49v1.52a.372.372 0 0 1-.373.373h-1.76a.372.372 0 0 1-.372-.372v-1.522c0-.206.166-.372.373-.372h1.76c.205 0 .372.166.372.372ZM23.757 35.49v1.52a.372.372 0 0 1-.373.373h-1.76a.372.372 0 0 1-.372-.372v-1.522c0-.206.167-.372.373-.372h1.76c.205 0 .372.166.372.372ZM23.757 31.427v1.521a.372.372 0 0 1-.373.372h-1.76a.372.372 0 0 1-.372-.372v-1.521c0-.206.167-.372.373-.372h1.76c.205 0 .372.166.372.372ZM28.22 31.427v1.521a.372.372 0 0 1-.373.372h-1.76a.372.372 0 0 1-.372-.372v-1.521c0-.206.166-.372.373-.372h1.76c.205 0 .372.166.372.372ZM28.22 27.286v1.522a.372.372 0 0 1-.373.372h-1.76a.372.372 0 0 1-.372-.372v-1.522c0-.206.166-.372.373-.372h1.76c.205 0 .372.166.372.372ZM23.757 27.286v1.522a.372.372 0 0 1-.373.372h-1.76a.372.372 0 0 1-.372-.372v-1.522c0-.206.167-.372.373-.372h1.76c.205 0 .372.166.372.372Z"
    />
    <path
      fill="#333"
      fillRule="evenodd"
      d="M34.012 0h-10.49a2.388 2.388 0 0 0-2.387 2.383v5.195H9.197a.587.587 0 0 0-.524.322l-4.926 5.57a.585.585 0 0 0-.147.388v21.994c0 .736.6 1.336 1.338 1.336h13.144v1.13c0 .928.756 1.682 1.685 1.682h14.4c.929 0 1.685-.754 1.685-1.681v-18.59c0-.928-.756-1.682-1.685-1.682h-1.368V9.609h2.29c.723 0 1.31-.586 1.31-1.308V2.383A2.388 2.388 0 0 0 34.013 0ZM8.61 9.742v3.54H5.48l3.13-3.54Zm-3.836 26.11c0 .09.074.164.164.164h13.144V19.728c0-.927.756-1.681 1.685-1.681h1.368V8.75H9.784v5.117a.586.586 0 0 1-.587.586H4.774v21.399Zm25.363-17.805a.586.586 0 1 0 0 1.172h-10.37a.51.51 0 0 0-.511.51v18.59c0 .28.229.51.51.51h14.4a.51.51 0 0 0 .511-.51v-18.59a.51.51 0 0 0-.51-.51h-3.639a.586.586 0 1 0 0-1.172h1.096V2.383c0-.442.122-.856.333-1.211h-8.435c-.669 0-1.213.543-1.213 1.21v15.665h7.828Zm4.952-9.61a.137.137 0 0 0 .136-.136V2.383a1.214 1.214 0 0 0-2.426 0v6.054h2.29Z"
      clipRule="evenodd"
    />
    <path
      fill="#333"
      fillRule="evenodd"
      d="M20.508 24.009V21.46c0-.676.55-1.226 1.228-1.226h10.46c.677 0 1.228.55 1.228 1.226v2.549c0 .675-.55 1.225-1.228 1.225h-10.46c-.677 0-1.228-.55-1.228-1.226Zm11.742 0V21.46a.054.054 0 0 0-.054-.054h-10.46a.054.054 0 0 0-.054.054v2.549c0 .03.024.053.054.053h10.46c.03 0 .054-.024.054-.053Z"
      clipRule="evenodd"
    />
    <path
      fill="#333"
      d="M32.222 26.328h-1.429c-.663 0-1.203.54-1.203 1.201v3.721a.586.586 0 0 0 1.174 0v-3.72a.03.03 0 0 1 .03-.03h1.428a.03.03 0 0 1 .03.03v9.238a.03.03 0 0 1-.03.029h-1.429a.03.03 0 0 1-.029-.03v-7.08a.586.586 0 0 0-1.174 0v7.08c0 .663.54 1.202 1.203 1.202h1.43c.663 0 1.203-.54 1.203-1.201v-9.239c0-.662-.54-1.2-1.204-1.2Z"
    />
    <path
      fill="#333"
      fillRule="evenodd"
      d="M21.624 26.328h1.76c.529 0 .96.43.96.958v1.522a.96.96 0 0 1-.96.958h-1.76a.96.96 0 0 1-.96-.958v-1.522a.96.96 0 0 1 .96-.958Zm.214 2.266h1.331V27.5h-1.33v1.094ZM26.087 26.328h1.759c.53 0 .96.43.96.958v1.522a.96.96 0 0 1-.96.958h-1.76a.96.96 0 0 1-.959-.958v-1.522a.96.96 0 0 1 .96-.958Zm.214 2.266h1.331V27.5h-1.33v1.094ZM21.624 30.469h1.76c.529 0 .96.43.96.958v1.521a.96.96 0 0 1-.96.958h-1.76a.96.96 0 0 1-.96-.958v-1.521a.96.96 0 0 1 .96-.958Zm.214 2.265h1.331v-1.093h-1.33v1.093ZM26.087 30.469h1.759c.53 0 .96.43.96.958v1.521a.96.96 0 0 1-.96.958h-1.76a.96.96 0 0 1-.959-.958v-1.521a.96.96 0 0 1 .96-.958Zm.214 2.265h1.331v-1.093h-1.33v1.093ZM21.624 34.531h1.76c.529 0 .96.43.96.959v1.52a.96.96 0 0 1-.96.959h-1.76a.96.96 0 0 1-.96-.958v-1.522a.96.96 0 0 1 .96-.958Zm.214 2.266h1.331v-1.094h-1.33v1.094ZM26.087 34.531h1.759c.53 0 .96.43.96.959v1.52a.96.96 0 0 1-.96.959h-1.76a.96.96 0 0 1-.959-.958v-1.522a.96.96 0 0 1 .96-.958Zm.214 2.266h1.331v-1.094h-1.33v1.094Z"
      clipRule="evenodd"
    />
    <path
      fill="#333"
      d="M23.757 5.078h1.174a.586.586 0 1 0 0-1.172h-1.174a.586.586 0 1 0 0 1.172ZM30.176 3.906h-3.444a.586.586 0 1 0 0 1.172h3.444a.586.586 0 1 0 0-1.172ZM30.175 6.719h-2.113a.586.586 0 1 0 0 1.172h2.113a.586.586 0 1 0 0-1.172ZM23.757 7.89h2.505a.586.586 0 1 0 0-1.171h-2.505a.586.586 0 1 0 0 1.172ZM23.757 10.703h1.174a.586.586 0 1 0 0-1.172h-1.174a.586.586 0 1 0 0 1.172ZM30.176 9.531h-3.444a.586.586 0 1 0 0 1.172h3.444a.586.586 0 1 0 0-1.172ZM26.262 12.344h-2.505a.586.586 0 1 0 0 1.172h2.505a.586.586 0 1 0 0-1.172ZM30.175 12.344h-2.113a.586.586 0 1 0 0 1.172h2.113a.586.586 0 1 0 0-1.172Z"
    />
    <rect width="6.184" height="1.172" x="6.73" y="17.266" fill="#333" rx=".586" />
    <rect width="6.967" height="1.172" x="6.652" y="19.453" fill="#333" rx=".586" />
    <rect width="5.48" height="1.172" x="6.73" y="21.641" fill="#333" rx=".586" />
    <rect width="11.038" height="1.172" x="6.652" y="24.609" fill="#333" rx=".586" />
    <rect width="9.081" height="1.172" x="6.652" y="26.875" fill="#333" rx=".586" />
    <rect width="11.038" height="1.172" x="6.652" y="29.063" fill="#333" rx=".586" />
    <rect width="10.098" height="1.172" x="6.652" y="31.328" fill="#333" rx=".586" />
  </svg>
);
export default APLogoIcon;
