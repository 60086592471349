import * as React from "react"

function WordIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={50}
      height={50}
      fill="none"
      {...props}
    >
      <path
        fill="#41A5EE"
        d="M47.775 2.02H13.733a2.122 2.122 0 00-2.128 2.115v9.425l19.73 5.77 18.568-5.77V4.133a2.12 2.12 0 00-2.128-2.115z"
      />
      <path
        fill="#2B7CD3"
        d="M49.903 13.56H11.606V25.1l19.728 3.461L49.903 25.1V13.56z"
      />
      <path
        fill="#185ABD"
        d="M11.605 25.1v11.54l18.569 2.308 19.729-2.308V25.1H11.606z"
      />
      <path
        fill="#103F91"
        d="M13.733 48.18h34.043a2.122 2.122 0 002.128-2.116V36.64H11.605v9.425a2.12 2.12 0 002.128 2.115z"
      />
      <path
        fill="#000"
        d="M25.725 11.252h-14.12v28.85h14.12a2.128 2.128 0 002.128-2.116v-24.62a2.128 2.128 0 00-2.128-2.114z"
        opacity={0.1}
      />
      <path
        fill="#000"
        d="M24.566 12.405h-12.96v28.85h12.96a2.128 2.128 0 002.127-2.115V14.52a2.13 2.13 0 00-2.128-2.115z"
        opacity={0.2}
      />
      <path
        fill="#000"
        d="M24.566 12.405h-12.96v26.542h12.96a2.128 2.128 0 002.127-2.115V14.52a2.13 2.13 0 00-2.128-2.115z"
        opacity={0.2}
      />
      <path
        fill="#000"
        d="M23.405 12.405h-11.8v26.542h11.8a2.128 2.128 0 002.127-2.115V14.52a2.129 2.129 0 00-2.127-2.115z"
        opacity={0.2}
      />
      <path
        fill="url(#a)"
        d="M2.128 12.405h21.277c1.174 0 2.127.948 2.127 2.115v21.158a2.12 2.12 0 01-2.127 2.115H2.128A2.121 2.121 0 010 35.678V14.52c0-1.167.952-2.115 2.128-2.115z"
      />
      <path
        fill="#fff"
        d="M8.729 28.629c.042.326.07.61.083.853h.048c.019-.23.058-.508.116-.834s.112-.6.16-.825l2.237-9.6h2.893l2.32 9.455c.135.588.231 1.184.29 1.784h.037a13.422 13.422 0 01.242-1.731l1.85-9.513h2.634l-3.251 13.756h-3.076l-2.205-9.11a22.798 22.798 0 01-.217-1.027c-.081-.422-.13-.729-.15-.923h-.038a19.59 19.59 0 01-.15.997c-.075.44-.134.767-.179.979L10.3 31.97H7.171L3.902 18.225h2.68l2.016 9.619c.047.197.09.46.13.786z"
      />
      <defs>
        <linearGradient
          id="a"
          x1={4.4657}
          x2={21.0659}
          y1={10.7238}
          y2={39.4746}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2368C4" />
          <stop offset={0.5} stopColor="#1A5DBE" />
          <stop offset={1} stopColor="#1146AC" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export {WordIcon}
