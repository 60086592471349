import React, {FunctionComponent, useState} from 'react';
import clsx from 'clsx';
import {StepLabel, StepIconProps, makeStyles, Theme, withStyles, StepConnector, Stepper, Step} from '@material-ui/core';
import stepperIcon from '../../assets/svg/stepper-current.svg';
import {RightAngleIcon} from '../../assets/svg';
export interface IStepper {
  steps: string[];
  activeStep: number;
  isSalesModule?: boolean;
}

type StyleProps = {
  isSalesModule?: boolean;
};

const StyledConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50%)',
    right: 'calc(50%)',
  },
  active: {
    '& $line': {
      borderColor: '#C68A1940',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#C68A1920',
    },
  },
  line: {
    borderColor: '#F1F1F1',
    borderTopWidth: 1,
    borderRadius: 1,
  },
})(StepConnector);

const useCustomStepperStyle = makeStyles<Theme, StyleProps>({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: '#C68A19 !important',
    boxShadow: '0 0 0 3px #eec477 !important',
    zIndex: 1,
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: '#cccccc !important',
    boxShadow: '0 0 0 3px rgb(234, 234, 234) !important',
    zIndex: 1,
  },
  completed: {
    zIndex: 1,
    backgroundColor: '#f4d8a4 !important',
    boxShadow: '0 0 0 3px #fcf5e8 !important',
    width: 8,
    height: 8,
    borderRadius: '50%',
  },
  label: {
    color: 'rgb(51,51,51) !important',
    fontSize: '14px',
    whiteSpace: 'nowrap',
    lineHeight: 1.063,
    marginTop: '5px!important',
  },
  labelActive: {
    color: '#C68A19 !important',
  },
  labelCompleted: {
    color: 'rgba(51, 51, 51, 0.4) !important',
  },
  customStepperClose: {
    minWidth: '130px',
    position: 'absolute',
    height: ({isSalesModule}) => (isSalesModule ? '58px' : '80px'),
    right: '0px',
    overflow: 'hidden',
    transition: '.2s linear',
    background: '#fff',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '6px 0 0 6px',
    boxShadow: '0 1px 4px 0 #00000020',
    '& span': {
      color: ({isSalesModule}) => (isSalesModule ? '#333333' : '#C68A19'),
    },
  },
  stepperRoot: {
    padding: '0px',
    backgroundColor: '#fff',
    marginLeft: '30px',
  },
  stepperActionBtn: {
    content: '""',
    position: 'absolute',
    width: '26px',
    height: '100%',
    left: '0px',
    top: '0px',
    background: 'hsl(var(--primary))',
    borderRadius: '6px 0 0 6px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& img': {
      width: '11px',
    },
  },
});

const useStepIconStyles = makeStyles({
  root: {
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  circle: {
    width: 15,
    height: 15,
    borderRadius: '50%',
    backgroundColor: 'transparent',
    border: '1px solid hsl(var(--primary))',
  },
  semiCircle: {
    width: 15,
    height: 15,
    borderRadius: '50%',
    background: 'linear-gradient(to right, hsl(var(--primary)) 60%, transparent 50%)',
    backgroundRepeat: 'no-repeat',
    border: '1px solid hsl(var(--primary))',
    backgroundPosition: '-1px 0',
  },
  completed: {
    width: 15,
    height: 15,
    borderRadius: '50%',
    backgroundColor: 'hsl(var(--primary))',
    border: '1px solid hsl(var(--primary))',
  },
});

function QontoStepIcon(props: StepIconProps) {
  const classes = useCustomStepperStyle({});
  const {active, completed} = props;

  return (
    <div className={classes.root}>
      {completed ? (
        <div className={classes.completed} />
      ) : (
        <div
          className={clsx({
            [classes.active]: active,
            [classes.circle]: !active,
          })}
        />
      )}
    </div>
  );
}

function StepIcon(props: Omit<StepIconProps, 'icon'>) {
  const classes = useStepIconStyles();
  const {completed} = props;

  return (
    <div className={classes.root}>
      {completed ? <div className={classes.completed} /> : <div className={classes.circle} />}
    </div>
  );
}

export const CustomStepper: FunctionComponent<IStepper> = ({steps, activeStep, isSalesModule}) => {
  const classes = useCustomStepperStyle({isSalesModule});

  const [open, setOpen] = useState(false);
  const [showStepper, setShowStepper] = useState(false);
  const handleStepper = () => {
    if (open) {
      setOpen(false);
      setTimeout(() => {
        setShowStepper(false);
      }, 100);
    } else {
      setTimeout(() => {
        setOpen(true);
      }, 100);
      setShowStepper(true);
    }
  };
  return (
    <div
      className={clsx(
        'absolute right-0 z-50 flex items-center rounded-md bg-white text-spenda-primarytext shadow-[0_1px_4px_0_#00000020]',
        {
          'min-w-[66%] transition-all delay-300': open,
          'min-w-[130px] overflow-hidden': !open,
          'h-20': !isSalesModule,
          'h-[58px]': isSalesModule,
        },
      )}
    >
      <div className={clsx('z-50 cursor-pointer', classes.stepperActionBtn)} onClick={handleStepper}>
        <RightAngleIcon className={`h-4 ${open ? null : 'rotate-180'}`} />
      </div>

      {showStepper &&
        (isSalesModule ? (
          <section className="z-50 ml-8 flex flex-1">
            {steps.map((label: string, index: number) => (
              <div key={label} className="flex items-center gap-x-2 rounded-md px-2">
                <StepIcon completed={activeStep >= index + 1} />
                <span className="font-poppins text-xs font-medium leading-[0.8rem] text-spenda-primarytext">
                  {label}
                </span>
              </div>
            ))}
          </section>
        ) : (
          <Stepper
            classes={{
              root: classes.stepperRoot,
            }}
            activeStep={activeStep}
            alternativeLabel={!isSalesModule}
            connector={isSalesModule ? <></> : <StyledConnector />}
          >
            {steps.map(label => (
              <Step key={label}>
                <StepLabel
                  classes={{
                    label: classes.label,
                    active: classes.labelActive,
                    completed: classes.labelCompleted,
                  }}
                  StepIconComponent={QontoStepIcon}
                >
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        ))}

      {showStepper ? null : isSalesModule ? ( // </Stepper> //   ))} //     </Step> //       )} //         </StepLabel> //           {label} //         > //           StepIconComponent={QontoStepIcon} //           }} //             completed: classes.labelCompleted, //             active: classes.labelActive, //             label: classes.label, //           classes={{ //         <StepLabel //       ) : ( //         </StepLabel> //           </p> //             {label} //           > //             style={{lineHeight: '0.8rem'}} //             className="font-poppins text-xs font-medium text-spenda-primarytext" //           <p //         <StepLabel StepIconComponent={props => StepIcon(props)}> //       {isSalesModule ? ( //     <Step key={label}> //   {steps.map(label => ( // > //   connector={isSalesModule ? <></> : <StyledConnector />} //   alternativeLabel={!isSalesModule} //   activeStep={activeStep} //   }} //     root: classes.stepperRoot, //   classes={{ // <Stepper
        <section className="ml-8 flex flex-1">
          <div className="flex items-center gap-x-2 rounded-md px-2">
            <StepIcon completed={activeStep <= 0 ? false : true} />
            <span className="font-poppins text-xs font-medium text-spenda-primarytext">
              {steps[activeStep < 0 ? activeStep : activeStep - 1]}
            </span>
          </div>
        </section>
      ) : (
        <div className="flex flex-col items-center justify-center pl-8 pr-2">
          <img src={stepperIcon} />
          <span>{steps[activeStep]}</span>
        </div>
      )}
    </div>
  );
};
