import * as React from 'react';

const VirtualCreditFacility = (props: {width?: string; height?: string}) => (
  <svg
    width={props.width || '40px'}
    height={props.height || '40px'}
    fill="none"
    viewBox="0 0 515 515"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M495.772 182.375v209.032c0 16.871-13.674 30.555-30.559 30.555H108.991c-16.873 0-30.559-13.684-30.559-30.555V182.375c0-16.87 13.686-30.555 30.559-30.555h356.222c16.885.002 30.559 13.686 30.559 30.555Z"
      fill="#1EA0E4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M465.215 133.56c26.973.002 48.819 21.865 48.819 48.815v209.032c0 26.951-21.846 48.816-48.821 48.816H108.991c-26.959 0-48.821-21.86-48.821-48.816V182.375c0-26.955 21.862-48.815 48.821-48.815h356.224Zm-.002 18.26c16.885.002 30.559 13.686 30.559 30.555v209.032c0 16.871-13.674 30.555-30.559 30.555H108.991c-16.873 0-30.559-13.684-30.559-30.555V182.375c0-16.87 13.686-30.555 30.559-30.555h356.222Z"
      fill="#333"
    />
    <path
      d="M465.215 151.822h-22.889c16.885 0 30.559 13.684 30.559 30.555v209.032c0 16.87-13.674 30.555-30.559 30.555h22.889c16.884 0 30.559-13.685 30.559-30.555V182.376c0-16.87-13.675-30.554-30.559-30.554Z"
      fill="#1C78AD"
    />
    <path d="M78.434 244.981h417.339v62.557H78.433v-62.557Z" fill="#7FC0A6" />
    <path d="M472.885 244.981h22.888v62.557h-22.888v-62.557Z" fill="#3C9F78" />
    <path
      d="M197.598 216.597h-69.537a8.1 8.1 0 0 1-8.1-8.1v-15.174a8.1 8.1 0 0 1 8.1-8.099h69.537a8.1 8.1 0 0 1 8.1 8.099v15.175a8.1 8.1 0 0 1-8.1 8.099Z"
      fill="#DDB975"
    />
    <path
      d="M197.6 185.225h-22.889a8.1 8.1 0 0 1 8.1 8.1V208.5a8.1 8.1 0 0 1-8.1 8.099H197.6a8.1 8.1 0 0 0 8.1-8.099v-15.177a8.099 8.099 0 0 0-8.1-8.098Z"
      fill="#C68A19"
    />
    <ellipse cx={417.701} cy={364.144} rx={28.164} ry={28.161} fill="#1EA0E4" />
    <path
      d="M417.701 335.983c-3.992 0-7.787.836-11.228 2.334 9.964 4.338 16.934 14.266 16.934 25.827 0 11.56-6.97 21.489-16.934 25.827a28.057 28.057 0 0 0 11.228 2.334c15.555 0 28.164-12.607 28.164-28.161 0-15.552-12.61-28.161-28.164-28.161Z"
      fill="#1C78AD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M380.619 364.144c0-20.444 16.635-37.077 37.081-37.077 20.447 0 37.081 16.633 37.082 37.077 0 20.445-16.634 37.077-37.082 37.077-20.447 0-37.081-16.632-37.081-37.077Zm17.835 0c0 10.612 8.634 19.245 19.246 19.245 10.613 0 19.246-8.633 19.247-19.245 0-10.611-8.634-19.244-19.247-19.244-10.612 0-19.246 8.633-19.246 19.244ZM111.045 193.323c0-9.382 7.634-17.015 17.018-17.015h69.535c9.383 0 17.017 7.633 17.017 17.015v15.176c0 9.383-7.634 17.015-17.017 17.015h-69.535c-9.384 0-17.018-7.633-17.018-17.015v-15.176Zm17.836 14.358h67.9V194.14h-67.9v13.541Z"
      fill="#333"
    />
    <path
      d="M148.504 346.775a8.92 8.92 0 0 0-12.612 0l-4.759 4.76-4.759-4.76a8.918 8.918 0 0 0-12.612 12.608l4.761 4.76-4.761 4.76a8.916 8.916 0 0 0 0 12.609c2.79 2.571 7.981 4.043 12.612 0l4.759-4.76 4.759 4.76c2.788 2.571 7.982 4.043 12.612 0a8.916 8.916 0 0 0 0-12.609l-4.761-4.76 4.761-4.76a8.914 8.914 0 0 0 0-12.608ZM204.678 346.775a8.92 8.92 0 0 0-12.612 0l-4.759 4.76-4.76-4.76a8.916 8.916 0 0 0-12.611 0 8.914 8.914 0 0 0 0 12.608l4.761 4.76-4.761 4.76a8.916 8.916 0 0 0 0 12.609c2.789 2.571 7.98 4.043 12.611 0l4.76-4.76 4.759 4.76c2.788 2.571 7.982 4.043 12.612 0a8.916 8.916 0 0 0 0-12.609l-4.761-4.76 4.761-4.76a8.914 8.914 0 0 0 0-12.608ZM260.852 346.775c-3.483-3.482-9.129-3.484-12.61 0l-4.762 4.761-4.762-4.761a8.917 8.917 0 0 0-12.61 0 8.913 8.913 0 0 0 0 12.608l4.761 4.76-4.761 4.76a8.914 8.914 0 0 0 0 12.609c2.788 2.571 7.981 4.043 12.61 0l4.762-4.761 4.762 4.761c2.788 2.573 7.98 4.043 12.61 0a8.916 8.916 0 0 0 0-12.609l-4.761-4.76 4.761-4.76a8.914 8.914 0 0 0 0-12.608ZM317.022 346.775c-3.484-3.482-9.13-3.484-12.61 0l-4.762 4.761-4.762-4.761a8.917 8.917 0 0 0-12.61 0 8.913 8.913 0 0 0 0 12.608l4.76 4.76-4.76 4.76a8.914 8.914 0 0 0 0 12.609c2.788 2.571 7.981 4.043 12.61 0l4.762-4.761 4.762 4.761c2.788 2.573 7.98 4.043 12.61 0a8.916 8.916 0 0 0 0-12.609l-4.761-4.76 4.761-4.76a8.914 8.914 0 0 0 0-12.608Z"
      fill="#333"
    />
    <ellipse cx={457.912} cy={201.506} rx={9.445} ry={9.444} fill="#333" />
    <path
      d="M38.962 127.559c1.652 1.861 8.16 4.024 12.605-.437a8.917 8.917 0 0 0-.438-12.602L19.917 85.405c-3.6-3.36-9.242-3.164-12.605.437a8.917 8.917 0 0 0 .438 12.602l31.212 29.115ZM8.91 160.5c.105 0 .211-.001.316-.005l21.86-.758c4.922-.172 8.773-4.299 8.602-9.22-.17-4.922-4.352-8.76-9.22-8.602l-21.86.758c-4.923.172-8.774 4.299-8.603 9.22a8.915 8.915 0 0 0 8.906 8.607ZM73.769 113.657c.104 0 .21-.001.316-.005 4.922-.171 8.773-4.299 8.6-9.22l-.722-20.825c-.171-4.922-4.285-8.778-9.222-8.601a8.914 8.914 0 0 0-8.6 9.22l.722 20.825a8.917 8.917 0 0 0 8.906 8.606Z"
      fill="#333"
    />
  </svg>
);

export default VirtualCreditFacility;
