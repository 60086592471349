import React, {useState} from 'react';
import {Typography} from 'spenda-ui-react';
import {camelCase, upperFirst} from 'lodash';

import {AlertDialogSlideV2} from './AlertDialogSlideV2';

interface ICatalogueAddingProductOptionsProps {
  handleCancel?: () => void;
  handleContinue?: () => void;
}

interface IProductOptionBox {
  content?: string;
  isSelected?: boolean;
  handleSelectOption?: () => void;
}

export const CatalogueAddingProductOptionsDialog = (props: ICatalogueAddingProductOptionsProps) => {
  const {handleCancel, handleContinue} = props;

  const [isSelected, setIsSelected] = useState<boolean>(false);

  const ProductOptionBox = (props: IProductOptionBox) => {
    const {content, handleSelectOption, isSelected} = props;
    return (
      <div
        className={`flex h-[112px] max-w-[230px] cursor-pointer items-center justify-center rounded-md border border-primary p-6 ${isSelected ? 'bg-primary/20' : 'bg-primary/10'}`}
        onClick={handleSelectOption}
      >
        <Typography
          className="text-center font-semibold text-primary"
          data-autoid={`txt${upperFirst(camelCase(content))}`}
        >
          {content}
        </Typography>
      </div>
    );
  };

  return (
    <AlertDialogSlideV2
      dialogActionsAlignment="justify-between"
      dialogClassess="!w-full !min-w-[20%] !max-w-[690px]"
      dialogBodyClassess="p-10 text-black-800"
      headingTextSize="h1"
      title={`Adding products`}
      actions={[
        {
          action: handleCancel,
          label: 'Cancel',
          variant: 'outlined',
        },
        {
          action: handleContinue,
          label: 'Continue',
          disabled: !isSelected,
        },
      ]}
    >
      <Typography variant="small" className="mb-10 text-center">
        You have two options for sourcing products for the shared catalog: you can either download and upload our
        spreadsheet template or choose from the existing products in Product Management.
      </Typography>
      <div className="flex items-center justify-center gap-8">
        <ProductOptionBox
          content="Download & upload spreadsheet"
          isSelected={isSelected}
          handleSelectOption={() => setIsSelected(true)}
        />
        <ProductOptionBox content="Choose from existing products" />
      </div>
    </AlertDialogSlideV2>
  );
};
