import React, {useState} from 'react';
import {FormikProps, getIn} from 'formik';
import clsx from 'clsx';

import {ISupplier} from '../../model/supplier/Supplier';
import {LocationDialog} from '../dialog/LocationDialog';
import {STextField} from '../inputs/STextField';

import {makeStyles} from '@material-ui/core';

const useSupplierStyle = makeStyles(() => ({
  newAddressBox: {
    backgroundColor: '#C8E2ED',
    border: '1px solid #0082BA',
    borderRadius: '6px',
  },
  addressBox: {
    border: '1px solid #0082BA',
    borderRadius: '6px',
  },
}));

export type CreateSupplierValues = Partial<ISupplier>;

export const SupplierForm = (props: FormikProps<CreateSupplierValues>) => {
  const classes = useSupplierStyle();

  const [open, setOpen] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const {values, errors, touched, handleChange, handleBlur, setFieldValue} = props;

  const handleAddressDialogOpen = (_isEdit: boolean = false) => {
    setOpen(true);
    setIsEdit(_isEdit);
  };

  return (
    <>
      <LocationDialog
        {...props}
        isEdit={isEdit}
        open={open}
        setOpen={setOpen}
        locationIndex={0}
        addressIndex={0}
        newUI={true}
        isLatestDesign={true}
      />
      <div className="grid grid-cols-7 gap-x-5">
        <div className={clsx('col-span-5 grid grid-cols-3 gap-x-3 gap-y-7')}>
          <STextField
            v2
            fullWidth
            id="Name"
            name="Name"
            variant="outlined"
            label="Company name"
            placeholder="Company name"
            value={values.Name}
            onBlur={handleBlur}
            onChange={handleChange}
            helperText={touched?.Name && errors?.Name}
            error={touched?.Name && Boolean(errors?.Name)}
            onClearClick={() => setFieldValue('Name', '')}
          />
          <STextField
            v2
            fullWidth
            id="Contacts.0.FirstName"
            name="Contacts.0.FirstName"
            variant="outlined"
            label="First name"
            placeholder="First name"
            value={values.Contacts?.[0]?.FirstName}
            onBlur={handleBlur}
            onChange={handleChange}
            helperText={getIn(touched, 'Contacts.0.FirstName') && getIn(errors, 'Contacts.0.FirstName')}
            error={getIn(touched, 'Contacts.0.FirstName') && Boolean(getIn(errors, 'Contacts.0.FirstName'))}
            onClearClick={() => setFieldValue('Contacts.0.FirstName', '')}
          />
          <STextField
            v2
            fullWidth
            id="Contacts.0.LastName"
            name="Contacts.0.LastName"
            variant="outlined"
            label="Last name"
            placeholder="Last name"
            value={values.Contacts?.[0]?.LastName}
            onBlur={handleBlur}
            onChange={handleChange}
            helperText={getIn(touched, 'Contacts.0.LastName') && getIn(errors, 'Contacts.0.LastName')}
            error={getIn(touched, 'Contacts.0.LastName') && Boolean(getIn(errors, 'Contacts.0.LastName'))}
            onClearClick={() => setFieldValue('Contacts.0.LastName', '')}
          />
          <STextField
            v2
            fullWidth
            id="Contacts.0.EmailAddress"
            name="Contacts.0.EmailAddress"
            variant="outlined"
            label="Email"
            placeholder="Email"
            value={values.Contacts?.[0]?.EmailAddress}
            onBlur={handleBlur}
            onChange={handleChange}
            helperText={getIn(touched, 'Contacts.0.EmailAddress') && getIn(errors, 'Contacts.0.EmailAddress')}
            error={getIn(touched, 'Contacts.0.EmailAddress') && Boolean(getIn(errors, 'Contacts.0.EmailAddress'))}
            className="col-span-2"
            onClearClick={() => setFieldValue('Contacts.0.EmailAddress', '')}
          />
          <STextField
            v2
            fullWidth
            id="Contacts.0.PhoneMobile"
            name="Contacts.0.PhoneMobile"
            variant="outlined"
            label="Phone"
            placeholder="Phone"
            value={values.Contacts?.[0]?.PhoneMobile}
            onBlur={handleBlur}
            onChange={handleChange}
            helperText={getIn(touched, 'Contacts.0.PhoneMobile') && getIn(errors, 'Contacts.0.PhoneMobile')}
            error={getIn(touched, 'Contacts.0.PhoneMobile') && Boolean(getIn(errors, 'Contacts.0.PhoneMobile'))}
            onClearClick={() => setFieldValue('Contacts.0.PhoneMobile', '')}
          />
          <STextField
            v2
            fullWidth
            id="Phone1"
            name="Phone1"
            variant="outlined"
            label="Mobile"
            placeholder="Mobile"
            value={values.Phone1}
            onBlur={handleBlur}
            onChange={handleChange}
            helperText={touched?.Phone1 && errors?.Phone1}
            error={touched?.Phone1 && Boolean(errors?.Phone1)}
            onClearClick={() => setFieldValue('Phone1', '')}
          />
          <STextField
            v2
            fullWidth
            id="ABN"
            name="ABN"
            variant="outlined"
            label="ABN"
            placeholder="ABN"
            value={values.ABN}
            onBlur={handleBlur}
            onChange={handleChange}
            helperText={touched?.ABN && errors?.ABN}
            error={touched?.ABN && Boolean(errors?.ABN)}
            className="col-span-2"
            onClearClick={() => setFieldValue('ABN', '')}
          />
        </div>
        <div className="col-span-2">
          {!values.Locations?.[0]?.Addresses?.length ? (
            <div
              className={clsx(
                classes.newAddressBox,
                'flex h-full cursor-pointer items-center justify-center text-base font-semibold text-primary',
              )}
              onClick={() => handleAddressDialogOpen()}
              data-autoid="btnAddAddress"
            >
              Add address
            </div>
          ) : null}
          {values?.Locations?.[0]?.Addresses?.length ? (
            <div
              className={clsx(classes.addressBox, 'flex h-full cursor-pointer items-center justify-center')}
              onClick={() => handleAddressDialogOpen(true)}
              data-autoid="btnAddAddress"
            >
              <p className="text-center text-base font-semibold text-primary">
                {values?.Locations?.[0]?.Addresses?.[0]?.StreetAddress} {values?.Locations?.[0]?.Addresses?.[0]?.City}{' '}
                {values?.Locations?.[0]?.Addresses?.[0]?.State}
                {values?.Locations?.[0]?.Addresses?.[0]?.PostCode ? ', ' : ''}
                {values?.Locations?.[0]?.Addresses?.[0]?.Country}
              </p>
            </div>
          ) : null}
          {getIn(touched, 'Locations.0.Addresses') && getIn(errors, 'Locations.0.Addresses') ? (
            <p className="text-base text-spenda-error">Address is required</p>
          ) : null}
        </div>
      </div>
    </>
  );
};
