import React, {SVGProps} from 'react';
const IconKeyboardBackspaceRounded = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width || '20px'}
      height={props.width || '20px'}
      viewBox="0 0 50 50"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.828 29.384L9.89 37.53a2.083 2.083 0 002.959 0 2.083 2.083 0 000-2.958l-7.417-7.458h42.48a2.083 2.083 0 100-4.167H5.306l7.542-7.542a2.083 2.083 0 000-2.875 2.083 2.083 0 00-2.959 0l-8.062 8.021a6.25 6.25 0 000 8.834z"
        className="fill-primary stroke-primary"
      />
    </svg>
  );
};
export default IconKeyboardBackspaceRounded;
