import clsx from 'clsx';
import {cloneDeep} from 'lodash';
import moment from 'moment';
import React, {useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {ClickAwayListener} from '@material-ui/core';
import {Button, Spinner, Typography} from 'spenda-ui-react';
import {WidgetDeleteLogo} from '../../assets/svg/WidgetDeleteLogo';
import {IntegrationContext} from '../../context/IntegrationContext';
import ARContext from '../../context/ar-context/ARContext';
import {usePsblBatchHook, usePSBLHook} from '../../hooks/useARHook';
import {useFeatureFlags} from '../../hooks/useFeatureFlags';
import {ISelectedTransactionsList} from '../../model/accounts-receivable/AccountsReceivable';
import {DatTypes, PSBLBatchAction, PaymentWidgetScope} from '../../model/constants/Constants';
import {PSBLWidget} from '../../screens/purchasing/PSBLWidget';
import {PriceFormat, netPayableAmount} from '../../utils/formatter';
import APTooltip from '../data-display/APTootip';
import {ARDialogTemplate} from './ARDialogTemplate';
import {SplitPaymentButton} from './SplitPaymentButton';
import {WidgetPlaceHolder} from './WigetPlaceHolder';

export const PsblWidget = () => {
  // Feature Flags
  const {T2TPhase177852, scheduledPaymentsV2} = useFeatureFlags().supplierDriven();

  // Context
  const {psblBatch, setPsblBatch} = useContext(ARContext);
  const {financialAdaptor} = useContext(IntegrationContext);

  // Constant
  const {supplierId} = useParams<{supplierId?: string}>();
  const _supplierId = supplierId ? parseInt(supplierId) : 0;
  const {invoicePaymentAllocationID, loading: isPsblBatchLoading, selectedTxList, totalInvoiceAmount} = psblBatch;

  // Custom Hooks
  const {cancelPSBLBatch, updateBatchTransactions} = usePsblBatchHook(_supplierId);
  const {isPaymentOptionAvailable} = usePSBLHook();

  // State
  const [isShowPaymentWidgetModal, setIsShowPaymentWidgetModal] = useState(false);
  const [isShowCancelBatchDialog, setIsShowCancelBatchDialog] = useState(false);
  const [isOpenManualPushWarning, setIsOpenManualPushWarning] = React.useState(false);

  useEffect(() => {
    // Must have T2T feature flag enabled and a financial adaptor and be in the context of a buyer
    if (!financialAdaptor || !T2TPhase177852) {
      setIsOpenManualPushWarning(false);
      return;
    }

    if (isShowPaymentWidgetModal) {
      setIsOpenManualPushWarning(false);
      return;
    }

    // Check if there is at least one transaction
    if (!selectedTxList?.length) {
      setIsOpenManualPushWarning(false);
      return;
    }

    // Check if there is at least one transaction that is not a payment
    const hasPaymentTransactions = selectedTxList.some(st => st.transactionTypeID === DatTypes.Payment);
    if (hasPaymentTransactions) {
      setIsOpenManualPushWarning(false);
      return;
    }

    // Check if amount of credit notes and claims is greater than amount of invoices
    const totals = selectedTxList?.reduce(
      (acc, st) => {
        if (st?.transactionTypeID === DatTypes.Invoice) {
          acc.totalInvoiceAmount = acc.totalInvoiceAmount + (st?.amountToPay ? st?.amountToPay : st?.balance);
        } else if ([DatTypes.CreditNote, DatTypes.Claim].includes(st?.transactionTypeID)) {
          acc.totalCreditAndClaimsSelected = acc.totalCreditAndClaimsSelected + st?.balance;
        }
        return acc;
      },
      {totalInvoiceAmount: 0, totalCreditAndClaimsSelected: 0},
    );
    const previewTotal = netPayableAmount(totals.totalInvoiceAmount, totals.totalCreditAndClaimsSelected);
    setIsOpenManualPushWarning(previewTotal <= 0);

    return () => {
      setIsOpenManualPushWarning(false);
    };
  }, [selectedTxList.length, financialAdaptor, T2TPhase177852, isShowPaymentWidgetModal]);

  // Functions

  const handleClick = () => {
    setPsblBatch({...psblBatch, PSBLBatchStatus: 'INPROGRESS'});
    setIsShowPaymentWidgetModal(true);
  };

  const getSelectedInvoiceTotal = () => {
    const totals = selectedTxList?.reduce(
      (acc, st) => {
        if (st?.transactionTypeID === DatTypes.Invoice) {
          acc.totalInvoiceAmount = acc.totalInvoiceAmount + (st?.amountToPay ? st?.amountToPay : st?.balance);
        } else if ([DatTypes.CreditNote, DatTypes.Claim, DatTypes.Payment].includes(st?.transactionTypeID)) {
          acc.totalCreditAndClaimsSelected = acc.totalCreditAndClaimsSelected + st?.balance;
        }
        return acc;
      },
      {totalInvoiceAmount: 0, totalCreditAndClaimsSelected: 0},
    );
    const previewTotal = netPayableAmount(totals.totalInvoiceAmount, totals.totalCreditAndClaimsSelected);
    return PriceFormat(parseFloat(previewTotal?.toFixed(2)));
  };

  const removeSelectedTransaction = async (transData: ISelectedTransactionsList) => {
    if (scheduledPaymentsV2) {
      const records = [...selectedTxList];
      const removeIndex = records?.findIndex(st => st.id === transData?.id);
      if (removeIndex > -1) {
        records?.splice(removeIndex, 1);
        setPsblBatch({...psblBatch, loading: true, selectedTxList: records});
      }
    } else {
      const payload = [
        {
          action: PSBLBatchAction.REMOVE,
          datTypeID: transData.transactionTypeID,
          id: transData.id,
        },
      ];
      await updateBatchTransactions(payload);
    }
  };

  const handleCancel = async () => {
    try {
      invoicePaymentAllocationID && (await cancelPSBLBatch(invoicePaymentAllocationID));
      setIsShowCancelBatchDialog(false);
    } catch {}
  };

  const handleSplitAmountChange = (amount?: number) => {
    if (selectedTxList?.length === 1 && selectedTxList[0].transactionTypeID === DatTypes.Invoice) {
      const _selectedTxList = cloneDeep(selectedTxList);
      _selectedTxList[0].amountToPay = amount;
      setPsblBatch({
        ...psblBatch,
        selectedTxList: _selectedTxList,
      });
    }
  };

  const handleClickAway = () => {
    setIsOpenManualPushWarning(false);
  };

  return (
    <div className="relative h-[inherit] w-[inherit]">
      <div className="relative flex h-full w-full flex-col pb-16">
        <div className="buttonSet flex w-full flex-row items-center">
          {!!selectedTxList?.length && (
            <div className="w-full bg-[white] px-3 pb-3 pt-4 text-left font-poppins text-xl text-spenda-primarytext">
              {'Process batch'}
            </div>
          )}
        </div>
        {!!selectedTxList?.length ? (
          <>
            <div className="processBatchBody flex h-full w-full flex-col justify-between">
              <div className="mt-2.5 flex h-full w-full flex-col items-center">
                <div className="processBatchHeading mb-2 flex w-full items-center justify-between border-[#F1F1F1] border-b-default px-3 pb-4 pt-1">
                  <Typography variant="paragraph" data-autoid={`lblPayInvoice`} className="font-medium  text-[#707070]">
                    {selectedTxList?.length > 1
                      ? `Pay invoices(${selectedTxList?.length})`
                      : `Pay invoice(${selectedTxList?.length})`}
                  </Typography>
                  <div className="flex flex-row items-center">
                    <Typography
                      variant="paragraph"
                      data-autoid={`lblSelectedInvoiceTotal`}
                      className="font-semibold text-[#3C9F78]"
                    >
                      {isPsblBatchLoading ? <Spinner className="h-3 w-3 text-primary/30" /> : getSelectedInvoiceTotal()}
                    </Typography>
                  </div>
                </div>

                <div className="mb-[160px] flex w-full flex-col overflow-auto">
                  {selectedTxList?.map(st => {
                    const isCreditNoteOrPayment = [DatTypes.CreditNote, DatTypes.Payment].includes(
                      st.transactionTypeID,
                    );
                    const isInvoice = st?.transactionTypeID === DatTypes.Invoice;

                    return (
                      <div
                        key={`transactionPreview${st.id}`}
                        className="processBatchData flex w-full flex-row items-center justify-between border-[#F1F1F1] border-b-default px-2.5 py-4"
                      >
                        <div className="flex flex-col">
                          <Typography
                            variant="paragraph"
                            data-autoid={`lblAccountCustomerName${st?.id}`}
                            className="mb-[2px] font-medium text-black-800"
                          >
                            {st?.accountCustomerName}
                          </Typography>
                          <Typography
                            variant="xsmall"
                            data-autoid={`lblTransactionType&RefNumber${st?.id}`}
                            className=" mb-[2px] text-black-800"
                          >
                            {st?.transactionType}: {st?.refNumber}
                          </Typography>
                          <Typography variant="xsmall" data-autoid={`lblTotalInc${st?.id}`} className=" text-black-800">
                            {`Total :  ${
                              isCreditNoteOrPayment ? `(${PriceFormat(st?.totalInc)})` : PriceFormat(st?.totalInc)
                            }`}
                          </Typography>
                        </div>
                        <div className="flex flex-row items-center">
                          <div className="mr-5 flex flex-col items-center">
                            <Typography
                              variant="paragraph"
                              data-autoid={`lblBalance${st?.id}`}
                              className="mb-[4px] mr-2 flex self-end font-semibold text-[#444343]"
                            >
                              {isCreditNoteOrPayment
                                ? `(${PriceFormat(st?.balance)})`
                                : PriceFormat(st?.amountToPay || st?.balance)}
                            </Typography>
                            <div
                              data-autoid={`lblDate${st?.id}`}
                              className={clsx(
                                `whitespace-nowrap rounded-[4px] px-[16px] py-[4px] font-poppins text-xs font-medium`,
                                {
                                  'bg-[#D3E5EF50] text-primary': isCreditNoteOrPayment,
                                },
                                {
                                  'bg-[#F8E1DC] text-[#C55D44]': isInvoice,
                                },
                              )}
                            >
                              {isCreditNoteOrPayment &&
                                `Received on - ${st?.transDate ? moment(st?.transDate).format('Do MMM YYYY') : ''}`}
                              {isInvoice && `Due on - ${st.dueDate ? moment(st.dueDate).format('Do MMM YYYY') : ''}`}
                            </div>
                          </div>
                          <Typography
                            variant="xsmall"
                            className="inline"
                            data-autoid={`btnWidgetDelete`}
                            onClick={() => removeSelectedTransaction(st)}
                          >
                            <WidgetDeleteLogo className="cursor-pointer" />
                          </Typography>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="processBatchFooter h-15 absolute bottom-0 left-2.5 mb-[70px] flex w-[394px] flex-row items-center justify-between rounded-[8px] bg-[#f0f0f0] p-2.5">
                <Button
                  data-autoid={`btnCancel`}
                  className="bg-white"
                  onClick={() => setIsShowCancelBatchDialog(true)}
                  variant="outlined"
                  color="primary"
                >
                  Cancel
                </Button>
                {isPaymentOptionAvailable && (
                  <div className="flex flex-row items-center gap-3">
                    <SplitPaymentButton
                      actualInvoiceBalance={selectedTxList?.length === 1 ? selectedTxList[0]?.balance : 0}
                      disabled={
                        !(selectedTxList?.length === 1 && selectedTxList[0].transactionTypeID === DatTypes.Invoice)
                      }
                      title={'Partial payments can only be made for a single invoice.'}
                      onAmountChanged={handleSplitAmountChange}
                      disableHoverListener={
                        selectedTxList?.length === 1 && selectedTxList[0].transactionTypeID === DatTypes.Invoice
                      }
                      amountToPay={selectedTxList?.[0]?.amountToPay}
                    />

                    <ClickAwayListener onClickAway={handleClickAway}>
                      <APTooltip
                        title={`Batches with a $0 balance cannot be posted to ${financialAdaptor?.Name} so will need to be entered manually. To ensure the batch posts automatically, remove some credit notes or add more invoices to the batch.`}
                        open={isOpenManualPushWarning}
                        placement="left"
                        arrow
                        interactive
                      >
                        <div>
                          <Button
                            disabled={!totalInvoiceAmount}
                            variant="filled"
                            color="primary"
                            data-autoid={`btnPayInvoice`}
                            onClick={handleClick}
                          >
                            Pay Invoice
                          </Button>
                        </div>
                      </APTooltip>
                    </ClickAwayListener>
                  </div>
                )}
              </div>
            </div>
          </>
        ) : (
          <WidgetPlaceHolder placeholder="Selected invoices will appear here" />
        )}
      </div>

      {/* AR Preview Selected Transaction Widget Modals */}
      {isShowPaymentWidgetModal && (
        <div
          className={clsx(
            'absolute  left-[0px] top-[0] rounded-[2px] bg-[#ADADAD20] pb-[68px] backdrop-blur',
            'z-10 flex h-[inherit] w-full flex-col items-center justify-center pt-2 drop-shadow',
          )}
        >
          <div className={`flex h-[inherit] max-h-[618px] w-auto flex-col rounded-[10px] bg-white p-2.5`}>
            <PSBLWidget
              onSuccess={() => setPsblBatch({...psblBatch, PSBLBatchStatus: 'SUCCESS'})}
              onClose={() => setIsShowPaymentWidgetModal(false)}
              widgetScope={PaymentWidgetScope.PSBL}
            />
          </div>
        </div>
      )}

      {isShowCancelBatchDialog && (
        <ARDialogTemplate
          containerClass="px-2.5"
          handleClose={() => setIsShowCancelBatchDialog(false)}
          header={<p className=" font-poppins text-xl font-light text-[#333333]">Confirm</p>}
          body={
            <div className="my-12">
              <Typography variant="paragraph" className="text-center">
                {`There is a batch in progress. Are you sure you want to discard the batch?`}
              </Typography>
            </div>
          }
          footer={
            <div className="flex w-full flex-row items-center justify-between">
              <Button
                variant="outlined"
                data-autoid="btnCancel"
                className="bg-[#ffff]"
                onClick={() => setIsShowCancelBatchDialog(false)}
              >
                Cancel
              </Button>
              <Button data-autoid="btnDiscardBatch" onClick={handleCancel} variant="filled" color="error">
                Discard
              </Button>
            </div>
          }
        />
      )}
    </div>
  );
};
