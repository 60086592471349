import React, {FunctionComponent, ChangeEvent} from 'react';
import {IRequisitionLine} from '../../model/requisition/Requisition';
import {css} from 'glamor';
import {TransactionLineItem} from './TransactionLineItem';

interface TransactionLineItemsProps {
  lines: IRequisitionLine[]; // TODO: This should be a generic transaction ITrans
  unavailableLines: IRequisitionLine[] | undefined;
  onChangeQuantity: (e: ChangeEvent<HTMLInputElement>, line: IRequisitionLine) => void;
  onRemoveLine: (line: IRequisitionLine, isAvailable?: boolean) => void;
  isCalculateLineTotal: boolean;
}

export const TransactionLineItems: FunctionComponent<TransactionLineItemsProps> = ({
  lines,
  unavailableLines,
  onChangeQuantity,
  onRemoveLine,
  isCalculateLineTotal,
}) => {
  // const tableBody = css({
  //     display: "block",
  //     width: "100%"
  // })

  const labelStyles = css({
    color: '#006c91',
    fontSize: '0.85rem',
    fontWeight: '400 !important',
  });

  const qty = css({
    '@media(min-width:1280px)': {
      width: '11%',
    },
  });

  const listTableStyles = css({
    maxHeight: '74%',
    overflowY: 'auto',
    '@media(min-width:1280px)': {
      maxHeight: '80%',
      overflowY: 'auto',
    },
  });

  return (
    <div className={`${listTableStyles}`}>
      <table className={`w-full table-fixed p-3 mt-4 mb-4 md:text-base`}>
        <thead className={`uppercase`}>
          <tr>
            <th className={`${labelStyles} py-2 text-left`}>ITEM</th>
            <th className={`${labelStyles} py-2 text-left sm:w-2/5 xl:w-1/2`}>DESCRIPTION</th>
            <th className={`${labelStyles} py-2 text-center`}>UNIT PRICE</th>
            <th className={`${labelStyles} ${qty} py-2`}>QTY</th>
            <th className={`${labelStyles} py-2 text-right`}>TOTAL (EX)</th>
            <th className="sm:w-8"></th>
          </tr>
        </thead>
        <tbody>
          {unavailableLines &&
            unavailableLines.map((line: IRequisitionLine, index: number) => (
              <TransactionLineItem
                key={index}
                line={line}
                onChangeQuantity={onChangeQuantity}
                onRemoveLine={onRemoveLine}
                isCalculateLineTotal={isCalculateLineTotal}
                isAvailable={false}
              />
            ))}
          {lines &&
            lines.map((line: IRequisitionLine, index: number) => (
              <TransactionLineItem
                key={index}
                line={line}
                onChangeQuantity={onChangeQuantity}
                onRemoveLine={onRemoveLine}
                isCalculateLineTotal={isCalculateLineTotal}
              />
            ))}
        </tbody>
      </table>
    </div>
  );
};
