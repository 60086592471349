import React from 'react';
//Icon
const IconFilter = (props: {width?: string; height?: string; fill?: string}) => {
  return (
    <svg
      width={props.width || '20px'}
      height={props.height || '22px'}
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>Filter</title>
      <g id="Icons/General/filter" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <path
          d="M54.1566831,100 C53.2551405,100 52.3779171,99.7075922 51.6566831,99.1666667 L34.9900164,86.6666667 C33.9408254,85.8797734 33.3233497,84.6448221 33.3233497,83.3333333 L33.3233497,59.9166667 L4.09001641,27.0291667 C-0.150811094,22.2449784 -1.19232788,15.418717 1.42886286,9.58755639 C4.0500536,3.75639579 9.84681232,0.00403774977 16.2400164,0 L75.4066831,0 C81.7993009,0.0074985409 87.5936124,3.76231835 90.2119843,9.59410597 C92.8303563,15.4258936 91.786266,22.2510377 87.5441831,27.0333333 L58.3233497,59.9166667 L58.3233497,95.8333333 C58.3233497,98.1345198 56.4578695,100 54.1566831,100 L54.1566831,100 Z M41.6566831,81.25 L49.9900164,87.5 L49.9900164,58.3333333 C49.9908575,57.3131567 50.3659445,56.3287386 51.0441831,55.5666667 L81.3275164,21.4958333 C83.3900876,19.1645965 83.8951459,15.8410383 82.6182172,13.0023206 C81.3412884,10.1636029 78.5193756,8.33660258 75.4066831,8.33333333 L16.2400164,8.33333333 C13.1289987,8.33804684 10.3091253,10.1644199 9.03254681,13.0014607 C7.75596829,15.8385015 8.25935597,19.1602368 10.3191831,21.4916667 L40.6066831,55.5666667 C41.283411,56.3293507 41.6569902,57.3137023 41.6566831,58.3333333 L41.6566831,81.25 Z"
          id="Shape"
          fill={props.fill ?? 'text-primary'}
          className="fill-primary"
        />
      </g>
    </svg>
  );
};

export default IconFilter;
