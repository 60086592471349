import * as React from 'react';
const APOnbFinancialAdaptorIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fill="hsl(var(--primary))"
      fillRule="evenodd"
      d="M8 6.4c.424 0 .769.332.769.743v.199l.02.006c.928.313 1.578 1.143 1.61 2.104l.001.074c0 .411-.344.743-.768.743a.758.758 0 0 1-.767-.696l-.002-.047c0-.457-.387-.83-.863-.83-.475 0-.862.373-.862.83 0 .441.36.804.812.83H8c1.324 0 2.4 1.04 2.4 2.318 0 .992-.659 1.857-1.612 2.178l-.02.006.001.199a.754.754 0 0 1-.72.742H8a.756.756 0 0 1-.768-.742v-.2l-.02-.005c-.928-.313-1.578-1.143-1.61-2.104l-.002-.074c0-.411.345-.743.769-.743.373 0 .69.258.758.624l.01.119c0 .457.388.83.863.83.476 0 .863-.373.863-.83 0-.442-.36-.804-.812-.83l-.05-.001c-1.324 0-2.4-1.039-2.4-2.317 0-.992.659-1.857 1.612-2.178l.019-.006v-.2c0-.394.318-.716.72-.74L8 6.4Zm6.18-3.798L12.8 1.22A4.14 4.14 0 0 0 9.853 0H4.567A4.172 4.172 0 0 0 .4 4.167v11.666A4.172 4.172 0 0 0 4.567 20h6.667a4.172 4.172 0 0 0 4.166-4.167V5.548a4.14 4.14 0 0 0-1.22-2.946ZM13 3.78c.118.118.224.247.316.387h-2.083V2.083c.139.093.269.2.387.317l1.38 1.38Zm.733 12.053a2.5 2.5 0 0 1-2.5 2.5H4.567a2.5 2.5 0 0 1-2.5-2.5V4.167a2.5 2.5 0 0 1 2.5-2.5h5v2.5c0 .92.746 1.666 1.667 1.666h2.5v10Z"
      clipRule="evenodd"
    />
  </svg>
);
export default APOnbFinancialAdaptorIcon;
