import {withStyles, Tooltip} from '@material-ui/core';

const APTooltip = withStyles({
  tooltip: {
    padding: '10px',
    boxSizing: 'border-box',
    background: '#fff',
    border: '1px solid #fafafa',
    zIndex: 999999,
    top: '-5px',
    boxShadow: '0px 0px 4px 0 rgb(0 0 0 / 20%)',
    borderRadius: '3px',
    color: '#333',
    fontFamily: 'poppins',
    '& .MuiTooltip-arrow': {
      color: '#fff',
      '&::before': {
        boxShadow: '-2px -3px 4px 0px rgb(0 0 0 / 12%)',
      },
    },
    '& p': {
      fontSize: '10px',
    },
    '& li': {
      '& p': {
        '&:first-child': {
          color: '#707070',
        },
      },
    },
    '& table': {
      fontSize: '10px',
      '& th': {
        color: '#BFBFBF',
      },
    },
  },
})(Tooltip);

export default APTooltip;
