import useHttp from '../hooks/useHttp';
import { IActionResults, IAlertsActionResult, IPagedActionResults } from '../model/ActionResults';
import { IAlertInfo, IAlertRequestRequest } from '../model/alert/AlertInfo';
import { IConnectedCustomer } from '../model/customer/ConnectedCustomer';
import { ICustomersSummaries, IPaidInvoicesResponse } from '../model/customer/CustomerStatements';
import { IInvoice } from '../model/invoice/Invoice';
import { IPaymentT, IRefBusTrans, MerchantSurcharge } from '../model/payment/PaymentT';
import { ISearchCustomerStatementsFilter } from '../model/search-filters/SearchCustomerStatementsFilter';
import { SynkSaveQueueResponse } from '../model/SynkSaveQueueResponse';
import { ARCustomerStatementOperation, ARStatementDeltasActions } from './../model/constants/Constants';
import {
  IAgedTransactionResponse,
  ICreateStatementResponse,
  ICustomerPaymentHistoryResponse,
  ICustomerStatementsResponse,
  IGetAllCustomersResponse,
  IGetStatementDetail,
  ISupplierPaymentsResponse,
  lCreatedStatementResponse,
} from './../model/customer/CustomerStatements';
import { IBuyerPaidInvoicesQuery } from './useSupplierTransactionsAPI';

export interface IGetCustomerTransactionRequest {
  TransID: number;
  TransactionGuid: string;
  merchantCustomerID?: number;
}

export interface ICreateStatementDeltas {
  datTypeID: string;
  id: number;
  action: ARStatementDeltasActions;
}

export interface ICreateStatementPayload {
  statementID: number | null;
  accountCustomerID: number;
  statementDeltas?: ICreateStatementDeltas[];
  name?: string;
  endDate_string?: string;
  operation: ARCustomerStatementOperation;
  finaliseStatement?: boolean;
}

export interface IStatementFilterQuery {
  asAtDate?: string;
  startDate?: string;
  endDate?: string;
  searchString?: string;
  searchField?: string;
  showSelectedOnly?: boolean;
  hideSelected?: boolean;
  showPaymentsCreditsOnly?: boolean;
  showInvoicesOnly?: boolean;
}

export interface ICustomerStatementFilterQuery extends IStatementFilterQuery {
  editingStatementID?: number;
  accountCustomerID?: number;
}

export interface ISupplierPaidInvoicesQuery extends IBuyerPaidInvoicesQuery {
  accountCustomerID?: number | null;
}

export const useCustomerStatementsAPI = () => {
  const { GET, POST, PUT, isLoading } = useHttp();

  const search = (
    filter: Partial<ISearchCustomerStatementsFilter>,
  ): Promise<IPagedActionResults<ICustomersSummaries>> => {
    return GET(`customerStatements`, filter);
  };

  const getCustomer = (id: number): Promise<IConnectedCustomer> => {
    return GET(`customers/${id}`).then((data: IActionResults<IConnectedCustomer>) => data.Value);
  };

  const getCreditNote = (filter: Partial<IGetCustomerTransactionRequest>): Promise<IInvoice> => {
    return GET(`customerStatements/creditnote`, filter).then((data: IInvoice) => data);
  };

  const getPayment = (filter: Partial<IGetCustomerTransactionRequest>): Promise<IInvoice> => {
    return GET(`customerStatements/payment`, filter).then((data: IInvoice) => data);
  };

  const payInvoice = (req: IPaymentT): Promise<SynkSaveQueueResponse<IPaymentT>> => {
    return POST('customerStatements/invoice/payment', req);
  };

  const getInvoicePrintOut = (filter: Partial<IAlertRequestRequest>): Promise<IAlertInfo> => {
    return GET(`customerStatements/invoice/alert`, filter).then((data: IActionResults<IAlertInfo>) => data.Value);
  };

  const getCreditNotePrintOut = (filter: Partial<IAlertRequestRequest>): Promise<IAlertInfo> => {
    return GET(`customerStatements/creditnote/alert`, filter).then((data: IActionResults<IAlertInfo>) => data.Value);
  };

  const getPaymentAlert = (filter: Partial<IAlertRequestRequest>): Promise<IAlertInfo> => {
    return GET(`customerStatements/payment/alert`, filter).then((data: IActionResults<IAlertInfo>) => data.Value);
  };

  const getBatchPaymentHstoryPrintOut = (req: Partial<IAlertRequestRequest>): Promise<string[]> => {
    return PUT('Spenda/Alerts/GetDocument', req).then((data: IAlertsActionResult) => data.value);
  };

  const getMerchantSurcharge = (
    customerId: number | string,
    paymentMethod: string,
    refBusTrans: IRefBusTrans[],
  ): Promise<MerchantSurcharge> => {
    return POST(`connectedCustomer/${customerId}/merchantSurcharge/${paymentMethod}`, refBusTrans).then(
      (data: IActionResults<MerchantSurcharge>) => data.Value,
    );
  };

  // --------New Endpoints--------

  const getInvoiceByGuid = (query: { merchantCustomerID?: number, transId?: number, transactionGuid?: string }): Promise<IInvoice> => {
    return GET(`CustomerStatements/invoice`, query);
  };

  const getAllCustomers = (query?: { asAtDate?: string; searchString?: string }): Promise<IGetAllCustomersResponse> => {
    return GET(`Spenda/Payments/AccountsReceivable/Supplier/OutStandingStatementSummary`, query);
  };

  const getCustomerAllTransactions = (query?: ICustomerStatementFilterQuery): Promise<ICustomerStatementsResponse> => {
    return GET(`Spenda/Payments/AccountsReceivable/Supplier/CustomerOutstandingStatement`, query);
  };

  const getCustomerAllStatements = (accountCustomerID: number | null): Promise<lCreatedStatementResponse[]> => {
    return GET(`Spenda/Payments/AccountsReceivable/Supplier/Statement/${accountCustomerID}`);
  };

  const createStatement = (payload: ICreateStatementPayload): Promise<ICreateStatementResponse> => {
    return POST(`Spenda/Payments/AccountsReceivable/Supplier/Statement`, payload);
  };

  const getStatementDetail = (accountCustomerID: number, statementID: number): Promise<IGetStatementDetail> => {
    return GET(`Spenda/Payments/AccountsReceivable/Supplier/Statement/${accountCustomerID}/${statementID}`);
  };

  const getCustomerPaymentHistory = (query?: {
    accountCustomerID?: string;
    SearchString?: string;
    searchField?: string;
  }): Promise<ICustomerPaymentHistoryResponse[]> => {
    return GET(`Spenda/Payments/AccountsReceivable/Supplier/PaymentHistory`, query);
  };

  const getAgedTransactions = (
    timelineGroup: number,
    query?: { accountCustomerID?: number; asAtDate?: string; editingStatementID?: number; searchString?: string },
  ): Promise<IAgedTransactionResponse> => {
    return GET(`Spenda/Payments/AccountsReceivable/Supplier/AgedCustomerOutstandingStatement/${timelineGroup}`, query);
  };

  const getSupplierPayments = (query?: {
    accountCustomerID?: string;
    SearchString?: string;
    searchField?: string;
  }): Promise<ISupplierPaymentsResponse> => {
    return GET(`Spenda/Payments/AccountsReceivable/Supplier/PaymentHistoryV2`, query);
  };

  const getSupplierPaidInvoices = (query?: ISupplierPaidInvoicesQuery): Promise<IPaidInvoicesResponse[]> => {
    return GET(`Spenda/Payments/AccountsReceivable/Supplier/PaidInvoices`, query);
  };

  return {
    search,
    getCustomer,
    getCreditNote,
    getPayment,
    payInvoice,
    getInvoicePrintOut,
    getCreditNotePrintOut,
    getPaymentAlert,
    getMerchantSurcharge,
    getBatchPaymentHstoryPrintOut,
    getAllCustomers,
    getCustomerAllTransactions,
    getCustomerAllStatements,
    createStatement,
    getStatementDetail,
    getCustomerPaymentHistory,
    getInvoiceByGuid,
    getAgedTransactions,
    getSupplierPayments,
    getSupplierPaidInvoices,
    isLoading,
  };
};
