import * as React from 'react';

export const PlusButton = (props: SVGProps) => {
  return (
    <svg width={41} height={40} viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect x={0.400391} width={38.2263} height={40} rx={7} className="fill-white" />
      <rect x={0.900391} y={0.5} width={39} height={39} rx={5.5} className="stroke-primary" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.984 19.083h-3.667v-3.666a.917.917 0 10-1.833 0v3.666h-3.667a.917.917 0 100 1.834h3.667v3.666a.917.917 0 101.833 0v-3.666h3.667a.917.917 0 100-1.834z"
        fill="#1C78AD"
      />
    </svg>
  );
};
