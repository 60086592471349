import React from 'react';
import {Typography} from 'spenda-ui-react';
import {AlertDialogSlideV2} from './AlertDialogSlideV2';

interface IQuoteCustomerDialog {
  handleGoBack: () => void;
  handleContinue: () => void;
  totalVariants?: number;
  unchangedVariantsLength?: number;
}

export const QuoteCustomerDialog = (props: IQuoteCustomerDialog) => {
  const {handleGoBack, handleContinue, totalVariants, unchangedVariantsLength} = props;
  
  return (
    <>
      <AlertDialogSlideV2
        data-autoid="dlgUneditedWarningDialog"
        title={'Unedited customer quote'}
        headingTextSize="h2"
        headingClassess="justify-center"
        dialogActionsAlignment="justify-between"
        dialogClassess="!min-w-[20%] !max-w-[555px] !w-full"
        dialogBodyClassess="flex justify-center items-center"
        actions={[
          {
            label: 'Go Back',
            action: handleGoBack,
            color: 'primary',
            variant: 'outlined',
          },
          {
            label: 'Continue',
            action: handleContinue,
            color: 'primary',
          },
        ]}
      >
        <Typography
          variant="small"
          className="py-10 flex items-center justify-center text-black-800 font-normal text-center max-w-[258px] w-full"
        >
          Are you sure you would like to continue with only editing {unchangedVariantsLength} {(unchangedVariantsLength ?? 0)  <= 1 ? 'quote' : 'quotes'} out of {totalVariants} {(totalVariants ?? 0)  <= 1 ? 'quote' : 'quotes'}
        </Typography>
      </AlertDialogSlideV2>
    </>
  );
};
