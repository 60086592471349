import React from 'react';
const IconChatClaims = (props: {width?: string; height?: string; className?: string; color?: string}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4373 0.619885L4.30927 8.74788C3.68222 9.37155 3.33077 10.2202 3.33327 11.1046V11.9999C3.33327 12.3681 3.63174 12.6666 3.99993 12.6666H4.89527C5.77965 12.669 6.62827 12.3176 7.25193 11.6906L15.3799 3.56255C16.1911 2.74937 16.1911 1.43306 15.3799 0.619885C14.5568 -0.166915 13.2604 -0.166915 12.4373 0.619885ZM14.4372 2.61989L6.3092 10.7479C5.93328 11.1215 5.4252 11.3318 4.8952 11.3332H4.66653V11.1046C4.66792 10.5745 4.87824 10.0665 5.25186 9.69055L13.3799 1.56255C13.6757 1.27997 14.1414 1.27997 14.4372 1.56255C14.7286 1.85476 14.7286 2.32768 14.4372 2.61989Z"
        fill={`${props?.color || '#CCCCCC'}`}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3333 5.986C14.9651 5.986 14.6667 6.28448 14.6667 6.65267V10H12C10.8954 10 10 10.8954 10 12V14.6667H3.33333C2.22876 14.6667 1.33333 13.7712 1.33333 12.6667V3.33333C1.33333 2.22876 2.22876 1.33333 3.33333 1.33333H9.36133C9.72952 1.33333 10.028 1.03486 10.028 0.666667C10.028 0.298477 9.72952 0 9.36133 0H3.33333C1.4933 0.00220408 0.00220408 1.4933 0 3.33333V12.6667C0.00220408 14.5067 1.4933 15.9978 3.33333 16H10.8953C11.7799 16.0025 12.6288 15.6511 13.2527 15.024L15.0233 13.252C15.6505 12.6284 16.0022 11.7798 16 10.8953V6.65267C16 6.28448 15.7015 5.986 15.3333 5.986ZM12.31 14.0813C12.042 14.3487 11.7031 14.5337 11.3333 14.6147V12C11.3333 11.6318 11.6318 11.3333 12 11.3333H14.6167C14.5342 11.7023 14.3493 12.0406 14.0833 12.3093L12.31 14.0813Z"
        fill={`${props?.color || '#CCCCCC'}`}
      />
    </svg>
  );
};

export default IconChatClaims;
