import React, { FunctionComponent, useEffect, useState } from "react";
import { AlertDialogSlide } from "./AlertDialogSlide";
import { TextField } from "@material-ui/core";
import { css } from "glamor";
import { IRequisitionTemplate } from "../../model/template/IRequisitionTemplate";
import useTemplateAPI from "../../services/useTemplateAPI";
import LoadingIndicator from "../ui/LoadingIndicator";
import WarningIcon from '@material-ui/icons/Warning';

interface IUpdateTemplateDialogProps {
    templateID?: number, 
    onUpdateTemplate: (template: Partial<IRequisitionTemplate>) => void,
    onCloseDialog: () => void,
}

enum TemplateActionType {
    CREATE,
    UPDATE
}

export const UpdateTemplateDialog : FunctionComponent<IUpdateTemplateDialogProps> = ({ templateID, onUpdateTemplate, onCloseDialog }) => {
    
    const selectedBgColor = css({ backgroundColor: "#d8ecf2", borderColor: "#2896bd" })
    const selectedBorderColor = css({ 
        cursor: "pointer"
    })

    const disabledLabel = css({
        borderColor: "lightgray",
        background: "lightgray",
        opacity: 0.5,
        cursor: "not-allowed",
    })

    const templateAPI = useTemplateAPI();

    const [template, setTemplate] = useState<Partial<IRequisitionTemplate> | undefined>(undefined);
    const [templateAction, setTemplateAction] = useState<TemplateActionType>(TemplateActionType.UPDATE);
    const [isLoading, setIsLoading] = useState(true);
    const [originalTemplateName, setOriginalTemplateName] = useState("")
    const [newTemplateName, setNewTemplateName] = useState("")

    const onTemplateActionChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const action: TemplateActionType = e.target.value === "Update" ? TemplateActionType.UPDATE : TemplateActionType.CREATE; 
        setTemplateAction(action);
    }

    const handleChange = (event: React.FormEvent) => {
        const target = event.currentTarget as HTMLInputElement;

        if (templateAction === TemplateActionType.UPDATE) {
            setTemplate({
                ...template,
                [target.name]: target.value
            });
        } else {
            setNewTemplateName(target.value);
        }

    }

    const onClickUpdateOrCreateTemplate = () => {

        if (isTemplateNameRequired()) { return; }

        if (templateAction === TemplateActionType.UPDATE) {
            return template && onUpdateTemplate(template)
        } else {
            const newTemplate: Partial<IRequisitionTemplate> = { Name: newTemplateName, IsSaveAsNew: true }
            return onUpdateTemplate(newTemplate);
        }
    }

    useEffect(() => {
        if (templateID) {
            templateAPI.get(templateID).then((res: IRequisitionTemplate) => {

                if (res) {
                    setTemplate(res);
                    setOriginalTemplateName(res.Name);
                } else {
                    setTemplateAction(TemplateActionType.CREATE)
                }
                setIsLoading(false)
            })
        }
    }, [templateID])

    const isTemplateNameRequired = (): boolean => (templateAction === TemplateActionType.CREATE && !newTemplateName) || (templateAction === TemplateActionType.UPDATE && (!template || !template.Name))
    
    return isLoading ? <LoadingIndicator isLoading /> : <AlertDialogSlide title={`${templateAction === TemplateActionType.UPDATE ? 'Update' : 'Create'} Template`} 
        actions={[{ label: `${templateAction === TemplateActionType.UPDATE ? 'Update': 'Create'}`, action: onClickUpdateOrCreateTemplate },
                { label: "Cancel", variant: "outlined", action: onCloseDialog }]}>

        {(!template || !template.ID) && <p><WarningIcon /> Template no longer available, please create a new one</p>}
        
        <div className="mt-4 mb-8 flex justify-center items-center">
            <label htmlFor="update-template" className={`p-2 flex justify-center items-center w-1/2 h-24 bg-blue-100 border rounded bg-border-300 text-center ${templateAction === TemplateActionType.UPDATE ? selectedBgColor : ''} ${(!template || !template.ID) ? disabledLabel : selectedBorderColor}`}>Update existing "{originalTemplateName}" template
                <input className="hidden" type="radio" id="update-template" name="template-action" value="Update" checked={templateAction === TemplateActionType.UPDATE} onChange={onTemplateActionChanged} disabled={!template || !template.ID}></input>
            </label>
            <span className="mx-4 text-3xl">or</span>
            <label htmlFor="create-new-template" className={`p-2 flex justify-center items-center w-1/2 h-24 bg-blue-100 border rounded bg-border-300 text-center ${templateAction === TemplateActionType.CREATE ? selectedBgColor : ''} ${selectedBorderColor}`}>Create new template
                <input className="hidden" type="radio" id="create-new-template" name="template-action" value="Create" checked={templateAction === TemplateActionType.CREATE} onChange={onTemplateActionChanged}></input>
            </label>
        </div>
        
        <TextField
            id="template-name"
            name="Name"
            helperText={isTemplateNameRequired() && "Template Name is required"}
            error={isTemplateNameRequired()}
            label="TEMPLATE NAME"
            value={template && templateAction === TemplateActionType.UPDATE ? template.Name : newTemplateName}
            onChange={handleChange}
            fullWidth
            />
        <div className="mb-8"></div>
    </AlertDialogSlide>
}