import React from 'react';
//Icon
export const IconCashFlow = (props: {width?: string | undefined; height?: string | undefined}) => {
  return (
    <svg
      width={props.width || '50px'}
      height={props.height || '50px'}
      viewBox="0 0 50 50"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Sell 11</title>
      <g id="Order-Listing" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="Spenda-Collect-Supplier-Interface-Copy"
          transform="translate(-1178.000000, -689.000000)"
          fillRule="nonzero"
        >
          <g id="Group" transform="translate(1178.000000, 689.000000)">
            <g id="stock-market-2" transform="translate(0.200000, 0.200000)">
              <g id="Group" transform="translate(0.800000, 0.800000)">
                <polygon
                  id="Path"
                  fill="#74B9D3"
                  points="19.2 10.4 19.2 15.2 17.6 15.2 16 15.2 16 10.4 17.6 10.4"
                ></polygon>
                <polygon
                  id="Path"
                  fill="#74B9D3"
                  points="12.8 14.4 12.8 20.8 11.2 20.8 9.6 20.8 9.6 14.4 11.2 14.4"
                ></polygon>
                <polygon
                  id="Path"
                  fill="#74B9D3"
                  points="6.4 16.8 6.4 24.8 4.8 24.8 3.2 24.8 3.2 16.8 4.8 16.8"
                ></polygon>
                <path
                  d="M40,21.936 C39.528,22.08 39.032,22.184 38.536,22.264 L32.8,28 L25.6,28 L20.8,34.4 L11.2,34.4 L0,47.2 L0,48 L44.8,48 L44.8,19.04 C43.488,20.376 41.848,21.384 40,21.936 Z"
                  id="Path"
                  fill="#7FC0A6"
                ></path>
                <path
                  d="M36.8,0 C42.984,0 48,5.016 48,11.2 C48,14.248 46.776,17.016 44.8,19.04 C43.488,20.376 41.848,21.384 40,21.936 C39.528,22.08 39.032,22.184 38.536,22.264 C37.968,22.352 37.392,22.4 36.8,22.4 C30.616,22.4 25.6,17.384 25.6,11.2 C25.6,5.016 30.616,0 36.8,0 Z"
                  id="Path"
                  fill="#D2A656"
                ></path>
                <circle id="Oval" fill="#FEC359" cx="36.8" cy="11.2" r="8"></circle>
              </g>
              <path
                d="M38.4,17.6 L38.4,16 C39.7232,16 40.8,14.9232 40.8,13.6 C40.8,12.2768 39.7232,11.2 38.4,11.2 L36.8,11.2 C36.3584,11.2 36,10.8416 36,10.4 C36,9.9584 36.3584,9.6 36.8,9.6 L39.2,9.6 L39.2,10.4 L40.8,10.4 L40.8,8 L38.4,8 L38.4,6.4 L36.8,6.4 L36.8,8 C35.4768,8 34.4,9.0768 34.4,10.4 C34.4,11.7232 35.4768,12.8 36.8,12.8 L38.4,12.8 C38.8416,12.8 39.2,13.1584 39.2,13.6 C39.2,14.0416 38.8416,14.4 38.4,14.4 L36,14.4 L36,13.6 L34.4,13.6 L34.4,16 L36.8,16 L36.8,17.6 L38.4,17.6 Z"
                id="Path"
                fill="#333333"
              ></path>
              <path
                d="M46.4,12 C46.4,7.148 42.452,3.2 37.6,3.2 C32.748,3.2 28.8,7.148 28.8,12 C28.8,16.852 32.748,20.8 37.6,20.8 C42.452,20.8 46.4,16.852 46.4,12 Z M30.4,12 C30.4,8.0296 33.6296,4.8 37.6,4.8 C41.5704,4.8 44.8,8.0296 44.8,12 C44.8,15.9704 41.5704,19.2 37.6,19.2 C33.6296,19.2 30.4,15.9704 30.4,12 Z"
                id="Shape"
                fill="#333333"
              ></path>
              <path
                d="M49.6,12 C49.6,5.3832 44.2168,0 37.6,0 C30.9832,0 25.6,5.3832 25.6,12 C25.6,17.0944 28.796,21.4464 33.2848,23.184 L31.6688,24.8 L25.2368,24.8 L19.6368,31.2 L10.0504,31.2 L0.2128,41.8568 L1.388,42.9424 L10.7504,32.8 L20.364,32.8 L25.964,26.4 L32.332,26.4 L35.0192,23.7128 C35.752,23.8744 36.5096,23.964 37.2856,23.984 L33.2688,28 L26,28 L21.2,34.4 L11.6368,34.4 L0,47.6992 L0,49.6 L46.4,49.6 L46.4,20.1312 C48.3792,17.9904 49.6,15.1384 49.6,12 Z M27.2,12 C27.2,6.2656 31.8656,1.6 37.6,1.6 C43.3344,1.6 48,6.2656 48,12 C48,17.7344 43.3344,22.4 37.6,22.4 C31.8656,22.4 27.2,17.7344 27.2,12 Z M40,23.7584 L40,48 L36.8,48 L36.8,26.7312 L39.732,23.7992 C39.8208,23.7832 39.912,23.776 40,23.7584 Z M33.9312,29.6 L35.2,28.3312 L35.2,48 L32,48 L32,29.6 L33.9312,29.6 Z M11.2,48 L8,48 L8,40.9864 L11.2,37.3296 L11.2,48 Z M12.8,36 L16,36 L16,48 L12.8,48 L12.8,36 Z M17.6,36 L20.8,36 L20.8,48 L17.6,48 L17.6,36 Z M22.4,35.4664 L25.6,31.2 L25.6,48 L22.4,48 L22.4,35.4664 Z M27.2,29.6 L30.4,29.6 L30.4,48 L27.2,48 L27.2,29.6 Z M6.4,42.8152 L6.4,48 L1.8632,48 L6.4,42.8152 Z M41.6,48 L41.6,23.3 C42.7592,22.888 43.8352,22.3048 44.8,21.5776 L44.8,48 L41.6,48 Z"
                id="Shape"
                fill="#333333"
              ></path>
              <path
                d="M4.8,29.6 L6.4,29.6 L6.4,26.4 L8,26.4 L8,16.8 L6.4,16.8 L6.4,13.6 L4.8,13.6 L4.8,16.8 L3.2,16.8 L3.2,26.4 L4.8,26.4 L4.8,29.6 Z M4.8,18.4 L6.4,18.4 L6.4,24.8 L4.8,24.8 L4.8,18.4 Z"
                id="Shape"
                fill="#333333"
              ></path>
              <path
                d="M11.2,25.6 L12.8,25.6 L12.8,22.4 L14.4,22.4 L14.4,14.4 L12.8,14.4 L12.8,11.2 L11.2,11.2 L11.2,14.4 L9.6,14.4 L9.6,22.4 L11.2,22.4 L11.2,25.6 Z M11.2,16 L12.8,16 L12.8,20.8 L11.2,20.8 L11.2,16 Z"
                id="Shape"
                fill="#333333"
              ></path>
              <path
                d="M17.6,20 L19.2,20 L19.2,16.8 L20.8,16.8 L20.8,10.4 L19.2,10.4 L19.2,7.2 L17.6,7.2 L17.6,10.4 L16,10.4 L16,16.8 L17.6,16.8 L17.6,20 Z M17.6,12 L19.2,12 L19.2,15.2 L17.6,15.2 L17.6,12 Z"
                id="Shape"
                fill="#333333"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
