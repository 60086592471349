import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {FormControl, InputLabel, Select, SelectProps, FormHelperText} from '@material-ui/core';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';

export const SSelect = (props: SelectProps) => {
  const defaultStyles = {
    root: {
      '& .MuiSelect-icon': {
        color: 'black',
      },
      '& .MuiInput-underline': {
        borderBottom: '1px solid black',
      },
    },
  };

  const useStyles = makeStyles(defaultStyles);

  const classes = useStyles();

  return (
    <FormControl style={{width: '10rem'}} classes={{root: classes.root}}>
      <InputLabel htmlFor={(props.label as string) || ''}>{props.label}</InputLabel>
      <Select {...props}>{props.children}</Select>
    </FormControl>
  );
};

export const SSelectSimple = (props: SelectProps & {helperText?: string | boolean | undefined}) => {
  const defaultStyles = {
    root: {
      paddingBottom: '1.5rem',
      margin: 'auto',
      '& .MuiSelect-icon': {
        top: 'calc(50% - 10px)',
        color: '#1C78AD',
        fontWeight: 300,
        fontSize: '1.25rem',
      },
      '& .MuiSelect-root': {
        color: '#000',
      },
      '& .MuiInput-underline': {
        borderBottom: '1px solid black',
      },
      '& .MuiInput-underline:hover': {
        borderBottom: '1px solid black',
      },
    },
  };

  const useStyles = makeStyles(defaultStyles);

  const classes = useStyles();

  const {error, ...selectProps} = props;

  return (
    <FormControl fullWidth classes={{root: classes.root}} error={error}>
      <Select {...selectProps} IconComponent={props => <KeyboardArrowDownRoundedIcon {...props} />}>
        {props.children}
      </Select>
      <FormHelperText>{props.helperText}</FormHelperText>
    </FormControl>
  );
};
