import React, {useContext, useState} from 'react';
import clsx from 'clsx';
import IconBinTrash from '../../assets/svg/IconBinTrash';
import ARContext from '../../context/ar-context/ARContext';
import {PriceFormat} from '../../utils/formatter';
import {WidgetPlaceHolder} from './WigetPlaceHolder';
import {SelectEmailTemplateDialog, SelectEmailTemplateScope} from './SelectEmailTemplateDialog';
import {Button, Typography} from 'spenda-ui-react';
import LoadingIndicator from '../ui/LoadingIndicator';
import moment from 'moment';
import {WarningDialog} from './modals/WarningDialog';

export const EmailBatchStatementsWidget = () => {
  // States
  const [isShowSelectEmailTemplateDialog, setIsShowSelectEmailTemplateDialog] = useState(false);
  const [isShowWarningDialog, setIsShowWarningDialog] = useState(false);

  // Context
  const {emailBatch, setEmailBatch} = useContext(ARContext);

  if (emailBatch?.loading) {
    return <LoadingIndicator size="md" isLoading={true} />;
  }

  if (!emailBatch?.emailBatchList?.length) {
    return <WidgetPlaceHolder placeholder="Selected customers appear here" />;
  }

  const handleDiscardBatch = () => {
    setEmailBatch(undefined);
    setIsShowWarningDialog(false);
  };

  const handleRemoveCustomer = (id: number) => {
    setEmailBatch({
      ...emailBatch,
      emailBatchList: emailBatch?.emailBatchList?.filter(e => e?.accountCustomerId !== id),
    });
  };

  return (
    <div className={clsx('pb-15 relative flex h-full w-full flex-col')}>
      <div className="flex h-full w-full flex-col justify-between pb-20">
        <div className=" h-15 flex w-full flex-row items-center justify-between bg-[#FFF] p-2.5">
          <Typography variant="h2" className="font-light leading-normal text-black-800">
            {emailBatch?.emailBatchList?.length && `Selected Customers(${emailBatch?.emailBatchList?.length})`}
          </Typography>
        </div>
        <div className="flex h-full w-full flex-col items-center overflow-y-auto">
          <div className="flex h-full w-full flex-col gap-2">
            <div className="flex h-full w-full flex-col items-center gap-2.5 px-2.5 pb-[80px] pt-3">
              {emailBatch?.emailBatchList?.map(c => (
                <div key={c?.customerName} data-autoid={`lstSelectedCustomer-${c?.customerName}`} className="w-full">
                  <div className={clsx('mb-1')}>
                    <div className="relative flex w-full flex-row items-center justify-between rounded-md border !border-[#F1F1F1] bg-[#FAFAFA] px-2.5 pb-5 pt-6">
                      <div className="absolute right-0 top-0 rounded-bl-md rounded-tr-md bg-[#C68A1920] px-2 py-1 font-poppins text-xs font-medium text-[#C68A19]">
                        <span data-autoid={`lblLastTemplate`}> Last template: {c?.lastTemplateUsed || 'None'}</span>
                      </div>
                      <div className="flex flex-col justify-center gap-1">
                        <Typography variant="h3" data-autoid={`lblCustomerName`} className="font-medium text-black-800">
                          {c?.customerName}
                        </Typography>
                        <Typography variant="small" className="text-spenda-error">
                          {emailBatch?.timelineGroup ? 'Collection Amount' : 'Total Overdue'}:{' '}
                          <span className="font-semibold" data-autoid={`lblTotalOverdue`}>
                            {PriceFormat(c?.totalOverdue, undefined, 2)}
                          </span>
                        </Typography>
                        <Typography variant="small" className="text-black-800">
                          Total Owing:
                          <span className="font-semibold" data-autoid={`lblTotalOwing`}>
                            {PriceFormat(c?.totalOwing, undefined, 2)}
                          </span>
                        </Typography>
                      </div>
                      <div className="flex flex-row items-center justify-end gap-5">
                        <div className="flex flex-col justify-end">
                          <Typography variant="xsmall" className="text-black-800">
                            Last notification sent:
                          </Typography>
                          <Typography
                            variant="h3"
                            data-autoid={`lblLastNotificationDate`}
                            className="text-right font-semibold text-black-800"
                          >
                            <b>
                              {c?.lastNotificationSent ? moment(c?.lastNotificationSent).format('DD MMM YY') : 'None'}
                            </b>
                          </Typography>
                        </div>
                        <button
                          type="button"
                          data-autoid={`btnRemoveCustomer`}
                          onClick={() => handleRemoveCustomer(c?.accountCustomerId)}
                          className="flex cursor-pointer flex-row items-center justify-end"
                        >
                          <IconBinTrash />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="w-full px-2.5">
          <div className="h-15 flex w-full flex-row items-center justify-between rounded-[8px] bg-[#f0f0f0] p-2.5">
            <Button
              onClick={() => setIsShowWarningDialog(true)}
              data-autoid={`btnDiscard`}
              className="bg-white"
              variant="outlined"
              color="error"
            >
              Discard
            </Button>
            <Button
              variant="filled"
              color="primary"
              data-autoid={`btnSelect&SendTemplate`}
              onClick={() => setIsShowSelectEmailTemplateDialog(true)}
            >
              Select & Send Template
            </Button>
          </div>
        </div>
      </div>
      {isShowSelectEmailTemplateDialog && (
        <SelectEmailTemplateDialog
          isShowModal={isShowSelectEmailTemplateDialog}
          onClose={() => setIsShowSelectEmailTemplateDialog(false)}
          scope={SelectEmailTemplateScope.SendStatement}
        />
      )}
      {isShowWarningDialog && (
        <WarningDialog
          conrfirmBtn={{title: 'Confirm', onClick: () => handleDiscardBatch()}}
          cancelBtn={{title: 'Cancel', onClick: () => setIsShowWarningDialog(false)}}
          title="Warning"
          message="Do you want to discard this batch?"
          onClose={() => setIsShowWarningDialog(false)}
          isOpen={isShowWarningDialog}
        />
      )}
    </div>
  );
};
