import React, {FunctionComponent, PropsWithChildren, useEffect, useState} from 'react';
import {IUserAccountInfo, UserRoleEnum} from '../../model/user/UserAccountInfo';
import Skeleton from '../Skeleton';
import EditUserDialog from './EditUserDialog';
import {Toast} from '../../utils/Toast';
import {IUser} from '../../model/user/User';
import {IActionResultsList} from '../../model/ActionResults';
import useUsersAPI from '../../services/useUsersAPI';
import {Badge, Button, Typography} from 'spenda-ui-react';
import {AlertDialogSlideV2, IDialogActionV2} from './AlertDialogSlideV2';
import {useFeatureFlags} from '../../hooks/useFeatureFlags';

interface IUserDialogProps {
  title: string;
  actions: IDialogActionV2[];
  updateLoggedInUser: (user: Partial<IUser>) => void;
  logOut?: () => void;
  currentUser: IUser;
  paymentAuth72488?: boolean;
  isAPOnboardingV1?: boolean;
  APOnCloseUserDialog?: () => void;
  APUser?: IUserAccountInfo;
  APUserSave?: () => void;
}

const UsersDialog: FunctionComponent<IUserDialogProps> = ({
  title,
  actions,
  updateLoggedInUser,
  logOut,
  currentUser,
  paymentAuth72488,
  isAPOnboardingV1,
  APOnCloseUserDialog,
  APUser,
  APUserSave,
}) => {
  const [users, setUsers] = useState<IUserAccountInfo[]>([]);
  const {serviceManagementV1} = useFeatureFlags().tenantOwned();
  const [isEditUserVisible, setIsEditUserVisible] = useState(false);
  const [userToEdit, setUserToEdit] = useState<IUserAccountInfo | undefined>(undefined);
  const [currentUserAccount, setCurrentUserAccount] = useState<IUserAccountInfo | undefined>(undefined);
  const [userGroups, setUserGroups] = useState([
    {
      name: 'Primary User',
      filter: (u: IUserAccountInfo) => u.IsPrimaryContact,
    },
    {
      name: 'Admin Users',
      filter: (u: IUserAccountInfo) =>
        !u.IsPrimaryContact &&
        u.UserRoles.some(u => u.UserRoleEnum === UserRoleEnum.Administrator) &&
        u.UserRoles.every(u => u.UserRoleEnum !== UserRoleEnum.Support),
    },
    {
      name: 'Standard Users',
      filter: (u: IUserAccountInfo) =>
        !u.IsPrimaryContact &&
        u.UserRoles.every(
          u =>
            u.UserRoleEnum !== UserRoleEnum.Administrator &&
            u.UserRoleEnum !== UserRoleEnum.Support &&
            u.UserRoleEnum !== UserRoleEnum.Technician,
        ),
    },
    {
      name: 'Support Users',
      filter: (u: IUserAccountInfo) =>
        !u.IsPrimaryContact && u.UserRoles.some(u => u.UserRoleEnum === UserRoleEnum.Support),
    },
  ]);

  const {get, getUser, saveUser, isLoading} = useUsersAPI();

  const {UserID: currentUserID} = currentUser;

  useEffect(() => {
    if (!isEditUserVisible) {
      get({}).then((data: IActionResultsList<IUserAccountInfo>) => {
        if (data.IsSuccess) {
          setUsers(data.Items);
        }
      });
    }
  }, [isEditUserVisible]);

  useEffect(() => {
    if (!isEditUserVisible && currentUserID) {
      getUser(currentUserID).then(userAccount => {
        setCurrentUserAccount(userAccount);
      });
    }
  }, [isEditUserVisible, currentUserID]);

  useEffect(() => {
    if (isAPOnboardingV1) {
      onOpenEditUser(APUser);
    }
  }, [APUser, isAPOnboardingV1]);

  useEffect(() => {
    const techIndex = userGroups.findIndex(ug => ug.name === 'Technician');
    if (serviceManagementV1 && techIndex === -1) {
      setUserGroups([
        ...userGroups,
        {
          name: 'Technician',
          filter: (u: IUserAccountInfo) =>
            !u.IsPrimaryContact && u.UserRoles.some(u => u.UserRoleEnum === UserRoleEnum.Technician),
        },
      ]);
    } else if (!serviceManagementV1 && techIndex !== -1) {
      const groups = [...userGroups];
      groups.splice(techIndex, 1);
      setUserGroups(groups);
    }
  }, [serviceManagementV1]);

  const newUser: IUserAccountInfo = {
    FirstName: '',
    LastName: '',
    Position: '',
    EmailAddress: '',
    Mobile: '',
    UserRoles: [],
    IsPrimaryContact: false,
    IsActive: true,
    IsEnabled: true,
    IsGetNotificationEmailAlerts: false,
    IsNewUnconfirmedUser: true,
    OptionsFor2SA: null,
  };

  const onSaveUser = async (user?: IUserAccountInfo, isLogOutAfterSaving?: boolean) => {
    if (!user) {
      return;
    }
    user.IsReachUser = user.IsEnabled;

    let isSaved = false;

    isSaved = (await saveUser(user as IUserAccountInfo)).IsSuccess;

    if (isSaved) {
      Toast.info(`User details have been saved`);
      setIsEditUserVisible(false);
      setUserToEdit(undefined);

      if (user.UserID === currentUserID) {
        updateLoggedInUser({...currentUser, FirstName: user.FirstName, LastName: user.LastName});
      }

      if (isLogOutAfterSaving && logOut) {
        logOut();
      }

      if (isAPOnboardingV1 && APUserSave) {
        APUserSave();
      }
    }
  };

  const onOpenEditUser = (user?: IUserAccountInfo) => {
    setIsEditUserVisible(true);
    setUserToEdit(user ? user : newUser);
  };

  const onCloseEditUser = () => {
    setIsEditUserVisible(false);
    setUserToEdit(undefined);
    if (isAPOnboardingV1) {
      APOnCloseUserDialog?.();
    }
  };

  return (
    <>
      {isAPOnboardingV1 ? (
        <div>
          {isEditUserVisible && (
            <EditUserDialog
              title="User details"
              actions={[
                {
                  label: 'Cancel',
                  variant: 'outlined',
                  action: onCloseEditUser,
                },
              ]}
              user={userToEdit}
              updateLoggedInUser={updateLoggedInUser}
              onSaveUser={onSaveUser}
              isEditable={!currentUserAccount?.IsStandardUser}
              currentUserID={currentUserID}
              currentPrimaryUser={users.find(u => u.IsPrimaryContact)}
              paymentAuth72488={paymentAuth72488}
              isAPOnboardingV1={isAPOnboardingV1}
              showFooterNewDesign={isAPOnboardingV1}
            />
          )}
        </div>
      ) : (
        <AlertDialogSlideV2
          headerChildren={
            !currentUserAccount?.IsStandardUser && (
              <Button className="!absolute left-[22px]" onClick={() => onOpenEditUser()}>
                New User
              </Button>
            )
          }
          title={title}
          headingTextSize="h1"
          dialogBodyClassess="h-[74vh] overflow-y-scroll px-[50px] scroll-smooth"
          dialogClassess="!w-[960px] !min-w-[960px] !w-max-[960px]"
          actions={actions}
        >
          <div className="pl-16 text-black-900">
            {userGroups.map((ug, id) => (
              <UserGroup
                key={id}
                groupName={ug.name}
                users={users.filter(ug.filter)}
                currentUserID={currentUserID}
                isLoading={isLoading}
                onUserClick={onOpenEditUser}
              />
            ))}
          </div>
          {isEditUserVisible && (
            <EditUserDialog
              title="User Details"
              actions={[
                {
                  label: 'Cancel',
                  action: onCloseEditUser,
                  variant: 'outlined',
                },
              ]}
              user={userToEdit}
              updateLoggedInUser={updateLoggedInUser}
              onSaveUser={onSaveUser}
              isEditable={!currentUserAccount?.IsStandardUser}
              currentUserID={currentUserID}
              currentPrimaryUser={users.find(u => u.IsPrimaryContact)}
              paymentAuth72488={paymentAuth72488}
            />
          )}
        </AlertDialogSlideV2>
      )}
    </>
  );
};

const UserGroup = (props: {
  groupName: string;
  users: IUserAccountInfo[];
  isLoading: boolean;
  currentUserID?: number;
  onUserClick?: (user: IUserAccountInfo) => void;
}) => {
  const {groupName, users, currentUserID, isLoading, onUserClick} = props;
  return (
    <section className="relative mt-8 pb-6">
      <div className="groupName relative w-full">
        <Typography className="relative left-[-6rem] z-50 inline-block bg-white pr-1 text-base text-gray-700">
          {groupName}
        </Typography>
      </div>
      {users.length ? (
        <UserCardList users={users} currentUserID={currentUserID} onUserClick={onUserClick} />
      ) : (
        !isLoading && (
          <Typography variant="h3" className="text-center text-gray-700">
            You currently have no {groupName}.
          </Typography>
        )
      )}
      {isLoading && <UserCard isLoading={true} />}
    </section>
  );
};

const UserCardList = (props: {
  users: IUserAccountInfo[];
  currentUserID?: number;
  onUserClick?: (user: IUserAccountInfo) => void;
}) => {
  const {users, currentUserID, onUserClick} = props;

  return (
    <div className="flex flex-wrap pl-1">
      {users.map(u => (
        <UserCard key={`user-${u.UserID}`} user={u} isMe={u.UserID == currentUserID} onClick={onUserClick} />
      ))}
    </div>
  );
};

const UserCard = (props: {
  user?: IUserAccountInfo;
  isMe?: boolean;
  isLoading?: boolean;
  onClick?: (user: IUserAccountInfo) => void;
}) => {
  const {user, isMe, isLoading} = props;

  const onClick = () => {
    if (props.onClick) {
      props.onClick(user!);
    }
  };

  return (
    <STile
      dataAutoid={user?.EmailAddress}
      isDisabled={!user?.IsEnabled}
      isActive={user?.IsConfirmed}
      isLoading={isLoading}
      onClick={onClick}
      isMe={isMe}
    >
      <>
        <Typography
          variant="h3"
          className="truncate font-semibold"
          title={`${user?.FirstName} ${user?.LastName}`}
        >{`${user?.FirstName} ${user?.LastName}`}</Typography>
        <Typography className="block truncate text-xs" title={user?.Position || ''}>
          {user?.Position}
        </Typography>
        <Typography className="block truncate text-xs text-gray-700" title={user?.EmailAddress || ''}>
          {user?.EmailAddress}
        </Typography>
        <Typography className="block truncate text-xs text-gray-700" title={user?.Phone1 || user?.Mobile || ''}>
          {user?.Phone1 || user?.Mobile}
        </Typography>
      </>
    </STile>
  );
};

interface ISpendaTileProps extends PropsWithChildren {
  isDisabled?: boolean;
  isLoading?: boolean;
  isActive?: boolean;
  onClick?: (obj: any) => void;
  width?: string;
  isMe?: boolean;
  dataAutoid?: string;
}

export const STile: FunctionComponent<ISpendaTileProps> = props => {
  return (
    <Badge
      content={'ME'}
      className={`-left-[2%] top-[15%] h-8 w-8`}
      invisible={!props.isMe}
      color="orange"
      placement="top-start"
    >
      <button
        data-autoid={props.dataAutoid || 'tile-missing-autoid'}
        className={`relative my-2 mr-2 min-h-[95px] w-44 cursor-pointer rounded border-2 p-1 text-center font-poppins outline-none ${
          props.isLoading ? '' : props.isActive ? 'border-primary' : 'border-warning'
        } ${props.isDisabled ? 'opacity-50' : ''} focus:outline-none`}
        onClick={props.onClick}
      >
        {props.isLoading ? <Skeleton count={4} /> : props.children}
      </button>
    </Badge>
  );
};

export default UsersDialog;
