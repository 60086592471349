import * as React from 'react';
import {SVGProps} from 'react';

const IconAddPaymentOption = (props: SVGProps<SVGSVGElement>) => (
  <svg width={22} height={22} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Add Payment</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.583 2.2h11.074a4.589 4.589 0 0 1 4.583 4.583v4.4a.917.917 0 0 1-1.833 0V8.617H1.833v5.5a2.75 2.75 0 0 0 2.75 2.75h9.02a.917.917 0 1 1 0 1.833h-9.02A4.589 4.589 0 0 1 0 14.117V6.783A4.589 4.589 0 0 1 4.583 2.2Zm11.074 1.833H4.583a2.75 2.75 0 0 0-2.75 2.75h16.574a2.75 2.75 0 0 0-2.75-2.75Zm-10.615 11a1.375 1.375 0 1 0 0-2.75 1.375 1.375 0 0 0 0 2.75Zm16.043.36c.506 0 .917.397.917.887s-.41.887-.917.887h-1.592v1.541c-.009.484-.416.872-.916.872s-.908-.388-.917-.872v-1.541h-1.593c-.5-.008-.9-.403-.9-.887s.4-.879.9-.887h1.593v-1.541c.009-.484.416-.872.916-.872s.908.388.917.872v1.54h1.592Z"
      className="fill-primary"
    />
  </svg>
);

export default IconAddPaymentOption;
