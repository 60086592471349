import React from 'react';
import {FormikProps} from 'formik';
import {PasswordField} from './PasswordField';
import {ChangeFormValues} from '../../screens/public/ChangePassword';
import {Box, makeStyles} from '@material-ui/core';
import {PrimaryButton} from '../buttons/DefaultButtons';

const useChangePasswordFormStyles = makeStyles({
  pswdLabel: {
    '& legend': {
      width: '57px',
    },
    '& .MuiIconButton-edgeEnd': {
      marginRight: '-6px',
    },
    width: '277px',
  },
  confirmPswdLabel: {
    '& legend': {
      fontSize: '10px',
    },
    '& .MuiIconButton-edgeEnd': {
      marginRight: '-6px',
    },
    width: '277px',
  },
});

const ChangePasswordForm = (props: FormikProps<ChangeFormValues>) => {
  const {
    values: {newPassword, confirmPassword},
    errors,
    touched,
    handleChange,
    isValid,
    setFieldTouched,
    isSubmitting,
  } = props;

  const change = (name: 'newPassword' | 'confirmPassword', e: {persist: () => void}) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };
  const classes = useChangePasswordFormStyles();

  return (
    <form
      onSubmit={props.handleSubmit}
      className={`rebrandingTxtField mt-20 flex flex-col items-center justify-center`}
    >
      <h1 className={'mt-5 flex justify-center text-center font-poppins text-xl font-light text-primary'}>
        Set a new password
      </h1>
      <br />

      <PasswordField
        id="newPassword"
        error={touched.newPassword && Boolean(errors.newPassword)}
        onChange={change.bind(null, 'newPassword')}
        helperText={touched.newPassword ? errors.newPassword : ''}
        value={newPassword}
        label="Password"
        name="newPassword"
        className={`mt-7 ${classes.pswdLabel}`}
      ></PasswordField>

      <PasswordField
        id="confirmPassword"
        label="Confirm Password"
        error={touched.confirmPassword && Boolean(errors.confirmPassword)}
        onChange={change.bind(null, 'confirmPassword')}
        helperText={touched.confirmPassword ? errors.confirmPassword : ''}
        value={confirmPassword}
        name="confirmPassword"
        className={`mt-7 ${classes.confirmPswdLabel}`}
      ></PasswordField>
      <Box className="flex justify-between">
        <PrimaryButton
          label="Set Password"
          type="submit"
          disabled={!isValid || isSubmitting ? true : false}
          isSubmitting={isSubmitting}
        />
      </Box>
    </form>
  );
};

export default ChangePasswordForm;
