import React from 'react';
const LockIcon = (props: SVGProps) => (
  <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.677 4.667v.949a3.333 3.333 0 012 3.05v4A3.337 3.337 0 0111.344 16H4.677a3.337 3.337 0 01-3.333-3.333v-4a3.333 3.333 0 012-3.051v-.95a4.667 4.667 0 019.333 0zM8.01 1.333a3.333 3.333 0 00-3.333 3.334v.666h6.667v-.666A3.333 3.333 0 008.01 1.333zm5.334 11.334a2 2 0 01-2 2H4.677a2 2 0 01-2-2v-4a2 2 0 012-2h6.667a2 2 0 012 2v4zm-6-2.667a.667.667 0 011.333 0v1.333a.667.667 0 11-1.333 0V10z"
      className="fill-primary"
    />
  </svg>
);
export {LockIcon};
