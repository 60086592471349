import React from 'react';

export const ARMoreAction = (props: {width?: string; height?: string; className?: string; onClick?: any}) => {
  return (
    <svg
      width="4px"
      height="20px"
      className={props.className}
      viewBox="0 0 4 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>More</title>
      <g id="Main-File" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="Spenda-Collect-Supplier-Interface-Copy-18"
          transform="translate(-1408.000000, -242.000000)"
          fill="#1C78AD"
        >
          <g id="Group-10" transform="translate(1016.000000, 222.000000)">
            <g id="3-Dots" transform="translate(392.400000, 20.000000)">
              <path
                d="M1.66666667,3.33333333 C2.58714125,3.33333333 3.33333333,2.58714125 3.33333333,1.66666667 C3.33333333,0.746192084 2.58714125,0 1.66666667,0 C0.746192084,0 0,0.746192084 0,1.66666667 C0,2.58714125 0.746192084,3.33333333 1.66666667,3.33333333 Z M1.66666667,11.6666667 C2.58714125,11.6666667 3.33333333,10.9204746 3.33333333,10 C3.33333333,9.07952542 2.58714125,8.33333333 1.66666667,8.33333333 C0.746192084,8.33333333 0,9.07952542 0,10 C0,10.9204746 0.746192084,11.6666667 1.66666667,11.6666667 Z M1.66666667,20 C2.58714125,20 3.33333333,19.2538079 3.33333333,18.3333333 C3.33333333,17.4128588 2.58714125,16.6666667 1.66666667,16.6666667 C0.746192084,16.6666667 0,17.4128588 0,18.3333333 C0,19.2538079 0.746192084,20 1.66666667,20 Z"
                id="Combined-Shape"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
