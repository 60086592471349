import React, {useContext} from 'react';
import {Formik, FormikProps} from 'formik';
import * as Yup from 'yup';
import {makeStyles, InputAdornment, IconButton} from '@material-ui/core';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';

import {PaymentWidgetContext, PaymentWidgetPaymentSteps, PaymentWidgetStep} from './PaymentWidget';
import {INewAirPlusAccountRequest} from '../../../services/useSpendaPaymentServicesAPI';
import {usePaymentsFromBuyer} from './usePaymentsFromBuyer';
import {Toast} from '../../../utils/Toast';
import {PaymentWidgetScope} from '../../../model/constants/Constants';
import {STextField} from '../../../components/inputs/STextField';
import {PrimaryButton, SecondaryButton} from '../../../components/buttons/DefaultButtons';
import AirPlusThumbnailIcon from '../../../assets/svg/AirPlus_Icon.svg';

const useStyles = makeStyles({
  iconRoot: {
    color: '#BBB',
    fontSize: '1.25rem',
    '& .MuiSvgIcon-root': {
      color: '#BBB !important',
    },
  },
  crossButton: {
    width: 'auto !important',
  },
  textFiled: {
    marginBottom: '12px',
    position: 'relative',
    '& .MuiOutlinedInput-input:-webkit-autofill': {
      '-webkit-box-shadow': '0 0 0 100px #fff inset !important',
    },
    '& .MuiInputAdornment-root': {
      display: 'none',
    },
    '& .MuiOutlinedInput-root': {
      padding: '3px 12px',
      height: '100%',
      '&.Mui-focused': {
        '& .MuiInputAdornment-root': {
          display: 'contents',
        },
      },
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 16px) scale(1)',
      fontFamily: 'Poppins !important',
      fontSize: '14px',
      fontWeight: 500,
      width: 'auto',
      color: '#bbbbbb !important',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -5px) scale(1)',
      fontSize: '10px ',
    },
    '& textarea, input': {
      padding: '0px',
      fontFamily: 'Poppins !important',
      fontSize: '14px',
    },
    '& .MuiFormHelperText-root.Mui-error': {
      fontFamily: 'Poppins !important',
      position: 'relative !important',
      top: '0px !important',
    },
    '& .MuiInputAdornment-positionEnd button': {
      padding: '0px !important',
    },
  },
  radioButton: {
    '& .MuiCheckbox-colorSecondary.Mui-checked': {
      color: '#1C78AD',
    },
    '&.MuiCheckbox-root': {
      color: '#1C78AD',
    },
  },
});

const validationSchema = Yup.object({
  MemberNumber: Yup.string()
    .required('Account Number is Required')
    .typeError('Please enter valid Account Number')
    .test('len', 'Please enter valid Account Number', val => {
      const charactersLength = val?.toString()?.trim()?.length ?? 0;
      return charactersLength >= 12 && charactersLength <= 15;
    }),
});

const initialValues = {
  FriendlyName: '',
  MemberNumber: '',
};

export const PaymentWidgetStepAddAirPlus = () => {
  const classes = useStyles();

  const {setStep, setBpspAccountDetails, onCancel, widgetScope} = useContext(PaymentWidgetContext);
  const {saveAirPlusAccount, isLoadingSpendaPaymentServices, fetchSPSAccount} = usePaymentsFromBuyer();

  const onClose = () => {
    if (widgetScope === PaymentWidgetScope.ADD_AIRPLUS) {
      if (onCancel) onCancel();
      return;
    }
    if (setStep) setStep(PaymentWidgetPaymentSteps.NEW);
  };

  const handleSubmit = async (values: INewAirPlusAccountRequest) => {
    try {
      const result = await saveAirPlusAccount(values);
      if (result.accountGUID) {
        updateAirPlus(result.accountGUID);
      }
    } catch (error) {
      if (setStep) setStep(PaymentWidgetPaymentSteps.NEW);
      Toast.error('Some Error Occured');
    }
  };

  const updateAirPlus = async (accountGUID: string) => {
    return fetchSPSAccount(accountGUID).then(res => {
      if (setStep) {
        setStep(PaymentWidgetPaymentSteps.VERIFY_CREDIT_CARD);
      }
      if (setBpspAccountDetails) {
        setBpspAccountDetails(res);
      }
    });
  };

  const title = 'Enter your AirPlus details';

  const body = (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        values,
        setFieldValue,
        handleSubmit,
        handleChange,
        touched,
        errors,
        handleBlur,
      }: FormikProps<INewAirPlusAccountRequest>) => (
        <form onSubmit={handleSubmit} id="add-air-plus-account-form">
          <div className="mx-4 my-6">
            <div className="flex justify-center px-8 pb-12 my-4">
              <img src={AirPlusThumbnailIcon} alt="AirPlus" className="w-[113px] h-[113px]" />
            </div>
            <div className={`font-poppins p-2`}>
              <div className={`${classes.textFiled} font-poppins`}>
                <STextField
                  size="small"
                  id="FriendlyName"
                  name="FriendlyName"
                  helperText={touched.FriendlyName ? (errors.FriendlyName as string) : ''}
                  error={touched.FriendlyName && Boolean(errors.FriendlyName)}
                  value={values.FriendlyName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label="Nickname"
                  variant="outlined"
                  backgroundColor="#FFF"
                  borderColor="#333"
                  labelColor={touched.FriendlyName && Boolean(errors?.FriendlyName) ? '#FF0000' : '#333'}
                  InputProps={{
                    style: {fontFamily: 'Poppins', fontSize: '10px'},
                    endAdornment: values?.FriendlyName?.length ? (
                      <InputAdornment position="end">
                        <IconButton
                          className={classes.crossButton}
                          data-autoid="clearFriendlyName"
                          onClick={() => setFieldValue('FriendlyName', '')}
                          onMouseDown={e => e.preventDefault()}
                        >
                          <ClearRoundedIcon className={`${classes.iconRoot} `} />
                        </IconButton>
                      </InputAdornment>
                    ) : null,
                  }}
                />
              </div>
              <div className={`${classes.textFiled} font-poppins`}>
                <STextField
                  type="number"
                  size="small"
                  id="MemberNumber"
                  name="MemberNumber"
                  helperText={touched.MemberNumber ? (errors.MemberNumber as string) : ''}
                  error={touched.MemberNumber && Boolean(errors.MemberNumber)}
                  value={values.MemberNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label="Framework Number"
                  variant="outlined"
                  backgroundColor="#FFF"
                  borderColor="#333"
                  labelColor={touched.MemberNumber && Boolean(errors?.MemberNumber) ? '#FF0000' : '#333'}
                  InputProps={{
                    style: {fontFamily: 'Poppins', fontSize: '10px'},
                    endAdornment: values?.MemberNumber?.length ? (
                      <InputAdornment position="end">
                        <IconButton
                          className={classes.crossButton}
                          data-autoid="clearMemberNumber"
                          onClick={() => setFieldValue('MemberNumber', '')}
                          onMouseDown={e => e.preventDefault()}
                        >
                          <ClearRoundedIcon className={`${classes.iconRoot} `} />
                        </IconButton>
                      </InputAdornment>
                    ) : null,
                  }}
                />
              </div>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );

  const footer = <Footer onClose={onClose} isLoading={isLoadingSpendaPaymentServices} />;

  return <PaymentWidgetStep title={title} body={body} footer={footer} titleType="bank" />;
};

interface IFooterProps {
  isLoading?: boolean;
  onClose?: () => void;
}
export const Footer = ({isLoading, onClose}: IFooterProps) => {
  return (
    <>
      <SecondaryButton data-autoid="btnClose" onClick={onClose ?? undefined} disabled={isLoading} label={'Cancel'} />
      <PrimaryButton
        disabled={isLoading}
        isSubmitting={isLoading}
        label={'Submit'}
        form="add-air-plus-account-form"
        type="submit"
        data-autoid="btnSubmit"
      />
    </>
  );
};
