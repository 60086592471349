import React from 'react';
import {FormikProps} from 'formik';

import {ICategory} from '../../model/inventory/Category';
import {FileDetails} from '../../model/FileDetails';
import {CategoryAutocomplete} from '../inventory/InventoryAutocomplete';
import {Image} from '../ui/Image';

import {Grid} from '@material-ui/core';
import PhotoLibraryOutlinedIcon from '@material-ui/icons/PhotoLibraryOutlined';
import {IDashboardActionResults} from '../../model/ActionResults';
import useCategoryAPI from '../../services/useCategoryAPI';
import {DatTypes} from '../../model/constants/Constants';
import {Input, Typography} from 'spenda-ui-react';
import {MediaUploader} from '../inputs/MediaUploader';

export const CategoryForm = (props: FormikProps<ICategory> & {handleAddNewClick?: () => void}) => {
  const {dashboard} = useCategoryAPI();
  const {values, errors, touched, handleChange, setFieldValue, handleAddNewClick, setStatus, status, initialValues} =
    props;

  const onMediaUpload = (files: FileDetails[]) => {
    if (Array.isArray(values.MediaFiles)) {
      setFieldValue('MediaFiles', [...values.MediaFiles, ...files]);
    } else {
      setFieldValue('MediaFiles', files);
    }
  };

  const deleteMedia = (id: number | undefined) => {
    if (Array.isArray(values.MediaFiles) && id) {
      setFieldValue(
        'MediaFiles',
        values.MediaFiles.filter((media: FileDetails) => media?.ID !== id),
      );
    }
  };

  const setParentCategory = (selected: Partial<ICategory> | null) => {
    setFieldValue('ParentID', selected?.ID || -1);
    setFieldValue('ParentName', selected?.Name || null);
  };

  const checkCategoryName = () => {
    dashboard({
      DatTypeID: DatTypes.Category,
      LastRowNumber: 0,
      MaxResults: 1,
      Search: values.Name,
      IsExactMatch: true,
      SearchNameOnly: true,
    }).then((res: IDashboardActionResults<ICategory[]>) => {
      if (res.TotalRecordCount > 0 && res.Value[0]?.Name !== initialValues.Name) {
        setStatus({Name: 'Category name already exists'});
      } else {
        setStatus(null);
      }
    });
  };

  return (
    <div className="mb-5 flex flex-col items-start justify-start">
      <Grid container spacing={1}>
        <Grid item sm={2} className="flex items-end">
          <Typography className="pb-6 text-base text-gray-900">Name:</Typography>
        </Grid>
        <Grid item sm={3}>
          <Input
            id="Name"
            name="Name"
            label="Category Name"
            value={values.Name}
            onChange={e => {
              if (!(values.Name.length === 0 && e.target.value === ' ')) handleChange(e);
            }}
            onBlur={checkCategoryName}
            helperText={(touched?.Name && errors?.Name) || (!!status && status?.Name)}
            error={(touched?.Name && Boolean(errors?.Name)) || !!status}
            containerProps={{
              className: 'h-[30px]',
            }}
            labelProps={{
              className: 'peer-focus-within:leading-2 peer-placeholder-shown:leading-[2.9]',
            }}
            className="!pb-2"
            size="md"
            variant="standard"
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item sm={2} className="flex items-end">
          <Typography className="pb-6 text-base text-gray-900">Description:</Typography>
        </Grid>
        <Grid item sm={10}>
          <Input
            id="Description"
            name="Description"
            placeholder="Category Description"
            value={values.Description}
            onChange={handleChange}
            helperText={touched?.Description ? errors?.Description : undefined}
            error={touched?.Description ? Boolean(errors?.Description) : undefined}
            containerProps={{
              className: 'h-[27px]',
            }}
            className="!pt-0"
            variant="standard"
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item sm={2} className="flex items-end">
          <Typography className="pb-6 text-base text-gray-900">Parent:</Typography>
        </Grid>
        <Grid item sm={3}>
          <CategoryAutocomplete
            styles={{marginTop: '-1.5rem'}}
            placeholder="Select Parent"
            selected={
              values.ParentID && values.ParentName
                ? {
                    ID: values.ParentID,
                    Name: values.ParentName,
                  }
                : null
            }
            addNewClick={handleAddNewClick}
            clearClick={() => setParentCategory(null)}
            onSuggestionSelected={(e, selected) => setParentCategory(selected.suggestion)}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item sm={2} className="flex items-center">
          <Typography className="text-base text-gray-900">Media:</Typography>
        </Grid>
        <Grid item sm={3} className="flex items-center ">
          <MediaUploader
            className="shadow-non flex w-full justify-between border-0 border-primary/20 px-12 py-2 text-base leading-normal text-primary"
            icon={<PhotoLibraryOutlinedIcon />}
            onChange={file => onMediaUpload(file)}
            label="Add Category Media"
          ></MediaUploader>
        </Grid>
      </Grid>
      {Array.isArray(values.MediaFiles) && values.MediaFiles.length ? (
        <Grid container spacing={1}>
          <Grid item sm={2} />
          <Grid item sm={10} className="grid grid-cols-4 gap-2 p-1">
            {values.MediaFiles.map((image: FileDetails, index: number) => {
              return (
                <Image
                  key={`product-image-${index}`}
                  alt={image.Name}
                  src={image?.ThumbnailURL || ''}
                  className={`before:pb-full before:grid-area-[1/1/2/2] mt-3 flex justify-center before:block`}
                  onDelete={() => deleteMedia(image?.ID)}
                />
              );
            })}
          </Grid>
        </Grid>
      ) : null}
    </div>
  );
};
