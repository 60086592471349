import React from 'react';
import {IAddress} from '../../model/address/Address';
import {IPlaceAutoCompleteResult} from '../../model/address/place-prediction';
import useAddressAPI from '../../services/useAddressAPI';
import {debounce} from 'lodash';
import {Formik, FormikErrors, FormikProps, FormikTouched} from 'formik';
import {SDialog} from '../modals/modalSpendaMeterialUI';
import {SButton} from '../buttons/Button';
import {css} from 'glamor';
import * as Yup from 'yup';
import {AlertDialogSlide} from '../dialog/AlertDialogSlide';
import googleLogo from '../../assets/svg/powered_by_google_on_white.svg';
import {Input, Switch, Typography} from 'spenda-ui-react';
import {DialogActions, DialogContent, DialogTitle} from '@material-ui/core';

interface IAddressDialogProps {
  title?: string;
  address: IAddress;
  open?: boolean;
  onClose: () => void;
  onSave: (address: IAddress) => void;
}

export const AddressDialog: React.FunctionComponent<IAddressDialogProps> = ({
  title,
  address,
  open,
  onClose,
  onSave,
}: IAddressDialogProps) => {
  const [isShowDiscardChangesConfirmation, setIsShowDiscardChangesConfirmation] = React.useState(false);
  const [isDisplayAddressLookup, setIsDisplayAddressLookup] = React.useState(false);

  const overflowStyle = css({
    '& .MuiDialog-paper': {
      minHeight: '375px',
      overflowY: 'hidden !important',
    },
  });

  const onToggleAddressLookup = () => {
    setIsDisplayAddressLookup(value => !value);
  };

  const onEditLocationClose = (isDirty?: boolean) => {
    if (isDirty) {
      setIsShowDiscardChangesConfirmation(true);
    } else {
      if (onClose) {
        onClose();
      }
    }
  };

  const validationSchema = Yup.object({
    StreetAddress: Yup.string()
      .required('Street Address is required.')
      .max(255, 'Street Address cannot be longer than 255 characters.'),
    City: Yup.string().required('City is required').max(50, 'City cannot be longer than 50 characters.'),
    PostCode: Yup.string().required('Postcode is required').max(50, 'Postcode cannot be longer than 50 characters.'),
    State: Yup.string().max(50, 'State cannot be longer than 50 characters.'),
    Country: Yup.string().required('Country is required').max(100, 'Country cannot be longer than 100 characters.'),
  });

  return (
    <Formik validationSchema={validationSchema} initialValues={address} onSubmit={onSave}>
      {renderProps => (
        <SDialog
          className={`${overflowStyle}`}
          open={open!}
          onClose={() => onEditLocationClose(renderProps.dirty)}
          onBackdropClick={() => onEditLocationClose(renderProps.dirty)}
        >
          <DialogTitle>
            <div className="flex justify-center border-b border-gray-500"> {title} </div>
          </DialogTitle>
          <DialogContent>
            <AddressDetailsForm
              {...renderProps}
              isDisplayAddressLookup={isDisplayAddressLookup}
              onToggleAddressLookup={onToggleAddressLookup}
              onClose={onEditLocationClose}
              open={open}
              isShowDiscardChangesConfirmation={isShowDiscardChangesConfirmation}
              setIsShowDiscardChangesConfirmation={setIsShowDiscardChangesConfirmation}
            />
          </DialogContent>
          <DialogActions>
            <div className="w-full flex justify-between">
              <div className="mb-2">
                <SButton type="button" onClick={() => onEditLocationClose(renderProps.dirty)} color="yellow">
                  Close
                </SButton>
              </div>
            </div>
          </DialogActions>
        </SDialog>
      )}
    </Formik>
  );
};

export interface IAddressDetailsFormProps {
  isAddressLookup?: boolean;
  isShowDiscardChangesConfirmation?: boolean;
  onToggleAddressLookup?: () => void;
  onClose?: (isDirty?: boolean) => void;
  open?: boolean;
  isDisplayAddressLookup?: boolean;
  setIsShowDiscardChangesConfirmation: (value: boolean) => void;
}

export const AddressDetailsForm = (props: FormikProps<IAddress> & IAddressDetailsFormProps) => {
  const {search, get} = useAddressAPI();
  const [addressSuggetionsList, setAddressSuggetionsList] = React.useState<IPlaceAutoCompleteResult[]>([]);

  const handleAdvanceSearch = (value: any) => {
    search(value).then(items => setAddressSuggetionsList(items ? items : []));
  };

  const onClickAddressCard = (_event: React.MouseEvent<HTMLDivElement, MouseEvent>, item: IPlaceAutoCompleteResult) => {
    get(item.PlaceID).then(res => {
      const {StreetAddress, City, PostCode, State, Country} = res;

      props.setFieldValue(`StreetAddress`, StreetAddress || '');
      props.setFieldValue(`City`, City || '');
      props.setFieldValue(`PostCode`, PostCode || '');
      props.setFieldValue(`State`, State || '');
      props.setFieldValue(`Country`, Country || '');

      if (props.onToggleAddressLookup) {
        props.onToggleAddressLookup();
      }
    });
  };

  const change = (name: string, e: {persist: () => void; target?: any}) => {
    e.persist();
    props.handleChange(e);
    props.setFieldValue(`${name}`, e.target.value);
    props.setFieldTouched(name, true, false);
  };

  return (
    <form id="address-form" onSubmit={props.handleSubmit} style={{minWidth: '500px', minHeight: '156px'}}>
      <AddressInputsOrLookup
        address={props.values}
        addressSuggetionsList={addressSuggetionsList}
        change={change}
        isDisplayAddressLookup={props.isDisplayAddressLookup}
        lookUpAddress={handleAdvanceSearch}
        onClickSuggestion={onClickAddressCard}
        toggleAddressLookup={props.onToggleAddressLookup}
        addressInputErrors={props.errors as FormikErrors<IAddress>}
        addressInputTouched={props.touched as FormikTouched<IAddress>}
      />

      {props.isShowDiscardChangesConfirmation && (
        <AlertDialogSlide
          title="Discard Changes"
          actions={[
            {
              label: 'Yes',
              action: () => {
                if (props.onClose) {
                  props.onClose(false);
                }
              },
            },
            {
              label: 'No',
              color: 'yellow',
              textColor: 'white',
              variant: 'outlined',
              action: () => props.setIsShowDiscardChangesConfirmation(false),
            },
          ]}
        >
          <Typography className="pb-3">Are you sure you want to discard your changes ? </Typography>
        </AlertDialogSlide>
      )}
    </form>
  );
};

interface IAddressInputsOrLookup {
  address: IAddress;
  addressSuggetionsList: IPlaceAutoCompleteResult[];
  isDisplayAddressLookup?: boolean;
  isSubmitting?: boolean;
  addressInputErrors?: FormikErrors<IAddress>;
  addressInputTouched?: FormikTouched<IAddress>;
  change: (name: keyof IAddress, e: {persist: () => void}) => void;
  lookUpAddress: (value: any) => void;
  onClickSuggestion: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, item: IPlaceAutoCompleteResult) => void;
  toggleAddressLookup?: () => void;
}

// TODO: Split this component in two 1. ManualAddress 2. LookUpAddress
export const AddressInputsOrLookup = (props: IAddressInputsOrLookup) => {
  const {addressSuggetionsList, address, isDisplayAddressLookup, change, lookUpAddress, onClickSuggestion} = props;

  // const [isManualInput, setIsManualInput] = React.useState(false);

  const debounceOnChange = debounce(lookUpAddress, 500);

  return (
    <>
      <div className="flex justify-end mb-5">
        <Switch
          label="Advanced"
          ripple={false}
          name="advanced"
          checked={!isDisplayAddressLookup}
          // onChange={() => setIsManualInput(!isManualInput)}
          onChange={() => {
            if (props.toggleAddressLookup) {
              props.toggleAddressLookup();
            }
          }}
          color="success"
          circleProps={() => {
            if (props.toggleAddressLookup) {
              props.toggleAddressLookup();
            }
          }}
          rootContainerProps={{className: 'flex flex-row-reverse gap-2'}}

          // labelPlacement="start"
        />
      </div>

      {!props.isDisplayAddressLookup && (
        <>
          <div className="flex mb-8 justify-between">
            <div className="mr-2 w-3/5">
              <Input
                name="StreetAddress"
                label="Address"
                value={address?.StreetAddress}
                className="w-full"
                onChange={change.bind(null, 'StreetAddress')}
                helperText={props.addressInputTouched?.StreetAddress ? props.addressInputErrors?.StreetAddress : ''}
                error={props.addressInputTouched?.StreetAddress && Boolean(props.addressInputErrors?.StreetAddress)}
              />
            </div>
            <div className="ml-2 w-2/5">
              <Input
                name="Country"
                label="Country"
                value={address?.Country}
                className="w-full"
                onChange={change.bind(null, 'Country')}
                helperText={props.addressInputTouched?.Country ? props.addressInputErrors?.Country : ''}
                error={props.addressInputTouched?.Country && Boolean(props.addressInputErrors?.Country)}
              />
            </div>
          </div>
          <div className="flex mt-10 mb-8 justify-between">
            <div className="mr-2 w-1/3">
              <Input
                name="City"
                label="City"
                value={address?.City}
                className="w-full"
                onChange={change.bind(null, 'City')}
                helperText={props.addressInputTouched?.City ? props.addressInputErrors?.City : ''}
                error={props.addressInputTouched?.City && Boolean(props.addressInputErrors?.City)}
              />
            </div>
            <div className="mx-2 w-1/3">
              <Input
                name="State"
                label="State"
                value={address?.State}
                className="w-full"
                onChange={change.bind(null, 'State')}
                helperText={props.addressInputTouched?.State ? props.addressInputErrors?.State : ''}
                error={props.addressInputTouched?.State && Boolean(props.addressInputErrors?.State)}
              />
            </div>
            <div className="ml-2 w-1/3 mb-8">
              <Input
                name="PostCode"
                label="Postcode"
                value={address?.PostCode}
                className="w-full"
                onChange={change.bind(null, 'PostCode')}
                helperText={props.addressInputTouched?.PostCode ? props.addressInputErrors?.PostCode : ''}
                error={props.addressInputTouched?.PostCode && Boolean(props.addressInputErrors?.PostCode)}
              />
            </div>
          </div>
        </>
      )}

      {props.isDisplayAddressLookup && (
        <>
          <div className="md:flex mb-4">
            <div className="md:w-full">
              <Input
                name="GoogleSearch"
                label="Search for an address"
                className="mr-4 w-full"
                autoComplete="false"
                onChange={e => debounceOnChange(e.target.value)}
              />
            </div>
            <div className="flex ml-4 items-center justify-center">
              <img alt="Powered by Google" src={googleLogo} />
            </div>
          </div>
          <div className="md:flex mt-4">
            {addressSuggetionsList.map((item: IPlaceAutoCompleteResult) => (
              <div
                key={item.PlaceID}
                className="w-1/3 flex text-center items-center p-2 mr-2 mb-2 border-primary border-2 rounded text-xs cursor-pointer"
                onClick={e => onClickSuggestion(e, item)}
              >
                {item.PlaceDescription}
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
};
