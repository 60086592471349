import React, {useEffect, useState} from 'react';
import {FormikProps} from 'formik';
import update from 'immutability-helper';
import {Button, Input, Switch, Typography} from 'spenda-ui-react';
import clsx from 'clsx';
import _ from 'lodash';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import AddPlusIcon from '@material-ui/icons/Add';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import {Grid, Divider, IconButton, InputAdornment, makeStyles} from '@material-ui/core';

import {STextField} from '../inputs/STextField';
import {SButton} from '../../components/buttons/Button';
import {ICustomer} from '../../model/customer/Customer';
import {ILocation} from '../../model/address/Location';
import {IAddress} from '../../model/address/Address';
import {LocationAutoComplete} from '../location/LocationAutoComplete';
import {LocationDialog} from '../dialog/LocationDialog';
import {useLocationAPI} from '../../services/useLocationAPI';
import {IconBin} from '../../assets/svg/IconBin';
import {AddIcon} from '../../assets/svg/AddIcon';
import googleImg from '../../assets/png/SalesOrder/powered-by-google.png';

const useAddLocationFormStyle = makeStyles(() => ({
  locationBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '20px',
  },
  locationBoxArea: {
    width: '467px',
    height: '100px',
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#F7F9FA',
    border: '1px solid #EAEAEA',
    borderRadius: '4px',
    padding: '10px 10px',
  },
  locationBoxAreaText: {
    fontSize: '0.875em',
    color: '#333333',
    fontWeight: 400,
    marginBottom: '8px',
    display: 'flex',
    alignItems: 'center',
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  addIcon: {
    border: ' 1px solid hsl(var(--primary))',
    width: '40px',
    height: ' 40px',
    borderRadius: '6px',
    fill: 'hsl(var(--primary))',
    marginLeft: '20px',
    cursor: 'pointer',
  },
  textFields: {
    '& .MuiFormControl-root': {
      background: '#fff',
      paddingBottom: '0px',
      height: '100%',
      '& .MuiOutlinedInput-root': {
        height: '42px',
        backgroundColor: 'transparent',
        borderRadius: '6px',
      },
      '& .MuiFormLabel-root': {
        color: '#BBBBBB',
        fontFamily: 'Poppins, sans-serif !important',
        width: 'auto',
        fontWeight: 600,
        fontSize: '14px',
        top: '-6px',
        left: '-5px',
      },
      '& .MuiInputLabel-shrink': {
        top: '3px',
        left: 0,
        paddingRight: '2px',
        marginLeft: '-4px',
        color: '#333',
        fontSize: '10px',
        fontWeight: 500,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#333',
        '& legend': {
          fontSize: '12px!important',
        },
      },
      '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#333!important',
      },
      '& .MuiFormLabel-root.Mui-focused': {
        color: '#333',
      },
      '& .MuiInputBase-input': {
        fontSize: '14px',
        fontWeight: 600,
        padding: '0px 8px',
        fontFamily: 'Poppins !important',
        color: '#333',
      },
      '& .MuiIconButton-root': {
        color: '#1C78AD',
      },
      '& .MuiInputBase-adornedEnd': {
        paddingRight: '0px',
      },
      '& .MuiInputBase-adornedStart': {
        paddingRight: '0px',
        paddingLeft: '7px',
      },
      '& .MuiFormHelperText-root.Mui-error': {
        top: '0px',
        marginLeft: '10px',
        fontFamily: 'poppins',
        position: 'relative',
      },
      '& .MuiOutlinedInput-input:-webkit-autofill': {
        boxShadow: '0 0 0 100px #ffffff inset',
      },
    },
  },
  selectLoc: {
    padding: '0px 12px 0px 0px',
    marginTop: '20px',
    '& .MuiInputBase-root': {
      width: '100%!important',
      '& .MuiIconButton-root': {
        background: 'transparent',
      },
      '& .MuiOutlinedInput-notchedOutline legend': {
        fontSize: '9px!important',
      },
    },
  },
  locationBoxNew: {
    borderRadius: '6px',
    backgroundColor: '#FFFFFF',
    border: '1px solid #1C78AD',
    fontWeight: 600,
    fontSize: '14px',
    color: '#1C78AD',
    padding: '24px 8px',
    position: 'relative',
    width: '100%',
    margin: '4px',
  },
}));

export const AddLocationForm = (props: FormikProps<ICustomer> & {customerToEdit?: ICustomer; customerID?: number}) => {
  const classes = useAddLocationFormStyle();
  const [addressNumber, setAddressNumber] = useState(0);
  const [locationNumber, setLocationNumber] = useState(0);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const {getLocationAddress} = useLocationAPI();
  const {values, handleChange, setFieldValue, handleBlur, customerToEdit} = props;

  const [expand, setExpand] = useState([false]);
  const [box, setBox] = useState([false]);

  const [open, setOpen] = useState(false);
  const locationLength = values?.Locations ? values?.Locations?.length - 1 : 0;
  useEffect(() => {
    for (let i = 0; i < locationLength; i++) {
      box.push(false);
      expand.push(false);
    }
  }, [customerToEdit]);

  useEffect(() => {
    let count = 0;
    let fk = values?.Locations?.length || 0;
    for (let fi = 0; fi < fk; fi++) {
      if (values?.Locations?.[fi]?.IsActive === true) {
        count++;
      }
      if (count == 2) {
        break;
      }
    }
  }, [values?.Locations]);

  const AddAddress = (LIndex: number) => {
    const tempObj: IAddress = {
      Name: '',
      Country: '',
      State: '',
      StreetAddress: '',
      City: '',
      PostCode: '',
      FullAddress: '',
      IsDefaultDeliverTo: false,
      IsDefaultBilling: false,
      IsActive: true,
      addressFlag: true,
    };
    if (
      values?.Locations?.[LIndex]?.Addresses?.length === 1 &&
      !values?.Locations?.[LIndex]?.Addresses?.[0]?.Name &&
      !values?.Locations?.[LIndex]?.Addresses?.[0]?.Country &&
      !values?.Locations?.[LIndex]?.Addresses?.[0]?.State &&
      !values?.Locations?.[LIndex]?.Addresses?.[0]?.StreetAddress &&
      !values?.Locations?.[LIndex]?.Addresses?.[0]?.City &&
      !values?.Locations?.[LIndex]?.Addresses?.[0]?.PostCode
    ) {
      tempObj.IsDefaultDeliverTo = true;
      tempObj.IsDefaultBilling = true;
      setFieldValue(`Locations.${LIndex}.Addresses`, [tempObj]);
    } else {
      setFieldValue(`Locations.${LIndex}.Addresses`, [...(values?.Locations?.[LIndex]?.Addresses || []), tempObj]);
    }
  };

  const AddLocation = () => {
    const tempObj: ILocation = {
      Addresses: [
        {
          Name: '',
          Country: '',
          State: '',
          StreetAddress: '',
          City: '',
          PostCode: '',
          FullAddress: '',
          IsDefaultDeliverTo: true,
          IsDefaultBilling: true,
          IsActive: true,
        },
      ],
      IsActive: true,
      IsDefaultLocation: false,
      tempFlag: true,
    };
    setFieldValue(`Locations`, [...(values?.Locations || []), tempObj]);
    box.push(false);
    expand.push(false);
  };

  const handleDefaultBilling = (lIndex: number, aIndex: number, preference: string) => {
    values &&
      values?.Locations?.[lIndex]?.Addresses?.map((d, index) => {
        if (
          (preference === 'IsDefaultBilling' ? d.IsDefaultBilling === true : d.IsDefaultDeliverTo === true) &&
          index !== aIndex
        ) {
          setFieldValue(`Locations.${[lIndex]}.Addresses.${[index]}.${preference}`, false);
        }
      });
    setFieldValue(
      `Locations.${[lIndex]}.Addresses.${[aIndex]}.${preference}`,
      preference === 'IsDefaultBilling'
        ? !values.Locations?.[lIndex]?.Addresses?.[aIndex]?.IsDefaultBilling
        : !values.Locations?.[lIndex]?.Addresses?.[aIndex]?.IsDefaultDeliverTo,
    );
  };

  const deleteLocation = (locNumber: number) => {
    const _location = _.cloneDeep(values.Locations) || [];
    if (values?.Locations?.[locNumber]?.tempFlag === true) {
      _location.splice(locNumber, 1);
      setFieldValue(`Locations`, _location);
      box.splice(locNumber, 1);
      expand.splice(locNumber, 1);
    } else {
      setFieldValue(`Locations.${locNumber}.IsActive`, false);
    }
  };

  const deleteAddress = (locNumber: number, addressNumber: number) => {
    const _address = _.cloneDeep(values?.Locations?.[locNumber]?.Addresses) || [];
    if (values?.Locations?.[locNumber]?.Addresses?.[addressNumber]?.addressFlag === true) {
      _address.splice(addressNumber, 1);
      setFieldValue(`Locations.${locNumber}.Addresses`, _address);
    } else {
      setFieldValue(
        `Locations.${locNumber}.Addresses.${addressNumber}.IsActive`,
        !values?.Locations?.[locNumber]?.Addresses?.[addressNumber].IsActive,
      );
    }
  };

  const handlePrimaryLocation = (locationIndex: number) => {
    const primaryLoc = values?.Locations?.splice(locationIndex, 1);
    setFieldValue('Locations', [primaryLoc?.[0], ...(values?.Locations || [])]);
    setFieldValue(`Locations.[0].IsDefaultLocation`, true);
    setFieldValue(`Locations.[1].IsDefaultLocation`, false);
  };

  const handleAddAddress = (LIndex: number) => {
    if (
      !values?.Locations?.[LIndex]?.Addresses?.[0]?.Name &&
      !values?.Locations?.[LIndex]?.Addresses?.[0]?.Country &&
      !values?.Locations?.[LIndex]?.Addresses?.[0]?.State &&
      !values?.Locations?.[LIndex]?.Addresses?.[0]?.StreetAddress &&
      !values?.Locations?.[LIndex]?.Addresses?.[0]?.City &&
      !values?.Locations?.[LIndex]?.Addresses?.[0]?.PostCode
    ) {
      setAddressNumber(0);
    } else {
      setAddressNumber(values?.Locations?.[LIndex]?.Addresses?.length || 1);
    }
    setIsEdit(false);
    AddAddress(LIndex);
    setOpen(true);
  };

  return (
    <>
      <LocationDialog
        {...props}
        isEdit={isEdit}
        open={open}
        setOpen={setOpen}
        locationIndex={locationNumber}
        addressIndex={addressNumber}
        handleDefaultBilling={handleDefaultBilling}
        newUI={true}
        isLatestDesign={true}
      />
      {
        <Grid item sm={12} container className="relative" spacing={1}>
          <Grid sm={4} item className={clsx('flex items-center pr-2')}>
            <div className="text-#4D4D4D self-center text-xl font-medium">
              <Typography
                variant="paragraph"
                className="text-#4D4D4D mr-4 whitespace-nowrap font-poppins font-semibold"
              >
                Location Details
              </Typography>
            </div>
            {values?.Locations?.length ? (
              <Input
                id="Locations.0.Name"
                key={`Locations.${0}.Name`}
                name="Locations.0.Name"
                value={values?.Locations?.[0]?.Name}
                onChange={handleChange}
                onBlur={handleBlur}
                label="Location Name"
                variant="outlined"
                className="mr-2"
                containerProps={{
                  endAdornment: values?.Locations?.[0]?.Name ? (
                    <InputAdornment position="end">
                      <IconButton
                        data-autoid="btnClearEmail"
                        onClick={() => setFieldValue(`Locations.${0}.Name`, '')}
                        onMouseDown={e => e.preventDefault()}
                      >
                        <ClearRoundedIcon className="text-#BBBBBB" />
                      </IconButton>
                    </InputAdornment>
                  ) : (
                    <></>
                  ),
                }}
              />
            ) : (
              <AddPlusIcon
                onClick={AddLocation}
                className="fill- ml-5 h-10 w-10 cursor-pointer rounded-md border border-primary/70 text-primary/70"
              />
            )}
          </Grid>
          <Grid sm={2} item />
          {values?.Locations?.[0]?.Addresses?.length === 1 && box[0] === false ? (
            <Grid container item xs={6} className={` flex items-center justify-end pr-3`}>
              <Typography variant="h3" className={`text-#333 mr-3 font-poppins font-medium`}>
                Enter Manually
              </Typography>
              <Switch
                name='chkLocationManual'
                ripple={false}
                checked={expand[0]}
                onChange={() => setExpand(update(expand, {$splice: [[0, 1, !expand[0]]]}))}
              />
            </Grid>
          ) : null}
        </Grid>
      }

      {values?.Locations?.map(
        (locationData: ILocation, locationIndex: number) =>
          locationData.IsActive && (
            <React.Fragment key={locationIndex}>
              <Grid item sm={12} container>
                {locationIndex > 0 ? (
                  <Grid
                    className={`${` relative flex`}`}
                    sm={locationIndex < 1 ? 4 : 12}
                    container
                    spacing={0}
                    item={true}
                  >
                    <Grid item sm={2} className="flex items-end">
                      <p className={`text-base text-[#333333]`}>Location Name:</p>
                    </Grid>
                    <Grid item sm={3}>
                      <Input
                        id={`Locations.${locationIndex}.Name`}
                        name={`Locations.${locationIndex}.Name`}
                        variant="static"
                        placeholder="Location Name"
                        value={values?.Locations?.[locationIndex]?.Name}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid container sm={4} className="ml-4 mt-3 flex flex-row " alignItems="flex-start">
                      {values?.Locations?.filter(a => a.IsActive === true)?.length === 1 ||
                      values?.Locations?.[locationIndex]?.IsDefaultLocation === true ? null : (
                        <div
                          className={`${classes.cursorPointer} mr-4`}
                          onClick={() => {
                            deleteLocation(locationIndex);
                          }}
                        >
                          <IconBin height="24px" width="19.73px" />
                        </div>
                      )}
                      {locationIndex === 0 ? (
                        <SButton
                          color="lightYellow"
                          border="1px solid #C68A19"
                          textColor="yellow"
                          fontSize="xs"
                          lineHeight="xs"
                          width="92px"
                          type="button"
                          onClick={() => {
                            handlePrimaryLocation(locationIndex);
                          }}
                        >
                          Primary Location
                        </SButton>
                      ) : (
                        <SButton
                          color="transparent"
                          border="1px solid #C68A19"
                          textColor="yellow"
                          fontSize="xs"
                          lineHeight="xs"
                          width="92px"
                          type="button"
                          onClick={() => {
                            handlePrimaryLocation(locationIndex);
                          }}
                        >
                          Make Primary
                        </SButton>
                      )}
                    </Grid>
                    {values?.Locations?.[locationIndex]?.Addresses?.length === 1 && box[locationIndex] === false ? (
                      <Grid item sm={2} className="flex justify-end" alignItems="flex-start">
                        <p
                          className={`cursor-pointer text-lg font-semibold text-primary/60`}
                          onClick={() =>
                            setExpand(update(expand, {$splice: [[locationIndex, 1, !expand[locationIndex]]]}))
                          }
                        >
                          {!expand[locationIndex] ? (
                            <>
                              Expand <KeyboardArrowDownIcon />
                            </>
                          ) : (
                            <>
                              Collapse <KeyboardArrowUpIcon />
                            </>
                          )}
                        </p>
                      </Grid>
                    ) : null}
                  </Grid>
                ) : null}
                {locationData?.Addresses?.map((data: ILocation, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      {!values?.Locations?.[locationIndex]?.Addresses?.[0]?.Name &&
                      !values?.Locations?.[locationIndex]?.Addresses?.[0]?.Country &&
                      !values?.Locations?.[locationIndex]?.Addresses?.[0]?.State &&
                      !values?.Locations?.[locationIndex]?.Addresses?.[0]?.StreetAddress &&
                      !values?.Locations?.[locationIndex]?.Addresses?.[0]?.City &&
                      !values?.Locations?.[locationIndex]?.Addresses?.[0]?.PostCode &&
                      index === 0 &&
                      !box[locationIndex] ? (
                        <Grid item sm={12} className={`${classes.textFields} flex`}>
                          {!expand[locationIndex] ? (
                            <div className="w-full">
                              <Grid className={`mb-5 flex`} sm={12} container spacing={0} item={true}>
                                <Grid item sm={4} className={classes.selectLoc}>
                                  <LocationAutoComplete
                                    styles={{marginTop: 0}}
                                    carouselUI={true}
                                    isSalesModule={true}
                                    selected={
                                      values?.Locations?.[locationIndex]?.Addresses?.[index]?.StreetAddress ||
                                      values?.Locations?.[locationIndex]?.Addresses?.[index]?.City ||
                                      values?.Locations?.[locationIndex]?.Addresses?.[index]?.State ||
                                      values?.Locations?.[locationIndex]?.Addresses?.[index]?.Country ||
                                      values?.Locations?.[locationIndex]?.Addresses?.[index]?.PostCode
                                        ? {
                                            PlaceDescription: `${
                                              values?.Locations?.[locationIndex]?.Addresses?.[index]?.StreetAddress ??
                                              ''
                                            } ${values?.Locations?.[locationIndex]?.Addresses?.[index]?.City ?? ''} ${
                                              values?.Locations?.[locationIndex]?.Addresses?.[index]?.State ?? ''
                                            } ${
                                              values?.Locations?.[locationIndex]?.Addresses?.[index]?.PostCode ?? ''
                                            } ${values?.Locations?.[locationIndex]?.Addresses?.[index]?.Country ?? ''}`,
                                          }
                                        : null
                                    }
                                    onSuggestionSelected={(e, selected) => {
                                      getLocationAddress(selected.suggestion.PlaceID || '').then((res: IAddress) => {
                                        if (res) {
                                          setFieldValue(`Locations.${locationIndex}.Addresses.${index}`, {
                                            StreetAddress: res.StreetAddress,
                                            City: res.City,
                                            State: res.State,
                                            Country: res.Country,
                                            PostCode: res.PostCode,
                                            IsDefaultBilling:
                                              values?.Locations?.[locationIndex]?.Addresses?.[index]?.IsDefaultBilling,
                                            IsDefaultDeliverTo:
                                              values?.Locations?.[locationIndex]?.Addresses?.[index]
                                                ?.IsDefaultDeliverTo,
                                            IsActive: true,
                                            addressFlag: true,
                                          });
                                        }
                                      });
                                    }}
                                  />
                                </Grid>
                                {
                                  <Grid sm={8} item className="flex items-start justify-end">
                                    <img width={122} height={17} src={googleImg} className="mr-4 mt-5" />
                                  </Grid>
                                }
                              </Grid>
                            </div>
                          ) : (
                            <>
                              <Grid container xs={9}>
                                <Grid
                                  className="flex"
                                  sm={12}
                                  container
                                  spacing={0}
                                  item={true}
                                  style={{margin: '20px 0px'}}
                                >
                                  <Grid item sm={6} style={{padding: '0px 12px 0px 0px'}}>
                                    <STextField
                                      id={`Locations.${locationIndex}.Addresses.${index}.StreetAddress`}
                                      name={`Locations.${locationIndex}.Addresses.${index}.StreetAddress`}
                                      key={`Locations.${locationIndex}.Addresses.${index}.StreetAddress`}
                                      value={values?.Locations?.[locationIndex]?.Addresses?.[index]?.StreetAddress}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      fullWidth
                                      label={'Street'}
                                      variant={'outlined'}
                                    />
                                  </Grid>
                                  <Grid item sm={6} style={{padding: '0px 12px 0px 0px'}}>
                                    <STextField
                                      id={`Locations.${locationIndex}.Addresses.${index}.City`}
                                      name={`Locations.${locationIndex}.Addresses.${index}.City`}
                                      key={`Locations.${locationIndex}.Addresses.${index}.City`}
                                      value={values?.Locations?.[locationIndex]?.Addresses?.[index]?.City}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      fullWidth
                                      label={'Suburb/City'}
                                      variant={'outlined'}
                                    />{' '}
                                  </Grid>
                                </Grid>
                                <Grid container xs={12}>
                                  <Grid className="flex" sm={8} container spacing={0} item={true}>
                                    <Grid item sm={6} style={{padding: '0px 12px 0px 0px'}}>
                                      <STextField
                                        id={`Locations.${locationIndex}.Addresses.${index}.State`}
                                        name={`Locations.${locationIndex}.Addresses.${index}.State`}
                                        key={`Locations.${locationIndex}.Addresses.${index}.State`}
                                        value={values?.Locations?.[locationIndex]?.Addresses?.[index]?.State}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        fullWidth
                                        label={'State'}
                                        variant={'outlined'}
                                      />
                                    </Grid>
                                    <Grid item sm={6} style={{padding: '0px 12px 0px 0px'}}>
                                      <STextField
                                        id={`Locations.${locationIndex}.Addresses.${index}.Country`}
                                        name={`Locations.${locationIndex}.Addresses.${index}.Country`}
                                        key={`Locations.${locationIndex}.Addresses.${index}.Country`}
                                        value={values?.Locations?.[locationIndex]?.Addresses?.[index]?.Country}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        fullWidth
                                        label={'Country'}
                                        variant={'outlined'}
                                      />
                                    </Grid>
                                  </Grid>
                                  <Grid className="flex" sm={4} container spacing={0} item={true}>
                                    <Grid item sm={12} style={{padding: '0px 12px 0px 0px'}}>
                                      <STextField
                                        id={`Locations.${locationIndex}.Addresses.${index}.PostCode`}
                                        name={`Locations.${locationIndex}.Addresses.${index}.PostCode`}
                                        key={`Locations.${locationIndex}.Addresses.${index}.PostCode`}
                                        value={values?.Locations?.[locationIndex]?.Addresses?.[index]?.PostCode}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        fullWidth
                                        label={'Postcode'}
                                        variant={'outlined'}
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                              {
                                <Grid xs={3} container style={{padding: '20px 0 0'}}>
                                  <Button
                                    className={`flex h-full w-full items-center justify-center rounded-md bg-primary/70 font-poppins text-base font-bold text-white`}
                                    onClick={() => {
                                      setBox(update(box, {$splice: [[locationIndex, 1, true]]}));
                                    }}
                                    disabled={
                                      !props.values.Locations?.[locationIndex]?.Addresses?.[index]?.PostCode ||
                                      !props.values.Locations?.[locationIndex]?.Addresses?.[index]?.Country
                                    }
                                    data-autoid="btnUseThisAddress"
                                  >
                                    Use this Address
                                  </Button>
                                </Grid>
                              }
                            </>
                          )}
                        </Grid>
                      ) : (
                        <Grid sm={4} container>
                          {
                            <div
                              className={
                                'relative m-1 w-full rounded-md border border-primary bg-white px-2 py-6 text-base text-primary'
                              }
                            >
                              <p className="text-center">
                                {values?.Locations?.[locationIndex]?.Addresses?.[index]?.StreetAddress}{' '}
                                {values?.Locations?.[locationIndex]?.Addresses?.[index]?.City}{' '}
                                {values?.Locations?.[locationIndex]?.Addresses?.[index]?.State}
                                {values?.Locations?.[locationIndex]?.Addresses?.[index]?.PostCode}
                                {values?.Locations?.[locationIndex]?.Addresses?.[index]?.PostCode ? ', ' : ''}
                                {values?.Locations?.[locationIndex]?.Addresses?.[index]?.Country}
                              </p>
                              {/* defaultTag: {
    margin: '0 2px',
  }, */}
                              <div
                                className="absolute text-xs font-medium text-primary flex justify-between w-[calc(100%-4px)]"
                                style={{top: '2px', left: '2px'}}
                              >
                                {values.Locations?.[locationIndex]?.Addresses?.[index]?.IsDefaultBilling === true ? (
                                  <span className='whitespace-nowrap rounded-md bg-primary/10 px-1.5 py-0.5 mx-1'>Default Billing Address</span>
                                ) : null}
                                {values.Locations?.[locationIndex]?.Addresses?.[index]?.IsDefaultDeliverTo === true ? (
                                  <span className='whitespace-nowrap rounded-md bg-primary/10 px-1.5 py-0.5 mx-1'>Default Shipping Address</span>
                                ) : null}
                              </div>
                              <div className="absolute" style={{bottom: '2px', right: '2px'}}>
                                {data?.IsActive ? (
                                  <>
                                    <IconButton
                                      data-autoid="btnEditAddress"
                                      onClick={() => {
                                        setLocationNumber(locationIndex);
                                        setAddressNumber(index);
                                        setIsEdit(true);
                                        setOpen(true);
                                      }}
                                      style={{padding: 4}}
                                    >
                                      <EditRoundedIcon fontSize="small" style={{color: '#D8D8D8'}} />
                                    </IconButton>
                                    <IconButton
                                      data-autoid="btnDeleteAddress"
                                      onClick={() => {
                                        deleteAddress(locationIndex, index);
                                      }}
                                      style={{padding: 4}}
                                    >
                                      <DeleteRoundedIcon fontSize="small" style={{color: '#D8D8D8'}} />
                                    </IconButton>
                                  </>
                                ) : (
                                  <IconButton
                                    data-autoid="btnAddAddress"
                                    onClick={() => {
                                      deleteAddress(locationIndex, index);
                                    }}
                                    style={{padding: 4}}
                                  >
                                    <AddIcon width="16px" height="16px" />
                                  </IconButton>
                                )}
                              </div>
                            </div>
                          }
                        </Grid>
                      )}
                    </React.Fragment>
                  );
                })}
              </Grid>
              <div className={`flex w-full justify-start px-2 py-5 pt-0`}>
                <div className="flex">
                  {
                    <Button
                      ripple={false}
                      variant="text"
                      className="hover:no-underline"
                      onClick={() => {
                        setLocationNumber(locationIndex);
                        handleAddAddress(locationIndex);
                      }}
                    >
                      Add New Address
                    </Button>
                  }
                </div>
              </div>
              {locationIndex !== (values?.Locations?.length || 1) - 1 ? (
                <Divider
                  className="w-full"
                  style={{height: '2px !important', backgroundColor: '#F1F1F1', margin: '20px 0px'}}
                />
              ) : null}
            </React.Fragment>
          ),
      )}
    </>
  );
};
