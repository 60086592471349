import * as React from 'react';
const WatchIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg {...props}>
    <path
      fill="#999"
      fillRule="evenodd"
      d="M11.495 5.5a.5.5 0 0 0-.5.5 5.017 5.017 0 1 1-1.45-3.51.43.43 0 0 1-.05.01h-1.5a.5.5 0 0 0 0 1h1.5a1.5 1.5 0 0 0 1.5-1.5V.5a.5.5 0 0 0-1 0v1.032a5.997 5.997 0 1 0 2 4.468.5.5 0 0 0-.5-.5Z"
      clipRule="evenodd"
    />
    <path
      fill="#999"
      fillRule="evenodd"
      d="M5.994 3a.5.5 0 0 0-.5.5V6a.5.5 0 0 0 .147.354l1.5 1.5a.5.5 0 0 0 .707-.708L6.494 5.793V3.5a.5.5 0 0 0-.5-.5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default WatchIcon;
