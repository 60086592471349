import React, {useState} from 'react';
import {useHistory, Link as RouterLink} from 'react-router-dom';

import {InventoryClass} from '../../model/inventory/InventoryClass';

import {Layout} from '../../components/layout/Layout';
import {SButton} from '../../components/buttons/Button';
import {STable} from '../../components/tables/STable';
import {SPagination} from '../../components/tables/SPagination';
import {InventoryClassesToolbar} from '../../components/inventory/InventoryToolbar';
import {useLoadInventoryClass} from '../../services/useInventoryClassesAPI';

import {makeStyles} from '@material-ui/core';

import {AUTH_INVENTORY_PRODUCT_CLASSES_ADD, AUTH_INVENTORY_PRODUCT_CLASSES_EDIT} from '../../routes/InventoryRoutes';

const useProductClassesListStyles = makeStyles(() => ({
  root: {
    maxHeight: 'calc( 100vh - 20.5em )',
  },
  title: {
    fontWeight: 500,
    fontSize: '1.5em',
    color: '#4D4D4D',
  },
}));

export const ProductClassesList = () => {
  const history = useHistory();
  const classes = useProductClassesListStyles();
  const [selected, setSelected] = useState<number[]>([]);

  const {
    loading,
    totalCount,
    pageSize,
    currentPage,
    items: inventoryClassList,
    searchFilter,
    setSearchFilter,
  } = useLoadInventoryClass();

  const handleRowClick = (rowData: InventoryClass) => {
    history.push(AUTH_INVENTORY_PRODUCT_CLASSES_EDIT.replace(/:productClassID/g, rowData.ID));
  };

  const handlePageChange = (newPage: number) => {
    setSearchFilter({
      ...searchFilter,
      LastRowNumber: (newPage - 1) * pageSize,
    });
  };

  const handleRowsChange = (rows: number) => {
    setSearchFilter({
      ...searchFilter,
      MaxResults: rows,
      LastRowNumber: 0,
    });
  };

  const productClassesList = (
    <div
      className={`${classes.root} flex min-h-full w-full flex-col overflow-y-auto rounded-lg bg-white px-5 pt-4`}
      id="product-classes-list"
    >
      <div className={'flex w-full justify-between px-0 py-4'}>
        <p className={`${classes.title}`}>Product Classes</p>
        <div className="flex">
          <RouterLink to={AUTH_INVENTORY_PRODUCT_CLASSES_ADD}>
            <SButton
              margin="0 0.25rem"
              color="blueShade"
              border="1px solid #1C78AD"
              textColor="white"
              lineHeight="m"
              width="m"
              type="button"
            >
              New Product Class
            </SButton>
          </RouterLink>
        </div>
      </div>
      <InventoryClassesToolbar
        loading={loading}
        title={`Classes (${totalCount})`}
        searchFilter={searchFilter}
        data={inventoryClassList}
        selected={selected}
        setSelected={setSelected}
        setSearchFilter={setSearchFilter}
      />
      <STable
        columns={[
          {title: 'Name', field: 'Name'},
          {title: 'Description', field: 'Description', className: 'ellpsisCol'},
          {title: 'Class', field: 'PostingBehaviour'},
          {title: '# Products', field: 'CountOfProducts'},
        ]}
        data={inventoryClassList}
        emptyDataMessage={'No Product Classes'}
        stickyHeader={true}
        // selection={true}
        // selected={selected}
        // setSelected={setSelected}
        loading={loading}
        onRowClick={handleRowClick}
      ></STable>
      <SPagination
        currentPage={currentPage}
        totalCount={totalCount}
        pageSize={pageSize}
        onPageChange={handlePageChange}
        rowsPerPageOptions={[10, 15, 25]}
        onRowsPerPageChange={handleRowsChange}
      />
    </div>
  );

  return (
    <div className={`relative h-full overflow-hidden bg-spenda-newbg`}>
      <Layout leftPanel={productClassesList} splitWidthType={4} />
    </div>
  );
};
