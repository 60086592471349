import React from 'react';
const IconCamera = (props: {width?: string; height?: string; className?: string; color: string}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6667 2.66667H12.328L10.872 0.778667C10.4918 0.289405 9.90763 0.00224649 9.288 0H6.712C6.09237 0.00224649 5.50822 0.289405 5.128 0.778667L3.672 2.66667H3.33333C1.4933 2.66887 0.00220408 4.15996 0 6V12.6667C0.00220408 14.5067 1.4933 15.9978 3.33333 16H12.6667C14.5067 15.9978 15.9978 14.5067 16 12.6667V6C15.9978 4.15996 14.5067 2.66887 12.6667 2.66667ZM6.184 1.59333C6.31054 1.42995 6.50534 1.33402 6.712 1.33333H9.288C9.49461 1.33422 9.68933 1.43011 9.816 1.59333L10.644 2.66667H5.356L6.184 1.59333ZM14.6667 12.6667C14.6667 13.7712 13.7712 14.6667 12.6667 14.6667H3.33333C2.22876 14.6667 1.33333 13.7712 1.33333 12.6667V6C1.33333 4.89543 2.22876 4 3.33333 4H12.6667C13.7712 4 14.6667 4.89543 14.6667 6V12.6667Z"
        fill={`${props?.color || '#CCCCCC'}`}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 5.3335C5.79086 5.3335 4 7.12436 4 9.3335C4 11.5426 5.79086 13.3335 8 13.3335C10.2091 13.3335 12 11.5426 12 9.3335C11.9978 7.12527 10.2082 5.3357 8 5.3335ZM8 12.0002C6.52724 12.0002 5.33333 10.8063 5.33333 9.3335C5.33333 7.86074 6.52724 6.66683 8 6.66683C9.47276 6.66683 10.6667 7.86074 10.6667 9.3335C10.6667 10.0407 10.3857 10.719 9.88562 11.2191C9.38552 11.7192 8.70724 12.0002 8 12.0002Z"
        fill={`${props?.color || '#CCCCCC'}`}
      />
    </svg>
  );
};

export default IconCamera;
