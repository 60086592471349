import * as React from 'react';
const APDisableSelectTogglerIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="none" {...props}>
    <g opacity=".3">
      <circle cx="11" cy="11" r="10.5" stroke="#1C78AD" />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M15.136 7.488a.818.818 0 0 1 1.328.955l-.056.078-5.428 6.707a.818.818 0 0 1-1.148.124l-.072-.065-3.6-3.663a.822.822 0 0 1 .01-1.16.818.818 0 0 1 1.081-.06l.077.069 2.957 3.008 4.851-5.993Z"
        clipRule="evenodd"
      />
    </g>
  </svg>
);
export default APDisableSelectTogglerIcon;
