import {SetStateAction, Dispatch} from 'react';
import {IActionResults} from '../../../model/ActionResults';
import {
  AccountUsage,
  InvoicePaymentAllocationStatus,
  PaymentMethodTypes,
  PaymentProviderName,
  PaymentStatusType,
  VerificationStatus,
} from '../../../model/constants/Constants';
import {IPaymentBatch, ISubmitBatchPaymentResponse} from '../../../model/payment-batch/PaymentBatch';
import {PaymentAuthoriseResponse} from '../../../model/payment/PaymentAuthority';
import {ICreditCardT, ITenantSupplierPaymentMethod} from '../../../model/payment/PaymentMethod';
import {IMerchantProcessingFee, MerchantSurcharge} from '../../../model/payment/PaymentT';
import {ISupplierPaymentResponse} from '../../../model/payment/SupplierPaymentResponse';
import {usePaymentBatchAPI} from '../../../services/usePaymentBatchAPI';
import useSpendaPaymentServicesAPI, {
  IAccountResponse,
  IAccountResponseWithFee,
  INewCreditCardAccountRequest,
  INewCreditCardAccountResponse,
  UpdateAccountRequest,
} from '../../../services/useSpendaPaymentServicesAPI';
import {ISubmitPayment} from './PaymentWidget';

interface IPaymentFromAP {
  paymentBatch?: IPaymentBatch;
  setBatchDetail?: Dispatch<SetStateAction<IPaymentBatch | undefined>>;
  paymentAuth72488?: boolean;
  airplus79131?: boolean;
  fees88078?: boolean;
}

export const usePaymentsFromAP = (props: IPaymentFromAP) => {
  const {paymentBatch, setBatchDetail, fees88078} = props;
  const {
    saveCreditCard: saveBPSPCreditCardAPI,
    deleteBankAccount: deleteSPSPaymentAccount,
    getAPMerchantProcessingFee: getSPSMerchantProcessingFee,
    getAccount: getSPSAccountAPI,
    initiateVerification: initiateVerificationAPI,
    verify: codeVerificationAPI,
    retryVerification: onRetryVerificationAPI,
    updateAccountDetails: updateAccountDetailsAPI,
    getAccounts: getAccountsAPI,
    getAccountsWithFee: getAccountWithFeesAPI,
  } = useSpendaPaymentServicesAPI();

  const {
    updatePaymentBatch: updatePaymentBatchAPI,
    submitPaymentBatch: submitPaymentBatchAPI,
    checkBatchPaymentStatus: checkBatchPaymentStatusAPI,
    getPaymentBatch,
    authorisePayment: authorisePaymentAPI,
  } = usePaymentBatchAPI();

  const saveCreditCard: (
    data: ICreditCardT,
    isBPSPMerchant?: boolean,
  ) => Promise<ITenantSupplierPaymentMethod | INewCreditCardAccountResponse | undefined> = async (
    data: ICreditCardT,
  ) => {
    if (
      data.CardType &&
      [PaymentMethodTypes.Visa, PaymentMethodTypes.Mastercard].some(pm => pm.toString() === data.CardType)
    ) {
      // Call BPSP endpoint to save card
      // TODO: SaveCard for BPSP has different request payload than Fiserv
      const bpspres = await saveBPSPCreditCardAPI(PaymentProviderName.Spenda_Payment_Services, {
        cardType: data.CardType,
        cardNumber: data.CardNumber,
        cardholderName: data.CardHolderName,
        expiry: data.ExpiryMMYY,
        ccv: data.CardVerificationNumber,
        isInvigoDefault: data.IsInvigoDefault,
      } as INewCreditCardAccountRequest);

      return bpspres;
    }
    return;
  };

  const removeCreditCard: (paymentOption: ITenantSupplierPaymentMethod) => Promise<boolean | undefined> = async (
    paymentOption: ITenantSupplierPaymentMethod,
  ) => {
    if (!paymentOption.PaymentAccountGUID) {
      return false;
    }

    return deleteSPSPaymentAccount(PaymentProviderName.Spenda_Payment_Services, paymentOption.PaymentAccountGUID);
  };

  const getSurcharge: (
    ccType?: PaymentProviderName,
    paymentAccountGUID?: string,
    paymentAmount?: number,
  ) => Promise<MerchantSurcharge | IMerchantProcessingFee | undefined> = async (
    ccType?: PaymentProviderName,
    paymentAccountGUID?: string,
    paymentAmount?: number,
  ) => {
    if (!paymentAccountGUID || !paymentAmount) {
      return;
    }

    return getSPSMerchantProcessingFee(ccType || '', paymentAccountGUID, paymentAmount);
  };

  const submitPayment = async (submitPaymentPayload: ISubmitPayment) => {
    const {pm, authorisationToken, airPlusDbiData} = submitPaymentPayload;
    if (!paymentBatch) {
      return Promise.reject(`Issue with the Batch`);
    }

    if (!pm) {
      // There should always be at least one selected payment method at this point
      return Promise.reject(`Payment method hasn't been selected`);
    }

    if (!pm.PaymentAccountGUID) {
      // Payment method should have PaymentAccountGUID
      return Promise.reject(`Can't Process Payment with selected payment method`);
    }

    let payload = {};

    try {
      if (props.paymentAuth72488) {
        payload = {...payload, authorisationToken};
      }
      if (props.airplus79131 && pm.PaymentMethod === PaymentMethodTypes.Airplus && airPlusDbiData !== undefined)
        payload = {
          ...payload,
          metadata: {
            airplusDetails: [
              {
                paymentAccountGUID: pm.PaymentAccountGUID,

                dbiFields: airPlusDbiData,
              },
            ],
          },
        };

      const res = await submitPaymentBatchAPI(paymentBatch.id, payload);
      const formatedRes = formatPaymentResponse(res);

      return formatedRes;
    } catch (e) {
      return Promise.reject(`Some Error occured`);
    }
  };

  const fetchSPSAccount: (accountGUI: string) => Promise<IAccountResponse> = async (accountGUID: string) => {
    return getSPSAccountAPI(PaymentProviderName.Spenda_Payment_Services, accountGUID);
  };

  const initiateVerification: (accountGUID: string) => Promise<IAccountResponse> = (accountGUID: string) => {
    return initiateVerificationAPI(PaymentProviderName.Spenda_Payment_Services, accountGUID);
  };

  const onVerifyCode: (code: string, accountGUID: string) => Promise<IAccountResponse> = (
    code: string,
    accountGUID: string,
  ) => {
    return codeVerificationAPI(PaymentProviderName.Spenda_Payment_Services, accountGUID, {verificationCode: code});
  };

  const onRetryVerification: (accountGUID: string) => Promise<IAccountResponse> = (accountGUID: string) => {
    return onRetryVerificationAPI(PaymentProviderName.Spenda_Payment_Services, accountGUID);
  };

  const updateAccountDetails: (accountGUID: string, req: UpdateAccountRequest) => Promise<any> = (
    accountGUID: string,
    req: UpdateAccountRequest,
  ) => {
    return updateAccountDetailsAPI(PaymentProviderName.Spenda_Payment_Services, accountGUID, req);
  };

  const getPaymentMethods: () => Promise<ITenantSupplierPaymentMethod[]> = async () => {
    let paymentMethods: IAccountResponseWithFee[] | IAccountResponse[];
    if (fees88078) {
      paymentMethods = await getAccountWithFeesAPI(PaymentProviderName.Spenda_Payment_Services, {
        paymentAmount: paymentBatch?.paymentAmount || 0,
        accountUsage: AccountUsage.BUYER,
      });
    } else {
      paymentMethods = await getAccountsAPI(PaymentProviderName.Spenda_Payment_Services);
    }

    const acceptedCardTypes = [
      PaymentMethodTypes.Visa,
      PaymentMethodTypes.Mastercard,
      PaymentMethodTypes.BankTransfer,
      PaymentMethodTypes.Airplus,
    ];
    const filteredPaymentMethods = paymentMethods?.filter(p =>
      acceptedCardTypes.includes(p.paymentMethod as PaymentMethodTypes),
    );

    const convertedPaymentMethods = filteredPaymentMethods?.map((p, i) => {
      const {
        bankDetails,
        cardDetails,
        friendlyName,
        isInvigoDefault,
        accountGUID,
        verificationStatus,
        paymentMethod,
        retriesLeft,
        payLaterDetails,
        virtualCard,
        fees,
        buyerTotalAmount,
        paymentAmount,
      } = p;
      return {
        BankAccountNumber: bankDetails?.bankAccountNumber,
        BankBSB: bankDetails?.bankBSB,
        BankName: bankDetails?.bankName,
        CardHolderName: cardDetails?.accountHolderName,
        CreditAvailable: payLaterDetails?.creditAvailable,
        DefaultRepaymentMethodLast4: payLaterDetails?.defaultRepaymentMethodDescription?.slice(-4),
        DefaultRepaymentMethodType: payLaterDetails?.defaultRepaymentMethodType,
        Expiry: cardDetails?.expiry,
        FriendlyName: friendlyName,
        FundingRate: payLaterDetails?.fundingRate,
        IsInvigoDefault: isInvigoDefault,
        Last4: virtualCard?.last4 ? virtualCard?.last4 : cardDetails?.last4,
        PaymentAccountGUID: accountGUID,
        PaymentAccountVerificationStatus: verificationStatus,
        PaymentMethod: paymentMethod,
        RetriesLeft: retriesLeft,
        TradingTermDays: payLaterDetails?.tradingTermDays,
        //TODO: Look for some better approach
        SupplierPaymentOptionID: verificationStatus === VerificationStatus.VERIFIED ? i + 1 : -100 - i,

        // New Fee Response
        Fees: fees88078 ? fees : undefined,
        PaymentAmount: fees88078 ? paymentAmount : undefined,
        TotalAmount: fees88078 ? buyerTotalAmount : undefined,
      };
    }) as ITenantSupplierPaymentMethod[];

    // Make the selected Payment for Batch to Default
    const selectedPaymentMethodForBatch = paymentBatch?.debits?.[0]?.paymentAccountGUID;
    if (selectedPaymentMethodForBatch) {
      const index = convertedPaymentMethods.findIndex(p => p.PaymentAccountGUID === selectedPaymentMethodForBatch);

      if (index > -1) {
        const tempPaymentMethod = convertedPaymentMethods[0];
        convertedPaymentMethods[0] = convertedPaymentMethods[index];
        convertedPaymentMethods[index] = tempPaymentMethod;
      }
    }
    return convertedPaymentMethods || [];
  };

  const updatePaymentBatch = async (
    paymentAccountGUID: string,
    merchantProcessingFee: IMerchantProcessingFee,
    pm?: ITenantSupplierPaymentMethod,
  ) => {
    if (!paymentBatch) {
      return;
    }

    const fee = pm?.Fees?.find(f => f?.feeName === 'Buyer');

    const payload = {
      debits: [
        {
          paymentAccountGUID,
          payerFeeAmount: fees88078 ? fee?.buyerFeeAmount : merchantProcessingFee.PayerFeeAmount,
          payerFeeDescription: fees88078 ? fee?.buyerFeeDescription : merchantProcessingFee.PayerFeeDescription,
        },
      ],
    } as Partial<IPaymentBatch>;

    const updatedBatchDetail = await updatePaymentBatchAPI(paymentBatch.id, payload);
    if (setBatchDetail) {
      setBatchDetail(updatedBatchDetail);
    }
    // TODO Priyam: Remove return statement if not required
    return updatedBatchDetail;
  };

  const checkBatchPaymentStatus = async (txGuid?: string) => {
    if (!paymentBatch && !txGuid) {
      return Promise.reject(`Some error occured`);
    }

    const transctionGUID = txGuid ?? paymentBatch?.debits?.[0]?.transactionGUID;

    const res = await checkBatchPaymentStatusAPI(transctionGUID);
    const formatedRes = formatPaymentResponse(res);
    return Promise.resolve(formatedRes);
  };

  const formatPaymentResponse = (res: ISubmitBatchPaymentResponse): IActionResults<ISupplierPaymentResponse> => {
    let status = res.value;

    if (status === PaymentStatusType.Paid) {
      status = InvoicePaymentAllocationStatus.Complete;
    }

    const formatRes = {
      IsSuccess: true,
      Messages: [],
      Value: {
        PaymentStatus: status,
        WorkflowStatus: status,
      },
    } as IActionResults<ISupplierPaymentResponse>;
    return formatRes;
  };

  const authorisePayment = async () => {
    if (!paymentBatch?.id) {
      return Promise.reject(`Issue with the Batch`);
    }
    const {value} = await authorisePaymentAPI(paymentBatch.id);
    const formattedRes: PaymentAuthoriseResponse = {
      authorisationID: value.authorisationID,
      authorisationStatus: value.authorisationStatusForUser,
      token: value.authorisationToken,
    };
    return formattedRes;
  };

  return {
    saveCreditCard,
    removeCreditCard,
    getSurcharge,
    submitPayment,
    fetchSPSAccount,
    initiateVerification,
    onVerifyCode,
    onRetryVerification,
    updateAccountDetails,
    getPaymentMethods,
    checkBatchPaymentStatus,
    getPaymentBatch,
    authorisePayment,
    updatePaymentBatch,
  };
};
