import React from 'react';

export const IconStatementsDocument = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9199 14C16.9199 14.5522 16.4722 15 15.9199 15H7.91992C7.36764 15 6.91992 14.5522 6.91992 14C6.91992 13.4477 7.36764 13 7.91992 13H15.9199C16.4722 13 16.9199 13.4477 16.9199 14ZM12.9199 17H7.91992C7.36764 17 6.91992 17.4477 6.91992 18C6.91992 18.5522 7.36764 19 7.91992 19H12.9199C13.4722 19 13.9199 18.5522 13.9199 18C13.9199 17.4477 13.4722 17 12.9199 17ZM21.9199 10.485V19C21.9166 21.7601 19.68 23.9967 16.9199 24H6.91992C4.15987 23.9967 1.92323 21.7601 1.91992 19V5.00002C1.92323 2.23997 4.15987 0.0033292 6.91992 2.30802e-05H11.4349C13.2924 -0.00475775 15.0747 0.733362 16.3849 2.05002L19.8689 5.53602C21.1864 6.84539 21.925 8.62758 21.9199 10.485ZM14.9709 3.46395C14.6562 3.15911 14.3029 2.89687 13.9199 2.68395V6.99995C13.9199 7.55224 14.3676 7.99995 14.9199 7.99995H19.2359C19.0229 7.61714 18.7603 7.2641 18.4549 6.94995L14.9709 3.46395ZM19.9199 10.485C19.9199 10.32 19.8879 10.162 19.8729 9.99995H14.9199C13.2631 9.99995 11.9199 8.65681 11.9199 6.99995V2.04695C11.7579 2.03195 11.5989 1.99995 11.4349 1.99995H6.91992C5.26307 1.99995 3.91992 3.3431 3.91992 4.99995V19C3.91992 20.6568 5.26307 22 6.91992 22H16.9199C18.5768 22 19.9199 20.6568 19.9199 19V10.485Z"
        fill="#1C78AD"
      />
    </svg>
  );
};
