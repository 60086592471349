import React, {FunctionComponent, useEffect, useState} from 'react';
import {SwitchTransition, CSSTransition} from 'react-transition-group';
import {Link} from 'react-router-dom';
import {css} from 'glamor';
import moment from 'moment';
import {groupBy} from 'lodash';

import {
  AUTH_BUYING_AP_APPROVE_REQUESTS_BATCH_VIEW,
  AUTH_BUYING_AP_APPROVE_REQUESTS_ABA_BATCH_VIEW,
} from '../../routes/AccountsPayableRoutes';
import {IUpcomingPaymentBatch} from '../../model/payment-batch/PaymentBatch';
import LoadingIndicator from '../../components/ui/LoadingIndicator';
import {usePaymentBatchAPI} from '../../services/usePaymentBatchAPI';
import {PriceFormat} from '../../utils/formatter';
import {makeStyles} from '@material-ui/core';
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import KeyboardArrowLeftRoundedIcon from '@material-ui/icons/KeyboardArrowLeftRounded';

const useBatchTimeline = makeStyles({
  dateColumn: {
    width: '90px',
  },
  timelineHeadings: {
    '& h3': {
      fontSize: '14px',
      color: '#BBBBBB',
      '&:first-child': {
        width: '90px',
      },
      '&:last-child': {
        width: 'calc(100% - 90px)',
        borderLeft: '1px solid #D8D8D8',
      },
    },
  },
  dateCol: {
    '& h2': {
      fontSize: '22px',
      color: '#333333',
      lineHeight: '15px',
      '& span': {
        color: '#707070',
      },
    },
    '& .timelineDot': {
      width: '10px',
      height: '10px',
      borderRadius: '50%',
      border: '1px solid #CCCCCC',
      background: '#F3F3F3',
      right: '-26px',
      top: '50%',
    },
  },
  eventsColumn: {
    width: 'calc(100% - 90px)',
  },
  eventCol: {
    background: '#CCCCCC30',
    borderRadius: '6px',
  },
  collapiseEventCol: {
    height: '56px',
    background: '#1C78AD',
    borderRadius: '6px',
    fontSize: '14px',
    color: '#fff',
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      width: 'calc(100% - 10px)',
      height: '5px',
      bottom: '-5px',
      left: '5px',
      background: '#D3E5EF',
      borderRadius: '6px',
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      width: 'calc(100% - 20px)',
      height: '10px',
      bottom: '-10px',
      left: '10px',
      background: '#D3E5EF50',
      borderRadius: '6px',
    },
  },
  batchInfo: {
    fontSize: '14px',
    '& span': {
      fontSize: '10px',
    },
  },
  batchAmount: {
    color: '#3C9F78',
    fontSize: '14px',
  },
  scrollTimeline: {
    maxHeight: 'calc(100vh - 16em)',
  },
  'fade-enter': {
    opacity: 0,
    transform: 'translateX(-100%)',
  },
  'fade-enter-active': {
    opacity: 1,
    transform: 'translateX(0%)',
    transition: 'opacity 500ms, transform 500ms',
  },
  'fade-exit': {
    opacity: 1,
    transform: 'translateX(0%)',
  },
  'fade-exit-active': {
    opacity: 0,
    transform: 'translateX(100%)',
    transition: 'opacity 500ms, transform 500ms',
  },
});

interface IUpcomingPaymentBatchFilter {
  StartDate: string;
  EndDate: string;
}

export interface IBatchTimeline {}

const panelRightBody = css({
  borderRadius: '6px',
});

export const BatchTimeline: FunctionComponent<IBatchTimeline> = () => {
  const classes = useBatchTimeline();
  const [batchCollapsed, setBatchCollapsed] = useState<{[x: string]: boolean}>({});
  const [upcomingPaymentBatches, setUpcomingPaymentBatches] = useState<{[x: string]: IUpcomingPaymentBatch[]}>({});
  const [filter, setFilter] = useState<IUpcomingPaymentBatchFilter>({
    StartDate: moment().startOf('month').format('YYYY-MM-DD'),
    EndDate: moment().endOf('month').format('YYYY-MM-DD'),
  });

  const {getUpcomingPaymentBatches, isLoading} = usePaymentBatchAPI();

  useEffect(() => {
    fetchUpcomingBatches(filter);
  }, []);

  const fetchUpcomingBatches = async (_filter: IUpcomingPaymentBatchFilter) => {
    const _upcomingBatches = await getUpcomingPaymentBatches(_filter);
    const _batches = groupBy(_upcomingBatches, batch => batch.dueDate);
    const _batchCollaped = {} as {[x: string]: boolean};
    Object.keys(_batches).forEach(v => (_batchCollaped[v] = true));
    setBatchCollapsed(_batchCollaped);
    setUpcomingPaymentBatches(_batches);
  };

  const batchCollapsedHandle = (key: string) => {
    setBatchCollapsed(_bc => ({..._bc, [key]: !_bc[key]}));
  };

  const handleCalendarButton = (action: number) => {
    const _filter = {
      StartDate: '',
      EndDate: '',
    } as IUpcomingPaymentBatchFilter;
    if (action === 1) {
      _filter.StartDate = moment(filter.StartDate, 'YYYY-MM-DD').add(1, 'months').startOf('month').format('YYYY-MM-DD');
      _filter.EndDate = moment(filter.EndDate, 'YYYY-MM-DD').add(1, 'months').endOf('month').format('YYYY-MM-DD');
    } else {
      _filter.StartDate = moment(filter.StartDate, 'YYYY-MM-DD')
        .subtract(1, 'months')
        .startOf('month')
        .format('YYYY-MM-DD');
      _filter.EndDate = moment(filter.EndDate, 'YYYY-MM-DD').subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
    }
    fetchUpcomingBatches(_filter);
    setFilter(_filter);
  };

  const renderUpcomingBatch = (key: string, value: IUpcomingPaymentBatch[]): React.ReactElement => {
    if (value.length === 1) {
      const date = moment(key);
      const batch = value[0];
      return (
        <div className="flex w-full">
          <div className={`h-full p-5 text-center ${classes.dateColumn}`}>
            <div className={`relative ${classes.dateCol}`}>
              <h2 className="pt-4 font-bold">
                {date.format('DD')} <span className="text-xs font-normal">{date.format('MMMM')}</span>
              </h2>
              <span className={`timelineDot absolute`}></span>
            </div>
          </div>
          <Link
            data-autoid={`lnkTimelineBatch-${batch.refNumber}`}
            to={
              batch.abaFileID
                ? AUTH_BUYING_AP_APPROVE_REQUESTS_ABA_BATCH_VIEW.replace(/:batchID/g, batch.accountsPayableBatchID)
                : AUTH_BUYING_AP_APPROVE_REQUESTS_BATCH_VIEW.replace(/:batchID/g, batch.accountsPayableBatchID)
            }
            className={`h-full p-5 px-7 pb-0 ${classes.eventsColumn}`}
          >
            <div className={`mb-3 flex items-center justify-between px-4 py-3 ${classes.eventCol}`}>
              <p className={`font-semibold text-spenda-primarytext ${classes.batchInfo}`}>
                {batch.name} <span className="block">Batch ID- {batch.refNumber}</span>
              </p>
              <p className={`font-semibold text-spenda-primarytext ${classes.batchAmount}`}>
                {PriceFormat(batch.paymentAmount)}
              </p>
            </div>
          </Link>
        </div>
      );
    } else if (value.length > 1) {
      const date = moment(key);
      const batchSum = value.reduce((acc, curr) => acc + curr.paymentAmount, 0);
      return (
        <div className="flex w-full">
          <div className={`h-full p-5 text-center ${classes.dateColumn}`}>
            <div className={`relative ${classes.dateCol}`}>
              <h2 className="pt-4 font-bold">
                {date.format('DD')} <span className="text-xs font-normal">{date.format('MMMM')}</span>
              </h2>
              <span className={`timelineDot absolute`}></span>
            </div>
          </div>
          <div className={`h-full p-5 px-7 pb-0 ${classes.eventsColumn}`}>
            {batchCollapsed[key] ? (
              <div
                data-autoid={`lnkTimelineBatch${key}`}
                className={`mb-3 flex cursor-pointer items-center justify-between px-4 py-3 ${classes.collapiseEventCol}`}
                onClick={() => batchCollapsedHandle(key)}
              >
                <p className={`font-semibold`}>{value.length} batches</p>
                <span className={`font-semibold`}>{PriceFormat(batchSum)}</span>
              </div>
            ) : (
              <div>
                {value.map((batch, index) => (
                  <Link
                    key={index}
                    data-autoid={`lnkTimelineBatch${index}${key}`}
                    to={
                      batch.abaFileID
                        ? AUTH_BUYING_AP_APPROVE_REQUESTS_ABA_BATCH_VIEW.replace(
                            /:batchID/g,
                            batch.accountsPayableBatchID,
                          )
                        : AUTH_BUYING_AP_APPROVE_REQUESTS_BATCH_VIEW.replace(/:batchID/g, batch.accountsPayableBatchID)
                    }
                    className={`mb-3 flex cursor-pointer items-center justify-between px-4 py-3 ${classes.eventCol}`}
                  >
                    <p className={`font-semibold text-spenda-primarytext ${classes.batchInfo}`}>
                      {batch.name} <span className="block">Batch ID- {batch.refNumber}</span>
                    </p>
                    <p className={`font-semibold text-spenda-primarytext ${classes.batchAmount}`}>
                      {PriceFormat(batch.paymentAmount)}
                    </p>
                  </Link>
                ))}
              </div>
            )}
          </div>
        </div>
      );
    }
    return <></>;
  };

  return (
    <div className={`${panelRightBody} flex h-full flex-col bg-white`}>
      <div className="font-poppins">
        <div
          className={`flex flex-col justify-between px-5 py-4 pb-3 ${css({
            borderBottom: '1px solid #D8D8D8',
          })}`}
        >
          <span className="text-xl text-spenda-primarytext">Upcoming batch payments</span>
        </div>
      </div>
      <div className="relative h-full font-poppins">
        <div className="flex justify-between">
          <div className={`flex ${classes.timelineHeadings}`}>
            <h3 className={`p-5 pb-2 font-semibold`}>DATE</h3>
            <h3 className={`p-5 pb-2 font-semibold`}>EVENTS</h3>
          </div>
          <div className="p-5">
            <button
              className="rounded-full"
              data-autoid="btnCalendarMonthPrevious"
              onClick={() => handleCalendarButton(-1)}
            >
              <KeyboardArrowLeftRoundedIcon style={{color: '#1C78AD'}} />
            </button>
            <SwitchTransition mode="out-in">
              <CSSTransition
                timeout={200}
                classNames={{
                  enter: classes['fade-enter'],
                  enterActive: classes['fade-enter-active'],
                  exit: classes['fade-exit'],
                  exitActive: classes['fade-exit-active'],
                }}
                key={moment(filter.StartDate, 'YYYY-MM-DD').format('MMM YY')}
                addEndListener={(node, done) => {
                  node.addEventListener('transitionend', done, false);
                }}
              >
                <span className="px-0.5 text-base font-semibold text-primary">
                  {moment(filter.StartDate, 'YYYY-MM-DD').format('MMM YY')}
                </span>
              </CSSTransition>
            </SwitchTransition>
            <button className="rounded-full" data-autoid="btnCalendarMonthNext" onClick={() => handleCalendarButton(1)}>
              <KeyboardArrowRightRoundedIcon style={{color: '#1C78AD'}} />
            </button>
          </div>
        </div>
        {isLoading ? (
          <LoadingIndicator isLoading={true} size="md" color="#1C78AD" />
        ) : (
          <div className={`mainEventWrap h-full w-full overflow-y-auto ${classes.scrollTimeline}`}>
            {Object.keys(upcomingPaymentBatches).length ? (
              Object.entries(upcomingPaymentBatches).map(([key, value]: [string, IUpcomingPaymentBatch[]]) =>
                renderUpcomingBatch(key, value),
              )
            ) : (
              <div className="flex h-full items-center justify-center px-3 py-8 font-poppins text-spenda-primarytext">
                <p className="font-semibold">No upcoming batches</p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
