import * as React from 'react';
export const CollapseUpIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none" {...props}>
    <path
      className="fill-primary"
      fillRule="evenodd"
      d="M.834 5.523a.917.917 0 0 0 1.302 1.293l4.198-4.208a.917.917 0 0 1 1.302 0l4.198 4.208a.917.917 0 1 0 1.302-1.293L8.928 1.316a2.75 2.75 0 0 0-3.886 0L.834 5.523Zm0 6.417a.917.917 0 0 0 1.302 1.293l4.849-4.85 4.85 4.85a.916.916 0 1 0 1.3-1.293l-5.5-5.5a.917.917 0 0 0-1.3 0l-5.5 5.5Z"
      clipRule="evenodd"
    />
  </svg>
);
