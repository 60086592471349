import React, {useContext, useEffect, useState, useRef} from 'react';
import Skeleton from 'react-loading-skeleton';
import {DatTypes} from '../../model/constants/Constants';
import useMasterAdaptorAPI from '../../services/useMasterAdaptorAPI';
import {RefreshIcon} from '../../assets/svg/RefreshIcon';
import {IntegrationContext} from '../../context/IntegrationContext';
import {Button} from 'spenda-ui-react';

interface IRefreshDataProps {
  handleRefreshData?: () => void;
  customerId?: string;
  datTypeId: DatTypes;
  isAccountsPayable?: boolean;
  newOnly?: boolean;
  isCreditLine?: boolean;
  showOnlyRefreshIcon?: boolean;
  dataAutoID?: string;
  title?: string;
}

export const RefreshDataBtn: React.FC<IRefreshDataProps> = ({title, dataAutoID = 'btnARRefresh'}) => {
  const [isLoading, setIsLoading] = useState(false);
  const {isLoading: isAdaptorLoading} = useMasterAdaptorAPI();
  const {financialAdaptor} = useContext(IntegrationContext);

  const workflowStatusTimer = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    return () => {
      if (workflowStatusTimer.current) {
        clearInterval(workflowStatusTimer.current);
      }
    };
  }, []);

  const handleRefreshClick = async () => {
    if (!financialAdaptor?.Name || !financialAdaptor?.ID) return;
    setIsLoading(true);
    try {
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    } catch {
      setIsLoading(false);
    }
  };
  return (
    <>
      {isAdaptorLoading ? (
        <div className="flex">
          <Skeleton circle width={20} height={20} />
          <div className="mx-2 flex flex-col">
            <Skeleton width={50} height={20} />
          </div>
          <Skeleton circle width={20} height={20} />
        </div>
      ) : (
        <Button
          type="button"
          variant="outlined"
          color="primary"
          data-autoid={dataAutoID}
          onClick={() => !isLoading && handleRefreshClick()}
        >
          <div className="flex items-center gap-x-2">
            {title} <RefreshIcon height="14px" width="14px" className={`${isLoading ? 'animate-spin' : ''}`} />
          </div>
        </Button>
      )}
    </>
  );
};
