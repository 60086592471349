import React from 'react';
//Icon
export const IconTradeFinance = (props: {width?: string; height?: string}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 50 50`}
      width={props.width || '50'}
      height={props.height || '50'}
    >
      <g fill="none">
        <path
          fill="#7FC0A6"
          d="m30.83.83-5 6.67 5 6.67h18.34V.83H30.83Zm.84 8.34a1.67 1.67 0 1 1 0-3.34 1.67 1.67 0 0 1 0 3.34Z"
        />
        <path fill="#3C9F78" d="M49.17 3.83 34.3 14.17h14.87z" />
        <path fill="#E1C490" d="M13.33 20.83h23.33v20.83H13.33z" />
        <path fill="#7FC0A6" d="M22.5 20.83h5v5h-5z" />
        <path
          fill="#87CED9"
          d="M41.86 5.83H45V4.17h-1.67v-.84h-1.66v.86a1.86 1.86 0 0 0-.64 3.5l2.2 1.1a.2.2 0 0 1-.1.38H40v1.66h1.67v.84h1.66v-.86a1.86 1.86 0 0 0 .64-3.5l-2.2-1.1a.2.2 0 0 1 .09-.38Z"
        />
        <path
          fill="#4398D1"
          d="M40.42 25.83h-3.75v-1.66h3.75a2.92 2.92 0 0 0 0-5.84H22.5a5.83 5.83 0 0 1 0-11.66H30v1.66h-7.5a4.17 4.17 0 0 0 0 8.34h17.92a4.58 4.58 0 1 1 0 9.16Z"
        />
        <path fill="#D2A656" d="M13.33 41.25v.42h23.34V23.75z" />
        <path
          fill="#E1C490"
          d="M32.5 40.83h-1.67a2.92 2.92 0 1 0 0-5.83H22.5a11.76 11.76 0 0 0-11.67 0v14.17H32.5l15.12-9.07a3.2 3.2 0 0 0-1.64-5.93h-.18a3.2 3.2 0 0 0-1.5.37l-11.8 6.3Z"
        />
        <path fill="#74B9D3" d="M0 35h10.83v14.17H0z" />
        <path fill="#F5B789" d="M32.5 41.67h-9.17a.83.83 0 0 1 0-1.67h9.17a.83.83 0 0 1 0 1.67Z" />
        <path fill="#2C95BC" d="M0 40.83h10.83v8.33H0z" />
        <circle cx="5.83" cy="44.17" r="1.67" fill="#18588C" />
        <path
          fill="#333"
          d="M8.33 44.17a2.5 2.5 0 1 0-5 0 2.5 2.5 0 0 0 5 0Zm-3.33 0a.83.83 0 1 1 1.67 0 .83.83 0 0 1-1.67 0Z"
        />
        <path
          fill="#333"
          d="M49.17 0H30.83c-.26 0-.5.12-.66.33l-4.75 6.34H22.5a5.83 5.83 0 0 0 0 11.66h17.92a2.92 2.92 0 0 1 0 5.84H37.5v-3.34a.83.83 0 0 0-.83-.83H13.33a.83.83 0 0 0-.83.83v12.5c-.65.23-1.27.5-1.87.84H0v1.66h10v12.5H0V50h31.58c.75 0 1.5-.2 2.14-.6l14.32-8.59a4.03 4.03 0 0 0-2.07-7.48h-.17c-.66 0-1.31.17-1.9.48l-6.4 3.41V25.83h2.92a4.58 4.58 0 1 0 0-9.16H22.5a4.17 4.17 0 0 1 0-8.34h2.92l4.75 6.34c.15.2.4.33.66.33h18.34c.46 0 .83-.37.83-.83V.83a.83.83 0 0 0-.83-.83ZM23.33 21.67h3.34V25h-3.34v-3.33Zm-9.16 0h7.5v4.16c0 .46.37.84.83.84h5c.46 0 .83-.38.83-.84v-4.16h7.5V38.1l-1.36.73a3.75 3.75 0 0 0-3.64-4.67h-8.11a12.65 12.65 0 0 0-8.55-1.3v-11.2Zm30.52 13.6c.34-.17.72-.27 1.1-.27h.18a2.36 2.36 0 0 1 1.22 4.38l-14.33 8.6a2.5 2.5 0 0 1-1.28.35H11.67V35.5c3.29-1.68 7.2-1.6 10.41.23.13.07.27.1.42.1h8.33a2.08 2.08 0 1 1 0 4.17h-7.5a.83.83 0 0 0 0 1.67h9.17c.14 0 .27-.04.4-.1l11.79-6.3Zm3.64-21.94H31.25l-3.75-5h1.82a2.5 2.5 0 1 0 0-1.66H27.5l3.75-5h17.08v11.66ZM30.83 7.5a.83.83 0 1 1 1.67 0 .83.83 0 0 1-1.67 0Z"
        />
        <path
          fill="#333"
          d="M41.67 11.67h1.66v-.86a1.86 1.86 0 0 0 .64-3.5l-2.2-1.1a.2.2 0 0 1 .09-.38H45V4.17h-1.67v-.84h-1.66v.86a1.86 1.86 0 0 0-.64 3.5l2.2 1.1a.2.2 0 0 1-.1.38H40v1.66h1.67v.84Z"
        />
      </g>
    </svg>
  );
};
