import React from 'react';

export const UploadColour = () => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8414 3.00809L12.8333 21.0191C12.8333 21.6634 13.3556 22.1858 13.9999 22.1858C14.6443 22.1858 15.1666 21.6634 15.1666 21.0191L15.1748 3.02792L18.5721 6.42642C19.0277 6.88187 19.7662 6.88187 20.2218 6.42642C20.6772 5.97084 20.6772 5.23234 20.2218 4.77675L16.4756 1.02592C15.8192 0.369057 14.9286 0 13.9999 0C13.0713 0 12.1807 0.369057 11.5243 1.02592L7.77811 4.77325C7.32266 5.22884 7.32266 5.96734 7.77811 6.42292C8.23369 6.87837 8.97219 6.87837 9.42777 6.42292L12.8414 3.00809Z"
        className='fill-primary'
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.6667 19.8337V24.5003C25.6667 25.1447 25.1443 25.667 24.5 25.667H3.5C2.85567 25.667 2.33333 25.1447 2.33333 24.5003V19.8337C2.33333 19.1893 1.811 18.667 1.16667 18.667C0.522334 18.667 0 19.1893 0 19.8337V24.5003C0 26.4333 1.567 28.0003 3.5 28.0003H24.5C26.433 28.0003 28 26.4333 28 24.5003V19.8337C28 19.1893 27.4777 18.667 26.8333 18.667C26.189 18.667 25.6667 19.1893 25.6667 19.8337Z"
         className='fill-primary'
      />
    </svg>
  );
};
