import React, {SetStateAction, useState} from 'react';
import {Button, Checkbox, Chip, Typography} from 'spenda-ui-react';
import IconQuotesDivider from '../../../assets/svg/quotes-management/IconQuotesDivider';
import {SpendaNoTagLogo} from '../../../assets/svg/SpendaNoTagLogo';
import SecurityCode from '../../../components/inputs/SecurityCode';
import useQuotesAPI from '../../../services/useQuotesAPI';
import {IActiveSession} from '../../../model/quotes/quotes';
import PreviewAttachment from '../PreviewAttachment';

interface CustomerQuotesAuthorizeProps {
  onClose: React.Dispatch<SetStateAction<boolean>>;
  handleResendCode: (acceptAuthorise: boolean, sessionTimeout: boolean) => void;
  inviteCode: string;
  fromRevealClick?: boolean;
  quoteName?: string[];
  abn?: string;
  supplierName?: string;
  logo?: string;
  setActiveSession: React.Dispatch<SetStateAction<IActiveSession>>;
  getQuoteDetails: (inviteCode: string, showExistingStatusDialog: boolean) => void;
  selectedQuoteGUID: string;
  inviteSource: string;
  quoteTermsMediaFileURI?: string;
}

export const CustomerQuotesAuthorize = (props: CustomerQuotesAuthorizeProps) => {
  const [securityCode, setSecurityCode] = useState<string>('');
  const [securityCodeErrorMsg, setSecuityCodeErrorMsg] = useState('');
  const [isTermsAgreed, setIsTermsAgreed] = useState<boolean>(false);
  const [isSending, setIsSending] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = React.useState({} as {uri: string; show: boolean; type: string});

  const {submitApprovalQuote, acceptQuoteByInviteCode} = useQuotesAPI();

  const onEnterVerificationCode = async () => {
    setSecuityCodeErrorMsg('');
    if (securityCode.trim().length < 6) {
      return;
    }
  };

  const handleViewQuote = async () => {
    // verify otp
    submitApprovalQuote(props.inviteCode, securityCode)
      .then(async response => {
        props.setActiveSession({...response, isAuthorised: true});
        if (props.fromRevealClick) {
          props.onClose(false);
        } else {
          await acceptQuoteByInviteCode(props.selectedQuoteGUID, props.inviteCode, response.approvalSessionGUID);
          await props.getQuoteDetails(props.inviteCode, true);
          props.onClose(false);
        }
      })
      .catch(error => {
        if (error && error?.length && error?.[0]?.message) {
          setSecuityCodeErrorMsg(error?.[0]?.message);
        }
      })
      .finally(() => setIsSending(false));
  };

  const handleChangeSecurityCode = (code: any) => {
    if (securityCodeErrorMsg) setSecuityCodeErrorMsg('');
    return setSecurityCode(code);
  };

  return (
    <div className="happyFlowBg flex h-[calc(100%-48px)] w-full items-center justify-center">
      <div className="flex w-full max-w-[750px] flex-col items-center justify-center text-black-800 md:flex-row">
        <div className="mb-7 flex w-[357px] flex-col items-center justify-center md:mb-0">
          <div className="w-full max-w-[160px] pt-2 text-center sm:max-w-max">
            {props?.logo ? (
              <img src={props?.logo} alt="Preview Logo" className={`mx-auto h-[100px] w-[100px]`} />
            ) : (
              <SpendaNoTagLogo height={'40px'} width={'100px'} className="mx-auto" />
            )}
          </div>
          {props.abn ? (
            <Typography className="mt-3">ABN: {props.abn}</Typography>
          ) : (
            <Typography className="mt-3">{props.supplierName}</Typography>
          )}
          <div className="mt-4 flex flex-col items-center justify-center gap-y-5 md:gap-y-3">
            <Typography>Has provided a quote for</Typography>
            {props?.quoteName?.map((name, index) => {
              return (
                <Chip
                  key={index}
                  value={name}
                  color="primary"
                  className="min-w-[230px] border border-spenda-sBlue p-2.5 text-center text-base text-black-900"
                ></Chip>
              );
            })}
          </div>
        </div>
        <IconQuotesDivider className="hidden md:block" />
        <div className="flex w-full max-w-[400px] flex-col items-center justify-center gap-2 md:max-w-[357px]">
          <Typography className="mb-5 text-center md:mb-9 md:max-w-[196px]" variant="small">
            {`Please enter the 6 digit code sent to you ending in ${props.inviteSource} to accept this quote.`}
          </Typography>
          <div className="mb-4 flex gap-2">
            <SecurityCode
              is2SA={true}
              onChange={code => handleChangeSecurityCode(code)}
              value={securityCode}
              onEnter={onEnterVerificationCode}
              newCodeDesign
              // below key to be used to show invalid code
              invalidCode={!!securityCodeErrorMsg}
            ></SecurityCode>
          </div>
          <Checkbox
            label={
              props?.quoteTermsMediaFileURI ? (
                <span
                  className="flex items-center border-b font-poppins text-sm font-medium text-primary"
                  onClick={() =>
                    setPreviewImage({
                      uri: props.quoteTermsMediaFileURI!,
                      show: true,
                      type: 'Document',
                    })
                  }
                >
                  I agree to terms & conditions
                </span>
              ) : (
                <a
                  href={'https://spenda.co/terms-conditions/'}
                  target="_blank"
                  className="flex items-center border-b font-poppins text-sm font-medium text-primary"
                >
                  I agree to terms & conditions
                </a>
              )
            }
            onChange={() => setIsTermsAgreed(!isTermsAgreed)}
            className="checked:border-primary checked:bg-primary"
            checked={isTermsAgreed}
          />
          <button
            type="button"
            className="cursor-pointer"
            onClick={() => props.handleResendCode(props.fromRevealClick ? false : true, false)}
            disabled={isSending}
          >
            <Typography className=" mb-3 font-semibold text-primary">Resend Code</Typography>
          </button>
          <Button
            variant="filled"
            color="primary"
            onClick={() => {
              setIsSending(true);
              handleViewQuote();
            }}
            disabled={securityCode.trim().length < 6 || !isTermsAgreed || isSending}
          >
            {props.fromRevealClick ? 'View Quote' : 'Accept Quote'}
          </Button>
        </div>
      </div>
      <div className="absolute bottom-0 flex h-12 w-[100vw] items-center justify-center bg-[#eeeeee]">
        {props?.logo ? (
          <img src={props?.logo} alt="Tenant Logo" className={`h-[32px]`} />
        ) : (
          <SpendaNoTagLogo height={'32px'} />
        )}
      </div>
      {previewImage.show && (
        <PreviewAttachment
          type={previewImage.type}
          imageUrl={previewImage.uri}
          setShow={value =>
            setPreviewImage({
              uri: '',
              show: value,
              type: '',
            })
          }
        />
      )}
    </div>
  );
};
