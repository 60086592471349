import React from 'react';
//Icon
const IconStatusFilter = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="20px"
      height="22px"
      viewBox="0 0 22 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>Icons/General/filter</title>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Icons/General/filter" transform="translate(-0.000000, 0.000000)" fill="#1C78AD">
          <path
            d="M12.9976039,24 C12.7812337,24 12.5707001,23.9298221 12.3976039,23.8 L8.39760394,20.8 C8.1457981,20.6111456 7.99760394,20.3147573 7.99760394,20 L7.99760394,14.38 L0.981603938,6.487 C-0.0361946625,5.33879483 -0.286158691,3.70049208 0.342927086,2.30101353 C0.972012864,0.90153499 2.36323496,0.000969059945 3.89760394,0 L18.0976039,0 C19.6318322,0.00179964982 21.022467,0.902956404 21.6508762,2.30258543 C22.2792855,3.70221446 22.0287038,5.34024906 21.0106039,6.488 L13.9976039,14.38 L13.9976039,23 C13.9976039,23.5522847 13.5498887,24 12.9976039,24 L12.9976039,24 Z M9.99760394,19.5 L11.9976039,21 L11.9976039,14 C11.9978058,13.7551576 12.0878267,13.5188973 12.2506039,13.336 L19.5186039,5.159 C20.013621,4.59950316 20.134835,3.8018492 19.8283721,3.12055695 C19.5219092,2.43926471 18.8446501,2.00078462 18.0976039,2 L3.89760394,2 C3.1509597,2.00113124 2.47419008,2.43946077 2.16781123,3.12035056 C1.86143239,3.80124035 1.98224543,4.59845682 2.47660394,5.158 L9.74560394,13.336 C9.90801864,13.5190442 9.99767765,13.7552885 9.99760394,14 L9.99760394,19.5 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default IconStatusFilter;
