import React, {useEffect, useState} from 'react';
import 'react-tabs/style/react-tabs.css';
import {ISearchSupplierStatementsFilter} from '../model/search-filters/SearchSupplierStatementsFilter';
import {PriceFormat} from '../utils/formatter';
import {SearchInputAndBucketsForm, SearchInputFormValues} from './form/InvoiceSearchForm';
import {
  RequestedListShortName,
  PurchaseInvoiceRequestedList,
  PurchaseInvoiceViewTypes,
} from '../model/constants/Constants';
import {ISearchPurchaseInvoicesFilter} from '../model/search-filters/SearchCustomerStatementsFilter';

export type SearchTrans = {
  onFreeTextSearch?: (event: any) => void | undefined;
  freeTextInput: string;
  searchFilter?: ISearchSupplierStatementsFilter;
  updateFilter?: (filter: ISearchSupplierStatementsFilter) => void;
  placeholder?: string;
};

export interface IActivityHeaderProps {
  isSearching?: boolean;
  searchFilter: ISearchSupplierStatementsFilter;
  updateFilter: (searchString: string) => void;
  onClickBucket: (x: any) => void;
  selected: string | number;
  OverdueRecords?: number;
  OverdueAmount?: number;
  DueRecords?: number;
  DueAmount?: number;
  CreditRecords?: number;
  CreditAmount?: number;
  isCustomerTx?: boolean;
  TotalRecords?: number;
  isInvoiceSelected?: boolean;
  isPaymentHistoryTab?: boolean;
  financialAdaptorName?: string;
}

export interface ISpendaPayActivityHeaderProps extends Omit<IActivityHeaderProps, 'searchFilter'> {
  searchFilter: ISearchPurchaseInvoicesFilter;
  isAccountsPayable?: boolean;
  purchaseInvoiceViewMode?: PurchaseInvoiceViewTypes;
  apRefreshData?: () => void;
  DebitNotesAmount?: number;
  isT2TPhaseTwo?: boolean;
}

export const AccountsPayableActivityHeader = (props: ISpendaPayActivityHeaderProps) => {
  const [isOverdueSelected, setIsOverdueSelected] = useState(true);
  const [isDueSoonSelected, setIsDueSoonSelected] = useState(true);

  useEffect(() => {
    if (props.selected === PurchaseInvoiceRequestedList.Payable) {
      resetDueAndOverDueSelection(true);
    } else if (props.selected === PurchaseInvoiceRequestedList.Overdue) {
      dueSoonBucket.isActive = false;
      setIsDueSoonSelected(false);
      overDueBucket.isActive = true;
      setIsOverdueSelected(true);
    } else if (props.selected === PurchaseInvoiceRequestedList.Due) {
      dueSoonBucket.isActive = true;
      setIsDueSoonSelected(true);
      overDueBucket.isActive = false;
      setIsOverdueSelected(false);
    }
  }, [props.selected]);

  const onSubmit = (values: SearchInputFormValues) => {
    resetDueAndOverDueSelection(!Boolean(values.searchText));
    props.updateFilter(values.searchText);
  };

  const overDueBucket = {
    id: PurchaseInvoiceRequestedList.Overdue, // Overdue
    color: 'apRed',
    description: `${props.OverdueRecords || 0} Overdue ${props.OverdueRecords === 1 ? 'Customer' : 'Customers'}`,
    amount: `${PriceFormat(props.OverdueAmount || 0)}`,
    shortDescription: RequestedListShortName.Overdue,
    onClick: () => onClickBucketOverdue(),
    isActive: isOverdueSelected,
    count: props.OverdueRecords && parseInt(props.OverdueRecords?.toString()) > 99 ? '99+' : props.OverdueRecords,
    dataAutoId: 'btnOverdue',
  };

  const dueSoonBucket = {
    id: PurchaseInvoiceRequestedList.Due, // Due
    color: 'blue',
    description: `${props.DueRecords || 0} Due Soon ${props.DueRecords === 1 ? 'Customer' : 'Customers'}`,
    amount: `${PriceFormat(props.DueAmount || 0)}`,
    shortDescription: RequestedListShortName.Due,
    onClick: () => onClickBucketDueSoon(),
    isActive: isDueSoonSelected,
    count: props.DueRecords && parseInt(props.DueRecords?.toString()) > 99 ? '99+' : props.DueRecords,
    dataAutoId: 'btnDueSoon',
  };

  const onClickBucketOverdue = () => {
    // toggle over due tab
    overDueBucket.isActive = !overDueBucket.isActive;
    setIsOverdueSelected(overDueBucket.isActive);

    let status = PurchaseInvoiceRequestedList.None;
    if (overDueBucket.isActive && isDueSoonSelected) {
      status = PurchaseInvoiceRequestedList.Payable;
    } else if (overDueBucket.isActive) {
      status = PurchaseInvoiceRequestedList.Overdue;
    } else if (isDueSoonSelected) {
      status = PurchaseInvoiceRequestedList.Due;
    }
    props.onClickBucket(status);
  };

  const onClickBucketDueSoon = () => {
    // toggle due soon tab
    dueSoonBucket.isActive = !dueSoonBucket.isActive;
    setIsDueSoonSelected(dueSoonBucket.isActive);

    let status = PurchaseInvoiceRequestedList.None;
    if (dueSoonBucket.isActive && isOverdueSelected) {
      status = PurchaseInvoiceRequestedList.Payable;
    } else if (dueSoonBucket.isActive) {
      status = PurchaseInvoiceRequestedList.Due;
    } else if (isOverdueSelected) {
      status = PurchaseInvoiceRequestedList.Overdue;
    }

    props.onClickBucket(status);
  };

  const resetDueAndOverDueSelection = (status: boolean = false) => {
    dueSoonBucket.isActive = status;
    setIsDueSoonSelected(status);
    overDueBucket.isActive = status;
    setIsOverdueSelected(status);
  };

  let buckets: any[] = [overDueBucket, dueSoonBucket];

  if (props.purchaseInvoiceViewMode === PurchaseInvoiceViewTypes.TimelineView) {
    buckets = [];
  }
  const totalOwing = props.isT2TPhaseTwo
    ? (props.OverdueAmount || 0) + (props.DueAmount || 0) - (props.DebitNotesAmount || 0)
    : (props.OverdueAmount || 0) + (props.DueAmount || 0);

  return (
    <div>
      <SearchInputAndBucketsForm
        totalOwing={totalOwing}
        isAccountsPayable={props.isAccountsPayable}
        apRefreshData={props.apRefreshData}
        placeholder="Search all Purchase Invoices..."
        isSearching={props.isSearching}
        searchFilter={props.searchFilter}
        onSearch={onSubmit}
        buckets={buckets}
        clearOnEntityChange={false}
        financialAdaptorName={props.financialAdaptorName}
      ></SearchInputAndBucketsForm>
    </div>
  );
};
