import React, {useEffect, useState} from 'react';
import {Box, DialogTitle, DialogContent, Typography, makeStyles, useMediaQuery, useTheme} from '@material-ui/core';
import {SButton} from '../buttons/Button';
import CloseButton from '../../assets/svg/menu/Cross.svg';
import arrowNext from '../../assets/svg/arrowNext.svg';
import arrowPrev from '../../assets/svg/arrowPrev.svg';
import {SDialog, SModal} from '../modals/modalSpendaMeterialUI';
import {useHistory} from 'react-router-dom';
import {css} from 'glamor';
import {Carousel} from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {IkeyDesc, IMenuModule} from '../../model/menu/Menu';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import SVGIcon from '../ui/SVGIcon';
import {useFeatureFlags} from '../../hooks/useFeatureFlags';
import {ModuleTypes} from '../../model/constants/Constants';
import useTenantAPI from '../../services/useTenantAPI';
import {Button} from 'spenda-ui-react';

interface IModuleInfo {
  handleClose: () => void;
  module: IMenuModule;
  isPrimaryUser: boolean;
  moduleOnboardingBtnClick: (moduleId: number) => void;
  isLoadingModule?: boolean;
}

const useStyles = makeStyles({
  root: {
    '& .MuiDialog-paperFullWidth': {
      width: 'calc(100% - 20px)',
    },
    '& .MuiDialog-paperScrollPaper': {
      maxHeight: 'calc(100% - 20px)',
    },
    '& .MuiDialog-paper': {
      margin: '9px',
    },
  },
});

export const ModuleInfo: React.FunctionComponent<IModuleInfo> = ({
  handleClose,
  module,
  isPrimaryUser,
  moduleOnboardingBtnClick,
  isLoadingModule,
}: IModuleInfo) => {
  const history = useHistory();
  const {purchaseInvoicingV1} = useFeatureFlags().tenantOwned();
  const {airplusVirtualCreditRequest} = useTenantAPI();

  const [selectedItemIndex, setSelectedItemIndex] = useState(0);
  const [selectedImage, setSelectedImage] = useState<undefined | any>();

  const classes = useStyles();
  const theme = useTheme();
  const smmatches = useMediaQuery(theme.breakpoints.down(640));

  const isVirtualCreditFacility = module?.ModuleID === ModuleTypes.VirtualCreditFacility;

  useEffect(() => {
    if (module.ModuleID === ModuleTypes.VirtualCreditFacility) {
      sendAirplusVirtualCreditRequestEmail();
    }
  }, [module.ModuleID]);

  const sendAirplusVirtualCreditRequestEmail = async () => {
    try {
      await airplusVirtualCreditRequest();
    } catch {}
  };

  const redirectUser = (type: string, route?: string) => {
    if (type === 'findOutMore') {
      if (route) {
        window.open(route);
      } else window.open(' https://spenda.co/features/payments/');
    } else if (type === 'contactSales') {
      window.open(' https://spenda.co/contact-us/');
    }
  };

  const startOnboarding = () => {
    if (module?.Actions?.ONBOARDING) {
      history.push(module.Actions.ONBOARDING);
    } else if (moduleOnboardingBtnClick) {
      moduleOnboardingBtnClick(module?.ModuleID!);
    }
  };

  const handleNextClick = () => {
    if (module?.contents?.length && selectedItemIndex === module?.contents?.length - 1) {
      setSelectedItemIndex(0);
    } else {
      setSelectedItemIndex(selectedItemIndex + 1);
    }
  };

  const handlePreviousClick = () => {
    if (selectedItemIndex === 0) {
      if (module?.contents?.length) {
        setSelectedItemIndex(module?.contents?.length - 1);
      }
    } else {
      setSelectedItemIndex(selectedItemIndex - 1);
    }
  };

  return (
    <>
      {selectedImage && (
        <SModal open onClose={() => setSelectedImage(undefined)}>
          <div className="flex items-center justify-center" onClick={() => setSelectedImage(undefined)}>
            {selectedImage.type === 'image' ? (
              <img src={selectedImage.path} style={{height: '100vh'}} />
            ) : (
              <iframe
                src={selectedImage.path}
                allow="autoplay; encrypted-media"
                width="600"
                height="400"
                title="video"
                allowFullScreen
              />
            )}
          </div>
        </SModal>
      )}
      <SDialog open onClose={handleClose} maxWidth={'lg'} fullWidth className={classes.root}>
        <DialogTitle
          className="flex w-full max-w-full flex-col bg-white"
          {...css({
            fontFamily: 'Poppins, sans-serif !important',
            boxShadow: '0 0 .5rem 0 #9d9d9d',
            padding: '1.5rem 1.25rem 1.5rem 2.5rem',
            '@media(max-width: 640px)': {padding: '1.5rem 1.25rem 2.5rem !important'},
          })}
        >
          <Box className="smMax:flex-col flex items-start justify-between">
            <div
              className={`collect-left flex ${isVirtualCreditFacility && '!w-full'} flex-row items-center`}
              {...css({
                '@media(max-width: 640px)': {width: '100%', padding: '45px 0px 20px 0px'},
              })}
            >
              <div className="menu-logo-box">
                <SVGIcon name={module.Src} height="100px" width="100px" />
              </div>
              <div
                className={`collect-details ${isVirtualCreditFacility && '!w-full'}`}
                {...css({
                  '@media(max-width: 640px)': {width: 'calc(100% - 139px)'},
                })}
              >
                <Typography variant="h3">{module?.Title || 'Spenda Collect'}</Typography>
                <Typography>
                  {module?.SubTitle ||
                    'Meet SpendaCollect, a payment platform that enables any business to collect debt from any customer.'}
                </Typography>
              </div>
            </div>
            {!isVirtualCreditFacility && (
              <div className={`collect-right m-auto`}>
                {((module?.Actions?.ONBOARDING || module?.onboardingButtonTitle) &&
                  module.ModuleID !== ModuleTypes.SpendaPay &&
                  module.ModuleID !== ModuleTypes.SpendaBuy) ||
                (module?.Actions?.ONBOARDING && module.ModuleID === ModuleTypes.SpendaPay && isPrimaryUser) ||
                (module?.Actions?.ONBOARDING && module.ModuleID === ModuleTypes.SpendaBuy && purchaseInvoicingV1) ? (
                  <Button
                    loading={isLoadingModule}
                    onClick={startOnboarding}
                    className="mb-2 !text-lg font-medium"
                    size="md"
                  >
                    {module?.onboardingButtonTitle}
                  </Button>
                ) : null}

                <div className="collect-action-btn flex w-full justify-between">
                  <SButton
                    type="submit"
                    width="w-full"
                    lineHeight="m"
                    fontSize="m"
                    onClick={() => redirectUser('contactSales')}
                    className="primaryBtn"
                  >
                    <Typography style={{fontFamily: 'Poppins'}}> Contact Sales </Typography>
                  </SButton>
                  <SButton
                    type="submit"
                    width="w-full"
                    lineHeight="m"
                    fontSize="m"
                    onClick={() => redirectUser('findOutMore', module?.Actions?.FINDOUTMORE)}
                    className="primaryBtn"
                  >
                    <Typography style={{fontFamily: 'Poppins'}}> Find Out More </Typography>
                  </SButton>
                </div>
              </div>
            )}
            <img
              src={CloseButton}
              className="smMax:absolute smMax:top-2.5 smMax:right-2.5 cursor-pointer"
              style={{marginLeft: '3.75rem'}}
              onClick={handleClose}
            />
          </Box>
        </DialogTitle>
        <DialogContent
          {...css({
            backgroundColor: '#f8f8f8',
            padding: '.5rem 1.5rem .5rem 0',
            '@media(max-width: 640px)': {padding: '0px 0px !important'},
          })}
        >
          {module?.IFrameURL ? (
            <div className="flex !h-[560px] !min-h-[560px] w-full">
              <iframe
                src={module?.IFrameURL}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;"
                width="100%"
                height="560"
                title="Financial Service"
                sandbox="allow-scripts allow-forms allow-popups allow-same-origin"
                loading="eager"
              />
            </div>
          ) : (
            <>
              {module?.contents && (
                <Box className="smMax:flex-col smMax:pt-7 mt-8 flex items-center">
                  <Box className="module-info-slides smMax:w-full relative w-3/5">
                    <div className="custom-carousel-previous">
                      <img src={arrowPrev} className="h-8 w-8 cursor-pointer" onClick={handlePreviousClick} />
                    </div>
                    <div className="custom-carousel-next">
                      <img src={arrowNext} className="h-8 w-8 cursor-pointer" onClick={handleNextClick} />
                    </div>
                    <Carousel
                      centerMode
                      showArrows={false}
                      centerSlidePercentage={smmatches ? 70 : 50}
                      showIndicators={false}
                      showStatus={false}
                      selectedItem={selectedItemIndex}
                      infiniteLoop={true}
                      showThumbs={false}
                      transitionTime={0}
                      onClickItem={index => {
                        setSelectedItemIndex(index);
                      }}
                      onChange={index => {
                        setSelectedItemIndex(index);
                      }}
                    >
                      {module?.contents?.map((content, index) => (
                        <ModuleImage
                          totalContent={module?.contents?.length}
                          key={content.path}
                          content={content}
                          index={index}
                          selectedItemIndex={selectedItemIndex}
                          onClick={() => setSelectedImage(content)}
                        />
                      ))}
                    </Carousel>
                  </Box>
                  <Box className="smMax:w-full smMax:px-6 smMax:pt-4 w-2/5 pl-4">
                    {module?.keyPoints?.map(point => (
                      <KeyPoints key={point.image} Description={point.description} image={point.image} />
                    ))}
                  </Box>
                </Box>
              )}
              {!!module?.MiddleDescription && (
                <Box>
                  <p className="mx-5" style={{fontFamily: 'poppins'}}>
                    {module?.MiddleDescription}
                  </p>
                </Box>
              )}
              {!!module?.keyDescriptions?.length && (
                <Box className="smMax:flex-col flex flex-wrap justify-between px-6 pt-8">
                  {module?.keyDescriptions?.map((desc, i) => (
                    <KeyPointsDescription key={'moudleDesc' + i} keyDescriptionData={desc} />
                  ))}
                </Box>
              )}
            </>
          )}
        </DialogContent>
      </SDialog>
    </>
  );
};

const KeyPointsDescription = ({keyDescriptionData}: {keyDescriptionData: IkeyDesc}) => {
  const {keyPoints, label, plainText} = keyDescriptionData;
  return (
    <div
      {...css({
        background: 'rgb(28 120 173 / 8%)',
        width: '32%',
        '@media(max-width: 640px)': {width: '100%'},
        fontFamily: 'Poppins, sans-serif !important',
      })}
      className="font-base border-neutral-300 mb-4 flex flex-col rounded-md border-solid p-4 text-black-800"
    >
      {!!label && (
        <p className="mb-4 mt-2 font-bold" style={{fontFamily: 'Poppins', color: '#1C78AD'}}>
          {label}
        </p>
      )}
      {!!keyPoints?.length && (
        <ul className="pl-5" style={{listStyle: 'disc'}}>
          {keyPoints?.map(point => <li key={point}>{point}</li>)}
        </ul>
      )}
      {plainText?.map(text => (
        <p key={text} className={`${keyPoints?.length || label ? 'mt-5' : ''}`} style={{fontFamily: 'Poppins'}}>
          {text}
        </p>
      ))}
    </div>
  );
};

const KeyPoints = (props: any) => {
  return (
    <Box
      {...css({
        fontFamily: 'Poppins, sans-serif !important',
      })}
      className="font-base mb-8 flex items-center text-black-800"
    >
      <Box className="float-left mr-4 flex h-8 w-8 items-center">
        <img src={props.image} />
      </Box>
      <span
        style={{
          fontFamily: 'Poppins',
          width: 'calc(100% - 3rem)',
        }}
        className="font-base float-right flex items-center font-normal text-black-800"
      >
        {props.Description}
      </span>
    </Box>
  );
};

const ModuleImage = ({onClick, index, selectedItemIndex, content, totalContent}: any) => {
  const getCustomItemClass = () => {
    if (selectedItemIndex === 0 && index === totalContent - 1) {
      return 'previous-item';
    } else if (index === selectedItemIndex - 1) {
      return 'previous-item';
    } else if (selectedItemIndex === totalContent - 1 && index === 0) {
      return 'next-item';
    } else if (index === selectedItemIndex + 1) {
      return 'next-item';
    }

    return '';
  };
  return (
    <Box className={`selectedItem relative cursor-pointer ${getCustomItemClass()}`} onClick={onClick}>
      {content.type === 'video' && (
        <PlayCircleOutlineIcon style={{position: 'absolute', top: '44%', left: '45%', fontSize: '40px'}} />
      )}
      <img
        src={content.type === 'image' ? content.path : content.thumbnail}
        {...css({
          height: '8.75rem',
          width: '11.45rem !important',
          padding: 0,
        })}
      />
    </Box>
  );
};
