import {withStyles, Tooltip} from '@material-ui/core';

const PaymentMethodsTooltip = withStyles({
  tooltip: {
    position: 'absolute',
    padding: '10px',
    boxSizing: 'border-box',
    background: '#fff',
    border: '1px solid #fafafa',
    left: '0',
    zIndex: 999999,
    width: 'auto',
    top: '-5px',
    boxShadow: '0px 0px 4px 0 rgb(0 0 0 / 20%)',
    borderRadius: '3px',
    '&::after': {
      zIndex: 1,
      content: "''",
      position: 'absolute',
      width: '0',
      height: '0',
      marginLeft: '0',
      bottom: '0',
      top: '-8px',
      left: '8px',
      boxSizing: 'border-box',
      border: '5px solid #fff',
      borderColor: 'transparent transparent #fff #fff',
      transformOrigin: '0 0',
      transform: 'rotate(45deg)',
      boxShadow: '-2px -3px 4px 0px rgb(0 0 0 / 12%)',
    },
    '&::before': {
      zIndex: 10,
      content: "''",
      position: 'absolute',
      width: 0,
      height: 0,
      marginLeft: 0,
      bottom: 0,
      top: '0',
      left: '16px',
      boxSizing: 'border-box',
      border: '5px solid black',
      borderColor: 'transparent transparent #fff #fff',
      transformOrigin: '0 0',
      transform: 'rotate(136deg)',
    },
  },
})(Tooltip);

export default PaymentMethodsTooltip;
