import {ModuleTypes} from '../../model/constants/Constants';
import {IActionRoutes, IContents, IContentType, IkeyDesc, IKeyPoints, IMenuModule} from '../../model/menu/Menu';
import {
  AUTH_SELLING_COLLECT_ROUTE,
  AUTH_ONBOARING_ROUTE,
  AUTH_ACCOUNTSPAYABLE_ONBOARING_ROUTE,
  AUTH_MERCHANT_PORTAL_ROUTE,
  AUTH_TRADE_ACCOUNT_ROUTE,
} from '../../routes/AuthenticatedRoutes';
import {AUTH_PURCHASING_ONBOARDING_ROUTE, AUTH_PURCHASING_PURCHASE_DASHBOARD_LIST} from '../../routes/PurchasingRoutes';
import {SPENDA_POS_APP_STORE_ROUTE, SPENDA_POS_DEEPLINKING_ROUTE} from '../../routes/MobileApplicationRoutes';
import {SVGName} from '../ui/SVGIcon';
import {FeatureFlagKeys} from '../../hooks/useFeatureFlags';
import arinvite from '../../assets/png/AccountsReceivable/AR-invitation.png';
import arhome from '../../assets/png/AccountsReceivable/AR-home.png';
import ar02 from '../../assets/png/AccountsReceivable/AR-home-2.png';
import ar03 from '../../assets/png/AccountsReceivable/AR-home-3.png';
import ar04 from '../../assets/png/AccountsReceivable/AR-home-4.png';
import ser_icon1 from '../../assets/png/Service/icon_1.png';
import ser_icon2 from '../../assets/png/Service/icon_2.png';
import ser_icon3 from '../../assets/png/Service/icon_3.png';
import ser_icon4 from '../../assets/png/Service/icon_4.png';
import rs_icon1 from '../../assets/png/ReceiveStock/icon_1.png';
import rs_icon2 from '../../assets/png/ReceiveStock/icon_2.png';
import rs_icon3 from '../../assets/png/ReceiveStock/icon_3.png';
import rs_icon4 from '../../assets/png/ReceiveStock/icon_4.png';
import pur_icon1 from '../../assets/png/Purchasing/icon_1.png';
import pur_icon2 from '../../assets/png/Purchasing/icon_2.png';
import pur_icon3 from '../../assets/png/Purchasing/icon_3.png';
import pur_icon4 from '../../assets/png/Purchasing/icon_4.png';
import pos_icon1 from '../../assets/png/PointOfSale/icon_1.png';
import pos_icon2 from '../../assets/png/PointOfSale/icon_2.png';
import pos_icon3 from '../../assets/png/PointOfSale/icon_3.png';
import pos_icon4 from '../../assets/png/PointOfSale/icon_4.png';
import ar_icon1 from '../../assets/png/AccountsReceivable/icon_1.png';
import ar_icon2 from '../../assets/png/AccountsReceivable/icon_2.png';
import ar_icon3 from '../../assets/png/AccountsReceivable/icon_3.png';
import ar_icon4 from '../../assets/png/AccountsReceivable/icon_4.png';
import ap_icon1 from '../../assets/png/AccountsPayable/icon_1.png';
import ap_icon2 from '../../assets/png/AccountsPayable/icon_2.png';
import ap_icon3 from '../../assets/png/AccountsPayable/icon_3.png';
import ap_icon4 from '../../assets/png/AccountsPayable/icon_4.png';

// New Images
import {AROnboardingImages} from '../../assets/png/AccountsReceivable/NewImages/ARImages';
import {APOnboardingImages} from '../../assets/png/AccountsPayable/NewImages/APImages';
import {POSOnboadingImages} from '../../assets/png/PointOfSale/NewImages/POSImages';
import {PurchasingOnboadingImages} from '../../assets/png/Purchasing/NewImages/PurchasingImages';
import {ServiceOnboardingImages} from '../../assets/png/Service/NewImages/ServiceImages';
import {ReceiveStockOnboardingImages} from '../../assets/png/ReceiveStock/NewImages/ReceiveStockImages';
import {SOOnboardingImages, SOKeyPointIcons} from '../../assets/png/SalesOrder/SalesOrderImages';
import {PSOnboardingImages, PSKeyPointIcons} from '../../assets/png/PaymentServices/PaymentServicesImages';
import {QMKeyPointIcons, QMOnboardingImages} from '../../assets/png/QuoteManagement/QuotesManagementImages';
import {AUTH_INVENTORY_PRODUCTS_LIST} from '../../routes/InventoryRoutes';
import {AUTH_CUSTOMER_OPERATIONAL_CUSTOMERS_LIST} from '../../routes/CustomerRoutes';
import {AUTH_SELLING_SALES_ORDER_LIST} from '../../routes/SalesOrderRoutes';
import {AUTH_PURCHASING_PAY_ROUTE} from '../../routes/AccountsPayableRoutes';
import {AUTH_BUYING_SUPPLIERS} from '../../routes/SupplierManagementRoutes';
import {AUTH_SELLING_QUOTES_LIST} from '../../routes/QuoteManagementRoutes';
import {AUTH_SELLING_SERVICE_DASHBOARD} from '../../routes/ServiceManagementRoutes';

interface IMenuConfig {
  ModuleID: number;
  Name: string;
  Route: string; // Authenticated Route
  Src: string;
  Actions: IActionRoutes;
  DependencyModuleID?: number;
  FeatureFlags?: FeatureFlagKeys[];
  Title?: string;
  SubTitle?: string;
  keyDescriptions?: IkeyDesc[];
  keyPoints?: IKeyPoints[];
  contents?: IContents[];
  onboardingButtonTitle?: string;
  MiddleDescription?: string;
  IFrameURL?: string;
}

export const MenuConfig: IMenuConfig[] = [
  {
    ModuleID: ModuleTypes.SpendaCollectAR,
    Name: 'Accounts Receivable',
    Route: AUTH_SELLING_COLLECT_ROUTE,
    Src: SVGName.AccountReceivables,
    onboardingButtonTitle: 'Start 30 Day Free Trial',
    Actions: {
      ONBOARDING: AUTH_ONBOARING_ROUTE,
      FINDOUTMORE: 'https://spenda.co/accounts-receivable/',
    },
    Title: 'Accounts Receivable Management ',
    SubTitle: 'Take payments, collect debt, set up pay-later plans and improve cash flow.',
    keyDescriptions: [
      {plainText: ['Solve administrative burdens with an automated and integrated e-invoicing solution.']},
      {plainText: ['Send automated notifications to your customers to prompt payment.']},
      {plainText: ['Secure ledger and full debtor account access. ']},
      {plainText: ['Accept major cards and bank transfers.']},
      {plainText: ['Keep your current business banking when you switch to Spenda.']},
      {plainText: ['Integrates with accounting systems and larger ERP management solutions.']},
    ],
    keyPoints: [
      {description: 'Improve collections and get paid faster', image: ar_icon1},
      {description: 'Reduce administrative pain points', image: ar_icon2},
      {description: 'Send late payment reminders en masse ', image: ar_icon3},
      {description: 'Benefit from automatic account reconciliation', image: ar_icon4},
    ],
    contents: [
      {type: IContentType.Image, path: AROnboardingImages.ar_detail},
      {type: IContentType.Image, path: AROnboardingImages.ar_invoice},
      {type: IContentType.Image, path: AROnboardingImages.ar_selectInvoice},
      {type: IContentType.Image, path: AROnboardingImages.ar_sendInvoice},
    ],
  },
  {
    ModuleID: ModuleTypes.ProductManagement,
    Name: 'Product Management MC',
    Route: AUTH_INVENTORY_PRODUCTS_LIST,
    Src: SVGName.ProductManagement,
    Actions: {
      FINDOUTMORE: 'https://spenda.co/accounts-receivable/',
    },
    Title: 'Product Management',
    SubTitle: 'Take payments, collect debt, set up pay-later plans and improve cash flow.',
    keyDescriptions: [
      {plainText: ['Solve administrative burdens with an automated and integrated e-invoicing solution.']},
      {plainText: ['Send automated notifications to your customers to prompt payment.']},
      {plainText: ['Secure ledger and full debtor account access. ']},
      {plainText: ['Accept major cards and bank transfers.']},
      {plainText: ['Keep your current business banking when you switch to Spenda.']},
      {plainText: ['Integrates with accounting systems and larger ERP management solutions.']},
    ],
    keyPoints: [
      {description: 'Improve collections and get paid faster', image: ar_icon1},
      {description: 'Reduce administrative pain points', image: ar_icon2},
      {description: 'Send late payment reminders en masse ', image: ar_icon3},
      {description: 'Benefit from automatic account reconciliation', image: ar_icon4},
    ],
    contents: [
      {type: IContentType.Image, path: ar02},
      {type: IContentType.Image, path: ar03},
      {type: IContentType.Image, path: ar04},
      {type: IContentType.Image, path: arhome},
      {type: IContentType.Image, path: arinvite},
    ],
  },
  {
    ModuleID: ModuleTypes.SpendaStock,
    Name: 'WareHouse Management',
    Route: '',
    Src: SVGName.WareHouse,
    Actions: {
      FINDOUTMORE: 'https://spenda.co/warehouse-management/',
    },
  },
  {
    ModuleID: ModuleTypes.Suppliers,
    Name: 'Supplier Management',
    Route: AUTH_BUYING_SUPPLIERS,
    Src: SVGName.Suppliers,
    DependencyModuleID: ModuleTypes.SpendaPay,
    Actions: {
      FINDOUTMORE: 'https://spenda.co/accounts-receivable/',
    },
    FeatureFlags: [FeatureFlagKeys.T2TPhase280729],
  },
  {
    ModuleID: ModuleTypes.SpendaBuy,
    Name: 'Buying',
    Route: AUTH_PURCHASING_PURCHASE_DASHBOARD_LIST,
    Src: SVGName.Buying,
    onboardingButtonTitle: 'Start Subscription',
    Actions: {
      ONBOARDING: AUTH_PURCHASING_ONBOARDING_ROUTE,
      FINDOUTMORE: 'https://spenda.co/purchasing/',
    },
    Title: 'Buying',
    SubTitle: 'A smart digital tool designed to automate and optimise the procurement process.',
    keyDescriptions: [
      {plainText: ['Purchase on the go with a portable cloud-based ordering system.']},
      {
        plainText: [
          'Add products to purchase orders by using the search functionality or via the in-built barcode scanner.',
        ],
      },
      {plainText: ['Easy stock replenishment though min/max values using the ‘suggest what I need to buy’ feature.']},
      {plainText: ['Access multiple suppliers in one place.']},
      {plainText: ['Customisable user settings and approvals.']},
      {plainText: ['Generate supplier invoice form received goods.']},
    ],
    keyPoints: [
      {description: 'Digitise your procurement process, save time and reduce errors ', image: pur_icon1},
      {description: 'Benefit from an automated data-driven system ', image: pur_icon2},
      {description: 'Always have the right stock on hand', image: pur_icon3},
      {description: 'Better visibility and tracking of all purchase orders', image: pur_icon4},
    ],
    contents: [
      {type: IContentType.Image, path: PurchasingOnboadingImages.purchasing_add},
      {type: IContentType.Image, path: PurchasingOnboadingImages.purchasing_list},
      {type: IContentType.Image, path: PurchasingOnboadingImages.purchasing_newDetails},
      {type: IContentType.Image, path: PurchasingOnboadingImages.purchasing_orderDetails},
      {type: IContentType.Image, path: PurchasingOnboadingImages.purchasing_quantity},
    ],
  },
  {
    ModuleID: ModuleTypes.ReceiveStock,
    Name: 'Collect',
    Route: '',
    Src: SVGName.ReceiveStock,
    Actions: {
      DEEP_LINKING: SPENDA_POS_DEEPLINKING_ROUTE,
      APP_STORE: SPENDA_POS_APP_STORE_ROUTE,
      FINDOUTMORE: 'https://spenda.co/retail-and-service/',
    },
    Title: 'Receive Stock',
    SubTitle: 'Get smarter with stock management and gain visibility across multiple locations.',
    keyDescriptions: [
      {plainText: ['Portable cloud-based inventory management solution.']},
      {plainText: ['Get low stock notifications when your predetermined minimum stock levels are reached.']},
      {plainText: ['Delivers seamless warehouse to warehouse stock transfers and requisitions.']},
      {plainText: ['View, track and manage products across multiple warehouses in real-time.']},
      {plainText: ['Use the built-in margin calculator to automatically determine the suggested sale price.']},
      {plainText: ['Perform stock takes or rolling stock takes, and make stock adjustments all from the one place.']},
    ],
    keyPoints: [
      {description: 'Real-time inventory management', image: rs_icon1},
      {description: 'Better manage and forecast stock requirements', image: rs_icon2},
      {description: 'Reduce inventory shrinkage and ageing stock problems', image: rs_icon3},
      {description: 'Always have enough stock on hand and never miss a sale', image: rs_icon4},
    ],
    contents: [
      {type: IContentType.Image, path: ReceiveStockOnboardingImages.stock_pricing},
      {type: IContentType.Image, path: ReceiveStockOnboardingImages.stock_productDetails},
      {type: IContentType.Image, path: ReceiveStockOnboardingImages.stock_selectStock},
      {type: IContentType.Image, path: ReceiveStockOnboardingImages.stock_transferDetails},
      {type: IContentType.Image, path: ReceiveStockOnboardingImages.stock_variations},
    ],
  },
  {
    ModuleID: ModuleTypes.SpendaPay,
    Name: 'Pay',
    Route: AUTH_PURCHASING_PAY_ROUTE,
    Src: SVGName.AccountsPayable,
    onboardingButtonTitle: 'Start Subscription',
    Actions: {
      ONBOARDING: AUTH_ACCOUNTSPAYABLE_ONBOARING_ROUTE,
      FINDOUTMORE: 'https://spenda.co/accounts-payable/',
    },
    Title: 'Accounts Payable Management',
    SubTitle: 'One convenient dashboard to view and pay unpaid invoices.',
    keyDescriptions: [
      {plainText: ['Track, group and batch-pay outstanding invoices.']},
      {plainText: ['Save time and enhance accuracy by removing the need to manually process and pay invoices.']},
      {plainText: ['Benefit from more a variety of flexible payment options such as credit card and bank transfer.']},
      {plainText: ['Assign employee payment approval permissions ']},
      {plainText: ['Pay by credit card and earn reward/loyalty points.']},
      {plainText: ['Integrates with accounting systems and larger ERP management solutions.']},
    ],
    keyPoints: [
      {description: 'Pay multiple suppliers from one dashboard', image: ap_icon1},
      {description: 'Save time and enhance accuracy', image: ap_icon2},
      {description: 'Benefit from automatic account reconciliation', image: ap_icon3},
      {description: 'Secure and reliable', image: ap_icon4},
    ],
    contents: [
      {type: IContentType.Image, path: APOnboardingImages.ap_batchUploaded},
      {type: IContentType.Image, path: APOnboardingImages.ap_limits},
      {type: IContentType.Image, path: APOnboardingImages.ap_paidBatch},
      {type: IContentType.Image, path: APOnboardingImages.ap_payment},
      {type: IContentType.Image, path: APOnboardingImages.ap_remittance},
      {type: IContentType.Image, path: APOnboardingImages.ap_uploadfile},
    ],
  },
  {
    ModuleID: ModuleTypes.TradeFinance,
    Name: 'Trade Finance',
    Route: '',
    Src: SVGName.TradeFinance,
    Actions: {
      FINDOUTMORE: 'https://spenda.co/accounts-receivable/',
    },
    DependencyModuleID: ModuleTypes.SpendaPay,
  },
  {
    ModuleID: ModuleTypes.CustomerManagement,
    Name: 'Customers',
    Route: AUTH_CUSTOMER_OPERATIONAL_CUSTOMERS_LIST,
    Src: SVGName.Customers,
    Actions: {
      FINDOUTMORE: 'https://spenda.co/accounts-receivable/',
    },
    Title: 'Customer Management',
    SubTitle: 'Take payments, collect debt, set up pay-later plans and improve cash flow.',
    keyDescriptions: [
      {plainText: ['Solve administrative burdens with an automated and integrated e-invoicing solution.']},
      {plainText: ['Send automated notifications to your customers to prompt payment.']},
      {plainText: ['Secure ledger and full debtor account access. ']},
      {plainText: ['Accept major cards and bank transfers.']},
      {plainText: ['Keep your current business banking when you switch to Spenda.']},
      {plainText: ['Integrates with accounting systems and larger ERP management solutions.']},
    ],
    keyPoints: [
      {description: 'Improve collections and get paid faster', image: ar_icon1},
      {description: 'Reduce administrative pain points', image: ar_icon2},
      {description: 'Send late payment reminders en masse ', image: ar_icon3},
      {description: 'Benefit from automatic account reconciliation', image: ar_icon4},
    ],
    contents: [
      {type: IContentType.Image, path: ar02},
      {type: IContentType.Image, path: ar03},
      {type: IContentType.Image, path: ar04},
      {type: IContentType.Image, path: arhome},
      {type: IContentType.Image, path: arinvite},
    ],
  },
  {
    ModuleID: ModuleTypes.SalesOrderManagement,
    Name: 'Sales Orders',
    Route: AUTH_SELLING_SALES_ORDER_LIST,
    Src: SVGName.SalesOrdersManagement,
    Actions: {
      FINDOUTMORE: 'https://spenda.co/sales-and-fulfilment/',
    },
    onboardingButtonTitle: 'Start Application',
    Title: 'Sales and Fulfilment',
    SubTitle: 'Deliver a better structure to the pick, pack and shipment of goods.',
    keyDescriptions: [
      {plainText: ['Track sales, payments, stock and fulfilment from one dashboard.']},
      {plainText: ['Speed up procurement timelines with automation through the supply chain.']},
      {plainText: ['A payment is only taken after goods are picked, removing the need for refunds/credit notes.']},
      {plainText: ['Better communicate with key stakeholders through the entire sales process.']},
      {plainText: ['Shipping and third-party logistics integration available']},
      {plainText: ['Integrates with accounting systems and larger ERP management solutions.']},
    ],
    keyPoints: [
      {description: 'One central order and price management hub', image: SOKeyPointIcons.icon1},
      {description: 'Improves collaboration and transparency', image: SOKeyPointIcons.icon2},
      {description: 'Removes manual processes', image: SOKeyPointIcons.icon3},
      {description: 'Delivers a better customer experience', image: SOKeyPointIcons.icon4},
    ],
    contents: [
      {type: IContentType.Image, path: SOOnboardingImages.so_create},
      {type: IContentType.Image, path: SOOnboardingImages.so_home},
      {type: IContentType.Image, path: SOOnboardingImages.so_pack},
      {type: IContentType.Image, path: SOOnboardingImages.so_packagingtype},
      {type: IContentType.Image, path: SOOnboardingImages.so_ship},
    ],
  },
  {
    ModuleID: ModuleTypes.SpendaPOS,
    Name: 'Point of Sale',
    Route: '',
    Src: SVGName.SalesOrders,
    Actions: {
      DEEP_LINKING: SPENDA_POS_DEEPLINKING_ROUTE,
      APP_STORE: SPENDA_POS_APP_STORE_ROUTE,
      FINDOUTMORE: 'https://spenda.co/point-of-sale/',
    },
    Title: 'Point of Sale',
    SubTitle: 'Transform your business with a powerful cloud-based point of sale solution.',
    keyDescriptions: [
      {plainText: ['Portable iPad-based POS solution with built-in barcode and virtual payment terminal.']},
      {plainText: ['Easily update complex product ranges with the smart product variant builder.']},
      {plainText: ['Simple returns and refund management.']},
      {plainText: ['Accept cash, card or connect our Pay Invoice by Link service. Choose to pay 0% merchant fees.']},
      {plainText: ['Add pop-up checkouts during busy periods.']},
      {plainText: ['Seamless integration with your accounting system.']},
    ],
    keyPoints: [
      {description: 'Intuitive and easy to use', image: pos_icon1},
      {description: 'Process sales anywhere, anytime', image: pos_icon2},
      {description: 'Accept contactless payments in real-time', image: pos_icon3},
      {description: 'Deliver a frictionless checkout experience', image: pos_icon4},
    ],
    contents: [
      {type: IContentType.Image, path: POSOnboadingImages.pos_float},
      {type: IContentType.Image, path: POSOnboadingImages.pos_payment},
      {type: IContentType.Image, path: POSOnboadingImages.pos_refund},
      {type: IContentType.Image, path: POSOnboadingImages.pos_scanner},
      {type: IContentType.Image, path: POSOnboadingImages.pos_screen},
      {type: IContentType.Image, path: POSOnboadingImages.pos_virtualterminal},
    ],
  },
  {
    ModuleID: ModuleTypes.Quotes,
    Name: 'Quote Management',
    Route: AUTH_SELLING_QUOTES_LIST,
    Src: SVGName.Quotes,
    Actions: {
      FINDOUTMORE: 'https://spenda.co/software-solutions/',
    },
    onboardingButtonTitle: 'Start Application',
    Title: 'Quote Management',
    SubTitle: 'Streamline your quote workflow and make it easier for customers to buy​',
    FeatureFlags: [FeatureFlagKeys.quoteManagementV1],
    keyDescriptions: [
      {
        plainText: [
          'Use a smarter and more convenient quoting process and easily send up-to-date quotes via text or email.',
        ],
      },
      {
        plainText: [
          'Quickly create multiple quotes and offer customers different product options to choose from with the ability to make secure payment.',
        ],
      },
      {plainText: ['Easily search and retrieve quotes and track the progress of each quote in real-time.']},
      {
        plainText: [
          'Speed up the quote-to-pay process with quick and easy digital acceptance and payment options for your customer.',
        ],
      },
      {
        plainText: [
          'Improve customer interactions throughout the sales process and drive enhanced loyalty and repeat business.',
        ],
      },
      {plainText: ['Integrates with common accounting systems and larger ERP management solutions.']},
    ],
    keyPoints: [
      {description: 'Easily access and track quotes in real-time', image: QMKeyPointIcons.icon4},
      {description: 'Eliminate manual work and data entry errors', image: QMKeyPointIcons.icon2},
      {description: 'Improve lead conversion', image: QMKeyPointIcons.icon3},
      {description: 'Deliver a better customer experience', image: QMKeyPointIcons.icon1},
    ],
    contents: [
      {type: IContentType.Image, path: QMOnboardingImages.PS_01},
      {type: IContentType.Image, path: QMOnboardingImages.PS_04},
      {type: IContentType.Image, path: QMOnboardingImages.PS_02},
      {type: IContentType.Image, path: QMOnboardingImages.PS_05},
      {type: IContentType.Image, path: QMOnboardingImages.PS_06},
      {type: IContentType.Image, path: QMOnboardingImages.PS_03},
    ],
  },
  {
    ModuleID: ModuleTypes.SpendaECom,
    Name: 'Manage Ecom',
    Route: '',
    Src: SVGName.Ecom,
    Actions: {
      FINDOUTMORE: 'https://spenda.co/accounts-receivable/',
    },
  },
  {
    ModuleID: ModuleTypes.Payments,
    Name: 'Payments',
    Route: '',
    Src: SVGName.PaymentServices,
    Actions: {
      FINDOUTMORE: 'https://spenda.co/accounts-receivable/',
    },
    DependencyModuleID: ModuleTypes.SpendaCollectAR,
    onboardingButtonTitle: 'Start Application',
    Title: 'Payment Services',
    SubTitle: 'Seamlessly accept payments, maximise conversions and never miss a sale.',
    MiddleDescription:
      'Our merchant application process consists of three quick steps. At each step, you will be required enter key information about your business and attach documents to help us get you set up as quickly as possible. Here’s what you will need:',
    keyDescriptions: [
      {
        label: 'Step 1:',
        keyPoints: ['BSB and account number', 'Chartered account codes'],
        plainText: [
          'Once the initial set up is done, you will receive an email from our team requesting documents to verify the account, as outlined in steps two and three.',
        ],
      },
      {
        label: 'Step 2:',
        keyPoints: [
          'ABN',
          'Average transaction size',
          'Estimated annual turnover',
          'Estimated annual credit card turnover',
          'Time frames on goods and services delivered',
          'Your returns policy',
        ],
      },
      {
        label: 'Step 3:',
        keyPoints: [
          'A recent bank statement',
          'Proof of identity, including a selfie',
          'Proof of business address (a Google listing screen shot is acceptable)',
          'Trust Deed (if applicable)',
          'Two years worth of financials',
        ],
      },
    ],
    keyPoints: [
      {description: 'Hassle-free application process', image: PSKeyPointIcons.icon1},
      {description: 'Accept payments quickly', image: PSKeyPointIcons.icon2},
      {description: 'Offer more payment methods', image: PSKeyPointIcons.icon3},
      {description: 'Secure and reliable', image: PSKeyPointIcons.icon4},
    ],
    contents: [
      {type: IContentType.Image, path: PSOnboardingImages.PS_01},
      {type: IContentType.Image, path: PSOnboardingImages.PS_02},
      {type: IContentType.Image, path: PSOnboardingImages.PS_03},
      {type: IContentType.Image, path: PSOnboardingImages.PS_04},
      {type: IContentType.Image, path: PSOnboardingImages.PS_05},
      {type: IContentType.Image, path: PSOnboardingImages.PS_06},
      {type: IContentType.Image, path: PSOnboardingImages.PS_07},
      {type: IContentType.Image, path: PSOnboardingImages.PS_08},
      {type: IContentType.Image, path: PSOnboardingImages.PS_09},
    ],
  },
  {
    ModuleID: ModuleTypes.Statements,
    Name: 'Statements',
    Route: '',
    Src: SVGName.Statements,
    DependencyModuleID: ModuleTypes.SpendaCollectAR,
    Actions: {},
    FeatureFlags: [FeatureFlagKeys.payfacStatementing83495],
  },
  {
    ModuleID: ModuleTypes.MerchantPortal,
    Name: 'Merchant Portal',
    Route: AUTH_MERCHANT_PORTAL_ROUTE,
    Src: SVGName.MerchantPortal,
    DependencyModuleID: ModuleTypes.SpendaCollectAR,
    onboardingButtonTitle: 'Start',
    Actions: {
      FINDOUTMORE: 'https://spenda.co/merchant-portal/',
    },
    FeatureFlags: [FeatureFlagKeys.payfacMerchantPortal88470],
    // TO DO: Udit
    keyDescriptions: [
      {
        plainText: ['Sample'],
      },
      {
        plainText: ['Sample'],
      },
      {plainText: ['Sample']},
      {
        plainText: ['Sample'],
      },
      {
        plainText: ['Sample'],
      },
      {plainText: ['Sample']},
    ],
    keyPoints: [
      {description: 'Sample', image: QMKeyPointIcons.icon4},
      {description: 'Sample', image: QMKeyPointIcons.icon2},
      {description: 'Sample', image: QMKeyPointIcons.icon3},
      {description: 'Sample', image: QMKeyPointIcons.icon1},
    ],
    contents: [
      {type: IContentType.Image, path: QMOnboardingImages.PS_01},
      {type: IContentType.Image, path: QMOnboardingImages.PS_04},
      {type: IContentType.Image, path: QMOnboardingImages.PS_02},
      {type: IContentType.Image, path: QMOnboardingImages.PS_05},
      {type: IContentType.Image, path: QMOnboardingImages.PS_06},
      {type: IContentType.Image, path: QMOnboardingImages.PS_03},
    ],
  },
  {
    ModuleID: ModuleTypes.Service,
    Name: 'Service Management',
    Route: AUTH_SELLING_SERVICE_DASHBOARD,
    Src: SVGName.Service,
    onboardingButtonTitle: 'Start Application',
    Actions: {
      FINDOUTMORE: 'https://spenda.co/service-management-pro/',
    },
    Title: 'Service Management',
    SubTitle: 'Streamline your business from end-to-end and enhance the customer experience.',
    keyDescriptions: [
      {plainText: ['Portable cloud-based  solution with built-in barcode scanner and payment terminal.']},
      {plainText: ['Easily access customer and vehicle information, and save time by creating job templates.']},
      {plainText: ['Accept cash, card or connect our Pay Invoice by Link service. Choose to pay 0% merchant fees.']},
      {plainText: ['Quickly create and edit bookings. Reminders can be sent to customers via an SMS or email.']},
      {plainText: ['Invoice jobs and take payment instantly wherever you are.']},
      {plainText: ['Seamless integration with your accounting system.']},
    ],
    keyPoints: [
      {description: 'Boost efficiency and reduce administrative pain points ', image: ser_icon1},
      {description: 'Process sales anywhere, anytime', image: ser_icon2},
      {description: 'Accept contactless payments in real-time', image: ser_icon3},
      {description: 'Automated account reconciliation', image: ser_icon4},
    ],
    contents: [
      {type: IContentType.Image, path: ServiceOnboardingImages.service_calendar},
      {type: IContentType.Image, path: ServiceOnboardingImages.service_dayView},
      {type: IContentType.Image, path: ServiceOnboardingImages.service_pay},
      {type: IContentType.Image, path: ServiceOnboardingImages.service_sale},
    ],
    FeatureFlags: [FeatureFlagKeys.serviceManagementV1],
  },
  {
    ModuleID: ModuleTypes.VirtualCreditFacility,
    Name: 'Finance Service',
    Route: '',
    Src: SVGName.VirtualCreditFacility,
    DependencyModuleID: ModuleTypes.SpendaPay,
    Actions: {
      FINDOUTMORE: 'https://spenda.co/finance-service/',
    },
    FeatureFlags: [FeatureFlagKeys.virtualCreditFacility88813],
    Title: 'Virtual Credit Facility',
    SubTitle:
      'An unsecured top-up facility designed to provide you with access to cash to keep your business running smoothly.',
    IFrameURL: 'https://spenda.co/my-spenda-internal-pages/virtual-credit-card/',
  },
  {
    ModuleID: ModuleTypes.TradeAccount,
    Name: 'TradeAccount',
    Route: AUTH_TRADE_ACCOUNT_ROUTE,
    Src: SVGName.VirtualCreditFacility,
    DependencyModuleID: ModuleTypes.SpendaPay,
    onboardingButtonTitle: 'Start',
    Actions: {
      FINDOUTMORE: 'https://spenda.co/finance-service/',
    },
    FeatureFlags: [FeatureFlagKeys.eCommerceAndTransactionsView89915],
    Title: 'Trade Account',
    SubTitle:
      'An unsecured top-up facility designed to provide you with access to cash to keep your business running smoothly.',
    keyDescriptions: [
      {
        plainText: ['Sample'],
      },
      {
        plainText: ['Sample'],
      },
      {plainText: ['Sample']},
      {
        plainText: ['Sample'],
      },
      {
        plainText: ['Sample'],
      },
      {plainText: ['Sample']},
    ],
    keyPoints: [
      {description: 'Sample', image: QMKeyPointIcons.icon4},
      {description: 'Sample', image: QMKeyPointIcons.icon2},
      {description: 'Sample', image: QMKeyPointIcons.icon3},
      {description: 'Sample', image: QMKeyPointIcons.icon1},
    ],
    contents: [
      {type: IContentType.Image, path: QMOnboardingImages.PS_01},
      {type: IContentType.Image, path: QMOnboardingImages.PS_04},
      {type: IContentType.Image, path: QMOnboardingImages.PS_02},
      {type: IContentType.Image, path: QMOnboardingImages.PS_05},
      {type: IContentType.Image, path: QMOnboardingImages.PS_06},
      {type: IContentType.Image, path: QMOnboardingImages.PS_03},
    ],
  },
];

export const SkeletonMenu: IMenuModule[] = [
  {
    Name: 'Service',
    Category: 'Selling',
    SubCategory: '',
    Src: SVGName.Service,
    OrderSequence: 5,
    IsParentModule: false,
  },
  {
    Name: 'SpendaBuy',
    TrialPeriodDays: 30,
    Category: 'Buying',
    SubCategory: '',
    Src: SVGName.Buying,
    OrderSequence: 2,
    IsParentModule: false,
  },
  {
    Name: 'SpendaCollectAR',
    Type: 'Payment',
    TrialPeriodDays: 30,
    Category: 'Selling',
    SubCategory: '',
    Src: SVGName.AccountReceivables,
    OrderSequence: 3,
    IsParentModule: false,
  },
  {
    Name: 'SpendaPOS',
    Type: 'Sales',
    TrialPeriodDays: 30,
    Category: 'Selling',
    SubCategory: '',
    Src: SVGName.SalesOrders,
    OrderSequence: 4,
    IsParentModule: false,
  },
  {
    Name: 'SpendaPay',
    Category: 'Buying',
    SubCategory: '',
    Src: SVGName.AccountsPayable,
    OrderSequence: 4,
    IsParentModule: false,
    ModuleID: ModuleTypes.SpendaPay,
  },
  {
    Name: 'SupplierManagement',
    Category: 'Buying',
    SubCategory: '',
    Src: SVGName.Suppliers,
    OrderSequence: 6,
    IsParentModule: false,
    ModuleID: ModuleTypes.Suppliers,
  },
  {
    Name: 'ProductManagement',
    Category: 'Inventory',
    Src: SVGName.ProductManagement,
    OrderSequence: 1,
    IsParentModule: false,
  },
  {
    Name: 'ReceiveStock',
    Category: 'Buying',
    Src: SVGName.ReceiveStock,
    OrderSequence: 3,
    IsParentModule: false,
  },
  {
    Name: 'CustomerManagement',
    Category: 'Selling',
    SubCategory: '',
    Src: SVGName.Customers,
    OrderSequence: 1,
    IsParentModule: false,
  },
  {
    Name: 'Payment Services',
    Category: 'Selling',
    SubCategory: 'Payment Services',
    Src: SVGName.PaymentServices,
    OrderSequence: 1,
    IsParentModule: false,
    IsActive: true,
  },
  {
    Name: 'Statements',
    Category: 'Selling',
    SubCategory: 'Payment Services',
    Src: SVGName.Statements,
    OrderSequence: 2,
    IsParentModule: false,
    FeatureFlags: [FeatureFlagKeys.payfacStatementing83495],
  },
  {
    Name: 'MerchantPortal',
    Category: 'Selling',
    SubCategory: 'Payment Services',
    Src: SVGName.Statements,
    OrderSequence: 1,
    IsParentModule: true,
    FeatureFlags: [FeatureFlagKeys.payfacMerchantPortal88470],
  },
  {
    Name: 'SalesOrder',
    Category: 'Selling',
    Src: SVGName.SalesOrdersManagement,
    OrderSequence: 2,
    IsParentModule: false,
  },
  {
    Name: 'Finance Service',
    Category: 'Buying',
    Src: SVGName.VirtualCreditFacility,
    OrderSequence: 2,
    IsParentModule: false,
    FeatureFlags: [FeatureFlagKeys.virtualCreditFacility88813],
  },
  {
    Name: 'Trade Account',
    Category: 'Buying',
    SubCategory: 'Finance Service',
    Src: SVGName.VirtualCreditFacility,
    OrderSequence: 1,
    IsParentModule: true,
    FeatureFlags: [FeatureFlagKeys.eCommerceAndTransactionsView89915],
  },
];
