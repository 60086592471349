import React, {useReducer} from 'react';
import PurchasingReducer from './purchasingReducer';
import PurchasingContext, {IPurchasingState} from './purchasingContext';
import PurchasingActions from './purchasingActions';
import {IRequisition, IRequisitionLine} from '../../model/requisition/Requisition';
import useRequisitionAPI from '../../services/useRequisitionAPI';
import {ISupplierMarket} from '../../model/SupplierMarket';
import {IRequisitionFilter} from '../../model/search-filters/RequisitionSearchFilter';
import {IRequisitionActionOption} from '../../model/requisition/RequisitionActionOption';
import usePurchaseOrderAPI from '../../services/usePurchaseOrderAPI';
import {PurchaseOrderStatus, PaymentProcessStatus, PaymentOutcomeStatus} from '../../model/constants/Constants';
import {IPurchaseOrder} from '../../model/purchase-order/PurchaseOrder';
import {SupplierInventory} from '../../model/inventory/SupplierInventory';
import {IConnectedSupplierStatementSummary} from '../../model/supplier/SupplierTransaction';
import {IPaymentStatusCount} from '../../model/purchase-invoice/purchaseInvoice';
import {SelectedCreditNotes} from '../../model/credit-notes/SelectedCreditNotes';
import {IAppliedCredit} from '../../model/credit-notes/CreditClaims';
import {IConversationChatbox} from '../../model/conversation/Conversation';
import {IARBatchResponse} from '../../model/accounts-receivable/batch';
import { uuid } from '../../utils/uuidGenerator';

export const PurchasingState = (props: any) => {
  const requisitionActionOptions: IRequisitionActionOption[] = [
    {
      ID: 1,
      Name: 'NEW',
      Icon: 'icon',
      Label: 'New Order',
      DarkColor: '#1C78AD',
      LightColor: '#d6ebf3',
      Action: (value: boolean) => setIsShowRequisitionDetailsDialog(value),
    },
    {
      ID: 2,
      Name: 'DRAFT',
      Counter: 0,
      Label: 'Draft Orders',
      DarkColor: '#3BA179',
      LightColor: 'rgb(217,237,229)',
      IsActive: true,
      Action: value => setActionOptionIsActive(value),
    },
    {
      ID: 3,
      Name: 'PREVIOUS',
      Counter: 0,
      Label: 'Previous Orders',
      DarkColor: '#c8973a',
      LightColor: '#f6eede',
      IsActive: false,
      Action: value => setActionOptionIsActive(value),
    },
  ];

  const initialState: IPurchasingState = {
    isShowRequisitionDetailsDialog: false,
    productToAdd: undefined,
    requisition: undefined,
    purchaseOrder: undefined,
    statusCounters: requisitionActionOptions,
    transactions: [],
    poToCopyFrom: undefined,
    invoicesToPay: [],
    paymentProcessStatus: PaymentProcessStatus.NEW,
    paymentOutcome: undefined,
    paymentStatusCounts: undefined,
    creditsToApply: [],
    creditStatementSummary: undefined,
    isLoyaltyPointsToggleOn: false,
    conversations: props?.conversationsState ? props?.conversationsState : [],
    psblBatch: undefined,
    totalInvoiceAmount: 0,
    totalCreditAndClaimsSelected: 0,
    totalPrepaymentsSelected: 0,
    creditAndClaimsList: [],
  };

  const [state, dispatch] = useReducer(PurchasingReducer, initialState);

  const requisitionAPI = useRequisitionAPI();
  const purchaseOrderAPI = usePurchaseOrderAPI();

  const setActionOptionIsActive = (actionOption: IRequisitionActionOption) => {
    if (actionOption.IsActive) {
      return;
    }
    dispatch({
      type: PurchasingActions.SET_REQUISITION_TYPE_ACTIVE,
      payload: actionOption,
    });
  };

  const setIsShowRequisitionDetailsDialog = (value: boolean = true, poToCopyFrom?: IRequisition) => {
    dispatch({
      type: PurchasingActions.SET_DISPLAY_ORDER_POUP,
      payload: value,
      options: poToCopyFrom,
    });
  };

  const setRequisition = async (
    requisition: Partial<IRequisition> | undefined,
    isSave: boolean = true,
    isClose?: boolean
  ) => {
    if (requisition) {
      let payload = requisition;

      if (isSave) {
        payload = await requisitionAPI.save(requisition);
        if (!payload) {
          return;
        }
      } else {
        payload = await requisitionAPI.get(requisition.ID as number);
      }

      dispatch({
        type: PurchasingActions.SET_REQUISITION,
        payload: isClose ? undefined : payload,
      });
      setIsShowRequisitionDetailsDialog(false);
    } else {
      dispatch({
        type: PurchasingActions.SET_REQUISITION,
        payload: requisition,
      });
    }
  };

  const setPurchaseOrder = async (poId: number | undefined) => {
    if (poId) {
      const payload = await purchaseOrderAPI.get(poId);
      dispatch({
        type: PurchasingActions.SET_PURCHASE_ORDER,
        payload: payload,
      });
    } else {
      dispatch({
        type: PurchasingActions.SET_PURCHASE_ORDER,
        payload: undefined,
      });
    }
  };

  const deleteRequisition = async (requisition: Partial<IRequisition> | undefined) => {
    if (!requisition) {
      return;
    }

    const deleteRequisition = await requisitionAPI.delete(requisition.ID as number);
    if (deleteRequisition) {
      dispatch({type: PurchasingActions.SET_REQUISITION, payload: undefined});
    }
  };

  const updateRequisitionStatus = async (requisition: Partial<IRequisition> | undefined, status: string) => {
    if (!requisition) {
      return;
    }

    const updatedRequisition = await requisitionAPI.updateStatus(requisition.ID as number, status);
    if (!updatedRequisition || !updatedRequisition.LinkedTrans) {
      return;
    }

    const linkedPO = updatedRequisition.LinkedTrans[0];
    if (!linkedPO || !linkedPO.ID) {
      return;
    }

    const po = await purchaseOrderAPI.get(linkedPO.ID);
    if (!po) {
      return;
    }

    dispatch({type: PurchasingActions.SET_PURCHASE_ORDER, payload: po});
  };

  const updatePurchaseOrderStatus = async (
    purchaseOrder: Partial<IPurchaseOrder> | undefined,
    status: PurchaseOrderStatus,
    isSavePO: boolean = false
  ) => {
    if (isSavePO && purchaseOrder) {
      await purchaseOrderAPI.save(purchaseOrder);
    }

    const response = await purchaseOrderAPI.updateStatus(purchaseOrder && purchaseOrder.ID, status);
    if (response) {
      dispatch({
        type: PurchasingActions.SET_PURCHASE_ORDER,
        payload: undefined,
      });
    }
  };

  const updatePurchaseOrder = async (purchaseOrder: Partial<IPurchaseOrder> | undefined) => {
    if (!purchaseOrder) {
      return;
    }

    const response = await purchaseOrderAPI.save(purchaseOrder);
    if (response) {
      dispatch({
        type: PurchasingActions.SET_PURCHASE_ORDER,
        payload: undefined,
      });
    }
  };

  const addOrUpdateOrderLine = (product: SupplierInventory, supplier?: ISupplierMarket, manualQty?: number | '') => {
    if (!state.requisition) {
      // 1. Add product to state as pending.
      if (product && !state.productToAdd) {
        dispatch({
          type: PurchasingActions.SET_PRODUCT_TO_ADD,
          payload: product,
        });
      }
      return;
    }

    const po: IRequisition = JSON.parse(JSON.stringify(state.requisition));

    if (po && po.Lines) {
      // Check if product exists in line
      const existingLines = [...po.Lines];
      let lineIndex: number = existingLines.findIndex(l => l.SKU === product.SKU);

      if (lineIndex >= 0) {
        let newQty: number | undefined;
        if (manualQty === undefined) {
          newQty = (existingLines[lineIndex].Quantity || 0) + 1;
        }

        if (manualQty === '') {
          newQty = undefined;
        }

        if (manualQty && manualQty >= 0) {
          newQty = manualQty;
        }

        existingLines[lineIndex].Quantity = newQty;
        existingLines[lineIndex].IsModified = true;

        if (existingLines[lineIndex].IsActive === false) {
          // Readding line that was previously removed from document
          const reAddedLine = existingLines[lineIndex];
          reAddedLine.IsActive = true;
          reAddedLine.Quantity = 1;
          existingLines.splice(lineIndex, 1);
          existingLines.push(reAddedLine);
        }
      } else {
        // otherwise add to order
        const newLine: IRequisitionLine = {
          Code: product.InventoryCode,
          CostPriceEx: product.SpecialSellPriceEx || product.StandardSellPriceEx,
          GUID: uuid(),
          InventoryID: product.ID,
          IsActive: true,
          IsModified: true,
          Quantity: 1,
          ShortDescription: product.ShortDescription,
          SKU: product.SKU,
          SupplierID: supplier && supplier.SupplierID,
          SupplierInventoryGUID: product.InventoryGUID,
          SupplierName: supplier && supplier.TenantName,
        };

        existingLines.push(newLine);
      }

      po.Lines = existingLines;
      dispatch({type: PurchasingActions.SET_REQUISITION, payload: po});
    }

    dispatch({
      type: PurchasingActions.SET_PRODUCT_TO_ADD,
      payload: undefined,
    });
  };

  const removeLine = (line: IRequisitionLine, isAvailable: boolean = true) => {
    if (!state.requisition) {
      return;
    }

    const po: IRequisition = JSON.parse(JSON.stringify(state.requisition));

    if (isAvailable) {
      if (!po || !po.Lines) {
        return;
      }

      const existingLines = [...po.Lines];
      let lineIndex: number = existingLines.findIndex(l => l.GUID === line.GUID);

      if (lineIndex >= 0) {
        existingLines[lineIndex].IsActive = false;
        existingLines[lineIndex].IsModified = true;
      }
      po.Lines = existingLines;
    } else {
      if (!po || !po.LinesNoLongerAvailable) {
        return;
      }

      let linesNoLongerAvailable = [...po.LinesNoLongerAvailable];
      let lineIndex: number = linesNoLongerAvailable.findIndex(l => l.ID === line.ID);

      if (lineIndex >= 0) {
        linesNoLongerAvailable.splice(lineIndex, 1);
      }
      po.LinesNoLongerAvailable = linesNoLongerAvailable;
    }

    dispatch({type: PurchasingActions.SET_REQUISITION, payload: po});
  };

  const saveRequisitionLines = async () => {
    let reqId = undefined;
    let modifiedLines: IRequisitionLine[] = [];

    if (state.requisition && state.requisition.Lines) {
      reqId = state.requisition.ID;
      modifiedLines = state.requisition.Lines.filter((line: IRequisitionLine) => line.IsModified);
    }

    if (!reqId || !modifiedLines.length) {
      return;
    }

    const response = await requisitionAPI.saveLines(reqId, modifiedLines);

    dispatch({type: PurchasingActions.SET_REQUISITION, payload: response});
  };

  const searchTransactions = async () => {
    const reqFilter: IRequisitionFilter = {
      Status: ['New', 'Pending', 'Approved', 'Processed'],
      MaxResults: 1000,
      RequisitionType: 'Purchase',
      IsIncludeLines: false,
      // IsComplete: true,
      SortAsc: false,
      SortField: 'createddatetimeutc',
    };

    const poFilter: IRequisitionFilter = {
      MaxResults: 1000,
      Status: [PurchaseOrderStatus.Review, PurchaseOrderStatus.Approved, PurchaseOrderStatus.Cancelled],
      SortAsc: false,
      SortField: 'createddatetimeutc',
    };

    const poResults = await purchaseOrderAPI.search(poFilter);
    const reqResults = await requisitionAPI.search(reqFilter);

    // Filter requisitions
    const filteredReqResults =
      reqResults &&
      reqResults.Value.filter(tr => {
        if (tr && tr.LinkedTrans && tr.LinkedTrans.length) {
          return tr.LinkedTrans.every(
            lt =>
              lt.DatTypeID === 71 &&
              lt.Status !== PurchaseOrderStatus.Review &&
              lt.Status !== PurchaseOrderStatus.Approved
          );
        } else {
          return true;
        }
      });

    // TODO: Get counters from one API Call instead of two.
    let statusCounters: IRequisitionActionOption[] = state.statusCounters;

    const rao = statusCounters.find((action: IRequisitionActionOption) => action.IsActive);
    let transactions: IRequisition[] = [];

    if (rao && rao.Name === 'PREVIOUS') {
      transactions = poResults.Value;
    } else {
      transactions = filteredReqResults;
    }

    statusCounters[1].Counter = filteredReqResults.length;
    statusCounters[2].Counter = poResults.Count;

    dispatch({
      type: PurchasingActions.SEARCH_TRANSACTIONS,
      payload: {statusCounters, transactions},
    });
  };

  const setInvoicesToPay = (tx: IConnectedSupplierStatementSummary) => {
    const selectedInvoices = [...state.invoicesToPay];

    const txIndex = selectedInvoices.findIndex(si => si.DatTypeID === tx.DatTypeID && si.TransID === tx.TransID);

    if (txIndex >= 0) {
      selectedInvoices.splice(txIndex, 1);
    } else {
      selectedInvoices.push(tx);
    }

    dispatch({
      type: PurchasingActions.SET_INVOICES_TO_PAY,
      payload: selectedInvoices,
    });
  };

  const setMultipleInvoicesToPay = async (tx: IConnectedSupplierStatementSummary[]) => {
    return dispatch({type: PurchasingActions.SET_INVOICES_TO_PAY, payload: tx});
  };

  const setPaymentProcessStatus = (status: PaymentProcessStatus) => {
    dispatch({
      type: PurchasingActions.SET_PAYMENT_PROCESS_STATUS,
      payload: status,
    });
  };

  const setPaymentOutcome = (outcome: PaymentOutcomeStatus) => {
    dispatch({type: PurchasingActions.SET_PAYMENT_OUTCOME, payload: outcome});
  };

  const resetPaymentProcess = () => {
    dispatch({
      type: PurchasingActions.RESET_PAYMENT_PROCESS,
      payload: undefined,
    });
  };

  const setPaymentStatusCount = (paymentStatusCounts: IPaymentStatusCount) => {
    dispatch({
      type: PurchasingActions.SET_PAYMENT_COUNTS,
      payload: paymentStatusCounts,
    });
  };

  const setCreditsToApply = (credits: Partial<SelectedCreditNotes>[]) => {
    dispatch({
      type: PurchasingActions.SET_CREDITS_TO_APPLY,
      payload: credits,
    });
  };

  const setCreditStatementSummary = (response: IAppliedCredit | undefined) => {
    dispatch({
      type: PurchasingActions.SET_CREDITS_STATEMENT_SUMMARY,
      payload: response,
    });
  };

  const setIsLoyaltyPointsToggleOn = (value: boolean) => {
    dispatch({
      type: PurchasingActions.SET_LOYALTY_POINTS_TOGGLE,
      payload: value,
    });
  };

  const setConversations = (conversations: IConversationChatbox[]) => {
    dispatch({
      type: PurchasingActions.SET_CONVERSATIONS,
      payload: conversations,
    });
  };

  const setPsblBatch = (batchRes: IARBatchResponse) => {
    const {invoices, creditNotes, claims, payments} = batchRes;

    const totalInvoiceAmount = invoices.reduce((sum, i) => sum + i.balance, 0);
    const totalPrepaymentsSelected = payments?.reduce((sum, i) => sum + i.balance, 0);
    const totalCreditAndClaimsSelected = [...creditNotes, ...claims, ...payments].reduce(
      (sum, i) => sum + i.balance,
      0
    );

    dispatch({
      type: PurchasingActions.SET_BATCH_RESPONSE,
      payload: {
        batchRes,
        totalInvoiceAmount,
        totalCreditAndClaimsSelected,
        totalPrepaymentsSelected,
      },
    });
  };

  const setCreditAndClaimsList = (creditAndClaims: Partial<SelectedCreditNotes>[]) => {
    dispatch({
      type: PurchasingActions.SET_CREDIT_AND_CLAIMS_LIST,
      payload: creditAndClaims,
    });
  };

  return (
    <PurchasingContext.Provider
      value={{
        isShowRequisitionDetailsDialog: state.isShowRequisitionDetailsDialog,
        productToAdd: state.productToAdd,
        requisition: state.requisition,
        purchaseOrder: state.purchaseOrder,
        statusCounters: state.statusCounters,
        transactions: state.transactions,
        poToCopyFrom: state.poToCopyFrom,
        invoicesToPay: state.invoicesToPay,
        paymentProcessStatus: state.paymentProcessStatus,
        paymentOutcome: state.paymentOutcome,
        paymentStatusCounts: state.paymentStatusCounts,
        creditsToApply: state.creditsToApply,
        creditStatementSummary: state.creditStatementSummary,
        isLoyaltyPointsToggleOn: state.isLoyaltyPointsToggleOn,
        conversations: state.conversations,
        psblBatch: state.psblBatch,
        totalInvoiceAmount: state.totalInvoiceAmount,
        totalCreditAndClaimsSelected: state.totalCreditAndClaimsSelected,
        totalPrepaymentsSelected: state.totalPrepaymentsSelected,
        creditAndClaimsList: state.creditAndClaimsList,
        addOrUpdateOrderLine,
        deleteRequisition,
        removeLine,
        setPurchaseOrder,
        saveRequisitionLines,
        searchTransactions,
        setIsShowRequisitionDetailsDialog,
        setRequisition,
        updatePurchaseOrder,
        updatePurchaseOrderStatus,
        updateRequisitionStatus,
        setInvoicesToPay,
        setMultipleInvoicesToPay,
        setPaymentProcessStatus,
        setPaymentOutcome,
        resetPaymentProcess,
        setPaymentStatusCount,
        setCreditsToApply,
        setCreditStatementSummary,
        setIsLoyaltyPointsToggleOn,
        setConversations,
        setPsblBatch,
        setCreditAndClaimsList,
      }}
    >
      {props.children}
    </PurchasingContext.Provider>
  );
};
