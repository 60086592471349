import React from 'react';

export const LinkedDoc = (props: SVGProps) => (
  <svg
    width="228px"
    height="126px"
    viewBox="0 0 228 126"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <title>{'Group'}</title>
    <g id="Page-1" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <g id="Detailed-Order-view---no-pickslip-generated-yet" transform="translate(-1164.000000, -467.000000)">
        <g id="Group" transform="translate(1164.000000, 467.000000)">
          <rect id="Rectangle" fill="#FFFFFF" x={0} y={0} width={228} height={126} rx={5} />
          <rect
            id="Rectangle-Copy"
            fillOpacity={0.503250656}
            className="fill-primary/10"
            x={10}
            y={10}
            width={209}
            height={28}
            rx={2}
          />
          <rect id="Rectangle-Copy" className="fill-primary-header" x={17} y={17} width={69} height={14} rx={2} />
          <rect id="Rectangle-Copy" className="fill-primary-header" x={195} y={17} width={16} height={14} rx={2} />
          <rect
            id="Rectangle-Copy"
            fillOpacity={0.503250656}
            className="fill-primary/10"
            x={10}
            y={48}
            width={209}
            height={28}
            rx={2}
          />
          <rect id="Rectangle-Copy" className="fill-primary-header" x={17} y={55} width={69} height={14} rx={2} />
          <rect id="Rectangle-Copy" className="fill-primary-header" x={195} y={55} width={16} height={14} rx={2} />
          <rect
            id="Rectangle-Copy"
            fillOpacity={0.503250656}
            className="fill-primary/10"
            x={10}
            y={86}
            width={209}
            height={28}
            rx={2}
          />
          <rect id="Rectangle-Copy" className="fill-primary-header" x={17} y={93} width={69} height={14} rx={2} />
          <rect id="Rectangle-Copy" className="fill-primary-header" x={195} y={93} width={16} height={14} rx={2} />
        </g>
      </g>
    </g>
  </svg>
);
