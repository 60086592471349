import {IBatchUpdate} from '../model/BatchUpdate';
import {IActionResults} from '../model/ActionResults';
import useHttp from '../hooks/useHttp';

export const useBatchUpdateAPI = () => {
  const {POST, isLoading} = useHttp();

  const batchUpdate = (payload: IBatchUpdate): Promise<IActionResults<number>> => {
    return POST('synk/batchupdates', payload);
  };

  return {
    batchUpdate,
    isLoading,
  };
};
