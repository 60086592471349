import React, {useCallback} from 'react';
import {Card, CardBody, CardHeader, Typography} from 'spenda-ui-react';
import collapseIcon from '../../assets/svg/accounts-payable/report-collapsible-icon.svg';
import {camelCase, upperFirst} from 'lodash';
export interface ICardContent {
  contentValue: string | number | undefined;
  contentHeading: string;
}
interface IQuoteDetailsCard {
  customerInfo: ICardContent[];
  mainHeading: string;
  setQuoteVariantShow?: () => void;
  isQuoteSent?: boolean;
}

const QuoteDetailsCard = (props: IQuoteDetailsCard) => {
  const {customerInfo, mainHeading, setQuoteVariantShow, isQuoteSent} = props;

  const CardContent = useCallback((props: ICardContent) => {
    const {contentHeading, contentValue} = props;

    return (
      <div className="flex flex-col gap-y-2">
        <div className="flex gap-y-1 gap-x-4">
          <Typography className="text-black-800 min-w-[90px]">{contentHeading} :</Typography>
          <Typography
            title={`${contentValue}`}
            data-autoid={`txt${upperFirst(camelCase(contentHeading))}`}
            className="text-black-900 font-semibold truncate max-w-[calc(100%-90px)]"
          >
            {contentValue ? contentValue : '-'}
          </Typography>
        </div>
      </div>
    );
  }, []);

  return (
    <div className="w-full">
      <Card shadow={false} className="border bg-[#FAFAFA] border-[#F1F1F1] rounded-md">
        <CardHeader className="bg-transparent mx-2.5 flex justify-between">
          <Typography variant="h3" className="leading-6 text-black-900">
            {mainHeading}
          </Typography>

          {setQuoteVariantShow && !isQuoteSent && (
            <img
              src={collapseIcon}
              onClick={() => setQuoteVariantShow?.()}
              data-autoid="imgCollapseToggle"
              className="pr-1 cursor-pointer"
            />
          )}
        </CardHeader>
        <CardBody className="pl-2.5 pr-3.5 py-4">
          <div className="flex flex-col gap-y-2">
            {customerInfo.map(({contentHeading, contentValue}) => {
              return <CardContent key={contentHeading} contentHeading={contentHeading} contentValue={contentValue} />;
            })}
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default QuoteDetailsCard;
