import * as React from 'react';
const MergedIcon = (props: SVGProps) => (
  <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6.89 6.92a2.023 2.023 0 110-1.555h.777c1.108 0 1.93.28 2.54.787.59.493.892 1.13 1.098 1.646.053.13.101.26.146.379v.001c.045.12.086.23.128.334.085.212.158.365.235.48a.575.575 0 00.19.195c.055.03.15.066.33.066h.777a2.023 2.023 0 110 1.559c-.728.023-1.082.214-1.292.404-.26.235-.415.561-.64 1.073l-.021.047c-.2.457-.473 1.076-1.001 1.553-.583.527-1.38.809-2.49.809h-.778a2.023 2.023 0 110-1.556h.778c.834 0 1.223-.204 1.447-.407.26-.235.415-.56.64-1.073l.021-.047c.193-.44.453-1.03.944-1.5a2.444 2.444 0 01-.2-.26 4.16 4.16 0 01-.384-.765c-.048-.12-.094-.243-.138-.359l-.003-.009c-.045-.12-.088-.234-.133-.346-.182-.456-.367-.792-.65-1.029-.267-.221-.708-.427-1.544-.427h-.778z"
      fill="#1C78AD"
    />
  </svg>
);
export default MergedIcon;
