import React, {PropsWithChildren, createContext, useState} from 'react';
import {IInvoiceInvitationDetails} from '../../services/useSupplierTransactionsAPI';

interface PayByLinkContextValue {
  supplierDetails?: IInvoiceInvitationDetails;
  setSupplierDetails: (supplierDetails: IInvoiceInvitationDetails) => void;
}

export const PayByLinkContext = createContext<PayByLinkContextValue>({
  supplierDetails: undefined,
  setSupplierDetails: (_supplierDetails) => {},
});

export const PayByLinkProvider: React.FC<PropsWithChildren> = ({children}) => {
  const [supplierDetails, setSupplierDetails] = useState<IInvoiceInvitationDetails>();

  return (
    <PayByLinkContext.Provider value={{supplierDetails, setSupplierDetails}}>{children}</PayByLinkContext.Provider>
  );
};
