import {Box} from '@material-ui/core';
import React, {useEffect} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import LoadingIndicator from '../../components/ui/LoadingIndicator';
import {useInviteAPI} from '../../services/useInviteAPI';
import {Toast} from '../../utils/Toast';
import PublicShell from './PublicShell';

export const Referral = () => {
  const {referrer} = useParams<{referrer: string}>();
  const history = useHistory();
  const {saveReferral: saveReferralAPI} = useInviteAPI();

  useEffect(() => {
    const saveReferral = async () => {
      if (!referrer) return;
      const res = await saveReferralAPI(referrer);

      if (!res.IsSuccess && res.Messages.length) {
        res.Messages.map(m => Toast.info(m));
      }

      setTimeout(() => {
        history.push('/');
      }, 1000);
    };

    saveReferral();
  }, [referrer]);

  return (
    <PublicShell>
      <Box display="flex">
        <LoadingIndicator isLoading={true} position={{position: 'relative'}} size="md" />
        <p className={`ml-2 text-primary`}>Setting up your account...</p>
      </Box>
    </PublicShell>
  );
};
