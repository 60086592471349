import * as React from 'react';
const APAuthorizerCheckIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="10px" height="8px" {...props}>
    <path
      fill="#3C9F78"
      fillRule="evenodd"
      d="M7.883.513a.679.679 0 0 1 .926-.267c.306.171.43.543.3.86l-.035.073-3.478 6.308a.679.679 0 0 1-.923.269l-.068-.043L1.126 5.22a.689.689 0 0 1-.16-.957.679.679 0 0 1 .878-.206l.072.045L4.774 6.15 7.883.513Z"
    />
  </svg>
);
export default APAuthorizerCheckIcon;
