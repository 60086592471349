import React, {useContext} from 'react';
import {ARDialogTemplate} from '../ARDialogTemplate';
import {Button, Typography} from 'spenda-ui-react';
import AppContext from '../../../context/app/appContext';
import {useAuth} from '../../../services/useAuth';

export const CapNonPilotMemberNotAllowedDialog = () => {
  const {logout} = useContext(AppContext);
  const {logout: authLogout} = useAuth();

  const handleLogout = () => {
    authLogout().finally(() => logout());
  };

  return (
    <ARDialogTemplate
      isFullScreen
      dialogProps={{size: 'sm', open: true, className: '!min-w-[597px]'}}
      body={
        <div className="my-20 flex flex-col items-center justify-center">
          <Typography variant="paragraph" className="mb-4 text-center text-sm font-medium text-black-800">
            Thank you for your interest in the Integrated Capricorn Statement Pilot.
          </Typography>
          <Typography variant="paragraph" className="max-w-[432px] text-center text-sm font-normal text-black-800">
            Unfortunately we are not accepting any new Capricorn Members into the pilot at this stage. Please contact
            Capricorn to express your interest to join the next Member rollout.
          </Typography>
        </div>
      }
      footer={
        <div className="flex w-full flex-row items-center justify-center">
          <Button data-autoid={`btnCloseWindow`} variant="filled" color="primary" onClick={handleLogout}>
            Close Window
          </Button>
        </div>
      }
    />
  );
};
