import React from 'react';
import {SModal} from '../modals/modalSpendaMeterialUI';
import {Box, makeStyles} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {SButton} from '../buttons/Button';

export interface IInvoiceMappingModelProps {
  handleClose: () => void;
  handleVendorMapping: (supplierId: number, value: boolean) => void;
  supplierId: number;
}

const useInvoiceMappingModal = makeStyles({
  frame: {
    height: '340px',
  },
  footer: {
    maxWidth: '440px',
    background: '#ECECEC',
    borderRadius: '6px',
    left: '10px',
    justifyContent: 'space-between',
  },
});
export const InvoiceMappingModal = (props: IInvoiceMappingModelProps) => {
  const {handleClose, handleVendorMapping, supplierId} = props;
  const classes = useInvoiceMappingModal();

  return (
    <SModal open>
      <div className={`spenda-color flex h-full max-w-full items-center justify-center font-poppins text-xl`}>
        <div className={`modal-box max-w-[460px]  px-5 pb-5 pt-3 text-spenda-primarytext ${classes.frame}`}>
          <Box className={`-mx-5 flex justify-between border-[#CCCCCC] border-b-default px-3 pb-3`}>
            <h2 className={`w-full self-center text-center text-xl font-light`}>Invoice Mapping</h2>
            <Box className="flex justify-end text-right">
              <span className="ap-onboarding-close-icon">
                <CloseIcon data-autoid="btnClose" onClick={handleClose} className="text-primary" />
              </span>
            </Box>
          </Box>

          <div className="mt-19 flex w-[388px] justify-center text-center">
            <p className="font-poppins text-base font-normal text-spenda-greytext ">
              Do you want to turn on vendor mapping? If yes, you will be allowed to turn on vendor mapping for each
              Invoice. If no, it will be mapped with your supplier only.
            </p>
          </div>

          <Box
            className={`${classes.footer} absolute bottom-3 flex w-full flex-row items-center justify-center py-2 pl-2`}
          >
            <SButton
              borderRadius="6px"
              margin="0 10px 0 0"
              textColor="#1C78AD"
              lineHeight="l"
              height="40px"
              boxShadow="none"
              fontWeight={600}
              fontFamily="poppins"
              fontSize="14px"
              border="1px solid #1C78AD"
              width="60px"
              backgroundColor="white"
              data-autoid="btnMapWithSupplier"
              onClick={handleClose}
            >
              Map With Supplier
            </SButton>
            <SButton
              borderRadius="6px"
              margin="0 10px 0 0"
              textColor="white"
              lineHeight="l"
              height="40px"
              boxShadow="none"
              fontWeight={600}
              fontFamily="poppins"
              fontSize="14px"
              border="1px solid #1C78AD"
              width="183px"
              backgroundColor="#1C78AD"
              data-autoid="btnTurnOnVendorMapping"
              onClick={() => handleVendorMapping(supplierId, true)}
            >
              Turn On Vendor Mapping
            </SButton>
          </Box>
        </div>
      </div>
    </SModal>
  );
};
