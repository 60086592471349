import React, {useEffect, useState} from 'react';
import {makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@material-ui/core';
import {css} from 'glamor';
import {IPaymentBatch, IPaymentBatchCreditLine} from '../../model/payment-batch/PaymentBatch';
import {PriceFormat} from '../../utils/formatter';
import SendRoundedIcon from '@material-ui/icons/SendRounded';
import {LabelPaymentBatchCrDrStatusTypes} from '../../model/constants/Constants';
import supplierViewDropdown from '../../assets/svg/accounts-payable/supplierViewDropdown.svg';
import {IBatchReviewPurchaseInvoice} from '../../model/purchase-invoice/purchaseInvoice';
import {APInvoiceDetailView} from './APInvoiceDetailView';
import _ from 'lodash';
import blueInfoIcon from '../../assets/svg/accounts-payable/blue-info-icon.svg';
import APTooltip from '../../components/data-display/APTootip';
import {Typography} from 'spenda-ui-react';

interface IReportDetailsPurchaseInvoices {
  batchDetails: IPaymentBatch;
  renderPaymentLineStatus: (credit: IPaymentBatchCreditLine) => JSX.Element | undefined;
  handleRemittanceClick: (credit: IPaymentBatchCreditLine) => void;
  t2tV3?: boolean;
}

const useReportDetailsStyles = makeStyles({
  reportsTable: {
    borderRadius: '2px 2px 2px 2px',
    margin: '28px 0 0 0',
    maxHeight: 'calc(100vh - 27em)',
    '& table': {
      borderBottom: '2px solid #F8F8F8',
      '& th': {
        color: '#999999',
        fontWeight: 600,
        fontSize: '14px',
        fontFamily: 'poppins',
      },
      '& .MuiTableRow-root': {
        '&.d-none': {
          display: 'none!important',
        },
        '&.selectedRow': {
          '& td:nth-child(odd)': {
            background: '#eaeef0',
          },
          '& td:nth-child(even)': {
            background: '#eff2f5',
          },
        },
      },
      '& .MuiTableBody-root': {
        '& td': {
          '&:first-child': {
            width: '36px',
          },
          '&.collapse-cell': {
            padding: '0px!important',
            border: '0px',
          },
        },
      },
    },
  },
  expandArrow: {
    width: '14px',
    height: '14px',
    border: '1px solid #1579af',
    borderRadius: '3px',
    '& img': {
      width: '7px',
      transform: 'rotate(180deg)',
    },
    '& img.rotate-expand-more-icon': {
      transform: 'rotate(0deg)',
    },
  },
  supplierListBreakdowntable: {
    padding: '0px',
    margin: '0',
    width: '100%!important',
  },
});

export interface IAPInvoiceId {
  transId: number;
}

export const APReportDetailPurchaseInvoices = (props: IReportDetailsPurchaseInvoices) => {
  const {batchDetails, renderPaymentLineStatus, handleRemittanceClick, t2tV3} = props;
  const classes = useReportDetailsStyles();
  const [breakDownSupplierId, setBreakSupplierId] = useState<Number | undefined>();
  const [showInvoice, setShowInvoice] = useState<boolean>(false);
  const [invoiceId, setInvoiceId] = useState<IAPInvoiceId>();
  const [formattedReportData, setFormattedReportData] = useState(t2tV3 ? [] : batchDetails.credits);

  const formatData = () => {
    let creditData = _.cloneDeep(batchDetails.credits);
    let debitData = _.cloneDeep(batchDetails.debitNotes);
    let formattedData: any = [];
    if (creditData.length) {
      creditData.map(credit => {
        credit.purchaseInvoices?.map((pi, index) => {
          // check if debit note is applied to this invoice
          const debitNote = _.find(debitData, {purchaseInvoices: [{purchaseInvoiceID: pi.purchaseInvoiceID}]});
          const debitPI = debitNote?.purchaseInvoices?.[0];
          if (credit && credit?.purchaseInvoices?.[index] && debitPI) {
            credit.purchaseInvoices[index] = {...pi, ...debitPI, paymentAmount: credit.paymentAmount};
            credit.debitRefNo = debitNote?.refNumber;
            credit.totalInc = debitNote?.totalInc;
            credit.balance = debitNote?.balance;
          }
        });
      });
      formattedData = creditData;
    } else {
      formattedData = debitData.map(debit => {
        return {...debit, debitRefNo: debit.refNumber};
      });
    }
    setFormattedReportData(formattedData);
  };

  useEffect(() => {
    t2tV3 && formatData();
  }, [batchDetails]);

  return (
    <>
      {showInvoice && (
        <APInvoiceDetailView open={showInvoice} onClose={() => setShowInvoice(false)} apInvoiceId={invoiceId} />
      )}
      <div className={`w-full h-full flex flex-col justify-start items-start bg-white relative`}>
        <TableContainer className={`${classes.reportsTable} overflow-y-auto`}>
          <Table className="tableCellHeight" aria-label="simple table" data-autoid={`tblPurchaseInvoiceReportDetails`}>
            <TableHead>
              <TableRow>
                <TableCell align="left"></TableCell>
                <TableCell align="left">Payee</TableCell>
                {!t2tV3 && (
                  <>
                    <TableCell align="left">Payment description</TableCell>
                    <TableCell align="left">Bank account</TableCell>
                  </>
                )}
                <TableCell align="right">Invoice total</TableCell>
                <TableCell align="right">{t2tV3 ? 'Paid amount' : 'Transaction amount'}</TableCell>
                <TableCell align="right">{t2tV3 ? 'Payment' : 'Total paid'}</TableCell>
                {t2tV3 && batchDetails?.debitNotes?.length ? (
                  <TableCell align="right" className="!flex !flex-row justify-end">
                    Debit Note
                    <APTooltip
                      arrow
                      placement="bottom-end"
                      title={
                        <React.Fragment>
                          <Typography className="text-black-800 font-poppins text-center" variant="small">
                            Hover on the amount to know the details
                          </Typography>
                        </React.Fragment>
                      }
                    >
                      <img
                        className="cursor-pointer ml-2 w-3 flex align-center items-center justify-center mt-1"
                        src={blueInfoIcon}
                      />
                    </APTooltip>
                  </TableCell>
                ) : null}
                {!t2tV3 || (t2tV3 && !batchDetails?.linkedDocumentGUID) ? (
                  <TableCell align="right">Applied amount</TableCell>
                ) : null}
                <TableCell align="right">{t2tV3 ? 'Balance' : 'Outstnd.'}</TableCell>
                <TableCell align="left">Status</TableCell>
                <TableCell align="center">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {formattedReportData &&
                formattedReportData?.map((credit: IPaymentBatchCreditLine) => {
                  const totalInvoiceAmount = Number(
                    (credit?.purchaseInvoices?.reduce((sum, {totalInc}) => sum + totalInc, 0) || 0)?.toFixed(2)
                  );
                  const totalPaidAmount =
                    t2tV3 && credit.debitRefNo
                      ? Number(
                          (
                            credit?.purchaseInvoices?.reduce((sum, {paymentAmount}) => sum + (paymentAmount || 0), 0) ||
                            0
                          )?.toFixed(2)
                        )
                      : Number(
                          (
                            credit?.purchaseInvoices?.reduce(
                              (sum, {totalInc, balance}) => sum + (totalInc - balance),
                              0
                            ) || 0
                          )?.toFixed(2)
                        );

                  const totalTransactionAmount =
                    t2tV3 && credit.debitRefNo
                      ? credit?.purchaseInvoices?.reduce((sum, {totalInc}) => sum + Number(totalInc || 0), 0) || 0
                      : credit?.purchaseInvoices?.reduce(
                          (sum, {appliedAmount}) => sum + Number(appliedAmount || 0),
                          0
                        ) || 0;

                  const totalComtdAmount = Number(
                    (
                      credit?.purchaseInvoices?.reduce(
                        (sum, {balance, remnantBalance}) => sum + (balance - (remnantBalance || 0)),
                        0
                      ) || 0
                    )?.toFixed(2)
                  );
                  const totalDebitNoteAmount = Number(
                    (
                      credit?.purchaseInvoices?.reduce(
                        (sum, {totalInc, paymentAmount}) => sum + (totalInc - (paymentAmount || 0)),
                        0
                      ) || 0
                    )?.toFixed(2)
                  );
                  const totalOutstandingAmount =
                    t2tV3 && credit.debitRefNo
                      ? Number((totalInvoiceAmount - (totalPaidAmount + totalDebitNoteAmount) || 0)?.toFixed(2))
                      : Number((totalInvoiceAmount - (totalPaidAmount + totalComtdAmount) || 0)?.toFixed(2));

                  return (
                    <React.Fragment key={credit.supplierID}>
                      <TableRow className={`${breakDownSupplierId === credit.supplierID ? 'selectedRow' : ''}`}>
                        <TableCell style={{width: '5%'}}>
                          <div className={`flex items-center justify-center`}>
                            <span
                              className={`flex items-center justify-center cursor-pointer togglerIcon ${classes.expandArrow}`}
                              onClick={() => {
                                setBreakSupplierId(
                                  credit.supplierID === breakDownSupplierId ? undefined : credit.supplierID
                                );
                              }}
                              data-autoid={`lnkExpandaleIcon-${credit.supplierID}`}
                            >
                              <img
                                data-autoid={`imgSupplierViewDropdown-${credit.supplierID}`}
                                src={supplierViewDropdown}
                                className={`w-full ${
                                  breakDownSupplierId === credit.supplierID ? 'rotate-expand-more-icon' : ''
                                }`}
                              />
                            </span>
                          </div>
                        </TableCell>
                        <TableCell style={{width: '13%'}}>
                          <span className="text-spenda-primarytext font-medium font-poppins text-base">
                            {credit.supplierName}
                          </span>
                        </TableCell>
                        {!t2tV3 && (
                          <>
                            <TableCell style={{width: '15%'}}>
                              <span className="text-spenda-primarytext font-medium font-poppins text-base">
                                {credit.description}
                              </span>
                            </TableCell>
                            <TableCell style={{width: '14%'}}>
                              <span className="text-spenda-primarytext font-medium font-poppins text-base">
                                {credit.bankBSB} {credit.bankAccountNumber}
                              </span>
                            </TableCell>
                          </>
                        )}
                        <TableCell align="right" style={{width: '10%'}}>
                          <span className="text-spenda-primarytext font-medium font-poppins text-base">
                            {PriceFormat(totalInvoiceAmount)}
                          </span>
                        </TableCell>
                        <TableCell align="right" style={{width: '10%'}}>
                          <span className="text-spenda-primarytext font-medium font-poppins text-base">
                            {PriceFormat(totalTransactionAmount, '$', 2)}
                          </span>
                        </TableCell>
                        <TableCell align="right" style={{width: '10%'}}>
                          <span className="text-spenda-primarytext font-medium font-poppins text-base">
                            {PriceFormat(totalPaidAmount)}
                          </span>
                        </TableCell>
                        {t2tV3 && batchDetails?.debitNotes?.length ? (
                          <TableCell align="right" style={{width: '10%'}}>
                            <span className="text-spenda-primarytext font-medium font-poppins text-base">
                              {PriceFormat(totalDebitNoteAmount)}
                            </span>
                          </TableCell>
                        ) : null}
                        {!t2tV3 || (t2tV3 && !batchDetails?.linkedDocumentGUID) ? (
                          <TableCell align="right" style={{width: '10%'}}>
                            <span className="text-spenda-primarytext font-medium font-poppins text-base">
                              {PriceFormat(totalComtdAmount)}
                            </span>
                          </TableCell>
                        ) : null}
                        <TableCell align="right" style={{width: '10%'}}>
                          <span className="text-spenda-primarytext font-medium font-poppins text-base ">
                            {PriceFormat(totalOutstandingAmount)}
                          </span>
                        </TableCell>
                        <TableCell align="left" style={{width: '9%'}}>
                          <span className="text-spenda-primarytext font-medium font-poppins text-base flex items-center"></span>
                        </TableCell>
                        <TableCell align="center" style={{width: '5%'}}>
                          <SendRoundedIcon
                            className={`${
                              credit.mappedStatus !== LabelPaymentBatchCrDrStatusTypes.Open ? 'cursor-pointer' : null
                            }`}
                            onClick={() =>
                              credit.mappedStatus !== LabelPaymentBatchCrDrStatusTypes.Open
                                ? handleRemittanceClick(credit)
                                : null
                            }
                            fontSize="small"
                            {...css({
                              color: `${
                                credit.mappedStatus !== LabelPaymentBatchCrDrStatusTypes.Open ? '#1C78AD' : '#C5C5C5'
                              }`,
                            })}
                            data-autoid={`imgMappedStatus-${credit.supplierID}`}
                          />
                        </TableCell>
                      </TableRow>
                      {credit.purchaseInvoices?.map((inv: IBatchReviewPurchaseInvoice) => {
                        const paidAmount =
                          t2tV3 && inv.debitNoteID
                            ? Number(inv.paymentAmount || 0)
                            : Number((inv.totalInc - inv.balance || 0)?.toFixed(2));
                        const committedAmount = Number((inv.balance - (inv.remnantBalance || 0) || 0)?.toFixed(2));
                        const debitNoteAmount = t2tV3 && inv.debitNoteID ? Number(inv.appliedAmount || 0) : 0;
                        const outstandingAmount = Number(
                          (
                            inv.totalInc -
                              (t2tV3 && inv.debitNoteID
                                ? paidAmount + debitNoteAmount
                                : paidAmount + committedAmount) || 0
                          )?.toFixed(2)
                        );
                        const transactionAmount =
                          t2tV3 && inv.debitNoteID ? Number(inv.totalInc || 0) : Number(inv.appliedAmount || 0);

                        return (
                          <TableRow
                            key={`collapse-${credit.supplierID}-${inv.TransID}`}
                            className={breakDownSupplierId !== credit.supplierID ? 'd-none' : ''}
                          >
                            <TableCell align="center"></TableCell>
                            <TableCell align="left" style={{width: '13%'}}>
                              <span
                                className="text-spenda-primarytext font-medium font-poppins text-base underline cursor-pointer"
                                onClick={() => {
                                  setInvoiceId({
                                    transId: inv.purchaseInvoiceID || 0,
                                  });
                                  setShowInvoice(true);
                                }}
                                data-autoid={`lnk-${credit.supplierID}`}
                              >
                                {inv.refNumber}
                              </span>
                            </TableCell>
                            {!t2tV3 && (
                              <>
                                <TableCell align="left" style={{width: '15%'}}>
                                  <span className="text-spenda-primarytext font-medium font-poppins text-base"></span>
                                </TableCell>
                                <TableCell align="left" style={{width: '14%'}}>
                                  <span className="text-spenda-primarytext font-medium font-poppins text-base"></span>
                                </TableCell>
                              </>
                            )}
                            <TableCell align="right" style={{width: '10%'}}>
                              <span className="text-spenda-primarytext font-medium font-poppins text-base">
                                {PriceFormat(inv.totalInc)}
                              </span>
                            </TableCell>
                            <TableCell align="right" style={{width: '10%'}}>
                              <span className="text-spenda-primarytext font-medium font-poppins text-base">
                                {PriceFormat(transactionAmount, '$', 2)}
                              </span>
                            </TableCell>
                            <TableCell align="right" style={{width: '10%'}}>
                              <span className="text-spenda-primarytext font-medium font-poppins text-base">
                                {PriceFormat(paidAmount)}
                              </span>
                            </TableCell>
                            {!t2tV3 || (t2tV3 && !batchDetails?.linkedDocumentGUID) ? (
                              <TableCell align="right" style={{width: '10%'}}>
                                <span className="text-spenda-primarytext font-medium font-poppins text-base">
                                  {PriceFormat(committedAmount)}
                                </span>
                              </TableCell>
                            ) : null}
                            {t2tV3 && batchDetails?.debitNotes?.length ? (
                              <TableCell align="right" style={{width: '10%'}}>
                                {debitNoteAmount ? (
                                  <APTooltip
                                    arrow
                                    placement="bottom-end"
                                    title={
                                      <React.Fragment>
                                        <Typography className="text-black-800 font-poppins text-center" variant="small">
                                          <ul className="w-[230px]">
                                            <li className="flex justify-between items-center pb-1">
                                              <Typography variant="small" className="font-poppins">
                                                Ref No:
                                              </Typography>
                                              <Typography
                                                variant="small"
                                                className="text-black-800 font-poppins font-semibold"
                                              >
                                                {credit.debitRefNo}
                                              </Typography>
                                            </li>
                                            <li className="flex justify-between items-center pb-1">
                                              <Typography variant="small" className="font-poppins">
                                                Starting Balance:
                                              </Typography>
                                              <Typography
                                                variant="small"
                                                className="text-black-800 font-poppins font-semibold"
                                              >
                                                {PriceFormat(credit.totalInc)}
                                              </Typography>
                                            </li>
                                            <li className="flex justify-between items-center">
                                              <Typography variant="small" className="font-poppins">
                                                Remaining Balance:
                                              </Typography>
                                              <Typography
                                                variant="small"
                                                className="text-black-800 font-poppins font-semibold"
                                              >
                                                {PriceFormat(credit.balance || 0)}
                                              </Typography>
                                            </li>
                                          </ul>
                                        </Typography>
                                      </React.Fragment>
                                    }
                                  >
                                    <span className="text-spenda-primarytext font-medium font-poppins text-base">
                                      {PriceFormat(debitNoteAmount)}
                                    </span>
                                  </APTooltip>
                                ) : (
                                  <span className="text-spenda-primarytext font-medium font-poppins text-base">
                                    {PriceFormat(debitNoteAmount)}
                                  </span>
                                )}
                              </TableCell>
                            ) : null}
                            <TableCell
                              align="right"
                              style={{width: t2tV3 && !batchDetails?.linkedDocumentGUID ? '20%' : '10%'}}
                            >
                              <span className="text-spenda-primarytext font-medium font-poppins text-base">
                                {PriceFormat(outstandingAmount)}
                              </span>
                            </TableCell>
                            <TableCell style={{width: '9%'}}>
                              <span className="text-spenda-primarytext font-medium font-poppins text-base flex items-center">
                                {credit.mappedStatus && renderPaymentLineStatus(credit)}
                              </span>
                            </TableCell>
                            <TableCell style={{width: '5%'}}></TableCell>
                          </TableRow>
                        );
                      })}
                    </React.Fragment>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};
