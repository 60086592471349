import {IActionRes, IPagedActionResults, IActionResults} from './../model/ActionResults';
import {useState, useEffect} from 'react';
import useHttp from '../hooks/useHttp';
import {ICustomer, ICustomerFilter} from '../model/customer/Customer';
import {DatTypes} from '../model/constants/Constants';

export const useCustomersAPI = () => {
  const {GET, POST, PUT, isLoading} = useHttp();

  const save = (customer: ICustomer): Promise<IActionResults<ICustomer>> => {
    return POST(`customers`, {Object: customer});
  };
  const saveContacts = (id: number, customer: Partial<ICustomer>[]): Promise<IActionResults<ICustomer>> => {
    return POST(`Spenda/Customers/${id}/Contacts`, {Contacts: customer});
  };

  const getCustomer = (id: number): Promise<ICustomer> => {
    return GET(`customers/${id}`, id).then((data: IActionResults<ICustomer>) => data.Value);
  };

  const getCustomerById = (id: number): Promise<ICustomer> => {
    return GET(`v3/customers/${id}`, id).then((data: IActionResults<ICustomer>) => data.Value);
  };

  const getOperationalCustomerById = async (id: number): Promise<ICustomer[]> => {
    return GET(`v3/customers/${id}/operational`, id).then((data: IActionResults<ICustomer[]>) => data.Value);
  };

  const getCustomerByReferenceNo = (Search: string): Promise<ICustomer[]> => {
    return GET(
      `customers?LastRowNumber=0&MaxResults=1&SortField=&SortAsc=true&Search=${Search}&StatusStrings=&Statuses=&IsExactMatch=true&StartDate=&EndDate=&IncludeDeleted=&GetCountOnly=&ParentID=&IsSystem=&IsShowAccountCustomersOnly=false&IsCODorPrePaidAccountCustomersOnly=`
    ).then((data: IActionResults<ICustomer[]>) => data.Value);
  };
  const getAccountCustomerByReferenceNo = (Search: string): Promise<ICustomer[]> => {
    return GET(
      `customers?LastRowNumber=0&MaxResults=1&SortField=&SortAsc=true&Search=${Search}&StatusStrings=&Statuses=&IsExactMatch=true&StartDate=&EndDate=&IncludeDeleted=&GetCountOnly=&ParentID=&IsSystem=&IsShowAccountCustomersOnly=true&IsCODorPrePaidAccountCustomersOnly=`
    ).then((data: IActionResults<ICustomer[]>) => data.Value);
  };
  const list = (filter: any): Promise<IPagedActionResults<ICustomer[]>> => {
    return GET('customers', filter).then((data: IPagedActionResults<ICustomer[]>) => data);
  };
  const dashboard = (filter: any): Promise<IPagedActionResults<ICustomer[]>> => {
    return GET('dashboard', filter).then((data: IPagedActionResults<ICustomer[]>) => data);
  };

  const adaptorTypes = (): Promise<any> => {
    return GET('adaptor/GetAdaptorByType?adaptorTypes=Accounts').then((data: any) => data);
  };
  const tennatUpdate = (AdaptorID: number, AdaptorName: string, websiteId: number): Promise<any> => {
    return POST('synk/isauthorized', {AdaptorID, AdaptorName, websiteId}).then((data: any) => data);
  };
  const tennatDetailUpdate = (
    AdaptorID: number,
    AdaptorName: string,
    DatTypeID: number,
    websiteId: number
  ): Promise<any> => {
    return POST('workflow/queue', {AdaptorID, AdaptorName, DatTypeID, websiteId}).then((data: any) => data);
  };
  const getTennatById = (id: number): Promise<any> => {
    return GET(`workflow/${id}`, id).then((data: any) => data.Value);
  };

  const saveCustomer = (customer: ICustomer, websiteId: number): Promise<any> => {
    return POST('customers', { Object: customer, websiteId });
  };

  const updateCustomer = (id: number, customer: Partial<ICustomer>, websiteId: number): Promise<ICustomer> => {
    return PUT(`Customers/${id}`, {Object: customer, websiteId});
  };

  const linkToGenericClass = (customersId: number, customerToLinkID: number): Promise<IActionRes> => {
    return PUT(`v3/customers/${customersId}/class?classid=${customerToLinkID}`).then(
      (response: IActionRes) => response
    );
  };

  const linkToAccountCustomers = (customersId: number, customerToLinkID: number): Promise<IActionRes> => {
    return PUT(`v3/customers/${customersId}/link?customerToLinkID=${customerToLinkID}`).then(
      (response: IActionRes) => response
    );
  };

  const unLinkFromCustomerAccount = (customersId: number): Promise<IActionRes> => {
    return PUT(`v3/customers/${customersId}/unlink?customerToUnlinkID=`).then((response: IActionRes) => response);
  };

  const unLinkFromCustomerClass = (customersId: number): Promise<IActionRes> => {
    return PUT(`v3/customers/${customersId}/class?classid=undefined`).then((response: IActionRes) => response);
  };

  const getcustomerStatements = (accountCustomerId: number): Promise<ICustomer> => {
    return GET(`customerStatements/customer/${accountCustomerId}?MaxResults=1&RequestedList=1&StartRow=1`).then(
      (data: any) => data.Value
    );
  };

  return {
    save,
    list,
    saveCustomer,
    getCustomer,
    updateCustomer,
    isLoading,
    linkToAccountCustomers,
    linkToGenericClass,
    unLinkFromCustomerAccount,
    unLinkFromCustomerClass,
    getCustomerById,
    getCustomerByReferenceNo,
    adaptorTypes,
    tennatUpdate,
    tennatDetailUpdate,
    getTennatById,
    dashboard,
    getAccountCustomerByReferenceNo,
    getcustomerStatements,
    saveContacts,
    getOperationalCustomerById,
  };
};

export const useLoadCustomers = (isShowAccountCustomersOnly: boolean = false) => {
  let pageSize = 10;

  const initialFilterState = {
    DatTypeID: DatTypes.Customers,
    MaxResults: pageSize,
    SortAsc: false,
    SortField: 'CreatedDateTime',
    isShowAccountCustomersOnly,
    IsShowActiveOnly: true,
  } as ICustomerFilter;

  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<ICustomer[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [moreToGet, setMoreToGet] = useState<boolean>(true);
  const [error, setError] = useState<any>();
  const [searchFilter, setSearchFilter] = useState<ICustomerFilter>(initialFilterState);

  const {dashboard} = useCustomersAPI();

  const getCustomersList = async () => {
    setLoading(true);

    if (searchFilter.StartRow === 1) {
      setItems([]);
      setMoreToGet(true);
    }

    try {
      const {Value, MoreToGet, TotalRecordCount, LastRowNo} = await dashboard(searchFilter);
      let items: ICustomer[] = Value || [];

      setItems(items);
      setTotalCount(TotalRecordCount);
      setMoreToGet(MoreToGet);
      setCurrentPage(Math.ceil(LastRowNo / (searchFilter.MaxResults || pageSize)));
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCustomersList();
  }, [searchFilter]);

  return {
    loading,
    items,
    totalCount,
    pageSize: searchFilter.MaxResults || pageSize,
    currentPage,
    moreToGet,
    error,
    searchFilter,
    getCustomersList,
    setSearchFilter,
  };
};

export const useCustomerLinking = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [response, setResponse] = useState<IActionRes>();
  const [error, setError] = useState<any>();
  const {linkToGenericClass, linkToAccountCustomers} = useCustomersAPI();
  const {unLinkFromCustomerClass, unLinkFromCustomerAccount} = useCustomersAPI();

  const linkGenericClass = async (customerID: number, customerClassID: number) => {
    setLoading(true);
    try {
      const response: IActionRes = await linkToGenericClass(customerID, customerClassID);
      setResponse(response);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const linkAccountCustomers = async (customerID: number, customerAccountID: number) => {
    setLoading(true);
    try {
      const response: IActionRes = await linkToAccountCustomers(customerID, customerAccountID);
      setResponse(response);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const unLinkCustomerAccount = async (customerID: number) => {
    setLoading(true);
    try {
      const response: IActionRes = await unLinkFromCustomerAccount(customerID);
      setResponse(response);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const unLinkCustomerClass = async (customerID: number) => {
    setLoading(true);
    try {
      const response: IActionRes = await unLinkFromCustomerClass(customerID);
      setResponse(response);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    response,
    error,
    linkGenericClass,
    linkAccountCustomers,
    unLinkCustomerAccount,
    unLinkCustomerClass,
  };
};
