import React from 'react';
import {Button, Dialog, DialogBody, DialogFooter, Typography} from 'spenda-ui-react';
import {IconQuoteAccepted} from '../../assets/svg/quotes-management/IconQuoteAccepted';
import {IconQuoteRejected} from '../../assets/svg/quotes-management/IconQuoteRejected';
import {useFlags} from 'launchdarkly-react-client-sdk';

interface IQuoteAcceptedRejectedDialog {
  open: boolean;
  isAccepted?: boolean;
  handleClose: () => void;
  handleOpen: () => void;
  quoteName?: string;
}

export const QuoteAcceptedRejectedDialog = (props: IQuoteAcceptedRejectedDialog) => {
  const {open, isAccepted, handleOpen, handleClose, quoteName} = props;
  const {globalLimepay} = useFlags();

  return (
    <Dialog
      open={open}
      handler={() => {}}
      className={`flex !w-full !min-w-[20%] !max-w-[90%] flex-col items-center justify-center sm:block sm:h-auto sm:!max-w-[339px] sm:p-0`}
    >
      <DialogBody className="my-[4.5rem] flex flex-col items-center justify-center gap-10">
        {isAccepted ? <IconQuoteAccepted /> : <IconQuoteRejected />}
        <Typography className={`${isAccepted ? 'mx-2 text-center text-black-900' : 'text-[#C55D44]'}`} variant="h2">
          {isAccepted ? 'Thank you for accepting the quote' : 'Quote rejected'}
        </Typography>
        <div className="flex w-[234px] flex-col items-center justify-center gap-5 text-center">
          {isAccepted ? (
            <div className="flex flex-col gap-5 text-[#707070]">
              <Typography variant="small">You have successfully accepted the quote {quoteName}.</Typography>
              <Typography variant="small">
                If you have any questions or queries, then feel free to get in contact with us.
              </Typography>
            </div>
          ) : (
            <Typography variant="small">Your quote has been rejected.</Typography>
          )}
        </div>
      </DialogBody>
      <DialogFooter className="p-2.5">
        <div className="flex w-full items-center justify-center rounded-lg p-2 sm:bg-[#f1f1f1]">
          <div className="flex gap-2">
            <div className="flex gap-2">
              <Button onClick={isAccepted && globalLimepay ? handleOpen : handleClose}>
                {isAccepted && globalLimepay ? 'Okay' : 'Close'}
              </Button>
            </div>
          </div>
        </div>
      </DialogFooter>
    </Dialog>
  );
};
