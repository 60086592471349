import {IReminderRequestResponse} from './useAlertAPI';
import {IConnectedCustomer} from '../model/customer/ConnectedCustomer';
import useHttp from '../hooks/useHttp';

export const useConnectedCustomerAPI = () => {
  const {GET, isLoading} = useHttp();

  const getConnectedCustomer = (isUseNewEndpoint: boolean, id: number, filter?: any): Promise<IConnectedCustomer> => {
    if (isUseNewEndpoint) {
      return GET(`Spenda/Payments/AccountsReceivable/Supplier/ConnectedCustomerDetails/${id}`);
    }
    return GET(`connectedCustomer/${id}`, filter);
  };

  const getLastReminder = (customerId: number): Promise<IReminderRequestResponse> => {
    return GET(`connectedCustomer/${customerId}/lastreminder`);
  };

  const getLastInvoiceSentReminder = (invoiceId: number): Promise<IReminderRequestResponse> => {
    return GET(`connectedCustomer/${invoiceId}/lastinvoicesentreminder`);
  };

  return {
    getConnectedCustomer,
    getLastReminder,
    getLastInvoiceSentReminder,
    isLoading,
  };
};
