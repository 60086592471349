import React from 'react';

export const ClockIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15px" height="15px" fill="none" {...props}>
    <g className="fill-primary">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C15.9952 3.5837 12.4163 0.00477656 8 0ZM8 14.6667C4.3181 14.6667 1.33333 11.6819 1.33333 8C1.33333 4.3181 4.3181 1.33333 8 1.33333C11.6819 1.33333 14.6667 4.3181 14.6667 8C14.6626 11.6802 11.6802 14.6626 8 14.6667Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00021 4C7.63202 4 7.33355 4.29848 7.33355 4.66667V7.55L5.08621 8.958C4.77325 9.15351 4.67804 9.5657 4.87355 9.87867C5.06906 10.1916 5.48125 10.2868 5.79421 10.0913L8.35421 8.49133C8.5509 8.3681 8.66934 8.15143 8.66688 7.91933V4.66667C8.66688 4.29848 8.3684 4 8.00021 4Z"
      />
    </g>
  </svg>
);
