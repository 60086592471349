import React, {useState} from 'react';
import {HeaderWidget} from '../WidgetPanel/HeaderWidget';
import {Button, IconButton} from 'spenda-ui-react';
import IconFilter from '../../../assets/svg/IconFilter';
import {ARSearchBar} from '../../../components/AccountsReceivable/ARSearchBar';
import {useLocation} from 'react-router-dom';
import {STabs} from '../../../components/tabs/STabs';
import {StatementView} from './StatementView';
import {SettlementView} from './SettlementView';
import {TransactionView} from './TransactionView';
import IconDownloadClaim from '../../../assets/svg/IconDownloadClaim';

export const TableView = () => {
  const query = new URLSearchParams(useLocation().search);
  const selectedTab = query.get('tab') || undefined;
  const [productTab, setProductTab] = useState<number>(selectedTab ? parseInt(selectedTab) : 0);
  return (
    <>
      <HeaderWidget />
      <div className="flex h-full !w-full items-start justify-center rounded-[8px] bg-white">
        <div className="relative flex w-full flex-row items-center justify-between p-2.5">
          <div className="flex h-[inherit] flex-1 flex-col items-center justify-start">
            <STabs
              value={productTab}
              className="!font-poppins"
              handleChange={newValue => setProductTab(newValue)}
              tabs={[
                {
                  label: 'Statement',
                  content: <StatementView />,
                },
                {
                  label: 'Settlement',
                  content: <SettlementView />,
                },
                {
                  label: 'Transaction',
                  content: <TransactionView />,
                },
              ]}
              isInAPContext={true}
            ></STabs>
          </div>
          <div className="absolute right-2.5 top-2.5 flex flex-row items-center justify-end gap-2.5">
            <div className="max-w-[360px]">
              <ARSearchBar
                type="advance"
                reverse
                searchCategories={[
                  {value: 'RefNumber', label: 'Ref Number'},
                  {value: 'VendorName', label: 'Vendor Name'},
                  {value: 'Amount', label: 'Amount'},
                ]}
                searchString={''}
                searchCategory={'RefNumber'}
                isSearching={false}
                onSubmit={() => null}
                dataAutoIdSuffix={'PSBLTableHeader'}
              />
            </div>
            <IconButton variant="outlined" data-autoid={'btnAdvanceFilter'}>
              <IconFilter />
            </IconButton>
            {productTab === 2 && (
              <Button variant="outlined">
                <div className="flex items-center gap-x-1">
                  <IconDownloadClaim /> CSV
                </div>
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
