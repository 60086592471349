import React, {useState, useEffect, useContext} from 'react';
import {Formik} from 'formik';

import {
  PermissionTypeIdEnum,
  UserRoleEnum,
  IUserRolePaymentBatchPermission,
  IUserRolePaymentPermission,
} from '../../model/user/UserAccountInfo';
import {SButton} from '../buttons/Button';
import {AlertDialogSlide} from '../dialog/AlertDialogSlide';
import useUsersAPI from '../../services/useUsersAPI';
import {APFooter} from './APFooter';

import {Box, makeStyles} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import backButton from '../../assets/png/back-button.png';
import {PaymentPermissionForm} from '../form/PaymentPermissionForm';
import {Toast} from '../../utils/Toast';
import {PaymentBatchPermissionType} from '../../model/constants/Constants';
import APContext from '../../context/ap-context/APContext';

const useCommonPaymentPermissionStyles = makeStyles({
  apOnboardingModal: {
    maxWidth: '800px',
  },
  apBusinessHeader: {
    borderBottom: '1px solid #CCCCCC',
    '& h2': {
      fontFamily: 'Poppins, sans-serif !important',
      fontWeight: 300,
      fontSize: '28px',
      color: '#333333',
    },
  },
  backBtn: {
    maxWidth: '40px',
    height: '40px',
  },
  boxHeight: {
    '& h2': {
      fontFamily: 'Poppins, sans-serif !important',
      fontSize: '28px',
    },
    '& p, button, span, h1, h2, h3, h4, h5, h6, table, td, th': {
      fontFamily: 'Poppins, sans-serif !important',
    },
  },
  permissionPager: {
    color: '#444343',
    fontSize: '12px',
    fontWeight: 600,
    '& span': {
      color: '#707070',
      fontWeight: 400,
    },
  },
});
export interface ICommonPaymentPermissionProps {
  handleClose?: () => void;
  onBackPress?: (backStep?: number) => void;
  onNextClick?: () => void;
  isAPSettings?: boolean;
  isAPOnboardingV1?: boolean;
}
interface RolePermission {
  [key: string]: boolean;
}
interface UserRole {
  [key: string]: RolePermission;
}

export const CommonPaymentPermission = (props: ICommonPaymentPermissionProps) => {
  const classes = useCommonPaymentPermissionStyles();
  const {saveUserRolePaymentBatch, getUserRolePaymentBatch} = useUsersAPI();

  const [userRole, setUserRole] = useState<UserRole>({});
  const {setUserRoleBatchPermissions, getUserRoleBatchPermissions} = useContext(APContext);
  const [confirmationDialog, setConfirmationDialog] = useState({
    openDialog: false,
    backIconClick: false,
    closeIconClick: false,
  });

  useEffect(() => {
    getUserRolePaymentBatchList();
  }, []);

  const getUserRolePaymentBatchList = async () => {
    const userRoleRes = await getUserRolePaymentBatch();
    const _userRole = {} as UserRole;
    if (userRoleRes.Permissions[0]?.RolePermissions?.length === 0) {
      _userRole[UserRoleEnum.Administrator] = {
        [PermissionTypeIdEnum.CreateBatch]: true,
        [PermissionTypeIdEnum.ApproveBatch]: true,
      };
      _userRole[UserRoleEnum.Standard] = {
        [PermissionTypeIdEnum.CreateBatch]: true,
        [PermissionTypeIdEnum.ApproveBatch]: false,
      };
      _userRole[UserRoleEnum.Primary] = {
        [PermissionTypeIdEnum.CreateBatch]: true,
        [PermissionTypeIdEnum.ApproveBatch]: true,
      };
    } else {
      userRoleRes &&
        userRoleRes.Permissions.forEach(role => {
          const _permission = {} as RolePermission;
          role.RolePermissions.forEach(permission => {
            _permission[permission.PermissionTypeID] = permission.IsActive;
          });
          _userRole[role.UserRoleID] = _permission;
        });
    }
    setUserRole(_userRole);
  };

  const onBackPress = (dirty: boolean) => {
    if (dirty) {
      confirmationDialog.openDialog = true;
      confirmationDialog.backIconClick = true;
      setConfirmationDialog({...confirmationDialog});
    } else {
      props.onBackPress && props.onBackPress();
    }
  };

  const onClose = (dirty: boolean) => {
    if (dirty) {
      confirmationDialog.openDialog = true;
      confirmationDialog.closeIconClick = true;
      setConfirmationDialog({...confirmationDialog});
    } else {
      props.handleClose && props.handleClose();
    }
  };

  const updateUserRolePermissions = async () => {
    const permissionsResponse: PaymentBatchPermissionType[] = await getUserRoleBatchPermissions();
    setUserRoleBatchPermissions(permissionsResponse);
  };

  const onSave = async (values: UserRole) => {
    const data = [] as IUserRolePaymentPermission[];
    Object.entries(values).forEach(([key, value]: [string, RolePermission]) => {
      const rolePermissions = [] as IUserRolePaymentBatchPermission[];
      Object.entries(value).forEach(([key1, value1]: [string, boolean]) => {
        rolePermissions.push({
          PermissionTypeID: Number(key1) as any,
          IsActive: value1,
        });
      });
      data.push({
        UserRoleID: Number(key) as any,
        RolePermissions: rolePermissions,
      });
    });

    await saveUserRolePaymentBatch({Permissions: data});

    if (confirmationDialog.backIconClick) {
      props.onBackPress && props.onBackPress();
    } else if (confirmationDialog.closeIconClick) {
      props.handleClose && props.handleClose();
    } else {
      props.onNextClick && props.onNextClick();
    }
    if (props.isAPSettings) {
      Toast.info('User Permissions updated');
    }
    updateUserRolePermissions();
  };

  return (
    <Formik enableReinitialize initialValues={userRole} onSubmit={onSave}>
      {({values, handleChange, handleSubmit, setValues, dirty}) => (
        <div
          className={`${
            props.isAPOnboardingV1 ? '' : 'items-center'
          } text-xl spenda-color flex justify-center  h-full max-w-full `}
        >
          <div
            className={`${
              props.isAPSettings || props.isAPOnboardingV1
                ? 'w-full'
                : `${classes.apOnboardingModal} px-3 py-3 modal-box `
            }`}
          >
            {!props.isAPSettings && !props.isAPOnboardingV1 && (
              <Box className={`${classes.apBusinessHeader} flex justify-between -mx-3 px-3 pb-3`}>
                <span className="cursor-pointer" onClick={() => onBackPress(dirty)} data-autoid="btnBack">
                  <img src={backButton} alt="Back Button" className={classes.backBtn} />
                </span>
                <h2 className={`text-center w-full`}>User Roles &amp; Permissions</h2>
                <Box className="text-right flex justify-end">
                  <span className="ap-onboarding-close-icon" onClick={() => onClose(dirty)} data-autoid="btnClose">
                    <CloseIcon />
                  </span>
                </Box>
              </Box>
            )}
            <div className={`${props.isAPSettings || props.isAPOnboardingV1 ? '' : `${classes.boxHeight} mt-12 px-5`}`}>
              <PaymentPermissionForm
                {...props}
                setUserRole={setUserRole}
                values={values}
                setValues={setValues}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                isAPSettings={props.isAPSettings}
                isAPOnboardingV1={props.isAPOnboardingV1}
              />
            </div>

            {!props.isAPSettings && (
              <APFooter
                isAPOnboardingV1={props.isAPOnboardingV1}
                onNextClick={() => handleSubmit()}
                secondaryButtonLabel="Skip"
                isCancelButton={true}
                handleCancelClick={() => handleSubmit()}
              />
            )}
          </div>
          {confirmationDialog.openDialog && !props.isAPSettings && (
            <AlertDialogSlide
              maxWidth="xs"
              title="Discard Changes"
              footer={
                <div className="w-full flex justify-between px-4 py-2">
                  <SButton
                    margin="0 0.25rem"
                    color="transparent"
                    border="1px solid #1C78AD"
                    textColor="blueShade"
                    lineHeight="m"
                    width="m"
                    type="button"
                    onClick={() => (confirmationDialog.backIconClick ? props.onBackPress?.() : props.handleClose?.())}
                    data-autoid="btnNo"
                  >
                    No
                  </SButton>
                  <SButton
                    margin="0 0.25rem"
                    color="blueShade"
                    textColor="white"
                    lineHeight="m"
                    width="m"
                    type="button"
                    onClick={() => onSave(values)}
                    data-autoid="btnYes"
                  >
                    Yes
                  </SButton>
                </div>
              }
            >
              <p className="pb-3 font-medium text-center">Do you want to save changes before you leave?</p>
            </AlertDialogSlide>
          )}
        </div>
      )}
    </Formik>
  );
};
