import React, {useEffect, useState} from 'react';
import {SDialog} from '../modals/modalSpendaMeterialUI';
import {Box, DialogActions, DialogContent, DialogTitle, makeStyles} from '@material-ui/core';
import SecurityCode, {CodeProps} from '../inputs/SecurityCode';
import {SuccessPennyVerificationDialog} from './SuccessPennyVerificationDialog';
import {ErrorAnimation, ProcessingAnimation} from '../../assets/svg/animations/LottieAnimations';
import {FailedPennyVerificationDialog} from './FailedPennyVerificationDialog';
import {IconPennyDrop} from '../../assets/svg/IconPennyDrop';
import {SupportDialog} from './SupporDialog';
import useSpendaPaymentServicesAPI, {IAccountResponse} from '../../services/useSpendaPaymentServicesAPI';
import {AccountType, PaymentProviderName, VerificationStatus} from '../../model/constants/Constants';
import {SButton} from '../buttons/Button';
import {useAccountReceivableBatchAPI} from '../../services/useAccountReceivableBatchAPI';
import {IARBatchResponse} from '../../model/accounts-receivable/batch';

interface IPennyVerificationDialogProps {
  dialogStage: PennyVerificationDialogStages;
  setDialogStage: (newStage: PennyVerificationDialogStages) => void;
  bankAccountDetails: IAccountResponse | undefined;
  onRemoveAccount?: () => void;
  loadPaymentMethods?: () => void;
  supplierId?: number;
  reloadAccount: () => void;
  closeDialog?: () => void;
  successCallBack?: () => void;
  dialogTitle?: () => JSX.Element;
  psblBatch?: IARBatchResponse;
}

export enum PennyVerificationDialogStages {
  NONE,
  ADD_BANK_DETAILS,
  CHECKING_BANK_ACCOUNT_STATUS,
  PLEASE_WAIT,
  VERIFY_BANK_ACCOUNT,
  FAILED,
  RETRY_REQUIRED,
  NO_DEPOSIT,
  CARD_VERIFICATION,
}

export const PennyVerificationDialog = (props: IPennyVerificationDialogProps) => {
  const [code, setCode] = useState<string>('');
  const [clickConfirmCount, setClickConfirmCount] = useState<number>(0);
  const [isShowSuccessDialog, setIsShowSuccessDialog] = useState(false);
  const [isShowSupport, setIsShowSupport] = useState(false);
  const [isShowNoDeposit, setIsShowNoDeposit] = useState(false);
  const [reloadTimeout, setReloadTimeout] = useState<NodeJS.Timeout | undefined>(undefined);

  const {setPaymentAccountGUID} = useAccountReceivableBatchAPI({
    linkedSupplierID: props.supplierId!,
  });

  const isCreditCard = props.bankAccountDetails?.accountType !== AccountType.BANK_TRANSFER;
  const useStyles = makeStyles({
    dialog: {
      fontSize: 18,
      fontWeight: 500,
      justifyContent: 'center',
      maxWidth: 600,
      minHeight: props.dialogStage === PennyVerificationDialogStages.CHECKING_BANK_ACCOUNT_STATUS ? 415 : 470,
      position: 'absolute',
    },
    dialogTitle: {
      color: '#0082BA',
      paddingTop: props.dialogStage === PennyVerificationDialogStages.CHECKING_BANK_ACCOUNT_STATUS ? 0 : 38,
    },
    dialogActions: {
      flexDirection: 'column',
      paddingBottom: 44,
    },
  });
  const classes = useStyles();

  const disabled = code.trim().length < 3;

  const pennyVerificationAPI = useSpendaPaymentServicesAPI();
  const pennyCodeVer = {
    verificationCode: code,
  };

  const onSubmit = () => {
    if (clickConfirmCount < 3) {
      const accountGUID = props.bankAccountDetails?.accountGUID;
      if (accountGUID) {
        pennyVerificationAPI
          .verify(PaymentProviderName.Spenda_Payment_Services, accountGUID, pennyCodeVer)
          .then(res => {
            if (res.verificationStatus === VerificationStatus.VERIFIED) {
              if (props.psblBatch?.invoicePaymentAllocationID) {
                setPaymentAccountGUID({
                  invoicePaymentAllocationID: props.psblBatch?.invoicePaymentAllocationID!,
                  paymentAccountGUID: accountGUID,
                });
              }

              props.successCallBack && props.successCallBack();
              setIsShowSuccessDialog(true);
            }
          });
        handleClick();
      }
    }
    if (clickConfirmCount > 2) {
      setIsShowSupport(true);
    }
  };

  const handleClick = () => {
    setClickConfirmCount(prev => {
      const val = prev + 1;
      return val;
    });
  };

  const onEnterCode = () => {
    onSubmit();
  };

  const onComeBackLater = () => {
    setCode('');
    onClose();
  };

  const onDidntReceiveDeposit = () => {
    props.setDialogStage(PennyVerificationDialogStages.NO_DEPOSIT);
    setIsShowNoDeposit(true);
  };

  const startVerification = () => {
    if (props.bankAccountDetails) {
      const guid = props.bankAccountDetails.accountGUID;
      pennyVerificationAPI.initiateVerification(PaymentProviderName.Spenda_Payment_Services, guid).then(() => {
        props.reloadAccount();
      });
    }
  };

  const onClose = () => {
    if (reloadTimeout) {
      clearTimeout(reloadTimeout);
      setReloadTimeout(undefined);
    }
    if (props.loadPaymentMethods) {
      props.loadPaymentMethods();
    }

    props.setDialogStage(PennyVerificationDialogStages.NONE);
    if (props.closeDialog) {
      props.closeDialog();
    }
  };

  useEffect(() => {
    if (props.bankAccountDetails) {
      if (props.bankAccountDetails.verificationStatus === VerificationStatus.VERIFIED) {
        // Calling setPaymentAccountGuid API after adding payment method for batch payments
        if (props.psblBatch?.invoicePaymentAllocationID) {
          setPaymentAccountGUID({
            invoicePaymentAllocationID: props.psblBatch?.invoicePaymentAllocationID!,
            paymentAccountGUID: props.bankAccountDetails.accountGUID!,
          });
        }
        onClose();
      } else if (
        props.bankAccountDetails.verificationStatus === VerificationStatus.NOT_VERIFIED ||
        props.bankAccountDetails.verificationStatus === VerificationStatus.ATTEMPTS_EXCEEDED
      ) {
        props.setDialogStage(PennyVerificationDialogStages.VERIFY_BANK_ACCOUNT);
      } else if (props.bankAccountDetails.verificationStatus === VerificationStatus.FAILED) {
        props.setDialogStage(PennyVerificationDialogStages.FAILED);
      } else if (props.bankAccountDetails.verificationStatus === VerificationStatus.RETRY_REQUIRED) {
        props.setDialogStage(PennyVerificationDialogStages.RETRY_REQUIRED);
      } else if (props.bankAccountDetails.verificationStatus === VerificationStatus.NOT_STARTED) {
        props.setDialogStage(PennyVerificationDialogStages.CARD_VERIFICATION);
      }
      // Polling Unknown state because server is waiting for the webhook
      else if (
        props.bankAccountDetails.verificationStatus === VerificationStatus.PENDING ||
        props.bankAccountDetails.verificationStatus === VerificationStatus.UNKNOWN
      ) {
        props.setDialogStage(PennyVerificationDialogStages.PLEASE_WAIT);
        // Reloading the account will trigger this effect again, as the bank account details will be reloaded.
        // If we're still in the same state, we'll do another timeout!
        if (!reloadTimeout) {
          const timeout = setTimeout(() => {
            setReloadTimeout(undefined);
            props.reloadAccount();
          }, 5000);
          setReloadTimeout(timeout);
        }
      }
    }
  }, [props.bankAccountDetails]);

  const isAttemptsExceeded = props.bankAccountDetails?.verificationStatus === VerificationStatus.ATTEMPTS_EXCEEDED;

  const PleaseWait = () => {
    return (
      <>
        <DialogContent>
          <Box className="py-2" textAlign="center" fontFamily={'Poppins'}>
            <Box display="flex" justifyContent="center">
              <Box width="50%">
                <ProcessingAnimation width="252px" height="148px" />
              </Box>
            </Box>
            <Box paddingTop={4}>Please wait while we verify your {isCreditCard ? `card` : `account`}.</Box>
            <Box>
              <Box paddingTop={2}>
                This usually takes less 30 seconds
                {isCreditCard
                  ? `, but can take longer depending on your bank.`
                  : ` to process into your nominated bank account.`}
              </Box>
              <Box paddingTop={2} paddingBottom={2}>
                In a rush? You can come back and finish the {isCreditCard ? `card` : `account`} verification later.
              </Box>
            </Box>
            {/**************** HOTFIX TO DISABLE PENNY VERIFICATION  ****************/}
            {/* {isCreditCard ? <Box paddingTop={4}>
              We are saving your card, please wait, this will only take a moment or two.
            </Box> :
              <Box>
                <Box paddingTop={4}>
                  Processing account verification please wait ...
                </Box>
                <Box>
                  <Box paddingTop={2}>
                    This usually takes 30 seconds to process into your nominated bank account.
                  </Box>
                  <Box paddingTop={2}>
                    If you are not automatically redirected to enter the verification
                    <br /> code within 30 seconds, you can come back and finish
                    <br /> the verification later.
                  </Box>
                </Box>
              </Box>
            } */}
            {/**************** HOTFIX TO DISABLE PENNY VERIFICATION ***************/}
          </Box>
        </DialogContent>
        {/**************** HOTFIX TO DISABLE PENNY VERIFICATION ***************/}
        {/* {!isCreditCard && */}
        <DialogActions classes={{root: classes.dialogActions}}>
          <SButton
            color="yellow"
            label="white"
            lineHeight="m"
            width="m"
            onClick={onClose}
            type="button"
            borderRadius="6px"
          >
            Come Back Later
          </SButton>
        </DialogActions>
        {/**************** HOTFIX TO DISABLE PENNY VERIFICATION ***************/}
        {/* } */}
      </>
    );
  };

  const showFailureDialog =
    props.dialogStage === PennyVerificationDialogStages.RETRY_REQUIRED ||
    props.dialogStage === PennyVerificationDialogStages.FAILED;

  return (
    <>
      <SDialog open={props.dialogStage != PennyVerificationDialogStages.NONE} classes={{paper: classes.dialog}}>
        <DialogTitle classes={{root: classes.dialogTitle}}>
          {props.dialogTitle && props.dialogTitle()}
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            fontSize="34px"
            justifyContent="center"
            fontFamily={'Poppins'}
          >
            {isAttemptsExceeded ? (
              <>
                <Box display="flex" justifyContent="center">
                  <Box width="100%">
                    <ErrorAnimation />
                  </Box>
                </Box>
                <Box textAlign={'center'} color={'#C55D44'}>
                  Unsuccessful Account Verification
                </Box>
              </>
            ) : (
              props.dialogStage === PennyVerificationDialogStages.VERIFY_BANK_ACCOUNT && <IconPennyDrop />
            )}
            <Box fontFamily={'Poppins'}>
              {props.dialogStage === PennyVerificationDialogStages.VERIFY_BANK_ACCOUNT &&
                isCreditCard &&
                !isAttemptsExceeded &&
                'Finish verifying your card'}
            </Box>
            <Box>
              {props.dialogStage === PennyVerificationDialogStages.VERIFY_BANK_ACCOUNT &&
                !isCreditCard &&
                !isAttemptsExceeded &&
                'Finish verifying your account'}
            </Box>
            <Box>{props.dialogStage === PennyVerificationDialogStages.PLEASE_WAIT && 'Please Wait'}</Box>
            <Box>{props.dialogStage === PennyVerificationDialogStages.CARD_VERIFICATION && 'Card Verification'}</Box>
          </Box>
        </DialogTitle>
        {props.dialogStage === PennyVerificationDialogStages.PLEASE_WAIT && <PleaseWait />}
        {props.dialogStage === PennyVerificationDialogStages.VERIFY_BANK_ACCOUNT && (
          <Box>
            <DialogContent>
              <Box
                padding={0}
                textAlign="center"
                color={`${isAttemptsExceeded ? 'lightgray' : 'black'}`}
                fontFamily={'Poppins'}
              >
                {!isAttemptsExceeded &&
                  (isCreditCard ? (
                    <Box>
                      <Box fontFamily={'Poppins'}>
                        <br /> 1. Log into your internet banking.
                        <br /> 2. Look for a transaction from Spenda and take note
                        <br /> of the amount charged to your account.
                        <br /> 3. Enter the amount in the section below. The amount will be less than $1.
                      </Box>
                    </Box>
                  ) : (
                    <>
                      <Box
                        padding={'40px'}
                        className="font-poppins text-base font-normal"
                        style={{marginLeft: '39px', marginRight: '39px'}}
                      >
                        <ol>
                          <li>Log into your internet banking.</li>
                          <br />
                          <li>
                            Look for a transaction from <strong>Spenda</strong> and take note of the amount charged to
                            your account.
                          </li>
                          <br />
                          <li>Enter the amount in the section below. The amount will be less than $1.00.</li>
                        </ol>
                      </Box>
                    </>
                  ))}
              </Box>
              {!isAttemptsExceeded ? (
                <Box textAlign="center" paddingTop={3} justifyContent="center" display="grid">
                  <PaymentReferenceCode
                    is2SA={false}
                    onChange={setCode}
                    value={code}
                    onEnter={onEnterCode}
                  ></PaymentReferenceCode>
                </Box>
              ) : (
                <Box padding={'40px'}>
                  <Box className="" textAlign="center">
                    You have exceeded your amount of attempts. Please contact support to assist you with the
                    verification process.
                  </Box>
                </Box>
              )}
            </DialogContent>
            <DialogActions classes={{root: classes.dialogActions}}>
              <Box className="my-4 flex items-center" justifyContent="space-around" width="100%">
                <SButton
                  color="yellow"
                  lineHeight="m"
                  width="m"
                  onClick={onComeBackLater}
                  label="white"
                  type="button"
                  borderRadius="6px"
                >
                  Come Back Later
                </SButton>
                {isAttemptsExceeded ? (
                  <SButton
                    color="blue"
                    lineHeight="m"
                    width="m"
                    onClick={() => setIsShowSupport(true)}
                    type="submit"
                    borderRadius="6px"
                  >
                    Support
                  </SButton>
                ) : (
                  <SButton
                    color="blue"
                    lineHeight="m"
                    width="m"
                    onClick={onSubmit}
                    disabled={disabled}
                    type="submit"
                    borderRadius="6px"
                  >{`${clickConfirmCount < 3 ? 'Confirm' : 'Support'}`}</SButton>
                )}
              </Box>
              <Box
                onClick={onDidntReceiveDeposit}
                className="cursor-pointer"
                fontSize={16}
                color="#1C78AD"
                data-autoid="lnkNoDeposit"
                fontFamily={'Poppins'}
              >
                {isCreditCard ? `Can't see the charge?` : `Didn't receive the deposit?`}
              </Box>
            </DialogActions>
          </Box>
        )}
        {props.dialogStage === PennyVerificationDialogStages.CARD_VERIFICATION && (
          <Box>
            <DialogContent>
              <Box padding={3} textAlign="center" color="black" fontFamily="poppins">
                <Box>
                  Thanks for adding a new payment method.
                  <br />
                </Box>
                <Box>
                  Before we can begin processing payments
                  <br />
                  we need to verify your card.
                </Box>
                <Box paddingTop={2}>
                  To do this we will conduct a quick check
                  <br /> to make sure you have entered the correct details.
                  <br /> This involves charging
                  <br /> a small verification fee of less than $1
                  <br /> to your card.
                </Box>
              </Box>
            </DialogContent>
            <DialogActions classes={{root: classes.dialogActions}}>
              <Box className="my-4 flex items-center" justifyContent="space-around" width="100%">
                <SButton
                  color="yellow"
                  lineHeight="m"
                  width="m"
                  onClick={startVerification}
                  label="white"
                  type="button"
                  borderRadius="6px"
                >
                  Start Verification
                </SButton>
              </Box>
            </DialogActions>
          </Box>
        )}
        {props.dialogStage === PennyVerificationDialogStages.CHECKING_BANK_ACCOUNT_STATUS && (
          <Box>
            <DialogContent>
              <Box textAlign="center">
                <Box display="flex" justifyContent="center">
                  <Box>
                    <ProcessingAnimation />
                  </Box>
                </Box>
                <Box>Checking Account Status ...</Box>
              </Box>
            </DialogContent>
          </Box>
        )}
      </SDialog>
      {isShowSupport && <SupportDialog bankAccountDetails={props.bankAccountDetails} onClose={onClose} />}
      {(showFailureDialog || isShowNoDeposit) && props.bankAccountDetails && (
        <FailedPennyVerificationDialog
          reloadAccount={props.reloadAccount}
          supplierId={props.supplierId}
          dialogStage={props.dialogStage}
          setDialogStage={props.setDialogStage}
          onRemoveAccount={props.onRemoveAccount}
          bankAccountDetails={props.bankAccountDetails}
          onClose={onClose}
          isCreditCard={isCreditCard}
        ></FailedPennyVerificationDialog>
      )}
      {isShowSuccessDialog && (
        <SuccessPennyVerificationDialog
          isCreditCard={isCreditCard}
          onClosePennyDialog={onClose}
        ></SuccessPennyVerificationDialog>
      )}
    </>
  );
};

export const PaymentReferenceCode = (props: CodeProps) => {
  return (
    <>
      <Box display="flex">
        <Box p={0.25}>
          <label className="px-3" style={{marginTop: '5px'}}>
            <div className="text-xl text-primary">$</div>
          </label>
        </Box>
        <SecurityCode
          is2SA={props.is2SA}
          onChange={props.onChange}
          value={props.value}
          onEnter={props.onEnter}
        ></SecurityCode>
      </Box>
    </>
  );
};
