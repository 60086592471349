import { createContext, Dispatch, SetStateAction, useContext } from "react";
import { IServiceJob, ServiceJobScope } from "../../model/service-management/serviceJob";
import { ICustomer } from "../../model/customer/Customer";

interface ServiceContextType {
    scope: ServiceJobScope;
    customerDetails?: ICustomer;
    serviceJob?: IServiceJob;
    setCustomerDetails: Dispatch<SetStateAction<ICustomer>>;
    setServiceJob: Dispatch<SetStateAction<IServiceJob>>;
    setScope: Dispatch<SetStateAction<ServiceJobScope>>;
}

const ServiceContext = createContext<ServiceContextType | null>(null);

const useServiceContext = () => {
    const context = useContext(ServiceContext);
    if (!context) {
        throw new Error('useServiceContext must be used within a ServiceContextProvider');
    }
    return context;
}

export { ServiceContext, useServiceContext };