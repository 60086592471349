import React, {FunctionComponent} from 'react';
import {css} from 'glamor';
import {LabelPaymentBatchStatusTypes, PaymentBatchStatusTypes} from '../../model/constants/Constants';
import {Calendar} from '../../assets/svg/accounts-payable/Calendar';
import {Document} from '../../assets/svg/accounts-payable/Document';
import {CommentCheck} from '../../assets/svg/accounts-payable/CommentCheck';
import {PaperPlane} from '../../assets/svg/accounts-payable/PaperPlane';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import {IPaymentBatchActions} from '../../model/payment-batch/PaymentBatch';
import {NameInitials} from '../../utils/formatter';
import moment from 'moment';
import {matchPath, useLocation} from 'react-router-dom';
import {
  AUTH_BUYING_AP_APPROVE_REQUESTS_BATCH_VIEW,
  AUTH_BUYING_AP_REPORTS_VIEW_DETAILS,
  AUTH_BUYING_AP_APPROVE_REQUESTS_ABA_BATCH_VIEW,
} from '../../routes/AccountsPayableRoutes';

export interface IBatchStatus {
  status: PaymentBatchStatusTypes | undefined;
  actions?: IPaymentBatchActions[] | undefined;
}

const panelRightBody = css({
  borderRadius: '6px',
});

const filter = css({
  border: '1px solid transparent',
  width: '88px',
  borderRadius: '6px',
  height: '140px',
  margin: ' 0px 5px 0px 0px',
});

const filterHeading = css({
  fontSize: '10px',
  height: '30px',
});

const getCheckColor = (
  status: PaymentBatchStatusTypes | undefined,
  state: 'Create' | 'Sent' | 'Approved' | 'Paid',
): '#3D9F78' | '#e1e1e1' => {
  if (status === PaymentBatchStatusTypes.Completed) {
    return '#3D9F78';
  }
  if (status === PaymentBatchStatusTypes.InProgress || status === PaymentBatchStatusTypes.PaymentApproved) {
    if (state === 'Paid') {
      return '#e1e1e1';
    }
    return '#3D9F78';
  }
  if (status === PaymentBatchStatusTypes.ReadyForApproval) {
    if (state === 'Paid' || state === 'Approved') {
      return '#e1e1e1';
    }
    return '#3D9F78';
  }
  if (status === PaymentBatchStatusTypes.Open) {
    if (state === 'Paid' || state === 'Approved' || state === 'Sent') {
      return '#e1e1e1';
    }
    return '#3D9F78';
  }
  return '#e1e1e1';
};

export const BatchStatus: FunctionComponent<IBatchStatus> = ({status, actions}) => {
  const location = useLocation();
  const showActions =
    matchPath(location.pathname, {
      path: AUTH_BUYING_AP_APPROVE_REQUESTS_BATCH_VIEW,
    }) ||
    matchPath(location.pathname, {
      path: AUTH_BUYING_AP_APPROVE_REQUESTS_ABA_BATCH_VIEW,
    }) ||
    matchPath(location.pathname, {
      path: AUTH_BUYING_AP_REPORTS_VIEW_DETAILS,
    });
  return (
    <div className={`${panelRightBody} relative flex h-full flex-col bg-white`}>
      <div className="font-poppins">
        <div
          className={`flex flex-col justify-between px-3 pb-4 pt-4 ${css({
            borderBottom: '1px solid rgba(216 216 216 / 60%)',
          })}`}
        >
          <span className="text-xl text-spenda-primarytext">Recent Modification</span>
          {/* <span className="text-xs mb-4 text-spenda-primarytext">Select filters from below</span> */}
          <div className="flex justify-evenly">
            <div className={`${filter} jusity-center flex cursor-pointer flex-col items-center`}>
              <span className={`mb-2 mt-1 rounded-md px-7 py-4 ${css({backgroundColor: '#C68A1920', height: '56px'})}`}>
                <Document data-autoid={`imgDocument`} />
              </span>
              <span className={`${filterHeading} mx-2 mb-2 overflow-y-hidden text-center font-medium`}>
                Create Batch
              </span>
              <CheckCircleRoundedIcon
                className="text-xs"
                {...css({color: getCheckColor(status, 'Create')})}
                data-autoid={`imgCreateBatch`}
              />
            </div>
            <div className={`${filter} jusity-center flex cursor-pointer flex-col items-center`}>
              <span
                className={`mb-2 mt-1 rounded-md px-7  py-4 ${css({backgroundColor: '#C55D4420', height: '56px'})}`}
              >
                <PaperPlane data-autoid={`imgPlanePaper`} />
              </span>
              <span className={`${filterHeading} mx-2 mb-2 overflow-y-hidden text-center font-medium`}>
                Sent for Approval
              </span>
              <CheckCircleRoundedIcon
                className="text-xs"
                {...css({color: getCheckColor(status, 'Sent')})}
                data-autoid={`imgSendForApproval`}
              />
            </div>
            <div className={`${filter} jusity-center flex cursor-pointer flex-col items-center`}>
              <span
                className={`mb-2 mt-1 rounded-md px-7  py-4 ${css({backgroundColor: '#8178CF20', height: '56px'})}`}
              >
                <CommentCheck data-autoid={`imgComment`} />
              </span>
              <span className={`${filterHeading} mx-2 mb-2 overflow-y-hidden text-center font-medium`}>Approved</span>
              <CheckCircleRoundedIcon
                className="text-xs"
                {...css({color: getCheckColor(status, 'Approved')})}
                data-autoid={`imgApproved`}
              />
            </div>
            <div className={`${filter} jusity-center flex cursor-pointer flex-col items-center`}>
              <span
                className={`mb-2 mt-1 rounded-md px-7  py-4 ${css({backgroundColor: '#1C78AD20', height: '56px'})}`}
              >
                <Calendar data-autoid={`imgCalender`} />
              </span>
              <span className={`${filterHeading} mx-2 mb-2 overflow-y-hidden text-center font-medium`}>Paid</span>
              {status === PaymentBatchStatusTypes.Failed ? (
                <CancelRoundedIcon className="text-xs" {...css({color: '#B9624B'})} data-autoid={`imgPaymentCancel`} />
              ) : (
                <CheckCircleRoundedIcon
                  className="text-xs"
                  {...css({color: getCheckColor(status, 'Paid')})}
                  data-autoid={`imgPaymentPaid`}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="items-center justify-center overflow-y-auto px-3 py-3 font-poppins text-spenda-primarytext">
        {showActions ? (
          <div className={`flex-col items-center justify-center`}>
            {actions
              ? actions.map((action, index) => {
                  switch (action.actionName) {
                    case 'Payment':
                      return (
                        <div
                          key={index}
                          className={`flex flex-row items-center px-4 ${css({
                            borderBottom: '1px solid rgba(216 216 216 / 60%)',
                          })}`}
                        >
                          <div {...css({borderRadius: '5px'})}>
                            <p
                              {...css({
                                backgroundColor: 'rgb(129, 120, 207, 20%)',
                                color: '#8178CF',
                              })}
                              className="relative flex h-12 w-12 items-center justify-center rounded-full p-2 text-center font-poppins font-light"
                            >
                              {NameInitials(action.actionUserName)}
                            </p>
                          </div>
                          <p className={`py-5 pl-4 font-poppins text-base ${css({color: '#444343'})}`}>
                            <>
                              {action.actionUserName} has <span style={{fontWeight: 600}}>paid</span> the batch
                              <span
                                className={`float-right pt-1 font-poppins text-xs font-light ${css({
                                  color: '#707070',
                                })}`}
                              >
                                ({moment.utc(action?.actionDateTime_utc).local().format('DD MMM YYYY, hh:mm a')})
                              </span>
                            </>
                          </p>
                        </div>
                      );
                    case 'Update':
                      return (
                        <div
                          key={index}
                          className={`flex flex-row items-center px-4 ${css({
                            borderBottom: '1px solid rgba(216 216 216 / 60%)',
                          })}`}
                        >
                          <div {...css({borderRadius: '5px'})}>
                            <p
                              {...css({
                                backgroundColor: 'rgb(129, 120, 207, 20%)',
                                color: '#8178CF',
                              })}
                              className="relative flex h-12 w-12 items-center justify-center rounded-full p-2 text-center font-poppins font-light"
                            >
                              {NameInitials(action.actionUserName)}
                            </p>
                          </div>
                          <p className={`py-5 pl-4 font-poppins text-base ${css({color: '#444343'})}`}>
                            {action.attributeName === 'Status' &&
                            action.oldValue === 'Open' &&
                            action.newValue === 'ReadyForApproval' ? (
                              <>
                                {action.actionUserName} created this batch and{' '}
                                <span style={{fontWeight: 600}}>sent it for approval</span>
                              </>
                            ) : action.attributeName === 'Status' && action.oldValue === 'ReadyForApproval' ? (
                              <>
                                {action.actionUserName} has <span style={{fontWeight: 600}}>approved</span> the batch
                              </>
                            ) : action.attributeName === 'Status' && action.newValue === 'PaymentApproved' ? (
                              <>
                                {action.actionUserName} has <span style={{fontWeight: 600}}>approved</span> the batch
                                payment
                              </>
                            ) : action.attributeName === 'Status' &&
                              action.oldValue === 'ReadyForApproval' &&
                              action.newValue === 'PaymentApproved' ? (
                              <>
                                {action.actionUserName} has <span style={{fontWeight: 600}}>approved</span> the batch
                                payment
                              </>
                            ) : (
                              <>
                                {' '}
                                {action.actionUserName} has updated{' '}
                                <span style={{fontWeight: 600}}>
                                  {action.attributeName === 'Credit' ? 'Credit Card' : action.attributeName}
                                </span>{' '}
                                from{' '}
                                <span style={{fontWeight: 600}}>
                                  {action.attributeName === 'Status'
                                    ? LabelPaymentBatchStatusTypes[action?.oldValue as PaymentBatchStatusTypes]
                                    : action.attributeName === 'DueDate'
                                      ? moment.utc(action?.oldValue).local().format('DD MMM YYYY')
                                      : action.oldValue}
                                </span>{' '}
                                to{' '}
                                <span style={{fontWeight: 600}}>
                                  {action.attributeName === 'Status'
                                    ? LabelPaymentBatchStatusTypes[action?.newValue as PaymentBatchStatusTypes]
                                    : action.attributeName === 'DueDate'
                                      ? moment.utc(action?.newValue).local().format('DD MMM YYYY')
                                      : action.newValue}{' '}
                                </span>
                              </>
                            )}
                            <span
                              className={`float-right pt-1 font-poppins text-xs font-light ${css({
                                color: '#707070',
                              })}`}
                            >
                              ({moment.utc(action?.actionDateTime_utc).local().format('DD MMM YYYY, hh:mm a')})
                            </span>
                          </p>
                        </div>
                      );
                    default:
                      return null;
                  }
                })
              : null}
          </div>
        ) : (
          <p className="font-semibold">Coming Soon...</p>
        )}
      </div>
    </div>
  );
};
