import React, {useCallback, useState} from 'react';
import {Typography, Menu, MenuHandler, MenuList, SearchInput, Button, Checkbox} from 'spenda-ui-react';
import {camelCase, debounce} from 'lodash';

interface IServiceHistoryHeader {
  columnOptions?: string[];
  setColumnOptions: React.Dispatch<React.SetStateAction<string[]>>;
  setSearchString: React.Dispatch<React.SetStateAction<string>>;
}

const MENU_ITEMS = [
  'Customer name',
  'Company name',
  'Service address',
  'Email address',
  'Phone number',
  'Open job count',
  'Last service date',
  'In progress jobs',
  'Customer ABN',
  'Asset count',
];
const ServiceHistoryHeader = (props: IServiceHistoryHeader) => {
  const {columnOptions, setColumnOptions, setSearchString} = props;

  const [query, setQuery] = useState('');
  const [selectedMenuItems, setSelectedMenuItems] = useState<Set<string>>(new Set(columnOptions?.map(val => val)));

  const handleColumnOptions = (event: React.ChangeEvent<HTMLInputElement>, key: string) => {
    const selectedItemsSet = new Set(selectedMenuItems);
    if (selectedItemsSet?.has(key)) {
      selectedItemsSet.delete(key);
    } else {
      selectedItemsSet.add(key);
    }
    setSelectedMenuItems(selectedItemsSet);
    setColumnOptions(Array.from(selectedItemsSet));
  };

  const debouncedSearch = useCallback(
    debounce((value: string) => {
      setSearchString(value);
    }, 750),
    [],
  );

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
    debouncedSearch(e.target.value);
  };

  return (
    <div className={`flex w-full items-center justify-center`}>
      <Typography className="whitespace-nowrap text-xl font-light text-black-800">Service History</Typography>
      <div className="flex w-full justify-end gap-x-2">
        <div className="w-52">
          <SearchInput
            reverse={true}
            clearIcon
            value={query}
            clearIconProps={{
              onClick: () => {
                setQuery('');
                setSearchString('');
              },
            }}
            iconButtonProps={{
              name: 'Search',
            }}
            data-autoid="txtSearchInput"
            autoFocus
            placeholder="Search..."
            onChange={onSearchChange}
          />
        </div>
        <Menu
          placement="bottom-end"
          dismiss={{
            itemPress: false,
          }}
        >
          <MenuHandler>
            <Button variant="outlined">Column Options</Button>
          </MenuHandler>
          <MenuList className="flex flex-col border border-primary p-0">
            {MENU_ITEMS.map((item, index) => (
              <div
                key={index}
                className="flex items-center gap-x-2 px-2.5 py-0 outline-none first:pt-1 last:pb-1 hover:!bg-primary/10 active:!bg-primary/10"
              >
                <Checkbox
                  id={camelCase(item)}
                  ripple={false}
                  label={item}
                  color="primary"
                  containerProps={{className: 'p-0'}}
                  checked={selectedMenuItems && selectedMenuItems.has(item)}
                  onChange={e => handleColumnOptions(e, item)}
                  className="border-primary bg-white before:h-5 before:w-5 checked:border-none checked:bg-primary hover:before:opacity-0"
                  labelProps={{className: 'px-3 py-2 text-black-800 font-semibold text-base'}}
                />
              </div>
            ))}
          </MenuList>
        </Menu>
      </div>
    </div>
  );
};

export default ServiceHistoryHeader;
