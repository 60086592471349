const suggestLookup: any = [];
for (let i = 0; i < 10; ++i) {
  suggestLookup[(i * 19) % 89] = i;
}

export const validateABN = (abn: string, isNZBN = false) => {

  if(isNZBN) {
    return {
      valid: abn.length === 13,
    };
  }

  const weights = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];
  // strip anything other than digits
  abn = abn.replace(/\D/g, '');

  // check length is 11 digits
  if (abn.length === 11) {
    // apply ato check method
    let sum = 0;
    weights.forEach((weight, position) => {
      const digit = parseInt(abn[position]) - (position ? 0 : 1);
      sum += weight * digit;
    });

    return {
      valid: sum % 89 === 0,
      suggestion: '',
    };
  }

  return {
    valid: false,
  };
};

export const validateURL = (url: string) => {
  const parsed = new URL(url);
  return ['https:', 'http:'].includes(parsed.protocol);
};
