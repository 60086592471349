import * as React from 'react';
const TourStatusIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" {...props}>
    <path
      fill="#1C78AD"
      d="M18 2.607h-6.424V.833a.576.576 0 0 0-1.152 0v1.779H4a2.873 2.873 0 0 0-2.87 2.87v9.04A2.873 2.873 0 0 0 4 17.394h3.472l-2.281 3.463a.57.57 0 0 0 .477.89.58.58 0 0 0 .48-.258l2.7-4.095h4.305l2.698 4.095a.574.574 0 0 0 .958-.632l-2.281-3.463H18a2.873 2.873 0 0 0 2.87-2.87V5.478c0-1.581-1.29-2.87-2.87-2.87Zm1.723 11.911c0 .95-.774 1.723-1.723 1.723H4c-.95 0-1.723-.773-1.723-1.723v-9.04c0-.95.774-1.723 1.723-1.723h14c.95 0 1.723.773 1.723 1.723v9.04Z"
    />
    <path
      fill="#1C78AD"
      fillRule="evenodd"
      d="M10.204.833a.796.796 0 0 1 1.592 0v1.555H18a3.095 3.095 0 0 1 3.09 3.09v9.045a3.093 3.093 0 0 1-3.09 3.09h-3.064l2.057 3.122a.79.79 0 0 1-.66 1.23.794.794 0 0 1-.665-.355v-.001l-2.634-3.996H8.966l-2.633 3.996-.001.001a.8.8 0 0 1-1.098.226l-.001-.001a.79.79 0 0 1-.226-1.1l2.057-3.122H4a3.093 3.093 0 0 1-3.09-3.09v-9.04A3.093 3.093 0 0 1 4 2.391h6.204V.832ZM11 .477a.356.356 0 0 0-.356.356v1.999H4a2.653 2.653 0 0 0-2.65 2.65v9.04c0 1.46 1.187 2.651 2.65 2.651h3.88l-2.506 3.804a.351.351 0 0 0 .1.49.35.35 0 0 0 .194.059.36.36 0 0 0 .298-.16l2.763-4.193h4.542l2.763 4.193a.354.354 0 0 0 .488.104l.003-.002a.35.35 0 0 0 .1-.49l-2.506-3.805H18c1.46 0 2.651-1.186 2.651-2.65V5.478c0-1.46-1.19-2.65-2.65-2.65h-6.644V.833A.356.356 0 0 0 11 .477ZM4 3.975c-.828 0-1.503.675-1.503 1.503v9.04c0 .829.675 1.503 1.503 1.503h14c.828 0 1.503-.675 1.503-1.503v-9.04c0-.828-.675-1.503-1.503-1.503H4ZM2.057 5.478c0-1.071.872-1.943 1.943-1.943h14c1.07 0 1.943.872 1.943 1.943v9.04A1.946 1.946 0 0 1 18 16.462H4a1.946 1.946 0 0 1-1.943-1.944v-9.04Z"
      clipRule="evenodd"
    />
    <path
      fill="#1C78AD"
      fillRule="evenodd"
      d="M9.077 13.011c.222.13.475.198.732.2.238 0 .472-.061.679-.179l3.316-1.657a1.44 1.44 0 0 0-.03-2.52L10.518 7.23A1.442 1.442 0 0 0 8.36 8.482v3.283c-.003.514.272.99.718 1.246Zm.62-4.71a.194.194 0 0 1 .097-.025c.05.001.098.016.14.042l3.253 1.625a.2.2 0 0 1 .105.18.194.194 0 0 1-.074.164l-3.316 1.658a.208.208 0 0 1-.309-.18V8.482a.202.202 0 0 1 .104-.18Z"
      clipRule="evenodd"
    />
  </svg>
);
export default TourStatusIcon;
