import React from 'react';
import {ARTable} from '../../../components/AccountsReceivable/ARTable';
import {IconButton, Typography} from 'spenda-ui-react';
import IconDownloadClaim from '../../../assets/svg/IconDownloadClaim';

export const StatementView = () => {
  const rows = {
    month: 'June',
    statementPeriod: '1st Jun - 31st Jun',
    mid: '355',
    totalTransaction: '355',
    totalTransaction$: '$500,000.00',
  };

  const columns = [
    {
      title: 'Month',
      key: 'month',
      width: '15%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: false,
      rowRenderer: () => (
        <Typography
          data-autoid={`lblTransDate`}
          variant="paragraph"
          className="inline cursor-pointer overflow-ellipsis font-medium text-primary"
        >
          {rows?.month}
        </Typography>
      ),
    },
    {
      title: 'Statement period',
      key: 'statementPeriod',
      width: '25%',
      align: 'center',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: false,
      rowRenderer: () => (
        <Typography data-autoid={`lblTransDate`} variant="paragraph" className="inline overflow-ellipsis font-medium">
          {rows?.statementPeriod}
        </Typography>
      ),
    },
    {
      title: 'MID',
      key: 'mid',
      width: '20%',
      align: 'center',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: false,
      rowRenderer: () => (
        <Typography data-autoid={`lblTransDate`} variant="paragraph" className="inline overflow-ellipsis font-medium">
          {rows?.mid}
        </Typography>
      ),
    },
    {
      title: 'Total transactions',
      key: 'totaltransactions',
      width: '20%',
      align: 'right',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: false,
      rowRenderer: () => (
        <Typography data-autoid={`lblTransDate`} variant="paragraph" className="inline overflow-ellipsis font-medium">
          {rows?.totalTransaction}
        </Typography>
      ),
    },
    {
      title: 'Total transaction $',
      key: 'statementPeriod',
      width: '15%',
      align: 'right',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: false,
      rowRenderer: () => (
        <Typography data-autoid={`lblTransDate`} variant="paragraph" className="inline overflow-ellipsis font-medium">
          {rows?.totalTransaction$}
        </Typography>
      ),
    },
    {
      title: '',
      key: 'download',
      width: '4%',
      align: 'right',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: false,
      rowRenderer: () => (
        <IconButton variant="text" size="sm">
          <IconDownloadClaim width="20px" height="20px" />
        </IconButton>
      ),
    },
  ];

  return (
    <>
      <div className="flex h-full !w-full items-start justify-start rounded-[8px] bg-white pt-2.5">
        <ARTable
          isHighlightRowOnHover
          tableClass="mb-12 !mx-0"
          rows={[rows]}
          columns={columns}
          isLoading={false}
          isPaginated
          dataAutoId="StatementView"
          getRowDataAutoId={rowData => `rowStatementView${rowData?.refNumber}`}
        />
      </div>
    </>
  );
};
