import * as React from 'react';
const APOnbPostingConfigurationsIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5625 2.72667L14.6591 0.760833C14.1869 0.275942 13.5393 0.00169428 12.8625 0H8.93331C6.95406 0.00249857 5.24867 1.39465 4.84998 3.33333H4.76664C2.4666 3.33609 0.602731 5.19996 0.599976 7.5V15.8333C0.602731 18.1334 2.4666 19.9972 4.76664 20H9.76664C12.0667 19.9972 13.9306 18.1334 13.9333 15.8333V15.75C15.872 15.3513 17.2641 13.6459 17.2666 11.6667V4.46667C17.2679 3.81701 17.0152 3.1926 16.5625 2.72667ZM9.76664 18.3333H4.76664C3.38593 18.3333 2.26664 17.214 2.26664 15.8333V7.5C2.26664 6.11929 3.38593 5 4.76664 5V11.6667C4.7694 13.9667 6.63326 15.8306 8.93331 15.8333H12.2666C12.2666 17.214 11.1474 18.3333 9.76664 18.3333ZM13.1 14.1667H8.93331C7.5526 14.1667 6.43331 13.0474 6.43331 11.6667V4.16667C6.43331 2.78595 7.5526 1.66667 8.93331 1.66667H12.2666V3.33333C12.2666 4.25381 13.0128 5 13.9333 5H15.6V11.6667C15.6 13.0474 14.4807 14.1667 13.1 14.1667Z"
      fill="hsl(var(--primary))"
    />
  </svg>
);
export default APOnbPostingConfigurationsIcon;
