import React from 'react';
import {Button} from 'spenda-ui-react';
import {AirPlusPortalURL} from '../../../../model/constants/Constants';

interface IAirPlusPortalLoginButton {
  isDisabled?: boolean;
  fullWidth?: boolean;
}
export const AirPlusPortalLoginButton = (props: IAirPlusPortalLoginButton) => {
  const {fullWidth = true, isDisabled = false} = props;
  return (
    <Button
      variant="outlined"
      data-autoid="btnLogIntoAirPlus"
      disabled={isDisabled}
      onClick={() => window.open(AirPlusPortalURL, '_blank')}
      className={`${fullWidth ? 'w-full' : 'w-[12.625rem]'} py-1 shadow-none`}
    >
      <p className="text-xs">To check your balance</p>
      <p className="font-semibold">Login to AirPlus</p>
    </Button>
  );
};
