import {css} from 'glamor';
import React, {PropsWithChildren} from 'react';
import RebrandedSpendaFooterLogo from '../../assets/svg/RebrandedSpendaFooterLogo';
interface IPayByLinkLayoutProps {
  isScrollable?: boolean;
}

export const PayByLinkLayout = (props: PropsWithChildren<IPayByLinkLayoutProps>) => {
  const layoutHeight = css({
    height: 'calc(100vh - calc(100vh - 100%))',
  });

  const contentHeight = css({
    height: 'calc(100% - 48px)',
    overflowY: 'hidden',
    WebkitOverflowScrolling: 'touch',
    position: 'relative',
  });

  return (
    <section className={` flex flex-col ${layoutHeight}`} style={{backgroundColor: '#F4F8FB'}}>
      <main className={`${contentHeight} flex justify-center items-center`}>{props.children}</main>

      <footer>
        <div
          style={{zIndex: 50}}
          className={'p-1 h-12 flex flex-1 justify-center items-center text-white text-xl bg-spenda-footerBg'}
        >
          <RebrandedSpendaFooterLogo style={{width: '150', height: '40px'}} />
        </div>
      </footer>
    </section>
  );
};
