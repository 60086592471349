import React, {ReactElement, useContext, useEffect} from 'react';
import clsx from 'clsx';
import {css} from 'glamor';
import {PaymentBatchPermissionType, PaymentBatchStatusTypes} from '../../model/constants/Constants';
import LoadingIndicator from '../ui/LoadingIndicator';
import {
  Button,
  ButtonGroup,
  Popper,
  Grow,
  MenuItem,
  MenuList,
  ClickAwayListener,
  makeStyles,
  Tooltip,
  withStyles,
  Theme,
} from '@material-ui/core';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import {PaymentAuthorisationDetails} from '../../model/payment/PaymentAuthority';
import APContext from '../../context/ap-context/APContext';

const useStyles = makeStyles({
  root: {
    '& .MuiSelect-icon': {
      color: 'black',
    },
    '& .MuiInput-underline': {
      borderBottom: '1px solid black',
    },
  },
  popperContainer: {
    borderRadius: '4px',
    border: '1px solid #EAEAEA',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 1px 10px 0 #00000012',
    minWidth: '150px',
  },
  menuItemRoot: {
    color: '#444343',
    fontSize: '0.8rem',
    padding: '6px 10px',
    '&:hover': {
      backgroundColor: '#EAEFF260',
    },
  },
  menuItemSelected: {
    backgroundColor: 'rgba(211, 229, 239, 0.5) !important',
  },
  buttonBlue: {
    height: '40px',
    padding: '5px 8px',
    color: '#FFFFFF',
    minWidth: '150px',
    fontWeight: 500,
    fontSize: '14px',
    borderRadius: '6px',
    borderBottomRightRadius: '0px',
    borderTopRightRadius: '0px',
    textTransform: 'none',
    fontFamily: 'poppins',
  },
  buttonRadius: {
    borderBottomRightRadius: '6px',
    borderTopRightRadius: '6px',
  },
  buttonDrowdownBlue: {
    backgroundColor: '#F5F5F5',
    color: '#1C78AD',
    borderRadius: '6px',
  },
  buttonDisabled: {
    backgroundColor: '#F5F5F5',
    color: '#1C78AD',
  },
});

export enum BatchSplitButtonType {
  Pay = 'Pay',
  ApprovePay = 'ApprovePay',
  Approve = 'Approve',
  SendForApproval = 'SendForApproval',
}

interface ISSplitButtonOptions {
  disabled: boolean;
  disabledReason: string;
  text: string | ReactElement;
  type: BatchSplitButtonType;
  color: '#1C78AD' | '#3C9F78';
}

export interface IBatchSplitButton {
  isSubmitting?: boolean;
  disabled?: boolean;
  batchStatus: PaymentBatchStatusTypes;
  handleClick: (type: BatchSplitButtonType) => void;
  disabledReason?: string;
  batchPaymentMethod?: string;
  isAuthorisationRulesSet?: boolean;
  isCurrentUserAnAuthoriser?: boolean;
  isAuthorisationList?: boolean;
  authorisation?: PaymentAuthorisationDetails;
  dataAutoIdSuffix?: string;
}

export const BatchSplitButton = (props: IBatchSplitButton) => {
  const classes = useStyles();
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const {
    isSubmitting,
    batchStatus,
    handleClick,
    isAuthorisationRulesSet,
    isCurrentUserAnAuthoriser,
    isAuthorisationList,
    dataAutoIdSuffix,
  } = props;

  const [open, setOpen] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [options, setOptions] = React.useState<ISSplitButtonOptions[]>([]);

  const disabled = options[selectedIndex]?.disabled || props.disabled;
  const color = options[selectedIndex]?.color;
  const {userRoleBatchPermissions} = useContext(APContext);

  useEffect(() => {
    // Check Permissions
    const _options: ISSplitButtonOptions[] = [];
    if (!isAuthorisationList) {
      // Show Pay button
      if (batchStatus === PaymentBatchStatusTypes.PaymentApproved) {
        let disabled = false,
          disabledReason = '';
        _options.push({
          text: (
            <span>
              {!isAuthorisationRulesSet || (isAuthorisationRulesSet && isCurrentUserAnAuthoriser)
                ? 'Pay'
                : 'Request Payment'}
            </span>
          ),
          type: BatchSplitButtonType.Pay,
          disabled: disabled,
          disabledReason: disabledReason,
          color: '#3C9F78',
        });
      }
      // Show Approve & Pay button
      if (
        (batchStatus === PaymentBatchStatusTypes.Open || batchStatus === PaymentBatchStatusTypes.ReadyForApproval) &&
        userRoleBatchPermissions &&
        userRoleBatchPermissions.includes(PaymentBatchPermissionType.ApproveBatch)
      ) {
        let disabled = false,
          disabledReason = '';
        _options.push({
          text: (
            <span>
              {' '}
              {!isAuthorisationRulesSet || (isAuthorisationRulesSet && isCurrentUserAnAuthoriser)
                ? 'Approve & Pay'
                : 'Approve & Request Payment'}
            </span>
          ),
          type: BatchSplitButtonType.ApprovePay,
          disabled: disabled,
          disabledReason: disabledReason,
          color: '#3C9F78',
        });
      }
      // Show Approve button
      if (
        (batchStatus === PaymentBatchStatusTypes.Open || batchStatus === PaymentBatchStatusTypes.ReadyForApproval) &&
        userRoleBatchPermissions &&
        userRoleBatchPermissions.includes(PaymentBatchPermissionType.ApproveBatch)
      ) {
        let disabled = false,
          disabledReason = '';
        _options.push({
          text: 'Approve',
          type: BatchSplitButtonType.Approve,
          disabled: disabled,
          disabledReason: disabledReason,
          color: '#1C78AD',
        });
      }
      // Show Send For approval button
      if (batchStatus === PaymentBatchStatusTypes.Open) {
        _options.push({
          text: 'Send For Approval',
          type: BatchSplitButtonType.SendForApproval,
          disabled: false,
          disabledReason: '',
          color: '#1C78AD',
        });
      }
    } else {
      _options.push({
        text: <span>Pay</span>,
        type: BatchSplitButtonType.Pay,
        disabled: false,
        disabledReason: '',
        color: '#3C9F78',
      });
    }
    setOptions(_options);
  }, [batchStatus, isAuthorisationList, isAuthorisationRulesSet, isCurrentUserAnAuthoriser, userRoleBatchPermissions]);

  useEffect(() => {
    // Set Options to first enabled
    const firstEnabled = options.findIndex(option => !option.disabled);
    if (firstEnabled > -1 && firstEnabled !== selectedIndex) {
      setSelectedIndex(firstEnabled);
    }
  }, [options]);

  const handleButtonClick = () => {
    handleClick(options[selectedIndex].type);
  };

  const handleMenuItemClick = (_event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  if (batchStatus === PaymentBatchStatusTypes.Completed && !isAuthorisationList) {
    return (
      <span
        {...css({color: '#3D9F78', border: '2px solid #3D9F7830'})}
        className="my-2 rounded-xl px-10 py-2 font-poppins text-lg font-semibold"
      >
        Completed
      </span>
    );
  }

  if (
    (batchStatus === PaymentBatchStatusTypes.InProgress ||
      batchStatus === PaymentBatchStatusTypes.ProcessingDebit ||
      batchStatus === PaymentBatchStatusTypes.DebitSuccessful ||
      batchStatus === PaymentBatchStatusTypes.ProcessingCredit) &&
    !isAuthorisationList
  ) {
    return (
      <span
        {...css({color: '#1C78AD', border: '2px solid #1C78AD30'})}
        className="my-2 rounded-xl px-7 py-2 font-poppins text-lg font-semibold"
      >
        In Progress
      </span>
    );
  }

  if (
    batchStatus === PaymentBatchStatusTypes.Failed ||
    (batchStatus === PaymentBatchStatusTypes.Incomplete && !isAuthorisationList)
  ) {
    return (
      <span
        {...css({color: '#C55D44', border: '2px solid #C55D4430'})}
        className="my-2 rounded-xl px-10 py-2 font-poppins text-lg font-semibold"
      >
        Failed
      </span>
    );
  }

  if (batchStatus === PaymentBatchStatusTypes.Cancelled && !isAuthorisationList) {
    return (
      <span
        {...css({color: '#C55D44', border: '2px solid #C55D4430'})}
        className="my-2 rounded-xl px-10 py-2 font-poppins text-lg font-semibold"
      >
        Rejected
      </span>
    );
  }

  if (!options.length) {
    return null;
  }

  const APTooltip = withStyles((theme: Theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 12,
      fontFamily: 'poppins',
    },
  }))(Tooltip);

  return (
    <>
      <ButtonGroup ref={anchorRef} aria-label="split button">
        <APTooltip
          title={options[selectedIndex]?.disabledReason || props.disabledReason || ''}
          disableHoverListener={!Boolean(disabled)}
        >
          <span>
            <Button
              data-autoid={`btnBatchAction${options[selectedIndex]?.type}${dataAutoIdSuffix || ''}`}
              style={{
                backgroundColor: disabled ? `rgb(244 244 244)` : color,
                border: `1px solid ${color}`,
                color: disabled ? `${color}` : '#FFF',
                cursor: disabled ? `not-allowed` : 'pointer',
              }}
              disabled={Boolean(disabled)}
              classes={{
                root: clsx(classes.buttonBlue, {
                  [classes.buttonRadius]: options.length <= 1,
                }),
              }}
              onClick={handleButtonClick}
            >
              {options[selectedIndex]?.text}
              <LoadingIndicator isLoading={isSubmitting} size="sm" color="#FFF" position={{right: '5px'}} />
            </Button>
          </span>
        </APTooltip>
        {options.length > 1 ? (
          <Button
            data-autoid="btnBatchActionDropdown"
            style={{border: `1px solid ${color}`}}
            size="small"
            classes={{
              root: classes.buttonDrowdownBlue,
              disabled: classes.buttonDisabled,
            }}
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <KeyboardArrowDownRoundedIcon />
          </Button>
        ) : null}
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{zIndex: 1, width: anchorRef?.current?.clientWidth}}
      >
        {({TransitionProps, placement}) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'left bottom',
            }}
          >
            <div className={`${classes.popperContainer}`}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="menu-selectTimelineRange" data-autoid={`BatchAction_DropDown_lst`}>
                  {options.map((option, index) => (
                    <MenuItem
                      data-autoid={`BatchAction_DropDown${option.type}_mnu`}
                      classes={{
                        root: classes.menuItemRoot,
                        selected: classes.menuItemSelected,
                      }}
                      disableGutters
                      key={option.type}
                      selected={index === selectedIndex}
                      onClick={event => handleMenuItemClick(event, index)}
                    >
                      {option.text}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </div>
          </Grow>
        )}
      </Popper>
    </>
  );
};
