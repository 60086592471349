import * as React from 'react';
import {SVGProps} from 'react';

const IconRequiresAttention = (props: SVGProps<SVGSVGElement>) => (
  <svg width={21} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g className="fill-primary">
      <path d="M20.628 15.25 12.96 2.486A2.724 2.724 0 0 0 10.63 1.2c-.957 0-1.843.489-2.329 1.285L.634 15.251a2.415 2.415 0 0 0 0 2.514 2.68 2.68 0 0 0 2.329 1.292h15.336a2.681 2.681 0 0 0 2.332-1.292 2.415 2.415 0 0 0-.003-2.514Zm-1.278 1.802v.005c-.218.36-.62.579-1.051.572H2.963a1.205 1.205 0 0 1-1.051-.577 1.028 1.028 0 0 1 0-1.084L9.582 3.2c.222-.356.62-.574 1.05-.574.429 0 .827.218 1.049.574l7.668 12.768c.206.334.207.75.002 1.084Z" />
      <path d="M10.631 5.485c-.408 0-.74.32-.74.715v5.714c0 .395.332.714.74.714s.74-.32.74-.714V6.2a.727.727 0 0 0-.74-.715ZM10.631 14.057c-.408 0-.74.32-.74.714v.715c0 .394.332.714.74.714s.74-.32.74-.714v-.715a.727.727 0 0 0-.74-.714Z" />
    </g>
  </svg>
);

export default IconRequiresAttention;
