import React from 'react';

export const EmptyBox = (props: {width?: string; height?: string}) => {
  return (
    <svg
      width={props.width || '194px'}
      height={props.height || '60%'}
      viewBox="0 0 244 253"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Group 7</title>
      <g id="Updated-Sales-Order" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Pack-Items-Copy-6" transform="translate(-597.000000, -380.000000)">
          <g id="Group-7" transform="translate(597.960641, 380.970995)">
            <g
              id="blob-(1)"
              opacity="0.0599999987"
              transform="translate(121.239359, 125.529005) rotate(30.000000) translate(-121.239359, -125.529005) translate(36.775634, 29.345750)"
              fill="#1C78AD"
              fillRule="nonzero"
            >
              <path
                d="M157.413555,8.19504958 C172.885727,22.0887032 169.82193,56.1357884 165.073046,85.6025483 C160.324161,115.069308 154.043378,139.650388 138.418016,157.971689 C122.792654,176.140313 98.1290923,188.049159 69.9421645,191.408064 C41.7552366,194.76697 10.3513225,189.575934 2.23226175,171.40731 C-5.73360916,153.086009 9.58537335,121.787119 17.3980544,98.1221043 C25.0575457,74.3044124 25.2107355,58.4259511 33.1766064,44.5322975 C41.2956672,30.4859664 57.227409,18.7297979 82.3505403,9.56914719 C107.626861,0.561173966 141.788192,-5.85128155 157.413555,8.19504958 Z"
                id="Path"
              ></path>
            </g>
            <text
              id="Package-1"
              opacity="0.5"
              fontFamily="SairaCondensed-Regular, Saira Condensed"
              fontSize="11.2"
              fontStyle="condensed"
              fontWeight="normal"
              fill="#333333"
            >
              <tspan x="40.7577594" y="179.029005">
                Package 1
              </tspan>
            </text>
            <text
              id="Package-2"
              opacity="0.5"
              fontFamily="SairaCondensed-Regular, Saira Condensed"
              fontSize="11.2"
              fontStyle="condensed"
              fontWeight="normal"
              fill="#333333"
            >
              <tspan x="157.943359" y="179.029005">
                Package 2
              </tspan>
            </text>
            <g id="Group-14" opacity="0.800000012" transform="translate(4.039359, 83.029005)">
              <g id="Group-27" opacity="0.600000024" transform="translate(122.930310, 0.000000)">
                <polygon
                  id="Fill-1"
                  fillOpacity="0.2"
                  fill="#1C78AD"
                  points="20.1840752 32.1771208 20.1840752 78.0837504 58.8638515 81 76.9857462 78.0837504 76.9857462 31.2050376 41.6142704 29.2608711"
                ></polygon>
                <g id="Group-26">
                  <polygon
                    id="Stroke-2"
                    stroke="#263238"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    points="20.1836818 78.0834587 58.8634581 80.9997084 76.9853528 78.0834587 76.9853528 31.2047459 41.613877 29.2605795 20.1836818 32.1768291"
                  ></polygon>
                  <path
                    d="M50.3803816,57.6906111 C50.3803816,63.9333295 45.3707256,68.5691943 39.1897896,68.0462136 C33.009837,67.5232328 27.9991976,62.0387393 27.9991976,55.7960209 C27.9991976,49.5533026 33.009837,44.9164656 39.1897896,45.4394464 C45.3707256,45.9633993 50.3803816,51.4478927 50.3803816,57.6906111"
                    id="Fill-4"
                    fill="#263238"
                  ></path>
                  <path
                    d="M38.1977912,57.0441758 L38.1977912,61.1113719 L39.3769387,61.2105244 C40.0702657,61.2698215 40.4439738,60.9791686 40.4439738,59.9954204 L40.4439738,58.8425297 C40.4439738,57.6128445 40.0515803,57.2016533 39.115343,57.1219424 L38.1977912,57.0441758 Z M38.1977912,51.6549465 L38.1977912,55.1534739 L39.0032306,55.2215198 C39.7712991,55.2866493 40.2384343,54.9862756 40.2384343,53.9267049 L40.2384343,53.1888938 C40.2384343,52.2440289 39.919799,51.8007589 39.1900846,51.7385456 L38.1977912,51.6549465 Z M42.2977629,53.1558429 L42.2977629,53.6282754 C42.2977629,54.990164 41.8857005,55.8057418 40.9681486,56.1634684 L40.9681486,56.2013796 C42.0725546,56.7301929 42.5033024,57.7499082 42.5033024,59.1487359 L42.5033024,60.2267762 C42.5033024,62.269123 41.4362673,63.2762012 39.3769387,63.1012263 L36.1384626,62.8270988 L36.1384626,49.5892696 L39.2461409,49.8527042 C41.380211,50.0325396 42.2977629,51.112524 42.2977629,53.1558429 L42.2977629,53.1558429 Z"
                    id="Fill-6"
                    fill="#FFFFFF"
                  ></path>
                  <polyline
                    id="Stroke-8"
                    stroke="#263238"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    points="58.8635565 80.9997084 58.8635565 35.0230888 27.0216558 33.1139174"
                  ></polyline>
                  <line
                    x1="58.8635565"
                    y1="35.0232832"
                    x2="76.9854511"
                    y2="31.2049403"
                    id="Stroke-10"
                    stroke="#263238"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></line>
                  <line
                    x1="82.449851"
                    y1="44.9766376"
                    x2="103.06969"
                    y2="44.7073706"
                    id="Stroke-12"
                    stroke="#263238"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></line>
                  <line
                    x1="80.1150602"
                    y1="27.0918619"
                    x2="94.121248"
                    y2="17.759863"
                    id="Stroke-14"
                    stroke="#263238"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></line>
                  <line
                    x1="54.0491138"
                    y1="19.4931846"
                    x2="54.0491138"
                    y2="0"
                    id="Stroke-16"
                    stroke="#263238"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></line>
                  <line
                    x1="29.1489402"
                    y1="19.4931846"
                    x2="22.9247327"
                    y2="8.18299646"
                    id="Stroke-18"
                    stroke="#263238"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></line>
                  <line
                    x1="12.4198951"
                    y1="37.8820827"
                    x2="0"
                    y2="35.4800651"
                    id="Stroke-20"
                    stroke="#263238"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></line>
                  <line
                    x1="12.4198951"
                    y1="56.7432188"
                    x2="0"
                    y2="60.7608387"
                    id="Stroke-22"
                    stroke="#263238"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></line>
                  <line
                    x1="84.3946085"
                    y1="60.7606443"
                    x2="94.1218381"
                    y2="64.6062055"
                    id="Stroke-24"
                    stroke="#263238"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></line>
                </g>
              </g>
              <g id="Group-27" opacity="0.600000024">
                <polygon
                  id="Fill-1"
                  fillOpacity="0.2"
                  fill="#1C78AD"
                  points="26.0843367 31.2051348 26.0843367 78.0838476 44.2062314 80.9991251 82.8860077 78.0838476 82.8860077 32.177218 61.4558125 29.2609683"
                ></polygon>
                <g id="Group-26">
                  <polygon
                    id="Stroke-2"
                    stroke="#263238"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    points="82.8860077 78.0834587 44.2052479 80.9997084 26.0843367 78.0834587 26.0843367 31.2047459 61.4558125 29.2605795 82.8860077 32.1768291"
                  ></polygon>
                  <path
                    d="M75.0381367,56.0165866 C75.0381367,62.259305 70.0019277,67.644646 63.7895216,68.0461163 C57.5761319,68.4475867 52.539923,63.7125694 52.539923,57.469851 C52.539923,51.2271326 57.5761319,45.8408196 63.7895216,45.4403213 C70.0019277,45.0388509 75.0381367,49.7748403 75.0381367,56.0165866"
                    id="Fill-4"
                    fill="#263238"
                  ></path>
                  <path
                    d="M62.6889511,58.957916 L64.6843559,58.829601 L63.7048472,52.1980494 L63.6674763,52.1999935 L62.6889511,58.957916 Z M67.4134088,62.8511093 L65.3235935,62.9872009 L64.9666039,60.6075412 L62.4253885,60.7718233 L62.0674154,63.1971709 L60.1664211,63.3196534 L62.2749218,49.9457326 L65.3049081,49.7493717 L67.4134088,62.8511093 Z"
                    id="Fill-6"
                    fill="#FFFFFF"
                  ></path>
                  <polyline
                    id="Stroke-8"
                    stroke="#263238"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    points="44.2057396 80.9997084 44.2057396 35.0230888 76.1076304 33.1139174"
                  ></polyline>
                  <line
                    x1="44.2057396"
                    y1="35.0232832"
                    x2="26.0848284"
                    y2="31.2049403"
                    id="Stroke-10"
                    stroke="#263238"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></line>
                  <line
                    x1="20.6198386"
                    y1="44.9766376"
                    x2="0"
                    y2="44.7073706"
                    id="Stroke-12"
                    stroke="#263238"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></line>
                  <line
                    x1="22.9541376"
                    y1="27.0918619"
                    x2="8.94794978"
                    y2="17.759863"
                    id="Stroke-14"
                    stroke="#263238"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></line>
                  <line
                    x1="49.0210674"
                    y1="19.4931846"
                    x2="49.0210674"
                    y2="0"
                    id="Stroke-16"
                    stroke="#263238"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></line>
                  <line
                    x1="73.9202576"
                    y1="19.4931846"
                    x2="80.1454485"
                    y2="8.18299646"
                    id="Stroke-18"
                    stroke="#263238"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></line>
                  <line
                    x1="90.6497944"
                    y1="37.8820827"
                    x2="103.06969"
                    y2="35.4800651"
                    id="Stroke-20"
                    stroke="#263238"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></line>
                  <line
                    x1="90.6497944"
                    y1="56.7432188"
                    x2="103.06969"
                    y2="60.7608387"
                    id="Stroke-22"
                    stroke="#263238"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></line>
                  <line
                    x1="18.6745893"
                    y1="60.7606443"
                    x2="8.94834316"
                    y2="64.6062055"
                    id="Stroke-24"
                    stroke="#263238"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></line>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
