import {useContext, createContext} from 'react';
import {ISearchFilter} from '../../../../model/search-filters/SearchFilter';
import {IServiceJob} from '../../../../model/service-management/serviceJob';

export const UninvoicedContext = createContext<IUninvoicedContext>({} as IUninvoicedContext);

interface IUninvoicedContext {
  currentPage: number;
  items?: IServiceJob[];
  pageSize: number;
  searchFilter: ISearchFilter;
  totalCount: number;
  handleRowsPerPageChange: (rows: number) => void;
  setSearchFilter: React.Dispatch<React.SetStateAction<ISearchFilter>>;
  handlePageChange: (newPage: number, pageSize: number) => void;
  selectedServiceJobs: number[];
  setSelectedServiceJobs: React.Dispatch<React.SetStateAction<number[]>>;
}

export const useUninvoicedContext = () => {
  const context = useContext(UninvoicedContext);

  if (context === undefined) {
    throw new Error('useUninvoicedContext must be used within a UninvoicedProvider');
  }

  return context;
};
