import React, {useContext} from 'react';
import SecurityCode from '../../../../components/inputs/SecurityCode';
import lock from '../../../../assets/svg/lock.svg';
import {PaymentAuthorisationDetails} from '../../../../model/payment/PaymentAuthority';
import {ApprovalCodeDeliverytype} from '../../../../model/constants/Constants';
import AppContext from '../../../../context/app/appContext';

export const EnterSecurityCode = (props: {
  securityCode: string;
  setSecurityCode: (securityCode: string) => void;
  handleResetSecurityCode: () => void;
  errorMsg?: string;
  paymentAuthorisationDetails: PaymentAuthorisationDetails;
  onEnterVerificationCode: () => Promise<void>;
}) => {
  const {securityCode, setSecurityCode, handleResetSecurityCode, paymentAuthorisationDetails, errorMsg} = props;
  const {tenant} = useContext(AppContext);
  const {Users} = tenant ?? {};
  const mobile = Users?.[0]?.Mobile;
  const email = Users?.[0]?.EmailAddress;
  const approvalCodeType = paymentAuthorisationDetails?.approvalCodeDeliveryType;

  const onEnterVerificationCode = async () => {
    if (securityCode.trim().length < 6) {
      return;
    }
    props.onEnterVerificationCode();
  };
  return (
    <>
      <div className="mb-8 mt-12 flex justify-center p-1 font-poppins">
        <img className="h-18 w-16" src={lock} alt="lockicon" />
      </div>

      <h1 className="text-xxl font-poppins">Enter security code</h1>

      <div className="px-8 pt-8">
        <p className="px-2 pb-9 font-poppins text-xs font-normal">
          Please enter the 6 digit code sent to your {approvalCodeType?.toLowerCase()}{' '}
          {approvalCodeType === ApprovalCodeDeliverytype.Mobile ? mobile : email} to authorise this payment.
        </p>
        <div className="grid justify-center text-center">
          <SecurityCode
            is2SA={true}
            onChange={setSecurityCode}
            value={securityCode}
            onEnter={onEnterVerificationCode}
            newCodeDesign
            // below key to be used to show invalid code
            invalidCode={!!errorMsg}
          ></SecurityCode>
        </div>
        <p className="mt-4 font-poppins text-xs text-spenda-error">{errorMsg}</p>
        {paymentAuthorisationDetails?.newCodesRemainingCount ? (
          <div className="pt-6">
            <span
              className="cursor-pointer font-poppins text-base font-semibold text-primary"
              onClick={handleResetSecurityCode}
            >
              Resend Code
            </span>
            {paymentAuthorisationDetails?.approvalCodeDeliveryType === ApprovalCodeDeliverytype.Email ? (
              <p className="pt-4 font-poppins text-xs font-normal">
                To receive the code by mobile next time, please add your number to your user profile.
              </p>
            ) : null}
          </div>
        ) : null}
      </div>
    </>
  );
};
