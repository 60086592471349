import {useEffect, useState, useContext} from 'react';
import qs from 'query-string';
import {SalesOrdersViewMode} from '../model/constants/Constants';
import {ISalesOrder} from '../model/sales-order/SalesOrder';
import {
  IActionResults,
  IActionResultsList,
  IPagedActionResults,
  IActionResultsCreatedObjects,
} from '../model/ActionResults';
import {ISearchFilter} from '../model/search-filters/SearchFilter';
import useHttp from '../hooks/useHttp';
import AppContext from '../context/app/appContext';

export const useSalesOrderAPI = () => {
  const {GET, POST, PUT, DELETE, isLoading} = useHttp();

  const salesOrderFulfillment = async (
    filter: any
  ): Promise<IPagedActionResults<ISalesOrder[]> & IActionResultsList<ISalesOrder>> => {
    return GET(`SalesOrders/fulfillment?${qs.stringify(filter, {arrayFormat: 'bracket'})}`).then(
      (data: IPagedActionResults<ISalesOrder[]> & IActionResultsList<ISalesOrder>) => data
    );
  };

  const getOrderById = async (ID: any): Promise<ISalesOrder> => {
    return GET(`SalesOrders/${ID}`).then((data: IActionResults<ISalesOrder>) => data.Value);
  };

  const createOrder = async (payload: Partial<ISalesOrder>): Promise<IActionResults<ISalesOrder>> => {
    return POST('SalesOrders', {Object: payload}).then((data: IActionResults<ISalesOrder>) => data);
  };

  const updateSalesOrder = async (ID: any, payload: Partial<ISalesOrder>): Promise<IActionResults<ISalesOrder>> => {
    return PUT(`SalesOrders/${ID}`, {Object: payload}).then((data: IActionResults<ISalesOrder>) => data);
  };

  const updateSalesOrderStatus = async (
    ID: any,
    status: string
  ): Promise<IActionResultsCreatedObjects> => {
    return POST(`SalesOrders/${ID}/status`, {status: status}).then(
      (data: IActionResultsCreatedObjects) => data
    );
  };

  const addSalesOrderLineItem = async (ID: any, payload: any): Promise<IActionResults<ISalesOrder>> => {
    return POST(`SalesOrders/${ID}/lines`, payload).then((data: IActionResults<ISalesOrder>) => data);
  };

  const deleteSalesOrderById = async (ID: any): Promise<IActionResultsCreatedObjects> => {
    return DELETE(`SalesOrders/${ID}`).then((data: IActionResultsCreatedObjects) => data);
  };

  const invoiceSalesOrder = async (ID: any): Promise<IActionResults<ISalesOrder>> => {
    return POST(`SalesOrders/${ID}/invoice`, {}).then((data: IActionResults<ISalesOrder>) => data);
  };

  return {
    salesOrderFulfillment,
    createOrder,
    getOrderById,
    updateSalesOrder,
    updateSalesOrderStatus,
    addSalesOrderLineItem,
    deleteSalesOrderById,
    invoiceSalesOrder,
    isLoading,
  };
};

interface ICount {
  TotalRecordCount: number;
  TotalCountRequiresAttention: number;
  TotalCountReadyToPick: number;
  TotalCountReadyToPack: number;
  TotalCountAwaitingDispatch: number;
}

export const useLoadSalesOrder = () => {
  const {tenantInfo} = useContext(AppContext);
  let pageSize = 15;

  const initialFilterState = {
    WarehouseID: tenantInfo?.TenantUserDetails?.DefaultWarehouseID,
    ViewMode: SalesOrdersViewMode.All,
    StartRow: 1,
    MaxResults: pageSize,
    SortAsc: false,
    SortField: 'RefNumber',
  } as ISearchFilter;

  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<ISalesOrder[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [moreToGet, setMoreToGet] = useState<boolean>(true);
  const [error, setError] = useState<any>();
  const [count, setCount] = useState<ICount>({
    TotalRecordCount: 0,
    TotalCountRequiresAttention: 0,
    TotalCountReadyToPick: 0,
    TotalCountReadyToPack: 0,
    TotalCountAwaitingDispatch: 0,
  });
  const [searchFilter, setSearchFilter] = useState<ISearchFilter>(initialFilterState);

  const {salesOrderFulfillment} = useSalesOrderAPI();

  const getOrdersList = async () => {
    setLoading(true);

    if (searchFilter.StartRow === 1) {
      setItems([]);
      setMoreToGet(true);
    }

    try {
      const {
        Items,
        MoreToGet,
        TotalRowCount,
        TotalRecordCount,
        TotalCountRequiresAttention,
        TotalCountReadyToPick,
        TotalCountReadyToPack,
        TotalCountAwaitingDispatch,
      } = await salesOrderFulfillment(searchFilter);
      let items: ISalesOrder[] = Items || [];

      const AllCounts = {
        TotalRecordCount,
        TotalCountRequiresAttention,
        TotalCountReadyToPick,
        TotalCountReadyToPack,
        TotalCountAwaitingDispatch,
      } as ICount;
      setItems(items);
      setTotalCount(TotalRowCount);
      setMoreToGet(MoreToGet);
      setCurrentPage(Math.ceil((searchFilter.StartRow || 1 + pageSize - 1) / (searchFilter.MaxResults || pageSize)));
      setCount(AllCounts);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getOrdersList();
  }, [searchFilter]);

  return {
    count,
    loading,
    items,
    totalCount,
    pageSize: searchFilter.MaxResults || pageSize,
    currentPage,
    moreToGet,
    error,
    searchFilter,
    getOrdersList,
    setSearchFilter,
  };
};
