import React, {useReducer} from 'react';
import ATransactionsContextReducer from './ARContextReducer';
import ARContext, {IARContextState} from './ARContext';
import ARContextActions from './ARContextActions';
import {ARStatementPeriodDefaultOptions} from '../../model/constants/Constants';
import {ITxData} from '../../components/AccountsReceivable/TransactionView';
import {
  IARModalToShow,
  IEmailBatch,
  IPsblBatch,
  IPsblStatement,
} from '../../model/accounts-receivable/AccountsReceivable';

export const ARContextProvider = (props: any) => {
  const initialState: IARContextState = {
    psblStatement: {selectedTxList: []},
    psblBatch: {selectedTxList: []},
    selectedStatementPeriod: ARStatementPeriodDefaultOptions.ALL,
    arModalToShow: undefined,
    viewingTx: undefined,
    filterConversationId: undefined,
    emailBatch: undefined,
  };

  const [state, dispatch] = useReducer(ATransactionsContextReducer, initialState);

  const setSelectedStatementPeriod = (statementId: string | ARStatementPeriodDefaultOptions) => {
    dispatch({
      type: ARContextActions.SET_CURRENT_STATEMENT_PERIOD,
      payload: statementId,
    });
  };

  const setPsblStatement = (psblStatement: IPsblStatement) => {
    dispatch({
      type: ARContextActions.SET_PSBL_STATEMENT,
      payload: psblStatement,
    });
  };

  const setPsblBatch = (psblBatchData: IPsblBatch) => {
    dispatch({
      type: ARContextActions.SET_PSBL_BATCH,
      payload: psblBatchData,
    });
  };

  const setArModalToShow = (modalData: IARModalToShow | undefined) => {
    dispatch({
      type: ARContextActions.SET_AR_MODAL_TO_SHOW,
      payload: modalData,
    });
  };

  const setViewingTx = (data: ITxData | undefined) => {
    dispatch({
      type: ARContextActions.SET_CURRENT_VIEWING_TX,
      payload: data,
    });
  };

  const setFilterConversationId = (data: number | undefined) => {
    dispatch({
      type: ARContextActions.SET_FILTER_CONVERSATION_ID,
      payload: data,
    });
  };

  const setEmailBatch = (data: IEmailBatch | undefined) => {
    dispatch({
      type: ARContextActions.SET_EMAIL_BATCH,
      payload: data,
    });
  };

  return (
    <ARContext.Provider
      value={{
        selectedStatementPeriod: state.selectedStatementPeriod,
        psblStatement: state.psblStatement,
        psblBatch: state.psblBatch,
        arModalToShow: state.arModalToShow,
        viewingTx: state.viewingTx,
        filterConversationId: state.filterConversationId,
        emailBatch: state.emailBatch,
        setPsblStatement,
        setPsblBatch,
        setSelectedStatementPeriod,
        setArModalToShow,
        setViewingTx,
        setFilterConversationId,
        setEmailBatch,
      }}
    >
      {props.children}
    </ARContext.Provider>
  );
};
