import React, {useEffect} from 'react';
import {Form, Formik, FormikHelpers} from 'formik';
import * as Yup from 'yup';

import {IPackageType} from '../../model/sales-order/PackageType';
import {ActionFormButton} from '../form/ActionFormButton';
import {usePackageTypeAPI} from '../../services/usePackageTypeAPI';

import {SDialog} from '../modals/modalSpendaMeterialUI';
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  IconButton,
  Grid,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {PackageType} from '../../assets/svg/PackageType';
import {EmptyPackage} from '../../assets/svg/EmptyPackage';

export type AddPackageTypeValues = {
  DefaultHeight_m?: number;
  DefaultLength_m?: number;
  DefaultWidth_m?: number;
  MaxWeight_kg?: number;
  Name: string;
  IsActive: boolean;
};

interface IAddPackageProps {
  open: boolean;
  packageTypeID?: any;
  onClose: (newPackage?: any) => void;
}

const useUnlinkDialogStyles = makeStyles(theme => ({
  dialog: {
    justifySelf: 'center',
    position: 'absolute',
  },
  dialogTitle: {
    borderBottom: 'none',
  },
  dialogContent: {
    padding: '0px 0px',
  },
  dialogActions: {
    borderTop: '1px solid #F1F1F1',
    margin: '0px 20px',
    padding: '8px 0px',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  topContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '75px',
    marginBottom: '75px',
  },
  greyContent: {
    backgroundColor: '#FAFAFA',
    border: '1px solid #EAEAEA',
    padding: '0px 16px',
  },
  tableHeads: {
    fontWeight: 500,
    fontSize: '12px',
    color: '#999999',
    marginBottom: '0.25rem',
  },
  textInput: {
    '& .MuiInput-formControl': {
      color: 'black',
      background: 'white',
      border: '1px solid #EAEAEA',
      borderRadius: '4px',
      padding: '0px 5px 0px 5px',
    },
  },
  gridBorder: {
    borderLeft: '1px solid rgba(151, 151, 151,0.1)',
    paddingLeft: '12px',
    marginLeft: '8px',
  },
}));

const validationSchema = Yup.object({
  Name: Yup.string().required('Box Name is required.').max(50, 'Box Name cannot be longer than 50 characters.'),
  DefaultHeight_m: Yup.number().min(0.1, 'must be greater than 0.1').required('Height is required'),
  DefaultWidth_m: Yup.number().min(0.1, 'must be greater than 0.1').required('Width is required'),
  DefaultLength_m: Yup.number().min(0.1, 'must be greater than 0.1').required('Length is required'),
  MaxWeight_kg: Yup.number().min(0.1, 'must be greater than 0.1').required('Weight is required'),
});

const newPackageType = {
  Name: '',
  IsActive: true,
} as AddPackageTypeValues;

export const AddPackageDialog: React.FunctionComponent<IAddPackageProps> = ({
  open,
  packageTypeID,
  onClose,
}: IAddPackageProps) => {
  const classes = useUnlinkDialogStyles();
  const {createPackageType, getPackageTypeById, updatePackageType} = usePackageTypeAPI();

  const [packageTypeToEdit, setPackageTypeToEdit] = React.useState<AddPackageTypeValues>(newPackageType);

  useEffect(() => {
    if (packageTypeID && open) {
      getPackage();
    }
  }, [packageTypeID, open]);

  const getPackage = async () => {
    const _package = await getPackageTypeById(packageTypeID);
    const _packageType = {
      Name: _package.Name,
      DefaultHeight_m: _package.DefaultHeight_m,
      DefaultLength_m: _package.DefaultLength_m,
      DefaultWidth_m: _package.DefaultWidth_m,
      MaxWeight_kg: _package.MaxWeight_kg,
      IsActive: _package.IsActive,
    } as AddPackageTypeValues;
    setPackageTypeToEdit(_packageType);
  };

  const closeDialog = (res?: any) => {
    if (onClose) {
      onClose(res);
    }
  };

  const onSave = async (values: IPackageType, helpers: FormikHelpers<IPackageType>) => {
    if (!packageTypeID) {
      return createPackageType(values).then(res => {
        if (res.IsSuccess) {
          helpers.resetForm();
          closeDialog(res.Value);
        }
      });
    } else {
      return updatePackageType({ID: packageTypeID, ...values}).then(res => {
        if (res.IsSuccess) {
          helpers.resetForm();
          closeDialog(res.Value);
        }
      });
    }
  };

  return (
    <Formik
      // innerRef={formRef}
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={packageTypeToEdit}
      onSubmit={onSave}
    >
      {({handleBlur, values, handleSubmit, handleChange, isSubmitting, errors, touched, resetForm, isValid}) => (
        <Form onSubmit={handleSubmit}>
          <SDialog open={open || false} classes={{paper: classes.dialog}}>
            <DialogTitle classes={{root: classes.dialogTitle}}>
              <Box display="flex" alignItems="top" justifyContent="end">
                <IconButton
                  aria-label="close"
                  className={classes.closeButton}
                  onClick={() => closeDialog()}
                  data-autoid="btnCloseAddPackage"
                >
                  <CloseIcon />
                </IconButton>
              </Box>
              <Box className="flex flex-col mt-5" alignItems="center" justifyContent="center">
                <PackageType />
                <p className="mt-3">Create Your Package Type</p>
              </Box>
            </DialogTitle>
            <DialogContent className={`${classes.dialogContent}`}>
              <div className={`${classes.topContent}`}>
                <EmptyPackage />
              </div>
              <Grid container sm={12} className={`${classes.greyContent} flex`}>
                <Grid item sm={3} className="flex flex-col px-1 py-3">
                  <p className={`${classes.tableHeads}`}>Shipping Box</p>
                  <TextField
                    className={`${classes.textInput}`}
                    type="name"
                    key={`Name`}
                    id={`Name`}
                    name={`Name`}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values?.Name}
                    helperText={touched?.Name && errors?.Name}
                    error={touched?.Name && Boolean(errors?.Name)}
                    fullWidth
                    inputProps={{'data-autoid': 'txtPackageName'}}
                  />
                </Grid>
                <Grid item sm={2} className="flex flex-col px-1 py-3">
                  <p className={`${classes.tableHeads}`}>Height</p>
                  <TextField
                    type="number"
                    className={`${classes.textInput}`}
                    key={`DefaultHeight_m`}
                    id={`DefaultHeight_m`}
                    name={`DefaultHeight_m`}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values?.DefaultHeight_m}
                    helperText={touched?.DefaultHeight_m && errors?.DefaultHeight_m}
                    error={touched?.DefaultHeight_m && Boolean(errors?.DefaultHeight_m)}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">m</InputAdornment>,
                    }}
                    fullWidth
                    inputProps={{'data-autoid': 'txtPackageHeight'}}
                  />
                </Grid>
                <Grid item sm={2} className="flex flex-col px-1 py-3">
                  <p className={`${classes.tableHeads}`}>Width</p>
                  <TextField
                    type="number"
                    className={`${classes.textInput}`}
                    key={`DefaultWidth_m`}
                    id={`DefaultWidth_m`}
                    name={`DefaultWidth_m`}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values?.DefaultWidth_m}
                    helperText={touched?.DefaultWidth_m && errors?.DefaultWidth_m}
                    error={touched?.DefaultWidth_m && Boolean(errors?.DefaultWidth_m)}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">m</InputAdornment>,
                    }}
                    fullWidth
                    inputProps={{'data-autoid': 'txtPackageWidth'}}
                  />
                </Grid>
                <Grid item sm={2} className="flex flex-col px-1 mr-3 py-3">
                  <p className={`${classes.tableHeads}`}>Length</p>
                  <TextField
                    type="number"
                    className={`${classes.textInput}`}
                    key={`DefaultLength_m`}
                    id={`DefaultLength_m`}
                    name={`DefaultLength_m`}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values?.DefaultLength_m}
                    helperText={touched?.DefaultLength_m && errors?.DefaultLength_m}
                    error={touched?.DefaultLength_m && Boolean(errors?.DefaultLength_m)}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">m</InputAdornment>,
                    }}
                    fullWidth
                    inputProps={{'data-autoid': 'txtPackageLength'}}
                  />
                </Grid>
                <Grid item sm={2} className={`${classes.gridBorder} flex flex-col pl-1 py-3`}>
                  <p className={`${classes.tableHeads}`}>Weight</p>
                  <TextField
                    type="number"
                    className={`${classes.textInput}`}
                    key={`MaxWeight_kg`}
                    id={`MaxWeight_kg`}
                    name={`MaxWeight_kg`}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values?.MaxWeight_kg}
                    helperText={touched?.MaxWeight_kg && errors?.MaxWeight_kg}
                    error={touched?.MaxWeight_kg && Boolean(errors?.MaxWeight_kg)}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">Kg</InputAdornment>,
                    }}
                    fullWidth
                    inputProps={{'data-autoid': 'txtPackageWeight'}}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions classes={{root: classes.dialogActions}}>
              <Box display="flex" justifyContent="space-between" width="100%">
                <ActionFormButton
                  onClick={() => {
                    closeDialog();
                    resetForm();
                  }}
                  color="transparent"
                  textColor="blueShade"
                  border="1px solid #1C78AD"
                  name="Cancel"
                  type="button"
                  lineHeight="m"
                  width="m"
                />
                <ActionFormButton
                  color="blueShade"
                  textColor="white"
                  border="1px solid #1C78AD"
                  name="Done"
                  lineHeight="m"
                  width="m"
                  type="submit"
                  disabled={!isValid || isSubmitting}
                  isSubmitting={isSubmitting}
                  onClick={() => {
                    handleSubmit();
                  }}
                />
              </Box>
            </DialogActions>
          </SDialog>
        </Form>
      )}
    </Formik>
  );
};
