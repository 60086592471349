import {IWarehouse} from '../model/Warehouse';
import {IActionResults, IPagedActionResults} from '../model/ActionResults';
import useHttp from '../hooks/useHttp';

const useWarehouseAPI = () => {
  const {GET, isLoading} = useHttp();

  const search = (): Promise<IWarehouse[]> => {
    return GET('warehouse').then((data: IActionResults<IWarehouse[]>) => data.Value);
  };

  const listWarehouses = (filter: any): Promise<IPagedActionResults<IWarehouse[]>> => {
    return GET('warehouse', filter).then((data: IPagedActionResults<IWarehouse[]>) => data);
  };

  return {search, listWarehouses, isLoading};
};

export default useWarehouseAPI;
