import React, {useState, useCallback, useRef, useEffect} from 'react';
import clsx from 'clsx';
import {debounce} from 'lodash';
import Autosuggest, {
  AutosuggestPropsSingleSection,
  RenderSuggestionsContainerParams,
  RenderInputComponentProps,
  SuggestionsFetchRequestedParams,
  SuggestionSelectedEventData,
} from 'react-autosuggest';
import {STextField} from '../inputs/STextField';
import {ClickAwayListener, InputAdornment, IconButton, Popper, Fade, makeStyles} from '@material-ui/core';
import KeyboardArrowUpRoundedIcon from '@material-ui/icons/KeyboardArrowUpRounded';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import T2TInventoryDropdownArrow from '../../assets/svg/T2TInventoryDropdownArrow';
import IconSearch from '../../assets/svg/IconSearch';
import LoadingIndicator from '../ui/LoadingIndicator';

export interface ISAutocomplete<T>
  extends Omit<AutosuggestPropsSingleSection<T>, 'inputProps' | 'onSuggestionsFetchRequested'> {
  isSalesModule?: boolean;
  size?: 'small' | 'medium';
  async?: boolean;
  hideInitialSuggestion?: boolean;
  loading?: boolean;
  placeholder?: string;
  disabled?: boolean;
  suggestions: T[];
  selected: T | null | undefined;
  helperText?: string | boolean | undefined;
  error?: boolean | undefined;
  addNewClick?: ((value: string) => void) | boolean | undefined;
  clearClick?: (() => void) | boolean | undefined;
  getSuggestions: any;
  renderSuggestion: (suggestion: T) => React.ReactNode | string;
  getSuggestionValue: (suggestion: T) => string;
  onSearchChange: (item: string) => void;
  onSuggestionSelected: (event: React.FormEvent<any>, selected: SuggestionSelectedEventData<T>) => void;
  noRecordMsg?: string;
  autoFocus?: boolean;
  handleKeyPress?: (event: React.KeyboardEvent) => void;
  styles?: any;
  label?: string;
  hideDropDown?: boolean;
  isT2TSupplierModule?: boolean;
  isLatestDesign?: boolean;
  isServiceAsset?: boolean;
}

export const SAutocomplete = <T,>(props: ISAutocomplete<T>) => {
  const useAutocompleteStyles = makeStyles(() => ({
    iconButtonRootV2: {
      padding: '0px 6px',
      height: '45px',
      backgroundColor: 'hsl(var(--primary))',
      borderRadius: '0 6px 6px 0',
      '& svg': {
        color: '#FFFFFF !important',
      },
      '&:hover': {
        padding: '0px 6px',
        height: '45px',
        backgroundColor: 'hsl(var(--primary))',
        borderRadius: '0 6px 6px 0',
        '& svg': {
          color: '#FFFFFF !important',
        },
      },
    },
    iconButtonRoot: {
      padding: 0,
    },
    iconRoot: {
      color: 'hsl(var(--primary))',
      fontSize: '1.25rem',
    },
    addIcon: {
      borderLeft: '1px solid #EAEAEA',
      paddingLeft: '2px',
    },
    container: {
      width: '100%',
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
    },
    suggestionsContainerOpen: {
      paddingTop: '2px',
      paddingBottom: '2px',
      backgroundColor: '#FFFFFF',
      color: '#444343',
      fontWeight: 500,
      fontSize: '1rem',
      borderRadius: '4px',
      border: '1px solid #EAEAEA',
      boxShadow: '0 1px 10px 0 #00000020',
      position: 'absolute',
      zIndex: 9999,
      // marginTop: '-1.4rem',
      left: 0,
      right: 0,
      overflowY: 'auto',
      overflowX: 'auto',
      maxHeight: '30%',
      ...(typeof props.styles === 'object' ? props.styles : {}),
    },
    suggestion: {
      display: 'block',
      padding: '3px 5px',
      '&:hover': {
        backgroundColor: 'hsl(var(--primary-background))',
      },
    },
    suggestionsList: {
      margin: 0,
      padding: 0,
      listStyleType: 'none',
    },
    textfield: {
      width: props.isT2TSupplierModule || props.isLatestDesign ? '100%' : '',
      '& .MuiFormLabel-root ': {
        top: props.isLatestDesign ? '2px!important' : '-6px',
      },
      '& .MuiInputBase-root': {
        backgroundColor: props.isT2TSupplierModule ? 'transparent' : '#ffffff !important',
        caretColor: '#BBBBBB',
        fontSize: '1rem',
        width: props.isSalesModule
          ? props.isT2TSupplierModule || props.isLatestDesign
            ? '100%'
            : '426px'
          : props.isT2TSupplierModule
            ? '100%'
            : '255px',
        padding: '2px 0px 2px 10px',
        fontFamily: 'Poppins, sans-serif',
        height: '46px!important',
        '&::placeholder': {
          color: props.isT2TSupplierModule ? '#333' : 'inherit',
        },
      },

      '& .MuiInputLabel-shrink, .MuiInputBase-root .MuiOutlinedInput-notchedOutline legend': {
        fontSize: '10px!important',
      },
      '& .MuiInputAdornment-root': {
        margin: '0px!important',
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'hsl(var(--primary))',
      },
    },
  }));

  const inputRef = useRef<HTMLInputElement>(null);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [menuWidth, setMenuWidth] = useState<number>();
  const [autocompleteValue, setAutocompleteValue] = useState<string>('');
  const [dropdownClick, setDropdownClick] = useState<boolean>(false);

  const classes = useAutocompleteStyles();
  useEffect(() => {
    setMenuWidth(inputRef.current ? inputRef.current.offsetWidth : 0);
  }, [inputRef.current]);

  const {
    isSalesModule,
    async,
    size,
    hideInitialSuggestion,
    loading,
    placeholder,
    disabled,
    suggestions,
    selected,
    helperText,
    error,
    addNewClick,
    clearClick,
    getSuggestions,
    renderSuggestion,
    getSuggestionValue,
    onSearchChange,
    onSuggestionSelected,
    noRecordMsg,
    autoFocus,
    handleKeyPress,
    label,
    hideDropDown = false,
    isT2TSupplierModule,
    isLatestDesign,
    isServiceAsset,
  } = props;

  useEffect(() => {
    if (selected) {
      setAutocompleteValue(getSuggestionValue(selected));
    } else {
      setAutocompleteValue('');
    }
  }, [props.selected]);

  useEffect(() => {
    if (autocompleteValue.length >= 3 && !menuOpen && !selected) {
      setMenuOpen(true);
    }
    if (autocompleteValue.length < 3 && menuOpen) {
      setMenuOpen(false);
    }
  }, [autocompleteValue]);

  const defaultDebounceInterval = 750;

  const handleClickAway = () => {
    setMenuOpen(false);
  };

  const handleMousedown = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleDropDownClick = (event: React.MouseEvent) => {
    setDropdownClick(!dropdownClick);
    if (!menuOpen && !hideInitialSuggestion) {
      getSuggestions(autocompleteValue);
    }
    setMenuOpen(!menuOpen);
    event.stopPropagation();
  };

  const handleAddNewClick = () => {
    if (typeof addNewClick === 'function') {
      addNewClick('');
    }
  };

  const handleClearClick = () => {
    if (typeof clearClick === 'function') {
      clearClick();
      setMenuOpen(false);
    }
  };

  const debouncedSearch = useCallback(
    debounce(
      (newValue: string) => {
        if (newValue.length > 2) {
          onSearchChange(newValue);
        }
      },
      !async ? 0 : defaultDebounceInterval,
    ),
    [],
  );

  const updateAutocompleteValue = (event: React.FormEvent<HTMLElement>) => {
    if (typeof (event.target as HTMLInputElement).value === 'string') {
      setAutocompleteValue((event.target as HTMLInputElement).value);
    } else {
      setAutocompleteValue('');
    }
  };

  const onSuggestionsFetchRequested = (suggestion: SuggestionsFetchRequestedParams) => {
    if (suggestion.reason === 'input-changed') {
      debouncedSearch(suggestion.value);
    } else if (suggestion.reason === 'suggestion-selected') {
      handleClickAway();
    }
  };

  const handleCloseMenu = () => {
    if (dropdownClick) {
      setMenuOpen(false);
      setDropdownClick(false);
    }
  };

  const renderInputComponent = (inputProps: RenderInputComponentProps): React.ReactNode => {
    const {value, placeholder, disabled, onChange, ref, autoFocus} = inputProps;
    return (
      <STextField
        v2={isSalesModule || isT2TSupplierModule}
        variant={isSalesModule || isT2TSupplierModule ? 'outlined' : 'standard'}
        className={isSalesModule || isT2TSupplierModule ? classes.textfield : ''}
        size={size || 'medium'}
        value={value}
        autoFocus={autoFocus}
        placeholder={placeholder}
        disabled={disabled}
        onChange={onChange}
        key="autocomplete-textfield"
        name={placeholder?.replace(/ /g, '')}
        onClick={handleCloseMenu}
        label={label}
        onKeyDown={e =>
          e.key === 'Enter' && !menuOpen && typeof handleKeyPress === 'function' ? handleKeyPress(e) : null
        }
        helperText={helperText}
        error={error}
        InputProps={{
          inputRef: ref,
          endAdornment: (
            <InputAdornment position="end">
              {!hideInitialSuggestion && !selected && !isT2TSupplierModule && (
                <IconButton
                  disabled={disabled}
                  className={isSalesModule ? classes.iconButtonRootV2 : classes.iconButtonRoot}
                  onClick={handleDropDownClick}
                  onMouseDown={handleMousedown}
                  data-autoid="btnAutocompleteEndAdornment"
                >
                  {isSalesModule ? (
                    <IconSearch fill="currentColor" width={'35px'} />
                  ) : menuOpen ? (
                    <KeyboardArrowUpRoundedIcon className={classes.iconRoot} />
                  ) : (
                    <>
                      <IconSearch fill="currentColor" width={'35px'} />
                      <KeyboardArrowDownRoundedIcon className={classes.iconRoot} />
                    </>
                  )}
                </IconButton>
              )}
              {isT2TSupplierModule && (
                <IconButton
                  disabled={disabled}
                  className={classes.iconButtonRoot}
                  onClick={handleDropDownClick}
                  onMouseDown={handleMousedown}
                  data-autoid="btnAutocompleteEndAdornment"
                >
                  <T2TInventoryDropdownArrow className="mr-2" />
                </IconButton>
              )}
              {addNewClick && !selected ? (
                <IconButton
                  disabled={disabled}
                  className={classes.iconButtonRoot}
                  onClick={handleAddNewClick}
                  onMouseDown={handleMousedown}
                  data-autoid="btnAutocompleteAdd"
                >
                  <AddCircleOutlineRoundedIcon className={clsx(classes.iconRoot, classes.addIcon)} />
                </IconButton>
              ) : null}
              {clearClick && selected ? (
                <IconButton
                  disabled={disabled}
                  className={classes.iconButtonRoot}
                  onClick={handleClearClick}
                  onMouseDown={handleMousedown}
                  data-autoid="btnAutocompleteClear"
                >
                  <ClearRoundedIcon className={clsx(classes.iconRoot, classes.addIcon, '!w-10')} />
                </IconButton>
              ) : null}
            </InputAdornment>
          ),
        }}
      />
    );
  };

  const renderSuggestionsContainer = (suggestionProps: RenderSuggestionsContainerParams) => {
    const {containerProps, children} = suggestionProps;

    if (hideDropDown) {
      return null;
    }

    return (
      <Popper
        open={menuOpen}
        disablePortal={false}
        anchorEl={menuOpen ? inputRef?.current : null}
        placement="bottom-start"
        transition
        {...containerProps}
        style={{width: menuWidth, fontFamily: isSalesModule ? 'Poppins, sans-serif' : '', fontSize: '12px'}}
        className={`${classes.suggestionsContainerOpen}`}
        modifiers={{
          flip: {
            enabled: true,
          },
        }}
      >
        {({TransitionProps}) => (
          <Fade {...TransitionProps} timeout={250}>
            {loading ? (
              <div className="jusitfy-center flex items-center p-2 h-5">
                <LoadingIndicator isLoading size={'sm'} />
              </div>
            ) : (
              <div>
                {children || (
                  <p className={`${isT2TSupplierModule ? 'font-poppins' : ''} text-center font-medium`}>
                    {noRecordMsg || 'No result found'}
                  </p>
                )}
              </div>
            )}
          </Fade>
        )}
      </Popper>
    );
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div
        ref={inputRef}
        className={`${isLatestDesign ? (isServiceAsset ? 'mx-auto max-w-[340px]' : 'mb-7 flex max-w-[368px]') : ''} w-full`}
      >
        <Autosuggest
          focusInputOnSuggestionClick={false}
          suggestions={suggestions}
          alwaysRenderSuggestions={menuOpen}
          shouldRenderSuggestions={() => menuOpen || autocompleteValue.length >= 2}
          renderSuggestion={renderSuggestion}
          getSuggestionValue={getSuggestionValue}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={() => {}}
          onSuggestionSelected={onSuggestionSelected}
          renderInputComponent={renderInputComponent}
          renderSuggestionsContainer={renderSuggestionsContainer}
          inputProps={{
            value: autocompleteValue,
            placeholder: placeholder || '',
            disabled: disabled || false,
            autoFocus: autoFocus,
            onChange: updateAutocompleteValue,
          }}
          theme={{
            container: classes.container,
            suggestionsContainerOpen: classes.suggestionsContainerOpen,
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion,
          }}
        />
      </div>
    </ClickAwayListener>
  );
};
