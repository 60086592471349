import * as React from 'react';
import {SVGProps} from 'react';

const PSBLOnbPostingConfigurationIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.563 2.727 15.659.76A2.517 2.517 0 0 0 13.863 0h-3.93A4.174 4.174 0 0 0 5.85 3.333h-.083A4.172 4.172 0 0 0 1.6 7.5v8.333A4.172 4.172 0 0 0 5.767 20h5a4.172 4.172 0 0 0 4.166-4.167v-.083a4.174 4.174 0 0 0 3.334-4.083v-7.2a2.49 2.49 0 0 0-.704-1.74Zm-6.796 15.606h-5a2.5 2.5 0 0 1-2.5-2.5V7.5a2.5 2.5 0 0 1 2.5-2.5v6.667a4.172 4.172 0 0 0 4.166 4.166h3.334a2.5 2.5 0 0 1-2.5 2.5Zm3.333-4.166H9.933a2.5 2.5 0 0 1-2.5-2.5v-7.5a2.5 2.5 0 0 1 2.5-2.5h3.334v1.666c0 .92.746 1.667 1.666 1.667H16.6v6.667a2.5 2.5 0 0 1-2.5 2.5Z"
      fill="hsl(var(--primary))"
    />
  </svg>
);

export default PSBLOnbPostingConfigurationIcon;
