import clsx from 'clsx';
import React from 'react';
import {Checkbox} from 'spenda-ui-react';

export const ARSelectToggler = (props: {
  isSelected: boolean;
  className?: string;
  containerClassName?: string;
  dataAutoId?: string;
}) => {
  return (
    <Checkbox
      checked={props?.isSelected}
      className={clsx(
        `checked:border-primary, h-[22px] w-[22px] rounded-full border-primary bg-white transition-all checked:bg-primary hover:before:opacity-0`,
        props.className,
      )}
      containerProps={{className: props.containerClassName}}
      data-autoid={props.dataAutoId}
      ripple={false}
    />
  );
};
