import {useEffect, useState} from 'react';
import {
  ICalendarEvents,
  IDailySummaryJob,
  IServiceJobTechnician,
  ITechnician,
  ITechnicianViewJobs,
} from '../model/service-management/serviceJob';
import useServiceJobAPI from '../services/useServiceJobAPI';
import _ from 'lodash';
import moment from 'moment';

interface BookingDiaryHookProps {
  searchString: string;
  jobDialogOpen: boolean;
  selectedDate: Date;
  isFetchingTechnicians?: boolean;
  alreadySelectedTechnicians?: IServiceJobTechnician[];
  initialTechnicians?: ITechnician[];
  isShowUnassignedServiceJobs?: boolean;
}

const useBookingDiary = (param: BookingDiaryHookProps) => {
  const {
    searchString,
    jobDialogOpen,
    selectedDate,
    isFetchingTechnicians,
    alreadySelectedTechnicians,
    initialTechnicians,
    isShowUnassignedServiceJobs,
  } = param;

  const {getServiceTechnicians, getDailySummaryJobs, getSchedulerViewJobs, isLoading} = useServiceJobAPI();

  const [technicians, setTechnicians] = useState<ITechnician[]>(initialTechnicians ?? []);
  const [selectedTechnicians, setSelectedTechnicians] = useState<number[]>(
    initialTechnicians?.map(tech => tech.userID!) ?? [],
  );
  const [schedulerData, setSchedulerData] = useState<ITechnicianViewJobs[]>([]);
  const [calendarEvents, setCalendarEvents] = useState<ICalendarEvents[]>([]);

  const fetchTechnicians = async () => {
    const technicians = await getServiceTechnicians();
    const sortedTechnicians = _.sortBy(technicians, [obj => (obj.userID === -1 ? 0 : 1), 'firstName']);
    setTechnicians(sortedTechnicians);
    const selectedTech = sortedTechnicians.map(tech => tech.userID!);
    setSelectedTechnicians(selectedTech);
    return selectedTech;
  };

  useEffect(() => {
    if (isFetchingTechnicians) {
      fetchTechnicians();
    }
  }, [isFetchingTechnicians]);

  const fetchSummaryJobs = async (startDate = new Date(), endDate = new Date(), technicians = selectedTechnicians) => {
    const responses = await getDailySummaryJobs({
      StartDate: moment(startDate).format('YYYY-MM-DD'),
      EndDate: moment(endDate).format('YYYY-MM-DD'),
      ServiceTechnicianIDs: technicians,
      IsShowUnassignedServiceJobs: isShowUnassignedServiceJobs,
    });
    handleCalendarEvents(responses);
  };

  useEffect(() => {
    if (alreadySelectedTechnicians && alreadySelectedTechnicians.length > 0) {
      return;
    }
  }, [searchString, jobDialogOpen, isShowUnassignedServiceJobs]);

  const getAlreadySelectedTechnicians = () => {
    if (!alreadySelectedTechnicians) return [];
    let selectedTechs: number[] = [];
    for (let i = 0; i < alreadySelectedTechnicians?.length; i++) {
      const technician = technicians.find(tech => tech.userID == alreadySelectedTechnicians[i].technicianUserID);
      if (technician && technician.userID) {
        selectedTechs.push(technician.userID);
      }
    }
    return selectedTechs;
  };

  useEffect(() => {
    const already = getAlreadySelectedTechnicians();
    if (already.length === 0) {
      return;
    }
    if (already.length > 0) {
      setSelectedTechnicians(already);
    }
    const selectedTech = already.length > 0 ? already : selectedTechnicians;
    fetchSchedulerViewJobs(selectedTech);
  }, [alreadySelectedTechnicians, selectedDate]);

  const fetchSchedulerViewJobs = async (selectedTech = selectedTechnicians) => {
    const jobs = await getSchedulerViewJobs({
      StartDate: moment(selectedDate).format('YYYY-MM-DD'),
      EndDate: moment(selectedDate).format('YYYY-MM-DD'),
      ServiceTechnicianIDs: selectedTech,
      SearchString: searchString,
      IsShowUnassignedServiceJobs: isShowUnassignedServiceJobs,
    });
    setSchedulerData(jobs);
  };

  useEffect(() => {
    if (alreadySelectedTechnicians && alreadySelectedTechnicians.length > 0) {
      return;
    }

    if (selectedDate && !jobDialogOpen) {
      fetchSchedulerViewJobs(selectedTechnicians);
    }
  }, [selectedDate, searchString, jobDialogOpen]);

  const handleCalendarEvents = (result: IDailySummaryJob[]) => {
    if (result && result.length) {
      let events = result.map(event => {
        if (!event.countJobs && !event.totalBookedJobHours) return;
        return {
          title: `${event?.countJobs} ${event?.countJobs && event.countJobs > 1 ? 'Jobs' : 'Job'} (${event?.totalBookedJobHours || 0} ${event?.totalBookedJobHours > 1 ? 'hrs' : 'hr'})`,
          date: new Date(event.calendarDate),
        };
      });
      events = events.filter(function (e) {
        return e;
      });
      setCalendarEvents(events as ICalendarEvents[]);
    }
  };

  return {
    technicians,
    setTechnicians,
    selectedTechnicians,
    setSelectedTechnicians,
    schedulerData,
    setSchedulerData,
    calendarEvents,
    setCalendarEvents,
    fetchSummaryJobs,
    fetchSchedulerViewJobs,
    fetchTechnicians,
    isLoading,
  };
};

export {useBookingDiary};
