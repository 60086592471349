import React, {useState, useEffect} from 'react';
import {SuggestionSelectedEventData} from 'react-autosuggest';
import {Card, CardBody, Carousel, Typography} from 'spenda-ui-react';
import {SAutocomplete} from '../inputs/SAutocomplete';
import {useCustomerClassesAPI} from '../../services/useCustomerClassesAPI';
import {useLocationAPI} from '../../services/useLocationAPI';
import {IPlaceAutoCompleteResult} from '../../model/address/place-prediction';
import ArrowLeftRoundedIcon from '@material-ui/icons/ArrowLeftRounded';
import ArrowRightRoundedIcon from '@material-ui/icons/ArrowRightRounded';
interface ILocationAutoComplete<T> {
  id?: string;
  name?: string;
  value?: any;
  placeholder?: string;
  types?: string;
  multiple?: boolean;
  disabled?: boolean;
  selected?: any;
  addNewClick?: ((value: string) => void) | boolean | undefined;
  clearClick?: (() => void) | boolean | undefined;
  onSuggestionSelected: (event: React.FormEvent<Object>, selected: SuggestionSelectedEventData<T>) => void;
  styles?: any;
  carouselUI?: boolean;
  label?: string;
  isT2TSupplierModule?: boolean;
  isLatestDesign?: boolean;
  isSalesModule?: boolean;
  isServiceAsset?: boolean;
}

export const LocationAutoComplete = (props: ILocationAutoComplete<Partial<IPlaceAutoCompleteResult>>) => {
  const {
    placeholder,
    label,
    selected,
    addNewClick,
    clearClick,
    isSalesModule,
    onSuggestionSelected,
    carouselUI,
    isT2TSupplierModule,
    isLatestDesign,
    isServiceAsset,
    disabled,
  } = props;

  const {isLoading} = useCustomerClassesAPI();
  const {getLocationBySearch} = useLocationAPI();

  const [suggestions, setSuggestions] = useState<Partial<IPlaceAutoCompleteResult>[]>([]);

  const getLocationList = (Search: string) => {
    getLocationBySearch(Search).then((res: IPlaceAutoCompleteResult[]) => {
      if (res?.length) setSuggestions(res);
    });
  };

  useEffect(() => {
    if (selected) {
      setSuggestions([selected]);
    }
  }, [selected]);

  const handleSuggestionSelected = (
    event: React.FormEvent<any>,
    _selected: SuggestionSelectedEventData<Partial<IPlaceAutoCompleteResult>>,
  ) => {
    onSuggestionSelected(event, _selected);
    setSuggestions([_selected.suggestion]);
  };

  const prevArrow = ({loop, handlePrev, firstIndex}: {loop: boolean; handlePrev: () => void; firstIndex: boolean}) => {
    return (
      <button
        data-autoid="btnPrev"
        onClick={handlePrev}
        disabled={!loop && firstIndex ? true : false}
        className={`${isServiceAsset ? 'left-[-42px] top-[46%]' : 'left-[-35px] top-[46%]'} !absolute z-10 grid h-12 max-h-[55px] w-12 max-w-[55px] -translate-y-2/4 select-none place-items-center transition-all disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none`}
      >
        <ArrowLeftRoundedIcon style={{color: 'inherit', fontSize: '55px'}} />
      </button>
    );
  };

  const nextArrow = ({loop, handleNext, lastIndex}: {loop: boolean; handleNext: () => void; lastIndex: boolean}) => {
    return (
      <button
        data-autoid="btnNext"
        onClick={handleNext}
        disabled={!loop && lastIndex ? true : false}
        className={`${isServiceAsset ? 'right-[-30px] top-[46%]' : 'right-[-39.5rem] top-[46%]'} !absolute z-10 grid h-12 max-h-[55px] w-12 max-w-[55px] -translate-y-2/4 select-none place-items-center transition-all disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none`}
      >
        <ArrowRightRoundedIcon style={{color: 'inherit', fontSize: '55px'}} />
      </button>
    );
  };

  const handleClearClick = () => {
    setSuggestions([]);
    if (typeof clearClick === 'function') {
      clearClick();
    }
  };

  return (
    <>
      <SAutocomplete<Partial<IPlaceAutoCompleteResult>>
        async
        hideDropDown={isSalesModule && carouselUI}
        styles={props.styles}
        loading={isLoading}
        placeholder={placeholder || 'Search location'}
        addNewClick={addNewClick}
        clearClick={handleClearClick}
        suggestions={isSalesModule && carouselUI ? [] : suggestions}
        selected={selected}
        getSuggestions={(value: string) => {
          if (value) {
            getLocationList(value);
          }
        }}
        renderSuggestion={(item: Partial<IPlaceAutoCompleteResult>) => (
          <>
            <p className={isT2TSupplierModule ? 'cursor-pointer font-poppins text-spenda-primarytext' : ''}>
              {item.PlaceDescription}
            </p>
          </>
        )}
        getSuggestionValue={(suggestion: Partial<IPlaceAutoCompleteResult>) => {
          return `${suggestion.PlaceDescription}`;
        }}
        onSearchChange={(value: string) => {
          if (value) getLocationList(value);
        }}
        onSuggestionSelected={handleSuggestionSelected}
        isSalesModule={isSalesModule}
        label={label || 'Search location'}
        isT2TSupplierModule={isT2TSupplierModule}
        isLatestDesign={isLatestDesign}
        isServiceAsset={isServiceAsset}
        disabled={disabled}
      />
      {isSalesModule && carouselUI && suggestions.length && !selected ? (
        <div className={`${isLatestDesign ? (isServiceAsset ? ' px-0' : 'px-6') : ''} relative mb-5`}>
          <Carousel
            itemsToShow={4}
            className={`${isServiceAsset ? 'w-full max-w-[923px]' : 'w-[880px]'} static my-2.5 h-full`}
            prevArrow={prevArrow}
            nextArrow={nextArrow}
          >
            {suggestions.map((suggestion, index) => (
              <div key={suggestion.PlaceID} className="!h-full !w-full px-1 py-2">
                <Card
                  onClick={e =>
                    handleSuggestionSelected(e, {
                      suggestion: suggestion,
                      suggestionValue: suggestion.PlaceDescription || '',
                      suggestionIndex: index,
                      sectionIndex: null,
                      method: 'click',
                    })
                  }
                  data-autoid={`btnSuggestion${suggestion.PlaceDescription}`}
                  className="min-h-[95px] cursor-pointer shadow-[0_0_6px_0_#908E8E50]"
                >
                  <CardBody className="px-2 py-3 text-center">
                    <Typography className="font-semibold text-primary">{suggestion.PlaceDescription}</Typography>
                  </CardBody>
                </Card>
              </div>
            ))}
          </Carousel>
        </div>
      ) : null}
    </>
  );
};
