import React, {useEffect, useRef, useState} from 'react';
import {Form as FormikForm, Formik, FormikProps} from 'formik';
import * as Yup from 'yup';
import moment from 'moment';

import {ConversationStatus, ConversationType} from '../../model/constants/Constants';
import {
  IConversationChatbox,
  IConvoGetMessagesResponse,
  IDocumentConversationSubjectObj,
} from '../../model/conversation/Conversation';
import {
  IInitiateConvoPayload,
  IInitiateConvoWithoutMsgPayload,
  useConversationAPI,
} from '../../services/useConversationAPI';
import {ChatBoxBody} from './ChatBoxBody';
import {ChatBoxFooter} from './ChatBoxFooter';
import {ChatBoxHeader} from './ChatBoxHeader';

import {Box, makeStyles} from '@material-ui/core';

interface IChatBoxProps {
  chatBoxNumber: number;
  handleMinOrMaxChatBox: (operationId: string, isMinimizeAllOpenChatWindow?: boolean) => void;
  handleCloseChatBox: (operationId: string) => void;
  conversationData: IConversationChatbox;
  tenantId: string;
  userReadTheMessage: Function;
  updateConversationId: Function;
  handleResolveConversation: (operationId: string) => void;
  scope?: string;
}

export interface IConversationChatboxInput {
  message: string;
  subjectId: string;
  subjectName: string;
  isSubjectSelected: boolean;
  attachments: File[];
}

export const formInitialValues = {
  message: '',
  subjectId: '',
  attachments: [],
  subjectName: '',
  isSubjectSelected: false,
};

let newLineMsgTimeOutId: number = 0;
let refreshMsgIntervalId: number = 0;

export const ChatBox = (props: IChatBoxProps) => {
  const {
    initiateConversation,
    sendMessage,
    getMessages,
    getMessagesDataByGuidIds,
    getSubjectList,
    createSubject,
    uploadAttachments,
    initiateConversationWithoutMsg,
    isLoading,
  } = useConversationAPI();

  const formRef = useRef<FormikProps<IConversationChatboxInput>>(null);

  const [messageData, setMessagesData] = useState<IConvoGetMessagesResponse>();
  const [isShowNewMsgLine, setIsShowNewMsgLine] = useState<boolean>(false);
  const [scrollToBottom, setScrollToBottom] = useState<boolean>(false);
  const [subjectList, setSubjectList] = useState<IDocumentConversationSubjectObj[] | undefined>();

  const {
    chatBoxNumber,
    handleMinOrMaxChatBox,
    handleCloseChatBox,
    conversationData,
    tenantId,
    userReadTheMessage,
    updateConversationId,
    scope,
  } = props;

  const useChatBoxStyles = makeStyles(() => ({
    chatboxForm: {
      position: 'relative',
      borderRadius: '12px 12px 0px 0px',
      width: '100%',
      right: '0px',
      bottom: '0px',
      zIndex: 999,
      padding: 0,
    },
    chatbox1: {
      right: '370px',
    },
    chatbox2: {
      right: '730px',
    },
  }));

  const classes = useChatBoxStyles();

  useEffect(() => {
    return () => {
      window.clearInterval(refreshMsgIntervalId);
      window.clearTimeout(newLineMsgTimeOutId);
    };
  }, []);

  useEffect(() => {
    if (isShowNewMsgLine) {
      window.clearTimeout(newLineMsgTimeOutId);
      newLineMsgTimeOutId = window.setTimeout(() => {
        userReadTheMessage(conversationData.operationUniqueId);
        setIsShowNewMsgLine(false);
      }, 5000);
      return () => {
        window.clearTimeout(newLineMsgTimeOutId);
      };
    }
  }, [isShowNewMsgLine]);

  useEffect(() => {
    if (conversationData.isChatboxOpen && !conversationData.conversationId) {
      getInvoiceSubjectsList();
      if (
        conversationData.convoType === ConversationType.DOCUMENT &&
        conversationData.guid &&
        conversationData.datTypeID
      ) {
        getMessagesDataByGuidIds([conversationData.guid], conversationData.datTypeID)
          .then((data: any) => {
            if (data?.documentMessageSummaryList?.length) {
              updateConversationId(
                conversationData.operationUniqueId,
                data.documentMessageSummaryList[0].messageSummary.conversationID
              );
            }
          })
          .catch(_e => {});
      }
    }
    if (conversationData.isChatboxOpen && conversationData.conversationId) {
      getConversationMessages(true);
      refreshMsgIntervalId = window.setInterval(() => {
        getConversationMessages();
      }, 30000);
    } else {
      window.clearInterval(refreshMsgIntervalId);
    }
  }, [conversationData.isChatboxOpen, conversationData.conversationId]);

  useEffect(() => {
    if (scrollToBottom) {
      scrollToMessagesBottom();
    }
  }, [scrollToBottom, messageData]);

  const scrollToMessagesBottom = () => {
    const scrollKeepBottom = document.getElementById(`scroll-to-bottom-${conversationData.operationUniqueId}`);
    if (scrollKeepBottom) {
      scrollKeepBottom.scrollTo({top: scrollKeepBottom.scrollHeight, behavior: 'smooth'});
    }
  };

  const getConversationMessages = (isScrollToBottom = false) => {
    if (conversationData.conversationId) {
      const userTimeZone = moment().utcOffset() || undefined;
      const isCustomerLevelConvo = conversationData.convoType === ConversationType.CUSTOMER;
      getMessages(conversationData.conversationId, isCustomerLevelConvo, userTimeZone)
        .then(data => {
          if (data) {
            setMessagesData(data);
            setScrollToBottom(isScrollToBottom);
            data?.unreadMessages && setIsShowNewMsgLine(true);
            if (data?.status === ConversationStatus.Closed) {
              let errorMsg = 'This conversation is closed ';
              if (conversationData.supplierID) {
                errorMsg += 'by supplier';
              } else if (conversationData.customerID) {
                errorMsg += 'by buyer';
              }
              formRef.current && formRef.current.setFieldError('message', errorMsg);
              formRef.current && formRef.current.setFieldTouched('message', true);
              setTimeout(() => {
                props.handleResolveConversation(conversationData.operationUniqueId);
              }, 3000);
            }
          }
        })
        .catch(() => {});
    }
  };

  const getInvoiceSubjectsList = () => {
    const supId = conversationData?.supplierID ? conversationData.supplierID : 0;
    const cusId = conversationData?.customerID ? conversationData.customerID : 0;
    getSubjectList(supId, cusId)
      .then(data => {
        if (data?.subjects) {
          setSubjectList(data.subjects);
        }
      })
      .catch(() => {});
  };

  const handleResolveConversation = () => {
    if (conversationData?.conversationId) {
      const payload = {
        text: null,
        status: ConversationStatus.Closed,
      };
      sendMessage(conversationData.conversationId, payload)
        .then(data => {
          if (data) {
            props.handleResolveConversation(conversationData.operationUniqueId);
          }
        })
        .catch(() => {});
    }
  };

  const handleMarkAsUnread = (operationId: string) => {
    if (conversationData?.conversationId) {
      const payload = {
        text: null,
        status: ConversationStatus.Open,
        markAsUnread: true,
      };
      sendMessage(conversationData.conversationId, payload)
        .then(() => {
          handleMinOrMaxChatBox(operationId);
        })
        .catch(() => {});
    }
  };

  const onSubmit = async (values: IConversationChatboxInput, formikBag: any) => {
    if (!conversationData.supplierID && !conversationData.customerID) return;

    const {message, attachments, subjectId, subjectName} = values;
    const {conversationId, datTypeID, refNumber, guid, customerID, supplierID, convoType} = conversationData;
    const {setFieldError, setFieldTouched, resetForm, setFieldValue} = formikBag;
    const messageErrorText = 'Your message could not be sent. Please try again';

    if (subjectId === 'other') {
      try {
        const createSubjectRes = await createSubject({subjectID: 0, isEnable: true, description: subjectName});
        if (createSubjectRes) {
          setSubjectList(subjectList ? [...subjectList, createSubjectRes.subject] : [createSubjectRes.subject]);
          resetForm();
          await setFieldValue('subjectId', String(createSubjectRes.subject.subjectID));
        }
      } catch {
        await setFieldError('subjectId', 'Something went wrong please try again!');
        await setFieldTouched('subjectId', true);
      }
      return;
    }

    let ErrorType = 0;

    if (conversationId) {
      try {
        if (message) {
          const payload = {
            text: message,
            status: ConversationStatus.Open,
          };
          await sendMessage(conversationId, payload);
        }
      } catch {
        ErrorType = 1;
      } finally {
        try {
          if (attachments?.length) {
            const formData = new FormData();
            attachments.forEach((attachment: File) => {
              formData.append(attachment.name, attachment, attachment.name);
            });
            await uploadAttachments(conversationId, formData, {
              'content-type': 'multipart/form-data',
            });
          }
        } catch {
          !ErrorType ? (ErrorType = 2) : (ErrorType = 3);
        } finally {
          switch (ErrorType) {
            case 0:
              resetForm();
              break;
            case 1:
              attachments?.length && (await setFieldValue('attachments', []));
              await setFieldError('message', messageErrorText);
              await setFieldTouched('message', true);
              break;
            case 2:
              await setFieldValue('message', '');
              await setFieldError('attachments', 'Some error occurs while sending attachments');
              await setFieldTouched('attachments', true);
              break;
            case 3:
              await setFieldError('attachments', 'Some error occurs while sending message and attachments');
              await setFieldTouched('attachments', true);
              break;
            default:
              break;
          }
          getConversationMessages(true);
        }
      }
    } else if (!conversationId) {
      let conversationId = undefined;
      try {
        if (message) {
          const payload: IInitiateConvoPayload = {
            supplierID: supplierID || 0,
            customerID: customerID || 0,
            subjectID: parseInt(subjectId),
            text: message,
            status: ConversationStatus.Open,
          };

          if (convoType === ConversationType.DOCUMENT && datTypeID && guid && refNumber) {
            payload.refBusTrans = {
              datTypeID: datTypeID,
              guid: guid,
              refNumber: refNumber,
            };
          }
          const initiateConvoRes = await initiateConversation(payload, convoType === ConversationType.CUSTOMER);
          if (initiateConvoRes?.conversationID) {
            conversationId = initiateConvoRes.conversationID;
          }
        } else {
          const payload: IInitiateConvoWithoutMsgPayload = {
            supplierID: supplierID || 0,
            customerID: customerID || 0,
            isCustomerLevelConversation: convoType === ConversationType.CUSTOMER,
            subjectID: parseInt(subjectId),
          };
          if (convoType === ConversationType.DOCUMENT && datTypeID && guid && refNumber) {
            payload.refBusTrans = {
              datTypeID: datTypeID,
              guid: guid,
              refNumber: refNumber,
            };
          }
          const res = await initiateConversationWithoutMsg(
            payload,
            conversationData.convoType === ConversationType.CUSTOMER
          );
          if (res && res?.conversationID) {
            conversationId = res?.conversationID;
          }
        }
      } catch {
        ErrorType = 1;
      } finally {
        try {
          if (attachments?.length && conversationId) {
            const formData = new FormData();
            attachments.forEach((attachment: File) => {
              formData.append(attachment.name, attachment, attachment.name);
            });
            await uploadAttachments(conversationId, formData, {
              'content-type': 'multipart/form-data',
            });
          }
        } catch {
          !ErrorType ? (ErrorType = 2) : (ErrorType = 3);
        } finally {
          conversationId && updateConversationId(conversationData.operationUniqueId, conversationId);
          switch (ErrorType) {
            case 0:
              resetForm();
              break;
            case 1:
              message && (await setFieldValue('message', message));
              attachments?.length && !conversationId && (await setFieldValue('attachments', attachments));
              attachments?.length && conversationId && (await setFieldValue('attachments', []));
              await setFieldError('message', messageErrorText);
              await setFieldTouched('message', true);
              break;
            case 2:
              message && (await setFieldValue('message', ''));
              attachments?.length && (await setFieldValue('attachments', attachments));
              await setFieldError('attachments', 'Some error occurs while sending attachments');
              await setFieldTouched('attachments', true);
              break;
            case 3:
              await setFieldError('attachments', 'Some error occurs while sending message and attachments');
              await setFieldTouched('attachments', true);
              break;
            default:
              break;
          }
        }
      }
    }
  };

  const validationSchema = Yup.object({
    subjectName: Yup.string().when('subjectId', {
      is: (subjectId: string) => subjectId === 'other',
      then: Yup.string()
        .required('Subject is required.')
        .max(50, 'Subject cannot be longer than 50 characters.')
        .test('not-contain-special-character', 'Special character not allowed', value =>
          value ? !/[^ -~]/.test(value) : false
        ),
    }),
    isSubjectSelected: Yup.boolean().when('subjectId', {
      is: (subjectId: string) => subjectId !== 'other',
      then: Yup.boolean().oneOf([true], 'Please select conversation subject'),
    }),
    message: Yup.string().when(['isSubjectSelected', 'attachments'], {
      is: (isSubjectSelected: boolean, attachments: File[]) => isSubjectSelected && !attachments?.length,
      then: Yup.string()
        .required('Message is required.')
        .max(250, 'Message cannot be longer than 250 characters.')
        .test('not-contain-special-character', 'Special character not allowed', value =>
          value ? !/[^ -~]/.test(value) : false
        ),
    }),
  });

  return (
    <>
      <Formik
        innerRef={formRef}
        key={`formik-${conversationData.operationUniqueId}`}
        initialValues={{...formInitialValues, isSubjectSelected: conversationData.conversationId ? true : false}}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        validateOnBlur={false}
        enableReinitialize
      >
        {props => (
          <FormikForm
            key={`formik-form-${conversationData.operationUniqueId}`}
            className={`${classes.chatboxForm} ${
              chatBoxNumber === 1 ? classes.chatbox1 : chatBoxNumber === 2 ? classes.chatbox2 : ''
            }`}
            autoComplete="off"
            onSubmit={e => {
              if (!props.isSubmitting) {
                props.setSubmitting(true);
                props.handleSubmit();
              }
              e.preventDefault();
            }}
            onKeyDown={e => {
              if (e.keyCode === 13 && !props.isSubmitting) props.handleSubmit();
            }}
          >
            <Box className={` bg-white rounded-tl-[12px] rounded-tr-[12px] flex flex-col`}>
              <Box>
                <ChatBoxHeader
                  scope={scope}
                  conversationData={conversationData}
                  subjectId={props.values.subjectId}
                  handleCloseChatBox={handleCloseChatBox}
                  handleMinOrMaxChatBox={handleMinOrMaxChatBox}
                  handleResolveConversation={handleResolveConversation}
                  handleMarkAsUnread={handleMarkAsUnread}
                  newLineMsgTimeOutId={newLineMsgTimeOutId}
                  refreshMsgIntervalId={refreshMsgIntervalId}
                />
                {conversationData.isChatboxOpen && (
                  <>
                    <ChatBoxBody
                      {...props}
                      conversationData={conversationData}
                      messageData={messageData}
                      isShowNewMsgLine={isShowNewMsgLine}
                      subjectList={subjectList}
                      tenantId={tenantId}
                    />
                    <ChatBoxFooter
                      {...props}
                      conversationData={conversationData}
                      messageData={messageData}
                      isLoading={isLoading}
                      loadMessages={getConversationMessages}
                    />
                  </>
                )}
              </Box>
            </Box>
          </FormikForm>
        )}
      </Formik>
    </>
  );
};
