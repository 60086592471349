import React, {useState, useEffect} from 'react';

import {STable} from '../../components/tables/STable';
import {Button, Input, Typography} from 'spenda-ui-react';

import {CreateNewCustomerAccountForm} from '../form/CreateNewCustomerAccountForm';
import {ICustomer} from '../../model/customer/Customer';
import {IPagedActionResults} from '../../model/ActionResults';
import {useLoadCustomers} from '../../services/useCustomersAPI';

import {Grid} from '@material-ui/core';
import ArrowForwardTwoTone from '@material-ui/icons/ArrowForwardTwoTone';

export const LinkToAccountCustomer = (props: any) => {
  const {response, linkClick} = props;
  const [search, setSearch] = useState<string>();

  const [customerData, setCustomerData] = useState<ICustomer[]>();
  const [classIdCheck, setClassIdCheck] = useState<boolean>(false);
  const [popUpID, setPopUpID] = useState<number | null>();
  const {loading} = useLoadCustomers(true);

  useEffect(() => {
    if (search && search?.length > 2) {
      const filter = {
        search,
        MaxResults: 5,
        IsShowAccountCustomersOnly: true,
      };
      props.getCustomerList(filter).then((res: IPagedActionResults<ICustomer[]>) => {
        setCustomerData(res.Value);
      });
    }
  }, [search]);

  useEffect(() => {
    if (response?.IsSuccess && props.customerLink) {
      props.fetchCustomerInfo(props.customerId);
      props.onClose();
    } else if (response?.IsSuccess && props.customerLinked) {
      props.getCustomersList();
      props.onClose();
    }
  }, [response]);

  return (
    <>
      {classIdCheck === false && (
        <div>
          <Typography variant="h3" className="mb-5 mt-3 font-medium text-[#4D4D4D]">
            Search for an existing Account Customer to link to
          </Typography>
          <Grid container spacing={1}>
            <Grid item sm={2} container alignItems="flex-end" className="flex">
              <Typography variant="small" className="pb-2 font-medium text-[#333333]">
                Account Customer:
              </Typography>
            </Grid>
            <Grid item sm={5}>
              <Input
                placeholder="Search"
                variant="standard"
                value={search}
                onChange={e => {
                  setSearch(e.target.value || ' ');
                }}
                containerProps={{className: 'w-full'}}
              />
            </Grid>

            <Grid item sm={1}>
              <ArrowForwardTwoTone
                data-autoid={`arrowForward${props.customerId}`}
                className="mt-3 cursor-pointer"
                onClick={() => {
                  setSearch(search || ' ');
                  const filter = {
                    search,
                    MaxResults: 5,
                    IsShowAccountCustomersOnly: true,
                  };
                  props.getCustomerList(filter).then((res: IPagedActionResults<ICustomer[]>) => {
                    setCustomerData(res.Value);
                  });
                }}
              />
            </Grid>
          </Grid>
          <Typography variant="small" className="mb-20 mt-3 font-normal text-gray-400">
            *This will search for all the Account Customers in your Financial System.
          </Typography>
          {search && (
            <>
              <Typography variant="h3" className="mb-5 font-medium text-[#4D4D4D]">
                Search Result
              </Typography>
              <STable
                columns={[
                  {
                    title: 'Acc Customer Ref',
                    field: 'InventoryCode',
                    render: (rowData: ICustomer) => {
                      return (
                        <Typography variant="paragraph" className="font-medium leading-[1.357] text-[#999999]">
                          {rowData?.RefNumber}
                        </Typography>
                      );
                    },
                  },
                  {
                    title: 'Name',
                    field: 'Name',
                    render: (rowData: ICustomer) => {
                      return (
                        <Typography variant="paragraph" className="font-medium leading-[1.357] text-[#999999]">
                          {rowData?.CompanyName}
                        </Typography>
                      );
                    },
                  },
                  {
                    title: 'Phone Number',
                    field: 'category',
                    render: (rowData: ICustomer) => {
                      return (
                        <Typography variant="paragraph" className="font-medium leading-[1.357] text-[#999999]">
                          {rowData?.Phone1}
                        </Typography>
                      );
                    },
                  },
                  {
                    title: 'Links',
                    field: 'category',
                    render: (rowData: ICustomer) => {
                      return (
                        <Typography variant="paragraph" className="font-medium leading-[1.357] text-[#999999]">
                          {rowData?.CountOfLinkedCustomers}
                        </Typography>
                      );
                    },
                  },
                  {
                    title: 'Billing Address',
                    field: 'brand',
                    render: (rowData: ICustomer) => {
                      return (
                        <Typography variant="paragraph" className="font-medium leading-[1.357] text-[#999999]">
                          {rowData?.BillingAddressString}
                        </Typography>
                      );
                    },
                  },
                  {
                    title: 'Action',
                    field: 'IsActive',
                    align: 'center',
                    render: (rowData: ICustomer) => {
                      return (
                        <>
                          <Button
                            data-autoid={`btnView${props.customerId}-${rowData.ID}`}
                            type="button"
                            onClick={() => {
                              if (rowData.ClassID) {
                                linkClick(props?.customerId, rowData?.ID);
                              } else {
                                setPopUpID(rowData?.ID);
                                setClassIdCheck(true);
                              }
                            }}
                          >
                            Link
                          </Button>
                        </>
                      );
                    },
                  },
                ]}
                data={customerData ? customerData : []}
                emptyDataMessage={'No Data'}
                stickyHeader={true}
                selection={false}
                loading={loading}
              ></STable>
            </>
          )}
        </div>
      )}
      <div>
        {classIdCheck === true && (
          <CreateNewCustomerAccountForm {...props} customerData={customerData} popUpID={popUpID} />
        )}
      </div>
    </>
  );
};
