import React from 'react';

export const IconAddMedia = (props: {width?: string; height?: string}) => {
  return (
    <svg width={50} height={50} viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25 0C11.193 0 0 11.193 0 25s11.193 25 25 25 25-11.193 25-25C49.985 11.2 38.8.015 25 0zm0 45.833C13.494 45.833 4.167 36.506 4.167 25 4.167 13.494 13.494 4.167 25 4.167c11.506 0 20.833 9.327 20.833 20.833C45.821 36.5 36.501 45.82 25 45.833zM35.417 25c0 1.15-.933 2.083-2.084 2.083h-6.25v6.25a2.083 2.083 0 11-4.166 0v-6.25h-6.25a2.083 2.083 0 110-4.166h6.25v-6.25a2.083 2.083 0 114.166 0v6.25h6.25c1.15 0 2.084.932 2.084 2.083z"
        className="fill-primary"
      />
    </svg>
  );
};
