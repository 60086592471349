import useHttp from '../hooks/useHttp';
import {IActionResults} from '../model/ActionResults';
import {IAdaptor} from '../model/synkd/Adaptor';
import {IAdaptorSummary} from '../model/synkd/AdaptorSummary';

export const useAdaptorAPI = () => {
  const {GET, POST, isLoading} = useHttp();

  const getAdaptorActionsSummary = async (): Promise<IAdaptorSummary[]> => {
    return GET(`adaptoraction/purchaseinvoiceimportsummary`).then(
      (res: IActionResults<IAdaptorSummary[]>) => res.Value
    );
  };

  const getAdaptorLogs = async (adaptorLogId: string): Promise<IAdaptorSummary> => {
    return GET(`adaptoraction/log/${adaptorLogId}`).then((res: IActionResults<IAdaptorSummary>) => res.Value);
  };

  const searchAdaptor = (payload: {websiteId: number}): Promise<IAdaptor[]> => {
    return POST('adaptor/search', payload).then(data => (data ? data.Items : undefined));
  };

  return {
    getAdaptorActionsSummary,
    getAdaptorLogs,
    searchAdaptor,
    isLoading,
  };
};
