import {IActionResults} from '../model/ActionResults';
import {IPickSlip} from '../model/sales-order/PickSlip';
import useHttp from '../hooks/useHttp';

export const usePickSlipAPI = () => {
  const {GET, POST, isLoading} = useHttp();

  const getPickSlipById = (ID: any): Promise<IPickSlip> => {
    return GET(`pickslip/${ID}`).then((data: IActionResults<IPickSlip>) => data.Value);
  };

  const upatePickSlipLines = (ID: any, payload: Partial<IPickSlip>): Promise<IActionResults<IPickSlip>> => {
    return POST(`pickslip/${ID}/lines`, {Value: payload}).then((data: IActionResults<IPickSlip>) => data);
  };

  const upatePickSlipStatus = (ID: any, payload: Partial<IPickSlip>): Promise<IActionResults<IPickSlip>> => {
    return POST(`pickslip/${ID}/status`, {Value: payload}).then((data: IActionResults<IPickSlip>) => data);
  };

  return {
    getPickSlipById,
    upatePickSlipLines,
    upatePickSlipStatus,
    isLoading,
  };
};
