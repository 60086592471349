import * as React from 'react';
import {SVGProps} from 'react';

const IconBinTrash = (props: SVGProps<SVGSVGElement>) => (
  <svg width={13} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.252 1.333h3.083v1.334H0V1.333h3.084L4.116 0H8.22l1.033 1.333ZM2.878 16c-.904 0-1.644-.798-1.644-1.774V4h9.868v10.226c0 .976-.74 1.774-1.645 1.774H2.878Z"
      fill="#CCC"
    />
  </svg>
);

export default IconBinTrash;
