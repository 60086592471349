import React from 'react';

export const DualAuth = (props: {width?: string; height?: string; className?: string; onClick?: any}) => {
  return (
    <svg width="12" height="11" className={props.className}>
      <path
        fill="#C55D44"
        fillRule="nonzero"
        d="M6.496 6a1.5 1.5 0 0 1 1.5 1.5l-.001.748c.103 1.837-1.312 2.753-3.935 2.753C1.446 11 0 10.097 0 8.275V7.5A1.5 1.5 0 0 1 1.5 6h4.996Zm4 0a1.5 1.5 0 0 1 1.5 1.5v.527c.09 1.647-1.16 2.473-3.444 2.473-.31 0-.602-.015-.874-.045.29-.268.507-.587.645-.958l.229.003c1.784 0 2.497-.471 2.444-1.446V7.5a.5.5 0 0 0-.5-.5H8.433a2 2 0 0 0-.615-1h2.678Zm-4 1H1.5a.5.5 0 0 0-.5.5v.775C1 9.42 1.928 10 4.06 10c2.124 0 3-.567 2.936-1.725V7.5a.5.5 0 0 0-.5-.5ZM4 0a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5Zm5 1a2 2 0 1 1 0 4 2 2 0 0 1 0-4ZM4 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Zm5 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z"
      />
    </svg>
  );
};
