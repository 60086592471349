import React from 'react';

export const AuthorisePayment = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20px" height="30px" viewBox="0 0 100 100" {...props}>
      <g className="fill-primary">
        <path
          fillRule="evenodd"
          d="M50 32c2.12 0 3.843 1.661 3.843 3.715v.995l.096.031c4.645 1.563 7.895 5.714 8.055 10.52l.006.371c0 2.054-1.722 3.715-3.843 3.715-2.039 0-3.71-1.536-3.836-3.48l-.007-.235c0-2.287-1.935-4.152-4.314-4.152s-4.313 1.865-4.314 4.152c0 2.205 1.8 4.019 4.06 4.145l.254.007c6.616 0 12 5.194 12 11.584 0 4.962-3.297 9.287-8.061 10.89l-.096.031v.996c0 1.975-1.592 3.587-3.6 3.708L50 79c-2.12 0-3.843-1.661-3.843-3.715v-.996l-.096-.03c-4.645-1.563-7.895-5.714-8.055-10.52L38 63.368c0-2.054 1.722-3.715 3.843-3.715 1.863 0 3.45 1.29 3.792 3.122l.051.593c0 2.287 1.935 4.152 4.314 4.152 2.38 0 4.314-1.866 4.314-4.152 0-2.206-1.799-4.02-4.06-4.147L50 59.214c-6.615 0-12-5.193-12-11.582 0-4.962 3.297-9.287 8.061-10.89l.096-.032v-.995c0-1.975 1.592-3.587 3.6-3.708L50 32Zm30.896-18.991-6.9-6.909A20.7 20.7 0 0 0 59.263 0h-26.43C21.333.014 12.013 9.333 12 20.834v58.333c.014 11.5 9.333 20.82 20.833 20.833h33.334c11.5-.014 20.82-9.333 20.833-20.833v-51.43a20.7 20.7 0 0 0-6.104-14.728ZM75.004 18.9c.591.589 1.12 1.237 1.58 1.934H66.166V10.417c.695.464 1.344.994 1.937 1.583l6.9 6.9Zm3.663 60.267c0 6.903-5.597 12.5-12.5 12.5H32.833c-6.903 0-12.5-5.597-12.5-12.5V20.834c0-6.904 5.597-12.5 12.5-12.5h25v12.5a8.333 8.333 0 0 0 8.334 8.333h12.5v50Z"
        />
      </g>
    </svg>
  );
};
