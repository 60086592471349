import React, {useState, useEffect, FunctionComponent, ChangeEvent, useContext, useCallback} from 'react';
import {bindTrigger, bindPopover, usePopupState} from 'material-ui-popup-state/hooks';
import {useLocation, matchPath} from 'react-router-dom';
import {Box, InputAdornment, makeStyles} from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import {Popover} from '@material-ui/core';
import {FormikProps} from 'formik';
import {uniqueId, debounce, omit} from 'lodash';
import {Input} from 'spenda-ui-react';
import {css} from 'glamor';

import AppContext from '../../context/app/appContext';
import {
  AUTH_SELLING_SALES_ORDER_CREATE,
  AUTH_SELLING_SALES_ORDER_VIEW,
  AUTH_SELLING_SALES_ORDER_PICK,
} from '../../routes//SalesOrderRoutes';
import {SALES_ORDER_STATUS, DISCOUNT_MODE, DeliveryMethods, PICK_SLIP_STATUS} from '../../model/constants/Constants';
import {Inventory} from '../../model/inventory/Inventory';
import {ICustomer} from '../../model/customer/Customer';
import {InventoryAutocomplete} from '../inventory/InventoryAutocomplete';
import {CreateSalesOrderValues} from '../form/SalesOrderForm';
import {SalesOrderBottomBarSlide} from './SalesOrderBottomBar';
import {STableCustom} from '../tables/STableCustom';
import {STextField} from '../inputs/STextField';
import {SButton} from '../buttons/Button';
import {CustomStepper} from '../Stepper/Stepper';
import LoadingIndicator from '../ui/LoadingIndicator';
import {PriceFormat} from '../../utils/formatter';
import PriceCalculator from '../../utils/priceCalculator';
import {useSalesOrderAPI} from '../../services/useSalesOrderAPI';

import IconColumnSetting from '../../assets/svg/IconColumnSetting';
import {SCheckbox} from '../inputs/SCheckbox';

import {IHeaderData} from '../tables/STable';

const useOrderDetailsStyles = makeStyles(() => ({
  bottomInput: {
    borderRadius: '2px',
    border: '1px solid #DDDDDD',
    backgroundColor: '#FFFFFF',
    maxWidth: '100px',
    '& span': {
      lineHeight: '30px',
    },
  },
  bottomBar: {
    left: '8px',
    width: 'calc(100% - 16px)',
    borderRadius: '0px 0px 8px 8px',
  },
  footerBar: {
    borderRadius: '0px',
    width: '100%',
  },
  editIcon: {
    fontSize: '17px!important',
  },
  editOrderBtn: {
    padding: '0px',
  },
  pickingTable: {
    marginTop: '20px',
    '& .MuiTableContainer-root': {
      height: '210px',
      overflowY: 'auto',
      marginBottom: '10px',
    },
  },
  commonTable: {
    marginTop: '20px',
    '& .MuiTableContainer-root': {
      height: '210px',
      overflowY: 'auto',
      marginBottom: '10px',
    },
  },
}));

export const SalesOrderLineItems: FunctionComponent<
  FormikProps<CreateSalesOrderValues> & {
    lineItemsError?: boolean;
    customerDetails?: ICustomer | undefined;
    handleDoneClick?: (() => void) | undefined;
    setEditMode: any;
    editMode: boolean;
    handleSetAddress?: (addressID: string, addressString: string, addressType: string) => void;
  }
> = props => {
  const classes = useOrderDetailsStyles();
  const {tenantInfo} = useContext(AppContext);
  const location = useLocation();

  const {values, customerDetails, handleChange, setFieldValue, setValues, handleDoneClick, editMode, setEditMode} =
    props;

  const isCreation = !!matchPath(location.pathname, {path: AUTH_SELLING_SALES_ORDER_CREATE});
  const isView = !!matchPath(location.pathname, {path: AUTH_SELLING_SALES_ORDER_VIEW});
  const isPicking = !!matchPath(location.pathname, {path: AUTH_SELLING_SALES_ORDER_PICK});

  const {addSalesOrderLineItem, isLoading} = useSalesOrderAPI();
  const filterItem = css({color: '#4D4D4D', whiteSpace: 'nowrap'});
  const columnSettingPopupState = usePopupState({variant: 'popover', popupId: 'columnSettingPopupState'});

  const [currentEdit, setCurrentEdit] = useState<Partial<any> | null>();
  const [searchText, setSearchText] = useState<string>('');
  const [selectedColumns, setSelectedColumns] = useState<any>([]);
  const [hideDelete, setHideDelete] = useState<boolean>(false);

  const debounceCalculateLineTotal = useCallback(
    debounce((Lines: any, keyUpdated?: string, discount?: number, discountMode?: string, freightTotalEx?: number) => {
      calculateLineTotal(Lines, keyUpdated, discount, discountMode, freightTotalEx);
    }, 500),
    [],
  );

  useEffect(() => {
    if (!values.Status || values.Status === SALES_ORDER_STATUS.OPEN) {
      debounceCalculateLineTotal(values.Lines, '', Number(values.Discount), values.DiscountMode, values.FreightTotalEx);
    }
  }, [Number(values.Discount)]);

  useEffect(() => {
    if (!values.Status || values.Status === SALES_ORDER_STATUS.OPEN) {
      debounceCalculateLineTotal(values.Lines, '', Number(values.Discount), values.DiscountMode, values.FreightTotalEx);
    }
  }, [values.DiscountMode]);

  useEffect(() => {
    if (!values.Status || values.Status === SALES_ORDER_STATUS.OPEN) {
      debounceCalculateLineTotal(values.Lines, '', Number(values.Discount), values.DiscountMode, values.FreightTotalEx);
    }
  }, [values.FreightTotalEx]);

  useEffect(() => {
    if (
      !values.Lines ||
      (Array.isArray(values.Lines) &&
        values.Lines?.filter(
          _line =>
            _line.InventoryID !== tenantInfo?.TenantUserDetails?.DefaultDiscountLineItemInventoryId &&
            _line.InventoryID !== tenantInfo?.TenantUserDetails?.DefaultShippingLineItemInventoryId,
        ).length === 0)
    ) {
      addLineInventory();
    }
  }, [values.Lines]);

  useEffect(() => {
    if (editMode && !currentEdit && values.Lines && values.Lines.length) {
      const linesSize = values.Lines.length;
      if (
        values.Lines[linesSize - 1]?.InventoryID !==
          tenantInfo?.TenantUserDetails?.DefaultDiscountLineItemInventoryId &&
        values.Lines[linesSize - 1]?.InventoryID !== tenantInfo?.TenantUserDetails?.DefaultShippingLineItemInventoryId
      )
        setCurrentEdit(values.Lines[linesSize - 1]);
    }
  }, [editMode]);

  const handleSearchText = (searchTextInput: string) => {
    setSearchText(searchTextInput.toLocaleLowerCase());
  };

  const handleDelete = (id: number) => {
    const data = values.Lines;
    const index = data.findIndex(t => t.InventoryID === id);
    data.splice(index, 1);
    setFieldValue('Lines', data);
    debounceCalculateLineTotal(data, '', Number(values.Discount), values.DiscountMode, values.FreightTotalEx);
    if (data.length === 0) {
      setHideDelete(true);
      addLineInventory();
    } else {
      setHideDelete(false);
    }
  };

  const calculateLineTotal = async (
    _Lines: any,
    keyUpdated?: string,
    discount: number = 0,
    discountMode: string = DISCOUNT_MODE.PERCENT,
    freightTotalEx: number = 0,
  ) => {
    if (!Array.isArray(_Lines)) {
      return;
    }
    const Lines = _Lines
      .filter(inventory => Boolean(inventory.Code))
      .map(
        inventory =>
          ({
            DeliveryMethod: DeliveryMethods.Deliver,
            InventoryID: inventory.InventoryID,
            Quantity: inventory.Quantity,
            ID: inventory.ID || 0,
            ...(inventory.HasPriceBeenEdited ? {SellPriceEx: inventory.SellPriceEx, HasPriceBeenEdited: true} : {}),
          }) as any,
      );
    const freightLineItemIndex = Lines.findIndex(
      _line => _line.InventoryID === tenantInfo?.TenantUserDetails?.DefaultShippingLineItemInventoryId,
    );
    if (freightLineItemIndex >= 0 && freightTotalEx) {
      Lines[freightLineItemIndex].SellPriceEx = freightTotalEx;
      Lines[freightLineItemIndex].HasPriceBeenEdited = true;
    } else if (freightLineItemIndex >= 0 && !freightTotalEx) {
      Lines.splice(freightLineItemIndex, 1);
    } else if (freightLineItemIndex < 0 && freightTotalEx) {
      Lines.push({
        InventoryID: tenantInfo?.TenantUserDetails?.DefaultShippingLineItemInventoryId,
        Quantity: 1,
        SellPriceEx: freightTotalEx,
        HasPriceBeenEdited: true,
      });
    }
    const {Transaction, IsSuccess} = await addSalesOrderLineItem(values.ID || 0, {
      CustomerID: values.CustomerID || tenantInfo?.TenantUserDetails?.DefaultCashSaleCustomerId,
      Lines: Lines,
      Discount:
        discountMode === DISCOUNT_MODE.PERCENT && discount && discount > 1 ? discount / 100 || 0 : discount || 0,
      DiscountMode: discount ? discountMode : 'None',
    });
    if (IsSuccess && Transaction) {
      const newLines = Transaction?.Lines?.map(line1 => ({
        ...(Lines.find(line2 => line2.InventoryID === line1.InventoryID) || {}),
        ...omit(line1, keyUpdated || ''),
      }));
      setValues({
        ...values,
        Discount:
          discountMode === DISCOUNT_MODE.PERCENT && discount <= 1
            ? (Number(discount) || 0) * 100
            : Number(discount) || '',
        DiscountString: Transaction.DiscountString,
        DiscountMode: discountMode,
        FreightTotalEx: freightTotalEx,
        Lines: newLines as any,
        TotalEx: Transaction.TotalEx,
        TotalExString: Transaction.TotalExString,
        TotalInc: Transaction.TotalInc,
        TotalIncString: Transaction.TotalIncString,
        TotalTax: Transaction.TotalTax,
        TotalTaxString: Transaction.TotalTaxString,
      });
    }
  };

  const handleClearClick = (id: string) => {
    if (id) {
      const index = values.Lines.findIndex(li => li.InventoryID === id);
      const Lines = [...values.Lines];
      Lines[index].ShortDescription = '';
      Lines[index].SKU = '';
      Lines[index].Code = '';
      Lines[index].InventoryID = '';
      Lines[index].QuantityPicked = 0;
      Lines[index].LineTotalEx = 0;
      Lines[index].SellPriceEx = 0;
      Lines[index].SellPriceExString = PriceFormat(Lines[index].SellPriceEx);
      Lines[index].LineTotalExString = PriceFormat(Lines[index].LineTotalEx);
      Lines[index].Quantity = 0;
      if (
        Lines[index]?.InventoryID !== tenantInfo?.TenantUserDetails?.DefaultDiscountLineItemInventoryId &&
        Lines[index]?.InventoryID !== tenantInfo?.TenantUserDetails?.DefaultShippingLineItemInventoryId
      )
        setCurrentEdit(Lines[index]);
      setFieldValue('Lines', Lines);
      debounceCalculateLineTotal(Lines, '', Number(values.Discount), values.DiscountMode, values.FreightTotalEx);
    }
  };

  const addLineInventory = () => {
    if (!props?.errors?.Lines) {
      const newLineItem = {
        InventoryID: uniqueId('NewLineItem_'),
        Code: '',
        ShortDescription: '',
        SellPriceEx: 0,
        SellPriceExString: PriceFormat(0),
        Quantity: 0,
        SOH: 0,
        LineTotalEx: 0,
        LineTotalExString: PriceFormat(0),
        CostPriceEx: '',
        Weight_kg: '',
        Volume: '',
        UoM: '',
        Type: '',
      };
      if (Array.isArray(values.Lines)) {
        setFieldValue('Lines', [...values.Lines, newLineItem]);
      } else {
        setFieldValue('Lines', [newLineItem]);
      }
      setEditMode(true);
      if (
        Number(newLineItem?.InventoryID) !== tenantInfo?.TenantUserDetails?.DefaultDiscountLineItemInventoryId &&
        Number(newLineItem?.InventoryID) !== tenantInfo?.TenantUserDetails?.DefaultShippingLineItemInventoryId
      )
        setCurrentEdit(newLineItem as any);
    }
  };

  const saveLineInventory = async (selected: Partial<Inventory> | null) => {
    const inventoryItem = {
      InventoryID: selected?.ID,
      Code: selected?.InventoryCode,
      ShortDescription: selected?.ShortDescription,
      Quantity: 1,
      DeliveryMethod: DeliveryMethods.Deliver,
    };

    if (Array.isArray(values.Lines)) {
      const index = values.Lines.findIndex(li => li.InventoryID === currentEdit?.InventoryID);
      const Lines = [...values.Lines];
      Lines[index] = inventoryItem;
      setFieldValue('Lines', Lines);
      debounceCalculateLineTotal(Lines, '', Number(values.Discount), values.DiscountMode, values.FreightTotalEx);
      setEditMode(true);
      if (
        inventoryItem?.InventoryID !== tenantInfo?.TenantUserDetails?.DefaultDiscountLineItemInventoryId &&
        inventoryItem?.InventoryID !== tenantInfo?.TenantUserDetails?.DefaultShippingLineItemInventoryId
      )
        setCurrentEdit(inventoryItem);
    }
  };

  const handleSellPriceExChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (Array.isArray(values.Lines) && /^\d+(\.\d{0,2})?$|^$/.test(e.target.value)) {
      const index = values.Lines.findIndex(li => li.InventoryID === currentEdit?.InventoryID);
      const Lines = [...values.Lines];
      Lines[index].SellPriceEx = PriceCalculator.roundNumber(parseFloat(e.target.value));
      Lines[index].HasPriceBeenEdited = true;
      setFieldValue('Lines', Lines);
      debounceCalculateLineTotal(
        Lines,
        'SellPriceEx',
        Number(values.Discount),
        values.DiscountMode,
        values.FreightTotalEx,
      );
    }
  };

  const handleQuantityChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (Array.isArray(values.Lines)) {
      const index = values.Lines.findIndex(li => li.InventoryID === currentEdit?.InventoryID);
      const Lines = [...values.Lines];
      const quantity = parseInt(e.target.value) <= 0 ? ('' as any) : parseInt(e.target.value);
      Lines[index].Quantity = quantity;
      setFieldValue('Lines', Lines);
      debounceCalculateLineTotal(
        Lines,
        'Quantity',
        Number(values.Discount),
        values.DiscountMode,
        values.FreightTotalEx,
      );
    }
  };

  const handleQuantityPickedChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, InventoryID: any) => {
    if (Array.isArray(values.Lines)) {
      const index = values.Lines.findIndex(li => li.InventoryID === InventoryID);
      const Lines = [...values.Lines];
      Lines[index].QuantityPicked = parseFloat(e.target.value);
      Lines[index].LineTotalEx = parseFloat(e.target.value) * (Lines[index]?.SellPriceEx || 0);
      Lines[index].LineTotalExString = PriceFormat(Lines[index].LineTotalEx);
      setFieldValue('Lines', Lines);
    }
  };

  const onEnterPress = (ev: React.KeyboardEvent, field: string, rowData: any, lastField: boolean = false) => {
    if (ev.key === 'Enter') {
      ev.preventDefault();
      // Do code here
      const {Lines} = values;
      const nextIndex = Lines.findIndex((item: any) => item.InventoryID === rowData.InventoryID) + 1;

      if (nextIndex >= Lines.length && !isPicking) {
        // If pressed enter on last line
        addLineInventory();
        return;
      }

      const nextItem = Lines[nextIndex];
      if (
        !isPicking &&
        nextItem?.InventoryID !== tenantInfo?.TenantUserDetails?.DefaultDiscountLineItemInventoryId &&
        nextItem?.InventoryID !== tenantInfo?.TenantUserDetails?.DefaultShippingLineItemInventoryId
      ) {
        setCurrentEdit(nextItem);
      }
      setTimeout(() => {
        const ele = document.getElementById(`${field}-${nextItem?.InventoryID}`);
        if (ele) {
          ele.focus();
        }
      }, 0);
    } else if (ev.key === 'Tab') {
      if (lastField) {
        ev.preventDefault();
        const {Lines} = values;
        const nextIndex = Lines.findIndex((item: any) => item.InventoryID === rowData.InventoryID) + 1;
        if (nextIndex >= Lines.length) {
          // If pressed enter on last line
          addLineInventory();
          return;
        }
      }
    }
  };

  const getActiveStep = () => {
    if (isCreation) {
      return 0;
    } else if (isPicking && values.Status === PICK_SLIP_STATUS.COMPLETE) {
      return 2;
    } else if (isPicking) {
      return 1;
    } else if (values.Status === SALES_ORDER_STATUS.CLOSED) {
      return 7;
    } else {
      return 0;
    }
  };

  const clearEmptyRows = () => {
    let data = [...values.Lines];
    data = data.filter(item => item.Code);
    setFieldValue('Lines', data);
  };

  const getColumns = (): IHeaderData[] => {
    const columns = [
      {
        title: 'Code',
        field: 'Code',
        render: (rowData: any) => {
          return !rowData.ShortDescription ? (
            <span className="text-base text-spenda-labeltext">Code</span>
          ) : (
            <span className="text-base text-spenda-primarytext">{rowData.Code}</span>
          );
        },
      },
      {
        title: 'Items',
        field: 'ShortDescription',
        render: (rowData: any) => {
          if (rowData.InventoryID === currentEdit?.InventoryID) {
            return (
              <InventoryAutocomplete
                autoFocus
                alreadyFilled={values.Lines.map(a => a.InventoryID)}
                size="small"
                clearClick={() => handleClearClick(rowData.InventoryID)}
                handleKeyPress={ev => onEnterPress(ev, 'shortDescription', rowData)}
                additionalFilter={{
                  ViewMode: 'Sales',
                  IsSystem: false,
                }}
                selected={
                  rowData?.InventoryID && rowData?.ShortDescription
                    ? {
                        InventoryCode: rowData?.InventoryID,
                        ShortDescription: rowData?.ShortDescription,
                      }
                    : null
                }
                onSuggestionSelected={(e, selected) => saveLineInventory(selected.suggestion)}
              />
            );
          }
          return (
            <>
              <b>{rowData.Code}</b> {rowData.ShortDescription}
            </>
          );
        },
      },
      {
        title: 'SKU',
        field: 'LineTotalExString',
        align: 'right',
        render: (rowData: any) => {
          return !rowData?.SKU?.toString() ? (
            <span className="text-base text-spenda-labeltext"></span>
          ) : (
            <span className="text-base text-spenda-primarytext">{rowData.SKU}</span>
          );
        },
      },
      {
        title: 'Cost Price',
        field: 'LineTotalExString',
        align: 'right',
        render: (rowData: any) => {
          return <span className="text-base text-spenda-primarytext">{rowData?.CostPriceEx}</span>;
        },
      },
      {
        title: 'UoM',
        field: 'LineTotalExString',
        align: 'right',
        render: (rowData: any) => {
          return !rowData?.UoM?.toString() ? (
            <span className="text-base text-spenda-labeltext"> </span>
          ) : (
            <span className="text-base text-spenda-primarytext">{rowData.UoM}</span>
          );
        },
      },
      {
        title: 'Volume',
        field: 'LineTotalExString',
        align: 'right',
        render: (rowData: any) => {
          return !rowData?.Volume?.toString() ? (
            <span className="text-base text-spenda-labeltext"></span>
          ) : (
            <span className="text-base text-spenda-primarytext">{rowData.Volume}</span>
          );
        },
      },
      {
        title: 'Weight',
        field: 'LineTotalExString',
        align: 'right',
        render: (rowData: any) => {
          return !rowData?.Weight_kg?.toString() ? (
            <span className="text-base text-spenda-labeltext"></span>
          ) : (
            <span className="text-base text-spenda-primarytext">{rowData.Weight_kg}</span>
          );
        },
      },
      {
        title: 'Type',
        field: 'LineTotalExString',
        align: 'right',
        render: (rowData: any) => {
          return !rowData?.Type?.toString() ? (
            <span className="text-base text-spenda-labeltext"></span>
          ) : (
            <span className="text-base text-spenda-primarytext">{rowData.Type}</span>
          );
        },
      },
      {
        title: 'Sell Price Inc',
        field: 'Sell Price Inc',
        align: 'right',
        render: (rowData: any) => {
          return !rowData?.StandardSellPriceInc?.toString() ? (
            <span className="text-base text-spenda-labeltext"></span>
          ) : (
            <span className="text-base text-spenda-primarytext">{rowData.StandardSellPriceInc}</span>
          );
        },
      },
      {
        title: 'Order Qty',
        field: 'Quantity',
        align: 'right',
        render: (rowData: any) => {
          if (rowData.InventoryID === currentEdit?.InventoryID) {
            return (
              <STextField
                {...css({maxWidth: '100px'})}
                id={`OrderQty-${rowData.InventoryID}`}
                disabled={isLoading}
                type="number"
                placeholder="0"
                size="small"
                onKeyDown={ev => onEnterPress(ev, 'OrderQty', rowData)}
                value={rowData.Quantity}
                onChange={handleQuantityChange}
                InputProps={{
                  // startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  inputProps: {min: 1, step: 1},
                }}
              />
            );
          }
          return rowData.Quantity || rowData.QuantityRequired;
        },
      },
      ...(isCreation || isView
        ? [
            {
              title: 'Price',
              field: 'SellPriceExString',
              align: 'right',
              render: (rowData: any) => {
                if (rowData.InventoryID === currentEdit?.InventoryID) {
                  return (
                    <STextField
                      {...css({maxWidth: '100px'})}
                      id={`SellPriceEx-${rowData.InventoryID}`}
                      disabled={isLoading}
                      type="number"
                      placeholder="0"
                      size="small"
                      onKeyDown={ev => onEnterPress(ev, 'SellPriceEx', rowData)}
                      value={PriceCalculator.roundNumber(rowData.SellPriceEx || 0)}
                      onChange={e => {
                        if (Number(e.target.value) >= 0) handleSellPriceExChange(e);
                      }}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                    />
                  );
                }
                return rowData.SellPriceExString;
              },
            },
          ]
        : []),
      ...(isPicking
        ? [
            {
              title: 'Backorder Qty',
              field: 'BackOrderQuantity',
              align: 'right' as 'right',
              render: (rowData: any) => {
                return PriceCalculator.roundNumber(
                  rowData.Quantity || rowData.QuantityRequired - rowData?.QuantityPicked || 0,
                );
              },
            },
            {
              title: 'Pick Qty',
              field: 'QuantityPicked',
              align: 'right' as 'right',
              render: (rowData: any) => {
                if (
                  rowData.Status === 'Picked' ||
                  rowData.Status === 'Complete' ||
                  rowData.DeliveryMethod === DeliveryMethods.Cash_and_Carry
                ) {
                  return rowData?.QuantityPicked;
                }
                return (
                  <STextField
                    {...css({maxWidth: '100px'})}
                    id={`QuantityPicked-${rowData.InventoryID}`}
                    type="number"
                    placeholder="0"
                    size="small"
                    onKeyDown={ev => onEnterPress(ev, 'QuantityPicked', rowData)}
                    InputProps={{
                      inputProps: {
                        step: 1,
                        min: 0,
                        max: rowData.QuantityRequired,
                        'data-autoid': `txtQuantityPicked${rowData.InventoryID}`,
                      },
                    }}
                    value={rowData?.QuantityPicked}
                    onChange={e => {
                      if (
                        e.target.value.length === 0 ||
                        (Number(e.target.value) <= rowData.QuantityRequired && Number(e.target.value) >= 0)
                      ) {
                        handleQuantityPickedChange(e, rowData?.InventoryID);
                      }
                    }}
                  />
                );
              },
            },
          ]
        : []),
      ...(isCreation || isView
        ? [
            {
              title: 'Line Total',
              field: 'LineTotalExString',
              align: 'right',
              render: (rowData: any) => {
                return !rowData?.LineTotalExString?.toString() ? (
                  <span className="text-base text-spenda-labeltext">$0.00</span>
                ) : (
                  <span className="text-base text-spenda-primarytext">{rowData.LineTotalExString}</span>
                );
              },
            },
          ]
        : []),
    ] as IHeaderData[];
    const selectedFixColumns = [
      ...selectedColumns,
      'Code',
      'Items',
      'Price',
      'Order Qty',
      'Pick Qty',
      'Backorder Qty',
      'Line Total',
    ];

    return columns.filter(column => selectedFixColumns.includes(column.title));
  };

  const columnArray = ['SKU', 'Cost Price', 'Weight', 'Volume', 'UoM', 'Sell Price Inc', 'Type'];
  const handleColumnCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentSelected = [...selectedColumns];
    if (event.target.checked) {
      currentSelected.push(event.target.name);
    } else {
      const index = currentSelected.indexOf(event.target.name);
      if (index !== -1) {
        currentSelected.splice(index, 1);
      }
    }
    setSelectedColumns(currentSelected);
  };

  const maxDiscount = values?.Lines?.reduce((acc, _line) => {
    if (_line.InventoryID !== tenantInfo?.TenantUserDetails?.DefaultDiscountLineItemInventoryId) {
      return acc + (_line?.LineTotalInc || 0);
    }
    return acc;
  }, 0);

  return (
    <Box height="100%" display="flex" flexDirection="column" position="relative">
      <LoadingIndicator isLoading={isLoading} size="sm" color="#1C78AD" />
      <Box className={`${editMode && values?.Status !== SALES_ORDER_STATUS.APPROVED ? 'pr-12' : ''}`}>
        <Box display="flex" justifyContent="flex-end">
          {values?.Status && values?.Status !== SALES_ORDER_STATUS.OPEN ? (
            <Box className="" margin="13px -20px -15px 0" position="absolute">
              <CustomStepper
                steps={[
                  'Ready To Pick',
                  'In Picking',
                  'Picked',
                  'Ready to Pack',
                  'In Packing',
                  'Packed',
                  'Awaiting Collection',
                  'Completed',
                ]}
                activeStep={getActiveStep()}
              />
            </Box>
          ) : null}
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          marginTop="20px"
          className={`${values?.Status === SALES_ORDER_STATUS.APPROVED ? 'pr-32' : ''}`}
        >
          <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="7px">
            <span className="text-xl font-medium text-spenda-headingtext">
              Order <span className="text-spenda-labeltext">{values.RefNumber ? `(${values.RefNumber})` : ''}</span>
            </span>
            {!values.Status || values?.Status === SALES_ORDER_STATUS.OPEN ? (
              <Box display="flex" alignItems="flex-end">
                {!values.Status ? (
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-evenly"
                    alignItems="flex-end"
                    marginRight="20px"
                  >
                    <div
                      {...filterItem}
                      {...bindTrigger(columnSettingPopupState)}
                      className="ml-4 flex cursor-pointer items-center text-xl font-medium"
                    >
                      <IconColumnSetting />
                      <span className="ml-2 px-1">Column Settings</span>
                    </div>
                    <Popover
                      {...bindPopover(columnSettingPopupState)}
                      anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                      transformOrigin={{vertical: 'top', horizontal: 'right'}}
                    >
                      <div className="flex flex-col p-3 pr-4">
                        <p>Fields</p>
                        <div className="flex flex-col">
                          {Object.entries(columnArray).map(([key, value]: [string, any]) => (
                            <SCheckbox
                              disabled={Boolean(value.disabled)}
                              textColor="#333333"
                              key={`columnArray-${key}`}
                              name={value}
                              label={value}
                              checked={selectedColumns?.includes(value)}
                              onChange={handleColumnCheckbox}
                            />
                          ))}
                        </div>
                      </div>
                    </Popover>
                  </Box>
                ) : null}
                <span className="text-xl font-medium text-spenda-headingtext">
                  Order Total: <span className={'text-success'}>{PriceFormat(values.TotalInc)}</span>
                </span>
              </Box>
            ) : null}
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Box className="flex w-full max-w-[500px] items-end">
              <span className="pr-5 pt-1 text-spenda-primarytext">Search: </span>
              <Input
                variant="static"
                name="search"
                placeholder="Search Items, code"
                className="!pb-0"
                onChange={e => handleSearchText(e.target.value)}
              />
            </Box>
            {!values.Status || values?.Status === SALES_ORDER_STATUS.OPEN ? (
              <Box display="flex" flexDirection="column" justifyContent="space-evenly" alignItems="flex-end">
                {editMode ? (
                  <Box>
                    <SButton
                      margin="0px 4px"
                      color="transparent"
                      boxShadow="none"
                      textColor="blueShade"
                      lineHeight="m"
                      type="button"
                      width="m"
                      fontSize="m"
                      onClick={() => {
                        addLineInventory();
                      }}
                    >
                      Add Line Item
                    </SButton>
                    <SButton
                      margin="0px 0px 0px 10px"
                      color="blueShade"
                      border="1px solid #1C78AD"
                      textColor="white"
                      boxShadow="none"
                      lineHeight="m"
                      type="button"
                      width="m"
                      fontSize="m"
                      onClick={() => {
                        if (!props?.errors?.Lines) {
                          clearEmptyRows();
                          setCurrentEdit(null);
                          setEditMode(false);
                          if (handleDoneClick) {
                            handleDoneClick();
                          }
                        }
                      }}
                    >
                      Done
                    </SButton>
                  </Box>
                ) : (
                  <Box marginTop="5px">
                    <SButton
                      margin="0px 0px"
                      color="transparent"
                      boxShadow="none"
                      textColor="blueShade"
                      lineHeight="m"
                      type="button"
                      width="m"
                      fontSize="m"
                      startIcon={<EditOutlinedIcon width="14px" height="14px" className={`${classes.editIcon}`} />}
                      onClick={() => {
                        setEditMode(true);
                        if (
                          values.Lines &&
                          Array.isArray(values.Lines) &&
                          values.Lines.length &&
                          values.Lines[0]?.InventoryID !==
                            tenantInfo?.TenantUserDetails?.DefaultDiscountLineItemInventoryId &&
                          values.Lines[0]?.InventoryID !==
                            tenantInfo?.TenantUserDetails?.DefaultShippingLineItemInventoryId
                        ) {
                          setCurrentEdit(values.Lines[0]);
                        }
                      }}
                      className={`${classes.editOrderBtn}`}
                    >
                      {'Edit Order Details'}
                    </SButton>
                  </Box>
                )}
              </Box>
            ) : null}
          </Box>
        </Box>
      </Box>

      <Box flex={1}>
        <Box className={isPicking ? `${classes.pickingTable}` : `${classes.commonTable}`}>
          <STableCustom
            hideDeleteButton={isPicking || hideDelete}
            columns={getColumns()}
            data={
              Array.isArray(values.Lines)
                ? values.Lines?.filter(
                    a =>
                      a?.ShortDescription?.toLocaleLowerCase().includes(searchText) ||
                      a?.Code?.toLocaleLowerCase()?.includes(searchText),
                  )
                : []
            }
            emptyDataMessage={'No Order Items'}
            onDelete={editMode ? handleDelete : undefined}
            onRowClick={(rowData: any) => {
              if (
                editMode &&
                rowData?.InventoryID !== tenantInfo?.TenantUserDetails?.DefaultDiscountLineItemInventoryId &&
                rowData?.InventoryID !== tenantInfo?.TenantUserDetails?.DefaultShippingLineItemInventoryId
              )
                setCurrentEdit(rowData);
            }}
            status={values?.Status || ''}
          />
          {!isPicking ? (
            <Box
              bgcolor="#EAEFF2"
              display="flex"
              paddingLeft="80px"
              justifyContent="space-between"
              className={`z-10 -mb-5 px-5 py-3 ${
                !values.Status || values?.Status === SALES_ORDER_STATUS.OPEN
                  ? `fixed bottom-7 ${classes.bottomBar}`
                  : `${classes.footerBar}`
              }`}
            >
              <div className="my-auto">
                <span className="pr-3 text-spenda-primarytext">Sub Total (Ex):</span>
                <span className="font-medium text-spenda-primarytext">{PriceFormat(values.TotalEx)}</span>
              </div>
              <div className="flex items-center">
                <span className="pr-2 text-spenda-primarytext">Shipping Charges:</span>
                {editMode ? (
                  <div className={`${classes.bottomInput} relative`}>
                    <input
                      type="number"
                      id="FreightTotalEx"
                      name="FreightTotalEx"
                      disabled={isLoading}
                      pattern="[0-9]"
                      step={1}
                      value={values?.FreightTotalEx || ''}
                      onChange={e => {
                        if (Number(e.target.value) >= 0 && !/[^0-9]+/.test(e.target.value)) handleChange(e);
                      }}
                      className="mb-0 border-0 p-1 text-base"
                      placeholder="0"
                    />
                    <span
                      {...css({backgroundColor: '#F1F1F1'})}
                      className="py-0.75 absolute right-0 top-0 h-full px-3 font-medium"
                    >
                      $
                    </span>
                  </div>
                ) : (
                  <span className="font-medium text-spenda-primarytext">{PriceFormat(values.FreightTotalEx)}</span>
                )}
              </div>
              <div className="flex items-center">
                <span className="pr-2 text-spenda-primarytext">Total GST (10%): </span>
                <span className="font-medium text-spenda-primarytext">{PriceFormat(values.TotalTax)}</span>
              </div>
              <div className="flex items-center">
                <span className="pr-2 text-spenda-primarytext">Discount:</span>
                {editMode ? (
                  <div className={`${classes.bottomInput} relative`}>
                    <input
                      type="number"
                      id="Discount"
                      name="Discount"
                      disabled={isLoading}
                      value={values?.Discount}
                      step={1}
                      pattern="[0-9]"
                      onChange={e => {
                        if (
                          (values?.DiscountMode?.toLowerCase() === DISCOUNT_MODE.PERCENT?.toLowerCase() &&
                            Number(e.target.value) >= 0 &&
                            Number(e.target.value) <= 100 &&
                            /^\d+(\.\d{0,2})?$|^$/.test(e.target.value)) ||
                          (values?.DiscountMode?.toLowerCase() === DISCOUNT_MODE.DOLLAR?.toLowerCase() &&
                            Number(e.target.value) >= 0 &&
                            Number(e.target.value) <= maxDiscount &&
                            /^\d+(\.\d{0,2})?$|^$/.test(e.target.value))
                        ) {
                          handleChange(e);
                        }
                      }}
                      className="mb-0 border-0 p-1 text-base"
                      placeholder="0"
                    />
                    <div
                      {...css({backgroundColor: '#F1F1F1'})}
                      className="absolute right-0 top-0 h-full px-1 font-medium"
                    >
                      <select
                        id="DiscountMode"
                        name="DiscountMode"
                        {...css({backgroundColor: '#F1F1F1'})}
                        value={values.DiscountMode}
                        className="absolute right-0 top-0 h-full px-1 font-medium"
                        onChange={e => {
                          setValues({
                            ...values,
                            DiscountMode: e.target.value,
                            Discount: '',
                          });
                        }}
                      >
                        <option value={DISCOUNT_MODE.PERCENT} id={DISCOUNT_MODE.PERCENT}>
                          %
                        </option>
                        <option value={DISCOUNT_MODE.DOLLAR} id={DISCOUNT_MODE.DOLLAR}>
                          $
                        </option>
                      </select>
                    </div>
                  </div>
                ) : (
                  <span className="font-medium text-spenda-primarytext">
                    {values.DiscountMode === DISCOUNT_MODE.PERCENT
                      ? `${values.DiscountString || 0}%`
                      : PriceFormat(Number(values.Discount) ?? 0)}
                  </span>
                )}
              </div>
              <div className="my-auto">
                <span className="pr-2 text-spenda-primarytext">Total:</span>
                <span className="font-medium text-spenda-primarytext">{PriceFormat(values.TotalInc)}</span>
              </div>
            </Box>
          ) : null}
        </Box>
        {props?.lineItemsError && values?.Lines?.[0]?.Code === '' ? (
          <div className="w-max text-sm text-error">
            <p>Add at least one product</p>
          </div>
        ) : null}
        {props?.errors?.Lines ? (
          <div className="w-max text-sm text-error">
            <p>Please provide all order details</p>
          </div>
        ) : null}
      </Box>
      {isPicking && <SalesOrderBottomBarSlide customerDetails={customerDetails} {...props} />}
    </Box>
  );
};
