import React from 'react';
import {useParams} from 'react-router-dom';
import {useTenantInfo} from '../../hooks/useTenantInfo';
import {SupplierEnd} from './SupplierEnd';
import {BuyerEnd} from './BuyerEnd';
import {SpendaFinance} from './SpendaFinance';

export const AccountsReceivable = () => {
  const {customerId, transactionId, supplierId} = useParams<{
    customerId?: string;
    transactionId?: string;
    supplierId?: string;
  }>();

  const _supplierId = supplierId ? parseInt(supplierId) : undefined;
  const _customerId = customerId ? parseInt(customerId) : undefined;
  const _transactionId = transactionId ? parseInt(transactionId) : undefined;

  const {isInBuyerContext, isInSupplierContext, isInSpendaFinanceContext} = useTenantInfo();

  return (
    <>
      {isInBuyerContext && _supplierId && <BuyerEnd supplierId={_supplierId} />}
      {isInSpendaFinanceContext && _supplierId && <SpendaFinance />}
      {isInSupplierContext && <SupplierEnd customerId={_customerId} transactionId={_transactionId} />}
    </>
  );
};
