import {isEmpty} from 'lodash';
import {IAddress} from '../model/address/Address';
import {ILocation} from '../model/address/Location';

export const getAddressObject = (address: IAddress, addressType: 'ShippingAddress' | 'BillingAddress') =>
  addressType === 'ShippingAddress'
    ? {
        ShippingAddress: address,
        ShippingAddressID: address.ID,
        ShipCity: address.City || '',
        ShipCountry: address.Country || '',
        ShipPostCode: address.PostCode || '',
        ShipState: address.State || '',
        ShipStreetAddress: address.StreetAddress || '',
      }
    : {
        BillingAddress: address,
        BillingAddressID: address.ID,
        BillCity: address.City || '',
        BillCountry: address.Country || '',
        BillPostCode: address.PostCode || '',
        BillState: address.State || '',
        BillStreetAddress: address.StreetAddress || '',
      };

export const getLocation = async (locations: ILocation[]) => {
  let ShippingAddressID,
    ShippingAddress: IAddress | undefined = undefined,
    BillingAddress: IAddress | undefined = undefined,
    BillingAddressID;
  const defaultLocation = locations?.find(a => a.IsDefaultLocation === true) || {};
  const defaultLocationIndex = locations?.findIndex(a => a.IsDefaultLocation === true);
  // check default Billing
  const defaultBillingIndex =
    defaultLocationIndex !== -1
      ? defaultLocation?.Addresses?.findIndex(
          addr => addr?.IsActive && addr.IsDefaultBilling && addr.PostCode && addr.Country,
        )
      : -1;
  const defaultShippingIndex =
    defaultLocationIndex !== -1
      ? defaultLocation?.Addresses?.findIndex(
          addr => addr?.IsActive && addr.IsDefaultDeliverTo && addr.PostCode && addr.Country,
        )
      : -1;
  if (defaultShippingIndex !== -1) {
    if (
      defaultLocation &&
      defaultLocation.Addresses &&
      defaultLocation?.Addresses[defaultShippingIndex!]?.FullAddress
    ) {
      defaultLocation.Addresses[defaultShippingIndex!].CompleteAddress =
        `${defaultLocation?.Addresses[defaultShippingIndex!]?.StreetAddress}, 
      ${defaultLocation?.Addresses[defaultShippingIndex!]?.City}, ${defaultLocation?.Addresses[defaultShippingIndex!]?.Country}, ${defaultLocation?.Addresses[defaultShippingIndex!]?.State}, ${defaultLocation?.Addresses[defaultShippingIndex!]?.PostCode}`.replace(
          / ,/g,
          '',
        );
    }
    ShippingAddressID =
      (defaultLocation && defaultLocation.Addresses && defaultLocation?.Addresses[defaultShippingIndex!]?.ID) || '';
    ShippingAddress =
      (defaultLocation && defaultLocation.Addresses && defaultLocation?.Addresses[defaultShippingIndex!]) || {};
  }
  if (defaultBillingIndex !== -1) {
    if (defaultLocation && defaultLocation.Addresses && defaultLocation?.Addresses[defaultBillingIndex!]?.FullAddress) {
      defaultLocation.Addresses[defaultBillingIndex!].CompleteAddress =
        `${defaultLocation?.Addresses[defaultBillingIndex!]?.StreetAddress}, ${defaultLocation?.Addresses[defaultBillingIndex!]?.City}, ${defaultLocation?.Addresses[defaultBillingIndex!]?.Country}, ${defaultLocation?.Addresses[defaultBillingIndex!]?.State}, ${defaultLocation?.Addresses[defaultBillingIndex!]?.PostCode}`.replace(
          / ,/g,
          '',
        );
    }

    BillingAddressID =
      (defaultLocation && defaultLocation.Addresses && defaultLocation?.Addresses[defaultBillingIndex!]?.ID) || '';
    BillingAddress =
      (defaultLocation && defaultLocation.Addresses && defaultLocation?.Addresses[defaultBillingIndex!]) || {};
  }
  if (defaultShippingIndex === -1 || defaultBillingIndex === -1) {
    // search from other locations
    const filteredLocations = locations?.filter(
      loc => loc.Addresses && loc.Addresses.length && loc.IsActive && loc.Name && loc.Name.trim() !== '',
    );
    await Promise.all(
      filteredLocations.map(addresses => {
        if (isEmpty(ShippingAddress)) {
          ShippingAddress =
            addresses.Addresses?.find(
              addr => addr.IsActive && addr.IsDefaultDeliverTo && addr.PostCode && addr.Country,
            ) || {};
          if (ShippingAddress?.FullAddress) {
            ShippingAddress.CompleteAddress =
              `${ShippingAddress.StreetAddress}, ${ShippingAddress.City}, ${ShippingAddress.Country}, ${ShippingAddress.State}, ${ShippingAddress.PostCode}`.replace(
                / ,/g,
                '',
              );
          }
          ShippingAddressID = ShippingAddress?.ID || '';
        }
        if (isEmpty(BillingAddress)) {
          BillingAddress =
            addresses.Addresses?.find(
              addr => addr.IsActive && addr.IsDefaultDeliverTo && addr.PostCode && addr.Country,
            ) || {};
          if (BillingAddress?.FullAddress) {
            BillingAddress.CompleteAddress =
              `${BillingAddress.StreetAddress}, ${BillingAddress.City}, ${BillingAddress.Country}, ${BillingAddress.State}, ${BillingAddress.PostCode}`.replace(
                / ,/g,
                '',
              );
          }
        }
        BillingAddressID = BillingAddress?.ID || '';
      }),
    );
  }
  return {
    ShippingAddressID,
    ShippingAddress: ShippingAddress || {},
    BillingAddress: BillingAddress || {},
    BillingAddressID,
  };
};
