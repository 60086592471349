import React, {useContext, useEffect} from 'react';
import {AlertDialogSlide, IDialogAction} from './AlertDialogSlide';
import PurchasingContext from '../../context/purchasing/purchasingContext';
import {TextField, Grid, FormControl, InputLabel, Select, MenuItem, createTheme} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';

import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import useWarehouseAPI from '../../services/useWarehouseAPI';
import {IWarehouse} from '../../model/Warehouse';
import {IRequisition} from '../../model/requisition/Requisition';
import {makeStyles, ThemeProvider} from '@material-ui/core/styles';
import moment from 'moment';
import {Template} from '../purchasing/Template';
import {css} from 'glamor';
import {IRequisitionTemplate} from '../../model/template/IRequisitionTemplate';
import useTemplateAPI from '../../services/useTemplateAPI';
import LoadingIndicator from '../ui/LoadingIndicator';

export const RequisitionDetailsDialog = () => {
  const warehouseAPI = useWarehouseAPI();
  const templateAPI = useTemplateAPI();

  const border = css({
    border: '1px solid #ccc',
    borderRadius: 14,
    flexWrap: 'wrap',
    overflowY: 'auto',
    maxHeight: 314,
  });

  const temp = css({
    color: '#0092A8',
  });

  const pContext = useContext(PurchasingContext);
  let requisition = pContext.requisition;
  const originalRequisitionName = requisition && requisition.Name;
  const originalRequisitionTemplateId = requisition && requisition.RequisitionTemplateID;

  if (!requisition) {
    let tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    requisition = {
      DeliveryMethod: 'Deliver',
      DueDate: tomorrow,
      IsActive: true,
      Lines: [],
      Name: pContext.poToCopyFrom ? `Copy from ${pContext.poToCopyFrom.Name || ''}` : '',
      OrderDate: new Date(),
      ReorderFromPurchaseOrderID: pContext.poToCopyFrom ? pContext.poToCopyFrom.ID : undefined,
      RequisitionType: 'Purchase',
      Status: 'New',
      ToWarehouseID: 0,
    };
  }

  const [requisitionState, setRequisitionState] = React.useState(requisition);

  const [warehousesList, setWarehousesList] = React.useState<IWarehouse[]>([]);
  const [templatesList, setTemplatesList] = React.useState<Partial<IRequisitionTemplate>[]>([]);
  const [selectedTemplate, setSelectedTemplate] = React.useState<Partial<IRequisitionTemplate>>({});

  const isLoading = templateAPI.isLoading;

  const handleChange = (event: React.ChangeEvent<{name?: string; value: unknown}>) => {
    const {name, value} = event.target;

    setRequisitionState(oldRequisitionState => ({
      ...oldRequisitionState,
      [name as string]: value,
    }));
  };

  const handleDateChange = (date: Date | null, _value?: string | null | undefined, name?: string) => {
    const noTzDate = date && moment(date).local().format('YYYY-MM-DD');

    setRequisitionState(oldRequisitionState => ({
      ...oldRequisitionState,
      [name as string]: noTzDate,
    }));
  };

  const onCreateRequisition = (requisition?: Partial<IRequisition>) => {
    pContext.setRequisition(requisition || requisitionState);
  };

  const onClickTemplate = (template: Partial<IRequisitionTemplate>) => {
    if (!template) return;

    if (template.ID === selectedTemplate.ID) return;

    let requisition: Partial<IRequisition> = JSON.parse(JSON.stringify(requisitionState));

    if (originalRequisitionTemplateId) {
      return;
    } else {
      requisition.RequisitionTemplateID = template.ID;
    }

    if (!originalRequisitionName) {
      // requisition.Name = template.Name ? `${template.Name} ${moment().format("MMM-DD-H-m")}` : "";
      requisition.Name = template.Name || '';
    }

    setSelectedTemplate(template);
    setRequisitionState(requisition);
  };

  const onDeleteTemplate = (templateID: number) => {
    if (templateID) {
      templateAPI.delete(templateID).then(isSuccess => {
        if (isSuccess) {
          let temps = [...templatesList];
          let idx = temps.findIndex(t => t.ID === templateID);
          if (idx >= 0) {
            temps.splice(idx, 1);
            setTemplatesList(temps);
          }
        }
      });
    }
  };

  useEffect(() => {
    let requisition: Partial<IRequisition> = JSON.parse(JSON.stringify(requisitionState));

    warehouseAPI.search().then((warehouses: IWarehouse[]) => {
      if (warehouses && warehouses.length) {
        setWarehousesList(warehouses);
        const defaultWarehouse = warehouses.find((wh: IWarehouse) => wh.IsDefault);
        if (!requisition.ToWarehouseID && defaultWarehouse) {
          requisition.ToWarehouseID = defaultWarehouse.ID;
        }
        setRequisitionState(requisition);
      }
    });
  }, []);

  useEffect(() => {
    templateAPI.search().then((templates: IRequisitionTemplate[]) => {
      const emptyTemplate: Partial<IRequisitionTemplate> = {ID: 0};
      if (templates && templates.length) {
        setTemplatesList([emptyTemplate, ...templates]);

        const tId: number = (requisition && requisition.RequisitionTemplateID) || 0;
        const template = templates.find((t: IRequisitionTemplate) => t.ID === tId);
        if (template) {
          setSelectedTemplate(template);
        } else {
          setSelectedTemplate(emptyTemplate);
        }
      }
    });
  }, []);

  const useStyles = makeStyles({
    root: {
      '& label.Mui-focused': {
        color: '#2C95BC',
      },
      '& .MuiFormLabel-root': {
        color: '#1C78AD !important',
        fontFamily: 'sans-serif',
      },
      '& .MuiInput-underline:before': {
        borderBottomColor: '#000',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: '#1C78AD',
      },
    },
    select: {
      '& .MuiSelect-select': {
        minWidth: '14rem',
      },
      '& .MuiInput-underline:after': {
        pointerEvents: 'none',
      },
    },
  });

  const classes = useStyles();

  const materialTheme = createTheme({
    overrides: {
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: '#1C78AD',
        },
      },
      MuiPickersDay: {
        day: {
          color: '#000',
        },
        daySelected: {
          backgroundColor: '#1C78AD',
        },
        dayDisabled: {
          color: '#ccc',
        },
      },
      MuiInput: {
        underline: {
          '&:after': {
            borderBottomColor: '#2C95BC',
          },
        },
      },
    },
  });

  const dialogStyles = {
    root: {
      textAlign: 'left',
      marginLeft: '28px',
      color: '#1C78AD',
      '& h2': {
        display: 'flex',
        justifyContent: 'center',
        fontFamily: 'sans-serif',
        fontSize: '37px',
        letterSpacing: '-1px',
        wordSpacing: '-1px',
      },
    },
    MuiDialog: {
      '& .MuiDialog-paper': {
        maxWidth: '757px',
        minWidth: '757px',
      },
    },
    MuiDialogContent: {
      padding: '0px 60px',
      overflowY: 'hidden',
      '& .MuiInputBase-input': {
        color: '#000',
        fontFamily: 'sans-serif',
        fontSize: '14px',
      },
      '& .MuiFormLabel-root': {
        color: '#000',
        fontFamily: 'sans-serif',
      },
      '& .MuiInput-underline:before': {
        borderBottomColor: '#000',
      },
    },
  };

  let actions: IDialogAction[] = [
    {
      label: pContext.requisition ? 'Save & Close' : 'Continue',
      color: 'blueShade',
      border: '1px solid #1C78AD',
      action: () => onCreateRequisition(),
    },
    {
      label: 'Cancel',
      variant: 'outlined',
      color: 'white',
      textColor: 'blueShade',
      border: '1px solid #1C78AD',
      action: () => pContext.setIsShowRequisitionDetailsDialog(false),
    },
  ];

  return isLoading ? (
    <LoadingIndicator isLoading />
  ) : (
    <AlertDialogSlide materialStyles={dialogStyles} title="Purchase Details" actions={actions}>
      <Grid container justifyContent="space-between" className="text-center">
        <h3>Enter a reason for this purchase and the default values for your purchase orders.</h3>
      </Grid>

      <Grid container justifyContent="space-between">
        <TextField
          classes={{root: classes.root}}
          autoFocus
          margin="dense"
          id="requisition-reason"
          name="Name"
          label="What's the reason for this purchase?*"
          type="text"
          onChange={handleChange}
          value={requisitionState.Name}
          fullWidth
        />
      </Grid>

      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container justify="space-between" classes={{root: classes.root}}>
          <ThemeProvider theme={materialTheme}>
            <KeyboardDatePicker
              autoOk
              variant="inline"
              margin="normal"
              format="dd/MM/yyyy"
              id="order-date-picker"
              label="ORDER DATE"
              value={requisitionState.OrderDate}
              onChange={(date, value) => handleDateChange(date, value, 'OrderDate')}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
            <KeyboardDatePicker
              autoOk
              variant="inline"
              margin="normal"
              format="dd/MM/yyyy"
              id="required-date-picker"
              label="REQUIRED DATE"
              value={requisitionState.DueDate}
              onChange={(date, value) => handleDateChange(date, value, 'DueDate')}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </ThemeProvider>
        </Grid>
      </MuiPickersUtilsProvider>

      <Grid container justify="space-between">
        <FormControl margin="normal" classes={{root: classes.select}}>
          <InputLabel htmlFor="warehouse">WAREHOUSE</InputLabel>
          <Select
            value={requisitionState.ToWarehouseID}
            onChange={handleChange}
            inputProps={{
              name: 'ToWarehouseID',
              id: 'warehouse',
            }}
          >
            {warehousesList.map((wh: IWarehouse, _index: number) => (
              <MenuItem key={wh.ID} value={wh.ID}>
                {wh.Name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl margin="normal" classes={{root: classes.select}}>
          <InputLabel htmlFor="delivery-method">DELIVERY METHOD</InputLabel>
          <Select
            value={requisitionState.DeliveryMethod}
            onChange={handleChange}
            inputProps={{
              name: 'DeliveryMethod',
              id: 'delivery-method',
            }}
          >
            <MenuItem value="Deliver">Delivery</MenuItem>
            <MenuItem value="Warehouse_Pickup">Warehouse Pickup</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <br />
      {templatesList.length > 1 && !originalRequisitionName && (
        <div>
          <div className={`${temp}`}>SELECT YOUR TEMPLATE</div>
          <div className={`flex my-2 ${border}`}>
            {templatesList.map((template: Partial<IRequisitionTemplate>) => (
              <Template
                key={template.GUID}
                template={template}
                isActive={template.ID === selectedTemplate.ID}
                onDelete={onDeleteTemplate}
                onClick={onClickTemplate}
              />
            ))}
          </div>
        </div>
      )}
    </AlertDialogSlide>
  );
};
