import React, {useState, SetStateAction} from 'react';
import {Button, Dialog, DialogBody, DialogHeader, DialogFooter, Typography, Input, IconButton} from 'spenda-ui-react';
import {IQuotes} from '../../model/quotes/quotes';
import {PriceFormat} from '../../utils/formatter';
import SendPaymentIcon from '../../assets/svg/quotes-management/SendPaymentIcon';
import CrossIcon from '../../assets/svg/Cross';

interface IQuoteAmountPayDialog {
  open: boolean;
  handleOpenPaymentWidget: () => void;
  handleClose: () => void;
  quoteDetails: IQuotes;
  setAmountToPay: React.Dispatch<SetStateAction<number>>;
}

export const QuoteAmountPayDialog = (props: IQuoteAmountPayDialog) => {
  const {open, handleOpenPaymentWidget, handleClose, quoteDetails, setAmountToPay} = props;

  const [showCustomAmount, setShowCustomAmount] = useState<boolean>(false);
  const [customAmount, setCustomAmount] = useState<number>();
  const [showCustomAmountError, setShowCustomAmountError] = useState<boolean>(false);
  const [customAmountErrorMessage, setCustomAmountErrorMessage] = useState<string>();

  const handleAmountPay = (val: number) => {
    setAmountToPay(val);
    handleOpenPaymentWidget();
  };

  const handlePayCustomAmount = () => {
    if (customAmount) {
      if (customAmount < (quoteDetails?.requiredDepositAmount || 0)) {
        setShowCustomAmountError(true);
        setCustomAmountErrorMessage('Amount cannot be less than Set Deposit.');
      } else if (customAmount > (quoteDetails?.balance || 0)) {
        setShowCustomAmountError(true);
        setCustomAmountErrorMessage('Amount cannot be greater than total amount.');
      } else {
        handleAmountPay(customAmount);
      }
    } else {
      setShowCustomAmountError(true);
      setCustomAmountErrorMessage('Required');
    }
  };

  return (
    <Dialog
      open={open}
      handler={() => {}}
      className={`flex h-full !w-full !min-w-[20%] flex-col items-center justify-center sm:block sm:h-auto sm:!max-w-[339px] sm:p-0`}
    >
      <DialogHeader className="flex flex-col gap-1">
        <div className="flex w-full justify-end">
          <IconButton variant="outlined" onClick={handleClose}>
            <CrossIcon className="fill-primary" height={'17px'} width={'17px'} />
          </IconButton>
        </div>
        <Typography className="flex w-[261px] items-center justify-center text-center text-black" variant="h2">
          How much would you like to pay as a deposit
        </Typography>
      </DialogHeader>
      <DialogBody className="flex w-full min-w-[290px] flex-col items-center justify-center gap-5">
        <Typography
          className={`mb-4 w-[220px] min-w-[220px] max-w-[220px] text-center font-normal text-black-900`}
          variant="small"
        >
          There are three options, you can either pay the set deposit amount, pay a custom amount greater than the set
          deposit or pay the full amount upfront.
        </Typography>

        {showCustomAmount && (
          <div className="flex items-center justify-center justify-between">
            <Input
              type="number"
              label="Custom amount"
              containerProps={{className: 'w-[248px] min-w-[248px]'}}
              value={customAmount}
              onChange={e => {
                setShowCustomAmountError(false);
                setCustomAmount(parseInt(e.target.value));
              }}
              helperText={(showCustomAmountError && customAmountErrorMessage) || ''}
              error={Boolean(showCustomAmountError)}
            />

            <IconButton
              variant="outlined"
              onClick={handlePayCustomAmount}
              className="ml-1 h-[45px] max-h-[48px] w-[45px] max-w-[48px]"
            >
              <SendPaymentIcon height={25} width={25} />
            </IconButton>
          </div>
        )}
      </DialogBody>
      <DialogFooter>
        <div className="flex w-full flex-col items-center justify-center p-2">
          <div className="flex w-[300px] flex-col justify-between gap-2">
            <Button onClick={() => handleAmountPay(quoteDetails?.requiredDepositAmount!)} variant="outlined">
              Pay Set Deposit Amount - {PriceFormat(quoteDetails?.requiredDepositAmount)}
            </Button>
            <Button
              onClick={() => setShowCustomAmount(!showCustomAmount)}
              variant={showCustomAmount ? 'filled' : 'outlined'}
            >
              Pay Custom Amount
            </Button>
            <Button onClick={() => handleAmountPay(quoteDetails?.totalInc!)} variant="outlined">
              Pay Full Amount - {PriceFormat(quoteDetails?.totalInc)}
            </Button>
          </div>
        </div>
      </DialogFooter>
    </Dialog>
  );
};
