import React from 'react';
export const IconPurchasingRebranding = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="21px"
      height="23px"
      viewBox="0 0 21 23"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>Icon</title>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="Spenda-Collect-Supplier-Interface-Copy-16"
          transform="translate(-123.000000, -143.000000)"
          className="fill-primary"
        >
          <g id="MenuStrip" transform="translate(115.000000, 139.000000)">
            <g id="Icon" transform="translate(8.840000, 4.000000)">
              <path
                d="M14.375,13.4167107 C14.375,13.9459836 13.9459396,14.375044 13.4166667,14.375044 L5.75,14.375044 C5.22072711,14.375044 4.79166667,13.9459836 4.79166667,13.4167107 C4.79166667,12.8874378 5.22072711,12.4583774 5.75,12.4583774 L13.4166667,12.4583774 C13.9459396,12.4583774 14.375,12.8874378 14.375,13.4167107 Z M10.5416667,16.2917107 L5.75,16.2917107 C5.22072711,16.2917107 4.79166667,16.7207711 4.79166667,17.250044 C4.79166667,17.7793169 5.22072711,18.2083774 5.75,18.2083774 L10.5416667,18.2083774 C11.0709396,18.2083774 11.5,17.7793169 11.5,17.250044 C11.5,16.7207711 11.0709396,16.2917107 10.5416667,16.2917107 Z M19.1666911,10.048169 L19.1666911,18.2083774 C19.1634983,20.8534283 17.0200509,22.9968757 14.375,23.000044 L4.79166667,23.000044 C2.14661573,22.9968757 0.00316836149,20.8534283 0,18.2083774 L0,4.79171069 C0.00316836149,2.14665975 2.14661573,0.00321238617 4.79166667,2.19061458e-05 L9.11854167,2.19061458e-05 C10.898634,-0.00453760088 12.6066606,0.702826934 13.8622917,1.96462736 L17.201125,5.30537736 C18.463674,6.56019183 19.1714868,8.26812233 19.1666911,10.048169 L19.1666911,10.048169 Z M12.5072083,3.31971069 C12.2056029,3.02757333 11.866978,2.77626273 11.5,2.57221069 L11.5,6.70837736 C11.5,7.23765024 11.9290604,7.66671069 12.4583333,7.66671069 L16.5945,7.66671069 C16.3903329,7.29985058 16.1386772,6.96151714 15.8460417,6.66046069 L12.5072083,3.31971069 Z M17.25,10.048169 C17.25,9.89004402 17.2193333,9.73862736 17.2049583,9.58337736 L12.4583333,9.58337736 C10.8705147,9.58337736 9.58333333,8.29619601 9.58333333,6.70837736 L9.58333333,1.96175236 C9.42808333,1.94737736 9.27570833,1.91671069 9.11854167,1.91671069 L4.79166667,1.91671069 C3.20384801,1.91671069 1.91666667,3.20389204 1.91666667,4.79171069 L1.91666667,18.2083774 C1.91666667,19.796196 3.20384801,21.0833774 4.79166667,21.0833774 L14.375,21.0833774 C15.9628187,21.0833774 17.25,19.796196 17.25,18.2083774 L17.25,10.048169 Z"
                id="Shape"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
