import {useState, useEffect} from 'react';
import {DatTypes} from '../model/constants/Constants';
import {IActionRes, IActionResults, IPagedActionResults} from './../model/ActionResults';
import {
  ICreateCustomerClassResponse,
  ICreateCustomerClassValues,
  IGetCustomerClassValues,
} from '../model/customer/CustomerClasses';
import {ICustomerFilter} from '../model/customer/Customer';
import useHttp from '../hooks/useHttp';

export const useCustomerClassesAPI = () => {
  const {POST, GET, PUT, DELETE, isLoading} = useHttp();

  const create = (customer: ICreateCustomerClassValues, websiteId: number): Promise<ICreateCustomerClassResponse> => {
    return POST(`CustomerClass`, {Object: customer, websiteId});
  };

  const updateClass = (
    id: number,
    customer: Partial<ICreateCustomerClassValues>,
    websiteId: number,
  ): Promise<IActionRes> => {
    return PUT(`CustomerClass/${id}`, {Object: customer, websiteId});
  };

  const getClass = (id: number): Promise<ICreateCustomerClassValues> => {
    return GET(`CustomerClass/${id}`, id).then((data: IActionResults<ICreateCustomerClassValues>) => data.Value);
  };

  const deleteClass = (id: number): Promise<IActionRes> => {
    return DELETE(`CustomerClass/${id}`);
  };

  const getList = (filter: any): Promise<IPagedActionResults<ICreateCustomerClassValues[]>> => {
    return GET(`dashboard`, {...filter, DatTypeID: DatTypes.CustomerClass}).then(
      (data: IPagedActionResults<ICreateCustomerClassValues[]>) => data,
    );
  };
  const getGenericCustomersClassList = (
    search: string,
    PostingBehaviour?: string,
  ): Promise<IPagedActionResults<ICreateCustomerClassValues[]>> => {
    const query = {
      LastRowNumber: 0,
      MaxResults: 50,
      SortField: 'name',
      SortAsc: true,
      Search: search,
      IsExactMatch: false,
      'PostingBehaviours[]': PostingBehaviour,
    };
    return GET(`customerclass`, query).then((data: IPagedActionResults<ICreateCustomerClassValues[]>) => data);
  };

  const getCustomerClass = (customerClassID: number): Promise<IGetCustomerClassValues> => {
    return GET(`Spenda/Customers/CustomerClass/${customerClassID}`, customerClassID).then(
      (data: IGetCustomerClassValues) => data,
    );
  };

  return {
    create,
    getList,
    isLoading,
    getClass,
    updateClass,
    deleteClass,
    getGenericCustomersClassList,
    getCustomerClass,
  };
};

export const useLoadCustomers = () => {
  const pageSize = 10;

  const initialFilterState = {
    MaxResults: pageSize,
    SortAsc: false,
    SortField: 'CreatedDateTime',
    IsShowActiveOnly: true,
  } as ICustomerFilter;
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<ICreateCustomerClassValues[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [moreToGet, setMoreToGet] = useState<boolean>(true);
  const [error, setError] = useState<any>();
  const [searchFilter, setSearchFilter] = useState<ICustomerFilter>(initialFilterState);

  const {getList} = useCustomerClassesAPI();

  const getCustomersList = async () => {
    setLoading(true);

    if (searchFilter.StartRow === 1) {
      setItems([]);
      setMoreToGet(true);
    }

    try {
      const {Value, MoreToGet, TotalRecordCount, LastRowNo} = await getList(searchFilter);
      let items: ICreateCustomerClassValues[] = Value || [];

      setItems(items);
      setTotalCount(TotalRecordCount);
      setMoreToGet(MoreToGet);
      setCurrentPage(Math.ceil(LastRowNo / (searchFilter.MaxResults || pageSize)));
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCustomersList();
  }, [searchFilter]);

  return {
    loading,
    items,
    totalCount,
    pageSize: searchFilter.MaxResults || pageSize,
    currentPage,
    moreToGet,
    error,
    searchFilter,
    getCustomersList,
    setSearchFilter,
  };
};
