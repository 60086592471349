import React from 'react';

import CloseIcon from '@material-ui/icons/Close';
import {SModal} from '../modals/modalSpendaMeterialUI';
import {Box, makeStyles} from '@material-ui/core';
import {SButton} from '../buttons/Button';

const useSendRemittanceSelectSupplierStyles = makeStyles({
  sendRemittanceModal: {
    maxWidth: '550px',
    height: '320px',
    fontFamily: 'Poppins, sans-serif !important',
  },
  apSendRemittanceHeader: {
    borderBottom: '1px solid #CCCCCC',
    '& h2': {
      fontFamily: 'Poppins, sans-serif !important',
      fontWeight: 300,
      fontSize: '28px',
      color: '#333333',
    },
  },
  discardFooter: {
    maxWidth: '472px',
    background: 'rgb(173 173 173 / 20%)',
    borderRadius: '8px',
  },
});

export interface ISendRemittanceSelectSupplierProps {
  isSubmitting: boolean;
  supplierCount: number;
  handleClose: () => void;
  handleSend?: () => void;
}

export const SendRemittanceSelectSupplier = (props: ISendRemittanceSelectSupplierProps) => {
  const classes = useSendRemittanceSelectSupplierStyles();
  const {isSubmitting, supplierCount, handleClose, handleSend} = props;

  return (
    <SModal open>
      <div className={`text-xl spenda-color flex justify-center items-center h-full max-w-full`}>
        <div className={`px-5 pt-3 pb-5 modal-box ${classes.sendRemittanceModal}`}>
          <Box className={`${classes.apSendRemittanceHeader} flex justify-between -mx-5 px-3 pb-3`}>
            <h2 className={`text-center w-full`}>Send Remittance Advice</h2>
            <Box className="text-right flex justify-end">
              <span className="ap-onboarding-close-icon">
                <CloseIcon onClick={handleClose} data-autoid="btnClose" />
              </span>
            </Box>
          </Box>
          <Box className="mt-6">
            <p className="text-center text-lg font-medium text-spenda-primarytext w-4/5 mx-auto">
              Are you sure you want to send remittance advice to selected {supplierCount} supplier
              {supplierCount > 1 ? 's' : ''}?
            </p>
          </Box>
          <Box
            className={`${classes.discardFooter} w-full flex justify-between items-center absolute bottom-5 left-0 p-2 mx-8 `}
          >
            <SButton
              textColor={'blueShade'}
              width="72px"
              height="40px"
              lineHeight="l"
              fontSize="xs"
              backgroundColor={'#fff'}
              margin="0px "
              variant={'outlined'}
              border={'1px solid #1C78AD'}
              borderRadius="6px"
              data-autoid="btnCancel"
              fontFamily="poppins"
              onClick={handleClose}
            >
              Cancel
            </SButton>
            <SButton
              textColor={'white'}
              width="72px"
              height="40px"
              lineHeight="l"
              fontSize="xs"
              backgroundColor="#1C78AD"
              margin="0px"
              variant={'outlined'}
              border={'1px solid #1C78AD'}
              borderRadius="6px"
              data-autoid="btnSend"
              fontFamily="poppins"
              disabled={isSubmitting}
              isSubmitting={isSubmitting}
              onClick={handleSend}
            >
              Send
            </SButton>
          </Box>
        </div>
      </div>
    </SModal>
  );
};
