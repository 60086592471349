import React from 'react';

export const IconDropdownSupplierView = (props: SVGProps) => (
  <svg width="10" height="10" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g id="Icons/Arrows/caret_down">
      <rect width="50" height="50" fill="white" fillOpacity="0.01" />
      <path
        id="Path"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.78022 11H46.2198C47.7551 11.0064 49.1353 11.937 49.7169 13.3579C50.2986 14.7787 49.9671 16.4101 48.877 17.4912L27.6952 38.673C26.9824 39.3917 26.0122 39.7959 25 39.7959C23.9878 39.7959 23.0176 39.3917 22.3048 38.673L1.12301 17.4912C0.0329342 16.4101 -0.298569 14.7787 0.283067 13.3579C0.864703 11.937 2.24494 11.0064 3.78022 11Z"
        className="fill-primary"
      />
    </g>
  </svg>
);
