import React, {useState, useEffect, useContext} from 'react';
import {Button, Chip, Dialog, DialogBody, DialogFooter, DialogHeader, Switch, Typography} from 'spenda-ui-react';

import {useBetaFeatures} from '../../services/useFeatureFlagsAPI';
import {IFeatureFlagsState, IFlagTypes, ILDFeature} from '../../model/feature-flags/FeatureFlags';
import {useTenantInfo} from '../../hooks/useTenantInfo';
import {ISupplierMarket} from '../../model/SupplierMarket';
import Skeleton from '../Skeleton';
import useHomePageAPI from '../../services/useHomePageAPI';

enum FlagTags {
  Mobile = 'Mobile',
  Web = 'Web',
}

const BetaFeaturesContext = React.createContext<Partial<BetaFeaturesContextProps>>({});

interface IFeatureFlagsDialogProps {
  open: boolean;
  onClose?: () => void;
  supplierMarket?: Partial<ISupplierMarket>;
  flags?: Partial<IFeatureFlagsState>;
  updateFeatureFlags?: (flags: Partial<IFeatureFlagsState>) => void;
}

type BetaFeaturesContextProps = {
  isInBuyerContext: boolean;
  flags?: Partial<IFeatureFlagsState>;
  isSupportUserRole: boolean;
};

export const FeatureFlagsDialog = (props: IFeatureFlagsDialogProps) => {
  // Hooks
  const {fetchFlags: fetchFlagsAPI, saveFeatureFlag} = useBetaFeatures();
  const {isInBuyerContext, isSupportUserRole} = useTenantInfo();
  const {GetPublishedModules} = useHomePageAPI();

  // State
  const [isFetching, setIsFetching] = useState<boolean>(true);

  // Effects
  useEffect(() => {
    const refreshFlags = async () => {
      setIsFetching(true);
      const linkedSupplierId = isInBuyerContext ? props.supplierMarket?.SupplierID : undefined;
      const flags = await fetchFlagsAPI(linkedSupplierId);

      if (props.updateFeatureFlags) {
        props.updateFeatureFlags(flags);
      }

      setIsFetching(false);
    };

    refreshFlags();
  }, []);

  const onClose = () => {
    if (props.onClose) props.onClose();
  };

  const onToggleFeatureFlag = async (flag: ILDFeature) => {
    await saveFeatureFlag(flag);
    await GetPublishedModules();

    for (let modes in props.flags?.featureFlags) {
      for (let featureFlag of props.flags?.featureFlags[modes as keyof IFlagTypes] || []) {
        if (featureFlag.key === flag.key) {
          featureFlag.value = flag.value;
        }
      }
    }
    props.updateFeatureFlags?.(props.flags!);
  };

  return (
    <BetaFeaturesContext.Provider value={{isInBuyerContext, flags: props.flags, isSupportUserRole}}>
      <Dialog handler={() => {}} open className="">
        <DialogHeader className="justify-center">
          <Typography variant="h1" className="text-4xl font-medium text-primary ">
            Beta Features
          </Typography>
        </DialogHeader>
        <DialogBody className="max-h-[70vh] overflow-y-auto px-6 py-2">
          <Typography className="text-md mb-4 text-justify font-medium text-black-800">
            {isInBuyerContext
              ? `Your supplier ${props.supplierMarket?.TenantName} has the following beta features available for its customers. Get in contact with
                  ${props.supplierMarket?.TenantName} if you wish to enable a particular beta feature.`
              : `I understand that by enabling any of the Experimental Features within Spenda, I accept full risk and
                  liability associated with the use of any Product or Service that is still in the development phase. Spenda
                  Ltd. or its subsidiaries will not be held accountable for any potential loss or business damaged incurred.`}
          </Typography>

          <FeatureFlagGroup
            label={'In Development'}
            flags={props.flags?.featureFlags?.InDevelopment || []}
            onToggleFeatureFlag={onToggleFeatureFlag}
            isLoading={isFetching}
            isGroupDisabled={isInBuyerContext}
          />
          <FeatureFlagGroup
            label={'In Testing'}
            flags={props.flags?.featureFlags?.InTesting || []}
            onToggleFeatureFlag={onToggleFeatureFlag}
            isLoading={isFetching}
            isGroupDisabled={isInBuyerContext}
          />
          <FeatureFlagGroup
            label={'Beta'}
            flags={props.flags?.featureFlags?.Beta || []}
            onToggleFeatureFlag={onToggleFeatureFlag}
            isLoading={isFetching}
            isGroupDisabled={isInBuyerContext}
          />
        </DialogBody>
        <DialogFooter className="p-0">
          <div className="mx-2.5 mb-2.5 flex w-full justify-center rounded-lg bg-spenda-footerBg py-2.5">
            <Button onClick={onClose}>Close</Button>
          </div>{' '}
        </DialogFooter>
      </Dialog>
    </BetaFeaturesContext.Provider>
  );
};

interface IFeatureFlagGroup {
  label: string;
  flags: ILDFeature[];
  onToggleFeatureFlag?: (f: ILDFeature) => void;
  isLoading?: boolean;
  isGroupDisabled?: boolean;
}

const FeatureFlagGroup = (props: IFeatureFlagGroup) => {
  const webFlags = props.flags.filter(f => f.availability?.isAvailableForClientSDK);
  const hasFlags = webFlags.length > 0;

  return props.isLoading ? (
    <>
      <Skeleton className="mb-2 w-1/4" />
      <div className="mb-2 rounded border bg-gray-100 p-2 text-base">
        <Skeleton count={2} className="mb-1" />
      </div>
    </>
  ) : hasFlags ? (
    <>
      <Typography className="text-black-800" variant="h3">
        {props.label}
      </Typography>
      <div className="mb-2 rounded border bg-gray-300 p-2">
        {webFlags.map(f => (
          <FeatureFlagItem
            key={f.key}
            flag={f}
            onToggleFeatureFlag={props.onToggleFeatureFlag}
            disabled={!f.on || props.isGroupDisabled}
          />
        ))}
      </div>
    </>
  ) : (
    <></>
  );
};

interface IFeatureFlagItem {
  flag: ILDFeature;
  onToggleFeatureFlag?: (f: ILDFeature) => void;
  disabled?: boolean;
}

const editableFlagsBySupportUser: string[] = ['claim-and-returns-v2-72602'];

const FeatureFlagItem = (props: IFeatureFlagItem) => {
  const {flags, isSupportUserRole} = useContext(BetaFeaturesContext);

  const {flag} = props;
  let isFlagChecked = !!flag.value;

  const supplierFeatureFlag = flags?.linkedSupplierFeatureFlags?.find(sf => sf.key === flag.key);
  if (supplierFeatureFlag) {
    isFlagChecked = !!supplierFeatureFlag.value;
  }

  const onChangeFlag = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (props.onToggleFeatureFlag) {
      flag.value = event.target.checked;
      props.onToggleFeatureFlag(flag);
    }
  };

  const getBadges = () => {
    const hasMobileTag = flag.tags.find(f => f.toLowerCase() === FlagTags.Mobile.toLowerCase());
    const hasWebTag = flag.tags.find(f => f.toLowerCase() === FlagTags.Web.toLowerCase());

    return (
      <>
        {hasMobileTag && <FlagTagBadge flagTagName={FlagTags.Mobile} />}
        {hasWebTag && <FlagTagBadge flagTagName={FlagTags.Web} />}
      </>
    );
  };

  // Check if the current flag key is in the restrictedFlagsBySupportUserRole array
  return (
    <div className="mb-2 flex">
      <div className="flex-1 text-base font-semibold text-black-800">
        <label className="flex items-center gap-x-1" htmlFor={flag.key}>
          {flag.name}
          {getBadges()}
        </label>

        <Typography variant="h3" className="pr-2 text-base text-gray-600">
          {flag.description}
        </Typography>
      </div>
      <div className="text-right">
        <Switch
          checked={isFlagChecked}
          disabled={props.disabled || (editableFlagsBySupportUser.indexOf(flag.key!) >= 0 && !isSupportUserRole)}
          onChange={onChangeFlag}
          name={flag.key!}
          ripple={false}
          data-autoid={`chk${flag.key!}`}
        />
      </div>
    </div>
  );
};

const FlagTagBadge = (props: {flagTagName: FlagTags}) => (
  <Chip value={props.flagTagName} variant="outlined" size="sm" className="h-fit px-1 py-0.5 leading-none" />
);
