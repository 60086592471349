import React, {useState} from 'react';
import moment from 'moment';
import clsx from 'clsx';
import {IRepaymentSchedule} from '../../model/payment/Lending';
import {PriceFormat} from '../../utils/formatter';
import {ARSelectToggler} from './ARSelectToggler';
import {PrimaryButton} from '../buttons/DefaultButtons';
import {PSBLWidget} from '../../screens/purchasing/PSBLWidget';
import {PaymentWidgetScope} from '../../model/constants/Constants';
import {SplitPaymentButton} from './SplitPaymentButton';
import ARTooltip from '../data-display/ARTootip';

interface ILendingWidgetPanel {
  repayments: IRepaymentSchedule[];
  selectedRepayments: IRepaymentSchedule[];
  setSelectedRepayments: (selectedRepayments: IRepaymentSchedule[]) => void;
  facilityGuid: string;
  refreshBorrowerTransactions: () => Promise<void>;
}

export const LendingWidgetPanel = (props: ILendingWidgetPanel) => {
  const {repayments, selectedRepayments, setSelectedRepayments, facilityGuid, refreshBorrowerTransactions} = props;
  const [isShowPaymentWidgetModal, setIsShowPaymentWidgetModal] = useState<boolean>(false);
  const [amountToPay, setAmountToPay] = useState<number>(0);

  const handleSplitAmountChange = (amount?: number) => {
    setAmountToPay(amount ?? 0);
    setSelectedRepayments([]);
  };

  const handleGetRepaymentAmount = (paymentList: IRepaymentSchedule[]) => {
    const amount = paymentList?.reduce((acc, cur) => {
      return acc + cur.amountDue;
    }, 0);
    return amount;
  };

  //to create badges
  const handleRepaymentStatus = (dueDateString: string) => {
    const dueDate = moment(dueDateString).valueOf();
    const presentDate = moment().valueOf();
    if (presentDate < dueDate) {
      return (
        <div className="flex h-6 !w-fit items-center justify-center rounded-[6px] bg-[#8178CF1a] px-5">
          <p className="font-poppins text-xs text-[#8178CF]">Scheduled</p>
        </div>
      );
    } else {
      return (
        <div className="flex h-6 !w-fit items-center justify-center rounded-[6px] bg-[#C55D441a] px-5">
          <p className="font-poppins text-xs text-[#C55D44]">Overdue</p>
        </div>
      );
    }
  };

  const handleSelectRepayment = (row: IRepaymentSchedule) => {
    const selectedList = [...selectedRepayments];
    let repaymentAmt = 0;
    if (selectedList?.findIndex(s => s.dueDate === row.dueDate) > -1) {
      const filterdList = selectedList.filter(s => s.dueDate !== row.dueDate);
      repaymentAmt = handleGetRepaymentAmount(filterdList);

      setSelectedRepayments(filterdList);
    } else {
      if (selectedList?.length) {
        setSelectedRepayments([...selectedList, row]);
        repaymentAmt = handleGetRepaymentAmount([...selectedList, row]);
      } else {
        setSelectedRepayments([row]);
        repaymentAmt = handleGetRepaymentAmount([row]);
      }
    }
    setAmountToPay(parseFloat(repaymentAmt.toFixed(2)));
  };

  const onPaymentSuccess = () => {
    refreshBorrowerTransactions();
    setAmountToPay(0);
  };

  const handleDisableARSelectToggler = (idx: number) => {
    return idx > selectedRepayments.length;
  };

  return (
    <>
      <div className="h-full w-full">
        <div className="mx-2 mt-5 flex flex-row justify-between align-bottom">
          <p className="text-xxl font-poppins font-light text-spenda-primarytext ">Repayments</p>
          <p className="font-poppins text-base font-semibold text-[#3C9F78]" data-autoid="lblRepaymentAmount">
            {PriceFormat(amountToPay)}
          </p>
        </div>
        <hr className="mb-[30px] ml-[0px] mt-2 h-0 w-full flex-shrink-0 border-t border-gray-300 bg-gray-300" />
        <div className="h-full overflow-x-hidden overflow-y-scroll pb-40">
          <div className="px-1">
            <table className="mb-2.5 w-full overflow-auto" data-autoid="tblRepayment">
              <thead>
                <tr className="!h-10 bg-[#F1F1F1]">
                  <th align="left" className="w-[10%] px-2.5 font-poppins text-base font-semibold text-[#999999]"></th>
                  <th align="left" className="w-[30%] px-2.5 font-poppins text-base font-semibold text-[#999999]">
                    Date
                  </th>
                  <th align="right" className="w-[25%] px-2.5 font-poppins text-base font-semibold text-[#999999]">
                    Amount
                  </th>
                  <th align="center" className="w-[35%] px-2.5 font-poppins text-base font-semibold text-[#999999]">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {repayments?.map((r, idx) => (
                  <tr key={idx} style={{borderBottom: '1px solid #ECECEC'}} data-autoid={`rowTransaction${r.dueDate}`}>
                    <td
                      align="left"
                      className="border-b border-blue-gray-50 bg-[#FAFAFA] px-2.5 py-2.5 font-poppins text-base font-medium text-spenda-primarytext"
                      data-autoid="chkTransaction"
                    >
                      <span
                        className={clsx(`cursor-pointer items-center font-poppins text-base font-normal`, {
                          'opacity-40': handleDisableARSelectToggler(idx),
                        })}
                        onClick={() => !handleDisableARSelectToggler(idx) && handleSelectRepayment(r)}
                      >
                        <ARTooltip
                          arrow
                          title={
                            <p className="text-center font-poppins text-xs font-medium text-spenda-primarytext">
                              First, select the ones that are older than this
                            </p>
                          }
                          disableHoverListener={!handleDisableARSelectToggler(idx)}
                        >
                          <div>
                            <ARSelectToggler
                              className={clsx(`h-[25px] w-[25px]`, {
                                '!cursor-default': handleDisableARSelectToggler(idx),
                              })}
                              key={`checkbox${r.dueDate}`}
                              isSelected={selectedRepayments!?.findIndex(st => st.dueDate === r.dueDate) > -1}
                            />
                          </div>
                        </ARTooltip>
                      </span>
                    </td>
                    <td
                      align="left"
                      className="px-2.5 py-2.5 font-poppins text-base font-medium text-spenda-primarytext"
                      data-autoid="lblRepaymentDate"
                    >
                      {moment.utc(r?.dueDate).local().format('DD MMM YYYY')}
                    </td>
                    <td
                      align="right"
                      className="bg-[#FAFAFA]  px-2.5 py-2.5 font-poppins text-base font-medium text-spenda-primarytext"
                      data-autoid="lblRepaymentAmount"
                    >
                      {PriceFormat(r?.amountDue)}
                    </td>
                    <td align="center" data-autoid="lblRepaymentStatus">
                      <div className="h-[24px] w-[80px]">{handleRepaymentStatus(r.dueDate)}</div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="absolute bottom-0 left-2 right-2 mb-2">
            <div className="rounded-[5px] bg-[#ECECEC] p-2.5">
              <div className="flex justify-between ">
                <SplitPaymentButton
                  actualInvoiceBalance={handleGetRepaymentAmount(repayments)}
                  onAmountChanged={handleSplitAmountChange}
                  disableHoverListener={true}
                  amountToPay={amountToPay}
                  textFieldLabel="Please enter a custom amount you want to pay"
                  buttonLabel="Other Amount"
                />
                <PrimaryButton
                  label={'Make Payment'}
                  onClick={() => setIsShowPaymentWidgetModal(true)}
                  disabled={amountToPay <= 0}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {isShowPaymentWidgetModal && (
        <div
          className={` absolute left-[0px] top-[0] z-10 flex h-[inherit] w-full flex-col items-center justify-center rounded-[2px] bg-[#ADADAD20] pb-[68px] pt-2 drop-shadow backdrop-blur`}
        >
          <div className={`rounded-2.5 flex h-[inherit] max-h-[618px] w-auto flex-col bg-white p-2.5`}>
            <PSBLWidget
              onSuccess={onPaymentSuccess}
              onClose={() => setIsShowPaymentWidgetModal(false)}
              widgetScope={PaymentWidgetScope.SPENDA_FINANCE}
              paybleAmount={amountToPay}
              facilityGuid={facilityGuid}
            />
          </div>
        </div>
      )}
    </>
  );
};
