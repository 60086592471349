import React from 'react';

export const CloseCross = (props: SVGProps) => {
  return (
    <svg width={14} height={14} viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>{props.name ? props.name : 'Close'}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.39.61a2.083 2.083 0 00-2.947 0L25 22.054 3.556.61A2.083 2.083 0 00.61 3.556L22.054 25 .61 46.443a2.083 2.083 0 002.946 2.946L25 27.945 46.443 49.39a2.083 2.083 0 002.946-2.946L27.945 25 49.39 3.556a2.083 2.083 0 000-2.946z"
        fill="currentColor"
      />
    </svg>
  );
};
