import React, {useMemo, useState} from 'react';
import {ClickAwayListener, Popper} from '@material-ui/core';
import {debounce} from 'lodash';
import {bindPopper, usePopupState} from 'material-ui-popup-state/hooks';
import ReactLoading from 'react-loading';
import {useTenantInfo} from '../../../hooks/useTenantInfo';
import {IPagedActionResults} from '../../../model/ActionResults';
import {ISupplierInventory, Inventory} from '../../../model/inventory/Inventory';
import {useInventoryAPI} from '../../../services/useInventoryAPI';

interface ISuggestion {
  inventoryID: number;
  code: string;
  description: string;
  uoM: string;
  isTaxExempt: boolean;
}

interface ISearchableInput
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  onClickSearchResult?: (product: ISuggestion) => void;
  supplierId: number | undefined;
  onChangeSuggesion: () => void;
}

export const ProductSearchInput = (props: ISearchableInput) => {
  // Props
  const {onChange, onChangeSuggesion, supplierId, onClickSearchResult, ...restProps} = props;

  // APIs
  const {listInventory, supplierInventory, isLoading} = useInventoryAPI();

  // Hooks
  const {isInBuyerContext, isInSupplierContext} = useTenantInfo();

  // State
  const [suggestions, setSuggestions] = useState<ISuggestion[]>([]);

  const handleClickAway = () => {
    popupState.close();
  };

  const getInventoryList = (input: string) => {
    if (isInSupplierContext) {
      listInventory({
        MaxResults: 100,
        ViewMode: 'Sales',
        IsSystem: false,
        ...(input ? {Search: input} : {}),
      }).then((res: IPagedActionResults<Inventory[]>) => {
        const _suggestion: ISuggestion[] = [];
        res.Value?.forEach(v => {
          if (v?.ID && v?.InventoryCode && v?.ShortDescription && v?.UoM) {
            _suggestion.push({
              inventoryID: v?.ID,
              code: v?.InventoryCode,
              description: v?.ShortDescription,
              uoM: v?.UoM,
              isTaxExempt: !!v?.IsOrderTaxExempt,
            });
          }
        });
        setSuggestions(_suggestion);
      });
    } else if (isInBuyerContext) {
      supplierInventory({
        MaxResults: 100,
        ViewMode: 'Sales',
        IsSystem: false,
        LinkedSupplierID: supplierId,
        ...(input ? {Search: input} : {}),
      }).then((res: IPagedActionResults<ISupplierInventory[]>) => {
        const _suggestion = res?.Value?.map(v => ({
          inventoryID: v?.InventoryID,
          code: v?.InventoryCode,
          description: v?.ShortDescription,
          uoM: v?.UoM,
          isTaxExempt: !!v?.IsOrderTaxExempt,
        }));
        setSuggestions(_suggestion);
      });
    }
  };

  const debouncedSave = useMemo(
    () =>
      debounce((newValue: string) => {
        getInventoryList(newValue);
        onChangeSuggesion();
      }, 750),
    [suggestions]
  );

  const searchSuggestion = (event: React.ChangeEvent<HTMLInputElement>) => {
    debouncedSave(event.target.value);
    popupState.open(event);
  };

  const popupState = usePopupState({variant: 'popper', popupId: 'product-search-result'});
  return (
    <div className="w-full">
      <ClickAwayListener onClickAway={handleClickAway}>
        <input
          onChange={e => {
            searchSuggestion(e);
            onChange?.(e);
          }}
          {...restProps}
        />
      </ClickAwayListener>
      <Popper
        className="!min-w-[240px]"
        {...bindPopper(popupState)}
        transition
        placement="bottom-start"
        modifiers={{
          flip: {
            enabled: true,
          },
        }}
      >
        {() => (
          <div className="flex flex-col !w-[inherit]">
            {isLoading ? (
              <div className="flex jusitfy-center !w-[inherit] items-center p-2 bg-white">
                <ReactLoading className="m-auto" width="18px" height="18px" type="spin" color="#3398A7" />
              </div>
            ) : (
              !!suggestions?.length && (
                <ul className="flex flex-col !w-[inherit] !max-h-[220px] overflow-auto bg-white h-full py-2 drop-shadow-lg rounded-[6px]">
                  {suggestions.map(suggestion => {
                    return (
                      <li
                        onClick={() => onClickSearchResult?.(suggestion)}
                        className="px-3 py-1 font-poppins !w-[inherit] text-sm text-black-800 hover:bg-spenda-dashbg cursor-pointer"
                        key={suggestion?.inventoryID}
                      >
                        <span className="font-poppins text-xs font-normal">
                          <b>{suggestion.inventoryID}</b> {suggestion.description}
                        </span>
                      </li>
                    );
                  })}
                </ul>
              )
            )}
          </div>
        )}
      </Popper>
    </div>
  );
};
