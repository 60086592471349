import React, {useContext, useRef, useState} from 'react';
import {PrimaryButton, SecondaryButton} from '../../../components/buttons/DefaultButtons';
import {PaymentWidgetContext, PaymentWidgetPaymentSteps, PaymentWidgetStep} from './PaymentWidget';
import {IconNAB} from '../../../assets/svg/bank-accounts/IconNAB';
import {IconWestpac} from '../../../assets/svg/bank-accounts/IconWestpac';
import {IconCommonWealth} from '../../../assets/svg/bank-accounts/IconCommonWealth';
import {IconANZ} from '../../../assets/svg/bank-accounts/IconANZ';
import {BankWhite} from '../../../assets/svg/payment-methods/BankWhite';
import {BankAccounts, VerificationStatus} from '../../../model/constants/Constants';
import {SpendaPayLater} from '../../../assets/svg/payment-methods/SpendaPayLater';
import {Visa} from '../../../assets/svg/payment-methods/Visa';
import {MasterCardNew} from '../../../assets/svg/payment-methods/MasterCardNew';
import Radio from '@material-ui/core/Radio';
import {css} from 'glamor';
import {ITenantSupplierPaymentMethod} from '../../../model/payment/PaymentMethod';
import {UpdateAccountRequest} from '../../../services/useSpendaPaymentServicesAPI';
import {AddPaymentMethodPopOver, cardIconbgCss} from './PaymentWidgetStepHome';
import {withStyles} from '@material-ui/core';

const DarkBlueRadio = withStyles({
  colorSecondary: {
    color: '#1C76AD',
    '&$checked': {
      color: '#1C76AD',
    },
  },
  checked: {},
})(Radio);

export const PaymentWidgetStepSelectPaymentMethod = () => {
  const {
    storedPaymentOptions,
    selectedPaymentMethod,
    setStep,
    updateAccountDetails,
    updatePaymentMethodDetails,
    setIsPersistSelectedPaymentMethod,
    setIsRefreshPaymentMethods,
    setSelectedPaymentMethod,
  } = useContext(PaymentWidgetContext);

  const isLoading = useRef(false);

  const [tempSelectedPaymentMethod, setTempSelectedPaymentMethod] = useState(selectedPaymentMethod);
  const [isOpen, setIsOpen] = useState(false);

  const handleBackButton = async () => {
    setStep && setStep(PaymentWidgetPaymentSteps.INVIGO_CREDIT_INFO);
  };

  const handlePaymentPopover = () => {
    setIsOpen(open => !open);
  };

  const handleNextClick = async () => {
    if (!tempSelectedPaymentMethod) {
      return;
    }

    isLoading.current = true;

    let updateReq: UpdateAccountRequest = {
      isInvigoDefault: true, // Set Auto Pay to true
    };

    const {PaymentAccountGUID, SupplierPaymentOptionID} = tempSelectedPaymentMethod;

    if (PaymentAccountGUID) {
      if (updateAccountDetails) {
        await updateAccountDetails(PaymentAccountGUID, updateReq);
      }
    } else {
      if (SupplierPaymentOptionID && updatePaymentMethodDetails) {
        updatePaymentMethodDetails(SupplierPaymentOptionID.toString(), updateReq);
      }
    }

    setSelectedPaymentMethod && setSelectedPaymentMethod(tempSelectedPaymentMethod);
    setIsPersistSelectedPaymentMethod && setIsPersistSelectedPaymentMethod(true);
    setIsRefreshPaymentMethods && setIsRefreshPaymentMethods(true);
    setStep && setStep(PaymentWidgetPaymentSteps.NEW);

    isLoading.current = false;
  };

  const body = (
    <div className="h-97 overflow-y-auto p-4 font-poppins">
      {isOpen && <AddPaymentMethodPopOver bottom="4.5rem" />}

      {storedPaymentOptions
        ?.filter(pm => pm.PaymentAccountVerificationStatus === VerificationStatus.VERIFIED)
        ?.map(pm => (
          <PaymentMethodSlide
            key={pm.SupplierPaymentOptionID}
            pm={pm}
            isSelected={pm.SupplierPaymentOptionID === tempSelectedPaymentMethod?.SupplierPaymentOptionID}
            onChangePaymentMethod={() => setTempSelectedPaymentMethod(pm)}
          />
        ))}
    </div>
  );

  const footer = (
    <>
      <SecondaryButton onClick={handleBackButton} label="Back" disabled={isLoading.current} />
      <SecondaryButton onClick={handlePaymentPopover} label="+" />
      <PrimaryButton
        onClick={handleNextClick}
        label="Next"
        disabled={isLoading.current}
        isSubmitting={isLoading.current}
      />
    </>
  );

  return <PaymentWidgetStep title={'Select a payment method'} body={body} footer={footer} />;
};

interface IPaymentMethodSlide {
  isSelected: boolean;
  pm: ITenantSupplierPaymentMethod;
  onChangePaymentMethod: () => void;
  showFriendlyName?: boolean;
}

export const PaymentMethodSlide = ({
  pm,
  isSelected,
  onChangePaymentMethod,
  showFriendlyName = true,
}: IPaymentMethodSlide) => {
  const isBankTransfer = pm.PaymentMethod.toUpperCase() === 'BANKTRANSFER';

  let bgGradientColor = '#dbdbdb';
  let fontColor = 'text-white';
  let paymentMethodIcon = undefined;

  const getBankTransferIconAndColor = () => {
    switch (pm.BankName) {
      case BankAccounts.NAB:
        bgGradientColor = 'linear-gradient(to right, #484C4F , #090F0F)';
        paymentMethodIcon = <IconNAB width="77px" height="47px" />;
        break;
      case BankAccounts.ANZ:
        bgGradientColor = 'linear-gradient(to right, #007DBA , #004165)';
        paymentMethodIcon = <IconANZ width="69px" height="45px" />;
        break;
      case BankAccounts.CBA:
        bgGradientColor = 'linear-gradient(to right, #216F86 , #1B3C41)';
        paymentMethodIcon = <IconCommonWealth width="66px" height="43px" />;
        break;
      case BankAccounts.WESTPAC:
        bgGradientColor = 'linear-gradient(to right, #DA1710 , #630202)';
        paymentMethodIcon = <IconWestpac width="65px" height="41px" />;
        break;
      default:
        bgGradientColor = 'linear-gradient(to right, #74B9D3 , #1F7290)';
        paymentMethodIcon = <BankWhite width="34px" height="40px" />;
        break;
    }
  };

  switch (pm.PaymentMethod.toUpperCase()) {
    case 'VISA':
      bgGradientColor = 'linear-gradient(to right, #2064CD , #16419E)';
      paymentMethodIcon = <Visa width="60px" height="42px" />;
      break;
    case 'MASTERCARD':
      bgGradientColor = 'linear-gradient(145deg, rgba(37,55,69,1) 0%, rgba(66,81,95,1) 67%)';
      paymentMethodIcon = <MasterCardNew width="54px" height="43px" />;
      break;
    case 'INVIGOPAYLATER':
      bgGradientColor = '#2F97BC';
      paymentMethodIcon = <SpendaPayLater height="50px" />;
      break;
    case 'BANKTRANSFER':
      getBankTransferIconAndColor();
      break;
  }

  return (
    <div className="pb-1">
      <div
        className="flex cursor-pointer"
        onClick={onChangePaymentMethod}
        data-autoid={`btnPm${pm.PaymentAccountGUID}`}
      >
        <DarkBlueRadio checked={isSelected} inputProps={{'aria-label': 'A'}} />
        <div
          className={`h-13 flex flex-1 items-center justify-between rounded-lg px-2.5 font-courierNew text-xl ${fontColor}`}
          {...css({background: bgGradientColor})}
        >
          {isBankTransfer ? (
            <div className="text-left font-courierNew text-base font-bold">
              <p>BSB: {pm.BankBSB}</p>
              <p>ACC: {pm.BankAccountNumber}</p>
            </div>
          ) : (
            <p className="tracking-widest">•••• {pm.Last4}</p>
          )}
          <div className={`${cardIconbgCss}`}>
            <span>{paymentMethodIcon}</span>
          </div>
        </div>
      </div>
      {showFriendlyName ? <p className="text-xs">{pm.FriendlyName}</p> : null}
    </div>
  );
};
