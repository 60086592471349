import React from 'react';
import {Dialog} from '@material-ui/core';
import {AddAccountCustomerCmp} from '../../screens/customers/AddAccountCustomer';
export interface ILinkedOperationalCustomerDialogProps {
  open: boolean;
  onClose: () => void;
  customerID: number;
}

export const LinkedOperationalCustomerDialog = (props: ILinkedOperationalCustomerDialogProps) => {
  const {open, onClose, customerID} = props;

  return (
    <Dialog open={open} maxWidth="lg" onClose={onClose}>
      <AddAccountCustomerCmp
        onCancelBtnClick={() => onClose()}
        customerID={customerID.toString()}
        scope="AR"
        onSuccess={() => onClose()}
      />
    </Dialog>
  );
};
