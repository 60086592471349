import {makeStyles, createStyles} from '@material-ui/core';
import React, {useContext, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {STextField} from '../../../components/inputs/STextField';
import {PaymentWidgetContext, PaymentWidgetPaymentSteps, PaymentWidgetStep} from './PaymentWidget';
import {PriceFormat} from '../../../utils/formatter';
import {IconPrepayment} from '../../../assets/svg/IconPrepayment';
import {Button} from 'spenda-ui-react';

const useStyles = makeStyles(() =>
  createStyles({
    inputField: {
      position: 'relative',
      '& .MuiOutlinedInput-input:-webkit-autofill': {
        '-webkit-box-shadow': '0 0 0 100px #fff inset !important',
      },
      '& .MuiInputAdornment-root': {
        display: 'none',
      },
      '& .MuiOutlinedInput-root': {
        padding: '8px 14px',
        height: '100%',
        '&.Mui-focused': {
          '& .MuiInputAdornment-root': {
            display: 'contents',
          },
        },
      },
      '& .MuiInputLabel-outlined': {
        transform: 'translate(14px, 16px) scale(1)',
        fontFamily: 'Poppins !important',
        fontSize: '14px',
        fontWeight: 500,
        width: 'auto',
        color: '#bbbbbb !important',
      },
      '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        transform: 'translate(14px, -4.7px) scale(1)',
        fontSize: '10px ',
      },
      '& textarea, input': {
        padding: '0px',
        fontFamily: 'Poppins !important',
        fontSize: '14px',
      },
      '& .MuiFormHelperText-root.Mui-error': {
        fontFamily: 'Poppins !important',
        position: 'relative !important',
        top: '0px !important',
      },
      '& .MuiInputAdornment-positionEnd button': {
        padding: '0px !important',
      },
      '& .Mui-focused .MuiOutlinedInput-notchedOutline': {borderColor: 'hsl(var(--primary)) !important'},
    },
    descriptionFiled: {
      paddingBottom: '0px!important',
      '& .MuiOutlinedInput-multiline': {
        backgroundColor: '#ffffff!important',
        borderColor: '#333',
      },
      position: 'relative',
      '& .MuiOutlinedInput-input:-webkit-autofill': {
        '-webkit-box-shadow': '0 0 0 100px #fff inset !important',
      },
      '& .MuiInputAdornment-root': {
        display: 'none',
      },
      '& .MuiOutlinedInput-root': {
        padding: '10px 10px',
        height: '100%',
        '&.Mui-focused': {
          '& .MuiInputAdornment-root': {
            display: 'contents',
          },
        },
      },
      '& .MuiInputLabel-outlined': {
        transform: 'translate(14px, 16px) scale(1)',
        fontFamily: 'Poppins !important',
        fontSize: '14px',
        fontWeight: 500,
        width: 'auto',
        color: '#bbbbbb !important',
      },
      '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        transform: 'translate(14px, -4.7px) scale(1)',
        fontSize: '11px ',
      },
      '& textarea, input': {
        padding: '0px',
        fontFamily: 'Poppins !important',
        fontSize: '14px',
      },
      '& .MuiFormHelperText-root.Mui-error': {
        fontFamily: 'Poppins !important',
        position: 'relative !important',
        top: '0px !important',
      },
      '& .MuiInputAdornment-positionEnd button': {
        padding: '0px !important',
      },

      '& .MuiOutlinedInput-input': {
        height: '50px',
      },
      '& .MuiOutlinedInput-notchedOutline': {borderColor: '#333 !important'},
      '& .Mui-focused .MuiOutlinedInput-notchedOutline': {borderColor: 'hsl(var(--primary)) !important'},
    },
  }),
);

export const PaymentWidgetStepCreatePrepayment = () => {
  const {
    setStep,
    marketplaceSupplier,
    nonInvoicePaymentAmount,
    setNonInvoicePaymentAmount,
    paymentDescription,
    setPaymentDescription,
  } = useContext(PaymentWidgetContext);
  const history = useHistory();
  const classes = useStyles();
  const handleHome = () => {
    history.push(`/supplier/${marketplaceSupplier?.SupplierID}/bills`);
  };

  const [prepaymentError, setPrepaymentAmtError] = useState(false);

  const handlePrepaymentAmtError = () => {
    if (nonInvoicePaymentAmount <= 0 && !prepaymentError) {
      setPrepaymentAmtError(true);
    } else if (nonInvoicePaymentAmount > 0 && prepaymentError) {
      setPrepaymentAmtError(false);
    }
  };

  const handleNext = () => {
    if (nonInvoicePaymentAmount > 0) {
      setStep?.(PaymentWidgetPaymentSteps.NEW);
    } else {
      handlePrepaymentAmtError();
    }
  };

  const title = null;
  const body = (
    <div className="px-5 font-poppins">
      <div className="mt-12 flex justify-center pt-1">
        <IconPrepayment width="96px" height="96px" />
      </div>
      <div className="flex h-32 flex-col justify-center">
        <h6 className=" text-xxl font-light">Create a payment for</h6>
        <h6 className=" text-xxl font-light">{marketplaceSupplier?.TenantName}</h6>
      </div>
      <p className="pb-5 text-xs font-normal">
        This payment won't be allocated to
        <br />
        any invoices until you choose to include
        <br />
        it in an invoice batch.
      </p>

      <div className={`${classes.inputField} py-2`}>
        <STextField
          v2
          size="small"
          height="45px"
          backgroundColor="#FFF"
          borderColor="#333"
          variant="outlined"
          name="amount"
          label={prepaymentError ? 'Amount must be greater than 0 ' : 'Amount'}
          onBlur={handlePrepaymentAmtError}
          value={PriceFormat(nonInvoicePaymentAmount)}
          error={prepaymentError}
          onChange={e => {
            let val = parseInt(e.target.value.replace(/\D/g, '')) / 100;
            val = isNaN(val) ? 0 : val;
            if (val > 0 && prepaymentError) {
              setPrepaymentAmtError(false);
            }
            setNonInvoicePaymentAmount?.(val);
          }}
          fullWidth
          labelColor={prepaymentError ? '#FF0000' : '#333'}
        />
      </div>
      <div className={`py-2`}>
        <STextField
          fullWidth
          id="description"
          name="description"
          variant="outlined"
          label="Description"
          rows={3}
          multiline
          data-autoid="txtPrepaymentDescription"
          className={`${classes.descriptionFiled}`}
          inputProps={{style: {fontFamily: 'Poppins'}}}
          autoComplete="off"
          value={paymentDescription}
          onChange={e => {
            if (e.target.value.length <= 100) setPaymentDescription?.(e.target.value);
          }}
        />
      </div>
    </div>
  );
  const footer = (
    <>
      <Button className="bg-white" variant="outlined" onClick={handleHome} data-autoid="btnCancel">
        Cancel
      </Button>
      <Button onClick={handleNext} data-autoid="btnNext">
        Next
      </Button>
    </>
  );
  return <PaymentWidgetStep title={title} body={body} footer={footer} />;
};
