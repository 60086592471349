import React, { useContext, useEffect, useState} from 'react';
import {Formik, FormikProps, getIn} from 'formik';
import * as Yup from 'yup';
import {isEmpty} from 'lodash';

import {SButton} from '../buttons/Button';
import {STextField} from '../inputs/STextField';
import {SModal} from '../modals/modalSpendaMeterialUI';
import {usePaymentBatchAPI} from '../../services/usePaymentBatchAPI';
import {AlertDialogSlide} from '../../components/dialog/AlertDialogSlide';
import {SendRemittanceSelectSupplier} from './SendRemittanceSelectSupplier';
import {IPaymentBatchCreditLine} from '../../model/payment-batch/PaymentBatch';
import {Toast} from '../../utils/Toast';

import {
  Checkbox,
  FormControlLabel,
  InputBase,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Tick from '../../assets/svg/accounts-payable/remittenceSelectChq.svg';
import UnTick from '../../assets/svg/accounts-payable/remittenceBlankChq.svg';
import addEmailIcon from '../../assets/svg/accounts-payable/addEmail.svg';
import { Switch, Typography } from 'spenda-ui-react';
import AppContext from '../../context/app/appContext';
import { ITenantUserDetails } from '../../model/Tenant';


const useSendRemittanceAdviceModalStyles = makeStyles({
  apOnboardingModal: {
    maxWidth: '1100px',
    height: '660px',
  },
  apSendRemittanceHeader: {
    borderBottom: '1px solid #CCCCCC',
    '& h2': {
      fontFamily: 'Poppins, sans-serif !important',
      fontWeight: 300,
      fontSize: '28px',
      color: '#333333',
    },
  },
  allSelectChq: {
    '& .MuiIconButton-label': {
      padding: '0px',
    },
    '& .MuiTypography-root': {
      marginleft: '10px',
      fontFamily: 'poppins',
      fontWeight: 500,
      color: '#333',
    },
  },
  invoicesTable: {
    borderRadius: '8px 8px 0px 0px',
    height: '384px',
    '&::-webkit-scrollbar': {
      width: '4px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#EAEFF2',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#CCCCCC',
    },
    '& table': {
      borderCollapse: 'separate',
      borderSpacing: 0,
      borderBottom: '1px solid #F8F8F8',
      '& th': {
        color: '#999999',
        fontWeight: 600,
        fontSize: '14px',
        fontFamily: 'Poppins',
        width: '180px',
      },
      '& .MuiTableHead-root': {
        background: '#F1F1F1',
      },
      '& .MuiTableCell-root': {
        borderBottom: '1px solid #F1F1F1',
        padding: '8px 20px',
        '& .MuiCheckbox-root': {
          padding: '0px',
        },
      },
      '& .MuiTableCell-body': {
        padding: '8px 20px',
        '&:nth-child(odd)': {
          background: '#FAFAFA',
        },
      },
    },
  },
  emailBody: {
    paddingBottom: '0px!important',
    '& .MuiOutlinedInput-multiline': {
      padding: '7px 10px',
      backgroundColor: '#ffffff!important',
    },
    '& .MuiOutlinedInput-input': {
      height: '368px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#333333!important',
    },
    '& .MuiFormLabel-root': {
      color: ' #333!important',
      fontSize: '14px',
      fontFamily: 'Poppins, sans-serif !important',
    },
    '& textarea': {
      color: ' #333!important',
      fontSize: '14px',
      fontFamily: 'Poppins, sans-serif !important',
      ' -webkit-text-fill-color': '#8d8d8d !important',
      padding: '6px 0px',
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #333',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      fontSize: '13px',
      transform: 'translate(11px, -4px) scale(0.75)',
    },
    '& fieldset legend': {fontSize: '9px !important', fontFamily: 'poppins'},
    '& .MuiFormHelperText-root': {
      top: '55px !important',
    },
  },
  emailInput: {
    color: '#333333',
    fontSize: '12px',
  },
  inputFocused: {
    border: '1px solid #1C78AD !important',
  },
  inputError: {
    border: '1px solid #f44336 !important',
  },
  discardFooter: {
    maxWidth: '1033px',
    background: 'rgb(173 173 173 / 20%)',
    borderRadius: '8px',
  },
});

const validationSchema = Yup.object({
  EmailMessage: Yup.string().required('Email Message is required.').max(500, 'Email Message max 500 char.'),
  credits: Yup.array().of(
    Yup.object().shape({
      emailAddress: Yup.string().when(['selected'], (selected: boolean | undefined, schema: any) => {
        return Boolean(selected)
          ? schema.email().required('Email Address is required.').max(100)
          : schema.email().max(100).nullable(true);
      }),
    })
  ),
});

interface IPaymentBatchCreditLineEdit extends IPaymentBatchCreditLine {
  selected?: boolean;
}

interface ISendBulkRemittance {
  credits: IPaymentBatchCreditLineEdit[];
  EmailMessage: string;
}

export interface ISendRemittanceAdviceProps {
  credits: IPaymentBatchCreditLineEdit[];
  handleClose: (refresh?: boolean) => void;
}

const initialValues: ISendBulkRemittance = {
  credits: [],
  EmailMessage: `Dear <SupplierName>,\n\nWe have paid <PaidAmount> batch <BatchName> with ref id. <BatchID> and description <DescriptionDetail> on <PaymentDate>.`,
};

export const SendRemittanceAdviceModal = (props: ISendRemittanceAdviceProps) => {
  const classes = useSendRemittanceAdviceModalStyles();
  const {credits, handleClose} = props;
  const {saveAccountsPayablePayees, sendBulkAdviceToSuppliers, sendCopyToggle, isLoading} = usePaymentBatchAPI();

  const formRef = React.useRef<FormikProps<ISendBulkRemittance>>(null);

  const [edit, setEdit] = useState<any>(undefined);
  const [initValues, setInitValues] = useState<ISendBulkRemittance>(initialValues);
  const [sendRemittance, setsendRemittance] = useState<boolean>(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState<boolean>(false);
  const { tenantInfo, setTenantInfo} = useContext(AppContext);
  const [isToggle, setIsToggle] = useState(false);

  useEffect(() => {
    setIsToggle(tenantInfo?.TenantUserDetails.ShouldSendPaymentAdviceToUser!)
    setInitValues({
      credits: credits.map(credit => ({...credit, selected: false})),
      EmailMessage: initialValues.EmailMessage,
    });
  }, []);

  const handleSendRemittance = () => {
    setsendRemittance(true);
  };

  const handleSendRemittanceClose = () => {
    setsendRemittance(false);
  };

  const handleSelect = (
    event: React.ChangeEvent<HTMLInputElement>,
    values: ISendBulkRemittance,
    index: number,
    setValues: (values: React.SetStateAction<ISendBulkRemittance>, shouldValidate?: boolean | undefined) => void
  ) => {
    const _credits = [...values.credits];
    if (index < 0) {
      _credits.forEach(credit => {
        if (event.target.checked) {
          credit.selected = true;
        } else {
          credit.selected = false;
        }
      });
    } else {
      if (event.target.checked) {
        _credits[index].selected = true;
      } else {
        _credits[index].selected = false;
      }
    }
    setValues({
      ...values,
      credits: _credits,
    });
  };

  const handleSaveClose = async () => {
    try {
      if (formRef?.current?.validateForm) {
        const err: any = await formRef.current.validateForm();
        if (isEmpty(err)) {
          await savePayees(formRef?.current?.values);
          Toast.info('Supplier Details saved');
          handleClose(true);
        } else {
          Toast.info('Please check email addresses');
          setOpenConfirmationDialog(false);
        }
      }
    } catch (err) {}
  };

  const savePayees = async (values?: ISendBulkRemittance): Promise<void> => {
    if (values) {
      const payload = values.credits
        .filter(credit => credit.emailAddress)
        .map(credit => ({
          payeeID: credit.payeeID,
          emailAddress: credit.emailAddress,
        }));
      const res = await saveAccountsPayablePayees(payload);
      if (res.code) {
        Toast.error('Something went wrong');
        throw new Error('Save Payee Error');
      }
    }
  };

  const onSubmit = async (values: ISendBulkRemittance): Promise<void> => {
    await savePayees(values);
    const TransactionGUIDs = values.credits.map(credit => credit.transactionGUID) as string[];
    return sendBulkAdviceToSuppliers({TransactionGUIDs, EmailMessage: values.EmailMessage}).then(res => {
      if (res.code) {
        Toast.error('Something went wrong');
        throw new Error('Send Remittance Error');
      }
      Toast.info('Remittance Advices sent');
      handleClose(true);
    });
  };

  const handleToggleChange = async (): Promise<void> => {
    setIsToggle(!isToggle)
    try{
        await sendCopyToggle(!isToggle);
        if (typeof tenantInfo != 'undefined' && typeof tenantInfo?.TenantUserDetails != 'undefined') {
          const newObject: ITenantUserDetails = {
            ...tenantInfo.TenantUserDetails,
            ShouldSendPaymentAdviceToUser: !isToggle,
          };
          setTenantInfo({...tenantInfo, TenantUserDetails: newObject});
        }
      }
    catch(error){
      console.error('Error toggling send copy setting:', error);
    }
}

  return (
    <>
      {openConfirmationDialog && (
        <AlertDialogSlide
          maxWidth="xs"
          title="Discard Changes"
          footer={
            <div className="flex justify-between w-full px-4 py-2">
              <SButton
                margin="0 0.25rem"
                backgroundColor={'white'}
                border={'1px solid #C55D44'}
                textColor={'#C55D44'}
                lineHeight="l"
                width="110px"
                height="40px"
                boxShadow="none"
                fontFamily="poppins"
                fontSize="14px"
                onClick={() => handleClose()}
                data-autoid={`btnDiscard&Close`}
              >
                Discard & Close
              </SButton>
              <SButton
                margin="0 0.25rem"
                color="blueShade"
                textColor="white"
                lineHeight="l"
                width="110px"
                height="40px"
                boxShadow="none"
                fontFamily="poppins"
                fontSize="14px"
                type="button"
                onClick={handleSaveClose}
                data-autoid={`btnSave&Close`}
              >
                Save & Close
              </SButton>
            </div>
          }
        >
          <p className="pb-3 font-medium text-center font-poppins">
            You have unsaved changes, are you sure you want to discard changes?
          </p>
        </AlertDialogSlide>
      )}
      {sendRemittance ? (
        <SendRemittanceSelectSupplier
          isSubmitting={isLoading}
          supplierCount={formRef?.current?.values.credits.filter(v => v.selected).length || 0}
          handleSend={formRef?.current?.handleSubmit}
          handleClose={handleSendRemittanceClose}
        />
      ) : null}
      <SModal open>
        <div className={`text-xl spenda-color flex justify-center items-center h-full max-w-full`}>
          <Formik
            enableReinitialize
            innerRef={formRef}
            validationSchema={validationSchema}
            initialValues={initValues}
            onSubmit={onSubmit}
          >
            {({values, handleChange, setValues, errors, touched, dirty, validateForm}) => (
              <div className={`px-5 pt-3 pb-5 modal-box ${classes.apOnboardingModal}`}>
                <div className={`border-b border-[#ccc] flex justify-between -mx-5 px-3 pb-3`}>
                  <Typography variant="h1" className={`font-light text-center w-full text-black-800`}>
                    Send Bulk Remittance
                  </Typography>
                  <div className="flex justify-end text-right">
                    <span className="ap-onboarding-close-icon">
                      <CloseIcon
                        onClick={() => (dirty ? setOpenConfirmationDialog(true) : handleClose())}
                        data-autoid="btnClose"
                      />
                    </span>
                  </div>
                </div>
                <div className="w-full mt-6">
                  <div className={`${classes.allSelectChq} flex justify-between items-center w-full mb-3`}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.credits.every(v => v.selected)}
                          onChange={event => handleSelect(event, values, -1, setValues)}
                          icon={<img src={UnTick} />}
                          checkedIcon={<img src={Tick} />}
                          data-autoid="chkSelectSupplier"
                        />
                      }
                      label={`Select suppliers(${values.credits.length})`}
                    />
                    <Switch
                      color="teal"
                      label="Send me a copy"
                      checked={isToggle}
                      onChange={handleToggleChange}
                      labelProps={{className: 'font-medium text-spenda-primarytext leading-5 font-poppins text-sm'}}
                      rootContainerProps={{className: 'flex flex-row-reverse gap-2'}}
                    />
                  </div>
                  <div className="flex w-full gap-7">
                    <TableContainer className={`${classes.invoicesTable} overflow-y-auto w-[55%]`}>
                      <Table data-autoid="tblRemittanceAdvice" aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell align="left" style={{padding: '8px 0px'}}></TableCell>
                            <TableCell align="left">Payee</TableCell>
                            <TableCell align="left">Email</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {values.credits.map((credit, index) => (
                            <TableRow key={credit.payeeID}>
                              <TableCell
                                width="4%"
                                className="text-black-800"
                                style={{backgroundColor: 'transparent', padding: '8px 0px'}}
                              >
                                <Checkbox
                                  checked={Boolean(credit.selected)}
                                  onChange={event => handleSelect(event, values, index, setValues)}
                                  icon={<img src={UnTick} />}
                                  checkedIcon={<img src={Tick} />}
                                  name="selectAll"
                                  data-autoid={`chkSelectAll-${credit.supplierID}`}
                                />
                              </TableCell>
                              <TableCell className="text-black-800">
                                <span className="text-base font-medium text-black-800 font-poppins">
                                  {credit.supplierName}
                                </span>
                              </TableCell>
                              <TableCell
                                className={`text-black-800 ${edit === credit.payeeID ? classes.inputFocused : ''}
                            ${Boolean(getIn(errors, `credits.${index}.emailAddress`)) ? classes.inputError : ''}`}
                              >
                                {edit !== credit.payeeID && !credit.emailAddress ? (
                                  <img
                                    src={addEmailIcon}
                                    onClick={() => setEdit(credit.payeeID)}
                                    className="cursor-pointer"
                                  />
                                ) : null}
                                {edit !== credit.payeeID && credit.emailAddress ? (
                                  <span
                                    className="text-base font-medium cursor-pointer text-black-800 font-poppins"
                                    onDoubleClick={() => setEdit(credit.payeeID)}
                                  >
                                    {credit.emailAddress}{' '}
                                  </span>
                                ) : null}
                                {edit === credit.payeeID ? (
                                  <InputBase
                                    autoFocus
                                    fullWidth
                                    id={`credits.${index}.emailAddress`}
                                    name={`credits.${index}.emailAddress`}
                                    className={classes.emailInput}
                                    placeholder="Enter your email"
                                    data-autoid={`txtAddEmail-${credit.supplierID}`}
                                    value={credit.emailAddress || null}
                                    onChange={handleChange}
                                    inputProps={{style: {fontFamily: 'Poppins'}}}
                                  />
                                ) : null}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <div className="w-[45%]">
                      <STextField
                        fullWidth
                        id="EmailMessage"
                        name="EmailMessage"
                        variant="outlined"
                        label="Email body"
                        rows={10}
                        multiline
                        value={values.EmailMessage}
                        onChange={handleChange}
                        className={`${classes.emailBody}`}
                        helperText={touched?.EmailMessage && errors?.EmailMessage}
                        error={touched?.EmailMessage && Boolean(errors?.EmailMessage)}
                        data-autoid="txtEmailBody"
                        inputProps={{style: {fontFamily: 'Poppins'}}}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className={`${classes.discardFooter} w-full flex justify-between items-center absolute bottom-5 left-0 p-2 mx-8 `}
                >
                  <SButton
                    textColor={'blueShade'}
                    width="72px"
                    height="40px"
                    lineHeight="l"
                    fontSize="xs"
                    backgroundColor={'#fff'}
                    margin="0px "
                    variant={'outlined'}
                    border={'1px solid #1C78AD'}
                    borderRadius="6px"
                    data-autoid="btnCancel"
                    fontFamily="poppins"
                    onClick={() => (dirty ? setOpenConfirmationDialog(true) : handleClose())}
                  >
                    Cancel
                  </SButton>
                  <SButton
                    textColor={'white'}
                    width="130px"
                    height="40px"
                    lineHeight="l"
                    fontSize="xs"
                    backgroundColor="#1C78AD"
                    margin="0px"
                    variant={'outlined'}
                    border={'1px solid #1C78AD'}
                    borderRadius="6px"
                    data-autoid="btnSendToSelectedSupplier"
                    fontFamily="poppins"
                    disabled={!Boolean(values.credits.filter(v => v.selected).length)}
                    onClick={() => validateForm().then(err => isEmpty(err) && handleSendRemittance())}
                  >
                    Send to selected supplier({values.credits.filter(v => v.selected).length})
                  </SButton>
                </div>
              </div>
            )}
          </Formik>
        </div>
      </SModal>
    </>
  );
};
