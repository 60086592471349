import {useEffect, useState} from 'react';
import {DatTypes} from '../model/constants/Constants';
import {InventoryClass} from '../model/inventory/InventoryClass';
import {IActionResults, IPagedActionResults, IDashboardActionResults} from '../model/ActionResults';
import {IProductsFilter} from '../model/search-filters/ProductsFilter';
import useHttp from '../hooks/useHttp';

export const useInventoryClassAPI = () => {
  const {GET, PUT, POST, DELETE, isLoading} = useHttp();

  const dashboard = (filter: any): Promise<IDashboardActionResults<InventoryClass[]>> => {
    return GET('dashboard', filter).then((data: IDashboardActionResults<InventoryClass[]>) => data);
  };

  const listInventoryClass = (filter: any): Promise<IPagedActionResults<InventoryClass[]>> => {
    return GET('inventoryclass', filter).then((data: IPagedActionResults<InventoryClass[]>) => data);
  };

  const createInventoryClass = (payload: Partial<InventoryClass>): Promise<IActionResults<InventoryClass>> => {
    return POST('inventoryclass', {Object: payload}).then((data: IActionResults<InventoryClass>) => data);
  };

  const getInventoryClassById = (ID: string): Promise<InventoryClass> => {
    return GET(`inventoryclass/${ID}`).then((data: IActionResults<InventoryClass>) => data.Value);
  };

  const updateInventoryClass = (payload: Partial<InventoryClass>): Promise<IActionResults<InventoryClass>> => {
    return PUT(`inventoryclass/${payload.ID}`, {Object: payload}).then((data: IActionResults<InventoryClass>) => data);
  };

  const deleteInventoryClass = (ID: string): Promise<IActionResults<InventoryClass>> => {
    return DELETE(`inventoryclass/${ID}`).then((data: IActionResults<InventoryClass>) => data);
  };

  return {
    dashboard,
    listInventoryClass,
    createInventoryClass,
    getInventoryClassById,
    updateInventoryClass,
    deleteInventoryClass,
    isLoading,
  };
};

export const useLoadInventoryClass = () => {
  let pageSize = 15;

  const initialFilterState = {
    DatTypeID: DatTypes.InventoryClass,
    IsExactMatch: false,
    IsShowActiveOnly: true,
    LastRowNumber: 0,
    MaxResults: pageSize,
    SortAsc: false,
    SortField: 'CreatedDateTime_utc',
  } as IProductsFilter;

  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<InventoryClass[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [moreToGet, setMoreToGet] = useState<boolean>(true);
  const [error, setError] = useState<any>();
  const [searchFilter, setSearchFilter] = useState<IProductsFilter>(initialFilterState);

  const {dashboard} = useInventoryClassAPI();

  const getInventoryClassesList = async () => {
    setLoading(true);

    if (searchFilter.StartRow === 1) {
      setItems([]);
      setMoreToGet(true);
    }

    try {
      const {Value, IsMore, TotalRecordCount, LastRowNo} = await dashboard(searchFilter);
      let items: InventoryClass[] = Value || [];

      setItems(items);
      setTotalCount(TotalRecordCount);
      setMoreToGet(IsMore);
      setCurrentPage(Math.ceil(LastRowNo / (searchFilter.MaxResults || pageSize)));
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getInventoryClassesList();
  }, [searchFilter]);

  return {
    loading,
    items,
    totalCount,
    pageSize: searchFilter.MaxResults || pageSize,
    currentPage,
    moreToGet,
    error,
    searchFilter,
    getInventoryClassesList,
    setSearchFilter,
  };
};
