import React from 'react';

export const Upload = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="18px" height="18px" viewBox="0 0 100 100" {...props}>
      <g fill="#1C78AD" fillRule="evenodd">
        <path d="m45.862 10.743-.029 64.325a4.167 4.167 0 1 0 8.333 0l.03-64.254 12.133 12.137a4.167 4.167 0 0 0 5.892-5.892L58.84 3.665a12.5 12.5 0 0 0-17.683 0L27.78 17.047a4.167 4.167 0 0 0 5.892 5.892l12.191-12.196Z" />
        <path d="M91.667 70.835V87.5a4.167 4.167 0 0 1-4.167 4.167h-75a4.167 4.167 0 0 1-4.167-4.167V70.835a4.167 4.167 0 1 0-8.333 0V87.5c0 6.904 5.596 12.5 12.5 12.5h75c6.904 0 12.5-5.596 12.5-12.5V70.835a4.167 4.167 0 1 0-8.333 0Z" />
      </g>
    </svg>
  );
};
