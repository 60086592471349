import React from 'react';
import {ARDialogTemplate} from '../ARDialogTemplate';
import {Button, Typography} from 'spenda-ui-react';

interface IDeleteClaimModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  isSubmitting: boolean;
}

export const DeleteClaimModal = (props: IDeleteClaimModalProps) => {
  //Props
  const {isOpen, onClose, onConfirm} = props;

  return (
    <ARDialogTemplate
      dialogProps={{size: 'xs', open: isOpen, className: '!min-w-[460px] !max-w-[460px]'}}
      isFullScreen
      header={<p className=" font-poppins text-xl font-light text-[#333333]">Delete Claim</p>}
      body={
        <div className="my-12">
          <Typography className="text-center font-light text-black-800" variant="paragraph">
            Once the claim is deleted, you will not be able to edit it any further. Are you sure you want to delete the
            claim now?
          </Typography>
        </div>
      }
      footer={
        <div className="flex w-full flex-row items-center justify-between">
          <Button
            data-autoid={`btnCancel`}
            variant="outlined"
            color="primary"
            className="bg-white"
            onClick={() => onClose()}
          >
            Cancel
          </Button>
          <Button data-autoid={`btnDelete`} variant="filled" color="error" onClick={() => onConfirm()}>
            Delete
          </Button>
        </div>
      }
      handleClose={() => onClose()}
    />
  );
};
