import React, {useState, useEffect} from 'react';
import {useHistory, Link as RouterLink} from 'react-router-dom';
import {makeStyles} from '@material-ui/core';
import {Button} from 'spenda-ui-react';
import {omit} from 'lodash';
import {css} from 'glamor';

import {
  AUTH_CUSTOMER_OPERATIONAL_CUSTOMERS_ADD,
  AUTH_CUSTOMER_OPERATIONAL_CUSTOMERS_EDIT,
} from '../../routes/CustomerRoutes';
import {LinkOperationalCustomerDialog} from '../../components/dialog/LinkOperationalCustomerDialog';
import {Layout} from '../../components/layout/Layout';
import {STable} from '../../components/tables/STable';
import {SPagination} from '../../components/tables/SPagination';
import {ICustomer} from '../../model/customer/Customer';
import {useLoadCustomers, useCustomerLinking, useCustomersAPI} from '../../services/useCustomersAPI';
import {OperationalCustomerToolbar} from '../../components/customer/CustomerToolbar';
import {useCustomerClassesAPI} from '../../services/useCustomerClassesAPI';
import {ICreateCustomerClassValues} from '../../model/customer/CustomerClasses';
import {UnlinkDialog} from '../../components/dialog/UnlinkDialog';

const useOperationalCustomersStyles = makeStyles(() => ({
  root: {
    maxHeight: 'calc( 100vh - 20.5em )',
  },
  title: {
    fontWeight: 500,
    fontSize: '1.5em',
    color: '#4D4D4D',
  },
}));

const columnsRender = (
  setCustomerId: React.Dispatch<React.SetStateAction<undefined>>,

  toggleCompanyDetailsDialog: (f: boolean) => void,
  unlinkCustomer: (row: ICustomer) => void,
  _getCustomersList: () => void,
  _editIconClick: (row: ICustomer) => void,
  setRemoveClass: React.Dispatch<React.SetStateAction<boolean>>,
) => ({
  CompanyName: {
    disabled: true,
    className: 'ellpsisCol',
    title: 'Company/Customer Name',
    field: 'CompanyName',
    render: (rowData: ICustomer) => {
      if (rowData.CompanyName || rowData.PrimaryContactFullName) {
        return (
          <div {...css({position: 'relative'})}>
            <p {...css({lineHeight: '1.357', color: '#999999', fontWeight: 500, fontSize: '0.875em'})}>
              {rowData.CompanyName}
            </p>
            <p>{rowData.PrimaryContactFullName}</p>
          </div>
        );
      } else {
        return (
          <div {...css({position: 'relative'})}>
            <p {...css({lineHeight: '1.357', color: '#999999', fontWeight: 500, fontSize: '0.875em'})}>{' - '}</p>
          </div>
        );
      }
    },
  },
  Phone1: {
    title: 'Contact Info',
    field: 'Phone1',
    render: (rowData: ICustomer) => {
      if (rowData.Phone1 || rowData.PrimaryContactEmailAddress) {
        return (
          <div {...css({position: 'relative'})}>
            <p {...css({lineHeight: '1.357', color: '#999999', fontWeight: 500, fontSize: '0.875em'})}>
              {rowData.Phone1}
            </p>
            <p>{rowData.PrimaryContactEmailAddress}</p>
          </div>
        );
      } else {
        return (
          <div {...css({position: 'relative'})}>
            <p {...css({lineHeight: '1.357', color: '#999999', fontWeight: 500, fontSize: '0.875em'})}>{' - '}</p>
          </div>
        );
      }
    },
  },
  ClassName: {
    title: 'Class',
    field: 'ClassName',
    className: 'ellpsisCol',
    render: (rowData: ICustomer) => {
      return rowData.ClassName || rowData?.AccountCustomerClassName || '-';
    },
  },
  category: {
    title: 'Linked Acc. Customer',
    field: 'category',
    render: (rowData: ICustomer) => rowData.AccountCustomerName || ' -  ',
  },
  DefaultLocation: {
    title: 'Billing Address',
    field: 'DefaultLocation',
    className: 'ellpsisCol',
    render: (rowData: ICustomer) => rowData?.DefaultLocation?.Addresses?.[0]?.FullAddress || ' - ',
  },
  IsActive: {
    title: 'Action',
    field: 'IsActive',
    align: 'center',
    render: (rowData: any) => {
      if (rowData.BillToCustomerID || rowData.ClassID) {
        return (
          <span
            className="mr-[22px] flex w-full justify-center text-base text-primary"
            onClick={e => {
              unlinkCustomer(rowData);
              setRemoveClass(false);
              e.stopPropagation();
            }}
          >
            {rowData.BillToCustomerID ? 'Unlink' : 'Remove Class'}
          </span>
        );
      } else {
        return (
          <Button
            size="sm"
            className="h-fit w-[90px] py-1"
            onClick={e => {
              setCustomerId(rowData.ID);
              setRemoveClass(false);
              e.stopPropagation();
              toggleCompanyDetailsDialog(false);
            }}
            data-autoid={`btnLink-${rowData.ID}`}
          >
            Link
          </Button>
        );
      }
    },
  },
});

export const OperationalCustomers = () => {
  const {loading: linkGenericLoader, response: responseCustomerLinking, linkAccountCustomers} = useCustomerLinking();
  const history = useHistory();
  const classes = useOperationalCustomersStyles();
  const [customerId, setCustomerId] = React.useState();
  const [removeClass, setRemoveClass] = React.useState(false);

  const [selected, setSelected] = useState<number[]>([]);
  const [refetch, setRefetch] = React.useState(0);
  const {response, unLinkCustomerAccount, unLinkCustomerClass, linkGenericClass} = useCustomerLinking();
  const {getCustomer} = useCustomersAPI();

  const [searchGenericFilter, setSearchGenericFilter] = useState<any>();
  const [customerClassFilter, setCustomerClassFilter] = useState<Partial<ICreateCustomerClassValues>>();
  const [customerDataGeneric, setCustomerDataGeneric] = useState<ICreateCustomerClassValues[]>();
  const [search] = useState<string>();
  const [unLink, setUnLink] = useState<boolean>(true);
  const {getList} = useCustomerClassesAPI();

  const [selectedColumns, setSelectedColumns] = useState<string[]>([
    'CompanyName',
    'Phone1',
    'ClassName',
    'category',
    'DefaultLocation',
    'IsActive',
  ]);

  const {
    loading,
    totalCount,
    pageSize,
    currentPage,
    items: CustomersList,
    searchFilter,
    setSearchFilter,
    getCustomersList,
  } = useLoadCustomers(false);

  const [showLinkOperationalCustomerDialog, setShowLinkOperationalCustomerDialog] = useState<boolean>(false);
  const [showUnLinkDialog, setShowUnLinkDialog] = useState<boolean>(false);
  const [UnLinkData, setUnLinkData] = useState<ICustomer>();

  useEffect(() => {
    if (response?.IsSuccess) {
      getCustomersList();
    }
  }, [response]);

  useEffect(() => {
    getCustomersList();
  }, [refetch]);

  const handleCustomerClassFilter = (selected: Partial<ICreateCustomerClassValues> | null) => {
    if (setSearchGenericFilter) {
      setSearchGenericFilter({
        ...omit(searchGenericFilter, ['customerClassID', 'LastRowNumber']),
        ...(selected ? {customerClassID: selected?.ID} : {}),
      });
    }
    setCustomerClassFilter(selected || undefined);
  };
  useEffect(() => {
    if (search) {
      const filter = {
        search,
      };
      getList(filter).then(res => {
        setCustomerDataGeneric(res.Value);
      });
    } else {
      setCustomerDataGeneric([]);
    }
  }, [search]);

  useEffect(() => {
    if (response?.IsSuccess && unLink) {
      toggleCompanyDetailsDialog(false);
    }
    setUnLink(true);
  }, [response]);

  const toggleCompanyDetailsDialog = (f: boolean) => {
    setShowLinkOperationalCustomerDialog(!f);
  };
  const handleRowClick = (rowData: any) => {
    history.push(AUTH_CUSTOMER_OPERATIONAL_CUSTOMERS_EDIT.replace(/:customerID/g, rowData.ID));
  };

  const handlePageChange = (newPage: number) => {
    setSearchFilter({
      ...searchFilter,
      LastRowNumber: (newPage - 1) * pageSize,
    });
  };

  const unlinkCustomer = (customer: ICustomer) => {
    setUnLink(false);
    setUnLinkData(customer);
    setShowUnLinkDialog(!showUnLinkDialog);
  };
  const editIconClick = (row: any) => {
    history.push(AUTH_CUSTOMER_OPERATIONAL_CUSTOMERS_EDIT.replace(/:customerID/g, row.ID));
  };
  const linkGenericCustomer = async (customerId: number, customerClassFilter: number) => {
    if (!linkGenericLoader) {
      await linkGenericClass(customerId, customerClassFilter ? customerClassFilter : 0);
      setShowLinkOperationalCustomerDialog(false);
    }
  };

  const UnlinkOperationalCustomer = () => {
    if (UnLinkData?.ID && UnLinkData.AccountCustomerID) {
      unLinkCustomerAccount(UnLinkData.ID);
    } else if (UnLinkData?.ID && UnLinkData.ClassID) {
      unLinkCustomerClass(UnLinkData.ID);
    }
    unlinkCustomer(UnLinkData ? UnLinkData : {});
  };
  const operationalCustomerList = (
    <div
      className={`${classes.root} flex min-h-full w-full flex-col overflow-y-auto rounded-lg bg-white p-4 px-5`}
      id="customer-op-list"
    >
      <div className={'flex w-full justify-between px-1 py-4'}>
        <p className={`${classes.title}`}>Customer Management</p>
        <div className="flex">
          <RouterLink to={AUTH_CUSTOMER_OPERATIONAL_CUSTOMERS_ADD}>
            <Button size="sm">Add Customer</Button>
          </RouterLink>
        </div>
      </div>
      <OperationalCustomerToolbar
        loading={loading}
        title={`Customers List (${totalCount})`}
        searchFilter={searchFilter}
        columns={columnsRender(
          setCustomerId,
          toggleCompanyDetailsDialog,
          unlinkCustomer,
          getCustomersList,
          editIconClick,
          setRemoveClass,
        )}
        data={CustomersList}
        selected={selected}
        selectedColumns={selectedColumns}
        setSelected={setSelected}
        setSelectedColumns={setSelectedColumns}
        setSearchFilter={setSearchFilter}
        setRefetch={setRefetch}
        refetch={refetch}
      />
      <STable
        columns={Object.entries(
          columnsRender(
            setCustomerId,
            toggleCompanyDetailsDialog,
            unlinkCustomer,
            getCustomersList,
            editIconClick,
            setRemoveClass,
          ),
        )
          .map(([key, value]: [string, any]) => {
            if (selectedColumns.includes(key)) {
              return value;
            }
          })
          .filter(value => value)}
        data={CustomersList}
        selected={selected}
        setSelected={setSelected}
        emptyDataMessage={'No Customers'}
        stickyHeader={true}
        isUpdatedSelect={true}
        selection={true}
        loading={loading}
        onRowClick={handleRowClick}
      ></STable>
      <SPagination
        currentPage={currentPage}
        totalCount={totalCount}
        pageSize={pageSize}
        onPageChange={handlePageChange}
      />
    </div>
  );

  const linkClick = async (customersId: number, AccountCustomerID: number) => {
    if (!linkGenericLoader) {
      await linkAccountCustomers(customersId, AccountCustomerID);
      setShowLinkOperationalCustomerDialog(false);
    }
  };

  return (
    <div className={`relative h-full overflow-hidden bg-spenda-newbg`}>
      <Layout leftPanel={operationalCustomerList} splitWidthType={4} />
      <LinkOperationalCustomerDialog
        responseCustomerLinking={responseCustomerLinking}
        linkClick={linkClick}
        linkAccountCustomers={linkAccountCustomers}
        open={showLinkOperationalCustomerDialog}
        setShowLinkOperationalCustomerDialog={setShowLinkOperationalCustomerDialog}
        onClose={toggleCompanyDetailsDialog}
        customerId={customerId}
        customerLinked={true}
        getCustomersList={getCustomersList}
        getCustomer={getCustomer}
        handleFilter={handleCustomerClassFilter}
        customerDataGeneric={customerDataGeneric}
        linkGeneric={linkGenericCustomer}
        customerClassFilter={customerClassFilter}
      ></LinkOperationalCustomerDialog>

      <UnlinkDialog
        Unlink={UnlinkOperationalCustomer}
        unLinkOperational={true}
        open={showUnLinkDialog}
        onClose={unlinkCustomer}
        removeClass={removeClass}
      ></UnlinkDialog>
    </div>
  );
};
