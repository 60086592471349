import React from 'react';

export const IconQuoteRejected = (props: {width?: string; height?: string; fill?: string; viewBox?: string}) => {
  return (
    <svg width={88} height={88} viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.928 0a25.514 25.514 0 0118.15 7.517l12.775 12.782a25.488 25.488 0 017.52 18.146v13.328a3.667 3.667 0 01-7.33.183l-.004-.183.001-13.328c0-.605-.117-1.184-.172-1.778H54.707c-6.075 0-11-4.925-11-11V7.506c-.594-.055-1.177-.173-1.779-.173H25.373c-6.075 0-11 4.925-11 11v51.334c0 6.075 4.925 11 11 11h25.52a3.667 3.667 0 110 7.333h-25.52c-10.12-.012-18.32-8.213-18.333-18.333V18.333C7.052 8.214 15.253.014 25.373 0h16.555zm44.973 59.179a3.751 3.751 0 01.046 5.259l-8.602 8.602 8.602 8.602a3.751 3.751 0 01-5.305 5.305l-8.602-8.602-8.602 8.602a3.752 3.752 0 01-5.305-5.305l8.602-8.602-8.602-8.602a3.751 3.751 0 015.305-5.305l8.602 8.602 8.602-8.602a3.751 3.751 0 015.26.046zm-39.528 3.154a3.667 3.667 0 010 7.334H29.04a3.667 3.667 0 010-7.334h18.333zm11-14.666a3.667 3.667 0 010 7.333H29.04a3.667 3.667 0 010-7.333h29.333zM51.04 9.84v15.826a3.667 3.667 0 003.667 3.666h15.825a18.272 18.272 0 00-2.864-3.85L54.894 12.701a18.372 18.372 0 00-3.854-2.86z"
        fill="#C55D44"
      />
    </svg>
  );
};
