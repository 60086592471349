import {useEffect, useState} from 'react';
import {DatTypes} from '../model/constants/Constants';
import {ICategory} from '../model/inventory/Category';
import {IActionResults, IPagedActionResults, IDashboardActionResults} from '../model/ActionResults';
import {ICategoryFilter} from '../model/search-filters/CategoryFilter';
import useHttp from '../hooks/useHttp';

const useCategoryAPI = () => {
  const {GET, POST, PUT, isLoading} = useHttp();

  const search = (filter: any): Promise<ICategory[]> => {
    return GET('suppliercategory', filter).then((data: IActionResults<ICategory[]>) => data.Value);
  };

  const dashboard = (filter: any): Promise<IDashboardActionResults<ICategory[]>> => {
    return GET('dashboard', filter).then((data: IDashboardActionResults<ICategory[]>) => data);
  };

  const listCategories = (filter: any): Promise<IPagedActionResults<ICategory[]>> => {
    return GET('category', filter).then((data: IPagedActionResults<ICategory[]>) => data);
  };

  const createCategory = (payload: Partial<ICategory>): Promise<ICategory> => {
    return POST('category', {Object: payload}).then((data: IActionResults<ICategory>) => data?.Object!);
  };

  const getCategoryById = (payload: Partial<ICategory>): Promise<ICategory> => {
    return GET(`category/${payload.ID}`).then((data: IActionResults<ICategory>) => data.Value);
  };

  const updateCategory = (payload: Partial<ICategory>): Promise<ICategory> => {
    return PUT(`category/${payload.ID}`, {Object: payload}).then((data: IActionResults<ICategory>) => data.Value);
  };

  return {
    search,
    dashboard,
    listCategories,
    createCategory,
    getCategoryById,
    updateCategory,
    isLoading,
  };
};

export default useCategoryAPI;

export const useLoadCategory = () => {
  let pageSize = 15;

  const initialFilterState = {
    DatTypeID: DatTypes.Category,
    IsExactMatch: false,
    LastRowNumber: 0,
    MaxResults: pageSize,
    SortAsc: true,
    SortField: 'Name',
  } as ICategoryFilter;

  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<ICategory[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [moreToGet, setMoreToGet] = useState<boolean>(true);
  const [error, setError] = useState<any>();
  const [searchFilter, setSearchFilter] = useState<ICategoryFilter>(initialFilterState);

  const {dashboard} = useCategoryAPI();

  const getCategoriesList = async () => {
    setLoading(true);

    if (searchFilter.StartRow === 1) {
      setItems([]);
      setMoreToGet(true);
    }

    try {
      const {Value, IsMore, TotalRecordCount, LastRowNo} = await dashboard(searchFilter);
      let items: ICategory[] = Value || [];

      setItems(items);
      setTotalCount(TotalRecordCount);
      setMoreToGet(IsMore);
      setCurrentPage(Math.ceil(LastRowNo / (searchFilter.MaxResults || pageSize)));
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCategoriesList();
  }, [searchFilter]);

  return {
    loading,
    items,
    totalCount,
    pageSize: searchFilter.MaxResults || pageSize,
    currentPage,
    moreToGet,
    error,
    searchFilter,
    getCategoriesList,
    setSearchFilter,
  };
};
