import React from 'react';
const IconNotLinked = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.36639 5.37037C3.56176 5.56561 3.56188 5.88225 3.36664 6.07762L1.73564 7.71012C0.776886 8.69576 0.78787 10.2688 1.7603 11.241C2.73273 12.2131 4.30577 12.2237 5.29114 11.2646L6.92214 9.63362H6.92364C7.11983 9.44414 7.43169 9.44685 7.62455 9.63971C7.81742 9.83258 7.82013 10.1444 7.63064 10.3406L5.99964 11.9716C4.62763 13.3443 2.4026 13.3449 1.02989 11.9729C-0.34281 10.6009 -0.343369 8.37583 1.02864 7.00312L2.65914 5.37062C2.85438 5.17525 3.17102 5.17514 3.36639 5.37037ZM1.62025 1.09431L4.30069 3.74602C4.32006 3.71554 4.34304 3.68671 4.36964 3.66012L6.00314 2.02862C6.66067 1.36797 7.55505 0.997647 8.48714 1.0001L8.54538 1.00045C9.45692 1.01341 10.3281 1.38271 10.9716 2.03062C12.3412 3.40422 12.3407 5.62708 10.9706 7.00012L9.33964 8.63012C9.32245 8.64732 9.30431 8.663 9.2854 8.67717L11.8487 11.2128C11.9689 11.3276 12.0162 11.4986 11.9724 11.66C11.9286 11.8213 11.8005 11.9481 11.6374 11.9914C11.4742 12.0347 11.3014 11.9879 11.1854 11.869L6.48624 7.22037L4.85314 8.85362C4.72761 8.9836 4.54171 9.03573 4.36689 8.98997C4.19208 8.94421 4.05555 8.80769 4.0098 8.63288C3.96404 8.45806 4.01617 8.27216 4.14614 8.14662L5.77536 6.51728L0.956898 1.75053C0.78172 1.57101 0.786354 1.28363 0.967314 1.10461C1.14827 0.925598 1.43878 0.921013 1.62025 1.09431ZM8.48614 2.00012L8.43213 2.00057C7.78475 2.01289 7.16661 2.2764 6.70914 2.73662L5.07664 4.36762C5.05148 4.39276 5.02431 4.41467 4.99565 4.43333L6.43524 5.85741L7.14614 5.14662C7.34233 4.95714 7.65419 4.95985 7.84705 5.15271C8.03992 5.34558 8.04263 5.65743 7.85314 5.85362L7.146 6.56061L8.58196 7.9811C8.59694 7.96066 8.61367 7.9411 8.63214 7.92262L10.2631 6.29162C10.9819 5.57269 11.197 4.49162 10.8081 3.55233C10.4191 2.61305 9.50277 2.00046 8.48614 2.00012Z"
        fill="#C55D44"
      />
    </svg>
  );
};

export default IconNotLinked;
