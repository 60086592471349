import React, {useEffect, useRef, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {Formik, FormikHelpers, Form, FormikProps} from 'formik';
import * as Yup from 'yup';
import {Button} from 'spenda-ui-react';

import {AUTH_CUSTOMER_ACCOUNT_CUSTOMERS_LIST} from '../../routes/CustomerRoutes';
import {Layout} from '../../components/layout/Layout';
import {UnlinkDialog} from '../../components/dialog/UnlinkDialog';
import {AddAccountCustomerForm, LinkedOperationalCustomer} from '../../components/form/AccountCustomerForm';
import {ICustomer} from '../../model/customer/Customer';
import {useCustomersAPI, useCustomerLinking} from '../../services/useCustomersAPI';
import {ILocation} from '../../model/address/Location';
import {Toast} from '../../utils/Toast';
import LoadingIndicator from '../../components/ui/LoadingIndicator';
import {AlertDialog} from '../../components/dialog/AlertDialogSlideV2';

export const AddAccountCustomer = () => {
  const {customerID} = useParams<{customerID?: string | undefined}>();
  return <AddAccountCustomerCmp customerID={customerID || ''} scope="CM" />;
};

interface IAddAccountCustomerCmpProps {
  customerID: string;
  scope: 'AR' | 'CM';
  onSuccess?: () => void;
  onCancelBtnClick?: () => void;
}

export const AddAccountCustomerCmp = (props: IAddAccountCustomerCmpProps) => {
  const {customerID, scope, onSuccess, onCancelBtnClick} = props;
  const isInARModule = scope === 'AR';
  const isInCMModule = scope === 'CM';
  const history = useHistory();
  const {saveCustomer, getCustomer, updateCustomer, getOperationalCustomerById} = useCustomersAPI();
  const {response, unLinkCustomerAccount, unLinkCustomerClass, loading} = useCustomerLinking();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showSaveChangesDialog, setShowSaveChangesDialog] = useState(false);

  const formRef = useRef<FormikProps<ICustomer>>(null);

  const newCustomer: ICustomer = {
    IsActive: true,
    Name: '',
    Phone1: '',
    ABN: '',
    RefNumber: '',
    IsIndividual: 'false',
    IsExemptFromMerchantSurcharge: 'false',
    Locations: [
      {
        Name: '',
        Country: '',
        State: '',
        StreetAddress: '',
        City: '',
        PostCode: '',
        FullAddress: '',
      },
    ] as any,

    Contacts: [
      {
        FirstName: '',
        LastName: '',
        EmailAddress: '',
        Phone1: '',
      },
    ],
  } as ICustomer;
  const [customerEdit, setCustomerEdit] = React.useState<ICustomer>(newCustomer);
  const [refNumberChange, setRefNumberChange] = useState<boolean>(false);

  const [customerDetails, setCustomerDetails] = React.useState<ICustomer>({});
  const [operationalCustomers, setOperationalCustomers] = React.useState<ICustomer[]>([]);
  const [accountCustomerData, setAccountCustomerData] = React.useState<ICustomer>({});

  const [removeClass, setRemoveClass] = React.useState(false);
  const [showUnLinkDialog, setShowUnLinkDialog] = useState<boolean>(false);
  const [UnLinkData, setUnLinkData] = useState<ICustomer>();

  const fetchCustomerInfo = (customerID: number) => {
    getCustomer(customerID).then(
      async (res: ICustomer) => {
        if (res.BillToCustomerID) {
          const accountCustomer = await getCustomer(res?.BillToCustomerID);
          setAccountCustomerData(accountCustomer);
        } else if (res.ClassID) {
        } else {
          setAccountCustomerData({});
        }
        setCustomerDetails(res);
      },
      () => {
        history.push(AUTH_CUSTOMER_ACCOUNT_CUSTOMERS_LIST);
      },
    );
  };

  useEffect(() => {
    if (response?.IsSuccess && customerID) {
      fetchCustomerInfo(parseInt(customerID));
    }
  }, [response]);

  useEffect(() => {
    if (customerID) {
      getCustomer(parseInt(customerID)).then(async (res: ICustomer) => {
        if (res) {
          let loc = res?.Locations?.map((d: ILocation) => ({
            Addresses: d?.Addresses,
            IsActive: d?.IsActive,
            Name: d?.Name,
            IsDefaultLocation: d?.IsDefaultLocation,
          }));

          let con = res?.Contacts?.map((d: ILocation) => ({
            BusinessContactID: d?.BusinessContactID,
            FirstName: d?.FirstName,
            LastName: d?.LastName,
            EmailAddress: d?.EmailAddress,
            Phone1: d?.Phone1,
            IsActive: true,
          }));

          const _customers = {
            IsActive: res.IsActive,
            Name: res?.IsIndividual === true ? '' : res.Name,
            Phone1: res.Phone1,
            ABN: res.ABN,
            BillToCustomerID: res.BillToCustomerID,
            ClassPostingBehaviour: res.ClassPostingBehaviour,
            RefNumber: res.RefNumber,
            IsIndividual: res.IsIndividual ? 'true' : 'false',
            IsExemptFromMerchantSurcharge: res.IsExemptFromMerchantSurcharge ? 'true' : 'false',
            Locations: loc,
            ClassID: res.ClassID,
            CustomerClass: res.CustomerClass,
            Contacts: con,
          } as ICustomer;

          if (res.BillToCustomerID) {
            const accountCustomer = await getCustomer(res?.BillToCustomerID);
            setAccountCustomerData(accountCustomer);
          } else if (res.ClassID) {
          } else {
            setAccountCustomerData({});
          }

          fetchOperationalCustomer(customerID);

          setCustomerDetails(res);
          setIsLoading(false);
          setCustomerEdit(_customers);
        }
      });
    } else {
      setIsLoading(false);
    }
  }, []);

  const fetchOperationalCustomer = async (customerID: string | undefined) => {
    if (customerID) {
      const _operationalCustomerList = await getOperationalCustomerById(parseInt(customerID));
      setOperationalCustomers(_operationalCustomerList);
    }
  };

  const validationSchema = Yup.object({
    ABN: Yup.string().matches(
      /^(?:\d{2}-\d{3}-\d{3}-\d{3}|\d{11})$/,
      'ABN must be of 11 characters without any blank space',
    ),
    Contacts: Yup.array().of(
      Yup.object()
        .nullable(true)
        .shape({
          EmailAddress: Yup.string().email('Invalid Email address').nullable(true),
          FirstName: Yup.string()
            .nullable(true)
            .required('First Name is required')
            .max(50, 'First Name cannot be longer than 50 characters.'),
          LastName: Yup.string()
            .nullable(true)
            .required('Last Name is required')
            .max(50, 'Last Name cannot be longer than 50 characters.'),
          Phone1: Yup.string()
            .matches(/^[0-9]+$/, 'Only numbers are allowed for this field')
            .max(20, 'More than 20 characters not allowed.'),
        }),
    ),
    Phone1: Yup.string()
      .matches(/^[0-9]+$/, 'Only numbers are allowed for this field')
      .max(20, 'More than 20 characters not allowed.'),
    Name: Yup.string().when(['IsIndividual'], (IsIndividual: string, schema: any) => {
      return IsIndividual === 'false' ? schema.required(' Company Name is required') : schema.nullable();
    }),
    RefNumber: Yup.string().test({
      name: 'RefNumber',
      test: function (value) {
        let check = true;
        if (customerID) {
          if (customerEdit.RefNumber === value) {
            check = false;
          }
        }
        setTimeout(() => {}, 1000);
        return refNumberChange === true && check
          ? this.createError({message: 'Unique Reference number require', path: 'RefNumber'})
          : true;
      },
    }),
  });

  const onSubmit = (values: ICustomer, helpers: FormikHelpers<ICustomer>): void => {
    let data: ICustomer | undefined = values;
    if (isInCMModule) {
      if (data.IsIndividual === 'true') {
        data.Name = '';
        data.Phone1 = '';
        data.CompanyName = '';
      }
      let i = 0;
      for (i = data?.Locations?.length || -1; i >= 0; i--) {
        if (data?.Locations?.[i]?.Addresses?.[0]?.FullAddress) {
          delete data?.Locations?.[i]?.Addresses?.[0]?.FullAddress;
        }
        if (
          !data?.Locations?.[i]?.Addresses?.[0]?.Name &&
          !data?.Locations?.[i]?.Addresses?.[0]?.Country &&
          !data?.Locations?.[i]?.Addresses?.[0]?.State &&
          !data?.Locations?.[i]?.Addresses?.[0]?.StreetAddress &&
          !data?.Locations?.[i]?.Addresses?.[0]?.City &&
          !data?.Locations?.[i]?.Addresses?.[0]?.PostCode
        ) {
          data = data?.Locations?.splice(i, 1) && data;
        }
      }
      if (customerID) {
        updateCustomer(parseInt(customerID), values, 13).then(() => {
          helpers.setSubmitting(false);
          history.push(AUTH_CUSTOMER_ACCOUNT_CUSTOMERS_LIST);
        });
      } else {
        saveCustomer(values, 13).then(() => {
          helpers.setSubmitting(false);
          history.push(AUTH_CUSTOMER_ACCOUNT_CUSTOMERS_LIST);
        });
      }
    } else if (isInARModule) {
      updateCustomer(parseInt(customerID), values, 13).then(() => {
        helpers.setSubmitting(false);
        onSuccess?.();
      });
    }
  };

  const unlinkCustomer = (customer: ICustomer) => {
    setUnLinkData(customer);
    setShowUnLinkDialog(!showUnLinkDialog);
  };

  const UnlinkOperationalCustomer = async () => {
    if (UnLinkData?.ID && UnLinkData.AccountCustomerID) {
      await unLinkCustomerAccount(UnLinkData.ID);
    } else if (UnLinkData?.ID && UnLinkData.ClassID) {
      await unLinkCustomerClass(UnLinkData.ID);
    }
    unlinkCustomer({});
    Toast.info('Unlink Successfull');
    fetchOperationalCustomer(customerID);
  };

  const addCustomer = (
    <div
      className={
        'flex h-full max-h-[calc(100vh-20.5em)] min-h-full w-full flex-col overflow-y-auto rounded-lg bg-white p-4 px-5'
      }
      id="sales-orders-list"
    >
      {isLoading && isInCMModule ? (
        <LoadingIndicator isLoading size={'md'} />
      ) : (
        <Formik
          innerRef={formRef}
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={customerEdit}
          onSubmit={onSubmit}
        >
          {props => (
            <Form
              onSubmit={props.handleSubmit}
              className={`flex h-full flex-col ${isInARModule ? 'w-[600px] p-0' : 'px-5 py-4'}`}
            >
              {isInCMModule && (
                <div className={`flex w-full justify-between p-4`}>
                  <p className={`text-2xl font-medium text-[#4D4D4D]`}>
                    {customerID ? 'Edit Customer' : 'Create A New Customer'}
                  </p>
                </div>
              )}

              {isInCMModule ? (
                <AddAccountCustomerForm
                  {...props}
                  customerId={customerID}
                  customerToEdit={customerDetails}
                  accountData={accountCustomerData}
                  setRefNumberChange={setRefNumberChange}
                  operationalCustomers={operationalCustomers}
                  setRemoveClass={setRemoveClass}
                  unlinkCustomer={unlinkCustomer}
                />
              ) : (
                <>
                  {isLoading ? (
                    <div className="flex min-h-[250px] items-center justify-center">
                      <LoadingIndicator isLoading size={'md'} />
                    </div>
                  ) : (
                    <LinkedOperationalCustomer
                      {...props}
                      isUIUpdate
                      customerToEdit={customerDetails}
                      operationalCustomers={operationalCustomers}
                      setRemoveClass={setRemoveClass}
                      unlinkCustomer={unlinkCustomer}
                    />
                  )}
                </>
              )}
              {isInCMModule && (
                <div
                  className={
                    'sticky bottom-0 -mb-5 mr-3 flex w-[calc(100%-14px)] justify-end rounded-lg bg-spenda-footerBg p-2.5'
                  }
                >
                  <div className="flex gap-x-2">
                    <Button
                      variant="outlined"
                      className="bg-white"
                      onClick={() => {
                        if (props.dirty) {
                          setShowSaveChangesDialog(true);
                        } else {
                          history.push(AUTH_CUSTOMER_ACCOUNT_CUSTOMERS_LIST);
                        }
                      }}
                    >
                      Cancel
                    </Button>
                    <Button type="submit" loading={props.isSubmitting} disabled={props.isSubmitting || !props.dirty}>
                      Save
                    </Button>
                  </div>
                </div>
              )}
              {isInARModule && (
                <div className="flex h-[52px] items-center justify-between rounded-[6px] bg-spenda-footerBg px-[10px]">
                  <Button variant="outlined" onClick={() => onCancelBtnClick?.()} disabled={props.isSubmitting}>
                    Cancel
                  </Button>
                  <Button type="submit" disabled={props.isSubmitting || !props.dirty} loading={props.isSubmitting}>
                    Done
                  </Button>
                </div>
              )}
            </Form>
          )}
        </Formik>
      )}
    </div>
  );

  return (
    <>
      <UnlinkDialog
        isLoading={loading}
        Unlink={UnlinkOperationalCustomer}
        unLinkOperational={true}
        open={showUnLinkDialog}
        onClose={unlinkCustomer}
        removeClass={removeClass}
        scope={scope}
      ></UnlinkDialog>
      {isInCMModule && (
        <div className={`relative h-full overflow-hidden bg-spenda-newbg`}>
          <Layout leftPanel={addCustomer} splitWidthType={4} />
          {showSaveChangesDialog && (
            <AlertDialog
              size="sm"
              title="Discard Changes"
              headingTextSize="h2"
              content="You have unsaved changes, would you like to discard changes or return to the page?"
              actions={[
                {
                  label: 'Cancel',
                  variant: 'outlined',
                  action: () => setShowSaveChangesDialog(false),
                },
                {
                  label: 'Discard',
                  action: () => history.push(AUTH_CUSTOMER_ACCOUNT_CUSTOMERS_LIST),
                },
              ]}
            />
          )}
        </div>
      )}
      {isInARModule && <div className="flex w-full"> {addCustomer}</div>}
    </>
  );
};
