import useHttp from '../hooks/useHttp';
import {PaymentProviderName} from '../model/constants/Constants';
import {
  AuthenticateCodeRequest,
  PaymentAuthorisationResponse,
  AuthorisationRulesRequest,
  AuthorisationRulesResponse,
  PaymentAuthoriseRequest,
  RejectAuthorisationPayload,
  IPaymentAuthorityChangeNotificationResponse,
} from '../model/payment/PaymentAuthority';

const usePaymentAuthorityAPI = () => {
  const {isLoading, GET, POST} = useHttp();

  const savePaymentAuthorisationRules = (
    payload: AuthorisationRulesRequest,
    paymentAccountGUID: string,
    paymentProviderName: PaymentProviderName = PaymentProviderName.Spenda_Payment_Services
  ) => {
    return POST(`PaymentServices/Account/${paymentProviderName}/${paymentAccountGUID}`, payload);
  };

  const paymentAuthorityChangeNotification = (
    paymentAccountGUID: string,
    paymentProviderName: PaymentProviderName = PaymentProviderName.Spenda_Payment_Services
  ): Promise<IPaymentAuthorityChangeNotificationResponse[]> => {
    return GET(`PaymentServices/Account/${paymentProviderName}/${paymentAccountGUID}/ApprovedTransactions`);
  };

  const getPaymentMethodAuthorisationRules = (
    paymentAccountGUID: string,
    paymentProviderName: PaymentProviderName = PaymentProviderName.Spenda_Payment_Services
  ): Promise<AuthorisationRulesResponse> => {
    return GET(`PaymentServices/Account/${paymentProviderName}/${paymentAccountGUID}/Authorisers`).then(
      (data: AuthorisationRulesResponse) => data
    );
  };

  const authorisePayment = (
    payload: PaymentAuthoriseRequest,
    paymentProviderName: PaymentProviderName = PaymentProviderName.Spenda_Payment_Services
  ) => {
    return POST(`PaymentServices/Payment/${paymentProviderName}/Authorise`, payload);
  };

  const approvePaymentAuthorisation = (
    payload: AuthenticateCodeRequest,
    authorisationID: number
  ): Promise<PaymentAuthorisationResponse> => {
    return POST(`Spenda/Authorisations/${authorisationID}/Approve`, payload);
  };

  const rejectPaymentAuthorisation = (
    payload: RejectAuthorisationPayload,
    authorisationID: number
  ): Promise<PaymentAuthorisationResponse> => {
    return POST(`Spenda/Authorisations/${authorisationID}/Reject`, payload);
  };

  const getPaymentMethodAuthorisationDetails = (authorisationID: number): Promise<PaymentAuthorisationResponse> => {
    return GET(`Spenda/Authorisations/${authorisationID}`);
  };

  const resetAuthenticationCode = (authorisationID: number): Promise<PaymentAuthorisationResponse> => {
    return POST(`Spenda/Authorisations/${authorisationID}/NewCode`, undefined);
  };

  return {
    isLoading,
    savePaymentAuthorisationRules,
    getPaymentMethodAuthorisationRules,
    authorisePayment,
    approvePaymentAuthorisation,
    getPaymentMethodAuthorisationDetails,
    rejectPaymentAuthorisation,
    resetAuthenticationCode,
    paymentAuthorityChangeNotification,
  };
};

export default usePaymentAuthorityAPI;
