import * as React from 'react';
const GreenCheckIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg {...props}>
    <circle cx="9" cy="9" r="9" fill="#fff" />
    <path
      fill="#1E9D71"
      fillRule="evenodd"
      d="M11.834 5.582a.67.67 0 0 1 1.206.581l-.034.07-3.421 6.176a.67.67 0 0 1-.908.263l-.066-.042-3.421-2.44a.672.672 0 0 1 .705-1.138l.072.045 2.81 2.004 3.057-5.519Z"
      clipRule="evenodd"
    />
  </svg>
);
export default GreenCheckIcon;
