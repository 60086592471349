import currency from 'currency.js';
import moment from 'moment';
import {DISCOUNT_MODE, TradingTermName} from '../model/constants/Constants';

// Takes the amount and splits it into an array of strings
// then it checks if the array has more than one element
// if it does, it checks if the last element has more than 4 characters
// if it does, it formats the amount to 4 decimal places
// if it doesn't, it formats the amount to 2 decimal places
// if the array has only one element, it formats the amount to 2 decimal places
// it then returns the formatted amount with the currency symbol and commas
// Examples: 1000.1234 => $1,000.1234, 1000 => $1,000.00, 1000.1 => $1,000.10, 1000.12345 => $1,000.1235
export const PriceFormat = (amount?: number, currencyStr: string = '$', decimalLength?: number): string => {
  amount = amount || 0;

  const amountString = amount.toString();
  const amountStringArray = amountString.split('.');

  const decimalPlacesString = amountStringArray.length > 1 ? amountStringArray[amountStringArray.length - 1] : '';

  let formattedAmountString: string;

  if (!decimalPlacesString.length || decimalPlacesString.length === 1 || decimalLength === 2) {
    formattedAmountString = amount.toFixed(2);
  } else if (decimalPlacesString.length > 4) {
    formattedAmountString = amount.toFixed(4);
  } else {
    formattedAmountString = amount.toString();
  }
  const stringToDisplay = formattedAmountString.replace('-', '').replace(/\d(?=(\d{3})+\.)/g, '$&,');
  return `${amount < 0 ? '-' : ''}${currencyStr}${stringToDisplay}`;
};

// Takes a string and returns the first two characters of the string
// Examples: 'John Doe' => 'JD', 'John' => 'JO', 'J' => 'J'
export const NameInitials = (name?: string): string => {
  if (!name || !name.trim()) {
    return '';
  }

  const nameArray = name.split(' ').filter(Boolean);
  if (nameArray.length > 1) {
    return `${nameArray[0][0]}`.toUpperCase() + `${nameArray[1][0]}`.toUpperCase();
  } else {
    return nameArray[0].substr(0, 2).toUpperCase();
  }
};

// CurrencyFromNumber does the following:
// 1. Checks if the value is undefined, not a number, or not finite
// 2. If the value is undefined, not a number, or not finite, it returns 0
// 3. If the value is a number, it formats it to 2 decimal places and adds commas
// 4. It returns the formatted value
// Examples: 1000.1234 => 1,000.12, 1000 => 1,000.00, 1000.1 => 1,000.10, 1000.12345 => 1,000.12
export const CurrencyFromNumber = (value: number): number => {
  if (value === undefined || typeof value !== 'number' || !isFinite(value)) {
    return 0;
  }
  let amountValue = value
    .toFixed(2)
    .trim()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return parseFloat(amountValue);
};

// Takes a number and returns a string with the number formatted to the number of decimal places specified
// Examples: 1000.1234, 2 => 1000.12, 1000.1234, 4 => 1000.1234, 1000.1234, 0 => 1000
export const toFixedWithoutRounding = (value: number, rounded: number = 2): string => {
  return value?.toString()?.match('^-?\\d+(?:\\.\\d{0,' + rounded + '})?')?.[0] || '';
};

// Takes the string and splits it into an array of words
// then it capitalizes the first letter of each word and joins them back together
// Examples: 'this is a test' => 'This Is A Test', 'this-is-a-test' => 'This Is A Test'
export const capitalizeInitials = (s: string | undefined) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

// Takes a string and removes all non-numeric characters
// Examples: '1234' => '1234', '1234.1234' => '12341234', '1234.1234.1234' => '123412341234'
export const StripNonNumericCharacters = (s: string | undefined) => {
  if (typeof s !== 'string') return '';
  return s.replace(/\D/g, '');
};

// The way this works is that it takes the string and splits it into an array of words
// then it capitalizes the first letter of each word and joins them back together
// Example: 'this-is-a-test' => 'ThisIsATest'
export const kebabToPascal = (text: string) => {
  const capitalize = (word: string) => {
    return word?.charAt(0)?.toLocaleUpperCase() + word?.slice(1);
  };

  const pascalize = (text: string) => {
    const words = text?.split('-');
    const completeWord = words?.map(word => capitalize(word));
    return completeWord?.join('');
  };

  return pascalize(text);
};

// Takes a string and splits it into an array of words
// then it capitalizes the first letter of each word and joins them back together
export const pascalToSentence = (str: string) => {
  return str
    .replace(/([A-Z])/g, ' $1')
    .replace(/^./, function (str) {
      return str.toUpperCase();
    })
    .trim();
};

// Takes the total invoice amount and subtracts the total credit and claims selected
// if the result is less than 0, it returns 0, else it returns the result
export const netPayableAmount = (totalInvoiceAmount: number, totalCreditAndClaimsSelected: number) => {
  let payableAmount = totalInvoiceAmount - totalCreditAndClaimsSelected;

  payableAmount = parseFloat(payableAmount?.toFixed(2));
  return payableAmount < 0 ? 0 : (payableAmount * 100) / 100;
};

// calculate time elapsed since notification was created using date-fns and format string i.e. '5 days ago' to '5d'
// Examples: '5 days ago' => '5d', '5 hours ago' => '5h', '5 mins ago' => '5m'
export const formattedTimeFromNow = (date: string) => {
  const timeElapsed = moment(moment.utc(date).local()).fromNow();

  return timeElapsed
    .replace('a few seconds ago', ' 1m')
    .replace('days', 'd')
    .replace('day', '1d')
    .replace('minutes', 'm')
    .replace('minute', '1m')
    .replace('mins', 'm')
    .replace('seconds', 's')
    .replace('second', '1s')
    .replace('min', '1m')
    .replace('hours', 'h')
    .replace('hour', '1h')
    .replace('months', 'mo')
    .replace('month', '1mo')
    .replace('years', 'y')
    .replace('year', '1y')
    .replace('weeks', 'w')
    .replace('week', '1w')
    .replace('ago', '')
    .replace('an', '')
    .replace('a', '')
    .split(' ')
    .join('');
};

export const rightToLeftFormatter = (value: string): string => {
  if (!Number(value)) return '';

  return currency(Number(value) / 100).toString();
};

export const getCurrencyStrFromDecimal = (num: string): string => {
  if (num) {
    return currency(num).format();
  }
  return '';
};

export const getDecimalFromCurrencyStr = (currencyStr: string): string => {
  return rightToLeftFormatter(currency(currencyStr.replace('.', '')).toString());
};

export const getMonthName = (monthNumber: number) => {
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  if (monthNumber > 0 && monthNumber <= 12) {
    return monthNames[monthNumber - 1];
  } else {
    return monthNumber;
  }
};

export const convertNumberToWords = (num: number) => {
  const words = [' ', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 'Ten'];
  return words[num] || num.toString();
};

export const getTradingTermDays = (tradingTermDays: number, tradingTermName: TradingTermName) => {
  if (tradingTermName === TradingTermName.DaysAfterEOM) {
    return moment().endOf('month').add(tradingTermDays, 'days').format('Do MMM YYYY');
  } else {
    return moment().add(tradingTermDays, 'days').format('Do MMM YYYY');
  }
};

export const discountToDiscountString = (
  discount: number,
  discountMode: DISCOUNT_MODE,
  isForMargin?: boolean,
  quantity?: number,
) => {
  if (discountMode === DISCOUNT_MODE.DOLLAR && !isForMargin) {
    return Number(discount).toFixed(2);
  } else if (discountMode === DISCOUNT_MODE.PERCENT) {
    return (Number(discount) * 100).toFixed(2);
  } else if (discountMode === DISCOUNT_MODE.NONE) {
    return '0.00';
  } else {
    return (discount * Number(quantity ?? 0)).toFixed(2);
  }
};

export const roundTo = (value: number | null | undefined, decimals: number, format?: boolean) => {
  if (value == null) {
    return null;
  }
  if (isNaN(value) || isNaN(decimals) || decimals < 0 || !Number.isInteger(decimals)) {
    throw new TypeError('Invalid input: value should be a number, decimals should be a non-negative integer.');
  }
  const factor = Math.pow(10, decimals);
  const amt = format ? PriceFormat(Math.round(value * factor) / factor) : Math.round(value * factor) / factor;
  return amt;
};

export const validDecimalPlaces = (value: number, decimalPlaces: number = 2) => {
  if (value.toString().match(/\./g)) {
    const [, decimal] = value.toString().split('.');

    // restrict value to only 2 decimal places
    if (decimal.length > decimalPlaces) {
      // do nothing
      return false;
    }
  }
  return true;
};

export const fileExtension = (fileName: string) => {
  return fileName.split('.').pop();
};
