import React, {useContext, useEffect, useState} from 'react';
import {Layout} from '../../components/layout/Layout';
import {Formik, Form} from 'formik';
import {css} from 'glamor';
import {pick} from 'lodash';
import {PaymentWidgetScope} from '../../model/constants/Constants';
import LoadingIndicator from '../../components/ui/LoadingIndicator';
import {BatchList} from '../../components/purchasing/BatchList';
import {SearchInput} from '../../components/form/InvoiceSearchForm';
import {usePaymentBatchAPI} from '../../services/usePaymentBatchAPI';
import {Popper, Grow, ClickAwayListener} from '@material-ui/core';
import {usePopupState, bindToggle, bindPopper} from 'material-ui-popup-state/hooks';
import {IPaymentBatchForApproval} from '../../model/payment-batch/PaymentBatch';
import searchIcon from '../../assets/svg/accounts-payable/ap-search-icon.svg';
import AppContext from '../../context/app/appContext';
import {PaymentWidgetModal} from '../pay-by-link/payment-widget/PaymentWidget';
import {usePaymentMethods} from '../pay-by-link/payment-widget/usePaymentMethods';
import {useHistory} from 'react-router-dom';
import {BatchSupplierSummary} from '../../components/purchasing/BatchSupplierSummary';
import {PayBatch} from './PayBatch';
import {AUTH_BUYING_AP_REPORTS} from '../../routes/AccountsPayableRoutes';
import {useFeatureFlags} from '../../hooks/useFeatureFlags';
import {IActionResults} from '../../model/ActionResults';
import {ISupplierPaymentResponse} from '../../model/payment/SupplierPaymentResponse';
import {usePaymentsFromAP} from '../pay-by-link/payment-widget/usePaymentsFromAP';

const listContainer = css({
  boxShadow: '0px 0px 6px 0px #D3E5EF',
  fontFamily: 'poppins',
});

const borderBottom = css({
  borderBottom: '1px solid #D8D8D8',
});

export const APAuthorisePayment = () => {
  const [paymentBatches, setPaymentBatches] = useState<IPaymentBatchForApproval[]>([]);
  const [searchString, setSearchString] = useState<string>('');
  const [batchID, setBatchID] = useState<number | undefined>();
  const [authoriseBatchCount, setAuthoriseBatchCount] = useState<number>(0);
  const [authorisationId, setAuthorisationId] = useState<number | undefined>();
  const {getBatchesReadyForAuthorisation, isLoading: paymentBatchLoader} = usePaymentBatchAPI();
  const {onboardingUpgradeV1} = useFeatureFlags().tenantOwned();

  const isLoading = paymentBatchLoader;

  const searchInputPopup = usePopupState({variant: 'popover', popupId: 'searchInputPopup'});
  const [selectedBatchId, setSelectedBatchId] = useState<number | undefined>();

  useEffect(() => {
    fetchBatchesForApproval();
  }, []);

  const fetchBatchesForApproval = async () => {
    const authoriseBatches = await getBatchesReadyForAuthorisation();
    setAuthoriseBatchCount(authoriseBatches.length);
    setPaymentBatches(authoriseBatches);
  };

  const payBatch = async (batch: IPaymentBatchForApproval) => {
    setBatchID(batch.accountsPayableBatchID);
    setAuthorisationId(batch?.authorisation?.authorisationID);
  };

  const onSubmitSearch = (values: {searchText: string}): Promise<void> => {
    setSearchString(values.searchText);
    return Promise.resolve();
  };

  const handleClickAway = () => {
    searchInputPopup.close();
  };

  const batchListPanel = (
    <div className={`flex flex-col h-full`}>
      <div className={`${listContainer} h-full rounded invoicesList rounded p-2 bg-white`}>
        <div
          className={`flex justify-between items-center text-lg text-spenda-primarytext font-light px-4 py-2 font-poppins ${borderBottom}`}
        >
          <span>{'Authorise Payments'}</span>
          {authoriseBatchCount ? (
            <div className={`flex`}>
              <Formik initialValues={{searchText: '' as string}} onSubmit={onSubmitSearch} validateOnChange={false}>
                {formik => (
                  <Form translate="yes">
                    <ClickAwayListener onClickAway={handleClickAway}>
                      <div>
                        <div className="m-auto" {...bindToggle(searchInputPopup)}>
                          <img src={searchIcon} className="cursor-pointer" data-autoid="imgSearchIcon" />
                        </div>
                        <Popper {...bindPopper(searchInputPopup)} disablePortal={false} placement="left" transition>
                          {({TransitionProps}) => (
                            <Grow {...TransitionProps} timeout={250}>
                              <SearchInput
                                placeholder={'Search'}
                                {...formik}
                                onSubmit={formik.handleSubmit}
                                isAccountsPayable={true}
                              ></SearchInput>
                            </Grow>
                          )}
                        </Popper>
                      </div>
                    </ClickAwayListener>
                  </Form>
                )}
              </Formik>
            </div>
          ) : null}
        </div>
        {!authoriseBatchCount ? (
          <div className={` h-full rounded flex justify-center items-center bg-white relative`}>
            <span className="text-lg font-medium text-spenda-primarytext flex justify-center font-poppins">
              No batch available for payment authorisation
            </span>
          </div>
        ) : (
          <div className={`h-full flex justify-center items-start bg-white relative mt-2`}>
            <BatchList
              isLoading={isLoading}
              isAuthorisationList
              batchList={
                searchString
                  ? paymentBatches.filter(obj =>
                      Object.values(
                        pick(obj, ['approvedByUserName', 'dueDate', 'name', 'paymentAmount', 'refNumber', 'status'])
                      ).some(val => (val + '').toLocaleLowerCase().includes(searchString.toLocaleLowerCase()))
                    )
                  : paymentBatches
              }
              payBatch={payBatch}
              selectedBatchId={selectedBatchId}
              setSelectedBatchId={setSelectedBatchId}
            />
          </div>
        )}
      </div>
    </div>
  );

  const apMainPanel = (
    <BatchSupplierSummary
      selectedBatchId={selectedBatchId}
      setSelectedBatchId={setSelectedBatchId}
      batchDetail={
        selectedBatchId ? paymentBatches.find(batch => batch.accountsPayableBatchID === selectedBatchId) : undefined
      }
    />
  );
  const {user} = useContext(AppContext);
  const {
    getPaymentMethodAuthorisationDetails,
    approvePaymentAuthorisation,
    rejectPaymentAuthorisation,
    resetAuthenticationCode,
    getPaymentMethodAuthorisationRules,
  } = usePaymentMethods();
  const {checkBatchPaymentStatus, getPaymentBatch} = usePaymentsFromAP({});
  const history = useHistory();

  const handleCheckPaymentStatus = async (refID: number): Promise<IActionResults<ISupplierPaymentResponse>> => {
    const batchDetails = await getPaymentBatch(refID);
    return checkBatchPaymentStatus(batchDetails?.debits?.[0]?.transactionGUID);
  };

  return (
    <div className={`h-full relative overflow-hidden bg-spenda-newbg`}>
      {!authorisationId && batchID && (
        <PayBatch
          batchID={batchID}
          onClose={() => {
            setBatchID(undefined);
            setAuthorisationId(undefined);
          }}
          onPaymentApproved={() => {}}
          onPaymentFailed={() => getBatchesReadyForAuthorisation()}
          onDone={() => history.push(`${AUTH_BUYING_AP_REPORTS}/detail/${batchID}`)}
        />
      )}
      {authorisationId && (
        <PaymentWidgetModal
          userID={user?.UserID}
          tenantName={user?.TenantName}
          open={true}
          onClose={() => {
            setAuthorisationId(undefined);
            setBatchID(undefined);
          }}
          widgetScope={PaymentWidgetScope.AUHTORISE_PAYMENT}
          getPaymentMethodAuthorisationDetails={getPaymentMethodAuthorisationDetails}
          rejectPaymentAuthorisation={rejectPaymentAuthorisation}
          approvePaymentAuthorisation={approvePaymentAuthorisation}
          resetAuthenticationCode={resetAuthenticationCode}
          getPaymentMethodAuthorisationRules={getPaymentMethodAuthorisationRules}
          checkPaymentStatus={handleCheckPaymentStatus}
          authorisationId={authorisationId}
          isAccountsPayable
          onDone={() => {
            setAuthorisationId(undefined);
            history.push(`${AUTH_BUYING_AP_REPORTS}/detail/${batchID}`);
          }}
          isAPOnboardingV1={onboardingUpgradeV1}
        />
      )}
      {!isLoading ? (
        <Layout leftPanel={batchListPanel} mainPanel={apMainPanel} splitWidthType={'AR'} />
      ) : (
        <div className={`h-full relative overflow-hidden bg-spenda-newbg`}>
          <Layout splitWidthType={4} leftPanel={<LoadingIndicator isLoading={true} size="md" color="#1C78AD" />} />
        </div>
      )}
    </div>
  );
};
