import React, {FunctionComponent, ReactNode, ChangeEvent} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {Tabs, Tab, TabProps} from '@material-ui/core';
import clsx from 'clsx';
import {createAutoIdAttributeFromName} from '../../utils/createAutoIdAttribute';

interface TabData {
  label: string;
  content: ReactNode;
  disabled?: boolean;
}

interface ISTabs {
  v2?: boolean;
  className?: string;
  tabs: TabData[];
  value: number;
  handleChange: (newValue: number) => void;
  isInAPContext?: boolean;
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props: {value: number; index: number; className?: string; children: ReactNode}) {
  const {children, value, index, className, ...other} = props;

  return (
    <div
      className={`w-full ${value === index ? className : ''}`}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const StyledTabs = withStyles({
  indicator: {
    display: 'none',
  },
  root: {
    backgroundColor: '#EFEFEF80',
    borderRadius: '5px',
    padding: '3px 4px',
    minHeight: '44px',
    width: (props: any) => (props.v2 ? '510px' : 'auto'),
  },
})((props: any) => <Tabs {...props} TabIndicatorProps={{children: <span />}} />);

const StyledTab = withStyles(() => ({
  textColorInherit: {
    color: '#999999',
  },
  root: {
    color: '#333333',
    textTransform: 'none',
    padding: '5px 30px',
    minWidth: (props: any) => (props.isInAPContext ? '160px' : '200px'),
    fontSize: (props: any) => (props.isInAPContext ? '14px' : '16px'),
    fontWeight: 500,
    minHeight: (props: any) => (props.isInAPContext ? '40px' : '30px'),
    fontFamily: (props: any) => (props.isInAPContext ? 'poppins' : 'Saira Condensed'),
    '&:hover': {
      color: '#33333380 !important',
      boxShadow: '0 0 2px 0 #00000008',
    },
  },
  selected: {
    color: '#FFFFFF !important',
    backgroundColor: '#1C78AD',
    borderRadius: '5px',
    boxShadow: '0 0 2px 0 #00000008',
    '&:hover': {
      color: '#FFFFFF !important',
      boxShadow: '0 0 2px 0 #00000008',
    },
  },
}))((props: TabProps & {v2?: boolean} & {isInAPContext?: boolean}) => <Tab disableRipple {...props} />);

export const STabs: FunctionComponent<ISTabs> = props => {
  const {tabs, value, className, handleChange, v2, isInAPContext} = props;

  const handleTabClick = (event: ChangeEvent<{}>, value: number) => {
    handleChange(value);
  };

  return (
    <>
      <StyledTabs
        v2={v2}
        className={clsx({'mx-auto': v2})}
        scrollButtons="auto"
        value={value}
        onChange={handleTabClick}
        aria-label="styled tabs example"
        isInAPContext={isInAPContext}
      >
        {tabs.map((tab: TabData, index) => (
          <StyledTab
            v2={v2}
            disabled={Boolean(tab.disabled)}
            key={`stab-${index}`}
            label={tab.label}
            className={clsx({'!min-w-[123px] !max-w-[123px] !px-8 !py-2 !font-poppins !text-xs !font-medium': v2})}
            {...createAutoIdAttributeFromName(tab.label, 'btn')}
            {...a11yProps(index)}
            isInAPContext={isInAPContext}
          />
        ))}
      </StyledTabs>
      {tabs.map((tab: TabData, index) => (
        <TabPanel key={`stab-panel-${index}`} value={value} index={index} className={className || 'h-full'}>
          {tab.content}
        </TabPanel>
      ))}
    </>
  );
};
