import React, {useState, useRef} from 'react';
import {Formik, FormikProps, FormikHelpers} from 'formik';
import * as Yup from 'yup';

import {ICategory} from '../../model/inventory/Category';
import {CategoryForm} from '../form/CategoryForm';
import useCategoryAPI from '../../services/useCategoryAPI';
import KeyboardBackspaceRoundedIcon from '@material-ui/icons/KeyboardBackspaceRounded';
import {DatTypes} from '../../model/constants/Constants';
import {Typography, IconButton} from 'spenda-ui-react';
import {AlertDialogSlideV2} from './AlertDialogSlideV2';
import {CloseCross} from '../../assets/svg/CloseCross';

interface ICategoryDialogProps {
  type?: 'main' | 'parent';
  category: ICategory;
  open?: boolean;
  handleAddNewClick?: () => void;
  onClose: (refetchList?: boolean, newCategory?: ICategory) => void;
}

const validationSchema = Yup.object({
  Name: Yup.string()
    .required('Category Name is required.')
    .max(50, 'Category Name cannot be longer than 50 characters.'),
  Description: Yup.string().max(255, 'Category Description cannot be longer than 255 characters.'),
});

export const CategoryDialog: React.FunctionComponent<ICategoryDialogProps> = ({
  type = 'main',
  category,
  open,
  handleAddNewClick,
  onClose,
}: ICategoryDialogProps) => {
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState<boolean>(false);

  const {updateCategory, createCategory, dashboard} = useCategoryAPI();

  const formRef = useRef<FormikProps<ICategory>>(null);

  const handleCloseClick = (props: FormikProps<ICategory>, cancelClick: boolean = true) => {
    if (cancelClick && props.dirty) {
      setOpenConfirmationDialog(true);
    } else {
      onClose();
    }
  };

  const onSave = async (values: ICategory, actions: FormikHelpers<ICategory>): Promise<void> => {
    const categoryLit = await dashboard({
      DatTypeID: DatTypes.Category,
      LastRowNumber: 0,
      MaxResults: 1,
      Search: values.Name,
      IsExactMatch: true,
      SearchNameOnly: true,
    });
    if (values.ID) {
      if (categoryLit?.TotalRecordCount > 0 && categoryLit.Value[0]?.Name !== values.Name) {
        return Promise.reject();
      }
      return updateCategory(values).then(() => {
        onClose(true);
        actions.resetForm();
      });
    } else {
      if (categoryLit?.TotalRecordCount > 0) {
        return Promise.reject();
      }
      return createCategory(values).then((res: ICategory) => {
        if (handleAddNewClick) {
          onClose(true, res);
        } else {
          onClose(false, res);
        }
        actions.resetForm();
      });
    }
  };

  return (
    <>
      {openConfirmationDialog && (
        <AlertDialogSlideV2
          size="sm"
          title="Discard Changes"
          actions={[
            {label: 'Cancel', color: 'error', action: () => setOpenConfirmationDialog(false), variant: 'outlined'},
            {
              label: 'Discard',
              color: 'primary',
              action: () => {
                if (onClose) {
                  setOpenConfirmationDialog(false);
                  if (formRef && typeof formRef.current?.resetForm === 'function') {
                    const resetForm = formRef.current?.resetForm;
                    resetForm();
                  }
                  onClose();
                }
              },
            },
          ]}
          dialogActionsAlignment="justify-between"
        >
          <Typography className="pb-3 text-center font-medium">
            You have unsaved changes, are you sure you want to discard changes?
          </Typography>
        </AlertDialogSlideV2>
      )}
      {open && (
        <Formik
          innerRef={formRef}
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={category}
          onSubmit={onSave}
        >
          {props => (
            <AlertDialogSlideV2
              dialogClassess="!mx-5 !w-full !max-w-[96%] xl:!max-w-[950px]"
              dialogBodyClassess="h-[550px] flex-grow px-11 py-7"
              title={props.values.ID ? 'Edit Category' : props.values.ID ? 'Add New Category' : 'Add Parent Category'}
              headingClassess="font-semibold justify-center"
              headerChildren={
                <>
                  {type === 'parent' ? (
                    <IconButton
                      variant="text"
                      className="!absolute left-2 active:bg-transparent"
                      onClick={() => handleCloseClick(props, false)}
                    >
                      <KeyboardBackspaceRoundedIcon />
                    </IconButton>
                  ) : null}
                  <IconButton
                    variant="text"
                    onClick={() => handleCloseClick(props, false)}
                    className="!absolute right-2 active:bg-transparent"
                    ripple={false}
                    name="btnClose"
                  >
                    <CloseCross />
                  </IconButton>
                </>
              }
              actions={[
                {label: 'Cancel', color: 'error', action: () => handleCloseClick(props), variant: 'outlined'},
                {
                  label: props.values.ID ? 'Save Category' : 'Add Category',
                  action: () => props.handleSubmit(),
                  type: 'submit',
                  disabled: props.isSubmitting || !props.dirty,
                },
              ]}
              dialogActionsAlignment="justify-between"
            >
              <CategoryForm {...props} handleAddNewClick={handleAddNewClick} />
            </AlertDialogSlideV2>
          )}
        </Formik>
      )}
    </>
  );
};
