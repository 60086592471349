import React from 'react';
import moment from 'moment';
import Skeleton from 'react-loading-skeleton';
import {PriceFormat} from '../../../utils/formatter';
import {IInvoice, IRefBusTran} from '../../../model/invoice/Invoice';
import {ARTable} from '../ARTable';
import {useTransactionViewHook} from '../../../hooks/useARHook';
import {PaymentStatusBadge} from '../PaymentStatusBadge';
import {Typography} from 'spenda-ui-react';

interface IPaymentTemplateProps {
  tx?: IInvoice;
  isLoading: boolean;
}

export const PaymentTemplate = (props: IPaymentTemplateProps) => {
  const {tx, isLoading} = props;

  const {handleViewTx} = useTransactionViewHook();

  const columns = [
    {
      title: 'Date',
      key: 'AppliedDate',
      columClassName: 'font-poppins leading-loose !text-[10px] font-medium !text-black-800 text-left py-0 pl-2 pr-1',
      rowClassName: 'p-0 pl-2 pr-1',
      align: 'left',
      isSortable: false,
      rowRenderer: (rowData: IRefBusTran) => (
        <Typography variant="xsmall" data-autoid={`lblAppliedDate`} className="text-left leading-loose text-black-800">
          {moment(rowData?.AppliedDate).format('DD/MM/YYYY')}
        </Typography>
      ),
    },
    {
      title: 'Batch No.',
      key: 'RefNumber',
      columClassName: 'font-poppins leading-loose !text-[10px] font-medium !text-black-800 text-left pl-0 py-0 pr-1',
      rowClassName: 'p-0 pr-1',
      align: 'left',
      isSortable: false,
      rowRenderer: (rowData: IRefBusTran) => (
        <Typography
          variant="xsmall"
          data-autoid={`lblRefNumber`}
          className="cursor-pointer text-left leading-loose text-primary underline"
          onClick={() =>
            handleViewTx({txDatType: rowData?.TransTypeID, txGuid: rowData?.GUIDstring, txId: rowData?.ID}, true)
          }
        >
          {rowData?.RefNumber}
        </Typography>
      ),
    },
    {
      title: 'Applied Amount',
      key: 'AppliedAmount',
      columClassName: 'font-poppins !text-[10px] leading-loose font-medium !text-black-800 text-right py-0 pr-1',
      rowClassName: 'p-0 pr-1',
      align: 'right',
      isSortable: false,
      rowRenderer: (rowData: IRefBusTran) => (
        <Typography
          variant="xsmall"
          data-autoid={`lblAppliedAmount`}
          className="text-right leading-loose text-black-800"
        >
          {PriceFormat(rowData?.AppliedAmount)}
        </Typography>
      ),
    },
    {
      title: 'Balance',
      key: 'Balance',
      columClassName: 'font-poppins !text-[10px] leading-loose font-medium !text-black-800 text-right py-0 pr-2',
      rowClassName: 'p-0 pr-2',
      align: 'right',
      isSortable: false,
      rowRenderer: (rowData: IRefBusTran) => (
        <Typography variant="xsmall" data-autoid={`lblBalance`} className="text-right leading-loose text-black-800">
          {PriceFormat(rowData?.Balance)}
        </Typography>
      ),
    },
  ];

  const status = tx?.InvoicePaymentAllocations?.[0]?.Status || tx?.Status;

  return (
    <>
      <div className="flex h-auto w-full flex-col overflow-y-auto overflow-x-hidden bg-white px-[30px] pb-2.5 pt-[40px]">
        <div className="flex h-auto w-full flex-row items-start justify-between border-b-spenda-dashbg pb-3">
          <div className="flex w-[50%] flex-col">
            <Typography
              variant="xsmall"
              data-autoid="txtPaymentRefBold"
              className="mb-8 text-left text-xl font-bold leading-loose text-black-800"
            >
              Payment: {tx?.RefNumber}
            </Typography>
            <Typography variant="xsmall" data-autoid="txtPaymentRefNum" className="leading-loose text-black-800">
              Payment #: {tx?.RefNumber}
            </Typography>
            <Typography variant="xsmall" data-autoid="txtPaymentDate" className="leading-loose text-black-800">
              Payment date: {moment(tx?.DateTime).format('Do MMM YYYY')}
            </Typography>
            <Typography variant="xsmall" data-autoid="txtPaymentStatus" className="leading-loose text-black-800">
              Payment status: {status}
            </Typography>
          </div>
          <div className="flex w-[50%] flex-col">
            <PaymentStatusBadge isLoading={isLoading} status={status} />

            <Typography
              variant="xsmall"
              data-autoid="txtPaymentMethod"
              className="text-right leading-loose text-black-800"
            >
              Payment method: {tx?.Method}
            </Typography>

            <Typography variant="xsmall" className="text-right text-black-800">
              <Typography variant="xsmall" className="leading-loose" data-autoid="txtPaymentFee">
                {tx?.SurchargeAmount ? 'Surcharge ' : 'Processing fee '}
                {tx?.PayerFeeDescription
                  ? `(${tx?.PayerFeeDescription})`
                  : tx?.SurchageDescription
                    ? `(${tx?.SurchageDescription})`
                    : null}
                : {PriceFormat(tx?.Fee || tx?.SurchargeAmount || 0)}
              </Typography>
            </Typography>
          </div>
        </div>

        <>
          <Typography variant="paragraph" className="mb-4 mt-6 text-xl font-medium text-black-800">
            Invoice Allocations
          </Typography>
          <div className="mb-[20px] flex h-auto w-full flex-col">
            {tx && !isLoading ? (
              <>
                <ARTable
                  tableClass="!mx-0"
                  rows={tx?.RefBusTrans || []}
                  columns={columns}
                  isLoading={isLoading}
                  dataAutoId="PaymentTemplate"
                  getRowDataAutoId={rowData => `rowPaymentTemplate${rowData?.RefNumber}`}
                />
                {!tx?.RefBusTrans?.length && !isLoading && (
                  <div className={`m-2 flex justify-center pb-2 text-xs font-bold`}>
                    THERE ARE NO INVOICES ATTACHED TO THIS PAYMENT
                  </div>
                )}
                <div className="flex min-w-[200px] flex-row justify-between self-end p-[6px]">
                  <Typography variant="xsmall" className="text-black-800">
                    Total Applied:
                  </Typography>
                  <Typography variant="xsmall" className="text-black-800" data-autoid={'txtPaymentAppliedAmount'}>
                    {PriceFormat(tx?.AppliedAmount)}
                  </Typography>
                </div>

                <div className="flex min-w-[200px] flex-row justify-between self-end bg-[#E1E1E1] p-[6px]">
                  <Typography variant="xsmall" className="text-black-800">
                    Payment Balance:
                  </Typography>
                  <Typography variant="xsmall" className="text-black-800" data-autoid={'txtPaymentBalance'}>
                    {PriceFormat(tx?.Balance)}
                  </Typography>
                </div>
              </>
            ) : (
              <table className="mb-[20px]">
                <thead>
                  <tr className="border-t-spenda-dashbg bg-[#ddd]">
                    <th className="text-left font-poppins text-base font-normal text-black-800">
                      <Skeleton width={'90%'} />
                    </th>
                    <th className="text-left font-poppins text-base font-normal text-black-800">
                      <Skeleton width={'90%'} />
                    </th>
                    <th className="text-center font-poppins text-base font-normal text-black-800">
                      <Skeleton width={'90%'} />
                    </th>
                    <th className="text-right font-poppins text-base font-normal text-black-800">
                      <Skeleton width={'90%'} />
                    </th>
                  </tr>
                </thead>
                <tbody className="border-b-spenda-dashbg">
                  {[0, 1, 2, 3]?.map((tl, index) => (
                    <tr key={index}>
                      <td className="text-left font-poppins text-base font-normal text-black-800">
                        <Skeleton width={'90%'} />
                      </td>
                      <td className="text-left font-poppins text-base font-normal text-black-800">
                        <Skeleton width={'90%'} />
                      </td>
                      <td className="text-center font-poppins text-base font-normal text-black-800">
                        <Skeleton width={'90%'} />
                      </td>
                      <td className="text-right font-poppins text-base font-normal text-black-800">
                        <Skeleton width={'90%'} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </>
      </div>
    </>
  );
};
