import React, {useContext, useEffect, useRef, useState} from 'react';
import clsx from 'clsx';
import moment from 'moment';
import {ClickAwayListener} from '@material-ui/core';
import {Button, Typography} from 'spenda-ui-react';
import {WidgetDeleteLogo} from '../../assets/svg/WidgetDeleteLogo';
import {IntegrationContext} from '../../context/IntegrationContext';
import ARContext from '../../context/ar-context/ARContext';
import {usePsblStatementHook} from '../../hooks/useARHook';
import {ISelectedTransactionsList} from '../../model/accounts-receivable/AccountsReceivable';
import {
  ARCustomerStatementOperation,
  ARModals,
  ARStatementDeltasActions,
  ARStatementPeriodDefaultOptions,
  DatTypes,
} from '../../model/constants/Constants';
import {useCustomerStatementsAPI} from '../../services/useCustomerStatementsAPI';
import {PriceFormat, netPayableAmount} from '../../utils/formatter';
import APTooltip from '../data-display/APTootip';
import {ARDialogTemplate} from './ARDialogTemplate';
import {StatementDialog} from './StatementDialog';
import {WidgetPlaceHolder} from './WigetPlaceHolder';

export const PreviewSelectedTransactions = () => {
  // Hooks
  const buttonRef = useRef<HTMLDivElement>(null);

  // Context
  const {
    selectedStatementPeriod: ssp,
    setSelectedStatementPeriod,
    arModalToShow,
    setArModalToShow,
    psblStatement,
    setPsblStatement,
  } = useContext(ARContext);
  const {financialAdaptor} = useContext(IntegrationContext);

  // Constant
  const {accountCustomerId, selectedTxList, statementId, otherUserEditingStatementError} = psblStatement;
  const selectedStatementPeriod = parseInt(ssp.toString()) || undefined;

  // Custom Hooks
  const {updateStatement} = usePsblStatementHook();

  // APIs
  const {getCustomerAllTransactions, createStatement} = useCustomerStatementsAPI();

  // State
  const [isShowCreateStatementModal, setIsShowCreateStatementModal] = useState(false);
  const [isShowCancelStatementDialog, setIsShowCancelStatementDialog] = useState(false);
  const [isOpenManualPushWarning, setIsOpenManualPushWarning] = React.useState(false);

  // useEffect
  useEffect(() => {
    try {
      const selectAllCheckBox = document.getElementById('ARCustomerFocusSelectAllCheckBox');
      if (selectAllCheckBox?.classList?.contains('selectAll-true') && buttonRef?.current?.children?.[0]?.children?.[0])
        buttonRef.current.children[0].children[0].innerHTML = 'Create statement';
      else if (selectAllCheckBox?.classList?.contains('selectAll-false') && buttonRef.current)
        buttonRef.current.children[0].children[0].innerHTML = 'Create arrangement';
    } catch {}
  });

  useEffect(() => {
    if (arModalToShow?.modal === ARModals.CreateStatementModal) setIsShowCreateStatementModal(true);
  }, [arModalToShow?.modal]);

  // Functions
  const handleClick = () => {
    setIsShowCreateStatementModal(true);
  };

  const getSelectedInvoiceTotal = () => {
    const totals = selectedTxList?.reduce(
      (acc, st) => {
        if (st?.transactionTypeID === DatTypes.Invoice) {
          acc.totalInvoiceAmount = acc.totalInvoiceAmount + (st?.amountToPay ? st?.amountToPay : st?.balance);
        } else if ([DatTypes.CreditNote, DatTypes.Claim, DatTypes.Payment].includes(st?.transactionTypeID)) {
          acc.totalCreditAndClaimsSelected = acc.totalCreditAndClaimsSelected + st?.balance;
        }
        return acc;
      },
      {totalInvoiceAmount: 0, totalCreditAndClaimsSelected: 0},
    );
    const previewTotal = netPayableAmount(totals.totalInvoiceAmount, totals.totalCreditAndClaimsSelected);
    return PriceFormat(parseFloat(previewTotal?.toFixed(2)));
  };

  const removeSelectedTransaction = async (transData: ISelectedTransactionsList) => {
    if (accountCustomerId && statementId) {
      const payload = {
        statementID: statementId,
        accountCustomerID: accountCustomerId,
        statementDeltas: [
          {
            action: ARStatementDeltasActions.REMOVE,
            datTypeID: transData?.transactionTypeID?.toString(),
            id: transData?.id,
          },
        ],
        operation: ARCustomerStatementOperation.UPDATE,
      };
      await updateStatement(payload);
    }
  };

  const onClickEditStatement = async () => {
    try {
      if (statementId || !selectedStatementPeriod || !accountCustomerId) return;
      const payload = {
        statementID: selectedStatementPeriod,
        accountCustomerID: accountCustomerId,
        operation: ARCustomerStatementOperation.OPEN,
      };

      const openStatementResponse = await createStatement(payload);
      if (!openStatementResponse) return;

      const {statementID, accountCustomerID} = openStatementResponse;
      const query = {editingStatementID: statementID, accountCustomerID};
      const custAllTransactions = await getCustomerAllTransactions(query);

      const selectedTransactionsList = custAllTransactions?.statementSummary?.transactions?.map(st => {
        return {
          transactionType: st.datTypeName,
          balance: st.balance,
          dueDate: st.dueDate,
          transDate: st.transactionDate,
          refNumber: st.refNumber,
          totalInc: st.totalInc,
          id: st.id,
          transactionTypeID: st.datTypeInteger,
          accountCustomerName: st.accountCustomerName,
        };
      });

      setPsblStatement({
        accountCustomerId: accountCustomerID,
        selectedTxList: selectedTransactionsList ?? [],
        statementId: statementID,
      });
    } catch {}
  };

  const handleCancel = async () => {
    try {
      if (!accountCustomerId || !statementId) return;
      const payload = {
        statementID: statementId,
        accountCustomerID: accountCustomerId,
        operation: ARCustomerStatementOperation.CANCEL,
      };
      const cancelStatementResponse = await createStatement(payload);
      if (!cancelStatementResponse) return;
      setSelectedStatementPeriod(ARStatementPeriodDefaultOptions.ALL);
      setPsblStatement({
        ...psblStatement,
        selectedTxList: [],
        statementId: undefined,
      });
      setIsShowCancelStatementDialog(false);
    } catch {}
  };

  const handleClickAway = () => {
    setIsOpenManualPushWarning(false);
  };

  return (
    <div className="relative h-[inherit] w-[inherit]">
      <div className="relative flex h-full w-full flex-col pb-16">
        <div className="buttonSet flex w-full flex-row items-center">
          {selectedStatementPeriod && !statementId && (
            <button
              disabled={!!statementId}
              onClick={onClickEditStatement}
              className="w-full bg-[white] px-3 pb-3 pt-4 text-left font-poppins text-xl text-spenda-primarytext"
            >
              Edit statement
            </button>
          )}
          {!!selectedTxList?.length && (
            <button className="w-full bg-[white] px-3 pb-3 pt-4 text-left font-poppins text-xl text-spenda-primarytext">
              Process statement
            </button>
          )}
        </div>
        {!!selectedTxList?.length ? (
          <>
            <div className="processBatchBody flex h-full w-full flex-col justify-between">
              <div className="mt-2.5 flex h-full w-full flex-col items-center">
                <div className="processBatchHeading mb-2 flex w-full items-center justify-between border-[#F1F1F1] border-b-default px-3 pb-4 pt-1">
                  <Typography variant="paragraph" data-autoid={`lblPayInvoice`} className="font-medium  text-[#707070]">
                    {selectedTxList?.length > 1
                      ? `Pay invoices(${selectedTxList?.length})`
                      : `Pay invoice(${selectedTxList?.length})`}
                  </Typography>
                  <div className="flex flex-row items-center">
                    <Typography
                      variant="paragraph"
                      data-autoid={`lblSelectedInvoiceTotal`}
                      className="font-semibold text-[#3C9F78]"
                    >
                      {getSelectedInvoiceTotal()}
                    </Typography>
                  </div>
                </div>
                <div className="mb-[160px] flex w-full flex-col overflow-auto">
                  {selectedTxList?.map(st => {
                    const isCreditNoteOrPayment = [DatTypes.CreditNote, DatTypes.Payment].includes(
                      st.transactionTypeID,
                    );
                    const isInvoice = st?.transactionTypeID === DatTypes.Invoice;

                    return (
                      <div
                        key={`transactionPreview${st.id}`}
                        className="processBatchData flex w-full flex-row items-center justify-between border-[#F1F1F1] border-b-default px-2.5 py-4"
                      >
                        <div className="flex flex-col">
                          <Typography
                            variant="paragraph"
                            data-autoid={`lblAccountCustomerName`}
                            className="mb-[2px] font-medium text-black-800"
                          >
                            {st?.accountCustomerName}
                          </Typography>
                          <Typography
                            variant="xsmall"
                            data-autoid={`lblTransactionType&RefNumber`}
                            className=" mb-[2px] text-black-800"
                          >
                            {st?.transactionType}: {st?.refNumber}
                          </Typography>
                          <Typography variant="xsmall" data-autoid={`lblTotalInc`} className=" text-black-800">
                            {`Total :  ${
                              isCreditNoteOrPayment ? `(${PriceFormat(st?.totalInc)})` : PriceFormat(st?.totalInc)
                            }`}
                          </Typography>
                        </div>
                        <div className="flex flex-row items-center">
                          <div className="mr-5 flex flex-col items-center">
                            <Typography
                              variant="paragraph"
                              data-autoid={`lblBalance`}
                              className="mb-[4px] font-semibold text-[#444343]"
                            >
                              {isCreditNoteOrPayment
                                ? `(${PriceFormat(st?.balance)})`
                                : PriceFormat(st?.amountToPay || st?.balance)}
                            </Typography>
                            <div
                              data-autoid={`lblDate`}
                              className={clsx(
                                `whitespace-nowrap rounded-[4px] px-[16px] py-[4px] font-poppins text-xs font-medium`,
                                {
                                  'bg-[#D3E5EF50] text-primary': isCreditNoteOrPayment,
                                },
                                {
                                  'bg-[#F8E1DC] text-[#C55D44]': isInvoice,
                                },
                              )}
                            >
                              {isCreditNoteOrPayment &&
                                `Received on - ${st?.transDate ? moment(st?.transDate).format('Do MMM YYYY') : ''}`}
                              {isInvoice && `Due on - ${st.dueDate ? moment(st.dueDate).format('Do MMM YYYY') : ''}`}
                            </div>
                          </div>
                          <Typography
                            variant="xsmall"
                            className="inline"
                            data-autoid={`btnWidgetDelete`}
                            onClick={() => removeSelectedTransaction(st)}
                          >
                            <WidgetDeleteLogo className="cursor-pointer" />
                          </Typography>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="processBatchFooter h-15 absolute bottom-0 left-2.5 mb-[70px] flex w-[394px] flex-row items-center justify-between rounded-[8px] bg-[#f0f0f0] p-2.5">
                <Button
                  data-autoid={`btnCancel`}
                  className="bg-white"
                  onClick={() => setIsShowCancelStatementDialog(true)}
                  variant="outlined"
                  color="primary"
                >
                  Cancel
                </Button>
                <div className="flex flex-row items-center gap-3">
                  <ClickAwayListener onClickAway={handleClickAway}>
                    <APTooltip
                      title={`Batches with a $0 balance cannot be posted to ${financialAdaptor?.Name} so will need to be entered manually. To ensure the batch posts automatically, remove some credit notes or add more invoices to the batch.`}
                      open={isOpenManualPushWarning}
                      placement="left"
                      arrow
                      interactive
                    >
                      <div ref={buttonRef}>
                        <Button
                          variant="filled"
                          color="primary"
                          data-autoid={`btnCreateStatement`}
                          onClick={handleClick}
                        >
                          Create statement
                        </Button>
                      </div>
                    </APTooltip>
                  </ClickAwayListener>
                </div>
              </div>
            </div>
          </>
        ) : (
          <WidgetPlaceHolder placeholder="Selected invoices will appear here" />
        )}
      </div>

      {/* AR Preview Selected Transaction Widget Modals */}
      {!!accountCustomerId && !!selectedTxList?.length && isShowCreateStatementModal && (
        <StatementDialog customerId={accountCustomerId} handleClose={() => setIsShowCreateStatementModal(false)} />
      )}
      {arModalToShow?.modal === ARModals.IsOtherUserEditingStatement && (
        <ARDialogTemplate
          containerClass="!w-[374px] !px-2.5"
          header={<p className=" font-poppins text-xl font-light text-[#333333]"> Editing in progress</p>}
          body={
            <div className="my-12">
              <Typography variant="paragraph" className="text-center font-light text-black-800">
                {otherUserEditingStatementError
                  ? otherUserEditingStatementError
                  : 'Other user is currently editing this statement and cannot be changed until they have sent it.'}
              </Typography>
            </div>
          }
          footer={
            <Button
              variant="filled"
              color="primary"
              data-autoid={`btnDone`}
              onClick={() => setArModalToShow(undefined)}
            >
              Done
            </Button>
          }
          handleClose={() => setArModalToShow(undefined)}
        />
      )}
      {isShowCancelStatementDialog && (
        <ARDialogTemplate
          containerClass="!px-2.5"
          handleClose={() => setIsShowCancelStatementDialog(false)}
          header={<p className=" font-poppins text-xl font-light text-[#333333]">Confirm</p>}
          body={
            <div className="my-12">
              <Typography variant="paragraph" className="text-center">
                {`There is a statement in progress. Are you sure you want to cancel the statement?`}
              </Typography>
            </div>
          }
          footer={
            <div className="flex w-full flex-row items-center justify-between">
              <Button
                variant="outlined"
                data-autoid="btnCancel"
                className="bg-[#ffff]"
                onClick={() => setIsShowCancelStatementDialog(false)}
              >
                Cancel
              </Button>
              <Button data-autoid="btnDiscardBatch" onClick={handleCancel} variant="filled" color="error">
                Discard
              </Button>
            </div>
          }
        />
      )}
    </div>
  );
};
