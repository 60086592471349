import React from 'react';
import {FormikProps} from 'formik';
import {Box, Grid, Divider, makeStyles} from '@material-ui/core';
import {Button, Radio} from 'spenda-ui-react';

import {STextField} from '../inputs/STextField';
import {ICustomer} from '../../model/customer/Customer';
import {AddLocationForm} from './AddressLocation';
import {ContactDetailsForm} from './ContactDetailsForm';
import {ICreateCustomerClassValues} from '../../model/customer/CustomerClasses';
import {CustomerClassesAutocomplete} from '../customer/CustomerAutocomplete';
import {useCustomersAPI} from '../../services/useCustomersAPI';

const useAccountCustomerFormStyle = makeStyles(() => ({
  formLabel: {
    fontSize: '1rem',
    color: '#333333',
    paddingBottom: '1.5rem',
  },
  sideFormLabel: {
    fontSize: '1rem',
    color: '#333333',
    paddingBottom: '1rem',
  },
  sideFormValue: {
    fontSize: '1rem',
    color: '#999999',
    paddingBottom: '1rem',
  },
  title: {
    fontWeight: 500,
    fontSize: '1.5em',
    color: '#4D4D4D',
  },
  subTitle: {
    fontWeight: 400,
    fontSize: '1.25em',
    marginTop: '20px',
  },

  rightSubTitle: {
    fontWeight: 500,
    fontSize: '1em',
    marginTop: '20px',
    marginBottom: '42px',
    color: '#999999',
  },
  sideFormValueLink: {
    fontSize: '0.875rem',
    color: '#333333',
    paddingBottom: '1rem',
  },
  sideFormLabelLink: {
    fontSize: '0.875rem',
    color: '#999999',
    paddingBottom: '1rem',
  },
  marginBottom: {
    marginBottom: '48px',
  },
  red: {
    color: 'ref',
  },
}));

export const AddAccountCustomerForm = (
  props: FormikProps<ICustomer> & {
    customerToEdit: ICustomer;
    accountData: ICustomer;
    customerId: any;
    setRefNumberChange: any;
    operationalCustomers?: ICustomer[];
    setRemoveClass: (x: boolean) => void;
    unlinkCustomer: (x: ICustomer) => void;
  },
) => {
  const {getAccountCustomerByReferenceNo} = useCustomersAPI();

  const classes = useAccountCustomerFormStyle();

  const {values, touched, errors, handleChange, handleBlur} = props;

  return (
    <>
      <Box
        display="flex"
        flex="1"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="start"
        padding="0 20px 0 10px"
      >
        <Grid container spacing={1} className="py-5">
          <Grid item sm={8}>
            <Box display="flex" flexDirection="column" marginY="10px">
              <div className="flex gap-x-3 text-spenda-primarytext">
                <Radio
                  name="IsIndividual"
                  onChange={handleChange}
                  value="true"
                  label="Individual"
                  color="primary"
                  containerProps={{
                    className: '-ml-3',
                  }}
                  className="border-primary before:hover:opacity-0"
                  labelProps={{
                    className: 'text-spenda-primarytext font-normal',
                  }}
                />
                <Radio
                  name="IsIndividual"
                  onChange={handleChange}
                  value="false"
                  label="Company"
                  defaultChecked
                  color="primary"
                  className="border-primary before:hover:opacity-0"
                  labelProps={{
                    className: 'text-spenda-primarytext font-normal',
                  }}
                />
              </div>
              <Divider className="w-full" style={{height: '2px !important', margin: '10px 0px'}} />
              <Grid container alignItems="flex-end" className="flex" spacing={1} sm={11} item={true}>
                {values.IsIndividual === 'false' && (
                  <>
                    <Grid item sm={2}>
                      <p className={`${classes.formLabel}`}>Company Name:</p>
                    </Grid>
                    <Grid item sm={3}>
                      <STextField
                        id="Name"
                        name="Name"
                        placeholder="Company Name"
                        value={values.Name}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        helperText={touched?.Name && errors?.Name}
                        error={touched?.Name && Boolean(errors?.Name)}
                        fullWidth
                      />
                    </Grid>
                  </>
                )}
                {values.IsIndividual === 'false' && <Grid item sm={1} />}
                <Grid item sm={2}>
                  <p className={`${classes.formLabel}`}>Account Ref:</p>
                </Grid>
                <Grid item sm={3}>
                  <STextField
                    id="RefNumber"
                    name="RefNumber"
                    placeholder="Account Ref"
                    value={values.RefNumber}
                    onBlur={e => {
                      handleBlur(e);
                      getAccountCustomerByReferenceNo(values?.RefNumber || '').then(data => {
                        if (data.length === 1 && values?.RefNumber) {
                          props.setRefNumberChange(true);
                        } else {
                          props.setRefNumberChange(false);
                        }
                      });
                    }}
                    onChange={e => {
                      handleChange(e);
                      getAccountCustomerByReferenceNo(values?.RefNumber || '').then(data => {
                        if (data.length === 1 && values?.RefNumber) {
                          props.setRefNumberChange(true);
                        } else {
                          props.setRefNumberChange(false);
                        }
                      });
                    }}
                    helperText={touched?.RefNumber && errors?.RefNumber}
                    error={touched?.RefNumber && Boolean(errors?.RefNumber)}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container alignItems="flex-end" className="flex" spacing={1} sm={11} item={true}>
                <Grid item sm={2}>
                  <p className={`${classes.formLabel}`}>ABN:</p>
                </Grid>
                <Grid item sm={3}>
                  <STextField
                    id="ABN"
                    name="ABN"
                    placeholder="ABN"
                    value={values.ABN}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    helperText={touched?.ABN && errors?.ABN}
                    error={touched?.ABN && Boolean(errors?.ABN)}
                    fullWidth
                  />
                </Grid>
                <Grid item sm={1} />
                {values.IsIndividual === 'false' && (
                  <>
                    <Grid item sm={2}>
                      <p className={`${classes.formLabel}`}>Phone No:</p>
                    </Grid>
                    <Grid item sm={3}>
                      <STextField
                        id="Phone1"
                        name="Phone1"
                        placeholder="Phone No"
                        value={values.Phone1}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        helperText={touched?.Phone1 && errors?.Phone1}
                        error={touched?.Phone1 && Boolean(errors?.Phone1)}
                        fullWidth
                      />
                    </Grid>
                  </>
                )}
              </Grid>
              <Divider
                className="w-full"
                style={{height: '2px !important', backgroundColor: '#F1F1F1', margin: '20px 0px'}}
              />
              <ContactDetailsForm {...props} />
              <Divider
                className="w-full"
                style={{height: '2px !important', backgroundColor: '#F1F1F1', margin: '20px 0px'}}
              />
              <p className={`${classes.title}`}>Merchant Surcharge</p>
              <Grid container sm={11} spacing={1} item={true} alignItems="center">
                <Grid item sm={4}>
                  <p>Exempt from all merchant surcharges.</p>
                </Grid>
                <Grid item sm={7}>
                  <div className="flex gap-x-3 text-spenda-primarytext">
                    <Radio
                      name="IsExemptFromMerchantSurcharge"
                      onChange={handleChange}
                      value="true"
                      label="Yes"
                      color="primary"
                      className="border-primary before:hover:opacity-0"
                      labelProps={{
                        className: 'text-spenda-primarytext font-normal',
                      }}
                    />
                    <Radio
                      name="IsExemptFromMerchantSurcharge"
                      onChange={handleChange}
                      value="false"
                      label="No"
                      defaultChecked
                      color="primary"
                      className="border-primary before:hover:opacity-0"
                      labelProps={{
                        className: 'text-spenda-primarytext font-normal',
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
              <Divider
                className="w-full"
                style={{height: '2px !important', backgroundColor: '#F1F1F1', margin: '20px 0px'}}
              />

              <AddLocationForm {...props} customerID={props.customerId} />
            </Box>
          </Grid>

          <Grid
            item
            sm={4}
            className="flex flex-col"
            style={{
              border: '1px solid #EAEAEA',
              backgroundColor: '#FAFAFA',
              height: 'max-content',
              padding: '20px 24px',
            }}
          >
            <LinkedOperationalCustomer {...props} />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export const LinkedOperationalCustomer = (
  props: FormikProps<ICustomer> & {
    customerToEdit: ICustomer;
    operationalCustomers?: ICustomer[];
    setRemoveClass: (x: boolean) => void;
    unlinkCustomer: (x: ICustomer) => void;
    isUIUpdate?: boolean;
  },
) => {
  const classes = useAccountCustomerFormStyle();

  const {values, errors, setValues, operationalCustomers, setRemoveClass, unlinkCustomer, isUIUpdate} = props;

  const details = props.customerToEdit;

  const handleCustomerClassFilter = (_setFieldValue: any, selected: Partial<ICreateCustomerClassValues> | null) => {
    if (selected) {
      setValues({
        ...values,
        ClassID: selected.ID,
        CustomerClass: selected,
      });
    } else {
      setValues({
        ...values,
        ClassID: undefined,
        CustomerClass: undefined,
      });
    }
  };

  return (
    <div className="align-center flex w-full flex-col justify-center">
      <p className={isUIUpdate ? `font-poppins text-xl font-medium text-black-800` : `${classes.title}`}>
        Linked Operational Customers{' '}
      </p>

      {operationalCustomers?.length ? (
        operationalCustomers?.map(operationalCustomer => (
          <div
            key={operationalCustomer.ID}
            className="my-2 flex flex-row items-center justify-between rounded border border-primary p-2"
          >
            <span
              data-autoid={'txtCompanyName'}
              className={isUIUpdate ? `font-poppins text-base font-medium text-black-800` : ''}
            >
              {operationalCustomer.CompanyName}
            </span>
            {operationalCustomer.BillToCustomerID ? (
              <Button
                size={isUIUpdate ? 'md' : 'sm'}
                loading={props.isSubmitting}
                disabled={props.isSubmitting}
                onClick={e => {
                  unlinkCustomer(operationalCustomer);
                  setRemoveClass(false);
                  e.stopPropagation();
                }}
              >
                Unlink
              </Button>
            ) : null}
            {operationalCustomer.ClassID ? (
              <Button
                size={isUIUpdate ? 'md' : 'sm'}
                loading={props.isSubmitting}
                disabled={props.isSubmitting}
                onClick={e => {
                  unlinkCustomer(operationalCustomer);
                  setRemoveClass(true);
                  e.stopPropagation();
                }}
              >
                Remove Class
              </Button>
            ) : null}
          </div>
        ))
      ) : (
        <p className={isUIUpdate ? `font-poppins text-base font-medium text-black-800` : `${classes.rightSubTitle}`}>
          No linked operational customers
        </p>
      )}

      <Grid container spacing={1} className={`${classes.marginBottom}`}>
        <Grid item sm={4}>
          <p className={isUIUpdate ? `font-poppins text-base font-normal text-black-800` : `${classes.sideFormLabel}`}>
            Customer Class:{' '}
          </p>
        </Grid>

        <Grid item sm={8}>
          <CustomerClassesAutocomplete
            customerClassType={true}
            selected={values.ClassID ? values.CustomerClass : null}
            clearClick={() => handleCustomerClassFilter(setValues, null)}
            onSuggestionSelected={(e, selected) => handleCustomerClassFilter(setValues, selected.suggestion)}
          />
          {errors?.ClassID && (
            <p className={isUIUpdate ? `font-poppins text-[12px] font-normal text-spenda-error` : classes.red}>
              {errors?.ClassID}
            </p>
          )}
        </Grid>
      </Grid>

      <Grid container spacing={1} className={`${classes.marginBottom}`}>
        <Grid container sm={4} item={true} className="flex" direction="column">
          <p
            className={
              isUIUpdate ? `font-poppins text-base font-normal text-spenda-labeltext` : `${classes.sideFormLabelLink}`
            }
          >
            Available Credit:{' '}
          </p>
          <p
            className={
              isUIUpdate ? `font-poppins text-base font-normal text-black-800` : `${classes.sideFormValueLink}`
            }
          >
            {details?.CreditLimitString}
          </p>
        </Grid>
        <Grid container sm={4} item={true} className="flex" direction="column" alignContent="center">
          <p
            className={
              isUIUpdate ? `font-poppins text-base font-normal text-spenda-labeltext` : `${classes.sideFormLabelLink}`
            }
          >
            Overdue Invoices:{' '}
          </p>
          <p
            className={
              isUIUpdate ? `font-poppins text-base font-normal text-black-800` : `${classes.sideFormValueLink}`
            }
          >
            -
          </p>
        </Grid>

        <Grid container sm={4} item={true} className="flex" direction="column" alignContent="flex-end">
          <p
            className={
              isUIUpdate ? `font-poppins text-base font-normal text-spenda-labeltext` : `${classes.sideFormLabelLink}`
            }
          >
            Unpaid Invoices:{' '}
          </p>
          <p
            className={
              isUIUpdate ? `font-poppins text-base font-normal text-black-800` : `${classes.sideFormValueLink}`
            }
          >
            $0.00
          </p>
        </Grid>
      </Grid>
    </div>
  );
};
