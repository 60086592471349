import * as React from 'react';
const APOnbPaymentMethodIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg {...props}>
    <path
      fill="hsl(var(--primary))"
      fillRule="evenodd"
      d="M4.167.6h11.666A4.172 4.172 0 0 1 20 4.766v6.667a4.172 4.172 0 0 1-4.167 4.167H4.167A4.172 4.172 0 0 1 0 11.433V4.766A4.172 4.172 0 0 1 4.167.6Zm11.666 1.666H4.167a2.5 2.5 0 0 0-2.5 2.5h16.666a2.5 2.5 0 0 0-2.5-2.5Zm0 11.667H4.167a2.5 2.5 0 0 1-2.5-2.5v-5h16.666v5a2.5 2.5 0 0 1-2.5 2.5Zm-11.25-1.667a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default APOnbPaymentMethodIcon;
