import React, {FunctionComponent, useContext, useState, useCallback} from 'react';
import {debounce, isEqual, omit} from 'lodash';
import clsx from 'clsx';

import AppContext from '../../context/app/appContext';
import {
  PurchaseOrderDashboardViewMode,
  PurchaseOrderStatus,
  RequisitionStatusType,
} from '../../model/constants/Constants';
import {IRequisitionFilter} from '../../model/search-filters/RequisitionSearchFilter';
import {FilterMenu, FilterMenuItem} from '../menu/FilterMenu';
import {STableToolbar} from '../tables/STableToolbar';
import {PrimaryButton} from '../buttons/DefaultButtons';

import {ClickAwayListener, Grow, IconButton, Popper, TextField, makeStyles} from '@material-ui/core';
import IconSearch from '../../assets/svg/IconSearch';
import IconFilter from '../../assets/svg/IconFilter';
import IconSort from '../../assets/svg/IconSort';

import {usePopupState, bindMenu, bindTrigger, bindToggle, bindPopper} from 'material-ui-popup-state/hooks';

export interface IPurchaseOrderToolbar {
  viewMode: PurchaseOrderDashboardViewMode;
  title: string | React.ReactNode;
  loading: boolean;
  searchFilter: IRequisitionFilter;
  setSearchFilter: (filter: any) => void;
  handleCreate: () => void;
}

const usePurchaseOrderToolbarStyles = makeStyles(() => ({
  title: {
    fontWeight: 500,
    fontSize: '1.25em',
    color: '#4D4D4D',
    lineHeight: 1.1,
  },
  iconButton: {
    color: '#1C78AD',
    border: '1px solid #1C78AD',
    borderRadius: '6px',
    padding: '5px',
    margin: '0 0.25rem',
    width: 40,
    height: 40,
  },
  iconButtonActive: {
    borderRadius: '0 6px 6px 0',
    backgroundColor: '#1C78AD',
    '& path': {
      fill: '#FFFFFF',
    },
    '&:hover': {
      borderRadius: '0 6px 6px 0',
      backgroundColor: '#1C78AD',
    },
  },
  textfield: {
    fontFamily: 'Poppins',
    minWidth: '300px',
    '& .MuiOutlinedInput-root': {
      border: '1px solid #1C78AD',
      borderRadius: '6px 0 0 6px',
    },
    '& .MuiInputBase-root': {
      color: '#333333',
      padding: '0 5px',
      height: '100%',
      '&::placeholder': {
        color: '#BBBBBB',
        fontWeight: 600,
      },
    },
  },
}));

const mapSortField = {
  [PurchaseOrderDashboardViewMode.Requisitions]: 'createddatetimeutc',
  [PurchaseOrderDashboardViewMode.PurchaseOrders]: 'CreatedDateTime',
  [PurchaseOrderDashboardViewMode.DeliveryReceipts]: 'CreatedDateTime_UTC',
};

export const PurchaseOrderToolbar: FunctionComponent<IPurchaseOrderToolbar> = props => {
  const classes = usePurchaseOrderToolbarStyles();
  const {tenantInfo} = useContext(AppContext);

  const userId = tenantInfo?.TenantUserDetails?.UserID;

  const [searchInput, setSearchInput] = useState<string>('');

  const {title, viewMode, searchFilter, handleCreate, setSearchFilter} = props;

  const filterPopupState = usePopupState({variant: 'popover', popupId: 'filterPopupState'});
  const sortPopupState = usePopupState({variant: 'popover', popupId: 'sortPopupState'});
  const searchInputPopup = usePopupState({variant: 'popover', popupId: 'searchInputPopup'});

  const debouncedSearch = useCallback(
    debounce(
      (newValue: string) => setSearchFilter({...omit(searchFilter, ['LastRowNumber']), SearchString: newValue}),
      500,
    ),
    [searchFilter],
  );

  const handleSort = (sortAsc: string, sortField?: string) => {
    if (setSearchFilter) {
      setSearchFilter({
        ...omit(searchFilter, ['LastRowNumber', 'SortField']),
        SortField: sortField || mapSortField[viewMode],
        SortAsc: sortAsc,
      });
      sortPopupState.close();
    }
  };

  const handleFilter = (filter: Partial<IRequisitionFilter>) => {
    if (setSearchFilter) {
      setSearchFilter({
        ...omit(searchFilter, ['LastRowNumber', 'ViewForUserID', 'CreatedByUserID', 'ModifiedByUserID']),
        ...filter,
      });
      sortPopupState.close();
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
    debouncedSearch(e.target.value);
  };

  const handleClickAway = () => {
    if (!searchInput) {
      searchInputPopup.close();
    }
  };

  return (
    <STableToolbar>
      <div className="flex w-full items-center justify-between">
        <p className="text-xl font-light text-spenda-primarytext">{title}</p>
        <div style={{color: '#0082BA'}} className="flex">
          <ClickAwayListener onClickAway={handleClickAway}>
            <div>
              <IconButton
                disableRipple
                className={clsx(classes.iconButton, {
                  [classes.iconButtonActive]: searchInputPopup.isOpen,
                })}
                data-autoid="btnSearch"
                {...bindToggle(searchInputPopup)}
              >
                <IconSearch />
              </IconButton>
              <Popper {...bindPopper(searchInputPopup)} disablePortal={false} placement="left" transition>
                {({TransitionProps}) => (
                  <Grow {...TransitionProps} timeout={250}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      placeholder={
                        viewMode === PurchaseOrderDashboardViewMode.DeliveryReceipts
                          ? 'Search by supplier or ref number'
                          : 'Search...'
                      }
                      className={clsx(classes.textfield)}
                      value={searchInput}
                      onChange={handleSearchChange}
                      inputProps={{
                        'data-autoid': 'txtSearch',
                      }}
                    />
                  </Grow>
                )}
              </Popper>
            </div>
          </ClickAwayListener>
          <IconButton
            disableRipple
            className={classes.iconButton}
            data-autoid="btnFilterRequisition"
            {...bindTrigger(filterPopupState)}
          >
            <IconFilter />
          </IconButton>
          <FilterMenu
            {...bindMenu(filterPopupState)}
            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
            transformOrigin={{vertical: 'top', horizontal: 'right'}}
          >
            {viewMode === PurchaseOrderDashboardViewMode.Requisitions && (
              <FilterMenuItem
                selected={
                  isEqual(searchFilter.Status, [RequisitionStatusType.New, RequisitionStatusType.Pending]) &&
                  !Boolean(searchFilter.ViewForUserID)
                }
                onClick={() =>
                  handleFilter({
                    Status:
                      isEqual(searchFilter.Status, [RequisitionStatusType.New, RequisitionStatusType.Pending]) &&
                      !Boolean(searchFilter.ViewForUserID)
                        ? [
                            RequisitionStatusType.New,
                            RequisitionStatusType.Pending,
                            RequisitionStatusType.Approved,
                            RequisitionStatusType.Processed,
                          ]
                        : [RequisitionStatusType.New, RequisitionStatusType.Pending],
                  })
                }
                data-autoid="mnuRequiredApproval"
              >
                Requires approval
              </FilterMenuItem>
            )}
            {viewMode === PurchaseOrderDashboardViewMode.Requisitions && (
              <FilterMenuItem
                selected={Boolean(searchFilter.ViewForUserID)}
                onClick={() => {
                  handleFilter({
                    ...(!Boolean(searchFilter.ViewForUserID) ? {ViewForUserID: userId} : {}),
                    Status: [
                      RequisitionStatusType.New,
                      RequisitionStatusType.Pending,
                      RequisitionStatusType.Approved,
                      RequisitionStatusType.Processed,
                    ],
                  });
                }}
                data-autoid="mnuMyRequisition"
              >
                My Requisitions
              </FilterMenuItem>
            )}

            {viewMode === PurchaseOrderDashboardViewMode.PurchaseOrders && (
              <FilterMenuItem
                selected={isEqual(searchFilter.Status, [PurchaseOrderStatus.Open])}
                onClick={() => {
                  handleFilter({
                    Status: isEqual(searchFilter.Status, [PurchaseOrderStatus.Open])
                      ? [
                          PurchaseOrderStatus.Approved,
                          PurchaseOrderStatus.Cancelled,
                          PurchaseOrderStatus.Open,
                          PurchaseOrderStatus.Review,
                          PurchaseOrderStatus.Sent,
                          PurchaseOrderStatus.Sent_as_Unlinked,
                        ]
                      : [PurchaseOrderStatus.Open],
                  });
                }}
                data-autoid="mnuNotSent"
              >
                Not sent
              </FilterMenuItem>
            )}

            {viewMode === PurchaseOrderDashboardViewMode.PurchaseOrders && (
              <FilterMenuItem
                selected={isEqual(searchFilter.Status, [
                  PurchaseOrderStatus.Sent,
                  PurchaseOrderStatus.Sent_as_Unlinked,
                ])}
                onClick={() => {
                  handleFilter({
                    Status: isEqual(searchFilter.Status, [
                      PurchaseOrderStatus.Sent,
                      PurchaseOrderStatus.Sent_as_Unlinked,
                    ])
                      ? [
                          PurchaseOrderStatus.Approved,
                          PurchaseOrderStatus.Cancelled,
                          PurchaseOrderStatus.Open,
                          PurchaseOrderStatus.Review,
                          PurchaseOrderStatus.Sent,
                          PurchaseOrderStatus.Sent_as_Unlinked,
                        ]
                      : [PurchaseOrderStatus.Sent, PurchaseOrderStatus.Sent_as_Unlinked],
                  });
                }}
                data-autoid="mnuSent"
              >
                Sent
              </FilterMenuItem>
            )}

            {viewMode === PurchaseOrderDashboardViewMode.PurchaseOrders && (
              <FilterMenuItem
                selected={isEqual(searchFilter.Status, [PurchaseOrderStatus.Approved])}
                onClick={() => {
                  handleFilter({
                    Status: isEqual(searchFilter.Status, [PurchaseOrderStatus.Approved])
                      ? [
                          PurchaseOrderStatus.Approved,
                          PurchaseOrderStatus.Cancelled,
                          PurchaseOrderStatus.Open,
                          PurchaseOrderStatus.Review,
                          PurchaseOrderStatus.Sent,
                          PurchaseOrderStatus.Sent_as_Unlinked,
                        ]
                      : [PurchaseOrderStatus.Approved],
                  });
                }}
                data-autoid="mnuCompleted"
              >
                Completed
              </FilterMenuItem>
            )}

            {viewMode === PurchaseOrderDashboardViewMode.DeliveryReceipts && (
              <FilterMenuItem onClick={() => handleFilter({})} data-autoid="mnuNone">
                None
              </FilterMenuItem>
            )}
            {viewMode === PurchaseOrderDashboardViewMode.DeliveryReceipts && (
              <FilterMenuItem
                selected={Boolean(searchFilter.CreatedByUserID)}
                onClick={() => handleFilter({CreatedByUserID: userId})}
                data-autoid="mnuMyDeliveryReceipts"
              >
                My delivery receipts
              </FilterMenuItem>
            )}
            {viewMode === PurchaseOrderDashboardViewMode.DeliveryReceipts && (
              <FilterMenuItem
                selected={Boolean(searchFilter.ModifiedByUserID)}
                onClick={() => handleFilter({ModifiedByUserID: userId})}
                data-autoid="mnuModifiedByMe"
              >
                Modified by me
              </FilterMenuItem>
            )}
          </FilterMenu>
          <IconButton
            disableRipple
            className={classes.iconButton}
            data-autoid="btnSortRequisition"
            {...bindTrigger(sortPopupState)}
          >
            <IconSort />
          </IconButton>
          <FilterMenu
            {...bindMenu(sortPopupState)}
            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
            transformOrigin={{vertical: 'top', horizontal: 'right'}}
          >
            <FilterMenuItem
              selected={String(searchFilter.SortAsc) === 'true' && searchFilter.SortField !== 'Status'}
              onClick={() => handleSort('true')}
              data-autoid="mnuCreatedOldestToNewest"
            >
              Created: Oldest to newest
            </FilterMenuItem>
            <FilterMenuItem
              selected={String(searchFilter.SortAsc) === 'false' && searchFilter.SortField !== 'Status'}
              onClick={() => handleSort('false')}
              data-autoid="mnuCreatedNewestToOldest"
            >
              Created: Newest to oldest
            </FilterMenuItem>
            {viewMode === PurchaseOrderDashboardViewMode.DeliveryReceipts && (
              <FilterMenuItem
                selected={String(searchFilter.SortAsc) === 'true' && searchFilter.SortField === 'Status'}
                onClick={() => handleSort('true', 'Status')}
                data-autoid="mnuStatusAtoZ"
              >
                Status: A - Z
              </FilterMenuItem>
            )}
            {viewMode === PurchaseOrderDashboardViewMode.DeliveryReceipts && (
              <FilterMenuItem
                selected={String(searchFilter.SortAsc) === 'false' && searchFilter.SortField === 'Status'}
                onClick={() => handleSort('false', 'Status')}
                data-autoid="mnuStatusZtoA"
              >
                Status: Z - A
              </FilterMenuItem>
            )}
          </FilterMenu>
          {viewMode === PurchaseOrderDashboardViewMode.Requisitions ||
          viewMode === PurchaseOrderDashboardViewMode.PurchaseOrders ? (
            <PrimaryButton
              margin="0 0 0 0.25rem"
              padding="10px !important"
              label="Create Requisition"
              type="button"
              onClick={handleCreate}
            />
          ) : null}
          {viewMode === PurchaseOrderDashboardViewMode.DeliveryReceipts ? (
            <PrimaryButton
              margin="0 0 0 0.25rem"
              padding="10px !important"
              label="New Delivery"
              type="button"
              onClick={handleCreate}
            />
          ) : null}
        </div>
      </div>
    </STableToolbar>
  );
};
