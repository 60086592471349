import {useState} from 'react';

type useCalculatePaginationType<S> = {
  initialFilterState: S;
};

export const useCalculatePagination = <T, S>({initialFilterState}: useCalculatePaginationType<S>) => {
  const [items, setItems] = useState<T[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [moreToGet, setMoreToGet] = useState<boolean>(true);
  const [searchFilter, setSearchFilter] = useState<S>(initialFilterState);

  const setCurrentPageFromFilter = (startRow?: number, maxResult?: number) => {
    let pageSize = 15;
    setCurrentPage(Math.ceil((startRow || 1 + pageSize - 1) / (maxResult || pageSize)));
  };

  const handlePageChange = (newPage: number, pageSize: number) => {
    setSearchFilter({
      ...searchFilter,
      StartRow: (newPage - 1) * pageSize + 1,
    });
  };

  const handleRowsPerPageChange = (rows: number) => {
    setSearchFilter({
      ...searchFilter,
      MaxResults: rows,
      StartRow: 1,
    });
  };

  return {
    items,
    totalCount,
    currentPage,
    moreToGet,
    searchFilter,
    setSearchFilter,
    setItems,
    setTotalCount,
    setCurrentPage,
    setMoreToGet,
    setCurrentPageFromFilter,
    handlePageChange,
    handleRowsPerPageChange,
  };
};
