const types = {
  SET_USER: 'SET_USER',
  SET_TENANT: 'SET_TENANT',
  SET_USER_TENANT: 'SET_USER_TENANT',
  SET_USER_TENANT_LIST: 'SET_USER_TENANT_LIST',
  SET_MENU: 'SET_MENU',
  SET_SUPPLIERS: 'SET_SUPPLIERS',
  LOAD_SESSION: 'LOAD_SESSION',
  LOGOUT: 'LOGOUT',
  SET_LOADING: 'SET_LOADING',
  CONFIRMATION_COMPLETE: 'CONFIRMATION_COMPLETE',
  SET_MARKETPLACE_SUPPLIER: 'SET_MARKETPLACE_SUPPLIER',
  SET_SUPPLIER_TO_LINK: 'SET_SUPPLIER_TO_LINK',
  SET_USER_MODULES: 'SET_USER_MODULES',
  SET_STATEMENT_INVITATION: 'SET_STATEMENT_INVITATION',
  SET_INVOICE_PAYMENT_INVITATION: 'SET_INVOICE_PAYMENT_INVITATION',
  SET_SELECTED_MODULE: 'SET_SELECTED_MODULE',
  SET_SELECTED_ADAPTOR: 'SET_SELECTED_ADAPTOR',
  TOGGLE_2SA_SETUP: 'TOGGLE_2SA_SETUP',
  TOGGLE_2SA_SETTINGS: 'TOGGLE_2SA_SETTINGS',
  TOGGLE_SMS_SETUP: 'TOGGLE_SMS_SETUP',
  TOGGLE_AUTH_APP_SETUP: 'TOGGLE_AUTH_APP_SETUP',
  TOGGLE_DOWNLOAD_AUTH_APP: 'TOGGLE_DOWNLOAD_AUTH_APP',
  SET_PAYMENT_METHODS: 'SET_PAYMENT_METHODS',
  TOGGLE_LOGIN_AUTH_APP: 'TOGGLE_LOGIN_AUTH_APP',
  SET_TENANT_INFO: 'SET_TENANT_INFO',
  SET_WORKFLOW_ID: 'SET_WORKFLOW_ID',
  SET_CONNECTED_SUPPLIERS: 'SET_CONNECTED_SUPPLIERS',
  SET_FEATURE_FLAGS: 'SET_FEATURE_FLAGS',
  SET_MODULES: 'SET_MODULES',
  SET_GET_ACCESS_TOKEN_SILENTLY: 'SET_GET_ACCESS_TOKEN_SILENTLY',
  SET_IS_PSBL_PIBL_ENABLE: 'SET_IS_PSBL_PIBL_ENABLE',
  SET_IS_MOBILE_DISCLAIMER_SHOWN: 'SET_IS_MOBILE_DISCLAIMER_SHOWN',
  SET_IS_SHOW_CONFIGURE_SETTINGS_DIALOG: 'SET_IS_SHOW_CONFIGURE_SETTINGS_DIALOG',
  SET_AVAILABLE_PAYMENT_METHOD_TYPES: 'SET_AVAILABLE_PAYMENT_METHOD_TYPES',
  SET_IS_QUOTES_SETTINGS_SAVED: 'SET_IS_QUOTES_SETTINGS_SAVED',
};

export default types;
