import React from 'react';
import {Formik} from 'formik';
import * as Yup from 'yup';

import {IPaymentBatchCreditLine} from '../../model/payment-batch/PaymentBatch';
import {SModal} from '../modals/modalSpendaMeterialUI';
import {SButton} from '../buttons/Button';
import {usePaymentBatchAPI} from '../../services/usePaymentBatchAPI';
import {Box, makeStyles} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {Toast} from '../../utils/Toast';
import {STextField} from '../inputs/STextField';

const useSendRemittanceModalStyles = makeStyles({
  apOnboardingModal: {
    maxWidth: '726px',
    height: '507px',
  },

  apSendRemittanceHeader: {
    borderBottom: '1px solid #CCCCCC',
    '& h2': {
      fontFamily: 'Poppins, sans-serif !important',
      fontWeight: 300,
      fontSize: '28px',
      color: '#333333',
    },
  },

  boxHeight: {
    paddingRight: '100px',
    '& p': {
      fontFamily: 'Poppins, sans-serif !important',
      color: '#333',
    },
  },
  secondaryHeading: {
    fontSize: '16px!important',
  },
  txtField: {
    paddingBottom: '0px!important',
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#FFFFFF!important',
      padding: '0px 10px',
    },
    '& .MuiOutlinedInput-multiline': {
      padding: '10px',
    },
    '& .MuiOutlinedInput-input': {
      height: '40px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#333333!important',
    },
    '& .MuiFormLabel-root': {
      color: ' #333!important',
      fontSize: '14px',
      fontFamily: 'Poppins, sans-serif !important',
      transform: 'translate(14px, 14px) scale(1)',
    },

    '& textarea, input': {
      color: ' #333!important',
      fontSize: '14px',
      fontFamily: 'Poppins, sans-serif !important',
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #1C78AD!important',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      fontSize: '13px',
      transform: 'translate(11px, -4px) scale(0.75)',
    },
    '& .MuiOutlinedInput-input:-webkit-autofill': {
      caretColor: '#fff',
      borderRadius: 'inherit',
      '-webkit-box-shadow': '0 0 0 100px #ffffff inset!important',
      '-webkit-text-fill-color': '#333!important',
    },
  },

  multiLineTextField: {
    '& .MuiOutlinedInput-input': {
      height: 'auto',
    },
  },
  sendRemittanceFooter: {
    maxWidth: '660px',
    background: 'rgb(173 173 173 / 20%)',
    borderRadius: '8px',
  },
});

interface ISendRemittance {
  EmailAddress: string;
  EmailMessage: string;
}
export interface ISendRemittanceModalProps {
  remittanceDetails: IPaymentBatchCreditLine;
  handleClose: () => void;
}

const validationSchema = Yup.object({
  EmailAddress: Yup.string().email().required('Email Address is required.'),
  EmailMessage: Yup.string().required('Email Message is required.').max(500, 'Email Message max 500 char.'),
});

export const SendRemittanceModal = (props: ISendRemittanceModalProps) => {
  const classes = useSendRemittanceModalStyles();
  const {remittanceDetails, handleClose} = props;

  const {sendBulkAdviceToSuppliers, saveAccountsPayablePayees} = usePaymentBatchAPI();

  const remittanceModal: ISendRemittance = {
    EmailAddress: remittanceDetails.emailAddress || '',
    EmailMessage:
      'Dear <SupplierName>,\n\nWe have paid <PaidAmount> batch <BatchName> with ref id. <BatchID> and description <DescriptionDetail> on <PaymentDate>.',
  };

  const onSubmit = async (values: ISendRemittance): Promise<void> => {
    if (remittanceDetails.emailAddress !== values.EmailAddress) {
      const res = await saveAccountsPayablePayees([
        {
          payeeID: remittanceDetails.payeeID,
          emailAddress: values.EmailAddress,
        },
      ]);
      if (res.code) {
        Toast.error('Something went wrong');
        throw new Error('Save Payee Error');
      }
    }
    const TransactionGUIDs = [remittanceDetails.transactionGUID] as string[];
    return sendBulkAdviceToSuppliers({TransactionGUIDs, EmailMessage: values.EmailMessage}).then(res => {
      if (res.code) {
        Toast.error('Something went wrong');
        throw new Error('Send Remittance Error');
      }
      Toast.info('Remittance Advice sent');
      handleClose();
    });
  };

  return (
    <SModal open>
      <div className={`text-xl spenda-color flex justify-center items-center h-full max-w-full`}>
        <Formik
          validateOnMount
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={remittanceModal}
          onSubmit={onSubmit}
        >
          {({values, handleChange, handleSubmit, isSubmitting, errors, touched}) => (
            <div className={`modal-box ${classes.apOnboardingModal} px-5 pt-3 pb-5`}>
              <Box className={`${classes.apSendRemittanceHeader} flex justify-between -mx-5 px-3 pb-3`}>
                <h2 className={`text-center w-full`}>Send Remittance Advice</h2>
                <Box className="text-right flex justify-end">
                  <span className="ap-onboarding-close-icon">
                    <CloseIcon onClick={handleClose} data-autoid="btnClose" />
                  </span>
                </Box>
              </Box>
              <div className={`${classes.boxHeight} flex flex-col relative pl-3 py-8`}>
                <p className={`${classes.secondaryHeading} font-medium text-base pb-6`}>
                  Supplier name: {remittanceDetails.supplierName}
                </p>
                <Box className="w-7/12 mb-7">
                  <STextField
                    v2
                    fullWidth
                    id="EmailAddress"
                    name="EmailAddress"
                    variant="outlined"
                    label="Email"
                    placeholder="Add your email here…"
                    className={`${classes.txtField}`}
                    value={values.EmailAddress}
                    onChange={handleChange}
                    helperText={touched?.EmailAddress && errors?.EmailAddress}
                    error={touched?.EmailAddress && Boolean(errors?.EmailAddress)}
                    data-autoid="txtEmailAddress"
                  />
                </Box>
                <Box className="w-11/12">
                  <STextField
                    v2
                    fullWidth
                    multiline
                    id="EmailMessage"
                    name="EmailMessage"
                    variant="outlined"
                    label="Email body"
                    placeholder="Add your email body here…"
                    className={`${classes.txtField} ${classes.multiLineTextField}`}
                    value={values.EmailMessage}
                    onChange={handleChange}
                    helperText={touched?.EmailMessage && errors?.EmailMessage}
                    error={touched?.EmailMessage && Boolean(errors?.EmailMessage)}
                    data-autoid="txtEmailMessage"
                    minRows={6}
                    maxRows={6}
                    maxLength={200}
                  />
                </Box>
              </div>
              <Box
                className={`${classes.sendRemittanceFooter} w-full flex justify-between items-center absolute bottom-5 left-0 p-2 mx-8 `}
              >
                <SButton
                  textColor={'blueShade'}
                  width="67px"
                  height="40px"
                  lineHeight="l"
                  fontSize="xs"
                  backgroundColor={'#fff'}
                  margin="0px "
                  variant={'outlined'}
                  border={'1px solid #1C78AD'}
                  borderRadius="6px"
                  data-autoid="btnCancel"
                  fontFamily="poppins"
                  onClick={handleClose}
                >
                  Cancel
                </SButton>
                <SButton
                  textColor={'white'}
                  width="82px"
                  height="40px"
                  lineHeight="l"
                  fontSize="xs"
                  backgroundColor={'#1C78AD'}
                  margin="0px"
                  variant={'outlined'}
                  border={'1px solid transparent'}
                  borderRadius="6px"
                  data-autoid="btnSend"
                  fontFamily="poppins"
                  onClick={handleSubmit}
                  isSubmitting={isSubmitting}
                  disabled={isSubmitting}
                >
                  Send
                </SButton>
              </Box>
            </div>
          )}
        </Formik>
      </div>
    </SModal>
  );
};
