import React from 'react';

export const ARExpandOutIcon = (props: {
  width?: string;
  height?: string;
  className?: string;
  onClick?: any;
  title?: string;
}) => {
  return (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>{props.title || 'Send Statement'}</title>
      <g id="Main-File" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="Spenda-Collect-Supplier-Interface-Copy-32"
          transform="translate(-968.000000, -406.000000)"
          fill="#FFFFFF"
        >
          <g id="Icons/Documents/paper_plane" transform="translate(968.000000, 406.000000)">
            <path
              d="M15.4109738,0.58764379 C14.9248544,0.0956273261 14.2169832,-0.106621599 13.5443071,0.054310457 L2.87764046,2.30097712 C1.62464094,2.472817 0.577138313,3.33963448 0.173890349,4.53835372 C-0.229357616,5.73707296 0.0812610854,7.06076112 0.97564046,7.95497712 L2.12097379,9.09964379 C2.24617016,9.22480225 2.3164463,9.39461625 2.31630713,9.57164379 L2.31630713,11.6836438 C2.31777839,11.9805973 2.38613776,12.2734032 2.51630713,12.5403105 L2.51097379,12.5449771 L2.52830713,12.5623105 C2.7236493,12.9550638 3.04269519,13.2727042 3.43630713,13.4663105 L3.45364046,13.4836438 L3.45830713,13.4783105 C3.72521434,13.6084798 4.01802033,13.6768392 4.31497379,13.6783105 L6.42697379,13.6783105 C6.6036747,13.6781713 6.7732029,13.7481887 6.89830713,13.8729771 L8.04297379,15.0176438 C8.66307913,15.6446166 9.50781102,15.9981081 10.3896405,15.9996438 C10.7534751,15.9991915 11.1148299,15.9397537 11.4596405,15.8236438 C12.6474403,15.4336151 13.511367,14.4038268 13.6889738,13.1663105 L15.9389738,2.47631046 C16.1083048,1.797695 15.9076811,1.08006003 15.4109738,0.58764379 Z M3.06497379,8.15831046 L1.91897379,7.01364379 C1.37417528,6.48196679 1.18650333,5.68293135 1.43764046,4.96431046 C1.68119451,4.22685401 2.3323915,3.69912396 3.10430713,3.61364379 L13.6649738,1.39031046 L3.64830713,11.4083105 L3.64830713,9.57164379 C3.65034594,9.04154808 3.44030611,8.53265157 3.06497379,8.15831046 Z M12.3789738,12.9383105 C12.2745702,13.6893554 11.754416,14.3167695 11.0357275,14.5585448 C10.3170389,14.8003202 9.5234283,14.6148721 8.98630713,14.0796438 L7.83964046,12.9329771 C7.46579184,12.5570757 6.95712744,12.3463125 6.42697379,12.3476438 L4.59030713,12.3476438 L14.6083071,2.33297712 L12.3789738,12.9383105 Z"
              id="Shape"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
