import React, {useState} from 'react';
import {Button, IconButton, SearchInput, Typography} from 'spenda-ui-react';

import {BackIcon} from '../../../../assets/svg';
import {AlertDialog} from '../../../../components/dialog/AlertDialogSlideV2';

import {useHistory} from 'react-router-dom';
import {AUTH_INVENTORY_PRODUCTS_LIST} from '../../../../routes/InventoryRoutes';
import {UploadProductsDialog} from '../../../../components/dialog/UploadProductsDialog';

interface StagingAreaHeaderProps {
  validationResults: any[];
}

export const StagingAreaHeader: React.FC<StagingAreaHeaderProps> = ({validationResults}) => {
  const [onRemoveProducts, setOnRemoveProducts] = useState(false);
  const [onNavigateAway, setOnNavigateAway] = useState(false);
  const [showUploadDialog, setShowUploadDialog] = useState(false);

  const history = useHistory();
  const handleBack = () => {
    history.push(AUTH_INVENTORY_PRODUCTS_LIST);
  };

  const isAllDataValid = () => {
    return validationResults.every(result => Object.values(result).every(status => status === 'valid'));
  };

  return (
    <>
      <header className="mb-2 flex items-center justify-between border-b border-[#ECECEC] pb-2.5">
        <div className="flex items-center justify-start gap-2.5">
          <div className="cursor-pointer" onClick={() => setOnNavigateAway(true)}>
            <IconButton variant="outlined" ripple={false}>
              <BackIcon />
            </IconButton>
          </div>
          <div className="flex flex-col">
            <Typography variant="h2" className="font-light">
              Staging Area
            </Typography>
            <Typography variant="xsmall" className="text-spenda-labeltext">
              194 products uploaded, 2 products deleted, 30 products created, 8 issues found
            </Typography>
          </div>
        </div>
        <div className="flex items-center gap-2.5">
          <div>
            <SearchInput
              reverse={true}
              clearIcon
              iconButtonProps={{
                name: 'Search',
              }}
              data-autoid="txtSearchInput"
              autoFocus
              placeholder="Search..."
            />
          </div>
          <Button variant="outlined" onClick={() => setShowUploadDialog(true)}>
            Re-upload
          </Button>
          <Button variant="outlined" color="error" onClick={() => setOnRemoveProducts(true)}>
            Remove Selected Products
          </Button>
          <Button variant="filled" disabled={!isAllDataValid()}>
            Commit Products
          </Button>
        </div>
      </header>
      {onRemoveProducts && (
        <AlertDialog
          id="removeProductsPopup"
          title="Remove selected products"
          size="xs"
          content="Are you sure you would like to continue and remove the selected products from the staging area? "
          contentClass="text-[12px] mx-14 font-medium"
          headingTextSize="h2"
          actions={[
            {
              label: 'No',
              action: () => setOnRemoveProducts(false),
              variant: 'outlined',
            },
            {
              label: 'Yes',
              action: () => setOnRemoveProducts(false),
            },
          ]}
        />
      )}
      {onNavigateAway && (
        <AlertDialog
          id="navigateAwayPopup"
          title="Unsaved changes"
          size="xs"
          content="Are you sure you would like to navigate away from this screen? you currently have unsaved changes. "
          contentClass="text-[12px] mx-20 font-medium"
          headingTextSize="h2"
          actions={[
            {
              label: 'Navigate Away',
              action: () => handleBack(),
              variant: 'outlined',
            },
            {
              label: 'Stay on this Screen',
              action: () => setOnNavigateAway(false),
            },
          ]}
        />
      )}
      {showUploadDialog && (
        <UploadProductsDialog
          handleCancel={() => setShowUploadDialog(false)}
          handleContinue={() => setShowUploadDialog(false)}
        />
      )}
    </>
  );
};
