import React from 'react';
import {Accordion, AccordionDetails, makeStyles} from '@material-ui/core';

export interface IHeadCell<T> {
  id: keyof T;
  label: string;
  align: 'left' | 'center' | 'right';
  width?: string;
  render?: (a: T) => React.ReactElement;
  dataAutoid?: string;
}
export interface ISExpandableTableProps<T extends {borrowerTransactionID: number}> {
  disabled?: boolean;
  data: T[];
  headCells: IHeadCell<T>[];
  children: any;
  tableHeadDataAutoId: string;
  rowDataAutoId: string;
}

const useAccordianStyles = makeStyles(() => ({
  AccordionClass: {
    borderRadius: '6px!important',
    margin: '0.5rem',
    '& .MuiAccordionSummary-root': {
      padding: '0px ',
      borderRadius: '6px 6px 0px 0px!important',
      backgroundColor: '#1C78AD',
      minHeight: '48px !important',
      '& .MuiAccordionSummary-content': {
        margin: '0!important',
      },
    },
    '& .MuiAccordionActions-root': {
      padding: '0px!important',
    },
    '& .MuiAccordionDetails-root': {
      padding: '0px!important',
    },
  },
}));

export const SExpandableTable = <T extends {borrowerTransactionID: number}>(props: ISExpandableTableProps<T>) => {
  const {headCells, data, disabled, tableHeadDataAutoId, rowDataAutoId} = props;
  const classes = useAccordianStyles();

  return (
    <Accordion className={`${classes.AccordionClass} w-full`} disabled={disabled}>
      {props.children}
      <AccordionDetails>
        <div className="w-full">
          <table className="w-full">
            <thead data-autoid={tableHeadDataAutoId}>
              <tr className="!h-10 bg-[#F1F1F1]">
                {headCells?.map(a => (
                  <th
                    key={a.id as string}
                    align={a.align}
                    className={`px-[8px] font-poppins text-base font-semibold text-[#999999] w-[${a.width ?? '12.5%'}]`}
                  >
                    {a.label}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data?.map(rowData => {
                return (
                  <tr
                    key={rowData?.borrowerTransactionID}
                    className="border-b-2 border-b-[#ECECEC]"
                    data-autoid={`${rowDataAutoId}${rowData?.borrowerTransactionID}`}
                  >
                    {headCells?.map((columnData: IHeadCell<T>, index) => {
                      return (
                        <td
                          key={columnData.id as string}
                          align={columnData.align}
                          className={`p-2.5 font-poppins text-base font-normal text-spenda-primarytext ${
                            index % 2 ? 'bg-[#FAFAFA]' : ''
                          }`}
                          data-autoid={columnData.dataAutoid}
                        >
                          {/* @ts-ignore */}
                          {columnData.render ? columnData.render(rowData) : rowData[columnData.id]}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};
