import * as React from 'react';
const TourPlayIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" {...props}>
    <path
      fill="#BBDBED"
      fillRule="evenodd"
      d="M10 0c5.523 0 10 4.477 10 10s-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0ZM7.2 13.473a.683.683 0 0 0 1.032.587l5.834-3.473a.683.683 0 0 0 0-1.173L8.232 5.94a.682.682 0 0 0-1.032.586v6.946Z"
      clipRule="evenodd"
    />
  </svg>
);
export default TourPlayIcon;
