import React, {useState, useEffect} from 'react';
import {useHistory, Prompt} from 'react-router-dom';
import {Formik, FormikProps, Form} from 'formik';
import {Button} from 'spenda-ui-react';
import * as Yup from 'yup';

import {DISCOUNT_MODE} from '../../model/constants/Constants';
import {AUTH_SELLING_SALES_ORDER_LIST} from '../../routes/SalesOrderRoutes';
import {IActionResults} from '../../model/ActionResults';
import {ICustomer} from '../../model/customer/Customer';
import {ISalesOrder} from '../../model/sales-order/SalesOrder';
import {Layout} from '../../components/layout/Layout';
import LoadingIndicator from '../../components/ui/LoadingIndicator';
import {CreateSalesOrderValues} from '../../components/form/SalesOrderForm';
import {SalesOrderCustomerForm} from '../../components/form/SalesOrderCustomerForm';
import {SalesOrderDetailLines} from '../../components/sales-orders/SalesOrderDetailLines';
import {useSalesOrderAPI} from '../../services/useSalesOrderAPI';
import {useCustomersAPI} from '../../services/useCustomersAPI';
import {Toast} from '../../utils/Toast';
import {AlertDialog} from '../../components/dialog/AlertDialogSlideV2';

export const validationSchemaSalesOrder = Yup.object({
  CustomerRefTransRefNumber: Yup.string().max(50, 'Customer Ref max 50 char').nullable(true),
  InternalNote: Yup.string().nullable(true).max(255, 'Must be less than 255 characters'),
  ContactPhone: Yup.string()
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      'Not a valid phone number',
    )
    .max(10, 'Not a valid phone number')
    .min(10, 'Not a valid phone number')
    .required('Phone Number is required'),
  ContactEmailAddress: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
  CustomerName: Yup.string().nullable(true).required('Customer Name is required'),
  ShippingAddress: Yup.object()
    .shape({
      Country: Yup.string().required(),
    })
    .nullable()
    .required('Shipping address is required.'),
  BillingAddress: Yup.object()
    .shape({
      Country: Yup.string().required(),
    })
    .nullable()
    .required('Billing address is required.'),
  DiscountMode: Yup.string(),
  Discount: Yup.number().when(['DiscountMode'], (DiscountMode: string, DiscountSchema: Yup.NumberSchema) => {
    switch (DiscountMode) {
      case DISCOUNT_MODE.PERCENT:
        return DiscountSchema.typeError('Enter a number')
          .min(0, 'Enter a discount percentage of at least 0%')
          .max(100, 'Enter a discount percentage of at most 100%');
      case DISCOUNT_MODE.DOLLAR:
        return DiscountSchema.typeError('Enter a number').min(0, 'Enter a discount amount of at least 0');
      default:
        return DiscountSchema;
    }
  }),
  Lines: Yup.array().of(
    Yup.object({
      ShortDescription: Yup.string().nullable(true),
      SellPriceExString: Yup.string()
        .nullable(true)
        .when(['ShortDescription'], (ShortDescription: string, schema: any) => {
          return ShortDescription ? schema.required(' Price is required') : schema.nullable();
        }),
      Quantity: Yup.number()
        .nullable(true)
        .when(['ShortDescription'], (ShortDescription: string, schema: any) => {
          return ShortDescription ? schema.required('Quantity is required') : schema.nullable();
        }),
    }),
  ),
});

export const newSalesOrder: CreateSalesOrderValues = {
  DeliveryMethod: 'Deliver',
  CustomerID: '',
  CustomerName: '',
  ContactEmailAddress: '',
  ContactPhone: '',
  CompanyName: '',
  CreatedWith: 'SpendaWeb',
  DiscountMode: 'None',
  ShippingAddress: {
    City: '',
    Country: '',
    StreetAddress: '',
    PostCode: '',
  },
  ShippingAddressID: '',
  BillingAddressID: '',
  BillingAddress: {
    City: '',
    Country: '',
    StreetAddress: '',
    PostCode: '',
  },
} as CreateSalesOrderValues;

export const CreateSalesOrder = () => {
  const history = useHistory();
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState<boolean>(false);
  const [lineItemsError, setLineItemsErrors] = useState<boolean>(false);
  const [selectedCustomer, setSelectedCustomer] = useState<Partial<ICustomer> | null>();
  const [accountDetails, setAccountDetails] = useState<any>();
  const [customerInvoices, setCustomerInvoices] = useState<any>();
  const [editMode, setEditMode] = useState<boolean>(false);
  const {createOrder, isLoading} = useSalesOrderAPI();
  const {getCustomer, getcustomerStatements} = useCustomersAPI();

  useEffect(() => {
    if (selectedCustomer?.ID) {
      const getCustomerDetails = async () => {
        if (selectedCustomer.AccountCustomerID) {
          const accountDetailsRes = await getCustomer(selectedCustomer.AccountCustomerID);
          const customerStatementsRes = await getcustomerStatements(selectedCustomer?.AccountCustomerID);
          setAccountDetails(accountDetailsRes);
          setCustomerInvoices(customerStatementsRes);
        }
      };

      getCustomerDetails();
    }
  }, [selectedCustomer]);

  const onSubmit = async (values: CreateSalesOrderValues): Promise<void> => {
    if (values?.Lines?.[0]?.Code) {
      setLineItemsErrors(false);
      if (values.DiscountMode === DISCOUNT_MODE.PERCENT && values.Discount && (values.Discount as number) > 1) {
        values.Discount = Number(values.Discount) / 100;
      }
      values.Lines = values.Lines.filter(item => item.Code);
      return createOrder(values).then((res: IActionResults<ISalesOrder>) => {
        if (res.IsSuccess) {
          Toast.info('Sales Order created');
          history.push(AUTH_SELLING_SALES_ORDER_LIST);
        } else {
          Toast.info('Error while creating Order, Try Again.');
        }
      });
    } else {
      setLineItemsErrors(true);
      return Promise.reject();
    }
  };

  const onKeyDown = (keyEvent: React.KeyboardEvent<HTMLFormElement>) => {
    if (keyEvent && keyEvent.key === 'Enter') {
      keyEvent.preventDefault();
    }
  };

  const handleCloseClick = (props: FormikProps<CreateSalesOrderValues>) => {
    if (props.dirty) {
      setOpenConfirmationDialog(true);
    } else {
      history.push(AUTH_SELLING_SALES_ORDER_LIST);
    }
  };

  const handleErrorMsg = (formikProps: any) => {
    const error = formikProps?.errors;
    if (
      error?.BillingAddress ||
      error?.CustomerName ||
      error?.ContactEmailAddress ||
      error?.CompanyName ||
      error?.ShippingAddress ||
      error?.InternalNote ||
      !formikProps?.dirty
    ) {
      Toast.error('You have missed or entered incorrect values for Customer');
    } else if (!formikProps?.values?.Lines?.[0]?.Code) {
      Toast.error('Add at least one product');
    }
  };

  const _createOrder = (
    <Formik
      enableReinitialize
      validationSchema={validationSchemaSalesOrder}
      initialValues={newSalesOrder}
      onSubmit={onSubmit}
    >
      {props => {
        return (
          <Form onKeyDown={onKeyDown} className="h-full">
            <Prompt
              when={props.dirty && !props.submitCount}
              message={() => `You have unsaved changes, would you like to leave page?`}
            />
            {
              <div className="relative h-full overflow-y-auto">
                <SalesOrderCustomerForm
                  {...props}
                  createSalesOrder={true}
                  accountDetails={accountDetails}
                  customerInvoices={customerInvoices}
                  selectedCustomer={selectedCustomer}
                  setSelectedCustomer={setSelectedCustomer}
                />
                <SalesOrderDetailLines
                  {...props}
                  lineItemsError={lineItemsError}
                  editMode={editMode}
                  setEditMode={setEditMode}
                />
                <div className="fixed px-3 " style={{bottom: 5, width: 'calc(100% - 2rem)'}}>
                  <div className="flex w-full flex-row justify-between rounded-lg bg-spenda-footerBg p-2">
                    <Button variant="outlined" className="bg-white" onClick={() => handleCloseClick(props)}>
                      Cancel
                    </Button>
                    <Button
                      loading={props.isSubmitting}
                      disabled={props.isSubmitting || !props.dirty}
                      onClick={() => {
                        handleErrorMsg(props);
                        props.handleSubmit();
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            }
          </Form>
        );
      }}
    </Formik>
  );

  return (
    <>
      {openConfirmationDialog && (
        <AlertDialog
          title="Discard Changes"
          headingTextSize="h2"
          contextTextVariant="paragraph"
          content="You have unsaved changes, Would you like to discard them or return to the previous screen?"
          actions={[
            {
              label: 'Cancel',
              action: () => setOpenConfirmationDialog(false),
              variant: 'outlined',
            },
            {
              label: 'Discard',
              action: () => history.push(AUTH_SELLING_SALES_ORDER_LIST),
            },
          ]}
        />
      )}
      <div className={`relative mx-1 h-full overflow-hidden bg-spenda-newbg`}>
        <Layout leftPanel={_createOrder} splitWidthType={4} />
        <LoadingIndicator isLoading={isLoading} size="md" />
      </div>
    </>
  );
};
