import React from 'react';
import {IconButton, makeStyles} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import clsx from 'clsx';

interface IImage {
  v2?: boolean;
  src: string;
  alt: string;
  className?: string;
  isDefault?: boolean;
  onDelete?: () => void;
  onSetDefault?: () => void;
}

const useImageStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    borderRadius: '5px',
    backgroundColor: '#CCCCCC',
  },
  image: {
    borderRadius: '5px',
    width: '100%',
    height: 'auto',
    objectFit: 'contain',
  },
  overlay: {
    borderRadius: '5px',
    display: 'flex',
    gap: '12px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    height: '100%',
    width: '100%',
    opacity: 0,
    transition: '.25s ease',
    backgroundColor: '#12121260',
    '&:hover': {
      opacity: 1,
    },
  },
  iconButton: {
    backgroundColor: 'rgba(255, 255, 255, 0.08)',
    padding: '0',
  },
  icon: {
    fontSize: '1rem',
    color: '#FAFAFA',
  },
}));

export const Image: React.FC<IImage> = ({v2, src, alt, className, isDefault, onDelete, onSetDefault}) => {
  const classes = useImageStyles();
  return (
    <div
      className={clsx(className, classes.root, {
        'h-[133px] w-[190px] !rounded-[20px] border-[1px] border-spenda-sBlue !bg-transparent': v2,
      })}
    >
      <img src={src} alt={alt} className={classes.image}></img>
      {onDelete || onSetDefault ? (
        <div
          className={clsx(classes.overlay, {
            '!rounded-[20px]': v2,
          })}
        >
          {onDelete && (
            <IconButton onClick={onDelete} className={classes.iconButton} data-autoid="btnDelete">
              <DeleteIcon className={clsx({[classes.icon]: !v2, '!text-[24px]': v2})} />
            </IconButton>
          )}
          {onSetDefault && !isDefault && (
            <button onClick={onSetDefault} className="text-base font-semibold text-white" data-autoid="btnSetDefault">
              Set as default
            </button>
          )}
        </div>
      ) : null}
      {isDefault && (
        <span className="absolute -bottom-2 mx-auto rounded-md bg-spenda-sBlue px-4 text-[10px] font-medium text-white">
          Default
        </span>
      )}
    </div>
  );
};
