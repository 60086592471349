import React, {useState, useEffect, useContext} from 'react';
import {Box} from '@material-ui/core';
import {SButton} from '../buttons/Button';
import spendaCollectImg01 from '../../assets/png/spenda-collect-img-01.png';
import spendaCollectImg02 from '../../assets/png/spenda-collect-img-02.png';
import spendaCollectImg03 from '../../assets/png/spenda-collect-img-03.png';
import spendaCollectImg04 from '../../assets/png/spenda-collect-img-04.png';
import spendaCollectLogo from '../../assets/svg/SpendaCollectLogo.svg';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import {SModal} from '../modals/modalSpendaMeterialUI';
import {useHistory} from 'react-router-dom';
import AppContext from '../../context/app/appContext';
import {ModuleTypes} from '../../model/constants/Constants';
interface ModuleInfo {
  onNextClick: () => void;
}

export const ModuleInfo: React.FunctionComponent<ModuleInfo> = ({onNextClick}: ModuleInfo) => {
  const history = useHistory();
  const appContext = useContext(AppContext);
  const [isOnboardingStarted, setIsOnboardingStarted] = useState(false);

  useEffect(() => {
    const module =
      Array.isArray(appContext?.tenantInfo?.Modules) &&
      appContext?.tenantInfo?.Modules.find(module => {
        return module.ModuleID === ModuleTypes.SpendaCollectAR && module.IsTenantOwned;
      });

    if (module) {
      setIsOnboardingStarted(true);
    }
  }, []);

  const redirectUser = (type: string) => {
    if (type === 'findOutMore') {
      window.open(' https://spenda.co/features/payments/');
    } else if (type === 'contactSales') {
      window.open(' https://spenda.co/contact-us/');
    }
  };

  return (
    <SModal
      open
      disableBackdropClick={false}
      onClose={() => {
        history.push('/');
      }}
    >
      <div className="text-xl spenda-color flex justify-center pt-10 pb-10 collect-modal-box">
        <div className="flex flex-col max-w-full w-full ">
          <Box className="spenda-collect-section flex justify-between align-center">
            <div className="collect-left">
              <div className="logo-box">
                <img src={spendaCollectLogo} />
              </div>
              <div className="collect-details">
                <h3>SpendaCollect</h3>
                <p>
                  Meet SpendaCollect, a payment platform that enables any business to collect debt from any customer.
                </p>
              </div>
            </div>
            <div className="collect-right ">
              <SButton
                type="submit"
                width="w-full"
                lineHeight="m"
                fontSize="m"
                className="common-next-btn"
                onClick={onNextClick}
              >
                {isOnboardingStarted ? 'Complete Setup Now' : 'Start 30 Day Free Trial'}
              </SButton>
              <div className="collect-action-btn w-full flex justify-between">
                <SButton
                  type="submit"
                  width="w-full"
                  lineHeight="m"
                  fontSize="m"
                  onClick={() => redirectUser('contactSales')}
                  className="primaryBtn"
                >
                  Contact Sales
                </SButton>
                <SButton
                  type="submit"
                  width="w-full"
                  lineHeight="m"
                  fontSize="m"
                  onClick={() => redirectUser('findOutMore')}
                  className="primaryBtn"
                >
                  Find Out More
                </SButton>
              </div>
            </div>
          </Box>
          <div className="spenda-collect-content">
            <Box className="collect-2-columns flex justify-between">
              <div className="collect-2-columns-col">
                <h2 className="">Give your customers more ways to pay</h2>
                <p className="">
                  Get paid quicker by providing customers more payment options such as credit card payments and bank
                  transfers.
                </p>
              </div>

              <div className="collect-2-columns-col">
                <h2 className="">No-cost merchant fees</h2>
                <p className="">
                  Save thousands of dollars every year by on-charging your merchant fees directly to your customers.
                </p>
              </div>
            </Box>

            <Box className="flex justify-between align-center spenda-collect-img">
              <img src={spendaCollectImg01} />
              <img src={spendaCollectImg02} />
              <img src={spendaCollectImg03} />
              <img src={spendaCollectImg04} />
            </Box>

            <Box className="collect-instructions flex justify-between flex-wrap">
              <div className="instruction-points flex ">
                <FiberManualRecordIcon />
                <p>Keep your current business banking when you switch to Spenda</p>
              </div>
              <div className="instruction-points flex ">
                <FiberManualRecordIcon />
                <p>Integrate your POS and EFTPOS for fewer keying errors</p>
              </div>
              <div className="instruction-points flex ">
                <FiberManualRecordIcon />
                <p>Integrated with small business accounting software</p>
              </div>
              <div className="instruction-points flex ">
                <FiberManualRecordIcon />
                <p>Accepts all major cards and bank transfers via the web app</p>
              </div>
              <div className="instruction-points flex ">
                <FiberManualRecordIcon />
                <p>Add a virtual terminal and take payments without an EFTPOS machine</p>
              </div>
              <div className="instruction-points flex ">
                <FiberManualRecordIcon />
                <p>Generate e-invoices and set up recurring payments</p>
              </div>
            </Box>
          </div>
        </div>
      </div>
    </SModal>
  );
};
