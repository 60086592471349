import React from 'react';
const IconTickClaims = (props: {width?: string; height?: string; className?: string}) => {
  return (
    <svg
      className={props.className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5443 1.31603C13.8954 0.682089 14.6929 0.453463 15.3255 0.805381C15.913 1.13216 16.1516 1.84461 15.9016 2.45229L15.8351 2.59044L9.14535 14.667C8.79138 15.3059 7.99323 15.5244 7.37078 15.1815L7.24031 15.0994L0.550555 10.3283C-0.0389332 9.90788 -0.176715 9.08817 0.242811 8.49743C0.630066 7.95213 1.35682 7.79253 1.92999 8.10188L2.06979 8.18903L7.5657 12.1078L13.5443 1.31603Z"
        className="fill-primary"
      />
    </svg>
  );
};

export default IconTickClaims;
