import React from 'react';

export const AttachFilesIcon = (props: {width?: string; height?: string; className?: string}) => {
  return (
    <svg
      width="20px"
      className={props.className}
      height="10px"
      viewBox="0 0 20 10"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Attach</title>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Buyer-screen/start-conv-Copy-11" transform="translate(-1258.000000, -738.000000)" fill="#CCCCCC">
          <g id="Group-11" transform="translate(986.000000, 268.000000)">
            <g
              id="General/-63-Copy"
              transform="translate(281.998624, 474.999950) rotate(45.000000) translate(-281.998624, -474.999950) translate(273.999632, 466.999013)"
            >
              <path
                d="M9.22952533,11.5113498 L7.05485867,13.6860164 C5.74103169,14.9647299 3.64363648,14.9506744 2.34706507,13.6544677 C1.05049365,12.3582609 1.03584819,10.2608697 2.314192,8.94668312 L4.48885867,6.77001645 C4.74916888,6.50952214 4.74901964,6.08732666 4.48852533,5.82701645 C4.22803103,5.56670624 3.80583555,5.56685548 3.54552533,5.82734978 L1.37152533,8.00401645 C-0.457825806,9.83428812 -0.457079591,12.8009986 1.37319205,14.6303497 C3.20346369,16.4597009 6.17017414,16.4589547 7.99952533,14.6286831 L10.174192,12.4540164 C10.4268401,12.1924305 10.4232269,11.7766266 10.1660711,11.5194707 C9.90891519,11.2623149 9.49311126,11.2587016 9.23152533,11.5113498 L9.22952533,11.5113498 Z"
                id="Path-A"
              ></path>
              <path
                d="M14.6288587,1.37401645 C13.7526088,0.491747731 12.5596559,-0.00305526041 11.316192,-2.84217094e-14 L11.316192,-2.84217094e-14 C10.0734032,-0.00328496215 8.88088805,0.490475851 8.004192,1.37134978 L5.826192,3.54668312 C5.56569771,3.80699333 5.56554848,4.2291888 5.82585868,4.4896831 C6.08616889,4.7501774 6.50836436,4.75032665 6.76885867,4.49001645 L8.94552533,2.31534978 C9.57242854,1.6846763 10.4256166,1.33106177 11.3148587,1.33334978 L11.3148587,1.33334978 C12.6703569,1.33379878 13.8921671,2.15058402 14.410745,3.40296279 C14.9293229,4.65534156 14.6425844,6.09677809 13.684192,7.05534978 L11.5095253,9.23001645 C11.249031,9.49051076 11.249031,9.91285548 11.5095253,10.1733498 C11.7700196,10.4338441 12.1923644,10.4338441 12.4528587,10.1733498 L14.6275253,8.00001645 C16.4542827,6.1692863 16.4548791,3.20548163 14.6288587,1.37401645 Z"
                id="Path-A"
              ></path>
              <path
                d="M9.528192,5.52868312 L5.528192,9.52868312 C5.35488761,9.6960661 5.2853836,9.94393504 5.3463939,10.1770213 C5.40740419,10.4101076 5.58943416,10.5921376 5.82252045,10.6531479 C6.05560674,10.7141582 6.30347569,10.6446542 6.47085867,10.4713498 L10.4708587,6.47134978 C10.7235068,6.20976386 10.7198936,5.79395993 10.4627377,5.53680407 C10.2055819,5.2796482 9.78977793,5.27603498 9.528192,5.52868312 Z"
                id="Path-A"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
