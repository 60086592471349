import React, {useContext} from 'react';
import AppContext from '../../context/app/appContext';
import {SupportChat} from '../navigation/SupportChat';
import LiveChatIcon from '../../assets/svg/live-chat-blue-icon.svg';
import {makeStyles} from '@material-ui/core/styles';

const useChatStyles = makeStyles({
  liveChat: {
    '& p': {
      fontFamily: 'Poppins, sans-serif !important',
      fontSize: '12px',
      color: '#666666',
    },
  },
  chatText: {
    fontFamily: 'Poppins, sans-serif !important',
    fontSize: '13px',
    color: 'hsl(var(--primary))',
  },
  apliveChatBtn: {
    border: '1px solid hsl(var(--primary))',
    borderRadius: '6px',
    color: 'hsl(var(--primary))',
    fontSize: '14px',
    fontWeight: 600,
    padding: ' 5px 12px',
    backgroundColor: '#ffffff',
    height: '40px',
    width: '100px',
  },
});
interface IChatWidget {
  isAPOnboarding?: boolean;
}

export const ChatWidget: React.FunctionComponent<IChatWidget> = ({isAPOnboarding}: IChatWidget) => {
  const classes = useChatStyles();
  const {user} = useContext(AppContext);

  return (
    <div className={`${isAPOnboarding ? `${classes.liveChat}` : ''}`}>
      {!isAPOnboarding && <p>Need help?</p>}
      <SupportChat user={user} className={`${isAPOnboarding ? `${classes.apliveChatBtn}` : 'liveChatBtn'}`}>
        <>
          {!isAPOnboarding && <img src={LiveChatIcon} className="mr-2 mt-1" />}
          <div className={`${isAPOnboarding ? `${classes.chatText}` : ''}`}>Live Chat</div>
        </>
      </SupportChat>
    </div>
  );
};
