import React from 'react';
import Lottie, {Options, EventListener} from 'react-lottie';
import {ProcessingAnimationData} from './LottieAnimationsData';
import PaymentDeclined from '../../svg/PaymentDeclined.svg';
import PaymentSuccessful from '../../svg/PaymentSuccessful.svg';
import PaymentTimeout from '../../svg/time_out.svg';
import './styles.css';
interface ILottieAnimationProps {
  eventListeners?: EventListener[];
  height?: string;
  width?: string;
  data_autoid?: string;
}

export const ProcessingAnimation = (props: ILottieAnimationProps) => {
  const processingOptions: Options = {
    animationData: ProcessingAnimationData,
    autoplay: true,
    loop: true,
  };
  return (
    <Lottie
      options={processingOptions}
      width={props.width || '84%'}
      height={props.height || '127px'}
      style={{cursor: 'default'}}
    />
  );
};

export const SuccessAnimation = (props: ILottieAnimationProps) => {
  return (
    <img
      alt="PaymentSuccessful"
      data-autoid={props.data_autoid || 'imgSuccess'}
      style={{float: 'right', width: props.width || '84%', height: props.height || '127px'}}
      src={PaymentSuccessful}
    />
  );
};

export const ErrorAnimation = (props: ILottieAnimationProps) => {
  return (
    <img
      alt="PaymentDeclined"
      data-autoid={props.data_autoid || 'imgDeclined'}
      style={{float: 'right', width: props.width || '84%', height: props.height || '127px'}}
      src={PaymentDeclined}
    />
  );
};

export const TimeoutAnimation = (props: ILottieAnimationProps) => {
  return (
    <img
      alt="PaymentDeclined"
      data-autoid={props.data_autoid || 'imgTimeout'}
      style={{float: 'right', width: props.width || '84%', height: props.height || '127px'}}
      src={PaymentTimeout}
    />
  );
};
