import * as Yup from 'yup';

const characterLimitExceeded = 'Character limit exceeded';

export const abnNzbnValidationSchema = Yup.object({
  ABN: Yup.string()
    .matches(/^\d{11,13}$/, 'The number must be between 11 to 13 digits long without any spaces.')
    .required('ABN or NZBN is required.'),
});

export const baseSupplierValidationSchema = Yup.object({
  ABN: Yup.string()
    .matches(/^(?:\d{2}-\d{3}-\d{3}-\d{3}|\d{11})$/, 'ABN must be 11 characters without spaces.')
    .required('ABN is required.'),
});

export const supplierValidationSchema = baseSupplierValidationSchema.shape({
  Name: Yup.string().required('Company name is required.').max(100, characterLimitExceeded),
  Phone1: Yup.string()
    .matches(/^[0-9\\+]+$/, 'Only numbers are allowed for this field')
    .max(20, 'More than 20 characters not allowed.'),
  Locations: Yup.array()
    .of(
      Yup.object().shape({
        EmailAddress: Yup.string().email('Invalid email address').required('Email is required'),
      }),
    )
    .min(1)
    .required(),
});
