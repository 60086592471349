import React, {useEffect, useState} from 'react';
import {Radio, Typography} from 'spenda-ui-react';
import clsx from 'clsx';

import {AlertDialogSlideV2, IDialogActionV2} from './AlertDialogSlideV2';
import {AuthorisePayment} from '../../assets/svg/accounts-payable/AuthorisePayment';
import useServiceJobAPI from '../../services/useServiceJobAPI';
import {Toast} from '../../utils/Toast';
import {PriceFormat} from '../../utils/formatter';
import {IServiceJob} from '../../model/service-management/serviceJob';
import useAlertAPI from '../../services/useAlertAPI';
import {ISendReminderAlert} from '../../model/alert/AlertInfo';
import {DatTypes} from '../../model/constants/Constants';
import {CloseJobIcon} from '../../assets/svg';
import {useCustomersAPI} from '../../services/useCustomersAPI';

interface IInvoiceGenerateDialogProps {
  handleCancel: () => void;
  totalInc?: number;
  selectedServiceJobs: number[];
  handleSendInvoice?: (generatedInvoice: Partial<IServiceJob>[]) => void;
  getCustomerDetails?: (generateInvoices: Partial<IServiceJob>[]) => {customerIDs: number[]; customerEmails: string[]};
}
export const InvoiceGenerateDialog = ({
  handleCancel,
  selectedServiceJobs,
  totalInc,
  getCustomerDetails,
}: IInvoiceGenerateDialogProps) => {
  const {generateServiceJobInvoice, isLoading} = useServiceJobAPI();
  const {sendPayInvoiceByLink, isLoading: sendingLink} = useAlertAPI();
  const {getCustomer} = useCustomersAPI();

  const [generatedInvoice, setGeneratedInvoice] = useState([] as Partial<IServiceJob>[]);

  const generateInvoices = async () => {
    if (selectedServiceJobs.length) {
      const response = await generateServiceJobInvoice(selectedServiceJobs);
      if (response.isSuccess) {
        setGeneratedInvoice(response.value?.serviceJobs!);
        Toast.info('Invoice generated successfully');
      }
    }
  };

  useEffect(() => {
    try {
      generateInvoices();
    } catch (error) {
      Toast.error('Failed to generate invoice');
    }
  }, []);

  const handleSendInvoice = async () => {
    if (selectedServiceJobs.length === 1) {
      const customerDetails = getCustomerDetails
        ? getCustomerDetails(generatedInvoice)
        : {customerIDs: [], customerEmails: []};

      const accountCustomer = await getCustomer(customerDetails.customerIDs[0]);
      const payload: Partial<ISendReminderAlert> = {
        DocID: '' + generatedInvoice[0].invoiceID!,
        DatTypeID: DatTypes.SendPayInvoiceByLink,
        CustomerIDs: customerDetails.customerIDs,
        ToList: [{ContactEmailAddress: accountCustomer?.PrimaryContactEmailAddress}],
      };
      const res = await sendPayInvoiceByLink(payload);
      if (res.IsSuccess) {
        Toast.info('Invoice sent successfully');
        handleCancel();
      }
    }
    handleCancel();
  };

  const actions: IDialogActionV2[] = [
    {
      label: 'cancel',
      loading: false,
      action: handleCancel,
      variant: 'outlined',
      disabled: isLoading,
    },
    {
      label: 'Send Invoice',
      action: handleSendInvoice,
      disabled: isLoading || sendingLink,
      loading: sendingLink,
    },
  ];

  if (selectedServiceJobs.length === 1 && !totalInc) {
    actions.pop();
    actions[0].label = 'Close';
    actions[0].variant = 'filled';
  }

  return (
    <AlertDialogSlideV2
      headingClassess="!border-b-0 "
      dialogActionsAlignment={actions.length === 1 ? 'justify-center' : 'justify-between'}
      data-autoid={'dlgQuoteSent'}
      dialogClassess="!w-[320px] !min-w-[320px]"
      actions={actions}
    >
      {selectedServiceJobs.length === 1 && !totalInc ? (
        <div className="flex flex-col items-center justify-center px-10 py-36">
          <div className="flex flex-col items-center justify-center gap-y-8">
            <CloseJobIcon
              className={clsx('h-[88px] w-[88px]', {
                'first:animate-pulse': isLoading,
              })}
            />
            <Typography variant="h2" className="text-primary">
              Job closed
            </Typography>
          </div>
          <Typography variant="small" className="pt-6 text-center">
            Great! Your job has been successfully closed.
          </Typography>
        </div>
      ) : (
        <div className="mt-4 flex flex-col gap-2.5">
          <div className="relative mt-2.5 flex w-full justify-center">
            <AuthorisePayment
              className={clsx('h-[88px] w-[88px]', {
                'first:animate-pulse': isLoading,
              })}
            />
          </div>

          <div className="mb-4 mt-8 flex flex-col items-center justify-center gap-4">
            <Typography variant="h2" className="mb-6 text-primary">
              Invoice generated
            </Typography>
            <div className="flex w-[234px] flex-col justify-between gap-6 text-center font-normal text-black-800">
              <Typography variant="small">
                You have successfully generated {selectedServiceJobs.length > 1 ? selectedServiceJobs.length : 'the'}{' '}
                invoice.
              </Typography>
              <Typography variant="small">Would you like to send this invoice via Pay invoice by link?</Typography>
            </div>
            <div className="flex items-center justify-center">
              <Radio
                color="primary"
                name="type"
                defaultChecked
                label={
                  <Typography variant="h1" className={`text-base font-semibold text-black`}>
                    Send as Pay invoice by link
                  </Typography>
                }
              />
            </div>

            <div className="w-full px-3.5">
              {selectedServiceJobs.length === 1 ? (
                <div className="mt-4 flex w-full items-center justify-between rounded-md bg-primary/10 p-2.5">
                  <Typography variant="small" className="text-black-800">
                    Total amount:
                  </Typography>
                  <Typography className="font-semibold text-black-900">{PriceFormat(totalInc, '$', 2)}</Typography>
                </div>
              ) : (
                <div className="mt-4 h-3.5 p-2.5" />
              )}
            </div>
          </div>
        </div>
      )}
    </AlertDialogSlideV2>
  );
};
