import React, {useEffect} from 'react';
import {Formik, FormikHelpers, Form} from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';

import {ISupplier} from '../../model/supplier/Supplier';
import {SDialog} from '../modals/modalSpendaMeterialUI';
import {PrimaryButton, SecondaryButton} from '../buttons/DefaultButtons';
import {SupplierForm, CreateSupplierValues} from '../form/SupplierForm';
import {Toast} from '../../utils/Toast';
import LoadingOverLay from '../ui/LoadingOverlay';

import {DialogActions, DialogContent, DialogTitle, makeStyles} from '@material-ui/core';
import {useSupplierAPI} from '../../services/useSupplierAPI';

interface ICreateSupplier {
  open: boolean;
  onClose: (supplier?: ISupplier) => void;
  supplierId?: any;
}

const useCreateSupplierDialog = makeStyles(() => ({
  dialogPaper: {
    minWidth: '896px',
    minHeight: '421px',
    fontFamily: 'Poppins, sans-serif',
  },
  dialogTitle: {
    borderBottom: '1px solid #EAEAEA',
    '& h2': {
      fontSize: '22px',
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 300,
    },
  },
  dialogContent: {
    paddingLeft: '80px',
    paddingRight: '80px',
  },
  dialogActions: {
    margin: '8px',
    borderRadius: '8px',
    backgroundColor: '#ADADAD20',
    padding: '8px 12px',
    justifyContent: 'space-between',
  },
}));

const validationSchema = Yup.object({
  Name: Yup.string().required('Company Name is required'),
  Contacts: Yup.array()
    .of(
      Yup.object().shape({
        EmailAddress: Yup.string().email('Invalid Email address').required('Email is Required'),
        FirstName: Yup.string().when('IsActive', {
          is: true,
          then: Yup.string()
            .required('First Name is required')
            .max(50, 'First Name cannot be longer than 50 characters.')
            .matches(/^[A-zÀ-ú ]*$/, 'Please enter valid  first name'),
        }),
        LastName: Yup.string().when('IsActive', {
          is: true,
          then: Yup.string()
            .required('Last Name is required')
            .max(50, 'Last Name cannot be longer than 50 characters.')
            .matches(/^[A-zÀ-ú ]*$/, 'Please enter valid  last name'),
        }),
        PhoneMobile: Yup.string().when('IsActive', {
          is: true,
          then: Yup.string()
            .matches(
              /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
              'Not a valid phone number'
            )
            .required('Phone is Required'),
        }),
      })
    )
    .min(1),
  Locations: Yup.array()
    .of(
      Yup.object().shape({
        Addresses: Yup.array()
          .of(
            Yup.object().shape({
              StreetAddress: Yup.string().required(),
              City: Yup.string().required(),
              State: Yup.string().required(),
              PostCode: Yup.string().required(),
              Country: Yup.string().required(),
            })
          )
          .min(1),
      })
    )
    .min(1),
  Phone1: Yup.string()
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      'Not a valid phone number'
    )
    .required('Mobile is Required'),
  ABN: Yup.string()
    .matches(/^(?:\d{2}-\d{3}-\d{3}-\d{3}|\d{11})$/, 'ABN must be of 11 characters without any blank space')
    .required('ABN is Required'),
});

const newSupplier: CreateSupplierValues = {
  ABN: '',
  Name: '',
  Phone1: '',
  RefNumber: '',
  Locations: [
    {
      Addresses: [],
      IsActive: true,
      IsDefaultLocation: false,
    },
  ],
  Contacts: [
    {
      IsPrimaryContact: true,
      PhoneMobile: '',
      FirstName: '',
      LastName: '',
      EmailAddress: '',
      IsActive: true,
    },
  ],
};

export const CreateSupplierDialog = (props: ICreateSupplier) => {
  const classes = useCreateSupplierDialog();
  const {supplierId, open, onClose} = props;

  const [supplierToEdit, setSupplierToEdit] = React.useState<CreateSupplierValues>(newSupplier);

  const {getSupplierById, saveSupplier, isLoading} = useSupplierAPI();

  useEffect(() => {
    if (open && supplierId) {
      getSupplier(supplierId);
    }
  }, [open, supplierId]);

  const getSupplier = async (id: any) => {
    const supplier = await getSupplierById(id);
    setSupplierToEdit(supplier as Partial<ISupplier>);
  };

  const onKeyDown = (keyEvent: React.KeyboardEvent<HTMLFormElement>) => {
    if (keyEvent && keyEvent.key === 'Enter') {
      keyEvent.preventDefault();
    }
  };

  const onSubmit = async (
    values: CreateSupplierValues,
    helpers: FormikHelpers<CreateSupplierValues>
  ): Promise<void> => {
    return saveSupplier(values).then(res => {
      if (res) {
        onClose(res);
        helpers.resetForm();
        Toast.info(`Supplier '${values.Name}' ${values.ID ? 'updated' : 'created'}`);
      }
    });
  };

  const handleCreateNew = () => {
    setSupplierToEdit(newSupplier);
  };

  return (
    <SDialog classes={{paper: classes.dialogPaper}} fullWidth maxWidth="md" open={open}>
      <LoadingOverLay isLoading={isLoading} />
      <Formik enableReinitialize validationSchema={validationSchema} initialValues={supplierToEdit} onSubmit={onSubmit}>
        {props => (
          <>
            <DialogTitle className={clsx(classes.dialogTitle, 'text-center font-light text-spenda-primarytext')}>
              {supplierToEdit?.ID ? 'Edit Supplier' : 'Create a new Supplier'}
            </DialogTitle>
            <DialogContent className={clsx(classes.dialogContent, 'flex flex-col items-center justify-center')}>
              <Form id="supplier-form" onKeyDown={onKeyDown} onSubmit={props.handleSubmit}>
                <SupplierForm {...props} />
              </Form>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              <SecondaryButton
                label="Cancel"
                onClick={() => {
                  setSupplierToEdit(newSupplier);
                  props.resetForm();
                  onClose();
                }}
              />
              <div>
                {supplierToEdit?.ID ? <PrimaryButton label="Create New" onClick={handleCreateNew} /> : null}
                <PrimaryButton
                  isSubmitting={isLoading}
                  disabled={isLoading}
                  form="supplier-form"
                  margin="0 0 0 8px"
                  label="Save & Close"
                  onClick={props.handleSubmit}
                />
              </div>
            </DialogActions>
          </>
        )}
      </Formik>
    </SDialog>
  );
};
