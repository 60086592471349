import React, {Fragment} from 'react';
import {FormikProps} from 'formik';
import {Link} from 'react-router-dom';

import {
  IConversationChatbox,
  IConversationMessages,
  IConvoGetMessagesResponse,
  IConvoMessagesGroupByDate,
  IDocumentConversationSubjectObj,
} from '../../model/conversation/Conversation';
import {IConversationChatboxInput} from './ChatBox';
import {useTenantInfo} from '../../hooks/useTenantInfo';
import {AUTH_SELLING_COLLECT_ROUTE} from '../../routes/AuthenticatedRoutes';

import {Box, Button, makeStyles, MenuItem, Select} from '@material-ui/core';
import {ArrowSend} from '../../assets/svg/ArrowSend';
import {DisplayMessage} from './DisplayMessage';
import {PreviewAttachedFiles} from './UploadAttachments';

interface ChatBoxBodyProps {
  conversationData: IConversationChatbox;
  messageData: IConvoGetMessagesResponse | undefined;
  subjectList: IDocumentConversationSubjectObj[] | undefined;
  tenantId: string;
  isShowNewMsgLine: boolean;
}

export const ChatBoxBody = (props: FormikProps<IConversationChatboxInput> & ChatBoxBodyProps) => {
  const {conversationData, messageData, subjectList, tenantId, isShowNewMsgLine, setErrors, setTouched, setFieldValue} =
    props;

  const {operationUniqueId, isChatboxOpen, refNumber, conversationWith, customerID} = conversationData;
  const {isSubjectSelected, subjectId, attachments} = props.values;

  const {isInSupplierContext} = useTenantInfo();

  const useChatBoxBodyStyles = makeStyles(() => ({
    subject: {
      position: 'absolute',
      color: '#999999',
      fontWeight: 500,
      fontSize: '14px',
      background: '#fff',
      zIndex: 2,
      width: '100%',
      padding: '3px 0 3px 16px',
      fontFamily: 'poppins',
      borderBottom: '1px solid #DDDDDD',
      '& p': {
        padding: '0 0 0 3px',
        fontWeight: '500',
        color: '#4D4D4D',
      },
    },
    chatContainer: {
      width: '100%',
      height: '57vh',
      justifyContent: 'space-between',
    },
    animatedItemIn: {
      // animation: `$myEffectIn 250ms ${theme.transitions.easing.easeIn}`,
    },
    chatInfoContainer: {
      height: '57vh',
      padding: '8px 16px',
      position: 'relative',
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    infoUnBold: {
      maxWidth: '280px',
      textAlign: 'center',
      fontWeight: 400,
      fontSize: '1em',
      color: '#999999',
      marginTop: '0px',
      marginBottom: '0px',
    },
    infoBold: {
      fontWeight: 600,
    },
    receiverMessageBox: {
      maxWidth: '260px',
      height: 'auto',
      backgroundColor: '#EFF3F6',
      alignSelf: 'start',
      padding: '9px 10px',
      borderRadius: '12px',
      overflowWrap: 'break-word',
    },
    receiverMessage: {
      fontFamily: 'poppins',
      fontWeight: 400,
      fontSize: '0.75em',
      color: '#333333',
    },
    subjectItemBox: {
      width: '260px',
      padding: '10px 0',
      '& select': {
        color: '#333',
      },
    },
    batchSelect: {
      minWidth: '260px',
      height: '32px',
      padding: '6px 10px',
      border: '1px solid #333',
      borderRadius: '6px',
      marginLeft: '0px',
      borderColor: '#1C78AD',
      '& .MuiSelect-select': {
        color: '#4d4d4d',
        fontSize: '12px',
        fontWeight: '400',
      },
      '& svg': {
        color: '#cacaca',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: '0px!important',
      },
    },
    menuItemList: {
      '&:hover': {
        backgroundColor: '#EAEFF2',
      },
    },
    selectSubjectBtn: {
      margin: '10px 10px 10px',
      display: 'flex',
      alignItems: 'center',
      borderRadius: '4px',
      color: '#fff',
    },
    selectSubject: {
      border: '1px solid #e9f2f7',
      backgroundColor: '#e9f2f7',
      borderRadius: '3px',
      minWidth: '32px !important',
      width: '32px !important',
      marginRight: '8px',
      padding: '0',
      '&:hover': {
        backgroundColor: '#e9f2f7',
      },
      '& .MuiButton-label': {
        width: '32px !important',
      },
      '& .MuiTouchRipple-root': {
        width: '32px !important',
        height: '32px !important',
      },
      '& .MuiButton-startIcon': {
        margin: 0,
      },
    },
    dateTile: {
      width: '100px',
      height: '20px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#99999910',
      border: '1px solid #66666610',
      borderRadius: '27.5px',
      marginBottom: '20px',
    },
    dateStyle: {
      fontFamily: 'poppins',
      fontSize: '10px',
      color: '#666666',
    },
    generalTile: {
      width: 'auto',
      padding: '4px 10px',
      height: 'fit-content',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#99999910',
      border: '1px solid #66666610',
      borderRadius: '27.5px',
      marginTop: '30px',
    },
  }));

  const classes = useChatBoxBodyStyles();
  const newLine = {isNewMessagesStarted: false};

  const handleRemoveUploadedFile = (removeIndex: number) => {
    const _attachments = attachments?.filter((attachment, i) => i !== removeIndex) as File[];
    setFieldValue('attachments', _attachments);
  };

  return (
    <>
      {!!((isSubjectSelected && subjectList) || messageData?.subject) && (
        <Box
          data-autoid={`lblConversationSubject-${operationUniqueId}`}
          className={`${classes.subject} flex flex-wrap`}
        >
          <h4>Subject: </h4>
          <p>
            {messageData?.subject
              ? messageData?.subject
              : subjectList?.find(list => list.subjectID === parseInt(subjectId))?.description}
          </p>
        </Box>
      )}
      <Box
        className={`${classes.chatContainer} ${
          isChatboxOpen ? `${classes.animatedItemIn}` : ''
        } flex flex-col bg-white`}
      >
        <Box
          data-autoid={`scroll-to-bottom-${operationUniqueId}`}
          id={`scroll-to-bottom-${operationUniqueId}`}
          className={`${classes.chatInfoContainer} flex ${
            !messageData?.conversationMessages?.length ? 'justify-between' : null
          } flex-col w-full`}
        >
          <div className="flex flex-col">
            <div className={`mt-8 mb-5 self-center`}>
              <p data-autoid={`lblChatInfo-${operationUniqueId}`} className={`${classes.infoUnBold}`}>
                You started a conversation with{' '}
                {isInSupplierContext && customerID ? (
                  <Link className={`${classes.infoBold}`} to={`${AUTH_SELLING_COLLECT_ROUTE}/${customerID}`}>
                    {conversationWith}
                  </Link>
                ) : (
                  <span className={`${classes.infoBold}`}>{conversationWith}</span>
                )}
                {refNumber && ' regarding invoice '}
                {refNumber && <span className={`${classes.infoBold}`}>{refNumber}</span>}
              </p>
            </div>
          </div>
          {!isSubjectSelected && subjectList && (
            <>
              <Box className={`${classes.receiverMessageBox} flex flex-col`}>
                <p
                  className={`${classes.receiverMessage}`}
                >{`Please choose the subject of the conversation from the following:`}</p>
              </Box>
              <Box className="flex flex-wrap mb-3 mt-2">
                <Box data-autoid={`ddlSubjectList-${operationUniqueId}`} className={classes.subjectItemBox}>
                  <Select
                    displayEmpty
                    className={`${classes.batchSelect} text-black-800 border font-poppins`}
                    variant="outlined"
                    value={subjectId}
                    onChange={e => {
                      setFieldValue('subjectId', e.target.value);
                      setErrors({});
                      setTouched({});
                    }}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                      },
                      getContentAnchorEl: null,
                    }}
                    inputProps={{
                      name: 'subject',
                    }}
                  >
                    <MenuItem
                      className={classes.menuItemList}
                      style={{display: 'none'}}
                      value={''}
                    >{`Choose Subject`}</MenuItem>
                    {subjectList?.map(item => (
                      <MenuItem
                        data-autoid={`ddlItem-${item.description}`}
                        className={classes.menuItemList}
                        key={item.subjectID}
                        value={item.subjectID}
                      >
                        {item.description}
                      </MenuItem>
                    ))}
                    <MenuItem data-autoid={`ddlItem-other`} className={classes.menuItemList} value={`other`}>
                      {`Other`}
                    </MenuItem>
                  </Select>
                </Box>
                {subjectId && subjectId !== 'other' && (
                  <Box className={classes.selectSubjectBtn}>
                    {subjectId && (
                      <Button
                        data-autoid={`btnChooseSubject-${operationUniqueId}`}
                        variant="contained"
                        className={`${classes.selectSubject}`}
                        startIcon={<ArrowSend />}
                        onClick={() => {
                          setFieldValue('isSubjectSelected', true);
                          setErrors({});
                          setTouched({});
                        }}
                      >
                        {''}
                      </Button>
                    )}
                  </Box>
                )}
              </Box>
            </>
          )}
          {!!(messageData?.conversationMessagesGroupByDate?.length && tenantId) &&
            messageData.conversationMessagesGroupByDate.map(
              (messagesGroupByDate: IConvoMessagesGroupByDate, i: number) => (
                <Fragment key={`messageDate-${i}`}>
                  <div className={`${classes.dateTile} self-center mt-2`}>
                    <p className={`${classes.dateStyle}`}>{messagesGroupByDate?.messageDate}</p>
                  </div>
                  {messagesGroupByDate?.conversationMessages?.map((message: IConversationMessages, i: number) => (
                    <DisplayMessage
                      key={`chatbox-message-${message.conversationID}-${i}`}
                      isShowNewMsgLine={isShowNewMsgLine}
                      iteration={i}
                      newLine={newLine}
                      tenantId={tenantId}
                      messageObj={message}
                    />
                  ))}
                </Fragment>
              )
            )}
        </Box>
        <Box
          data-autoid={`review-uploads-${operationUniqueId}`}
          className="flex flex-row items-center w-full flex-wrap px-3 pt-1"
        >
          {attachments?.map((attachment: File, i: number) => (
            <PreviewAttachedFiles
              key={`chatbox-message-${attachment.name}-${i}`}
              attachment={attachment}
              iteration={i}
              operationUniqueId={operationUniqueId}
              handleRemoveUploadedFile={handleRemoveUploadedFile}
            />
          ))}
        </Box>
      </Box>
    </>
  );
};
