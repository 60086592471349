import React, {useContext, useEffect, useState} from 'react';
import moment from 'moment';
import Skeleton from 'react-loading-skeleton';
import {DatTypes} from '../../model/constants/Constants';
import {IWorkflowStatusResponse} from '../../model/workflow/WorkflowResponse';
import useWorkflowAPI from '../../services/useWorkflowAPI';
import useMasterAdaptorAPI from '../../services/useMasterAdaptorAPI';
import {css} from 'glamor';
import {RefreshIcon} from '../../assets/svg/RefreshIcon';
import xeroIcon from '../../assets/svg/xero-new-icon.svg';
import myobIcon from '../../assets/svg/Myob_logo.svg';
import quickbooksIcon from '../../assets/svg/Quickbooks_logo.svg';
import {IntegrationContext} from '../../context/IntegrationContext';
import {Button} from 'spenda-ui-react';

interface IArRefreshProps {
  handleRefreshData?: () => void;
  customerId?: string;
  datTypeId: DatTypes;
  isAccountsPayable?: boolean;
  newOnly?: boolean;
  isCreditLine?: boolean;
  showOnlyRefreshIcon?: boolean;
  isNewBtn?: boolean;
  dataAutoID?: string;
  title?: string;
  height?: string;
  width?: string;
}

export const ArRefreshBtn = (props: IArRefreshProps) => {
  const {
    handleRefreshData,
    customerId,
    datTypeId,
    isAccountsPayable,
    newOnly,
    isCreditLine,
    showOnlyRefreshIcon,
    dataAutoID,
    title: titleProp,
    isNewBtn,
    height,
    width,
  } = props;
  const {isLoading: isAdaptorLoading} = useMasterAdaptorAPI();
  const {synkDataRead, checkWorkflowStatus, lastSyncDate} = useWorkflowAPI();
  let workflowStatusTimer: ReturnType<typeof setInterval>;

  const [isLoading, setIsLoading] = useState(false);
  const {financialAdaptor} = useContext(IntegrationContext);
  const [lastSyncDays, setLastSyncDays] = useState<number>();

  useEffect(() => {
    getLastSyncDate();
    return () => {
      clearInterval(workflowStatusTimer);
    };
  }, []);

  const getLastSyncDate = async () => {
    try {
      const response = await lastSyncDate(datTypeId);
      if (response?.length) {
        setLastSyncDays(response[0].lastSyncDays);
      }
    } catch {}
  };

  const handleRefreshClick = async () => {
    try {
      if (financialAdaptor?.Name && financialAdaptor?.ID) {
        setIsLoading(true);
        const synkDataRes = await synkDataRead(
          true,
          'Read',
          financialAdaptor.Name,
          financialAdaptor.ID,
          datTypeId,
          29,
          true,
          !newOnly ? moment().subtract(3, 'years').toISOString() : undefined,
          customerId && parseInt(customerId) ? [parseInt(customerId)] : undefined,
        );
        workflowStatusTimer = setInterval(() => {
          synkDataRes?.Value?.WorkflowID && checkWorkflowStatusFun(synkDataRes.Value.WorkflowID);
        }, 5000);
      }
    } catch {
      setIsLoading(false);
    }
  };

  const checkWorkflowStatusFun = (workFlowId: number) => {
    if (workFlowId) {
      checkWorkflowStatus(workFlowId)
        .then((data: IWorkflowStatusResponse) => {
          if (data.Status === 'Complete') {
            if (handleRefreshData) {
              handleRefreshData();
            }
            setIsLoading(false);
            clearTimeout(workflowStatusTimer);
            getLastSyncDate();
          }
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  };

  const getAdaptorImage = () => {
    switch (financialAdaptor?.Name) {
      case 'Xero':
        return <img className="ml-2" width="24px" height="24px" src={xeroIcon} alt={'Xero Icon'} />;
      case 'MyObAccountRightLive':
        return <img className="ml-2" width="45px" height="22px" src={myobIcon} alt={'myob Icon'} />;
      case 'QuickBooksOnline':
        return <img className="ml-2" width="70px" height="20px" src={quickbooksIcon} alt={'quick book Icon'} />;
      case 'Parody':
        return undefined;
      default:
        return undefined;
    }
  };

  const animationSpin = css({
    animation: 'spin 2s linear infinite',
  });

  let title = titleProp;
  if (!titleProp) {
    if (datTypeId === DatTypes.Accounts && !isCreditLine) {
      title = 'Refresh Charts of Accounts';
    } else if (datTypeId === DatTypes.Suppliers) {
      title = 'Refresh suppliers';
    } else {
      title = 'Refresh Data';
    }
  }

  return (
    <>
      {isAdaptorLoading ? (
        <div className={`flex`}>
          <Skeleton circle={true} width={20} height={20} />
          <div className="mx-2 flex flex-col">
            <Skeleton width={50} height={20} />
          </div>
          <Skeleton circle={true} width={20} height={20} />
        </div>
      ) : isNewBtn ? (
        <Button
          type="button"
          variant="outlined"
          color="primary"
          data-autoid={dataAutoID}
          onClick={() => (!isLoading ? handleRefreshClick() : null)}
        >
          <div className="flex items-center gap-x-2">
            {title} <RefreshIcon height="14px" width="14px" className={`${isLoading ? 'animate-spin' : ''}`} />
          </div>
        </Button>
      ) : (
        <div
          data-autoid={dataAutoID ? dataAutoID : 'btnARRefresh'}
          className={`flex cursor-pointer flex-row items-center`}
          onClick={() => (!isLoading ? handleRefreshClick() : null)}
        >
          <RefreshIcon
            height={height || '14px'}
            width={width || '14px'}
            className={`${isLoading ? animationSpin : ''}`}
            style={{minWidth: isAccountsPayable ? '14px' : ''}}
          />
          {!showOnlyRefreshIcon && (
            <>
              <div className="ml-2 flex flex-col">
                <p
                  className={`whitespace-nowrap font-poppins text-base font-semibold leading-[1.1] text-primary ${
                    isAccountsPayable ? 'mb-0' : 'mb-[2px]'
                  }`}
                >
                  {title}
                </p>
                <p className={`whitespace-nowrap font-poppins text-[10px] font-medium leading-[1.1] text-[#999999]`}>
                  Last Sync {lastSyncDays} days ago
                </p>
              </div>
              {(datTypeId !== DatTypes.Accounts || isCreditLine) && getAdaptorImage()}
            </>
          )}
        </div>
      )}
    </>
  );
};
