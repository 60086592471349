import {camelCase} from 'lodash';

export const createAutoIdAttribute = (name: string) => {
  return {'data-autoid': name};
};

export const createPayButtonAutoIdAttribute = () => {
  return createAutoIdAttribute('btnPay');
};

export type AutoIdPrefix = 'txt' | 'btn' | 'lnk' | 'chk' | 'lbl' | '';

export const createAutoIdAttributeFromReactProps = (props: any, prefix: AutoIdPrefix) => {
  let dataAttributeValue: string | undefined = undefined;

  if (props) {
    dataAttributeValue = props['data-autoid'];
    if (dataAttributeValue) {
      // if props['data-autoid'] exist, use it exactly as is
      return {
        attributeValue: dataAttributeValue,
        attributeValueWithPrefix: '',
        autoIdAttribute: createAutoIdAttribute(dataAttributeValue),
      };
    } else {
      // if props.inputProps['data-autoid'] exist, use it
      dataAttributeValue = props.inputProps && props.inputProps['data-autoid'];

      if (dataAttributeValue) {
        // if props['data-autoid'] exist, use it exactly as is
        return {
          attributeValue: dataAttributeValue,
          attributeValueWithPrefix: '',
          autoIdAttribute: createAutoIdAttribute(dataAttributeValue),
        };
      } else {
        // if props.name exist, use it
        dataAttributeValue = props.name;
        if (!dataAttributeValue) {
          // if props.id exist, use it
          dataAttributeValue = props.id;
        }
      }
    }
  }

  if (dataAttributeValue) {
    // uppercase the first letter
    dataAttributeValue = dataAttributeValue[0].toUpperCase() + dataAttributeValue.substr(1);
  }

  const attributeValueWithPrefix = `${prefix || ''}${dataAttributeValue || ''}`;
  const autoIdAttribute = createAutoIdAttribute(attributeValueWithPrefix);

  return {
    attributeValue: dataAttributeValue || '',
    attributeValueWithPrefix,
    autoIdAttribute,
  };
};

export const createAutoIdAttributeFromName = (name: string = '', prefix: AutoIdPrefix) => {
  const autoId = camelCase(name);
  return createAutoIdAttribute(`${prefix}${autoId.charAt(0).toUpperCase() + autoId.slice(1)}`);
};
