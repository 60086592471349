import React, {FC} from 'react';

export const KebabMenu: FC<SVGProps> = props => {
  return (
    <svg width={17} height={5} viewBox="0 0 17 5" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx={2.125} cy={2.125} r={2.125} fill="currentColor" />
      <circle cx={8.5} cy={2.125} r={2.125} fill="currentColor" />
      <circle cx={14.875} cy={2.125} r={2.125} fill="currentColor" />
    </svg>
  );
};
