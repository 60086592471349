import React, {useContext} from 'react';
import moment from 'moment';
import {Typography} from 'spenda-ui-react';
import IconScheduledPayments from '../../../assets/svg/IconScheduledPayments';
import PaymentScheduled from '../../../assets/svg/PaymentScheduled';
import {IHeaderDetails} from '../../../components/AccountsReceivable/ARHeader';
import {ARSelectToggler} from '../../../components/AccountsReceivable/ARSelectToggler';
import {ARTable} from '../../../components/AccountsReceivable/ARTable';
import {ITxData} from '../../../components/AccountsReceivable/TransactionView';
import StartConvo from '../../../components/conversationChatBox/StartConvo';
import ARTooltip from '../../../components/data-display/ARTootip';
import AppContext from '../../../context/app/appContext';
import ARContext from '../../../context/ar-context/ARContext';
import PurchasingContext from '../../../context/purchasing/purchasingContext';
import {useFeatureFlags} from '../../../hooks/useFeatureFlags';
import {ARSelectableTransactions, ConversationType, DatTypes, StartConvoType} from '../../../model/constants/Constants';
import {ICustomerOutstandingStatement} from '../../../model/customer/CustomerStatements';
import {PriceFormat} from '../../../utils/formatter';
import clsx from 'clsx';
import {isWebsiteId17} from '../../../config';

interface IPsblStandardViewProps {
  supplierId: number;
  isLoading: boolean;
  headerData?: IHeaderDetails;
  transactionList: ICustomerOutstandingStatement[];
  onSelect: (tx: ICustomerOutstandingStatement) => Promise<void>;
  onViewTransaction: (currenViewingTx: ITxData) => void;
}

const PsblStandardView = (props: IPsblStandardViewProps) => {
  // Props
  const {supplierId, isLoading, transactionList, headerData, onSelect, onViewTransaction} = props;

  // Context
  const {conversations} = useContext(PurchasingContext);
  const {psblBatch} = useContext(ARContext);
  const {marketplaceSupplier} = useContext(AppContext);
  const isVendorPresent = transactionList?.find(s => s.vendorName);

  // Feature Flags
  const {scheduledPaymentsV2, payStatementByLinkV289938} = useFeatureFlags().supplierDriven();

  const columns = [
    {
      title: '',
      key: 'checkbox',
      columClassName: 'text-spenda-labeltext !max-w-[50px] !min-w-[50px]',
      rowClassName: 'p-0 !max-w-[50px] !min-w-[50px]',
      align: 'left',
      isSortable: false,
      rowRenderer: (rowData: ICustomerOutstandingStatement) => {
        const isShowCheckbox = ARSelectableTransactions.includes(rowData.transactionTypeID);
        if (!isShowCheckbox) return <></>;

        const isNotSelectable =
          scheduledPaymentsV2 &&
          typeof rowData?.balanceIncludingScheduledIPAs === 'number' &&
          rowData?.balanceIncludingScheduledIPAs <= 0;
        const isSelected = psblBatch?.selectedTxList?.findIndex(st => st.id === rowData.transID) > -1;

        return (
          <>
            {isNotSelectable ? (
              <ARTooltip
                arrow
                title={
                  <Typography
                    variant="paragraph"
                    className="min-w-[200px] text-center text-[10px] font-medium text-black-800"
                  >
                    This {rowData?.transactionType} cannot be selected as it’s already scheduled for payment.
                  </Typography>
                }
              >
                <span
                  className={`cursor-pointer items-center font-poppins text-base font-normal opacity-30 grayscale`}
                  data-autoid={`chkTransactionRow`}
                >
                  <ARSelectToggler key={`checkbox${rowData.transID}`} isSelected={isSelected} />
                </span>
              </ARTooltip>
            ) : (
              <span
                className={`cursor-pointer items-center font-poppins text-base font-normal`}
                onClick={() => onSelect(rowData)}
                data-autoid={`chkTransactionRow`}
              >
                <ARSelectToggler key={`checkbox${rowData.transID}`} isSelected={isSelected} />
              </span>
            )}
          </>
        );
      },
    },
    {
      title: 'Date',
      key: 'transDate',
      width: isVendorPresent ? '10%' : '12%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: true,
      rowRenderer: (rowData: ICustomerOutstandingStatement) => (
        <Typography data-autoid={`lblTransDate`} variant="paragraph" className="inline overflow-ellipsis font-medium">
          {rowData?.transDate ? moment(rowData?.transDate).format('DD MMM YYYY') : ''}
        </Typography>
      ),
    },
    {
      title: 'Doc Type',
      key: 'transactionType',
      width: isVendorPresent ? '16%' : '20%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: '',
      getRowClassConditionally: (rowData: ICustomerOutstandingStatement) =>
        rowData?.scheduledPaymentMethodLast4 ? 'pl-2.5' : 'px-2.5',
      isSortable: true,
      rowRenderer: (rowData: ICustomerOutstandingStatement) => (
        <div className="flex flex-row items-center justify-between">
          <Typography
            data-autoid={`lblTransactionType`}
            variant="paragraph"
            className="inline-block overflow-ellipsis font-medium"
          >
            {rowData.transactionType}
          </Typography>
          <div className="flex flex-row items-center justify-end gap-2">
            {scheduledPaymentsV2 && rowData?.scheduledInvoicePaymentAllocationSummary?.length && (
              <ARTooltip
                arrow
                title={
                  <React.Fragment>
                    <Typography variant="xsmall" className="text-center font-medium text-black-800">
                      Payment Scheduled
                    </Typography>
                    <ul className="mt-3">
                      {rowData?.scheduledInvoicePaymentAllocationSummary?.map(s => (
                        <li key={s?.scheduledDateTime_utc} className="flex items-center justify-start gap-2 pb-1">
                          <Typography variant="xsmall" className="whitespace-nowrap font-medium text-[#B2B2B2]">
                            Payment Date:
                          </Typography>
                          <Typography
                            data-autoid={`lblDateScheduledPayment`}
                            variant="xsmall"
                            className="font-semibold text-black-800"
                          >
                            {moment(s?.scheduledDateTime_utc)?.format('DD/MM/YYYY')}
                          </Typography>
                          <Typography
                            data-autoid={`lblAmountScheduledPayment`}
                            variant="xsmall"
                            className="font-semibold text-black-800"
                          >
                            {PriceFormat(s?.amount)}
                          </Typography>
                        </li>
                      ))}
                    </ul>
                  </React.Fragment>
                }
              >
                <button
                  className={clsx(
                    `ml-2 mr-1 flex h-[42px] w-9 cursor-pointer items-center justify-center bg-[#e6e4f5]`,
                    {
                      'mr-7': !supplierId || rowData.transactionTypeID !== DatTypes.Invoice,
                    },
                  )}
                  data-autoid={`btnSchedulePaymentsIcon`}
                >
                  <IconScheduledPayments />
                </button>
              </ARTooltip>
            )}
            {rowData?.scheduledPaymentMethodLast4 && (
              <ARTooltip
                title={
                  <React.Fragment>
                    <Typography variant="xsmall" className="text-center font-medium">
                      Payment Scheduled
                    </Typography>
                    <ul className="mt-3">
                      <li className="flex items-center justify-start pb-1">
                        <Typography variant="xsmall" className="w-[80px] font-medium text-[#B2B2B2]">
                          Paying Via:
                        </Typography>
                        <Typography variant="xsmall" className="font-semibold">
                          {rowData?.scheduledPaymentMethod} ***{rowData?.scheduledPaymentMethodLast4}
                        </Typography>
                      </li>
                      <li className="flex items-center justify-start pb-1">
                        <Typography variant="xsmall" className="w-[80px] font-medium text-[#B2B2B2]">
                          Due in
                        </Typography>
                        <Typography variant="xsmall" className="font-semibold">
                          {rowData?.scheduledInNoOfDays}{' '}
                          <Typography variant="xsmall" className="inline w-[80px] font-medium text-[#B2B2B2]">
                            days(s)
                          </Typography>
                        </Typography>
                      </li>
                    </ul>
                  </React.Fragment>
                }
              >
                <button
                  className={clsx(
                    `ml-2 mr-1 flex h-[42px] w-9 cursor-pointer items-center justify-center bg-[rgba(60,159,120,.1)]`,
                    {
                      'mr-7': !supplierId || rowData.transactionTypeID !== DatTypes.Invoice,
                    },
                  )}
                >
                  <PaymentScheduled className="h-[24px] w-[26px]" />
                </button>
              </ARTooltip>
            )}

            {rowData.transactionTypeID === DatTypes.Invoice && supplierId && (
              <StartConvo
                operationUniqueId={rowData.transGUID}
                conversationWith={marketplaceSupplier?.SupplierName || ''}
                refNumber={rowData.refNumber}
                customerID={null}
                supplierID={supplierId}
                datTypeID={rowData.transactionTypeID}
                guid={rowData.transGUID}
                unreadMessages={rowData.unreadMessages}
                conversationId={rowData.conversationID}
                contextConvos={conversations}
                startConvoType={StartConvoType.ICON}
                convoType={ConversationType.DOCUMENT}
                transactionId={rowData?.transID}
              />
            )}
          </div>
        </div>
      ),
    },
    {
      title: 'Ref ID',
      key: 'refNumber',
      width: isVendorPresent ? '10%' : '14%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: true,
      rowRenderer: (rowData: ICustomerOutstandingStatement) => {
        const isShowInvoice = isWebsiteId17 && payStatementByLinkV289938 ? false : true;
        return (
          <>
            <div className="flex flex-col justify-start">
              <Typography
                onClick={() => {
                  if (isShowInvoice) {
                    onViewTransaction({
                      txDatType: rowData.transactionTypeID,
                      txGuid: rowData.transGUID,
                      txId: rowData.transID,
                    });
                  }
                }}
                data-autoid={'lnkRefNumber'}
                variant="paragraph"
                className={clsx(
                  `inline overflow-ellipsis font-medium text-primary `,
                  isShowInvoice && 'cursor-pointer  underline',
                )}
              >
                {rowData.refNumber}
              </Typography>
              {isVendorPresent && rowData?.vendorRefNumber && (
                <Typography variant="xsmall" className="text-[#CCCCCC]">
                  {rowData?.vendorRefNumber}
                </Typography>
              )}
            </div>
          </>
        );
      },
    },
    {
      title: 'Due Date',
      key: 'dueDate',
      width: isVendorPresent ? '10%' : '12%',
      align: 'right',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: true,
      rowRenderer: (rowData: ICustomerOutstandingStatement) => (
        <Typography data-autoid={`lblDueDate`} className="inline overflow-ellipsis font-medium" variant="paragraph">
          {rowData?.dueDate ? moment(rowData?.dueDate).format('DD MMM YYYY') : ''}
        </Typography>
      ),
    },
    {
      title: 'Amount',
      key: 'totalInc',
      width: isVendorPresent ? '12%' : '14%',
      align: 'right',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: true,
      rowRenderer: (rowData: ICustomerOutstandingStatement) => (
        <Typography
          data-autoid={`lblTotalInc`}
          className={`inline overflow-ellipsis font-medium text-black-800 ${
            rowData?.totalInc == 0 ? 'opacity-50' : ''
          }`}
          variant="paragraph"
        >
          {[DatTypes.CreditNote, DatTypes.Payment].includes(rowData?.transactionTypeID)
            ? `(${PriceFormat(rowData?.totalInc)})`
            : PriceFormat(rowData?.totalInc)}
        </Typography>
      ),
    },
    {
      title: 'Applied',
      key: 'paymentsAllocated',
      width: isVendorPresent ? '12%' : '14%',
      align: 'right',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: true,
      rowRenderer: (rowData: ICustomerOutstandingStatement) => (
        <Typography
          data-autoid={`lblPaymentsAllocated`}
          className={`inline overflow-ellipsis font-medium text-black-800 ${
            rowData?.totalInc == 0 ? 'opacity-50' : ''
          }`}
          variant="paragraph"
        >
          {[DatTypes.Invoice].includes(rowData?.transactionTypeID) && rowData?.paymentsAllocated > 0
            ? `(${PriceFormat(rowData?.paymentsAllocated)})`
            : PriceFormat(rowData?.paymentsAllocated)}
        </Typography>
      ),
    },
    {
      title: 'Balance',
      key: 'balance',
      width: isVendorPresent ? '12%' : '14%',
      align: 'right',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: true,
      rowRenderer: (rowData: ICustomerOutstandingStatement) => {
        const isCreditNoteOrPayment = [DatTypes.CreditNote, DatTypes.Payment].includes(rowData?.transactionTypeID);
        return (
          <div className="relative h-[20px]">
            {scheduledPaymentsV2 && typeof rowData?.balanceIncludingScheduledIPAs === 'number' ? (
              <ARTooltip
                arrow
                title={
                  <React.Fragment>
                    <Typography variant="xsmall" className="text-center font-medium text-black-800">
                      Balance including scheduled payments
                    </Typography>
                    <Typography
                      data-autoid={`lblBalance`}
                      variant="xsmall"
                      className="mt-1 whitespace-nowrap text-center font-medium text-black-800"
                    >
                      {PriceFormat(rowData?.balanceIncludingScheduledIPAs)}
                    </Typography>
                  </React.Fragment>
                }
              >
                <Typography
                  data-autoid={`lblBalance`}
                  className={clsx(
                    `inline overflow-ellipsis font-medium text-black-800`,
                    `${rowData?.totalInc === 0 && 'opacity-50'}`,
                  )}
                  variant="paragraph"
                >
                  {isCreditNoteOrPayment ? `(${PriceFormat(rowData?.balance)})` : PriceFormat(rowData?.balance)}
                </Typography>
              </ARTooltip>
            ) : (
              <Typography
                data-autoid={`lblBalance`}
                className={clsx(
                  `inline overflow-ellipsis font-medium text-black-800`,
                  `${rowData?.totalInc === 0 && 'opacity-50'}`,
                )}
                variant="paragraph"
              >
                {isCreditNoteOrPayment ? `(${PriceFormat(rowData?.balance)})` : PriceFormat(rowData?.balance)}
              </Typography>
            )}
          </div>
        );
      },
    },
  ];

  if (isVendorPresent) {
    columns?.splice(2, 0, {
      title: 'Vendor',
      key: 'vendorName',
      width: '16%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'pl-2.5 py-2.5',
      isSortable: true,
      rowRenderer: (rowData: ICustomerOutstandingStatement) => (
        <div className="flex h-[inherit] flex-row items-center justify-between">
          <Typography
            data-autoid={`lblVendorName`}
            className={`inline overflow-ellipsis font-medium`}
            variant="paragraph"
          >
            {rowData.vendorName ? rowData.vendorName : headerData?.supplierTenantName}
          </Typography>
        </div>
      ),
    });
  }

  return (
    <ARTable
      isHighlightRowOnHover
      tableClass="mb-12"
      rows={transactionList}
      columns={columns}
      isLoading={isLoading}
      isPaginated
      dataAutoId="PSBLStandardView"
      getRowDataAutoId={rowData => `rowPSBLStandardView${rowData?.refNumber}`}
    />
  );
};

export default PsblStandardView;
