import React, { FunctionComponent } from "react";
import { StatusCounterList } from "./StatusCounterList";
import { TransactionList } from "./TransactionList";

interface ITransactionListPanelProps {
    statusCounterList: any[],
    purchaseOrderList?: any[]
}

export const TransactionListPanel: FunctionComponent<ITransactionListPanelProps> = (props) => {
    return (
        <div className="flex flex-col">
            <div style={{ flex: "1 1 10%" }} className={`p-2 rounded bg-white`}>
                <StatusCounterList items={props.statusCounterList} />
            </div>
            <div style={{ flex: "1 1 90%" }} className={`p-2 rounded mt-2 overflow-y-auto bg-white`}>
                <TransactionList />
            </div>
        </div>)
}