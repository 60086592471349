import {RedirectLoginOptions, useAuth0} from '@auth0/auth0-react';
import {useHistory} from 'react-router-dom';
import {randomString} from '../utils/nonceGenerator';
import useStorage from './useStorage';

export const useAuth0Callback = () => {
  const history = useHistory();
  const {getAuth0Nonce, setAuth0Nonce} = useStorage();
  const {loginWithRedirect} = useAuth0();

  const OAuthCallbackWhitelist = ['/onboarding/xero', '/pay/onboarding/xero', '/purchasing/onboarding/xero', '/supplier/psbl/onboarding/xero'];

  const onRedirectCallback = async (appState: any) => {
    const state = await getAuth0Nonce(appState);
    history.push(state && state.redirectUrl ? state.redirectUrl : window.location.pathname);
  };

  const loginWithAuth0Redirect = async (options: RedirectLoginOptions) => {
    const nonce = randomString(16);
    await setAuth0Nonce(nonce, {
      redirectUrl: options.redirectUri,
    });

    delete options.redirectUri;

    loginWithRedirect({...options, appState: nonce});
  };

  return {
    onRedirectCallback,
    loginWithAuth0Redirect,
    OAuthCallbackWhitelist,
  };
};
