import React from 'react';
import {SDialog} from '../modals/modalSpendaMeterialUI';
import {Box, DialogActions, DialogContent, DialogTitle, IconButton, makeStyles} from '@material-ui/core';
import {PrimaryButton} from '../buttons/DefaultButtons';
import CloseIcon from '@material-ui/icons/Close';

interface IConfigureSettingsDialogProps {
  open: boolean;
  onClose: () => void;
  onButtonClick: () => void;
}

const useConfigureSettingsDialogStyles = makeStyles(theme => ({
  dialogActions: {
    borderTop: '1px solid lightgrey',
    margin: '0 1rem',
    padding: '1rem 0',
  },
  dialogContent: {
    margin: '20px',
    padding: 0,
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogPaper: {
    minHeight: '55vh',
    borderRadius: '24px',
    fontFamily: 'poppins',
  },
}));

export const ConfigureSettingsDialog: React.FunctionComponent<IConfigureSettingsDialogProps> = ({
  open,
  onClose,
  onButtonClick,
}: IConfigureSettingsDialogProps) => {
  const classes = useConfigureSettingsDialogStyles();

  return (
    <>
      <SDialog classes={{paper: classes.dialogPaper}} maxWidth="xs" fullWidth open={open!} onClose={() => onClose()}>
        <DialogTitle>
          <Box display="flex" justifyContent="center" marginTop="10px">
            <p className="text-3xl font-poppins font-normal">Configure Settings</p>
            <IconButton aria-label="close" className={classes.closeButton} onClick={() => onClose()}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent classes={{root: classes.dialogContent}}>
          <p className="text-xl text-center">
            To enable this feature and allow your customers to pay online please configure your <b>payment services</b>.
          </p>
        </DialogContent>
        <DialogActions classes={{root: classes.dialogActions}}>
          <Box display="flex" justifyContent="center" width="100%" paddingY="8px">
            <PrimaryButton label="Payment Services" type="button" onClick={() => onButtonClick()} />
          </Box>
        </DialogActions>
      </SDialog>
    </>
  );
};
