import React from 'react';
import {WidgetPanel} from '../../components/AccountsReceivable/WidgetPanel';
import {Layout} from '../../components/layout/Layout';
import {CustomerFocusView} from './customer-focus-view/CustomerFocusView';
import {CustomerGroupView} from './customer-group-view/CustomerGroupView';
import {PreviewSelectedTransactions} from '../../components/AccountsReceivable/PreviewSelectedTransactions';
import {useFeatureFlags} from '../../hooks/useFeatureFlags';
import {EmailBatchStatementsWidget} from '../../components/AccountsReceivable/EmailBatchStatementsWidget';
interface ISupplierView {
  customerId: number | undefined;
  transactionId: number | undefined;
}

export const SupplierEnd = (props: ISupplierView) => {
  // Props
  const {customerId, transactionId} = props;

  //Feature Flags
  const {statementsAndAutomatedStatement83193} = useFeatureFlags().tenantOwned();

  const getLeftPanel = () => {
    if (!customerId && !transactionId) {
      return <CustomerGroupView />;
    } else if (customerId && !transactionId) {
      return <CustomerFocusView customerId={customerId} />;
    }
  };

  const getWigetPanel = () => {
    if (!customerId && !transactionId) {
      if (statementsAndAutomatedStatement83193) {
        return (
          <WidgetPanel>
            <EmailBatchStatementsWidget />
          </WidgetPanel>
        );
      }
      return (
        <WidgetPanel>
          <PreviewSelectedTransactions />
        </WidgetPanel>
      );
    } else if (customerId && !transactionId) {
      return (
        <WidgetPanel>
          <PreviewSelectedTransactions />
        </WidgetPanel>
      );
    }
  };

  return <Layout leftPanel={getLeftPanel()} mainPanel={getWigetPanel()} splitWidthType={'AR'} />;
};
