import {useContext, useEffect, useState} from 'react';
import {IActionResults, IActionRes, IPagedActionResults} from '../model/ActionResults';
import {ISynkSaveQueueResponse, ISynkValidation} from '../model/synkd/SynkResponse';
import {IDeliveryReceipt} from '../model/delivery-receipt/DeliveryReceipt';
import {ISearchFilter} from '../model/search-filters/SearchFilter';
import {DeliveryReceiptStatusTypes} from '../model/constants/Constants';
import useHttp from '../hooks/useHttp';
import AppContext from '../context/app/appContext';
import {IDeliveryReceiptAddLine} from '../components/purchase-order/DeliveryReceiptLines';

const useDeliveryReceiptAPI = () => {
  const {GET, POST, DELETE, isLoading} = useHttp();

  const getAll = async (filter?: any): Promise<IPagedActionResults<IDeliveryReceipt>> => {
    return GET('v2/deliveryReceipt', filter).then((data: IPagedActionResults<IDeliveryReceipt>) => data);
  };

  const get = async (deliveryReceiptID: number): Promise<IDeliveryReceipt> => {
    return GET(`v2/deliveryReceipt/${deliveryReceiptID}`).then((data: IActionResults<IDeliveryReceipt>) => data.Value);
  };

  const save = async (deliveryReceipt: Partial<IDeliveryReceipt>): Promise<ISynkValidation> => {
    return POST('v2/deliveryReceipt', {Value: deliveryReceipt}).then((data: ISynkSaveQueueResponse) => data.Value);
  };

  const deleteDeliveryReceipt = (deliveryReceiptID: number): Promise<boolean> => {
    return DELETE('deliveryReceipt/delete', {Value: deliveryReceiptID}).then((data: IActionRes) => data.IsSuccess);
  };

  const addInventoryDeliveryReceipt = async (
    deliveryReceiptID: number,
    body: IDeliveryReceiptAddLine
  ): Promise<IDeliveryReceipt> => {
    const data = await POST(`v2/DeliveryReceipt/${deliveryReceiptID}/inventory`, {Value: body});
    return data.Value;
  };

  const updateStatus = async (deliveryReceiptID: number, status: DeliveryReceiptStatusTypes) => {
    return POST('/deliveryReceipt/UpdateStatus', {
      ID: deliveryReceiptID,
      Status: status,
    }).then((data: IActionRes) => data.IsSuccess);
  };

  const generateDeliveryReceipt = async (payload: Partial<IDeliveryReceipt>): Promise<IDeliveryReceipt> => {
    return POST('DeliveryReceipt/PurchaseOrderLines', {Value: payload}).then(
      (data: IActionResults<IDeliveryReceipt>) => data.Value
    );
  };

  return {
    get,
    getAll,
    save,
    updateStatus,
    generateDeliveryReceipt,
    deleteDeliveryReceipt,
    addInventoryDeliveryReceipt,
    isLoading,
  };
};

export default useDeliveryReceiptAPI;

export const useLoadDeliveryReceipt = () => {
  const {getAll} = useDeliveryReceiptAPI();
  const {tenantInfo} = useContext(AppContext);

  const defaultWarehouseID = tenantInfo?.TenantUserDetails?.DefaultWarehouseID;

  let pageSize = 15;

  const initialFilterState = {
    Status: [
      DeliveryReceiptStatusTypes.In_Progress,
      DeliveryReceiptStatusTypes.Verified,
      DeliveryReceiptStatusTypes.Complete,
      DeliveryReceiptStatusTypes.Billed,
    ],
    WarehouseID: defaultWarehouseID,
    LastRowNumber: 0,
    MaxResults: pageSize,
    SortAsc: false,
    SortField: 'CreatedDateTime_UTC',
    IsExactMatch: false,
  } as ISearchFilter;

  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<IDeliveryReceipt[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [moreToGet, setMoreToGet] = useState<boolean>(true);
  const [error, setError] = useState<any>();
  const [searchFilter, setSearchFilter] = useState<ISearchFilter>(initialFilterState);

  const getDeliveryReceiptList = async () => {
    setLoading(true);

    try {
      const {Items, TotalRecordCount, MoreToGet, LastRowNumber} = await getAll(searchFilter);
      setItems(Items || []);
      setTotalCount(TotalRecordCount);
      setMoreToGet(MoreToGet);
      setCurrentPage(Math.ceil(LastRowNumber / (searchFilter.MaxResults || pageSize)));
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDeliveryReceiptList();
  }, [searchFilter]);

  return {
    loading,
    items,
    totalCount,
    pageSize: searchFilter.MaxResults || pageSize,
    currentPage,
    moreToGet,
    error,
    searchFilter,
    refetch: getDeliveryReceiptList,
    setSearchFilter,
  };
};
