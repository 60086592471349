import * as React from 'react';

const DropdownArrow = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" {...props}>
    <path
      fillRule="evenodd"
      d="M4.2889 5.4688h7.4533a.6666.6666 0 0 1 .4667 1.14l-3.72 3.7199a.6663.6663 0 0 1-.9467 0l-3.72-3.72a.6667.6667 0 0 1 .4667-1.14Z"
      clipRule="evenodd"
      fill="currentColor"
    />
  </svg>
);
export {DropdownArrow};
