import * as React from 'react';
const CalendarIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 100 100" {...props}>
    <title>{'Icons/Time/calendar'}</title>
    <g id="Icons/Time/calendar" stroke="none" strokeWidth={1} fillRule="evenodd">
      <path
        d="M79.1666667,8.33333333 L75,8.33333333 L75,4.16666667 C75,1.86548021 73.1345198,0 70.8333333,0 C68.5321469,0 66.6666667,1.86548021 66.6666667,4.16666667 L66.6666667,8.33333333 L33.3333333,8.33333333 L33.3333333,4.16666667 C33.3333333,1.86548021 31.4678531,0 29.1666667,0 C26.8654802,0 25,1.86548021 25,4.16666667 L25,8.33333333 L20.8333333,8.33333333 C9.33311187,8.34710882 0.0137754847,17.6664452 0,29.1666667 L0,79.1666667 C0.0137754847,90.6668881 9.33311187,99.9862245 20.8333333,100 L79.1666667,100 C90.6668881,99.9862245 99.9862245,90.6668881 100,79.1666667 L100,29.1666667 C99.9862245,17.6664452 90.6668881,8.34710882 79.1666667,8.33333333 Z M8.33333333,29.1666667 C8.33333333,22.2631073 13.929774,16.6666667 20.8333333,16.6666667 L79.1666667,16.6666667 C86.070226,16.6666667 91.6666667,22.2631073 91.6666667,29.1666667 L91.6666667,33.3333333 L8.33333333,33.3333333 L8.33333333,29.1666667 Z M79.1666667,91.6666667 L20.8333333,91.6666667 C13.929774,91.6666667 8.33333333,86.070226 8.33333333,79.1666667 L8.33333333,41.6666667 L91.6666667,41.6666667 L91.6666667,79.1666667 C91.6666667,86.070226 86.070226,91.6666667 79.1666667,91.6666667 Z"
        id="Shape"
      />
      <circle id="Oval" cx={50} cy={62.5} r={6.25} />
      <circle id="Oval" cx={29.1666667} cy={62.5} r={6.25} />
      <circle id="Oval" cx={70.8333333} cy={62.5} r={6.25} />
    </g>
  </svg>
);
export default CalendarIcon;
