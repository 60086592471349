import React from 'react';
import {Checkbox, CheckboxProps} from 'spenda-ui-react';

export const RoundedCheckbox = ({
  containerProps,
  rootContainerProps,
  labelProps,
  className,
  ...rest
}: Omit<CheckboxProps, 'ref'>) => {
  return (
    <Checkbox
      {...rest}
      className={`h-5 w-5 rounded-full border-primary transition-all before:h-5 before:w-5 before:rounded-full before:border-primary before:bg-white checked:border-primary checked:bg-primary ${className ?? null}`}
      containerProps={{...containerProps, className: `p-0 ${containerProps?.className}`}}
      rootContainerProps={{
        ...rootContainerProps,
        className: `flex items-center justify-center ${rootContainerProps?.className}`,
      }}
      labelProps={{...labelProps, className: `font-medium text-primary ml-2.5 ${labelProps?.className}`}}
    />
  );
};
