import {createContext} from 'react';
import {ARStatementPeriodDefaultOptions} from '../../model/constants/Constants';
import {ITxData} from '../../components/AccountsReceivable/TransactionView';
import {
  IARModalToShow,
  IEmailBatch,
  IPsblBatch,
  IPsblStatement,
} from '../../model/accounts-receivable/AccountsReceivable';

export interface IARContextState {
  selectedStatementPeriod: string | ARStatementPeriodDefaultOptions;
  psblStatement: IPsblStatement;
  psblBatch: IPsblBatch;
  arModalToShow: IARModalToShow | undefined;
  viewingTx: ITxData | undefined;
  filterConversationId: number | undefined;
  emailBatch: IEmailBatch | undefined;
}

export interface IARContextType extends IARContextState {
  setSelectedStatementPeriod: (statementId: string | ARStatementPeriodDefaultOptions) => void;
  setPsblStatement: (selectedTransactions: IPsblStatement) => void;
  setPsblBatch: (psblBatch: IPsblBatch) => void;
  setArModalToShow: (modalData: IARModalToShow | undefined) => void;
  setViewingTx: (data: ITxData | undefined) => void;
  setFilterConversationId: (id: number | undefined) => void;
  setEmailBatch: (customers: IEmailBatch | undefined) => void;
}

const ARContext = createContext<IARContextType>({
  selectedStatementPeriod: ARStatementPeriodDefaultOptions.ALL,
  psblStatement: {selectedTxList: []},
  psblBatch: {selectedTxList: []},
  arModalToShow: undefined,
  viewingTx: undefined,
  filterConversationId: undefined,
  emailBatch: undefined,
  setPsblStatement: (_selectedTxList: IPsblStatement) => undefined,
  setPsblBatch: (_psblBatchData: IPsblBatch) => undefined,
  setSelectedStatementPeriod: (_statementId: string | ARStatementPeriodDefaultOptions) => undefined,
  setArModalToShow: (_modalData: IARModalToShow | undefined) => undefined,
  setViewingTx: (_data: ITxData | undefined) => undefined,
  setFilterConversationId: (_id: number | undefined) => undefined,
  setEmailBatch: (_customers: IEmailBatch | undefined) => undefined,
});

export default ARContext;
