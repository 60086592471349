import React from 'react';
import {SyncStatuses} from '../../model/constants/Constants';
import {pascalToSentence} from '../../utils/formatter';
import {WidgetTooltip} from '../data-display/WidgetTooltip';
import SBadge from '../ui/Badge';
import WarningIcon from '@material-ui/icons/Warning';

export enum IntegrationStatus {
  Error = 'Error',
  InProgress = 'InProgress',
  NotPosted = 'NotPosted',
  Posted = 'Posted',
}

export const IntegrationStatusBadge = (props: {syncStatus?: SyncStatuses; syncError?: string}) => {
  let integrationStatus = IntegrationStatus.NotPosted;
  switch (props.syncStatus) {
    case SyncStatuses.Invalid:
    case SyncStatuses.Ignore:
      integrationStatus = IntegrationStatus.NotPosted;
      break;
    case SyncStatuses.Ready:
    case SyncStatuses.Pending:
    case SyncStatuses.Syncing:
      integrationStatus = IntegrationStatus.InProgress;
      break;
    case SyncStatuses.Synced:
      integrationStatus = IntegrationStatus.Posted;
      break;
    case SyncStatuses.Error:
      integrationStatus = IntegrationStatus.Error;
      break;
  }

  const formattedStatus = pascalToSentence(integrationStatus);

  switch (integrationStatus) {
    case IntegrationStatus.InProgress:
      return (
        <SBadge color="#C68A19" backgroundColor="#C68A1930">
          {formattedStatus}
        </SBadge>
      );
    case IntegrationStatus.Posted:
      return (
        <SBadge color="#1982c6" backgroundColor="#1982c630">
          {formattedStatus}
        </SBadge>
      );
    case IntegrationStatus.NotPosted:
      return (
        <SBadge color="#515151" backgroundColor="#51515130">
          {formattedStatus}
        </SBadge>
      );
    case IntegrationStatus.Error:
      return (
        <SBadge color="#B9624B" backgroundColor="#B9624B30">
          <WidgetTooltip arrow title={props.syncError || 'Error'}>
            <span>
              <WarningIcon className="!w-4 !h-4" /> {formattedStatus}
            </span>
          </WidgetTooltip>
        </SBadge>
      );
    default:
      return <span className="text-xs font-medium cursor-default">{formattedStatus}</span>;
  }
};
