enum ARContextActions {
  SET_PSBL_BATCH = 'SET_PSBL_BATCH',
  SET_CURRENT_STATEMENT_PERIOD = 'SET_CURRENT_STATEMENT_PERIOD',
  SET_PSBL_STATEMENT = 'SET_PSBL_STATEMENT',
  SET_AR_MODAL_TO_SHOW = 'SET_AR_MODAL_TO_SHOW',
  SET_CURRENT_VIEWING_TX = 'SET_CURRENT_VIEWING_TX',
  SET_FILTER_CONVERSATION_ID = 'SET_FILTER_CONVERSATION_ID',
  SET_EMAIL_BATCH = 'SET_EMAIL_BATCH',
}

export default ARContextActions;
