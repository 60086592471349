import React from 'react';
import {Button, Typography} from 'spenda-ui-react';
export interface IRemovePartialPaymentDialogProps {
  handleClose: () => void;
  onConfirm: () => void;
}

export const RemovePartialPaymentDialog = (props: IRemovePartialPaymentDialogProps) => {
  const {handleClose, onConfirm} = props;

  return (
    <div
      className={`absolute left-[0px] top-[8px] z-20 flex h-full w-full flex-col items-center justify-center rounded-[2px] bg-[#ADADAD20] drop-shadow backdrop-blur`}
    >
      <div className={`h-auto w-[500px] rounded-[10px] bg-white p-2.5`}>
        <div className="relative flex h-[60px] flex-row items-center justify-center border-[#F7F7F7] border-b-default px-4">
          <Typography variant="h2" className=" font-light text-[#333333]">
            Partial payment in progress
          </Typography>
        </div>
        <div className=" mx-2 my-12 py-2.5">
          <Typography variant="paragraph" className="text-center font-light text-black-800">
            There is a partial payment in progress, would you like to continue your partial payment?
          </Typography>
        </div>
        <div className=" flex h-[52px] items-center justify-between rounded-[6px] bg-[#EFEFEF] px-2.5">
          <Button variant="filled" color="error" data-autoid={`btnDiscard`} onClick={onConfirm}>
            Discard
          </Button>
          <Button variant="filled" color="primary" data-autoid={`btnContinue`} onClick={handleClose}>
            Continue
          </Button>
        </div>
      </div>
    </div>
  );
};
