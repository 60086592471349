import React, {PropsWithChildren, useState} from 'react';
import {IServiceJob, ServiceJobScope} from '../../model/service-management/serviceJob';
import {ServiceContext} from './ServiceContext';
import { ICustomer } from '../../model/customer/Customer';

const ServiceContextProvider: React.FC<PropsWithChildren> = ({children}) => {
  const [serviceJob, setServiceJob] = useState<IServiceJob>();
  const [customerDetails, setCustomer] = useState<ICustomer>();
  const [scope, setScope] = useState<ServiceJobScope>(ServiceJobScope.QuoteDetails);

  return (
    <ServiceContext.Provider
      value={{
        customerDetails: customerDetails,
        serviceJob: serviceJob,
        setCustomerDetails: setCustomer,
        setServiceJob: setServiceJob,
        scope: scope,
        setScope: setScope,
      }}
    >
      {children}
    </ServiceContext.Provider>
  );
};
export {ServiceContextProvider};
