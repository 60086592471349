import * as React from 'react';
const TourPauseIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" {...props}>
    <path
      fill="#1C78AD"
      fillRule="evenodd"
      d="M10 0C4.475 0 0 4.475 0 10s4.475 10 10 10 10-4.475 10-10S15.525 0 10 0ZM9 14H7V6h2v8Zm4 0h-2V6h2v8Z"
      clipRule="evenodd"
    />
  </svg>
);
export default TourPauseIcon;
