import React, {useState} from 'react';
import Dropzone from 'react-dropzone';
import {css} from 'glamor';
import {AlertDialogSlide} from '../dialog/AlertDialogSlide';
import DeleteIcon from '@material-ui/icons/Delete';
import UploadFileIcon from '../../assets/svg/upload.svg';
import APUploadIcon from '../../assets/svg/accounts-payable/APUploadIcon';

export const DropZoneComponent = (props: {
  onChange: (files: File[]) => void;
  placeholder: string;
  uploadedFile: string | undefined;
  onRemove: () => void;
  dialogTitle: string;
  dialogDescription?: string;
  isAPOnboardingDropZone?: boolean;
  isAPOnboardingV1Header?: boolean;
}) => {
  const {
    uploadedFile,
    onChange,
    placeholder,
    onRemove,
    dialogTitle,
    dialogDescription,
    isAPOnboardingDropZone,
    isAPOnboardingV1Header,
  } = props;

  const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] = useState<Boolean>(false);
  const [error, setError] = useState<string>('');

  const dropzone = css({
    width: '100%',
    height: ' 110px',
    background: isAPOnboardingDropZone ? '#E3ECF1' : isAPOnboardingV1Header ? ' #EAEFF280' : '#F4F4F4',
    border: isAPOnboardingDropZone
      ? '1px solid hsl(var(--primary))'
      : isAPOnboardingV1Header
        ? '2.4px dashed hsl(var(--primary))'
        : '2px dashed hsl(var(--primary))',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    borderRadius: '8px',
    padding: '10px',
  });

  const apOnboardingDropZone = css({
    '& p': {
      fontFamily: 'Poppins, sans-serif !important',
    },
  });

  const getError = (type: string) => {
    switch (type) {
      case 'file-too-large':
        return 'File size exceeds 10MB.';

      case 'file-invalid-type':
        return 'File Type must be jpeg/png/gif';

      default:
        return 'Some Error Occured';
    }
  };

  return (
    <>
      {!uploadedFile && (
        <Dropzone
          noKeyboard
          accept={['image/jpeg', 'image/png', 'image/gif']}
          maxSize={10485760}
          multiple={false}
          onDrop={() => {
            setError('');
          }}
          onDropAccepted={acceptedFile => onChange(acceptedFile)}
          onDropRejected={rejectedFile => {
            const errorCode = rejectedFile?.[0]?.errors[0]?.code || '';
            const error = getError(errorCode);
            setError(error);
          }}
          //onDragOver={this.handleDragOver}
        >
          {({getRootProps, getInputProps}) => {
            return (
              <div
                {...getRootProps({
                  className: `${dropzone} `,
                })}
              >
                <input {...getInputProps()} />
                {isAPOnboardingDropZone ? (
                  <div className={`drag-drop-placeholder-text ${apOnboardingDropZone} `}>
                    <img src={UploadFileIcon} />
                    <p className="paceholder-big">Drop an image here or click</p>
                  </div>
                ) : (
                  <div className="drag-drop-placeholder-text">
                    {props.isAPOnboardingV1Header ? (
                      <>
                        <APUploadIcon className="mx-auto !mb-3 h-[37px] w-[60px]" />
                        <p className="font-poppins text-xs">Drop an image here or click to upload</p>
                      </>
                    ) : (
                      <>
                        <p className="paceholder-big">Upload or drop your files here</p>
                        <p className="placeholder-small">{placeholder}</p>
                      </>
                    )}
                  </div>
                )}
              </div>
            );
          }}
        </Dropzone>
      )}
      {uploadedFile && (
        <div className="upload-img-field">
          <img src={uploadedFile} />
          <div className="overlay-img">
            <span onClick={() => setShowDeleteConfirmationDialog(true)}>
              <DeleteIcon />
            </span>
          </div>
        </div>
      )}
      {showDeleteConfirmationDialog && (
        <AlertDialogSlide
          id="discardPopup"
          title={dialogTitle}
          actions={[
            {
              label: 'Yes',
              fontFamily: 'poppins',
              height: '40px',
              padding: '7px 20px',
              action: () => {
                setShowDeleteConfirmationDialog(false);
                onRemove();
              },
            },
            {
              label: 'No',
              color: 'transparent',
              textColor: 'blue',
              fontFamily: 'poppins',
              height: '40px',
              padding: '7px 20px',
              border: '1px solid hsl(var(--primary));',
              className: 'delete-image-cancel-btn',
              action: () => setShowDeleteConfirmationDialog(false),
            },
          ]}
        >
          <p className="py-12 font-poppins">{dialogDescription} </p>
        </AlertDialogSlide>
      )}
      {error && <p className="file-error font-poppins">{error}</p>}
    </>
  );
};
