import {useFormik} from 'formik';
import React, {useEffect, useState} from 'react';
import 'react-quill/dist/quill.snow.css';
import {Button, Input} from 'spenda-ui-react';
import * as Yup from 'yup';
import {IEmailTemplate} from '../../model/statements/Statements';
import {useAutomatedStatementsAPI} from '../../services/useAutomatedStatementsAPI';
import {ARDialogTemplate} from './ARDialogTemplate';
import RichTextEditor from './automated-statement/RichTextEditor';
import LoadingIndicator from '../ui/LoadingIndicator';
import {DatTypes} from '../../model/constants/Constants';
import {Toast} from '../../utils/Toast';
import {WarningDialog} from './modals/WarningDialog';

interface IFormInitialValues {
  templateName: string;
  templateSubject: string;
  templateBody: string;
  templateDescription: string;
  templateColour: string;
}

const formInitialValues = {
  templateName: '',
  templateSubject: '',
  templateBody: '',
  templateDescription: '',
  templateColour: '#C68A19',
};

const validationSchema = Yup.object({
  templateSubject: Yup.string().required('Template subject is required.'),
  templateName: Yup.string().required('Template name is required.'),
  templateDescription: Yup.string().required('Template description is required.'),
  templateBody: Yup.string().required('Template body is required'),
  templateColour: Yup.string().required('Template colour is required'),
});

const templateColors = [
  {id: 'btnRed', color: '#C55D44'},
  {id: 'btnGreen', color: '#3C9F78'},
  {id: 'btnBlue', color: '#1C78AD'},
  {id: 'btnYellow', color: '#C68A19'},
  {id: 'btnPurple', color: '#8178CF'},
];

interface ICreateTemplateDialogProps {
  isShowModal: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  alertId?: number;
  onClickPreviewButton: (templateDetails: IEmailTemplate | undefined) => void;
}

export const CreateEmailTemplateDialog = (props: ICreateTemplateDialogProps) => {
  // Props
  const {isShowModal, alertId, onClickPreviewButton, onClose} = props;

  // State
  const [formValues, setFormValues] = useState<IFormInitialValues>(formInitialValues);
  const [templateDetails, setTemplateDetails] = useState<IEmailTemplate>();
  const [isShowUnSavedWarningDialog, setIsShowUnSavedWarningDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [continueAction, setContinueAction] = useState<string>();

  // API
  const {getEmailTemplate, createEmailTemplate} = useAutomatedStatementsAPI();

  useEffect(() => {
    fetchTemplate(alertId, true);
  }, [alertId]);

  const fetchTemplate = async (alertID?: number, isShowLoading?: boolean) => {
    isShowLoading && setIsLoading(true);
    try {
      const payload = {
        isMasterTemplate: !alertID,
        alertID: alertID,
        websiteID: 13,
        datTypeID: DatTypes.CustomerStatements,
      };

      const response = await getEmailTemplate(payload);
      setTemplateDetails(response?.[0]);
      const {body, name, subject, description, colourCode} = response?.[0];
      setFormValues(prev => ({
        templateName: name,
        templateSubject: subject,
        templateBody: body,
        templateDescription: description,
        templateColour: colourCode || prev?.templateColour,
      }));
    } catch (e) {
      console.error('Error fetching template details', e);
    } finally {
      setIsLoading(false);
    }
  };

  // Drag and drop
  const handleDragStart = (event: React.DragEvent<HTMLDivElement>, tag: string) => {
    event.dataTransfer.setData('text/plain', tag);
  };

  // Formik
  const handleFormSubmit = async (values: IFormInitialValues) => {
    try {
      const payload = {
        alertID: templateDetails?.isMasterTemplate ? undefined : templateDetails?.alertID,
        alertType: 'Email',
        datTypeID: DatTypes.CustomerStatements,
        websiteID: 13,
        name: values?.templateName,
        description: values?.templateDescription,
        subject: values?.templateSubject,
        template: values?.templateBody?.replaceAll('<br>', '<br/>'),
        templateDataType: 'XSLT',
        colourCode: values?.templateColour,
      };

      const createResponse = await createEmailTemplate(payload);
      await fetchTemplate(createResponse?.alertID, false);
      Toast.info('Template Saved');
    } catch {}
  };

  const {values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, dirty, isSubmitting} =
    useFormik({
      initialValues: formValues,
      validationSchema: validationSchema,
      onSubmit: handleFormSubmit,
      enableReinitialize: true,
    });

  const performContinueButtonAction = (action?: string) => {
    switch (action) {
      case 'CLOSE':
        onClose();
        break;
      case 'PREVIEW':
        onClickPreviewButton(templateDetails);
        break;
      default:
        break;
    }
  };

  const checkIsFormChanged = (action: string) => {
    if (dirty) {
      setContinueAction(action);
      setIsShowUnSavedWarningDialog(true);
      return;
    }
    performContinueButtonAction(action);
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <ARDialogTemplate
          dialogProps={{size: 'sm', open: isShowModal, className: '!min-w-[918px]'}}
          isFullScreen
          footerClass="!h-[60px]"
          header={<p className="from-spenda-primarytext font-poppins text-xl font-light">Create template</p>}
          body={
            <div className="flex h-auto w-[900px] flex-col flex-wrap items-start justify-start !gap-x-2.5 !gap-y-2.5 overflow-y-auto">
              {isLoading ? (
                <div className="flex h-[200px] w-full items-center justify-center">
                  <LoadingIndicator size="md" isLoading={isLoading} />
                </div>
              ) : (
                <div>
                  <div className="my-5 flex h-auto w-[70%] flex-col gap-y-5">
                    <div className="flex w-full flex-row items-center justify-start gap-x-[30px]">
                      <div className="relative h-10 w-full min-w-[200px] font-poppins">
                        <Input
                          type="text"
                          label="Template name"
                          placeholder="Template name"
                          value={values?.templateName}
                          name="templateName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          helperText={errors?.templateName && touched?.templateName ? errors?.templateName : ''}
                          error={Boolean(errors?.templateName && touched?.templateName)}
                        />
                      </div>
                      <div>
                        <div className="flex flex-row items-center justify-start gap-x-2.5">
                          {templateColors?.map(c => (
                            <button
                              key={c?.id}
                              data-autoid={c?.id}
                              onClick={() => setFieldValue('templateColour', c?.color)}
                              className={`flex h-[32px] w-[32px] cursor-pointer rounded-[4px]`}
                              style={{backgroundColor: c?.color}}
                            >
                              {values?.templateColour === c?.color && (
                                <p className="h-[32px] w-[32px] border-spacing-1 rounded-[4px]  border-[3px] border-black-800"></p>
                              )}
                            </button>
                          ))}
                        </div>
                        {touched.templateColour && errors.templateColour && (
                          <p className="font-poppins text-xs text-red-700">{errors.templateColour}</p>
                        )}
                      </div>
                    </div>
                    <div className="relative h-10 w-full min-w-[200px] font-poppins">
                      <Input
                        type="text"
                        label="Template description"
                        placeholder="Template description"
                        value={values?.templateDescription}
                        name="templateDescription"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={
                          errors?.templateDescription && touched?.templateDescription ? errors?.templateDescription : ''
                        }
                        error={Boolean(errors?.templateDescription && touched?.templateDescription)}
                      />
                    </div>
                    <div className="relative h-10 w-full min-w-[200px] font-poppins">
                      <Input
                        type="text"
                        label="Subject"
                        placeholder="Subject"
                        value={values?.templateSubject}
                        name="templateSubject"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={errors?.templateSubject && touched?.templateSubject ? errors?.templateSubject : ''}
                        error={Boolean(errors?.templateSubject && touched?.templateSubject)}
                      />
                    </div>
                  </div>
                  <div className="my-5 flex h-auto w-full flex-row justify-between gap-x-5 pr-4">
                    <div className="flex w-[70%] flex-col">
                      <div className="h-full">
                        <RichTextEditor
                          dataAutoId="txtTemplateBody"
                          defaultValue={templateDetails?.body}
                          handleChange={v => setFieldValue('templateBody', v === '<p><br></p>' ? '' : v)}
                        />
                      </div>
                      {errors.templateBody && touched.templateBody && (
                        <div className="font-poppins text-xs font-medium text-red-700">{errors.templateBody}</div>
                      )}
                    </div>

                    <div className="flex w-[30%] flex-col rounded-[6px] bg-[#E3EEF566] p-3">
                      <p className="mb-3 font-poppins text-xs font-medium">Insert variables</p>
                      <div className="flex w-full flex-row flex-wrap items-start justify-start gap-2">
                        {templateDetails?.alertContentVariables?.map(t => (
                          <div
                            key={t?.displayName}
                            id={t?.displayName}
                            draggable
                            onDragStart={e => handleDragStart(e, `{${t?.keyName}}`)}
                            className="flex cursor-pointer items-center justify-center rounded-[4px] bg-white px-2.5 py-[4px] font-poppins text-xs font-normal"
                          >
                            {t?.keyName}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          }
          footer={
            <div className=" flex h-[60px] w-full items-center justify-between">
              <div className="flex flex-row items-center justify-start gap-3">
                <Button
                  type="button"
                  data-autoid={`btnDiscard`}
                  onClick={() => checkIsFormChanged('CLOSE')}
                  className="bg-white"
                  variant="outlined"
                  color="error"
                >
                  Discard
                </Button>
              </div>
              <div className="flex flex-row items-center justify-end gap-3">
                <Button
                  type="button"
                  data-autoid={`btnPreview`}
                  className="bg-white"
                  variant="outlined"
                  color="primary"
                  onClick={() => checkIsFormChanged('PREVIEW')}
                >
                  Preview
                </Button>
                <Button
                  type="submit"
                  loading={isSubmitting}
                  variant="filled"
                  color="primary"
                  data-autoid={`btnSave`}
                  onClick={() => handleSubmit()}
                >
                  Save
                </Button>
              </div>
            </div>
          }
          handleClose={() => checkIsFormChanged('CLOSE')}
        />
      </form>

      {isShowUnSavedWarningDialog && (
        <WarningDialog
          conrfirmBtn={{title: 'Continue', onClick: () => performContinueButtonAction(continueAction)}}
          cancelBtn={{title: 'Cancel', onClick: () => setIsShowUnSavedWarningDialog(false)}}
          title="Warning"
          message="All current changes will be lost"
          onClose={() => setIsShowUnSavedWarningDialog(false)}
          isOpen={isShowUnSavedWarningDialog}
        />
      )}
    </div>
  );
};
