import React from 'react';
import {AlertDialogSlideV2} from './AlertDialogSlideV2';
import {Typography} from 'spenda-ui-react';

interface IQuoteAttachmentDocumentDialog {
  handleBack: () => void;
  handleSend: () => void;
}

function QuoteAttachmentDocumentDialog(props: IQuoteAttachmentDocumentDialog) {
  const {handleBack, handleSend} = props;

  return (
    <AlertDialogSlideV2
      data-autoid="dlgAttachedDocumentationWarning"
      dialogClassess="!min-w-[562px] !max-w-[562px] !w-full"
      title={'Attached documentation'}
      headingTextSize="h1"
      headingClassess="justify-center"
      dialogActionsAlignment="justify-between"
      actions={[
        {
          label: 'Go back',
          action: handleBack,
          variant: 'outlined',
        },
        {
          label: 'Send',
          action: handleSend,
          variant: 'filled',
        },
      ]}
    >
      <Typography variant="small" className="mx-auto w-9/12 py-10 text-center font-normal text-black-900">
        You have selected to send this quote via text message, this means the documentation you have uploaded will not
        be able to send. Are you happy to proceed? Otherwise you can go back to send this quote via email.
      </Typography>
    </AlertDialogSlideV2>
  );
}

export default QuoteAttachmentDocumentDialog;
