import React, {PropsWithChildren, useMemo} from 'react';

import useTheme from '../../context/theme-context/useTheme';
import {getNestedValue} from './VisibleContent';

interface CustomContentProps extends PropsWithChildren {
  keyPath: string;
  render?: (value: any) => React.ReactNode;
}

export const CustomContent = ({keyPath, children, render}: CustomContentProps) => {
  const {personalizedContent} = useTheme();

  const value = useMemo(() => getNestedValue(personalizedContent, keyPath), [personalizedContent, keyPath]);

  if (value === undefined) {
    return children;
  }

  return render ? render(value) : value;
};
