import * as React from 'react';
export const APCrossIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width={22} height={22} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.293 4.707a1.049 1.049 0 00-1.483 0L11 9.517l-4.81-4.81A1.049 1.049 0 004.707 6.19L9.517 11l-4.81 4.81a1.049 1.049 0 001.484 1.483l4.81-4.81 4.81 4.81a1.049 1.049 0 001.482-1.483L12.483 11l4.81-4.81c.41-.41.41-1.073 0-1.483z"
        fill="#1C78AD"
      />
    </svg>
  );
};
