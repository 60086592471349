import React, {useEffect, useMemo, useState} from 'react';
import {useCustomerStatementsAPI} from '../../../services/useCustomerStatementsAPI';
import {
  IAgedTxCustOutstandingStatementList,
  IAgedTransactionResponse,
  IGetAllCustomersResponse,
} from '../../../model/customer/CustomerStatements';
import {CustomerGroupStandardView} from './CustomerGroupStandardView';
import {CustomerGroupActivityHeader} from './CustomerGroupActivityHeader';
import {ArCustomerGroupViewTypes, CustomerGroupTableHeader} from './CustomerGroupTableHeader';
import {ARAgeReceivableDateRange} from '../../../model/constants/Constants';
import {CustomerGroupAgedReceivableView} from './CustomerGroupAgeReceivable';
import {TransactionView} from '../../../components/AccountsReceivable/TransactionView';
import {useTransactionViewHook} from '../../../hooks/useARHook';

export const CustomerGroupView = () => {
  // Hooks
  const {handleCloseTx, handleViewTx, viewingTx} = useTransactionViewHook();

  // States
  const [viewType, setViewType] = useState<ArCustomerGroupViewTypes>(ArCustomerGroupViewTypes.STANDARD_VIEW);
  const [ageReceivableDateRange, setAgeReceivableDateRange] = useState<ARAgeReceivableDateRange>(
    ARAgeReceivableDateRange.BY_MONTH,
  );
  const [customersList, setCustomersList] = useState<IGetAllCustomersResponse>();
  const [agedTransactions, setAgedTransactions] = useState<IAgedTransactionResponse>();
  const [searchString, setSearchString] = useState<string>('');
  const [expandedCustomer, setExpandedCustomer] = useState<IAgedTxCustOutstandingStatementList>();
  const [isInloadingState, setIsInloadingState] = useState(false);

  // APIs
  const {getAllCustomers, getAgedTransactions, isLoading} = useCustomerStatementsAPI();

  // UseEffects
  useEffect(() => {
    if (viewType === ArCustomerGroupViewTypes.STANDARD_VIEW) fetchAllCustomers();
    else if (viewType === ArCustomerGroupViewTypes.AGED_RECEIVABLES) fetchAgeReceivableTransactions();
  }, [searchString, viewType, ageReceivableDateRange]);

  // Functions
  const fetchAllCustomers = async () => {
    try {
      setIsInloadingState(true);
      const res = await getAllCustomers({searchString: searchString || undefined});
      setCustomersList(res);
    } finally {
      setIsInloadingState(false);
    }
  };

  const fetchAgeReceivableTransactions = async () => {
    try {
      setIsInloadingState(true);
      const _agedTransactions = await getAgedTransactions(ageReceivableDateRange, {
        searchString: searchString || undefined,
      });
      setAgedTransactions(_agedTransactions);
    } finally {
      setIsInloadingState(false);
    }
  };

  const handleViewChange = (selectedOption: ArCustomerGroupViewTypes) => {
    setViewType(selectedOption);
  };

  const handleAgeFilterChange = (selectedOption: ARAgeReceivableDateRange) => {
    setAgeReceivableDateRange(selectedOption);
  };

  const getCurrentView = () => {
    switch (viewType) {
      case ArCustomerGroupViewTypes.STANDARD_VIEW:
        return <CustomerGroupStandardView customersList={customersList} isLoading={isLoading || isInloadingState} />;
      case ArCustomerGroupViewTypes.AGED_RECEIVABLES:
        return (
          <CustomerGroupAgedReceivableView
            agedTransactions={agedTransactions}
            isLoading={isLoading || isInloadingState}
            setExpandedCustomer={setExpandedCustomer}
            expandedCustomer={expandedCustomer}
            ageReceivableDateRange={ageReceivableDateRange}
            onViewTransaction={t => handleViewTx(t)}
            scope="CUST_GROUP"
          />
        );
      default:
        return null;
    }
  };

  const getTxList = useMemo(() => {
    return expandedCustomer?.groupedList?.map(t => ({
      txId: t.transID,
      txGuid: t.transGUID,
      txDatType: t.transactionTypeID,
    }));
  }, [expandedCustomer?.groupedList]);

  return (
    <div className="flex h-full flex-col">
      {viewingTx && (
        <TransactionView
          key={viewingTx?.txGuid}
          isShowInPdfView={false}
          transactionId={viewingTx?.txId}
          txDatType={viewingTx?.txDatType}
          txGuid={viewingTx?.txGuid}
          handleOnClosePdfView={() => handleCloseTx()}
          customerId={expandedCustomer?.groupedList?.[0]?.accountCustomerID}
          scope="CustomerGroupView"
          txList={getTxList}
        />
      )}
      <CustomerGroupActivityHeader
        searchString={searchString}
        setSearchString={setSearchString}
        refetchCustomers={fetchAllCustomers}
        isLoading={isLoading}
      />
      <div className="overflow-auto">
        <div className={`flex h-full flex-col rounded-[6px] bg-white px-0 py-2.5 font-poppins`}>
          <CustomerGroupTableHeader
            onChangeAgeDateRange={handleAgeFilterChange}
            onChange={handleViewChange}
            selectedOption={viewType}
            selectedAgeDateRange={ageReceivableDateRange}
          />
          {getCurrentView()}
        </div>
      </div>
    </div>
  );
};
