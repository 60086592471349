import React from 'react';

export const DateRangeCalendar = (props: {className?: string; width?: string; height?: string}) => {
  return (
    <svg
      className={props.className || ''}
      width={props.width || '24px'}
      height={props.height || '23px'}
      viewBox="0 0 24 23"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Calendar</title>
      <g id="Main-File" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Buyers-view/Selected-Copy-2" transform="translate(-592.000000, -613.000000)" className="fill-primary">
          <g id="Group-32-Copy" transform="translate(353.000000, 335.000000)">
            <g id="Filter-expanded-Copy" transform="translate(230.000000, 269.000000)">
              <g id="Icons/General/filter" transform="translate(9.500000, 9.000000)">
                <path
                  d="M18.2083333,1.91666667 L17.25,1.91666667 L17.25,0.958333333 C17.25,0.429060448 16.8209396,0 16.2916667,0 C15.7623938,0 15.3333333,0.429060448 15.3333333,0.958333333 L15.3333333,1.91666667 L7.66666667,1.91666667 L7.66666667,0.958333333 C7.66666667,0.429060448 7.23760622,0 6.70833333,0 C6.17906045,0 5.75,0.429060448 5.75,0.958333333 L5.75,1.91666667 L4.79166667,1.91666667 C2.14661573,1.91983503 0.00316836149,4.0632824 0,6.70833333 L0,18.2083333 C0.00316836149,20.8533843 2.14661573,22.9968316 4.79166667,23 L18.2083333,23 C20.8533843,22.9968316 22.9968316,20.8533843 23,18.2083333 L23,6.70833333 C22.9968316,4.0632824 20.8533843,1.91983503 18.2083333,1.91666667 Z M1.91666667,6.70833333 C1.91666667,5.12051468 3.20384801,3.83333333 4.79166667,3.83333333 L18.2083333,3.83333333 C19.796152,3.83333333 21.0833333,5.12051468 21.0833333,6.70833333 L21.0833333,7.66666667 L1.91666667,7.66666667 L1.91666667,6.70833333 Z M18.2083333,21.0833333 L4.79166667,21.0833333 C3.20384801,21.0833333 1.91666667,19.796152 1.91666667,18.2083333 L1.91666667,9.58333333 L21.0833333,9.58333333 L21.0833333,18.2083333 C21.0833333,19.796152 19.796152,21.0833333 18.2083333,21.0833333 Z"
                  id="Shape"
                ></path>
                <circle id="Oval" cx="11.5" cy="14.375" r="1.4375"></circle>
                <circle id="Oval" cx="6.70833333" cy="14.375" r="1.4375"></circle>
                <circle id="Oval" cx="16.2916667" cy="14.375" r="1.4375"></circle>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
