import React from 'react';
import {FormikProps} from 'formik';

import {IBrand} from '../../model/inventory/Brand';
import {STextField} from '../inputs/STextField';

import {Box, Grid, makeStyles} from '@material-ui/core';
import {IDashboardActionResults} from '../../model/ActionResults';
import useBrandAPI from '../../services/useBrandAPI';
import {DatTypes} from '../../model/constants/Constants';

const useBrandFormStyles = makeStyles(() => ({
  formLabel: {
    fontSize: '1rem',
    color: '#333333',
    paddingBottom: '1.5rem',
  },
}));

export const BrandForm = (props: FormikProps<IBrand>) => {
  const classes = useBrandFormStyles();
  const {dashboard} = useBrandAPI();

  const {values, errors, touched, handleChange, status, setStatus, initialValues} = props;

  const checkBrandName = () => {
    dashboard({
      DatTypeID: DatTypes.Brands,
      LastRowNumber: 0,
      MaxResults: 1,
      Search: values.Name,
      IsExactMatch: true,
      SearchNameOnly: true,
    }).then((res: IDashboardActionResults<IBrand[]>) => {
      if (res.TotalRecordCount > 0 && res.Value[0]?.Name !== initialValues.Name) {
        setStatus({Name: 'Brand name already exists'});
      } else {
        setStatus(null);
      }
    });
  };

  return (
    <Box display="flex" flexDirection="column" justifyContent="flex-start" alignItems="start" marginBottom="20px">
      <Grid container spacing={1}>
        <Grid item sm={2} className="flex items-end">
          <p className={`${classes.formLabel}`}>Name:</p>
        </Grid>
        <Grid item sm={3}>
          <STextField
            id="Name"
            name="Name"
            placeholder="Brand Name"
            value={values.Name}
            onChange={e => {
              if (!(values.Name.length === 0 && e.target.value === ' ')) handleChange(e);
            }}
            onBlur={checkBrandName}
            helperText={(touched?.Name && errors?.Name) || (!!status && status?.Name)}
            error={(touched?.Name && Boolean(errors?.Name)) || !!status}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item sm={2} className="flex items-end">
          <p className={`${classes.formLabel}`}>Description:</p>
        </Grid>
        <Grid item sm={10}>
          <STextField
            id="Description"
            name="Description"
            placeholder="Brand Description"
            value={values.Description}
            onChange={handleChange}
            helperText={touched?.Description && errors?.Description}
            error={touched?.Description && Boolean(errors?.Description)}
            fullWidth
          />
        </Grid>
      </Grid>
    </Box>
  );
};
