import {IRequisitionActionOption} from './../../model/requisition/RequisitionActionOption';
import {Inventory} from './../../model/inventory/Inventory';
import {IPurchasingState} from './purchasingContext';
import {IPagedActionResults} from '../../model/ActionResults';
import PurchasingActions from './purchasingActions';
import {IRequisition} from '../../model/requisition/Requisition';
import {PaymentProcessStatus} from '../../model/constants/Constants';

export default (state: IPurchasingState, action: {type: string; payload: any; options?: any}) => {
  switch (action.type) {
    case PurchasingActions.SET_PRODUCTS:
      const payload = action.payload as IPagedActionResults<Inventory[]>;
      return {
        ...state,
        products: payload.Value,
        totalProducts: payload.TotalRecordCount,
        totalPagedProducts: payload.LastRowNumber,
      };
    case PurchasingActions.SET_PRODUCT_TO_ADD:
      const productToAdd = action.payload as Inventory;
      return {
        ...state,
        productToAdd,
        isShowRequisitionDetailsDialog: productToAdd ? true : false,
      };
    case PurchasingActions.SET_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };
    case PurchasingActions.SET_DISPLAY_ORDER_POUP:
      return {
        ...state,
        productToAdd: action.payload ? state.productToAdd : undefined, // Clear our productToAdd when closing Requisition Details Dialog
        isShowRequisitionDetailsDialog: action.payload,
        poToCopyFrom: action.options,
      };
    case PurchasingActions.SET_REQUISITION:
      return {
        ...state,
        requisition: action.payload,
        purchaseOrder: undefined,
      };
    case PurchasingActions.SAVE_REQUISITION_LINES:
      return state;
    case PurchasingActions.SEARCH_TRANSACTIONS:
      const transAndCounts = action.payload as {
        statusCounters: IRequisitionActionOption[];
        transactions: IRequisition[];
      };

      return {
        ...state,
        statusCounters: transAndCounts.statusCounters,
        transactions: transAndCounts.transactions,
      };
    case PurchasingActions.SET_REQUISITION_TYPE_ACTIVE:
      const requisitionTypeOptionClicked = action.payload as IRequisitionActionOption;
      const actionOptions = [...state.statusCounters];
      actionOptions.forEach(ao => (ao.IsActive = ao.ID === requisitionTypeOptionClicked.ID));
      return {
        ...state,
        statusCounters: actionOptions,
      };
    case PurchasingActions.SET_PURCHASE_ORDER:
      const po = action.payload;
      return {
        ...state,
        purchaseOrder: po,
        requisition: undefined,
      };
    case PurchasingActions.SET_INVOICES_TO_PAY:
      return {
        ...state,
        invoicesToPay: action.payload,
      };
    case PurchasingActions.SET_PAYMENT_PROCESS_STATUS:
      return {
        ...state,
        paymentProcessStatus: action.payload,
      };
    case PurchasingActions.SET_PAYMENT_OUTCOME:
      return {
        ...state,
        paymentProcessStatus: PaymentProcessStatus.COMPLETE,
        paymentOutcome: action.payload,
      };
    case PurchasingActions.RESET_PAYMENT_PROCESS:
      return {
        ...state,
        paymentProcessStatus: PaymentProcessStatus.NEW,
        paymentOutcome: undefined,
        invoicesToPay: [],
        isLoyaltyPointsToggleOn: false,
        creditsToApply: [],
        creditStatementSummary: undefined,
      };
    case PurchasingActions.SET_PAYMENT_COUNTS:
      return {
        ...state,
        paymentStatusCounts: action.payload,
      };
    case PurchasingActions.SET_CREDITS_TO_APPLY:
      return {
        ...state,
        creditsToApply: action.payload,
      };
    case PurchasingActions.SET_CREDITS_STATEMENT_SUMMARY:
      return {
        ...state,
        creditStatementSummary: action.payload,
      };
    case PurchasingActions.SET_LOYALTY_POINTS_TOGGLE:
      return {
        ...state,
        isLoyaltyPointsToggleOn: action.payload,
      };
    case PurchasingActions.SET_CONVERSATIONS:
      return {
        ...state,
        conversations: action.payload,
      };
    case PurchasingActions.SET_BATCH_RESPONSE:
      return {
        ...state,
        psblBatch: action.payload.batchRes,
        totalInvoiceAmount: action.payload.totalInvoiceAmount,
        totalCreditAndClaimsSelected: action.payload.totalCreditAndClaimsSelected,
        totalPrepaymentsSelected: action.payload.totalPrepaymentsSelected,
      };
    case PurchasingActions.SET_CREDIT_AND_CLAIMS_LIST:
      return {
        ...state,
        creditAndClaimsList: action.payload,
      };
    default:
      return state;
  }
};
