import * as React from 'react';
const EyeIcon = (props: SVGProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="none" {...props}>
    <path
      fill="#1C78AD"
      fillRule="evenodd"
      d="M16.487 6.661c-1.099-1.79-3.598-4.791-7.984-4.791S1.618 4.872.52 6.661a3.477 3.477 0 0 0 0 3.657c1.098 1.789 3.597 4.79 7.983 4.79 4.386 0 6.885-3.001 7.984-4.79a3.477 3.477 0 0 0 0-3.657Zm-1.208 2.915c-.943 1.534-3.08 4.116-6.776 4.116-3.697 0-5.832-2.582-6.776-4.116a2.067 2.067 0 0 1 0-2.173c.944-1.534 3.08-4.116 6.776-4.116 3.697 0 5.833 2.579 6.776 4.116a2.067 2.067 0 0 1 0 2.173Z"
      clipRule="evenodd"
    />
    <path
      fill="#1C78AD"
      fillRule="evenodd"
      d="M8.504 4.948a3.542 3.542 0 1 0 0 7.083 3.542 3.542 0 0 0 0-7.083Zm0 5.666a2.125 2.125 0 1 1 0-4.25 2.125 2.125 0 0 1 0 4.25Z"
      clipRule="evenodd"
    />
  </svg>
);
export default EyeIcon;
