import React from 'react';

import {ArRefreshBtn} from '../../../components/arRefresh/ArRefresh';
import {DatTypes} from '../../../model/constants/Constants';
import {ARSearchBar} from '../../../components/AccountsReceivable/ARSearchBar';

interface ICustomerGroupActivityHeaderProps {
  searchString: string;
  setSearchString: React.Dispatch<React.SetStateAction<string>>;
  refetchCustomers: () => Promise<void>;
  isLoading: boolean;
}

export const CustomerGroupActivityHeader = (props: ICustomerGroupActivityHeaderProps) => {
  const {searchString, setSearchString, refetchCustomers, isLoading} = props;

  const handleRefreshData = () => {
    refetchCustomers();
  };

  return (
    <>
      <div
        className="mb-[8px] flex justify-between rounded-[6px] bg-white px-2.5 py-2.5"
        style={{boxShadow: '0px 0px 6px 0px #D3E5EF'}}
      >
        <ARSearchBar
          type="simple"
          searchString={searchString}
          onSubmit={({searchString}) => setSearchString(searchString)}
          isSearching={isLoading}
          dataAutoIdSuffix={'customerGroupActivityHeader'}
        />
        <ArRefreshBtn handleRefreshData={handleRefreshData} datTypeId={DatTypes.Customers} />
      </div>
    </>
  );
};
