import React from 'react';
import GridOnIcon from '@material-ui/icons/GridOn';
import {LabelAndCounter} from '../surfaces/LabelAndCounter';
import {css} from 'glamor';
import {ICategory} from '../../model/inventory/Category';
import {Tabs, makeStyles} from '@material-ui/core';
import {SelectedCategory} from './SelectedCategory';
import {IProductsFilter} from '../../model/search-filters/ProductsFilter';

interface ICategoryListProps {
  categories: ICategory[];
  isCollapsed: boolean;
  selectedCategories: ICategory[];
  deselectCategory: (cat: ICategory) => void;
  selectCategory: (cat: ICategory) => void;
  toggleCategories: () => void;
  updateFilter: (filter: IProductsFilter) => void;
}

export const CategoryList = (props: ICategoryListProps) => {
  const tabStyles = makeStyles({
    scroller: {
      display: 'flex',
      alignItems: 'center',
    },
  });

  const font = css({
    // fontFamily: "Saira Extra Condensed",
    fontSize: '18px',
    fontWeight: 500,
  });

  const expandTransition = css({
    flexWrap: props.isCollapsed ? 'nowrap' : 'wrap',
    transition: 'all 2s',
    cursor: 'pointer',
  });

  const onClickCategory = (cat: ICategory) => {
    props.selectCategory(cat);
  };

  const onClearCategory = (cat: ICategory) => {
    props.deselectCategory(cat);
  };

  const tabClasses = tabStyles();

  return (
    <div id="category-list" style={{display: '-webkit-flex', flexDirection: 'column', flex: '0 0 auto'}}>
      <h2 className={`${font} mb-1 sm:hidden xl:block`}>Categories</h2>
      <div className="mb-1 flex">
        {props.selectedCategories.map((cat: ICategory) => (
          <SelectedCategory cat={cat} onClear={onClearCategory} key={cat.ID}></SelectedCategory>
        ))}
      </div>
      <div className="flex items-center justify-between rounded border px-2 py-1">
        <GridOnIcon onClick={props.toggleCategories} style={{cursor: 'pointer'}} />
        <Tabs className={tabClasses.scroller} orientation="horizontal" variant="scrollable" value={false}>
          <div className={`flex flex-auto justify-start ${expandTransition}`}>
            {props.categories.map((cat: ICategory, index) =>
              cat.InventoryCount > 0 ? (
                <LabelAndCounter
                  key={index}
                  id={cat.ID}
                  count={cat.InventoryCount}
                  label={cat.Name}
                  onClick={() => onClickCategory(cat)}
                />
              ) : undefined,
            )}
          </div>
        </Tabs>
      </div>
    </div>
  );
};
