import React from 'react';
//Icon
export const IconEdit = (props: {width?: string; height?: string; iconColor?: string; className?: string}) => {
  return (
    <svg
      viewBox="0 0 100 100"
      width={props.width || '26px'}
      height={props.height || '26px'}
      className={props?.className || ''}
    >
      <title>{'Icons/Documents/edit'}</title>
      <g
        id="Icons/Documents/edit"
        stroke="none"
        strokeWidth={1}
        fill={props?.iconColor ? props?.iconColor : '#1C78AD'}
        fillRule="evenodd"
      >
        <path
          d="M77.7333333,3.875 L26.9333333,54.675 C23.014296,58.572891 20.8177268,63.8767654 20.8332507,69.4041667 L20.8332507,75 C20.8332507,77.3011865 22.6988135,79.1667493 25,79.1667493 L30.5958333,79.1667493 C36.1232346,79.1822732 41.427109,76.985704 45.325,73.0666667 L96.125,22.2666667 C101.194922,17.1843076 101.194922,8.95735905 96.125,3.875 C90.9805631,-1.04250159 82.8777702,-1.04250159 77.7333333,3.875 Z M90.2333333,16.375 L39.4333333,67.175 C37.083856,69.5101365 33.9083597,70.8246513 30.5958333,70.8333333 L29.1666667,70.8333333 L29.1666667,69.4041667 C29.1753487,66.0916403 30.4898635,62.916144 32.825,60.5666667 L83.625,9.76666667 C85.4737613,8.00051871 88.384572,8.00051871 90.2333333,9.76666667 C92.0546647,11.5929598 92.0546647,14.5487068 90.2333333,16.375 Z"
          id="Shape"
        />
        <path
          d="M95.8333333,37.4125 C93.5321469,37.4125 91.6666667,39.2779802 91.6666667,41.5791667 L91.6666667,62.5 L75,62.5 C68.0964406,62.5 62.5,68.0964406 62.5,75 L62.5,91.6666667 L20.8333333,91.6666667 C13.929774,91.6666667 8.33333333,86.070226 8.33333333,79.1666667 L8.33333333,20.8333333 C8.33333333,13.929774 13.929774,8.33333333 20.8333333,8.33333333 L58.5083333,8.33333333 C60.8095198,8.33333333 62.675,6.46785312 62.675,4.16666667 C62.675,1.86548021 60.8095198,0 58.5083333,0 L20.8333333,0 C9.33311187,0.0137754847 0.0137754847,9.33311187 0,20.8333333 L0,79.1666667 C0.0137754847,90.6668881 9.33311187,99.9862245 20.8333333,100.000085 L68.0958333,100.000085 C73.6244942,100.015791 78.9297313,97.8192786 82.8291667,93.9 L93.8958333,82.825 C97.8156123,78.927269 100.013575,73.623653 100.000063,68.0958333 L100.000063,41.5791667 C100.000063,39.2779802 98.1345198,37.4125 95.8333333,37.4125 Z M76.9375,88.0083333 C75.2625644,89.67919 73.1444597,90.8358342 70.8333333,91.3416667 L70.8333333,75 C70.8333333,72.6988135 72.6988135,70.8333333 75,70.8333333 L91.3541667,70.8333333 C90.8387213,73.1396415 89.6834051,75.2538702 88.0208333,76.9333333 L76.9375,88.0083333 Z"
          id="Shape"
        />
      </g>
    </svg>
  );
};
