import React from 'react';
import {Grid, makeStyles} from '@material-ui/core';
import {css} from 'glamor';
import {IHeadCell, STableInvoices} from '../../tables/STableInvoices';
import moment from 'moment';
import {PriceFormat} from '../../../utils/formatter';
import {Link, useParams} from 'react-router-dom';
import {DatTypes, PaymentStatusType} from '../../../model/constants/Constants';
import {AUTH_SELLING_COLLECT_ROUTE} from '../../../routes/AuthenticatedRoutes';
import {ISupplierWhoRaisedInvoice} from '../../../model/supplier/SupplierWhoRaisedInvoice';
import {IInvoice, IRefBusTran} from '../../../model/invoice/Invoice';

const headerTopStyle = css({
  borderRadius: '1rem 0 1rem 1rem',
  '&:after': {
    content: ' ',
    position: 'absolute',
    top: '0',
    right: '0',
    borderWidth: '32px 0px 0px 32px',
    borderStyle: 'solid',
    borderColor: `#f4f8fb #fff #ffffff00 #d8d8d800`,
    backgroundColor: '#E1E1E1',
    display: 'block',
    width: 0,
    WebkitBorderBottomLeftRadius: '10px',
    overflowY: 'auto',
    borderRadius: '0 0 0 10px',
  },
});

const customScrollBarStyle = css({
  '::-webkit-scrollbar': {
    width: '22px',
  },
  '::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
    marginTop: '0.75rem',
    marginBottom: '1.25rem',
  },
  '::-webkit-scrollbar-thumb': {
    backgroundColor: '#CCE6F1',
    borderRadius: '20px',
    border: '6px solid transparent',
    backgroundClip: 'content-box',
  },
  '::-webkit-scrollbar-thumb:hover': {
    backgroundColor: '#1C78AD',
  },
});

const useBatchPaymentStyles = makeStyles({
  invAllSummary: {
    backgroundColor: '#FAFAFA',
    borderColor: '#E1E1E1',
    borderRadius: '4px',
    padding: '12px 10px 10px 10px',
    lineHeight: '1.25rem',
  },
  headerContainer: {
    padding: '8px 8px 10px 0px',
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0 0 16px ',
  },
  divider: {
    backgroundColor: '#EAEAEA',
    height: '2px',
  },
});

const paymentBalance = css({
  backgroundColor: '#E1E1E1',
});

export const PaymentView = (props: {tx: IInvoice; supplier?: ISupplierWhoRaisedInvoice}) => {
  const {supplierId, customerId} = useParams<{supplierId?: string; customerId?: string}>();
  const {tx} = props;

  const linkPathName = (transactionId: number) => {
    if (supplierId) {
      return `/supplier/${supplierId}/bills/${transactionId}`;
    } else {
      return `${AUTH_SELLING_COLLECT_ROUTE}/${customerId}/${transactionId}`;
    }
  };

  let statusColor: string = '';
  let statusBorderColor: string = '';
  switch (tx?.Status) {
    case PaymentStatusType.Cleared:
    case PaymentStatusType.Paid:
      statusColor = '#3C9F78';
      statusBorderColor = '#3C9F7830';
      break;
    case PaymentStatusType.InProgress:
      statusColor = '#C68A19';
      statusBorderColor = '#C68A1930';
      break;
    case PaymentStatusType.Failed:
    case PaymentStatusType.Cancelled:
      statusColor = '#B9624B';
      statusBorderColor = '#B9624B30';
      break;
    default:
      break;
  }

  const paidBorderStyle = css({
    backgroundColor: '#fff',
    borderColor: statusBorderColor,
    color: statusColor,
    justifyContent: 'center',
    minWidth: '10rem',
    minHeight: '3.75rem',
    borderRadius: '14px',
    alignItems: 'center',
  });

  const transactionStatus = (status: string) => {
    switch (status) {
      case 'Paid':
        return 'PAID';
      case 'InProgress':
        return 'IN PROGRESS';
      default:
        return status;
    }
  };

  const invoiceHead: IHeadCell<IRefBusTran>[] = [
    {
      id: 'AppliedDate',
      numeric: false,
      disablePadding: true,
      sort: true,
      label: 'Date',
      align: 'left',
      render: (row: IRefBusTran) => {
        return <span>{moment(row?.AppliedDate).format('DD/MM/YYYY')}</span>;
      },
    },
    {
      id: 'RefNumber',
      numeric: true,
      disablePadding: true,
      sort: true,
      label: 'Ref No.',
      align: 'left',
      linked: true,
      render: (row: IRefBusTran) => {
        return (
          <>
            <Link
              className="text-primary underline"
              to={{
                pathname: linkPathName(row?.ID),
                search: `?txGuid=${row?.GUID}&txDatType=${DatTypes.Invoice}`,
              }}
            >
              {row.RefNumber}
            </Link>
            {row?.TransSubType === 'MerchantSurcharge' ? ' (Surcharge)' : null}
          </>
        );
      },
    },
    {
      id: 'AppliedAmount',
      numeric: true,
      disablePadding: false,
      label: 'Applied amount',
      align: 'right',
      render: (row: IRefBusTran) => {
        return <span>{PriceFormat(Number(row?.AppliedAmount))}</span>;
      },
    },
    {
      id: 'Balance',
      numeric: true,
      disablePadding: false,
      label: 'Balance',
      align: 'right',
      render: (row: IRefBusTran) => {
        return <span>{PriceFormat(Number(row?.Balance))}</span>;
      },
      style: {
        width: '8rem',
      },
    },
  ];

  const classes = useBatchPaymentStyles();

  return (
    <header className={`relative h-full bg-white font-poppins ${headerTopStyle}`}>
      <div className={`h-full overflow-y-auto  pb-20 pt-8 sm:px-3  ${customScrollBarStyle}`}>
        <Grid container sm={12} className={classes.headerContainer}>
          <Grid container item sm={6} direction="column">
            <Grid className="text-xxl mb-6 ml-1 flex justify-start font-bold">
              <span>Payment: {tx?.RefNumber}</span>
            </Grid>
            <Grid className="ml-1 flex justify-start text-xs font-normal">
              <span>Payment #: {tx?.RefNumber}</span>
            </Grid>
            <Grid className="ml-1 flex justify-start text-xs font-normal">
              <span>Payment date: {moment(tx?.DateTime).format('Do MMM YYYY')}</span>
            </Grid>
            <Grid className="ml-1 flex justify-start text-xs font-normal">
              <span>Payment Status: {tx?.Status} </span>
            </Grid>
          </Grid>
          <Grid container item sm={6} direction="column">
            <Grid className="flex justify-end text-base font-bold">
              <div className={`flex border-4 ${paidBorderStyle} mb-3 `}>
                <h1 className="px-2 text-lg font-medium uppercase">{transactionStatus(tx?.Status)}</h1>
              </div>
            </Grid>
            <Grid className="mr-1 flex justify-end text-xs font-normal">
              <span>Payment method: {tx?.Method} </span>
            </Grid>
            <Grid className="mr-1 flex justify-end text-xs font-normal">
              <span>
                {tx?.SurchargeAmount ? 'Surcharge ' : 'Processing fee '}
                {!!tx?.PayerFeeDescription
                  ? `(${tx?.PayerFeeDescription})`
                  : !!tx?.SurchageDescription
                    ? `(${tx?.SurchageDescription})`
                    : null}
                : {PriceFormat(tx?.Fee || tx?.SurchargeAmount || 0)}
              </span>
            </Grid>
          </Grid>
        </Grid>
        <div className={classes.divider} />
        <div className="mt-5">
          <STableInvoices<IRefBusTran> headCells={invoiceHead} rows={tx?.RefBusTrans} />
          {!tx?.RefBusTrans?.length && (
            <div className={`-mt-4 mb-4 flex justify-center py-2 text-xs font-bold`}>
              THERE ARE NO INVOICES ATTACHED TO THIS PAYMENT
            </div>
          )}
        </div>
        <div className="-mt-4 flex flex-col items-end">
          <div className="flex py-2 text-xs font-medium ">
            <span>Total applied: </span>
            <span className="w-32 px-1 text-end">{PriceFormat(tx?.AppliedAmount)}</span>
          </div>
          <div className={`flex w-64 justify-end py-2 text-xs font-bold ${paymentBalance}`}>
            <span>Payment balance: </span>
            <span className="w-32 px-1 text-end">{PriceFormat(tx?.Balance)}</span>
          </div>
        </div>
      </div>
    </header>
  );
};
