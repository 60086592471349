import {INotification} from '../context/NotificationContext';
import useHttp from '../hooks/useHttp';

export const useNotificationsAPI = () => {
  const {GET, POST, isLoading} = useHttp();

  interface INotificationResponse {
    notifications: INotification[];
    error: any;
  }

  interface IAcknowledgeNotificationBody {
    notificationIDs: number[];
    contentFormat: 'Html';
  }

  const get = (contentFormat?: 'html' | 'plain'): Promise<INotification[]> => {
    return GET(`Spenda/Alerts/Notifications${contentFormat ? '?contentFormat=' + contentFormat : ''}`).then(
      (data: INotificationResponse) => data.notifications,
    );
  };

  const acknowledge = (notificationIds: number[]): Promise<INotification[]> => {
    const payload: IAcknowledgeNotificationBody = {
      notificationIDs: notificationIds,
      contentFormat: 'Html',
    };

    return POST(`Spenda/Alerts/Notifications`, payload).then((data: INotificationResponse) => data.notifications);
  };

  return {
    get,
    acknowledge,
    isLoading,
  };
};
