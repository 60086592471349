import React, {useEffect, useState} from 'react';
import moment from 'moment';
import Skeleton from 'react-loading-skeleton';
import {Typography} from 'spenda-ui-react';
import {SameDataArrow} from '../../../assets/svg/SameDataArrow';
import {SpendaNewLogo} from '../../../assets/svg/SpendaNewLogo';
import {useTransactionViewHook} from '../../../hooks/useARHook';
import {LogoTypeEnum} from '../../../model/Tenant';
import {DatTypes, PaymentStatusType} from '../../../model/constants/Constants';
import {
  ICreditNotesSummary,
  IInvoicePaymentAllocation,
  IInvoicesSummary,
  IPaymentAllocationSummary,
} from '../../../model/invoice/InvoicePaymentAllocation';
import {ISupplierWhoRaisedInvoice} from '../../../model/supplier/SupplierWhoRaisedInvoice';
import {PriceFormat} from '../../../utils/formatter';
import {ARTable} from '../ARTable';
import {PaymentStatusBadge} from '../PaymentStatusBadge';

interface IInvoiceAllocationTemplateProps {
  tx?: IInvoicePaymentAllocation;
  isLoading: boolean;
  supplier?: ISupplierWhoRaisedInvoice;
}

export const InvoiceAllocationTemplate = (props: IInvoiceAllocationTemplateProps) => {
  const {tx, isLoading, supplier} = props;

  const [isVendorPresent, setIsVendorPresent] = useState(false);
  const {handleViewTx} = useTransactionViewHook();
  const LogoURI = supplier?.Logos.find(x => x.LogoTypeID.toString() === LogoTypeEnum.Logo)?.URI;

  let creditNoteID: undefined | number = undefined;
  let paymentID: undefined | number = undefined;
  let currentIndex: number = 0;

  useEffect(() => {
    let _isVendorPresent: any = tx?.invoicesSummary?.find(s => s.customer_Vendor);
    if (!_isVendorPresent) {
      _isVendorPresent = tx?.creditNotesSummary?.find(s => s.customer_Vendor);
    }
    setIsVendorPresent(_isVendorPresent);
  }, [tx]);

  const conparePaymentDuplicate = (id: number, index: number) => {
    if (paymentID === id && currentIndex !== index) {
      return true;
    }
    paymentID = id;
    currentIndex = index;
    return false;
  };

  const compareCrediteNoteDuplicate = (id: number, index: number) => {
    if (creditNoteID === id && currentIndex !== index) {
      return true;
    }
    creditNoteID = id;
    currentIndex = index;
    return false;
  };

  const invoicesSummaryColumns = [
    {
      title: 'Date',
      key: 'invoiceDate',
      width: isVendorPresent ? '12%' : '15%',
      columClassName:
        'font-poppins leading-loose !text-[10px] font-medium !text-black-800 text-left py-0 pl-2 pr-1 bg-[#ddd] border-t-default border-t-spenda-dashbg',
      rowClassName: 'p-0 pl-2 pr-1',
      align: 'left',
      isSortable: false,
      rowRenderer: (rowData: IInvoicesSummary) => (
        <Typography variant="xsmall" data-autoid={`lblDate`} className="inline text-left leading-loose text-black-800">
          {moment(rowData?.invoiceDate).format('DD/MM/YYYY')}
        </Typography>
      ),
    },
    {
      title: 'Invoice Ref No.',
      key: 'refNumber',
      width: isVendorPresent ? '12%' : '17%',
      columClassName:
        'font-poppins leading-loose !text-[10px] font-medium !text-black-800 text-left py-0 pl-0 pr-1 bg-[#ddd] border-t-default border-t-spenda-dashbg',
      rowClassName: 'p-0 pr-1',
      align: 'left',
      isSortable: false,
      rowRenderer: (rowData: IInvoicesSummary) => (
        <Typography
          variant="xsmall"
          onClick={() =>
            handleViewTx({txDatType: DatTypes.Invoice, txGuid: rowData.invoiceGUID, txId: rowData.invoiceID}, true)
          }
          data-autoid={`lblInvoiceRefNo`}
          className="cursor-pointer text-left leading-loose text-primary underline"
        >
          {rowData?.refNumber}
        </Typography>
      ),
    },
    {
      title: 'Totals',
      key: 'totalInc',
      width: isVendorPresent ? '13%' : '17%',
      columClassName:
        'font-poppins !text-[10px] leading-loose font-medium !text-black-800 text-right py-0 pr-1 bg-[#ddd] border-t-default border-t-spenda-dashbg',
      rowClassName: 'p-0 pr-1',
      align: 'right',
      isSortable: false,
      rowRenderer: (rowData: IInvoicesSummary) => (
        <Typography
          variant="xsmall"
          data-autoid={`lblTotalInc`}
          className="inline text-right leading-loose text-black-800"
        >
          {PriceFormat(rowData?.totalInc)}
        </Typography>
      ),
    },
    {
      title: 'Payment',
      key: 'applied_Payments',
      width: isVendorPresent ? '13%' : '17%',
      columClassName:
        'font-poppins !text-[10px] leading-loose font-medium !text-black-800 text-right py-0 pr-2 bg-[#ddd] border-t-default border-t-spenda-dashbg',
      rowClassName: 'p-0 pr-2',
      align: 'right',
      isSortable: false,
      rowRenderer: (rowData: IInvoicesSummary) => (
        <Typography
          variant="xsmall"
          data-autoid={`lblPayment`}
          className="inline text-right leading-loose text-black-800"
        >
          {PriceFormat(rowData?.applied_Payments)}
        </Typography>
      ),
    },
    {
      title: 'Credits',
      key: 'applied_Credits',
      width: isVendorPresent ? '13%' : '17%',
      columClassName:
        'font-poppins !text-[10px] leading-loose font-medium !text-black-800 text-right py-0 pr-2 bg-[#ddd] border-t-default border-t-spenda-dashbg',
      rowClassName: 'p-0 pr-2',
      align: 'right',
      isSortable: false,
      rowRenderer: (rowData: IInvoicesSummary) => (
        <Typography
          variant="xsmall"
          data-autoid={`lblCredits`}
          className="inline text-right leading-loose text-black-800"
        >
          {PriceFormat(rowData?.applied_Credits)}
        </Typography>
      ),
    },
    {
      title: 'Balance',
      key: 'balance',
      width: isVendorPresent ? '13%' : '17%',
      columClassName:
        'font-poppins !text-[10px] leading-loose font-medium !text-black-800 text-right py-0 pr-2 bg-[#ddd] border-t-default border-t-spenda-dashbg',
      rowClassName: 'p-0 pr-2',
      align: 'right',
      isSortable: false,
      rowRenderer: (rowData: IInvoicesSummary) => (
        <Typography
          variant="xsmall"
          data-autoid={`lblBalance`}
          className="inline text-right leading-loose text-black-800"
        >
          {PriceFormat(rowData?.balance)}
        </Typography>
      ),
    },
  ];

  const paymentAllocationsColumns = [
    {
      title: 'Date',
      key: 'creditNoteDate',
      width: '15%',
      columClassName:
        'font-poppins leading-loose !text-[10px] font-medium !text-black-800 text-left py-0 pl-2 pr-1 bg-[#ddd] border-t-default border-t-spenda-dashbg',
      rowClassName: 'p-0 pl-2 pr-1',
      align: 'left',
      isSortable: false,
      rowRenderer: (rowData: IPaymentAllocationSummary, index: number) => {
        if (conparePaymentDuplicate(rowData?.paymentID, index)) return <></>;

        return (
          <Typography
            variant="xsmall"
            data-autoid={`lblDate`}
            className="inline text-left leading-loose text-black-800"
          >
            {moment(tx?.invoicePaymentAllocation?.scheduledDateTime_utc || rowData?.paymentDate).format('DD/MM/YYYY')}
          </Typography>
        );
      },
    },
    {
      title: 'Ref No.',
      key: 'refNumber',
      width: '17%',
      columClassName:
        'font-poppins leading-loose !text-[10px] font-medium !text-black-800 text-left py-0 pl-0 pr-1 bg-[#ddd] border-t-default border-t-spenda-dashbg',
      rowClassName: 'p-0 pr-1',
      align: 'left',
      isSortable: false,
      rowRenderer: (rowData: IPaymentAllocationSummary, index: number) => {
        if (conparePaymentDuplicate(rowData?.paymentID, index)) return <SameDataArrow />;

        return (
          <Typography
            variant="xsmall"
            onClick={() =>
              handleViewTx({txDatType: DatTypes.Payment, txGuid: rowData.paymentGUID, txId: rowData.paymentID}, true)
            }
            data-autoid={`lblRefNo`}
            className="cursor-pointer text-left leading-loose text-primary underline"
          >
            {rowData?.refNumber}
          </Typography>
        );
      },
    },
    {
      title: 'Payment Method',
      key: 'totalInc',
      width: '17%',
      columClassName:
        'font-poppins !text-[10px] leading-loose font-medium !text-black-800 text-right py-0 pr-1 bg-[#ddd] border-t-default border-t-spenda-dashbg',
      rowClassName: 'p-0 pr-1',
      align: 'right',
      isSortable: false,
      rowRenderer: (rowData: IPaymentAllocationSummary, index: number) => {
        if (conparePaymentDuplicate(rowData?.paymentID, index)) return <></>;

        return (
          <Typography
            variant="xsmall"
            data-autoid={`lblPaymentMethod`}
            className="inline text-right leading-loose text-black-800"
          >
            {rowData?.paymentMethod}
          </Typography>
        );
      },
    },
    {
      title: 'Processing Fee',
      key: 'totalInc',
      width: '17%',
      columClassName:
        'font-poppins !text-[10px] leading-loose font-medium !text-black-800 text-right py-0 pr-1 bg-[#ddd] border-t-default border-t-spenda-dashbg',
      rowClassName: 'p-0 pr-1',
      align: 'right',
      isSortable: false,
      rowRenderer: (rowData: IPaymentAllocationSummary, index: number) => {
        if (conparePaymentDuplicate(rowData?.paymentID, index)) return <></>;
        return (
          <Typography
            variant="xsmall"
            data-autoid={`lblProcessingFee`}
            className="inline text-right leading-loose text-black-800"
          >
            {PriceFormat(rowData?.payerFeeAmount)}
          </Typography>
        );
      },
    },
    {
      title: 'Starting Balance',
      key: 'totalInc',
      width: '17%',
      columClassName:
        'font-poppins !text-[10px] leading-loose font-medium !text-black-800 text-right py-0 pr-1 bg-[#ddd] border-t-default border-t-spenda-dashbg',
      rowClassName: 'p-0 pr-1',
      align: 'right',
      isSortable: false,
      rowRenderer: (rowData: IPaymentAllocationSummary) => (
        <Typography
          variant="xsmall"
          data-autoid={`lblStartingBalance`}
          className="inline text-right leading-loose text-black-800"
        >
          {PriceFormat(rowData?.totalInc)}
        </Typography>
      ),
    },
    {
      title: 'Applied Amount',
      key: 'creditss',
      width: '17%',
      columClassName:
        'font-poppins !text-[10px] leading-loose font-medium !text-black-800 text-right py-0 pr-2 bg-[#ddd] border-t-default border-t-spenda-dashbg',
      rowClassName: 'p-0 pr-2',
      align: 'right',
      isSortable: false,
      rowRenderer: (rowData: IPaymentAllocationSummary) => (
        <Typography
          variant="xsmall"
          data-autoid={`lblAppliedAmount`}
          className="inline text-right leading-loose text-black-800"
        >
          {PriceFormat(rowData?.credits)}
        </Typography>
      ),
    },
    {
      title: 'Invoice Ref. No',
      key: 'invoice_RefNumber',
      width: '17%',
      columClassName:
        'font-poppins !text-[10px] leading-loose font-medium !text-black-800 text-right py-0 pr-2 bg-[#ddd] border-t-default border-t-spenda-dashbg',
      rowClassName: 'p-0 pr-2',
      align: 'right',
      isSortable: false,
      rowRenderer: (rowData: IPaymentAllocationSummary) => (
        <Typography
          variant="xsmall"
          onClick={() =>
            handleViewTx({txDatType: DatTypes.Invoice, txGuid: rowData.invoiceGUID, txId: rowData.invoiceID}, true)
          }
          className="cursor-pointer text-right leading-loose text-primary underline"
          data-autoid={`lblInvoiceRefNo`}
        >
          {rowData?.invoice_RefNumber}
        </Typography>
      ),
    },
    {
      title: 'Balance',
      key: 'balance',
      width: '17%',
      columClassName:
        'font-poppins !text-[10px] leading-loose font-medium !text-black-800 text-right py-0 pr-2 bg-[#ddd] border-t-default border-t-spenda-dashbg',
      rowClassName: 'p-0 pr-2',
      align: 'right',
      isSortable: false,
      rowRenderer: (rowData: IPaymentAllocationSummary) => (
        <Typography
          variant="xsmall"
          data-autoid={`lblBalance`}
          className="inline text-right leading-loose text-black-800"
        >
          {PriceFormat(rowData?.balance)}
        </Typography>
      ),
    },
  ];

  const creditNotesSummaryColumns = [
    {
      title: 'Date',
      key: 'creditNoteDate',
      width: isVendorPresent ? '12%' : '15%',
      columClassName: `font-poppins leading-loose !text-[10px] font-medium !text-black-800 text-left py-0 pl-2 pr-1 bg-[#ddd] border-t-default border-t-spenda-dashbg`,
      rowClassName: 'p-0 pl-2 pr-1',
      align: 'left',
      isSortable: true,
      rowRenderer: (rowData: ICreditNotesSummary, index: number) => {
        if (compareCrediteNoteDuplicate(rowData?.creditNoteID, index)) return <></>;

        return (
          <Typography
            variant="xsmall"
            data-autoid={`lblDate`}
            className="inline text-left leading-loose text-black-800"
          >
            {moment(rowData?.creditNoteDate).format('DD/MM/YYYY')}
          </Typography>
        );
      },
    },
    {
      title: 'Ref No.',
      key: 'refNumber',
      width: isVendorPresent ? '12%' : '17%',
      columClassName: `font-poppins leading-loose !text-[10px] font-medium !text-black-800 text-left py-0 pl-0 pr-1 bg-[#ddd] border-t-default border-t-spenda-dashbg`,
      rowClassName: 'p-0 pr-1',
      align: 'left',
      isSortable: true,
      rowRenderer: (rowData: ICreditNotesSummary, index: number) => {
        if (compareCrediteNoteDuplicate(rowData?.creditNoteID, index)) return <SameDataArrow />;

        return (
          <Typography
            variant="xsmall"
            onClick={() =>
              handleViewTx(
                {txDatType: DatTypes.CreditNote, txGuid: rowData.creditNoteGUID, txId: rowData.creditNoteID},
                true,
              )
            }
            data-autoid={`lblRefNo`}
            className="cursor-pointer text-left leading-loose text-primary underline"
          >
            {rowData?.refNumber}
          </Typography>
        );
      },
    },
    {
      title: 'Starting Balance',
      key: 'totalInc',
      width: isVendorPresent ? '13%' : '17%',
      columClassName:
        'font-poppins !text-[10px] leading-loose font-medium !text-black-800 text-right py-0 pr-1 bg-[#ddd] border-t-default border-t-spenda-dashbg',
      rowClassName: 'p-0 pr-1',
      align: 'right',
      isSortable: false,
      rowRenderer: (rowData: ICreditNotesSummary) => (
        <Typography
          variant="xsmall"
          data-autoid={`lblStartingBalance`}
          className="inline text-right leading-loose text-black-800"
        >
          {PriceFormat(rowData?.totalInc)}
        </Typography>
      ),
    },
    {
      title: 'Payment',
      key: 'payment',
      width: isVendorPresent ? '13%' : '17%',
      columClassName:
        'font-poppins !text-[10px] leading-loose font-medium !text-black-800 text-right py-0 pr-2 bg-[#ddd] border-t-default border-t-spenda-dashbg',
      rowClassName: 'p-0 pr-2',
      align: 'right',
      isSortable: false,
      rowRenderer: (rowData: ICreditNotesSummary) => (
        <Typography
          variant="xsmall"
          data-autoid={`lblPayment`}
          className="inline text-right leading-loose text-black-800"
        >
          {PriceFormat(rowData?.credits)}
        </Typography>
      ),
    },
    {
      title: 'Invoice Ref. No',
      key: 'invoice_RefNumber',
      width: isVendorPresent ? '13%' : '17%',
      columClassName:
        'font-poppins !text-[10px] leading-loose font-medium !text-black-800 text-right py-0 pr-2 bg-[#ddd] border-t-default border-t-spenda-dashbg',
      rowClassName: 'p-0 pr-2',
      align: 'right',
      isSortable: false,
      rowRenderer: (rowData: ICreditNotesSummary) => (
        <Typography
          variant="xsmall"
          data-autoid={`lblInvoiceRefNo`}
          onClick={() =>
            handleViewTx(
              {txDatType: DatTypes.CreditNote, txGuid: rowData.creditNoteGUID, txId: rowData.creditNoteID},
              true,
            )
          }
          className="cursor-pointer text-right leading-loose text-primary underline"
        >
          {rowData?.invoice_RefNumber}
        </Typography>
      ),
    },
    {
      title: 'Balance',
      key: 'balance',
      width: isVendorPresent ? '13%' : '17%',
      columClassName:
        'font-poppins !text-[10px] leading-loose font-medium !text-black-800 text-right py-0 pr-2 bg-[#ddd] border-t-default border-t-spenda-dashbg',
      rowClassName: 'p-0 pr-2',
      align: 'right',
      isSortable: false,
      rowRenderer: (rowData: ICreditNotesSummary) => (
        <Typography
          variant="xsmall"
          data-autoid={`lblBalance`}
          className="inline text-right leading-loose text-black-800"
        >
          {PriceFormat(rowData?.balance)}
        </Typography>
      ),
    },
  ];

  if (isVendorPresent) {
    invoicesSummaryColumns?.splice(
      2,
      0,
      {
        title: 'Vendor',
        key: 'customer_Vendor',
        width: '12%',
        columClassName:
          'font-poppins leading-loose !text-[10px] font-medium !text-black-800 text-left py-0 pr-1 bg-[#ddd] border-t-default border-t-spenda-dashbg',
        rowClassName: 'p-0 pr-1',
        align: 'left',
        isSortable: false,
        rowRenderer: (rowData: IInvoicesSummary) => (
          <Typography
            variant="xsmall"
            data-autoid={`lblVendor`}
            className="inline text-left leading-loose text-black-800"
          >
            {rowData?.customer_Vendor}
          </Typography>
        ),
      },
      {
        title: 'Vendor Ref No.',
        key: 'vendor_RefNumber',
        width: '12%',
        columClassName:
          'font-poppins !text-[10px] leading-loose font-medium !text-black-800 text-right py-0 pr-1 bg-[#ddd] border-t-default border-t-spenda-dashbg',
        rowClassName: 'p-0 pr-1',
        align: 'left',
        isSortable: false,
        rowRenderer: (rowData: IInvoicesSummary) => (
          <Typography
            variant="xsmall"
            data-autoid={`lblVendorRefNo`}
            className="inline text-left leading-loose text-black-800"
          >
            {rowData?.vendor_RefNumber}
          </Typography>
        ),
      },
    );

    creditNotesSummaryColumns?.splice(
      2,
      0,
      {
        title: 'Vendor',
        key: 'customer_Vendor',
        width: '12%',
        columClassName:
          'font-poppins leading-loose !text-[10px] font-medium !text-black-800 text-left py-0 pr-1 bg-[#ddd] border-t-default border-t-spenda-dashbg',
        rowClassName: 'p-0 pr-1',
        align: 'left',
        isSortable: true,
        rowRenderer: (rowData: ICreditNotesSummary, index: number) => {
          if (compareCrediteNoteDuplicate(rowData?.creditNoteID, index)) return <></>;
          return (
            <Typography
              variant="xsmall"
              data-autoid={`lblVendor`}
              className="inline text-left leading-loose text-black-800"
            >
              {rowData?.customer_Vendor}
            </Typography>
          );
        },
      },
      {
        title: 'Vendor Ref No.',
        key: 'vendor_RefNumber',
        width: '12%',
        columClassName:
          'font-poppins !text-[10px] leading-loose font-medium !text-black-800 text-right py-0 pr-1 bg-[#ddd] border-t-default border-t-spenda-dashbg',
        rowClassName: 'p-0 pr-1',
        align: 'left',
        isSortable: false,
        rowRenderer: (rowData: ICreditNotesSummary, index: number) => {
          if (compareCrediteNoteDuplicate(rowData?.creditNoteID, index)) return <></>;
          return (
            <Typography
              variant="xsmall"
              data-autoid={`lblVendorRefNo`}
              className="inline text-left leading-loose text-black-800"
            >
              {rowData?.vendor_RefNumber}
            </Typography>
          );
        },
      },
    );
  }
  const getStatus = () => {
    const isCancellationPending = tx?.invoicePaymentAllocation?.isCancellationPending;

    if (isCancellationPending) return PaymentStatusType.CancellationInProgress;
    else return tx?.invoicePaymentAllocation?.status;
  };

  const getSkeltonTable = (len: number, dataAutoId: string) => {
    return (
      <table className="mb-[20px]">
        <thead data-autoid={dataAutoId}>
          <tr className="border-t-spenda-dashbg bg-[#ddd]">
            {Array(len)
              .fill(1)
              .map((_, indx) => (
                <th
                  key={`${dataAutoId}Th${indx}`}
                  className="text-left font-poppins text-base font-normal text-black-800"
                >
                  <Skeleton width={'90%'} />
                </th>
              ))}
          </tr>
        </thead>
        <tbody className="border-b-spenda-dashbg">
          {[0, 1]?.map(tl => (
            <tr key={tl}>
              {Array(len)
                .fill(1)
                .map((_, indx) => (
                  <td
                    key={`${dataAutoId}Td${indx}`}
                    className="text-left font-poppins text-base font-normal text-black-800"
                  >
                    <Skeleton width={'90%'} />
                  </td>
                ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <>
      <div className="flex h-auto w-full flex-col overflow-y-auto overflow-x-hidden bg-white px-[30px] pb-[140px] pt-[40px]">
        <div className="flex h-auto w-full flex-row items-start justify-between border-b-spenda-dashbg">
          {LogoURI ? (
            <img data-autoid={'imgLogoURIInvoiceAllocationTemplate'} src={LogoURI} className="h-[100px] w-[100px]" />
          ) : (
            <SpendaNewLogo width="30%" />
          )}
          <div className="flex flex-col justify-center">
            <Typography variant="paragraph" className="mb-2 text-right font-semibold text-black-800">
              Invoice Allocation Report
            </Typography>
            <PaymentStatusBadge isLoading={isLoading} status={getStatus()} />
            <Typography variant="xsmall" data-autoid={'lblAllocation'} className="mb-1 text-right text-black-800">
              Allocation #: {tx && !isLoading ? tx?.invoicePaymentAllocation?.refNumber : <Skeleton width={`4rem`} />}
            </Typography>
            <Typography variant="xsmall" data-autoid={'lblDate'} className="mb-1 text-right text-black-800">
              Date:{' '}
              {tx && !isLoading ? (
                moment(
                  tx?.invoicePaymentAllocation?.scheduledDateTime_utc || tx?.invoicePaymentAllocation?.paymentDate,
                ).format('DD/MM/YYYY')
              ) : (
                <Skeleton width={`4rem`} />
              )}
            </Typography>
            <Typography variant="xsmall" data-autoid={'lblCustomerName'} className="mb-1 text-right text-black-800">
              Customer:{' '}
              {tx && !isLoading ? tx?.invoicePaymentAllocation?.accountCustomerName : <Skeleton width={`5rem`} />}
            </Typography>
            <Typography variant="xsmall" data-autoid={'lblSupplierName'} className="mb-1 text-right text-black-800">
              Supplier: {tx && !isLoading ? tx?.invoicePaymentAllocation?.supplierName : <Skeleton width={`4rem`} />}
            </Typography>
          </div>
        </div>
        <p className="mb-4 mt-5 font-poppins text-base font-medium text-black-800">Invoice Allocation Summary</p>
        <div className="flex h-auto w-full flex-row justify-between gap-10 rounded-[2px] border-[1px] border-spenda-dashbg bg-[#fafafa] p-5">
          <div className="flex h-auto w-[50%] flex-col">
            <div className="flex flex-row items-center justify-between">
              <Typography variant="xsmall" className="inline leading-loose text-black-800">
                No. of Invoices:
              </Typography>
              <Typography
                variant="xsmall"
                data-autoid={'lblInvoiceCount'}
                className="inline font-semibold leading-loose text-black-800"
              >
                {tx && !isLoading ? tx?.invoicePaymentAllocation?.invoice_Count : <Skeleton width={`1rem`} />}
              </Typography>
            </div>
            <div className="flex flex-row items-center justify-between">
              <Typography variant="xsmall" className="inline leading-loose text-black-800">
                No. of Credit Notes:
              </Typography>
              <Typography
                variant="xsmall"
                data-autoid={'lblCreditNoteCount'}
                className="inline font-semibold leading-loose text-black-800"
              >
                {tx && !isLoading ? tx?.invoicePaymentAllocation?.creditNote_Count : <Skeleton width={`1rem`} />}
              </Typography>
            </div>
            <div className="flex flex-row items-center justify-between">
              <Typography variant="xsmall" className="inline leading-loose text-black-800">
                No. of Claims Requested:
              </Typography>
              <Typography
                variant="xsmall"
                data-autoid={'lblClaimCount'}
                className="inline font-semibold leading-loose text-black-800"
              >
                {tx && !isLoading ? (
                  `(${tx?.invoicePaymentAllocation?.claim_Count}) ${PriceFormat(
                    tx?.invoicePaymentAllocation?.requestedClaims,
                  )} `
                ) : (
                  <Skeleton width={`1rem`} />
                )}
              </Typography>
            </div>
            <div className="flex flex-row items-center justify-between">
              <Typography variant="xsmall" className="inline leading-loose text-black-800">
                No. of Payments Applied:
              </Typography>
              <Typography
                variant="xsmall"
                data-autoid={'lblPaymentCount'}
                className="inline font-semibold leading-loose text-black-800"
              >
                {tx && !isLoading ? tx?.invoicePaymentAllocation?.payment_Count : <Skeleton width={`1rem`} />}
              </Typography>
            </div>
          </div>
          <div className="flex h-auto w-[50%] flex-col justify-between">
            <div className="flex flex-row items-center justify-between">
              <Typography variant="xsmall" className="inline leading-loose text-black-800">
                Outstanding Invoice Balance:
              </Typography>
              <Typography
                variant="xsmall"
                data-autoid={'lblOutstandingInvoiceBalance'}
                className="inline font-semibold leading-loose text-black-800"
              >
                {tx && !isLoading ? (
                  PriceFormat(tx?.invoicePaymentAllocation?.outstandingInvoiceBalance)
                ) : (
                  <Skeleton width={`2rem`} />
                )}
              </Typography>
            </div>
            <div className="flex flex-row items-center justify-between">
              <Typography variant="xsmall" className="inline leading-loose text-black-800">
                Less Credits:
              </Typography>
              <Typography
                variant="xsmall"
                data-autoid={'lblInvoicePaymentAllocationCredits'}
                className="inline font-semibold leading-loose text-black-800"
              >
                {tx && !isLoading ? (
                  `(${PriceFormat(tx?.invoicePaymentAllocation?.appliedCredits)})`
                ) : (
                  <Skeleton width={`2rem`} />
                )}
              </Typography>
            </div>
            <div className="flex flex-row items-center justify-between">
              <Typography variant="xsmall" className="inline leading-loose text-black-800">
                Less Payments:
              </Typography>
              <Typography
                variant="xsmall"
                data-autoid={'lblAmount'}
                className="inline font-semibold leading-loose text-black-800"
              >
                {tx && !isLoading ? (
                  `(${PriceFormat(tx?.invoicePaymentAllocation?.appliedPayments)})`
                ) : (
                  <Skeleton width={`2rem`} />
                )}
              </Typography>
            </div>
            <div className="flex flex-row items-center justify-between">
              <Typography variant="xsmall" className="inline leading-loose text-black-800">
                Balance:
              </Typography>
              <Typography
                variant="xsmall"
                data-autoid={'lblBalance'}
                className="inline font-semibold leading-loose text-black-800"
              >
                {tx && !isLoading ? PriceFormat(tx?.invoicePaymentAllocation?.balance) : <Skeleton width={`2rem`} />}
              </Typography>
            </div>
          </div>
        </div>

        <div>
          <div className="mb-5 mt-6 flex h-auto w-full flex-col">
            {!isLoading ? (
              <>
                {tx?.invoicesSummary && tx?.invoicesSummary?.length > 0 && (
                  <div>
                    <Typography variant="paragraph" className="mb-4 font-medium text-black-800">
                      Invoice Allocations
                    </Typography>
                    <ARTable
                      dataAutoId="InvoiceAllocations"
                      tableClass="!mx-0"
                      rows={tx?.invoicesSummary}
                      columns={invoicesSummaryColumns}
                      isLoading={isLoading}
                      getRowDataAutoId={rowData => `rowInvoiceAllocation${rowData?.invoiceID}`}
                    />
                  </div>
                )}
              </>
            ) : (
              getSkeltonTable(isVendorPresent ? 8 : 6, 'tblInvoiceAllocations')
            )}
          </div>
        </div>

        <div>
          <div className="mb-[20px] flex h-auto w-full flex-col">
            {!isLoading ? (
              <>
                {tx?.paymentAllocations && tx?.paymentAllocations?.length > 0 && (
                  <div>
                    <Typography variant="paragraph" className="mb-4 font-medium text-black-800">
                      Payment Allocations
                    </Typography>
                    <ARTable
                      dataAutoId="PaymentAllocations"
                      tableClass="!mx-0"
                      rows={tx?.paymentAllocations}
                      columns={paymentAllocationsColumns}
                      isLoading={isLoading}
                      getRowDataAutoId={rowData => `rowPaymentAllocation${rowData?.paymentID}`}
                    />
                  </div>
                )}
              </>
            ) : (
              getSkeltonTable(9, 'tblPaymentAllocations')
            )}
          </div>
        </div>

        <div>
          <div className="mb-[20px] flex h-auto w-full flex-col">
            {!isLoading ? (
              <>
                {tx?.creditNotesSummary && tx?.creditNotesSummary?.length > 0 && (
                  <div>
                    <Typography variant="paragraph" className="mb-4 font-medium text-black-800">
                      Credit Note Allocations
                    </Typography>
                    <ARTable
                      dataAutoId="CreditNotesSummary"
                      tableClass="!mx-0"
                      rows={tx?.creditNotesSummary}
                      columns={creditNotesSummaryColumns}
                      isLoading={isLoading}
                      getRowDataAutoId={rowData => `rowCreditNotesSummary${rowData?.creditNoteID}`}
                    />
                  </div>
                )}
              </>
            ) : (
              getSkeltonTable(isVendorPresent ? 9 : 7, 'tblCreditNoteAllocations')
            )}
          </div>
        </div>
      </div>
    </>
  );
};
