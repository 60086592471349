import React, {useContext} from 'react';
import {Box} from '@material-ui/core';
import {SButton} from '../buttons/Button';
import CloseIcon from '@material-ui/icons/Close';
import LiveChat from '../../assets/svg/live-chat-icon.svg';
import {Button} from '@material-ui/core';
import AppContext from '../../context/app/appContext';
import {SupportChat} from '../navigation/SupportChat';
import 'react-circular-progressbar/dist/styles.css';
import {SModal} from '../modals/modalSpendaMeterialUI';
import {css} from 'glamor';

interface DaysLeftDialogProps {
  handleClose: () => void;
  expired?: boolean;
}

export const PurchaseNowDialog: React.FunctionComponent<DaysLeftDialogProps> = ({
  handleClose,
  expired,
}: DaysLeftDialogProps) => {
  const {user} = useContext(AppContext);

  const backdropFilterCSS = css({
    background: 'rgb(255, 255, 255)',
    '@supports ((backdrop-filter: blur(10px))) or (-webkit-backdrop-filter: blur(10px))': {
      background: 'rgba(255, 255, 255, 0.7)',
      backdropFilter: 'blur(10px)',
      '& -webkit-backdrop-filter': 'blur(10px)',
    },
  });

  return (
    <SModal open>
      <div className={`text-xl spenda-color flex justify-center items-center h-full max-w-full ${backdropFilterCSS}`}>
        <div className="flex flex-col max-w-3xl modal-box" style={{padding: '50px'}}>
          <span className="closeIcon" onClick={handleClose}>
            <CloseIcon />
          </span>
          <div className="flex flex-row h-full">
            <Box className="bg-white-inner modal-right-content flex flex-col justify-between" alignItems="center">
              {expired ? (
                <h2 className="text-4xl subscription-heading text-center w-10/12 mx-auto">
                  “Eek! Your <span>subscription has expired!</span>
                  <br></br>
                  Would you like to contact Sales team so we can keep your service going?”
                </h2>
              ) : (
                <h2 className="text-4xl subscription-heading text-center w-10/12 mx-auto">
                  <span>“Trying to purchase the subscription?</span>
                  <br></br>
                  You need to contact Sales team in order to purchase the subscription.”
                </h2>
              )}
              <Box
                display="flex"
                justifyContent="space-evenly"
                alignItems="center"
                className="subscription-action-btn w-full"
              >
                <SupportChat user={user}>
                  <SButton
                    color={'blueShade'}
                    type="submit"
                    width="m"
                    lineHeight="m"
                    fontSize="m"
                    onClick={() => {}}
                    className="common-next-btn liveChat"
                  >
                    <img src={LiveChat} alt="Live Chat Icon" className="mr-3" />
                    Live Chat
                  </SButton>
                </SupportChat>
                <div className="flex items-center text-base">
                  <p>or call on</p>
                  <Button className="phoneNumber">1300 682 108</Button>
                </div>
              </Box>
            </Box>
            {/* </div> */}
          </div>
        </div>
      </div>
    </SModal>
  );
};
