export interface IDbifield {
  dbiCode: 'AE' | 'AK' | 'AU' | 'BD' | 'DS' | 'IK' | 'KS' | 'PK' | 'PR' | 'RZ';
  maxLength: number;
  minLength: number;
  rule: 'M' | 'O' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9';
  type: 'A' | 'X' | 'N' | 'D';
}
export interface IDbiValues {
  name: 'AE' | 'AK' | 'AU' | 'BD' | 'DS' | 'IK' | 'KS' | 'PK' | 'PR' | 'RZ';
  value: string;
  error: string;
  rule: 'M' | 'O' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9';
  type: 'A' | 'X' | 'N' | 'D';
  validationRule: {
    required: boolean | number;
    errorMessage?: string;
    min: number;
    max: number;
    regex: RegExp;
  };
}

export interface IGetAirPlusDBIConfigResponse {
  dbiReply: IDbiReply;
}

export interface IDbiReply {
  request: IDbiRequest;
  error: number;
  errordesc: string;
  dbifields: IDbifield[] | null;
}

export interface IDbiRequest {
  param: IDbiParam;
}

export interface IDbiParam {
  name: string;
  value: number;
}

export interface IAirPlusDbiData {
  [key: string]: string;
}

export const AirPlusdbiFieldAbbreviations = {
  AE: 'Accounting Unit',
  AK: 'Action Code',
  AU: 'Order Number',
  BD: 'Departure Date',
  DS: 'Department Code',
  IK: 'Internal Account',
  KS: 'Cost Centre',
  PK: 'Employee Number',
  PR: 'Project Number',
  RZ: 'Destination',
};
