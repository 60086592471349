import React from 'react';
import {Typography} from 'spenda-ui-react';
import {AlertDialogSlideV2} from './AlertDialogSlideV2';
import {useHistory} from 'react-router-dom';
import {AUTH_SELLING_QUOTES_LIST} from '../../routes/QuoteManagementRoutes';

export const QuoteCancelDialog = (props: {onCancel: () => void}) => {
  const history = useHistory();
  return (
    <AlertDialogSlideV2
      title={'Cancel quote'}
      headingTextSize="h2"
      data-autoid="dlgCancelQuote"
      headingClassess="justify-center"
      dialogActionsAlignment="justify-between"
      dialogClassess="!min-w-[20%] !max-w-[497px] !w-full"
      dialogBodyClassess="flex justify-center items-center"
      actions={[
        {
          label: 'Cancel',
          action: () => {
            props?.onCancel();
          },
          color: 'primary',
          variant: 'outlined',
        },
        {
          label: 'Yes, Go Back',
          action: () => {
            history.push(AUTH_SELLING_QUOTES_LIST);
          },
          variant: 'filled',
          color: 'primary',
        },
      ]}
    >
      <Typography
        variant="small"
        className="py-10 flex items-center justify-center text-black-800 font-normal text-center max-w-[230px] w-full"
      >
        Are you sure you want to go back? Any progress will be lost.
      </Typography>
    </AlertDialogSlideV2>
  );
};
