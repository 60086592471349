import React from 'react';

export const PurchaseInvoiceIcon = (props: {width?: string; height?: string}) => {
  return (
    <svg
      width={props.width || '20px'}
      height={props.height || '20px'}
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>{'Icons/Documents/search_alt'}</title>
      <g id="Icons/Documents/search_alt" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <path
          d="M58.3333333,25 C60.6345198,25 62.5,26.8654802 62.5,29.1666667 C62.5,31.4678531 60.6345198,33.3333333 58.3333333,33.3333333 L25,33.3333333 C22.6988135,33.3333333 20.8333333,31.4678531 20.8333333,29.1666667 C20.8333333,26.8654802 22.6988135,25 25,25 L58.3333333,25 Z M90.4458333,98.7791667 C88.8187506,100.405758 86.1812494,100.405758 84.5541667,98.7791667 L74.525,88.75 C71.5549077,90.6456644 68.1068122,91.6572599 64.5833333,91.6666667 C56.9996662,91.6666667 50.1627359,87.0983758 47.2605921,80.091981 C44.3584483,73.0855862 45.9626188,65.0208769 51.3250812,59.6584145 C56.6875436,54.2959521 64.7522529,52.6917817 71.7586477,55.5939254 C78.7650425,58.4960692 83.3333333,65.3329996 83.3333333,72.9166667 C83.3239266,76.4401455 82.3123311,79.8882411 80.4166667,82.8583333 L90.4458333,92.8875 C92.0724247,94.5145827 92.0724247,97.152084 90.4458333,98.7791667 Z M64.5833333,83.3333333 C70.3362995,83.3333333 75,78.6696328 75,72.9166667 C75,67.1637005 70.3362995,62.5 64.5833333,62.5 C58.8303672,62.5 54.1666667,67.1637005 54.1666667,72.9166667 C54.1666667,78.6696328 58.8303672,83.3333333 64.5833333,83.3333333 Z M45.8333333,91.6666667 L20.8333333,91.6666667 C13.929774,91.6666667 8.33333333,86.070226 8.33333333,79.1666667 L8.33333333,20.8333333 C8.33333333,13.929774 13.929774,8.33333333 20.8333333,8.33333333 L70.8333333,8.33333333 C73.1345198,8.33333333 75,10.1988135 75,12.5 L75,45.8333333 C75,48.1345198 76.8654802,50 79.1666667,50 C81.4678531,50 83.3333333,48.1345198 83.3333333,45.8333333 L83.3333333,12.5 C83.3333333,5.59644063 77.7368927,0 70.8333333,0 L20.8333333,0 C9.33311187,0.0137754847 0.0137754847,9.33311187 0,20.8333333 L0,79.1666667 C0.0137754847,90.6668881 9.33311187,99.9862245 20.8333333,100 L45.8333333,100 C48.1345198,100 50,98.1345198 50,95.8333333 C50,93.5321469 48.1345198,91.6666667 45.8333333,91.6666667 Z"
          id="Shape"
          fill="#1C78AD"
        />
      </g>
    </svg>
  );
};
