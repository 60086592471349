import React, {useContext} from 'react';
import moment from 'moment';
import {ARSelectToggler} from '../../../components/AccountsReceivable/ARSelectToggler';
import {ARTable} from '../../../components/AccountsReceivable/ARTable';
import StartConvo from '../../../components/conversationChatBox/StartConvo';
import ARContext from '../../../context/ar-context/ARContext';
import PurchasingContext from '../../../context/purchasing/purchasingContext';
import {ARSelectableTransactions, ConversationType, DatTypes, StartConvoType} from '../../../model/constants/Constants';
import {ICustomerOutstandingStatement} from '../../../model/customer/CustomerStatements';
import {PriceFormat} from '../../../utils/formatter';
import {ARExpandOutIcon} from '../../../assets/svg/ARExpandOutIcon';
import PaymentScheduled from '../../../assets/svg/PaymentScheduled';
import ARTooltip from '../../../components/data-display/ARTootip';
import {ITxData} from '../../../components/AccountsReceivable/TransactionView';
import IconScheduledPayments from '../../../assets/svg/IconScheduledPayments';
import {useFeatureFlags} from '../../../hooks/useFeatureFlags';
import {Typography} from 'spenda-ui-react';

interface ICustomerFocusStandardViewProps {
  customerId: number;
  isLoading: boolean;
  transactionList: ICustomerOutstandingStatement[];
  onSelect: (record: ICustomerOutstandingStatement) => Promise<void>;
  onViewTransaction: (currenViewingTx: ITxData) => void;
  onPIBLClick: (txId: ICustomerOutstandingStatement) => void;
}

const CustomerFocusStandardView = (props: ICustomerFocusStandardViewProps) => {
  // Props
  const {customerId, isLoading, transactionList, onSelect, onViewTransaction, onPIBLClick} = props;

  // Context
  const {conversations} = useContext(PurchasingContext);
  const {psblStatement} = useContext(ARContext);

  // Feature Flags
  const {scheduledPaymentsV2} = useFeatureFlags().tenantOwned();

  const columns = [
    {
      title: '',
      key: 'checkbox',
      columClassName: 'text-spenda-labeltext !max-w-[50px] !min-w-[50px]',
      rowClassName: 'p-0 !max-w-[50px] !min-w-[50px]',
      align: 'left',
      isSortable: false,
      rowRenderer: (rowData: ICustomerOutstandingStatement) => {
        const isShowCheckbox = ARSelectableTransactions.includes(rowData.transactionTypeID);
        const {selectedTxList: stList, statementId} = psblStatement;
        const isSelected = stList?.findIndex(st => rowData.transID === st.id) > -1 && !!statementId;
        if (!isShowCheckbox) return <></>;

        const isNotSelectable =
          scheduledPaymentsV2 &&
          typeof rowData?.balanceIncludingScheduledIPAs === 'number' &&
          rowData?.balanceIncludingScheduledIPAs <= 0;

        return (
          <>
            {isNotSelectable ? (
              <ARTooltip
                arrow
                title={
                  <React.Fragment>
                    <Typography
                      variant="paragraph"
                      className="min-w-[200px] text-center text-[10px] font-medium text-black-800"
                    >
                      This {rowData?.transactionType} cannot be selected as it’s already scheduled for payment.
                    </Typography>
                  </React.Fragment>
                }
              >
                <span
                  className={`cursor-pointer items-center font-poppins text-base font-normal opacity-30 grayscale`}
                  data-autoid={`chkTransactionRow`}
                >
                  <ARSelectToggler isSelected={isSelected} />
                </span>
              </ARTooltip>
            ) : (
              <span
                className={`cursor-pointer items-center font-poppins text-base font-normal`}
                onClick={() => (isShowCheckbox ? onSelect(rowData) : null)}
                data-autoid={`chkTransactionRow`}
              >
                <ARSelectToggler isSelected={isSelected} />
              </span>
            )}
          </>
        );
      },
    },
    {
      title: 'Date',
      key: 'transDate',
      width: '12%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: true,
      rowRenderer: (rowData: ICustomerOutstandingStatement) => (
        <Typography data-autoid={`lblTransDate`} variant="paragraph" className="inline overflow-ellipsis font-medium">
          {rowData?.transDate ? moment(rowData?.transDate).format('DD MMM YYYY') : ''}
        </Typography>
      ),
    },
    {
      title: 'Doc Type',
      key: 'transactionType',
      width: '20%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: true,
      rowRenderer: (rowData: ICustomerOutstandingStatement) => (
        <div className="flex flex-row items-center justify-between">
          <span data-autoid={`lblTransactionType`} className="font-poppins text-base font-medium text-black-800">
            <div className="flex flex-col">
              <Typography
                variant="paragraph"
                className="inline max-w-[155px] overflow-x-hidden overflow-ellipsis whitespace-nowrap font-medium"
              >
                {rowData.transactionType}
              </Typography>
              <Typography
                variant="xsmall"
                className="inline max-w-[155px] overflow-x-hidden overflow-ellipsis whitespace-nowrap font-medium text-spenda-labeltext"
              >
                {rowData.vendorName ? rowData.vendorName : ''}
              </Typography>
            </div>
          </span>
          {rowData.transactionTypeID === DatTypes.Invoice && customerId && (
            <StartConvo
              operationUniqueId={rowData.transGUID}
              conversationWith={rowData?.accountCustomerName || ''}
              refNumber={rowData.refNumber}
              customerID={customerId || null}
              supplierID={null}
              datTypeID={rowData.transactionTypeID}
              guid={rowData.transGUID}
              unreadMessages={rowData.unreadMessages}
              conversationId={rowData.conversationID}
              contextConvos={conversations}
              startConvoType={StartConvoType.ICON}
              convoType={ConversationType.DOCUMENT}
              transactionId={rowData?.transID}
            />
          )}
        </div>
      ),
    },
    {
      title: 'Ref ID',
      key: 'refNumber',
      width: '14%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'pl-2.5',
      isSortable: true,
      rowRenderer: (rowData: ICustomerOutstandingStatement) => (
        <div className="flex w-full items-center justify-between">
          <div className="flex flex-col justify-start">
            <Typography
              variant="paragraph"
              onClick={() =>
                onViewTransaction({
                  txDatType: rowData.transactionTypeID,
                  txGuid: rowData.transGUID,
                  txId: rowData.transID,
                })
              }
              data-autoid={`lnkRefNumber`}
              className=" cursor-pointer overflow-ellipsis font-medium text-primary underline"
            >
              {rowData.refNumber}
            </Typography>
            {rowData?.vendorRefNumber && (
              <Typography
                variant="xsmall"
                className=" cursor-pointer overflow-ellipsis font-medium text-[#CCCCCC] underline"
              >
                {rowData?.vendorRefNumber}
              </Typography>
            )}
          </div>
          {rowData?.scheduledPaymentMethodLast4 && (
            <ARTooltip
              title={
                <React.Fragment>
                  <Typography variant="h3" className="text-center text-[10px] font-medium">
                    Payment Scheduled
                  </Typography>
                  <ul className="mt-3">
                    <li className="flex items-center justify-start pb-1">
                      <Typography variant="xsmall" className="w-[80px] font-medium text-[#B2B2B2]">
                        Due in
                      </Typography>
                      <Typography variant="h3" className="text-[10px] font-semibold">
                        {rowData?.scheduledInNoOfDays}{' '}
                        <Typography variant="xsmall" className="inline font-medium text-[#B2B2B2]">
                          days(s)
                        </Typography>
                      </Typography>
                    </li>
                  </ul>
                </React.Fragment>
              }
            >
              <button className="flex h-[42px] w-9 cursor-pointer items-center justify-center bg-[rgba(60,159,120,.1)]">
                <PaymentScheduled className="h-[24px] w-[26px]" />
              </button>
            </ARTooltip>
          )}
          {scheduledPaymentsV2 && rowData?.scheduledInvoicePaymentAllocationSummary?.length && (
            <ARTooltip
              arrow
              title={
                <React.Fragment>
                  <Typography variant="h3" className="text-center text-[10px] font-medium text-black-800">
                    Payment Scheduled
                  </Typography>
                  <ul className="mt-3">
                    {rowData?.scheduledInvoicePaymentAllocationSummary.map(s => (
                      <li key={s?.scheduledDateTime_utc} className="flex items-center justify-start gap-2 pb-1">
                        <Typography variant="xsmall" className="whitespace-nowrap font-medium text-[#B2B2B2]">
                          Payment Date:
                        </Typography>
                        <Typography
                          data-autoid={`lblDateScheduledPayment`}
                          variant="xsmall"
                          className="font-semibold text-black-800"
                        >
                          {moment(s?.scheduledDateTime_utc)?.format('DD/MM/YYYY')}
                        </Typography>
                        <Typography
                          data-autoid={`lblAmountScheduledPayment`}
                          variant="xsmall"
                          className="font-semibold text-black-800"
                        >
                          {PriceFormat(s?.amount)}
                        </Typography>
                      </li>
                    ))}
                  </ul>
                </React.Fragment>
              }
            >
              <button
                className="ml-2 flex h-[42px] w-9 cursor-pointer items-center justify-center bg-[#e6e4f5]"
                data-autoid={`btnSchedulePaymentsIcon`}
              >
                <IconScheduledPayments />
              </button>
            </ARTooltip>
          )}
        </div>
      ),
    },
    {
      title: 'Due Date',
      key: 'dueDate',
      width: '12%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: true,
      rowRenderer: (rowData: ICustomerOutstandingStatement) => (
        <Typography data-autoid={`lblDueDate`} variant="paragraph" className="inline overflow-ellipsis font-medium">
          {rowData?.dueDate ? moment(rowData?.dueDate).format('DD MMM YYYY') : ''}
        </Typography>
      ),
    },
    {
      title: 'Amount',
      key: 'totalInc',
      width: '13%',
      align: 'right',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: true,
      rowRenderer: (rowData: ICustomerOutstandingStatement) => (
        <Typography
          variant="paragraph"
          data-autoid={`lblTotalInc`}
          className={`overflow-ellipsis font-medium text-black-800 ${rowData?.totalInc == 0 ? 'opacity-50' : ''}`}
        >
          {[DatTypes.CreditNote, DatTypes.Payment].includes(rowData?.transactionTypeID)
            ? `(${PriceFormat(rowData?.totalInc)})`
            : PriceFormat(rowData?.totalInc)}
        </Typography>
      ),
    },
    {
      title: 'Applied',
      key: 'paymentsAllocated',
      width: '13%',
      align: 'right',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: true,
      rowRenderer: (rowData: ICustomerOutstandingStatement) => (
        <Typography
          data-autoid={`lblPaymentsAllocated`}
          variant="paragraph"
          className={`overflow-ellipsis font-medium text-black-800 ${
            rowData?.paymentsAllocated == 0 ? 'opacity-50' : ''
          }`}
        >
          {[DatTypes.Invoice].includes(rowData?.transactionTypeID) && rowData?.paymentsAllocated > 0
            ? `(${PriceFormat(rowData?.paymentsAllocated)})`
            : PriceFormat(rowData?.paymentsAllocated)}
        </Typography>
      ),
    },
    {
      title: 'Balance',
      key: 'balance',
      width: '13%',
      align: 'right',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: true,
      rowRenderer: (rowData: ICustomerOutstandingStatement) => (
        <div className="relative h-[20px]">
          {scheduledPaymentsV2 && typeof rowData?.balanceIncludingScheduledIPAs === 'number' ? (
            <ARTooltip
              arrow
              title={
                <React.Fragment>
                  <Typography variant="h3" className="text-center  text-[10px] font-medium text-black-800">
                    Balance including scheduled payments
                  </Typography>
                  <Typography
                    ata-autoid={`lblBalanceIncludingScheduledPayments`}
                    variant="xsmall"
                    className="mt-1 whitespace-nowrap text-center font-medium text-black-800"
                  >
                    {PriceFormat(rowData?.balanceIncludingScheduledIPAs)}
                  </Typography>
                </React.Fragment>
              }
            >
              <Typography
                variant="paragraph"
                data-autoid={`lblBalance`}
                className={`overflow-ellipsis font-medium text-black-800 ${rowData?.balance == 0 ? 'opacity-50' : ''}`}
              >
                {[DatTypes.CreditNote, DatTypes.Payment].includes(rowData?.transactionTypeID)
                  ? `(${PriceFormat(Math.abs(rowData?.balance))})`
                  : PriceFormat(rowData?.balance)}
              </Typography>
            </ARTooltip>
          ) : (
            <Typography
              variant="paragraph"
              data-autoid={`lblBalance`}
              className={`overflow-ellipsis font-medium text-black-800 ${rowData?.balance == 0 ? 'opacity-50' : ''}`}
            >
              {[DatTypes.CreditNote, DatTypes.Payment].includes(rowData?.transactionTypeID)
                ? `(${PriceFormat(Math.abs(rowData?.balance))})`
                : PriceFormat(rowData?.balance)}
            </Typography>
          )}
          {rowData.transactionTypeID === DatTypes.Invoice && (
            <div
              title="Send Invoice"
              className={`absolute -right-2.5 -top-[12px] z-[999] !m-0 flex min-h-[44.5px] w-[36.7px] cursor-pointer flex-row items-center justify-center self-end`}
            >
              <button
                className={`flex min-h-[44.5px] w-full cursor-pointer flex-row items-center justify-center opacity-0 hover:opacity-100`}
                style={{backgroundColor: 'rgb(49, 130, 206)'}}
                data-autoid={`btnPsblSendInvoice`}
                onClick={() => onPIBLClick(rowData)}
              >
                <ARExpandOutIcon title="Send Invoice" />
              </button>
            </div>
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      <ARTable
        isHighlightRowOnHover
        tableClass="mb-12"
        rows={transactionList}
        columns={columns}
        isLoading={isLoading}
        isPaginated
        dataAutoId="CustomerFocusStandardView"
        getRowDataAutoId={rowData => `rowCustomerFocusStandardView${rowData?.refNumber}`}
      />
    </>
  );
};

export default CustomerFocusStandardView;
