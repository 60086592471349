import React, {FC, useContext, useLayoutEffect} from 'react';
import {makeStyles} from '@material-ui/core';
import {ModuleInfoHeader} from '../../components/module-info/ModuleInfoHeader';
import AppContext from '../../context/app/appContext';
import {ITenant} from '../../model/Tenant';
import {IUser} from '../../model/user/User';
import GreenCheckIcon from '../../assets/svg/accounts-payable/GreenCheckIcon';
import {AccountsPayableModuleInfoTabs} from './AccountsPayabeModuleInfoTabs';
import blueBackgourdImg from '../../assets/png/AccountsPayable/APOverviewBg.png';
import {APOnboardingImages} from '../../assets/png/AccountsPayable/NewImages/APImages';
import {Button, IconButton, Spinner, Typography} from 'spenda-ui-react';
import {CloseCross} from '../../assets/svg/CloseCross';
import {useHistory} from 'react-router-dom';
import {AUTH_ACCOUNTSPAYABLE_ONBOARDING_V1_ROUTE} from '../../routes/AuthenticatedRoutes';
import {useOnboarding} from '../../services/useOnboarding';
import {LogoName} from '../../components/LogoName';
import CapricornRequiresAttenionBg from '../../assets/png/CapricornRequiresAttentionBg.png';
import CommercialReleaseOnboarding from '../../assets/png/CommercialReleaseOnboarding.png';
import clsx from 'clsx';
import {useFeatureFlags} from '../../hooks/useFeatureFlags';

interface IAccountsPayableModuleInfoProps {
  isPsblModuleInfo?: boolean;
  isAPModuleInfo?: boolean;
  payStatementByLinkV289938?: boolean;
}

export const APOrPsblModuleInfo = (props: IAccountsPayableModuleInfoProps) => {
  const {isPsblModuleInfo, isAPModuleInfo, payStatementByLinkV289938} = props;
  const {lmiCommercialRelease92671} = useFeatureFlags().supplierDriven();
  const useAccountsPayableStyle = makeStyles({
    overviewWrapper: {
      '& .iframe': {
        '&:before': {
          content: "''",
          position: 'absolute',
          width: 'calc(100% - 20px)',
          height: 'calc(100% - 45px)',
          background: 'hsla(var(--primary)/15%)',
          opacity: '0.5',
          top: '-20px',
          right: '0px',
          borderRadius: '8px',
          boxShadow: 'inset 0px 0px 61px 0px hsl(var(--primary-hover))',
        },
      },
      '& .overViewRight': {
        background: isPsblModuleInfo
          ? 'linear-gradient(135deg, hsla(var(--primary)/15%) 0%, rgba(255, 255, 255, 0.1) 50%, hsla(var(--primary)/15%) 100%)'
          : `url(${blueBackgourdImg}) top left no-repeat`,
        boxShadow: isPsblModuleInfo ? '0px 0px 6px 0px hsla(var(--primary)/20%)' : '',
        backgroundSize: 'cover',
      },
    },
  });
  const classes = useAccountsPayableStyle();
  const appContext = useContext(AppContext);
  const tenant = appContext.tenant as ITenant;
  const user = appContext.user as IUser;
  const {getOnboardingStep, isLoading} = useOnboarding();
  const history = useHistory();
  const [isPlayVideo, setIsPlayVideo] = React.useState(false);

  useLayoutEffect(() => {
    const onboardingStep = async () => {
      if (isPsblModuleInfo && payStatementByLinkV289938) {
        if (lmiCommercialRelease92671 && appContext?.tenantInfo?.TenantUserDetails?.IsOnboardingMeetingCompleted) {
          history.push('/supplier/psbl/onboarding');
        }
        return;
      }

      let steps = await getOnboardingStep();
      if (steps[0].startedDateTime_utc) {
        history.push({
          pathname: isPsblModuleInfo ? `/supplier/psbl/onboarding` : AUTH_ACCOUNTSPAYABLE_ONBOARDING_V1_ROUTE,
          state: steps,
        });
      }
    };
    onboardingStep();
  }, [isPsblModuleInfo, payStatementByLinkV289938, lmiCommercialRelease92671]);

  if (isLoading) {
    return (
      <div className="flex h-full flex-col items-center justify-center bg-spenda-newbg">
        <LogoName />
        <div className="flex gap-x-2">
          <Spinner color="primary" className="h-7 w-7 text-primary/40" />
          <Typography className="font-poppins font-light text-primary">Loading...</Typography>
        </div>
      </div>
    );
  }

  //state for play video
  const VIDEO_URL = isPsblModuleInfo
    ? 'https://media.spenda.co/media/spenda/onboarding/Spenda%20PSBL%20Onboarding%20Promo%20Video.mp4'
    : 'https://media.spenda.co/media/spenda/onboarding/Spenda%20AP%20Onboarding%20Promo%20Video.mp4';

  const keyFeaturesAP = [
    'Track, group and batch-pay outstanding invoices',
    'Save time and enhance accuracy',
    'Pay multiple suppliers from one dashboard',
    'Assign employees payment approval permissions',
    'Pay by credit card and earn reward/loyalty points',
    'Benefit from automatic account reconciliation',
  ];

  const keyFeaturesPSBL = [
    'Automatically set bills as “paid” by your Capricorn account',
    'Post missing invoices directly from your Capricorn statement',
    'Match and link Capricorn Suppliers with your financials',
    'Match and link Capricorn transactions with  your financials',
    'Rapid end-of-month account reconciliation',
  ];

  const onClickPlayVideo = () => {
    if (isAPModuleInfo || (isPsblModuleInfo && payStatementByLinkV289938 && lmiCommercialRelease92671)) {
      setIsPlayVideo(true);
    }
  };

  const OnboardingKeyFeatures = (props: {className?: string}) => {
    const {className} = props;

    return (
      <div className={`flex flex-col gap-4 ${className}`}>
        <h3 className="text-base font-medium text-spenda-primarytext">Key features</h3>
        <ul>
          {isPsblModuleInfo && payStatementByLinkV289938
            ? keyFeaturesPSBL.map((feture, key) => (
                <li className="flex items-center justify-start pt-3 first:pt-0" key={key}>
                  <GreenCheckIcon className="w-min-[18px] mr-1" width="18px" height={'18px'} />
                  <p className="text-sm text-spenda-primarytext">{feture}</p>
                </li>
              ))
            : keyFeaturesAP.map((feture, key) => (
                <li className="flex items-center justify-start pt-3 first:pt-0" key={key}>
                  <GreenCheckIcon className="w-min-[18px] mr-1" width="18px" height={'18px'} />
                  <p className="text-sm text-spenda-primarytext">{feture}</p>
                </li>
              ))}
        </ul>
        {isPsblModuleInfo && (
          <a href="https://spenda.co/swiftstatement/" target="blank" rel="noopener noreferrer" className="self-center">
            <Button>Learn More</Button>
          </a>
        )}
      </div>
    );
  };

  return (
    <>
      <ModuleInfoHeader tenant={tenant} user={user} isPsblOnboarding={isPsblModuleInfo && payStatementByLinkV289938} />
      <div
        className={clsx(
          classes.overviewWrapper,
          'h-full w-full overflow-auto font-poppins',
          isPsblModuleInfo ? 'bg-primary-background px-2.5 pt-2.5 2xl:pt-2.5' : 'bg-white px-5 pt-4 2xl:pt-[30px]',
        )}
      >
        <div className="flex h-full flex-col rounded-md md:flex-row">
          <div
            className={clsx('w-full px-0 pt-0 md:w-[35%] md:pt-[50px] 2xl:w-2/5 2xl:px-[100px]', {
              'flex flex-col items-center justify-center': isPsblModuleInfo,
            })}
          >
            <div className="iframe relative mx-auto mb-12 hidden w-[272px] md:block">
              <img
                className={clsx(
                  'drop-shadow-[0_0_10px_0px_hsla(var(--primary)/15%) relative z-50 mb-4 cursor-default rounded-[6px]',
                  (isAPModuleInfo || (isPsblModuleInfo && payStatementByLinkV289938 && lmiCommercialRelease92671)) &&
                    'cursor-pointer',
                )}
                width="252"
                height="159"
                src={
                  isPsblModuleInfo && payStatementByLinkV289938
                    ? lmiCommercialRelease92671
                      ? CommercialReleaseOnboarding
                      : CapricornRequiresAttenionBg
                    : APOnboardingImages.ap_demoImage
                }
                title={
                  isAPModuleInfo || (isPsblModuleInfo && payStatementByLinkV289938 && lmiCommercialRelease92671)
                    ? 'Play video'
                    : 'Onboarding'
                }
                onClick={onClickPlayVideo}
              />
            </div>
            <OnboardingKeyFeatures className="hidden md:flex" />
          </div>
          <div
            className={clsx('w-full px-0 md:w-[65%] 2xl:w-3/5 2xl:pr-16', {
              'flex flex-col items-center justify-center': isPsblModuleInfo,
            })}
          >
            <div className="overViewRight drop-shadow-[0_0_6px_0px_hsla(var(--primary)/4%) w-full max-w-[1200px] rounded-md px-6 pb-[72px] pt-6 md:overflow-auto md:rounded-[30px] md:px-10 md:py-6">
              <div className="mb-6">
                <h2
                  className={clsx(
                    'mb-3 font-semibold',
                    {'mt-5 text-[20px] leading-8': isPsblModuleInfo},
                    {'text-lg': !isPsblModuleInfo},
                  )}
                >
                  {isPsblModuleInfo && payStatementByLinkV289938
                    ? lmiCommercialRelease92671
                      ? 'Hi, welcome to your SwiftStatement portal'
                      : 'Hi, welcome to your Statement Integration portal'
                    : `Hi ${user.FirstName}, let's get started!`}
                </h2>
                <p className="w-full text-sm text-[#666666] md:w-[45%]">
                  {isPsblModuleInfo && payStatementByLinkV289938
                    ? 'Let’s run you through a few steps which will be needed to setup your account.'
                    : 'Setting up an Accounts Payable account is a simple six step process that will take about 10 minutes tocomplete.'}
                </p>
              </div>
              <AccountsPayableModuleInfoTabs
                isPsblModuleInfo={isPsblModuleInfo && payStatementByLinkV289938}
                keyFeatures={<OnboardingKeyFeatures />}
              />
            </div>
          </div>
        </div>
      </div>
      {isPlayVideo && <VideoPlayer setIsPlayVideo={setIsPlayVideo} thumbnailUrl="anc" videoUrl={VIDEO_URL} />}
    </>
  );
};
interface IVideoPlayerProps {
  videoUrl: string;
  thumbnailUrl: string;
  setIsPlayVideo: React.Dispatch<React.SetStateAction<boolean>>;
}
const VideoPlayer: FC<IVideoPlayerProps> = props => {
  const {setIsPlayVideo, videoUrl} = props;

  return (
    <div
      className={`fixed left-0 top-0 z-50 flex h-full w-full items-center justify-center bg-black-800 bg-opacity-10 backdrop-blur-sm transition-all duration-1000 ease-in`}
    >
      <div className="absolute right-10 top-5">
        <IconButton variant="outlined" onClick={() => setIsPlayVideo(false)}>
          <CloseCross />
        </IconButton>
      </div>

      <video className="!h-[70%] !w-[70%] rounded-md object-cover" autoPlay={true} controls controlsList="nodownload">
        <source src={videoUrl} type="video/mp4" />
      </video>
    </div>
  );
};
