import React from 'react';
//Icon
export const IconEditGray = (props: {width?: string; height?: string}) => {
  return (
    <svg viewBox="0 0 16 16" width={props.width || '26px'} height={props.height || '26px'} cursor="pointer">
      <g stroke="none" strokeWidth="1" fill="#BBBBBB" fillRule="evenodd">
        <g>
          <path d="M15.2967111,5.63555556 C15.1000889,5.63555556 14.9411556,5.79555556 14.9411556,5.99111111 L14.9411556,13.8133333 C14.9411556,14.5991111 14.3043556,15.2355556 13.5189333,15.2355556 L2.14115556,15.2355556 C1.35573333,15.2355556 0.718933333,14.5991111 0.718933333,13.8133333 L0.718933333,2.43555556 C0.718933333,1.64977778 1.35573333,1.01333333 2.14115556,1.01333333 L9.96337778,1.01333333 C10.16,1.01333333 10.3189333,0.853333333 10.3189333,0.657777778 C10.3189333,0.462222222 10.16,0.302222222 9.96337778,0.302222222 L2.14115556,0.302222222 C0.962844444,0.302222222 0.00782222222,1.25866667 0.00782222222,2.43555556 L0.00782222222,13.8133333 C0.00782222222,14.9902222 0.962844444,15.9466667 2.14115556,15.9466667 L13.5189333,15.9466667 C14.6972444,15.9466667 15.6522667,14.9902222 15.6522667,13.8133333 L15.6522667,5.99111111 C15.6522667,5.79555556 15.4933333,5.63555556 15.2967111,5.63555556 Z M5.08195556,9.54666667 L6.40782222,9.54666667 L6.40782222,10.8728889 L4.80782222,11.1466667 L5.08195556,9.54666667 Z M12.1578667,2.28977778 L13.6661333,3.79733333 L7.11893333,10.3431111 L7.11893333,8.83555556 L5.61066667,8.83555556 L12.1578667,2.28977778 Z M13.1203556,1.32622222 C13.3134222,1.13422222 13.5800889,1.01333333 13.8744889,1.01333333 C14.4632889,1.01333333 14.9411556,1.48977778 14.9411556,2.08 C14.9411556,2.37511111 14.8216889,2.64177778 14.6286222,2.83377778 L14.1688889,3.29244444 L12.6606222,1.78488889 L13.1203556,1.32622222 Z M3.91893333,12.0355556 L6.9536,11.5164444 L15.1313778,3.33866667 C15.4531556,3.01511111 15.6522667,2.57066667 15.6522667,2.08 C15.6522667,1.09866667 14.8565333,0.302222222 13.8744889,0.302222222 C13.3834667,0.302222222 12.9390222,0.501333333 12.6176,0.821333333 L4.43982222,8.99911111 L3.91893333,12.0355556 Z" />
        </g>
      </g>
    </svg>
  );
};
