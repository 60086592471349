import React, {useState} from 'react';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import {css} from 'glamor';
import FilterButton from '../form/FilterButton';
import {InputLabel, Select, MenuItem, makeStyles} from '@material-ui/core';
import {IProductsFilter} from '../../model/search-filters/ProductsFilter';

interface IFavouritesProps {
  searchFilter: IProductsFilter;
  updateFilter: (filter: IProductsFilter) => void;
  toggleFavourites: () => void;
}

export const Favourites = (props: IFavouritesProps) => {
  const style = css({
    alignItems: 'center',
    display: 'flex',
    // fontFamily: "Saira Extra Condensed",
    justifyContent: 'space-between',
    padding: '0.5em 0',
  });

  const selectStyles = makeStyles({
    root: {
      '& .MuiSelect-icon': {
        color: '#0092a8 !important',
      },
    },
  });

  const classes = selectStyles();

  const sortingOptions = [
    {
      Label: 'Name: A to Z',
      SortField: 'ShortDescription',
      SortAsc: true,
    },
    {
      Label: 'Name: Z to A',
      SortField: 'ShortDescription',
      SortAsc: false,
    },
    {
      Label: 'Code: A to Z',
      SortField: 'InventoryCode',
      SortAsc: true,
    },
    {
      Label: 'Code: Z to A',
      SortField: 'InventoryCode',
      SortAsc: false,
    },
  ];

  const [selectedSort, setSelectedSort] = useState(sortingOptions[0]);

  const sortProducts = (e: React.ChangeEvent<any>) => {
    const item = sortingOptions.find(option => option.Label === e.target.value);
    if (item) {
      setSelectedSort(item);
      props.updateFilter({...props.searchFilter, LastRowNumber: 0, SortAsc: item.SortAsc, SortField: item.SortField});
    }
  };

  return (
    <div>
      <div>
        <div id="favourites" className={`${style}`}>
          <div>
            <FilterButton
              isFavButton={true}
              label="Only Show Favourites"
              onClick={props.toggleFavourites}
              isActive={props.searchFilter.IsShowFavourites}
            >
              <FavoriteBorderIcon />
            </FilterButton>
          </div>
          <div>
            <InputLabel
              htmlFor="sorted-by"
              style={{
                color: '#1C78AD',
                display: 'inline',
                marginRight: '0.5em',
              }}
            >
              Sorted By
            </InputLabel>
            <Select
              style={{color: '#1C78AD'}}
              value={selectedSort.Label}
              onChange={sortProducts}
              className={classes.root}
            >
              {sortingOptions.map((sortingOption, index) => (
                <MenuItem className="pr-0" key={index} value={sortingOption.Label}>
                  {sortingOption.Label}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
      </div>
    </div>
  );
};
