import React, {useEffect, useContext, useState} from 'react';
import {Layout} from '../../components/layout/Layout';
import AppContext from '../../context/app/appContext';
import MenuService from '../../services/MenuService';
import {  
  ModuleTypes,  
  PaymentProviderName,
  AccountUsage,
} from '../../model/constants/Constants';
import {useHistory} from 'react-router-dom';
import {DaysLeftDialog} from '../../components/onboarding/DaysLeftDialog';
import {PurchaseNowDialog} from '../../components/onboarding/PurchaseNowDialog';
import useWorkflowAPI from '../../services/useWorkflowAPI';
import {Toast} from '../../utils/Toast';
import {IWorkflowStatusResponse} from '../../model/workflow/WorkflowResponse';
import {ConfigureSettingsDialog} from '../../components/dialog/ConfigureSettingsDialog';
import {ConnectAccount} from '../../components/paymentOnboarding/ConnectAccount';
import usePaymentServicesAPI from '../../services/usePaymentServicesAPI';
import {AccountsReceivable} from '../AccountsReceivable/AccountsReceivable';

export const TakePayment = () => {
  const appCtx = useContext(AppContext);  
  const {checkWorkflowStatus} = useWorkflowAPI();
  const {checkPsblPiblFlag} = usePaymentServicesAPI();

  const [showDaysLeftDialog, setShowDaysLeftDialog] = useState<boolean>(false);
  const [freeTrialDaysLeft, setFreeTrialDaysLeft] = useState<number>(0);
  const [isShowPaymentServicesDialog, setIsShowPaymentServicesDialog] = useState(false);

  const history = useHistory();

  useEffect(() => {
    appCtx.setSelectedModule(MenuService.Selling);
  }, [appCtx.user]);

  useEffect(() => {
    const module =
      Array.isArray(appCtx?.tenantInfo?.Modules) &&
      appCtx?.tenantInfo?.Modules.find(module => {
        return (
          module &&
          module.ModuleID === ModuleTypes.SpendaCollectAR &&
          ((module?.IsActive &&
            (module.Status === 'TrialActive' || module?.Status === 'TrialExpired') &&
            (module.TrialRemainingDays! < 6 || module?.IsExpired)) ||
            !module.IsModuleSetup)
        );
      });

    if (module) {
      setFreeTrialDaysLeft(module.TrialRemainingDays!);
      if (!module.IsModuleSetup) {
        history.push('/home');
      } else {
        if (module.IsExpired) {
          setFreeTrialDaysLeft(-1);
        }
        // appCtx.setMenu(appCtx.menu!, MenuTypes.Selling);
      }
      setShowDaysLeftDialog(true);
    }

    const workFlowId = appCtx?.workFlowId;
    if (workFlowId) {
      checkWorkflowStatusFun(workFlowId);
    }

    isPsblPiblEnableCheck();
  }, []);

  const handlePurchaseNowDialog = () => {
    setShowDaysLeftDialog(false);
    return history.push('/menu');
  };

  const checkWorkflowStatusFun = (workFlowId: number) => {
    if (workFlowId) {
      checkWorkflowStatus(workFlowId).then((data: IWorkflowStatusResponse) => {
        if (data?.Status === 'Complete') {
          Toast.info('Hurray! All your transaction have been imported. Please refresh the page to continue.');
          appCtx.setWorkFlowId(undefined);
        } else {
          setTimeout(() => {
            checkWorkflowStatusFun(workFlowId);
          }, 5000);
        }
      });
    }
  };

  if (showDaysLeftDialog) {
    return (
      <Layout
        leftPanel={
          freeTrialDaysLeft === -1 ? (
            <PurchaseNowDialog handleClose={handlePurchaseNowDialog} expired />
          ) : (
            <DaysLeftDialog handleClose={() => setShowDaysLeftDialog(false)} daysLeft={freeTrialDaysLeft} />
          )
        }
      />
    );
  }

  const isPsblPiblEnableCheck = async () => {
    try {
      const response = await checkPsblPiblFlag(PaymentProviderName.Spenda_Payment_Services, AccountUsage.SUPPLIER);
      if (response?.psbL_PIBLCheckFlag) {
        appCtx.setIsPSBLPIBLEnable(true);
      }
    } catch {}
  };

  return (
    <>
      <div className={`h-full relative overflow-hidden bg-spenda-newbg`}>
        <AccountsReceivable />        
        {appCtx.isShowConfigureSettingsDialog && (
          <ConfigureSettingsDialog
            open={appCtx.isShowConfigureSettingsDialog}
            onClose={() => appCtx.setIsShowConfigureSettingsDialog(false)}
            onButtonClick={() => {
              appCtx.setIsShowConfigureSettingsDialog(false);
              setIsShowPaymentServicesDialog(true);
            }}
          />
        )}
        {isShowPaymentServicesDialog && (
          <ConnectAccount onComplete={() => isPsblPiblEnableCheck()} handleClose={setIsShowPaymentServicesDialog} />
        )}        
      </div>
    </>
  );
};
