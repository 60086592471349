import {
  IConversationChatbox,
  IDocumentConvoSummaryList,
  IGetMessagesDataByGuidIdsResponse,
  ISupplierConvoResponse,
} from '../model/conversation/Conversation';
import { useContext } from 'react';
import PurchasingContext from '../context/purchasing/purchasingContext';
import { IConnectedSupplierStatementSummary } from '../model/supplier/SupplierTransaction';
import { DatTypes } from '../model/constants/Constants';
import { ICustomerStatementsSummary } from '../model/customer/CustomerStatements';
import { useConversationAPI } from '../services/useConversationAPI';
import { useTenantInfo } from './useTenantInfo';

export const useConversations = () => {
  const pCtx = useContext(PurchasingContext);

  const startConversation = (startChat: IConversationChatbox) => {
    const conversations = pCtx.conversations;
    // Close all the conversation
    if (conversations.length) {
      conversations.forEach((conversation, i) => {
        conversations[i].isChatboxOpen = false;
      });
    }
    const isConversationAlreadyOpen = conversations?.find(
      conversation => conversation.operationUniqueId === startChat.operationUniqueId
    );
    // Return if conversation already in context
    if (conversations.length && isConversationAlreadyOpen) {
      const index = conversations.findIndex(
        conversation => conversation.operationUniqueId === startChat.operationUniqueId
      );
      conversations[index].isChatboxOpen = true;
      pCtx.setConversations(conversations);
      return;
    }

    // Conversation open according to screen size
    // let totalChatBoxToOpen = 0;
    // if (window.innerWidth > 1080) {
    //   totalChatBoxToOpen = 2;
    // } else if (window.innerWidth > 720) {
    //   totalChatBoxToOpen = 1;
    // }

    conversations.push({
      conversationId: startChat?.conversationId,
      operationUniqueId: startChat?.operationUniqueId,
      refNumber: startChat?.refNumber,
      isChatboxOpen: true,
      conversationWith: startChat?.conversationWith,
      supplierID: startChat?.supplierID,
      customerID: startChat?.customerID,
      datTypeID: startChat?.datTypeID,
      guid: startChat?.guid,
      unreadMessages: startChat?.unreadMessages,
      messageCount: startChat?.messageCount,
      convoType: startChat?.convoType,
      transactionId: startChat?.transactionId,
    });
    pCtx.setConversations(conversations);
  };

  const { getMessagesDataByGuidIds, getSupplierConversations } = useConversationAPI();
  const tenant = useTenantInfo();
  const GUID = tenant.isInBuyerContext ? 'TransGUID' : 'GUIDstring';

  const addConversationMessagesData = async (items: IConnectedSupplierStatementSummary[]) => {
    if (Array.isArray(items) && items.length) {
      try {
        const GuidIds = items.map(item => item[GUID]) as string[];
        const datTypeID = DatTypes.Invoice;
        const messagesData = (await getMessagesDataByGuidIds(GuidIds, datTypeID)) as IGetMessagesDataByGuidIdsResponse;
        if (messagesData?.documentMessageSummaryList?.length) {
          items.forEach((item, i) => {
            const index = messagesData.documentMessageSummaryList.findIndex(
              (md: IDocumentConvoSummaryList) => md.guid === item[GUID]
            );
            if (index >= 0) {
              items[i].documentConversationData = {
                conversationId: messagesData.documentMessageSummaryList[index].messageSummary.conversationID,
                messageCount: messagesData.documentMessageSummaryList[index].messageSummary.messageCount,
                unreadMessages: messagesData.documentMessageSummaryList[index].messageSummary.unreadMessages,
              };
            }
          });
        }
      } catch { }
    }
    return items;
  };

  const addOpenConversationData = async (items: ICustomerStatementsSummary[]) => {
    try {
      const customerIds = items.map(item => item.CustomerID);
      if (customerIds?.length) {
        const openConversationsData = (await getSupplierConversations(
          customerIds.toString()
        )) as ISupplierConvoResponse;
        if (openConversationsData?.supplierConversationList?.length) {
          openConversationsData.supplierConversationList.forEach((openConversation, i) => {
            const index = items.findIndex(item => item.CustomerID === openConversation.customerID);
            if (index >= 0) {
              items[index].CustomerConvoData = openConversationsData.supplierConversationList[i];
            }
          });
        }
      }
    } catch { }
    return items;
  };

  return {
    startConversation,
    addConversationMessagesData,
    addOpenConversationData,
  };
};
