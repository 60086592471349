import React from 'react';
import {ICategory} from '../../model/inventory/Category';
import {css} from 'glamor';
import ClearIcon from '@material-ui/icons/Clear';

export const SelectedCategory = (props: {cat: ICategory; onClear: (cat: ICategory) => void}) => {
  const onClearCategory = () => {
    props.onClear(props.cat);
  };

  const category = css({
    backgroundColor: '#D7EBF2',
    borderRadius: 6,
    border: '1px solid #1C78AD',
    // fontFamily: "Saira Extra Condensed",
    fontSize: '16px',
    marginRight: '1em',
    width: 'fit-content',
    '&:after': {
      position: 'absolute',
      color: '#1C78AD',
    },
    '&:not(:last-child):after': {
      content: '➡',
    },
    '&:last-child:after': {
      content: '⤵',
      fontWeight: 600,
      paddingLeft: '3px',
      marginTop: '3px',
    },
  });

  return (
    <div className={`${category}`}>
      <span className="m-3">{props.cat.Name}</span>
      <ClearIcon
        style={{cursor: 'pointer', position: 'relative', color: '#1C78AD'}}
        fontSize="small"
        onClick={onClearCategory}
      />
    </div>
  );
};
