import React from 'react';
import {useHistory} from 'react-router-dom';
import moment from 'moment';
import clsx from 'clsx';

import {AUTH_PURCHASING_REQUISITION_DETAIL} from '../../routes/PurchasingRoutes';
import {RequisitionStatusType} from '../../model/constants/Constants';
import {IRequisition} from '../../model/requisition/Requisition';
import {STable} from '../tables/STable';
import {PriceFormat} from '../../utils/formatter';

interface IRequisitionList {
  items: IRequisition[];
  loading: boolean;
}

const statusRenderer = (_status: RequisitionStatusType) => {
  let style = '';
  let status = '';
  switch (_status) {
    case RequisitionStatusType.New:
      style = 'bg-[#1C78AD20] text-primary';
      status = 'Draft';
      break;
    case RequisitionStatusType.Pending:
      style = 'bg-[#C68A1920] text-[#C68A19]';
      status = 'Awaiting Approval';
      break;
    case RequisitionStatusType.Approved:
      style = 'bg-[#8819A820] text-[#9D5CB3]';
      status = 'Approved';
      break;
    case RequisitionStatusType.Processed:
      style = 'bg-[#8178CF20] text-[#8178CF]';
      status = 'Processed';
      break;
    default:
      break;
  }
  return (
    <span className={clsx('mx-6 block whitespace-nowrap rounded px-6 py-1 font-poppins text-xs font-medium', style)}>
      {status}
    </span>
  );
};

const columnSetting = {
  RefNumber: {
    title: 'Req ref',
    field: 'RefNumber',
  },
  Name: {
    title: 'Purchase Reason',
    field: 'Name',
  },
  DueDate: {
    title: 'Due date',
    field: 'DueDate',
    align: 'center',
    render: (rowData: any) => moment(rowData.DueDate).format('Do MMM YYYY'),
  },
  CreatedByUserName: {title: 'Created by', field: 'CreatedByUserName'},
  AssignedToUserName: {title: 'Assigned to', field: 'AssignedToUserName'},
  LineCount: {
    title: 'Total lines',
    field: 'LineCount',
    align: 'right',
  },
  TotalEx: {
    title: 'Est. cost (ex)',
    field: 'TotalEx',
    align: 'right',
    render: (rowData: any) => PriceFormat(rowData.TotalEx),
  },
  Status: {
    title: 'Order Status',
    field: 'Status',
    align: 'center',
    width: '235px',
    render: (rowData: any) => statusRenderer(rowData.Status as RequisitionStatusType),
  },
} as {[key: string]: any};

export const RequisitionList: React.FC<IRequisitionList> = ({items, loading}) => {
  const history = useHistory();

  const handleRowClick = async (rowData: any) => {
    history.push(AUTH_PURCHASING_REQUISITION_DETAIL.replace(/:requisitionID/g, rowData.ID));
  };

  return (
    <STable
      newUI={true}
      columns={Object.entries(columnSetting)
        .map(([, value]: [string, any]) => value)
        .filter(value => value)}
      data={items}
      emptyDataMessage={'No requisition found.'}
      stickyHeader={true}
      loading={loading}
      onRowClick={handleRowClick}
    ></STable>
  );
};
