import service_calendar from './service-calendar.png';
import service_dayView from './service-dayview.png';
import service_pay from './service-pay.png';
import service_sale from './service-sale.png';

export const ServiceOnboardingImages = {
  service_calendar,
  service_dayView,
  service_pay,
  service_sale,
};
