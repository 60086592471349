import React, {useContext, useEffect} from 'react';
import {FunctionComponent} from 'react';
import {IPurchaseOrder, IPurchaseOrderLine} from '../../model/purchase-order/PurchaseOrder';
import {css} from 'glamor';
import PurchasingContext from '../../context/purchasing/purchasingContext';
import {PurchaseOrderStatus} from '../../model/constants/Constants';
import {TextField, Select, FormControl, MenuItem, InputLabel} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import moment from 'moment';
import {AddressDialog} from '../address/AddressDialog';
import {IAddress} from '../../model/address/Address';
import {AlertDialogSlide} from '../dialog/AlertDialogSlide';
import usePurchaseOrderAPI from '../../services/usePurchaseOrderAPI';
import AppContext from '../../context/app/appContext';
import {PriceFormat} from '../../utils/formatter';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import {SButton} from '../buttons/Button';
import STooltip from '../data-display/Tooltip';
import SDatePicker from '../pickers/DatePicker';
import TransactionTotal from '../transactions/TransactionTotal';
import {Toast} from '../../utils/Toast';

interface IPurchaseOrderEditPanelProps {
  visible?: boolean;
  purchaseOrder: Partial<IPurchaseOrder> | undefined;
}

const PurchaseOrderEditPanel: FunctionComponent<IPurchaseOrderEditPanelProps> = ({purchaseOrder}) => {
  const ctx = useContext(PurchasingContext);
  const appCtx = useContext(AppContext);

  const [po, setPo] = React.useState(purchaseOrder);
  const [addressToEdit, setAddressToEdit] = React.useState<IAddress | undefined>(undefined);
  const [editAddressType, setEditAddressType] = React.useState<'Billing' | 'Shipping' | undefined>(undefined);
  const [isShowDeleteConfirmation, setIsShowDeleteConfirmation] = React.useState(false);
  const [isShowModifyConfirmation, setIsShowModifyConfirmation] = React.useState(false);
  // const [isDefaultAddressSearch, SetIsDefaultAddressSearch] = React.useState(false);

  const purchaseOrderAPI = usePurchaseOrderAPI();

  const isReadOnly = ['Review', 'Cancelled', 'Approved'].indexOf((po && po.Status) || '') >= 0;

  useEffect(() => {
    setPo(purchaseOrder);

    if (!purchaseOrder) {
      setIsShowDeleteConfirmation(false);
    }

    return function () {
      setPo(undefined);
    };
  }, [purchaseOrder]);

  const headerStyles = css({
    // fontFamily: "Saira Extra Condensed",
    fontWeight: 'bold',
    fontSize: '1.5em',
    lineHeight: 1,
    width: '197px',
    '@media(min-width: 1280px)': {
      fontSize: '2rem',
      width: '260px',
    },
  });

  const labelStyles = css({
    color: '#006c91',
    fontSize: '0.85rem',
    fontWeight: '400',
  });

  const tblBorderStyles = css({
    borderBottom: '2px solid #ccc',
  });

  const PurchaseOrderOutlineStyles = css({
    border: `0.05rem solid #006c91`,
    backgroundColor: '#d6ebf3',
    width: '100%',
  });

  const actionButtons = css({
    position: 'absolute',
    bottom: '1em',
    width: '100%',
    left: 0,
    padding: '1.5em 1em 0 0.5em',
    '@media(min-width: 1280px)': {
      position: 'absolute',
      bottom: '1em',
      width: '100%',
      left: 0,
      padding: '0 1em 0 0.5em',
    },
  });

  const purchaseOrderBodyStyles = css({
    maxHeight: '82%',
    overflowY: 'auto',
    overflowX: 'hidden',
    '@media(min-width: 1280px)': {
      maxHeight: '85%',
      overflowY: 'auto',
      overflowX: 'hidden',
    },
  });

  const useStyles = makeStyles({
    root: {
      '& label.Mui-focused': {
        color: '#006c91',
      },
      '& .MuiFormLabel-root': {
        color: '#006c91 !important',
        fontFamily: 'sans-serif',
      },
      '& .MuiInput-underline:before': {
        borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: '#006c91',
      },
      '& .MuiInputBase-input': {
        fontFamily: 'sans-serif',
        color: '#000',
      },
    },
    select: {
      '& .MuiSelect-select': {
        minWidth: '14rem',
      },
      '& .MuiInput-underline:after': {
        pointerEvents: 'none',
      },
    },
  });

  const classes = useStyles();

  const openEditAddressDialog = (addressType: 'Billing' | 'Shipping') => {
    if (isReadOnly) {
      return;
    }
    setEditAddressType(addressType);
    setAddressToEdit(addressType === 'Billing' ? po && po.BillingAddress : po && po.ShippingAddress);
    // SetIsDefaultAddressSearch(true);
  };

  const updateAddress = (address: IAddress | undefined) => {
    if (!address || !po) {
      return;
    }

    let updatedPo: Partial<IPurchaseOrder> = {...po};

    if (editAddressType === 'Billing') {
      updatedPo.BillingAddress = address;
      updatedPo.BillCity = address.City;
      updatedPo.BillCountry = address.Country;
      updatedPo.BillPostCode = address.PostCode;
      updatedPo.BillState = address.State;
      updatedPo.BillStreetAddress = address.StreetAddress;
    }
    if (editAddressType === 'Shipping' && po) {
      updatedPo.ShippingAddress = address;
      updatedPo.ShipCity = address.City;
      updatedPo.ShipCountry = address.Country;
      updatedPo.ShipPostCode = address.PostCode;
      updatedPo.ShipState = address.State;
      updatedPo.ShipStreetAddress = address.StreetAddress;
    }

    setPo(updatedPo);
    setAddressToEdit(undefined);
  };

  const onSendPurchaseOrder = () => {
    if (!purchaseOrder) {
      return;
    }

    const hasValidStreetAddresses = !!po?.BillingAddress?.StreetAddress && !!po.ShippingAddress?.StreetAddress;

    if (!hasValidStreetAddresses) {
      Toast.info('Please provide billing and shipping addresses.');
      return;
    }
    ctx.updatePurchaseOrderStatus(po, PurchaseOrderStatus.Sent, true);
  };

  const onCancelPurchaseOrder = () => {
    if (!purchaseOrder) {
      return;
    }
    ctx.updatePurchaseOrderStatus(po, PurchaseOrderStatus.Cancelled, false);
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | {name?: string; value: unknown}>,
  ) => {
    const {name, value} = event.target;

    if (!name) {
      return;
    }

    setPo(oldState => ({
      ...oldState,
      [name]: value,
    }));
  };

  const handleDateChange = (date: Date | null, value?: string | null | undefined, name?: string) => {
    let m = moment().format('YYYY-MM-DD');
    if (date) {
      m = moment(date).local().format('YYYY-MM-DD');
    }

    setPo(oldState => ({
      ...oldState,
      [name as string]: m,
    }));
  };

  const onClickBackButton = () => {
    if (po && po.Status === 'Review') {
      ctx.setPurchaseOrder(undefined);
    }

    let eq = JSON.stringify(purchaseOrder) === JSON.stringify(po);

    if (eq) {
      setIsShowModifyConfirmation(false);
      ctx.setPurchaseOrder(undefined);
    } else {
      setIsShowModifyConfirmation(true);
    }
  };

  const onChangeConfirmation = () => {
    setIsShowModifyConfirmation(false);
    ctx.setPurchaseOrder(undefined);
  };

  return (
    <>
      <div className="h-full rounded bg-white p-2">
        <div className="flex flex-row">
          <div className="mr-4 lg:py-1 xl:py-2">
            <SButton textColor="blue" variant="outlined" onClick={onClickBackButton}>
              <ChevronLeftIcon />
              Back
            </SButton>
          </div>
          <div className="flex-1 text-left">
            <h2 className={`${headerStyles}`}>
              {purchaseOrderAPI.getStatusToDisplay((po && po.Status) || '')} Purchase Order
            </h2>
            <p>Ref: {po && po.RefNumber}</p>
          </div>
          <div className="flex flex-1 items-end truncate text-right sm:text-base xl:text-base">
            <STooltip title={po?.Name || ''}>
              <p className="w-full">Reason: {po && po.Name}</p>
            </STooltip>
          </div>
        </div>
        <hr />
        <div className={`${purchaseOrderBodyStyles}`}>
          <div className="flex flex-row">
            <div className="w-full flex-col px-2 py-2 xl:mx-2">
              <label className={`${labelStyles}`}>SUPPLIER</label>
              <div
                style={{minHeight: '85px'}}
                className="pointer-events-none rounded-md border-2 border-gray-400 px-1 py-1"
              >
                {po && po.SupplierName}
              </div>
            </div>
            <div className="w-full flex-col px-2 py-2 xl:mx-2">
              <label className={`${labelStyles}`}>BILL TO</label>
              <div
                className={`${PurchaseOrderOutlineStyles} rounded-md px-1 py-1 ${
                  isReadOnly ? 'cursor-default' : 'cursor-pointer'
                }`}
                onClick={() => openEditAddressDialog('Billing')}
              >
                {po && po.BillStreetAddress}
                <br />
                {po && po.BillCity}
                <br />
                {po && `${po.BillState} ${po.BillPostCode}`}
                <br />
              </div>
            </div>
            <div className="w-full flex-col px-2 py-2 xl:mx-2">
              <label className={`${labelStyles}`}>SHIP TO</label>
              <div
                className={`${PurchaseOrderOutlineStyles} rounded-md px-1 py-1 ${
                  isReadOnly ? 'cursor-default' : 'cursor-pointer'
                }`}
                onClick={() => openEditAddressDialog('Shipping')}
              >
                {po && po.ShipStreetAddress}
                <br />
                {po && po.ShipCity}
                <br />
                {po && `${po.ShipState} ${po.ShipPostCode}`}
                <br />
              </div>
            </div>
          </div>
          <div className="flex flex-row py-2">
            <div className="w-full flex-col sm:pr-1 xl:pr-2">
              <TextField
                classes={{root: classes.root}}
                autoFocus
                margin="dense"
                id="Purchase Order"
                name="RefNumber"
                label="REFERENCE"
                type="text"
                value={(po && po.RefNumber) || ''}
                onChange={handleChange}
                disabled
                fullWidth
              ></TextField>
            </div>
            <div className="w-full flex-col sm:pr-1 xl:pr-2">
              <SDatePicker
                id="order-date-picker"
                label="ORDER DATE"
                disabled={isReadOnly}
                value={(po && po.TransDate) || ''}
                onChange={(date, value) => handleDateChange(date, value, 'TransDate')}
              ></SDatePicker>
            </div>
            <div className="w-full flex-col sm:pr-1 xl:pr-2">
              <FormControl margin="dense" classes={{root: classes.root}} className="w-full">
                <InputLabel id="demo-simple-select-helper-label" style={{width: '155px'}}>
                  DELIVERY METHOD
                </InputLabel>
                <Select
                  value={(po && po.DeliveryMethod) || 'Deliver'}
                  onChange={handleChange}
                  name="DeliveryMethod"
                  disabled={isReadOnly}
                >
                  <MenuItem value="Deliver">Delivery</MenuItem>
                  <MenuItem value="Warehouse_Pickup">Warehouse Pickup</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="w-full flex-col sm:pr-1 xl:pr-2">
              <SDatePicker
                id="order-date-picker"
                label="REQUIRED DATE"
                disabled={isReadOnly}
                value={po && po.DueDate}
                onChange={(date, value) => handleDateChange(date, value, 'DueDate')}
              ></SDatePicker>
            </div>
          </div>

          <table className="mb-4 mt-4 w-full table-auto">
            <thead className={`${tblBorderStyles} uppercase`}>
              <tr>
                <th className={`px-4 py-2 ${labelStyles}`}>ITEM</th>
                <th className={`px-4 py-2 ${labelStyles}`}>DESCRIPTION</th>
                <th className={`px-4 py-2 ${labelStyles}`}>UNIT PRICE</th>
                <th className={`px-4 py-2 ${labelStyles}`}>QTY</th>
                <th className={`px-4 py-2 ${labelStyles} sm:w-1/5`}>TOTAL (EX)</th>
              </tr>
            </thead>
            <tbody className={`${tblBorderStyles} sm:text-xs xl:text-base`}>
              {po &&
                po.Lines &&
                po.Lines.map((line: IPurchaseOrderLine, index: number) => {
                  return (
                    line.IsActive && (
                      <tr key={index}>
                        <td className="px-4 py-2 text-center">{line.Code}</td>
                        <td className="px-4 py-2 text-center">{line.ShortDescription}</td>
                        <td className="px-4 py-2 text-center">{PriceFormat(line.CostPriceEx || 0)}</td>
                        <td className="px-4 py-2 text-center">{line.Quantity}</td>
                        <td className="px-4 py-2 text-right">{PriceFormat(line.LineTotalEx)}</td>
                      </tr>
                    )
                  );
                })}
            </tbody>
          </table>

          <TransactionTotal subtotal={po && po.TotalEx} tax={po && po.TotalTax} total={po && po.TotalInc} />

          <div className="flex flex-row">
            <div className="mr-4 flex-col px-4 py-2">
              <label className={`${labelStyles}`}>INTERNAL NOTES</label>
              <textarea
                rows={4}
                cols={40}
                className={`${PurchaseOrderOutlineStyles} rounded-md p-2 focus:bg-white`}
                name="InternalNote"
                value={(po && po.InternalNote) || ''}
                onChange={handleChange}
                autoComplete="off"
                disabled={isReadOnly}
              />
            </div>
            <div className="flex-col px-4 py-2">
              <label className={`${labelStyles}`}>DELIVERY NOTES</label>
              <textarea
                rows={4}
                cols={40}
                className={`${PurchaseOrderOutlineStyles} rounded-md p-2 focus:bg-white`}
                name="ExternalNotes"
                value={(po && po.ExternalNotes) || ''}
                onChange={handleChange}
                autoComplete="off"
                disabled={isReadOnly}
              />
            </div>
          </div>
        </div>
        <hr />
        <div className={`flex items-center justify-between ${actionButtons}`}>
          {po && po.Status !== 'Cancelled' && (
            <SButton color="red" onClick={() => setIsShowDeleteConfirmation(true)}>
              Cancel
            </SButton>
          )}
          <div className="flex">
            {!isReadOnly && (
              <div className="mr-2">
                <SButton variant="contained" onClick={() => ctx.updatePurchaseOrder(po)}>
                  Save &amp; Close
                </SButton>
              </div>
            )}
            {!isReadOnly && (
              <SButton variant="contained" color="yellow" onClick={onSendPurchaseOrder}>
                Send
              </SButton>
            )}
          </div>
        </div>
      </div>

      {addressToEdit && (
        <AddressDialog
          title="Edit Your Address "
          address={addressToEdit}
          open={addressToEdit != undefined}
          onClose={() => setAddressToEdit(undefined)}
          onSave={(address?: IAddress) => updateAddress(address)}
        />
      )}

      {isShowDeleteConfirmation && (
        <AlertDialogSlide
          title="Cancel Purchase Order"
          actions={[
            {label: 'Yes', action: onCancelPurchaseOrder},
            {label: 'No', variant: 'outlined', action: () => setIsShowDeleteConfirmation(false)},
          ]}
        >
          <p>Are you sure you want to cancel this purchase order "{po && po.RefNumber}" ?</p>
          <p>
            If you proceed, you will need to contact{' '}
            {appCtx.marketplaceSupplier && appCtx.marketplaceSupplier.TenantName} to confirm cancellation of this order.
          </p>
        </AlertDialogSlide>
      )}

      {isShowModifyConfirmation && (
        <AlertDialogSlide
          title="Confirm Exit"
          actions={[
            {label: 'Exit', action: onChangeConfirmation},
            {label: 'Cancel', variant: 'outlined', action: () => setIsShowModifyConfirmation(false)},
          ]}
        >
          <p>
            Are you sure you want to exit without saving changes to order :"{po && po.Name}({po && po.RefNumber})?
          </p>
        </AlertDialogSlide>
      )}
    </>
  );
};

export default PurchaseOrderEditPanel;
