import React from 'react';
//Icon
const IconSort = (props: {width?: string; height?: string}) => {
  return (
    <svg
      width={props.width || '22px'}
      height={props.height || '22px'}
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>{'Icons/General/Apps/apps_sort'}</title>
      <g id="Icons/General/Apps/apps_sort" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <path
          d="M29.1666667,0 L16.6666667,0 C7.46192084,0 0,7.46192084 0,16.6666667 L0,29.1666667 C0,38.3714125 7.46192084,45.8333333 16.6666667,45.8333333 L29.1666667,45.8333333 C38.3714125,45.8333333 45.8333333,38.3714125 45.8333333,29.1666667 L45.8333333,16.6666667 C45.8333333,7.46192084 38.3714125,0 29.1666667,0 Z M37.5,29.1666667 C37.5,33.7690396 33.7690396,37.5 29.1666667,37.5 L16.6666667,37.5 C12.0642938,37.5 8.33333333,33.7690396 8.33333333,29.1666667 L8.33333333,16.6666667 C8.33333333,12.0642938 12.0642938,8.33333333 16.6666667,8.33333333 L29.1666667,8.33333333 C33.7690396,8.33333333 37.5,12.0642938 37.5,16.6666667 L37.5,29.1666667 Z"
          id="Shape"
          fill="#1C78AD"
        />
        <path
          d="M29.1666667,54.1666667 L16.6666667,54.1666667 C7.46192084,54.1666667 0,61.6285875 0,70.8333333 L0,83.3333333 C0,92.5380792 7.46192084,100 16.6666667,100 L29.1666667,100 C38.3714125,100 45.8333333,92.5380792 45.8333333,83.3333333 L45.8333333,70.8333333 C45.8333333,61.6285875 38.3714125,54.1666667 29.1666667,54.1666667 Z M37.5,83.3333333 C37.5,87.9357062 33.7690396,91.6666667 29.1666667,91.6666667 L16.6666667,91.6666667 C12.0642938,91.6666667 8.33333333,87.9357062 8.33333333,83.3333333 L8.33333333,70.8333333 C8.33333333,66.2309604 12.0642938,62.5 16.6666667,62.5 L29.1666667,62.5 C33.7690396,62.5 37.5,66.2309604 37.5,70.8333333 L37.5,83.3333333 Z"
          id="Shape"
          fill="#1C78AD"
        />
        <path
          d="M92.8875,79.3708333 L83.3333333,88.9125 L83.3333333,10.9708333 L92.8875,20.5125 C93.9406892,21.5649449 95.4753488,21.9755744 96.9133874,21.5897082 C98.351426,21.2038421 99.4743714,20.0801026 99.8592208,18.6417915 C100.24407,17.2034805 99.8323559,15.6691115 98.7791667,14.6166667 L88,3.85416667 C83.1197076,-1.0200563 75.2136258,-1.0200563 70.3333333,3.85416667 L59.5541667,14.6166667 C58.5009774,15.6691115 58.0892631,17.2034805 58.4741125,18.6417915 C58.8589619,20.0801026 59.9819073,21.2038421 61.4199459,21.5897083 C62.8579845,21.9755744 64.3926442,21.5649449 65.4458333,20.5125 L75,10.9708333 L75,88.9125 L65.4458333,79.3708333 C64.3926442,78.3183884 62.8579845,77.907759 61.4199459,78.2936251 C59.9819073,78.6794912 58.8589619,79.8032307 58.4741125,81.2415418 C58.0892631,82.6798529 58.5009774,84.2142218 59.5541667,85.2666667 L70.3333333,96.0291667 C75.2136258,100.90339 83.1197076,100.90339 88,96.0291667 L98.7791667,85.2666667 C99.8323559,84.2142218 100.24407,82.6798529 99.8592208,81.2415418 C99.4743714,79.8032307 98.351426,78.6794912 96.9133874,78.2936251 C95.4753488,77.907759 93.9406892,78.3183884 92.8875,79.3708333 L92.8875,79.3708333 Z"
          id="Path"
          fill="#1C78AD"
        />
      </g>
    </svg>
  );
};

export default IconSort;
