import { IGetAllOpenConvoResponse } from './../model/conversation/Conversation';
import useHttp from '../hooks/useHttp';
import { ConversationStatus, DatTypes } from '../model/constants/Constants';
import {
  IConvoGetMessagesResponse,
  IConvoSendMessageResponse,
  IDocumentConvoRefBusTrans,
  IDocumentCreateSubjectResponse,
  IDocumentResolvedConvo,
  IDocumentSubjectListResponse,
  IGetBuyerCustomerConvo,
  IGetMessagesDataByGuidIdsResponse,
  ISupplierConvoResponse,
  IUploadAttachmentResponse,
} from '../model/conversation/Conversation';

export interface IInitiateConvoWithoutMsgPayload {
  supplierID: number | null;
  customerID: number | null;
  subjectID: number;
  isCustomerLevelConversation: boolean;
  refBusTrans?: IDocumentConvoRefBusTrans;
}

export interface IInitiateConvoPayload {
  supplierID: number | null;
  customerID: number | null;
  subjectID: number;
  text: string;
  status: ConversationStatus;
  refBusTrans?: IDocumentConvoRefBusTrans;
}

interface ISendMessagePayload {
  text: string | null;
  status: ConversationStatus;
  markAsUnread?: boolean;
}

interface ICreateSubjectPayload {
  subjectID: null | number;
  isEnable: boolean;
  description: string;
}

export const useConversationAPI = () => {
  const { isLoading, POST, GET } = useHttp();

  const initiateConversationWithoutMsg = (
    Payload: IInitiateConvoWithoutMsgPayload,
    isCustomerLevelConvo: boolean
  ): Promise<IConvoGetMessagesResponse> => {
    return POST(`Spenda/Messaging/Messaging/NewConversation/${isCustomerLevelConvo}`, {
      ...Payload,
    });
  };

  const initiateConversation = (
    Payload: IInitiateConvoPayload,
    isCustomerLevelConvo: boolean
  ): Promise<IConvoSendMessageResponse> => {
    return POST(`Spenda/Messaging/Messaging/NewConversation/${isCustomerLevelConvo}`, {
      ...Payload,
    });
  };

  const sendMessage = (conversationId: number, payload: ISendMessagePayload): Promise<IConvoSendMessageResponse> => {
    return POST(`Spenda/Messaging/Messaging/${conversationId}`, {
      ...payload,
    });
  };

  const getMessages = (
    conversationId: number,
    isCustomerLevelConvo: boolean,
    utcOffset?: number
  ): Promise<IConvoGetMessagesResponse> => {
    return GET(
      `Spenda/Messaging/Messaging/GetConversation/${conversationId}/${isCustomerLevelConvo}/${utcOffset || 0}`
    );
  };

  const getMessagesDataByGuidIds = (
    GuidIds: string[],
    datTypeID: DatTypes
  ): Promise<IGetMessagesDataByGuidIdsResponse> => {
    return POST(`Spenda/Messaging/Messaging/Search`, {
      datTypeID: datTypeID,
      guidList: GuidIds,
    });
  };

  const getSupplierConversations = (customerIds: string): Promise<ISupplierConvoResponse> => {
    return GET(`Spenda/Messaging/Messaging/Supplier?CustomerIDs=${customerIds}`);
  };

  const getSubjectList = (SupplierID: number, CustomerID: number): Promise<IDocumentSubjectListResponse> => {
    return GET(`Spenda/Messaging/Messaging/Subjects/${SupplierID}/${CustomerID}`);
  };

  const createSubject = (payload: ICreateSubjectPayload): Promise<IDocumentCreateSubjectResponse> => {
    return POST(`Spenda/Messaging/Messaging/Subjects`, {
      ...payload,
    });
  };

  const uploadAttachments = (
    conversationId: number,
    attachments: any,
    header: { [key: string]: string } | undefined = undefined
  ): Promise<IUploadAttachmentResponse[]> => {
    return POST(`Spenda/Messaging/Messaging/Upload/${conversationId}`, attachments, true, false, header);
  };

  const getResolvedConversations = (guid: string, datTypeID: DatTypes): Promise<IDocumentResolvedConvo[]> => {
    return GET(`Spenda/Messaging/Messaging/ResolvedConversations/${datTypeID}/${guid}`);
  };

  const getBuyerCustomerConvo = (): Promise<IGetBuyerCustomerConvo> => {
    return GET(`Spenda/Messaging/Messaging/CustomerConversationSearch`);
  };

  const getAllSupplierOpenConvos = (customerId?: string): Promise<IGetAllOpenConvoResponse[]> => {
    return GET(`Spenda/Messaging/Messaging/Supplier/GetConversations/${customerId || 0}`);
  };

  const getAllBuyerOpenConvos = (supplierId: string): Promise<IGetAllOpenConvoResponse[]> => {
    return GET(`Spenda/Messaging/Messaging/Buyer/GetConversations/${supplierId}`);
  };

  return {
    initiateConversation,
    getMessagesDataByGuidIds,
    sendMessage,
    getMessages,
    getSupplierConversations,
    getSubjectList,
    createSubject,
    uploadAttachments,
    initiateConversationWithoutMsg,
    getResolvedConversations,
    getBuyerCustomerConvo,
    getAllSupplierOpenConvos,
    getAllBuyerOpenConvos,
    isLoading,
  };
};
