import React, {useRef} from 'react';
import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css';

interface IEditorProps {
  placeholder?: string;
  defaultValue?: string;
  handleChange: (newValue: string) => void;
  dataAutoId?: string;
}

const defaultColors = [
  '#C55D44',
  '#3C9F78',
  '#1C78AD',
  '#C68A19',
  '#8178CF',
  '#333333',
  '#666666',
  '#999999',
  '#0000FF',
  '#008000',
  '#800000',
  '#800080',
  '#654321',
  '#2F4F4F',
];

export default function RichTextEditor(props: IEditorProps) {
  let contentEditableRef = useRef<any>();

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    setCusrorAfterTheDroppedElement(event);
  };

  const setCusrorAfterTheDroppedElement = (event: React.DragEvent<HTMLDivElement>) => {
    const droppedContent = event?.dataTransfer?.getData('text/plain');
    if (!droppedContent) return;

    const editor = contentEditableRef?.current?.getEditor();
    if (!editor) return;

    const range = editor.getSelection();
    const cursorPosition = range?.index + droppedContent?.length;

    contentEditableRef?.current?.focus();

    setTimeout(() => {
      editor.setSelection(cursorPosition, 0);
    }, 0);
  };

  const modules = {
    toolbar: [
      [{header: [1, 2, 3, 4, 5, 6, false]}],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{align: ['right', 'center', 'justify']}],
      [{list: 'ordered'}, {list: 'bullet'}],
      [{color: defaultColors}],
      [{background: defaultColors}],
    ],
  };

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'link',
    'color',
    'image',
    'background',
    'align',
  ];

  const handleProcedureContentChange = (content: any) => {
    props?.handleChange(content);
  };

  return (
    <div className="h-full" data-autoid={props?.dataAutoId} onDrop={handleDrop}>
      <ReactQuill
        ref={contentEditableRef}
        id="ar-editor"
        theme="snow"
        className="h-[calc(100%-65px)]"
        data-autoid={props?.dataAutoId}
        modules={modules}
        formats={formats}
        defaultValue={props?.defaultValue}
        onChange={handleProcedureContentChange}
      />
    </div>
  );
}
