import React from 'react';
export const Profile = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="40px" height="40px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>Profile</title>
      <g id="Order-Listing" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Spenda-Collect-Supplier-Interface-Copy" transform="translate(-1263.000000, -83.000000)">
          <g id="Buttons/IconButton-Copy-2" transform="translate(1263.000000, 83.000000)">
            <rect
              id="Background"
              className="fill-white stroke-primary"
              x="0.5"
              y="0.5"
              width="39"
              height="39"
              rx="6"
            ></rect>
            <rect
              id="Background-Overlay"
              fillOpacity="0"
              fill="#000000"
              x="0"
              y="0"
              width="40"
              height="40"
              rx="6"
            ></rect>
            <rect id="Overlay" fillOpacity="0" fill="#000000" x="0" y="0" width="40" height="40" rx="6"></rect>
            <g id="Icon" transform="translate(9.000000, 9.000000)" className="fill-primary">
              <path
                d="M18.778183,3.22181703 C16.7005768,1.1442108 13.9381561,0 11,0 C8.06184388,0 5.29942321,1.1442108 3.22181703,3.22181703 C1.1442108,5.29942321 0,8.06184388 0,11 C0,13.9381561 1.1442108,16.7005768 3.22181703,18.778183 C5.29942321,20.8557892 8.06184388,22 11,22 C13.9381561,22 16.7005768,20.8557892 18.778183,18.778183 C20.8557892,16.7005768 22,13.9381561 22,11 C22,8.06184388 20.8557892,5.29942321 18.778183,3.22181703 Z M5.86666667,18.2292024 C6.17225223,15.6276422 8.43293581,13.6190476 11.1046913,13.6190476 C12.5131527,13.6190476 13.8375921,14.1619941 14.8338519,15.1475691 C15.6754831,15.9804316 16.2063039,17.0678614 16.3428571,18.2290627 C14.9236635,19.4050741 13.0963635,20.1142857 11.1046913,20.1142857 C9.11301902,20.1142857 7.28586023,19.4052138 5.86666667,18.2292024 Z M11.2095238,11.3142857 C9.64993719,11.3142857 8.38095238,10.0453009 8.38095238,8.48571429 C8.38095238,6.92598557 9.64993719,5.65714286 11.2095238,5.65714286 C12.7691104,5.65714286 14.0380952,6.92598557 14.0380952,8.48571429 C14.0380952,10.0453009 12.7691104,11.3142857 11.2095238,11.3142857 Z M16.194193,13.8027774 C15.4396822,13.0482666 14.5470575,12.4822236 13.5735468,12.1275077 C14.7438378,11.3338326 15.514334,9.99297746 15.514334,8.47564347 C15.514334,6.04426445 13.5361409,4.06607143 11.1047619,4.06607143 C8.67338288,4.06607143 6.69518986,6.04426445 6.69518986,8.47564347 C6.69518986,9.99377672 7.46632546,11.3351115 8.63757559,12.1286266 C7.74191372,12.4550485 6.9130708,12.9597075 6.19996221,13.6240606 C5.16730549,14.5857422 4.45020055,15.8119821 4.11770427,17.1576329 C2.70955053,15.5343157 1.85625,13.4173695 1.85625,11.1047619 C1.85625,6.00510019 6.00510019,1.85625 11.1047619,1.85625 C16.2044236,1.85625 20.3532738,6.00510019 20.3532738,11.1047619 C20.3532738,13.417849 19.4996536,15.5352748 18.0910203,17.158592 C17.778186,15.8973441 17.1258219,14.7344064 16.194193,13.8027774 Z"
                id="Shape"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
