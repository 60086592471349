import {IConnectedSupplierStatementSummary} from './../../model/supplier/SupplierTransaction';
import {IRequisitionActionOption} from './../../model/requisition/RequisitionActionOption';
import {ISupplierMarket} from './../../model/SupplierMarket';
import {IRequisition, IRequisitionLine} from '../../model/requisition/Requisition';
import {createContext} from 'react';
import {IPurchaseOrder, IPurchaseOrderLine} from '../../model/purchase-order/PurchaseOrder';
import {PurchaseOrderStatus, PaymentProcessStatus, PaymentOutcomeStatus} from '../../model/constants/Constants';
import {SupplierInventory} from '../../model/inventory/SupplierInventory';
import {IPaymentStatusCount} from '../../model/purchase-invoice/purchaseInvoice';
import {SelectedCreditNotes} from '../../model/credit-notes/SelectedCreditNotes';
import {IAppliedCredit} from '../../model/credit-notes/CreditClaims';
import {IConversationChatbox} from '../../model/conversation/Conversation';
import {IARBatchResponse} from '../../model/accounts-receivable/batch';

export interface IPurchasingState {
  isShowRequisitionDetailsDialog: boolean;
  productToAdd: SupplierInventory | undefined;
  purchaseOrder: Partial<IPurchaseOrder> | undefined;
  requisition: Partial<IRequisition> | undefined;
  statusCounters: IRequisitionActionOption[];
  transactions: Partial<IRequisition[]>;
  poToCopyFrom: IRequisition | undefined;
  invoicesToPay: IConnectedSupplierStatementSummary[];
  paymentProcessStatus: PaymentProcessStatus;
  paymentOutcome: PaymentOutcomeStatus | undefined;
  paymentStatusCounts: IPaymentStatusCount | undefined;
  creditsToApply: Partial<SelectedCreditNotes>[];
  creditStatementSummary: IAppliedCredit | undefined;
  isLoyaltyPointsToggleOn: boolean;
  conversations: IConversationChatbox[];
  psblBatch: IARBatchResponse | undefined;
  totalInvoiceAmount: number;
  totalCreditAndClaimsSelected: 0;
  totalPrepaymentsSelected: 0;
  creditAndClaimsList: [];
}

export interface IPurchasingContextType extends IPurchasingState {
  addOrUpdateOrderLine: (product: SupplierInventory, supplier?: ISupplierMarket, manualQtyt?: number) => void;
  deleteRequisition: (requisition: Partial<IRequisition> | undefined) => void;
  removeLine: (line: IRequisitionLine, isAvailable?: boolean) => void;
  saveRequisitionLines: () => void;
  searchTransactions: () => void;
  setIsShowRequisitionDetailsDialog: (value: boolean, poToCopyFrom?: IRequisition) => void;
  setPurchaseOrder: (poId: number | undefined) => void;
  setRequisition: (value: Partial<IRequisition> | undefined, isSave?: boolean, isClose?: boolean) => void;
  updatePurchaseOrder: (purchaseOrder: Partial<IPurchaseOrderLine> | undefined) => void;
  updatePurchaseOrderStatus: (
    purchaseOrder: Partial<IPurchaseOrder> | undefined,
    status: PurchaseOrderStatus,
    isSavePO: boolean
  ) => void;
  updateRequisitionStatus: (requisition: Partial<IRequisition> | undefined, status: string) => void;
  setInvoicesToPay: (tx: IConnectedSupplierStatementSummary) => void;
  setMultipleInvoicesToPay: (tx: IConnectedSupplierStatementSummary[]) => Promise<any>;
  setPaymentProcessStatus: (status: PaymentProcessStatus) => void;
  setPaymentOutcome: (outcome: PaymentOutcomeStatus) => void;
  resetPaymentProcess: () => void;
  setPaymentStatusCount: (count: IPaymentStatusCount) => void;
  setCreditsToApply: (credit: Partial<SelectedCreditNotes>[]) => void;
  setCreditStatementSummary: (credit: IAppliedCredit | undefined) => void;
  setIsLoyaltyPointsToggleOn: (value: boolean) => void;
  setConversations: (invoicesConversations: IConversationChatbox[]) => void;
  setPsblBatch: (batchRes: IARBatchResponse) => void;
  setCreditAndClaimsList: (creditAndClaims: Partial<SelectedCreditNotes>[]) => void;
}

const PurchasingContext = createContext<IPurchasingContextType>({
  isShowRequisitionDetailsDialog: false,
  productToAdd: undefined,
  purchaseOrder: undefined,
  requisition: undefined,
  statusCounters: [],
  transactions: [],
  poToCopyFrom: undefined,
  invoicesToPay: [],
  paymentProcessStatus: PaymentProcessStatus.NEW,
  paymentOutcome: PaymentOutcomeStatus.APPROVED,
  paymentStatusCounts: undefined,
  creditsToApply: [],
  creditStatementSummary: undefined,
  isLoyaltyPointsToggleOn: false,
  conversations: [],
  psblBatch: undefined,
  totalInvoiceAmount: 0,
  totalCreditAndClaimsSelected: 0,
  totalPrepaymentsSelected: 0,
  creditAndClaimsList: [],
  addOrUpdateOrderLine: (_product: SupplierInventory) => {},
  deleteRequisition: () => {},
  removeLine: (_line: IRequisitionLine) => {},
  saveRequisitionLines: () => {},
  searchTransactions: () => {},
  setIsShowRequisitionDetailsDialog: (_value: boolean) => {},
  setPurchaseOrder: () => {},
  setRequisition: () => {},
  updatePurchaseOrder: () => {},
  updatePurchaseOrderStatus: (
    _purchaseOrder: Partial<IPurchaseOrder> | undefined,
    _status: PurchaseOrderStatus,
    _isSavePO: boolean
  ) => {},
  updateRequisitionStatus: (_requisition: Partial<IRequisition> | undefined, _status: string) => {},
  setInvoicesToPay: (_tx: IConnectedSupplierStatementSummary) => undefined,
  setMultipleInvoicesToPay: (_tx: IConnectedSupplierStatementSummary[]) => Promise.resolve(),
  setPaymentProcessStatus: (_status: PaymentProcessStatus) => undefined,
  setPaymentOutcome: (_outcome: PaymentOutcomeStatus) => undefined,
  resetPaymentProcess: () => undefined,
  setPaymentStatusCount: (_count: IPaymentStatusCount) => undefined,
  setCreditsToApply: (_credit: Partial<SelectedCreditNotes>[]) => undefined,
  setCreditStatementSummary: (_response: IAppliedCredit | undefined) => undefined,
  setIsLoyaltyPointsToggleOn: (_value: boolean) => undefined,
  setConversations: (_conversations: IConversationChatbox[]) => undefined,
  setPsblBatch: (_batchRes: IARBatchResponse) => undefined,
  setCreditAndClaimsList: (_creditAndClaims: Partial<SelectedCreditNotes>[]): void => undefined,
});

export default PurchasingContext;
