import React from 'react';
import {Calendar, Chip} from 'spenda-ui-react';

import {AlertDialogSlideV2} from './AlertDialogSlideV2';
import {ICalendarEvents} from '../../model/service-management/serviceJob';
import {useServiceContext} from '../../context/serviceManagement/ServiceContext';
import LoadingIndicator from '../ui/LoadingIndicator';

interface ICalendarDialogProps {
  onCLose: () => void;
  onDateSelect?: (date: Date) => void;
  isLoading?: boolean;
  calendarEvents: ICalendarEvents[];
  onMonthChange?: (startDate: Date, endDate: Date) => void;
  selectedDate: Date;
}

export default function CalendarDialog(props: ICalendarDialogProps) {
  const {onCLose, calendarEvents, onDateSelect, onMonthChange, isLoading, selectedDate} = props;

  const {serviceJob} = useServiceContext();

  const EventView = ({event}: {event: ICalendarEvents}) => {
    return <Chip color="warning" size="lg" value={event.title} className="bg-warning/20" />;
  };

  return (
    <AlertDialogSlideV2
      headingClassess="justify-start"
      title={serviceJob?.customerName!}
      dialogBodyClassess="px-32 py-4"
      dialogClassess="!mx-5 !w-full !max-w-[96%] xl:!max-w-[1245px] min-h-[90vh]"
      dialogActionsAlignment="justify-start"
      actions={[
        {
          label: 'Go Back',
          variant: 'outlined',
          action: onCLose,
        },
      ]}
    >
      <div className="relative h-full w-full">
        <LoadingIndicator
          isLoading={isLoading}
          size="md"
          position={{
            height: '100% !important',
            display: 'flex',
            position: 'absolute',
            left: '0',
            right: '0',
            marginLeft: 'auto',
            marginRight: 'auto',
            zIndex: 50,
          }}
        />
        <Calendar
          activeMonth={selectedDate}
          events={calendarEvents}
          eventViewComponent={EventView}
          onDateSelect={onDateSelect}
          onMonthChange={onMonthChange}
          containerProps={{
            className: 'h-[calc(80vh-134px)] overflow-y-auto',
          }}
          headerProps={{
            className: 'text-black font-semibold text-lg',
          }}
          gridItemProps={{
            className: 'gap-y-1',
          }}
        />
      </div>
    </AlertDialogSlideV2>
  );
}
