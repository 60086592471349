import React, {useContext, useEffect, useState} from 'react';
import {Switch, Tab, TabPanel, Tabs, TabsBody, TabsHeader, Textarea, Typography} from 'spenda-ui-react';
import {QuotePreviewMainPanel} from './QuotePreviewMainPanel';
import {useHistory} from 'react-router-dom';
import {
  AUTH_SELLING_QUOTES_CREATE,
  AUTH_SELLING_QUOTES_DETAILS,
  AUTH_SELLING_QUOTES_LIST,
} from '../../routes/QuoteManagementRoutes';
import {QuotePreviewFooter} from './QuotePreviewFooter';
import {QuoteContactCustomerModal} from '../../components/dialog/QuoteContactCustomerModal';
import {QuoteCustomerDialog} from '../../components/dialog/QuoteCustomerDialog';
import backButton from '../../assets/png/back-button.png';
import QuoteContext from '../../context/quote-context/QuoteContext';
import useQuotesAPI from '../../services/useQuotesAPI';
import AlertIcon from '../../assets/svg/quotes-management/AlertIcon';
import {QuoteSentSuccessDialog} from '../dialog/QuoteSentSuccessDialog';
import {IQuotePackageSummaryLines} from '../../model/quotes/quotes';
import _ from 'lodash';
import AppContext from '../../context/app/appContext';

export interface ISelectedCustomers {
  emailType: string;
  name: string;
}

const QuotePreviewTabs = (props: {isResendingQuote: boolean}) => {
  const {quotePackage, updateQuotePackage} = useContext(QuoteContext);

  const {tenantInfo, setTenantInfo} = useContext(AppContext);
  const isShowQuantityInQuote = tenantInfo?.TenantUserDetails.IsShowQuantityInQuote;

  // States
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const {getSummaryLines, showQuantityInPreviewQuote, isLoading} = useQuotesAPI();
  const [showQuoteCustomerDialog, setShowQuoteCustomerDialog] = useState<boolean>(false);
  const [showContactCustomerDialog, setShowContactCustomerDialog] = useState<boolean>(false);
  const [externalNote, setExternalNote] = useState<string>();
  const [tabs, setTabs] = useState<{label: string; value: number; quoteId: number; isUpdated: boolean}[]>();
  const [disableContinue, setDisableContinue] = useState<boolean>(false);
  const [isShowQuoteSentDialog, setIsShowQuoteSentDialog] = useState<boolean>(false);
  const [quotePckgSummaryLines, setQuotePckgSummaryLines] = useState<IQuotePackageSummaryLines[]>([]);

  // UseHistory
  const history = useHistory();
  const quotesDescriptionValidation = async () => {
    if (quotePackage?.quotes?.length) {
      let isDescriptionEmpty = false;
      await Promise.all(
        quotePckgSummaryLines?.map(async quote => {
          const lines = quote.lines;
          if (lines.filter(line => line?.description.length === 0).length > 0) {
            isDescriptionEmpty = true;
          }
        }),
      );
      setDisableContinue(isDescriptionEmpty);
    }
  };

  const setEditedTabs = (quoteId: number, quoteIndex: number) => {
    if (quoteId && tabs?.length && !tabs?.[quoteIndex].isUpdated) {
      const tabsData = [...tabs];
      tabsData[quoteIndex].isUpdated = true;
      setTabs(tabsData);
    }
  };

  const getPackageSummaryLines = async () => {
    if (quotePackage?.quotes?.length) {
      const result = await Promise.all(
        quotePackage?.quotes?.map(async quote => {
          const lines = await getSummaryLines(quote.quoteID!);
          return {quoteId: quote.quoteID!, lines};
        }),
      );
      setQuotePckgSummaryLines(result);
    }
  };

  useEffect(() => {
    setExternalNote(quotePackage?.externalNote);
    getPackageSummaryLines();
    if (!tabs) {
      const quotes = quotePackage?.quotes?.map((quote, index) => {
        return {label: quote.name, value: index, quoteId: quote.quoteID!, isUpdated: false};
      });
      setTabs(quotes);
    }
  }, [quotePackage]);

  useEffect(() => {
    quotesDescriptionValidation();
  }, [_.cloneDeep(quotePckgSummaryLines)]);

  // Function
  const handleClose = () => {
    props.isResendingQuote
      ? history.push(`${AUTH_SELLING_QUOTES_DETAILS}${quotePackage?.quotePackageID}`)
      : history.push(AUTH_SELLING_QUOTES_LIST);
  };

  const handleQuantityToggle = async () => {
    await showQuantityInPreviewQuote({IsShowQuantityInQuote: !isShowQuantityInQuote});
    if (tenantInfo) {
      setTenantInfo({
        ...tenantInfo,
        TenantUserDetails: {
          ...tenantInfo.TenantUserDetails,
          IsShowQuantityInQuote: !isShowQuantityInQuote,
        },
      });
    }
  };

  return (
    <>
      <div className="absolute left-2.5 flex flex-row items-center">
        <span
          className="cursor-pointer"
          data-autoid="btnBack"
          onClick={() => {
            props.isResendingQuote
              ? history.push(`${AUTH_SELLING_QUOTES_DETAILS}${quotePackage?.quotePackageID}`)
              : history.push(`${AUTH_SELLING_QUOTES_CREATE}${quotePackage?.quotePackageID}`);
          }}
        >
          <img
            src={backButton}
            alt="Back Button"
            style={{width: '40px', height: '40px', margin: '0px 10px 0px 0px'}}
            data-autoid={`imgBack`}
          />
        </span>
        <Typography variant="h2">Preview of customer quote</Typography>
      </div>
      <Tabs value={selectedTab} className="flex w-full flex-col items-center">
        <TabsHeader
          className="h-[40px] w-[550px] bg-[#ECECEC]"
          indicatorProps={{
            className: 'bg-spenda-sBlue shadow-none !text-white',
          }}
        >
          {tabs?.map(({label, value}) => (
            <Tab
              key={value}
              value={value}
              className={`${selectedTab === value ? 'font-medium text-white' : 'font-medium'}`}
              onClick={() => setSelectedTab(value)}
              data-autoid={`tab-${label}`.replace(/ /g, '')}
              tabInnerClass={`${quotePackage?.quotes && quotePackage?.quotes?.length > 3 ? 'max-w-[90px] truncate' : ''}`}
              title={label}
            >
              {label}
            </Tab>
          ))}
        </TabsHeader>
        <TabsBody className="mt-2.5 overflow-y-auto border-t border-[#DCDCDC]">
          {tabs?.map(({value, quoteId}) => (
            <TabPanel key={value} value={value} className="w-full">
              <div className="flex w-full flex-row justify-center gap-10">
                <QuotePreviewMainPanel
                  isDisabledMode={true}
                  quoteID={quoteId}
                  isResendingQuote={props.isResendingQuote}
                  quotePckgSummaryLines={quotePckgSummaryLines}
                  setQuotePckgSummaryLines={setQuotePckgSummaryLines}
                  quoteIndex={value}
                  setEditedTabs={setEditedTabs}
                />
                <div>
                  <QuotePreviewMainPanel
                    isDisabledMode={false}
                    quoteID={quoteId}
                    isResendingQuote={props.isResendingQuote}
                    quotePckgSummaryLines={quotePckgSummaryLines}
                    setQuotePckgSummaryLines={setQuotePckgSummaryLines}
                    quoteIndex={value}
                    setEditedTabs={setEditedTabs}
                    isShowQuantityInQuote={isShowQuantityInQuote}
                  />
                </div>
                <div className="mt-12 flex flex-col">
                  <div className="flex w-full max-w-[350px] flex-col gap-y-3 rounded-md bg-spenda-bckgrndBlue p-2.5">
                    <Typography className="font-medium" variant="small">
                      Editing PDF
                    </Typography>
                    <Typography variant="xsmall">
                      With this feature, you have the ability to fine-tune your quote before sending it through to the
                      customer.
                    </Typography>
                    <Typography variant="xsmall">
                      Any changes that you make to the quote at this point will not be reflected in the Spenda system.
                    </Typography>
                  </div>
                  <div className="mt-5">
                    <Switch
                      id="chqQuantityToggle"
                      data-autoid="chqQuantityToggle"
                      ripple={false}
                      className="h-full w-full checked:bg-[#3C9F78]"
                      containerProps={{
                        className: 'w-11 h-6',
                      }}
                      checked={!isShowQuantityInQuote}
                      circleProps={{
                        className: 'before:hidden left-0.5 border-none',
                      }}
                      label={<Typography className="font-medium text-black-900">Hide Quantity</Typography>}
                      onChange={() => handleQuantityToggle()}
                    />
                  </div>
                  <div className="py-5">
                    <Textarea
                      label="Payment Instructions"
                      color="primary"
                      displayLength
                      value={externalNote}
                      disabled={props.isResendingQuote}
                      onChange={e => setExternalNote(e.target.value)}
                      maxLength={500}
                      data-autoid="txtPaymentInstruction"
                      onBlur={async () => {
                        updateQuotePackage({quotePackageID: quotePackage?.quotePackageID!, externalNote});
                      }}
                      className="min-h-[150px]"
                    />
                  </div>
                  {disableContinue && (
                    <div className="mt-[100px] flex w-[350px] flex-row items-center gap-2 rounded-md bg-[#F4E8D1] p-2.5">
                      <AlertIcon />
                      <Typography variant="xsmall" className="ml-1">
                        Adding description is mandatory in all variants in order to continue to next step.
                      </Typography>
                    </div>
                  )}
                </div>
              </div>
            </TabPanel>
          ))}
        </TabsBody>
      </Tabs>
      <QuotePreviewFooter
        handleCancel={handleClose}
        isLoading={isLoading}
        handleSaveClose={handleClose}
        hideSaveClose={props.isResendingQuote}
        disableButton={disableContinue}
        handleContinue={() => {
          const unchangedVariants = tabs?.filter(tab => tab.isUpdated).length || 0;
          if (unchangedVariants < (tabs?.length || 0) && !props.isResendingQuote) {
            setShowQuoteCustomerDialog(!showQuoteCustomerDialog);
          } else {
            setShowContactCustomerDialog(!showContactCustomerDialog);
          }
        }}
      />

      {/* UnEdited customer dialog */}
      {showQuoteCustomerDialog && (
        <QuoteCustomerDialog
          totalVariants={tabs?.length}
          unchangedVariantsLength={tabs?.filter(tab => tab.isUpdated).length}
          handleContinue={() => {
            setShowQuoteCustomerDialog(false);
            setShowContactCustomerDialog(true);
          }}
          handleGoBack={() => setShowQuoteCustomerDialog(false)}
        />
      )}
      {/* Contact customer dialog */}
      {showContactCustomerDialog && (
        <QuoteContactCustomerModal
          handleClose={() => {
            setShowContactCustomerDialog(false);
          }}
          handleSave={() => {
            setIsShowQuoteSentDialog(true);
            setShowContactCustomerDialog(false);
          }}
        />
      )}
      {isShowQuoteSentDialog && (
        <QuoteSentSuccessDialog
          onDoneClick={() => {
            history.push(AUTH_SELLING_QUOTES_LIST);
          }}
        />
      )}
    </>
  );
};

export default QuotePreviewTabs;
