import ap_batchUploaded from './AP-batchuploaded.png';
import ap_limits from './AP-limits.png';
import ap_paidBatch from './AP-paidbatch.png';
import ap_payment from './AP-payment.png';
import ap_remittance from './AP-remittance.png';
import ap_uploadfile from './AP-uploadfile.png';
import ap_demoImage from './AP-demoImage.png';

export const APOnboardingImages = {
  ap_batchUploaded,
  ap_limits,
  ap_paidBatch,
  ap_payment,
  ap_remittance,
  ap_uploadfile,
  ap_demoImage,
};
