import {startsWith} from 'lodash';
import React, {FunctionComponent} from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import {createAutoIdAttributeFromReactProps} from '../../utils/createAutoIdAttribute';

export interface CountryData {
  name: string;
  dialCode: string;
  countryCode: string;
  format: string;
}

interface ISPhoneInput {
  name: string;
  value?: string | null;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (
    value: string,
    data: CountryData,
    event: React.ChangeEvent<HTMLInputElement>,
    formattedValue: string
  ) => void;
  countryCodeEditable?: boolean;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  theme?: 'primary' | 'form' | 'secondary';
  isValidCallback?: (isValid: boolean) => void;
}

const SPhoneInput: FunctionComponent<ISPhoneInput> = props => {
  const {value, onChange, onKeyDown, onBlur, name} = props;

  const onInputChange = (
    value: string,
    data: CountryData,
    event: React.ChangeEvent<HTMLInputElement>,
    formattedValue: string
  ) => {
    let mobileNum = formattedValue?.replace(new RegExp('[() ]', 'g'), '');

    if (event.nativeEvent) {
      // it means the change is a result of typing in the input

      const dialCodeWith0 = `+${data.dialCode}0`;
      if (mobileNum && mobileNum.startsWith(dialCodeWith0)) {
        mobileNum = mobileNum.replace(dialCodeWith0, `+${data.dialCode}`);
      }
    } else {
      // change is a result of typing selecting a country in the dropdown
      if (value && value.startsWith('0')) {
        mobileNum = data.dialCode + value.substring(1);
      }
    }

    if (onChange) {
      onChange(mobileNum, data, event, formattedValue);
    }
  };

  const ds = {
    border: '1px solid #2c95bc',
    color: '#1C78AD',
    // fontFamily: 'Saira Extra Condensed',
    fontSize: '22px',
    minHeight: '48px',
    maxWidth: '200px',
  };

  const dbs = {
    border: 'none',
    borderRadius: 0,
    borderBottom: '1px solid #000',
    backgroundColor: '#fff',
  };

  const secondaryButton = {
    border: 'none',
    borderRadius: 0,
    backgroundColor: '#f1f1f1',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
  };

  const defaultButtonStyle = {border: `1px solid #1C78AD`};

  const secondaryContainer = {
    borderRadius: 4,
    width: 100,
  };

  const secondaryInput = {
    border: 'none',
    borderRadius: 0,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    width: '204%',
    background: '#f1f1f1',
    fontFamily: 'sans-serif',
    fontSize: '0.9rem',
    height: '38px',
  };

  const defaultStyles =
    props.theme === 'form'
      ? {
          border: 'none',
          borderBottom: '1px solid #000',
          borderRadius: 0,
          // width: "186px",
          fontFamily: 'sans-serif',
          fontSize: '1rem',
        }
      : props.theme === 'secondary'
      ? secondaryInput
      : ds;

  const {autoIdAttribute} = createAutoIdAttributeFromReactProps(props, 'txt');

  return (
    <PhoneInput
      inputProps={{name, ...autoIdAttribute}}
      country="au"
      masks={{au: '... ... ...', nz: '... ... ...'}}
      onlyCountries={['au', 'nz']}
      value={value}
      onChange={onInputChange}
      onBlur={e => {
        if (onBlur) {
          onBlur(e);
        }
      }}
      autoFormat={true}
      disabled={props.disabled}
      placeholder="+61 412 345 678"
      onKeyDown={onKeyDown}
      containerStyle={props.theme === 'secondary' ? secondaryContainer : ds}
      specialLabel="Hello"
      inputStyle={defaultStyles}
      buttonStyle={props.theme === 'form' ? dbs : props.theme === 'secondary' ? secondaryButton : defaultButtonStyle}
      isValid={(inputNumber, country, countries) => {
        const valid = countries.some((country: any) => {
          return (
            (startsWith(inputNumber, country.dialCode) || startsWith(country.dialCode, inputNumber)) &&
            inputNumber !== ''
          );
        });

        if (props.isValidCallback) {
          props.isValidCallback(valid);
        }

        return valid;
      }}
    />
  );
};

export default SPhoneInput;
