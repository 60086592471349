import {errorDetails} from '../../services/useSpendaPaymentServicesAPI';
import {
  PaymentBatchStatusTypes,
  PaymentBatchCrDrStatusTypes,
  LabelPaymentBatchStatusTypes,
  LabelPaymentBatchCrDrStatusTypes,
  SyncStatuses,
  AuthorisationRequestMode,
  TimelineCustomRange,
  PaymentBatchStatusValues,
  AuthorisationStatus,
  PaymentMethodTypes,
} from '../constants/Constants';
import {Authorisers, PaymentAuthorisationDetails} from '../payment/PaymentAuthority';
import {IBatchReviewPurchaseInvoice} from '../purchase-invoice/purchaseInvoice';

export interface IPaymentBatchCreditLine {
  bankAccountNumber?: string;
  bankBSB?: string;
  description?: string;
  isNovelBankAccount?: boolean;
  isBankAccountImported?: boolean;
  isNovelSupplier?: boolean;
  modifiedByUserID?: any;
  modifiedByUserName?: string;
  modifiedDateTime_utc?: Date;
  paymentAmount?: number;
  status: PaymentBatchCrDrStatusTypes;
  supplierID?: any;
  supplierName: string;
  transactionGUID?: string;
  mappedStatus?: LabelPaymentBatchCrDrStatusTypes;
  payeeID?: number;
  emailAddress?: string;
  defaultDescription?: string;
  errorCode?: string;
  errorMessage?: string;
  purchaseInvoices?: IBatchReviewPurchaseInvoice[];
  action?: IPurchaseInvoiceBatchActions;
  debitRefNo?: string;
  balance?: number;
  totalInc?: number;
}

export interface IPaymentBatchDebitLine {
  cardType: string;
  cardholderName?: string;
  createdByUserID?: any;
  createdDateTime_utc?: Date;
  expiry?: string;
  last4?: string;
  modifiedByUserID?: any;
  modifiedDateTime_utc?: Date;
  payerFeeAmount?: number;
  payerFeeDecimal?: number | null;
  paymentAccountGUID: string;
  paymentAmount?: number;
  paymentMethodID?: any;
  status?: PaymentBatchCrDrStatusTypes;
  transactionGUID?: string;
  payerFeeDescription?: string;
  bankAccountName?: string;
  bankIdentificationNumber?: string;
  bankBSB?: string;
  bankAccountNumber?: string;
  isAuthorisationRequired?: boolean;
  isCurrentUserAnAuthoriser?: boolean;
  paymentMethodName: PaymentMethodTypes;
  friendlyName: string;
  errorCode?: string;
  errorMessage?: string;
  paymentAmountIncFees?: number;
}
export interface PaymentAuthorisationRule {
  authorisationRule: {
    authorisers: Authorisers[];
    requiredApprovalCount: number;
    requestMode: AuthorisationRequestMode;
  };
}
export interface IPaymentBatchForApproval extends PaymentAuthorisationRule {
  abaFileID?: any;
  abaFileName?: string;
  accountsPayableBatchID: any;
  balance: number;
  batchApprovedByUserID: any | null;
  batchApprovedByUserName: string | null;
  createdByUserID: any;
  createdByUserName: string;
  createdDateTime_utc: Date;
  countCredits: number;
  dueDate: Date;
  name: string;
  paymentAmount: number;
  paymentApprovedByDateTime_utc: Date | null;
  batchApprovedDateTime_utc: Date | null;
  refNumber: string;
  status: PaymentBatchStatusTypes;
  mappedStatus?: LabelPaymentBatchStatusTypes;
  syncLogs?: ISyncLogs[];
  debits: IPaymentBatchDebitLine[];
  authorisation?: PaymentAuthorisationDetails;
  authorisationStatus?: AuthorisationStatus;
}
export interface IPaymentBatchActions {
  actionName: string;
  actionResult: boolean;
  actionDateTime_utc: Date;
  actionUserID: number;
  actionUserName: string;
  attributeName: string;
  oldValue: string;
  newValue: string;
}
export interface ISyncLogs {
  accountsPayableBatchID: number;
  adaptorID: number;
  bsid: string;
  details: string;
  lastExportDateTime_offset: string;
  lastExportDateTime_utc: string;
  lastImportDateTime_offset: string;
  lastImportDateTime_utc: string;
  status: SyncStatuses;
  syncLogCode: number;
}

export interface IDebitNote {
  balance?: number;
  debitNoteID: number;
  dueDate: string;
  lineCount: number;
  linkedDocumentGUID: string;
  purchaseInvoices: IBatchReviewPurchaseInvoice[];
  refNumber: string;
  status: PaymentBatchCrDrStatusTypes;
  supplierID: number;
  supplierName: string;
  supplierRefNumber: string | null;
  totalInc: number;
  isLinked?: boolean;
}
export interface IPaymentBatch {
  abaFileID: any;
  batchApprovedByDateTime_utc: Date;
  batchApprovedByUserID: any;
  batchApprovedByUserName: string;
  batchGUID: string;
  countCredits: number;
  createdByUserID: any;
  createdByUserName: string;
  createdDateTime_utc: Date;
  credits: IPaymentBatchCreditLine[];
  debits: IPaymentBatchDebitLine[];
  debitNotes: IDebitNote[];
  dueDate: Date | string;
  id: any;
  name: string;
  paymentAmount: number;
  paymentApprovedByUserID: any;
  paymentApprovedByUserName: string;
  paymentApprovedDateTime_utc: Date;
  paymentSubmittedDateTime_utc: Date | null;
  refNumber: string;
  status: PaymentBatchStatusTypes;
  mappedStatus?: LabelPaymentBatchStatusTypes;
  actions?: IPaymentBatchActions[];
  purchaseInvoices?: IBatchPurchaseInvoice[];
  accountsPayableBatchID?: any;
  syncLogs?: ISyncLogs[];
  authorisation?: PaymentAuthorisationDetails;
  authorisationID?: number;
  authorisationStatus?: AuthorisationStatus;
  linkedDocumentGUID?: null;
}

export interface IBatchPurchaseInvoice {
  balance: number;
  linkedDocumentGUID: string;
  purchaseInvoiceID: number;
  refNumber: string;
  sourceSupplierID: number;
  sourceSupplierName: string;
  supplierID: number;
  supplierName: string;
}

export interface IBatchPaymentRequest {
  id: string;
  name: string;
  dueDate: Date | string;
  purchaseInvoices: IPurchaseInvoiceRequest[];
  debitNoteAllocations: IDebitNoteRequest[];
}

export interface IUpcomingPaymentBatch {
  abaFileID?: number;
  accountsPayableBatchID: any;
  dueDate: string;
  name: string;
  paymentAmount: number;
  refNumber: string;
}

export interface ISubmitBatchPaymentResponse extends errorDetails {
  value: string;
}

export interface IAccountPayeesDetails {
  payeeID?: number;
  emailAddress?: string;
  defaultDescription?: string;
}

export enum IPurchaseInvoiceBatchActions {
  Add = 'Add',
  Remove = 'Remove',
  Update = 'Update',
}

export interface IPurchaseInvoiceRequest {
  PurchaseInvoiceID: number;
  AppliedAmount?: number;
  Action: IPurchaseInvoiceBatchActions;
}

export interface IDebitNoteRequest {
  DebitNoteID: number;
  Action: IPurchaseInvoiceBatchActions;
}

export interface IPurchaseInvoiceBatch {
  id?: string;
  Name: string;
  DueDate: Date | string;
  PurchaseInvoices: IPurchaseInvoiceRequest[];
  DebitNoteAllocations?: IDebitNoteRequest[];
}
export interface IUserDefaultViewSettings {
  defaultSearchStatusFilters: PaymentBatchStatusValues[];
  defaultTimelineGroupsFilter: TimelineCustomRange;
}
