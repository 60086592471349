import React, { useEffect } from "react";
import debounce from "lodash/debounce";

export const useInfiniteScroll = (loadMore: () => Promise<void>) => {
    const elementRef = React.useRef<HTMLElement>();

    const [isBottom, setIsBottom] = React.useState(true);
    const [scrolling, setScrolling] = React.useState(false);
    
    useEffect(() => {
      async function loadItems() {
        setScrolling(true);
        await loadMore();
        setTimeout(() => setScrolling(false), 1000);
      }
      if(isBottom) {
        loadItems();
        setIsBottom(false);
      }
    }, [isBottom, loadMore]);
  
    const handleScroll = debounce(() => {
      
      const scroller = elementRef.current;
      if (scroller && scroller.scrollTop > 0 && Math.floor(scroller.scrollHeight - scroller.scrollTop) === scroller.clientHeight) {
        setIsBottom(true);
      }
    }, 750);
  
    return {elementRef, handleScroll, scrolling};
  }