import React from 'react';

export function CloseJobIcon(props: SVGProps) {
  return (
    <svg width={82} height={82} viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_5591_26410)" className="fill-primary">
        <path d="M28.35 51.12h7.59c2.79 0 5.06-2.27 5.06-5.06v-1.51l4.959-.853a2.528 2.528 0 002.062-2.923 2.51 2.51 0 00-2.922-2.064l-4.365.751c-.655-2.034-2.545-3.519-4.794-3.519h-7.59c-2.25 0-4.137 1.485-4.794 3.517L7.69 36.714a2.538 2.538 0 01-2.11-2.492v-5.87c0-4.185 3.405-7.59 7.59-7.59h37.95c4.185 0 7.59 3.405 7.59 7.59v5.186a2.53 2.53 0 105.06 0v-5.186c0-6.975-5.675-12.65-12.65-12.65h-7.59v-2.53c0-4.185-3.405-7.59-7.59-7.59h-7.59c-4.185 0-7.59 3.405-7.59 7.59v2.53h-7.59C6.195 15.7.52 21.374.52 28.349v5.87c0 2.219.972 4.23 2.53 5.63v13.8c0 6.976 5.675 12.65 12.65 12.65h23.276a2.53 2.53 0 100-5.06H15.7c-4.185 0-7.59-3.405-7.59-7.59V41.92l15.18 2.627v1.513c0 2.79 2.27 5.06 5.06 5.06zm0-10.12h7.59v5.06h-7.59V41zm-2.53-27.83a2.533 2.533 0 012.53-2.53h7.59a2.533 2.533 0 012.53 2.53v2.53H25.82v-2.53z" />
        <path d="M63.77 41c-9.763 0-17.71 7.944-17.71 17.71 0 9.766 7.947 17.71 17.71 17.71 9.763 0 17.71-7.944 17.71-17.71 0-9.766-7.947-17.71-17.71-17.71zm0 30.36c-6.975 0-12.65-5.675-12.65-12.65 0-6.975 5.675-12.65 12.65-12.65 6.975 0 12.65 5.675 12.65 12.65 0 6.975-5.675 12.65-12.65 12.65z" />
        <path d="M67.041 54.054l-4.958 4.956-1.584-1.584a2.527 2.527 0 00-3.578 0c-.989.99-.989 2.589 0 3.578l3.373 3.372a2.523 2.523 0 001.789.742c.647 0 1.295-.248 1.788-.742l6.748-6.747c.989-.987.989-2.588 0-3.578a2.527 2.527 0 00-3.578.003z" />
      </g>
      <defs>
        <clipPath id="clip0_5591_26410">
          <path fill="#fff" transform="translate(.52 .52)" d="M0 0H80.96V80.96H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
