import useHttp from '../hooks/useHttp';
import {ILastSyncDateReponse, IWorkflowStatusResponse} from '../model/workflow/WorkflowResponse';
import {IActionResults} from '../model/ActionResults';
import {ISupplierRefreshResponse} from './useSupplierTransactionsAPI';

const useWorkflowAPI = () => {
  const {POST, GET, isLoading} = useHttp();

  const synkDataRead = (
    IsSyncDependencies: boolean,
    Action: string,
    AdaptorName: string,
    AdaptorID: number,
    DatTypeID: number,
    MaxWaitSecs: number = 13,
    IsImportUnPaidInvoicesOnly: boolean = false,
    LastExecDateTimeOverride_utc: string = '',
    CustomerIDs?: number[],
  ): Promise<any> => {
    const payload = {
      IsSyncDependencies,
      Action,
      AdaptorName,
      AdaptorID,
      DatTypeID,
      MaxWaitSecs,
      IsImportUnPaidInvoicesOnly,
      LastExecDateTimeOverride_utc,
      CustomerIDs: CustomerIDs,
    };
    if (!CustomerIDs?.length) {
      delete payload.CustomerIDs;
    }
    return POST('workflow/queue', payload).then((data: any) => data);
  };

  const checkWorkflowStatus = (workflowId: number): Promise<IWorkflowStatusResponse> => {
    return GET(`workflow/${workflowId}`).then((data: IActionResults<any>) => (data.Value ? data.Value : undefined));
  };

  const lastSyncDate = (datTypeId: number): Promise<ILastSyncDateReponse[]> => {
    return GET(`Spenda/Tenant/Workflows/GetWorkflows?DatTypeID=${datTypeId}`);
  };

  const queueWorkflow = async (payload: any) => {
    return POST('workflow/queue', payload).then((res: IActionResults<ISupplierRefreshResponse>) => res.Value);
  };

  return {
    synkDataRead,
    checkWorkflowStatus,
    lastSyncDate,
    queueWorkflow,
    isLoading,
  };
};

export default useWorkflowAPI;
