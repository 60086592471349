import React from 'react';

export const CaretUp = (props: {width?: string; height?: string; name?: string; className?: string}) => {
  return (
    <svg
      width={props.width || '16px'}
      height={props.height || '10px'}
      className={props.className}
      viewBox="0 0 16 10"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>{props.name}</title>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="caret_down"
          transform="translate(8.027227, 4.995361) scale(1, -1) translate(-8.027227, -4.995361) translate(0.343750, 0.570312)"
          className="fill-primary"
          fillRule="nonzero"
        >
          <path
            d="M1.16181021,0 L14.2051435,0 C14.676995,0.0019784023 15.1011967,0.28798845 15.2799561,0.724672246 C15.4587155,1.16135604 15.3568318,1.6627227 15.0218102,1.995 L8.51181021,8.505 C8.29274917,8.72586476 7.994554,8.85009805 7.68347687,8.85009805 C7.37239975,8.85009805 7.07420457,8.72586476 6.85514354,8.505 L0.345143539,1.995 C0.0101219379,1.6627227 -0.0917618033,1.16135604 0.0869976452,0.724672246 C0.265757094,0.28798845 0.689958785,0.0019784023 1.16181021,0 Z"
            id="Path"
          ></path>
        </g>
      </g>
    </svg>
  );
};
