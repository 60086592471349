import React, {ChangeEvent, FunctionComponent, useEffect, useState} from 'react';
import {IUserAccountInfo, UserRoleEnum} from '../../model/user/UserAccountInfo';
import {Formik, FormikProps} from 'formik';
import {makeStyles} from '@material-ui/core';
import {Input, Switch, Typography, Select, Option, Button} from 'spenda-ui-react';
import * as Yup from 'yup';
import {IUser} from '../../model/user/User';
import SPhoneInput from '../inputs/SPhoneInput';
import {AlertDialog, AlertDialogSlideV2, IDialogActionV2} from './AlertDialogSlideV2';
import {useFeatureFlags} from '../../hooks/useFeatureFlags';

interface IEditUserDialogProps {
  title: string;
  actions: IDialogActionV2[];
  user?: IUserAccountInfo;
  onSaveUser?: (user?: IUserAccountInfo, isLogoutAfterSaving?: boolean) => void;
  updateLoggedInUser: (user: Partial<IUser>) => void;
  isEditable?: boolean;
  currentUserID?: number;
  currentPrimaryUser?: IUserAccountInfo | undefined;
  paymentAuth72488?: boolean;
  isAPOnboardingV1?: boolean;
  showFooterNewDesign?: boolean;
}

const EditUserDialog: FunctionComponent<IEditUserDialogProps> = ({
  title,
  actions,
  user,
  onSaveUser,
  isEditable,
  updateLoggedInUser,
  currentUserID,
  currentPrimaryUser,
  paymentAuth72488,
  isAPOnboardingV1,
  showFooterNewDesign,
}) => {
  const {UserID} = user as IUserAccountInfo;
  const [isDisableUserConfirmationVisible, setIsDisableUserConfirmationVisible] = useState(false);
  const [isPrimaryUserConfirmationVisible, setIsPrimaryUserConfirmationVisible] = useState(false);
  const [isPrimaryUserWarningVisible, setIsPrimaryUserWarningVisible] = useState(false);
  const {serviceManagementV1} = useFeatureFlags().tenantOwned();

  const onSubmit = (editedUser?: IUserAccountInfo) => {
    if (user?.UserID === currentUserID && user?.IsEnabled && !editedUser?.IsEnabled) {
      // As a primary/admin/support trying to disabled my own account

      // Display confirmation dialog
      setIsDisableUserConfirmationVisible(true);
      return;
    }

    if (!user?.IsPrimaryContact && editedUser?.IsPrimaryContact) {
      // Setting an user as the new primary user, display confirmation

      // Display confirmation dialog
      setIsPrimaryUserConfirmationVisible(true);
      return;
    }

    if (user?.IsPrimaryContact && !editedUser?.IsPrimaryContact) {
      // Trying to change the role of a primary user to something else

      // Display warning
      setIsPrimaryUserWarningVisible(true);
      return;
    }

    if (onSaveUser) {
      if (serviceManagementV1) {
        if (!editedUser) return;
        let userRoles = [...editedUser.UserRoles];
        const isAlreadyServiceManager = userRoles.find(role => role.UserRoleEnum === UserRoleEnum.ServiceManager);
        if (editedUser.isServiceManagementVisible && !isAlreadyServiceManager) {
          userRoles = [
            ...editedUser?.UserRoles,
            {
              ModuleID: 0,
              Name: UIUserRoles.ServiceManager,
              SelectedForUser: false,
              UserRoleEnum: UserRoleEnum.ServiceManager,
            },
          ];
        } else if (!editedUser.isServiceManagementVisible && isAlreadyServiceManager) {
          userRoles = [...editedUser?.UserRoles].filter(ur => ur.UserRoleEnum !== UserRoleEnum.ServiceManager);
        }
        const updatedEditedUser = {
          ...editedUser,
          UserRoles: userRoles,
        };
        onSaveUser(updatedEditedUser as IUserAccountInfo);
      } else onSaveUser(editedUser);
    }
  };

  const validationSchema = Yup.object({
    FirstName: Yup.string().required('First Name is required').max(50, 'Character limit exceeded'),
    LastName: Yup.string().required('Last Name is required').max(50, 'Character limit exceeded'),
    EmailAddress: Yup.string().email('Email is not valid').required('Email address is required'),
  });

  return (
    // @ts-ignore
    <Formik initialValues={user as IUserAccountInfo} validationSchema={validationSchema} onSubmit={onSubmit}>
      {(props: FormikProps<IUserAccountInfo>) => (
        <>
          <AlertDialogSlideV2
            id="userDialog"
            title={title}
            headingTextSize="h1"
            dialogClassess="!w-[805px] !min-w-[805px] !w-max-[805px]"
            dialogBodyClassess="min-h-[400px]"
            dialogActionsAlignment={showFooterNewDesign ? 'justify-between' : 'justify-end'}
            footer={
              showFooterNewDesign ? (
                <>
                  {actions.map((a, index) => (
                    <React.Fragment key={index}>
                      <Button
                        onClick={a.action}
                        disabled={a.disabled}
                        hidden={a.hidden}
                        className={`${a.className} ${a.variant === 'outlined' ? ' bg-white focus:ring-0' : ''}`}
                        variant={a.variant}
                        color={a.color ?? 'primary'}
                        loading={a.loading}
                      >
                        {a.label}
                      </Button>
                    </React.Fragment>
                  ))}
                  {!isAPOnboardingV1 && (
                    <Button
                      variant="outlined"
                      onClick={() => {
                        props.setFieldValue('IsSendWelcomeInvite', false);
                        props.handleSubmit();
                      }}
                    >
                      Save & Close
                    </Button>
                  )}
                  <Button
                    onClick={() => {
                      props.setFieldValue('IsSendWelcomeInvite', true);
                      props.handleSubmit();
                    }}
                  >
                    {UserID ? 'Resend Invite' : 'Send Invite'}
                  </Button>
                </>
              ) : null
            }
            actions={[
              ...actions,
              {
                label: 'Save & Close',
                className: 'ml-2',
                action: e => {
                  props.setFieldValue('IsSendWelcomeInvite', false);
                  props.handleSubmit(e);
                },
              },
              {
                label: UserID ? 'Resend Invite' : 'Send Invite',
                className: 'ml-2',
                action: e => {
                  props.setFieldValue('IsSendWelcomeInvite', true);
                  props.handleSubmit(e);
                },
                disabled: !props.values.IsEnabled,
                hidden: !user?.IsNewUnconfirmedUser,
              },
            ]}
            overflowY="scroll"
            paddingX={20}
          >
            <EditUserForm
              {...props}
              isMobileNumber={!!user?.Mobile}
              updateLoggedInUser={updateLoggedInUser}
              isEditable={isEditable}
              currentUserID={currentUserID}
              paymentAuth72488={paymentAuth72488}
              isAPOnboardingV1={isAPOnboardingV1}
              serviceManagementV1={serviceManagementV1}
            />
          </AlertDialogSlideV2>
          {isDisableUserConfirmationVisible && (
            <AlertDialog
              title="Disable my account"
              actions={[
                {
                  label: 'No',
                  variant: 'outlined',
                  action: e => {
                    setIsDisableUserConfirmationVisible(false);
                    props.setFieldValue('IsActive', false);
                    props.handleReset(e);
                  },
                },
                {
                  label: 'Yes',
                  action: () => {
                    setIsDisableUserConfirmationVisible(false);
                    if (onSaveUser) onSaveUser(props.values, true);
                  },
                },
              ]}
              content="Are you sure you want to disable your own account? You will be logged out and unable to access your
              account again."
            />
          )}

          {isPrimaryUserConfirmationVisible && (
            <AlertDialog
              title="Confirm Primary User Change"
              actions={[
                {
                  label: 'No',
                  action: () => setIsPrimaryUserConfirmationVisible(false),
                  variant: 'outlined',
                },
                {
                  label: 'Yes',
                  action: () => {
                    setIsPrimaryUserConfirmationVisible(false);
                    if (onSaveUser) onSaveUser(props.values);
                  },
                },
              ]}
              content={`You can only have one Primary User within your tenancy. Would you like to make ${
                props.values.FirstName || ''
              } ${props.values.LastName || ''} your Primary User and change ${currentPrimaryUser?.FirstName || ''} ${
                currentPrimaryUser?.LastName || ''
              } to an Admin?`}
            />
          )}

          {isPrimaryUserWarningVisible && (
            <AlertDialog
              title="Warning!"
              size="sm"
              dialogActionsAlignment="justify-end"
              actions={[
                {
                  label: 'Ok',
                  action: () => {
                    setIsPrimaryUserWarningVisible(false);
                    props.setFieldValue('IsPrimary', true);
                  },
                },
              ]}
              content="You must promote another user to Primary first."
            />
          )}
        </>
      )}
    </Formik>
  );
};

export interface IEditUserProps {
  FirstName: string;
  LastName: string;
  Position: string;
  EmailAddress: string;
  Mobile: string;
}

enum UIUserRoles {
  Primary = 'Primary',
  Admin = 'Administrator',
  Standard = 'Standard',
  Support = 'Support',
  Technician = 'Technician',
  ServiceManager = 'Service Manager',
}

const EditUserForm: FunctionComponent<
  FormikProps<IUserAccountInfo> & {
    isEditable?: boolean;
    currentUserID?: number;
    updateLoggedInUser: (user: Partial<IUser>) => void;
    isMobileNumber?: boolean;
    paymentAuth72488?: boolean;
    isAPOnboardingV1?: boolean;
    serviceManagementV1?: boolean;
  }
> = props => {
  const {
    errors,
    handleChange,
    handleSubmit,
    setFieldTouched,
    setFieldValue,
    touched,
    values: {
      FirstName,
      LastName,
      Position,
      EmailAddress,
      Mobile,
      IsEnabled,
      isServiceManagementVisible,
      IsGetNotificationEmailAlerts,
      IsPrimaryContact,
      UserRoles,
      UserID,
    },
    paymentAuth72488,
    isEditable,
    currentUserID,
    isMobileNumber,
    isAPOnboardingV1,
    serviceManagementV1,
  } = props;

  const useStyles = makeStyles(_ => ({
    root: {
      '& .MuiFormControl-root': {
        width: '228px',
        margin: '12px 20px 12px 0px',
      },
      '& .MuiFormLabel-root': {
        fontFamily: 'poppins!important',
        '&.Mui-focused': {
          color: 'hsl(var(--primary))',
        },
      },
      '& .react-tel-input ': {
        width: '100% !important',
        '& input': {
          width: '100% !important',
          borderRadius: '4px !important',
          background: 'transparent !important',
          height: '45px !important',
          border: '1px solid #333 !important',
          fontSize: '14px',
          fontWeight: 600,
        },
        '& .flag-dropdown': {
          borderRadius: '0px',
          backgroundColor: 'transparent !important',
          borderRight: '1px solid #333',
        },
        '& legend': {
          fontSize: '15px',
        },
        '& #companyName-helper-text': {
          fontFamily: 'poppins !important',
        },
        '& .selected-flag:hover, .selected-flag:focus, .flag-dropdown.open .selected-flag': {
          backgroundColor: 'transparent',
        },
      },
    },
  }));

  let initialUserRole = UIUserRoles.Standard;

  if (IsPrimaryContact) {
    initialUserRole = UIUserRoles.Primary;
  } else if (UserRoles.some(ur => ur.UserRoleEnum === UserRoleEnum.Administrator)) {
    initialUserRole = UIUserRoles.Admin;
  } else if (UserRoles.some(ur => ur.UserRoleEnum === UserRoleEnum.Support)) {
    initialUserRole = UIUserRoles.Support;
  } else if (UserRoles.some(ur => ur.UserRoleEnum === UserRoleEnum.Technician)) {
    initialUserRole = UIUserRoles.Technician;
  }

  const items = [
    {
      desc: 'Primary User is generally your Business Manager and can perform all functions in Spenda. You can only have one Primary User.',
      value: UIUserRoles.Primary,
    },
    {
      desc: 'Admin Users can perform functions like adding new users, editing inventory, pricing and manage classes.',
      value: UIUserRoles.Admin,
    },
    {
      desc: isAPOnboardingV1
        ? ' Standared users can now perform functions like editing inventory, pricing and manage classes.'
        : 'Standard users are not able to perform functions like editing inventory, pricing and manage classes.',
      value: UIUserRoles.Standard,
    },
    {
      desc: 'These Users are the Spenda Support Team and get added automatically to every tenant. They can perform all functions to help you out when you get stuck.',
      value: UIUserRoles.Support,
    },
  ];
  const techIndex = items.findIndex(item => item.value === UIUserRoles.Technician);
  if (serviceManagementV1 && techIndex === -1) {
    items.push({
      desc: 'Technician are usually sub-contractors.',
      value: UIUserRoles.Technician,
    });
  } else if (!serviceManagementV1 && techIndex !== -1) {
    items.splice(techIndex, 1);
  }

  const [selectedRole, setSelectedRole] = useState(items.find(i => i.value === initialUserRole) || items[0]);
  const isMe = currentUserID === UserID;

  useEffect(() => {
    const hasUserRoleEnum = UserRoles.some(ur => ur.UserRoleEnum === UserRoleEnum.ServiceManager);
    setFieldValue('isServiceManagementVisible', hasUserRoleEnum);
  }, []);

  useEffect(() => {
    const alreadyHasAdminModule = UserRoles.some(ur => ur.UserRoleEnum === UserRoleEnum.Administrator);
    const alreadyHasSupportModule = UserRoles.some(ur => ur.UserRoleEnum === UserRoleEnum.Support);
    const alreadyHasTechnicianModule = UserRoles.some(ur => ur.UserRoleEnum === UserRoleEnum.Technician);

    let updatedUserRoles = [...UserRoles];
    let isPrimaryContact = false;
    switch (selectedRole.value) {
      case UIUserRoles.Primary:
        isPrimaryContact = true;
        break;
      case UIUserRoles.Admin:
        if (!alreadyHasAdminModule) {
          updatedUserRoles = [
            ...UserRoles,
            {ModuleID: 0, Name: 'Administrator', SelectedForUser: false, UserRoleEnum: UserRoleEnum.Administrator},
          ];
          if (alreadyHasSupportModule || alreadyHasTechnicianModule) {
            updatedUserRoles = updatedUserRoles
              .filter(ur => ur.UserRoleEnum !== UserRoleEnum.Support)
              .filter(ur => ur.UserRoleEnum !== UserRoleEnum.Technician);
          }
        }
        break;
      case UIUserRoles.Support:
        if (!alreadyHasSupportModule) {
          updatedUserRoles = [
            ...UserRoles,
            {ModuleID: 0, Name: 'Support', SelectedForUser: false, UserRoleEnum: UserRoleEnum.Support},
          ];
        }
        break;
      case UIUserRoles.Standard:
        if (alreadyHasAdminModule || alreadyHasSupportModule || alreadyHasTechnicianModule) {
          updatedUserRoles = UserRoles.filter(ur => ur.UserRoleEnum !== UserRoleEnum.Administrator)
            .filter(ur => ur.UserRoleEnum !== UserRoleEnum.Support)
            .filter(ur => ur.UserRoleEnum !== UserRoleEnum.Technician);
        }
        break;
      case UIUserRoles.Technician:
        if (!alreadyHasTechnicianModule) {
          updatedUserRoles = [
            ...UserRoles,
            {ModuleID: 0, Name: 'Technician', SelectedForUser: false, UserRoleEnum: UserRoleEnum.Technician},
          ];
          if (alreadyHasAdminModule || alreadyHasSupportModule) {
            updatedUserRoles = updatedUserRoles
              .filter(ur => ur.UserRoleEnum !== UserRoleEnum.Administrator)
              .filter(ur => ur.UserRoleEnum !== UserRoleEnum.Support);
          }
        }
        break;
    }

    setFieldValue('IsPrimaryContact', isPrimaryContact);
    setFieldValue('UserRoles', updatedUserRoles);
  }, [selectedRole]);

  const onChange = (name: keyof IEditUserProps, e: {persist: () => void}) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  const onInActive = () => {
    setFieldValue('IsEnabled', false);
    setFieldValue('IsGetNotificationEmailAlerts', false);
    setFieldValue('isServiceManagementVisible', false);
    // setFieldValue("IsActive", false)
  };

  const onMobileChange = (value: string): void => {
    setFieldValue('Mobile', value);
  };

  const onOptionChanged = (value: string) => {
    setSelectedRole(items.find(i => i.value === value) || items[0]);
    if (value === UIUserRoles.Technician) {
      setFieldValue('isServiceManagementVisible', true);
    } else {
      setFieldValue('isServiceManagementVisible', false);
    }
  };

  const handleSwitchChange = () => {
    setFieldValue('IsGetNotificationEmailAlerts', !IsGetNotificationEmailAlerts);
  };

  const handleServiceMgmtSwitchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFieldValue('isServiceManagementVisible', e.target.checked);
  };
  const classes = useStyles();
  return (
    <form onSubmit={handleSubmit} className="max-w-[760px] px-2">
      <section className={`${classes.root} mt-4 grid grid-cols-3 gap-5`}>
        <Input
          id="FirstName"
          name="FirstName"
          error={touched.FirstName && Boolean(errors.FirstName)}
          type="string"
          value={FirstName}
          helperText={touched.FirstName ? errors.FirstName : ''}
          onChange={onChange.bind(null, 'FirstName')}
          disabled={!isEditable}
          label="First name"
        />
        <Input
          id="LastName"
          name="LastName"
          type="string"
          helperText={touched.LastName ? errors.LastName : ''}
          error={touched.LastName && Boolean(errors.LastName)}
          value={LastName}
          onChange={onChange.bind(null, 'LastName')}
          disabled={!isEditable}
          label="Last name"
        />
        <Input
          id="Position"
          name="Position"
          type="string"
          error={touched.Position && Boolean(errors.Position)}
          value={Position}
          onChange={onChange.bind(null, 'Position')}
          disabled={!isEditable}
          label="Position"
        />
        <Input
          id="EmailAddress"
          name="EmailAddress"
          type="string"
          helperText={touched.EmailAddress ? errors.EmailAddress : ''}
          error={touched.EmailAddress && Boolean(errors.EmailAddress)}
          value={EmailAddress}
          onChange={onChange.bind(null, 'EmailAddress')}
          disabled={!isEditable}
          label="Email"
        />
        <>
          {!isAPOnboardingV1 ? (
            <div className="relative mb-4 flex">
              <label
                className={`absolute left-[9px] top-[-6px] z-50 w-auto bg-white pl-[5px] pr-2 text-[10px] font-medium text-spenda-primarytext`}
              >
                Mobile
              </label>
              <SPhoneInput
                name="Mobile"
                theme="secondary"
                value={Mobile}
                disabled={!isEditable || (paymentAuth72488 && isMobileNumber)}
                onChange={onMobileChange}
                countryCodeEditable={true}
              />
            </div>
          ) : (
            <Input
              id="mobile"
              name="Mobile"
              autoComplete="off"
              label={'Phone number'}
              variant={'outlined'}
              value={Mobile}
              type="string"
              onChange={onChange.bind(null, 'Mobile')}
              disabled={!isEditable || isMobileNumber}
              helperText={touched.Mobile ? errors.Mobile : ''}
              error={touched.Mobile && Boolean(errors.Mobile)}
            />
          )}
        </>
      </section>

      <section className="my-3 ml-0 mr-5 border-[#CCCCCC] border-t-default font-poppins">
        <div
          className="relative flex items-center justify-between border-t py-4"
          style={{borderColor: 'hsl(var(--primary))'}}
        >
          <div className="w-[80%] pr-3">
            <Typography variant="h3" className="text-black-900">
              User Role
            </Typography>
            <Typography className="mt-2 w-[80%] text-gray-700">{selectedRole.desc}</Typography>
          </div>
          <Select
            name="UserRole"
            id="UserRole"
            label="User Role"
            disabled={!isEditable}
            value={selectedRole.value}
            onChange={onOptionChanged}
            data-autoid="selectPurchaseView"
            className="text-base"
            containerProps={{className: 'min-w-[150px] max-w-[185px]'}}
          >
            {items.map(item => {
              return (
                <Option key={item.value} value={item.value} data-autoid={`txtUser-${item.value}`}>
                  {item.value}
                </Option>
              );
            })}
          </Select>
        </div>
        {!isAPOnboardingV1 && (
          <>
            <div
              className={`relative flex items-center justify-between border-t py-4 ${
                IsEnabled ? 'border-primary' : 'border-warning'
              }`}
            >
              <div className="min-w-[80%] pr-3">
                <Typography variant="h3" className="text-black-900">
                  Is this user active?
                </Typography>
                <Typography variant="paragraph" className="mt-2 w-[80%] text-base text-gray-700">
                  Users which aren't active won't be able to login.{' '}
                  {IsPrimaryContact ? ' Your primary user cannot be inactivated' : ''}
                </Typography>
              </div>
              <Switch
                name="userStatus"
                ripple={false}
                checked={IsEnabled as boolean}
                onChange={e => {
                  if (e.target.checked) {
                    setFieldValue('IsEnabled', true);
                  } else {
                    onInActive();
                  }
                }}
                disabled={!isEditable || IsPrimaryContact}
              />
            </div>
            <div
              className={`relative flex items-center justify-between border-t py-4 ${
                IsGetNotificationEmailAlerts ? 'border-primary' : 'border-warning'
              }`}
            >
              <div className="min-w-[80%] pr-3">
                <Typography variant="h3" className="text-black-900">
                  Send notification emails to this user?
                </Typography>
                <Typography variant="paragraph" className="mt-2 w-[80%] text-base text-gray-700">
                  Would you like this user to receive email notification emails e.g. Accounting system disconnection
                </Typography>
              </div>
              <Switch
                name="IsGetNotificationEmailAlerts"
                checked={IsGetNotificationEmailAlerts as boolean}
                onChange={handleSwitchChange}
                disabled={!IsEnabled || (!isEditable && !isMe)}
              />
            </div>
            <div className={`relative flex items-center justify-between border-t border-primary py-4`}>
              <div className="min-w-[80%] pr-3">
                <Typography variant="h3" className="text-black-900">
                  User Visibility in the Service Management Booking Diary
                </Typography>
                <Typography variant="paragraph" className="mt-2 w-[80%] text-base text-gray-700">
                  Do you want this user to appear on the Booking Diary within the Service Management module for job
                  assignments?
                </Typography>
              </div>
              <Switch
                name="isServiceManagementVisible"
                checked={isServiceManagementVisible as boolean}
                onChange={handleServiceMgmtSwitchChange}
                disabled={!IsEnabled || (!isEditable && !isMe) || selectedRole.value === UIUserRoles.Technician}
              />
            </div>
          </>
        )}
      </section>
    </form>
  );
};

export default EditUserDialog;
