import {createContext} from 'react';
import {
  PaymentBatchStatusValues,
  PaymentBatchPermissionType,
  TimelineCustomRange,
} from '../../model/constants/Constants';
import {IUserDefaultViewSettings} from '../../model/payment-batch/PaymentBatch';

export type paymentBatchStatusEnumValues = PaymentBatchStatusValues;
export interface IAPContextState {
  userRoleBatchPermissions: PaymentBatchPermissionType[];
  defaultSearchStatusFilters: paymentBatchStatusEnumValues[];
  defaultTimelineGroupsFilter: TimelineCustomRange;
}

export interface IAPContextType extends IAPContextState {
  setUserRoleBatchPermissions: (permissions: PaymentBatchPermissionType[]) => void;
  getUserRoleBatchPermissions: () => Promise<PaymentBatchPermissionType[]>;
  getUserDefaultViewSettings: () => Promise<IUserDefaultViewSettings>;
  setUserDefaultViewSettings: (settings: IUserDefaultViewSettings) => void;
}

const APContext = createContext<IAPContextType>({
  defaultTimelineGroupsFilter: TimelineCustomRange.SevenToTwentyEightDays,
  defaultSearchStatusFilters: [],
  userRoleBatchPermissions: [],
  setUserRoleBatchPermissions: (_permissions: PaymentBatchPermissionType[]) => undefined,
  getUserRoleBatchPermissions: () => new Promise<PaymentBatchPermissionType[]>(resolve => resolve([])),
  getUserDefaultViewSettings: () => new Promise<IUserDefaultViewSettings>(() => {}),
  setUserDefaultViewSettings: (_settings: IUserDefaultViewSettings) => undefined,
});

export default APContext;
