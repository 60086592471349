import React from 'react';
import {IConversationMessages} from '../../model/conversation/Conversation';

import {DownloadIconChat} from '../../assets/svg/DownloadIconChat';
import {Box, makeStyles} from '@material-ui/core';
import {ConvoMessageType} from '../../model/constants/Constants';
import {NameInitials} from '../../utils/formatter';

interface DisplayMessageProps {
  messageObj: IConversationMessages;
  iteration: number;
  tenantId: string;
  newLine: any;
  isShowNewMsgLine: boolean;
}

export const DisplayMessage = (props: DisplayMessageProps) => {
  const {messageObj, iteration, newLine, tenantId, isShowNewMsgLine} = props;
  const {message, status, conversationID, fromTenantGlobalGUID, fileName, fileUri, fromUserName, fileType, fileSize} =
    messageObj;

  const useChatBoxDisplayMessageStyles = makeStyles(() => ({
    senderChatSet: {
      alignItems: 'end',
      marginBottom: '10px',
    },
    senderMessageBox: {
      maxWidth: '260px',
      height: 'auto',
      backgroundColor: '#1C78AD',
      alignSelf: 'end',
      padding: '9px 10px',
      borderRadius: '12px',
      position: 'relative',
      overflowWrap: 'break-word',
      '& :after': {
        content: ' ',
        width: '10px',
        height: '10px',
        position: 'absolute',
        bottom: 0,
        backgroundColor: '#1C78AD',
      },
    },
    receiverChatSet: {
      alignItems: 'start',
      marginBottom: '10px',
    },
    receiverMessageBox: {
      maxWidth: '260px',
      height: 'auto',
      backgroundColor: '#EFF3F6',
      alignSelf: 'start',
      padding: '9px 10px',
      borderRadius: '12px',
      overflowWrap: 'break-word',
    },
    senderMessage: {
      fontFamily: 'poppins',
      fontWeight: 400,
      fontSize: '0.75em',
      color: '#FFFFFF',
    },
    receiverMessage: {
      fontFamily: 'poppins',
      fontWeight: 400,
      fontSize: '0.75em',
      color: '#333333',
    },
    infoTime: {
      fontWeight: 400,
      fontSize: '0.675em',
      color: '#999999',
      width: 'fit-content',
    },
    senderAttachmentBox: {
      width: '201px',
      height: 'auto',
      backgroundColor: '#175376',
      alignSelf: 'end',
      padding: '10px',
      borderRadius: '8px 8px 8px 8px',
      position: 'relative',
      overflowWrap: 'break-word',
      wordBreak: 'break-word',
      '& :after': {
        content: ' ',
        width: '10px',
        height: '10px',
        position: 'absolute',
        bottom: 0,
        backgroundColor: '#1C78AD',
      },
    },
    receiverAttachmentBox: {
      width: '201px',
      height: 'auto',
      backgroundColor: '#A4A6AA',
      alignSelf: 'start',
      padding: '10px',
      borderRadius: '8px 8px 8px 8px',
      overflowWrap: 'break-word',
    },
    senderFileType: {
      fontWeight: 400,
      fontSize: '8px',
      color: '#FFFFFF',
      marginRight: '8px',
      lineHeight: 2,
      marginTop: '4px',
      marginLeft: '4px',
    },
    senderFileSize: {
      fontWeight: 400,
      fontSize: '8px',
      color: '#FFFFFF',
      lineHeight: 2,
      marginTop: '4px',
    },
    receiverFileType: {
      fontWeight: 400,
      fontSize: '8px',
      color: '#121212',
      marginRight: '8px',
      lineHeight: 2,
      marginTop: '4px',
      marginLeft: '4px',
    },
    receiverFileSize: {
      fontWeight: 400,
      fontSize: '8px',
      color: '#121212',
      lineHeight: 2,
      marginTop: '4px',
    },
    divider: {
      borderBottom: '1px solid #C55D44',
      width: '40%',
    },
    newMessage: {
      fontFamily: 'poppins',
      color: '#C55D44',
      fontSize: '10px',
      fontWeight: 400,
    },
  }));

  const classes = useChatBoxDisplayMessageStyles();

  return (
    <div>
      {status === 'Unread' && fromTenantGlobalGUID !== tenantId && !newLine.isNewMessagesStarted && (
        <DisplayNewLine isShowNewMsgLine={isShowNewMsgLine} newLine={newLine} conversationID={conversationID} />
      )}
      <div
        className={`${
          fromTenantGlobalGUID === tenantId ? classes.senderChatSet : classes.receiverChatSet
        } w-full flex flex-col`}
      >
        <div className="flex flex-col items-end">
          <p
            className={`${classes.infoTime} mb-1`}
            style={{alignSelf: fromTenantGlobalGUID === tenantId ? 'start' : 'end'}}
          >
            {fromUserName}
          </p>
          <div className={`flex items-end ${fromTenantGlobalGUID === tenantId ? 'flex-row' : 'flex-row-reverse'}`}>
            <div
              className={`${
                fromTenantGlobalGUID === tenantId ? classes.senderMessageBox : classes.receiverMessageBox
              } flex flex-col`}
            >
              {(messageObj.messageTypeID === ConvoMessageType.Message || messageObj.messageTypeID === 0) && (
                <p
                  data-autoid={`lblMessage-${conversationID}-${iteration}`}
                  className={`${fromTenantGlobalGUID === tenantId ? classes.senderMessage : classes.receiverMessage}`}
                >
                  {message}
                </p>
              )}
              {messageObj.messageTypeID === ConvoMessageType.Attachment && (
                <>
                  <div
                    className={`${
                      fromTenantGlobalGUID === tenantId ? classes.senderAttachmentBox : classes.receiverAttachmentBox
                    } flex flex-row justify-between items-center`}
                  >
                    <p
                      data-autoid={`lblAttachmentFileName-${conversationID}-${iteration}`}
                      className={`${
                        fromTenantGlobalGUID === tenantId ? classes.senderMessage : classes.receiverMessage
                      }`}
                    >
                      {fileName}
                    </p>
                    {fileUri && (
                      <a
                        data-autoid={`btnAttachmentFileDownloadLink-${conversationID}-${iteration}`}
                        href={fileUri}
                        download
                      >
                        <DownloadIconChat className={`cursor-pointer`} />
                      </a>
                    )}
                  </div>
                  <div className={`flex flex-row`}>
                    <p
                      data-autoid={`lblAttachmentFileType-${conversationID}-${iteration}`}
                      className={`${
                        fromTenantGlobalGUID === tenantId ? classes.senderFileType : classes.receiverFileType
                      }`}
                    >
                      {fileType}
                    </p>
                    <p
                      data-autoid={`lblAttachmentFileSize-${conversationID}-${iteration}`}
                      className={`${
                        fromTenantGlobalGUID === tenantId ? classes.senderFileSize : classes.receiverFileSize
                      }`}
                    >
                      {fileSize}
                    </p>
                  </div>
                </>
              )}
            </div>
            <div
              className="flex flex-row items-center justify-center rounded-full w-5 h-5 mx-2"
              style={{
                backgroundColor: fromTenantGlobalGUID === tenantId ? '#C68A1920' : '#8178CF20',
                color: fromTenantGlobalGUID === tenantId ? '#C68A19' : '#8178CF',
              }}
            >
              <p className=" text-xs">{NameInitials(fromUserName || '')}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

interface DisplayNewLineProps {
  isShowNewMsgLine: boolean;
  newLine: any;
  conversationID: number;
}

const DisplayNewLine = (props: DisplayNewLineProps) => {
  const {isShowNewMsgLine, newLine, conversationID} = props;
  newLine.isNewMessagesStarted = true;

  const useChatBoxDisplayMessageStyles = makeStyles(() => ({
    divider: {
      borderBottom: '1px solid #C55D44',
      width: '40%',
    },
    newMessage: {
      fontFamily: 'poppins',
      color: '#C55D44',
      fontSize: '10px',
      fontWeight: 400,
    },
  }));

  const classes = useChatBoxDisplayMessageStyles();

  return (
    <>
      {isShowNewMsgLine && (
        <Box
          data-autoid={`lblNewMessageLine-${conversationID}`}
          className={`w-full flex flex-row items-center justify-between mb-3`}
        >
          <hr className={`${classes.divider}`} />
          <p className={`${classes.newMessage}`}>New Message</p>
          <hr className={`${classes.divider}`} />
        </Box>
      )}
    </>
  );
};
