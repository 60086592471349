import * as React from 'react';
const ARCreditHoldIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="20" height="16" {...props}>
    <path
      fill="#c68a19"
      fillRule="evenodd"
      d="M15.3 16.3c-1.8 0-3.5-1.2-4.2-2.8h-9c-1.2 0-2.1-1-2.1-2.1V2.8C0 1.7.9.7 2.1.7h14.7c1.1 0 2.1 1 2.1 2.1v5.7c.7.8 1.1 1.9 1.1 3.1 0 2.6-2.1 4.7-4.7 4.7zm3.7-4.7c0-2.1-1.7-3.8-3.7-3.8s-3.7 1.7-3.7 3.8c0 2 1.7 3.7 3.7 3.7s3.7-1.7 3.7-3.7zm-3.2 1.5V9.9c0-.3.2-.6.5-.6s.5.3.5.6v3.2c0 .3-.2.5-.5.5s-.5-.2-.5-.5zm-2.2 0V9.9c0-.3.2-.6.5-.6s.5.3.5.6v3.2c0 .3-.2.5-.5.5s-.5-.2-.5-.5zm-2.9-1.5c0-2.6 2.1-4.7 4.6-4.7 1 0 1.9.3 2.6.8V2.8c0-.6-.5-1.1-1.1-1.1H2.1c-.6 0-1.1.5-1.1 1.1v8.6c0 .6.5 1.1 1.1 1.1h8.7q-.1-.4-.1-.9zM3 11.2c-.7 0-.7-1 0-1h6c.6 0 .6 1 0 1zm3.7-2.4c-.7 0-.7-1 0-1h2c.6 0 .6 1 0 1zM3 8.8c-.6 0-.6-1 0-1h2c.7 0 .7 1 0 1zm-.5-5.9h4.2v3.5H2.5zm.9 2.5h2.4V3.8H3.4z"
    />
  </svg>
);
export default ARCreditHoldIcon;
