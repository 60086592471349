import {MenuItem, Select, makeStyles} from '@material-ui/core';
import moment from 'moment';
import React, {useContext, useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import {IconPaymentMethods} from '../../../assets/svg/IconPaymentMethods';
import {ARTable} from '../../../components/AccountsReceivable/ARTable';
import {ITxList, TransactionView} from '../../../components/AccountsReceivable/TransactionView';
import PaymentMethodsTooltip from '../../../components/data-display/PaymentMethodsTooltip';
import {SCheckbox} from '../../../components/inputs/SCheckbox';
import {IntegrationStatusBadge} from '../../../components/integration/IntegrationStatusBadge';
import {IntegrationContext} from '../../../context/IntegrationContext';
import {useTransactionViewHook} from '../../../hooks/useARHook';
import {useFeatureFlags} from '../../../hooks/useFeatureFlags';
import {useTenantInfo} from '../../../hooks/useTenantInfo';
import {
  ARInvoicePaymentAllocationStatus,
  ARPaymentMethodIconsStatus,
  DatTypes,
} from '../../../model/constants/Constants';
import {ICustomerPaymentHistoryResponse, IPaymentHistoryResponse} from '../../../model/customer/CustomerStatements';
import {useCustomerStatementsAPI} from '../../../services/useCustomerStatementsAPI';
import {useSupplierTransactionsAPI} from '../../../services/useSupplierTransactionsAPI';
import {PriceFormat} from '../../../utils/formatter';
import {renderPaymentMethodIcons, renderPaymentStatus} from '../payments/ARPayments';
import {Typography} from 'spenda-ui-react';
import {ARSearchBar} from '../../../components/AccountsReceivable/ARSearchBar';
import {IconDropDownArrow} from '../../../assets/svg/IconDropDownArrow';

const useFilterStyles = makeStyles({
  batchSelect: {
    minWidth: '185px',
    height: '40px',
    border: '1px solid #333',
    borderRadius: '6px',
    margin: 0,
    '& .MuiSelect-select': {
      color: '#333333',
      fontSize: '14px',
      fontWeight: '600',
      fontFamily: 'poppins',
      lineHeight: '16px',
      padding: '0px 40px 0px 10px',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      height: '40px',
      '& .MuiRadio-root': {
        display: 'none',
      },
    },
    // '& svg': {
    //   color: '#1c78ad',
    // },
    '& .MuiOutlinedInput-notchedOutline': {
      border: '0px!important',
    },
  },
  paymentHistory: {
    '& .pdf-transaction-view': {
      top: '0px',
    },
  },
  menuPaper: {
    maxHeight: '200px',
    maxWidth: '200px',
  },
  menuItem: {
    '& .MuiFormControlLabel-root': {
      maxWidth: '25px !important',
    },
  },
});

const ARPaymentMethods = {
  Visa: [ARPaymentMethodIconsStatus.Visa],
  Mastercard: [ARPaymentMethodIconsStatus.Mastercard],
  AmericanExpress: [ARPaymentMethodIconsStatus.AmericanExpress],
  CreditNotes: [ARPaymentMethodIconsStatus.CreditNotes],
  BankTransfer: [ARPaymentMethodIconsStatus.BankTransfer],
  Finance: [ARPaymentMethodIconsStatus.Invigo, ARPaymentMethodIconsStatus.SpendaFinance],
  AirPlus: [ARPaymentMethodIconsStatus.AirPlus],
  Capricorn: [ARPaymentMethodIconsStatus.CapricornDSD],
};

export const ARPaymentHistory = () => {
  // Classes
  const classes = useFilterStyles();

  // Context
  const {financialAdaptor} = useContext(IntegrationContext);

  // State
  const [paymentHistoryList, setPaymentHistoryList] = useState<IPaymentHistoryResponse[]>();
  const [customerPaymentHistoryList, setCustomerPaymentHistoryList] = useState<ICustomerPaymentHistoryResponse[]>();
  const [expandedCustomerPaymentHistory, setExpandedCustomerPaymentHistory] =
    useState<ICustomerPaymentHistoryResponse>();
  const [search, setSearch] = useState({searchKey: '', searchCategory: 'RefNumber'});
  const [statusFilter, setStatusFilter] = useState<
    Array<ARInvoicePaymentAllocationStatus | ARPaymentMethodIconsStatus[]>
  >([]);
  const [isInLoadingState, setIsInLoadingState] = useState(false);

  // Hooks
  const {isInBuyerContext, isInSupplierContext} = useTenantInfo();
  const {viewingTx, handleCloseTx} = useTransactionViewHook();

  // Feature Flags
  const {scheduledPayments83107: scheduledPayments83107Supplier} = useFeatureFlags().tenantOwned();
  const {T2TPhase177852, scheduledPayments83107: scheduledPayments83107Buyer} = useFeatureFlags().supplierDriven();
  const scheduledPayments83107 = isInBuyerContext ? scheduledPayments83107Buyer : scheduledPayments83107Supplier;

  // APIs
  const {getBuyerPaymentHistory} = useSupplierTransactionsAPI();
  const {getCustomerPaymentHistory} = useCustomerStatementsAPI();

  // Constant
  const {customerId, supplierId} = useParams<{customerId?: string; supplierId?: string}>();
  const _supplierId = supplierId ? parseInt(supplierId) || undefined : undefined;
  const _customerId = customerId ? parseInt(customerId) || undefined : undefined;
  const searchCategories = [
    {value: 'RefNumber', label: 'Payment Ref'},
    {value: 'VendorName', label: 'Vendor Name'},
    {value: 'Amount', label: 'Amount'},
    {value: 'InvoiceRefNumber', label: 'Invoice Ref'},
  ];

  const filterRecords = (list: IPaymentHistoryResponse[] | undefined) => {
    return list?.filter(
      p =>
        statusFilter?.flat()?.includes(p?.status as ARInvoicePaymentAllocationStatus) &&
        p?.paymentMethodList?.some(r => statusFilter?.flat()?.includes(r as ARPaymentMethodIconsStatus)),
    );
  };

  let txList: ITxList[] | undefined = undefined;

  if (isInSupplierContext && !customerId) {
    txList = filterRecords(expandedCustomerPaymentHistory?.paymentHistory)?.map(t => {
      return {
        txId: t.transID,
        txGuid: t.transGUID,
        txDatType: t.datTypeID,
      };
    });
  } else if (isInBuyerContext || (isInSupplierContext && customerId)) {
    txList = filterRecords(paymentHistoryList)?.map(t => {
      return {
        txId: t.transID,
        txGuid: t.transGUID,
        txDatType: t.datTypeID,
      };
    });
  }

  // useEffect
  useEffect(() => {
    setExpandedCustomerPaymentHistory(undefined);
    if (isInBuyerContext && supplierId) getBuyerTransactions();
    else if (isInSupplierContext) getSupplierTransactions();
  }, [search]);

  useEffect(() => {
    let defaultSelectedFilters = (
      Object.keys(ARInvoicePaymentAllocationStatus) as Array<keyof typeof ARInvoicePaymentAllocationStatus>
    ).filter(key => key !== ARInvoicePaymentAllocationStatus?.Cancelled);

    if (!scheduledPayments83107) {
      defaultSelectedFilters = defaultSelectedFilters?.filter(d => d !== ARInvoicePaymentAllocationStatus.Scheduled);
    }

    const defaultPaymentMethodFilters = Object.values(ARPaymentMethods).map(pm => pm);

    setStatusFilter([...defaultSelectedFilters, ...defaultPaymentMethodFilters] as (
      | ARInvoicePaymentAllocationStatus
      | ARPaymentMethodIconsStatus[]
    )[]);
  }, [scheduledPayments83107]);

  // Functions
  const getBuyerTransactions = async () => {
    if (!_supplierId) return;
    try {
      setIsInLoadingState(true);
      const query = {
        searchString: search?.searchKey || undefined,
        searchField: search?.searchKey ? search?.searchCategory : undefined,
      };
      const result = await getBuyerPaymentHistory(_supplierId, query);
      const updatedList = result;
      setPaymentHistoryList(updatedList);
      setIsInLoadingState(false);
    } catch {
      setIsInLoadingState(false);
    }
  };

  const getSupplierTransactions = async () => {
    try {
      setIsInLoadingState(true);
      const result = await getCustomerPaymentHistory({
        accountCustomerID: customerId || undefined,
        SearchString: search?.searchKey || undefined,
        searchField: search?.searchKey ? search?.searchCategory : undefined,
      });
      if (customerId) {
        const updatedList = result?.[0]?.paymentHistory;
        setPaymentHistoryList(updatedList);
        setIsInLoadingState(false);
        return;
      }
      const updatedList = result?.map(r => ({...r, paymentHistory: r?.paymentHistory}));
      setCustomerPaymentHistoryList(updatedList);
      setIsInLoadingState(false);
    } catch {
      setIsInLoadingState(false);
    }
  };

  const onStatusSelectChange = (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ) => {
    const optionValue = e?.target?.value;
    setStatusFilter(optionValue as (ARInvoicePaymentAllocationStatus | ARPaymentMethodIconsStatus[])[]);
  };

  // Create an integration Status column
  const integrationStatusColumn = {
    title: 'Integration Status',
    key: 'integrationStatus',
    width: '15%',
    align: 'center',
    columClassName: 'text-spenda-labeltext',
    rowClassName: 'p-2.5',
    isSortable: false,
    rowRenderer: (rowData: IPaymentHistoryResponse) => (
      <div className="relative h-[20px]">
        <span
          data-autoid={`lblIntegrationStatusARPaymentHistory`}
          className={`text- } overflow-ellipsis font-poppins text-base font-medium
          text-black-800`}
        >
          <IntegrationStatusBadge syncStatus={rowData.integrationStatus} syncError={rowData.integrationDetails} />
        </span>
      </div>
    ),
  };

  const paymentHistoryColumns = [
    {
      title: '',
      key: 'vendor-dropdown',
      columClassName: `text-spenda-labeltext !max-w-[32px] !min-w-[32px] ${isInBuyerContext ? 'hidden' : ''}`,
      rowClassName: `p-0 !max-w-[32px] !min-w-[32px] ${isInSupplierContext ? '!bg-primary-background' : 'hidden'}`,
      align: 'left',
      isSortable: false,
      rowRenderer: () => {
        return <></>;
      },
    },
    {
      title: 'Allocation',
      key: 'refNumber',
      width: isInSupplierContext ? '13%' : '14%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: `p-2.5 ${isInSupplierContext ? '!bg-primary-background' : ''}`,
      isSortable: true,
      rowRenderer: (rowData: IPaymentHistoryResponse) => {
        const path =
          DatTypes.InvoicePaymentAllocation === rowData.datTypeID
            ? `?txDatType=${rowData.datTypeID}&txId=${rowData.transID}`
            : `?txGuid=${rowData.transGUID}&txDatType=${rowData.datTypeID}&txId=${rowData.transID}`;

        return (
          <div className="flex flex-row items-center justify-between">
            <Link
              to={{
                pathname: location.pathname,
                search: path,
              }}
            >
              <Typography
                data-autoid={`lnkAllocationRefNumber`}
                className="inline cursor-pointer overflow-ellipsis font-medium text-primary underline"
                variant="paragraph"
              >
                {rowData?.refNumber}
              </Typography>
            </Link>
            <div className="flex flex-row flex-wrap justify-end gap-x-2">
              <PaymentMethodsTooltip
                title={
                  <React.Fragment>
                    <div data-autoid={`rowPaymentHistory<allocationID`} className="flex flex-row justify-start gap-2">
                      {rowData?.paymentMethodList?.map((t, i) => {
                        return (
                          <span data-autoid={`btnAllocationPaymentMethod`} key={i}>
                            {renderPaymentMethodIcons(t as ARPaymentMethodIconsStatus)}
                          </span>
                        );
                      })}
                    </div>
                  </React.Fragment>
                }
              >
                <button
                  className="flex cursor-pointer items-center justify-center"
                  data-autoid="btnAllocationPaymentMethod"
                >
                  <IconPaymentMethods />
                </button>
              </PaymentMethodsTooltip>
            </div>
          </div>
        );
      },
    },
    {
      title: 'Payment date',
      key: 'paymentDateTime_utc',
      width: isInSupplierContext ? '12%' : '14%',
      align: 'right',
      columClassName: 'text-spenda-labeltext',
      rowClassName: `p-2.5 ${isInSupplierContext ? '!bg-[#F4F9FB]' : ''}`,
      isSortable: true,
      rowRenderer: (rowData: IPaymentHistoryResponse) => (
        <Typography data-autoid={`lblPaymentDate`} className="inline overflow-ellipsis font-medium" variant="paragraph">
          {moment(rowData?.paymentDateTime_utc).format('DD MMM YYYY')}
        </Typography>
      ),
    },
    {
      title: 'Invoice total',
      key: 'invoiceAppliedAmount',
      width: isInSupplierContext ? '13%' : '14%',
      align: 'right',
      columClassName: 'text-spenda-labeltext',
      rowClassName: `p-2.5 ${isInSupplierContext ? '!bg-primary-background' : ''}`,
      isSortable: true,
      rowRenderer: (rowData: IPaymentHistoryResponse) => (
        <Typography
          data-autoid={`lblInvoiceTotal`}
          className="inline overflow-ellipsis font-medium"
          variant="paragraph"
        >
          {PriceFormat(Number(rowData?.invoiceAppliedAmount?.toFixed(2)))}
        </Typography>
      ),
    },
    {
      title: 'Credit applied',
      key: 'creditNoteAppliedAmount',
      width: isInSupplierContext ? '13%' : '14%',
      align: 'right',
      columClassName: 'text-spenda-labeltext',
      rowClassName: `p-2.5 ${isInSupplierContext ? '!bg-[#F4F9FB]' : ''}`,
      isSortable: true,
      rowRenderer: (rowData: IPaymentHistoryResponse) => (
        <Typography
          data-autoid={`lblCreditApplied`}
          className="inline overflow-ellipsis font-medium"
          variant="paragraph"
        >
          {PriceFormat(Number(rowData?.creditNoteAppliedAmount?.toFixed(2)))}
        </Typography>
      ),
    },
    {
      title: 'Payment amount',
      key: 'appliedPayments',
      width: isInSupplierContext ? '13%' : '14%',
      align: 'right',
      columClassName: 'text-spenda-labeltext',
      rowClassName: `p-2.5 ${isInSupplierContext ? '!bg-primary-background' : ''}`,
      isSortable: true,
      rowRenderer: (rowData: IPaymentHistoryResponse) => (
        <div className="relative h-[20px]">
          <Typography
            data-autoid={`lblPaymentAmount`}
            className="inline overflow-ellipsis font-medium"
            variant="paragraph"
          >
            {PriceFormat(Number(rowData?.appliedPayments?.toFixed(2)))}
          </Typography>
        </div>
      ),
    },
    {
      title: 'Payment status',
      key: 'status',
      width: isInSupplierContext ? '12%' : '15%',
      align: 'center',
      columClassName: 'text-spenda-labeltext',
      rowClassName: `p-2.5 ${isInSupplierContext ? '!bg-[#F4F9FB]' : ''}`,
      isSortable: true,
      rowRenderer: (rowData: IPaymentHistoryResponse) => (
        <div className="relative h-[20px]">
          <span
            data-autoid={`lblPaymentStatus`}
            className={`text- } overflow-ellipsis font-poppins text-base font-medium
            text-black-800`}
          >
            {renderPaymentStatus(rowData?.status as ARInvoicePaymentAllocationStatus)}
          </span>
        </div>
      ),
    },
  ];

  // Display integrationStatusColumn to the end of the paymentHistoryColumns array
  // if the buyer has an accounting adaptor and the feature flag is enabled
  if (isInBuyerContext && financialAdaptor && T2TPhase177852) {
    paymentHistoryColumns.push(integrationStatusColumn);
  }

  const customerPaymentHistoryColumns = [
    {
      title: '',
      key: 'vendor-dropdown',
      columClassName: 'text-spenda-labeltext !max-w-[32px] !min-w-[32px]',
      rowClassName: 'p-0 !max-w-[32px] !min-w-[32px]',
      align: 'left',
      isSortable: false,
      getRowClassConditionally: (rowData: ICustomerPaymentHistoryResponse) =>
        rowData?.customer === expandedCustomerPaymentHistory?.customer ? '!bg-[#F4F9FB]' : '',
      rowRenderer: (rowData: ICustomerPaymentHistoryResponse) => {
        return (
          <>
            <span
              data-autoid={`btnRowVendor-${rowData.customer}`}
              className={`togglerIcon text- m-auto mr-[8px] flex !h-[14px] !w-[14px] cursor-pointer items-center justify-center rounded-[3px] border border-[hsl(var(--primary))] ${
                rowData?.customer !== expandedCustomerPaymentHistory?.customer ? '' : '!rotate-180'
              }`}
              onClick={() => {
                if (rowData?.customer === expandedCustomerPaymentHistory?.customer)
                  setExpandedCustomerPaymentHistory(undefined);
                else setExpandedCustomerPaymentHistory(rowData);
              }}
            >
              <IconDropDownArrow />
            </span>
          </>
        );
      },
    },
    {
      title: 'Customer',
      key: 'customer',
      width: '24%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: true,
      getRowClassConditionally: (rowData: ICustomerPaymentHistoryResponse) =>
        rowData?.customer === expandedCustomerPaymentHistory?.customer ? '!bg-[#F4F9FB]' : '',
      rowRenderer: (rowData: ICustomerPaymentHistoryResponse) => (
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-row items-center">
            <Typography className="inline overflow-ellipsis font-medium" variant="paragraph">
              {rowData?.customer}
            </Typography>
          </div>
        </div>
      ),
    },
    {
      title: 'Allocation',
      key: 'refNumber',
      width: '13%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: true,
      getRowClassConditionally: (rowData: ICustomerPaymentHistoryResponse) =>
        rowData?.customer === expandedCustomerPaymentHistory?.customer ? '!bg-[#F4F9FB]' : '',
      rowRenderer: () => <span className="overflow-ellipsis font-poppins text-base font-medium text-black-800"></span>,
    },
    {
      title: 'Payment date',
      key: 'appliedDate',
      width: '12%',
      align: 'right',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: true,
      getRowClassConditionally: (rowData: ICustomerPaymentHistoryResponse) =>
        rowData?.customer === expandedCustomerPaymentHistory?.customer ? '!bg-[#F4F9FB]' : '',
      rowRenderer: () => <span className="overflow-ellipsis font-poppins text-base font-medium text-black-800"></span>,
    },
    {
      title: 'Invoice total',
      key: 'totalInc',
      width: '13%',
      align: 'right',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: true,
      getRowClassConditionally: (rowData: ICustomerPaymentHistoryResponse) =>
        rowData?.customer === expandedCustomerPaymentHistory?.customer ? '!bg-[#F4F9FB]' : '',
      rowRenderer: () => (
        <span className={`overflow-ellipsis font-poppins text-base font-medium text-black-800`}></span>
      ),
    },
    {
      title: 'Credit applied',
      key: 'appliedAmount',
      width: '13%',
      align: 'right',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: true,
      getRowClassConditionally: (rowData: ICustomerPaymentHistoryResponse) =>
        rowData?.customer === expandedCustomerPaymentHistory?.customer ? '!bg-[#F4F9FB]' : '',
      rowRenderer: () => (
        <span className={`overflow-ellipsis font-poppins text-base font-medium text-black-800 `}></span>
      ),
    },
    {
      title: 'Payment amount',
      key: 'balance',
      width: '13%',
      align: 'right',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: true,
      getRowClassConditionally: (rowData: ICustomerPaymentHistoryResponse) =>
        rowData?.customer === expandedCustomerPaymentHistory?.customer ? '!bg-[#F4F9FB]' : '',
      rowRenderer: () => (
        <div className="relative h-[20px]">
          <span className={`text- overflow-ellipsis font-poppins text-base font-medium text-black-800`}></span>
        </div>
      ),
    },
    {
      title: 'Payment status',
      key: 'status',
      width: '12%',
      align: 'center',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: true,
      getRowClassConditionally: (rowData: ICustomerPaymentHistoryResponse) =>
        rowData?.customer === expandedCustomerPaymentHistory?.customer ? '!bg-[#F4F9FB]' : '',
      rowRenderer: () => (
        <div className="relative h-[20px]">
          <span className={`text- overflow-ellipsis font-poppins text-base font-medium text-black-800`}></span>
        </div>
      ),
    },
  ];

  if (isInSupplierContext) {
    paymentHistoryColumns?.splice(1, 0, {
      title: 'Customer',
      key: 'accountCustomerName',
      width: '24%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: `p-2.5 ${isInSupplierContext ? '!bg-[#F4F9FB]' : ''}`,
      isSortable: true,
      rowRenderer: (rowData: IPaymentHistoryResponse) => (
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-row items-center">
            <Typography
              data-autoid={`lblAccountCustomerNameARPaymentHistory`}
              className="inline overflow-ellipsis font-medium"
              variant="paragraph"
            >
              {rowData.accountCustomerName}
            </Typography>
          </div>
        </div>
      ),
    });

    searchCategories?.splice(1, 0, {value: 'CustomerName', label: 'Customer Name'});
  }

  const getNestedTableColumns = () => {
    return filterRecords(expandedCustomerPaymentHistory?.paymentHistory);
  };

  const handleRenderNestedTable = (row: ICustomerPaymentHistoryResponse, order: 'asc' | 'desc', orderBy: string) => {
    if (expandedCustomerPaymentHistory?.customer !== row?.customer) return;
    return (
      <ARTable
        isHighlightRowOnHover
        isNestedTable
        rows={getNestedTableColumns() || []}
        columns={paymentHistoryColumns}
        isLoading={false}
        order={order}
        orderBy={orderBy}
        getRowDataAutoId={rowData => `rowPaymentHistory${rowData?.transID}`}
      />
    );
  };

  const getColumns = () => {
    if (isInSupplierContext) {
      if (customerId) return paymentHistoryColumns;
      return customerPaymentHistoryColumns;
    } else if (isInBuyerContext) {
      return paymentHistoryColumns;
    }
    return [];
  };

  const getDataAutoID = (row: any, rowIndex: number) => {
    if (isInSupplierContext) {
      if (customerId) return `rowPaymentHistory${row?.transID}`;
      return `rowPaymentHistory${row?.customer}`;
    } else if (isInBuyerContext) {
      return `rowPaymentHistory${row?.transID}`;
    }
    return `row${rowIndex}`;
  };

  const getRows = () => {
    if (isInSupplierContext) {
      if (customerId && paymentHistoryList) return filterRecords(paymentHistoryList);
      else if (customerPaymentHistoryList) return customerPaymentHistoryList;
      return [];
    } else if (isInBuyerContext && paymentHistoryList) {
      return filterRecords(paymentHistoryList);
    }
    return [];
  };

  const paymentHistoryPanel = (
    <div className={`invoicesList h-full w-full rounded bg-white pb-16`}>
      <div className={`flex items-center justify-between border-b border-black-800 px-2.5 pb-2.5`}>
        <Typography className="font-light" data-autoid={`lblRecordLengthARPaymentHistory`} variant="h2">
          Payment history
        </Typography>
        <div className="flex flex-row items-center justify-end gap-2.5">
          <div className="min-w-[380px]">
            <ARSearchBar
              reverse
              type="advance"
              searchCategories={searchCategories}
              dataAutoIdSuffix={'paymentHistory'}
              searchString={search?.searchKey}
              searchCategory={search?.searchCategory || 'RefNumber'}
              isSearching={isInLoadingState}
              onSubmit={({searchString, searchCategory}) => {
                setSearch({searchKey: searchString, searchCategory: searchString ? searchCategory || '' : ''});
              }}
            />
          </div>
          {
            <Select
              multiple
              renderValue={(selected: any) => (selected?.length ? `${selected?.length} Filters` : `Filters`)}
              className={`${classes.batchSelect} ml-2.5 h-[40px] min-w-[185px] rounded-[6px] border-[1px] border-[#333] font-poppins text-black-800`}
              variant="outlined"
              placeholder="Status"
              value={statusFilter}
              onChange={e => onStatusSelectChange(e)}
              MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                classes: {paper: classes.menuPaper},
              }}
              inputProps={{
                name: 'selectBatchType',
                'data-autoid': `ddlFilters`,
              }}
            >
              <MenuItem
                key={'lblSelectStatusOptionsPaymentStatus'}
                data-autoid={`lblARAgeReceivableStatusOptionsOption`}
                value={'PaymentStatus'}
                className={`!text-xs text-spenda-labeltext`}
                disabled
              >
                Payment status
              </MenuItem>
              {(
                Object.keys(ARInvoicePaymentAllocationStatus) as Array<keyof typeof ARInvoicePaymentAllocationStatus>
              ).map((key, index) => {
                if (key === 'Scheduled' && !scheduledPayments83107) return;
                if (key === 'CancellationInProgress') return;
                return (
                  <MenuItem
                    key={'lblSelectStatusOptions' + index}
                    data-autoid={`ddlFilters`}
                    value={ARInvoicePaymentAllocationStatus[key]}
                    className={classes.menuItem}
                  >
                    <SCheckbox
                      disabled={false}
                      className={`!p-0 !pl-2 !pr-2 text-[#1c78ad]`}
                      textColor="#333333"
                      key={'chkSelectStatusOptions' + index}
                      name={ARInvoicePaymentAllocationStatus[key]}
                      value={ARInvoicePaymentAllocationStatus[key]}
                      label={''}
                      checked={statusFilter?.flat()?.includes(ARInvoicePaymentAllocationStatus[key])}
                    />
                    {ARInvoicePaymentAllocationStatus[key]?.replace(/([A-Z])/g, ' $1')?.trim()}
                  </MenuItem>
                );
              })}
              <MenuItem
                key={'lblSelectStatusOptionsPaymentStatus'}
                data-autoid={`lblARAgeReceivableStatusOptionsOption`}
                value={'PaymentStatus'}
                className="!text-xs text-spenda-labeltext"
                disabled
              >
                Payment method
              </MenuItem>
              {(Object.keys(ARPaymentMethods) as Array<keyof typeof ARPaymentMethods>).map((key, index) => {
                return (
                  <MenuItem
                    className={classes.menuItem}
                    key={'lblSelectStatusOptions' + index}
                    data-autoid={`ddlFilters`}
                    value={ARPaymentMethods[key]}
                  >
                    <SCheckbox
                      disabled={false}
                      className={`!p-0 !pl-2 !pr-2 text-[#1c78ad]`}
                      textColor="#333333"
                      key={'chkSelectStatusOptions' + index}
                      name={String(ARPaymentMethods[key])}
                      value={ARPaymentMethods[key]}
                      label={''}
                      checked={statusFilter?.includes(ARPaymentMethods[key])}
                    />
                    {key?.replace(/([A-Z])/g, ' $1')?.trim()}
                  </MenuItem>
                );
              })}
            </Select>
          }
        </div>
      </div>
      <div className={`mt-4 flex h-full w-full bg-white`}>
        <div className="w-full overflow-auto">
          <ARTable
            isHighlightRowOnHover
            tableClass="mb-12"
            isLoading={isInLoadingState}
            columns={getColumns()}
            rows={getRows() || []}
            dataAutoId="PaymentHistory"
            renderNestedTable={isInSupplierContext && !customerId ? handleRenderNestedTable : undefined}
            getRowDataAutoId={getDataAutoID}
          />
        </div>
      </div>
    </div>
  );

  return (
    <div className={`${classes.paymentHistory} h-full`}>
      {viewingTx && (
        <TransactionView
          key={`${viewingTx?.txGuid}${viewingTx?.txId}${viewingTx?.txDatType}`}
          isShowInPdfView={true}
          transactionId={viewingTx?.txId}
          txDatType={viewingTx?.txDatType}
          txGuid={viewingTx?.txGuid}
          handleOnClosePdfView={() => handleCloseTx()}
          supplierId={_supplierId}
          customerId={_customerId}
          txList={txList}
          scope="ARPaymentHistory"
        />
      )}
      <div className="h-full w-full">{paymentHistoryPanel}</div>
    </div>
  );
};
