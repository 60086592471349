import React from 'react';
import {Typography} from 'spenda-ui-react';
import {AlertDialogSlideV2} from './AlertDialogSlideV2';

interface IPaymentSummaryProps {
  handleClose: () => void;
}

const PaymentSummary = ({handleClose}: IPaymentSummaryProps) => {
  return (
    <AlertDialogSlideV2
      title={'Payment summary'}
      size="sm"
      headingTextSize="h1"
      footerClass="flex justify-center"
      dialogClassess="md:!min-w-[620px]"
      dialogBodyClassess="flex max-h-[80vh] flex-col gap-1 overflow-y-auto px-10 pt-6"
      data-autoid={'dlgPaymentSummary'}
      actions={[
        {
          label: 'Close',
          action: handleClose,
        },
      ]}
    >
      <div className="mb-2 flex justify-between px-2">
        <div className="flex flex-col gap-2">
          <Typography variant="h3" className="text-justify font-medium text-spenda-primarytext">
            Payment Ref #PY-00006
          </Typography>
          <Typography variant="h3" className=" text-justify font-medium text-spenda-primarytext">
            Quote Ref #QT-00003
          </Typography>
        </div>
        <div className="rounded-xl border-4 border-[#e5f2ed] px-14 py-3 text-lg font-medium text-[#3C9F78] ">PAID</div>
      </div>
      <div className="mb-2 flex flex-col gap-2 px-2">
        <Typography className="text-black">By payment method: CreditCard</Typography>
        <Typography className="text-black">Paid on: 6th Sep 2024</Typography>
      </div>
      <div className="mb-3 flex w-[240px] flex-col gap-2 px-2">
        <div className="flex justify-between gap-2">
          <Typography className="text-black">Payment:</Typography>
          <Typography className="font-semibold text-spenda-primarytext"> $3,192.64</Typography>
        </div>
        <div className="flex justify-between gap-2">
          <Typography className="text-black">Surcharge:</Typography>
          <Typography className="font-semibold text-spenda-primarytext"> $0.0</Typography>
        </div>
        <div className="flex justify-between gap-2">
          <Typography className="text-black">Total:</Typography>
          <Typography className="font-semibold text-spenda-primarytext"> $3,192.64</Typography>
        </div>
      </div>

      <div className="rounded-lg bg-[#F6F6F6] p-2">
        <Typography variant="h3" className="mb-2 font-semibold text-spenda-primarytext">
          Payment applied on:
        </Typography>
        <Typography className="mb-2 text-black">IV-000014 - total $3,192.64</Typography>
        <div className="flex justify-between">
          <Typography className="text-black">Applied amount: $3,192.64</Typography>
          <Typography className="text-black">Remaining balance: $0.00</Typography>
        </div>
      </div>
    </AlertDialogSlideV2>
  );
};

export default PaymentSummary;
