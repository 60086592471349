import React from 'react';
import {Box} from '@material-ui/core';
import {css} from 'glamor';
import SVGIcon, {SVGName} from '../ui/SVGIcon';
import {CustomContent} from '../ui/CustomContent';

interface ISMenuItemProps {
  // item: IMenuOption,
  iconSrc?: SVGName | string;
  isActive?: boolean;
  name?: string;
}

export const SMenuItem = ({iconSrc, isActive, name}: ISMenuItemProps) => {
  const container = css({
    display: 'flex',
    justifyContent: 'center',
    marginLeft: '0.25rem',
    marginRight: '0.25rem',
    padding: '2px 6px',
    borderRadius: '5px 5px 6px 6px',
    position: 'relative',
    '> div': {
      display: 'inline-block',
      '> svg': {
        display: 'inline',
        marginRight: '0.25rem',
      },
      '> span': {
        display: 'none',
        '@media(min-width: 768px)': {
          display: 'inline',
        },
      },
      '&:after': {
        content: "''",
        display: 'block',
        transform: 'scaleX(0)',
        borderRadius: '6px',
        transition: 'transform 250ms ease-in-out',
      },
      '&:hover:after': {
        transform: 'scaleX(1)',
      },
      '> img': {
        display: 'inline',
      },
    },
  });

  const active = css({
    backgroundColor: '#ffffff !important',
    '> div': {
      '&:after': {
        content: "''",
        display: 'block',
        borderRadius: '6px',
        transform: 'scaleX(1)',
      },
    },
  });

  const APmenuItem = css({
    marginRight: '9px!important',
    '> svg': {
      marginRight: '9px!important',
    },
  });

  return (
    <Box className={`${container} ${isActive && active}`}>
      <div className={`${APmenuItem}`}>
        <SVGIcon name={iconSrc} width="29px" height="26px" />
        <span className={`ml-0.5 font-poppins text-base font-semibold text-primary`}>
          <CustomContent keyPath={`accountReceivables.header.subMenu.${name}`}>{name}</CustomContent>
        </span>
      </div>
    </Box>
  );
};
