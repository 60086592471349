import useHttp from '../hooks/useHttp';
import {IActionResults} from '../model/ActionResults';
import {IInvoice} from '../model/invoice/Invoice';

export const useInvoiceAPI = () => {
  const {GET} = useHttp();

  const get = (id: number): Promise<IInvoice> => {
    return GET(`invoice/${id}`).then((data: IActionResults<IInvoice>) => data.Value);
  };

  const getInvoiceByTransID = (id: number): Promise<IInvoice> => {
    return GET(`CustomerStatements/invoice?transID=${id}`).then((data: IInvoice) => data);
  };

  return {
    getInvoice: get,
    getInvoiceByTransID,
  };
};
