import React from 'react';
//Icon
const IconEnvelope = (props: {width?: string; height?: string}) => {
  return (
    <svg
      width={props.width || '20px'}
      height={props.height || '18px'}
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>{'send quotes'}</title>
      <g id="send quotes" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <path
          d="M79.1666667,0 L20.8333333,0 C9.33311187,0.0137754847 0.0137754847,9.33311187 0,20.8333333 L0,70.8333333 C0.0137754847,82.3335548 9.33311187,91.6528912 20.8333333,91.6666667 L79.1666667,91.6666667 C90.6668881,91.6528912 99.9862245,82.3335548 100,70.8333333 L100,20.8333333 C99.9862245,9.33311187 90.6668881,0.0137754847 79.1666667,0 Z M20.8333333,8.33333333 L79.1666667,8.33333333 C84.2709259,8.34337329 88.856326,11.4558374 90.75,16.1958333 L58.8416667,48.1083333 C53.9528207,52.9776021 46.0471793,52.9776021 41.1583333,48.1083333 L9.25,16.1958333 C11.143674,11.4558374 15.7290741,8.34337329 20.8333333,8.33333333 Z M79.1666667,83.3333333 L20.8333333,83.3333333 C13.929774,83.3333333 8.33333333,77.7368927 8.33333333,70.8333333 L8.33333333,27.0833333 L35.2666667,54 C43.4096875,62.1224354 56.5903125,62.1224354 64.7333333,54 L91.6666667,27.0833333 L91.6666667,70.8333333 C91.6666667,77.7368927 86.070226,83.3333333 79.1666667,83.3333333 Z"
          id="Shape"
          className="fill-primary"
        />
      </g>
    </svg>
  );
};

export default IconEnvelope;
