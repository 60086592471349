import React, {useContext, useState} from 'react';
import {makeStyles, TableContainer, Table, TableBody, TableCell, TableHead, TableRow, Button} from '@material-ui/core';

import {SButton} from '../../components/buttons/Button';
import {ManageSchedulerBatchModal} from '../../components/accountsPayableOnboarding/ManageScheduler';
import {useHistory} from 'react-router-dom';
import {AUTH_XERO_ADAPTER_SYNC_DETAILS} from '../../routes/AccountsPayableRoutes';
import {IAdaptorSummary} from '../../model/synkd/AdaptorSummary';
import moment from 'moment';
import useWorkflowAPI from '../../services/useWorkflowAPI';
import {RefreshIcon} from '../../assets/svg/RefreshIcon';
import {css} from 'glamor';
import {DatTypes} from '../../model/constants/Constants';
import {IWorkflowStatusResponse} from '../../model/workflow/WorkflowResponse';
import {IntegrationContext} from '../../context/IntegrationContext';
import {ARAdaptorView} from '../AccountsReceivable/ARsettingsView/ARAdaptorView';

const useXeroAdaptorInvoicesStyles = makeStyles({
  invoicesTable: {
    borderRadius: '2px 2px 2px 2px',
    margin: '28px 0 0 0',
    '& table': {
      borderBottom: '2px solid #F8F8F8',
      borderRadius: '2px',
      '& th': {
        color: '#999999',
        fontWeight: 600,
        fontSize: '14px',
        fontFamily: 'Poppins',
        padding: '10px!important',
        lineHeight: '14px',
        height: '40px',
        '& .MuiTableSortLabel-root': {
          paddingRight: '15px',
          '& .MuiTableSortLabel-icon': {
            margin: '0px -7px 0px 0px',
            position: 'absolute',
            right: '0',
            transform: 'rotate(0deg)',
            opacity: '1',
            fontSize: '20px',
            '&.MuiTableSortLabel-iconDirectionAsc': {
              color: '#999',
            },
            '&.MuiTableSortLabel-iconDirectionDesc': {
              color: '#999',
            },
          },
          '&.MuiTableSortLabel-active': {
            color: '#999999',
            '& .MuiTableSortLabel-iconDirectionDesc': {
              color: '#9e9e9e',
            },
            '& .MuiTableSortLabel-iconDirectionAsc': {
              color: '#1c78ad!important',
            },
          },
        },
      },
      '& .MuiTableHead-root': {
        background: '#F1F1F1',
      },
      '& .MuiTableCell-root': {
        borderBottom: '1px solid #F8F8F8',
        borderLeft: '0px',
        padding: '8px',
        position: 'relative',
      },
      '& .MuiTableCell-body': {
        padding: '7px 10px',
        height: '40px',
        '&:nth-child(2)': {
          background: '#FAFAFA',
        },
        '& button': {
          padding: '0px',
          textTransform: 'unset',
          minWidth: 'auto',
          textAlign: 'left',
        },
      },
    },
  },

  scheduled: {
    background: '#8178CF20',
    color: '#8178CF',
    fontSize: '10px',
    borderRadius: '4px',
    width: '80px',
    height: '24px',
  },
  manual: {
    background: '#C68A1920',
    color: '#C68A19',
    fontSize: '10px',
    borderRadius: '4px',
    width: '80px',
    height: '24px',
  },
});

const dateFormater = (date: string) => moment(date).format('Do MMM YYYY, hh:mm a');
interface IAPXeroAdaptorInvoicesProps {
  syncDetails?: IAdaptorSummary[];
  refreshSyncDetails?: () => void;
  scope?: 'AR' | 'PSBL';
}

export const APXeroAdaptorInvoices = (props: IAPXeroAdaptorInvoicesProps) => {
  const classes = useXeroAdaptorInvoicesStyles();
  const history = useHistory();
  const [isScheduler, setIsScheduler] = useState<boolean>(false);
  const {syncDetails, scope, refreshSyncDetails} = props;
  const {synkDataRead, checkWorkflowStatus} = useWorkflowAPI();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  let workflowStatusTimer: ReturnType<typeof setInterval>;
  const {financialAdaptor} = useContext(IntegrationContext);
  const isPSBLScope = scope === 'PSBL';

  const setSchedulerHandleChanged = () => {
    setIsScheduler(false);
  };

  const animationSpin = css({
    animation: 'spin 2s linear infinite',
  });

  const hanldeSyncInvoice = async () => {
    setIsLoading(true);
    try {
      if (financialAdaptor?.Name && financialAdaptor?.ID) {
        const synkDataRes = await synkDataRead(
          true,
          'Read',
          financialAdaptor.Name,
          financialAdaptor.ID,
          DatTypes.PurchaseInvoice,
          29,
          true,
        );
        workflowStatusTimer = setInterval(() => {
          synkDataRes?.Value?.WorkflowID && checkWorkflowStatusFun(synkDataRes.Value.WorkflowID);
        }, 5000);
      }
    } catch {
      setIsLoading(false);
    }
  };

  const checkWorkflowStatusFun = (workFlowId: number) => {
    if (workFlowId) {
      checkWorkflowStatus(workFlowId)
        .then((data: IWorkflowStatusResponse) => {
          if (data.Status === 'Complete') {
            if (refreshSyncDetails) {
              refreshSyncDetails();
            }
            setIsLoading(false);
            clearTimeout(workflowStatusTimer);
          }
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  };

  if (isPSBLScope) {
    return (
      <ARAdaptorView
        isSyncCompleted={!isLoading}
        isScheduler={isScheduler}
        setIsScheduler={setIsScheduler}
        dateFormater={dateFormater}
        hanldeSyncInvoice={hanldeSyncInvoice}
      />
    );
  }

  return (
    <>
      <div className="mr-5 flex items-center justify-between">
        <h2 className={`font-light text-spenda-primarytext`}>{`${
          financialAdaptor?.Name === 'MyObAccountRightLive' ? 'MyOB' : financialAdaptor?.Name
        } adapter`}</h2>
        <div className="flex items-center">
          <RefreshIcon
            height="20px"
            width="20px"
            className={`${isLoading ? animationSpin : ''}`}
            style={{minWidth: '14px', marginRight: '7px'}}
          />
          <SButton
            textColor={'blueShade'}
            width="120px"
            height="40px"
            lineHeight="l"
            fontSize="xs"
            boxShadow="none"
            type="submit"
            backgroundColor={'#fff'}
            padding="7px 20px!important"
            variant={'contained'}
            borderRadius="6px"
            border="1px solid #1C78AD"
            margin="0px 10px 0px 0px"
            data-autoid="btnSyncInvoices"
            disabled={isLoading}
            onClick={hanldeSyncInvoice}
          >
            Sync Invoices
          </SButton>
          <SButton
            textColor={'white'}
            width="100px"
            height="40px"
            lineHeight="l"
            fontSize="xs"
            boxShadow="none"
            type="submit"
            backgroundColor={'#1C78AD'}
            padding="7px 20px!important"
            margin="0"
            variant={'contained'}
            borderRadius="6px"
            onClick={() => {
              setIsScheduler(true);
            }}
            data-autoid="btnManageScheduler"
          >
            Manage Scheduler
          </SButton>
          <ManageSchedulerBatchModal open={isScheduler} handleClose={setSchedulerHandleChanged} />
        </div>
      </div>
      <div className="mt-6">
        <TableContainer className={`${classes.invoicesTable}`}>
          <Table className="" aria-label="simple table" data-autoid="tblPurchaseInvoice">
            <TableHead>
              <TableRow>
                <TableCell align="left" style={{width: '60%'}} className="pl-20">
                  Last update
                </TableCell>
                <TableCell align="left" style={{width: '40%'}}>
                  Invoices/batches synced
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {syncDetails &&
                syncDetails.map(item => {
                  return (
                    <TableRow
                      key={item.ID}
                      onClick={() => {
                        history.push(AUTH_XERO_ADAPTER_SYNC_DETAILS.replace(/:adaptorLogId/g, String(item.ID)));
                      }}
                      className="cursor-pointer"
                      data-autoid={`lnkSyncDetails-${item.ID}`}
                    >
                      <TableCell className="text-black-800">
                        <span className="font-poppins text-base font-medium text-black-800">
                          {dateFormater(item.ActionDateTimeUTC)}
                        </span>
                      </TableCell>
                      <TableCell className="text-black-800">
                        <Button>
                          <span className="tooltip-wrap cursor-pointer font-poppins text-base font-medium text-black-800 underline">
                            {item?.count} invoices
                          </span>
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};
