import {IAccountResponse, IAccountResponseWithFee} from '../../services/useSpendaPaymentServicesAPI';
import {ITenantSupplierPaymentMethod} from '../../model/payment/PaymentMethod';
import {VerificationStatus} from '../../model/constants/Constants';

export class PaymentConverter {
  public static SpsToFiserveModel(pm: IAccountResponse | IAccountResponseWithFee, fees88078?: boolean): any {
    const {
      bankDetails,
      cardDetails,
      friendlyName,
      isInvigoDefault,
      accountGUID,
      verificationStatus,
      paymentMethod,
      retriesLeft,
      paymentService,
      isPayerFeeApplicable,
      payLaterDetails,
      marketplaceFacility,
      isAttentionRequired,
      attentionRequiredReason,
      paymentAmount,
      buyerTotalAmount,
      fees,
    } = pm;

    const randomNumber = Math.trunc(Math.random() * 1000);
    return {
      BankAccountNumber: bankDetails?.bankAccountNumber,
      BankBSB: bankDetails?.bankBSB,
      BankName: bankDetails?.bankName,
      CardHolderName: cardDetails?.accountHolderName,
      Expiry: cardDetails?.expiry,
      FriendlyName: friendlyName,
      IsInvigoDefault: isInvigoDefault,
      Last4: cardDetails?.last4,
      PaymentAccountGUID: accountGUID,
      PaymentAccountVerificationStatus: verificationStatus,
      PaymentMethod: paymentMethod,
      RetriesLeft: retriesLeft,
      PaymentService: paymentService,
      IsPayerFeeApplicable: isPayerFeeApplicable,
      IsAttentionRequired: isAttentionRequired,
      AttentionRequiredReason: attentionRequiredReason,
      FundingRate: payLaterDetails?.fundingRate,
      //TODO: Look for some better approach
      SupplierPaymentOptionID: verificationStatus === VerificationStatus.VERIFIED ? randomNumber : -randomNumber,

      /********** InvigoPayLater and MarketplaceFacilty details **********/
      CreditAvailable: payLaterDetails?.creditAvailable || marketplaceFacility?.availableBalance,
      DefaultRepaymentMethodLast4:
        payLaterDetails?.defaultRepaymentMethodDescription?.slice(-4) || marketplaceFacility?.repaymentLast4,
      DefaultRepaymentMethodType:
        payLaterDetails?.defaultRepaymentMethodType || marketplaceFacility?.repaymentServiceType,
      TradingTermDays: payLaterDetails?.tradingTermDays || marketplaceFacility?.facilityTradingTermDays,
      RepaymentAccountConfigIssues: marketplaceFacility?.repaymentAccountConfigIssues ?? '',
      TradingTermName: payLaterDetails?.tradingTermName || marketplaceFacility?.facilityTradingTermName,
      FacilityID: marketplaceFacility?.facilityID,
      /********** InvigoPayLater and MarketplaceFacilty details **********/

      /***********New Fee Response*******************/
      Fees: fees88078 ? fees : undefined,
      PaymentAmount: fees88078 ? paymentAmount : undefined,
      TotalAmount: fees88078 ? buyerTotalAmount : undefined,
    } as ITenantSupplierPaymentMethod;
  }
}
