import {Menu} from '@material-ui/core';
import React, {useEffect, useRef, useState} from 'react';
import SearchInvoiceIcon from '../../assets/svg/IconSearch';
import {Input, Select, Option, IconButton, Spinner, InputProps} from 'spenda-ui-react';

interface ISearchBarExpandedProps {
  isSearching: boolean;
  renderMenuItems: JSX.Element;
  handleSearch: () => void;
  onCategoryChange?: (searchCat: string) => void;
  searchCategoriesOptions?: {label: string; value: string}[];
  selectedSearchCategory?: string;
  isShowOptions?: boolean;
  label?: string;
}

export const ARExpandedSearchBar = React.forwardRef(
  (props: InputProps & ISearchBarExpandedProps, ref: React.Ref<HTMLInputElement> | undefined) => {
    const optionRef = useRef<HTMLDivElement | null>(null);
    const {
      selectedSearchCategory,
      isSearching,
      renderMenuItems,
      searchCategoriesOptions,
      onCategoryChange,
      handleSearch,
      isShowOptions,
      label,
      ...restProps
    } = props;

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    useEffect(() => {
      isShowOptions ? setAnchorEl(optionRef?.current) : setAnchorEl(null);
    }, [isShowOptions]);

    const handleCloseOptions = () => {
      setAnchorEl(null);
    };

    return (
      <>
        <div className="search-container !w-full">
          <div id="ar-expaned-search-bar" className="!relative flex flex-row !whitespace-nowrap" ref={optionRef}>
            <Input
              {...restProps}
              ref={ref}
              label={label}
              onBlur={e => !anchorEl && props?.onBlur?.(e)}
              onClick={e => e.stopPropagation()}
              onKeyDown={e => {
                if (e.keyCode === 13) {
                  handleSearch();
                }
              }}
              autoComplete="off"
              data-autoid="txtSearchInput"
              containerProps={{
                className: `relative [&>*]:!rounded-r-none !pr-[42px] mr-1 ${!searchCategoriesOptions?.length ? '[&>*]:!rounded-r-lg' : ''} [&>*]:!border-r-white`,
              }}
              labelProps={{className: `after:!rounded-t-none !overflow-hidden`}}
            />
            <div className={`absolute right-[0px] flex flex-row items-center`}>
              <>
                {isSearching && <Spinner color="primary" className="!z-[20000]" />}
                {!!searchCategoriesOptions?.length && (
                  <div className="flex flex-row items-center">
                    <div className="flex h-[24px] w-[2px] flex-col bg-[#ddd]"></div>
                    {searchCategoriesOptions?.length === 1 ? (
                      <p
                        className={`flex !h-[38px] min-w-[133px] max-w-[133px] items-center justify-center pl-2 !font-poppins text-base font-medium text-black-800`}
                      >
                        {searchCategoriesOptions?.[0]?.label}
                      </p>
                    ) : (
                      <Select
                        className={`!z-[19999] !h-[38px] min-w-[200px] max-w-[200px] !border-none pl-2 !font-poppins text-black-800`}
                        onChange={value => onCategoryChange?.(value as string)}
                        name={'searchSelect'}
                        value={selectedSearchCategory}
                        data-autoid="ddlFilterCategory"
                        menuProps={{
                          getContentAnchorEl: null,
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                        }}
                        inputProps={{
                          name: 'selectedSearchCategory',
                        }}
                      >
                        {searchCategoriesOptions?.map(s => (
                          <Option
                            key={s?.value}
                            value={s?.value}
                            className={`${searchCategoriesOptions && 'min-w-[122px]'}`}
                          >
                            <span className="text-black-800" data-autoid={`ddlItem-${s?.label}`}>
                              {s?.label}
                            </span>
                          </Option>
                        ))}
                      </Select>
                    )}
                  </div>
                )}
              </>
              <IconButton
                data-autoid="btnSearch"
                onClick={() => {
                  handleSearch();
                }}
                className={`!h-[45px] !max-h-[45px] !w-[51px] !max-w-[51px] rounded-l-none`}
              >
                <SearchInvoiceIcon fill="#fff" />
              </IconButton>
            </div>
          </div>
        </div>
        {!isSearching && isShowOptions && (
          <Menu
            id="vendor-search-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleCloseOptions}
            MenuListProps={{'aria-labelledby': 'search-vendor-input'}}
            PopoverClasses={{root: 'arAdvanceSelectPopover !z-[19999]', paper: 'arAdvanceSelectMenuPaper'}}
            classes={{paper: 'arAdvanceSelectMenuPaper'}}
            getContentAnchorEl={null}
            anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
            transformOrigin={{vertical: 'top', horizontal: 'left'}}
          >
            <div className="w-full" onClick={() => handleCloseOptions()}>
              {renderMenuItems}
            </div>
          </Menu>
        )}
      </>
    );
  },
);
