import React, {useState, useEffect} from 'react';
import {Box} from '@material-ui/core';
import {SButton} from '../buttons/Button';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NextArrowIcon from '../../assets/svg/next-arrow.svg';
import BackIcon from '../../assets/svg/back.svg';
import {css} from 'glamor';
import useAccountAPI from '../../services/useAccountAPI';
import {IAccounts} from '../../model/accounts/Accounts';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {GetBackgroundCSS, IPayments} from './Onboarding';
import {DatTypes} from '../../model/constants/Constants';
import useWorkflowAPI from '../../services/useWorkflowAPI';
import {Toast} from '../../utils/Toast';
import {IMasterAdaptor} from '../../model/MasterAdaptor';
import LoadingOverLay from '../ui/LoadingOverlay';
import DeleteIcon from '@material-ui/icons/Delete';
import RefreshIcon from '@material-ui/icons/Refresh';
import LoadingIndicator from '../ui/LoadingIndicator';
import {SModal} from '../modals/modalSpendaMeterialUI';
import {ChatWidget} from './ChatWidget';
import CloseIcon from '@material-ui/icons/Close';

interface PaymentSetupProps {
  onNextClick: () => void;
  onBackPress: () => void;
  setPaymentsMethods: (payments: IPayments[]) => void;
  defaultPayments: IPayments[];
  selectedAdaptor?: IMasterAdaptor;
  handleClose: () => void;
  isBuyer: boolean;
}

export const PaymentSetup: React.FunctionComponent<PaymentSetupProps> = ({
  onNextClick,
  onBackPress,
  setPaymentsMethods,
  defaultPayments,
  selectedAdaptor,
  handleClose,
  isBuyer,
}: PaymentSetupProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {getAccounts, submitPayment, getPaymentMethods} = useAccountAPI();
  const [clearsToAccounts, setClearsToAccounts] = useState<IAccounts[]>([]);
  // const [, setNewPaymentError] = useState<any>({});
  const [paymentsError, setPaymentsError] = useState<any>({});

  const getChartAccounts = () => {
    getAccounts().then((data: IAccounts[]) => {
      setClearsToAccounts(data);
    });
  };

  useEffect(() => {
    if (clearsToAccounts && clearsToAccounts.length === 0) {
      getChartAccounts();
    }
    getPaymentMethods().then(data => {
      if (Array.isArray(data?.Settings) && data.Settings.length) {
        const _payments = data.Settings.map((method: any) => ({
          clearsTo: method?.Account?.ID,
          paymentMethod: method?.PaymentMethodName,
          surcharge: method?.MerchantSurchargePercent?.toFixed(2),
        }));
        setPayments(_payments);
      }
    });
  }, []);

  const labelStyles = css({
    color: '#006c91',
    fontSize: '0.85rem',
    fontWeight: '400',
  });
  const {synkDataRead} = useWorkflowAPI();
  const [payments, setPayments] = useState(defaultPayments);
  const [showNewPayment, setShowNewPayment] = useState(false);
  const [newPayment, setNewPayment] = useState({
    paymentMethod: '',
    clearsTo: '',
    surcharge: '',
  });

  const isPaymentValid = (payment: any) => {
    const error: any = {};
    // if (!payment.paymentMethod) {
    //   error.paymentMethod = "Payment method required !!!";
    // }

    if (!payment.clearsTo) {
      error.clearsTo = 'Clears to required !!!';
    }

    // if (String(payment.surcharge).trim() == "") {
    //   error.surcharge = "Number is required !!!";
    // }

    return error;
  };

  const refreshAccounts = async () => {
    if (selectedAdaptor && selectedAdaptor?.Name && selectedAdaptor?.ID) {
      setIsLoading(true);
      await synkDataRead(false, 'Read', selectedAdaptor.Name, selectedAdaptor.ID, DatTypes.Accounts, 15);
      await getChartAccounts();
      setIsLoading(false);
      Toast.info('Chart of Accounts imported Successfully.');
    }
  };

  const handlePaymentsChange = (event: any, index: number, onBlur: boolean = false) => {
    if (event.target.name === 'surcharge' && (event.target.value < 0 || event.target.value > 100)) return;
    let value = event.target.value
      .toString()
      .split('.')
      .map((el: string, i: number) => (i ? el.split('').slice(0, 2).join('') : el))
      .join('.');

    if (onBlur) {
      value = parseFloat(value).toFixed(2);
    }
    const _tempPayments: Array<any> = [...payments];
    _tempPayments[index][event.target.name] = value;
    setPayments(_tempPayments);

    const _paymentsErrors = {...paymentsError};
    _paymentsErrors[index] = isPaymentValid(_tempPayments[index]);
    setPaymentsError(_paymentsErrors);
  };

  const handleNewPaymentChange = (event: any, onBlur: boolean = false) => {
    if (event.target.name === 'surcharge' && (event.target.value < 0 || event.target.value > 100)) return;

    let value = event.target.value
      .toString()
      .split('.')
      .map((el: string, i: number) => (i ? el.split('').slice(0, 2).join('') : el))
      .join('.');

    if (onBlur) {
      value = parseFloat(value).toFixed(2);
    }
    const _newPayment: any = {...newPayment};
    _newPayment[event.target.name] = value;
    setNewPayment(_newPayment);
  };

  const addNewPayment = () => {
    const error = isPaymentValid(newPayment);
    // setNewPaymentError(error);
    if (Object.values(error).length) {
      return;
    }
    setPaymentsError({});

    if (payments.find(payment => payment.paymentMethod === newPayment.paymentMethod)) {
      Toast.error('Payment Method is already selected.');
      return;
    }
    setPayments(prevPayments => [...prevPayments, newPayment]);
    setNewPayment({
      paymentMethod: '',
      clearsTo: '',
      surcharge: '',
    });
    setShowNewPayment(false);
  };

  const deletePayment = (index: number) => {
    const _payments = [...payments];
    _payments.splice(index, 1);
    setPaymentsError({});
    setPayments(_payments);
  };

  const checkIsPaymentValid = () => {
    const errors: any = {};
    let hasError = false;
    payments.forEach((payment: any, index: number) => {
      errors[index] = isPaymentValid(payment);
      if (Object.values(errors[index]).length) {
        hasError = true;
      }
    });
    setPaymentsError(errors);
    return hasError;
  };
  const onSubmitPayment = async () => {
    const hasError = checkIsPaymentValid();
    if (hasError) {
      return;
    }
    setIsLoading(true);
    const paymentsData = {
      Settings: payments.map(payment => {
        return {
          PaymentMethodName: payment.paymentMethod.replace(/ /g, ''),
          MerchantSurchargePercent: payment.surcharge,
          Account: {
            ID: payment.clearsTo,
            IsActive: Boolean(
              (clearsToAccounts.find(clearsToAccount => clearsToAccount.ID === Number(payment.clearsTo)) || {})
                .IsActive,
            ),
          },
        };
      }),
    };
    submitPayment(paymentsData)
      .then(() => {
        setPaymentsMethods(payments);
        onNextClick();
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        alert('Failed to submit payment');
      });
  };

  return (
    <>
      <SModal open>
        <div
          className={`spenda-color flex h-full max-w-full items-center justify-center text-xl ${GetBackgroundCSS()}`}
        >
          <LoadingOverLay isLoading={isLoading}>
            <div className="modal-box max-w-3xl px-5 py-5" id="payment-setup">
              {isBuyer && (
                <span className="closeIcon onboarding-close-icon" onClick={handleClose} data-autoid="btnClose">
                  <CloseIcon />
                </span>
              )}
              <span
                className="cursor-pointer"
                style={{position: 'relative'}}
                onClick={onBackPress}
                data-autoid="btnBack"
              >
                <img src={BackIcon} alt="Next Arrow Icon" className="back-btn-bg-image" style={{marginTop: '-7px'}} />
                <ChevronLeftIcon className="back-btn-icon" />
              </span>
              <div className="row flex justify-center">
                <Box
                  display="column"
                  justifyContent="center"
                  alignContent="center"
                  alignItems="center"
                  alignSelf="center"
                >
                  <p id="payment-setup-description" className="simple-modal-title mx-12 -mt-5 pb-3 text-center">
                    “Let’s setup your payment methods.”
                  </p>
                </Box>
              </div>
              <div className="mr-4 flex flex-col items-end justify-end" style={{marginLeft: 'auto'}}>
                <p style={{fontSize: 'small'}} className="mb-1">
                  <span style={{color: 'black'}}>Can't see your Chart of Accounts</span>
                  <span className="account-refresh-btn" onClick={refreshAccounts} data-autoid="btnRefreshAccounts">
                    <RefreshIcon /> Refresh
                  </span>
                </p>
              </div>
              <div className="payment-setup row mt-10 flex justify-center">
                <div className="payment-method-col w-full">
                  <table className="payment-setup-table mb-4 mt-4 w-full table-auto">
                    <thead className={`table-head`}>
                      <tr>
                        <th style={{width: '27%'}} className={`px-6 py-2 ${labelStyles}`}>
                          Payment Methods
                        </th>
                        <th style={{width: '40%'}} className={`px-6 py-2 ${labelStyles}`}>
                          Clears To
                        </th>
                        <th style={{width: '23%'}} className={`px-6 py-2 ${labelStyles}`}>
                          Surcharge %
                        </th>
                        <th className={`px-6 py-2 ${labelStyles}`}>Action</th>
                      </tr>
                    </thead>
                    <tbody className={` table-body sm:text-xs xl:text-base`}>
                      {payments &&
                        payments.map((item: any, index: number) => {
                          return (
                            <tr key={index}>
                              <td className="td-col1 px-6 py-3 ">
                                <p>{item.paymentMethod}</p>
                              </td>

                              <td className="clear-select px-6 py-2">
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  displayEmpty
                                  value={item.clearsTo}
                                  style={{
                                    color: 'black',
                                  }}
                                  onChange={event => handlePaymentsChange(event, index)}
                                  inputProps={{
                                    id: 'deviceSource-native-select',
                                    name: 'clearsTo',
                                    'data-autoid': `ddlClearsTo${item.paymentMethod}`,
                                  }}
                                  MenuProps={{
                                    getContentAnchorEl: null,
                                    anchorOrigin: {
                                      vertical: 'bottom',
                                      horizontal: 'left',
                                    },
                                  }}
                                >
                                  {/* <MenuItem value="">
                                                        None
                                                    </MenuItem> */}
                                  <MenuItem value="" disabled>
                                    <p className="opacity-50">Select Account</p>
                                  </MenuItem>
                                  {clearsToAccounts.map(({Name, ID, Code}) => (
                                    <MenuItem key={ID} value={ID}>
                                      {Code} - {Name}
                                    </MenuItem>
                                  ))}
                                </Select>
                                {/* {paymentsError[index] &&
                                paymentsError[index].clearsTo && (
                                  <span> {paymentsError[index].clearsTo} </span>
                                )} */}
                              </td>
                              <td className="table-input px-6 py-2 ">
                                {' '}
                                <input
                                  style={{textAlign: 'center'}}
                                  name="surcharge"
                                  data-id={index}
                                  type="number"
                                  min="0.0"
                                  max="100"
                                  step="0.01"
                                  value={item.surcharge}
                                  onBlur={event => handlePaymentsChange(event, index, true)}
                                  onChange={event => handlePaymentsChange(event, index)}
                                  placeholder="0.00"
                                  data-autoid={`txtSurcharge${item.paymentMethod}`}
                                />
                                {/* {paymentsError[index] &&
                              paymentsError[index].surcharge && (
                                <span> {paymentsError[index].surcharge} </span>
                              )} */}
                              </td>
                              <td className="px-6 py-2">
                                {!(
                                  item.paymentMethod === 'Cheque' ||
                                  item.paymentMethod === 'Eftpos' ||
                                  item.paymentMethod === 'Cash'
                                ) && (
                                  <button
                                    onClick={() => deletePayment(index)}
                                    className="delete-payment-icon"
                                    data-autoid={`btnDelete${item.paymentMethod}`}
                                  >
                                    <DeleteIcon className="deleteBtn" />
                                  </button>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="table-submit">
                <Box display="flex" justifyContent="center">
                  <SButton
                    className="butt-1 alt primaryBtn"
                    type="submit"
                    width="m"
                    lineHeight="m"
                    fontSize="s"
                    textColor="blueShade"
                    onClick={() => setShowNewPayment(true)}
                    data-autoid="btnAddNewPaymentMethod"
                  >
                    {' '}
                    Add New Payment Method
                  </SButton>
                  <div className="padd"></div>
                  <SButton
                    className="common-next-btn"
                    type="submit"
                    width="m"
                    lineHeight="m"
                    fontSize="s"
                    onClick={onSubmitPayment}
                    disabled={
                      payments[0]?.clearsTo === '' ||
                      payments[1]?.clearsTo === '' ||
                      payments[2]?.clearsTo === '' ||
                      isLoading
                    }
                    data-autoid="btnNext"
                  >
                    Next
                    {isLoading ? (
                      <LoadingIndicator isLoading={isLoading} size="sm" color="#FFF" position={{right: '5px'}} />
                    ) : (
                      <img src={NextArrowIcon} alt="Next Arrow Icon" className="" />
                    )}
                  </SButton>
                </Box>
              </div>
              <div className="live-chat-section">
                <ChatWidget />
              </div>
            </div>
          </LoadingOverLay>
        </div>
      </SModal>

      <SModal open={showNewPayment}>
        <div className="spenda-color flex h-full max-w-full items-center justify-center text-xl">
          <div className="w-full max-w-2xl bg-white p-8" id="add-payment-setup">
            <div className="row flex justify-center">
              <Box
                display="column"
                justifyContent="center"
                alignContent="center"
                alignItems="center"
                alignSelf="center"
              >
                <p id="payment-setup-description" className="simple-modal-title mb-8 mt-2 text-center">
                  Add new payment method
                </p>
              </Box>
            </div>
            <div className="payment-setup row mt-0 flex w-full justify-start">
              <table className="payment-setup-table mb-4 mt-4 w-full table-auto">
                <thead className={`table-head`}>
                  <tr>
                    <th style={{width: '30%'}} className={`px-4 py-2 ${labelStyles}`}>
                      Payment Methods
                    </th>
                    <th style={{width: '40%'}} className={`px-4 py-2 ${labelStyles}`}>
                      Clears To
                    </th>
                    <th className={`px-4 py-2 ${labelStyles}`}>Surcharge %</th>
                    <th style={{width: '10%'}} className={`px-4 py-2 ${labelStyles}`}></th>
                  </tr>
                </thead>
                <tbody className={` table-body sm:text-xs xl:text-base`}>
                  <tr>
                    <td className="td-col1 clear-select px-4 py-2 text-center">
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={newPayment.paymentMethod}
                        displayEmpty
                        style={{
                          color: 'black',
                        }}
                        onChange={e => handleNewPaymentChange(e, false)}
                        inputProps={{
                          id: 'deviceSource-native-select',
                          name: 'paymentMethod',
                          'data-autoid': `ddlPaymentMethod`,
                        }}
                        MenuProps={{
                          getContentAnchorEl: null,
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                        }}
                      >
                        {/* <MenuItem value="">
                                                    None
                                                </MenuItem> */}
                        <MenuItem value="" disabled>
                          <p className="opacity-50">Select Method</p>
                        </MenuItem>
                        <MenuItem value={'PayPal'}>PayPal </MenuItem>
                        <MenuItem value={'Credit Card'}> Credit Card </MenuItem>
                        <MenuItem value={'Afterpay'}> Afterpay </MenuItem>
                        <MenuItem value={'Direct Debit'}> Direct Debit </MenuItem>
                        <MenuItem value={'American Express'}> American Express </MenuItem>
                        <MenuItem value={'Square'}> Square </MenuItem>
                        <MenuItem value={'Diners Club'}> Diners Club </MenuItem>
                        <MenuItem value={'Union Pay'}> Union Pay </MenuItem>
                        <MenuItem value={'Mastercard'}> Mastercard </MenuItem>
                        <MenuItem value={'Visa'}> Visa </MenuItem>
                      </Select>
                      {/* {newPaymentError.paymentMethod && (
                        <span> {newPaymentError.paymentMethod} </span>
                      )} */}
                    </td>
                    <td className="clear-select px-4 py-2 text-center">
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={newPayment.clearsTo}
                        displayEmpty
                        style={{
                          color: 'black',
                        }}
                        onChange={e => handleNewPaymentChange(e, false)}
                        inputProps={{
                          id: 'deviceSource-native-select',
                          name: 'clearsTo',
                          'data-autoid': `ddlClearsTo`,
                        }}
                        MenuProps={{
                          getContentAnchorEl: null,
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                        }}
                      >
                        {/* <MenuItem value="">
                                                    None
                                                </MenuItem> */}
                        <MenuItem value="" disabled>
                          <p className="opacity-50">Select Account</p>
                        </MenuItem>
                        {clearsToAccounts.map(({Name, ID, Code}) => (
                          <MenuItem key={ID} value={ID}>
                            {Code} - {Name}
                          </MenuItem>
                        ))}
                      </Select>
                      <br />
                      {/* {newPaymentError.clearsTo && (
                        <span> {newPaymentError.clearsTo} </span>
                      )} */}
                    </td>
                    <td className="table-input px-4 py-2 text-center">
                      <input
                        style={{textAlign: 'center'}}
                        name="surcharge"
                        type="number"
                        min="0.0"
                        max="100"
                        step="0.01"
                        value={newPayment.surcharge}
                        onBlur={event => handleNewPaymentChange(event, true)}
                        onChange={e => handleNewPaymentChange(e, false)}
                        placeholder="0.00"
                        data-autoid="txtSurcharge"
                      />
                      {/* {newPaymentError.surcharge && (
                        <span> {newPaymentError.surcharge} </span>
                      )} */}
                    </td>
                    <td className="px-4 py-2 text-center">
                      <p id="payment-setup-description"></p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="table-submit">
              <Box display="flex" justifyContent="space-between">
                <SButton
                  className="butt-1 alt primaryBtn"
                  type="submit"
                  width="m"
                  lineHeight="m"
                  fontSize="s"
                  textColor="blueShade"
                  onClick={() => {
                    setNewPayment({
                      paymentMethod: '',
                      clearsTo: '',
                      surcharge: '',
                    });
                    setShowNewPayment(false);
                  }}
                  data-autoid="btnCancelPaymentMethod"
                >
                  {' '}
                  Cancel
                </SButton>
                <div className="padd"></div>
                <SButton
                  className="common-next-btn addBtn"
                  type="submit"
                  width="m"
                  lineHeight="m"
                  fontSize="s"
                  disabled={newPayment.clearsTo === '' || newPayment.paymentMethod === ''}
                  onClick={addNewPayment}
                  data-autoid="btnAddPaymentMethod"
                >
                  Add{' '}
                </SButton>
              </Box>
            </div>
          </div>
        </div>
      </SModal>
    </>
  );
};
