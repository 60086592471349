import React, {useEffect} from 'react';
import {FormikProps} from 'formik';
import {cloneDeep} from 'lodash';
import {FileRejection} from 'react-dropzone';

import {IConversationChatbox, IConvoGetMessagesResponse} from '../../model/conversation/Conversation';
import {formInitialValues, IConversationChatboxInput} from './ChatBox';

import {Box, Button, makeStyles, TextField} from '@material-ui/core';
import {UploadAttachments} from './UploadAttachments';
import {SendChatButton} from '../../assets/svg/SendChatButton';
import {RefreshARMessages} from '../../assets/svg/RefreshARMessages';

interface ChatBoxFooterProps {
  conversationData: IConversationChatbox;
  messageData: IConvoGetMessagesResponse | undefined;
  isLoading: boolean;
  loadMessages: (isScrollToBottom?: boolean) => void;
}

let errorTimeoutId: number = 0;

export const ChatBoxFooter = (props: FormikProps<IConversationChatboxInput> & ChatBoxFooterProps) => {
  const {
    conversationData,
    messageData,
    touched,
    errors,
    isLoading,
    handleChange,
    handleBlur,
    setErrors,
    setTouched,
    setFieldError,
    setFieldTouched,
    setFieldValue,
    handleSubmit,
    loadMessages,
  } = props;
  const {operationUniqueId} = conversationData;
  const {subjectName, subjectId, isSubjectSelected, message, attachments} = props.values;

  const useChatBoxFooterStyles = makeStyles(() => ({
    inputSet: {
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '8px 8px',
      margin: '12px 20px',
      borderRadius: '8px',
      border: '1px solid #E2E2E2',
      minHeight: '47px',
      bottom: 0,
      width: 'inherit',
      backgroundColor: '#FAFAFA',
    },
    inputMessage: {
      fontFamily: 'poppins !important',
      '& .MuiInputBase-input::placeholder': {
        fontFamily: 'poppins !important',
        fontSize: '12px',
        color: '#B0B0B0',
      },
      '& .MuiInputBase-input': {
        color: 'black !important',
      },
      '& input[type="text"]:disabled': {
        cursor: 'not-allowed',
      },
    },
  }));

  const useChatBoxFooterCss = makeStyles(() => ({
    refreshButton: {
      border: '1px solid #e9f2f7',
      backgroundColor: '#e9f2f7',
      borderRadius: '4px',
      minWidth: '25px',
      width: '25px',
      marginRight: '8px',
      '&:hover': {
        backgroundColor: '#e9f2f7',
      },
      '& .MuiButton-label': {
        width: '25px',
      },
      '& .MuiTouchRipple-root': {
        width: '25px',
      },
      '& .MuiButton-startIcon': {
        margin: 0,
      },
    },
    chatBoxError: {
      position: 'absolute',
      background: '#fff',
      width: '60%',
      left: ' 20%',
      bottom: '10%',
      textAlign: 'center',
      fontSize: '12px',
      fontWeight: 400,
      color: '#C55D44 !important',
      boxShadow: '0 0 2px 0 #888;',
      padding: '10px 0',
      borderRadius: '25px',
    },
  }));

  const classes = useChatBoxFooterStyles();
  const chatboxCss = useChatBoxFooterCss();

  useEffect(() => {
    return () => {
      window.clearTimeout(errorTimeoutId);
    };
  }, []);

  const handleRejectedFile = (rejectedFile: FileRejection[]) => {
    const errorCode = rejectedFile[0].errors[0].code;
    const errorMessage = rejectedFile[0].errors[0].message;
    let errorMsg = '';
    switch (errorCode) {
      case 'file-invalid-type':
        errorMsg = `This document type is not allowed, ${errorMessage}`;
        break;
      case 'file-too-large':
        errorMsg = `File is larger than 10MB`;
        break;
      default:
        errorMsg = `Some error occurred`;
        break;
    }
    setFieldTouched('attachments', true);
    setFieldError('attachments', errorMsg);
  };

  const handleAcceptedFile = (acceptedFile: any) => {
    const attachedFiles = cloneDeep(attachments) || [];
    if (attachedFiles?.length >= 4) {
      setFieldError('attachments', 'You can send 4 files at a time');
    } else if (acceptedFile?.length) {
      attachedFiles.push(acceptedFile[0]);
      setFieldValue('attachments', attachedFiles);
    }
  };

  const resetErrors = (setErrors: any, setTouched: any) => {
    window.clearTimeout(errorTimeoutId);
    errorTimeoutId = window.setTimeout(() => {
      setErrors({});
      setTouched({});
    }, 5000);
  };

  return (
    <Box className={`${classes.inputSet} flex flex-col`}>
      <Box className={`flex flex-row items-center w-full`}>
        <Box className={`flex flex-row items-center w-full`}>
          <Button
            data-autoid={`btnRefreshMessage-${operationUniqueId}`}
            onClick={() => loadMessages(true)}
            variant="contained"
            className={`${chatboxCss.refreshButton}`}
            startIcon={<RefreshARMessages className={isLoading ? 'animate-[spin_2s_linear_infinite]' : ''} />}
          >
            {''}
          </Button>
          {subjectId === 'other' ? (
            <TextField
              className={`${classes.inputMessage} w-full`}
              placeholder={'Enter new subject ...'}
              variant="standard"
              value={subjectName}
              name={`subjectName`}
              onChange={handleChange}
              inputProps={{
                'data-autoid': `txtTypeSubjectName-${operationUniqueId}`,
              }}
            />
          ) : (
            <TextField
              className={`${classes.inputMessage} w-full`}
              disabled={isSubjectSelected || messageData?.subject ? false : true}
              placeholder={!isSubjectSelected ? 'Please select a subject' : 'Type your message here'}
              variant="standard"
              value={message}
              name={`message`}
              onChange={handleChange}
              inputProps={{
                'data-autoid': `txtTypeMessage-${operationUniqueId}`,
              }}
            />
          )}
          {Object.keys(formInitialValues).map(formKey => {
            touched[formKey as keyof IConversationChatboxInput] &&
              errors[formKey as keyof IConversationChatboxInput] &&
              resetErrors(setErrors, setTouched);
            return (
              touched[formKey as keyof IConversationChatboxInput] &&
              errors[formKey as keyof IConversationChatboxInput] && (
                <div
                  key={`errorKey-${formKey}-${operationUniqueId}`}
                  className={`${chatboxCss.chatBoxError}`}
                  data-autoid={`lbl${formKey[0].toUpperCase() + formKey.slice(1)}Error-${operationUniqueId}`}
                >
                  {/* @ts-ignore */}
                  {errors[formKey as keyof IConversationChatboxInput]}
                </div>
              )
            );
          })}
        </Box>
        <UploadAttachments
          handleAcceptedFile={handleAcceptedFile}
          handleRejectedFile={handleRejectedFile}
          handleBlur={handleBlur}
          handleChange={handleChange}
          isSubjectSelected={isSubjectSelected}
          operationUniqueId={operationUniqueId}
        />
        <Box
          className="flex flex-row items-center"
          data-autoid={`btnSendMessage-${operationUniqueId}`}
          onClick={() => handleSubmit()}
        >
          <SendChatButton className={`cursor-pointer ml-3`} />
        </Box>
      </Box>
    </Box>
  );
};
