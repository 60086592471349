import React from 'react';

export const IconPaymentSubmit = (props: {width?: string; height?: string}) => {
  return (
    <svg
      width={props?.width}
      height={props?.height}
      viewBox="0 0 83 86"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Payment Submitted</title>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Spenda-Collect-Supplier-Interface-Copy-16" transform="translate(-671.000000, -253.000000)">
          <g id="Group-2" transform="translate(555.000000, 195.000000)">
            <g id="Payment-Submitted" transform="translate(116.000000, 58.000000)">
              <g id="Icons/Documents/paper_plane" transform="translate(0.000000, 3.759040)" fill="#1C78AD">
                <path
                  d="M14.7497335,11.7939679 C8.32731581,12.6747564 2.9582001,17.117746 0.891298386,23.2619428 C-1.17560333,29.4061396 0.41651463,36.1908814 5.00077649,40.7743059 L10.8713366,46.641449 C11.5130475,47.2829655 11.8732573,48.15337 11.8725439,49.0607485 L11.8725439,59.8860888 C11.8800851,61.4081637 12.2304702,62.9089802 12.8976709,64.2770491 L12.8703341,64.3009687 L12.9591785,64.3898131 C13.9604311,66.4029231 15.5957437,68.0310317 17.6132547,69.0233867 L17.702099,69.1122311 L17.7260186,69.0848943 C19.0940875,69.7520949 20.594904,70.1024801 22.1169789,70.1100213 L32.9423192,70.1100213 C33.8480235,70.1093082 34.7169631,70.4681914 35.3582016,71.1078115 L41.2253447,76.9749545 C44.4037781,80.188588 48.733565,82.0004563 53.2535006,82.0083277 C55.118384,82.0060093 56.9705564,81.7013529 58.7379296,81.106216 C64.8261577,79.1070713 69.2543303,73.8287531 70.1646776,67.4856963 L81.6973555,12.6926625 C82.5652844,9.21432769 81.5369607,5.53599303 78.9910204,3.01204734 C76.4993502,0.490150731 72.8710609,-0.546503357 69.4231692,0.278375557 C61.6054518,1.92498228 55.7421637,3.15993732 51.833305,3.98324069 M50.8675558,4.1858102 C51.6858694,8.66495152 52.5191075,10.8152405 53.36727,10.6366772 C57.0726905,9.85657633 62.6308213,8.68642506 70.0416624,7.12622337 L18.6998892,58.4748307 L18.6998892,49.0607485 C18.7103394,46.3436716 17.633752,43.7352541 15.7099357,41.8165183 L9.83595846,35.9493752 C7.04352037,33.2241932 6.08158252,29.1286295 7.36881967,25.4452414 C8.61718873,21.6653091 8.91377672,19.8687069 12.8703341,19.4305668 L15.7099357,18.6805749 L16.4565359,11.4342119 M63.4500963,66.3170517 C62.9149617,70.1666334 60.2488414,73.3825288 56.5651066,74.6217809 C52.8813717,75.8610331 48.8136135,74.9104943 46.0605267,72.1671093 L40.1831323,66.2897149 C38.2669209,64.3629817 35.6596931,63.2826862 32.9423192,63.2895102 L23.528237,63.2895102 L74.8768444,11.9579883 L63.4500963,66.3170517 Z"
                  id="Shape"
                ></path>
              </g>
              <circle
                id="Oval"
                stroke="#1C78AD"
                strokeWidth="5"
                cx="34.4074088"
                cy="22.4396145"
                r="19.9396145"
              ></circle>
              <circle id="Oval" fill="#FFFFFF" cx="34.4074088" cy="22.4396145" r="17.4530335"></circle>
              <g id="Icons/Finance/dollar" transform="translate(27.545873, 10.967794)" fill="#1C78AD">
                <path
                  d="M8.16569259,3.65682606 C9.71410622,3.65833794 10.9689663,4.91319801 10.9704782,6.46161165 C10.9704782,6.96651396 11.3797824,7.37581816 11.8846847,7.37581816 C12.389587,7.37581816 12.7988912,6.96651396 12.7988912,6.46161165 L12.7988912,6.3994456 C12.7988912,6.38207568 12.7988912,6.36653417 12.7988912,6.34916425 C12.7354448,3.83538199 10.6802748,1.83009088 8.16569259,1.82841303 L7.31365212,1.82841303 L7.31365212,0.914206515 C7.31365212,0.409304199 6.90434792,0 6.3994456,0 C5.89454329,0 5.48523909,0.409304199 5.48523909,0.914206515 L5.48523909,1.82841303 L4.63319862,1.82841303 C2.36226223,1.83083805 0.427804299,3.4788739 0.0645915832,5.72057738 C-0.298621133,7.96228086 1.01648161,10.1368278 3.17046819,10.8562024 L5.48523909,11.6287069 L5.48523909,18.2841303 L4.63319862,18.2841303 C3.08478498,18.2826184 1.82992491,17.0277583 1.82841303,15.4793447 C1.82841303,14.9744424 1.41910883,14.5651382 0.914206515,14.5651382 C0.409304199,14.5651382 0,14.9744424 0,15.4793447 L0,15.5415108 C0,15.5588807 0,15.5744222 0,15.5917921 C0.0634464339,18.1055744 2.11861637,20.1108655 4.63319862,20.1125433 L5.48523909,20.1125433 L5.48523909,21.0267498 C5.48523909,21.5316522 5.89454329,21.9409564 6.3994456,21.9409564 C6.90434792,21.9409564 7.31365212,21.5316522 7.31365212,21.0267498 L7.31365212,20.1125433 L8.16569259,20.1125433 C10.436629,20.1101183 12.3710869,18.4620825 12.7342996,16.220379 C13.0975123,13.9786755 11.7824096,11.8041286 9.62842301,11.084754 L7.31365212,10.3122495 L7.31365212,3.65682606 L8.16569259,3.65682606 Z M9.05247291,12.8180895 C10.3581154,13.2526457 11.1556799,14.5702044 10.9353167,15.9285053 C10.7149534,17.2868062 9.54175256,18.2846388 8.16569259,18.2841305 L7.31365212,18.2841305 L7.31365212,12.2384826 L9.05247291,12.8180895 Z M5.48523909,9.70247374 L3.74824671,9.12286681 C2.44293263,8.68841988 1.64539175,7.3713846 1.86525695,6.01335358 C2.08512215,4.65532255 3.25748468,3.6572146 4.63319862,3.65682606 L5.48523909,3.65682606 L5.48523909,9.70247374 Z"
                  id="Shape"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
