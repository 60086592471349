import React, {useContext, useState} from 'react';
import {ARSelectToggler} from '../../../components/AccountsReceivable/ARSelectToggler';
import {ARStatementPeriodDefaultOptions} from '../../../model/constants/Constants';
import IconFilter from '../../../assets/svg/IconFilter';
import {AdvanceFilter} from '../../../components/AccountsReceivable/AdvanceFilter';
import {ARSearchBar} from '../../../components/AccountsReceivable/ARSearchBar';
import {SwitchSlider} from '../../../components/form/SwitchSlider';
import {IBuyerStatementFilterQuery} from '../../../services/useSupplierTransactionsAPI';
import moment from 'moment';
import {IconButton, Select, Option, Typography} from 'spenda-ui-react';
import ARContext from '../../../context/ar-context/ARContext';

interface IPsblTableHeaderProps {
  handleSelectAll: () => void;
  isSelectedAll: boolean;
  statementOptions: {name: string | null; value: string}[] | undefined;
  setTransactionFilterQuery: React.Dispatch<React.SetStateAction<IBuyerStatementFilterQuery | undefined>>;
  transactionFilterQuery: IBuyerStatementFilterQuery | undefined;
  isVendorPresent?: boolean;
  isShowInGroupView?: boolean;
  onChangeGroupView: () => void;
  selectAllCount?: number;
}

export const PsblTableHeader = (props: IPsblTableHeaderProps) => {
  // Props
  const {
    handleSelectAll,
    isSelectedAll,
    statementOptions,
    transactionFilterQuery,
    setTransactionFilterQuery,
    isVendorPresent,
    isShowInGroupView,
    onChangeGroupView,
    selectAllCount,
  } = props;

  // Hooks
  const {setSelectedStatementPeriod, selectedStatementPeriod} = useContext(ARContext);

  const onStatementPeriodChange = (value: string | undefined) => {
    try {
      if (value) {
        setSelectedStatementPeriod(value);
        let date = undefined;
        if (value === ARStatementPeriodDefaultOptions.END_OF_LAST_MONTH) {
          date = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
        }

        const parseStatementId = parseInt(value) || undefined;
        setTransactionFilterQuery({
          ...transactionFilterQuery,
          filterStatementID: parseStatementId,
          asAtDate: date,
        });
      }
    } catch {}
  };

  const [isAdvanceFilter, setIsAdvanceFilter] = useState<boolean>(false);

  return (
    <>
      <div className="rounded-md">
        <div className={`mx-2.5 flex items-center justify-between border-b border-[#D8D8D8] pb-2.5`}>
          <div className="flex flex-row items-center gap-2.5">
            <span
              className={`select-symbol !m-0 items-center font-poppins text-base font-normal`}
              onClick={() => handleSelectAll()}
              data-autoid={`chkSelectAllViewTransactions`}
            >
              <ARSelectToggler isSelected={isSelectedAll} />{' '}
              <Typography variant="h2" className="cursor-pointer font-medium text-primary">
                Select All
                <Typography
                  variant="paragraph"
                  className="inline cursor-pointer text-xl font-medium text-primary"
                  data-autoid="txtAllCount"
                >
                  {selectAllCount ? `(${selectAllCount})` : ''}
                </Typography>
              </Typography>
            </span>
            <div className="min-w-[360px]">
              <ARSearchBar
                type="advance"
                searchCategories={[
                  {value: 'RefNumber', label: 'Ref Number'},
                  {value: 'VendorName', label: 'Vendor Name'},
                  {value: 'Amount', label: 'Amount'},
                ]}
                searchString={transactionFilterQuery?.searchString || ''}
                searchCategory={transactionFilterQuery?.searchField || (isShowInGroupView ? 'VendorName' : 'RefNumber')}
                isSearching={false}
                onSubmit={({searchString, searchCategory}) => {
                  setTransactionFilterQuery(oldValues => ({
                    ...oldValues,
                    searchString: searchString,
                    searchField: searchString ? searchCategory : undefined,
                  }));
                }}
                dataAutoIdSuffix={'PSBLTableHeader'}
              />
            </div>
          </div>
          {isAdvanceFilter && (
            <AdvanceFilter
              onSubmit={filteredValues => {
                setSelectedStatementPeriod(ARStatementPeriodDefaultOptions.ALL);
                setTransactionFilterQuery({
                  ...filteredValues,
                  asAtDate:
                    filteredValues?.asAtDate === moment().format('DD/MM/YYYY') ? filteredValues?.asAtDate : undefined,
                  filterStatementID: undefined,
                });
              }}
              transactionFilterQuery={transactionFilterQuery}
              open={isAdvanceFilter}
              handleClose={() => setIsAdvanceFilter(false)}
            />
          )}
          <div className="flex flex-row items-center">
            <IconButton variant="outlined" data-autoid={'btnAdvanceFilter'} onClick={() => setIsAdvanceFilter(true)}>
              <IconFilter />
            </IconButton>
            {statementOptions?.length && (
              <div className="ml-2.5 flex !min-w-[185px] flex-col py-1">
                <Select
                  size="md"
                  variant="outlined"
                  label="Filter"
                  value={selectedStatementPeriod}
                  onChange={e => onStatementPeriodChange(e)}
                  data-autoid={`ddlChoosePsblViewStatementPeriod`}
                  containerProps={{className: '!min-w-[185px]'}}
                >
                  {statementOptions?.map(option => (
                    <Option
                      data-autoid={`lblSelectBatchPeriodOptions${option.value}`}
                      key={option.value}
                      value={option.value}
                    >
                      {option.name}
                    </Option>
                  ))}
                </Select>
              </div>
            )}
          </div>
        </div>
      </div>
      {isVendorPresent && (
        <span className="flex flex-row items-center justify-end py-2.5 pr-[22px] text-base">
          Group Invoice
          <div className="-mr-5 pl-3 text-right">
            <SwitchSlider
              data-autoid={`btnGroupInvoice`}
              handleChange={() => onChangeGroupView()}
              name="groupInvoice"
              checked={isShowInGroupView || false}
            ></SwitchSlider>
          </div>
        </span>
      )}
    </>
  );
};
