import React from 'react';

export const Visa = (props: {
  width?: string,
  height?: string,
}) => (
  <svg viewBox="0 0 359 117" width={props.width || "35px"} height={props.height || "35px"}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g>
        <polyline
          fill="#FFF"
          points="155.805 114.517 126.606 114.517 144.855 2.365 174.057 2.365 155.805 114.517"
        />
        <path
          d="M102.042,2.365 L74.202,79.501 L70.911,62.89 L70.914,62.896 L61.086,12.466 C61.086,12.466 59.901,2.365 47.235,2.365 L1.218,2.365 L0.681,4.261 C0.681,4.261 14.757,7.189 31.221,17.086 L56.589,114.517 L87.012,114.517 L133.467,2.365 L102.042,2.365"
          fill="#FFF"
        />
        <path
          d="M299.577,74.845 L314.922,32.872 L323.553,74.845 L299.577,74.845 Z M331.707,114.517 L358.518,114.517 L335.142,2.365 L311.667,2.365 C300.825,2.365 298.188,10.723 298.188,10.723 L254.643,114.517 L285.075,114.517 L291.165,97.861 L328.284,97.861 L331.707,114.517 L331.707,114.517 Z"
          fill="#FFF"
        />
        <path
          d="M256.926,29.335 L261.09,5.248 C261.09,5.248 248.232,0.358 234.828,0.358 C220.338,0.358 185.931,6.694 185.931,37.486 C185.931,66.463 226.314,66.823 226.314,82.036 C226.314,97.249 190.092,94.531 178.14,84.934 L173.796,110.113 C173.796,110.113 186.831,116.449 206.757,116.449 C226.68,116.449 256.743,106.126 256.743,78.052 C256.743,48.892 215.994,46.177 215.994,33.499 C215.994,20.821 244.431,22.45 256.926,29.335"
          fill="#FFF"
        />
        <path
          d="M70.914,62.896 L61.086,12.466 C61.086,12.466 59.901,2.365 47.235,2.365 L1.218,2.365 L0.681,4.261 C0.681,4.261 22.8,8.848 44.019,26.02 C64.299,42.436 70.914,62.896 70.914,62.896"
          fill="#EE9F3F"
        />
      </g>
    </g>
  </svg>
);