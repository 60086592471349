import ar_detail from './AR-details.png';
import ar_invoice from './AR-invoice.png';
import ar_selectInvoice from './AR-selectinvoice.png';
import ar_sendInvoice from './AR-sendinvoice.png';

export const AROnboardingImages = {
  ar_detail,
  ar_invoice,
  ar_selectInvoice,
  ar_sendInvoice,
};
