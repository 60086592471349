import React, {useState} from 'react';
import {SDialog} from '../modals/modalSpendaMeterialUI';
import {Box, DialogActions, DialogContent, DialogTitle, makeStyles} from '@material-ui/core';
import {SButton} from '../buttons/Button';

interface ISuccessPennyVerificationDialogProps {
  onClosePennyDialog: () => void;
  isCreditCard: boolean;
}

export const SuccessPennyVerificationDialog = (props: ISuccessPennyVerificationDialogProps) => {
  const [open, setOpen] = useState(true);

  const useStyles = makeStyles({
    dialog: {
      fontSize: 18,
      fontWeight: 500,
      justifyContent: 'center',
      maxWidth: 600,
      minWidth: 600,
      minHeight: 670,
      position: 'absolute',
    },
    dialogTitle: {
      color: '#0082BA',
      padding: 38,
    },
    dialogActions: {
      justifyContent: 'center',
      paddingBottom: 44,
    },
  });
  const classes = useStyles();

  const onClose = () => {
    setOpen(false);
    props.onClosePennyDialog();
  };

  return (
    <>
      <SDialog open={open} classes={{paper: classes.dialog}}>
        <DialogTitle classes={{root: classes.dialogTitle}}>
          <Box display="flex" alignItems="center" fontSize="33px" justifyContent="center" fontFamily={'Poppins'}>
            Successful {props.isCreditCard ? 'Card' : 'Account'} Verification
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box fontSize="24px" paddingTop={3} textAlign="center" fontFamily={'Poppins'}>
            <Box>Your {props.isCreditCard ? 'credit card' : 'bank account'} has been verified.</Box>
            <Box paddingTop={2} marginBottom="-20px">
              You're ready to start processing payments.
            </Box>
          </Box>
        </DialogContent>
        <DialogActions classes={{root: classes.dialogActions}}>
          <SButton color="blue" lineHeight="m" width="m" onClick={onClose}>
            Done
          </SButton>
        </DialogActions>
      </SDialog>
    </>
  );
};
