import React from 'react';

import {Layout} from '../../../../components/layout/Layout';
import {CreateCataloguePanel} from './CreateCataloguePanel';

export const CreateCatalogue = () => {
  const _leftPanel = <CreateCataloguePanel />;

  return (
    <div className={`relative h-full overflow-hidden bg-spenda-newbg font-poppins`}>
      <Layout leftPanel={_leftPanel} splitWidthType={4} />
    </div>
  );
};
