import React, {PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {makeStyles} from '@material-ui/core';
import {css} from 'glamor';
import moment from 'moment';
import {useParams} from 'react-router';
import {Link} from 'react-router-dom';
import {CreditNotesIcon} from '../../../assets/svg/CreditNotesIcon';
import {IconAmexCardRebranded} from '../../../assets/svg/IconAmexCardRebranded';
import {IconBankBlue} from '../../../assets/svg/IconBankBlue';
import {IconMasterCardRebranded} from '../../../assets/svg/IconMasterCardRebranded';
import {IconPaymentMethods} from '../../../assets/svg/IconPaymentMethods';
import {IconSpendaFinance} from '../../../assets/svg/IconSpendaFinance';
import {IconVisaCardRebranded} from '../../../assets/svg/IconVisaCardRebranded';
import {IconDropdownSupplierView} from '../../../assets/svg/accounts-payable/IconDropdownSupplierView';
import {ARTable, IARColumns} from '../../../components/AccountsReceivable/ARTable';
import {TransactionView} from '../../../components/AccountsReceivable/TransactionView';
import PaymentMethodsTooltip from '../../../components/data-display/PaymentMethodsTooltip';
import {Layout} from '../../../components/layout/Layout';
import {IntegrationContext} from '../../../context/IntegrationContext';
import {useTransactionViewHook} from '../../../hooks/useARHook';
import {useFeatureFlags} from '../../../hooks/useFeatureFlags';
import {useTenantInfo} from '../../../hooks/useTenantInfo';
import {InvoicePaymentAllocationStatus, ARPaymentMethodIconsStatus, DatTypes} from '../../../model/constants/Constants';
import {
  IPaymentHistoryDetails,
  ISupplierGroupedPaymentHistoryWithTotal,
  ISupplierPaymentsResponse,
} from '../../../model/customer/CustomerStatements';
import {IBuyerPaymentsResponse} from '../../../model/supplier/SupplierTransaction';
import {useCustomerStatementsAPI} from '../../../services/useCustomerStatementsAPI';
import {useSupplierTransactionsAPI} from '../../../services/useSupplierTransactionsAPI';
import {PriceFormat} from '../../../utils/formatter';
import {MarketplaceLayout} from '../../purchasing/MarketplaceLayout';
import {IntegrationStatusBadge} from '../../../components/integration/IntegrationStatusBadge';
import {ConfirmCancelBatchPaymentModal} from '../../../components/AccountsReceivable/modals/ConfirmCancelBatchPaymentModal';
import IconBinTrash from '../../../assets/svg/IconBinTrash';
import {EditPaymentDateDialog} from '../../../components/AccountsReceivable/EditPaymentDateDialog';
import {CanNotDeleteSchedulePaymentModal} from '../../../components/AccountsReceivable/modals/CanNotDeleteSchedulePaymentModal';
import {
  Button,
  Tab,
  TabPanel,
  Tabs,
  TabsBody,
  TabsHeader,
  Typography,
  Tooltip,
  useTabs,
  Option,
  Select,
  Checkbox,
} from 'spenda-ui-react';
import {PSBLFooter} from '../../../components/AccountsReceivable/PSBLFooter';
import {AUTH_PAYMENT_AUTHORISATION} from '../../../routes/AuthenticatedRoutes';
import IconAirplusPaymentMethod from '../../../assets/svg/payment-methods/IconAirPlusPaymentMethod';
import clsx from 'clsx';
import {ARSearchBar, IARSearchBarSearch} from '../../../components/AccountsReceivable/ARSearchBar';
import {CustomContent} from '../../../components/ui/CustomContent';

const useFilterStyles = makeStyles({
  paymentHistory: {
    '& .pdf-transaction-view': {
      top: '0px',
    },
  },
});

export const ARPaymentMethodsFilters = {
  Visa: [ARPaymentMethodIconsStatus.Visa],
  Mastercard: [ARPaymentMethodIconsStatus.Mastercard],
  AmericanExpress: [ARPaymentMethodIconsStatus.AmericanExpress],
  CreditNotes: [ARPaymentMethodIconsStatus.CreditNotes],
  BankTransfer: [ARPaymentMethodIconsStatus.BankTransfer],
  Finance: [ARPaymentMethodIconsStatus.Invigo, ARPaymentMethodIconsStatus.SpendaFinance],
  AirPlus: [ARPaymentMethodIconsStatus.AirPlus],
  Capricorn: [ARPaymentMethodIconsStatus.CapricornDSD],
};

const ARPaymentHistoryPaymentStatusFilters = {
  Cancelled: [InvoicePaymentAllocationStatus.Cancelled],
  Cleared: [InvoicePaymentAllocationStatus.Cleared],
  Declined: [InvoicePaymentAllocationStatus.Declined],
  Failed: [InvoicePaymentAllocationStatus.Failed],
  InProgress: [InvoicePaymentAllocationStatus.InProgress],
  Paid: [InvoicePaymentAllocationStatus.Paid],
};

const ARUpcomingPaymentStatusFilters = {
  Scheduled: [InvoicePaymentAllocationStatus.Scheduled],
  CancellationInProgress: [InvoicePaymentAllocationStatus.CancellationInProgress],
  InProgress: [InvoicePaymentAllocationStatus.InProgress],
  AwaitingAuthorisation: [InvoicePaymentAllocationStatus.AwaitingAuthorisation],
};

const InitialSeach = {searchString: '', searchCategory: 'RefNumber'};

enum ARPaymentsView {
  PaymentHistory = 'PaymentHistory',
  UpcomingPayments = 'UpcomingPayments',
}

export const ARPayments = () => {
  // Classes
  const classes = useFilterStyles();

  // Hooks
  const {isInBuyerContext, isInSupplierContext, isAdminUser, isPrimaryUser} = useTenantInfo();
  const {customerId, supplierId} = useParams<{customerId?: string; supplierId?: string}>();
  const {viewingTx, handleCloseTx} = useTransactionViewHook();

  // Feature Flag
  const {T2TPhase177852, scheduledPaymentsV2: scheduledPaymentsV2Buyer} = useFeatureFlags().supplierDriven();
  const {scheduledPaymentsV2: scheduledPaymentsV2Supplier} = useFeatureFlags().tenantOwned();
  const scheduledPaymentsV2 = isInBuyerContext ? scheduledPaymentsV2Buyer : scheduledPaymentsV2Supplier;

  // State
  const [supplierPaymentsData, setSupplierPaymentsData] = useState<ISupplierPaymentsResponse>();
  const [buyerPaymentsData, setBuyerPaymentsData] = useState<IBuyerPaymentsResponse>();
  const [expandedCustomer, setExpandedCustomer] = useState<ISupplierGroupedPaymentHistoryWithTotal>();
  const [selectedBucket, setSelectedBucket] = useState(ARPaymentsView.UpcomingPayments);
  const [search, setSearch] = useState<IARSearchBarSearch>(InitialSeach);
  const [paymentHistoryFilterBy, setPaymentHistoryTabFilterBy] = useState<string[][]>([]);
  const [upcomingPaymentsFilterBy, setUpcomingPaymentsFilterBy] = useState<string[][]>([]);
  const [isShowConfirmDeleteSPDialog, setIsConfirmDeleteSPDialog] = useState(false);
  const [clickedRowData, setClickedRowData] = useState<IPaymentHistoryDetails>();
  const [isShowEditPaymentDateDialog, setIsShowEditPaymentDateDialog] = useState(false);
  const [isShowCanNotDeleteSPDialog, setIsShowCanNotDeleteSPDialog] = useState(false);

  // APIs
  const {getSupplierPayments, isLoading: isSupplierLoading} = useCustomerStatementsAPI();
  const {getBuyerPayments, isLoading: isBuyerLoading} = useSupplierTransactionsAPI();

  // Context
  const {financialAdaptor} = useContext(IntegrationContext);

  // Constants
  const isPaymentHistoryView = ARPaymentsView.PaymentHistory === selectedBucket;
  const isUpcomingView = ARPaymentsView.UpcomingPayments === selectedBucket;
  const _supplierId = Number(supplierId) || undefined;
  const _customerId = Number(customerId) || undefined;
  const isLoading = isSupplierLoading || isBuyerLoading;
  const canDeleteScheduledPayment = isAdminUser || isPrimaryUser;
  const searchCategories = [
    {value: 'RefNumber', label: 'Payment Ref'},
    {value: 'VendorName', label: 'Vendor Name'},
    {value: 'Amount', label: 'Amount'},
    {value: 'InvoiceRefNumber', label: 'Invoice Ref'},
  ];

  useEffect(() => {
    setExpandedCustomer(undefined);
    fetchPaymentsData();
  }, [search]);

  useEffect(() => {
    setSelectedFilter();
  }, []);

  const filterRecords = useCallback(
    (list: IPaymentHistoryDetails[] | undefined) => {
      return list?.reduce((a: IPaymentHistoryDetails[], p: IPaymentHistoryDetails) => {
        if (p?.isCancellationPending) {
          p.status = InvoicePaymentAllocationStatus.CancellationInProgress;
        }

        let isValid = false;

        switch (selectedBucket) {
          case ARPaymentsView.UpcomingPayments:
            isValid =
              upcomingPaymentsFilterBy?.flat()?.includes(p?.status) &&
              p?.paymentMethodList?.some(r => upcomingPaymentsFilterBy?.flat()?.includes(r));
            break;
          case ARPaymentsView.PaymentHistory:
            isValid =
              paymentHistoryFilterBy?.flat()?.includes(p?.status) &&
              p?.paymentMethodList?.some(r => paymentHistoryFilterBy?.flat()?.includes(r));
            break;
          default:
            break;
        }

        if (isValid) {
          a?.push(p);
        }
        return a;
      }, []);
    },
    [paymentHistoryFilterBy, selectedBucket, upcomingPaymentsFilterBy],
  );

  const txList = useMemo(() => {
    let list: IPaymentHistoryDetails[] | undefined = [];
    if (isInBuyerContext) {
      list = isPaymentHistoryView
        ? buyerPaymentsData?.paymentHistory?.paymentHistories
        : buyerPaymentsData?.upcomingPayments?.paymentHistories;
    } else if (isInSupplierContext) {
      if (customerId) {
        list = isPaymentHistoryView
          ? supplierPaymentsData?.paymentHistory?.groupedPaymentHistoryWithTotals?.[0]?.paymentHistories
          : supplierPaymentsData?.upcomingPayments?.groupedPaymentHistoryWithTotals?.[0]?.paymentHistories;
      } else {
        list = expandedCustomer?.paymentHistories;
      }
    }
    return filterRecords(list)?.map(t => ({
      txId: t.transID,
      txGuid: t.transGUID,
      txDatType: t.datTypeID,
    }));
  }, [
    filterRecords,
    customerId,
    isInBuyerContext,
    isInSupplierContext,
    isPaymentHistoryView,
    buyerPaymentsData,
    supplierPaymentsData,
    expandedCustomer?.paymentHistories,
  ]);

  const fetchPaymentsData = async () => {
    isInSupplierContext && (await fetchSupplierPaymentsData());
    isInBuyerContext && (await fetchBuyerPaymentsData());
  };

  const fetchSupplierPaymentsData = async () => {
    try {
      const payload = {
        accountCustomerID: customerId || undefined,
        SearchString: search?.searchString || undefined,
        searchField: search?.searchString ? search?.searchCategory : undefined,
      };
      const response = await getSupplierPayments(payload);
      setSupplierPaymentsData(response);
    } catch {}
  };

  const fetchBuyerPaymentsData = async () => {
    try {
      if (!_supplierId) return;

      const query = {
        searchString: search?.searchString || undefined,
        searchField: search?.searchString ? search?.searchCategory : undefined,
      };
      const response = await getBuyerPayments(_supplierId, query);
      setBuyerPaymentsData(response);
    } catch {}
  };

  const setSelectedFilter = () => {
    const defaultPaymentMethodFilters = Object.values(ARPaymentMethodsFilters);
    const defaultHistoryPaymentStatusFilters = Object.values(ARPaymentHistoryPaymentStatusFilters);
    setPaymentHistoryTabFilterBy([...defaultHistoryPaymentStatusFilters, ...defaultPaymentMethodFilters]);
    const defaultUpcomingPaymentStatusFilters = Object.values(ARUpcomingPaymentStatusFilters);
    setUpcomingPaymentsFilterBy([...defaultUpcomingPaymentStatusFilters, ...defaultPaymentMethodFilters]);
  };

  const handleBucketChange = (value: ARPaymentsView) => {
    setSelectedBucket(value);
    setExpandedCustomer(undefined);
  };

  const handleSearchChange = (searchData: IARSearchBarSearch) => {
    setSearch(searchData);
  };

  const onClickCancelIPA = (rowData: IPaymentHistoryDetails) => {
    if (isInSupplierContext) return;

    if (canDeleteScheduledPayment) {
      setClickedRowData(rowData);
      setIsConfirmDeleteSPDialog(true);
      return;
    }
    setIsShowCanNotDeleteSPDialog(true);
  };

  const getNestedTableColumns = () => {
    return filterRecords(expandedCustomer?.paymentHistories);
  };

  const handleRenderNestedTable = (
    row: ISupplierGroupedPaymentHistoryWithTotal,
    order: 'asc' | 'desc',
    orderBy: string,
  ) => {
    if (expandedCustomer?.customer !== row?.customer) return;
    return (
      <ARTable
        isHighlightRowOnHover
        isPaginated
        isNestedTable
        rows={getNestedTableColumns() || []}
        columns={arPaymentsColums}
        isLoading={false}
        order={order}
        orderBy={orderBy}
      />
    );
  };

  const getColumns = () => {
    if (isInSupplierContext && !customerId) {
      return customerGroupColums;
    }
    return arPaymentsColums;
  };

  const getRows = useMemo(() => {
    if (isInSupplierContext) {
      const {paymentHistory, upcomingPayments} = supplierPaymentsData || {};
      if (customerId) {
        switch (selectedBucket) {
          case ARPaymentsView.UpcomingPayments:
            return filterRecords(upcomingPayments?.groupedPaymentHistoryWithTotals?.[0]?.paymentHistories);
          case ARPaymentsView.PaymentHistory:
            return filterRecords(paymentHistory?.groupedPaymentHistoryWithTotals?.[0]?.paymentHistories);
        }
      }
      switch (selectedBucket) {
        case ARPaymentsView.UpcomingPayments:
          return upcomingPayments?.groupedPaymentHistoryWithTotals;
        case ARPaymentsView.PaymentHistory:
          return paymentHistory?.groupedPaymentHistoryWithTotals;
      }
    }
    const {paymentHistory, upcomingPayments} = buyerPaymentsData || {};
    switch (selectedBucket) {
      case ARPaymentsView.UpcomingPayments:
        return filterRecords(upcomingPayments?.paymentHistories);
      case ARPaymentsView.PaymentHistory:
        return filterRecords(paymentHistory?.paymentHistories);
    }
  }, [buyerPaymentsData, customerId, filterRecords, isInSupplierContext, selectedBucket, supplierPaymentsData]);

  // Table Columns AR-Payments
  const arPaymentsColums: IARColumns[] = [
    {
      title: '',
      key: 'vendor-dropdown',
      columClassName: `text-spenda-labeltext !max-w-[32px] !min-w-[32px] ${isInBuyerContext ? 'hidden' : ''}`,
      rowClassName: `p-0 !max-w-[32px] !min-w-[32px] ${isInSupplierContext ? '!bg-primary-background' : 'hidden'}`,
      align: 'left',
      isSortable: false,
      rowRenderer: () => {
        return <></>;
      },
    },
    {
      title: 'Allocation',
      key: 'refNumber',
      width: isInSupplierContext ? '13%' : '14%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: `p-2.5 ${isInSupplierContext ? '!bg-primary-background' : ''}`,
      isSortable: true,
      rowRenderer: (rowData: IPaymentHistoryDetails) => {
        const path =
          DatTypes.InvoicePaymentAllocation === rowData.datTypeID
            ? `?txDatType=${rowData.datTypeID}&txId=${rowData.transID}`
            : `?txGuid=${rowData.transGUID}&txDatType=${rowData.datTypeID}&txId=${rowData.transID}`;

        return (
          <div className="flex flex-row items-center justify-between">
            <Link
              to={{
                pathname: location.pathname,
                search: path,
              }}
            >
              <Typography
                onClick={() => setClickedRowData(rowData)}
                data-autoid={`lnkAllocationRefNumber`}
                className="inline cursor-pointer overflow-ellipsis font-medium text-primary underline"
                variant="paragraph"
              >
                {rowData?.refNumber}
              </Typography>
            </Link>
            <div className="flex flex-row flex-wrap justify-end gap-x-2">
              <PaymentMethodsTooltip
                data-autoid={`rowPaymentHistory-${rowData?.transID}`}
                title={
                  <React.Fragment>
                    <div className="flex flex-row justify-start gap-2">
                      {rowData?.paymentMethodList?.map((t, i) => {
                        return (
                          <Typography
                            data-autoid={`btnAllocationPaymentMethod`}
                            key={i}
                            variant="paragraph"
                            className="inline"
                          >
                            {renderPaymentMethodIcons(t as ARPaymentMethodIconsStatus)}
                          </Typography>
                        );
                      })}
                    </div>
                  </React.Fragment>
                }
              >
                <button className="flex cursor-pointer items-center justify-center">
                  <IconPaymentMethods />
                </button>
              </PaymentMethodsTooltip>
            </div>
          </div>
        );
      },
    },
    {
      title: 'Payment date',
      key: 'paymentDateTime_utc',
      width: isInSupplierContext ? '12%' : '14%',
      align: 'right',
      columClassName: 'text-spenda-labeltext',
      rowClassName: `p-2.5 ${isInSupplierContext ? '!bg-primary-background' : ''}`,
      isSortable: true,
      rowRenderer: (rowData: IPaymentHistoryDetails) =>
        rowData?.isUpcomingPayment && rowData?.scheduledDateTime_utc && isInBuyerContext ? (
          <Typography
            data-autoid={`lblPaymentDate`}
            onClick={() => {
              setIsShowEditPaymentDateDialog(true);
              setClickedRowData(rowData);
            }}
            className="inline cursor-pointer overflow-ellipsis font-medium text-primary underline"
            variant="paragraph"
          >
            {moment(rowData?.paymentDateTime_utc).format('DD MMM YYYY')}
          </Typography>
        ) : (
          <Typography
            data-autoid={`lblPaymentDate`}
            className="inline overflow-ellipsis font-medium"
            variant="paragraph"
          >
            {moment(rowData?.paymentDateTime_utc).format('DD MMM YYYY')}
          </Typography>
        ),
    },
    {
      title: 'Invoice total',
      key: 'invoiceAppliedAmount',
      width: isInSupplierContext ? '13%' : '14%',
      align: 'right',
      columClassName: 'text-spenda-labeltext',
      rowClassName: `p-2.5 ${isInSupplierContext ? '!bg-primary-background' : ''}`,
      isSortable: true,
      rowRenderer: (rowData: IPaymentHistoryDetails) => (
        <Typography
          data-autoid={`lblInvoiceTotal`}
          className="inline overflow-ellipsis font-medium"
          variant="paragraph"
        >
          {PriceFormat(Number(rowData?.invoiceAppliedAmount?.toFixed(2)))}
        </Typography>
      ),
    },
    {
      title: 'Credit applied',
      key: 'creditNoteAppliedAmount',
      width: isInSupplierContext ? '13%' : '14%',
      align: 'right',
      columClassName: 'text-spenda-labeltext',
      rowClassName: `p-2.5 ${isInSupplierContext ? '!bg-primary-background' : ''}`,
      isSortable: true,
      rowRenderer: (rowData: IPaymentHistoryDetails) => (
        <Typography
          data-autoid={`lblCreditApplied`}
          className="inline overflow-ellipsis font-medium"
          variant="paragraph"
        >
          {PriceFormat(Number(rowData?.creditNoteAppliedAmount?.toFixed(2)))}
        </Typography>
      ),
    },
    {
      title: 'Payment amount',
      key: 'appliedPayments',
      width: isInSupplierContext ? '13%' : '14%',
      align: 'right',
      columClassName: 'text-spenda-labeltext',
      rowClassName: `p-2.5 ${isInSupplierContext ? '!bg-primary-background' : ''}`,
      isSortable: true,
      rowRenderer: (rowData: IPaymentHistoryDetails) => (
        <div className="relative h-[20px]">
          <Typography
            data-autoid={`lblPaymentAmount`}
            className="inline overflow-ellipsis font-medium"
            variant="paragraph"
          >
            {PriceFormat(Number(rowData?.appliedPayments?.toFixed(2)))}
          </Typography>
        </div>
      ),
    },
    {
      title: 'Payment status',
      key: 'status',
      width: isInSupplierContext ? '12%' : '15%',
      align: 'center',
      columClassName: 'text-spenda-labeltext',
      rowClassName: `p-2.5 ${isInSupplierContext ? '!bg-primary-background' : ''}`,
      isSortable: true,
      rowRenderer: (rowData: IPaymentHistoryDetails) => (
        <div className="relative h-[20px]">
          <div
            data-autoid={`lblPaymentStatus`}
            className={`text- overflow-ellipsis font-poppins text-base font-medium text-black-800`}
          >
            {scheduledPaymentsV2 &&
            rowData?.logMessage &&
            rowData?.status === InvoicePaymentAllocationStatus.Cancelled ? (
              <Tooltip
                placement="bottom"
                className={clsx('rounded-[4px] bg-white py-2 pl-3 pr-5 text-spenda-primarytext shadow-md')}
                content={
                  <p className="whitespace-nowrap text-center font-poppins text-sm font-medium text-spenda-primarytext">
                    {rowData?.logMessage}
                  </p>
                }
                arrow
              >
                {renderPaymentStatus(rowData?.status)}
              </Tooltip>
            ) : (
              renderPaymentStatus(rowData?.status)
            )}
          </div>
        </div>
      ),
    },
  ];

  const customerGroupColums = [
    {
      title: '',
      key: 'vendor-dropdown',
      columClassName: 'text-spenda-labeltext !max-w-[32px] !min-w-[32px]',
      rowClassName: 'p-0 !max-w-[32px] !min-w-[32px]',
      align: 'left',
      isSortable: false,
      getRowClassConditionally: (rowData: ISupplierGroupedPaymentHistoryWithTotal) =>
        rowData?.customer === expandedCustomer?.customer ? '!bg-primary-background' : '',
      rowRenderer: (rowData: ISupplierGroupedPaymentHistoryWithTotal) => {
        return (
          <>
            <span
              data-autoid={`btnRowVendor-${rowData.customer}`}
              className={`togglerIcon m-auto mr-[8px] flex !h-[14px] !w-[14px] cursor-pointer items-center justify-center rounded-[3px] border border-primary ${
                rowData?.customer !== expandedCustomer?.customer ? '' : '!rotate-180'
              }`}
              onClick={() => {
                if (rowData?.customer === expandedCustomer?.customer) setExpandedCustomer(undefined);
                else setExpandedCustomer(rowData);
              }}
            >
              <IconDropdownSupplierView className="w-[7px] rotate-180 transform" />
            </span>
          </>
        );
      },
    },
    {
      title: 'Customer',
      key: 'customer',
      width: '24%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: true,
      getRowClassConditionally: (rowData: ISupplierGroupedPaymentHistoryWithTotal) =>
        rowData?.customer === expandedCustomer?.customer ? '!bg-primary-background' : '',
      rowRenderer: (rowData: ISupplierGroupedPaymentHistoryWithTotal) => (
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-row items-center">
            <Typography
              data-autoid={`lblCustomer-${rowData.customer}`}
              className="inline overflow-ellipsis font-medium"
              variant="paragraph"
            >
              {rowData?.customer}
            </Typography>
          </div>
        </div>
      ),
    },
    {
      title: 'Allocation',
      key: 'refNumber',
      width: '13%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: true,
      getRowClassConditionally: (rowData: ISupplierGroupedPaymentHistoryWithTotal) =>
        rowData?.customer === expandedCustomer?.customer ? '!bg-primary-background' : '',
      rowRenderer: () => <span className="overflow-ellipsis font-poppins text-base font-medium text-black-800"></span>,
    },
    {
      title: 'Payment date',
      key: 'appliedDate',
      width: '12%',
      align: 'right',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: true,
      getRowClassConditionally: (rowData: ISupplierGroupedPaymentHistoryWithTotal) =>
        rowData?.customer === expandedCustomer?.customer ? '!bg-primary-background' : '',
      rowRenderer: () => <span className="overflow-ellipsis font-poppins text-base font-medium text-black-800"></span>,
    },
    {
      title: 'Invoice total',
      key: 'totalInc',
      width: '13%',
      align: 'right',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: true,
      getRowClassConditionally: (rowData: ISupplierGroupedPaymentHistoryWithTotal) =>
        rowData?.customer === expandedCustomer?.customer ? '!bg-primary-background' : '',
      rowRenderer: () => (
        <span className={`overflow-ellipsis font-poppins text-base font-medium text-black-800`}></span>
      ),
    },
    {
      title: 'Credit applied',
      key: 'appliedAmount',
      width: '13%',
      align: 'right',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: true,
      getRowClassConditionally: (rowData: ISupplierGroupedPaymentHistoryWithTotal) =>
        rowData?.customer === expandedCustomer?.customer ? '!bg-primary-background' : '',
      rowRenderer: () => (
        <span className={`overflow-ellipsis font-poppins text-base font-medium text-black-800 `}></span>
      ),
    },
    {
      title: 'Payment amount',
      key: 'balance',
      width: '13%',
      align: 'right',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: true,
      getRowClassConditionally: (rowData: ISupplierGroupedPaymentHistoryWithTotal) =>
        rowData?.customer === expandedCustomer?.customer ? '!bg-primary-background' : '',
      rowRenderer: () => (
        <div className="relative h-[20px]">
          <span className={`text- overflow-ellipsis font-poppins text-base font-medium text-black-800`}></span>
        </div>
      ),
    },
    {
      title: 'Payment status',
      key: 'status',
      width: '12%',
      align: 'center',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: true,
      getRowClassConditionally: (rowData: ISupplierGroupedPaymentHistoryWithTotal) =>
        rowData?.customer === expandedCustomer?.customer ? '!bg-primary-background' : '',
      rowRenderer: () => (
        <div className="relative h-[20px]">
          <span className={`text- overflow-ellipsis font-poppins text-base font-medium text-black-800`}></span>
        </div>
      ),
    },
  ];

  if (isInBuyerContext || customerId) {
    arPaymentsColums?.splice(0, 1);
  }

  if (isInBuyerContext && financialAdaptor && T2TPhase177852) {
    // Display integrationStatusColumn to the end of the arPaymentsColums array
    // if the buyer has an accounting adaptor and the feature flag is enabled
    const integrationStatusColumn = {
      title: 'Integration Status',
      key: 'integrationStatus',
      width: '15%',
      align: 'center',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: false,
      rowRenderer: (rowData: IPaymentHistoryDetails) => (
        <div className="relative h-[20px]">
          <span
            data-autoid={`lblIntegrationStatusARPaymentHistory`}
            className={`text- } overflow-ellipsis font-poppins text-base font-medium
          text-black-800`}
          >
            <IntegrationStatusBadge syncStatus={rowData.integrationStatus} syncError={rowData.integrationDetails} />
          </span>
        </div>
      ),
    };

    arPaymentsColums.splice(arPaymentsColums?.length - 1, 0, integrationStatusColumn);
  }

  if (isInBuyerContext && isUpcomingView) {
    arPaymentsColums?.splice(arPaymentsColums?.length + 1, 0, {
      title: 'Action',
      key: 'action',
      width: '5%',
      align: 'center',
      columClassName: 'text-spenda-labeltext',
      rowClassName: `p-2.5 ${isInSupplierContext ? '!bg-[#F4F9FB]' : ''}`,
      isSortable: false,
      rowRenderer: (rowData: IPaymentHistoryDetails) =>
        rowData?.approversMetaData?.length || rowData?.isPendingApproval ? (
          <div className="relative">
            <Tooltip
              placement="bottom"
              offset="0"
              className={clsx({
                'rounded-[4px] bg-white py-2 pl-3 pr-5 text-spenda-primarytext shadow-md': !rowData?.isPendingApproval,
              })}
              content={
                rowData?.isPendingApproval ? (
                  ''
                ) : (
                  <Typography
                    data-autoid={`lblApproverList`}
                    className="whitespace-nowrap text-center font-medium text-spenda-primarytext"
                    variant="paragraph"
                  >
                    <div className="flex flex-col items-start">
                      <Typography variant="paragraph" className="w-full text-left font-medium">
                        Waiting for:{' '}
                      </Typography>
                      <ul className="list-disc pl-3">
                        {rowData?.approversMetaData?.map((a, i) => (
                          <li key={a?.approverUserName} data-autoid={`lblApproverName${i}`} className="font-normal">
                            {a?.approverUserName}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </Typography>
                )
              }
            >
              <Link
                to={`${AUTH_PAYMENT_AUTHORISATION?.replace(':code', String(rowData?.authorisationID))}`}
                data-autoid={`lnkApprove`}
                onClick={event => !rowData?.isPendingApproval && event.preventDefault()}
                className={clsx('cursor-default overflow-ellipsis font-poppins text-base font-medium text-gray-600', {
                  'cursor-pointer text-primary': rowData?.isPendingApproval,
                })}
              >
                Approve
              </Link>
            </Tooltip>
          </div>
        ) : (
          <></>
        ),
    });

    arPaymentsColums?.splice(arPaymentsColums?.length + 1, 0, {
      title: '',
      key: 'delete',
      width: '2%',
      align: 'center',
      columClassName: 'text-spenda-labeltext',
      rowClassName: `p-2.5 ${isInSupplierContext ? '!bg-[#F4F9FB]' : ''}`,
      isSortable: false,
      rowRenderer: (rowData: IPaymentHistoryDetails) => (
        <div className="relative">
          <span
            data-autoid={`lblAccountCustomerNameARPaymentHistory`}
            className="cursor-pointer overflow-ellipsis font-poppins text-base font-medium text-primary"
          >
            {rowData?.isUpcomingPayment && !rowData.isCancellationPending && (
              <button
                data-autoid="btnDeleteUpcomingPayment"
                className="cursor-pointer"
                onClick={() => onClickCancelIPA(rowData)}
              >
                <IconBinTrash />
              </button>
            )}
          </span>
        </div>
      ),
    });
  }

  if (isInSupplierContext) {
    searchCategories?.splice(1, 0, {value: 'CustomerName', label: 'Customer Name'});

    arPaymentsColums?.splice(1, 0, {
      title: 'Customer',
      key: 'accountCustomerName',
      width: '24%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: `p-2.5 ${isInSupplierContext ? '!bg-primary-background' : ''}`,
      isSortable: true,
      rowRenderer: (rowData: IPaymentHistoryDetails) => (
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-row items-center">
            <Typography
              data-autoid={`lblAccountCustomerName`}
              className="inline overflow-ellipsis font-medium"
              variant="paragraph"
            >
              {rowData.accountCustomerName}
            </Typography>
          </div>
        </div>
      ),
    });
  }

  const getRowDataAutoId = (row: any) => {
    if (isInSupplierContext && !customerId) {
      return `row${selectedBucket}${row?.customer}`;
    }
    return `row${selectedBucket}${row?.transID}`;
  };

  const arPaymentCount = useMemo(() => {
    if (isInSupplierContext && !customerId) {
      return getRows?.reduce((a, c: ISupplierGroupedPaymentHistoryWithTotal) => a + c?.paymentHistories?.length, 0);
    }
    return getRows?.length;
  }, [getRows, isInSupplierContext, customerId]);

  let totalUpcomingPayments = '$0.00';
  let totalPaymentHistory = '$0.00';
  if (isInSupplierContext) {
    totalUpcomingPayments = PriceFormat(supplierPaymentsData?.upcomingPayments?.totalAppliedPayments);
    totalPaymentHistory = PriceFormat(supplierPaymentsData?.paymentHistory?.totalAppliedPayments);
  } else if (isInBuyerContext) {
    totalUpcomingPayments = PriceFormat(buyerPaymentsData?.upcomingPayments?.totalAppliedPayments);
    totalPaymentHistory = PriceFormat(buyerPaymentsData?.paymentHistory?.totalAppliedPayments);
  }

  const arPaymentTable = (
    <>
      <div className={`flex items-center justify-between border-b border-[#D8D8D8] bg-white p-2.5`}>
        <Typography className="font-light" data-autoid={`lblCount${arPaymentCount || 0}`} variant="h2">
          {isPaymentHistoryView && `Payment history (${arPaymentCount || 0})`}
          {isUpcomingView && `Upcoming payments (${arPaymentCount || 0})`}
        </Typography>
        <div className="flex flex-row items-center justify-end gap-2.5">
          <div className="min-w-[380px]">
            <ARSearchBar
              reverse
              type="advance"
              searchCategories={searchCategories}
              dataAutoIdSuffix={'paymentHistory'}
              searchString={search?.searchString}
              searchCategory={search?.searchCategory || 'RefNumber'}
              isSearching={isLoading}
              onSubmit={handleSearchChange}
            />
          </div>
          {isPaymentHistoryView && (
            <div>
              <PaymentsFilterDropdown
                filterBy={paymentHistoryFilterBy}
                setFilterBy={setPaymentHistoryTabFilterBy}
                methodOptionObject={ARPaymentMethodsFilters}
                statusOptionsObject={ARPaymentHistoryPaymentStatusFilters}
              />
            </div>
          )}
          {isUpcomingView && (
            <div>
              <PaymentsFilterDropdown
                filterBy={upcomingPaymentsFilterBy}
                setFilterBy={setUpcomingPaymentsFilterBy}
                methodOptionObject={ARPaymentMethodsFilters}
                statusOptionsObject={ARUpcomingPaymentStatusFilters}
              />
            </div>
          )}
        </div>
      </div>
      <div className={`${classes.paymentHistory} invoicesList h-[inherit] overflow-auto bg-white pt-2.5`}>
        <ARTable
          isPaginated
          isHighlightRowOnHover
          dataAutoId={`${isPaymentHistoryView ? 'PaymentHistory' : 'UpcomingPayments'}`}
          tableClass="mb-12"
          isLoading={isLoading}
          columns={getColumns()}
          rows={getRows || []}
          renderNestedTable={isInSupplierContext && !customerId ? handleRenderNestedTable : undefined}
          getRowDataAutoId={getRowDataAutoId}
        />
      </div>
    </>
  );

  const leftPannel = (
    <div className={`relative flex h-full flex-col overflow-hidden rounded-md bg-primary-background`}>
      {viewingTx && (
        <TransactionView
          key={`${viewingTx?.txGuid}${viewingTx?.txId}${viewingTx?.txDatType}`}
          isShowInPdfView={true}
          transactionId={viewingTx?.txId}
          txDatType={viewingTx?.txDatType}
          txGuid={viewingTx?.txGuid}
          handleOnClosePdfView={action => {
            handleCloseTx();
            if (action === 'RefetchData') fetchPaymentsData();
          }}
          supplierId={_supplierId}
          customerId={_customerId}
          txList={txList}
          scope="ARPaymentHistory"
        />
      )}
      {isShowConfirmDeleteSPDialog && _supplierId && clickedRowData && (
        <ConfirmCancelBatchPaymentModal
          isOpen={isShowConfirmDeleteSPDialog}
          onClose={() => setIsConfirmDeleteSPDialog(false)}
          onSuccess={async () => {
            setIsConfirmDeleteSPDialog(false);
            fetchPaymentsData();
            handleCloseTx();
          }}
          isScheduled={clickedRowData?.status === InvoicePaymentAllocationStatus.Scheduled}
          supplierId={_supplierId}
          transactionID={clickedRowData?.transID}
          datTypeID={clickedRowData?.datTypeID}
          schedulePaymentData={moment(clickedRowData?.scheduledDateTime_utc).format('DD/MM/YYYY')}
        />
      )}
      {isShowCanNotDeleteSPDialog && (
        <CanNotDeleteSchedulePaymentModal
          isOpen={isShowCanNotDeleteSPDialog}
          onClose={() => setIsShowCanNotDeleteSPDialog(false)}
        />
      )}
      {isShowEditPaymentDateDialog && isInBuyerContext && clickedRowData && supplierId && (
        <EditPaymentDateDialog
          supplierId={supplierId}
          isOpen={isShowEditPaymentDateDialog}
          onClose={() => setIsShowEditPaymentDateDialog(false)}
          onSuccess={() => {
            setIsShowEditPaymentDateDialog(false);
            fetchPaymentsData();
          }}
          transactionID={clickedRowData?.transID}
          datTypeID={clickedRowData?.datTypeID}
          scheduleDatetime={clickedRowData?.scheduledDateTime_utc}
          originalScheduleDate={clickedRowData?.originalScheduledDateTime_utc}
          maxAllowedScheduledDate={clickedRowData?.maxAllowedScheduledDateTime}
        />
      )}
      <div className="h-[inherit] w-full">
        <ARPaymentsTabView
          handleBucketChange={handleBucketChange}
          selectedBucket={selectedBucket}
          totalPaymentHistory={totalPaymentHistory}
          totalUpcomingPayments={totalUpcomingPayments}
        >
          {arPaymentTable}
        </ARPaymentsTabView>
      </div>
    </div>
  );

  return (
    <div className={`relative h-full overflow-hidden bg-spenda-newbg`}>
      {isInBuyerContext && (
        <>
          <MarketplaceLayout leftPanel={leftPannel} splitWidthType={4} />
          <PSBLFooter />
        </>
      )}
      {isInSupplierContext && <Layout leftPanel={leftPannel} splitWidthType={4} />}
    </div>
  );
};

interface IARPaymentsTabViewProps {
  handleBucketChange: (value: ARPaymentsView) => void;
  selectedBucket: ARPaymentsView;
  totalUpcomingPayments: string;
  totalPaymentHistory: string;
}

const ARPaymentsTabView = (props: PropsWithChildren<IARPaymentsTabViewProps>) => {
  // Props
  const {handleBucketChange, selectedBucket, totalUpcomingPayments, totalPaymentHistory} = props;

  const Tabdata = [
    {
      label: 'Upcoming Payments',
      value: ARPaymentsView.UpcomingPayments,
      count: totalUpcomingPayments,
      dataAutoId: 'btnUpcomingPayments',
    },
    {
      label: 'Payment History',
      value: ARPaymentsView.PaymentHistory,
      count: totalPaymentHistory,
      dataAutoId: 'btnPaymentHistory',
    },
  ];

  return (
    <Tabs value={ARPaymentsView.UpcomingPayments} className="h-[inherit]">
      <TabsHeader className="mt-1 rounded-lg bg-[#FFFFFF] px-0 shadow-sm">
        {Tabdata.map(tab => (
          <Tab key={tab.value} value={tab.value} onClick={() => handleBucketChange(tab.value)} className="px-[5px]">
            <TabButton dataAutoId={tab.dataAutoId} data={tab} />
          </Tab>
        ))}
      </TabsHeader>
      <TabsBody className="!h-[inherit] pb-[80px]">
        <TabPanel value={selectedBucket} className="mt-2 !h-[inherit] rounded-md bg-white px-0 py-2">
          {props?.children}
        </TabPanel>
      </TabsBody>
    </Tabs>
  );
};

const TabButton = (props: any) => {
  const {state} = useTabs();
  const {active: currentActive} = state;
  const isActive = props.data.value === currentActive;

  return (
    <Button
      fullWidth
      wave
      ripple={false}
      variant={isActive ? 'filled' : 'outlined'}
      data-autoid={props.data.dataAutoId}
      className="h-12"
    >
      <div className="flex flex-col items-center">
        <Typography variant="xsmall" className="font-medium">
          {props.data.label}
        </Typography>
        <Typography className="font-semibold">{props.data.count}</Typography>
      </div>
    </Button>
  );
};

export const renderPaymentStatus = (status: string) => {
  switch (status) {
    case InvoicePaymentAllocationStatus.Paid:
      return (
        <span
          className="inline-block w-[150px] rounded-sm bg-primary/10 px-3 py-1 text-xs font-medium text-primary"
          data-autoid={`lblStatus${status}`}
        >
          <CustomContent keyPath="accountReceivables.paymentHistory.status.paid">{status}</CustomContent>
        </span>
      );
    case InvoicePaymentAllocationStatus.Scheduled:
    case InvoicePaymentAllocationStatus.CancellationInProgress:
    case InvoicePaymentAllocationStatus.AwaitingAuthorisation:
      return (
        <span
          {...css({color: '#C68A19', backgroundColor: '#C68A1930'})}
          className="inline-block w-[150px] whitespace-nowrap rounded-sm px-3 py-1 text-xs font-medium"
          data-autoid={`lblStatus${status}`}
        >
          {status?.replace(/([A-Z])/g, ' $1')?.trim()}
        </span>
      );
    case InvoicePaymentAllocationStatus.Cleared:
      return (
        <span
          {...css({color: '#3C9F78', backgroundColor: '#3C9F7830'})}
          className="inline-block w-[150px] rounded-sm px-3 py-1 text-xs font-medium"
          data-autoid={`lblStatus${status}`}
        >
          {status}
        </span>
      );
    case InvoicePaymentAllocationStatus.InProgress:
      return (
        <span
          {...css({color: '#C68A19', backgroundColor: '#C68A1930'})}
          className="inline-block w-[150px] rounded-sm px-3 py-1 text-xs font-medium"
          data-autoid={`lblStatus${status}`}
        >
          In Progress
        </span>
      );
    case InvoicePaymentAllocationStatus.Failed:
    case InvoicePaymentAllocationStatus.Cancelled:
    case InvoicePaymentAllocationStatus.Declined:
      return (
        <span
          {...css({color: '#B9624B', backgroundColor: '#B9624B30'})}
          className="inline-block w-[150px] rounded-sm px-3 py-1 text-xs font-medium"
          data-autoid={`lblStatus${status}`}
        >
          {status}
        </span>
      );
    default:
      return <span data-autoid={`lblStatus${status}`}>{status}</span>;
  }
};

export const renderPaymentMethodIcons = (status: ARPaymentMethodIconsStatus) => {
  switch (status) {
    case ARPaymentMethodIconsStatus.Visa:
      return <IconVisaCardRebranded />;
    case ARPaymentMethodIconsStatus.Mastercard:
      return <IconMasterCardRebranded />;
    case ARPaymentMethodIconsStatus.AmericanExpress:
      return <IconAmexCardRebranded />;
    case ARPaymentMethodIconsStatus.CreditNotes:
      return <CreditNotesIcon />;
    case ARPaymentMethodIconsStatus.BankTransfer:
      return <IconBankBlue />;
    case ARPaymentMethodIconsStatus.Invigo:
    case ARPaymentMethodIconsStatus.SpendaFinance:
      return <IconSpendaFinance />;
    case ARPaymentMethodIconsStatus.AirPlus:
      return <IconAirplusPaymentMethod />;
    default:
      return '';
  }
};

interface IPaymentsFilterDropdownProps {
  statusOptionsObject?: {[key: string]: string[]};
  methodOptionObject: {[key: string]: string[]};
  filterBy: string[][];
  setFilterBy: React.Dispatch<React.SetStateAction<string[][]>>;
}

export const PaymentsFilterDropdown = (props: IPaymentsFilterDropdownProps) => {
  // Props
  const {statusOptionsObject, methodOptionObject, filterBy, setFilterBy} = props;

  const renderOption = (optionsObject: {[key: string]: string[]}) => {
    return Object.entries(optionsObject).map(([k, v]) => {
      const index = filterBy?.findIndex(f => f?.some((i: InvoicePaymentAllocationStatus) => v?.includes(i)));
      const alreadySelected = index >= 0;
      const optionName = k?.replace(/([A-Z])/g, ' $1')?.trim();

      return (
        <Option
          data-autoid={`ddlOption${optionName}`}
          onClick={() => {
            if (index === -1) {
              setFilterBy(prev => [...prev, v]);
              return;
            }
            setFilterBy(prev => {
              prev?.splice(index, 1);
              return [...prev];
            });
          }}
          className="p-0"
          key={v?.toString()}
          value={v?.toString()}
        >
          <div className="flex items-center justify-start">
            <Checkbox
              data-autoid={`chk${optionName}`}
              checked={alreadySelected}
              ripple={false}
              className="h-4 w-4 border-primary bg-white transition-all checked:border-primary checked:bg-primary hover:before:opacity-0"
            />
            {optionName}
          </div>
        </Option>
      );
    });
  };

  let options: React.JSX.Element[] = [];

  if (statusOptionsObject) {
    options = [
      <Option key={'PaymentStatus'} value="" disabled>
        Payment status
      </Option>,
      ...renderOption(statusOptionsObject),
    ];
  }

  options = [
    ...options,
    <Option key={'paymentMethods'} value="" disabled>
      Payment methods
    </Option>,
    ...renderOption(methodOptionObject),
  ];

  return (
    <Select
      data-autoid="ddlFilters"
      size="lg"
      variant="outlined"
      label="Filter"
      labelProps={{className: 'font-poppins'}}
      value={`${filterBy?.length} Selected`}
      dismiss={{itemPress: false}}
    >
      {options}
    </Select>
  );
};
