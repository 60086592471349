import React from 'react';
import {ARDialogTemplate} from '../ARDialogTemplate';
import {useSupplierTransactionsAPI} from '../../../services/useSupplierTransactionsAPI';
import {Button, Typography} from 'spenda-ui-react';
import moment from 'moment';

interface IConfirmSchedulePaymentCancelModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => Promise<void>;
  supplierId: number;
  transactionID: number;
  datTypeID: number;
  isScheduled: boolean;
  schedulePaymentData?: string;
}

export const ConfirmCancelBatchPaymentModal = (props: IConfirmSchedulePaymentCancelModalProps) => {
  //Props
  const {isOpen, supplierId, isScheduled, transactionID, datTypeID, schedulePaymentData, onClose, onSuccess} = props;

  // Hook
  const {cancelScheduledPayment, isLoading} = useSupplierTransactionsAPI();

  const onCancelScheduledPayment = async () => {
    try {
      const res = await cancelScheduledPayment(supplierId, {transactionID, datTypeID});
      res && (await onSuccess());
    } catch {}
  };

  return (
    <ARDialogTemplate
      dialogProps={{size: 'xs', open: isOpen, className: '!min-w-[460px] !max-w-[460px]'}}
      isFullScreen
      header={
        <p className=" font-poppins text-xl font-light text-[#333333]">
          {isScheduled ? 'Cancel Scheduled payment' : 'Cancel payment'}
        </p>
      }
      body={
        <div className="my-12">
          <Typography variant="paragraph" className="text-center font-light text-black-800">
            {isScheduled
              ? `Are you sure you would like to cancel the scheduled batch payment that was due to be proceeded on the 
                ${moment(schedulePaymentData).format('DD/MM/YYYY')}?`
              : 'Are you sure you would like to cancel the batch payment.'}
          </Typography>
        </div>
      }
      footer={
        <div className="flex w-full flex-row items-center justify-between">
          <Button
            data-autoid={`btnCancel`}
            className="bg-white"
            onClick={() => onClose()}
            variant="outlined"
            color="primary"
          >
            Cancel
          </Button>
          <Button
            loading={isLoading}
            disabled={isLoading}
            variant="filled"
            color="primary"
            data-autoid={`btnCancelBatch`}
            onClick={onCancelScheduledPayment}
          >
            Cancel Batch
          </Button>
        </div>
      }
      handleClose={() => onClose()}
    />
  );
};
