import { Inventory } from '../model/inventory/Inventory';
import { SupplierInventory } from '../model/inventory/SupplierInventory';
import { IPagedActionResults, IActionRes } from '../model/ActionResults';
import useHttp from '../hooks/useHttp';
import { IProductsFilter } from '../model/search-filters/ProductsFilter';



const useSupplierInventoryAPI = () => {

    const { GET, POST } = useHttp();
    
    const supplierInventorySearch = (filter: IProductsFilter): Promise<IPagedActionResults<SupplierInventory[]>> => {
        return GET("supplierinventory", filter).then((data: IPagedActionResults<SupplierInventory[]>) => data);
    }
    
    const setAsFavourite = (product: Inventory, isFavourite: boolean): Promise<boolean> => {
        
        const request = {
            SupplierID: product.SupplierID,
            SupplierInventoryGUID: product.InventoryGUID,
            IsFavourite: isFavourite
        }
        
        return POST("supplierinventory/favourite", request).then((data: IActionRes) => data.IsSuccess );
    }
    
    return {
        supplierInventorySearch,
        setAsFavourite
    }
}

export default useSupplierInventoryAPI;
