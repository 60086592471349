import React, {FunctionComponent, useEffect, useState} from 'react';
import {ITenantLocation} from '../../model/tenantLocation/TenantLocation';
import useTenantLocationAPI from '../../services/useTenantLocationAPI';
import {TenantLocationDialog} from '../tenantLocation/TenantLocationDialog';
import {STile} from './UsersDialog';
import {titleCase} from 'title-case';
import {FormikHelpers} from 'formik';
import {AlertDialogSlideV2} from './AlertDialogSlideV2';
import {Button, Typography} from 'spenda-ui-react';

interface ICompanyLocationsDialogProps {
  title?: string;
  onClose?: () => void;
  isAdmin?: boolean;
  isSupport?: boolean;
}

export const CompanyLocationsDialog: FunctionComponent<ICompanyLocationsDialogProps> = ({
  title,
  onClose,
  isAdmin,
  isSupport,
}) => {
  const {get, save: saveTenanLocation, isLoading} = useTenantLocationAPI();

  const [tenantLocationList, setTenantLocationList] = useState<ITenantLocation[]>([]);

  const [locationToEdit, setLocationToEdit] = useState<ITenantLocation | undefined>(undefined);

  useEffect(() => {
    const loadData = () => {
      get().then((wh: ITenantLocation[]) => {
        if (wh) {
          setTenantLocationList(wh);
        }
      });
    };
    if (!locationToEdit) {
      loadData();
    }
  }, [locationToEdit]);

  const warehouseGroups = [
    {
      name: 'Primary Location',
      filter: (w: ITenantLocation) => w.IsDefaultLocation,
    },
    {
      name: 'Other Locations',
      filter: (w: ITenantLocation) => !w.IsDefaultLocation,
    },
  ];

  const newTl: ITenantLocation = {
    LocationName: '',
    DefaultWarehouse: {
      Name: '',
      WarehouseTypeID: 1,
    },
    TenantLocationAddress: {
      StreetAddress: '',
      City: '',
      PostCode: '',
      State: '',
      Country: '',
      IsDefaultDeliverTo: true,
    },
  };

  const onEditLocation = (tl?: ITenantLocation) => {
    if (canAddNewLocation) {
      // setIsDefaultSearch(tl ? true : false);
      // For a tenant location that don't have an address, initialise the TenantLocationAddress object so the form validation kicks in.
      if (tl && !tl.TenantLocationAddress) {
        tl.TenantLocationAddress = newTl.TenantLocationAddress;
      }

      setLocationToEdit(tl ? tl : newTl);
    }
  };

  const onClickDone = () => {
    if (onClose) {
      onClose();
    }
  };

  const onSave = (tl?: ITenantLocation, helpers?: FormikHelpers<ITenantLocation>) => {
    if (!tl) {
      return;
    }
    saveTenanLocation(tl)
      .then(res => {
        if (res.IsSuccess) {
          setLocationToEdit(undefined);
        }
      })
      .finally(() => {
        if (helpers) {
          helpers.setSubmitting(false);
        }
      });
  };

  const onCloseEditLocationDialog = () => {
    setLocationToEdit(undefined);
  };

  const canAddNewLocation = isAdmin || isSupport;

  return (
    <>
      <AlertDialogSlideV2
        headerChildren={
          canAddNewLocation && (
            <Button className="!absolute left-[22px]" variant="filled" onClick={() => onEditLocation()}>
              New Location
            </Button>
          )
        }
        title={title}
        size="lg"
        headingTextSize="h1"
        dialogBodyClassess="py-0 px-[60px] overflow-y-scroll scroll-smooth"
        actions={[
          {
            label: 'Done',
            action: onClickDone,
            color: 'primary',
          },
        ]}
      >
        <div className="pl-16">
          {warehouseGroups.map(whg => (
            <TenantLocationGroup
              key={whg.name}
              groupName={whg.name}
              tlList={tenantLocationList?.filter(whg.filter).sort((a, b) => {
                return a.LocationName && b.LocationName ? a.LocationName?.localeCompare(b.LocationName!) : 0;
              })}
              isLoading={isLoading}
              onLocationClick={onEditLocation}
            />
          ))}
        </div>
      </AlertDialogSlideV2>

      {locationToEdit && (
        <TenantLocationDialog
          title="Edit Your Location"
          tenantLocation={locationToEdit!}
          onClose={onCloseEditLocationDialog}
          onSave={onSave}
          open={locationToEdit !== undefined}
        />
      )}
    </>
  );
};

const TenantLocationGroup = (props: {
  groupName: string;
  tlList: ITenantLocation[];
  isLoading: boolean;
  onLocationClick?: (wh: ITenantLocation) => void;
}) => {
  const {groupName, tlList: whs, isLoading, onLocationClick} = props;

  return (
    <section className="relative mt-8 pb-6">
      <div className="groupName relative w-full">
        <Typography variant="paragraph" className="relative left-[-6rem] z-50 inline-block bg-white pr-1">
          {groupName}
        </Typography>
      </div>
      {isLoading && (
        <div className="mt-5 flex flex-wrap">
          <TenantLocationTile isLoading={true} />
        </div>
      )}
      {whs.length ? (
        <TenantLocationTileList tlList={whs} onLocationClick={onLocationClick} />
      ) : (
        !isLoading && (
          <Typography variant="h3" className="text-center font-poppins text-gray-700">
            You currently have no {groupName}.
          </Typography>
        )
      )}
    </section>
  );
};

const TenantLocationTileList = (props: {
  tlList: ITenantLocation[];
  onLocationClick?: (tenantLocation: ITenantLocation) => void;
}) => {
  const {tlList: whList, onLocationClick} = props;

  return (
    <div className="flex flex-wrap pl-8">
      {whList.map(wh => (
        <TenantLocationTile key={`wh-${wh.ID}`} tl={wh} onClick={onLocationClick} />
      ))}
    </div>
  );
};

const TenantLocationTile = (props: {
  tl?: ITenantLocation;
  isLoading?: boolean;
  onClick?: (tl: ITenantLocation) => void;
}) => {
  const {tl, isLoading} = props;

  const defaultShippingAddress = tl?.TenantLocationAddress;

  const onClick = () => {
    if (props.onClick) {
      props.onClick(tl!);
    }
  };

  return (
    <STile dataAutoid={tl?.LocationName} isActive={true} isLoading={isLoading} onClick={onClick}>
      <>
        <Typography variant="paragraph" className="truncate font-bold text-black-800">
          {titleCase(tl?.LocationName || '')}
        </Typography>
        <Typography
          variant="xsmall"
          className="block truncate text-[#000000]"
          title={defaultShippingAddress?.StreetAddress}
        >
          {titleCase(defaultShippingAddress?.StreetAddress || '')}
        </Typography>
        <Typography variant="xsmall" className="block truncate text-gray-700" title={defaultShippingAddress?.City}>
          {titleCase(defaultShippingAddress?.City?.toLowerCase() || '')}
        </Typography>
        <Typography variant="xsmall" className="block truncate text-gray-700">
          {titleCase(defaultShippingAddress?.State || '')} {titleCase(defaultShippingAddress?.PostCode || '')}
        </Typography>
      </>
    </STile>
  );
};

export default CompanyLocationsDialog;
