import React, {useEffect, useState} from 'react';
import {Calendar, Chip} from 'spenda-ui-react';
import moment from 'moment';

import {BookingDiaryList} from '../../components/bookingDiary/BookingDiaryList';
import {BookingDiaryHeader} from '../../components/bookingDiary/BookingDiaryHeader';
import {BookingDiaryScheduler} from '../../components/bookingDiary/BookingDiaryScheduler';
import {CreateServiceJobModal} from '../../components/dialog/CreateServiceJobModal';
import {
  IRequiresAttentionServiceJobRes,
  IServiceJob,
  ServiceJobScope,
  ServiceJobStatus,
} from '../../model/service-management/serviceJob';
import {ServiceContextProvider} from '../../context/serviceManagement/ServiceContextProvider';
import useServiceJobAPI from '../../services/useServiceJobAPI';
import {ICustomer} from '../../model/customer/Customer';
import {useBookingDiary} from '../../hooks/useBookingDiary';
import {ISchedulerDialogState} from '../../components/dialog/SchedulerDialog';
import {Actions} from '../../model/constants/Constants';
import LoadingIndicator from '../../components/ui/LoadingIndicator';

export type currentActiveView = 'calendar' | 'scheduler';

// Function to get all statuses excluding specific values
function getFilteredStatuses(enumObj: Record<string, string>, exclusions: string[]): string[] {
  // Convert enum values to an array
  const allValues = Object.values(enumObj);
  // Filter out the excluded values
  return allValues.filter(value => !exclusions.includes(value));
}

export type RightPanelView = 'ListView' | 'RequiresAttentionView';

export const ServiceDashboard = () => {
  // states
  const [activeView, setActiveView] = useState<currentActiveView>('calendar');
  const [isCollapsibleView, setIsCollapsibleView] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [openBookingDialog, setOpenBookingDialog] = useState<boolean>(false);
  const [searchString, setSearchString] = useState('');
  const [jobList, setJobList] = useState<IServiceJob[]>();
  const [requiresAttentionList, setRequiresAttentionList] = useState<IRequiresAttentionServiceJobRes>();
  const [serviceJobId, setServiceJobId] = useState<number>();
  const {getJobsList, createAdhocJob, getRequiresAttentionList, isLoading} = useServiceJobAPI();
  const [isShowUnassignedServiceJobs, setIsShowUnassignedServiceJobs] = useState<boolean>(true);
  const [activeRightPanelView, setActiveRightPanelView] = useState<RightPanelView>('ListView');

  const {
    technicians,
    selectedTechnicians,
    setSelectedTechnicians,
    calendarEvents,
    schedulerData,
    fetchSummaryJobs,
    fetchSchedulerViewJobs,
    fetchTechnicians,
    isLoading: isCalendarLoading,
  } = useBookingDiary({
    selectedDate,
    jobDialogOpen: openBookingDialog,
    searchString,
    isFetchingTechnicians: true,
    isShowUnassignedServiceJobs,
  });

  const handleSetSelectedTechnicians = (technicians: number[]) => {
    setSelectedTechnicians(technicians);
    fetchJobs(technicians);
    setSelectedDate(new Date(selectedDate));
    fetchSchedulerViewJobs(technicians);
  };

  useEffect(() => {
    if (!openBookingDialog) {
      fetchJobs();
    }
  }, [setSearchString, searchString, openBookingDialog, isShowUnassignedServiceJobs]);

  const fetchJobs = async (technician = selectedTechnicians) => {
    const exclusions = [ServiceJobStatus.Completed, ServiceJobStatus.Cancelled, ServiceJobStatus.Closed];
    const remainingStatuses = getFilteredStatuses(ServiceJobStatus, exclusions);
    const results = await Promise.all([
      getJobsList({
        StartRow: 1,
        MaxResults: 1000,
        SearchString: searchString,
        SortOrder: 'DESC',
        SortField: 'ModifiedDate',
        ServiceTechnicianIDs: technician,
        IsShowUnassignedServiceJobs: isShowUnassignedServiceJobs,
        Statuses: remainingStatuses,
      }),
      getRequiresAttentionList({
        StartRow: 1,
        MaxResults: 1000,
        SearchString: searchString,
        SortOrder: 'DESC',
        SortField: 'ModifiedDate',
        ServiceTechnicianIDs: technician,
        IsShowUnassignedServiceJobs: isShowUnassignedServiceJobs,
      }),
    ]);
    setJobList(results[0].items);
    setRequiresAttentionList(results[1]);
  };

  const onDateSelect = async (date: Date) => {
    setSelectedDate(date);
    activeView !== 'scheduler' && setActiveView('scheduler');
  };

  const EventView = ({event}: {event: (typeof calendarEvents)[0]}) => {
    return <Chip color="warning" size="lg" value={event.title} className="bg-warning/20" />;
  };

  const handleCreateAdhocBooking = async (
    selectedServiceCustomer: ICustomer,
    scheduleJobInfo?: ISchedulerDialogState,
  ) => {
    const payload: Partial<IServiceJob> = {
      customerID: selectedServiceCustomer.ID!,
      customerName: selectedServiceCustomer.IsIndividual
        ? selectedServiceCustomer.PrimaryContactFullName
        : selectedServiceCustomer.CompanyName,
    };

    if (scheduleJobInfo) {
      payload.bookings = [
        {
          startDateTime_utc: moment(`${scheduleJobInfo.startDate}`).utc().format('YYYY-MM-DD HH:mm'),
          endDateTime_utc: moment(`${scheduleJobInfo.endDate}`).utc().format('YYYY-MM-DD HH:mm'),
          action: Actions.add,
        },
      ];
      if (scheduleJobInfo.technician.id !== '-1') {
        const name = scheduleJobInfo.technician.name.split(' ');
        payload.technicians = [
          {
            firstName: name[0],
            lastName: name[1],
            technicianUserID: +scheduleJobInfo.technician.id,
            action: Actions.add,
          },
        ];
        payload.primaryTechnicianFirstName = name[0];
        payload.primaryTechnicianLastName = name[1];
        payload.primaryTechnicianUserID = scheduleJobInfo.technician.id;
      }
    }

    const response: IServiceJob = await createAdhocJob(payload);
    setServiceJobId(response.serviceJobID);
    setOpenBookingDialog(true);
  };

  const handleRefreshTechnician = async () => {
    const latestTechnicians = await fetchTechnicians();
    fetchJobs(latestTechnicians);
    setSelectedDate(new Date(selectedDate));
    fetchSchedulerViewJobs(latestTechnicians);
  };

  return (
    <div className="relative h-full overflow-hidden bg-[rgba(211,229,239,0.5)]">
      <BookingDiaryHeader
        handleCreateAdhocBooking={customer => handleCreateAdhocBooking(customer)}
        handleRefreshTechnician={handleRefreshTechnician}
        setSearchString={setSearchString}
        technicians={technicians}
        setSelectedTechnicians={handleSetSelectedTechnicians}
        selectedTechnicians={selectedTechnicians}
        isShowUnassignedServiceJobs={isShowUnassignedServiceJobs}
        setIsShowUnassignedServiceJobs={setIsShowUnassignedServiceJobs}
      />
      <div className="mt-2 flex w-full flex-row px-2">
        <div
          className={`${isCollapsibleView ? '!w-full' : '!w-[calc(100%-443px)]'} overflow-y relative rounded-md bg-white p-2`}
        >
          {isCalendarLoading && (
            <LoadingIndicator
              isLoading={isCalendarLoading}
              size="md"
              position={{
                height: '100% !important',
                display: 'flex',
                position: 'absolute',
                left: '0',
                right: '0',
                marginLeft: 'auto',
                marginRight: 'auto',
                zIndex: 50,
              }}
            />
          )}
          {activeView === 'calendar' ? (
            <Calendar
              activeMonth={selectedDate}
              onDateSelect={onDateSelect}
              events={calendarEvents as []}
              eventViewComponent={EventView}
              isShowSwitcher={true}
              currentActiveView={activeView}
              onSwitchClick={(view: any) => {
                setIsCollapsibleView(false);
                setActiveView(view);
              }}
              rootContainerProps={{className: 'font-poppins'}}
              containerProps={{className: 'overflow-y-auto h-[calc(100vh-259px)] font-poppins'}}
              onMonthChange={async (startDate: Date, endDate: Date) => {
                fetchSummaryJobs(startDate, endDate, selectedTechnicians);
              }}
              todayButtonProps={{onClick: () => setSelectedDate(new Date())}}
            />
          ) : (
            <BookingDiaryScheduler
              currentActiveView={activeView}
              selectedDate={selectedDate}
              setSelectedDate={onDateSelect}
              onSwitchClick={view => {
                setActiveView(view);
                if (view === 'calendar') {
                  setIsCollapsibleView(false);
                  setSelectedDate(new Date(selectedDate));
                }
              }}
              onEditJob={id => {
                setServiceJobId(id);
                setOpenBookingDialog(true);
              }}
              handleCollapse={() => {
                setIsCollapsibleView(false);
                setSelectedDate(new Date(selectedDate));
              }}
              isCollapseView={isCollapsibleView}
              selectedTechnicianIDs={selectedTechnicians}
              technicians={technicians}
              schedulerData={schedulerData}
              handleCreateAdhocBooking={handleCreateAdhocBooking}
              isShowUnassignedServiceJobs={isShowUnassignedServiceJobs}
            />
          )}
        </div>
        <div className={`${isCollapsibleView ? 'hidden' : 'block'} ml-2 w-[435px] rounded-md bg-white`}>
          {isLoading ? (
            <LoadingIndicator
              isLoading={true}
              size="md"
              position={{position: 'absolute', top: '50%', left: '88%', transform: 'translate(-50%, -50%)'}}
            />
          ) : (
            <BookingDiaryList
              activeRightPanelView={activeRightPanelView}
              setActiveRightPanelView={setActiveRightPanelView}
              activeLeftPanelView={activeView}
              handleCollapse={() => {
                setSelectedDate(new Date(selectedDate));
                setIsCollapsibleView(true);
              }}
              jobList={jobList}
              requiresAttentionList={requiresAttentionList}
              setOpenBookingDialog={setOpenBookingDialog}
              setServiceJobId={setServiceJobId}
              selectedDate={selectedDate}
            />
          )}
        </div>
      </div>
      {openBookingDialog && (
        <ServiceContextProvider>
          <CreateServiceJobModal
            serviceJobId={serviceJobId}
            open={openBookingDialog}
            handleClose={() => {
              setOpenBookingDialog(false);
              setServiceJobId(undefined);
            }}
            scope={serviceJobId ? ServiceJobScope.QuoteDetails : ServiceJobScope.adhocJob}
          />
        </ServiceContextProvider>
      )}
    </div>
  );
};
