import React from 'react';

import {SModal} from '../modals/modalSpendaMeterialUI';
import {SButton} from '../buttons/Button';

import {Box, makeStyles} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const usePaymentBatchDuplicateDialog = makeStyles({
  apOnboardingModal: {
    maxWidth: '570px',
    height: '250px',
  },
  apBusinessHeader: {
    borderBottom: '1px solid #CCCCCC',
    '& h2': {
      fontFamily: 'Poppins, sans-serif !important',
      fontWeight: 300,
      fontSize: '28px',
      color: '#333333',
    },
  },
  boxHeight: {
    '& p': {
      fontFamily: 'Poppins, sans-serif !important',
      color: '#333',
    },
  },
  discardFooter: {
    maxWidth: '500px',
    background: 'rgb(173 173 173 / 20%)',
    borderRadius: '8px',
  },
});
export interface IPaymentBatchDuplicateDialog {
  open: boolean;
  fileName: string;
  isLoading: boolean;
  handleClose: () => void;
  handleProceedBatchClick: () => void;
}

export const PaymentBatchDuplicateDialog = (props: IPaymentBatchDuplicateDialog) => {
  const classes = usePaymentBatchDuplicateDialog();

  const {open, fileName, isLoading, handleClose, handleProceedBatchClick} = props;

  return (
    <SModal open={open}>
      <div className={`text-xl spenda-color flex justify-center items-center h-full max-w-full`}>
        <div className={`modal-box ${classes.apOnboardingModal} px-5 pt-3 pb-5`}>
          <Box className={`${classes.apBusinessHeader} flex justify-between -mx-5 px-3 pb-3`}>
            <h2 className={`text-center w-full`}>Duplicate Batch</h2>
            <Box className="text-right flex justify-end">
              <span className="ap-onboarding-close-icon">
                <CloseIcon onClick={handleClose} data-autoid="btnClose" />
              </span>
            </Box>
          </Box>
          <div className={`${classes.boxHeight} flex flex-col relative px-3 py-8`}>
            <Box>
              <p className="font-medium text-base pb-6 text-center">
                A Batch already exists with the file name <b>{fileName}</b>. Do you still want to proceed?
              </p>
            </Box>
          </div>
          <Box
            className={`${classes.discardFooter} w-full flex justify-between items-center absolute bottom-5 left-0 p-2 mx-8 `}
          >
            <SButton
              textColor={'blueShade'}
              width="72px"
              height="40px"
              lineHeight="l"
              fontSize="xs"
              backgroundColor={'#fff'}
              margin="0px "
              variant={'outlined'}
              border={'1px solid #1C78AD'}
              borderRadius="6px"
              data-autoid="btnCancel"
              fontFamily="poppins"
              onClick={handleClose}
            >
              Cancel
            </SButton>
            <SButton
              textColor={'white'}
              width="130px"
              height="40px"
              lineHeight="l"
              fontSize="xs"
              backgroundColor={'#c65d42'}
              margin="0px"
              variant={'outlined'}
              border={'1px solid #c65d42'}
              borderRadius="6px"
              data-autoid="btnProceedWithBatch"
              fontFamily="poppins"
              onClick={handleProceedBatchClick}
              disabled={isLoading}
              isSubmitting={isLoading}
            >
              Proceed with Batch
            </SButton>
          </Box>
        </div>
      </div>
    </SModal>
  );
};
