import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {Button, IconButton, Typography} from 'spenda-ui-react';

import {AUTH_SELLING_SERVICE_CUSTOMERS} from '../../../routes/ServiceManagementRoutes';
import {CustomerDialog} from '../../../components/dialog/CustomerDialog';
import {IServiceJob} from '../../../model/service-management/serviceJob';
import {BackIcon} from '../../../assets/svg';

interface IServiceHistoryHeader {
  isCustomerHistoryTab?: boolean;
  customerJobDetails?: IServiceJob[];
  fetchJobs: (id: number) => void;
}

const ServiceHistoryHeader = (props: IServiceHistoryHeader) => {
  const {isCustomerHistoryTab, customerJobDetails, fetchJobs} = props;

  const [openCustomer, setOpenCustomer] = useState<boolean>(false);

  return (
    <div className="mb-4 flex items-center justify-between border-b border-[#ECECEC] pb-2.5">
      <div className="flex items-center justify-start gap-2.5">
        <Link to={AUTH_SELLING_SERVICE_CUSTOMERS} data-autoid={`lnkBackbtn`}>
          <IconButton variant="outlined">
            <BackIcon />
          </IconButton>
        </Link>
        <Typography variant="h2" className="font-light text-black-800">
          {isCustomerHistoryTab ? 'Customer history' : 'Customer Info'}
        </Typography>
      </div>
      <div className="flex gap-2.5">
        {!isCustomerHistoryTab && (
          <Button
            variant="outlined"
            onClick={() => {
              setOpenCustomer(true);
            }}
          >
            Edit Customer
          </Button>
        )}
        {isCustomerHistoryTab && <Button variant="outlined">Contact customer</Button>}
      </div>
      {openCustomer && (
        <CustomerDialog
          customerId={customerJobDetails?.[0].customerID}
          open={openCustomer}
          onClose={() => setOpenCustomer(false)}
          setOpenAccountCustomer={() => null}
          setCustomer={() => null}
          isQuoteCustomer
          onCustomerUpdate={() => {
            setOpenCustomer(false);
            fetchJobs(customerJobDetails?.[0].customerID!);
          }}
        />
      )}
    </div>
  );
};

export default ServiceHistoryHeader;
