export enum UserRoleEnum {
  Administrator = 1,
  Primary = 3002,
  Standard = 3001,
  Support = 3000,
  Technician = 42,
  ServiceManager = 40,
}

export enum PermissionTypeIdEnum {
  CreateBatch = 1,
  ApproveBatch = 2,
}

export interface IUserRolePaymentBatchPermission {
  PermissionTypeID: PermissionTypeIdEnum;
  Name?: string;
  Description?: string;
  IsActive: boolean;
}
export interface IUserRolePaymentPermission {
  UserRoleID: UserRoleEnum;
  RoleName?: string;
  RolePermissions: IUserRolePaymentBatchPermission[];
}
export interface IUserRolePaymentBatch {
  Permissions: IUserRolePaymentPermission[];
}
export interface IUserRole {
  IsSystem?: boolean;
  ModuleID?: number;
  Name?: string;
  SelectedForUser?: boolean;
  UserRoleEnum?: UserRoleEnum;
}

export interface IBaseUser {
  ABN?: string;
  FirstName?: string;
  LastName?: string;
  Mobile?: string;
  Phone1?: string;
  TenantName?: string;
  UserID?: number;
  UserName?: string;
  email?: string;
}

export interface IUserAccountInfo extends IBaseUser {
  Barcode?: string;
  DefaultWarehouseID?: number;
  DefaultWarehouseName?: string;
  DisplayName?: string;
  EmailAddress?: string;
  HasVan?: boolean;
  Is2SARequired?: boolean;
  IsConfirmed?: boolean;
  IsActive?: boolean;
  IsEnabled?: boolean;
  isServiceManagementVisible?:boolean;
  IsGetNotificationEmailAlerts?: boolean;
  IsNewUnconfirmedUser?: boolean;
  IsPrimaryContact?: boolean;
  IsProfileComplete?: boolean;
  IsReachUser?: boolean;
  IsSendWelcomeInvite?: boolean;
  IsStandardUser?: boolean;
  IsSystem?: boolean;
  OptionsFor2SA?: null;
  Position?: string;
  Provider?: string;
  Teams?: null;
  TempPassword?: null;
  TenantLocations?: null;
  Territories?: null;
  UserGlobalID?: string;
  UserRoles: IUserRole[];
  IsAdministrator?: boolean;
  IsSupport?: boolean;
}
