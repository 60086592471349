import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import {APFooter} from '../accountsPayableOnboarding/APFooter';
import {Dialog} from 'spenda-ui-react';

interface IDiscardSupplierDetails {
  handleClose: () => void;
  handleDiscard: () => void;
}

export const DiscardSupplierDetails = (props: IDiscardSupplierDetails) => {
  const {handleClose, handleDiscard} = props;

  return (
    <Dialog open handler={() => {}} size="xs">
      <div className={`spenda-color flex h-full w-full items-center justify-center font-poppins text-xl`}>
        <div className={`modal-box !h-full max-h-[340px] w-full px-[30px] pb-5 pt-3 text-spenda-primarytext`}>
          <div className={`-mx-[30px] flex justify-between border-[#CCCCCC] border-b-default px-3 pb-3`}>
            <h2 className={`w-full self-center text-center text-xl font-light`}>Discard details</h2>
            <div className="flex justify-end text-right">
              <span className="ap-onboarding-close-icon">
                <CloseIcon data-autoid="btnClose" onClick={handleClose} className="text-primary" />
              </span>
            </div>
          </div>
          <div className="flex min-h-[204px] items-center justify-center pb-16">
            <p className="text-center text-base text-[#707070]">
              Are you sure you want to skip filling the supplier details, you would have to select it’s posting
              behaviour and redo the process.
            </p>
          </div>
          <APFooter
            APFooterClass="!bottom-[10px] !left-[10px]"
            style={{width: 'calc(100% - 20px)'}}
            isCancelButton={true}
            buttonLabel={'Discard Details'}
            onNextClick={handleDiscard}
            handleCancelClick={handleClose}
            isT2TSupplierModule={true}
            conditionallyClass={true}
          />
        </div>
      </div>
    </Dialog>
  );
};
