import {createTheme} from '@material-ui/core/styles';
import {grey} from '@material-ui/core/colors';

export default createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#fff',
    },
    secondary: {
      main: '#2F97BC',
    },
    text: {
      primary: '#fafafa',
      secondary: grey[500],
    },
  },
  typography: {
    fontFamily: 'Saira Condensed',
  },
  overrides: {
    //For the Mui Requisition Paper
    MuiPaper: {
      root: {
        color: '#000',
        backgroundColor: '#fff',
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: 0,
        height: '34px',
      },
    },
    // For the Mui Pop-Up ListItem
    MuiListItem: {
      root: {
        justifyContent: 'center !important',
      },
    },
    MuiMenuItem: {
      root: {
        fontFamily: 'sans-serif',
      },
    },
    MuiChip: {
      root: {
        backgroundColor: '#02804e',
      },
    },
    //Whenever we used SPopover component it will add this dim background which is nice for User exp.
    MuiPopover: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.20)',
      },
    },

    //If we want to change all the Material UI InputBase
    // MuiInputBase: {
    //   input:{
    //     color: "#000",
    //     fontFamily: 'sans-serif'
    //   }}
  },
});
