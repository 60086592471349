import * as React from 'react';

function TableDropChange(props: {width?: string; height?: string}) {
  return (
    <svg width="16px" height="8px" viewBox="0 0 16 8" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M.667 1.333h14.666a.667.667 0 100-1.333H.667a.667.667 0 100 1.333zm2.666 2a.667.667 0 000 1.334h9.334a.667.667 0 000-1.334H3.333zm6.134 3.334H6.533a.667.667 0 100 1.333h2.934a.667.667 0 000-1.333z"
        transform="translate(-154 -363) translate(21 334) translate(133 29)"
        className="fill-primary"
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
      />
    </svg>
  );
}

export default TableDropChange;
