import * as React from 'react';
const DisclaimerIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg {...props}>
    <path fill="#E3EEF5" d="M29 3h51v103H29z" opacity=".6" />
    <path
      fill="#DDB975"
      fillRule="evenodd"
      d="M44.74 6.182a2.167 2.167 0 0 1-2.167-2.164V2.555h23.854v1.463a2.167 2.167 0 0 1-2.167 2.164H44.74Z"
      clipRule="evenodd"
    />
    <path
      fill="#1C78AD"
      fillRule="evenodd"
      d="M79.298 100.972a5.055 5.055 0 0 1-5.047 5.048H34.75a5.056 5.056 0 0 1-5.047-5.048V8.028a5.054 5.054 0 0 1 5.047-5.048h5.269v1.038a4.726 4.726 0 0 0 4.722 4.719h19.52a4.725 4.725 0 0 0 4.722-4.72V2.98h5.27a5.055 5.055 0 0 1 5.046 5.048v92.944ZM42.998 2.98v1.038a1.74 1.74 0 0 0 1.742 1.738h19.52a1.74 1.74 0 0 0 1.741-1.738V2.98H43ZM74.252 0H34.749a8.038 8.038 0 0 0-8.028 8.028v92.944A8.04 8.04 0 0 0 34.749 109H74.25a8.039 8.039 0 0 0 8.028-8.028V8.028A8.038 8.038 0 0 0 74.251 0Z"
      clipRule="evenodd"
    />
    <path
      fill="#C68A19"
      fillRule="evenodd"
      d="M54 41c-7.18 0-13 5.82-13 13s5.82 13 13 13 13-5.82 13-13c-.008-7.176-5.824-12.992-13-13Zm0 23.833c-5.983 0-10.833-4.85-10.833-10.833 0-5.983 4.85-10.833 10.833-10.833 5.983 0 10.833 4.85 10.833 10.833C64.827 59.98 59.98 64.827 54 64.833Z"
      clipRule="evenodd"
    />
    <path
      fill="#C68A19"
      fillRule="evenodd"
      d="M54 46.417c-.598 0-1.083.485-1.083 1.083v8.667a1.083 1.083 0 1 0 2.166 0V47.5c0-.598-.485-1.083-1.083-1.083Z"
      clipRule="evenodd"
    />
    <rect width="2.167" height="2.167" x="52.917" y="59.417" fill="#C68A19" rx="1.083" />
  </svg>
);
export default DisclaimerIcon;
