import React, {useContext} from 'react';
import {Box, createStyles, InputAdornment, makeStyles, IconButton} from '@material-ui/core';
import {Formik, FormikProps} from 'formik';
import {css} from 'glamor';
import * as Yup from 'yup';
import valid from 'card-validator';
import {SwitchSlider} from './SwitchSlider';
import infoIcon from '../../assets/png/infoIcon.png';
import {SPopover} from '../modals/modalSpendaMeterialUI';
import {CreditCardTypeCardBrandId} from 'credit-card-type/dist/types';
import {ITenantSupplierPaymentMethod} from '../../model/payment/PaymentMethod';
import Cards, {ReactCreditCardProps, CustomCreditCardProps} from '../creditCards';
import {STextField} from '../inputs/STextField';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import {InfoRound} from '../../assets/svg/InfoRound';
import {PaymentMethodTypes, PaymentServiceType} from '../../model/constants/Constants';
import {Mastercard} from '../../assets/svg/available-payment-methods/Mastercard';
import {Visa} from '../../assets/svg/available-payment-methods/Visa';
import {Amex} from '../../assets/svg/available-payment-methods/Amex';
import {DinersClub} from '../../assets/svg/available-payment-methods/DinersClub';
import {UnionPay} from '../../assets/svg/payment-methods/UnionPay';
import {kebabToPascal} from '../../utils/formatter';
import AppContext from '../../context/app/appContext';

interface ICreditCardProps {
  onSubmit: (card: ReactCreditCardProps) => void;
  onIssuerChanged?: (issuer: string) => void;
  onCancel?: (values?: any, actions?: any) => void;
  disabled?: boolean;
  hidden?: boolean;
  isSaveCard?: boolean;
  isShowAutoPaymentsCheck?: boolean;
  saveCardForFuture?: boolean;
  toggleSaveCardForFuture?: () => void;
  isPaymentFromBuyer?: boolean;
  layout?: 'compact' | 'default';
  cardToEdit?: ITenantSupplierPaymentMethod;
  issuerClassName?: string;
  isBPSP: boolean;
  availableCreditCards?: string[];
}

export const CreditCardForm = (props: ICreditCardProps) => {
  const isCompact = props.layout === 'compact';

  const responsive = css({
    '@media(min-width: 1280px)': {
      flexWrap: 'no-wrap',
    },
    '@media(max-width: 1024px)': {
      flexWrap: 'wrap',
    },
  });

  const speechBubbleModal = css({
    position: 'relative',
    bottom: '0px',
    padding: '5px',
    background: '#FFF',
    borderRadius: '10px',
    border: '1px solid #0082BA',
    '&:after': {
      content: "''",
      position: 'absolute',
      borderStyle: 'solid',
      borderWidth: '0px 12px 9px',
      borderColor: '#FFF transparent',
      display: 'block',
      width: 0,
      zIndex: 1,
      top: '128px',
      left: '167px',
      transform: 'rotate(180deg)',
    },
    '&:before': {
      content: "''",
      position: 'absolute',
      borderStyle: 'solid',
      borderWidth: '0px 12px 9px',
      borderColor: '#0082BA transparent',
      display: 'block',
      width: 0,
      zIndex: 0,
      top: '129px',
      left: '167px',
      transform: 'rotate(180deg)',
    },
  });

  const scale = css({
    transform: 'scale(0.35)',
  });

  const useStyles = makeStyles(() =>
    createStyles({
      popover: {
        pointerEvents: 'none',
        backgroundColor: 'transparent !important',
      },
      paper: {
        // padding: theme.spacing(1),
        overflow: 'hidden',
        backgroundColor: 'transparent !important',
      },
      root: {
        '& .MuiPaper-root': {
          height: '140px',
        },
        '& .MuiPaper-elevation8': {
          boxShadow: 'none !important',
        },
      },
      iconRoot: {
        color: '#BBB',
        fontSize: '1.25rem',
        '& .MuiSvgIcon-root': {
          color: '#BBB !important',
        },
      },
      crossButton: {
        width: 'auto !important',
      },
      SaveThisCard: {
        '& .MuiFormControlLabel-root': {
          '& .MuiTypography-body1': {
            fontSize: '12px!important',
            fontFamily: 'poppins',
          },
        },
        marginLeft: '-26px',
        marginTop: '5px',
      },
      inputField: {
        position: 'relative',
        '& .MuiOutlinedInput-input:-webkit-autofill': {
          '-webkit-box-shadow': '0 0 0 100px #fff inset !important',
        },
        '& .MuiInputAdornment-root': {
          display: 'none',
        },
        '& .MuiOutlinedInput-root': {
          padding: '8px 14px',
          height: '100%',
          '&.Mui-focused': {
            '& .MuiInputAdornment-root': {
              display: 'contents',
            },
          },
        },
        '& .MuiInputLabel-outlined': {
          transform: 'translate(14px, 16px) scale(1)',
          fontFamily: 'Poppins !important',
          fontSize: '14px',
          fontWeight: 500,
          width: 'auto',
          color: '#bbbbbb !important',
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
          transform: 'translate(14px, -4.7px) scale(1)',
          fontSize: '10px ',
        },
        '& textarea, input': {
          padding: '0px',
          fontFamily: 'Poppins !important',
          fontSize: '14px',
        },
        '& .MuiFormHelperText-root.Mui-error': {
          fontFamily: 'Poppins !important',
          position: 'relative !important',
          top: '0px !important',
        },
        '& .MuiInputAdornment-positionEnd button': {
          padding: '0px !important',
        },
      },
    }),
  );

  const tooltip = css({
    position: 'relative',
    display: 'flex',
    cursor: 'pointer',
    '& .tooltipStyles': {
      display: 'none',
      width: '316px',
      backgroundColor: '#fff',
      color: '#333',
      borderRadius: '4px',
      padding: '5px 20px',
      position: 'absolute',
      bottom: '192%',
      right: '-100%',
      zIndex: 1,
      boxShadow: '0px 0px 9px 6px rgba(0, 0, 0, 25%)',
      '&:after': {
        content: '""',
        position: 'absolute',
        top: '100%',
        right: '8%',
        borderWidth: '10px',
        borderStyle: 'solid',
        borderColor: '#fff transparent transparent transparent',
      },
    },
    '&:hover .tooltipStyles': {
      display: 'flex',
    },
  });

  const {availablePaymentMethodTypes} = useContext(AppContext);
  const {availableCreditCards} = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [cardType, setCardType] = React.useState<string>('');

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const initialState: CustomCreditCardProps = {
    cvc: '',
    expiry: props.cardToEdit?.Expiry || '',
    focused: 'number',
    name: props.cardToEdit?.CardHolderName || '',
    number: props.cardToEdit?.Last4 ? `•••• •••• •••• ${props.cardToEdit.Last4}` : '',
    fname: props.cardToEdit?.FriendlyName || '',
    isInvigoDefault: props.cardToEdit?.IsInvigoDefault || false,
    originalFname: props.cardToEdit?.FriendlyName || '',
    originalIsInvigoDefault: props.cardToEdit?.IsInvigoDefault || false,
  };

  const onResetForm = (values: any, actions: any) => {
    if (props.onCancel) {
      props.onCancel(values, actions);
    }
  };
  const CreditCardSchema = Yup.object().shape({
    number: Yup.string()
      .required('Card Number is required')
      .test('test-card-number', 'Card Number is invalid', value => valid.number(value!).isValid)
      .test('test-card-type', 'Card type not supported by Supplier', value => {
        const validation = valid.number(value!);
        let type = validation.card?.type as CreditCardTypeCardBrandId;

        //ex-> american-express-->AmericanExpress
        const pascalType = kebabToPascal(type);

        setCardType(pascalType);
        if (availablePaymentMethodTypes && availablePaymentMethodTypes.length) {
          return availablePaymentMethodTypes
            .map(a => (a?.serviceType === PaymentServiceType.CREDIT_CARD ? a.paymentMethod : []))
            .includes(pascalType);
        } else if (availableCreditCards) {
          return availableCreditCards?.includes(pascalType) ?? false;
        } else {
          if (!props.isBPSP) {
            // Accept all cards
            return true;
          }
          return type === 'visa' || type === 'mastercard';
        }
      }),
    cvc: Yup.string()
      .required('CVC is required')
      .test('test-cvc-number', 'CVC is invalid', value => {
        if (cardType === PaymentMethodTypes.AmericanExpress) {
          return valid.cvv(value!, 4).isValid || valid.cvv(value!, 3).isValid;
        }
        return valid.cvv(value!).isValid;
      }),
    name: Yup.string().required('Name is required').max(96, 'Name can not exceed 96 characters'),
    expiry: Yup.string()
      .required('Expiry Date is required')
      .test('test-card-expiry-date', 'Expiry Date is invalid', value => valid.expirationDate(value!).isValid),
  });

  const getAvailablePaymentMethods = () => {
    let icon = null;
    return availableCreditCards?.map(pm => {
      switch (pm) {
        case PaymentMethodTypes.Visa:
          icon = <Visa />;
          break;
        case PaymentMethodTypes.AmericanExpress:
          icon = <Amex />;
          break;
        case PaymentMethodTypes.Mastercard:
          icon = <Mastercard />;
          break;
        case PaymentMethodTypes.DinersClub:
          icon = <DinersClub />;
          break;
        case PaymentMethodTypes.UnionPay:
          icon = <UnionPay />;
          break;
        default:
          return;
      }
      return (
        <span className="px-3" key={pm}>
          {icon}
        </span>
      );
    });
  };

  const isOneTimeUseCardEnabled = availablePaymentMethodTypes?.find(
    a => a.paymentMethod === cardType,
  )?.isSingleUsePermitted;

  return (
    <Formik
      initialValues={initialState}
      validationSchema={props.cardToEdit ? undefined : CreditCardSchema}
      onSubmit={props.onSubmit}
      onReset={onResetForm}
    >
      {({
        values,
        setFieldValue,
        handleSubmit,
        handleReset,
        errors,
        touched,
        handleBlur,
        handleChange,
      }: FormikProps<CustomCreditCardProps>) => {
        return (
          <>
            <Box overflow="hidden">
              <Box display={`${isCompact || 'flex'}`} justifyContent={'space-around'} className={`${responsive} ${''}`}>
                <Box className={`mb-2`}>
                  {!props.hidden && (
                    <div className={`flex xl:mr-0 ${isCompact ? '' : 'xl:ml-10'}`}>
                      <Cards
                        cvc={values.cvc}
                        expiry={values.expiry}
                        focused={values.focused}
                        name={values.name}
                        number={values.number}
                        callback={(cardDetails: {issuer: string}) => {
                          if (props.onIssuerChanged) {
                            props.onIssuerChanged(cardDetails.issuer);
                          }
                        }}
                      />
                    </div>
                  )}
                </Box>

                <Box flexBasis="45%" px="0.5rem">
                  <form
                    onSubmit={handleSubmit}
                    onReset={handleReset}
                    id="credit-card-form"
                    className={`${!props.hidden ? `xl:mr-0 xl:px-4 ${isCompact ? '' : 'lg:mx-4 xl:ml-4'}` : 'm-0 w-0'}`}
                    style={{padding: isCompact ? '0 18px' : ''}}
                  >
                    <fieldset disabled={props.disabled}>
                      {!props.hidden && (
                        <>
                          {props.isPaymentFromBuyer && (
                            <Box
                              className={`${classes.inputField}`}
                              style={{paddingTop: 4, marginTop: isCompact ? 8 : 12}}
                            >
                              <STextField
                                size="small"
                                height="45px"
                                backgroundColor="#FFF"
                                labelColor={touched.fname && Boolean(errors?.fname) ? '#FF0000' : '#333'}
                                borderColor="#333"
                                variant="outlined"
                                name="fname"
                                error={touched.fname && Boolean(errors?.fname)}
                                label="Nickname"
                                value={values?.fname}
                                onChange={handleChange}
                                onFocus={(e: React.FocusEvent<any>) => {
                                  setFieldValue('focused', e.target.name);
                                }}
                                onBlur={handleBlur}
                                fullWidth
                                InputProps={{
                                  style: {fontFamily: 'Poppins', fontSize: '10px'},
                                  endAdornment: values?.fname?.length ? (
                                    <InputAdornment position="end">
                                      <IconButton
                                        className={classes.crossButton}
                                        data-autoid="clearFname"
                                        onClick={() => setFieldValue('fname', '')}
                                        onMouseDown={e => e.preventDefault()}
                                      >
                                        <ClearRoundedIcon className={`${classes.iconRoot} `} />
                                      </IconButton>
                                    </InputAdornment>
                                  ) : (
                                    ''
                                  ),
                                }}
                              />
                            </Box>
                          )}

                          <Box
                            className={`${classes.inputField}`}
                            style={{paddingTop: 4, marginTop: isCompact ? 8 : 12}}
                          >
                            <STextField
                              backgroundColor="#FFF"
                              labelColor={touched.name && Boolean(errors?.name) ? '#FF0000' : '#333'}
                              borderColor="#333"
                              height="45px"
                              size="small"
                              variant="outlined"
                              id="name"
                              name="name"
                              error={touched.name && Boolean(errors?.name)}
                              label={(touched.name && errors?.name) ?? `Card holder name`}
                              value={values?.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              onFocus={(e: React.FocusEvent<any>) => {
                                setFieldValue('focused', e.target.name);
                              }}
                              disabled={!!props.cardToEdit}
                              fullWidth
                              InputLabelProps={{
                                shrink: Boolean((touched.name && errors.name) || values?.name?.length),
                              }}
                              InputProps={{
                                style: {fontFamily: 'Poppins', fontSize: '10px'},
                                endAdornment:
                                  values?.name?.length && !Boolean(props.cardToEdit) ? (
                                    <InputAdornment position="end">
                                      <IconButton
                                        className={classes.crossButton}
                                        data-autoid="clearName"
                                        onClick={() => setFieldValue('name', '')}
                                        onMouseDown={e => e.preventDefault()}
                                      >
                                        <ClearRoundedIcon className={`${classes.iconRoot} `} />
                                      </IconButton>
                                    </InputAdornment>
                                  ) : null,
                              }}
                            />
                          </Box>

                          <Box
                            className={`${classes.inputField}  flex flex-row`}
                            style={{paddingTop: 4, marginTop: isCompact ? 8 : 12}}
                          >
                            <Box width={availableCreditCards ? '90%' : '100%'}>
                              <STextField
                                type="number"
                                backgroundColor="#FFF"
                                labelColor={touched.number && Boolean(errors?.number) ? '#FF0000' : '#333'}
                                borderColor="#333"
                                height="45px"
                                size="small"
                                variant="outlined"
                                name="number"
                                error={touched.number && Boolean(errors?.number)}
                                label={(touched.number && errors?.number) ?? 'Card number'}
                                value={values?.number}
                                onChange={handleChange}
                                onFocus={(e: React.FocusEvent<any>) => {
                                  setFieldValue('focused', e.target.name);
                                }}
                                onBlur={handleBlur}
                                disabled={!!props.cardToEdit}
                                fullWidth
                                InputLabelProps={{
                                  shrink: Boolean((touched.number && errors.number) || values?.number),
                                }}
                                InputProps={{
                                  style: {fontFamily: 'Poppins', fontSize: '10px'},
                                  endAdornment:
                                    values?.number && !Boolean(props.cardToEdit) ? (
                                      <InputAdornment position="end">
                                        <IconButton
                                          className={classes.crossButton}
                                          data-autoid="clearCardNumber"
                                          onClick={() => setFieldValue('number', '')}
                                          onMouseDown={e => e.preventDefault()}
                                        >
                                          <ClearRoundedIcon className={`${classes.iconRoot} `} />
                                        </IconButton>
                                      </InputAdornment>
                                    ) : (
                                      ''
                                    ),
                                }}
                              />
                            </Box>
                            {availableCreditCards ? (
                              <div className="ml-2 flex items-center">
                                <div className={`${tooltip}`}>
                                  <InfoRound
                                    style={{
                                      width: '24px',
                                      height: '24px',
                                      color: Boolean(touched.number && errors.number) ? '#C55D44' : '#BBBBBB',
                                    }}
                                  />
                                  <div className={'tooltipStyles flex flex-col'} style={{height: '74px'}}>
                                    <p className="py-1 font-poppins text-xs">
                                      These are the card types your Supplier supports:
                                    </p>
                                    <div className="flex flex-row justify-center py-2 pt-1">
                                      {getAvailablePaymentMethods()}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </Box>
                          <Box className={`${classes.inputField}`} display="flex" justifyContent="space-between">
                            <Box width="49.5%" style={{paddingTop: 4, marginTop: isCompact ? 8 : 12}}>
                              <STextField
                                backgroundColor="#FFF"
                                labelColor={touched.expiry && Boolean(errors?.expiry) ? '#FF0000' : '#333'}
                                borderColor="#333"
                                height="45px"
                                size="small"
                                variant="outlined"
                                name="expiry"
                                id="expiry"
                                error={touched.expiry && Boolean(errors?.expiry)}
                                label={(touched.expiry && errors?.expiry) ?? 'Expiry date'}
                                value={values?.expiry}
                                onChange={handleChange}
                                onFocus={(e: React.FocusEvent<any>) => {
                                  setFieldValue('focused', e.target.name);
                                }}
                                onBlur={handleBlur}
                                disabled={!!props.cardToEdit}
                                fullWidth
                                maxLength={values.expiry.toString()[2] === '/' ? 5 : 4}
                                InputLabelProps={{
                                  shrink: Boolean((touched.expiry && errors.expiry) || values?.expiry),
                                }}
                                InputProps={{
                                  style: {fontFamily: 'Poppins', fontSize: '10px'},
                                  endAdornment:
                                    values?.expiry && !Boolean(props.cardToEdit) ? (
                                      <InputAdornment position="end">
                                        <IconButton
                                          className={classes.crossButton}
                                          data-autoid="clearExpiryDate"
                                          onClick={() => setFieldValue('expiry', '')}
                                          onMouseDown={e => e.preventDefault()}
                                        >
                                          <ClearRoundedIcon className={`${classes.iconRoot} `} />
                                        </IconButton>
                                      </InputAdornment>
                                    ) : (
                                      ''
                                    ),
                                }}
                              />
                            </Box>
                            <Box width="47.5%" style={{paddingTop: 4, marginTop: isCompact ? 8 : 12}}>
                              <STextField
                                backgroundColor="#FFF"
                                labelColor={touched.cvc && Boolean(errors?.cvc) ? '#FF0000' : '#333'}
                                borderColor="#333"
                                height="45px"
                                size="small"
                                variant="outlined"
                                name="cvc"
                                id="cvc"
                                error={touched.cvc && Boolean(errors?.cvc)}
                                label={(touched.cvc && errors?.cvc) ?? 'CVC'}
                                value={values?.cvc}
                                onChange={handleChange}
                                onFocus={(e: React.FocusEvent<any>) => {
                                  setFieldValue('focused', e.target.name);
                                }}
                                onBlur={handleBlur}
                                disabled={!!props.cardToEdit}
                                fullWidth
                                InputLabelProps={{
                                  shrink: Boolean((touched.cvc && errors.cvc) || values?.cvc),
                                }}
                                InputProps={{
                                  style: {fontFamily: 'Poppins', fontSize: '10px'},
                                  endAdornment:
                                    values?.cvc && !Boolean(props.cardToEdit) ? (
                                      <InputAdornment position="end">
                                        <IconButton
                                          className={classes.crossButton}
                                          data-autoid="clearCVC"
                                          onClick={() => setFieldValue('cvc', '')}
                                          onMouseDown={e => e.preventDefault()}
                                        >
                                          <ClearRoundedIcon className={`${classes.iconRoot} `} />
                                        </IconButton>
                                      </InputAdornment>
                                    ) : (
                                      ''
                                    ),
                                }}
                              />
                            </Box>
                          </Box>
                          {props.isSaveCard && !props.cardToEdit?.PaymentAccountGUID ? (
                            <Box
                              display="flex"
                              justifyContent={'space-between'}
                              className={`${classes.SaveThisCard} justify-center`}
                            >
                              <SwitchSlider
                                label="Would you like to save this card?"
                                name="save"
                                checked={props.saveCardForFuture!}
                                handleChange={() =>
                                  isOneTimeUseCardEnabled &&
                                  props.toggleSaveCardForFuture &&
                                  props.toggleSaveCardForFuture()
                                }
                                labelPlacement="start"
                              />

                              {isCompact ? (
                                <div className={`${tooltip} flex items-center`} style={{marginLeft: '11px'}}>
                                  <InfoRound
                                    style={{
                                      width: '24px',
                                      height: '24px',
                                      color: '#BBBBBB',
                                    }}
                                  />
                                  <div className={'tooltipStyles flex flex-col'} style={{bottom: '114%'}}>
                                    <p className="py-1 font-poppins text-xs">
                                      All credit card details are saved using a method called ‘tokenisation’ that keeps
                                      personal data secure. Spenda's tokenisation replaces your credit card details with
                                      a unique token, that way, we don't need to store your credit card details in our
                                      system, maximising your security.
                                    </p>
                                  </div>
                                </div>
                              ) : (
                                <>
                                  <div
                                    className="ml-3 flex items-center"
                                    style={{marginRight: isCompact ? '-1px' : ''}}
                                    aria-owns={open ? 'mouse-over-popover' : undefined}
                                    aria-haspopup="true"
                                    onMouseEnter={handlePopoverOpen}
                                    onMouseLeave={handlePopoverClose}
                                  >
                                    <InfoRound
                                      style={{
                                        width: '24px',
                                        height: '24px',
                                        color: '#BBBBBB',
                                      }}
                                    />
                                  </div>

                                  <SPopover
                                    id="mouse-over-popover"
                                    className={classes.popover}
                                    classes={{
                                      paper: classes.paper,
                                      root: classes.root,
                                    }}
                                    open={open}
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                      vertical: 'top',
                                      horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                      vertical: 'bottom',
                                      horizontal: 'center',
                                    }}
                                    onClose={handlePopoverClose}
                                    disableRestoreFocus
                                  >
                                    <Box className={`p-2 ${speechBubbleModal}`}>
                                      All credit card details are saved using a method called <br />
                                      ‘tokenisation’ that keeps personal data secure. <br />
                                      Spenda's tokenisation replaces your credit card details <br />
                                      with a unique token, that way, we don't need to store your <br />
                                      credit card details in our system, maximising your security.
                                    </Box>
                                  </SPopover>
                                </>
                              )}
                            </Box>
                          ) : (
                            <></>
                          )}

                          {props.cardToEdit?.PaymentAccountGUID && props.isShowAutoPaymentsCheck ? (
                            <div className="flex">
                              <SwitchSlider
                                label={'Enabled for Auto-Pay repayments'}
                                name="IsInvigoDefault"
                                checked={values.isInvigoDefault!}
                                handleChange={(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                                  setFieldValue('isInvigoDefault', checked);
                                }}
                                labelPlacement="start"
                              />

                              <div>
                                <img
                                  aria-owns={open ? 'mouse-over-popover' : undefined}
                                  aria-haspopup="true"
                                  onMouseEnter={handlePopoverOpen}
                                  onMouseLeave={handlePopoverClose}
                                  src={infoIcon}
                                  className={`${scale}`}
                                  alt="Info"
                                />
                              </div>

                              <SPopover
                                id="mouse-over-popover"
                                className={classes.popover}
                                classes={{
                                  paper: classes.paper,
                                  root: classes.root,
                                }}
                                open={open}
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                  vertical: 'top',
                                  horizontal: 'center',
                                }}
                                transformOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'center',
                                }}
                                onClose={handlePopoverClose}
                                disableRestoreFocus
                              >
                                <Box className={`p-2 ${speechBubbleModal}`}>
                                  Set this payment method as my default for auto-payments. <br />
                                  Auto-payments are payments that are automatically processed <br />
                                  when due. By enabling this payment method for auto-pay you <br />
                                  agree to that this payment method will be automatically <br />
                                  charged for future pre-authorised payments.
                                </Box>
                              </SPopover>
                            </div>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                    </fieldset>
                  </form>
                </Box>
              </Box>
            </Box>
          </>
        );
      }}
    </Formik>
  );
};
