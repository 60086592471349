import { IPlaceAutoCompleteResult } from "../model/address/place-prediction"
import { IActionResults, IActionResultsList } from "../model/ActionResults"
import { IAddress } from "../model/address/Address";
import useHttp from '../hooks/useHttp';


const useAddressAPI = () => {

    const { GET, isLoading} = useHttp();
    
    const search = (searchTerm: string): Promise<IPlaceAutoCompleteResult[]> => {
        return GET(`Places/Search?term=${searchTerm}`).then((data:IActionResultsList<IPlaceAutoCompleteResult>) => data.Items);
    }
    
    const get = (placeId: string): Promise<IAddress> => {
        return GET(`Places/Get?placeId=${placeId}`).then((data: IActionResults<IAddress>) => data.Value);
    }
    
    return {
        search,
        get,
        isLoading
    }
}

export default useAddressAPI;