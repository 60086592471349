import React from 'react';

export const Amex = () => {
  return (
    <svg
      width="29px"
      height="29px"
      viewBox="0 0 29 29"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>amex-american-express-seeklogo.com</title>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Spenda-Collect-Supplier-Interface-Copy-16" transform="translate(-1092.000000, -551.000000)">
          <g id="amex-american-express-seeklogo.com" transform="translate(1092.000000, 551.000000)">
            <polygon id="Fill-1" fill="#016FD0" points="0 29 29 29 29 0 0 0"></polygon>
            <polyline
              id="Fill-2"
              fill="#FFFFFE"
              points="10 23.9766427 10 14 29 14.0159324 29 16.7717795 26.8039619 18.9650415 29 21.1784123 29 23.9927299 25.493754 23.9927299 23.6303988 22.0710927 21.7802847 24 10 23.9766427"
            ></polyline>
            <polyline
              id="Fill-3"
              fill="#016FD0"
              points="12 23 12 15 19 15 19 16.8429502 14.266462 16.8429502 14.266462 18.0939437 18.8871444 18.0939437 18.8871444 19.9062152 14.266462 19.9062152 14.266462 21.1349547 19 21.1349547 19 23 12 23"
            ></polyline>
            <polyline
              id="Fill-4"
              fill="#016FD0"
              points="19.0001546 23 22.6516307 18.9953107 19 15 21.8263256 15 24.0576028 17.5355773 26.2952201 15 29 15 29 15.0629483 25.4263071 18.9953107 29 22.8865023 29 23 26.2680038 23 23.9971392 20.438989 21.749625 23 19.0001546 23"
            ></polyline>
            <polyline
              id="Fill-5"
              fill="#FFFFFE"
              points="11.5353257 5 15.9511243 5 17.5021036 8.42170954 17.5021036 5 22.9537502 5 23.8938488 7.56353009 24.8371391 5 29 5 29 15 7 15 11.5353257 5"
            ></polyline>
            <path
              d="M12.5865026,6 L9,14 L11.4598484,14 L12.1364834,12.3979636 L15.8028074,12.3979636 L16.4789606,14 L19,14 L15.4284338,6 L12.5865026,6 Z M12.8947064,10.6026329 L13.9702878,8.05592014 L15.0450662,10.6026329 L12.8947064,10.6026329 L12.8947064,10.6026329 Z"
              id="Fill-6"
              fill="#016FD0"
            ></path>
            <polyline
              id="Fill-7"
              fill="#016FD0"
              points="19 13.981271 19 6 22.3390201 6.01174533 24.0571522 10.9184374 25.786451 6 29 6 29 13.981271 26.9321308 14 26.9321308 8.51762792 24.9801169 13.981271 23.0926222 13.981271 21.1001287 8.49889888 21.1001287 13.981271 19 13.981271"
            ></polyline>
          </g>
        </g>
      </g>
    </svg>
  );
};
