import React from 'react';
import {SpendaNoTagLogo} from '../../assets/svg/SpendaNoTagLogo';

export const PSBLFooter = () => {
  return (
    <a data-autoid={'lnkSpendaLogoBuyerEnd'} href="https://www.spenda.co/" target="_blank">
      <div className="flex flex-row justify-center items-center absolute bottom-0 h-[40px] w-[100vw] bg-[#eeeeee]">
        <SpendaNoTagLogo height="38px" />
      </div>
    </a>
  );
};
