import { useMemo } from "react";
import environment from "../utils/environment";

export const useEnvironment = () => {

    const CapricornEnvironmentNames = [
        'capproduction',
        'capsandbox',
        'devtest',
        'capdevtest',
        'capperf',
        'dev1',
        'tst1',
        'stg1',
        'sbx',
        'prd',
    ];

    const isCapricornEnvironment = useMemo(() => {

        if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.log('environment.FEAT_NAME', environment.FEAT_NAME);
        }

        return CapricornEnvironmentNames.includes(environment.FEAT_NAME)
    },
        [environment.FEAT_NAME]);

    return {
        CapricornEnvironmentNames,
        isCapricornEnvironment,
        ZOHO_WIDGET_CODE: environment.ZOHO_WIDGET_CODE
    };
}


