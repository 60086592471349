import {Formik, Form, getIn} from 'formik';
import React, {useState, useEffect} from 'react';
import {Accordion, AccordionBody, Button, DialogFooter, Input, Radio, Typography} from 'spenda-ui-react';
import {Grid} from '@material-ui/core';
import * as Yup from 'yup';
import {omit} from 'lodash';

import {ICreateCustomerClassValues} from '../../model/customer/CustomerClasses';
import {useCustomersAPI, useCustomerLinking} from '../../services/useCustomersAPI';
import {CustomerClassesAutocomplete} from '../customer/CustomerAutocomplete';

import {IconBin} from '../../assets/svg/IconBin';
import {ICustomer} from '../../model/customer/Customer';
import {LocationAutoComplete} from '../location/LocationAutoComplete';
import {IAddress} from '../../model/address/Address';
import {useLocationAPI} from '../../services/useLocationAPI';
import LoadingIndicator from '../ui/LoadingIndicator';
import {RightAngleIcon} from '../../assets/svg';

export const CreateNewCustomerAccountForm = (props: any) => {
  const {getLocationAddress} = useLocationAPI();
  const [expand, setExpand] = useState(false);
  const [refNumberChange, setRefNumberChange] = useState<boolean>(false);

  const [customerData, setCustomerData] = useState<ICustomer>();
  const [searchFilter, setSearchFilter] = useState<any>();
  const [customerClassFilter, setCustomerClassFilter] = useState<Partial<ICreateCustomerClassValues>>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const {getCustomer, save, getCustomerByReferenceNo} = useCustomersAPI();
  const {loading, response} = useCustomerLinking();

  const validationSchema1 = Yup.object({
    Name: Yup.string()
      .nullable(true)
      .required('Company Name is required')
      .max(50, 'First Name cannot be longer than 50 characters.'),
    Phone1: Yup.string()
      .matches(/^[0-9]+$/, 'Only numbers are allowed for this field')
      .max(20, 'More than 20 characters note allowed.'),
    ClassID: Yup.number().typeError('Only number allowed').required('Class is required'),
    ABN: Yup.string().matches(
      /^(?:\d{2}-\d{3}-\d{3}-\d{3}|\d{11})$/,
      'ABN must be of 11 characters without any blank space',
    ),
    RefNumber: Yup.string().test({
      name: 'RefNumber',
      test: function () {
        setTimeout(() => {}, 1000);
        return refNumberChange === true
          ? this.createError({message: 'Unique Reference number require', path: 'RefNumber'})
          : true;
      },
    }),
    Contacts: Yup.array().of(
      Yup.object()
        .nullable(true)
        .shape({
          EmailAddress: Yup.string().email('Invalid Email address').nullable(true),
          FirstName: Yup.string()
            .nullable(true)
            .required('First Name is required')
            .max(50, 'First Name cannot be longer than 50 characters.'),
          LastName: Yup.string()
            .nullable(true)
            .required('Last Name is required')
            .max(50, 'Last Name cannot be longer than 50 characters.'),
          Phone1: Yup.string()
            .matches(/^[0-9]+$/, 'Only numbers are allowed for this field')
            .max(20, 'More than 20 characters note allowed.'),
        }),
    ),
  });

  useEffect(() => {
    if (props?.popUpID) {
      getCustomer(props?.popUpID).then(res => {
        setCustomerData(res);
        setIsLoading(false);
      });
    } else {
      if (props.customerId) {
        getCustomer(props.customerId).then(res => {
          setCustomerData(res);
          setIsLoading(false);
        });
      }
    }
  }, [props.customerId, props?.popUpID]);

  useEffect(() => {
    if (response?.IsSuccess && props.addCustomer) {
      props.fetchCustomerInfo(props.customerId);
      props.onClose(true);
    } else if (response?.IsSuccess && props.customerLinked) {
      props.getCustomersList();
      props.onClose(true);
    }
  }, [response]);

  const newCustomer: ICustomer = {
    Name: customerData?.Name,
    Phone1: customerData?.Phone1,
    ABN: customerData?.ABN,
    RefNumber: customerData?.RefNumber,
    IsIndividual: customerData?.IsIndividual === false ? 'false' : 'true',
    IsExemptFromMerchantSurcharge: customerData?.IsExemptFromMerchantSurcharge,
    ClassID: undefined,
    IsAccountCustomer: true,
    OperationalCustomerIDs: [props.customerId],
    Locations: customerData?.Locations?.length
      ? customerData.Locations
      : ([
          {
            FullAddress: '',
            StreetAddress: '',
            City: '',
            State: '',
            PostCode: '',
            Country: '',
          },
        ] as IAddress[]),
    Contacts: [
      {
        EmailAddress: customerData?.PrimaryContactEmailAddress,
        FirstName: customerData?.PrimaryContactFirstName,
        LastName: customerData?.PrimaryContactLastName || '',
        Phone1: customerData?.PrimaryContactPhone,
      },
    ],
  } as ICustomer;

  const onSubmit1 = (values: ICustomer): void => {
    let data = values;
    if (values.IsIndividual === 'true') {
      data.Name = '';
    }

    let i = 0;
    for (i = data?.Locations?.length || -1; i >= 0; i--) {
      if (data?.Locations?.[i]?.Addresses?.[0]?.FullAddress) {
        delete data?.Locations?.[i]?.Addresses?.[0]?.FullAddress;
      }
    }
    save(data).then(() => {
      if (props.customerLinked) {
        props.getCustomersList();
        props.onClose(true);
        props.setCustomerTab(0);
        getCustomer(props?.customerId).then(res => {
          if (res) props.setCustomer(res);
        });
      } else if (props.addCustomer) {
        props.fetchCustomerInfo(props.customerId);
        props.setChangeNewCustomer(true);
        props.onClose(true);
        props.setCustomerTab(0);
      }
    });
  };

  const handleCustomerClassFilter = (setFieldValue: Function, selected: Partial<ICreateCustomerClassValues> | null) => {
    if (setSearchFilter) {
      setSearchFilter({
        ...omit(searchFilter, ['customerClassID', 'LastRowNumber']),
        ...(selected ? {customerClassID: selected?.ID} : {}),
      });
    }
    setCustomerClassFilter(selected || undefined);
    if (selected) {
      setFieldValue('ClassID', selected?.ID);
    } else {
      setFieldValue('ClassID', undefined);
    }
  };
  return (
    <>
      {isLoading ? (
        <LoadingIndicator isLoading size={'md'} />
      ) : (
        <>
          <Formik
            enableReinitialize
            validationSchema={validationSchema1}
            initialValues={newCustomer}
            onSubmit={onSubmit1}
          >
            {({values, touched, errors, handleSubmit, handleChange, handleBlur, setFieldValue, setValues}) => (
              <Form onSubmit={handleSubmit} className="flex h-full flex-col">
                <Typography variant="h2" className="mb-5 mt-3 font-medium text-[#4D4D4D]">
                  Create Account Customer
                </Typography>
                <div className="felx flex-col">
                  <div className="flex gap-x-3 text-spenda-primarytext">
                    <Radio
                      name="IsIndividual"
                      onChange={handleChange}
                      value="true"
                      label="Individual"
                      color="primary"
                      containerProps={{
                        className: '-ml-3',
                      }}
                      className="border-primary before:hover:opacity-0"
                      labelProps={{
                        className: 'text-spenda-primarytext font-normal',
                      }}
                    />
                    <Radio
                      name="IsIndividual"
                      onChange={handleChange}
                      value="false"
                      label="Company"
                      defaultChecked
                      color="primary"
                      className="border-primary before:hover:opacity-0"
                      labelProps={{
                        className: 'text-spenda-primarytext font-normal',
                      }}
                    />
                  </div>
                  <hr className="my-2.5 border-none" />
                  <Grid container spacing={1}>
                    {values?.IsIndividual === 'false' && (
                      <>
                        <Grid item sm={2} alignItems="flex-end" className="flex">
                          <Typography variant="paragraph" className="pb-2 font-normal text-[#333333]">
                            Company Name:
                          </Typography>
                        </Grid>
                        <Grid item sm={3}>
                          <Input
                            id="Name"
                            name="Name"
                            variant="standard"
                            placeholder="Company Name"
                            value={values.Name}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            helperText={touched?.Name ? errors?.Name : ''}
                            error={touched?.Name && Boolean(errors?.Name)}
                            className="w-full"
                          />
                        </Grid>
                        <Grid item sm={2} />
                      </>
                    )}
                    <Grid item sm={2} alignItems="flex-end" className="flex">
                      <Typography variant="paragraph" className="pb-2 font-normal text-[#333333]">
                        Account Ref:
                      </Typography>
                    </Grid>
                    <Grid item sm={3}>
                      <Input
                        id="RefNumber"
                        name="RefNumber"
                        variant="standard"
                        placeholder="Account Ref"
                        value={values.RefNumber}
                        onBlur={e => {
                          handleBlur(e);
                          getCustomerByReferenceNo(values?.RefNumber || '').then(data => {
                            if (data.length === 1 && values?.RefNumber) {
                              setRefNumberChange(true);
                            } else {
                              setRefNumberChange(false);
                            }
                          });
                        }}
                        onChange={e => {
                          handleChange(e);
                          getCustomerByReferenceNo(values?.RefNumber || '').then(data => {
                            if (data.length === 1 && values?.RefNumber) {
                              setRefNumberChange(true);
                            } else {
                              setRefNumberChange(false);
                            }
                          });
                        }}
                        helperText={touched?.RefNumber ? errors?.RefNumber : ''}
                        error={touched?.RefNumber && Boolean(errors?.RefNumber)}
                        className="w-full"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item sm={2} alignItems="flex-end" className="flex">
                      <Typography variant="paragraph" className="pb-2 font-normal text-[#333333]">
                        ABN:
                      </Typography>
                    </Grid>
                    <Grid item sm={3}>
                      <Input
                        id="ABN"
                        name="ABN"
                        variant="standard"
                        placeholder="ABN"
                        value={values.ABN}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        helperText={touched?.ABN ? errors?.ABN : ''}
                        error={touched?.ABN && Boolean(errors?.ABN)}
                        className="w-full"
                      />
                    </Grid>
                    {values?.IsIndividual === 'false' && (
                      <>
                        <Grid item sm={2} />
                        <Grid item sm={2} alignItems="flex-end" className="flex">
                          <Typography variant="paragraph" className="pb-2 font-normal text-[#333333]">
                            Phone No:
                          </Typography>
                        </Grid>
                        <Grid item sm={3}>
                          <Input
                            id="Phone1"
                            name="Phone1"
                            variant="standard"
                            placeholder="Phone No"
                            value={values.Phone1}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            helperText={touched?.Phone1 ? errors?.Phone1 : ''}
                            error={touched?.Phone1 && Boolean(errors?.Phone1)}
                            className="w-full"
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                  <hr className="my-5 border-[#F1F1F1]" />

                  <Typography variant="h2" className="mb-5 mt-3 font-medium text-[#4D4D4D]">
                    Primary Contact
                  </Typography>

                  <hr className="my-2.5 h-0.5 border-none" />
                  <Grid container spacing={1}>
                    <Grid item sm={2} alignItems="flex-end" className="flex">
                      <Typography variant="paragraph" className="pb-2 font-normal text-[#333333]">
                        First Name:
                      </Typography>
                    </Grid>
                    <Grid item sm={3}>
                      <Input
                        id={`Contacts[0].FirstName`}
                        name={`Contacts[0].FirstName`}
                        variant="standard"
                        placeholder="First Name"
                        value={values?.Contacts?.[0]?.FirstName}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        helperText={getIn(touched, 'Contacts[0].FirstName') && getIn(errors, 'Contacts[0].FirstName')}
                        error={
                          getIn(touched, 'Contacts[0].FirstName') && Boolean(getIn(errors, 'Contacts[0].FirstName'))
                        }
                        className="w-full"
                      />
                    </Grid>
                    <Grid item sm={2} />
                    <Grid item sm={2} alignItems="flex-end" className="flex">
                      <Typography variant="paragraph" className="pb-2 font-normal text-[#333333]">
                        Last Name:
                      </Typography>
                    </Grid>
                    <Grid item sm={3}>
                      <Input
                        id={`Contacts[0].LastName`}
                        name={`Contacts[0].LastName`}
                        variant="standard"
                        placeholder="Last Name"
                        value={values?.Contacts?.[0]?.LastName}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        helperText={getIn(touched, 'Contacts[0].LastName') && getIn(errors, 'Contacts[0].LastName')}
                        error={getIn(touched, 'Contacts[0].LastName') && Boolean(getIn(errors, 'Contacts[0].LastName'))}
                        className="w-full"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item sm={2} alignItems="flex-end" className="flex">
                      <Typography variant="paragraph" className="pb-2 font-normal text-[#333333]">
                        Email Address:
                      </Typography>
                    </Grid>
                    <Grid item sm={3}>
                      <Input
                        id={`Contacts[0].EmailAddress`}
                        name={`Contacts[0].EmailAddress`}
                        variant="standard"
                        placeholder="Email Address"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        helperText={
                          getIn(touched, 'Contacts[0].EmailAddress') && getIn(errors, 'Contacts[0].EmailAddress')
                        }
                        error={
                          getIn(touched, 'Contacts[0].EmailAddress') &&
                          Boolean(getIn(errors, 'Contacts[0].EmailAddress'))
                        }
                        className="w-full"
                      />
                    </Grid>
                    <Grid item sm={2} />
                    <Grid item sm={2} alignItems="flex-end" className="flex">
                      <Typography variant="paragraph" className="pb-2 font-normal text-[#333333]">
                        Mobile No:
                      </Typography>
                    </Grid>
                    <Grid item sm={3}>
                      <Input
                        id={`Contacts[0].Phone1`}
                        name={`Contacts[0].Phone1`}
                        variant="standard"
                        placeholder="Mobile No"
                        value={values?.Contacts?.[0]?.Phone1}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        helperText={getIn(touched, 'Contacts[0].Phone1') && getIn(errors, 'Contacts[0].Phone1')}
                        error={getIn(touched, 'Contacts[0].Phone1') && Boolean(getIn(errors, 'Contacts[0].Phone1'))}
                        className="w-full"
                      />
                    </Grid>
                  </Grid>
                  <div hidden={expand}>
                    <Grid container spacing={2}>
                      <Grid item sm={2} alignItems="flex-end" className="flex">
                        <Typography variant="paragraph" className="pb-6 font-normal text-[#333333]">
                          Address:
                        </Typography>
                      </Grid>
                      <Grid item sm={10}>
                        <LocationAutoComplete
                          styles={{marginTop: '-1.5rem'}}
                          clearClick={() => {
                            setValues({
                              ...values,
                              Locations: [
                                {
                                  Addresses: [
                                    {
                                      StreetAddress: '',
                                      City: '',
                                      State: '',
                                      Country: '',
                                      PostCode: '',
                                    },
                                  ],
                                },
                              ],
                            });
                          }}
                          selected={
                            values?.Locations?.[0]?.Addresses?.[0]?.StreetAddress ||
                            values?.Locations?.[0]?.Addresses?.[0]?.City ||
                            values?.Locations?.[0]?.Addresses?.[0]?.State ||
                            values?.Locations?.[0]?.Addresses?.[0]?.Country ||
                            values?.Locations?.[0]?.Addresses?.[0]?.PostCode
                              ? {
                                  PlaceDescription: `${values?.Locations?.[0]?.Addresses?.[0]?.StreetAddress} ${values?.Locations?.[0]?.Addresses?.[0]?.City} ${values?.Locations?.[0]?.Addresses?.[0]?.State} ${values?.Locations?.[0]?.Addresses?.[0]?.PostCode} ${values?.Locations?.[0]?.Addresses?.[0]?.Country}`,
                                }
                              : null
                          }
                          onSuggestionSelected={(e, selected) => {
                            getLocationAddress(selected.suggestion.PlaceID || '').then((res: IAddress) => {
                              if (res) {
                                setFieldValue(`Locations[0].StreetAddress`, res.StreetAddress || '');
                                setFieldValue(`Locations[0].City`, res.City || '');
                                setFieldValue(`Locations[0].State`, res.State || '');
                                setFieldValue(`Locations[0].Country`, res.Country || '');
                                setFieldValue(`Locations[0].PostCode`, res.PostCode || '');
                              }
                            });
                          }}
                        />
                      </Grid>
                    </Grid>
                  </div>
                  <Accordion open={expand}>
                    <Grid item sm={12} alignItems="flex-end" className="flex" justify="flex-end">
                      <Button
                        variant="text"
                        className={`whitespace-nowrap hover:no-underline active:bg-transparent`}
                        ripple={false}
                        onClick={() => setExpand(!expand)}
                      >
                        <span className="flex items-center justify-center gap-x-1">
                          {expand ? 'Collapse' : 'Expand'}{' '}
                          <RightAngleIcon
                            className="h-3 w-3"
                            fill="hsl(var(--primary))"
                            style={{transform: !expand ? 'rotate(90deg)' : 'rotate(270deg)'}}
                          />
                        </span>
                      </Button>
                    </Grid>
                    <div>
                      <AccordionBody>
                        <Grid container spacing={1}>
                          <Grid item sm={2} className="flex items-end">
                            <Typography variant="paragraph" className="pb-2 font-normal text-[#333333]">
                              Street Address:
                            </Typography>
                          </Grid>
                          <Grid item sm={3}>
                            <Input
                              id={`Locations[0].StreetAddress`}
                              name={`Locations[0].StreetAddress`}
                              variant="standard"
                              placeholder="Street Address"
                              value={values?.Locations?.[0]?.Addresses?.[0]?.StreetAddress}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              helperText={
                                getIn(touched, 'Locations[0].Addresses[0].StreetAddress') &&
                                getIn(errors, 'Locations[0].Addresses[0].StreetAddress')
                              }
                              error={
                                getIn(touched, 'Locations[0].Addresses[0].StreetAddress') &&
                                Boolean(getIn(errors, 'Locations[0].Addresses[0].StreetAddress'))
                              }
                              className="w-full"
                            />
                          </Grid>
                          <Grid item sm={2} />
                          <Grid item sm={2} className="flex items-end">
                            <Typography variant="paragraph" className="pb-2 font-normal text-[#333333]">
                              City:
                            </Typography>
                          </Grid>
                          <Grid item sm={3}>
                            <Input
                              id={`Locations[0].City`}
                              name={`Locations[0].City`}
                              variant="standard"
                              placeholder="City"
                              value={values?.Locations?.[0]?.Addresses?.[0]?.City}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              helperText={
                                getIn(touched, 'Locations[0].Addresses[0].City') &&
                                getIn(errors, 'Locations[0].Addresses[0].City')
                              }
                              error={
                                getIn(touched, 'Locations[0].Addresses[0].City') &&
                                Boolean(getIn(errors, 'Locations[0].Addresses[0].City'))
                              }
                              className="w-full"
                            />
                          </Grid>
                        </Grid>

                        <Grid container spacing={1}>
                          <Grid item sm={2} className="flex items-end">
                            <Typography variant="paragraph" className="pb-2 font-normal text-[#333333]">
                              State:
                            </Typography>
                          </Grid>
                          <Grid item sm={3}>
                            <Input
                              id={`Locations[0].State`}
                              name={`Locations[0].State`}
                              variant="standard"
                              placeholder="State"
                              value={values?.Locations?.[0]?.Addresses?.[0].State}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              helperText={
                                getIn(touched, 'Locations[0].Addresses[0].State') &&
                                getIn(errors, 'Locations[0].Addresses[0].State')
                              }
                              error={
                                getIn(touched, 'Locations[0].Addresses[0].State') &&
                                Boolean(getIn(errors, 'Locations[0].Addresses[0].State'))
                              }
                              className="w-full"
                            />
                          </Grid>
                          <Grid item sm={2} />
                          <Grid item sm={2} className="flex items-end">
                            <Typography variant="paragraph" className="pb-2 font-normal text-[#333333]">
                              Country:
                            </Typography>
                          </Grid>
                          <Grid item sm={3}>
                            <Input
                              id={`Locations[0].Country`}
                              name={`Locations[0].Country`}
                              variant="standard"
                              placeholder="Country"
                              value={values?.Locations?.[0]?.Addresses?.[0]?.Country}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              helperText={
                                getIn(touched, 'Locations[0].Addresses[0].Country') &&
                                getIn(errors, 'Locations[0].Addresses[0].Country')
                              }
                              error={
                                getIn(touched, 'Locations[0].Addresses[0].Country') &&
                                Boolean(getIn(errors, 'Locations[0].Addresses[0].Country'))
                              }
                              className="w-full"
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                          <Grid item sm={2} className="flex items-end">
                            <Typography variant="paragraph" className="pb-2 font-normal text-[#333333]">
                              Post Code:
                            </Typography>
                          </Grid>
                          <Grid item sm={3}>
                            <Input
                              id={`Locations[0].PostCode`}
                              name={`Locations[0].PostCode`}
                              variant="standard"
                              placeholder="Post Code"
                              value={values?.Locations?.[0]?.Addresses?.[0]?.PostCode}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              className="w-full"
                            />
                          </Grid>
                        </Grid>
                      </AccordionBody>
                    </div>
                  </Accordion>

                  <Grid container spacing={1}>
                    <Grid item sm={2} className="flex items-end">
                      <Typography variant="paragraph" className="pb-2 font-normal text-[#333333]">
                        Customer Class:
                      </Typography>
                    </Grid>
                    <Grid item sm={3}>
                      <CustomerClassesAutocomplete
                        PostingBehaviour={'Account'}
                        customerClassType={true}
                        selected={customerClassFilter ? customerClassFilter : null}
                        clearClick={() => handleCustomerClassFilter(setFieldValue, null)}
                        onSuggestionSelected={(e, selected) =>
                          handleCustomerClassFilter(setFieldValue, selected.suggestion)
                        }
                        helperText={errors?.ClassID}
                        error={Boolean(errors?.ClassID)}
                      />
                    </Grid>
                  </Grid>
                  {customerClassFilter && (
                    <div className="flex w-[462px] flex-col rounded-md bg-[#EAEFF2] p-2.5">
                      <Typography variant="paragraph" className="pb-2 text-[#333333]">
                        {customerClassFilter.Name}
                      </Typography>
                      <Grid sm={12} container direction="row">
                        <Grid container sm={11} direction="column">
                          <Grid container spacing={1}>
                            <Grid item sm={3} alignItems="flex-end" className="flex">
                              <Typography variant="paragraph" className="p - 0 pb-2 text-[#999999]">
                                Credit Limit:
                              </Typography>
                            </Grid>
                            <Grid item sm={5}>
                              <Typography variant="paragraph" className="p - 0 pb-2 text-[#333333]">
                                {customerClassFilter.CreditLimit}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container spacing={1}>
                            <Grid item sm={3} alignItems="flex-end" className="flex">
                              <Typography variant="paragraph" className="p - 0 pb-2 text-[#999999]">
                                Trading Terms:
                              </Typography>
                            </Grid>
                            <Grid item sm={5}>
                              <Typography variant="paragraph" className="mt-3 text-[#333333]">
                                {customerClassFilter.TradingTermDays} Day After Invoice Name
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid container sm={1}>
                          <div onClick={() => handleCustomerClassFilter(setFieldValue, null)}>
                            <IconBin height="24px" width="19.73px" />
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  )}
                </div>
                <DialogFooter className="mx-10 my-0 border-t border-[#F1F1F1] px-0 py-2">
                  <div className="flex w-full justify-between">
                    <Button
                      loading={loading}
                      type="submit"
                      onClick={() => {
                        handleSubmit();
                        props?.setShowLinkOperationalCustomerDialog?.(false);
                      }}
                      className="!absolute bottom-[16px] right-9"
                    >
                      Save
                    </Button>
                  </div>
                </DialogFooter>
              </Form>
            )}
          </Formik>
        </>
      )}
    </>
  );
};
