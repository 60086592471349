import React, {useContext, useEffect} from 'react';
import OnboardingComponent from '../components/onboarding/Onboarding';
import AppContext from '../context/app/appContext';
import {useParams, useHistory} from 'react-router-dom';
import {ModuleTypes} from '../model/constants/Constants';
import {Box} from '@material-ui/core';
import {Menu} from './menu/Menu';

const Onboarding = () => {
  const history = useHistory();
  const appContext = useContext(AppContext);
  const {onboardingAccount} = useParams<{onboardingAccount?: string}>();

  useEffect(() => {
    const module = appContext?.tenantInfo?.Modules?.find(m => m.ModuleID === ModuleTypes.SpendaCollectAR);
    if (module?.IsModuleSetup) {
      history.push('/');
    }
  }, []);

  return (
    <>
      <Box>
        <Menu />
      </Box>
      <OnboardingComponent
        onboardingAccount={onboardingAccount}
        onBoardingAccountRedirectUrl={onboardingAccount ? window.location.href : ''}
      />
    </>
  );
};

export default Onboarding;
