import React from 'react';

export function SentInvoiceIcon(props: SVGProps) {
  return (
    <svg width={91} height={97} viewBox="0 0 91 97" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.358 0c7.043-.019 13.8 2.964 18.768 8.286l13.21 14.089c4.996 5.292 7.796 12.495 7.777 20.002v14.69c0 2.233-1.698 4.043-3.792 4.043-2.03 0-3.689-1.702-3.787-3.84l-.005-.202v-14.69c0-.668-.12-1.306-.177-1.961h-18.78c-6.283 0-11.376-5.429-11.376-12.125V8.274c-.614-.061-1.217-.19-1.838-.19h-17.12c-6.282 0-11.375 5.428-11.375 12.125v56.583c0 6.696 5.093 12.125 11.375 12.125h26.39c2.03 0 3.688 1.7 3.787 3.84l.005.201c0 2.233-1.698 4.042-3.792 4.042h-26.39C15.773 96.987 7.292 87.947 7.28 76.792V20.209C7.292 9.053 15.773.014 26.238 0h17.12zm5.63 68.709c2.094 0 3.792 1.81 3.792 4.041 0 2.232-1.698 4.042-3.792 4.042H30.03c-2.094 0-3.792-1.81-3.792-4.042 0-2.232 1.698-4.041 3.792-4.041h18.958zm11.375-16.167c2.094 0 3.792 1.81 3.792 4.042 0 2.232-1.698 4.041-3.792 4.041H30.03c-2.094 0-3.792-1.81-3.792-4.041 0-2.233 1.698-4.042 3.792-4.042h30.333zM52.78 10.848v17.444c0 2.232 1.697 4.042 3.791 4.042h16.365a20.102 20.102 0 00-2.961-4.244L56.765 14a19.136 19.136 0 00-3.985-3.152zM90.3 69.763c.07 1.163-.292 2.304-1.004 3.171L72.3 93.891a3.925 3.925 0 01-2.312 1.367l-.595.113c-1.129.026-2.22-.462-3.005-1.345L56.31 82.598c-.813-.822-1.278-1.97-1.289-3.171-.01-1.2.436-2.35 1.235-3.179.797-.829 1.877-1.262 2.984-1.2 1.108.063 2.145.617 2.868 1.532l7.046 7.956L83.262 67.14c.7-.862 1.69-1.388 2.75-1.464 1.06-.074 2.109.309 2.914 1.066.81.771 1.305 1.858 1.374 3.02z"
        className="fill-primary"
      />
    </svg>
  );
}
