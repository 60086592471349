import React, {useContext, useEffect, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {ARTable} from '../../../components/AccountsReceivable/ARTable';
import {IGetAllCustomersResponse, IOutStandingStatementSummaryList} from '../../../model/customer/CustomerStatements';
import {AUTH_SELLING_COLLECT_ROUTE} from '../../../routes/AuthenticatedRoutes';
import {ARCustomerStatementResponseStatus} from '../../../model/constants/Constants';
import ARContext from '../../../context/ar-context/ARContext';
import {PriceFormat} from '../../../utils/formatter';
import ARExpandBlueIcon from '../../../assets/svg/ARExpandBlueIcon';
import ARExpandYellowIcon from '../../../assets/svg/ARExpandYellowIcon';
import ARCreditStoppedIcon from '../../../assets/svg/ARCreditStoppedIcon';
import ARCreditHoldIcon from '../../../assets/svg/ARCreditHoldIcon';
import TableDropChange from '../../../assets/svg/TableDropChange';
import {MenuItem, Popover, makeStyles} from '@material-ui/core';
import {ARChatIcon} from '../../../assets/svg/ARChatIcon';
import {useFeatureFlags} from '../../../hooks/useFeatureFlags';
import {Badge, Typography} from 'spenda-ui-react';
import {useAutomatedStatementsAPI} from '../../../services/useAutomatedStatementsAPI';

interface ICustomerGroupStandardViewProps {
  customersList: IGetAllCustomersResponse | undefined;
  isLoading: boolean;
}

const useARStyle = makeStyles({
  changeSortValueStyles: {
    background: 'transparent !important',
    '& .MuiPaper-root': {
      border: '1px solid hsl(var(--primary))',
      boxShadow: 'none',
    },
    '& .MuiButtonBase-root': {
      color: '#333333',
      fontSize: '14px',
      fontWeight: '600',
      fontFamily: 'poppins',
      lineHeight: '16px',
      padding: '0px 40px 0px 10px',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      height: '40px',
      '& .MuiRadio-root': {
        display: 'none',
      },
    },
    '& .MuiButtonBase-root:hover': {
      backgroundColor: 'hsl(var(--primary-header))',
    },
  },
});

export const CustomerGroupStandardView = (props: ICustomerGroupStandardViewProps) => {
  // Props
  const {customersList, isLoading} = props;

  // Variables
  const history = useHistory();

  // Styles
  const classes = useARStyle();

  // Feature Flags
  const {statementsAndAutomatedStatement83193, scheduledPaymentsV2} = useFeatureFlags().tenantOwned();

  // Context
  const {setArModalToShow, setFilterConversationId} = useContext(ARContext);
  const {setEmailBatch, emailBatch} = useContext(ARContext);

  // APIs
  const {getLastNotficationSendDetails} = useAutomatedStatementsAPI();

  // State
  const [anchorEl, setAnchorEl] = React.useState<HTMLSpanElement | null>(null);
  const [sortByKey, setSortByKey] = useState('accountCustomerName');

  // contants
  const open = Boolean(anchorEl);
  const id = open ? 'change-sort-value' : undefined;

  // UseEffect
  useEffect(() => {
    return () => {
      setArModalToShow(undefined);
    };
  }, []);

  const handleSelectCustomers = async (customer: IOutStandingStatementSummaryList) => {
    try {
      if (emailBatch?.loading) return;
      setEmailBatch({...emailBatch, loading: true});
      const response = await getLastNotficationSendDetails({accountCustomerID: customer?.accountCustomerID});
      response?.[0]?.lastCustomerStatementSentDateTime_utc;
      const selectedCustomer = [
        {
          customerId: customer?.customerID,
          accountCustomerId: customer?.accountCustomerID,
          customerName: customer?.accountCustomerName,
          totalOverdue: customer?.overDue,
          totalOwing: customer?.due + customer?.overDue,
          lastNotificationSent: response?.[0]?.lastCustomerStatementSentDateTime_utc,
          lastTemplateUsed: response?.[0]?.templateName,
        },
      ];
      setEmailBatch({emailBatchList: selectedCustomer, loading: false});
    } catch {
      setEmailBatch(undefined);
    }
  };

  const columns = [
    {
      title: 'Customers',
      align: 'left',
      key: sortByKey,
      width: '40%',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: true,
      headerRenderer: () => (
        <div className="flex items-center justify-center gap-1">
          <Typography variant="paragraph" className="ml-auto text-left">
            Customers
          </Typography>
          <div className="flex items-center justify-center" onClick={e => e.stopPropagation()}>
            <span onClick={e => setAnchorEl(e.currentTarget)} aria-describedby={id} className="absolute -right-7 ">
              <TableDropChange />
            </span>
            <Popover
              className={`${classes.changeSortValueStyles} font-poppins text-black-800`}
              id={open ? id : undefined}
              open={open}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <MenuItem
                onClick={() => {
                  setSortByKey('accountCustomerName');
                  setAnchorEl(null);
                }}
              >
                Sort by name
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setSortByKey('accountCustomerRefNumber');
                  setAnchorEl(null);
                }}
              >
                Sort by ref ID
              </MenuItem>
            </Popover>
          </div>
        </div>
      ),
      rowRenderer: (rowData: IOutStandingStatementSummaryList) => (
        <div className="flex flex-row items-center justify-between">
          <span
            onClick={() => history.push(`${AUTH_SELLING_COLLECT_ROUTE}/${rowData?.accountCustomerID}`)}
            data-autoid={`lnkAccountCustomerName`}
            className={`cursor-pointer font-poppins text-base font-medium text-primary hover:text-spenda-title`}
          >
            <Typography variant="paragraph" className="cursor-pointer font-medium text-primary hover:text-primary">
              {rowData?.accountCustomerName}
            </Typography>
            <Typography variant="xsmall" className="text-xs text-spenda-labeltext">
              {rowData?.accountCustomerRefNumber}
            </Typography>
          </span>
          <div className="flex items-center justify-center">
            <div className="cursor-pointer" onClick={() => setFilterConversationId(rowData?.accountCustomerID)}>
              {!!rowData?.openConversations ? (
                <Badge
                  content={rowData?.openConversations}
                  color="error"
                  className="!min-h-[18px] !min-w-[18px] font-poppins !text-[10px]"
                >
                  <ARChatIcon color="#1C78AD" className=" cursor-pointer" width="18px" height="15" />
                </Badge>
              ) : (
                <ARChatIcon color="#33333375" className=" cursor-pointer" width="18px" height="15" />
              )}
            </div>
            {rowData?.isOnCreditStop && (
              <div className="ml-2 flex h-6 w-[30px] items-center justify-center rounded bg-[rgba(197,93,68,.1)]">
                <ARCreditStoppedIcon />
              </div>
            )}
            {rowData?.isOnCreditHold && (
              <div className="ml-2 flex h-6 w-[30px] items-center justify-center rounded bg-[rgba(198,138,25,0.125)]">
                <ARCreditHoldIcon />
              </div>
            )}
          </div>
        </div>
      ),
    },
    {
      title: 'Overdue',
      align: 'left',
      key: 'overDue',
      width: '14%',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: true,
      headerRenderer: () => (
        <div className={`text-right} ml-auto text-black-800`}>
          <Typography variant="paragraph" className="text-spenda-labeltext">
            Overdue
          </Typography>
          <Typography variant="paragraph" data-autoid={`lblTotalOverDue`}>
            {PriceFormat(customersList?.total_OverDue)}
          </Typography>
        </div>
      ),
      rowRenderer: (rowData: IOutStandingStatementSummaryList) => (
        <Typography
          variant="paragraph"
          className={`inline font-medium text-spenda-error ${rowData?.overDue == 0 ? 'opacity-50' : ''}`}
        >
          {PriceFormat(rowData?.overDue)}
          <Typography variant="paragraph" data-autoid={`lblOverDueCount`}>{`(${rowData?.overDue_Count} ${
            rowData?.overDue_Count > 1 ? 'invoices' : 'invoice'
          })`}</Typography>
        </Typography>
      ),
    },
    {
      title: 'Due',
      align: 'center',
      key: 'due',
      width: '14%',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: true,
      headerRenderer: () => (
        <div className={`text-right} ml-auto text-black-800`}>
          <Typography variant="paragraph" className="text-spenda-labeltext">
            Due
          </Typography>
          <Typography variant="paragraph" data-autoid={`lblTotalDue`}>
            {PriceFormat(customersList?.total_Due)}
          </Typography>
        </div>
      ),
      rowRenderer: (rowData: IOutStandingStatementSummaryList) => (
        <Typography
          variant="paragraph"
          data-autoid={`btnDue`}
          className={`inline font-medium text-black-800 ${rowData?.due == 0 ? 'opacity-50' : ''}`}
        >
          {PriceFormat(rowData?.due)}
        </Typography>
      ),
    },
    {
      title: 'Credits',
      align: 'right',
      key: 'credits',
      width: '14%',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: true,
      headerRenderer: () => (
        <div className={`text-right} ml-auto text-black-800`}>
          <Typography variant="paragraph" className="text-spenda-labeltext">
            Credits
          </Typography>
          <Typography variant="paragraph" data-autoid={`btnTotalcredits`}>
            {PriceFormat(customersList?.total_Credits)}
          </Typography>
        </div>
      ),
      rowRenderer: (rowData: IOutStandingStatementSummaryList) => (
        <Typography
          variant="paragraph"
          className={`inline font-medium text-primary ${rowData?.credits == 0 ? 'opacity-50' : ''}`}
        >
          {PriceFormat(rowData?.credits)}
          <Typography
            variant="paragraph"
            data-autoid={`lblCreditsCount`}
          >{`(${rowData?.credits_Count} unallocated)`}</Typography>
        </Typography>
      ),
    },
    {
      title: 'Balance',
      align: 'right',
      key: 'balance',
      width: '14%',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: true,
      headerRenderer: () => (
        <div className={`text-right} ml-auto text-black-800`}>
          <Typography variant="paragraph" className="text-spenda-labeltext">
            Balance
          </Typography>
          <Typography variant="paragraph" data-autoid={`lblTotalBalance`}>
            {PriceFormat(
              scheduledPaymentsV2
                ? customersList?.total_BalanceIncludingScheduledPayments
                : customersList?.total_Balance,
            )}
          </Typography>
        </div>
      ),
      rowRenderer: (rowData: IOutStandingStatementSummaryList) => (
        <div className="relative h-[45.5px]" title="Send Statement">
          <Typography variant="paragraph" data-autoid={`btnBalance`} className={'inline font-medium'}>
            {PriceFormat(scheduledPaymentsV2 ? rowData?.balanceIncludingScheduledPayments : rowData?.balance)}
          </Typography>
        </div>
      ),
    },
    {
      title: '',
      align: 'right',
      key: '',
      width: '14%',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'pl-2.5',
      rowRenderer: (rowData: IOutStandingStatementSummaryList) => (
        <div className="relative h-full" title="Send Statement">
          <div className={`flex min-h-[63px] w-[40px] cursor-pointer flex-row items-center justify-center self-end`}>
            {statementsAndAutomatedStatement83193 ? (
              <button
                className={`innerDiv flex min-h-[63px] w-full cursor-pointer items-center justify-center !bg-[#D3E5EF]`}
                onClick={e => {
                  e.stopPropagation();
                  handleSelectCustomers(rowData);
                }}
                data-autoid={`btnSendStatement-${rowData.accountCustomerID}`}
              >
                <div>
                  <ARExpandBlueIcon className="h-4 w-4" />
                </div>
              </button>
            ) : rowData.statementStatus === ARCustomerStatementResponseStatus.OPEN ? (
              <Link
                className={`innerDiv flex min-h-[63px] w-full cursor-pointer items-center justify-center`}
                style={{backgroundColor: '#C68A1920'}}
                data-autoid={`lnkEditPsbl`}
                key={`btnEditPsbl${rowData.accountCustomerID}`}
                to={`${AUTH_SELLING_COLLECT_ROUTE}/${rowData?.accountCustomerID}`}
              >
                <div>
                  <ARExpandYellowIcon className="h-4 w-4" />
                </div>
              </Link>
            ) : (
              <Link
                className={`innerDiv bg-primary-header flex min-h-[63px] w-full cursor-pointer items-center justify-center`}
                data-autoid={`lnkSendPsbl`}
                key={`btnSendPsbl${rowData.accountCustomerID}`}
                to={{
                  pathname: `${AUTH_SELLING_COLLECT_ROUTE}/${rowData?.accountCustomerID}`,
                  state: {isLoadAllStatmentInWidget: !!rowData?.statementID},
                }}
              >
                <div>
                  <ARExpandBlueIcon className="h-4 w-4" />
                </div>
              </Link>
            )}
          </div>
        </div>
      ),
    },
  ];

  return (
    <ARTable
      isHighlightRowOnHover
      tableClass="mb-12"
      rows={customersList?.outStandingStatementSummaryList || []}
      columns={columns}
      isLoading={isLoading}
      dataAutoId="OutstandingStatementSummary"
      getRowDataAutoId={rowData => `rowOutstandingStatementSummary${rowData?.accountCustomerRefNumber}`}
    />
  );
};
