import React, {PropsWithChildren, createContext, useContext, useState} from 'react';
import {ApplicationInfo} from '../model/constants/ApplicationInfo';
import {IMasterAdaptor} from '../model/MasterAdaptor';
import useMasterAdaptorAPI from '../services/useMasterAdaptorAPI';
import AppContext from './app/appContext';
import {useFeatureFlags} from '../hooks/useFeatureFlags';
import {MasterAdaptors} from '../model/constants/Constants';

interface IntegrationContextValue {
  adaptors: IMasterAdaptor[];
  financialAdaptor: IMasterAdaptor | undefined;
  getAdaptors: (isRefresh?: boolean) => Promise<IMasterAdaptor[]>;
  isAPFinancialAdaptorSetup: () => boolean;
  setAdaptors: (adaptors: IMasterAdaptor[]) => void;
}

export const IntegrationContext = createContext<IntegrationContextValue>({
  adaptors: [],
  financialAdaptor: undefined,
  getAdaptors: (_isRefresh?: boolean) => new Promise<IMasterAdaptor[]>(resolve => resolve([])),
  isAPFinancialAdaptorSetup: () => false,
  setAdaptors: (_adaptors: IMasterAdaptor[]) => {},
});

export const IntegrationProvider: React.FC<PropsWithChildren> = ({children}) => {
  const {tenantInfo} = useContext(AppContext);
  const [adaptors, setAdaptors] = useState<IMasterAdaptor[]>([]);
  const {apQboIntegration} = useFeatureFlags().tenantOwned();

  const {search} = useMasterAdaptorAPI();

  const tenantId = tenantInfo?.TenantUserDetails?.TenantID || 0;

  const getAdaptors = async (isRefresh?: boolean) => {
    if (isRefresh || adaptors.length === 0) {
      // fetch adaptors
      const adaptorsResponse = await search(tenantId, ApplicationInfo.WebsiteId);
      return adaptorsResponse.filter((account: IMasterAdaptor) => account.Type === 'Accounts');
    } else {
      return adaptors;
    }
  };

  const financialAdaptor = adaptors.find(
    adaptor => adaptor.Type === 'Accounts' && Boolean(adaptor.ID) && adaptor.IsUsedByTenant,
  );

  const isAPFinancialAdaptorSetup = (): boolean => {
    const xeroOrMyObAdaptor = adaptors.find(
      adaptor =>
        adaptor.Type === 'Accounts' &&
        (adaptor.MasterAdaptorID === MasterAdaptors.Xero ||
          adaptor.MasterAdaptorID === MasterAdaptors.Myob ||
          (apQboIntegration && adaptor.MasterAdaptorID === MasterAdaptors.Quickbooks)) &&
        Boolean(adaptor.ID) &&
        adaptor.IsUsedByTenant,
    );
    return Boolean(xeroOrMyObAdaptor);
  };

  return (
    <IntegrationContext.Provider
      value={{adaptors, getAdaptors, setAdaptors, isAPFinancialAdaptorSetup, financialAdaptor}}
    >
      {children}
    </IntegrationContext.Provider>
  );
};
