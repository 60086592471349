import useHttp from '../hooks/useHttp';
import {IMasterAdaptor} from '../model/MasterAdaptor';

const useMasterAdaptorAPI = () => {
  const {POST, isLoading} = useHttp();

  const search = (tenantId: number, websiteId: number): Promise<IMasterAdaptor[]> => {
    const data = {
      TenantID: tenantId,
      websiteId,
    };
    return POST('masterAdaptor/search', data).then(data => (data ? data.Items : undefined));
  };

  const selectAdaptor = (selctedAdaptor: number[], websiteId: number): Promise<any> => {
    const data = {
      Value: selctedAdaptor,
      websiteId: websiteId,
    };
    return POST('adaptor/select', data).then((data: any) => data);
  };

  return {
    search,
    selectAdaptor,
    isLoading,
  };
};

export default useMasterAdaptorAPI;
