import {IActionResults, IActionResultsList} from '../model/ActionResults';
import {IConsignmentNote} from '../model/sales-order/ConsignmentNote';
import useHttp from '../hooks/useHttp';

export const useConsignmentNoteAPI = () => {
  const {GET, POST, PATCH, isLoading} = useHttp();

  const createConsignmentNote = async (
    payload: Partial<IConsignmentNote>
  ): Promise<IActionResults<IConsignmentNote>> => {
    return POST(`consignmentnote`, {Value: payload}).then((data: IActionResults<IConsignmentNote>) => data);
  };

  const getConsignmentNote = (filter: any): Promise<IConsignmentNote[]> => {
    return GET('ConsignmentNote', filter).then((data: IActionResultsList<IConsignmentNote>) => data.Items || []);
  };

  const getConsignmentNoteById = async (ID: any): Promise<IActionResults<IConsignmentNote>> => {
    return GET(`consignmentnote/${ID}`).then((data: IActionResults<IConsignmentNote>) => data);
  };

  const updateConsignmentNote = async (
    payload: Partial<IConsignmentNote>
  ): Promise<IActionResults<IConsignmentNote>> => {
    return POST(`consignmentnote`, {Value: payload}).then((data: IActionResults<IConsignmentNote>) => data);
  };

  const updateConsignmentNoteStatus = async (
    ID: any,
    payload: Partial<IConsignmentNote>
  ): Promise<IActionResults<IConsignmentNote>> => {
    return POST(`consignmentnote/${ID}/status`, {Value: payload}).then(
      (data: IActionResults<IConsignmentNote>) => data
    );
  };

  const patchConsignmentNote = async (
    ID: any,
    payload: Partial<IConsignmentNote>
  ): Promise<IActionResults<IConsignmentNote>> => {
    return PATCH(`consignmentnote/${ID}`, {Value: payload}).then((data: IActionResults<IConsignmentNote>) => data);
  };

  return {
    getConsignmentNote,
    getConsignmentNoteById,
    updateConsignmentNote,
    createConsignmentNote,
    updateConsignmentNoteStatus,
    patchConsignmentNote,
    isLoading,
  };
};
