import * as React from 'react';
import {SVGProps} from 'react';

export const Document = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="21px"
    height="24px"
    viewBox="0 0 21 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <title>{'Icons/Documents/document'}</title>
    <g id="Spenda-Pay-Onboarding" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <g id="Spenda-Collect-Supplier-Interface-Copy-23" transform="translate(-1068.000000, -279.000000)" fill="#C68A19">
        <g id="Icons/Documents/document" transform="translate(1067.000000, 279.000000)">
          <path
            d="M16.92,14.0000459 C16.92,14.5523307 16.4722847,15.0000459 15.92,15.0000459 L7.92,15.0000459 C7.36771525,15.0000459 6.92,14.5523307 6.92,14.0000459 C6.92,13.4477612 7.36771525,13.0000459 7.92,13.0000459 L15.92,13.0000459 C16.4722847,13.0000459 16.92,13.4477612 16.92,14.0000459 Z M12.92,17.0000459 L7.92,17.0000459 C7.36771525,17.0000459 6.92,17.4477612 6.92,18.0000459 C6.92,18.5523307 7.36771525,19.0000459 7.92,19.0000459 L12.92,19.0000459 C13.4722847,19.0000459 13.92,18.5523307 13.92,18.0000459 C13.92,17.4477612 13.4722847,17.0000459 12.92,17.0000459 Z M21.9200255,10.4850459 L21.9200255,19.0000459 C21.9166939,21.7600991 19.6800532,23.9967398 16.92,24.0000459 L6.92,24.0000459 C4.15994685,23.9967398 1.92330612,21.7600991 1.92,19.0000459 L1.92,5.00004594 C1.92330612,2.23999279 4.15994685,0.00335205513 6.92,2.28585869e-05 L11.435,2.28585869e-05 C13.2924877,-0.00473488788 15.0747763,0.733384627 16.385,2.05004594 L19.869,5.53604594 C21.1864425,6.84541756 21.9250297,8.62760591 21.9200255,10.4850459 L21.9200255,10.4850459 Z M14.971,3.46404594 C14.6562813,3.15920695 14.3029335,2.89696981 13.92,2.68404594 L13.92,7.00004594 C13.92,7.55233069 14.3677153,8.00004594 14.92,8.00004594 L19.236,8.00004594 C19.022956,7.61723539 18.7603588,7.2641918 18.455,6.95004594 L14.971,3.46404594 Z M19.92,10.4850459 C19.92,10.3200459 19.888,10.1620459 19.873,10.0000459 L14.92,10.0000459 C13.2631458,10.0000459 11.92,8.65690019 11.92,7.00004594 L11.92,2.04704594 C11.758,2.03204594 11.599,2.00004594 11.435,2.00004594 L6.92,2.00004594 C5.26314575,2.00004594 3.92,3.34319169 3.92,5.00004594 L3.92,19.0000459 C3.92,20.6569002 5.26314575,22.0000459 6.92,22.0000459 L16.92,22.0000459 C18.5768542,22.0000459 19.92,20.6569002 19.92,19.0000459 L19.92,10.4850459 Z"
            id="Shape"
          />
        </g>
      </g>
    </g>
  </svg>
);
