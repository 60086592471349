import React, {useState, useRef} from 'react';
import {Formik, FormikProps} from 'formik';

import {DatTypes} from '../../model/constants/Constants';
import {IBatchUpdate, IBatchUpdatesArray} from '../../model/BatchUpdate';
import {ApplicationInfo} from '../../model/constants/ApplicationInfo';
import {IActionResults} from '../../model/ActionResults';
import {AlertDialogSlide} from './AlertDialogSlide';
import {SButton} from '../buttons/Button';
import {ToggleSwitch} from '../buttons/ToggleSwitch';
import {SDialog} from '../modals/modalSpendaMeterialUI';
import {ModifyInventoryForm, ModifyInventoryValues} from '../form/ModifyInventoryForm';
import {useBatchUpdateAPI} from '../../services/useBatchUpdateAPI';
import {Toast} from '../../utils/Toast';
import {Box, DialogActions, DialogContent, DialogTitle, IconButton, makeStyles} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useBrandDialogStyles = makeStyles(theme => ({
  dialogActions: {
    borderTop: '1px solid lightgrey',
    margin: '0 1rem',
    padding: '0.5rem 0',
  },
  dialogContent: {
    margin: '20px',
    flex: 1,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogPaper: {
    minHeight: '60vh',
  },
}));

interface IModifyInventoryDialogProps {
  open: boolean;
  selectedRows: number[];
  onClose: (refetchList?: boolean) => void;
}

const dependancyObject: {[key: string]: 'UoM' | 'IsSold'} = {
  UoMDescription: 'UoM',
  CategoryID: 'IsSold',
  IsPublished: 'IsSold',
  IsApproved: 'IsSold',
};

export const ModifyDialog: React.FunctionComponent<IModifyInventoryDialogProps> = ({
  open,
  selectedRows,
  onClose,
}: IModifyInventoryDialogProps) => {
  const classes = useBrandDialogStyles();
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState<boolean>(false);
  const [saveConfirmationDialog, setSaveConfirmationDialog] = useState<boolean>(false);

  const {batchUpdate} = useBatchUpdateAPI();

  const formRef = useRef<FormikProps<ModifyInventoryValues>>(null);

  const newModifyValues: ModifyInventoryValues = {
    IsSohTracked: undefined,
    IsPhysical: undefined,
    IsPublished: undefined,
    IsSold: undefined,
    IsPurchased: undefined,
    IsApproved: undefined,
    UoM: '',
    UoMDescription: '',
    Quantity: '',
    IsRemoveFromCollection: false,
    BrandID: undefined,
    CategoryID: undefined,
    InventoryClassID: undefined,
  } as ModifyInventoryValues;

  const handleCloseClick = (props: FormikProps<ModifyInventoryValues>, cancelClick: boolean = true) => {
    if (cancelClick && props.dirty) {
      setOpenConfirmationDialog(true);
    } else {
      if (formRef && typeof formRef.current?.resetForm === 'function') {
        const resetForm = formRef.current?.resetForm;
        resetForm();
      }
      onClose();
    }
  };

  const onSave = (values: ModifyInventoryValues) => {
    const {IsRemoveFromCollection, ...modifyValues} = values;
    const batchUpdatePayload: IBatchUpdate = {
      Updates: [],
      WebsiteID: ApplicationInfo.WebsiteId,
    } as IBatchUpdate;

    for (const [key, value] of Object.entries(modifyValues)) {
      const dependantValue = dependancyObject[key];
      if (dependantValue && !modifyValues[dependantValue]) {
        continue;
      }
      if (typeof value === 'boolean' || value) {
        const batchData = {
          DatTypeID: DatTypes.Inventory,
          ColumnToUpdate: key,
          // @ts-ignore
          Value: value?.ID ? value.ID : value,
          IDs: selectedRows,
          IsRemoveFromCollection: key === 'CategoryID' ? Boolean(IsRemoveFromCollection) : false,
        } as IBatchUpdatesArray;
        batchUpdatePayload.Updates.push(batchData);
      }
    }

    return batchUpdate(batchUpdatePayload).then((res: IActionResults<number>) => {
      if (res.IsSuccess) {
        if (formRef && typeof formRef.current?.resetForm === 'function') {
          const resetForm = formRef.current?.resetForm;
          resetForm();
        }
        onClose(true);
        Toast.info('Inventory Updated.');
      }
    });
  };

  return (
    <>
      {openConfirmationDialog && (
        <AlertDialogSlide
          maxWidth="xs"
          title="Discard Changes"
          footer={
            <div className="w-full flex justify-between px-4 py-2">
              <SButton
                margin="0 0.25rem"
                color="transparent"
                border="1px solid #1C78AD"
                textColor="blueShade"
                lineHeight="m"
                width="m"
                type="button"
                onClick={() => setOpenConfirmationDialog(false)}
              >
                Cancel
              </SButton>
              <SButton
                margin="0 0.25rem"
                color="blueShade"
                textColor="white"
                lineHeight="m"
                width="m"
                type="button"
                onClick={() => {
                  if (onClose) {
                    setOpenConfirmationDialog(false);
                    if (formRef && typeof formRef.current?.resetForm === 'function') {
                      const resetForm = formRef.current?.resetForm;
                      resetForm();
                    }
                    onClose();
                  }
                }}
              >
                Discard
              </SButton>
            </div>
          }
        >
          <p className="pb-3 font-medium text-center">
            You have unsaved changes, are you sure you want to discard changes?
          </p>
        </AlertDialogSlide>
      )}
      {saveConfirmationDialog && (
        <AlertDialogSlide
          maxWidth="xs"
          title="Save Changes"
          footer={
            <div className="w-full flex justify-between px-4 py-2">
              <SButton
                margin="0 0.25rem"
                color="transparent"
                border="1px solid #1C78AD"
                textColor="blueShade"
                lineHeight="m"
                width="m"
                type="button"
                onClick={() => setSaveConfirmationDialog(false)}
              >
                Cancel
              </SButton>
              <SButton
                margin="0 0.25rem"
                color="blueShade"
                textColor="white"
                lineHeight="m"
                width="m"
                type="button"
                onClick={() => {
                  setSaveConfirmationDialog(false);
                  if (formRef && typeof formRef.current?.submitForm === 'function') {
                    const submitForm = formRef.current?.submitForm;
                    submitForm();
                  }
                }}
              >
                Save
              </SButton>
            </div>
          }
        >
          <p className="pb-3 font-medium text-center">Are you sure you want to update the inventory?</p>
        </AlertDialogSlide>
      )}
      <Formik innerRef={formRef} enableReinitialize initialValues={newModifyValues} onSubmit={onSave}>
        {props => (
          <SDialog
            classes={{paper: classes.dialogPaper}}
            maxWidth="md"
            fullWidth
            open={open!}
            onClose={() => onClose()}
          >
            <DialogTitle>
              <Box display="flex" justifyContent="center" marginTop="10px">
                {` Modify Selected Products `}
                <IconButton
                  aria-label="close"
                  className={classes.closeButton}
                  onClick={() => handleCloseClick(props, false)}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </DialogTitle>
            <DialogContent classes={{root: classes.dialogContent}}>
              <ModifyInventoryForm {...props} />
            </DialogContent>
            <DialogActions classes={{root: classes.dialogActions}}>
              <Box display="flex" justifyContent="space-between" width="100%" paddingY="8px">
                <SButton
                  margin="0 0.25rem"
                  color="transparent"
                  border="1px solid #1C78AD"
                  textColor="blueShade"
                  lineHeight="m"
                  width="m"
                  type="button"
                  onClick={() => handleCloseClick(props)}
                >
                  Cancel
                </SButton>
                <div className="flex items-center" style={{transform: 'scale(0.75)'}}>
                  <ToggleSwitch disabled value={false} handleChange={() => {}} />
                  <label className="text-spenda-primarytext text-lg ml-2 mr-4">No</label>
                  <ToggleSwitch disabled value={true} handleChange={() => {}} />
                  <label className="text-spenda-primarytext text-lg ml-2 mr-4">Yes</label>
                  <ToggleSwitch disabled value={undefined} handleChange={() => {}} />
                  <label className="text-spenda-primarytext text-lg ml-2">Initial</label>
                </div>
                <SButton
                  margin="0 0.25rem"
                  color="blueShade"
                  border="1px solid #1C78AD"
                  textColor="white"
                  lineHeight="m"
                  width="m"
                  onClick={() => setSaveConfirmationDialog(true)}
                  isSubmitting={props.isSubmitting}
                  disabled={!props.dirty}
                >
                  Save
                </SButton>
              </Box>
            </DialogActions>
          </SDialog>
        )}
      </Formik>
    </>
  );
};
