import React, {useState} from 'react';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import {IBrand} from '../../model/inventory/Brand';
import {MenuItem, Select} from '@material-ui/core';
import FilterButton from '../form/FilterButton';

export const BrandSelector = (props: {
  isShowFavourites: boolean | undefined;
  selectedBrand: IBrand | undefined;
  brands: IBrand[];
  setSelectedBrand: (bra: IBrand) => void;
  deselectBrand: (bra: IBrand) => void;
}) => {
  const [open, setOpen] = useState(false);

  const onChangeBrand = (e: React.ChangeEvent<{name?: string; value: any}>): void => {
    props.setSelectedBrand(e.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div style={{flex: '1 1 10%'}}>
      <FilterButton label={props.selectedBrand ? props.selectedBrand.Name : 'All Brands'} onClick={handleOpen}>
        {' '}
        <LocalOfferIcon />
      </FilterButton>
      <Select
        style={{color: 'black', position: 'absolute', opacity: 0}}
        value={props.selectedBrand}
        onChange={onChangeBrand}
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
      >
        {props.brands.map((bra: IBrand, index) => (
          <MenuItem
            key={index}
            // @ts-ignore
            value={bra}
          >
            {bra.Name}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};
