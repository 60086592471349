import {useContext} from 'react';
import {IConnectedSupplier} from './../model/supplier/ConnectedSupplier';
import {ModuleStatus, ModuleTypes, PaymentMethodTypes} from '../model/constants/Constants';
import AppContext from '../context/app/appContext';
// import {useFeatureFlags} from './useFeatureFlags';
import {isWebsiteId17} from '../config';

const useConnectedSupplier = () => {
  const {tenantInfo} = useContext(AppContext);

  const getRedirectPath = (supplier?: IConnectedSupplier): string => {
    let path = '/';

    if (!supplier || !supplier.AvailableModules || !supplier.AvailableModules.length) {
      return supplier?.MarketplacePath || path;
    }

    const module = tenantInfo?.Modules?.find(m => m.ModuleID === ModuleTypes.SpendaPay);
    if (isWebsiteId17 && (module?.Status != ModuleStatus.SubscriptionActive || !module?.IsModuleSetup)) {
      path = `/supplier/module-info/psbl`;
    } else if (isWebsiteId17) {
      path = `/supplier/${supplier.SupplierID}/requires-attention`;
    } else if (supplier.AvailableModules.indexOf(ModuleTypes.SpendaCollectConnectedStatements) >= 0) {
      path = `/supplier/${supplier.SupplierID}/bills`;
    } else if (supplier.AvailableModules.indexOf(ModuleTypes.SpendaMarketBuying) >= 0) {
      path = `/supplier/${supplier.SupplierID}/order`;
    }

    return path;
  };

  const isBPSP = (supplier?: {
    AvailablePaymentMethods?: string[];
    IsPaymentServicesCreditCardMerchant?: boolean;
  }): boolean =>
    supplier && supplier.AvailablePaymentMethods
      ? (supplier.AvailablePaymentMethods?.indexOf(PaymentMethodTypes.Visa) >= 0 ||
          supplier.AvailablePaymentMethods?.indexOf(PaymentMethodTypes.Mastercard) >= 0) &&
        supplier.IsPaymentServicesCreditCardMerchant!
      : false;

  const isBT = (supplier: IConnectedSupplier): boolean =>
    supplier.AvailablePaymentMethods?.indexOf(PaymentMethodTypes.BankTransfer) >= 0;
  return {
    getRedirectPath,
    isBPSP,
    isBT,
  };
};

export default useConnectedSupplier;
