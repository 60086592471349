import React, {useContext} from 'react';
import {Form, Formik, FormikHelpers} from 'formik';
import * as Yup from 'yup';
import {ITenant} from '../../model/Tenant';
import {IBaseUser, IUserAccountInfo} from '../../model/user/UserAccountInfo';
import {IUser} from '../../model/user/User';
import AppContext from '../../context/app/appContext';
import useUsersAPI from '../../services/useUsersAPI';
import useTenantAPI from '../../services/useTenantAPI';
import {Toast} from '../../utils/Toast';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {Button, Checkbox, Input} from 'spenda-ui-react';
import {SpendaNoTagLogo} from '../../assets/svg/SpendaNoTagLogo';
import ThemeContext from '../../context/theme-context/ThemeContext';
import {isWebsiteId17} from '../../config';
import {useFeatureFlags} from '../../hooks/useFeatureFlags';

type TenantSetupValidationSchema = {
  firstName: string;
  lastName: string;
  companyName?: string;
  agreeTnCs: boolean;
  companyPhoneNumber?: string;
  email: string;
};

interface ITenantSetupComponentProps {
  onSubmit?: (values: TenantSetupValidationSchema, helpers: FormikHelpers<TenantSetupValidationSchema>) => void;
  onAfterSubmit?: () => void;
  tenant?: ITenant;
  user?: IBaseUser;
}

export const TenantSetupComponent = (props: ITenantSetupComponentProps) => {
  const {setUserTenant} = useContext(AppContext);
  const {saveUser} = useUsersAPI();
  const {saveTenantDetails} = useTenantAPI();
  const {payStatementByLinkV289938} = useFeatureFlags().supplierDriven();

  const {Users} = props.tenant || {};
  const user: Partial<IBaseUser> = props.user ? props.user : Users?.length ? Users[0] : {};

  const initialValues: TenantSetupValidationSchema = {
    firstName: user.FirstName || '',
    lastName: user.LastName || '',
    companyName: user.TenantName?.includes('@') ? '' : user.TenantName,
    agreeTnCs: false,
    companyPhoneNumber: user.Phone1 || '',
    email: user.email || '',
  };

  const onSubmit = async (values: TenantSetupValidationSchema, helpers: FormikHelpers<TenantSetupValidationSchema>) => {
    if (props.onSubmit) {
      props.onSubmit(values, helpers);
      return;
    }

    let updatedTenant = undefined;
    let updatedUser = undefined;

    const tenantDetails: ITenant = {
      Name: values.companyName,
      FirstName: values.firstName,
      LastName: values.lastName,
      AgreedTsAndCs: values.agreeTnCs,
      Phone: values.companyPhoneNumber,
    } as ITenant;

    if ((payStatementByLinkV289938 && !isWebsiteId17) || !payStatementByLinkV289938) {
      tenantDetails.Email = values.email;
    }

    const userDetails: IUserAccountInfo = {
      ...user,
      FirstName: values.firstName,
      LastName: values.lastName,
    } as IUserAccountInfo;

    const p1 = saveTenantDetails(tenantDetails);
    const p2 = saveUser(userDetails);

    try {
      const res = await Promise.allSettled([p1, p2]);
      res
        .filter(r => r.status === 'rejected')
        .map(r => {
          Toast.info((r as PromiseRejectedResult).reason || '');
        });

      const tenantResponse = res[0].status === 'fulfilled' ? res[0].value : undefined;
      if (tenantResponse) {
        updatedTenant = {
          ...props.tenant,
          Name: tenantDetails.Name,
          Email: tenantDetails.Email,
          AgreedTsAndCs: tenantDetails.AgreedTsAndCs,
          FirstName: tenantDetails.FirstName,
          LastName: tenantDetails.LastName,
        };
      }

      const userResponse = res[1].status === 'fulfilled' ? res[1].value : undefined;
      if (userResponse) {
        updatedUser = {
          ...props.user,
          TenantName: tenantDetails.Name,
          FirstName: userDetails.FirstName,
          LastName: userDetails.LastName,
        };

        // Update the users array in the tenant object
        const tenantUsers = updatedTenant?.Users;
        if (tenantUsers?.length === 1) {
          tenantUsers[0] = {
            ...tenantUsers[0],
            FirstName: userDetails.FirstName,
            LastName: userDetails.LastName,
          };
        }
        updatedTenant = {
          ...updatedTenant,
          Users: tenantUsers,
        };
      }

      // Update User and Tenant objects in the global context
      if (updatedTenant && updatedUser) {
        setUserTenant({
          tenant: updatedTenant as ITenant,
          user: updatedUser as IUser,
        });
      }
    } catch (error) {
      console.error(error);
      Toast.error('An error occurred while saving your details.');
    } finally {
      if (props.onAfterSubmit) {
        props.onAfterSubmit();
      }
    }
  };
  return <TenantSetupForm key={'tenantSetup'} onSubmit={onSubmit} initialValues={initialValues} />;
};

interface ITenantSetupFormProps {
  onSubmit: (values: TenantSetupValidationSchema, helpers: FormikHelpers<TenantSetupValidationSchema>) => void;
  initialValues: TenantSetupValidationSchema;
}

export const TenantSetupForm = (props: ITenantSetupFormProps) => {
  const {logo: Logo} = useContext(ThemeContext);

  const characterLimitExceeded = 'Character limit exceeded';

  const validationSchema = Yup.object({
    firstName: Yup.string().required('First name is required.').max(50, characterLimitExceeded),
    lastName: Yup.string().required('Last name is required.').max(50, characterLimitExceeded),
    companyName: Yup.string().required('Company name is required.').max(100, characterLimitExceeded),
    companyPhoneNumber: Yup.string().min(10, 'Invalid Phone no.').max(11, 'Invalid Phone no.'),
    email: isWebsiteId17
      ? Yup.string().email('Email is not valid.').optional()
      : Yup.string().email('Email is not valid.').required('Email address is required.'),
    agreeTnCs: Yup.boolean().defined().oneOf([true], 'You must agree to the Terms and Conditions.'),
  });

  return (
    <div className={`h-full bg-white`}>
      <div
        className={`spenda-color flex h-full max-w-full items-center justify-center bg-[rgba(227,238,245,40%)] font-poppins text-2xl`}
      >
        <div
          className={`modal-box flex h-[550px] max-w-[1112px] items-center justify-center py-16 text-center shadow-[0px_0px_6px_0px_rgba(211,229,239,1)]`}
        >
          <div className="flex h-full w-[45%] items-center justify-center border-[rgba(204,204,204,40%)] border-r-default px-8">
            {Logo ? <Logo /> : <SpendaNoTagLogo width="298px" height="134px" />}
          </div>
          <div className="relative mx-16 h-full w-[55%] text-left text-spenda-primarytext">
            <Formik initialValues={props.initialValues} validationSchema={validationSchema} onSubmit={props.onSubmit}>
              {({values, dirty, touched, errors, handleChange, isSubmitting, handleBlur, isValid}) => (
                <>
                  <h2 className="mb-8 pt-1 text-2xl font-light">Tell us about yourself</h2>
                  <Form>
                    <div className="mb-6 flex gap-x-4 text-left">
                      <Input
                        id="firstName"
                        name="firstName"
                        label={'First name'}
                        value={values.firstName}
                        helperText={(touched.firstName && errors.firstName) || ''}
                        error={touched.firstName && !!errors.firstName}
                        onChange={e => handleChange(e)}
                        onBlur={handleBlur}
                        data-autoid="txtFirstName"
                      />
                      <Input
                        id="lastName"
                        name="lastName"
                        label={'Last name'}
                        value={values.lastName}
                        helperText={(touched.lastName && errors.lastName) || ''}
                        error={touched.lastName && !!errors.firstName}
                        onChange={e => handleChange(e)}
                        onBlur={handleBlur}
                        data-autoid="txtLastName"
                      />
                    </div>
                    <div className="mb-6 flex gap-x-4 text-left">
                      <Input
                        id="companyName"
                        name="companyName"
                        label={'Company display name'}
                        value={values.companyName}
                        helperText={(touched.companyName && errors.companyName) || ''}
                        error={touched.companyName && !!errors.companyName}
                        onChange={e => handleChange(e)}
                        onBlur={handleBlur}
                        data-autoid="txtCompanyDisplayName"
                      />
                      <Input
                        id="companyPhoneNumber"
                        name="companyPhoneNumber"
                        label={'Company phone number'}
                        value={values.companyPhoneNumber}
                        helperText={(touched.companyPhoneNumber && errors.companyPhoneNumber) || ''}
                        error={touched.companyPhoneNumber && !!errors.companyPhoneNumber}
                        onChange={e => handleChange(e)}
                        onBlur={handleBlur}
                        data-autoid="txtCompanyPhoneNumber"
                      />
                    </div>
                    {!isWebsiteId17 && (
                      <div className="mb-3 flex text-left">
                        <Input
                          id="email"
                          name="email"
                          label={'Email'}
                          value={values.email}
                          onChange={e => handleChange(e)}
                          disabled={true}
                          data-autoid="txtEmail"
                        />
                      </div>
                    )}
                    <div>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="agreeTnCs"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
                            checked={!!values.agreeTnCs}
                            ripple={false}
                            className="h-4 w-4 rounded-[4px] border-primary bg-white transition-all checked:border-primary checked:bg-primary hover:before:opacity-0"
                            data-autoid="chqAgreeT&C"
                          />
                        }
                        label={
                          <span
                            className={`font-poppins text-sm font-medium leading-7 text-primary ${
                              errors.agreeTnCs && touched.agreeTnCs ? 'text-red-600' : ''
                            }`}
                          >
                            <a
                              data-autoid="lnkTermsAndConditions"
                              className={`text-black hover:underline`}
                              href="https://spenda.co/terms-conditions/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              I accept the Spenda terms and conditions
                            </a>
                          </span>
                        }
                      />
                    </div>
                    <div
                      className={`absolute bottom-0 left-0 flex w-full items-center justify-end rounded-[6px] bg-[rgba(173,173,173,20%)] p-2`}
                    >
                      <Button type="submit" loading={isSubmitting} disabled={isSubmitting || !isValid || !dirty}>
                        Continue
                      </Button>
                    </div>
                  </Form>
                </>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};
