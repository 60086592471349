import React, {PropsWithChildren, useReducer} from 'react';
import {
  PaymentBatchStatusValues,
  PaymentBatchPermissionType,
  TimelineCustomRange,
} from '../../model/constants/Constants';
import {IUserDefaultViewSettings} from '../../model/payment-batch/PaymentBatch';
import {usePaymentBatchAPI} from '../../services/usePaymentBatchAPI';
import APContext, {IAPContextState, paymentBatchStatusEnumValues} from './APContext';
import APContextActions from './APContextActions';
import APContextReducer from './APContextReducer';
export const APContextProvider: React.FC<PropsWithChildren> = ({children}) => {
  const initialState: IAPContextState = {
    defaultTimelineGroupsFilter: TimelineCustomRange.SevenToTwentyEightDays,
    defaultSearchStatusFilters: Object.values(PaymentBatchStatusValues).filter(
      v => !isNaN(Number(v))
    ) as paymentBatchStatusEnumValues[],
    userRoleBatchPermissions: [],
  };

  const [state, dispatch] = useReducer(APContextReducer, initialState);

  const {getUserRolePermissions, getUserRoleDefaultViewSettings} = usePaymentBatchAPI();

  const setUserRoleBatchPermissions = (permissions: PaymentBatchPermissionType[]) => {
    dispatch({
      type: APContextActions.SET_USER_ROLE_BATCH_PERMISSIONS,
      payload: permissions,
    });
  };

  const setUserDefaultViewSettings = (settings: IUserDefaultViewSettings) => {
    if (settings) {
      dispatch({
        type: APContextActions.SET_USER_ROLE_DEFAULT_VIEW_SETTINGS,
        payload: settings,
      });
    }
  };

  const getUserRoleBatchPermissions = async () => {
    const response = await getUserRolePermissions();
    return response;
  };

  const getUserDefaultViewSettings = async () => {
    const response = await getUserRoleDefaultViewSettings();
    return response;
  };

  return (
    <APContext.Provider
      value={{
        defaultSearchStatusFilters: state.defaultSearchStatusFilters,
        defaultTimelineGroupsFilter: state.defaultTimelineGroupsFilter,
        userRoleBatchPermissions: state.userRoleBatchPermissions,
        getUserDefaultViewSettings,
        setUserDefaultViewSettings,
        setUserRoleBatchPermissions,
        getUserRoleBatchPermissions,
      }}
    >
      {children}
    </APContext.Provider>
  );
};
