import React, {FunctionComponent, useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {css} from 'glamor';
import moment from 'moment';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {AUTH_PURCHASING_PAY_BILL_PREVIEW} from '../../routes/AccountsPayableRoutes';
import {IBatchReviewPurchaseInvoice, IPurchaseInvoice} from '../../model/purchase-invoice/purchaseInvoice';
import {STextField} from '../inputs/STextField';
import {SButton} from '../buttons/Button';
import {PriceFormat} from '../../utils/formatter';
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import {makeStyles} from '@material-ui/core';
import {APInvoiceDetailView} from '../../screens/purchasing/APInvoiceDetailView';
import {IAPInvoiceId} from '../../screens/purchasing/APInvoicesSingleViewList';
import {PaymentBatchStatusTypes} from '../../model/constants/Constants';
import {DraftBatch} from './DraftBatch';
import draftSwapIcon from '../../assets/svg/accounts-payable/draft-swap-icon.svg';
import {usePaymentBatchAPI} from '../../services/usePaymentBatchAPI';
import useSpendaPaymentServicesAPI from '../../services/useSpendaPaymentServicesAPI';
import {DiscardBatchModal} from '../accountsPayableOnboarding/DiscardBatchModal';
import {Toast} from '../../utils/Toast';
import {AUTH_PURCHASING_PAY_BILL_ROUTE} from '../../routes/AccountsPayableRoutes';
import disableExclamation from '../../assets/svg/accounts-payable/red-exclamation.svg';
import blueInfoIcon from '../../assets/svg/accounts-payable/blue-info-icon.svg';
import APTooltip from '../data-display/APTootip';
import deleteIcon from '../../assets/svg/accounts-payable/delete-icon.svg';
import {Calendar} from '../../assets/svg/accounts-payable/Calendar';
import {IDebitNote} from '../../model/payment-batch/PaymentBatch';

export type CreateBatchValues = {
  batchName: string;
  paymentDate: Date | string;
  invoices: IPurchaseInvoice[];
  debitNotes: IDebitNote[];
};
export interface IBatchCreate {
  totalPayable: number;
  handleInvoices: (invoicesId: number, supplierId: number) => void;
  handleDebitNote: (debitNoteId: number, supplierId: number) => void;
  handleCancelBatchCreation: () => void;
  batchID?: string;
  batchDetails: CreateBatchValues;
  resetSelectedInvoices: () => void;
  setBatchDetails?: any;
  isSavingBatch?: boolean;
  setIsSavingBatch?: React.Dispatch<React.SetStateAction<boolean>>;
  approvedInvoices: IBatchReviewPurchaseInvoice[];
  removeAlreadyApprovedInvoice: (invoice: IBatchReviewPurchaseInvoice) => void;
  showDraftList: boolean;
  setShowDraftList: React.Dispatch<React.SetStateAction<boolean>>;
  t2TV3: boolean;
}

const validationSchema = Yup.object({
  batchName: Yup.string().required('Batch Name is required.').max(50, 'Batch Name max 50 char.'),
  paymentDate: Yup.string().required('Payment Date is required.'),
});

const panelRightBody = css({
  borderRadius: '6px',
  '& .draft-swapping': {
    '& icon': {
      width: '20px',
    },
  },
});

const dueDateStyle = css({
  color: '#1C78AD',
  borderRadius: '4px',
  backgroundColor: '#D3E5EF50',
  fontSize: '10px',
  padding: '4px 10px',
  fontWeight: 500,
  marginTop: '3px',
});

const overDueDateStyle = css({
  color: '#C55D44',
  borderRadius: '4px',
  backgroundColor: '#F8E1DC',
  fontSize: '10px',
  padding: '4px 10px',
  fontWeight: 500,
  marginTop: '3px',
});

const useBatchCreateStyles = makeStyles({
  payInvoiceText: {
    width: '214px',
    height: '40px',
    '& .MuiFormControl-root': {
      background: '#fff',
      paddingBottom: '0px',
      height: '100%',
      '& .MuiOutlinedInput-root': {
        height: '100%',
        backgroundColor: 'transparent',
        borderRadius: '6px',
      },
      '& .MuiFormLabel-root': {
        color: '#ccc',
        fontFamily: 'Poppins, sans-serif !important',
        width: 'auto',
        fontWeight: 500,
        fontSize: '13px',
        top: '-7px',
        left: '-5px',
      },
      '& .MuiInputLabel-shrink': {
        color: '#333',
        top: '0px',
        left: 0,
        paddingRight: '2px',
        marginLeft: '-4px',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#333',
        '& legend': {
          height: '5px',
        },
      },
      '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#333!important',
      },
      '& .MuiFormLabel-root.Mui-focused': {
        color: '#333',
      },
      '& .MuiInputBase-input': {
        fontSize: '14px',
        fontWeight: 600,
        padding: '0px 8px',
        fontFamily: 'Poppins !important',
        color: '#333',
      },
      '& .MuiIconButton-root': {
        color: '#1C78AD',
        padding: '0px 10px',
        marginTop: '-2px',
      },
      '& .MuiInputBase-adornedEnd': {
        paddingRight: '0px',
      },
      '& .MuiOutlinedInput-notchedOutline legend': {
        fontSize: '9px!important',
      },
    },
  },
  pickerDialog: {
    '& .MuiTypography-root': {
      fontFamily: 'poppins',
    },
    '& .MuiPickersToolbar-toolbar': {
      backgroundColor: '#1C78AD',
    },
    '& .MuiPickersCalendarHeader-dayLabel.MuiPickersCalendarHeader-dayLabel': {
      color: '#333',
    },
    '& .MuiPickersCalendar-week': {
      '& .MuiPickersDay-day': {
        color: '#333',
      },
      '& .MuiPickersDay-dayDisabled': {
        color: '#33333350',
      },
      '& .MuiPickersDay-daySelected': {
        backgroundColor: '#1C78AD',
        color: '#fff',
      },
    },
  },
  batchTableHeight: {
    height: 'calc(100vh - 350px)',
    paddingBottom: '60px',
  },
  actionBtns: {
    fontWeight: 600,
  },
  footer: {},
  boxHeight: {
    height: '164px',
    '& p': {
      fontFamily: 'Poppins, sans-serif !important',
      color: '#333',
    },
  },
  draftChanges: {
    top: '8px',
    left: '8px',
    height: 'calc(100% - 16px)',
    width: 'calc(100% - 16px)',
    background: 'rgba(173, 173, 173, 0.2)',
    backdropFilter: 'blur(10px)',
    borderRadius: '8px',
  },
  modalBox: {
    maxWidth: '384px',
    width: '100%',
    margin: '0 auto',
    height: '280px',
    position: 'relative',
    borderRadius: '10px',
    paddingBottom: '10px',
    '& h2': {
      height: '60px',
      borderBottom: '1px solid #D8D8D8',
      fontSize: '22px',
    },
    '& p': {
      color: '#707070',
    },
    '& .footer': {
      bottom: '10px',
    },
  },
  invoiceDisable: {
    '& .flex-col': {
      opacity: '30%',
    },
  },
});

export const BatchCreate: FunctionComponent<IBatchCreate> = props => {
  const classes = useBatchCreateStyles();
  const history = useHistory();
  const {
    totalPayable,
    approvedInvoices,
    handleInvoices,
    batchID,
    batchDetails,
    resetSelectedInvoices,
    setBatchDetails,
    isSavingBatch,
    setIsSavingBatch,
    removeAlreadyApprovedInvoice,
    handleDebitNote,
    showDraftList,
    setShowDraftList,
    t2TV3,
  } = props;
  const [batchToEdit, setBatchToEdit] = useState<CreateBatchValues>(batchDetails);
  const [showInvoice, setShowInvoice] = useState<boolean>(false);
  const [invoiceId, setInvoiceId] = useState<IAPInvoiceId>();
  const [addNewBatch, setAddNewBatch] = useState<boolean>(false);
  const {updatePaymentBatch, isLoading: batchLoader, cancelPaymentBatch} = usePaymentBatchAPI();
  const {isLoading: paymentLoader} = useSpendaPaymentServicesAPI();
  const isLoading = paymentLoader || batchLoader;
  const [discardHandle, setDiscardHandle] = useState<boolean>(false);

  useEffect(() => {
    setBatchToEdit(batchDetails);
  }, [batchDetails]);

  const handleDeleteClick = (invoice: IPurchaseInvoice) => {
    if (!isSavingBatch) {
      setIsSavingBatch && setIsSavingBatch(true);
      handleInvoices(invoice.TransID, invoice.SupplierID);
    }
  };

  const handleDeleteDebitNote = (debitNote: IDebitNote) => {
    if (!isSavingBatch) {
      setIsSavingBatch && setIsSavingBatch(true);
      handleDebitNote(debitNote.debitNoteID, debitNote.supplierID);
    }
  };

  const handleRemoveInvoice = (invoice: IBatchReviewPurchaseInvoice) => {
    if (!isSavingBatch) {
      setIsSavingBatch && setIsSavingBatch(true);
      removeAlreadyApprovedInvoice(invoice);
    }
  };

  const onSubmit = async (values: CreateBatchValues): Promise<void> => {
    if (batchID) {
      let response = await updatePaymentBatch(batchID, {
        id: batchID,
        name: values.batchName,
        dueDate: moment(values.paymentDate).format('YYYY-MM-DDTHH:mm:ss'),
      });
      if (response?.id) history.push(AUTH_PURCHASING_PAY_BILL_PREVIEW.replace(/:batchID/g, batchID));
      return Promise.resolve();
    }
  };

  const cancelBatch = async (description: string) => {
    if (batchID) {
      await cancelPaymentBatch(batchID, description);
      Toast.info('Payment Batch discarded!');
      history.push(AUTH_PURCHASING_PAY_BILL_ROUTE);
      setDiscardHandle(false);
      resetSelectedInvoices();
    }
  };

  const discardHandleChanged = () => {
    setDiscardHandle(!discardHandle);
  };

  const setdiscardHandleChanged = () => {
    setDiscardHandle(false);
  };

  const draftHandleClick = () => {
    resetSelectedInvoices();
    setShowDraftList(true);
  };

  const newBatchHandleChanged = async (values: CreateBatchValues) => {
    setAddNewBatch(!addNewBatch);
    if (batchID) {
      await updatePaymentBatch(batchID, {
        id: batchID,
        name: values.batchName,
        dueDate: moment(values.paymentDate).format('YYYY-MM-DDTHH:mm:ss'),
      });
    }
  };

  const setNewBatchHandleChanged = () => {
    setAddNewBatch(false);
    resetSelectedInvoices();
  };

  return (
    <>
      {discardHandle && (
        <DiscardBatchModal
          isLoading={isLoading}
          handleClose={setdiscardHandleChanged}
          handleDiscardClick={cancelBatch}
        />
      )}
      {showInvoice && (
        <APInvoiceDetailView open={showInvoice} onClose={() => setShowInvoice(false)} apInvoiceId={invoiceId} />
      )}
      <div className={`${panelRightBody} relative flex h-full w-full items-center justify-center bg-white`}>
        {showDraftList ? (
          <DraftBatch setShowDraftList={setShowDraftList} t2TV3={t2TV3} />
        ) : (
          <Formik
            enableReinitialize
            validationSchema={validationSchema}
            initialValues={batchToEdit}
            onSubmit={onSubmit}
          >
            {({values, handleSubmit, setFieldValue, isSubmitting, errors, touched, isValid}) => (
              <div className="h-full w-full font-poppins">
                <div className={`heading flex items-center justify-between px-3 pb-3 pt-4`}>
                  <div className="text-xl text-spenda-primarytext">Create batch({values.invoices.length})</div>
                  <div
                    className="draft-swapping flex cursor-pointer items-center justify-between text-base font-medium"
                    {...css({color: '#1C78AD'})}
                    onClick={draftHandleClick}
                    data-autoid="lnkdraft"
                  >
                    <img src={draftSwapIcon} alt="draft batch icon" className="mr-2" data-autoid="imgDraft" />
                    <span>Drafts</span>
                  </div>
                </div>
                <div
                  className={`flex items-center justify-between px-3 pb-5 pt-1 ${css({
                    borderBottom: '1px solid #D8D8D8',
                  })}`}
                >
                  <div className="text-base font-medium" {...css({color: '#707070'})}>
                    Batch total :
                  </div>
                  <div className="text-base font-semibold" {...css({color: '#3C9F78'})}>
                    <span>{totalPayable && PriceFormat(Number(totalPayable?.toFixed(2)))}</span>
                  </div>
                </div>
                <div className="mb-5 mt-6 flex justify-between px-3">
                  <div className={`${classes.payInvoiceText} pr-2`}>
                    <STextField
                      fullWidth
                      id="batchName"
                      name="batchName"
                      variant="outlined"
                      label="Batch name"
                      value={values.batchName}
                      onChange={(e: React.ChangeEvent<{value: unknown}>) => {
                        setFieldValue('batchName', e.target.value);
                        setBatchToEdit({...batchToEdit, batchName: e.target.value as string});
                        setBatchDetails({...batchDetails, batchName: e.target.value as string});
                      }}
                      helperText={touched?.batchName && errors?.batchName}
                      error={touched?.batchName && Boolean(errors?.batchName)}
                      InputProps={{style: {fontFamily: 'Poppins'}}}
                      inputProps={{
                        'data-autoid': `txtBatchName`,
                      }}
                    />
                  </div>
                  <div className={`${classes.payInvoiceText} pl-2`}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        autoOk
                        minDate={new Date()}
                        minDateMessage="Overdue date should be greater than today."
                        variant="inline"
                        id="paymentDate"
                        name="paymentDate"
                        inputVariant="outlined"
                        label="Payment date"
                        format="dd/MM/yyyy"
                        value={values.paymentDate}
                        InputAdornmentProps={{position: 'end'}}
                        onChange={date => {
                          setFieldValue('paymentDate', date);
                          setBatchToEdit({...batchToEdit, paymentDate: date?.toString() || ''});
                          setBatchDetails({...batchDetails, paymentDate: date});
                        }}
                        helperText={touched?.paymentDate && errors?.paymentDate}
                        error={touched?.paymentDate && Boolean(errors?.paymentDate)}
                        PopoverProps={{className: classes.pickerDialog}}
                        InputProps={{style: {fontFamily: 'Poppins'}}}
                        inputProps={{
                          'data-autoid': `txtPaymentDate`,
                        }}
                        keyboardIcon={<Calendar />}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </div>
                <div className={`mt-2 flex flex-col overflow-y-auto overflow-x-hidden ${classes.batchTableHeight}`}>
                  {approvedInvoices &&
                    approvedInvoices.map((invoice: IBatchReviewPurchaseInvoice, index: number) => (
                      <div
                        className={`flex pb-4 pl-3 pr-1 pt-5 ${css({borderBottom: '1px solid #D8D8D8'})} ${
                          classes.invoiceDisable
                        }`}
                        key={index}
                      >
                        <div className="flex w-7/12 flex-col">
                          <span className="text-md mb-1 font-medium text-spenda-primarytext">
                            {invoice.SupplierName}
                          </span>
                          <span className="text-xs text-spenda-primarytext">{`Purchase Invoice: ${invoice.refNumber}`}</span>
                          <span className="my-1 text-xs text-spenda-primarytext">{`Outstanding: ${PriceFormat(
                            invoice.balance,
                          )}`}</span>
                        </div>
                        <div className="flex w-5/12 flex-col items-center justify-center">
                          <span className="text-md font-semibold text-spenda-primarytext">
                            {PriceFormat(invoice.balance)}
                          </span>
                          <span
                            className={`text-xs ${
                              moment(invoice.dueDate).isBefore(moment()) ? overDueDateStyle : dueDateStyle
                            }`}
                          >{`Due on - ${moment(invoice.dueDate).format('Do MMM YYYY')}`}</span>
                        </div>
                        <div className="flex items-center justify-end pr-2" style={{minWidth: '62px'}}>
                          <APTooltip
                            arrow
                            placement="bottom-end"
                            title={
                              <React.Fragment>
                                <p className="w-[230px] text-center font-poppins font-medium text-black-800">
                                  This invoice has already got approved in other batch. Please delete to continue
                                </p>
                              </React.Fragment>
                            }
                          >
                            <img
                              className="ml-2 cursor-pointer"
                              src={disableExclamation}
                              data-autoid={`imgDisableExclamation`}
                            />
                          </APTooltip>
                          <div className="ml-3">
                            <img
                              src={deleteIcon}
                              className="cursor-pointer"
                              onClick={() => handleRemoveInvoice(invoice)}
                              data-autoid={`lnkHandleDelete`}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  {values.invoices.map((invoice: IPurchaseInvoice, index) => {
                    const totalCommittedAmount = invoice?.linkedBatches
                      ?.filter(
                        ({status}) =>
                          status === PaymentBatchStatusTypes.PaymentApproved ||
                          status === PaymentBatchStatusTypes.InProgress ||
                          status === PaymentBatchStatusTypes.ProcessingDebit ||
                          status === PaymentBatchStatusTypes.DebitSuccessful ||
                          status === PaymentBatchStatusTypes.ProcessingCredit ||
                          status === PaymentBatchStatusTypes.Incomplete,
                      )
                      .reduce((sum, {appliedAmount}) => sum + appliedAmount, 0);
                    const totalPaidAmount = invoice?.linkedBatches
                      ?.filter(({status}) => status === PaymentBatchStatusTypes.Completed)
                      .reduce((sum, {appliedAmount}) => sum + appliedAmount, 0);
                    return (
                      <div
                        className={`flex pb-4 pl-3 pr-1 pt-5 ${css({borderBottom: '1px solid #D8D8D8'})}`}
                        key={index}
                      >
                        <div
                          className="flex w-7/12 flex-col"
                          style={{cursor: 'pointer'}}
                          onClick={() => {
                            setInvoiceId({
                              transId: invoice.TransID,
                              supplierId: invoice.SupplierID,
                            });
                            setShowInvoice(true);
                          }}
                          data-autoid={`lnk${invoice.SupplierName}`}
                        >
                          <span className="text-md mb-1 font-medium text-spenda-primarytext">
                            {invoice.SupplierName?.trim().length > 1
                              ? invoice.SupplierName
                              : invoice.SourceSupplierName}
                          </span>
                          <span className="text-xs text-spenda-primarytext">{`Purchase Invoice: ${invoice.RefNumber}`}</span>
                          <span className="my-1 text-xs text-spenda-primarytext">{`Outstanding: ${PriceFormat(
                            invoice.Balance,
                          )}`}</span>
                        </div>
                        <div className="flex w-5/12 flex-col items-center justify-center">
                          <span className="text-md font-semibold text-spenda-primarytext">
                            {invoice.appliedAmount && PriceFormat(invoice?.appliedAmount)}
                          </span>
                          <span
                            className={`text-xs ${
                              moment(invoice.DueDate).isBefore(moment()) ? overDueDateStyle : dueDateStyle
                            }`}
                          >{`Due on - ${moment(invoice.DueDate).format('Do MMM YYYY')}`}</span>
                        </div>
                        <div className="flex items-center justify-end pr-2" style={{minWidth: '62px'}}>
                          {invoice.appliedAmount && invoice.appliedAmount > invoice.Balance ? (
                            <APTooltip
                              arrow
                              placement="bottom-end"
                              title={
                                <React.Fragment>
                                  <p className="w-[230px] text-center font-poppins font-medium text-black-800">
                                    Applied amount cannot be greater than Outstanding amount. Please update in next
                                    screen.
                                  </p>
                                </React.Fragment>
                              }
                            >
                              <img
                                className="ml-2 cursor-pointer"
                                src={disableExclamation}
                                data-autoid={`imgDisableExclamation`}
                              />
                            </APTooltip>
                          ) : invoice?.linkedBatches && invoice?.linkedBatches.length ? (
                            <APTooltip
                              arrow
                              placement="bottom-end"
                              title={
                                <React.Fragment>
                                  <ul className="mb-3 w-[230px]">
                                    <li className="flex items-center justify-between pb-1">
                                      <p className="font-poppins">Invoice total:</p>
                                      <p className="font-poppins font-semibold text-black-800">
                                        {PriceFormat(invoice.TotalInc)}
                                      </p>
                                    </li>
                                    <li className="flex items-center justify-between pb-1">
                                      <p className="font-poppins">Paid amount:</p>
                                      <p className="font-poppins font-semibold text-black-800">
                                        {PriceFormat(totalPaidAmount)}
                                      </p>
                                    </li>
                                    <li className="flex items-center justify-between pb-1">
                                      <p className="font-poppins">Applied amount:</p>
                                      <p className="font-poppins font-semibold text-black-800">
                                        {PriceFormat(totalCommittedAmount)}
                                      </p>
                                    </li>
                                    <li className="flex items-center justify-between pb-1">
                                      <p className="font-poppins">Outstanding amount:</p>
                                      <p className="font-poppins font-semibold text-black-800">
                                        {PriceFormat(invoice.remnantBalance)}
                                      </p>
                                    </li>
                                  </ul>
                                  <table>
                                    <thead>
                                      <tr>
                                        <th></th>
                                        <th align="left" style={{width: '80%'}} className="pb-1 font-medium">
                                          Batch name
                                        </th>
                                        <th align="left" className="pb-1 font-medium">
                                          Amount
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {invoice?.linkedBatches.map((batch, index) => {
                                        return (
                                          <tr key={index}>
                                            <th className="pb-1 font-medium">{index + 1}. </th>
                                            <td className="pb-1 font-semibold text-black-800">{batch.name}</td>
                                            <td className="pb-1 pr-0 text-right font-semibold text-black-800">
                                              ${batch.appliedAmount}
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </React.Fragment>
                              }
                            >
                              <img className="ml-2 cursor-pointer" src={blueInfoIcon} data-autoid={`imgBlueInfo`} />
                            </APTooltip>
                          ) : null}

                          <div className="ml-3">
                            <img
                              src={deleteIcon}
                              className="cursor-pointer"
                              onClick={() => handleDeleteClick(invoice)}
                              data-autoid={`lnkHandleDelete`}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  {values.debitNotes.map((debitNote: IDebitNote, index) => {
                    return (
                      <div
                        className={`flex pb-4 pl-3 pr-1 pt-5 ${css({borderBottom: '1px solid #D8D8D8'})}`}
                        key={index}
                      >
                        <div
                          className="flex w-7/12 flex-col"
                          style={{cursor: 'pointer'}}
                          data-autoid={`lnk${debitNote.supplierName}`}
                        >
                          <span className="text-md mb-1 font-medium text-spenda-primarytext">
                            {debitNote.supplierName}
                          </span>
                          <span className="text-xs text-spenda-primarytext">{`DebitNote: ${debitNote.refNumber}`}</span>
                          <span className="my-1 text-xs text-spenda-primarytext">{`Outstanding: ${PriceFormat(
                            debitNote.balance,
                          )}`}</span>
                        </div>
                        <div className="flex w-5/12 flex-col items-center justify-center">
                          <span className="text-md font-semibold text-spenda-primarytext">
                            {debitNote.balance && PriceFormat(debitNote.balance)}
                          </span>
                          <span
                            className={`text-xs ${
                              moment(debitNote.dueDate).isBefore(moment()) ? overDueDateStyle : dueDateStyle
                            }`}
                          >{`Due on - ${moment(debitNote.dueDate).format('Do MMM YYYY')}`}</span>
                        </div>
                        <div className="flex items-center justify-end pr-2" style={{minWidth: '62px'}}>
                          <div className="ml-3">
                            <img
                              src={deleteIcon}
                              className="cursor-pointer"
                              onClick={() => handleDeleteDebitNote(debitNote)}
                              data-autoid={`lnkHandleDelete`}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className={`absolute bottom-0 w-full px-3 ${classes.footer}`}>
                  <div
                    className={`flex w-full flex-row justify-between p-2 ${css({
                      backgroundColor: '#f2f2f2',
                      borderRadius: '8px',
                    })}`}
                  >
                    <SButton
                      borderRadius="6px"
                      margin="0 0.25rem"
                      backgroundColor={'white'}
                      border={isLoading || !batchID ? '1px solid #c55d444d' : '1px solid #C55D44'}
                      textColor={isLoading || !batchID ? '#c55d444d' : '#C55D44'}
                      lineHeight="l"
                      width="100px"
                      height="40px"
                      type="button"
                      boxShadow="none"
                      fontFamily="poppins"
                      fontSize="14px"
                      className={classes.actionBtns}
                      disabled={isLoading || !batchID}
                      onClick={discardHandleChanged}
                      data-autoid={`btnDiscard`}
                    >
                      Discard
                    </SButton>
                    <div className="flex items-center">
                      <SButton
                        borderRadius="6px"
                        margin="0 7px 0px 0px"
                        color="white"
                        border="1px solid #1C78AD"
                        textColor="blueShade"
                        lineHeight="l"
                        width="120px"
                        height="40px"
                        type="button"
                        boxShadow="none"
                        fontFamily="poppins"
                        fontSize="14px"
                        className={classes.actionBtns}
                        onClick={() => newBatchHandleChanged(values)}
                        data-autoid={`btnSave&Close`}
                      >
                        Save &amp; Close
                      </SButton>
                      <SButton
                        borderRadius="6px"
                        margin="0"
                        backgroundColor={
                          isSubmitting || !isValid || !batchID || approvedInvoices.length > 0
                            ? 'rgba(28,120,173,.3)'
                            : 'rgb(28,120,173)'
                        }
                        textColor="white"
                        lineHeight="l"
                        width="100px"
                        height="40px"
                        type="button"
                        boxShadow="none"
                        fontFamily="poppins"
                        fontSize="14px"
                        className={classes.actionBtns}
                        onClick={handleSubmit}
                        isSubmitting={isSubmitting}
                        disabled={isSubmitting || !isValid || !batchID || approvedInvoices.length > 0}
                        data-autoid={`btnNext`}
                      >
                        Next
                      </SButton>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Formik>
        )}
      </div>

      {addNewBatch && (
        <div className={`absolute z-50 flex h-full items-center justify-center ${classes.draftChanges}`}>
          <div className={`flex h-full flex-col items-center justify-start bg-white ${classes.modalBox}`}>
            <h2 className={`flex w-full items-center justify-center text-center font-poppins font-light`}>
              Changes saved
            </h2>
            <div className={`${classes.boxHeight} relative flex items-center justify-center px-8 py-8 text-center`}>
              <div>
                <p className="text-xs">Your changes have been saved, you can find this batch in the drafts section.</p>
              </div>
            </div>
            <div className={`footer absolute bottom-0 w-full px-3`}>
              <div
                className={`flex w-full flex-row justify-between p-2 text-center ${css({
                  backgroundColor: '#f2f2f2',
                  borderRadius: '8px',
                })}`}
              >
                <SButton
                  borderRadius="6px"
                  margin="0 auto"
                  color="blueShade"
                  textColor="white"
                  lineHeight="l"
                  width="78px"
                  height="40px"
                  type="button"
                  boxShadow="none"
                  fontFamily="poppins"
                  fontSize="14px"
                  data-autoid="btnDone"
                  onClick={setNewBatchHandleChanged}
                >
                  Done
                </SButton>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
