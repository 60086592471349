import React from 'react';
import {Close} from '@material-ui/icons';
import clsx from 'clsx';
import {Dialog, DialogProps} from 'spenda-ui-react';

export interface IARDialogTemplateProps {
  header?: any;
  body?: any;
  footer?: any;
  handleClose?: () => void;
  isFullScreen?: boolean;
  containerClass?: string;
  headerClass?: string;
  bodyClass?: string;
  footerClass?: string;
  dialogContentClass?: string;
  dialogProps?: Omit<DialogProps, 'ref' | 'handler' | 'children'>;
}

export const ARDialogTemplate = (props: IARDialogTemplateProps) => {
  const {
    header,
    body,
    footer,
    isFullScreen,
    dialogProps,
    containerClass,
    headerClass,
    bodyClass,
    dialogContentClass,
    footerClass,
    handleClose,
  } = props;

  const dialogContent = (
    <div className={clsx(`m-auto h-auto w-full rounded-[10px] bg-white py-2.5`, `${dialogContentClass || ''}`)}>
      {header && (
        <div
          className={clsx(
            'relative flex h-[50px] w-full flex-row items-center justify-center border-b border-[#D8D8D8] px-2.5',
            `${headerClass || ''}`,
          )}
        >
          {header}
          {handleClose && (
            <div
              data-autoid="btnClose"
              onClick={handleClose}
              className="absolute right-[0px] top-[0px] z-10 mx-2.5 flex h-[40px] w-[40px] cursor-pointer flex-row items-center justify-center rounded-[6px] border-[1px] border-primary"
            >
              <Close className="!h-[22px] !w-[22px] text-primary" />
            </div>
          )}
        </div>
      )}
      <div className={clsx('mx-2.5 w-[auto] px-2.5 py-2.5', `${bodyClass || ''}`)}>{body}</div>
      {footer && (
        <div
          className={clsx(
            `mx-2.5 flex h-[52px] items-center justify-center rounded-[6px] bg-[#EFEFEF] px-2.5`,
            `${footerClass || ''}`,
          )}
        >
          {footer}
        </div>
      )}
    </div>
  );

  if (isFullScreen) {
    return (
      <Dialog {...dialogProps} open={dialogProps?.open || false} handler={() => {}}>
        {dialogContent}
      </Dialog>
    );
  }
  return (
    <div
      className={clsx(
        `absolute bottom-0 left-0 right-0 top-0 z-30 m-0 flex h-full w-full flex-col items-center justify-center`,
        'rounded-[2px] bg-[#ADADAD20] drop-shadow backdrop-blur-md',
        `${containerClass || ''}`,
      )}
    >
      {dialogContent}
    </div>
  );
};
