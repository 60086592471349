import React, {useState} from 'react';
import clsx from 'clsx';
import moment from 'moment';

import {ARSelectToggler} from '../../../components/AccountsReceivable/ARSelectToggler';
import {ARAgeReceivableDateRange, ARStatementPeriodDefaultOptions} from '../../../model/constants/Constants';

import {ARAgeReceivableDateRangeDropDown} from '../customer-group-view/CustomerGroupTableHeader';
import {AdvanceFilter} from '../../../components/AccountsReceivable/AdvanceFilter';
import IconFilter from '../../../assets/svg/IconFilter';
import {ICustomerStatementFilterQuery} from '../../../services/useCustomerStatementsAPI';
import {Select, Option, Typography, IconButton} from 'spenda-ui-react';
import {ARSearchBar} from '../../../components/AccountsReceivable/ARSearchBar';

/** Enums*/
export enum ArCustomerFocusedViewTypes {
  STANDARD_VIEW = 'Standard view',
  AGED_RECEIVABLES = 'Aged Receivables',
}

interface ITableTopHeaderProps {
  handleSelectAll: () => void;
  isSelectedAll: boolean;
  statementOptions: {name: string | null; value: string}[] | undefined;
  selectedStatementPeriod: string | ARStatementPeriodDefaultOptions;
  onViewChange: (selectedOption: ArCustomerFocusedViewTypes) => void;
  selectedView: ArCustomerFocusedViewTypes;
  selectedAgeDateRange: ARAgeReceivableDateRange;
  onChangeAgeDateRange: (selectedAgeDateRange: ARAgeReceivableDateRange) => void;
  setTransactionFilterQuery: React.Dispatch<React.SetStateAction<ICustomerStatementFilterQuery | undefined>>;
  transactionFilterQuery: ICustomerStatementFilterQuery | undefined;
  selectAllCount?: number;
  setSelectedStatementPeriod: (statementId: string | ARStatementPeriodDefaultOptions) => void;
}

export const CustomerFocusTableHeader = (props: ITableTopHeaderProps) => {
  const {
    handleSelectAll,
    isSelectedAll,
    statementOptions,
    selectedStatementPeriod,
    onViewChange,
    selectedView,
    selectedAgeDateRange,
    onChangeAgeDateRange,
    transactionFilterQuery,
    setTransactionFilterQuery,
    selectAllCount,
    setSelectedStatementPeriod,
  } = props;

  const onStatementPeriodChange = (value: string | undefined) => {
    if (value) {
      setSelectedStatementPeriod(value);
      try {
        let date = undefined;
        if (value === ARStatementPeriodDefaultOptions.END_OF_LAST_MONTH)
          date = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');

        const parseStatementId = parseInt(value) || undefined;
        setTransactionFilterQuery({...transactionFilterQuery, asAtDate: date, editingStatementID: parseStatementId});
      } catch {}
    }
  };

  const [isAdvanceFilter, setIsAdvanceFilter] = useState<boolean>(false);

  return (
    <div>
      <div className={`mx-2.5 mb-[24px] flex h-[70px] items-center justify-between border-b border-[#D8D8D8] py-2.5`}>
        <div className="flex flex-row items-center gap-2.5">
          <span
            data-autoid={`chkSelectAllTransactionsCustomerFocused`}
            className={`select-symbol !m-0 items-center font-poppins text-base font-normal`}
            onClick={() => handleSelectAll()}
          >
            <div
              id="ARCustomerFocusSelectAllCheckBox"
              className={clsx({'selectAll-true': isSelectedAll}, {'selectAll-false': !isSelectedAll})}
            >
              <ARSelectToggler isSelected={isSelectedAll} />
            </div>
            <Typography variant="h2" className="cursor-pointer font-medium text-primary">
              Select All{selectAllCount ? `(${selectAllCount})` : ''}
            </Typography>
          </span>
          <span />
          <div className="min-w-[360px]">
            <ARSearchBar
              type="advance"
              searchCategories={[
                {value: 'RefNumber', label: 'Ref Number'},
                {value: 'VendorName', label: 'Vendor Name'},
                {value: 'Amount', label: 'Amount'},
              ]}
              searchString={transactionFilterQuery?.searchString || ''}
              searchCategory={transactionFilterQuery?.searchField || 'RefNumber'}
              isSearching={false}
              onSubmit={({searchString, searchCategory}) => {
                setTransactionFilterQuery(oldValues => ({
                  ...oldValues,
                  searchString: searchString,
                  searchField: searchString ? searchCategory : undefined,
                }));
              }}
              dataAutoIdSuffix={'CustomerFocusTableHeader'}
            />
          </div>
        </div>
        {selectedView === ArCustomerFocusedViewTypes.STANDARD_VIEW && isAdvanceFilter && (
          <AdvanceFilter
            onSubmit={filteredValues => {
              setSelectedStatementPeriod(ARStatementPeriodDefaultOptions.ALL);
              setTransactionFilterQuery({
                ...filteredValues,
                asAtDate:
                  filteredValues?.asAtDate === moment().format('DD/MM/YYYY') ? filteredValues?.asAtDate : undefined,
                editingStatementID: undefined,
              });
            }}
            transactionFilterQuery={transactionFilterQuery}
            open={isAdvanceFilter}
            handleClose={() => setIsAdvanceFilter(false)}
          />
        )}
        <div className="flex flex-row items-center">
          {selectedView === ArCustomerFocusedViewTypes.STANDARD_VIEW && (
            <IconButton variant="outlined" data-autoid="btnFilterTableHeader" onClick={() => setIsAdvanceFilter(true)}>
              <IconFilter />
            </IconButton>
          )}

          {selectedView === ArCustomerFocusedViewTypes.STANDARD_VIEW && statementOptions?.length && (
            <div className="ml-2.5 flex !min-w-[185px] flex-col py-1">
              <Select
                size="md"
                variant="outlined"
                data-autoid={`ddlSelectBatchPeriodCustomerFocused`}
                label="Filter"
                value={selectedStatementPeriod}
                onChange={e => onStatementPeriodChange(e)}
                containerProps={{className: '!min-w-[185px]'}}
              >
                {statementOptions?.map(option => (
                  <Option
                    data-autoid={`lblSelectBatchPeriodOptions${option.value}`}
                    key={option.value}
                    value={option.value}
                  >
                    {option.name}
                  </Option>
                ))}
              </Select>
            </div>
          )}
          {selectedView === ArCustomerFocusedViewTypes.AGED_RECEIVABLES && (
            <ARAgeReceivableDateRangeDropDown
              selectedAgeDateRange={selectedAgeDateRange}
              onChangeAgeDateRange={onChangeAgeDateRange}
            />
          )}
          <div className="ml-2.5 flex !min-w-[185px] flex-col py-1">
            <Select
              size="md"
              variant="outlined"
              label="View"
              data-autoid={`ddlSelectViewTypeCustomerFocused`}
              value={selectedView}
              onChange={value => value && onViewChange(value as ArCustomerFocusedViewTypes)}
              containerProps={{className: '!min-w-[185px]'}}
            >
              {(Object.keys(ArCustomerFocusedViewTypes) as Array<keyof typeof ArCustomerFocusedViewTypes>).map(
                (key, index) => {
                  return (
                    <Option
                      data-autoid={`lblSelectViewTypeCustomerFocusedOptions${ArCustomerFocusedViewTypes[key]}`}
                      key={index}
                      value={ArCustomerFocusedViewTypes[key]}
                    >
                      {ArCustomerFocusedViewTypes[key]}
                    </Option>
                  );
                },
              )}
            </Select>
          </div>
        </div>
      </div>
    </div>
  );
};
