import React, {useEffect, useState} from 'react';
import {ILocation} from '../../model/address/Location';
import {AddOrderLocationForm} from '../form/AddOrderLocationForm';
import {Button, Dialog, DialogBody, DialogFooter, DialogHeader, IconButton} from 'spenda-ui-react';
import {CloseCross} from '../../assets/svg/CloseCross';

export const AddressDialog = ({
  setOpen,
  open,
  addressIndex,
  locationIndex,
  isEdit,
  handleDefaultBilling,
  locations,
  setLocations,
  setShowLoader,
  setAddressNumber,
  setLocationNumber,
  addressType,
  reloadSaveLocations,
  setActiveBox,
  isServiceAsset,
  onCopyShippingAddress,
  closeDialog,
  collapseAsset,
  setCollapseAsset,
  dialogTitle,
  placeholder,
  label,
  ...props
}: any) => {
  const [valuesObject, setValuesObject] = useState<ILocation[] | undefined>();

  useEffect(() => {
    setValuesObject(locations);
  }, [locations]);

  const handleDialogClose = () => {
    if (isEdit === false) {
      if (locations[locationIndex].Addresses.length > 1) {
        delete locations[locationIndex].Addresses[addressIndex];
      } else {
        delete locations[locationIndex];
      }
    }
    reloadSaveLocations?.();
    setOpen(false);
    if (isServiceAsset) {
      closeDialog();
    }
  };

  const handleSubmitForm = async () => {
    if (!isServiceAsset) {
      setShowLoader(true);
    }
    setOpen(false);
    const _location = [...locations];
    await props?.handleUpdateOpCustomer(_location);
    setActiveBox?.({locNumber: locationIndex, addNumber: addressIndex});
    if (!isServiceAsset) {
      setShowLoader(false);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        handler={() => setOpen(false)}
        className="!mx-5 h-[500px] !w-full !max-w-[96%] xl:!max-w-[950px]"
      >
        <DialogHeader className="w-full border-b border-[#EAEAEA] p-2.5">
          <div className="flex w-full items-center justify-between ">
            <div className={`flex w-full justify-between`}>
              <span> </span>
              <h2 className={`w-full text-center text-xl font-light lg:text-2xl`}>{dialogTitle}</h2>
              {!isServiceAsset && (
                <div className="flex justify-end text-right">
                  <IconButton variant="outlined" onClick={handleDialogClose} data-autoid="btnClose">
                    <CloseCross />
                  </IconButton>
                </div>
              )}
            </div>
          </div>
        </DialogHeader>
        <DialogBody>
          <AddOrderLocationForm
            {...props}
            addressIndex={addressIndex}
            locationIndex={locationIndex}
            handleDefaultBilling={handleDefaultBilling}
            salesOrder={true}
            valuesObject={valuesObject}
            setValuesObject={setValuesObject}
            setLocations={setLocations}
            setAddressNumber={setAddressNumber}
            setLocationNumber={setLocationNumber}
            addressType={addressType}
            isServiceAsset={isServiceAsset}
            onCopyShippingAddress={onCopyShippingAddress}
            collapseAsset={collapseAsset}
            setCollapseAsset={setCollapseAsset}
            placeholder={placeholder}
            label={label}
          />
        </DialogBody>
        <DialogFooter className="absolute bottom-0 w-full p-2.5">
          {
            <div
              className="flex w-full flex-row justify-between p-2"
              style={{backgroundColor: '#ADADAD50', borderRadius: '8px'}}
            >
              <Button
                variant="outlined"
                className="bg-white"
                type="button"
                data-autoid="btnCancelAddAddressDialog"
                onClick={() => handleDialogClose()}
              >
                Cancel
              </Button>
              <Button
                data-autoid="btnSaveAddress"
                loading={props.isSubmitting}
                type="submit"
                onClick={() => handleSubmitForm()}
                disabled={
                  props.isSubmitting ||
                  !valuesObject?.[locationIndex]?.Addresses?.[addressIndex]?.PostCode ||
                  !valuesObject?.[locationIndex]?.Addresses?.[addressIndex]?.Country
                }
              >
                {isServiceAsset ? 'Save Asset' : 'Done'}
              </Button>
            </div>
          }
        </DialogFooter>
      </Dialog>
    </>
  );
};
