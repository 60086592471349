import React, {FunctionComponent, useContext} from 'react';
import {makeStyles} from '@material-ui/core';
import SelectAPInvoice from '../../assets/svg/accounts-payable/select-ap-invoice.svg';
import APAuthorizerCheckIcon from '../../assets/svg/accounts-payable/APAuthorizerCheckIcon';
import {DualAuth} from '../../assets/svg/accounts-payable/DualAuth';
import {NameInitials} from '../../utils/formatter';
import moment from 'moment';
import {IPaymentBatchForApproval} from '../../model/payment-batch/PaymentBatch';
import {AuthorisationRequestMode} from '../../model/constants/Constants';
import APTooltip from '../data-display/APTootip';
import AppContext from '../../context/app/appContext';

export interface IBatchSupplierSummary {
  selectedBatchId?: number;
  setSelectedBatchId?: (batchId: number) => void;
  batchDetail?: IPaymentBatchForApproval;
}

const useBatchSupplierSummaryStyles = makeStyles({
  batchDetailWrap: {
    '& .batchDetailsRow': {
      '&::before': {
        content: "''",
        position: 'absolute',
        width: '1px',
        height: '10px',
        background: '#D8D8D8',
        opacity: '60%',
        top: '100%',
        left: '20px',
      },
      '&:last-child::before': {
        height: '40px',
      },
    },
    '&:last-child': {
      '& .batchDetailsRow::before': {
        display: 'none',
      },
    },
  },
});

export const BatchSupplierSummary: FunctionComponent<IBatchSupplierSummary> = props => {
  const classes = useBatchSupplierSummaryStyles();
  const {selectedBatchId, batchDetail} = props;
  const {user: loogedInUser} = useContext(AppContext);
  const authorisation = batchDetail?.authorisation ? batchDetail?.authorisation : batchDetail?.authorisationRule;
  const isDualAuthorisation = authorisation?.requiredApprovalCount && authorisation.requiredApprovalCount > 1;
  const awaitingResponse =
    !isDualAuthorisation &&
    authorisation?.authorisers?.find(user => !user.status || user.status === 'AwaitingResponse');
  const hasOneAuthorised = isDualAuthorisation && authorisation?.authorisers?.find(user => user.status === 'Approved');
  const otherUsers = authorisation?.authorisers?.filter(user => user.userID !== loogedInUser?.UserID);
  return (
    <div className={`flex h-full flex-col bg-white`}>
      {!selectedBatchId ? (
        <div className="relative flex h-full w-full items-center justify-center rounded-[8px] bg-white">
          <div className="mx-5 rounded-[10px] bg-[#eff6f9] p-8 pb-10 text-center">
            <p className="font-poppins text-xl font-light text-black-800">Selected batch details will appear here</p>
            <img src={SelectAPInvoice} className="mt-8 w-full" data-autoid="imgSelectAPInvoice" />
          </div>
        </div>
      ) : (
        <div className="font-poppins">
          <div className="flex items-center justify-between bg-[#FAFAFA] py-2.5">
            <div className="font-medium">
              <h3 className="ml-2.5 text-base leading-[14px] text-black-800">Batch - Suppliers summary</h3>
            </div>
            <div className="mr-2.5 text-center">
              <p className="text-[10px] text-black-800">Payment date</p>
              <span className="text-base font-semibold leading-[12px] text-primary">
                {batchDetail?.dueDate && moment.utc(batchDetail?.dueDate).local().format('DD MMM YYYY')}
              </span>
            </div>
          </div>
          <div className="p-2.5">
            {/* Batch Details of Authorizers */}
            <div className={`${classes.batchDetailWrap} mb-[40px]`}>
              <h4 className="mb-2.5 flex items-center justify-start text-xs font-semibold text-spenda-headingtext">
                Batch authorisers{' '}
                {isDualAuthorisation && <DualAuth className="mb-[3px] ml-[3px]" data-autoid="imgDualAuth" />}
              </h4>
              {!isDualAuthorisation ? (
                <div
                  className={`batchDetailsRow relative mb-2.5 flex items-center justify-start text-base font-semibold text-spenda-headingtext`}
                >
                  <span className="mr-2.5 flex h-[40px] w-[40px] items-center justify-center rounded-full bg-[rgba(132,118,213,20%)] font-normal text-[#8178CF]">
                    {awaitingResponse && NameInitials(awaitingResponse?.firstName + ' ' + awaitingResponse?.lastName)}
                  </span>
                  {awaitingResponse && awaitingResponse?.firstName + ' ' + awaitingResponse?.lastName}
                </div>
              ) : isDualAuthorisation && authorisation?.requestMode === AuthorisationRequestMode.SEQUENTIAL ? (
                authorisation?.authorisers?.map((user, index) => {
                  return (
                    <div
                      key={index}
                      className={`batchDetailsRow relative flex items-center justify-between ${
                        index === 0 && 'mb-2.5'
                      }`}
                    >
                      <div
                        className={`text-base font-semibold ${
                          user?.status === 'Approved' ? 'text-[#3C9F78]' : 'text-spenda-headingtext'
                        } flex items-center justify-start`}
                      >
                        {user.status === 'Approved' ? (
                          <span className="mr-2.5 flex h-[40px] w-[40px] items-center justify-center rounded-full bg-[rgba(61,159,120,20%)]">
                            <APAuthorizerCheckIcon data-autoid="imgAuthorizeIcon" />
                          </span>
                        ) : (
                          <span className="mr-2.5 flex h-[40px] w-[40px] items-center justify-center rounded-full bg-[rgba(132,118,213,20%)] font-normal text-[#8178CF]">
                            {NameInitials(user?.firstName + ' ' + user?.lastName)}
                          </span>
                        )}
                        {user?.firstName + ' ' + user?.lastName}
                      </div>
                      {user?.status === 'Approved' && (
                        <div className="text-[10px] font-medium text-[#BFBFBF]">{`${moment
                          .utc(user?.responseDateTime_utc)
                          .local()
                          .format('DD/MM/YY • h:mm a')}`}</div>
                      )}
                    </div>
                  );
                })
              ) : isDualAuthorisation && authorisation?.requestMode === AuthorisationRequestMode.PARALLEL ? (
                <>
                  {hasOneAuthorised ? (
                    <div
                      className={`batchDetailsRow relative mb-2.5 flex items-center justify-start text-base font-semibold text-spenda-headingtext`}
                    >
                      <span className="mr-2.5 flex h-[40px] w-[40px] items-center justify-center rounded-full bg-[rgba(132,118,213,20%)] font-normal text-[#8178CF]">
                        {NameInitials(`${loogedInUser?.FirstName + ' ' + loogedInUser?.LastName}`)}
                      </span>
                      {loogedInUser?.FirstName + ' ' + loogedInUser?.LastName}
                    </div>
                  ) : (
                    <div
                      className={`batchDetailsRow relative mb-2.5 flex items-center justify-start text-base font-semibold text-spenda-headingtext`}
                    >
                      <span className="mr-2.5 flex h-[40px] w-[40px] items-center justify-center rounded-full bg-[rgba(132,118,213,20%)] font-normal text-[#8178CF]">
                        {NameInitials('Other Users')}
                      </span>
                      <APTooltip
                        arrow
                        placement="bottom-start"
                        title={
                          <React.Fragment>
                            <div className="w-[150px]">
                              <h4 className="pb-1 text-[10px] font-medium text-spenda-primarytext">Authorisers list</h4>
                              {otherUsers?.map((user, index) => (
                                <p key={index} className="pb-[2px] text-[10px] text-spenda-labeltext">
                                  {`${index + 1}. ${user?.firstName + ' ' + user?.lastName}`}
                                </p>
                              ))}
                            </div>
                          </React.Fragment>
                        }
                      >
                        <button className="underline hover:text-primary" data-autoid="btnOtherUsers">
                          Other Users
                        </button>
                      </APTooltip>
                    </div>
                  )}
                  {hasOneAuthorised ? (
                    <div className={`batchDetailsRow relative flex items-center justify-between `}>
                      <div className="flex items-center justify-start text-base font-semibold text-[#3C9F78]">
                        <span className="mr-2.5 flex h-[40px] w-[40px] items-center justify-center rounded-full bg-[rgba(61,159,120,20%)]">
                          <APAuthorizerCheckIcon data-autoid="imgAuthorization" />
                        </span>
                        {hasOneAuthorised?.firstName + ' ' + hasOneAuthorised?.lastName}
                      </div>
                      <div className="text-[10px] font-medium text-[#BFBFBF]">{`${moment
                        .utc(hasOneAuthorised?.responseDateTime_utc)
                        .local()
                        .format('DD/MM/YY • h:mm a')}`}</div>
                    </div>
                  ) : (
                    <div
                      className={`batchDetailsRow relative mb-2.5 flex items-center justify-start text-base font-semibold text-spenda-headingtext`}
                    >
                      <span className="mr-2.5 flex h-[40px] w-[40px] items-center justify-center rounded-full bg-[rgba(132,118,213,20%)] font-normal text-[#8178CF]">
                        {NameInitials(`${loogedInUser?.FirstName + ' ' + loogedInUser?.LastName}`)}
                      </span>
                      {loogedInUser?.FirstName + ' ' + loogedInUser?.LastName}
                    </div>
                  )}
                </>
              ) : null}
            </div>
            {/* Batch Details by Approver */}
            <div className={`${classes.batchDetailWrap} mb-[40px]`}>
              <h4 className="mb-2.5 text-xs font-semibold text-spenda-headingtext">Batch approved by</h4>
              <div className={`batchDetailsRow relative mb-2.5 flex items-center justify-between `}>
                <div className="flex items-center justify-start text-base font-semibold text-spenda-headingtext">
                  {batchDetail && batchDetail?.batchApprovedByUserName && (
                    <span className="mr-2.5 flex h-[40px] w-[40px] items-center justify-center rounded-full bg-[rgba(197,93,68,20%)] font-normal text-[#C55D44]">
                      {NameInitials(batchDetail?.batchApprovedByUserName)}
                    </span>
                  )}
                  {batchDetail && batchDetail?.batchApprovedByUserName}
                </div>
                {batchDetail?.batchApprovedDateTime_utc && (
                  <div className="text-[10px] font-medium text-[#BFBFBF]">{`${moment
                    .utc(batchDetail?.batchApprovedDateTime_utc)
                    .local()
                    .format('DD/MM/YY • h:mm a')}`}</div>
                )}
              </div>
            </div>

            {/* Batch Details by Creators*/}
            <div className={`${classes.batchDetailWrap} mb-[40px]`}>
              <h4 className="mb-2.5 text-xs font-semibold text-spenda-headingtext">Batch created by</h4>
              <div className={`batchDetailsRow relative mb-2.5 flex items-center justify-between`}>
                <div className="flex items-center justify-start text-base font-semibold text-spenda-headingtext">
                  {batchDetail && batchDetail?.createdByUserName && (
                    <span className="mr-2.5 flex h-[40px] w-[40px] items-center justify-center rounded-full bg-[rgba(198,138,19,20%)] font-normal text-[#C68A19]">
                      {NameInitials(batchDetail?.createdByUserName)}
                    </span>
                  )}
                  {batchDetail && batchDetail?.createdByUserName}
                </div>
                {batchDetail?.createdDateTime_utc && (
                  <div className="text-[10px] font-medium text-[#BFBFBF]">{`${moment
                    .utc(batchDetail?.createdDateTime_utc)
                    .local()
                    .format('DD/MM/YY • h:mm a')}`}</div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
