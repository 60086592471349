import React, {useEffect} from 'react';
import ThemeContext from './ThemeContext';
import environment from '../../utils/environment';
import {capitalize} from 'lodash';
import {PersonalizedContent} from '../../model/theme/Theme';
import {useFeatureFlags} from '../../hooks/useFeatureFlags';
import {isWebsiteId17} from '../../config';

interface IThemeContextProviderProps {
  children: React.ReactNode;
}
export const ThemeContextProvider = (props: IThemeContextProviderProps) => {
  const {THEME: theme} = environment;

  const {lmiCommercialRelease92671: isCommerceReleaseFlag} = useFeatureFlags().supplierDriven();
  const lmiCommercialRelease92671 = isWebsiteId17 && isCommerceReleaseFlag;

  const {rootClassName, title} = lmiCommercialRelease92671 ? {rootClassName: '', title: ''} : theme || {};

  useEffect(() => {
    if (lmiCommercialRelease92671 === undefined) return;

    if (rootClassName) {
      document.body.className = rootClassName;
      document.title = title || capitalize(rootClassName);

      const favicon = document.querySelector('link[rel="icon"]');
      if (favicon) {
        favicon.setAttribute('href', `/favicon-${rootClassName}.ico`);
      }
    }
  }, [rootClassName, lmiCommercialRelease92671]);

  return (
    <ThemeContext.Provider
      value={{
        rootClassName: theme?.rootClassName || '',
        logo: theme?.logo || undefined,
        content: theme?.content || {},
        personalizedContent: theme?.personalizedContent || ({} as PersonalizedContent),
      }}
    >
      {props.children}
    </ThemeContext.Provider>
  );
};
