import React, {PropsWithChildren} from 'react';
import {MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardDatePickerProps} from '@material-ui/pickers';
import {createTheme, makeStyles, TextFieldProps, ThemeProvider} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {STextField} from '../inputs/STextField';
import CalendarIcon from '../../assets/svg/Calendar';
import {omit} from 'lodash';

interface ISDatePicker extends KeyboardDatePickerProps {
  v2?: boolean;
}

const materialTheme = createTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#2896be',
      },
    },
    MuiPickersDay: {
      day: {
        color: '#000',
      },
      daySelected: {
        backgroundColor: '#2896be',
      },
      dayDisabled: {
        color: '#ccc',
      },
    },
    MuiInput: {
      underline: {
        '&:after': {
          borderBottomColor: '#006c91',
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: 0,
      },
    },
    MuiFormLabel: {
      root: {
        transform: 'translate(0, 1.5px)scale(0.75) !important',
        color: '#006c91',
        width: '155px', //add width to form label not to flex/wrap
        '&.Mui-focused': {
          color: '#006c91',
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#B9624B', //red
    },
    secondary: {
      main: '#ca8305', //orange
    },
    text: {
      secondary: '#ffffff',
    },
  },
  typography: {
    fontFamily: 'Saira Condensed',
  },
});

const SDatePicker = (props: ISDatePicker) => {
  if (props.v2) {
    return <DefaultDatePicker {...omit(props, ['v2'])} />;
  }
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <ThemeProvider theme={materialTheme}>
        <KeyboardDatePicker
          {...props}
          autoOk
          variant="inline"
          margin="dense"
          format="dd/MM/yyyy"
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          inputProps={props.inputProps ? props.inputProps : {}}
        />
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
};

export default SDatePicker;

const useDefaultDatePickerStyles = makeStyles({
  positionEnd: {
    '& .MuiIconButton-root': {
      padding: '0px',
    },
  },
  pickerDialog: {
    '& .MuiPickersCalendarHeader-iconButton': {
      backgroundColor: 'hsl(var(--primary))',
      '&:hover': {
        backgroundColor: 'hsl(var(--primary))',
      },
    },
    '& .MuiTypography-root': {
      fontFamily: 'poppins',
    },
    '& .MuiPickersToolbar-toolbar': {
      backgroundColor: 'hsl(var(--primary))',
    },
    '& .MuiPickersCalendarHeader-dayLabel.MuiPickersCalendarHeader-dayLabel': {
      color: '#333',
    },
    '& .MuiPickersCalendar-week': {
      '& .MuiPickersDay-day': {
        color: '#333',
      },
      '& .MuiPickersDay-dayDisabled': {
        color: '#33333350',
      },
      '& .MuiPickersDay-daySelected': {
        backgroundColor: 'hsl(var(--primary))',
        color: '#fff',
      },
    },
  },
});

function DatePickerInput(props: PropsWithChildren<TextFieldProps>) {
  return <STextField {...props} v2={true} />;
}

const DefaultDatePicker = (props: KeyboardDatePickerProps) => {
  const defaultClasses = useDefaultDatePickerStyles();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        {...props}
        TextFieldComponent={DatePickerInput}
        keyboardIcon={<CalendarIcon className="h-[25px] w-[25px] fill-current !text-primary" />}
        PopoverProps={{className: `${defaultClasses.pickerDialog} ${props.PopoverProps?.className || ''}`}}
        InputAdornmentProps={{
          position: 'end',
          classes: {
            positionEnd: defaultClasses.positionEnd,
          },
        }}
        InputLabelProps={Object.assign(props.InputLabelProps || {}, Boolean(props.value) ? {shrink: true} : {})}
      />
    </MuiPickersUtilsProvider>
  );
};
