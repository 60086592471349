import {useEffect, useState} from 'react';
import {ISearchFilter} from '../model/search-filters/SearchFilter';
import useQuotesAPI from './useQuotesAPI';
import {IQuotesList} from '../model/quotes/quotes';
import {QuoteViewMode} from '../model/constants/Constants';

export const useLoadQuotes = () => {
  const {getQuotes} = useQuotesAPI();
  let pageSize = 15;

  const initialFilterState = {
    StartRow: 1,
    MaxResults: pageSize,
    SearchString: '',
    ViewMode: QuoteViewMode.All,
  } as ISearchFilter;

  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<IQuotesList[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [moreToGet, setMoreToGet] = useState<boolean>(true);
  const [error, setError] = useState<any>();
  const [searchFilter, setSearchFilter] = useState<ISearchFilter>(initialFilterState);
  const [countAllQuotes, setCountAllQuotes] = useState<number>(0);
  const [countExpiredOrRejectedQuotes, setCountExpiredOrRejectedQuotes] = useState<number>(0);
  const [countApprovedQuotes, setCountApprovedQuotes] = useState<number>(0);
  const [countOpenQuotes, setCountOpenQuotes] = useState<number>(0);
  const [countSentQuotes, setCountSentQuotes] = useState<number>(0);

  const getQuotesList = async () => {
    setLoading(true);
    try {
      const result = await getQuotes(searchFilter);
      if (result === undefined) return;
      let items = result?.items || [];
      setItems(items);
      setTotalCount(result?.totalRecordCount || 0);
      setCountAllQuotes(result?.countAllQuotes || 0);
      setCountApprovedQuotes(result?.countApprovedQuotes || 0);
      setCountExpiredOrRejectedQuotes(result?.countExpiredOrRejectedQuotes || 0);
      setCountOpenQuotes(result?.countOpenQuotes || 0);
      setCountSentQuotes(result?.countSentQuotes || 0);
      setCurrentPage(Math.ceil((searchFilter.StartRow || 1 + pageSize - 1) / (searchFilter.MaxResults || pageSize)));
      setMoreToGet(result?.moreToGet || false);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getQuotesList();
  }, [searchFilter]);
  return {
    loading,
    items,
    totalCount,
    pageSize: searchFilter.MaxResults || pageSize,
    currentPage,
    moreToGet,
    error,
    searchFilter,
    setSearchFilter,
    countAllQuotes,
    countApprovedQuotes,
    countExpiredOrRejectedQuotes,
    countOpenQuotes,
    countSentQuotes,
  };
};
