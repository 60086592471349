import React, {useEffect, useState} from 'react';
import {Typography} from 'spenda-ui-react';
import {useParams} from 'react-router-dom';

import ServiceHistoryHeader from '../ServiceHistoryHeader';
import CustomerDetailsSection from '../CustomerDetailsSection';
import CustomerInfoList from './CustomerInfoList';
import IconStatusFilter from '../../../../assets/svg/IconStatusFilter';
import AdvanceFilter from '../../../../components/ECommerce/AdvanceFilter';
import {IServiceJob} from '../../../../model/service-management/serviceJob';
import useServiceJobAPI from '../../../../services/useServiceJobAPI';
import {useCustomersAPI} from '../../../../services/useCustomersAPI';
import {ICustomer} from '../../../../model/customer/Customer';
import {ITechnician, ServiceJobStatus} from '../../../../model/service-management/serviceJob';
import {useLoadAsset} from '../../../../services/useCustomersAssetAPI';
import LoadingIndicator from '../../../../components/ui/LoadingIndicator';
import moment from 'moment';

export const initialFilters = {
  assets: [],
  status: [],
  technician: [],
  startDate: null,
  endDate: null,
};
interface ICustomerInfoFilters {
  assets: IFilterOption[];
  status: IFilterOption[];
  technician: IFilterOption[];
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
}
interface IFilterOption {
  id: string | number;
  name: string;
}
interface IFilterSection {
  title: string;
  options: IFilterOption[];
  searchPlaceholder: string;
  searchKey: string;
  filterKey: string;
}

const CustomerInfo = () => {
  const {customerID} = useParams<{customerID: string}>();
  const {getJobsList, isLoading} = useServiceJobAPI();
  const {getCustomer} = useCustomersAPI();

  const {items: assets} = useLoadAsset(+customerID);
  const [isShowFilterDialog, setIsShowFilterDialog] = useState<boolean>(false);
  const [filters, setFilters] = useState<ICustomerInfoFilters>(initialFilters);
  const [customerJobDetails, setCustomerJobDetails] = useState<IServiceJob[]>();
  const [customerDetails, setCustomerDetails] = useState<ICustomer>();

  const fetchJobs = async (id: number) => {
    const results = await Promise.all([
      getJobsList({
        StartRow: 1,
        MaxResults: 1000,
        CustomerID: id,
        ServiceTechnicianIDs: filters.technician.map(tech => parseInt(tech.id.toString())),
        AssetID: filters.assets.map(item => parseInt(item.id.toString())),
        Statuses: filters.status.map(item => item.name.toString()),
        StartDate: filters.startDate ? moment(filters.startDate).format('YYYY-MM-DD') : undefined,
        EndDate: filters.endDate ? moment(filters.endDate).format('YYYY-MM-DD') : undefined,
      }),
      getCustomer(id),
    ]);
    setCustomerJobDetails(results[0].items);
    setCustomerDetails(results[1]);
  };

  useEffect(() => {
    fetchJobs(+customerID);
  }, [customerID, filters]);

  const [technicians, setTechnicians] = useState<ITechnician[]>([]);

  const {getServiceTechnicians} = useServiceJobAPI();

  const fetchFilters = async () => {
    try {
      const results = await getServiceTechnicians();
      setTechnicians(results);
    } catch (error) {
      console.error('Failed to fetch technicians:', error);
    }
  };

  useEffect(() => {
    fetchFilters();
  }, []);

  const handleSaveFilters = (_filters: Record<string, any>, _isFetchList: boolean) => {
    setFilters(_filters as ICustomerInfoFilters);
    setIsShowFilterDialog(false);
  };

  const handleClearAllFilter = () => {
    setIsShowFilterDialog(false);
    setFilters(initialFilters);
  };

  const statusOptions = Object.values(ServiceJobStatus).map(status => ({id: status, name: status}));

  const filterSections: IFilterSection[] = [
    {
      title: 'Assets',
      options: assets.map(asset => ({
        id: asset.assetID || '',
        name: `  ${asset?.businessLocationAddress?.postCode} , ${asset?.businessLocationAddress?.streetAddress}
      `,
      })),
      searchPlaceholder: 'Search assets',
      searchKey: 'asset',
      filterKey: 'assets',
    },
    {
      title: 'Status',
      options: statusOptions,
      searchPlaceholder: 'Search status',
      searchKey: 'status',
      filterKey: 'status',
    },
    {
      title: 'Technician',
      options: technicians.map(technician => ({
        id: technician.userID || '',
        name: `${technician.firstName} ${technician.lastName}`,
      })),
      searchPlaceholder: 'Search technician',
      searchKey: 'technician',
      filterKey: 'technician',
    },
  ];

  return (
    <div className="w-full">
      <ServiceHistoryHeader customerJobDetails={customerJobDetails} fetchJobs={fetchJobs} />
      {isLoading ? (
        <div className={'relative mb-5 h-[calc(100vh-222px)] px-8 py-2'}>
          <LoadingIndicator isLoading={isLoading} size="md" />
        </div>
      ) : (
        <>
          <CustomerDetailsSection customerDetails={customerDetails} />
          <div>
            <div className="flex justify-between ">
              <Typography variant="h2" className="font-light">
                Recent Jobs
              </Typography>
              <div
                className={
                  'flex w-[40px] cursor-pointer items-center rounded-md border !border-spenda-sBlue p-2 pl-2 text-xl font-medium'
                }
                data-autoid="ddlStatus"
                onClick={() => setIsShowFilterDialog(true)}
              >
                <IconStatusFilter data-autoid={`imgStatus`} />
              </div>
              {isShowFilterDialog && (
                <AdvanceFilter
                  showModal={isShowFilterDialog}
                  setOpen={() => setIsShowFilterDialog(!isShowFilterDialog)}
                  onSave={handleSaveFilters}
                  onClear={handleClearAllFilter}
                  filterSections={filterSections}
                  selectedFilters={filters}
                />
              )}
            </div>
            <CustomerInfoList customerJobDetails={customerJobDetails!} fetchJobs={fetchJobs} />
          </div>
        </>
      )}
    </div>
  );
};

export default CustomerInfo;
