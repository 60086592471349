import React, {useContext, useEffect, useState} from 'react';
import usePurchaseInvoiceAPI from '../../services/usePurchaseInvoiceAPI';
import {useParams, RouteProps} from 'react-router-dom';
import {IInvoice} from '../../model/invoice/Invoice';
import {ISupplierWhoRaisedInvoice} from '../../model/supplier/SupplierWhoRaisedInvoice';
import {InvoiceDetail} from '../../components/ui/InvoiceDetailsComponent/InvoiceDetail';
import {IAlertInfo, IAlertRequestRequest} from '../../model/alert/AlertInfo';
import {SButton} from '../../components/buttons/Button';
import {PaymentStatusType} from '../../model/constants/Constants';
import {SavePDFButton} from '../../components/buttons/SavePDFButton';
import {IAPInvoiceId} from './APInvoicesSingleViewList';
import {useInvoiceAPI} from '../../services/useInvoiceAPI';
import {useTransactionViewHook} from '../../hooks/useARHook';
import {useFeatureFlags} from '../../hooks/useFeatureFlags';
import AppContext from '../../context/app/appContext';

interface ITransactionProps extends RouteProps {
  isPayMode?: boolean;
  handleInvoices?: (invoicesId: number, supplierId: number) => void;
  isAPInvoice?: boolean;
  apInvoiceId?: IAPInvoiceId;
  isServiceJobInvoice?: boolean;
}

export const Transaction = (props: ITransactionProps) => {
  let {transactionId, supplierId} = useParams<{transactionId?: string; supplierId?: string}>();
  const {tenantInfo} = useContext(AppContext);
  const purchaseInvoiceAPI = usePurchaseInvoiceAPI();
  const invoiceAPI = useInvoiceAPI();
  const {getSupplierWhoRaisedInvoice} = useTransactionViewHook();
  const {QuoteV289367} = useFeatureFlags().tenantOwned();
  const [supplier, setSupplier] = useState<ISupplierWhoRaisedInvoice>({
    ID: 0,
    Name: '',
    Logos: [],
  });
  const [tx, setTx] = useState<IInvoice | undefined>();
  const [showSummaryTabs, setShowSummaryTabs] = useState<boolean>(false);

  const fetchInvoiceDetail = async () => {
    const invoice: IInvoice = await invoiceAPI.getInvoiceByTransID(props?.apInvoiceId?.transId!);
    const supplier = await getSupplierWhoRaisedInvoice();
    if (invoice.SummaryLines && QuoteV289367) setShowSummaryTabs(true);
    setTx(invoice);
    setSupplier(supplier as ISupplierWhoRaisedInvoice);
  };

  useEffect(() => {
    if (props?.isServiceJobInvoice) {
      fetchInvoiceDetail();
    } else getPurchsaseInvoiceData();
  }, [transactionId, QuoteV289367]);

  const getPurchsaseInvoiceData = async () => {
    if (props?.isAPInvoice && !props?.isServiceJobInvoice) {
      transactionId = props?.apInvoiceId?.transId.toString();
      supplierId = props?.apInvoiceId?.supplierId?.toString();
    }
    const purchaseInvoice = await purchaseInvoiceAPI.getPurchaseInvoice(parseInt(transactionId!));

    if (purchaseInvoice) {
      //  we want some additional data i.e. supplier, payments
      if (purchaseInvoice.SupplierID) {
        const [payments, _supplier] = await Promise.all([
          purchaseInvoiceAPI.GetFailedPaymentsByPurchaseInvoiceTransID(purchaseInvoice.ID),
          purchaseInvoiceAPI.getSupplierById(purchaseInvoice.SupplierID),
        ]);

        purchaseInvoice.Payments = payments.map(payment => ({
          ...payment,
          AppliedDate: payment.DateTime,
        })); // update payments array
        setTx(purchaseInvoice);
        setSupplier({
          ID: _supplier.ID,
          Name: _supplier.CompanyName,
          ABN: _supplier.ABN,
          PrimaryContactEmail: _supplier.PrimaryContactEmailAddress,
          Website: _supplier.WebSiteURL,
          BusinessPhone: _supplier.Phone1,
          Logos: _supplier.Logos,
          // ARContactEmailAddress: _supplier.ARContactEmailAddress
        });
      }
    }
  };

  const onPayNowClick = () => {
    props.handleInvoices && props.handleInvoices(parseInt(transactionId!), parseInt(supplierId!));
  };

  const onOpenPDF = () => {
    if (transactionId && tx?.GUID) {
      const alertReq: Partial<IAlertRequestRequest> = {
        TransID: parseInt(transactionId),
        TransactionGuid: tx && tx.GUID,
      };

      return purchaseInvoiceAPI.getPurchaseInvoicePrintOut(alertReq).then((data: IAlertInfo) => {
        if (data.AlertMsg) {
          const pdf = data.AlertMsg.AttachmentFileNames[0];
          window.open(pdf);
        }
      });
    }

    return Promise.resolve();
  };

  const isPaid =
    (tx?.Balance || 0) <= 0 && tx?.Status.toLocaleLowerCase() === PaymentStatusType.Paid.toLocaleLowerCase();

  const purchaseInvoiceFooter = (
    <div className="flex justify-between">
      <SavePDFButton
        lineHeight="m"
        fontSize="m"
        variant="contained"
        color="blue"
        onClick={onOpenPDF}
        // isSubmitting={isDownloading}
      />
      {!isPaid && (
        <SButton lineHeight="m" fontSize="m" variant="contained" color="green" onClick={onPayNowClick}>
          Pay Now
        </SButton>
      )}
    </div>
  );

  return (
    <InvoiceDetail
      tx={tx}
      isPaid={isPaid}
      supplier={supplier}
      isStickyFooter
      fullWidth
      showBoxShadow={false}
      showAmountFieldOnPaymentSummaryPopover={false}
      showSalesOrderRefNumber={false}
      isAPInvoice={props.isAPInvoice ?? false}
      footer={purchaseInvoiceFooter}
      showSummaryTabs={showSummaryTabs}
      hideQuantity={!tenantInfo?.TenantUserDetails.IsShowQuantityInQuote}
    />
  );
};
