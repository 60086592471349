import {Box, makeStyles} from '@material-ui/core';
import {css} from 'glamor';
import React, {useMemo, useState} from 'react';
import {PaymentProviderName, PaymentWidgetScope} from '../../model/constants/Constants';
import {IPaymentMethodTypes, ITenantSupplierPaymentMethod} from '../../model/payment/PaymentMethod';
import {ManagePaymentMethods} from '../../screens/payment-methods/ManagePaymentMethods';
import useSpendaPaymentServicesAPI, {IAccountResponse} from '../../services/useSpendaPaymentServicesAPI';
import {PennyVerificationDialogStages} from '../dialog/PennyVerificationDialog';
import {PaymentMethodThumbnail} from '../inputs/PaymentMethodThumbnail';
import {SPopover} from '../modals/modalSpendaMeterialUI';
import {PaymentOptionsToAdd} from './PaymentOptionsToAdd';
import clsx from 'clsx';
import {ISupplierMarket} from '../../model/SupplierMarket';
import {PaymentWidgetPaymentSteps} from '../../screens/pay-by-link/payment-widget/PaymentWidget';
import {useFeatureFlags} from '../../hooks/useFeatureFlags';
import {IconAddMedia} from '../../assets/svg/IconAddMedia';
import {Button} from 'spenda-ui-react';

interface ICommonPaymentProps {
  onClick?: () => void;
  paymentMethods?: IAccountResponse[];
  supplierId?: number;
  loadPaymentMethods?: () => void;
  onChangePaymentMethod?: (pm: ITenantSupplierPaymentMethod) => void;
  onEditPaymentMethod?: (
    method: ITenantSupplierPaymentMethod | undefined,
    account: IAccountResponse | undefined,
    isAfterCreatingAccount: boolean,
  ) => Promise<boolean>;
  showApplyCreditNotesBtn?: boolean;
  dialogStage?: PennyVerificationDialogStages;
  setDialogStage?: (newStage: PennyVerificationDialogStages) => void;
  paymentAuth72488?: boolean;
  capricornDemo77857?: boolean;
  airplus79131?: boolean;
  availablePaymentMethodTypes?: IPaymentMethodTypes[];
  isBPSP?: boolean;
  marketplaceSupplier?: ISupplierMarket;
  isAccountsPayable?: boolean;
  scheduledPaymentsV2?: boolean;
  hideAddPaymentMethod?: boolean;
}
const addPaymentText = css({
  color: '#1C78AD',
});

export const CommonPaymentMethods = (props: ICommonPaymentProps) => {
  const useStyles = makeStyles({
    root: {
      '& .MuiPaper-root': {
        backgroundColor: 'transparent !important',
        overflow: 'hidden',
        minHeight: 124,
        paddingTop: '18px',
      },
      '& .MuiPaper-rounded': {
        borderRadius: '10px',
      },
      '& .MuiPaper-elevation8': {
        boxShadow: 'none !important',
      },
    },
  });

  const speechBubbleModal = css({
    position: 'relative',
    width: props?.airplus79131 ? '273px' : '190px',
    height: '92.71px',
    background: '#FFF',
    borderRadius: '10px',
    border: '1px solid #D6D2D2',
    bottom: '0px',
    boxShadow: '0 6px 7px 2px rgb(0 0 0 / 20%)',
    '&:after': {
      content: "''",
      position: 'absolute',
      borderStyle: 'solid',
      borderWidth: '10px 10px 0',
      borderColor: '#FFF transparent',
      display: 'block',
      width: 0,
      zIndex: 1,
      top: 'unset',
      left: '94px',
      bottom: '122px',
      transform: 'rotate(180deg)',
    },
    '&:before': {
      content: "''",
      position: 'absolute',
      borderStyle: 'solid',
      borderWidth: '10px 10px 0',
      borderColor: '#D6D2D2 transparent',
      display: 'block',
      width: 0,
      zIndex: 0,
      top: 'unset',
      left: '94px',
      bottom: '122px',
      transform: 'rotate(180deg)',
    },
  });

  const {
    paymentMethods,
    loadPaymentMethods,
    availablePaymentMethodTypes,
    isBPSP,
    marketplaceSupplier,
    paymentAuth72488,
    airplus79131,
    isAccountsPayable,
  } = props;
  const classes = useStyles();
  const [isLoadingInvitation] = useState<boolean>(false);
  const [openWidget, setOpenWidget] = useState<boolean>(false);
  const [cardToEdit, setCardToEdit] = useState<ITenantSupplierPaymentMethod | undefined>();
  const [bpspAccountDetails, setBpspAccountDetails] = useState<IAccountResponse | undefined>();
  const [widgetScope, setWidgetScope] = useState<PaymentWidgetScope>(PaymentWidgetScope.ADD_CREDIT_CARD);
  const [widgetStep, setWidgetStep] = useState<PaymentWidgetPaymentSteps | undefined>(undefined);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [deletingCardAccountId, setDeletingCardId] = useState<string | undefined>(undefined);
  const {deleteBankAccount, getAccount} = useSpendaPaymentServicesAPI();
  const {onboardingUpgradeV1} = useFeatureFlags().tenantOwned();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);

  const id = open ? 'simple-popover' : undefined;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onEditPaymentMethod = (
    method: ITenantSupplierPaymentMethod | undefined,
    account: IAccountResponse | undefined,
    setAuthRules?: boolean,
  ): Promise<boolean> => {
    const id = method?.PaymentAccountGUID ?? account?.accountGUID ?? method?.SupplierPaymentOptionID;
    let success = false;
    if (id) {
      success = true;
      setWidgetScope(PaymentWidgetScope.CONFIGURE_PAYMENT_OPTIONS);
      setAuthRules && setWidgetStep(PaymentWidgetPaymentSteps.SET_PAYMENT_AUTHORISATION_RULES);
      setCardToEdit(method);
      setOpenWidget(true);
    }
    return Promise.resolve(success);
  };

  const onCheckError = async (method: ITenantSupplierPaymentMethod) => {
    const bpspAccount = await getAccount(PaymentProviderName.Spenda_Payment_Services, method.PaymentAccountGUID!);
    setBpspAccountDetails(bpspAccount);
    setWidgetStep(PaymentWidgetPaymentSteps.VERIFY_CREDIT_CARD);
    setOpenWidget(true);
    setWidgetScope(PaymentWidgetScope.CONFIGURE_PAYMENT_OPTIONS);
  };

  const onRemoveMethod = (method: ITenantSupplierPaymentMethod) => {
    if (method.PaymentAccountGUID) {
      setDeletingCardId(method.PaymentAccountGUID);
      deleteBankAccount(PaymentProviderName.Spenda_Payment_Services, method.PaymentAccountGUID)
        .then(() => {
          loadPaymentMethods && loadPaymentMethods();
        })
        .finally(() => setDeletingCardId(undefined));
    }
  };

  const onPaymentMethodVerify = (method: ITenantSupplierPaymentMethod | undefined): Promise<boolean> => {
    setWidgetScope(PaymentWidgetScope.VERIFY_PAYMENT_METHOD);
    setCardToEdit(method);
    setOpenWidget(true);
    return Promise.resolve(true);
  };

  const handleClearWidgetStates = () => {
    setOpenWidget(value => !value);
    setCardToEdit(undefined);
    setWidgetStep(undefined);
    setBpspAccountDetails(undefined);
  };

  const accountMethods = useMemo(
    () =>
      paymentMethods?.map(method => {
        return {
          CardHolderName: method?.cardDetails?.accountHolderName,
          CardNumber: method?.cardDetails?.last4,
          Expiry: method?.cardDetails?.expiry,
          FriendlyName: method?.friendlyName || '',
          IsInvigoDefault: method?.isInvigoDefault,
          Last4: method?.virtualCard?.last4 ?? method?.cardDetails?.last4,
          PaymentAccountGUID: method?.accountGUID,
          PaymentAccountVerificationStatus: method?.verificationStatus,
          PaymentMethod: method?.paymentMethod || '',
          RetriesLeft: method?.retriesLeft,
          SaveCardForFuture: true,
          SupplierID: 0,
          BankAccountNumber: method?.bankDetails?.bankAccountNumber,
          BankBSB: method?.bankDetails?.bankBSB,
          BankName: method?.bankDetails?.bankName || '',
          AttentionRequiredReason: method?.attentionRequiredReason,
          FacilityID: method?.marketplaceFacility?.facilityID,
          DefaultRepaymentMethodType: method?.marketplaceFacility?.repaymentServiceType,
          DefaultRepaymentMethodLast4: method?.marketplaceFacility?.repaymentLast4,
        };
      }),
    [paymentMethods],
  );

  return (
    <>
      {openWidget && (
        <ManagePaymentMethods
          widgetScope={widgetScope}
          widgetManageMethods73098={true}
          onClose={() => {
            loadPaymentMethods && loadPaymentMethods();
            handleClearWidgetStates();
          }}
          open={openWidget}
          isBPSP={isBPSP ?? true}
          onSuccess={() => {
            handleClearWidgetStates();
            loadPaymentMethods && loadPaymentMethods();
          }}
          paymentMethodToEdit={cardToEdit}
          paymentAuth72488={props.paymentAuth72488}
          capricornDemo77857={props.capricornDemo77857}
          availablePaymentMethodTypes={availablePaymentMethodTypes}
          marketplaceSupplier={marketplaceSupplier}
          widgetStep={widgetStep}
          bpspCardDetails={bpspAccountDetails}
          scheduledPaymentsV2={props.scheduledPaymentsV2}
          onboardingUpgradeV1={onboardingUpgradeV1}
          storedPaymentOptions={accountMethods}
          isAccountsPayable={isAccountsPayable}
        />
      )}
      <SPopover
        id={id}
        anchorEl={anchorEl}
        classes={{root: classes.root}}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 30,
          horizontal: 0,
        }}
      >
        <div className={clsx(`${speechBubbleModal} rounded border-2`, `px-2`)}>
          <PaymentOptionsToAdd
            isAccountsPayable={isAccountsPayable}
            isLoadingInvitation={isLoadingInvitation}
            onClickAddBT={() => {
              setOpenWidget(true);
              setWidgetScope(PaymentWidgetScope.ADD_BANK_ACCOUNT);
              setAnchorEl(null);
            }}
            onClickAddAirPlus={() => {
              setWidgetScope(PaymentWidgetScope.ADD_AIRPLUS);
              setOpenWidget(true);
              setAnchorEl(null);
            }}
            onClickAddCc={() => {
              setWidgetScope(PaymentWidgetScope.ADD_CREDIT_CARD);
              setOpenWidget(true);
              setAnchorEl(null);
            }}
            airplus79131={airplus79131}
          />
        </div>
      </SPopover>
      <div className="flex h-full flex-col">
        <Box className={`h-[inherit]] mb-1 flex  flex-wrap justify-start overflow-auto pl-1`}>
          {!props.hideAddPaymentMethod && (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              marginTop="8px"
              marginBottom="15px"
              className={'mr-5   pt-2'}
            >
              <Button
                className="p-[13px 8px] flex h-[130px] w-[210px] items-center justify-center rounded-[6px] border-0 bg-primary/10"
                variant="outlined"
                onClick={handleClick}
                data-autoid="btnAddAPayment"
              >
                <IconAddMedia data-autoid="imgAddAPayment" />
              </Button>
              <Box
                textAlign="center"
                className={`${addPaymentText} mt-3 font-poppins text-base font-semibold !text-primary`}
              >
                {'Add a payment method'}
              </Box>
            </Box>
          )}
          {accountMethods?.map((method, id) => {
            const isDeletingMethod =
              !!deletingCardAccountId &&
              !!method.PaymentAccountGUID &&
              method.PaymentAccountGUID === deletingCardAccountId;
            return (
              <PaymentMethodThumbnail
                key={`payment-method-${id}`}
                method={method}
                loadPaymentMethods={loadPaymentMethods}
                onChangePaymentMethod={props.onChangePaymentMethod}
                isDeletingMethod={isDeletingMethod}
                hideIsSelectedCheck={!props.onChangePaymentMethod}
                isAccountsPayable={true}
                onPaymentMethodVerify={onPaymentMethodVerify}
                onEditPaymentMethod={onEditPaymentMethod}
                onRemoveMethod={onRemoveMethod}
                newPaymentMethodsStyling={true}
                paymentAuth72488={paymentAuth72488}
                onCheckError={onCheckError}
              />
            );
          })}
        </Box>
      </div>
    </>
  );
};
