import { IActionRes, IActionResults, IPagedActionResults } from '../model/ActionResults';
import { IRequisitionTemplate } from '../model/template/IRequisitionTemplate';
import { SynkSaveQueueResponse } from '../model/SynkSaveQueueResponse';
import useHttp from '../hooks/useHttp';

const useTemplateAPI = () => {

    const { POST, GET, DELETE, isLoading } = useHttp();

    const save = (payload: Partial<IRequisitionTemplate>): Promise<IRequisitionTemplate> => {
        return POST("requisitiontemplate", { Object: payload }).then((data: SynkSaveQueueResponse<IRequisitionTemplate>) => data.Object );
    }
    
    const deleteTemplate = (templateId: number): Promise<boolean> => {
        return DELETE(`requisitiontemplate/${templateId}`).then((data: IActionRes) => data.IsSuccess);
    }
    
    const get = (templateId: number): Promise<IRequisitionTemplate> => {
        return GET(`requisitiontemplate/${templateId}`).then((data: IActionResults<IRequisitionTemplate>) => data && data.Value);
    }
    
    const search = (): Promise<IRequisitionTemplate[]> => {
        return GET("requisitiontemplate", { MaxResults: 50 }).then((data: IPagedActionResults<IRequisitionTemplate[]>) => data.Value);
    }
    return {
        delete: deleteTemplate,
        get,
        save,
        search,
        isLoading
    }
}

export default useTemplateAPI;