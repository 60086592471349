import * as React from 'react';
const ListViewIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={40} height={40} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x={0.5} y={0.5} width={39} height={39} rx={5.5} stroke="#1C78AD" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.833 14.5a.917.917 0 110 1.833H14.5a.917.917 0 110-1.833h7.333zm7.065 16.231a.917.917 0 01-1.296 0l-2.207-2.206a4.086 4.086 0 01-2.187.642 4.125 4.125 0 114.125-4.125 4.085 4.085 0 01-.641 2.187l2.206 2.206a.917.917 0 010 1.296zm-5.69-3.398a2.292 2.292 0 100-4.583 2.292 2.292 0 000 4.583zm-4.125 1.834h-5.5a2.75 2.75 0 01-2.75-2.75V13.583a2.75 2.75 0 012.75-2.75h11c.507 0 .917.41.917.917v7.333a.917.917 0 101.833 0V11.75A2.75 2.75 0 0024.583 9h-11A4.589 4.589 0 009 13.583v12.834A4.589 4.589 0 0013.583 31h5.5a.917.917 0 100-1.833z"
      fill="currentColor"
    />
  </svg>
);
export default ListViewIcon;
