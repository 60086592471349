import {MuiThemeProvider, createTheme, makeStyles, createStyles} from '@material-ui/core/styles';
import React, {ReactElement} from 'react';
import {DropzoneArea} from 'material-ui-dropzone';

type DropzoneInputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  [key: string]: string | undefined;
};

export const FileUploader = (props: {
  'data-autoid'?: string;
  onChange: (files: File[]) => void;
  uploadedFiles: string[];
  isAccountsPayable?: boolean;
  dropzoneText?: string;
  dropzoneIcon?: ReactElement;
  acceptedFiles?: string[];
  isTenantBusinessDetails?: boolean;
}) => {
  const theme = createTheme({
    overrides: {
      MuiDropzoneSnackbar: {
        errorAlert: {
          backgroundColor: '#f29280',
          color: '#FFF',
        },
        successAlert: {
          backgroundColor: '#88c297',
          color: '#FFF',
        },
      },
      MuiDropzoneArea: {
        root: {
          // width: '40%',
          // minHeight: 180,
        },
        active: {
          border: '5px solid green',
          // alignContent: 'right',
          // width: "50%",
          // height: '100px',
        },
        text: {
          color: props.isTenantBusinessDetails ? '#333' : '#000',
          fontSize: props.isTenantBusinessDetails ? 14 : 16,
          fontFamily: props.isTenantBusinessDetails ? 'poppins' : 'Saira Condensed',
          margin: props.isTenantBusinessDetails ? '0px 0px 10px 0px!important' : 'inherits',
          ...(props.isAccountsPayable ? {color: '#333333', fontSize: '28px'} : {}),
        },
      },
      MuiTypography: {
        subtitle1: {
          color: '#000',
          fontFamily: 'Saira Condensed',
        },
      },
    },
  });

  const useStyles = makeStyles(() =>
    createStyles({
      // previewChip: {
      //     minWidth: 160,
      //     maxWidth: 210
      // },
      myDropZone: {
        position: 'relative',
        width: '100%',
        // height: '40%',
        minHeight: '100px',
        backgroundColor: props.isTenantBusinessDetails ? '#e3ecf1' : '#C8C8C8',
        border: props.isTenantBusinessDetails ? '1px solid' : 'dashed',
        borderColor: props.isTenantBusinessDetails ? 'rgb(0, 130, 186)' : 'grey',
        borderRadius: 10,
        cursor: 'pointer',
        boxSizing: 'border-box',
        opacity: props.isTenantBusinessDetails ? '100%' : '60%',
        padding: props.isTenantBusinessDetails ? '20px 0' : 'inherit',
        ...(props.isAccountsPayable ? {padding: '20px 0px', backgroundColor: '#EAEFF2', borderColor: '#1C78AD'} : {}),
      },
    })
  );

  const classes = useStyles();
  return (
    <MuiThemeProvider theme={theme}>
      <DropzoneArea
        inputProps={{'data-autoid': props['data-autoid'] || 'txtUpload'} as DropzoneInputProps}
        Icon={props.dropzoneIcon}
        dropzoneText={props?.dropzoneText || 'Drag and drop a file here or click'}
        initialFiles={props.uploadedFiles}
        onChange={props.onChange}
        acceptedFiles={props.acceptedFiles || ['image/jpeg', 'image/png', 'image/bmp', 'image/gif']}
        filesLimit={1}
        maxFileSize={5000000}
        dropzoneClass={classes.myDropZone}
        showPreviews={true}
        showPreviewsInDropzone={false}
        showFileNames={true}
        previewGridProps={{container: {spacing: 1, direction: 'row', justify: 'center'}}}
        // previewChipProps={{ classes: { root: classes.previewChip } }}
        previewText="Selected files"
      />
    </MuiThemeProvider>
  );
};
