import React, {FunctionComponent, ReactNode, useContext} from 'react';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@material-ui/core';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import {css} from 'glamor';
import ReactLoading from 'react-loading';

import AppContext from '../../context/app/appContext';
import {StyledCustomCheckbox} from '../inputs/SCheckbox';
import DeleteOutlineRoundedIcon from '@material-ui/icons/DeleteOutlineRounded';

export interface IHeaderData {
  title: string;
  field: string;
  align?: 'right' | 'left' | 'center';
  render?: (rowData: any) => string | ReactNode;
}

export interface ISTable {
  columns: IHeaderData[];
  data: any[];
  emptyDataMessage?: string;
  loading?: boolean;
  selection?: boolean;
  selected?: any[];
  setSelected?: (selected: number[]) => void;
  sortable?: boolean;
  stickyHeader?: boolean;
  onRowClick?: ((rowData: object) => void) | undefined;
  onDelete?: ((index: number) => void) | undefined;
  status?: string;
  hideDeleteButton?: boolean;
  footerData?: any;
  handleSelectAll?: () => void;
}

const useSTableStyles = makeStyles(() => ({
  container: {
    overflowX: 'initial',
  },
  table: {
    minWidth: 750,
  },
  tableRow: {
    '&:hover': {
      backgroundColor: 'hsl(var(--primary)/5%)',
    },
  },
  row: {
    maxHeight: '34px',
  },
}));

const StyledTableCell = withStyles(() => ({
  stickyHeader: {
    backgroundColor: 'transparent',
  },
  head: {
    color: '#444343',
    fontSize: '14px',
    fontWeight: 400,
    height: '40px',
    padding: '0px',
    paddingLeft: '16px',
    paddingRight: '16px',
    border: '1px solid #CCCCCC50',
  },
  root: {
    borderBottom: 'none',
  },
  body: {
    color: '#444343',
    fontSize: '14px',
    fontWeight: 400,
    border: '1px solid #CCCCCC50',
    padding: '2px',
    paddingLeft: '16px',
    paddingRight: '16px',
  },
}))(TableCell);

const STableHead: FunctionComponent<Partial<ISTable>> = props => {
  const classes = useSTableStyles();
  const {data, selected, handleSelectAll} = props;
  return (
    <TableHead className="bg-primary/10">
      <TableRow className={classes.row}>
        {handleSelectAll ? (
          <StyledTableCell padding="checkbox">
            <StyledCustomCheckbox
              name={'selectall'}
              checked={
                data?.length ? data?.every(v => selected?.some((_i: any) => _i.InventoryID === v?.InventoryID)) : false
              }
              onClick={e => e.stopPropagation()}
              onChange={handleSelectAll}
            />
          </StyledTableCell>
        ) : null}
        {Array.isArray(props.columns)
          ? props.columns.map((headCell, index) => {
              return (
                <StyledTableCell variant="head" key={index} align={headCell.align ? headCell.align : 'left'}>
                  {headCell.title}
                </StyledTableCell>
              );
            })
          : null}
        {props.onDelete ? <td {...css({':hover': {backgroundColor: 'transparent'}})} className="bg-white" /> : null}
      </TableRow>
    </TableHead>
  );
};

const STableFooter: FunctionComponent<Partial<ISTable>> = props => {
  const classes = useSTableStyles();
  return <div className={classes.row}>{props.footerData}</div>;
};

export const STableCustom: FunctionComponent<ISTable> = props => {
  const classes = useSTableStyles();
  const {tenantInfo} = useContext(AppContext);

  const {
    columns,
    data,
    selection,
    emptyDataMessage,
    loading,
    stickyHeader,
    selected,
    onDelete,
    setSelected,
    onRowClick,
    hideDeleteButton,
    footerData,
    handleSelectAll,
  } = props;

  const renderFieldData = (row: any, head: IHeaderData) => {
    if (typeof head.render === 'function') {
      return head.render(row);
    }
    return row[head.field];
  };

  const handleRowClick = (event: any, rowData: object) => {
    if (event.target.classList.contains('MuiTableCell-root')) {
      if (rowData && typeof onRowClick === 'function') {
        onRowClick(rowData);
      }
    }
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLInputElement>, row: any) => {
    if (Array.isArray(selected) && typeof setSelected === 'function') {
      const selectedSet = [...selected];
      if (event.target.checked) {
        selectedSet.push(row);
      } else {
        const index = selected.findIndex(_sel => _sel.InventoryID === row?.InventoryID);
        if (index > -1) {
          selectedSet.splice(index, 1);
        }
      }
      setSelected(selectedSet);
    }
  };

  const rowClickCSS = typeof onRowClick === 'function' ? css({cursor: 'pointer'}) : {};

  return (
    <TableContainer className={classes.container}>
      <Table stickyHeader={stickyHeader ? true : false} className={classes.table} aria-labelledby="tableTitle">
        <STableHead
          data={data}
          columns={columns}
          selected={selected}
          selection={selection}
          onDelete={onDelete}
          handleSelectAll={handleSelectAll}
        />
        <TableBody>
          {!loading ? (
            data.map((row, index) => (
              <TableRow
                role="checkbox"
                aria-checked={true}
                className={classes.tableRow}
                tabIndex={index}
                key={index}
                onClick={event => handleRowClick(event, row)}
                {...rowClickCSS}
              >
                {selection ? (
                  <StyledTableCell padding="checkbox">
                    <StyledCustomCheckbox
                      name={row.ID + ''}
                      checked={
                        Array.isArray(selected) ? selected.some((a: any) => a?.InventoryID === row?.InventoryID) : false
                      }
                      onClick={e => e.stopPropagation()}
                      onChange={e => handleSelectChange(e, row)}
                    />
                  </StyledTableCell>
                ) : null}
                {columns.map((headCell, index) => {
                  if (index === 0 && selection) {
                    return (
                      <StyledTableCell
                        key={index}
                        component="th"
                        id={`spenda-table-checkbox-${index}`}
                        scope="row"
                        align={headCell.align ? headCell.align : 'left'}
                      >
                        {renderFieldData(row, headCell)}
                      </StyledTableCell>
                    );
                  }
                  return (
                    <StyledTableCell key={index} align={headCell.align ? headCell.align : 'left'}>
                      {renderFieldData(row, headCell)}
                    </StyledTableCell>
                  );
                })}
                {onDelete &&
                !hideDeleteButton &&
                row?.InventoryID !== tenantInfo?.TenantUserDetails?.DefaultShippingLineItemInventoryId &&
                row?.InventoryID !== tenantInfo?.TenantUserDetails?.DefaultDiscountLineItemInventoryId ? (
                  <td style={{textAlign: 'center'}}>
                    <DeleteOutlineRoundedIcon
                      onClick={() => onDelete(row.InventoryID)}
                      data-autoid={`btnDelete${row.InventoryID}`}
                      fontSize="small"
                      className="pointer-cursor m-auto text-spenda-error"
                    />
                  </td>
                ) : null}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <StyledTableCell colSpan={selection ? columns.length + 1 : columns.length} align="center">
                <ReactLoading className="m-auto" width="32px" height="32px" type="spin" color="#3398A7" />
              </StyledTableCell>
            </TableRow>
          )}
          {data.length === 0 && !loading && (
            <TableRow>
              <StyledTableCell colSpan={selection ? columns.length + 1 : columns.length} align="center">
                {emptyDataMessage || 'No Records Found'}
              </StyledTableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <STableFooter footerData={footerData} />
    </TableContainer>
  );
};
