import React, {useState, PropsWithChildren, useEffect, useMemo} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import moment from 'moment';
import {omit} from 'lodash';
import clsx from 'clsx';

import {PurchaseInvoiceStatusTypes, PurchaseInvoiceViewMode, StorageKeys} from '../../model/constants/Constants';
import {Layout} from '../../components/layout/Layout';
import {STable} from '../../components/tables/STable';
import {SPagination} from '../../components/tables/SPagination';
import {PurchaseInvoiceToolbar} from '../../components/purchase-invoice/PurchaseInvoiceToolbar';
import {useLoadPurchaseInvoice} from '../../services/usePurchaseInvoiceAPI';
import useStorage from '../../hooks/useStorage';
import {PriceFormat} from '../../utils/formatter';

import {Box, makeStyles} from '@material-ui/core';
import {CreatePurchaseInvoiceDialog} from '../../components/purchase-invoice/CreatePurchaseInvoice';
import {AUTH_PURCHASING_PURCHASE_INVOICE_DETAIL} from '../../routes/PurchasingRoutes';

const usePurchaseInvoiceList = makeStyles(() => ({
  root: {
    maxHeight: 'calc( 100vh - 12em )',
    boxShadow: '0 0 6px 0 #D3E5EF',
  },
  title: {
    fontWeight: 500,
    fontSize: '1.25em',
    color: '#4D4D4D',
  },
  toolbar: {
    width: '100%',
    minHeight: '70px',
    padding: '9px 12px',
    backgroundColor: '#ffffff',
    marginBottom: '10px',
    borderRadius: '5px',
    display: 'grid',
    gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
    columnGap: '12px',
    boxShadow: '0 0 6px 0 #D3E5EF',
  },
}));

const statusRenderer = (rowData: any, viewMode: PurchaseInvoiceViewMode) => {
  let style = '';
  let status = '';
  if (viewMode === PurchaseInvoiceViewMode.All) {
    switch (rowData.Status) {
      case PurchaseInvoiceStatusTypes.Unfinalised:
        style = 'bg-[#1C78AD20] text-primary';
        status = 'Unfinalised';
        break;
      case PurchaseInvoiceStatusTypes.Overdue:
        style = 'bg-[#C68A1920] text-[#C68A19]';
        status = 'Overdue';
        break;
      case PurchaseInvoiceStatusTypes.Finalised:
        style = 'bg-[#1C78AD20] text-[#9D5CB3]';
        status = 'Finalised';
        break;
      case PurchaseInvoiceStatusTypes.Paid:
        style = 'bg-[#8178CF20] text-[#8178CF]';
        status = 'Paid';
        break;
      case PurchaseInvoiceStatusTypes.Complete:
        style = 'bg-[#8178CF20] text-[#8178CF]';
        status = 'Posted';
        break;
      case PurchaseInvoiceStatusTypes.Cancelled:
        style = 'bg-[#C55D4420] text-[#C55D44]';
        status = 'Cancelled';
        break;
      default:
        break;
    }
  } else {
    switch (viewMode) {
      case PurchaseInvoiceViewMode.RequiresAttention:
        style = 'bg-[#C55D4420] text-[#C55D44]';
        status = 'Require Attention';
        break;
      case PurchaseInvoiceViewMode.ReadyToPost:
        style = 'bg-[#1C78AD20] text-primary';
        status = 'Ready to Post';
        break;
      case PurchaseInvoiceViewMode.Posted:
        style = 'bg-[#8178CF20] text-[#8178CF]';
        status = 'Posted';
        break;
      default:
        break;
    }
  }
  return (
    <span
      className={clsx(
        'mx-auto block max-w-[130px] whitespace-nowrap rounded px-6 py-1 font-poppins text-[10px] font-medium',
        style,
      )}
    >
      {status}
    </span>
  );
};

const columnSetting = (viewMode: PurchaseInvoiceViewMode) =>
  ({
    [PurchaseInvoiceViewMode.All]: {
      InvoiceRef: {
        title: 'Invoice ref',
        field: 'InvoiceRef',
        render: (rowData: any) =>
          viewMode === PurchaseInvoiceViewMode.All
            ? `${rowData.RefNumber}`
            : `${rowData.TransactionType} ${rowData.RefNumber}`,
      },
      Supplier: {
        title: 'Supplier',
        field: 'SupplierName',
        render: (rowData: any) =>
          viewMode === PurchaseInvoiceViewMode.All ? rowData.OtherPartyName : rowData.SupplierName,
      },
      TransDateString: {
        title: 'Created date',
        field: 'TransDateString',
        align: 'center',
        render: (rowData: any) =>
          viewMode === PurchaseInvoiceViewMode.All
            ? moment(rowData.TransDate).format('Do MMM YYYY')
            : moment(rowData.TransactionDate).format('Do MMM YYYY'),
      },
      CreatedBy: {title: 'Created by', field: 'CreatedByUserName', align: 'center'},
      TotalLines: {
        title: 'Total lines',
        field: 'NumLines',
        align: 'center',
        render: (rowData: any) => (viewMode === PurchaseInvoiceViewMode.All ? rowData.LineCount : rowData.NumLines),
      },
      TotalEx: {
        title: 'Est. cost (ex)',
        field: 'TotalEx',
        align: 'right',
        render: (rowData: any) => PriceFormat(rowData.TotalEx),
      },
      Status: {
        title: 'Order Status',
        field: 'Status',
        align: 'center',
        width: '235px',
        render: (rowData: any) => statusRenderer(rowData, viewMode),
      },
    },
    aliases: {
      [PurchaseInvoiceViewMode.RequiresAttention]: PurchaseInvoiceViewMode.All,
      [PurchaseInvoiceViewMode.ReadyToPost]: PurchaseInvoiceViewMode.All,
      [PurchaseInvoiceViewMode.Posted]: PurchaseInvoiceViewMode.All,
    },
  }) as {[key: string]: any};

export const PurchaseInvoiceList = () => {
  const location = useLocation();
  const history = useHistory();
  const classes = usePurchaseInvoiceList();
  const {getItem, setItem} = useStorage();

  const [viewMode, setViewMode] = useState<PurchaseInvoiceViewMode>(PurchaseInvoiceViewMode.All);
  const [createDialog, setCreateDialog] = useState<boolean>(false);

  const [selectedColumns, setSelectedColumns] = useState<string[]>([
    'InvoiceRef',
    'Supplier',
    'TransDateString',
    'CreatedBy',
    'AssignedTo',
    'TotalLines',
    'TotalEx',
    'Status',
  ]);

  const {
    count,
    loading,
    totalCount,
    pageSize,
    currentPage,
    items: salesOrderList,
    searchFilter,
    setSearchFilter,
  } = useLoadPurchaseInvoice();

  const columns = useMemo(
    () => columnSetting(viewMode)[viewMode] || columnSetting(viewMode)[columnSetting(viewMode).aliases?.[viewMode]],
    [viewMode],
  );

  useEffect(() => {
    if (location?.state && (location.state as any)?.ViewMode) {
      setSearchFilter({...searchFilter, ViewMode: (location?.state as any)?.ViewMode || PurchaseInvoiceViewMode.All});
    }
    getItem(StorageKeys.PurchaseInvoicingColumnSetting).then((res: {[x: string]: string[] | undefined}) => {
      if (res && res[viewMode]) {
        setSelectedColumns(res[viewMode] || []);
      }
    });
  }, []);

  useEffect(() => {
    getItem(StorageKeys.SalesColumnSetting).then((res: {[x: string]: string[] | undefined}) => {
      if (res && res[viewMode]) {
        setSelectedColumns(res[viewMode] || []);
      } else {
        setSelectedColumns(Object.keys(columns) || []);
      }
    });
  }, [viewMode]);

  const handleSelectedColumnChange = async (_selectedColumns: string[]) => {
    const settings = await getItem(StorageKeys.SalesColumnSetting);
    await setItem(StorageKeys.SalesColumnSetting, {...(settings || {}), [viewMode]: _selectedColumns});
    setSelectedColumns(_selectedColumns);
  };

  const handleRowClick = async (rowData: any) => {
    if (searchFilter.ViewMode === PurchaseInvoiceViewMode.All) {
      history.push(AUTH_PURCHASING_PURCHASE_INVOICE_DETAIL.replace(/:purchaseInvoiceID/g, rowData.ID));
    } else {
      history.push(AUTH_PURCHASING_PURCHASE_INVOICE_DETAIL.replace(/:purchaseInvoiceID/g, rowData.TransactionID));
    }
  };

  const handlePageChange = (newPage: number) => {
    setSearchFilter({
      ...searchFilter,
      LastRowNumber: (newPage - 1) * pageSize,
    });
  };

  const handleRowsPerPageChange = (rows: number) => {
    setSearchFilter({
      ...searchFilter,
      MaxResults: rows,
      LastRowNumber: 0,
    });
  };

  const handleBucketClick = (viewMode: PurchaseInvoiceViewMode) => {
    setSearchFilter({
      ...omit(searchFilter, ['StartDate', 'EndDate']),
      LastRowNumber: 0,
      ViewMode: viewMode,
    });
    setViewMode(viewMode);
  };

  const handleCreateInvoice = () => {
    setCreateDialog(true);
  };

  const handleCreateDialogClose = () => {
    setCreateDialog(false);
  };

  const isBucketActive = (ViewMode: string) => {
    return viewMode === ViewMode;
  };

  const purchaseInvoiceList = (
    <>
      <div className={classes.toolbar}>
        <Bucket
          isActive={isBucketActive(PurchaseInvoiceViewMode.All)}
          onClick={() => handleBucketClick(PurchaseInvoiceViewMode.All)}
          dataAutoId="btnPurchaseInvoices"
        >
          <Box className="flex flex-col ">
            <p className="text-[10px] font-medium">All Purchase Invoices</p>
            <p className="text-base font-semibold">{`${count.TotalRecordCount} Invoices`}</p>
          </Box>
        </Bucket>
        <Bucket
          isActive={isBucketActive(PurchaseInvoiceViewMode.RequiresAttention)}
          onClick={() => handleBucketClick(PurchaseInvoiceViewMode.RequiresAttention)}
          dataAutoId="btnRequiresAttention"
        >
          <Box className="flex flex-col">
            <p className="text-[10px] font-medium">Requires Attention</p>
            <p className="text-base font-semibold">{`${count.TotalCountRequiresAttention} Invoices`}</p>
          </Box>
        </Bucket>
        <Bucket
          isActive={isBucketActive(PurchaseInvoiceViewMode.ReadyToPost)}
          onClick={() => handleBucketClick(PurchaseInvoiceViewMode.ReadyToPost)}
          dataAutoId="btnReadyToPost"
        >
          <Box className="flex flex-col">
            <p className="text-[10px] font-medium">Ready To Post</p>
            <p className="text-base font-semibold">{`${count.TotalCountReadyToPost} Invoices`}</p>
          </Box>
        </Bucket>
        <Bucket
          isActive={isBucketActive(PurchaseInvoiceViewMode.Posted)}
          onClick={() => handleBucketClick(PurchaseInvoiceViewMode.Posted)}
          dataAutoId="btnPosted"
        >
          <Box className="flex flex-col">
            <p className="text-[10px] font-medium">Posted</p>
            <p className="text-base font-semibold">{`${count.TotalCountPosted} Invoices`}</p>
          </Box>
        </Bucket>
      </div>
      <div
        className={clsx(
          classes.root,
          'flex h-full w-full flex-col overflow-y-auto rounded-lg bg-white p-2 pb-10 font-poppins',
        )}
        id="purchase-invoice-list"
      >
        <PurchaseInvoiceToolbar
          viewMode={viewMode}
          title="Purchase Invoices"
          searchFilter={searchFilter}
          loading={loading}
          columns={columns}
          selectedColumns={selectedColumns}
          setSelectedColumns={handleSelectedColumnChange}
          setSearchFilter={setSearchFilter}
          handleCreateInvoice={handleCreateInvoice}
        />
        <STable
          newUI={true}
          columns={Object.entries(columns)
            .map(([key, value]: [string, any]) => {
              if (selectedColumns.includes(key)) {
                return value;
              }
            })
            .filter(value => value)}
          data={salesOrderList}
          emptyDataMessage={'No Purchase Invoices'}
          stickyHeader={true}
          loading={loading}
          onRowClick={handleRowClick}
        ></STable>
        <SPagination
          currentPage={currentPage}
          totalCount={totalCount}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 15, 25]}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </div>
    </>
  );

  return (
    <>
      <CreatePurchaseInvoiceDialog open={createDialog} onClose={handleCreateDialogClose} />
      <div className={`relative h-full overflow-hidden bg-spenda-newbg font-poppins`}>
        <Layout leftPanel={purchaseInvoiceList} splitWidthType={4} />
      </div>
    </>
  );
};

const Bucket = (
  props: PropsWithChildren<{
    isActive?: boolean;
    onClick: () => void;
    dataAutoId: string;
  }>,
) => {
  let darkColor = '#1C78AD';
  let lightColor = '#FFFFFF';

  const bucketColor = {
    backgroundColor: props.isActive ? darkColor : lightColor,
    border: `1px solid ${props.isActive ? lightColor : darkColor}`,
    color: props.isActive ? '#FFFFFF' : darkColor,
  };

  return (
    <button
      className={clsx(
        bucketColor,
        'relative flex cursor-pointer items-stretch justify-center rounded-lg text-center leading-4',
      )}
      onClick={props.onClick}
      data-autoid={props.dataAutoId}
      style={bucketColor}
    >
      <div className="flex flex-initial items-center justify-center">
        {props.isActive && (
          <svg viewBox="0 0 459 29" className="absolute bottom-0 left-0 w-full">
            <title>{'Path 7'}</title>
            <g id="bucket-wave" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd" fillOpacity={0.1}>
              <path
                d="M52.4946697,13 C100.346782,13 132.88438,0.39871785 198.066837,0.39871785 C263.249293,0.39871785 295.559157,15.7772368 342.740121,15.7772368 C374.194096,15.7772368 412.888412,13.6401165 458.823067,9.36587588 L458.823067,25 C458.823067,27.209139 457.032206,29 454.823067,29 L0.0190409027,29 L0.0190409027,29 L0.0190409027,9.36587588 C3.10138503,11.7886253 20.5932613,13 52.4946697,13 Z"
                id="Path-7"
                fill="#FFFFFF"
              />
            </g>
          </svg>
        )}
        {props.children}
      </div>
    </button>
  );
};
