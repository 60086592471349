import React, {useState} from 'react';
import {Button, Textarea, Typography} from 'spenda-ui-react';
import {IconPencil} from '../../../assets/svg/quotes-management/IconPencil';
import {useTenantInfo} from '../../../hooks/useTenantInfo';
import moment from 'moment';
import {IClaimRequestLineItemsFormValues} from '../../../screens/AccountsReceivable/claims-and-returns/CreateAndEditClaimRequest';
import {ARDialogTemplate} from '../ARDialogTemplate';
interface IClaimLineItemNoteDialogProps {
  isOpen: boolean;
  onClose: () => void;
  externalNote?: IClaimRequestLineItemsFormValues;
  onConfirm: (note: string) => void;
  claimLineItemNote?: {rowIndex: number; lineId: number; note: string};
}

export const ClaimLineItemNoteDialog = (props: IClaimLineItemNoteDialogProps) => {
  //Props
  const {externalNote, onClose, onConfirm, claimLineItemNote, isOpen} = props;

  // State
  const [newExternalNote, setNewExternalNote] = useState<string>(claimLineItemNote?.note || '');
  const [newExternalNoteError, setNewExternalNoteError] = useState<boolean>(false);
  const [updatedExternalNote, setUpdatedExternalNote] = useState<string>('');
  const [updatedExternalNoteError, setUpdatedExternalNoteError] = useState<boolean>(false);
  const [isEditNote, setIsEditNote] = useState(false);

  const {isInSupplierContext, isInBuyerContext} = useTenantInfo();

  const handleSaveNote = async () => {
    if (!newExternalNote.trim().length) {
      setNewExternalNoteError(true);
      return;
    }
    setNewExternalNoteError(false);
    onConfirm(newExternalNote);
  };

  const renderSavedNotes = (isBuyerNote: boolean) => {
    return (
      <div className="min-h-[100px] w-full">
        <Typography variant="h3" className="mb-4 text-spenda-greytext">
          {isBuyerNote ? `Buyer's Note` : `Supplier's Note`}
        </Typography>
        <div
          className={`${isBuyerNote && isInBuyerContext ? 'pb-5 pt-3.5' : 'py-3.5'} mb-4 flex w-full items-start justify-between rounded-lg bg-primary/5 px-5`}
        >
          <div className="flex w-3/4 flex-col gap-y-2 text-black-800">
            {isEditNote && ((isBuyerNote && isInBuyerContext) || (!isBuyerNote && isInSupplierContext)) ? (
              <Textarea
                label={updatedExternalNoteError ? 'Required' : 'Edit note'}
                data-autoid={'txtInternalNote'}
                value={updatedExternalNote}
                helperText="Required"
                error={updatedExternalNoteError}
                onChange={e => {
                  setUpdatedExternalNoteError(false);
                  setUpdatedExternalNote(e.target.value);
                }}
              />
            ) : (
              <>
                {externalNote?.claimRequestLineID && (
                  <Typography variant="small">
                    <span className="font-medium">Line item:</span> {`${externalNote?.code}`}
                  </Typography>
                )}
                <Typography variant="small">
                  <span className="font-medium">Note:</span>{' '}
                  {isBuyerNote ? externalNote?.buyerNote : externalNote?.supplierNote}
                </Typography>
                <div className="flex items-center">
                  <Typography variant="small">
                    <span className="font-medium">Date created:</span>{' '}
                    {isBuyerNote
                      ? externalNote?.buyerNoteCreatedDateTime_utc &&
                        moment(externalNote?.buyerNoteCreatedDateTime_utc).format('DD-MMM-YYYY')
                      : externalNote?.supplierNoteCreatedDateTime_utc &&
                        moment(externalNote?.supplierNoteCreatedDateTime_utc).format('DD-MMM-YYYY')}
                  </Typography>
                  <div className="mx-10 h-3 w-[2px] bg-black-800"></div>
                  <Typography variant="small">
                    <span className="font-medium">Created by:</span>{' '}
                    {isBuyerNote
                      ? externalNote?.buyerNoteCreatedByUserName
                      : externalNote?.supplierNoteCreatedByUserName}
                  </Typography>
                </div>
                {(isBuyerNote
                  ? externalNote?.buyerNoteModifiedDateTime_utc &&
                    moment(externalNote?.buyerNoteModifiedDateTime_utc).diff(
                      moment(externalNote?.buyerNoteCreatedDateTime_utc),
                      'seconds',
                    ) > 0
                  : externalNote?.supplierNoteModifiedDateTime_utc &&
                    moment(externalNote?.supplierNoteModifiedDateTime_utc).diff(
                      moment(externalNote?.supplierNoteCreatedDateTime_utc),
                      'seconds',
                    ) > 0) && (
                  <Typography variant="xsmall" className="text-spenda-labeltext">
                    Modified by{' '}
                    <span className="font-medium">
                      {isBuyerNote
                        ? externalNote?.buyerNoteModifiedByUserName
                        : externalNote?.supplierNoteModifiedByUserName}
                    </span>{' '}
                    on{' '}
                    {isBuyerNote
                      ? externalNote?.buyerNoteModifiedDateTime_utc &&
                        moment(externalNote?.buyerNoteModifiedDateTime_utc).format('DD-MMM-YYYY')
                      : externalNote?.supplierNoteModifiedDateTime_utc &&
                        moment(externalNote?.supplierNoteModifiedDateTime_utc).format('DD-MMM-YYYY')}
                  </Typography>
                )}
              </>
            )}
          </div>
          <div className="cursor-pointer">
            {((isInBuyerContext && isBuyerNote) || (isInSupplierContext && !isBuyerNote)) && (
              <Button
                ripple={false}
                color="primary"
                variant="text"
                className="flex items-start justify-start px-0 text-sm"
                onClick={() => {
                  if (isEditNote) {
                    if (!isEditNote || !updatedExternalNote.trim().length) {
                      setUpdatedExternalNoteError(true);
                      return;
                    }
                    setUpdatedExternalNoteError(false);
                    onConfirm(updatedExternalNote);
                  } else {
                    const note = (isInBuyerContext ? externalNote?.buyerNote : externalNote?.supplierNote) || '';
                    setIsEditNote(true);
                    setUpdatedExternalNote(note);
                  }
                }}
              >
                {isEditNote ? (
                  'Save'
                ) : (
                  <div className="flex items-center justify-start gap-1">
                    <IconPencil />
                    <Typography variant="small" className="font-semibold text-primary">
                      Edit
                    </Typography>
                  </div>
                )}
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <ARDialogTemplate
      dialogProps={{size: 'lg', open: isOpen, className: '!min-w-[460px] !max-w-[940px]'}}
      isFullScreen
      header={
        <p className=" font-poppins text-xl font-light text-[#333333]">
          {(isInSupplierContext && externalNote?.supplierNote) || (isInBuyerContext && externalNote?.buyerNote)
            ? 'Edit Note'
            : 'Add Note'}
        </p>
      }
      body={
        <div className="w-full">
          {((isInSupplierContext && !externalNote?.supplierNote) || (isInBuyerContext && !externalNote?.buyerNote)) && (
            <>
              <div className="mb-6 flex w-full items-end justify-between gap-5 px-10 pt-3">
                <div className="w-full">
                  <Typography variant="h3" className="mb-4 text-spenda-greytext">
                    {isInBuyerContext ? `Buyer's note` : `Supplier's note`}
                  </Typography>
                  <Textarea
                    label={newExternalNoteError ? 'Required' : 'Add note'}
                    data-autoid={'txtExternalNote'}
                    value={newExternalNote}
                    error={newExternalNoteError}
                    onChange={e => {
                      setNewExternalNoteError(false);
                      setNewExternalNote(e.target.value);
                    }}
                  />
                </div>
                <div>
                  <Button className="mb-[7px] min-w-[112px] px-2" variant="outlined" onClick={handleSaveNote}>
                    Save Note
                  </Button>
                </div>
              </div>
              <hr className="mb-7 border-[#ececec]" />
            </>
          )}
          <div className="max-h-[300px] w-full flex-row items-start justify-between gap-5 overflow-auto px-10">
            {isInBuyerContext && (
              <>
                {externalNote?.buyerNote != null && renderSavedNotes(true)}
                {externalNote?.supplierNote != null && renderSavedNotes(false)}
              </>
            )}
            {isInSupplierContext && (
              <>
                {externalNote?.supplierNote != null && renderSavedNotes(false)}
                {externalNote?.buyerNote != null && renderSavedNotes(true)}
              </>
            )}
            <div className="min-w-[112px]"></div>
          </div>
        </div>
      }
      footer={
        <div className="flex w-full flex-row justify-center">
          <Button
            data-autoid={`btnCancel`}
            className="bg-white"
            onClick={() => onClose()}
            variant="outlined"
            color="primary"
          >
            Close
          </Button>
        </div>
      }
      handleClose={() => onClose()}
    />
  );
};
