import { useEffect, useState } from "react";
import { useSupplierAPI } from "./useSupplierAPI";
import { ISearchFilter } from "../model/search-filters/SearchFilter";
import { ISuppliers } from "../model/supplier/Supplier";

export const useLoadSupplier = () => {

    const { getSuppliers } = useSupplierAPI();
    let pageSize = 15;

    const initialFilterState = {
        StartRow: 1,
        MaxResults: pageSize,
        SearchString: "",
    } as ISearchFilter;

    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState<ISuppliers[]>([]);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [moreToGet, setMoreToGet] = useState<boolean>(true);
    const [error, setError] = useState<any>();
    const [searchFilter, setSearchFilter] = useState<ISearchFilter>(initialFilterState);

    const getSuppliersList = async () => {
        setLoading(true);
        try {
            const result = await getSuppliers(searchFilter);
            if (result === undefined) return;
            let items = result?.value || [];
            setItems(items);
            setTotalCount(result.totalRecordCount || 0);
            setCurrentPage(Math.ceil((searchFilter.StartRow || 1 + pageSize - 1) / (searchFilter.MaxResults || pageSize)));
            setMoreToGet(result.moreToGet || false);
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getSuppliersList();
    }, [searchFilter]);
    return {
        loading,
        items,
        totalCount,
        pageSize: searchFilter.MaxResults || pageSize,
        currentPage,
        moreToGet,
        error,
        searchFilter,
        setSearchFilter,
    }
}