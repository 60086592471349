import React, {useEffect, useRef, useState} from 'react';
import {InputAdornment, IconButton, TextField, TextFieldProps} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import {createAutoIdAttributeFromReactProps} from '../../utils/createAutoIdAttribute';

export const PasswordField = (props: TextFieldProps) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const textPasswordInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (props.autoFocus && textPasswordInput && textPasswordInput.current) {
      textPasswordInput.current.focus();
    }
  }, [props.autoFocus]);

  const {autoIdAttribute, attributeValue} = createAutoIdAttributeFromReactProps(props, 'txt');

  return (
    <TextField
      {...props}
      id={props.id || props.name}
      inputProps={{...props.inputProps, ...autoIdAttribute}}
      inputRef={textPasswordInput}
      type={showPassword ? 'text' : 'password'}
      label={props.label || 'Password'}
      key={props.id}
      value={props.value}
      error={props.error}
      helperText={props.helperText}
      onChange={props.onChange}
      autoComplete="off"
      autoFocus={!!props.autoFocus}
      name={props.name}
      variant={'outlined'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              data-autoid={`btnToggle${attributeValue}`}
              edge="end"
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
        style: {fontFamily: 'Poppins'},
      }}
      fullWidth
    />
  );
};
