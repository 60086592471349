import React from 'react';
import {FormikProps} from 'formik';
import {InputAdornment, TextField} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import {css} from 'glamor';
import {SearchFormValues} from '../purchasing/ProductsFilter';
import {makeStyles} from '@material-ui/core/styles';

const SearchForm = (props: FormikProps<SearchFormValues> & {minHeight?: string}) => {
  const {
    values: {freeTextInput, placeholder},
    errors,
    touched,
    handleChange,
    setFieldTouched,
  } = props;

  const color = css({
    color: '#1C78AD',
  });

  const change = (name: 'freeTextInput', e: {persist: () => void}) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  const useStyles = makeStyles({
    root: {
      '& .MuiInputBase-input': {
        color: '#000',
        textOverflow: 'ellipsis !important',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        '@media(min-width: 1280px)': {
          minWidth: '210px',
          minHeight: props.minHeight || '36px',
        },
        '@media(max-width: 1024px)': {
          padding: 0,
          margin: 0,
        },
        // width: '100%',
      },
    },
  });

  const classes = useStyles();

  return (
    <form onSubmit={props.handleSubmit} className="relative" style={{flex: '1 1 30%'}}>
      <TextField
        classes={{root: classes.root}}
        type="search"
        variant="outlined"
        name="freeTextInput"
        id="freeTextInput"
        helperText={touched.freeTextInput ? errors.freeTextInput : ''}
        error={touched.freeTextInput && Boolean(errors.freeTextInput)}
        onChange={change.bind(null, 'freeTextInput')}
        value={freeTextInput}
        placeholder={placeholder}
        style={{
          borderRadius: 4,
          border: '1px solid #1C78AD',
          color: '#1C78AD',
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon className={`${color}`} />
            </InputAdornment>
          ),
        }}
      />
    </form>
  );
};

export default SearchForm;
