import React from 'react';
import {PaymentStatusType, PurchaseInvoiceRequestedList} from '../../model/constants/Constants';
import {PriceFormat} from '../../utils/formatter';
import Tick from '../../assets/svg/Tick.svg';
import UnTick from '../../assets/svg/Untick.svg';
import tickBlank from '../../assets/svg/accounts-payable/batchCircleCheckboxBlank.svg';
import tickSelected from '../../assets/svg/accounts-payable/batchCircleCheckboxSelected.svg';
import {css} from 'glamor';

const selected = css({
  backgroundColor: '#1C78AD',
  color: '#FAFAFA',
  height: '30px',
  borderRadius: '4px',
  margin: '3px',
});

const toggleBtns = css({
  backgroundColor: '#F1F1F1',
  color: '#666666',
  height: '36px',
  alignItems: 'center',
  display: 'flex',
  borderRadius: '5px',
  fontSize: '14px',
  fontWeight: 500,
  cursor: 'pointer',
  border: '1px solid #EAEAEA',
});

const toggleBtn = css({
  padding: '4px 18px',
});


const width200 = css({
  width: '200px',
  '@media(max-width: 1100px)': {width: '120px'},
});

const apSelectSymbol = css({
  margin: '0px',
  width: '22px',
  height: '22px',
});

export const ViewModeToggler = (props: {toggleViewMode?: () => void; isListMode?: boolean}) => {
  const {toggleViewMode, isListMode} = props;
  return toggleViewMode ? (
    <div className={`${toggleBtns}`}>
      <span
        className={`${isListMode ? selected : ''} ${toggleBtn}`}
        onClick={() => (isListMode ? {} : toggleViewMode())}
      >
        List View
      </span>
      <span
        className={`${isListMode ? '' : selected} ${toggleBtn}`}
        onClick={() => (isListMode ? toggleViewMode() : {})}
      >
        Group View
      </span>
    </div>
  ) : (
    <div></div>
  );
};

export const getMessage = (selectedBucket: PurchaseInvoiceRequestedList) => {
  switch (selectedBucket) {
    case PurchaseInvoiceRequestedList.Overdue:
      return "YOU DON'T HAVE ANY OVERDUE PURCHASE INVOICES RIGHT NOW.";

    case PurchaseInvoiceRequestedList.Due:
      return "YOU DON'T HAVE ANY DUE PURCHASE INVOICES RIGHT NOW.";

    case PurchaseInvoiceRequestedList.Payments:
      return "YOU DON'T HAVE ANY PAYMENTS RIGHT NOW.";

    case PurchaseInvoiceRequestedList.Payable:
      return "YOU DON'T HAVE ANY PAYABLE INVOICES RIGHT NOW.";

    case PurchaseInvoiceRequestedList.None:
      return 'PLEASE SELECT ANY FILTER TO VIEW YOUR OVERDUE/DUE INVOICES';
    default:
      return "YOU DON'T HAVE ANY PAYABLE INVOICES RIGHT NOW.";
  }
};

export const SelectToggler = (props: {
  isSelected?: boolean;
  className?: string;
  dataAutoId?: string;
  isAccountsPayable?: boolean;
}) => {
  return (
    <>
      {props.isAccountsPayable ? (
        <img
          src={props.isSelected ? tickSelected : tickBlank}
          className={`select-symbol-icon ${apSelectSymbol} ${props.className || ''}`}
          data-autoid={props.dataAutoId}
        />
      ) : (
        <img
          src={props.isSelected ? Tick : UnTick}
          className={`select-symbol-icon ${props.className || ''}`}
          data-autoid={props.dataAutoId}
        />
      )}
    </>
  );
};

interface ISupplerStatementProps {
  outstandingAmount?: number;
  totalBill: number;
  isOverDueList: boolean;
  isPaid?: boolean;
  APDraftPreview?: boolean;
  APSupplierView?: boolean;
  payAmount?: number;
}
export const SupplierStatement = ({
  outstandingAmount,
  totalBill,
  isOverDueList,
  isPaid,
  APDraftPreview,
  APSupplierView,
  payAmount,
}: ISupplerStatementProps) => {
  return (
    <div
      className={`total-container flex-1 ${width200} ${
        isPaid ? 'total-paid-container' : isOverDueList ? 'total-overdue-container' : 'total-due-container'
      }`}
    >
      {APDraftPreview ? (
        <>
          <p className="pay-amount text-base font-medium">Pay amount: {PriceFormat(payAmount)}</p>
          <p className="invoice-no">Invoice total: {PriceFormat(totalBill)}</p>
        </>
      ) : (
        <p className="invoice-no">
          {APSupplierView ? 'Invoice total:' : 'Total Invoice:'} {PriceFormat(totalBill)}
        </p>
      )}
      {!APDraftPreview && (
        <p className="supplier-name">
          {isPaid ? PaymentStatusType.Paid : `Total Outstanding: ${PriceFormat(outstandingAmount)}`}
        </p>
      )}
    </div>
  );
};
