import React, {useState} from 'react';
import {Button, Switch, Typography} from 'spenda-ui-react';
import {AlertDialog} from '../dialog/AlertDialogSlideV2';
import VariantInventory from './VariantInventory';
import {CloseCross} from '../../assets/svg/CloseCross';
import {ARTable} from '../AccountsReceivable/ARTable';
import { ProductVariantIcon } from '../../assets/svg';

export default function Variant() {
  const [isLinkedDialog, setIsLinkedDialog] = useState<boolean>(false);
  const [isVariantInventoryDialog, setIsVariantInventoryDialog] = useState<boolean>(false);

  const columns = [
    {
      title: 'Attributes',
      key: 'attributes',
      width: '60%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5 ',
      rowRenderer: (index: number) => (
        <Typography variant="small" data-autoid={`txtAttributes-${index}`}>
          {index}.
        </Typography>
      ),
    },
    {
      title: 'Inventory items',
      key: 'inventoryItems',
      width: '40%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5 bg-spenda-scream/30',
      rowRenderer: (index: number) => (
        <Typography variant="small" data-autoid={`txtInventoryTtems-${index}`}>
          Use Existing or Create New Item
        </Typography>
      ),
    },
  ];
  return (
    <>
      <div className="mt-5 flex">
        <div className="w-full lg:w-4/5">
          <div className="flex w-full max-w-full lg:w-[916px] items-start justify-between">
            <div className="flex flex-col gap-2">
              <Typography variant="paragraph" className="font-poppins font-medium text-black-800">
                Do you want to enable variations for this item?
              </Typography>
              <Typography variant="small" className="max-w-full lg:w-[564px] text-start font-poppins font-normal text-gray-800">
                If you enable variations, this item will no longer be sold and you will be prompted to select the
                specific variation during the sales process.
              </Typography>
            </div>
            <div className="flex items-center justify-center">
              <Switch
                id="IsLinked"
                name="IsLinked"
                ripple={false}
                className="h-full w-full checked:bg-success"
                onChange={() => {
                  setIsLinkedDialog(true);
                }}
                containerProps={{
                  className: 'w-11 h-6',
                }}
                circleProps={{
                  className: 'before:hidden left-0.5 border-none',
                }}
              />
            </div>
          </div>
          <div className="mt-10 flex w-full lg:w-[996px] flex-col rounded-md bg-spenda-cream px-2.5">
            <div className="flex items-center justify-between p-3 ">
              <Typography className="text-lg font-medium text-black-800">Product variants</Typography>
              <Button variant="outlined">Variation Builder</Button>
            </div>
            <div className="mb-2 h-1 w-full border-b border-b-[#ECECEC]" />
            <Typography className="cursor-pointer pl-2.5 text-base font-semibold text-primary">Create All</Typography>
            <ARTable isLoading={false} columns={columns} scope="AR" rows={Array(2).fill(1)} tableClass="mt-3" />
          </div>
        </div>

        <div className="flex w-full max-w-full lg:w-[320px] flex-col items-start gap-4 rounded-md bg-[#E3EEF54D] p-4">
          <div className="self-end">
          <CloseCross data-autoid={'btnCross'} className="text-[#cccccc] cursor-pointer" />
          </div>
          <div className="flex flex-col items-center gap-y-2 space-x-4">
            <ProductVariantIcon />
            <div className="mt-5 flex flex-col items-center justify-center gap-y-4 text-center">
              <Typography variant="paragraph" className="font-poppins font-medium text-black-800">
                Benefits of adding variants
              </Typography>
              <Typography variant="small" className="font-poppins font-normal text-gray-800">
                Product variants are a great way to manage the same product that differs in style, color, size, etc.
                Each combination of value will result in a unique product selectable during the sales process.
              </Typography>
            </div>
          </div>
        </div>
      </div>

      {isLinkedDialog && (
        <AlertDialog
          title="Linked to External System"
          headingTextSize="h2"
          headingClassess="text-black justify-center"
          dialogClassess="!max-w-[497px] !min-w-[20%]"
          data-autoid={'dlgLinkedToExternalSystem'}
          actions={[
            {
              label: 'Cancel',
              variant: 'outlined',
              action: () => setIsLinkedDialog(!isLinkedDialog),
            },
            {
              label: 'Yes, Continue',
              action: () => setIsVariantInventoryDialog(true),
            },
          ]}
          dialogBodyClassess="flex justify-center"
          contentClass="max-w-[400px] text-sm py-12 px-6"
          body={
            <div className="flex flex-col gap-3 p-4 text-center">
              <Typography variant="small" className="font-normal text-black-800">
                This Item is integrated with an external system. In order to create a Master Inventory Item we will need
                to create a copy. This may result in a duplicate product being displayed when in Sales Mode. If you
                don't want the original Item to appear in your Inventory, you can manually archive it.
              </Typography>
              <Typography variant="small" className="font-normal text-black-800">
                Would you like to continue?
              </Typography>
            </div>
          }
        ></AlertDialog>
      )}
      {isVariantInventoryDialog && <VariantInventory handleClose={() => setIsVariantInventoryDialog(false)} />}
    </>
  );
}
