import React from 'react';
const IconQuotesDivider = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="19" height="429" viewBox="0 0 19 429" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M1 1.16162V189.712C1 190.881 1.94827 191.83 3.11802 191.83V191.83C4.28777 191.83 5.23604 190.881 5.23604 189.712V189.136C5.23604 186.415 8.81116 185.41 10.2289 187.733L17.6452 199.883C18.477 201.245 18.477 202.959 17.6452 204.322L10.2289 216.472C8.81115 218.795 5.23604 217.79 5.23604 215.068V214.493C5.23604 213.323 4.28777 212.375 3.11802 212.375V212.375C1.94827 212.375 1 213.323 1 214.493V428.402"
        stroke="#8EBFDA"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default IconQuotesDivider;
