import {useEffect, useState} from 'react';
import qs from 'query-string';
import useHttp from '../hooks/useHttp';
import {
  IActionResultsPayments,
  IPagedActionResults,
  IActionResultsList,
  IAlertsActionResult,
} from '../model/ActionResults';
import {ISearchFilter} from '../model/search-filters/SearchFilter';
import {
  IAccountPayeesDetails,
  IBatchPaymentRequest,
  IPaymentBatch,
  IPaymentBatchForApproval,
  IPurchaseInvoiceBatch,
  ISubmitBatchPaymentResponse,
  IUpcomingPaymentBatch,
  IUserDefaultViewSettings,
} from '../model/payment-batch/PaymentBatch';
import {PaymentBatchPermissionType} from '../model/constants/Constants';
import {IAlertRequestRequest} from '../model/alert/AlertInfo';

export const usePaymentBatchAPI = () => {
  const {GET, POST, PUT, isLoading} = useHttp();

  const uploadABAFile = async (payload: any): Promise<IPaymentBatch> => {
    return POST('Spenda/Payments/AccountsPayable/PaymentBatch/Upload', payload).then(
      (res: IActionResultsPayments<IPaymentBatch>) => res.value
    );
  };

  const getPaymentBatch = async (paymentBatchId: any): Promise<IPaymentBatch> => {
    return GET(`Spenda/Payments/AccountsPayable/PaymentBatch/${paymentBatchId}`).then(
      (res: IActionResultsPayments<IPaymentBatch>) => res.value
    );
  };

  const getUserRolePermissions = async (): Promise<PaymentBatchPermissionType[]> => {
    return GET(`Spenda/Payments/AccountsPayable/PaymentBatch/UserPermissions`).then(
      (res: IActionResultsPayments<PaymentBatchPermissionType[]>) => res.value as PaymentBatchPermissionType[]
    );
  };

  const getUserRoleDefaultViewSettings = async (): Promise<IUserDefaultViewSettings> => {
    return GET(`Spenda/Payments/AccountsPayable/UserSettings`).then(
      (res: IActionResultsPayments<IUserDefaultViewSettings>) => res.value as IUserDefaultViewSettings
    );
  };

  const updateUserRoleDefaultViewSettings = async (
    payload: IUserDefaultViewSettings
  ): Promise<IUserDefaultViewSettings> => {
    return POST(`Spenda/Payments/AccountsPayable/UserSettings`, payload).then(
      (res: IActionResultsPayments<IUserDefaultViewSettings>) => res.value
    );
  };

  const updatePaymentBatch = async (
    paymentBatchId: any,
    payload: Partial<IPaymentBatch> | IBatchPaymentRequest
  ): Promise<IPaymentBatch> => {
    return POST(`Spenda/Payments/AccountsPayable/PaymentBatch/${paymentBatchId}`, {value: payload}).then(
      (res: IActionResultsPayments<IPaymentBatch>) => res.value
    );
  };

  const cancelPaymentBatch = async (paymentBatchId: any, cancelReason: string): Promise<IPaymentBatch> => {
    return POST(`Spenda/Payments/AccountsPayable/PaymentBatch/${paymentBatchId}/cancel`, {cancelReason}).then(
      (res: IActionResultsPayments<IPaymentBatch>) => res.value
    );
  };

  const getPaymentBatchesForApproval = async (): Promise<IPaymentBatchForApproval[]> => {
    return GET(`Spenda/Payments/AccountsPayable/PaymentBatch/requiresapproval`, {}).then(
      (res: IActionResultsPayments<IPaymentBatchForApproval[]>) => res.value
    );
  };

  const getBatchesReadyForAuthorisation = async (): Promise<IPaymentBatchForApproval[]> => {
    return GET(`Spenda/Payments/AccountsPayable/PaymentBatch/ReadyForPayment`, {}).then(
      (res: IActionResultsPayments<IPaymentBatchForApproval[]>) => res.value
    );
  };

  const sendPaymentBatchForApproval = async (paymentBatchId: any): Promise<IPaymentBatch> => {
    return POST(`Spenda/Payments/AccountsPayable/PaymentBatch/${paymentBatchId}/sendforapproval`, {}).then(
      (res: IActionResultsPayments<IPaymentBatch>) => res.value
    );
  };

  const createPurchaseInvoicesBatch = async (invoices: IPurchaseInvoiceBatch): Promise<IPaymentBatch> => {
    return POST(`Spenda/Payments/AccountsPayable/PaymentBatch/CreateFromPurchaseInvoices`, invoices).then(
      (res: IActionResultsPayments<IPaymentBatch>) => res.value
    );
  };

  const removePurchaseInvoice = async (batchId: number, payload: any): Promise<ISubmitBatchPaymentResponse> => {
    return POST(`Spenda/Payments/AccountsPayable/PaymentBatch/${batchId}`, payload).then(
      (res: ISubmitBatchPaymentResponse) => res
    );
  };

  const getUpcomingPaymentBatches = async (filter?: {
    StartDate: string;
    EndDate: string;
  }): Promise<IUpcomingPaymentBatch[]> => {
    return GET(`Spenda/Payments/AccountsPayable/PaymentBatch/upcomingbatches`, filter).then(
      (res: IActionResultsPayments<IUpcomingPaymentBatch[]>) => res.value
    );
  };

  const approvePaymentBatchForPayment = async (paymentBatchId: any): Promise<IPaymentBatch> => {
    return POST(`Spenda/Payments/AccountsPayable/PaymentBatch/${paymentBatchId}/approveforpayment`, {}).then(
      (res: IActionResultsPayments<IPaymentBatch>) => res.value
    );
  };

  const submitPaymentBatch = async (paymentBatchId: any, payload: any): Promise<ISubmitBatchPaymentResponse> => {
    return POST(`Spenda/Payments/AccountsPayable/PaymentBatch/${paymentBatchId}/payment`, payload).then(
      (res: ISubmitBatchPaymentResponse) => res
    );
  };

  const getPaymentBatchStatus = async (paymentBatchId: any): Promise<Pick<IPaymentBatch, 'status'>> => {
    return GET(`Spenda/Payments/AccountsPayable/PaymentBatch/${paymentBatchId}/payment`, {}).then(
      (res: IActionResultsPayments<Pick<IPaymentBatch, 'status'>>) => res.value
    );
  };

  const getPaymentBatches = async (
    filter: ISearchFilter
  ): Promise<IPagedActionResults<IPaymentBatchForApproval[]> & IActionResultsList<IPaymentBatchForApproval>> => {
    return GET(
      `Spenda/Payments/AccountsPayable/PaymentBatchReport?${qs.stringify(filter, {arrayFormat: 'bracket'})}`
    ).then(
      (res: IPagedActionResults<IPaymentBatchForApproval[]> & IActionResultsList<IPaymentBatchForApproval>) => res
    );
  };

  const checkBatchPaymentStatus = async (transctionGUID: any): Promise<ISubmitBatchPaymentResponse> => {
    return GET(`Spenda/Payments/AccountsPayable/PaymentBatch/DebitStatus/${transctionGUID}`).then(
      (res: ISubmitBatchPaymentResponse) => res
    );
  };

  const saveAccountsPayablePayees = async (payload: IAccountPayeesDetails[]): Promise<IAlertsActionResult> => {
    return POST('Spenda/Payments/AccountsPayable/Payees/', {
      value: payload,
    }).then((res: IAlertsActionResult) => res);
  };

  const sendBulkAdviceToSuppliers = async (payload: {
    TransactionGUIDs: string[];
    EmailMessage: string;
  }): Promise<IAlertsActionResult> => {
    return POST(`Spenda/Payments/AccountsPayable/PaymentBatch/SendAdviceToSupplier`, {
      value: payload,
    }).then((res: IAlertsActionResult) => res);
  };
  
  const sendCopyToggle = async (shouldSend:boolean) : Promise<IAlertsActionResult> => {
    return PUT(`Spenda/Payments/AccountsPayable/Config/APShouldSendPaymentAdvice`,{shouldSend}).then
      ((res: IAlertsActionResult )=> res);
  };

  const downloadInvoiceasPdf = (req: Partial<IAlertRequestRequest>): Promise<string[]> => {
    return PUT('Spenda/Alerts/GetDocument', req).then((data: IAlertsActionResult) => data.value);
  };

  /*******************payment method authority APIs*********************/

  const authorisePayment = (paymentBatchId: any) => {
    return POST(`Spenda/Payments/AccountsPayable/PaymentBatch/${paymentBatchId}/authorise`, {});
  };

  /*******************payment method authority APIs*********************/

  return {
    uploadABAFile,
    getPaymentBatch,
    updatePaymentBatch,
    cancelPaymentBatch,
    getPaymentBatchesForApproval,
    getBatchesReadyForAuthorisation,
    sendPaymentBatchForApproval,
    getUpcomingPaymentBatches,
    approvePaymentBatchForPayment,
    submitPaymentBatch,
    getPaymentBatchStatus,
    getPaymentBatches,
    checkBatchPaymentStatus,
    saveAccountsPayablePayees,
    sendBulkAdviceToSuppliers,
    sendCopyToggle,
    downloadInvoiceasPdf,
    createPurchaseInvoicesBatch,
    removePurchaseInvoice,
    getUserRolePermissions,
    authorisePayment,
    getUserRoleDefaultViewSettings,
    updateUserRoleDefaultViewSettings,
    isLoading,
  };
};

export const usePaymentBatchReports = (initialStatus: string[]) => {
  let pageSize = 15;

  const initialFilterState = {
    StartRow: 1,
    MaxResults: pageSize,
    Status: initialStatus,
  } as ISearchFilter;

  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<IPaymentBatchForApproval[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [moreToGet, setMoreToGet] = useState<boolean>(true);
  const [error, setError] = useState<any>();
  const [searchFilter, setSearchFilter] = useState<ISearchFilter>(initialFilterState);

  const {getPaymentBatches} = usePaymentBatchAPI();

  const getPaymentBatchReports = async () => {
    setLoading(true);

    if (searchFilter.StartRow === 1) {
      setItems([]);
      setMoreToGet(true);
    }

    try {
      const {value, moreToGet, totalRecordCount} = await getPaymentBatches(searchFilter);
      let items: IPaymentBatchForApproval[] = value || [];

      setItems(items);
      setTotalCount(Number(totalRecordCount));
      setMoreToGet(Boolean(moreToGet));
      setCurrentPage(Math.ceil((searchFilter.StartRow || 1 + pageSize - 1) / (searchFilter.MaxResults || pageSize)));
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPaymentBatchReports();
  }, [searchFilter]);

  return {
    loading,
    items,
    totalCount,
    pageSize: searchFilter.MaxResults || pageSize,
    currentPage,
    moreToGet,
    error,
    searchFilter,
    getPaymentBatchReports,
    setSearchFilter,
  };
};
