import useHttp from '../hooks/useHttp';
import {IHeaderWidget} from '../model/merchant-portal/MerchantPortal';

const useMerchantPortalAPI = () => {
  const {GET, isLoading} = useHttp();

  const getMerchantPortalHeaderDetail = (): Promise<IHeaderWidget> => {
    return GET(`Spenda/Statements/MerchantPortal/KeyPerformanceIndicators`);
  };

  return {
    getMerchantPortalHeaderDetail,
    isLoading,
  };
};

export default useMerchantPortalAPI;
