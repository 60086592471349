import React from 'react';
import clsx from 'clsx';
import {Link} from 'react-router-dom';

import {IConversationChatbox} from '../../model/conversation/Conversation';
import {AUTH_SELLING_COLLECT_ROUTE} from '../../routes/AuthenticatedRoutes';
import {useTenantInfo} from '../../hooks/useTenantInfo';

import {Box, makeStyles, MenuItem, Select} from '@material-ui/core';
import GoBackIcon from '../../assets/svg/GoBackIcon';
import {CaretDown} from '../../assets/svg/CaretDown';
import {ARMoreAction} from '../../assets/svg/ARMoreAction';
import {Close} from '@material-ui/icons';

interface ChatBoxHeaderProps {
  conversationData: IConversationChatbox;
  subjectId: string;
  refreshMsgIntervalId: number;
  newLineMsgTimeOutId: number;
  scope?: string;
  handleMinOrMaxChatBox: (operationId: string, isMinimizeAllOpenChatWindow?: boolean) => void;
  handleCloseChatBox: (operationId: string) => void;
  handleResolveConversation: (operationId: string) => void;
  handleMarkAsUnread: (operationId: string) => void;
}

export const ChatBoxHeader = (props: ChatBoxHeaderProps) => {
  const {
    conversationData,
    subjectId,
    refreshMsgIntervalId,
    newLineMsgTimeOutId,
    scope,
    handleMinOrMaxChatBox,
    handleCloseChatBox,
    handleResolveConversation,
    handleMarkAsUnread,
  } = props;
  const {operationUniqueId, isChatboxOpen, refNumber, conversationWith, conversationId, customerID} = conversationData;

  const {isInSupplierContext} = useTenantInfo();

  const useChatBoxHeaderStyles = makeStyles(() => ({
    chatBoxHead: {
      borderRadius: '12px 12px 0px 0px',
      width: '100%',
      minHeight: '60px !important',
      maxHeight: '80px !important',
      height: 'auto !important',
      backgroundColor: '#e9f2f7',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '8px 16px',
    },
    chatTitle: {
      fontWeight: 300,
      fontFamily: 'poppins',
      fontSize: '1.2em',
      color: '#333333',
    },
    moreDropdown: {
      border: 'none',
      padding: 0,
      position: 'relative',
      fontFamily: 'poppins',
      '&::before': {
        borderBottom: 'none',
        ':hover': {
          borderBottom: 'none',
        },
      },
      '&::after': {
        borderBottom: '0px solid #fff !important',
      },
      '&:hover:not(.Mui-disabled)::before': {
        borderBottom: '0px solid #fafafa !important',
      },
      '& .MuiSelect-select': {
        display: 'flex',
        padding: 0,
        width: '0px !important',
        position: 'absolute',
      },
    },
    menuItemList: {
      fontFamily: 'poppins!important',
      '&:hover': {
        backgroundColor: '#EAEFF2',
      },
    },
  }));

  const classes = useChatBoxHeaderStyles();

  return (
    <Box aria-controls="panel1a-content" id="panel1a-header" className={`${classes.chatBoxHead}`}>
      <Box data-autoid={`lblReceiverInfo-${operationUniqueId}`} className={`flex flex-row items-center w-full`}>
        {scope !== 'CR_CreateAndEdit' && (
          <span onClick={() => handleMinOrMaxChatBox(operationUniqueId)}>
            <GoBackIcon className="cursor-pointer" />
          </span>
        )}
        <p className={clsx('mr-4', classes.chatTitle, {'ml-3': scope !== 'CR_CreateAndEdit'})}>
          {isInSupplierContext && customerID ? (
            <Link to={`${AUTH_SELLING_COLLECT_ROUTE}/${customerID}`}>{conversationWith}</Link>
          ) : (
            conversationWith
          )}
          {refNumber ? ` (${refNumber})` : ''}
        </p>
      </Box>
      <Box className={`flex flex-row items-center`}>
        <Box
          data-autoid={`btnMinMaxChatbox-${operationUniqueId}`}
          onClick={() => {
            window.clearInterval(refreshMsgIntervalId);
            window.clearTimeout(newLineMsgTimeOutId);
            handleMinOrMaxChatBox(operationUniqueId, true);
          }}
        >
          {scope === 'CR_CreateAndEdit' ? (
            <Box
              data-autoid={`btnCloseChatbox-${operationUniqueId}`}
              onClick={() => {
                window.clearInterval(refreshMsgIntervalId);
                window.clearTimeout(newLineMsgTimeOutId);
                handleCloseChatBox(operationUniqueId);
              }}
            >
              <Close className="cursor-pointer" />
            </Box>
          ) : (
            <CaretDown className={`cursor-pointer`} name={isChatboxOpen ? 'Minimize' : 'Maximize'} />
          )}
        </Box>
        {conversationId && (
          <Box data-autoid={`btnResolveConversation-${operationUniqueId}`}>
            <Select
              className={`${classes.moreDropdown} ml-2 text-black-800 border font-poppins`}
              value={subjectId}
              variant="standard"
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'center',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'right',
                },
                getContentAnchorEl: null,
              }}
              IconComponent={() => <ARMoreAction className="mt-1 ml-2" />}
              inputProps={{
                name: 'resolveConversation',
              }}
            >
              <MenuItem
                data-autoid="ddlItem-ResolveConversation"
                onClick={() => handleResolveConversation(operationUniqueId)}
                className={classes.menuItemList}
              >
                Resolve conversation
              </MenuItem>

              <MenuItem
                data-autoid="ddlItem-MarkAsUnread"
                onClick={() => handleMarkAsUnread(operationUniqueId)}
                className={classes.menuItemList}
              >
                Mark as unread
              </MenuItem>
            </Select>
          </Box>
        )}
      </Box>
    </Box>
  );
};
