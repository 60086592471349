import {IAPContextState} from './APContext';
import APContextActions from './APContextActions';

export default (state: IAPContextState, action: {type: string; payload: any; options?: any}) => {
  switch (action.type) {
    case APContextActions.SET_USER_ROLE_BATCH_PERMISSIONS:
      return {
        ...state,
        userRoleBatchPermissions: action.payload,
      };
    case APContextActions.SET_USER_ROLE_DEFAULT_VIEW_SETTINGS:
      return {
        ...state,
        defaultSearchStatusFilters: action?.payload?.defaultSearchStatusFilters,
        defaultTimelineGroupsFilter: action?.payload?.defaultTimelineGroupsFilter,
      };
    default:
      return state;
  }
};
