import {Link} from '@material-ui/core';
import {css} from 'glamor';
import React, {useState} from 'react';
import Skeleton from 'react-loading-skeleton';
import {PaymentStatusType, TransTypes} from '../../../model/constants/Constants';
import {IBusTransLine, IInvoice} from '../../../model/invoice/Invoice';
import {ITxDeduction} from '../../../model/payment/PaymentT';
import {PriceFormat} from '../../../utils/formatter';
import STooltip from '../../data-display/Tooltip';
import {PaymentSummary} from '../PaymentSummary';
import {Tooltip} from 'spenda-ui-react';

export const TransactionLines = (props: {
  txnLine?: IBusTransLine[];
  breakPoint?: any;
  tx?: IInvoice;
  isPaid?: boolean;
  showAmountFieldOnPaymentSummaryPopover?: boolean;
  borderStyling?: string;
  isAPInv?: boolean;
  showSummaryLines?: boolean;
  hideQuantity?: boolean;
}) => {
  const {isAPInv} = props;
  const gridLayout = css({
    borderBottom: Boolean(props?.borderStyling) ? 'solid 2px #E1E1E1' : '',
    display: 'grid',
    gridTemplateColumns: props.breakPoint
      ? 'minmax(7rem, 7rem) minmax(0, 25rem) auto'
      : isAPInv
        ? 'minmax(4rem, 7rem) minmax(0, 22rem) 1fr 1fr 1.25fr'
        : 'minmax(4rem, 7rem) minmax(0, 25rem) 1fr 1fr 1fr',
  });

  const APCustomTableData = ({
    tableHead,
    tableCell,
    className,
  }: {
    tableHead: string;
    tableCell: string | React.ReactNode;
    className?: string;
  }) => {
    return (
      <>
        <td
          width="15%"
          align="right"
          className={`${className} border-b-2 border-[#EAEAEA] px-2 py-1 text-xs font-light text-black-800`}
        >
          {tableHead}
        </td>
        <td
          width="15%"
          align="right"
          className={`${className} border-b-2 border-[#EAEAEA]  px-2 py-1 text-xs font-light text-black-800 `}
        >
          {tableCell}
        </td>
      </>
    );
  };

  const [showTooltip, setShowTooltip] = useState(false);

  const priceFormat = (amount?: number) => PriceFormat(amount, '');

  const successfulPayments: ITxDeduction[] =
    (props.tx?.Payments && props.tx?.Payments.filter(p => p.Status === PaymentStatusType.Paid)) || [];

  const appliedCreditNotes: ITxDeduction[] =
    (props.tx?.RefBusTrans && props.tx?.RefBusTrans.filter(p => p.TransType === TransTypes.CreditNote)) || [];

  const appliedToInvoice: ITxDeduction[] = props.tx?.RefBusTrans
    ? props.tx?.RefBusTrans.filter(p => p.TransType === TransTypes.Invoice)
    : props.tx?.LinkedTrans
      ? props.tx?.LinkedTrans.filter(p => p.DatTypeString === TransTypes.Invoice)
      : [];

  return (
    <main>
      {isAPInv ? (
        <>
          <table className="w-full">
            <thead className={`bg-[#E1E1E1] py-1`}>
              <tr>
                {!props.showSummaryLines && (
                  <th align="left" className={`px-1 py-[2px] text-sm font-medium`}>
                    <div className={`flex items-center`}>Code</div>
                  </th>
                )}
                <th align="center" className={`px-1 py-[2px] text-sm font-medium`}>
                  <div className={`flex items-center`}>Description</div>
                </th>

                {props.breakPoint || (props.showSummaryLines && props.hideQuantity) ? (
                  <> </>
                ) : (
                  <>
                    <th align="right" className={`px-1 py-[2px] text-sm font-medium`}>
                      Unit $
                    </th>
                    <th align="right" className={`px-1 py-[2px] text-sm font-medium`}>
                      Qty
                    </th>
                  </>
                )}
                <th align="right" className={`px-1 py-[2px] text-sm font-medium`}>
                  Line Total
                </th>
              </tr>
            </thead>
            <tbody>
              {props.txnLine?.map(tl => {
                return (
                  <tr key={tl.ID} className={`border-[#E1E1E1]`}>
                    {!props.showSummaryLines && (
                      <td
                        width={'15%'}
                        className={`border-b-2 border-[#E1E1E1] p-1 text-sm font-medium text-gray-800 `}
                      >
                        {props.breakPoint && tl?.Code?.length >= 15 ? (
                          <Tooltip
                            open={showTooltip}
                            onClose={() => setShowTooltip(false)}
                            content={<>{tl?.Code}</>}
                            placement="bottom"
                          >
                            <span
                              onClick={() => setShowTooltip(!showTooltip)}
                              className={`${props?.breakPoint && tl?.Code?.length >= 15 ? 'w-24 truncate' : ''}`}
                            >
                              <Link color="secondary">{tl?.Code}</Link>
                            </span>
                          </Tooltip>
                        ) : (
                          <p
                            title={tl?.Code}
                            className={`text-gray-800 ${tl?.Code?.length >= 15 ? 'w-24 truncate' : ''}`}
                          >
                            {tl?.Code}
                          </p>
                        )}
                      </td>
                    )}
                    <td width={'35%'} className={`border-b-2 border-[#E1E1E1] p-1 text-sm font-medium text-gray-800`}>
                      {tl?.Description}
                      {props.breakPoint && (
                        <span>
                          - {tl?.Quantity} x {priceFormat(tl?.SellPriceEx)}
                        </span>
                      )}
                    </td>
                    {props.breakPoint || (props.showSummaryLines && props.hideQuantity) ? (
                      <></>
                    ) : (
                      <>
                        <td
                          width={'15%'}
                          align="right"
                          className={`border-b-2 border-[#E1E1E1] p-1 text-sm font-medium text-gray-800`}
                        >
                          {priceFormat(tl?.SellPriceEx)}
                        </td>
                        <td
                          width={'15%'}
                          align="right"
                          className={`border-b-2 border-[#E1E1E1] p-1 text-sm font-medium text-gray-800`}
                        >
                          {tl?.Quantity}
                        </td>
                      </>
                    )}
                    <td
                      width={'15%'}
                      align="right"
                      className={`border-b-2 border-[#E1E1E1] p-1 text-sm font-medium text-gray-800`}
                    >
                      {priceFormat(tl?.LineTotalEx)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <table className="mb-5 w-full">
            <tbody>
              <tr>
                <td rowSpan={5} width="70%" className="align-text-top"></td>
                <APCustomTableData
                  tableHead="Sub total"
                  tableCell={`${priceFormat(props.tx?.TotalEx) || <Skeleton width={`3rem`} />}`}
                />
              </tr>
              <tr>
                <APCustomTableData
                  tableHead="GST"
                  tableCell={`${priceFormat(props.tx?.TotalTax) || <Skeleton width={`3rem`} />}`}
                />
              </tr>
              <tr>
                <APCustomTableData
                  tableHead="Total"
                  tableCell={`${priceFormat(props.tx?.TotalInc) || <Skeleton width={`3rem`} />}`}
                  className="bg-[#E1E1E1] !font-bold "
                />
              </tr>
              <div className="flex justify-between">
                {appliedToInvoice?.length <= 0 ? (
                  <PaymentSummary
                    payments={appliedToInvoice}
                    isCreditNote={true}
                    showCreditsApplied
                    showAmountFieldOnPaymentSummaryPopover={props.showAmountFieldOnPaymentSummaryPopover}
                  ></PaymentSummary>
                ) : (
                  ''
                )}
              </div>
              <div className="flex justify-between">
                {successfulPayments?.length <= 0 ? (
                  <PaymentSummary
                    payments={successfulPayments}
                    showAmountFieldOnPaymentSummaryPopover={props.showAmountFieldOnPaymentSummaryPopover}
                  ></PaymentSummary>
                ) : (
                  ''
                )}
              </div>
              <div className="flex justify-between">
                {appliedCreditNotes?.length <= 0 ? (
                  <PaymentSummary
                    payments={appliedCreditNotes}
                    showCreditsApplied
                    showAmountFieldOnPaymentSummaryPopover={props.showAmountFieldOnPaymentSummaryPopover}
                  ></PaymentSummary>
                ) : (
                  ''
                )}
              </div>
              <tr>
                <APCustomTableData
                  tableHead="Balance"
                  tableCell={`${priceFormat(props.tx?.Balance) || <Skeleton width={`3rem`} />}`}
                  className="bg-[#E1E1E1] !font-bold"
                />
              </tr>
            </tbody>
          </table>
        </>
      ) : (
        <>
          <div className={`items-start border-b bg-spenda-dashbg py-1 ${gridLayout}`}>
            <div className="px-1">
              <p className="tracking-wide">Code</p>
            </div>

            <div className="px-1">
              <p className="tracking-wide">Description</p>
            </div>

            {props.breakPoint ? (
              <> </>
            ) : (
              <>
                <div className="px-1 text-right">
                  <p className="tracking-wide">Unit $</p>
                </div>

                <div className="px-1 text-right">
                  <p>
                    <span className="block tracking-wide">Qty</span>
                  </p>
                </div>
              </>
            )}
            <div className="pl-1 pr-2 text-right">
              <p>
                <span className="block tracking-wide">Line Total</span>
              </p>
            </div>
          </div>
          {props.txnLine?.map(tl => (
            <div key={tl.ID} className={`border-b py-1 ${gridLayout} `}>
              <div className="px-1">
                {props.breakPoint && tl?.Code?.length >= 15 ? (
                  <STooltip
                    open={showTooltip}
                    onOpen={() => setShowTooltip(true)}
                    onClose={() => setShowTooltip(false)}
                    title={<>{tl?.Code}</>}
                    placement="bottom"
                  >
                    <div
                      onClick={() => setShowTooltip(!showTooltip)}
                      className={`${props?.breakPoint && tl?.Code?.length >= 15 ? 'w-24 truncate' : ''}`}
                    >
                      <Link color="secondary">{tl?.Code}</Link>
                    </div>
                  </STooltip>
                ) : (
                  <p title={tl?.Code} className={`text-gray-800 ${tl?.Code?.length >= 15 ? 'w-24 truncate' : ''}`}>
                    {tl?.Code}
                  </p>
                )}
              </div>
              <div className="px-1">
                <p className="text-gray-800">
                  {tl?.Description}
                  {props.breakPoint && (
                    <span>
                      - {tl?.Quantity} x {priceFormat(tl?.SellPriceEx)}
                    </span>
                  )}
                </p>
              </div>

              {props.breakPoint ? (
                <></>
              ) : (
                <>
                  <div className="px-1 text-right">
                    <p className="text-gray-800">{priceFormat(tl?.SellPriceEx)}</p>
                  </div>

                  <div className="px-1 text-right">
                    <p className="text-gray-800">{tl?.Quantity}</p>
                  </div>
                </>
              )}

              <div className="pl-1 pr-2 text-right">
                <p className="text-gray-800">{priceFormat(tl?.LineTotalEx)}</p>
              </div>
            </div>
          )) || <Skeleton width={'100%'} />}

          <div className={`ml-auto py-2 ${props.breakPoint ? 'w-1/2' : 'w-1/4'}`}>
            <div className="mb-1 flex justify-between">
              <div className=" flex-1 text-right">Sub Total</div>
              <div className="w-24 pr-2 text-right">
                <div>${priceFormat(props.tx?.TotalEx) || <Skeleton width={`3rem`} />}</div>
              </div>
            </div>
            <hr />
            <div className={`my-2 flex justify-between`}>
              <div className="flex-1 text-right">GST</div>
              <div className="w-24 pr-2 text-right">
                <div>${priceFormat(props.tx?.TotalTax) || <Skeleton width={`3rem`} />}</div>
              </div>
            </div>

            <div className={`border-b border-t bg-spenda-dashbg py-1 `}>
              <div className="flex justify-between">
                <div className={`flex-1 text-right text-lg font-bold`}>Total</div>
                <div className="w-24 pr-2 text-right">
                  <div className={` text-lg font-bold`}>
                    ${priceFormat(props.tx?.TotalInc) || <Skeleton width={`3rem`} />}
                  </div>
                </div>
              </div>
            </div>
            {
              <>
                <div className="flex justify-between">
                  {appliedToInvoice?.length ? (
                    <PaymentSummary
                      payments={appliedToInvoice}
                      isCreditNote={true}
                      showCreditsApplied
                      showAmountFieldOnPaymentSummaryPopover={props.showAmountFieldOnPaymentSummaryPopover}
                    ></PaymentSummary>
                  ) : (
                    ''
                  )}
                </div>
                <div className="flex justify-between">
                  {successfulPayments?.length ? (
                    <PaymentSummary
                      payments={successfulPayments}
                      showAmountFieldOnPaymentSummaryPopover={props.showAmountFieldOnPaymentSummaryPopover}
                    ></PaymentSummary>
                  ) : (
                    ''
                  )}
                </div>
                <div className="flex justify-between">
                  {appliedCreditNotes?.length ? (
                    <PaymentSummary
                      payments={appliedCreditNotes}
                      showCreditsApplied
                      showAmountFieldOnPaymentSummaryPopover={props.showAmountFieldOnPaymentSummaryPopover}
                    ></PaymentSummary>
                  ) : (
                    ''
                  )}
                </div>
                <div className={`mt-1 border-b border-t bg-spenda-dashbg py-1`}>
                  <div className="flex justify-between">
                    <div className={`flex-1 text-right text-lg font-bold`}>Balance</div>
                    <div className="w-24 pr-2 text-right">
                      <div className={`text-lg font-bold`}>
                        ${priceFormat(props.tx?.Balance) || <Skeleton width={`3rem`} />}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            }
          </div>
        </>
      )}
    </main>
  );
};
