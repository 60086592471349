import React, {useState} from 'react';
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Checkbox,
  Chip,
  Dialog,
  DialogBody,
  SearchInput,
  Typography,
} from 'spenda-ui-react';
import {IconArrowDown} from '../../assets/svg/IconArrowDown';
import moment, {Moment} from 'moment';
import ARDatepicker from '../AccountsReceivable/ARDatepicker';
import {pascalToSentence} from '../../utils/formatter';

interface IFilterOption {
  id: string | number;
  name: string;
}

interface IFilterSection {
  title: string;
  options: IFilterOption[];
  searchPlaceholder: string;
  searchKey: string;
  filterKey: string;
}

interface IAdvanceFilterProps {
  showModal: boolean;
  setOpen: (open: boolean) => void;
  onSave: (filters: Record<string, any>, isFetchList: boolean) => void;
  onClear: (isFetchList: boolean) => void;
  filterSections: IFilterSection[];
  selectedFilters: Record<string, any>;
  dateButtons?: string[];
}

const AdvanceFilter: React.FC<IAdvanceFilterProps> = (props: IAdvanceFilterProps) => {
  // Props
  const {showModal, setOpen, onSave, onClear, filterSections, selectedFilters, dateButtons} = props;

  // State
  const [expand, setExpand] = useState(0);
  const [filters, setFilters] = useState<Record<string, any>>(selectedFilters);
  const [startDate, setStartDate] = useState<Moment | null>(
    selectedFilters.startDate ? moment(selectedFilters.startDate) : null,
  );
  const [endDate, setEndDate] = useState<Moment | null>(
    selectedFilters.endDate ? moment(selectedFilters.endDate) : null,
  );
  const [viewAllList, setViewAllList] = useState<Record<string, boolean>>({});
  const [searchInputs, setSearchInputs] = useState<Record<string, string>>({});

  // Constant
  const isStartDateChanged = filters?.startDate !== startDate;
  const isEndDateChanged = filters?.endDate !== endDate;
  const isRefetchList = isStartDateChanged || isEndDateChanged;
  const startOfMonth = moment().startOf('month');
  const endOfMonth = moment().endOf('month');
  const isThisMonthSelected = startDate?.isSame(startOfMonth, 'day') && endDate?.isSame(endOfMonth, 'day');
  const isLastMonthSelected =
    startDate?.isSame(moment().subtract(1, 'months').startOf('month'), 'day') &&
    endDate?.isSame(moment().subtract(1, 'months').endOf('month'), 'day');
  const startOfWeek = moment().startOf('week');
  const endOfWeek = moment().endOf('week');
  const isThisWeekSelected = startDate?.isSame(startOfWeek, 'day') && endDate?.isSame(endOfWeek, 'day');
  const isLastWeekSelected =
    startDate?.isSame(moment().subtract(1, 'week').startOf('week'), 'day') &&
    endDate?.isSame(moment().subtract(1, 'week').endOf('week'), 'day');

  const toggleAccordion = (value: React.SetStateAction<number>) => setExpand(expand === value ? 0 : value);

  const handleDatesChange = ({startDate, endDate}: {startDate: Moment | null; endDate: Moment | null}) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handleThisMonthClick = () => {
    const startOfMonth = moment().startOf('month');
    const endOfMonth = moment().endOf('month');
    setStartDate(startOfMonth);
    setEndDate(endOfMonth);
  };

  const handleLastMonthClick = () => {
    const startOfLastMonth = moment().subtract(1, 'months').startOf('month');
    const endOfLastMonth = moment().subtract(1, 'months').endOf('month');
    setStartDate(startOfLastMonth);
    setEndDate(endOfLastMonth);
  };

  const handleThisWeekClick = () => {
    const startOfWeek = moment().startOf('week');
    const endOfWeek = moment().endOf('week');
    setStartDate(startOfWeek);
    setEndDate(endOfWeek);
  };

  const handleLastWeekClick = () => {
    const startOfLastWeek = moment().subtract(1, 'weeks').startOf('week');
    const endOfLastWeek = moment().subtract(1, 'weeks').endOf('week');
    setStartDate(startOfLastWeek);
    setEndDate(endOfLastWeek);
  };

  const handleSaveView = () => {
    const updatedFilters = {
      ...filters,
      startDate: isStartDateChanged ? startDate : filters.startDate,
      endDate: isEndDateChanged ? endDate : filters?.endDate,
    };
    onSave(updatedFilters, isRefetchList);
  };

  const handleClearFilters = () => {
    onClear(isRefetchList);
  };

  const handleSearchInputChange = (key: string, value: string) => {
    setSearchInputs(prev => ({...prev, [key]: value}));
  };

  const handleFilterChange = (filterKey: string, option: IFilterOption, isChecked: boolean) => {
    const currentFilterOptions = filters[filterKey] || [];
    const updatedFilters = isChecked
      ? [...currentFilterOptions, option]
      : currentFilterOptions.filter((item: IFilterOption) => item.id !== option.id);
    setFilters(prev => ({...prev, [filterKey]: updatedFilters}));
  };

  const renderDateButton = (buttonType: string) => {
    switch (buttonType) {
      case 'This month':
        return (
          <div key="btnThisMonth" onClick={handleThisMonthClick}>
            <Chip
              data-autoid="btnThisMonth"
              size="md"
              value="This month"
              color="primary"
              className={`cursor-pointer ${isThisMonthSelected ? 'border border-primary' : ''}`}
            />
          </div>
        );
      case 'Last month':
        return (
          <div key="btnLastMonth" onClick={handleLastMonthClick}>
            <Chip
              data-autoid="btnLastMonth"
              size="md"
              value="Last month"
              color="primary"
              className={`cursor-pointer ${isLastMonthSelected ? 'border border-primary' : ''}`}
            />
          </div>
        );
      case 'This week':
        return (
          <div key="btnThisWeek" onClick={handleThisWeekClick}>
            <Chip
              data-autoid="btnThisWeek"
              size="md"
              value="This week"
              color="primary"
              className={`cursor-pointer ${isThisWeekSelected ? 'border border-primary' : ''}`}
            />
          </div>
        );
      case 'Last week':
        return (
          <div key="btnLastWeek" onClick={handleLastWeekClick}>
            <Chip
              data-autoid="btnLastWeek"
              size="md"
              value="Last week"
              color="primary"
              className={`cursor-pointer ${isLastWeekSelected ? 'border border-primary' : ''}`}
            />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Dialog open={showModal} handler={() => setOpen(!showModal)} className="min-h-[530px] !w-[286px] !min-w-[286px]">
      <DialogBody className="!px-0 !py-2.5">
        <>
          <div className="flex w-full flex-col">
            <div className="item-center mx-2.5 flex flex-row justify-between border-b border-b-[#ECECEC] pb-2.5">
              <Typography variant="paragraph" className="font-medium text-black-900">
                Filter transactions
              </Typography>
              <div className="flex flex-row items-center justify-between gap-x-4">
                <span
                  data-autoid="btnClearAll"
                  className="cursor-pointer font-poppins text-[10px] font-medium text-primary"
                  onClick={handleClearFilters}
                >
                  Clear All
                </span>
                <span
                  data-autoid="btnSaveView"
                  className="cursor-pointer font-poppins text-[10px] font-medium text-primary"
                  onClick={handleSaveView}
                >
                  Save View
                </span>
              </div>
            </div>
            <input type="text" className="h-0 w-0" />
            {(dateButtons?.length || 0) > 0 && (
              <div className="item-center mx-2.5 flex flex-row justify-center gap-x-2.5 py-2.5">
                {dateButtons?.map(buttonType => {
                  return renderDateButton(buttonType);
                })}
              </div>
            )}
            <div className={`relative flex w-full justify-center p-2.5`}>
              <ARDatepicker
                regular
                inputIconPosition="after"
                startDate={startDate}
                endDate={endDate}
                onDatesChange={handleDatesChange}
                isOutsideRange={() => false}
              />
            </div>
            <div className="mb-2.5 flex h-[inherit] w-full flex-col overflow-y-auto">
              {filterSections.map((section, index) => {
                const filteredList = section.options.filter(option =>
                  option.name.toLowerCase().includes(searchInputs[section.searchKey]?.toLowerCase() || ''),
                );
                const displayedList =
                  (filteredList.length || 0) > 4 && !viewAllList[section.filterKey]
                    ? filteredList.slice(0, 4)
                    : filteredList;
                return (
                  <Accordion
                    key={section.filterKey}
                    className={`w-full gap-y-2.5 bg-white`}
                    open={expand === index + 1}
                    icon={
                      <IconArrowDown
                        className={`cursor-pointer ${expand === index + 1 ? 'rotate-180' : ''}`}
                        name={expand === index + 1 ? 'Minimize' : 'Maximize'}
                      />
                    }
                  >
                    <AccordionHeader
                      className={`w-full cursor-default p-0 px-2.5`}
                      aria-controls={`panel${index + 1}a-content`}
                      id={`panel${index + 1}a-header`}
                      onClick={() => toggleAccordion(index + 1)}
                      data-autoid={`btnFilter${section.filterKey}`}
                    >
                      <div
                        className={`box-border flex h-[40px] min-h-[40px] w-full flex-row items-center justify-between py-2 pr-2`}
                      >
                        <Typography variant="h3" className={`text-sm font-normal text-black-800`}>
                          {section.title}
                        </Typography>
                      </div>
                    </AccordionHeader>
                    <AccordionBody className=" !bg-[#F6F6F6] px-0 py-2.5">
                      <div className="mb-2 bg-[#fff] px-2.5">
                        <SearchInput
                          value={searchInputs[section.searchKey]}
                          onChange={e => handleSearchInputChange(section.searchKey, e.target.value)}
                          clearIcon
                          clearIconProps={{
                            onClick: () => handleSearchInputChange(section.searchKey, ''),
                          }}
                          autoFocus
                          expanded
                          placeholder={section.searchPlaceholder}
                          reverse={true}
                          data-autoid={`btnSearch${section.filterKey}`}
                        />
                      </div>
                      <div className="flex !max-h-[180px] flex-col overflow-y-auto">
                        {displayedList.map(option => (
                          <Checkbox
                            key={option.id}
                            data-autoid={`chk-${section.filterKey}${option.id}`}
                            label={pascalToSentence(option.name || '')}
                            id={`${option.id}`}
                            ripple={false}
                            name={`${option.id}`}
                            checked={filters[section.filterKey]?.some((item: IFilterOption) => item.id === option.id)}
                            onChange={e => handleFilterChange(section.filterKey, option, e.target.checked)}
                            value={filters[section.filterKey]?.some((item: IFilterOption) => item.id === option.id)}
                            containerProps={{className: ' !py-2'}}
                            className="h-4 w-4 border-primary bg-white transition-all checked:border-primary checked:bg-primary hover:before:opacity-0"
                          />
                        ))}
                        {(filteredList.length || 0) > 4 && (
                          <div className="w-full">
                            <Typography
                              className="ml-2.5 mt-2 cursor-pointer font-poppins text-[10px] font-medium text-primary"
                              variant="paragraph"
                              onClick={() =>
                                setViewAllList(prev => ({
                                  ...prev,
                                  [section.filterKey]: !viewAllList[section.filterKey],
                                }))
                              }
                            >
                              {viewAllList[section.filterKey] ? 'View less...' : 'View all...'}
                            </Typography>
                          </div>
                        )}
                      </div>
                    </AccordionBody>
                  </Accordion>
                );
              })}
            </div>
          </div>
        </>
      </DialogBody>
    </Dialog>
  );
};

export default AdvanceFilter;
