import useHttp from '../hooks/useHttp';
import {IActionResultsList, IActionResults} from '../model/ActionResults';
import {IPlaceAutoCompleteResult} from '../model/address/place-prediction';
import {IAddress} from '../model/address/Address';

export const useLocationAPI = () => {
  const {GET, isLoading} = useHttp();

  const getLocationBySearch = (Search: string): Promise<IPlaceAutoCompleteResult[]> => {
    return GET(`Places/Search?term=${Search || ' '}&location=0,0`).then(
      (response: IActionResultsList<IPlaceAutoCompleteResult>) => response.Items
    );
  };

  const getLocationAddress = (PlaceID: string): Promise<IAddress> => {
    return GET(`Places/Get?placeId=${PlaceID}`).then((response: IActionResults<IAddress>) => response.Value);
  };

  return {
    getLocationBySearch,
    getLocationAddress,
    isLoading,
  };
};
