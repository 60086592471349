import React from 'react';
import {Layout} from '../../components/layout/Layout';
import {WidgetPanel} from './WidgetPanel/WidgetPanel';
import {TableView} from './TableView/TableView';
import {TransactionDetailWidget} from './WidgetPanel/TransactionDetailWidget';

export const MerchantPortal = () => {
  const getLeftPanel = () => {
    if (true) {
      return (
        <div className="relative flex h-full !w-full flex-col items-start justify-center rounded-[8px] ">
          <TableView />
        </div>
      );
    } else {
      return '';
    }
  };

  const getWigetPanel = () => {
    if (true) {
      return (
        <div className="relative flex h-full !w-full items-start justify-center rounded-[8px] bg-white">
          <WidgetPanel />
        </div>
      );
    } else {
      return (
        <div className="relative flex h-full !w-full items-start justify-center rounded-[8px] bg-white">
          <TransactionDetailWidget />
        </div>
      );
    }
  };

  return (
    <>
      <Layout leftPanel={getLeftPanel()} mainPanel={getWigetPanel()} splitWidthType={'MP'} />
    </>
  );
};
