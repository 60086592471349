import React, {useRef} from 'react';
import {Input} from 'spenda-ui-react';
import {AirPlusdbiFieldAbbreviations, IDbifield, IDbiValues} from '../../model/payment/AirPlusDBI';
import SDatePicker from '../pickers/DatePicker';
import moment from 'moment';
import {PaymentWidgetScope} from '../../model/constants/Constants';

interface DBIConfigProps {
  configuration: IDbifield;
  dbiValue: IDbiValues;
  index: number;
  handleChange: (value: string, index: number, bluredOnce: boolean) => void;
  handleBlur: (index: number) => void;
  widgetScope?: PaymentWidgetScope;
}

const getInputFiledType = (type: string) => {
  switch (type) {
    case 'X':
      return 'text';
    case 'A':
      return 'text';
    case 'N':
      return 'number';
    case 'D':
      return 'date';
    default:
      return 'text';
  }
};

const defaultDbiFieldNames = [
  {
    code: 'AU',
    scope: PaymentWidgetScope.ACCOUNT_PAYABLE,
    name: 'Batch Ref',
  },
  {
    code: 'IK',
    scope: PaymentWidgetScope.ACCOUNT_PAYABLE,
    name: 'Batch Name',
  },
  {
    code: 'AU',
    scope: PaymentWidgetScope.PSBL,
    name: 'Batch Ref',
  },
  {
    code: 'IK',
    scope: PaymentWidgetScope.PSBL,
    name: 'Description',
  },
  {
    code: 'AU',
    scope: PaymentWidgetScope.PIBL,
    name: 'Invoice Ref',
  },
  {
    code: 'IK',
    scope: PaymentWidgetScope.PIBL,
    name: 'Description',
  },
  {
    code: 'AU',
    scope: PaymentWidgetScope.PREPAYMENT,
    name: 'Payment Date',
  },
  {
    code: 'IK',
    scope: PaymentWidgetScope.PREPAYMENT,
    name: 'Description',
  },
];

export const DBIConfigField = (props: DBIConfigProps) => {
  const {configuration, dbiValue, index, handleChange, handleBlur, widgetScope} = props;
  const {dbiCode} = configuration;
  const bluredOnce = useRef(false);

  const onBlur = () => {
    if (bluredOnce.current === false) {
      bluredOnce.current = true;
    }
    handleBlur(index);
  };

  const getDbiFieldLabel = () => {
    return (
      defaultDbiFieldNames.find(f => f.code === dbiCode && f.scope === widgetScope)?.name ??
      AirPlusdbiFieldAbbreviations[dbiCode]
    );
  };

  return (
    <div className="mb-4">
      {dbiValue.type !== 'D' ? (
        <Input
          id={dbiCode}
          name={dbiCode}
          label={getDbiFieldLabel()}
          type={getInputFiledType(configuration.type)}
          value={dbiValue.value}
          error={Boolean(dbiValue.error)}
          onChange={e => handleChange(e.target.value, index, bluredOnce.current)}
          onBlur={onBlur}
          helperText={dbiValue.error}
          data-autoid={`txt${dbiCode}`}
          readOnly={
            ['AU', 'IK'].includes(dbiCode) &&
            [
              PaymentWidgetScope.PIBL,
              PaymentWidgetScope.PSBL,
              PaymentWidgetScope.ACCOUNT_PAYABLE,
              PaymentWidgetScope.PREPAYMENT,
            ].includes(widgetScope as PaymentWidgetScope)
          }
        />
      ) : (
        <SDatePicker
          v2
          inputVariant="outlined"
          format="dd/MM/yyyy"
          autoOk
          fullWidth
          variant="inline"
          id="scheduledDate"
          label={AirPlusdbiFieldAbbreviations[dbiCode]}
          name="scheduledDate"
          value={dbiValue.value || null}
          onBlur={onBlur}
          onChange={date => {
            handleChange(moment(date).format('DDMMMYY').toUpperCase(), index, bluredOnce.current);
          }}
          className="w-full text-left"
          invalidDateMessage="Please enter correct date"
          inputProps={{
            'data-autoid': 'dtpPaymentScheduledDate',
          }}
          KeyboardButtonProps={
            {
              'aria-label': 'change date',
              'data-autoid': 'dtpPaymentScheduledDateIcon',
            } as any
          }
          error={Boolean(dbiValue.error)}
          helperText={dbiValue.error}
        />
      )}
    </div>
  );
};
