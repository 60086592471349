import { useEffect, useRef } from 'react';
import {HubConnectionBuilder, HubConnection, HubConnectionState} from '@microsoft/signalr';
import { useAuth0 } from '@auth0/auth0-react';

let hubConnection: HubConnection | null = null;

const createHubConnection = (hubUrl: string, getAccessTokenSilently: () => string | Promise<string>) => {
  
  if (!hubConnection) {
    hubConnection = new HubConnectionBuilder()
      .withUrl(hubUrl, {
        accessTokenFactory: getAccessTokenSilently
      })
      .build();
  }
  return hubConnection;
};

export const useSignalR = (endpoint: string): HubConnection | null => {
  
  const { getAccessTokenSilently } = useAuth0()  
  const connection = useRef<HubConnection | null>(null);

  useEffect(() => {

    const connectToHub = async () => {
      try {
        connection.current = createHubConnection(endpoint, getAccessTokenSilently);

        if (connection.current.state === HubConnectionState.Disconnected) {
          await connection.current.start();
          
        }
      } catch (error) {
        console.error('Error connecting to SignalR:', error);
      }
    };

    connectToHub();

    return () => {
      if (connection.current) {
        connection.current.stop().then(() => console.warn('SignalR disconnected')).catch((error) => console.error('Error disconnecting SignalR:', error));
      }
    }

  }, [endpoint, getAccessTokenSilently]);

  return connection.current;
}