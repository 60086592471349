import React from 'react';
import {useHistory, Link} from 'react-router-dom';
import moment from 'moment';
import clsx from 'clsx';

import {AUTH_PURCHASING_PURCHASE_ORDER_DETAIL, AUTH_PURCHASING_REQUISITION_DETAIL} from '../../routes/PurchasingRoutes';
import {PurchaseOrderStatus} from '../../model/constants/Constants';
import {IPurchaseOrder} from '../../model/purchase-order/PurchaseOrder';
import {STable} from '../tables/STable';
import {PriceFormat} from '../../utils/formatter';
import {Toast} from '../../utils/Toast';

interface IPurchaseOrderList {
  items: IPurchaseOrder[];
  loading: boolean;
}

const statusRenderer = (_status: PurchaseOrderStatus) => {
  let style = '';
  let status = '';
  switch (_status) {
    case PurchaseOrderStatus.Open:
      style = 'bg-[#C68A1920] text-[#C68A19]';
      status = 'Not sent';
      break;
    case PurchaseOrderStatus.Sent:
      style = 'bg-[#8178CF20] text-[#8178CF]';
      status = 'Sent';
      break;
    case PurchaseOrderStatus.Sent_as_Unlinked:
      style = 'bg-[#8178CF20] text-[#8178CF]';
      status = 'Sent';
      break;
    case PurchaseOrderStatus.Review:
      style = 'bg-[#8819A820] text-[#9D5CB3]';
      status = 'Review';
      break;
    case PurchaseOrderStatus.Approved:
      style = 'bg-[#8819A820] text-[#8819A8]';
      status = 'Completed';
      break;
    case PurchaseOrderStatus.Cancelled:
      style = 'bg-[#C55D4420] text-[#C55D44]';
      status = 'Cancelled';
      break;
    default:
      status = _status;
      break;
  }
  return (
    <span className={clsx('mx-6 block whitespace-nowrap rounded px-6 py-1 font-poppins text-xs font-medium', style)}>
      {status}
    </span>
  );
};

const columnSetting = {
  RefNumber: {
    title: 'PO ref',
    field: 'RefNumber',
  },
  Name: {
    title: 'Purchase Reason',
    field: 'Name',
  },
  DueDate: {
    title: 'Due date',
    field: 'DueDate',
    align: 'center',
    render: (rowData: IPurchaseOrder) => moment(rowData.DueDate).format('Do MMM YYYY'),
  },
  CreatedByUserName: {title: 'Created by', field: 'CreatedByUserName', aligin: 'center'},
  LineCount: {
    title: 'Total lines',
    field: 'TotalLines',
    align: 'center',
  },
  TotalEx: {
    title: 'Est. cost (ex)',
    field: 'TotalEx',
    align: 'right',
    render: (rowData: IPurchaseOrder) => PriceFormat(rowData.TotalEx),
  },
  LinkedRequisition: {
    title: 'Linked requisition',
    field: 'LinkedRequisition',
    align: 'right',
    render: (rowData: IPurchaseOrder) => (
      <Link
        onClick={e => e.stopPropagation()}
        className="text-primary underline"
        to={AUTH_PURCHASING_REQUISITION_DETAIL.replace(/:requisitionID/g, String(rowData.RequisitionID))}
      >
        {rowData.RequisitionRefNumber}
      </Link>
    ),
  },
  Status: {
    title: 'Order Status',
    field: 'Status',
    align: 'center',
    width: '235px',
    render: (rowData: IPurchaseOrder) => statusRenderer(rowData.Status as PurchaseOrderStatus),
  },
} as {[key: string]: any};

export const PurchaseOrderList: React.FC<IPurchaseOrderList> = ({items, loading}) => {
  const history = useHistory();

  const handleRowClick = async (rowData: IPurchaseOrder & {PurchaseOrderID: any}) => {
    if (rowData.RequisitionID) {
      history.push(
        AUTH_PURCHASING_PURCHASE_ORDER_DETAIL.replace(/:requisitionID/g, String(rowData.RequisitionID || '')).replace(
          /:purchaseOrderID/g,
          String(rowData.PurchaseOrderID || ''),
        ),
      );
    } else {
      Toast.error('Linked requisition not found.');
    }
  };

  return (
    <STable
      newUI={true}
      columns={Object.entries(columnSetting)
        .map(([, value]: [string, any]) => value)
        .filter(value => value)}
      data={items}
      emptyDataMessage={'No requisition found.'}
      stickyHeader={true}
      loading={loading}
      onRowClick={handleRowClick}
    ></STable>
  );
};
