import React, {useMemo} from 'react';
import {ClaimActions, ClaimReasons} from '../../../model/constants/Constants';
import {IClaimsReasonResponse} from '../../../model/claims/ClaimsAndReturns';
import {Select, Option, SelectProps} from 'spenda-ui-react';
import clsx from 'clsx';

interface IClaimsOption {
  option: string;
  disabled: boolean;
  isChildOption: boolean;
}
interface IClaimsAndReturnActionReasonDropdownProps {
  dataAutoId: string;
  showSubReason?: boolean;
  reasonDropdownOptions?: IClaimsReasonResponse[];
  claimAndReturnsV272602?: boolean;
  value?: string;
  type: 'reason' | 'action';
  className?: string;
  onChange?: (e: any) => void;
}

const ClaimsAndReturnActionReactionSelect = (
  props: IClaimsAndReturnActionReasonDropdownProps & Omit<SelectProps, 'value' | 'ref' | 'children' | 'onChange'>,
) => {
  const {
    dataAutoId,
    showSubReason,
    reasonDropdownOptions,
    claimAndReturnsV272602,
    value,
    type,
    className,
    onChange,
    ...restProps
  } = props;

  const options = useMemo(() => {
    if (type === 'reason') {
      if (claimAndReturnsV272602) {
        const actionOptions = reasonDropdownOptions?.reduce((acc: IClaimsOption[], r) => {
          const reasonName = r?.parentReason?.reason;
          const isDisable = r?.subReasons?.length && showSubReason ? true : false;
          acc.push({option: reasonName, disabled: isDisable, isChildOption: false});
          if (isDisable) {
            r?.subReasons?.forEach(s => {
              acc?.push({option: s?.reason, disabled: false, isChildOption: true});
            });
          }
          return acc;
        }, []);
        return actionOptions;
      } else {
        return Object.entries(ClaimReasons).map(([k, _v]) => ({
          option: k?.replace(/([A-Z])/g, ' $1').trim(),
          disabled: false,
          isChildOption: false,
        }));
      }
    } else if (type === 'action') {
      return Object.entries(ClaimActions).map(([_k, v]) => ({option: v, disabled: false, isChildOption: false}));
    }
  }, [claimAndReturnsV272602, reasonDropdownOptions, showSubReason, type]);

  return (
    <div className={`flex min-w-[185px] flex-col ${className}`}>
      <Select
        size="md"
        variant="outlined"
        data-autoid={`${dataAutoId}`}
        containerProps={{className: 'min-w-[185px]'}}
        labelProps={{className: 'font-poppins text-black-800'}}
        value={value}
        {...restProps}
        onChange={v => {
          const e = {target: {name: restProps?.name, value: v}};
          onChange?.(e);
        }}
      >
        {options?.length ? (
          options?.map(o => (
            <Option
              className={clsx(o?.isChildOption && 'pl-8')}
              value={o?.option}
              key={o?.option}
              disabled={o?.disabled}
            >
              {o?.option}
            </Option>
          ))
        ) : (
          <></>
        )}
      </Select>
    </div>
  );
};

export const ClaimsAndReturnActionReasonDropdown = React.memo(ClaimsAndReturnActionReactionSelect);
