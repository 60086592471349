import React from 'react';

export const RightArrowButton = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="34px"
      height="34px"
      viewBox="0 0 34 34"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <g id="Main-File" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Spenda-Collect-Supplier-Interface-Copy-21" transform="translate(-1376.000000, -310.000000)">
          <g id="Group-10" transform="translate(1376.000000, 310.000000)">
            <circle id="Oval" fillOpacity="0.503250656" fill="#D3E5EF" cx="17" cy="17" r="17"></circle>
            <g id="Icons/Arrows/arrow_small_right" transform="translate(10.073828, 11.050000)" fill="#1C78AD">
              <path
                d="M13.8833333,5.94086942 L13.8833333,5.94086942 C13.8785073,5.41914884 13.6682961,4.92034252 13.29825,4.55253609 L9.044,0.288369424 C8.65721286,-0.0961231412 8.03253714,-0.0961231412 7.64575,0.288369424 C7.45801495,0.474571302 7.35241666,0.7280372 7.35241666,0.992452757 C7.35241666,1.25686831 7.45801495,1.51033421 7.64575,1.69653609 L10.9083333,4.94920276 L0.991666667,4.94920276 C0.44398429,4.94920276 -1.77635684e-15,5.39318705 -1.77635684e-15,5.94086942 C-1.77635684e-15,6.4885518 0.44398429,6.93253609 0.991666667,6.93253609 L10.9083333,6.93253609 L7.64575,10.1951194 C7.25689551,10.5812355 7.25467559,11.2094733 7.64079167,11.5983278 C8.02690774,11.9871822 8.65514551,11.9894022 9.044,11.6032861 L13.29825,7.33911942 C13.6707079,6.96888039 13.8811192,6.46603313 13.8833333,5.94086942 L13.8833333,5.94086942 Z"
                id="Path"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
