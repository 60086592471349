import React from 'react';
import {Switch} from 'react-router-dom';
import {PrivateRoute} from './PrivateRoute';
import {FeatureFlagKeys} from '../hooks/useFeatureFlags';
import APOnboarding from '../screens/purchasing/AccountsPayableOnboarding';
import {MenuTypes, ModuleTypes} from '../model/constants/Constants';
import {PurchaseInvoiceList} from '../screens/purchase-invoice/PurchaseInvoiceList';
import {PurchaseInvoiceDetail} from '../screens/purchase-invoice/PurchaseInvoiceDetail';
import {PurchaseOrderDashboard} from '../screens/purchase-order/PurchaseOrderDashboard';
import {RequisitionDetail} from '../screens/purchase-order/RequisitionDetail';
import {DeliveryReceiptDetail} from '../screens/purchase-order/DeliveryReceiptDetail';
import {PurchaseOrderDetail} from '../screens/purchase-order/PurchaseOrderDetail';

// Purchasing Routes
export const AUTH_PURCHASING_ONBOARDING_ROUTE = '/purchasing/onboarding';
export const AUTH_PURCHASING_ONBOARDING = '/purchasing/onboarding/:onboardingAccount?';
export const AUTH_PURCHASING_PURCHASE_INVOICE_LIST = '/purchasing/purchase-invoice';
export const AUTH_PURCHASING_PURCHASE_INVOICE_DETAIL = '/purchasing/purchase-invoice/:purchaseInvoiceID';
export const AUTH_PURCHASING_PURCHASE_DASHBOARD_LIST = '/purchasing/purchase-order';
export const AUTH_PURCHASING_REQUISITION_DETAIL = '/purchasing/purchase-order/requisition/:requisitionID';
export const AUTH_PURCHASING_PURCHASE_ORDER_DETAIL = '/purchasing/purchase-order/:requisitionID/:purchaseOrderID';
export const AUTH_PURCHASING_DELIVERY_RECEIPT_DETAIL = '/purchasing/purchase-order/delivery-receipt/:deliveryReceiptID';

export const PurchasingRoutes = () => {
  return (
    <Switch>
      <PrivateRoute
        path={AUTH_PURCHASING_ONBOARDING}
        exact
        Component={APOnboarding}
        MenuFilters={{}}
        featureFlag={FeatureFlagKeys.purchaseInvoicingV1}
      />
      <PrivateRoute
        path={AUTH_PURCHASING_PURCHASE_INVOICE_LIST}
        exact
        moduleIds={[ModuleTypes.SpendaBuy]}
        Component={PurchaseInvoiceList}
        MenuFilters={{
          MenuType: MenuTypes.Buying,
          ModuleID: ModuleTypes.SpendaBuy,
          ModuleDependency: [ModuleTypes.SpendaBuy, ModuleTypes.SpendaPOS],
        }}
        featureFlag={FeatureFlagKeys.purchaseInvoicingV1}
      />
      <PrivateRoute
        path={AUTH_PURCHASING_PURCHASE_INVOICE_DETAIL}
        exact
        moduleIds={[ModuleTypes.SpendaBuy]}
        Component={PurchaseInvoiceDetail}
        MenuFilters={{
          MenuType: MenuTypes.Buying,
          ModuleID: ModuleTypes.SpendaBuy,
          ModuleDependency: [ModuleTypes.SpendaBuy, ModuleTypes.SpendaPOS],
        }}
        featureFlag={FeatureFlagKeys.purchaseInvoicingV1}
      />
      {/* End Purchase Invoice Routes */}
      {/* Purchase Order Routes */}
      <PrivateRoute
        path={AUTH_PURCHASING_PURCHASE_DASHBOARD_LIST}
        exact
        moduleIds={[ModuleTypes.SpendaBuy]}
        Component={PurchaseOrderDashboard}
        MenuFilters={{
          MenuType: MenuTypes.Buying,
          ModuleID: ModuleTypes.SpendaBuy,
          ModuleDependency: [ModuleTypes.SpendaBuy, ModuleTypes.SpendaPOS],
        }}
        featureFlag={FeatureFlagKeys.purchaseRequisitionsV1}
      />
      <PrivateRoute
        path={AUTH_PURCHASING_REQUISITION_DETAIL}
        exact
        moduleIds={[ModuleTypes.SpendaBuy]}
        Component={RequisitionDetail}
        MenuFilters={{
          MenuType: MenuTypes.Buying,
          ModuleID: ModuleTypes.SpendaBuy,
          ModuleDependency: [ModuleTypes.SpendaBuy, ModuleTypes.SpendaPOS],
        }}
        featureFlag={FeatureFlagKeys.purchaseRequisitionsV1}
      />
      <PrivateRoute
        path={AUTH_PURCHASING_DELIVERY_RECEIPT_DETAIL}
        exact
        moduleIds={[ModuleTypes.SpendaBuy]}
        Component={DeliveryReceiptDetail}
        MenuFilters={{
          MenuType: MenuTypes.Buying,
          ModuleID: ModuleTypes.SpendaBuy,
          ModuleDependency: [ModuleTypes.SpendaBuy, ModuleTypes.SpendaPOS],
        }}
        featureFlag={FeatureFlagKeys.purchaseRequisitionsV1}
      />
      <PrivateRoute
        path={AUTH_PURCHASING_PURCHASE_ORDER_DETAIL}
        exact
        moduleIds={[ModuleTypes.SpendaBuy]}
        Component={PurchaseOrderDetail}
        MenuFilters={{
          MenuType: MenuTypes.Buying,
          ModuleID: ModuleTypes.SpendaBuy,
          ModuleDependency: [ModuleTypes.SpendaBuy, ModuleTypes.SpendaPOS],
        }}
        featureFlag={FeatureFlagKeys.purchaseRequisitionsV1}
      />
      {/* End Purchase Order Routes */}
    </Switch>
  );
};
