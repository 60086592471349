import React, {useEffect, useContext} from 'react';
import AppContext from '../../context/app/appContext';
import {useParams, useLocation} from 'react-router-dom';
import {Box} from '@material-ui/core';
import {LogoName} from '../LogoName';

export const Callback = () => {
  const appContext = useContext(AppContext);

  const {servicename} = useParams<{servicename: string}>();

  const query = new URLSearchParams(useLocation().search);

  useEffect(() => {
    const data = {
      ServiceName: servicename,
      Params: query,
    };
    // window or window.parent?
    window.parent.postMessage(data, 'http://localhost:3000');
  }, []);

  return (
    <Box display="flex" position="center" className="bckg-spenda items-center justify-center h-full">
      <Box>
        <Box display="flex" className={`justify-center text-white text-xl`}>
          Processing data from {servicename}
        </Box>
        <Box display="flex" className={`justify-center text-white text-xl`}>
          Please Wait...
        </Box>
      </Box>
      {appContext.marketplaceSupplier && <LogoName isDefaultSpenda={true} className={'spenda-powered'}></LogoName>}
    </Box>
  );
};
