import {IActionRes, IActionResults, IPagedActionResults} from '../model/ActionResults';
import {IRequisitionFilter} from '../model/search-filters/RequisitionSearchFilter';
import {IRequisition, IRequisitionLine} from '../model/requisition/Requisition';
import useHttp from '../hooks/useHttp';
import {useContext, useEffect, useState} from 'react';
import AppContext from '../context/app/appContext';
import {RequisitionStatusType, RequisitionType} from '../model/constants/Constants';

const useRequisitionAPI = () => {
  const {POST, GET, DELETE, isLoading} = useHttp();

  const save = (payload: Partial<IRequisition>): Promise<IRequisition> => {
    return POST('requisition', {Value: payload}).then((data: IActionResults<IRequisition>) => data.Value);
  };

  const deleteRequisition = (requisitionId: number): Promise<boolean> => {
    return DELETE(`requisition/${requisitionId}`).then((data: IActionRes) => data.IsSuccess);
  };

  const get = (requisitionId: number): Promise<IRequisition> => {
    return GET(`requisition/${requisitionId}`).then((data: IActionResults<IRequisition>) => data.Value);
  };

  const search = (filter: IRequisitionFilter): Promise<IPagedActionResults<IRequisition[]>> => {
    return GET('v2/requisition', filter);
  };

  const saveLines = (requisitionId: number, lines: IRequisitionLine[]): Promise<IRequisition> => {
    return POST(`requisition/${requisitionId}/lines`, {Value: {ID: requisitionId, Lines: lines}}).then(
      (data: IActionResults<IRequisition>) => data.Value,
    );
  };

  const updateStatus = (requisitionId: number, status: string): Promise<IRequisition> => {
    return POST(`requisition/${requisitionId}/status`, {Value: {Status: status}}).then(
      (data: IActionResults<IRequisition>) => data.Value,
    );
  };

  const assignUser = async (requisitionId: number, payload: Object): Promise<IRequisition> => {
    return POST(`requisition/${requisitionId}/assign`, {Value: payload}).then(
      (data: IActionResults<IRequisition>) => data.Value,
    );
  };

  return {
    isLoading,
    delete: deleteRequisition,
    get,
    save,
    saveLines,
    search,
    updateStatus,
    assignUser,
  };
};

export const useLoadRequisition = () => {
  const {search} = useRequisitionAPI();
  const {tenantInfo} = useContext(AppContext);

  const defaultWarehouseID = tenantInfo?.TenantUserDetails?.DefaultWarehouseID;

  let pageSize = 15;

  const initialFilterState = {
    Status: [
      RequisitionStatusType.New,
      RequisitionStatusType.Pending,
      RequisitionStatusType.Approved,
      RequisitionStatusType.Processed,
    ],
    RequisitionType: RequisitionType.Purchase,
    WarehouseID: defaultWarehouseID,
    IsIncludeLines: false,
    LastRowNumber: 0,
    MaxResults: pageSize,
    SortAsc: false,
    SortField: 'createddatetimeutc',
  } as IRequisitionFilter;

  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<IRequisition[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [moreToGet, setMoreToGet] = useState<boolean>(true);
  const [error, setError] = useState<any>();
  const [searchFilter, setSearchFilter] = useState<IRequisitionFilter>(initialFilterState);

  const getRequisitionList = async () => {
    setLoading(true);

    try {
      const {Value, TotalRecordCount, MoreToGet, LastRowNumber} = await search(searchFilter);
      setItems(Value || []);
      setTotalCount(TotalRecordCount);
      setMoreToGet(MoreToGet);
      setCurrentPage(Math.ceil(LastRowNumber / (searchFilter.MaxResults || pageSize)));
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getRequisitionList();
  }, [searchFilter]);

  return {
    loading,
    items,
    totalCount,
    pageSize: searchFilter.MaxResults || pageSize,
    currentPage,
    moreToGet,
    error,
    searchFilter,
    refetch: getRequisitionList,
    setSearchFilter,
  };
};

export default useRequisitionAPI;
