import * as React from 'react';
const RightArrowIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.415 6.597l-2.58-2.607a.667.667 0 00-.947 0 .667.667 0 000 .947l2.374 2.387H.668a.667.667 0 000 1.333h13.634l-2.414 2.413a.667.667 0 000 .92.667.667 0 00.947 0l2.58-2.566a2 2 0 000-2.827z"
      fill="#1C78AD"
    />
  </svg>
);
export default RightArrowIcon;
