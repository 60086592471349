import {createContext, Dispatch, SetStateAction, useContext} from 'react';

import {ICustomer} from '../../model/customer/Customer';
import {IQuotePackageDetails, IQuotesPackage} from '../../model/quotes/quotes';

export interface IQuoteContextType {
  quotePackage?: IQuotesPackage;
  setQuotePackage?: (quotePackage: IQuotesPackage) => void;
  customerDetails?: ICustomer;
  setCustomerDetails: (cus?: ICustomer) => void;
  quoteCustomer?: ICustomer;
  setQuoteCustomer: (cus?: ICustomer) => void;
  updateQuotePackage: (quotePackage: Partial<IQuotesPackage>) => void;
  refreshQuotePackage: () => void;
  selectedQuoteVariantID?: number;
  setSelectedQuoteVariantID?: (ID: number) =>void;
  selectedVariantTotalAmount?: number;
  setSelectedVariantTotalAmount?: (amount: number) => void;
  quotePackageDetails?: IQuotePackageDetails;
  setQuotePackageDetails?: (packDetails: IQuotePackageDetails) => void;
  refreshCustomer?: () => void;
  handleDeleteQuote?: (quoteID?: number, deleteQuoteID?: number) => Promise<boolean>;
  handleDeletePackage?: () => Promise<void>;
  isLoading: boolean;
  duplicatePackage: boolean;
  setDuplicatePackage: Dispatch<SetStateAction<boolean>>;
  isCollapseRightPanal: boolean;
  setIsCollapseRightPanal: Dispatch<SetStateAction<boolean>>;
}

const quoteInitialValue = (): IQuoteContextType => {
  return {
    quotePackage: undefined,
    setQuotePackage: () => {},
    customerDetails: undefined,
    quoteCustomer: undefined,
    setCustomerDetails: () => {},
    setQuoteCustomer: () => {},
    updateQuotePackage: () => {},
    refreshQuotePackage: () => {},
    selectedQuoteVariantID: undefined,
    selectedVariantTotalAmount: undefined,
    setSelectedVariantTotalAmount: () => {},
    isLoading: false,
    duplicatePackage: false,
    setDuplicatePackage: () => {},
    isCollapseRightPanal: false,
    setIsCollapseRightPanal: () => {},
  };
};

const QuoteContext = createContext<IQuoteContextType>(quoteInitialValue());

export const useQuoteContext = () => {
  return useContext(QuoteContext);
};

export default QuoteContext;
