import React, {useState} from 'react';
import {Button, Typography} from 'spenda-ui-react';

import {CreateCatalogueDialog} from '../../../../components/dialog';
import {CatalogueAddingProductOptionsDialog} from '../../../../components/dialog/CatalogueAddingProductOptionsDialog';
import {CatalogueDownloadAndUploadSheetDialog} from '../../../../components/dialog/CatalogueDownloadAndUploadSheetDialog';

export const ManageCatalogueHeader = () => {
  const [isShowCreateCatelogueDialog, setIsShowCreateCatelogueDialog] = useState<boolean>(false);
  const [isShowAddingProductOptionsDialog, setIsShowAddingProductOptionsDialog] = useState<boolean>(false);
  const [isShowDownloadAndUploadSheetDialog, setIsShowDownloadAndUploadSheetDialog] = useState<boolean>(false);

  return (
    <>
      <div className="flex items-center justify-between px-2.5 pb-2.5">
        <Typography variant="h2">Catalogues(3)</Typography>
        <div className="flex items-center gap-2.5">
          <Button onClick={() => setIsShowCreateCatelogueDialog(true)}>Create catalogue</Button>
        </div>
      </div>
      {isShowCreateCatelogueDialog && (
        <CreateCatalogueDialog
          handleCancel={() => setIsShowCreateCatelogueDialog(false)}
          handleContinue={() => {
            setIsShowCreateCatelogueDialog(false);
            setIsShowAddingProductOptionsDialog(true);
          }}
        />
      )}

      {isShowAddingProductOptionsDialog && (
        <CatalogueAddingProductOptionsDialog
          handleCancel={() => setIsShowAddingProductOptionsDialog(false)}
          handleContinue={() => {
            setIsShowAddingProductOptionsDialog(false);
            setIsShowDownloadAndUploadSheetDialog(true);
          }}
        />
      )}

      {isShowDownloadAndUploadSheetDialog && (
        <CatalogueDownloadAndUploadSheetDialog handleCancel={() => setIsShowDownloadAndUploadSheetDialog(false)} />
      )}
    </>
  );
};
