import React from 'react';
import {SButton} from './Button';

interface IPrimaryButtonProps {
  fullWidth?: boolean;
  label: string;
  isValid?: boolean;
  isSubmitting?: boolean;
  margin?: string;
  padding?: string;
  width?: string;
  className?: string;
  disabled?: boolean;
  type?: 'submit' | 'reset' | 'button';
  form?: string;
  lineHeight?: 's' | 'l';
  onClick?: (event?: any) => void;
  loaderColor?: string;
}

export const PrimaryButton = (props: IPrimaryButtonProps) => {
  const {
    label,
    isSubmitting,
    className,
    margin,
    width,
    disabled,
    type,
    form,
    lineHeight,
    onClick,
    padding,
    loaderColor,
  } = props;
  return (
    <SButton
      textColor={'white'}
      width={width || 'auto'}
      height="40px"
      lineHeight={lineHeight || 'l'}
      fontSize="xs"
      fontFamily={'Poppins'}
      boxShadow="none"
      backgroundColor={disabled ? 'hsla(var(--primary)/15%)' : 'hsl(var(--primary))'}
      padding={padding || '7px 20px!important'}
      margin={margin || '0px'}
      borderRadius="6px"
      disabled={disabled || isSubmitting}
      isSubmitting={isSubmitting}
      onClick={onClick}
      className={className}
      type={type}
      form={form}
      loaderColor={loaderColor}
    >
      {label}
    </SButton>
  );
};

export const PositiveButton = (props: IPrimaryButtonProps) => {
  const {label, isSubmitting, className, margin, width, disabled, type, form, lineHeight, onClick, loaderColor} = props;
  return (
    <SButton
      textColor="white"
      width={width || 'auto'}
      height="40px"
      lineHeight={lineHeight || 'l'}
      fontSize="xs"
      fontFamily={'Poppins'}
      boxShadow="none"
      backgroundColor={disabled ? '#9ECFBC' : '#3aa079'}
      padding="7px 20px!important"
      margin={margin || '0px'}
      variant={'outlined'}
      borderRadius="6px"
      disabled={disabled || isSubmitting}
      isSubmitting={isSubmitting}
      onClick={onClick}
      className={className}
      type={type}
      form={form}
      loaderColor={loaderColor}
    >
      {label}
    </SButton>
  );
};

export const SecondaryButton = (props: IPrimaryButtonProps) => {
  const {
    fullWidth,
    label,
    className,
    margin,
    width,
    disabled,
    type,
    form,
    lineHeight,
    isSubmitting,
    padding,
    onClick,
    loaderColor,
  } = props;

  return (
    <SButton
      fullWidth={fullWidth}
      textColor={disabled ? 'hsla(var(--primary)/15%)' : 'hsl(var(--primary))'}
      width={width || 'auto'}
      height="40px"
      lineHeight={lineHeight || 'l'}
      fontSize="xs"
      backgroundColor={'#fff'}
      padding={padding || '7px 20px!important'}
      margin={margin || '0px'}
      variant={'outlined'}
      fontFamily={'Poppins'}
      border={disabled ? '1px solid hsla(var(--primary)/15%)' : '1px solid hsl(var(--primary))'}
      borderRadius="6px"
      onClick={onClick}
      className={className}
      disabled={disabled || isSubmitting}
      type={type}
      form={form}
      isSubmitting={isSubmitting}
      loaderColor={loaderColor}
    >
      {label}
    </SButton>
  );
};

export const DestructiveButton = (props: IPrimaryButtonProps) => {
  const {label, className, margin, width, disabled, type, form, lineHeight, isSubmitting, onClick, loaderColor} = props;
  return (
    <SButton
      textColor={'white'}
      width={width || 'auto'}
      height="40px"
      lineHeight={lineHeight || 'l'}
      fontSize="xs"
      backgroundColor={disabled ? '#E2AFA2' : '#C55D44'}
      padding="7px 20px!important"
      margin={margin || '0px'}
      variant={'outlined'}
      fontFamily={'Poppins'}
      borderRadius="6px"
      onClick={onClick}
      className={className}
      disabled={disabled || isSubmitting}
      type={type}
      form={form}
      isSubmitting={isSubmitting}
      loaderColor={loaderColor}
    >
      {label}
    </SButton>
  );
};

export const FramelessDestructiveButton = (props: IPrimaryButtonProps) => {
  const {label, className, margin, width, disabled, type, form, lineHeight, isSubmitting, onClick, loaderColor} = props;
  return (
    <SButton
      textColor={disabled ? '#E2AFA2' : '#C55D44'}
      width={width || 'auto'}
      height="40px"
      lineHeight={lineHeight || 'l'}
      fontSize="xs"
      backgroundColor="#fff"
      padding="7px 20px!important"
      margin={margin || '0px'}
      variant={'outlined'}
      fontFamily={'Poppins'}
      borderRadius="6px"
      border={disabled || isSubmitting ? '1px solid #E2AFA2' : '1px solid #C55D44'}
      onClick={onClick}
      className={className}
      disabled={disabled}
      type={type}
      form={form}
      isSubmitting={isSubmitting}
      loaderColor={loaderColor}
    >
      {label}
    </SButton>
  );
};

export const FramelessButton = (props: IPrimaryButtonProps) => {
  const {label, isSubmitting, className, margin, width, disabled, type, form, lineHeight, onClick, loaderColor} = props;
  return (
    <SButton
      textColor={disabled ? 'hsla(var(--primary)/15%)' : 'hsl(var(--primary))'}
      width={width || 'auto'}
      height="40px"
      lineHeight={lineHeight || 'l'}
      fontSize="xs"
      fontFamily={'Poppins'}
      boxShadow="none"
      backgroundColor={'white'}
      padding="7px 20px!important"
      margin={margin || '0px'}
      variant={'outlined'}
      borderRadius="6px"
      disabled={disabled || isSubmitting}
      isSubmitting={isSubmitting}
      onClick={onClick}
      className={className}
      type={type}
      form={form}
      loaderColor={loaderColor}
    >
      {label}
    </SButton>
  );
};
