import * as React from 'react';
const EditCustomerIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" fill="none" {...props}>
    <path
      fill="#1C78AD"
      fillRule="evenodd"
      d="M11.284.542 4.172 7.654a2.897 2.897 0 0 0-.854 2.063v.783c0 .322.262.583.584.583h.783a2.897 2.897 0 0 0 2.062-.854l7.112-7.112c.71-.711.71-1.863 0-2.575a1.863 1.863 0 0 0-2.575 0Zm1.75 1.75L5.922 9.404c-.329.327-.773.511-1.237.513h-.2v-.2c.001-.464.185-.909.512-1.238l7.112-7.112a.67.67 0 0 1 .925 0 .655.655 0 0 1 0 .925Z"
      clipRule="evenodd"
    />
    <path
      fill="#1C78AD"
      fillRule="evenodd"
      d="M13.818 5.238a.583.583 0 0 0-.583.583V8.75H10.9a1.75 1.75 0 0 0-1.75 1.75v2.333H3.318a1.75 1.75 0 0 1-1.75-1.75V2.917c0-.967.784-1.75 1.75-1.75h5.275a.583.583 0 1 0 0-1.167H3.318A2.92 2.92 0 0 0 .401 2.917v8.166A2.92 2.92 0 0 0 3.318 14h6.617a2.898 2.898 0 0 0 2.063-.854l1.549-1.55a2.898 2.898 0 0 0 .854-2.063V5.821a.583.583 0 0 0-.583-.583Zm-2.645 7.083a1.735 1.735 0 0 1-.855.467V10.5c0-.322.261-.583.583-.583h2.29a1.76 1.76 0 0 1-.467.854l-1.551 1.55Z"
      clipRule="evenodd"
    />
  </svg>
);
export default EditCustomerIcon;
