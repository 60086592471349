import React, {useState, FunctionComponent} from 'react';
import {css} from 'glamor';
import {makeStyles, Button} from '@material-ui/core';
import {Label} from '../Label';
import {IRequisitionTemplate} from '../../model/template/IRequisitionTemplate';
import {AlertDialogSlide} from '../dialog/AlertDialogSlide';
import DeleteTwoToneIcon from '@material-ui/icons/DeleteTwoTone';
import {PriceFormat} from '../../utils/formatter';

interface ITemplateProps {
  isActive?: boolean;
  template: Partial<IRequisitionTemplate>;
  onClick: (template: Partial<IRequisitionTemplate>) => void;
  onDelete?: (templateID: number) => void;
}

export const Template: FunctionComponent<ITemplateProps> = props => {
  const [isShowDeleteConfirmation, setIsShowDeleteConfirmation] = useState(false);

  const onDeleteItem = () => {
    setIsShowDeleteConfirmation(false);
    if (props.onDelete && props.template.ID) {
      props.onDelete(props.template.ID);
    }
  };

  const useStyles = makeStyles({
    root: {
      bottom: 2,
      color: '#269E6B',
      minWidth: 'auto',
      padding: 0,
      position: 'absolute',
      right: 2,
      '&:hover': {
        color: '#ef5350',
      },
      '&:focus': {
        outline: 'none',
      },
    },
  });

  const classes = useStyles();
  const isBlank = props.template.ID === 0;

  const template = css({
    border: isBlank
      ? props.isActive
        ? '2px solid #2C95BC'
        : '1px solid #2C95BC'
      : props.isActive
      ? '2px solid #269E6B'
      : '1px solid #269E6B',
    borderRadius: 4,
    backgroundColor: isBlank ? '#D0ECF6' : '#DAEDE5',
    color: isBlank ? '#2C95BC' : '#269E6B',
    cursor: 'pointer',
    height: 134,
    letterSpacing: -1,
    margin: 11,
    maxWidth: 184,
    minWidth: 184,
    padding: 8,
    position: 'relative',
    wordSpacing: -1,
    '&:hover': {
      border: isBlank ? '2px solid #2C95BC' : '2px solid #269E6B',
    },
  });

  const templateTitle = css({
    borderBottom: isBlank ? '1px solid #2C95BC' : '1px solid #269E6B',
    margin: 5,
    padding: 1,
  });

  return (
    <div className={`${template}`}>
      {isBlank ? (
        <div onClick={() => props.onClick(props.template)}>
          <div className={`flex justify-center items-center ${templateTitle}`}>
            <div className="w-full flex justify-center items-center">
              <Label size="s" position="center" type="secondary">
                Blank
              </Label>
            </div>
          </div>
          <div>
            <div className="w-full flex justify-center items-center">
              <Label size="xs" type="secondary" position="center">
                Create a purchase
              </Label>
            </div>
            <div className="w-full flex justify-center items-center">
              <Label size="xs" type="secondary" position="center">
                with no lines
              </Label>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div onClick={() => props.onClick(props.template || 0)}>
            <div className={`flex justify-center items-center ${templateTitle}`}>
              <div className="w-full flex justify-center items-center">
                <Label title={props.template && props.template.Name} size="s" position="center" type="secondary">
                  {props.template && props.template.Name}
                </Label>
              </div>
            </div>
            <div>
              <div className="w-full flex justify-center items-center">
                <Label size="xs" type="secondary" position="center">
                  Product Count: {props.template && props.template.LineCount}
                </Label>
              </div>
              <div className="w-full flex justify-center items-center">
                <Label size="xs" type="secondary" position="center">
                  Quantity: {props.template && props.template.QuantityCount}
                </Label>
              </div>
              <div className="w-full flex justify-center items-center">
                <Label size="xs" type="secondary" position="center">
                  est.Cost (ex): {PriceFormat(props.template && (props.template.TotalEx || 0))}
                </Label>
              </div>
            </div>
          </div>
          <Button onClick={() => setIsShowDeleteConfirmation(true)} classes={{root: classes.root}}>
            <DeleteTwoToneIcon />
          </Button>
        </div>
      )}

      {isShowDeleteConfirmation && (
        <AlertDialogSlide
          title="Delete Template"
          actions={[
            {label: 'Delete', action: onDeleteItem},
            {label: 'Cancel', variant: 'outlined', action: () => setIsShowDeleteConfirmation(false)},
          ]}
        >
          <p className="pb-3">Are you sure you want to delete '{props.template && props.template.Name}' ?</p>
        </AlertDialogSlide>
      )}
    </div>
  );
};
