import React, {useContext, useMemo} from 'react';
import {IconButton, InputNumber, Typography} from 'spenda-ui-react';

import AppContext from '../../context/app/appContext';
import {ProfitMarginTooltip} from './ProfitMarginTooltip';
import {DISCOUNT_MODE} from '../../model/constants/Constants';
import {IServiceJob} from '../../model/service-management/serviceJob';
import {IQuotes} from '../../model/quotes/quotes';
import {PriceFormat, validDecimalPlaces} from '../../utils/formatter';
import PriceCalculator from '../../utils/priceCalculator';
import {LockIcon, UnLockIcon} from '../../assets/svg';
import {useFeatureFlags} from '../../hooks/useFeatureFlags';

interface CalculationTableProps<T> {
  values: T;
  setFieldValue: (field: string, value: any) => void;
  handleDiscountChange: (value: string, type: DISCOUNT_MODE) => void;
  handleShippingChange: (value: string) => void;
  serviceJob?: boolean;
  handleUnlock?: (index: number) => void;
  isDiscountEditable?: boolean;
  isShippingEditable?: boolean;
  isServiceJobFromQuote?: boolean;
  disabled?: boolean;
}

/**
 * Calculation Table Component
 * @param {T} props - CalculationTableProps <T> extends IServiceJob | IQuotes
 * @returns {JSX.Element}
 */
export const CalculationTable = <T extends IServiceJob | IQuotes>(props: CalculationTableProps<T>) => {
  const {
    values,
    setFieldValue,
    handleDiscountChange,
    handleShippingChange,
    serviceJob,
    isDiscountEditable,
    isShippingEditable,
    isServiceJobFromQuote,
    handleUnlock,
    disabled,
  } = props;

  const {tenantInfo} = useContext(AppContext);
  const {QuoteV289367} = useFeatureFlags().tenantOwned();

  const calculatedData = useMemo(() => {
    let _subTotal = 0;
    let _totalCostPriceEx = 0;
    let _totalSalesPriceEx = 0;
    let _unDiscountedTotalInc = 0;
    let _discountEx = 0;
    let _discountIndex = -1;
    let _shippingIndex = -1;
    for (let i = 0; i < values?.lines.length; i++) {
      if (
        values?.lines[i].inventoryID !== tenantInfo?.TenantUserDetails?.DefaultDiscountLineItemInventoryId &&
        values?.lines[i].inventoryID !== tenantInfo?.TenantUserDetails?.DefaultShippingLineItemInventoryId
      ) {
        _totalCostPriceEx += values?.lines[i].quantity * (values?.lines[i].costPriceEx ?? 0);
        _subTotal += Number(values?.lines[i]?.lineTotalEx) || 0;
        _totalSalesPriceEx += values?.lines[i].quantity * Number(values?.lines[i]?.sellPriceEx ?? 0);
        _unDiscountedTotalInc += values?.lines[i].lineTotalInc || 0;
      } else if (values?.lines[i].inventoryID === tenantInfo?.TenantUserDetails?.DefaultDiscountLineItemInventoryId) {
        _discountEx = values?.lines[i].lineTotalEx || 0;
        _discountIndex = i;
      } else if (values?.lines[i].inventoryID === tenantInfo?.TenantUserDetails?.DefaultShippingLineItemInventoryId) {
        _shippingIndex = i;
      }
    }
    return {
      subTotal: _subTotal,
      totalCostPriceEx: _totalCostPriceEx,
      totalSalesPriceEx: _totalSalesPriceEx,
      unDiscountedTotalInc: _unDiscountedTotalInc,
      discountEx: _discountEx,
      discountIndex: _discountIndex,
      shippingIndex: _shippingIndex,
    };
  }, [JSON.stringify(values?.lines)]);

  const {subTotal, totalCostPriceEx, totalSalesPriceEx, unDiscountedTotalInc, discountEx} = calculatedData;

  const LockButton = ({index, isEditable}: {index: number; isEditable?: boolean}) => {
    return (
      <>
        {serviceJob && isServiceJobFromQuote ? (
          !isEditable ? (
            <IconButton
              variant="text"
              name="DiscountLock"
              onClick={() => handleUnlock?.(index)}
              disabled={disabled}
              className="active:bg-transparent"
              ripple={false}
            >
              <LockIcon />
            </IconButton>
          ) : (
            <div className="flex w-10 justify-center">
              '<UnLockIcon />
            </div>
          )
        ) : (
          <span className="block w-10"></span>
        )}
      </>
    );
  };

  return (
    <>
      <table className="w-full font-poppins">
        <tbody className="text-sm">
          <tr>
            <td rowSpan={8} width={serviceJob ? '69%' : '79%'} className="align-bottom">
              {QuoteV289367 && (values?.requiredDepositAmount || 0) > (values?.totalInc || 0) && (
                <div className="flex w-full justify-end">
                  <div className="w-full max-w-[283px] rounded-lg bg-warning/10 p-2.5">
                    <Typography className="text-spenda-warning" variant="small">
                      Please adjust the deposit amount to be less than the total balance of the quote by clicking on the
                      Change Details option.
                    </Typography>
                  </div>
                </div>
              )}
            </td>
            <td width={serviceJob ? '15%' : '10%'} className="border-y border-[#EAEAEA]">
              <div className="flex items-center justify-end gap-x-3 bg-[#FAFAFA] p-2.5 ">
                {!serviceJob && totalSalesPriceEx > 0 && (
                  <ProfitMarginTooltip
                    profitMargin={totalSalesPriceEx - totalCostPriceEx + Number(discountEx || 0)}
                    profitMarginPercentage={
                      ((totalSalesPriceEx - totalCostPriceEx + Number(discountEx || 0)) / totalCostPriceEx) * 100
                    }
                  />
                )}
                <Typography className="text-right text-black-800" variant="small">
                  Sub Total
                </Typography>
              </div>
            </td>
            <td width={serviceJob ? '15%' : '10%'} className="border-y border-[#EAEAEA]">
              <Typography className="p-2.5 text-right text-black-800" data-autoid={'txtSubTotal'} variant="small">
                {PriceFormat(subTotal, '$', 2)}
              </Typography>
            </td>
            <td width={serviceJob ? '1%' : '40px'} className="border-y border-[#EAEAEA]"></td>
          </tr>
          <tr>
            <td width={serviceJob ? '15%' : '10%'} className="border-y border-[#EAEAEA]">
              <Typography className="bg-[#FAFAFA] p-2.5 text-right text-black-800" variant="small">
                Discount (Inc)
              </Typography>
            </td>
            <td width={serviceJob ? '15%' : '10%'} className="border-y border-[#EAEAEA] py-0">
              <div className="flex justify-end gap-x-[1px]">
                <div className="flex w-[85px] max-w-[85px] items-center border border-[#707070] py-2.5">
                  <InputNumber
                    min={0}
                    max={100}
                    placeholder="0.00"
                    data-autoid="txtDiscountPercent"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    className="text-s w-3/4 text-right font-normal text-black-800"
                    value={
                      values?.discountMode === DISCOUNT_MODE.PERCENT
                        ? values?.discountString
                        : PriceCalculator.roundNumber((Number(values?.discount) / unDiscountedTotalInc!) * 100)
                    }
                    disabled={(serviceJob && !isDiscountEditable) || disabled}
                    onChange={e => {
                      if (!validDecimalPlaces(+e.target.value)) return;
                      const {value} = e.target;
                      if (Number(value) <= 100) {
                        handleDiscountChange(value, DISCOUNT_MODE.PERCENT);
                      }
                    }}
                  />
                  %
                </div>
                <div className="flex w-[85px] max-w-[85px] items-center justify-center border border-[#707070] py-2.5">
                  <InputNumber
                    placeholder="0.00"
                    data-autoid="txtDiscountAmt"
                    mode="currency"
                    locale="en-AU"
                    currency="AUD"
                    className="w-[75%] text-right text-sm font-normal  text-black-800"
                    value={
                      values?.discountMode === DISCOUNT_MODE.DOLLAR
                        ? values?.discountString
                        : PriceCalculator.roundNumber((Number(values?.discountString) * unDiscountedTotalInc) / 100)
                    }
                    disabled={(serviceJob && !isDiscountEditable) || disabled}
                    onChange={e => {
                      if (!validDecimalPlaces(+e.target.value)) return;
                      const {value} = e.target;
                      if (Number(value) <= unDiscountedTotalInc) {
                        handleDiscountChange(value, DISCOUNT_MODE.DOLLAR);
                      }
                    }}
                  />
                </div>
              </div>
            </td>
            <td width={serviceJob ? '1%' : '1'} className="border-y border-[#EAEAEA]">
              <LockButton index={calculatedData.discountIndex} isEditable={isDiscountEditable} />
            </td>
          </tr>
          <tr>
            <td width={serviceJob ? '15%' : '10%'} className="border-y border-[#EAEAEA]">
              <Typography className="bg-[#FAFAFA] p-2.5 text-right text-black-800" variant="small">
                Shipping (Inc)
              </Typography>
            </td>
            <td width={serviceJob ? '15%' : '10%'} className="border-y border-[#EAEAEA] py-0 pr-0">
              <div className="flex justify-end">
                <div className="flex max-w-[85px] items-center justify-center border border-[#707070] py-2.5">
                  <InputNumber
                    data-autoid="txtShippingAmt"
                    placeholder="0.00"
                    value={values?.shipping}
                    mode="currency"
                    locale="en-AU"
                    currency="AUD"
                    disabled={(serviceJob && !isShippingEditable) || disabled}
                    className="w-[75%] text-right text-sm font-normal  text-black-800"
                    onChange={e => {
                      if (!validDecimalPlaces(+e.target.value)) return;
                      setFieldValue('shipping', e.target.value);
                      if (unDiscountedTotalInc) handleShippingChange(e.target.value);
                    }}
                  />
                </div>
              </div>
            </td>
            <td width={serviceJob ? '1%' : '40px'}>
              <LockButton index={calculatedData.shippingIndex} isEditable={isShippingEditable} />
            </td>
          </tr>
          <tr>
            <td width={serviceJob ? '15%' : '10%'} className="border-y border-[#EAEAEA]">
              <Typography className="bg-[#FAFAFA] p-2.5 text-right text-black-800" variant="small">
                Tax
              </Typography>
            </td>
            <td width={serviceJob ? '15%' : '10%'} className="border-y border-[#EAEAEA]">
              <Typography data-autoid={`txtTotalTax`} className="p-2.5 text-right text-black-800" variant="small">
                {`${PriceFormat(values?.totalTax || 0, '$', 2)}`}
              </Typography>
            </td>
            <td width={serviceJob ? '1%' : '40'} className="border-y border-[#EAEAEA]"></td>
          </tr>
          <tr>
            <td width={serviceJob ? '15%' : '10%'}>
              <Typography
                className="border-b border-[#EAEAEA] bg-[#FAFAFA] p-2.5 text-right text-black-800"
                variant="small"
              >
                Total
              </Typography>
            </td>
            <td width={serviceJob ? '15%' : '10%'}>
              <Typography
                className="border-b border-[#EAEAEA] p-2.5 text-right text-black-800"
                data-autoid={`txtTotalInc`}
                variant="small"
              >
                {`${PriceFormat(values?.totalInc || 0, '$', 2)}`}
              </Typography>
            </td>
            <td width={'1%'} height={'40px'}>
              <span className="block h-full w-10 border-b border-[#EAEAEA]"></span>
            </td>
            {serviceJob && (
              <td width={'1%'}>
                <span className="block w-10"></span>
              </td>
            )}
          </tr>
          {!serviceJob && QuoteV289367 && (
            <>
              <tr className="border-t-[16px] border-transparent">
                <td width="10%">
                  <Typography
                    className="border-t border-[#EAEAEA] bg-[#FAFAFA] p-2.5 text-right text-black-800"
                    variant="small"
                  >
                    Deposit
                  </Typography>
                </td>
                <td width={'10%'}>
                  <Typography className="border-t border-[#EAEAEA] p-2.5 text-right text-black-800" variant="small">
                    {`${PriceFormat(values?.requiredDepositAmount || 0, '$', 2)}`}
                  </Typography>
                </td>
                <td width={'40px'} height={'40px'}>
                  <span className="block h-full w-10 border-t border-[#EAEAEA]"></span>
                </td>
              </tr>
              <tr>
                <td width={'10%'} className="border-y border-[#EAEAEA]">
                  <Typography className="bg-[#FAFAFA] p-2.5 text-right text-black-800" variant="small">
                    Amount
                  </Typography>
                </td>
                <td width={'10%'} className="border-y border-[#EAEAEA]">
                  <Typography className="p-2.5 text-right text-black-800" variant="small">
                    {`${PriceFormat((values?.totalInc || 0) - (values?.requiredDepositAmount || 0) || 0, '$', 2)}`}
                  </Typography>
                </td>
                <td width={'40px'} className="border-y border-[#EAEAEA]">
                  <span className="block w-10"></span>
                </td>
              </tr>
            </>
          )}
        </tbody>
      </table>
    </>
  );
};
