import * as React from "react"

export const ProductVariantIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={102}
      height={123}
      fill="none"
      {...props}
    >
      <path
        fill="#24AE5F"
        d="M85.911 113.835l-5.894-5.84a1.974 1.974 0 00-2.779 0 1.932 1.932 0 000 2.753l2.54 2.517H6.743l2.54-2.517a1.932 1.932 0 000-2.753 1.974 1.974 0 00-2.778 0l-5.895 5.84a1.933 1.933 0 000 2.754l5.895 5.84c.383.38.886.571 1.39.571s1.005-.191 1.388-.571a1.932 1.932 0 000-2.753l-2.54-2.517h73.037l-2.54 2.517a1.932 1.932 0 000 2.753c.382.38.885.571 1.388.571s1.006-.191 1.39-.571l5.894-5.84a1.934 1.934 0 000-2.754z"
      />
      <path
        className="fill-primary"
        d="M101.564 37.584l.068 36.514c.004 2.522-2.05 4.561-4.59 4.556l-39.086-.071c-2.54-.005-4.602-2.051-4.607-4.573l-.068-36.515c-.008-4.416 3.584-7.98 8.033-7.973l32.188.06c4.449.007 8.054 3.585 8.062 8.001z"
      />
      <path
        fill="#E3EEF5"
        d="M77.418 35.257l-5.853 3.868a1.157 1.157 0 01-1.643-.398l-5.16-9.197c1.142-4.562 4.588-5.697 4.588-5.697l16.095.03s3.45 1.147 4.608 5.713l-5.125 9.179a1.152 1.152 0 01-1.642.392l-5.867-3.89z"
      />
      <path
        fill="#17568C"
        d="M95.833 46.702l-9.197-.017a1.14 1.14 0 00-1.148 1.139l.005 2.282a1.15 1.15 0 001.151 1.143l9.197.017a1.14 1.14 0 001.148-1.139l-.005-2.282a1.15 1.15 0 00-1.151-1.143z"
      />
      <path
        fill="#ECF0F1"
        d="M73.993 47.804a1.14 1.14 0 001.146-1.14 1.152 1.152 0 00-1.15-1.145 1.139 1.139 0 00-1.147 1.14 1.151 1.151 0 001.151 1.145zm.014 6.847a1.14 1.14 0 001.146-1.14 1.152 1.152 0 00-1.151-1.145 1.14 1.14 0 00-1.146 1.14 1.152 1.152 0 001.151 1.145zm.012 6.846a1.14 1.14 0 001.145-1.14 1.152 1.152 0 00-1.15-1.145 1.139 1.139 0 00-1.146 1.14c.001.631.516 1.144 1.15 1.145zm.012 6.847a1.14 1.14 0 001.146-1.14 1.152 1.152 0 00-1.15-1.145 1.14 1.14 0 00-1.146 1.14 1.152 1.152 0 001.15 1.145zm.013 6.847a1.14 1.14 0 001.146-1.14 1.152 1.152 0 00-1.151-1.145 1.14 1.14 0 00-1.146 1.14 1.152 1.152 0 001.151 1.145z"
      />
      <path
        fill="#17568C"
        d="M85.445 23.861l-8.026 11.396-8.068-11.425 16.094.03z"
      />
      <path
        fill="#17568C"
        d="M78.568 35.26l.08 43.36-2.299-.004-.08-43.36a1.144 1.144 0 011.147-1.14c.632.001 1.15.516 1.152 1.143z"
      />
      <path
        fill="#FFC517"
        d="M94.14 30.054l.094 50.61c.006 3.495-2.84 6.32-6.362 6.314l-54.173-.1c-3.52-.006-6.378-2.842-6.385-6.337l-.093-50.61c-.012-6.12 4.966-11.061 11.132-11.05l44.613.082c6.167.01 11.163 4.97 11.174 11.09z"
      />
      <path
        fill="#FFDF7E"
        d="M60.674 26.83l-8.113 5.36a1.604 1.604 0 01-2.278-.55l-7.15-12.748c1.581-6.324 6.358-7.897 6.358-7.897l22.307.041s4.783 1.59 6.388 7.92l-7.104 12.721a1.596 1.596 0 01-2.275.543l-8.133-5.39z"
      />
      <path
        fill="#FFAC17"
        d="M86.197 42.692l-12.746-.024a1.58 1.58 0 00-1.59 1.58l.005 3.162a1.595 1.595 0 001.596 1.585l12.747.023a1.58 1.58 0 001.59-1.579l-.005-3.163a1.595 1.595 0 00-1.597-1.584z"
      />
      <path
        fill="#ECF0F1"
        d="M55.927 44.22a1.579 1.579 0 001.588-1.58 1.596 1.596 0 00-1.595-1.587 1.579 1.579 0 00-1.588 1.58 1.596 1.596 0 001.595 1.587zm.018 9.489a1.579 1.579 0 001.588-1.58 1.596 1.596 0 00-1.595-1.587 1.579 1.579 0 00-1.588 1.58 1.596 1.596 0 001.595 1.587zm.018 9.49a1.579 1.579 0 001.588-1.58 1.596 1.596 0 00-1.595-1.587 1.579 1.579 0 00-1.588 1.58 1.596 1.596 0 001.595 1.586zm.017 9.489a1.579 1.579 0 001.588-1.58 1.596 1.596 0 00-1.595-1.587 1.579 1.579 0 00-1.587 1.58 1.596 1.596 0 001.594 1.586zm.018 9.49a1.579 1.579 0 001.588-1.58 1.596 1.596 0 00-1.595-1.588 1.579 1.579 0 00-1.588 1.58 1.596 1.596 0 001.595 1.587z"
      />
      <path
        fill="#FFAC17"
        d="M71.798 11.035L60.674 26.83 49.49 10.994l22.307.04z"
      />
      <path
        fill="#FFAC17"
        d="M62.269 26.832l.11 60.1-3.186-.007-.111-60.099a1.585 1.585 0 011.59-1.578 1.6 1.6 0 011.597 1.584z"
      />
      <path
        fill="#E64C3C"
        d="M84.521 23.593v62.303c0 4.303-3.517 7.788-7.859 7.788H9.857c-4.342 0-7.859-3.485-7.859-7.788V23.593c0-7.535 6.15-13.629 13.754-13.629h55.015c7.605 0 13.754 6.094 13.754 13.629z"
      />
      <path
        fill="#FB7B76"
        d="M43.26 19.7l-10.017 6.617c-.955.63-2.25.32-2.808-.674L21.647 9.966C23.61 2.178 29.505.231 29.505.231h27.508s5.894 1.947 7.859 9.735l-8.789 15.677a1.974 1.974 0 01-2.808.674L43.26 19.699z"
      />
      <path
        fill="#C03A2B"
        d="M74.697 39.169H58.978a1.956 1.956 0 00-1.964 1.947v3.894c0 1.075.88 1.947 1.964 1.947h15.72a1.956 1.956 0 001.964-1.947v-3.894c0-1.076-.88-1.947-1.965-1.947z"
      />
      <path
        fill="#ECF0F1"
        d="M37.365 41.116a1.956 1.956 0 001.965-1.947c0-1.076-.88-1.947-1.965-1.947a1.956 1.956 0 00-1.965 1.947c0 1.075.88 1.947 1.965 1.947zm0 11.681a1.956 1.956 0 001.965-1.946c0-1.076-.88-1.947-1.965-1.947A1.956 1.956 0 0035.4 50.85c0 1.076.88 1.947 1.965 1.947zm0 11.682c1.085 0 1.965-.871 1.965-1.947s-.88-1.947-1.965-1.947-1.965.872-1.965 1.947.88 1.947 1.965 1.947zm0 11.682c1.085 0 1.965-.871 1.965-1.947s-.88-1.947-1.965-1.947-1.965.872-1.965 1.947.88 1.947 1.965 1.947zm0 11.682c1.085 0 1.965-.872 1.965-1.947s-.88-1.947-1.965-1.947-1.965.872-1.965 1.947.88 1.947 1.965 1.947z"
      />
      <path fill="#C03A2B" d="M57.014.229L43.26 19.699 29.506.229h27.508z" />
      <path
        fill="#C03A2B"
        d="M45.225 19.699v73.985h-3.93V19.7c0-1.07.884-1.947 1.965-1.947 1.08 0 1.965.876 1.965 1.947z"
      />
    </svg>
  )
}