import React from 'react';

export const IconCreditCardRebranding = (props: {width?: string; height?: string; fill?: string; viewBox?: string}) => {
  return (
    <svg
      width={props.width || '18px'}
      height={props.height || '20px'}
      viewBox="0 0 18 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Account-Receivable" transform="translate(0.600000, 0.000000)" className="fill-primary ">
          <path
            d="M12.5,11.6667049 C12.5,12.1269422 12.126904,12.5000383 11.6666667,12.5000383 L5,12.5000383 C4.53976271,12.5000383 4.16666667,12.1269422 4.16666667,11.6667049 C4.16666667,11.2064677 4.53976271,10.8333716 5,10.8333716 L11.6666667,10.8333716 C12.126904,10.8333716 12.5,11.2064677 12.5,11.6667049 Z M9.16666667,14.1667049 L5,14.1667049 C4.53976271,14.1667049 4.16666667,14.539801 4.16666667,15.0000383 C4.16666667,15.4602756 4.53976271,15.8333716 5,15.8333716 L9.16666667,15.8333716 C9.62690396,15.8333716 10,15.4602756 10,15.0000383 C10,14.539801 9.62690396,14.1667049 9.16666667,14.1667049 Z M16.6666879,8.73753828 L16.6666879,15.8333716 C16.6639116,18.1334159 14.8000443,19.9972832 12.5,20.0000383 L4.16666667,20.0000383 C1.86662237,19.9972832 0.00275509695,18.1334159 -2.27373675e-13,15.8333716 L-2.27373675e-13,4.16670495 C0.00275509695,1.86666066 1.86662237,0.00279337928 4.16666667,1.90488224e-05 L7.92916667,1.90488224e-05 C9.47707305,-0.0039457399 10.9623135,0.611153856 12.0541667,1.70837162 L14.9575,4.61337162 C16.0553687,5.70451464 16.6708581,7.18967159 16.6666879,8.73753828 L16.6666879,8.73753828 Z M10.8758333,2.88670495 C10.6135677,2.63267246 10.3191113,2.4141415 10,2.23670495 L10,5.83337162 C10,6.29360891 10.373096,6.66670495 10.8333333,6.66670495 L14.43,6.66670495 C14.2524634,6.34769616 14.0336324,6.05349317 13.7791667,5.79170495 L10.8758333,2.88670495 Z M15,8.73753828 C15,8.60003828 14.9733333,8.46837162 14.9608333,8.33337162 L10.8333333,8.33337162 C9.45262146,8.33337162 8.33333333,7.21408349 8.33333333,5.83337162 L8.33333333,1.70587162 C8.19833333,1.69337162 8.06583333,1.66670495 7.92916667,1.66670495 L4.16666667,1.66670495 C2.78595479,1.66670495 1.66666667,2.78599307 1.66666667,4.16670495 L1.66666667,15.8333716 C1.66666667,17.2140835 2.78595479,18.3333716 4.16666667,18.3333716 L12.5,18.3333716 C13.8807119,18.3333716 15,17.2140835 15,15.8333716 L15,8.73753828 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </svg>
  );
};
