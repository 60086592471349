import React, {useEffect, useState} from 'react';
import 'react-tabs/style/react-tabs.css';
import {css} from 'glamor';
import {useHistory, useParams} from 'react-router-dom';
import {IPurchaseInvoice, ISupplier} from '../../model/purchase-invoice/purchaseInvoice';
import moment from 'moment';
import {
  PaymentStatusType,
  PurchaseInvoiceRequestedList,
  PurchaseInvoiceViewTypes,
  SupplierViewSortingTypes,
  TimelineCustomRange,
} from '../../model/constants/Constants';
import {getMessage, SelectToggler} from './CommonComponents';
import {PurchaseInvoiceListFilter} from '../../components/purchasing/PurchaseInvoiceListFilter';
import APDisableSelectTogglerIcon from '../../assets/svg/accounts-payable/APDisableSelectTogglerIcon';
import {
  makeStyles,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  ClickAwayListener,
} from '@material-ui/core';
import {APInvoiceDetailView} from './APInvoiceDetailView';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {PriceFormat} from '../../utils/formatter';
import SelectedBillsDetails from '../../assets/svg/accounts-payable/selectedBillsDetails';
import APTooltip from '../../components/data-display/APTootip';
import ARTooltip from '../../components/data-display/ARTootip';
import IconScheduledPayments from '../../assets/svg/IconScheduledPayments';

const useAPInvoicesSingleViewList = makeStyles({
  invoicesTable: {
    borderRadius: '2px 2px 2px 2px',
    '& table': {
      borderBottom: '2px solid #F8F8F8',
      '& th': {
        color: '#999999',
        fontWeight: 600,
        fontSize: '14px',
        fontFamily: 'Poppins',
        width: '180px',
        padding: '10px!important',
        lineHeight: '14px',
        '& .MuiTableSortLabel-root': {
          paddingRight: '15px',
          '& .MuiTableSortLabel-icon': {
            margin: '0px -7px 0px 0px',
            position: 'absolute',
            right: '0',
            transform: 'rotate(0deg)',
            opacity: '1',
            fontSize: '20px',
            '&.MuiTableSortLabel-iconDirectionAsc': {
              color: '#999',
            },
            '&.MuiTableSortLabel-iconDirectionDesc': {
              color: '#999',
            },
          },
          '&.MuiTableSortLabel-active': {
            color: '#999999',
            '& .MuiTableSortLabel-iconDirectionDesc': {
              color: '#9e9e9e',
            },
            '& .MuiTableSortLabel-iconDirectionAsc': {
              color: '#1c78ad!important',
            },
          },
        },
      },
      '& .MuiTableHead-root': {
        background: '#F1F1F1',
        position: 'sticky',
        top: '0',
        zIndex: '9',
      },
      '& .MuiTableCell-root': {
        borderBottom: '1px solid #F1F1F1',
        borderLeft: '0px',
        padding: '10px',
        position: 'relative',
      },
      '& .MuiTableCell-body': {
        '&:nth-child(odd)': {
          background: '#FAFAFA',
        },
      },
    },
  },
  overDueCellColor: {
    color: '#c65d42',
  },
  dueCellColor: {
    color: '#1579AF',
  },
  invoiceDisable: {
    '& span.text-black-800': {
      color: '#333',
      opacity: '30%',
    },
    '& .disableOverdue': {
      opacity: '30%',
    },
  },
});

interface APInvoiceSingleViewListProps {
  suppliersList: Array<ISupplier>;
  selectedInvoices: any;
  handleInvoices: (invoicesId: number, supplierId: number) => void;
  handleDebitNotes: (debitNotesId: number, supplierId: number) => void;
  handleBulkInvoices: (supplierId: number) => void;
  getPurchaseInvoicesList: (SupplierID: number) => void;
  onSelectAll: (isAllSuppliersSelected: boolean) => void;
  selectedBucket: PurchaseInvoiceRequestedList;
  purchaseInvoicesList: Array<IPurchaseInvoice>;
  purchaseInvoiceViewMode: PurchaseInvoiceViewTypes;
  togglePurchaseInvoiceViewMode: (type: PurchaseInvoiceViewTypes) => void;
  toggleSupplierViewMode: (type: PurchaseInvoiceViewTypes) => void;
  SupplierViewMode: PurchaseInvoiceViewTypes;
  toggleTimelineViewMode: (type: PurchaseInvoiceViewTypes) => void;
  TimelineViewMode: PurchaseInvoiceViewTypes;
  timeLineCustomRangeMode?: TimelineCustomRange;
  toggletimeLineCustomRangeMode: (type: TimelineCustomRange) => void;
  SupplierSortingViewMode?: SupplierViewSortingTypes;
  toggleSupplierViewSortingMode: (type: SupplierViewSortingTypes) => void;
  totalRecords: number;
  isSearching?: boolean;
  totalPayable: number;
  isSavingBatch?: boolean;
  setIsSavingBatch: React.Dispatch<React.SetStateAction<boolean>>;
  isT2TPhaseTwo?: boolean;
  isScheduledPaymentsV2?: boolean;
  t2TV3?: boolean;
}

export interface IAPInvoiceId {
  transId: number;
  supplierId?: number;
}

const dateFormater = (date: string) => moment(date).format('Do MMM YYYY');

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<T>(order: Order, orderBy: keyof T) {
  return order === 'desc'
    ? (a: T, b: T) => descendingComparator(a, b, orderBy)
    : (a: T, b: T) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array?.map((el, index) => [el, index] as [T, number]);
  stabilizedThis &&
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
  return stabilizedThis.map(el => el[0]);
}

export const APInvoiceSingleViewList = (props: APInvoiceSingleViewListProps) => {
  const {
    selectedInvoices,
    handleDebitNotes,
    handleInvoices,
    onSelectAll,
    selectedBucket,
    purchaseInvoicesList,
    purchaseInvoiceViewMode,
    togglePurchaseInvoiceViewMode,
    SupplierViewMode,
    toggleSupplierViewMode,
    TimelineViewMode,
    toggleTimelineViewMode,
    timeLineCustomRangeMode,
    toggletimeLineCustomRangeMode,
    SupplierSortingViewMode,
    toggleSupplierViewSortingMode,
    totalRecords,
    isSearching,
    totalPayable,
    isSavingBatch,
    setIsSavingBatch,
    isT2TPhaseTwo,
    isScheduledPaymentsV2,
    t2TV3,
  } = props;

  const classes = useAPInvoicesSingleViewList();
  const [selectedPurchaseInvoicesList, setSelectedPurchaseInvoiceList] = useState<Array<number>>([]);
  const [selectedDebitNotesList, setSelectedDebitNotesList] = useState<Array<number>>([]);
  const [showInvoice, setShowInvoice] = useState<boolean>(false);
  const [invoiceId, setInvoiceId] = useState<IAPInvoiceId>();
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof IPurchaseInvoice>('SupplierName');
  const [showConnectedSupplierTooltipId, setShowConnectedSupplierTooltipId] = useState<number>();
  const history = useHistory();

  let {transactionId} = useParams<{transactionId: string}>();

  const listContainer = css({
    maxHeight: 'calc( 100vh - 20.5em )',
    borderRadius: '6px',
    boxShadow: '0px 0px 6px 0px #D3E5EF',
  });

  useEffect(() => {
    let ids: number[] = [];
    let debitIds: number[] = [];
    Object.keys(selectedInvoices).forEach(key => {
      if (Array.isArray(selectedInvoices[key]?.purchaseInvoiceIds)) {
        ids = [...ids, ...selectedInvoices[key].purchaseInvoiceIds];
      }
      if (Array.isArray(selectedInvoices[key]?.debitNotesIds)) {
        debitIds = [...debitIds, ...selectedInvoices[key].debitNotesIds];
      }
    });
    setSelectedPurchaseInvoiceList(ids);
    setSelectedDebitNotesList(debitIds);
  }, [selectedInvoices]);

  if (!purchaseInvoicesList.length || (isSearching && !purchaseInvoicesList.length)) {
    return (
      <div
        className={`${listContainer} invoice-list-col flex min-h-full flex-col overflow-y-auto rounded`}
        id="purchase-invoice-list"
      >
        <p className="no-result-found font-poppins">
          {isSearching
            ? 'LOADING...'
            : selectedBucket === PurchaseInvoiceRequestedList.Search
              ? `NO RESULTS FOUND.`
              : `${getMessage(selectedBucket)}`}
        </p>
      </div>
    );
  }

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof IPurchaseInvoice) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const createSortHandler = (property: keyof IPurchaseInvoice) => (event: React.MouseEvent<unknown>) => {
    handleRequestSort(event, property);
  };

  const recordsToConsider =
    totalRecords - purchaseInvoicesList.filter(pi => pi.Status === PaymentStatusType.Paid).length;
  return (
    <>
      {showInvoice && (
        <APInvoiceDetailView open={showInvoice} onClose={() => setShowInvoice(false)} apInvoiceId={invoiceId} />
      )}
      <div
        className={`${listContainer} flex min-h-full flex-col overflow-y-auto p-2.5 font-poppins`}
        id="purchase-invoice-list"
      >
        <PurchaseInvoiceListFilter
          selectedInvoiceTotal={totalPayable}
          onSelectAll={onSelectAll}
          isAllSuppliersSelected={Boolean(
            recordsToConsider && selectedPurchaseInvoicesList.length === recordsToConsider,
          )}
          purchaseInvoiceViewMode={purchaseInvoiceViewMode}
          togglePurchaseInvoiceViewMode={togglePurchaseInvoiceViewMode}
          supplierViewMode={SupplierViewMode}
          toggleSupplierViewMode={toggleSupplierViewMode}
          toggleTimelineViewMode={toggleTimelineViewMode}
          timelineViewMode={TimelineViewMode}
          timeLineCustomRangeMode={timeLineCustomRangeMode}
          toggletimeLineCustomRangeMode={toggletimeLineCustomRangeMode}
          toggleSupplierViewSortingMode={toggleSupplierViewSortingMode}
          SupplierSortingViewMode={SupplierSortingViewMode}
          selectedBucket={selectedBucket}
          isSavingBatch={isSavingBatch}
          setIsSavingBatch={setIsSavingBatch}
          t2TV3={t2TV3}
        />

        <TableContainer className={`${classes.invoicesTable} mt-5 !px-0`}>
          <Table className="invoiceTableCell" aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center" style={{width: '4%'}}></TableCell>
                <TableCell align="left" style={{width: '22%'}}>
                  Vendor
                </TableCell>
                <TableCell align="left" style={{width: '14%'}}>
                  Purchase Invoice
                </TableCell>

                <TableCell align="left" style={{width: '15%'}}>
                  <TableSortLabel
                    IconComponent={ArrowDropDownIcon}
                    active={orderBy === 'DueDate'}
                    direction={orderBy === 'DueDate' ? order : 'asc'}
                    onClick={createSortHandler('DueDate')}
                  >
                    {'Due Date'}
                  </TableSortLabel>
                </TableCell>
                <TableCell align="right" style={{width: '15%'}}>
                  <TableSortLabel
                    IconComponent={ArrowDropDownIcon}
                    active={orderBy === 'TotalInc'}
                    direction={orderBy === 'TotalInc' ? order : 'asc'}
                    onClick={createSortHandler('TotalInc')}
                  >
                    {'Invoice Total'}
                  </TableSortLabel>
                </TableCell>
                <TableCell align="right" style={{width: '16%'}}>
                  <TableSortLabel
                    IconComponent={ArrowDropDownIcon}
                    active={orderBy === 'Balance'}
                    direction={orderBy === 'Balance' ? order : 'asc'}
                    onClick={createSortHandler('Balance')}
                  >
                    {'Outstanding'}
                  </TableSortLabel>
                </TableCell>
                <TableCell align="right" style={{width: '18%'}}>
                  <TableSortLabel
                    IconComponent={ArrowDropDownIcon}
                    active={orderBy === 'Balance'}
                    direction={orderBy === 'Balance' ? order : 'asc'}
                    onClick={createSortHandler('Balance')}
                  >
                    {'Pay Amount'}
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!!purchaseInvoicesList?.length &&
                stableSort<IPurchaseInvoice>(purchaseInvoicesList, getComparator<IPurchaseInvoice>(order, orderBy)).map(
                  pi => (
                    <TableRow
                      key={pi.TransID}
                      className={`${
                        pi.LinkedDocumentGUID !== '' && pi.LinkedDocumentGUID !== null && isT2TPhaseTwo && !t2TV3
                          ? `${classes.invoiceDisable}`
                          : ''
                      }`}
                    >
                      <TableCell className="bg-spenda-cream">
                        <div
                          className={`flex w-full items-center justify-center ${
                            Boolean(parseInt(transactionId!) === pi.TransID) ? 'selected-invoice-card' : ''
                          }`}
                        >
                          {pi.LinkedDocumentGUID !== '' && pi.LinkedDocumentGUID !== null && isT2TPhaseTwo && !t2TV3 ? (
                            <APDisableSelectTogglerIcon className="min-w-[22px]" />
                          ) : (
                            <div
                              onClick={() => {
                                if (pi.Status !== PaymentStatusType.Paid) {
                                  if (!isSavingBatch) {
                                    setIsSavingBatch(true);
                                    if (pi?.TransType === 'DebitNote') {
                                      handleDebitNotes(pi.TransID, pi.SupplierID);
                                    } else {
                                      handleInvoices(pi.TransID, pi.SupplierID);
                                    }
                                  }
                                }
                              }}
                              data-autoid={`chkSelectToggler-${pi.TransID}`}
                              className={`${pi.Status === PaymentStatusType.Paid ? 'opacity-50' : ''}`}
                            >
                              <SelectToggler
                                isSelected={
                                  selectedPurchaseInvoicesList.includes(pi.TransID) ||
                                  selectedDebitNotesList.includes(pi.TransID)
                                }
                                isAccountsPayable={true}
                                dataAutoId={`imgSelectToggler-${pi.TransID}`}
                              />
                            </div>
                          )}
                        </div>
                      </TableCell>
                      <TableCell className="!flex">
                        <span className="font-poppins text-base font-medium text-black-800">
                          {pi?.SupplierName?.trim().length > 1 ? pi.SupplierName : pi.SourceSupplierName}
                        </span>
                        {isT2TPhaseTwo && pi?.IsLinked && (
                          <div className="mt-1 opacity-100 ">
                            <ClickAwayListener
                              onClickAway={e => {
                                e.preventDefault();
                                e.stopPropagation();
                                setShowConnectedSupplierTooltipId(undefined);
                              }}
                            >
                              <APTooltip
                                id={'APBillsTooltip'}
                                arrow
                                interactive
                                open={
                                  showConnectedSupplierTooltipId !== undefined &&
                                  pi.TransID === showConnectedSupplierTooltipId
                                }
                                placement="top-start"
                                title={
                                  <React.Fragment>
                                    <p className="w-[220px] px-3 text-center  font-poppins text-[10px] font-medium text-black-800">
                                      To pay this supplier blocked invoices please visit:
                                      <span
                                        className="cursor-pointer text-primary"
                                        onClick={() => {
                                          pi.MarketPlacePath !== ''
                                            ? history.replace(`/${pi.MarketPlacePath}?redirectedFromAPBills=true`)
                                            : null;
                                        }}
                                      >
                                        {' '}
                                        {pi?.SupplierName}{' '}
                                      </span>
                                    </p>
                                  </React.Fragment>
                                }
                              >
                                <div
                                  onClick={e => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    e.nativeEvent.stopImmediatePropagation();
                                    setShowConnectedSupplierTooltipId(pi.TransID);
                                  }}
                                >
                                  <SelectedBillsDetails className="ml-2 cursor-pointer" />
                                </div>
                              </APTooltip>
                            </ClickAwayListener>
                          </div>
                        )}
                      </TableCell>
                      <TableCell align="left">
                        <div className="flex flex-row items-center justify-between">
                          <span
                            className="cursor-pointer font-poppins text-base font-semibold text-primary underline"
                            onClick={() => {
                              if (pi.LinkedDocumentGUID !== '' && pi.LinkedDocumentGUID !== null && isT2TPhaseTwo)
                                return;
                              setInvoiceId({
                                transId: pi.TransID,
                                supplierId: pi.SupplierID,
                              });
                              setShowInvoice(true);
                            }}
                            data-autoid={`lnkRefNumber-${pi.TransID}`}
                          >
                            {pi.RefNumber}
                          </span>
                          {isScheduledPaymentsV2 && false && (
                            <ARTooltip
                              arrow
                              title={
                                <React.Fragment>
                                  <h4 className="text-center font-poppins text-[10px] font-medium text-spenda-primarytext">
                                    Payment Scheduled
                                  </h4>
                                  <ul className="mt-3">
                                    <li className="flex items-center justify-start gap-2 pb-1">
                                      <p className="whitespace-nowrap font-poppins text-[10px] font-medium text-[#B2B2B2]">
                                        Payment Date:
                                      </p>
                                      <p
                                        data-autoid={`lblDateScheduledPayment${pi.TransID}APInvoiceSingleViewList`}
                                        className="font-poppins text-[10px] font-semibold text-black-800"
                                      >
                                        12/10/12
                                      </p>
                                      <p
                                        data-autoid={`lblAmountScheduledPayment${pi.TransID}APInvoiceSingleViewList`}
                                        className="font-poppins text-[10px] font-semibold text-black-800"
                                      >
                                        $10,000
                                      </p>
                                    </li>
                                  </ul>
                                </React.Fragment>
                              }
                            >
                              <button className="ml-2 flex h-[42px] w-9 cursor-pointer items-center justify-center bg-[#e6e4f5]">
                                <IconScheduledPayments />
                              </button>
                            </ARTooltip>
                          )}
                        </div>
                      </TableCell>
                      <TableCell align="left">
                        <span
                          className={`${
                            pi.OverDueByNoOfDays
                              ? `${classes.overDueCellColor} disableOverdue`
                              : `${classes.dueCellColor} disableOverdue`
                          } font-poppins text-base font-semibold`}
                        >
                          {dateFormater(pi.DueDate)}
                        </span>
                      </TableCell>
                      <TableCell align="right">
                        <span className="font-poppins text-base font-medium text-black-800" style={{color: '#999999'}}>
                          {PriceFormat(pi.TotalInc)}
                        </span>
                      </TableCell>
                      <TableCell align="right">
                        <span className="font-poppins text-base font-medium text-black-800">
                          {PriceFormat(pi.Balance)}
                        </span>
                      </TableCell>
                      <TableCell align="right">
                        <span className="font-poppins text-base font-medium text-black-800">
                          {PriceFormat(pi.Balance)}
                        </span>
                      </TableCell>
                    </TableRow>
                  ),
                )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};
