import React, {useContext, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {Formik} from 'formik';
import clsx from 'clsx';
import * as Yup from 'yup';

import AppContext from '../../context/app/appContext';
import {AUTH_PURCHASING_PURCHASE_DASHBOARD_LIST} from '../../routes/PurchasingRoutes';
import {IUserAccountInfo} from '../../model/user/UserAccountInfo';
import {SDialog} from '../modals/modalSpendaMeterialUI';
import LoadingIndicator from '../ui/LoadingIndicator';
import {FilterMenuItem} from '../menu/FilterMenu';
import {PrimaryButton, SecondaryButton} from '../buttons/DefaultButtons';
import useRequisitionAPI from '../../services/useRequisitionAPI';
import useUsersAPI from '../../services/useUsersAPI';
import {Toast} from '../../utils/Toast';

import {
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  makeStyles,
  withStyles,
} from '@material-ui/core';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

interface ICreatePurchaseInvoice {
  open: boolean;
  onClose: () => void;
  requisitionID?: any;
}

const useAssignUserDialog = makeStyles(() => ({
  dialogPaper: {
    fontFamily: 'Poppins, sans-serif',
    width: '596px',
    height: '343px',
  },
  dialogTitle: {
    borderBottom: '1px solid #EAEAEA',
    '& h2': {
      fontSize: '22px',
      fontWeight: 300,
      fontFamily: 'Poppins, sans-serif',
    },
  },
  dialogContent: {},
  dialogActions: {
    margin: '8px',
    justifyContent: 'space-between',
    backgroundColor: '#EFEFEF',
    borderRadius: '6px',
    padding: '5px',
  },
  iconButton: {
    color: '#1C78AD',
    border: '1px solid #1C78AD',
    borderRadius: '6px',
    padding: '5px',
    margin: '0 0.25rem',
    width: 40,
    height: 40,
    position: 'absolute',
  },
  popover: {
    backgroundColor: 'transparent !important',
    '& .MuiPaper-root': {
      backgroundColor: '#FFFFFF',
      border: '1px solid #1C78AD',
      borderRadius: '0 0 6px 6px',
      fontFamily: 'Poppins',
    },
  },
}));

const StyledTextField = withStyles({
  root: {
    '& .MuiInputBase-root': {
      '&::before, &::after': {
        display: 'none',
      },
      '& svg, input::placeholder': {
        color: '#1C78AD',
      },
      '& .MuiSelect-root': {
        border: '1px solid #1C78AD',
        borderRadius: '6px',
        padding: '12px 24px 12px 12px',
        fontFamily: 'Poppins',
        color: '#1C78AD',
        fontWeight: 600,
        '&[aria-expanded="true"]': {
          borderRadius: '6px 6px 0 0',
        },
      },
      '& input': {
        marginBottom: 0,
      },
    },
    '& .MuiFormHelperText-root': {
      fontFamily: 'Poppins',
    },
  },
})(TextField);

const validationSchema = Yup.object({
  AssignedToUserID: Yup.string().required('Select a user to proceed.'),
});

export interface IAssignUser {
  AssignedToUserID: number | string;
}

const assignUserValues: IAssignUser = {
  AssignedToUserID: '',
};

export const AssignUserDialog = (props: ICreatePurchaseInvoice) => {
  const history = useHistory();
  const {tenantInfo} = useContext(AppContext);
  const classes = useAssignUserDialog();
  const {open, onClose, requisitionID} = props;

  const userID = tenantInfo?.TenantUserDetails?.UserID;

  const [users, setUsers] = React.useState<IUserAccountInfo[]>([]);

  const {assignUser: assignUserRequisition, isLoading: requisitionLoading} = useRequisitionAPI();
  const {get: getUsers, isLoading: userLoading} = useUsersAPI();

  const isLoading = requisitionLoading || userLoading;

  useEffect(() => {
    if (open && requisitionID) {
      getUsers({
        ExcludeUserRoleIDs: [3000, 202],
      }).then(res => {
        if (res.IsSuccess) {
          setUsers(res.Items.filter(user => user.UserID !== userID));
        }
      });
    }
  }, [open, requisitionID]);

  const handleCancelClick = () => {
    onClose();
  };

  const onSubmit = async (values: IAssignUser): Promise<void> => {
    if (requisitionID) {
      return assignUserRequisition(Number(requisitionID), values).then(() => {
        Toast.info('Requisition updated');
        history.push(AUTH_PURCHASING_PURCHASE_DASHBOARD_LIST);
      });
    }
  };

  return (
    <SDialog classes={{paper: classes.dialogPaper}} fullWidth maxWidth="sm" open={open}>
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={assignUserValues}
        onSubmit={onSubmit}
      >
        {props => (
          <>
            <DialogTitle
              className={clsx(classes.dialogTitle, 'font-lg text-center font-light text-spenda-primarytext')}
            >
              Assign requisition for approval
              <IconButton
                disableRipple
                className={clsx(classes.iconButton, 'right-4 top-4')}
                data-autoid="btnClose"
                onClick={() => onClose()}
              >
                <CloseRoundedIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent className="flex flex-col items-center justify-center">
              <LoadingIndicator
                isLoading={isLoading}
                position={{
                  height: '100% !important',
                  display: 'flex',
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
                size="md"
                color="#1C78AD"
              />
              <StyledTextField
                select
                fullWidth
                className="max-w-xs"
                id="AssignedToUserID"
                name="AssignedToUserID"
                placeholder="Select User"
                value={props.values.AssignedToUserID}
                onBlur={props.handleBlur}
                onChange={props.handleChange}
                helperText={props.touched?.AssignedToUserID && props.errors?.AssignedToUserID}
                error={props.touched?.AssignedToUserID && Boolean(props.errors?.AssignedToUserID)}
                SelectProps={{
                  displayEmpty: true,
                  MenuProps: {
                    anchorOrigin: {vertical: 'bottom', horizontal: 'left'},
                    getContentAnchorEl: null,
                    PopoverClasses: {
                      root: classes.popover,
                    },
                  },
                }}
              >
                <FilterMenuItem value="" disabled>
                  Select user
                </FilterMenuItem>
                {users.map(user => (
                  <FilterMenuItem key={user.UserID} value={user.UserID}>
                    {`${user.FirstName} ${user.LastName}`}
                  </FilterMenuItem>
                ))}
              </StyledTextField>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              <SecondaryButton label="Cancel" onClick={handleCancelClick} />
              <PrimaryButton label="Assign" disabled={!props.isValid} onClick={props.handleSubmit} />
            </DialogActions>
          </>
        )}
      </Formik>
    </SDialog>
  );
};
