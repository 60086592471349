export const scrollToBottom = (componentRef: React.RefObject<HTMLDivElement>) => {
  if (componentRef.current) {
    const scrollHeight = componentRef.current.scrollHeight;
    const clientHeight = componentRef.current.clientHeight;
    const maxScrollTop = scrollHeight - clientHeight;
    const duration = 1000;
    const start = performance.now();

    const scroInllStep = (timestamp: number) => {
      const elapsed = timestamp - start;
      const progress = Math.min(elapsed / duration, 1);
      const ease = (progress: number) => progress * (2 - progress);

      if (componentRef?.current) {
        componentRef.current.scrollTop = maxScrollTop * ease(progress);
      }

      if (progress < 1) {
        requestAnimationFrame(scroInllStep);
      }
    };

    requestAnimationFrame(scroInllStep);
  }
};
