import React, {useContext} from 'react';
import AppContext from '../../context/app/appContext';
import {LogoName} from '../../components/LogoName';
import {css} from 'glamor';
import {Box} from '@material-ui/core';
import {motion} from 'framer-motion';
import {SButton} from '../../components/buttons/Button';
import {useHistory} from 'react-router-dom';

export const NotFound = () => {
  const appContext = useContext(AppContext);

  const notFound = css({
    opacity: '20%',
    fontSize: '20rem',
  });

  const pageDoesNotExist = css({
    opacity: '45%',
    fontSize: '3rem',
  });

  const history = useHistory();

  const onClickBack = () => {
    history.goBack();
  };

  return (
    <Box display="flex" position="center" className="bckg-spenda items-center justify-center h-full">
      <motion.div initial={{opacity: 0}} animate={{opacity: 1}} transition={{duration: 1}}>
        <Box>
          <motion.div
            initial={{y: -250}}
            animate={{
              y: -10,
              rotate: [270, 360],
            }}
            transition={{delay: 0.5, type: 'spring', stiffness: 400}}
          >
            <Box display="flex" className={`${notFound} justify-center text-white text-xl`}>
              404
            </Box>
          </motion.div>
          <motion.div
            className="text-xl"
            initial={{x: 0}}
            animate={{x: 'calc(50% - 45px)'}}
            transition={{type: 'spring', stiffness: 120}}
          >
            <SButton className={`${pageDoesNotExist}`} fontSize="l" lineHeight="xl" onClick={onClickBack}>
              {' '}
              GO BACK{' '}
            </SButton>
          </motion.div>

          <Box display="flex" className={`${pageDoesNotExist} justify-center text-white text-xl`}>
            Oops, the page you tried to access does not exist!
          </Box>
        </Box>
        {appContext.marketplaceSupplier && <LogoName isDefaultSpenda={true} className={'spenda-powered'}></LogoName>}
      </motion.div>
    </Box>
  );
};
