import React, {useContext, useEffect, useState} from 'react';
import {Redirect, RouteProps, useHistory, useParams} from 'react-router-dom';
import {AlertDialogSlide} from '../../components/dialog/AlertDialogSlide';
import {ILayoutProps, Layout} from '../../components/layout/Layout';
import AppContext from '../../context/app/appContext';
import useConnectedSupplier from '../../hooks/useConnectedSupplier';
import {useFeatureFlags} from '../../hooks/useFeatureFlags';
import {IActionRes} from '../../model/ActionResults';
import {ButtonType, MarketplaceConnectionStatus, ModuleTypes} from '../../model/constants/Constants';
import {ICreditCardT} from '../../model/payment/PaymentMethod';
import {UserRoleEnum} from '../../model/user/UserAccountInfo';
import {AUTH_MARKETPLACE_RESERVED_ROUTES, AUTH_SUPPLIER_ROUTE} from '../../routes/AuthenticatedRoutes';
import useMarketPlaceAPI from '../../services/useMarketPlaceAPI';
import {Toast} from '../../utils/Toast';
import {TenantSetupComponent} from '../../components/tenantSetup/TenantSetupComponent';
import {Box} from '@material-ui/core';
import MockHomeBackground from '../../assets/png/MockHomeScreenBlur.png';
import {ManageInvoicesDialog} from '../../components/dialog/ManageInvoicesDialog';
import {useSupplierAPI} from '../../services/useSupplierAPI';
import {AUTH_BUYING_AP_REQUIRES_ATTENTION_ROUTE} from '../../routes/AccountsPayableRoutes';
import _ from 'lodash';
import {ISupplierMarket} from '../../model/SupplierMarket';
import {isWebsiteId17} from '../../config';

interface IMarketplaceLayoutProps extends ILayoutProps {
  setOneOffPaymentMethod?: (card?: ICreditCardT) => void;
}

export const MarketplaceLayout = (props: IMarketplaceLayoutProps & RouteProps) => {
  const {
    marketplaceSupplier: mpSupplier,
    tenant,
    supplierToLink,
    suppliers,
    tenantInfo,
    user,
    setSupplierToLink,
    switchSupplier,
    logout,
    setMarketplaceSupplier,
    setConnectedSuppliers,
  } = useContext(AppContext);

  // State
  const [modal, setModal] = useState<JSX.Element | undefined>(props.modal);
  const [showManageInvoicesDialog, setShowManageInvoicesDialog] = useState<boolean>(false);
  const [redirectToAP, setRedirectToAP] = useState<boolean>(false);
  const [connectedSupplierId, setConnectedSupplierId] = useState<number>();
  const [connectionStatus, setConnectionStatus] = useState<{isConnected: boolean; redirectPath: string}>({
    isConnected: false,
    redirectPath: '/',
  });

  // Feature Flags
  const {T2TPhase280729} = useFeatureFlags().tenantOwned();

  // API
  const {joinSupplier} = useMarketPlaceAPI();
  const {updateAutomaticInvoiceImportEnabled, isLoading} = useSupplierAPI();

  // Hooks
  const params = useParams<{marketPlacePath?: string; supplierId?: string}>();
  const history = useHistory();
  const {getRedirectPath} = useConnectedSupplier();

  // Constant
  const query = new URLSearchParams(history.location.search);
  const isSupportUser = tenantInfo?.TenantUserDetails.UserRoles.some(ur => ur.UserRoleEnum === UserRoleEnum.Support);
  const redirectedFromAPBills = query.get('redirectedFromAPBills') || undefined;
  const dialog = query.get('dialog');
  const fn = query.get('fname') || '';
  const ln = query.get('lname') || '';
  const tn = query.get('tname') || '';

  useEffect(() => {
    const changeSupplierMarketplace = async () => {
      if (params.supplierId && parseInt(params.supplierId) != mpSupplier?.SupplierID) {
        const s = suppliers?.find(s => s.SupplierID == parseInt(params.supplierId || '0'));
        if (!s) {
          return;
        }
        await switchSupplier(s.AvailableModules, s.MarketplacePath);
        return;
      }
    };

    changeSupplierMarketplace();
  }, [params.supplierId]);

  useEffect(() => {
    let mount = true;

    if (
      history.location.pathname.includes(AUTH_SUPPLIER_ROUTE) &&
      AUTH_MARKETPLACE_RESERVED_ROUTES.some(s => history.location.pathname.includes(s))
    )
      return;

    if (params.marketPlacePath) {
      const s = suppliers?.find(s => s.MarketplacePath?.toLowerCase() === params.marketPlacePath?.toLowerCase());

      if (!s) {
        if (!mount) return;
        setSupplierToLink(params.marketPlacePath);
      } else {
        // Check status of connection
        switch (s.Status) {
          case MarketplaceConnectionStatus.Connected:
            if (!mount) return;
            const rp = getRedirectPath(s);
            if (
              s.SupplierID &&
              T2TPhase280729 &&
              user?.Modules?.includes(ModuleTypes.SpendaPay.toString()) &&
              user?.Modules?.includes(ModuleTypes.Suppliers.toString()) &&
              !redirectedFromAPBills &&
              !isWebsiteId17
            ) {
              setConnectedSupplierId(s.SupplierID);
              if (!s?.IsAutomaticInvoiceImportSetup) setShowManageInvoicesDialog(true);
              else if (s?.IsAutomaticInvoiceImportEnabled) setRedirectToAP(true);
            }
            setConnectionStatus({
              isConnected: true,
              redirectPath: rp,
            });
            break;
          case MarketplaceConnectionStatus.ConnectionPending:
          case MarketplaceConnectionStatus.NotConnected:
          case MarketplaceConnectionStatus.Disconnected:
            if (!mount) return;
            setSupplierToLink(params.marketPlacePath);
            break;
        }
      }
    }
    return () => {
      mount = false;
    };
  }, [params.marketPlacePath]);

  useEffect(() => {
    switch (supplierToLink?.Status) {
      case MarketplaceConnectionStatus.ConnectionPending:
        setModal(pendingConnectionModal);
        break;
      case MarketplaceConnectionStatus.NotConnected:
        setModal(notConnectedModal);
        break;
      case MarketplaceConnectionStatus.Disconnected:
        setModal(disconnectedModal);
        break;
      case MarketplaceConnectionStatus.NonExistent:
        setModal(nonExistentModal);
        break;
      default:
        setModal(props.modal);
        break;
    }
  }, [supplierToLink?.Status, props.modal]);

  useEffect(() => {
    if (!dialog) {
      setModal(undefined);
    }

    switch (dialog) {
      case '1':
        {
          setShowManageInvoicesDialog(true);
          params.supplierId && setConnectedSupplierId(Number(params.supplierId));
        }
        break;
      case '2':
        <TenantSetupComponent
          tenant={tenant}
          user={{...user, FirstName: fn, LastName: ln, TenantName: tn}}
          onAfterSubmit={() => (window.location.href = `${history.location.pathname}`)}
        />;

        break;
    }
  }, [dialog]);

  const onCloseDialog = () => {
    setSupplierToLink();
    history.goBack();
  };

  const joinMarketPlaceSupplier = () => {
    const path = supplierToLink?.MarketPlacePath;
    if (path) {
      joinSupplier(path).then((response: IActionRes) => {
        if (response.IsSuccess) {
          setModal(pendingConnectionModal);
        } else {
          if (response.Messages?.length) {
            response.Messages.map((msg: string) => Toast.info(msg));
          }
        }
      });
    }
  };

  const nonExistentModal = (
    <AlertDialogSlide
      title={`Marketplace '${supplierToLink?.MarketPlacePath}' does not exist `}
      actions={[
        {label: 'CLOSE', action: onCloseDialog, color: 'white', textColor: 'blue', buttonType: ButtonType.SECONDARY},
        {label: 'LOGOUT', action: logout, buttonType: ButtonType.PRIMARY},
      ]}
      titleColor={'blueShade'}
    >
      The marketplace you are trying to access does not exist.
      <br />
      Check for spelling mistakes or contact your supplier for the right link.
    </AlertDialogSlide>
  );

  const pendingConnectionModal = (
    <AlertDialogSlide
      title={`${supplierToLink?.TenantName}`}
      actions={[
        {label: 'CLOSE', action: onCloseDialog, color: 'white', textColor: 'blue'},
        {label: 'LOGOUT', action: logout},
      ]}
    >
      Your request to connect is pending.
      <br />
      You will be able to transact with <b>{supplierToLink?.TenantName}</b> once your request is accepted.
    </AlertDialogSlide>
  );

  const notConnectedModal = (
    <AlertDialogSlide
      title="You are not connected to this supplier yet"
      actions={[
        {label: 'CLOSE', action: onCloseDialog, color: 'white', textColor: 'blue'},
        {label: isSupportUser ? 'YES' : 'LOGOUT', action: isSupportUser ? joinMarketPlaceSupplier : logout},
      ]}
    >
      {isSupportUser ? (
        <p>
          Do you want to connect <b>{tenant?.Name}</b> to the supplier <b>{supplierToLink?.TenantName}</b>?
        </p>
      ) : (
        <p>
          Please contact <b>{supplierToLink?.TenantName}</b> to invite you as a customer.
        </p>
      )}
    </AlertDialogSlide>
  );

  const disconnectedModal = (
    <AlertDialogSlide
      title="You are no longer connected to this supplier"
      actions={[
        {label: 'CLOSE', action: onCloseDialog, color: 'white', textColor: 'blue'},
        {label: 'LOGOUT', action: logout},
      ]}
    >
      <p>
        You are not able to transact with <b>{supplierToLink?.TenantName}</b> anymore.
      </p>
    </AlertDialogSlide>
  );

  const updateSupplierGobalContext = (importInvoices: boolean) => {
    const connectedSuppliers = _.cloneDeep(suppliers);
    const suppIndex = connectedSuppliers.findIndex(s => s.SupplierID === connectedSupplierId);
    if (suppIndex !== -1) {
      connectedSuppliers[suppIndex].IsAutomaticInvoiceImportSetup = true;
      connectedSuppliers[suppIndex].IsAutomaticInvoiceImportEnabled = importInvoices;
      setMarketplaceSupplier(connectedSuppliers[suppIndex] as ISupplierMarket);
      setConnectedSuppliers(connectedSuppliers);
    }
  };

  const handleManageInvoiceDialog = async (importInvoices: boolean) => {
    if (connectedSupplierId) {
      const response: IActionRes = await updateAutomaticInvoiceImportEnabled(importInvoices, connectedSupplierId);
      if (response?.IsSuccess && (suppliers || mpSupplier)) {
        setRedirectToAP(importInvoices);
        setShowManageInvoicesDialog(false);
        if (dialog) {
          if (!importInvoices) {
            window.location.href = `${history.location.pathname}`;
          } else {
            window.location.href = `${AUTH_BUYING_AP_REQUIRES_ATTENTION_ROUTE}`;
          }
        }
        updateSupplierGobalContext(importInvoices);
      }
    }
  };

  return connectionStatus.isConnected && !showManageInvoicesDialog ? (
    redirectToAP ? (
      <Redirect to={`${AUTH_BUYING_AP_REQUIRES_ATTENTION_ROUTE}`} />
    ) : (
      <Redirect to={connectionStatus.redirectPath} />
    )
  ) : showManageInvoicesDialog ? (
    <Box>
      <img alt={'background'} src={MockHomeBackground} className="absolute bottom-0 left-0 right-0 h-full w-full" />
      <ManageInvoicesDialog
        handleImportInvoices={() => handleManageInvoiceDialog(true)}
        handleClose={() => handleManageInvoiceDialog(false)}
        isLoading={isLoading}
      />
    </Box>
  ) : (
    <div style={{paddingBottom: '40px'}} className={`relative h-full overflow-hidden bg-primary-background`}>
      <Layout
        leftPanel={props.leftPanel}
        mainPanel={props.mainPanel}
        rightPanel={props.rightPanel}
        modal={modal}
        toggleView={props.toggleView}
        splitWidthType={props?.splitWidthType}
      />
    </div>
  );
};
