import * as React from 'react';
import {SVGProps} from 'react';

export const PaperPlane = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <title>{'Icons/Documents/paper_plane'}</title>
    <g id="Spenda-Pay-Onboarding" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <g id="Spenda-Collect-Supplier-Interface-Copy-23" transform="translate(-1163.000000, -279.000000)" fill="#C55D44">
        <g id="Icons/Documents/paper_plane" transform="translate(1163.000000, 279.000000)">
          <path
            d="M23.1164607,0.881465685 C22.3872817,0.143440989 21.3254748,-0.159932399 20.3164607,0.0814656854 L4.31646069,3.45146569 C2.43696141,3.7092255 0.86570747,5.00945172 0.260835523,6.80753058 C-0.344036424,8.60560944 0.121891628,10.5911417 1.46346069,11.9324657 L3.18146069,13.6494657 C3.36925525,13.8372034 3.47466944,14.0919244 3.47446069,14.3574657 L3.47446069,17.5254657 C3.47666758,17.9708959 3.57920664,18.4101049 3.77446069,18.8104657 L3.76646069,18.8174657 L3.79246069,18.8434657 C4.08547395,19.4325957 4.56404278,19.9090563 5.15446069,20.1994657 L5.18046069,20.2254657 L5.18746069,20.2174657 C5.58782151,20.4127197 6.02703049,20.5152588 6.47246069,20.5174657 L9.64046069,20.5174657 C9.90551205,20.517257 10.1598044,20.622283 10.3474607,20.8094657 L12.0644607,22.5264657 C12.9946187,23.4669249 14.2617165,23.9971621 15.5844607,23.9994657 C16.1302127,23.9987872 16.6722448,23.9096305 17.1894607,23.7354657 C18.9711605,23.1504226 20.2670506,21.6057402 20.5334607,19.7494657 L23.9084607,3.71446569 C24.1624572,2.6965425 23.8615217,1.62009004 23.1164607,0.881465685 Z M4.59746069,12.2374657 L2.87846069,10.5204657 C2.06126293,9.72295018 1.779755,8.52439703 2.15646069,7.44646569 C2.52179176,6.34028101 3.49858726,5.54868594 4.65646069,5.42046569 L20.4974607,2.08546569 L5.47246069,17.1124657 L5.47246069,14.3574657 C5.47551891,13.5623221 5.16045916,12.7989774 4.59746069,12.2374657 Z M18.5684607,19.4074657 C18.4118553,20.534033 17.631624,21.4751542 16.5535912,21.8378172 C15.4755584,22.2004803 14.2851424,21.9223082 13.4794607,21.1194657 L11.7594607,19.3994657 C11.1986878,18.8356136 10.4356912,18.5194687 9.64046069,18.5214657 L6.88546069,18.5214657 L21.9124607,3.49946569 L18.5684607,19.4074657 Z"
            id="Shape"
          />
        </g>
      </g>
    </g>
  </svg>
);
