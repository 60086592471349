import React, {useContext, useState, useEffect} from 'react';
import AppContext from '../../context/app/appContext';
import {ITenantSupplierPaymentMethod} from '../../model/payment/PaymentMethod';
import useSpendaPaymentServicesAPI, {
  IAccountResponse,
  INewAccountResponse,
  IAddBankAccountDetailsRequest,
} from '../../services/useSpendaPaymentServicesAPI';
import {AccountType, AccountUsage, PaymentProviderName} from '../../model/constants/Constants';
import {Toast} from '../../utils/Toast';
import {PennyVerificationDialog, PennyVerificationDialogStages} from '../dialog/PennyVerificationDialog';
import {AddBankAccountDialog} from '../dialog/AddBankAccountDialog';

interface IAddBankAccountProps {
  loadPaymentMethods?: () => void;
  onEditPaymentMethod?: (
    method: ITenantSupplierPaymentMethod | undefined,
    account: IAccountResponse | undefined,
    isAfterCreatingAccount: boolean,
  ) => Promise<boolean>;
  supplierId?: number;
  dialogStage: PennyVerificationDialogStages;
  setDialogStage: (newStage: PennyVerificationDialogStages) => void;
  addBankAccountClicked?: boolean;
  setAddBankAccountClicked: (value: boolean) => void;
  setIsLoadingInvitation?: (value: boolean) => void;
  setDeletingCardId?: (id: string | undefined) => void;
  successCallBack?: () => void;
  dialogTitle?: () => JSX.Element;
  bankAccountDetails?: IAccountResponse | undefined;
  accountUsageContext?: AccountUsage;
}

export const AddBankAccount = (props: IAddBankAccountProps) => {
  const {
    supplierId,
    loadPaymentMethods,
    dialogStage,
    setDialogStage,
    addBankAccountClicked,
    setAddBankAccountClicked,
    onEditPaymentMethod,
    setDeletingCardId,
    successCallBack,
    dialogTitle,
    bankAccountDetails: accountDetails,
    accountUsageContext,
  } = props;
  const [newBankAccount] = useState<INewAccountResponse | undefined>(undefined);
  const [bankAccountDetails, setBankAccountDetails] = useState<IAccountResponse | undefined>(undefined);
  const appCtx = useContext(AppContext);
  const {
    getAccount,
    deleteBankAccount,
    addBankAccountDetails,
    updateAccountDetails,
    isLoading: isLoadingAddBank,
  } = useSpendaPaymentServicesAPI();

  useEffect(() => {
    setBankAccountDetails(accountDetails);
  }, [accountDetails]);

  useEffect(() => {
    if (addBankAccountClicked) {
      clickAddBankAccount();
    }
  }, [addBankAccountClicked]);

  const clickAddBankAccount = async () => {
    setDialogStage(PennyVerificationDialogStages.ADD_BANK_DETAILS);
    setAddBankAccountClicked(false);
    return;
  };

  const onClosePennyVerification = () => {
    if (loadPaymentMethods) {
      loadPaymentMethods();
    }
    if (onEditPaymentMethod) {
      onEditPaymentMethod(undefined, bankAccountDetails, true);
    }
  };

  // Can't access current state within a listener, need to add a ref workaround:
  // https://medium.com/geographit/accessing-react-state-in-event-listeners-with-usestate-and-useref-hooks-8cceee73c559
  const bankAccountRef = React.useRef(newBankAccount);

  const updateBankAccount = async () => {
    if (bankAccountRef.current) {
      const accountGUID = bankAccountRef?.current?.accountGUID!;
      return getAccount(PaymentProviderName.Spenda_Payment_Services, accountGUID).then(async res => {
        setBankAccountDetails(res);
      });
    }
  };

  const onRemoveAccount = () => {
    if (!bankAccountDetails) {
      return;
    }

    setDeletingCardId && setDeletingCardId(bankAccountDetails.accountGUID);

    if (bankAccountDetails.accountType === AccountType.BANK_TRANSFER) {
      if (bankAccountDetails.accountGUID) {
        deleteBankAccount(PaymentProviderName.Spenda_Payment_Services, bankAccountDetails.accountGUID).then(() => {
          if (loadPaymentMethods) {
            loadPaymentMethods();
          }
          setDeletingCardId && setDeletingCardId(undefined);
        });
      }
    } else {
      Toast.error("Error: Shouldn't get here. OnRemoveAccount() should only be called from creating bank account.");
    }
  };

  const onCloseAddBankAccount = () => {
    setDialogStage(PennyVerificationDialogStages.NONE);
  };

  const onSubmitAddBankAccount = async (payload: IAddBankAccountDetailsRequest) => {
    try {
      const friendlyName = payload.friendlyName?.trim();
      delete payload.friendlyName;
      const accountUsage = accountUsageContext ? accountUsageContext : AccountUsage.BUYER;
      const result = await addBankAccountDetails(payload, accountUsage, PaymentProviderName.Spenda_Payment_Services);

      if (friendlyName) {
        const updateBankAccountRequestPayload = {
          friendlyName: friendlyName,
          isInvigoDefault: false,
        };

        await updateAccountDetails(
          PaymentProviderName.Spenda_Payment_Services,
          result.accountGUID,
          updateBankAccountRequestPayload,
        );
      }

      setDialogStage(PennyVerificationDialogStages.CHECKING_BANK_ACCOUNT_STATUS);

      if (result.error) {
        Toast.error(result.error.message);
        setDialogStage(PennyVerificationDialogStages.NONE);
      } else {
        bankAccountRef.current = result;
        updateBankAccount();
      }
    } catch (error) {
      setDialogStage(PennyVerificationDialogStages.NONE);
      Toast.error('Some Error Occured');
    }
  };
  const showPennyVerificationDialog = () => {
    return ![PennyVerificationDialogStages.NONE, PennyVerificationDialogStages.ADD_BANK_DETAILS].includes(dialogStage);
  };

  const showAddBankAcountDialog = () => {
    return Boolean(
      dialogStage === PennyVerificationDialogStages.ADD_BANK_DETAILS &&
        appCtx.tenantInfo?.TenantUserDetails.TenantName &&
        appCtx.tenantInfo?.TenantUserDetails.UserName,
    );
  };
  return (
    <>
      {showAddBankAcountDialog() && (
        <AddBankAccountDialog
          onCloseAddBankAccount={onCloseAddBankAccount}
          onSubmitAddBankAccount={onSubmitAddBankAccount}
          isLoading={isLoadingAddBank}
          tenantName={appCtx.tenantInfo?.TenantUserDetails.TenantName || ''}
          userName={appCtx.tenantInfo?.TenantUserDetails.UserName || ''}
          dialogTitle={dialogTitle}
        />
      )}
      {showPennyVerificationDialog() && (
        <PennyVerificationDialog
          successCallBack={successCallBack}
          closeDialog={onClosePennyVerification}
          supplierId={supplierId}
          bankAccountDetails={bankAccountDetails}
          loadPaymentMethods={loadPaymentMethods}
          onRemoveAccount={onRemoveAccount}
          dialogStage={dialogStage}
          setDialogStage={setDialogStage}
          reloadAccount={updateBankAccount}
          dialogTitle={dialogTitle}
        />
      )}
    </>
  );
};
