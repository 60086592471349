import React from 'react';
import {Box, makeStyles} from '@material-ui/core/';
import {SButton} from '../buttons/Button';
import {ChatWidget} from '../onboarding/ChatWidget';
import LoadingIndicator from '../ui/LoadingIndicator';
interface APFooterProps {
  isAPOnboarding?: boolean;
  isDisabled?: boolean;
  buttonLabel?: string;
  isCancelButton?: boolean;
  handleCancelClick?: () => void;
  onNextClick?: () => void;
  secondaryButtonLabel?: string;
  isLoading?: boolean;
  isAPOnboardingV1?: boolean;
  isT2TSupplierModule?: boolean;
  isInviteUser?: boolean;
  handleInvite?: () => void;
  isSetupComplete?: boolean;
  APFooterClass?: string;
  conditionallyClass?: boolean;
  style?: any;
  cancelButtonType?: 'button' | 'submit' | 'reset' | undefined;
  secondaryButtonDisable?: boolean;
  isPsblOnboarding?: boolean;
}

export const APFooter: React.FunctionComponent<APFooterProps> = ({
  onNextClick,
  isDisabled,
  buttonLabel,
  isCancelButton,
  handleCancelClick,
  secondaryButtonLabel,
  isLoading,
  isAPOnboardingV1,
  isT2TSupplierModule,
  isInviteUser,
  handleInvite,
  isSetupComplete,
  APFooterClass,
  conditionallyClass,
  style,
  cancelButtonType,
  secondaryButtonDisable,
  isPsblOnboarding,
}: APFooterProps) => {
  const useAPFooterStyles = makeStyles({
    apFooterWrapper: {
      position: 'absolute',
      width: '100%',
      left: '0px',
      bottom: isT2TSupplierModule ? ' 80px' : '15px',
    },
    apFooterinner: {
      maxWidth: isAPOnboardingV1 ? ' 540px' : '660px',
      width: '100%',
      margin: '0 auto',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      background: isAPOnboardingV1 ? '#FFFFFF' : 'rgb(173 173 173 / 20%)',
      padding: '10px',
      borderRadius: '8px',
      fontFamily: 'Poppins, sans-serif !important',
    },
  });
  const classes = useAPFooterStyles();

  const handleClick = () => {
    if (onNextClick) {
      onNextClick();
    }
  };

  return (
    <Box className={`${conditionallyClass && APFooterClass} ${classes.apFooterWrapper}`} style={style}>
      <Box
        className={`${isPsblOnboarding ? 'absolute  -bottom-[24px]  left-0 flex h-[60px] w-full flex-row items-center justify-between rounded-[6px] bg-[#EFEFEF] px-2.5' : classes.apFooterinner} ${isT2TSupplierModule ? '!max-w-full' : ''} ${
          isSetupComplete ? '!justify-center' : ''
        }`}
      >
        {!isT2TSupplierModule && !isSetupComplete && (
          <Box className="flex">
            <ChatWidget isAPOnboarding={true} />
            {isInviteUser && (
              <SButton
                textColor={'hsl(var(--primary))'}
                width={'74px'}
                height="40px"
                lineHeight="l"
                fontSize="xs"
                backgroundColor={'#fff'}
                padding={' 7px 15px'}
                margin="0px 0px 0px 10px"
                variant={'outlined'}
                border={'1px solid hsl(var(--primary))'}
                borderRadius="6px"
                onClick={handleInvite}
                data-autoid="btnCancel"
              >
                Invite User
              </SButton>
            )}
          </Box>
        )}
        <Box className={isT2TSupplierModule ? 'flex w-full items-center justify-between' : ''}>
          {isCancelButton && (
            <SButton
              textColor={secondaryButtonDisable ? 'grey' : 'hsl(var(--primary))'}
              width={isAPOnboardingV1 ? '74px' : '100px'}
              height="40px"
              lineHeight="l"
              fontSize="xs"
              type={cancelButtonType ? cancelButtonType : 'submit'}
              backgroundColor={'#fff'}
              padding={isAPOnboardingV1 ? ' 7px 15px' : '7px 20px!important'}
              margin="0px 10px 0px 0px"
              variant={'outlined'}
              border={secondaryButtonDisable ? '1px solid transparent' : '1px solid hsl(var(--primary))'}
              borderRadius="6px"
              onClick={isCancelButton ? handleCancelClick : undefined}
              data-autoid="btnCancel"
              disabled={secondaryButtonDisable}
            >
              {secondaryButtonLabel ? secondaryButtonLabel : 'Cancel'}
            </SButton>
          )}
          <SButton
            textColor={isDisabled ? 'hsl(var(--primary))' : 'white'}
            width={isAPOnboardingV1 ? '75px' : '100px'}
            fontFamily="poppins"
            height="40px"
            lineHeight="l"
            fontSize="xs"
            boxShadow="none"
            type="submit"
            backgroundColor={
              isDisabled && typeof isDisabled === 'boolean' ? 'hsla(var(--primary)/4%)' : 'hsl(var(--primary))'
            }
            padding={isAPOnboardingV1 ? ' 7px 15px' : '7px 20px!important'}
            margin="0px"
            variant={isDisabled ? 'outlined' : 'contained'}
            border={isDisabled ? '1px solid hsl(var(--primary))' : '1px solid transparent'}
            borderRadius="6px"
            disabled={isDisabled && typeof isDisabled === 'boolean' ? true : false}
            onClick={handleClick}
            data-autoid={buttonLabel ? `btn${buttonLabel}` : 'btnNext'}
          >
            {buttonLabel ? buttonLabel : 'Next'}
            {isLoading && (
              <LoadingIndicator
                isLoading={isLoading}
                size="sm"
                color={isDisabled ? 'hsl(var(--primary))' : '#FFF'}
                position={{right: '5px'}}
              />
            )}
          </SButton>
        </Box>
      </Box>
    </Box>
  );
};
