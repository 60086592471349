import React from 'react';

const WebsiteArrowIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="25px"
    height="22px"
    viewBox="0 0 25 22"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <title>ArrowIcon</title>
    <g
      id="Spenda-Pay-Onboarding"
      stroke="none"
      stroke-width="1"
      fill="none"
      fillRule="evenodd"
      className="fill-primary"
    >
      <g id="3-copy" transform="translate(-680.000000, -374.000000)">
        <g id="Group-4" transform="translate(350.000000, 279.000000)">
          <g id="Imputs/TextField-Copy-3" transform="translate(0.000000, 75.000000)">
            <g id="ArrowIcon" transform="translate(330.000000, 20.000000)">
              <path
                d="M0,20.4016993 L0,13.1100327 C0.00574075933,7.93474289 4.19971024,3.74077341 9.375,3.73503265 L14.40625,3.73503265 L14.40625,2.08294932 C14.4064299,1.24045786 14.9140156,0.48100338 15.6923863,0.158620197 C16.470757,-0.163762986 17.3666786,0.0143911607 17.9625,0.610032651 L24.0875,6.73399098 C25.3074436,7.95430302 25.3074436,9.93242895 24.0875,11.152741 L17.9625,17.2766993 C17.3666786,17.8723408 16.470757,18.050495 15.6923863,17.7281118 C14.9140156,17.4057286 14.4064299,16.6462741 14.40625,15.8037827 L14.40625,14.1516993 L8.33333333,14.1516993 C4.8829817,14.1551445 2.08677852,16.9513477 2.08333333,20.4016993 C2.08333333,20.9769959 1.61696328,21.443366 1.04166667,21.443366 C0.466370052,21.443366 0,20.9769959 0,20.4016993 Z M16.4895833,4.77669932 C16.4895833,5.35199593 16.0232133,5.81836598 15.4479167,5.81836598 L9.375,5.81836598 C5.34982744,5.82295831 2.08792566,9.08486009 2.08333333,13.1100327 L2.08333333,14.8954493 C3.6629086,13.0977868 5.94029174,12.0676505 8.33333333,12.068366 L15.4479167,12.068366 C16.0232133,12.068366 16.4895833,12.534736 16.4895833,13.1100327 L16.4895833,15.8037827 L22.6135417,9.67982432 C23.0201895,9.27305364 23.0201895,8.61367833 22.6135417,8.20690765 L16.4895833,2.08294932 L16.4895833,4.77669932 Z"
                id="Shape"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default WebsiteArrowIcon;
