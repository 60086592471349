import React, {FunctionComponent, useState, InputHTMLAttributes, useCallback} from 'react';
import {css} from 'glamor';
import clsx from 'clsx';
import {debounce, omit} from 'lodash';

import {PurchaseInvoiceViewMode} from '../../model/constants/Constants';
import {ApplicationInfo} from '../../model/constants/ApplicationInfo';
import {ISearchFilter} from '../../model/search-filters/SearchFilter';
import {IAdaptor} from '../../model/synkd/Adaptor';
import {STableToolbar} from '../tables/STableToolbar';
import {SCheckbox} from '../inputs/SCheckbox';
import {PrimaryButton, SecondaryButton} from '../buttons/DefaultButtons';
import {useAdaptorAPI} from '../../services/useAdaptorAPI';
import useWorkflowAPI from '../../services/useWorkflowAPI';
import useSynkAPI from '../../services/useSynkAPI';
import {Toast} from '../../utils/Toast';

import {usePopupState, bindTrigger, bindPopover, bindPopper, bindToggle} from 'material-ui-popup-state/hooks';
import {ClickAwayListener, Grow, IconButton, Popover, Popper, TextField, makeStyles} from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import FiberManualRecordOutlinedIcon from '@material-ui/icons/FiberManualRecordOutlined';
import IconSearch from '../../assets/svg/IconSearch';

export interface IPurchaseInvoiceToolbar {
  viewMode: PurchaseInvoiceViewMode;
  title: string | React.ReactNode;
  loading: boolean;
  searchFilter: ISearchFilter;
  columns: any;
  selectedColumns: string[];
  setSelectedColumns: (_selectedColumns: string[]) => Promise<void>;
  setSearchFilter: (filter: any) => void;
  handleCreateInvoice: () => void;
}

const usePurchaseInvoiceToolbarStyles = makeStyles(() => ({
  iconButton: {
    color: '#1C78AD',
    border: '1px solid #1C78AD',
    borderRadius: '6px',
    padding: '5px',
    margin: '0 0.25rem',
    width: 40,
    height: 40,
  },
  iconButtonActive: {
    borderRadius: '0 6px 6px 0',
    backgroundColor: '#1C78AD',
    '& path': {
      fill: '#FFFFFF',
    },
    '&:hover': {
      borderRadius: '0 6px 6px 0',
      backgroundColor: '#1C78AD',
    },
  },
  textfield: {
    fontFamily: 'Poppins',
    '& .MuiOutlinedInput-root': {
      border: '1px solid #1C78AD',
      borderRadius: '6px 0 0 6px',
    },
    '& .MuiInputBase-root': {
      color: '#333333',
      padding: '0 5px',
      height: '100%',
      '&::placeholder': {
        color: '#BBBBBB',
        fontWeight: 600,
      },
    },
  },
}));

export const PurchaseInvoiceToolbar: FunctionComponent<IPurchaseInvoiceToolbar> = props => {
  const classes = usePurchaseInvoiceToolbarStyles();

  const {
    title,
    columns,
    viewMode,
    searchFilter,
    selectedColumns,
    setSearchFilter,
    setSelectedColumns,
    handleCreateInvoice,
  } = props;

  const [searchInput, setSearchInput] = useState<string>('');

  const columnSettingPopupState = usePopupState({variant: 'popover', popupId: 'columnSettingPopupState'});
  const searchInputPopup = usePopupState({variant: 'popover', popupId: 'searchInputPopup'});

  const {searchAdaptor, isLoading: isAdaptorLoading} = useAdaptorAPI();
  const {synkIsAuthorize, isLoading: isSynkLoading} = useSynkAPI();
  const {queueWorkflow, isLoading: workflowLoading} = useWorkflowAPI();

  const isLoading = isAdaptorLoading || isSynkLoading || workflowLoading;

  const handleColumnCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentSelected = [...selectedColumns];
    if (event.target.checked) {
      currentSelected.push(event.target.name);
    } else {
      const index = currentSelected.indexOf(event.target.name);
      if (index !== -1) {
        currentSelected.splice(index, 1);
      }
    }
    setSelectedColumns(currentSelected);
  };

  const debouncedSearch = useCallback(
    debounce(
      (newValue: string) => setSearchFilter({...omit(searchFilter, ['LastRowNumber']), SearchString: newValue}),
      500,
    ),
    [searchFilter],
  );

  const handlePostPurchases = async () => {
    try {
      const adaptorResponse = (await searchAdaptor({websiteId: ApplicationInfo.WebsiteId})) || [];
      const adaptor = adaptorResponse.find((account: IAdaptor) => account.Type == 'Accounts');

      if (!adaptor || !adaptor.Name || !adaptor.ID || !adaptor.MasterAdaptorID) {
        throw new Error('Adaptor not connected');
      }

      const synkResponse = await synkIsAuthorize(adaptor.Name, adaptor.ID, adaptor.MasterAdaptorID);
      if (!synkResponse.IsAuthorized || !synkResponse?.TokenDetails?.OrgName) {
        throw new Error('Adaptor not authorized');
      }

      const queueRes = await queueWorkflow({MaxWaitSecs: 29, WorkflowType: 'PostPurchases'});

      if (queueRes.IsComplete) {
        Toast.info('Purchases successfully posted.');
        setSearchFilter({...searchFilter});
      } else {
        throw new Error('Purchase syncing failed');
      }
    } catch (e) {
      Toast.error((e as any).message);
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
    debouncedSearch(e.target.value);
  };

  const handleClickAway = () => {
    if (!searchInput) {
      searchInputPopup.close();
    }
  };

  return (
    <STableToolbar>
      <div className="flex w-full items-center justify-between">
        <p className="text-xl font-light text-spenda-primarytext">{title}</p>
        <div {...css({color: '#0082BA'})} className="flex">
          <ClickAwayListener onClickAway={handleClickAway}>
            <div>
              <IconButton
                disableRipple
                className={clsx(classes.iconButton, {
                  [classes.iconButtonActive]: searchInputPopup.isOpen,
                })}
                data-autoid="btnSearch"
                {...bindToggle(searchInputPopup)}
              >
                <IconSearch />
              </IconButton>
              <Popper {...bindPopper(searchInputPopup)} disablePortal={false} placement="left" transition>
                {({TransitionProps}) => (
                  <Grow {...TransitionProps} timeout={250}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      placeholder="Search..."
                      className={clsx(classes.textfield)}
                      value={searchInput}
                      onChange={handleSearchChange}
                      inputProps={{
                        'data-autoid': 'txtSearch',
                      }}
                    />
                  </Grow>
                )}
              </Popper>
            </div>
          </ClickAwayListener>
          <SecondaryButton
            margin="0 0.25rem 0 0.25rem"
            padding="10px !important"
            {...bindTrigger(columnSettingPopupState)}
            label="Column Settings"
          />
          <Popover
            {...bindPopover(columnSettingPopupState)}
            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
            transformOrigin={{vertical: 'top', horizontal: 'right'}}
          >
            <div className="flex flex-col p-3 pr-4">
              <p className="font-poppins font-medium text-primary">Columns Settings</p>
              <div className="flex flex-col">
                {Object.entries(columns).map(([key, value]: [string, any]) => (
                  <SCheckbox
                    key={`columns-${key}`}
                    name={key}
                    label={value.title}
                    checked={selectedColumns.includes(key)}
                    onChange={handleColumnCheckbox}
                    fontFamily="Poppins"
                    icon={<FiberManualRecordOutlinedIcon style={{fontSize: '1rem'}} />}
                    checkedIcon={<FiberManualRecordIcon style={{fontSize: '1rem'}} />}
                    inputProps={
                      {'data-autoid': `chkColumnSettings${key}`} as InputHTMLAttributes<HTMLInputElement> & {
                        'data-autoid': string;
                      }
                    }
                  />
                ))}
              </div>
            </div>
          </Popover>
          {viewMode === PurchaseInvoiceViewMode.All ? (
            <PrimaryButton
              margin="0 0 0 0.25rem"
              padding="10px !important"
              label="Create Invoice"
              type="button"
              onClick={handleCreateInvoice}
            />
          ) : null}
          {viewMode === PurchaseInvoiceViewMode.ReadyToPost ? (
            <PrimaryButton
              margin="0 0 0 0.25rem"
              padding="10px !important"
              label="Post Purchases"
              type="button"
              onClick={handlePostPurchases}
              isSubmitting={isLoading}
              disabled={isLoading}
            />
          ) : null}
        </div>
      </div>
    </STableToolbar>
  );
};
