import React from 'react';

export const WidgetDeleteLogo = (props: {
  width?: string;
  height?: string;
  className?: string;
  onClick?: () => void;
}) => {
  return (
    <svg
      width={props.width || '13px'}
      height={props.height || '16px'}
      className={props.className || ''}
      viewBox="0 0 13 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>Delete</title>
      <g id="Main-File" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="Spenda-Collect-Supplier-Interface-Copy-50"
          transform="translate(-1407.000000, -296.000000)"
          fill="#CCCCCC"
          fillRule="nonzero"
        >
          <g id="Icon/12px/delete" transform="translate(1407.000000, 296.000000)">
            <path
              d="M1.3,14.2262083 C1.3,15.2019167 2.07991875,16 3.03334687,16 L9.96669375,16 C10.9200812,16 11.7,15.2019167 11.7,14.2262083 L11.7,4 L1.3,4 L1.3,14.2262083 Z M13,1.33333333 L9.75,1.33333333 L8.66185937,0 L4.33818125,0 L3.25,1.33333333 L0,1.33333333 L0,2.66666667 L13,2.66666667 L13,1.33333333 Z"
              id="Shape"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
