import PS_01 from './qm-1.jpg';
import PS_02 from './qm-2.jpg';
import PS_03 from './qm-3.jpg';
import PS_04 from './qm-4.jpg';
import PS_05 from './qm-5.jpg';
import PS_06 from './qm-6.jpg';

import icon1 from './CustomerExp.png';
import icon2 from './ManualWork.png';
import icon3 from './ImproveLeadConversation.png';
import icon4 from './TrackQuotes.png';

export const QMOnboardingImages = {
  PS_01,
  PS_02,
  PS_03,
  PS_04,
  PS_05,
  PS_06,
};

export const QMKeyPointIcons = {
  icon1,
  icon2,
  icon3,
  icon4,
};
