import * as React from 'react';

export const IconRequireAttention = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="20px"
    height="14px"
    viewBox="0 0 19.556 13.333"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <title>{'TopMenu'}</title>
    <defs>
      <rect id="path-sales-order-listing" width={468} height={60} rx={6} />
    </defs>
    <g id="MainMenu" transform="translate(-172.17 -164.09)" fill="none">
      <g transform="translate(172.17 160.98)" fill="#fff" fillRule="evenodd">
        <path
          id="Shape"
          d="m9.7292 3.1138c-0.22902 0.025144-0.40087 0.22093-0.39592 0.45106v1.7765c-0.00226 0.16016 0.081967 0.30914 0.22044 0.38988 0.13847 0.080742 0.30973 0.080742 0.4482 0 0.13847-0.080742 0.2227-0.22972 0.22044-0.38988v-1.7765c0.002757-0.12791-0.049822-0.25079-0.14427-0.33718-0.094451-0.086389-0.2216-0.12789-0.34888-0.11389zm-4.9038 0.88825c-0.14827 0.018979-0.27707 0.11113-0.3428 0.24526-0.065729 0.13413-0.05959 0.29228 0.01634 0.42092l0.88907 1.5544c0.07811 0.13895 0.22451 0.22569 0.38401 0.22751 0.1595 0.00182 0.30785-0.081546 0.38912-0.21868 0.081269-0.13713 0.083102-0.30717 0.00481-0.44601l-0.88908-1.5544c-0.088036-0.16286-0.26793-0.25411-0.45148-0.229zm9.8007 0c-0.14658 0.014155-0.27664 0.099911-0.3473 0.229l-0.88907 1.5544c-0.07819 0.13884-0.07629 0.3088 5e-3 0.44587 0.08126 0.13706 0.22955 0.2204 0.38899 0.21861 0.15944-0.00179 0.30582-0.088435 0.38397-0.22729l0.88905-1.5544c0.08481-0.14464 0.080887-0.32466-0.010142-0.46548s-0.25366-0.21844-0.4205-0.2007zm-4.8482 2.6717c-3.7603 0-7.1689 1.7635-9.6687 4.5939-0.14547 0.16713-0.14547 0.41578 0 0.58291 2.4997 2.8289 5.9099 4.5939 9.6687 4.5939 3.7588 0 7.1689-1.765 9.6687-4.5939 0.14547-0.16713 0.14547-0.41578 0-0.58291-2.4998-2.8304-5.9084-4.5939-9.6687-4.5939zm0 0.88825c3.3488 0 6.3923 1.5094 8.7171 3.9971-2.3244 2.4851-5.3696 3.9971-8.7171 3.9971-3.3474 0-6.3927-1.512-8.7171-3.9971 2.3248-2.4877 5.3683-3.9971 8.7171-3.9971zm0 1.1103c-1.5906 0-2.8895 1.2977-2.8895 2.8868 0 1.5891 1.2989 2.8868 2.8895 2.8868s2.8895-1.2977 2.8895-2.8868c0-1.5891-1.2989-2.8868-2.8895-2.8868zm0 0.88824c1.1101 0 2.0004 0.88951 2.0004 1.9986 0 1.109-0.89035 1.9986-2.0004 1.9986-1.1101 0-2.0004-0.88953-2.0004-1.9986 0-1.109 0.89035-1.9986 2.0004-1.9986z"
        />
      </g>
    </g>
    <g id="bsupplier-offer" transform="translate(363.33 -158.09)" fill="none" fillRule="evenodd">
      <mask fill="#ffffff">
        <use width="100%" height="100%" xlinkHref="#path-sales-order-listing" />
      </mask>
    </g>
  </svg>
);
