import React from 'react';
import {Menu, MenuProps, MenuItem, MenuItemProps, makeStyles} from '@material-ui/core';

export const FilterMenu = (props: MenuProps) => {
  const defaultStyles = {
    paper: {
      backgroundColor: '#FFFFFF',
      border: '1px solid #1C78AD',
      borderRadius: 6,
      boxShadow: '0 1px 6px 0 #00000020',
      maxHeight: '200px',
      marginTop: '5px',
      minWidth: 175,
      fontFamily: 'poppins',
      '& .MuiList-root': {
        padding: 0,
        '& :last-child': {
          borderBottom: 'none',
        },
      },
    },
    popover: {
      backgroundColor: 'transparent !important',
    },
  };

  const useStyles = makeStyles(defaultStyles);

  const classes = useStyles();

  return (
    <Menu
      classes={{paper: classes.paper}}
      PopoverClasses={{root: classes.popover}}
      getContentAnchorEl={null}
      anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
      transformOrigin={{vertical: 'top', horizontal: 'left'}}
      {...props}
    ></Menu>
  );
};

export const FilterMenuItem = React.forwardRef((props: MenuItemProps, ref) => {
  const defaultStyles = {
    root: {
      fontWeight: 500,
      color: '#333333',
      fontSize: '12px',
      borderBottom: '1px solid #D8D8D8',
      paddingTop: '8px',
      paddingBottom: '8px',
      fontFamily: 'poppins !important',
      justifyContent: 'flex-start !important',
      '&:hover': {
        backgroundColor: '#D3E5EF40',
      },
      '&.Mui-selected': {
        backgroundColor: '#D3E5EF80',
        '&:hover': {
          backgroundColor: '#D3E5EF80',
        },
      },
    },
  };

  const useStyles = makeStyles(defaultStyles);

  const classes = useStyles();

  return <MenuItem ref={ref as any} button={true as any} classes={{root: classes.root}} {...props}></MenuItem>;
});
