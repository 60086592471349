import React, {useState, useEffect} from 'react';
import {Formik} from 'formik';
import clsx from 'clsx';
import * as Yup from 'yup';
import {IAddress} from '../../model/address/Address';
import {usePurchaseOrderDetailStyle} from '../../screens/purchase-order/PurchaseOrderDetail';
import {SDialog} from '../modals/modalSpendaMeterialUI';
import {LocationAutoComplete} from '../location/LocationAutoComplete';
import {STextField} from '../inputs/STextField';
import {SwitchSlider} from '../form/SwitchSlider';
import {useLocationAPI} from '../../services/useLocationAPI';
import {DialogContent, DialogTitle} from '@material-ui/core';
import googleImg from '../../assets/png/SalesOrder/powered-by-google.png';

const addressValidationSchema = Yup.object().shape({
  StreetAddress: Yup.string().required('Address is required'),
  City: Yup.string().required('City is required'),
  State: Yup.string().required('State is required'),
  Country: Yup.string().required('Country is required'),
  PostCode: Yup.string().required('Postcode is required'),
});

interface ILocationDialog {
  open: Partial<IAddress> | null;
  setOpen: (x: Partial<IAddress> | null) => void;
  handleSelectAddress: (x: Partial<IAddress>) => void;
}

export const PurchaseOrderLocationDialog = (props: ILocationDialog) => {
  const {open, setOpen, handleSelectAddress} = props;

  const classes = usePurchaseOrderDetailStyle();
  const {getLocationAddress} = useLocationAPI();

  const [enterManually, setEnterManually] = useState<boolean>(false);
  const [addressToEdit, setAddressToEdit] = useState<Partial<IAddress>>({
    StreetAddress: '',
    City: '',
    State: '',
    Country: '',
    PostCode: '',
  });

  useEffect(() => {
    if (open) {
      setEnterManually(false);
      setAddressToEdit(open);
    }
  }, [open]);

  const onSubmit = (values: Partial<IAddress>) => {
    handleSelectAddress(values);
  };

  return (
    <SDialog classes={{paper: 'relative'}} maxWidth="md" fullWidth open={Boolean(open)} onClose={() => setOpen(null)}>
      <DialogTitle>
        <p className="w-full text-center font-poppins">Select an address</p>
        <div className="absolute right-6 top-3 font-poppins">
          <SwitchSlider
            label={<span className="font-poppins">Enter manually</span>}
            name="advanced"
            checked={enterManually}
            handleChange={() => setEnterManually(!enterManually)}
            labelPlacement="start"
          />
        </div>
      </DialogTitle>
      <DialogContent classes={{root: clsx('mt-3 mb-10 font-poppins')}}>
        <Formik
          enableReinitialize
          validationSchema={addressValidationSchema}
          initialValues={addressToEdit}
          onSubmit={onSubmit}
        >
          {props => (
            <>
              {enterManually ? (
                <div className="grid grid-cols-4 gap-x-2.5 gap-y-4 ">
                  <STextField
                    v2
                    fullWidth
                    id="StreetAddress"
                    name="StreetAddress"
                    variant="outlined"
                    label="Address"
                    placeholder="Address"
                    value={props.values.StreetAddress}
                    onBlur={props.handleBlur}
                    onChange={props.handleChange}
                    helperText={props.touched?.StreetAddress && props.errors?.StreetAddress}
                    error={props.touched?.StreetAddress && Boolean(props.errors?.StreetAddress)}
                    className="col-span-2"
                  />
                  <STextField
                    v2
                    fullWidth
                    id="Country"
                    name="Country"
                    variant="outlined"
                    label="Country"
                    placeholder="Country"
                    value={props.values.Country}
                    onBlur={props.handleBlur}
                    onChange={props.handleChange}
                    helperText={props.touched?.Country && props.errors?.Country}
                    error={props.touched?.Country && Boolean(props.errors?.Country)}
                  />
                  <button
                    type="submit"
                    className="row-span-2 ml-auto h-full min-w-full max-w-[100px] rounded bg-spenda-sBlue font-poppins font-medium text-white disabled:bg-opacity-50"
                    onClick={() => props.handleSubmit()}
                    disabled={!props.isValid}
                    data-autoid="btnUseThisAddress"
                  >
                    Use this Address
                  </button>
                  <STextField
                    v2
                    fullWidth
                    id="City"
                    name="City"
                    variant="outlined"
                    label="City"
                    placeholder="City"
                    value={props.values.City}
                    onBlur={props.handleBlur}
                    onChange={props.handleChange}
                    helperText={props.touched?.City && props.errors?.City}
                    error={props.touched?.City && Boolean(props.errors?.City)}
                  />
                  <STextField
                    v2
                    fullWidth
                    id="State"
                    name="State"
                    variant="outlined"
                    label="State"
                    placeholder="State"
                    value={props.values.State}
                    onBlur={props.handleBlur}
                    onChange={props.handleChange}
                    helperText={props.touched?.State && props.errors?.State}
                    error={props.touched?.State && Boolean(props.errors?.State)}
                  />
                  <STextField
                    v2
                    fullWidth
                    id="PostCode"
                    name="PostCode"
                    variant="outlined"
                    label="Postcode"
                    placeholder="Postcode"
                    value={props.values.PostCode}
                    onBlur={props.handleBlur}
                    onChange={props.handleChange}
                    helperText={props.touched?.PostCode && props.errors?.PostCode}
                    error={props.touched?.PostCode && Boolean(props.errors?.PostCode)}
                  />
                </div>
              ) : null}
              {!enterManually ? (
                <div className={clsx('grid grid-cols-3 gap-x-2.5', classes.selectLocation)}>
                  <div>
                    <LocationAutoComplete
                      styles={{marginTop: '0'}}
                      carouselUI={true}
                      isSalesModule={true}
                      selected={null}
                      onSuggestionSelected={(e, selected) => {
                        getLocationAddress(selected.suggestion.PlaceID || '').then((res: IAddress) => {
                          if (res) {
                            setEnterManually(true);
                            props.setValues({
                              ...props.values,
                              StreetAddress: res.StreetAddress,
                              City: res.City,
                              State: res.State,
                              Country: res.Country,
                              PostCode: res.PostCode,
                              IsActive: true,
                            });
                          }
                        });
                      }}
                    />
                  </div>
                  <img width={122} height={17} src={googleImg} className="col-span-2 ml-auto mr-4 mt-3" />
                </div>
              ) : null}
            </>
          )}
        </Formik>
      </DialogContent>
    </SDialog>
  );
};
