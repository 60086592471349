import * as React from 'react';
const SupplierManagement = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 8.33389C20 8.27306 19.9933 8.21241 19.98 8.15306L18.8967 3.26306C18.4818 1.35017 16.784 -0.0108114 14.8267 0.000558995H5.17333C3.21726 -0.00923095 1.52125 1.3514 1.10667 3.26306L0.0200011 8.15306C0.00667456 8.21241 -3.30499e-05 8.27306 1.14116e-06 8.33389V9.16723C-0.000671198 9.97855 0.295769 10.762 0.833334 11.3697V15.8339C0.83609 18.1339 2.69996 19.9978 5 20.0006H15C17.3 19.9978 19.1639 18.1339 19.1667 15.8339V11.3697C19.7042 10.762 20.0007 9.97855 20 9.16723V8.33389ZM1.66666 8.42445L2.73333 3.62445C2.98243 2.47766 3.99982 1.66146 5.17333 1.66695H5.83333V4.16695C5.83333 4.62718 6.20643 5.00028 6.66666 5.00028C7.1269 5.00028 7.5 4.62718 7.5 4.16695V1.66695H12.5V4.16695C12.5 4.62718 12.8731 5.00028 13.3333 5.00028C13.7936 5.00028 14.1667 4.62718 14.1667 4.16695V1.66695H14.8267C16.0002 1.66146 17.0176 2.47766 17.2667 3.62445L18.3333 8.42445V9.16695C18.3333 10.0874 17.5871 10.8336 16.6667 10.8336H15.8333C14.9129 10.8336 14.1667 10.0874 14.1667 9.16695C14.1667 8.70671 13.7936 8.33361 13.3333 8.33361C12.8731 8.33361 12.5 8.70671 12.5 9.16695C12.5 10.0874 11.7538 10.8336 10.8333 10.8336H9.16666C8.24619 10.8336 7.5 10.0874 7.5 9.16695C7.5 8.70671 7.1269 8.33361 6.66666 8.33361C6.20643 8.33361 5.83333 8.70671 5.83333 9.16695C5.83333 10.0874 5.08714 10.8336 4.16666 10.8336H3.33333C2.41286 10.8336 1.66666 10.0874 1.66666 9.16695V8.42445ZM15 18.3336H5C3.61928 18.3336 2.5 17.2143 2.5 15.8336V12.3944C2.77216 12.4649 3.05219 12.5005 3.33333 12.5003H4.16666C5.12393 12.5008 6.03497 12.0887 6.66666 11.3694C7.29836 12.0887 8.2094 12.5008 9.16666 12.5003H10.8333C11.7906 12.5008 12.7016 12.0887 13.3333 11.3694C13.965 12.0887 14.8761 12.5008 15.8333 12.5003H16.6667C16.9478 12.5005 17.2278 12.4649 17.5 12.3944V15.8336C17.5 17.2143 16.3807 18.3336 15 18.3336Z"
      fill="#1C78AD"
    />
  </svg>
);
export default SupplierManagement;
