import * as React from 'react';
const UserIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" {...props}>
    <path
      fill="#1C78AD"
      fillRule="evenodd"
      d="M17.071 2.929A9.935 9.935 0 0 0 10 0a9.935 9.935 0 0 0-7.071 2.929A9.935 9.935 0 0 0 0 10a9.935 9.935 0 0 0 2.929 7.071A9.935 9.935 0 0 0 10 20a9.935 9.935 0 0 0 7.071-2.929A9.935 9.935 0 0 0 20 10a9.935 9.935 0 0 0-2.929-7.071ZM5.333 16.572c.278-2.365 2.333-4.191 4.762-4.191a4.79 4.79 0 0 1 3.39 1.39 4.72 4.72 0 0 1 1.372 2.8 7.441 7.441 0 0 1-4.762 1.715 7.441 7.441 0 0 1-4.762-1.714Zm4.857-6.286A2.574 2.574 0 0 1 7.62 7.714a2.574 2.574 0 0 1 2.572-2.571 2.574 2.574 0 0 1 2.57 2.571 2.574 2.574 0 0 1-2.57 2.572Zm4.532 2.262a6.524 6.524 0 0 0-2.382-1.523 4.008 4.008 0 1 0-6.253-3.32c0 1.38.7 2.6 1.765 3.321a6.529 6.529 0 0 0-2.216 1.36 6.523 6.523 0 0 0-1.893 3.212 8.372 8.372 0 0 1-2.055-5.503c0-4.636 3.771-8.408 8.407-8.408s8.408 3.772 8.408 8.408a8.372 8.372 0 0 1-2.057 5.504 6.544 6.544 0 0 0-1.724-3.051Z"
      clipRule="evenodd"
    />
  </svg>
);
export default UserIcon;
