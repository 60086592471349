import React, {useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import StepSuccessIcon from '../../assets/png/AccountsPayable/onboardingSucessGreenCheck.png';
import APRemainingSteps from '../../assets/svg/accounts-payable/APRemainingStepsIcon';
import {AUTH_BUYING_AP_USER_SETTINGS} from '../../routes/AccountsPayableRoutes';
import {Spinner, Typography} from 'spenda-ui-react';
import {IOnboardingV1Step} from '../../model/OnboardingV1Step';
import {APOnboardingSteps, APOnboardingStepsName} from '../../model/constants/Constants';
import {AlertDialogSlideV2} from '../dialog/AlertDialogSlideV2';
import {IAccountResponse} from '../../services/useSpendaPaymentServicesAPI';
import { convertNumberToWords } from '../../utils/formatter';
export interface IOnboardingStepsRemainingModalProps {
  handleClose: () => void;
  handleCompleteStep: (description: string) => void;
  paymentAccounts?: IAccountResponse[];
  onboardingStep?: IOnboardingV1Step[];
  countOfRemainingSteps?: number;
  showAMLKYCPending?: boolean;
  handleAmlKycClick?: () => void;
}

export const OnboardingStepsRemainingModal = (props: IOnboardingStepsRemainingModalProps) => {
  const {handleClose, handleCompleteStep, countOfRemainingSteps, onboardingStep, showAMLKYCPending, handleAmlKycClick} =
    props;
  const history = useHistory();

  const STEPS = useCallback((step: IOnboardingV1Step) => {
    switch (step.stepID) {
      case APOnboardingSteps.ConfigureFinancialAdaptor:
        return {
          stepName: APOnboardingStepsName.ConfigureFinancialAdaptor,
          onClick: () => {
            history.push(AUTH_BUYING_AP_USER_SETTINGS);
          },
          status: step.completedDateTime_utc ? true : false,
        };
      case APOnboardingSteps.BusinessInformation:
        return {
          stepName: APOnboardingStepsName.BusinessInformation,
          status: step.completedDateTime_utc ? true : false,
        };
      case APOnboardingSteps.AMLKYC:
        return {
          stepName: !showAMLKYCPending ? APOnboardingStepsName.AMLKYC : APOnboardingStepsName.AMLKYC + ' Pending',
          status: step.completedDateTime_utc && !showAMLKYCPending ? true : false,
          onClick: () => {
            showAMLKYCPending && handleAmlKycClick?.();
          },
        };
      case APOnboardingSteps.SetupPaymentMethods:
        return {
          stepName: APOnboardingStepsName.SetupPaymentMethods,
          onClick: () => {
            history.push(`${AUTH_BUYING_AP_USER_SETTINGS}?tab=1`);
          },
          status: step.completedDateTime_utc && props.paymentAccounts?.length ? true : false,
        };
      case APOnboardingSteps.ClearingAccount:
        return {
          stepName: APOnboardingStepsName.ClearingAccount,
          onClick: () => {
            history.push(`${AUTH_BUYING_AP_USER_SETTINGS}?tab=3`);
          },
          status: step.completedDateTime_utc ? true : false,
        };
      case APOnboardingSteps.UserSettings:
        return {
          stepName: APOnboardingStepsName.UserSettings,
          onClick: () => {
            history.push(`${AUTH_BUYING_AP_USER_SETTINGS}?tab=0`);
          },
          status: step.completedDateTime_utc ? true : false,
        };
    }
  }, []);

  return (
    <AlertDialogSlideV2
      dialogActionsAlignment="justify-between"
      dialogClassess="!min-w-[20%] !max-w-[500px]"
      dialogBodyClassess="px-0 py-7 h-[320px]"
      headingTextSize="h1"
      title={`${!countOfRemainingSteps ? '--' : convertNumberToWords(countOfRemainingSteps)} ${
        countOfRemainingSteps === 1  ? 'step' : 'steps'
      } remaining`}
      actions={[
        {
          action: handleClose,
          label: 'Cancel',
          variant: 'outlined',
        },
        {
          action: handleCompleteStep,
          label: 'Skip for now',
        },
      ]}
    >
      {!onboardingStep ? (
        <div className="w-full h-full flex justify-center items-center">
          <Spinner className="text-primary/30 w-7 h-7" />
        </div>
      ) : (
        <div className={`flex flex-col relative w-full`}>
          {onboardingStep.map(step => {
            const stepInfo = STEPS(step);
            return (
              <div
                key={step.stepID + stepInfo.stepName}
                onClick={stepInfo.status ? () => {} : stepInfo.onClick}
                className={`${
                  stepInfo.status ? '' : 'cursor-pointer'
                } hover:bg-primary/10 active:bg-primary/10 flex justify-between items-center py-3 px-5`}
              >
                <Typography variant="xsmall" className="text-spenda-primarytext cursor-default">
                  {stepInfo.stepName}
                </Typography>
                {stepInfo.status ? <img src={StepSuccessIcon} /> : <APRemainingSteps />}
              </div>
            );
          })}
        </div>
      )}
    </AlertDialogSlideV2>
  );
};
