import React, {useState, useEffect} from 'react';
import {css} from 'glamor';
import {SuggestionSelectedEventData} from 'react-autosuggest';
import {SAutocomplete} from '../inputs/SAutocomplete';
import {ICreateCustomerClassValues} from '../../model/customer/CustomerClasses';
import {ICustomer} from '../../model/customer/Customer';
import {IPagedActionResults} from '../../model/ActionResults';
import {useCustomerClassesAPI} from '../../services/useCustomerClassesAPI';
import {useCustomersAPI} from '../../services/useCustomersAPI';
import {PriceFormat} from '../../utils/formatter';

interface ICustomerAutocomplete<T> {
  isSalesModule?: boolean;
  customerClassType?: boolean;
  PostingBehaviour?: string;
  placeholder?: string;
  types?: string;
  multiple?: boolean;
  additionalFilter?: any;
  disabled?: boolean;
  selected: Partial<T> | null;
  addNewClick?: ((value: string) => void) | boolean | undefined;
  clearClick?: (() => void) | boolean | undefined;
  helperText?: string | boolean | undefined;
  error?: boolean | undefined;
  onSuggestionSelected: (event: React.FormEvent<Object>, selected: SuggestionSelectedEventData<T>) => void;
  styles?: any;
}

export const CustomerClassesAutocomplete = (props: ICustomerAutocomplete<Partial<ICreateCustomerClassValues>>) => {
  const [suggestions, setSuggestions] = useState<Partial<ICreateCustomerClassValues>[]>([]);
  const {isLoading, getList, getGenericCustomersClassList} = useCustomerClassesAPI();
  const [, setErrorMsg] = useState<Boolean>(false);

  const {placeholder, selected, addNewClick, clearClick, onSuggestionSelected, helperText, error} = props;

  const getCustomerClassesList = (newValue: string = '') => {
    if (!selected) {
      if (props.customerClassType) {
        getGenericCustomersClassList(newValue, props?.PostingBehaviour).then(
          (res: IPagedActionResults<ICreateCustomerClassValues[]>) => {
            setSuggestions(res?.Value || []);
          },
        );
      } else {
        getList({
          PostingBehaviours: ['Account'],
          MaxResults: 5,
          IsShowActiveOnly: true,
          ...(newValue ? {Search: newValue} : {}),
        }).then((res: IPagedActionResults<ICreateCustomerClassValues[]>) => {
          setSuggestions(res?.Value || []);
          if (!res?.Value) {
            setErrorMsg(true);
          } else {
            setErrorMsg(false);
          }
        });
      }
    }
  };

  useEffect(() => {
    if (selected) {
      setSuggestions([selected]);
    }
  }, [selected]);

  const handleSuggestionSelected = (
    event: React.FormEvent<any>,
    _selected: SuggestionSelectedEventData<Partial<ICreateCustomerClassValues>>,
  ) => {
    onSuggestionSelected(event, _selected);
    setSuggestions([_selected.suggestion]);
  };
  return (
    <>
      <SAutocomplete<Partial<ICreateCustomerClassValues>>
        noRecordMsg="<Not in a Class>"
        styles={props.styles}
        async
        size="small"
        loading={isLoading}
        placeholder={placeholder || 'Search Customer Classes'}
        addNewClick={addNewClick}
        clearClick={clearClick}
        suggestions={suggestions}
        selected={selected}
        getSuggestions={getCustomerClassesList}
        helperText={helperText}
        error={error}
        renderSuggestion={(item: Partial<ICreateCustomerClassValues>) => (
          <>
            <p data-autoid={`customerName${item.ID}`}>{item.Name}</p>
            {item.CreditLimit ? (
              <p data-autoid={`customerCreditLimit${item.ID}`} {...css({fontSize: '0.75rem'})}>
                <b>Credit Limit</b>: {PriceFormat(item.CreditLimit)}
              </p>
            ) : null}
            {item.TradingTermID ? (
              <p data-autoid={`customerTradingTerm-${item.ID}`} {...css({fontSize: '0.75rem'})}>
                <b>Trading Term</b>: {item?.TradingTermDays} {item?.TradingTermType?.replace(/_/g, ' ')}
              </p>
            ) : null}
          </>
        )}
        getSuggestionValue={(suggestion: Partial<ICreateCustomerClassValues>) => {
          return `${suggestion.Name}`;
        }}
        onSearchChange={(value: string) => {
          getCustomerClassesList(value);
        }}
        onSuggestionSelected={handleSuggestionSelected}
      />
    </>
  );
};

export const CustomerAutocomplete = (props: ICustomerAutocomplete<Partial<ICustomer>>) => {
  const [suggestions, setSuggestions] = useState<Partial<ICustomer>[]>([]);
  const {isLoading, list} = useCustomersAPI();

  const {
    isSalesModule,
    placeholder,
    selected,
    addNewClick,
    clearClick,
    additionalFilter,
    onSuggestionSelected,
    helperText,
    error,
  } = props;

  const getCustomerList = (newValue: string = '') => {
    if (!selected) {
      list({
        MaxResults: 15,
        ...(typeof additionalFilter === 'object' ? additionalFilter : {}),
        ...(newValue ? {Search: newValue} : {}),
      }).then((res: IPagedActionResults<ICustomer[]>) => {
        setSuggestions(res.Value);
      });
    }
  };

  useEffect(() => {
    if (selected) {
      setSuggestions([selected]);
    }
  }, [selected]);

  const handleSuggestionSelected = (
    event: React.FormEvent<any>,
    _selected: SuggestionSelectedEventData<Partial<ICustomer>>,
  ) => {
    onSuggestionSelected(event, _selected);
    setSuggestions([_selected.suggestion]);
  };

  return (
    <SAutocomplete<Partial<ICustomer>>
      async
      isSalesModule={isSalesModule}
      loading={isLoading}
      placeholder={placeholder || 'Search Customer'}
      addNewClick={addNewClick}
      clearClick={clearClick}
      suggestions={suggestions}
      selected={selected}
      getSuggestions={getCustomerList}
      renderSuggestion={(item: Partial<ICustomer>) => (
        <p className="text-left" data-autoid={`mnuCustomer${item.ID}`}>
          {item.Name}
          <span className="float-right text-spenda-labeltext">{item.PrimaryContactEmailAddress}</span>
        </p>
      )}
      getSuggestionValue={(suggestion: Partial<ICustomer>) => {
        return `${suggestion.Name}`;
      }}
      onSearchChange={getCustomerList}
      onSuggestionSelected={handleSuggestionSelected}
      helperText={helperText}
      error={error}
      styles={props.styles}
    />
  );
};
