import React, {SVGProps} from 'react';

const IconKeyboardArrowDownRounded = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width || '20px'}
      height={props.height || '20px'}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.013 18.097a2.083 2.083 0 00-2.958 0l-9.542 9.541a2.083 2.083 0 01-2.958 0l-9.542-9.541a2.083 2.083 0 00-2.958 0 2.083 2.083 0 000 2.937l9.563 9.563a6.25 6.25 0 008.833 0l9.562-9.563a2.083 2.083 0 000-2.937z"
        className="fill-primary stroke-primary"
      />
    </svg>
  );
};
export default IconKeyboardArrowDownRounded;
