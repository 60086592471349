import React, {useContext, useEffect} from 'react';
import AppContext from '../../context/app/appContext';
import PurchasingContext from '../../context/purchasing/purchasingContext';
import {SupplierCataloguePanel} from '../../components/purchasing/SupplierCataloguePanel';
import {RequisitionDetailsDialog} from '../../components/dialog/RequisitionDetailsDialog';
import {RequisitionEditPanel} from '../../components/purchasing/RequisitionEditPanel';
import {TransactionListPanel} from '../../components/purchasing/TransactionListPanel';
import PurchaseOrderEditPanel from '../../components/purchasing/PurchaseOrderEditPanel';
import {MarketplaceLayout} from './MarketplaceLayout';

export const Order = () => {
  const appCtx = useContext(AppContext);
  const ctx = useContext(PurchasingContext);

  useEffect(() => {
    async function fetchTransactions() {
      ctx.searchTransactions();
    }

    if (!ctx.requisition && !ctx.purchaseOrder) {
      // Update transactionList everytime either RequistionEditPanel or PurchaseOrderEditPanel are closed
      fetchTransactions();
    }
  }, [ctx.statusCounters, ctx.requisition, ctx.purchaseOrder, appCtx.marketplaceSupplier]);

  const leftPanel = <TransactionListPanel statusCounterList={ctx.statusCounters} />;

  const centrePanel = <SupplierCataloguePanel />;

  const rightPanel = ctx.requisition ? (
    <RequisitionEditPanel requisition={ctx.requisition} />
  ) : ctx.purchaseOrder ? (
    <PurchaseOrderEditPanel purchaseOrder={ctx.purchaseOrder} />
  ) : undefined;

  const modal = ctx.isShowRequisitionDetailsDialog && <RequisitionDetailsDialog />;


  return (
    <div className={`h-full relative overflow-hidden bg-spenda-newbg`}>
      <MarketplaceLayout
        leftPanel={leftPanel}
        mainPanel={centrePanel}
        rightPanel={rightPanel}
        modal={modal}
        toggleView={ctx.requisition !== undefined || ctx.purchaseOrder !== undefined}
      />
    </div>
  );
};
