import React from 'react';
import {FormikProps} from 'formik';
import {css} from 'glamor';

import {Inventory} from '../../model/inventory/Inventory';
import {STextField} from '../inputs/STextField';
import {InventoryAutocomplete} from '../inventory/InventoryAutocomplete';
import {SCheckbox} from '../inputs/SCheckbox';

import {Box, Grid, Divider, makeStyles, Radio, RadioGroup, FormControlLabel} from '@material-ui/core';

const useInventoryClassStyle = makeStyles(() => ({
  formLabel: {
    fontSize: '1rem',
    color: '#333333',
    paddingBottom: '1.5rem',
  },
  title: {
    fontWeight: 500,
    fontSize: '1.5em',
    color: '#4D4D4D',
  },
  subTitle: {
    fontWeight: 400,
    fontSize: '1.25em',
    marginTop: '20px',
  },
  radioLabel: {
    color: '#333333 !important',
    fontSize: '1rem',
  },
  radioIcon: {
    color: '#1C78AD !important',
    fontSize: '1rem',
  },
}));

export type AddInventoryClassValues = {
  ID?: number;
  Name: string;
  Description: string;
  IsApprovedForPOS: boolean;
  IsApprovedForService: boolean;
  IsApprovedForWeb: boolean;
  PostingBehaviour?: string;
  InventoryClassGroup?: any;
  SalesPostingInventoryCode?: string | null;
  SalesPostingInventoryExpenseAccountCode?: string | null;
  SalesPostingInventoryID?: number | null;
  SalesPostingInventoryName?: string | null;
  SalesPostingInventoryRevenueAccountCode?: string | null;
  ServicePostingInventoryCode?: string | null;
  ServicePostingInventoryExpenseAccountCode?: string | null;
  ServicePostingInventoryID?: number | null;
  ServicePostingInventoryName?: string | null;
  ServicePostingInventoryRevenueAccountCode?: string | null;
};

export type AddInventoryClassesProps = {
  initialValues: AddInventoryClassValues;
};

export const AddInventoryClasses = (props: FormikProps<AddInventoryClassValues> & AddInventoryClassesProps) => {
  const classes = useInventoryClassStyle();
  const {values, status, touched, errors, handleChange, setFieldValue} = props;

  const heading = css({
    textTransform: 'uppercase',
    color: '#1C78AD',
    fontWeight: 500,
    fontSize: '0.6rem',
  });

  const setSalesInventory = (selected: Partial<Inventory> | null) => {
    setFieldValue('SalesPostingInventoryCode', selected?.InventoryCode || null);
    setFieldValue('SalesPostingInventoryExpenseAccountCode', selected?.ExpenseAccount || null);
    setFieldValue('SalesPostingInventoryName', selected?.ShortDescription || null);
    setFieldValue('SalesPostingInventoryRevenueAccountCode', selected?.RevenueAccount || null);
    setFieldValue('SalesPostingInventoryID', selected?.ID || null);
  };

  const setServiceInventory = (selected: Partial<Inventory> | null) => {
    setFieldValue('ServicePostingInventoryCode', selected?.InventoryCode || null);
    setFieldValue('ServicePostingInventoryExpenseAccountCode', selected?.ExpenseAccount || null);
    setFieldValue('ServicePostingInventoryName', selected?.ShortDescription || null);
    setFieldValue('ServicePostingInventoryRevenueAccountCode', selected?.RevenueAccount || null);
    setFieldValue('ServicePostingInventoryID', selected?.ID || null);
  };

  return (
    <Box
      display="flex"
      flex="1"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="start"
      padding="0 100px 0 10px"
    >
      <Grid container spacing={1}>
        <Grid item sm={1} className="flex items-end">
          <p className={`${classes.formLabel}`}>Name:</p>
        </Grid>
        <Grid item sm={2}>
          <STextField
            disabled={Boolean(values.ID && props.initialValues?.PostingBehaviour)}
            id="Name"
            name="Name"
            placeholder="Class Name"
            value={values.Name}
            onChange={e => {
              if (!(values.Name.length === 0 && e.target.value === ' ')) handleChange(e);
            }}
            helperText={(touched?.Name && errors?.Name) || (!!status && status?.Name)}
            error={(touched?.Name && Boolean(errors?.Name)) || !!status}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item sm={1} className="flex items-end">
          <p className={`${classes.formLabel}`}>Description:</p>
        </Grid>
        <Grid item sm={6}>
          <STextField
            disabled={Boolean(values.ID && props.initialValues?.PostingBehaviour)}
            id="Description"
            name="Description"
            placeholder="Class Description"
            value={values.Description}
            onChange={handleChange}
            helperText={touched?.Description && errors?.Description}
            error={touched?.Description && Boolean(errors?.Description)}
            fullWidth
          />
        </Grid>
      </Grid>
      <Divider className="w-full" style={{height: '2px !important', backgroundColor: '#F1F1F1', margin: '20px 0px'}} />
      <p className={`${classes.title}`}>Financial System Posting Behaviour</p>
      <p className={`${classes.title} ${classes.subTitle}`}>
        Choose how you want Products in this Class to post to your financial system
      </p>
      <RadioGroup
        aria-label="PostingBehaviour"
        id="PostingBehaviour"
        name="PostingBehaviour"
        value={values.PostingBehaviour}
        onChange={handleChange}
      >
        <FormControlLabel
          classes={{root: classes.radioLabel, disabled: classes.radioLabel}}
          disabled={Boolean(values.ID && props.initialValues?.PostingBehaviour)}
          value="None"
          control={<Radio classes={{root: classes.radioIcon, disabled: classes.radioIcon}} />}
          label="Do not post these items or any transactions for products in this class"
        />
        <FormControlLabel
          classes={{root: classes.radioLabel, disabled: classes.radioLabel}}
          disabled={Boolean(values.ID && props.initialValues?.PostingBehaviour)}
          value="Account"
          control={<Radio classes={{root: classes.radioIcon, disabled: classes.radioIcon}} />}
          label="Post product directly into financial system"
        />
        <FormControlLabel
          classes={{root: classes.radioLabel, disabled: classes.radioLabel}}
          disabled={Boolean(values.ID && props.initialValues?.PostingBehaviour)}
          value="Generic"
          control={<Radio classes={{root: classes.radioIcon, disabled: classes.radioIcon}} />}
          label="Post product using a departmental item"
        />
      </RadioGroup>
      <Grid container spacing={3}>
        <Grid item sm={1} />
        <Grid item sm={3}>
          <p {...heading}>For sales transactions use this departmental item</p>
          <InventoryAutocomplete
            styles={{marginTop: '-1.5rem'}}
            helperText={touched?.SalesPostingInventoryID && errors?.SalesPostingInventoryID}
            error={touched?.SalesPostingInventoryID && Boolean(errors?.SalesPostingInventoryID)}
            additionalFilter={{IsShowPostingInventoryOnly: true}}
            disabled={
              values.PostingBehaviour !== 'Generic' || Boolean(values.ID && props.initialValues?.PostingBehaviour)
            }
            selected={
              values.SalesPostingInventoryCode && values.SalesPostingInventoryName
                ? {
                    InventoryCode: values.SalesPostingInventoryCode,
                    ShortDescription: values.SalesPostingInventoryName,
                  }
                : null
            }
            clearClick={() => setSalesInventory(null)}
            onSuggestionSelected={(e, selected) => setSalesInventory(selected.suggestion)}
          />
        </Grid>
        <Grid item sm={1} />
        <Grid item sm={3}>
          <p {...heading}>For service transactions use this departmental item</p>
          <InventoryAutocomplete
            styles={{marginTop: '-1.5rem'}}
            helperText={touched?.ServicePostingInventoryID && errors?.ServicePostingInventoryID}
            error={touched?.ServicePostingInventoryID && Boolean(errors?.ServicePostingInventoryID)}
            additionalFilter={{IsShowPostingInventoryOnly: true}}
            disabled={
              values.PostingBehaviour !== 'Generic' || Boolean(values.ID && props.initialValues?.PostingBehaviour)
            }
            selected={
              values.ServicePostingInventoryCode && values.ServicePostingInventoryName
                ? {
                    InventoryCode: values.ServicePostingInventoryCode,
                    ShortDescription: values.ServicePostingInventoryName,
                  }
                : null
            }
            clearClick={() => setServiceInventory(null)}
            onSuggestionSelected={(e, selected) => setServiceInventory(selected.suggestion)}
          />
        </Grid>
      </Grid>
      <Divider className="w-full" style={{height: '2px !important', backgroundColor: '#F1F1F1', margin: '20px 0px'}} />
      <p className={`${classes.title}`}>Sync Settings</p>
      <p className={`${classes.title} ${classes.subTitle}`}>
        Indicate where you want Inventory items in this class to be sold
      </p>
      <SCheckbox
        disabled={Boolean(values.ID && props.initialValues?.PostingBehaviour)}
        id="IsApprovedForPOS"
        name="IsApprovedForPOS"
        textColor="#333333"
        label="Sold in POS (Retail)"
        checked={values.IsApprovedForPOS}
        onChange={handleChange}
      />
      <SCheckbox
        disabled={Boolean(values.ID && props.initialValues?.PostingBehaviour)}
        id="IsApprovedForService"
        name="IsApprovedForService"
        textColor="#333333"
        label="Sold in Service"
        checked={values.IsApprovedForService}
        onChange={handleChange}
      />
      <SCheckbox
        disabled={Boolean(values.ID && props.initialValues?.PostingBehaviour)}
        id="IsApprovedForWeb"
        name="IsApprovedForWeb"
        textColor="#333333"
        label="Sold in Web"
        checked={values.IsApprovedForWeb}
        onChange={handleChange}
      />
    </Box>
  );
};
