import React, {FC, useContext, useMemo, useState} from 'react';
import {AlertDialogSlideV2} from './AlertDialogSlideV2';
import {Button, Checkbox, Chip, Option, Select, Typography} from 'spenda-ui-react';
import QuoteContext from '../../context/quote-context/QuoteContext';
import {CustomerDialog} from './CustomerDialog';
import {useCustomersAPI} from '../../services/useCustomersAPI';
import {ContactID} from './QuoteContactCustomerModal';
import {ICustomer} from '../../model/customer/Customer';
import { ILocation } from '../../model/address/Location';

interface IAddAdditionalContactCustomerModal {
  handleBack: () => void;
  selectedCustomer?: number[];
  handleContinue: (selectContactIDs?: ContactID[]) => void;
}

interface IOptions extends ILocation {
  isSelected: boolean;
}

const getInitialOptions = (customerDetails?: ICustomer, selectedCustomer?: number[]): IOptions[] => {
  if (selectedCustomer) {
    return (
      customerDetails?.Contacts?.map(contact => {
        if (contact.BusinessContactID && selectedCustomer.includes(contact.BusinessContactID)) {
          return {
            ...contact,
            isSelected: true,
          };
        }
        return {
          ...contact,
          isSelected: false,
        };
      }) || []
    );
  }
  return (
    customerDetails?.Contacts?.map(contact => {
      if (contact.IsPrimaryContact) {
        return {
          ...contact,
          isSelected: true,
        };
      }
      return {
        ...contact,
        isSelected: false,
      };
    }) || []
  );
};

const EmailType = ['To', 'CC', 'BCC'];

const getInitialSelectedContact = (optionsList: IOptions[]) => {
  const filteredSelected = optionsList?.filter(option => option.isSelected);
  return filteredSelected.map(option => {
    return {
      ...option,
      templateItemName: `${EmailType[0]}: ${option.FirstName}`,
    };
  });
};


export const AddAdditionalContactCustomerModal: FC<IAddAdditionalContactCustomerModal> = props => {
  const {handleBack, selectedCustomer, handleContinue} = props;

  const {customerDetails, setCustomerDetails} = useContext(QuoteContext);
  const {getCustomer} = useCustomersAPI();


  // States
  const [query, setQuery] = useState('');
  const [error, setError] = useState('');
  const [openCustomer, setOpenCustomer] = useState<boolean>(false);
  const [optionsList, setOptionsList] = useState(() => {
    return getInitialOptions(customerDetails, selectedCustomer)
  });
  const [selectedContact, setSelectedContact] = useState(() => {
    return getInitialSelectedContact(optionsList);
  });

  const getCustomerAPI = async () => {
    if (!customerDetails?.ID) return;
    const updatedCustomer = await getCustomer(customerDetails?.ID);
    setCustomerDetails(updatedCustomer);
    if (updatedCustomer) {
      const newData = updatedCustomer?.Contacts?.map(contact => {
        if (selectedContact?.find(v => v.BusinessContactID === contact.BusinessContactID)) {
          return {
            ...contact,
            isSelected: true,
          };
        }
        return {
          ...contact,
          isSelected: false,
        };
      });
      setOptionsList(prev => {
        if (!newData) return prev;
        return newData;
      });
    }
  };

  const filterOptions = (value: string) => {
    if (!optionsList?.length) return [];
    if (value === '') {
      return optionsList;
    }
    const filtered = optionsList.filter(contact => {
      return (
        contact?.FirstName?.toLowerCase().includes(value.toLowerCase()) ||
        contact?.EmailAddress?.toLowerCase().includes(value.toLowerCase())
      );
    });
    return filtered;
  };

  // Memoized values
  const filteredOptions = useMemo(() => {
    return filterOptions(query);
  }, [query, optionsList]);



  // Input onChange function
  const onChangeInput: ChangeEventHandler = e => {
    e.stopPropagation();
    const value = e.target.value;
    setQuery(value);
    setError('');
  };

  // On Change select function
  const onChange = (contactID: string) => {
    let latest = optionsList.find(v => `${v.BusinessContactID}` === contactID);
    if (!latest) return;
    setOptionsList(prev => {
      let latestOptions = prev.map(v => {
        if (`${v.BusinessContactID}` === contactID) {
          v.isSelected = !v.isSelected;
        }
        return v;
      });
      return latestOptions;
    });

    if (latest && latest.isSelected === false) {
      setSelectedContact(prev => {
        if (!prev) return [];
        const filtered = prev.filter(v => `${v.BusinessContactID}` !== contactID);
        return filtered;
      });
    } else {
      setSelectedContact(prev => {
        if (latest) {
          const value = {
            ...latest,
            templateItemName: `${EmailType[0]}: ${latest.FirstName}`,
          };
          return [...prev, value];
        }
        return prev;
      });
    }
  };

  // On Remove selected contact
  const onRemoveSelected = (contact?: (typeof selectedContact)[0]) => {
    if (!contact) return;
    setSelectedContact(prev => prev.filter(v => v.BusinessContactID !== contact.BusinessContactID));
    setOptionsList(prev => {
      const currentValue = prev.map(v => {
        if (v.BusinessContactID === contact.BusinessContactID) {
          v.isSelected = false;
        }
        return v;
      });
      return currentValue;
    });
  };

  // Key Press event
  const handleKeyDown = (event: any) => {
    if (event.key === 'Backspace' && query === '') {
      onRemoveSelected(selectedContact.at(selectedContact.length - 1));
    }
  };

  // View of selected contact in multi select dropdown
  const ViewSelected = (value: (typeof selectedContact)[0]) => {
    return (
      <Chip
        key={value.BusinessContactID}
        value={
          <div className="flex items-center gap-2">
            <span>{value.templateItemName}</span>
            <span onClick={() => onRemoveSelected(value)} className="text-base text-[#ccc]">
              &times;
            </span>
          </div>
        }
        color="primary"
        className="h-[22px] rounded px-2 py-1.5 leading-[7px]"
        data-autoid={`txtContact-${value.BusinessContactID}`}
      ></Chip>
    );
  };

  return (
    <AlertDialogSlideV2
      data-autoid="dlgManageRecipients"
      dialogClassess="!min-w-[562px] !max-w-[562px] !w-full"
      title="Manage recipients"
      headingTextSize="h1"
      footer={
        <div className="flex items-center justify-between w-full">
          <div className="flex gap-3">
            <Button
              data-autoid="btnBack"
              variant="outlined"
              className="bg-white focus:shadow-none"
              onClick={handleBack}
            >
              Go back
            </Button>
          </div>
          <div className="flex gap-2.5">
            <Button
              type="submit"
              onClick={() => {
                setOpenCustomer(true);
              }}
            >
              Add New Contact
            </Button>
            <Button
              onClick={() => {
                if (selectedContact.length === 0) return setError('Please select at least one contact');
                const selectedContactIDs = selectedContact.map(v => v.BusinessContactID);
                handleContinue(selectedContactIDs);
              }}
            >
              Continue
            </Button>
          </div>
        </div>
      }
    >
      <div className="pt-10 pb-28">
        <div className={`${error ? 'border-error' : 'border-primary'} relative mx-auto flex w-3/4 rounded-md border`}>
          <label
            className={`${
              error ? 'text-error' : 'text-black-800 '
            } absolute left-2.5 top-[-9px] bg-white px-1 text-xs font-medium`}
          >
            {error ? error : 'Contact name'}
          </label>
          <div className="relative w-full after:absolute after:right-0 after:top-2 after:h-[calc(100%-16px)] after:w-[2px] after:bg-[#ccc] after:content-['']">
            <Select
              className="border-none"
              isMultiSelect
              viewOfSelected={ViewSelected}
              onChange={onChange}
              value={selectedContact}
              menuProps={{className: 'max-h-60'}}
              inputProps={{
                value: query,
                placeholder: 'Search Customer',
                onChange: onChangeInput,
                onKeyDown: handleKeyDown,
                className: 'flex-auto	min-w-[64px]',
              }}
            >
              {filteredOptions.length ? (
                filteredOptions?.map((options, index) => {
                  return (
                    <Option
                      value={`${options.BusinessContactID}`}
                      key={index}
                      className="flex justify-start border-t-0 border-[#BBBBBB] p-2.5 last:border-b-0"
                    >
                      <div className="flex items-start justify-start">
                        <Checkbox
                          ripple={false}
                          readOnly
                          checked={options.isSelected}
                          value={options.FirstName}
                          containerProps={{
                            className: 'py-1 px-0',
                          }}
                          name={options.FirstName}
                          className="h-4 w-4 rounded-[4px] border-primary bg-white transition-all checked:border-primary checked:bg-primary hover:before:opacity-0"
                        />
                      </div>
                      <div className="w-[calc(100%-16px)] pl-2.5">
                        <div className="flex items-center justify-between">
                          <Typography className="font-semibold text-black-800">{options.FirstName}</Typography>
                          <Typography className="text-sm text-spenda-labeltext">{options.Phone1}</Typography>
                        </div>
                        <div className="flex items-center justify-between">
                          <Typography className="text-sm text-spenda-labeltext">{`[${
                            options?.ContactRole ? options.ContactRole : 'Reg'
                          }]`}</Typography>
                          <Typography className="text-sm text-spenda-labeltext">{options.EmailAddress}</Typography>
                        </div>
                      </div>
                    </Option>
                  );
                })
              ) : (
                <Option value="No Data" disabled className="flex justify-start border border-[#BBBBBB] p-2.5">
                  No Result found
                </Option>
              )}
            </Select>
          </div>
          <div className="flex items-center w-24">
            <Typography className="pl-2 text-base font-semibold text-black-800">{EmailType[0]}</Typography>
          </div>
        </div>
      </div>
      {openCustomer && (
        <CustomerDialog
          customerId={customerDetails?.ID}
          open={openCustomer}
          onClose={() => setOpenCustomer(false)}
          setOpenAccountCustomer={() => {}}
          setCustomer={async () => {
            await getCustomerAPI();
          }}
          isQuoteCustomer
          onCustomerUpdate={() => setOpenCustomer(false)}
        />
      )}
    </AlertDialogSlideV2>
  );
};
