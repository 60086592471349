import React, {useContext} from 'react';
import {Link} from 'react-router-dom';
import PurchasingContext from '../../context/purchasing/purchasingContext';
import {AUTH_SELLING_COLLECT_ROUTE} from '../../routes/AuthenticatedRoutes';
import {useTenantInfo} from '../../hooks/useTenantInfo';
import {NameInitials} from '../../utils/formatter';
import {ConversationType} from '../../model/constants/Constants';
import {RightArrowButton} from '../../assets/svg/RightArrowButton';
import {CaretUp} from '../../assets/svg/CaretUp';
import {useTransactionViewHook} from '../../hooks/useARHook';
import Skeleton from 'react-loading-skeleton';
import {Accordion, AccordionBody, AccordionHeader, Badge, Typography} from 'spenda-ui-react';

interface IAllOpenChatsProps {
  expand: boolean;
  setExpand: React.Dispatch<React.SetStateAction<boolean>>;
  handleOpenConversation: (operationId: string, isMinimizeAllOpenChatWindow?: boolean) => void;
  isLoading: boolean;
}

export const AllOpenChats = (props: IAllOpenChatsProps) => {
  // Props
  const {expand, setExpand, handleOpenConversation, isLoading} = props;

  // Context
  const {conversations} = useContext(PurchasingContext);

  // Hooks
  const {isInSupplierContext} = useTenantInfo();
  const {handleViewTx} = useTransactionViewHook();

  const toggleAccordion = () => {
    setExpand(prev => !prev);
  };

  return (
    <Accordion
      className={`w-full bg-white`}
      open={expand}
      icon={
        <div data-autoid={`btnExpandAllOpenChats`}>
          <CaretUp className={`cursor-pointer ${expand ? 'rotate-180' : ''}`} name={expand ? 'Minimize' : 'Maximize'} />
        </div>
      }
    >
      <AccordionHeader
        className="w-full cursor-default rounded-t-lg bg-primary/20 p-0 pr-4"
        aria-controls="panel1a-content"
        id="panel1a-header"
        onClick={toggleAccordion}
      >
        <div
          className={`box-border flex h-[60px] min-h-[60px] w-full flex-row items-center justify-between px-4 py-2.5`}
        >
          <Typography variant="h2" className={`font-light text-black-800`}>
            All Conversations
          </Typography>
        </div>
      </AccordionHeader>
      <AccordionBody className="p-0">
        <div className="w-full">
          <div className="flex h-[65vh] flex-col overflow-y-scroll">
            {isLoading ? (
              Array(7)
                ?.fill(1)
                ?.map((itm, ind) => (
                  <div key={ind} className="px-5 py-4">
                    <div className="flex items-center justify-center gap-5">
                      <div className="">
                        <Skeleton circle={true} width={45} height={45} />
                      </div>
                      <div className="w-full">
                        <Skeleton width={'90%'} height={'1.5rem'} />
                      </div>
                    </div>
                  </div>
                ))
            ) : conversations?.length > 0 ? (
              conversations?.map((convo, i) => (
                <div
                  data-autoid={`lblOpenConvo${convo.operationUniqueId}`}
                  className="flex flex-row items-center justify-between p-5"
                  key={`${convo?.operationUniqueId}convoRow${i}`}
                >
                  <div className="relative flex flex-row items-center">
                    {convo.unreadMessages !== 0 ? (
                      <Badge
                        content={
                          convo.unreadMessages! > 0 ? (
                            <span data-autoid={`unreadChat-${convo.operationUniqueId}}`}>{convo.unreadMessages}</span>
                          ) : (
                            convo.unreadMessages
                          )
                        }
                        color="error"
                        className="right-2 top-2 !min-h-[18px] !min-w-[18px] font-poppins !text-[10px]"
                      >
                        <div
                          className="flex h-10 w-10 flex-row items-center justify-center rounded-full bg-[#3D9F7820] font-poppins text-[#3D9F78]"
                          data-autoid={`lblNameInitials${convo.conversationId}`}
                        >
                          <p className="font-poppins !text-base">{NameInitials(convo?.conversationWith || '')}</p>
                        </div>
                      </Badge>
                    ) : (
                      <div
                        className="flex h-10 w-10 flex-row items-center justify-center rounded-full bg-[#3D9F7820] font-poppins text-[#3D9F78]"
                        data-autoid={`lblNameInitials${convo.conversationId}`}
                      >
                        <p className="font-poppins !text-base">{NameInitials(convo?.conversationWith || '')}</p>
                      </div>
                    )}
                    <div
                      className="ml-4 flex gap-1 text-ellipsis font-poppins text-base font-normal text-black-800"
                      data-autoid={`lblTitle${convo.conversationId}`}
                    >
                      {isInSupplierContext && convo.customerID ? (
                        <Link to={`${AUTH_SELLING_COLLECT_ROUTE}/${convo?.customerID}`}>{convo?.conversationWith}</Link>
                      ) : (
                        convo?.conversationWith
                      )}
                      {convo.convoType === ConversationType.DOCUMENT && convo?.refNumber && (
                        <Typography
                          variant="paragraph"
                          className="cursor-pointer text-black-800"
                          onClick={() => {
                            if (convo?.guid && convo?.datTypeID)
                              handleViewTx({txGuid: convo.guid!, txDatType: convo.datTypeID!, txId: undefined});
                          }}
                        >
                          {`- ${convo.refNumber}`}
                        </Typography>
                      )}
                    </div>
                  </div>
                  <div
                    data-autoid={`btnOpenChatMessages-${i + 1}`}
                    onClick={() => handleOpenConversation(convo.operationUniqueId)}
                    className="flex h-8 w-8 cursor-pointer flex-col items-center justify-center rounded-full bg-[#D3E5EF] font-poppins"
                  >
                    <RightArrowButton />
                  </div>
                </div>
              ))
            ) : (
              <div className="flex h-full items-center justify-center">
                <Typography variant="paragraph" className="text-black-800">
                  There is no conversation right now.
                </Typography>
              </div>
            )}
          </div>
        </div>
      </AccordionBody>
    </Accordion>
  );
};
