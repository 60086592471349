import React, {FunctionComponent} from 'react';
import {css} from 'glamor';

export interface IToggleSwitch {
  value: boolean | undefined;
  disabled?: boolean;
  handleChange: (value: string) => void;
}

const SwitchStyle = css({
  fontFamily: 'Lucida Grande, Tahoma, Verdana, sans-serif',
  position: 'relative',
  height: '20px',
  minWidth: '48px',
  width: '48px',
  backgroundColor: '#E3E3E3',
  borderRadius: '10px',
});

const SwitchLabelStyle = css({
  position: 'relative',
  zIndex: '2',
  float: 'left',
  width: '16px',
  height: '18px',
  top: '1px',
  fontSize: '12px',
});

const SwitchSelectionStyle = css({
  display: 'block',
  position: 'absolute',
  zIndex: '1',
  top: '1px',
  left: '1px',
  width: '18px',
  height: '18px',
  background: '#FAFAFA',
  borderRadius: '10px',
});

const SwitchSelectionTextStyle = css({
  display: 'block',
  position: 'absolute',
  textAlign: 'center',
  fontSize: '12px',
  width: '100%',
});

const ClickableLabel: FunctionComponent<{
  title: string;
  disabled: boolean;
  onChange: (value: string) => void;
}> = ({title, disabled, onChange}) => (
  <label
    className={`${SwitchLabelStyle} ${disabled ? 'cursor-default' : 'cursor-pointer'}`}
    onClick={() => onChange(title)}
  ></label>
);

const ConcealedRadio: FunctionComponent<{value: string; selected: string}> = ({value, selected}) => (
  <input className="hidden" type="radio" name="switch" checked={selected === value} />
);

export const ToggleSwitch: FunctionComponent<IToggleSwitch> = ({value, disabled, handleChange}) => {
  const _value = typeof value === 'boolean' ? value.toString() : '';
  const getStyleCalc = () => {
    switch (_value) {
      case 'false':
        return '-2px';
      case 'true':
        return '3px';
      default:
        return '0px';
    }
  };

  const selectionStyle = () => {
    return {
      left: `calc(${(['false', '', 'true'].indexOf(_value) / 3) * 100}% - ${getStyleCalc()})`,
    };
  };

  const selectionLabelStyle = () => {
    switch (_value) {
      case 'false':
        return {
          color: '#333333',
          paddingLeft: '1rem',
        };
      case 'true':
        return {
          color: '#FFFFFF',
          paddingRight: '0.8rem',
        };
      default:
        return {};
    }
  };

  const getSwitchBgColor = () => {
    if (_value === 'false') {
      return {
        backgroundColor: '#E3E3E3',
      };
    } else if (_value === 'true') {
      return {
        backgroundColor: '#34C759',
      };
    }
  };

  return (
    <div className={`${SwitchStyle}`} style={getSwitchBgColor()}>
      <span>
        <ConcealedRadio value={'false'} selected={_value} />
        <ClickableLabel title={'false'} disabled={Boolean(disabled)} onChange={handleChange} />
      </span>
      <span>
        <ConcealedRadio value={''} selected={_value} />
        <ClickableLabel title={''} disabled={Boolean(disabled)} onChange={handleChange} />
      </span>
      <span>
        <ConcealedRadio value={'true'} selected={_value} />
        <ClickableLabel title={'true'} disabled={Boolean(disabled)} onChange={handleChange} />
      </span>
      <span className={`${SwitchSelectionStyle}`} style={selectionStyle()} />
      <span className={`${SwitchSelectionTextStyle}`} style={selectionLabelStyle()}>
        {_value === 'true' ? 'On' : _value === 'false' ? 'Off' : ''}
      </span>
    </div>
  );
};
