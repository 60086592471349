import React, {FunctionComponent, PropsWithChildren} from 'react';
import {css} from 'glamor';
import ListAltIcon from '@material-ui/icons/ListAlt';
import {IRequisitionActionOption} from '../../model/requisition/RequisitionActionOption';
interface IStatusCounterItemProps extends IRequisitionActionOption, PropsWithChildren {
  IsDisabled?: boolean;
}

export const StatusCounterItem: FunctionComponent<IStatusCounterItemProps> = props => {
  let padding = '1em';

  switch (props.Padding) {
    case 'xs':
      padding = '0em';
      break;
    case 's':
      padding = '0.05em';
      break;
    case 'm':
      padding = '0.5em';
      break;
    case 'l':
      padding = '1em';
      break;
  }

  const styles = css({
    alignItems: 'center',
    // fontFamily: "Saira Extra Condensed",
    backgroundColor: props.IsActive ? props.DarkColor : props.LightColor, // "rgba(171, 210, 223, 0.55)",
    color: props.IsActive ? props.LightColor : props.DarkColor,
    '@media(max-width: 1024px)': {
      fontSize: '15px',
      display: 'flex',
      marginLeft: 0,
      border: `1px solid ${props.DarkColor}`,
      lineHeight: 1,
    },
    border: `0.1rem solid ${props.DarkColor}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: padding,
    flex: '1 1 0px',
    height: '100%',
    marginLeft: '0.25em',
    borderRadius: '5px',
    '&:active': {
      backgroundColor: props.DarkColor,
      color: props.LightColor,
    },
    '&:focus': {
      outline: 'none',
    },
  });

  const onClickStatusCounter = () => {
    if (props.Action) {
      props.Action(props);
    }
  };

  return (
    <button
      data-autoid={props.Label?.toLowerCase().split(' ').join('-')}
      className={`${styles}`}
      onClick={onClickStatusCounter}
      disabled={props.IsDisabled}
    >
      {props.children}
    </button>
  );
};

export const StatusCounterList = (props: {items: any[]}) => {
  const styles = css({
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    height: '100%',
    '& :first-child': {
      marginLeft: 0,
    },
  });

  const CounterLabelStyle = css({
    fontSize: '3.5rem',
    lineHeight: '2rem !important',
  });

  return (
    <div className={`${styles}`}>
      {props.items.map((item: IStatusCounterItemProps, index: number) => (
        <StatusCounterItem Padding="l" key={index} {...item}>
          {item.Icon ? (
            <ListAltIcon fontSize="large" />
          ) : (
            <span className={`${CounterLabelStyle}`}>{item.Counter}</span>
          )}
          <span>{item.Label}</span>
        </StatusCounterItem>
      ))}
    </div>
  );
};
