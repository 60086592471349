import {ISynkAuthorizeResponse, ISynkIsAuthorizedResponse} from '../model/synkd/SynkAuthorizeResponse';
import useHttp from '../hooks/useHttp';

const useSynkAPI = () => {
  const {POST, GET, isLoading} = useHttp();

  const connect = (Username: string, AdaptorName: string, CallbackUrl: string, websiteId: number): Promise<any> => {
    return POST('synk/connect', {
      Username,
      AdaptorName,
      CallbackUrl,
      websiteId,
    }).then((data: any) => data);
  };

  const synkAuthorize = (
    Username: string,
    AdaptorName: string,
    AuthorizeReturnUri: string,
    CallbackUrl: string,
    websiteId: number
  ): Promise<ISynkAuthorizeResponse> => {
    return POST('synk/authorize', {
      Username,
      AdaptorName,
      AuthorizeReturnUri,
      CallbackUrl,
      websiteId,
    }).then((data: ISynkAuthorizeResponse) => data);
  };

  const synkIsAuthorize = (
    AdaptorName: string,
    AdaptorId: number,
    MasterAdaptorID: number
  ): Promise<ISynkIsAuthorizedResponse> => {
    return POST('synk/isAuthorized', {
      AdaptorName,
      AdaptorId,
      MasterAdaptorID,
    }).then((data: ISynkIsAuthorizedResponse) => data);
  };

  const disconnect = (AdaptorID: number, AdaptorName: string, websiteId: number): Promise<any> => {
    return POST('synk/disconnect', {AdaptorID, AdaptorName, websiteId}).then((data: any) => data);
  };

  const getOrgName = (adaptorID: number): Promise<any> => {
    return GET(`synk/onauthorised?AdaptorID=${adaptorID}`).then((data: any) => data);
  };

  const onAuthorised = (
    AdaptorID: number | undefined,
    OrganisationName: string,
    LoginUserId: string,
    LoginPassword: string,
    OrganisationId: string
  ): Promise<any> => {
    return POST('synk/onauthorised', {
      AdaptorID,
      OrganisationDetail: {
        OrganisationName,
        LoginUserId,
        LoginPassword,
        OrganisationId,
      },
    }).then((data: any) => data);
  };

  return {
    isLoading,
    connect,
    synkAuthorize,
    synkIsAuthorize,
    disconnect,
    getOrgName,
    onAuthorised,
  };
};

export default useSynkAPI;
