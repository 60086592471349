import {useEffect, useState} from 'react';
import useHttp from '../hooks/useHttp';
import { IAssetLocationAddress, ICustomerAsset} from '../model/customer/Customer';
import {ISearchFilter} from '../model/search-filters/SearchFilter';
import {IActionResultsPayments} from '../model/ActionResults';

export interface IAssetSearchFilter extends ISearchFilter {
  customerID: number;
}

export const useCustomersAssetAPI = () => {
  const {GET, POST, isLoading} = useHttp();

  const getCustomerAsset = (customerID: number, data?: IAssetSearchFilter): Promise<ICustomerAsset[]> => {
    return GET(`Spenda/Customers/${customerID}/Assets`, data).then(res => res.value.items);
  };

  const saveCustomerAsset = (customerID: number, data?: ICustomerAsset): Promise<ICustomerAsset> => {
    return POST(`Spenda/Customers/${customerID}/Assets`, data).then(
      (res: IActionResultsPayments<ICustomerAsset>) => res.value,
    );
  };

  const fetchAssetDetails = (assetID: number): Promise<ICustomerAsset> => {
    return GET(`Spenda/Customers/Assets/${assetID}`).then(res => res.value);
  };

  const updateCustomerAsset = (assetID: number, data?: IAssetLocationAddress): Promise<IAssetLocationAddress> => {
    return POST(`Spenda/Customers/Address/${assetID} `, data).then((res: IActionResultsPayments<IAssetLocationAddress>) => res.value);
  };

  return {
    isLoading,
    getCustomerAsset,
    saveCustomerAsset,
    fetchAssetDetails,
    updateCustomerAsset
  };
};

export const useLoadAsset = (customerID: number) => {
  const initialFilterState = {
    customerID: customerID,
    StartRow: 1,
    MaxResults: 15,
    SearchString: '',
  } as IAssetSearchFilter;

  const {getCustomerAsset} = useCustomersAssetAPI();

  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<ICustomerAsset[]>([]);
  const [searchFilter, setSearchFilter] = useState<IAssetSearchFilter>(initialFilterState);

  const getCustomerAssetList = async () => {
    setLoading(true);
    try {
      const result = await getCustomerAsset(customerID, searchFilter);
      if (result === undefined) return;
      let items = result || [];
      setItems(items);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCustomerAssetList();
  }, [searchFilter]);

  return {
    loading,
    items,
    searchFilter,
    setSearchFilter,
  };
};
