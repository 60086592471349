import React, {useEffect, useState} from 'react';
import {Dialog} from '@material-ui/core';
import {PrimaryButton, SecondaryButton} from '../buttons/DefaultButtons';
import {Close} from '@material-ui/icons';
import moment, {Moment} from 'moment';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import {ICustomerStatementFilterQuery} from '../../services/useCustomerStatementsAPI';
import {IBuyerStatementFilterQuery} from '../../services/useSupplierTransactionsAPI';
import ARDatepicker from './ARDatepicker';

interface ISelectableButtonProps {
  onClick: (event: React.MouseEvent<HTMLButtonElement> | any) => void;
  label: string;
  isSelected?: boolean;
  selectedLabel?: string;
  className?: string;
  dataAutoId?: string;
  disabled?: boolean;
}

const SelectableButton = ({
  isSelected,
  label,
  selectedLabel,
  onClick,
  className,
  dataAutoId,
  disabled,
}: ISelectableButtonProps) => {
  return (
    <div
      className={`flex h-10 min-w-[100px] select-none items-center justify-center gap-2.5 rounded-[6px] border-[1px] border-[#F7F7F7] bg-[#F7F7F7] px-2.5 font-poppins text-base font-medium text-[#4D4D4D] 
      ${isSelected && 'border-[1px] !border-[#1c78ad] bg-[#F7F7F7] text-[#4D4D4D]'}
      ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'} ${className}`}
      data-autoid={dataAutoId}
      onClick={e => (disabled ? '' : onClick(e))}
    >
      {isSelected && selectedLabel ? selectedLabel : label}
    </div>
  );
};

export interface IAdvanceFilterProps {
  open: boolean;
  handleClose: () => void;
  transactionFilterQuery: ICustomerStatementFilterQuery | IBuyerStatementFilterQuery | undefined;
  onSubmit: (filterValues: ICustomerStatementFilterQuery | IBuyerStatementFilterQuery | undefined) => void;
}

export const AdvanceFilter = (props: IAdvanceFilterProps) => {
  const {open, handleClose, transactionFilterQuery, onSubmit} = props;

  const [filterValues, setFilterValues] = useState<ICustomerStatementFilterQuery | IBuyerStatementFilterQuery>();

  useEffect(() => {
    setFilterValues({...transactionFilterQuery});
  }, [transactionFilterQuery]);

  const handlendDatesChange = (arg: {startDate: Moment | null; endDate: Moment | null}) => {
    setFilterValues({
      ...filterValues,
      startDate: arg.startDate ? moment.utc(arg.startDate).format('DD/MM/YYYY') : undefined,
      endDate: arg.endDate ? moment.utc(arg.endDate).format('DD/MM/YYYY') : undefined,
    });
  };

  const handleClear = () => {
    setFilterValues({});
  };

  const handleSubmit = () => {
    onSubmit(filterValues);
    handleClose();
  };

  const {startDate, endDate} = filterValues || {};

  const dueLastMonthStartDate = moment.utc().subtract(1, 'months').startOf('month').format('DD/MM/YYYY');
  const dueLastMonthEndDate = moment.utc().subtract(1, 'months').endOf('month').format('DD/MM/YYYY');

  const dueThisMonthStartDate = moment.utc().startOf('month').format('DD/MM/YYYY');
  const dueThisMonthEndDate = moment.utc().endOf('month').format('DD/MM/YYYY');

  const dueNextMonthStartDate = moment.utc().add(1, 'months').startOf('month').format('DD/MM/YYYY');
  const dueNextMonthEndDate = moment.utc().add(1, 'months').endOf('month').format('DD/MM/YYYY');

  const dueOlderThanLastMonthEndDate = moment.utc().subtract(2, 'months').endOf('month').format('DD/MM/YYYY');

  let isDueLastMontSelected = false;
  if (startDate === dueLastMonthStartDate && endDate === dueLastMonthEndDate) {
    isDueLastMontSelected = true;
  } else if (startDate === dueLastMonthStartDate && endDate === dueThisMonthEndDate) {
    isDueLastMontSelected = true;
  } else if (startDate === dueLastMonthStartDate && endDate === dueNextMonthEndDate) {
    isDueLastMontSelected = true;
  } else if (!startDate && endDate === dueLastMonthEndDate) {
    isDueLastMontSelected = true;
  }

  let isDueThisMonthSelected = false;
  if (startDate === dueThisMonthStartDate && endDate === dueThisMonthEndDate) {
    isDueThisMonthSelected = true;
  } else if (startDate === dueLastMonthStartDate && endDate === dueThisMonthEndDate) {
    isDueThisMonthSelected = true;
  } else if (startDate === dueThisMonthStartDate && endDate === dueNextMonthEndDate) {
    isDueThisMonthSelected = true;
  } else if (!startDate && endDate === dueThisMonthEndDate) {
    isDueThisMonthSelected = true;
  }

  let isDueNextMonthSelected = false;
  if (startDate === dueNextMonthStartDate && endDate === dueNextMonthEndDate) {
    isDueNextMonthSelected = true;
  } else if (startDate === dueThisMonthStartDate && endDate === dueNextMonthEndDate) {
    isDueNextMonthSelected = true;
  } else if (startDate === dueLastMonthStartDate && endDate === dueNextMonthEndDate) {
    isDueNextMonthSelected = true;
  } else if (!startDate && endDate === dueNextMonthEndDate) {
    isDueNextMonthSelected = true;
  }

  let isDueOlderThanLastMonthSelected = false;
  if (!startDate && endDate === dueOlderThanLastMonthEndDate) {
    isDueOlderThanLastMonthSelected = true;
  } else if (!startDate && endDate === dueLastMonthEndDate) {
    isDueOlderThanLastMonthSelected = true;
  } else if (!startDate && endDate === dueThisMonthEndDate) {
    isDueOlderThanLastMonthSelected = true;
  } else if (!startDate && endDate === dueNextMonthEndDate) {
    isDueOlderThanLastMonthSelected = true;
  }

  const isEndDateIsDueThisMonth = dueThisMonthEndDate === endDate;
  const isEndDateIsDueNextMonth = dueNextMonthEndDate === endDate;

  const isStartDateIsDueThisMonth = startDate === dueThisMonthStartDate;
  const isStartDateIsDueLastMonth = startDate === dueLastMonthStartDate;

  return (
    <Dialog open={open} maxWidth="lg" id="mouse-over-popover">
      <div className={`w-full overflow-hidden text-center`}>
        <div className="relative flex h-[60px] flex-row items-center justify-center border-[#F7F7F7] border-b-default px-4">
          <p className=" font-poppins text-xl font-light text-[#333333]">Filter view</p>
          <div
            data-autoid="btnClose"
            onClick={handleClose}
            className="absolute right-2.5 top-2.5 z-10 flex h-[40px] w-[40px] cursor-pointer flex-row items-center justify-center rounded-[6px] border-[1px] border-primary"
          >
            <Close className="!h-[22px] !w-[22px] text-primary" />
          </div>
        </div>
        <div className="mb-2.5 mt-10 flex justify-center gap-2.5 px-4">
          <SelectableButton
            label="Hide selected"
            isSelected={filterValues?.hideSelected}
            onClick={() =>
              setFilterValues({
                ...filterValues,
                hideSelected: filterValues?.hideSelected ? undefined : true,
                showSelectedOnly: undefined,
              })
            }
            dataAutoId="btnHideSelectedAdvanceFilter"
          />
          <SelectableButton
            label="Show only selected"
            isSelected={filterValues?.showSelectedOnly}
            onClick={() =>
              setFilterValues({
                ...filterValues,
                showSelectedOnly: filterValues?.showSelectedOnly ? undefined : true,
                hideSelected: undefined,
              })
            }
            dataAutoId="btnShowOnlySelectedAdvanceFilter"
          />
          <SelectableButton
            label="Credit/payments"
            isSelected={filterValues?.showPaymentsCreditsOnly}
            onClick={() =>
              setFilterValues({
                ...filterValues,
                showPaymentsCreditsOnly: filterValues?.showPaymentsCreditsOnly ? undefined : true,
                showInvoicesOnly: undefined,
              })
            }
            dataAutoId="btnShowOnlySelectedAdvanceFilter"
          />
          <SelectableButton
            label="Overdue"
            isSelected={filterValues?.asAtDate === moment().format('DD/MM/YYYY')}
            onClick={() =>
              setFilterValues({
                ...filterValues,
                asAtDate:
                  filterValues?.asAtDate === moment().format('DD/MM/YYYY') ? undefined : moment().format('DD/MM/YYYY'),
              })
            }
            dataAutoId="btnShowOnlySelectedAdvanceFilter"
          />
          <SelectableButton
            label="Show Invoices Only"
            isSelected={filterValues?.showInvoicesOnly}
            onClick={() =>
              setFilterValues({
                ...filterValues,
                showInvoicesOnly: filterValues?.showInvoicesOnly ? undefined : true,
                showPaymentsCreditsOnly: undefined,
              })
            }
            dataAutoId="btnShowOnlySelectedAdvanceFilter"
          />
        </div>
        <div className=" mb-10 flex justify-center gap-2.5 px-4">
          <SelectableButton
            label="Older than last month"
            isSelected={isDueOlderThanLastMonthSelected}
            onClick={() => {
              if (isDueOlderThanLastMonthSelected) {
                if (isDueLastMontSelected) {
                  setFilterValues({
                    ...filterValues,
                    startDate: dueLastMonthStartDate,
                    endDate: dueLastMonthEndDate,
                  });
                } else if (isDueThisMonthSelected) {
                  setFilterValues({
                    ...filterValues,
                    startDate: dueThisMonthStartDate,
                    endDate: dueThisMonthEndDate,
                  });
                } else if (isDueNextMonthSelected) {
                  setFilterValues({
                    ...filterValues,
                    startDate: dueNextMonthStartDate,
                    endDate: dueNextMonthEndDate,
                  });
                }
                return;
              }

              if (isDueLastMontSelected || isDueThisMonthSelected || isDueNextMonthSelected) {
                setFilterValues({
                  ...filterValues,
                  startDate: undefined,
                });
                return;
              }

              setFilterValues({
                ...filterValues,
                endDate: dueOlderThanLastMonthEndDate,
              });
            }}
            dataAutoId="btnOlderThenLastMonthAdvanceFilter"
          />
          <SelectableButton
            label="Due last month"
            isSelected={isDueLastMontSelected}
            onClick={() => {
              if (isDueLastMontSelected) {
                if (dueLastMonthEndDate === endDate && dueLastMonthStartDate === startDate) {
                  setFilterValues({
                    ...filterValues,
                    endDate: undefined,
                    startDate: undefined,
                  });
                } else if (isStartDateIsDueLastMonth && isEndDateIsDueThisMonth) {
                  setFilterValues({
                    ...filterValues,
                    startDate: dueThisMonthStartDate,
                  });
                } else if (isStartDateIsDueLastMonth && isEndDateIsDueNextMonth) {
                  setFilterValues({
                    ...filterValues,
                    startDate: dueNextMonthStartDate,
                  });
                }
                return;
              }

              if (isDueThisMonthSelected || isDueNextMonthSelected) {
                setFilterValues({
                  ...filterValues,
                  startDate: dueLastMonthStartDate,
                });
                return;
              }

              if (isDueOlderThanLastMonthSelected) {
                setFilterValues({
                  ...filterValues,
                  endDate: dueLastMonthEndDate,
                });
                return;
              }

              setFilterValues({
                ...filterValues,
                startDate: dueLastMonthStartDate,
                endDate: dueLastMonthEndDate,
              });
            }}
            dataAutoId="btnDueLastMonthAdvanceFilter"
          />
          <SelectableButton
            label={'Due this month'}
            isSelected={isDueThisMonthSelected}
            onClick={() => {
              if (isDueThisMonthSelected) {
                if (dueThisMonthEndDate === endDate && dueThisMonthStartDate === startDate) {
                  setFilterValues({
                    ...filterValues,
                    endDate: undefined,
                    startDate: undefined,
                  });
                } else if (isStartDateIsDueLastMonth && isEndDateIsDueThisMonth) {
                  setFilterValues({
                    ...filterValues,
                    endDate: dueLastMonthEndDate,
                  });
                } else if (isStartDateIsDueThisMonth && isEndDateIsDueNextMonth) {
                  setFilterValues({
                    ...filterValues,
                    startDate: dueNextMonthStartDate,
                  });
                }
                return;
              }

              if (isDueOlderThanLastMonthSelected) {
                setFilterValues({
                  ...filterValues,
                  endDate: dueThisMonthEndDate,
                });
                return;
              }

              if (isDueNextMonthSelected) {
                setFilterValues({
                  ...filterValues,
                  startDate: dueThisMonthStartDate,
                });
                return;
              }

              if (isDueLastMontSelected) {
                setFilterValues({
                  ...filterValues,
                  endDate: dueThisMonthEndDate,
                });
                return;
              }

              setFilterValues({
                ...filterValues,
                startDate: dueThisMonthStartDate,
                endDate: dueThisMonthEndDate,
              });
            }}
            dataAutoId="btnDueNextMonthAdvanceFilter"
          />
          <SelectableButton
            label="Due next month"
            isSelected={isDueNextMonthSelected}
            onClick={() => {
              if (isDueNextMonthSelected) {
                if (dueNextMonthEndDate === endDate && dueNextMonthStartDate === startDate) {
                  setFilterValues({
                    ...filterValues,
                    endDate: undefined,
                    startDate: undefined,
                  });
                } else if (isStartDateIsDueLastMonth) {
                  setFilterValues({
                    ...filterValues,
                    endDate: dueLastMonthEndDate,
                  });
                } else if (isStartDateIsDueThisMonth) {
                  setFilterValues({
                    ...filterValues,
                    endDate: dueThisMonthEndDate,
                  });
                }
                return;
              }

              if (isDueOlderThanLastMonthSelected) {
                setFilterValues({
                  ...filterValues,
                  endDate: dueNextMonthEndDate,
                });
                return;
              }

              if (isDueLastMontSelected || isDueThisMonthSelected) {
                setFilterValues({
                  ...filterValues,
                  endDate: dueNextMonthEndDate,
                });
                return;
              }

              setFilterValues({
                ...filterValues,
                endDate: dueNextMonthEndDate,
                startDate: dueNextMonthStartDate,
              });
            }}
            dataAutoId="btnDueNextMonth"
          />
        </div>
        <p className=" pb-3 font-poppins text-base font-semibold text-[#BBBBBB]">Custom Range</p>
        <div className="flex flex-row justify-center">
          <div className={`relative flex w-full justify-center p-2.5`}>
            <ARDatepicker
              regular
              inputIconPosition="before"
              startDate={startDate ? moment.utc(startDate, 'DD/MM/YYYY') : null}
              endDate={endDate ? moment.utc(endDate, 'DD/MM/YYYY') : null}
              onDatesChange={handlendDatesChange}
              isOutsideRange={() => false}
            />
          </div>
        </div>
        <div className="m-2.5 flex h-[52px] items-center justify-between rounded-[6px] bg-[#EFEFEF] px-2.5">
          <SecondaryButton data-autoid="btnClear" label="Clear" onClick={handleClear} />
          <PrimaryButton data-autoid="btnDone" label="Done" onClick={handleSubmit} />
        </div>
      </div>
    </Dialog>
  );
};
