import React from 'react';

const SelectAPInvoiceIcon = (props: {width?: string; height?: string}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="305px" height="174px" viewBox="0 0 305 174" {...props}>
    <g
      transform="translate(-1071 -490) translate(1071 490)"
      stroke="none"
      strokeWidth={1}
      fill="none"
      fillRule="evenodd"
    >
      <rect fill="#FFF" x={0} y={0} width={305} height={174} rx={5} />
      <circle className="stroke-primary" cx={20.5} cy={31.5} r={8} />
      <rect fillOpacity={0.2} className="fill-primary" x={37} y={14} width={99} height={7} rx={2} />
      <rect fillOpacity={0.2} className="fill-primary" x={37} y={27} width={62} height={8} rx={2} />
      <rect fillOpacity={0.2} className="fill-primary" x={37} y={41} width={62} height={7} rx={2} />
      <path fill="#F1F1F1" d="M5 56H299V57H5z" />
      <rect fillOpacity={0.2} className="fill-primary" x={216} y={17.5} width={62} height={26} rx={2} />
      <circle strokeOpacity={0.3} className="stroke-primary" cx={20.5} cy={85.5} r={8} />
      <rect fill="#EAEAEA" x={37} y={68} width={99} height={7} rx={2} />
      <rect fill="#EAEAEA" x={37} y={81} width={62} height={8} rx={2} />
      <rect fill="#EAEAEA" x={37} y={95} width={62} height={7} rx={2} />
      <path fill="#F1F1F1" d="M5 110H299V111H5z" />
      <rect fill="#EAEAEA" x={216} y={71.5} width={62} height={26} rx={2} />
      <path
        className="fill-primary"
        fillRule="nonzero"
        d="M22.2135138 86.2666554L31.3985632 90.1490511 28.893661 92.3863568 33.581174 97.6604118 31.5911056 99.3960576 26.9038139 94.1218183 24.4845716 95.9107107z"
      />
      <circle stroke="#CCC" cx={20.5} cy={139.5} r={8} />
      <rect fill="#EAEAEA" x={37} y={122} width={99} height={7} rx={2} />
      <rect fill="#EAEAEA" x={37} y={135} width={62} height={8} rx={2} />
      <rect fill="#EAEAEA" x={37} y={149} width={62} height={7} rx={2} />
      <rect fill="#EAEAEA" x={216} y={125.5} width={62} height={26} rx={2} />
      <path
        className="stroke-primary"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M16.2095441 31.8330995L19.5462565 34.5 24.4416867 27.8761629"
      />
    </g>
  </svg>
);

export default SelectAPInvoiceIcon;
