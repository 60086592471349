import React from 'react';

export const RequiresAttention = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C19.994 4.47963 15.5204 0.00597071 10 0ZM10 18.3333C5.39763 18.3333 1.66667 14.6024 1.66667 10C1.66667 5.39763 5.39763 1.66667 10 1.66667C14.6024 1.66667 18.3333 5.39763 18.3333 10C18.3283 14.6003 14.6003 18.3283 10 18.3333Z"
        fill="#1C78AD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 4.16669C9.53978 4.16669 9.16669 4.53978 9.16669 5.00002V11.6667C9.16669 12.1269 9.53978 12.5 10 12.5C10.4603 12.5 10.8334 12.1269 10.8334 11.6667V5.00002C10.8334 4.53978 10.4603 4.16669 10 4.16669Z"
        fill="#1C78AD"
      />
      <rect x="9.16669" y="14.1667" width="1.66667" height="1.66667" rx="0.833333" fill="#1C78AD" />
    </svg>
  );
};
