import React, {useContext, FunctionComponent} from 'react';
import {css} from 'glamor';
import {Label} from '../Label';
import PurchasingContext from '../../context/purchasing/purchasingContext';
import Moment from 'react-moment';
import {IRequisition, ILinkedTran} from '../../model/requisition/Requisition';
import {Chip, Button, createTheme} from '@material-ui/core';
import usePurchaseOrderAPI from '../../services/usePurchaseOrderAPI';
import {PriceFormat} from '../../utils/formatter';
import {ThemeProvider} from '@material-ui/core/styles';

export const headerStyles = css({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  padding: '0.5em',
});

interface ITransactionListItemProps {
  transaction: IRequisition;
}

export const TransactionListItem: FunctionComponent<ITransactionListItemProps> = ({transaction}) => {
  const purchasingContext = useContext(PurchasingContext);
  const purchaseOrderAPI = usePurchaseOrderAPI();

  const darkColor = transaction.RequisitionType ? '#3BA179' : '#c8973a';
  const lightColor = transaction.RequisitionType ? 'rgb(217,237,229)' : '#f6eede';

  const border = css({
    border: `2px solid ${darkColor}`,
  });

  const borderBottom = css({
    borderBottom: `2px solid ${darkColor}`,
  });

  const headerBackground = css({
    backgroundColor: `${lightColor}`,
    borderTopLeftRadius: '3px',
    borderTopRightRadius: '3px',
  });

  const TransacListTheme = createTheme({
    overrides: {
      MuiButton: {
        root: {
          textTransform: 'none',
          fontSize: '1.05rem',
        },
      },
    },
    typography: {
      fontFamily: 'Saira Condensed',
    },
  });

  const onClickTransaction = () => {
    if (transaction.RequisitionType) {
      if (transaction.LinkedTrans && transaction.LinkedTrans.length) {
        purchasingContext.setPurchaseOrder(transaction.LinkedTrans[0].ID);
      } else {
        purchasingContext.setRequisition(transaction, false);
      }
    } else {
      purchasingContext.setPurchaseOrder(transaction.ID);
    }
  };

  const onClickReOrder = (event: React.MouseEvent) => {
    event.stopPropagation();
    purchasingContext.setIsShowRequisitionDetailsDialog(true, transaction);
  };

  return (
    <div className={`mb-2 cursor-pointer rounded border ${border}`} onClick={onClickTransaction}>
      <div className={`${headerStyles} && ${borderBottom} && ${headerBackground} relative`}>
        <Label>{transaction.RequisitionType ? transaction.Name : transaction.Name}</Label>
        <Label className="mr-20">
          {transaction.RequisitionType
            ? ''
            : purchaseOrderAPI.getStatusToDisplay(transaction.Status) + ' : ' + transaction.RefNumber}
        </Label>
        {(transaction.Status === 'Review' ||
          transaction.Status === 'Cancelled' ||
          transaction.Status === 'Approved') && (
          <div className="absolute" style={{right: '4px'}}>
            <ThemeProvider theme={TransacListTheme}>
              <Button
                variant="outlined"
                onClick={onClickReOrder}
                style={{
                  color: '#c8973a',
                  border: '1px solid #c8973a',
                  fontWeight: 500,
                  borderRadius: '4px',
                  padding: 0,
                }}
              >
                Reorder
              </Button>
            </ThemeProvider>
          </div>
        )}
      </div>
      <div className="xl:text-lg">
        <div className="flex justify-between p-2">
          <div>
            <p className="text-gray-700">
              Created On:{' '}
              <span className="text-black">
                {<Moment date={transaction.CreatedDateTime_utc} format="DD/MM/YYYY" local></Moment>}
              </span>
            </p>
            <p className="text-gray-700">
              Created By: <span className="text-black">{transaction.CreatedByUserName}</span>
            </p>
          </div>
          <div className="text-right">
            <p className="text-gray-700">
              Lines:<span className="text-black">{transaction.LineCount}</span>
            </p>
            <p className="text-gray-700">
              {'Buy (ex)'}:<span className="text-black">{PriceFormat(transaction.TotalEx)}</span>
            </p>
          </div>
        </div>
        {transaction.LinkedTrans &&
          transaction.LinkedTrans.map((linkedTran: ILinkedTran, index: number) => (
            <div key={index} className="flex justify-between rounded border sm:m-1 sm:p-1 xl:m-2 xl:p-2">
              <Chip key={index} label={purchaseOrderAPI.getStatusToDisplay(linkedTran.Status)} />
              <p>{linkedTran.RecipientName}</p>
              <p>{linkedTran.RefNumber}</p>
              <span>{PriceFormat(linkedTran.TotalEx)}</span>
            </div>
          ))}
      </div>
    </div>
  );
};

export const TransactionList = () => {
  const pContext = useContext(PurchasingContext);

  const tranListContainer = css({
    maxHeight: 'calc( 100vh - 15.5em )', //setting the specific height container to grow when the viewport height is changing
    minHeight: 'calc( 100vh - 15.5em )',
  });

  return (
    <div className={`${tranListContainer}`}>
      {pContext.transactions &&
        pContext.transactions.map((item: any, index: number) => <TransactionListItem key={index} transaction={item} />)}
    </div>
  );
};
