import React, {PropsWithChildren, useContext, useEffect, useState} from 'react';
import AppContext from '../../context/app/appContext';
import {useZohoChat} from '../../hooks/useZohoChat';
import {ZohoContext, ZohoValueType} from '../../context/zoho-context/ZohoContext';
import {useEnvironment} from '../../hooks/useEnvironment';

const ZohoContextProvider = (props: PropsWithChildren) => {
  const {user} = useContext(AppContext);
  const {ZOHO_WIDGET_CODE} = useEnvironment();

  const zohoValue = useZohoChat('https://salesiq.zoho.com/widget', ZOHO_WIDGET_CODE!);
  const [zohoContext, setZohoContext] = useState<ZohoValueType>(zohoValue);

  useEffect(() => {
    // Set zoho widget context
    setZohoContext(zohoValue);
    // The Zoho chat widget is currently hidden due to user session loading. unhiding it when the user session has loaded in app.tsx file.
    zohoValue?.hideFloatingButton();
    if (zohoValue && zohoValue.isReady && !user) {
      zohoContext.closeChatWindow();
    }
  }, [zohoValue]);

  return <ZohoContext.Provider value={zohoContext}>{props?.children} </ZohoContext.Provider>;
};

export default ZohoContextProvider;
