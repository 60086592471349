import React from 'react';
//Icon
const IconDownload = (props: {width?: string; height?: string}) => {
  return (
    <svg
      width={props.width || '20px'}
      height={props.height || '20px'}
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>{'download'}</title>
      <g id="download" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <path
          d="M41.1583333,75.5083333 C43.5027679,77.8542709 46.6834058,79.1723314 50,79.1723314 C53.3165942,79.1723314 56.4972321,77.8542709 58.8416667,75.5083333 L72.2208333,62.1291667 C73.7107306,60.4813636 73.6458445,57.9544585 72.0733416,56.385292 C70.5008388,54.8161254 67.9738017,54.7566064 66.3291667,56.25 L54.1375,68.4458333 L54.1666667,4.16666667 C54.1666667,1.86548021 52.3011865,0 50,0 L50,0 C47.6988135,0 45.8333333,1.86548021 45.8333333,4.16666667 L45.7958333,68.3666667 L33.6708333,56.25 C32.0427439,54.6230612 29.4040222,54.6239939 27.7770833,56.2520833 C26.1501445,57.8801728 26.1510772,60.5188945 27.7791667,62.1458333 L41.1583333,75.5083333 Z"
          id="Path"
          className="fill-primary"
        />
        <path
          d="M95.8333333,66.6666667 L95.8333333,66.6666667 C93.5321469,66.6666667 91.6666667,68.5321469 91.6666667,70.8333333 L91.6666667,87.5 C91.6666667,89.8011865 89.8011865,91.6666667 87.5,91.6666667 L12.5,91.6666667 C10.1988135,91.6666667 8.33333333,89.8011865 8.33333333,87.5 L8.33333333,70.8333333 C8.33333333,68.5321469 6.46785312,66.6666667 4.16666667,66.6666667 L4.16666667,66.6666667 C1.86548021,66.6666667 0,68.5321469 0,70.8333333 L0,87.5 C0,94.4035594 5.59644063,100 12.5,100 L87.5,100 C94.4035594,100 100,94.4035594 100,87.5 L100,70.8333333 C100,68.5321469 98.1345198,66.6666667 95.8333333,66.6666667 Z"
          id="Path"
          className="fill-primary"
        />
      </g>
    </svg>
  );
};

export default IconDownload;
