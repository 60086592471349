import {IActionResults, IActionResultsList} from '../model/ActionResults';
import {IPackageType} from '../model/sales-order/PackageType';
import useHttp from '../hooks/useHttp';

export const usePackageTypeAPI = () => {
  const {GET, POST, DELETE, isLoading} = useHttp();

  const createPackageType = (payload: Partial<IPackageType>): Promise<IActionResults<IPackageType>> => {
    return POST(`PackageType`, {Value: payload}).then((data: IActionResults<IPackageType>) => data);
  };

  const listPackageType = (): Promise<IPackageType[]> => {
    return GET(`PackageType/GetAll`).then((data: IActionResultsList<IPackageType>) => data?.Items || []);
  };

  const getPackageTypeById = (ID: any): Promise<IPackageType> => {
    return GET(`PackageType/${ID}`).then((data: IActionResults<IPackageType>) => data.Value);
  };

  const updatePackageType = (payload: Partial<IPackageType>): Promise<IActionResults<IPackageType>> => {
    return POST(`PackageType`, {Value: payload}).then((data: IActionResults<IPackageType>) => data);
  };

  const deletePackageType = (ID: any): Promise<IPackageType> => {
    return DELETE(`PackageType/${ID}`).then((data: IActionResults<IPackageType>) => data.Value);
  };

  return {
    getPackageTypeById,
    updatePackageType,
    createPackageType,
    listPackageType,
    deletePackageType,
    isLoading,
  };
};
