import * as React from 'react';
const ARExpandYellowIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg {...props}>
    <path
      fill="#C68A19"
      fillRule="evenodd"
      d="M15.411 1.388c.497.492.697 1.21.528 1.888l-.62 3.998a.48.48 0 0 1-.474.406h-.387a.48.48 0 0 1-.474-.554l.624-3.993L4.59 13.148h1.837c.937 0 1.03.263 1.182.465.09.119.2.586.335 1.4a.16.16 0 0 1-.242.162l-.804-.502a.667.667 0 0 0-.471-.195H4.315a1.977 1.977 0 0 1-.857-.2l-.022-.012a2 2 0 0 1-.908-.904l-.017-.017a2.088 2.088 0 0 1-.195-.861v-2.112a.667.667 0 0 0-.195-.472L.976 8.755a3.33 3.33 0 0 1 1.902-5.654L13.544.854c.673-.16 1.38.042 1.867.534Zm-2.68 8.387 1.334 1.333-3.882 3.879a.167.167 0 0 1-.125.053h-1.08a.176.176 0 0 1-.178-.178v-1.08c0-.05.018-.093.053-.128l3.879-3.879Zm.934-7.585L3.105 4.414a1.985 1.985 0 0 0-1.667 1.35 1.96 1.96 0 0 0 .444 2.012l1.183 1.182c.375.375.585.884.583 1.414v1.836L13.665 2.19Zm.098 6.554a.354.354 0 0 1 .501 0l.832.832a.354.354 0 0 1 0 .501l-.65.65-1.334-1.332Z"
    />
  </svg>
);
export default ARExpandYellowIcon;
