import {Box} from '@material-ui/core';
import {css} from 'glamor';
import React, {useContext} from 'react';
import MockARBackground from '../../assets/jpg/HomeBlurBgg.jpg';
import AppContext from '../../context/app/appContext';
import {PaymentWidgetScope} from '../../model/constants/Constants';
import {PaymentWidgetModal} from '../pay-by-link/payment-widget/PaymentWidget';
import {usePaymentMethods} from '../pay-by-link/payment-widget/usePaymentMethods';
import {usePaymentsFromBuyer} from '../pay-by-link/payment-widget/usePaymentsFromBuyer';
import {useFeatureFlags} from '../../hooks/useFeatureFlags';
import {usePaymentsFromAP} from '../pay-by-link/payment-widget/usePaymentsFromAP';
import {IActionResults} from '../../model/ActionResults';
import {ISupplierPaymentResponse} from '../../model/payment/SupplierPaymentResponse';

const backdropFilterCSS = css({
  '@supports ((backdrop-filter: blur(0px))) or (-webkit-backdrop-filter: blur(0px))': {
    backdropFilter: 'blur(0px)',
    '& -webkit-backdrop-filter': 'blur(0px)',
  },
});

export const AuthorisePayment = () => {
  // Context
  const {user, marketplaceSupplier} = useContext(AppContext);

  // Custom hooks
  const {
    getPaymentMethodAuthorisationDetails,
    approvePaymentAuthorisation,
    rejectPaymentAuthorisation,
    resetAuthenticationCode,
    getPaymentMethodAuthorisationRules,
  } = usePaymentMethods();
  const {onboardingUpgradeV1} = useFeatureFlags().tenantOwned();
  const {ARcheckBatchPaymentStatus, fetchRepaymentStatus} = usePaymentsFromBuyer({
    linkedSupplierId: marketplaceSupplier?.SupplierID,
  });
  const {checkBatchPaymentStatus, getPaymentBatch} = usePaymentsFromAP({});

  const handleCheckPaymentStatus = async (
    refID: number,
    supplierID?: number
  ): Promise<IActionResults<ISupplierPaymentResponse>> => {
    //for AP we just have refID (batchID)
    if (!supplierID && refID) {
      const batchDetails = await getPaymentBatch(refID);
      return checkBatchPaymentStatus(batchDetails?.debits?.[0]?.transactionGUID);
    } else {
      return ARcheckBatchPaymentStatus(refID, supplierID);
    }
  };
  return (
    <>
      <Box>
        <img alt={'background'} src={MockARBackground} className="absolute right-0 left-0 bottom-0 w-full h-full" />
      </Box>
      <Box className={`h-full z-10 overflow-y-auto ${backdropFilterCSS}`}>
        <PaymentWidgetModal
          userID={user?.UserID}
          tenantName={user?.TenantName}
          open={true}
          onClose={() => {}}
          widgetScope={PaymentWidgetScope.AUHTORISE_PAYMENT}
          getPaymentMethodAuthorisationDetails={getPaymentMethodAuthorisationDetails}
          rejectPaymentAuthorisation={rejectPaymentAuthorisation}
          approvePaymentAuthorisation={approvePaymentAuthorisation}
          resetAuthenticationCode={resetAuthenticationCode}
          getPaymentMethodAuthorisationRules={getPaymentMethodAuthorisationRules}
          checkPaymentStatus={handleCheckPaymentStatus}
          isAPOnboardingV1={onboardingUpgradeV1}
          checkRepaymentStatus={fetchRepaymentStatus}
        />
      </Box>
    </>
  );
};
