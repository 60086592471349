import React, {Dispatch, SetStateAction, useCallback, useEffect, useState} from 'react';
import {Typography, Menu, MenuHandler, IconButton, MenuList, SearchInput, Button, Checkbox} from 'spenda-ui-react';
import {IconSort} from '../../assets/svg';
import {camelCase, debounce} from 'lodash';

interface IQuoteDashboardHeader {
  handleCreateQuote: () => void;
  setSearchString: Dispatch<SetStateAction<string>>;
  sorting?: string;
  setSorting: Dispatch<SetStateAction<string>>;
  columnOptions?: string[];
  setColumnOptions: Dispatch<SetStateAction<string[]>>;
}

const menuItems = [
  'Customer ref',
  'Customer',
  'Company',
  'Variants',
  'Created date',
  'Created by',
  'Assigned to',
  'Quote status',
  'Expiry date',
  'Est. cost (inc)',
  'Est. cost (ex)',
];

const QuoteDashboardHeader = (props: IQuoteDashboardHeader) => {
  const {handleCreateQuote, setSearchString, sorting, setSorting, columnOptions, setColumnOptions} = props;
  const [openMenu, setOpenMenu] = useState(false);
  const [query, setQuery] = useState('');
  const [selectedMenuItems, setSelectedMenuItems] = useState<Set<string>>(new Set(columnOptions?.map(val => val)));

  const handleColumnOptions = (event: React.ChangeEvent<HTMLInputElement>, key: string) => {
    const selectedItemsSet = new Set(selectedMenuItems);
    if (selectedItemsSet?.has(key)) {
      selectedItemsSet.delete(key);
    } else {
      selectedItemsSet.add(key);
    }
    setSelectedMenuItems(selectedItemsSet);
    setColumnOptions(Array.from(selectedItemsSet));
  };

  useEffect(() => {
    if (
      !openMenu &&
      columnOptions?.sort().join(',') !== (selectedMenuItems && Array.from(selectedMenuItems).sort().join(','))
    ) {
      setColumnOptions(Array.from(selectedMenuItems));
    }
  }, [openMenu, selectedMenuItems]);

  const debouncedSearch = useCallback(
    debounce((value: string) => {
      setSearchString(value);
    }, 750),
    [],
  );

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
    debouncedSearch(e.target.value);
  };

  return (
    <div className="flex w-full items-center justify-center">
      <Typography className="whitespace-nowrap text-xl font-light text-black-800">All Quotes</Typography>
      <div className="flex w-full justify-end gap-x-2">
        <div className="w-52">
          <SearchInput
            reverse={true}
            clearIcon
            clearIconProps={{
              onClick: () => {
                setQuery('');
                setSearchString('');
              },
            }}
            iconButtonProps={{
              name: 'Search',
            }}
            data-autoid="txtSearchInput"
            autoFocus
            placeholder="Search..."
            onChange={onSearchChange}
            value={query}
          />
        </div>
        <Menu
          placement="bottom-end"
          dismiss={{
            itemPress: false,
          }}
          open={openMenu}
          handler={setOpenMenu}
        >
          <MenuHandler>
            <Button variant="outlined">Column Options</Button>
          </MenuHandler>
          <MenuList className="flex flex-col border border-primary p-0">
            {menuItems.map((item, index) => {
              return (
                <div
                  key={index}
                  className="flex items-center gap-x-2 px-2.5 py-0 outline-none first:pt-1 last:pb-1 hover:!bg-primary/10 active:!bg-primary/10"
                >
                  <Checkbox
                    id={camelCase(item)}
                    ripple={false}
                    color="primary"
                    checked={selectedMenuItems && selectedMenuItems.has(item)}
                    onChange={e => handleColumnOptions(e, item)}
                    containerProps={{className: 'p-0'}}
                    className="border-primary bg-white before:h-5 before:w-5 checked:border-none checked:bg-primary hover:before:opacity-0"
                    label={item}
                    labelProps={{className: 'px-3 py-2 text-black-800 font-semibold text-base'}}
                  />
                </div>
              );
            })}
          </MenuList>
        </Menu>
        <Menu
          placement="bottom-start"
          dismiss={{
            itemPress: true,
          }}
        >
          <MenuHandler>
            <IconButton variant="outlined" name="Sort">
              <IconSort />
            </IconButton>
          </MenuHandler>
          <MenuList className="flex flex-col border border-primary p-0">
            <button
              data-autoid="btnSortByModifiedDate"
              className={`min-w-[180px] rounded-none border-b border-[#ECECEC] px-3 py-2.5 text-base font-semibold text-black-900 hover:!bg-primary/10 ${
                sorting === 'ModifiedDate' && '!bg-primary/10'
              } text-left hover:text-black-900`}
              onClick={() => setSorting('ModifiedDate')}
            >
              Latest
            </button>
            <button
              data-autoid="btnSortByCustomerName"
              className={`min-w-[180px] rounded-none border-b border-[#ECECEC] px-3 py-2.5 text-base font-semibold text-black-900 hover:!bg-primary/10 ${
                sorting === 'CustomerName' && '!bg-primary/10'
              } text-left hover:text-black-900`}
              onClick={() => setSorting('CustomerName')}
            >
              A - Z Customer name
            </button>
            <button
              data-autoid="btnSortByCreationDate"
              className={`min-w-[180px] rounded-none px-3 py-2.5 text-base font-semibold text-black-900 hover:!bg-primary/10 ${
                sorting === 'CreatedDate' && '!bg-primary/10'
              } text-left hover:text-black-900`}
              onClick={() => setSorting('CreatedDate')}
            >
              Creation date
            </button>
            <button
              data-autoid="btnSortByExpiryDate"
              className={`min-w-[180px] rounded-none px-3 py-2.5 text-base font-semibold text-black-900 hover:!bg-primary/10 ${
                sorting === 'expiryDate' && '!bg-primary/10'
              } text-left hover:text-black-900`}
              onClick={() => setSorting('expiryDate')}
            >
              Expiry date/interval
            </button>
          </MenuList>
        </Menu>

        <Button onClick={handleCreateQuote}>Create Quote</Button>
      </div>
    </div>
  );
};

export default QuoteDashboardHeader;
