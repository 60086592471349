import React from 'react';
export const IconProductManagement = (props: {width?: string; height?: string}) => {
  return (
    <svg
      width={props.width || '50px'}
      height={props.height || '50px'}
      viewBox="0 0 50 50"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xlinkHref="http://www.w3.org/1999/xlink"
    >
      <title>Sell</title>
      <g id="Spenda-Onboarding-Continue" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Label-Concept" transform="translate(-55.000000, -445.000000)" fillRule="nonzero">
          <g id="Group" transform="translate(55.000000, 445.000000)">
            <g id="Group-16" transform="translate(0.100000, 0.100000)">
              <polygon
                id="Path"
                fill="#FFFFFF"
                points="0.778125 13.2278949 14.784375 13.2278949 14.784375 49.0210223 0.778125 49.0210223"
              ></polygon>
              <polygon
                id="Path"
                fill="#FFFFFF"
                points="14.784375 13.2278949 0.778125 13.2278949 5.446875 5.44678026 19.453125 5.44678026"
              ></polygon>
              <polygon
                id="Path"
                fill="#F1F1F1"
                points="24.1542645 49.0210223 14.784375 49.0210223 14.784375 13.2278949 19.4693684 5.44678026 24.1542645 13.2278949"
              ></polygon>
              <polygon
                id="Path"
                fill="#ECECEC"
                points="19.4693684 49.0210223 14.784375 49.0210223 14.784375 13.2278949 19.4693684 5.44678026"
              ></polygon>
              <polygon
                id="Path"
                fill="#74B9D3"
                points="0.778125 21.0090096 14.784375 21.0090096 14.784375 41.2399077 0.778125 41.2399077"
              ></polygon>
              <polygon
                id="Path"
                fill="#F1F1F1"
                points="30.346875 0.778111465 39.684375 0.778111465 39.684375 7.00300319 30.346875 7.00300319"
              ></polygon>
              <path
                d="M27.234375,33.458793 L27.234375,19.4527866 L27.3909727,17.8965637 C27.772513,16.0313691 28.8240517,14.3705274 30.346875,13.2278949 C30.346875,13.2278949 31.903125,11.671672 29.5882031,9.73611971 C28.5809203,8.89391131 28.5415277,6.99813999 30.2330742,7.00297517 L39.7981758,7.00297517 C41.4272777,6.99463849 41.4894305,8.86123063 40.4430469,9.73611971 C38.128125,11.671672 39.684375,13.2278949 39.684375,13.2278949 C41.643546,14.6975537 42.7966301,17.0036818 42.796875,19.4527866 L42.796875,33.458793 L27.234375,33.458793 Z"
                id="Path"
                fill="#7FC0A6"
              ></path>
              <path
                d="M27.234375,27.8782748 L27.234375,33.458793 L42.796875,33.458793 L42.796875,19.4527866 C42.796875,18.8363374 42.723452,18.222082 42.5783191,17.6229603 C39.4107886,23.3102258 33.7007733,27.1265851 27.234375,27.8782748 L27.234375,27.8782748 Z"
                id="Path"
                fill="#3C9F78"
              ></path>
              <polygon
                id="Path"
                fill="#F1F1F1"
                points="5.446875 0.778111465 19.453125 0.778111465 19.453125 5.44678026 5.446875 5.44678026"
              ></polygon>
              <path
                d="M35.6439609,28.7038511 C32.6571281,29.5111417 31.6954629,25.5127187 33.9741047,24.3667551 L32.68125,21.0090096 L27.234375,21.0090096 L27.234375,49.0210223 C31.903125,49.0210223 38.7436219,48.444928 42.01875,45.2606041 L35.6439609,28.7038511 Z"
                id="Path"
                fill="#FEC359"
              ></path>
              <path
                d="M27.234375,21.0090096 L24.5244574,28.0473196 C27.983223,28.616119 25.919441,34.2635548 22.878723,32.3217776 L20.3644066,38.8518836 C21.6635836,40.190138 20.788582,42.6720218 18.8485219,42.7890303 L17.896875,45.2606041 C23.0538012,50.2744626 31.4149488,50.2744626 36.571875,45.2606041 L27.234375,21.0090096 Z"
                id="Path"
                fill="#E1C085"
              ></path>
              <path
                d="M18.3770754,45.7043222 C23.5604578,50.2696967 31.5724219,50.1217582 36.571875,45.2606041 L32.8030266,35.4721564 C29.9105547,40.9963026 24.5469234,44.8006663 18.3770754,45.7043222 Z"
                id="Path"
                fill="#D2A656"
              ></path>
              <ellipse
                id="Oval"
                fill="#D2A656"
                opacity="0.4916004"
                cx="30.346875"
                cy="42.0180191"
                rx="1.55625"
                ry="1.55622293"
              ></ellipse>
              <ellipse
                id="Oval"
                fill="#D2A656"
                opacity="0.4916004"
                cx="28.0125"
                cy="36.5712389"
                rx="1.55625"
                ry="1.55622293"
              ></ellipse>
              <ellipse
                id="Oval"
                fill="#D2A656"
                opacity="0.4916004"
                cx="24.121875"
                cy="44.3523535"
                rx="1.55625"
                ry="1.55622293"
              ></ellipse>
              <g id="Group" transform="translate(22.565419, 35.014013)" opacity="0.4916004">
                <path
                  d="M8.55958079,7.00400651 C8.82726854,7.00387956 9.09041271,7.07318326 9.32331047,7.20514832 C9.33239329,7.13847661 9.33720154,7.07129214 9.33770602,7.00400651 C9.33812263,6.24658786 8.79315672,5.59886721 8.04681707,5.46971737 C7.30047742,5.34056753 6.56954229,5.76750075 6.31541352,6.48101599 C6.06128476,7.19453123 6.35777539,7.98738425 7.0177261,8.35908762 C7.11841637,7.58427653 7.77824123,7.00437999 8.55958079,7.00400651 L8.55958079,7.00400651 Z"
                  id="Path"
                  fill="#E1C085"
                ></path>
                <path
                  d="M6.22520579,1.55722625 C6.49289354,1.5570993 6.75603771,1.62640301 6.98893547,1.75836806 C6.99801829,1.69169636 7.00282654,1.62451189 7.00333102,1.55722625 C7.00374763,0.799807602 6.45878172,0.152086955 5.71244207,0.0229371158 C4.96610242,-0.106212724 4.23516729,0.320720493 3.98103852,1.03423573 C3.72690976,1.74775097 4.02340039,2.54060399 4.6833511,2.91230737 C4.78404137,2.13749628 5.44386623,1.55759973 6.22520579,1.55722625 L6.22520579,1.55722625 Z"
                  id="Path"
                  fill="#E1C085"
                ></path>
                <path
                  d="M2.33458079,9.3383409 C2.60226854,9.33821396 2.86541271,9.40751766 3.09831047,9.53948271 C3.10739329,9.47281101 3.11220154,9.40562654 3.11270602,9.3383409 C3.11312263,8.58092225 2.56815672,7.93320161 1.82181707,7.80405177 C1.07547742,7.67490193 0.344542289,8.10183514 0.0904135246,8.81535039 C-0.16371524,9.52886563 0.132775392,10.3217186 0.792726098,10.693422 C0.893416374,9.91861093 1.55324123,9.33871439 2.33458079,9.3383409 L2.33458079,9.3383409 Z"
                  id="Path"
                  fill="#D2A656"
                ></path>
              </g>
              <path
                d="M39.684375,7.00300319 L39.684375,0.778111465 L37.3136227,0.778111465 C37.0862892,3.28176711 35.7686665,5.55852021 33.7110984,7.00300319 L39.684375,7.00300319 Z"
                id="Path"
                fill="#ECECEC"
              ></path>
              <ellipse
                id="Oval"
                fill="#FFFFFF"
                opacity="0.400778634"
                cx="27.234375"
                cy="27.2339013"
                rx="1.55625"
                ry="1.55622293"
              ></ellipse>
              <ellipse
                id="Oval"
                fill="#FFFFFF"
                opacity="0.400778634"
                cx="28.790625"
                cy="30.3463471"
                rx="1"
                ry="1"
              ></ellipse>
              <ellipse
                id="Oval"
                fill="#ACD7C6"
                cx="34.6265625"
                cy="14.3950621"
                rx="1.9453125"
                ry="1.94527866"
              ></ellipse>
              <ellipse id="Oval" fill="#ACD7C6" cx="37.7390625" cy="17.507508" rx="1.1671875" ry="1.1671672"></ellipse>
              <ellipse id="Oval" fill="#FFFFFF" cx="6.225" cy="9.33733758" rx="1.55625" ry="1.55622293"></ellipse>
              <ellipse id="Oval" fill="#FFFFFF" cx="8.559375" cy="11.671672" rx="1" ry="1"></ellipse>
              <path
                d="M0.778125,36.0474726 L0.778125,41.2399077 L14.784375,41.2399077 L14.784375,25.1898997 C11.9415337,30.6943566 6.81754163,34.6664489 0.778125,36.0474726 Z"
                id="Path"
                fill="#2C95BC"
              ></path>
              <polygon
                id="Path"
                fill="#74B9D3"
                points="33.459375 31.9025701 35.015625 49.0210223 45.909375 49.0210223 47.465625 31.9025701"
              ></polygon>
              <ellipse id="Oval" fill="#B8DAE8" cx="36.571875" cy="35.7931274" rx="1.55625" ry="1.55622293"></ellipse>
              <ellipse id="Oval" fill="#B8DAE8" cx="38.90625" cy="38.1274618" rx="1" ry="1"></ellipse>
              <path
                d="M35.015625,49.0210223 L45.909375,49.0210223 L46.9702512,37.3518792 C44.1706567,41.5352361 39.6349812,44.2343494 34.6227691,44.699683 L35.015625,49.0210223 Z"
                id="Path"
                fill="#2C95BC"
              ></path>
              <path
                d="M11.671875,32.6806815 C11.671875,34.829377 9.92998285,36.5712389 7.78125,36.5712389 C5.63251715,36.5712389 3.890625,34.829377 3.890625,32.6806815 C3.890625,30.532024 7.78125,25.6776784 7.78125,25.6776784 C7.78125,25.6776784 11.671875,30.532024 11.671875,32.6806815 Z"
                id="Path"
                fill="#FFFFFF"
              ></path>
              <path
                d="M11.4534164,31.6083467 C10.0670895,33.8933683 7.35221133,35.2705283 4.63217812,34.9639524 C5.6218014,36.3290034 7.37813772,36.9011713 8.98194115,36.3809902 C10.5857446,35.860809 11.671875,34.3667078 11.671875,32.6806815 C11.6572643,32.3138065 11.583495,31.9516991 11.4534164,31.6083467 Z"
                id="Path"
                fill="#ECECEC"
              ></path>
              <path
                d="M30.346875,41.2399077 C30.7766216,41.2399077 31.125,40.8915353 31.125,40.4617962 C31.125,38.9731717 28.0125,39.7044019 28.0125,42.0180191 C28.0125,44.3289129 31.125,45.0677297 31.125,43.574242 C31.125,43.144503 30.7766216,42.7961306 30.346875,42.7961306 C29.9171284,42.7961306 29.56875,42.4477582 29.56875,42.0180191 C29.56875,41.58828 29.9171284,41.2399077 30.346875,41.2399077 Z"
                id="Path"
                fill="#333333"
              ></path>
              <path
                d="M30.346875,36.5712389 C30.346875,35.6270887 29.7781228,34.7759057 28.9058266,34.4145951 C28.0335305,34.0532845 27.0294742,34.2530002 26.3618476,34.9206152 C25.694221,35.5882301 25.4945018,36.592269 25.8558187,37.46455 C26.2171356,38.3368309 27.0683334,38.9055733 28.0125,38.9055733 C29.3011398,38.904126 30.3454277,37.8598562 30.346875,36.5712389 L30.346875,36.5712389 Z M27.234375,36.5712389 C27.234375,36.1414998 27.5827534,35.7931274 28.0125,35.7931274 C28.4422466,35.7931274 28.790625,36.1414998 28.790625,36.5712389 C28.790625,37.000978 28.4422466,37.3493503 28.0125,37.3493503 C27.5829534,37.3488679 27.2348574,37.000778 27.234375,36.5712389 L27.234375,36.5712389 Z"
                id="Shape"
                fill="#333333"
              ></path>
              <path
                d="M24.121875,42.0180191 C22.8326353,42.0180191 21.7875,43.0631362 21.7875,44.3523535 C21.7875,45.6415708 22.8326353,46.6866879 24.121875,46.6866879 C25.4111147,46.6866879 26.45625,45.6415708 26.45625,44.3523535 C26.4548027,43.0637362 25.4105148,42.0194664 24.121875,42.0180191 L24.121875,42.0180191 Z M24.121875,45.130465 C23.6921284,45.130465 23.34375,44.7820926 23.34375,44.3523535 C23.34375,43.9226144 23.6921284,43.574242 24.121875,43.574242 C24.5516216,43.574242 24.9,43.9226144 24.9,44.3523535 C24.8995176,44.7818926 24.5514216,45.1299826 24.121875,45.130465 L24.121875,45.130465 Z"
                id="Shape"
                fill="#333333"
              ></path>
              <path
                d="M31.3299387,47.5717897 C27.0124148,49.0314295 22.1569148,48.0386566 18.8118527,45.0498332 L19.4126625,43.4888443 C21.4904508,42.9899776 22.4163223,40.4332979 21.2516637,38.7131852 L23.3068863,33.3753405 C26.6669273,34.4133412 28.6662223,29.0338676 25.5482754,27.5534133 L27.234375,23.1744938 L29.5234242,29.1192654 C29.8935199,30.0805248 31.3455984,29.5203818 30.9757945,28.560095 L28.3678113,21.787121 L32.1470672,21.787121 L33.0237223,24.0642642 C30.6813715,26.0225763 32.3093062,30.4110277 35.846857,29.4550205 C36.1171883,29.3842569 36.3288166,29.1739739 36.4012991,28.9041028 C36.4737816,28.6342317 36.395987,28.3462176 36.197486,28.1495384 C35.998985,27.9528593 35.7102622,27.8777199 35.4410648,27.9526817 C33.3826324,28.5086424 32.8601215,25.7978966 34.3237746,25.0619004 C34.6812493,24.8821134 34.8440915,24.460523 34.7002898,24.0871213 L33.4074352,20.7293758 C33.2917661,20.4290219 33.0031117,20.2308798 32.68125,20.2308981 C27.0894492,20.2339133 26.770418,20.0481392 26.5081898,20.7293758 C23.8248258,27.9824445 23.519898,27.8588221 23.8493367,28.4336519 C23.9656418,28.6368019 24.1672266,28.7767978 24.3982066,28.8148293 C26.0814855,29.0917397 25.6667449,31.1016988 24.7637309,31.738194 C24.3370266,32.0390314 23.8437926,32.0146181 23.2975488,31.6659269 C23.100673,31.540233 22.8577451,31.5096281 22.6358445,31.5825632 C22.4139439,31.6554982 22.2365459,31.8242573 22.1526352,32.0422411 L19.6383187,38.5725416 C19.5292967,38.8557113 19.5948303,39.1764255 19.8061992,39.39413 C20.6421,40.2550131 20.0795156,41.9351502 18.800959,42.0126696 C18.4967496,42.0311149 18.231313,42.2254558 18.1218504,42.5098828 L17.1706898,44.9812621 C17.0587937,45.2719912 17.1310821,45.6014355 17.3544246,45.8186073 C21.235323,49.5924479 26.8806199,50.7189588 31.828425,49.0462137 C32.2355293,48.9085629 32.4539626,48.4669573 32.3163094,48.0598601 C32.1786562,47.6527629 31.737043,47.4343334 31.3299387,47.5719842 L31.3299387,47.5717897 Z"
                id="Path"
                fill="#333333"
              ></path>
              <path
                d="M49.021875,31.1244586 C48.2938418,31.1244586 33.9440496,31.1244586 31.903125,31.1244586 C31.4733784,31.1244586 31.125,31.472831 31.125,31.9025701 C31.125,32.3323092 31.4733784,32.6806815 31.903125,32.6806815 L32.7493359,32.6806815 L34.2411961,49.0914414 C34.2776011,49.4920503 34.6133585,49.7988737 35.015625,49.7991338 L45.909375,49.7991338 C46.3118281,49.7991338 46.6478682,49.492236 46.6842902,49.0914414 L47.2503762,42.8597412 C47.2794738,42.5807844 47.1561918,42.3077546 46.9276948,42.1451062 C46.6991978,41.9824579 46.4008488,41.9553629 46.1467886,42.0741873 C45.8927284,42.1930116 45.7222763,42.4393656 45.700643,42.7190003 L45.1986551,48.2429108 L35.7262477,48.2429108 L34.3114219,32.6806815 L46.6136754,32.6806815 L46.2646863,36.5212452 C46.2287588,36.9474878 46.5436341,37.3228043 46.9696401,37.3615168 C47.3956461,37.4002294 47.7730195,37.0878198 47.8145168,36.6620834 L48.1762477,32.6806815 L49.021875,32.6806815 C49.4516216,32.6806815 49.8,32.3323092 49.8,31.9025701 C49.8,31.472831 49.4516216,31.1244586 49.021875,31.1244586 L49.021875,31.1244586 Z"
                id="Path"
                fill="#333333"
              ></path>
              <path
                d="M27.2349586,18.6588211 C27.4371384,18.7002122 27.6474802,18.6595931 27.8197106,18.5458997 C27.991941,18.4322062 28.1119514,18.2547519 28.1533406,18.052575 C28.4961119,16.3735214 29.4426559,14.8784286 30.81375,13.8503841 C30.8432145,13.8283027 30.8710704,13.8041526 30.8971066,13.778117 C31.9302621,12.7443959 32.0822883,10.8071901 30.0873703,9.13921095 C29.7241805,8.83555295 29.5930664,8.32219391 29.6983078,8.03273645 C29.7234996,7.96358179 29.7876949,7.77985022 30.2332688,7.78111465 C39.6566543,7.96046934 40.129268,7.50498235 40.327009,8.04508897 C40.431375,8.33016955 40.314559,8.82942532 39.9440742,9.13921095 C38.9595516,9.96235562 38.4390832,10.8562112 38.3975508,11.7956835 C38.3560184,12.7252349 38.8617023,13.5832001 39.2177918,13.8502868 C40.9768137,15.1765194 42.0133728,17.2498535 42.01875,19.4527866 C42.01875,19.8825257 42.3671284,20.2308981 42.796875,20.2308981 C43.2266216,20.2308981 43.575,19.8825257 43.575,19.4527866 C43.5684291,16.7885955 42.3277721,14.2777985 40.2155426,12.6540377 C39.5797172,11.8656163 40.168466,10.9798336 40.9422141,10.3329312 C42.3506203,9.15545403 42.2682363,6.79972157 40.4625,6.3088305 L40.4625,0.778111465 C40.4625,0.348372369 40.1141216,0 39.684375,0 L30.346875,0 C29.9171284,0 29.56875,0.348372369 29.56875,0.778111465 L29.56875,6.30824691 C27.7446305,6.79456658 27.6780035,9.15321696 29.0890359,10.3330285 C29.8730941,10.9884901 30.4468641,11.8708685 29.8158047,12.6540377 C28.1740515,13.9092406 27.041957,15.7161106 26.6286047,17.7409414 C26.5427179,18.1618163 26.8141303,18.572672 27.2349586,18.6588211 L27.2349586,18.6588211 Z M31.125,1.55622293 L38.90625,1.55622293 L38.90625,3.11244586 L33.459375,3.11244586 C33.0296284,3.11244586 32.68125,3.46081823 32.68125,3.89055733 C32.68125,4.32029642 33.0296284,4.66866879 33.459375,4.66866879 L38.90625,4.66866879 L38.90625,6.22489172 L31.125,6.22489172 L31.125,1.55622293 Z"
                id="Shape"
                fill="#333333"
              ></path>
              <path
                d="M8.559375,36.5712389 C8.559375,36.1414998 8.21099657,35.7931274 7.78125,35.7931274 C6.06306366,35.7911977 4.67067971,34.398838 4.66875,32.6806815 C4.66875,31.4964932 6.3871418,28.8118141 7.78125,26.9495015 C9.1753582,28.811036 10.89375,31.4951315 10.89375,32.6806815 C10.89375,33.1104206 11.2421284,33.458793 11.671875,33.458793 C12.1016216,33.458793 12.45,33.1104206 12.45,32.6806815 C12.45,30.3268944 8.80419258,25.7097754 8.3884793,25.1913587 C8.24080181,25.0070637 8.01741615,24.8998095 7.78125,24.8998095 C7.54508385,24.8998095 7.32169819,25.0070637 7.1740207,25.1913587 C6.75830742,25.7097754 3.1125,30.3268944 3.1125,32.6806815 C3.1125,36.9707991 8.559375,38.4496972 8.559375,36.5712389 Z"
                id="Path"
                fill="#333333"
              ></path>
              <path
                d="M17.896875,48.2429108 L15.5625,48.2429108 L15.5625,13.4434318 L19.4628516,6.94376945 L23.3766258,13.4441126 L23.3766258,21.0090096 C23.3766258,21.4387486 23.7250042,21.787121 24.1547508,21.787121 C24.5844973,21.787121 24.9328848,21.4387486 24.9328848,21.0090096 L24.9328848,13.2278949 C24.9328848,12.8318362 24.970323,13.1491111 20.2317363,5.20362042 L20.2317363,0.778111465 C20.2317363,0.348372369 19.8833579,0 19.4536113,0 L5.446875,0 C5.01712843,0 4.66875,0.348372369 4.66875,0.778111465 L4.66875,5.23134064 L4.04625,6.26914681 C3.89970327,6.50768402 3.89259688,6.80655651 4.02764085,7.05178897 C4.16268482,7.29702143 4.41905925,7.45080603 4.69899549,7.45449688 C4.97893174,7.45818773 5.23927166,7.3112158 5.38073438,7.06962898 L5.88748828,6.22489172 L18.078859,6.22489172 L14.343859,12.4497834 L11.671875,12.4497834 C11.2421284,12.4497834 10.89375,12.7981558 10.89375,13.2278949 C10.89375,13.657634 11.2421284,14.0060064 11.671875,14.0060064 L14.00625,14.0060064 L14.00625,20.2308981 L1.55625,20.2308981 L1.55625,14.0060064 L8.559375,14.0060064 C8.98912157,14.0060064 9.3375,13.657634 9.3375,13.2278949 C9.3375,12.7981558 8.98912157,12.4497834 8.559375,12.4497834 L2.15229375,12.4497834 L2.17875,12.4055284 C2.39427481,12.03746 2.27331231,11.5644906 1.90754016,11.3450844 C1.54176802,11.1256783 1.06753615,11.241624 0.844265625,11.6050462 C0.0724628906,12.8930152 0,12.9407718 0,13.2278949 L0,49.0210223 C0,49.4507614 0.348378429,49.7991338 0.778125,49.7991338 L17.896875,49.7991338 C18.3266216,49.7991338 18.675,49.4507614 18.675,49.0210223 C18.675,48.5912832 18.3266216,48.2429108 17.896875,48.2429108 L17.896875,48.2429108 Z M6.225,4.66866879 L6.225,1.55622293 L18.675,1.55622293 L18.675,4.66866879 L6.225,4.66866879 Z M14.00625,48.2429108 L1.55625,48.2429108 L1.55625,42.0180191 L14.00625,42.0180191 L14.00625,48.2429108 Z M14.00625,40.4617962 L1.55625,40.4617962 L1.55625,21.787121 L14.00625,21.787121 L14.00625,40.4617962 Z"
                id="Shape"
                fill="#333333"
              ></path>
              <ellipse id="Oval" fill="#333333" cx="3.1125" cy="9.33733758" rx="1" ry="1"></ellipse>
              <ellipse id="Oval" fill="#333333" cx="10.89375" cy="35.7931274" rx="1" ry="1"></ellipse>
              <ellipse id="Oval" fill="#333333" cx="42.796875" cy="22.5652325" rx="1" ry="1"></ellipse>
              <ellipse id="Oval" fill="#333333" cx="46.6875" cy="39.6836847" rx="1" ry="1"></ellipse>
              <path
                d="M42.796875,24.8995669 C42.3671284,24.8995669 42.01875,25.2479393 42.01875,25.6776784 L42.01875,28.7901242 C42.01875,29.2198633 42.3671284,29.5682357 42.796875,29.5682357 C43.2266216,29.5682357 43.575,29.2198633 43.575,28.7901242 L43.575,25.6776784 C43.575,25.2479393 43.2266216,24.8995669 42.796875,24.8995669 Z"
                id="Path"
                fill="#333333"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
