import {useContext} from 'react';
import {MenuConfig} from '../components/menu/MenuConfig';
import {IMenuModule} from '../model/menu/Menu';
import _ from 'lodash';
import AppContext from '../context/app/appContext';

export const useMenu = () => {
  const appCtx = useContext(AppContext);
  
  const formatModulesData = (modules: IMenuModule[]) => {
    const modulesConfig = _.cloneDeep(MenuConfig);
    const updatedConfig: IMenuModule[] = [];
    modulesConfig.map(sm => {
      const module = modules.find(m => m.ModuleID === sm.ModuleID);
      if (module) {
        if (module?.IsActive && module.Status === 'TrialActive') {
          module.Labeldescription = `${module?.TrialRemainingDays?.toString()} Days Left`;
        }

        if (module.IsExpired && module.Status === 'TrialExpired') {
          module.Labeldescription = `Expired`;
        }

        updatedConfig.push({...sm, ...module});
      }
    });

    return updatedConfig;
  };

  const getModule = (ModuleID: number) => {
    return appCtx.modules?.find(module => module.ModuleID === ModuleID);    
  };

  return {
    formatModulesData,
    getModule,
  };
};
