import React, {ReactNode} from 'react';
import {ARDialogTemplate} from '../ARDialogTemplate';
import {Button, Typography} from 'spenda-ui-react';

interface IWarningDialog {
  isOpen: boolean;
  title: string;
  message: string | ReactNode;
  onClose: () => void;
  conrfirmBtn: {
    title: string;
    onClick: () => void;
    isLoading?: boolean;
  };
  cancelBtn: {
    title: string;
    onClick: () => void;
  };
}

export const WarningDialog = (props: IWarningDialog) => {
  // Props
  const {isOpen, conrfirmBtn, cancelBtn, onClose, title, message} = props;

  return (
    <ARDialogTemplate
      dialogProps={{size: 'xs', open: isOpen, className: '!min-w-[460px] !max-w-[460px]'}}
      isFullScreen
      header={
        <p data-autoid={'lblWarningTitle'} className=" font-poppins text-xl font-light text-[#333333]">
          {title || 'Warning'}
        </p>
      }
      body={
        <div className="my-12">
          <Typography className="text-center font-light text-black-800" variant="paragraph">
            {message}
          </Typography>
        </div>
      }
      footer={
        <div className="flex w-full flex-row items-center justify-between">
          <Button
            data-autoid={`btnCancel`}
            variant="outlined"
            color="error"
            className="bg-white"
            onClick={cancelBtn?.onClick}
          >
            {cancelBtn?.title}
          </Button>
          <Button
            loading={conrfirmBtn?.isLoading}
            variant="filled"
            color="primary"
            data-autoid={`btnContinue`}
            onClick={conrfirmBtn?.onClick}
          >
            {conrfirmBtn?.title}
          </Button>
        </div>
      }
      handleClose={() => onClose()}
    />
  );
};
