import React from 'react';
import {Button, IconButton, SearchInput, Typography} from 'spenda-ui-react';

import {BackIcon} from '../../../../assets/svg';
import IconStatusFilter from '../../../../assets/svg/IconStatusFilter';
import {useHistory} from 'react-router-dom';
import {AUTH_INVENTORY_CATALOGUE_ID} from '../../../../routes/InventoryRoutes';

export const ManageSubscribersHeader = () => {
  const history = useHistory();
  const handleBack = () => {
    history.push(AUTH_INVENTORY_CATALOGUE_ID);
  };
  return (
    <header className="flex items-center justify-between border-b border-[#ECECEC] pb-2.5">
      <div className="flex items-center justify-start gap-2.5">
        <div className="cursor-pointer" onClick={handleBack}>
          <IconButton variant="outlined" ripple={false}>
            <BackIcon />
          </IconButton>
        </div>
        <Typography variant="h2" className="font-light">
          Manage Subscribers
        </Typography>
      </div>
      <div className="flex items-center gap-2.5">
        <div>
          <SearchInput
            reverse={true}
            clearIcon
            iconButtonProps={{
              name: 'Search',
            }}
            data-autoid="txtSearchInput"
            autoFocus
            placeholder="Search..."
          />
        </div>
        <IconButton variant="outlined" name="statusFilter" className="min-w-10">
          <IconStatusFilter />
        </IconButton>
        <Button variant="outlined">
          Add Customers
        </Button>
      </div>
    </header>
  );
};
