import React, {useState} from 'react';
import {Accordion, AccordionBody, AccordionHeader, Typography} from 'spenda-ui-react';
import {IconArrowDown} from '../../../assets/svg/IconArrowDown';

export const WidgetPanel = () => {
  // State
  const [expand, setExpand] = useState(1);

  const toggleAccordion = (value: React.SetStateAction<number>) => setExpand(expand === value ? 0 : value);

  return (
    <div className="flex h-full w-full flex-col py-2.5">
      <div className="mb-2.5 flex h-[inherit] w-full flex-col gap-y-2.5 overflow-y-auto px-2.5">
        <Accordion
          className={`w-full gap-y-2.5 bg-white`}
          open={expand === 1}
          icon={
            <div data-autoid={`btnExpandAllOpenChats`}>
              <IconArrowDown
                className={`cursor-pointer ${expand === 1 ? 'rotate-180' : ''}`}
                name={expand === 1 ? 'Minimize' : 'Maximize'}
              />
            </div>
          }
        >
          <AccordionHeader
            className={`w-full cursor-default ${expand === 1 ? 'rounded-t-md' : 'rounded-md'} bg-[#D3E5EF] p-0 pr-4`}
            aria-controls="panel1a-content"
            id="panel1a-header"
            onClick={() => toggleAccordion(1)}
          >
            <div
              className={`box-border flex h-[40px] min-h-[40px] w-full flex-row items-center justify-between rounded-md px-4 py-2`}
            >
              <Typography variant="h3" className={`text-base font-medium text-black-800`}>
                Transaction Totals
              </Typography>
            </div>
          </AccordionHeader>
          <AccordionBody className=" p-0">
            <div className="flex flex-col gap-y-2.5">
              <div className="flex w-full flex-col">
                <div className="flex h-[50px] w-full flex-col items-center justify-center bg-[#ECECEC] text-base font-medium">
                  Visa/Mastercard Credit
                </div>
                <div className="w-full">
                  <table className="w-full border-collapse border border-[#ECECEC]">
                    <tbody>
                      <tr>
                        <td className="border border-[#ECECEC] p-2.5 text-sm font-normal text-black-800">Sales Qty</td>
                        <td
                          width="100px"
                          className="border border-[#ECECEC] bg-[#FAFAFA] p-2.5 text-sm font-normal text-black-800"
                          align="right"
                        >
                          $503.12
                        </td>
                      </tr>
                      <tr>
                        <td className="border border-[#ECECEC] p-2.5 text-sm font-normal text-black-800">Sales $</td>
                        <td
                          width="100px"
                          className="border border-[#ECECEC] bg-[#FAFAFA] p-2.5 text-sm font-normal text-black-800"
                          align="right"
                        >
                          $503.12
                        </td>
                      </tr>
                      <tr>
                        <td className="border border-[#ECECEC] p-2.5 text-sm font-normal text-black-800">
                          Returns Qty
                        </td>
                        <td
                          width="100px"
                          className="border border-[#ECECEC] bg-[#FAFAFA] p-2.5 text-sm font-normal text-black-800"
                          align="right"
                        >
                          $503.12
                        </td>
                      </tr>
                      <tr>
                        <td className="border border-[#ECECEC] p-2.5 text-sm font-normal text-black-800">Returns $</td>
                        <td
                          width="100px"
                          className="border border-[#ECECEC] bg-[#FAFAFA] p-2.5 text-sm font-normal text-black-800"
                          align="right"
                        >
                          $503.12
                        </td>
                      </tr>
                      <tr>
                        <td className="border border-[#ECECEC] p-2.5 text-sm font-normal text-black-800">Net Amount</td>
                        <td
                          width="100px"
                          className="border border-[#ECECEC] bg-[#FAFAFA] p-2.5 text-sm font-normal text-black-800"
                          align="right"
                        >
                          $503.12
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="flex w-full flex-col">
                <div className="flex h-[50px] w-full flex-col items-center justify-center bg-[#ECECEC] text-base font-medium">
                  Visa/Mastercard Debit
                </div>
                <div className="w-full">
                  <table className="w-full border-collapse border border-[#ECECEC]">
                    <tbody>
                      <tr>
                        <td className="border border-[#ECECEC] p-2.5 text-sm font-normal text-black-800">Sales Qty</td>
                        <td
                          width="100px"
                          className="border border-[#ECECEC] bg-[#FAFAFA] p-2.5 text-sm font-normal text-black-800"
                          align="right"
                        >
                          $503.12
                        </td>
                      </tr>
                      <tr>
                        <td className="border border-[#ECECEC] p-2.5 text-sm font-normal text-black-800">Sales $</td>
                        <td
                          width="100px"
                          className="border border-[#ECECEC] bg-[#FAFAFA] p-2.5 text-sm font-normal text-black-800"
                          align="right"
                        >
                          $503.12
                        </td>
                      </tr>
                      <tr>
                        <td className="border border-[#ECECEC] p-2.5 text-sm font-normal text-black-800">
                          Returns Qty
                        </td>
                        <td
                          width="100px"
                          className="border border-[#ECECEC] bg-[#FAFAFA] p-2.5 text-sm font-normal text-black-800"
                          align="right"
                        >
                          $503.12
                        </td>
                      </tr>
                      <tr>
                        <td className="border border-[#ECECEC] p-2.5 text-sm font-normal text-black-800">Returns $</td>
                        <td
                          width="100px"
                          className="border border-[#ECECEC] bg-[#FAFAFA] p-2.5 text-sm font-normal text-black-800"
                          align="right"
                        >
                          $503.12
                        </td>
                      </tr>
                      <tr>
                        <td className="border border-[#ECECEC] p-2.5 text-sm font-normal text-black-800">Net Amount</td>
                        <td
                          width="100px"
                          className="border border-[#ECECEC] bg-[#FAFAFA] p-2.5 text-sm font-normal text-black-800"
                          align="right"
                        >
                          $503.12
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="flex w-full flex-col">
                <div className="flex h-[50px] w-full flex-col items-center justify-center bg-[#ECECEC] text-base font-medium">
                  Eftpos
                </div>
                <div className="w-full">
                  <table className="w-full border-collapse border border-[#ECECEC]">
                    <tbody>
                      <tr>
                        <td className="border border-[#ECECEC] p-2.5 text-sm font-normal text-black-800">Sales Qty</td>
                        <td
                          width="100px"
                          className="border border-[#ECECEC] bg-[#FAFAFA] p-2.5 text-sm font-normal text-black-800"
                          align="right"
                        >
                          $503.12
                        </td>
                      </tr>
                      <tr>
                        <td className="border border-[#ECECEC] p-2.5 text-sm font-normal text-black-800">Sales $</td>
                        <td
                          width="100px"
                          className="border border-[#ECECEC] bg-[#FAFAFA] p-2.5 text-sm font-normal text-black-800"
                          align="right"
                        >
                          $503.12
                        </td>
                      </tr>
                      <tr>
                        <td className="border border-[#ECECEC] p-2.5 text-sm font-normal text-black-800">
                          Returns Qty
                        </td>
                        <td
                          width="100px"
                          className="border border-[#ECECEC] bg-[#FAFAFA] p-2.5 text-sm font-normal text-black-800"
                          align="right"
                        >
                          $503.12
                        </td>
                      </tr>
                      <tr>
                        <td className="border border-[#ECECEC] p-2.5 text-sm font-normal text-black-800">Returns $</td>
                        <td
                          width="100px"
                          className="border border-[#ECECEC] bg-[#FAFAFA] p-2.5 text-sm font-normal text-black-800"
                          align="right"
                        >
                          $503.12
                        </td>
                      </tr>
                      <tr>
                        <td className="border border-[#ECECEC] p-2.5 text-sm font-normal text-black-800">Net Amount</td>
                        <td
                          width="100px"
                          className="border border-[#ECECEC] bg-[#FAFAFA] p-2.5 text-sm font-normal text-black-800"
                          align="right"
                        >
                          $503.12
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </AccordionBody>
        </Accordion>
        <Accordion
          className={`w-full gap-y-2.5 bg-white`}
          open={expand === 2}
          icon={
            <div data-autoid={`btnExpandAllOpenChats`}>
              <IconArrowDown
                className={`cursor-pointer ${expand === 2 ? 'rotate-180' : ''}`}
                name={expand === 2 ? 'Minimize' : 'Maximize'}
              />
            </div>
          }
        >
          <AccordionHeader
            className={`w-full cursor-default ${expand === 2 ? 'rounded-t-md' : 'rounded-md'} bg-[#D3E5EF] p-0 pr-4`}
            aria-controls="panel1a-content"
            id="panel1a-header"
            onClick={() => toggleAccordion(2)}
          >
            <div
              className={`box-border flex h-[40px] min-h-[40px] w-full flex-row items-center justify-between rounded-md px-4 py-2`}
            >
              <Typography variant="h3" className={`text-base font-medium text-black-800`}>
                Transaction Charges
              </Typography>
            </div>
          </AccordionHeader>
          <AccordionBody className=" p-0">
            <div className="flex flex-col gap-y-2.5">
              <div className="flex w-full flex-col">
                <div className="flex h-[50px] w-full flex-col items-center justify-center bg-[#ECECEC] text-base font-medium">
                  Visa/Mastercard Credit
                </div>
                <div className="w-full">
                  <table className="w-full border-collapse border border-[#ECECEC]">
                    <tbody>
                      <tr>
                        <td className="border border-[#ECECEC] p-2.5 text-sm font-normal text-black-800">Sales Fee</td>
                        <td
                          width="100px"
                          className="border border-[#ECECEC] bg-[#FAFAFA] p-2.5 text-sm font-normal text-black-800"
                          align="right"
                        >
                          $503.12
                        </td>
                      </tr>
                      <tr>
                        <td className="border border-[#ECECEC] p-2.5 text-sm font-normal text-black-800">
                          Sales Fee $
                        </td>
                        <td
                          width="100px"
                          className="border border-[#ECECEC] bg-[#FAFAFA] p-2.5 text-sm font-normal text-black-800"
                          align="right"
                        >
                          $503.12
                        </td>
                      </tr>
                      <tr>
                        <td className="border border-[#ECECEC] p-2.5 text-sm font-normal text-black-800">
                          Returns Fee
                        </td>
                        <td
                          width="100px"
                          className="border border-[#ECECEC] bg-[#FAFAFA] p-2.5 text-sm font-normal text-black-800"
                          align="right"
                        >
                          $503.12
                        </td>
                      </tr>
                      <tr>
                        <td className="border border-[#ECECEC] p-2.5 text-sm font-normal text-black-800">
                          Returns Fee $
                        </td>
                        <td
                          width="100px"
                          className="border border-[#ECECEC] bg-[#FAFAFA] p-2.5 text-sm font-normal text-black-800"
                          align="right"
                        >
                          $503.12
                        </td>
                      </tr>
                      <tr>
                        <td className="border border-[#ECECEC] p-2.5 text-sm font-normal text-black-800">Net Fee</td>
                        <td
                          width="100px"
                          className="border border-[#ECECEC] bg-[#FAFAFA] p-2.5 text-sm font-normal text-black-800"
                          align="right"
                        >
                          $503.12
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="flex w-full flex-col">
                <div className="flex h-[50px] w-full flex-col items-center justify-center bg-[#ECECEC] text-base font-medium">
                  Visa/Mastercard Debit
                </div>
                <div className="w-full">
                  <table className="w-full border-collapse border border-[#ECECEC]">
                    <tbody>
                      <tr>
                        <td className="border border-[#ECECEC] p-2.5 text-sm font-normal text-black-800">Sales Fee</td>
                        <td
                          width="100px"
                          className="border border-[#ECECEC] bg-[#FAFAFA] p-2.5 text-sm font-normal text-black-800"
                          align="right"
                        >
                          $503.12
                        </td>
                      </tr>
                      <tr>
                        <td className="border border-[#ECECEC] p-2.5 text-sm font-normal text-black-800">
                          Sales Fee $
                        </td>
                        <td
                          width="100px"
                          className="border border-[#ECECEC] bg-[#FAFAFA] p-2.5 text-sm font-normal text-black-800"
                          align="right"
                        >
                          $503.12
                        </td>
                      </tr>
                      <tr>
                        <td className="border border-[#ECECEC] p-2.5 text-sm font-normal text-black-800">
                          Returns Fee
                        </td>
                        <td
                          width="100px"
                          className="border border-[#ECECEC] bg-[#FAFAFA] p-2.5 text-sm font-normal text-black-800"
                          align="right"
                        >
                          $503.12
                        </td>
                      </tr>
                      <tr>
                        <td className="border border-[#ECECEC] p-2.5 text-sm font-normal text-black-800">
                          Returns Fee $
                        </td>
                        <td
                          width="100px"
                          className="border border-[#ECECEC] bg-[#FAFAFA] p-2.5 text-sm font-normal text-black-800"
                          align="right"
                        >
                          $503.12
                        </td>
                      </tr>
                      <tr>
                        <td className="border border-[#ECECEC] p-2.5 text-sm font-normal text-black-800">Net Fee</td>
                        <td
                          width="100px"
                          className="border border-[#ECECEC] bg-[#FAFAFA] p-2.5 text-sm font-normal text-black-800"
                          align="right"
                        >
                          $503.12
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="flex w-full flex-col">
                <div className="flex h-[50px] w-full flex-col items-center justify-center bg-[#ECECEC] text-base font-medium">
                  Eftpos
                </div>
                <div className="w-full">
                  <table className="w-full border-collapse border border-[#ECECEC]">
                    <tbody>
                      <tr>
                        <td className="border border-[#ECECEC] p-2.5 text-sm font-normal text-black-800">Sales Fee</td>
                        <td
                          width="100px"
                          className="border border-[#ECECEC] bg-[#FAFAFA] p-2.5 text-sm font-normal text-black-800"
                          align="right"
                        >
                          $503.12
                        </td>
                      </tr>
                      <tr>
                        <td className="border border-[#ECECEC] p-2.5 text-sm font-normal text-black-800">
                          Sales Fee $
                        </td>
                        <td
                          width="100px"
                          className="border border-[#ECECEC] bg-[#FAFAFA] p-2.5 text-sm font-normal text-black-800"
                          align="right"
                        >
                          $503.12
                        </td>
                      </tr>
                      <tr>
                        <td className="border border-[#ECECEC] p-2.5 text-sm font-normal text-black-800">
                          Returns Fee
                        </td>
                        <td
                          width="100px"
                          className="border border-[#ECECEC] bg-[#FAFAFA] p-2.5 text-sm font-normal text-black-800"
                          align="right"
                        >
                          $503.12
                        </td>
                      </tr>
                      <tr>
                        <td className="border border-[#ECECEC] p-2.5 text-sm font-normal text-black-800">
                          Returns Fee $
                        </td>
                        <td
                          width="100px"
                          className="border border-[#ECECEC] bg-[#FAFAFA] p-2.5 text-sm font-normal text-black-800"
                          align="right"
                        >
                          $503.12
                        </td>
                      </tr>
                      <tr>
                        <td className="border border-[#ECECEC] p-2.5 text-sm font-normal text-black-800">Net Fee</td>
                        <td
                          width="100px"
                          className="border border-[#ECECEC] bg-[#FAFAFA] p-2.5 text-sm font-normal text-black-800"
                          align="right"
                        >
                          $503.12
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </AccordionBody>
        </Accordion>
        <Accordion
          className={`w-full gap-y-2.5 bg-white`}
          open={expand === 3}
          icon={
            <div data-autoid={`btnExpandAllOpenChats`}>
              <IconArrowDown
                className={`cursor-pointer ${expand === 3 ? 'rotate-180' : ''}`}
                name={expand === 3 ? 'Minimize' : 'Maximize'}
              />
            </div>
          }
        >
          <AccordionHeader
            className={`w-full cursor-default ${expand === 3 ? 'rounded-t-md' : 'rounded-md'} bg-[#D3E5EF] p-0 pr-4`}
            aria-controls="panel1a-content"
            id="panel1a-header"
            onClick={() => toggleAccordion(3)}
          >
            <div
              className={`box-border flex h-[40px] min-h-[40px] w-full flex-row items-center justify-between rounded-md px-4 py-2`}
            >
              <Typography variant="h3" className={`text-base font-medium text-black-800`}>
                Cost of acceptance
              </Typography>
            </div>
          </AccordionHeader>
          <AccordionBody className=" p-0">
            <div className="flex flex-col gap-y-2.5">
              <div className="flex flex-col gap-y-2.5">
                <div className="flex w-full flex-col">
                  <div className="mb-2.5 flex h-[30px] w-full flex-col items-start justify-center border-y border-[#aaa] pl-4 text-base font-medium">
                    Statement Month
                  </div>
                  <div className="flex h-[50px] w-full flex-col items-center justify-center bg-[#ECECEC] text-base font-medium">
                    Visa/Mastercard Credit
                  </div>
                  <div className="w-full">
                    <table className="w-full border-collapse border border-[#ECECEC]">
                      <tbody>
                        <tr>
                          <td className="border border-[#ECECEC] p-2.5 text-sm font-normal text-black-800">
                            Total value transaction
                          </td>
                          <td
                            width="100px"
                            className="border border-[#ECECEC] bg-[#FAFAFA] p-2.5 text-sm font-normal text-black-800"
                            align="right"
                          >
                            $503.12
                          </td>
                        </tr>
                        <tr>
                          <td className="border border-[#ECECEC] p-2.5 text-sm font-normal text-black-800">
                            Total value fees
                          </td>
                          <td
                            width="100px"
                            className="border border-[#ECECEC] bg-[#FAFAFA] p-2.5 text-sm font-normal text-black-800"
                            align="right"
                          >
                            $503.12
                          </td>
                        </tr>
                        <tr>
                          <td className="border border-[#ECECEC] p-2.5 text-sm font-normal text-black-800">
                            Cost of acceptance
                          </td>
                          <td
                            width="100px"
                            className="border border-[#ECECEC] bg-[#FAFAFA] p-2.5 text-sm font-normal text-black-800"
                            align="right"
                          >
                            $503.12
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="flex w-full flex-col">
                  <div className="flex h-[50px] w-full flex-col items-center justify-center bg-[#ECECEC] text-base font-medium">
                    Visa/Mastercard Debit
                  </div>
                  <div className="w-full">
                    <table className="w-full border-collapse border border-[#ECECEC]">
                      <tbody>
                        <tr>
                          <td className="border border-[#ECECEC] p-2.5 text-sm font-normal text-black-800">
                            Total value transaction
                          </td>
                          <td
                            width="100px"
                            className="border border-[#ECECEC] bg-[#FAFAFA] p-2.5 text-sm font-normal text-black-800"
                            align="right"
                          >
                            $503.12
                          </td>
                        </tr>
                        <tr>
                          <td className="border border-[#ECECEC] p-2.5 text-sm font-normal text-black-800">
                            Total value fees
                          </td>
                          <td
                            width="100px"
                            className="border border-[#ECECEC] bg-[#FAFAFA] p-2.5 text-sm font-normal text-black-800"
                            align="right"
                          >
                            $503.12
                          </td>
                        </tr>
                        <tr>
                          <td className="border border-[#ECECEC] p-2.5 text-sm font-normal text-black-800">
                            Cost of acceptance
                          </td>
                          <td
                            width="100px"
                            className="border border-[#ECECEC] bg-[#FAFAFA] p-2.5 text-sm font-normal text-black-800"
                            align="right"
                          >
                            $503.12
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="flex w-full flex-col">
                  <div className="flex h-[50px] w-full flex-col items-center justify-center bg-[#ECECEC] text-base font-medium">
                    Eftpos
                  </div>
                  <div className="w-full">
                    <table className="w-full border-collapse border border-[#ECECEC]">
                      <tbody>
                        <tr>
                          <td className="border border-[#ECECEC] p-2.5 text-sm font-normal text-black-800">
                            Total value transaction
                          </td>
                          <td
                            width="100px"
                            className="border border-[#ECECEC] bg-[#FAFAFA] p-2.5 text-sm font-normal text-black-800"
                            align="right"
                          >
                            $503.12
                          </td>
                        </tr>
                        <tr>
                          <td className="border border-[#ECECEC] p-2.5 text-sm font-normal text-black-800">
                            Total value fees
                          </td>
                          <td
                            width="100px"
                            className="border border-[#ECECEC] bg-[#FAFAFA] p-2.5 text-sm font-normal text-black-800"
                            align="right"
                          >
                            $503.12
                          </td>
                        </tr>
                        <tr>
                          <td className="border border-[#ECECEC] p-2.5 text-sm font-normal text-black-800">
                            Cost of acceptance
                          </td>
                          <td
                            width="100px"
                            className="border border-[#ECECEC] bg-[#FAFAFA] p-2.5 text-sm font-normal text-black-800"
                            align="right"
                          >
                            $503.12
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-y-2.5">
                <div className="flex w-full flex-col">
                  <div className="mb-2.5 flex h-[30px] w-full flex-col items-start justify-center border-y border-[#aaa] pl-4 text-base font-medium">
                    Most Recent 12 Months
                  </div>
                  <div className="flex h-[50px] w-full flex-col items-center justify-center bg-[#ECECEC] text-base font-medium">
                    Visa/Mastercard Credit
                  </div>
                  <div className="w-full">
                    <table className="w-full border-collapse border border-[#ECECEC]">
                      <tbody>
                        <tr>
                          <td className="border border-[#ECECEC] p-2.5 text-sm font-normal text-black-800">
                            Total value transaction
                          </td>
                          <td
                            width="100px"
                            className="border border-[#ECECEC] bg-[#FAFAFA] p-2.5 text-sm font-normal text-black-800"
                            align="right"
                          >
                            $503.12
                          </td>
                        </tr>
                        <tr>
                          <td className="border border-[#ECECEC] p-2.5 text-sm font-normal text-black-800">
                            Total value fees
                          </td>
                          <td
                            width="100px"
                            className="border border-[#ECECEC] bg-[#FAFAFA] p-2.5 text-sm font-normal text-black-800"
                            align="right"
                          >
                            $503.12
                          </td>
                        </tr>
                        <tr>
                          <td className="border border-[#ECECEC] p-2.5 text-sm font-normal text-black-800">
                            Cost of acceptance
                          </td>
                          <td
                            width="100px"
                            className="border border-[#ECECEC] bg-[#FAFAFA] p-2.5 text-sm font-normal text-black-800"
                            align="right"
                          >
                            $503.12
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="flex w-full flex-col">
                  <div className="flex h-[50px] w-full flex-col items-center justify-center bg-[#ECECEC] text-base font-medium">
                    Visa/Mastercard Debit
                  </div>
                  <div className="w-full">
                    <table className="w-full border-collapse border border-[#ECECEC]">
                      <tbody>
                        <tr>
                          <td className="border border-[#ECECEC] p-2.5 text-sm font-normal text-black-800">
                            Total value transaction
                          </td>
                          <td
                            width="100px"
                            className="border border-[#ECECEC] bg-[#FAFAFA] p-2.5 text-sm font-normal text-black-800"
                            align="right"
                          >
                            $503.12
                          </td>
                        </tr>
                        <tr>
                          <td className="border border-[#ECECEC] p-2.5 text-sm font-normal text-black-800">
                            Total value fees
                          </td>
                          <td
                            width="100px"
                            className="border border-[#ECECEC] bg-[#FAFAFA] p-2.5 text-sm font-normal text-black-800"
                            align="right"
                          >
                            $503.12
                          </td>
                        </tr>
                        <tr>
                          <td className="border border-[#ECECEC] p-2.5 text-sm font-normal text-black-800">
                            Cost of acceptance
                          </td>
                          <td
                            width="100px"
                            className="border border-[#ECECEC] bg-[#FAFAFA] p-2.5 text-sm font-normal text-black-800"
                            align="right"
                          >
                            $503.12
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="flex w-full flex-col">
                  <div className="flex h-[50px] w-full flex-col items-center justify-center bg-[#ECECEC] text-base font-medium">
                    Eftpos
                  </div>
                  <div className="w-full">
                    <table className="w-full border-collapse border border-[#ECECEC]">
                      <tbody>
                        <tr>
                          <td className="border border-[#ECECEC] p-2.5 text-sm font-normal text-black-800">
                            Total value transaction
                          </td>
                          <td
                            width="100px"
                            className="border border-[#ECECEC] bg-[#FAFAFA] p-2.5 text-sm font-normal text-black-800"
                            align="right"
                          >
                            $503.12
                          </td>
                        </tr>
                        <tr>
                          <td className="border border-[#ECECEC] p-2.5 text-sm font-normal text-black-800">
                            Total value fees
                          </td>
                          <td
                            width="100px"
                            className="border border-[#ECECEC] bg-[#FAFAFA] p-2.5 text-sm font-normal text-black-800"
                            align="right"
                          >
                            $503.12
                          </td>
                        </tr>
                        <tr>
                          <td className="border border-[#ECECEC] p-2.5 text-sm font-normal text-black-800">
                            Cost of acceptance
                          </td>
                          <td
                            width="100px"
                            className="border border-[#ECECEC] bg-[#FAFAFA] p-2.5 text-sm font-normal text-black-800"
                            align="right"
                          >
                            $503.12
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </AccordionBody>
        </Accordion>
        <Accordion
          className={`w-full gap-y-2.5 bg-white`}
          open={expand === 4}
          icon={
            <div data-autoid={`btnExpandAllOpenChats`}>
              <IconArrowDown
                className={`cursor-pointer ${expand === 4 ? 'rotate-180' : ''}`}
                name={expand === 4 ? 'Minimize' : 'Maximize'}
              />
            </div>
          }
        >
          <AccordionHeader
            className={`w-full cursor-default ${expand === 4 ? 'rounded-t-md' : 'rounded-md'} bg-[#D3E5EF] p-0 pr-4`}
            aria-controls="panel1a-content"
            id="panel1a-header"
            onClick={() => toggleAccordion(4)}
          >
            <div
              className={`box-border flex h-[40px] min-h-[40px] w-full flex-row items-center justify-between rounded-md px-4 py-2`}
            >
              <Typography variant="h3" className={`text-base font-medium text-black-800`}>
                Other charges
              </Typography>
            </div>
          </AccordionHeader>
          <AccordionBody className=" p-0">
            <div className="flex flex-col gap-y-2.5">
              <div className="flex w-full flex-col">
                <div className="flex h-[50px] w-full flex-col items-center justify-center bg-[#ECECEC] text-base font-medium">
                  Terminal rental fee
                </div>
                <div className="w-full">
                  <table className="w-full border-collapse border border-[#ECECEC]">
                    <tbody>
                      <tr>
                        <td className="border border-[#ECECEC] p-2.5 text-sm font-normal text-black-800">Quantity</td>
                        <td
                          width="100px"
                          className="border border-[#ECECEC] bg-[#FAFAFA] p-2.5 text-sm font-normal text-black-800"
                          align="right"
                        >
                          $503.12
                        </td>
                      </tr>
                      <tr>
                        <td className="border border-[#ECECEC] p-2.5 text-sm font-normal text-black-800">Unit Price</td>
                        <td
                          width="100px"
                          className="border border-[#ECECEC] bg-[#FAFAFA] p-2.5 text-sm font-normal text-black-800"
                          align="right"
                        >
                          $503.12
                        </td>
                      </tr>
                      <tr>
                        <td className="border border-[#ECECEC] p-2.5 text-sm font-normal text-black-800">Net fee</td>
                        <td
                          width="100px"
                          className="border border-[#ECECEC] bg-[#FAFAFA] p-2.5 text-sm font-normal text-black-800"
                          align="right"
                        >
                          $503.12
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </AccordionBody>
        </Accordion>
      </div>
      <div className="!mx-2.5 flex h-[45px] w-auto flex-col items-center justify-center rounded-md border-2 border-[#ECECEC] font-poppins text-base font-normal">
        1000 points
      </div>
    </div>
  );
};
