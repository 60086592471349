import React, {SetStateAction} from 'react';
import {Typography} from 'spenda-ui-react';
import {AlertDialogSlideV2} from './AlertDialogSlideV2';

export const QuoteExpiredDialog = (props: {
  expiryDate: string;
  handleClose: React.Dispatch<SetStateAction<boolean>>;
}) => {
  return (
    <AlertDialogSlideV2
      title={'Quote expired'}
      headingTextSize="h2"
      headingClassess="justify-center"
      dialogActionsAlignment="justify-center"
      dialogClassess="!min-w-[20%] !max-w-[497px] !w-full sm:h-auto h-full sm:block flex justify-center items-center flex-col sm:p-0 p-8"
      dialogBodyClassess="flex justify-center "
      actions={[
        {
          label: 'Done',
          action: () => {
            props.handleClose(false);
          },
          color: 'primary',
        },
      ]}
    >
      <Typography variant="small" className="w-full max-w-[352px] py-10 text-center font-normal text-black-800">
        This quote package expired on {props.expiryDate}, the price of the package is no longer valid.
      </Typography>
    </AlertDialogSlideV2>
  );
};
