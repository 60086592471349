import React from 'react';
import {ARDialogTemplate} from '../ARDialogTemplate';
import {PrimaryButton, SecondaryButton} from '../../buttons/DefaultButtons';

interface ICanNotDeleteSchedulePaymentModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const CanNotDeleteSchedulePaymentModal = (props: ICanNotDeleteSchedulePaymentModalProps) => {
  //Props
  const {isOpen, onClose} = props;

  return (
    <ARDialogTemplate
      dialogProps={{size: 'xs', open: isOpen, className: '!min-w-[460px] !max-w-[460px]'}}
      isFullScreen
      header={<p className=" font-poppins text-xl font-light text-[#333333]">Cancel Scheduled payment</p>}
      body={
        <div className="my-12">
          <p className="text-center font-poppins text-base font-light text-black-800">
            Only an Admin or Primary User can cancel a Scheduled Payment
          </p>
        </div>
      }
      footer={
        <div className="flex w-full flex-row items-center justify-between">
          <SecondaryButton data-autoid={`btnCancelScheduledPayments`} label="Close" onClick={() => onClose()} />
          <PrimaryButton data-autoid={`btnCancelBatchScheduledPayments`} label="Done" onClick={onClose} />
        </div>
      }
      handleClose={() => onClose()}
    />
  );
};
