import {IUser} from '../model/user/User';
import {useEffect, useMemo, useState} from 'react';
import {useLocation} from 'react-router-dom';
import useTheme from '../context/theme-context/useTheme';

export const useZohoChat = (zohoUrl: string, widgetCode: string) => {
  // https://www.zoho.com/salesiq/help/developer-section/js-api-visitor-name.html
  // https://www.zoho.com/salesiq/help/getting-started-add-welcome-page-code-to-website.html
  // inspect object 'window.$zoho.salesiq' in debugger tools for more utility function

  const location = useLocation();

  const {content} = useTheme();

  const [isOnline, setIsOnline] = useState<boolean>(!!window.$zoho?.salesiq?.chat?.isOnline);

  const [isReady, setIsReady] = useState<boolean>(!!window.$zoho?.salesiq?.chat?.isReady);

  useEffect(() => {
    if (window.$zoho) {
      return;
    }

    // script already exists...nothing to do here
    const existingScript = document.getElementById('zsiqscript');
    if (existingScript) {
      return;
    }

    // Hide Zoho chat widget based on theme options
    if (content?.zohoChat) {
      return;
    }

    const script = document.createElement('script');
    script.setAttribute('type', 'text/javascript');

    const code = `
                var $zoho = $zoho || {};
                $zoho.salesiq = $zoho.salesiq || { 
                    widgetcode: "${widgetCode}",
                    values: {},
                    ready: function () {},
                };
                var d = document;
                s = d.createElement("script");
                s.type = "text/javascript";
                s.id = "zsiqscript";
                s.defer = true;
                s.src = "${zohoUrl}";
                t = d.getElementsByTagName("script")[0];
                t.parentNode.insertBefore(s, t);
                d.innerHTML = "<div id='zsiqwidget'></div>";
        `;

    script.appendChild(document.createTextNode(code));
    document.body.appendChild(script);
  }, [window.$zoho, location.pathname]);

  useEffect(() => {
    if (!window.$zoho) {
      return;
    }

    if (window.$zoho.salesiq.chat?.isOnline !== isOnline) {
      // change detected
      setIsOnline(window.$zoho.salesiq.chat?.isOnline);
    }

    if (window.$zoho.salesiq.chat?.isReady !== isReady) {
      // change detected
      setIsOnline(window.$zoho.salesiq.chat?.isReady);
    }

    const recordOnlineStatusFromZohoServer = (isOnline: boolean) => {
      // ***** spenda custom property - our own property to track online status
      window.$zoho.salesiq.chat.isOnline = isOnline;
      // ***** spenda custom property *******
      setIsOnline(window.$zoho.salesiq.chat.isOnline);
      zohoCustomObject.isOnline = window.$zoho.salesiq.chat.isOnline;
    };

    window.$zoho.salesiq.ready = () => {
      window.$zoho.salesiq.chat.offline(() => {
        recordOnlineStatusFromZohoServer(false);
      });

      window.$zoho.salesiq.chat.online(() => {
        recordOnlineStatusFromZohoServer(true);
      });

      recordOnlineStatusFromZohoServer(false);
    };

    window.$zoho.salesiq.afterReady = function () {
      window.$zoho.salesiq.chat.isReady = true;
      // ***** spenda custom property *******
      setIsReady(window.$zoho.salesiq.chat.isReady);
      zohoCustomObject.isReady = window.$zoho.salesiq.chat.isReady;
    };
  }, [zohoUrl, window.$zoho]);

  const createZohoCustomObject = () => {
    const defaultHelpMessage = 'Hey, I need assistance!';
    var r = {
      showChatWindow: () => {
        window.$zoho.salesiq.floatwindow.visible('show');
      },
      hideChatWindow: () => {
        window.$zoho.salesiq.floatwindow.visible('hide');
      },
      isChatWindowVisable: () => {
        return window.$zoho.salesiq.floatwindowvisible === 'visible';
      },
      closeChatWindow: () => {
        window.$zoho.salesiq.floatwindow.close();
      },
      hideFloatingButton: () => {
        window?.$zoho?.salesiq?.floatbutton?.visible('hide');
      },
      showFloatingButton: () => {
        window?.$zoho?.salesiq?.floatbutton?.visible('show');
      },
      isOnline,
      isReady,
      loadUser: (user: IUser) => {
        if (user) {
          window.$zoho.salesiq.visitor.name(user.FirstName);
          window.$zoho.salesiq.visitor.email(user.UserName);
          window.$zoho.salesiq.visitor.question(defaultHelpMessage);
        }
      },
      loadQuestion: (question: string) => {
        window.$zoho.salesiq.visitor.question(question);
      },
      resetChatWindow: () => {
        // had to put the timeout otherwise the widget does not reset ¯\_(ツ)_/¯
        //setTimeout(() => {
        window.$zoho.salesiq.reset();
        // }, 1000);
      },
    };
    return r;
  };

  const zohoCustomObject = useMemo(() => createZohoCustomObject(), [isOnline, isReady]);

  return zohoCustomObject;
};
