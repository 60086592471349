import * as React from 'react';

export const CatalogueIcon = (props: SVGProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={20} fill="none" {...props}>
      <path
        className="fill-primary"
        fillRule="evenodd"
        d="M6.234 8.333a2.5 2.5 0 100 5h3.333a2.5 2.5 0 100-5H6.234zm4.166 2.5c0 .46-.373.834-.833.834H6.234a.833.833 0 010-1.667h3.333c.46 0 .833.373.833.833zm1.667 5c0 .46-.373.834-.833.834H4.567a.833.833 0 110-1.667h6.667c.46 0 .833.373.833.833zM14.18 2.601L12.8 1.22A4.14 4.14 0 009.853 0H4.567A4.172 4.172 0 00.4 4.167v11.666A4.172 4.172 0 004.567 20h6.667a4.172 4.172 0 004.166-4.167V5.548A4.14 4.14 0 0014.18 2.6zm-1.178 1.178c.117.119.223.249.315.388h-2.083V2.083c.139.092.268.197.386.314l1.382 1.382zm.732 12.054a2.5 2.5 0 01-2.5 2.5H4.567a2.5 2.5 0 01-2.5-2.5V4.167a2.5 2.5 0 012.5-2.5h5v2.5c0 .92.746 1.666 1.667 1.666h2.5v10z"
        clipRule="evenodd"
      />
    </svg>
  );
};
