import React, {Fragment, useState, useEffect, useRef, useCallback} from 'react';
// import {css} from 'glamor';
import clsx from 'clsx';
import {debounce} from 'lodash';
import {SuggestionSelectedEventData} from 'react-autosuggest';
import {bindPopper, usePopupState} from 'material-ui-popup-state/hooks';

import {SAutocomplete} from '../inputs/SAutocomplete';
import {SSelectSimple} from '../inputs/SSelect';
import {ICategory} from '../../model/inventory/Category';
import {IBrand} from '../../model/inventory/Brand';
import {Inventory} from '../../model/inventory/Inventory';
import {InventoryClass} from '../../model/inventory/InventoryClass';
import {IAccounts} from '../../model/accounts/Accounts';
import {ISupplier} from '../../model/supplier/Supplier';
import {IWarehouse} from '../../model/Warehouse';
import {DatTypes} from '../../model/constants/Constants';
import {IDashboardActionResults, IPagedActionResults} from '../../model/ActionResults';
import useCategoryAPI from '../../services/useCategoryAPI';
import useBrandAPI from '../../services/useBrandAPI';
import useAccountAPI from '../../services/useAccountAPI';
import useWarehouseAPI from '../../services/useWarehouseAPI';
import {useInventoryAPI} from '../../services/useInventoryAPI';
import {useInventoryClassAPI} from '../../services/useInventoryClassesAPI';
import {useSupplierAPI} from '../../services/useSupplierAPI';
import UnitofMeasure from '../../assets/data/UnitofMeasure.json';

import {
  ClickAwayListener,
  Fade,
  IconButton,
  InputAdornment,
  InputBase,
  List,
  ListItem,
  MenuItem,
  Popper,
  SelectProps,
  makeStyles,
  withStyles,
} from '@material-ui/core';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import LoadingIndicator from '../ui/LoadingIndicator';
import {Typography} from 'spenda-ui-react';

interface IInventoryAutocomplete<T> {
  size?: 'small' | 'medium';
  placeholder?: string;
  additionalFilter?: any;
  multiple?: boolean;
  multipleSelected?: Partial<T>[];
  disabled?: boolean;
  selected: Partial<T> | null;
  helperText?: string | boolean | undefined;
  error?: boolean | undefined;
  addNewClick?: ((value: string) => void) | boolean | undefined;
  clearClick?: (() => void) | boolean | undefined;
  onSuggestionSelected: (event: React.FormEvent<any>, selected: SuggestionSelectedEventData<T>) => void;
  alreadyFilled?: any;
  autoFocus?: boolean;
  handleKeyPress?: (event: React.KeyboardEvent) => void;
  onFocus?: () => void;
  styles?: any;
  searchCodeOnly?: boolean;
  id?: string;
}

export const CategoryAutocomplete = (props: IInventoryAutocomplete<Partial<ICategory>>) => {
  const [suggestions, setSuggestions] = useState<Partial<ICategory>[]>([]);

  const {isLoading, dashboard} = useCategoryAPI();

  const {multiple, multipleSelected, placeholder, selected, addNewClick, clearClick, onSuggestionSelected} = props;

  const getCategoryList = (newValue: string = '') => {
    if (!selected) {
      dashboard({
        DatTypeID: DatTypes.Category,
        MaxResults: 15,
        SearchNameOnly: true,
        ...(newValue ? {Search: newValue} : {}),
      }).then((res: IDashboardActionResults<ICategory[]>) => {
        setSuggestions(res?.Value || []);
      });
    }
  };

  useEffect(() => {
    if (selected) {
      setSuggestions([selected]);
    }
  }, [selected]);

  const handleSuggestionSelected = (
    event: React.FormEvent<any>,
    _selected: SuggestionSelectedEventData<Partial<ICategory>>,
  ) => {
    onSuggestionSelected(event, _selected);
    if (!multiple) {
      setSuggestions([_selected.suggestion]);
    }
  };

  return (
    <SAutocomplete<Partial<ICategory>>
      async
      styles={props.styles}
      loading={isLoading}
      placeholder={placeholder || 'Search Category'}
      addNewClick={addNewClick}
      clearClick={clearClick}
      suggestions={suggestions}
      selected={selected}
      getSuggestions={getCategoryList}
      renderSuggestion={(item: Partial<ICategory>) => {
        const isSelected = multiple && multipleSelected && multipleSelected.some(_m => _m.Name === item.Name);
        return (
          <div className="relative">
            <Typography>{item.Name}</Typography>
            {item.ParentID ? <Typography className="text-xs">Parent: {item.ParentName}</Typography> : null}
            {isSelected ? (
              <div className="absolute right-1 top-0 my-auto">
                <CheckRoundedIcon fontSize="small" className="text-primary" />
              </div>
            ) : null}
          </div>
        );
      }}
      getSuggestionValue={(suggestion: Partial<ICategory>) => {
        return `${suggestion.Name}`;
      }}
      onSearchChange={(value: string) => {
        getCategoryList(value);
      }}
      onSuggestionSelected={handleSuggestionSelected}
    />
  );
};

export const BrandAutocomplete = (props: IInventoryAutocomplete<Partial<IBrand>>) => {
  const [suggestions, setSuggestions] = useState<Partial<IBrand>[]>([]);

  const {isLoading, dashboard} = useBrandAPI();

  const {placeholder, selected, addNewClick, clearClick, onSuggestionSelected} = props;

  const getBrandList = (newValue: string = '') => {
    if (!selected) {
      dashboard({
        DatTypeID: DatTypes.Brands,
        MaxResults: 15,
        SearchNameOnly: true,
        ...(newValue ? {Search: newValue} : {}),
      }).then((res: IDashboardActionResults<IBrand[]>) => {
        setSuggestions(res?.Value || []);
      });
    }
  };

  useEffect(() => {
    if (selected) {
      setSuggestions([selected]);
    }
  }, [selected]);

  const handleSuggestionSelected = (
    event: React.FormEvent<any>,
    _selected: SuggestionSelectedEventData<Partial<IBrand>>,
  ) => {
    onSuggestionSelected(event, _selected);
    setSuggestions([_selected.suggestion]);
  };

  return (
    <SAutocomplete<Partial<IBrand>>
      async
      styles={props.styles}
      loading={isLoading}
      placeholder={placeholder || 'Search Brand'}
      addNewClick={addNewClick}
      clearClick={clearClick}
      suggestions={suggestions}
      selected={selected}
      getSuggestions={getBrandList}
      renderSuggestion={(item: Partial<IBrand>) => (
        <>
          <Typography>{item.Name}</Typography>
        </>
      )}
      getSuggestionValue={(suggestion: Partial<IBrand>) => {
        return `${suggestion.Name}`;
      }}
      onSearchChange={(value: string) => {
        getBrandList(value);
      }}
      onSuggestionSelected={handleSuggestionSelected}
    />
  );
};

export const InventoryAutocomplete = (props: IInventoryAutocomplete<Partial<Inventory>>) => {
  const [suggestions, setSuggestions] = useState<Partial<Inventory>[]>([]);

  const {isLoading, listInventory} = useInventoryAPI();
  const [currentSelected, setCurrentSelected] = useState<Partial<Inventory> | null>(null);

  const {
    size,
    disabled,
    placeholder,
    selected,
    error,
    helperText,
    additionalFilter,
    addNewClick,
    clearClick,
    onSuggestionSelected,
    alreadyFilled,
    autoFocus,
    handleKeyPress,
  } = props;

  useEffect(() => {
    if (selected) {
      setSuggestions([selected]);
    }
    setCurrentSelected(selected);
  }, [selected]);

  const handleSuggestionSelected = (
    event: React.FormEvent<any>,
    _selected: SuggestionSelectedEventData<Partial<Inventory>>,
  ) => {
    if (alreadyFilled) {
      if (!alreadyFilled.some((_m: number) => _m === _selected.suggestion.ID)) {
        onSuggestionSelected(event, _selected);
        setSuggestions([_selected.suggestion]);
      }
    } else {
      onSuggestionSelected(event, _selected);
      setSuggestions([_selected.suggestion]);
    }
  };

  const getInventoryList = (newValue: string = '') => {
    if (!currentSelected) {
      listInventory({
        MaxResults: 15,
        ...(typeof additionalFilter === 'object' ? additionalFilter : {}),
        ...(newValue ? {Search: newValue} : {}),
      }).then((res: IPagedActionResults<Inventory[]>) => {
        setSuggestions(res?.Value || []);
      });
    }
    if (currentSelected && typeof clearClick === 'function') {
      clearClick();
    }
  };

  return (
    <SAutocomplete<Partial<Inventory>>
      styles={props.styles}
      async
      autoFocus={autoFocus}
      size={size || 'medium'}
      disabled={disabled}
      loading={isLoading}
      placeholder={placeholder || 'Search Inventory by Code or Name'}
      addNewClick={addNewClick}
      clearClick={clearClick}
      suggestions={suggestions}
      selected={selected}
      error={error}
      helperText={helperText}
      handleKeyPress={handleKeyPress}
      getSuggestions={getInventoryList}
      renderSuggestion={(item: Partial<Inventory>) => {
        const isSelected = alreadyFilled && alreadyFilled.some((_m: number) => _m === item.ID);

        return (
          <div className="relative">
            <>
              <b>{item.InventoryCode}</b> {item.ShortDescription}
            </>
            {/* {item.ParentID ? <p {...css({fontSize: '0.6rem'})}>Parent: {item.ShortDescription}</p> : null} */}
            {isSelected ? (
              <div className="absolute right-1 top-0 my-auto">
                <CheckRoundedIcon fontSize="small" className="text-primary" />
              </div>
            ) : null}
          </div>
        );
      }}
      getSuggestionValue={(suggestion: Partial<Inventory>) => {
        return `${suggestion.InventoryCode} ${suggestion.ShortDescription}`;
      }}
      onSearchChange={(value: string) => {
        getInventoryList(value);
      }}
      onSuggestionSelected={handleSuggestionSelected}
    />
  );
};

export const InventoryClassAutocomplete = (props: IInventoryAutocomplete<Partial<InventoryClass>>) => {
  const [suggestions, setSuggestions] = useState<Partial<InventoryClass>[]>([]);

  const {isLoading, dashboard} = useInventoryClassAPI();

  const {disabled, placeholder, selected, addNewClick, clearClick, onSuggestionSelected} = props;

  const getInventoryClassList = (newValue: string = '') => {
    if (!selected) {
      dashboard({
        DatTypeID: DatTypes.InventoryClass,
        MaxResults: 15,
        SearchNameOnly: true,
        ...(newValue ? {Search: newValue} : {}),
      }).then((res: IDashboardActionResults<InventoryClass[]>) => {
        setSuggestions(res?.Value || []);
      });
    }
  };

  useEffect(() => {
    if (selected) {
      setSuggestions([selected]);
    }
  }, [selected]);

  const handleSuggestionSelected = (
    event: React.FormEvent<any>,
    _selected: SuggestionSelectedEventData<Partial<InventoryClass>>,
  ) => {
    onSuggestionSelected(event, _selected);
    setSuggestions([_selected.suggestion]);
  };

  return (
    <SAutocomplete<Partial<InventoryClass>>
      async
      styles={props.styles}
      disabled={disabled}
      loading={isLoading}
      placeholder={placeholder || 'Search Product Class'}
      addNewClick={addNewClick}
      clearClick={clearClick}
      suggestions={suggestions}
      selected={selected}
      getSuggestions={getInventoryClassList}
      renderSuggestion={(item: Partial<InventoryClass>) => <>{item.Name}</>}
      getSuggestionValue={(suggestion: Partial<InventoryClass>) => {
        return `${suggestion.Name}`;
      }}
      onSearchChange={(value: string) => {
        getInventoryClassList(value);
      }}
      onSuggestionSelected={handleSuggestionSelected}
    />
  );
};

export const UoMDropDown = (props: SelectProps & {helperText?: string | boolean | undefined}) => {
  return (
    <SSelectSimple {...props}>
      <MenuItem value="" disabled>
        {props.placeholder ? props.placeholder : 'Select UoM'}
      </MenuItem>
      {UnitofMeasure.map((uom: {name: string; value: string}) => (
        <MenuItem key={uom.value} value={uom.value}>
          {uom.name}
        </MenuItem>
      ))}
    </SSelectSimple>
  );
};

export const InventoryTypeDropDown = (props: SelectProps & {helperText?: string | boolean | undefined}) => {
  return (
    <SSelectSimple {...props}>
      <MenuItem value="" disabled>
        {props.placeholder ? props.placeholder : 'Select Inventory Type'}
      </MenuItem>
      <MenuItem key="Inventory" value="Inventory">
        Inventory
      </MenuItem>
      <MenuItem key="Fee" value="Fee">
        Fee
      </MenuItem>
      <MenuItem key="Labour" value="Labour">
        Labour
      </MenuItem>
    </SSelectSimple>
  );
};

export const AccountsAutocomplete = (props: IInventoryAutocomplete<Partial<IAccounts>>) => {
  const [suggestions, setSuggestions] = useState<Partial<IAccounts>[]>([]);

  const {isLoading, listAccounts} = useAccountAPI();

  const {placeholder, selected, addNewClick, clearClick, additionalFilter, onSuggestionSelected} = props;

  const getAccountsList = (newValue: string = '') => {
    if (!selected) {
      listAccounts({
        MaxResults: 15,
        ...additionalFilter,
        ...(newValue ? {Search: newValue} : {}),
      }).then((res: IPagedActionResults<IAccounts[]>) => {
        setSuggestions(res?.Value || []);
      });
    }
  };

  useEffect(() => {
    if (selected) {
      setSuggestions([selected]);
    }
  }, [selected]);

  const handleSuggestionSelected = (
    event: React.FormEvent<any>,
    _selected: SuggestionSelectedEventData<Partial<IAccounts>>,
  ) => {
    onSuggestionSelected(event, _selected);
    setSuggestions([_selected.suggestion]);
  };

  return (
    <SAutocomplete<Partial<IAccounts>>
      async
      styles={props.styles}
      loading={isLoading}
      placeholder={placeholder || 'Search for an Account'}
      addNewClick={addNewClick}
      clearClick={clearClick}
      suggestions={suggestions}
      selected={selected}
      getSuggestions={getAccountsList}
      renderSuggestion={(item: Partial<IAccounts>) => (
        <Typography>
          <b>{item.Code}</b>: {item.Name}
        </Typography>
      )}
      getSuggestionValue={(suggestion: Partial<IAccounts>) => {
        return `${suggestion.Code} ${suggestion.Name || ''}`;
      }}
      onSearchChange={(value: string) => {
        getAccountsList(value);
      }}
      onSuggestionSelected={handleSuggestionSelected}
    />
  );
};

export const SupplierAutocomplete = (props: IInventoryAutocomplete<Partial<ISupplier>>) => {
  const [suggestions, setSuggestions] = useState<Partial<ISupplier>[]>([]);

  const {isLoading, search} = useSupplierAPI();

  const {placeholder, selected, clearClick, onSuggestionSelected} = props;

  const getSupplierList = (newValue: string = '') => {
    if (!selected) {
      search({
        MaxResults: 15,
        ...(newValue ? {Search: newValue} : {}),
      }).then((res: ISupplier[]) => {
        setSuggestions(res);
      });
    }
  };

  useEffect(() => {
    if (selected) {
      setSuggestions([selected]);
    }
  }, [selected]);

  const handleSuggestionSelected = (
    event: React.FormEvent<any>,
    _selected: SuggestionSelectedEventData<Partial<ISupplier>>,
  ) => {
    onSuggestionSelected(event, _selected);
    setSuggestions([_selected.suggestion]);
  };

  return (
    <SAutocomplete<Partial<ISupplier>>
      async
      styles={props.styles}
      // hideInitialSuggestion
      loading={isLoading}
      placeholder={placeholder || 'Search for a Supplier'}
      clearClick={clearClick}
      suggestions={suggestions}
      selected={selected}
      getSuggestions={getSupplierList}
      renderSuggestion={(item: Partial<ISupplier>) => (
        <>
          <Typography>{item.Name}</Typography>
        </>
      )}
      getSuggestionValue={(suggestion: Partial<ISupplier>) => {
        return `${suggestion.Name || ''}`;
      }}
      onSearchChange={(value: string) => {
        getSupplierList(value);
      }}
      onSuggestionSelected={handleSuggestionSelected}
    />
  );
};

export const WarehouseAutocomplete = (props: IInventoryAutocomplete<Partial<IWarehouse>>) => {
  const [suggestions, setSuggestions] = useState<Partial<IWarehouse>[]>([]);

  const {isLoading, listWarehouses} = useWarehouseAPI();

  const {disabled, placeholder, selected, clearClick, onSuggestionSelected} = props;

  const getWarehouseList = (newValue: string = '') => {
    if (!selected) {
      listWarehouses({
        MaxResults: 15,
        ...(newValue ? {Search: newValue} : {}),
      }).then((res: IPagedActionResults<IWarehouse[]>) => {
        setSuggestions(res?.Value || []);
      });
    }
  };

  useEffect(() => {
    if (selected) {
      setSuggestions([selected]);
    }
  }, [selected]);

  const handleSuggestionSelected = (
    event: React.FormEvent<any>,
    _selected: SuggestionSelectedEventData<Partial<IWarehouse>>,
  ) => {
    onSuggestionSelected(event, _selected);
    setSuggestions([_selected.suggestion]);
  };

  return (
    <SAutocomplete<Partial<IWarehouse>>
      async
      styles={props.styles}
      disabled={disabled}
      loading={isLoading}
      placeholder={placeholder || 'Search Warehouse'}
      clearClick={clearClick}
      suggestions={suggestions}
      selected={selected}
      getSuggestions={getWarehouseList}
      renderSuggestion={(item: Partial<IWarehouse>) => <>{item.Name}</>}
      getSuggestionValue={(suggestion: Partial<IWarehouse>) => {
        return `${suggestion.Name}`;
      }}
      onSearchChange={(value: string) => {
        getWarehouseList(value);
      }}
      onSuggestionSelected={handleSuggestionSelected}
    />
  );
};

const StyledInputBase = withStyles({
  root: {
    '&.MuiInputBase-root': {
      color: '#333333',
      fontSize: '12px',
      fontFamily: 'Poppins, sans-serif',
      '&::placeholder': {
        color: '#999999',
      },
      '& input': {
        borderRadius: 0,
        padding: 0,
      },
      '& .MuiButtonBase-root': {
        padding: '0px',
        color: '#999999',
        display: 'none',
      },
      '&.Mui-focused .MuiButtonBase-root': {
        color: 'hsl(var(--primary))',
        display: 'inline-block',
      },
    },
  },
})(InputBase);

const useSalesOrderStyle = makeStyles(() => ({
  suggestionsContainer: {
    zIndex: 99999,
    borderRadius: '6px',
    backgroundColor: '#FFFFFF',
    border: '1px solid hsl(var(--primary))',
    padding: '6px',
    maxHeight: '20vh',
    overflowY: 'auto',
    '& .MuiList-root': {
      padding: 0,
      '& .MuiListItem-root': {
        justifyContent: 'flex-start !important',
        fontSize: '14px',
        fontWeight: 600,
        color: '#444343',
        borderBottom: '1px solid #BBBBBB',
        padding: '4px',
      },
    },
  },
  listItemHover: {
    '&:hover': {
      backgroundColor: 'hsl(var(--primary-background))',
    },
  },
  listItemActive: {
    backgroundColor: 'hsl(var(--primary-background))',
  },
}));

export const InventoryLineAutocomplete = (
  props: IInventoryAutocomplete<Partial<Inventory>> & {
    index: number;
    inputRefs: React.MutableRefObject<HTMLInputElement[]>;
    clearOnSelect?: boolean;
    isLocalSearch?: boolean;
    handleSuggestionInputChange?: (x: string) => void;
  },
) => {
  const classes = useSalesOrderStyle();
  const {listInventory, listInventoryByCode, isLoading} = useInventoryAPI();
  const {
    id,
    index,
    selected,
    alreadyFilled,
    searchCodeOnly,
    autoFocus,
    inputRefs,
    onFocus,
    onSuggestionSelected,
    clearOnSelect,
  } = props;

  const inputRef = useRef<HTMLInputElement>(null);
  const suggestionsRefs = useRef<HTMLElement[]>([]);
  const popupState = usePopupState({variant: 'popover', popupId: 'autocomplete-inventory'});

  const [suggestionsInput, setSuggestionsInput] = useState<string>('');
  const [suggestions, setSuggestions] = useState<Partial<Inventory>[]>([]);
  const [suggestionsWidth, setSuggestionsWidth] = useState<number>();
  const [currentSelected, setCurrentSelected] = useState<Partial<Inventory> | null>(null);
  const [cursor, setCursor] = useState<number>(0);

  useEffect(() => {
    if (selected) {
      setSuggestions([selected]);
      setSuggestionsInput(searchCodeOnly ? selected?.InventoryCode || '' : selected?.ShortDescription || '');
    } else {
      setSuggestions([]);
      setSuggestionsInput('');
    }
    setCurrentSelected(selected);
  }, [selected]);

  useEffect(() => {
    if (inputRef.current) {
      inputRefs.current[index] = inputRef.current;
    }
    setSuggestionsWidth(inputRef.current ? inputRef.current.offsetWidth + (popupState.isOpen ? 52 : 28) : 0);
  }, [inputRef.current, popupState.isOpen]);

  useEffect(() => {
    if (props.isLocalSearch && popupState.isOpen) {
      popupState.close();
    }
  }, [popupState, props.isLocalSearch]);

  const getInventoryList = (input: string) => {
    if (!currentSelected) {
      if (searchCodeOnly) {
        listInventoryByCode({
          MaxResults: 100,
          StartRow: 1,
          IsSystem: false,
          ...(input ? {SearchString: input} : {}),
        }).then((res: IPagedActionResults<Inventory[]>) => {
          setSuggestions(res?.Value || []);
        });
      } else {
        listInventory({
          MaxResults: 100,
          ViewMode: 'Sales',
          IsSystem: false,
          ...(input ? {Search: input} : {}),
        }).then((res: IPagedActionResults<Inventory[]>) => {
          setSuggestions(res?.Value || []);
        });
      }
    }
  };

  const handleSuggestionSelected = (event: any, _selected: SuggestionSelectedEventData<Partial<Inventory>>) => {
    if (alreadyFilled) {
      if (!alreadyFilled.some((_m: number) => _m === _selected?.suggestion?.ID)) {
        onSuggestionSelected(event, _selected);
        setSuggestions([_selected.suggestion]);
        if (clearOnSelect) {
          setSuggestionsInput('');
        } else {
          setSuggestionsInput(_selected.suggestionValue);
        }
      }
    } else {
      onSuggestionSelected(event, _selected);
      setSuggestions([_selected.suggestion]);
      if (clearOnSelect) {
        setSuggestionsInput('');
      } else {
        setSuggestionsInput(_selected.suggestionValue);
      }
    }
  };

  const debouncedSave = useCallback(
    debounce((newValue: string) => {
      getInventoryList(newValue);
    }, 750),
    [suggestions],
  );

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSuggestionsInput(event.target.value);
    if (props.handleSuggestionInputChange) {
      props.handleSuggestionInputChange(event.target.value);
    }
    if (event.target.value.length > 2 && !props.isLocalSearch) {
      debouncedSave(event.target.value);
      popupState.open(event);
    }
  };

  const handleMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleIconClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    getInventoryList(suggestionsInput);
    popupState.open(inputRef.current);
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // User pressed the up arrow
    if (event.key === 'ArrowUp') {
      if (Number(cursor) === 0) {
        return;
      }
      const node = suggestionsRefs.current[Number(cursor) - 1];
      setCursor(cursor => Number(cursor) - 1);
      if (node && node.scrollIntoView) {
        node.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'center'});
      }
      return;
    }
    // User pressed the down arrow
    else if (event.key === 'ArrowDown') {
      if (Number(cursor) - 1 === suggestions.length) {
        return;
      }
      const node = suggestionsRefs.current[Number(cursor) + 1];
      setCursor(cursor => Number(cursor) + 1);
      if (node && node.scrollIntoView) {
        node.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'center'});
      }
      return;
    }
    // User pressed the enter key
    else if (event.key === 'Enter') {
      if (!selected) {
        const suggestion: SuggestionSelectedEventData<Partial<Inventory>> = {
          suggestion: suggestions[cursor],
          suggestionValue: searchCodeOnly
            ? suggestions[cursor]?.InventoryCode || ''
            : suggestions[cursor]?.ShortDescription || '',
          suggestionIndex: cursor,
          sectionIndex: 0,
          method: 'enter',
        };
        handleSuggestionSelected(event, suggestion);
        setCursor(0);
        popupState.close();
      }
      event.stopPropagation();
    }
    return;
  };

  const handleClickAway = () => {
    popupState.close();
  };

  return (
    <Fragment>
      <ClickAwayListener onClickAway={handleClickAway}>
        <StyledInputBase
          autoFocus={Boolean(autoFocus)}
          fullWidth
          id={id}
          inputRef={inputRef}
          placeholder={props.placeholder || (searchCodeOnly ? 'Search by code' : 'Search product by name or code')}
          value={suggestionsInput}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onFocus={onFocus}
          onBlur={handleClickAway}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                tabIndex={-1}
                onMouseDown={handleMouseDown}
                onClick={handleIconClick}
                data-autoid={`btnSearchInventory${index}`}
              >
                <SearchRoundedIcon fontSize="inherit" />
              </IconButton>
            </InputAdornment>
          }
          inputProps={{
            'data-autoid': searchCodeOnly ? `txtCodeAutocomplete${index}` : `txtInventoryAutocomplete${index}`,
          }}
        />
      </ClickAwayListener>
      <Popper
        {...bindPopper(popupState)}
        transition
        placement="bottom-start"
        style={{width: Number(suggestionsWidth)}}
        className={classes.suggestionsContainer}
        modifiers={{
          flip: {
            enabled: true,
          },
          offset: {
            offset: '-10,8',
          },
        }}
        data-autoid={searchCodeOnly ? `dlgCodeAutocomplete${index}` : `dlgInventoryAutocomplete${index}`}
      >
        {({TransitionProps}) => (
          <Fade {...TransitionProps} timeout={250}>
            <>
              {isLoading ? (
                <div className="jusitfy-center flex items-center p-2">
                  <LoadingIndicator isLoading={isLoading} size={'sm'} />
                </div>
              ) : null}
              {!isLoading && suggestions.length ? (
                <List component="ul">
                  {suggestions.map((suggestion, index) => {
                    const isSelected = alreadyFilled && alreadyFilled.some((_m: number) => _m === suggestion.ID);
                    return (
                      <ListItem
                        button
                        key={suggestion.ID}
                        disableGutters
                        className={clsx(classes.listItemHover, 'relative', {
                          [classes.listItemActive]: cursor === index,
                        })}
                        ref={(n: HTMLDivElement) => (suggestionsRefs.current[index] = n)}
                        onClick={event =>
                          handleSuggestionSelected(event, {
                            suggestion,
                            suggestionValue: `${suggestion.InventoryCode} ${suggestion.ShortDescription}`,
                            suggestionIndex: index,
                            sectionIndex: 0,
                            method: 'click',
                          })
                        }
                        data-autoid={`mnuInventory${index}`}
                      >
                        <span className="font-poppins text-xs font-normal">
                          <b>{suggestion.InventoryCode}</b> {suggestion.ShortDescription}
                        </span>
                        {isSelected ? (
                          <div className="absolute right-1 top-0 my-auto">
                            <CheckRoundedIcon fontSize="small" className="text-primary" />
                          </div>
                        ) : null}
                      </ListItem>
                    );
                  })}
                </List>
              ) : null}
              {!isLoading && !suggestions.length ? (
                <Typography className="text-center font-medium">No Results Found</Typography>
              ) : null}
            </>
          </Fade>
        )}
      </Popper>
    </Fragment>
  );
};
