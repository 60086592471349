import React from 'react';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import {IAttachment} from '../../../model/invoice/Invoice';
import {validateURL} from '../../../utils/validators';
import {SPopover} from '../../modals/modalSpendaMeterialUI';

interface ITransactionAttachments {
  Counter?: number;
  Attachments?: IAttachment[];
}

export const TransactionAttachments = (props: ITransactionAttachments) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const Attachments = props.Attachments || [];

  return (
    <div className="my-4 mx-2 relative">
      <div onClick={handleClick} className="p-2 bg-spenda-default rounded-default cursor-pointer">
        <AttachFileIcon htmlColor="white" style={{transform: 'rotate(45deg)'}} />
      </div>
      <span
        className="w-6 h-6 rounded-full p-0 absolute bg-spenda-error text-center text-white"
        style={{bottom: '2em', left: '1.25em'}}
      >
        {props.Counter}
      </span>

      <SPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className="flex flex-col px-3 pt-2">
          {Attachments.map((a: IAttachment, i: number) => (
            <a
              className="underline text-spenda-default"
              key={`attachment-${i}`}
              href={validateURL(a.URI || '') ? a.URI || '' : ''}
              target="_blank"
            >
              <div className="flex mb-2">
                <PictureAsPdfIcon />
                <span className="ml-1">{a.Title}</span>
              </div>
            </a>
          ))}
        </div>
      </SPopover>
    </div>
  );
};
