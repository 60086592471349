import * as React from 'react';
import useDigitInput, {InputAttributes} from 'react-digit-input';
import './SecurityCode.css';
import {css} from 'glamor';
import {Box, makeStyles} from '@material-ui/core';

export type CodeProps = {
  value: string;
  onChange: (newValue: string) => void;
  onEnter: () => void;
  is2SA?: boolean;
  newCodeDesign?: boolean;
  invalidCode?: boolean;
};

const DigitInputElement = React.forwardRef<
  HTMLInputElement,
  Omit<InputAttributes, 'ref'> & {
    autoFocus?: boolean;
    newCodeDesign?: boolean;
    invalidCode?: boolean;
  }
>(({...props}, ref) => {
  return (
    <label className="font-poppins">
      <div className={`${props.invalidCode ? 'invalidCode' : ''} digit text-xl`}>{props.value}</div>
      {!props.newCodeDesign && <hr />}
      <input {...props} ref={ref} inputMode="decimal" />
    </label>
  );
});

export const SecurityCode = (props: CodeProps) => {
  const listener = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      props.onEnter();
    }
  };

  const {newCodeDesign, invalidCode} = props;

  const useSecurityCodeStyles = makeStyles({
    newSecCodeWrap: {
      '& #sec-code label': {
        '& div': {
          border: '1px solid #EAEAEA',
          borderRadius: '6px',
          backgroundColor: '#fafafa',
          '&.invalidCode': {
            borderColor: '#c55d44',
            color: '#333',
          },
        },
        '&:focus-within': {
          '& div': {
            boxShadow: 'none',
            borderColor: '#1C78AD',
            color: '#1C78AD',
          },
        },
        '& input': {
          fontFamily: 'poppins',
        },
      },
    },
  });
  const classes = useSecurityCodeStyles();
  const width = css({
    width: '19rem',
  });

  const digits = useDigitInput({
    acceptedCharacters: /^[0-9]$/,
    length: props.is2SA ? 6 : 3,
    value: props.value,
    onChange: props.onChange,
  });

  return (
    <>
      {props.is2SA ? (
        <div className={`${newCodeDesign ? `${classes.newSecCodeWrap}` : ''}`}>
          <div id="sec-code" onKeyDown={listener} className={`${width} input-group flex justify-around`}>
            <DigitInputElement
              data-autoid="txtSecurityCode"
              autoFocus
              {...digits[0]}
              newCodeDesign={newCodeDesign}
              invalidCode={invalidCode}
            />
            <DigitInputElement {...digits[1]} newCodeDesign={newCodeDesign} invalidCode={invalidCode} />
            <DigitInputElement {...digits[2]} newCodeDesign={newCodeDesign} invalidCode={invalidCode} />
            <DigitInputElement {...digits[3]} newCodeDesign={newCodeDesign} invalidCode={invalidCode} />
            <DigitInputElement {...digits[4]} newCodeDesign={newCodeDesign} invalidCode={invalidCode} />
            <DigitInputElement {...digits[5]} newCodeDesign={newCodeDesign} invalidCode={invalidCode} />
          </div>
        </div>
      ) : (
        <div>
          <div
            id="sec-code"
            data-autoid="pnlRefCode"
            onKeyDown={listener}
            className={`input-group flex justify-around`}
          >
            <DigitInputElement autoFocus {...digits[0]} data-autoid="txtAccountVerificationCode" />
            <Box p={0.25}>
              <Box
                style={{
                  marginTop: '5px',
                  marginLeft: '5px',
                  marginRight: '5px',
                }}
              >
                <div className="text-xl text-primary">.</div>
              </Box>
            </Box>
            <DigitInputElement {...digits[1]} />
            <DigitInputElement {...digits[2]} />
          </div>
        </div>
      )}
    </>
  );
};

export default SecurityCode;
