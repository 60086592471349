import React, {useContext, useEffect, useState} from 'react';
import Skeleton from 'react-loading-skeleton';
import {ARTable} from '../ARTable';
import {Typography} from 'spenda-ui-react';
import {
  IDebitNoteDetails,
  IDebitNoteDetailsLine,
  IDebitNoteDetailsValue,
  IPurchaseInvoiceDetails,
  IPurchaseInvoiceDetailsLine,
  IPurchaseInvoiceDetailsValue,
} from '../../../model/requires-attention/StagedTransactions';
import {useSupplierTransactionsAPI} from '../../../services/useSupplierTransactionsAPI';
import moment from 'moment';
import {DatTypes, FinancialAdaptorsType} from '../../../model/constants/Constants';
import LoadingIndicator from '../../ui/LoadingIndicator';
import {roundTo} from '../../../utils/formatter';
import {IntegrationContext} from '../../../context/IntegrationContext';

interface IInvoiceFromAdapterTemplateProps {
  previewDocumentId?: number;
  datTypeId?: number;
}

export const InvoiceFromAdapterTemplate: React.FC<IInvoiceFromAdapterTemplateProps> = props => {
  const {previewDocumentId, datTypeId} = props;

  //States
  const [detailData, setDetailData] = useState<IPurchaseInvoiceDetailsValue | IDebitNoteDetailsValue>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  //APIs
  const {getPurchaseInvoiceDetails, getDebitNoteDetails} = useSupplierTransactionsAPI();

  //Context
  const {financialAdaptor} = useContext(IntegrationContext);

  //Constants
  const isMYOBAdaptor = financialAdaptor?.Name === FinancialAdaptorsType.MyObAccountRightLive;

  const getInvoices = async () => {
    try {
      if (!previewDocumentId || !datTypeId) return;
      if (datTypeId === DatTypes.StagedPurchaseInvoice) {
        setIsLoading(true);
        const response: IPurchaseInvoiceDetails = await getPurchaseInvoiceDetails(previewDocumentId);
        setDetailData(response.value);
        setIsLoading(false);
      } else if (datTypeId === DatTypes.StagedDebitNote) {
        setIsLoading(true);
        const response: IDebitNoteDetails = await getDebitNoteDetails(previewDocumentId);
        setDetailData(response.value);
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error fetching invoices:', error);
    }
  };

  useEffect(() => {
    getInvoices();
  }, [previewDocumentId, datTypeId]);

  const isPurchaseInvoice = (
    data: IPurchaseInvoiceDetailsValue | IDebitNoteDetailsValue,
  ): data is IPurchaseInvoiceDetailsValue => {
    return 'purchaseInvoiceID' in data;
  };

  const isDebitNote = (data: IPurchaseInvoiceDetailsValue | IDebitNoteDetailsValue): data is IDebitNoteDetailsValue => {
    return 'debitNoteID' in data;
  };

  const transactionColumns = [
    {
      title: '',
      key: 'sno',
      width: '5%',
      columClassName: 'font-poppins leading-loose !text-[10px] font-medium !text-black-800 text-left py-0 pl-2 pr-1',
      rowClassName: 'p-0 pl-2 pr-1',
      align: 'left',
      isSortable: false,
      rowRenderer: (_rowData: IPurchaseInvoiceDetailsLine | IDebitNoteDetailsLine, index: number) => (
        <Typography
          data-autoid={`lblSNo`}
          variant="xsmall"
          className="inline text-left text-[10px] font-normal leading-loose text-black-800"
        >
          {index + 1}
        </Typography>
      ),
    },
    {
      title: 'Description',
      key: 'descrription',
      width: '50%',
      columClassName: 'font-poppins leading-loose !text-[10px] font-medium !text-black-800 text-left pl-0 py-0 pr-1',
      rowClassName: 'p-0 pr-1',
      align: 'left',
      isSortable: false,
      rowRenderer: (rowData: IPurchaseInvoiceDetailsLine | IDebitNoteDetailsLine) => (
        <Typography
          data-autoid={`lblDescription`}
          variant="xsmall"
          className="inline text-left text-[10px] font-normal leading-loose"
        >
          {rowData?.description}
        </Typography>
      ),
    },
    {
      title: 'QTY',
      key: 'quantity',
      width: '15%',
      columClassName: 'font-poppins leading-loose !text-[10px] font-medium !text-black-800 text-left pl-0 py-0 pr-1',
      rowClassName: 'p-0 pr-1',
      align: 'right',
      isSortable: false,
      rowRenderer: (rowData: IPurchaseInvoiceDetailsLine | IDebitNoteDetailsLine) => (
        <Typography
          data-autoid={`lblQty`}
          variant="xsmall"
          className="inline text-left text-[10px] font-normal leading-loose"
        >
          {rowData?.quantity}
        </Typography>
      ),
    },
    {
      title: 'Unit Price',
      key: 'unitPrice',
      width: '15%',
      columClassName: 'font-poppins leading-loose !text-[10px] font-medium !text-black-800 text-left pl-0 py-0 pr-1',
      rowClassName: 'p-0 pr-1',
      align: 'right',
      isSortable: false,
      rowRenderer: (rowData: IPurchaseInvoiceDetailsLine | IDebitNoteDetailsLine) => (
        <Typography
          data-autoid={`lblUnitPrice`}
          variant="xsmall"
          className="inline text-left text-[10px] font-normal leading-loose"
        >
          {roundTo(rowData?.buyPriceEx, 2, true)}
        </Typography>
      ),
    },
    {
      title: 'Total',
      key: 'total',
      width: '15%',
      columClassName: 'font-poppins leading-loose !text-[10px] font-medium !text-black-800 text-left pl-0 py-0 pr-1',
      rowClassName: 'p-0 pr-1',
      align: 'right',
      isSortable: false,
      rowRenderer: (rowData: IPurchaseInvoiceDetailsLine | IDebitNoteDetailsLine) => (
        <Typography
          data-autoid={`lblTotal`}
          variant="xsmall"
          className="inline text-left text-[10px] font-normal leading-loose"
        >
          {roundTo(rowData?.lineTotalEx, 2, true)}
        </Typography>
      ),
    },
  ];

  const renderTableSkelton = (
    <table className="mb-[20px]">
      <thead>
        <tr className="border-t-spenda-dashbg bg-[#ddd]">
          <th className="text-left font-poppins text-base font-normal text-black-800">
            <Skeleton width={'90%'} />
          </th>
          <th className="text-left font-poppins text-base font-normal text-black-800">
            <Skeleton width={'90%'} />
          </th>
          <th className="text-center font-poppins text-base font-normal text-black-800">
            <Skeleton width={'90%'} />
          </th>
          <th className="text-center font-poppins text-base font-normal text-black-800">
            <Skeleton width={'90%'} />
          </th>
          <th className="text-center font-poppins text-base font-normal text-black-800">
            <Skeleton width={'90%'} />
          </th>
        </tr>
      </thead>
      <tbody className="border-b-spenda-dashbg">
        {[0, 1, 2, 3]?.map(tl => (
          <tr key={tl}>
            <td className="text-left font-poppins text-base font-normal text-black-800">
              <Skeleton width={'90%'} />
            </td>
            <td className="text-left font-poppins text-base font-normal text-black-800">
              <Skeleton width={'90%'} />
            </td>
            <td className="text-center font-poppins text-base font-normal text-black-800">
              <Skeleton width={'90%'} />
            </td>
            <td className="text-center font-poppins text-base font-normal text-black-800">
              <Skeleton width={'90%'} />
            </td>
            <td className="text-center font-poppins text-base font-normal text-black-800">
              <Skeleton width={'90%'} />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  return (
    <>
      {isLoading ? (
        <LoadingIndicator isLoading={isLoading} size="md" />
      ) : (
        <div className="flex h-auto w-full flex-col overflow-y-auto overflow-x-hidden bg-white px-2">
          <div className="flex h-auto w-full flex-row items-start justify-between border-b border-b-spenda-dashbg pb-3">
            <div className="mt-[80px] flex w-[50%] flex-col items-start justify-start">
              <Typography
                variant="paragraph"
                className="pb-1 pt-3 text-[20px] font-light text-black-800"
                data-autoid={`lblSupplierName`}
              >
                {detailData?.supplierName}
              </Typography>
              <Typography variant="xsmall" className=" text-black-800" data-autoid={`lblABN`}>
                ABN: {detailData?.abn}
              </Typography>
              <Typography variant="xsmall" className=" text-black-800" data-autoid={`lblPhone`}>
                Phone: {detailData?.supplierPhone}
              </Typography>
              <Typography variant="xsmall" className=" text-black-800" data-autoid={`lblEmail`}>
                Email: {detailData?.supplierEmail}
              </Typography>
              <Typography variant="xsmall" className=" text-black-800">
                Web: {detailData?.supplierWebSiteURL}
              </Typography>
            </div>
            <div className="flex w-[50%] flex-col items-end justify-end">
              <div className="flex flex-row items-center justify-between gap-4">
                <Typography variant="paragraph" className="pb-1 text-[18px] font-bold text-black-800">
                  Transaction Receipt
                </Typography>
              </div>
              <div
                data-autoid="txtTransactionStatus"
                className={`mb-2 flex h-[50px] w-[140px] flex-col items-center justify-center self-end rounded-[8px] border-2 border-spenda-labeltext text-center font-poppins text-black`}
              >
                <Typography variant="xsmall" className=" text-black-800">
                  Amount Due
                </Typography>
                <Typography variant="paragraph" className="font-medium text-black-800" data-autoid={`lblTotalInc`}>
                  {roundTo(detailData?.balance, 2, true)}
                </Typography>
              </div>
              {detailData && isPurchaseInvoice(detailData) && (
                <>
                  <Typography
                    variant="xsmall"
                    className=" pb-1 text-lg font-medium text-black-800"
                    data-autoid={`lblInvoice`}
                  >
                    Invoice #: {detailData?.refNumber}
                  </Typography>
                  <Typography variant="xsmall" className=" text-black-800">
                    Ref Number:{' '}
                    {isMYOBAdaptor
                      ? detailData?.supplierInvoiceRefNumber
                      : detailData?.vendorPurchaseInvoiceRefNumber ?? detailData?.refNumber}
                  </Typography>
                  <Typography variant="xsmall" className=" text-black-800" data-autoid={`lblInvoiceDate`}>
                    Invoice Date: {moment(detailData?.invoiceDate).format('DD MMM YYYY')}
                  </Typography>
                  <Typography variant="xsmall" className=" text-black-800" data-autoid={`lblDueDate`}>
                    Due Date: {moment(detailData?.dueDate).format('DD MMM YYYY')}
                  </Typography>
                </>
              )}
              {detailData && isDebitNote(detailData) && (
                <>
                  <Typography
                    variant="xsmall"
                    className=" pb-1 text-lg font-medium text-black-800"
                    data-autoid={`lblCreditNote`}
                  >
                    Credit Note #: {detailData?.refNumber}
                  </Typography>
                  <Typography variant="xsmall" className=" text-black-800" data-autoid={`lblRefNo`}>
                    Ref Number:{' '}
                    {isMYOBAdaptor
                      ? detailData?.supplierInvoiceRefNumber
                      : detailData?.vendorDebitNoteRefNumber ?? detailData?.refNumber}
                  </Typography>
                  <Typography variant="xsmall" className=" text-black-800" data-autoid={`lblDebitNoteDate`}>
                    Credit Note Date: {moment(detailData?.debitNoteDate).format('DD MMM YYYY')}
                  </Typography>
                </>
              )}
            </div>
          </div>
          <div className="mt-2.5 flex h-auto w-full flex-row items-start justify-between pb-[34px]">
            <div className="flex w-[50%] flex-col items-start justify-start">
              {detailData && isPurchaseInvoice(detailData) && (
                <>
                  <Typography variant="paragraph" className="pb-1 font-medium text-black-800">
                    Bill To: {detailData?.tenantName}
                  </Typography>
                  <Typography variant="xsmall" className=" text-black-800">
                    Contact: {detailData?.buyer}
                  </Typography>
                  <Typography variant="xsmall" className=" text-black-800" data-autoid={`lblBuyerEmail`}>
                    {detailData?.buyerEmail}
                  </Typography>
                </>
              )}
            </div>
            <div className="flex w-[50%] flex-row items-start justify-end gap-6">
              <div className="flex flex-col items-start">
                {detailData && isPurchaseInvoice(detailData) && (
                  <>
                    <Typography variant="paragraph" className="pb-1 font-medium text-black-800">
                      Deliver to:
                    </Typography>
                    <Typography variant="xsmall" className=" text-black-800" data-autoid={`lblShipAddress&City`}>
                      {detailData?.shipStreetAddress} {detailData?.shipCity}
                    </Typography>
                    <Typography
                      variant="xsmall"
                      className=" text-black-800"
                      data-autoid={`lblShipStateCountry&PostCode`}
                    >
                      {detailData?.shipState} {detailData?.shipCountry} {detailData?.shipPostCode}
                    </Typography>
                  </>
                )}
              </div>
              <div className="flex flex-col items-start">
                {detailData && isPurchaseInvoice(detailData) && (
                  <>
                    <Typography variant="paragraph" className="pb-1 font-medium text-black-800">
                      Billing:
                    </Typography>
                    <Typography variant="xsmall" className=" text-black-800" data-autoid={`lblBillAddress&City`}>
                      {detailData?.billStreetAddress} {detailData?.billCity}
                    </Typography>
                    <Typography
                      variant="xsmall"
                      className=" text-black-800"
                      data-autoid={`lblBillStateCountry&PostCode`}
                    >
                      {detailData?.billState} {detailData?.billCountry} {detailData?.billPostCode}
                    </Typography>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="mb-[40px] flex h-auto w-full flex-col">
            {false ? (
              <>
                {renderTableSkelton}
                <div className="flex flex-col justify-between self-end">
                  {[1, 2, 3].map(i => (
                    <Skeleton
                      key={i}
                      className="mb-1 flex min-w-[180px] flex-row justify-between self-end p-[6px]"
                      width={'20%'}
                    />
                  ))}
                </div>
              </>
            ) : (
              <>
                <ARTable
                  tableClass="!mx-0"
                  rows={detailData?.lines || []}
                  columns={transactionColumns}
                  isLoading={false}
                  dataAutoId="LineItem"
                  getRowDataAutoId={(rowData: IPurchaseInvoiceDetailsValue | IDebitNoteDetailsValue) =>
                    `rowLineItem${rowData?.refNumber}`
                  }
                />
                <div className="mb-1 flex min-w-[180px] flex-row justify-between self-end border-b border-b-[#e3e3e3] px-[6px]">
                  <Typography variant="xsmall" className=" text-black-800">
                    Sub Total
                  </Typography>
                  <Typography variant="xsmall" className=" text-black-800" data-autoid={`lblTotalEx`}>
                    {roundTo(detailData?.totalEx, 2, true)}
                  </Typography>
                </div>
                <div className="mb-1 flex min-w-[180px] flex-row justify-between self-end px-[6px] ">
                  <Typography variant="xsmall" className=" text-black-800">
                    Tax
                  </Typography>
                  <Typography variant="xsmall" className=" text-black-800" data-autoid={`lblTotaltax`}>
                    {roundTo(detailData?.totalTax, 2, true)}
                  </Typography>
                </div>
                <div className="mb-1 flex min-w-[180px] flex-row justify-between self-end bg-[#E1E1E1] p-[6px] ">
                  <Typography variant="xsmall" className=" font-bold text-black-800">
                    Total
                  </Typography>
                  <Typography variant="xsmall" className=" font-bold text-black-800" data-autoid={`lblTotal`}>
                    {roundTo(detailData?.totalInc, 2, true)}
                  </Typography>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};
