import * as React from 'react';
import {SVGProps} from 'react';

const SelectInvoicePlaceholder = (props: SVGProps<SVGSVGElement>) => (
  <svg width={305} height={174} xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <rect fill="#FFF" width={305} height={174} rx={5} />
      <circle className="stroke-primary" cx={20.5} cy={31.5} r={8} />
      <rect fillOpacity={0.2} fill="hsl(var(--primary))" x={37} y={14} width={99} height={7} rx={2} />
      <rect fillOpacity={0.2} fill="hsl(var(--primary))" x={37} y={27} width={62} height={8} rx={2} />
      <rect fillOpacity={0.2} fill="hsl(var(--primary))" x={37} y={41} width={62} height={7} rx={2} />
      <path fill="#F1F1F1" d="M5 56h294v1H5z" />
      <rect fillOpacity={0.2} fill="hsl(var(--primary))" x={216} y={17.5} width={62} height={26} rx={2} />
      <circle strokeOpacity={0.3} className="stroke-primary" cx={20.5} cy={85.5} r={8} />
      <rect fill="#EAEAEA" x={37} y={68} width={99} height={7} rx={2} />
      <rect fill="#EAEAEA" x={37} y={81} width={62} height={8} rx={2} />
      <rect fill="#EAEAEA" x={37} y={95} width={62} height={7} rx={2} />
      <path fill="#F1F1F1" d="M5 110h294v1H5z" />
      <rect fill="#EAEAEA" x={216} y={71.5} width={62} height={26} rx={2} />
      <path
        className="fill-primary"
        fillRule="nonzero"
        d="m22.214 86.267 9.185 3.882-2.505 2.237 4.687 5.274-1.99 1.736-4.687-5.274-2.42 1.789z"
      />
      <circle stroke="#CCC" cx={20.5} cy={139.5} r={8} />
      <rect fill="#EAEAEA" x={37} y={122} width={99} height={7} rx={2} />
      <rect fill="#EAEAEA" x={37} y={135} width={62} height={8} rx={2} />
      <rect fill="#EAEAEA" x={37} y={149} width={62} height={7} rx={2} />
      <rect fill="#EAEAEA" x={216} y={125.5} width={62} height={26} rx={2} />
      <path
        className="stroke-primary"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m16.21 31.833 3.336 2.667 4.896-6.624"
      />
    </g>
  </svg>
);

export default SelectInvoicePlaceholder;
