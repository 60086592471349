enum PurchasingActions {
  SET_PRODUCTS = 'SET_PRODUCTS',
  SET_CATEGORIES = 'SET_CATEGORIES',
  SET_DISPLAY_ORDER_POUP = 'SET_DISPLAY_ORDER_POUP',
  SET_REQUISITION = 'SET_REQUISITION',
  SAVE_REQUISITION_LINES = 'SAVE_REQUISITION_LINES',
  SEARCH_TRANSACTIONS = 'SEARCH_TRANSACTIONS',
  SET_REQUISITION_TYPE_ACTIVE = 'SET_REQUISITION_TYPE_ACTIVE',
  SET_PRODUCT_TO_ADD = 'SET_PRODUCT_TO_ADD',
  SET_PURCHASE_ORDER = 'SET_PURCHASE_ORDER',
  SET_INVOICES_TO_PAY = 'SET_INVOICES_TO_PAY',
  SET_PAYMENT_PROCESS_STATUS = 'SET_PAYMENT_PROCESS_STATUS',
  SET_PAYMENT_OUTCOME = 'SET_PAYMENT_OUTCOME',
  RESET_PAYMENT_PROCESS = 'RESET_PAYMENT_PROCESS',
  SET_PAYMENT_COUNTS = 'SET_PAYMENT_COUNTS',
  SET_CREDITS_TO_APPLY = 'SET_CREDITS_TO_APPLY',
  SET_CREDITS_STATEMENT_SUMMARY = 'SET_CREDITS_STATEMENT_SUMMARY',
  SET_LOYALTY_POINTS_TOGGLE = 'SET_LOYALTY_POINTS_TOGGLE',
  SET_CONVERSATIONS = 'SET_CONVERSATIONS',
  SET_BATCH_RESPONSE = 'SET_BATCH_RESPONSE',
  SET_CREDIT_AND_CLAIMS_LIST = 'SET_CREDIT_AND_CLAIMS_LIST',
}

export default PurchasingActions;
