import React, {useContext, useState, useEffect} from 'react';
import {Formik, FormikHelpers} from 'formik';

import * as Yup from 'yup';
import {Button, Dialog, DialogBody, DialogFooter, DialogHeader, Typography, IconButton} from 'spenda-ui-react';
import {Grid} from '@material-ui/core';

import useTenantAPI from '../../services/useTenantAPI';
import {ITenant, LogoTypeEnum, ILogo} from '../../model/Tenant';
import {Toast} from '../../utils/Toast';
import {validateABN} from '../../utils/validators';
import AppContext from '../../context/app/appContext';
import {STabsDialog} from '../tabs/STabsDialog';
import {CreateNewCustomerAccountForm} from '../form/CreateNewCustomerAccountForm';
import {useCustomersAPI} from '../../services/useCustomersAPI';
import {LinkToAccountCustomer} from './LinkToAccountCustomerLinkForm';
import {CustomerClassesAutocomplete} from '../customer/CustomerAutocomplete';
import {ICustomer} from '../../model/customer/Customer';
import {BusinessDetailsFormValues} from '../onboarding/BusinessDetails';
import {CloseCross} from '../../assets/svg/CloseCross';

interface ILinkOperationalCustomerDialogProps {
  setShowLinkOperationalCustomerDialog?: Function;
  onClose?: (f: boolean) => boolean;
  open?: boolean;
  customerId?: number;
  getCustomer?: Function;
  linkClick?: () => void;
  responseCustomerLinking?: any;
  customerDataGeneric?: Function;
  customerLink?: boolean;
  customerLinked?: boolean;
  setChangeNewCustomer?: boolean;
  setCustomer: (customer: ICustomer) => void;
}
export const LinkOperationalCustomerDialog: React.FunctionComponent<any> = (
  props: ILinkOperationalCustomerDialogProps,
) => {
  const appCtx = useContext(AppContext);
  const {responseCustomerLinking, linkClick} = props;

  const newTenant: BusinessDetailsFormValues = {
    companyName: '',
    abn: '',
    companyPhone: '',
    website: '',
    mySpendaUrl: '',
    companyLogo: undefined,
    companyIcon: undefined,
    companyBanner: undefined,
  } as BusinessDetailsFormValues;

  const tenantAPI = useTenantAPI();

  const {getCustomer} = useCustomersAPI();
  const [customerData, setCustomerData] = useState<ICustomer>();
  const {list: getCustomerList} = useCustomersAPI();

  const onSaveTenant = async (companyDetails: BusinessDetailsFormValues) => {
    if (!companyDetails) {
      return;
    }

    let isSaved = false;

    const tenantDetails = {
      Name: companyDetails.companyName,
      ABN: companyDetails.abn,
      Phone: companyDetails.companyPhone,
      WebSiteURL: companyDetails.website,
      MarketPlacePath: companyDetails.mySpendaUrl,
      Logos: [] as ILogo[],
    } as ITenant;

    if (companyDetails.companyBanner || companyDetails.companyBanner === '') {
      tenantDetails.Logos.push({
        URI: companyDetails.companyBanner || '',
        ThumbnailURL: companyDetails.companyBannerThumb || '',
        LogoTypeID: LogoTypeEnum.Banner,
        GUID: '',
      });
    }
    if (companyDetails.companyIcon || companyDetails.companyIcon === '') {
      tenantDetails.Logos.push({
        URI: companyDetails.companyIcon || '',
        ThumbnailURL: companyDetails.companyIconThumb || '',
        LogoTypeID: LogoTypeEnum.Icon,
        GUID: '',
      });
    }
    if (companyDetails.companyLogo || companyDetails.companyLogo === '') {
      tenantDetails.Logos.push({
        URI: companyDetails.companyLogo || '',
        ThumbnailURL: companyDetails.companyLogoThumb || '',
        LogoTypeID: LogoTypeEnum.Logo,
        GUID: '',
      });
    }
    isSaved = (await tenantAPI.saveTenantDetails(tenantDetails)).IsSuccess;

    if (isSaved) {
      appCtx.setTenant(tenantDetails);
      Toast.info('Business details have been saved.');
      if (props.onClose) props.onClose(true);
    }
  };

  const onSubmit = (values: BusinessDetailsFormValues, helpers: FormikHelpers<BusinessDetailsFormValues>): void => {
    onSaveTenant(values).finally(() => {
      helpers.setSubmitting(false);
    });
  };

  const validationSchema = Yup.object({
    companyName: Yup.string()
      .required('Business Name is required.')
      .max(100, 'Last Name cannot be longer than 100 characters.'),
    companyPhone: Yup.string().required('Business Phone is required.'),
    abn: Yup.string()
      .test('abn', 'Please provide a valid ABN.', function (value: string | undefined) {
        if (!value) {
          return true;
        }
        let result = validateABN(value);
        if (!result) {
          return true;
        } else {
          return result.valid;
        }
      })
      .required('ABN is required'),
  });

  const closeDialog = () => {
    setCustomerTab(0);
    if (props.onClose) {
      props.onClose(true);
    }
  };

  useEffect(() => {
    if (props.customerId) {
      getCustomer(props.customerId).then(
        res => {
          setCustomerData(res);
        },
        (_error: string) => {
          if (props?.setShowLinkOperationalCustomerDialog) {
            props?.setShowLinkOperationalCustomerDialog?.(false);
          }
        },
      );
    }
  }, [props.customerId]);

  const [customerTab, setCustomerTab] = useState<number>(0);

  return (
    <Formik initialValues={newTenant} onSubmit={onSubmit} validationSchema={validationSchema}>
      {
        <Dialog
          open={props.open || false}
          className="!absolute !min-w-[900px] justify-center px-1 py-2"
          size="md"
          handler={() => {}}
        >
          <DialogHeader className=" justify-center border-b border-[#EAEAEA]" data-autoid="txtDefinePostingBehaviour">
            <Typography className="font-medium" variant="h2">
              Define Posting Behaviour for {customerData?.CompanyName}
            </Typography>
            <IconButton
              aria-label="close"
              className="!absolute right-2 top-4"
              onClick={() => closeDialog()}
              variant="text"
              color="gray"
              data-autoid="btnCloseLinkOperationalCustomerDialog"
            >
              <CloseCross />
            </IconButton>
          </DialogHeader>
          <DialogBody className="static !max-h-[650px] justify-center overflow-x-auto px-10 py-2">
            <Typography variant="h2" className="mb-5  font-medium text-[#4D4D4D]">
              Operational Customer Details
            </Typography>
            <Grid container spacing={1}>
              <Grid item sm={2} container className="flex">
                <Typography variant="h3" className="pb-2 font-normal text-[#333333]">
                  Name:
                </Typography>
              </Grid>
              <Grid item sm={3}>
                <Typography variant="paragraph" className="pb-4 font-medium text-[#999999]">
                  {customerData?.Name || '-'}
                </Typography>
              </Grid>
              <Grid item sm={1} />
              <Grid item sm={2} container className="flex">
                <Typography variant="h3" className="pb-2 font-normal text-[#333333]">
                  Email:
                </Typography>
              </Grid>
              <Grid item sm={3}>
                <Typography variant="paragraph" className="pb-4 font-medium text-[#999999]">
                  {customerData?.PrimaryContactEmailAddress || '-'}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item sm={2} container className="flex">
                <Typography variant="h3" className="pb-2 font-normal text-[#333333]">
                  Billing Address:
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <Typography variant="paragraph" className="pb-4 font-medium text-[#999999]">
                  {customerData?.BillingAddressString || '-'}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item sm={2} container className="flex">
                <Typography variant="h3" className="pb-2 font-normal text-[#333333]">
                  Phone No:
                </Typography>
              </Grid>
              <Grid item sm={3}>
                <Typography variant="paragraph" className="pb-4 font-medium text-[#999999]">
                  {customerData?.PrimaryContactPhone || '-'}
                </Typography>
              </Grid>
              <Grid item sm={1} />
              <Grid item sm={2} container className="flex">
                <Typography variant="h3" className="pb-2 font-normal text-[#333333]">
                  Source:
                </Typography>
              </Grid>
              <Grid item sm={3}>
                <Typography variant="paragraph" className="pb-4 font-medium text-[#999999]">
                  {customerData?.CreatedWith || '-'}
                </Typography>
              </Grid>
            </Grid>
            <Typography className="mb-[30px] mt-5 font-normal text-error ">
              This Operational Customer is NOT currently assigned to a <b>Customer Class.</b>
            </Typography>
            <hr className="my-5 border-[#F1F1F1]" />
            <Typography variant="h2" className="mb-5 font-medium text-[#4D4D4D]">
              Choose ONE of these three options
            </Typography>

            <STabsDialog
              value={customerTab}
              handleChange={newValue => setCustomerTab(newValue)}
              tabs={[
                {
                  label: 'Link To Account Customer',
                  content: (
                    <LinkToAccountCustomer
                      {...props}
                      setCustomerTab={setCustomerTab}
                      getCustomerList={getCustomerList}
                      customerData={customerData}
                      response={responseCustomerLinking}
                      linkClick={linkClick}
                    />
                  ),
                },
                {
                  label: 'Create New Account Customer',
                  content: (
                    <CreateNewCustomerAccountForm
                      {...props}
                      setShowLinkOperationalCustomerDialog={props?.setShowLinkOperationalCustomerDialog}
                      setCustomerTab={setCustomerTab}
                      customerData={customerData}
                      addCustomer={props.customerLink}
                      setChangeNewCustomer={props.setChangeNewCustomer}
                    />
                  ),
                },
                {
                  label: 'Assign A Generic Class',
                  content: (
                    <AssignAGenericClass
                      {...props}
                      setCustomerTab={setCustomerTab}
                      customerDataGeneric={props.customerDataGeneric}
                      setShowLinkOperationalCustomerDialog={props?.setShowLinkOperationalCustomerDialog}
                      data-autoid="txtAssignAGenericClass"
                    />
                  ),
                },
              ]}
            ></STabsDialog>
          </DialogBody>
          <DialogFooter className="mx-4 justify-between rounded-lg bg-spenda-footerBg py-2">
            <Button onClick={closeDialog} variant="outlined" className="bg-white">
              Cancel
            </Button>
          </DialogFooter>
        </Dialog>
      }
    </Formik>
  );
};
const AssignAGenericClass = (props: any) => {
  const handleSave = async () => {
    if (props.customerId && props.customerClassFilter?.ID) {
      await props.linkGeneric(props.customerId, props.customerClassFilter?.ID ? props.customerClassFilter?.ID : 0);
    }
    props.handleFilter(null);
  };

  return (
    <>
      <Typography variant="h2" className="mb-5 mt-3 font-medium text-[#4D4D4D]">
        Choose A Generic Customer Class for this Operational Customer
      </Typography>
      <Grid container spacing={1}>
        <Grid item sm={2} className="flex items-end">
          <Typography variant="small" className="pb-2 font-medium text-[#333333]">
            Customer Class:
          </Typography>
        </Grid>
        <Grid item sm={4}>
          <CustomerClassesAutocomplete
            styles={{paddingBottom: '12px'}}
            PostingBehaviour={'Generic'}
            customerClassType={true}
            selected={props.customerClassFilter ? props.customerClassFilter : null}
            clearClick={() => props.handleFilter(null)}
            onSuggestionSelected={(e, selected) => props.handleFilter(selected.suggestion)}
          />
        </Grid>
      </Grid>
      <Typography variant="small" className="mb-20 mt-3 font-normal text-gray-400">
        *This will assign the operational customer to a Customer Class with Generic posting behaviour.
      </Typography>
      <Button
        type="submit"
        form="company-details-form"
        className="!absolute bottom-[16px] right-9"
        onClick={handleSave}
        disabled={props.customerClassFilter?.ID ? false : true}
      >
        Save
      </Button>
    </>
  );
};
