import useHttp from '../hooks/useHttp';
import {IEmailTemplate, ILastNotificationSendDetails, IPreviewEmailTemplate} from '../model/statements/Statements';

export const useAutomatedStatementsAPI = () => {
  const {isLoading, GET, POST, DELETE} = useHttp();

  const getEmailTemplate = (query: IGetEmailTemplateQuery): Promise<IEmailTemplate[]> => {
    return GET(`Spenda/Alerts/Alerts`, query);
  };

  const createAndUpdateAutomatedCustomerStatements = (
    payload: ICreateAndUpdateAutomatedCustomerStatementsPayload,
  ): Promise<any> => {
    return POST(`Spenda/Payments/AccountsReceivable/Supplier/AutomatedCustomerStatement`, payload);
  };

  const createEmailTemplate = (payload: ICreateEmailTemplatePayload): Promise<IEmailTemplate> => {
    return POST(`Spenda/Alerts/Alerts`, payload);
  };

  const getLastNotficationSendDetails = (query?: {
    accountCustomerID?: number;
  }): Promise<ILastNotificationSendDetails[]> => {
    return GET(`Spenda/Payments/AccountsReceivable/LastCustomerStatement`, query);
  };

  const sendMail = (payload: ISendMailPayload): Promise<any> => {
    return POST(`Spenda/Payments/AccountsReceivable/Supplier/ProcessCustomerStatements`, payload);
  };

  const previewEmailTemplate = (payload: IPreviewEmailTemplatePayload): Promise<IPreviewEmailTemplate[]> => {
    return POST(`Spenda/Alerts/Preview`, payload);
  };

  const deleteEmailTemplate = (alertId: number): Promise<IPreviewEmailTemplate[]> => {
    return DELETE(`Spenda/Alerts/Alerts/${alertId}`);
  };

  return {
    getEmailTemplate,
    createAndUpdateAutomatedCustomerStatements,
    createEmailTemplate,
    getLastNotficationSendDetails,
    sendMail,
    previewEmailTemplate,
    deleteEmailTemplate,
    isLoading,
  };
};

export interface ICreateAndUpdateAutomatedCustomerStatementsPayload {
  automatedCustomerStatementID?: number;
  scheduledDay: number;
  alertID: number;
  customerClassID: number;
}

export interface IGetEmailTemplateQuery {
  datTypeID: number;
  websiteID: number;
  alertID?: number;
  isMasterTemplate?: boolean;
}

export interface ICreateEmailTemplatePayload {
  alertID?: number;
  alertType: string;
  datTypeID: number;
  websiteID: number;
  name: string;
  description: string;
  subject: string;
  template: string;
  templateDataType: string;
  attachmentTemplate?: any;
  attachmentTemplateDataType?: any;
  colourCode: string;
}

export interface ISendMailPayload {
  alertID: number;
  accountCustomerIDs?: number[];
  invoiceID?: number;
  timelineGroup?: number;
  timelineSubGroupID?: number;
  asAtDate?: string;
}

export interface IPreviewEmailTemplatePayload {
  alertType: number;
  alertID: number;
  request: {
    AccountCustomerID: number;
    InvoiceID?: number;
    TimelineGroup?: number;
    TimelineSubGroupID?: number;
    AsAtDate?: string;
  };
}
