import * as React from 'react';
const AlertIcon = (props: SVGProps) => (
  <svg width={26} height={26} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M14.743 11.438L9.187 1.864a1.96 1.96 0 00-3.376 0L.256 11.438a1.866 1.866 0 000 1.886c.346.604.991.974 1.687.97h11.112a1.929 1.929 0 001.69-.97c.34-.583.34-1.304-.002-1.886zm-.926 1.351v.004a.866.866 0 01-.761.429H1.944a.866.866 0 01-.762-.433.794.794 0 010-.813L6.74 2.4a.887.887 0 011.52 0l5.556 9.576c.15.25.15.563.002.813z"
      fill="#C68A19"
    />
    <path
      d="M7.5 4.114a.536.536 0 00-.536.536v4.286a.536.536 0 101.071 0V4.65a.536.536 0 00-.535-.536zM7.5 10.543a.536.536 0 00-.536.536v.535a.536.536 0 101.071 0v-.535a.536.536 0 00-.535-.536z"
      fill="#C68A19"
    />
  </svg>
);
export default AlertIcon;
