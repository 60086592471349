import React, {Component} from 'react';
import Error from './Error';
import {Unauthorized} from '../components/layout/Unauthorized';

export interface IFallbackComponentProps {
  error: Error | null;
  resetErrorBoundary: () => void;
}

interface ErrorBoundaryProps extends React.PropsWithChildren {
  fallback?: React.ReactNode;
  onReset?: () => void;
  fallbackComponent?: React.ComponentType<IFallbackComponentProps>;
}

type IState = {
  hasError: boolean;
  error: Error | null;
  unAuthorize: boolean;
};

const ErrorContext = React.createContext({setUnAuthorize: () => {}});

class ErrorBoundary extends Component<ErrorBoundaryProps, IState> {
  state = {hasError: false, error: null, unAuthorize: false};

  static getDerivedStateFromError(error: Error) {
    return {hasError: true, error};
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  resetErrorBoundary = () => {
    this.setState({hasError: false, error: null});
    this.props.onReset?.();
  };

  setUnAuthorize = () => {
    this.setState({unAuthorize: true});
  };

  renderContent = () => {
    if (this.state.unAuthorize) {
      return <Unauthorized />;
    }
    if (this.state.hasError) {
      if (this.props.fallbackComponent) {
        return <this.props.fallbackComponent resetErrorBoundary={this.resetErrorBoundary} error={this.state.error} />;
      }
      return this.props.fallback || <Error resetErrorBoundary={this.resetErrorBoundary} />;
    }

    return this.props.children;
  };

  render() {
    return <ErrorContext.Provider value={{setUnAuthorize:this.setUnAuthorize}}>{this.renderContent()}</ErrorContext.Provider>;
  }
}

export const useErrorContext = () => React.useContext(ErrorContext);

export {ErrorBoundary};
