import React, {useState, useRef} from 'react';
import {Formik, FormikProps} from 'formik';
import * as Yup from 'yup';
import {omit} from 'lodash';

import {IAccounts} from '../../model/accounts/Accounts';
import {AccountForm} from '../form/AccountForm';
import useAccountAPI from '../../services/useAccountAPI';
import {AlertDialogSlideV2} from './AlertDialogSlideV2';
import {IconButton, Typography} from 'spenda-ui-react';
import {CloseCross} from '../../assets/svg/CloseCross';

interface IAccountDialogProps {
  account: IAccounts;
  open?: boolean;
  onClose: (newAccount?: IAccounts) => void;
}

const validationSchema = Yup.object({
  Code: Yup.string().required('Account Code is required.').max(50, 'Account Code cannot be longer than 50 characters.'),
  Name: Yup.string().required('Account Name is required.').max(50, 'Account Name cannot be longer than 50 characters.'),
  Description: Yup.string().max(255, 'Account Description cannot be longer than 255 characters.'),
});

export const AccountDialog: React.FunctionComponent<IAccountDialogProps> = ({
  account,
  open,
  onClose,
}: IAccountDialogProps) => {
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState<boolean>(false);

  const {createAccount} = useAccountAPI();

  const formRef = useRef<FormikProps<IAccounts>>(null);

  const handleCloseClick = (props: FormikProps<IAccounts>, cancelClick: boolean = true) => {
    if (cancelClick && props.dirty) {
      setOpenConfirmationDialog(true);
    } else {
      onClose();
    }
  };

  const onSave = (values: IAccounts): Promise<void> => {
    const _values = {...values};
    if (_values.Class === 'Revenue') {
      omit(_values, ['Class']);
    }
    return createAccount(_values).then((res: IAccounts) => {
      onClose(res);
    });
  };

  return (
    <>
      {openConfirmationDialog && (
        <AlertDialogSlideV2
          size="sm"
          title="Discard Changes"
          actions={[
            {label: 'Cancel', color: 'error', action: () => setOpenConfirmationDialog(false), variant: 'outlined'},
            {
              label: 'Discard',
              color: 'primary',
              action: () => {
                if (onClose) {
                  setOpenConfirmationDialog(false);
                  onClose();
                  if (formRef && typeof formRef.current?.resetForm === 'function') {
                    const resetForm = formRef.current?.resetForm;
                    resetForm();
                  }
                }
              },
            },
          ]}
          dialogActionsAlignment="justify-between"
        >
          <Typography className="pb-3 text-center font-medium">
            You have unsaved changes, are you sure you want to discard changes?
          </Typography>
        </AlertDialogSlideV2>
      )}
      {open && (
        <Formik
          innerRef={formRef}
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={account}
          onSubmit={onSave}
        >
          {props => (
            <AlertDialogSlideV2
              dialogClassess="!mx-5 !w-full !max-w-[96%] xl:!max-w-[950px]"
              dialogBodyClassess="h-[350px] flex-grow px-11 py-7"
              title={props.values.ID ? 'Edit Account' : 'Add New Account'}
              headingClassess="font-semibold justify-center"
              headerChildren={
                <IconButton
                  variant="text"
                  onClick={() => handleCloseClick(props, false)}
                  className="!absolute right-2 active:bg-transparent"
                  ripple={false}
                  name="btnClose"
                >
                  <CloseCross />
                </IconButton>
              }
              actions={[
                {label: 'Cancel', color: 'error', action: () => handleCloseClick(props), variant: 'outlined'},
                {
                  label: props.values.ID ? 'Save Account' : 'Add Account',
                  action: () => props.handleSubmit(),
                  type: 'submit',
                  disabled: props.isSubmitting || !props.dirty,
                },
              ]}
              dialogActionsAlignment="justify-between"
            >
              <AccountForm {...props} />
            </AlertDialogSlideV2>
          )}
        </Formik>
      )}
    </>
  );
};
