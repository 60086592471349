import useHttp from '../hooks/useHttp';
import {IBaseResponseV3} from '../model/accounts-receivable/AccountsReceivable';
import {ITransactionHistoryResponse} from '../model/transaction-history/TransactionHistory';

export const useTransactionHistoryApi = () => {
  const {GET, isLoading} = useHttp();

  const getTransactionHistory = (
    query?: IGetTransactionHistoryQuery,
  ): Promise<IBaseResponseV3<ITransactionHistoryResponse>> => {
    return GET(`Spenda/Lending/Transactions/Paid`, query);
  };

  return {
    getTransactionHistory,
    isLoading,
  };
};

export interface IGetTransactionHistoryQuery {
  searchString?: string;
  searchField?: string;
  dateStart?: string;
  dateEnd?: string;
}
