import React from 'react';
import {Card, CardBody, Chip, Typography} from 'spenda-ui-react';
import EditCustomerIcon from '../../../assets/svg/EditCustomerIcon';

interface IServiceLocationCard {
  placeholder: string;
  chipValue: string;
  className?: string;
  handleOnClick: () => void;
  value?: string;
  data_autoid?: string;
}

export const ServiceLocationCard = (props: IServiceLocationCard) => {
  const {placeholder, chipValue, value, className, handleOnClick, data_autoid} = props;

  return (
    <Card
      className={`cursor-pointer ${className} ${
        !value?.trim() ? 'items-center justify-center' : ''
      } mt-[21px] h-[112px] min-w-[230px] max-w-[230px] rounded-md border border-primary shadow-none`}
      data-autoid={`${data_autoid}`}
    >
      <CardBody className="flex flex-col items-start p-[2px]">
        {value?.trim() && (
          <Chip
            value={chipValue}
            color="primary"
            className="h-[20px] w-auto px-3 py-[3px] text-xs font-medium leading-snug"
          ></Chip>
        )}
        <div className="relative w-full">
          {value?.trim() ? (
            <>
              <Typography
                className={`${
                  !value?.trim() ? '' : 'p-2.5'
                } mx-auto line-clamp-3 inline-block h-[76px] text-center font-semibold text-primary`}
                style={{display: '-webkit-box'}}
              >
                {value}
              </Typography>
              <EditCustomerIcon className="absolute -bottom-[6px] right-[6px]" onClick={handleOnClick} />
            </>
          ) : (
            <p className="text-center font-semibold text-primary" onClick={handleOnClick}>
              {placeholder}
            </p>
          )}
        </div>
      </CardBody>
    </Card>
  );
};
