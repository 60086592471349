import React, {useContext, useState, useEffect} from 'react';
import AppContext from '../../context/app/appContext';
import {PaymentWidgetScope} from '../../model/constants/Constants';
import {ICreditCardT, IPaymentMethodTypes, ITenantSupplierPaymentMethod} from '../../model/payment/PaymentMethod';
import {ISupplierMarket} from '../../model/SupplierMarket';
import {IAccountResponse} from '../../services/useSpendaPaymentServicesAPI';
import {PaymentWidgetModal, PaymentWidgetPaymentSteps} from '../pay-by-link/payment-widget/PaymentWidget';
import {usePaymentMethods} from '../pay-by-link/payment-widget/usePaymentMethods';

interface IManagePaymentMethodsProps {
  isBPSP?: boolean;
  linkedSupplierId?: number;
  marketplaceSupplier?: ISupplierMarket;
  paymentMethodToEdit?: ITenantSupplierPaymentMethod;
  open: boolean;
  widgetScope: PaymentWidgetScope;
  widgetManageMethods73098?: boolean;
  onClose: () => void;
  onSuccess: () => void;
  paymentAuth72488?: boolean;
  capricornDemo77857?: boolean;
  availablePaymentMethodTypes?: IPaymentMethodTypes[];
  widgetStep?: PaymentWidgetPaymentSteps;
  bpspCardDetails?: IAccountResponse;
  scheduledPaymentsV2?: boolean;
  onboardingUpgradeV1: boolean;
  storedPaymentOptions?: ITenantSupplierPaymentMethod[];
  isAccountsPayable?: boolean;
}

export const ManagePaymentMethods = (props: IManagePaymentMethodsProps) => {
  const {
    isBPSP,
    linkedSupplierId,
    marketplaceSupplier,
    paymentMethodToEdit: paymentMethodToEditProps,
    open,
    widgetScope,
    widgetStep,
    widgetManageMethods73098,
    onClose,
    onSuccess,
    paymentAuth72488,
    capricornDemo77857,
    availablePaymentMethodTypes,
    bpspCardDetails,
    scheduledPaymentsV2,
    onboardingUpgradeV1,
    isAccountsPayable,
  } = props;

  const [paymentMethodToEdit, setPaymentMethodToEdit] = useState<ITenantSupplierPaymentMethod | undefined>(
    paymentMethodToEditProps
  );
  const [paymentMethodToVerify, setPaymentMethodToVerify] = useState<IAccountResponse | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (paymentMethodToEditProps && widgetScope === PaymentWidgetScope.VERIFY_PAYMENT_METHOD) {
      getSPSPaymentMethod();
    }
  }, []);

  const {user} = useContext(AppContext);

  const {
    fetchSPSAccount,
    initiateVerification,
    onRetryVerification,
    onVerifyCode,
    removeCreditCard,
    saveCreditCard,
    updateAccountDetails,
    updatePaymentMethodDetails,
    getPaymentMethodAuthorisationRules,
    savePaymentAuthorisationRules,
    approvePaymentAuthorisation,
    rejectPaymentAuthorisation,
    getPaymentMethodAuthorisationDetails,
  } = usePaymentMethods({linkedSupplierId, merchantGlobalID: marketplaceSupplier?.GlobalID});

  const onSaveCreditCard = (card: ICreditCardT) => {
    const isBPSPMerchant = isBPSP;
    return saveCreditCard(card, isBPSPMerchant);
  };

  const getSPSPaymentMethod = async () => {
    if (!paymentMethodToEditProps) {
      return;
    }

    setLoading(true);

    const SPSBankAccount = await fetchSPSAccount(paymentMethodToEditProps?.PaymentAccountGUID!);
    setPaymentMethodToVerify(SPSBankAccount);
    setPaymentMethodToEdit(undefined);

    setLoading(false);
  };

  return (
    <>
      <PaymentWidgetModal
        isLinkedToLendingProvider={marketplaceSupplier?.IsLendingProvider}
        userEmailAddress={user?.UserName}
        fetchSPSAccount={fetchSPSAccount}
        initiateVerification={initiateVerification}
        onRetryVerification={onRetryVerification}
        onClose={onClose}
        onDone={onSuccess}
        onVerifyCode={onVerifyCode}
        open={open && !loading}
        removeCreditCard={removeCreditCard}
        saveCreditCard={onSaveCreditCard}
        bpspCardDetails={bpspCardDetails}
        updateAccountDetails={updateAccountDetails}
        updatePaymentMethodDetails={updatePaymentMethodDetails}
        tenantName={user?.TenantName}
        userID={user?.UserID}
        marketplaceSupplier={marketplaceSupplier}
        widgetScope={widgetScope}
        widgetStep={widgetStep}
        widgetManageMethods73098={widgetManageMethods73098}
        isBPSP={isBPSP}
        paymentMethodToEdit={paymentMethodToEdit}
        paymentMethodToVerify={paymentMethodToVerify}
        getPaymentMethodAuthorisationRules={getPaymentMethodAuthorisationRules}
        savePaymentAuthorisationRules={savePaymentAuthorisationRules}
        paymentAuth72488={paymentAuth72488}
        getPaymentMethodAuthorisationDetails={getPaymentMethodAuthorisationDetails}
        rejectPaymentAuthorisation={rejectPaymentAuthorisation}
        approvePaymentAuthorisation={approvePaymentAuthorisation}
        capricornDemo77857={capricornDemo77857}
        availablePaymentOptions={marketplaceSupplier?.AvailablePaymentMethods}
        availablePaymentMethodTypes={availablePaymentMethodTypes}
        scheduledPaymentsV2={scheduledPaymentsV2}
        isAPOnboardingV1={onboardingUpgradeV1}
        storedPaymentOptions={props.storedPaymentOptions}
        isAccountsPayable={isAccountsPayable}
      />
    </>
  );
};
