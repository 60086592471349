import * as React from 'react';
export const DocRejected = (props: any) => (
  <svg width={14} height={14} fill="none" {...props}>
    <path
      fill="#C55D44"
      fillRule="evenodd"
      d="M6.67 0a4.059 4.059 0 0 1 2.888 1.196l2.032 2.033a4.055 4.055 0 0 1 1.197 2.887v2.12a.583.583 0 0 1-1.166.03l-.001-.03v-2.12c0-.096-.018-.188-.027-.283h-2.89a1.75 1.75 0 0 1-1.75-1.75V1.194c-.094-.009-.187-.027-.282-.027H4.037a1.75 1.75 0 0 0-1.75 1.75v8.166c0 .967.783 1.75 1.75 1.75h4.06a.583.583 0 1 1 0 1.167h-4.06a2.92 2.92 0 0 1-2.917-2.917V2.917A2.92 2.92 0 0 1 4.037 0H6.67Zm7.155 9.415c.23.23.234.602.008.837l-1.369 1.368 1.369 1.369a.597.597 0 0 1-.844.843l-1.369-1.368-1.368 1.368a.597.597 0 0 1-.844-.843l1.368-1.369-1.368-1.368a.597.597 0 0 1 .844-.844l1.368 1.368 1.369-1.368a.597.597 0 0 1 .836.007Zm-6.288.502a.583.583 0 0 1 0 1.166H4.62a.583.583 0 0 1 0-1.166h2.917Zm1.75-2.334a.583.583 0 1 1 0 1.167H4.62a.583.583 0 1 1 0-1.167h4.667ZM8.12 1.566v2.517c0 .323.261.584.583.584h2.518a2.905 2.905 0 0 0-.456-.613L8.733 2.021a2.922 2.922 0 0 0-.613-.455Z"
      clipRule="evenodd"
    />
  </svg>
);
