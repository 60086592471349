import React, {FunctionComponent} from 'react';
import {useHistory} from 'react-router-dom';
import moment from 'moment';
import {css} from 'glamor';
import {
  AUTH_BUYING_AP_APPROVE_REQUESTS_BATCH_VIEW,
  AUTH_BUYING_AP_APPROVE_REQUESTS_ABA_BATCH_VIEW,
  AUTH_BUYING_AP_AUTHORISE_PAYMENT_ABA_BATCH_VIEW,
  AUTH_BUYING_AP_AUTHORISE_PAYMENT_BATCH_VIEW,
} from '../../routes/AccountsPayableRoutes';
import {IPaymentBatchForApproval} from '../../model/payment-batch/PaymentBatch';
import {PriceFormat} from '../../utils/formatter';
import {makeStyles, TableContainer, Table, TableBody, TableCell, TableRow} from '@material-ui/core';
import {BatchSplitButton, BatchSplitButtonType} from './BatchSplitButton';
import {DualAuth} from '../../assets/svg/accounts-payable/DualAuth';

export interface IBatchList {
  isLoading: boolean;
  batchList: IPaymentBatchForApproval[];
  showChat?: boolean;
  setShowChat?: (showChat: boolean) => void;
  approveBatch?: (batch: IPaymentBatchForApproval) => void;
  approvePayBatch?: (batch: IPaymentBatchForApproval) => void;
  payBatch: (batch: IPaymentBatchForApproval) => void;
  isAuthorisationList?: boolean;
  selectedBatchId?: number;
  setSelectedBatchId?: (batchId: number) => void;
}

const paymentDueStyle = css({
  backgroundColor: '#D3E5EF50',
  color: '#1C78AD',
  '& span:first-child': {
    fontSize: '10px',
  },
  '& span:last-child': {
    fontSize: '14px',
  },
});

const paymentOverDueStyle = css({
  backgroundColor: '#F8E1DC',
  color: '#C55D44',
  '& span:first-child': {
    fontSize: '10px',
  },
  '& span:last-child': {
    fontSize: '14px',
  },
});

const useApprovalRequestStyles = makeStyles({
  invoicesTable: {
    padding: '0px 10px',
    maxHeight: 'calc(100vh - 18em)',
    '& table': {
      '& .MuiTableCell-root': {
        padding: '12px 6px',
        borderBottom: '1px solid #D8D8D8',
        borderLeft: '0px',
      },
    },
  },
  batchType: {
    color: '#8178CF',
    fontSize: '10px',
    fontWeight: 500,
    background: '#8178CF20',
    padding: '4px 11px',
    borderRadius: '4px',
    marginLeft: '6px',
  },
});

export const BatchList: FunctionComponent<IBatchList> = props => {
  const classes = useApprovalRequestStyles();
  const history = useHistory();
  const {isLoading, batchList, isAuthorisationList, payBatch, selectedBatchId, setSelectedBatchId} = props;

  const handleBatchClick = (batch: IPaymentBatchForApproval) => {
    if (isAuthorisationList) {
      if (batch.abaFileID) {
        history.push(
          AUTH_BUYING_AP_AUTHORISE_PAYMENT_ABA_BATCH_VIEW.replace(/:batchID/g, batch.accountsPayableBatchID),
        );
      } else {
        history.push(AUTH_BUYING_AP_AUTHORISE_PAYMENT_BATCH_VIEW.replace(/:batchID/g, batch.accountsPayableBatchID));
      }
    } else {
      if (batch.abaFileID) {
        history.push(AUTH_BUYING_AP_APPROVE_REQUESTS_ABA_BATCH_VIEW.replace(/:batchID/g, batch.accountsPayableBatchID));
      } else {
        history.push(AUTH_BUYING_AP_APPROVE_REQUESTS_BATCH_VIEW.replace(/:batchID/g, batch.accountsPayableBatchID));
      }
    }
    return Promise.resolve();
  };

  const handleSplitButtonClick = (buttonType: BatchSplitButtonType, values: IPaymentBatchForApproval) => {
    if (isAuthorisationList) {
      payBatch(values);
    } else {
      if (values.abaFileID) {
        history.push({
          pathname: AUTH_BUYING_AP_APPROVE_REQUESTS_ABA_BATCH_VIEW.replace(/:batchID/g, values.accountsPayableBatchID),
          search: `action=${buttonType}`,
        });
      } else {
        history.push({
          pathname: AUTH_BUYING_AP_APPROVE_REQUESTS_BATCH_VIEW.replace(/:batchID/g, values.accountsPayableBatchID),
          search: `action=${buttonType}`,
        });
      }
    }
  };

  return (
    <TableContainer className={`${classes.invoicesTable} overflow-y-auto`}>
      <Table data-autoid="tblBatchReviewRequireApproval" className="" aria-label="simple table">
        <TableBody>
          {batchList.map((batch: IPaymentBatchForApproval, index: number) => {
            const isAuthorisationRulesSet =
              (batch?.debits && batch?.debits?.length && batch?.debits?.[0]?.isAuthorisationRequired) || false;
            // check if user exist in authoriser list
            const isCurrentUserAnAuthoriser =
              (batch?.debits && batch?.debits?.length && batch?.debits?.[0]?.isCurrentUserAnAuthoriser) || false;
            const isDualAuthorisation =
              isAuthorisationList &&
              batch.authorisation?.requiredApprovalCount &&
              batch.authorisation?.requiredApprovalCount > 1;
            const authorisedBy =
              (isDualAuthorisation &&
                batch?.authorisation?.authorisers?.find(user => user.status === 'Approved')?.firstName) ||
              '-';
            return (
              <TableRow
                key={`batch-list-row-${index}`}
                selected={selectedBatchId === batch.accountsPayableBatchID}
                onClick={() => setSelectedBatchId && setSelectedBatchId(batch.accountsPayableBatchID)}
                className="cursor-pointer"
                data-autoid={`lnkBatchListRow-${batch.refNumber}`}
              >
                <TableCell width="23%">
                  <div className="flex flex-col">
                    {batch?.abaFileID ? (
                      <span
                        data-autoid={`lnkBatchName-${batch.refNumber}`}
                        className="my-1 cursor-pointer font-poppins text-base font-medium text-primary"
                        onClick={() => handleBatchClick(batch)}
                      >
                        {batch.name}
                        <span className={classes.batchType}>ABA</span>
                      </span>
                    ) : (
                      <span
                        data-autoid={`lnkBatchName-${batch.refNumber}`}
                        onClick={() => handleBatchClick(batch)}
                        className="my-1 cursor-pointer font-poppins text-base font-medium text-primary"
                      >
                        {batch.name}
                      </span>
                    )}
                    <span className="font-poppins text-xs text-spenda-primarytext">{`Batch ID: ${batch.refNumber}`}</span>
                    <span className="font-poppins text-xs text-spenda-primarytext">{`No. of records: ${batch.countCredits}`}</span>
                  </div>
                </TableCell>
                <TableCell width="17%">
                  <div className="flex flex-col items-center justify-center">
                    <span className="font-poppins text-xs text-spenda-primarytext">Batch total:</span>
                    <span className="font-poppins text-base font-medium text-spenda-primarytext">
                      {`${PriceFormat(batch.paymentAmount)}`}
                    </span>
                  </div>
                </TableCell>
                <TableCell width="30%">
                  <div>
                    <span
                      className={`flex flex-col items-center justify-center rounded py-2 ${
                        moment(batch.dueDate, 'YYYY-MM-DD').isBefore(moment()) ? paymentOverDueStyle : paymentDueStyle
                      }`}
                    >
                      <span className="text-center font-poppins text-xs font-medium">{`Payment Date: ${
                        batch?.dueDate ? moment.utc(batch?.dueDate).local().format('DD MMM YYYY') : ''
                      }`}</span>
                      <span className="text-center font-poppins text-base font-semibold">{` ${
                        moment(batch.dueDate, 'YYYY-MM-DD').isBefore(moment()) ? 'Payment Due: ' : 'Payment: '
                      } ${moment(batch.dueDate, 'YYYY-MM-DD').fromNow()}`}</span>
                    </span>
                  </div>
                </TableCell>
                <TableCell width="20%">
                  {props.isAuthorisationList ? (
                    <div className="flex flex-col items-center justify-center">
                      <span className="flex items-center justify-center">
                        <span className="text-center font-poppins text-xs text-spenda-primarytext">Authorised by:</span>
                        {isDualAuthorisation && (
                          <DualAuth className="mb-1 ml-1" data-autoid={`imgDualAuth-${batch.refNumber}`} />
                        )}
                      </span>
                      <span
                        className="text-center font-poppins text-base font-medium text-spenda-primarytext"
                        {...css({color: '#3D9F78'})}
                      >
                        {authorisedBy}
                      </span>
                    </div>
                  ) : (
                    <div className="flex flex-col items-center justify-center">
                      <span className="text-center font-poppins text-xs text-spenda-primarytext">Approved by:</span>
                      <span
                        className="text-center font-poppins text-base font-medium text-spenda-primarytext"
                        {...css({color: '#3D9F78'})}
                      >
                        {batch.batchApprovedByUserName || '-'}
                      </span>
                    </div>
                  )}
                </TableCell>
                <TableCell align="center">
                  <BatchSplitButton
                    isAuthorisationList={props.isAuthorisationList}
                    disabled={isLoading}
                    isSubmitting={isLoading}
                    batchStatus={batch.status}
                    handleClick={x => handleSplitButtonClick(x, batch)}
                    isCurrentUserAnAuthoriser={isCurrentUserAnAuthoriser}
                    isAuthorisationRulesSet={isAuthorisationRulesSet}
                    authorisation={props.isAuthorisationList ? batch?.authorisation : undefined}
                    dataAutoIdSuffix={`-${String(batch.refNumber)}`}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
