import React, {useContext, useState} from 'react';

import {PaymentWidgetContext, PaymentWidgetPaymentSteps, PaymentWidgetStep} from './PaymentWidget';
import {PaymentWidgetScope} from '../../../model/constants/Constants';
import SDatePicker from '../../../components/pickers/DatePicker';
import IconPaymentScheduled from '../../../assets/svg/IconPaymentScheduled';
import {PrimaryButton, SecondaryButton} from '../../../components/buttons/DefaultButtons';
import moment, {Moment} from 'moment';

export const PaymentWidgetStepBatchPaymentScheduled = () => {
  //context
  const {setStep, onCancel, widgetScope, scheduledPaymentDate, setScheduledPaymentDate} =
    useContext(PaymentWidgetContext);

  // states
  const minDate = moment().add(1, 'day');
  const maxDate = moment().add(1, 'months').endOf('month');
  const [date, setDate] = useState<Moment | undefined>(scheduledPaymentDate || minDate);

  const onClose = () => {
    if (widgetScope === PaymentWidgetScope.ADD_BANK_ACCOUNT) {
      if (onCancel) onCancel();
      return;
    }
    if (setStep) setStep(PaymentWidgetPaymentSteps.NEW);
  };

  const handleSetScheduleDate = async () => {
    try {
      if (!date) return;
      setScheduledPaymentDate?.(date);
      onClose();
    } catch {}
  };

  const isValidaDate = () => {
    return !date?.isBetween(moment(), maxDate);
  };

  const body = (
    <div className="flex flex-col items-center px-6 h-full pt-10">
      <IconPaymentScheduled />
      <p className="text-xl font-light text-black-800 font-poppins my-6">Schedule Payment</p>
      <p className="text-sm font-normal text-black-800 font-poppins mb-6">This is a once off scheduled payment</p>
      <div className="flex flex-row justify-center items-center w-[240px]">
        <SDatePicker
          v2
          inputVariant="outlined"
          format="dd/MM/yyyy"
          minDate={minDate}
          maxDate={maxDate}
          autoOk
          fullWidth
          variant="inline"
          id="scheduledDate"
          label="Date"
          name="scheduledDate"
          value={date ?? minDate}
          onChange={date => setDate(moment(date, 'DD-MM-YYYY'))}
          className="w-full text-left"
          invalidDateMessage="Please enter correct date"
          inputProps={{
            'data-autoid': 'dtpPaymentScheduledDate',
          }}
          KeyboardButtonProps={
            {
              'aria-label': 'change date',
              'data-autoid': 'dtpPaymentScheduledDateIcon',
            } as any
          }
        />
      </div>
      {/* <p className="text-xs font-normal text-black-800 font-poppins mt-20">
        To edit this batch payment after processing it, simply proceed to the 'Upcoming payment' tab on the Payment
        History screen.
      </p> */}
    </div>
  );

  const footer = (
    <>
      <SecondaryButton width="100px" label="Cancel" onClick={onClose} data-autoid="btnCancelSchedulePayment" />
      <PrimaryButton
        disabled={isValidaDate()}
        width="100px"
        label="Confirm"
        onClick={handleSetScheduleDate}
        data-autoid="btnConfirmSchedulePayment"
      />
    </>
  );

  return <PaymentWidgetStep body={body} footer={footer} titleType="bank" />;
};
