import React, {useState} from 'react';
import {Scheduler, Typography} from 'spenda-ui-react';
import moment from 'moment';

import {AlertDialog, AlertDialogSlideV2} from './AlertDialogSlideV2';
import {useServiceContext} from '../../context/serviceManagement/ServiceContext';
import {ITechnician, ITechnicianViewJobs} from '../../model/service-management/serviceJob';
import FilterTechnician from '../bookingDiary/FilterTechnician';
import {IServiceJobTechnician} from '../../model/service-management/serviceJob';
import {useScheduleJob} from '../../hooks/useScheduleJob';
import {TemplateViewSchedulerTask} from '../bookingDiary/BookingDiaryScheduler';
import LoadingIndicator from '../ui/LoadingIndicator';

interface ISchedulerDialogProps {
  alreadySelectedTechnicians?: IServiceJobTechnician[];
  onClose: () => void;
  schedulerData: ITechnicianViewJobs[];
  selectedDate: Date;
  setSelectedDate: React.Dispatch<React.SetStateAction<Date>>;
  selectedTechnicians: number[];
  setSelectedTechnicians: (technicians: number[]) => void;
  technicians: ITechnician[];
  onScheduleJob: (jobInfo: ISchedulerDialogState) => void;
  isLoading?: boolean;
}

export type ISchedulerDialogState = {
  startDate: Date;
  endDate: Date;
  technician: {
    id: string;
    name: string;
  };
};

export default function SchedulerDialog(props: ISchedulerDialogProps) {
  const {
    selectedDate,
    setSelectedDate,
    onClose,
    selectedTechnicians,
    setSelectedTechnicians,
    schedulerData,
    technicians,
    alreadySelectedTechnicians,
    onScheduleJob,
    isLoading,
  } = props;

  const [selectedJobInfo, setSelectedJobInfo] = useState<ISchedulerDialogState>();
  const [showWarning, setShowWarning] = useState(false);
  const [isShowUnassignedServiceJobs, setIsShowUnassignedServiceJobs] = useState<boolean>(true);

  const {data, techniciansList} = useScheduleJob({
    schedulerData: schedulerData,
    selectedTechnicianIDs: selectedTechnicians,
    technicians: technicians,
    isAlreadySelectedTechnicians: !!alreadySelectedTechnicians?.length,
    isShowUnassignedServiceJobs: isShowUnassignedServiceJobs,
  });

  const {serviceJob} = useServiceContext();

  const onDateChange = (date: Date) => {
    setSelectedDate(date);
  };

  const onDragEnd = (technician: (typeof techniciansList)[0], startDateTime: Date, endDateTime: Date) => {
    setSelectedJobInfo({
      startDate: startDateTime,
      endDate: endDateTime,
      technician,
    });
    setShowWarning(true);
  };

  return (
    <>
      <AlertDialogSlideV2
        headingClassess="justify-start border-none p-0"
        dialogActionsAlignment="justify-start"
        dialogBodyClassess="py-3"
        dialogClassess="!mx-5 !w-full !max-w-[96%] xl:!max-w-[1245px] min-h-[90vh]"
        actions={[
          {
            label: 'Go Back',
            variant: 'outlined',
            action: onClose,
          },
        ]}
      >
        <div className="relative h-full w-full">
          <LoadingIndicator
            isLoading={isLoading}
            size="md"
            position={{
              height: '100% !important',
              display: 'flex',
              position: 'absolute',
              left: '0',
              right: '0',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          />
          <Scheduler
            workingHours={{
              start: '12AM',
              end: '12AM',
            }}
            data={data}
            technician={techniciansList}
            ViewTask={TemplateViewSchedulerTask}
            selectedDate={selectedDate}
            onDateChange={onDateChange}
            onDragEnd={onDragEnd}
            headerProps={{
              className: 'text-black pt-2.5',
              children: (
                <>
                  <div className="absolute right-0 top-0 h-10 w-10">
                    <FilterTechnician
                      selectedTechnicians={selectedTechnicians}
                      setSelectedTechnicians={setSelectedTechnicians}
                      technicians={technicians}
                      isShowUnassignedServiceJobs={isShowUnassignedServiceJobs}
                      setIsShowUnassignedServiceJobs={setIsShowUnassignedServiceJobs}
                    />
                  </div>
                  <div className="absolute left-0 top-2 flex items-end">
                    <Typography variant="h2">{serviceJob?.customerName}</Typography>
                  </div>
                </>
              ),
            }}
            getTechnicianIndexByEvent={event => {
              return techniciansList.findIndex(tech => tech.id == event.technician.id);
            }}
            ViewGridHeading={props => <span>{props.technician.name}</span>}
            containerProps={{
              className: 'h-[calc(80vh-102px)] mx-32',
            }}
          />
        </div>
      </AlertDialogSlideV2>
      {showWarning && (
        <AlertDialogWhenScheduling
          onScheduleJob={onScheduleJob}
          selectedJobInfo={selectedJobInfo!}
          onClose={setShowWarning}
        />
      )}
    </>
  );
}

interface AlertDialogWhenSchedulingProps {
  onScheduleJob: (jobInfo: ISchedulerDialogState) => void;
  selectedJobInfo: ISchedulerDialogState;
  onClose: (show: boolean) => void;
}

export const AlertDialogWhenScheduling = ({
  onScheduleJob,
  selectedJobInfo,
  onClose,
}: AlertDialogWhenSchedulingProps) => {
  return (
    <AlertDialog
      data-autoid={'dlgConfirmationforScheduling'}
      title="Confirmation for Scheduling"
      headingTextSize="h2"
      headingClassess="text-black justify-center"
      actions={[
        {
          label: 'Go Back',
          variant: 'outlined',
          action: () => onClose(false),
        },
        {
          label: 'Schedule Booking',
          action: () => onScheduleJob(selectedJobInfo),
        },
      ]}
      dialogBodyClassess="flex justify-center"
      contentClass="w-[400px]"
      body={
        <div className="flex flex-col justify-center">
          <div className="flex flex-row justify-center gap-6 pt-4">
            <div className="flex flex-col gap-1">
              <Typography variant="small" className="font-bold text-black-800" data-autoid="txtDate">
                Date:
              </Typography>
              <Typography variant="small" className="font-bold text-black-800" data-autoid="txtStart">
                Start:
              </Typography>
              <Typography variant="small" className="font-bold text-black-800" data-autoid="txtEnd">
                End:
              </Typography>
              <Typography variant="small" className="font-bold text-black-800" data-autoid="txtDuration">
                Duration:
              </Typography>
            </div>
            <div className="flex flex-col gap-1">
              <Typography
                variant="small"
                className="font-poppins font-normal text-black-800"
                data-autoid="txtJobStartDate"
              >
                {`${moment(selectedJobInfo.startDate).format('Do MMMM YYYY')}`}
              </Typography>
              <Typography
                variant="small"
                className="font-poppins font-normal text-black-800"
                data-autoid="txtJobStartTime"
              >
                {`${moment(selectedJobInfo.startDate).format('h:mm a')}`}
              </Typography>
              <Typography
                variant="small"
                className="font-poppins font-normal text-black-800"
                data-autoid="txtJobEndTime"
              >
                {`${moment(selectedJobInfo.endDate).format('h:mm a')}`}
              </Typography>
              <Typography
                variant="small"
                className="font-poppins font-normal text-black-800"
                data-autoid="txtJobDuration"
              >
                {`${moment(selectedJobInfo.endDate).diff(moment(selectedJobInfo.startDate), 'minutes') / 60} ${moment(selectedJobInfo.endDate).diff(moment(selectedJobInfo.startDate), 'minutes') / 60 > 1 ? 'hrs' : 'hr'}`}
              </Typography>
            </div>
          </div>
          <Typography
            variant="small"
            className="flex w-full max-w-[378px] items-center justify-center py-8 text-center font-normal text-black-800"
          >
            If these details are correct, please click 'Schedule Booking'. Otherwise, to select a different time, please
            select 'Go Back.
          </Typography>
        </div>
      }
    />
  );
};
