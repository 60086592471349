import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {ARHeader, IHeaderDetails} from '../../../components/AccountsReceivable/ARHeader';
import {
  SelectEmailTemplateDialog,
  SelectEmailTemplateScope,
} from '../../../components/AccountsReceivable/SelectEmailTemplateDialog';
import {TransactionView} from '../../../components/AccountsReceivable/TransactionView';
import {PaymentReminderDialog} from '../../../components/dialog/PaymentReminderDialog';
import ARContext from '../../../context/ar-context/ARContext';
import {usePsblStatementHook, useTransactionViewHook} from '../../../hooks/useARHook';
import {useFeatureFlags} from '../../../hooks/useFeatureFlags';
import {ISelectedTransactionsList} from '../../../model/accounts-receivable/AccountsReceivable';
import {
  ARAgeReceivableDateRange,
  ARCustomerStatementOperation,
  ARCustomerStatementResponseStatus,
  ARSelectableTransactions,
  ARStatementDeltasActions,
  ARStatementPeriodDefaultOptions,
} from '../../../model/constants/Constants';
import {
  IAgedTxCustOutstandingStatementList,
  ICustomerOutstandingStatement,
  ICustomerStatementsResponse,
} from '../../../model/customer/CustomerStatements';
import {useConnectedCustomerAPI} from '../../../services/useConnectedCustomerAPI';
import {
  ICreateStatementDeltas,
  ICustomerStatementFilterQuery,
  useCustomerStatementsAPI,
} from '../../../services/useCustomerStatementsAPI';
import {CustomerGroupAgedReceivableView} from '../customer-group-view/CustomerGroupAgeReceivable';
import CustomerFocusStandardView from './CustomerFocusStandardView';
import {ArCustomerFocusedViewTypes, CustomerFocusTableHeader} from './CustomerFocusTableHeader';

interface ICustomerFocusView {
  customerId: number;
}

interface ICustomerFocusStateLocationProps {
  isLoadAllStatmentInWidget?: boolean;
}

export const CustomerFocusView = (props: ICustomerFocusView) => {
  // Props
  const {customerId} = props;

  // APIs
  const {getConnectedCustomer, isLoading: headerLoading} = useConnectedCustomerAPI();
  const {getCustomerAllTransactions, getCustomerAllStatements, getAgedTransactions, isLoading} =
    useCustomerStatementsAPI();

  // Hooks
  const {updateStatement} = usePsblStatementHook();
  const {state} = useLocation<ICustomerFocusStateLocationProps>();
  const {handleCloseTx, handleViewTx, viewingTx} = useTransactionViewHook();

  // Context
  const {selectedStatementPeriod, setSelectedStatementPeriod, psblStatement, setPsblStatement} = useContext(ARContext);

  // Feature Flags
  const {statementsAndAutomatedStatement83193} = useFeatureFlags().tenantOwned();

  // State
  const [statementOptions, setStatementOptions] = useState<{name: string | null; value: string}[]>();
  const [headerDetails, setHeaderDetails] = useState<IHeaderDetails>();
  const [piblData, setPIBLData] = useState<{isShow?: boolean; tx?: ICustomerOutstandingStatement}>();
  const [viewType, setViewType] = useState<ArCustomerFocusedViewTypes>(ArCustomerFocusedViewTypes.STANDARD_VIEW);
  const [customerTransactions, setCustomerTransactions] = useState<ICustomerStatementsResponse>();
  const [agedInvoiceTransactions, setAgedInvoiceTransactions] = useState<IAgedTxCustOutstandingStatementList>();
  const [transactionFilterQuery, setTransactionFilterQuery] = useState<ICustomerStatementFilterQuery>();
  const [ageReceivableDateRange, setAgeReceivableDateRange] = useState<ARAgeReceivableDateRange>(
    ARAgeReceivableDateRange.BY_MONTH,
  );
  const [isInLoadingState, setIsInLoadingState] = useState(false);

  // Constants
  const txList = useMemo(
    () =>
      customerTransactions?.customerOutstandingStatements?.map(t => ({
        txId: t.transID,
        txGuid: t.transGUID,
        txDatType: t.transactionTypeID,
      })),
    [customerTransactions?.customerOutstandingStatements],
  );
  const isAgeReceivableViewSelected = viewType === ArCustomerFocusedViewTypes.AGED_RECEIVABLES;
  const isStandardViewSeleted = viewType === ArCustomerFocusedViewTypes.STANDARD_VIEW;

  // Feature Flags
  const {scheduledPaymentsV2} = useFeatureFlags().tenantOwned();

  // UseEffects
  useEffect(() => {
    setPsblStatement({selectedTxList: [], statementId: undefined, accountCustomerId: customerId});
    setSelectedStatementPeriod(ARStatementPeriodDefaultOptions.ALL);
    fetchCustomerAllCredtedStatement();
    fetchCustomerData();
  }, [customerId]);

  useEffect(() => {
    if (isStandardViewSeleted) {
      fetchAllTransactions();
    } else if (isAgeReceivableViewSelected) {
      fetchAgeReceivableTransactions();
    }
  }, [isAgeReceivableViewSelected, isStandardViewSeleted, ageReceivableDateRange, transactionFilterQuery]);

  useEffect(() => {
    if (customerTransactions?.customerOutstandingStatements?.length && state?.isLoadAllStatmentInWidget) {
      handleSelectAll();
    }
  }, [customerTransactions, state?.isLoadAllStatmentInWidget]);

  useEffect(() => {
    return () => setSelectedStatementPeriod(ARStatementPeriodDefaultOptions.ALL);
  }, []);

  // Functions

  const fetchCustomerAllCredtedStatement = async () => {
    try {
      const customerAllStatements = await getCustomerAllStatements(customerId);
      const allCreatedStatement = customerAllStatements
        ?.filter(s => s.status !== 'Open')
        ?.map(o => ({value: o.statementID.toString(), name: o?.name}));
      const defaultOptions = Object.entries(ARStatementPeriodDefaultOptions).map(([_key, value]) => ({
        value: value,
        name: value,
      }));

      if (allCreatedStatement?.length) {
        setStatementOptions([...defaultOptions, ...allCreatedStatement]);
        return;
      }
      setStatementOptions(defaultOptions);
    } catch {}
  };

  const fetchAllTransactions = async () => {
    try {
      setIsInLoadingState(true);
      const custTxRes = await getCustomerAllTransactions({...transactionFilterQuery, accountCustomerID: customerId});
      setCustomerTransactions(custTxRes);

      const otherUserEditingStatementError = custTxRes?.statementSummary?.errorMessage;
      if (otherUserEditingStatementError) {
        setPsblStatement({
          otherUserEditingStatementError: otherUserEditingStatementError?.replace('SMT013: ', ''),
          statementId: custTxRes?.statementSummary?.statementID,
          accountCustomerId: customerId,
          selectedTxList: [],
        });
        return;
      }

      if (custTxRes?.statementSummary?.status !== ARCustomerStatementResponseStatus.OPEN) return;

      const selectedTransList: ISelectedTransactionsList[] = custTxRes?.statementSummary?.transactions?.map(t => {
        return {
          id: t.id,
          refNumber: t.refNumber,
          transactionType: t.datTypeName,
          dueDate: t.dueDate,
          transDate: t.transactionDate,
          balance: t.balance,
          totalInc: t.totalInc,
          accountCustomerName: t.accountCustomerName,
          transactionTypeID: t.datTypeInteger,
        };
      });

      setPsblStatement({
        accountCustomerId: customerId,
        selectedTxList: selectedTransList,
        statementId: custTxRes?.statementSummary?.statementID,
      });
    } catch {
      setCustomerTransactions(undefined);
    } finally {
      setIsInLoadingState(false);
    }
  };

  const fetchAgeReceivableTransactions = async () => {
    try {
      setIsInLoadingState(true);
      const _agedTransactions = await getAgedTransactions(ageReceivableDateRange, {
        accountCustomerID: customerId,
      });

      if (!_agedTransactions?.agedCustomerOutstandingStatementGroupByList?.length) return;
      setAgedInvoiceTransactions(_agedTransactions?.agedCustomerOutstandingStatementGroupByList[0]);

      setIsInLoadingState(false);
    } catch {
      setIsInLoadingState(false);
    }
  };

  const fetchCustomerData = async () => {
    try {
      const response = await getConnectedCustomer(scheduledPaymentsV2, customerId);
      setHeaderDetails({
        customerName: response.CustomerName,
        customerEmail: response.PrimaryContact.EmailAddress,
        customerMobile: response.PrimaryContact.PhoneMobile,
        customerAddress: response.BillingAddress,
        accountBalance: scheduledPaymentsV2
          ? response?.AccountBalanceIncludingScheduledPayments
          : response?.AccountBalance!,
        creditLimit: response?.CreditLimit!,
        creditAvailable: scheduledPaymentsV2
          ? response?.CreditAvailableIncludingScheduledPayments
          : response?.CreditAvailable,
        IsOnCreditHold: response?.IsOnCreditHold,
        IsOnCreditStop: response?.IsOnCreditStop,
      });
    } catch {}
  };

  const handleChangeViewType = (view: ArCustomerFocusedViewTypes) => {
    setViewType(view);
  };

  const isAllTransactionsSelected = useMemo(() => {
    let isAllSelected: boolean | undefined = false;

    const txList =
      viewType === ArCustomerFocusedViewTypes.AGED_RECEIVABLES
        ? agedInvoiceTransactions?.groupedList
        : customerTransactions?.customerOutstandingStatements;

    if (!psblStatement?.statementId || !txList?.length) return false;

    isAllSelected = txList?.every(at => {
      if (!ARSelectableTransactions.includes(at.transactionTypeID)) {
        return true;
      }
      if (
        scheduledPaymentsV2 &&
        at?.scheduledInvoicePaymentAllocationSummary?.length &&
        typeof at?.balanceIncludingScheduledIPAs === 'number' &&
        at?.balanceIncludingScheduledIPAs <= 0
      ) {
        return true;
      }

      return psblStatement?.selectedTxList?.some(st => st?.id === at.transID) || false;
    });
    return Boolean(isAllSelected);
  }, [
    viewType,
    agedInvoiceTransactions?.groupedList,
    customerTransactions?.customerOutstandingStatements,
    psblStatement?.statementId,
    psblStatement?.selectedTxList,
    scheduledPaymentsV2,
  ]);

  const handleSelect = useCallback(
    async (record: ICustomerOutstandingStatement) => {
      try {
        const {statementId, selectedTxList} = psblStatement;
        const isAlreadySelected = !statementId ? false : selectedTxList?.findIndex(st => record.transID === st.id) > -1;
        const payload = {
          statementID: statementId || null,
          accountCustomerID: customerId,
          statementDeltas: [
            {
              action: isAlreadySelected ? ARStatementDeltasActions.REMOVE : ARStatementDeltasActions.ADD,
              datTypeID: record?.transactionTypeID?.toString(),
              id: record?.transID,
            },
          ],
          operation: statementId ? ARCustomerStatementOperation.UPDATE : ARCustomerStatementOperation.NEW,
        };
        await updateStatement(payload);
      } catch {}
    },
    [customerId, psblStatement, updateStatement],
  );

  const handleSelectAll = useCallback(async () => {
    try {
      const txList = isAgeReceivableViewSelected
        ? agedInvoiceTransactions?.groupedList
        : customerTransactions?.customerOutstandingStatements;

      if (!txList?.length) return;

      const {statementId} = psblStatement;

      const statementDeltas = txList?.reduce((acc: ICreateStatementDeltas[], transRecord) => {
        if (!ARSelectableTransactions.includes(transRecord.transactionTypeID)) {
          return acc;
        }

        if (
          scheduledPaymentsV2 &&
          transRecord?.scheduledInvoicePaymentAllocationSummary?.length &&
          typeof transRecord?.balanceIncludingScheduledIPAs === 'number' &&
          transRecord?.balanceIncludingScheduledIPAs <= 0
        ) {
          return acc;
        }

        acc.push({
          action: isAllTransactionsSelected ? ARStatementDeltasActions.REMOVE : ARStatementDeltasActions.ADD,
          datTypeID: transRecord?.transactionTypeID?.toString(),
          id: transRecord?.transID,
        });
        return acc;
      }, []);

      const payload = {
        statementID: statementId || null,
        accountCustomerID: customerId,
        statementDeltas: statementDeltas,
        operation: statementId ? ARCustomerStatementOperation.UPDATE : ARCustomerStatementOperation.NEW,
      };

      await updateStatement(payload);
    } catch {}
  }, [
    agedInvoiceTransactions?.groupedList,
    customerId,
    customerTransactions?.customerOutstandingStatements,
    isAgeReceivableViewSelected,
    isAllTransactionsSelected,
    scheduledPaymentsV2,
    psblStatement,
    updateStatement,
  ]);

  const onPIBLClick = (tx: ICustomerOutstandingStatement) => {
    setPIBLData({isShow: true, tx: tx});
  };

  const getCurrentView = () => {
    switch (viewType) {
      case ArCustomerFocusedViewTypes.STANDARD_VIEW:
        return (
          <CustomerFocusStandardView
            isLoading={isLoading || isInLoadingState}
            customerId={customerId}
            transactionList={customerTransactions?.customerOutstandingStatements || []}
            onSelect={handleSelect}
            onPIBLClick={onPIBLClick}
            onViewTransaction={t => handleViewTx(t)}
          />
        );
      case ArCustomerFocusedViewTypes.AGED_RECEIVABLES:
        return (
          <CustomerGroupAgedReceivableView
            customerId={customerId}
            isLoading={isLoading || isInLoadingState}
            expandedCustomer={agedInvoiceTransactions}
            ageReceivableDateRange={ageReceivableDateRange}
            onViewTransaction={t => handleViewTx(t)}
            onSelect={tx => handleSelect(tx)}
            scope="CUST_FOCUSED"
          />
        );
      default:
        return null;
    }
  };

  const getTransactionsCount = () => {
    return isAgeReceivableViewSelected
      ? agedInvoiceTransactions?.groupedList?.length
      : customerTransactions?.customerOutstandingStatements?.length;
  };

  return (
    <>
      {piblData?.isShow && !statementsAndAutomatedStatement83193 && (
        <PaymentReminderDialog
          txId={piblData?.tx?.transID?.toString()}
          customerId={customerId?.toString()}
          isSendByLink={true}
          closeDialog={() => setPIBLData({isShow: false, tx: undefined})}
        />
      )}
      {piblData?.isShow && statementsAndAutomatedStatement83193 && piblData?.tx && (
        <SelectEmailTemplateDialog
          isShowModal={piblData?.isShow}
          onClose={() => setPIBLData({isShow: false, tx: undefined})}
          invoiceId={piblData?.tx?.transID}
          accountCustomerId={piblData?.tx?.accountCustomerID}
          scope={SelectEmailTemplateScope.SendInvoice}
        />
      )}
      {viewingTx && (
        <TransactionView
          key={viewingTx?.txGuid}
          isShowInPdfView={false}
          transactionId={viewingTx?.txId}
          txDatType={viewingTx?.txDatType}
          txGuid={viewingTx?.txGuid}
          handleOnClosePdfView={() => handleCloseTx()}
          customerId={customerId}
          scope="CustomerFocusView"
          txList={txList}
        />
      )}
      <div className="flex h-full flex-col">
        <ARHeader headerData={headerDetails} isLoading={headerLoading} />
        <div className="overflow-auto">
          <div className={`py-2.5font-poppins flex h-full flex-col rounded-[6px] bg-white px-0`}>
            <CustomerFocusTableHeader
              handleSelectAll={handleSelectAll}
              isSelectedAll={isLoading ? false : isAllTransactionsSelected}
              statementOptions={statementOptions}
              selectedStatementPeriod={selectedStatementPeriod}
              onViewChange={handleChangeViewType}
              selectedView={viewType}
              transactionFilterQuery={transactionFilterQuery}
              setTransactionFilterQuery={setTransactionFilterQuery}
              selectedAgeDateRange={ageReceivableDateRange}
              onChangeAgeDateRange={selectedDateRange => setAgeReceivableDateRange(selectedDateRange)}
              selectAllCount={getTransactionsCount()}
              setSelectedStatementPeriod={setSelectedStatementPeriod}
            />
            {getCurrentView()}
          </div>
        </div>
      </div>
    </>
  );
};
