import React, {useEffect} from 'react';
import {IPlaceAutoCompleteResult} from '../../model/address/place-prediction';
import useAddressAPI from '../../services/useAddressAPI';
import {Field, FieldProps, Formik, FormikErrors, FormikHelpers, FormikProps} from 'formik';
import {ITenantLocation} from '../../model/tenantLocation/TenantLocation';
import {AddressInputsOrLookup} from '../address/AddressDialog';
import * as Yup from 'yup';
import {IAddress} from '../../model/address/Address';
import {AlertDialog, AlertDialogSlideV2} from '../dialog/AlertDialogSlideV2';
import {Input, Typography} from 'spenda-ui-react';

interface ITenantLocationDialogProps {
  title?: string;
  tenantLocation: ITenantLocation;
  open?: boolean;
  onClose?: () => void;
  onSave: (tenantLocation: ITenantLocation, helpers: FormikHelpers<ITenantLocation>) => void;
}

export const TenantLocationDialog: React.FunctionComponent<ITenantLocationDialogProps> = ({
  title,
  tenantLocation,
  open,
  onClose,
  onSave,
}: ITenantLocationDialogProps) => {
  const [isShowDiscardChangesConfirmation, setIsShowDiscardChangesConfirmation] = React.useState(false);
  const [isDisplayAddressLookup, setIsDisplayAddressLookup] = React.useState(true);

  useEffect(() => {
    if (tenantLocation.TenantLocationAddress) {
      const {StreetAddress} = tenantLocation.TenantLocationAddress;
      // Display Address LookUp for new locations and address inputs for existing addresses
      // setIsDisplayAddressLookup(!StreetAddress && !City && !PostCode && !Country);
      setIsDisplayAddressLookup(!StreetAddress);
    }
  }, [tenantLocation]);

  const validationSchema = Yup.object({
    LocationName: Yup.string()
      .required('Location Name is required.')
      .max(100, 'Location Name cannot be longer than 100 characters.'),
    DefaultWarehouse: Yup.object({
      Name: Yup.string()
        .required('Warehouse Location Name is required')
        .max(50, 'Warehouse Name cannot be longer than 50 characters.'),
    }),
    TenantLocationAddress: Yup.object({
      StreetAddress: Yup.string()
        .required('Street Address is required.')
        .max(500, 'Street Address cannot be longer than 500 characters.'),
      City: Yup.string().required('City is required').max(50, 'City cannot be longer than 50 characters.'),
      PostCode: Yup.string().required('Postcode is required').max(50, 'Postcode cannot be longer than 50 characters.'),
      State: Yup.string().max(50, 'Postcode cannot be longer than 50 characters.'),
      Country: Yup.string().required('Country is required').max(100, 'Country cannot be longer than 100 characters.'),
    }),
  });

  const onToggleAddressLookup = () => {
    setIsDisplayAddressLookup(value => !value);
  };

  const onEditLocationClose = (isDirty?: boolean) => {
    if (isDirty) {
      setIsShowDiscardChangesConfirmation(true);
    } else {
      if (onClose) {
        onClose();
      }
    }
  };

  return (
    <Formik
      initialValues={tenantLocation}
      onSubmit={(values, helpers) => onSave(values, helpers)}
      validationSchema={validationSchema}
    >
      {renderProps => (
        <AlertDialogSlideV2
          id="editLocationPopup"
          dialogClassess="!min-w-2/6 !w-full !max-w-[800px]"
          dialogBodyClassess="!min-h-[400px]"
          dialogActionsAlignment="justify-between"
          title={title}
          actions={[
            {
              label: 'Cancel',
              variant: 'outlined',
              action: () => {
                if (onEditLocationClose) {
                  onEditLocationClose(renderProps.dirty);
                }
              },
            },
            {
              label: 'Save',
              action: () => {
                if (renderProps.submitForm) {
                  renderProps.submitForm();
                }
              },
              disabled: renderProps.isSubmitting || isDisplayAddressLookup,
            },
          ]}
        >
          <TenantLocationForm
            {...renderProps}
            onClose={onEditLocationClose}
            open={open}
            isDisplayAddressLookup={isDisplayAddressLookup}
            onToggleAddressLookup={onToggleAddressLookup}
            isShowDiscardChangesConfirmation={isShowDiscardChangesConfirmation}
            setIsShowDiscardChangesConfirmation={setIsShowDiscardChangesConfirmation}
          />
        </AlertDialogSlideV2>
      )}
    </Formik>
  );
};

interface ITenantLocationFormProps {
  isDisplayAddressLookup?: boolean;
  isShowDiscardChangesConfirmation?: boolean;
  open?: boolean;
  onToggleAddressLookup?: () => void;
  onClose?: (isDirty?: boolean) => void;
  setIsShowDiscardChangesConfirmation: (value: boolean) => void;
}

const TenantLocationForm = (props: FormikProps<ITenantLocation> & ITenantLocationFormProps) => {
  const {search, get} = useAddressAPI();
  const [addressSuggetionsList, setAddressSuggetionsList] = React.useState<IPlaceAutoCompleteResult[]>([]);
  // const [isAdvanceGoogleSearch, setIsAdvanceGoogleSearch] = React.useState(props.isDisplayAddressLookup);

  const handleAdvanceSearch = (value: any) => {
    search(value).then(items => setAddressSuggetionsList(items ? items : []));
  };

  const onClickAddressCard = (_event: React.MouseEvent<HTMLDivElement, MouseEvent>, item: IPlaceAutoCompleteResult) => {
    get(item.PlaceID).then(res => {
      const {StreetAddress, City, PostCode, State, Country} = res;

      props.setFieldValue(`TenantLocationAddress.StreetAddress`, StreetAddress || '');
      props.setFieldValue(`TenantLocationAddress.City`, City || '');
      props.setFieldValue(`TenantLocationAddress.PostCode`, PostCode || '');
      props.setFieldValue(`TenantLocationAddress.State`, State || '');
      props.setFieldValue(`TenantLocationAddress.Country`, Country || '');

      if (props.onToggleAddressLookup) {
        props.onToggleAddressLookup();
      }
    });
  };

  const changeAddress = (name: string, e: {persist: () => void; target?: any}) => {
    e.persist();
    // TODO: Pass proper names to address component
    if (['LocationName', 'DefaultWarehouse.Name'].indexOf(name) >= 0) {
      props.handleChange(e);
      props.setFieldTouched(name, true, false);
    } else {
      props.setFieldValue(`TenantLocationAddress.${name}`, e.target.value);
      props.setFieldTouched(`TenantLocationAddress.${name}`, true, false);
    }
  };

  return (
    <form id="address-form" onSubmit={props.handleSubmit} style={{minHeight: '156px'}}>
      <div className="bottom-4 flex justify-between">
        <div className="mr-4 w-full">
          <Input
            name="LocationName"
            label="Location Name"
            className="w-full"
            value={props.values.LocationName}
            onChange={changeAddress.bind(null, 'LocationName')}
            helperText={props.touched.LocationName ? props.errors.LocationName : ''}
            error={props.touched.LocationName && Boolean(props.errors.LocationName)}
          />
        </div>
        <div className="w-full">
          <Field name={'DefaultWarehouse.Name'}>
            {({meta}: FieldProps) => {
              return (
                <Input
                  name="DefaultWarehouse.Name"
                  label="Default warehouse name"
                  className="w-full"
                  value={meta.value}
                  onChange={changeAddress.bind(null, 'DefaultWarehouse.Name')}
                  helperText={meta.touched && meta.error ? meta.error : ''}
                  error={meta.touched && Boolean(meta.error)}
                />
              );
            }}
          </Field>
        </div>
      </div>

      <div className=" mb-0 mt-12 flex items-center justify-center">
        <Typography variant="h2" className="mb-2 text-black-800">
          Edit Your Address
        </Typography>
      </div>

      <AddressInputsOrLookup
        address={props.values.TenantLocationAddress!} // TODO: Handle empty addresses
        addressSuggetionsList={addressSuggetionsList}
        change={changeAddress}
        lookUpAddress={handleAdvanceSearch}
        onClickSuggestion={onClickAddressCard}
        isDisplayAddressLookup={props.isDisplayAddressLookup}
        toggleAddressLookup={props.onToggleAddressLookup}
        isSubmitting={props.isSubmitting}
        addressInputErrors={props.errors.TenantLocationAddress as FormikErrors<IAddress>}
        // @ts-ignore
        addressInputTouched={props.touched.TenantLocationAddress}
      />

      {props.isShowDiscardChangesConfirmation && (
        <AlertDialog
          id="discardPopup"
          title="Discard Changes"
          size="sm"
          actions={[
            {
              label: 'No',
              action: () => props.setIsShowDiscardChangesConfirmation(false),
              variant: 'outlined',
            },
            {
              label: 'Yes',
              action: () => {
                if (props.onClose) {
                  props.onClose(false);
                }
              },
              variant: 'filled',
            },
          ]}
          content="Are you sure you want to discard your changes ?"
        />
      )}
    </form>
  );
};
