import React from 'react';
import {ARTable} from '../../../../components/AccountsReceivable/ARTable';
import {Typography} from 'spenda-ui-react';
import {RoundedCheckbox} from '../../../../components/inputs/RoundedCheckbox';

export const CustomerListTable = () => {
  const columns = [
    {
      title: '',
      key: '',
      width: '3%',
      align: 'left',
      rowClassName: 'p-2.5',
      headerRenderer: () => {
        return <RoundedCheckbox />;
      },
      rowRenderer: (_index: number) => {
        return <RoundedCheckbox />;
      },
    },
    {
      title: 'Name',
      key: 'name',
      width: '13%',
      align: 'left',
      rowClassName: 'p-2.5',
      rowRenderer: (index: number) => {
        return (
          <Typography data-autoid={`txtName-${index}`}>
            Aiden Young
          </Typography>
        );
      },
    },
    {
      title: 'Email',
      key: 'email',
      width: '13%',
      align: 'left',
      rowClassName: 'p-2.5',
      rowRenderer: (index: number) => {
        return (
          <Typography data-autoid={`txtEmail-${index}`}>
            aiden.young@carpetcourt.com
          </Typography>
        );
      },
    },
    {
      title: 'Contact name',
      key: 'contactName',
      width: '13%',
      align: 'left',
      rowClassName: 'p-2.5',
      rowRenderer: (index: number) => {
        return <Typography data-autoid={`txtContactName-${index}`}>Flo Matthews</Typography>;
      },
    },
    {
      title: 'Phone number',
      key: 'phoneNumber',
      width: '13%',
      align: 'left',
      rowClassName: 'p-2.5',
      rowRenderer: (index: number) => {
        return <Typography data-autoid={`txtPhoneNumber-${index}`}>+61 2 7890 1246</Typography>;
      },
    },
  ];
  return (
    <div>
      <ARTable
        isLoading={false}
        rows={Array(8).fill(2)}
        columns={columns}
        tableClass="!-mx-2.5 !w-[calc(100%+20px)] text-black"
      />
    </div>
  );
};
