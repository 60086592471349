import React, {useEffect, useState} from 'react';
import {AlertDialogSlideV2} from './AlertDialogSlideV2';
import {Typography} from 'spenda-ui-react';

interface IQuoteSentSuccessDialogProps {
  onDoneClick: () => void;
}
export const QuoteSentSuccessDialog = (props: IQuoteSentSuccessDialogProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  }, []);

  return (
    <>
      <AlertDialogSlideV2
        headingClassess="!border-b-0"
        size="sm"
        dialogActionsAlignment="justify-center"
        data-autoid={"dlgQuoteSent"}
        actions={[
          {
            label: 'Done',
            loading: false,
            action: props.onDoneClick,
          },
        ]}
      >
        <div>
          <div className="relative flex w-full justify-center mt-2.5">
            <svg
              width={77}
              height={81}
              viewBox="0 0 77 81"
              className={isLoading ? 'animate-pulse' : ''}
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M70.838 9.855l3.725 23.148c.116.716.07 1.452-.134 2.148a4.789 4.789 0 01-1.044 1.868L35.876 78.543a3.986 3.986 0 01-2.791 1.312 3.927 3.927 0 01-2.867-1.096L3.032 52.552a4.193 4.193 0 01-1.255-2.878 4.253 4.253 0 011.074-2.967L40.373 5.195a4.594 4.594 0 011.732-1.204 4.44 4.44 0 012.066-.286l22.614 2.243c1 .096 1.94.532 2.673 1.238a4.731 4.731 0 011.38 2.67zM62.16 20.28a3.993 3.993 0 002.009-1.208 4.237 4.237 0 001.074-2.96 4.177 4.177 0 00-1.255-2.872 3.887 3.887 0 00-4.378-.728 4.07 4.07 0 00-1.745 1.588 4.248 4.248 0 00-.602 2.317 4.2 4.2 0 00.744 2.266c.46.661 1.1 1.166 1.839 1.45a3.87 3.87 0 002.314.146zM39.824 52.563a6.517 6.517 0 002.822-1.927c1.02-1.181 1.566-2.335 1.64-3.46.089-1.143-.096-2.202-.554-3.176-.443-.992-1.116-2.166-2.02-3.521l5.005-5.802c.462.72.68 1.47.654 2.253a3.546 3.546 0 01-.656 2.051l3.142 2.919c1.162-1.598 1.642-3.248 1.441-4.951-.168-1.705-.946-3.331-2.333-4.878l1.877-2.176-1.674-1.555-1.877 2.176c-1.753-1.338-3.552-1.977-5.397-1.917-1.83.042-3.386.806-4.668 2.293-1.02 1.181-1.573 2.344-1.663 3.487-.072 1.159.114 2.235.557 3.227.458.974 1.131 2.148 2.019 3.52l-5.097 5.91c-.596-.78-.89-1.568-.881-2.367.024-.816.311-1.597.862-2.344l-3.141-2.918c-1.267 1.468-1.789 3.113-1.565 4.933.223 1.82 1.072 3.56 2.547 5.221l-1.877 2.176 1.674 1.555 1.877-2.176c1.208.93 2.448 1.5 3.718 1.713 1.287.228 2.477.14 3.568-.266zM39.488 35.8c-.057-.795.231-1.56.864-2.294.68-.788 1.435-1.215 2.266-1.282.848-.05 1.68.222 2.495.818l-4.68 5.426c-.556-.968-.871-1.858-.945-2.668zm-2.185 13.503c-.861.102-1.724-.134-2.587-.71l4.703-5.454c.556.969.863 1.85.92 2.645.089.793-.175 1.547-.793 2.263-.618.717-1.366 1.135-2.243 1.256z"
                fill="#2C95BC"
              />
              <path
                d="M74.184 5.572a4.712 4.712 0 01-.68 1.996 4.528 4.528 0 01-1.47 1.47l-1.82 1.128-.189-1.157a4.59 4.59 0 00-.489-1.473l1.201-.75c.26-.154.48-.368.643-.627a1.986 1.986 0 00.178-1.76 1.984 1.984 0 00-.492-.752 1.89 1.89 0 00-.76-.455 1.846 1.846 0 00-.876-.054c-.292.052-.57.175-.808.358a1.97 1.97 0 00-.57.694l-5.077 10.272c-.103.206-.257.38-.446.504s-.406.194-.63.203a1.191 1.191 0 01-.623-.147 1.292 1.292 0 01-.62-.764c-.1-.33-.07-.688.083-.995l5.066-10.245a4.571 4.571 0 011.574-1.798 4.352 4.352 0 012.235-.723 4.259 4.259 0 011.706.285 4.366 4.366 0 011.466.944 4.583 4.583 0 011.139 1.75c.243.668.332 1.386.26 2.096z"
                fill="#116799"
              />
            </svg>
            <div className="absolute -bottom-3">
              <svg
                width={46}
                height={48}
                className="z-10"
                viewBox="0 0 46 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M23.05 47.696c12.62 0 22.85-10.62 22.85-23.722C45.9 10.873 35.67.252 23.05.252 10.43.252.2 10.872.2 23.974c0 13.101 10.23 23.722 22.85 23.722z"
                  fill="#1C78AD"
                />
              </svg>
              <svg
                width={22}
                className={`absolute left-[12px] right-0 top-[17px] z-10 ${
                  isLoading ? 'hidden' : 'block scale-100 transform duration-500'
                }`}
                height={16}
                viewBox="0 0 22 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.147 15.88c-.733 0-1.462-.29-2.02-.869l-5.712-5.93a3.044 3.044 0 010-4.193 2.782 2.782 0 014.039 0l3.693 3.834 7.5-7.788a2.781 2.781 0 014.04 0 3.045 3.045 0 010 4.193l-9.521 9.884a2.79 2.79 0 01-2.019.869z"
                  fill="#EDEBEA"
                />
              </svg>
            </div>
          </div>

          <div className="mb-16 mt-8 flex justify-center">
            <Typography variant="h1" className={`${isLoading ? 'text- text-black-900' : 'text-primary'}`}>
              {isLoading ? 'Quote Sending...' : 'Quote Sent'}
            </Typography>
          </div>
        </div>
      </AlertDialogSlideV2>
    </>
  );
};
