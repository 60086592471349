import React from 'react';

export const RefreshIcon = (props: {width?: string; height?: string; className?: string; style?: any}) => {
  return (
    <svg
      width={props.width || '12px'}
      height={props.height || '12px'}
      className={props.className}
      viewBox="0 0 12 12"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      style={props.style}
    >
      <title>Refresh</title>
      <g id="Page-Refresh" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="List-View" transform="translate(-634.000000, -194.000000)" className="fill-primary">
          <g id="Group-9" transform="translate(634.000000, 189.000000)">
            <g id="Icons/Arrows/refresh-Copy" transform="translate(0.000000, 5.000000)">
              <path
                d="M5.97715387,1.00025153 C7.31683798,1.00464659 8.59909515,1.54477177 9.53815387,2.50025153 L7.97715387,2.50025153 C7.7010115,2.50025153 7.47715387,2.72410915 7.47715387,3.00025153 L7.47715387,3.00025153 C7.47715387,3.2763939 7.7010115,3.50025153 7.97715387,3.50025153 L10.0486539,3.50025153 C10.561336,3.49997567 10.976878,3.08443361 10.9771539,2.57175153 L10.9771539,0.500251526 C10.9771539,0.224109152 10.7532962,0.000251526455 10.4771539,0.000251526455 L10.4771539,0.000251526455 C10.2010115,0.000251526455 9.97715387,0.224109152 9.97715387,0.500251526 L9.97715387,1.53925153 C8.2916452,0.0212738154 5.89333023,-0.414513321 3.78154646,0.413475284 C1.66976269,1.24146389 0.206762666,3.19119618 0.00215387094,5.45025153 C-0.0109044322,5.59103648 0.0358333518,5.7308245 0.130942059,5.83544408 C0.226050766,5.94006366 0.36076512,5.99987299 0.502153871,6.00029205 L0.502153871,6.00029205 C0.755586888,6.00348143 0.969832116,5.81328192 0.996653871,5.56125153 C1.22734985,2.98162268 3.38723225,1.00366391 5.97715387,1.00025153 Z"
                id="Path-Refresh"
              ></path>
              <path
                d="M11.4526539,6.000211 C11.1992209,5.99702163 10.9849756,6.18722113 10.9581539,6.43925153 C10.7893717,8.38249137 9.50444666,10.0495243 7.66822775,10.7075278 C5.83200884,11.3655313 3.78074052,10.8940133 2.41615387,9.50025153 L3.97715374,9.50025153 C4.25329625,9.50025153 4.47715374,9.2763939 4.47715374,9.00025153 L4.47715374,9.00025153 C4.47715374,8.72410915 4.25329625,8.50025153 3.97715374,8.50025153 L1.90565387,8.50025153 C1.65935969,8.50011879 1.42311495,8.59789999 1.24895864,8.7720563 C1.07480233,8.94621261 0.977021134,9.18245735 0.977153736,9.42875153 L0.977153736,11.5002515 C0.977153736,11.7763939 1.2010115,12.0002515 1.47715374,12.0002515 L1.47715374,12.0002515 C1.75329625,12.0002515 1.97715374,11.7763939 1.97715374,11.5002515 L1.97715374,10.4612515 C3.66266255,11.9792292 6.06097751,12.4150164 8.17276128,11.5870278 C10.284545,10.7590392 11.7475451,8.80930687 11.9521539,6.55025153 C11.9652122,6.40946657 11.9184744,6.26967855 11.8233657,6.16505897 C11.728257,6.06043939 11.5935426,6.00063007 11.4521539,6.000211 L11.4526539,6.000211 Z"
                id="Path-Refresh"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
