import useHttp from '../hooks/useHttp';
import {IActionResultsList, IActionRes, IActionResults} from '../model/ActionResults';
import {SelectedCreditNotes} from '../model/credit-notes/SelectedCreditNotes';
import {IInvoice} from '../model/invoice/Invoice';
import {ISupplierPaymentResponse} from '../model/payment/SupplierPaymentResponse';
import {IConnectedSupplierStatementSummary} from '../model/supplier/SupplierTransaction';
import {IAppliedCredit, ICreditClaim, ICreditClaims} from './../model/credit-notes/CreditClaims';

export const useCreditClaimsAPI = () => {
  const {GET, POST, DELETE} = useHttp();

  const getClaims = (supplierId: number): Promise<ICreditClaims[]> => {
    return GET(`Claim/SearchV2`, {
      maxResults: 1000,
      'filter.SupplierID': supplierId,
      'filter.StatusStrings': 'Not_Sent',
    }).then((data: IActionResultsList<ICreditClaims>) => {
      return data?.Items;
    });
  };

  const getClaim = (supplierId: number, claimId?: number, claimCode?: string): Promise<ICreditClaim> => {
    return GET(`v2/Claim?supplierId=${supplierId}&id=${claimId || ''}&claimCode=${claimCode || ''}`).then(
      (data: IActionResults<ICreditClaim>) => data.Value
    );
  };

  const saveClaim = (claim: ICreditClaim): Promise<ICreditClaim> => {
    const _claim = {Value: claim};
    return POST('v2/Claim', _claim).then((data: ICreditClaim) => data);
  };

  const deleteClaim = (creditId: number): Promise<boolean> => {
    return DELETE(`claim?id=${creditId}`).then((data: IActionRes) => data.IsSuccess);
  };

  const payCreditClaimsInvoice = (req: Partial<IAppliedCredit>): Promise<IActionResults<ISupplierPaymentResponse>> => {
    // const payCreditClaimsInvoice = (req: Partial<IAppliedCredit>): Promise<SynkSaveQueueResponse<IPaymentT>> => {
    const payload = {
      Value: req,
    };
    return POST('SupplierTransactions/invoice/paymentwithcreditnotesallocation', payload);
  };

  const getTotalPaymentAndCreditToApplyValues = (
    claim: Partial<SelectedCreditNotes>[],
    invoices: IConnectedSupplierStatementSummary[] | Partial<IInvoice>[],
    credits: Partial<SelectedCreditNotes>[]
  ): Promise<IAppliedCredit> => {
    const payload = {
      value: {
        ConnectedSupplierStatements: [...invoices, ...credits],
        ClaimDetails: claim,
      },
    };

    return POST('SupplierTransactions/GetCreditNoteAllocationPaymentSummary', payload).then(
      (data: IActionResults<IAppliedCredit>) => data?.Value
    );
  };

  return {
    getClaims,
    getClaim,
    saveClaim,
    deleteClaim,
    getTotalPaymentAndCreditToApplyValues,
    payCreditClaimsInvoice,
  };
};
