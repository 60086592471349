import React, {PropsWithChildren, createContext, useState} from 'react';
import {useNotificationsAPI} from '../services/useNotificationsAPI';

export interface INotification {
  content: string;
  contentFormat?: 'Html' | 'Plain';
  createdDateTime_utc: string;
  datTypeID: number;
  data: any;
  documentID: number;
  isAcknowledged: boolean;
  isAcknowledgementRequired: boolean;
  pushNotificationID: number;
  refNumber?: string;
}

interface NotificationContextValue {
  notifications: INotification[];
  addNotification: (notification: INotification) => void;
  removeNotification: (index: number) => void;
  refreshNotifications: () => void;
  acknowledgedNotification: (notificationId: number) => void;
}

export const NotificationContext = createContext<NotificationContextValue>({
  notifications: [],
  addNotification: () => {},
  removeNotification: () => {},
  refreshNotifications: () => {},
  acknowledgedNotification: (_notificationId: number) => {},
});

export const NotificationProvider: React.FC<PropsWithChildren> = ({children}) => {
  const {get, acknowledge} = useNotificationsAPI();

  const [notifications, setNotifications] = useState<INotification[]>([]);

  const addNotification = (newNotification: INotification) => {
    // check if notification is of type array and add each notification to the list
    if (Array.isArray(newNotification)) {
      setNotifications([...newNotification, ...notifications]);
      return;
    }

    // if notification is an object add it to the list
    setNotifications([newNotification, ...notifications]);
  };

  const removeNotification = (index: number) => {
    setNotifications(notifications.filter((_, i) => i !== index));
  };

  const refreshNotifications = async () => {
    const notificationsResponse = await get();
    setNotifications(notificationsResponse);
  };

  const acknowledgedNotification = async (notificationId: number) => {
    const refreshedNotifications = await acknowledge([notificationId]);
    setNotifications(refreshedNotifications);
  };

  return (
    <NotificationContext.Provider
      value={{notifications, addNotification, removeNotification, refreshNotifications, acknowledgedNotification}}
    >
      {children}
    </NotificationContext.Provider>
  );
};
