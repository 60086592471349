import React, { useState } from 'react';
import { Layout } from '../../../../components/layout/Layout';
import { StagingAreaHeader } from './StagingAreaHeader';
import { StagingAreaTable } from './StagingAreaTable';

const mockData = [
  { sku: 'XZY-00123', code: '09000D048', productName: '', uom: 'm', costPrice: '$16.20', sellPrice: '$23.95', revenueAccount: 'Spenda01', expenseAccount: 'Spenda001', warningMessage:'Product name is missing' },
  { sku: 'XZY-00124', code: '', productName: 'Alira Gloss - Citrine Blackbutt', uom: 'm', costPrice: '$18.50', sellPrice: '$25.00', revenueAccount: 'Spenda02', expenseAccount: 'Spenda002' , warningMessage:'Code is a mandatory field'},
  { sku: 'XZY-00125', code: '09000D050', productName: 'Amalfi - Positano', uom: 'm', costPrice: '$20.00', sellPrice: '$30.00', revenueAccount: 'Spenda03', expenseAccount: 'Spenda003', warningMessage:'Invalid revenue account entered' },
  { sku: '', code: '09000D051', productName: 'Enchantment - Shale Grey', uom: 'm', costPrice: '$22.00', sellPrice: '$35.00', revenueAccount: 'Spenda04', expenseAccount: 'Spenda004', warningMessage:'SKU is a mandatory field' },
  { sku: 'XZY-00127', code: '09000D051', productName: 'Florence Oak - Cottesloe', uom: 'm', costPrice: '$22.00', sellPrice: '$35.00', revenueAccount: 'Spenda04', expenseAccount: 'Spenda005', warningMessage:'' },
];

const mockValidationResults = [
  { sku: 'valid', code: 'valid', productName: 'missing', uom: 'valid', costPrice: 'valid', sellPrice: 'valid', revenueAccount: 'valid', expenseAccount: 'valid'},
  { sku: 'valid', code: 'missing', productName: 'valid', uom: 'valid', costPrice: 'valid', sellPrice: 'valid', revenueAccount: 'valid', expenseAccount: 'valid' },
  { sku: 'valid', code: 'valid', productName: 'valid', uom: 'valid', costPrice: 'valid', sellPrice: 'valid', revenueAccount: 'invalid', expenseAccount: 'valid'},
  { sku: 'missing', code: 'valid', productName: 'valid', uom: 'valid', costPrice: 'valid', sellPrice: 'valid', revenueAccount: 'valid', expenseAccount: 'valid' },
  { sku: 'valid', code: 'valid', productName: 'valid', uom: 'valid', costPrice: 'valid', sellPrice: 'valid', revenueAccount: 'valid', expenseAccount: 'valid' },
];

export const StagingArea = () => {
   //As of now giving static data for, will be replaced with API data
   const [currentPage, setCurrentPage] = useState(1);
   const [pageSize, setPageSize] = useState(10);
 
   const handlePageChange = (newPage: number) => {
     setCurrentPage(newPage);
   };
 
   const handleRowsPerPageChange = (rows: number) => {
     setPageSize(rows);
   };
 
   const totalCount = mockData.length;
 
   const _leftPanel = (
     <div className="h-full rounded-md bg-white p-2.5 text-black-800 shadow-[0_0_6px_0_#D3E5EF]">
       <StagingAreaHeader validationResults={mockValidationResults} />
       <StagingAreaTable
         data={mockData}
         validationResults={mockValidationResults}
         pageSize={pageSize}
         totalCount={totalCount}
         currentPage={currentPage}
         handlePageChange={handlePageChange}
         handleRowsPerPageChange={handleRowsPerPageChange}
       />
     </div>
   );

  return (
    <div className={`relative h-full overflow-hidden bg-spenda-newbg font-poppins`}>
      <Layout leftPanel={_leftPanel} splitWidthType={4} />
    </div>
  );
};
