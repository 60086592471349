import React, {PropsWithChildren, useState} from 'react';
import {useLoadUninvoicedJob} from '../../../../hooks/useLoadUninvoicedJob';
import LoadingIndicator from '../../../../components/ui/LoadingIndicator';
import {UninvoicedContext} from './UninvoicedContext';

export const UninvoicedProvider = ({children}: PropsWithChildren) => {
  const [selectedServiceJobs, setSelectedServiceJobs] = useState<number[]>([] as number[]);

  const {
    items,
    searchFilter,
    currentPage,
    totalCount,
    isLoading,
    setSearchFilter,
    handlePageChange,
    handleRowsPerPageChange,
    pageSize,
  } = useLoadUninvoicedJob();

  return (
    <UninvoicedContext.Provider
      value={{
        searchFilter,
        setSearchFilter,
        currentPage,
        handlePageChange,
        handleRowsPerPageChange,
        pageSize,
        totalCount,
        items,
        selectedServiceJobs,
        setSelectedServiceJobs,
      }}
    >
      <div className={`relative h-full overflow-hidden bg-[rgba(211,229,239,0.5)]`}>
        <LoadingIndicator isLoading={isLoading} size="md" />
        {children}
      </div>
    </UninvoicedContext.Provider>
  );
};
