import React, {useState, useEffect, useContext} from 'react';
import {Button, IconButton, Dialog, DialogBody, DialogFooter, DialogHeader, Input, Typography} from 'spenda-ui-react';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import {Form, Formik} from 'formik';
import * as Yup from 'yup';

import {DatTypes} from '../../model/constants/Constants';
import {ApplicationInfo} from '../../model/constants/ApplicationInfo';
import AppContext from '../../context/app/appContext';
import useAlertAPI from '../../services/useAlertAPI';
import {Toast} from '../../utils/Toast';


interface ISendAsQuote {
  DocID: any;
  Subject: string;
  WebsiteID: number;
  DatTypeID: number;
  To: string;
  Cc: string;
}

const initialState: ISendAsQuote = {
  DocID: null,
  Subject: '',
  WebsiteID: ApplicationInfo.WebsiteId,
  DatTypeID: 0,
  To: '',
  Cc: '',
};

export interface ISalesOrderSendEmail {
  open: boolean;
  email: string;
  docId: number;
  datTypeID?: number;
  handleClose: () => void;
}

const validationSchema = Yup.object({
  To: Yup.string().email().required('Email Address is required.'),
  DatTypeID: Yup.number()
    .required()
    .oneOf([DatTypes.Invoice, DatTypes.SalesOrderQuoteDetailed, DatTypes.SalesOrderQuoteTotalsOnly]),
});

const getSubject = (datTypeId: DatTypes) => {
  switch (datTypeId) {
    case DatTypes.Invoice:
      return 'Spenda - Invoice';
    case DatTypes.SalesOrderQuoteDetailed:
    case DatTypes.SalesOrderQuoteTotalsOnly:
      return 'Spenda - Quote';
    default:
      return 'Spenda - ';
  }
};

export const SalesOrderSendEmail = (props: ISalesOrderSendEmail) => {
  const {user} = useContext(AppContext);
  const {open, email, docId, datTypeID, handleClose} = props;

  const {sendEmailAlert} = useAlertAPI();

  const [initialValues, setInitalValues] = useState<ISendAsQuote>(initialState);

  useEffect(() => {
    if (open) {
      setInitalValues({
        ...initialValues,
        To: email,
        DocID: docId,
        ...(datTypeID ? {DatTypeID: datTypeID} : {}),
      });
    }
  }, [open]);

  const onSubmit = async (values: ISendAsQuote): Promise<void> => {
    values.Subject = getSubject(values.DatTypeID);
    if (
      values.DatTypeID === DatTypes.SalesOrderQuoteDetailed ||
      values.DatTypeID === DatTypes.SalesOrderQuoteTotalsOnly
    ) {
      values.Cc = user?.UserName || '';
    }
    const res = await sendEmailAlert(values);
    if (res) {
      Toast.info('Email Sent Successfully');
      handleClose();
    }
  };
  return (
    <Dialog
      open={open}
      handler={handleClose}
      size="sm"
      className="relative justify-center rounded-md bg-white font-poppins"
    >
      <Formik
        validateOnMount
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {({values, handleChange, setFieldValue, isSubmitting, errors, touched}) => (
          <Form>
            <DialogHeader className="flex items-center justify-center border-b border-gray-300 pb-2 pt-2 text-xl text-spenda-primarytext">
              <Typography variant="h2" className="font-poppins font-light">
                {!values.DatTypeID ? 'Send as quote' : null}
                {values.DatTypeID ? 'Send quote' : null}
              </Typography>
            </DialogHeader>
            <DialogBody className="rebrandingTxtField space-y-4 py-10 text-center font-poppins text-xs text-spenda-primarytext">
              {!values.DatTypeID ? (
                <>
                  <Typography variant="xsmall" className="">
                    There are two options when sending as a quote:
                  </Typography>
                  <Typography variant="xsmall" className="">
                    <span className="font-medium">Detailed quote:</span> that includes all line item prices
                  </Typography>
                  <Typography variant="xsmall" className="">
                    <span className="font-medium">Totals only:</span> displays the total prices but excludes all line
                    item prices
                  </Typography>
                </>
              ) : null}
              {values.DatTypeID ? (
                <>
                  <Typography variant="xsmall">
                    Please confirm the email address is correct before sending quote.
                  </Typography>
                  <Input
                    id="To"
                    name="To"
                    helperText={touched.To && errors.To ? errors.To : ''}
                    error={touched.To && Boolean(errors.To)}
                    label="Email"
                    value={values.To}
                    onChange={handleChange}
                    adornmentContainerClass="pr-0"
                    containerProps={{
                      className: 'w-[300px] mx-auto text-center',
                    }}
                    InputProp={{
                      endAdornment: values.To ? (
                        <IconButton
                          variant="text"
                          onClick={() => setFieldValue('To', '')}
                          onMouseDown={e => e.preventDefault()}
                        >
                          <ClearRoundedIcon />
                        </IconButton>
                      ) : (
                        <></>
                      ),
                    }}
                  />
                </>
              ) : null}
            </DialogBody>
            <DialogFooter className="mx-2.5 mb-2.5 justify-between rounded-lg bg-spenda-footerBg p-2.5">
              {!values.DatTypeID ? (
                <>
                  <Button type="button" onClick={() => setFieldValue('DatTypeID', DatTypes.SalesOrderQuoteTotalsOnly)}>
                    Send Totals Only Quote
                  </Button>
                  <Button type="button" onClick={() => setFieldValue('DatTypeID', DatTypes.SalesOrderQuoteDetailed)}>
                    Send Detailed Quote
                  </Button>
                </>
              ) : null}
              {values.DatTypeID ? (
                <>
                  <Button className="bg-white" variant="outlined" type="button" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button disabled={isSubmitting} type="submit">
                    Send
                  </Button>
                </>
              ) : null}
            </DialogFooter>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
