import React from 'react';
//Icon
export const IconServiceCustomer = (props: {width?: string | undefined; height?: string | undefined}) => {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.071 2.929A9.935 9.935 0 0010 0a9.935 9.935 0 00-7.071 2.929A9.935 9.935 0 000 10a9.935 9.935 0 002.929 7.071A9.935 9.935 0 0010 20a9.935 9.935 0 007.071-2.929A9.935 9.935 0 0020 10a9.935 9.935 0 00-2.929-7.071zM5.333 16.572c.278-2.365 2.333-4.191 4.762-4.191a4.79 4.79 0 013.39 1.39 4.72 4.72 0 011.372 2.8 7.441 7.441 0 01-4.762 1.715 7.441 7.441 0 01-4.762-1.714zm4.857-6.286A2.574 2.574 0 017.62 7.714a2.574 2.574 0 012.572-2.571 2.574 2.574 0 012.57 2.571 2.574 2.574 0 01-2.57 2.572zm4.532 2.262a6.524 6.524 0 00-2.382-1.523 4.008 4.008 0 10-6.253-3.32c0 1.38.7 2.6 1.765 3.321a6.529 6.529 0 00-2.216 1.36 6.523 6.523 0 00-1.893 3.212 8.372 8.372 0 01-2.055-5.503c0-4.636 3.771-8.408 8.407-8.408s8.408 3.772 8.408 8.408a8.372 8.372 0 01-2.057 5.504 6.544 6.544 0 00-1.724-3.051z"
        fill="#1C78AD"
      />
    </svg>
  );
};
