import {CircularProgress, MenuItem, Select, makeStyles} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {AlertDatType, AlertType, DatTypes} from '../../model/constants/Constants';
import {ICustomerResolvedConvo, IDocumentResolvedConvo} from '../../model/conversation/Conversation';
import {useConversationAPI} from '../../services/useConversationAPI';
import {CloudDownload} from '../../assets/svg/CloudDownload';
import {ConvAttachment} from '../../assets/svg/ConvAttachment';
import useAlertAPI from '../../services/useAlertAPI';
import {IconButton, Badge} from 'spenda-ui-react';

const useTableTopHeaderCss = makeStyles({
  batchSelect: {
    '& .MuiSelect-nativeInput': {
      margin: 0,
      height: '40px',
      padding: 0,
    },
    '& .MuiSelect-iconOpen': {
      transform: 'rotate(0deg)',
    },
    '& .MuiSelect-iconOutlined': {
      marginTop: '4px',
    },
    '& .MuiSelect-select': {
      color: '#333333',
      fontSize: '14px',
      fontWeight: '600',
      fontFamily: 'poppins',
      lineHeight: '16px',
      padding: '0px',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      height: '40px',
      '& .MuiRadio-root': {
        display: 'none',
      },
    },
    '& svg': {
      color: '#1c78ad',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: '0px!important',
      height: '40px',
      padding: 0,
      marginTop: '4px',
    },
  },
});

interface IResolvedConvoFetchAndDownloadProps {
  txGuid?: string;
  txDatType?: DatTypes;
}

export const ResolvedConvoFetchAndDownload = (props: IResolvedConvoFetchAndDownloadProps) => {
  const {txGuid, txDatType} = props;
  const [resolvedConvos, setResolvedConvos] = useState<IDocumentResolvedConvo[]>();
  const {getResolvedConversations, isLoading} = useConversationAPI();

  useEffect(() => {
    if (txGuid && txDatType && txDatType === DatTypes.Invoice) {
      getResolvedConvoData(txGuid, txDatType);
    }
  }, [txGuid, txDatType]);

  const getResolvedConvoData = async (txGuid: string, txDatType: DatTypes) => {
    try {
      const resolvedConvos = await getResolvedConversations(txGuid, txDatType);
      if (resolvedConvos?.length) {
        setResolvedConvos(resolvedConvos);
      } else {
        setResolvedConvos(undefined);
      }
    } catch {}
  };

  return <>{resolvedConvos?.length && !isLoading && <ResolvedConvoDownload resolvedConvos={resolvedConvos} />}</>;
};

interface IResolvedConvoProps {
  resolvedConvos: IDocumentResolvedConvo[] | ICustomerResolvedConvo[];
}

export const ResolvedConvoDownload = (props: IResolvedConvoProps) => {
  const {resolvedConvos} = props;
  const {downloadDocument, isLoading} = useAlertAPI();
  const classes = useTableTopHeaderCss();

  const handleDownloadResolveConvo = async (conversationId: number) => {
    try {
      const payload = {
        docIDs: [conversationId],
        datTypeID: AlertDatType.AccountsReceivableResolvedConversation,
        alertType: AlertType.MESSAGING,
        websiteID: 13,
      };
      const response = await downloadDocument(payload);
      if (response?.value?.length) {
        window.location.href = response?.value[0];
      }
    } catch {}
  };

  return (
    <>
      {isLoading ? (
        <div className="p-3">
          <CircularProgress color="secondary" size={24} />
        </div>
      ) : (
        <Badge className="h-[22px] w-[22px] font-poppins" content={resolvedConvos?.length} color="error">
          <IconButton variant="outlined">
            <Select
              className={`${classes.batchSelect} flex h-[40px] w-[40px] flex-col items-center justify-center p-0 font-poppins text-black-800`}
              variant="outlined"
              data-autoid={`ddlChoosePsblViewType`}
              value={''}
              IconComponent={ConvAttachment}
              MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
              }}
              inputProps={{
                name: 'selectBatchType',
              }}
            >
              {(resolvedConvos as Array<IDocumentResolvedConvo | ICustomerResolvedConvo>)?.map(resolveConvo => (
                <MenuItem data-autoid={`lblChoosePsblViewTypeOption`} key={1} value={'Hello'}>
                  <div
                    data-autoid={`btnDownloadResolveConvo${resolveConvo.conversationId}`}
                    onClick={() => handleDownloadResolveConvo(resolveConvo.conversationId)}
                    className="flex flex-row items-center gap-3"
                  >
                    <CloudDownload />
                    <p className="font-poppins text-base font-semibold text-[#333333]">
                      {resolveConvo.subject} - {resolveConvo.messageCreatedDate}
                    </p>
                  </div>
                </MenuItem>
              ))}
            </Select>
          </IconButton>
        </Badge>
      )}
    </>
  );
};
