import React from 'react';

import {CreateCatalogueHeader} from './CreateCatalogueHeader';
import {CreateCatalogueTable} from './CreateCatalogueTable';
import {CreateCatalogueFooter} from './CreateCatalogueFooter';
import {Formik} from 'formik';

export const CreateCataloguePanel = () => {
  return (
    <div className={`h-full rounded bg-white p-2.5 text-black-800`}>
      <CreateCatalogueHeader />
      <Formik initialValues={{lines: [0]}} onSubmit={() => {}}>
        <CreateCatalogueTable />
      </Formik>
      <CreateCatalogueFooter />
    </div>
  );
};
