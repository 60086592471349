import * as React from 'react';

export const UploadCatalogueIcon = (props: SVGProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={12} height={16} fill="none" {...props}>
      <path
        fill="#fff"
        d="M10.727 16H1.273c-.6 0-1.091-.49-1.091-1.09V1.09C.182.49.672 0 1.272 0h6.546l4 4v10.91c0 .6-.49 1.09-1.09 1.09z"
      />
      <path fill="#AFD4B7" d="M7.818 0l4 4h-4V0z" />
      <path
        fill="#43A661"
        d="M2.363 6.273v5.09h7.273v-5.09H2.363zm3.182 4.181H3.272V9.273h2.273v1.181zm0-2.09H3.272V7.182h2.273v1.182zm3.182 2.09H6.454V9.273h2.273v1.181zm0-2.09H6.454V7.182h2.273v1.182z"
      />
    </svg>
  );
};
