import {IPagedResults, ISupplier, ISuppliers} from '../model/supplier/Supplier';
import {IActionRes, IActionResults, IActionResultsList, IDashboardActionResults} from '../model/ActionResults';
import {ISearchFilter} from '../model/search-filters/SearchFilter';
import useHttp from '../hooks/useHttp';

export const useSupplierAPI = () => {
  const {isLoading, PUT, GET, POST} = useHttp();

  const search = async (filter: ISearchFilter): Promise<ISupplier[]> => {
    return PUT('v2/supplier/search', filter).then((data: IActionResults<ISupplier[]>) => data.Value);
  };

  const dashboard = async (filter: ISearchFilter): Promise<ISupplier[]> => {
    return GET('dashboard', filter).then((data: IDashboardActionResults<ISupplier[]>) => data.Value);
  };

  const getSupplierById = async (ID: any): Promise<ISupplier> => {
    return GET(`v3/supplier/${ID}`).then((data: IActionResults<ISupplier>) => data.Value);
  };

  const saveSupplier = async (
    payload: Partial<ISupplier>,
    IsRunNow?: boolean,
    IsQueue?: boolean,
  ): Promise<ISupplier | undefined> => {
    return POST('v3/supplier', {Object: payload, IsRunNow, IsQueue}).then(
      (data: IActionResults<ISupplier>) => data.Object,
    );
  };

  const updateAutomaticInvoiceImportEnabled = (Value: boolean, supplierId: number) => {
    return POST(`/supplier/${supplierId}/automaticinvoiceimport`, {Value}).then((data: IActionRes) => data);
  };

  const getSuppliers = async (
    searchFilter: ISearchFilter,
  ): Promise<(IPagedResults & IActionResultsList<ISuppliers>) | undefined> => {
    return GET(`Spenda/Payments/AccountsPayable/Supplier`, searchFilter).then(
      (data: IPagedResults & IActionResultsList<ISuppliers>) => data,
    );
  };

  return {
    isLoading,
    search,
    dashboard,
    getSupplierById,
    saveSupplier,
    updateAutomaticInvoiceImportEnabled,
    getSuppliers,
  };
};
