import {useEffect, useState} from 'react';
import {ISearchFilter} from '../model/search-filters/SearchFilter';
import useServiceJobAPI from './useServiceJobAPI';
import { ICustomerList } from '../model/service-management/serviceJob';

export const useLoadCustomersList = () => {
  const {getCustomers } = useServiceJobAPI()
  let pageSize = 15;

  const initialFilterState = {
    StartRow: 1,
    MaxResults: pageSize,
    SearchString: '',
  } as ISearchFilter;

  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<ICustomerList[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [moreToGet, setMoreToGet] = useState<boolean>(true);
  const [searchFilter, setSearchFilter] = useState<ISearchFilter>(initialFilterState);

  const getCustomersList = async () => {
    setLoading(true);
    try {
        const result = await getCustomers(searchFilter);
        if (result.value === undefined) return;
        let items = result.value.items || [];
        setItems(items);
        setTotalCount(result.value.totalRecordCount || 0);
        setCurrentPage(Math.ceil((searchFilter.StartRow || 1 + pageSize - 1) / (searchFilter.MaxResults || pageSize)));
        setMoreToGet(result.value.moreToGet || false);
    } catch (err) {
        console.error(err)
    } finally {
        setLoading(false);
    }
};

useEffect(() => {
    getCustomersList();
}, [searchFilter]);

return {
    loading,
    items,
    totalCount,
    pageSize: searchFilter.MaxResults || pageSize,
    currentPage,
    moreToGet,
    searchFilter,
    setSearchFilter,
}
}
