import React from 'react';

export const Reports = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="18px" height="18px" viewBox="0 0 100 100" {...props}>
      <g fillRule="evenodd" className="fill-primary stroke-primary">
        <path d="M50 0a49.883 49.883 0 0 0-33.333 12.804V4.167a4.167 4.167 0 0 0-8.334 0v12.5c0 6.903 5.597 12.5 12.5 12.5h12.5a4.167 4.167 0 0 0 0-8.334h-12.5c-.14-.02-.28-.05-.416-.087C34.418 6.644 56.437 4.41 72.986 15.413c16.548 11.003 23.007 32.171 15.42 50.538-7.586 18.368-27.1 28.807-46.59 24.925C22.326 86.993 8.302 69.872 8.333 50A4.167 4.167 0 1 0 0 50c0 27.614 22.386 50 50 50s50-22.386 50-50S77.614 0 50 0Z" />
        <path d="M50 25a4.167 4.167 0 0 0-4.167 4.167V50c0 1.105.44 2.165 1.221 2.946l12.5 12.5a4.167 4.167 0 0 0 5.892-5.892l-11.28-11.279V29.167A4.167 4.167 0 0 0 50 25Z" />
      </g>
    </svg>
  );
};
