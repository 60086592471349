import React from 'react';
import {SVGProps} from 'react';

export const AddIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={'20px'} height={'20px'} {...props}>
      <g className="fill-primary">
        <path
          style={{
            stroke: 'none',
            fillRule: 'evenodd',
            fillOpacity: 1,
            strokeWidth: 1,
          }}
          id="addPath"
          d="M9.788 19.044a8.888 8.888 0 01-8.89-8.888 8.89 8.89 0 018.89-8.89 8.89 8.89 0 018.888 8.89 8.888 8.888 0 01-8.888 8.888zm0-18.666C4.388.378.01 4.756.01 10.156s4.378 9.777 9.778 9.777 9.777-4.377 9.777-9.777c0-5.4-4.377-9.778-9.777-9.778zm4.888 9.333h-4.444V5.267a.445.445 0 00-.889 0V9.71H4.9a.445.445 0 000 .889h4.444v4.444a.445.445 0 00.889 0V10.6h4.444a.445.445 0 000-.889z"
        />
      </g>
    </svg>
  );
};
