import * as React from 'react';
const APSuppierAddIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" {...props} fill="none">
    <path
      fill="#1C78AD"
      fillRule="evenodd"
      d="M30.083 19.083h-9.166V9.917a.917.917 0 0 0-1.834 0v9.166H9.917a.917.917 0 0 0 0 1.834h9.166v9.166a.917.917 0 1 0 1.834 0v-9.166h9.166a.917.917 0 1 0 0-1.834Z"
      clipRule="evenodd"
    />
    <rect width="39" height="39" x=".5" y=".5" stroke="#1C78AD" rx="5.5" />
  </svg>
);
export default APSuppierAddIcon;
