import React, {useContext, useEffect, useState} from 'react';
import {LabelValue} from '../form/LabelField';
import TransactionTotal from './TransactionTotal';
import {css} from 'glamor';
import {useParams, RouteProps, useHistory} from 'react-router-dom';
import {useSupplierTransactionsAPI} from '../../services/useSupplierTransactionsAPI';
import {IRefBusTran, IInvoice} from '../../model/invoice/Invoice';
import {SButton} from '../buttons/Button';
import {
  ConversationType,
  DateFormat,
  DatTypes,
  IAlertTypes,
  InvoicePaymentAllocationStatus,
  PaymentStatusType,
  StartConvoType,
  TransactionSubType,
} from '../../model/constants/Constants';
import {IAlertInfo, IAlertRequestRequest} from '../../model/alert/AlertInfo';
import TransactionDetail from '../../assets/svg/TransactionDetail.svg';
import {PriceFormat} from '../../utils/formatter';
import {CircularProgress} from '@material-ui/core';
import {ITxDeduction} from '../../model/payment/PaymentT';
import {PaymentSummary} from './PaymentSummary';
import {useInvoiceAPI} from '../../services/useInvoiceAPI';
import {useCustomerStatementsAPI} from '../../services/useCustomerStatementsAPI';
import {IConnectedSupplierStatementSummary} from '../../model/supplier/SupplierTransaction';
import moment from 'moment';
import {ITenantSupplierPaymentMethod} from '../../model/payment/PaymentMethod';
import {PaymentReminderDialog} from '../dialog/PaymentReminderDialog';
import {AUTH_SELLING_COLLECT_ROUTE} from '../../routes/AuthenticatedRoutes';
import {useTenantInfo} from '../../hooks/useTenantInfo';
import {InvoiceDetail} from '../ui/InvoiceDetailsComponent/InvoiceDetail';
import {ISupplierWhoRaisedInvoice} from '../../model/supplier/SupplierWhoRaisedInvoice';
import {AlertDialogSlide} from '../dialog/AlertDialogSlide';
import {Toast} from '../../utils/Toast';
import useSpendaPaymentServicesAPI from '../../services/useSpendaPaymentServicesAPI';
import PurchasingContext from '../../context/purchasing/purchasingContext';
import {IInvoicePaymentAllocation} from '../../model/invoice/InvoicePaymentAllocation';
import {PrimaryButton, SecondaryButton} from '../buttons/DefaultButtons';
import {useCommonTransactionAPI} from '../../services/useCommonTransactionAPI';
import StartConvo from '../conversationChatBox/StartConvo';
import AppContext from '../../context/app/appContext';

interface ITransactionProps extends RouteProps {
  isPayMode?: boolean;
  linkedSupplierId?: number | string;
  paymentMethods?: ITenantSupplierPaymentMethod[];
  supplierTenant?: ISupplierWhoRaisedInvoice;
  tx?: any;
  togglePayMode?: () => void;
  addInvoiceToPay?: (tx: IConnectedSupplierStatementSummary) => void;
  resetPaymentProcess?: () => void;
  prepayments72511?: boolean;
  onRetryBatch?: (id: number) => void;
}

const getTransactionType = (transType: string): string => {
  let result = transType;
  // Payment and Invoice will stay the same
  if (transType == 'CreditNote') {
    result = 'Credit Note';
  }

  return result;
};
const getDataType = (datType: DatTypes | undefined) => {
  let result = '';
  switch (datType) {
    case DatTypes.Invoice:
      result = 'Invoice';
      break;
    case DatTypes.CreditNote:
      result = 'Credit Note';
      break;
    case DatTypes.Payment:
      result = 'Payment';
      break;
    default:
      return;
  }
  return result;
};

export const Transaction = (props: ITransactionProps) => {
  const pCtx = useContext(PurchasingContext);
  const {isPSBLPIBLEnable, setIsShowConfigureSettingsDialog} = useContext(AppContext);
  const tenant = useTenantInfo();
  const history = useHistory();

  const {prepayments72511} = props;
  const [tx, setTx] = useState<IInvoice>();
  const [batchpaymentTx, setBatchpaymentTx] = useState<IInvoicePaymentAllocation>();
  const [loading, setLoading] = useState(false);
  const [isShowSendInvoiceDialog, setIsShowSendInvoiceDialog] = useState(false);
  const [isShowApproveLoanDialog, setIsShowApproveLoanDialog] = useState(false);

  const [retryAlertDialog, setRetryAlertDialog] = useState(false);
  const handleToggleRetryDialog = () => {
    setRetryAlertDialog(!retryAlertDialog);
  };
  const handleRetryBatch = () => {
    if (batchpaymentTx) {
      props?.onRetryBatch && props.onRetryBatch(batchpaymentTx.invoicePaymentAllocation.invoicePaymentAllocationID);
      handleToggleRetryDialog();
    }
  };

  // @ts-ignore
  let {transactionId, customerId, supplierId} = useParams();
  const queryParams = new URLSearchParams(window.location.search);
  let txDatType: DatTypes | undefined;
  const txGuid = queryParams.get('txGuid');
  if (queryParams.get('txDatType')) {
    txDatType = Number(queryParams.get('txDatType')) as number as DatTypes;
  }

  const isInvoicePaymentAllocation = txDatType === DatTypes.InvoicePaymentAllocation;
  const isPayment = txDatType === DatTypes.Payment;
  const isCreditNote = txDatType === DatTypes.CreditNote;
  const isInvoice = txDatType === DatTypes.Invoice;

  const customerStatementsAPI = useCustomerStatementsAPI();
  const invoiceAPI = useInvoiceAPI();
  const supplierTransactionsAPI = useSupplierTransactionsAPI();

  //Common APIs for buyer and supplier
  const commonTransactionsAPI = useCommonTransactionAPI();

  const {approveLoan, isLoading: isApprovingLoan} = useSpendaPaymentServicesAPI();

  const watermark = css({
    background: 'lightgrey',
    opacity: '50%',
    marginTop: '20px',
  });

  const labelStyles = css({
    color: '#2C95BC',
    backgroundColor: '#D5EAF2',
    fontWeight: '500',
  });

  const noStretch = css({
    width: '1%',
    whiteSpace: 'nowrap',
  });

  useEffect(() => {
    if (!transactionId || (!txGuid && txDatType !== DatTypes.InvoicePaymentAllocation) || !txDatType) return;
    let fetchTransaction: () => Promise<IInvoice | IInvoicePaymentAllocation | undefined>;

    if (tenant.isInSupplierContext) {
      const custRequest = {
        TransID: transactionId,
        TransactionGuid: txGuid!,
      };

      // from supplier context, get invoice data from its own tenancy
      fetchTransaction = () => {
        switch (txDatType) {
          case DatTypes.Invoice:
            return invoiceAPI.getInvoice(transactionId);
          case DatTypes.CreditNote:
            return customerStatementsAPI.getCreditNote(custRequest);
          case DatTypes.Payment:
            return customerStatementsAPI.getPayment(custRequest);
          case DatTypes.InvoicePaymentAllocation:
            return commonTransactionsAPI.getInvoicePaymentAllocation(Number(transactionId));
          default:
            return new Promise(resolve => resolve(undefined));
        }
      };
    } else {
      const request = {
        LinkedSupplierID: props.linkedSupplierId,
        TransactionGuid: txGuid!,
        IsIncludePayments: true,
      };

      // from customer context ... get the invoice data from the linked suppier tenancy
      fetchTransaction = () => {
        switch (txDatType) {
          case DatTypes.Invoice:
            return supplierTransactionsAPI.getInvoice(request);
          case DatTypes.CreditNote:
            return supplierTransactionsAPI.getCreditNote(request);
          case DatTypes.Payment:
            return supplierTransactionsAPI.getPayment(request);
          case DatTypes.InvoicePaymentAllocation:
            return commonTransactionsAPI.getInvoicePaymentAllocation(Number(transactionId));
          default:
            return new Promise(resolve => resolve(undefined));
        }
      };
    }

    fetchTransaction()
      .then(async data => {
        if (data) {
          if (txDatType !== DatTypes.InvoicePaymentAllocation) {
            setTx(data as IInvoice);
          } else {
            setBatchpaymentTx(data as IInvoicePaymentAllocation);
          }
        }
      })
      .catch(e => console.error(e));
  }, [transactionId, props.linkedSupplierId, txGuid, txDatType]);

  useEffect(() => {
    // If payMode is on, add selected tx to invoices to pay
    if (props.isPayMode && tx && props.addInvoiceToPay) {
      if (tx.DatTypeID === DatTypes.Invoice && tx.Balance > 0) {
        props.addInvoiceToPay({
          AmountToPay: tx.AmountToPay, // Partial payments support
          Balance: tx.Balance,
          DatTypeID: tx.DatTypeID,
          RefNumber: tx.RefNumber,
          TransID: tx.ID,
          TransGUID: tx.GUID,
        } as IConnectedSupplierStatementSummary);
      }
    } else {
      setTx(undefined);
      if (props.resetPaymentProcess) {
        props.resetPaymentProcess();
      }
    }
  }, [props.isPayMode]);

  const onOpenPDF: () => Promise<void> = () => {
    if (tenant.isInBuyerContext) {
      // from customer context ... get the invoice data from the linked suppier tenancy
      let alertReq: Partial<IAlertRequestRequest> = {
        LinkedSupplierID: props.linkedSupplierId,
        TransactionGuid: tx && tx.GUID,
      };
      if (isInvoicePaymentAllocation) {
        alertReq = {
          DatTypeID: DatTypes.InvoicePaymentAllocationReceipt,
          DocIDs: [transactionId],
          AlertType: IAlertTypes.Pdf,
          WebsiteID: 13,
        };
        if (!loading) {
          setLoading(true);
        }
        return customerStatementsAPI
          .getBatchPaymentHstoryPrintOut(alertReq)
          .then(data => {
            if (data) {
              data.map(pdf => {
                window.open(pdf);
              });
            }
          })
          .finally(() => setLoading(false));
      } else if (tx && tx.DatTypeID !== DatTypes.Invoice) {
        if (!loading) {
          setLoading(true);
        }
        return supplierTransactionsAPI
          .getCreditNotePrintout(alertReq)
          .then((data: IAlertInfo) => {
            if (data.AlertMsg) {
              const pdf = data.AlertMsg.AttachmentFileNames[0];
              window.open(pdf);
            }
          })
          .finally(() => setLoading(false));
      } else {
        if (!loading) {
          setLoading(true);
        }
        return supplierTransactionsAPI
          .getInvoicePrintout(alertReq)
          .then((data: IAlertInfo) => {
            if (data.AlertMsg) {
              const pdf = data.AlertMsg.AttachmentFileNames[0];
              window.open(pdf);
            }
          })
          .finally(() => setLoading(false));
      }
    } else {
      // from supplier context, get invoice data from its own tenancy
      let alertReq: Partial<IAlertRequestRequest> = {
        TransID: transactionId,
        TransactionGuid: tx && tx.GUID,
      };
      if (isInvoicePaymentAllocation) {
        alertReq = {
          DatTypeID: DatTypes.InvoicePaymentAllocationAdvice,
          DocIDs: [transactionId],
          AlertType: IAlertTypes.Pdf,
          WebsiteID: 13,
        };
        if (!loading) {
          setLoading(true);
        }
        return customerStatementsAPI
          .getBatchPaymentHstoryPrintOut(alertReq)
          .then(data => {
            if (data) {
              data.map(pdf => {
                window.open(pdf);
              });
            }
          })
          .finally(() => setLoading(false));
      } else if (tx && tx.DatTypeID !== DatTypes.Invoice) {
        if (!loading) {
          setLoading(true);
        }
        return customerStatementsAPI
          .getCreditNotePrintOut(alertReq)
          .then((data: IAlertInfo) => {
            if (data.AlertMsg) {
              const pdf = data.AlertMsg.AttachmentFileNames[0];
              window.open(pdf);
            }
          })
          .finally(() => setLoading(false));
      } else {
        if (!loading) {
          setLoading(true);
        }
        return customerStatementsAPI
          .getInvoicePrintOut(alertReq)
          .then((data: IAlertInfo) => {
            if (data.AlertMsg) {
              const pdf = data.AlertMsg.AttachmentFileNames[0];
              window.open(pdf);
            }
          })
          .finally(() => setLoading(false));
      }
    }
  };

  const onClickPayment = (payment: ITxDeduction) => {
    if (!payment.GUID) {
      return;
    }

    if (tenant.isInBuyerContext) {
      const alertReq: Partial<IAlertRequestRequest> = {
        LinkedSupplierID: props.linkedSupplierId,
        TransactionGuid: payment.GUID,
      };

      if (payment.DatType === 'Credit Note') {
        supplierTransactionsAPI.getCreditNotePrintout(alertReq).then((data: IAlertInfo) => {
          if (data.AlertMsg) {
            const pdf = data.AlertMsg.AttachmentFileNames[0];
            window.open(pdf, '_blank');
          }
        });
      } else if (payment.DatType === 'Invoice') {
        supplierTransactionsAPI.getInvoicePrintout(alertReq).then((data: IAlertInfo) => {
          if (data.AlertMsg) {
            const pdf = data.AlertMsg.AttachmentFileNames[0];
            window.open(pdf, '_blank');
          }
        });
      } else {
        supplierTransactionsAPI.getPaymentAlert(alertReq).then((data: IAlertInfo) => {
          if (data.AlertMsg) {
            const pdf = data.AlertMsg.AttachmentFileNames[0];
            window.open(pdf, '_blank');
          }
        });
      }
    } else {
      const alertReq: Partial<IAlertRequestRequest> = {
        TransID: transactionId,
        TransactionGuid: payment.GUID,
      };
      if (payment.DatType === 'Credit Note') {
        alertReq.TransID = payment.ID;
        customerStatementsAPI.getCreditNotePrintOut(alertReq).then((data: IAlertInfo) => {
          if (data.AlertMsg) {
            const pdf = data.AlertMsg.AttachmentFileNames[0];
            window.open(pdf, '_blank');
          }
        });
      } else if (payment.DatType === 'Invoice') {
        alertReq.TransID = payment.ID;
        customerStatementsAPI.getInvoicePrintOut(alertReq).then((data: IAlertInfo) => {
          if (data.AlertMsg) {
            const pdf = data.AlertMsg.AttachmentFileNames[0];
            window.open(pdf, '_blank');
          }
        });
      } else {
        alertReq.TransID = payment.ID;
        customerStatementsAPI.getPaymentAlert(alertReq).then((data: IAlertInfo) => {
          if (data.AlertMsg) {
            const pdf = data.AlertMsg.AttachmentFileNames[0];
            window.open(pdf, '_blank');
          }
        });
      }
    }
  };

  if (!transactionId || !(tx || batchpaymentTx)) return <div></div>;

  let txDeductions: ITxDeduction[] = [];

  if (tx?.Payments && tx?.Payments.length) {
    txDeductions = tx?.Payments.filter(
      p => p.Status === PaymentStatusType.Paid || p.Status === PaymentStatusType.InProgress,
    ).map(p => {
      return {
        DatType: 'Payment',
        RefNumber: p.RefNumber,
        Status: p.Status,
        AppliedDate: p.AppliedDate,
        Amount: p.AppliedAmount,
        GUID: p.GUID,
        ID: p.ID,
      } as ITxDeduction;
    });
  }

  if (tx?.RefBusTrans && tx?.RefBusTrans.length) {
    txDeductions = [
      ...txDeductions,
      ...tx?.RefBusTrans.map(r => {
        return {
          ID: r.ID,
          DatType: getTransactionType(r.TransType),
          RefNumber: r.RefNumber,
          Status: 'Applied',
          AppliedDate: r.AppliedDate,
          Amount: r.AppliedAmount,
          GUID: r.GUID,
        } as ITxDeduction;
      }),
    ];
  }

  if (tx?.LinkedTrans && tx?.LinkedTrans.length) {
    txDeductions = [
      ...txDeductions,
      ...tx?.LinkedTrans.filter(lt => lt.DatTypeID === DatTypes.Payment || lt.DatTypeID === DatTypes.CreditNote).map(
        lt => {
          return {
            ID: lt.ID,
            GUID: lt.GUIDstring,
            DatType: lt.DatTypeString,
            RefNumber: lt.RefNumber,
            Status: 'Applied',
            AppliedDate: lt.AppliedDate,
            Amount: lt.AppliedAmount,
          } as ITxDeduction;
        },
      ),
    ];
  }

  const failedPayments: ITxDeduction[] =
    (tx?.Payments && tx?.Payments.filter(p => p.Status === PaymentStatusType.Failed)) || [];

  const isSupplier = !!window.location?.href?.includes(`${AUTH_SELLING_COLLECT_ROUTE}/`);

  const isPaid =
    (tx?.Balance || 0) <= 0 && tx?.Status?.toLocaleLowerCase() === PaymentStatusType.Paid.toLocaleLowerCase();

  const onLoanApproval = async (loanId?: number) => {
    if (!tx?.Loan?.ID) {
      Toast.info('Invalid Load ID');
      return;
    }

    await approveLoan(loanId!);
    Toast.info('Funding has been approved');
    setIsShowApproveLoanDialog(false);
    const t = {...tx};
    if (!t.Loan) return;
    t.Loan.IsPending = false;
    setTx(t);
  };

  const retryInvoiceCount = batchpaymentTx?.invoicePaymentAllocation.live_Invoice_Count ?? 0;

  const enableRetryBatchButton =
    batchpaymentTx &&
    retryInvoiceCount > 0 &&
    isInvoicePaymentAllocation &&
    (batchpaymentTx.invoicePaymentAllocation.status === InvoicePaymentAllocationStatus.Failed ||
      batchpaymentTx.invoicePaymentAllocation.status === InvoicePaymentAllocationStatus.Cancelled ||
      batchpaymentTx.invoicePaymentAllocation.status === InvoicePaymentAllocationStatus.Declined);

  const invoiceFooter = (
    <>
      <div className="flex justify-between">
        <div className="justify-between">
          {!isPayment && (
            <SecondaryButton
              margin="0px 5px 0px 0px"
              label="Save PDF"
              onClick={onOpenPDF}
              isSubmitting={loading}
              disabled={loading}
            />
          )}
          {isSupplier && isInvoice && tx?.Loan && tx.Loan.IsPending && (
            <SecondaryButton
              margin="0px 5px 0px 0px"
              label="Approve Loan"
              onClick={() => setIsShowApproveLoanDialog(true)}
            />
          )}

          {isSupplier && isInvoice && (
            <SecondaryButton
              label="Send Invoice"
              onClick={() => {
                if (isPSBLPIBLEnable) {
                  setIsShowSendInvoiceDialog(true);
                } else {
                  setIsShowConfigureSettingsDialog(true);
                }
              }}
              disabled={isShowSendInvoiceDialog}
              margin="0px 5px 0px 0px"
            />
          )}
          {tx && tx?.DatTypeID === DatTypes.Invoice && (
            <StartConvo
              operationUniqueId={tx.GUID}
              conversationWith={(tenant.isInBuyerContext ? props.supplierTenant?.Name : tx.CustomerName) || ''}
              refNumber={tx.RefNumber}
              guid={tx.GUID}
              datTypeID={tx.DatTypeID}
              supplierID={tenant.isInBuyerContext ? supplierId : null}
              customerID={tenant.isInSupplierContext ? customerId : null}
              messageCount={undefined}
              unreadMessages={undefined}
              conversationId={undefined}
              convoType={ConversationType.DOCUMENT}
              contextConvos={pCtx.conversations}
              startConvoType={StartConvoType.BUTTON}
              transactionId={tx?.ID}
            />
          )}
        </div>

        <div className="flex justify-between">
          <>
            {!isInvoicePaymentAllocation && (
              <SecondaryButton
                label="Close"
                onClick={() => history.push(`/supplier/${props.linkedSupplierId}/bills`)}
              />
            )}
            {enableRetryBatchButton && (
              <>
                {retryAlertDialog && (
                  <RetryDialog handleToggleAlertDialog={handleToggleRetryDialog} handleRetryBatch={handleRetryBatch} />
                )}
                <PrimaryButton
                  label={`Retry ${retryInvoiceCount} ${retryInvoiceCount > 1 ? 'invoices' : 'invoice'}`}
                  margin="0px 0px 0px 5px"
                  onClick={handleToggleRetryDialog}
                />
              </>
            )}
          </>
        </div>
      </div>
    </>
  );

  return isInvoice || isCreditNote || isInvoicePaymentAllocation || (isPayment && prepayments72511) ? (
    <>
      <InvoiceDetail
        tx={txDatType === DatTypes.InvoicePaymentAllocation ? batchpaymentTx : tx}
        isPaid={isPaid}
        supplier={props.supplierTenant}
        footer={invoiceFooter}
        isStickyFooter
        fullWidth
        showBoxShadow={false}
        isCreditNote={isCreditNote}
        isPayment={isPayment}
        isInvoicePaymentAllocation={isInvoicePaymentAllocation}
      />
      {isShowSendInvoiceDialog && (
        <PaymentReminderDialog isSendByLink={true} closeDialog={() => setIsShowSendInvoiceDialog(false)} />
      )}

      {isShowApproveLoanDialog && (
        <AlertDialogSlide
          maxWidth="xs"
          title="Confirm Funding Approval"
          footer={
            <div className="flex w-full justify-between px-4 py-2">
              <SButton
                margin="0 0.25rem"
                color="transparent"
                border="1px solid #1C78AD"
                textColor="blueShade"
                lineHeight="m"
                width="m"
                type="button"
                onClick={() => setIsShowApproveLoanDialog(false)}
              >
                Cancel
              </SButton>
              <SButton
                isSubmitting={isApprovingLoan}
                disabled={isApprovingLoan}
                margin="0 0.25rem"
                color="blueShade"
                textColor="white"
                lineHeight="m"
                width="m"
                type="button"
                onClick={() => onLoanApproval(tx?.Loan?.ID)}
              >
                Approve
              </SButton>
            </div>
          }
        >
          <p className="pb-3 text-center font-medium">{`Are you sure you want to approve funding for invoice ${tx?.RefNumber}?`}</p>
        </AlertDialogSlide>
      )}

      {}
    </>
  ) : (
    <div className={`h-full rounded bg-white p-2 ${!(txDatType || txGuid) && 'opacity-50'}`}>
      <div>
        <img className="float-left" alt="TransactionDetail" src={TransactionDetail} />
        {/* Alex: Have to put this hard coded condition with DatTypeID instead of using TransType but API load is missing it. */}
        <h3 className="ml-2 inline text-xl font-semibold tracking-wide">
          {tx?.Status} {getDataType(tx?.DatTypeID)}: {tx?.RefNumber}
        </h3>
      </div>
      <hr />

      <div className="flex flex-col" style={{height: 'calc(100% - 35px)'}}>
        <div
          className="mb-4 grid gap-2 lg:gap-x-8"
          style={{gridTemplateColumns: 'repeat(auto-fit, minmax(80px, 1fr))'}}
        >
          {isPayment && tx && (
            <>
              <LabelValue
                label={getDataType(tx.DatTypeID) + ' Date'}
                value={moment(tx.DateTime).format(DateFormat.DD_MMM_YYY)}
              />
              <LabelValue label={getDataType(tx.DatTypeID) + ' Ref'} value={tx.RefNumber} />
              <LabelValue label="Payment Status" value={tx.Status} />
            </>
          )}

          {!isPayment && tx && (
            <>
              <div>
                <LabelValue
                  label={getDataType(tx.DatTypeID) + ' Date'}
                  value={moment(tx.TransDateString).format(DateFormat.DD_MMM_YYY)}
                />
                {!isCreditNote && (
                  <LabelValue label="Due Date" value={moment(tx.DueDate).format(DateFormat.DD_MMM_YYY)} />
                )}
                <LabelValue
                  label={getDataType(tx.DatTypeID) + ' Ref'}
                  value={tenant.isInBuyerContext ? tx.CustomerRefTransRefNumber : tx.RefTransRefNumber}
                />
                <LabelValue label="Customer Ref" value={tx.CustomerRefNumber} />
              </div>
              <div>
                <LabelValue
                  label="Sales Rep"
                  value={
                    tx.RepUserName
                      ? tx.RepUserName
                      : tx.RepFirstName && tx.RepLastName
                        ? tx.RepFirstName + ' ' + tx.RepLastName
                        : tx.RepFirstName
                          ? tx.RepFirstName
                          : ''
                  }
                />
                <LabelValue
                  label="Delivery Method"
                  value={tx.DeliveryMethod ? tx.DeliveryMethod.replace(/_/g, ' ') : ''}
                />
                <LabelValue label="Warehouse" value={tx.WarehouseName || ''} />
              </div>

              <div>
                <div>
                  <label style={{color: '#4ea7c6'}}>Bill To</label>
                  <p
                    className="leading-none"
                    dangerouslySetInnerHTML={{
                      __html: tx.BillStreetAddress && tx.BillStreetAddress.replace('\\n', ' '),
                    }}
                  ></p>
                  <p className="leading-none">
                    {tx.BillCity} {tx.BillState}
                  </p>
                  <p className="leading-none">{tx.BillPostCode}</p>
                </div>
              </div>

              <div>
                <div>
                  <label style={{color: '#4ea7c6'}}>Ship To</label>
                  <p
                    className="leading-none"
                    dangerouslySetInnerHTML={{
                      __html: tx.ShipStreetAddress && tx.ShipStreetAddress.replace('\\n', ' '),
                    }}
                  ></p>
                  <p className="leading-none">
                    {tx.ShipCity} {tx.ShipState}
                  </p>
                  <p className="leading-none">{tx.ShipPostCode}</p>
                </div>
              </div>
            </>
          )}
        </div>
        {isPayment && tx && (
          <div style={{flex: 1, overflow: 'auto'}}>
            <section style={{height: '100%', overflow: 'auto'}}>
              <table className="sticky-table w-full">
                <thead className={`uppercase`}>
                  <tr>
                    <th className={`border-b border-l py-1 pl-2 text-left ${labelStyles}`}>
                      {isPayment ? 'INV REF NUMBER' : 'REF NUMBER'}
                    </th>
                    <th className={`border-b py-1 text-left ${labelStyles}`}>DATE</th>
                    <th className={`border-b py-1 text-right  ${labelStyles}`}>APPLIED AMOUNT</th>
                    <th className={`border-b border-r py-1 text-right  ${labelStyles} pr-2`}>
                      {isPayment ? 'INV BALANCE' : 'BALANCE'}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {tx.RefBusTrans?.map((inv: IRefBusTran, id: number) => (
                    <tr key={id}>
                      <td className="border-b border-l py-1 pl-2 text-left">
                        {inv.TransSubType === TransactionSubType.MerchantSurcharge ? (
                          <div>
                            <p> Surcharge </p>
                            <p> {inv.RefNumber}</p>
                          </div>
                        ) : (
                          inv.RefNumber
                        )}{' '}
                      </td>
                      <td className="border-b py-1 text-left">
                        {moment(inv.AppliedDate).format(DateFormat.DD_MMM_YYY)}
                      </td>
                      <td className="border-b py-1 text-right">{inv.AppliedAmountString}</td>
                      <td className="border-b border-r py-1 pr-2 text-right">{PriceFormat(inv.Balance || 0)}</td>
                    </tr>
                  ))}
                  {!tx.RefBusTrans && (
                    <tr>
                      <td
                        colSpan={4}
                        className={`text-grey mx-auto h-8 flex-col items-center justify-center text-center ${watermark}`}
                      >
                        THERE ARE NO INVOICES ATTACHED TO THIS PAYMENT
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </section>
          </div>
        )}
        {tx?.Lines && (
          <div style={{flex: 1, overflow: 'auto'}}>
            <section style={{height: '100%', overflow: 'auto'}}>
              <table className="sticky-table w-full">
                <thead className={`uppercase`}>
                  <tr>
                    <th className={`border-b border-l py-1 pl-2 text-left ${labelStyles}`}>ITEM</th>
                    <th className={`border-b py-1 text-left ${labelStyles}`}>DESCRIPTION</th>
                    <th className={`border-b py-1 text-right ${labelStyles} ${noStretch}`}>UNIT PRICE</th>
                    <th className={`border-b py-1 text-center ${labelStyles} ${noStretch}`} style={{width: '10%'}}>
                      QTY
                    </th>
                    <th className={`border-b border-r py-1 text-right ${labelStyles} ${noStretch} pr-2`}>TOTAL (EX)</th>
                  </tr>
                </thead>
                <tbody>
                  {tx.Lines.map((l, id: number) => (
                    <tr key={id}>
                      <td className="border-b border-l py-1 pl-2 text-left">{l.Code}</td>
                      <td className="border-b py-1 text-left">{l.ShortDescription}</td>
                      <td className={`border-b py-1 text-right ${noStretch}`}>{l.SellPriceExString}</td>
                      <td className={`border-b py-1 text-center ${noStretch}`} style={{width: '10%'}}>
                        {l.Quantity}
                      </td>
                      <td className={`border-b border-r py-1 pr-2 text-right ${noStretch}`}>
                        {PriceFormat(l.LineTotalEx)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </section>
          </div>
        )}

        <div className="mt-2">
          <div>
            <TransactionTotal
              paymentMethods={props.paymentMethods}
              onClickPayment={onClickPayment}
              deductions={txDeductions}
              subtotal={isPayment ? tx?.Amount : tx?.TotalEx}
              tax={tx?.TotalTax}
              total={isPayment ? tx?.AppliedAmount : tx?.TotalInc}
              tx={tx}
            />

            <div className={`flex text-right`}>
              <div className={`flex-1 font-semibold`}>{isPayment ? 'Credit' : 'Balance'}</div>
              <div style={{flex: '0 1 25%'}}>
                <h3>
                  <b>{PriceFormat(tx?.Balance || 0)}</b>
                </h3>
              </div>
            </div>

            {isPayment ? <p></p> : <PaymentSummary payments={failedPayments} isFailed={true}></PaymentSummary>}
          </div>
          {!isPayment && (
            <div className="absolute" style={{bottom: '15px'}}>
              <SButton color="purple" onClick={onOpenPDF} disabled={loading}>
                Open PDF {loading && <CircularProgress size={24} />}
              </SButton>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const RetryDialog = (props: {handleToggleAlertDialog: () => void; handleRetryBatch: () => void}) => {
  const {handleToggleAlertDialog, handleRetryBatch} = props;
  return (
    <AlertDialogSlide
      title="Retry batch payment"
      titleColor="black"
      fontFamily={'poppins'}
      paddingY={30}
      actions={[
        {
          label: 'Cancel',
          textColor: 'blueShade',
          variant: 'outlined',
          color: 'white',
          border: '1px solid #1C78AD',
          height: '40px',
          width: '100px',
          action: handleToggleAlertDialog,
        },
        {
          label: 'Retry',
          height: '40px',
          width: '100px',
          color: 'blueShade',
          action: handleRetryBatch,
        },
      ]}
    >
      <p className="pb-3">
        Your current batch will be saved and you can pick up where you left off after you've retried this batch.
      </p>
    </AlertDialogSlide>
  );
};
