import React from 'react';
import {SModal} from '../modals/modalSpendaMeterialUI';
import {Box} from '@material-ui/core';
import {Button, Typography} from 'spenda-ui-react';
import TechnicianDisclaimer from '../../assets/svg/service-management/TechnicianDisclaimer';
import {isMobile} from 'react-device-detect';
import GooglePlayStore from '../../assets/svg/service-management/GooglePlayStore';
import AppleStore from '../../assets/svg/service-management/AppleStore';

export interface IDisclaimerModalProps {
  handleClose: () => void;
}

export default function TechnicianDisclaimerModal(props: IDisclaimerModalProps) {
  return (
    <SModal open id="disclaimerModal">
      <div className={`spenda-color flex h-full max-w-full items-center justify-center text-xl`}>
        <div className={`modal-box mx-6 !h-auto max-w-[383px] px-5 pb-5`}>
          <Box className={`-mx-5 flex justify-between rounded-t-xl bg-[rgba(227,238,245,0.6)] p-2`}>
            <h2 className={`w-full text-center font-poppins text-[24px] font-light text-spenda-primarytext`}>
              Disclaimer
            </h2>
          </Box>
          <div className={`relative mx-auto w-full max-w-[372px] py-[40px]`}>
            <Box className="flex flex-col items-center justify-center text-center">
              <TechnicianDisclaimer width="109px" data-autoid="imgDisclaimerIcon" />
              <Typography className="mt-7 text-spenda-headingtext" variant="paragraph">
                {`${isMobile ? 'Thank you for registering with Spenda! To begin reviewing jobs, please download the "Spenda Jobs app" from your preferred app store.' : 'Thank you for registering with Spenda! To begin reviewing jobs, please download the "Spenda Jobs app"from your preferred app store on your mobile device.'}`}
              </Typography>
            </Box>
          </div>
          <Box className={`flex w-full flex-col items-center justify-center gap-y-2`}>
            {!isMobile ? (
              <Button data-autoid="btnClose" onClick={props.handleClose}>
                Close Window
              </Button>
            ) : (
              <>
                <AppleStore onClick={props.handleClose} className="cursor-pointer" />
                <GooglePlayStore onClick={props.handleClose} className="cursor-pointer" />
              </>
            )}
          </Box>
        </div>
      </div>
    </SModal>
  );
}
