import React, {PropsWithChildren, useContext, useEffect} from 'react';
import {FormikProps, Formik, Form} from 'formik';
import * as Yup from 'yup';
import {
  TextField,
  InputAdornment,
  makeStyles,
  Box,
  Popper,
  Grow,
  ClickAwayListener,
  IconButton,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import SearchInvoiceIcon from '../../assets/svg/IconSearch';
import {PriceFormat} from '../../utils/formatter';

import {css} from 'glamor';
import LoadingIndicator from '../ui/LoadingIndicator';
import Plane from '../../assets/svg/Plane.svg';
import {useLocation, useParams} from 'react-router-dom';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import {usePopupState, bindToggle, bindPopper} from 'material-ui-popup-state/hooks';
import {DatTypes, PaymentTabs} from '../../model/constants/Constants';
import AppContext from '../../context/app/appContext';
import {ArRefreshBtn} from '../arRefresh/ArRefresh';

export interface SearchInputFormValues {
  searchText: string;
}

const formGrid = css({
  display: 'grid',
  gridTemplateColumns: '35% auto',
});

const formGrid1 = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const SearchInput = (
  props: FormikProps<SearchInputFormValues> & {
    placeholder: string;
    clearOnEntityChange?: boolean;
    isSearching?: boolean;
    onSubmit?: (values: any) => void;
    isAccountsPayable?: boolean;
    height?: string;
  }
) => {
  const change = (
    name: 'searchText',
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | React.MouseEvent<HTMLButtonElement>
  ) => {
    e.persist();
    props.handleChange(e);
    props.setFieldTouched(name, true, false);
    props.setFieldError(name, '');
  };

  const color = css({
    color: '#0092a8',
  });

  const handleMouseDownSearch = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
  };

  const useStyles = makeStyles({
    root: {
      '& .MuiInputBase-input': {
        color: '#000',
        textOverflow: 'ellipsis !important',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      },
      '& .MuiInputAdornment-positionStart': {
        left: '5px',
        position: 'relative',
      },
      '& .Mui-error.MuiFormHelperText-filled': {
        bottom: '0',
        margin: '0',
        left: '10%',
        top: '4em',
        position: 'absolute',
        backgroundColor: 'white',
        height: '30px',
        textAlign: 'center',
        padding: '5px',
        borderRadius: '5px',
        boxShadow: '0px 0px 4px #000',
        '&:after, &:before': {
          bottom: '100%',
          left: '50%',
          border: 'solid #ffffff',
          content: "''",
          height: 0,
          width: 0,
          position: 'absolute',
          pointerEvents: ' none',
        },
        '&:after': {
          borderColor: 'transparent',
          borderBottomColor: '#ffffff',
          borderWidth: '7px',
          marginLeft: '-10px',
        },
        '&:before': {
          borderColor: 'transparent',
          borderBottomColor: '#ababab',
          borderWidth: '8px',
          marginLeft: '-11px',
        },
      },
      '& .MuiInput-underline.Mui-error:after': {
        borderColor: 'transparent',
      },
      '& .MuiInput-underline:after , & :before': {
        content: 'none',
      },
      '& .MuiInputBase-root': {
        height: '100%',
      },
      '& .MuiInputBase-root.Mui-focused': {
        backgroundColor: '#1C78AD',
        '& .MuiSvgIcon-root': {
          fill: '#1C78AD',
        },
        '& input#search-text-input': {
          color: 'white',
        },
        '& input#search-text-input::placeholder': {
          color: 'white',
        },
        '& .MuiIconButton-label': {
          color: '#359bc0',
        },
        '& button#go-btn-input': {
          color: 'black',
          backgroundColor: '#fff',
          width: '33px',
          height: '34px',
          fontSize: '18px',
          marginRight: '5px',
        },
        '& button#clear-btn-input': {
          color: 'black',
        },
      },
    },
  });

  let {customerId} = useParams<{customerId: any}>();

  useEffect(() => {
    const customerID = Number(customerId);

    if (customerID) {
      if (props.clearOnEntityChange) {
        props.setFieldValue('searchText', '');
      }
    }
  }, [customerId]);

  const handleClear = (values: any) => {
    if (props.onSubmit) {
      props.onSubmit(values);
      props.setFieldValue('searchText', '');
    }
  };

  const classes = useStyles();
  const hasError = props.touched.searchText && Boolean(props.errors.searchText);

  return (
    <TextField
      classes={{root: classes.root}}
      type="text"
      name="searchText"
      id="search-text-input"
      helperText={props.touched.searchText ? props.errors.searchText : ''}
      error={hasError}
      onChange={e => change('searchText', e)}
      value={props.values.searchText}
      placeholder={props.placeholder}
      style={{
        borderRadius: 4,
        border: hasError ? '1px solid #f44336' : '1px solid #0092a8',
        color: '#0092a8',
        flex: '1 1 50%',
        height: props.height || '',
      }}
      autoComplete="off"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            {!props.isAccountsPayable && <SearchIcon className={`${color}`} />}
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <LoadingIndicator isLoading={props.isSearching} size="sm" color="#BBB" position={{right: '5px'}} />
            {/* <LoadingIndicator isLoading={true} size="sm" color="#BBB" position={{ right: "5px" }} /> */}
            {props.touched.searchText && props.values.searchText && !props.isSearching && (
              <IconButton
                type="button"
                id="clear-btn-input"
                aria-label="clear text string"
                onClick={handleClear}
                className={`${props.isAccountsPayable ? 'inputCleanIcon' : ''} flex text-xs absolute`}
                {...css({right: '0.25em', outline: 'none !important'})}
              >
                <ClearOutlinedIcon />
              </IconButton>
            )}
            {props.touched && !props.isSearching && (
              <IconButton
                aria-label="hit enter and search"
                id="go-btn-input"
                data-autoid="go-search-btn"
                onClick={props.submitForm}
                onMouseDown={handleMouseDownSearch}
              >
                GO
              </IconButton>
            )}
          </InputAdornment>
        ),
      }}
    />
  );
};

export interface ISearchInputAndBucketsFormProps {
  searchFilter: any;
  onSearch: (values: any) => void;
  buckets: any[];
  isSearching?: boolean;
  placeholder?: string;
  clearOnEntityChange?: boolean;
  isAccountsPayable?: boolean;
  totalOwing?: number;
  isPaymentHistoryTab?: boolean;
  apRefreshData?: () => void;
  financialAdaptorName?: string;
}

export const SearchInputAndBucketsForm = (props: ISearchInputAndBucketsFormProps) => {
  const {
    searchFilter,
    onSearch,
    buckets,
    isSearching,
    placeholder,
    clearOnEntityChange,
    isAccountsPayable,
    totalOwing,
    isPaymentHistoryTab,
    apRefreshData,
    financialAdaptorName,
  } = props;
  const searchInputPopup = usePopupState({variant: 'popover', popupId: 'searchInputPopup'});
  const isPaymentHistory = useLocation().pathname.includes(PaymentTabs.PAYMENT_HISTORY);

  const bucketGrid = css({
    display: 'grid',
    gridTemplateColumns: `repeat(${buckets.length}, 1fr)`,
    gridTemplateRows: 'minmax(50px,50px)',
  });

  const searchIconButton = css({
    borderRadius: '6px',
    border: '1px solid #1C78AD',
    padding: '7px',
    width: '40px',
    height: '40px',
  });

  const totalOwingStyle = css({
    '& span, p': {
      color: '#333',
    },
    '& span': {
      fontSize: '22px',
      lineHeight: '22px',
    },
  });

  const apBucketCount = css({
    fontSize: '14px',
    fontWeight: 600,
    padding: '0px!important',
    lineHeight: '15px',
    '& span': {
      fontSize: '10px',
      fontWeight: 400,
    },
  });

  const apBucketText = css({
    fontSize: '10px',
  });

  const handleClickAway = () => {
    searchInputPopup.close();
  };

  const onSubmit = (values: any) => {
    if (onSearch) {
      onSearch(values);
    }
  };

  const validationSchema = Yup.object({
    searchText: Yup.string().trim().min(3, 'Must be at least 3 characters.'),
  });

  return (
    <Formik
      initialValues={{searchText: searchFilter?.SearchString || ''}}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnChange={false}
    >
      {formik => (
        <Form translate="yes" className={`flex max-w-full h-full`}>
          <div className={`flex w-full ${isAccountsPayable || isPaymentHistoryTab ? formGrid1 : formGrid}`}>
            {isAccountsPayable ? (
              <ClickAwayListener onClickAway={handleClickAway}>
                <div>
                  <div className="m-auto" {...bindToggle(searchInputPopup)}>
                    <button
                      type="button"
                      {...searchIconButton}
                      className="flex items-center justify-center"
                      data-autoid="btnSearchIcon"
                    >
                      <SearchInvoiceIcon {...css({color: '#1C78AD'})} />
                    </button>
                  </div>
                  <Popper {...bindPopper(searchInputPopup)} disablePortal={false} placement="right" transition>
                    {({TransitionProps}) => (
                      <Grow {...TransitionProps} timeout={250}>
                        <SearchInput
                          isAccountsPayable={isAccountsPayable}
                          placeholder={'Search'}
                          {...formik}
                          onSubmit={onSubmit}
                          clearOnEntityChange={clearOnEntityChange}
                          isSearching={isSearching}
                        ></SearchInput>
                      </Grow>
                    )}
                  </Popper>
                </div>
              </ClickAwayListener>
            ) : (
              <SearchInput
                placeholder={isPaymentHistoryTab || !placeholder ? 'Search...' : placeholder}
                {...formik}
                onSubmit={onSubmit}
                clearOnEntityChange={clearOnEntityChange}
                isSearching={isSearching}
                height={isPaymentHistoryTab ? '3rem' : ''}
              ></SearchInput>
            )}
            {isAccountsPayable ? (
              <div
                className={`flex flex-col ${
                  !buckets.length ? 'justify-end' : 'justify-center'
                } items-center ${totalOwingStyle}`}
              >
                <p className="text-xs font-bold font-poppins">Total owing</p>
                <span className="font-light font-poppins">{PriceFormat(Number(totalOwing?.toFixed(2)))}</span>
              </div>
            ) : null}
            {isPaymentHistory ? null : buckets.length ? (
              <div className={`flex ${isAccountsPayable ? 'justify-end' : bucketGrid}`}>
                {buckets.map((bucket: any, id: number) => (
                  <Bucket
                    isAccountsPayable={isAccountsPayable}
                    key={id}
                    color={bucket.color}
                    isActive={bucket.isActive}
                    onClick={() => {
                      formik.setFieldValue('searchText', '');
                      return bucket.onClick();
                    }}
                    adorment={{onClick: bucket.onOpenDialog, icon: Plane}}
                    dataAutoId={bucket.dataAutoId}
                    disable={bucket?.disable}
                  >
                    {isAccountsPayable ? (
                      <Box className={`${apBucketText} sm:flex xl:hidden items-center`}>
                        {bucket.count ? `${bucket.count}` : ''} {bucket.shortDescription}
                      </Box>
                    ) : (
                      <Box className={`sm:h-8 sm:flex xl:hidden items-center`}>
                        {bucket.shortDescription}
                        {bucket.count ? `(${bucket.count})` : ''}
                      </Box>
                    )}
                    <Box className={`sm:hidden xl:flex`}>
                      <Box
                        {...css(
                          {paddingRight: '0.75em'},
                          {
                            '@media(max-width: 1440px)': {
                              paddingRight: 0,
                              fontSize: '0.95em',
                            },
                          },
                          {'@media(max-width:1280px)': {fontSize: '0.89em'}}
                        )}
                        className={`${isAccountsPayable ? apBucketCount : ''}`}
                      >
                        {isAccountsPayable ? (
                          <span>
                            {bucket.count ? ` ${bucket.count}` : ''} {bucket.shortDescription}
                          </span>
                        ) : (
                          <span>
                            {bucket.shortDescription}
                            {bucket.count ? `(${bucket.count})` : ''}
                          </span>
                        )}{' '}
                        <br />
                        {bucket.amount}
                      </Box>
                    </Box>
                  </Bucket>
                ))}
              </div>
            ) : null}
          </div>
          {isAccountsPayable ? (
            <div
              className={`flex items-center ml-5`}
              style={{width: financialAdaptorName === 'QuickBooksOnline' ? '260px' : '220px'}}
            >
              <ArRefreshBtn
                handleRefreshData={apRefreshData}
                datTypeId={DatTypes.PurchaseInvoice}
                isAccountsPayable={isAccountsPayable}
                newOnly
              />
            </div>
          ) : null}
        </Form>
      )}
    </Formik>
  );
};

const Bucket = (
  props: PropsWithChildren<{
    color?: 'red' | 'yellow' | 'purple' | 'green' | 'apRed' | 'blue';
    isActive?: boolean;
    onClick: () => {};
    disable?: boolean;
    adorment: any;
    dataAutoId: string;
    isAccountsPayable?: boolean;
  }>
) => {
  let darkColor = 'gray';
  let lightColor = 'lightgray';
  let darkerColor = 'darkgray';

  const {isPSBLPIBLEnable, setIsShowConfigureSettingsDialog} = useContext(AppContext);

  switch (props.color) {
    case 'red':
      darkColor = '#B9624B';
      lightColor = '#e9cfc8';
      darkerColor = '#984f3c';
      break;
    case 'yellow':
      darkColor = '#D2A656';
      lightColor = '#f1e4cb';
      darkerColor = '#bb944d';
      break;
    case 'purple':
      darkColor = '#766BCA';
      lightColor = '#d5d2ee';
      break;
    case 'green':
      darkColor = '#28A49E';
      lightColor = '#93d1ce';
      break;
    case 'apRed':
      darkColor = '#c65d42';
      lightColor = '#fff';
      darkerColor = '#984f3c';
      break;
    case 'blue':
      darkColor = '#1579af';
      lightColor = '#fff';
      darkerColor = '#04466a';
      break;
  }

  const styles = css({
    cursor: 'pointer',
    // fontFamily: 'Saira Extra Condensed',
    borderRadius: '4px',
    marginLeft: '5px',
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'center',
    textAlign: 'center',
    lineHeight: '1',
  });

  const bucketColor = css({
    backgroundColor: props.isActive ? darkColor : lightColor,
    border: `1px solid ${props.isActive ? lightColor : darkColor}`,
    color: props.isActive ? lightColor : darkColor,
    ':hover': {textDecoration: `underline`},
  });

  const apBucketColor = css({
    minWidth: '100px',
    height: '40px',
    borderRadius: '6px',
    padding: '5px 12px',
    backgroundColor: props.isActive ? darkColor : lightColor,
    border: `1px solid ${props.isActive ? lightColor : darkColor}`,
    color: props.isActive ? lightColor : darkColor,
    ':hover': {textDecoration: `none`},
  });

  const filterButton = css({
    flex: props.adorment?.onClick ? '0 1 75%' : '0 1 auto',
  });

  const redPlaneSvgColor = css({
    filter: 'invert(44%) sepia(21%) saturate(1391%) hue-rotate(326deg) brightness(95%) contrast(83%)',
  });

  const orangePlaneSvgColor = css({
    filter: 'invert(23%) sepia(80%) saturate(842%) hue-rotate(335deg) brightness(93%) contrast(88%)',
  });

  const adormentColor = css({
    backgroundColor: darkerColor,
    borderLeft: `1px solid ${lightColor}`,
    borderRadius: '0 4px 4px 0',
    color: lightColor,
  });

  const adorment =
    props.isActive && props.adorment?.onClick ? (
      <div
        onClick={e => {
          if (!isPSBLPIBLEnable) {
            e.stopPropagation();
            setIsShowConfigureSettingsDialog(true);
          } else {
            props.adorment.onClick(e);
          }
        }}
        className={`flex items-center justify-center border-l ${adormentColor} z-10`}
        style={{flex: '0 1 25%'}}
      >
        <img
          alt="Paper plane"
          src={props.adorment.icon}
          className={`${!props.isActive ? (props.color === 'red' ? redPlaneSvgColor : orangePlaneSvgColor) : ''}`}
        />
      </div>
    ) : (
      ''
    );
  return (
    <div
      className={`${
        props.isAccountsPayable ? `${apBucketColor} font-poppins text-center` : ''
      } ${styles} ${bucketColor} relative ${props.disable ? 'pointer-events-none' : ''}`}
      onClick={props.onClick}
    >
      <div
        className={`${props.isAccountsPayable ? 'flex-col' : ''} flex items-center justify-center ${filterButton}`}
        data-autoid={props.dataAutoId}
      >
        {props.isActive && !props.isAccountsPayable && <CheckCircleOutlineIcon />}
        {props.isActive && !props.isAccountsPayable && (
          <svg
            className="w-full absolute bottom-0 left-0 opacity-125 z-10"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 320"
          >
            <path
              fill={lightColor}
              fillOpacity="1"
              d="M0,288L48,261.3C96,235,192,181,288,138.7C384,96,480,64,576,64C672,64,768,96,864,128C960,160,1056,192,1152,181.3C1248,171,1344,117,1392,90.7L1440,64L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
            ></path>
          </svg>
        )}
        {props.children}
      </div>
      {adorment}
    </div>
  );
};
