import React from 'react';

export const IconCreditCard = (props: {width?: string; height?: string}) => (
  <svg
    width={props.width || '35px'}
    height={props.height || '35px'}
    viewBox="0 0 100 100"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Icon Credit Card</title>
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Icon Credit Card" className="fill-primary">
        <path
          d="M79.1666667,13 C90.6668881,13.0137755 99.9862245,22.3331119 100,33.8333333 L100,33.8333333 L100,67.1666667 C99.9862245,78.6668881 90.6668881,87.9862245 79.1666667,88 L79.1666667,88 L20.8333333,88 C9.33311187,87.9862245 0.0137754847,78.6668881 0,67.1666667 L0,67.1666667 L0,33.8333333 C0.0137754847,22.3331119 9.33311187,13.0137755 20.8333333,13 L20.8333333,13 Z M91.6666667,42.1666667 L8.33333333,42.1666667 L8.33333333,67.1666667 C8.33333333,74.070226 13.929774,79.6666667 20.8333333,79.6666667 L20.8333333,79.6666667 L79.1666667,79.6666667 C86.070226,79.6666667 91.6666667,74.070226 91.6666667,67.1666667 L91.6666667,67.1666667 L91.6666667,42.1666667 Z M22.9166667,58.8333333 C26.3684464,58.8333333 29.1666667,61.6315536 29.1666667,65.0833333 C29.1666667,68.535113 26.3684464,71.3333333 22.9166667,71.3333333 C19.464887,71.3333333 16.6666667,68.535113 16.6666667,65.0833333 C16.6666667,61.6315536 19.464887,58.8333333 22.9166667,58.8333333 Z M79.1666667,21.3333333 L20.8333333,21.3333333 C13.929774,21.3333333 8.33333333,26.929774 8.33333333,33.8333333 L8.33333333,33.8333333 L91.6666667,33.8333333 C91.6666667,26.929774 86.070226,21.3333333 79.1666667,21.3333333 L79.1666667,21.3333333 Z"
          id="Combined-Shape"
        ></path>
      </g>
    </g>
  </svg>
);
