import React, {useContext, useState} from 'react';
import {Box, DialogActions, DialogContent, DialogTitle, makeStyles} from '@material-ui/core';
import PhoneIcon from '@material-ui/icons/Phone';
import DraftsIcon from '@material-ui/icons/Drafts';
import {SupportChat} from '../navigation/SupportChat';
import AppContext from '../../context/app/appContext';
import {SDialog} from '../modals/modalSpendaMeterialUI';
import {SButton} from '../buttons/Button';
import LiveChat from '../../assets/svg/live-chat-icon.svg';
import {IAccountResponse} from '../../services/useSpendaPaymentServicesAPI';
import {AccountType} from '../../model/constants/Constants';

interface ISupportDialogProps {
  onClose: () => void;
  bankAccountDetails: IAccountResponse | undefined;
}

export const SupportDialog = (props: ISupportDialogProps) => {
  const [open] = useState(true);
  const {user} = useContext(AppContext);

  const useStyles = makeStyles({
    dialog: {
      fontSize: 18,
      fontWeight: 500,
      justifyContent: 'center',
      maxWidth: 600,
      minWidth: 600,
      minHeight: 745,
      position: 'absolute',
    },
    dialogTitle: {
      color: '#0082BA',
      padding: 38,
    },
    dialogActions: {
      flexDirection: 'column',
    },
  });
  const classes = useStyles();

  const onClose = () => {
    props.onClose();
  };

  return (
    <>
      <SDialog open={open} classes={{paper: classes.dialog}}>
        <DialogTitle classes={{root: classes.dialogTitle}}>
          <Box display="flex" alignItems="center" fontSize="34px" justifyContent="center">
            Do you need help ?
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box fontSize="20px" textAlign="center">
            <Box>
              Looks like you are having a bit of trouble verifying your{' '}
              {props.bankAccountDetails?.accountType === AccountType.BANK_TRANSFER
                ? `bank
              account`
                : `credit card`}
              .
            </Box>
            <Box paddingTop={2} marginBottom="-50px">
              Please contact our support team for assistance.
            </Box>
          </Box>
          <Box
            className="subscription-action-btn w-full"
            justifyContent="center"
            display="flex"
            textAlign="center"
            paddingTop={7}
            paddingBottom={1}
          >
            <SupportChat user={user} className="liveChatBtn">
              <SButton
                color="blue"
                type="submit"
                width="m"
                lineHeight="m"
                fontSize="m"
                onClick={() => {}}
                className="common-next-btn liveChat"
              >
                <img src={LiveChat} alt="Live Chat Icon" className="mr-3" />
                Live Chat
              </SButton>
            </SupportChat>
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center" className="text-gray-700">
            <PhoneIcon fontSize="large" color="secondary" />
            <Box className="text-xl" style={{paddingLeft: '10px'}}>
              1300 682 521
            </Box>
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center" className="text-gray-700 pb-4">
            <DraftsIcon fontSize="large" color="secondary" />
            <Box className="text-xl" style={{paddingLeft: '10px'}}>
              support@spenda.co
            </Box>
          </Box>
        </DialogContent>
        <DialogActions classes={{root: classes.dialogActions}}>
          <SButton color="blue" lineHeight="m" width="m" onClick={onClose} data-autoid="btnDone">
            Done
          </SButton>
        </DialogActions>
      </SDialog>
    </>
  );
};
