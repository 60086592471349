import React, {FunctionComponent, PropsWithChildren} from 'react';
import {Toolbar, makeStyles} from '@material-ui/core';

interface ISTableToolbar extends PropsWithChildren {}

const useToolbarStyles = makeStyles(() => ({
  root: {
    padding: '0px 0px 12px',
  },
}));

export const STableToolbar: FunctionComponent<ISTableToolbar> = props => {
  const classes = useToolbarStyles();
  return (
    <div className="w-full">
      <Toolbar disableGutters variant="dense" className={`${classes.root} flex w-full flex-col`}>
        {props.children}
      </Toolbar>
    </div>
  );
};
