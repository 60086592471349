import React from 'react';

export const CreditNotesIcon = (props: {width?: string; height?: string; className?: string; onClick?: any}) => {
  return (
    <svg width="20px" height="24px" viewBox="0 0 11 14" xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>Credit Notes</title>
      <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <path
          d="M10.105 1.821L9.093.854A3.11 3.11 0 006.932 0H3.056C1.369.002.002 1.307 0 2.917v8.166c.002 1.61 1.369 2.915 3.056 2.917h4.888c1.687-.002 3.054-1.307 3.056-2.917v-7.2a2.833 2.833 0 00-.895-2.062zm-.318 1.296c.176.167.333.351.47.549h-2.14a.957.957 0 01-.957-.957v-2c.206.132.4.282.576.45l2.051 1.958z"
          fill="#D3E5EF"
          transform="translate(-448 -314) translate(448 314)"
        />
        <path
          d="M5.5 4.846c.256 0 .464.19.464.426v.114l.011.003c.56.18.952.655.972 1.206v.042c0 .235-.207.426-.463.426-.246 0-.448-.176-.463-.399v-.027c0-.262-.234-.476-.521-.476-.287 0-.52.214-.52.476 0 .253.217.46.49.475h.03c.798 0 1.447.596 1.447 1.328 0 .568-.397 1.064-.972 1.248l-.012.003v.114c0 .226-.192.411-.434.425H5.5c-.256 0-.464-.19-.464-.425v-.114l-.011-.003c-.56-.18-.952-.655-.972-1.206V8.44c0-.235.207-.426.463-.426.225 0 .416.148.457.358l.007.068c0 .262.233.476.52.476.287 0 .52-.214.52-.476 0-.253-.217-.46-.49-.475l-.03-.001c-.798 0-1.447-.595-1.447-1.327 0-.568.397-1.064.972-1.248l.011-.003v-.114c0-.226.193-.411.435-.425H5.5z"
          fill="#8EBFDA"
          fillRule="nonzero"
          transform="translate(-448 -314) translate(448 314)"
        />
      </g>
    </svg>
  );
};
