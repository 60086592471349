import useHttp from '../hooks/useHttp';
import {
  IARPaymentRequest,
  IARPaymentResponse,
  IRepaymentRequest,
} from '../model/accounts-receivable/AccountsReceivable';

export const useAccountReceivablePaymentAPI = () => {
  const {GET, POST, isLoading} = useHttp();

  const ARBuyerPayment = (filter: IARPaymentRequest): Promise<IARPaymentResponse> => {
    return POST(`Spenda/Payments/AccountsReceivable/Buyer/Payment`, filter).then((data: IARPaymentResponse) => data);
  };

  const ARSupplierPayment = (filter: IARPaymentRequest): Promise<IARPaymentResponse> => {
    return POST(`Spenda/Payments/AccountsReceivable/Supplier/Payment`, filter).then((data: IARPaymentResponse) => data);
  };

  const getBuyerPayment = (supplierId: number, invoicePaymentAllocationID: number): Promise<IARPaymentResponse> => {
    return GET(`Spenda/Payments/AccountsReceivable/Buyer/Payment/${supplierId}/${invoicePaymentAllocationID}`).then(
      (data: IARPaymentResponse) => data
    );
  };

  const getSupplierPayment = (invoicePaymentAllocationID: number): Promise<IARPaymentResponse> => {
    return GET(`Spenda/Payments/AccountsReceivable/Supplier/Payment/${invoicePaymentAllocationID}`).then(
      (data: IARPaymentResponse) => data
    );
  };

  const ARBuyercheckBatchPaymentStatus = (
    linkedSupplierID: number,
    invoicePaymentAllocationID: number
  ): Promise<IARPaymentResponse> => {
    return GET(`Spenda/Payments/AccountsReceivable/Buyer/Payment/${linkedSupplierID}/${invoicePaymentAllocationID}`);
  };

  const makeFacilityRepayment = (facilityGuid: string, filter: IRepaymentRequest) => {
    return POST(`Spenda/Lending/Facilities/${facilityGuid}/Repayments`, filter);
  };

  const checkLendingFacilityRepaymentStatus = (facilityGUID: string, repaymentGUID: string) => {
    return GET(`Spenda/Lending/Facilities/${facilityGUID}/Repayments/${repaymentGUID}`);
  };

  return {
    ARBuyerPayment,
    ARSupplierPayment,
    getBuyerPayment,
    getSupplierPayment,
    ARBuyercheckBatchPaymentStatus,
    makeFacilityRepayment,
    checkLendingFacilityRepaymentStatus,
    isLoading,
  };
};
