import * as React from 'react';
const CrossIcon = (props: SVGProps) => (
  <svg viewBox="0 0 14 14" {...props}>
    <g id="Page-1" stroke="none" strokeWidth={1} fillRule="evenodd">
      <g id="Desktop-1024-x-1024" transform="translate(-899.000000, -190.000000)">
        <g id="Group-17" transform="translate(894.000000, 181.000000)">
          <g id="Icons/General/cross_small" transform="translate(0.000000, 4.000000)">
            <path
              d="M18.0115365,6.01153645 L18.0115365,6.01153645 C17.6210366,5.62115452 16.9880363,5.62115452 16.5975365,6.01153645 L12.0115365,10.5975365 L7.42553645,6.01153645 C7.0350366,5.62115452 6.40203631,5.62115452 6.01153645,6.01153645 L6.01153645,6.01153645 C5.62115452,6.40203631 5.62115452,7.0350366 6.01153645,7.42553645 L10.5975365,12.0115365 L6.01153645,16.5975365 C5.62115452,16.9880363 5.62115452,17.6210366 6.01153645,18.0115365 L6.01153645,18.0115365 C6.40203631,18.4019184 7.0350366,18.4019184 7.42553645,18.0115365 L12.0115365,13.4255365 L16.5975365,18.0115365 C16.9880363,18.4019184 17.6210366,18.4019184 18.0115365,18.0115365 L18.0115365,18.0115365 C18.4019184,17.6210366 18.4019184,16.9880363 18.0115365,16.5975365 L13.4255365,12.0115365 L18.0115365,7.42553645 C18.4019184,7.0350366 18.4019184,6.40203631 18.0115365,6.01153645 Z"
              id="Path"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default CrossIcon;
