import React from 'react';

export const ArrowSend = (props: {width?: string; height?: string}) => {
  return (
    <svg
      width={props.width || '32px'}
      height={props.height || '32px'}
      viewBox="0 0 32 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Select</title>
      <defs>
        <path
          d="M17.4916,4.35913043 L13.4916,0.446086957 C13.4192,0.375652174 13.3192,0.332608696 13.2088,0.332608696 C12.9876,0.332608696 12.8088,0.508695652 12.8088,0.723913043 C12.8088,0.833478261 12.8536,0.931304348 12.926,1.00173913 L16.2432,4.24565217 L0.4088,4.24565217 C0.1876,4.24565217 0.0088,4.42173913 0.0088,4.63695652 C0.0088,4.85217391 0.1876,5.02826087 0.4088,5.02826087 L16.2432,5.02826087 L12.926,8.27217391 C12.8536,8.3426087 12.8088,8.44043478 12.8088,8.55 C12.8088,8.76521739 12.9876,8.94130435 13.2088,8.94130435 C13.3192,8.94130435 13.4192,8.89826087 13.4916,8.82782609 L17.4916,4.91478261 C17.5644,4.84434783 17.6088,4.74652174 17.6088,4.63695652 C17.6088,4.5273913 17.5644,4.42956522 17.4916,4.35913043 Z"
          id="path-Select"
        ></path>
      </defs>
      <g id="Page-Select" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Buyer-screen/start-conv-Copy" transform="translate(-1227.000000, -665.000000)">
          <g id="Group-11" transform="translate(986.000000, 268.000000)">
            <g id="Group-Copy-3" transform="translate(241.000000, 397.000000)">
              <rect id="Rectangle" fill="#1C78AD" x="0" y="0" width="32" height="32" rx="3.36842105"></rect>
              <g id="Arrows/-6" transform="translate(7.000000, 11.500000)">
                <mask id="mask-2" fill="white">
                  <use xlinkHref="#path-Select"></use>
                </mask>
                <use id="Mask" fill="#FFFFFF" xlinkHref="#path-Select"></use>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
