import React from 'react';
import {useHistory} from 'react-router-dom';
import moment from 'moment';
import clsx from 'clsx';

import {AUTH_PURCHASING_DELIVERY_RECEIPT_DETAIL} from '../../routes/PurchasingRoutes';
import {DeliveryReceiptStatusTypes} from '../../model/constants/Constants';
import {IDeliveryReceipt} from '../../model/delivery-receipt/DeliveryReceipt';
import {STable} from '../tables/STable';
import {Toast} from '../../utils/Toast';

interface IDeliveryReceiptList {
  items: IDeliveryReceipt[];
  loading: boolean;
}

const statusRenderer = (_status: DeliveryReceiptStatusTypes) => {
  let style = '';
  let status = '';
  switch (_status) {
    case DeliveryReceiptStatusTypes.In_Progress:
      style = 'bg-[#C68A1920] text-[#C68A19]';
      status = 'In progress';
      break;
    case DeliveryReceiptStatusTypes.Verified:
      style = 'bg-[#8178CF20] text-[#8178CF]';
      status = 'Verified';
      break;
    case DeliveryReceiptStatusTypes.Complete:
      style = 'bg-[#8819A820] text-[#8819A8]';
      status = 'Completed';
      break;
    case DeliveryReceiptStatusTypes.Billed:
      style = 'bg-[#8819A820] text-[#8819A8]';
      status = 'Billed';
      break;
    default:
      status = _status;
      break;
  }
  return (
    <span className={clsx('mx-6 block whitespace-nowrap rounded px-6 py-1 font-poppins text-xs font-medium', style)}>
      {status}
    </span>
  );
};

const columnSetting = {
  RefNumber: {
    title: 'PO ref',
    field: 'RefNumber',
  },
  Name: {
    title: 'Supplier',
    field: 'SupplierName',
  },
  DueDate: {
    title: 'Received on',
    field: 'CreatedDateTime_utc',
    align: 'center',
    render: (rowData: IDeliveryReceipt) =>
      rowData.CreatedDateTime_utc ? moment(rowData.CreatedDateTime_utc).format('Do MMM YYYY') : '',
  },
  ReceivedByUserName: {title: 'Received by', field: 'CreatedByUserName', aligin: 'center'},
  CreatedByUserName: {title: 'Created by', field: 'CreatedByUserName', aligin: 'center'},
  LineCount: {
    title: 'Total lines',
    field: 'LineCount',
    align: 'center',
  },
  Status: {
    title: 'Order Status',
    field: 'Status',
    align: 'center',
    width: '235px',
    render: (rowData: IDeliveryReceipt) => statusRenderer(rowData.Status as DeliveryReceiptStatusTypes),
  },
} as {[key: string]: any};

export const DeliveryReceiptList: React.FC<IDeliveryReceiptList> = ({items, loading}) => {
  const history = useHistory();

  const handleRowClick = async (rowData: IDeliveryReceipt) => {
    if (rowData.ID) {
      history.push(AUTH_PURCHASING_DELIVERY_RECEIPT_DETAIL.replace(/:deliveryReceiptID/g, String(rowData.ID || '')));
    } else {
      Toast.error('Linked requisition not found.');
    }
  };

  return (
    <STable
      newUI={true}
      columns={Object.entries(columnSetting)
        .map(([, value]: [string, any]) => value)
        .filter(value => value)}
      data={items}
      emptyDataMessage={'No requisition found.'}
      stickyHeader={true}
      loading={loading}
      onRowClick={handleRowClick}
    ></STable>
  );
};
