import * as React from 'react';
const PostingInventoryIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" {...props}>
    <path
      fill="#1C78AD"
      d="m7.562 9.399 7.93-7.93h-3.75a.735.735 0 0 1 0-1.469h5.523c.406 0 .735.329.735.735v5.524a.735.735 0 1 1-1.47 0v-3.75l-7.929 7.929a.735.735 0 0 1-1.039-1.04Z"
    />
    <path
      fill="#1C78AD"
      d="M2.571 4.224c-.608 0-1.102.494-1.102 1.103v10.102c0 .608.494 1.102 1.102 1.102h10.103c.608 0 1.101-.494 1.101-1.102v-5.27a.735.735 0 1 1 1.47 0v5.27A2.571 2.571 0 0 1 12.674 18H2.57A2.571 2.571 0 0 1 0 15.429V5.327a2.571 2.571 0 0 1 2.571-2.572h5.817a.735.735 0 1 1 0 1.47H2.57Z"
    />
  </svg>
);
export default PostingInventoryIcon;
