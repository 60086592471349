import * as React from 'react';
export const APCaretDownIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_4250_40773)">
        <rect
          x={-0.416667}
          y={0.416667}
          width={19.1667}
          height={18.3333}
          rx={2.58333}
          transform="matrix(-1 0 0 1 19.167 .833)"
          fill="#fff"
          stroke="#1C78AD"
          strokeWidth={0.833333}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.863 8.112H6.1A.694.694 0 005.613 9.3l3.875 3.875a.694.694 0 00.986 0L14.35 9.3a.694.694 0 00-.486-1.188z"
          fill="#1C78AD"
        />
      </g>
      <defs>
        <clipPath id="clip0_4250_40773">
          <path fill="#fff" transform="matrix(-1 0 0 1 20 .833)" d="M0 0H20V19.1667H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
