import React, {useState, useEffect} from 'react';
import { AlertDialogSlideV2 } from './AlertDialogSlideV2';
import { Textarea } from 'spenda-ui-react';
import { useQuoteContext } from '../../context/quote-context/QuoteContext';

interface IQuoteAddNoteDialog {
  handleCancel: () => void;
  handleAddNote: (internalNote: string, externalNote: string) => void;
  isLoading?: boolean;
}

export const QuoteAddNoteDialog = (props: IQuoteAddNoteDialog) => {
  const {handleCancel, handleAddNote, isLoading} = props;
  const {quotePackage} = useQuoteContext();

  const [internalNote, setInternalNote] = useState<string>('');
  const [externalNote, setExternalNote] = useState<string>('');

  const handleAddNoteClick = () => {
    handleAddNote(internalNote, externalNote);
  };

  useEffect(() => {
    setInternalNote(quotePackage?.internalNote || '');
    setExternalNote(quotePackage?.externalNote || '');
  },[quotePackage]);

  return (
    <>
      <AlertDialogSlideV2
        title={'Add a note'}
        headingTextSize="h2"
        headingClassess="text-black justify-center"
        footerClass="justify-between sm:w-auto w-full"
        dialogClassess="!min-w-[20%] !max-w-[693px] sm:h-auto h-full sm:block flex justify-center items-center flex-col sm:p-0 p-8"
        dialogBodyClassess="flex justify-center"
        data-autoid={'dlgQuoteAddNoteDialog'}
        actions={[
          {
            label: 'Cancel',
            variant: 'outlined',
            action: handleCancel,
          },
          {
            label: 'Add Note',
            loading: isLoading,
            action: handleAddNoteClick,
            disabled: isLoading,
          },
        ]}
      >
        <div className="mx-auto flex w-4/5 flex-col gap-y-10 p-6 text-black-800">
          <Textarea
            label="Internal notes"
            displayLength
            maxLength={200}
            className="min-h-[132px] leading-[1.35rem]"
            data-autoid={'txtInternalNode'}
            containerProps={{ className: 'pb-[7px]' }}
            value={internalNote}
            onChange={e => setInternalNote(e.target.value)}
          />
          <Textarea
            label="Add note for the customer"
            displayLength
            maxLength={200}
            className="min-h-[140px]"
            data-autoid={'txtNotesForCustomer'}
            containerProps={{ className: 'pb-[7px]' }}
            value={externalNote}
            onChange={e => setExternalNote(e.target.value)}
          />
        </div>
      </AlertDialogSlideV2>
    </>
  );
};
