import React from 'react';
import {PSBLFooter} from '../../components/AccountsReceivable/PSBLFooter';
import {PsblWidget} from '../../components/AccountsReceivable/PsblWidget';
import {WidgetPanel} from '../../components/AccountsReceivable/WidgetPanel';
import {MarketplaceLayout} from '../purchasing/MarketplaceLayout';
import {PsblView} from './psbl/PsblView';

interface IBuyerView {
  supplierId: number;
}

export const BuyerEnd = (props: IBuyerView) => {
  // Props
  const {supplierId} = props;

  return (
    <>
      <MarketplaceLayout
        leftPanel={<PsblView supplierId={supplierId} />}
        mainPanel={<WidgetPanel>{<PsblWidget />}</WidgetPanel>}
        splitWidthType={'AR'}
      />
      <PSBLFooter />
    </>
  );
};
