import React, {FunctionComponent, useContext, useMemo, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Button, IconButton, Typography} from 'spenda-ui-react';
import {FormikProps} from 'formik';
import moment from 'moment';
import clsx from 'clsx';

import AppContext from '../../context/app/appContext';
import {AUTH_SELLING_SALES_ORDER_LIST} from '../../routes/SalesOrderRoutes';

import {ApplicationInfo} from '../../model/constants/ApplicationInfo';
import {ILinkedTran} from '../../model/requisition/Requisition';
import {DatTypes, IAlertTypes, SalesOrderInvoicingStatus, SALES_ORDER_STATUS} from '../../model/constants/Constants';
import {SalesOrderReservationStatus} from '../../model/sales-order/SalesOrder';

import {SalesOrderSendEmail} from '../dialog/SalesOrderSendEmail';
import {AlertDialog, AlertDialogSlideV2} from '../dialog/AlertDialogSlideV2';
import {CreateSalesOrderValues} from '../form/SalesOrderForm';

import IconDownload from '../../assets/svg/IconDownload';
import IconEnvelope from '../../assets/svg/IconEnvelope';
import {CloseCross} from '../../assets/svg/CloseCross';
import {LinkedDoc, SentInvoiceIcon} from '../../assets/svg';

import LoadingIndicator from '../ui/LoadingIndicator';
import Placeholder from '../Placeholder';

import useAlertAPI from '../../services/useAlertAPI';
import {useSalesOrderAPI} from '../../services/useSalesOrderAPI';
import {VisibleContent} from '../ui/VisibleContent';

interface ISalesOrderSideBar {
  isLoading: boolean;
  handleApproveOrder: (fProps: FormikProps<CreateSalesOrderValues>, redirectToListing?: boolean) => Promise<void>;
  handleCreateInvoice: (fProps: FormikProps<CreateSalesOrderValues>) => Promise<void>;
}

export const SalesOrderSideBar: FunctionComponent<FormikProps<CreateSalesOrderValues> & ISalesOrderSideBar> = props => {
  const {tenantInfo} = useContext(AppContext);
  const {values, handleApproveOrder, handleCreateInvoice, isLoading: isLoadingProps} = props;

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState<boolean>(false);
  const [sendEmail, setSendEmail] = useState<boolean>(false);
  const [showApproveOrder, setShowApproveOrder] = useState<boolean>(false);
  const [showSendInvoice, setShowSendInvoice] = useState<boolean>(false);
  const [datTypeId, setDatTypeId] = useState<any>(null);
  const [docId, setDocId] = useState<any>(null);
  const history = useHistory();

  const showInvoiceButton = useMemo(
    () =>
      [SalesOrderInvoicingStatus.NotInvoiced, SalesOrderInvoicingStatus.PartiallyInvoiced].includes(
        values?.InvoicingStatus as SalesOrderInvoicingStatus,
      ),
    [values?.InvoicingStatus],
  );

  const disableGeneratePickSlip = useMemo(
    () =>
      values?.Lines?.every(line => !line.IsPhysical) ||
      !values?.Lines?.filter(
        line =>
          line?.InventoryID !== tenantInfo?.TenantUserDetails?.DefaultShippingLineItemInventoryId &&
          line?.InventoryID !== tenantInfo?.TenantUserDetails?.DefaultDiscountLineItemInventoryId,
      ).length,
    [values.Lines],
  );

  const handleSendEmail = (datId?: any, Id?: any) => {
    if (!sendEmail) {
      setDatTypeId(datId);
      setDocId(Id);
    } else {
      setDatTypeId(null);
      setDocId(null);
    }
    setSendEmail(prev => !prev);
  };

  const {getPrintOut, isLoading: isLoadingAlert} = useAlertAPI();
  const {updateSalesOrderStatus, isLoading: isLoadingOrderAPI} = useSalesOrderAPI();

  const handleApproveAndCharge = async () => {
    setShowSendInvoice(true);
    await updateSalesOrderStatus(values.ID, SALES_ORDER_STATUS.APPROVED);
  };

  const isLoading = isLoadingAlert || isLoadingProps;

  const handleDownloadClick = async (linkedTran: ILinkedTran) => {
    const data = await getPrintOut({
      DocIDs: [linkedTran.ID],
      DatTypeID: linkedTran.DatTypeID,
      AlertType: IAlertTypes.Pdf,
      WebsiteID: ApplicationInfo.WebsiteId,
    });
    if (data?.AlertMsg) {
      const pdf = data.AlertMsg.AttachmentFileNames[0];
      window.open(pdf, '_blank');
    }
  };

  function LinkedTransactionActions(props: {linkedTransaction: ILinkedTran}) {
    const {linkedTransaction} = props;

    const actions = [];

    const isInvoiceEmailable =
      linkedTransaction.DatTypeID === DatTypes.Invoice &&
      (VisibleContent({keyPath: 'salesOrders.editOrder.emailLinkedInvoice', children: true}) as boolean);

    if (isInvoiceEmailable) {
      actions.push({
        action: () => handleSendEmail(linkedTransaction.DatTypeID, linkedTransaction.ID),
        dataAutoid: `btnMessageInvoice${linkedTransaction.ID}`,
        icon: <IconEnvelope />,
      });
    }

    const isDownloadable = VisibleContent({
      keyPath: 'salesOrders.editOrder.downloadLinkedTransactions',
      children: true,
    }) as boolean;

    if (isDownloadable) {
      actions.push({
        action: () => handleDownloadClick(linkedTransaction),
        dataAutoid: `btnDownloadDoc${linkedTransaction.ID}`,
        icon: <IconDownload />,
      });
    }

    return (
      actions.length > 0 && (
        <div className="flex items-center justify-center">
          {actions.map(({action, dataAutoid, icon}) => (
            <IconButton
              key={dataAutoid}
              ripple={false}
              variant="text"
              className="bg-transparent"
              onClick={action}
              data-autoid={dataAutoid}
            >
              {icon}
            </IconButton>
          ))}
        </div>
      )
    );
  }

  return (
    <>
      {openConfirmationDialog && (
        <AlertDialogSlideV2
          onCancel={() => setOpenConfirmationDialog(false)}
          showTitleBottomBorder={true}
          title={<span className="font-poppins font-light text-spenda-primarytext">Generate Pickslip</span>}
          headingClassess="font-semibold justify-center"
          headerChildren={
            <IconButton
              variant="text"
              onClick={() => setOpenConfirmationDialog(false)}
              className="!absolute right-2 active:bg-transparent"
              ripple={false}
              name="btnClose"
            >
              <CloseCross />
            </IconButton>
          }
          actions={[
            {
              label: 'View Sales Order List',
              action: () => handleApproveOrder(props, true),
            },
            {
              label: 'View Pickslip',
              action: () => handleApproveOrder(props),
            },
          ]}
          dialogActionsAlignment="justify-between"
        >
          <Typography variant="paragraph" className="my-8 text-center font-poppins">
            Would you like to view your Pickslip or go back to the Sales Order list?
          </Typography>
        </AlertDialogSlideV2>
      )}
      <SalesOrderSendEmail
        open={sendEmail}
        email={values.ContactEmailAddress}
        docId={docId}
        datTypeID={datTypeId}
        handleClose={() => handleSendEmail(null, values.ID)}
      />
      {props.values?.Reservation?.Status === SalesOrderReservationStatus.Active &&
        props.values.Status === SALES_ORDER_STATUS.OPEN && (
          <div className={`mb-2 grid gap-2 rounded-lg bg-white p-3 shadow-[0_0_6px_0_hsl(var(--primary-background))]`}>
            <Button onClick={() => setShowApproveOrder(true)} variant="outlined">
              Approve & Charge
            </Button>
          </div>
        )}
      {(isLoadingProps ||
        props.values.Status === SALES_ORDER_STATUS.OPEN ||
        props.values.Status === SALES_ORDER_STATUS.FINALISED ||
        showInvoiceButton) &&
      props.values?.Reservation?.Status !== SalesOrderReservationStatus.Active ? (
        // how i can apply n(3) class to below grid div
        <VisibleContent keyPath="salesOrders.customerOrderDetails.invoicePickslipQuote">
          <div className={`mb-2 grid gap-2 rounded-lg bg-white p-3 shadow-[0_0_6px_0_hsl(var(--primary-header))]`}>
            {isLoadingProps && <Placeholder className="h-10" />}
            {showInvoiceButton ? (
              <Button disabled={isLoading} onClick={() => handleCreateInvoice(props)} variant="outlined">
                Create Invoice
              </Button>
            ) : null}
            {props.values.Status === SALES_ORDER_STATUS.OPEN || props.values.Status === SALES_ORDER_STATUS.FINALISED ? (
              <>
                <Button
                  variant="outlined"
                  disabled={isLoading || disableGeneratePickSlip}
                  onClick={() => setOpenConfirmationDialog(true)}
                  className="whitespace-nowrap"
                >
                  Generate Pickslip
                </Button>
                <Button
                  variant="outlined"
                  disabled={isLoading}
                  fullWidth={true}
                  className="[&:nth-child(3)]:col-span-2"
                  onClick={() => handleSendEmail(null, values.ID)}
                >
                  Send as Quote
                </Button>
              </>
            ) : null}
          </div>
        </VisibleContent>
      ) : null}
      <div
        className={
          'relative flex flex-1 flex-col rounded-lg bg-white p-4 font-poppins shadow-[0_0_6px_0_hsl(var(--primary-header))]'
        }
      >
        <LoadingIndicator isLoading={isLoading} size="md" />
        {!values.LinkedTrans?.length ? (
          <div className="m-2 h-[315px] rounded-lg bg-primary-background p-2 opacity-70">
            <p className="mx-2 my-8 text-center text-xl font-light text-spenda-primarytext">
              Linked documents will appear here
            </p>
            <div className="my-4 flex justify-center">
              <LinkedDoc className={'h-[126] w-[228]'} viewBox="0 0 228 126" />
            </div>
          </div>
        ) : null}
        {values.LinkedTrans?.map(linkedTran => (
          <div
            key={linkedTran.ID}
            className={clsx('mb-2 flex w-full flex-row justify-between rounded p-3', 'bg-primary-header', {
              'bg-primary-background': [DatTypes.Invoice, DatTypes.ConsignmentNote].includes(linkedTran.DatTypeID),
            })}
          >
            <div className="flex flex-col text-xs  ">
              <span className="underline">{linkedTran.DatTypeString.replace(/([A-Z])/g, ' $1').trim()}</span>
              <span>
                <span className="text-spenda-greytext">Ref Number: </span>
                {linkedTran.RefNumber}
              </span>
              <span>
                <span className="text-spenda-greytext">Generated At: </span>
                {moment(linkedTran.AppliedDate).format('DD/MM/YY')}
              </span>
            </div>

            <LinkedTransactionActions linkedTransaction={linkedTran} />
          </div>
        ))}
        {showApproveOrder && (
          <AlertDialog
            headingTextSize="h2"
            title="Approve & charge"
            size="sm"
            contextTextVariant="small"
            contentClass="px-24 py-12"
            data-autoid="dlgApproveOrder"
            content={`Are you sure you would like to approve and charge sales order ${values.RefNumber} ? This action cannot be undone.`}
            actions={[
              {
                label: 'Cancel',
                action: () => setShowApproveOrder(false),
                variant: 'outlined',
              },
              {
                label: 'Approve & Charge',
                action: handleApproveAndCharge,
              },
            ]}
          />
        )}
        {showSendInvoice && (
          <AlertDialog
            size="sm"
            dialogBodyClassess="text-black border-t-0"
            dialogActionsAlignment="justify-center"
            actions={[
              {
                label: 'Done',
                disabled: isLoadingOrderAPI,
                action: () => history.push(AUTH_SELLING_SALES_ORDER_LIST),
              },
            ]}
          >
            <div className="py-9 ">
              <div className={`mb-12 ${isLoadingOrderAPI ? 'animate-pulse' : null}`}>
                <SentInvoiceIcon className="mx-auto" />
              </div>
              <Typography variant="h3" className="pb-1.5 text-center leading-6">
                Order has been approved and charged
              </Typography>
              <Typography variant="small" className="text-center">
                You will receive a notification once the payment goes through.
              </Typography>
            </div>
          </AlertDialog>
        )}
      </div>
    </>
  );
};
