import {useEffect, useState, useContext} from 'react';
import {assign, omit} from 'lodash';

import AppContext from '../context/app/appContext';
import {IActionResults, IPagedActionResults} from '../model/ActionResults';
import {
  ISupplierSummaries,
  IPurchaseInvoiceSummaries,
  IPurchaseInvoicePaymentSummaries,
  IPurchaseInvoicePaymentResponse,
  IPurchaseInvoiceDetail,
  IPurchaseInvoiceAddInventory,
  IXeroPurchaseInvoice,
} from '../model/purchase-invoice/purchaseInvoice';
import {ISupplier} from '../model/supplier/Supplier';
import {IInvoice} from '../model/invoice/Invoice';
import {DatTypes, PurchaseInvoiceRequestedList, PurchaseInvoiceViewMode} from '../model/constants/Constants';
import {IAlertInfo, IAlertRequestRequest} from '../model/alert/AlertInfo';
import {IPaymentT} from '../model/payment/PaymentT';
import {ISearchFilter} from '../model/search-filters/SearchFilter';
import {ITransactionQueue} from '../model/purchase-invoice/TransactionQueue';
import useHttp from '../hooks/useHttp';

const usePurchaseInvoiceAPI = () => {
  const {GET, POST, PATCH, DELETE, isLoading} = useHttp();

  const search = (filter?: any): Promise<IPagedActionResults<ISupplierSummaries>> => {
    return GET('PurchaseInvoice/Supplier/Summary', filter);
  };

  const getPurchaseInvoice = (id: number): Promise<IInvoice> => {
    return GET(`v2/purchaseinvoice/${id}`).then((data: IActionResults<IInvoice>) => data.Value);
  };

  const getPurchaseInvoicesListBySupplier = (SupplierID: number, filter: any): Promise<IPurchaseInvoiceSummaries> => {
    return GET(`PurchaseInvoice/Supplier/${SupplierID}`, filter).then(
      (data: IActionResults<IPurchaseInvoiceSummaries>) => data.Value
    );
  };

  const getPaymentSummary = (
    selectedInvoices: Partial<any>,
    selectedBucket: PurchaseInvoiceRequestedList
  ): Promise<IPurchaseInvoicePaymentSummaries> => {
    const FilterID = selectedBucket === 3 || selectedBucket === 5 ? [1, 2] : [selectedBucket];
    const purchaseInvoicesRequest: any = [];
    Object.keys(selectedInvoices).forEach(key => {
      if (selectedInvoices[key]?.purchaseInvoiceIds?.length) {
        purchaseInvoicesRequest.push({
          FilterID,
          supplierID: parseInt(key),
          allselected: selectedInvoices[key].allSelected,
          purchaseInvoicesID: selectedInvoices[key].purchaseInvoiceIds || [],
        });
      }
    });

    return POST('PurchaseInvoice/Supplier/GetStatementsPaymentSummary', {
      Value: purchaseInvoicesRequest,
    }).then((data: IActionResults<IPurchaseInvoicePaymentSummaries>) => data.Value);
  };

  const getPurchaseInvoicePrintOut = (filters: Partial<IAlertRequestRequest>): Promise<IAlertInfo> => {
    return GET(`PurchaseInvoice/alert`, filters).then((data: IActionResults<IAlertInfo>) => data.Value);
  };

  const getPurchaseInvoicesList = (filter: any): Promise<IPagedActionResults<IPurchaseInvoiceSummaries>> => {
    return GET(`PurchaseInvoice/Supplier`, filter);
  };

  const payPurchaseInvoice = (
    paymentSummries: IPurchaseInvoicePaymentSummaries
  ): Promise<IPurchaseInvoicePaymentResponse> => {
    return POST('PurchaseInvoice/Supplier/Payment', paymentSummries).then(
      (data: IPurchaseInvoicePaymentResponse) => data
    );
  };

  const getSupplierById = (supplierID: number): Promise<ISupplier> => {
    return GET(`v3/Supplier/${supplierID}`, {}).then((data: IActionResults<ISupplier>) => data.Value);
  };

  const GetFailedPaymentsByPurchaseInvoiceTransID = (transID: number): Promise<Array<IPaymentT>> => {
    return GET(`Payment/GetFailedPaymentsByPurchaseInvoiceID/${transID}`, {}).then(
      (data: IActionResults<Array<IPaymentT>>) => data.Value
    );
  };

  const generateDeliveryReceipt = async (payload: Partial<IPurchaseInvoiceDetail>): Promise<IPurchaseInvoiceDetail> => {
    return POST('PurchaseInvoice/DeliveryReceiptLines', {Value: payload}).then(
      (data: IActionResults<IPurchaseInvoiceDetail>) => data.Value
    );
  };

  const getPurchaseInvoiceById = async (id: number): Promise<IPurchaseInvoiceDetail> => {
    return GET(`v2/purchaseinvoice/${id}`).then((data: IActionResults<IPurchaseInvoiceDetail>) => data.Value);
  };

  const updatePurchaseInvoiceById = async (
    id: any,
    payload: Partial<IPurchaseInvoiceDetail>
  ): Promise<IPurchaseInvoiceDetail> => {
    return PATCH(`PurchaseInvoice/${id}`, {Value: payload}).then(
      (data: IActionResults<IPurchaseInvoiceDetail>) => data.Value
    );
  };

  const deletePurchaseInvoiceById = async (id: any): Promise<void> => {
    return DELETE(`PurchaseInvoice/${id}`);
  };

  const addInventoryPurchaseInvoice = async (
    id: any,
    payload: {InventoryList: IPurchaseInvoiceAddInventory[]}
  ): Promise<IActionResults<IPurchaseInvoiceDetail>> => {
    return POST(`v3/PurchaseInvoice/${id}/inventory`, {Value: payload}).then(
      (data: IActionResults<IPurchaseInvoiceDetail>) => data
    );
  };

  const updatePurchaseInvoiceStatus = async (
    id: any,
    payload: Partial<IPurchaseInvoiceDetail> & {IsQueue: boolean}
  ): Promise<void> => {
    return POST(`PurchaseInvoice/${id}/status`, payload);
  };

  const listPurchaseInvoice = async (filter: ISearchFilter): Promise<IXeroPurchaseInvoice[]> => {
    return GET('PurchaseInvoice', filter).then((data: IActionResults<IXeroPurchaseInvoice[]>) => data.Value);
  };

  return {
    isLoading,
    search,
    getPurchaseInvoice,
    getPurchaseInvoicesListBySupplier,
    getPaymentSummary,
    getPurchaseInvoicePrintOut,
    getPurchaseInvoicesList,
    payPurchaseInvoice,
    getSupplierById,
    GetFailedPaymentsByPurchaseInvoiceTransID,
    generateDeliveryReceipt,
    getPurchaseInvoiceById,
    updatePurchaseInvoiceById,
    deletePurchaseInvoiceById,
    addInventoryPurchaseInvoice,
    updatePurchaseInvoiceStatus,
    listPurchaseInvoice,
  };
};

interface ICount {
  TotalRecordCount: number;
  TotalCountRequiresAttention: number;
  TotalCountReadyToPost: number;
  TotalCountPosted: number;
}

const getUrlAndFilter = (
  searchFilter: ISearchFilter,
  defaultWarehouseID: number | undefined
): {url: string; filter: object} => {
  switch (searchFilter.ViewMode) {
    case PurchaseInvoiceViewMode.All:
      return {
        url: 'PurchaseInvoice',
        filter: assign(omit(searchFilter, ['ViewMode', 'DatTypeID']), {
          WarehouseID: defaultWarehouseID,
          Status: ['Overdue', 'Unfinalised', 'Finalised', 'Paid', 'Cancelled', 'Complete'].join(','),
        }),
      };
    case PurchaseInvoiceViewMode.RequiresAttention:
      return {url: 'transactionqueue/requiresattention', filter: omit(searchFilter, ['ViewMode'])};
    case PurchaseInvoiceViewMode.ReadyToPost:
      return {url: 'transactionqueue/readytopost', filter: omit(searchFilter, ['ViewMode'])};
    case PurchaseInvoiceViewMode.Posted:
      return {url: 'transactionqueue/posted', filter: omit(searchFilter, ['ViewMode'])};
    default:
      return {url: '', filter: {}};
  }
};

export const useLoadPurchaseInvoice = () => {
  const {GET} = useHttp();
  const {tenantInfo} = useContext(AppContext);

  const defaultWarehouseID = tenantInfo?.TenantUserDetails?.DefaultWarehouseID;

  let pageSize = 15;

  const initialFilterState = {
    ViewMode: PurchaseInvoiceViewMode.All,
    DatTypeID: DatTypes.PurchaseInvoice,
    LastRowNumber: 0,
    MaxResults: pageSize,
    SortAsc: false,
    SortField: 'CreatedDateTime',
  } as ISearchFilter;

  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<ITransactionQueue[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [moreToGet, setMoreToGet] = useState<boolean>(true);
  const [error, setError] = useState<any>();
  const [count, setCount] = useState<ICount>({
    TotalRecordCount: 0,
    TotalCountRequiresAttention: 0,
    TotalCountReadyToPost: 0,
    TotalCountPosted: 0,
  });
  const [searchFilter, setSearchFilter] = useState<ISearchFilter>(initialFilterState);

  const getPurchaseInvoiceList = async () => {
    setLoading(true);

    try {
      const {url, filter} = getUrlAndFilter(searchFilter, defaultWarehouseID);

      const {Value, IsMore, TotalRecordCount, LastRowNo, MoreToGet, LastRowNumber} = await GET(url, filter);
      setItems(Value || []);
      setTotalCount(TotalRecordCount);
      setMoreToGet(typeof IsMore === 'boolean' ? IsMore : MoreToGet);
      setCurrentPage(
        typeof LastRowNo === 'number'
          ? Math.ceil(LastRowNo / (searchFilter.MaxResults || pageSize))
          : Math.ceil(LastRowNumber / (searchFilter.MaxResults || pageSize))
      );
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const getCount = async () => {
    const [allRes, requiresAttentionRes, readyToPostRes, postedRes] = await Promise.all([
      GET(
        'PurchaseInvoice',
        assign(omit(searchFilter, ['ViewMode', 'DatTypeID']), {
          WarehouseID: defaultWarehouseID,
          Status: ['Overdue', 'Unfinalised', 'Finalised', 'Paid', 'Cancelled', 'Complete'].join(','),
        })
      ),
      GET('transactionqueue/requiresattention', omit(searchFilter, ['ViewMode'])),
      GET('transactionqueue/readytopost', omit(searchFilter, ['ViewMode'])),
      GET('transactionqueue/posted', omit(searchFilter, ['ViewMode'])),
    ]);
    const AllCounts = {
      TotalRecordCount: allRes.TotalRecordCount,
      TotalCountRequiresAttention: requiresAttentionRes.TotalRecordCount,
      TotalCountReadyToPost: readyToPostRes.TotalRecordCount,
      TotalCountPosted: postedRes.TotalRecordCount,
    } as ICount;
    setCount(AllCounts);
  };

  useEffect(() => {
    getCount();
  }, []);

  useEffect(() => {
    getPurchaseInvoiceList();
  }, [searchFilter]);

  return {
    count,
    loading,
    items,
    totalCount,
    pageSize: searchFilter.MaxResults || pageSize,
    currentPage,
    moreToGet,
    error,
    searchFilter,
    getPurchaseInvoiceList,
    setSearchFilter,
  };
};

export default usePurchaseInvoiceAPI;
