import React, {ChangeEvent, SetStateAction, useEffect, useState} from 'react';
import {
  Box,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
  makeStyles,
} from '@material-ui/core';
import {PermissionTypeIdEnum, UserRoleEnum} from '../../model/user/UserAccountInfo';
import {SButton} from '../buttons/Button';
import LoadingIndicator from '../ui/LoadingIndicator';
import {useTenantInfo} from '../../hooks/useTenantInfo';

const PaymentPermissionFormStyle = makeStyles({
  permissionTable: {
    borderRadius: '8px 8px 0px 0px',
    '& table': {
      background: 'rgba(250, 250, 250, .8)',
      '& th': {
        color: '#999999',
        fontWeight: 600,
        fontSize: '14px',
        width: '180px',
        fontFamily: 'poppins',
      },
      '& .MuiTableHead-root': {
        background: '#ECECEC',
      },
      '& .MuiTableCell-root': {
        borderBottom: '1px solid #F1F1F1',
        borderLeft: '1px solid #F1F1F1',
        padding: '10px',
        fontFamily: 'poppins',
        '&:last-child': {
          borderBottom: '1px solid #F1F1F1',
          borderRight: '1px solid #F1F1F1',
        },
      },
    },
  },
  checkboxField: {
    '& input': {
      borderColor: 'blue',
    },
    '& .Mui-checked svg': {
      fill: '#1c78ad',
      borderRadius: 4,
      width: '23px',
      height: '22px',
    },
  },
  icon: {
    borderRadius: 4,
    width: 18,
    height: 18,
    margin: '2px',
    border: '2px solid #1C78AD',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  userAvatar: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    fontSize: '14.4px',
    marginRight: '10px',
    fontFamily: 'poppins',
  },
  payInvoiceText: {
    width: '165px',
    height: '40px',
    margin: '0px auto',
    fontFamily: 'poppins',
    '& .MuiFormControl-root': {
      paddingBottom: '0px',
      height: '100%',
      '& .MuiOutlinedInput-root': {
        height: '100%',
        backgroundColor: 'transparent',
      },
      '& .MuiFormLabel-root': {
        color: '#333',
        fontFamily: 'Poppins, sans-serif !important',
        width: 'auto',
        fontWeight: 500,
        fontSize: '13px',
        top: '-7px',
        left: '-5px',
      },
      '& .MuiInputLabel-shrink': {
        top: '0px',
        left: 0,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#333',
        '& legend': {
          width: '55px',
          height: ' 9px',
        },
      },
      '& .MuiInputBase-input': {
        fontSize: '14px',
        fontWeight: 600,
        padding: '0px 8px',
      },
    },
  },
  paymentFooter: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
    boxShadow: '0px -2px 10px 0px rgb(0 0 0 / 7%)',
    height: '87px',
    left: 0,
    padding: '0px 20px',
    '& button': {
      fontFamily: 'Poppins, sans-serif !important',
    },
  },
  cancelBtn: {
    '& span': {
      color: '#1c78ad !important',
    },
  },
});
interface RolePermission {
  [key: string]: boolean;
}
interface UserRole {
  [key: string]: RolePermission;
}
type CheckboxInputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  [key: string]: string | undefined;
};
export const PaymentPermissionForm = (props: any) => {
  const {values, setValues, handleSubmit, isSubmitting, isAPSettings, isAPOnboardingV1} = props;
  const [isDisable, setIsDisable] = useState<boolean>(true);
  const [isPrimaryUser, setIsPrimaryUser] = useState<boolean>(false);

  const classes = PaymentPermissionFormStyle();
  const {getUserRoleID} = useTenantInfo();
  useEffect(() => {
    getUserRolePermissions();
  }, []);

  const getUserRolePermissions = async () => {
    const userRoleID = await getUserRoleID();
    if (userRoleID === UserRoleEnum.Primary) {
      setIsPrimaryUser(true);
    }
  };

  const handleApproveCheckbox = (
    e: ChangeEvent<HTMLInputElement>,
    values: UserRole,
    setValues: (_userRole: SetStateAction<UserRole>) => void,
    type: string,
  ) => {
    const role = e.target.name.split('.')[0] as string;
    if (e.target.checked && setValues) {
      setIsDisable(false);
      if (type === 'admin') {
        setValues &&
          setValues({
            ...values,
            [role]: {
              [PermissionTypeIdEnum.ApproveBatch]: true,
              [PermissionTypeIdEnum.CreateBatch]:
                values[UserRoleEnum.Administrator]?.[PermissionTypeIdEnum.CreateBatch],
            },
          });
      } else if (type === 'standard') {
        setValues &&
          setValues({
            ...values,
            [role]: {
              [PermissionTypeIdEnum.ApproveBatch]: true,
              [PermissionTypeIdEnum.CreateBatch]: values[UserRoleEnum.Standard]?.[PermissionTypeIdEnum.CreateBatch],
            },
          });
      }
    } else if (type === 'admin') {
      setValues &&
        setValues({
          ...values,
          [role]: {
            ...values[role],
            [PermissionTypeIdEnum.ApproveBatch]: e.target.checked,
          },
        });
      setIsDisable(false);
    } else if (type === 'standard') {
      setValues &&
        setValues({
          ...values,
          [role]: {
            ...values[role],
            [PermissionTypeIdEnum.ApproveBatch]: e.target.checked,
          },
        });
      setIsDisable(false);
    }
  };

  const handleCreatecheckbox = (
    e: ChangeEvent<HTMLInputElement>,
    values: UserRole,
    setValues: (_userRole: SetStateAction<UserRole>) => void,
    type: string,
  ) => {
    const role = e.target.name.split('.')[0] as string;
    if (e.target.checked && setValues) {
      if (type === 'admin') {
        setValues &&
          setValues({
            ...values,
            [role]: {
              [PermissionTypeIdEnum.ApproveBatch]:
                values[UserRoleEnum.Administrator]?.[PermissionTypeIdEnum.ApproveBatch],
              [PermissionTypeIdEnum.CreateBatch]: true,
            },
          });
      } else if (type === 'standard') {
        setValues &&
          setValues({
            ...values,
            [role]: {
              [PermissionTypeIdEnum.ApproveBatch]: values[UserRoleEnum.Standard]?.[PermissionTypeIdEnum.ApproveBatch],
              [PermissionTypeIdEnum.CreateBatch]: true,
            },
          });
      }
      setIsDisable(false);
    } else if (type === 'admin') {
      setValues &&
        setValues({
          ...values,
          [role]: {
            ...values[role],
            [PermissionTypeIdEnum.CreateBatch]: e.target.checked,
          },
        });
      setIsDisable(false);
    } else if (type === 'standard') {
      setValues &&
        setValues({
          ...values,
          [role]: {
            ...values[role],
            [PermissionTypeIdEnum.CreateBatch]: e.target.checked,
          },
        });
      setIsDisable(false);
    }
  };
  return (
    <Box className="relative">
      {isAPSettings && isPrimaryUser && !isAPOnboardingV1 && (
        <Box className="absolute right-0 flex justify-end	" marginTop="-60px">
          <SButton
            textColor={'white'}
            width="100px"
            height="40px"
            lineHeight="l"
            fontSize="xs"
            boxShadow="none"
            type="submit"
            backgroundColor={isDisable ? 'rgba(28, 120, 173, 0.25)' : '#1C78AD'}
            disabled={isDisable}
            padding="7px 20px!important"
            margin="0"
            variant={'contained'}
            borderRadius="6px"
            onClick={() => {
              setIsDisable(true);
              handleSubmit();
            }}
            data-autoid="btnSave"
          >
            Save <LoadingIndicator isLoading={isSubmitting} size="sm" color="#FFF" position={{right: '5px'}} />
          </SButton>
        </Box>
      )}
      <TableContainer className={`${classes.permissionTable}`}>
        <Table className="" aria-label="simple table" data-autoid="tblPaymentPermission">
          <TableHead>
            <TableRow>
              <TableCell align="left">Users</TableCell>
              <TableCell align="center">{'Approve & pay a batch'}</TableCell>
              <TableCell align="center">Create a batch</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th">
                <div
                  className={`${classes.userAvatar} float-left flex items-center justify-center`}
                  style={{background: 'rgb(198 138 25 / 20%)', color: '#C68A19'}}
                >
                  P
                </div>
                <div className={`float-left mt-2.5 text-base font-medium text-spenda-primarytext`}>Primary</div>
              </TableCell>
              <TableCell align="center">
                <div className={`${classes.checkboxField} flex w-full justify-center`}>
                  <Checkbox
                    id={`${UserRoleEnum.Primary}.${PermissionTypeIdEnum.ApproveBatch}`}
                    name={`${UserRoleEnum.Primary}.${PermissionTypeIdEnum.ApproveBatch}`}
                    size="small"
                    icon={<span className={classes.icon} />}
                    checked={values?.[UserRoleEnum.Primary]?.[PermissionTypeIdEnum.ApproveBatch] || false}
                    inputProps={{'data-autoid': 'chkPrimaryApproveBatch'} as CheckboxInputProps}
                  />
                </div>
              </TableCell>
              <TableCell align="center">
                <div className={`${classes.checkboxField} flex w-full justify-center`}>
                  <Checkbox
                    id={`${UserRoleEnum.Primary}.${PermissionTypeIdEnum.CreateBatch}`}
                    name={`${UserRoleEnum.Primary}.${PermissionTypeIdEnum.CreateBatch}`}
                    size="small"
                    icon={<span className={classes.icon} />}
                    checked={values?.[UserRoleEnum.Primary]?.[PermissionTypeIdEnum.CreateBatch] || false}
                    inputProps={{'data-autoid': 'chkPrimaryCreateBatch'} as CheckboxInputProps}
                  />
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th">
                <div
                  className={`${classes.userAvatar} float-left flex items-center justify-center`}
                  style={{background: 'rgb(132 118 213 / 20%)', color: '#8476D5'}}
                >
                  A
                </div>
                <div className={`float-left mt-2.5 text-base font-semibold text-spenda-primarytext`}>Admin</div>
              </TableCell>
              <TableCell align="center">
                <div className={`${classes.checkboxField} flex w-full justify-center`}>
                  <Checkbox
                    id={`${UserRoleEnum.Administrator}.${PermissionTypeIdEnum.ApproveBatch}`}
                    name={`${UserRoleEnum.Administrator}.${PermissionTypeIdEnum.ApproveBatch}`}
                    size="small"
                    icon={<span className={classes.icon} />}
                    checked={values?.[UserRoleEnum.Administrator]?.[PermissionTypeIdEnum.ApproveBatch] || false}
                    onChange={isPrimaryUser ? e => handleApproveCheckbox(e, values, setValues, 'admin') : undefined}
                    inputProps={{'data-autoid': 'chkAdminApproveBatch'} as CheckboxInputProps}
                  />
                </div>
              </TableCell>
              <TableCell align="center">
                <div className={`${classes.checkboxField} flex w-full justify-center`}>
                  <Checkbox
                    id={`${UserRoleEnum.Administrator}.${PermissionTypeIdEnum.CreateBatch}`}
                    name={`${UserRoleEnum.Administrator}.${PermissionTypeIdEnum.CreateBatch}`}
                    size="small"
                    icon={<span className={classes.icon} />}
                    checked={values?.[UserRoleEnum.Administrator]?.[PermissionTypeIdEnum.CreateBatch] || false}
                    onChange={isPrimaryUser ? e => handleCreatecheckbox(e, values, setValues, 'admin') : undefined}
                    inputProps={{'data-autoid': 'chkAdminCreateBatch'} as CheckboxInputProps}
                  />
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th">
                <div
                  className={`${classes.userAvatar} float-left flex items-center justify-center`}
                  style={{background: 'rgb(197 93 68 / 20%)', color: '#C55D44'}}
                >
                  S
                </div>
                <div className={`float-left mt-2.5 text-base font-semibold text-spenda-primarytext`}>Standard</div>
              </TableCell>
              <TableCell align="center">
                <div className={`${classes.checkboxField} flex w-full justify-center`}>
                  <Checkbox
                    id={`${UserRoleEnum.Standard}.${PermissionTypeIdEnum.ApproveBatch}`}
                    name={`${UserRoleEnum.Standard}.${PermissionTypeIdEnum.ApproveBatch}`}
                    size="small"
                    icon={<span className={classes.icon} />}
                    checked={values?.[UserRoleEnum.Standard]?.[PermissionTypeIdEnum.ApproveBatch] || false}
                    onChange={isPrimaryUser ? e => handleApproveCheckbox(e, values, setValues, 'standard') : undefined}
                    inputProps={{'data-autoid': 'chkStandardApproveBatch'} as CheckboxInputProps}
                  />
                </div>
              </TableCell>
              <TableCell align="center">
                <div className={`${classes.checkboxField} flex w-full justify-center`}>
                  <Checkbox
                    id={`${UserRoleEnum.Standard}.${PermissionTypeIdEnum.CreateBatch}`}
                    name={`${UserRoleEnum.Standard}.${PermissionTypeIdEnum.CreateBatch}`}
                    size="small"
                    icon={<span className={classes.icon} />}
                    checked={values?.[UserRoleEnum.Standard]?.[PermissionTypeIdEnum.CreateBatch] || false}
                    onChange={isPrimaryUser ? e => handleCreatecheckbox(e, values, setValues, 'standard') : undefined}
                    inputProps={{'data-autoid': 'chkStandardCreateBatch'} as CheckboxInputProps}
                  />
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
