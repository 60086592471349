import React from 'react';

export const Invoice = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20px" height="30px" viewBox="0 0 100 100" {...props}>
      <path
        fill="#1C78AD"
        fillRule="evenodd"
        d="m80.896 13.009-6.9-6.909A20.7 20.7 0 0 0 59.263 0h-26.43C21.333.014 12.013 9.333 12 20.834v58.333c.014 11.5 9.333 20.82 20.833 20.833h33.334c11.5-.014 20.82-9.333 20.833-20.833v-51.43a20.7 20.7 0 0 0-6.104-14.728ZM75.004 18.9c.591.589 1.12 1.237 1.58 1.934H66.166V10.417c.695.464 1.344.994 1.937 1.583l6.9 6.9Zm3.663 60.267c0 6.903-5.597 12.5-12.5 12.5H32.833c-6.903 0-12.5-5.597-12.5-12.5V20.834c0-6.904 5.597-12.5 12.5-12.5h25v12.5a8.333 8.333 0 0 0 8.334 8.333h12.5v50ZM66.167 37.5a4.167 4.167 0 0 1 0 8.334H32.833a4.167 4.167 0 0 1 0-8.334h33.334Zm4.166 20.834a4.167 4.167 0 0 1-4.166 4.166H32.833a4.167 4.167 0 0 1 0-8.333h33.334a4.167 4.167 0 0 1 4.166 4.167Zm-.8 14.22a4.167 4.167 0 0 1-.904 5.809 26.933 26.933 0 0 1-14.4 4.97 13.55 13.55 0 0 1-8.333-2.916c-1.367-.938-1.888-1.25-2.917-1.25a16.462 16.462 0 0 0-7.633 3.308 4.167 4.167 0 0 1-5.058-6.616 24.42 24.42 0 0 1 12.708-5.025 12.604 12.604 0 0 1 7.633 2.729A5.612 5.612 0 0 0 54.23 75a19.108 19.108 0 0 0 9.488-3.37 4.167 4.167 0 0 1 5.816.924Z"
      />
    </svg>
  );
};
