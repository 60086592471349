import React, {useEffect, useState} from 'react';
import {ARDialogTemplate} from '../../components/AccountsReceivable/ARDialogTemplate';
import {Button, IconButton, Spinner, Typography} from 'spenda-ui-react';
import IconDownloadClaim from '../../assets/svg/IconDownloadClaim';
import {IconStatementsDocument} from '../../assets/svg/IconStatementsDocument';
import useStatementsAPI, {IStatementsResponse} from '../../services/useStatementsAPI';
import {AlertDatType, AlertType} from '../../model/constants/Constants';
import useAlertAPI from '../../services/useAlertAPI';
import moment from 'moment';

interface IStatementsDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

export const StatementsDialog = (props: IStatementsDialogProps) => {
  //Props
  const {isOpen, onClose} = props;

  //State
  const [statementSummary, setStatementSummary] = useState<IStatementsResponse>();

  //API
  const {getStatements, isLoading} = useStatementsAPI();
  const {downloadDocument} = useAlertAPI();

  useEffect(() => {
    getStatementsSummary();
  }, []);

  const getStatementsSummary = async () => {
    try {
      const response = await getStatements();
      setStatementSummary(response);
    } catch {}
  };

  const handleDownloadPDF = async (docID: number) => {
    try {
      const payload = {
        docIDs: [docID],
        datTypeID: AlertDatType.StatementsPDF,
        alertType: AlertType.DOCUMENT,
        websiteID: 13,
      };
      const response = await downloadDocument(payload);
      if (response?.value?.length) {
        window.location.href = response?.value[0];
      }
    } catch {}
  };

  return (
    <ARDialogTemplate
      dialogProps={{size: 'sm', open: isOpen, className: '!min-w-[611px] !w-[611px]'}}
      isFullScreen
      header={
        <Typography className="font-light text-[#333333]" variant="h2">
          Merchant statements
          <Typography variant="h2" className="inline font-light text-[#333333]">
            {!isLoading && `(${statementSummary?.value?.totalCount})`}
          </Typography>
        </Typography>
      }
      body={
        <div className="my-6  flex h-[300px] w-full flex-col items-center justify-center">
          {isLoading ? (
            <div className="flex h-[300px] w-full flex-col items-center justify-center py-1">
              <Spinner />
            </div>
          ) : (
            <div className="flex max-h-[300px] w-full flex-col gap-3 overflow-y-auto py-1">
              {statementSummary?.value?.values?.map((tl, key) => (
                <div
                  key={key}
                  className="flex w-full flex-row items-center justify-between rounded-md bg-[#FAFAFA] px-4 py-3"
                >
                  <div className="flex flex-row items-center justify-start">
                    <IconStatementsDocument />
                    <Typography variant="paragraph" className="ml-5 text-[18px] font-medium text-[#333333]">
                      {moment(tl?.periodDate).format('MMMM')} {moment(tl?.periodDate).format('YYYY')} Statement{' '}
                    </Typography>
                  </div>
                  <IconButton variant="outlined" onClick={() => handleDownloadPDF(tl?.statementID)}>
                    <IconDownloadClaim />
                  </IconButton>
                </div>
              ))}
            </div>
          )}
        </div>
      }
      footer={
        <div className="flex w-full flex-row items-center justify-center">
          <Button data-autoid={`btnClose`} onClick={() => onClose()} variant="filled" color="primary">
            Close
          </Button>
        </div>
      }
      handleClose={() => onClose()}
    />
  );
};
