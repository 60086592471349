import React from 'react';

export const ARChatIcon = (props: {
  width?: string;
  height?: string;
  className?: string;
  onClick?: any;
  color?: string;
}) => {
  return (
    <svg
      width="16px"
      height="14px"
      className={props.className || ''}
      viewBox="0 0 16 14"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Chat</title>
      <g id="Main-File" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="Spenda-Collect-Supplier-Interface-Copy-32"
          transform="translate(-346.000000, -407.000000)"
          fill={props.color || 'text-primary'}
          className={props.color ? `stroke-[${props.color}]` : 'stroke-primary'}
          fillRule="nonzero"
        >
          <g id="noun-chat-945230-copy-5" transform="translate(346.000000, 407.000000)">
            <path
              d="M13.6240834,0 L2.37602196,0 C1.7453328,0 1.1405295,0.249951086 0.694755632,0.694645512 C0.249096055,1.1394339 -0.000905310895,1.74250785 4.47343478e-06,2.37115121 L4.47343478e-06,8.60889479 C-0.00121871261,9.23776595 0.24844748,9.841267 0.694212772,10.2862832 C1.13998664,10.7314133 1.74498994,10.9815551 2.37602196,10.9815551 L3.20002646,10.9815551 L3.20002646,13.0544723 C3.20002646,13.6683378 3.43038486,14 3.78082963,14 C3.99199078,14 4.24477502,13.880433 4.51843366,13.628503 L7.38873505,10.9815551 L13.6287691,10.9815551 C14.2589154,10.9803316 14.8628044,10.7296279 15.307464,10.2846032 C15.752095,9.83970094 16.0012107,9.23685479 15.9999866,8.60886632 L15.9999866,2.37112274 C16.000875,1.74247937 15.7508722,1.13940543 15.3052354,0.694617038 C14.8594616,0.249942544 14.2546583,0 13.6239691,0 L13.6240834,0 Z M15.0400683,8.61048932 C15.040509,8.98520382 14.8916246,9.34468487 14.6260032,9.60997476 C14.3604874,9.87524757 14.000114,10.024524 13.624112,10.0248372 L7.012933,10.0248372 L6.73770864,10.2799876 L4.16012313,12.6462983 L4.16012313,10.0248344 L2.37608482,10.0248344 C1.99985419,10.0245007 1.63913793,9.87501408 1.3736222,9.60940244 C1.10799503,9.34379649 0.959219934,8.98386271 0.96010172,8.60892042 L0.96010172,2.37117684 C0.960440483,1.99700334 1.10977218,1.63831956 1.37527935,1.37371304 C1.64068366,1.10921756 2.00062562,0.960388138 2.37605624,0.959961032 L13.6241177,0.959961032 C13.9995769,0.960405962 14.3594931,1.1092261 14.6248946,1.37371304 C14.8904103,1.63831956 15.039754,1.99703182 15.0400683,2.37117684 L15.0400683,8.61048932 Z"
              id="Shape"
            ></path>
            <path
              d="M8.00005268,4.71818045 C7.75618849,4.71740658 7.52193578,4.81305969 7.34882055,4.9843492 C7.17560532,5.15552482 7.07783621,5.38833471 7.07682554,5.63135814 C7.07582889,5.87438726 7.17181101,6.1079517 7.34346338,6.28058802 C7.51522717,6.45321011 7.74872273,6.55075547 7.99258121,6.55187757 C8.23655683,6.55308941 8.47092668,6.45765771 8.6441562,6.28659314 C8.81748286,6.11552856 8.91558625,5.88294931 8.91692626,5.63981199 C8.9160334,5.39700782 8.81949144,5.1643289 8.6479505,4.99192037 C8.47640957,4.81940933 8.24371972,4.72119769 8.00006125,4.71818045 L8.00005268,4.71818045 Z"
              id="Path-chat"
            ></path>
            <path
              d="M4.76317785,4.71818516 C4.51920224,4.71484833 4.28371809,4.80827895 4.10883142,4.97789705 C3.9339419,5.14751516 3.83382992,5.37951924 3.83059276,5.62277045 C3.82735617,5.86602167 3.92121897,6.10047449 4.09153133,6.2747623 C4.26184369,6.4489419 4.49464782,6.54860001 4.73873486,6.55171503 C4.98282191,6.55483006 5.21808034,6.46117706 5.39288129,6.29133686 C5.56754796,6.12149666 5.66743708,5.88948688 5.67044852,5.64623567 C5.67424312,5.4030955 5.58071661,5.16841773 5.41040139,4.99424383 C5.24008903,4.82006423 5.00717062,4.72073926 4.76319785,4.71818232 L4.76317785,4.71818516 Z"
              id="Path-chat"
            ></path>
            <path
              d="M11.2529276,4.71818516 C11.0078377,4.71484833 10.7715535,4.80905628 10.5964383,4.97989876 C10.4213259,5.15085228 10.3218825,5.38431137 10.3202082,5.62855917 C10.3184225,5.87280981 10.4146287,6.10771537 10.5872868,6.28100659 C10.7600563,6.45429496 10.9950033,6.55184032 11.2400904,6.55184032 C11.4851803,6.55184032 11.7202359,6.45440601 11.8928939,6.28111764 C12.0656635,6.10771822 12.1618697,5.87291801 12.1601954,5.62867022 C12.1593025,5.38853403 12.0636549,5.15839782 11.8938997,4.98801093 C11.7241444,4.81761264 11.4938975,4.72073641 11.2529247,4.71817662 L11.2529276,4.71818516 Z"
              id="Path-chat"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
