import React from 'react';
import clsx from 'clsx';
import {omit} from 'lodash';
import {IconButton, TextField, TextFieldProps, makeStyles, InputAdornment} from '@material-ui/core';
import {createAutoIdAttributeFromReactProps} from '../../utils/createAutoIdAttribute';
import {getDecimalFromCurrencyStr, getCurrencyStrFromDecimal} from '../../utils/formatter';
import CrossIcon from '../../assets/svg/Cross';
import {CaretDown} from '../../assets/svg/CaretDown';

export const STextField = (
  props: TextFieldProps & {
    v2?: boolean;
    borderColor?: string;
    width?: string;
    height?: string;
    backgroundColor?: string;
    labelColor?: string;
    onClearClick?: () => void;
    maxLength?: number;
    'data-autoid'?: string;
  },
) => {
  const defaultStyles = {
    MuiTextField: {
      paddingBottom: props.size === 'small' ? '0rem' : '1.5rem',
      width: props.width || '100%',

      '& .MuiInputBase-input': {
        '-webkit-text-fill-color': props.disabled ? '#ccc' : '#000',
        fontFamily: 'Saira Condensed',
        caretColor: '#000',
        paddingBottom: props.size === 'small' ? '0rem' : '2px',
      },
      '& legend': {
        fontSize: '1em !important',
      },
      '& .MuiInput-underline:before': {
        borderBottomColor: '#000',
      },
      '& .MuiFormLabel-root': {
        fontFamily: 'Saira Condensed',
        color: 'hsl(var(--primary))',
      },
      '& .MuiFormLabel-root.Mui-error': {
        color: 'red',
      },
      '& .MuiFormLabel-root.Mui-focused': {
        color: 'hsl(var(--primary))',
      },
      '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        color: `${props.labelColor} !important`,
      },
      '& .MuiFormLabel-root.Mui-focused.Mui-error': {
        color: 'red',
      },
      '& .MuiFormHelperText-root.Mui-error': {
        position: 'absolute',
        top: '43px',
      },
      '& .MuiFormHelperText-root.Mui-disabled': {
        color: '#9e9e9e',
      },
      '& .MuiFormLabel-error': {
        color: 'red',
      },
      '& .MuiFormLabel-error.Mui-focused': {
        color: 'red',
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottomColor: '#000 ',
        borderBottom: '1px solid #fafafa',
      },
      ' & .MuiInput-underline.Mui-disabled:before': {
        borderBottomStyle: 'solid',
      },
      '& .MuiInput-underline.Mui-focused:after': {
        borderBottomColor: '#000 ',
      },
      '& .MuiInput-underline.Mui-error.Mui-focused:after': {
        borderBottomColor: 'red ',
      },
      '& .MuiOutlinedInput-root': {
        '-webkit-text-fill-color': props.disabled ? '#ccc' : '#000',
        backgroundColor: props.backgroundColor || '#EAEFF2',
        height: props.height,
      },
      '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-notchedOutline, .Mui-focused .MuiOutlinedInput-notchedOutline':
        {
          borderColor: props.disabled ? '#EAEAEA' : 'hsl(var(--primary))',
        },
      '&  .MuiOutlinedInput-notchedOutline': {
        borderColor: props.disabled ? '#EAEAEA' : props.borderColor ?? 'hsl(var(--primary))',
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderWidth: '1px',
      },
      '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: 'hsl(var(--error)) !important',
      },
    },
  };

  const useStyles = makeStyles(defaultStyles);

  const classes = useStyles();

  if (props.v2) {
    return (
      <DefaultTextField {...omit(props, ['v2', 'borderColor', 'width', 'height', 'backgroundColor', 'labelColor'])} />
    );
  }

  const {autoIdAttribute} = createAutoIdAttributeFromReactProps(props, 'txt');
  const newInputProps = {...props.inputProps, ...autoIdAttribute};

  // Remove 'data-autoid' from props
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {'data-autoid': removedDataAuoIdProp, ...propsButDataAutoId} = props;

  return (
    <TextField
      {...propsButDataAutoId} // Spread the props without 'data-autoid'
      inputProps={{...newInputProps}}
      classes={{root: classes.MuiTextField}}
    ></TextField>
  );
};

const useDefaultTextfieldStyles = makeStyles({
  MuiTextField: {
    '& .MuiInputLabel-root': {
      fontFamily: 'Poppins',
      fontWeight: 600,
      fontSize: '14px',
      color: '#bbbbbb',
      '&.MuiInputLabel-shrink': {
        fontSize: '10px',
        fontWeight: 500,
        color: '#333333',
        transform: 'translate(14px, -6px) scale(1)',
        '&.Mui-error': {
          color: '#c55d44',
        },
      },
      '&.MuiInputLabel-outlined:not(.MuiInputLabel-shrink)': {
        transform: 'translate(14px, 15px) scale(1)',
      },
      '&.Mui-disabled': {
        color: '#444343b3',
      },
    },
    '& .MuiOutlinedInput-root': {
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontWeight: 600,
      color: '#333333',
      borderRadius: '6px',
      height: '45px',
      padding: '0 8px',
      caretColor: '#1c78ad',
      '&.MuiOutlinedInput-multiline': {
        height: 'auto',
        padding: '8px',
      },
      '& .MuiInputBase-input::placeholder': {
        fontFamily: 'Poppins',
        fontWeight: 600,
        fontSize: '14px',
        color: '#bbbbbb',
      },
      '& svg': {
        color: '#333333',
      },
      '&.Mui-disabled': {
        color: '#444343b3',
      },
      '&:not(.Mui-error) .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #333333',
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: '1px solid hsl(var(--primary))',
      },
      '&.Mui-focused:not(.Mui-error) .MuiInputAdornment-root': {
        '& svg': {
          color: '#1c78ad',
        },
      },
      '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #c55d44',
      },
      '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #444343b3',
      },
      '&.Mui-disabled .MuiInputAdornment-root': {
        '& svg': {
          color: '#444343b3',
        },
      },
      '& .MuiInputAdornment-root': {
        margin: '6px',
        '&.MuiSelect-icon': {
          top: 'calc(50% - 6px)',
        },
      },
      '& .MuiSelect-outlined': {
        paddingRight: '24px',
      },
    },
  },
  clearIconAdornment: {
    '&.MuiIconButton-root': {
      padding: '0px',
    },
  },
  menuPaper: {
    maxHeight: 250,
  },
});

const DefaultTextField = (
  props: TextFieldProps & {
    onClearClick?: () => void;
    maxLength?: number;
  },
) => {
  const defaultClasses = useDefaultTextfieldStyles();
  const {
    classes,
    helperText,
    inputProps,
    maxLength,
    onClearClick,
    InputLabelProps,
    InputProps,
    SelectProps,
    ...textfieldProps
  } = props;

  const {autoIdAttribute} = createAutoIdAttributeFromReactProps(textfieldProps, 'txt');

  // const {autoIdAttribute: labelDataAutoId} = createAutoIdAttributeFromReactProps(textfieldProps, 'lbl');

  if (helperText) {
    textfieldProps.label = helperText;
  }

  const handleMousedown = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const textfield = (
    <TextField
      variant="outlined"
      classes={Object.assign(classes || {}, {root: defaultClasses.MuiTextField})}
      inputProps={Object.assign(inputProps || {}, autoIdAttribute, maxLength ? {maxLength} : {})}
      InputLabelProps={Object.assign(
        InputLabelProps || {},
        // labelDataAutoId,
        helperText && props.error ? {shrink: true} : {},
      )}
      InputProps={Object.assign(InputProps || {}, {
        endAdornment: (
          <>
            {onClearClick && props.value && !props.disabled ? (
              <InputAdornment position="end">
                <IconButton
                  className={defaultClasses.clearIconAdornment}
                  onClick={onClearClick}
                  onMouseDown={handleMousedown}
                  data-autoid="btnClearName"
                >
                  <CrossIcon className="h-[14px] w-[14px] fill-current !text-[#bbbbbb]" />
                </IconButton>
              </InputAdornment>
            ) : null}
            {InputProps?.endAdornment || null}
          </>
        ),
      })}
      SelectProps={{
        ...SelectProps,
        MenuProps: {
          ...(SelectProps?.MenuProps || {}),
          classes: {
            paper: defaultClasses.menuPaper,
          },
        },
        IconComponent: props => (
          <InputAdornment position="end" {...props}>
            <CaretDown />
          </InputAdornment>
        ),
      }}
      {...textfieldProps}
    ></TextField>
  );

  if (maxLength) {
    return (
      <div className="relative">
        {textfield}
        <span
          className={clsx('absolute -bottom-5 right-0 font-poppins text-xs font-medium', {
            'text-spenda-primarytext': !textfieldProps.error,
            'text-spenda-error': textfieldProps.error,
          })}
        >{`${((props.value as string) || '')?.length}/${maxLength}`}</span>
      </div>
    );
  }
  return textfield;
};

export const CurrencyTextField = (props: TextFieldProps) => {
  const {value, onChange, onBlur, ...currencyProps} = props;

  const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (onChange) {
      e.target.value = getDecimalFromCurrencyStr(e.target.value || '');
      onChange(e);
    }
  };

  const handleOnBlur = (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (onBlur) {
      e.target.value = getDecimalFromCurrencyStr(e.target.value || '');
      onBlur(e);
    }
  };

  return (
    <DefaultTextField
      {...currencyProps}
      value={getCurrencyStrFromDecimal(String(value || ''))}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
    />
  );
};
