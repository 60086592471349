import React, {ChangeEvent, useEffect, useState} from 'react';
import {Button, Input, Select, Typography, Option} from 'spenda-ui-react';
import {IAffixes, IPrefixSuffix, useAPConfigs} from '../../../services/useAPConfigs';
import LoadingIndicator from '../../../components/ui/LoadingIndicator';
import {SelectCOADropdown} from '../../../components/AccountsReceivable/required-attention/SelectCOADropdown';
import {useFeatureFlags} from '../../../hooks/useFeatureFlags';
import {DatTypes} from '../../../model/constants/Constants';
import {PriceFormat} from '../../../utils/formatter';

const initialFormValues = {
  affixes: [],
  roundingThreshold: undefined,
  roundingAccountCode: undefined,
};

interface IPreffixSuffixProps {
  onClickCancelBtn?: () => void;
}

export const PreffixSuffix = (props: IPreffixSuffixProps) => {
  // Props
  const {onClickCancelBtn} = props;

  // States
  const {getPrefixSuffix, savePrefixSuffix, isLoading} = useAPConfigs();
  const [state, setState] = useState<IPrefixSuffix>(initialFormValues);
  const [isSaveLoading, setIsSaveLoading] = useState<boolean>(false);

  // Feature Flag
  const {PSBLSTAGING} = useFeatureFlags().supplierDriven();

  useEffect(() => {
    loadPrefixSuffix();
  }, []);

  const loadPrefixSuffix = async () => {
    const prefixSuffix = await getPrefixSuffix();
    setState(prefixSuffix);
  };

  const onSubmit = async (e: any) => {
    try {
      setIsSaveLoading(true);
      e.preventDefault();
      let payload = {...state};
      if (!PSBLSTAGING) {
        delete payload.roundingAccountCode;
        delete payload.roundingThreshold;
      }
      await savePrefixSuffix(payload);
    } catch {
    } finally {
      setIsSaveLoading(false);
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>, datTypeID?: number) => {
    const {name, value} = e.target;
    if (datTypeID) {
      const index = state?.affixes?.findIndex(a => a.datTypeID === datTypeID);
      setState(prevState => {
        if (index !== -1) {
          prevState.affixes[index] = {...prevState.affixes[index], [name]: value};
        } else {
          prevState.affixes.push({datTypeID, prefix: '', suffix: '', [name]: value});
        }
        return {...prevState};
      });
      return;
    }
    setState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const getInputValue = (name: keyof IAffixes, datTypeID: number) => {
    return state?.affixes?.find(a => a.datTypeID === datTypeID)?.[name as keyof IAffixes] || '';
  };

  return (
    <div className={`relative flex h-full w-full flex-col overflow-hidden bg-white pb-16`}>
      {isLoading ? (
        <LoadingIndicator
          isLoading={isLoading}
          position={{
            height: '100% !important',
            display: 'flex',
            position: 'absolute',
            left: '0',
            right: 0,
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
          size="md"
          color="hsl(var(--primary))"
        />
      ) : (
        <>
          <div className={` header flex !min-h-[60px] items-center justify-between border-b border-[#D8D8D8] py-2.5`}>
            <Typography className=" font-poppins text-xl font-light text-[#333333]" variant="h2">
              {'Invoice Matching Settings'}
            </Typography>
          </div>
          <div className="mt-6 flex gap-32">
            <div className="flex max-w-[409px] flex-col gap-7">
              <div className="flex flex-col gap-3.5">
                <Typography className="font-poppins font-medium text-[#000000]">
                  {PSBLSTAGING ? 'Invoice and credit note matching settings' : ' Invoice Matching Settings'}
                </Typography>
                <Typography className="flex-start mt-2 flex text-sm font-normal text-[#333333]">
                  If your workshop software adds characters to the supplier’s invoice number when imported to your
                  Financial System, Spenda won’t be able to match it automatically. Input the added characters below and
                  they’ll be ignored during the matching process.
                </Typography>
              </div>
              <div className="flex items-start justify-start gap-7">
                <div className="flex flex-col">
                  <div className="flex gap-3">
                    {PSBLSTAGING && (
                      <Typography className="my-2.5 font-poppins font-medium text-[#000000]">Invoices:</Typography>
                    )}
                    <Input
                      id="Prefix"
                      label="Prefix"
                      value={getInputValue('prefix', DatTypes?.PurchaseInvoice)}
                      name="prefix"
                      onChange={e => onChange(e, 72)}
                      data-autoid="txtInvoicePrefix"
                      containerProps={{className: ' ml-7 !max-h-[50px] !w-[118px] !min-w-[118px] '}}
                    />
                    <Input
                      id="Suffix"
                      label="Suffix"
                      value={getInputValue('suffix', DatTypes?.PurchaseInvoice)}
                      name="suffix"
                      onChange={e => onChange(e, 72)}
                      data-autoid="txtInvoiceSuffix"
                      containerProps={{className: '!max-h-[50px] !w-[118px] !min-w-[118px]'}}
                    />
                  </div>
                  {PSBLSTAGING && (
                    <div className="my-3 flex justify-between gap-3">
                      <Typography className="my-2.5  gap-7 font-poppins font-medium text-[#000000]">
                        Credit notes:
                      </Typography>
                      <Input
                        id="Prefix"
                        label="Prefix"
                        value={getInputValue('prefix', DatTypes?.DebitNote)}
                        name="prefix"
                        onChange={e => onChange(e, DatTypes?.DebitNote)}
                        data-autoid="txtCreditNotePrefix"
                        containerProps={{className: '!max-h-[50px] !w-[118px] !min-w-[118px]'}}
                      />
                      <Input
                        id="Suffix"
                        label="Suffix"
                        value={getInputValue('suffix', DatTypes?.DebitNote)}
                        name="suffix"
                        onChange={e => onChange(e, DatTypes?.DebitNote)}
                        data-autoid="txtCreditNoteSuffix"
                        containerProps={{className: '!max-h-[50px] !w-[118px] !min-w-[118px]'}}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-10 flex !max-w-[431px] flex-col gap-2 rounded-[10px] bg-primary-header p-3">
              <Typography className=" font-poppins text-xs font-medium text-[#000000]" variant="h2">
                Here are some examples:
              </Typography>
              <p className="text-xs font-normal text-[#000000CC]">
                In this example, Spenda could match an invoice in your Financial System with a reference number of
                “WMS-12345” to an invoice on your statement with the original Supplier reference of “12345”
              </p>
              <div className="flex w-full justify-center">
                <div className="flex !max-h-[23px] w-[72px] items-center justify-center rounded-[4px] border border-primary bg-white py-1 text-xs font-normal text-primary">
                  Prefix: <span className="ml-0.5 font-medium">WMS-</span>
                </div>
              </div>
              <p className="text-xs font-normal text-[#000000CC]">
                In this example, Spenda could match an invoice in your Financial System with a reference number of “XXX
                {'{'}12345{'}'}” to an invoice on your statement with the original Supplier reference of “12345”
              </p>
              <div className="flex w-full justify-center gap-3">
                <div className="flex !max-h-[23px] w-[54px] items-center justify-center rounded-[4px] border border-primary bg-white py-1 text-xs font-normal text-primary">
                  Prefix: <span className="ml-0.5 font-medium">{'*{'}</span>
                </div>
                <div className="flex !max-h-[23px] w-[54px] items-center justify-center rounded-[4px] border border-primary bg-white py-1 text-xs font-normal text-primary">
                  Suffix: <span className=" ml-0.5 font-medium">{'}'}</span>
                </div>
              </div>
            </div>
          </div>
          {PSBLSTAGING && (
            <div className="flex gap-32">
              <div className="flex max-w-[409px] flex-col gap-7">
                <div className="flex flex-col gap-2.5">
                  <Typography className="mt-4 font-poppins font-medium text-[#000000]" variant="paragraph">
                    Rounding Tolerance
                  </Typography>
                  <Typography className="flex-start flex text-sm font-normal text-[#333333]">
                    When matching invoices, Spenda can allow a tolerance based on the amount you set below.
                  </Typography>

                  <div className="mt-3 flex !h-[60px] !w-[330px] flex-col gap-6">
                    <Select
                      key={'roundingThreshold'}
                      name={'roundingThreshold'}
                      value={state?.roundingThreshold?.toString()}
                      color="primary"
                      label="Ignore differences selected"
                      inputProps={{placeholder: 'Ignore differences selected'}}
                      menuProps={{className: '!max-h-[200px]'}}
                      selected={() => (state?.roundingThreshold ? PriceFormat(state?.roundingThreshold) : '')}
                      onChange={value => {
                        const e = {target: {value, name: 'roundingThreshold'}};
                        onChange(e as ChangeEvent<HTMLInputElement>);
                      }}
                    >
                      {Array.from({length: 10}, (_, i) => (i + 1) * 0.01).map(option => (
                        <Option key={option} value={option.toFixed(2)}>
                          ${option.toFixed(2)}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  <div className="mb-5">
                    <div className="mb-5">
                      <Typography className="flex-start flex text-sm font-normal text-[#333333]">
                        How would you like us to post tax rounding adjustments?
                      </Typography>
                    </div>
                    <div className="mt-3 flex !h-[60px] !w-[330px] flex-col gap-6">
                      <SelectCOADropdown
                        isUsingForRoundingAdjustment
                        key={'roundingAccountCode'}
                        value={state.roundingAccountCode}
                        onChange={value => {
                          const e = {target: {value, name: 'roundingAccountCode'}};
                          onChange(e as ChangeEvent<HTMLInputElement>);
                        }}
                        name={'roundingAccountCode'}
                        color="primary"
                        size="lg"
                        variant="outlined"
                        label="Select an account"
                        placeholder="Select an account"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-14 flex max-h-[96px] max-w-[431px] flex-col gap-2 rounded-[10px] bg-primary-header p-3">
                <Typography className=" font-poppins text-xs font-medium text-[#000000]" variant="h2">
                  Here are some examples:
                </Typography>
                <p className="text-xs font-normal text-[#000000CC]">
                  If this amount is set to $0.05, then Spenda will identify a match between two invoices with values of
                  $100.00 and $100.05, as long as they are from the same Supplier and have a matching reference number.
                </p>
              </div>
            </div>
          )}
          <div className="footer absolute bottom-2.5 flex h-[60px] w-full flex-row items-center justify-between rounded-[6px] bg-[#ececec] px-4">
            <Button
              onClick={() => onClickCancelBtn?.()}
              data-autoid={`btnCancel`}
              variant="outlined"
              color="primary"
              className="bg-white"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              data-autoid="btnSavePrefixSuffix"
              loading={isSaveLoading}
              onClick={onSubmit}
            >
              Save
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
