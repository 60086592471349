import React from 'react';
import {Button, Typography} from 'spenda-ui-react';
import {useFormikContext} from 'formik';

import {RoundedCheckbox} from '../../../../components/inputs/RoundedCheckbox';
import QuoteInventoryAutocomplete from '../../../../components/quote-management/QuoteInventoryAutocomplete';

interface CatalogueColumn {
  title: string;
  key: string;
  width?: string;
  align?: string;
  rowClassName?: string;
  headerRenderer?: () => JSX.Element;
  rowRenderer: (index: number) => JSX.Element;
}

const TableHeader: React.FC<{heading: CatalogueColumn[]}> = ({heading}) => {
  return (
    <thead className="sticky top-0 z-[99] bg-[#F1F1F1] text-[#999999]">
      <tr>
        {heading.map(item => (
          <th
            key={item.key}
            className={`m-auto p-2.5 text-base font-semibold ${item.align ? `text-${item.align}` : ''}`}
            style={{width: item.width}}
          >
            {item.headerRenderer ? item.headerRenderer() : item.title}
          </th>
        ))}
      </tr>
    </thead>
  );
};

const Table: React.FC<{rows: number[]; columns: CatalogueColumn[]}> = ({rows, columns}) => {
  return (
    <table className="w-full pb-1">
      <TableHeader heading={columns} />
      <tbody>
        {rows.map((_, rowIndex) => (
          <tr key={rowIndex} className="relative w-full">
            {columns.map(col => (
              <td
                key={col.key}
                className={`m-auto h-10 border-b border-[#EAEAEA] p-0 odd:bg-[#FAFAFA] even:bg-[#FFFFFF] ${col.rowClassName}`}
                style={{width: col.width}}
              >
                {col.rowRenderer(rowIndex)}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export const CreateCatalogueTable = () => {
  const {values, setFieldValue} = useFormikContext<{lines: number[]}>();

  const addNewLine = () => {
    setFieldValue('lines', [...values.lines, values.lines.length]);
  };

  const columns: CatalogueColumn[] = [
    {
      title: '',
      key: '',
      width: '1%',
      align: 'left',
      rowClassName: 'p-2.5',
      headerRenderer: () => <RoundedCheckbox />,
      rowRenderer: () => <RoundedCheckbox />,
    },
    {
      title: 'Code',
      key: 'code',
      width: '10%',
      align: 'left',
      rowClassName: 'text-left',
      rowRenderer: index => {
        return <QuoteInventoryAutocomplete by="code" index={index} alreadyFilled={[]} calculateLineTotal={() => {}} />;
      },
    },
    {
      title: 'Product Name',
      key: 'productName',
      width: '20%',
      align: 'left',
      rowClassName: `text-left !border !border-l-transparent `,
      rowRenderer: index => {
        return (
          <QuoteInventoryAutocomplete by="product" index={index} alreadyFilled={[]} calculateLineTotal={() => {}} />
        );
      },
    },
    {
      title: 'Range',
      key: 'range',
      width: '13%',
      align: 'left',
      rowClassName: 'p-2.5',
      rowRenderer: (index: number) => {
        return <Typography data-autoid={`txtRange-${index}`}>Flooring</Typography>;
      },
    },
    {
      title: 'Category',
      key: 'category',
      width: '13%',
      align: 'left',
      rowClassName: 'p-2.5',
      rowRenderer: (index: number) => {
        return <Typography data-autoid={`txtCategory-${index}`}>Timber</Typography>;
      },
    },
    {
      title: 'Brand',
      key: 'brand',
      width: '10%',
      align: 'left',
      rowClassName: 'p-2.5',
      rowRenderer: (index: number) => {
        return <Typography data-autoid={`txtBrand-${index}`}>-</Typography>;
      },
    },
    {
      title: 'UoM',
      key: 'UoM',
      width: '10%',
      align: 'left',
      rowClassName: 'p-2.5',
      rowRenderer: (index: number) => {
        return <Typography data-autoid={`txtUOM-${index}`}>Each</Typography>;
      },
    },
    {
      title: 'Default Supplier',
      key: 'defaultSupplier',
      width: '13%',
      align: 'left',
      rowClassName: 'p-2.5',
      rowRenderer: (index: number) => {
        return <Typography data-autoid={`txtDefaultSupplier-${index}`}>-</Typography>;
      },
    },
    {
      title: 'Product Class',
      key: 'productClass',
      width: '15%',
      align: 'left',
      rowClassName: 'p-2.5',
      rowRenderer: (index: number) => {
        return <Typography data-autoid={`txtVariant-${index}`}>Parts</Typography>;
      },
    },
  ];

  return (
    <div className="h-[calc(100vh-440px)]">
      <div className="h-[calc(100vh-80px)]">
        <Table rows={values.lines} columns={columns} />
        <div className="px-5 py-2.5">
          <Button
            variant="text"
            ripple={false}
            onClick={addNewLine}
            className="hover:no-underline focus:bg-transparent active:bg-transparent"
          >
            Add New Line
          </Button>
        </div>
      </div>
    </div>
  );
};
