import {Formik, FormikHelpers} from 'formik';
import React, {useContext, useState} from 'react';
import {CompanyDetailsForm} from '../form/CompanyDetailsForm';
import * as Yup from 'yup';
import useTenantAPI from '../../services/useTenantAPI';
import {Toast} from '../../utils/Toast';
import {validateABN} from '../../utils/validators';
import AppContext from '../../context/app/appContext';
import {BusinessDetailsFormValues} from '../onboarding/BusinessDetails';
import {AlertDialogSlideV2} from './AlertDialogSlideV2';
import useGetTenantDetails from '../../services/useGetTenantDetails';
import useMarketPlaceAPI from '../../services/useMarketPlaceAPI';

interface ICompanyDetailsDialogProps {
  title?: string;
  onClose?: () => void;
}

export const CompanyDetailsDialog = (props: ICompanyDetailsDialogProps) => {
  const {setTenant, tenant} = useContext(AppContext);
  const [isShowDiscardChangesConfirmation, setIsShowDiscardChangesConfirmation] = useState(false);
  const {getTenantDetails} = useGetTenantDetails();
  const newTenant: BusinessDetailsFormValues = {
    companyName: '',
    abn: '',
    companyPhone: '',
    website: '',
    mySpendaUrl: '',
    companyLogo: '',
    companyIcon: '',
    companyBanner: '',
    companyEmailAddress: '',
    companyTagLine: '',
  } as BusinessDetailsFormValues;

  const tenantAPI = useTenantAPI();
  const {marketPlacePathUnique} = useMarketPlaceAPI();

  const onSaveTenant = async (companyDetails: BusinessDetailsFormValues, initialValues: BusinessDetailsFormValues) => {
    if (!companyDetails) {
      return;
    }
    const tenantDetails = await getTenantDetails(companyDetails, initialValues);
    if (!tenantDetails) return;

    let isSaved = (await tenantAPI.saveTenantDetails(tenantDetails, tenantDetails.ARContactEmailAddress)).IsSuccess;

    if (isSaved) {
      setTenant(tenantDetails);
      Toast.info('Business details have been saved.');
      if (props.onClose) props.onClose();
    }
  };

  const onSubmit = async (
    values: BusinessDetailsFormValues,
    helpers: FormikHelpers<BusinessDetailsFormValues>,
    initialValues: BusinessDetailsFormValues,
  ) => {
    helpers.setSubmitting(true);
    if (!Boolean(tenant?.MarketPlacePath)) {
      const result = await marketPlacePathUnique(values.mySpendaUrl);
      if (!result) {
        helpers.setFieldError('mySpendaUrl', 'Marketplace URL already exists.');
        helpers.setSubmitting(false);
        return;
      }
    }
    onSaveTenant(values, initialValues).finally(() => {
      helpers.setSubmitting(false);
    });
  };

  const validationSchema = Yup.object({
    companyName: Yup.string()
      .required('Business Name is required.')
      .max(100, 'Last Name cannot be longer than 100 characters.'),
    companyPhone: Yup.string().required('Business Phone is required.'),
    companyEmailAddress: Yup.string().email('Invalid email address'),
    abn: Yup.string()
      .test('abn', 'Please provide a valid ABN.', function (value: string | undefined) {
        // If abn is undefined, we should treat it as valid, and let any required validation return false instead
        if (!value) {
          return true;
        }
        let result = validateABN(value);
        if (!result) {
          return true;
        } else {
          return result.valid;
        }
      })
      .required('ABN is required'),
    mySpendaUrl: Yup.string().matches(
      /^[a-z0-9]+(-?[a-z0-9]+)+$/,
      'Only lower case letters, number, and hyphen is allowed',
    ),
    website: Yup.string().url('Invalid URL (e.g. http://www.example.com)'),
  });

  const closeDialog = () => {
    if (props.onClose) {
      props.onClose();
    }
  };

  const onCancel = (isDirty: boolean) => {
    if (isDirty) {
      setIsShowDiscardChangesConfirmation(true);
    } else {
      closeDialog();
    }
  };

  return (
    <Formik
      onSubmit={(values, helpers) => onSubmit(values, helpers, newTenant)}
      initialValues={newTenant}
      validationSchema={validationSchema}
    >
      {renderProps => (
        <AlertDialogSlideV2
          title="Business Details"
          size="md"
          headingTextSize="h1"
          dialogBodyClassess="h-[70vh] overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100"
          dialogClassess="!w-[800px] !min-w-[800px] !w-max-[800px]"
          dialogActionsAlignment="justify-between"
          actions={[
            {
              label: 'Cancel',
              action: () => onCancel(renderProps.dirty),
              color: 'primary',
              variant: 'outlined',
            },
            {
              label: 'Save',
              action: () => onSubmit(renderProps.values, renderProps, renderProps.initialValues),
              color: 'primary',
              disabled: renderProps.isSubmitting,
              loading: renderProps.isSubmitting,
            },
          ]}
        >
          <CompanyDetailsForm
            {...renderProps}
            onClose={closeDialog}
            isShowDiscardChangesConfirmation={isShowDiscardChangesConfirmation}
            setIsShowDiscardChangesConfirmation={setIsShowDiscardChangesConfirmation}
          />
        </AlertDialogSlideV2>
      )}
    </Formik>
  );
};
