import React, { useState } from 'react';
import {
  Menu,
  MenuHandler,
  MenuList,
  Tab,
  TabPanel,
  Tabs,
  TabsBody,
  TabsHeader,
  Typography,
} from 'spenda-ui-react';

import QuoteVariantV2 from '../quote-management/QuoteVariantV2';
import { useQuoteContext } from '../../context/quote-context/QuoteContext';
import { QuoteLinkedTransaction } from '../quote-management/QuoteLinkedTransaction';
import { DropdownArrow } from '../../assets/svg';
import { QuoteAddNoteDialog } from '../dialog';
import useQuotesAPI from '../../services/useQuotesAPI';
import EditCustomerIcon from '../../assets/svg/EditCustomerIcon';
import { Toast } from '../../utils/Toast';

interface IQuoteCreateRightPanel {
  handleDuplicateQuote?: (quoteID: number) => void;
  isSaving?: boolean;
  handleDeleteQuote?: (quoteID: number) => void;
  handleSelectedQuoteVariant?: (quoteID: number) => Promise<void>;
  isQuoteSent?: boolean;
}

const QuoteCreateRightPanel = (props: IQuoteCreateRightPanel) => {
  const { isSaving, handleSelectedQuoteVariant, isQuoteSent } = props;

  const { quotePackage, refreshQuotePackage } = useQuoteContext();
  const { updateQuotePackage } = useQuotesAPI();

  const [activeTab, setActiveTab] = useState<number>(0);
  const [isShowInternalNote, setIsShowInternalNote] = useState<boolean>(true);
  const [openAddNoteDialog, setOpenAddNoteDialog] = useState<boolean>(false);
  const [isSavingNotes, setIsSavingNotes] = useState<boolean>()

  const tabs = [
    {
      label: 'Quote Package',
      value: 0,
      desc: <QuoteVariantV2 handleSelectedQuoteVariant={handleSelectedQuoteVariant} isSaving={isSaving} isQuoteSent={isQuoteSent} />,
    },
    {
      label: 'Linked Transactions',
      value: 1,
      desc: <QuoteLinkedTransaction />,
    },
  ];

  const onSaveNote = async (internalNote: string, externalNote: string) => {
    const values = {
      quotePackageID: quotePackage?.quotePackageID!,
      internalNote,
      externalNote,
    };
    setIsSavingNotes(true);
    try {
      await updateQuotePackage(values)
      setOpenAddNoteDialog(false);
      refreshQuotePackage()
      setIsShowInternalNote(true)
    } catch (error) {
      Toast.error('Error during save API call:');
    }
    finally{
      setIsSavingNotes(true);
    }
  };

  return (
    <>
      <div className="h-[calc(100vh-106px)] overflow-y-auto rounded-lg bg-[#FFFFFF] p-2.5">
        <div>
          <Tabs value={activeTab} id="custom-animation">
            <TabsHeader
              className="w-full bg-[#ECECEC]"
              indicatorProps={{
                className: 'bg-spenda-sBlue shadow-none !text-white',
              }}
            >
              {tabs?.map(({ label, value }) => (
                <Tab
                  key={value}
                  value={value}
                  onClick={() => setActiveTab(value)}
                  className={`h-10 px-3 font-medium leading-4 text-black-800 ${activeTab === value ? 'text-white' : ''}`}
                  data-autoid={`tab-${label}`.replace(/ /g, '')}
                  title={label}
                >
                  {label}
                </Tab>
              ))}
            </TabsHeader>
            <TabsBody
              animate={{
                initial: { y: 100 },
                mount: { y: 0 },
                unmount: { y: 10 },
              }}
              className="mt-2 border-t border-[#ECECEC]"
            >
              {tabs.map(({ value, desc }) => (
                <TabPanel key={value} value={value} className="px-0 py-2.5">
                  {desc}
                </TabPanel>
              ))}
            </TabsBody>
          </Tabs>
        </div>
        {isQuoteSent && <Menu
          dismiss={{ itemPress: false }}
          open={isShowInternalNote}
          handler={() => {
            setIsShowInternalNote(!isShowInternalNote);
          }}
          placement="bottom-end"
        >
          <MenuHandler
            className={`fixed bottom-2.5 flex h-[46px] w-[287px] items-center justify-between px-4 ${isShowInternalNote ? 'rounded-b-lg bg-primary' : 'rounded-lg bg-[#EAEAEA] bg-opacity-[1.4]'} mb-2.5`}
          >
            <div>
              <Typography
                data-autoid={'txtViewInternalNote'}
                className={`text-center font-semibold cursor-pointer ${isShowInternalNote ? 'text-white' : 'text-black-800'}`}
              >
                View internal note
              </Typography>
              <div data-autoid={`imgActionQuote`}>
                <DropdownArrow className={`${isShowInternalNote ? 'fill-white rotate-180' : 'fill-primary'}`} />
              </div>
            </div>
          </MenuHandler>
          {isShowInternalNote && (
            <MenuList className="bg-opacity-4 mb-0 w-[287px] rounded-t-lg !border-none bg-[#EAEAEA]">
              <Typography className="font-normal text-black-800" variant="small">
                {quotePackage?.internalNote === '' || quotePackage?.internalNote === null ?
                  <div className='flex flex-col gap-y-2.5'>
                    <Typography variant='small'>No internal note found</Typography>
                    <Typography data-autoid="lnkAddNote" variant='small' className='text-primary font-medium underline cursor-pointer' onClick={() => setOpenAddNoteDialog(true)}>Please add a note</Typography>
                  </div>
                  :
                  <div className='relative pb-3'>
                    {quotePackage?.internalNote}
                    <EditCustomerIcon
                      onClick={() => {
                        setOpenAddNoteDialog(true)
                      }}
                      className="absolute right-0 bottom-0 cursor-pointer"
                      data-autoid={`btnEditNotes`}
                    /></div>}
              </Typography>
            </MenuList>
          )}
        </Menu>}
        {openAddNoteDialog && (
          <QuoteAddNoteDialog
            handleCancel={() => setOpenAddNoteDialog(false)}
            handleAddNote={(internalNote, externalNote) => {
              onSaveNote(internalNote, externalNote);
              }
            }
            isLoading={isSavingNotes}
          />
        )}
      </div>
    </>
  );
};

export default QuoteCreateRightPanel;
