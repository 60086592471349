import React from 'react';
import {Typography} from 'spenda-ui-react';
import {AlertDialogSlideV2} from './AlertDialogSlideV2';
import { AUTH_SELLING_QUOTES_LIST } from '../../routes/QuoteManagementRoutes';
import { useHistory } from 'react-router';

export const QuoteDiscardPackageDialog = (props: {handleDiscard: () => void}) => {
  const history = useHistory();
  return (
    <AlertDialogSlideV2
      title={'Cancel quote package'}
      headingTextSize="h2"
      headingClassess="justify-center"
      dialogActionsAlignment="justify-between"
      dialogClassess="!min-w-[20%] !max-w-[497px] !w-full"
      dialogBodyClassess="flex justify-center items-center"
      actions={[
        {
          label: 'Close',
          action: () => {
            history.push(AUTH_SELLING_QUOTES_LIST);
          },
          variant: 'outlined',
          color: 'primary',
        },
        {
          label: 'Discard Package',
          action: props.handleDiscard,
          color: 'error',
          variant: 'filled',
        },
      ]}
    >
      <Typography
        variant="small"
        className="flex items-center justify-center w-full max-w-[83%] py-10 font-normal text-center text-black-800"
      >
        Do you want to discard the entire quote? Click 'Discard package' to discard the quote package or 'Close' to keep the quote package.
      </Typography>
    </AlertDialogSlideV2>
  );
};
