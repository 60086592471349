import React, {useState} from 'react';
import {useFormikContext} from 'formik';
import {isEmpty} from 'lodash';
import {useHistory} from 'react-router-dom';
import {ClaimsMultiMenuButton} from '../../../components/AccountsReceivable/claimsAndReturns/ClaimsMultiMenuButton';
import {ApproveClaimModal} from '../../../components/AccountsReceivable/modals/ApproveClaimModal';
import {DeleteClaimModal} from '../../../components/AccountsReceivable/modals/DeleteClaimModal';
import {RejectClaimModal} from '../../../components/AccountsReceivable/modals/RejectClaimModal';
import {SendClaimModal} from '../../../components/AccountsReceivable/modals/SendClaimModal';
import {DestructiveButton, SecondaryButton, PrimaryButton} from '../../../components/buttons/DefaultButtons';
import {useTenantInfo} from '../../../hooks/useTenantInfo';
import {ClaimsAndReturnsStatus} from '../../../model/constants/Constants';
import {IClaimRequestFormValues} from './CreateAndEditClaimRequest';

interface ICreateAndEditClaimRequestFooterProps {
  redirectIfStatus: ClaimsAndReturnsStatus[];
  isSubmitting: boolean;
  claimAndReturnsV272602: boolean;
  updateClaimsStatus: (status: ClaimsAndReturnsStatus, rejectReason?: string) => Promise<void>;
}

export const CreateAndEditClaimRequestFooter = (props: ICreateAndEditClaimRequestFooterProps) => {
  // Props
  const {redirectIfStatus, claimAndReturnsV272602, isSubmitting, updateClaimsStatus} = props;

  // Hooks
  const formikProps = useFormikContext<IClaimRequestFormValues>();
  const {isInBuyerContext, isInSupplierContext} = useTenantInfo();
  const history = useHistory();

  // States
  const [isShowDeleteClaimDialog, setIsshowDeleteClaimDialog] = useState(false);
  const [isShowApproveClaimDialog, setIsShowApproveClaimDialog] = useState(false);
  const [isShowRejectClaimDialog, setIsShowRejectClaimDialog] = useState(false);
  const [isShowSendClaimDialog, setIsshowSendClaimDialog] = useState(false);

  const isShowButton = (button: string, fValues: IClaimRequestFormValues) => {
    if (redirectIfStatus?.includes(fValues?.status)) {
      return false;
    }

    switch (button) {
      case 'Delete':
        if (isInBuyerContext) {
          return fValues?.claimRequestID && fValues?.status === ClaimsAndReturnsStatus.Draft;
        } else if (isInSupplierContext) {
          return fValues?.status === ClaimsAndReturnsStatus.Draft && fValues?.claimRequestID;
        }
        return false;
      case 'Save':
        if (isInBuyerContext) {
          return fValues?.status === ClaimsAndReturnsStatus.Draft;
        } else if (isInSupplierContext) {
          return true;
        }
        return false;
      case 'Send':
        if (isInBuyerContext) {
          return fValues?.status === ClaimsAndReturnsStatus.Draft;
        } else if (isInSupplierContext) {
          return fValues?.status === ClaimsAndReturnsStatus.Draft;
        }
        return false;
      case 'ApproveAndReviewed':
      case 'Reject':
      case 'Approve':
        const showApproveAndRejectBtnStatusFor = [ClaimsAndReturnsStatus.Sent, ClaimsAndReturnsStatus.Reviewed];
        return (
          fValues?.claimRequestID && isInSupplierContext && showApproveAndRejectBtnStatusFor?.includes(fValues?.status)
        );
      default:
        return false;
    }
  };

  return (
    <>
      <div className="absolute bottom-2.5 left-2.5 right-2.5 z-[0] mx-2.5 flex h-[60px] flex-row items-center justify-between rounded-[8px] bg-[#f0f0f0] p-2.5">
        {/* Left Side Buttons */}
        <div className="flex flex-row items-center justify-start gap-2.5">
          {isShowButton('Delete', formikProps?.values) && (
            <DestructiveButton
              label="Delete"
              data-autoid={`btnCreateAndEditClaimRequestDelete`}
              onClick={() => setIsshowDeleteClaimDialog(true)}
            />
          )}

          {isShowButton('Reject', formikProps?.values) && (
            <DestructiveButton
              label="Reject"
              data-autoid={`btnCreateAndEditClaimRequestCodeReject`}
              onClick={() => setIsShowRejectClaimDialog(true)}
            />
          )}

          <SecondaryButton label="Cancel" onClick={() => history.goBack()} />
        </div>

        {/* Right Side Buttons */}
        <div className="flex flex-row items-center justify-end gap-2.5">
          {isShowButton('Save', formikProps?.values) && (
            <SecondaryButton
              disabled={isSubmitting || !formikProps?.values?.lineItems?.length}
              isSubmitting={isSubmitting}
              label="Save"
              onClick={async () => {
                formikProps?.setFieldValue('actionStatus', undefined);
                formikProps?.handleSubmit();
              }}
            />
          )}

          {isShowButton('Send', formikProps?.values) && (
            <PrimaryButton
              disabled={isSubmitting || !formikProps?.values?.lineItems?.length}
              label="Send"
              onClick={async () => {
                const errs = await formikProps?.validateForm();
                if (!isEmpty(errs)) formikProps?.handleSubmit();
                else setIsshowSendClaimDialog(true);
              }}
            />
          )}

          {isShowButton('ApproveAndReviewed', formikProps?.values) && claimAndReturnsV272602 ? (
            <ClaimsMultiMenuButton
              claimsStatus={formikProps?.values?.status}
              handleClick={(action: ClaimsAndReturnsStatus) => {
                formikProps?.setFieldValue('actionStatus', action);
                if (action === ClaimsAndReturnsStatus?.Approved) {
                  setIsShowApproveClaimDialog(true);
                  return;
                }
                formikProps?.handleSubmit();
              }}
            />
          ) : (
            isShowButton('Approve', formikProps?.values) && (
              <PrimaryButton label="Approve" onClick={() => setIsShowApproveClaimDialog(true)} />
            )
          )}
        </div>
      </div>

      <SendClaimModal
        isSubmitting={isSubmitting}
        isOpen={isShowSendClaimDialog}
        onConfirm={async () => {
          formikProps?.setFieldValue('actionStatus', ClaimsAndReturnsStatus.Sent);
          formikProps?.handleSubmit();
        }}
        onClose={() => setIsshowSendClaimDialog(false)}
      />

      <DeleteClaimModal
        isOpen={isShowDeleteClaimDialog}
        onConfirm={async () => {
          formikProps?.setFieldValue('actionStatus', ClaimsAndReturnsStatus.Deleted);
          updateClaimsStatus(ClaimsAndReturnsStatus.Deleted);
        }}
        onClose={() => setIsshowDeleteClaimDialog(false)}
        isSubmitting={isSubmitting}
      />

      <ApproveClaimModal
        isSubmitting={isSubmitting}
        isOpen={isShowApproveClaimDialog}
        onClose={() => setIsShowApproveClaimDialog(false)}
        onConfirm={async (referenceNo, paymentDate) => {
          formikProps?.setFieldValue('actionStatus', ClaimsAndReturnsStatus.Approved);
          claimAndReturnsV272602 && formikProps?.setFieldValue('approveReferenceNo', referenceNo);
          claimAndReturnsV272602 && formikProps?.setFieldValue('approvePaymentDate', paymentDate);
          formikProps?.handleSubmit();
        }}
      />

      <RejectClaimModal
        isSubmitting={isSubmitting}
        isOpen={isShowRejectClaimDialog}
        onClose={() => setIsShowRejectClaimDialog(false)}
        onConfirm={reason => {
          formikProps?.setFieldValue('actionStatus', ClaimsAndReturnsStatus.Rejected);
          updateClaimsStatus(ClaimsAndReturnsStatus.Rejected, reason);
        }}
      />
    </>
  );
};
