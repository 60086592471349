import React, {SetStateAction, useCallback, useState} from 'react';
import {Button, SearchInput, Typography} from 'spenda-ui-react';
import {debounce} from 'lodash';

import {Header} from '../layout/Header';
import {SelectQuoteCustomerDialog} from '../dialog/SelectQuoteCustomerDialog';
import {ICustomer} from '../../model/customer/Customer';
import {ITechnician} from '../../model/service-management/serviceJob';
import FilterTechnician from './FilterTechnician';

interface IBookingDiaryHeader {
  setSearchString: React.Dispatch<SetStateAction<string>>;
  handleCreateAdhocBooking: (selectedServiceCustomer: ICustomer) => void;
  technicians?: ITechnician[];
  setSelectedTechnicians: (technicians: number[]) => void;
  selectedTechnicians: number[];
  handleRefreshTechnician: () => void;
  isShowUnassignedServiceJobs: boolean;
  setIsShowUnassignedServiceJobs: React.Dispatch<SetStateAction<boolean>>;
}

export const BookingDiaryHeader = (props: IBookingDiaryHeader) => {
  const {
    setSearchString,
    handleCreateAdhocBooking,
    technicians,
    selectedTechnicians,
    setSelectedTechnicians,
    handleRefreshTechnician,
    isShowUnassignedServiceJobs,
    setIsShowUnassignedServiceJobs,
  } = props;

  const [query, setQuery] = useState<string>('');
  const [showSelectCustomerDialog, setShowSelectCustomerDialog] = useState(false);
  const [selectedServiceCustomer, setSelectedServiceCustomer] = useState<ICustomer>();

  const debouncedSearch = useCallback(
    debounce((value: string) => {
      setSearchString(value);
    }, 300),
    [],
  );

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
    debouncedSearch(e.target.value);
  };

  const handleCreateBooking = () => {
    setSelectedServiceCustomer(undefined);
    setShowSelectCustomerDialog(true);
  };

  return (
    <>
      <Header afterUserSettingDialogClose={handleRefreshTechnician}></Header>
      <div className="m-2 flex h-[60px] items-center justify-center rounded-lg bg-white">
        <Typography data-autoid={'txtBookDiary'} className="whitespace-nowrap pl-2 text-xl font-light text-black-800">
          Booking Diary
        </Typography>
        <div className="flex w-full justify-end gap-x-2 pr-2">
          <div className="w-52">
            <SearchInput
              reverse={true}
              clearIcon
              clearIconProps={{
                onClick: () => {
                  setQuery('');
                  setSearchString('');
                },
              }}
              iconButtonProps={{
                name: 'Search',
              }}
              data-autoid="txtSearchInput"
              autoFocus
              placeholder="Search..."
              onChange={onSearchChange}
              value={query}
            />
          </div>
          <FilterTechnician
            technicians={technicians}
            setSelectedTechnicians={setSelectedTechnicians}
            selectedTechnicians={selectedTechnicians}
            isShowUnassignedServiceJobs={isShowUnassignedServiceJobs}
            setIsShowUnassignedServiceJobs={setIsShowUnassignedServiceJobs}
          />
          <Button onClick={handleCreateBooking}>Create Booking</Button>
        </div>
      </div>
      {showSelectCustomerDialog && (
        <SelectQuoteCustomerDialog
          isService={true}
          open={showSelectCustomerDialog}
          handleCancel={() => {
            setShowSelectCustomerDialog(!showSelectCustomerDialog);
            setSelectedServiceCustomer(undefined);
          }}
          handleConfirm={() => {
            setShowSelectCustomerDialog(!showSelectCustomerDialog);
            handleCreateAdhocBooking(selectedServiceCustomer!);
          }}
          setSelectedQuoteCustomer={setSelectedServiceCustomer}
          selectedQuoteCustomer={selectedServiceCustomer}
        />
      )}
    </>
  );
};
