import React, {useContext, useRef} from 'react';

import {PaymentWidgetContext, PaymentWidgetPaymentSteps, PaymentWidgetStep} from './PaymentWidget';

import {PrimaryButton, SecondaryButton} from '../../../components/buttons/DefaultButtons';
import {PriceFormat} from '../../../utils/formatter';
import {UpdateAccountRequest} from '../../../services/useSpendaPaymentServicesAPI';
import {PaymentMethodTypes} from '../../../model/constants/Constants';

interface IInfoProps {
  description: string;
  title?: string;
}

export const PaymentWidgetStepInvigoCreditInfo = () => {
  const {
    marketplaceSupplier,
    selectedPaymentMethod,
    setIsPersistSelectedPaymentMethod,
    setIsRefreshPaymentMethods,
    setStoredPaymentOptions,
    setStep,
    updateAccountDetails,
    updatePaymentMethodDetails,
  } = useContext(PaymentWidgetContext);

  const isLoading = useRef(false);

  const TermsInfo = ({title, description}: IInfoProps) => (
    <div className="px-6.5 text-xs">
      <div className="bg-blue-100 h-8 rounded-md flex items-center justify-center">
        <p>{title}</p>
      </div>
      <p className="pt-3 pb-8">{description}</p>
    </div>
  );

  const handleSelectDifferentPaymentMethod = () => {
    setStep && setStep(PaymentWidgetPaymentSteps.SELECT_PAYMENT_METHOD);
  };

  const handleUseThisPaymentMethod = async () => {
    if (!selectedPaymentMethod) {
      return;
    }

    isLoading.current = true;

    let updateReq: UpdateAccountRequest = {
      isInvigoDefault: true, // Set Auto Pay to true
    };

    const {PaymentAccountGUID, SupplierPaymentOptionID} = selectedPaymentMethod;

    if (PaymentAccountGUID) {
      if (updateAccountDetails) {
        await updateAccountDetails(PaymentAccountGUID, updateReq);
      }
    } else {
      if (SupplierPaymentOptionID && updatePaymentMethodDetails) {
        updatePaymentMethodDetails(SupplierPaymentOptionID.toString(), updateReq);
      }
    }

    if (setStoredPaymentOptions) setStoredPaymentOptions([]);

    if (setIsPersistSelectedPaymentMethod) setIsPersistSelectedPaymentMethod(true);
    if (setIsRefreshPaymentMethods) setIsRefreshPaymentMethods(true);

    setTimeout(() => {
      if (setStep) setStep(PaymentWidgetPaymentSteps.NEW);
    }, 1000);

    isLoading.current = false;
  };

  const body = (
    <div className="font-poppins">
      <p className="px-10 text-xs pb-12 pt-12">
        Would you like to use your{' '}
        {`${
          selectedPaymentMethod?.PaymentMethod === PaymentMethodTypes.BankTransfer
            ? 'bank account'
            : selectedPaymentMethod?.PaymentMethod
        } ending in ${selectedPaymentMethod?.Last4}`}{' '}
        as the default method for Buyer Finance repayments?
      </p>

      <TermsInfo title="Credit Limit" description={PriceFormat(marketplaceSupplier?.CreditLimit)} />
      <TermsInfo title="Repayment Terms" description={marketplaceSupplier?.CustomerClassName!} />

      <div className="pt-9">
        <PrimaryButton
          width="300px"
          margin="0 0 8px 0"
          label="Use this payment method"
          onClick={handleUseThisPaymentMethod}
          disabled={isLoading.current}
          isSubmitting={isLoading.current}
        />
        <SecondaryButton
          width="300px"
          label="Select a different payment method"
          onClick={handleSelectDifferentPaymentMethod}
          disabled={isLoading.current}
        />
      </div>
    </div>
  );

  return <PaymentWidgetStep title={'Select a default payment method'} body={body} />;
};
