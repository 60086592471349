import React from 'react';
import {Typography} from 'spenda-ui-react';
import {AlertDialogSlideV2} from './AlertDialogSlideV2';

interface IQuoteDuplicateDialog {
  handleCancel: () => void;
  handleDuplicate: () => void;
}

export const QuoteDuplicateDialog = (props: IQuoteDuplicateDialog) => {
  const {handleCancel, handleDuplicate} = props;

  return (
    <>
      <AlertDialogSlideV2
        title={'Duplicate quote package'}
        headingTextSize="h2"
        headingClassess="justify-center w-full"
        footerClass="justify-between sm:w-auto w-full"
        dialogClassess="!min-w-[20%] !max-w-[497px] !w-full sm:h-auto h-full sm:block flex justify-center items-center flex-col sm:p-0 p-8"
        dialogBodyClassess="flex justify-center"
        data-autoid={'dlgDuplicateQuoteDialog'}
        actions={[
          {
            label: 'Close',
            action: handleCancel,
            color: 'primary',
            variant: 'outlined',
          },
          {
            label: 'Duplicate',
            action: handleDuplicate,
          },
        ]}
      >
        <Typography
          variant="small"
          className="flex w-full max-w-[294px] items-center justify-center py-10 text-center font-normal text-black-800"
        >
          Are you sure you want to duplicate the whole quote package?
        </Typography>
      </AlertDialogSlideV2>
    </>
  );
};
