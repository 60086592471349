import {IConnectedSupplierStatementSummary} from './../model/supplier/SupplierTransaction';
import {ICreditCardT, ITenantSupplierPaymentMethod} from './../model/payment/PaymentMethod';
import {useState} from 'react';
import {PaymentAuthenticationErrorMsg, PaymentMethodTypes, VerificationStatus} from '../model/constants/Constants';
import {compareAsc, differenceInCalendarDays, lastDayOfMonth} from 'date-fns';
import {cloneDeep} from 'lodash';
import {IBatchOperationResponse} from '../model/accounts-receivable/batch';
import {Authorisers, PaymentAuthorisationResponse} from '../model/payment/PaymentAuthority';
import {ISelectedTransactionsList} from '../model/accounts-receivable/AccountsReceivable';

export const usePaymentUtilities = () => {
  const [oneOffPaymentMethod, setOneOffPaymentMethod] = useState<ICreditCardT | undefined>(undefined);

  const getPaymentMethodFromIssuer = (issuer?: string) => {
    // expected card types from stripe payment library
    // https://github.com/jessepollak/payment
    // amex must be changed to americanexpress
    let adjustedIssuerName = issuer?.toLowerCase().replace('amex', 'americanexpress');
    return (
      Object.values(PaymentMethodTypes).find(pm => pm.toLowerCase() === adjustedIssuerName) ||
      PaymentMethodTypes['Unknown']
    );
  };

  /**
   *
   * @param paymentMethodList List of payment options to loop through
   * @param spm Payment Method from the list that wants to be set as IsSelected.
   * @returns And array with only one of the payment options set as IsSelected
   */
  const swapSelectedPaymentMethod = (
    paymentMethodList: ITenantSupplierPaymentMethod[],
    spm?: ITenantSupplierPaymentMethod
  ) => {
    // scenario 1: When clicking a card
    if (spm) {
      return paymentMethodList.map(pm => {
        pm.IsSelected = pm.PaymentAccountGUID === spm.PaymentAccountGUID;
        return pm;
      });
    }

    // scenario 2: Just added a temporal card
    const temporalCardIndex = paymentMethodList.findIndex(pm => !pm.SaveCardForFuture && pm.IsSelected);
    if (temporalCardIndex >= 0) {
      return paymentMethodList.map((pm, index) => {
        pm.IsSelected = index === temporalCardIndex;
        return pm;
      });
    }

    // scenario 3: Enter pay mode. We only need to select first valid card or verified bank transfer available
    for (let pm of paymentMethodList) {
      if (pm.PaymentMethod === PaymentMethodTypes.BankTransfer) {
        if (pm.PaymentAccountVerificationStatus === VerificationStatus.VERIFIED) {
          pm.IsSelected = true;
          break;
        } else {
          pm.IsSelected = false;
        }
      } else {
        pm.IsSelected = true;
        break;
      }
    }

    // scenario 4: Enter pay mode. if any card have isDefault Property true, then select that card
    let isDefaultCardPresent = false;

    const updatedPaymentMethods = cloneDeep(paymentMethodList).map(p => {
      if (p.IsDefault && !isDefaultCardPresent) {
        p.IsSelected = true;
        isDefaultCardPresent = true;
      } else {
        p.IsSelected = false;
      }

      return p;
    });

    if (isDefaultCardPresent) {
      return updatedPaymentMethods;
    }

    return paymentMethodList;
  };

  const sortPaymentMethods = (responsePaymentMethods: ITenantSupplierPaymentMethod[]) => {
    return responsePaymentMethods.sort((a, b) => {
      if (b.IsSelected || a.IsSelected) {
        return Number(b.IsSelected) - Number(a.IsSelected);
      } else if (b.IsDefault || a.IsDefault) {
        return Number(b.IsDefault) - Number(a.IsDefault);
      } else if (b.LastUsedDateUTC && a.LastUsedDateUTC) {
        return Date.parse(b.LastUsedDateUTC!) - Date.parse(a.LastUsedDateUTC!);
      } else if (b.LastUsedDateUTC && !a.LastUsedDateUTC) {
        return Date.parse(b.LastUsedDateUTC);
      } else {
        return b.SupplierPaymentOptionID! - a.SupplierPaymentOptionID!;
      }
    });
  };

  /**
   * Returns 0 if @expiryDate is the current month.
   * Returns 1 if @expiryDate is the future.
   * Returns -1 if @expiryDate is in the past.
   * @param expiryDate
   */
  const compareExpiryDate = (expiryDate: string) => {
    const expiryMonth = parseInt(expiryDate.substr(0, 2)) - 1;
    const expiryYear = parseInt(expiryDate.substr(2)) + 2000;
    const lastDayOfCurrentMonth = lastDayOfMonth(new Date());
    const lastDayOfValidCard = lastDayOfMonth(new Date(expiryYear, expiryMonth));

    return compareAsc(lastDayOfValidCard, lastDayOfCurrentMonth);
  };

  /**
   * Returns the number of days left before the @expiryDate.
   * @param expiryDate
   */
  const daysLeftInCreditCard = (expiryDate: string) => {
    const expiryMonth = parseInt(expiryDate.substr(0, 2)) - 1;
    const expiryYear = parseInt(expiryDate.substr(2)) + 2000;
    const lastDayOfValidCard = lastDayOfMonth(new Date(expiryYear, expiryMonth));

    return differenceInCalendarDays(new Date(), lastDayOfValidCard);
  };

  const partialPaymentInProgress = (invoices: IConnectedSupplierStatementSummary[]) => {
    return invoices.find(i => i.AmountToPay && i.AmountToPay > 0 && i.AmountToPay < i.Balance);
  };
  const partialBatchPaymentInProgress = (invoices: IBatchOperationResponse[]) => {
    return invoices.find(i => i.amountToPay && i.amountToPay > 0 && i.amountToPay < i.balance);
  };

  /* This function converts string from kabab case to pascal case, ex-> american-express-->AmericanExpress  */
  const kebabToPascal = (text: string) => {
    const capitalize = (word: string) => {
      return word?.charAt(0)?.toLocaleUpperCase() + word?.slice(1);
    };

    const pascalize = (text: string) => {
      const words = text?.split('-');
      const completeWord = words?.map(word => capitalize(word));
      return completeWord?.join('');
    };

    return pascalize(text);
  };

  // This function adds Fullname in users object and changes logged in user's fullname to "Me"
  const transformPaymentAuthorisationUserInfo = (users: Authorisers[], userID?: number): Authorisers[] =>
    users?.map(user => {
      if (user.userID === userID) {
        user.name = `Me`;
      } else {
        user.name = `${user.firstName} ${user.lastName}`;
      }
      return user;
    });

  const getAuthenticationCodeErrorMsg = (authDetails: PaymentAuthorisationResponse) => {
    const errors = authDetails?.error?.errors;
    if (errors?.[0].code === PaymentAuthenticationErrorMsg.CodeExpired) {
      return `Your code is only valid for a ten minute period only.`;
    }
    const atemptsRemaining = authDetails?.authorisation?.attemptsRemainingCount;
    const newCodesRemaining = authDetails?.authorisation?.newCodesRemainingCount;
    const isAtemptsExhausted = atemptsRemaining === 0 && newCodesRemaining === 0;

    const getAttemptsLeftInWords = () => {
      switch (atemptsRemaining) {
        case 0:
          return 'zero attempts';
        case 1:
          return 'one attempt';
        case 2:
          return 'two attempts';
        default:
          return `${atemptsRemaining} attempts`;
      }
    };

    if (isAtemptsExhausted) {
      return `Incorrect code please contact support for assistance.`;
    } else {
      return `Invalid code, you have ${getAttemptsLeftInWords()} remaining.`;
    }
  };

  // New AR UX upgrade functions
  const arBatchPartialPaymentInProgress = (invoices: ISelectedTransactionsList[]) => {
    return invoices.find(i => i.amountToPay && i.amountToPay > 0 && i.amountToPay < i.balance);
  };

  return {
    oneOffPaymentMethod,
    getPaymentMethodFromIssuer,
    swapSelectedPaymentMethod,
    sortPaymentMethods,
    setOneOffPaymentMethod,
    compareExpiryDate,
    daysLeftInCreditCard,
    partialPaymentInProgress,
    partialBatchPaymentInProgress,
    arBatchPartialPaymentInProgress,
    kebabToPascal,
    transformPaymentAuthorisationUserInfo,
    getAuthenticationCodeErrorMsg,
  };
};
