import React, {useContext, useEffect, useState} from 'react';
import {MenuItem} from '@material-ui/core';
import {Delete, Done, Edit} from '@material-ui/icons';
import clsx from 'clsx';
import {Field, FieldArray, Form, Formik} from 'formik';
import * as Yup from 'yup';
import AppContext from '../../context/app/appContext';
import {IVendorListResponse} from '../../model/claims/ClaimsAndReturns';
import {
  ClaimActions,
  ClaimAndReturnRowAction,
  ClaimReasons,
  ClaimsAndReturnsStatus,
} from '../../model/constants/Constants';
import useClaimsAndReturnsAPI, {ICreateClaimRequestPayload} from '../../services/useClaimsAndReturnsAPI';
import {NameInitials, PriceFormat} from '../../utils/formatter';
import {CurrencyTextField} from '../inputs/STextField';
import {ARDialogTemplate} from './ARDialogTemplate';
import {ARExpandedSearchBar} from './ARExpandedSearchBar';
import {isEmpty} from 'lodash';
import {SCheckbox} from '../inputs/SCheckbox';
import {useFeatureFlags} from '../../hooks/useFeatureFlags';
import {useTenantInfo} from '../../hooks/useTenantInfo';
import {useClaimsAndReturnInputHook} from '../../hooks/useARHook';
import LoadingIndicator from '../ui/LoadingIndicator';
import {Button, Input, Typography} from 'spenda-ui-react';
import {ClaimsAndReturnActionReasonDropdown} from '../../screens/AccountsReceivable/claims-and-returns/ClaimsAndReturnInputs';

interface IClaimRequestFormValuesLineItems {
  reason: string;
  action: string;
  quantity: number;
  price: number;
  description: string;
  rowAction: ClaimAndReturnRowAction;
  isTaxExempt: boolean;
}

interface IClaimRequestFormValue {
  invoiceId: null;
  defaultReason: string;
  defaultAction: string;
  comment: string;
  lineItems: IClaimRequestFormValuesLineItems[];
}

const formInitialValues: IClaimRequestFormValue = {
  invoiceId: null,
  defaultReason: '',
  defaultAction: ClaimActions?.Credit,
  comment: '',
  lineItems: [
    {
      quantity: 0,
      price: 0,
      reason: '',
      action: ClaimActions?.Credit,
      description: '',
      rowAction: ClaimAndReturnRowAction.Create,
      isTaxExempt: false,
    },
  ],
};

interface IAdHocQuickClaimDialogProps {
  isShowModal: boolean;
  onClose: () => void;
  supplierId?: number;
  onSuccess: (claimCode: string) => void;
}

export const AdHocQuickClaimDialog = (props: IAdHocQuickClaimDialogProps) => {
  // Props
  const {isShowModal, supplierId, onClose, onSuccess} = props;

  // APIs
  const {createClaimRequest, updateClaimAndReturnStatus, isLoading: isSubmitting} = useClaimsAndReturnsAPI();
  const {getVendorList, isLoading: isVendorLoading} = useClaimsAndReturnsAPI();

  // Hooks
  const {marketplaceSupplier} = useContext(AppContext);
  const {isInBuyerContext} = useTenantInfo();
  const {reasonDropdownOptions, claimDefaultReason, claimLineItemReason, isClaimReasonDropdowLoading} =
    useClaimsAndReturnInputHook({
      linkedSupplierID: supplierId,
    });

  //Feature Flags
  const {claimAndReturnsV272602: claimAndReturnsV2Supplier} = useFeatureFlags().tenantOwned();
  const {claimAndReturnsV272602: claimAndReturnsV2Buyer} = useFeatureFlags().supplierDriven();
  const claimAndReturnsV272602 = isInBuyerContext
    ? claimAndReturnsV2Buyer && claimAndReturnsV2Supplier
    : claimAndReturnsV2Supplier;

  // State
  const [editInvoiceLineId, setEditInvoiceLineId] = useState<number | undefined>(0);
  const [vendorListOptions, setVendorListOptions] = useState<IVendorListResponse[]>();
  const [selectedVendor, setSelectedVendor] = useState<number>();
  const [vendorSearchStr, setVendorSearchStr] = useState('');
  const [formValues, setFormValues] = useState<IClaimRequestFormValue>(formInitialValues);

  useEffect(() => {
    setFormData();
  }, [claimDefaultReason, claimLineItemReason]);

  const setFormData = () => {
    if (claimAndReturnsV272602) {
      setFormValues(prev => ({
        ...prev,
        defaultReason: claimDefaultReason || prev.defaultReason,
        lineItems: [{...prev.lineItems?.[0], reason: claimLineItemReason || prev.lineItems?.[0]?.reason}],
      }));
    } else {
      setFormValues(prev => ({
        ...prev,
        defaultReason: ClaimReasons.Damaged,
        lineItems: [{...prev.lineItems?.[0], reason: ClaimReasons.Damaged}],
      }));
    }
  };

  // Inputs
  function productDescriptionInput({field, form, indx}: {field: any; form: any; indx: number}) {
    return (
      <div className="relative font-poppins text-xs">
        <Input
          autoFocus={form?.values?.lineItems?.length > 1 ? editInvoiceLineId === indx : false}
          data-autoid={`txtProductName`}
          label="Product name"
          onChange={field?.onChange}
          onBlur={field?.onBlur}
          maxLength={50}
          containerProps={{className: '!w-[312px] !min-w-[312px]'}}
          helperText={form?.touched?.lineItems?.[indx]?.description && form?.errors?.lineItems?.[indx]?.description}
          error={form?.touched?.lineItems?.[indx]?.description && form?.errors?.lineItems?.[indx]?.description}
          name={field?.name}
          value={field?.value}
        />
      </div>
    );
  }

  const fetchVendorList = async () => {
    try {
      const vl = await getVendorList({linkedSupplierID: supplierId, searchString: vendorSearchStr});
      setVendorListOptions(vl);
    } catch {}
  };

  function quantityInput({field, form, indx}: {field: any; form: any; indx: number}) {
    return (
      <div className="relative font-poppins text-xs">
        <Input
          label="Qty"
          type="number"
          id="Qty"
          key={field?.name}
          onBlur={field?.onBlur}
          data-autoid={`txtQty`}
          name={field?.name}
          value={field?.value}
          className="!mt-1"
          containerProps={{className: '!w-[150px] !min-w-[150px]'}}
          onChange={field?.onChange}
          helperText={form?.touched?.lineItems?.[indx]?.quantity && form?.errors?.lineItems?.[indx]?.quantity}
          error={form?.touched?.lineItems?.[indx]?.quantity && form?.errors?.lineItems?.[indx]?.quantity}
        />
      </div>
    );
  }

  function isTaxExemptInput({field}: any) {
    return (
      <div className="ml-1 mt-1 flex h-full flex-col !font-poppins">
        <SCheckbox
          label="Tax exempt"
          fontFamily="poppins"
          lableFontSize="12px"
          name={field?.name}
          checked={field?.value}
          value={field?.value}
          onChange={field?.onChange}
          onBlur={field?.onBlur}
        />
      </div>
    );
  }

  function currencyInput({field, form, indx}: {field: any; form: any; indx: number}) {
    return (
      <CurrencyTextField
        id="Amount"
        type="text"
        data-autoid={`txtAmount`}
        label="Amount"
        className=" !mt-1 !w-[150px] !min-w-[150px]"
        name={field?.name}
        value={field?.value}
        onChange={field?.onChange}
        onBlur={field?.onBlur}
        helperText={form?.touched?.lineItems?.[indx]?.price && form?.errors?.lineItems?.[indx]?.price}
        error={form?.touched?.lineItems?.[indx]?.price && Boolean(form?.errors?.lineItems?.[indx]?.price)}
      />
    );
  }

  function selectReasonInput({field}: {field: any}) {
    return (
      <div className="!mt-2">
        <ClaimsAndReturnActionReasonDropdown
          dataAutoId={`ddlReason`}
          label="Reason"
          className="!h-[45px] !w-[150px] !min-w-[150px]"
          inputProps={{className: '!min-w-[150px] !w-[150px]'}}
          containerProps={{className: '!min-w-[150px] !w-[150px]'}}
          name={field?.name}
          value={field?.value}
          onChange={field?.onChange}
          onBlur={field?.onBlur}
          reasonDropdownOptions={reasonDropdownOptions}
          showSubReason={true}
          claimAndReturnsV272602={claimAndReturnsV272602}
          type="reason"
        />
      </div>
    );
  }

  function selectActionInput({field}: {field: any}) {
    return (
      <div className="!ml-3 !mt-2">
        <ClaimsAndReturnActionReasonDropdown
          dataAutoId={`ddlAction`}
          label="Action"
          className="!h-[45px] !w-[150px] !min-w-[150px]"
          inputProps={{className: '!min-w-[150px] !w-[150px]'}}
          containerProps={{className: '!min-w-[150px] !w-[150px]'}}
          name={field?.name}
          value={field?.value}
          onChange={field?.onChange}
          onBlur={field?.onBlur}
          type="action"
        />
      </div>
    );
  }

  const handleSubmit = async (values: IClaimRequestFormValue) => {
    try {
      const payload: ICreateClaimRequestPayload = {
        linkedSupplierID: supplierId || undefined,
        comment: values?.comment,
        defaultClaimReason: values?.defaultReason,
        defaultReturnType: values?.defaultAction,
        vendorID: selectedVendor,
        lines: values?.lineItems?.map(l => ({
          quantity: l?.quantity,
          isTaxExempt: l?.isTaxExempt,
          claimReason: l?.reason,
          returnType: l?.action,
          action: ClaimAndReturnRowAction.Create,
          description: l?.description,
          amountEx: l?.price,
        })),
      };

      const cr = await createClaimRequest(payload);
      if (cr) {
        const sr = await updateClaimAndReturnStatus(cr?.claimRequestID, {
          status: ClaimsAndReturnsStatus.Sent,
          linkedSupplierID: supplierId,
        });
        if (sr) onSuccess(cr?.claimCode);
      }
    } catch {}
  };

  const AdHocClaimRequestFormValidation = Yup.object({
    defaultAction: Yup.string().required('Default Action is required.'),
    defaultReason: Yup.string().required('Default Reason is required.'),
    comment: Yup.string().max(250, 'Max 250 char allowed'),
    lineItems: Yup.array().of(
      Yup.object().shape({
        quantity: Yup.number()
          .nullable(true)
          .when('rowAction', {
            is: (val: ClaimAndReturnRowAction) => val !== ClaimAndReturnRowAction.Delete,
            then: Yup.number().min(1, 'Quantity Should be greater than zero').required('Quantity is required'),
          }),
        action: Yup.string().required('Action is required'),
        reason: Yup.string().required('Reason is required'),
        price: Yup.number().min(1, 'Amount Should be greater than zero').required('Amount is required'),
        description: Yup.string().required('Product description is required'),
      }),
    ),
  });

  return (
    <>
      <Formik
        initialValues={formValues}
        onSubmit={handleSubmit}
        validationSchema={AdHocClaimRequestFormValidation}
        enableReinitialize
        validateOnChange={false}
      >
        {({values, errors, touched, handleChange, handleBlur, handleSubmit, validateForm}) => (
          <Form className="h-full">
            <ARDialogTemplate
              dialogProps={{size: 'sm', open: isShowModal, className: '!min-w-[428px] !w-[428px]'}}
              isFullScreen
              footerClass="h-[60px]"
              body={
                <>
                  {isClaimReasonDropdowLoading && claimAndReturnsV272602 ? (
                    <div
                      className="flex h-[500px] w-[384px] items-center justify-center"
                      data-autoid="LoadingIndicator"
                    >
                      <LoadingIndicator size="md" isLoading={isClaimReasonDropdowLoading} />
                    </div>
                  ) : (
                    <>
                      <div className="absolute left-0 right-0 top-0 flex h-[94px] w-full flex-row gap-2.5 bg-[#FAFAFA] px-2.5 pt-2.5">
                        <div
                          data-autoid={`lblTenantName`}
                          className="flex h-10 w-10 !min-w-10 flex-col items-center justify-center rounded-full bg-[#3D9F7820] font-poppins text-base font-normal leading-normal text-[#3D9F78]"
                        >
                          {NameInitials(marketplaceSupplier?.TenantName)}
                        </div>
                        <div className="flex w-full flex-col items-center justify-start">
                          <div className="flex w-full flex-row items-center justify-between">
                            <Typography
                              data-autoid={`lblTenantName`}
                              className="font-medium leading-normal text-black-800"
                              variant="paragraph"
                            >
                              {marketplaceSupplier?.TenantName}
                            </Typography>
                            <Typography
                              data-autoid={`lblBusinessPhone`}
                              className="font-normal leading-normal text-black-800"
                              variant="xsmall"
                            >
                              {marketplaceSupplier?.SupplierBusinessPhone}
                            </Typography>
                          </div>
                          <div className="mt-[4px] flex w-full flex-col items-end">
                            <ARExpandedSearchBar
                              className="w-full"
                              isSearching={isVendorLoading}
                              data-autoid="SearchBar"
                              value={vendorSearchStr}
                              onChange={e => setVendorSearchStr(e?.target?.value)}
                              handleSearch={() => {
                                setVendorListOptions(undefined);
                                fetchVendorList();
                              }}
                              isShowOptions={!!vendorListOptions?.length}
                              placeholder="Search Vendor"
                              label="Search Vendor"
                              renderMenuItems={
                                <>
                                  {vendorListOptions?.map(v => (
                                    <MenuItem
                                      key={v?.vendorID}
                                      onClick={() => {
                                        setVendorSearchStr(v?.name);
                                        setSelectedVendor(v?.vendorID);
                                      }}
                                    >
                                      {v?.name}
                                    </MenuItem>
                                  ))}
                                </>
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex w-full flex-col items-center justify-start pt-[84px]">
                        <div className="flex w-full flex-row items-center justify-between">
                          <div className="flex flex-row items-center gap-2">
                            <Typography className="font-medium leading-normal text-black-800" variant="paragraph">
                              Claimed items({values?.lineItems?.length})
                            </Typography>
                            {/* <AdHocAddIcon /> */}
                          </div>
                          <Typography
                            data-autoid={`lblPrice`}
                            className="font-semibold leading-normal text-[#3C9F78]"
                            variant="paragraph"
                          >
                            {PriceFormat(values?.lineItems?.reduce((a, c) => a + c.price * c.quantity, 0.0))}
                          </Typography>
                        </div>
                        <div className="mb-2 w-full font-poppins text-xs">
                          <Input
                            label="Add note"
                            name="comment"
                            data-autoid="txtAddNotes"
                            value={values?.comment}
                            onChange={handleChange}
                            placeholder="Add notes(e.g. I have found these broken products)"
                            className="!my-3 mb-2"
                          />
                        </div>
                        <div className="mb-[18px] flex w-full flex-row items-center justify-between gap-2.5">
                          <div className="flex flex-row">
                            <div className="!mt-3">
                              <ClaimsAndReturnActionReasonDropdown
                                label="Reason"
                                className="!h-[45px] !min-w-[190px]"
                                dataAutoId={`ddlDefaultReason`}
                                value={values?.defaultReason}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="defaultReason"
                                reasonDropdownOptions={reasonDropdownOptions}
                                showSubReason={false}
                                claimAndReturnsV272602={claimAndReturnsV272602}
                                type="reason"
                              />
                            </div>
                            <div className="!ml-3 !mt-3">
                              <ClaimsAndReturnActionReasonDropdown
                                label="Action"
                                className="!h-[45px] !min-w-[190px]"
                                dataAutoId={`ddlDefaultAction`}
                                value={values?.defaultAction}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="defaultAction"
                                type="action"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="h-[18px] w-full border-spenda-dashbg border-t-default"></div>
                        <FieldArray
                          name="lineItems"
                          render={arrayHelpers => (
                            <div className="w-full">
                              {values.lineItems &&
                                values.lineItems.length > 0 &&
                                values?.lineItems?.map((l, indx) => (
                                  <div key={indx}>
                                    <div
                                      className={clsx(
                                        'relative mb-4 flex w-[394px] flex-row items-start justify-between rounded-[6px] bg-[#dae9f175] p-2.5',
                                        {
                                          'border-[1px] border-red-700':
                                            touched?.lineItems?.[indx] &&
                                            errors?.lineItems?.[indx] &&
                                            indx !== editInvoiceLineId,
                                        },
                                      )}
                                    >
                                      <div className="flex w-[80%] flex-col">
                                        {editInvoiceLineId === indx ? (
                                          <Field
                                            name={`lineItems[${indx}].description`}
                                            component={productDescriptionInput}
                                            indx={indx}
                                          />
                                        ) : (
                                          <Typography
                                            data-autoid={`lblDescription`}
                                            className="mb-[3px] font-medium text-black-800"
                                            variant="paragraph"
                                          >
                                            {l?.description}
                                          </Typography>
                                        )}{' '}
                                        <div className="mb-[11px] mt-5 flex flex-row items-center gap-[12px] font-poppins text-xs font-normal leading-normal text-black-800">
                                          {editInvoiceLineId === indx ? (
                                            <>
                                              <Field
                                                name={`lineItems[${indx}].quantity`}
                                                component={quantityInput}
                                                indx={indx}
                                              />
                                              <Field
                                                name={`lineItems[${indx}].price`}
                                                component={currencyInput}
                                                indx={indx}
                                              />
                                            </>
                                          ) : (
                                            <Typography data-autoid={`lblAmount`}>
                                              Qty: {l?.quantity} {'  |  '} Amount: {PriceFormat(l?.price)}
                                            </Typography>
                                          )}
                                        </div>
                                        <div className="flex flex-row">
                                          {editInvoiceLineId === indx ? (
                                            <div key={`lineItems[${indx}].secondGroup`}>
                                              <Field name={`lineItems[${indx}].reason`} component={selectReasonInput} />
                                            </div>
                                          ) : (
                                            <Typography
                                              className="mr-5 font-semibold leading-normal text-black-800"
                                              variant="xsmall"
                                            >
                                              Reason:{' '}
                                              <span className=" font-normal" data-autoid={`lblReason`}>
                                                {l?.reason}
                                              </span>
                                            </Typography>
                                          )}
                                          {editInvoiceLineId === indx ? (
                                            <div key={`lineItems[${indx}].thirdGroup`}>
                                              <Field name={`lineItems[${indx}].action`} component={selectActionInput} />
                                            </div>
                                          ) : (
                                            <Typography
                                              className="font-semibold leading-normal text-black-800"
                                              variant="xsmall"
                                            >
                                              Action:{' '}
                                              <Typography className="font-normal" data-autoid={`lblAction`}>
                                                {l?.action}
                                              </Typography>
                                            </Typography>
                                          )}
                                        </div>
                                        <div className="flex flex-row">
                                          {claimAndReturnsV272602 && (
                                            <Field
                                              name={`lineItems[${indx}].isTaxExempt`}
                                              component={isTaxExemptInput}
                                              indx={indx}
                                            />
                                          )}
                                        </div>
                                      </div>

                                      {editInvoiceLineId === indx ? (
                                        <div className="flex cursor-pointer flex-col justify-end whitespace-nowrap align-middle font-poppins text-xs font-semibold leading-normal text-primary">
                                          <button
                                            data-autoid={`btnDone`}
                                            type="button"
                                            onClick={async () => {
                                              const err = await validateForm();
                                              if (isEmpty(err)) {
                                                setEditInvoiceLineId(undefined);
                                              } else {
                                                handleSubmit();
                                              }
                                            }}
                                          >
                                            <Done className=" !h-4" />
                                            Done
                                          </button>
                                          {values?.lineItems?.length > 1 && (
                                            <button className="absolute bottom-2 right-2 cursor-pointer whitespace-nowrap align-middle font-poppins text-xs font-semibold leading-normal text-primary">
                                              <Delete
                                                data-autoid={`btnDelete`}
                                                className={clsx('cursor-pointer text-[#CCCCCC]')}
                                                onClick={() => {
                                                  arrayHelpers.remove(indx);
                                                  setEditInvoiceLineId(indx - 1);
                                                }}
                                              />
                                            </button>
                                          )}
                                        </div>
                                      ) : (
                                        <div className="flex flex-col items-end justify-between gap-1">
                                          <Typography
                                            className="cursor-pointer whitespace-nowrap align-middle font-semibold leading-normal text-primary"
                                            variant="xsmall"
                                            onClick={() => {
                                              if (!errors?.lineItems?.length) setEditInvoiceLineId(indx);
                                            }}
                                          >
                                            <Edit data-autoid={`btnEdit`} className=" !h-4" /> Edit
                                          </Typography>
                                          {values?.lineItems?.length > 1 && (
                                            <Typography
                                              className="absolute bottom-2 right-2 cursor-pointer whitespace-nowrap align-middle font-semibold leading-normal text-primary"
                                              variant="xsmall"
                                            >
                                              <Delete
                                                data-autoid={`btnDelete`}
                                                className={clsx('cursor-pointer text-[#CCCCCC]')}
                                                onClick={() => {
                                                  setEditInvoiceLineId(
                                                    indx === editInvoiceLineId
                                                      ? indx - 1
                                                      : editInvoiceLineId && editInvoiceLineId - 1,
                                                  );
                                                  arrayHelpers.remove(indx);
                                                }}
                                              />
                                            </Typography>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                    {indx + 1 === values?.lineItems?.length && (
                                      <button
                                        data-autoid={`btnAddLineItem`}
                                        className="flex w-full items-center justify-end font-poppins text-base font-semibold text-primary"
                                        type="button"
                                        onClick={async () => {
                                          const err = await validateForm();
                                          if (isEmpty(err)) {
                                            arrayHelpers.insert(indx + 1, {
                                              quantity: 0,
                                              price: 0,
                                              reason: claimLineItemReason || ClaimReasons.Damaged,
                                              action: ClaimActions.Credit,
                                              description: '',
                                              rowAction: ClaimAndReturnRowAction.Create,
                                            });
                                            setEditInvoiceLineId(values?.lineItems?.length);
                                          } else {
                                            handleSubmit();
                                          }
                                        }}
                                      >
                                        Add line item
                                      </button>
                                    )}
                                  </div>
                                ))}
                            </div>
                          )}
                        />
                      </div>
                    </>
                  )}
                </>
              }
              footer={
                <div className="flex h-[60px] w-full items-center justify-between">
                  <Button
                    data-autoid={`btnDiscard`}
                    color="error"
                    variant="outlined"
                    className="bg-white"
                    onClick={() => onClose()}
                  >
                    Discard
                  </Button>
                  <Button
                    loading={isSubmitting}
                    data-autoid="btnSend"
                    disabled={isSubmitting}
                    variant="filled"
                    color="primary"
                    onClick={() => handleSubmit()}
                  >
                    Send
                  </Button>
                </div>
              }
              handleClose={onClose}
            />
          </Form>
        )}
      </Formik>
    </>
  );
};
