import React from 'react';
import {IconSales} from '../../assets/svg/IconSales';
import {IconPurchasing} from '../../assets/svg/IconPurchasing';
import {IconPurchasingRebranding} from '../../assets/svg/IconPurchasingRebranding';
import {IconUser} from '../../assets/svg/IconUser';
import {IconCollect} from '../../assets/svg/IconCollect';
import {IconProductManagement} from '../../assets/svg/IconProductManagement';
import {IconProductsRebranding} from '../../assets/svg/IconProductsRebranding';
import {IconProductClassesRebranding} from '../../assets/svg/IconProductClassesRebranding';
import {BankAccounts, ModuleTypes, PaymentMethodTypes} from '../../model/constants/Constants';
import {IconReceive} from '../../assets/svg/IconReceive';
import {IconOrders} from '../../assets/svg/IconOrders';
import {SpendaPOS} from '../../assets/svg/SpendaPOS';
import {SpendaService} from '../../assets/svg/SpendaService';
import {SpendaStock} from '../../assets/svg/SpendaStock';
import {IconPayOptRebranding} from '../../assets/svg/IconPayOptRebranding';
import {IconCreditCardRebranding} from '../../assets/svg/IconCreditCardRebranding';
import {IconBillsRebranding} from '../../assets/svg/IconBillsRebranding';
import {Amex} from '../../assets/svg/payment-methods/AMEX';
import {DinersClub} from '../../assets/svg/payment-methods/DinersClub';
import {Mastercard} from '../../assets/svg/payment-methods/MasterCard';
import {UnionPay} from '../../assets/svg/payment-methods/UnionPay';
import {Visa} from '../../assets/svg/payment-methods/Visa';
import {IconCompanyDetailsNew} from '../../assets/svg/IconCompanyDetailsNew';
import {IconCompanyLocationsNew} from '../../assets/svg/IconCompanyLocationsNew';
import {IconManageUsersNew} from '../../assets/svg/IconManageUsersNew';
import {IconPay} from '../../assets/svg/IconPay';
import {Export} from '../../assets/svg/Export';
import {IconAccountReceivables} from '../../assets/svg/menu/AccountReceivables';
import ExperimentNew from '../../assets/svg/ExperimentNew';
import CreditStop from '../../assets/svg/CreditStop';
import CreditHold from '../../assets/svg/CreditHold';
import {ICustomer} from '../../assets/svg/ICustomer';
import {IconSalesOrderListingRebranding} from '../../assets/svg/IconSalesOrderRebranding';
import {IconRequireAttention} from '../../assets/svg/IconRequireAttention';
import {IconAccountPayable} from '../../assets/svg/menu/AccountsPayable';
import {IconCashFlow} from '../../assets/svg/menu/CashFlow';
import {IconCustomers} from '../../assets/svg/menu/Customers';
import {IconEcom} from '../../assets/svg/menu/Ecom';
import {IconPaymentServices} from '../../assets/svg/menu/PaymentServices';
import {IconMenuProductManagement} from '../../assets/svg/menu/ProductManagement';
import {IconRangeNewProducts} from '../../assets/svg/menu/RangeNewProducts';
import {IconReceiveStock} from '../../assets/svg/menu/ReceiveStock';
import {IconService} from '../../assets/svg/menu/Service';
import {IconSalesOrders} from '../../assets/svg/menu/SalesOrders';
import {IconSalesOrderManagement} from '../../assets/svg/menu/SalesOrderManagement';
import {IconSuppliers} from '../../assets/svg/menu/Suppliers';
import {IconTradeFinance} from '../../assets/svg/menu/TradeFinance';
import {IconWareHouse} from '../../assets/svg/menu/WareHouse';
import {Dashboard} from '../../assets/svg/accounts-payable/Dashboard';
import {ApproveRequest} from '../../assets/svg/accounts-payable/ApproveRequest';
import {Reports} from '../../assets/svg/accounts-payable/Reports';
import {Invoice} from '../../assets/svg/accounts-payable/Invoice';
import {Settings} from '../../assets/svg/accounts-payable/Settings';
import ccIcon from '../../assets/svg/accounts-payable/cc.png';
import bank from '../../assets/svg/payment-methods/bank.svg';
import APVisaIcon from '../../assets/png/AccountsPayable/visa-logo.png';
import {OperationalCustomerRebranding} from '../../assets/svg/OperationalCustomerRebranding';
import {AccountCustomersRebranding} from '../../assets/svg/AccountCustomersRebranding';
import {CustomersClassesRebranding} from '../../assets/svg/CustomerClassesRebranding';
import {PurchaseOrderIcon} from '../../assets/svg/purchasing/PurchaseOrderIcon';
import {PurchaseInvoiceIcon} from '../../assets/svg/purchasing/PurchaseInvoiceIcon';
import {Upload} from '../../assets/svg/accounts-payable/Upload';
import {AuthorisePayment} from '../../assets/svg/accounts-payable/AuthorisePayment';
import {RequiresAttention} from '../../assets/svg/accounts-payable/RequiresAttention';
import SupplierManagement from '../../assets/svg/accounts-payable/SupplierManagement';
import IconQuotes from '../../assets/svg/menu/Quotes';
import IconQuotesListing from '../../assets/svg/quotes-management/IconQuotesListing';
import {IconStatements} from '../../assets/svg/menu/Statements';
import {IconBookingDiary} from '../../assets/svg/menu/BookingDiary';
import {IconServiceCustomer} from '../../assets/svg/menu/ServiceCustomer';
import VirtualCreditFacility from '../../assets/svg/VirtualCreditFacility';
import MerchantPortal from '../../assets/svg/menu/MerchantPortal';
import PaidInvoices from '../../assets/svg/menu/PaidInvoices';
import IconRequiresAttention from '../../assets/svg/IconRequiresAttention';
import {CatalogueIcon} from '../../assets/svg';

export enum SVGName {
  AccountCustomers = 'AccountCustomers',
  AccountReceivables = 'Accounts Receivable',
  AccountsPayable = 'AccountsPayable',
  ApproveRequest = 'approveRequest',
  AuthorisePayment = 'authorisePayment',
  SupplierManagement = 'supplierManagement',
  AR = 'ar',
  Bills = 'bills',
  Buy = 'buy',
  Buying = 'buying',
  CashFlow = 'CashFlow',
  Collect = 'collect',
  CompanyDetails = 'companyDetails',
  CompanyLocations = 'companyLocations',
  CreditCards = 'creditCards',
  CreditHold = 'CreditHold',
  CreditStop = 'creditStop',
  Customers = 'Customers',
  CustomersClasses = 'CustomersClasses',
  Dashboard = 'dashboard',
  Ecom = 'Ecom',
  Experiment = 'experiment',
  Export = 'export',
  Invoices = 'invoices',
  RequiresAttention = 'requiresAttention',
  Upload = 'upload',
  ManageUsers = 'manageUsers',
  OperationalCustomer = 'OperationalCustomer',
  Order = 'order',
  Pay = 'pay',
  PaymentServices = 'PaymentServices',
  ProductClasses = 'productClasses',
  ProductManagement = 'productManagement',
  Products = 'products',
  PurchaseInvoices = 'PurchaseInvoices',
  PurchaseOrders = 'PurchaseOrders',
  RangeNewProducts = 'RangeNewProducts',
  Receive = 'receive',
  ReceiveStock = 'ReceiveStock',
  Reports = 'reports',
  RequireAttention = 'RequireAttention',
  SalesOrders = 'SalesOrders',
  SalesOrdersListing = 'SalesOrdersListing',
  SalesOrdersManagement = 'Sales Orders',
  Service = 'service',
  BookingDiary = 'BookingDiary',
  ServiceCustomer = 'ServiceCustomer',
  Settings = 'settings',
  SpendaCustomer = 'SpendaCustomer',
  Suppliers = 'Suppliers',
  TradeFinance = 'TradeFinance',
  User = 'user',
  WareHouse = 'WareHouse',
  Quotes = 'Quotes',
  QuotesListing = 'QuotesListing',
  Statements = 'Statements',
  VirtualCreditFacility = 'VirtualCreditFacility',
  MerchantPortal = 'MerchantPortal',
  PaidInvoices = 'PaidInvoices',
  PSBLRequiresAttention = 'PSBLRequiresAttention',
  Catalogue = 'Catalogue',
}
interface ISVGIcon {
  paymentMethod?: PaymentMethodTypes | string;
  bankAccount?: BankAccounts | string;
  moduleId?: ModuleTypes;
  name?: SVGName | string;
  width?: string;
  height?: string;
  isAccountsPayable?: boolean;
}

const SVGIcon = (props: ISVGIcon) => {
  const {paymentMethod, bankAccount, moduleId, name, width, height, isAccountsPayable} = props;

  let svgIcon = null;

  if (paymentMethod) {
    switch (paymentMethod) {
      case PaymentMethodTypes.AmericanExpress:
        svgIcon = <Amex />;
        break;
      case PaymentMethodTypes.DinersClub:
        svgIcon = <DinersClub />;
        break;
      case PaymentMethodTypes.Mastercard:
        if (isAccountsPayable) {
          svgIcon = <img src={ccIcon} width="72px" height="48px" />;
        } else {
          svgIcon = <Mastercard />;
        }
        break;
      case PaymentMethodTypes.UnionPay:
        svgIcon = <UnionPay />;
        break;
      case PaymentMethodTypes.Visa:
        if (isAccountsPayable) {
          svgIcon = <img src={APVisaIcon} width="85px" height="46px" />;
        } else {
          svgIcon = <Visa />;
        }
        break;
      default:
        break;
    }
  }

  if (bankAccount) {
    switch (bankAccount) {
      case BankAccounts.ANZ:
        svgIcon = <Amex />;
        break;
      case BankAccounts.BANKWEST:
        svgIcon = <DinersClub />;
        break;
      case BankAccounts.CBA:
        svgIcon = <Mastercard />;
        break;
      case BankAccounts.NAB:
        svgIcon = <UnionPay />;
        break;
      case BankAccounts.WESTPAC:
        svgIcon = <Visa />;
        break;
      default:
        svgIcon = (
          <img
            src={bank}
            style={{width: `${width ? width : '28px'}`, height: `${height ? height : '32px'}`, paddingTop: '1px'}}
          />
        );
        break;
    }
  }

  if (moduleId) {
    switch (moduleId) {
      case ModuleTypes.SpendaMarketSelling:
        svgIcon = <IconOrders />;
        break;
      case ModuleTypes.SpendaCollectCustomerPortal:
        svgIcon = <IconCollect />;
        break;
      case ModuleTypes.SpendaMarketBuying:
        svgIcon = <IconSales />;
        break;
      case ModuleTypes.Recvd:
        svgIcon = <IconReceive />;
        break;
      case ModuleTypes.SpendaPOS:
        svgIcon = <SpendaPOS />;
        break;
      case ModuleTypes.Service:
        svgIcon = <SpendaService />;
        break;
      case ModuleTypes.Inventory:
        svgIcon = <SpendaStock />;
        break;
      default:
        break;
    }
  }

  if (!moduleId && !svgIcon) {
    switch (name) {
      case SVGName.Buying:
        svgIcon = <IconPurchasing width={width} height={height} />;
        break;
      case SVGName.Buy:
        svgIcon = <IconSales width={width} height={height} />;
        break;
      case SVGName.Collect:
        svgIcon = <IconCollect width={width} height={height} />;
        break;
      case SVGName.ProductManagement:
        svgIcon = <IconProductManagement width={width} height={height} />;
        break;
      case SVGName.Products:
        svgIcon = <IconProductsRebranding width={width} height={height} />;
        break;
      case SVGName.OperationalCustomer:
        svgIcon = <OperationalCustomerRebranding width={width} height={height} />;
        break;
      case SVGName.AccountCustomers:
        svgIcon = <AccountCustomersRebranding width={width} height={height} />;
        break;
      case SVGName.Customers:
        svgIcon = <ICustomer width={width} height={height} />;
        break;
      case SVGName.CustomersClasses:
        svgIcon = <CustomersClassesRebranding width={width} height={height} />;
        break;
      case SVGName.ProductClasses:
        svgIcon = <IconProductClassesRebranding width={width} height={height} />;
        break;
      case SVGName.Receive:
        svgIcon = <IconReceive width={width} height={height} />;
        break;
      case SVGName.Service:
        svgIcon = <IconService width={width} height={height} />;
        break;
      case SVGName.BookingDiary:
        svgIcon = <IconBookingDiary width={width} height={height} />;
        break;
      case SVGName.ServiceCustomer:
        svgIcon = <IconServiceCustomer width={width} height={height} />;
        break;
      case SVGName.Bills:
        svgIcon = <IconBillsRebranding width={width} height={height} />;
        break;
      case SVGName.CreditCards:
        svgIcon = <IconPayOptRebranding width={width} height={height} />;
        break;
      case SVGName.AR:
        svgIcon = <IconCreditCardRebranding width={width} height={height} />;
        break;
      case SVGName.Order:
        svgIcon = <IconPurchasingRebranding width={width} height={height} />;
        break;
      case SVGName.CompanyDetails:
        svgIcon = <IconCompanyDetailsNew width={width} height={height} />;
        break;
      case SVGName.CompanyLocations:
        svgIcon = <IconCompanyLocationsNew width={width} height={height} />;
        break;
      case SVGName.ManageUsers:
        svgIcon = <IconManageUsersNew width={width} height={height} />;
        break;
      case SVGName.Experiment:
        svgIcon = <ExperimentNew width={width} height={height} />;
        break;
      case SVGName.Pay:
        svgIcon = <IconPay width={width} height={height} />;
        break;
      case SVGName.Export:
        svgIcon = <Export width={width} />;
        break;
      case SVGName.Dashboard:
        svgIcon = <Dashboard width={width} />;
        break;
      case SVGName.ApproveRequest:
        svgIcon = <ApproveRequest width={width} />;
        break;
      case SVGName.AuthorisePayment:
        svgIcon = <AuthorisePayment width={width} />;
        break;
      case SVGName.SupplierManagement:
        svgIcon = <SupplierManagement width={width} />;
        break;
      case SVGName.Reports:
        svgIcon = <Reports width={width} />;
        break;
      case SVGName.Invoices:
        svgIcon = <Invoice width={width} />;
        break;
      case SVGName.RequiresAttention:
        svgIcon = <RequiresAttention width={width} />;
        break;
      case SVGName.Upload:
        svgIcon = <Upload width={width} />;
        break;
      case SVGName.Settings:
        svgIcon = <Settings width={width} />;
        break;
      case SVGName.CreditStop:
        svgIcon = <CreditStop width={width} height={height} />;
        break;
      case SVGName.CreditHold:
        svgIcon = <CreditHold width={width} height={height} />;
        break;
      case SVGName.AccountReceivables:
        svgIcon = <IconAccountReceivables width={width} height={height} />;
        break;
      case SVGName.AccountsPayable:
        svgIcon = <IconAccountPayable width={width} height={height} />;
        break;
      case SVGName.CashFlow:
        svgIcon = <IconCashFlow width={width} height={height} />;
        break;
      case SVGName.Customers:
        svgIcon = <IconCustomers width={width} height={height} />;
        break;
      case SVGName.Ecom:
        svgIcon = <IconEcom width={width} height={height} />;
        break;
      case SVGName.PaymentServices:
        svgIcon = <IconPaymentServices width={width} height={height} />;
        break;
      case SVGName.ProductManagement:
        svgIcon = <IconMenuProductManagement width={width} height={height} />;
        break;
      case SVGName.RangeNewProducts:
        svgIcon = <IconRangeNewProducts width={width} height={height} />;
        break;
      case SVGName.ReceiveStock:
        svgIcon = <IconReceiveStock width={width} height={height} />;
        break;
      case SVGName.SalesOrders:
        svgIcon = <IconSalesOrders width={width} height={height} />;
        break;
      case SVGName.SalesOrdersManagement:
        svgIcon = <IconSalesOrderManagement width={width} height={height} />;
        break;
      case SVGName.SalesOrdersListing:
        svgIcon = <IconSalesOrderListingRebranding width={width} height={height} />;
        break;
      case SVGName.RequireAttention:
        svgIcon = <IconRequireAttention width={width} height={height} />;
        break;
      case SVGName.Suppliers:
        svgIcon = <IconSuppliers width={width} height={height} />;
        break;
      case SVGName.TradeFinance:
        svgIcon = <IconTradeFinance width={width} height={height} />;
        break;
      case SVGName.WareHouse:
        svgIcon = <IconWareHouse width={width} height={height} />;
        break;
      case SVGName.Quotes:
        svgIcon = <IconQuotes width={width} height={height} />;
        break;
      case SVGName.QuotesListing:
        svgIcon = <IconQuotesListing width={width} height={height} />;
        break;
      case SVGName.PurchaseOrders:
        svgIcon = <PurchaseOrderIcon width={width} height={height} />;
        break;
      case SVGName.PurchaseInvoices:
        svgIcon = <PurchaseInvoiceIcon width={width} height={height} />;
        break;
      case SVGName.Statements:
        svgIcon = <IconStatements width={width} height={height} />;
        break;
      case SVGName.VirtualCreditFacility:
        svgIcon = <VirtualCreditFacility width={width} height={height} />;
        break;
      case SVGName.MerchantPortal:
        svgIcon = <MerchantPortal width={width} height={height} />;
        break;
      case SVGName.PaidInvoices:
        svgIcon = <PaidInvoices width={width} height={height} />;
        break;
      case SVGName.PSBLRequiresAttention:
        svgIcon = <IconRequiresAttention width={width} height={height} />;
        break;
      case SVGName.Catalogue:
        svgIcon = <CatalogueIcon />;
        break;

      default:
        svgIcon = <IconUser width="36px" height="33px" viewBox="0 0 36 33" fill="" />;
        break;
    }
  }

  return svgIcon;
};

export default SVGIcon;
