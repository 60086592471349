import React from 'react';
import {ARDialogTemplate} from '../ARDialogTemplate';
import {Button, Typography} from 'spenda-ui-react';

interface ICancelClaimModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  isSubmitting: boolean;
}

export const CancelClaimModal = (props: ICancelClaimModalProps) => {
  //Props
  const {isOpen, onClose, onConfirm, isSubmitting} = props;

  return (
    <ARDialogTemplate
      dialogProps={{size: 'xs', open: isOpen, className: '!min-w-[460px] !max-w-[460px]'}}
      isFullScreen
      header={<p className=" font-poppins text-xl font-light text-[#333333]">Cancel Claim</p>}
      body={
        <div className="my-12">
          <Typography variant="paragraph" className="text-center font-light text-black-800">
            Once the claim is cancel, you will not be able to edit it any further. Are you sure you want to cancel the
            claim now?
          </Typography>
        </div>
      }
      footer={
        <div className="flex w-full flex-row items-center justify-between">
          <Button
            data-autoid={`btnCancel`}
            className="bg-white"
            onClick={() => onClose()}
            variant="outlined"
            color="primary"
          >
            Cancel
          </Button>
          <Button
            variant="filled"
            color="error"
            data-autoid={`btnConfirm`}
            onClick={() => onConfirm()}
            loading={isSubmitting}
          >
            Confirm
          </Button>
        </div>
      }
      handleClose={() => onClose()}
    />
  );
};
