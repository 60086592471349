import React from 'react';
import {Button, Dialog, DialogBody, DialogFooter, DialogHeader, IconButton, Typography} from 'spenda-ui-react';
import clsx from 'clsx';
import CrossIcon from '../../assets/svg/Cross';

interface IUnlinkDialogProps {
  onClose?: () => void;
  open?: boolean;
  customerId?: number;
  Unlink?: any;
  unLinkOperational?: boolean;
  unLinkDetail?: boolean;
  detailUnlink?: any;
  removeClass?: boolean;
  removeClassDetail?: boolean;
  editDetail?: boolean;
  isLoading?: boolean;
  scope?: 'AR' | 'CM';
}

export const UnlinkDialog: React.FunctionComponent<any> = (props: IUnlinkDialogProps) => {
  const closeDialog = () => {
    if (props.onClose) {
      props.onClose();
    }
  };

  const isInARModule = props.scope === 'AR';

  const handleClick = () => {
    if (
      (props.unLinkOperational && props.removeClass === true) ||
      (props.unLinkOperational && props.removeClass === false)
    ) {
      props?.Unlink?.();
    } else {
      props?.detailUnlink?.();
    }
  };

  return (
    <Dialog
      open={props.open || false}
      handler={() => {}}
      size={props.removeClass || props.removeClassDetail ? 'sm' : 'md'}
    >
      <DialogHeader className="relative justify-center border-b border-[#EAEAEA]">
        <div className="absolute right-2.5 ">
          <IconButton
            variant="text"
            aria-label="close"
            color="gray"
            className={'active:bg-transparent'}
            onClick={closeDialog}
          >
            <div className="h-4 w-4">
              <CrossIcon />
            </div>
          </IconButton>
        </div>
        <Typography className={`${isInARModule ? 'font-poppins' : 'font-serif'} font-medium`} variant="h2">
          {props.removeClass === true || props.removeClassDetail === true
            ? 'Remove Your Account'
            : 'Unlink Your Account'}
        </Typography>
      </DialogHeader>
      <DialogBody className="justify-center px-10 py-8">
        {props.removeClass === true || props.removeClassDetail === true ? (
          <Typography
            className={clsx(
              'text-center text-spenda-primarytext',
              isInARModule ? 'font-poppins' : 'font-serif font-medium',
            )}
          ></Typography>
        ) : (
          <Typography
            variant="h3"
            className={clsx(
              'text-center text-spenda-primarytext',
              isInARModule ? 'font-poppins' : 'font-serif font-medium',
            )}
          >
            Please be aware that any new transactions for this operational customer will not post until another account
            customer is assigned.
            <br />
            <br />
            Any existing transactions will still be linked to the previous account customer.
          </Typography>
        )}

        {props.removeClass === true ? (
          <Typography
            variant="h3"
            className={clsx(
              'text-center text-spenda-primarytext',
              isInARModule ? 'font-poppins' : 'font-serif font-medium',
            )}
          >
            Are you sure you want to remove this customer's class?
          </Typography>
        ) : props.removeClassDetail === true ? (
          <>
            <Typography
              variant="h3"
              className={clsx(
                'text-center text-spenda-primarytext',
                isInARModule ? 'font-poppins' : 'font-serif font-medium',
              )}
            >
              Are you sure you want to remove this customer's class?
            </Typography>
            <Typography
              variant="h3"
              className={clsx(
                'text-center text-spenda-primarytext',
                isInARModule ? 'font-poppins' : 'font-serif font-medium',
              )}
            >
              {' '}
              Any other changes to the operating customer will also be saved.
            </Typography>
          </>
        ) : props.editDetail === true ? (
          <>
            <Typography
              variant="h3"
              className={clsx(
                'text-center text-spenda-primarytext',
                isInARModule ? 'font-poppins' : 'font-serif font-medium',
              )}
            >
              Are you sure you want to unlink this account customer?
            </Typography>
            <Typography
              variant="h3"
              className={clsx(
                'text-center text-spenda-primarytext',
                isInARModule ? 'font-poppins' : 'font-serif font-medium',
              )}
            >
              Any other changes to the operating customer will also be saved.
            </Typography>
          </>
        ) : (
          <Typography
            variant="h3"
            className={clsx(
              'text-center text-spenda-primarytext',
              isInARModule ? 'font-poppins' : 'font-serif font-medium',
            )}
          >
            Are you sure you want to unlink this account customer?
          </Typography>
        )}
      </DialogBody>
      <DialogFooter className="mx-2.5 mb-2.5 justify-between rounded-lg bg-spenda-footerBg p-2.5">
        <Button variant="outlined" className="bg-white" onClick={closeDialog}>
          {isInARModule ? 'Remove' : 'Cancel'}
        </Button>
        <Button
          onClick={handleClick}
          color={
            (!isInARModule && props.unLinkOperational && props.removeClass === true) ||
            (props.unLinkDetail && props.removeClassDetail === true)
              ? 'error'
              : 'primary'
          }
          loading={props.isLoading}
          disabled={props.isLoading}
        >
          {(props.unLinkOperational && props.removeClass === true) ||
          (props.unLinkDetail && props.removeClassDetail === true)
            ? 'Remove'
            : 'Unlink'}
        </Button>
      </DialogFooter>
    </Dialog>
  );
};
