import ARContextActions from './ARContextActions';
import {IARContextState} from './ARContext';

export default (state: IARContextState, action: {type: string; payload: any; options?: any}) => {
  switch (action.type) {
    case ARContextActions.SET_CURRENT_STATEMENT_PERIOD:
      return {
        ...state,
        selectedStatementPeriod: action.payload,
      };
    case ARContextActions.SET_PSBL_STATEMENT:
      return {
        ...state,
        psblStatement: action.payload,
      };
    case ARContextActions.SET_PSBL_BATCH:
      return {
        ...state,
        psblBatch: action.payload,
      };
    case ARContextActions.SET_AR_MODAL_TO_SHOW:
      return {
        ...state,
        arModalToShow: action.payload,
      };
    case ARContextActions.SET_CURRENT_VIEWING_TX:
      return {
        ...state,
        viewingTx: action.payload,
      };
    case ARContextActions.SET_FILTER_CONVERSATION_ID:
      return {
        ...state,
        filterConversationId: action.payload,
      };
    case ARContextActions.SET_EMAIL_BATCH:
      return {
        ...state,
        emailBatch: action.payload,
      };
    default:
      return state;
  }
};
