import {IActionResults} from '../model/ActionResults';
import {
  ISendReminderAlert,
  IAlertRequestRequest,
  IAlertInfo,
  IAlertMessage,
  IDownloadDocumentResponse,
} from '../model/alert/AlertInfo';
import useHttp from '../hooks/useHttp';

export interface IReminderRequest {
  CustomerID?: number;
  DatTypeID: number;
  IsSendAll: boolean;
}

export interface IReminderRequestResponse {
  DaysSinceLastSent: number;
  FirstReminder: boolean;
  LastSentDateTime: string;
  TimeSpanSinceLastSent: string;
  CustomersMissingEmailAddress: number[];
}

const useAlertAPI = () => {
  const {GET, POST, PUT, isLoading} = useHttp();

  const send = (req: Partial<ISendReminderAlert>): Promise<IActionResults<IReminderRequestResponse>> => {
    return POST('alert/sendreminder', req);
  };

  const getReminderRequest = (filter: IReminderRequest): Promise<IActionResults<IReminderRequestResponse>> => {
    return GET(`alert/getReminderRequest`, filter);
  };

  const sendPayInvoiceByLink = (
    req: Partial<ISendReminderAlert>,
  ): Promise<IActionResults<IReminderRequestResponse>> => {
    return POST('alert/sendpayinvoicebylink', req);
  };

  const getPrintOut = (req: Partial<IAlertRequestRequest>): Promise<IAlertInfo> => {
    return PUT('alert/getprintout', req).then((data: IActionResults<IAlertInfo>) => data.Value);
  };

  const sendEmailAlert = async (payload: Partial<IAlertInfo & IAlertMessage>): Promise<boolean> => {
    return POST('alert/send', payload).then((data: IActionResults<IAlertInfo>) => data.IsSuccess);
  };

  const getAlertRequest = async (payload: Partial<IAlertInfo>): Promise<IAlertInfo> => {
    return PUT('alert/getAlertRequest', payload).then((data: IActionResults<IAlertInfo>) => data.Value);
  };

  const downloadDocument = (payload: IDownloadDocumentPayload): Promise<IDownloadDocumentResponse> => {
    return PUT(`Spenda/Alerts/GetDocument`, payload);
  };

  return {
    send,
    getReminderRequest,
    sendPayInvoiceByLink,
    getPrintOut,
    sendEmailAlert,
    getAlertRequest,
    downloadDocument,
    isLoading,
  };
};

export default useAlertAPI;

interface IDownloadDocumentPayload {
  docIDs: number[];
  datTypeID: number;
  alertType: string;
  websiteID: number;
  linkedSupplierId?: number;
  accountCustomerId?: number;
}
