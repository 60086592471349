import React from 'react';

type PlaceholderProps = {} & React.HTMLAttributes<HTMLDivElement>;

const Placeholder = (props: PlaceholderProps) => {
  return (
    <div
      {...props}
      className={`border animate-pulse border-gray-400 bg-gray-300 shadow rounded-md w-full mx-auto ${props.className}`}
    ></div>
  );
};

export default Placeholder;
