import React, {useMemo, useState, useContext, useEffect} from 'react';
import {css} from 'glamor';
import clsx from 'clsx';

import {SelectToggler} from './CommonComponents';
import {PurchaseInvoiceListFilter} from '../../components/purchasing/PurchaseInvoiceListFilter';
import {IPurchaseInvoice, ISupplier} from '../../model/purchase-invoice/purchaseInvoice';
import {
  PurchaseInvoiceRequestedList,
  PurchaseInvoiceViewTypes,
  SupplierViewSortingTypes,
  TimelineCustomRange,
  TimelineCustomRangeDays,
} from '../../model/constants/Constants';
import AppContext from '../../context/app/appContext';
import {PriceFormat} from '../../utils/formatter';
import {checkDueDate} from '../../utils/timelineViewValidator';
import {APInvoiceDetailView} from './APInvoiceDetailView';

import {
  ClickAwayListener,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import APTooltip from '../../components/data-display/APTootip';
import SelectedBillsDetails from '../../assets/svg/accounts-payable/selectedBillsDetails';
import APDisableSelectTogglerIcon from '../../assets/svg/accounts-payable/APDisableSelectTogglerIcon';
import {useHistory} from 'react-router-dom';
import ExpandArrow from '../../assets/svg/accounts-payable/ExpandArrow';
import IconScheduledPayments from '../../assets/svg/IconScheduledPayments';
import ARTooltip from '../../components/data-display/ARTootip';

interface APInvoiceTimelineViewListProps {
  suppliersList: Array<ISupplier>;
  handleBulkInvoices: (supplierId: number) => void;
  selectedInvoices: any;
  totalPayable: number;
  onSelectAll: (isAllSuppliersSelected: boolean) => void;
  purchaseInvoiceViewMode: PurchaseInvoiceViewTypes;
  supplierViewMode: PurchaseInvoiceViewTypes;
  timelineViewMode: PurchaseInvoiceViewTypes;
  togglePurchaseInvoiceViewMode: (type: PurchaseInvoiceViewTypes) => void;
  toggleSupplierViewMode: (type: PurchaseInvoiceViewTypes) => void;
  toggleTimelineViewMode: (type: PurchaseInvoiceViewTypes) => void;
  getPurchaseInvoicesList: (SupplierID: number) => void;
  selectedBucket: PurchaseInvoiceRequestedList;
  timeLineCustomRangeMode?: TimelineCustomRange;
  handleInvoices: (invoicesId: number, supplierId: number) => void;
  handleDebitNotes: (invoicesId: number, supplierId: number) => void;
  toggletimeLineCustomRangeMode: (type: TimelineCustomRange) => void;
  SupplierSortingViewMode: SupplierViewSortingTypes;
  toggleSupplierViewSortingMode: (type: SupplierViewSortingTypes) => void;
  handleTimelineSelect: (column: number, supplierId?: number) => void;
  isSavingBatch?: boolean;
  setIsSavingBatch: React.Dispatch<React.SetStateAction<boolean>>;
  checkColumnSelected: (column: number, supplierData?: ISupplier) => boolean;
  isT2TPhaseTwo?: boolean;
  isScheduledPaymentsV2?: boolean;
  t2TV3?: boolean;
  isConnectedSupplierSelected?: boolean;
  selectedConnectedSupplierId?: number;
  isUnlinkedSuppliersSelected?: boolean;
  showDeselectOption?: boolean;
  handleDeselectClick?: () => void;
}

const useAPInvoiceTimelineViewList = makeStyles({
  invoicesTable: {
    borderRadius: '2px 2px 2px 2px',
    margin: '28px 0 0 0',
    '& table': {
      borderBottom: '0px solid #F8F8F8',
      '& th': {
        color: '#999999',
        fontWeight: 600,
        fontSize: '14px',
        fontFamily: 'Poppins',
        width: '180px',
        padding: '10px!important',
        '& .MuiTableSortLabel-root': {
          paddingRight: '15px',
          '& .MuiTableSortLabel-icon': {
            margin: '0px -6px -15px 0px',
            position: 'absolute',
            right: '0',
            transform: 'rotate(0deg)',
            opacity: '1',
            fontSize: '20px',
            '&.MuiTableSortLabel-iconDirectionAsc': {
              color: '#999',
            },
            '&.MuiTableSortLabel-iconDirectionDesc': {
              color: '#999',
            },
          },
          '&.MuiTableSortLabel-active': {
            color: '#999999',
            '& .MuiTableSortLabel-iconDirectionDesc': {
              color: '#9e9e9e',
            },
            '& .MuiTableSortLabel-iconDirectionAsc': {
              color: '#1c78ad!important',
            },
          },
        },
      },
      '& .MuiTableHead-root': {
        position: 'sticky',
        top: '0',
        zIndex: '9',
        background: '#fff',
        '& .MuiTableCell-head': {
          background: '#ECECEC',
          lineHeight: '18px',
          '&.tableHeadDaysBg': {
            background: 'rgba(28, 120, 173,10%)',
          },
        },
      },
      '& .MuiTableCell-root': {
        borderBottom: '2px solid #f8f8f8',
        padding: '10px',
        position: 'relative',
        boxSizing: 'border-box!important',
        '& .selectedInvoicesNumber': {
          color: '#3C9F78!important',
          fontSize: '10px',
          fontWeight: 500,
          fontFamily: 'poppins',
          lineHeight: '16px',
        },
        '& .supplierDetails': {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          '& .alreadyExistError': {
            color: '#C55D44',
            fontSize: '10px',
          },
        },
        '&.rowColorGreen': {
          color: '#3C9F78',
        },
      },
      '& .MuiTableCell-body': {
        padding: '10px',
        lineHeight: '1.5',
        height: '40px',
        '&:nth-child(odd)': {
          background: '#FAFAFA',
        },
        '&:nth-child(even).bgDarkGrey': {
          background: '#f0f3f5',
        },
        '&:first-child': {
          padding: '6px 6px 7px!important',
        },
        '&:first-child.bgColorBlue': {
          background: '#F5F8FA',
        },
        '& .togglerIcon': {
          width: '14px',
          margin: '0px 10px 0px 0px',
        },
        '&.collapse-cell': {
          padding: '0px!important',
          border: '0px',
        },
      },
      '& .selectedRow': {
        background: '#FAFAFA',
        '& td:nth-child(even)': {
          background: '#eaf2f7!important',
          position: 'relative',
        },
        '& td:nth-child(odd)': {
          background: '#e7f0f4!important',
          position: 'relative',
        },
      },
      '& tr:last-child .MuiTableCell-root': {
        '& tr .MuiTableCell-root': {
          borderBottom: '2px solid #f8f8f8',
        },
        '& tr:last-child .MuiTableCell-root': {
          borderBottom: '2px solid transparent',
        },
      },
      '& .supplierName': {
        width: '142px',
        fontSize: '12px',
      },

      '& tr.d-none': {
        display: 'none!important',
      },
    },
  },
  SelectSupplierTimeline: {
    padding: '0px 15px',
    color: '#1C78AD',
    textDecoration: 'underline',
    fontSize: '14px',
    fontWeight: 600,
    fontFamily: 'poppins',
    lineHeight: '18px',
    cursor: 'pointer',
    '&.rowColorGreen': {
      color: '#3C9F78!important',
    },
  },
  SelectSupplierTimelineDisabled: {
    color: '#1C78AD20!important',
    cursor: 'default',
    '&.bgColorBlue.rowColorGreen': {
      color: '#1C78AD30!important',
    },
    '&.rowColorGreen': {
      color: '#1C78AD30!important',
    },
  },

  selectedSupplierTimeline: {
    color: '#3C9F78',
  },

  SelectFullColumn: {
    '& .MuiTableCell-body': {
      padding: '7px 8px',
      color: '#1C78AD',
      fontSize: '14px',
      fontWeight: 600,
      fontFamily: 'poppins',
      cursor: 'pointer',
      textDecoration: 'underline',
      lineHeight: '18px',
    },
  },

  expandArrow: {
    minWidth: '14px',
    height: '14px',
    border: '1px solid #1579af',
    borderRadius: '3px',
    marginRight: '8px',
    '& svg': {
      transform: 'rotate(180deg)',
    },
    '& svg.rotate-expand-more-icon': {
      transform: 'rotate(0deg)',
    },
  },
  lightGreen: {
    color: '#3C9F7830',
  },
  darkGreen: {
    color: '#3C9F78',
  },
  CreditsSelectSupplierTimeline: {
    padding: '0px 15px',
    color: '#1C78AD',
    fontSize: '14px',
    fontWeight: 600,
    fontFamily: 'poppins',
    lineHeight: '18px',
  },
});

const supplierListBreakdowntable = css({
  padding: '0px',
  margin: '0',
  width: '100%!important',
  '& .MuiTableCell-root': {
    borderBottom: '1px solid #F1F1F1',
    borderLeft: '0px',
    padding: '8px',
  },
  '& .MuiTableCell-body': {
    padding: '7px 8px',
    color: '#707070',
    fontWeight: 500,
    fontFamily: 'poppins',
    fontSize: '14px',
    '&:nth-child(odd)': {
      background: '#FAFAFA',
    },
  },
  '& .supplierName': {
    width: '80px!important',
  },
  '& .bgColorBlue': {
    '& td:nth-child(even)': {
      background: '#f6fafc!important',
    },
    '& td:nth-child(odd)': {
      background: '#f2f6f8!important',
    },
    '& td:nth-child(odd):first-child': {
      background: '#FAFAFA!important',
    },
  },
  '& .invoiceDisable': {
    '& td': {
      color: '#333',
      opacity: '30%',
      '&:first-child': {
        opacity: '100%',
      },
    },
  },
  '&.disabledRow': {
    '&::after': {
      position: 'absolute',
      zIndex: 999,
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      content: "''",
    },
    '& td': {
      color: '#999999!important',
      '& span': {
        color: '#999999!important',
      },
    },
  },
});

export interface IAPInvoiceId {
  transId: number;
  supplierId: number;
}

const listContainer = css({
  maxHeight: 'calc( 100vh - 20.5em )',
  borderRadius: '6px',
  boxShadow: '0px 0px 6px 0px #D3E5EF',
});

const supplierNameCell = css({
  '& img.select-symbol-icon': {
    minWidth: '22px',
  },
});

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<T>(order: Order, orderBy: keyof T) {
  return order === 'desc'
    ? (a: T, b: T) => descendingComparator(a, b, orderBy)
    : (a: T, b: T) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array?.map((el, index) => [el, index] as [T, number]);
  stabilizedThis &&
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
  return stabilizedThis.map(el => el[0]);
}

const mapTimelineFilterToText = (timelineViewMode: TimelineCustomRange | undefined, column: number): string => {
  let res = '';
  const days = TimelineCustomRangeDays[Number(timelineViewMode) - 1]?.split('_') || [];
  switch (timelineViewMode) {
    case TimelineCustomRange.SevenToTwentyEightDays:
      res = `${column === 1 ? 'Up to ' : ''}${days[column]} days`;
      break;
    case TimelineCustomRange.ThirtyToNinetyDays:
      res = `${days[column]} days`;
      break;
    case TimelineCustomRange.TwoToSixMonths:
      res = `${days[column]} months`;
      break;
    case TimelineCustomRange.ThreeToNineMonths:
      res = `${days[column]} months`;
      break;
    default:
      res = `${days[column]} days`;
      break;
  }
  return res;
};

export const APInvoiceTimelineViewList = (props: APInvoiceTimelineViewListProps) => {
  const {
    suppliersList,
    totalPayable,
    onSelectAll,
    handleInvoices,
    handleBulkInvoices,
    purchaseInvoiceViewMode,
    supplierViewMode,
    timelineViewMode,
    selectedInvoices,
    togglePurchaseInvoiceViewMode,
    getPurchaseInvoicesList,
    toggleSupplierViewMode,
    toggleTimelineViewMode,
    timeLineCustomRangeMode,
    toggletimeLineCustomRangeMode,
    SupplierSortingViewMode,
    toggleSupplierViewSortingMode,
    selectedBucket,
    handleTimelineSelect,
    isSavingBatch,
    setIsSavingBatch,
    checkColumnSelected,
    isT2TPhaseTwo,
    isScheduledPaymentsV2,
    t2TV3,
    handleDebitNotes,
    isConnectedSupplierSelected,
    selectedConnectedSupplierId,
    isUnlinkedSuppliersSelected,
    showDeselectOption,
    handleDeselectClick,
  } = props;

  const appCtx = useContext(AppContext);
  const classes = useAPInvoiceTimelineViewList();
  const [isAllSuppliersSelected, setIsAllSuppliersSelected] = useState<boolean>(false);
  const [breakDownSupplierId, setBreakSupplierId] = useState<Number | undefined>();
  const [showInvoice, setShowInvoice] = useState<boolean>(false);
  const [invoiceId, setInvoiceId] = useState<IAPInvoiceId>();
  const [showConnectedSupplierTooltipId, setShowConnectedSupplierTooltipId] = useState<number>();

  useEffect(() => {
    setIsAllSuppliersSelected(getIsAllSuppliersSelected());
  }, [selectedInvoices, suppliersList]);

  const getIsAllSuppliersSelected = () => {
    if (!suppliersList.length) {
      return false;
    }

    if (Object.keys(selectedInvoices).length < suppliersList.length) {
      return false;
    }

    return !Boolean(Object.keys(selectedInvoices).find(key => !selectedInvoices[key]?.allSelected));
  };
  const debitNotesSum = useMemo(
    () => suppliersList.reduce((acc: number, supp: ISupplier) => (supp.ValueDebitNotes || 0) + acc, 0),
    [suppliersList],
  );
  const dueSoonSum = useMemo(
    () => suppliersList.reduce((acc: number, supp: ISupplier) => supp.ValueDueSoonInvoices + acc, 0),
    [suppliersList],
  );
  const firstGroupSum = useMemo(
    () => suppliersList.reduce((acc: number, supp: ISupplier) => supp.ValueFirstGroupInvoices + acc, 0),
    [suppliersList],
  );
  const secondGroupSum = useMemo(
    () => suppliersList.reduce((acc: number, supp: ISupplier) => supp.ValueSecondGroupInvoices + acc, 0),
    [suppliersList],
  );
  const thirdGroupSum = useMemo(
    () => suppliersList.reduce((acc: number, supp: ISupplier) => supp.ValueThirdGroupInvoices + acc, 0),
    [suppliersList],
  );
  const fourthGroupSum = useMemo(
    () => suppliersList.reduce((acc: number, supp: ISupplier) => supp.ValueForthGroupInvoices + acc, 0),
    [suppliersList],
  );
  const utcOffset = useMemo(
    () => appCtx.tenant?.Locations.find(loc => loc.IsDefaultLocation === true)?.UtcOffset || 0,
    [appCtx.tenant],
  );

  const dueSoonAllSelected = checkColumnSelected(0);
  const firstGroupAllSelected = checkColumnSelected(1);
  const secondGroupAllSelected = checkColumnSelected(2);
  const thirdGroupAllSelected = checkColumnSelected(3);
  const fourthGroupAllSelected = checkColumnSelected(4);
  const history = useHistory();

  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof ISupplier>('SupplierName');
  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof ISupplier) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const createSortHandler = (property: keyof ISupplier) => (event: React.MouseEvent<unknown>) => {
    handleRequestSort(event, property);
  };

  return (
    <>
      {showInvoice && (
        <APInvoiceDetailView open={showInvoice} onClose={() => setShowInvoice(false)} apInvoiceId={invoiceId} />
      )}
      <div
        className={`${listContainer} flex min-h-full flex-col overflow-y-auto p-2.5 font-poppins`}
        id="purchase-invoice-list"
      >
        <PurchaseInvoiceListFilter
          selectedInvoiceTotal={totalPayable}
          onSelectAll={onSelectAll}
          isAllSuppliersSelected={isAllSuppliersSelected}
          purchaseInvoiceViewMode={purchaseInvoiceViewMode}
          supplierViewMode={supplierViewMode}
          togglePurchaseInvoiceViewMode={togglePurchaseInvoiceViewMode}
          toggleSupplierViewMode={toggleSupplierViewMode}
          toggleTimelineViewMode={toggleTimelineViewMode}
          timelineViewMode={timelineViewMode}
          timeLineCustomRangeMode={timeLineCustomRangeMode}
          toggletimeLineCustomRangeMode={toggletimeLineCustomRangeMode}
          selectedBucket={selectedBucket}
          toggleSupplierViewSortingMode={toggleSupplierViewSortingMode}
          SupplierSortingViewMode={SupplierSortingViewMode}
          isSavingBatch={isSavingBatch}
          setIsSavingBatch={setIsSavingBatch}
          t2TV3={t2TV3}
          showDeselectOption={showDeselectOption}
          handleDeselectClick={handleDeselectClick}
        />

        <TableContainer className={`${classes.invoicesTable}`}>
          <Table className="invoiceTableCell mb-16" aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell style={{width: '4%'}}></TableCell>
                <TableCell style={{width: '20%'}}>Vendor</TableCell>

                <TableCell style={{width: isT2TPhaseTwo ? '10%' : '13%'}} align="right">
                  <div>
                    <TableSortLabel
                      IconComponent={ArrowDropDownIcon}
                      active={orderBy === 'ValueDueSoonInvoices'}
                      direction={orderBy === 'ValueDueSoonInvoices' ? order : 'asc'}
                      onClick={createSortHandler('ValueDueSoonInvoices')}
                    >
                      Due
                    </TableSortLabel>

                    <div
                      className={`${clsx(classes.SelectSupplierTimeline, {
                        [classes.SelectSupplierTimelineDisabled]: !dueSoonSum,
                      })} ${dueSoonAllSelected ? 'rowColorGreen' : ''} ${
                        t2TV3 && isConnectedSupplierSelected && '!cursor-default !text-[#999999]'
                      }`}
                      onClick={() => {
                        if (t2TV3 && isConnectedSupplierSelected) return;
                        if (dueSoonSum) {
                          if (!isSavingBatch) {
                            setIsSavingBatch(true);
                            handleTimelineSelect(0);
                          }
                        } else return null;
                      }}
                      data-autoid="lnkDueSoon"
                    >
                      <p>{PriceFormat(Number(dueSoonSum.toFixed(2)))}</p>
                    </div>
                  </div>
                </TableCell>
                <TableCell style={{width: isT2TPhaseTwo ? '11%' : '12%'}} align="right" className="tableHeadDaysBg">
                  <div>
                    <TableSortLabel
                      IconComponent={ArrowDropDownIcon}
                      active={orderBy === 'ValueFirstGroupInvoices'}
                      direction={orderBy === 'ValueFirstGroupInvoices' ? order : 'asc'}
                      onClick={createSortHandler('ValueFirstGroupInvoices')}
                    >
                      {mapTimelineFilterToText(timeLineCustomRangeMode, 1)}
                    </TableSortLabel>
                    <div
                      className={`${clsx(classes.SelectSupplierTimeline, {
                        [classes.SelectSupplierTimelineDisabled]: !firstGroupSum,
                      })} ${firstGroupAllSelected ? 'rowColorGreen' : ''} ${
                        t2TV3 && isConnectedSupplierSelected && '!cursor-default !text-[#999999]'
                      }`}
                      onClick={() => {
                        if (t2TV3 && isConnectedSupplierSelected) return;
                        if (firstGroupSum) {
                          if (!isSavingBatch) {
                            setIsSavingBatch(true);
                            handleTimelineSelect(1);
                          }
                        } else return null;
                      }}
                      data-autoid="lnkUpto7Days"
                    >
                      <p>{PriceFormat(Number(firstGroupSum.toFixed(2)))}</p>
                    </div>
                  </div>
                </TableCell>
                <TableCell style={{width: isT2TPhaseTwo ? '11%' : '12%'}} align="right" className="tableHeadDaysBg">
                  <div>
                    <TableSortLabel
                      IconComponent={ArrowDropDownIcon}
                      active={orderBy === 'ValueSecondGroupInvoices'}
                      direction={orderBy === 'ValueSecondGroupInvoices' ? order : 'asc'}
                      onClick={createSortHandler('ValueSecondGroupInvoices')}
                    >
                      {mapTimelineFilterToText(timeLineCustomRangeMode, 2)}
                    </TableSortLabel>
                    <div
                      className={`${clsx(classes.SelectSupplierTimeline, {
                        [classes.SelectSupplierTimelineDisabled]: !secondGroupSum,
                      })} ${secondGroupAllSelected ? 'rowColorGreen' : ''} ${
                        t2TV3 && isConnectedSupplierSelected && '!cursor-default !text-[#999999]'
                      }`}
                      onClick={() => {
                        if (t2TV3 && isConnectedSupplierSelected) return;
                        if (secondGroupSum) {
                          if (!isSavingBatch) {
                            setIsSavingBatch(true);
                            handleTimelineSelect(2);
                          }
                        } else return null;
                      }}
                      data-autoid="lnkUpto14Days"
                    >
                      <p>{PriceFormat(Number(secondGroupSum.toFixed(2)))}</p>
                    </div>
                  </div>
                </TableCell>
                <TableCell style={{width: isT2TPhaseTwo ? '11%' : '12%'}} align="right" className="tableHeadDaysBg">
                  <div>
                    <TableSortLabel
                      IconComponent={ArrowDropDownIcon}
                      active={orderBy === 'ValueForthGroupInvoices'}
                      direction={orderBy === 'ValueForthGroupInvoices' ? order : 'asc'}
                      onClick={createSortHandler('ValueForthGroupInvoices')}
                    >
                      {mapTimelineFilterToText(timeLineCustomRangeMode, 3)}
                    </TableSortLabel>
                    <div
                      className={`${clsx(classes.SelectSupplierTimeline, {
                        [classes.SelectSupplierTimelineDisabled]: !thirdGroupSum,
                      })} ${thirdGroupAllSelected ? 'rowColorGreen' : ''} ${
                        t2TV3 && isConnectedSupplierSelected && '!cursor-default !text-[#999999]'
                      }`}
                      onClick={() => {
                        if (t2TV3 && isConnectedSupplierSelected) return;
                        if (thirdGroupSum) {
                          if (!isSavingBatch) {
                            setIsSavingBatch(true);
                            handleTimelineSelect(3);
                          }
                        } else return null;
                      }}
                      data-autoid="lnkUpto28Days"
                    >
                      <p>{PriceFormat(Number(thirdGroupSum.toFixed(2)))}</p>
                    </div>
                  </div>
                </TableCell>
                <TableCell style={{width: isT2TPhaseTwo ? '11%' : '12%'}} align="right">
                  <div>
                    <TableSortLabel
                      IconComponent={ArrowDropDownIcon}
                      active={orderBy === 'ValueOverdueInvoices'}
                      direction={orderBy === 'ValueOverdueInvoices' ? order : 'asc'}
                      onClick={createSortHandler('ValueOverdueInvoices')}
                    >
                      {`Older than ${mapTimelineFilterToText(timeLineCustomRangeMode, 3)}`}
                    </TableSortLabel>
                    <div
                      className={`${clsx(classes.SelectSupplierTimeline, {
                        [classes.SelectSupplierTimelineDisabled]: !fourthGroupSum,
                      })} ${fourthGroupAllSelected ? 'rowColorGreen' : ''} ${
                        t2TV3 && isConnectedSupplierSelected && '!cursor-default !text-[#999999]'
                      }`}
                      onClick={() => {
                        if (t2TV3 && isConnectedSupplierSelected) return;
                        if (fourthGroupSum) {
                          if (!isSavingBatch) {
                            setIsSavingBatch(true);
                            handleTimelineSelect(4);
                          }
                        } else return null;
                      }}
                      data-autoid="lnkOlder28Days"
                    >
                      <p>{PriceFormat(Number(fourthGroupSum.toFixed(2)))}</p>
                    </div>
                  </div>
                </TableCell>
                {isT2TPhaseTwo && (
                  <TableCell style={{width: '11%'}} align="right">
                    <div>
                      Credit
                      <div
                        className={`font-poppins text-base font-semibold leading-5 text-primary`}
                        data-autoid="lnkCredit"
                      >
                        <p>-{PriceFormat(Number(debitNotesSum.toFixed(2)))}</p>
                      </div>
                    </div>
                  </TableCell>
                )}
                <TableCell style={{width: isT2TPhaseTwo ? '10%' : '12%'}} align="right">
                  Amount
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(suppliersList) &&
                stableSort<ISupplier>(suppliersList, getComparator<ISupplier>(order, orderBy))?.map(
                  (supplier: ISupplier) => {
                    const isAllInvoicesSelected = !t2TV3
                      ? selectedInvoices?.[supplier.SupplierID.toString()]?.purchaseInvoiceIds?.length ===
                        supplier?.PurchaseInvoicesID?.length
                      : selectedInvoices?.[supplier.SupplierID.toString()]?.allSelected;
                    const disableSupplier =
                      t2TV3 &&
                      ((isUnlinkedSuppliersSelected && supplier.IsLinked) ||
                        (isConnectedSupplierSelected && !supplier.IsLinked) ||
                        (supplier.IsLinked &&
                          isConnectedSupplierSelected &&
                          selectedConnectedSupplierId !== supplier.SupplierID));
                    return (
                      <React.Fragment key={supplier.SupplierID}>
                        <APTooltip
                          arrow
                          title={
                            disableSupplier
                              ? isConnectedSupplierSelected && supplier.IsLinked
                                ? "You can't add 2 connected suppliers invoices in the same batch"
                                : "You can't add connected and unlinked suppliers invoices in the same batch"
                              : ''
                          }
                          placement="bottom"
                        >
                          <TableRow className={`${breakDownSupplierId === supplier.SupplierID ? 'selectedRow' : ''}`}>
                            <TableCell className={`${supplierNameCell}`}>
                              {disableSupplier ? (
                                <APDisableSelectTogglerIcon className="min-w-[22px]" />
                              ) : (
                                <div
                                  data-autoid={`chkSupplierList-${supplier.SupplierID}`}
                                  className="flex items-center justify-center"
                                  onClick={() => {
                                    if (!isSavingBatch) {
                                      setIsSavingBatch(true);
                                      getPurchaseInvoicesList(supplier.SupplierID);
                                      setBreakSupplierId(supplier.SupplierID);
                                      handleBulkInvoices(supplier.SupplierID);
                                    }
                                  }}
                                >
                                  <SelectToggler
                                    dataAutoId={`imgSupplierToggle-${supplier.SupplierID}`}
                                    isSelected={isAllInvoicesSelected}
                                    isAccountsPayable={true}
                                  />
                                </div>
                              )}
                            </TableCell>
                            <TableCell
                              style={{width: '20%'}}
                              className={`${supplierNameCell} ${
                                breakDownSupplierId === supplier.SupplierID ? 'bgColorBlue' : ''
                              }`}
                            >
                              <div className={`flex items-center justify-start`}>
                                <span
                                  className={`togglerIcon flex cursor-pointer items-center justify-center ${classes.expandArrow}`}
                                  onClick={() => {
                                    getPurchaseInvoicesList(supplier.SupplierID);
                                    setBreakSupplierId(
                                      supplier.SupplierID === breakDownSupplierId ? undefined : supplier.SupplierID,
                                    );
                                  }}
                                  data-autoid={`imgExpandIcon-${supplier.SupplierID}`}
                                >
                                  <ExpandArrow
                                    className={`${
                                      breakDownSupplierId === supplier.SupplierID ? 'rotate-expand-more-icon' : ''
                                    }`}
                                    data-autoid={`imgSupplierViewDropdown-${supplier.SupplierID}`}
                                  />
                                </span>
                                <div className="supplierDetails max-w-[175px]">
                                  <div className="flex justify-start">
                                    <span className="supplierName !w-auto truncate font-poppins font-medium text-black-800">
                                      {supplier.SupplierName}
                                    </span>
                                    {isT2TPhaseTwo && supplier?.IsLinked && (
                                      <div>
                                        <ClickAwayListener
                                          onClickAway={e => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            setShowConnectedSupplierTooltipId(undefined);
                                          }}
                                        >
                                          <APTooltip
                                            id={'APBillsTooltip'}
                                            arrow
                                            interactive
                                            open={
                                              showConnectedSupplierTooltipId !== undefined &&
                                              supplier.SupplierID === showConnectedSupplierTooltipId
                                            }
                                            placement="top-start"
                                            title={
                                              <React.Fragment>
                                                <p className="w-[220px] px-3 text-center  font-poppins text-[10px] font-medium text-black-800">
                                                  To pay this supplier blocked invoices please visit:
                                                  <span
                                                    className="cursor-pointer text-primary"
                                                    onClick={() => {
                                                      supplier.MarketPlacePath !== ''
                                                        ? history.replace(
                                                            `/${supplier.MarketPlacePath}?redirectedFromAPBills=true`,
                                                          )
                                                        : null;
                                                    }}
                                                  >
                                                    {' '}
                                                    {supplier?.SupplierName}{' '}
                                                  </span>
                                                </p>
                                              </React.Fragment>
                                            }
                                          >
                                            <div
                                              onClick={e => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                e.nativeEvent.stopImmediatePropagation();
                                                setShowConnectedSupplierTooltipId(supplier.SupplierID);
                                              }}
                                            >
                                              <SelectedBillsDetails className="ml-2 cursor-pointer" />
                                            </div>
                                          </APTooltip>
                                        </ClickAwayListener>
                                      </div>
                                    )}
                                  </div>
                                  <p>
                                    {selectedInvoices?.[supplier.SupplierID.toString()]?.purchaseInvoiceIds?.length ? (
                                      <span className="selectedInvoicesNumber">
                                        {selectedInvoices?.[supplier.SupplierID.toString()]?.purchaseInvoiceIds?.length}{' '}
                                        Invoice selected
                                      </span>
                                    ) : null}
                                  </p>
                                  <p>
                                    {selectedInvoices?.[supplier.SupplierID.toString()]?.debitNotesIds?.length ? (
                                      <span className="selectedInvoicesNumber">
                                        {selectedInvoices?.[supplier.SupplierID.toString()]?.debitNotesIds?.length}{' '}
                                        DebitNote selected
                                      </span>
                                    ) : null}
                                  </p>
                                </div>
                              </div>
                            </TableCell>
                            <TableCell
                              align="right"
                              style={{width: isT2TPhaseTwo ? '10%' : '13%'}}
                              className={`${clsx(classes.SelectSupplierTimeline, {
                                [classes.SelectSupplierTimelineDisabled]: !supplier.ValueDueSoonInvoices,
                                bgDarkGrey: breakDownSupplierId === supplier.SupplierID,
                              })} ${checkColumnSelected(0, supplier) ? 'rowColorGreen' : ''}`}
                              onClick={() => {
                                if (supplier.ValueDueSoonInvoices) {
                                  if (!isSavingBatch) {
                                    setIsSavingBatch(true);
                                    handleTimelineSelect(0, supplier.SupplierID);
                                  }
                                } else return null;
                              }}
                              data-autoid={`btnDueSoonSave-${supplier.SupplierID}`}
                            >
                              <p>{PriceFormat(Number(supplier.ValueDueSoonInvoices.toFixed(2)))}</p>
                            </TableCell>
                            <TableCell
                              align="right"
                              style={{width: isT2TPhaseTwo ? '11%' : '12%'}}
                              className={`${clsx(classes.SelectSupplierTimeline, {
                                [classes.SelectSupplierTimelineDisabled]: !supplier.ValueFirstGroupInvoices,
                                bgColorBlue: breakDownSupplierId === supplier.SupplierID,
                              })}  ${checkColumnSelected(1, supplier) ? 'rowColorGreen' : ''} ${
                                disableSupplier && '!cursor-default !text-[#999999]'
                              }`}
                              onClick={() => {
                                if (disableSupplier) return;
                                if (supplier.ValueFirstGroupInvoices) {
                                  if (!isSavingBatch) {
                                    setIsSavingBatch(true);
                                    handleTimelineSelect(1, supplier.SupplierID);
                                  }
                                } else return null;
                              }}
                              data-autoid={`btnUpto7DaysSave-${supplier.SupplierID}`}
                            >
                              <p>{PriceFormat(Number(supplier.ValueFirstGroupInvoices.toFixed(2)))}</p>
                            </TableCell>
                            <TableCell
                              align="right"
                              style={{width: isT2TPhaseTwo ? '11%' : '12%'}}
                              className={`${clsx(classes.SelectSupplierTimeline, {
                                [classes.SelectSupplierTimelineDisabled]: !supplier.ValueSecondGroupInvoices,
                                bgDarkGrey: breakDownSupplierId === supplier.SupplierID,
                              })}  ${checkColumnSelected(2, supplier) ? 'rowColorGreen' : ''} ${
                                disableSupplier && '!cursor-default !text-[#999999]'
                              }`}
                              onClick={() => {
                                if (disableSupplier) return;
                                if (supplier.ValueSecondGroupInvoices) {
                                  if (!isSavingBatch) {
                                    setIsSavingBatch(true);
                                    handleTimelineSelect(2, supplier.SupplierID);
                                  }
                                } else return null;
                              }}
                              data-autoid={`btnUpto14DaysSave-${supplier.SupplierID}`}
                            >
                              <p>{PriceFormat(Number(supplier.ValueSecondGroupInvoices.toFixed(2)))}</p>
                            </TableCell>
                            <TableCell
                              align="right"
                              style={{width: isT2TPhaseTwo ? '11%' : '12%'}}
                              className={`${clsx(classes.SelectSupplierTimeline, {
                                [classes.SelectSupplierTimelineDisabled]: !supplier.ValueThirdGroupInvoices,
                                bgColorBlue: breakDownSupplierId === supplier.SupplierID,
                              })}  ${checkColumnSelected(3, supplier) ? 'rowColorGreen' : ''} ${
                                disableSupplier && '!cursor-default !text-[#999999]'
                              }`}
                              onClick={() => {
                                if (disableSupplier) return;
                                if (supplier.ValueThirdGroupInvoices) {
                                  if (!isSavingBatch) {
                                    setIsSavingBatch(true);
                                    handleTimelineSelect(3, supplier.SupplierID);
                                  }
                                } else return null;
                              }}
                              data-autoid={`btnUpto28DaysSave-${supplier.SupplierID}`}
                            >
                              <p>{PriceFormat(Number(supplier.ValueThirdGroupInvoices.toFixed(2)))}</p>
                            </TableCell>
                            <TableCell
                              align="right"
                              style={{width: isT2TPhaseTwo ? '11%' : '12%'}}
                              className={`${clsx(classes.SelectSupplierTimeline, {
                                [classes.SelectSupplierTimelineDisabled]: !supplier.ValueForthGroupInvoices,
                                bgColorBlue: breakDownSupplierId === supplier.SupplierID,
                              })}  ${checkColumnSelected(4, supplier) ? 'rowColorGreen' : ''} ${
                                disableSupplier && '!cursor-default !text-[#999999]'
                              }`}
                              onClick={() => {
                                if (disableSupplier) return;
                                if (supplier.ValueForthGroupInvoices) {
                                  if (!isSavingBatch) {
                                    setIsSavingBatch(true);
                                    handleTimelineSelect(4, supplier.SupplierID);
                                  }
                                } else return null;
                              }}
                              data-autoid={`btnUpto28DaysSave-${supplier.SupplierID}`}
                            >
                              <p>{PriceFormat(Number(supplier.ValueForthGroupInvoices.toFixed(2)))}</p>
                            </TableCell>
                            {isT2TPhaseTwo && (
                              <TableCell
                                align="right"
                                style={{width: isT2TPhaseTwo ? '11%' : '12%'}}
                                className={`${clsx(classes.CreditsSelectSupplierTimeline, {
                                  bgColorBlue: breakDownSupplierId === supplier.SupplierID,
                                })} ${disableSupplier && '!cursor-default !text-[#999999]'}`}
                                data-autoid={`btnCredits-${supplier.SupplierID}`}
                              >
                                <p>-{PriceFormat(Number((supplier.ValueDebitNotes || 0).toFixed(2)))}</p>
                              </TableCell>
                            )}
                            <TableCell
                              align="right"
                              style={{width: isT2TPhaseTwo ? '10%' : '12%'}}
                              className={`text-gray-800 ${clsx(classes.SelectSupplierTimeline, {
                                [classes.SelectSupplierTimelineDisabled]: !(
                                  supplier.ValueDueSoonInvoices + supplier.ValueOverdueInvoices
                                ),
                                bgColorBlue: breakDownSupplierId === supplier.SupplierID,
                              })}  ${isAllInvoicesSelected ? 'rowColorGreen' : ''} ${
                                disableSupplier && '!cursor-default !text-[#999999]'
                              }`}
                              onClick={() => {
                                if (disableSupplier) return;
                                if (supplier.ValueDueSoonInvoices + supplier.ValueOverdueInvoices) {
                                  if (!isSavingBatch) {
                                    setIsSavingBatch(true);
                                    handleTimelineSelect(-1, supplier.SupplierID);
                                  }
                                } else return null;
                              }}
                              data-autoid={`btnAmount-${supplier.SupplierID}`}
                            >
                              {isT2TPhaseTwo ? (
                                <p>
                                  {PriceFormat(
                                    Number(
                                      (
                                        supplier.ValueDueSoonInvoices +
                                        supplier.ValueOverdueInvoices -
                                        (supplier.ValueDebitNotes || 0)
                                      ).toFixed(2),
                                    ),
                                  )}
                                </p>
                              ) : (
                                <p>
                                  {PriceFormat(
                                    Number((supplier.ValueDueSoonInvoices + supplier.ValueOverdueInvoices).toFixed(2)),
                                  )}
                                </p>
                              )}
                            </TableCell>
                          </TableRow>
                        </APTooltip>
                        {isT2TPhaseTwo &&
                          supplier.DebitNotes?.map((inv: IPurchaseInvoice) => {
                            const _selectedDebitNotes = selectedInvoices?.[supplier.SupplierID.toString()]
                              ?.debitNotesIds
                              ? selectedInvoices[supplier.SupplierID.toString()]?.debitNotesIds
                              : [];
                            return (
                              <APTooltip
                                key={inv.SupplierID}
                                arrow
                                title={
                                  disableSupplier
                                    ? isConnectedSupplierSelected && supplier.IsLinked
                                      ? "You can't add 2 connected suppliers invoices in the same batch"
                                      : "You can't add connected and unlinked suppliers invoices in the same batch"
                                    : ''
                                }
                                placement="bottom"
                              >
                                <TableRow
                                  key={`collapse-${supplier.SupplierID}-${inv.TransID}`}
                                  className={`${breakDownSupplierId === supplier.SupplierID ? 'bgColorBlue' : ''} ${
                                    breakDownSupplierId !== supplier.SupplierID
                                      ? 'd-none'
                                      : `${supplierListBreakdowntable} ${
                                          (inv.LinkedDocumentGUID !== '' &&
                                            inv.LinkedDocumentGUID !== null &&
                                            isT2TPhaseTwo &&
                                            !t2TV3) ||
                                          disableSupplier
                                            ? 'disabledRow relative'
                                            : ''
                                        }`
                                  }`}
                                >
                                  <TableCell
                                    className={breakDownSupplierId === supplier.SupplierID ? 'bgColorBlue' : ''}
                                    style={{padding: '0px', width: '4%'}}
                                  >
                                    <div className="flex items-center justify-center">
                                      {(inv.LinkedDocumentGUID !== '' &&
                                        inv.LinkedDocumentGUID !== null &&
                                        isT2TPhaseTwo &&
                                        !t2TV3) ||
                                      disableSupplier ? (
                                        <APDisableSelectTogglerIcon className="min-w-[22px]" />
                                      ) : (
                                        <div
                                          className="flex items-center justify-start"
                                          onClick={() => {
                                            if (!isSavingBatch) {
                                              setIsSavingBatch(true);
                                              handleDebitNotes(inv.TransID, supplier.SupplierID);
                                            }
                                          }}
                                          data-autoid={`chkInvoiceToggler-${inv.TransID}`}
                                        >
                                          <SelectToggler
                                            isSelected={_selectedDebitNotes.includes(inv.TransID)}
                                            isAccountsPayable={true}
                                            dataAutoId={`imgInvoiceToggler-${inv.TransID}`}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </TableCell>
                                  <TableCell style={{width: '20%'}}>
                                    <div className="flex cursor-pointer items-center justify-between">
                                      <span
                                        className="font-poppins text-xs font-semibold text-primary underline"
                                        data-autoid={`lnkRefNumber-${inv.TransID}`}
                                      >
                                        {`${inv.RefNumber}${
                                          inv.LinkedDocumentGUID && inv.LinkedDocumentGUID !== '' ? '(DebitNote)' : ''
                                        }`}
                                      </span>
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    align="right"
                                    style={{width: '13%'}}
                                    className={breakDownSupplierId === supplier.SupplierID ? 'bgDarkGrey' : ''}
                                  >
                                    <span className={`font-poppins text-xs font-medium`}>-</span>
                                  </TableCell>
                                  <TableCell
                                    align="right"
                                    style={{width: '12%'}}
                                    className={breakDownSupplierId === supplier.SupplierID ? 'bgColorBlue' : ''}
                                  >
                                    <span className={`font-poppins text-xs font-medium`}>-</span>
                                  </TableCell>
                                  <TableCell
                                    align="right"
                                    style={{width: '12%'}}
                                    className={breakDownSupplierId === supplier.SupplierID ? 'bgDarkGrey' : ''}
                                  >
                                    <span className={`font-poppins text-xs font-medium`}>-</span>
                                  </TableCell>
                                  <TableCell
                                    align="right"
                                    style={{width: '12%'}}
                                    className={breakDownSupplierId === supplier.SupplierID ? 'bgColorBlue' : ''}
                                  >
                                    <span className={`font-poppins text-xs font-medium`}>-</span>
                                  </TableCell>
                                  <TableCell
                                    align="right"
                                    style={{width: '12%'}}
                                    className={breakDownSupplierId === supplier.SupplierID ? 'bgDarkGrey' : ''}
                                  >
                                    <span className={`font-poppins text-xs font-medium`}>-</span>
                                  </TableCell>
                                  <TableCell
                                    align="right"
                                    style={{width: '12%'}}
                                    className={breakDownSupplierId === supplier.SupplierID ? 'bgColorBlue' : ''}
                                  >
                                    <span className={`font-poppins text-xs font-medium`}>
                                      -{PriceFormat(inv.Balance)}
                                    </span>
                                  </TableCell>
                                  <TableCell
                                    align="right"
                                    style={{width: '12%'}}
                                    className={breakDownSupplierId === supplier.SupplierID ? 'bgDarkGrey' : ''}
                                  >
                                    <span className={`font-poppins text-xs font-medium`}>
                                      -{PriceFormat(inv.Balance)}
                                    </span>
                                  </TableCell>
                                </TableRow>
                              </APTooltip>
                            );
                          })}
                        {supplier.PurchaseInvoices?.map((inv: IPurchaseInvoice) => {
                          const _selectedInvoices = selectedInvoices?.[supplier.SupplierID.toString()]
                            ?.purchaseInvoiceIds
                            ? selectedInvoices[supplier.SupplierID.toString()]?.purchaseInvoiceIds
                            : [];
                          return (
                            <APTooltip
                              key={inv.SupplierID}
                              arrow
                              title={
                                disableSupplier
                                  ? isConnectedSupplierSelected && supplier.IsLinked
                                    ? "You can't add 2 connected suppliers invoices in the same batch"
                                    : "You can't add connected and unlinked suppliers invoices in the same batch"
                                  : ''
                              }
                              placement="bottom"
                            >
                              <TableRow
                                key={`collapse-${supplier.SupplierID}-${inv.TransID}`}
                                className={`${breakDownSupplierId === supplier.SupplierID ? 'selectedRow' : ''} ${
                                  breakDownSupplierId !== supplier.SupplierID
                                    ? 'd-none'
                                    : `${supplierListBreakdowntable} ${
                                        (inv.LinkedDocumentGUID !== '' &&
                                          inv.LinkedDocumentGUID !== null &&
                                          isT2TPhaseTwo &&
                                          !t2TV3) ||
                                        disableSupplier
                                          ? 'disabledRow relative'
                                          : ''
                                      }`
                                }`}
                              >
                                <TableCell
                                  className={breakDownSupplierId === supplier.SupplierID ? 'bgColorBlue' : ''}
                                  style={{padding: '0px', width: '4%'}}
                                >
                                  <div className="flex items-center justify-center">
                                    {(inv.LinkedDocumentGUID !== '' &&
                                      inv.LinkedDocumentGUID !== null &&
                                      isT2TPhaseTwo &&
                                      !t2TV3) ||
                                    disableSupplier ? (
                                      <APDisableSelectTogglerIcon className="min-w-[22px]" />
                                    ) : (
                                      <div
                                        className="flex items-center justify-start"
                                        onClick={() => {
                                          if (!isSavingBatch) {
                                            setIsSavingBatch(true);
                                            handleInvoices(inv.TransID, supplier.SupplierID);
                                          }
                                        }}
                                        data-autoid={`chkInvoiceToggler-${inv.TransID}`}
                                      >
                                        <SelectToggler
                                          isSelected={_selectedInvoices.includes(inv.TransID)}
                                          isAccountsPayable={true}
                                          dataAutoId={`imgInvoiceToggler-${inv.TransID}`}
                                        />
                                      </div>
                                    )}
                                  </div>
                                </TableCell>
                                <TableCell style={{width: '20%'}}>
                                  <div className="flex cursor-pointer flex-row items-center justify-between">
                                    <span
                                      className="font-poppins text-xs font-semibold text-primary underline"
                                      onClick={() => {
                                        setInvoiceId({
                                          transId: inv.TransID,
                                          supplierId: inv.SupplierID,
                                        });
                                        setShowInvoice(true);
                                      }}
                                      data-autoid={`lnkRefNumber-${inv.TransID}`}
                                    >
                                      {`${inv.RefNumber}${
                                        inv.LinkedDocumentGUID &&
                                        inv.LinkedDocumentGUID !== '' &&
                                        inv.SupplierName !== inv.SourceSupplierName &&
                                        inv.SupplierName?.trim().length > 1
                                          ? '(' + inv.SupplierName + ')'
                                          : ''
                                      }`}
                                    </span>
                                    {isScheduledPaymentsV2 && false && (
                                      <ARTooltip
                                        arrow
                                        title={
                                          <React.Fragment>
                                            <h4 className="text-center font-poppins text-[10px] font-medium text-spenda-primarytext">
                                              Payment Scheduled
                                            </h4>
                                            <ul className="mt-3">
                                              <li className="flex items-center justify-start gap-2 pb-1">
                                                <p className="whitespace-nowrap font-poppins text-[10px] font-medium text-[#B2B2B2]">
                                                  Payment Date:
                                                </p>
                                                <p
                                                  data-autoid={`lblDateScheduledPayment${inv.TransID}APInvoiceTimelineViewList`}
                                                  className="font-poppins text-[10px] font-semibold text-black-800"
                                                >
                                                  12/10/12
                                                </p>
                                                <p
                                                  data-autoid={`lblAmountScheduledPayment${inv.TransID}APInvoiceTimelineViewList`}
                                                  className="font-poppins text-[10px] font-semibold text-black-800"
                                                >
                                                  $10,000
                                                </p>
                                              </li>
                                            </ul>
                                          </React.Fragment>
                                        }
                                      >
                                        <button className="ml-2 flex h-[42px] w-9 cursor-pointer items-center justify-center bg-[#e6e4f5]">
                                          <IconScheduledPayments />
                                        </button>
                                      </ARTooltip>
                                    )}
                                  </div>
                                </TableCell>
                                <TableCell
                                  align="right"
                                  style={{width: isT2TPhaseTwo ? '10%' : '13%'}}
                                  className={breakDownSupplierId === supplier.SupplierID ? 'bgDarkGrey' : ''}
                                >
                                  <span className={`font-poppins text-xs font-medium`}>
                                    {checkDueDate(utcOffset, timeLineCustomRangeMode, 0, inv)
                                      ? PriceFormat(inv.Balance)
                                      : '-'}
                                  </span>
                                </TableCell>
                                <TableCell
                                  align="right"
                                  style={{width: isT2TPhaseTwo ? '11%' : '12%'}}
                                  className={breakDownSupplierId === supplier.SupplierID ? 'bgColorBlue' : ''}
                                >
                                  <span className={`font-poppins text-xs font-medium`}>
                                    {checkDueDate(utcOffset, timeLineCustomRangeMode, 1, inv)
                                      ? PriceFormat(inv.Balance)
                                      : '-'}
                                  </span>
                                </TableCell>
                                <TableCell
                                  align="right"
                                  style={{width: isT2TPhaseTwo ? '11%' : '12%'}}
                                  className={breakDownSupplierId === supplier.SupplierID ? 'bgDarkGrey' : ''}
                                >
                                  <span className={`font-poppins text-xs font-medium`}>
                                    {checkDueDate(utcOffset, timeLineCustomRangeMode, 2, inv)
                                      ? PriceFormat(inv.Balance)
                                      : '-'}
                                  </span>
                                </TableCell>
                                <TableCell
                                  align="right"
                                  style={{width: isT2TPhaseTwo ? '11%' : '12%'}}
                                  className={breakDownSupplierId === supplier.SupplierID ? 'bgColorBlue' : ''}
                                >
                                  <span className={`font-poppins text-xs font-medium`}>
                                    {checkDueDate(utcOffset, timeLineCustomRangeMode, 3, inv)
                                      ? PriceFormat(inv.Balance)
                                      : '-'}
                                  </span>
                                </TableCell>
                                <TableCell
                                  align="right"
                                  style={{width: isT2TPhaseTwo ? '11%' : '12%'}}
                                  className={breakDownSupplierId === supplier.SupplierID ? 'bgDarkGrey' : ''}
                                >
                                  <span className={`font-poppins text-xs font-medium`}>
                                    {checkDueDate(utcOffset, timeLineCustomRangeMode, 4, inv)
                                      ? PriceFormat(inv.Balance)
                                      : '-'}
                                  </span>
                                </TableCell>
                                {isT2TPhaseTwo && (
                                  <TableCell
                                    align="right"
                                    style={{width: isT2TPhaseTwo ? '11%' : '12%'}}
                                    className={breakDownSupplierId === supplier.SupplierID ? 'bgColorBlue' : ''}
                                  >
                                    <span className={`font-poppins text-xs font-medium`}>-</span>
                                  </TableCell>
                                )}
                                <TableCell
                                  align="right"
                                  style={{width: isT2TPhaseTwo ? '10%' : '12%'}}
                                  className={breakDownSupplierId === supplier.SupplierID ? 'bgDarkGrey' : ''}
                                >
                                  <span className={`font-poppins text-xs font-medium`}>{PriceFormat(inv.Balance)}</span>
                                </TableCell>
                              </TableRow>
                            </APTooltip>
                          );
                        })}
                      </React.Fragment>
                    );
                  },
                )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};
