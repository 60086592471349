import React from 'react';
import {Button} from 'spenda-ui-react';

interface QuotePreviewFooterProps {
  handleContinue: () => void;
  handleSaveClose: () => void;
  handleCancel: () => void;
  disableButton?: boolean;
  isLoading?: boolean;
  hideSaveClose?: boolean;
}

export const QuotePreviewFooter: React.FunctionComponent<QuotePreviewFooterProps> = (
  props: QuotePreviewFooterProps,
) => {
  const {handleCancel, handleContinue, handleSaveClose, disableButton, isLoading, hideSaveClose} = props;
  return (
    <div className="absolute bottom-2.5 left-3 right-3 z-10 h-[60px] !max-w-full justify-end rounded-md bg-[#ECECEC] p-2.5">
      <div className="flex flex-row justify-between">
        <div>
          <Button variant="outlined" className="bg-white" onClick={handleCancel} data-autoid="btnCancel">
            Cancel
          </Button>
        </div>
        <div className={`gap-cols-2 flex grid ${!hideSaveClose && 'grid-cols-2'} flex-row justify-end gap-x-2`}>
          {!hideSaveClose && (
            <Button
              variant="outlined"
              className="bg-white"
              onClick={handleSaveClose}
              loading={isLoading}
              data-autoid="btnSaveClose"
              disabled={disableButton}
            >
              Save & Close
            </Button>
          )}
          <Button
            variant="filled"
            color="primary"
            loading={isLoading}
            onClick={handleContinue}
            data-autoid="btnContinue"
            disabled={disableButton}
          >
            Continue
          </Button>
        </div>
      </div>
    </div>
  );
};
