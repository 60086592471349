import {createContext} from 'react';
import {HiddenContent, PersonalizedContent} from '../../model/theme/Theme';

export interface IThemeContextState {
  rootClassName: string;
  logo?: (props: SVGProps) => JSX.Element;
  content: HiddenContent;
  personalizedContent?: PersonalizedContent;
  // colors: IThemeColors | undefined;
}

export interface IThemeContextType extends IThemeContextState {
  // setLogos: (logos: IThemeLogo[]) => void;
  // setColors: (colors: IThemeColors | undefined) => void;
}

const ThemeContext = createContext<IThemeContextType>({
  logo: undefined,
  rootClassName: '',
  content: {},
  personalizedContent: {} as PersonalizedContent,

  // colors: undefined,
  // setLogos: () => undefined,
  // setColors: () => undefined,
});

export default ThemeContext;
