import React from 'react';
import {Button, Dialog, DialogBody, DialogFooter, DialogHeader, Input, Typography} from 'spenda-ui-react';
import {Form, Formik, getIn} from 'formik';
import * as Yup from 'yup';
import {IQuotes} from '../../model/quotes/quotes';
import moment from 'moment';
import useQuotesAPI from '../../services/useQuotesAPI';
import {IServiceJob} from '../../model/service-management/serviceJob';
import {DISCOUNT_MODE} from '../../model/constants/Constants';
import {useAppContext} from '../../context/app/appContext';

interface IQuoteGeneratingDialog {
  serviceJob: IServiceJob;
  open: boolean;
  handleCancel: () => void;
  redirectToQuote: (quotePackageId: number) => void;
}
const validationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
});

export const QuoteGeneratingDialog: React.FunctionComponent<IQuoteGeneratingDialog> = ({
  serviceJob,
  open,
  handleCancel,
  redirectToQuote,
}: IQuoteGeneratingDialog) => {
  const quoteDetails: Partial<IQuotes> = {name: '', description: ''};

  const {user} = useAppContext();
  const {createQuotePackage, createQuote} = useQuotesAPI();

  const handleSubmit = async (values: Partial<IQuotes>) => {
    const quotePackageResponse = await createQuotePackage({
      customerID: serviceJob.customerID,
      expiryDate: undefined,
      isActive: true,
      quoteDate: moment(new Date()).format('YYYY-MM-DD'),
      internalNote: serviceJob.internalNote,
      externalNote: serviceJob.externalNotes,
      expiresInDays: 30,
      assignedToUserID: serviceJob?.repUserID ? +serviceJob.repUserID : user?.UserID,
    });
    const quotePackageId = quotePackageResponse.quotePackageID;
    const payload: IQuotes = {
      quotePackageID: quotePackageId!,
      name: values.name!,
      description: values.description!,
      isActive: true,
      lines: [],
      discount: 0,
      discountMode: DISCOUNT_MODE.NONE,
      shipping: 0,
      serviceJobID: serviceJob.serviceJobID,
      assetID: serviceJob.assetID,
    };
    await createQuote(payload);
    redirectToQuote(quotePackageId!);
  };
  return (
    <Dialog
      open={open}
      handler={() => {}}
      className={`!w-[574px] !min-w-[538px]`}
      data-autoid={'dlgCreateQuotePackage'}
    >
      <DialogHeader className="justify-center border-b border-[#ccc] text-center text-xl font-light text-black-900">
        Generating quote
      </DialogHeader>
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={quoteDetails}
        onSubmit={handleSubmit}
      >
        {({values, setFieldValue, touched, errors}) => (
          <Form>
            <DialogBody className="flex flex-col items-center justify-center">
              <Typography
                variant="small"
                className="flex w-full max-w-[290px] items-center justify-center pt-2 text-center font-normal text-black-800"
              >
                Please input a quote name and quote description so the quote will be saved as a draft.
              </Typography>
              <div className="mr-2 py-12">
                <div className="mb-3 flex flex-col items-center justify-center">
                  <div className="flex justify-between">
                    <Input
                      containerProps={{className: 'min-w-[164px] w-[164px] mr-2'}}
                      label="Quote name"
                      value={values.name}
                      onChange={e => setFieldValue('name', e.target.value)}
                      helperText={getIn(touched, 'name') && getIn(errors, 'name')}
                      error={getIn(touched, 'name') && Boolean(getIn(errors, 'name'))}
                    />
                    <Input
                      containerProps={{className: 'min-w-[290px]'}}
                      label="Short description"
                      value={values.description}
                      onChange={e => setFieldValue('description', e.target.value)}
                      helperText={getIn(touched, 'description') && getIn(errors, 'description')}
                      error={getIn(touched, 'description') && Boolean(getIn(errors, 'description'))}
                    />
                  </div>
                </div>
              </div>
            </DialogBody>
            <DialogFooter className="p-2.5">
              <div className="flex w-full items-center justify-between rounded-lg bg-[#f1f1f1] p-2.5">
                <div>
                  <Button
                    variant="outlined"
                    onClick={() => handleCancel()}
                    className="bg-white"
                    data-autoid="btnCancelQuote"
                  >
                    Cancel
                  </Button>
                </div>
                <div className="flex gap-3">
                  <Button variant="filled" color="primary" type="submit" data-autoid="btnConfirmQuote">
                    Generate Quote
                  </Button>
                </div>
              </div>
            </DialogFooter>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
