import React, {useRef} from 'react';
import {useState, useEffect} from 'react';
import {SDialog} from '../../../components/modals/modalSpendaMeterialUI';
import {useContext} from 'react';
import {ICreditCardT, IPaymentMethodTypes, ITenantSupplierPaymentMethod} from '../../../model/payment/PaymentMethod';
import {IInvoice} from '../../../model/invoice/Invoice';
import {PaymentWidgetScope} from '../../../model/constants/Constants';
import {MerchantSurcharge, IMerchantProcessingFee} from '../../../model/payment/PaymentT';
import {
  IAccountResponse,
  INewCreditCardAccountResponse,
  UpdateAccountRequest,
} from '../../../services/useSpendaPaymentServicesAPI';
import {makeStyles, useMediaQuery} from '@material-ui/core';
import {PaymentWidgetStepHome} from './PaymentWidgetStepHome';
import {PaymentWidgetStepAddCreditCard} from './PaymentWidgetStepAddCreditCard';
import {PaymentWidgetStepVerifyCreditCard} from './PaymentWidgetStepVerifyCreditCard';
import {IActionResults, IPagedActionResults} from '../../../model/ActionResults';
import {ISupplierPaymentResponse} from '../../../model/payment/SupplierPaymentResponse';
import {PaymentWidgetStepAddBankAccount} from './PaymentWidgetStepAddBankAccount';
import {PaymentWidgetStepCreditNotes} from './PaymentWidgetStepCreditNotes';

// For Credit notes allocation
import {IAppliedCredit, ICreditClaims} from '../../../model/credit-notes/CreditClaims';
import {SelectedCreditNotes} from '../../../model/credit-notes/SelectedCreditNotes';
import {
  IConnectedSupplierStatementSummaries,
  IConnectedSupplierStatementSummary,
} from '../../../model/supplier/SupplierTransaction';
import {ISearchSupplierStatementsFilter} from '../../../model/search-filters/SearchSupplierStatementsFilter';
import {ISupplierMarket} from '../../../model/SupplierMarket';
import {IConnectedSupplier} from '../../../model/supplier/ConnectedSupplier';
import {PaymentWidgetStepUpdatePaymentDetails} from './PaymentWidgetStepUpdatePaymentDetails';
import {PaymentWidgetStepInvigoCreditInfo} from './PaymentWidgetStepInvigoCreditInfo';
import {PaymentWidgetStepSelectPaymentMethod} from './PaymentWidgetStepSelectPaymentMethod';
import {IGetSurchargeProps} from '../../../model/payment-widget/PaymentWidget';
import {PaymentWidgetStepSetPaymentAuthorisationRules} from './PaymentWidgetStepSetPaymentAuthorisationRules';
import {
  AuthenticateCodeRequest,
  PaymentAuthorisationResponse,
  AuthorisationRulesRequest,
  AuthorisationRulesResponse,
  PaymentAuthoriseRequest,
  PaymentAuthoriseResponse,
  RejectAuthorisationPayload,
  PaymentAuthorisationDetails,
} from '../../../model/payment/PaymentAuthority';
import {PaymentWidgetStepAuthorisePayment} from './PaymentWidgetStepAuthorisePayment';
import {IPaymentBatch} from '../../../model/payment-batch/PaymentBatch';
import {PaymentWidgetStepApprovePaymentAuthorisationRules} from './PaymentWidgetStepApproveAuthorisationRules';
import {PaymentWidgetStepCreatePrepayment} from './PaymentWidgetStepCreatePrepayment';
import {ITenantUserDetails} from '../../../model/Tenant';
import {IARBatchResponse, ISetPaymentAccountGUIDReq} from '../../../model/accounts-receivable/batch';
import {scrollToBottom} from '../../../utils/scroll';
import {PaymentWidgetStepBatchPaymentScheduled} from './PaymentWidgetStepBatchPaymentScheduled';
import moment from 'moment';
import {IPsblBatch} from '../../../model/accounts-receivable/AccountsReceivable';
import {PaymentWidgetStepAddAirPlus} from './PaymentWidgetStepAddAirPlus';
import {PaymentWidgetStepAirPlusDBI} from './PaymentWidgetStepAirPlusDBI';
import {IAirPlusDbiData, IDbiReply} from '../../../model/payment/AirPlusDBI';

export interface ISubmitPayment {
  pm: ITenantSupplierPaymentMethod;
  merchantSurcharge: MerchantSurcharge | IMerchantProcessingFee | undefined;
  temporalCard?: ICreditCardT;
  isCreditNotesAppliedGreaterThanInvoiceTotal?: boolean;
  creditStatementSummary?: IAppliedCredit | undefined;
  isLoyaltyPointsToggleOn?: boolean;
  authorisationToken?: any;
  scheduledPaymentDate?: moment.Moment;
  airPlusDbiData?: IAirPlusDbiData | null;
}

export interface IDefaultDbiDetails {
  AU: string;
  IK: string;
}

export type PaymentWidgetContextProps = {
  bpspAccountDetails: IAccountResponse | undefined;
  cardToEdit?: ITenantSupplierPaymentMethod;
  isRefreshPaymentMethods: boolean;
  invoicesToPay?: Partial<IInvoice>[];
  merchantSurcharge: MerchantSurcharge | IMerchantProcessingFee;
  code?: string;
  step: PaymentWidgetPaymentSteps;
  storedPaymentOptions: ITenantSupplierPaymentMethod[];
  temporalCreditCards: ICreditCardT[];
  selectedPaymentMethod: ITenantSupplierPaymentMethod;
  userEmailAddress?: string;
  userID?: number;
  tenantName?: string;
  isLinkedToLendingProvider?: boolean;
  isAccountsPayable?: boolean;
  isAPOnboardingV1?: boolean;
  creditsToApply: Partial<SelectedCreditNotes>[];
  creditStatementSummary: IAppliedCredit | undefined;
  totalPayableAmount?: number;
  widgetScope?: PaymentWidgetScope;
  widgetStep?: PaymentWidgetPaymentSteps;
  isPersistSelectedPaymentMethod?: boolean;
  widgetManageMethods73098?: boolean;
  paymentMethodToEdit?: ITenantSupplierPaymentMethod;
  paymentMethodToVerify?: IAccountResponse;
  paymentAuthorisationDetails?: PaymentAuthorisationDetails;
  paymentAuthRules?: AuthorisationRulesResponse;
  nonInvoicePaymentAmount?: any;
  paymentDescription?: string;
  tenantUserDetails?: ITenantUserDetails;
  creditAndClaimsList?: Partial<SelectedCreditNotes>[];
  pwStepData: IPwStepData;
  airPlusDbiData: IAirPlusDbiData | null;
  defaultDbiDetails?: IDefaultDbiDetails;
  setCode?: (value: string) => void;
  fetchSPSAccount?: (accountGUID: string) => Promise<IAccountResponse>;
  getSurcharge: (
    props: IGetSurchargeProps,
  ) => Promise<MerchantSurcharge | IMerchantProcessingFee | undefined> | IMerchantProcessingFee | undefined;
  initiateVerification?: (accountGUID: string) => Promise<IAccountResponse>;
  onRetryVerification?: (accountGUID: string) => Promise<IAccountResponse>;
  loadPaymentOptions: () => Promise<ITenantSupplierPaymentMethod[]>;
  onCancel?: () => void;
  onVerifyCode: (code: string, accountGUID: string) => Promise<IAccountResponse>;
  onLogout?: () => void;
  onPaymentApproved?: () => void;
  onPaymentFailed?: () => void;
  removeCreditCard?: (paymentOption: ITenantSupplierPaymentMethod) => Promise<boolean | undefined>;
  saveCreditCard: (
    card: ICreditCardT,
  ) => Promise<ITenantSupplierPaymentMethod | INewCreditCardAccountResponse | undefined>;
  setBpspAccountDetails: (account: IAccountResponse | undefined) => void;
  setCardToEdit?: (pm: ITenantSupplierPaymentMethod | undefined) => void;
  setIsRefreshPaymentMethods: (value: boolean) => void;
  setMerchantSurcharge?: (surcharge?: MerchantSurcharge | IMerchantProcessingFee) => void;
  setStep: (step: PaymentWidgetPaymentSteps) => void;
  setStoredPaymentOptions: (values: ITenantSupplierPaymentMethod[]) => void;
  setTemporalCreditCards: (values: ICreditCardT[]) => void;
  setSelectedPaymentMethod: (pm: ITenantSupplierPaymentMethod) => void;
  submitPayment?: (submitPaymentPayload: ISubmitPayment) => Promise<IActionResults<ISupplierPaymentResponse>>;
  updateAccountDetails?: (accountGUID: string, req: UpdateAccountRequest) => Promise<any>;
  updatePaymentMethodDetails?: (supplierPaymentOptionID: string, req: UpdateAccountRequest) => Promise<any>;
  getCreditClaimsAPI?: (supplierId: number) => Promise<ICreditClaims[]>;
  getTotalPaymentAndCreditToApplyValues?: (
    claim: Partial<SelectedCreditNotes>[],
    invoices: IConnectedSupplierStatementSummary[] | Partial<IInvoice>[],
    credits: Partial<SelectedCreditNotes>[],
  ) => Promise<IAppliedCredit>;
  getSupplierStatementSummary?: (
    filter: Partial<ISearchSupplierStatementsFilter>,
  ) => Promise<IPagedActionResults<IConnectedSupplierStatementSummaries>>;
  marketplaceSupplier?: ISupplierMarket | undefined;
  suppliers?: IConnectedSupplier[];
  setCreditsToApply?: (credit: Partial<SelectedCreditNotes>[]) => void;
  setCreditStatementSummary?: (credit: IAppliedCredit | undefined) => void;
  checkPaymentStatus?: (
    invoicePaymentAllocationID: number,
    supplierID?: number,
  ) => Promise<IActionResults<ISupplierPaymentResponse>>;
  checkRepaymentStatus?: (
    repaymentGUID: string,
    facilityGUID?: string,
  ) => Promise<IActionResults<ISupplierPaymentResponse>>;
  isBPSP?: boolean;
  onDone?: () => void;
  setIsPersistSelectedPaymentMethod?: (value: boolean) => void;
  isLoyaltyPointsToggleOn?: boolean;
  setIsLoyaltyPointsToggleOn?: (isLoyaltyPointsToggleOn: boolean) => void;
  featLoyaltyPoints?: boolean;
  availablePaymentOptions?: string[];
  availablePaymentMethodTypes?: IPaymentMethodTypes[];
  paymentAuth72488?: boolean;
  getPaymentMethodAuthorisationRules?: (paymentAccountGUID: string) => Promise<AuthorisationRulesResponse>;
  savePaymentAuthorisationRules?: (
    payload: AuthorisationRulesRequest,
    paymentAccountGUID: string,
  ) => Promise<undefined>;
  authorisePayment?: (payload: PaymentAuthoriseRequest) => Promise<PaymentAuthoriseResponse>;
  approvePaymentAuthorisation?: (
    payload: AuthenticateCodeRequest,
    authorisationID: number,
  ) => Promise<PaymentAuthorisationResponse>;
  getPaymentMethodAuthorisationDetails?: (authorisationID: number) => Promise<PaymentAuthorisationResponse>;
  rejectPaymentAuthorisation?: (
    payload: RejectAuthorisationPayload,
    authorisationID: number,
  ) => Promise<PaymentAuthorisationResponse>;
  resetAuthenticationCode?: (authorisationID: number) => Promise<PaymentAuthorisationResponse>;
  updatePaymentBatch?: (
    paymentAccountGUID: string,
    merchantProcessingFee: IMerchantProcessingFee,
    selectedPaymentMethod?: ITenantSupplierPaymentMethod,
  ) => Promise<IPaymentBatch | undefined>;
  setPaymentAuthorisationDetails?: (a: PaymentAuthorisationDetails) => void;
  setPaymentAuthorisationRules: (a: AuthorisationRulesResponse) => void;
  setNonInvoicePaymentAmount?: (nonInvoicePaymentAmount: any) => void;
  setPaymentDescription?: (paymentDescription: string) => void;
  setCreditAndClaimsList?: (creditAndClaims: Partial<SelectedCreditNotes>[]) => void;
  capricornDemo77857?: boolean;
  selectedBatchDetails?: IPsblBatch;
  authorisationId?: number;
  setBatchPaymentGuid?: (payload: ISetPaymentAccountGUIDReq, isUpdateResponse?: boolean) => Promise<IARBatchResponse>;
  retryPSBLBatch?: (invoicePaymentAllocationID: number) => Promise<void>;
  scheduledPaymentDate: moment.Moment | undefined;
  setScheduledPaymentDate: (date: moment.Moment | undefined) => void;
  scheduledPayments83107?: boolean;
  scheduledPaymentsV2?: boolean;
  airplus79131?: boolean;
  fees88078?: boolean;
  fetchAirPlusDBIConfig?: (accountGUI: string) => Promise<IDbiReply>;
  setPwStepsData: (data: IPwStepData | undefined) => void;
  setAirPlusDbiData: (data: IAirPlusDbiData | null) => void;
};

export const PaymentWidgetContext = React.createContext<Partial<PaymentWidgetContextProps>>({});

export type IPwStepData = {
  lastStep?: PaymentWidgetPaymentSteps | undefined;
  authToken?: string | undefined;
};

interface IPaymentWidgetModalProps extends IPaymentWidgetProps {
  onClose: () => void;
  open: boolean;
  isShowWithoutModal?: boolean;
  isAccountsPayable?: boolean;
}

export const PaymentWidgetModal = (props: IPaymentWidgetModalProps) => {
  const useStyles = makeStyles({
    root: {
      overflowY: 'auto',
      height: 'inherit',
      '& .MuiBackdrop-root': {
        background: props.isAccountsPayable && 'rgba(0, 0, 0, 0.4)',
        backdropFilter: props.isAccountsPayable && 'blur(4px)',
        '& -webkit-backdrop-filter': props.isAccountsPayable && 'blur(4px)',
      },
      '& .MuiDialog-paper': {
        borderRadius: '10px !important',
        overflow: 'hidden',
      },
    },
  });

  const classes = useStyles();
  return (
    <>
      {props.isShowWithoutModal ? (
        <PaymentWidget {...props} onCancel={props.onClose} />
      ) : (
        <SDialog
          className={classes.root}
          open={props.open}
          onClose={props.onClose}
          hideBackdrop={[PaymentWidgetScope.PREPAYMENT, PaymentWidgetScope.AUHTORISE_PAYMENT].includes(
            props?.widgetScope!,
          )}
        >
          <PaymentWidget {...props} onCancel={props.onClose} />
        </SDialog>
      )}
    </>
  );
};

interface IPaymentWidgetProps {
  code?: string;
  invoicesToPay?: Partial<IInvoice>[];
  // isActiveInvigo?: boolean;
  userEmailAddress?: string;
  userID?: number;
  tenantName?: string;
  isLinkedToLendingProvider?: boolean;
  totalPayableAmount?: number;
  widgetScope?: PaymentWidgetScope;
  widgetStep?: PaymentWidgetPaymentSteps;
  widgetManageMethods73098?: boolean;
  paymentMethodToEdit?: ITenantSupplierPaymentMethod;
  bpspCardDetails?: IAccountResponse;
  paymentMethodToVerify?: IAccountResponse;
  defaultDbiDetails?: IDefaultDbiDetails;
  setCode?: (value: string) => void;
  getSurcharge?: (
    props: IGetSurchargeProps,
  ) => Promise<MerchantSurcharge | IMerchantProcessingFee | undefined> | IMerchantProcessingFee | undefined;
  initiateVerification?: (accountGUID: string) => Promise<IAccountResponse>;
  onRetryVerification?: (accountGUID: string) => Promise<IAccountResponse>;
  loadPaymentOptions?: () => Promise<ITenantSupplierPaymentMethod[]>;
  removeCreditCard?: (paymentOption: ITenantSupplierPaymentMethod) => Promise<boolean | undefined>;
  saveCreditCard?: (
    card: ICreditCardT,
  ) => Promise<ITenantSupplierPaymentMethod | INewCreditCardAccountResponse | undefined>;
  // setActiveInvigo?: (value: boolean) => void;
  togglePayMode?: () => void;
  onPaymentApproved?: () => void;
  onPaymentFailed?: () => void;
  submitPayment?: (submitPaymentPayload: ISubmitPayment) => Promise<IActionResults<ISupplierPaymentResponse>>;
  onCancel?: () => void;
  onLogout?: () => void;
  fetchSPSAccount?: (accountGUID: string) => Promise<IAccountResponse>;
  onVerifyCode?: (code: string, accountGUID: string) => Promise<IAccountResponse>;
  updateAccountDetails?: (accountGUID: string, req: UpdateAccountRequest) => Promise<any>;
  updatePaymentMethodDetails?: (supplierPaymentOptionID: string, req: UpdateAccountRequest) => Promise<any>;
  getCreditClaimsAPI?: (supplierId: number) => Promise<ICreditClaims[]>;
  getTotalPaymentAndCreditToApplyValues?: (
    claim: Partial<SelectedCreditNotes>[],
    invoices: IConnectedSupplierStatementSummary[] | Partial<IInvoice>[],
    credits: Partial<SelectedCreditNotes>[],
  ) => Promise<IAppliedCredit>;
  getSupplierStatementSummary?: (
    filter: Partial<ISearchSupplierStatementsFilter>,
  ) => Promise<IPagedActionResults<IConnectedSupplierStatementSummaries>>;
  marketplaceSupplier?: ISupplierMarket | undefined;
  suppliers?: IConnectedSupplier[];
  checkPaymentStatus?: (
    invoicePaymentAllocationID: number,
    supplierID?: number,
  ) => Promise<IActionResults<ISupplierPaymentResponse>>;
  checkRepaymentStatus?: (
    repaymentGUID: string,
    facilityGUID?: string,
  ) => Promise<IActionResults<ISupplierPaymentResponse>>;
  isBPSP?: boolean;
  onDone?: () => void;
  storedPaymentOptions?: ITenantSupplierPaymentMethod[];
  featLoyaltyPoints?: boolean;
  availablePaymentOptions?: string[];
  isAccountsPayable?: boolean;
  isAPOnboardingV1?: boolean;
  availablePaymentMethodTypes?: IPaymentMethodTypes[];
  paymentAuth72488?: boolean;
  getPaymentMethodAuthorisationRules?: (paymentAccountGUID: string) => Promise<AuthorisationRulesResponse>;
  savePaymentAuthorisationRules?: (
    payload: AuthorisationRulesRequest,
    paymentAccountGUID: string,
  ) => Promise<undefined>;
  authorisePayment?: (payload: PaymentAuthoriseRequest) => Promise<PaymentAuthoriseResponse>;
  approvePaymentAuthorisation?: (
    payload: AuthenticateCodeRequest,
    authorisationID: number,
  ) => Promise<PaymentAuthorisationResponse>;
  getPaymentMethodAuthorisationDetails?: (authorisationID: number) => Promise<PaymentAuthorisationResponse>;
  rejectPaymentAuthorisation?: (
    payload: RejectAuthorisationPayload,
    authorisationID: number,
  ) => Promise<PaymentAuthorisationResponse>;
  resetAuthenticationCode?: (authorisationID: number) => Promise<PaymentAuthorisationResponse>;
  updatePaymentBatch?: (
    paymentAccountGUID: string,
    merchantProcessingFee: IMerchantProcessingFee,
    selectedPaymentMethod?: ITenantSupplierPaymentMethod,
  ) => Promise<IPaymentBatch | undefined>;
  nonInvoicePaymentAmount?: any;
  paymentDescription?: string;
  setNonInvoicePaymentAmount?: (nonInvoicePaymentAmount: any) => void;
  setPaymentDescription?: (paymentDescription: string) => void;
  tenantUserDetails?: ITenantUserDetails;
  capricornDemo77857?: boolean;
  creditAndClaimsList?: Partial<SelectedCreditNotes>[];
  setCreditAndClaimsList?: (creditAndClaims: Partial<SelectedCreditNotes>[]) => void;
  selectedBatchDetails?: IPsblBatch;
  authorisationId?: number;
  setBatchPaymentGuid?: (payload: ISetPaymentAccountGUIDReq, isUpdateResponse?: boolean) => Promise<IARBatchResponse>;
  retryPSBLBatch?: (invoicePaymentAllocationID: number) => Promise<void>;
  scheduledPayments83107?: boolean;
  scheduledPaymentsV2?: boolean;
  airplus79131?: boolean;
  fees88078?: boolean;
  fetchAirPlusDBIConfig?: (accountGUI: string) => Promise<IDbiReply>;
}

export enum PaymentWidgetPaymentSteps {
  NEW = 1,
  ADD_BANK_ACCOUNT,
  ADD_CREDIT_CARD,
  PROCESSING,
  APPROVED,
  DECLINED,
  SUBMITTED,
  TIMEOUT,
  VERIFY_CREDIT_CARD,
  CREDIT_NOTES,
  CONFIGURE_PAYMENT_OPTIONS,
  INVIGO_CREDIT_INFO,
  SELECT_PAYMENT_METHOD,
  SET_PAYMENT_AUTHORISATION_RULES,
  AUTHORISE_PAYMENT,
  SUBMITTED_TO_AUTHORISER,
  ENTER_SECURITY_CODE,
  APPROVE_AUTHORISATION_RULES,
  CREATE_PREPAYMENT,
  SCHEDULE_PAYMENT,
  ADD_AIRPLUS,
  AIRPLUS_DBI,
}

export const PaymentWidget = (props: IPaymentWidgetProps) => {
  const {paymentMethodToEdit, paymentMethodToVerify, widgetStep, widgetScope, bpspCardDetails} = props;
  useEffect(() => {
    if (!widgetStep) {
      //Redirect to the screen as per the scope
      switch (widgetScope) {
        case PaymentWidgetScope.PREPAYMENT:
          setStep(PaymentWidgetPaymentSteps.CREATE_PREPAYMENT);
          break;

        case PaymentWidgetScope.ADD_AIRPLUS:
          setStep(PaymentWidgetPaymentSteps.ADD_AIRPLUS);
          break;

        case PaymentWidgetScope.ADD_BANK_ACCOUNT:
          setStep(PaymentWidgetPaymentSteps.ADD_BANK_ACCOUNT);
          break;

        case PaymentWidgetScope.ADD_CREDIT_CARD:
          setStep(PaymentWidgetPaymentSteps.ADD_CREDIT_CARD);
          break;

        case PaymentWidgetScope.CONFIGURE_PAYMENT_OPTIONS:
          if (paymentMethodToEdit) setCardToEdit(paymentMethodToEdit);
          setStep(PaymentWidgetPaymentSteps.CONFIGURE_PAYMENT_OPTIONS);
          break;

        case PaymentWidgetScope.VERIFY_PAYMENT_METHOD:
          if (paymentMethodToVerify) setBpspAccountDetails(paymentMethodToVerify);
          setStep(PaymentWidgetPaymentSteps.VERIFY_CREDIT_CARD);
          break;

        case PaymentWidgetScope.AUHTORISE_PAYMENT:
          setStep(PaymentWidgetPaymentSteps.AUTHORISE_PAYMENT);
          break;
      }
    }
  }, []);

  const [step, setStep] = useState<PaymentWidgetPaymentSteps>(widgetStep || PaymentWidgetPaymentSteps.NEW);
  const [code, setCode] = useState<string>('');
  const [storedPaymentOptions, setStoredPaymentOptions] = useState<ITenantSupplierPaymentMethod[]>([]);
  const [merchantSurcharge, setMerchantSurcharge] = useState<MerchantSurcharge | IMerchantProcessingFee>();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<ITenantSupplierPaymentMethod>();
  const [temporalCreditCards, setTemporalCreditCards] = useState<ICreditCardT[]>([]);
  const [bpspAccountDetails, setBpspAccountDetails] = useState<IAccountResponse | undefined>(bpspCardDetails);
  const [cardToEdit, setCardToEdit] = useState<ITenantSupplierPaymentMethod | undefined>(paymentMethodToEdit);
  const [isRefreshPaymentMethods, setIsRefreshPaymentMethods] = useState<boolean>(true);
  const [creditsToApply, setCreditsToApply] = useState<Partial<SelectedCreditNotes>[]>([]);
  const [creditStatementSummary, setCreditStatementSummary] = useState<IAppliedCredit | undefined>();
  const [isLoyaltyPointsToggleOn, setIsLoyaltyPointsToggleOn] = useState<boolean>(false);
  const [airPlusDbiData, setAirPlusDbiData] = useState<IAirPlusDbiData | null>();

  //to use data between different steps of the widget
  const [pwStepData, setPwStepsData] = useState<IPwStepData | undefined>(undefined);

  //Jitu: use this state when you don't want to change selected card and refresh payment options
  const [isPersistSelectedPaymentMethod, setIsPersistSelectedPaymentMethod] = useState<boolean>(false);

  const [paymentAuthorisationDetails, setPaymentAuthorisationDetails] = useState<PaymentAuthorisationDetails>();
  const [paymentAuthRules, setPaymentAuthorisationRules] = useState<AuthorisationRulesResponse>();

  // Schedule payment date
  const [scheduledPaymentDate, setScheduledPaymentDate] = useState<moment.Moment | undefined>(undefined);

  return (
    <PaymentWidgetContext.Provider
      value={{
        code,
        // isActiveInvigo,
        bpspAccountDetails,
        cardToEdit,
        isRefreshPaymentMethods,
        merchantSurcharge,
        step,
        storedPaymentOptions,
        selectedPaymentMethod,
        temporalCreditCards,
        creditsToApply,
        creditStatementSummary,
        isPersistSelectedPaymentMethod,
        isLoyaltyPointsToggleOn,
        paymentAuthorisationDetails,
        paymentAuthRules,
        scheduledPaymentDate,
        pwStepData,
        airPlusDbiData,
        setBpspAccountDetails,
        setCode,
        setCardToEdit,
        setIsRefreshPaymentMethods,
        setMerchantSurcharge,
        setSelectedPaymentMethod,
        setStep,
        setStoredPaymentOptions,
        setTemporalCreditCards,
        setCreditsToApply,
        setCreditStatementSummary,
        setIsPersistSelectedPaymentMethod,
        setIsLoyaltyPointsToggleOn,
        setPaymentAuthorisationDetails,
        setPaymentAuthorisationRules,
        setScheduledPaymentDate,
        setPwStepsData,
        setAirPlusDbiData,
        ...props,
      }}
    >
      <PaymentWidgetWizard />
    </PaymentWidgetContext.Provider>
  );
};

const PaymentWidgetWizard = () => {
  const {step} = useContext(PaymentWidgetContext);

  switch (step) {
    case PaymentWidgetPaymentSteps.ADD_CREDIT_CARD:
      return <PaymentWidgetStepAddCreditCard />;
    case PaymentWidgetPaymentSteps.PROCESSING:
    case PaymentWidgetPaymentSteps.SUBMITTED:
    case PaymentWidgetPaymentSteps.APPROVED:
    case PaymentWidgetPaymentSteps.DECLINED:
    case PaymentWidgetPaymentSteps.NEW:
    case PaymentWidgetPaymentSteps.SUBMITTED_TO_AUTHORISER:
    case PaymentWidgetPaymentSteps.ENTER_SECURITY_CODE:
      return <PaymentWidgetStepHome />;
    case PaymentWidgetPaymentSteps.ADD_BANK_ACCOUNT:
      return <PaymentWidgetStepAddBankAccount />;
    case PaymentWidgetPaymentSteps.ADD_AIRPLUS:
      return <PaymentWidgetStepAddAirPlus />;
    case PaymentWidgetPaymentSteps.VERIFY_CREDIT_CARD:
      return <PaymentWidgetStepVerifyCreditCard />;
    case PaymentWidgetPaymentSteps.CREDIT_NOTES:
      return <PaymentWidgetStepCreditNotes />;
    case PaymentWidgetPaymentSteps.CONFIGURE_PAYMENT_OPTIONS:
      return <PaymentWidgetStepUpdatePaymentDetails />;
    case PaymentWidgetPaymentSteps.INVIGO_CREDIT_INFO:
      return <PaymentWidgetStepInvigoCreditInfo />;
    case PaymentWidgetPaymentSteps.SELECT_PAYMENT_METHOD:
      return <PaymentWidgetStepSelectPaymentMethod />;
    case PaymentWidgetPaymentSteps.SET_PAYMENT_AUTHORISATION_RULES:
      return <PaymentWidgetStepSetPaymentAuthorisationRules />;
    case PaymentWidgetPaymentSteps.AUTHORISE_PAYMENT:
      return <PaymentWidgetStepAuthorisePayment />;
    case PaymentWidgetPaymentSteps.APPROVE_AUTHORISATION_RULES:
      return <PaymentWidgetStepApprovePaymentAuthorisationRules />;
    case PaymentWidgetPaymentSteps.CREATE_PREPAYMENT:
      return <PaymentWidgetStepCreatePrepayment />;
    case PaymentWidgetPaymentSteps.SCHEDULE_PAYMENT:
      return <PaymentWidgetStepBatchPaymentScheduled />;
    case PaymentWidgetPaymentSteps.AIRPLUS_DBI:
      return <PaymentWidgetStepAirPlusDBI />;
    default:
      return <PaymentWidgetStepHome />;
  }
};

export interface IPaymentWidgetStepProps {
  title?: string | React.ReactNode;
  body?: React.ReactNode;
  footer?: React.ReactNode;
  titleType?: string;
}

export const PaymentWidgetStep = (props: IPaymentWidgetStepProps) => {
  const {step, widgetScope} = useContext(PaymentWidgetContext);
  const scroll = scrollToBottom;
  const useStyles = makeStyles({
    footer: {
      backgroundColor: '#EAEAEA',
      padding: '10px 10px 10px',
      borderRadius: '6px',
      margin: '9px',
      marginTop: '18px',
      justifyContent: step === PaymentWidgetPaymentSteps.SUBMITTED_TO_AUTHORISER ? 'center' : 'space-between',
    },
    header: {
      fontSize: '1.35rem',
    },
  });

  const componentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    //We only needed this scroll function in the PSBL view
    if (step === PaymentWidgetPaymentSteps.NEW && widgetScope === PaymentWidgetScope.PSBL) {
      setTimeout(() => {
        scroll(componentRef);
      }, 2000);
    }
  }, [step]);

  const classes = useStyles();
  const matches = useMediaQuery('(max-width:400px)');

  return (
    <section
      ref={componentRef}
      className="relative flex h-[inherit] flex-col justify-between text-center"
      style={{
        width: matches ? '80vw' : '328px',
        overflowY: step === PaymentWidgetPaymentSteps.NEW ? 'auto' : 'unset',
        //As we have to provide automatic scrolling in home step, so we  need to remove min height from there
        minHeight: step === PaymentWidgetPaymentSteps.NEW && widgetScope === PaymentWidgetScope.PSBL ? '' : '584px',
      }}
    >
      {props.title && props.titleType == 'bank' ? (
        <h3 className={`${classes.header} mx-auto mt-6 w-3/5 pt-4 font-poppins font-light`}>{props.title}</h3>
      ) : (
        props.title && (
          <h3
            className={`${classes.header} ${
              step !== PaymentWidgetPaymentSteps.CREDIT_NOTES ? 'mb-3 mt-6' : ''
            } font-poppins font-light `}
          >
            {props.title}
          </h3>
        )
      )}

      <div>
        <main>{props.body}</main>
      </div>
      <div>{props.footer && <footer className={`flex ${classes.footer} z-10 text-base`}>{props.footer}</footer>}</div>
    </section>
  );
};
