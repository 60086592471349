import React, {useState, useEffect, useContext, useCallback} from 'react';
import {matchPath, useLocation} from 'react-router-dom';
import {css} from 'glamor';
import {Layout} from '../../components/layout/Layout';
import {XeroInvoicesDetails} from '../../components/accountsPayableOnboarding/XeroInvoicesDetails';
import {CommonPaymentPermission} from '../../components/accountsPayableOnboarding/CommonPaymentPermission';
import {CommonPaymentMethods} from '../../components/payments/CommonPaymentMethods';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import useSpendaPaymentServicesAPI, {IAccountResponse} from '../../services/useSpendaPaymentServicesAPI';
import {APOnboardingSteps, AccountType, PaymentProviderName, VerificationStatus} from '../../model/constants/Constants';
import {useFeatureFlags} from '../../hooks/useFeatureFlags';
import {AUTH_XERO_ADAPTER_SYNC_DETAILS} from '../../routes/AccountsPayableRoutes';
import {APXeroAdaptorInvoices} from './APXeroAdaptorInvoices';
import {useAdaptorAPI} from '../../services/useAdaptorAPI';
import {IAdaptorSummary} from '../../model/synkd/AdaptorSummary';
import {APPaymentSetup} from '../../components/accountsPayableOnboarding/APPaymentSetup';
import {IntegrationContext} from '../../context/IntegrationContext';
import AppContext from '../../context/app/appContext';
import {useOnboarding} from '../../services/useOnboarding';


const settingTabsWrap = css({
  boxShadow: '0px 0px 6px 0px #D3E5EF',
  borderRadius: '6px',
  '& h2': {
    color: '#BBBBBB',
    fontSize: '14px',
  },
});

const tabsWrapper = css({
  '& .MuiTabs-flexContainer': {
    display: 'block',
    '& .MuiButtonBase-root': {
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontWeight: 500,
      textTransform: 'inherit',
      textAlign: 'left',
      padding: '10px 16px',
      width: '100%',
      display: 'block',
      opacity: 1,
      '&.Mui-selected': {
        background: '#D3E5EF50',
      },
      '& .MuiTab-wrapper': {
        display: 'block',
      },
    },
  },
  '& .MuiTabs-indicator': {
    display: 'none',
  },
});

const rightPanel = css({
  boxShadow: '0px 0px 6px 0px #D3E5EF',
  borderRadius: '6px',
  '& h2.text-spenda-primarytext': {
    fontSize: '22px',
  },
});
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const APSettings = () => {
  const [value, setValue] = useState<Number>(0);
  const {getAdaptorActionsSummary} = useAdaptorAPI();
  const {paymentAuth72488, capricornDemo77857, airplus79131} = useFeatureFlags().tenantOwned();
  const [syncDetails, setSyncDetails] = useState<IAdaptorSummary[]>([]);
  const {getAccounts} = useSpendaPaymentServicesAPI();
  const [accounts, setAccounts] = useState<IAccountResponse[]>();
  const {isAPFinancialAdaptorSetup, financialAdaptor} = useContext(IntegrationContext);
  const {onboardingUpgradeV1} = useFeatureFlags().tenantOwned();
  const {tenantInfo} = useContext(AppContext);
  const {updateOnboardingStep} = useOnboarding();
  const {search} = useLocation();

  useEffect(() => {
    loadSyncDetails();
  }, []);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const loadPaymentMethods = async () => {
    // get payment method list
    const account = await getAccounts(PaymentProviderName.Spenda_Payment_Services);
    
    setAccounts(account);
    onSuccessOfAddPaymentMethod(account);
    // update onboarding step if not completed negative case for onboarding upgrade v1
  };

  const onSuccessOfAddPaymentMethod = useCallback(async (latestAccount:IAccountResponse[]) => {
    if (!!onboardingUpgradeV1 && tenantInfo?.TenantUserDetails.AreAllAPOnboardingStepsCompletedForTenant) return;
      const filteredAccounts = latestAccount.filter(
        account =>
          [AccountType.CREDIT_CARD, AccountType.BANK_TRANSFER, AccountType.VIRTUAL_CARD].includes(account.accountType) &&
          account.verificationStatus === VerificationStatus.VERIFIED
      );
      if (filteredAccounts.length === 1) {
        updateOnboardingStep([
          {
            stepID: APOnboardingSteps.SetupPaymentMethods,
            isCompleted: true,
          },
        ]);
      }
  },[]);

  const handleSaveClearingAccount = useCallback(async () => {
    if (!!onboardingUpgradeV1 && tenantInfo?.TenantUserDetails.AreAllAPOnboardingStepsCompletedForTenant) return;
    await updateOnboardingStep([
      {
        stepID: APOnboardingSteps.ClearingAccount,
        isCompleted: true,
      },
    ]);
  }, []);

  const loadSyncDetails = async () => {
    const response = await getAdaptorActionsSummary();
    setSyncDetails(response);
  };

  useEffect(() => {
    const query = new URLSearchParams(search);
    const tab = query.get('tab');
    if (tab) {
      setValue(Number(tab));
    }
  }, []);

  useEffect(() => {
    if (value === 1) {
      loadPaymentMethods();
    } else if (value === 2) {
      loadSyncDetails();
    }
  }, [value]);

  const settingsTabs = (
    <div className={`w-full py-8 bg-white h-full font-poppins ${settingTabsWrap}`}>
      <h2 className="font-semibold px-4 mb-5">Settings</h2>
      <div className="pb-8">
        <Tabs value={value} onChange={handleChange} aria-label="" className={`${tabsWrapper}`}>
          <Tab label={'Manage permissions'} {...a11yProps(0)} data-autoid="tabManagePermissions" />
          <Tab label="My accounts" {...a11yProps(1)} data-autoid="tabMyAccount" />
          {financialAdaptor?.Name && (
            <Tab
              label={`${financialAdaptor?.Name === 'MyObAccountRightLive' ? 'MyOB' : financialAdaptor?.Name} adaptor`}
              {...a11yProps(1)}
              data-autoid="tabXeroAdaptor"
            />
          )}
          {financialAdaptor?.Name && (
            <Tab label="Clearing account" {...a11yProps(1)} data-autoid="tabClearingAccount" />
          )}
        </Tabs>
      </div>
    </div>
  );

  let apMainPanel = (
    <div className={`font-poppins w-full bg-white h-full px-5 py-6 ${rightPanel}`}>
      <TabPanel value={value} index={0}>
        <h2 className={`text-spenda-primarytext font-light pb-6`}>Manage user permissions</h2>
        <CommonPaymentPermission isAPSettings={true} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <h2 className={`text-spenda-primarytext font-light pb-6`}>My accounts</h2>
        <h4 className={`text-spenda-primarytext font-medium text-base pb-6`}>Payment methods:</h4>
        <CommonPaymentMethods
          loadPaymentMethods={loadPaymentMethods}
          paymentMethods={accounts}
          paymentAuth72488={paymentAuth72488}
          capricornDemo77857={capricornDemo77857}
          airplus79131={airplus79131}
          isAccountsPayable={true}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        {isAPFinancialAdaptorSetup() ? (
          <APXeroAdaptorInvoices syncDetails={syncDetails} refreshSyncDetails={loadSyncDetails} />
        ) : (
          <div className={`h-full mt-40 rounded flex justify-center items-center align-center bg-white relative`}>
            <span className="text-lg font-medium text-spenda-primarytext">
              Contact support to upgrade and integrate financial adaptor
            </span>
          </div>
        )}
      </TabPanel>
      <TabPanel value={value} index={3}>
        {isAPFinancialAdaptorSetup() ? (
          <>
            <h2 className={`text-spenda-primarytext font-light pb-6`}>Setup your payment methods:</h2>
            <APPaymentSetup isAPSettings={true} onNextClick={handleSaveClearingAccount}></APPaymentSetup>
          </>
        ) : (
          <div className={`h-full mt-40 rounded flex justify-center items-center align-center bg-white relative`}>
            <span className="text-lg font-medium text-spenda-primarytext">
              Contact support to upgrade and integrate financial adaptor
            </span>
          </div>
        )}
      </TabPanel>
    </div>
  );

  if (matchPath(location.pathname, AUTH_XERO_ADAPTER_SYNC_DETAILS)) {
    apMainPanel = (
      <div className="px-3 h-full w-full">
        <div className={`font-poppins w-full bg-white h-full p-3 ${rightPanel}`}>
          <XeroInvoicesDetails />{' '}
        </div>
      </div>
    );
  }

  return (
    <>
      <div className={`h-full relative overflow-hidden bg-spenda-newbg`}>
        <Layout
          leftPanel={settingsTabs}
          mainPanel={apMainPanel}
          splitWidthType={matchPath(location.pathname, AUTH_XERO_ADAPTER_SYNC_DETAILS) ? 7 : 6}
        />
      </div>
    </>
  );
};
