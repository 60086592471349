import * as React from 'react';

function DragIcon(props: SVGProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
      <path
        fill="#CCC"
        d="M15.833 8.404l-2.286 2.285a.57.57 0 11-.808-.808l1.31-1.31H8.571v5.478l1.31-1.31a.57.57 0 11.808.808l-2.285 2.286a.572.572 0 01-.809 0l-2.286-2.286a.57.57 0 11.808-.808l1.311 1.31V8.571H1.95l1.31 1.31a.57.57 0 11-.808.808L.167 8.404a.574.574 0 010-.809l2.285-2.286a.57.57 0 11.808.808L1.95 7.428h5.478V1.95l-1.31 1.31a.57.57 0 11-.808-.808L7.596.167a.574.574 0 01.808 0l2.286 2.285a.57.57 0 11-.809.808l-1.31-1.31v5.478h5.478l-1.31-1.31a.57.57 0 11.808-.808l2.286 2.286a.573.573 0 010 .808z"
      />
    </svg>
  );
}

export {DragIcon};
