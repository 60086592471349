import React, {useContext} from 'react';
import {useHistory} from 'react-router-dom';
import {INotification, NotificationContext} from '../../context/NotificationContext';
import {ClaimsAndReturnsStatus, DatTypes, NotificationType} from '../../model/constants/Constants';
import {formattedTimeFromNow} from '../../utils/formatter';
import {IconDocument} from '../../assets/svg/IconDocument';
import './css/Header.css';
import {GreenTick} from '../../assets/svg/GreenTick';
import {DocRejected} from '../../assets/svg/DocRejected';
import {Settings} from '../../assets/svg/accounts-payable/Settings';
import {AUTH_SELLING_AR_CLAIM_REQUESTS_ROUTE} from '../../routes/AuthenticatedRoutes';
import clsx from 'clsx';
import {useClaimsAndReturnHook} from '../../hooks/useARHook';
import AppContext from '../../context/app/appContext';

export const Notifications = () => {
  const {notifications, acknowledgedNotification} = useContext(NotificationContext);
  const history = useHistory();
  const {getFilterNameByStatus} = useClaimsAndReturnHook();
  const {suppliers} = useContext(AppContext);

  const onActionNotification = async (notification: INotification) => {
    if (notification.documentID) {
      await acknowledgedNotification(notification.pushNotificationID);

      switch (notification.datTypeID) {
        case DatTypes.Authorisation:
          history.push(`/payment-authorisation/${notification.documentID}`);
          break;
        case DatTypes.ClaimRequest:
          const {SupplierID, AccountCustomerID, ClaimRequestID, Status, CreditNoteGUID, CreditNoteID} =
            notification?.data;
          const filterBy = getFilterNameByStatus(Status);
          const isInBuyerContext = suppliers?.find(s => s?.SupplierID === SupplierID);
          if (isInBuyerContext) {
            if (Status === ClaimsAndReturnsStatus.Approved && CreditNoteGUID && CreditNoteID)
              history.push(
                `/supplier/${SupplierID}/bills?txDatType=${DatTypes.CreditNote}&txGuid=${CreditNoteGUID}&txId=${CreditNoteID}`
              );
            else
              history.push(
                `/supplier/${SupplierID}/claim-request?claimRequestId=${ClaimRequestID}&filterBy=${filterBy}`
              );
          } else if (AccountCustomerID && [ClaimsAndReturnsStatus.Sent]?.includes(Status)) {
            history.push(
              `${AUTH_SELLING_AR_CLAIM_REQUESTS_ROUTE}?claimRequestId=${ClaimRequestID}&accountCustomerId=${AccountCustomerID}&filterBy=${filterBy}`
            );
          }
          break;
        default:
          console.warn('Notification DatType action not implemented');
          break;
      }
    } else {
      console.warn('Notification does not have a documentID');
    }
  };

  return (
    <ul className="notifications-container">
      {notifications.length ? (
        notifications.map((notification, index) => (
          <Notification
            onClick={onActionNotification}
            key={`notification-${index}`}
            notification={notification}
            dataAutoId={`Notification${index}`}
          />
        ))
      ) : (
        <p className="p-3">There are no notifications</p>
      )}
    </ul>
  );
};

export const Notification = (props: {
  notification: INotification;
  onClick: (notification: INotification) => void;
  dataAutoId: string;
}) => {
  const {notification, onClick, dataAutoId} = props;
  const timeElapsed = notification.createdDateTime_utc ? formattedTimeFromNow(notification.createdDateTime_utc) : '';

  const getNotificationIcon = (notificationType: NotificationType) => {
    switch (notificationType) {
      case NotificationType.Payment:
        return (
          <i className={`w-10 h-10 rounded bg-[#E3EEF5] flex items-center justify-center shrink-0`}>
            <IconDocument />
          </i>
        );

      case NotificationType.AuthorisationStatusApproved:
        return (
          <i className={`w-10 h-10 rounded bg-[#E9F3EF] flex items-center justify-center shrink-0`}>
            <GreenTick />
          </i>
        );
      case NotificationType.AuthorisationStatusRejected:
        return (
          <i className={`w-10 h-10 rounded bg-[#F8E1DC] flex items-center justify-center shrink-0`}>
            <DocRejected />
          </i>
        );
      case NotificationType.PaymentAccountSettings:
        return (
          <i className={`w-10 h-10 rounded bg-[#E3EEF5] flex items-center justify-center shrink-0`}>
            <Settings />
          </i>
        );
      default:
        return (
          <i className={`w-10 h-10 rounded bg-[#E3EEF5] flex items-center justify-center shrink-0`}>
            <IconDocument />
          </i>
        );
    }
  };

  const isDiabledForClaimRequest =
    notification?.datTypeID === DatTypes?.ClaimRequest &&
    !notification?.data?.SupplierID &&
    !notification?.data?.AccountCustomerID;

  return (
    <li
      className={clsx(
        `notification-container relative `,
        {'cursor-not-allowed': isDiabledForClaimRequest},
        {'bg-[#EBF2F7]': !notification.isAcknowledged}
      )}
    >
      <button
        disabled={isDiabledForClaimRequest}
        data-autoid={`btn${dataAutoId}`}
        onClick={() => onClick(notification)}
        className={clsx('flex text-xs items-center', {'cursor-not-allowed': isDiabledForClaimRequest})}
      >
        {getNotificationIcon(notification.data?.notificationType)}
        <div className="pl-2 text-left" dangerouslySetInnerHTML={{__html: notification.content}}></div>
        <p className="text-gray-500 absolute top-4 right-2">{timeElapsed}</p>
      </button>
    </li>
  );
};
