import React, {useState, useContext, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {css} from 'glamor';
import {PaymentBatchPermissionType, PaymentBatchStatusTypes} from '../../model/constants/Constants';
import {IPaymentBatch} from '../../model/payment-batch/PaymentBatch';
import {AUTH_BUYING_AP_BATCH_BATCH_VIEW} from '../../routes/AccountsPayableRoutes';
import {Layout} from '../../components/layout/Layout';
import {FileUploader} from '../../components/inputs/FileUploader';
import LoadingIndicator from '../../components/ui/LoadingIndicator';
import {PaymentBatchDuplicateDialog} from '../../components/dialog/PaymentBatchDuplicateDialog';
import {usePaymentBatchAPI} from '../../services/usePaymentBatchAPI';
import {Toast} from '../../utils/Toast';
import APContext from '../../context/ap-context/APContext';
import AppContext from '../../context/app/appContext';
import {IntegrationContext} from '../../context/IntegrationContext';

const uploadBatchCss = css({
  '& p': {
    fontFamily: 'poppins',
    fontSize: '20px',
    padding: ' 0px 15px',
  },
});

export const APBatchUpload = () => {
  const history = useHistory();
  const {tenantInfo} = useContext(AppContext);
  const {userRoleBatchPermissions} = useContext(APContext);
  const [fileToUpload, setFileToUpload] = useState<File>();
  const {uploadABAFile, getPaymentBatches, isLoading: batchLoader} = usePaymentBatchAPI();
  const isLoading = batchLoader;
  const {isAPFinancialAdaptorSetup} = useContext(IntegrationContext);

  const isAba = (file: any) => {
    if (!file) {
      return false;
    }
    const lastDotIndex = file.name.lastIndexOf('.');
    if (lastDotIndex < 0) {
      return false;
    } // File has no extension
    const fileExtension = file.name.substring(lastDotIndex + 1).toLowerCase();
    return fileExtension === 'aba';
  };

  useEffect(() => {
    if (!isAPFinancialAdaptorSetup()) {
      checkPaymentBatch();
    }
  }, [isAPFinancialAdaptorSetup]);

  const checkPaymentBatch = async (): Promise<boolean> => {
    const {value: batches} = await getPaymentBatches({
      StartRow: 1,
      MaxResults: 100,
      Status: [PaymentBatchStatusTypes.Open],
    });
    const currentBatch = batches?.find(
      batch => batch.createdByUserID === tenantInfo?.TenantUserDetails?.UserID && batch.abaFileID
    );
    if (currentBatch) {
      history.replace(AUTH_BUYING_AP_BATCH_BATCH_VIEW.replace(/:batchID/g, currentBatch.accountsPayableBatchID));
      return false;
    }
    return true;
  };

  const handleFileChange = async (files: File[]) => {
    if (!files || files.length === 0) {
      return;
    }

    const file = files[0];
    if (!isAba(file)) {
      Toast.error('The file format is not supported. ABA format Only');
      return;
    }
    if (file.size > 4194304) {
      Toast.error('The file is too big to be uploaded.');
      return;
    }

    const {value: batches} = await getPaymentBatches({
      StartRow: 1,
      MaxResults: 100,
      Status: Object.values(PaymentBatchStatusTypes).filter(
        type =>
          ![
            PaymentBatchStatusTypes.Completed,
            PaymentBatchStatusTypes.Cancelled,
            PaymentBatchStatusTypes.Failed,
          ].includes(type)
      ),
    });
    if (batches?.find(batch => batch.abaFileName === file.name)) {
      setFileToUpload(file);
      return;
    }
    handleBatchUpload(file);
  };

  const handleBatchUpload = async (file: File) => {
    const formData = new FormData();
    formData.append('UploadFiles', file, file.name);
    const paymentBatch: IPaymentBatch = await uploadABAFile(formData);
    setFileToUpload(undefined);
    if (paymentBatch && paymentBatch.id) {
      history.push(AUTH_BUYING_AP_BATCH_BATCH_VIEW.replace(/:batchID/g, paymentBatch.id));
    } else {
      Toast.error('Something went worng');
    }
  };

  const uploadBatch = (
    <div
      className={`${css({
        boxShadow: '0px 0px 6px 0px #D3E5EF',
        height: 'calc(100% - 1rem)',
      })} h-full mt-3 rounded flex justify-center items-center bg-white relative ${uploadBatchCss}`}
    >
      {userRoleBatchPermissions && userRoleBatchPermissions.includes(PaymentBatchPermissionType.CreateBatch) ? (
        <div {...css({width: '30%'})} data-autoid="drpUpload">
          <FileUploader
            data-autoid="txtUploadABAFile"
            dropzoneText="Upload your .aba file here to create a Batch and make a Payment"
            isAccountsPayable={true}
            uploadedFiles={[]}
            onChange={handleFileChange}
            acceptedFiles={['.aba', '.ABA']}
          ></FileUploader>
        </div>
      ) : (
        <span className="text-lg font-medium text-spenda-primarytext font-poppins">No permission to create batch</span>
      )}
    </div>
  );
  return (
    <div className={`h-full relative overflow-hidden bg-spenda-newbg`}>
      <PaymentBatchDuplicateDialog
        isLoading={isLoading}
        open={Boolean(fileToUpload)}
        fileName={fileToUpload?.name || ''}
        handleClose={() => setFileToUpload(undefined)}
        handleProceedBatchClick={() => fileToUpload && handleBatchUpload(fileToUpload)}
      />
      <Layout
        splitWidthType={4}
        leftPanel={isLoading ? <LoadingIndicator isLoading={true} size="md" color="#1C78AD" /> : uploadBatch}
      />
    </div>
  );
};
