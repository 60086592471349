import React, {useState} from 'react';
import {useTenantInfo} from '../../../hooks/useTenantInfo';
import {useLocation, useParams} from 'react-router-dom';
import {MarketplaceLayout} from '../../purchasing/MarketplaceLayout';
import {WidgetPanel} from '../../../components/AccountsReceivable/WidgetPanel';
import {Layout} from '../../../components/layout/Layout';
import {ClaimsHighLevel} from './ClaimsHighLevel';
import {PSBLClaimsWidget} from '../../../components/AccountsReceivable/claimsAndReturns/PSBLClaimsWidget';
import {ARClaimsWidget} from '../../../components/AccountsReceivable/claimsAndReturns/ARClaimsWidget';
import {PSBLFooter} from '../../../components/AccountsReceivable/PSBLFooter';
import {useFeatureFlags} from '../../../hooks/useFeatureFlags';

export const ClaimsAndReturns = () => {
  // States
  const [refecthClaimListCount, setRefetchClaimListCount] = useState(0);

  //Hooks
  const {isInBuyerContext, isInSupplierContext} = useTenantInfo();
  const {search} = useLocation<{isGoBack: boolean}>();

  // Constant
  const {supplierId} = useParams<{supplierId?: string}>();
  const _supplierId = supplierId ? Number(supplierId) : undefined;

  const query = new URLSearchParams(search);
  const accountCustomerId = Number(query.get('accountCustomerId')) || undefined;
  const claimRequestId = Number(query.get('claimRequestId')) || undefined;

  //Feature Flags
  const {claimAndReturnsV272602: claimAndReturnsV2Supplier} = useFeatureFlags().tenantOwned();
  const {claimAndReturnsV272602: claimAndReturnsV2Buyer} = useFeatureFlags().supplierDriven();
  const claimAndReturnsV272602 = isInBuyerContext
    ? claimAndReturnsV2Buyer && claimAndReturnsV2Supplier
    : claimAndReturnsV2Supplier;

  // Functions
  const handleRefetchClaimList = () => {
    setRefetchClaimListCount(refecthClaimListCount + 1);
  };

  return (
    <>
      {isInBuyerContext && _supplierId && (
        <>
          <MarketplaceLayout
            leftPanel={
              <ClaimsHighLevel
                claimAndReturnsV272602={claimAndReturnsV272602}
                refecthClaimListCount={refecthClaimListCount}
                supplierId={_supplierId}
                claimRequestId={claimRequestId}
              />
            }
            mainPanel={
              <WidgetPanel>
                <PSBLClaimsWidget
                  refecthClaimList={handleRefetchClaimList}
                  supplierId={_supplierId}
                  claimRequestId={claimRequestId}
                />
              </WidgetPanel>
            }
            splitWidthType={'AR'}
          />
          <PSBLFooter />
        </>
      )}
      {isInSupplierContext && (
        <Layout
          leftPanel={
            <ClaimsHighLevel
              claimAndReturnsV272602={claimAndReturnsV272602}
              refecthClaimListCount={refecthClaimListCount}
              claimRequestId={claimRequestId}
            />
          }
          mainPanel={
            <WidgetPanel>
              <ARClaimsWidget
                accountCustomerId={accountCustomerId}
                refecthClaimList={handleRefetchClaimList}
                claimRequestId={claimRequestId}
              />
            </WidgetPanel>
          }
          splitWidthType={'AR'}
        />
      )}
    </>
  );
};
