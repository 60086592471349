import React from 'react';
import {ProductCard} from './ProductCard';
import {useInfiniteScroll} from '../layout/InfiniteScroll';
import {SupplierInventory} from '../../model/inventory/SupplierInventory';
import {css} from 'glamor';
import {useMediaQuery} from '@material-ui/core';
import Theme from '../../Theme';

interface IPropsCatalogue {
  getMoreProducts: () => Promise<void>;
  products: SupplierInventory[];
  isSearching: boolean;
  isShowFavourites?: boolean;
  totalProductsNumber: number;
  toggleCategories: () => void;
  isCollapsed: boolean;
}

export const ProductsCatalogue = (props: IPropsCatalogue) => {
  const {elementRef, handleScroll, scrolling} = useInfiniteScroll(props.getMoreProducts);

  const matches = useMediaQuery(Theme.breakpoints.between('md', 'xl'));

  const productCatalogueStyles = css({
    // display: "flex",
    overflow: 'auto',
    // marginRight: "-0.25rem",
    // "@media(min-width: 1280px)": {
    //   marginRight: "auto"
    // }
  });

  const productGridLayout = css({
    display: 'grid',
    gridTemplateColumns: `repeat(auto-fit, minmax(6.875rem, ${matches ? '9rem' : '8rem'}))`,
    gridColumnGap: '11px',
    gridRowGap: '10px',
    justifyContent: !props.isShowFavourites ? 'center' : 'none',
  });

  return (
    <>
      <div className={`${productCatalogueStyles} w-full`} id="products-catalogue">
        {props.totalProductsNumber ? (
          <div
            ref={elementRef as any}
            onScroll={handleScroll}
            className={`overflow-y-auto w-full h-full justify-start ${productGridLayout}`}
          >
            {!props.isSearching &&
              props.products &&
              props.products.map((product: SupplierInventory, index: number) => (
                <ProductCard key={index} product={product} />
              ))}
            {(props.isSearching || scrolling) && (
              <>
                {[1, 2, 3, 4, 5, 6].map(i => (
                  <ProductCard key={`skeleton-${i}`} product={{IsLoading: true}} />
                ))}
              </>
            )}
          </div>
        ) : (
          <div className={`flex justify-center text-grey absolute w-full opacity-50 mt-5`}>NO RESULTS</div>
        )}
      </div>
    </>
  );
};
