import React from 'react';
export const ARInfoYellowIcon = (props: {width?: string; height?: string; className?: string}) => {
  return (
    <svg
      width={props.height || '16px'}
      height={props.height || '16px'}
      className={props.className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Order-Listing" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Ready-to-Pick" transform="translate(-1254.000000, -502.000000)" fill="#fff" fillRule="nonzero">
          <g id="ARIIcon" transform="translate(1254.000000, 502.000000)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.001 4.334a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm7 8.334v-4.44A8.14 8.14 0 0 0 8.558.02 8 8 0 0 0 .02 8.57C.315 12.806 4.056 16 8.723 16h3.945A3.337 3.337 0 0 0 16 12.669ZM8.468 1.35a6.8 6.8 0 0 1 6.2 6.878v4.44a2 2 0 0 1-2 2H8.723c-4.023 0-7.122-2.6-7.372-6.19A6.667 6.667 0 0 1 8.468 1.35ZM9.334 12V8c0-.736-.597-1.332-1.333-1.332h-.667a.667.667 0 0 0 0 1.333h.667v4a.667.667 0 1 0 1.333 0Z"
              fill="#C68A19"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
