import * as React from 'react';
const T2TInventoryDropdownArrow = (props: React.SVGProps<SVGSVGElement>) => (
  <svg {...props} width="13" height="9" fill="none">
    <g className="fill-primary">
      <path
        fillRule="evenodd"
        d="M.983.86h11.034a.987.987 0 0 1 .691 1.688L7.201 8.055a.987.987 0 0 1-1.402 0L.292 2.548A.987.987 0 0 1 .982.86Z"
        clipRule="evenodd"
      />
    </g>
  </svg>
);
export default T2TInventoryDropdownArrow;
