import pos_float from './pos-float.png';
import pos_payment from './pos-payment.png';
import pos_refund from './pos-refund.png';
import pos_scanner from './pos-scanner.png';
import pos_screen from './pos-screen.png';
import pos_virtualterminal from './pos-virtualterminal.png';

export const POSOnboadingImages = {
  pos_float,
  pos_payment,
  pos_refund,
  pos_scanner,
  pos_screen,
  pos_virtualterminal,
};
