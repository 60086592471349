import React, {useEffect, useContext} from 'react';
import PublicShell from '../../screens/public/PublicShell';
import AppContext from '../../context/app/appContext';
import {Box} from '@material-ui/core';
import LoadingIndicator from '../ui/LoadingIndicator';

export const Unauthorized = () => {
  const {logout} = useContext(AppContext);

  useEffect(() => {
    setTimeout(() => {
      logout();
    }, 2000);
  }, []);

  return (
    <PublicShell>
      <Box
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        className="bg-spenda-newbg"
      >
        <Box display="flex">
          <LoadingIndicator isLoading={true} position={{position: 'relative'}} size="md" color="#1C78AD" />
          <p className="ml-2 font-poppins text-xl font-light text-primary">
            Your session has expired, logging you out safely...
          </p>
        </Box>
      </Box>
    </PublicShell>
  );
};
