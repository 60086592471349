import React from 'react';

export const IconAmexCardRebranded = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>American Express</title>
    <g id="amex-american-express-seeklogo.com">
      <path id="Fill 1" fillRule="evenodd" clipRule="evenodd" d="M0 24H24V0H0V24Z" fill="#016FD0" />
      <path
        id="Fill 2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.27588 19.843V11.5864L24 11.5996V13.8803L22.1826 15.6954L24 17.5272V19.8563H21.0983L19.5562 18.266L18.0251 19.8623L8.27588 19.843Z"
        fill="#FFFFFE"
      />
      <path
        id="Fill 3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.93115 19.0343V12.4136H15.7243V13.9388H11.8068V14.9741H15.6309V16.4739H11.8068V17.4908H15.7243V19.0343H9.93115Z"
        fill="#016FD0"
      />
      <path
        id="Fill 4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7242 19.0343L18.7462 15.72L15.7241 12.4136H18.0631L19.9097 14.512L21.7615 12.4136H24V12.4657L21.0424 15.72L24 18.9403V19.0343H21.739L19.8597 16.9148L17.9997 19.0343H15.7242Z"
        fill="#016FD0"
      />
      <path
        id="Fill 5"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.54634 4.1377H13.2008L14.4844 6.96945V4.1377H18.9961L19.7741 6.25924L20.5547 4.1377H23.9999V12.4136H5.79297L9.54634 4.1377Z"
        fill="#FFFFFE"
      />
      <path
        id="Fill 6"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4164 4.96533L7.44824 11.586H9.48398L10.044 10.2602H13.0782L13.6377 11.586H15.7241L12.7683 4.96533H10.4164ZM10.6713 8.7742L11.5614 6.66657L12.4509 8.7742H10.6713Z"
        fill="#016FD0"
      />
      <path
        id="Fill 7"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7241 11.5705V4.96533L18.4874 4.97505L19.9094 9.03576L21.3405 4.96533H24V11.5705L22.2886 11.586V7.04889L20.6732 11.5705H19.1111L17.4622 7.03339V11.5705H15.7241Z"
        fill="#016FD0"
      />
    </g>
  </svg>
);
