import React from 'react';
import {
  Dialog,
  DialogProps,
  Drawer,
  DrawerProps,
  Menu,
  MenuProps,
  Popover,
  PopoverProps,
  Modal,
  ModalProps,
} from '@material-ui/core';

/*
SPopover is a `Spenda Popover` component that derives from material UI's Popover
This was needed because we are using zoho chat widget which works with iFrames and it does not play nice with React.
If you have a model material UI component open, the chat does not allow one to input data on the iFrame form.

I found a solution here - https://stackoverflow.com/questions/51388205/prevent-auto-focus-of-a-material-ui-popover-element#answer-59979967

Add props 'disableAutoFocus' and 'disableEnforceFocus' to all material UI modal components.

NOTE: This fix comes at a price, we sacrifice screen reader capabilities for our visually impaired users  😢 :(

Material UI list which we need to create custom components by adding the two properties `disableAutoFocus` & `disableEnforceFocus`: 
  Dialog, 
  Drawer,
  Menu,
  Popover (SPopover is the Spenda version)

  (see https://material-ui.com/api/modal/)

*/

export interface SPopoverProps extends PopoverProps {}
export const SPopover: React.FunctionComponent<SPopoverProps> = props => {
  return (
    <Popover {...props} disableAutoFocus disableEnforceFocus>
      {props.children}
    </Popover>
  );
};

export interface SMenuProps extends MenuProps {}
export const SMenu: React.FunctionComponent<SMenuProps> = props => {
  return (
    <Menu {...props} disableAutoFocus disableEnforceFocus>
      {props.children}
    </Menu>
  );
};

export interface SDrawerProps extends DrawerProps {}
export const SDrawer: React.FunctionComponent<SDrawerProps> = props => {
  return (
    <Drawer {...props} disableAutoFocus disableEnforceFocus>
      {props.children}
    </Drawer>
  );
};

export interface SDialogProps extends DialogProps {}
export const SDialog: React.FunctionComponent<SDialogProps> = props => {
  return (
    <Dialog {...props} disableAutoFocus disableEnforceFocus>
      {props.children}
    </Dialog>
  );
};

export interface SModalProps extends ModalProps {}
export const SModal: React.FunctionComponent<SModalProps> = props => {
  return (
    <Modal {...props} disableAutoFocus disableEnforceFocus className={`${props.className}`}>
      <>{props.children}</>
    </Modal>
  );
};
