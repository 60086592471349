import React, {useState} from 'react';
import {useHistory, Link as RouterLink} from 'react-router-dom';
import {Button} from 'spenda-ui-react';
import DoneIcon from '@material-ui/icons/Done';
import {makeStyles} from '@material-ui/core';
import {css} from 'glamor';

import {AUTH_CUSTOMER_CUSTOMERS_CLASSES_ADD, AUTH_CUSTOMER_CUSTOMERS_CLASSES_EDIT} from '../../routes/CustomerRoutes';
import {Layout} from '../../components/layout/Layout';
import {STable} from '../../components/tables/STable';
import {SPagination} from '../../components/tables/SPagination';
import {OperationalCustomerToolbar} from '../../components/customer/CustomerToolbar';
import {useLoadCustomers} from '../../services/useCustomerClassesAPI';
import {ICreateCustomerClassValues} from '../../model/customer/CustomerClasses';

const useCustomerClassesStyles = makeStyles(() => ({
  root: {
    maxHeight: 'calc( 100vh - 20.5em )',
  },
  title: {
    fontWeight: 500,
    fontSize: '1.5em',
    color: '#4D4D4D',
  },
}));

const tradingTypeText = (tradingTermType: string) => {
  switch (tradingTermType) {
    case 'Pre Paid':
      return 'Prepaid';
    case 'COD':
      return 'Cash On Delivery';
    case 'Days after EOM':
      return 'Days After Current Month';
    case 'Day of EOM':
      return 'Day Of Following Month';
    default:
      return tradingTermType.replaceAll('_', ' ');
  }
};

const columnsRender = () => ({
  Name: {
    disabled: true,
    title: 'Class Name',
    field: 'Name',
    render: (rowData: ICreateCustomerClassValues) => {
      return (
        <div {...css({position: 'relative'})}>
          <p>{rowData.Name || ' - '}</p>
        </div>
      );
    },
  },
  Description: {
    title: 'Description',
    field: 'Description',
    render: (rowData: ICreateCustomerClassValues) => {
      return (
        <div {...css({position: 'relative'})}>
          <p>{rowData.Description || ' - '}</p>
        </div>
      );
    },
  },
  PostingBehaviour: {title: 'Posting Behaviour', field: 'PostingBehaviour'},
  TradingTermType: {
    title: 'Trading Terms',
    field: 'TradingTermType',
    render: (rowData: ICreateCustomerClassValues) => {
      if (rowData?.TradingTermDays || rowData?.TradingTermType) {
        return (
          <div {...css({position: 'relative'})}>
            <p>
              {rowData?.TradingTermDays} {tradingTypeText(rowData?.TradingTermType)}
            </p>
          </div>
        );
      } else {
        return (
          <div {...css({position: 'relative'})}>
            <p>{' - '}</p>
          </div>
        );
      }
    },
  },
  CreditLimit: {
    title: 'Credit Limit',
    field: 'CreditLimit',
    render: (rowData: ICreateCustomerClassValues) =>
      rowData?.CreditLimit === 0 || (rowData.CreditLimit && rowData.CreditLimit >= 1) ? `${rowData?.CreditLimit}` : '-',
  },

  IsBatchInvoice: {
    title: 'Batch Invoiced',
    field: 'IsBatchInvoice',

    render: (rowData: ICreateCustomerClassValues) =>
      rowData?.IsBatchInvoice === true ? (
        <DoneIcon {...css({color: '#333333', width: '4px'})} />
      ) : (
        <p style={{textAlign: 'left', marginLeft: '8px'}}>-</p>
      ),
  },

  BatchInvoicePrefix: {
    title: 'Invoice Prefix',
    field: 'BatchInvoicePrefix',
    render: (rowData: ICreateCustomerClassValues) => rowData?.BatchInvoicePrefix || '-',
  },
  CountOfCustomers: {
    title: '#Customers',
    field: 'CountOfCustomers',
    render: (rowData: ICreateCustomerClassValues) => (rowData?.CountOfCustomers ? rowData?.CountOfCustomers : 0),
  },
});

export const CustomerClasses = () => {
  const [selected, setSelected] = useState<number[]>([]);

  const [selectedColumns, setSelectedColumns] = useState<string[]>([
    'Name',
    'Description',
    'PostingBehaviour',
    'TradingTermType',
    'CreditLimit',
    'IsBatchInvoice',
    'BatchInvoicePrefix',
    'CountOfCustomers',
  ]);
  const {
    loading,
    totalCount,
    pageSize,
    currentPage,
    items: inventoryList,
    searchFilter,
    setSearchFilter,
  } = useLoadCustomers();
  const history = useHistory();
  const classes = useCustomerClassesStyles();
  const handleRowClick = (rowData: any) => {
    history.push(AUTH_CUSTOMER_CUSTOMERS_CLASSES_EDIT.replace(/:classID/g, rowData.ID));
  };

  const handlePageChange = (newPage: number) => {
    setSearchFilter({
      ...searchFilter,
      LastRowNumber: (newPage - 1) * pageSize,
    });
  };

  const productsList = (
    <div
      className={`${classes.root} flex min-h-full w-full flex-col overflow-y-auto rounded-lg bg-white p-4 px-5`}
      id="products-list"
    >
      <div className={'flex w-full justify-between px-1 py-4'}>
        <p className={`${classes.title}`}>Customer Classes</p>
        <div className="flex">
          <RouterLink to={AUTH_CUSTOMER_CUSTOMERS_CLASSES_ADD}>
            <Button size='sm'>Add Customer Class</Button>
          </RouterLink>
        </div>
      </div>

      <OperationalCustomerToolbar
        isCustomerClasses={true}
        loading={loading}
        title={`Classes List (${totalCount})`}
        searchFilter={searchFilter}
        columns={columnsRender}
        data={inventoryList}
        selected={selected}
        selectedColumns={selectedColumns}
        setSelected={setSelected}
        setSelectedColumns={setSelectedColumns}
        setSearchFilter={setSearchFilter}
      />

      <STable
        columns={Object.entries(columnsRender())
          .map(([key, value]: [string, any]) => {
            if (selectedColumns.includes(key)) {
              return value;
            }
          })
          .filter(value => value)}
        data={inventoryList}
        emptyDataMessage={'No Customers'}
        stickyHeader={true}
        selection={false}
        loading={loading}
        onRowClick={handleRowClick}
      ></STable>
      <SPagination
        currentPage={currentPage}
        totalCount={totalCount}
        pageSize={pageSize}
        onPageChange={handlePageChange}
      />
    </div>
  );

  return (
    <div className={`relative h-full overflow-hidden bg-spenda-newbg`}>
      <Layout leftPanel={productsList} splitWidthType={4} />
    </div>
  );
};
