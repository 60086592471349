import React, {useState} from 'react';
import {CloseCross} from '../../assets/svg/CloseCross';
import {IconButton} from 'spenda-ui-react';
import {AlertDialog} from './AlertDialogSlideV2';

import {useBatchUpdateAPI} from '../../services/useBatchUpdateAPI';
import {ICreateCustomerClassValues} from '../../model/customer/CustomerClasses';
import {IBatchUpdate, IBatchUpdatesArray} from '../../model/BatchUpdate';
import {ApplicationInfo} from '../../model/constants/ApplicationInfo';
import {CustomerClassesAutocomplete} from '../customer/CustomerAutocomplete';

export interface IModifyDialog {
  open: boolean;
  selectedRows: number[];
  onClose: () => void;
  getCustomersList?: () => void;
  setRefetch: any;
  refetch: number;
}

export const ModifyDialog: React.FunctionComponent<IModifyDialog> = ({
  open,
  selectedRows,
  onClose,
  setRefetch,
  refetch,
}: IModifyDialog) => {
  const [customerClassFilter, setCustomerClassFilter] = useState<Partial<ICreateCustomerClassValues>>();

  const {isLoading, batchUpdate} = useBatchUpdateAPI();

  const handleCloseClick = () => {
    onClose();
  };

  const onSave = () => {
    const batchUpdateArray = {
      DatTypeID: 1,
      ColumnToUpdate: 'CustomerClassID',
      Value: customerClassFilter?.ID,
      IDs: selectedRows,
      IsRemoveFromCollection: false,
    } as IBatchUpdatesArray;
    const batchUpdatePayload: IBatchUpdate = {
      Updates: [batchUpdateArray],
      WebsiteID: ApplicationInfo.WebsiteId,
    } as IBatchUpdate;
    batchUpdate(batchUpdatePayload).then(() => {
      handleCloseClick();
      setCustomerClassFilter(undefined);
      setRefetch(refetch + 1);
    });
  };

  const handleCustomerClassFilter = (selected: Partial<ICreateCustomerClassValues> | null) => {
    setCustomerClassFilter(selected || undefined);
  };

  return (
    <div>
      {open && (
        <AlertDialog
          handler={onClose}
          title="Modify Selected Customers"
          data-autoid="dlgModifySelectedCustomers"
          headingTextSize="h2"
          headingClassess="justify-center border-b border-[#ccc] text-center text-xl font-light text-black-900"
          onCancel={handleCloseClick}
          headerChildren={
            <IconButton
              aria-label="close"
              variant="outlined"
              className="!absolute right-4 top-4 !text-gray-500 "
              data-autoid="btnClose"
              onClick={handleCloseClick}
            >
              <CloseCross />
            </IconButton>
          }
          actions={[
            {
              label: 'Close',
              variant: 'outlined',
              action: handleCloseClick,
            },
            {
              label: 'Save',
              action: onSave,
              loading: isLoading,
              disabled: isLoading,
            },
          ]}
          body={
            <div className="flex h-[320px] min-w-[275px] flex-col p-4">
              <p>Select Customer Class</p>
              <CustomerClassesAutocomplete
                selected={customerClassFilter ? customerClassFilter : null}
                clearClick={() => handleCustomerClassFilter(null)}
                onSuggestionSelected={(e, selected) => handleCustomerClassFilter(selected.suggestion)}
              />
            </div>
          }
        ></AlertDialog>
      )}
    </div>
  );
};
