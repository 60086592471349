import * as localForage from "localforage";
import { StorageKeys } from "../model/constants/Constants";
import environment from "../utils/environment";

const useStorage = () => {

    const _prefix: string = "Spenda";
    const prefixKey = (key: StorageKeys): string => `${_prefix}_${environment.ENV_NAME}_${key}`;
    
    const setItem = (key: StorageKeys, value: any): Promise<any> => {
        return localForage.setItem(prefixKey(key), value)
    }

    const getItem = (key: StorageKeys): Promise<any> => {
        return localForage.getItem(prefixKey(key));
    }

    const removeItem = (key: StorageKeys): Promise<void> => {
        return localForage.removeItem(prefixKey(key));
    }

    const setAuth0Nonce = (key: string, value: any): Promise<any> => {
        return localForage.setItem(key, value)
    }

    const getAuth0Nonce = (key: string): Promise<any> => {
        const value = localForage.getItem(key)
        localForage.removeItem(key)
        return value;
    }

    const removeAuth0Nonce = (key: string): Promise<void> => {
        return localForage.removeItem(key);
    }

    return {
        getItem,
        setItem,
        removeItem,
        setAuth0Nonce,
        getAuth0Nonce,
        removeAuth0Nonce
    }
}

export default useStorage;