import React, {PropsWithChildren} from 'react';
import {LogoName} from '../../components/LogoName';

const PublicShell: React.FC<PropsWithChildren> = props => {
  return (
    <div className={`flex items-center justify-center h-full bg-spenda-newbg`}>
      <div className="flex flex-col items-center text-xl">
        <LogoName />
        {props.children}
      </div>
    </div>
  );
};

export default PublicShell;
