import React, {useContext, useState} from 'react';
import {SButton} from '../../../components/buttons/Button';
import {useEffect} from 'react';
import {PaymentWidgetContext, PaymentWidgetPaymentSteps, PaymentWidgetStep} from './PaymentWidget';
import {
  AccountType,
  PaymentMethodTypes,
  PaymentWidgetScope,
  VerificationStatus,
} from '../../../model/constants/Constants';
import {IAccountResponse} from '../../../services/useSpendaPaymentServicesAPI';
import {ErrorAnimation, ProcessingAnimation, SuccessAnimation} from '../../../assets/svg/animations/LottieAnimations';
import {PaymentReferenceCode} from '../../../components/dialog/PennyVerificationDialog';
import {css} from 'glamor';
import {IconPennyDrop} from '../../../assets/svg/IconPennyDrop';
import {ITenantSupplierPaymentMethod} from '../../../model/payment/PaymentMethod';
import SuccessfulAccount from '../../../assets/svg/SuccessfulAccount.svg';
import PaymentSuccessful from '../../../assets/svg/PaymentSuccessful.svg';
import FailedPayment from '../../../assets/svg/FailedPayment.svg';
import {PaymentConverter} from '../../../utils/data-converters/payment-converter';
import {Button, Dialog, DialogBody, DialogFooter, DialogHeader} from 'spenda-ui-react';

export const PaymentWidgetStepVerifyCreditCard = () => {
  const {
    code,
    bpspAccountDetails: bpspAccount,
    widgetScope,
    paymentAuth72488,
    setCode,
    setBpspAccountDetails,
    setStep,
    fetchSPSAccount,
    initiateVerification,
    onVerifyCode,
    onRetryVerification,
    setIsRefreshPaymentMethods,
    removeCreditCard,
    onCancel,
    onDone,
    setCardToEdit,
    setBatchPaymentGuid,
    selectedBatchDetails,
    isLinkedToLendingProvider,
    isAPOnboardingV1,
  } = useContext(PaymentWidgetContext);

  const redErrorHeading = css({
    color: '#C55D44',
    fontSize: '22px',
  });

  useEffect(() => {
    if (setCode) {
      setCode('');
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!bpspAccount || !bpspAccount.accountGUID) return;

      if (
        bpspAccount.verificationStatus === VerificationStatus.PENDING ||
        bpspAccount.verificationStatus === VerificationStatus.UNKNOWN
      ) {
        if (fetchSPSAccount) {
          fetchSPSAccount(bpspAccount?.accountGUID).then(res => {
            if (setBpspAccountDetails) {
              setBpspAccountDetails(res);
            }
          });
        }
      }
    }, 2500);
    return () => {
      clearInterval(interval);
    };
  }, [bpspAccount?.verificationStatus]);

  const [isShowDeleteConfirmation, setIsShowDeleteConfirmation] = useState<boolean>(false);
  const [isShowNoDepositDialog, setIsShowNoDepositDialog] = useState<boolean>(false);

  const isCreditCard =
    bpspAccount?.accountType !== AccountType.BANK_TRANSFER && bpspAccount?.accountType !== AccountType.VIRTUAL_CARD;
  const isAirPlus = bpspAccount?.paymentMethod === PaymentMethodTypes.Airplus;

  const isPaymentScopeOnly = (): boolean => {
    if (!widgetScope) {
      return false;
    }

    return [
      PaymentWidgetScope.ADD_BANK_ACCOUNT,
      PaymentWidgetScope.ADD_AIRPLUS,
      PaymentWidgetScope.ADD_CREDIT_CARD,
      PaymentWidgetScope.VERIFY_PAYMENT_METHOD,
      PaymentWidgetScope.PSBL,
      PaymentWidgetScope.CONFIGURE_PAYMENT_OPTIONS,
    ].includes(widgetScope);
  };

  const goNextStep = async (isRefreshPaymentMethods?: boolean) => {
    if (isPaymentScopeOnly()) {
      if (isRefreshPaymentMethods === true) {
        if (onDone) onDone();
        if (widgetScope === PaymentWidgetScope.PSBL) {
          await setBatchPaymentGuid?.({
            paymentAccountGUID: bpspAccount?.accountGUID!,
            invoicePaymentAllocationID: selectedBatchDetails?.invoicePaymentAllocationID!,
          });
        }
      } else if (onCancel) onCancel();
      return;
    }

    if (isRefreshPaymentMethods) {
      if (setIsRefreshPaymentMethods) setIsRefreshPaymentMethods(true);
    }

    if (setStep) setStep(PaymentWidgetPaymentSteps.NEW);
  };

  const VSNotStarted = (_props: {bpspAccount?: IAccountResponse}) => (
    <div>
      <RemoveConfirmationDialog />
      <div style={{marginTop: '100px', fontSize: '22px'}}>
        <h1 className="m-10 font-poppins font-light">{isAirPlus ? 'Account Verification' : 'Card Verification'}</h1>
      </div>
      <div className="px-10 font-poppins text-xs font-normal">
        <p>
          {`Thanks for adding a new payment method. Before we can begin processing payments we need to verify your  ${
            isAirPlus ? 'account' : 'card'
          }`}
          .
        </p>
      </div>
      <div>
        <br />
        <div className="px-10 font-poppins text-xs font-normal">
          <p>
            {` To do this we will conduct a quick check to make sure you have entered the correct details. This involves
              charging a small verification fee of less than $1 to your ${isAirPlus ? 'account' : 'card'} ending in ${
                isAirPlus ? bpspAccount?.virtualCard?.last4 : bpspAccount?.cardDetails?.last4
              }
              .`}
          </p>
        </div>
      </div>
    </div>
  );

  const VSRetryRequired = () => {
    return (
      <>
        <div>
          <RemoveConfirmationDialog />
          <div>
            <div className="mt-3 flex justify-center" {...css({width: '100%', height: '81px', marginTop: '100px'})}>
              {isCreditCard || isAirPlus ? (
                <ErrorAnimation width="108px" height="81px" />
              ) : (
                <img alt="FailedPayment" style={{width: '89px', height: '81px'}} src={FailedPayment} />
              )}
            </div>
            <div>
              <h1 className={`m-10 font-poppins text-3xl font-light ${redErrorHeading}`}>
                {isCreditCard || isAirPlus ? 'Charge' : 'Payment'} Failed
              </h1>
            </div>
          </div>
          <div style={{fontSize: '12px'}}>
            <div className="mt-4 font-poppins font-normal">
              We received a technical failure when <br />
              {isCreditCard
                ? 'charging your card.'
                : isAirPlus
                  ? 'charging your account'
                  : 'processing your Account Verification.'}
            </div>
            <div className="mt-4 font-poppins font-normal">
              <p>Please click the button below to retry.</p>
            </div>
          </div>
        </div>
      </>
    );
  };

  const VSFailed = () => {
    return (
      <>
        <div className="px-2 py-4" style={{marginTop: '30px'}}>
          <div>
            <div className="mb-3 flex justify-center" {...css({height: '73px', marginTop: '33px'})}>
              {isCreditCard || isAirPlus ? (
                <ErrorAnimation width="100px" height="81px" />
              ) : (
                <img alt="FailedPayment" style={{width: '76px', height: '69px'}} src={FailedPayment} />
              )}
            </div>
            <div className="font-poppins">
              <h1 className={`m-3 mt-11 font-poppins ${redErrorHeading}`}>
                {isCreditCard || isAirPlus ? 'Charge' : 'Payment'} Failed
              </h1>
            </div>
          </div>
          <div className=" text-xs font-normal" style={{fontFamily: 'poppins'}}>
            {isCreditCard ? (
              <>
                <div className="px-10 py-2 font-normal">
                  <div className=" font-normal">We were unable to charge your card.</div>
                  The details you provided appear to be incorrect: <br />
                  <div style={{margin: '20px'}}>
                    Last four digits: <span className="font-normal">{bpspAccount?.cardDetails?.last4}</span>
                    <br /> Expiry date: <span className="font-normal">
                      {bpspAccount?.cardDetails.expiry}
                    </span> <br /> Name on card:{' '}
                    <span className="font-normal">{bpspAccount?.cardDetails.accountHolderName}</span> <br />
                  </div>
                  <div>Would you like to remove this card and try again ?</div>
                </div>
              </>
            ) : isAirPlus ? (
              <>
                <div className="px-10 py-2 font-normal">
                  <p className=" font-normal">We were unable to charge your account.</p>
                  <p className="mt-4">
                    Last four digits: <span className="font-normal">{bpspAccount?.virtualCard?.last4}</span>
                  </p>
                  <p className="mt-8">Would you like to remove this account and try again ?</p>
                </div>
              </>
            ) : (
              <>
                <div className="px-10 py-2 font-normal" style={{marginTop: '20px', marginBottom: '20px'}}>
                  Payment to your nominated bank account failed. The details you provided appear to be incorrect: <br />
                  <div style={{fontSize: '12px', marginTop: '20px', marginBottom: '20px'}}>
                    BSB: {bpspAccount?.bankDetails?.bankBSB}
                    <br />
                    Account number: {bpspAccount?.bankDetails?.bankAccountNumber}
                  </div>
                  Would you like to remove this account and try again ?
                </div>
              </>
            )}
          </div>
          <RemoveConfirmationDialog />
        </div>
      </>
    );
  };

  const VSPendingOrUnknown = () => {
    return (
      <>
        <div className="mt-10 text-center">
          <div className="flex justify-center">
            <div>
              <ProcessingAnimation width="182px" height="164px" />
            </div>
          </div>
          <div className="mx-8 mt-16">
            <div className="pt-3 font-poppins text-xs">
              Please wait while we verify your {isCreditCard ? `card` : `account`}.
            </div>
            <div>
              <div className="pt-3 font-poppins text-xs">
                {isAPOnboardingV1
                  ? 'This usually takes less than 30 seconds but can take longer depending on your bank and internet connection.'
                  : 'This usually takes less 30 seconds but can take longer depending on your bank.'}
              </div>
              <div className="pt-3 font-poppins text-xs">
                {isAPOnboardingV1
                  ? 'If you are in a rush, you can come back and finish this step later.'
                  : `In a rush? You can come back and finish the ${
                      isCreditCard ? `card` : `account`
                    } verification later.`}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const RemoveConfirmationDialog = () => {
    return (
      <div className="font-poppins">
        <Dialog
          handler={() => {}}
          open={isShowDeleteConfirmation}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogHeader
            id="alert-dialog-title"
            className={`border-b border-[#f1f1f1] font-poppins font-light text-black-900 `}
          >
            Delete Card
          </DialogHeader>
          <DialogBody className="py-10">
            <p className="font-poppins text-base text-black-800">
              You wont be able to use this card in the future. Are you sure you want to delete it?
            </p>
          </DialogBody>
          <DialogFooter className="justify-between">
            <Button
              variant="outlined"
              color="primary"
              className="mr-2"
              onClick={() => setIsShowDeleteConfirmation(false)}
            >
              Cancel
            </Button>
            <Button variant="filled" color="error" onClick={onRemove}>
              Delete
            </Button>
          </DialogFooter>
        </Dialog>
      </div>
    );
  };

  const onEnterVerificationCode = async () => {
    if (onVerifyCode && code) {
      const res = await onVerifyCode(code, bpspAccount?.accountGUID || '');
      if (setBpspAccountDetails) setBpspAccountDetails(res);
    }
  };

  const onToggleRemoveConfirmation = () => {
    setIsShowDeleteConfirmation(true);
  };

  const onRemove = async () => {
    const pmObj = {
      SupplierPaymentOptionID: -45654, // Alex exp: need to improve this way.
      PaymentAccountGUID: bpspAccount?.accountGUID,
    } as ITenantSupplierPaymentMethod;

    setIsShowDeleteConfirmation(false);

    if (!removeCreditCard || !pmObj) return;

    const isDeleted = await removeCreditCard(pmObj);

    if (isDeleted || pmObj.PaymentAccountGUID) {
      if (isPaymentScopeOnly()) {
        if (onCancel) onCancel();
        return;
      }

      if (setIsRefreshPaymentMethods) {
        setIsRefreshPaymentMethods(true);
      }
      if (setStep) {
        setStep(PaymentWidgetPaymentSteps.NEW);
      }
    }
  };

  const onClickNoDeposit = () => {
    setIsShowNoDepositDialog(true);
  };

  let inputCode: string = '';
  if (code) {
    inputCode = code;
  }

  const setInputCode = (newValue: string) => {
    if (setCode) {
      setCode(newValue);
    }
  };

  const VSNotVerified = (
    <>
      <RemoveConfirmationDialog />
      {isShowNoDepositDialog ? (
        <div>
          <div>
            <div className="my-6 flex justify-center" {...css({height: '120px'})}>
              <SuccessAnimation />
            </div>
          </div>
          <div className="p-2">
            <div>
              <p>{`We have successfully charged your ${isCreditCard ? 'card' : 'account'}`}</p>
              {isCreditCard ? (
                <>
                  <p>
                    Last four digits: <span className="text-xl">{bpspAccount?.cardDetails.last4}</span>
                  </p>
                  <p>
                    Expiry date: <span className="text-xl">{bpspAccount?.cardDetails.expiry}</span>
                  </p>
                  <p>
                    Name on card: <span className="text-xl">{bpspAccount?.cardDetails.accountHolderName}</span>
                  </p>
                </>
              ) : (
                <>
                  <p>
                    BSB <span className="text-xl">{bpspAccount?.bankDetails.bankBSB}</span>
                  </p>
                  <p>
                    Account number <span className="text-xl">{bpspAccount?.bankDetails.bankAccountNumber}</span>
                  </p>
                </>
              )}
            </div>
            <div>
              <div>
                <p>
                  If these details are incorrect <br /> you can remove this {isCreditCard ? 'card' : 'account'} and try
                  again.
                </p>
                <SButton
                  width="86px"
                  height="40px"
                  fontFamily="poppins"
                  color="red"
                  onClick={onToggleRemoveConfirmation}
                >
                  Remove {isCreditCard ? 'Card' : 'Account'}
                </SButton>
              </div>
            </div>
            <div>
              {isCreditCard ? (
                <p>
                  A pending charge should appear on your card statement within a few minutes, but it can take longer
                  depending on your bank.
                </p>
              ) : (
                <p>
                  Verification usually takes 30 seconds to process into your nominated bank account, but can take longer
                  in some circumstances.
                </p>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div style={{marginTop: '57px'}}>
            <div className="mt-3 flex justify-center">
              <IconPennyDrop height="81px" width="81px" />
            </div>
            <div>
              <h1 className="mb-3 mt-4 px-8 font-poppins text-xl font-light">{`${
                isCreditCard ? 'Finish verifying your card' : 'Finish verifying your account'
              }`}</h1>
            </div>
          </div>
          <div>
            <div>
              <div className="font-poppins text-xs font-normal" style={{marginLeft: '39px', marginRight: '39px'}}>
                <ol>
                  <li>{isAirPlus ? 'Log in to the AirPlus portal.' : 'Log into your internet banking.'}</li>
                  {isAirPlus ? (
                    <li> Look for the newly generated virtual card and take note of the card limit. </li>
                  ) : (
                    <li>
                      Look for a transaction from <strong>Spenda</strong> and take note of the{' '}
                      {isCreditCard ? 'amount charged to your account.' : 'amount deposited to your account'}
                    </li>
                  )}
                  <li className="pt-4">Enter the amount in the section below. The amount will be less than $1.00.</li>
                </ol>
              </div>
              <div className="grid justify-center pt-3 text-center">
                <PaymentReferenceCode
                  is2SA={false}
                  onChange={setInputCode}
                  value={inputCode}
                  onEnter={onEnterVerificationCode}
                ></PaymentReferenceCode>
              </div>
            </div>
            <div>
              <div
                className="mt-6 cursor-pointer font-poppins font-semibold text-primary hover:underline"
                onClick={onClickNoDeposit}
              >
                {isCreditCard ? `Can't see the charge?` : `Didn't receive the deposit?`}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );

  const VSAttemptsExceeded = () => {
    return (
      <div>
        <RemoveConfirmationDialog />
        <div>
          <div className="mt-8 flex justify-center" {...css({height: '85px', marginTop: '73px'})}>
            {isCreditCard || isAirPlus ? (
              <ErrorAnimation width="108px" height="81px" />
            ) : (
              <img alt="FailedPayment" style={{width: '89px', height: '81px'}} src={FailedPayment} />
            )}
          </div>
          <div>
            <h1 className={`mb-5 mt-6 font-poppins ${redErrorHeading}`}>{`${
              isCreditCard ? 'Finish verifying your card' : 'Unsuccessful Account Verification'
            }`}</h1>
          </div>
        </div>
        <div className="mt-2 p-2 font-poppins" style={{fontSize: '12px', marginLeft: '31px', marginRight: '31px'}}>
          <p>
            You have exceeded your amount of attempts. Please contact support to assist you with the verification
            process.{' '}
          </p>
        </div>
      </div>
    );
  };

  const VSVerified = () => {
    return (
      <div className="px-4">
        <div className="flex justify-center">
          {isCreditCard || isAirPlus ? (
            <img
              alt="PaymentSuccessful"
              style={{width: '108px', height: '81px', marginTop: '63px'}}
              src={PaymentSuccessful}
            />
          ) : (
            <img
              alt="SuccessfulAccount"
              style={{height: '89px', width: '80px', marginTop: '73px'}}
              src={SuccessfulAccount}
            />
          )}
        </div>
        <div>
          <h1 className={`mb-8 mt-6 font-poppins text-xl text-spenda-title`}>
            Successful {isCreditCard ? 'Card' : 'Account'} Verification
          </h1>
        </div>
        <div>
          <div className={'m-10 font-poppins text-xs font-normal'}>
            <p>
              {isAPOnboardingV1
                ? 'Your account has been successfully verified!'
                : `Your ${isCreditCard ? 'credit card' : isAirPlus ? 'account' : 'bank account'} has been verified.`}
            </p>
            <br />
            <p>
              {isAPOnboardingV1
                ? 'You are now ready to start processing payments. Press ‘next’ to continue.'
                : `You're ready to start processing payments.`}
            </p>
          </div>
        </div>
      </div>
    );
  };

  const startVerification = async () => {
    if (bpspAccount && initiateVerification) {
      const res = await initiateVerification(bpspAccount.accountGUID);

      if (res && setBpspAccountDetails) setBpspAccountDetails(res);
    }
  };

  const retryVerification = async () => {
    if (bpspAccount && onRetryVerification) {
      const res = await onRetryVerification(bpspAccount.accountGUID);

      if (res && setBpspAccountDetails) setBpspAccountDetails(res);
    }
  };

  const body = (
    <>
      {bpspAccount?.verificationStatus === VerificationStatus.NOT_STARTED && <VSNotStarted bpspAccount={bpspAccount} />}
      {bpspAccount?.verificationStatus === VerificationStatus.NOT_VERIFIED && VSNotVerified}
      {bpspAccount?.verificationStatus === VerificationStatus.RETRY_REQUIRED && <VSRetryRequired />}
      {(bpspAccount?.verificationStatus === VerificationStatus.PENDING ||
        bpspAccount?.verificationStatus === VerificationStatus.UNKNOWN) && <VSPendingOrUnknown />}
      {bpspAccount?.verificationStatus === VerificationStatus.ATTEMPTS_EXCEEDED && <VSAttemptsExceeded />}
      {bpspAccount?.verificationStatus === VerificationStatus.VERIFIED && <VSVerified />}
      {bpspAccount?.verificationStatus === VerificationStatus.FAILED && <VSFailed />}
    </>
  );

  const footer = (
    <>
      {bpspAccount?.verificationStatus === VerificationStatus.NOT_STARTED && (
        <>
          <SButton
            height="40px"
            fontFamily="poppins"
            color="white"
            textColor="blueShade"
            border={'1px solid #1C78AD'}
            onClick={goNextStep}
            type="button"
            borderRadius="6px"
            width="86px"
          >
            Cancel
          </SButton>
          <SButton
            width="86px"
            height="40px"
            fontFamily="poppins"
            color="red"
            borderRadius="6px"
            label="white"
            type="button"
            onClick={onToggleRemoveConfirmation}
          >
            Remove
          </SButton>
          <SButton
            height="40px"
            fontFamily="poppins"
            borderRadius="6px"
            color="blueShade"
            onClick={startVerification}
            label="white"
            type="button"
            width="86px"
          >
            Start
          </SButton>
        </>
      )}
      {bpspAccount?.verificationStatus === VerificationStatus.RETRY_REQUIRED && (
        <>
          <SButton
            height="40px"
            color="white"
            textColor="blueShade"
            onClick={goNextStep}
            label="blue"
            type="button"
            variant="outlined"
            width="86px"
            padding="13px 8px"
            border="1px solid #1C78AD"
            fontFamily="poppins"
            borderRadius="6px"
          >
            Cancel
          </SButton>
          <SButton
            height="40px"
            fontFamily="poppins"
            color="red"
            width="86px"
            label="white"
            type="button"
            borderRadius="6px"
            onClick={onToggleRemoveConfirmation}
          >
            Remove
          </SButton>
          <SButton
            height="40px"
            fontFamily="poppins"
            color="blueShade"
            width="86px"
            onClick={retryVerification}
            label="white"
            type="button"
            borderRadius="6px"
          >
            Retry
          </SButton>
        </>
      )}
      {(bpspAccount?.verificationStatus === VerificationStatus.PENDING ||
        bpspAccount?.verificationStatus === VerificationStatus.UNKNOWN) && (
        <SButton
          height="40px"
          color="white"
          textColor="blueShade"
          border={'1px solid #1C78AD'}
          label="white"
          type="button"
          onClick={goNextStep}
          fontFamily="poppins"
          margin="0 auto"
          width="137px"
        >
          Come Back Later
        </SButton>
      )}

      {bpspAccount?.verificationStatus === VerificationStatus.NOT_VERIFIED && (
        <>
          {isShowNoDepositDialog ? (
            <>
              <SButton
                width="86px"
                height="40px"
                fontFamily="poppins"
                color="blue"
                onClick={goNextStep}
                label="white"
                type="button"
              >
                Done
              </SButton>
              <SButton width="86px" height="40px" fontFamily="poppins" color="blue" label="white" type="button">
                Support
              </SButton>
            </>
          ) : (
            <>
              <SButton
                height="40px"
                width="86px"
                fontFamily="poppins"
                color="white"
                textColor="blueShade"
                border={'1px solid #1C78AD'}
                onClick={goNextStep}
                type="button"
                borderRadius="6px"
              >
                Cancel
              </SButton>
              <SButton
                height="40px"
                width="86px"
                fontFamily="poppins"
                backgroundColor="#C55E44"
                label="white"
                type="button"
                onClick={onToggleRemoveConfirmation}
                borderRadius="6px"
              >
                Remove
              </SButton>
              <SButton
                width="86px"
                height="40px"
                fontFamily="poppins"
                color="blueShade"
                label="white"
                type="button"
                onClick={onEnterVerificationCode}
                borderRadius="6px"
              >
                Confirm
              </SButton>
            </>
          )}
        </>
      )}
      {(bpspAccount?.verificationStatus === VerificationStatus.VERIFIED ||
        bpspAccount?.verificationStatus === VerificationStatus.ATTEMPTS_EXCEEDED) && (
        <>
          <SButton
            color="red"
            width="86px"
            label="white"
            height="40px"
            fontFamily="poppins"
            borderRadius="6px"
            type="button"
            onClick={onToggleRemoveConfirmation}
          >
            Remove
          </SButton>
          <SButton
            fontFamily="poppins"
            color="blueShade"
            width="98px"
            height="40px"
            label="white"
            type="button"
            borderRadius="6px"
            onClick={() => {
              if (
                (paymentAuth72488 || isLinkedToLendingProvider) &&
                bpspAccount?.verificationStatus !== VerificationStatus.ATTEMPTS_EXCEEDED
              ) {
                const transformedCard = PaymentConverter.SpsToFiserveModel(bpspAccount);
                setCardToEdit && setCardToEdit(transformedCard);
                if (setStep) {
                  if (isLinkedToLendingProvider) {
                    setStep(PaymentWidgetPaymentSteps.CONFIGURE_PAYMENT_OPTIONS);
                  } else {
                    setStep(PaymentWidgetPaymentSteps.SET_PAYMENT_AUTHORISATION_RULES);
                  }
                }
              } else {
                goNextStep(true);
              }
            }}
          >
            {isAPOnboardingV1 ? 'Next' : 'Continue'}
          </SButton>
        </>
      )}
      {bpspAccount?.verificationStatus === VerificationStatus.FAILED && (
        <>
          <SButton
            fontFamily="poppins"
            color="red"
            width="86px"
            label="white"
            type="button"
            onClick={onToggleRemoveConfirmation}
            height="40px"
          >
            Remove
          </SButton>
          <SButton height="40px" fontFamily="poppins" color="blue" width="86px" label="white" type="button">
            Support
          </SButton>
        </>
      )}
    </>
  );

  return <PaymentWidgetStep body={body} footer={footer} />;
};
