import * as React from 'react';
const WarningIcon = (props: SVGProps) => (
  <svg width={12} height={13} viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.795 10.015l-4.446-7.66a1.567 1.567 0 00-2.7 0l-4.444 7.66a1.493 1.493 0 000 1.508c.277.484.793.78 1.35.776h8.89a1.543 1.543 0 001.351-.776 1.493 1.493 0 00-.002-1.508zm-.741 1.08v.004a.693.693 0 01-.61.343h-8.89a.693.693 0 01-.609-.346.635.635 0 010-.65l4.447-7.662a.71.71 0 011.216 0l4.445 7.661c.12.2.12.45.001.65z"
      fill="#C55D44"
    />
    <path
      d="M6 4.156a.429.429 0 00-.429.428v3.429a.429.429 0 10.857 0V4.584A.429.429 0 006 4.156zM6 9.298a.429.429 0 00-.429.429v.428a.429.429 0 10.857 0v-.428A.429.429 0 006 9.298z"
      fill="#C55D44"
    />
  </svg>
);
export default WarningIcon;
