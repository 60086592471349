import {useContext} from 'react';
import {UserRoleEnum} from '../model/user/UserAccountInfo';
import AppContext from '../context/app/appContext';
import useUsersAPI from '../services/useUsersAPI';
import {AUTH_FINANCE_SERVICES} from '../routes/FinanceServiceRoutes';
import {UserRoleTypes} from '../model/constants/Constants';

export const useTenantInfo = () => {
  const {tenantInfo} = useContext(AppContext);
  const {getUser, isLoading} = useUsersAPI();

  const isURLPathMatch = (pathToMatch: string) => {
    const {pathname} = window.location;
    var r = pathname.toLocaleLowerCase().includes(pathToMatch.toLocaleLowerCase());
    return r;
  };
  const isInBuyerContext = isURLPathMatch('/supplier');
  const isInSpendaFinanceContext = isURLPathMatch(AUTH_FINANCE_SERVICES);
  const isInSupplierContext = !(isInBuyerContext || isInSpendaFinanceContext);

  const getUserRoleID = async (): Promise<UserRoleEnum> => {
    const userID = tenantInfo?.TenantUserDetails?.UserID;
    if (userID) {
      const userDetails = await getUser(userID);
      if (userDetails?.IsPrimaryContact) {
        return UserRoleEnum.Primary;
      } else if (
        !userDetails?.IsPrimaryContact &&
        userDetails?.UserRoles?.some(u => u?.UserRoleEnum === UserRoleEnum.Administrator) &&
        userDetails?.UserRoles?.every(u => u?.UserRoleEnum !== UserRoleEnum.Support)
      ) {
        return UserRoleEnum.Administrator;
      } else if (
        !userDetails?.IsPrimaryContact &&
        userDetails?.UserRoles?.every(
          u => u.UserRoleEnum !== UserRoleEnum.Administrator && u.UserRoleEnum !== UserRoleEnum.Support
        )
      ) {
        return UserRoleEnum.Standard;
      } else if (
        !userDetails?.IsPrimaryContact &&
        userDetails?.UserRoles.some(u => u.UserRoleEnum === UserRoleEnum.Support)
      ) {
        return UserRoleEnum.Support;
      }
    }
    return UserRoleEnum.Support;
  };

  const isSupportUserRole =
    tenantInfo?.TenantUserDetails?.UserRoles?.find(
      role => role.Name === UserRoleTypes.SUPPORT || role.UserRoleEnum === UserRoleEnum.Support
    ) != undefined;

  const isPrimaryUser =
    tenantInfo?.TenantUserDetails?.UserRoles?.find(
      role => role.Name === UserRoleTypes.PRIMARY || role.UserRoleEnum === UserRoleEnum.Primary
    ) != undefined;

  const isAdminUser =
    tenantInfo?.TenantUserDetails?.UserRoles?.find(
      role => role.Name === UserRoleTypes.ADMIN || role.UserRoleEnum === UserRoleEnum.Administrator
    ) != undefined;

  return {
    isInSpendaFinanceContext,
    isInSupplierContext,
    isInBuyerContext,
    getUserRoleID,
    isLoading,
    isSupportUserRole,
    isAdminUser,
    isPrimaryUser,
    IsLendingProvider: tenantInfo?.TenantUserDetails?.IsLendingProvider,
  };
};
