import React, {useState, useEffect} from 'react';
import {FormikProps} from 'formik';
import clsx from 'clsx';

import {IPurchaseOrder} from '../../model/purchase-order/PurchaseOrder';
import {ISynkValidation} from '../../model/synkd/SynkResponse';
import {usePurchaseOrderDetailStyle} from '../../screens/purchase-order/PurchaseOrderDetail';
import {PrimaryButton, SecondaryButton} from '../buttons/DefaultButtons';
import {SDialog} from '../modals/modalSpendaMeterialUI';
import {STextField} from '../inputs/STextField';

import {DialogContent, DialogTitle, IconButton} from '@material-ui/core';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

interface IPurchaseOrderNotesDialog extends FormikProps<Partial<IPurchaseOrder>> {
  open: boolean;
  setOpen: (x: boolean) => void;
  savePurchaseOrder: (x: Partial<IPurchaseOrder>) => Promise<ISynkValidation>;
}

export const PurchaseOrderNotesDialog = (props: IPurchaseOrderNotesDialog) => {
  const {open, setOpen, values, setValues, savePurchaseOrder} = props;
  const classes = usePurchaseOrderDetailStyle();

  const [internalNotes, setInternalNotes] = useState<string>('');
  const [deliveryNotes, setDeliveryNotes] = useState<string>('');

  useEffect(() => {
    if (!open) {
      setInternalNotes('');
      setDeliveryNotes('');
    } else {
      setInternalNotes(values.InternalNote || '');
      setDeliveryNotes(values.ExternalNotes || '');
    }
  }, [open]);

  const handleSave = async () => {
    setValues({
      ...values,
      InternalNote: internalNotes,
      ExternalNotes: deliveryNotes,
    });
    await savePurchaseOrder({
      ...values,
      InternalNote: internalNotes,
      ExternalNotes: deliveryNotes,
    });
    setInternalNotes('');
    setDeliveryNotes('');
    setOpen(false);
  };

  return (
    <SDialog
      classes={{paper: 'relative w-[834px] h-[710px]'}}
      maxWidth="md"
      fullWidth
      open={Boolean(open)}
      onClose={() => setOpen(false)}
    >
      <DialogTitle className="border-b-[1px] border-b-gray-200">
        <p className="font-poppins text-center w-full">Add notes</p>
        <div className="absolute top-3 right-2 font-poppins">
          <IconButton
            disableRipple
            className={classes.backIconButton}
            data-autoid="btnSearch"
            onClick={() => setOpen(false)}
          >
            <CloseRoundedIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent classes={{root: clsx('mb-16 font-poppins')}}>
        <div className="max-w-[423px] mx-auto py-14">
          <p className="text-center text-xs text-spenda-primarytext mb-2">Internal notes</p>
          <STextField
            v2
            fullWidth
            multiline
            minRows={7}
            maxLength={200}
            id="InternalNotes"
            name="InternalNotes"
            variant="outlined"
            value={internalNotes}
            onChange={e => setInternalNotes(e.target.value)}
          />
          <p className="text-center text-xs text-spenda-primarytext mt-9 mb-2">Delivery notes</p>
          <STextField
            v2
            fullWidth
            multiline
            minRows={7}
            maxLength={200}
            id="DeliveryNotes"
            name="DeliveryNotes"
            variant="outlined"
            value={deliveryNotes}
            onChange={e => setDeliveryNotes(e.target.value)}
          />
        </div>
        <div className="absolute px-0.5" style={{bottom: 10, width: 'calc(100% - 32px)', left: '16px'}}>
          <div className="p-2 flex flex-row w-full" style={{backgroundColor: '#ADADAD50', borderRadius: '8px'}}>
            <SecondaryButton label="Cancel" onClick={() => setOpen(false)} />
            <div className="flex-1" />
            <PrimaryButton margin="0 0 0 8px" label="Save" onClick={() => handleSave()} />
          </div>
        </div>
      </DialogContent>
    </SDialog>
  );
};
