import React from 'react';
import {makeStyles, TooltipProps, Tooltip} from '@material-ui/core';
interface WidgetTooltipProps extends TooltipProps {
  backgroundColor?: string;
  textColor?: string;
  maxWidth?: string;
  fontSize?: string;
  fontWeight?: number;
  width?: string;
  margin?: string;
  textAlign?: 'center' | 'right' | 'left';
}

export const WidgetTooltip = (props: WidgetTooltipProps) => {
  const {
    backgroundColor = '#fff',
    textColor = '#333',
    maxWidth,
    fontSize = '10px',
    fontWeight = 500,
    textAlign = 'center',
    width = '70%',
    margin = '0px',
  } = props;
  const widgetTooltipStyles = makeStyles({
    arrow: {
      color: '#fff',
    },
    tooltip: {
      backgroundColor: backgroundColor,
      color: textColor,
      maxWidth: maxWidth ?? '',
      width: width,
      fontSize,
      fontWeight,
      fontFamily: 'poppins',
      textAlign,
      margin,
      boxShadow: '0px 2px 4px 2px rgba(0, 0, 0, 18%)',
    },
  });
  const classes = widgetTooltipStyles();
  return <Tooltip arrow classes={classes} {...props} />;
};
