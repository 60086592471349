import React, {useRef, useState} from 'react';
import {Formik, FormikProps} from 'formik';
import * as Yup from 'yup';
import {AlertDialogSlideV2} from './AlertDialogSlideV2';
import {Input} from 'spenda-ui-react';
import {AddAttributeDialog} from './AddAttributeDialog';

interface IAddVariantDialogProps {
  open?: boolean;
  onClose: () => void;
}

interface IVariantValues {
  variantName: string;
  abbrevation: string;
}

const validationSchema = Yup.object({
  variantName: Yup.string().required('Variant Name is required'),
  abbrevation: Yup.string().required('Abbreviation is required').max(3, 'Reached char limit'),
});

export const AddVariantDialog: React.FunctionComponent<IAddVariantDialogProps> = ({
  open,
  onClose,
}: IAddVariantDialogProps) => {
  const [openAttributeDialog, setOpenAttributeDialog] = useState<boolean>(false);
  const [variantName, setVariantName] = useState<string>('');
  const formRef = useRef<FormikProps<IVariantValues>>(null);
  const initialValues: IVariantValues = {variantName: '', abbrevation: ''};

  const handleCloseClick = (props: FormikProps<IVariantValues>, cancelClick: boolean = true) => {
    if (cancelClick && props.dirty) {
      setOpenAttributeDialog(true);
    } else {
      onClose();
    }
  };

  return (
    <>
      {open && (
        <Formik
          innerRef={formRef}
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={() => {}}
        >
          {({handleChange, handleSubmit, values, validateForm, touched, errors}) => (
            <AlertDialogSlideV2
              dialogClassess="!mx-5 !w-full !max-w-[496px]"
              dialogBodyClassess="h-[250px] flex flex-grow items-center justify-center"
              title="Add a new variation property"
              headingClassess="font-semibold justify-center"
              actions={[
                {
                  label: 'Cancel',
                  action: (props: FormikProps<IVariantValues>) => handleCloseClick(props),
                  variant: 'outlined',
                  className: 'mr-auto',
                },
                {
                  label: 'Save & Create',
                  variant: 'outlined',
                  className: 'ml-auto',
                  action: async () => {
                    const errors = await validateForm();
                    handleSubmit();
                    if (Object.keys(errors).length === 0) {
                      setVariantName(values.variantName);
                      setOpenAttributeDialog(true);
                    }
                  },
                },
                {
                  label: 'Continue',
                  type: 'submit',
                  className: 'ml-3',
                  action: async () => {
                    const errors = await validateForm();
                    handleSubmit();
                    if (Object.keys(errors).length === 0) {
                      setVariantName(values.variantName);
                      setOpenAttributeDialog(true);
                    }
                  },
                },
              ]}
              dialogActionsAlignment="justify-between"
            >
              <>
                <div className="flex items-center justify-center gap-4">
                  <Input
                    data-autoid={`txtVariantName`}
                    label="Variant Name"
                    type="text"
                    name="variantName"
                    placeholder=""
                    containerProps={{className: 'w-[345px] min-w-[345px]'}}
                    onChange={handleChange}
                    value={values.variantName}
                    helperText={touched.variantName && errors.variantName ? errors.variantName : ''}
                    error={Boolean(touched.variantName && errors.variantName)}
                  />
                  <Input
                    data-autoid={`txtAbbrevation`}
                    label="Abbrevation"
                    type="text"
                    name="abbrevation"
                    placeholder=""
                    containerProps={{className: 'w-[178px] min-w-[178px]'}}
                    onChange={handleChange}
                    helperText={touched.abbrevation && errors.abbrevation ? errors.abbrevation : ''}
                    error={Boolean(touched.abbrevation && errors.abbrevation)}
                  />
                </div>
              </>
            </AlertDialogSlideV2>
          )}
        </Formik>
      )}
      {openAttributeDialog && (
        <AddAttributeDialog open={openAttributeDialog} onClose={onClose} variantName={variantName} />
      )}
    </>
  );
};
