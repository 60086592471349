import React from 'react';

interface InvoiceDetailsFooterProps {
  isStickyFooter?: boolean;
  footer: React.ReactNode;
}

export const InvoiceDetailsFooter = ({isStickyFooter, footer}: InvoiceDetailsFooterProps) => {
  return (
    <footer
      className={`${isStickyFooter ? 'sticky' : 'fixed'} left-0 right-0`}
      style={{bottom: `${isStickyFooter ? '25px' : '52px'}`}}
    >
      <section className="sm:mx-4 py-2 px-2 bg-spenda-dashbg font-thin rounded-md shadow-lg">{footer}</section>
    </footer>
  );
};
