import React from 'react';

export const IconStockAvailable = (props: {width?: string; height?: string}) => (
  <svg
    width={props.width || '25px'}
    height={props.height || '23px'}
    viewBox="0 0 25 23"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>{'Available'}</title>
    <g id="Order-Listing" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <g
        id="Product-Management/Add-Products/Warehouse"
        transform="translate(-1322.000000, -664.000000)"
        fill="#1C78AD"
        fillRule="nonzero"
        stroke="#FFFFFF"
        strokeWidth={0.2}
      >
        <g id="Group" transform="translate(1318.000000, 659.000000)">
          <g id="Group" transform="translate(4.266667, 4.977778)">
            <polygon
              id="Path"
              points="4.55172927 16.2648597 3.28243763 14.9996891 2.27998571 16.002141 4.55172927 18.2656424 7.30975989 15.5137934 6.30730797 14.5123718"
            />
            <path
              d="M13.0432041,0.109647123 L24.0278741,5.05014514 L24.0278741,17.7043856 L13.0432116,22.6459012 L7.32430418,20.072754 C6.5946644,20.5419897 5.7268487,20.8196159 4.7948728,20.8196159 C3.50053384,20.8196159 2.32676484,20.2947361 1.47658751,19.4472989 C0.62657243,18.6000234 0.1,17.4302698 0.1,16.1401971 C0.1,14.6609741 0.796104789,13.3431449 1.87344799,12.4848355 L2.05648119,12.3460898 L2.05648119,5.05015007 L13.0432041,0.109647123 Z M22.8112824,5.46959674 L13.0452641,9.86232718 L3.2772224,5.46962302 L3.2772224,11.7121327 L3.51863618,11.636242 C3.92551843,11.5206241 4.35446162,11.4597481 4.79693334,11.4597481 C6.09130312,11.4597481 7.26484504,11.984656 8.1147689,12.8323879 C8.96449615,13.6799237 9.49077587,14.8501708 9.49077587,16.1412274 C9.49077587,17.3087772 9.05823796,18.3799571 8.34267458,19.2007687 L8.34267458,19.2007687 L13.043222,21.3136141 L22.8091816,16.92091 L22.8112824,5.46959674 Z M4.7948728,12.6753379 C3.83681954,12.6753379 2.96762771,13.0639763 2.33803968,13.6916431 C1.70860341,14.3191586 1.31868067,15.1855402 1.31868067,16.1412274 C1.31868067,17.0961874 1.70859892,17.9625811 2.33828684,18.5901553 C2.96769022,19.2174459 3.83661549,19.6060865 4.7948728,19.6060865 C5.75352876,19.6060865 6.62234244,19.217545 7.25159151,18.5904081 C7.8812983,17.9628151 8.27106493,17.0963185 8.27106493,16.1412274 C8.27106493,15.185939 7.88104251,14.3194456 7.25145138,13.6917757 C6.62188231,13.0641278 5.75279413,12.6753379 4.7948728,12.6753379 Z M13.0432107,1.44503577 L5.16504505,4.98648918 L13.0432107,8.52900577 L20.9203131,4.98648918 L13.0432107,1.44503577 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
