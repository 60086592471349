import React from 'react';
import {SModal} from '../modals/modalSpendaMeterialUI';
import {CommonPaymentPermission} from './CommonPaymentPermission';
export interface IPaymentPermissionProps {
  handleClose?: () => void;
  onBackPress?: (backStep?: number) => void;
  onNextClick?: () => void;
}

export const PaymentPermission: React.FunctionComponent<IPaymentPermissionProps> = ({
  handleClose,
  onBackPress,
  onNextClick,
}: IPaymentPermissionProps) => {
  return (
    <SModal open>
      <CommonPaymentPermission onBackPress={onBackPress} handleClose={handleClose} onNextClick={onNextClick} />
    </SModal>
  );
};
