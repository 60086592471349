import {useEffect, useState} from 'react';
import {DatTypes} from '../model/constants/Constants';
import {Inventory, ChildVariants, ISupplierInventory} from '../model/inventory/Inventory';
import {InventorySOH} from '../model/inventory/InventorySOH';
import {IActionResults, IPagedActionResults, IDashboardActionResults} from '../model/ActionResults';
import {IProductsFilter} from '../model/search-filters/ProductsFilter';
import useHttp from '../hooks/useHttp';

export const useInventoryAPI = () => {
  const {GET, PUT, POST, isLoading} = useHttp();

  const dashboard = (filter: any): Promise<IDashboardActionResults<Inventory[]>> => {
    return GET('dashboard', filter).then((data: IDashboardActionResults<Inventory[]>) => data);
  };

  const listInventory = (filter: any): Promise<IPagedActionResults<Inventory[]>> => {
    return GET('inventory', filter).then((data: IPagedActionResults<Inventory[]>) => data);
  };

  const createInventory = (payload: Partial<Inventory>): Promise<IActionResults<Inventory>> => {
    return POST('inventory', {Object: payload}).then((data: IActionResults<Inventory>) => data);
  };

  const getInventoryById = (ID: string): Promise<Inventory> => {
    return GET(`inventory/${ID}`).then((data: IActionResults<Inventory>) => data.Value);
  };

  const getInventorySOH = (ID: string): Promise<InventorySOH> => {
    return GET(`v2/inventory/${ID}/stockonhand`).then((data: IActionResults<InventorySOH>) => data.Value);
  };

  const updateInventory = (payload: Partial<Inventory>): Promise<IActionResults<Inventory>> => {
    return PUT(`inventory/${payload.ID}`, {Object: payload}).then((data: IActionResults<Inventory>) => data);
  };

  const inventoryCodeUnique = (filter: any): Promise<IActionResults<any>> => {
    return GET('v2/inventory/IsInventoryCodeUnique', filter).then((data: IActionResults<any>) => data);
  };

  const getInventoryVariants = (ID: string): Promise<IActionResults<ChildVariants[]>> => {
    return GET(`inventory/${ID}/variants`).then((data: IActionResults<ChildVariants[]>) => data);
  };

  const checkVariantsCanUnlink = (ID: string): Promise<IActionResults<boolean>> => {
    return GET(`inventory/${ID}/canunlink`).then((data: IActionResults<boolean>) => data);
  };

  const unlinkVariants = (ID: string): Promise<boolean> => {
    return POST(`inventory/${ID}/unlinkvariants`, {}).then((data: IActionResults<any>) => data.IsSuccess);
  };

  const linkVariants = (ID: string, payload: any): Promise<boolean> => {
    return POST(`inventory/${ID}/linkvariant`, {Value: payload}).then((data: IActionResults<any>) => data.IsSuccess);
  };

  const listInventoryByCode = async (filter: any): Promise<IPagedActionResults<Inventory[]>> => {
    const data = await GET('inventory/code', filter);
    return data;
  };

  const supplierInventory = (filter: any): Promise<IPagedActionResults<ISupplierInventory[]>> => {
    return GET('supplierinventory', filter).then((data: IPagedActionResults<ISupplierInventory[]>) => data);
  };

  return {
    dashboard,
    listInventory,
    createInventory,
    getInventoryById,
    getInventorySOH,
    updateInventory,
    inventoryCodeUnique,
    getInventoryVariants,
    checkVariantsCanUnlink,
    unlinkVariants,
    linkVariants,
    listInventoryByCode,
    supplierInventory,
    isLoading,
  };
};

export const useLoadInventory = () => {
  let pageSize = 15;

  const initialFilterState = {
    DatTypeID: DatTypes.Inventory,
    IsExactMatch: false,
    IsShowActiveOnly: true,
    IsShowChildVariants: false,
    LastRowNumber: 0,
    MaxResults: pageSize,
    SortAsc: false,
    SortField: 'CreatedDateTime',
    IsSystem: false,
  } as IProductsFilter;

  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<Inventory[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [moreToGet, setMoreToGet] = useState<boolean>(true);
  const [error, setError] = useState<any>();
  const [searchFilter, setSearchFilter] = useState<any>(initialFilterState);

  const {dashboard} = useInventoryAPI();

  const getInventoryList = async () => {
    setLoading(true);

    if (searchFilter.StartRow === 1) {
      setItems([]);
      setMoreToGet(true);
    }

    try {
      const {Value, IsMore, TotalRecordCount, LastRowNo} = await dashboard(searchFilter);
      let items: Inventory[] = Value || [];

      setItems(items);
      setTotalCount(TotalRecordCount);
      setMoreToGet(IsMore);
      setCurrentPage(Math.ceil(LastRowNo / (searchFilter.MaxResults || pageSize)));
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getInventoryList();
  }, [searchFilter]);

  return {
    loading,
    items,
    totalCount,
    pageSize: searchFilter.MaxResults || pageSize,
    currentPage,
    moreToGet,
    error,
    searchFilter,
    getInventoryList,
    setSearchFilter,
  };
};
