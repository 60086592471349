import {withStyles, Tooltip} from '@material-ui/core';

const ARTooltip = withStyles({
  tooltip: {
    padding: '10px',
    boxSizing: 'border-box',
    background: '#fff',
    border: '1px solid #fafafa',
    zIndex: 999999,
    minWidth: '100px',
    maxWidth: '220px',
    width: 'auto',
    boxShadow: '0px 0px 4px 0 rgb(0 0 0 / 20%)',
    borderRadius: '3px',
  },
  arrow: {
    color: '#fff',
  },
})(Tooltip);

export default ARTooltip;
