import React, {useContext, useEffect, useMemo, useState} from 'react';
import {Layout} from '../../../components/layout/Layout';
import {Badge, Button, IconButton, Menu, MenuHandler, MenuList, Typography} from 'spenda-ui-react';
import {QuoteServiceDialog} from '../../../components/dialog/QuoteServiceDialog';
import {useFeatureFlags} from '../../../hooks/useFeatureFlags';
import useQuotesAPI from '../../../services/useQuotesAPI';
import QuoteVariantDetails from './QuoteVariantDetails';
import {DatTypes, QuoteStatus} from '../../../model/constants/Constants';
import {QuoteRejectDialog} from '../../../components/dialog/QuoteRejectDialog';
import QuoteContext, {useQuoteContext} from '../../../context/quote-context/QuoteContext';
import QuoteContextProvider from '../../../context/quote-context/QuoteContextProvider';
import {IQuoteAttachment, IQuotesPackage} from '../../../model/quotes/quotes';
import {IQuotes} from '../../../model/quotes/quotes';
import {useHistory} from 'react-router-dom';
import {AUTH_SELLING_QUOTES_PREVIEW} from '../../../routes/QuoteManagementRoutes';
import {CreateServiceJobModal} from '../../../components/dialog/CreateServiceJobModal';
import useServiceJobAPI from '../../../services/useServiceJobAPI';
import {ServiceJobScope} from '../../../model/service-management/serviceJob';
import {ServiceContextProvider} from '../../../context/serviceManagement/ServiceContextProvider';
import QuoteCreateRightPanel from '../../../components/form/QuoteCreateRightPanel';
import QuoteDetailsCardV2 from '../../../components/quote-management/QuoteDetailsCardV2';
import _ from 'lodash';
import {AttachDocs} from '../../../assets/svg/AttachDocs';
import {QuoteAttachmentDialog} from '../../../components/dialog';
import {QuoteDepositDialog} from '../../../components/dialog/QuoteDepositDialog';

export const QuoteDetailPackageV2 = () => {
  return (
    <QuoteContextProvider>
      <QuotesDetailV2 />
    </QuoteContextProvider>
  );
};

const QuotesDetailV2 = () => {
  const {isCollapseRightPanal} = useQuoteContext();
  // States
  const [quotePackage, setQuotesPackage] = useState<IQuotesPackage>();
  const [selectedQuoteDetails, setSelectedQuoteDetails] = useState<IQuotes>();
  const {serviceManagementV1} = useFeatureFlags().tenantOwned();

  const {setSelectedQuoteVariantID, setQuotePackage, selectedQuoteVariantID} = useQuoteContext();

  useEffect(() => {
    if (quotePackage) {
        const index = quotePackage?.quotes?.findIndex(q => q.quoteID === selectedQuoteVariantID);
        if (index !== -1) {
            setSelectedQuoteVariantID?.(selectedQuoteVariantID!);
        }
    }
}, [quotePackage, selectedQuoteVariantID]);

  const handleSelectedQuoteVariant = (quoteID: number): Promise<void> => {
    const quotePkg = quotePackage && {...quotePackage};
    const index = quotePkg?.quotes?.findIndex(q => q.quoteID == selectedQuoteVariantID);

    if (index !== -1 && quotePkg?.quotes?.length) {
        setQuotePackage?.(quotePkg);
    }
    setSelectedQuoteVariantID?.(quoteID);
    return Promise.resolve();
};

  const _rightPanelV2 = (
    <div className="flex h-[calc(100vh-120px)] flex-col justify-between rounded bg-white">
      <QuoteCreateRightPanel isSaving={false} isQuoteSent={true} handleSelectedQuoteVariant={handleSelectedQuoteVariant} />
    </div>
  );

  const showActionJobs = useMemo(
    () =>
      (serviceManagementV1 &&
        quotePackage?.status === QuoteStatus.Approved &&
        selectedQuoteDetails?.status === QuoteStatus.Approved &&
        ((selectedQuoteDetails?.serviceJobID == null && !selectedQuoteDetails?.linkedTransactions?.length) ||
          (selectedQuoteDetails?.serviceJobID &&
            !selectedQuoteDetails?.linkedTransactions?.filter(trans => trans.id != selectedQuoteDetails?.serviceJobID)
              ?.length))) ||
      (quotePackage?.status === QuoteStatus.Draft && selectedQuoteDetails?.status === QuoteStatus.Draft),
    [serviceManagementV1, quotePackage, selectedQuoteDetails],
  );

  const _QuoteDetailV2 = (
    <>
      <QuoteDetailsCardV2
        selectedQuoteDetails={selectedQuoteDetails}
        isShowActionJobs={showActionJobs}
        isQuoteDetailV2={true}
      />
      <QuotesDetailTable
        selectedQuoteDetails={selectedQuoteDetails}
        selectedVariant={selectedQuoteVariantID!}
        setSelectedQuoteDetails={setSelectedQuoteDetails}
        setQuotePackage={setQuotesPackage}
        quotePackageState={quotePackage}
        setSelectedVariant={setSelectedQuoteVariantID!}
        isShowActionJobs={showActionJobs}
      />
    </>
  );

  return (
    <div className={`relative h-full overflow-hidden bg-primary/5 font-poppins`}>
      <Layout
        duration={0}
        leftPanel={_QuoteDetailV2}
        mainPanel={_rightPanelV2}
        splitWidthType={isCollapseRightPanal ? 4 : 'MP'}
      />
    </div>
  );
};

interface IQuotesDetailTable {
  selectedQuoteDetails?: IQuotes;
  selectedVariant: number;
  setSelectedVariant: React.Dispatch<React.SetStateAction<number>>;
  setSelectedQuoteDetails: React.Dispatch<React.SetStateAction<IQuotes>>;
  setQuotePackage?: React.Dispatch<React.SetStateAction<IQuotesPackage>>;
  quotePackageState?: IQuotesPackage;
  isShowActionJobs: boolean;
}

const QuotesDetailTable = (props: IQuotesDetailTable) => {
  const {
    selectedQuoteDetails,
    setSelectedQuoteDetails,
    setQuotePackage,
    quotePackageState,
    selectedVariant,
    setSelectedVariant,
    isShowActionJobs,
  } = props;

  const {refreshQuotePackage, quotePackage, isCollapseRightPanal} = useContext(QuoteContext);
  const {serviceManagementV1} = useFeatureFlags().tenantOwned();
  const {acceptQuote, rejectQuote, getQuoteAttachments} = useQuotesAPI();
  const {createJobFromQuote, isLoading: serviceLoading} = useServiceJobAPI();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showRejectQuoteDialog, setShowRejectQuoteDialog] = useState<boolean>(false);
  const history = useHistory();
  const [showServiceJobModal, setShowServiceJobModal] = useState<boolean>(false);
  const [serviceJobId, setServiceJobId] = useState<number>();
  const [quoteAttachments, setQuoteAttachments] = useState<IQuoteAttachment[]>([]);
  const [openAttachmentDialog, setOpenAttachmentDialog] = useState<boolean>(false);
  const [openDepositDialog, setOpenDepositDialog] = useState<boolean>(false);

  useEffect(() => {
    if (selectedVariant !== -1 && quotePackage) {
      const selQuote = quotePackage?.quotes?.find(quote => quote.quoteID === selectedVariant);
      if (selQuote) {
        setSelectedQuoteDetails(selQuote);
      }
    }
  });

  useEffect(() => {
    if (!quotePackage) return;
    if (quotePackage.status === QuoteStatus.Approved) {
      const approvedQuote = quotePackage?.quotes?.find(quote => quote.status === QuoteStatus.Approved);
      if (approvedQuote) {
        quotePackage.quotes = _.sortBy(quotePackage.quotes, obj => {
          return obj.quoteID === approvedQuote?.quoteID ? 0 : 1;
        });
        setSelectedVariant(approvedQuote?.quoteID!);
        setSelectedQuoteDetails(approvedQuote);
      }
    } else if (!quotePackageState && quotePackage?.quotes && quotePackage?.quotes.length > 0) {
      setSelectedVariant(quotePackage?.quotes[0]?.quoteID!);
      setSelectedQuoteDetails(quotePackage?.quotes[0]);
    } else if (quotePackageState && selectedQuoteDetails) {
      const latestQuote = quotePackage?.quotes?.find(quote => selectedQuoteDetails.quoteID === quote.quoteID);
      if (latestQuote) {
        setSelectedVariant(latestQuote?.quoteID!);
        setSelectedQuoteDetails(latestQuote);
      }
    }
    setQuotePackage && setQuotePackage(quotePackage);
  }, [quotePackage]);

  const handleAcceptQuote = async () => {
    if (selectedQuoteDetails && selectedQuoteDetails.quoteID) {
      await acceptQuote(selectedQuoteDetails.quoteID);
      await refreshQuotePackage();
    }
    setIsLoading(false);
  };

  const handleRejectQuote = async (rejectReason?: string) => {
    setIsLoading(true);
    if (selectedQuoteDetails && selectedQuoteDetails.quoteID) {
      await rejectQuote(selectedQuoteDetails.quoteID, rejectReason);
      await refreshQuotePackage();
    }
    setIsLoading(false);
  };

  const handleIsDialogOpen = () => {
    setIsDialogOpen(true);
  };

  const fetchAttachments = async () => {
    if (quoteAttachments.length === 0 && selectedQuoteDetails?.countAttachments) {
      const attachments = await getQuoteAttachments(selectedQuoteDetails?.quoteID!);
      setQuoteAttachments(attachments!);
    }
    setOpenAttachmentDialog(!openAttachmentDialog);
  };

  return (
    <div className={`${isShowActionJobs ? 'h-[calc(100vh-290px)]' : 'h-[calc(100vh-290px)]'} rounded bg-white p-2`}>
      <div className="mb-2.5 flex min-h-[51px] w-full items-center justify-between border-b border-b-[#ECECEC] pb-2.5">
        {selectedQuoteDetails && (
          <div className="flex flex-row">
            <Typography variant="h2">
              {`${selectedQuoteDetails?.name}`} - {selectedQuoteDetails?.description}
            </Typography>
          </div>
        )}
        <div className="pr-2">
          <Badge
            className="error h-[22px] min-h-[20px] w-[22px] min-w-[20px]"
            color="error"
            invisible={selectedQuoteDetails?.countAttachments === 0}
            content={selectedQuoteDetails?.countAttachments}
          >
            <IconButton variant="outlined" name="attachment" onClick={fetchAttachments}>
              <AttachDocs className="-rotate-45" width={22} height={22} />
            </IconButton>
          </Badge>
        </div>
      </div>
      <div className="my-2 h-0.5 w-full">
        {selectedQuoteDetails && (
          <QuoteVariantDetails selectedQuoteDetails={selectedQuoteDetails} showActionJobs={isShowActionJobs} />
        )}
        {isShowActionJobs && (
          <div
            className={`fixed bottom-5 flex ${!isCollapseRightPanal ? 'w-[calc(100%-357px)]' : 'w-[calc(100%-44px)]'}  items-center justify-between rounded-lg bg-[#ECECEC]`}
          >
            <div className="m-2.5 flex gap-x-2.5">
              {selectedQuoteDetails?.status === QuoteStatus.Draft && quotePackage?.isSent && (
                <Button
                  variant="outlined"
                  className="bg-[#FFFFFF]"
                  onClick={() => {
                    history.push(`${AUTH_SELLING_QUOTES_PREVIEW}${quotePackage?.quotePackageID}`, {isResend: true});
                  }}
                >
                  Resend Quote
                </Button>
              )}
            </div>
            <div className="m-2.5 flex gap-x-2.5">
              {quotePackage?.status === QuoteStatus.Draft && selectedQuoteDetails?.status === QuoteStatus.Draft && (
                <Button
                  variant="outlined"
                  disabled={isLoading}
                  className="bg-[#FFFFFF]"
                  onClick={() => {
                    setOpenDepositDialog(true);
                  }}
                >
                  Accept for Customer
                </Button>
              )}
              {isShowActionJobs && (
                <Menu
                  dismiss={{
                    itemPress: false,
                  }}
                  open={isMenuOpen}
                  handler={() => {
                    if (!isMenuOpen && isLoading) return;
                    setIsMenuOpen(!isMenuOpen);
                  }}
                  placement="bottom-end"
                >
                  <MenuHandler className="flex h-[40px] w-[200px] cursor-pointer items-center justify-center gap-[1.25rem] rounded-md bg-primary pl-4">
                    <div>
                      <Typography data-autoid={'txtActionQuote'} className="text-center font-semibold text-white">
                        Action Quote
                      </Typography>
                      <div className="flex items-center justify-end gap-2 ">
                        <span className="border border-y-[10px] border-[#fff]"></span>
                        <div className={`${isMenuOpen ? 'rotate-180' : 'rotate-0'}`} data-autoid={`imgActionQuote`}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
                            <path
                              fill="#fff"
                              fillRule="evenodd"
                              d="M4.2889 5.4688h7.4533a.6666.6666 0 0 1 .4667 1.14l-3.72 3.7199a.6663.6663 0 0 1-.9467 0l-3.72-3.72a.6667.6667 0 0 1 .4667-1.14Z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </MenuHandler>
                  {isMenuOpen && (
                    <MenuList className="w-[200px] overflow-hidden border-primary p-0 ">
                      {serviceManagementV1 &&
                        quotePackage?.status === QuoteStatus.Approved &&
                        selectedQuoteDetails?.status === QuoteStatus.Approved &&
                        ((selectedQuoteDetails?.serviceJobID == null &&
                          !selectedQuoteDetails?.linkedTransactions?.length) ||
                          (selectedQuoteDetails?.serviceJobID &&
                            !selectedQuoteDetails?.linkedTransactions?.filter(
                              trans =>
                                trans.id != selectedQuoteDetails?.serviceJobID && trans.datTypeID != DatTypes.Invoice,
                            )?.length)) && (
                          <li
                            onClick={() => handleIsDialogOpen()}
                            data-autoid={'liCreateServiceJob'}
                            className="w-full cursor-pointer list-none border-b border-[#ECECEC] px-3 py-2  text-left text-base  font-semibold text-black-800 hover:bg-[#E3EEF5]"
                          >
                            Create a service job
                          </li>
                        )}
                      {quotePackage?.status === QuoteStatus.Draft &&
                        selectedQuoteDetails?.status === QuoteStatus.Draft && (
                          <li
                            className="w-full cursor-pointer list-none border-b border-[#ECECEC]  px-3 py-2  text-left text-base  font-semibold text-black-800 hover:bg-[#E3EEF5]"
                            onClick={() => setShowRejectQuoteDialog(true)}
                            data-autoid={'liRejectQuote'}
                          >
                            Reject quote
                          </li>
                        )}
                      {isDialogOpen && (
                        <QuoteServiceDialog
                          handleGoBack={() => setIsDialogOpen(!isDialogOpen)}
                          handleCreate={async () => {
                            // create service job from quote
                            const serviceJobResponse = await createJobFromQuote(selectedQuoteDetails?.quoteID!);
                            setServiceJobId(serviceJobResponse.serviceJobID);
                            setShowServiceJobModal(true);
                          }}
                          isLoading={serviceLoading}
                        />
                      )}
                    </MenuList>
                  )}
                </Menu>
              )}
            </div>
          </div>
        )}
      </div>

      {openAttachmentDialog && (
        <QuoteAttachmentDialog
          open={openAttachmentDialog}
          customerView
          handleOpenAndClose={setOpenAttachmentDialog}
          uploadedAttachments={quoteAttachments}
        />
      )}

      {showRejectQuoteDialog && (
        <QuoteRejectDialog
          handleRejectQuote={handleRejectQuote}
          handleCancel={() => setShowRejectQuoteDialog(false)}
          isLoading={isLoading}
          quoteName={selectedQuoteDetails?.name}
        />
      )}

      {openDepositDialog && (
        <QuoteDepositDialog
          open
          handleSave={() => {
            setIsLoading(true);
            handleAcceptQuote();
            setOpenDepositDialog(false);
          }}
          handleClose={()=> setOpenDepositDialog(false)}
        />
      )}

      {showServiceJobModal && (
        <ServiceContextProvider>
          <CreateServiceJobModal
            open={showServiceJobModal}
            handleClose={() => {
              setShowServiceJobModal(false);
              setIsDialogOpen(false);
              refreshQuotePackage();
            }}
            scope={ServiceJobScope.QuoteDetails}
            serviceJobId={serviceJobId}
          />
        </ServiceContextProvider>
      )}
    </div>
  );
};
export default QuotesDetailV2;
