import {Box} from '@material-ui/core';
import React, {useContext, useEffect} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import LoadingIndicator from '../../components/ui/LoadingIndicator';
import AppContext from '../../context/app/appContext';
import {useAuth0Callback} from '../../hooks/useAuth0Callback';
import useConnectedSupplier from '../../hooks/useConnectedSupplier';
import {useSupplierTransactionsAPI} from '../../services/useSupplierTransactionsAPI';
import useTenantAPI from '../../services/useTenantAPI';
import PublicShell from './PublicShell';
import {ModuleTypes} from '../../model/constants/Constants';
import {useFeatureFlags} from '../../hooks/useFeatureFlags';
import {AUTH_BUYING_AP_REQUIRES_ATTENTION_ROUTE} from '../../routes/AccountsPayableRoutes';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const CustomerInvitation = () => {
  const query = useQuery();
  const history = useHistory();
  const {loginWithAuth0Redirect} = useAuth0Callback();
  const {T2TPhase280729} = useFeatureFlags().tenantOwned();
  const {validateInvitation} = useSupplierTransactionsAPI();
  const {getRedirectPath} = useConnectedSupplier();
  const {matchAndLink} = useTenantAPI();
  const {setStatementInvitation, user, suppliers, setConnectedSuppliers, tenantInfo} = useContext(AppContext);

  useEffect(() => {
    setStatementInvitation(undefined); // Clear context
    const magicLinkQryStr = query.get('statementid') || '';

    async function attemptToAutoMatchAndLink(magicLink?: string) {
      if (magicLink) {
        const statementIdValidationResponse = await validateInvitation(magicLink);
        if (!statementIdValidationResponse || !statementIdValidationResponse.IsSuccess) {
          history.push('/');
          return;
        }

        const responseValue = statementIdValidationResponse.Value;

        if (responseValue.IsExistingUser) {
          if (user) {
            // Already logged In
            const res = await matchAndLink(magicLink);

            if (res.IsSuccess) {
              const newConnectedSupplier = res.Value;
              if (!newConnectedSupplier) {
                console.error('No Connected Supplier');
                return;
              }

              // There's a new connected supplier, update list
              setConnectedSuppliers([...suppliers, newConnectedSupplier]);

              // Redirect buyer to the supplier's bills view (SpendaCollectCustomerPortal/PSBL)
              let redirectPath = getRedirectPath(newConnectedSupplier);

              // Check if tenant needs to complete registration
              if (!tenantInfo?.TenantUserDetails.AgreedTsAndCs) {
                // TODO JB: This is a hacky way to get prepopulate the tenant's details while completing their regio. will be removed once <TenantSetup /> is included in <Layout/ >
                redirectPath = `${redirectPath}?dialog=2&fname=${responseValue.PrimaryContact.FirstName}&lname=${responseValue.PrimaryContact?.LastName}&tname=${responseValue.PrimaryContact?.CompanyName}&tabn=${responseValue.PrimaryContact?.ABN}`;
              }
              // check if manage invoices value is null
              if (
                newConnectedSupplier.SupplierID &&
                T2TPhase280729 &&
                user?.Modules?.includes(ModuleTypes.SpendaPay.toString()) &&
                user?.Modules?.includes(ModuleTypes.Suppliers.toString())
              ) {
                if (!newConnectedSupplier.IsAutomaticInvoiceImportSetup) {
                  // show manage invoice dialog
                  redirectPath = `${redirectPath}?dialog=1`;
                } else if (newConnectedSupplier.IsAutomaticInvoiceImportEnabled) {
                  redirectPath = `${AUTH_BUYING_AP_REQUIRES_ATTENTION_ROUTE}`;
                }
              }
              history.push(redirectPath);
            } else {
              // Existing user, who's logged in and couldn't be auto match & link
              console.error('User couldnt be matched and linked');
              history.push('/');
            }
          } else {
            loginWithAuth0Redirect({
              redirectUri: `/statement?statementid=${magicLinkQryStr}`,
              screen_hint: 'login',
              login_hint: responseValue.EmailAddress,
            });
          }
        } else {
          if (responseValue.HasInvitationBeenConsumedByAnotherUser) {
            history.push(responseValue.MarketplacePath ? responseValue.MarketplacePath : '/', {
              displayExpiredInviteDialog: true,
              supplierName: responseValue.SupplierName,
            });
          } else {
            // Go Register
            loginWithAuth0Redirect({
              redirectUri: `/statement?statementid=${magicLinkQryStr}`,
              screen_hint: 'signup',
              login_hint: responseValue.EmailAddress,
            });
          }
        }
      } else {
        history.push('/');
      }
    }

    attemptToAutoMatchAndLink(magicLinkQryStr);
  }, []);

  return (
    <PublicShell>
      <Box display="flex">
        <LoadingIndicator isLoading={true} position={{position: 'relative'}} size="md" />
        <p className={`ml-2 text-primary`}>Spenda Customer Validation/Authentication...</p>
      </Box>
    </PublicShell>
  );
};
