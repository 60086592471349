import * as React from 'react';
const APOnbClearingAccountIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg {...props}>
    <path
      fill="hsl(var(--primary))"
      fillRule="evenodd"
      d="M15.833 13.734H4.167A4.172 4.172 0 0 1 0 9.567v-5A4.172 4.172 0 0 1 4.167.4h11.666A4.172 4.172 0 0 1 20 4.567v5a4.172 4.172 0 0 1-4.167 4.167ZM4.167 2.067a2.5 2.5 0 0 0-2.5 2.5v5a2.5 2.5 0 0 0 2.5 2.5h11.666a2.5 2.5 0 0 0 2.5-2.5v-5a2.5 2.5 0 0 0-2.5-2.5H4.167ZM10 10.4a3.333 3.333 0 1 1 0-6.666 3.333 3.333 0 0 1 0 6.666Zm0-5a1.667 1.667 0 1 0 0 3.334A1.667 1.667 0 0 0 10 5.4ZM4.167 3.734a.833.833 0 1 0 0 1.666.833.833 0 0 0 0-1.666ZM15 4.567a.833.833 0 1 0 1.667 0 .833.833 0 0 0-1.667 0ZM4.167 8.734a.833.833 0 1 0 0 1.666.833.833 0 0 0 0-1.666ZM15 9.567a.833.833 0 1 0 1.667 0 .833.833 0 0 0-1.667 0Z"
      clipRule="evenodd"
    />
  </svg>
);
export default APOnbClearingAccountIcon;
