import React, {useContext, useState} from 'react';

import {PaymentWidgetContext, PaymentWidgetPaymentSteps, PaymentWidgetStep} from './PaymentWidget';
import {IAddBankAccountDetailsRequest, INewAccountResponse} from '../../../services/useSpendaPaymentServicesAPI';
import {AddBankAccountForm, Footer} from '../../../components/dialog/AddBankAccountDialog';
import {usePaymentsFromBuyer} from './usePaymentsFromBuyer';
import {Toast} from '../../../utils/Toast';
import {PaymentWidgetScope} from '../../../model/constants/Constants';

export const PaymentWidgetStepAddBankAccount = () => {
  const [newBankAccount] = useState<INewAccountResponse | undefined>(undefined);

  const {setStep, setBpspAccountDetails, onCancel, tenantName, userEmailAddress, widgetScope} =
    useContext(PaymentWidgetContext);
  const bankAccountRef = React.useRef(newBankAccount);
  const {addBankAccountDetails, fetchSPSAccount, isLoadingSpendaPaymentServices} = usePaymentsFromBuyer();

  const onClose = () => {
    if (widgetScope === PaymentWidgetScope.ADD_BANK_ACCOUNT) {
      if (onCancel) onCancel();

      return;
    }

    if (setStep) setStep(PaymentWidgetPaymentSteps.NEW);
  };

  const paymentMethodWidgetScope = [
    PaymentWidgetScope.CONFIGURE_PAYMENT_OPTIONS,
    PaymentWidgetScope.ADD_BANK_ACCOUNT,
  ].includes(widgetScope!);

  const onSubmitAddBankAccount = async (payload: IAddBankAccountDetailsRequest) => {
    try {
      const result = await addBankAccountDetails(payload);
      if (result.error) {
        Toast.error(result.error.message);
        if (setStep && !paymentMethodWidgetScope) setStep(PaymentWidgetPaymentSteps.NEW);
      } else {
        bankAccountRef.current = result;
        updateBankAccount();
      }
    } catch (error) {
      if (setStep) setStep(PaymentWidgetPaymentSteps.NEW);
      Toast.error('Some Error Occured');
    }
  };

  const updateBankAccount = async () => {
    if (bankAccountRef.current) {
      const accountGUID = bankAccountRef.current.accountGUID;
      return fetchSPSAccount(accountGUID).then(res => {
        if (setStep) {
          setStep(PaymentWidgetPaymentSteps.VERIFY_CREDIT_CARD);
        }
        if (setBpspAccountDetails) {
          setBpspAccountDetails(res);
        }
      });
    }
  };

  const title = 'Enter your bank account details';

  const body = (
    <div className="px-6 overflow-y-auto text-left">
      <AddBankAccountForm
        onSubmit={onSubmitAddBankAccount}
        userName={userEmailAddress || ''}
        tenantName={tenantName || ''}
      />
    </div>
  );

  const footer = <Footer onClose={onClose} isLoading={isLoadingSpendaPaymentServices} />;

  return <PaymentWidgetStep title={title} body={body} footer={footer} titleType="bank" />;
};
