import * as React from 'react';
const APUploadIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg {...props}>
    <path
      fill="#1C78AD"
      d="M45.84 15.344c-.01-.453-.04-.901-.088-1.344h2.982C54.956 14 60 19 60 25.169c0 6.168-5.044 11.169-11.266 11.169H11.266C5.044 36.338 0 31.338 0 25.169 0 19 5.044 14 11.266 14h2.982c-.054.496-.084.998-.09 1.506C14.266 6.923 21.317 0 30 0c8.628 0 15.645 6.837 15.84 15.344Z"
      opacity=".1"
    />
    <g fill="#1C78AD" fillRule="evenodd" clipRule="evenodd" opacity=".2">
      <path d="M36.694 28.252H22.806A1.8 1.8 0 0 1 21 26.462V21.46c0-.399.326-.722.729-.722.402 0 .729.323.729.722v5.002c0 .19.156.345.348.345h13.888a.455.455 0 0 0 .457-.453V21.46c0-.399.326-.722.729-.722.402 0 .728.323.728.722v4.894a1.908 1.908 0 0 1-1.914 1.898ZM26.093 18.046a.73.73 0 0 1-.52-.216.718.718 0 0 1 .009-1.022l3.737-3.642a.733.733 0 0 1 1.025.004l3.674 3.643a.718.718 0 0 1 0 1.021.733.733 0 0 1-1.03 0L29.825 14.7l-3.221 3.139a.73.73 0 0 1-.511.207Z" />
      <path d="M29.798 23.794a.726.726 0 0 1-.73-.723v-9.389c0-.4.327-.723.73-.723.402 0 .729.324.729.723v9.39a.726.726 0 0 1-.73.722Z" />
    </g>
  </svg>
);
export default APUploadIcon;
