import * as React from 'react';
export const GreenTick = (props: any) => (
  <svg width={16} height={16} fill="none" {...props}>
    <path
      fill="#1E9D71"
      fillRule="evenodd"
      d="M13.544 1.316a1.309 1.309 0 0 1 1.781-.51c.588.326.827 1.038.577 1.646l-.067.138-6.69 12.077a1.309 1.309 0 0 1-1.774.514l-.13-.082-6.69-4.77a1.315 1.315 0 0 1-.308-1.832 1.309 1.309 0 0 1 1.687-.395l.14.087 5.496 3.919 5.978-10.792Z"
      clipRule="evenodd"
    />
  </svg>
);
