import React, {useState} from 'react';
import {FormikProps} from 'formik';

import {InventoryClass} from '../../model/inventory/InventoryClass';
import {ICategory} from '../../model/inventory/Category';
import {IBrand} from '../../model/inventory/Brand';
import {STextField} from '../inputs/STextField';
import {
  CategoryAutocomplete,
  BrandAutocomplete,
  InventoryClassAutocomplete,
  UoMDropDown,
  SupplierAutocomplete,
} from '../inventory/InventoryAutocomplete';
import {CategoryDialog} from '../dialog/CategoryDialog';
import {ToggleSwitch} from '../buttons/ToggleSwitch';
import {initialCategoryData} from '../../screens/product-mangement/CategoriesList';

import UnitofMeasure from '../../assets/data/UnitofMeasure.json';

import {Box, Grid, Divider, Radio, RadioGroup, FormControlLabel, makeStyles} from '@material-ui/core';

const useModifyInventoryFormStyles = makeStyles(() => ({
  formLabel: {
    fontSize: '1rem',
    color: '#333333',
    paddingBottom: '1.5rem',
  },
  title: {
    fontWeight: 500,
    fontSize: '1.25rem',
    color: '#4D4D4D',
  },
  radioLabel: {
    color: '#333333 !important',
    fontSize: '1rem',
  },
  radioIcon: {
    color: '#1C78AD !important',
    fontSize: '1rem',
  },
}));

export type ModifyInventoryValues = {
  IsSohTracked?: boolean;
  IsPhysical?: boolean;
  IsPublished?: boolean;
  IsSold?: boolean;
  IsPurchased?: boolean;
  IsApproved?: boolean;
  UoM: string;
  UoMDescription: string;
  Quantity: string;
  IsRemoveFromCollection: boolean;
  BrandID?: IBrand;
  CategoryID?: ICategory;
  InventoryClassID?: InventoryClass;
};

export const ModifyInventoryForm = (props: FormikProps<ModifyInventoryValues>) => {
  const classes = useModifyInventoryFormStyles();
  const {values, errors, touched, handleChange, setFieldValue} = props;

  const [openCategory, setOpenCategory] = useState<boolean>(false);

  const handleAddNewCategory = () => {
    setOpenCategory(true);
  };

  const handleQuantityBlur = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.target) {
      const uom = UnitofMeasure.find((uom: {name: string; value: string}) => uom.value === values.UoM);
      setFieldValue('UoMDescription', `${event.target.value} ${uom?.name.split(' - ')[1]}`);
    }
  };

  const setUoM = (selected: string | undefined) => {
    setFieldValue('UoM', selected);
    const uom = UnitofMeasure.find((uom: {name: string; value: string}) => uom.value === selected);
    setFieldValue('UoMDescription', uom?.name.split(' - ')[1]);
  };

  const changeToggle = (
    name: 'IsPublished' | 'IsPhysical' | 'IsSohTracked' | 'IsApproved' | 'IsSold' | 'IsPurchased',
    value: string,
  ) => {
    if (value === 'true') {
      props.setFieldValue(name, true);
    } else if (value === 'false') {
      props.setFieldValue(name, false);
    } else {
      props.setFieldValue(name, undefined);
    }
    props.setFieldTouched(name, true, false);
  };

  return (
    <>
      <CategoryDialog category={initialCategoryData} open={openCategory} onClose={() => setOpenCategory(false)} />
      <Box display="flex" flexDirection="column" justifyContent="flex-start" alignItems="start" marginBottom="20px">
        <Grid container spacing={1}>
          <Grid item sm={2} className="flex items-end">
            <p className={`${classes.formLabel}`}>Product Class:</p>
          </Grid>
          <Grid item sm={3}>
            <InventoryClassAutocomplete
              selected={values.InventoryClassID ? values.InventoryClassID : null}
              clearClick={() => setFieldValue('InventoryClassID', undefined)}
              onSuggestionSelected={(e, selected) => setFieldValue('InventoryClassID', selected.suggestion)}
            />
          </Grid>
          <Grid item sm={2} />
          <Grid item sm={2} className="flex items-end">
            <p className={`${classes.formLabel}`}>Brand:</p>
          </Grid>
          <Grid item sm={3}>
            <BrandAutocomplete
              selected={values.BrandID ? values.BrandID : null}
              clearClick={() => setFieldValue('BrandID', undefined)}
              onSuggestionSelected={(e, selected) => setFieldValue('BrandID', selected.suggestion)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item sm={2} className="flex items-end">
            <p className={`${classes.formLabel}`}>Unit of Measure:</p>
          </Grid>
          <Grid item sm={3}>
            <UoMDropDown
              id="UoM"
              name="UoM"
              placeholder="Select UoM"
              value={values.UoM}
              onChange={(event: React.ChangeEvent<any>) => setUoM(event?.target?.value)}
              error={touched?.UoM && Boolean(errors?.UoM)}
              helperText={touched?.UoM && errors?.UoM}
              fullWidth
              displayEmpty
            />
          </Grid>
          <Grid item sm={2} />
          <Grid item sm={2} className="flex items-end">
            <p className={`${classes.formLabel}`}>UoM Description:</p>
          </Grid>
          <Grid item sm={3}>
            <STextField
              disabled={Boolean(!values.UoM)}
              id="UoMDescription"
              name="UoMDescription"
              placeholder="UoM Description"
              value={values.UoMDescription}
              onChange={handleChange}
              helperText={touched?.UoMDescription && errors?.UoMDescription}
              error={touched?.UoMDescription && Boolean(errors?.UoMDescription)}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item sm={2} className="flex items-end">
            <p className={`${classes.formLabel}`}>Quantity (optional):</p>
          </Grid>
          <Grid item sm={3}>
            <STextField
              disabled={Boolean(!values.UoM)}
              id="Quantity"
              name="Quantity"
              placeholder="0"
              value={values.Quantity}
              onChange={handleChange}
              onBlur={handleQuantityBlur}
              helperText={touched?.Quantity && errors?.Quantity}
              error={touched?.Quantity && Boolean(errors?.Quantity)}
              fullWidth
            />
          </Grid>
          <Grid item sm={2} />
        </Grid>
        <Grid container spacing={1}>
          <Grid item sm={4} className="flex items-center">
            <ToggleSwitch value={values.IsPhysical} handleChange={changeToggle.bind(null, 'IsPhysical')} />
            <label className="ml-3 text-base text-spenda-primarytext">Is physical item</label>
          </Grid>
          <Grid item sm={4} className="flex items-center">
            <ToggleSwitch value={values.IsSohTracked} handleChange={changeToggle.bind(null, 'IsSohTracked')} />
            <label className="ml-3 text-base text-spenda-primarytext">I track SOH for this product</label>
          </Grid>
        </Grid>
        <Divider
          className="w-full"
          style={{height: '2px !important', backgroundColor: '#F1F1F1', margin: '20px 0px'}}
        />
        <Grid container spacing={1}>
          <Grid item sm={4} className="flex items-end">
            <p className={`${classes.title}`}>I sell This Product</p>
          </Grid>
          <Grid item sm={1}>
            <ToggleSwitch value={values.IsSold} handleChange={changeToggle.bind(null, 'IsSold')} />
          </Grid>
        </Grid>
        {values.IsSold ? (
          <>
            <Grid container spacing={1} className="pt-2">
              <Grid item sm={2} className="flex items-end">
                <p className={`${classes.formLabel}`}>Categories:</p>
              </Grid>
              <Grid item sm={10}>
                <CategoryAutocomplete
                  selected={values.CategoryID ? values.CategoryID : null}
                  addNewClick={handleAddNewCategory}
                  clearClick={() => setFieldValue('CategoryID', undefined)}
                  onSuggestionSelected={(e, selected) => setFieldValue('CategoryID', selected.suggestion)}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item sm={2}></Grid>
              <Grid item sm={10} className="flex items-end">
                <RadioGroup
                  row
                  aria-label="IsRemoveFromCollection"
                  id="IsRemoveFromCollection"
                  name="IsRemoveFromCollection"
                  value={values.IsRemoveFromCollection + ''}
                  onChange={handleChange}
                  style={{flexWrap: 'nowrap'}}
                >
                  <FormControlLabel
                    classes={{root: classes.radioLabel}}
                    value="false"
                    control={<Radio classes={{root: classes.radioIcon}} />}
                    label="Add"
                  />
                  <FormControlLabel
                    classes={{root: classes.radioLabel}}
                    value="true"
                    control={<Radio classes={{root: classes.radioIcon}} />}
                    label="Remove"
                  />
                </RadioGroup>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item sm={5} className="flex items-center">
                <ToggleSwitch value={values.IsPublished} handleChange={changeToggle.bind(null, 'IsPublished')} />
                <label className="ml-3 text-base text-spenda-primarytext">
                  I publish this product on B2B e-commerce
                </label>
              </Grid>
              <Grid item sm={6} className="flex items-center">
                <ToggleSwitch value={values.IsApproved} handleChange={changeToggle.bind(null, 'IsApproved')} />
                <label className="ml-3 text-base text-spenda-primarytext">
                  I publish this product on third-party e-commerce
                </label>
              </Grid>
            </Grid>
          </>
        ) : null}
        <Divider
          className="w-full"
          style={{height: '2px !important', backgroundColor: '#F1F1F1', margin: '20px 0px'}}
        />
        <Grid container spacing={1}>
          <Grid item sm={4} className="flex items-end">
            <p className={`${classes.title}`}>I Purchase this Product</p>
          </Grid>
          <Grid item sm={1}>
            <ToggleSwitch value={values.IsPurchased} handleChange={changeToggle.bind(null, 'IsPurchased')} />
          </Grid>
        </Grid>
        {values.IsPurchased ? (
          <Grid container spacing={1} className="pt-2">
            <Grid item sm={2} className="flex items-end">
              <p className={`${classes.formLabel}`}>Supplier:</p>
            </Grid>
            <Grid item sm={3}>
              <SupplierAutocomplete selected={null} clearClick={() => {}} onSuggestionSelected={() => {}} />
            </Grid>
          </Grid>
        ) : null}
      </Box>
    </>
  );
};
