import * as React from 'react';
const OrangeInfoIcon = (props: SVGProps) => (
  <svg width={12} height={12} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 0a6 6 0 106 6 6.007 6.007 0 00-6-6zm0 11A5 5 0 116 1a5 5 0 010 10z"
      fill="#C68A19"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 2.5a.5.5 0 00-.5.5v4a.5.5 0 001 0V3a.5.5 0 00-.5-.5z"
      fill="#C68A19"
    />
    <rect x={5.5} y={8.5} width={1} height={1} rx={0.5} fill="#C68A19" />
  </svg>
);
export default OrangeInfoIcon;
