import * as React from 'react';
const ExpandArrow = (props: React.SVGProps<SVGSVGElement>) => (
  <svg {...props} width="8" height="5" fill="none">
    <path
      fill="#1C78AD"
      fillRule="evenodd"
      d="M.909 4.509h6.21a.556.556 0 0 0 .39-.95l-3.1-3.1a.556.556 0 0 0-.79 0l-3.1 3.1a.556.556 0 0 0 .39.95Z"
      clipRule="evenodd"
    />
  </svg>
);
export default ExpandArrow;
