import React, {useState} from 'react';
import {Textarea, Typography} from 'spenda-ui-react';
import {AlertDialogSlideV2} from './AlertDialogSlideV2';

interface IQuoteRejectDialog {
  handleCancel: () => void;
  handleRejectQuote?: (rejectReason?: string) => void;
  quoteName?: string;
  isLoading?: boolean;
  isCancelServiceJob?: boolean;
}

export const QuoteRejectDialog = (props: IQuoteRejectDialog) => {
  const {handleCancel, handleRejectQuote, quoteName, isLoading, isCancelServiceJob} = props;

  const [rejectReason, setRejectReason] = useState<string>();
  const handleReject = async () => {
    await handleRejectQuote?.(rejectReason);
    handleCancel();
  };

  return (
    <>
      <AlertDialogSlideV2
        title={isCancelServiceJob ? 'Cancel service job' : 'Reject quote'}
        headingTextSize="h2"
        headingClassess="justify-center w-full"
        footerClass="justify-between sm:w-auto w-full"
        dialogClassess="!min-w-[20%] !max-w-[482px] !w-full sm:h-auto h-full sm:block flex justify-center items-center flex-col sm:p-0 p-8"
        dialogBodyClassess="flex justify-center"
        data-autoid={isCancelServiceJob ? 'dlgCancelServiceJob' : 'dlgRejectQuote'}
        actions={[
          {
            label: isCancelServiceJob ? 'Close' : 'Cancel',
            action: handleCancel,
            color: 'primary',
            variant: 'outlined',
            disabled: isLoading,
          },
          {
            label: isCancelServiceJob ? 'Cancel Job' : 'Reject',
            action: handleReject,
            disabled: isLoading,
            loading: isLoading,
            color: 'error',
          },
        ]}
      >
        <div className="mx-auto w-full py-8 sm:max-w-[74%]">
          <Typography variant="small" className="mb-6 text-black-800">
            Are you sure you would like to {isCancelServiceJob ? 'cancel job' : `reject ${quoteName}`} ?
          </Typography>
          <Textarea
            label={`What is the reason for ${isCancelServiceJob ? 'cancel job' : 'rejecting the quote'}?`}
            color="primary"
            value={rejectReason}
            onChange={e => setRejectReason(e.target.value)}
            displayLength
            maxLength={300}
          />
        </div>
      </AlertDialogSlideV2>
    </>
  );
};
