import React from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import {Box} from '@material-ui/core';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<T>(order: Order, orderBy: keyof T) {
  return order === 'desc'
    ? (a: T, b: T) => descendingComparator(a, b, orderBy)
    : (a: T, b: T) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array?.map((el, index) => [el, index] as [T, number]);
  stabilizedThis &&
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
  return stabilizedThis.map(el => el[0]);
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      width: '100%',
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    head: {
      color: '#333',
      fontWeight: 500,
      backgroundColor: '#E1E1E1',
      border: 'none',
      fontSize: '0.625rem',
      fontFamily: 'Poppins',
      padding: '0.125rem 0.25rem !important',
      lineHeight: '0.875rem',
    },
    sortLabel: {
      '&.MuiTableSortLabel-root.MuiTableSortLabel-active': {
        color: '#333',
      },
    },
    data: {
      fontSize: '0.625rem',
      fontFamily: 'Poppins',
      padding: '0.5rem 0.25rem !important',
      lineHeight: '1rem',
      borderBottom: '1px solid #E1E1E1',
      '&.MuiTableCell-body': {
        color: '#333',
      },
      '&..MuiTableCell-root': {
        padding: '3px',
      },
    },
  }),
);

export interface IHeadCell<T> {
  disablePadding: boolean;
  id: keyof T;
  label: string;
  numeric: boolean;
  sort?: boolean;
  align: 'left' | 'right' | 'inherit' | 'center' | 'justify' | undefined;
  linked?: boolean;
  style?: any;
  render?: (a: T) => React.ReactElement;
  grouped?: boolean;
}
interface EnhancedTableProps<T> {
  classes: ReturnType<typeof useStyles>;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof T) => void;
  order: Order;
  orderBy: string;
  headCells: IHeadCell<T>[];
}
interface STableInvoicesProps<T> {
  headCells: IHeadCell<T>[];
  rows: T[];
  orderBy?: keyof T;
}

export const STableInvoices = <T,>({headCells, rows, orderBy: firstOrder}: STableInvoicesProps<T>) => {
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof T>(firstOrder!);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof T) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const EnhancedTableHead = (props: EnhancedTableProps<T>) => {
    const {classes, order, orderBy, onRequestSort} = props;
    const createSortHandler = (property: keyof T) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {headCells.map(headCell => (
            <TableCell
              className={`${classes.head}`}
              style={{display: headCell?.style?.innerWidth ? 'flex' : ''}}
              key={headCell.id as string}
              align={headCell.align}
              padding={'none'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {headCell.sort ? (
                <TableSortLabel
                  IconComponent={ExpandMoreRoundedIcon}
                  className={classes.sortLabel}
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell?.id)}
                  style={{width: headCell?.style?.innerWidth || ''}}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
              ) : (
                <div style={{width: headCell?.style?.innerWidth || ''}}>{headCell.label}</div>
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  return (
    <div className={classes.root}>
      <Box className={classes.paper}>
        <TableContainer>
          <Table className={classes.table} aria-labelledby="tableTitle" aria-label="enhanced table" size="small">
            <EnhancedTableHead
              headCells={headCells}
              classes={classes}
              order={order}
              orderBy={orderBy as string}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {rows &&
                stableSort<T>(rows, getComparator<T>(order, orderBy)).map((row, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      {headCells.map(col => (
                        <TableCell
                          key={col.id as string}
                          align={col.align}
                          className={`${classes.data} `}
                          style={{maxWidth: col?.style?.maxWidth || '', width: col?.style?.width}}
                        >
                          {/* @ts-ignore */}
                          {col.render ? col.render(row) : row[col.id]}
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </div>
  );
};
