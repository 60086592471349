import React from 'react';
import {ARDialogTemplate} from '../ARDialogTemplate';
import {IPaymentAuthorityChangeNotificationResponse} from '../../../model/payment/PaymentAuthority';
import {useHistory} from 'react-router';
import {Button, Typography} from 'spenda-ui-react';
import {PriceFormat} from '../../../utils/formatter';

interface IPaymentAuthorityChangeAlertModalProps {
  isOpen: boolean;
  onClose: () => void;
  modalData: IPaymentAuthorityChangeNotificationResponse[];
  supplierId: string | undefined;
}

export const PaymentAuthorityChangeAlertModal = (props: IPaymentAuthorityChangeAlertModalProps) => {
  // Props
  const {isOpen, supplierId, onClose, modalData} = props;

  // Hooks
  const history = useHistory();

  return (
    <ARDialogTemplate
      dialogProps={{size: 'sm', open: isOpen}}
      isFullScreen
      header={<p className=" font-poppins text-xl font-light text-[#333333]">Authority Change</p>}
      body={
        <div className="my-12 flex flex-col">
          <Typography variant="paragraph" className="mb-2 text-center font-light text-black-800">
            You have changed the authority rules for this payment method however, there are{' '}
            <Typography variant="paragraph" className="inline font-medium text-black-800">
              {modalData?.length}{' '}
            </Typography>
            scheduled payments upcoming. If you wish to review or cancel a scheduled payment, please go to Payments
            view.
          </Typography>
          <ul className="mx-4 mt-3 grid max-h-[150px] grid-cols-2 justify-center overflow-y-scroll">
            {modalData?.map(d => (
              <li
                key={d?.transactionID}
                className="flex justify-start text-center font-poppins text-base font-light text-black-800"
              >
                <Typography variant="paragraph" className="font-medium" data-autoid="lblRefNumber">
                  {d?.refNumber}
                </Typography>
                &nbsp;- Amount: <span data-autoid="lblPaymentAmount">{PriceFormat(d?.paymentAmount)}</span>
              </li>
            ))}
          </ul>
        </div>
      }
      footer={
        <div className="flex w-full flex-row items-center justify-between">
          <Button
            data-autoid={`btnClose`}
            className="bg-white"
            onClick={() => onClose()}
            variant="outlined"
            color="primary"
          >
            Close
          </Button>
          <Button
            variant="filled"
            color="primary"
            data-autoid={`btnPayments`}
            onClick={() => history.push(`/supplier/${supplierId}/payment-history`)}
          >
            Payments
          </Button>
        </div>
      }
      handleClose={() => onClose()}
    />
  );
};
