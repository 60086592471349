import React, {useState, useEffect, useRef} from 'react';
import {AlertDialogSlide} from './AlertDialogSlide';
import {Box, Link, makeStyles, MenuItem, Radio} from '@material-ui/core';
import * as Yup from 'yup';
import {Formik, FormikProps} from 'formik';
import useAlertAPI, {IReminderRequestResponse} from '../../services/useAlertAPI';
import {css} from 'glamor';
import {useConnectedCustomerAPI} from '../../services/useConnectedCustomerAPI';
import {IContact} from '../../model/people/Contact';
import {DatTypes} from '../../model/constants/Constants';
import {ISendReminderAlert} from '../../model/alert/AlertInfo';
import {IActionResults} from '../../model/ActionResults';
import {Toast} from '../../utils/Toast';
import {STextField} from '../inputs/STextField';
import {SwitchSlider} from '../form/SwitchSlider';
import {SSelect} from '../inputs/SSelect';
import {useParams} from 'react-router-dom';
import {IConnectedCustomer} from '../../model/customer/ConnectedCustomer';
import Skeleton from 'react-loading-skeleton';
import {PrimaryButton, SecondaryButton} from '../buttons/DefaultButtons';
import {useCustomersAPI} from '../../services/useCustomersAPI';
import clsx from 'clsx';
import CrossIcon from '../../assets/svg/Cross';
import {Button} from 'spenda-ui-react';

interface IPaymentReminderDialog {
  onSendReminder?: (reminder: any) => void;
  closeDialog: () => void;
  isSendByLink?: boolean;
  handleSendPaymentReminderPayload?: (paymentReminderRequest: ISendReminderAlert) => void;
  isUsingInCreateStatementModal?: boolean;
  customerId?: string;
  txId?: string;
}

export const PaymentReminderDialog = (props: IPaymentReminderDialog) => {
  const {isUsingInCreateStatementModal} = props;

  enum PaymentReminderSteps {
    CONTACT_LIST = 1,
    EDIT_CONTACT,
  }

  type NewContactType = Pick<
    IContact,
    'FirstName' | 'LastName' | 'EmailAddress' | 'IsPrimaryContact' | 'PositionOrOccupation'
  >;

  const newContactValues: NewContactType = {
    FirstName: '',
    LastName: '',
    EmailAddress: '',
    IsPrimaryContact: false,
    PositionOrOccupation: '',
  };

  let {customerId, transactionId} = useParams<{customerId?: string; transactionId: string}>();
  const [lastReminder, setLastReminder] = useState<IReminderRequestResponse | undefined>();
  const [currentStep, setCurrentStep] = useState<PaymentReminderSteps>(PaymentReminderSteps.CONTACT_LIST);
  const [customer, setCustomer] = useState<IConnectedCustomer | undefined>(undefined);
  const [contactToEdit, setContactToEdit] = useState<Partial<IContact>>(newContactValues);
  const [originalPrimaryContact, setOriginalPrimaryContact] = useState<Partial<IContact> | undefined>(undefined);
  const [isDeletingContact, setIsDeletingContact] = useState<boolean>(false);
  const [isUpdatingContact, setIsUpdatingContact] = useState<boolean>(false);
  const [, setHasAddedNewContact] = useState<boolean>(false);
  const [selectedCustomerContact, setSelectedCustomerContact] = useState<IContact | undefined>();

  // For new arUxUpdrade we have added this condition here
  if (props?.customerId) {
    customerId = props.customerId;
  }

  if (props?.txId) {
    transactionId = props.txId;
  }

  const {send: sendReminder, isLoading: isSendingReminder, sendPayInvoiceByLink} = useAlertAPI();
  const {
    getConnectedCustomer,
    getLastReminder: lastRemainderAPI,
    getLastInvoiceSentReminder,
    isLoading: isLoadingContacts,
  } = useConnectedCustomerAPI();
  const {saveContacts: saveCustomerNew} = useCustomersAPI();

  useEffect(() => {
    if (customerId && currentStep === PaymentReminderSteps.CONTACT_LIST) {
      getConnectedCustomer(false, parseInt(customerId), {filterOnActiveContactsOnly: 1}).then(
        (connectedCustomer: IConnectedCustomer) => {
          setCustomer(connectedCustomer);
          const freshListOfContacts = connectedCustomer?.Contacts?.length ? connectedCustomer.Contacts : [];
          // const freshListOfContacts = connectedCustomer?.Contacts?.length ? connectedCustomer.Contacts.map(cc => {cc.EmailAddress = ''; return cc;}) : [];

          if (
            selectedCustomerContact &&
            selectedCustomerContact.EmailAddress &&
            selectedCustomerContact.EmailAddress.trim()
          ) {
            // there is a value, lookup the email in the fresh list from the server so the Ids match for the radio button to be selected.
            const newSelectedContact = freshListOfContacts.find(
              c => c.IsActive && c.EmailAddress?.toLowerCase() === selectedCustomerContact!.EmailAddress.toLowerCase(),
            );
            setSelectedCustomerContact(newSelectedContact);
          }

          let isSelectedContactDeleted = false;
          if (
            isDeletingContact &&
            contactToEdit.EmailAddress?.toLowerCase() === selectedCustomerContact?.EmailAddress?.toLowerCase()
          ) {
            // deleting the selected contact, void the selected contact.
            setSelectedCustomerContact(undefined);
            isSelectedContactDeleted = true;
            setContactToEdit(newContactValues);
          }

          const primaryContact = freshListOfContacts.find(c => c.IsPrimaryContact);
          if (!selectedCustomerContact || isSelectedContactDeleted) {
            setSelectedCustomerContact(primaryContact);
          }
          setOriginalPrimaryContact(primaryContact);

          setIsDeletingContact(false);
          setIsUpdatingContact(false);
          setHasAddedNewContact(false);
        },
      );
    }
  }, [currentStep]);

  // Adding this because we want to trigger the send reminder from parent
  useEffect(() => {
    if (isUsingInCreateStatementModal) {
      const paymentReminderRequest = {
        DatTypeID: DatTypes.OverdueAndDueInvoiceReminder,
        IsSendAll: false,
        CustomerIDs: [customer?.CustomerID || 0],
        ToList: [selectedCustomerContact]?.map(c => {
          return {ContactEmailAddress: c?.EmailAddress};
        }),
      } as ISendReminderAlert;
      props.handleSendPaymentReminderPayload && props.handleSendPaymentReminderPayload(paymentReminderRequest);
    }
  }, [customer, selectedCustomerContact]);

  useEffect(() => {
    if (!props.isSendByLink && customerId) {
      lastRemainderAPI(parseInt(customerId)).then((data: IReminderRequestResponse) => {
        setLastReminder(data);
      });
    } else if (props.isSendByLink && transactionId) {
      getLastInvoiceSentReminder(parseInt(transactionId)).then((data: IReminderRequestResponse) => {
        setLastReminder(data);
      });
    }
  }, []);

  const helperText = css({
    color: 'hsl(var(--primary))',
    fontSize: 15,
  });
  const selectContact = css({
    '& .MuiSvgIcon-root': {
      fill: 'hsl(var(--primary))',
    },
  });

  const paymentReminderDialogStyles = {
    root: {
      fontFamily: 'Poppins',
      textAlign: 'center',
      color: 'hsl(var(--primary))',
      width: '100%',
      '& h2': {
        width: '100%',
        lineHeight: '40px',
      },
    },
  };

  const sendCustomerReminder = (): void => {
    let paymentReminderRequest = {
      DatTypeID: DatTypes.OverdueAndDueInvoiceReminder,
      IsSendAll: false,
      CustomerIDs: [customer?.CustomerID || 0],
      ToList: [selectedCustomerContact].map(c => {
        return {ContactEmailAddress: c?.EmailAddress};
      }),
    } as ISendReminderAlert;

    sendReminder(paymentReminderRequest).then((response: IActionResults<IReminderRequestResponse>) => {
      if (response.IsSuccess) {
        Toast.info('Payment reminder has been sent.');
        props.closeDialog();
      }
    });
  };

  const sendInvoiceByLink = (): void => {
    if (!transactionId) return;

    let invoiceByLinkReq = {
      DatTypeID: DatTypes.SendPayInvoiceByLink,
      DocID: transactionId,
      // IsSendAll: false,
      CustomerIDs: [customer?.CustomerID || 0],
      ToList: [selectedCustomerContact].map(c => {
        return {ContactEmailAddress: c?.EmailAddress};
      }),
    } as ISendReminderAlert;

    sendPayInvoiceByLink(invoiceByLinkReq).then((response: IActionResults<IReminderRequestResponse>) => {
      if (response.IsSuccess) {
        Toast.info('Invoice has been sent.');
        props.closeDialog();
      }
    });
  };

  const onToggleSelectedContact = (contact: IContact) => {
    setSelectedCustomerContact(contact);
  };

  const onSaveContact = async (contact: Partial<IContact>) => {
    setSelectedCustomerContact(contact as IContact);
    setIsUpdatingContact(false);
    setHasAddedNewContact(false);

    const customerContacts = customer?.Contacts || [];

    let updatedContactList: Partial<IContact>[] = [...customerContacts];

    // Different payload for new add/update customer API
    updatedContactList = updatedContactList?.map(current => ({
      ID: current.ID,
      FirstName: current.FirstName,
      LastName: current.LastName,
      EmailAddress: current.EmailAddress,
      IsActive: current.IsActive,
      PositionOrOccupation: current.PositionOrOccupation,
      IsPrimaryContact: current.IsPrimaryContact,
    }));

    if (contact.ID) {
      setIsUpdatingContact(true);
      // Editing existing
      updatedContactList.forEach(c => {
        if (c.ID === contact.ID) {
          c.FirstName = contact.FirstName!;
          c.LastName = contact.LastName!;
          c.EmailAddress = contact.EmailAddress!;
          c.IsActive = isDeletingContact ? false : contact.IsActive!;
          c.PositionOrOccupation = contact.PositionOrOccupation!;
        }
      });

      const userHasUpdatedNewPrimaryContact = contact.IsPrimaryContact && originalPrimaryContact?.ID !== contact.ID;

      if (userHasUpdatedNewPrimaryContact) {
        updatedContactList = updatedContactList.map(c => ({
          ...c,
          IsPrimaryContact: c.ID === contact.ID ? true : false,
        }));
      }
    } else {
      setHasAddedNewContact(true);
      // Adding new
      updatedContactList = [...customerContacts, contact as IContact];

      if (contact.IsPrimaryContact) {
        updatedContactList = updatedContactList.map(c => ({
          ...c,
          IsPrimaryContact: c.ID === contact.ID ? true : false,
        }));
      }

      const newSelectedCustomer = updatedContactList.find(
        c => c.EmailAddress?.toLowerCase() === contact?.EmailAddress?.toLowerCase(),
      );
      setSelectedCustomerContact(newSelectedCustomer as IContact);
    }

    const customerToSave = {
      ID: customer?.CustomerID,
      Name: customer?.CustomerName,
      Contacts: updatedContactList,
    };

    // New endpoints to add/update customer
    if (customer?.CustomerID) {
      await saveCustomerNew(customer.CustomerID, customerToSave.Contacts);
    }

    setCurrentStep(PaymentReminderSteps.CONTACT_LIST);
  };

  const onEditExistingContact = (contact: Partial<IContact>) => {
    setContactToEdit(contact);
    setCurrentStep(PaymentReminderSteps.EDIT_CONTACT);
  };

  const onDeleteContact = () => {
    if (contactToEdit.IsPrimaryContact) {
      Toast.info('The primary contact cannot be removed');
      return;
    }

    setIsDeletingContact(true);
  };

  const onClose = () => {
    setIsDeletingContact(false);
  };

  const validationSchema = Yup.object({
    FirstName: Yup.string().required('First Name is required').max(50, 'Character limit exceeded'),
    LastName: Yup.string().required('Last Name is required').max(50, 'Character limit exceeded'),
    EmailAddress: Yup.string().email('Email is not valid').required('Email address is required'),
  });

  const timeSinceLastReminder = (lastReminder?: IReminderRequestResponse) => {
    if (!lastReminder || lastReminder.DaysSinceLastSent === null) {
      return;
    }

    let timeSpan = '';
    const hoursAgo = lastReminder.TimeSpanSinceLastSent?.split(':')[0];
    const minsAgo = lastReminder.TimeSpanSinceLastSent?.split(':')[1];

    switch (lastReminder.DaysSinceLastSent) {
      case 0:
        timeSpan = `${
          parseInt(hoursAgo)
            ? parseInt(hoursAgo) + ' hours ago'
            : parseInt(minsAgo)
              ? parseInt(minsAgo) + ' mins ago'
              : '1 min ago'
        }`;
        break;
      case 1:
        timeSpan = `yesterday`;
        break;
      default:
        timeSpan = `${lastReminder.DaysSinceLastSent} days ago`;
        break;
    }

    return timeSpan;
  };

  let header = undefined;
  let footer = undefined;
  let body = undefined;

  switch (currentStep) {
    case PaymentReminderSteps.CONTACT_LIST:
      header = <p className="font-poppins">Send {props.isSendByLink ? 'Invoice' : 'Reminder'}</p>;

      body = (
        <Box
          display="flex"
          flexDirection="column"
          className={`font-pop w-full ${isUsingInCreateStatementModal ? 'item-left' : 'items-center'}`}
        >
          <p
            className={`${isUsingInCreateStatementModal ? 'font-poppins  text-base text-[#707070]' : 'mb-8'}`}
          >{`Select the contact you want to ${props.isSendByLink ? 'send the invoice' : 'email the statement'} to.`}</p>
          {!lastReminder?.FirstReminder && (
            <Box
              className={`${helperText} ${isUsingInCreateStatementModal ? 'my-2 pr-3 font-poppins !text-base' : ''}`}
            >
              {' '}
              You last sent a reminder to this customer {timeSinceLastReminder(lastReminder)}
            </Box>
          )}
          <Box
            className={`flex w-full flex-col justify-center ${
              isUsingInCreateStatementModal ? `${selectContact} items-left h-[70px] overflow-y-auto` : 'items-center'
            }`}
          >
            {isLoadingContacts ? (
              <>
                {' '}
                {[1, 2, 3].map(i => (
                  <CustomerContact key={`contact-skeleton-${i}`} />
                ))}{' '}
              </>
            ) : (
              <>
                <Box
                  className={`${isUsingInCreateStatementModal ? 'mx-0' : 'mx-auto'}`}
                  {...css({
                    maxHeight: isUsingInCreateStatementModal ? '200px' : '13.25em',
                    maxWidth: '36em',
                    overflowY: 'auto',
                  })}
                >
                  {originalPrimaryContact && (
                    <CustomerContact
                      contact={originalPrimaryContact as IContact}
                      selectedContact={selectedCustomerContact}
                      onEditExistingContact={onEditExistingContact}
                      onToggleSelectedContact={onToggleSelectedContact}
                      isUsingInCreateStatementModal={isUsingInCreateStatementModal}
                    />
                  )}
                  {customer?.Contacts.filter(c => !c.IsPrimaryContact && c.IsActive).map((c: IContact, index) => (
                    <CustomerContact
                      key={`customer-contact-${index}`}
                      contact={c}
                      selectedContact={selectedCustomerContact}
                      onEditExistingContact={onEditExistingContact}
                      onToggleSelectedContact={onToggleSelectedContact}
                      isUsingInCreateStatementModal={isUsingInCreateStatementModal}
                    />
                  ))}
                </Box>
                <Box>
                  {customer?.Contacts.every(c => !c.EmailAddress) && (
                    <p className={`text-gray-700 ${isUsingInCreateStatementModal ? 'font-poppins' : ''}`}>
                      No default email address exists for this debtor
                    </p>
                  )}
                </Box>
              </>
            )}
          </Box>
          {!props.isSendByLink && (
            <Box
              width="100%"
              paddingTop="0.75em"
              paddingBottom={isUsingInCreateStatementModal ? '0' : '0.85em'}
              borderTop={isUsingInCreateStatementModal ? 'none' : '1px solid #ccc'}
              borderBottom={isUsingInCreateStatementModal ? 'none' : '1px solid #ccc'}
              display="flex"
              alignItems={isUsingInCreateStatementModal ? 'start' : 'center'}
              flexDirection="column"
              marginTop={isUsingInCreateStatementModal ? '0em' : '1em'}
            >
              {isLoadingContacts ? (
                <Box>
                  <Skeleton width="100PX" />
                </Box>
              ) : (
                <>
                  {isUsingInCreateStatementModal ? (
                    <SecondaryButton
                      label="Add a new contact"
                      padding="7px"
                      margin="0px 0px 0px 40px"
                      onClick={() => {
                        setCurrentStep(PaymentReminderSteps.EDIT_CONTACT);
                        setContactToEdit(newContactValues);
                      }}
                    />
                  ) : (
                    <PrimaryButton
                      label="Add a new contact"
                      onClick={() => {
                        setCurrentStep(PaymentReminderSteps.EDIT_CONTACT);
                        setContactToEdit(newContactValues);
                      }}
                    />
                  )}
                </>
              )}
            </Box>
          )}
        </Box>
      );

      footer = (
        <Box display="flex" justifyContent="right" padding="0.5rem" className="font-pop">
          <Box display="flex" paddingRight="1em">
            <SecondaryButton label="Cancel" onClick={props.closeDialog} />
          </Box>
          <Box display="flex">
            {!props.isSendByLink && !isLoadingContacts && (
              <>
                <PrimaryButton
                  label="Send Reminder"
                  onClick={sendCustomerReminder}
                  isSubmitting={isSendingReminder}
                  disabled={!selectedCustomerContact?.EmailAddress}
                />
              </>
            )}
            {props.isSendByLink && !isLoadingContacts && (
              <>
                <PrimaryButton
                  label="Send Link To Pay"
                  className="pl-2"
                  onClick={sendInvoiceByLink}
                  isSubmitting={isSendingReminder}
                  disabled={!selectedCustomerContact?.EmailAddress}
                />
              </>
            )}
          </Box>
        </Box>
      );
      break;
    case PaymentReminderSteps.EDIT_CONTACT:
      header = (
        <>
          <Box className={`-mx-3 flex justify-between border-b-[#ccc] px-3 pb-3`}>
            <span className="cursor-pointer"></span>

            <p className={'font-poppins text-2xl font-normal text-spenda-primarytext'}>
              {contactToEdit.ID ? 'Edit Contact' : 'New Contact'}
            </p>
            <Box className="flex justify-end text-right">
              <span
                className="ap-onboarding-close-icon"
                data-autoid="btnCloseBtn"
                onClick={() => setCurrentStep(PaymentReminderSteps.CONTACT_LIST)}
              >
                <div className="h-4 w-4">
                  <CrossIcon />
                </div>
              </span>
            </Box>
          </Box>
        </>
      );

      body = (
        <Box display="flex" flexDirection="column" alignItems="center" className="font-pop w-full">
          <p className={`mb-8`}>
            {contactToEdit.ID ? `Edit this contact for` : `Create a new contact for`} {customer?.CustomerName}
          </p>
          <Box display="flex" alignItems="center" className="w-full">
            <Formik initialValues={contactToEdit} onSubmit={onSaveContact} validationSchema={validationSchema}>
              {(props: FormikProps<any>) => (
                <CustomerContactForm
                  {...props}
                  isDisablePrimaryContactToggle={contactToEdit.ID ? (customer?.Contacts || []).length <= 1 : false}
                  originalPrimaryContact={originalPrimaryContact}
                  isDeletingContact={isDeletingContact}
                  onClose={onClose}
                />
              )}
            </Formik>
          </Box>
        </Box>
      );

      footer = (
        <Box display="flex" width="100%" justifyContent="space-around" className="font-pop">
          <Box width="100%" display="flex">
            <Button
              onClick={onDeleteContact}
              onSubmit={onClose}
              loading={isDeletingContact}
              disabled={(customer?.Contacts || []).length <= 1}
            >
              Delete
            </Button>
          </Box>
          <Box display="flex">
            <Box paddingRight="1em">
              <SecondaryButton label="Cancel" onClick={() => setCurrentStep(PaymentReminderSteps.CONTACT_LIST)} />
            </Box>
            <Box>
              <PrimaryButton
                label="Save"
                form="edit-customer-contact-form"
                type="submit"
                isSubmitting={isUpdatingContact}
                disabled={isUpdatingContact}
              />
            </Box>
          </Box>
        </Box>
      );
    default:
      break;
  }

  return (
    <>
      {PaymentReminderSteps.CONTACT_LIST === currentStep && isUsingInCreateStatementModal ? (
        body
      ) : (
        <AlertDialogSlide
          materialStyles={paymentReminderDialogStyles}
          title={header}
          titleColor="black"
          paddingTopHeader={10}
          footer={footer}
        >
          <Box
            minHeight="16rem"
            minWidth="40rem"
            display="flex"
            justifyContent="space-around"
            alignItems="center"
            padding={'0px 50px'}
          >
            {body}
          </Box>
        </AlertDialogSlide>
      )}
    </>
  );
};

const CustomerContact = (props: {
  contact?: IContact;
  isLoading?: boolean;
  selectedContact?: IContact;
  onEditExistingContact?: (c: IContact) => void;
  onToggleSelectedContact?: (c: IContact) => void;
  isUsingInCreateStatementModal?: boolean;
}) => {
  const {contact: c, isUsingInCreateStatementModal} = props;

  const fullName = `${c?.FirstName} ${c?.LastName}`.trim();

  const onChange = (c: IContact) => {
    if (props.onToggleSelectedContact) {
      props.onToggleSelectedContact(c);
    }
  };

  const onEdit = (c: IContact) => {
    if (props.onEditExistingContact) {
      props.onEditExistingContact(c);
    }
  };

  return (
    <Box
      display={isUsingInCreateStatementModal ? 'flex' : 'grid'}
      {...css({
        gridTemplateColumns: '20px minmax(194px,212px) minmax(min-content,168px) 1fr',
        gridColumnGap: isUsingInCreateStatementModal ? '0em' : '0.5em',
      })}
      alignItems={isUsingInCreateStatementModal ? 'start' : 'center'}
      key={`contact-${c ? c.ID : Math.random().toString(36).substring(2, 7)}`}
    >
      {c ? (
        <>
          <Radio
            disabled={props.isLoading}
            checked={props.selectedContact?.ID === c.ID}
            onChange={() => onChange(c)}
            value={c.EmailAddress}
            name={`${c.EmailAddress}`}
          />
          {isUsingInCreateStatementModal ? (
            <div className="mt-2 flex w-full flex-col">
              <Link
                underline={isUsingInCreateStatementModal ? 'none' : 'hover'}
                color="secondary"
                href="#"
                onClick={() => onEdit(c)}
              >
                <div
                  className={` ${
                    isUsingInCreateStatementModal
                      ? 'font-poppins text-base font-semibold text-black-800'
                      : 'truncate font-bold underline'
                  }`}
                >
                  {fullName
                    ? `${c.FirstName} ${c.LastName} ${c.IsPrimaryContact ? ' (Primary Contact)' : ''}`
                    : 'Add Name'}
                </div>
              </Link>
              <h2
                title={c.EmailAddress}
                className={`${
                  isUsingInCreateStatementModal
                    ? ' mt-[2px] font-poppins text-xs font-normal text-[#707070]'
                    : 'my-2 ml-2 truncate'
                } ${c.EmailAddress?.length >= 25 ? 'w-50' : ''} ${c.EmailAddress ? 'text-[#707070]' : ''}`}
              >
                Email-{' '}
                <span className="text-[#444343]">
                  {c.EmailAddress ? c.EmailAddress : 'Edit contact to add an email address'}
                </span>
              </h2>
              <p className={`font-poppins text-xs font-normal text-[#707070]`}>{c.PositionOrOccupation}</p>
            </div>
          ) : (
            <>
              <Link href="#" onClick={() => onEdit(c)}>
                <div className={`truncate font-bold text-primary underline`}>
                  {fullName
                    ? `${c.FirstName} ${c.LastName} ${c.IsPrimaryContact ? ' (Primary Contact)' : ''}`
                    : 'Add Name'}
                </div>
              </Link>
              <h2
                title={c.EmailAddress}
                className={`ml-2 ${c.EmailAddress?.length >= 25 ? 'w-40' : ''} my-2 truncate ${
                  c.EmailAddress ? 'font-semibold' : ''
                }`}
              >
                {c.EmailAddress ? c.EmailAddress : 'Edit contact to add an email address'}
              </h2>
              <p>{c.PositionOrOccupation}</p>
            </>
          )}
        </>
      ) : (
        <>
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </>
      )}
    </Box>
  );
};

const CustomerContactForm = (
  props: FormikProps<Partial<IContact>> & {
    originalPrimaryContact: Partial<IContact> | undefined;
    isDisablePrimaryContactToggle: boolean;
    isDeletingContact: boolean;
    onClose: () => void;
  },
) => {
  const {
    errors,
    handleChange,
    setFieldTouched,
    setFieldValue,
    setFieldError,
    touched,
    values: {FirstName: FirstName, LastName, EmailAddress, IsPrimaryContact, PositionOrOccupation},
  } = props;

  const isOriginalPrimaryContact = useRef(IsPrimaryContact);
  const [isPrimaryContactChanged, setIsPrimaryContactChanged] = useState<boolean>(false);

  let nameStr = (FirstName || '').concat(LastName || '');

  const useStyles = makeStyles(theme => ({
    root: {
      fontFamily: 'Poppins',
      '& > *': {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
      '& .MuiFormLabel-root': {
        fontFamily: 'Poppins!important',
        color: '#333',
      },
      '& .MuiInputBase-root': {
        color: '#000',
        '& input': {
          fontFamily: 'Poppins!important',
        },
      },
      '& .textFields': {
        '& .MuiFormControl-root': {
          width: '260px!important',
        },
      },
      '& .selectRoleField': {
        '& .MuiFormControl-root': {
          width: '260px!important',
          '& .MuiInputLabel-formControl': {
            transform: 'translate(0, 16px) scale(1)',
            paddingLeft: '13px',
            fontSize: '14px',
            fontWeight: '600',
            '&.MuiInputLabel-shrink': {
              transform: 'translate(0, -5px) scale(1)',
              fontSize: '10px',
            },
          },
          '& .MuiInputBase-root': {
            height: '45px',
            borderRadius: '6px',
            paddingLeft: '8px',
            '& .MuiSelect-root': {
              fontWeight: 600,
              fontSize: '14px',
            },
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#333',
          },
        },
      },
    },

    menuPaper: {
      maxHeight: '250px',
      marginTop: '15px',
      width: '260px',
      marginLeft: '-6px',
    },
  }));

  const confirmationDialogStyles = {
    root: {
      minWidth: '32em',
    },
    MuiDialog: {
      '& .MuiDialogContent-root': {
        display: 'flex',
        justifyContent: 'center',
      },
    },
  };

  const classes = useStyles();

  const onTogglePrimaryContact = () => {
    if (isOriginalPrimaryContact.current) {
      setFieldError(
        'IsPrimaryContact',
        'You must have at least one primary contact. Please make another contact the primary contact first.',
      );
    } else {
      // user changed Primary Contact
      setFieldValue('IsPrimaryContact', !IsPrimaryContact);
    }
  };

  const onChange = (name: keyof IContact, e: {persist: () => void}) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  const primaryContactChangedCheck = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    props.validateForm();

    setFieldValue('IsActive', true);

    const {IsPrimaryContact, ID} = props.values;

    if (!props.originalPrimaryContact) {
      props.submitForm();
    }

    if (IsPrimaryContact && ID !== props.originalPrimaryContact?.ID) {
      if (props.isValid) {
        // Display warning dialog as there's a new primary contact
        setIsPrimaryContactChanged(true);
      } else {
        // props.setErrors(props.errors);
        Object.keys(props.errors).forEach(e => props.setFieldTouched(e));
      }
    } else {
      props.submitForm();
    }
  };

  const onRoleChanged = (
    event: React.ChangeEvent<{name?: string | undefined; value: unknown}>,
    _child: React.ReactNode,
  ) => {
    setFieldValue('PositionOrOccupation', event?.target.value);
  };

  return (
    <>
      <form onSubmit={primaryContactChangedCheck} id="edit-customer-contact-form">
        <Box className={clsx(classes.root, 'mb-5 flex items-center justify-between pr-0')}>
          <div className="textFields">
            <STextField
              v2
              id="FirstName"
              name="FirstName"
              type="string"
              helperText={touched.FirstName ? errors.FirstName : ''}
              error={touched.FirstName && Boolean(errors.FirstName)}
              value={FirstName}
              onChange={onChange.bind(null, 'FirstName')}
              label={'First name'}
            />
          </div>
          <div className="textFields">
            <STextField
              v2
              id="LastName"
              name="LastName"
              type="string"
              helperText={touched.LastName ? errors.LastName : ''}
              error={touched.LastName && Boolean(errors.LastName)}
              value={LastName}
              onChange={onChange.bind(null, 'LastName')}
              label={'Last name'}
            />
          </div>
        </Box>
        <Box className={clsx(classes.root, 'mb-5 flex items-center justify-between')} display="flex">
          <div className="textFields">
            <STextField
              v2
              id="EmailAddress"
              name="EmailAddress"
              type="string"
              helperText={touched.EmailAddress ? errors.EmailAddress : ''}
              error={touched.EmailAddress && Boolean(errors.EmailAddress)}
              value={EmailAddress}
              onChange={onChange.bind(null, 'EmailAddress')}
              label="Email"
            />
          </div>

          <div className="selectRoleField">
            <SSelect
              value={PositionOrOccupation || ''}
              onChange={onRoleChanged}
              label="Role"
              variant="outlined"
              MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                classes: {paper: classes.menuPaper},
              }}
              labelId="select-role"
            >
              {[
                '',
                'Accounting',
                'Executive',
                'Finance',
                'Human Resources',
                'Information Technology',
                'Management',
                'Marketing',
                'Operations',
                'Owner',
                'Purchasing',
                'Sales',
                'Other',
              ].map((role, index) => (
                <MenuItem className="pr-0 !font-poppins" key={index} value={role}>
                  {role}
                </MenuItem>
              ))}
            </SSelect>
          </div>
        </Box>
        {(!IsPrimaryContact || !isOriginalPrimaryContact?.current) && (
          <Box className={classes.root} display="flex" justifyContent="center">
            <Box display="flex" alignItems="center">
              <label>Make Primary Contact</label>
            </Box>
            <Box>
              <label className={`flex items-center justify-start text-black`}>
                <SwitchSlider
                  handleChange={onTogglePrimaryContact}
                  checked={!!IsPrimaryContact}
                  disabled={props.isDisablePrimaryContactToggle}
                  name="makePrimaryContact"
                />
              </label>
            </Box>
          </Box>
        )}
        {isOriginalPrimaryContact?.current && (
          <Box className={classes.root} display="flex" justifyContent="center">
            <Box display="flex" alignItems="center">
              <label>This is the Primary Contact</label>
            </Box>
          </Box>
        )}
        <small className={`h-4`}>{errors.IsPrimaryContact}</small>
      </form>
      {isPrimaryContactChanged && (
        <AlertDialogSlide
          title="Changing Primary Contact?"
          fontFamily="Poppins"
          actions={[
            {
              label: 'No',
              textColor: 'white',
              color: 'yellow',
              variant: 'outlined',
              action: () => {
                setFieldValue('IsPrimaryContact', false);
                setIsPrimaryContactChanged(false);
              },
            },
            {
              label: 'Yes',
              action: () => {
                props.submitForm();
              },
            },
          ]}
        >
          <p className="m-2 text-[15px]">You can only have one Primary Contact per customer.</p>
          <p className={`m-2 pb-3 text-center text-base`}>
            {`Would you like to make `}{' '}
            <b>
              '{FirstName || ''} {LastName || ''}'
            </b>{' '}
            {` the Primary Contact 
                and change `}{' '}
            <b>
              '{props.originalPrimaryContact?.FirstName || ''} {props.originalPrimaryContact?.LastName || ''}'
            </b>{' '}
            {` to an additional contact?`}
          </p>
        </AlertDialogSlide>
      )}
      {props.isDeletingContact && (
        <AlertDialogSlide
          materialStyles={confirmationDialogStyles}
          title="Deleting Contact"
          fontFamily="Poppins"
          actions={[
            {label: 'No', textColor: 'white', color: 'yellow', variant: 'outlined', action: () => props.onClose()},
            {
              label: 'Yes',
              action: () => {
                props.submitForm();
              },
            },
          ]}
        >
          <p className={`float-left pb-3 text-center`}>{`Are you sure you want to delete`}&nbsp;</p>
          <p className={`truncate ${nameStr.length >= 50 ? 'w-84' : ' '} float-left`}>
            <b>
              {"'"}
              {FirstName || ''} {LastName || ''}
              {"'"}
            </b>
          </p>
          <p className={`float-left`}> ?</p>
        </AlertDialogSlide>
      )}
    </>
  );
};
