import React, {useState} from 'react';
import moment from 'moment';
import clsx from 'clsx';
import {Scheduler, IconButton, Typography, Chip, Tooltip} from 'spenda-ui-react';
import {currentActiveView} from 'spenda-ui-react/types/components/calendar';
import {ViewTaskProps} from 'spenda-ui-react/types/components/scheduler';

import ExpandIcon from '../../assets/svg/service-management/ExpandIcon';
import {ICustomer} from '../../model/customer/Customer';
import {SelectQuoteCustomerDialog} from '../dialog/SelectQuoteCustomerDialog';
import {
  ISchedulerData,
  ITechnician,
  ITechnicianViewJobs,
  RequiresAttentionStatus,
  ServiceJobStatus,
} from '../../model/service-management/serviceJob';
import {useScheduleJob} from '../../hooks/useScheduleJob';
import {AlertDialogWhenScheduling, ISchedulerDialogState} from '../dialog/SchedulerDialog';

export function BookingDiaryScheduler(props: {
  currentActiveView: currentActiveView;
  onSwitchClick: (view: currentActiveView) => void;
  handleCollapse?: () => void;
  isCollapseView?: boolean;
  selectedDate: Date;
  setSelectedDate: (date: Date) => void;
  technicians: ITechnician[];
  selectedTechnicianIDs: number[];
  schedulerData: ITechnicianViewJobs[];
  handleCreateAdhocBooking?: (selectedServiceCustomer: ICustomer, scheduleJobInfo?: ISchedulerDialogState) => void;
  onEditJob: (serviceJobId: number) => void;
  isShowUnassignedServiceJobs?: boolean;
}) {
  const {
    currentActiveView,
    onSwitchClick,
    handleCollapse,
    isCollapseView,
    selectedDate,
    setSelectedDate,
    technicians,
    selectedTechnicianIDs,
    schedulerData,
    handleCreateAdhocBooking,
    onEditJob,
    isShowUnassignedServiceJobs,
  } = props;

  const [selectedJobInfo, setSelectedJobInfo] = useState<ISchedulerDialogState>();
  const [showWarning, setShowWarning] = useState(false);

  const [showSelectCustomerDialog, setShowSelectCustomerDialog] = useState(false);
  const [selectedServiceCustomer, setSelectedServiceCustomer] = useState<ICustomer>();

  const {data, techniciansList} = useScheduleJob({
    schedulerData: schedulerData,
    selectedTechnicianIDs: selectedTechnicianIDs,
    technicians: technicians,
    isShowUnassignedServiceJobs: isShowUnassignedServiceJobs,
  });

  const onDragEnd = (technician: any, startDateTime: Date, endDateTime: Date) => {
    setSelectedJobInfo({
      startDate: startDateTime,
      endDate: endDateTime,
      technician,
    });
    setShowWarning(true);
  };

  const onDateChange = (date: Date) => {
    setSelectedDate(date);
  };

  const TaskView = ({item, minWidth, overlapped}: ViewTaskProps & {item: ISchedulerData}) => {
    return (
      <div onClick={() => onEditJob(item.serviceJobID)} className="h-full w-full">
        <TemplateViewSchedulerTask item={item} minWidth={minWidth} overlapped={overlapped} />
      </div>
    );
  };

  return (
    <>
      <Scheduler
        data={data}
        onDragEnd={onDragEnd}
        selectedDate={selectedDate}
        workingHours={{
          start: '12AM',
          end: '12AM',
        }}
        onDateChange={onDateChange}
        technician={techniciansList}
        ViewTask={TaskView}
        isShowSwitcher={true}
        currentActiveView={currentActiveView}
        onSwitchClick={view => onSwitchClick(view)}
        ViewGridHeading={props => <span>{props.technician.name}</span>}
        getTechnicianIndexByEvent={event => {
          return techniciansList.findIndex(tech => tech.id == event.technician.id);
        }}
        rootContainerProps={{
          className: 'font-poppins pt-2',
        }}
        gridContainerProps={{
          className: 'pb-2.5',
        }}
        containerProps={{
          className: 'h-[calc(100vh-259px)]',
        }}
        moreButtonsInHeader={
          isCollapseView && (
            <IconButton data-autoid="btnCollapse" variant={'outlined'} onClick={handleCollapse}>
              <ExpandIcon className="rotate-180" />
            </IconButton>
          )
        }
      />

      {showSelectCustomerDialog && (
        <SelectQuoteCustomerDialog
          isService={true}
          open={showSelectCustomerDialog}
          handleCancel={() => {
            setShowSelectCustomerDialog(!showSelectCustomerDialog);
          }}
          handleConfirm={() => {
            setShowSelectCustomerDialog(!showSelectCustomerDialog);
            handleCreateAdhocBooking?.(selectedServiceCustomer!, selectedJobInfo);
          }}
          setSelectedQuoteCustomer={setSelectedServiceCustomer}
          selectedQuoteCustomer={selectedServiceCustomer}
        />
      )}
      {showWarning && (
        <AlertDialogWhenScheduling
          onScheduleJob={() => {
            setShowWarning(false);
            setShowSelectCustomerDialog(true);
          }}
          onClose={() => setShowWarning(false)}
          selectedJobInfo={selectedJobInfo!}
        />
      )}
    </>
  );
}

const getServiceJobStatusColor = (status: RequiresAttentionStatus) => {
  switch (status) {
    case RequiresAttentionStatus.AwaitingStock:
    case RequiresAttentionStatus.Reschedule:
    case RequiresAttentionStatus.Paused:
      return 'warning';
    case RequiresAttentionStatus.ScheduledTentative:
    case RequiresAttentionStatus.Scheduled:
      return 'cool-purple';
    case RequiresAttentionStatus.InProgress:
      return 'warm-purple';
    case RequiresAttentionStatus.Cancelled:
      return 'gray';
    case RequiresAttentionStatus.Completed:
      return 'success';
    default:
      return 'primary';
  }
};

export const TemplateViewSchedulerTask = (props: ViewTaskProps) => {
  const {item, minWidth, overlapped} = props;
  const startDate = moment(item.startDate);
  const endDate = moment(item.endDate);
  const duration = moment.duration(endDate.diff(startDate));
  const hours = duration.asHours();

  const lessThanThirtyMinutes = hours < 0.5 && item.bufferHeight <= 10;
  const thirtyMinutes = hours <= 0.5 && item.bufferHeight <= 10;
  const fortyFiveMinutes = hours <= 0.75 && item.bufferHeight <= 30;
  const oneHour = hours <= 1 && item.bufferHeight <= 30;

  const status = item?.isWaitingForStock
    ? RequiresAttentionStatus.AwaitingStock
    : !item?.isWaitingForStock && !item.isSent
      ? RequiresAttentionStatus.ScheduledTentative
      : RequiresAttentionStatus[`${item.status as ServiceJobStatus}`];

  const statusColor = getServiceJobStatusColor(status);

  return (
    <div id="templateParentDiv" className="relative flex h-full flex-col overflow-hidden rounded-md">
      {item.bufferHeight > 0 && <div className="bg-stripe" style={{minHeight: item.bufferHeight}} />}

      <Tooltip
        placement="top-start"
        className={`rounded-[4px] bg-white p-0 text-black-800 shadow-md`}
        content={
          oneHour && (
            <div style={{width: minWidth}} className="px-3 py-2">
              {(lessThanThirtyMinutes || fortyFiveMinutes || oneHour) && (
                <Typography
                  className="max-w-[200px] pb-1 text-xs font-semibold"
                  data-autoid={`txtDescription-${item.id}`}
                >
                  Description: <span className="font-medium text-spenda-greytext">{item.description}</span>
                </Typography>
              )}
              {(lessThanThirtyMinutes || fortyFiveMinutes || overlapped) && (
                <Typography className="pb-1 text-xs font-semibold" data-autoid={`txtTitle-${item.id}`}>
                  Date/Time:{' '}
                  <span className="font-medium text-spenda-greytext">
                    {startDate.format('DD/MM HH:mm')} - {endDate.format('HH:mm')}
                  </span>
                </Typography>
              )}
              {lessThanThirtyMinutes && (
                <Typography className="text-xs font-semibold ">
                  Status: <span className="font-medium text-spenda-greytext">{status}</span>
                </Typography>
              )}
            </div>
          )
        }
      >
        <div
          className={`relative h-full cursor-pointer overflow-hidden border-t-2 border-t-primary bg-[#E3EEF5] py-[2px]`}
        >
          <div className="px-2">
            <Typography
              className={clsx('pb-1 text-sm font-medium text-primary', {'text-xs': thirtyMinutes})}
              data-autoid={`txtTitle-${item.id}`}
            >
              {item.title}
            </Typography>
            {hours >= 1 && item.bufferHeight <= 30 && !overlapped && (
              <Typography className="pb-1 text-xs font-medium text-black-300" data-autoid={`txtTitle-${item.id}`}>
                {startDate.format('DD/MM HH:mm')} - {endDate.format('HH:mm')}
              </Typography>
            )}
            {hours > 1 && item.bufferHeight <= 120 && (
              <Typography
                className={`${hours < 2 && item.bufferHeight <= 120 ? 'truncate' : null} text-xs font-medium text-spenda-greytext`}
                data-autoid={`txtDescription-${item.id}`}
              >
                {item.description && item.description}
              </Typography>
            )}
          </div>
          {hours >= 0.5 && (
            <Chip
              className={clsx('absolute bottom-0.5 w-full px-2 py-1.5 text-center text-xs font-medium', {
                'py-[2px] text-[8px]': thirtyMinutes,
              })}
              color={statusColor}
              data-autoid={`txtStatus-${item.id}`}
              value={status}
            />
          )}
        </div>
      </Tooltip>
      {item.bufferHeight > 0 && <div className="bg-stripe" style={{minHeight: item.bufferHeight}} />}
    </div>
  );
};
