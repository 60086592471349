import React, {useContext, useEffect, useState} from 'react';
import useSpendaPaymentServicesAPI, {IAccountResponse} from '../../../services/useSpendaPaymentServicesAPI';
import {ARModals, AccountUsage, PaymentProviderName} from '../../../model/constants/Constants';
import AppContext from '../../../context/app/appContext';
import {CommonPaymentMethods} from '../../../components/payments/CommonPaymentMethods';
import {PennyVerificationDialogStages} from '../../../components/dialog/PennyVerificationDialog';
import {useFeatureFlags} from '../../../hooks/useFeatureFlags';
import {useTenantInfo} from '../../../hooks/useTenantInfo';
import useConnectedSupplier from '../../../hooks/useConnectedSupplier';
import {PaymentAuthorityChangeAlertModal} from '../../../components/AccountsReceivable/modals/PaymentAuthorityChangeAlertModal';
import ARContext from '../../../context/ar-context/ARContext';
import {useParams} from 'react-router';
import {Typography} from 'spenda-ui-react';
interface IPaymentOptionsSettingsProps {
  isInSettingPage?: boolean;
}

export const PaymentOptionsSettings = (props: IPaymentOptionsSettingsProps) => {
  // Props
  const {isInSettingPage} = props;

  // Hooks
  const {isInBuyerContext, isInSupplierContext} = useTenantInfo();
  const {isBPSP} = useConnectedSupplier();
  const {supplierId} = useParams<{supplierId?: string}>();

  // Feature Flag
  const {paymentAuth72488, airplus79131} = useFeatureFlags().tenantOwned();
  const {scheduledPaymentsV2} = useFeatureFlags().supplierDriven();

  // Context
  const {marketplaceSupplier, tenant, availablePaymentMethodTypes} = useContext(AppContext);

  // Context
  const {arModalToShow, setArModalToShow} = useContext(ARContext);

  // States
  const [paymentAccounts, setPaymentAccounts] = useState<IAccountResponse[]>();
  const [dialogStage, setDialogStage] = useState<PennyVerificationDialogStages>(PennyVerificationDialogStages.NONE);
  const [isShowPaymentAuthorityChangeModal, setIsShowPaymentAuthorityChangeModal] = useState(false);

  // APIs
  const {getAccounts} = useSpendaPaymentServicesAPI();

  // useEffect
  useEffect(() => {
    fetchAccounts();
    return () => {
      setArModalToShow(undefined);
    };
  }, []);

  useEffect(() => {
    if (arModalToShow?.modal === ARModals.PaymentAuthorityChangeAlertModal) setIsShowPaymentAuthorityChangeModal(true);
  }, [arModalToShow?.modal]);

  // Functions
  const fetchAccounts = async () => {
    try {
      let query = undefined;

      if (isInBuyerContext) {
        query = {
          merchantGlobalID: marketplaceSupplier?.GlobalID,
          accountUsage: AccountUsage.BUYER,
          linkedSupplierID: supplierId,
        };
      } else if (isInSupplierContext) {
        query = {merchantGlobalID: tenant?.GlobalID, accountUsage: AccountUsage.SUPPLIER};
      }

      const accounts = await getAccounts(PaymentProviderName.Spenda_Payment_Services, query);
      setPaymentAccounts(accounts);
      return accounts;
    } catch {}
  };

  return (
    <div className="h-full pb-20">
      {!isInSettingPage ? (
        <>
          <Typography variant="h2" className="mb-6 pr-4 font-semibold">
            Payment options
          </Typography>
          <Typography variant="h3" className="pb-8 text-base font-medium">
            Payment methods: ({paymentAccounts?.length || 0})
          </Typography>
        </>
      ) : (
        <>
          <div className={`flex items-center justify-between border-b border-[#D8D8D8] py-4`}>
            <Typography
              className="font-poppins text-xl font-light text-[#333333]"
              data-autoid={`lblFinancialAdaptor`}
              variant="h2"
            >
              Payment options
            </Typography>
          </div>
        </>
      )}
      <CommonPaymentMethods
        dialogStage={dialogStage}
        setDialogStage={setDialogStage}
        loadPaymentMethods={fetchAccounts}
        paymentMethods={paymentAccounts}
        paymentAuth72488={paymentAuth72488}
        airplus79131={airplus79131}
        availablePaymentMethodTypes={availablePaymentMethodTypes}
        isBPSP={isBPSP(marketplaceSupplier)}
        marketplaceSupplier={marketplaceSupplier}
        scheduledPaymentsV2={scheduledPaymentsV2}
      />
      {isShowPaymentAuthorityChangeModal && (
        <PaymentAuthorityChangeAlertModal
          supplierId={supplierId}
          modalData={arModalToShow?.data}
          isOpen={isShowPaymentAuthorityChangeModal}
          onClose={() => setIsShowPaymentAuthorityChangeModal(false)}
        />
      )}
    </div>
  );
};
