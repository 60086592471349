import React, {useState} from 'react';
import {FormikProps} from 'formik';
import clsx from 'clsx';
import {PriceFormat} from '../../utils/formatter';
import {Box, Grid, Divider, makeStyles, Radio, RadioGroup, FormControlLabel} from '@material-ui/core';
import {ICustomer} from '../../model/customer/Customer';
import {AddLocationForm} from './AddressLocation';
import {ContactDetailsForm} from './ContactDetailsForm';
import {UnlinkDialog} from '../../components/dialog/UnlinkDialog';
import {useCustomersAPI} from '../../services/useCustomersAPI';
import {Button, Input, Option, Select, Switch, Typography} from 'spenda-ui-react';
import {ICreateCustomerClassValues} from '../../model/customer/CustomerClasses';

const useAddCustomerFormStyle = makeStyles(() => ({
  sideFormLabel: {
    fontSize: '1rem',
    color: '#333333',
    paddingBottom: '1rem',
  },
  sideFormValue: {
    fontSize: '1rem',
    color: '#999999',
    paddingBottom: '1rem',
  },
  title: {
    fontWeight: 500,
    fontSize: '1.5em',
    color: '#4D4D4D',
  },
  titleGeneric: {
    fontWeight: 500,
    fontSize: '1.5em',
    color: '#4D4D4D',
    textAlign: 'center',
  },
  buttonContainerDisable: {
    padding: '12px 12px',
    border: '1px solid lightgray',
    backgroundColor: '#EAEAEA',
    maxWidth: '300px',
    overflowX: 'hidden',
    borderRadius: '4px',
    marginBottom: '8px',
  },
  buttonContainer: {
    padding: '20px 10px 10px 0px',
    flexDirection: 'column',
    alignItems: 'center',
  },
  image: {
    borderRadius: '5px',
  },
  formControlLabel: {
    '& span.Mui-disabled': {
      color: '#ABABAB',
    },
  },
  radioBtn: {
    width: '100%',
    '& .MuiTypography-body1': {
      fontFamily: 'poppins',
      fontWeight: 500,
      color: '#333',
    },
    '& .MuiSvgIcon-root': {
      fill: 'hsl(var(--primary))',
    },
  },
}));

type RadioInputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  [key: string]: string | undefined;
};

export const AddCustomerForm = (
  props: FormikProps<ICustomer> & {
    accountData?: ICustomer;
    customerToEdit?: ICustomer;
    handleUnlinkCustomer?: (customer: ICustomer) => void;
    newCustLink?: (f: boolean) => void;
    customerId?: number;
    handlelinkCustomer?: (f: any) => void;
    accountCustData?: ICustomer;
    setRefNumberChange: any;
    formType?: string;
    customerInvoices?: ICustomer;
    isQuoteCustomer?: boolean;
    customerClasses?: ICreateCustomerClassValues[];
    hasPreSelectedClass?: number;
  },
) => {
  const classes = useAddCustomerFormStyle();
  const [showUnLinkDialog, setShowUnLinkDialog] = useState<boolean>(false);
  const [removeClassDetail, setRemoveClassDetail] = React.useState(false);
  const [editDetail, setEditDetail] = React.useState(false);
  const {
    values,
    touched,
    errors,
    handleChange,
    setFieldValue,
    handleBlur,
    setStatus,
    isQuoteCustomer,
    customerClasses,
    hasPreSelectedClass,
  } = props;
  const details = props.customerToEdit;
  const {getCustomerByReferenceNo} = useCustomersAPI();
  const GenericCustomer = () => {
    if (showUnLinkDialog) {
      handleClose();
    }
    setShowUnLinkDialog(!showUnLinkDialog);
  };
  const handleClose = () => {};
  const detailUnlink = () => {
    details && props.handleUnlinkCustomer && props.handleUnlinkCustomer(details);
    GenericCustomer();
  };

  return (
    <>
      <main className="flex flex-1 flex-col pl-2.5 pr-5 font-poppins">
        <Grid container justifyContent="flex-start" spacing={1} className={!!props?.formType ? '' : 'pb-5'}>
          <Grid item sm={props?.formType === 'salesOrderDialog' ? 12 : 8}>
            <Box display="flex" flexDirection="column" marginY="10px">
              <Grid container item className={`flex `} sm={12}>
                <RadioGroup
                  aria-label="IsIndividual"
                  id="IsIndividual"
                  name="IsIndividual"
                  onChange={e => {
                    setFieldValue('IsIndividual', e.target.value);
                  }}
                  value={values.IsIndividual}
                  onBlur={handleBlur}
                  className={classes.radioBtn}
                >
                  <Grid item container xs={12} spacing={1}>
                    <Grid item sm={2} style={{marginRight: '30px'}}>
                      <FormControlLabel
                        control={<Radio inputProps={{'data-autoid': 'rdoIndividual'} as RadioInputProps} />}
                        label="Individual"
                        value="true"
                      />
                    </Grid>
                    <Grid item sm={2}>
                      <FormControlLabel
                        control={<Radio inputProps={{'data-autoid': 'rdoCompany'} as RadioInputProps} />}
                        label="Company"
                        value="false"
                      />
                    </Grid>
                  </Grid>
                </RadioGroup>
                {values.IsIndividual === 'false' && (
                  <Divider className="w-full" style={{height: '2px !important', margin: '15px 0px'}} />
                )}
                <Grid container sm={6} spacing={0} item={true}>
                  {values.IsIndividual === 'false' && (
                    <>
                      <Grid item sm={6} style={{padding: '0px 12px 0px 0px'}}>
                        <Input
                          id="Name"
                          name="Name"
                          value={values.Name?.trimStart()}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={touched?.Name && Boolean(errors?.Name)}
                          variant={'outlined'}
                          label={'Company Name'}
                        />
                      </Grid>
                    </>
                  )}
                  {values.IsIndividual === 'false' && (
                    <>
                      <Grid item sm={5} style={{padding: '0px 12px 0px 0px'}}>
                        <Input
                          id="RefNumber"
                          label="Account Ref"
                          name="RefNumber"
                          value={values.RefNumber}
                          error={touched?.RefNumber && Boolean(errors?.RefNumber)}
                          variant={'outlined'}
                          onBlur={e => {
                            handleBlur(e);
                            getCustomerByReferenceNo(values?.RefNumber || '').then(data => {
                              if (data.length === 1 && values?.RefNumber) {
                                props.setRefNumberChange(true);
                              } else {
                                props.setRefNumberChange(false);
                              }
                            });
                          }}
                          onChange={e => {
                            handleChange(e);
                            getCustomerByReferenceNo(values?.RefNumber || '').then(data => {
                              if (data.length === 1 && values?.RefNumber) {
                                props.setRefNumberChange(true);
                              } else {
                                props.setRefNumberChange(false);
                              }
                            });
                          }}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
                <Grid container sm={6} spacing={0} item={true}>
                  {values.IsIndividual === 'false' && (
                    <>
                      <Grid item sm={6} style={{padding: '0px 12px 0px 0px'}}>
                        <Input
                          id="ABN"
                          name="ABN"
                          value={values.ABN}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={touched?.ABN && Boolean(errors?.ABN)}
                          variant={'outlined'}
                          label={'ABN'}
                        />
                      </Grid>
                    </>
                  )}

                  {values.IsIndividual === 'false' && (
                    <>
                      <Grid item sm={6} style={{padding: '0px 12px 0px 0px'}}>
                        <Input
                          id="Phone1"
                          name="Phone1"
                          value={values.Phone1}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={touched?.Phone1 && Boolean(errors?.Phone1)}
                          variant="outlined"
                          label="Phone"
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
              {values.IsIndividual === 'false' && isQuoteCustomer && customerClasses?.length && (
                <Grid container item className={`!mt-8 flex pr-[12px]`} sm={12}>
                  <Select
                    name="customerClass"
                    size="lg"
                    variant="outlined"
                    error={touched?.ClassID && Boolean(errors.ClassID)}
                    label={
                      !touched?.ClassID && !Boolean(errors.ClassID)
                        ? 'Please select a customer class'
                        : 'Please select a customer class (required)'
                    }
                    value={hasPreSelectedClass ? hasPreSelectedClass?.toString() : values?.ClassID?.toString()}
                    disabled={hasPreSelectedClass ? true : false}
                    onChange={(value: string) => {
                      setFieldValue('ClassID', value);
                    }}
                    arrow={hasPreSelectedClass ? false : undefined}
                  >
                    {customerClasses?.map((options, index) => {
                      return (
                        <Option value={options.ID?.toString()} key={index}>
                          {options.Name}
                        </Option>
                      );
                    })}
                  </Select>
                </Grid>
              )}
              {values.IsIndividual === 'false' && (
                <Divider
                  className="w-full"
                  style={{
                    height: '2px !important',
                    backgroundColor: '#F1F1F1',
                    margin: '26px 0px 20px',
                  }}
                />
              )}
              <ContactDetailsForm {...props} />
              <Divider
                className="w-full"
                style={{
                  height: '2px !important',
                  backgroundColor: '#F1F1F1',
                  margin: '24px 0',
                }}
              />
              <Grid container item xs={12}>
                <Grid item container xs={5} className={clsx('flex flex-col')}>
                  <div className="text-xl font-medium text-gray-800">
                    <Typography variant="h3" className="font-poppins font-semibold text-gray-800">
                      Merchant Surcharge
                    </Typography>
                    {
                      <Typography variant="xsmall" className="font-poppins">
                        Exempt from all merchant surcharges.
                      </Typography>
                    }
                  </div>
                </Grid>
                <Grid container sm={7} spacing={1} item={true} alignItems="center">
                  <Grid item sm={7}>
                    {
                      <Box>
                        <Switch
                          name='IsExemptFromMerchantSurcharge'
                          checked={values.IsExemptFromMerchantSurcharge === 'true' ? true : false}
                          onChange={e => {
                            setFieldValue('IsExemptFromMerchantSurcharge', String(e.target.checked));
                          }}
                        />
                      </Box>
                    }
                  </Grid>
                </Grid>
              </Grid>
              <Divider
                className="w-full"
                style={{
                  height: '2px !important',
                  backgroundColor: '#F1F1F1',
                  margin: '24px 0',
                }}
              />

              <AddLocationForm {...props} customerID={props?.customerId} />
            </Box>
          </Grid>

          {!props?.formType ? (
            props.accountData?.AccountCustomerClassID ? (
              <Grid
                container
                sm={4}
                justifyContent="center"
                alignItems="center"
                item={true}
                className="flex flex-col"
                style={{
                  backgroundColor: '#FAFAFA',
                  height: 'max-content',
                  padding: '20px 24px',
                  border: '1px solid #EAEAEA',
                }}
              >
                <div className="align-center flex flex-col justify-center">
                  <p className={`${classes.title}`}>*Sales Invoices will be posted to this account customer: </p>
                  <Divider className="w-full" style={{height: '2px !important', margin: '20px 0px'}} />
                  <Grid container spacing={1}>
                    <Grid item sm={4} className="flex">
                      <p className={`${classes.sideFormLabel}`}>ABN:</p>
                    </Grid>
                    <Grid item sm={4} className="flex">
                      <p className={`${classes.sideFormValue}`}>{props.accountCustData?.ABN}</p>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item sm={4} className="flex">
                      <p className={`${classes.sideFormLabel}`}>Account Reference: </p>
                    </Grid>
                    <Grid item sm={4} className="flex">
                      <p className={`${classes.sideFormValue}`}>{props.accountCustData?.RefNumber}</p>
                    </Grid>
                  </Grid>
                  <Divider
                    className="w-full"
                    style={{height: '2px !important', backgroundColor: '#F1F1F1', margin: '0px 0px 20px 0px'}}
                  />
                  <p className={`${classes.sideFormLabel}`}>Primary Contact: </p>
                  <Grid container spacing={1}>
                    <Grid item sm={4} className="flex">
                      <p className={`${classes.sideFormLabel}`}>First Name: </p>
                    </Grid>
                    <Grid item sm={4} className="flex">
                      <p className={`${classes.sideFormValue}`}>{props.accountCustData?.Contacts?.[0].FirstName}</p>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item sm={4} className="flex">
                      <p className={`${classes.sideFormLabel}`}>Last Name: </p>
                    </Grid>
                    <Grid item sm={4} className="flex">
                      <p className={`${classes.sideFormValue}`}>{props.accountCustData?.Contacts?.[0].LastName}</p>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item sm={4} className="flex">
                      <p className={`${classes.sideFormLabel}`}>Email Address: </p>
                    </Grid>
                    <Grid item sm={4} className="flex">
                      <p className={`${classes.sideFormValue}`}>{props.accountCustData?.Contacts?.[0].EmailAddress}</p>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item sm={4} className="flex">
                      <p className={`${classes.sideFormLabel}`}>Phone No: </p>
                    </Grid>
                    <Grid item sm={4} className="flex">
                      <p className={`${classes.sideFormValue}`}>{props.accountCustData?.Phone1}</p>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item sm={4} className="flex">
                      <p className={`${classes.sideFormLabel}`}>Mobile No: </p>
                    </Grid>
                    <Grid item sm={4} className="flex">
                      <p className={`${classes.sideFormValue}`}>-</p>
                    </Grid>
                  </Grid>
                  <Divider
                    className="w-full"
                    style={{height: '2px !important', backgroundColor: '#F1F1F1', margin: '0px 0px 20px 0px'}}
                  />
                  <Grid container spacing={1}>
                    <Grid item sm={4} className="flex">
                      <p className={`${classes.sideFormLabel}`}>Customer Class: </p>
                    </Grid>
                    <Grid item sm={4} className="flex">
                      <p className={`${classes.sideFormValue}`}>{props.accountCustData?.ClassName}</p>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item sm={4} className="flex">
                      <p className={`${classes.sideFormLabel}`}>Billing Address: </p>
                    </Grid>
                    <Grid item sm={4} className="flex">
                      <p className={`${classes.sideFormValue}`}>{props.accountCustData?.BillingAddressString}</p>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item sm={4} className="flex">
                      <p className={`${classes.sideFormLabel}`}>Credit Limit: </p>
                    </Grid>
                    <Grid item sm={4} className="flex">
                      <p className={`${classes.sideFormValue}`}>{props.accountCustData?.CreditLimitString}</p>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item sm={4} className="flex">
                      <p className={`${classes.sideFormLabel}`}>Trading Terms: </p>
                    </Grid>
                    <Grid item sm={4} className="flex">
                      <p className={`${classes.sideFormValue}`}>
                        {props.accountCustData?.TradingTermType?.includes('_')
                          ? props.accountCustData?.TradingTermType?.replaceAll('_', ' ')
                          : props.accountCustData?.TradingTermType}
                      </p>
                    </Grid>
                  </Grid>
                  <Divider
                    className="w-full"
                    style={{height: '2px !important', backgroundColor: '#F1F1F1', margin: '0px 0px 20px 0px'}}
                  />
                  <Grid container spacing={1}>
                    <Grid item sm={4} className="flex">
                      <p className={`${classes.sideFormLabel}`}>Available Credit: </p>
                    </Grid>
                    <Grid item sm={4} className="flex">
                      <p className={`${classes.sideFormValue}`}>{props.accountCustData?.CreditLimitString}</p>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item sm={4} className="flex">
                      <p className={`${classes.sideFormLabel}`}>Overdue Invoices: </p>
                    </Grid>
                    <Grid item sm={4} className="flex">
                      <p className={`${classes.sideFormValue}`}>
                        {props.customerInvoices?.TotalOverdueAmount
                          ? PriceFormat(props.customerInvoices?.TotalOverdueAmount)
                          : '-'}
                      </p>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item sm={4} className="flex">
                      <p className={`${classes.sideFormLabel}`}>Unpaid Invoices: </p>
                    </Grid>
                    <Grid item sm={4} className="flex">
                      <p className={`${classes.sideFormValue}`}>
                        {' '}
                        {props.customerInvoices?.TotalDueAmount
                          ? PriceFormat(props.customerInvoices?.TotalDueAmount)
                          : '-'}
                      </p>
                    </Grid>
                  </Grid>
                  <div className={`${classes.buttonContainer} align-center flex w-full justify-center`}>
                    <div className="flex">
                      <Button
                        variant="outlined"
                        onClick={() => {
                          setStatus(false);
                          setRemoveClassDetail(false);
                          setEditDetail(true);
                          GenericCustomer();
                        }}
                      >
                        Unlink Account
                      </Button>
                    </div>
                  </div>
                </div>
              </Grid>
            ) : props.accountData?.ClassID ? (
              <Grid
                item
                sm={4}
                justifyContent="center"
                className="flex flex-col"
                style={{
                  borderColor: '#EAEAEA',
                  backgroundColor: '#FAFAFA',
                  height: 'max-content',
                  padding: '20px 64px',
                }}
              >
                <div className="align-center flex flex-col justify-center" style={{height: '354px'}}>
                  <p className={`${classes.titleGeneric}`}>
                    This Operational Customer has currently been assigned the class:
                  </p>

                  <div className={`${classes.buttonContainer} align-center  flex w-full justify-center`}>
                    <input
                      disabled
                      className={`${classes.buttonContainerDisable} `}
                      value={props.accountData?.ClassName}
                    />

                    <div className="flex ">
                      <Button
                        variant="outlined"
                        onClick={e => {
                          setStatus(false);
                          e.stopPropagation();
                          setRemoveClassDetail(true);
                          GenericCustomer();
                        }}
                      >
                        Remove
                      </Button>
                    </div>
                  </div>
                </div>
              </Grid>
            ) : (
              <Grid
                container
                sm={4}
                item={true}
                justifyContent="center"
                alignItems="center"
                className="flex flex-col"
                style={{
                  border: '1px solid #EAEAEA',
                  backgroundColor: '#FAFAFA',
                  height: 'max-content',
                  padding: '20px 24px',
                }}
              >
                <div className="align-center flex flex-col justify-center" style={{height: '354px'}}>
                  <p className={`${classes.title}`}>Define Posting Behaviour</p>
                  <div className={`${classes.buttonContainer} align-center flex w-full justify-center`}>
                    <div className="flex">
                      {props.customerId ? (
                        <Button
                          onClick={e => {
                            setStatus(false);
                            e.stopPropagation();
                            setRemoveClassDetail(false);
                            props.handlelinkCustomer && props.handlelinkCustomer(false);
                          }}
                        >
                          Link
                        </Button>
                      ) : (
                        <Button
                          onClick={e => {
                            setStatus(false);
                            e.stopPropagation();
                            setRemoveClassDetail(false);
                            props.newCustLink && props.newCustLink(true);
                            props.handleSubmit();
                          }}
                        >
                          Link
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </Grid>
            )
          ) : null}
        </Grid>
      </main>
      <UnlinkDialog
        open={showUnLinkDialog}
        unLinkDetail={true}
        detailUnlink={detailUnlink}
        removeClassDetail={removeClassDetail}
        editDetail={editDetail}
        onClose={GenericCustomer}
      ></UnlinkDialog>
    </>
  );
};
