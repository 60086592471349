import React from 'react';
import {
  Button,
  Dialog,
  DialogBody,
  DialogHeader,
  DialogFooter,
  Typography,
  IconButton,
  Textarea,
  Select,
  Option,
} from 'spenda-ui-react';
import * as Yup from 'yup';
import {Form, Formik} from 'formik';
import { CloseCross } from '../../assets/svg/CloseCross';

interface IQuoteDepositDialog {
  open: boolean;
  handleClose: () => void;
  handleSave: () => void;
}

export const QuoteDepositDialog = (props: IQuoteDepositDialog) => {
  const {open, handleClose, handleSave} = props;

  const validationSchema = Yup.object().shape({
    deposit: Yup.string().required('Deposit is required'),
    paymentMode: Yup.string().required('Mode of payment is required'),
    other: Yup.string().when('paymentMode', {
      is: 'Other',
      then: Yup.string().required('Please specify other payment mode'),
    }),
  });

  return (
    <Dialog
      open={open}
      handler={() => {}}
      className={`flex h-full !w-full !min-w-[20%] flex-col items-center justify-center sm:block sm:h-auto sm:!max-w-[339px] sm:p-0`}
    >
      <DialogHeader className="flex flex-col gap-1">
        <div className="flex w-full justify-end">
          <IconButton variant="outlined" onClick={handleClose}>
            <CloseCross />
          </IconButton>
        </div>
        <Typography className="flex items-center justify-center text-center text-black" variant="h2">
          How much you want to mark the deposit manually?
        </Typography>
      </DialogHeader>
      <DialogBody className="flex w-full min-w-[290px] flex-col items-center justify-center gap-5">
        <Typography className={`min-w-[268px] text-center font-normal text-black-900`} variant="small">
          Enter the customer's deposit amount and choose the payment method.
        </Typography>
        <div className="flex w-[308px] items-center justify-between rounded-md bg-[#D3E5EF4D] px-2.5 py-1">
          <Typography variant="small" className="flex items-center justify-center font-normal text-black-900">
            Set deposit
          </Typography>
          <Typography variant="small" className="p-2 font-normal text-black-900">
            $100
          </Typography>
        </div>
        <Formik
          initialValues={{deposit: '', paymentMode: '', other: ''}}
          validationSchema={validationSchema}
          onSubmit={() => {}}
        >
          {({}) => (
            <Form>
              <div className="flex w-[308px] flex-col justify-center rounded-md bg-[#D3E5EF4D] p-4">
                <div className="flex items-center justify-between py-3">
                  <Typography className="font-poppins text-sm text-black-800"> Deposit value: </Typography>
                  <div className="flex w-[103px] items-end justify-end">
                    <Textarea
                      name="deposit"
                      data-autoid="txtDeposit"
                      containerProps={{className: '!min-w-[103px]'}}
                      label="$0.00"
                      className="!h-[45px] min-h-[45px] w-[103px]"
                    />
                  </div>
                </div>
                <div className="flex w-[276px] flex-col items-center justify-center gap-4">
                  <Select size="lg" variant="outlined" label="Mode of payment">
                    <Option> Other </Option>
                  </Select>
                  <Textarea
                    name=""
                    data-autoid="txtOther"
                    label="Type your own"
                    className="flex !h-[45px] min-h-[45px] items-center justify-center"
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </DialogBody>
      <DialogFooter>
        <div className="flex w-full flex-col items-center justify-center p-2">
          <div className="flex w-[300px] flex-col justify-between gap-2">
            <Button onClick={handleSave}>Save Deposit</Button>
          </div>
        </div>
      </DialogFooter>
    </Dialog>
  );
};
