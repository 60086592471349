import React from 'react';
import {ARDialogTemplate} from '../ARDialogTemplate';
import {Button, Typography} from 'spenda-ui-react';
interface IUnableToCreateClaimRequestDialogProps {
  isOpen: boolean;
  onClose: () => void;
}
export const UnableToCreateClaimRequestDialog = (props: IUnableToCreateClaimRequestDialogProps) => {
  //Props
  const {isOpen, onClose} = props;

  return (
    <ARDialogTemplate
      dialogProps={{size: 'xs', open: isOpen, className: '!min-w-[460px] !max-w-[460px]'}}
      isFullScreen
      header={
        <Typography className="font-light text-[#333333]" variant="h2">
          Create Claim
        </Typography>
      }
      body={
        <div className="my-12">
          <p className="text-center font-poppins text-base font-light text-black-800">
            You are unable to create a claim as this invoice is associated with an early credit note.
          </p>
        </div>
      }
      footer={
        <div className="flex w-full flex-row items-center justify-center">
          <Button data-autoid={`btnDone`} onClick={() => onClose()} variant="filled" color="primary">
            Close
          </Button>
        </div>
      }
      handleClose={() => onClose()}
    />
  );
};
