import React from 'react';
import {Box, makeStyles} from '@material-ui/core';
import {SButton} from '../buttons/Button';
import NextArrowIcon from '../../assets/svg/next-arrow.svg';
import {SModal} from '../modals/modalSpendaMeterialUI';
import {ChatWidget} from './ChatWidget';
import CloseIcon from '@material-ui/icons/Close';
import {capitalizeInitials} from '../../utils/formatter';
import {GetBackgroundCSS} from './Onboarding';
import {APFooter} from '../accountsPayableOnboarding/APFooter';

const useWelcomeStyles = makeStyles({
  apModalBox: {
    maxWidth: '800px',
  },
  boxHeight: {
    fontFamily: 'Poppins, sans-serif !important',
    height: 'calc(100% - 97px)',
  },
  apOndoardingTitle: {
    fontSize: '28px',
  },
  apOndoardingDesc: {
    fontSize: '16px',
    fontWeight: 500,
  },
});
interface WelcomeProps {
  onNextClick: () => void;
  userName?: string;
  handleClose: () => void;
  isBuyer: boolean;
  isAPOnboarding?: boolean;
  enableWelcomeNextBtn?: boolean;
}

export const Welcome: React.FunctionComponent<WelcomeProps> = ({
  onNextClick,
  userName,
  handleClose,
  isBuyer,
  isAPOnboarding,
  enableWelcomeNextBtn,
}: WelcomeProps) => {
  const getInitialUserText = () => {
    const today = new Date();
    const curHr = today.getHours();
    let initialMessage = '';
    if (curHr < 12) {
      initialMessage = 'Good Morning';
    } else if (curHr < 18) {
      initialMessage = 'Good Afternoon';
    } else {
      initialMessage = 'Good Evening';
    }
    return initialMessage;
  };
  const classes = useWelcomeStyles();

  return (
    <SModal open>
      <div className={`spenda-color flex h-full max-w-full items-center justify-center text-xl ${GetBackgroundCSS()}`}>
        <div
          className={`modal-box max-w-3xl ${
            isAPOnboarding ? `${classes.apModalBox} px-3 py-3` : 'flex flex-col justify-center px-12 py-3'
          }`}
        >
          {isAPOnboarding && (
            <Box className="flex justify-between">
              <Box className="flex w-full justify-end text-right">
                <span className="ap-onboarding-close-icon" onClick={handleClose} data-autoid="btnClose">
                  <CloseIcon />
                </span>
              </Box>
            </Box>
          )}
          {isBuyer && !isAPOnboarding && (
            <span className="closeIcon onboarding-close-icon" onClick={handleClose} data-autoid="btnClose">
              <CloseIcon />
            </span>
          )}
          <div
            className={`flex flex-row ${
              isAPOnboarding ? `${classes.boxHeight} flex items-center justify-between px-10 ` : 'justify-center'
            } `}
          >
            <Box className={`bg-white-inner`}>
              {isAPOnboarding ? (
                <h2 className={`${classes.apOndoardingTitle} pb-8 text-center`}>
                  "Welcome, <span className="font-bold">{capitalizeInitials(userName)}</span>"
                </h2>
              ) : (
                <h2 className="simple-modal-title pb-3 text-center text-4xl">
                  "{getInitialUserText()}, <span className="font-bold">{capitalizeInitials(userName)}</span>"
                </h2>
              )}

              <p className="simple-modal-description mb-8 text-center">
                To set up your account and get started, please press <span className="font-bold"> 'Next'</span> or tap
                on <span className="font-bold"> 'Live Chat'</span> if you need any help along the way.
              </p>
              {isAPOnboarding && enableWelcomeNextBtn && false && (
                <p
                  className={`${
                    isAPOnboarding ? `${classes.apOndoardingDesc} mb-12` : 'simple-modal-description mb-8 text-center '
                  }`}
                >
                  "Already Connected to Xero. Click
                  <span className={isAPOnboarding ? '' : 'font-bold'}> 'Next'</span> to continue."
                </p>
              )}
            </Box>
          </div>
          {!isAPOnboarding && (
            <>
              <Box display="flex" justifyContent="center">
                <SButton
                  color={'blueShade'}
                  type="submit"
                  width="m"
                  lineHeight="m"
                  fontSize="m"
                  onClick={() => onNextClick()}
                  className="common-next-btn"
                  data-autoid="btnNext"
                >
                  Next
                  <img src={NextArrowIcon} alt="Next Arrow Icon" className="" data-autoid="imgNextArrow" />
                </SButton>
              </Box>
              <div className="live-chat-section">
                <ChatWidget />
              </div>
            </>
          )}
          {isAPOnboarding && (
            <APFooter onNextClick={onNextClick} isDisabled={!enableWelcomeNextBtn} isLoading={!enableWelcomeNextBtn} />
          )}
        </div>
      </div>
    </SModal>
  );
};
