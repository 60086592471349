import React from 'react';

export const Arrow = (props: {width?: string; height?: string; className?: string; onClick?: any}) => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.8079 12.4904C19.8029 11.9424 19.5821 11.4184 19.1934 11.0321L14.7246 6.55291C14.3183 6.14903 13.6621 6.14903 13.2559 6.55291C13.0587 6.7485 12.9477 7.01474 12.9477 7.29249C12.9477 7.57024 13.0587 7.83649 13.2559 8.03208L16.6829 11.4487H6.26628C5.69098 11.4487 5.22461 11.9151 5.22461 12.4904C5.22461 13.0657 5.69098 13.5321 6.26628 13.5321H16.6829L13.2559 16.9592C12.8474 17.3647 12.8451 18.0247 13.2507 18.4331C13.6562 18.8416 14.3161 18.8439 14.7246 18.4383L19.1934 13.9592C19.5846 13.5703 19.8056 13.0421 19.8079 12.4904Z"
        fill="#1C78AD"
      />
    </svg>
  );
};
