import React from 'react';

export const RemoveAttachment = (props: {width?: string; height?: string; className?: string}) => {
  return (
    <svg
      width="14px"
      height="12px"
      className={props.className}
      viewBox="0 0 14 12"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Remove</title>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="4-files-attached" transform="translate(-1084.000000, -688.000000)" fill="#CCCCCC">
          <g id="Group-11" transform="translate(986.000000, 268.000000)">
            <g id="Group-5-Copy" transform="translate(10.000000, 411.000000)">
              <g id="Icons/Documents/delete-Copy" transform="translate(88.000000, 9.120000)">
                <path
                  d="M11.0837382,1.37226265e-05 L5.80807149,1.37226265e-05 C4.90740496,-0.00275489864 4.05668411,0.41355072 3.50623815,1.12644425 L0.122904819,5.47519425 C-0.0409682731,5.68585552 -0.0409682731,5.98086631 0.122904819,6.19152758 L3.50623815,10.5402776 C4.05668411,11.2531711 4.90740496,11.6694767 5.80807149,11.6667081 L11.0837382,11.6667081 C12.6937692,11.6647657 13.9984763,10.3600586 14.0004048,8.75002758 L14.0004048,2.91669425 C13.9984763,1.30666324 12.6937692,0.00195615042 11.0837382,1.37226265e-05 Z M12.8337382,8.75002758 C12.8337382,9.71652589 12.0502365,10.5000381 11.0837382,10.5000381 L5.80807149,10.5000381 C5.26763787,10.5019071 4.75709258,10.2522401 4.42673815,9.82452758 L1.32282149,5.83336092 L4.42673815,1.84219425 C4.75709258,1.41448175 5.26763787,1.16481476 5.80807149,1.16668371 L11.0837382,1.16668371 C12.0502365,1.16668371 12.8337382,1.95019594 12.8337382,2.91669425 L12.8337382,8.75002758 Z M10.0378215,4.78744425 L8.99190482,5.83336092 L10.0378215,6.87927758 C10.1894628,7.02573769 10.2502788,7.24262302 10.1968948,7.44657352 C10.1435108,7.65052402 9.98423459,7.80980025 9.78028409,7.86318426 C9.57633359,7.91656826 9.35944826,7.85575226 9.21298815,7.70411092 L8.16707149,6.65819425 L7.12115482,7.70411092 C6.89226714,7.92517804 6.5284387,7.92201647 6.30342732,7.69700508 C6.07841593,7.4719937 6.07525436,7.10816527 6.29632149,6.87927758 L7.34223815,5.83336092 L6.29632149,4.78744425 C6.07525436,4.55855657 6.07841593,4.19472813 6.30342732,3.96971675 C6.5284387,3.74470536 6.89226714,3.74154379 7.12115482,3.96261092 L8.16707149,5.00852758 L9.21298815,3.96261092 C9.44187584,3.74154379 9.80570427,3.74470536 10.0307157,3.96971675 C10.255727,4.19472813 10.2588886,4.55855657 10.0378215,4.78744425 Z"
                  id="Shape"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
