import React from 'react';

export const IconBankBlue = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="25" height="24" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>Bank</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0011 15.3343C16.0011 15.7025 15.7026 16.001 15.3344 16.001H0.667745C0.299555 16.001 0.0010781 15.7025 0.0010781 15.3343C0.0010781 14.9662 0.299555 14.6677 0.667745 14.6677H15.3344C15.7026 14.6677 16.0011 14.9662 16.0011 15.3343ZM0.195078 5.70235C-0.0979188 5.15889 -0.058422 4.49647 0.297078 3.99168C0.581917 3.57376 0.963374 3.23072 1.40908 2.99168L6.40974 0.389013C7.40724 -0.129671 8.59492 -0.129671 9.59241 0.389013L14.5924 2.99368C15.0381 3.23272 15.4196 3.57576 15.7044 3.99368C16.0599 4.49847 16.0994 5.16089 15.8064 5.70435C15.4912 6.30068 14.8703 6.67204 14.1957 6.66768H14.0011V12.001H14.6677C15.0359 12.001 15.3344 12.2995 15.3344 12.6677C15.3344 13.0359 15.0359 13.3343 14.6677 13.3343H1.33441C0.966222 13.3343 0.667745 13.0359 0.667745 12.6677C0.667745 12.2995 0.966222 12.001 1.33441 12.001H2.00108V6.66768H1.80641C1.13122 6.6719 0.509896 6.29967 0.195078 5.70235ZM3.33441 12.001H5.33441V6.66768H3.33441V12.001ZM6.66774 6.66768V12.001H9.33441V6.66768H6.66774ZM12.6677 6.66768H10.6677V12.001H12.6677V6.66768ZM1.37641 5.08435C1.46169 5.24156 1.62759 5.33802 1.80641 5.33435H14.1957C14.3746 5.33802 14.5405 5.24156 14.6257 5.08435C14.688 4.98016 14.6817 4.84876 14.6097 4.75101C14.4482 4.51095 14.2312 4.31337 13.9771 4.17501L8.97708 1.57035C8.36558 1.25299 7.63791 1.25299 7.02641 1.57035L2.02641 4.17501C1.7724 4.31382 1.55543 4.51158 1.39374 4.75168C1.32163 4.84901 1.3148 4.98005 1.37641 5.08435Z"
        fill="#1C78AD"
      />
    </svg>
  );
};
