import React, {useState} from 'react';
import {SModal} from '../modals/modalSpendaMeterialUI';
import CloseIcon from '@material-ui/icons/Close';
import {Box, makeStyles} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import backButton from '../../assets/png/back-button.png';
import {APFooter} from './APFooter';
export interface ITermsConditionsProps {
  handleClose: () => void;
  onBackPress: (backStep?: number) => void;
  onNextClick: () => void;
}

const useTermsConditionsStyles = makeStyles({
  apOnboardingModal: {
    maxWidth: '800px',
  },
  apBusinessHeader: {
    borderBottom: '1px solid #CCCCCC',
    '& h2': {
      fontFamily: 'Poppins, sans-serif !important',
      fontWeight: 300,
      fontSize: '28px',
      color: '#333333',
    },
  },
  backBtn: {
    maxWidth: '40px',
    height: '40px',
  },
  conditionScroll: {
    '&::-webkit-scrollbar-track': {
      background: '#EAEFF2',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#1C78AD',
    },
  },
  boxHeight: {
    height: 'calc(100% - 118px)',
    overflow: 'hidden',
    '& h2': {
      fontFamily: 'Poppins, sans-serif !important',
      fontSize: '28px',
    },
    '& button': {
      fontFamily: 'Poppins, sans-serif !important',
    },
    '& p, button, span': {
      fontFamily: 'Poppins, sans-serif !important',
      fontSize: '13px',
      color: '#333',
    },
  },
  termsPara: {
    fontSize: '12px',
  },
  termsBot: {
    margin: '-27px  0 0 -5px',
  },
  icon: {
    borderRadius: 4,
    width: 16,
    height: 16,
    margin: '2px',
    border: '1px solid #1C78AD',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
});
type CheckboxInputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  [key: string]: string | undefined;
};
export const TermsConditions = (props: ITermsConditionsProps) => {
  const classes = useTermsConditionsStyles();

  const [termschecked, setTermsChecked] = useState<boolean>(false);
  const [feeschecked, setFeesChecked] = useState<boolean>(false);
  const termsConditionsChanged = () => {
    setTermsChecked(!termschecked);
  };
  const feesChargesChanged = () => {
    setFeesChecked(!feeschecked);
  };

  return (
    <SModal open>
      <div className={`text-xl spenda-color flex justify-center items-center h-full max-w-full`}>
        <div className={`modal-box ${classes.apOnboardingModal} px-3 py-3`}>
          <Box className={`${classes.apBusinessHeader} flex justify-between -mx-3 px-3 pb-3`}>
            <span className="cursor-pointer" onClick={() => props.onBackPress()} data-autoid="btnBack">
              <img src={backButton} alt="Back Button" className={classes.backBtn} />
            </span>
            <h2 className={`text-center w-full`}>Terms &amp; Conditions</h2>
            <Box className="text-right flex justify-end">
              <span className="ap-onboarding-close-icon" onClick={props.handleClose} data-autoid="btnClose">
                <CloseIcon />
              </span>
            </Box>
          </Box>
          <Box className={`${classes.boxHeight} w-full px-5 flex justify-between`}>
            <Box className={`pt-10 pl-4 w-full pr-5`}>
              <Box
                className={`p-3 pr-2 mt-3`}
                border="1px solid #EDEDED"
                boxShadow="0px 0px 0px 0px rgba(0,0,0,.07)"
                borderRadius="8px"
              >
                <Box className={`${classes.conditionScroll} pr-4 overflow-y-auto`} style={{maxHeight: '215px'}}>
                  <p className={`${classes.termsPara} color-gray`}>
                    These Terms and Conditions of service and use, as amended from time to time (and including any
                    schedules or annexures) (Terms and Conditions) constitute an agreement between Cirralto and any
                    party that registers to use the Platform as a User and who is approved by Cirralto or any of its
                    Related Bodies Corporate to use the Platform. Each User should read these Terms and Conditions
                    carefully
                    <br />
                    <br /> By creating a User Account and/or using the Platform, you as a User agree to these Terms and
                    Conditions and to any amendments which may be made from time to time in the manner provided for
                    below.
                    <br />
                    <br />
                    <b>1. MEANINGS OF KEY TERMS IN THESE TERMS AND CONDITIONS</b>
                    <br />
                    Business means the business operated by a User, as specified by that User during the Account
                    registration process.
                  </p>
                </Box>
              </Box>
              <Box className="p-3">
                <FormControl component="fieldset" className={`APCheckboxField w-full`}>
                  <FormGroup className="w-full flex">
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="termsConditions"
                          size="small"
                          icon={<span className={classes.icon} />}
                          onChange={termsConditionsChanged}
                          inputProps={{'data-autoid': 'chkTermsConditions'} as CheckboxInputProps}
                        />
                      }
                      label="Agree to terms &amp; conditions"
                      className="text-xs color-gray"
                      data-autoid="lblTermsConditions"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="feesCharges"
                          size="small"
                          icon={<span className={classes.icon} />}
                          onChange={feesChargesChanged}
                          inputProps={{'data-autoid': 'chkFeesCharges'} as CheckboxInputProps}
                        />
                      }
                      label="Agree to fees &amp; charges"
                      className="text-xs color-gray"
                      data-autoid="lblFeesCharges"
                    />
                  </FormGroup>
                </FormControl>
              </Box>
            </Box>
          </Box>
          <APFooter onNextClick={() => props.onNextClick()} isDisabled={!feeschecked || !termschecked} />
        </div>
      </div>
    </SModal>
  );
};
