import React, {useContext, useState} from 'react';
import {ARDialogTemplate} from '../ARDialogTemplate';
import {Button, Typography} from 'spenda-ui-react';
import COAWarningDialog from './COAWarningDialog';
import {DatTypes, PostingTypeOptions} from '../../../model/constants/Constants';
import {ISelectedFinancialAdaptorRow} from './InvoiceFromFinancialAdaptorDialog';
import AppContext from '../../../context/app/appContext';
import {PriceFormat} from '../../../utils/formatter';

interface ISelectPostingOptionsProps {
  isOpen: boolean;
  formattedAdjustmentAmount: string;
  supplier: string | undefined;
  isLoading?: boolean;
  onClose: () => void;
  handleDone: () => void;
  handlePostingType: (selectedPostingType: PostingTypeOptions) => void;
  selectedPostingType: PostingTypeOptions | undefined;
  isCOARoundeingSelected?: boolean;
  datTypeID?: number;
  totalCapricornAmount?: number;
  selectedTotalBalance?: number;
  selectedTotalInc?: number;
  adjustmentAmount?: number;
  selectedFinancialAdaptorRows?: ISelectedFinancialAdaptorRow[];
}

export const SelectPostingOptions = (props: ISelectPostingOptionsProps) => {
  //Props
  const {
    isOpen,
    formattedAdjustmentAmount,
    supplier,
    isCOARoundeingSelected,
    selectedPostingType,
    isLoading,
    onClose,
    selectedFinancialAdaptorRows,
    handleDone,
    datTypeID,
    handlePostingType,
    selectedTotalBalance,
    selectedTotalInc,
    totalCapricornAmount,
    adjustmentAmount,
  } = props;

  // State
  const [isCOAWarningDialog, setIsCOAWarningDialog] = useState(false);

  const {marketplaceSupplier} = useContext(AppContext);

  // Constants
  const showSalesAdjustment = true;
  const showDoNothing = true;
  const showRoundingAdjustment = adjustmentAmount && Math.abs(adjustmentAmount) <= 0.1;
  const showPartiallyPayInvoice =
    selectedFinancialAdaptorRows?.length === 1 &&
    datTypeID === DatTypes.StagedPurchaseInvoice &&
    totalCapricornAmount &&
    selectedTotalBalance &&
    selectedTotalInc &&
    totalCapricornAmount <= selectedTotalBalance &&
    totalCapricornAmount < selectedTotalInc;
  const transactionType = datTypeID === DatTypes?.StagedPurchaseInvoice ? 'Invoices' : 'Credit Note';
  const formattedCapricornAmount = totalCapricornAmount && PriceFormat(totalCapricornAmount);

  const options = [
    {
      key: PostingTypeOptions.PostAsASalesAdjustment,
      label: 'Post as a sales adjustment',
      description: `We’ll create an invoice or credit note for ${formattedAdjustmentAmount} in your ${supplier} current statement account.`,
      condition: showSalesAdjustment,
    },
    {
      key: PostingTypeOptions.PostAsARoundingAdjustment,
      label: 'Post as a rounding adjustment',
      description: `We'll create a rounding adjustment to the amount of ${formattedAdjustmentAmount} in the account you have configured.`,
      condition: showRoundingAdjustment,
    },
    {
      key: PostingTypeOptions.PartiallyPayMyInvoice,
      label: 'Partially pay my invoice',
      description: `We’ll only pay ${formattedCapricornAmount} from your ${marketplaceSupplier?.TenantName} statement off the invoice in your financial system.`,
      condition: showPartiallyPayInvoice,
    },
    {
      key: PostingTypeOptions.DoNothing,
      label: 'Do nothing',
      description: `The ${transactionType} will remain in the list and you can come back to it later.`,
      condition: showDoNothing,
    },
  ];

  const handlePost = () => {
    if (selectedPostingType === PostingTypeOptions.DoNothing) {
      onClose();
      return;
    }
    if (selectedPostingType === PostingTypeOptions.PostAsARoundingAdjustment && !isCOARoundeingSelected) {
      setIsCOAWarningDialog(true);
      return;
    }
    handleDone();
  };

  return (
    <ARDialogTemplate
      dialogProps={{size: 'xs', open: isOpen, className: '!min-w-[922px]'}}
      isFullScreen
      header={<p className="font-poppins text-xl font-light text-[#333333]">How would you like us to post? </p>}
      body={
        <div className="my-12">
          <Typography
            variant="paragraph"
            className="mb-16 text-center font-light text-black-800"
            data-autoid={`lblSelectedInvoices`}
          >
            The total amount on the selected invoice doesn’t match, below are some options available.
          </Typography>
          <div className="relative flex flex-row justify-center gap-4">
            {options
              ?.filter(option => option.condition)
              .map(option => (
                <button
                  key={option.key}
                  onClick={() => handlePostingType(option.key)}
                  className={`flex h-[149px] w-[210px] cursor-pointer flex-col items-center justify-center gap-4 rounded-[6px] border px-[24px] py-[30px] hover:border-primary-header hover:bg-primary-background ${
                    selectedPostingType === option.key
                      ? 'border-primary-header bg-primary-background'
                      : 'border-[#F1F1F1] bg-white'
                  }`}
                >
                  <p className="text-center font-poppins text-sm font-medium text-black-800">{option.label}</p>
                  <p className="text-center font-poppins text-xs font-normal text-black-800">{option.description}</p>
                </button>
              ))}
          </div>
          <COAWarningDialog isOpen={isCOAWarningDialog} onClose={() => setIsCOAWarningDialog(false)} />
        </div>
      }
      footer={
        <div className="flex w-full flex-row items-center justify-between">
          <Button
            data-autoid={`btnCancel`}
            variant="outlined"
            className="bg-white"
            color="primary"
            onClick={() => onClose()}
          >
            Cancel
          </Button>
          <Button
            data-autoid={`btnPost`}
            variant="filled"
            color="primary"
            onClick={handlePost}
            disabled={!selectedPostingType}
            loading={isLoading}
          >
            {selectedPostingType === PostingTypeOptions.DoNothing ? 'Close' : 'Post'}
          </Button>
        </div>
      }
    />
  );
};
