import React, {SetStateAction} from 'react';
import {Button, Dialog, DialogBody, DialogFooter, Typography} from 'spenda-ui-react';
import {ErrorAnimation, SuccessAnimation} from '../../assets/svg/animations/LottieAnimations';
import {PriceFormat} from '../../utils/formatter';

interface ILimepayPaymentStatus {
  open: boolean;
  handleClose: React.Dispatch<SetStateAction<boolean>>;
  isSuccessful: boolean;
  amountToPay: number;
}
export const LimepayPaymentStatus: React.FC<ILimepayPaymentStatus> = ({
  handleClose,
  isSuccessful,
  open,
  amountToPay,
}) => {
  return (
    <Dialog
      open={open}
      handler={() => {}}
      className={`flex h-full !w-full !min-w-[20%] flex-col items-center justify-center sm:block sm:h-auto sm:!max-w-[329px] sm:p-0`}
    >
      <DialogBody className="flex min-h-[450px] w-full min-w-[290px] flex-col items-center justify-center gap-5">
        {isSuccessful ? <SuccessAnimation height="88px" width="88px" /> : <ErrorAnimation height="88px" width="88px" />}
        <Typography className={`mx-2 text-center ${isSuccessful ? 'text-primary' : 'text-error'}`} variant="h2">
          {isSuccessful ? 'Payment Successful' : 'Payment Failed'}
        </Typography>
        <div className="flex w-[234px] flex-col items-center justify-center gap-5 pt-2 text-center text-spenda-greytext">
          {isSuccessful ? (
            <Typography variant="small">
              Your payment of {PriceFormat(amountToPay)} has been successfully processed.
            </Typography>
          ) : (
            <Typography variant="small">Your payment of {PriceFormat(amountToPay)} has failed.</Typography>
          )}
        </div>
      </DialogBody>
      <DialogFooter className="!p-2">
        <div className="flex w-full items-center justify-center rounded-[6px] bg-[#ADADAD33] p-2">
          <Button onClick={() => handleClose(false)} variant="filled">
            Close
          </Button>
        </div>
      </DialogFooter>
    </Dialog>
  );
};
