import React, {FunctionComponent, useState} from 'react';
import {Collapse, Popover, makeStyles} from '@material-ui/core';
import {usePopupState, bindTrigger, bindPopover} from 'material-ui-popup-state/hooks';
import {Button, Checkbox, Menu, MenuHandler, MenuItem, MenuList} from 'spenda-ui-react';
import {css} from 'glamor';
import {omit} from 'lodash';

import {ICreateCustomerClassValues} from '../../model/customer/CustomerClasses';
import {ModifyDialog} from '../dialog/ModifyCustomerDialog';
import {STableToolbar} from '../tables/STableToolbar';
import {SSearchInput} from '../inputs/SSearchInput';
import {SButton} from '../buttons/Button';
import {CustomerClassesAutocomplete} from '../customer/CustomerAutocomplete';
import IconFilter from '../../assets/svg/IconFilter';
import IconSearch from '../../assets/svg/IconSearch';
import IconColumnSetting from '../../assets/svg/IconColumnSetting';
import IconKeyboardArrowDownRounded from '../../assets/svg/IconKeyboardArrowDownRounded';
import {RoundedCheckbox} from '../inputs/RoundedCheckbox';

export interface ICustomerToolbar {
  title?: string;
  loading?: boolean;
  searchFilter: any;
  columns?: any;
  selectedColumns?: any;
  setSelectedColumns?: any;
  selected?: number[];
  data?: any[];
  isCustomerClasses?: boolean;
  setSelected?: (selected: number[]) => void;
  setSearchFilter: (filter: any) => void;
  getCustomersList: () => void;
  setRefetch: any;
  refetch: any;
  isAccountCustomer?: boolean;
}

const useCustomerToolbarStyles = makeStyles(() => ({
  title: {
    fontWeight: 500,
    fontSize: '1.5em',
    color: '#4D4D4D',
  },
  tableTitle: {
    fontSize: '1.25em',
  },
  filterContainer: {
    display: 'flex',
    backgroundColor: '#FAFAFA',
    border: '1px solid #ECECEC',
    borderRadius: '8px',
    justifyContent: 'space-between',
    width: '100%',
    margin: '1rem 0',
    padding: '0.5rem 0',
  },
}));

export const OperationalCustomerToolbar: FunctionComponent<Partial<ICustomerToolbar>> = props => {
  const classes = useCustomerToolbarStyles();
  const [open, setOpen] = useState<boolean>(false);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [customerClassFilter, setCustomerClassFilter] = useState<Partial<ICreateCustomerClassValues>>();
  const [showArchive, setShowArchive] = useState<boolean>(false);
  const [searchInputText, setSearchInputText] = useState<string>('');
  const {
    title,
    loading,
    searchFilter,
    selectedColumns,
    columns,
    setSelectedColumns,
    data,
    selected,
    setSelected,
    setSearchFilter,
    isCustomerClasses,
    isAccountCustomer,
    setRefetch,
    refetch,
  } = props;

  const classPopupState = usePopupState({variant: 'popover', popupId: 'classPopupState'});
  // const columnSettingPopupState = usePopupState({variant: 'popover', popupId: 'columnSettingPopupState'});

  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  const filterItem = css({color: '#4D4D4D', whiteSpace: 'nowrap'});

  const handleSearchInput = (newValue: string) => {
    setSearchInputText(newValue);
    if (setSearchFilter && newValue.length > 2) {
      setSearchFilter({
        ...omit(searchFilter, ['LastRowNumber']),
        Search: newValue,
      });
    }
  };

  const handleSearchClear = () => {
    if (setSearchFilter) {
      setSearchFilter({
        ...omit(searchFilter, ['LastRowNumber']),
        Search: '',
      });
    }
  };

  const handleIconSearch = () => {
    if (setSearchFilter) {
      setSearchFilter({
        ...omit(searchFilter, ['LastRowNumber']),
        Search: searchInputText,
      });
    }
  };

  const handleColumnCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentSelected = [...selectedColumns];
    if (event.target.checked) {
      currentSelected.push(event.target.name);
    } else {
      const index = currentSelected.indexOf(event.target.name);
      if (index !== -1) {
        currentSelected.splice(index, 1);
      }
    }
    setSelectedColumns(currentSelected);
  };

  const handleArchiveClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (setSearchFilter) {
      setShowArchive(event.target.checked);
      if (event.target.checked) {
        setSearchFilter({
          ...omit(searchFilter, ['IsShowActiveOnly', 'LastRowNumber']),
        });
      } else {
        setSearchFilter({
          ...omit(searchFilter, ['IsShowActiveOnly', 'LastRowNumber']),
          IsShowActiveOnly: true,
        });
      }
    }
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Array.isArray(data) && Array.isArray(selected) && typeof setSelected === 'function') {
      const selectedSet = new Set(selected);

      for (let _data of data) {
        if (event.target.checked) {
          selectedSet.add(_data.ID);
        } else {
          selectedSet.delete(_data.ID);
        }
      }
      setSelected(Array.from(selectedSet));
    }
  };

  const closeDialog = () => {
    setOpen(false);
  };

  const handleCustomerClassFilter = (selected: Partial<ICreateCustomerClassValues> | null) => {
    if (setSearchFilter) {
      setSearchFilter({
        ...omit(searchFilter, ['customerClassID', 'LastRowNumber']),
        ...(selected ? {customerClassID: selected?.ID} : {}),
      });
    }
    setCustomerClassFilter(selected || undefined);
  };

  return (
    <>
      <ModifyDialog
        selectedRows={selected || []}
        open={open}
        onClose={closeDialog}
        setRefetch={setRefetch}
        refetch={refetch}
      />
      <STableToolbar>
        <div className="flex w-full justify-between pt-2">
          <p className={`${classes.title} ${classes.tableTitle}`}>{title}</p>
          <div className="flex">
            <div
              {...filterItem}
              onClick={toggleFilter}
              className="mx-4 flex cursor-pointer items-center text-xl font-medium"
            >
              <IconSearch />
              <span className="px-1 pl-2">Search</span>
            </div>
            {!isCustomerClasses ? (
              <div
                {...filterItem}
                onClick={toggleFilter}
                className="mx-4 flex cursor-pointer items-center text-xl font-medium"
              >
                <IconFilter />
                <span className="px-1 pl-2">Filters</span>
              </div>
            ) : null}
            <Menu
              placement="bottom-end"
              dismiss={{
                itemPress: false,
              }}
            >
              <MenuHandler>
                <Button
                  data-autoid="btnColumnSettings"
                  variant="text"
                  ripple={false}
                  className="h-[33px] font-serif text-[#4D4D4D] hover:text-[#4D4D4D] hover:no-underline active:bg-transparent"
                >
                  <div className="flex gap-x-2 text-xl">
                    <IconColumnSetting />
                    <span>Column Settings</span>
                  </div>
                </Button>
              </MenuHandler>
              <MenuList className="flex flex-col border border-primary p-0">
                {Object.entries(columns)?.length ? (
                  Object.entries(columns).map(([key, value]: [string, any]) => (
                    <div
                      key={`columns-${key}`}
                      data-autoid={`columnItem-${key}`}
                      className="flex items-center gap-x-2 px-2.5 py-0 outline-none first:pt-1 last:pb-1 hover:!bg-primary/10 active:!bg-primary/10"
                    >
                      <Checkbox
                        name={key}
                        ripple={false}
                        color="primary"
                        checked={selectedColumns.includes(key)}
                        onChange={handleColumnCheckbox}
                        label={value.title}
                        containerProps={{className: 'p-0'}}
                        className="border-primary bg-white before:h-5 before:w-5 checked:border-none checked:bg-primary hover:before:opacity-0"
                        labelProps={{className: 'px-3 py-2 text-[#4D4D4D] font-serif font-medium text-base'}}
                      />
                    </div>
                  ))
                ) : (
                  <MenuItem disabled>
                    <p className="font-serif">No Columns</p>
                  </MenuItem>
                )}
              </MenuList>
            </Menu>
          </div>
        </div>
        <Collapse in={showFilter} className={'w-full'}>
          <div className={classes.filterContainer}>
            <SSearchInput
              data-autoid={`searchInputCustomerClasses`}
              loading={loading}
              placeholder={isCustomerClasses ? `Search by Class Name or Description` : `Search customers`}
              handleChange={handleSearchInput}
              handleIconSearch={handleIconSearch}
              handleSearchClear={handleSearchClear}
            />
            {!isCustomerClasses ? (
              <div className="flex">
                <SButton
                  data-autoid="btnCustomerClass"
                  margin="0"
                  color="transparent"
                  textColor="greyTint"
                  width="m"
                  type="button"
                  boxShadow="none"
                  {...bindTrigger(classPopupState)}
                >
                  Customer Class <IconKeyboardArrowDownRounded />
                </SButton>
                <Popover
                  {...bindPopover(classPopupState)}
                  anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                  transformOrigin={{vertical: 'top', horizontal: 'right'}}
                >
                  <div className="flex flex-col p-4" {...css({minWidth: '275px'})}>
                    <p className="text-xs font-medium uppercase text-primary">Select Customer Class</p>
                    <CustomerClassesAutocomplete
                      styles={{marginTop: '-1.5rem'}}
                      selected={customerClassFilter ? customerClassFilter : null}
                      clearClick={() => handleCustomerClassFilter(null)}
                      onSuggestionSelected={(e, selected) => handleCustomerClassFilter(selected.suggestion)}
                    />
                  </div>
                </Popover>
              </div>
            ) : null}
          </div>
        </Collapse>
        <div className="flex w-full justify-between pt-4">
          <div className="flex items-center">
            {!isCustomerClasses ? (
              <RoundedCheckbox
                label={
                  <>
                    Select All <span data-autoid="txtCustomerCount">({Array.isArray(data) ? data.length : 0})</span>
                  </>
                }
                checked={data?.length ? data?.every(v => selected?.includes(v?.ID)) : false}
                onChange={handleSelectAll}
                id="selectAll"
                labelProps={{className: 'pr-4'}}
              />
            ) : null}

            {!isCustomerClasses ? (
              <Button
                size="sm"
                onClick={() => setOpen(true)}
                disabled={Array.isArray(selected) && selected.length ? false : true}
                data-autoid="btnModifySelected"
              >
                Modify Selected({Array.isArray(selected) ? selected.length : 0})
              </Button>
            ) : null}
          </div>

          {isCustomerClasses === true || isAccountCustomer === true ? null : (
            <div className="flex items-center">
              <Checkbox
                id="showArchive"
                checked={showArchive}
                className="border-primary checked:bg-primary hover:before:opacity-0"
                onChange={handleArchiveClick}
              />
              <label htmlFor="showArchive" className="inline cursor-pointer pr-4 text-lg font-medium">
                Show Archive Customers
              </label>
            </div>
          )}
        </div>
      </STableToolbar>
    </>
  );
};
