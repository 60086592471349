import useHttp from "../hooks/useHttp";
import { IActionRes } from "../model/ActionResults";

export const useInviteAPI = () => {

    const { POST } = useHttp();

    const saveReferral = (referrer: string): Promise<IActionRes> => {
        return POST(`/invite/referrals/${referrer}`, undefined);
    }

    return {
        saveReferral
    }

}

