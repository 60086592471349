import React from 'react';
import Dropzone, {FileRejection} from 'react-dropzone';

import {ConvoUploadSupportedFileTypes} from '../../model/constants/Constants';

import {AttachFilesIcon} from '../../assets/svg/AttachFilesIcon';
import {Box, makeStyles} from '@material-ui/core';
import {RemoveAttachment} from '../../assets/svg/RemoveAttachment';

const useChatBoxAttachmentStyles = makeStyles(() => ({
  attachmentTile: {
    height: '28px',
    borderRadius: '16px',
    backgroundColor: '#EAEFF2',
    padding: '6px 10px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: '4px',
    marginBottom: '4px',
  },
  attachmentText: {
    fontSize: '12px',
    fontWeight: 400,
    marginRight: '10px',
  },
}));

interface UploadAttachmentsProps {
  handleAcceptedFile: (acceptedFile: any) => void;
  handleRejectedFile: (rejectedFile: FileRejection[]) => void;
  operationUniqueId: string;
  isSubjectSelected: boolean;
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T = string | React.ChangeEvent<any>>(field: T): T extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  handleBlur: {
    (e: React.FocusEvent<any>): void;
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  };
}

export const UploadAttachments = (props: UploadAttachmentsProps) => {
  const {handleAcceptedFile, handleRejectedFile, handleChange, handleBlur, isSubjectSelected, operationUniqueId} =
    props;
  return (
    <Box className="flex flex-row items-center" data-autoid={`btnChooseAttachments-${operationUniqueId}`}>
      <Dropzone
        noKeyboard
        disabled={!isSubjectSelected}
        accept={ConvoUploadSupportedFileTypes}
        maxFiles={1}
        maxSize={10485760}
        multiple={false}
        onDropAccepted={acceptedFile => handleAcceptedFile(acceptedFile)}
        onDropRejected={rejectedFile => handleRejectedFile(rejectedFile)}
      >
        {({getRootProps, getInputProps}) => (
          <div {...getRootProps()}>
            <input
              data-autoid={`upload-input-${operationUniqueId}`}
              onChange={handleChange}
              onBlur={handleBlur}
              {...getInputProps()}
            />
            <div data-autoid={`upload-icon-${operationUniqueId}`}>
              <AttachFilesIcon className={`cursor-pointer ml-2`} />
            </div>
          </div>
        )}
      </Dropzone>
    </Box>
  );
};

interface PreviewAttachedFilesProps {
  operationUniqueId: string;
  iteration: number;
  attachment: File;
  handleRemoveUploadedFile: (removeIndex: number) => void;
}

export const PreviewAttachedFiles = (props: PreviewAttachedFilesProps) => {
  const {attachment, operationUniqueId, iteration, handleRemoveUploadedFile} = props;
  const classes = useChatBoxAttachmentStyles();

  return (
    <Box className={`${classes.attachmentTile} flex flex-col`}>
      <p data-autoid={`lblAttachmentName-${operationUniqueId}-${iteration}`} className={`${classes.attachmentText}`}>
        {attachment.name}
      </p>
      <Box
        data-autoid={`btnRemoveAttachment-${operationUniqueId}-${iteration}`}
        onClick={() => handleRemoveUploadedFile(iteration)}
      >
        <RemoveAttachment className="cursor-pointer" />
      </Box>
    </Box>
  );
};
