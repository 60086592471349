import { IPagedActionResults, IActionResults } from './../model/ActionResults';
import { ITenantLocation } from './../model/tenantLocation/TenantLocation';
import useHttp from '../hooks/useHttp';

const useTenantLocationAPI = () => {

    const { GET, POST, isLoading } = useHttp();

    const get = (): Promise<ITenantLocation[]> => {
        return GET("v2/tenantlocation", { IsIncludeDefaultWarehouse: true }).then((data:IPagedActionResults<ITenantLocation[]>) => data.Value);
    }

    const save = (data: ITenantLocation): Promise<IActionResults<ITenantLocation>> => {

        if (data.DefaultWarehouse) {
            data.DefaultWarehouse.Code = data.DefaultWarehouse?.Name;
        }
        return POST('v2/tenantLocation', data).then((res: IActionResults<ITenantLocation>) => res);
    }

    return { get, save, isLoading }
}

export default useTenantLocationAPI;