import {FeatureFlagKeys} from '../../hooks/useFeatureFlags';

export interface IMenuModule {
  Actions?: IActionRoutes;
  Category?: string;
  contents?: IContents[];
  DependencyModuleID?: number;
  Description?: string;
  FeatureFlags?: FeatureFlagKeys[];
  icon?: string;
  IsActive?: boolean;
  IsDisableAdaptorActionRefresh?: boolean;
  IsExperimental?: boolean | undefined;
  IsExpired?: boolean;
  IsIpadOnly?: boolean | undefined;
  IsModuleSetup?: boolean;
  IsPublished?: boolean;
  IsSystem?: boolean;
  IsTenantOwned?: boolean;
  keyDescriptions?: IkeyDesc[];
  keyPoints?: IKeyPoints[];
  Labeldescription?: string | undefined;
  LogoUrl?: string | undefined;
  MiddleDescription?: string;
  ModuleID?: number;
  Name?: string;
  onboardingButtonTitle?: string;
  OrderSequence?: number | undefined;
  Route?: string;
  Src?: string;
  Status?: string | undefined;
  SubCategory?: string | undefined;
  SubTitle?: string;
  Title?: string;
  TrialExpiryDate?: string | undefined;
  TrialPeriodDays?: number | undefined;
  TrialRemainingDays?: number | undefined;
  TrialStartDate?: string | undefined;
  Type?: string | undefined;
  // New Properties which are included in the IGetModulesRes
  InstalledFromWebsiteID?: number | null;
  IsParentModule?: boolean;
  ParentModuleID?: string | null;
  IFrameURL?: string;
}

export interface IModules {
  modules: IMenuModule[];
}

export enum ModulesStatus {
  Build,
  Live,
  Experimental,
  Trial,
  demo,
  'Coming soon',
  'App only',
}

export enum ModulesCategory {
  Buying,
  selling,
  inventory,
}

export enum ModulesSubCategory {
  Funding,
  Payment,
}

export interface IActionRoutes {
  ONBOARDING?: string;
  DEEP_LINKING?: string;
  APP_STORE?: string;
  FINDOUTMORE?: string;
}

export interface IKeyPoints {
  description?: string;
  image?: string;
}

export enum IContentType {
  Image = 'image',
  Video = 'video',
}

export interface IContents {
  type: IContentType;
  path: string;
}

export interface IkeyDesc {
  label?: string;
  keyPoints?: string[];
  plainText?: string[];
}
