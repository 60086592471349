import DateFnsUtils from '@date-io/date-fns';
import {makeStyles} from '@material-ui/core';
import {MuiPickersUtilsProvider, TimePicker} from '@material-ui/pickers';
import clsx from 'clsx';
import {Formik, FormikProps} from 'formik';
import React from 'react';
import {Button, Dialog, DialogBody, DialogHeader, Option, Radio, Select, Typography} from 'spenda-ui-react';
import {ClockIcon} from '../../../assets/svg/ClockIcon';
import {ManageSchedulerTime, ScheduledTaskRecurrenceType} from '../../../model/constants/Constants';
import {ScheduledTaskValues, validationSchema} from '../../accountsPayableOnboarding/ManageScheduler';
import LoadingIndicator from '../../ui/LoadingIndicator';

interface IManageScheduleDialogProps {
  handleClose: () => void;
  scheduledTaskToEdit: ScheduledTaskValues;
  open: boolean;
  isLoading: boolean;
  onSave: (values: ScheduledTaskValues) => Promise<void>;
}

const useManageScheduleDialogStyles = makeStyles({
  timeTextField: {
    '& .MuiFormControl-root': {
      '& .MuiOutlinedInput-root': {
        backgroundColor: 'transparent',
      },
      '& .MuiOutlinedInput-root.Mui-disabled': {
        '& .MuiInputBase-input': {
          color: '#33333350',
        },
      },
      '& .MuiFormLabel-root': {
        color: '#333',
        fontFamily: 'Poppins, sans-serif !important',
        fontWeight: 600,
        fontSize: '14px',
      },
      '& .MuiInputBase-input': {
        fontSize: '14px',
        fontWeight: 600,
        padding: '0px 8px',
        fontFamily: 'Poppins !important',
        color: '#333',
      },
      '& .MuiIconButton-root': {
        color: 'hsl(var(--primary))D',
      },
      '& .MuiInputBase-adornedEnd': {
        paddingRight: '0px',
      },
    },
  },
  pickerDialog: {
    '& .MuiTypography-root': {
      fontFamily: 'poppins',
    },
    '& .MuiPickersToolbar-toolbar': {
      backgroundColor: 'hsl(var(--primary))',
    },
    '& .MuiPickersCalendarHeader-dayLabel.MuiPickersCalendarHeader-dayLabel': {
      color: '#333',
    },
    '& .MuiPickersClockPointer-pointer': {
      color: '#333',
    },
    '& .MuiPickersClockNumber-clockNumber': {
      color: '#33333350',
    },
    '& .MuiPickersClockNumber-clockNumberSelected': {
      backgroundColor: 'hsl(var(--primary))',
      color: '#fff',
    },
  },
});

const ManageScheduleDialog: React.FC<IManageScheduleDialogProps> = (props: IManageScheduleDialogProps) => {
  // Props

  const {open, scheduledTaskToEdit, isLoading, onSave, handleClose} = props;

  const classes = useManageScheduleDialogStyles();

  const schedule = (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={scheduledTaskToEdit}
      onSubmit={onSave}
    >
      {(fProps: FormikProps<ScheduledTaskValues>) => (
        <>
          {isLoading && !fProps?.isSubmitting ? (
            <div className="relative flex h-[200px] w-full items-center justify-center">
              <LoadingIndicator isLoading={isLoading} size="md" color="hsl(var(--primary))" />
            </div>
          ) : (
            <div className="flex flex-col justify-between">
              <div className="mx-auto flex justify-around gap-10">
                <div className="flex h-[134px] w-[218px] flex-col items-start justify-start rounded-lg bg-primary/[0.2] px-4 py-2">
                  <div className="flex items-center justify-start">
                    <Radio
                      name="timeOfDay"
                      color="primary"
                      checked={fProps.values.RecurrenceType === ScheduledTaskRecurrenceType.TimeOfDay}
                      onChange={() => fProps.setFieldValue('RecurrenceType', ScheduledTaskRecurrenceType.TimeOfDay)}
                    />
                    <p className="text-base font-semibold text-primary">Time of day</p>
                  </div>
                  <div className="ml-2 mt-2 flex w-full flex-col items-start justify-start">
                    <div
                      className={`${classes.timeTextField} h-[40px] w-[164px] rounded-md border border-primary bg-white`}
                    >
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <div className="flex h-full w-full items-center justify-around p-1">
                          <TimePicker
                            autoOk
                            variant="inline"
                            id="TimeOfDay"
                            inputVariant="outlined"
                            format="HH:mm"
                            value={fProps.values.TimeOfDay}
                            onChange={date => {
                              fProps.setFieldValue('TimeOfDay', date);
                            }}
                            InputProps={{style: {fontFamily: 'Poppins'}}}
                            InputLabelProps={{shrink: Boolean(fProps.values.TimeOfDay)}}
                            PopoverProps={{className: classes.pickerDialog, style: {zIndex: 2147483647}}}
                            helperText={fProps.touched?.TimeOfDay && fProps.errors?.TimeOfDay}
                            error={fProps.touched?.TimeOfDay && Boolean(fProps.errors?.TimeOfDay)}
                            disabled={fProps.values.RecurrenceType === ScheduledTaskRecurrenceType.RunEvery}
                          />
                          <div className="mx-2 flex items-center justify-center text-center">
                            <ClockIcon width="16" height="16" fill="primary" />
                          </div>
                        </div>
                      </MuiPickersUtilsProvider>
                    </div>
                  </div>
                </div>
                <div className="flex h-[134px] w-[241px] flex-col items-start justify-start rounded-lg bg-primary/[0.2] px-4 py-2">
                  <div className="flex items-center justify-start">
                    <Radio
                      name="timedIntervals"
                      value="timedIntervals"
                      color="primary"
                      checked={fProps.values.RecurrenceType === ScheduledTaskRecurrenceType.RunEvery}
                      onChange={() => fProps.setFieldValue('RecurrenceType', ScheduledTaskRecurrenceType.RunEvery)}
                    />
                    <p className="text-base font-semibold text-primary">Timed intervals</p>
                  </div>
                  <div className="ml-2 mt-2 flex w-full flex-col items-start justify-start">
                    <div
                      className={`${classes.timeTextField} h-[40px] w-[190px] rounded-md border border-primary bg-white`}
                    >
                      <div className="flex w-full items-center justify-between">
                        <div className={`mr-1 w-[84px] font-poppins`}>
                          <Select
                            containerProps={{className: '!w-[84px] !min-w-[84px] !border-none'}}
                            className="!border-none !outline-none disabled:!bg-transparent disabled:text-[#33333350]"
                            variant="outlined"
                            value={fProps.values.Interval.toString()}
                            onChange={value => {
                              value && fProps.setFieldValue('Interval', Number(value));
                            }}
                            inputProps={{
                              name: 'selectManageSchedule',
                            }}
                            menuProps={{className: '!max-h-[200px]'}}
                            disabled={fProps.values.RecurrenceType === ScheduledTaskRecurrenceType.TimeOfDay}
                          >
                            {Array.from({length: 10}, (_, i) => i + 1).map(itm => (
                              <Option key={itm.toString()} value={itm.toString()}>
                                {itm}
                              </Option>
                            ))}
                          </Select>
                        </div>
                        {' | '}
                        <div className={`mr-1 w-[102px] font-poppins`}>
                          <Select
                            size="md"
                            variant="outlined"
                            name="IntervalType"
                            containerProps={{className: '!w-[102px] !min-w-[102px] !border-none'}}
                            className="!border-none text-center !outline-none disabled:!bg-transparent disabled:text-[#33333350]"
                            value={fProps.values.IntervalType.toString()}
                            inputProps={{
                              name: 'IntervalType',
                            }}
                            onChange={value => {
                              value && fProps.setFieldValue('IntervalType', Number(value));
                            }}
                            disabled={fProps.values.RecurrenceType === ScheduledTaskRecurrenceType.TimeOfDay}
                          >
                            <Option
                              key={ManageSchedulerTime.Minutes.toString()}
                              value={ManageSchedulerTime.Minutes.toString()}
                            >
                              Minutes
                            </Option>
                            <Option
                              key={ManageSchedulerTime.Hours.toString()}
                              value={ManageSchedulerTime.Hours.toString()}
                            >
                              Hours
                            </Option>
                            <Option
                              key={ManageSchedulerTime.Days.toString()}
                              value={ManageSchedulerTime.Days.toString()}
                            >
                              Days
                            </Option>
                          </Select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="absolute bottom-2.5 flex h-[55.19px] w-[640px] flex-row items-center justify-between rounded-[6px] bg-[#ececec] px-2.5">
                <Button
                  type="button"
                  data-autoid={`btnCancel`}
                  variant="outlined"
                  className="!bg-white"
                  color="primary"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  type="button"
                  data-autoid={`btnSave`}
                  variant="filled"
                  color="primary"
                  loading={fProps.isSubmitting}
                  disabled={fProps.isSubmitting || (!fProps.dirty && fProps.values.IsEnabled)}
                  onClick={() => {
                    fProps.handleSubmit();
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          )}
        </>
      )}
    </Formik>
  );

  return (
    <Dialog
      size="xxl"
      open={open}
      handler={() => handleClose()}
      className="flex flex-col items-center justify-center bg-[#ADADAD33] backdrop-blur-[13px]"
    >
      <div className="flex h-full max-h-[396.44px] w-[660px] max-w-[660px] flex-col rounded-[10px] bg-white shadow-md">
        <DialogHeader className={clsx(`border-b border-[#D8D8D8] text-spenda-primarytext`)}>
          <Typography className="w-full self-center text-center text-2xl font-light">Manage Schedule</Typography>{' '}
        </DialogHeader>
        <DialogBody className="mt-6 h-full px-2.5 ">{schedule}</DialogBody>
      </div>
    </Dialog>
  );
};

export default ManageScheduleDialog;
