import React from 'react';
import {Layout} from '../../components/layout/Layout';
import QuoteContextProvider from '../../context/quote-context/QuoteContextProvider';
import QuotePreviewTabs from '../../components/quote-management/QuotePreviewTabs';
import {useLocation} from 'react-router-dom';

export const QuotesPreview = () => {
  const {state} = useLocation<{isResend: boolean}>();
  const leftPanel = (
    <div className="shadow-[0px_0px_6px_0px_#D3E5EF'] relative flex h-[calc(100vh_-_108px)] w-full flex-col rounded bg-white p-[10px] pb-10 font-poppins">
      <div className="flex w-full justify-center overflow-y-auto pb-7">
        <QuotePreviewTabs isResendingQuote={state?.isResend} />
      </div>
    </div>
  );
  return (
    <div>
      <QuoteContextProvider>
        <Layout leftPanel={leftPanel} splitWidthType={4} />
      </QuoteContextProvider>
    </div>
  );
};
