import React from 'react';
import {Box} from '@material-ui/core';
import {SButton} from '../buttons/Button';
import NextArrowIcon from '../../assets/svg/next-arrow.svg';
import LoadingIndicator from '../ui/LoadingIndicator';
import {SModal} from '../modals/modalSpendaMeterialUI';
import {ChatWidget} from './ChatWidget';
import CloseIcon from '@material-ui/icons/Close';
import {GetBackgroundCSS} from './Onboarding';
interface SetupCompleteProps {
  onBackPress: () => void;
  onSetupComplete: () => void;
  isSetupCompleteLoading?: boolean;
  handleClose: () => void;
  isBuyer: boolean;
}

export const SetupComplete: React.FunctionComponent<SetupCompleteProps> = ({
  onSetupComplete,
  isSetupCompleteLoading: isLoading,
  handleClose,
  isBuyer,
}: SetupCompleteProps) => {
  return (
    <div>
      <SModal open>
        <div
          className={`spenda-color flex h-full max-w-full items-center justify-center text-xl ${GetBackgroundCSS()}`}
        >
          <div className="modal-box max-w-3xl px-5 py-5">
            {isBuyer && (
              <span className="closeIcon onboarding-close-icon" onClick={handleClose} data-autoid="btnClose">
                <CloseIcon />
              </span>
            )}
            {/* <span
                className="cursor-pointer"
                onClick={onBackPress}
                style={{ position: "relative" }}
              >
                <img
                  src={BackIcon}
                  alt="Next Arrow Icon"
                  style={{ display: "block" }}
                />
                <ChevronLeftIcon className="back-btn-icon" />
              </span> */}
            <div className="row my-24 flex justify-center" style={{position: 'relative', top: 0, left: 0}}>
              <Box
                className={`mx-8 mt-5`}
                display="column"
                justifyContent="center"
                alignContent="center"
                alignItems="center"
                alignSelf="center"
              >
                <h2
                  id="simple-modal-title"
                  className="simple-modal-title pb-8 text-center text-3xl"
                  style={{fontWeight: 500}}
                >
                  “Great! You're all set.”
                </h2>
                <p id="simple-modal-description" className="customer-description-para color-gray mb-8 text-center">
                  Your account is now ready. Tap <span className="font-semibold"> 'Done' </span> to start using using
                  Spenda. If you need more help along the way, the <span className="font-semibold"> ‘Live Chat’ </span>{' '}
                  can be accessed from your MySpenda menu at any time.
                </p>
                <Box display="flex" justifyContent="center">
                  <SButton
                    type="submit"
                    className="common-next-btn done-btn"
                    width="m"
                    lineHeight="m"
                    fontSize="m"
                    onClick={() => {
                      onSetupComplete();
                    }}
                    disabled={isLoading}
                    data-autoid="btnNext"
                  >
                    Done
                    {isLoading ? (
                      <LoadingIndicator isLoading={isLoading} size="sm" color="#FFF" position={{right: '5px'}} />
                    ) : (
                      <img src={NextArrowIcon} alt="Next Arrow Icon" className="" />
                    )}
                  </SButton>
                </Box>
              </Box>
            </div>
            <div className="live-chat-section">
              <ChatWidget />
            </div>
          </div>
        </div>
      </SModal>
    </div>
  );
};
