import React, {useState} from 'react';

import {SModal} from '../modals/modalSpendaMeterialUI';
import {STextField} from '../inputs/STextField';
import {SButton} from '../buttons/Button';
import {Box, makeStyles} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useDiscardBatchModalStyles = makeStyles({
  apOnboardingModal: {
    maxWidth: '726px',
    height: '384px',
  },

  apBusinessHeader: {
    borderBottom: '1px solid #CCCCCC',
    '& h2': {
      fontFamily: 'Poppins, sans-serif !important',
      fontWeight: 300,
      fontSize: '28px',
      color: '#333333',
    },
  },

  boxHeight: {
    maxWidth: '385px',
    '& p': {
      fontFamily: 'Poppins, sans-serif !important',
      color: '#333',
    },
  },
  discardDescription: {
    paddingBottom: '0px!important',
    '& .MuiOutlinedInput-multiline': {
      padding: '12px 10px',
      backgroundColor: '#ffffff!important',
    },
    '& .MuiOutlinedInput-input': {
      height: 'auto',
    },
    '& .MuiInputLabel-formControl': {
      top: '-8px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#333333!important',
    },
    '& .MuiFormLabel-root': {
      color: ' #333!important',
      fontSize: '14px',
      fontFamily: 'Poppins, sans-serif !important',
    },
    '& textarea': {
      color: ' #333!important',
      fontSize: '14px',
      fontFamily: 'Poppins, sans-serif !important',
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #333',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      fontSize: '13px',
      transform: 'translate(11px, -4px) scale(0.75)',
      top: '0px',
    },
    '& legend': {
      width: '82px',
    },
    '& .MuiFormHelperText-root': {
      bottom: '0px!important',
      top: '100%!important',
      marginLeft: '10px',
    },
  },
  discardFooter: {
    maxWidth: '660px',
    background: 'rgb(173 173 173 / 20%)',
    borderRadius: '8px',
  },
});
export interface IDiscardBatchModalProps {
  isLoading: boolean;
  handleClose: () => void;
  handleDiscardClick: (description: string) => void;
}

export const DiscardBatchModal = (props: IDiscardBatchModalProps) => {
  const classes = useDiscardBatchModalStyles();

  const [description, setDescription] = useState<string>('');

  const {handleDiscardClick} = props;

  return (
    <SModal open>
      <div className={`text-xl spenda-color flex justify-center items-center h-full max-w-full`}>
        <div className={`modal-box ${classes.apOnboardingModal} px-5 pt-3 pb-5`}>
          <Box className={`${classes.apBusinessHeader} flex justify-between -mx-5 px-3 pb-3`}>
            <h2 className={`text-center w-full`}>Discard Batch</h2>
            <Box className="text-right flex justify-end">
              <span className="ap-onboarding-close-icon">
                <CloseIcon onClick={props.handleClose} data-autoid="btnClose" />
              </span>
            </Box>
          </Box>
          <div className={`${classes.boxHeight} flex flex-col relative py-8 w-full mx-auto`}>
            <Box>
              <p className="text-xs pb-8">Please add a description of why you want to discard this batch?</p>
              <STextField
                fullWidth
                id="discardDiscription"
                name="discardDiscription"
                variant="outlined"
                label="Add Description"
                placeholder="Add your description here…"
                rows={4}
                multiline
                className={`${classes.discardDescription}`}
                value={description}
                onChange={e => setDescription(e.target.value)}
                error={!description}
                inputProps={{maxLength: 500}}
                helperText={!description && 'Discard Description is Required (500 characters max)'}
                data-autoid="txtDiscardDiscription"
              />
            </Box>
          </div>
          <Box
            className={`${classes.discardFooter} w-full flex justify-between items-center absolute bottom-5 left-0 p-2 mx-8 `}
          >
            <SButton
              textColor={'blueShade'}
              width="72px"
              height="40px"
              lineHeight="l"
              fontSize="xs"
              backgroundColor={'#fff'}
              margin="0px "
              variant={'outlined'}
              border={'1px solid #1C78AD'}
              borderRadius="6px"
              data-autoid="btnCancel"
              fontFamily="poppins"
              onClick={props.handleClose}
            >
              Cancel
            </SButton>
            <SButton
              textColor={'white'}
              width="130px"
              height="40px"
              lineHeight="l"
              fontSize="xs"
              backgroundColor={'#c65d42'}
              margin="0px"
              variant={'outlined'}
              border={'1px solid #c65d42'}
              borderRadius="6px"
              data-autoid="btnDiscardBatchPopup"
              fontFamily="poppins"
              disabled={!description}
              isSubmitting={props.isLoading}
              onClick={() => handleDiscardClick(description)}
            >
              Discard Batch
            </SButton>
          </Box>
        </div>
      </div>
    </SModal>
  );
};
