import React from 'react';
import {Box} from '@material-ui/core';
import {ARAgeReceivableDateRange} from '../../../model/constants/Constants';
import {Select, Option, Typography} from 'spenda-ui-react';

interface ICustomerGroupTableHeader {
  selectedOption: ArCustomerGroupViewTypes;
  onChange: (selectedOption: ArCustomerGroupViewTypes) => void;
  selectedAgeDateRange: ARAgeReceivableDateRange;
  onChangeAgeDateRange: (selectedAgeDateRange: ARAgeReceivableDateRange) => void;
}

/** Enums*/
export enum ArCustomerGroupViewTypes {
  STANDARD_VIEW = 'Standard view',
  AGED_RECEIVABLES = 'Aged receivables',
}

export const CustomerGroupTableHeader = (props: ICustomerGroupTableHeader) => {
  // Props
  const {onChange, onChangeAgeDateRange, selectedOption, selectedAgeDateRange} = props;

  return (
    <div>
      <div className={`mx-2.5 mb-[24px] flex h-[60px] items-center justify-between border-b border-[#D8D8D8] pb-2.5`}>
        <Typography variant="h2" className="items-center text-base font-medium">
          All Customers
        </Typography>
        <div className="flex flex-row items-center">
          {selectedOption === ArCustomerGroupViewTypes.AGED_RECEIVABLES && (
            <Box>
              <ARAgeReceivableDateRangeDropDown
                selectedAgeDateRange={selectedAgeDateRange}
                onChangeAgeDateRange={onChangeAgeDateRange}
              />
            </Box>
          )}
          <div className="ml-2.5 flex !min-w-[185px] flex-col py-1">
            <Select
              size="lg"
              variant="outlined"
              label="View"
              data-autoid={`ddlGroupView`}
              value={selectedOption}
              onChange={value => value && onChange(value as ArCustomerGroupViewTypes)}
              inputProps={{className: '!min-w-[185px]'}}
            >
              {(Object.keys(ArCustomerGroupViewTypes) as Array<keyof typeof ArCustomerGroupViewTypes>).map(
                (key, index) => {
                  return (
                    <Option
                      key={index}
                      data-autoid={`lblGroupViewOption${ArCustomerGroupViewTypes[key]}`}
                      value={ArCustomerGroupViewTypes[key]}
                    >
                      {ArCustomerGroupViewTypes[key]}
                    </Option>
                  );
                },
              )}
            </Select>
          </div>
        </div>
      </div>
    </div>
  );
};

interface ICustomerAgeReceivableDateRangeProps {
  selectedAgeDateRange: ARAgeReceivableDateRange;
  onChangeAgeDateRange: (selectedAgeDateRange: ARAgeReceivableDateRange) => void;
}

const OptionName: {[key in ARAgeReceivableDateRange]: string} = {
  [ARAgeReceivableDateRange.DAYS_30_60_90]: '30, 60, 90 Days',
  [ARAgeReceivableDateRange.DAYS_7_14_28]: '7, 14, 28 Days ',
  [ARAgeReceivableDateRange.MONTHS_1_2_3]: '1, 2, 3 Months',
  [ARAgeReceivableDateRange.MONTHS_2_4_6]: '2, 4, 6 Months',
  [ARAgeReceivableDateRange.MONTHS_3_6_9]: '3, 6, 9 Months',
  [ARAgeReceivableDateRange.WEEKS_1_2_4]: '1, 2, 4 Weeks',
  [ARAgeReceivableDateRange.BY_MONTH]: 'By Month',
};

export const ARAgeReceivableDateRangeDropDown = (props: ICustomerAgeReceivableDateRangeProps) => {
  // props
  const {selectedAgeDateRange, onChangeAgeDateRange} = props;

  return (
    <div className="ml-2.5 flex !min-w-[185px] flex-col py-1">
      <Select
        size="md"
        variant="outlined"
        label="Filter"
        data-autoid={`ddlFilterOptions`}
        value={String(selectedAgeDateRange)}
        onChange={value => value && onChangeAgeDateRange(Number(value))}
        inputProps={{className: '!min-w-[185px]'}}
      >
        {(Object.keys(ARAgeReceivableDateRange) as Array<keyof typeof ARAgeReceivableDateRange>)
          ?.filter(k => isNaN(Number(k)))
          .map(k => {
            return (
              <Option key={k} data-autoid={`lblFilterOptions${k}`} value={String(ARAgeReceivableDateRange[k])}>
                {OptionName?.[ARAgeReceivableDateRange[k]]}
              </Option>
            );
          })}
      </Select>
    </div>
  );
};
