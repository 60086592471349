import React, {FunctionComponent, useState, useEffect, useContext} from 'react';
import {RouteComponentProps, useParams, useHistory, useLocation, matchPath} from 'react-router-dom';
import {css} from 'glamor';
import moment from 'moment';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {pick} from 'lodash';

import {
  AUTH_BUYING_AP_BATCH,
  AUTH_BUYING_AP_APPROVE_REQUESTS,
  AUTH_BUYING_AP_APPROVE_REQUESTS_ABA_BATCH_VIEW,
  AUTH_BUYING_AP_AUTHORISE_PAYMENT_ABA_BATCH_VIEW,
  AUTH_BUYING_AP_AUTHORISE_PAYMENT,
  AUTH_BUYING_AP_REPORTS,
} from '../../routes/AccountsPayableRoutes';
import {
  IAccountPayeesDetails,
  IPaymentBatch,
  IPaymentBatchCreditLine,
  IPaymentBatchDebitLine,
} from '../../model/payment-batch/PaymentBatch';
import {
  PaymentBatchPermissionType,
  PaymentBatchStatusTypes,
  PaymentProviderName,
  AccountType,
  VerificationStatus,
  DatTypes,
  PaymentWidgetScope,
  APOnboardingSteps,
} from '../../model/constants/Constants';
import {Layout} from '../../components/layout/Layout';
import {STextField} from '../../components/inputs/STextField';
import {SButton} from '../../components/buttons/Button';
import {BatchSplitButton, BatchSplitButtonType} from '../../components/purchasing/BatchSplitButton';
import {SearchInput} from '../../components/form/InvoiceSearchForm';
import {BatchStatus} from '../../components/purchasing/BatchStatus';
import {DiscardBatchModal} from '../../components/accountsPayableOnboarding/DiscardBatchModal';
import {usePaymentBatchAPI} from '../../services/usePaymentBatchAPI';
import useSpendaPaymentServicesAPI, {IAccountResponse} from '../../services/useSpendaPaymentServicesAPI';
import {PriceFormat} from '../../utils/formatter';
import {Toast} from '../../utils/Toast';
import SelectAPInvoice from '../../assets/svg/accounts-payable/select-ap-invoice.svg';
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import {
  makeStyles,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Popper,
  Grow,
  ClickAwayListener,
  MenuItem,
  Select,
  FormControl,
  FormHelperText,
  InputLabel,
  Grid,
  IconButtonProps,
  InputAdornment,
} from '@material-ui/core';
import {usePopupState, bindToggle, bindPopper} from 'material-ui-popup-state/hooks';
import {PayBatch} from './PayBatch';
import LoadingIndicator from '../../components/ui/LoadingOverlay';
import pinIcon from '../../assets/svg/accounts-payable/pin.svg';
import greyPin from '../../assets/svg/accounts-payable/grey-pin.svg';
import unPin from '../../assets/svg/accounts-payable/unpin.svg';
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';
import ArrowDropUpOutlinedIcon from '@material-ui/icons/ArrowDropUpOutlined';
import searchIcon from '../../assets/svg/accounts-payable/ap-search-icon.svg';
import batchEditIcon from '../../assets/svg/accounts-payable/batch-edit-icon.svg';
import AppContext from '../../context/app/appContext';
import backButton from '../../assets/png/back-button.png';
import expandIcon from '../../assets/svg/accounts-payable/report-expand-icon.svg';
import collapseIcon from '../../assets/svg/accounts-payable/report-collapsible-icon.svg';
import {AMLKYCModal} from '../../components/accountsPayableOnboarding/AmlKycModal';
import {ApplicationInfo} from '../../model/constants/ApplicationInfo';
import {useFeatureFlags} from '../../hooks/useFeatureFlags';
import usePaymentServicesAPI from '../../services/usePaymentServicesAPI';
import {PaymentWidgetModal} from '../pay-by-link/payment-widget/PaymentWidget';
import {usePaymentMethods} from '../pay-by-link/payment-widget/usePaymentMethods';
import APContext from '../../context/ap-context/APContext';
import {OnboardingStepsRemainingModal} from '../../components/accountsPayableOnboarding/OnboardingStepsRemainingModal';
import {IOnboardingV1Step, RemainingStepInfoModal} from '../../model/OnboardingV1Step';
import {useOnboarding} from '../../services/useOnboarding';
import {ITenantUserDetails} from '../../model/Tenant';

const validationSchema = Yup.object({
  name: Yup.string().required('Batch Name is required.').max(50, 'Batch Name max 50 char.'),
  dueDate: Yup.string().required('Payment Date is required.'),
  credits: Yup.array().of(
    Yup.object().shape({
      description: Yup.string().required(),
    }),
  ),
  debits: Yup.array()
    .of(
      Yup.object().shape({
        paymentAccountGUID: Yup.string().required(),
      }),
    )
    .min(1)
    .required(),
});

const selectAPInvoiceCard = css({
  backgroundColor: '#eff6f9',
  borderRadius: '10px',
  '& p': {
    fontSize: '22px',
    color: '#333333',
  },
});

const panelRightBody = css({
  borderRadius: '6px',
});

const listContainer = css({
  boxShadow: '0px 0px 6px 0px #D3E5EF',
});

const useBatchViewStyles = makeStyles({
  batchName: {
    '& legend': {
      width: '66px',
    },
  },
  paymentDate: {
    '& legend': {
      width: '76px',
    },
  },
  batchSelect: {
    maxWidth: '265px',
    width: '100%',
    height: '40px',
    background: '#fff',
    paddingBottom: '0px',
    margin: '0em 0.5em',
    '& .MuiOutlinedInput-root': {
      height: '100%',
      backgroundColor: 'transparent',
      borderRadius: '6px',
    },
    '& .MuiFormLabel-root': {
      color: '#333',
      fontFamily: 'Poppins, sans-serif !important',
      width: 'auto',
      fontWeight: 500,
      fontSize: '13px',
      top: '-7px',
      left: '-5px',
    },
    '& .MuiInputLabel-shrink': {
      top: '0px',
      left: 0,
      paddingRight: '2px',
      marginLeft: '-4px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#333',
      '& legend': {
        height: '5px',
      },
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#333!important',
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: '#333',
    },
    '& .MuiSelect-select': {
      color: '#333333',
      fontSize: '14px',
      fontWeight: '600',
      fontFamily: 'poppins',
      paddingLeft: '10px',
      height: '40px',
      display: 'flex',
      alignItems: 'center',
      '& p': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    '& svg': {
      color: '#1c78ad',
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
      {
        border: '1px solid #333',
      },
    '& legend': {
      width: '89px',
    },
  },

  pin: {
    width: '18px!important',
    cursor: 'pointer',
  },
  greyPin: {
    width: '13.85px!important',
    cursor: 'pointer',
  },
  unpin: {
    width: '12px!important',
    cursor: 'pointer',
  },
  payInvoiceText: {
    width: '180px',
    height: '40px',
    '& .MuiFormControl-root': {
      background: '#fff',
      paddingBottom: '0px',
      height: '100%',
      '& .MuiInputAdornment-root': {
        display: 'flex',
        paddingRight: '0px',
      },
      '& .MuiOutlinedInput-root': {
        height: '100%',
        backgroundColor: 'transparent',
        '&.Mui-focused': {
          '& .MuiInputAdornment-root': {
            display: 'flex',
            paddingRight: '10px',
          },
        },
      },
      '& .MuiFormLabel-root': {
        color: '#333',
        fontFamily: 'Poppins, sans-serif !important',
        width: 'auto',
        fontWeight: 500,
        fontSize: '13px',
        top: '-7px',
        left: '-5px',
      },
      '& .MuiInputLabel-shrink': {
        top: '0px',
        left: 0,
        paddingRight: '2px',
        marginLeft: '-4px',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#333',
        '& legend': {
          height: '5px',
        },
      },
      '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#333!important',
        borderWidth: '2px!important',
      },
      '& .MuiFormLabel-root.Mui-focused': {
        color: '#333',
      },
      '& .MuiInputBase-input': {
        fontSize: '14px',
        fontWeight: 600,
        padding: '0px 8px',
        fontFamily: 'Poppins !important',
        color: '#333',
      },
      '& .MuiIconButton-root': {
        color: '#1C78AD',
      },
      '& .MuiInputBase-adornedEnd': {
        paddingRight: '4px',
        '& img': {
          width: '13px!important',
        },
      },
      '& .MuiInputBase-adornedStart': {
        paddingRight: '0px',
        paddingLeft: '7px',
      },
    },
    '&.editableInvoiceText': {
      '& .MuiOutlinedInput-root': {
        border: '1px solid transparent !important',
        '&.Mui-focused': {
          borderRadius: '0px',
          border: '1px solid rgb(30 121 173) !important',
        },
      },
    },
  },
  fieldsDisable: {
    '& .Mui-disabled.MuiFormLabel-filled': {
      color: '#707070',
      position: 'relative',
      marginLeft: '8px',
      transform: 'translate(0px) scale(.75)',
      lineHeight: '18px',
      top: '3px',
    },
    '& .MuiOutlinedInput-input.Mui-disabled': {
      fontSize: '14px',
      fontWeight: 500,
      '-webkit-text-fill-color': '#333',
      height: '20px',
      display: 'flex',
      alignItems: 'center',
    },
    '& .Mui-disabled, .Mui-disabled:hover': {
      '& fieldset': {
        border: '0px!important',
      },
    },
    '& .MuiInputAdornment-root': {
      display: 'none!important',
    },
    '& .MuiInputLabel-shrink': {
      position: 'relative',
      marginLeft: '11px',
      transform: 'translate(0px) scale(.75)',
      lineHeight: '18px',
      top: '3px',
    },
    '& .bankMethod': {
      overflow: 'visible!important',
    },
  },
  toPayText: {
    width: '125px',
  },
  pickerDialog: {
    '& .MuiPickersToolbar-toolbar': {
      backgroundColor: '#1C78AD',
    },
    '& .MuiPickersCalendarHeader-dayLabel.MuiPickersCalendarHeader-dayLabel': {
      color: '#333',
    },
    '& .MuiPickersCalendar-week': {
      '& .MuiPickersDay-day': {
        color: '#333',
      },
      '& .MuiPickersDay-dayDisabled': {
        color: '#33333350',
      },
      '& .MuiPickersDay-daySelected': {
        backgroundColor: '#1C78AD',
        color: '#fff',
      },
    },
  },
  paymentDesc: {
    width: '100%',
    height: '34px',
    padding: '7px',
    '& .MuiSelect-outlined.MuiSelect-outlined': {
      color: '#333',
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontWeight: 600,
    },
    '& .MuiSvgIcon-root': {
      color: '#1c78ad',
    },
    '& .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline':
      {
        borderColor: '#333',
        borderWidth: '1px',
      },
  },
  descSelect: {
    position: 'absolute',
    right: '-0px',
    top: '42px',
    background: '#fff',
    width: '100%',
    zIndex: 999,
    border: '1px solid #1C78AD',
    borderRadius: '6px',
    padding: '6px 0px',
    '& li': {
      color: '#333',
      fontSize: '14px',
      fontWeight: 600,
      fontFamily: 'poppins',
      textTransform: 'capitalize',
      justifyContent: 'flex-start!important',
      padding: '10px',
      '&:hover': {
        background: '#D3E5EF80',
      },
      '&.Mui-selected': {
        background: '#D3E5EF50',
      },
    },
  },
  desc: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  descSpan: {
    width: '90%',
  },
  footer: {
    width: '100%',
    background: 'rgb(173 173 173 / 20%)',
    padding: '10px',
    borderRadius: '6px',
  },
  customBtns: {
    maxWidth: '40px',
    width: '100%',
    height: '40px',
    borderRadius: '6px',
    border: '1px solid #1C78AD',
    marginRight: '10px',
  },
  searchForm: {
    marginRight: '10px',
  },
});

type DatePickerKeyboardProps = IconButtonProps & {
  [key: string]: string | undefined;
};
interface ISelectedDescription {
  id: string;
  isSelected: boolean;
}
export const APBatchView: FunctionComponent<RouteComponentProps> = () => {
  const classes = useBatchViewStyles();
  const history = useHistory();
  const {search} = useLocation();
  const {batchID} = useParams<{batchID: string}>();
  const query = new URLSearchParams(search);
  const {tenant, user, tenantInfo, setTenantInfo} = useContext(AppContext);
  const {userRoleBatchPermissions} = useContext(APContext);
  const [batchToEdit, setBatchToEdit] = useState<IPaymentBatch>();
  const [paymentAccounts, setPaymentAccounts] = useState<IAccountResponse[]>();
  const [paymentAccountSelected, setPaymentAccountSelected] = useState<IPaymentBatchDebitLine>();
  const [batchEditable, setBatchEditable] = useState<boolean>(true);
  const [discardHandle, setDiscardHandle] = useState<boolean>(false);
  const [openKYCDialog, setOpenKYCDialog] = useState<boolean>(false);
  const [searchString, setSearchString] = useState<string>('');
  const [payMode, setPayMode] = useState<boolean>(false);
  const [selectedDescription, setSelectedDescription] = useState<ISelectedDescription>({
    id: `credits[0].description`,
    isSelected: false,
  });
  const [creditDescriptions, setCreditDescriptions] = useState<IAccountPayeesDetails[]>([]);
  const [batchCredits, setBatchCredits] = useState<IPaymentBatchCreditLine[]>([]);
  const searchInputPopup = usePopupState({variant: 'popover', popupId: 'searchInputPopup'});
  const [collapseView, setCollapseView] = useState<boolean>(false);
  const [authorisationId, setAuthorisationId] = useState<number | undefined>();
  const [isAuthorisationRulesSet, setIsAuthorisationRulesSet] = useState<boolean>(false);
  const [isCurrentUserAnAuthoriser, setIsCurrentUserAnAuthoriser] = useState<boolean>(false);
  const [remainingOnboardingStepsInfo, setRemainingOnboardingStepsInfo] = useState<RemainingStepInfoModal>();

  const {
    getPaymentBatch,
    updatePaymentBatch,
    sendPaymentBatchForApproval,
    approvePaymentBatchForPayment,
    cancelPaymentBatch,
    isLoading: batchLoader,
    saveAccountsPayablePayees,
  } = usePaymentBatchAPI();
  const {
    getPaymentMethodAuthorisationDetails,
    approvePaymentAuthorisation,
    rejectPaymentAuthorisation,
    resetAuthenticationCode,
    getPaymentMethodAuthorisationRules,
  } = usePaymentMethods();
  const {getAccounts, isLoading: paymentLoader} = useSpendaPaymentServicesAPI();
  const [descAnchorEl, setDescAnchorEl] = useState<null | SVGElement>(null);
  const {sendAMLKYCEmail} = usePaymentServicesAPI();
  const {getOnboardingStep} = useOnboarding();
  const {onboardingUpgradeV1} = useFeatureFlags().tenantOwned();

  const isLoading = paymentLoader || batchLoader;

  useEffect(() => {
    // get batch by ID
    if (batchID) {
      fetchBatchDetails().then(res => handleActions(res));
      getPaymentAccounts();
    }
  }, [batchID]);

  useEffect(() => {
    if (
      typeof paymentAccounts !== 'undefined' &&
      onboardingUpgradeV1 &&
      (!tenantInfo?.TenantUserDetails.AreAllAPOnboardingStepsCompletedForTenant || !tenant?.GoodToTrade)
    ) {
      const getStep = async () => {
        const steps = await getOnboardingStep();
        const isSkippedAdaptor = steps[0].completedDateTime_utc ? false : true;
        let result = {
          remainingSteps: 0,
          steps: steps,
        };

        const countOfRemainingSteps = (steps: IOnboardingV1Step[]) => {
          let count = 0;
          for (let step of steps) {
            if (step.stepID === APOnboardingSteps.SetupPaymentMethods && !paymentAccounts?.length) {
              count++;
            } else if (step.stepID === APOnboardingSteps.AMLKYC && !tenant?.GoodToTrade) {
              count++;
            } else if (!step.completedDateTime_utc) {
              count++;
            }
          }
          return count;
        };
        if (isSkippedAdaptor) {
          const filteredSteps = steps.filter(step => {
            if (
              step.stepID === APOnboardingSteps.ConfigureFinancialAdaptor ||
              step.stepID === APOnboardingSteps.ClearingAccount
            ) {
              return false;
            } else {
              return true;
            }
          });
          result.remainingSteps = countOfRemainingSteps(filteredSteps);
          result.steps = filteredSteps;
        } else {
          result.remainingSteps = countOfRemainingSteps(steps);
        }
        return result;
      };

      const updateState = async () => {
        const remainingSteps = await getStep();
        if (remainingSteps.remainingSteps === 0) {
          if (typeof tenantInfo != 'undefined' && typeof tenantInfo?.TenantUserDetails != 'undefined') {
            const newObject: ITenantUserDetails = {
              ...tenantInfo.TenantUserDetails,
              AreAllAPOnboardingStepsCompletedForTenant: true,
            };
            setTenantInfo({...tenantInfo, TenantUserDetails: newObject});
          }
        } else {
          setRemainingOnboardingStepsInfo({
            remainingSteps: remainingSteps.remainingSteps,
            steps: remainingSteps.steps,
            showDialog: true,
            showAMLKYCPending: !tenant?.GoodToTrade,
          });
        }
      };
      updateState();
    }
  }, [paymentAccounts]);

  const saveDefaultDescription = async (payeeId: number, description: string) => {
    const payload: IAccountPayeesDetails[] = creditDescriptions.filter(cd => cd.payeeID !== payeeId);
    const response = await saveAccountsPayablePayees([
      {
        payeeID: payeeId,
        defaultDescription: description,
      },
    ]);
    setCreditDescriptions([...payload, ...(response?.value as IAccountPayeesDetails[])]);
  };

  const fetchBatchDetails = async (): Promise<IPaymentBatch> => {
    const batchDetails = await getPaymentBatch(batchID);
    batchDetails.dueDate = moment(batchDetails.dueDate).toDate();
    if (batchDetails.status !== PaymentBatchStatusTypes.Open) {
      setBatchEditable(false);
    }
    const batchCredits = JSON.parse(JSON.stringify(batchDetails?.credits));
    const creditDescriptions = batchDetails?.credits?.map(credit => {
      if (credit.defaultDescription && credit.defaultDescription !== '') {
        credit.description = credit.defaultDescription;
      }
      return {payeeID: credit.payeeID, defaultDescription: credit.defaultDescription};
    });
    if (batchDetails?.debits?.[0]?.paymentAccountGUID) {
      const isAuthorisationRulesSet =
        (batchDetails?.debits && batchDetails?.debits?.length && batchDetails?.debits?.[0]?.isAuthorisationRequired) ||
        false;
      // check if user exist in authoriser list
      const isCurrentUserAnAuthoriser =
        (batchDetails?.debits &&
          batchDetails?.debits?.length &&
          batchDetails?.debits?.[0]?.isCurrentUserAnAuthoriser) ||
        false;
      setIsAuthorisationRulesSet(isAuthorisationRulesSet);
      setIsCurrentUserAnAuthoriser(isCurrentUserAnAuthoriser);
    }
    setPaymentAccountSelected(batchDetails.debits[0]);
    setBatchCredits(batchCredits);
    setBatchToEdit(batchDetails);
    setCreditDescriptions(creditDescriptions);
    return batchDetails;
  };

  const getPaymentAccounts = async () => {
    const accounts = await getAccounts(PaymentProviderName.Spenda_Payment_Services);
    const _filteredAccount = accounts.filter(
      (account: IAccountResponse) =>
        [AccountType.CREDIT_CARD, AccountType.BANK_TRANSFER, AccountType.VIRTUAL_CARD].includes(account.accountType) &&
        account.verificationStatus === VerificationStatus.VERIFIED,
    );
    setPaymentAccounts(_filteredAccount);
  };

  const handleActions = (values: IPaymentBatch) => {
    if (query.get('action') === BatchSplitButtonType.Pay && values.status === PaymentBatchStatusTypes.PaymentApproved) {
      payBatch(values);
    } else if (
      query.get('action') === BatchSplitButtonType.ApprovePay &&
      values.status === PaymentBatchStatusTypes.ReadyForApproval
    ) {
      approvePayBatch(values);
    } else if (
      query.get('action') === BatchSplitButtonType.Approve &&
      values.status === PaymentBatchStatusTypes.ReadyForApproval
    ) {
      approveBatch(values, false);
    }
  };

  const formatAccountDetails = (accountGUID: string): IPaymentBatchDebitLine[] => {
    const account = paymentAccounts?.find(account => account.accountGUID === accountGUID);
    if (account) {
      const acc = {
        paymentAccountGUID: accountGUID,
      } as IPaymentBatchDebitLine;
      setPaymentAccountSelected(acc);
      return [acc];
    }
    return [];
  };

  const handleClickAway = () => {
    searchInputPopup.close();
  };

  const handleEdit = async (values: IPaymentBatch) => {
    if (batchEditable) {
      values.dueDate = moment(values.dueDate).format('YYYY-MM-DDTHH:mm:ss');
      await updatePaymentBatch(values.id, values);
      Toast.info('Payment Batch updated successfully!');
      fetchBatchDetails();
    }
    setBatchEditable(!batchEditable);
    return Promise.resolve();
  };

  const discardHandleChanged = () => {
    setDiscardHandle(!discardHandle);
  };

  const setdiscardHandleChanged = () => {
    setDiscardHandle(false);
  };

  const handleSplitButtonClick = (buttonType: BatchSplitButtonType, values: IPaymentBatch) => {
    if (buttonType === BatchSplitButtonType.Pay) {
      payBatch(values);
    } else if (buttonType === BatchSplitButtonType.ApprovePay) {
      approvePayBatch(values);
    } else if (buttonType === BatchSplitButtonType.Approve) {
      approveBatch(values);
    } else if (buttonType === BatchSplitButtonType.SendForApproval) {
      sendBatchForApproval(values);
    }
  };

  const sendBatchForApproval = async (values: IPaymentBatch) => {
    await sendPaymentBatchForApproval(values.id);
    history.push(AUTH_BUYING_AP_BATCH);
  };

  const approveBatch = async (values: IPaymentBatch, redirect: boolean = true) => {
    if (!tenant?.GoodToTrade) {
      handleApproveClick();
    } else {
      await approvePaymentBatchForPayment(values.id);
      if (redirect) {
        history.push(AUTH_BUYING_AP_APPROVE_REQUESTS);
      } else {
        fetchBatchDetails();
      }
    }
  };

  const handleApproveClick = async () => {
    if (!tenant?.GoodToTrade) {
      const requestPayload = {
        Subject: 'AML/KYC application',
        WebsiteID: ApplicationInfo.WebsiteId,
        DatTypeID: DatTypes.AMLKYCApplication,
        To: '',
        PersonalisedMessage: 'Product Name: Accounts Payable',
      };
      if (process.env.REACT_APP_ENVIRONMENT === 'production') {
        requestPayload.To = 'customersuccess@spenda.co';
      } else {
        requestPayload.To = 'developers@spenda.co';
      }
      await sendAMLKYCEmail(requestPayload);
      if (remainingOnboardingStepsInfo?.showDialog) {
        setRemainingOnboardingStepsInfo(prev => {
          if (!prev) return prev;
          return {...prev, showDialog: false};
        });
      }
      setOpenKYCDialog(true);
    }
  };

  const handlePayClick = async () => {
    //Open the Payment widget
    setPayMode(true);
  };

  const approvePayBatch = async (values: IPaymentBatch) => {
    if (!tenant?.GoodToTrade) {
      handleApproveClick();
    } else {
      await approvePaymentBatchForPayment(values.id);
      await fetchBatchDetails();
      handlePayClick();
    }
  };

  const payBatch = async (values: IPaymentBatch) => {
    if (values.status !== PaymentBatchStatusTypes.PaymentApproved && !values?.authorisationID) {
      await approvePaymentBatchForPayment(values.id);
      await fetchBatchDetails();
    }
    if (values?.authorisationID) {
      setAuthorisationId(values?.authorisationID);
    } else {
      //Open the Payment widget
      handlePayClick();
    }
  };

  const cancelBatch = async (description: string) => {
    if (batchID) {
      await cancelPaymentBatch(batchID, description);
      Toast.info('Payment Batch discarded!');
      history.push(AUTH_BUYING_AP_BATCH);
    }
  };

  const onSubmitSearch = (values: {searchText: string}): Promise<void> => {
    setSearchString(values.searchText);
    return Promise.resolve();
  };

  const onPaymentApproved = () => {
    fetchBatchDetails();
  };

  const onPaymentFailed = () => {
    fetchBatchDetails();
  };

  const invoicesTable = css({
    borderRadius: '2px 2px 2px 2px',
    padding: '0px 12px',
    margin: '28px 0 0 0',
    maxHeight: 'calc(100vh - 24em)',
    minHeight: 'calc(100vh - 24em)',
    '& table': {
      '& th': {
        color: '#999999',
        fontWeight: 600,
        fontSize: '14px',
        fontFamily: 'Poppins',
        width: '180px',
      },
      '& .MuiTableCell-body': {
        background: batchEditable ? '#FAFAFA' : 'inherit',
      },
    },
  });

  const handleExpandPanel = () => {
    setCollapseView(!collapseView);
  };

  const leftpanel = (
    <div className={`flex h-full w-full flex-col`}>
      <div className={`${listContainer} invoicesList h-full rounded rounded bg-white`}>
        <div className={`${panelRightBody} relative flex h-full items-center justify-center bg-white`}>
          {!batchToEdit ? (
            <div className={`${selectAPInvoiceCard} mx-5 rounded px-7 py-8 text-center`}>
              <p className="font-poppins font-light">Loading Batch Details</p>
              <img src={SelectAPInvoice} className="mx-auto mt-8" data-autoid={`imgSelectAPInvoice`} />
            </div>
          ) : (
            <Formik
              validateOnBlur
              enableReinitialize
              validationSchema={validationSchema}
              initialValues={batchToEdit}
              onSubmit={handleEdit}
              validateOnMount
            >
              {({values, handleChange, setFieldValue, submitForm, errors, touched, dirty, isValid}) => (
                <div className="h-full w-full font-poppins">
                  <Grid
                    container
                    xs={12}
                    className={`flex items-center justify-between px-3 pb-3 pt-4 ${css({
                      borderBottom: '1px solid #D8D8D8',
                    })}`}
                  >
                    <Grid sm={6} item className="flex items-center justify-start text-lg text-primary">
                      {(matchPath(location.pathname, AUTH_BUYING_AP_APPROVE_REQUESTS_ABA_BATCH_VIEW) ||
                        matchPath(location.pathname, AUTH_BUYING_AP_AUTHORISE_PAYMENT_ABA_BATCH_VIEW)) && (
                        <span
                          className="cursor-pointer"
                          data-autoid="btnBack"
                          onClick={() => {
                            matchPath(location.pathname, AUTH_BUYING_AP_APPROVE_REQUESTS_ABA_BATCH_VIEW)
                              ? history.push(AUTH_BUYING_AP_APPROVE_REQUESTS)
                              : history.push(AUTH_BUYING_AP_AUTHORISE_PAYMENT);
                          }}
                        >
                          <img
                            src={backButton}
                            alt="Back Button"
                            style={{width: '40px', height: '40px', margin: '0px 10px 0px 0px'}}
                            data-autoid={`imgBack`}
                          />
                        </span>
                      )}
                      Batch payment: {values.refNumber}
                    </Grid>
                    <Grid sm={6} container className={`flex items-center justify-end`}>
                      <Formik
                        initialValues={{searchText: '' as string}}
                        onSubmit={onSubmitSearch}
                        validateOnChange={false}
                      >
                        {formik => (
                          <form className={`m-0 w-auto ${classes.searchForm}`}>
                            <ClickAwayListener onClickAway={handleClickAway}>
                              <div>
                                <div className="m-auto" {...bindToggle(searchInputPopup)}>
                                  <img src={searchIcon} className="cursor-pointer" data-autoid="imgSearchBatch" />
                                </div>
                                <Popper
                                  {...bindPopper(searchInputPopup)}
                                  disablePortal={false}
                                  placement="left"
                                  transition
                                >
                                  {({TransitionProps}) => (
                                    <Grow {...TransitionProps} timeout={250}>
                                      <SearchInput
                                        placeholder={'Search'}
                                        {...formik}
                                        onSubmit={formik.handleSubmit}
                                        isAccountsPayable={true}
                                      ></SearchInput>
                                    </Grow>
                                  )}
                                </Popper>
                              </div>
                            </ClickAwayListener>
                          </form>
                        )}
                      </Formik>
                      {!batchEditable &&
                      [PaymentBatchStatusTypes.Open, PaymentBatchStatusTypes.ReadyForApproval].includes(
                        values.status,
                      ) ? (
                        <span
                          className={`${classes.customBtns} mr-0 flex cursor-pointer items-center justify-center p-2`}
                          onClick={() => submitForm()}
                          data-autoid="lnkEdit"
                        >
                          <img src={batchEditIcon} alt="Back Button" data-autoid={`imgEdit`} />
                        </span>
                      ) : null}
                      <div className={`expandableView cursor-pointer`}>
                        <img
                          src={collapseView ? expandIcon : collapseIcon}
                          onClick={handleExpandPanel}
                          data-autoid="imgCollapseToggle"
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <div className={`mb-5 mt-7 flex pl-3 pr-5  ${!batchEditable ? `${classes.fieldsDisable}` : ''}`}>
                    <div style={{width: 'calc(100% - 185px)'}} className="flex">
                      <div className={`${classes.payInvoiceText} mr-1`}>
                        <STextField
                          fullWidth
                          id="name"
                          name="name"
                          variant="outlined"
                          label="Batch name"
                          value={values?.name}
                          onChange={handleChange}
                          helperText={touched?.name && errors?.name}
                          error={touched?.name && Boolean(errors?.name)}
                          className={`${classes.batchName}`}
                          disabled={!batchEditable}
                          inputProps={{
                            'data-autoid': `txtBatchName`,
                          }}
                        />
                      </div>
                      <div className={`${classes.payInvoiceText} mx-1`}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            autoOk
                            minDate={new Date()}
                            minDateMessage="Overdue date should be greater than today."
                            variant="inline"
                            id="dueDate"
                            name="dueDate"
                            inputVariant="outlined"
                            label="Payment date"
                            format="dd/MM/yyyy"
                            value={values?.dueDate}
                            InputAdornmentProps={{position: 'end'}}
                            onChange={date => setFieldValue('dueDate', date)}
                            helperText={touched?.dueDate && errors?.dueDate}
                            error={touched?.dueDate && Boolean(errors?.dueDate)}
                            PopoverProps={{className: classes.pickerDialog}}
                            className={`${classes.paymentDate}`}
                            disabled={!batchEditable}
                            inputProps={{
                              'data-autoid': 'dtpBatchPaymentDate',
                            }}
                            KeyboardButtonProps={
                              {
                                'aria-label': 'change date',
                                'data-autoid': 'dtpBatchPaymentDateIcon',
                              } as DatePickerKeyboardProps
                            }
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                      <FormControl
                        variant="outlined"
                        className={`${classes.batchSelect} mx-1`}
                        error={touched?.debits && Boolean(errors?.debits)}
                      >
                        <InputLabel id="demo-simple-select-outlined-label">Payment Method</InputLabel>
                        <Select
                          displayEmpty
                          label="Pay method"
                          name="payMethod"
                          value={paymentAccounts?.length ? paymentAccountSelected?.paymentAccountGUID || '' : '_'}
                          onChange={(e: React.ChangeEvent<{value: unknown}>) =>
                            setFieldValue('debits', formatAccountDetails(e.target.value as string))
                          }
                          variant="outlined"
                          MenuProps={{
                            getContentAnchorEl: null,
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left',
                            },
                          }}
                          inputProps={{
                            name: 'selectPaymentMethod',
                          }}
                          disabled={!batchEditable}
                          data-autoid="Batch_PaymentMethod_ddl"
                        >
                          {paymentAccounts && paymentAccounts?.length < 1 && (
                            <MenuItem value="_">No Payment Method added</MenuItem>
                          )}
                          {paymentAccounts?.map((account: IAccountResponse, index: number) => (
                            <MenuItem
                              data-autoid={`Batch_PaymentMethod-${index}_mnu`}
                              key={account.accountGUID}
                              value={account.accountGUID}
                            >
                              {account.paymentMethod === AccountType.BANK_TRANSFER ? (
                                <p
                                  className="bankMethod"
                                  title={`${account.paymentMethod} - ${account.bankDetails?.bankBSB} ${account.bankDetails?.bankAccountNumber}`}
                                >{`${account.paymentMethod} - ${account.bankDetails?.bankBSB} ${account.bankDetails?.bankAccountNumber}`}</p>
                              ) : account.accountType === AccountType.VIRTUAL_CARD ? (
                                <p
                                  className="bankMethod"
                                  title={`${account.paymentMethod} - ${account.virtualCard?.last4}`}
                                >{`${account.paymentMethod} - ${account.virtualCard?.last4}`}</p>
                              ) : (
                                <p
                                  className="bankMethod"
                                  title={`${account.paymentMethod} - ${account.cardDetails?.accountHolderName} ${account.cardDetails?.last4}`}
                                >{`${account.paymentMethod} - ${account.cardDetails?.accountHolderName} ${account.cardDetails?.last4}`}</p>
                              )}
                            </MenuItem>
                          ))}
                        </Select>
                        {touched.debits && errors?.debits && <FormHelperText>Select a payment method</FormHelperText>}
                      </FormControl>
                    </div>
                    <div className="mx-12 flex flex-col">
                      <span className="text-center text-xs font-semibold text-spenda-primarytext">Batch amount</span>
                      <span
                        className="text-xxl text-center font-light"
                        {...css({color: '#3C9F78', lineHeight: '33px'})}
                      >
                        {PriceFormat(values.paymentAmount)}
                      </span>
                    </div>
                  </div>
                  <TableContainer className={`${invoicesTable} overflow-y-auto`}>
                    <Table data-autoid="tblBatchSuppliers" className="tableCellHeight" aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">Payee</TableCell>
                          <TableCell align="left">Payment description</TableCell>
                          <TableCell align="left">Bank account</TableCell>
                          <TableCell align="right">Transaction amount</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {(searchString
                          ? values.credits.filter(obj =>
                              Object.values(
                                pick(obj, [
                                  'bankAccountNumber',
                                  'bankBSB',
                                  'description',
                                  'paymentAmount',
                                  'status',
                                  'supplierName',
                                ]),
                              ).some(val => (val + '').toLocaleLowerCase().includes(searchString.toLocaleLowerCase())),
                            )
                          : values.credits
                        ).map((credit: IPaymentBatchCreditLine, index: number) => (
                          <TableRow key={credit.payeeID}>
                            <TableCell className="text-black-800">
                              <span className="font-poppins text-base font-medium text-black-800">
                                {credit.supplierName}
                              </span>
                            </TableCell>
                            <>
                              {batchEditable ? (
                                <TableCell
                                  align="left"
                                  width="206px"
                                  className={`relative ${classes.payInvoiceText} ${
                                    batchEditable && 'editableInvoiceText'
                                  }`}
                                >
                                  <STextField
                                    data-autoid={`txtPaymentDescription-${credit.supplierID}`}
                                    fullWidth
                                    key={`credits.${[index]}.description`}
                                    id={`credits.${[index]}.description`}
                                    name={`credits.${[index]}.description`}
                                    variant="outlined"
                                    value={values?.credits?.[index]?.description}
                                    onChange={handleChange}
                                    onFocus={() => {
                                      setSelectedDescription({id: `credits.${[index]}.description`, isSelected: true});
                                      setDescAnchorEl(null);
                                    }}
                                    focused={selectedDescription?.id === `credits.${[index]}.description`}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end" className="relative">
                                          {selectedDescription?.id === `credits.${[index]}.description` &&
                                          creditDescriptions &&
                                          !creditDescriptions?.find(cd => cd.payeeID === credit.payeeID)
                                            ?.defaultDescription ? (
                                            <img
                                              src={pinIcon}
                                              onMouseDown={e => {
                                                e.preventDefault();
                                                saveDefaultDescription(
                                                  credit?.payeeID || 0,
                                                  values?.credits?.[index]?.description || '',
                                                );
                                              }}
                                              className={classes.pin}
                                              title="Mark as default"
                                              data-autoid={`imgPinIcon-${credit.supplierID}`}
                                            />
                                          ) : (
                                            <>
                                              {creditDescriptions?.find(cd => cd.payeeID === credit.payeeID)
                                                ?.defaultDescription === values?.credits?.[index]?.description && (
                                                <img
                                                  src={greyPin}
                                                  className={classes.pin}
                                                  title="Default"
                                                  data-autoid={`imgGreyPin-${credit.supplierID}`}
                                                />
                                              )}
                                              {creditDescriptions?.find(cd => cd.payeeID === credit.payeeID)
                                                ?.defaultDescription &&
                                              creditDescriptions
                                                ?.find(cd => cd.payeeID === credit.payeeID)
                                                ?.defaultDescription?.trim() !== '' &&
                                              batchCredits?.find(cd => cd.payeeID === credit.payeeID)?.description !==
                                                creditDescriptions?.find(cd => cd.payeeID === credit.payeeID)
                                                  ?.defaultDescription ? (
                                                !Boolean(descAnchorEl) ? (
                                                  <ArrowDropDownOutlinedIcon
                                                    onClick={event => {
                                                      setSelectedDescription({
                                                        id: `credits.${[index]}.description`,
                                                        isSelected: true,
                                                      });
                                                      setDescAnchorEl(event.currentTarget);
                                                    }}
                                                    data-autoid={`imgDropDownArrow-${credit.supplierID}`}
                                                    {...css({color: '#1C78AD', fontSize: '1.75rem', cursor: 'pointer'})}
                                                  />
                                                ) : (
                                                  <>
                                                    <ArrowDropUpOutlinedIcon
                                                      onClick={() => {
                                                        setDescAnchorEl(null);
                                                      }}
                                                      {...css({
                                                        color: '#1C78AD',
                                                        fontSize: '1.75rem',
                                                        cursor: 'pointer',
                                                      })}
                                                      data-autoid={`imgDropUpArrow-${credit.supplierID}`}
                                                    />
                                                  </>
                                                )
                                              ) : null}
                                            </>
                                          )}
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                  {selectedDescription?.id === `credits.${[index]}.description` &&
                                    creditDescriptions?.find(cd => cd.payeeID === credit.payeeID)?.defaultDescription &&
                                    creditDescriptions
                                      ?.find(cd => cd.payeeID === credit.payeeID)
                                      ?.defaultDescription?.trim() !== '' &&
                                    batchCredits?.find(cd => cd.payeeID === credit.payeeID)?.description !==
                                      creditDescriptions?.find(cd => cd.payeeID === credit.payeeID)
                                        ?.defaultDescription &&
                                    Boolean(descAnchorEl) && (
                                      <div className={classes.descSelect}>
                                        <>
                                          <MenuItem
                                            selected={
                                              values?.credits?.[index]?.description !==
                                              creditDescriptions?.find(cd => cd.payeeID === credit.payeeID)
                                                ?.defaultDescription
                                            }
                                            onClick={e => {
                                              e.preventDefault();
                                              setFieldValue(
                                                `credits.${[index]}.description`,
                                                batchCredits?.find(cd => cd.payeeID === credit.payeeID)?.description,
                                              );
                                              setDescAnchorEl(null);
                                            }}
                                          >
                                            {batchCredits?.find(cd => cd.payeeID === credit.payeeID)?.description}
                                          </MenuItem>
                                          <MenuItem
                                            selected={
                                              values?.credits?.[index]?.description ===
                                              creditDescriptions?.find(cd => cd.payeeID === credit.payeeID)
                                                ?.defaultDescription
                                            }
                                          >
                                            <div className="flex w-full justify-between">
                                              <span
                                                className={classes.descSpan}
                                                onClick={e => {
                                                  e.preventDefault();
                                                  setFieldValue(
                                                    `credits.${[index]}.description`,
                                                    creditDescriptions?.find(cd => cd.payeeID === credit.payeeID)
                                                      ?.defaultDescription,
                                                  );
                                                  setDescAnchorEl(null);
                                                }}
                                                data-autoid={`lnkDescription-${credit.supplierID}`}
                                              >
                                                {
                                                  creditDescriptions?.find(cd => cd.payeeID === credit.payeeID)
                                                    ?.defaultDescription
                                                }
                                              </span>
                                              <img
                                                src={unPin}
                                                onMouseDown={e => {
                                                  e.preventDefault();
                                                  setFieldValue(
                                                    `credits.${[index]}.description`,
                                                    batchCredits?.find(cd => cd.payeeID === credit.payeeID)
                                                      ?.description,
                                                  );
                                                  saveDefaultDescription(credit?.payeeID || 0, '');
                                                }}
                                                className={classes.unpin}
                                                title="Unpin default"
                                                data-autoid={`imgUnpinIcon-${credit.supplierID}`}
                                              />
                                            </div>
                                          </MenuItem>
                                        </>
                                      </div>
                                    )}
                                </TableCell>
                              ) : (
                                <TableCell align="left" className={`${classes.payInvoiceText} ${classes.desc}`}>
                                  <span className="flex items-center font-poppins text-base font-medium text-black-800">
                                    {credit.description}{' '}
                                  </span>
                                  {credit.description === credit.defaultDescription && (
                                    <img
                                      src={greyPin}
                                      className={classes.greyPin}
                                      data-autoid={`imgGreyPin-${credit.supplierID}`}
                                    />
                                  )}
                                </TableCell>
                              )}
                            </>
                            <TableCell align="left">
                              <span className="font-poppins text-base font-medium text-black-800">
                                {credit.bankBSB} {credit.bankAccountNumber}
                              </span>
                            </TableCell>

                            <TableCell align="right">
                              <span className="font-poppins text-base font-medium text-black-800">
                                {PriceFormat(credit.paymentAmount)}
                              </span>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <div className="absolute bottom-0 w-full p-2">
                    <div className={`${classes.footer} flex items-center justify-between`}>
                      <div>
                        {values.status === PaymentBatchStatusTypes.Open ||
                        (userRoleBatchPermissions &&
                          userRoleBatchPermissions.includes(PaymentBatchPermissionType.ApproveBatch) &&
                          values.status === PaymentBatchStatusTypes.ReadyForApproval) ||
                        values.status === PaymentBatchStatusTypes.PaymentApproved ? (
                          <SButton
                            borderRadius="6px"
                            margin="0"
                            textColor="discardRed"
                            lineHeight="l"
                            height="40px"
                            boxShadow="none"
                            fontFamily="poppins"
                            fontSize="14px"
                            border="1px solid #C55D44"
                            width="140px"
                            color="white"
                            disabled={isLoading}
                            onClick={discardHandleChanged}
                            data-autoid="btnDiscardBatch"
                          >
                            Discard Batch
                          </SButton>
                        ) : null}
                      </div>
                      <div className="flex items-center">
                        {batchEditable &&
                        [PaymentBatchStatusTypes.Open, PaymentBatchStatusTypes.ReadyForApproval].includes(
                          values.status,
                        ) ? (
                          <SButton
                            borderRadius="6px"
                            margin="0 10px 0 0"
                            textColor="blueShade"
                            lineHeight="l"
                            height="40px"
                            boxShadow="none"
                            fontFamily="poppins"
                            fontSize="14px"
                            border="1px solid #0082BA"
                            width="96px"
                            color="white"
                            onClick={() => {
                              if (
                                onboardingUpgradeV1 &&
                                !tenantInfo?.TenantUserDetails.AreAllAPOnboardingStepsCompletedForTenant
                              ) {
                                setRemainingOnboardingStepsInfo(prev => {
                                  if (!prev) return prev;
                                  return {...prev, showDialog: true};
                                });
                              } else {
                                submitForm();
                              }
                            }}
                            data-autoid="btnSave"
                            isSubmitting={isLoading}
                            disabled={isLoading}
                          >
                            Save
                          </SButton>
                        ) : null}

                        <div>
                          <BatchSplitButton
                            disabled={(batchEditable && dirty) || isLoading || !isValid}
                            isSubmitting={isLoading}
                            batchStatus={batchToEdit.status}
                            batchPaymentMethod={batchToEdit?.debits?.[0]?.paymentAccountGUID}
                            handleClick={x => handleSplitButtonClick(x, values)}
                            isCurrentUserAnAuthoriser={isCurrentUserAnAuthoriser}
                            isAuthorisationRulesSet={isAuthorisationRulesSet}
                            isAuthorisationList={
                              matchPath(location.pathname, AUTH_BUYING_AP_AUTHORISE_PAYMENT_ABA_BATCH_VIEW)
                                ? true
                                : false
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Formik>
          )}
        </div>
      </div>
    </div>
  );

  const mainPanel = <BatchStatus status={batchToEdit?.status} actions={batchToEdit?.actions} />;

  return (
    <>
      {payMode && (
        <PayBatch
          batchID={parseInt(batchID)}
          onClose={() => setPayMode(false)}
          onPaymentApproved={onPaymentApproved}
          onPaymentFailed={onPaymentFailed}
          onDone={() => history.push(`${AUTH_BUYING_AP_REPORTS}/detail/${batchID}`)}
        />
      )}
      {authorisationId && (
        <PaymentWidgetModal
          userID={user?.UserID}
          tenantName={user?.TenantName}
          open={true}
          onClose={() => {
            setAuthorisationId(undefined);
          }}
          widgetScope={PaymentWidgetScope.AUHTORISE_PAYMENT}
          getPaymentMethodAuthorisationDetails={getPaymentMethodAuthorisationDetails}
          rejectPaymentAuthorisation={rejectPaymentAuthorisation}
          approvePaymentAuthorisation={approvePaymentAuthorisation}
          resetAuthenticationCode={resetAuthenticationCode}
          getPaymentMethodAuthorisationRules={getPaymentMethodAuthorisationRules}
          authorisationId={authorisationId}
          isAccountsPayable
          onDone={() => {
            setAuthorisationId(undefined);
            history.push(`${AUTH_BUYING_AP_REPORTS}/detail/${batchID}`);
          }}
          isAPOnboardingV1={onboardingUpgradeV1}
        />
      )}
      {discardHandle && (
        <DiscardBatchModal
          isLoading={isLoading}
          handleClose={setdiscardHandleChanged}
          handleDiscardClick={cancelBatch}
        />
      )}
      {openKYCDialog && <AMLKYCModal isLoading={isLoading} handleClose={() => setOpenKYCDialog(!openKYCDialog)} />}
      <div className={`relative h-full overflow-hidden bg-spenda-newbg`}>
        <Layout
          leftPanel={
            <LoadingIndicator isLoading={isLoading} overlayBgColor="rgba(0, 0, 0, 0.05)">
              {leftpanel}
            </LoadingIndicator>
          }
          mainPanel={collapseView && mainPanel}
          splitWidthType={collapseView ? 'AR' : 4}
        />
      </div>
      {remainingOnboardingStepsInfo?.showDialog && (
        <OnboardingStepsRemainingModal
          handleClose={() => {
            setRemainingOnboardingStepsInfo(prev => {
              if (!prev) return prev;
              return {...prev, showDialog: false};
            });
          }}
          handleCompleteStep={() => {
            setRemainingOnboardingStepsInfo(prev => {
              if (!prev) return prev;
              return {...prev, showDialog: false};
            });
          }}
          countOfRemainingSteps={remainingOnboardingStepsInfo?.remainingSteps || 0}
          onboardingStep={remainingOnboardingStepsInfo?.steps || 0}
          paymentAccounts={paymentAccounts}
          showAMLKYCPending={remainingOnboardingStepsInfo?.showAMLKYCPending}
          handleAmlKycClick={handleApproveClick}
        />
      )}
    </>
  );
};
