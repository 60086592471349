import React from 'react';
import {MarketplaceLayout} from '../../purchasing/MarketplaceLayout';
import {PaymentOptionsSettings} from './PaymentOptionsSettings';
import {PSBLFooter} from '../../../components/AccountsReceivable/PSBLFooter';

export const PSBLSettings = () => {
  const leftPanel = (
    <div className="font-poppins w-full bg-white h-full px-5 py-6 mr-3 css-18oc5fq">
      <PaymentOptionsSettings />
    </div>
  );

  return (
    <div className={`h-full relative overflow-hidden !bg-spenda-newbg`}>
      <MarketplaceLayout leftPanel={leftPanel} splitWidthType={4} />
      <PSBLFooter />
    </div>
  );
};
