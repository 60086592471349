import React, {useState} from 'react';
import {AlertDialogSlideV2} from '../dialog/AlertDialogSlideV2';
import {SearchInput, Step, Stepper, Textarea, Typography} from 'spenda-ui-react';
import {BlueInfoIcon, PostingBehaviourIcon} from '../../assets/svg';
import {camelCase, upperCase} from 'lodash';
import {ARTable} from '../AccountsReceivable/ARTable';
import CreateMasterVariantDialog from '../dialog/CreateMasterVariantDialog';

export interface IVariantInventory {
  handleClose: () => void;
}

interface IProductVariantBox {
  content?: string;
  handleSelectOption?: () => void;
  isSelected?: boolean;
}

export default function VariantInventory(props: IVariantInventory) {
  const {handleClose} = props;
  const [selectedBox, setSelectedBox] = useState<number>(2);
  const [selectPostingBehavior, setSelectPostingBehavior] = useState<boolean>(false);
  const [openMasterVariantDialog, setOpenMasterVariantDialog] = useState<boolean>(false); 

  const handleSelectOption = (index: number) => {
    setSelectedBox(index);
  };

  const ProductVariantBox = (props: IProductVariantBox) => {
    const {content, handleSelectOption, isSelected} = props;

    return (
      <div
        className={`flex h-[112px] max-w-[278px] cursor-pointer items-center justify-center rounded-md border p-8 ${isSelected ? 'border-primary bg-primary/10' : 'border-[#ECECEC] bg-spenda-cream'}`}
        onClick={handleSelectOption}
      >
        <Typography
          className="text-center font-medium text-black-800"
          data-autoid={`txt${upperCase(camelCase(content))}`}
        >
          {content}
        </Typography>
      </div>
    );
  };
  const columns = [
    {
      title: 'Code',
      key: 'code',
      width: '16%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5 ',
      rowRenderer: (index: number) => (
        <Typography className="text-base text-black-800 font-medium" data-autoid={`txtCode-${index}`}>
          000003
        </Typography>
      ),
    },
    {
      title: 'Product',
      key: 'product',
      width: '16%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5 ',
      rowRenderer: (index: number) => (
        <Typography className="text-base text-black-800 font-medium" data-autoid={`txtProduct-${index}`}>
          Aquara 02
        </Typography>
      ),
    },
    {
      title: 'Category',
      key: 'category',
      width: '16%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5 ',
      rowRenderer: (index: number) => (
        <Typography className="text-base text-black-800 font-medium" data-autoid={`txtCategory-${index}`}>
          Timber
        </Typography>
      ),
    },
    {
      title: 'Brand',
      key: 'brand',
      width: '16%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5 ',
      rowRenderer: (index: number) => (
        <Typography className="text-base text-black-800 font-medium" data-autoid={`txtBrand-${index}`}>
          -
        </Typography>
      ),
    },
    {
      title: 'Supplier',
      key: 'supplier',
      width: '24%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5 ',
      rowRenderer: (index: number) => (
        <Typography className="text-base text-black-800 font-medium" data-autoid={`txtSupplier-${index}`}>
          Adele Kenworthy Pvt Ltd
        </Typography>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      width: '10%',
      align: 'center',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      rowRenderer: (index: number) => (
        <Typography  className="text-base text-primary font-medium cursor-pointer" data-autoid={`txtAction-${index}`}>
          Select
        </Typography>
      ),
    },
  ];
  return (
    <div>
      <AlertDialogSlideV2
        dialogActionsAlignment="justify-between"
        size="lg"
        dialogBodyClassess="px-0 py-0"
        headingTextSize="h1"
        headingClassess="border-none justify-center"
        data-autoid={`${selectPostingBehavior ? 'dlgMasterInventory' : 'dlgPostingBehavior'}`}
        title={`${
          selectPostingBehavior ? 'Set your child variant posting behaviour' : 'Setup your master inventory item'
        }`}
        actions={[
          {
            label: 'Cancel',
            variant: 'outlined',
            action: handleClose,
          },
          {
            label: !selectPostingBehavior ? 'Next' : 'Create Master Variant',
            action: () => {
              if (selectPostingBehavior) {
                setOpenMasterVariantDialog(true);
              } else {
                setSelectPostingBehavior(true);
              }
            },
          },
        ]}
      >
        <>
          <div className="bg-cream">
            <div className="flex items-center justify-center bg-[#F6F6F6] py-3">
              <Stepper activeStep={0} className="w-[65%]">
                <Step className="flex h-full w-max gap-x-4 rounded-none !bg-[#F6F6F6] px-4 ">
                  <BlueInfoIcon />
                  <Typography className="text-base text-black-800">Setup master inventory</Typography>
                </Step>
                <Step className="flex h-full w-max gap-x-4 rounded-none !bg-[#F6F6F6] px-4 text-white/75">
                  <PostingBehaviourIcon />
                  <Typography className="text-base text-black-800">Posting behaviour</Typography>
                </Step>
              </Stepper>
            </div>
          </div>
          {selectPostingBehavior ? (
            <div className="flex flex-col items-center justify-center gap-y-6 px-8">
              <Typography className="py-5 font-poppins text-base text-black-300">
                How would you like us to post your variant children to...?
              </Typography>
              <div className="flex w-full cursor-pointer items-center gap-8 justify-center text-center">
                <ProductVariantBox
                  content="Post all variant children to..."
                  isSelected={selectedBox === 0}
                  handleSelectOption={() => handleSelectOption(0)}
                />
                <ProductVariantBox
                  content="Post all variant children through an existing generic class"
                  isSelected={selectedBox === 1}
                  handleSelectOption={() => handleSelectOption(1)}
                />
                <ProductVariantBox
                  content="Post all variant children through a new generic class"
                  isSelected={selectedBox === 2}
                  handleSelectOption={() => handleSelectOption(2)}
                />
              </div>
              {selectedBox === 1 && (
                <div className="flex flex-col w-full">
                  <div className="flex flex-col w-[380px] gap-2 items-start justify-center text-black-800">
                    <SearchInput
                      name="searchString"
                      id="search-text-input"
                      placeholder="Search..."
                      type="text"
                      clearIcon
                      data-autoid="txtSearchBar"
                      expanded
                      reverse
                    />
                    <Typography className="font-poppins text-xs font-medium text-[#cccccc]">
                      *This will search for all the existing vendors in your financial system.
                    </Typography>
                  </div>
                  <Typography className="text-lg font-poppins font-medium text-black-800 pt-5"> 4 search results: </Typography>
                  <ARTable isLoading={false} columns={columns} scope="AR" rows={Array(4).fill(1)} tableClass="mt-3" conatinerClass='!px-0 max-h-[265px]'/>
                </div>
              )}
              {selectedBox === 2 && (
                <div className="mt-5 flex w-[480px] flex-col items-center justify-center pb-[134px]">
                  <Textarea
                    name="genericClass"
                    id="genericClass"
                    data-autoid="txtGenericClassName"
                    label="New generic class name"
                    className=" !h-[45px] min-h-[45px]"
                  />
                  <Typography className="text-start text-xs text-black-800">
                    All variant children will be posted to your finance system as...
                  </Typography>
                </div>
              )}
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center">
              <div className="flex w-[634px] flex-col gap-5 p-6 py-14 text-center">
                <Typography variant="small" className="font-semibold text-black-300">
                  Brake Master Cylinder - 210A0372 (210A0372)
                  <span className="font-normal">
                    cannot be promoted to a Master item as its integrated with another system.
                  </span>
                </Typography>
                <Typography variant="small" className="font-normal text-black-300">
                  We will need to make a copy of this item to continue. What would you like the inventory code to be for
                  your new variant master item?
                </Typography>
              </div>
              <div className="flex w-[480px] items-center justify-center pb-[8rem]">
                <Textarea
                  name="variantInventory"
                  id="variantInventory"
                  label="Variant master inventory code"
                  className="!h-[45px] min-h-[45px]"
                  color="primary"
                />
              </div>
            </div>
          )}
        </>
      </AlertDialogSlideV2>
      {openMasterVariantDialog && (
        <CreateMasterVariantDialog
          open={openMasterVariantDialog}
          onClose={() => setOpenMasterVariantDialog(false)}
        />
      )}
    </div>
  );
}
