import React, {useContext, useEffect, useState} from 'react';
import {IconButton} from '@material-ui/core';
import {ArrowBack, ArrowForward, Close} from '@material-ui/icons';
import {ARDialogTemplate} from '../ARDialogTemplate';
import {Button} from 'spenda-ui-react';
import {SelectEmailTemplateScope} from '../SelectEmailTemplateDialog';
import ARContext from '../../../context/ar-context/ARContext';
import {useAutomatedStatementsAPI} from '../../../services/useAutomatedStatementsAPI';
import {IEmailTemplate, IPreviewEmailTemplate} from '../../../model/statements/Statements';
import {DatTypes} from '../../../model/constants/Constants';
import LoadingIndicator from '../../ui/LoadingIndicator';

interface IPreviewModalProps {
  isShowModal: boolean;
  onClose: () => void;
  onClickSendButton: () => void;
  onClickEditButton: () => void;
  scope: SelectEmailTemplateScope;
  invoiceId?: number;
  accountCustomerId?: number;
  selectedTemplate?: IEmailTemplate;
}
export const PreviewModal = (props: IPreviewModalProps) => {
  // Props
  const {
    isShowModal,
    selectedTemplate,
    invoiceId,
    accountCustomerId,
    scope,
    onClickEditButton,
    onClickSendButton,
    onClose,
  } = props;

  // State
  const [currentViewingIndex, setCurrentViewingIndex] = useState(0);
  const [previewData, setPreviewData] = useState<IPreviewEmailTemplate>();

  // Context
  const {emailBatch} = useContext(ARContext);

  // API
  const {previewEmailTemplate, isLoading} = useAutomatedStatementsAPI();

  useEffect(() => {
    getEmailTemplatePreview();
  }, [currentViewingIndex]);

  const getEmailTemplatePreview = async () => {
    try {
      if (!selectedTemplate) return;

      const accountCustomerIds = emailBatch?.emailBatchList?.map(e => e?.accountCustomerId);
      const id = accountCustomerId || accountCustomerIds?.[currentViewingIndex];
      if (!id) return;

      let payload = {
        alertID: selectedTemplate.alertID,
        alertType: DatTypes.CustomerStatements,
        request: {
          AccountCustomerID: id,
        },
      };

      switch (scope) {
        case SelectEmailTemplateScope.SendStatement:
          Object.assign(payload.request, {
            TimelineGroup: emailBatch?.timelineGroup,
            TimelineSubGroupID: emailBatch?.timelineSubGroupId,
          });
          break;
        case SelectEmailTemplateScope.SendInvoice:
          Object.assign(payload.request, {
            InvoiceID: invoiceId,
          });
          break;
      }

      const response = await previewEmailTemplate(payload);
      setPreviewData(response?.[0]);
    } catch (error) {
      console.error('Failed to preview email template:', error);
    }
  };

  const getAccountCustomerIds = () => {
    return accountCustomerId ? [accountCustomerId] : emailBatch?.emailBatchList?.map(e => e?.accountCustomerId) || [];
  };

  const onClickArrow = (action: 'NEXT' | 'PREVIOUS') => {
    const id = getAccountCustomerIds();
    if (!id.length) return;

    if (action === 'NEXT' && currentViewingIndex < id.length - 1) {
      setCurrentViewingIndex(currentViewingIndex + 1);
    } else if (action === 'PREVIOUS' && currentViewingIndex > 0) {
      setCurrentViewingIndex(currentViewingIndex - 1);
    }
  };

  const isArrowsAreDisabled = (action: 'NEXT' | 'PREVIOUS') => {
    const id = getAccountCustomerIds();
    if (!id.length) return true;

    if (action === 'NEXT') {
      return currentViewingIndex >= id.length - 1;
    } else if (action === 'PREVIOUS') {
      return currentViewingIndex <= 0;
    }
  };

  const nextButton = (
    <IconButton
      onClick={() => onClickArrow('NEXT')}
      disabled={isArrowsAreDisabled('NEXT')}
      data-autoid="btnPDFViewNext"
      className="flex flex-row items-center justify-center"
      style={{
        padding: 0,
        color: '#fff',
        position: 'absolute',
        right: '-100px',
        top: '50%',
        zIndex: 10,
      }}
    >
      <div className="flex h-[48px] w-[48px] flex-col items-center justify-center rounded-full bg-[#CCC] hover:bg-spenda-sBlue">
        <ArrowForward style={{color: 'inherit', fontSize: '40px', zIndex: 10}} />
      </div>
    </IconButton>
  );

  const previousButton = (
    <IconButton
      onClick={() => onClickArrow('PREVIOUS')}
      disabled={isArrowsAreDisabled('PREVIOUS')}
      data-autoid="btnPDFViewPrev"
      className="flex flex-row items-center justify-center"
      style={{
        padding: 0,
        color: '#fff',
        position: 'absolute',
        left: '-100px',
        top: '50%',
        zIndex: 10,
      }}
    >
      <div
        onClick={() => {}}
        className="flex h-[48px] w-[48px] flex-col items-center justify-center rounded-full bg-[#CCC] hover:bg-spenda-sBlue"
      >
        <ArrowBack style={{color: 'inherit', fontSize: '40px', zIndex: 10}} />
      </div>
    </IconButton>
  );

  return (
    <ARDialogTemplate
      dialogProps={{size: 'md', open: isShowModal, className: '!min-w-[548px] !w-[548px]'}}
      bodyClass="!py-0 !mx-0"
      isFullScreen
      body={
        <div className="invoiceBody relative m-auto flex !h-[inherit] flex-col items-center">
          <div
            data-autoid={'btnClose'}
            onClick={onClose}
            className="absolute -right-[158px] -top-[40px] z-10 flex cursor-pointer flex-row items-center justify-center"
          >
            <Close className=" text-primary" />
            <p className="ml-1 font-poppins text-xl font-light text-black-800">Close</p>
          </div>
          <div className={`relative m-0 flex h-full w-full flex-col`}>
            <div className="relative flex h-[50px] w-[auto] flex-row items-center justify-center border-[#F7F7F7] border-b-default px-4">
              <p className=" font-poppins text-xl font-light text-[#333333]">{previewData?.templateName}</p>
            </div>

            <div className="flex">{previousButton}</div>
            {!isLoading && !previewData ? (
              <div className="flex h-[540px] items-center justify-center">
                <h2 className="flex items-center justify-center text-lg">Preview not found</h2>
              </div>
            ) : isLoading ? (
              <div className="h-[540px]">
                <LoadingIndicator isLoading={isLoading} size={'md'} />
              </div>
            ) : (
              <div className=" w-[auto] pt-2.5">
                <div className="flex w-full flex-col gap-5 px-5">
                  <div className="flex flex-col">
                    <p className="text-left font-poppins text-base font-medium text-black-800">Email Address</p>
                    <div className="w-full rounded-md border border-[#efefef] bg-[#FAFAFA] px-5 py-3">
                      <p className="text-left font-poppins text-base font-light text-black-800">
                        {previewData?.recipientsGroup?.toString()}
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-left font-poppins text-base font-medium text-black-800">Subject</p>
                    <div className="w-full rounded-md border border-[#efefef] bg-[#FAFAFA] px-5 py-3">
                      <p className="text-left font-poppins text-base font-light text-black-800">
                        {previewData?.subject}
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-left font-poppins text-base font-medium text-black-800">Email</p>
                    <div className="relative flex max-h-[240px] w-full overflow-y-auto rounded-md border border-[#efefef] bg-[#FAFAFA] p-5">
                      <p
                        dangerouslySetInnerHTML={{__html: previewData?.content || ''}}
                        className="text-left font-poppins text-base font-light text-black-800"
                      />
                    </div>
                  </div>
                </div>
                <div
                  className={`mt-5 flex h-[52px] w-full items-center justify-center rounded-[6px] bg-[#EFEFEF] px-2.5`}
                >
                  <div className="relative flex h-full w-full flex-row items-center justify-between gap-1">
                    <Button
                      data-autoid={`btnEdit`}
                      className="bg-white"
                      onClick={() => onClickEditButton?.()}
                      variant="outlined"
                      color="primary"
                    >
                      Edit
                    </Button>
                    {(scope === SelectEmailTemplateScope.SendInvoice ||
                      scope === SelectEmailTemplateScope.SendStatement) && (
                      <Button
                        onClick={() => onClickSendButton?.()}
                        variant="filled"
                        color="primary"
                        data-autoid={`btnSend`}
                      >
                        Send
                      </Button>
                    )}
                    {scope === SelectEmailTemplateScope.SelectTemplate && (
                      <Button variant="filled" color="primary" data-autoid={`btnDone`}>
                        Done
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            )}
            <div className="flex">{nextButton}</div>
          </div>
        </div>
      }
    />
  );
};
