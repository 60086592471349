import {useMediaQuery, useTheme as muiUseTheme, makeStyles} from '@material-ui/core';
import React, {useContext, useState, useEffect, Dispatch, SetStateAction, PropsWithChildren, useMemo} from 'react';
import {css} from 'glamor';
import Joyride, {Step, TooltipRenderProps} from 'react-joyride';
import {IUser} from '../../model/user/User';
import {SPopover} from '../modals/modalSpendaMeterialUI';
import {ITenant, LogoTypeEnum} from '../../model/Tenant';
import {NameInitials} from '../../utils/formatter';
import {HeaderDialogs} from './HeaderDialogs';
import {useHistory, RouteProps, Link, useLocation} from 'react-router-dom';
import {AUTH_PURCHASING_PAY_BILL_ROUTE} from '../../routes/AccountsPayableRoutes';
import {AUTH_PURCHASING_PURCHASE_INVOICE_LIST} from '../../routes/PurchasingRoutes';
import _ from 'lodash';
import {
  AccountUsage,
  MarketplaceConnectionStatus,
  ModuleCategories as ModuleCategoriesEnums,
  ModuleStatus,
  ModuleTypes,
  PaymentProviderName,
} from '../../model/constants/Constants';
import {ZohoContext} from '../../context/zoho-context/ZohoContext';
import {FeatureFlagKeys, useFeatureFlags} from '../../hooks/useFeatureFlags';
import {Button, Typography} from 'spenda-ui-react';

// Icons
import MockARBackground from '../../assets/jpg/HomeBlurBgg.jpg';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import SVGIcon, {SVGName} from '../ui/SVGIcon';
import AppContext from '../../context/app/appContext';
import {IMenuModule} from '../../model/menu/Menu';
import {ModuleInfo} from './ModuleInfo';
import {ConnectAccount} from '../paymentOnboarding/ConnectAccount';
import {useTenantInfo} from '../../hooks/useTenantInfo';
import {UserRoleEnum} from '../../model/user/UserAccountInfo';
import useSpendaPaymentServicesAPI from '../../services/useSpendaPaymentServicesAPI';
import useTenantAPI from '../../services/useTenantAPI';
import useHomePageAPI from '../../services/useHomePageAPI';
import {IConnectedSupplier} from '../../model/supplier/ConnectedSupplier';
import {createAutoIdAttributeFromName} from '../../utils/createAutoIdAttribute';
import {IntegrationContext} from '../../context/IntegrationContext';
import {AUTH_BUYING_AP_BATCH} from '../../routes/AccountsPayableRoutes';
import {SkeletonMenu} from './MenuConfig';
import Skeleton from '../Skeleton';
import {
  AUTH_ACCOUNTSPAYABLE_MODULEINFO_ROUTE,
  AUTH_MENU,
  AUTH_SELLING_AR_PAID_INVOICES_ROUTE,
} from '../../routes/AuthenticatedRoutes';
import {Header} from './MenuHeader';
import TourStatusIcon from '../../assets/svg/TourStatusIcon';
import StepSucessIcon from '../../assets/png/AccountsPayable/onboardingSucessGreenCheck.png';
import TourPauseIcon from '../../assets/svg/TourPauseIcon';
import TourPlayIcon from '../../assets/svg/TourPlayIcon';
import {StatementsDialog} from '../../screens/statements/StatementsDialog';
import useTheme from '../../context/theme-context/useTheme';
import {AUTH_SELLING_QUOTES_SETTINGS} from '../../routes/QuoteManagementRoutes';
import {useEnvironment} from '../../hooks/useEnvironment';

const useStyles = makeStyles({
  typography: {
    fontFamily: 'Poppins',
  },
  fontType1: {
    fontSize: '0.75rem',
  },
  fontType2: {
    fontSize: '0.875rem',
  },
  fontType3: {
    fontSize: '1.25rem',
  },
  fontType4: {
    fontSize: '1.375rem',
  },
  fontType5: {
    fontSize: '1.75rem',
  },
  fontType6: {
    fontSize: '2rem',
  },
  fontType7: {
    fontSize: '3.75rem',
  },
  fontLight: {
    fontWeight: 300,
  },
  fontMedium: {
    fontWeight: 500,
  },
  fontSemiBold: {
    fontWeight: 600,
  },
  root: {
    '& .MuiPaper-root': {
      background: 'transparent !important',
      overflow: 'hidden',
      maxWidth: '100% !important',
      maxHeight: '100% !important',
      width: '100%',
      height: '100%',
      top: '0px !important',
      left: '0px !important',
      bottom: '0px !important',
      right: '0px !important',
      borderRadius: '0px !important',
      transition: 'none !important',
    },
  },
});

type color = 'success' | 'warning' | 'error' | 'cool-purple';
export interface IMenuDialogProps extends IMenuProps {
  openMenu: boolean;
}

export interface IMenuProps extends RouteProps {
  isDialog?: boolean;
  handleClose?: () => void;
  supplierId?: string | undefined;
  user: IUser;
  tenant: ITenant;
  connectedSuppliers?: IConnectedSupplier[];
}

export interface IFilteredMenuModules {
  [key: string]: IMenuModule[];
}

export interface IFilteredSubCategories {
  [key: string]: IFilteredMenuModules;
}

type CustomTourStep = Step & {id: number};
export const MenuDialog = (props: IMenuDialogProps) => {
  const {openMenu, handleClose, user, tenant, supplierId, connectedSuppliers} = props;

  const classes = useStyles();

  return (
    <SPopover open={openMenu} classes={{root: classes.root}}>
      <Menu
        handleClose={handleClose}
        isDialog
        user={user}
        tenant={tenant}
        supplierId={supplierId}
        connectedSuppliers={connectedSuppliers}
      />
    </SPopover>
  );
};

// TODO: Manish
// const IsFinanceServiceEnable = false;

export const Menu = (props: IMenuProps) => {
  const {handleClose, isDialog, user, tenant, connectedSuppliers} = props;
  const history = useHistory();
  const {getUserRoleID} = useTenantInfo();
  const {
    virtualCreditFacility88813,
    eCommerceAndTransactionsView89915,
    payfacMerchantPortal88470,
    payfacStatementing83495,
  } = useFeatureFlags().tenantOwned();
  const {tenantInfo, modules} = useContext(AppContext);
  const {isCapricornEnvironment} = useEnvironment();

  const supplierIcon = tenant?.Logos?.find(sI => sI.LogoTypeID.toString() === LogoTypeEnum.Logo);
  const {T2TPhase280729, loansP280050} = useFeatureFlags().tenantOwned();

  const filteredConnectedSuppliers =
    connectedSuppliers
      ?.filter((s: IConnectedSupplier) => !s.IsPaymentProvider && !s.IsLendingProvider)
      .sort((a, b) => b.SupplierID - a.SupplierID) || [];

  const financeSupplier = connectedSuppliers?.filter((s: IConnectedSupplier) => s.IsLendingProvider) || [];

  const [userAnchorEl, setUserAnchorEl] = useState<null | HTMLElement>(null);
  const [settingsAnchorEl, setSettingsAnchorEl] = useState<null | HTMLElement>(null);
  const [notificationsAnchorEl, setNotificationsAnchorEl] = useState<null | HTMLElement>(null);

  const [selectedCategory, setSelectedCategory] = useState<string>('Buying');
  const [categoriesModules, setCategoriesModules] = useState<IFilteredMenuModules>({});
  const [subCategoriesModules, setSubCategoriesModules] = useState<IFilteredSubCategories>({});
  const [isPrimaryUser, setIsPrimaryUser] = useState<boolean>(false);

  const visibleCategory = (category: string) => {
    return xlmatches || lgmatches || selectedCategory === category;
  };
  const theme = muiUseTheme();
  const xlmatches = useMediaQuery(theme.breakpoints.up(1279));
  const lgmatches = useMediaQuery(theme.breakpoints.between(769, 1279));
  const mdmatches = useMediaQuery(theme.breakpoints.between(576, 769));
  const smmatches = useMediaQuery(theme.breakpoints.down(576));

  // States for Product Tour on Homescreen
  const [tourCurrentStep, setTourCurrentStep] = useState<number>(0);
  const [isShowTourStatus, setIsShowTourStatus] = useState<boolean>(false);
  // play and pause Product Tour states
  const [startTour, setStartTour] = useState(false);
  const {search: searchLocation} = useLocation();
  const location = new URLSearchParams(searchLocation);
  const [step, setStep] = useState<CustomTourStep[]>([
    {
      content: <p>Track, organise and manage inventory and pricing with greater ease.</p>,
      id: 0,
      target: '.inventory',
      disableBeacon: true,
      placement: 'top',
    },
    {
      content: (
        <p>Manage inventory levels, easily create purchase orders and make secure payments to your suppliers.</p>
      ),
      id: 1,
      disableBeacon: true,
      target: '.buying',
      placement: 'top',
    },
    {
      content: (
        <p>
          Manage quotes, orders, deliveries, and invoices across all your sales channels, and securely accept and
          monitor payments.
        </p>
      ),
      id: 2,
      target: '.selling',
      disableBeacon: true,
      placement: 'top',
    },
    {
      content: (
        <p>
          Make and accept payments quickly and securely. Access more flexible payment options and strengthen cash flow.
        </p>
      ),
      id: 3,
      disableBeacon: true,
      target: '.paymentservices',
      placement: 'top',
    },
  ]);

  useEffect(() => {
    let cancel = false;

    const getUserRolePermissions = async () => {
      const userRoleID = await getUserRoleID();
      if (cancel) return;
      if (userRoleID === UserRoleEnum.Primary) {
        setIsPrimaryUser(true);
      }
    };

    getUserRolePermissions();

    return () => {
      cancel = true;
    };
  }, []);

  useEffect(() => {
    const categories: IFilteredMenuModules = _.groupBy(modules.length ? modules : SkeletonMenu, 'Category');
    const subCategories: IFilteredSubCategories = {};

    Object.keys(categories).map(category => {
      const sortedModules = _.orderBy(categories[category], ['OrderSequence']);
      const modulesWithSubCategories = sortedModules.filter(data => data.SubCategory);
      const modulesWithoutSubCategories = sortedModules.filter(data => !data.SubCategory);
      subCategories[category] = _.groupBy(modulesWithSubCategories, 'SubCategory');
      categories[category] = modulesWithoutSubCategories;
    });

    setCategoriesModules(categories);
    setSubCategoriesModules(subCategories);
  }, [modules]);

  useEffect(() => {
    if (location.get('exploreProducts') === 'true') {
      setStartTour(true);
    }
  }, [location.get('exploreProducts')]);

  const backdropFilterCSS = css({
    '@supports ((backdrop-filter: blur(0px))) or (-webkit-backdrop-filter: blur(0px))': {
      backdropFilter: 'blur(0px)',
      '& -webkit-backdrop-filter': 'blur(0px)',
    },
  });

  const isAirplusVirtualCardEnable = tenantInfo?.TenantUserDetails?.IsAirplusVirtualCardEnable;
  const isPayfacMerchant = tenantInfo?.TenantUserDetails?.IsPayfacMerchant;

  const isLendingProvider = useMemo(() => tenantInfo?.TenantUserDetails?.IsLendingProvider, [tenantInfo]);

  const activeTradeAccountModule = useMemo(() => {
    const m = modules?.find(m => m.ModuleID === ModuleTypes.TradeAccount && m.IsActive);
    if (m) {
      m.Description = 'Transactions';
    }

    return m;
  }, [modules]);

  const getSubCategoryModules = (category: string) => {
    const categoryModules = subCategoriesModules?.[category];

    if (categoryModules) {
      return Object?.keys(categoryModules)?.map(subCategory => {
        const filteredModules = categoryModules[subCategory]?.filter(m => {
          if (
            m?.ModuleID === ModuleTypes.VirtualCreditFacility &&
            (isAirplusVirtualCardEnable || !virtualCreditFacility88813)
          ) {
            return false;
          }
          if (m?.ModuleID === ModuleTypes.TradeAccount && (!isLendingProvider || !eCommerceAndTransactionsView89915)) {
            return false;
          }
          if (m.ModuleID === ModuleTypes.Statements && (!isPayfacMerchant || !payfacStatementing83495)) {
            return false;
          }
          if (m.ModuleID === ModuleTypes.MerchantPortal && (!isPayfacMerchant || !payfacMerchantPortal88470)) {
            return false;
          }
          return true;
        });

        return (
          <ModuleContainer
            key={subCategory}
            heading={subCategory}
            subHeading={true}
            handleClose={handleClose}
            modules={filteredModules}
            menuModules={modules}
            isPrimaryUser={isPrimaryUser}
            financeSupplier={financeSupplier}
            loansP280050={loansP280050}
            category={category}
          />
        );
      });
    }
  };

  const handleTourStep = (prop: any) => {
    if (prop.action === 'next' && prop.lifecycle === 'complete') {
      setTourCurrentStep(prev => prev + 1);
    } else if (prop.action === 'skip') {
      setStartTour(false);
      setIsShowTourStatus(true);
    }
    if (prop.action === 'reset') {
      setTourCurrentStep(prev => prev + 1);
      history.push(AUTH_MENU);
    }
  };

  const handlePlayAgain = () => {
    setStartTour(true);
    setStep(prev => prev.filter(step => tourCurrentStep <= step.id));
  };

  const isFinanceServiceHeadingAvailable =
    subCategoriesModules?.[ModuleCategoriesEnums.Buying] &&
    Object.keys(subCategoriesModules?.[ModuleCategoriesEnums.Buying])?.some(s => s === 'Finance Service');
  const isShowFinanceModule = !isFinanceServiceHeadingAvailable && (financeSupplier?.length || 0) > 0;

  return (
    <>
      {!isDialog && (
        <div>
          <img alt={'background'} src={MockARBackground} className="absolute bottom-0 left-0 right-0 h-full w-full" />
        </div>
      )}
      <HeaderDialogs
        userAnchorEl={userAnchorEl}
        settingsAnchorEl={settingsAnchorEl}
        notificationsAnchorEl={notificationsAnchorEl}
        setUserAnchorEl={setUserAnchorEl}
        setSettingsAnchorEl={setSettingsAnchorEl}
        setNotificationsAnchorEl={setNotificationsAnchorEl}
      />
      <div className={`z-10 h-full overflow-y-auto ${backdropFilterCSS}`}>
        {location.get('exploreProducts') && (
          <>
            <div
              className="absolute right-4 top-[70px] z-50 cursor-pointer rounded bg-spenda-bckgrndBlue p-[9px]"
              onClick={() => setIsShowTourStatus(!isShowTourStatus)}
            >
              <TourStatusIcon />
              {isShowTourStatus && (
                <ModuleStep
                  currentStep={tourCurrentStep}
                  handleTourSkip={setStartTour}
                  handlePlayAgain={handlePlayAgain}
                />
              )}
            </div>
          </>
        )}
        <Header
          supplierIcon={supplierIcon}
          handleClose={handleClose}
          user={user}
          tenant={tenant}
          setUserAnchorEl={setUserAnchorEl}
          setSettingsAnchorEl={setSettingsAnchorEl}
          setNotificationsAnchorEl={setNotificationsAnchorEl}
          isDialog={isDialog}
        />

        {isCapricornEnvironment &&
          (isLendingProvider && activeTradeAccountModule ? (
            <ModuleWrapper flex={xlmatches ? 2 : 1}>
              <ModuleContainer
                heading="Funding Services"
                modules={[activeTradeAccountModule]}
                handleClose={handleClose}
                menuModules={modules}
                isPrimaryUser={isPrimaryUser}
                center
              />
            </ModuleWrapper>
          ) : (
            tenant.IsCapricornPreferredSupplier && (
              <ModuleWrapper flex={xlmatches ? 2 : 1}>
                <ModuleContainer
                  heading="Capricorn eCommerce Transaction management"
                  modules={categoriesModules[ModuleCategoriesEnums.Selling]?.filter(cm =>
                    [ModuleTypes.SpendaCollectAR, ModuleTypes.SalesOrderManagement].includes(cm.ModuleID!),
                  )}
                  handleClose={handleClose}
                  menuModules={modules}
                  isPrimaryUser={isPrimaryUser}
                  center
                />
              </ModuleWrapper>
            )
          ))}

        {!isCapricornEnvironment && (
          <>
            {(mdmatches || smmatches) && (
              <ModuleCategories
                setSelectedCategory={setSelectedCategory}
                selectedCategory={selectedCategory}
                mdmatches={mdmatches}
              />
            )}
            <div className="flex">
              {visibleCategory(ModuleCategoriesEnums.Inventory) && (
                <ModuleWrapper flex={xlmatches ? 1 : 1} border>
                  <ModuleContainer
                    heading={ModuleCategoriesEnums.Inventory}
                    modules={categoriesModules[ModuleCategoriesEnums.Inventory]}
                    handleClose={handleClose}
                    menuModules={modules}
                    isPrimaryUser={isPrimaryUser}
                  />
                  {getSubCategoryModules(ModuleCategoriesEnums.Inventory)}
                </ModuleWrapper>
              )}
              {visibleCategory(ModuleCategoriesEnums.Buying) && (
                <ModuleWrapper flex={xlmatches ? 2 : 1} border>
                  <ModuleContainer
                    heading={ModuleCategoriesEnums.Buying}
                    modules={categoriesModules[ModuleCategoriesEnums.Buying]}
                    handleClose={handleClose}
                    menuModules={modules}
                    isPrimaryUser={isPrimaryUser}
                  />
                  <>
                    {getSubCategoryModules(ModuleCategoriesEnums.Buying)}

                    {isShowFinanceModule && (
                      <ConnectedSuppliersContainer title="Finance Service">
                        {isShowFinanceModule && (
                          <ConnectedSuppliersCardList
                            connectedSuppliers={financeSupplier}
                            isLoanManagementActive={loansP280050}
                          />
                        )}
                      </ConnectedSuppliersContainer>
                    )}

                    {(filteredConnectedSuppliers?.length || 0) > 0 && (
                      <ConnectedSuppliersContainer>
                        <ConnectedSuppliersCardList
                          connectedSuppliers={filteredConnectedSuppliers}
                          isT2TPhaseTwo={T2TPhase280729}
                        />
                      </ConnectedSuppliersContainer>
                    )}
                  </>
                </ModuleWrapper>
              )}
              {visibleCategory(ModuleCategoriesEnums.Selling) && (
                <ModuleWrapper flex={xlmatches ? 2 : 1}>
                  <ModuleContainer
                    heading={ModuleCategoriesEnums.Selling}
                    modules={categoriesModules[ModuleCategoriesEnums.Selling]}
                    handleClose={handleClose}
                    menuModules={modules}
                    isPrimaryUser={isPrimaryUser}
                  />
                  {getSubCategoryModules(ModuleCategoriesEnums.Selling)}
                </ModuleWrapper>
              )}
            </div>
          </>
        )}
      </div>
      {startTour && (
        <>
          <Joyride
            tooltipComponent={CustomTooltipJoyride}
            steps={step}
            run={startTour}
            styles={{
              spotlight: {
                borderRadius: '16px',
              },
            }}
            callback={handleTourStep}
            spotlightClicks={false}
            continuous
            scrollToFirstStep
            showSkipButton
            hideBackButton
            hideCloseButton
            spotlightPadding={5}
          />
        </>
      )}
    </>
  );
};

const CustomTooltipJoyride = (props: TooltipRenderProps) => {
  const {skipProps, primaryProps, closeProps, isLastStep} = props;
  return (
    <div className="w-full max-w-[380px] rounded-md border-b-4 border-b-[#1EA0E4] bg-white p-4">
      <Typography variant="h3" className="text-sm">
        {props.step.content}
      </Typography>
      {!isLastStep ? (
        <div className="mt-4 flex w-full items-center justify-between">
          <Button variant="outlined" {...skipProps}>
            Skip
          </Button>
          <Button {...primaryProps}>Next</Button>
        </div>
      ) : (
        <div className="mt-4 flex w-full items-center justify-end">
          <Button {...closeProps}>Last</Button>
        </div>
      )}
    </div>
  );
};

const ConnectedSuppliersCardList = (props: {
  connectedSuppliers: IConnectedSupplier[];
  isT2TPhaseTwo?: boolean;
  isLoanManagementActive?: boolean;
}) => {
  const {connectedSuppliers, isT2TPhaseTwo, isLoanManagementActive} = props;

  const onMenuItemClick = (s: IConnectedSupplier): string => {
    if (isLoanManagementActive && s?.IsLendingProvider) {
      return `/finance/${s.SupplierID}/statements`;
    } else {
      return s.Status === MarketplaceConnectionStatus.Connected
        ? isT2TPhaseTwo
          ? `${s.MarketplacePath}?redirectedFromAPBills=true`
          : s.MarketplacePath
        : '#';
    }
  };

  return (
    <>
      {connectedSuppliers.map(s => (
        <Link key={s.SupplierID} to={onMenuItemClick(s)} data-autoid={`lnk-${s?.MarketplacePath?.toLowerCase()}`}>
          <MenuItemCard
            description={s.IsLendingProvider ? 'Finance' : s.SupplierName}
            svgIconSrc={s.IsLendingProvider ? SVGName.TradeFinance : ''}
            urlLogoSrc={s.Logos.find(l => l.LogoTypeID == LogoTypeEnum.Icon)?.URI || ''}
            omitDataAutoId={true}
          />
        </Link>
      ))}
    </>
  );
};

export interface IModuleContainerProps {
  heading: string;
  subHeading?: boolean;
  modules: IMenuModule[];
  handleClose?: () => void;
  menuModules: IMenuModule[];
  isPrimaryUser?: boolean;
  financeSupplier?: IConnectedSupplier[];
  loansP280050?: boolean;
  category?: string;
  center?: boolean;
}

const ModuleContainer = (props: IModuleContainerProps) => {
  const {
    heading,
    modules,
    subHeading,
    handleClose,
    menuModules,
    isPrimaryUser,
    financeSupplier,
    loansP280050,
    category,
    center = false,
  } = props;

  const header = css({
    marginBottom: '2rem !important',
    marginTop: subHeading ? '10px' : '0px',
  });

  const {isFlagEnabled} = useFeatureFlags();

  const classNameOfModuleContainer = useMemo(() => heading.split(' ').join('').toLowerCase(), [heading]);
  const isShowFinanceServiceModule =
    heading === 'Finance Service' &&
    Boolean(subHeading) &&
    category === ModuleCategoriesEnums.Buying &&
    !!financeSupplier?.length;

  if (!modules?.length && !isShowFinanceServiceModule) return;

  if (
    modules?.every(m => m?.FeatureFlags && !m?.FeatureFlags?.every(flag => isFlagEnabled(flag))) &&
    !isShowFinanceServiceModule
  ) {
    return null;
  }

  return (
    <div className={classNameOfModuleContainer}>
      <Typography variant="h2" className={`text-center text-[#121212] ${header} ${subHeading ? '' : 'mdMax:hidden'}`}>
        {heading}
      </Typography>
      <div
        className={`lgMax:flex-col lgMax:items-center mdMax:flex-row smMax:flex-col flex flex-wrap ${center ? 'justify-center' : 'justify-around'} gap-x-4`}
      >
        {isShowFinanceServiceModule && !!financeSupplier?.length && (
          <ConnectedSuppliersCardList connectedSuppliers={financeSupplier} isLoanManagementActive={loansP280050} />
        )}
        {Array.isArray(modules) &&
          modules.map(module => (
            <Module
              key={module.Name}
              module={module}
              handleCloseMenuDialog={handleClose}
              menuModules={menuModules}
              isPrimaryUser={isPrimaryUser}
            />
          ))}
      </div>
    </div>
  );
};

const ConnectedSuppliersContainer = (props: PropsWithChildren<{title?: string}>) => {
  return (
    <div>
      <Typography variant="h2" className={`mdMax:hidden mb-8 mt-2.5 text-center text-black-800`}>
        {props.title || `Connected Suppliers`}
      </Typography>
      <div className="lgMax:flex-col lgMax:items-center mdMax:flex-row smMax:flex-col flex flex-wrap justify-around gap-x-4">
        {props.children}
      </div>
    </div>
  );
};

interface IModuleWrapperProps extends PropsWithChildren {
  flex: number;
  border?: boolean;
}

const ModuleWrapper: React.FC<IModuleWrapperProps> = props => {
  const {children, flex} = props;

  const container = css({
    flex,
    padding: '0px 30px 0px 30px',
    '@media(max-width: 768px)': {
      maxWidth: '37.5rem',
      width: '100%',
      margin: '0 auto',
    },
  });
  const wrapperBorder = css({
    borderRight: '.125rem solid #D3D3D3',
    borderSpacing: '15px',
    '@media(max-width: 768px)': {
      borderRight: '0',
    },
  });
  return <div className={`${container} ${wrapperBorder}`}>{children}</div>;
};

interface IModuleProps {
  module: IMenuModule;
  handleCloseMenuDialog?: () => void;
  menuModules: IMenuModule[];
  isPrimaryUser?: boolean;
}

const Module = (props: IModuleProps) => {
  const {module, menuModules} = props;
  const history = useHistory();
  const {content} = useTheme();
  const zohoChatContext = useContext(ZohoContext)!;
  const [zohoChat] = useState(zohoChatContext);
  const {loadQuestion, resetChatWindow, loadUser, showChatWindow} = zohoChat || {};
  const {user, setModules, isQuotesSettingsSaved, loadUserSession, setUser} = useContext(AppContext);
  const {isFlagEnabled, tenantOwned} = useFeatureFlags();
  const {purchaseRequisitionsV1, onboardingUpgradeV1, QuoteV289367} = tenantOwned();
  const {getAccounts} = useSpendaPaymentServicesAPI();
  const {updateModule} = useTenantAPI();
  const {GetModules} = useHomePageAPI();
  const {isAPFinancialAdaptorSetup, adaptors} = useContext(IntegrationContext);

  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [showPaymentOnboarding, setShowPaymentOnboarding] = useState<boolean>(false);
  const [showStatementsDialog, setShowStatementsDialog] = useState<boolean>(false);
  const [isFinancialAdaptorSetup, setIsFinancialAdaptorSetup] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(
    module.ModuleID === ModuleTypes.SpendaPay && !adaptors.length ? true : false,
  );
  const [isLoadingModule, setIsLoadingModule] = useState<boolean>(false);

  useEffect(() => {
    if (adaptors.length) {
      if (!isFinancialAdaptorSetup) {
        const result = isAPFinancialAdaptorSetup();

        setIsFinancialAdaptorSetup(result);
      }
      setIsLoading(false);
    }
  }, [adaptors, isAPFinancialAdaptorSetup]);

  const handleClose = () => {
    setShowDialog(false);
  };

  const handleOpen = () => {
    setShowDialog(true);
  };

  const moduleInfoStyle = useMemo(
    () =>
      getModuleStyle(
        module.IsExperimental,
        module.IsIpadOnly,
        module.Status,
        module.Labeldescription,
        module.FeatureFlags,
      ),
    [module],
  );

  const showApplication = () => {
    if (module.Route) {
      if (props.handleCloseMenuDialog) {
        props.handleCloseMenuDialog();
      }
      if (module.ModuleID === ModuleTypes.SpendaPay && !isFinancialAdaptorSetup) {
        history.push(AUTH_BUYING_AP_BATCH);
      } else if (module.ModuleID === ModuleTypes.SpendaPay && isFinancialAdaptorSetup) {
        history.push(AUTH_PURCHASING_PAY_BILL_ROUTE);
      } else if (module.ModuleID === ModuleTypes.SpendaBuy && !purchaseRequisitionsV1) {
        history.push(AUTH_PURCHASING_PURCHASE_INVOICE_LIST);
      } else if (module.ModuleID === ModuleTypes.Quotes && QuoteV289367 && !isQuotesSettingsSaved) {
        history.push(AUTH_SELLING_QUOTES_SETTINGS);
      } else if (module.ModuleID === ModuleTypes.SpendaCollectAR) {
        const arPath = content.accountsReceivable?.menu?.accountsReceivable
          ? AUTH_SELLING_AR_PAID_INVOICES_ROUTE
          : module.Route;
        history.push(arPath);
      } else {
        history.push(module.Route);
      }
    }
  };

  const handleAccountsPayableModuleInfoClick = async () => {
    try {
      history.push(AUTH_ACCOUNTSPAYABLE_MODULEINFO_ROUTE);
    } catch (error) {
      console.error('error', error);
    }
  };

  const handlePaymentModuleClick = async () => {
    try {
      const accounts = await getAccounts(PaymentProviderName.Spenda_Payment_Services, {
        accountUsage: AccountUsage.SUPPLIER,
      });
      const supplierBankAcc = accounts?.find(account => account.usage === AccountUsage.SUPPLIER);
      if (!supplierBankAcc) {
        handleOpen();
      } else {
        setShowPaymentOnboarding(true);
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  const handleStatementsModuleClick = () => {
    setShowStatementsDialog(true);
  };

  const handleModuleOnboardingClick = (moduleId: number) => {
    setIsLoadingModule(true);
    switch (moduleId) {
      case ModuleTypes.SalesOrderManagement:
        activateModule(ModuleTypes.SalesOrderManagement, true);
        break;
      case ModuleTypes.Quotes:
        activateModule(ModuleTypes.Quotes, true);
        break;
      case ModuleTypes.MerchantPortal:
        activateModule(ModuleTypes.MerchantPortal, true);
        break;
      case ModuleTypes.TradeAccount:
        activateModule(ModuleTypes.TradeAccount, true);
        break;
      case ModuleTypes.Service:
        activateModule(ModuleTypes.Service, true);
        break;
      case ModuleTypes.Payments:
        handleClose();
        setShowPaymentOnboarding(true);
        break;
      default:
        break;
    }
  };

  const activateModule = async (moduleId: number, isModuleSetup: boolean) => {
    try {
      const updateModuleRes = await updateModule({ParentModuleID: moduleId, IsModuleSetup: isModuleSetup});
      const menuModules = await GetModules();
      if (updateModuleRes && menuModules?.modules) {
        setModules(menuModules?.modules);
        if (user) {
          const currentUser = {...user};
          const userModules = currentUser.Modules + ',' + ModuleTypes[moduleId].toString();
          currentUser.Modules = userModules;
          currentUser && setUser(currentUser);
          await loadUserSession(currentUser);
        }
        if (module?.Route) {
          if (QuoteV289367 && moduleId === ModuleTypes.Quotes) {
            history.push(AUTH_SELLING_QUOTES_SETTINGS);
          } else history.push(module.Route);
        }
      }
      setIsLoadingModule(false);
      handleClose();
    } catch {}
  };

  const handleModuleClick = () => {
    if (module.IsPublished) {
      if (module.IsIpadOnly) {
        // Check if the device is iPad and links for app is available
        if (
          module?.Actions?.DEEP_LINKING &&
          module?.Actions?.APP_STORE &&
          navigator.userAgent.match(/Mac/) &&
          navigator.maxTouchPoints &&
          navigator.maxTouchPoints > 2
        ) {
          window.location.replace(module?.Actions?.APP_STORE!);
        } else {
          handleOpen();
        }
      } else if (module.ModuleID === ModuleTypes.Payments) {
        handlePaymentModuleClick();
      } else if (module.ModuleID === ModuleTypes.Statements) {
        handleStatementsModuleClick();
      } else if (
        module.ModuleID === ModuleTypes.SpendaPay &&
        onboardingUpgradeV1 &&
        (module.Status != ModuleStatus.SubscriptionActive || !module.IsModuleSetup)
      ) {
        handleAccountsPayableModuleInfoClick();
      } else if (
        (module.Status === ModuleStatus.SubscriptionActive || module.Status === ModuleStatus.TrialActive) &&
        module.IsModuleSetup &&
        module.IsActive
      ) {
        showApplication();
      } else if (module.Status === ModuleStatus.TrialExpired || module.Status === ModuleStatus.SubscriptionCancelled) {
        resetChatWindow();
        setTimeout(() => {
          loadUser(user!);
          loadQuestion(`Hi Support, I'd like to renew my subscription for module ${module.Description}.`);
          showChatWindow();
        }, 1000);
      } else if (module.IsExperimental && !module.Status) {
        if (module.ModuleID === ModuleTypes.SalesOrderManagement) {
          handleOpen();
        } else {
          resetChatWindow();
          setTimeout(() => {
            loadUser(user!);
            loadQuestion(`Hi Support, I'd like to try out the experimental module ${module.Description}.`);
            showChatWindow();
          }, 1000);
        }
      } else if (module.Status === ModuleStatus.SetupRequired && module?.Actions?.ONBOARDING) {
        history.push(module.Actions.ONBOARDING);
      } else if (module.Status !== ModuleStatus.ComingSoon) {
        handleOpen();
      }
    }
  };

  const isModuleAvlForUse = () => {
    let isAvailable = false;
    if (!module.IsPublished) {
      return isAvailable;
    } else if (module.IsParentModule) {
      return true;
    } else if (!module.IsParentModule && module?.ParentModuleID) {
      module.ParentModuleID.split(',').find(parentModuleId => {
        const moduleFound = menuModules.find(menuModule => menuModule?.ModuleID?.toString() === parentModuleId);
        if (moduleFound) {
          if (moduleFound?.IsActive) {
            isAvailable = true;
            return true;
          }
        }
      });
    }
    return isAvailable;
  };

  // TODO
  if (module?.FeatureFlags && !module?.FeatureFlags?.every(flag => isFlagEnabled(flag))) {
    return null;
  }

  return (
    <>
      {showDialog && (
        <ModuleInfo
          isLoadingModule={isLoadingModule}
          moduleOnboardingBtnClick={handleModuleOnboardingClick}
          handleClose={handleClose}
          module={module}
          isPrimaryUser={props.isPrimaryUser || false}
        />
      )}
      {showPaymentOnboarding && <ConnectAccount handleClose={setShowPaymentOnboarding} />}
      {showStatementsDialog && (
        <StatementsDialog isOpen={showStatementsDialog} onClose={() => setShowStatementsDialog(false)} />
      )}
      <MenuItemCard
        onClick={isModuleAvlForUse() ? handleModuleClick : () => {}}
        disabled={!isModuleAvlForUse()}
        svgIconSrc={module.Src}
        description={module.Description}
        label={moduleInfoStyle?.label}
        color={moduleInfoStyle?.color}
        bgColor={moduleInfoStyle?.bgColor}
        isLoading={isLoading}
      />
    </>
  );
};
interface IMenuItemCard {
  onClick?: React.MouseEventHandler<HTMLElement>;
  svgIconSrc?: string;
  urlLogoSrc?: string;
  description?: string;
  label?: string;
  color?: color;
  bgColor?: string;
  omitDataAutoId?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
}

const MenuItemCard = (props: IMenuItemCard) => {
  const container = css({
    width: '14.43rem',
    height: '4.68rem',
    borderRadius: '.93rem',
    marginBottom: '1.625rem',
    boxShadow: 'rgba(211, 229, 239, 0.5) 0px 3px 0px 2px',
  });

  const {onClick, svgIconSrc, description, label, urlLogoSrc, bgColor, color, isLoading, disabled} = props;

  const dataAutoId = props.omitDataAutoId ? {} : createAutoIdAttributeFromName(props.description, 'lnk');

  return (
    <button
      type="button"
      className={`${container} relative flex cursor-pointer items-center justify-center bg-white p-2`}
      style={disabled ? {cursor: 'auto', opacity: 0.5} : {}}
      onClick={event => (isLoading ? null : onClick && onClick(event))}
      {...dataAutoId}
      disabled={isLoading || disabled}
    >
      <div className={`flex h-full w-[3.75rem] items-center justify-center border-r-[3px] border-r-[#CCCCCC] pr-2`}>
        {svgIconSrc && <SVGIcon name={svgIconSrc} />}
        {!svgIconSrc && urlLogoSrc && (
          <img className="rounded-default h-full w-full" style={{objectFit: 'cover'}} src={urlLogoSrc} alt=""></img>
        )}
        {!svgIconSrc && !urlLogoSrc && (
          <Typography className="relative h-10 w-10 rounded-full bg-gray-200 p-2 text-center">
            {NameInitials(description)}
          </Typography>
        )}
      </div>
      <div className="flex-1 pl-4 text-left">
        {isLoading ? (
          <Skeleton />
        ) : (
          <Typography variant="h3" className={`line-clamp-2 w-[9rem]  break-words leading-normal text-black-800`}>
            {description ? description : ''}
          </Typography>
        )}
      </div>
      {label && (
        <div className={`absolute -top-[0.81rem]`}>
          <span
            style={{backgroundColor: bgColor}}
            className={`relative rounded-md border px-3 py-1 font-poppins text-sm font-normal text-${color} border-${color}`}
          >
            {label}
          </span>
        </div>
      )}
    </button>
  );
};

interface IModuleCategoriesProps {
  mdmatches: boolean;
  selectedCategory: string;
  setSelectedCategory: Dispatch<SetStateAction<string>>;
}

const ModuleCategories = (props: IModuleCategoriesProps) => {
  const {mdmatches, selectedCategory, setSelectedCategory} = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const TabsContainer = css({
    border: '1px solid #DFDFDF',
    padding: '0.18rem',
    margin: '0 auto 2rem',
    maxWidth: '37.5rem',
    '& p': {
      padding: '.5rem 0',
      lineHeight: '1.125rem',
      cursor: 'pointer',
      fontFamily: 'Poppins, sans-serif !important',
      flex: 1,
      textAlign: 'center',
    },
    '& p.active': {
      backgroundColor: '#1C78AD',
      color: '#fff',
      borderRadius: '.313rem',
    },
  });

  const dropDownContainer = css({
    maxWidth: '12.5rem',
    margin: '0 auto 2rem',
    '& svg': {
      fontSize: '2rem',
    },
  });

  const setCategory = (type: string) => {
    setSelectedCategory(type);
    setAnchorEl(null);
  };

  return (
    <>
      <SPopover
        id="categorySelectionPopover"
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div className={`my-auto w-40 p-4`}>
          <Typography className={`mx-auto cursor-pointer pb-1 text-lg`} onClick={() => setCategory('Inventory')}>
            Inventory
          </Typography>
          <Typography className={`mx-auto cursor-pointer pb-1 text-lg`} onClick={() => setCategory('Buying')}>
            Buying
          </Typography>
          <Typography className={`mx-auto cursor-pointer pb-1 text-lg`} onClick={() => setCategory('Selling')}>
            Selling
          </Typography>
        </div>
      </SPopover>
      {mdmatches ? (
        <div className={`${TabsContainer} flex w-full justify-around rounded-md bg-gray-200 text-black-800`}>
          <Typography
            className={selectedCategory === 'Inventory' ? 'active' : ''}
            onClick={() => setSelectedCategory('Inventory')}
          >
            Inventory
          </Typography>
          <Typography
            className={selectedCategory === 'Buying' ? 'active' : ''}
            onClick={() => setSelectedCategory('Buying')}
          >
            Buying
          </Typography>
          <Typography
            className={selectedCategory === 'Selling' ? 'active' : ''}
            onClick={() => setSelectedCategory('Selling')}
          >
            Selling
          </Typography>
        </div>
      ) : (
        <div
          className={`${dropDownContainer} w-full text-center font-poppins text-xl text-primary`}
          onClick={(event: React.MouseEvent<HTMLDivElement>) => setAnchorEl(event.currentTarget)}
        >
          {selectedCategory} <ArrowDropDownIcon />
        </div>
      )}
    </>
  );
};

const getModuleStyle = (
  isExperimental: boolean | undefined,
  isIpadOnly: boolean | undefined,
  Status: string | undefined,
  LabelDescription: string | undefined,
  FeatureFlags: FeatureFlagKeys[] | undefined,
): {color: color; label?: string; bgColor: string} => {
  const bgColors = {
    success: '#D6E7CF',
    error: '#EEDAD5',
    warning: '#EFE3CC',
    'cool-purple': '#E1DFF0',
  };

  if ((isExperimental && !Status) || (isExperimental && FeatureFlags)) {
    return {
      color: 'cool-purple',
      bgColor: bgColors['cool-purple'],
      label: 'Experimental',
    };
  }
  switch (Status) {
    case ModuleStatus.TrialAvailable:
      return {
        color: 'success',
        bgColor: bgColors.success,
        label: LabelDescription || 'Try Our App Now',
      };
    case ModuleStatus.TrialActive:
      return {
        color: 'error',
        bgColor: bgColors.error,
        label: LabelDescription || '29 days left',
      };
    case ModuleStatus.TrialExpired:
      return {
        color: 'error',
        bgColor: bgColors.error,
        label: 'Renew Subscription',
      };
    case ModuleStatus.SubscriptionCancelled:
      return {
        color: 'error',
        bgColor: bgColors.error,
        label: 'Renew Subscription',
      };
    case ModuleStatus.ComingSoon:
      return {
        color: LabelDescription === 'Launch App' ? 'success' : 'warning',
        bgColor: bgColors[LabelDescription === 'Launch App' ? 'success' : 'warning'],
        label: LabelDescription || 'Coming Soon',
      };
    default:
      return {
        color: LabelDescription === 'Coming Soon' ? 'warning' : 'success',
        bgColor: bgColors[LabelDescription === 'Coming Soon' ? 'warning' : 'success'],
        label: LabelDescription,
      };
  }
};

const ModuleStep = (prop: any) => {
  const step = [
    {
      id: 0,
      title: 'Inventory',
    },
    {
      id: 1,
      title: 'Buying',
    },
    {
      id: 2,
      title: 'Selling',
    },
    {
      id: 3,
      title: 'Payment services',
    },
  ];

  return (
    <div className="absolute right-0 top-11 z-[9999] flex w-64 flex-col rounded-lg shadow-md">
      <header className="flex items-center rounded-t-md bg-[#D3E5EF] p-2.5">
        <Typography className="font-medium">Modules</Typography>
      </header>
      <div className="flex flex-col items-center rounded-b-md bg-white">
        {step.map((item, index) => (
          <div
            onClick={() => {
              if (index === prop.currentStep) {
                prop.handlePlayAgain();
              }
            }}
            key={index}
            className={`${
              index === prop.currentStep ? 'bg-[rgba(211,229,239,.3)]' : 'bg-white'
            } flex w-full items-center justify-between p-2.5 font-poppins`}
          >
            <p className="text-sm">
              {index + 1}. {item.title}{' '}
            </p>
            {prop?.currentStep > index ? (
              <span>
                <img src={StepSucessIcon} />
              </span>
            ) : index === prop.currentStep ? (
              <span>
                <TourPauseIcon />
              </span>
            ) : (
              <span>
                <TourPlayIcon />
              </span>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
