import React, {useState, useEffect} from 'react';
import clsx from 'clsx';
import {IAddress} from '../../model/address/Address';
import {AddressDialog} from './AddressDialog';
import LoadingIndicator from '../ui/LoadingIndicator';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import {ILocation} from '../../model/address/Location';
import {Button, Dialog, DialogBody, DialogFooter, DialogHeader, Typography} from 'spenda-ui-react';
import CrossIcon from '../../assets/svg/Cross';

export const SelectShippingAddressDialog = (
  props: any & {addressType: string; onClose?: () => void; open?: boolean},
) => {
  const closeDialog = () => {
    if (props.onClose) {
      props.onClose();
    }
  };
  const {customerDetails, values} = props;
  const addressType = props.addressType;
  const [locationEditDialog, setLocationEditDialog] = useState<boolean>(false);
  const [addressNumber, setAddressNumber] = useState(0);
  const [locationNumber, setLocationNumber] = useState(0);
  const [locations, setLocations] = useState<ILocation[]>();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [activeBox, setActiveBox] = useState<any>({
    locNumber: '',
    addNumber: '',
  });
  const [showLoader, setShowLoader] = useState<boolean>(false);

  const handleClose = () => {};

  const handleDialog = (locIndex: number, index: number) => {
    if (locationEditDialog) {
      handleClose();
    }
    setLocationNumber(locIndex);
    setAddressNumber(index);
    setIsEdit(true);
    setLocationEditDialog(!locationEditDialog);
  };

  const reloadSaveLocations = () => {
    const loc = customerDetails?.Locations && JSON.parse(JSON.stringify(customerDetails?.Locations));
    setLocations(loc);
  };

  useEffect(() => {
    reloadSaveLocations();
  }, [customerDetails?.Locations]);

  const setActiveBoxValues = async () => {
    if (locations && locations.length) {
      let selAddrIndex = '-1',
        selLocIndex = '-1';
      /* eslint-disable */
      await Promise.all(
        locations.map((loc, index) => {
          if (addressType === 'ShippingAddress' && values?.ShippingAddressID) {
            if (selAddrIndex === '-1') {
              selAddrIndex =
                loc?.Addresses?.findIndex(addr => addr.ID === values?.ShippingAddressID).toString() || '-1';
              selLocIndex = index.toString();
            }
          } else if (addressType === 'BillingAddress' && values?.BillingAddressID) {
            if (selAddrIndex === '-1') {
              selAddrIndex = loc?.Addresses?.findIndex(addr => addr.ID === values?.BillingAddressID).toString() || '-1';
              selLocIndex = index.toString();
            }
          }
        }),
      );
      if (selAddrIndex !== '-1' && selLocIndex !== '-1') {
        setActiveBox({locNumber: selLocIndex, addNumber: selAddrIndex});
      } else {
        await Promise.all(
          locations.map((loc, index) => {
            if (selAddrIndex !== '-1' && selLocIndex !== '-1') return;
            if (loc.IsActive) {
              selAddrIndex = loc?.Addresses?.findIndex(addr => addr.IsActive).toString() || '-1';
              selLocIndex = index.toString();
            }
          }),
        );
        if (selAddrIndex !== '-1' && selLocIndex !== '-1') {
          setActiveBox({locNumber: selLocIndex, addNumber: selAddrIndex});
        }
      }
    }
  };

  useEffect(() => {
    if (locations && locations.length) {
      setActiveBoxValues();
    }
  }, [locations]);

  const AddLocation = () => {
    const tempObj: ILocation = {
      Addresses: [
        {
          Name: '',
          Country: '',
          State: '',
          StreetAddress: '',
          City: '',
          PostCode: '',
          FullAddress: '',
          IsDefaultDeliverTo: addressType === 'ShippingAddress' ? true : false,
          IsDefaultBilling: addressType === 'BillingAddress' ? true : false,
          IsActive: true,
          addressFlag: true,
        },
      ],
      IsActive: true,
      IsDefaultLocation: false,
      tempFlag: true,
    };
    setLocations([...(locations || []), tempObj]);
  };

  const deleteAddress = async (locNumber: number, addressNumber: number, allLocations: any[]) => {
    setShowLoader(true);
    const selAddress = [...allLocations];
    selAddress[locNumber].Addresses[addressNumber].IsActive = false;
    if (selAddress[locNumber].IsDefaultLocation) {
      const index = selAddress.findIndex(addr => addr.Addresses && addr.Addresses.length && !addr.IsDefaultLocation);
      if (index !== -1) {
        selAddress[index].IsDefaultLocation = true;
        selAddress[index].IsDefaultDeliverTo = true;
        selAddress[index].IsDefaultBilling = true;
      }
    }
    setLocations(selAddress);
    setActiveBox({locNumber: '', addNumber: ''});
    await props.handleUpdateOpCustomer(selAddress);
    setShowLoader(false);
  };

  const handleAddAddress = () => {
    setLocationNumber(locations?.length || 0);
    setAddressNumber(0);
    setIsEdit(false);
    AddLocation();
    setLocationEditDialog(true);
  };
  const locationAddresses = locations?.filter(
    loc => loc.Addresses && loc.Addresses.length && loc.IsActive && loc.Name && loc.Name.trim() !== '',
  );

  const assignValue = async () => {
    if (activeBox?.locNumber !== '' && activeBox?.addNumber !== '' && locations && locations.length) {
      const selLocation = locations[parseInt(activeBox.locNumber)];
      const address =
        (selLocation && selLocation?.Addresses && selLocation?.Addresses[parseInt(activeBox.addNumber)]) || {};
      const addressId = address?.ID || '';
      if (props.isServiceModule) {
        setShowLoader(true);
        if (addressType === 'BillingAddress') {
          address.IsDefaultBilling = true;
        } else {
          address.IsDefaultDeliverTo = true;
        }
        selLocation.Addresses = selLocation.Addresses && [...selLocation.Addresses, address];
        // set rest all default shipping and billing to false
        const otherLocations = locations.filter(loc => loc.ID != selLocation.ID);
        await otherLocations.forEach(loc =>
          loc.Addresses?.forEach(addr => {
            addr.IsDefaultBilling = false;
            addr.IsDefaultDeliverTo = false;
          }),
        );
        const loc = [selLocation, ...otherLocations];
        setLocations(loc);
        await props.handleUpdateOpCustomer(loc);
        setShowLoader(false);
      } else props.handleSetAddress(addressId, address, addressType);
    }
  };

  return (
    <>
      <Dialog
        open={props.open || false}
        data-autoid="dlgSelectShipping"
        className="relative !z-[9999] !min-h-[529px] !min-w-[1000px] pb-20"
        handler={() => {}}
      >
        <DialogHeader className="w-full border-b border-[#EAEAEA] p-2.5">
          <div className="flex w-full items-center justify-between ">
            <div className={`flex w-full justify-between`}>
              <span> </span>
              <Typography variant="h1" className={`w-full text-center font-light `}>
                Select {props?.addressType == 'ShippingAddress' ? 'Shipping' : 'Billing'} Address
              </Typography>
              <div className="flex justify-end text-right ">
                <span className="ap-onboarding-close-icon" onClick={closeDialog} data-autoid="btnCloseBtn">
                  <div className="h-4 w-4">
                    <CrossIcon />
                  </div>
                </span>
              </div>
            </div>
          </div>
        </DialogHeader>
        <DialogBody className="max-h-[500px] overflow-y-auto">
          {showLoader && <LoadingIndicator isLoading={showLoader} />}
          <div className="mb-12 flex h-full w-full flex-row flex-wrap">
            {locations &&
              Array.isArray(locations) &&
              locations.length &&
              locations?.map(
                (loc: ILocation, locIndex: number) =>
                  loc?.Addresses &&
                  loc?.Addresses?.map(
                    (data: IAddress, index: number) =>
                      data &&
                      data?.IsActive &&
                      (data?.PostCode || data?.Country) && (
                        <button
                          className={clsx(
                            'relative m-1 max-h-[183px] w-[32%] cursor-pointer rounded-md border border-primary bg-white px-2 py-6 text-base font-semibold text-primary',
                            {
                              ['!bg-primary/10']: activeBox.locNumber == locIndex && activeBox.addNumber == index,
                            },
                          )}
                          onClick={() => {
                            setActiveBox({
                              locNumber: locIndex,
                              addNumber: index,
                            });
                          }}
                          data-autoid={`btnSelectAddress${index}`}
                        >
                          <p className="text-center">
                            <b>{loc?.Name}</b>
                          </p>
                          <p className="text-center">{data?.StreetAddress}</p>
                          <p className="text-center">{data?.City}</p>
                          <p className="text-center">{data?.State}</p>
                          <p className="text-center">{data?.Country}</p>
                          <p className="text-center">{data?.PostCode}</p>
                          <div className="absolute" style={{top: '2px', left: '2px'}}>
                            {data?.IsDefaultBilling === true ? (
                              <span
                                className={
                                  'mx-[2px] whitespace-nowrap rounded-md bg-[#D3E5EF50] px-[6px] py-[2px] font-poppins text-xs font-medium text-primary'
                                }
                              >
                                Default Billing Address
                              </span>
                            ) : null}
                            {data?.IsDefaultDeliverTo === true ? (
                              <span
                                className={
                                  'mx-[2px] whitespace-nowrap rounded-md bg-[#D3E5EF50] px-[6px] py-[2px] font-poppins text-xs font-medium text-primary'
                                }
                              >
                                Default Shipping Address
                              </span>
                            ) : null}
                          </div>
                          <div className="absolute" style={{bottom: '2px', right: '2px'}}>
                            <EditRoundedIcon
                              data-autoid={`btnEditAddress${index}`}
                              onClick={() => {
                                handleDialog(locIndex, index);
                              }}
                              fontSize="small"
                              style={{color: '#D8D8D8'}}
                            />
                            {locationAddresses && locationAddresses.length > 1 ? (
                              <CrossIcon
                                data-autoid={`btnDeleteAddress${index}`}
                                onClick={() => deleteAddress(locIndex, index, locations)}
                                fontSize="small"
                                style={{color: '#D8D8D8'}}
                              />
                            ) : null}
                          </div>
                        </button>
                      ),
                  ),
              )}
          </div>
        </DialogBody>
        <DialogFooter>
          {
            <div className="absolute" style={{bottom: 10, width: 970}}>
              <div
                className="flex w-full flex-row justify-between p-2"
                style={{backgroundColor: '#ADADAD50', borderRadius: '8px'}}
              >
                <Button
                  variant="outlined"
                  className="bg-white"
                  type="button"
                  data-autoid="btnCancelAddressDialog"
                  onClick={() => closeDialog()}
                >
                  Cancel
                </Button>
                <div>
                  <Button
                    variant="outlined"
                    type="button"
                    data-autoid="btnCancelAddressDialog"
                    onClick={e => {
                      setLocationNumber(locations?.length || 0);
                      handleAddAddress();
                    }}
                    className="mr-2.5 bg-white"
                  >
                    Add New Address
                  </Button>
                  <Button
                    data-autoid="btnSelectAddressDone"
                    loading={props.isSubmitting}
                    type="button"
                    onClick={() => {
                      closeDialog();
                      assignValue();
                    }}
                  >
                    Done
                  </Button>
                </div>
              </div>
            </div>
          }
        </DialogFooter>
      </Dialog>
      {locationEditDialog && (
        <AddressDialog
          {...props}
          dialogTitle={isEdit ? 'Edit address' : 'Add new Address'}
          isEdit={isEdit}
          open={locationEditDialog}
          setOpen={setLocationEditDialog}
          locationIndex={locationNumber}
          addressIndex={addressNumber}
          handleUpdateOpCustomer={props.handleUpdateOpCustomer}
          locations={locations}
          setLocations={setLocations}
          setShowLoader={setShowLoader}
          setAddressNumber={setAddressNumber}
          setLocationNumber={setLocationNumber}
          addressType={addressType}
          reloadSaveLocations={reloadSaveLocations}
          setActiveBox={setActiveBox}
        />
      )}
    </>
  );
};
