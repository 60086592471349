import React from 'react';

export const IconDropDownArrow = (props: SVGProps) => {
  return (
    <svg width={7} height={9} viewBox="0 0 32 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.42.04h27.16a2.43 2.43 0 011.701 4.154L17.725 17.751a2.43 2.43 0 01-3.45 0L.72 4.194A2.43 2.43 0 012.419.04z"
        fill="currentColor"
      />
    </svg>
  );
};
