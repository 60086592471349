import React, {useState} from 'react';
import {AlertDialogSlide} from './AlertDialogSlide';
import {TextField} from '@material-ui/core';
import {IRequisitionTemplate} from '../../model/template/IRequisitionTemplate';

interface ICreateTempalteDialog {
  onSaveTemplate: (template: Partial<IRequisitionTemplate>) => void;
  onCancel: () => void;
  templateName?: string;
}

const CreateTemplateDialog = (props: ICreateTempalteDialog) => {
  const [templateName, setTemplateName] = useState(props.templateName ? props.templateName + ' Template' : '');

  const handleChange = (event: React.FormEvent) => {
    const target = event.currentTarget as HTMLInputElement;
    setTemplateName(target.value);
  };

  const onClickSaveTemplate = () => {
    if (!templateName) {
      return;
    }

    const template: Partial<IRequisitionTemplate> = {
      Name: templateName,
    };

    props.onSaveTemplate(template);
  };

  return (
    <AlertDialogSlide
      title="Create Template"
      actions={[
        {label: 'Create', action: onClickSaveTemplate},
        {label: 'Cancel', variant: 'outlined', action: props.onCancel},
      ]}
    >
      <p className="mb-8">Would you like to save this order as a template?</p>

      <TextField
        id="template-name"
        name="TemplateName"
        helperText={!templateName && 'Template Name is required'}
        error={!templateName}
        label="TEMPLATE NAME"
        value={templateName}
        onChange={handleChange}
        fullWidth
      />
      <div className="mb-8"></div>
    </AlertDialogSlide>
  );
};

export default CreateTemplateDialog;
