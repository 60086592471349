import React, {PropsWithChildren} from 'react';
import {css} from 'glamor';
import STooltip from './data-display/Tooltip';

interface IPropsLabel extends PropsWithChildren {
  type?: 'primary' | 'secondary';
  className?: string;
  size?: 'xs' | 's' | 'm' | 'l' | 'xl';
  position?: 'center';
  title?: string;
  // isHover?: boolean
}

export const Label: React.FC<IPropsLabel> = ({size, type, className, position, title, children}) => {
  let fontSize = '18px';

  switch (size) {
    case 'xs':
      fontSize = '14px';
      break;
    case 's':
      fontSize = '16px';
      break;
    case 'l':
      fontSize = '20px';
      break;
    default:
      break;
  }

  const center = css({
    display: 'flex',
    justifyContent: 'center !important',
  });

  const primaryStyles = css({
    // fontFamily: "Saira Extra Condensed",
    fontSize,
    fontWeight: 'bold',
  });

  const secondaryStyles = css({
    fontFamily: 'sans-serif',
    fontSize,
  });

  return title ? (
    <STooltip title={title}>
      <span
        style={{
          display: 'block',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
        className={`${type === 'secondary' ? secondaryStyles : primaryStyles} ${
          position === 'center' ? center : ''
        } ${className}`}
      >
        {children}
      </span>
    </STooltip>
  ) : (
    <span
      className={`${type === 'secondary' ? secondaryStyles : primaryStyles} ${
        position === 'center' ? center : ''
      } ${className}`}
    >
      {children}
    </span>
  );
};
