import React, {useContext, useEffect, useState} from 'react';
import * as _ from 'lodash';
import {ChatBox} from './ChatBox';
import PurchasingContext from '../../context/purchasing/purchasingContext';
import AppContext from '../../context/app/appContext';
import {useTenantInfo} from '../../hooks/useTenantInfo';
import {AllOpenChats} from '../AccountsReceivable/AllOpenChats';
import {useConversationAPI} from '../../services/useConversationAPI';
import {useParams} from 'react-router-dom';
import {IConversationChatbox, IGetAllOpenConvoResponse} from '../../model/conversation/Conversation';
import {ConversationType} from '../../model/constants/Constants';
import ARContext from '../../context/ar-context/ARContext';

interface IInvoiceConversationProps {
  tenantGlobalId?: string;
  scope?: 'CR_CreateAndEdit';
}

export const ConversationChatBox = (props: IInvoiceConversationProps) => {
  // Props
  const {tenantGlobalId, scope} = props;

  // Hooks
  const {isInBuyerContext, isInSupplierContext} = useTenantInfo();

  // Context
  const {filterConversationId, setFilterConversationId} = useContext(ARContext);
  const {conversations, setConversations} = useContext(PurchasingContext);
  const {tenant} = useContext(AppContext);

  // APIs
  const {getAllSupplierOpenConvos, getAllBuyerOpenConvos, isLoading} = useConversationAPI();

  // Constants
  const {supplierId, customerId} = useParams<{supplierId?: string; customerId?: string}>();
  const convos = conversations?.filter(conv => conv.isChatboxOpen);

  // States
  const [expand, setExpand] = useState(false);

  useEffect(() => {
    if (filterConversationId) {
      setExpand(true);
      if (expand) getAllOpenConvos();
    }
  }, [filterConversationId]);

  useEffect(() => {
    if (expand) {
      getAllOpenConvos();
    } else {
      setFilterConversationId(undefined);
    }
  }, [expand]);

  useEffect(() => {
    return () => {
      setFilterConversationId(undefined);
    };
  }, []);

  // Functions
  const getAllOpenConvos = async () => {
    try {
      let openConvos: IGetAllOpenConvoResponse[] = [];
      const openChatbox = conversations?.find(conv => conv.isChatboxOpen);

      if (isInBuyerContext && supplierId) {
        openConvos = await getAllBuyerOpenConvos(supplierId);
      } else if (isInSupplierContext) {
        openConvos = await getAllSupplierOpenConvos(customerId || filterConversationId?.toString());
      }

      const convos = openConvos.reduce((acc: IConversationChatbox[], openConvo) => {
        let operationConvoUniqueId: string | undefined = undefined;
        if (openConvo.isCustomerLevelConversation && openConvo.accountID) {
          operationConvoUniqueId = String(openConvo.accountID);
        } else if (!openConvo.isCustomerLevelConversation && openConvo.documentID) {
          operationConvoUniqueId = openConvo.documentID;
        }
        if (operationConvoUniqueId) {
          acc.push({
            conversationId: openConvo?.conversationID,
            operationUniqueId: operationConvoUniqueId,
            refNumber: openConvo?.refNumber || '',
            isChatboxOpen: openChatbox?.operationUniqueId === operationConvoUniqueId ? true : false,
            conversationWith: openConvo.isCustomerLevelConversation
              ? openConvo?.conversationTitle
              : openConvo?.conversationTitle?.replace(` - ${openConvo.refNumber}`, ''),
            supplierID: (isInBuyerContext && openConvo.accountID) || null,
            customerID: (isInSupplierContext && openConvo.accountID) || null,
            datTypeID: openConvo?.datTypeID || undefined,
            guid: openConvo?.documentID || undefined,
            unreadMessages: openConvo?.unreadMessages,
            messageCount: openConvo?.messageCount,
            convoType: openConvo.isCustomerLevelConversation ? ConversationType.CUSTOMER : ConversationType.DOCUMENT,
          });
        }
        return acc;
      }, []);

      const sortedByUnreadMsg = _.orderBy(convos, [item => item.unreadMessages], ['desc']);

      setConversations(sortedByUnreadMsg);
    } catch {}
  };

  const handleMinOrMaxChatBox = async (operationId: string, isMinimizeAllOpenChatWindow?: boolean) => {
    const convos = _.cloneDeep(conversations);
    convos.forEach((conversation, i) => {
      if (conversation.operationUniqueId === operationId) {
        convos[i].isChatboxOpen = !convos[i].isChatboxOpen;
      } else {
        convos[i].isChatboxOpen = false;
      }
    });
    const _convos = convos?.filter(conv => conv.conversationId);
    setConversations(_convos);
    if (isMinimizeAllOpenChatWindow) {
      setExpand(false);
    } else {
      setExpand(true);
    }
    return;
  };

  const handleCloseConversation = (operationId: string) => {
    const convos = conversations.filter(conversation => conversation.operationUniqueId !== operationId);
    setConversations(convos);
  };

  const handleResolveConversation = (operationId: string) => {
    let convos = _.cloneDeep(conversations);
    if (convos?.length) {
      const index = convos.findIndex(conversation => conversation.operationUniqueId === operationId);
      if (index >= 0) {
        convos[index].conversationId = undefined;
        convos[index].unreadMessages = 0;
        convos[index].isChatboxOpen = false;
      }
      setConversations(convos);
    }
    handleCloseConversation(operationId);
  };

  const handleUserReadTheMessage = (operationId: string) => {
    const convos = _.cloneDeep(conversations);
    if (convos?.length) {
      const index = convos.findIndex(conversation => conversation.operationUniqueId === operationId);
      if (index >= 0) {
        convos[index].unreadMessages = 0;
      }
      setConversations(convos);
    }
  };

  const handleSetConversationId = (operationId: string, conversationId: number) => {
    const convos = _.cloneDeep(conversations);
    const index = convos.findIndex(conversation => conversation.operationUniqueId === operationId);
    if (index >= 0) {
      convos[index].conversationId = conversationId;
    }
    setConversations(convos);
  };

  return (
    <>
      {!convos?.length && !scope && scope !== 'CR_CreateAndEdit' && (
        <AllOpenChats
          expand={expand}
          setExpand={setExpand}
          handleOpenConversation={handleMinOrMaxChatBox}
          isLoading={isLoading}
        />
      )}
      {convos?.map((conversation, i) => (
        <ChatBox
          scope={scope}
          key={conversation.operationUniqueId}
          chatBoxNumber={i}
          handleMinOrMaxChatBox={handleMinOrMaxChatBox}
          handleCloseChatBox={handleCloseConversation}
          conversationData={conversation}
          tenantId={tenantGlobalId ? tenantGlobalId : tenant?.GlobalID || ''}
          userReadTheMessage={handleUserReadTheMessage}
          updateConversationId={handleSetConversationId}
          handleResolveConversation={handleResolveConversation}
        />
      ))}
    </>
  );
};
