import React from 'react';
//Icon
export const IconBookingDiary = (props: {width?: string | undefined; height?: string | undefined}) => {
  return (
    <svg width={21} height={19} viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.126.969a4.063 4.063 0 00-3.37-.9l-3.142.577a3.277 3.277 0 00-1.873 1.075A3.277 3.277 0 008.864.646L5.727.068a4.07 4.07 0 00-3.371.898 4.19 4.19 0 00-1.479 3.2v8.995c0 2.013 1.42 3.738 3.375 4.099l5.167.952a7.3 7.3 0 002.645 0l5.171-.952c1.953-.362 3.37-2.087 3.37-4.1V4.167A4.175 4.175 0 0019.125.97zM9.919 16.606a5.956 5.956 0 01-.207-.034l-5.166-.951a2.491 2.491 0 01-2.025-2.46V4.166c0-1.38 1.104-2.5 2.466-2.5a2.5 2.5 0 01.444.041l3.14.584A1.66 1.66 0 019.92 3.926v12.68zm9.042-3.445a2.491 2.491 0 01-2.024 2.46l-5.167.951a5.953 5.953 0 01-.207.034V3.926a1.66 1.66 0 011.35-1.64l3.14-.583a2.442 2.442 0 012.024.541c.561.476.885 1.18.884 1.922v8.995z"
        fill="#1C78AD"
      />
    </svg>
  );
};
