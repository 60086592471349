import React, {useContext, useState, useEffect} from 'react';
import AppContext from '../../context/app/appContext';
import useTenantAPI from '../../services/useTenantAPI';
import {ITenantTemplate, ITenant} from '../../model/Tenant';
import _ from 'lodash';
import {IMasterAdaptor} from '../../model/MasterAdaptor';
import useMasterAdaptorAPI from '../../services/useMasterAdaptorAPI';
import {Welcome} from './Welcome';
import {TemplateSelection} from './TemplateSelection';
import {SelectAccountingSystem} from './SelectAccountingSystem';
import {AuthorizeAccountingSystem} from './AuthorizeAccountingSystem';
import {BusinessDetails} from './BusinessDetails';
import {FetchCustomers} from './FetchCustomers';
import {PaymentSetup} from './PaymentSetup';
import {ModuleInfo} from './ModuleInfo';
import {ISynkAuthorizeResponse, ISynkIsAuthorizedResponse} from '../../model/synkd/SynkAuthorizeResponse';
import useStorage from '../../hooks/useStorage';
import {ModuleTypes, StorageKeys} from '../../model/constants/Constants';
import {SetupComplete} from './SetupComplete';
import {useHistory} from 'react-router-dom';
import {IUser} from '../../model/user/User';
import useSynkAPI from '../../services/useSynkAPI';
import {AUTH_MENU} from '../../routes/AuthenticatedRoutes';

import {css} from 'glamor';
import {ApplicationInfo} from '../../model/constants/ApplicationInfo';

export interface IPayments {
  paymentMethod: string;
  clearsTo: string;
  surcharge?: number | string;
}

const _defaultPayments: IPayments[] = [
  {
    paymentMethod: 'Cash',
    clearsTo: '',
  },
  {
    paymentMethod: 'Eftpos',
    clearsTo: '',
  },
  {
    paymentMethod: 'Cheque',
    clearsTo: '',
  },
];

const Onboarding = (props: {onboardingAccount?: string; onBoardingAccountRedirectUrl?: string}) => {
  const appContext = useContext(AppContext);
  const history = useHistory();
  const {synkIsAuthorize, connect} = useSynkAPI();

  const showCloseBtn = () => {
    const {suppliers, user} = appContext;

    if (suppliers?.length) {
      return true;
    }

    if (user?.Modules.includes(ModuleTypes.SpendaPay.toString())) {
      return true;
    }
  };

  const getInitialStep = () => {
    let step = showCloseBtn() ? 0 : 1;
    step = 1;

    if (history.location.pathname.replaceAll('/', '') !== 'onboarding') {
      step = 4;
    }
    return step;
  };

  const [currentStep, setCurrentStep] = useState<number>(getInitialStep());
  const [businessTemplate, setBusinessTemplate] = useState<ITenantTemplate[]>([]);

  const [isBuyer, setIsBuyer] = useState<boolean>(false);
  const [customerRequestStatus, setCustomerRequestStatus] = useState<string>('NOTSTARTED');
  const [customerRequestData, setCustomerRequestData] = useState<any>(undefined);
  const [customerRequestPercentage, setCustomerRequestPercentage] = useState<any>(0);
  const [customerRequestWorkflowId, setCustomerRequestWorkflowId] = useState<any>(null);
  const [customerNavigated, setCustomerNavigated] = useState<boolean>(false);
  const [selectedBusinessTemplate, setSelectedBusinessTemplate] = useState<ITenantTemplate>();
  const [accounts, setAccounts] = useState<IMasterAdaptor[]>([]);
  const [selectedAccount, setSelectedAccount] = useState<IMasterAdaptor>();
  const [selectedAccountMasterAdaptorId, setSelectedAccountMasterAdaptorId] = useState<number | undefined>();
  const [isSyncConnectLoading, setIsSyncConnectLoading] = useState<boolean>(false);
  const {getTemplates, saveTenantDetails, saveTenantTemplate, getTenantInfo, updateModule} = useTenantAPI();
  const {selectAdaptor, search} = useMasterAdaptorAPI();
  const [organizationName, setOrganizationName] = useState<string>('');
  const [payments, setPayments] = useState<IPayments[]>(_.cloneDeep(_defaultPayments));

  const [, setIsTenantModuleEnabled] = useState<boolean>(false);
  const [, setSynkAuthorizeResponse] = useState<ISynkAuthorizeResponse>();
  const [isAccountSystemLoading, setIsAccountSystemLoading] = useState<boolean>(false);
  const [isSetupCompleteLoading, setIsSetupCompleteLoading] = useState<boolean>(false);
  const [isTempateSelectionLoading, setIsTempateSelectionLoading] = useState<boolean>(false);
  const [skipAccountTypeScreen, setSkipAccountTypeScreen] = useState<boolean>(false);
  const [skipAccountingSelectionScreen, setSkipAccountingSelectionScreen] = useState<boolean>(false);

  const {getItem, setItem} = useStorage();

  const getSelectedAdaptor = async () => {
    if (appContext && !appContext.selectedAdaptor) {
      const adaptor = await getItem(StorageKeys.SelectedAccount);
      setSelectedAccount(adaptor);
      setSelectedAccountMasterAdaptorId(adaptor?.MasterAdaptorID);
    } else {
      setSelectedAccount(appContext.selectedAdaptor);
    }
    setCurrentStep(4);
  };

  useEffect(() => {
    if (props.onboardingAccount) {
      getSelectedAdaptor();
    }

    if (businessTemplate && businessTemplate.length === 0) {
      getTemplates().then(response => {
        const templateTenantId = appContext?.tenantInfo?.TenantUserDetails?.TemplateTenantID;

        if (templateTenantId) {
          const template = response.find(tem => tem.TemplateID === templateTenantId);
          if (template) {
            setSelectedBusinessTemplate(template);
          }
        }
        setBusinessTemplate(response);
      });
    }
    if (accounts && accounts.length === 0) {
      const tenantId = appContext.tenantInfo?.TenantUserDetails.TenantID || 0;
      search(tenantId, ApplicationInfo.WebsiteId).then(response => {
        let filterAccounts =
          (response &&
            response.length > 0 &&
            response.filter((account: IMasterAdaptor) => account.Type === 'Accounts')) ||
          [];

        const adaptorItems = filterAccounts.filter(
          (account: IMasterAdaptor) => Boolean(account.ID) && account.IsUsedByTenant,
        );

        if (adaptorItems.length) {
          setAccounts(adaptorItems);
          if (!props.onboardingAccount) {
            setSelectedAccount(adaptorItems[0]);
          }
        } else {
          setAccounts(filterAccounts);
        }
      });
    }
    if (showCloseBtn()) {
      setIsBuyer(true);
    }
  }, []);

  const onBusinessTemplateSelection = (selectedTemplate: ITenantTemplate) => {
    setSelectedBusinessTemplate(selectedTemplate);
  };

  const onSetupComplete = async () => {
    if (appContext && appContext.tenant) {
      setIsSetupCompleteLoading(true);
      const templateId = await getItem(StorageKeys.SelectedTemplate);
      if (templateId) {
        await saveTenantTemplate(templateId);
      }

      const tenantDetail: ITenant = {...appContext.tenant};
      tenantDetail.IsSetupComplete = true;
      await saveTenantDetails(tenantDetail);
      appContext.setTenant(tenantDetail);
      await updateModule({
        ParentModuleID: ModuleTypes.SpendaCollectAR,
        IsModuleSetup: true,
        configureSchedules: true,
        SchedulesConfig: [
          {
            TaskTypeID: 31,
            WorkflowID: null,
            IsActive: true,
            IsRecurringSchedule: true,
            IsEnabled: true,
            StatusType: 0,
          },
          {
            TaskTypeID: 34,
            WorkflowID: null,
            IsActive: true,
            IsRecurringSchedule: true,
            IsEnabled: true,
            StatusType: 0,
          },
        ],
      });

      const tenantInfo = await getTenantInfo();
      await appContext.setTenantInfo(tenantInfo);
      if (appContext && appContext.user) {
        const currentUser: IUser = {...appContext.user};
        const userModules = currentUser.Modules + ',' + ModuleTypes.SpendaCollectAR.toString();
        currentUser.Modules = userModules;
        appContext.setUser(currentUser);
        await appContext.loadUserSession(currentUser);
      }
      setIsSetupCompleteLoading(false);
      history.push(`${AUTH_MENU}`);
    }
  };

  const onAccountingSystemSelection = async () => {
    if (selectedAccount && selectedAccount.MasterAdaptorID) {
      setIsAccountSystemLoading(true);
      const arr = [];
      arr.push(selectedAccount.MasterAdaptorID);
      const response = await selectAdaptor(arr, ApplicationInfo.WebsiteId);
      const adaptorId = response && response.Value[0];
      selectedAccount.ID = adaptorId;
      appContext.setSelectedAdaptor(selectedAccount);
      setSelectedAccountMasterAdaptorId(selectedAccount?.MasterAdaptorID);
    }
    setIsAccountSystemLoading(false);
    setSelectedAccount(selectedAccount);
    changeStep();
  };

  const changeStep = () => {
    setCurrentStep(_currentStep => _currentStep + 1);
  };

  const redirectToStep = (step: number) => {
    setCurrentStep(step);
  };

  const submitSynkAuthorizeResponse = (data: ISynkAuthorizeResponse) => {
    setSynkAuthorizeResponse(data);
  };

  const onBackPress = () => {
    let _currentStep;
    if ((currentStep === 5 && skipAccountTypeScreen) || (currentStep === 4 && skipAccountingSelectionScreen)) {
      _currentStep = 2;
    } else {
      _currentStep = currentStep == 0 ? currentStep : currentStep - 1;
    }
    setCurrentStep(_currentStep);
  };

  const onTenantModuleSelect = async (enableModule: boolean = false) => {
    await updateModule({ParentModuleID: ModuleTypes.SpendaCollectAR, IsModuleSetup: enableModule});
  };

  const onTemplateSelection = async () => {
    setIsTempateSelectionLoading(true);
    onTenantModuleSelect()
      .then(async () => {
        setIsTenantModuleEnabled(true);
        const adaptor = accounts.find(account => Boolean(account.ID) && Boolean(account.IsUsedByTenant));
        if (adaptor && adaptor.Name && adaptor.ID && adaptor.MasterAdaptorID) {
          const response: ISynkIsAuthorizedResponse = await synkIsAuthorize(
            adaptor?.Name,
            adaptor?.ID,
            adaptor?.MasterAdaptorID,
          );
          if (!response.IsAuthorized || !response?.TokenDetails?.OrgName) {
            setCurrentStep(4);
            setSkipAccountingSelectionScreen(true);
          } else {
            setSelectedAccount(adaptor);
            setSelectedAccountMasterAdaptorId(adaptor?.MasterAdaptorID);
            setCurrentStep(5);
            setOrganizationName(response?.TokenDetails?.OrgName || '');
            setSkipAccountTypeScreen(true);
          }
        } else {
          changeStep();
        }
      })
      .finally(() => {
        setIsTempateSelectionLoading(false);
        setItem(StorageKeys.SelectedTemplate, selectedBusinessTemplate?.TemplateID);
      });
  };

  const synkConnect = async () => {
    let accountType = '';

    switch (selectedAccount?.Name) {
      case 'Xero':
        accountType = 'xero';
        break;
      case 'QuickBooksOnline':
        accountType = 'quickbooks';
        break;
      case 'MyObAccountRightLive':
        accountType = 'myob';
        break;
      case 'Parody':
        accountType = 'parody';
        break;
    }

    const location = window.location.origin;
    const onBoardingAdaptorPath = '/onboarding/' + accountType;
    const authorizeRedirectLink = location + onBoardingAdaptorPath;

    const userName = appContext?.user?.UserName;

    if (userName && selectedAccount && selectedAccount.Name && selectedAccount.MasterAdaptorID) {
      setIsSyncConnectLoading(true);
      connect(userName, selectedAccount.Name, authorizeRedirectLink, ApplicationInfo.WebsiteId)
        .then(data => {
          if (data.OAuthLoginUrl) {
            window.open(data.OAuthLoginUrl, '_self');
          } else {
            if (selectedAccount.Name?.toLowerCase() === 'parody') {
              history.push(onBoardingAdaptorPath);
            }
          }
        })
        .finally(() => {
          setIsSyncConnectLoading(false);
        });
    }
  };

  const startModuleTrial = async () => {
    await onTenantModuleSelect();
    changeStep();
  };

  const handleClose = async () => {
    const module = appContext.tenantInfo?.Modules.find(m => m.ModuleID === ModuleTypes.SpendaCollectAR);

    if (!module?.IsTenantOwned) {
      const currentUser: IUser = {...appContext.user!};
      await appContext.loadUserSession(currentUser);
    }
    history.push(`${AUTH_MENU}`);
  };

  const renderSteps = (step: number) => {
    switch (step) {
      case 0:
        return <ModuleInfo onNextClick={startModuleTrial} />;
      case 1:
        return (
          <Welcome
            onNextClick={changeStep}
            userName={appContext?.user?.FirstName}
            isBuyer={isBuyer}
            handleClose={handleClose}
          />
        );
      case 2:
        return (
          <TemplateSelection
            onBackPress={onBackPress}
            onNextClick={onTemplateSelection}
            businessTemplate={businessTemplate}
            onTemplateSelection={onBusinessTemplateSelection}
            selectedBusinessTemplate={selectedBusinessTemplate}
            isLoading={isTempateSelectionLoading}
            isBuyer={isBuyer}
            handleClose={handleClose}
          />
        );
      case 3:
        return (
          <SelectAccountingSystem
            onBackPress={onBackPress}
            onNextClick={onAccountingSystemSelection}
            accounts={accounts}
            selectedAccount={selectedAccount}
            onSelectAccountingSystem={setSelectedAccount}
            isAccountSystemLoading={isAccountSystemLoading}
            selectedAccountMasterAdaptorId={selectedAccountMasterAdaptorId}
            isBuyer={isBuyer}
            handleClose={handleClose}
          />
        );
      case 4:
        return (
          <AuthorizeAccountingSystem
            onBackPress={onBackPress}
            onNextClick={changeStep}
            userName={appContext?.user?.UserName}
            onboardingAccount={props.onboardingAccount}
            selectedAccount={selectedAccount}
            onBoardingAccountRedirectUrl={props.onBoardingAccountRedirectUrl}
            websiteId={ApplicationInfo.WebsiteId}
            submitSynkAuthorizeResponse={submitSynkAuthorizeResponse}
            setOrganizationName={name => setOrganizationName(name)}
            synkConnect={synkConnect}
            isSyncConnectLoading={isSyncConnectLoading}
            isBuyer={isBuyer}
            handleClose={handleClose}
          />
        );
      case 5:
        return (
          <BusinessDetails
            onBackPress={onBackPress}
            onNextClick={changeStep}
            organisationName={organizationName}
            isBuyer={isBuyer}
            handleClose={handleClose}
          />
        );
      case 6:
        return (
          <FetchCustomers
            onBackPress={onBackPress}
            onNextClick={changeStep}
            selectedAdaptor={selectedAccount}
            websiteId={ApplicationInfo.WebsiteId}
            customerRequestPercentage={customerRequestPercentage}
            setCustomerRequestPercentage={setCustomerRequestPercentage}
            setCustomerRequestWorkflowId={setCustomerRequestWorkflowId}
            customerRequestWorkflowId={customerRequestWorkflowId}
            redirectToStep={redirectToStep}
            setCustomerRequestStatus={setCustomerRequestStatus}
            customerRequestStatus={customerRequestStatus}
            customerRequestData={customerRequestData}
            setCustomerRequestData={setCustomerRequestData}
            selectedAccount={selectedAccount}
            setCustomerNavigated={setCustomerNavigated}
            customerNavigated={customerNavigated}
            isBuyer={isBuyer}
            handleClose={handleClose}
          />
        );
      case 7:
        return (
          <PaymentSetup
            onBackPress={() => {
              setPayments(_.cloneDeep(_defaultPayments));
              onBackPress();
            }}
            onNextClick={() => {
              setPayments(_.cloneDeep(_defaultPayments));
              changeStep();
            }}
            setPaymentsMethods={setPayments}
            defaultPayments={payments}
            selectedAdaptor={selectedAccount}
            isBuyer={isBuyer}
            handleClose={handleClose}
          />
        );
      case 8:
        return (
          <SetupComplete
            onBackPress={onBackPress}
            onSetupComplete={onSetupComplete}
            isSetupCompleteLoading={isSetupCompleteLoading}
            isBuyer={isBuyer}
            handleClose={handleClose}
          />
        );
      default:
        return null;
    }
  };

  return <>{renderSteps(currentStep)}</>;
};

export const GetBackgroundCSS = () => {
  return css({
    background: 'rgb(255, 255, 255)',
    '@supports ((backdrop-filter: blur(10px))) or (-webkit-backdrop-filter: blur(10px))': {
      background: 'rgba(255, 255, 255, 0.7)',
      backdropFilter: 'blur(10px)',
      '& -webkit-backdrop-filter': 'blur(10px)',
    },
  });
};

export default Onboarding;
