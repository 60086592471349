import React from 'react';
import supplierViewDropdown from '../../assets/svg/accounts-payable/supplierViewDropdown.svg';
import {VectorIcon} from '../../assets/svg/accounts-payable/VectorIcon';
import {TableContainer, Table, TableBody, TableCell, TableHead, TableRow, makeStyles} from '@material-ui/core';
import {Arrow} from '../../assets/svg/accounts-payable/Arrow';
import {ARTable, IARColumns} from '../../components/AccountsReceivable/ARTable';
import {SwitchSlider} from '../../components/form/SwitchSlider';
import {
  ISupplierPrimaryContact,
  ISuppliersStagedTransactions,
  ITransactionsList,
  SelectedInvoicesType,
} from '../../model/requires-attention/StagedTransactions';
import {SelectToggler} from '../../screens/purchasing/CommonComponents';
import {Toast} from '../../utils/Toast';
import APTooltip from '../data-display/APTootip';
import {Edit} from '@material-ui/icons';
import {APErrorCodes, DatTypes} from '../../model/constants/Constants';
import {InvoiceIcon} from '../../assets/svg/accounts-payable/InvoiceIcon';
import {Link} from 'react-router-dom';
import {
  AUTH_BUYING_AP_REQUIRES_ATTENTION_DEBIT_NOTE_DETAILS_ROUTE,
  AUTH_BUYING_AP_REQUIRES_ATTENTION_INVOICE_DETAILS_ROUTE,
} from '../../routes/AccountsPayableRoutes';
import WhiteInfoIcon from '../../assets/svg/accounts-payable/WhiteInfoIcon';
import AttentionRequireIcon from '../../assets/svg/accounts-payable/AttentionRequireIcon';
import {Button, Tooltip} from 'spenda-ui-react';

export const useRequiresAttentionTableStyle = makeStyles({
  expandArrow: {
    '& img': {
      transform: 'rotate(180deg)',
    },
    '& img.rotate-expand-more-icon': {
      transform: 'rotate(0deg)',
    },
  },
  tables: {
    '& table': {
      borderRadius: '2px 2px 0 0',
      '& th': {
        color: '#999999',
        fontWeight: 600,
        fontSize: '14px',
        fontFamily: 'Poppins',
        width: '180px',
      },
    },
    '& .MuiTableHead-root': {
      background: '#F1F1F1',
    },
    '& .MuiTableCell-root': {
      borderBottom: '1px solid #EAEAEA60',
      borderLeft: '0px',
      padding: '8px 10px',
    },
    '& .MuiTableCell-body': {
      color: '#333333',
      fontWeight: 500,
      fontFamily: 'poppins',
      fontSize: '14px',
      '&.action': {
        color: '#1C78AD!important',
        width: '12%',
      },
    },
  },
  expandableTable: {
    '& td': {
      '&:nth-child(odd)': {
        background: '#FAFAFA!important',
      },
    },
  },
});

interface IRequiresAttentionTableProps {
  t2tV2: boolean;
  stagedTransactions?: ISuppliersStagedTransactions[];
  handleBulkInvoices: (transaction: ISuppliersStagedTransactions) => void;
  breakDownSupplierId: number | undefined;
  setBreakDownSupplierId: React.Dispatch<React.SetStateAction<number | undefined>>;
  handleVendorMapping: (supplierId: number, value: boolean) => void;
  breakdownVendorToggle: boolean;
  setBreakdownVendorToggle: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  handleSelectInvoices: (messageId: number, supplierId: number) => void;
  selectedInvoices?: SelectedInvoicesType;
  handleSelectPostingType: (
    show: boolean,
    supplierDetails?: ISupplierPrimaryContact,
    isVendorDataAvailable?: boolean,
  ) => void;
  setIsVendorPostingType: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  setVendorPostingTypeDetail: React.Dispatch<React.SetStateAction<ITransactionsList>>;
  setSelectPostingInventoryModal: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  setSelectVendorPostingInventory: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  setShowCreditlineModal: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  setSupplierCreditLineAccount: React.Dispatch<React.SetStateAction<number | null>>;
  setSelectSourceSupplierData: React.Dispatch<React.SetStateAction<ISuppliersStagedTransactions>>;
  setSelectedVendorData: React.Dispatch<React.SetStateAction<ITransactionsList>>;
}

export const RequiresAttentionTable = (props: IRequiresAttentionTableProps) => {
  const {
    t2tV2,
    stagedTransactions,
    handleBulkInvoices,
    breakDownSupplierId,
    setBreakDownSupplierId,
    handleVendorMapping,
    breakdownVendorToggle,
    handleSelectInvoices,
    selectedInvoices,
    handleSelectPostingType,
    setIsVendorPostingType,
    setVendorPostingTypeDetail,
    setSelectPostingInventoryModal,
    setSelectVendorPostingInventory,
    setBreakdownVendorToggle,
    setShowCreditlineModal,
    setSupplierCreditLineAccount,
    setSelectSourceSupplierData,
    setSelectedVendorData,
  } = props;
  const classes = useRequiresAttentionTableStyle();

  const columns: IARColumns[] = [
    {
      title: '',
      key: '',
      width: '2%',
      align: 'left',
      columClassName: 'h-[40px] text-spenda-labeltext !py-1',
      rowClassName: 'bg-spenda-cream h-[40px] py-0 px-2.5',
      rowRenderer: (transaction: ITransactionsList) => {
        const supplierId =
          transaction.datTypeID === DatTypes.StagedPurchaseInvoice
            ? transaction?.stagedPurchaseInvoice?.sourceSupplierID
            : transaction?.stagedDebitNote?.sourceSupplierID;
        return (
          <span
            className="flex items-center justify-center"
            data-autoid={`selectPurchaseInvoice-${transaction?.stagedPurchaseInvoice?.id}`}
            onClick={() => supplierId && handleSelectInvoices(transaction?.messageID, supplierId)}
          >
            <SelectToggler
              className="!m-0 min-w-[22px]"
              isSelected={
                supplierId && selectedInvoices?.[supplierId?.toString()]?.MessageIds?.includes(transaction?.messageID)
                  ? true
                  : false
              }
              isAccountsPayable
            />
          </span>
        );
      },
    },
    {
      title: 'Ref no.',
      key: 'RefNo',
      width: breakdownVendorToggle ? '10%' : '11%',
      align: 'left',
      columClassName: 'text-spenda-labeltext !py-1',
      rowClassName: 'bg-white h-[40px] py-0 pl-2.5',
      rowRenderer: (transaction: ITransactionsList, rowIndex: number) => (
        <>
          {t2tV2 ? (
            <Link
              to={
                transaction.datTypeID === DatTypes.StagedPurchaseInvoice
                  ? AUTH_BUYING_AP_REQUIRES_ATTENTION_INVOICE_DETAILS_ROUTE.replace(
                      ':invoiceID',
                      transaction?.stagedPurchaseInvoice?.id?.toString() || '',
                    )
                  : AUTH_BUYING_AP_REQUIRES_ATTENTION_DEBIT_NOTE_DETAILS_ROUTE.replace(
                      ':invoiceID',
                      transaction?.stagedDebitNote?.id?.toString() || '',
                    )
              }
              data-autoid={`lblRefNo${rowIndex}`}
              className="flex items-center justify-between font-poppins text-base font-medium text-black-800"
            >
              <Tooltip
                placement="bottom"
                offset="0"
                className="rounded-[4px] bg-white px-3 py-2 text-spenda-primarytext shadow-md"
                content={
                  <div className="flex">
                    <h4 className="mr-2 font-medium">Doc Type:</h4>
                    <p>{transaction.datTypeID === DatTypes.StagedPurchaseInvoice ? 'Invoice' : 'Debit Note'}</p>
                  </div>
                }
              >
                <Button className="!bg-transparent p-0 text-primary">
                  <span>
                    {(transaction.datTypeID === DatTypes.StagedPurchaseInvoice
                      ? transaction?.stagedPurchaseInvoice?.refNumber
                      : transaction?.stagedDebitNote?.refNumber) || '-'}
                  </span>
                </Button>
              </Tooltip>
              <span className="flex h-10 w-9 items-center justify-center bg-[#eff5f9]">
                <InvoiceIcon data-autoid={`lnkInvoice`} />
              </span>
            </Link>
          ) : (
            <Tooltip
              placement="bottom"
              offset="0"
              className="rounded-[4px] bg-white px-3 py-2 text-spenda-primarytext shadow-md"
              content={
                <div className="flex">
                  <h4 className="mr-2 font-medium">Doc Type:</h4>
                  <p>{transaction.datTypeID === DatTypes.StagedPurchaseInvoice ? 'Invoice' : 'Debit Note'}</p>
                </div>
              }
            >
              <Button className="!bg-transparent p-0 text-primary">
                <span data-autoid={`lblRefNo${rowIndex}`} className="font-poppins text-base font-medium text-black-800">
                  <span>
                    {(transaction.datTypeID === DatTypes.StagedPurchaseInvoice
                      ? transaction?.stagedPurchaseInvoice?.refNumber
                      : transaction?.stagedDebitNote?.refNumber) || '-'}
                  </span>
                </span>
              </Button>
            </Tooltip>
          )}
        </>
      ),
    },
    {
      title: 'Vendor name',
      key: 'Vendor name',
      width: '11%',
      align: 'left',
      columClassName: 'text-spenda-labeltext !py-1',
      rowClassName: 'bg-white px-2.5 h-[40px] py-0',
      rowRenderer: (transaction: ITransactionsList, rowIndex: number) => (
        <Tooltip
          placement="bottom"
          offset="0"
          className="rounded-[4px] bg-white px-3 py-2 text-spenda-primarytext shadow-lg"
          content={
            <div className="flex">
              <h4 className="mr-2 font-medium">Vendor ref no.</h4>
              <p>
                {(transaction.datTypeID === DatTypes.StagedPurchaseInvoice
                  ? transaction?.stagedPurchaseInvoice?.vendorInvoiceRefNumber
                  : transaction?.stagedDebitNote?.vendorDebitNoteRefNumber) || '-'}
              </p>
            </div>
          }
        >
          <Button className="!bg-transparent p-0 text-primary">
            <span
              data-autoid={`lblVendorName${rowIndex}`}
              className="block w-[125px] truncate font-poppins text-base font-medium text-black-800"
            >
              {(transaction.datTypeID === DatTypes.StagedPurchaseInvoice
                ? transaction?.stagedPurchaseInvoice?.vendorName
                : transaction?.stagedDebitNote?.vendorName) || '-'}
            </span>
          </Button>
        </Tooltip>
      ),
    },
    {
      title: 'Invoice total',
      key: 'Invoice total',
      width: '10%',
      align: 'right',
      columClassName: 'text-spenda-labeltext !py-1',
      rowClassName: '!bg-white px-2.5 h-[40px] py-0',
      rowRenderer: (transaction: ITransactionsList, rowIndex: number) => (
        <span data-autoid={`lblInvoiceTotal${rowIndex}`} className="font-poppins text-base font-medium text-black-800">
          {`$${
            transaction.datTypeID === DatTypes.StagedPurchaseInvoice
              ? transaction?.stagedPurchaseInvoice?.totalInc?.toFixed(2)
              : transaction?.stagedDebitNote?.totalInc?.toFixed(2)
          }`}
        </span>
      ),
    },
    {
      title: 'Paid amount',
      key: 'Paid amount',
      width: '10%',
      align: 'right',
      columClassName: 'text-spenda-labeltext !py-1',
      rowClassName: 'bg-spenda-cream px-2.5 h-[40px] py-0',
      rowRenderer: (transaction: ITransactionsList, rowIndex: number) => (
        <span data-autoid={`lblPaidAmount${rowIndex}`} className={`font-poppins text-base font-medium text-black-800`}>
          {`$${
            transaction.datTypeID === DatTypes.StagedPurchaseInvoice
              ? (
                  (transaction?.stagedPurchaseInvoice?.totalInc || 0) -
                  (transaction?.stagedPurchaseInvoice?.balance || 0)
                )?.toFixed(2)
              : ((transaction?.stagedDebitNote?.totalInc || 0) - (transaction?.stagedDebitNote?.balance || 0))?.toFixed(
                  2,
                )
          }`}
        </span>
      ),
    },
    {
      title: 'Outstanding',
      key: 'Outstanding',
      width: '9%',
      align: 'right',
      columClassName: 'text-spenda-labeltext !py-1',
      rowClassName: 'bg-white px-2.5 h-[40px] py-0',
      rowRenderer: (transaction: ITransactionsList, rowIndex: number) => (
        <>
          <span
            data-autoid={`lblOutstanding${rowIndex}`}
            className={`font-poppins text-base font-medium text-black-800`}
          >
            {`$${
              transaction.datTypeID === DatTypes.StagedPurchaseInvoice
                ? transaction?.stagedPurchaseInvoice?.balance?.toFixed(2)
                : transaction?.stagedDebitNote?.balance?.toFixed(2)
            }`}
          </span>
        </>
      ),
    },
    {
      title: 'Status',
      key: 'Status',
      width: breakdownVendorToggle ? '12%' : '11%',
      align: 'center',
      columClassName: 'text-spenda-labeltext !py-1',
      rowClassName:
        (t2tV2 && breakdownVendorToggle) || !t2tV2
          ? 'bg-spenda-cream px-2.5 h-[40px] py-0'
          : 'bg-white px-2.5 h-[40px] py-0',
      rowRenderer: (transaction: ITransactionsList) => {
        const warnings =
          transaction.datTypeID === DatTypes.StagedPurchaseInvoice
            ? transaction?.stagedPurchaseInvoice?.warnings
            : transaction?.stagedDebitNote?.warnings;
        const isPotentialDuplicate =
          warnings && warnings.length && warnings.find(warn => warn.code === APErrorCodes.PotentialDuplicate);
        return (
          <div className="flex items-center justify-between">
            {isPotentialDuplicate ? (
              <span className={`w-[140px] cursor-pointer font-poppins text-base font-medium text-spenda-error`}>
                Potential duplicate
              </span>
            ) : (
              <span className={`cursor-pointer font-poppins text-base font-medium text-black-800`}>New invoice</span>
            )}
            <APTooltip
              arrow
              placement="bottom-end"
              title={
                <React.Fragment>
                  <p className="w-[140px] text-center font-poppins font-medium text-black-800">
                    {isPotentialDuplicate
                      ? 'Invoice with similar ref. no and total amount may already exist in your financial.'
                      : 'New Invoice'}
                  </p>
                </React.Fragment>
              }
            >
              <span className="flex cursor-pointer items-center justify-center">
                <WhiteInfoIcon width={12} height={12} className="min-w-[12px]" />
              </span>
            </APTooltip>
          </div>
        );
      },
    },
    {
      title: 'Errors',
      key: 'Errors',
      width: breakdownVendorToggle ? '10%' : '5%',
      align: 'center',
      columClassName: 'text-spenda-labeltext !py-1',
      rowClassName:
        (t2tV2 && breakdownVendorToggle) || !t2tV2
          ? 'bg-white px-2.5 h-[40px] py-0'
          : 'bg-spenda-cream px-2.5 h-[40px] py-0',
      rowRenderer: (transaction: ITransactionsList) => {
        const errors =
          transaction.datTypeID === DatTypes.StagedPurchaseInvoice
            ? transaction?.stagedPurchaseInvoice?.errors
            : transaction?.stagedDebitNote?.errors;
        return (
          <>
            {errors?.length ? (
              <APTooltip
                title={errors?.map((err, index) => `${err.description} ${index + 1 < errors.length ? ', ' : ''}`)}
                placement="bottom"
                arrow
              >
                <span className="flex cursor-pointer items-center justify-center">
                  <AttentionRequireIcon width={16} height={16} />
                </span>
              </APTooltip>
            ) : null}
          </>
        );
      },
    },
  ];
  if (breakdownVendorToggle) {
    columns?.splice(8, 0, {
      title: 'Post as',
      key: 'PostAs',
      width: '13%',
      align: 'center',
      columClassName: 'text-spenda-labeltext !py-1',
      rowClassName:
        (t2tV2 && breakdownVendorToggle) || !t2tV2
          ? 'bg-spenda-cream px-2.5 h-[40px] py-0'
          : 'bg-white px-2.5 h-[40px] py-0',
      rowRenderer: (transaction: ITransactionsList, rowIndex: number) => {
        const supplierId =
          transaction.datTypeID === DatTypes.StagedPurchaseInvoice
            ? transaction?.stagedPurchaseInvoice?.supplierID
            : transaction?.stagedDebitNote?.supplierID;
        const supplierName = supplierId
          ? transaction.datTypeID === DatTypes.StagedPurchaseInvoice
            ? transaction?.stagedPurchaseInvoice?.supplierPrimaryContact?.name
            : transaction?.stagedDebitNote?.supplierPrimaryContact?.name
          : '';
        const supplierDetails =
          transaction.datTypeID === DatTypes.StagedPurchaseInvoice
            ? transaction?.stagedPurchaseInvoice?.sourceSupplierPrimaryContact
            : transaction?.stagedDebitNote?.sourceSupplierPrimaryContact;
        return (
          <>
            {!supplierId ? (
              <span
                data-autoid={`lnkSelectPostingType${rowIndex}`}
                className={`cursor-pointer font-poppins text-base font-medium text-primary`}
                onClick={() => {
                  handleSelectPostingType(true, supplierDetails);
                  setIsVendorPostingType(true);
                  setVendorPostingTypeDetail(transaction);
                }}
              >
                Select Posting Type
              </span>
            ) : (
              <span className={`font-poppins text-base font-medium text-primary`}>{`${supplierName}`}</span>
            )}
          </>
        );
      },
    });
    if (t2tV2) {
      columns?.splice(9, 0, {
        title: 'Posting inventory',
        key: 'PostingInventory',
        width: '19%',
        align: 'center',
        columClassName: 'text-spenda-labeltext !py-1',
        rowClassName: 'bg-white px-2.5 h-[40px] py-0',
        rowRenderer: (transaction: ITransactionsList, rowIndex: number) => {
          const supplierId =
            transaction.datTypeID === DatTypes.StagedPurchaseInvoice
              ? transaction?.stagedPurchaseInvoice?.supplierID
              : transaction?.stagedDebitNote?.supplierID;
          const postingInventoryCode =
            transaction.datTypeID === DatTypes.StagedPurchaseInvoice
              ? transaction?.stagedPurchaseInvoice?.postingSupplierPostingInventoryCode
              : transaction?.stagedDebitNote?.postingSupplierPostingInventoryCode;
          return (
            <>
              {!postingInventoryCode ? (
                <span
                  data-autoid={`lnkPostingInventory${rowIndex}`}
                  className={`cursor-pointer font-poppins text-base font-medium text-primary`}
                  onClick={() => {
                    if (supplierId) {
                      setSelectPostingInventoryModal(true);
                      setSelectVendorPostingInventory(true);
                      setSelectedVendorData(transaction);
                    } else {
                      Toast.info('Select posting type of vendor first.');
                    }
                  }}
                >
                  Select
                </span>
              ) : (
                <span
                  className={`felx items-center justify-center font-poppins text-base font-medium text-primary`}
                  onClick={() => {
                    setSelectPostingInventoryModal(true);
                    setSelectVendorPostingInventory(true);
                    setSelectedVendorData(transaction);
                  }}
                >
                  {`${postingInventoryCode}`} <Edit className="ml-2 !h-3 !w-3" />
                </span>
              )}
            </>
          );
        },
      });
    }
  }

  return (
    <TableContainer className={`${classes.tables} px-3`}>
      <Table data-autoid={'tblSupplier'}>
        <TableHead>
          <TableRow>
            <TableCell align="left" style={{width: '2%'}}></TableCell>
            <TableCell align="left" style={{width: '15%', padding: '0px 10px 0px 35px'}}>
              Suppliers
            </TableCell>
            <TableCell align="left" style={{width: t2tV2 ? '12%' : '15%', padding: '0px 10px'}}>
              ABN
            </TableCell>
            <TableCell align="left" style={{width: '15%', padding: '0px 10px'}}>
              Email
            </TableCell>
            <TableCell align="left" style={{width: '14%'}}>
              Reference ID
            </TableCell>
            <TableCell align="center" style={{width: '15%'}}>
              Post as
            </TableCell>
            {t2tV2 && (
              <TableCell align="center" style={{width: '15%'}}>
                Posting inventory
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {stagedTransactions &&
            stagedTransactions.map((transaction, index) => {
              const isVendorDataAvailable =
                transaction?.transactionsList?.filter(
                  item => item?.stagedDebitNote?.vendorName || item?.stagedPurchaseInvoice?.vendorName,
                ).length > 0;
              return (
                <>
                  <TableRow className={`w-full`} key={index}>
                    <TableCell
                      className={`h-[40px] ${
                        breakDownSupplierId === transaction?.sourceSupplier?.supplierID
                          ? 'bg-[#eaf0f4]'
                          : 'bg-spenda-cream'
                      }`}
                    >
                      <span
                        data-autoid={`lnkSelectAllToggler-${transaction?.sourceSupplier?.supplierID}`}
                        className={`mx-auto flex w-[22px] items-center justify-center font-poppins text-base font-medium text-black-800`}
                        onClick={() => handleBulkInvoices(transaction)}
                      >
                        <SelectToggler
                          dataAutoId={`imgSelectToggler${index}`}
                          isAccountsPayable
                          className="!m-0"
                          isSelected={
                            transaction?.sourceSupplier?.supplierID &&
                            selectedInvoices?.[transaction?.sourceSupplier?.supplierID]?.MessageIds.length ===
                              transaction?.transactionsList?.length
                              ? true
                              : false
                          }
                        />
                      </span>
                    </TableCell>
                    <TableCell
                      align="left"
                      className={`pr-2.5 ${
                        breakDownSupplierId === transaction?.sourceSupplier?.supplierID ? 'bg-[#eff5f9]' : 'bg-white'
                      }`}
                    >
                      <div className={`flex items-center`}>
                        <div className="flex items-center justify-center bg-[#FAFAFA]">
                          <span
                            data-autoid={`ddlLinkedSupplier-${transaction?.sourceSupplier?.supplierID}`}
                            onClick={() => {
                              setBreakDownSupplierId(
                                transaction?.sourceSupplier?.supplierID === breakDownSupplierId
                                  ? undefined
                                  : transaction?.sourceSupplier?.supplierID,
                              );
                              setBreakdownVendorToggle(
                                transaction?.sourceSupplier?.supplierID === breakDownSupplierId
                                  ? false
                                  : transaction?.isSourceSupplierVendorMappingEnabled,
                              );
                            }}
                            className={`items-left ml-[0px] flex h-[14px] min-w-[15px] cursor-pointer justify-center rounded-[3px] !border-[#1579af] bg-[#F1F1F1] ${classes.expandArrow}`}
                          >
                            <img
                              src={supplierViewDropdown}
                              className={`!w-[9px] ${
                                breakDownSupplierId === transaction?.sourceSupplier?.supplierID
                                  ? 'rotate-expand-more-icon h-[11px]'
                                  : 'h-[13px]'
                              }`}
                            />
                          </span>
                        </div>
                        <div className="ml-2.5 flex items-center justify-between" style={{width: 'calc(100% - 14px)'}}>
                          <p className="max-w-[80%] truncate pr-3">{`${transaction?.sourceSupplier?.name}`}</p>
                          {!transaction?.isSourceSupplierPosted && (
                            <span
                              className={`flex h-[40px] min-w-[36px] items-center justify-center bg-[#F4E8D1] pr-0`}
                            >
                              <VectorIcon data-autoid={`imgError${index}`} width="16px" height="16px" />
                            </span>
                          )}
                        </div>
                      </div>
                    </TableCell>
                    <TableCell
                      data-autoid={`lblAbn${index}`}
                      align="left"
                      className={`${
                        breakDownSupplierId === transaction?.sourceSupplier?.supplierID
                          ? 'bg-[#eaf0f4]'
                          : 'bg-spenda-cream'
                      }`}
                    >
                      {transaction?.sourceSupplier?.abn || '-'}
                    </TableCell>
                    <TableCell
                      data-autoid={`lblEmailAddress${index}`}
                      align="left"
                      className={`px-2.5 ${
                        breakDownSupplierId === transaction?.sourceSupplier?.supplierID ? 'bg-[#eff5f9]' : 'bg-white'
                      }`}
                    >
                      {transaction?.sourceSupplier?.emailAddress || '-'}
                    </TableCell>
                    <TableCell
                      data-autoid={`lblRefNumber${index}`}
                      align="left"
                      className={`${
                        breakDownSupplierId === transaction?.sourceSupplier?.supplierID
                          ? 'bg-[#eaf0f4]'
                          : 'bg-spenda-cream'
                      }`}
                    >
                      {transaction?.sourceSupplier?.refNumber || '-'}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={`action px-2.5 ${
                        breakDownSupplierId === transaction?.sourceSupplier?.supplierID ? 'bg-[#eff5f9]' : 'bg-white'
                      }`}
                    >
                      <div className="flex items-center justify-center">
                        {!transaction?.isSourceSupplierPosted ? (
                          <>
                            <span
                              data-autoid={`lnkSelectPostingType${index}`}
                              onClick={() =>
                                handleSelectPostingType(true, transaction?.sourceSupplier, isVendorDataAvailable)
                              }
                              className="cursor-pointer font-semibold"
                            >
                              Select Posting Type
                            </span>
                            <div className="flex h-[25px] w-[25px] items-center justify-center">
                              <Arrow data-autoid={`imgArrow${index}`} width="19px" height="15px" />
                            </div>
                          </>
                        ) : (
                          <span className="pr-[4px] font-semibold">{`${transaction?.sourceSupplier?.name}`}</span>
                        )}
                      </div>
                    </TableCell>
                    {t2tV2 && (
                      <TableCell
                        align="center"
                        className={`action px-2.5 ${
                          breakDownSupplierId === transaction?.sourceSupplier?.supplierID
                            ? 'bg-[#eaf0f4]'
                            : 'bg-spenda-cream'
                        }`}
                      >
                        <span
                          data-autoid={`lnkSupplierPostingInventory${index}`}
                          className="cursor-pointer font-semibold"
                          onClick={() => {
                            if (transaction?.isSourceSupplierPosted) {
                              setSelectPostingInventoryModal(true);
                              setSelectSourceSupplierData(transaction);
                            } else {
                              Toast.info('Select posting type of supplier first.');
                            }
                          }}
                        >
                          {transaction?.sourceSupplierPostingInventoryCode ? (
                            <span className="flex items-center justify-center">
                              {transaction?.sourceSupplierPostingInventoryCode}
                              <Edit className="ml-2 !h-3 !w-3" />
                            </span>
                          ) : (
                            'Select'
                          )}
                        </span>
                      </TableCell>
                    )}
                  </TableRow>
                  <TableRow>
                    <TableCell
                      colSpan={8}
                      className={`!p-0 ${
                        breakDownSupplierId === transaction?.sourceSupplier?.supplierID ? '!bg-[#eff5f9]' : '!bg-white'
                      }`}
                    >
                      {breakDownSupplierId && breakDownSupplierId === transaction?.sourceSupplier?.supplierID && (
                        <div>
                          {transaction?.isSourceSupplierPosted && (
                            <div className="mb-1 mr-9 mt-1 flex items-center justify-end">
                              {t2tV2 && transaction?.isSourceSupplierVendorMappingEnabled && (
                                <h1
                                  className="mr-6 cursor-pointer font-poppins text-base font-semibold !text-primary"
                                  data-autoid={`lblCreditlineAccount`}
                                  onClick={() => {
                                    setShowCreditlineModal(true);
                                    setSupplierCreditLineAccount(transaction?.sourceSupplierCreditLineAccountID);
                                  }}
                                >
                                  {!transaction?.sourceSupplierCreditLineAccountID
                                    ? 'Setup creditline account'
                                    : 'View creditline account'}
                                </h1>
                              )}
                              <h1 className="mr-0 font-poppins text-base font-semibold text-spenda-labeltext">
                                Vendor mapping
                              </h1>
                              <APTooltip
                                title={`Can be turned off from Supplier Module only.`}
                                placement="bottom"
                                arrow
                              >
                                <div className="mr-2 flex items-center">
                                  <SwitchSlider
                                    dataAutoId={`chkVendorMapping${index}`}
                                    label=""
                                    checked={transaction?.isSourceSupplierVendorMappingEnabled}
                                    name="vendorMapping"
                                    handleChange={() => {
                                      transaction.sourceSupplierCreditLineAccountID &&
                                        setSupplierCreditLineAccount(transaction.sourceSupplierCreditLineAccountID);
                                      !transaction?.isSourceSupplierVendorMappingEnabled &&
                                        handleVendorMapping(breakDownSupplierId, true);
                                    }}
                                    labelPlacement="start"
                                    disabled={transaction?.isSourceSupplierVendorMappingEnabled ? true : false}
                                  />
                                </div>
                              </APTooltip>
                            </div>
                          )}
                          <div className="mx-auto mb-5 mt-2 max-h-[260px] w-[calc(100%-72px)] overflow-y-auto">
                            <ARTable
                              isHighlightRowOnHover
                              tableClass={`${classes.expandableTable} !mx-0`}
                              rows={transaction?.transactionsList}
                              isLoading={false}
                              columns={columns}
                              scope="AP"
                            />
                          </div>
                        </div>
                      )}
                    </TableCell>
                  </TableRow>
                </>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
