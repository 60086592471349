import React from 'react';

export const InfoRound = (props: {style?: {width?: string; height?: string; color?: string}}) => {
  const {width = '30px', height = '30px', color = '#1C78AD'} = props.style || {};
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 100 100"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Icons/General/info" fill={color}>
          <path
            d="M50,0 C22.3857625,0 0,22.3857625 0,50 C0,77.6142375 22.3857625,100 50,100 C77.6142375,100 100,77.6142375 100,50 C99.9701465,22.3981377 77.6018623,0.0298535317 50,0 Z M50,91.6666667 C26.9881354,91.6666667 8.33333333,73.0118646 8.33333333,50 C8.33333333,26.9881354 26.9881354,8.33333333 50,8.33333333 C73.0118646,8.33333333 91.6666667,26.9881354 91.6666667,50 C91.6414068,73.0013935 73.0013935,91.6414068 50,91.6666667 L50,91.6666667 Z"
            id="Shape"
          ></path>
          <path
            d="M50,41.6666667 L45.8333333,41.6666667 C43.5321469,41.6666667 41.6666667,43.5321469 41.6666667,45.8333333 C41.6666667,48.1345198 43.5321469,50 45.8333333,50 L50,50 L50,75 C50,77.3011865 51.8654802,79.1666667 54.1666667,79.1666667 C56.4678531,79.1666667 58.3333333,77.3011865 58.3333333,75 L58.3333333,50 C58.3333333,45.3976271 54.6023729,41.6666667 50,41.6666667 Z"
            id="Path"
          ></path>
          <circle id="Oval" cx="50" cy="27.0833333" r="6.25"></circle>
        </g>
      </g>
    </svg>
  );
};
