import {ILinkedTran} from './../requisition/Requisition';
import {IRefBusTran, IAttachment} from './../invoice/Invoice';
import {IPaymentT} from './../payment/PaymentT';
import {IAddress} from './../address/Address';
import {IContact} from './../people/Contact';
import {IWarehouse} from './../Warehouse';
import {SalesOrderInvoicingStatus} from '../constants/Constants';

export interface IClaimRequestLines {
  ClaimRequestID: any;
  ClaimRequestLineID: any;
  ClaimRequestStatus: string;
  Comment: string;
  IsActive: boolean;
  IsReturnToStock: boolean;
  LineTotalEx: number;
  LineTotalInc: number;
  LineTotalTax: number;
  Quantity: number;
  SellPriceEx: number;
  SellPriceInc: number;
  SellPriceTax: number;
  TransDate: Date;
  WarehouseID: any;
  WarehouseName: string;
}

export interface ISalesOrderLine {
  GUIDstring?: string;
  AccountCode?: string;
  AcquisitionMethod?: string;
  AcquisitionWarehouseID?: any;
  AdjustedQuantityReceived?: any;
  AssetID?: any;
  Attachments?: any[];
  Barcode?: string;
  CalculatedBuyPriceTax?: any;
  ClaimRequestLines?: IClaimRequestLines[];
  Code?: string;
  Comment?: string;
  CommentFromOtherParty?: any;
  CostPriceEx?: number;
  CostPriceExString?: string;
  CostPriceInc?: number;
  CostPriceTax?: number;
  CurrentSuppliersInventoryGUID?: any;
  DatTypeID?: number;
  DefaultImageURL?: any;
  DefaultThumbnailURL?: any;
  DeliveryMethod?: string;
  DeliveryMethodDescription?: string;
  Description?: string;
  DiscountPercentage?: any;
  EAN?: string;
  ExpectedBuyPriceEx?: any;
  FeeGUID?: any;
  FeeGUIDstring?: any;
  FeeID?: any;
  FeePercentage?: number;
  GUID?: string;
  HasPriceBeenEdited?: boolean;
  ID?: number;
  InventoryGUID?: any;
  InventoryGUIDstring?: any;
  InventoryID?: any;
  InventoryType?: string;
  IsActive?: boolean;
  IsAmendedByOtherParty?: boolean;
  IsAssetItem?: boolean;
  IsCanUpdateInventoryOnMerge?: boolean;
  IsCreatedByOtherParty?: boolean;
  IsDiscontinued?: boolean;
  IsOnSpecial?: boolean;
  IsPhysical?: boolean;
  IsPriceRefresh?: boolean;
  IsRejected?: boolean;
  IsSpecialPrice?: boolean;
  IsTaxable?: boolean;
  IsVariantMaster?: boolean;
  ItemID?: any;
  LabourRateID?: any;
  LineID?: number;
  LineTotalEx?: number;
  LineTotalExString?: string;
  LineTotalInc?: number;
  LineTotalIncString?: string;
  LineTotalTax?: number;
  LineTotalTaxString?: string;
  LineType?: string;
  MaxOrderQuantity?: number;
  MinOrderQuantity?: number;
  OrderTotalInc?: number;
  OrderTotalIncDesc?: string;
  OriginalLine?: any;
  PostingInventoryCode?: any;
  PostingInventoryID?: any;
  PriceDescription?: string;
  PriceType?: string;
  PricingMode?: string;
  Quantity?: number;
  QuantityCancelled?: any;
  QuantityFaulty?: any;
  QuantityInCart?: number;
  QuantityInCartString?: string;
  QuantityIncorrect?: any;
  QuantityOrdered?: any;
  QuantityOutofDate?: any;
  QuantityReceived?: any;
  QuantityRefunded?: any;
  SOH?: any;
  RefBarCode?: string;
  RefInventoryCode?: string;
  ReturnInNumberOfWeeks?: number;
  ReturnReason?: string;
  SKU?: string;
  SellPriceEx?: number;
  SellPriceExString?: string;
  SellPriceInc?: number;
  SellPriceIncString?: string;
  SellPriceTax?: number;
  SellPriceTaxString?: string;
  SequenceNumber?: number;
  ShortDescription?: string;
  StandardSellPriceEx?: any;
  StandardSellPriceInc?: number;
  SupplierInventoryGUID?: any;
  SupplierInventoryGUIDString?: string;
  TaxRate?: number;
  TaxType?: string;
  Title?: string;
  TotalQuantityReceived?: any;
  UoM?: string;
  UoMDescription?: string;
  Warehouse?: IWarehouse;
  WarehouseID?: number;
}

export enum SalesOrderReservationStatus {
  Active = 'Active',
}

export interface ISalesOrder {
  AcceptedDateTime_utc?: any;
  AccountBalance?: any;
  AccountBalanceString: string;
  AccountCustomerID: any;
  AccountCustomerName: string;
  AccountCustomerRefNumber: string;
  AttachmentCount: number;
  Attachments: IAttachment[];
  AvailableCredit?: any;
  AvailableCreditString: string;
  Balance: number;
  BillCity: string;
  BillCountry: string;
  BillLatitude?: any;
  BillLongitude?: any;
  BillPostCode: string;
  BillState: string;
  BillStreetAddress: string;
  BillingAddress: IAddress;
  BillingAddressID?: any;
  BusinessContactID: number;
  CashPaymentRounding?: any;
  Contact: IContact;
  ContactCustomerName: string;
  ContactEmailAddress: string;
  ContactFirstName: string;
  ContactLastName: string;
  ContactPhone: string;
  ContractID?: any;
  CostCentreCode: string;
  CreatedByUserName: string;
  CreatedDateTime: Date;
  CreatedDateTime_offset: number;
  CreatedDateTime_utc: Date;
  CreatedWith: string;
  Currency: string;
  CustomerBSID: string;
  CustomerGUIDstring?: any;
  CustomerID?: any;
  CustomerName: string;
  CustomerRefNumber: string;
  CustomerRefTransRefNumber: string;
  DatTypeID: number;
  DeliveryDate?: any;
  DeliveryMethod: string;
  DeliveryNote: string;
  Description?: any;
  Discount: number | string;
  DiscountMode: string;
  DiscountString: string;
  DisplayAddress: string;
  DueDate?: any;
  DueDateString?: any;
  ExchangeRate?: any;
  ExternalNotes?: any;
  ExternalTerminalName: string;
  FinalisedDateTime_utc?: any;
  FreightTotalEx: number;
  FreightTotalTax: number;
  GUID: string;
  GUIDstring: string;
  HasBeenAmended: boolean;
  HasBeenAmendedByOtherParty: boolean;
  ID: number;
  InboxID?: any;
  InternalNote?: any;
  InvoiceStatus?: string;
  InvoicingStatus?: SalesOrderInvoicingStatus;
  IsAccountCustomer: boolean;
  IsActive: boolean;
  IsCanCancel: boolean;
  IsCanDelete: boolean;
  IsChargeToAccount: boolean;
  IsCreatedByOtherParty: boolean;
  IsIndividual?: any;
  IsLinkedToOtherParty: boolean;
  IsMessagesSendable: boolean;
  IsMoreLinesToGet: boolean;
  IsProcessed: boolean;
  IsReadOnly: boolean;
  IsSendable: boolean;
  IsSent: boolean;
  IsSentToUnlinked: boolean;
  IsSeparateLines: boolean;
  LastLineRowNumber: number;
  LineCount: number;
  Lines: ISalesOrderLine[];
  LinesWithUnmappedInventoryCount: number;
  LinkedTrans: ILinkedTran[];
  OriginalTotalEx: number;
  OriginalTotalInc: number;
  OriginalTotalTax: number;
  PickingStatus?: string;
  PackingStatus?: string;
  POSDeviceID?: any;
  POSTerminalShiftID?: any;
  Payments: IPaymentT[];
  QuantityCount: number;
  ReceivedDateTime_utc?: any;
  RefBusTrans: IRefBusTran[];
  RefNumber: string;
  RefTransID?: any;
  RefTransRefNumber: string;
  RepEmailAddress: string;
  RepFirstName: string;
  RepLastName: string;
  RepUserID: number;
  RepUserName: string;
  Reserve: {
    status: string;
  } | null;
  Reservation: {
    Status: SalesOrderReservationStatus;
  } | null;
  ReservationStatus: SalesOrderReservationStatus | null;
  RowNumber?: any;
  SentDateTime_utc?: any;
  ShipCity: string;
  ShipContactEmailAddress: string;
  ShipContactFirstName: string;
  ShipContactLastName: string;
  ShipContactPhone: string;
  ShipCountry: string;
  ShipCustomerName: string;
  ShipLatitude?: any;
  ShipLongitude?: any;
  ShippingMode?: string;
  ShipPostCode: string;
  ShipState: string;
  ShipStreetAddress: string;
  ShippingAddress: IAddress;
  ShippingAddressID?: any;
  Status: string;
  TerritoryID?: any;
  TerritoryName: string;
  Token?: any;
  TotalEx: number;
  TotalExString: string;
  TotalInc: number;
  TotalIncString: string;
  TotalLineCount: number;
  TotalOwing: number;
  TotalOwingString: string;
  TotalPaid: number;
  TotalPaidString: string;
  TotalRefund: number;
  TotalTax: number;
  TotalTaxString: string;
  TransDate: Date;
  TransDateString: string;
  TransType?: any;
  UseIncPrices: boolean;
  Version?: any;
  WarehouseCode: any;
  WarehouseID: number;
  WarehouseName: string;
  WasSentWhenLinked: boolean;
  WasSentWhenUnlinked: boolean;
}
