import React from 'react';

export const PackageType = (props: {width?: string; height?: string}) => {
  return (
    <svg
      width={props.width || '56px'}
      height={props.height || '53px'}
      viewBox="0 0 56 53"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Group 2</title>
      <g id="Updated-Sales-Order" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Pack-Items-Copy-7" transform="translate(-692.000000, -312.000000)" fill="#1C78AD" fillRule="nonzero">
          <g id="Group-6" transform="translate(423.000000, 271.000000)">
            <g id="Group" transform="translate(269.000000, 41.000000)">
              <path
                d="M28.581916,0.645502907 C28.581916,0.323355627 28.3207018,0.0621425371 27.9985556,0.0621425371 C27.6764083,0.0621425371 27.4151953,0.323356721 27.4151953,0.645502907 L27.4151953,8.11947337 L25.7431418,5.22337667 C25.5820901,4.94528226 25.2260106,4.85041699 24.9479162,5.01146876 C24.6698218,5.17252052 24.5749565,5.52859997 24.7360083,5.80669438 L27.4734202,10.547989 C27.6852854,10.9848098 28.3118368,10.9848098 28.523702,10.547989 L31.2611139,5.80669438 C31.4221656,5.52859997 31.3273002,5.17252052 31.049206,5.01146876 C30.7711116,4.85041699 30.4150321,4.94528248 30.2539803,5.22337667 L28.5819269,8.11947337 L28.5819269,0.645502907 L28.581916,0.645502907 Z"
                id="Path"
              ></path>
              <path
                d="M27.4151865,27.1418673 L27.4151865,50.7691512 L9.34876262,45.8684827 L9.34876262,31.942543 L8.18197335,31.6280609 L8.18197335,46.3054238 C8.17791011,46.5641398 8.35541173,46.8004214 8.61275067,46.8772437 L27.8262161,52.0890365 C27.9409334,52.1222804 28.0572432,52.1242182 28.1703242,52.0891657 L47.3843365,46.8774167 C47.6472734,46.806106 47.8191765,46.5659064 47.8150738,46.3054239 L47.8150738,30.7833388 L46.6482808,31.0978209 L46.6482808,45.8684828 L28.5818569,50.7691513 L28.5818569,26.8908461 L28.0472855,26.3825428 L27.4151351,27.1418554 L27.4151865,27.1418673 Z"
                id="Path"
              ></path>
              <path
                d="M39.6339626,42.4121655 L44.9254259,40.8006963 C45.3140179,40.6947207 45.6852975,40.9648051 45.6780206,41.3673919 L45.6780206,44.5620344 C45.6780206,44.8437033 45.4783422,45.0787784 45.2128218,45.1334245 L39.9214022,46.7448937 C39.5403902,46.8644335 39.1617024,46.569718 39.1687649,46.1783272 L39.1687649,42.9835534 C39.1687649,42.7018845 39.3684433,42.4668094 39.6339637,42.4121633 L39.6339626,42.4121655 Z M44.51129,42.1415634 L40.3354769,43.4132734 L40.3354769,45.4040747 L44.51129,44.1323209 L44.51129,42.1415634 Z"
                id="Shape"
              ></path>
              <path
                d="M11.485889,44.132345 L15.661735,45.404055 L15.661735,43.4132975 L11.485889,42.1415438 L11.485889,44.132345 L11.485889,44.132345 Z M16.0758709,46.7449134 L10.7843747,45.1334442 C10.5188544,45.0787985 10.319176,44.8437229 10.319176,44.5620541 L10.319176,41.3673241 C10.312286,40.9850629 10.7026435,40.66804 11.0718133,40.8007149 L16.3632766,42.4121841 C16.628797,42.4668297 16.8284754,42.7019053 16.8284754,42.9835742 L16.8284754,46.1782604 C16.835839,46.5853689 16.4661089,46.8445585 16.0758807,46.7449134 L16.0758709,46.7449134 Z"
                id="Shape"
              ></path>
              <path
                d="M29.4765143,15.3854297 L47.1512575,19.6980413 L51.7420925,17.1970816 L34.1561638,12.4571651 L29.4765143,15.3854188 L29.4765143,15.3854297 Z M27.9988903,24.9289204 L44.8971628,20.3449762 L27.9984528,16.2217518 L11.0996334,20.3450637 L27.9988903,24.9289314 L27.9988903,24.9289204 Z M48.2094408,20.4453082 L55.8169064,27.709777 C56.1610145,28.0141822 55.9929867,28.6040888 55.538683,28.7015375 L36.3342959,33.877717 C36.1210098,33.9448937 35.897906,33.8696644 35.7502027,33.7068475 L28.0473161,26.3825164 L21.0794133,34.7519602 C20.9421752,34.9391072 20.6654579,35.0057247 20.4498038,34.9345867 L1.2492449,29.7594354 C0.822070086,29.6402402 0.675099577,29.1212561 0.970719539,28.8011334 L7.72860663,20.6535071 L0.437602804,18.7949541 C-0.163671408,18.6398022 -0.135079043,17.7856243 0.460296442,17.6590691 L19.6627148,12.4834475 C19.7770011,12.4496007 19.9103213,12.4583423 20.021206,12.500543 L27.9172313,14.9871195 L33.725,11.3529874 C33.8699033,11.2501984 34.0430128,11.2213035 34.2142706,11.2661744 L53.4190952,16.4423976 C53.9465189,16.556684 54.045733,17.2869722 53.57386,17.5229519 L48.2094091,20.4453101 L48.2094408,20.4453082 Z M8.77197146,19.7160962 L25.8355133,15.552599 L19.8021426,13.6525701 L2.87904141,18.2138732 L8.77199333,19.7160962 L8.77197146,19.7160962 Z M26.971639,25.8588881 L8.97431978,20.9769888 L2.42738902,28.8702578 L20.4257801,33.7214111 L26.971639,25.8588881 L26.971639,25.8588881 Z M47.068415,20.9646182 L29.1499788,25.8251671 L36.3462836,32.6678436 L54.2661417,27.8378329 L47.068415,20.9646182 L47.068415,20.9646182 Z"
                id="Shape"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
