import React from 'react';
import {Box} from '@material-ui/core';
import {SModal} from '../modals/modalSpendaMeterialUI';
import {makeStyles} from '@material-ui/core/styles';
import {APFooter} from './APFooter';

const useSetupCompleteStyles = makeStyles({
  apOnboardingModal: {
    maxWidth: '800px',
  },
  boxHeight: {
    height: 'calc(100% - 57px)',
    overflow: 'hidden',
    '& h2': {
      fontFamily: 'Poppins, sans-serif !important',
      fontSize: '28px',
    },
    '& button': {
      fontFamily: 'Poppins, sans-serif !important',
    },
    '& p, button, span': {
      fontFamily: 'Poppins, sans-serif !important',
    },
    '& .modal-para': {
      fontSize: '16px',
      color: '#1c78ad',
      fontWeight: 500,
    },
  },
});
export interface ISetupCompleteProps {
  handleClose: () => void;
  onBackPress: () => void;
  onSetupComplete: () => void;
  isSetupCompleteLoading?: boolean;
}

export const SetupComplete = (props: ISetupCompleteProps) => {
  const classes = useSetupCompleteStyles();

  return (
    <SModal open>
      <div className={`text-xl spenda-color flex justify-center items-center h-full max-w-full`}>
        <div className={`modal-box ${classes.apOnboardingModal} px-5 py-5`}>
          <div className={`${classes.boxHeight} flex flex-row justify-center items-center relative`}>
            <Box className={`w-8/12`}>
              <h2 className="text-center pb-8">“Great! You're all set.”</h2>
              <p className={`text-center modal-para`}>
                “Your account payable setup has been completed! Tap 'Finish' to start using Spenda, and if you need more
                help along the way, just click on ‘Live Chat’ at any time.”
              </p>
            </Box>
          </div>
          <APFooter
            onNextClick={() => props.onSetupComplete()}
            buttonLabel={'Finish'}
            isDisabled={props.isSetupCompleteLoading}
            isLoading={props.isSetupCompleteLoading}
          />
        </div>
      </div>
    </SModal>
  );
};
