import React, {useContext} from 'react';
import {SpendaName} from '../assets/svg/SpendaName';
import AppContext from '../context/app/appContext';
import {css} from 'glamor';
import {LogoTypeEnum} from '../model/Tenant';
import {SpendaNoTagLogo} from '../assets/svg/SpendaNoTagLogo';
export interface LogoNameProps {
  isDefaultSpenda?: boolean;
  className?: string;
  textColor?: string;
}

const defaultProps: LogoNameProps = {
  isDefaultSpenda: false,
  textColor: '#FFFFFF',
};

export const LogoName: React.FC<LogoNameProps> = props => {
  const {marketplaceSupplier} = useContext(AppContext);

  const glassBorder = css({
    boxShadow: '0px 0px 0px 12px rgba(4, 82, 114, 0.98), 0px 20px 14px 0px rgba(0, 90, 125, 0.59)',
    borderRadius: '7px',
    maxWidth: '300px',
  });

  // 1. Supplier's Logo
  let tenantLogo = marketplaceSupplier && marketplaceSupplier.Logos?.find(l => l.LogoTypeID == LogoTypeEnum.Logo)?.URI;
  let tenantName = marketplaceSupplier && marketplaceSupplier.TenantName;

  // 3. White Logo
  let spendaLogo = (
    <div className="h-32 w-4/5 md:h-56">
      <SpendaNoTagLogo width={'100%'} height={'100%'} />
    </div>
  );

  if (props.isDefaultSpenda) {
    tenantLogo = undefined;
    tenantName = '';
  }

  return (
    <div className={`flex justify-center ${props.className}`}>
      {tenantLogo && !props.isDefaultSpenda ? (
        <img src={tenantLogo} alt="logo" width="133px" className={`mx-auto mb-12 ${glassBorder}`}></img>
      ) : (
        <></>
      )}
      {!tenantLogo && spendaLogo}
      {tenantName && !props.isDefaultSpenda ? (
        <h1 className={'font-poppins text-5xl text-primary'}>{tenantName}</h1>
      ) : (
        <></>
      )}
      {!tenantName && props.isDefaultSpenda && (
        <SpendaName fillbg="#000000" width="120px" height="35px" viewBox="0 0 238 85" />
      )}
    </div>
  );
};

LogoName.defaultProps = defaultProps;
