import {useState} from 'react';
import {IActionResults} from '../model/ActionResults';
import useHttp from '../hooks/useHttp';
import {IMasterAdaptor} from '../model/MasterAdaptor';
import {AccountUsage, PaymentProviderName} from '../model/constants/Constants';
import {IPaymentServicesResponse} from '../model/payment-services/PaymentServices';

interface IAdaptorType {
  Accounts: string | undefined;
  Adaptor: IMasterAdaptor;
  DatTypes: string | undefined;
  Schedules: string | undefined;
}

export interface ISavePaymentServiceMerchantPayload {
  paymentProviderName: string;
  paymentServiceName: string;
  status: string;
  deliveryMethod: string;
  payerFeePercentage: number;
  clearingAccountCode: string;
  paymentMethodID: number;
}

export interface ISendAMLKYCEmailPayload {
  Subject: string;
  To: string;
  WebsiteID: number;
  DatTypeID: number;
}

export interface IMerchantPaymentConfigResponse {
  status: string;
  isTermsAndConditionsAccepted: boolean;
  deliveryMethod: string;
  flatFee: number;
  percentageFee: number;
  payerFeePercentage: number;
  clearingAccountCode: string;
  paymentMethods: number;
}

interface ICreateClearingAccountPayload {
  BankAccountNumber: string;
  BankBSB: string;
  Code: string;
  Name: string;
}

const usePaymentServicesAPI = () => {
  const {GET, POST} = useHttp();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getAdaptorByType = (type: string): Promise<any> => {
    return GET(`adaptor/GetAdaptorByType?adaptorTypes=${type}`).then(
      (res: IActionResults<IAdaptorType>) => res.Value.Adaptor,
    );
  };

  const validateExistingAccountbycode = (value: string): Promise<any> => {
    return GET(`Accounts/ValidateExistingAccountbycode?code=${value}`).then(
      (res: IActionResults<boolean>) => res.Value,
    );
  };

  const savePaymentServiceMerchant = (value: ISavePaymentServiceMerchantPayload): Promise<any> => {
    setIsLoading(true);
    const payload = {
      status: value.status,
      isTermsAndConditionsAccepted: true,
      deliveryMethod: value.deliveryMethod,
      payerFeePercentage: value.payerFeePercentage,
      clearingAccountCode: value.clearingAccountCode,
      paymentMethodID: value.paymentMethodID,
    };
    return POST(
      `PaymentServices/Merchant/${value.paymentProviderName}/${value.paymentServiceName}/${value.paymentMethodID}`,
      payload,
    )
      .then((res: IActionResults<boolean>) => res.Value)
      .finally(() => {
        setIsLoading(false);
      });
  };

  const createClearingAccount = (createClearingAccPayload: ICreateClearingAccountPayload): Promise<any> => {
    const payload = {
      IsQueue: true,
      Object: {
        IsActive: true,
        BankAccountNumber: createClearingAccPayload.BankAccountNumber,
        BankBSB: createClearingAccPayload.BankBSB,
        Code: createClearingAccPayload.Code,
        Name: createClearingAccPayload.Name,
        Type: 'Bank',
        TaxType: 'BAS_Excluded',
      },
      websiteId: 1,
    };
    return POST(`accounts`, payload)
      .then((res: IActionResults<boolean>) => res.Value)
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getClearingAccounts = (): Promise<any> => {
    return GET(`accounts/GetAccounts?filter.types=Bank&filter.MaxResults=30`).then(
      (res: IActionResults<any>) => res.Value,
    );
  };

  const sendAMLKYCEmail = (payload: ISendAMLKYCEmailPayload): Promise<any> => {
    setIsLoading(true);
    return POST(`alert/send/`, payload)
      .then((res: IActionResults<boolean>) => res?.IsSuccess)
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getMerchantPaymentConfig = (
    paymentProviderName: string,
    paymentServiceName: string,
    paymentMethodID: number,
  ): Promise<IMerchantPaymentConfigResponse> => {
    return GET(`PaymentServices/Merchant/${paymentProviderName}/${paymentServiceName}/${paymentMethodID}`).then(
      (res: IMerchantPaymentConfigResponse) => res,
    );
  };

  const checkPsblPiblFlag = (paymentProviderName: PaymentProviderName, accountUsage: AccountUsage) => {
    return GET(`PaymentServices/Account/${paymentProviderName}/CheckConfiguration?accountUsage=${accountUsage}`).then(
      (res: IPaymentServicesResponse) => res,
    );
  };

  return {
    getAdaptorByType,
    validateExistingAccountbycode,
    savePaymentServiceMerchant,
    createClearingAccount,
    getClearingAccounts,
    sendAMLKYCEmail,
    getMerchantPaymentConfig,
    checkPsblPiblFlag,
    isLoading,
  };
};

export default usePaymentServicesAPI;
