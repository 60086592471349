import React from 'react';
//Icon
const IconCheckboxUnchecked = (props: {width?: string; height?: string}) => {
  return (
    <svg
      width={props.width || '20px'}
      height={props.height || '20px'}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>General/-23 Copy</title>
      <g id="Order-Listing" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Order-Listing-Concept/Picking-State" transform="translate(-30.000000, -293.000000)" fill="#1C78AD">
          <g id="General/-23-Copy" transform="translate(30.000000, 293.000000)">
            <path
              d="M11.5121035,0.455619355 L11.7450667,0.453333333 L11.7450667,0.453333333 L12.0222902,0.456571557 L12.0222902,0.456571557 L12.2732922,0.465112702 L12.2732922,0.465112702 C18.5081165,0.741217218 23.4784,5.88365503 23.4784,12.1866667 C23.4784,18.6018667 18.329608,23.8148107 11.9390946,23.918428 L11.7450667,23.92 C5.26506667,23.92 0.0117333333,18.6666667 0.0117333333,12.1866667 C0.0117333333,5.94797148 4.88110003,0.846285772 11.0264632,0.474979766 L11.2984625,0.461748246 L11.2984625,0.461748246 L11.5121035,0.455619355 L11.5121035,0.455619355 Z M11.962446,1.52217018 L11.7450667,1.52 L11.4625713,1.52366691 L11.4625713,1.52366691 L11.2328881,1.53207621 L11.1079783,1.53875285 L10.8620647,1.55610858 L10.8620647,1.55610858 L10.5213724,1.5894045 L10.5213724,1.5894045 L10.6177337,1.57900444 C8.05582082,1.84880044 5.76684912,3.02798596 4.07306667,4.78933333 L4.06816199,4.780311 C3.11257242,5.77055766 2.34684175,6.94529918 1.82960516,8.24602853 L1.83893333,8.25066667 C1.42086809,9.29812685 1.1587232,10.4286918 1.09408691,11.6116817 L1.0814866,11.931442 L1.0784,12.1866667 C1.0784,17.4661111 4.91122292,21.8467361 9.94465639,22.7020807 L10.1206009,22.7304842 C10.6502729,22.8113802 11.1927667,22.8533333 11.7450667,22.8533333 L11.9287993,22.8517836 C12.4177625,22.8435296 12.8987129,22.8023918 13.3695324,22.7304842 L13.5454769,22.7020807 C18.5789104,21.8467361 22.4117333,17.4661111 22.4117333,12.1866667 L22.4087234,11.9346298 C22.3798703,10.7272025 22.1445789,9.57069211 21.746047,8.49696565 L21.6512,8.25066667 L21.6605282,8.24602853 C21.1432916,6.94529918 20.3775609,5.77055766 19.4219713,4.780311 L19.4170667,4.78933333 C17.7985397,3.10624335 15.6365375,1.95473306 13.2122355,1.62032275 L12.8724087,1.5788454 L12.8724087,1.5788454 L12.7202814,1.56408497 L12.7202814,1.56408497 L12.3820684,1.53869985 L12.2575306,1.53212016 L12.176831,1.5285761 C12.105547,1.52573925 12.0340831,1.52360172 11.962446,1.52217018 Z"
              id="Shape"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default IconCheckboxUnchecked;
