import React from 'react';
//Icon
const IconDownloadClaim = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <title>Download Claim</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.40843 13.5915C7.83042 14.0138 8.40294 14.251 8.99993 14.251C9.59691 14.251 10.1694 14.0138 10.5914 13.5915L12.9997 11.1832C13.2679 10.8866 13.2562 10.4318 12.9731 10.1494C12.6901 9.8669 12.2352 9.85619 11.9392 10.125L9.74468 12.3202L9.74993 0.75C9.74993 0.335786 9.41414 0 8.99993 0C8.58571 0 8.24993 0.335786 8.24993 0.75L8.24318 12.306L6.06068 10.125C5.76762 9.83215 5.29265 9.83232 4.9998 10.1254C4.70695 10.4184 4.70712 10.8934 5.00018 11.1862L7.40843 13.5915Z"
        className="fill-primary"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.25 12C16.8358 12 16.5 12.3358 16.5 12.75V15.75C16.5 16.1642 16.1642 16.5 15.75 16.5H2.25C1.83579 16.5 1.5 16.1642 1.5 15.75V12.75C1.5 12.3358 1.16421 12 0.75 12C0.335786 12 0 12.3358 0 12.75V15.75C0 16.9926 1.00736 18 2.25 18H15.75C16.9926 18 18 16.9926 18 15.75V12.75C18 12.3358 17.6642 12 17.25 12Z"
        className="fill-primary"
      />
    </svg>
  );
};

export default IconDownloadClaim;
