import React, {useEffect, useState} from 'react';
import {useParams, useHistory} from 'react-router-dom';
import {Formik, Form} from 'formik';
import moment from 'moment';

import {AUTH_SELLING_SALES_ORDER_SHIP} from '../../routes/SalesOrderRoutes';
import {Layout} from '../../components/layout/Layout';
import LoadingIndicator from '../../components/ui/LoadingIndicator';
import {AlertDialogSlide} from '../../components/dialog/AlertDialogSlide';
import {SalesOrderPackItems} from '../../components/sales-orders/SalesOrderPackItems';
import {usePickSlipAPI} from '../../services/usePickSlipAPI';
import {useConsignmentNoteAPI} from '../../services/useConsignmentNoteAPI';
import {Toast} from '../../utils/Toast';

import {Button, Chip, Typography} from 'spenda-ui-react';
import {Divider, makeStyles} from '@material-ui/core';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import {
  ConsignmentNoteInvoiceMode,
  CONSIGNMENT_NOTE_STATUS,
  DeliveryMethods,
  PICK_SLIP_STATUS,
  SalesOrderInvoicingStatus,
} from '../../model/constants/Constants';
import {IPickSlip} from '../../model/sales-order/PickSlip';
import {IConsignmentNote} from '../../model/sales-order/ConsignmentNote';

const useOrderPackStyles = makeStyles(() => ({
  // divider: {
  //   backgroundColor: '#EAEAEA',
  // },
  dialogFooter: {
    backgroundColor: '#ADADAD20',
    borderRadius: '8px',
    padding: '0.5rem',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

export const SalesOrderPack = () => {
  const classes = useOrderPackStyles();
  const history = useHistory();
  let {pickSlipID, consignmentNoteID} = useParams<{
    pickSlipID?: string | undefined;
    consignmentNoteID?: string | undefined;
  }>();

  const [readyToshipDisable, setReadyToshipDisable] = useState<boolean>(false);
  const [pickSlip, setPickSlipDetails] = useState<IPickSlip>();
  const [conNote, setConNoteDetails] = useState<IConsignmentNote>();
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState<boolean>(false);

  const {getPickSlipById, isLoading: pickLoading} = usePickSlipAPI();
  const {getConsignmentNoteById, updateConsignmentNoteStatus, isLoading: consignmentLoader} = useConsignmentNoteAPI();

  const isLoading = pickLoading || consignmentLoader;

  useEffect(() => {
    if (pickSlipID) {
      getPickSlip();
    }
  }, [pickSlipID]);

  useEffect(() => {
    if (consignmentNoteID) {
      getConsignmentNote();
    }
  }, [consignmentNoteID]);

  const getPickSlip = async () => {
    const pickSlipRes = await getPickSlipById(pickSlipID);
    pickSlipRes.Lines = pickSlipRes.Lines.filter(
      _line => _line.DeliveryMethod !== DeliveryMethods.Cash_and_Carry && _line.QuantityPicked > 0,
    )?.map(_line => ({
      ..._line,
      QuantityPacked: 0,
    }));
    setPickSlipDetails(pickSlipRes);
  };

  const getConsignmentNote = async () => {
    const conNoteRes = await getConsignmentNoteById(consignmentNoteID);
    if (conNoteRes.Value) {
      setConNoteDetails(conNoteRes.Value);
    }
  };

  const onSubmit = (): void => {};

  const onKeyDown = (keyEvent: React.KeyboardEvent<HTMLFormElement>) => {
    if (keyEvent && keyEvent.key === 'Enter') {
      keyEvent.preventDefault();
    }
  };

  const handleMovetoShipping = async (
    invoiceMode: ConsignmentNoteInvoiceMode = ConsignmentNoteInvoiceMode.AllSalesOrderLines,
  ) => {
    const conNoteStatus = await updateConsignmentNoteStatus(consignmentNoteID, {
      Status: CONSIGNMENT_NOTE_STATUS.APPROVED,
      InvoiceMode: invoiceMode,
    });
    if (conNoteStatus.IsSuccess && pickSlipID && consignmentNoteID) {
      if (conNote?.SalesOrderInvoicingStatus === SalesOrderInvoicingStatus.Invoiced) {
        Toast.info('Items Packed');
      } else {
        Toast.info('Items Packed & Invoiced');
      }
      history.push(AUTH_SELLING_SALES_ORDER_SHIP.replace(/:consignmentNoteID/g, consignmentNoteID));
    }
  };

  const handleReadyToShip = () => {
    const showDialog =
      pickSlip?.LinkedTrans?.some(linkedTran => linkedTran.Status === PICK_SLIP_STATUS.PAUSED) &&
      [SalesOrderInvoicingStatus.NotInvoiced, SalesOrderInvoicingStatus.PartiallyInvoiced].includes(
        conNote?.SalesOrderInvoicingStatus as SalesOrderInvoicingStatus,
      );
    if (showDialog) {
      setOpenConfirmationDialog(true);
    } else {
      handleMovetoShipping();
    }
  };

  const _pickSlip = (
    <div
      className={`flex h-full min-h-full w-full flex-col overflow-y-auto rounded-lg bg-white`}
      id="sales-orders-list"
    >
      <Formik enableReinitialize initialValues={pickSlip || ({} as any)} onSubmit={onSubmit}>
        {fProps => {
          return (
            <Form onKeyDown={onKeyDown} onSubmit={fProps.handleSubmit} className="flex h-full flex-col">
              <div className={`flex w-full justify-between px-5 pb-3 pt-4`}>
                <div className="flex">
                  <Typography variant="h2" className={`text-#4D4D4D font-poppin my-auto font-medium`}>
                    {conNote?.Status === CONSIGNMENT_NOTE_STATUS.OPEN ? 'Ready to Pack' : 'Pack'}
                  </Typography>
                  {fProps.values?.ID ? (
                    <Chip
                      value={moment.utc(fProps?.values?.CreatedDateTime_utc).local().fromNow()}
                      className="my-auto ml-4 flex !h-6 items-center gap-5 border-[#C55D44] px-1 py-0 font-poppins text-[#C55D44]"
                      variant="outlined"
                      style={{borderColor: '#C55D44', color: 'black', padding: '0px 2px 0px 4px'}}
                      icon={<QueryBuilderIcon className="text-#C55D44 pb-1 pr-2 font-poppins" />}
                    ></Chip>
                  ) : null}
                </div>
                <div className="flex">
                  {conNote?.Status === CONSIGNMENT_NOTE_STATUS.OPEN ? (
                    <Button
                      type="button"
                      loading={isLoading}
                      size="sm"
                      disabled={readyToshipDisable || isLoading}
                      onClick={handleReadyToShip}
                    >
                      Ready To Ship
                    </Button>
                  ) : null}
                </div>
              </div>
              <Divider className="bg-[#EAEAEA]" />
              <div className="relative flex flex-1 flex-col items-start justify-start px-5 py-5">
                <div className="h-full w-full">
                  {fProps.values?.ID && conNote?.ID ? (
                    <SalesOrderPackItems
                      {...fProps}
                      conNote={conNote}
                      getConsignmentNote={getConsignmentNote}
                      setReadyToshipDisable={setReadyToshipDisable}
                    />
                  ) : null}
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );

  return (
    <>
      {openConfirmationDialog && (
        <AlertDialogSlide
          showTitleBottomBorder={true}
          titleColor="black"
          headerFontWeight={300}
          paddingY={60}
          paddingX={85}
          dialogWidth={'451px'}
          showCancel
          onCancel={() => setOpenConfirmationDialog(false)}
          title={
            <span className="> * + * divide-current font-poppins font-light text-spenda-primarytext">
              Generate invoice
            </span>
          }
          footer={
            <div className={classes.dialogFooter}>
              <Button
                variant="outlined"
                disabled={isLoading}
                onClick={() => handleMovetoShipping(ConsignmentNoteInvoiceMode.PickedLinesOnly)}
              >
                Invoice Picked Items
              </Button>
              <Button
                disabled={isLoading}
                onClick={() => handleMovetoShipping(ConsignmentNoteInvoiceMode.AllSalesOrderLines)}
              >
                Invoice All
              </Button>
            </div>
          }
        >
          <p className="text-center font-poppins text-xs">
            Would you like to invoice all ordered products or only products that are picked on the current pickslip?
          </p>
        </AlertDialogSlide>
      )}
      <div className={`relative h-full overflow-hidden bg-spenda-newbg`}>
        <Layout leftPanel={_pickSlip} splitWidthType={4} />
        <LoadingIndicator isLoading={isLoading} size="md" color="#1C78AD" />
      </div>
    </>
  );
};
