import React from 'react';

export const RefreshARMessages = (props: {width?: string; height?: string; className?: string; onClick?: any}) => {
  return (
    <svg
      width="16px"
      height="16px"
      className={props.className}
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Refresh</title>
      <g id="Main-File" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="Spenda-Collect-Supplier-Interface-Copy-18"
          transform="translate(-1052.000000, -857.000000)"
          className="fill-primary"
        >
          <g id="Refresh" transform="translate(1042.000000, 847.000000)">
            <g transform="translate(10.000000, 10.000000)" id="Path">
              <path d="M7.96953849,1.3336687 C9.75578398,1.33952879 11.4654602,2.05969569 12.7175385,3.3336687 L10.6362052,3.3336687 C10.2680153,3.3336687 9.96953849,3.63214554 9.96953849,4.00033537 L9.96953849,4.00033537 C9.96953849,4.3685252 10.2680153,4.66700204 10.6362052,4.66700204 L13.3982052,4.66700204 C14.0817813,4.66663422 14.6358374,4.11257815 14.6362052,3.42900204 L14.6362052,0.667002035 C14.6362052,0.298812202 14.3377283,0.000335368607 13.9695385,0.000335368607 L13.9695385,0.000335368607 C13.6013487,0.000335368607 13.3028718,0.298812202 13.3028718,0.667002035 L13.3028718,2.05233537 C11.0555269,0.0283650872 7.85777365,-0.552684427 5.04206195,0.551300378 C2.22635026,1.65528518 0.275683555,4.25492824 0.00287182792,7.26700204 C-0.014539243,7.45471531 0.0477778023,7.64109934 0.174589412,7.78059211 C0.301401022,7.92008488 0.481020159,7.99983065 0.669538495,8.0003894 L0.669538495,8.0003894 C1.00744918,8.0046419 1.29310949,7.75104256 1.32887183,7.41500204 C1.63646647,3.97549691 4.51630967,1.33821855 7.96953849,1.3336687 Z"></path>
              <path d="M15.2702052,8.00028133 C14.9322945,7.99602884 14.6466342,8.24962818 14.6108718,8.5856687 C14.3858289,11.1766552 12.6725956,13.3993657 10.2243037,14.2767037 C7.77601179,15.1540417 5.04098736,14.525351 3.22153849,12.667002 L5.30287165,12.667002 C5.67106166,12.667002 5.96953849,12.3685252 5.96953849,12.0003354 L5.96953849,12.0003354 C5.96953849,11.6321455 5.67106166,11.3336687 5.30287165,11.3336687 L2.54087183,11.3336687 C2.21247959,11.3334917 1.8974866,11.4638667 1.66527819,11.6960751 C1.43306978,11.9282835 1.30269484,12.2432765 1.30287165,12.5716687 L1.30287165,15.3336687 C1.30287165,15.7018585 1.60134866,16.0003354 1.96953849,16.0003354 L1.96953849,16.0003354 C2.33772833,16.0003354 2.63620516,15.7018585 2.63620516,15.3336687 L2.63620516,13.9483354 C4.88355006,15.9723056 8.08130334,16.5533552 10.897015,15.4493704 C13.7127267,14.3453856 15.6633934,11.7457425 15.9362052,8.7336687 C15.9536162,8.54595543 15.8912992,8.3595714 15.7644876,8.22007863 C15.637676,8.08058586 15.4580568,8.00084009 15.2695385,8.00028133 L15.2702052,8.00028133 Z"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
