import moment from 'moment';
import {useLocation} from 'react-router-dom';
import {TimelineCustomRange, TimelineCustomRangeDays} from '../model/constants/Constants';
import {IPurchaseInvoice} from '../model/purchase-invoice/purchaseInvoice';

export const checkDueDate = (
  utcOffset: number,
  timelineViewMode: TimelineCustomRange | undefined,
  column: number,
  inv: IPurchaseInvoice
): boolean => {
  if (column === -1) {
    return inv.TotalInc ? true : false;
  }
  if (!column && moment.utc().utcOffset(utcOffset).diff(inv.DueDate, 'days') <= 0) {
    return inv.TotalInc ? true : false;
  }
  switch (timelineViewMode) {
    case TimelineCustomRange.SevenToTwentyEightDays: {
      const differenceDays = moment.utc().utcOffset(utcOffset).startOf('day').diff(inv.DueDate, 'days');
      const days = TimelineCustomRangeDays[0].split('_');
      if (column === 4) {
        return differenceDays > Number(days[3]) ? true : false;
      } else {
        return differenceDays > Number(days[column - 1]) && differenceDays <= Number(days[column]) ? true : false;
      }
    }
    case TimelineCustomRange.ThirtyToNinetyDays: {
      const differenceDays = moment.utc().utcOffset(utcOffset).startOf('day').diff(inv.DueDate, 'days');
      const days = TimelineCustomRangeDays[1].split('_');
      if (column === 4) {
        return differenceDays > Number(days[3]) ? true : false;
      } else {
        return differenceDays > Number(days[column - 1]) && differenceDays <= Number(days[column]) ? true : false;
      }
    }
    case TimelineCustomRange.TwoToSixMonths: {
      const differenceMonths = moment.utc().utcOffset(utcOffset).startOf('day').diff(inv.DueDate, 'months');
      const months = TimelineCustomRangeDays[2].split('_');
      if (column === 4) {
        return differenceMonths >= Number(months[3]) ? true : false;
      } else {
        return differenceMonths >= Number(months[column - 1]) && differenceMonths < Number(months[column])
          ? true
          : false;
      }
    }
    case TimelineCustomRange.ThreeToNineMonths: {
      const differenceMonths = moment.utc().utcOffset(utcOffset).startOf('day').diff(inv.DueDate, 'months');
      const months = TimelineCustomRangeDays[3].split('_');
      if (column === 4) {
        return differenceMonths >= Number(months[3]) ? true : false;
      } else {
        return differenceMonths >= Number(months[column - 1]) && differenceMonths < Number(months[column])
          ? true
          : false;
      }
    }
    default:
      return false;
  }
};

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}
