import React, {useEffect, useState} from 'react';
import {Typography} from 'spenda-ui-react';
import useMerchantPortalAPI from '../../../services/useMerchantPortalAPI';
import {IHeaderWidget} from '../../../model/merchant-portal/MerchantPortal';
import Skeleton from 'react-loading-skeleton';

export const HeaderWidget = () => {
  const [merchantHeaderDetails, setMerchantHeaderDetails] = useState<IHeaderWidget>();

  const {getMerchantPortalHeaderDetail, isLoading} = useMerchantPortalAPI();

  useEffect(() => {
    fetchMerchantPortalHeaderDetail();
  }, []);

  const fetchMerchantPortalHeaderDetail = async () => {
    try {
      const res = await getMerchantPortalHeaderDetail();
      setMerchantHeaderDetails(res);
    } catch {}
  };

  return (
    <div className="relative mb-2.5 flex h-[214px] !w-full flex-row items-start justify-start rounded-[8px] bg-white p-2.5">
      <div className="flex flex-col items-start justify-start">
        <Typography variant="h1" className="text-black text-xl">
          Merchant data
        </Typography>
        <div className="mt-4 flex flex-col items-start justify-start">
          <Typography variant="paragraph" className="text-black text-sm font-semibold">
            Merchant Details
          </Typography>
          <Typography variant="paragraph" className="text-black text-sm font-normal">
            Business:{' '}
            {isLoading ? (
              <Skeleton width={120} height={10} />
            ) : (
              <span data-autoid="txtBusinessName">{merchantHeaderDetails?.value?.merchantDetails?.businessName}</span>
            )}
          </Typography>
          <Typography variant="paragraph" className="text-black text-sm font-normal">
            Merchant ABN:{' '}
            {isLoading ? (
              <Skeleton width={120} height={10} />
            ) : (
              <span data-autoid="txtABN">{merchantHeaderDetails?.value?.merchantDetails?.abn}</span>
            )}
          </Typography>
          <Typography variant="paragraph" className="text-black text-sm font-normal">
            Address:{' '}
            {isLoading ? (
              <Skeleton width={150} height={10} />
            ) : (
              <span data-autoid="txtAddress">{merchantHeaderDetails?.value?.merchantDetails?.address}</span>
            )}
          </Typography>
        </div>
        <div className="mt-3 flex flex-col items-start justify-start">
          <Typography variant="paragraph" className="text-black text-sm font-semibold">
            Settlement & Fees Bank Account
          </Typography>
          <Typography variant="paragraph" className="text-black text-sm font-normal">
            BSB:{' '}
            {isLoading ? (
              <Skeleton width={120} height={10} />
            ) : (
              <span data-autoid="txtBSB">{merchantHeaderDetails?.value?.merchantSettlementAccount?.bsb}</span>
            )}
          </Typography>
          <Typography variant="paragraph" className="text-black text-sm font-normal">
            Number:{' '}
            {isLoading ? (
              <Skeleton width={120} height={10} />
            ) : (
              <span data-autoid="txtSettlementAccountNumber">
                {merchantHeaderDetails?.value?.merchantSettlementAccount?.number}
              </span>
            )}
          </Typography>
        </div>
      </div>
    </div>
  );
};
