import React, {useState} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {omit} from 'lodash';

import {Button, Typography} from 'spenda-ui-react';
import {IBrand} from '../../model/inventory/Brand';
import {AUTH_INVENTORY_PRODUCTS_LIST} from '../../routes/InventoryRoutes';
import {Layout} from '../../components/layout/Layout';
import {STable} from '../../components/tables/STable';
import {BrandToolbar} from '../../components/inventory/InventoryToolbar';
import {BrandDialog} from '../../components/dialog/BrandDialog';
import {SPagination} from '../../components/tables/SPagination';
import useBrandAPI, {useLoadBrand} from '../../services/useBrandAPI';
import IconKeyboardBackspaceRounded from '../../assets/svg/IconKeyboardBackspaceRounded';

export const initialBrandData = {
  Name: '',
  Description: '',
  IsActive: true,
} as IBrand;

export const BrandsList = () => {
  const [open, setOpen] = useState(false);
  const [brandToEdit, setBrandToEdit] = React.useState<IBrand>(initialBrandData);
  const [selected, setSelected] = useState<number[]>([]);

  const {
    loading,
    totalCount,
    pageSize,
    currentPage,
    items: brands,
    searchFilter,
    setSearchFilter,
    getBrandsList,
  } = useLoadBrand();

  const {getBrandById} = useBrandAPI();

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = (refetchList: boolean = false) => {
    setOpen(false);
    setBrandToEdit(initialBrandData);
    if (refetchList) {
      if (searchFilter.IsShowActiveOnly) {
        getBrandsList();
      } else {
        setSearchFilter({
          ...omit(searchFilter, ['LastRowNumber']),
          ...{IsShowActiveOnly: true},
        });
      }
    }
  };

  const handleRowClick = (rowData: Partial<IBrand>) => {
    getBrandById(rowData).then((res: Partial<IBrand>) => {
      if (res) {
        setBrandToEdit({
          ID: res.ID!,
          Name: res.Name || '',
          Description: res.Description || '',
          IsActive: res.IsActive!,
        });
        openDialog();
      }
    });
  };

  const handlePageChange = (newPage: number) => {
    setSearchFilter({
      ...searchFilter,
      LastRowNumber: (newPage - 1) * pageSize,
    });
  };

  const handleRowsChange = (rows: number) => {
    setSearchFilter({
      ...searchFilter,
      MaxResults: rows,
      LastRowNumber: 0,
    });
  };

  const brandsListing = (
    <div
      className={`flex max-h-[calc(100vh-20.5em)] min-h-full w-full flex-col overflow-y-auto rounded-lg bg-white px-5 pt-4`}
      id="brands-list"
    >
      <div className={'flex w-full justify-between px-0 py-4'}>
        <div className=" flex">
          <RouterLink to={AUTH_INVENTORY_PRODUCTS_LIST}>
            <Button variant="text">
              <IconKeyboardBackspaceRounded />
            </Button>
          </RouterLink>
          <Typography className="mt-1 text-xl font-medium text-[#4D4D4D]">Brands</Typography>
        </div>
        <div className="flex">
          <Button size="sm" onClick={openDialog}>
            Add New Brand
          </Button>
        </div>
      </div>
      <BrandToolbar
        loading={loading}
        title={`Brands (${totalCount})`}
        searchFilter={searchFilter}
        data={brands}
        selected={selected}
        refetchList={getBrandsList}
        setSelected={setSelected}
        setSearchFilter={setSearchFilter}
      />
      <STable
        columns={[
          {title: 'Name', field: 'Name'},
          {title: 'Description', field: 'Description'},
        ]}
        data={brands}
        emptyDataMessage={'No Brands'}
        stickyHeader={true}
        selection={true}
        selected={selected}
        setSelected={setSelected}
        loading={loading}
        onRowClick={handleRowClick}
      ></STable>
      <SPagination
        currentPage={currentPage}
        totalCount={totalCount}
        pageSize={pageSize}
        onPageChange={handlePageChange}
        rowsPerPageOptions={[10, 15, 25]}
        onRowsPerPageChange={handleRowsChange}
      />
    </div>
  );

  return (
    <>
      <BrandDialog brand={brandToEdit} open={open} onClose={closeDialog} />
      <div className={`relative h-full overflow-hidden bg-spenda-newbg`}>
        <Layout leftPanel={brandsListing} splitWidthType={4} />
      </div>
    </>
  );
};
