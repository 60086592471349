import {css} from 'glamor';
import React from 'react';
import {IconANZ} from '../../assets/svg/bank-accounts/IconANZ';
import {IconCommonWealth} from '../../assets/svg/bank-accounts/IconCommonWealth';
import SpendaFinanceBackground from '../../assets/svg/SpendaFinanceBackground.svg';
import SpendaIcon from '../../assets/svg/SpendaIcon.svg';
import AirPlusIcon from '../../assets/svg/AirPlus_Icon.svg';
import {IconNAB} from '../../assets/svg/bank-accounts/IconNAB';
import {IconWestpac} from '../../assets/svg/bank-accounts/IconWestpac';
import {Amex} from '../../assets/svg/payment-methods/AMEX';
import {BankWhite} from '../../assets/svg/payment-methods/BankWhite';
import {MasterCardNew} from '../../assets/svg/payment-methods/MasterCardNew';
import {SpendaPayLater} from '../../assets/svg/payment-methods/SpendaPayLater';
import {Visa} from '../../assets/svg/payment-methods/Visa';
import {BankAccounts} from '../../model/constants/Constants';
import {ITenantSupplierPaymentMethod} from '../../model/payment/PaymentMethod';

export const cardIconbgCss = css({
  width: '42px',
  height: '30px',
  backgroundColor: '#ffffff15',
  borderRadius: ' 8px',
  display: 'flex',
  justifyContent: 'center !important',
  alignItems: 'center !important',
});

export const PaymentMethodThumbnailIcons = (props: {pm: ITenantSupplierPaymentMethod}) => {
  const {pm} = props;
  let bgGradientColor = '#dbdbdb';
  let fontColor = 'text-white';
  let paymentMethodIcon = undefined;

  const isBNPL = pm.PaymentMethod.toUpperCase() === 'INVIGOPAYLATER';
  const isBankTransfer = pm.PaymentMethod.toUpperCase() === 'BANKTRANSFER';
  const isSpendaFinance = pm.PaymentMethod.toUpperCase() === 'LENDINGFACILITY';
  const isAirPlus = pm.PaymentMethod.toUpperCase() === 'AIRPLUS';
  const capricornDemo77857 = false;

  const getBankTransferIconAndColor = () => {
    switch (pm.BankName) {
      case BankAccounts.NAB:
        bgGradientColor = 'linear-gradient(to right, #484C4F , #090F0F)';
        paymentMethodIcon = <IconNAB width="32px" height="32px" />;
        break;
      case BankAccounts.ANZ:
        bgGradientColor = 'linear-gradient(to right, #007DBA , #004165)';
        paymentMethodIcon = <IconANZ width="32px" height="32px" />;
        break;
      case BankAccounts.CBA:
        bgGradientColor = 'linear-gradient(to right, #216F86 , #1B3C41)';
        paymentMethodIcon = <IconCommonWealth width="32px" height="32px" />;
        break;
      case BankAccounts.WESTPAC:
        bgGradientColor = 'linear-gradient(to right, #DA1710 , #630202)';
        paymentMethodIcon = <IconWestpac width="32px" height="32px" />;
        break;
      default:
        bgGradientColor = 'linear-gradient(to right, #74B9D3 , #1F7290)';
        paymentMethodIcon = <BankWhite width="25px" height="25px" />;
        break;
    }
  };

  switch (pm.PaymentMethod.toUpperCase()) {
    case 'VISA':
      bgGradientColor = '#125a99';
      paymentMethodIcon = <Visa width="32px" height="32px" />;
      break;
    case 'MASTERCARD':
      bgGradientColor = 'linear-gradient(145deg, rgba(37,55,69,1) 0%, rgba(66,81,95,1) 67%)';
      paymentMethodIcon = <MasterCardNew width="30px" height="30px" />;
      break;
    case 'INVIGOPAYLATER':
      bgGradientColor = '#E7E7E7';
      paymentMethodIcon = <SpendaPayLater width="24.3px" height="32px" />;
      break;
    case 'AMERICANEXPRESS':
      bgGradientColor = 'linear-gradient(90deg, rgba(83,119,168,1) 0%, rgba(23,34,59,1) 100%)';
      paymentMethodIcon = <Amex width="25px" height="25px" />;
      break;
    case 'LENDINGFACILITY':
      bgGradientColor = `url(${SpendaFinanceBackground}), linear-gradient(to right, #3E3F5A, #252637)`;
      paymentMethodIcon = <img src={SpendaIcon} className="h-[30px]" />;
      break;
    case 'AIRPLUS':
      bgGradientColor = `#160F5B`;
      paymentMethodIcon = <img src={AirPlusIcon} className="h-[30px] -mb-1" />;
      break;
    case 'BANKTRANSFER':
      getBankTransferIconAndColor();
      break;
  }
  return (
    <div
      className={`relative flex justify-end items-end p-2 ${fontColor} self-center w-[80%] h-[90px] rounded-[10px] !bg-contain !bg-no-repeat !bg-center`}
      style={{
        background: bgGradientColor,
      }}
    >
      <div className="w-full flex justify-between items-end">
        {isBNPL ? (
          capricornDemo77857 ? (
            <div className="ml-3 mb-1">
              <p className="text-xs font-bold font-courierNew">Member name: Brisk Automotive</p>
              <p className="text-xs font-bold font-courierNew">Member number: 880324</p>
            </div>
          ) : null
        ) : isBankTransfer ? (
          <div>
            <p className="text-xs font-bold font-courierNew" data-autoid="txtBankBSB">
              BSB: {pm.BankBSB}
            </p>
            <p className="text-xs font-bold font-courierNew" data-autoid="txtBankACC">
              ACC: {pm.BankAccountNumber}
            </p>
          </div>
        ) : isSpendaFinance ? (
          <div>
            <p className={`text-xs font-medium font-poppins`}>{`Spenda Finance`}</p>
          </div>
        ) : (
          ''
        )}
        <>
          <div>
            {!isBNPL && !isBankTransfer && !isSpendaFinance && (
              <p className="text-xs tracking-widest font-courierNew font-bold" data-autoid="txtCreditCardLastFour">
                •••• {`${pm.Last4}`}
              </p>
            )}
          </div>
          <div className={`${isBNPL || isSpendaFinance || isAirPlus ? '' : cardIconbgCss}`}>
            <span>{paymentMethodIcon}</span>
          </div>
        </>
      </div>
    </div>
  );
};
