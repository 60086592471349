import React from 'react';
export const IconCommonWealth = (props: {width?: string; height?: string}) => {
  return (
    <svg
      width={props.width || '1247px'}
      height={props.height || '523px'}
      viewBox="0 0 1247 523"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>commonwealth-bank-seeklogo.com</title>
      <defs>
        <polygon id="path-1" points="0 0 1247 0 1247 523 0 523"></polygon>
      </defs>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="commonwealth-bank-seeklogo.com">
          <path
            d="M796,227.346175 L717.535087,227.346175 L717.535087,207.28227 C717.535087,190.050499 713.987401,181.593922 702.039395,181.593922 C689.772894,181.593922 686.587938,190.050499 686.587938,207.28227 L686.587938,316.022972 C686.587938,333.268018 689.772894,341.70247 702.039395,341.70247 C714.367826,341.70247 717.535087,333.268018 717.535087,316.022972 L717.535087,283.285263 L796,283.285263 L796,370.559269 C772.059753,389.547848 740.046528,398 698.18206,398 C627.489323,398 605,371.267302 605,304.73426 L605,219.597631 C605,154.498357 627.489323,126 695.726991,126 C735.830886,126 769.940873,136.213386 796,154.856799 L796,227.346175"
            id="Fill-1"
            fill="#0A0B09"
          ></path>
          <path
            d="M891.744063,278.7611 L904.033618,185.6493 L918.824392,278.7611 L891.744063,278.7611 Z M1007.69719,325.30813 L967.642341,131 L852.396275,131 L816.91963,325.30813 C814.798455,337.645576 810.23793,345.042722 806,346.470709 L806,393 L896.331103,393 L896.331103,346.470709 C888.602073,345.406371 884.757444,341.534843 884.757444,333.428139 C884.757444,330.93138 885.420311,328.478969 885.795936,325.30813 L925.506092,325.30813 C925.86404,328.478969 926.553422,330.93138 926.553422,333.428139 C926.553422,340.337463 923.725189,344.169079 918.108495,345.814367 L918.139429,393 L1020,393 L1020,346.470709 C1014.73684,344.710117 1010.18073,337.290797 1007.69719,325.30813 L1007.69719,325.30813 Z"
            id="Fill-2"
            fill="#0A0B09"
          ></path>
          <g id="Group-7">
            <mask id="mask-2" fill="white">
              <use xlinkHref="#path-1"></use>
            </mask>
            <g id="Clip-4"></g>
            <path
              d="M1117.0237,345.965335 L1117.0237,392.428244 L1033.22389,392.428244 L1033.22389,345.965335 C1041.30754,341.753914 1043.06601,334.686167 1043.06601,321.998764 L1043.06601,200.531824 C1043.06601,187.853278 1041.30754,180.803245 1033.22389,176.582967 L1033.22389,130.802034 L1123.38875,130.802034 L1173.05117,263.207153 L1173.05117,200.531824 C1173.05117,187.140304 1171.97926,180.457828 1161.75178,176.582967 L1161.75178,130.802034 L1247,130.802034 L1247,176.582967 C1238.56642,180.803245 1237.51666,188.207551 1237.51666,200.531824 L1237.51666,392.428244 L1157.86719,392.428244 L1107.17271,267.794989 L1107.17271,321.998764 C1107.17271,335.748986 1108.22691,342.781306 1117.0237,345.965335"
              id="Fill-3"
              fill="#0A0B09"
              mask="url(#mask-2)"
            ></path>
            <polyline
              id="Fill-5"
              fill="#0A0B09"
              mask="url(#mask-2)"
              points="261.702214 523.265705 523.404429 261.617353 458.296685 196.550681 376.538827 278.285899 261.702214 523.265705"
            ></polyline>
            <polyline
              id="Fill-6"
              fill="#EBBF28"
              mask="url(#mask-2)"
              points="261.702214 0 0 261.617353 261.702214 523.265705 376.538827 278.285899 458.296685 196.550681 261.702214 0"
            ></polyline>
          </g>
        </g>
      </g>
    </svg>
  );
};
