import stock_pricing from './stock-pricing.png';
import stock_productDetails from './stock-productdetails.png';
import stock_selectStock from './stock-selectstock.png';
import stock_transferDetails from './stock-transferdetails.png';
import stock_variations from './stock-variations.png';

export const ReceiveStockOnboardingImages = {
  stock_pricing,
  stock_productDetails,
  stock_selectStock,
  stock_transferDetails,
  stock_variations,
};
