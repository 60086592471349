import React, {useEffect, useState} from 'react';
import {camelCase, upperFirst} from 'lodash';
import {
  ClickAwayListener,
  Grow,
  IconButton,
  InputAdornment,
  Popper,
  Tooltip,
  TooltipProps,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import {bindPopper, bindToggle} from 'material-ui-popup-state/core';
import {usePopupState} from 'material-ui-popup-state/hooks';
import Done from '@material-ui/icons/Done';
import {CurrencyTextField} from '../inputs/STextField';
import UndoIcon from '@material-ui/icons/Undo';
import {Button, Typography} from 'spenda-ui-react';

interface ISplitPaymentButtonProps {
  disabled?: boolean;
  title?: string;
  onAmountChanged?: (updatedAmount?: number | undefined) => void;
  actualInvoiceBalance: number;
  disableHoverListener?: boolean;
  amountToPay?: number;
  textFieldLabel?: string;
  buttonLabel?: string;
}

function CustomTooltip(props: TooltipProps) {
  return <Tooltip data-autoid="tooltipPartialPayText" arrow {...props} />;
}

export const SplitPaymentButton = (props: ISplitPaymentButtonProps) => {
  //Mobile Responsive property
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.between(320, 415));

  const {
    disabled,
    disableHoverListener,
    title,
    actualInvoiceBalance,
    onAmountChanged,
    amountToPay,
    textFieldLabel,
    buttonLabel,
  } = props;

  // State
  const [splitAmount, setSplitAmount] = useState(0);
  const [error, setError] = useState('');
  const splitInputPopup = usePopupState({variant: 'popover', popupId: 'splitInputPopup'});

  const handleClickAway = () => {
    splitInputPopup.close();
  };

  useEffect(() => {
    if (!amountToPay) {
      setSplitAmount(0);
    }
  }, [amountToPay]);

  const handleSplitAmountChange = () => {
    if (splitAmount > 0 && splitAmount <= actualInvoiceBalance) {
      setError('');
      splitInputPopup.close();
      onAmountChanged?.(splitAmount);
      return;
    }
    if (splitAmount > actualInvoiceBalance) {
      setError('Amount exceeds invoice total');
      return;
    }
    setError('Enter an amount between $0.00 and the total invoice amount');
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div>
        <CustomTooltip title={title ?? ''} disableHoverListener={disableHoverListener} placement="top" arrow>
          <div>
            <Button
              data-autoid={buttonLabel ? `btn${upperFirst(camelCase(buttonLabel))}` : 'btnSplit'}
              className="bg-white"
              variant="outlined"
              color="primary"
              disabled={disabled}
              {...bindToggle(splitInputPopup)}
            >
              {buttonLabel ?? 'Split'}
            </Button>
          </div>
        </CustomTooltip>
        <Popper {...bindPopper(splitInputPopup)} disablePortal={true} transition className={`z-10`} placement="top">
          {({TransitionProps}) => (
            <Grow {...TransitionProps} timeout={250}>
              <section
                className={`relative bottom-0 mb-[20px] h-[131px] w-[444px] max-w-[250px] rounded-[10px] border border-[#D6D2D2] bg-[#fff] pb-2.5 shadow-lg before:absolute before:left-[107px] before:top-[132px] before:z-0 before:block before:w-0 before:border-b-[0px] before:border-l-[15px] before:border-r-[15px] before:border-t-[15px] before:border-solid before:border-b-[#FFF] before:border-l-[transparent] before:border-r-[transparent] before:border-t-[#FFF] before:content-[''] after:absolute after:left-[105px] after:top-[131px] after:z-[1] after:block after:w-0 after:border-b-[0px] after:border-l-[15px] after:border-r-[15px] after:border-t-[15px] after:border-solid after:border-b-[#FFF] after:border-l-[transparent] after:border-r-[transparent] after:border-t-[#FFF] after:content-['']`}
              >
                <div className="rounded-lg bg-white">
                  <div className={`px-5 pt-3 text-center font-poppins font-bold ${matches && 'px-16'}`}>
                    <Typography variant="xsmall" className=" text-center text-black-800">
                      {textFieldLabel ?? 'Please enter a custom amount to partially pay this invoice.'}
                    </Typography>
                  </div>
                  <div className="flex items-center justify-around">
                    <div className={`m-4`}>
                      <CurrencyTextField
                        onKeyDown={evt => {
                          if (evt.key.toLowerCase() === 'enter') {
                            handleSplitAmountChange();
                          }
                        }}
                        fullWidth
                        id="ExpectedTotalInc"
                        name="ExpectedTotalInc"
                        variant="outlined"
                        placeholder="$0.00"
                        InputLabelProps={Object.assign({
                          'data-autoid': 'lblExpectedTotalInc',
                        })}
                        label="Amount"
                        title="Hint: Round up, and the exact invoice amount will be applied automatically."
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                name={'undoSplitAmout'}
                                data-autoid={'btnUndo'}
                                aria-label="click to restore invoice balance"
                                edge="end"
                                onClick={() => {
                                  setError('');
                                  setSplitAmount(actualInvoiceBalance);
                                  onAmountChanged?.(actualInvoiceBalance);
                                }}
                              >
                                {<UndoIcon color="secondary" />}
                              </IconButton>

                              <IconButton
                                onClick={handleSplitAmountChange}
                                name={'confirmSplitAmount'}
                                data-autoid={'btnConfirm'}
                                aria-label="click to confirm the amount"
                                edge="end"
                              >
                                {<Done color="secondary" />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        value={splitAmount}
                        onChange={e => setSplitAmount(Number(e.target.value))}
                        error={!!error}
                        helperText={error}
                      />
                    </div>
                  </div>
                </div>
              </section>
            </Grow>
          )}
        </Popper>
      </div>
    </ClickAwayListener>
  );
};
