import React, {useContext} from 'react';
import PublicShell from './PublicShell';
import {Link, useLocation, useHistory} from 'react-router-dom';
import AppContext from '../../context/app/appContext';
import {Formik, FormikHelpers} from 'formik';
import * as Yup from 'yup';
import {Toast} from '../../utils/Toast';
import {ApplicationInfo} from '../../model/constants/ApplicationInfo';
import ChangePasswordForm from '../../components/form/ChangePasswordForm';

export type ChangeFormValues = {
  newPassword: string;
  confirmPassword: string;
  token: string;
};

export const ChangePassword = () => {
  const appContext = useContext(AppContext);
  const [code, setCode] = React.useState('');
  const query = new URLSearchParams(useLocation().search);
  const h = useHistory();

  React.useEffect(() => {
    let codeQueryParam: string = '';

    codeQueryParam = query.get('Code') || '';

    if (!codeQueryParam) {
      codeQueryParam = query.get('code') || '';
    }

    setCode(codeQueryParam);
  }, []);

  const onSubmit = async (values: ChangeFormValues, formHelpers: FormikHelpers<ChangeFormValues>) => {
    if (!code) {
      Toast.info('Missing token');
      return;
    }

    let response = await appContext.changePassword(values.newPassword, ApplicationInfo.WebsiteId, code);

    if (response.IsSuccess) {
      Toast.info('Your password has been changed.');
      h.push('/');
    } else {
      Toast.info(response.Messages[0] ? response.Messages[0] : 'Something went wrong');
    }

    formHelpers.setSubmitting(false);
  };

  const validationSchema = Yup.object({
    newPassword: Yup.string().required('Password is required.').min(6, 'Password must be at least 6 characters.'),
    confirmPassword: Yup.string()
      .required('Confirm Password is required.')
      .oneOf([Yup.ref('newPassword'), null], "Passwords don't match."),
  });

  return (
    <PublicShell>
      <Formik
        initialValues={{newPassword: '', confirmPassword: '', token: ''}}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {props => <ChangePasswordForm {...props} />}
      </Formik>
      <div className={'mt-4 flex justify-center text-center font-poppins text-base font-semibold text-primary'}>
        <Link to="/">Cancel</Link>
      </div>
    </PublicShell>
  );
};
