import React, {useState, useEffect, useContext} from 'react';
import {useParams, useHistory, useLocation, matchPath} from 'react-router-dom';
import {css} from 'glamor';
import moment from 'moment';
import {Formik, FormikHelpers, getIn, setIn, validateYupSchema, yupToFormErrors} from 'formik';
import * as Yup from 'yup';
import {
  AUTH_BUYING_AP_APPROVE_REQUESTS,
  AUTH_PURCHASING_PAY_BILL_ROUTE,
  AUTH_PURCHASING_PAY_BILL_EDIT_ROUTE,
  AUTH_BUYING_AP_APPROVE_REQUESTS_BATCH_VIEW,
  AUTH_BUYING_AP_AUTHORISE_PAYMENT_BATCH_VIEW,
  AUTH_BUYING_AP_AUTHORISE_PAYMENT,
  AUTH_BUYING_AP_REPORTS,
} from '../../routes/AccountsPayableRoutes';
import {
  IPaymentBatch,
  IPaymentBatchCreditLine,
  IPaymentBatchDebitLine,
  IPurchaseInvoiceBatchActions,
  IPurchaseInvoiceRequest,
} from '../../model/payment-batch/PaymentBatch';
import {
  PaymentBatchPermissionType,
  PaymentBatchStatusTypes,
  PaymentProviderName,
  AccountType,
  VerificationStatus,
  DatTypes,
  PaymentWidgetScope,
  APErrorCodes,
  APOnboardingSteps,
} from '../../model/constants/Constants';
import {Layout} from '../../components/layout/Layout';
import {STextField} from '../../components/inputs/STextField';
import {SButton} from '../../components/buttons/Button';
import {BatchSplitButton, BatchSplitButtonType} from '../../components/purchasing/BatchSplitButton';
import {SearchInput} from '../../components/form/InvoiceSearchForm';
import {BatchStatus} from '../../components/purchasing/BatchStatus';
import {DiscardBatchModal} from '../../components/accountsPayableOnboarding/DiscardBatchModal';
import {usePaymentBatchAPI} from '../../services/usePaymentBatchAPI';
import useSpendaPaymentServicesAPI, {IAccountResponse} from '../../services/useSpendaPaymentServicesAPI';
import {PriceFormat} from '../../utils/formatter';
import {Toast} from '../../utils/Toast';
import SelectAPInvoice from '../../assets/svg/accounts-payable/select-ap-invoice.svg';
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import {
  makeStyles,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Popper,
  Grow,
  ClickAwayListener,
  MenuItem,
  Select,
  FormControl,
  FormHelperText,
  InputLabel,
  Grid,
  IconButtonProps,
  Collapse,
  TableSortLabel,
} from '@material-ui/core';
import {usePopupState, bindToggle, bindPopper} from 'material-ui-popup-state/hooks';
import {PayBatch} from './PayBatch';
import LoadingIndicator from '../../components/ui/LoadingOverlay';
import {IBatchReviewPurchaseInvoice} from '../../model/purchase-invoice/purchaseInvoice';
import supplierViewDropdown from '../../assets/svg/accounts-payable/supplierViewDropdown.svg';
import {SupplierStatement} from './CommonComponents';
import deleteIcon from '../../assets/svg/accounts-payable/delete-icon.svg';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import batchEditIcon from '../../assets/svg/accounts-payable/batch-edit-icon.svg';
import batchAddIcon from '../../assets/svg/accounts-payable/batch-add-icon.svg';
import searchIcon from '../../assets/svg/accounts-payable/ap-search-icon.svg';
import AppContext from '../../context/app/appContext';
import disableExclamation from '../../assets/svg/accounts-payable/red-exclamation.svg';
import expandIcon from '../../assets/svg/accounts-payable/report-expand-icon.svg';
import collapseIcon from '../../assets/svg/accounts-payable/report-collapsible-icon.svg';
import APTooltip from '../../components/data-display/APTootip';
import backButton from '../../assets/png/back-button.png';
import {pick} from 'lodash';
import {ApplicationInfo} from '../../model/constants/ApplicationInfo';
import usePaymentServicesAPI from '../../services/usePaymentServicesAPI';
import {AMLKYCModal} from '../../components/accountsPayableOnboarding/AmlKycModal';
import {PaymentWidgetModal} from '../pay-by-link/payment-widget/PaymentWidget';
import {usePaymentMethods} from '../pay-by-link/payment-widget/usePaymentMethods';
import APContext from '../../context/ap-context/APContext';
import _ from 'lodash';
import {Calendar} from '../../assets/svg/accounts-payable/Calendar';
import {useFeatureFlags} from '../../hooks/useFeatureFlags';
import {useOnboarding} from '../../services/useOnboarding';
import {ITenantUserDetails} from '../../model/Tenant';
import {IOnboardingV1Step, RemainingStepInfoModal} from '../../model/OnboardingV1Step';
import {OnboardingStepsRemainingModal} from '../../components/accountsPayableOnboarding/OnboardingStepsRemainingModal';
import {Typography} from 'spenda-ui-react';
import {APCaretUpIcon} from '../../assets/svg/accounts-payable/APCaretUpIcon';
import {APCaretDownIcon} from '../../assets/svg/accounts-payable/APCaretDownIcon';

const validationSchema = Yup.object({
  name: Yup.string().required('Batch Name is required.').max(50, 'Batch Name max 50 char.'),
  dueDate: Yup.string().nullable().required('Payment Date is required.'),
  debits: Yup.array()
    .of(
      Yup.object().shape({
        paymentAccountGUID: Yup.string().required(),
      }),
    )
    .min(1)
    .required(),
  credits: Yup.array().of(
    Yup.object().shape(
      {
        bankBSB: Yup.string()
          .ensure()
          .when(['bankAccountNumber'], {
            is: (bankBSB: string, bankAccountNumber: string) => bankAccountNumber?.length > 5 || bankBSB?.length > 0,
            then: Yup.string()
              .required('BSB is required')
              .nullable()
              .min(6, 'Invalid BSB number')
              .max(6, 'Invalid BSB number'),
            otherwise: Yup.string().notRequired().nullable(),
          }),
        bankAccountNumber: Yup.string()
          .ensure()
          .when(['bankBSB'], {
            is: (bankAccountNumber: string, bankBSB: string) => bankBSB?.length > 8 || bankAccountNumber?.length > 0,
            then: Yup.string()
              .required('Acc no is required')
              .nullable()
              .min(6, 'Invalid Acc no')
              .max(9, 'Invalid Acc no'),
            otherwise: Yup.string().notRequired().nullable(),
          }),
        purchaseInvoices: Yup.array().of(
          Yup.object().shape({
            appliedAmount: Yup.number()
              .required('Required*')
              .test('min_max_check', 'Enter positive number', function (val) {
                const balance = this.parent.remnantBalance;
                const status = this.options.context?.status || PaymentBatchStatusTypes.Open;
                if (val) {
                  if (val < 0) {
                    return false;
                  } else if (val && balance && balance < val && status !== PaymentBatchStatusTypes.PaymentApproved) {
                    return this.createError({message: 'Pay amount should be less than Balance!'});
                  }
                } else {
                  return this.createError({message: 'Invalid Amount'});
                }
                return true;
              }),
          }),
        ),
      },
      [['bankBSB', 'bankAccountNumber']],
    ),
  ),
});

const listContainerTable = css({
  maxHeight: 'calc( 100vh - 25em )',
});

const selectAPInvoiceCard = css({
  backgroundColor: '#eff6f9',
  borderRadius: '10px',
  '& p': {
    fontSize: '22px',
    color: '#333333',
  },
});

const panelRightBody = css({
  borderRadius: '6px',
});

const listContainer = css({
  boxShadow: '0px 0px 6px 0px #D3E5EF',
});

const useBatchViewStyles = makeStyles({
  batchName: {
    '& legend': {
      width: '66px',
    },
  },
  paymentDate: {
    '& legend': {
      width: '76px',
    },
  },
  batchSelect: {
    maxWidth: '265px',
    width: '100%',
    height: '38px',
    background: '#fff',
    paddingBottom: '0px',
    '& .MuiOutlinedInput-root': {
      height: '100%',
      backgroundColor: 'transparent',
      borderRadius: '6px',
    },
    '& .MuiFormLabel-root': {
      color: '#333',
      fontFamily: 'Poppins, sans-serif !important',
      width: 'auto',
      fontWeight: 500,
      fontSize: '13px',
      top: '-7px',
      left: '-5px',
    },
    '& .MuiInputLabel-shrink': {
      top: '0px',
      left: 0,
      paddingRight: '2px',
      marginLeft: '-4px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#333',
      '& legend': {
        height: '5px',
      },
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#333!important',
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: '#333',
    },
    '& .MuiSelect-select': {
      color: '#333333',
      fontSize: '14px',
      fontWeight: '600',
      fontFamily: 'poppins',
      paddingLeft: '10px',
      height: '40px',
      display: 'flex',
      alignItems: 'center',
      '& p': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    '& svg': {
      color: '#1c78ad',
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
      {
        border: '1px solid #333',
      },
    '& legend': {
      width: '89px',
    },
  },

  payInvoiceText: {
    width: '180px',
    height: '40px',
    '& .MuiFormControl-root': {
      paddingBottom: '0px',
      height: '100%',
      '& .MuiOutlinedInput-root': {
        height: '100%',
        backgroundColor: 'transparent',
        borderRadius: '6px',
      },
      '& .MuiFormLabel-root': {
        color: '#ccc',
        fontFamily: 'Poppins, sans-serif !important',
        width: 'auto',
        fontWeight: 600,
        fontSize: '14px',
        top: '-7px',
        left: '-5px',
      },
      '& .MuiInputLabel-shrink': {
        top: '0px',
        left: 0,
        paddingRight: '2px',
        marginLeft: '-4px',
        fontWeight: 500,
        fontSize: '13px',
        color: '#333',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#333',
        top: '-3px',
        '& legend': {
          height: '5px',
        },
      },
      '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#333!important',
        borderWidth: '2px!important',
      },
      '& .MuiFormLabel-root.Mui-focused': {
        color: '#333',
      },
      '& .MuiInputBase-input': {
        fontSize: '14px',
        fontWeight: 600,
        padding: '0px 8px',
        fontFamily: 'Poppins !important',
        color: '#333',
        '-webkit-text-fill-color': '#333',
        '&:-webkit-autofill': {
          '-webkit-box-shadow': '0 0 0 100px #ffffff inset',
        },
      },
      '& .MuiIconButton-root': {
        color: '#1C78AD',
      },
      '& .MuiInputBase-adornedEnd': {
        paddingRight: '4px',
        '& img': {
          width: '13px!important',
        },
      },
      '& .MuiInputBase-adornedStart': {
        paddingRight: '0px',
        paddingLeft: '7px',
      },
    },
    '&.editableInvoiceText': {
      '& .MuiOutlinedInput-root': {
        border: '1px solid transparent !important',
        '&.Mui-focused': {
          borderRadius: '0px',
          border: '1px solid rgb(30 121 173) !important',
        },
      },
    },
    '& .MuiIconButton-root': {
      color: '#1C78AD',
      padding: '0px 10px',
      marginTop: '-2px',
    },
    '& .MuiInputBase-adornedEnd': {
      paddingRight: '0px!important',
    },
    '& .MuiFormHelperText-root.Mui-error': {
      fontSize: '10px',
      fontFamily: 'poppins',
      margin: '0px 0px 0px 5px',
    },
  },
  fieldsDisable: {
    '& .Mui-disabled.MuiFormLabel-filled': {
      color: '#707070',
      position: 'relative',
      marginLeft: '8px',
      transform: 'translate(0px) scale(.75)',
      lineHeight: '18px',
      top: '3px',
    },
    '& .MuiOutlinedInput-input.Mui-disabled': {
      fontSize: '14px',
      fontWeight: 500,
      '-webkit-text-fill-color': '#333',
      height: '20px',
      display: 'flex',
      alignItems: 'center',
    },
    '& .Mui-disabled, .Mui-disabled:hover': {
      '& fieldset': {
        border: '0px!important',
      },
    },
    '& .MuiInputAdornment-root': {
      display: 'none',
    },
    '& .MuiInputLabel-shrink': {
      position: 'relative',
      marginLeft: '11px',
      transform: 'translate(0px) scale(.75)',
      lineHeight: '18px',
      top: '3px',
    },
    '& .bankMethod': {
      overflow: 'visible!important',
    },
  },
  pickerDialog: {
    '& .MuiPickersToolbar-toolbar': {
      backgroundColor: '#1C78AD',
    },
    '& .MuiPickersCalendarHeader-dayLabel.MuiPickersCalendarHeader-dayLabel': {
      color: '#333',
    },
    '& .MuiPickersCalendar-week': {
      '& .MuiPickersDay-day': {
        color: '#333',
      },
      '& .MuiPickersDay-dayDisabled': {
        color: '#33333350',
      },
      '& .MuiPickersDay-daySelected': {
        backgroundColor: '#1C78AD',
        color: '#fff',
      },
    },
  },
  supplierInvoicesWrap: {
    padding: '0px',
    borderTop: '1px solid #f1f1f1',
    borderBottom: '0px',
  },
  expandArrow: {
    minWidth: '20px',
    height: '20px',
    border: '1px solid #1579af',
    borderRadius: '3px',
    marginLeft: '0px',
    '& img': {
      width: '9px',
      transform: 'rotate(180deg)',
    },
    '& img.rotate-expand-more-icon': {
      transform: 'rotate(0deg)',
    },
  },

  supplierListcard: {
    padding: '0',
    '& .pay-amount': {
      color: '#3C9F78',
      fontSize: '16px',
    },
    '& .invoice-no': {
      fontSize: '12px',
      '& span': {},
    },
    '& .alreadyExistError': {
      color: '#C55D44',
      fontSize: '10px',
    },
    '& .total-container': {
      minWidth: '205px',
      maxWidth: '205px',
      margin: '0px',
      padding: '0px 10px',
      display: 'inline-block',
      textAlign: 'left',
      '& .supplier-name': {
        fontSize: '16px',
        width: 'auto',
        '&::after': {
          display: 'none',
        },
      },
    },
    '& .textfieldContainer': {
      width: '270px',
      margin: '0px',
      height: '100px',
      padding: '18px 10px 15px 10px',
      background: '#fafafa',
      '& .MuiFormControl-fullWidth': {
        '&:first-child': {
          '&::after': {
            content: "''",
            position: 'absolute',
            height: '45px',
            width: '1px',
            backgroundColor: '#444343',
            right: '0',
            top: '0px',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderRight: '0px',
          },
          '& .MuiOutlinedInput-root': {
            borderRadius: '4px 0px 0px 4px',
          },
        },
        '&:last-child': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderLeft: '0px',
          },
          '& .MuiOutlinedInput-root': {
            borderRadius: '0px 4px 4px 0px',
          },
        },
        '& .MuiOutlinedInput-input:-webkit-autofill': {
          '-webkit-box-shadow': '0 0 0 100px #fff inset',
        },
      },
    },

    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#333!important',
    },
  },
  supplierListCol: {
    minWidth: '192px',
    maxWidth: '270px',
    padding: '10px 5px 10px 0px',
    '& .supplier-name': {
      fontSize: '16px',
      width: '100%',
      color: '#1C78AD',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      '& span': {
        display: 'inline-block',
        '& img': {
          width: '18px',
          height: '16px',
          position: 'relative',
          top: '2px',
        },
      },
    },
  },
  toggler: {
    width: '36px',
    textAlign: 'center',
    height: '100%',
    background: '#FAFAFA',
    marginRight: '10px',
    '& img': {
      margin: '0 auto',
    },
  },
  descriptionField: {
    height: '100px',
    padding: '20px 10px 15px 10px',
    display: 'flex',
    alignItems: 'center',
    background: '#fafafa',
    minWidth: '200px',
    '& .descField': {
      width: '100%',
      height: '40px',
    },
  },
  searchForm: {
    marginRight: '10px',
  },
  customBtns: {
    maxWidth: '40px',
    width: '100%',
    height: '40px',
    borderRadius: '6px',
    border: '1px solid #1C78AD',
    marginRight: '10px',
  },
  footer: {
    width: '100%',
    background: 'rgb(173 173 173 / 20%)',
    padding: '10px',
    borderRadius: '6px',
  },
});

const supplierListBreakdowntable = css({
  width: 'calc(100% - 30px)!important',
  '& table': {
    overflow: 'hidden',
    borderRadius: '2px 2px 0 0',
    '& th': {
      color: '#999999',
      fontWeight: 600,
      fontSize: '14px',
      fontFamily: 'Poppins',
      width: '180px',
      '& .MuiTableSortLabel-root': {
        paddingRight: '15px',
        '& .MuiTableSortLabel-icon': {
          margin: '0px -7px 0px 0px',
          position: 'absolute',
          right: '0',
          transform: 'rotate(0deg)',
          opacity: '1',
          fontSize: '20px',
          '&.MuiTableSortLabel-iconDirectionAsc': {
            color: '#999',
          },
          '&.MuiTableSortLabel-iconDirectionDesc': {
            color: '#999',
          },
        },
        '&.MuiTableSortLabel-active': {
          color: '#999999',
          '& .MuiTableSortLabel-iconDirectionDesc': {
            color: '#9e9e9e',
          },
          '& .MuiTableSortLabel-iconDirectionAsc': {
            color: '#1c78ad!important',
          },
        },
      },
    },
    '& .MuiTableHead-root': {
      background: '#F1F1F1',
      height: '40px',
    },
    '& .MuiTableCell-root': {
      borderBottom: '1px solid #F1F1F1',
      borderLeft: '1px solid #F1F1F1',
      padding: '10px',
      boxSizing: 'border-box!important',
      lineHeight: '16px',
      '&:last-child': {
        borderLeft: '0px',
      },
    },
    '& .MuiTableCell-body': {
      height: '40px',
      padding: '10px',
      color: '#333333',
      fontWeight: 500,
      fontFamily: 'poppins',
      fontSize: '14px',
      background: '#fff',
      boxSizing: 'border-box!important',
      cursor: 'auto',
      '&:nth-child(odd)': {
        background: '#FAFAFA',
      },
      '& .select-symbol-icon': {
        margin: '0 auto',
      },
      '&.due-by-date span': {
        color: '#C55D44!important',
        fontWeight: 600,
        fontSize: '14px',
      },
      '&.invoice-total': {
        backgroundColor: '#fff',
        color: '#cccccc!important',
        '& .MuiFormControl-root': {
          paddingBottom: '0px',
          position: 'relative',
          zIndex: '99',
          '& .MuiFormHelperText-root': {
            top: '0px',
            margin: ' 0px 36px 0px 0px!important',
            height: '35px',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            paddingRight: '10px',
            fontFamily: 'Poppins',
            zIndex: '-1',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent',
          },
          '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
            {
              borderColor: '#1C78AD',
            },
        },
      },
    },

    '& .invoiceDisable': {
      '& td': {
        color: '#333',
        opacity: '30%',
        '&:last-child': {
          opacity: '100%',
        },
      },
    },
  },
});
const balanceInvoice = css({
  '&.MuiTableCell-body': {
    padding: '0px!important',
    '& .MuiOutlinedInput-root': {
      borderRadius: '0px',
      background: 'transparent',
      padding: '0px 10px',
      '& input': {
        fontFamily: 'poppins',
        fontSize: '14px',
        textAlign: 'right',
      },
    },
  },
});
type DatePickerKeyboardProps = IconButtonProps & {
  [key: string]: string | undefined;
};

interface ISelectedPayAmountFocus {
  id: string;
  isSelected: boolean;
}

const dateFormater = (date: string) => moment(date).format('Do MMM YYYY');

export const APBatchPreview = () => {
  const classes = useBatchViewStyles();
  const history = useHistory();
  const {search} = useLocation();
  const {batchID} = useParams<{batchID: string}>();
  const query = new URLSearchParams(search);
  const [searchString, setSearchString] = useState<string>('');
  const [batchToEdit, setBatchToEdit] = useState<IPaymentBatch>();
  const [paymentAccounts, setPaymentAccounts] = useState<IAccountResponse[]>();
  const [paymentAccountSelected, setPaymentAccountSelected] = useState<IPaymentBatchDebitLine>();
  const [batchEditable, setBatchEditable] = useState<boolean>(true);
  const [discardHandle, setDiscardHandle] = useState<boolean>(false);
  const [payMode, setPayMode] = useState<boolean>(false);
  const searchInputPopup = usePopupState({variant: 'popover', popupId: 'searchInputPopup'});
  const [breakDownSupplierId, setBreakDownSupplierId] = useState<Number | undefined>();
  const [authorisationId, setAuthorisationId] = useState<number | undefined>();
  const {
    getPaymentBatch,
    updatePaymentBatch,
    sendPaymentBatchForApproval,
    approvePaymentBatchForPayment,
    cancelPaymentBatch,
    removePurchaseInvoice,
    isLoading: batchLoader,
  } = usePaymentBatchAPI();
  const {getAccounts, isLoading: paymentLoader} = useSpendaPaymentServicesAPI();
  const isLoading = paymentLoader || batchLoader;
  let {transactionId} = useParams<{transactionId: string}>();
  const [purchaseInvoiceList, setPurchaseInvoiceList] = useState<IBatchReviewPurchaseInvoice[]>([]);
  const [disabledReason, setDisabledReason] = useState<string>();
  const {tenant, user, tenantInfo, setTenantInfo} = useContext(AppContext);
  const {userRoleBatchPermissions} = useContext(APContext);
  const [collapseView, setCollapseView] = useState<boolean>(false);
  const {sendAMLKYCEmail} = usePaymentServicesAPI();
  const [openKYCDialog, setOpenKYCDialog] = useState<boolean>(false);
  const [isAuthorisationRulesSet, setIsAuthorisationRulesSet] = useState<boolean>(false);
  const [isCurrentUserAnAuthoriser, setIsCurrentUserAnAuthoriser] = useState<boolean>(false);
  const [remainingOnboardingStepsInfo, setRemainingOnboardingStepsInfo] = useState<RemainingStepInfoModal>();
  const {
    getPaymentMethodAuthorisationDetails,
    approvePaymentAuthorisation,
    rejectPaymentAuthorisation,
    resetAuthenticationCode,
    getPaymentMethodAuthorisationRules,
  } = usePaymentMethods();
  const {getOnboardingStep} = useOnboarding();
  const {onboardingUpgradeV1, t2TV3} = useFeatureFlags().tenantOwned();

  useEffect(() => {
    // get batch by ID
    if (batchID) {
      fetchBatchDetails().then(res => handleActions(res));
      getPaymentAccounts();
    }
  }, [batchID]);

  useEffect(() => {
    if (
      typeof paymentAccounts !== 'undefined' &&
      onboardingUpgradeV1 &&
      !tenantInfo?.TenantUserDetails.AreAllAPOnboardingStepsCompletedForTenant
    ) {
      const getStep = async () => {
        const steps = await getOnboardingStep();
        const isSkippedAdaptor = steps[0].completedDateTime_utc ? false : true;
        let result = {
          remainingSteps: 0,
          steps: steps,
        };
        const countOfRemainingSteps = (steps: IOnboardingV1Step[]) => {
          let count = 0;
          for (let step of steps) {
            if (step.stepID === APOnboardingSteps.SetupPaymentMethods && !paymentAccounts?.length) {
              count++;
            } else if (step.stepID === APOnboardingSteps.AMLKYC && !tenant?.GoodToTrade) {
              count++;
            } else if (!step.completedDateTime_utc) {
              count++;
            }
          }
          return count;
        };
        if (isSkippedAdaptor) {
          const filteredSteps = steps.filter(step => {
            if (
              step.stepID === APOnboardingSteps.ConfigureFinancialAdaptor ||
              step.stepID === APOnboardingSteps.ClearingAccount
            ) {
              return false;
            } else {
              return true;
            }
          });
          result.remainingSteps = countOfRemainingSteps(filteredSteps);
          result.steps = filteredSteps;
        } else {
          result.remainingSteps = countOfRemainingSteps(steps);
        }
        return result;
      };

      const updateState = async () => {
        const remainingSteps = await getStep();
        if (remainingSteps.remainingSteps === 0) {
          if (typeof tenantInfo != 'undefined' && typeof tenantInfo?.TenantUserDetails != 'undefined') {
            const newObject: ITenantUserDetails = {
              ...tenantInfo.TenantUserDetails,
              AreAllAPOnboardingStepsCompletedForTenant: true,
            };
            setTenantInfo({...tenantInfo, TenantUserDetails: newObject});
          }
        } else {
          setRemainingOnboardingStepsInfo({
            remainingSteps: remainingSteps.remainingSteps,
            steps: remainingSteps.steps,
            showDialog: true,
            showAMLKYCPending: !tenant?.GoodToTrade,
          });
        }
      };
      updateState();
    }
  }, [paymentAccounts]);

  const fetchBatchDetails = async (): Promise<IPaymentBatch> => {
    const batchDetails = await getPaymentBatch(batchID);
    batchDetails.dueDate = moment(batchDetails.dueDate).toDate();
    const purchaseInvoices: IBatchReviewPurchaseInvoice[] = [];
    batchDetails.credits.map(credit => {
      credit.bankBSB = credit?.bankBSB?.replace('-', '') || '';
      credit?.purchaseInvoices &&
        credit.purchaseInvoices.map(pi => {
          pi.appliedAmount =
            pi.appliedAmount && Number.parseFloat(Number.parseFloat(String(pi.appliedAmount)).toFixed(2));
          purchaseInvoices.push(pi);
        });
    });
    if (batchDetails.status !== PaymentBatchStatusTypes.Open) {
      setBatchEditable(false);
    }
    if (batchDetails.paymentAmount === 0) {
      setBatchEditable(true);
    }
    if (batchDetails?.debits?.[0]?.paymentAccountGUID) {
      const isAuthorisationRulesSet =
        (batchDetails?.debits && batchDetails?.debits?.length && batchDetails?.debits?.[0]?.isAuthorisationRequired) ||
        false;
      // check if user exist in authoriser list
      const isCurrentUserAnAuthoriser =
        (batchDetails?.debits &&
          batchDetails?.debits?.length &&
          batchDetails?.debits?.[0]?.isCurrentUserAnAuthoriser) ||
        false;
      setIsAuthorisationRulesSet(isAuthorisationRulesSet);
      setIsCurrentUserAnAuthoriser(isCurrentUserAnAuthoriser);
    }
    batchDetails.credits = _.orderBy(batchDetails.credits, ['supplierName']);
    setPurchaseInvoiceList(purchaseInvoices);
    setBreakDownSupplierId(batchDetails?.credits[0]?.supplierID || undefined);
    setPaymentAccountSelected(batchDetails.debits[0]);
    setBatchToEdit(batchDetails);
    return batchDetails;
  };

  const getPaymentAccounts = async () => {
    const accounts = await getAccounts(PaymentProviderName.Spenda_Payment_Services);
    const _filteredAccount = accounts.filter(
      (account: IAccountResponse) =>
        [AccountType.CREDIT_CARD, AccountType.BANK_TRANSFER, AccountType.VIRTUAL_CARD].includes(account.accountType) &&
        account.verificationStatus === VerificationStatus.VERIFIED,
    );
    setPaymentAccounts(_filteredAccount);
  };

  const handleActions = (values: IPaymentBatch) => {
    if (query.get('action') === BatchSplitButtonType.Pay && values.status === PaymentBatchStatusTypes.PaymentApproved) {
      payBatch(values);
    } else if (
      query.get('action') === BatchSplitButtonType.ApprovePay &&
      values.status === PaymentBatchStatusTypes.ReadyForApproval
    ) {
      approvePayBatch(values);
    } else if (
      query.get('action') === BatchSplitButtonType.Approve &&
      values.status === PaymentBatchStatusTypes.ReadyForApproval
    ) {
      approveBatch(values, false);
    }
  };

  const formatAccountDetails = (accountGUID: string): IPaymentBatchDebitLine[] => {
    const account = paymentAccounts?.find(account => account.accountGUID === accountGUID);
    if (account) {
      const acc = {
        paymentAccountGUID: accountGUID,
      } as IPaymentBatchDebitLine;
      setPaymentAccountSelected(acc);
      return [acc];
    }
    return [];
  };

  const handleClickAway = () => {
    searchInputPopup.close();
  };

  const handleEdit = async (values: IPaymentBatch, formikHelpers: FormikHelpers<IPaymentBatch>) => {
    if (batchEditable) {
      values.dueDate = moment(values.dueDate).format('YYYY-MM-DDTHH:mm:ss');
      const updateRequest: any = {
        id: values.id,
        name: values.name,
        dueDate: values.dueDate,
        debits: values.debits,
      };
      const editedPurchaseInvoices: IPurchaseInvoiceRequest[] = [];
      const credits = values.credits.map(credit => {
        const initialCredit = batchToEdit?.credits?.find(c => c.transactionGUID === credit.transactionGUID);
        credit.purchaseInvoices &&
          credit.purchaseInvoices.map(pi => {
            if (
              pi.appliedAmount !==
              purchaseInvoiceList.find(inv => pi.purchaseInvoiceID === inv.purchaseInvoiceID)?.appliedAmount
            ) {
              editedPurchaseInvoices.push({
                PurchaseInvoiceID: pi.purchaseInvoiceID!,
                AppliedAmount: pi.appliedAmount,
                Action: IPurchaseInvoiceBatchActions.Update,
              });
            }
          });
        let updateCr: any = {};
        if (initialCredit?.description !== credit.description) updateCr['description'] = credit.description;
        if (
          !credit.isBankAccountImported &&
          credit.isNovelBankAccount &&
          (initialCredit?.bankBSB !== credit.bankBSB || initialCredit?.bankAccountNumber !== credit?.bankAccountNumber)
        ) {
          updateCr['bankBSB'] = credit.bankBSB;
          updateCr['bankAccountNumber'] = credit.bankAccountNumber;
        }
        if (updateCr.description || updateCr.bankBSB || updateCr.bankAccountNumber)
          return {
            transactionGUID: credit.transactionGUID,
            action: IPurchaseInvoiceBatchActions.Update,
            ...updateCr,
          };
      });
      updateRequest.credits = credits.filter(function (e) {
        return e;
      });
      updateRequest.purchaseInvoices = editedPurchaseInvoices;
      updatePaymentBatch(values.id, updateRequest)
        .then(_response => {
          Toast.info('Payment Batch updated successfully!');
          fetchBatchDetails();
          setBatchEditable(!batchEditable);
          return Promise.resolve();
        })
        .catch(error => {
          formikHelpers.setSubmitting(false);
          if (error && error?.Errors && error?.Errors?.length) {
            const GUIDsArr = error?.Errors?.map((er: any) => er.Code === APErrorCodes.InvalidBSB && er.GUID);
            let errors = {};
            values?.credits?.forEach((cr, index) => {
              if (GUIDsArr.includes(cr?.transactionGUID)) {
                errors = setIn(errors, `credits.${[index]}.bankBSB`, 'Invalid BSB');
              }
            });
            formikHelpers.setErrors(errors);
          }
        });
    }
  };

  const handleRemovePurchaseInvoice = async (id: number | undefined) => {
    const payload = {
      value: {
        id: parseInt(batchID),
        purchaseInvoices: [
          {
            purchaseInvoiceID: id,
            action: IPurchaseInvoiceBatchActions.Remove,
          },
        ],
      },
    };
    await removePurchaseInvoice(parseInt(batchID), payload);
    Toast.info('Purchase invoice removed successfully!');
    fetchBatchDetails();
  };

  const handleRemoveCredit = async (id: string) => {
    const payload = {
      value: {
        id: parseInt(batchID),
        credits: [
          {
            transactionGUID: id,
            action: IPurchaseInvoiceBatchActions.Remove,
          },
        ],
      },
    };
    await removePurchaseInvoice(parseInt(batchID), payload);
    Toast.info('Purchase invoice removed successfully!');
    fetchBatchDetails();
  };

  const discardHandleChanged = () => {
    setDiscardHandle(!discardHandle);
  };

  const setdiscardHandleChanged = () => {
    setDiscardHandle(false);
  };

  const handleSplitButtonClick = (buttonType: BatchSplitButtonType, values: IPaymentBatch) => {
    if (buttonType === BatchSplitButtonType.Pay) {
      payBatch(values);
    } else if (buttonType === BatchSplitButtonType.ApprovePay) {
      approvePayBatch(values);
    } else if (buttonType === BatchSplitButtonType.Approve) {
      approveBatch(values);
    } else if (buttonType === BatchSplitButtonType.SendForApproval) {
      sendBatchForApproval(values);
    }
  };

  const sendBatchForApproval = async (values: IPaymentBatch) => {
    await sendPaymentBatchForApproval(values.id);
    history.push(AUTH_PURCHASING_PAY_BILL_ROUTE);
  };

  const approveBatch = async (values: IPaymentBatch, redirect: boolean = true) => {
    if (!tenant?.GoodToTrade) {
      handleApproveClick();
    } else {
      await approvePaymentBatchForPayment(values.id);
      if (redirect) {
        history.push(AUTH_BUYING_AP_APPROVE_REQUESTS);
      } else {
        fetchBatchDetails();
      }
    }
  };

  const handleApproveClick = async () => {
    if (!tenant?.GoodToTrade) {
      const requestPayload = {
        Subject: 'AML/KYC application',
        WebsiteID: ApplicationInfo.WebsiteId,
        DatTypeID: DatTypes.AMLKYCApplication,
        To: '',
        PersonalisedMessage: 'Product Name: Accounts Payable',
      };
      if (process.env.REACT_APP_ENVIRONMENT === 'production') {
        requestPayload.To = 'customersuccess@spenda.co';
      } else {
        requestPayload.To = 'developers@spenda.co';
      }
      await sendAMLKYCEmail(requestPayload);
      if (remainingOnboardingStepsInfo?.showDialog) {
        setRemainingOnboardingStepsInfo(prev => {
          if (!prev) return prev;
          return {...prev, showDialog: false};
        });
      }
      setOpenKYCDialog(true);
    }
  };

  const handlePayClick = async () => {
    //Open the Payment widget
    setPayMode(true);
  };

  const approvePayBatch = async (values: IPaymentBatch) => {
    if (!tenant?.GoodToTrade) {
      handleApproveClick();
    } else {
      await approvePaymentBatchForPayment(values.id);
      await fetchBatchDetails();
      //Open the Payment widget
      handlePayClick();
    }
  };

  const payBatch = async (values: IPaymentBatch) => {
    if (values.status !== PaymentBatchStatusTypes.PaymentApproved && !values?.authorisationID) {
      await approvePaymentBatchForPayment(values.id);
      await fetchBatchDetails();
    }
    if (values?.authorisationID) {
      setAuthorisationId(values?.authorisationID);
    } else {
      //Open the Payment widget
      handlePayClick();
    }
  };

  const cancelBatch = async (description: string) => {
    if (batchID) {
      await cancelPaymentBatch(batchID, description);
      Toast.info('Payment Batch discarded!');
      history.push(AUTH_PURCHASING_PAY_BILL_ROUTE);
    }
  };

  const onSubmitSearch = (values: {searchText: string}): Promise<void> => {
    setSearchString(values.searchText);
    return Promise.resolve();
  };

  const onPaymentApproved = () => {
    fetchBatchDetails();
  };

  const onPaymentFailed = () => {
    fetchBatchDetails();
  };

  const handleAddInvoices = () => {
    history.replace(AUTH_PURCHASING_PAY_BILL_EDIT_ROUTE.replace(/:batchID/g, batchID));
  };

  const checkValidations = () => {
    let result = false;
    let disabledReason;
    batchToEdit?.credits?.forEach(cr => {
      if (result) return;
      if (!cr.bankBSB || !cr.bankAccountNumber) {
        result = true;
        disabledReason = 'Missing BSB/Account No for Suppliers.';
      }
      if (
        batchToEdit.status !== PaymentBatchStatusTypes.PaymentApproved &&
        batchToEdit.status !== PaymentBatchStatusTypes.InProgress
      ) {
        if (cr?.purchaseInvoices?.filter((pi: IBatchReviewPurchaseInvoice) => pi.remnantBalance === 0)?.length) {
          result = true;
          disabledReason = 'Please delete already approved invoices to continue.';
        }
      }
    });
    if (result) {
      setDisabledReason(disabledReason);
    } else {
      setDisabledReason(undefined);
    }
    return result;
  };

  const handleExpandPanel = () => {
    setCollapseView(!collapseView);
  };

  const leftpanel = (
    <div className={`flex h-full w-full flex-col`}>
      <div className={`${listContainer} invoicesList h-full rounded bg-white`}>
        <div className={`${panelRightBody} relative flex h-full items-center justify-center bg-white`}>
          {!batchToEdit ? (
            <div className={`${selectAPInvoiceCard} mx-5 rounded px-7 py-8 text-center`}>
              <p className="font-poppins font-light">Loading Batch Details</p>
              <img src={SelectAPInvoice} className="mx-auto mt-8" data-autoid={`imgSelectAPInvoice`} />
            </div>
          ) : (
            <Formik
              validateOnBlur
              enableReinitialize
              initialValues={batchToEdit}
              validate={async value => {
                try {
                  await validateYupSchema(value, validationSchema, false, value);
                } catch (err) {
                  return yupToFormErrors(err); //for rendering validation errors
                }
                return {};
              }}
              onSubmit={handleEdit}
              validateOnMount
            >
              {({values, handleChange, setFieldValue, submitForm, errors, touched, dirty, isValid, handleBlur}) => {
                return (
                  <div className="relative h-full w-full font-poppins">
                    <Grid
                      container
                      xs={12}
                      className={`flex items-center justify-between px-3 pb-3 pt-4 ${css({
                        borderBottom: '1px solid #D8D8D8',
                      })}`}
                    >
                      <Grid sm={6} item className="flex items-center justify-start text-lg text-primary">
                        {(matchPath(location.pathname, AUTH_BUYING_AP_APPROVE_REQUESTS_BATCH_VIEW) ||
                          matchPath(location.pathname, AUTH_BUYING_AP_AUTHORISE_PAYMENT_BATCH_VIEW)) && (
                          <span
                            className="cursor-pointer"
                            data-autoid="lnkBtnBack"
                            onClick={() => {
                              matchPath(location.pathname, AUTH_BUYING_AP_APPROVE_REQUESTS_BATCH_VIEW)
                                ? history.push(AUTH_BUYING_AP_APPROVE_REQUESTS)
                                : history.push(AUTH_BUYING_AP_AUTHORISE_PAYMENT);
                            }}
                          >
                            <img
                              src={backButton}
                              alt="Back Button"
                              style={{width: '40px', height: '40px', margin: '0px 10px 0px 0px'}}
                              data-autoid={`imgBackBtn`}
                            />
                          </span>
                        )}
                        Batch payment: {values.refNumber}
                      </Grid>
                      <Grid sm={6} container className={`flex items-center justify-end`}>
                        <Formik
                          initialValues={{searchText: '' as string}}
                          onSubmit={onSubmitSearch}
                          validateOnChange={false}
                        >
                          {formik => (
                            <form className={`m-0 w-auto ${classes.searchForm}`}>
                              <ClickAwayListener onClickAway={handleClickAway}>
                                <div>
                                  <div className="m-auto" {...bindToggle(searchInputPopup)}>
                                    <img src={searchIcon} className="cursor-pointer" data-autoid="imgSearchIcon" />
                                  </div>
                                  <Popper
                                    {...bindPopper(searchInputPopup)}
                                    disablePortal={false}
                                    placement="left"
                                    transition
                                  >
                                    {({TransitionProps}) => (
                                      <Grow {...TransitionProps} timeout={250}>
                                        <SearchInput
                                          placeholder={'Search'}
                                          {...formik}
                                          onSubmit={formik.handleSubmit}
                                          isAccountsPayable={true}
                                        ></SearchInput>
                                      </Grow>
                                    )}
                                  </Popper>
                                </div>
                              </ClickAwayListener>
                            </form>
                          )}
                        </Formik>
                        {batchEditable && (
                          <span
                            className={`${classes.customBtns} flex cursor-pointer items-center justify-center p-2`}
                            onClick={() => handleAddInvoices()}
                            data-autoid="lnkAddInvoice"
                          >
                            <img src={batchAddIcon} alt="Back Button" data-autoid={`imgAddInvoice`} />
                          </span>
                        )}
                        {[PaymentBatchStatusTypes.Open, PaymentBatchStatusTypes.ReadyForApproval].includes(
                          values.status,
                        ) ? (
                          <>
                            {!batchEditable && (
                              <span
                                className={`${classes.customBtns} mr-0 flex cursor-pointer items-center justify-center p-2`}
                                onClick={() => {
                                  setBatchEditable(!batchEditable);
                                }}
                                data-autoid="lnkEditInvoice"
                              >
                                <img src={batchEditIcon} alt="Back Button" data-autoid={`imgEditInvoice`} />
                              </span>
                            )}
                          </>
                        ) : null}
                        <div className={`expandableView cursor-pointer`}>
                          <img
                            src={collapseView ? expandIcon : collapseIcon}
                            onClick={handleExpandPanel}
                            data-autoid="imgCollapseToggle"
                          />
                        </div>
                      </Grid>
                    </Grid>
                    <div className={`mb-5 mt-7 flex pl-3 pr-5 ${!batchEditable ? `${classes.fieldsDisable}` : ''}`}>
                      <div style={{width: 'calc(100% - 185px)'}} className="flex items-end">
                        <div className={`${classes.payInvoiceText} mr-2.5`}>
                          <STextField
                            fullWidth
                            id="name"
                            name="name"
                            variant="outlined"
                            label="Batch name"
                            value={values?.name}
                            onChange={handleChange}
                            helperText={touched?.name && errors?.name}
                            error={touched?.name && Boolean(errors?.name)}
                            className={`${classes.batchName}`}
                            disabled={!batchEditable}
                            InputProps={{
                              inputProps: {'data-autoid': `txtBatchName`},
                            }}
                            onBlur={e => {
                              handleBlur(e);
                            }}
                          />
                        </div>
                        <div className={`${classes.payInvoiceText} mr-2.5`}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              autoOk
                              minDate={new Date()}
                              minDateMessage="Overdue date should be greater than today."
                              variant="inline"
                              id="dueDate"
                              name="dueDate"
                              inputVariant="outlined"
                              label="Payment date"
                              format="dd/MM/yyyy"
                              value={values?.dueDate}
                              InputAdornmentProps={{position: 'end'}}
                              onChange={date => setFieldValue('dueDate', date)}
                              helperText={touched?.dueDate && errors?.dueDate}
                              error={touched?.dueDate && Boolean(errors?.dueDate)}
                              PopoverProps={{className: classes.pickerDialog}}
                              className={`${classes.paymentDate}`}
                              disabled={!batchEditable}
                              inputProps={{
                                'data-autoid': 'dtpBatchPaymentDate',
                              }}
                              KeyboardButtonProps={
                                {
                                  'aria-label': 'change date',
                                  'data-autoid': 'dtpBatchPaymentDateIcon',
                                } as DatePickerKeyboardProps
                              }
                              onBlur={handleBlur}
                              keyboardIcon={<Calendar />}
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                        <FormControl
                          variant="outlined"
                          className={`${classes.batchSelect}`}
                          error={touched?.debits && Boolean(errors?.debits)}
                        >
                          <InputLabel id="demo-simple-select-outlined-label">Payment method</InputLabel>
                          <Select
                            displayEmpty
                            label="Pay method"
                            name="payMethod"
                            value={
                              paymentAccounts && paymentAccounts?.length
                                ? paymentAccountSelected?.paymentAccountGUID || ''
                                : '_'
                            }
                            onChange={(e: React.ChangeEvent<{value: unknown}>) =>
                              setFieldValue('debits', formatAccountDetails(e.target.value as string))
                            }
                            variant="outlined"
                            MenuProps={{
                              getContentAnchorEl: null,
                              anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                              },
                            }}
                            data-autoid="Batch_PaymentMethod_ddl"
                            inputProps={{
                              name: 'selectPaymentMethod',
                            }}
                            disabled={!batchEditable}
                          >
                            {paymentAccounts && paymentAccounts?.length < 1 && (
                              <MenuItem value="_">No Payment Method added</MenuItem>
                            )}
                            {paymentAccounts?.map((account: IAccountResponse, index: number) => (
                              <MenuItem
                                data-autoid={`Batch_PaymentMethod-${index}_mnu`}
                                key={account.accountGUID}
                                value={account.accountGUID}
                              >
                                {account.paymentMethod === AccountType.BANK_TRANSFER ? (
                                  <p
                                    className="bankMethod"
                                    title={`${account.paymentMethod} - ${account.bankDetails?.bankBSB} ${account.bankDetails?.bankAccountNumber}`}
                                  >{`${account.paymentMethod} - ${account.bankDetails?.bankBSB} ${account.bankDetails?.bankAccountNumber}`}</p>
                                ) : account.accountType === AccountType.VIRTUAL_CARD ? (
                                  <p
                                    className="bankMethod"
                                    title={`${account.paymentMethod} - ${account.virtualCard?.last4}`}
                                  >{`${account.paymentMethod} - ${account.virtualCard?.last4}`}</p>
                                ) : (
                                  <p
                                    className="bankMethod"
                                    title={`${account.paymentMethod} - ${account.cardDetails?.accountHolderName} ${account.cardDetails?.last4}`}
                                  >{`${account.paymentMethod} - ${account.cardDetails?.accountHolderName} ${account.cardDetails?.last4}`}</p>
                                )}
                              </MenuItem>
                            ))}
                          </Select>
                          {touched.debits && errors?.debits && <FormHelperText>Select a payment method</FormHelperText>}
                        </FormControl>
                      </div>
                      <div className="mx-12 flex flex-col">
                        <span className="text-center text-xs font-semibold text-spenda-primarytext">Batch amount</span>
                        <span
                          className="text-center text-xl font-light"
                          {...css({color: '#3C9F78', lineHeight: '33px'})}
                        >
                          {values.paymentAmount && PriceFormat(values.paymentAmount)}
                        </span>
                      </div>
                    </div>
                    <div className={`flex flex-col overflow-y-auto font-poppins ${listContainerTable}`}>
                      {(searchString
                        ? values.credits.filter(obj =>
                            Object.values(
                              pick(obj, [
                                'supplierID',
                                'bankBSB',
                                'bankAccountNumber',
                                'paymentAmount',
                                'description',
                                'supplierName',
                                'purchaseInvoices',
                                'transactionGUID',
                              ]),
                            ).some(val => (val + '').toLocaleLowerCase().includes(searchString.toLocaleLowerCase())),
                          )
                        : values.credits
                      ).map((credit: IPaymentBatchCreditLine, index: number) => {
                        const payAmount =
                          credit && credit?.purchaseInvoices
                            ? credit?.purchaseInvoices?.reduce(
                                (amt, trans) => amt + (trans.appliedAmount || 0) * 100,
                                0,
                              ) / 100
                            : 0;

                        const isAlreadyApproved =
                          values?.status !== PaymentBatchStatusTypes.PaymentApproved &&
                          values?.status !== PaymentBatchStatusTypes.InProgress &&
                          credit?.purchaseInvoices?.filter(pi => pi.remnantBalance === 0)?.length;
                        return (
                          <div key={index}>
                            <div className="flex w-full justify-between px-3">
                              <div
                                className={`section-purchaseInvoices flex w-full items-center ${classes.supplierInvoicesWrap}`}
                              >
                                <div className={`flex items-center justify-center ${classes.toggler}`}>
                                  <span
                                    onClick={() => {
                                      setBreakDownSupplierId(
                                        credit.supplierID === breakDownSupplierId ? undefined : credit.supplierID,
                                      );
                                    }}
                                    className={`flex cursor-pointer items-center justify-center ${classes.expandArrow}`}
                                    data-autoid={`lnkExpandArrow-${credit.supplierID}`}
                                  >
                                    <img
                                      src={supplierViewDropdown}
                                      className={`w-full ${
                                        Boolean(breakDownSupplierId === credit.supplierID)
                                          ? 'rotate-expand-more-icon'
                                          : 'rotate-expand-less-icon'
                                      }`}
                                      data-autoid={`imgExpandArrow-${credit.supplierID}`}
                                    />
                                  </span>
                                </div>
                                <div
                                  className={`invoice-list-card flex w-full items-center justify-between ${classes.supplierListcard}`}
                                >
                                  <div className={classes.supplierListCol}>
                                    <p
                                      className={`supplier-name ${
                                        (Boolean(getIn(errors, `credits.${[index]}.purchaseInvoices`)) ||
                                          Boolean(getIn(errors, `credits.${[index]}.bankBSB`)) ||
                                          Boolean(getIn(errors, `credits.${[index]}.bankAccountNumber`))) &&
                                        '!text-spenda-error'
                                      }`}
                                      title={credit.supplierName}
                                    >
                                      {credit.supplierName}
                                    </p>
                                    <p className="invoice-no">
                                      No. of purchase invoice: {credit.purchaseInvoices?.length}
                                    </p>
                                    <p className="invoice-no">
                                      Oldest overdue:{' '}
                                      {credit?.purchaseInvoices &&
                                        credit.purchaseInvoices?.length &&
                                        dateFormater(credit.purchaseInvoices[0].dueDate)}{' '}
                                    </p>
                                    {isAlreadyApproved ? (
                                      <p className="alreadyExistError font-medium">
                                        Invoice already approved. Please delete to continue
                                      </p>
                                    ) : null}
                                  </div>
                                  <div
                                    className={`${classes.descriptionField} ${
                                      !batchEditable ? `${classes.fieldsDisable}` : ''
                                    }`}
                                  >
                                    <div className={`w-full`}>
                                      <STextField
                                        v2
                                        fullWidth
                                        id="description"
                                        name="description"
                                        variant="outlined"
                                        label="Description"
                                        placeholder="Description"
                                        InputProps={{
                                          inputProps: {'data-autoid': `txtDescription-${credit.supplierID}`},
                                        }}
                                        value={credit?.description}
                                        onChange={e => {
                                          setFieldValue(`credits.${[index]}.description`, e.target.value);
                                        }}
                                        className={`${classes.batchName}`}
                                        disabled={!batchEditable}
                                      />
                                    </div>
                                  </div>
                                  <SupplierStatement
                                    isOverDueList={false}
                                    totalBill={credit?.paymentAmount || 0}
                                    APDraftPreview={true}
                                    payAmount={payAmount}
                                  />
                                  <div className={`textfieldContainer flex flex-row items-center justify-center`}>
                                    <STextField
                                      v2
                                      fullWidth
                                      id="BSB"
                                      name="BSB"
                                      value={credit?.bankBSB}
                                      label="BSB"
                                      placeholder="BSB"
                                      InputProps={{
                                        inputProps: {'data-autoid': `txtBSB-${credit.supplierID}`},
                                      }}
                                      variant="outlined"
                                      inputMode="numeric"
                                      type="number"
                                      onChange={e => {
                                        setFieldValue(`credits.${[index]}.bankBSB`, e.target.value);
                                      }}
                                      helperText={getIn(errors, `credits.${[index]}.bankBSB`)}
                                      error={Boolean(getIn(errors, `credits.${[index]}.bankBSB`))}
                                      disabled={
                                        !batchEditable || !credit.isNovelBankAccount || credit.isBankAccountImported
                                      }
                                      onBlur={e => {
                                        handleBlur(e);
                                      }}
                                    />
                                    <STextField
                                      v2
                                      fullWidth
                                      id="AccNumber"
                                      name="AccNumber"
                                      type="number"
                                      placeholder="Acc no"
                                      label="Acc no"
                                      variant="outlined"
                                      inputMode="numeric"
                                      InputProps={{
                                        inputProps: {'data-autoid': `txtAccNo-${credit.supplierID}`},
                                      }}
                                      value={credit?.bankAccountNumber}
                                      onChange={e => {
                                        setFieldValue(`credits.${[index]}.bankAccountNumber`, e.target.value);
                                      }}
                                      helperText={
                                        getIn(touched, `credits.${[index]}.bankAccountNumber`) &&
                                        getIn(errors, `credits.${[index]}.bankAccountNumber`)
                                      }
                                      error={
                                        getIn(touched, `credits.${[index]}.bankAccountNumber`) &&
                                        Boolean(getIn(errors, `credits.${[index]}.bankAccountNumber`))
                                      }
                                      disabled={
                                        !batchEditable || !credit.isNovelBankAccount || credit.isBankAccountImported
                                      }
                                      onBlur={handleBlur}
                                    />
                                  </div>
                                  {batchEditable && (
                                    <div className="ml-3">
                                      <img
                                        src={deleteIcon}
                                        className="cursor-pointer "
                                        onClick={() =>
                                          credit?.transactionGUID && handleRemoveCredit(credit?.transactionGUID)
                                        }
                                        data-autoid={`imgDelecteIcon-${credit.supplierID}`}
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <SupplierInvoiceBreakDown
                              open={Boolean(breakDownSupplierId === credit.supplierID)}
                              invoices={credit?.purchaseInvoices}
                              transactionId={transactionId}
                              supplierId={credit.supplierID}
                              creditIndex={index}
                              touched={touched}
                              errors={errors}
                              handleAmountChange={(amount, purchaseInvoiceID) => {
                                const invIndex = credit?.purchaseInvoices?.findIndex(
                                  cd => cd.purchaseInvoiceID === purchaseInvoiceID,
                                );
                                setFieldValue(
                                  `credits.${[index]}.purchaseInvoices.${[invIndex]}.appliedAmount`,
                                  amount,
                                );
                              }}
                              batchEditable={batchEditable}
                              handleRemovePurchaseInvoice={handleRemovePurchaseInvoice}
                              batchStatus={values.status}
                              t2TV3={t2TV3}
                            />
                          </div>
                        );
                      })}
                    </div>
                    <div className="absolute bottom-0 w-full p-2">
                      <div className={`${classes.footer} flex items-center justify-between`}>
                        <div>
                          {values.status === PaymentBatchStatusTypes.Open ||
                          (userRoleBatchPermissions &&
                            userRoleBatchPermissions.includes(PaymentBatchPermissionType.ApproveBatch) &&
                            values.status === PaymentBatchStatusTypes.ReadyForApproval) ||
                          values.status === PaymentBatchStatusTypes.PaymentApproved ? (
                            <>
                              {' '}
                              {batchEditable && (
                                <SButton
                                  borderRadius="6px"
                                  margin="0"
                                  textColor="discardRed"
                                  lineHeight="l"
                                  height="40px"
                                  boxShadow="none"
                                  fontFamily="poppins"
                                  fontSize="14px"
                                  border="1px solid #C55D44"
                                  width="120px"
                                  color="white"
                                  disabled={isLoading}
                                  onClick={discardHandleChanged}
                                  data-autoid="btnDiscardBatch"
                                >
                                  Discard Batch
                                </SButton>
                              )}
                            </>
                          ) : null}
                        </div>
                        <div className="flex items-center">
                          {batchEditable && (
                            <SButton
                              borderRadius="6px"
                              margin="0 10px 0 0"
                              textColor="white"
                              lineHeight="l"
                              height="40px"
                              boxShadow="none"
                              fontFamily="poppins"
                              fontSize="14px"
                              border="1px solid #1C78AD"
                              width="120px"
                              color="blueShade"
                              disabled={isLoading || values.paymentAmount === 0}
                              data-autoid="btnSave"
                              onClick={() => {
                                if (
                                  onboardingUpgradeV1 &&
                                  !tenantInfo?.TenantUserDetails.AreAllAPOnboardingStepsCompletedForTenant
                                ) {
                                  setRemainingOnboardingStepsInfo(prev => {
                                    if (!prev) return prev;
                                    return {...prev, showDialog: true};
                                  });
                                } else {
                                  submitForm();
                                }
                              }}
                            >
                              Save
                            </SButton>
                          )}
                          <BatchSplitButton
                            disabled={
                              checkValidations() ||
                              (batchEditable && dirty) ||
                              isLoading ||
                              !isValid ||
                              values.paymentAmount === 0
                            }
                            isSubmitting={isLoading}
                            batchStatus={batchToEdit.status}
                            handleClick={x => handleSplitButtonClick(x, values)}
                            disabledReason={disabledReason}
                            batchPaymentMethod={batchToEdit?.debits?.[0]?.paymentAccountGUID}
                            isAuthorisationRulesSet={isAuthorisationRulesSet}
                            isCurrentUserAnAuthoriser={isCurrentUserAnAuthoriser}
                            isAuthorisationList={
                              matchPath(location.pathname, AUTH_BUYING_AP_AUTHORISE_PAYMENT_BATCH_VIEW) ? true : false
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }}
            </Formik>
          )}
        </div>
      </div>
      {remainingOnboardingStepsInfo?.showDialog && (
        <OnboardingStepsRemainingModal
          handleClose={() => {
            setRemainingOnboardingStepsInfo(prev => {
              if (!prev) return prev;
              return {...prev, showDialog: false};
            });
          }}
          handleCompleteStep={() => {
            setRemainingOnboardingStepsInfo(prev => {
              if (!prev) return prev;
              return {...prev, showDialog: false};
            });
          }}
          countOfRemainingSteps={remainingOnboardingStepsInfo?.remainingSteps || 0}
          onboardingStep={remainingOnboardingStepsInfo?.steps || 0}
          paymentAccounts={paymentAccounts}
          showAMLKYCPending={remainingOnboardingStepsInfo?.showAMLKYCPending}
          handleAmlKycClick={handleApproveClick}
        />
      )}
    </div>
  );

  const mainPanel = <BatchStatus status={batchToEdit?.status} actions={batchToEdit?.actions} />;

  return (
    <>
      {payMode && (
        <PayBatch
          batchID={parseInt(batchID)}
          onClose={() => setPayMode(false)}
          onPaymentApproved={onPaymentApproved}
          onPaymentFailed={onPaymentFailed}
          onDone={() => history.push(`${AUTH_BUYING_AP_REPORTS}/detail/${batchID}`)}
        />
      )}
      {authorisationId && (
        <PaymentWidgetModal
          userID={user?.UserID}
          tenantName={user?.TenantName}
          open={true}
          onClose={() => {
            setAuthorisationId(undefined);
          }}
          widgetScope={PaymentWidgetScope.AUHTORISE_PAYMENT}
          getPaymentMethodAuthorisationDetails={getPaymentMethodAuthorisationDetails}
          rejectPaymentAuthorisation={rejectPaymentAuthorisation}
          approvePaymentAuthorisation={approvePaymentAuthorisation}
          resetAuthenticationCode={resetAuthenticationCode}
          getPaymentMethodAuthorisationRules={getPaymentMethodAuthorisationRules}
          authorisationId={authorisationId}
          isAccountsPayable
          onDone={() => {
            setAuthorisationId(undefined);
            history.push(`${AUTH_BUYING_AP_REPORTS}/detail/${batchID}`);
          }}
          isAPOnboardingV1={onboardingUpgradeV1}
        />
      )}
      {discardHandle && (
        <DiscardBatchModal
          isLoading={isLoading}
          handleClose={setdiscardHandleChanged}
          handleDiscardClick={cancelBatch}
        />
      )}
      {openKYCDialog && <AMLKYCModal isLoading={isLoading} handleClose={() => setOpenKYCDialog(!openKYCDialog)} />}
      <div className={`relative h-full overflow-hidden bg-spenda-newbg`}>
        <Layout
          leftPanel={
            <LoadingIndicator isLoading={isLoading} overlayBgColor="rgba(0, 0, 0, 0.05)">
              {leftpanel}
            </LoadingIndicator>
          }
          mainPanel={collapseView && mainPanel}
          splitWidthType={collapseView ? 'AR' : 4}
        />
      </div>
    </>
  );
};

interface ISupplierBreakDownProps {
  open: boolean;
  invoices: Array<IBatchReviewPurchaseInvoice> | undefined;
  transactionId: string | undefined;
  supplierId: number;
  handleAmountChange: (amount: any, purchaseInvoiceID: any) => void;
  batchEditable?: boolean;
  touched: any;
  errors: any;
  creditIndex: number;
  handleRemovePurchaseInvoice: (id: number | undefined) => void;
  batchStatus: PaymentBatchStatusTypes;
  t2TV3?: boolean;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
export const blockInvalidChar = (e: any) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();

type Order = 'asc' | 'desc';

function getComparator<T>(order: Order, orderBy: keyof T) {
  return order === 'desc'
    ? (a: T, b: T) => descendingComparator(a, b, orderBy)
    : (a: T, b: T) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array?.map((el, index) => [el, index] as [T, number]);
  stabilizedThis &&
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
  return stabilizedThis.map(el => el[0]);
}

const SupplierInvoiceBreakDown = (props: ISupplierBreakDownProps) => {
  const {
    open,
    invoices,
    transactionId,
    handleAmountChange,
    batchEditable,
    creditIndex,
    touched,
    errors,
    handleRemovePurchaseInvoice,
    batchStatus,
    t2TV3,
  } = props;
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof IBatchReviewPurchaseInvoice>('SupplierName');
  const [expandedDebitNotes, setExpandedDebitNotes] = useState<boolean>(false);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof IBatchReviewPurchaseInvoice) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const [selectedpayAmountFocus, setSelectedpayAmountFocus] = useState<ISelectedPayAmountFocus>({
    id: `credits[0].appliedAmount`,
    isSelected: false,
  });

  const createSortHandler = (property: keyof IBatchReviewPurchaseInvoice) => (event: React.MouseEvent<unknown>) => {
    handleRequestSort(event, property);
  };

  return (
    <Collapse in={open} timeout="auto" unmountOnExit>
      {t2TV3 && (
        <div className="mx-5 mt-3 flex h-6 w-24 items-center justify-center rounded-md border-[1px] border-[#ECECEC] bg-[#F6F6F6]">
          <Typography className="text-center align-middle font-medium" variant="xsmall">
            All invoices
          </Typography>
        </div>
      )}
      <TableContainer className={`${supplierListBreakdowntable} ${t2TV3 ? 'mx-5 mt-3' : 'mx-3 py-5'}`}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left" style={{width: '15%'}}>
                Purchase invoice
              </TableCell>
              {t2TV3 && (
                <TableCell align="left" style={{width: '13%'}}>
                  Vendor name
                </TableCell>
              )}
              <TableCell align="left" style={t2TV3 ? {width: '11%'} : {width: '13%'}}>
                No. of line items
              </TableCell>
              {!t2TV3 && (
                <TableCell align="left" style={{width: '14%'}}>
                  <TableSortLabel
                    IconComponent={ArrowDropDownIcon}
                    active={orderBy === 'dueDate'}
                    direction={orderBy === 'dueDate' ? order : 'asc'}
                    onClick={createSortHandler('dueDate')}
                  >
                    Due date
                  </TableSortLabel>
                </TableCell>
              )}
              <TableCell align="right" style={{width: '12%'}}>
                <TableSortLabel
                  IconComponent={ArrowDropDownIcon}
                  active={orderBy === 'balance'}
                  direction={orderBy === 'balance' ? order : 'asc'}
                  onClick={createSortHandler('balance')}
                >
                  {!t2TV3 ? 'Invoice total' : 'Invoice balance'}
                </TableSortLabel>
              </TableCell>
              {t2TV3 && (
                <TableCell align="right" style={{width: '12%'}}>
                  Applied debits
                </TableCell>
              )}
              {!t2TV3 && (
                <TableCell align="right" style={{width: '12%'}}>
                  <TableSortLabel
                    IconComponent={ArrowDropDownIcon}
                    active={orderBy === 'balance'}
                    direction={orderBy === 'balance' ? order : 'asc'}
                    onClick={createSortHandler('balance')}
                  >
                    Paid
                  </TableSortLabel>
                </TableCell>
              )}
              <TableCell align="right" style={{width: '12%'}}>
                <TableSortLabel
                  IconComponent={ArrowDropDownIcon}
                  active={orderBy === 'balance'}
                  direction={orderBy === 'balance' ? order : 'asc'}
                  onClick={createSortHandler('balance')}
                >
                  {t2TV3 ? (
                    <APTooltip arrow placement="top" title="Applied payments on other approved batches">
                      <Typography className="font-semibold" variant="paragraph">
                        Applied amount
                      </Typography>
                    </APTooltip>
                  ) : (
                    'Applied amount'
                  )}
                </TableSortLabel>
              </TableCell>
              {!t2TV3 && (
                <TableCell align="right" style={{width: '12%'}}>
                  <TableSortLabel
                    IconComponent={ArrowDropDownIcon}
                    active={orderBy === 'balance'}
                    direction={orderBy === 'balance' ? order : 'asc'}
                    onClick={createSortHandler('balance')}
                  >
                    Outstanding
                  </TableSortLabel>
                </TableCell>
              )}
              <TableCell align="right" style={t2TV3 ? {width: '12%'} : {width: '25%'}}>
                {t2TV3 ? (
                  <APTooltip arrow placement="top" title="Total Payment to be applied in this batch">
                    <Typography className="font-semibold" variant="paragraph">
                      Amount to pay
                    </Typography>
                  </APTooltip>
                ) : (
                  'Amount to pay'
                )}
              </TableCell>
              {t2TV3 && (
                <TableCell align="right" style={{width: '13%'}}>
                  <TableSortLabel
                    IconComponent={ArrowDropDownIcon}
                    active={orderBy === 'balance'}
                    direction={orderBy === 'balance' ? order : 'asc'}
                    onClick={createSortHandler('balance')}
                  >
                    <APTooltip arrow placement="top" title="Pending amount on this invoice">
                      <Typography className="font-semibold" variant="paragraph">
                        Remaining balance
                      </Typography>
                    </APTooltip>
                  </TableSortLabel>
                </TableCell>
              )}
              <TableCell align="right" style={{width: '3%'}}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(invoices) &&
              stableSort<IBatchReviewPurchaseInvoice>(
                invoices,
                getComparator<IBatchReviewPurchaseInvoice>(order, orderBy),
              ).map((inv: IBatchReviewPurchaseInvoice, index: number) => {
                const invIndex = invoices.findIndex(cd => cd.purchaseInvoiceID === inv.purchaseInvoiceID);
                const isAlreadyApproved =
                  batchStatus !== PaymentBatchStatusTypes.PaymentApproved &&
                  batchStatus !== PaymentBatchStatusTypes.InProgress &&
                  inv?.remnantBalance === 0;
                const paidAmount = Number((inv.totalInc - inv.balance || 0)?.toFixed(2));
                const committedAmount = Number((inv.balance - (inv.remnantBalance || 0) || 0)?.toFixed(2));
                const outstandingAmount = Number((inv.totalInc - (paidAmount + committedAmount) || 0)?.toFixed(2));
                return (
                  <TableRow
                    key={index}
                    className={`cursor-pointer ${Boolean(parseInt(transactionId!) === inv.TransID) ? '' : ''} ${
                      isAlreadyApproved ? `invoiceDisable` : ''
                    }`}
                  >
                    <TableCell align="left">{inv.refNumber}</TableCell>
                    {t2TV3 && (
                      <APTooltip arrow placement="bottom" title="Vendor Ref: VREIF-23">
                        <TableCell align="left">Vendor name</TableCell>
                      </APTooltip>
                    )}
                    <TableCell align="left">{inv.lineCount}</TableCell>
                    {!t2TV3 && (
                      <TableCell align="left" className={'due-by-date'}>
                        <span>{dateFormater(inv.dueDate)}</span>
                      </TableCell>
                    )}
                    <TableCell align="right">
                      {!t2TV3 ? (
                        inv.totalInc && PriceFormat(inv.totalInc)
                      ) : (
                        <APTooltip
                          arrow
                          placement="bottom"
                          title={
                            <>
                              <Typography className="font-medium" variant="paragraph">
                                Invoice total: $50.00
                              </Typography>
                              <Typography className="font-medium" variant="paragraph">
                                Paid amount: $10:00
                              </Typography>
                            </>
                          }
                        >
                          <Typography className="font-semibold" variant="paragraph">
                            {inv.totalInc && PriceFormat(inv.totalInc)}
                          </Typography>
                        </APTooltip>
                      )}
                    </TableCell>
                    {t2TV3 && <TableCell align="right">{inv.totalInc && PriceFormat(50)}</TableCell>}
                    {!t2TV3 && (
                      <TableCell align="right" style={{color: '#BFBFBF'}}>
                        {PriceFormat(paidAmount)}
                      </TableCell>
                    )}
                    <TableCell align="right" style={{color: '#BFBFBF'}}>
                      {PriceFormat(committedAmount)}
                    </TableCell>
                    {!t2TV3 && <TableCell align="right">{PriceFormat(outstandingAmount)}</TableCell>}
                    {batchEditable && !isAlreadyApproved ? (
                      <TableCell align="right" className={`${balanceInvoice} invoice-total`}>
                        <STextField
                          fullWidth
                          key={`appliedAmount`}
                          id={`appliedAmount`}
                          name={`appliedAmount`}
                          variant="outlined"
                          value={inv.appliedAmount}
                          type="number"
                          onKeyDown={blockInvalidChar}
                          InputProps={{inputProps: {min: 0, max: 5, 'data-autoid': `txtAppliedAmount-${index}`}}}
                          helperText={
                            getIn(touched, `credits.${[creditIndex]}.purchaseInvoices.${[invIndex]}.appliedAmount`) &&
                            getIn(errors, `credits.${[creditIndex]}.purchaseInvoices.${[invIndex]}.appliedAmount`)
                          }
                          error={
                            (getIn(touched, `credits.${[creditIndex]}.purchaseInvoices.${[invIndex]}.appliedAmount`) &&
                              Boolean(
                                getIn(errors, `credits.${[creditIndex]}.purchaseInvoices.${[invIndex]}.appliedAmount`),
                              )) ||
                            (inv.remnantBalance && inv.remnantBalance > 0
                              ? inv.appliedAmount > inv.remnantBalance
                              : false)
                          }
                          onFocus={() => {
                            setSelectedpayAmountFocus({
                              id: `credits.${[creditIndex]}.purchaseInvoices.${[invIndex]}.appliedAmount`,
                              isSelected: true,
                            });
                          }}
                          focused={
                            selectedpayAmountFocus?.id ===
                            `credits.${[creditIndex]}.purchaseInvoices.${[invIndex]}.appliedAmount`
                          }
                          onChange={e => {
                            if (e.target.value.match(/\./g)) {
                              const [, decimal] = e.target.value.split('.');

                              // restrict value to only 2 decimal places
                              if (decimal?.length > 2) {
                                // do nothing
                                return;
                              }
                            }

                            handleAmountChange(e.target.value, inv?.purchaseInvoiceID);
                          }}
                        />
                      </TableCell>
                    ) : (
                      <TableCell align="right" className="invoice-total">
                        <span>{inv.appliedAmount && PriceFormat(Number(inv.appliedAmount), '$', 2)}</span>
                      </TableCell>
                    )}
                    {t2TV3 && <TableCell align="right">{PriceFormat(outstandingAmount)}</TableCell>}
                    {(invoices?.length > 1 && batchEditable) || isAlreadyApproved ? (
                      <TableCell align="right">
                        <div className="flex items-center justify-end pl-2">
                          {isAlreadyApproved && (
                            <APTooltip
                              arrow
                              placement="bottom-end"
                              title={
                                <React.Fragment>
                                  <p className="w-[230px] text-center font-poppins font-medium text-black-800">
                                    This invoice has already got approved in other batch. Please delete to continue
                                  </p>
                                </React.Fragment>
                              }
                            >
                              <img
                                className="ml-2 cursor-pointer"
                                src={disableExclamation}
                                style={{minWidth: '13px'}}
                                data-autoid={`imgDisableExclamation`}
                              />
                            </APTooltip>
                          )}
                          <img
                            src={deleteIcon}
                            className="ml-2 cursor-pointer"
                            style={{minWidth: '13px'}}
                            onClick={() => handleRemovePurchaseInvoice(inv?.purchaseInvoiceID)}
                            data-autoid={`imgDeleteInvoice`}
                          />
                        </div>
                      </TableCell>
                    ) : null}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {t2TV3 && (
        <>
          <div className={`flex flex-row items-center justify-between`}>
            <div className="mx-5 my-3 flex h-6 w-36 items-center justify-center rounded-md border-[1px] border-[#ECECEC] bg-[#F6F6F6]">
              <Typography className="text-center align-middle font-medium" variant="xsmall">
                Added debit notes
              </Typography>
            </div>
            {expandedDebitNotes ? (
              <APCaretUpIcon
                className="justify-right my-3 mr-5 flex cursor-pointer"
                onClick={() => setExpandedDebitNotes(!expandedDebitNotes)}
              />
            ) : (
              <APCaretDownIcon
                className="justify-right my-3 mr-5 flex cursor-pointer"
                onClick={() => setExpandedDebitNotes(!expandedDebitNotes)}
              />
            )}
          </div>
          <Collapse in={expandedDebitNotes} timeout="auto" unmountOnExit>
            <TableContainer className={`${supplierListBreakdowntable} mx-5 mb-3`}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="left" style={{width: '15%'}}>
                      Debit note
                    </TableCell>
                    <TableCell align="left" style={{width: '13%'}}>
                      Vendor name
                    </TableCell>
                    <TableCell align="right" style={{width: '14%'}}>
                      Debit note balance
                    </TableCell>
                    <TableCell align="left" style={{width: '13%'}}>
                      Invoice applied to
                    </TableCell>
                    <TableCell align="right" style={{width: '12%'}}>
                      Remaining balance
                    </TableCell>
                    <TableCell align="right" style={{width: '2%'}}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="left">DR-02</TableCell>
                    <TableCell align="left">Company IV-4</TableCell>
                    <TableCell align="right">
                      <span>{PriceFormat(50)}</span>
                    </TableCell>
                    <TableCell align="left">PI93847</TableCell>
                    <TableCell align="right" style={{color: '#BFBFBF'}}>
                      {PriceFormat(0)}
                    </TableCell>
                    <TableCell align="center">
                      <div className="flex items-center justify-center">
                        <img
                          src={deleteIcon}
                          className="cursor-pointer"
                          style={{minWidth: '13px'}}
                          onClick={() => {}}
                          data-autoid={`imgDeleteInvoice`}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Collapse>
        </>
      )}
    </Collapse>
  );
};
