import * as React from 'react';
const PaymentScheduled = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="24" height="23" {...props}>
    <path
      fill="#3C9F78"
      fillRule="evenodd"
      d="M9.803.2c5.405 0 9.803 4.347 9.803 9.695l-.005.132.072.022c2.52.795 4.286 3.102 4.326 5.755l.001.093c0 3.388-2.786 6.143-6.21 6.143a6.218 6.218 0 0 1-5.179-2.77l-.055-.083-.07.02a9.726 9.726 0 0 1-2.501.378l-.182.001C4.398 19.586 0 15.237 0 9.891 0 4.547 4.398.2 9.803.2Zm7.987 10.816c-2.722 0-4.937 2.19-4.937 4.881 0 2.691 2.215 4.881 4.937 4.881 2.723 0 4.937-2.19 4.937-4.88 0-2.692-2.214-4.882-4.937-4.882Zm0 .892c.351 0 .636.282.636.63v.17l.016.005c.77.265 1.308.97 1.334 1.786l.001.063c0 .349-.285.63-.636.63a.634.634 0 0 1-.635-.59l-.002-.04a.71.71 0 0 0-.714-.705.71.71 0 0 0-.714.705.71.71 0 0 0 .672.704h.042c1.095 0 1.987.882 1.987 1.967 0 .843-.546 1.577-1.335 1.85l-.016.004v.17a.633.633 0 0 1-.596.629l-.04.001a.633.633 0 0 1-.636-.63v-.17l-.016-.005a1.965 1.965 0 0 1-1.334-1.786v-.063a.637.637 0 0 1 1.264-.1l.008.1c0 .389.32.705.714.705a.71.71 0 0 0 .714-.705.71.71 0 0 0-.672-.704h-.042a1.979 1.979 0 0 1-1.987-1.967c0-.842.546-1.577 1.335-1.849l.016-.005v-.17c0-.334.263-.608.596-.629h.04ZM9.803 1.461c-4.704 0-8.53 3.782-8.53 8.43 0 4.65 3.826 8.434 8.53 8.434.676 0 1.344-.081 1.998-.24l.174-.044-.04-.11a6.026 6.026 0 0 1-.353-1.894l-.002-.14c0-3.387 2.786-6.142 6.21-6.142.134 0 .27.005.41.015l.132.01v-.029c-.075-4.537-3.796-8.215-8.388-8.288l-.141-.002Zm0 1.814c.352 0 .637.282.637.631v4.306l.02.008a1.8 1.8 0 0 1 1.164 1.608v.064a1.813 1.813 0 0 1-2.516 1.657l-.053-.023-1.962 1.936a.635.635 0 0 1-.399.182l-.05.002a.638.638 0 0 1-.45-.185.627.627 0 0 1 .001-.893l1.96-1.933-.024-.05a1.741 1.741 0 0 1-.146-.614l-.001-.08c0-.748.471-1.405 1.163-1.671l.02-.007V3.906c0-.335.264-.609.596-.63h.04Zm.001 6.077a.545.545 0 0 0-.548.54c0 .114.036.222.095.303l.071.083a.549.549 0 0 0 .93-.386.545.545 0 0 0-.548-.54Z"
    />
  </svg>
);
export default PaymentScheduled;
