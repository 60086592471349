import useHttp from '../hooks/useHttp';

export interface IStatementsResponse {
  value: IStatementSummaryResponse;
}

export interface IStatementSummaryResponse {
  totalCount: number;
  values: IStatementsValuesResponse[];
}

export interface IStatementsValuesResponse {
  statementID: number;
  statementGUID: string;
  createCreatedDateTime_utc: string;
  periodDate: string;
}

const useStatementsAPI = () => {
  const {GET, isLoading} = useHttp();

  const getStatements = (): Promise<IStatementsResponse> => {
    return GET(`/Spenda/Statements/Summary`);
  };

  return {
    getStatements,
    isLoading,
  };
};

export default useStatementsAPI;
