import React from 'react';

import {ServiceJobLabourAndMaterialTable} from '../../../components/service-management/ServiceJobLabourAndMaterialTable';

export default function MaterialAndLabour() {
  return (
    <div className="relative max-h-[550px] w-full overflow-y-scroll bg-[#FFFFFF] lg:px-4 xl:px-12">
      <ServiceJobLabourAndMaterialTable />
    </div>
  );
}
