import * as React from 'react';

function PDFIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={50} height={50} fill="none" {...props}>
      <g clipPath="url(#a)">
        <path
          fill="#FF2116"
          d="M10.164 0A5.172 5.172 0 005 5.165v39.669a5.172 5.172 0 005.164 5.165h30.333a5.172 5.172 0 005.164-5.165V12.215s.081-.955-.333-1.885c-.389-.87-1.022-1.477-1.022-1.477l-.005-.007-7.526-7.381a.775.775 0 00-.012-.013S36.12.84 35.167.432C34.046-.05 32.89 0 32.89 0l.017-.002H10.164z"
        />
        <path
          fill="#F5F5F5"
          d="M10.163 1.696h22.761s.91.01 1.575.295c.64.274 1.094.69 1.097.693l.003.003 7.507 7.365s.452.477.672.969c.177.396.187 1.122.187 1.122a1.083 1.083 0 00-.001.036v32.655a3.441 3.441 0 01-3.467 3.469H10.163a3.441 3.441 0 01-3.467-3.47V5.166a3.441 3.441 0 013.467-3.469z"
        />
        <path
          fill="#FF2116"
          d="M15.153 29.77c-1.168-1.168.095-2.772 3.523-4.476l2.157-1.072.84-1.838a72.343 72.343 0 001.533-3.666l.693-1.828-.478-1.353c-.586-1.663-.796-4.163-.423-5.062.504-1.217 2.156-1.092 2.81.213.51 1.019.458 2.865-.147 5.193l-.497 1.908.438.742c.24.408.942 1.377 1.56 2.154l1.162 1.445 1.445-.189c4.591-.6 6.164.42 6.164 1.88 0 1.842-3.605 1.994-6.633-.132-.68-.479-1.149-.954-1.149-.954s-1.896.386-2.83.638c-.964.26-1.445.422-2.857.899 0 0-.495.72-.818 1.242-1.2 1.946-2.603 3.558-3.605 4.145-1.12.657-2.296.702-2.888.11zm1.832-.655c.656-.406 1.984-1.977 2.904-3.435l.372-.59-1.695.852c-2.618 1.317-3.815 2.557-3.193 3.307.35.422.769.387 1.612-.134zm17.005-4.773c.641-.45.548-1.356-.177-1.721-.565-.284-1.02-.343-2.487-.321-.902.061-2.352.243-2.597.298 0 0 .796.55 1.15.753.47.269 1.614.768 2.45 1.024.823.252 1.3.225 1.66-.033zM27.152 21.5c-.388-.408-1.049-1.26-1.468-1.894-.547-.718-.822-1.225-.822-1.225s-.4 1.288-.729 2.063l-1.025 2.533-.297.574s1.58-.518 2.383-.728c.852-.222 2.58-.562 2.58-.562l-.622-.761zm-2.203-8.836c.099-.832.14-1.662-.126-2.08-.741-.81-1.636-.135-1.484 1.79.05.649.212 1.756.427 2.439l.391 1.24.276-.934c.151-.514.383-1.62.516-2.455z"
        />
        <path
          fill="#2C2C2C"
          d="M17.138 35.175h1.895c.606 0 1.097.058 1.475.174.377.112.695.365.953.757.258.389.387.858.387 1.408 0 .504-.105.938-.314 1.3-.21.363-.492.625-.847.786-.351.16-.893.24-1.626.24h-.656v2.99h-1.267v-7.655zm1.267.982v2.669h.628c.56 0 .948-.105 1.16-.314.218-.21.326-.55.326-1.02 0-.352-.071-.636-.213-.853-.142-.22-.3-.355-.471-.404-.168-.052-.436-.078-.802-.078h-.628zm4.413-.982h1.722c.833 0 1.499.148 1.996.443.5.296.878.735 1.133 1.318.258.583.387 1.23.387 1.94 0 .748-.116 1.415-.348 2.002a3.183 3.183 0 01-1.054 1.413c-.471.36-1.144.539-2.019.539h-1.817v-7.655zm1.267 1.015v5.625h.528c.736 0 1.27-.255 1.603-.763.333-.512.5-1.194.5-2.047 0-1.876-.702-2.815-2.103-2.815h-.528zm5.187-1.015h4.251v1.015H30.54v2.288h2.389v1.015h-2.39v3.337h-1.267v-7.655z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h50v50H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export {PDFIcon};
