import React from 'react';

export const IconQuoteAccepted = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="88" height="88" fill="none" {...props}>
      <path
        fill="#1C78AD"
        fillRule="evenodd"
        d="M41.9284 0a25.5136 25.5136 0 0 1 18.15 7.517L72.853 20.2988a25.4879 25.4879 0 0 1 7.5206 18.1464v13.3276c0 2.0253-1.6419 3.6672-3.6672 3.6672-1.9639 0-3.5672-1.5438-3.6626-3.484l-.0045-.1832.0007-13.3276c0-.605-.1173-1.1844-.1723-1.7784h-18.161c-6.0751 0-11-4.9248-11-11V7.5059c-.594-.055-1.177-.1723-1.7783-.1723h-16.555c-6.0752 0-11 4.9249-11 11v51.3333c0 6.0752 4.9248 11 11 11l25.5196-.0006c1.9638-.0001 3.5671 1.5436 3.6625 3.4838l.0045.1831c0 2.0253-1.6418 3.6671-3.667 3.6671H25.3734C15.2532 87.9881 7.0522 79.7871 7.04 69.6669V18.3336C7.0522 8.2134 15.2532.0124 25.3734.0001h16.555Zm5.445 62.3336c2.025 0 3.6666 1.6416 3.6666 3.6667 0 2.025-1.6416 3.6666-3.6666 3.6666H29.04c-2.025 0-3.6666-1.6416-3.6666-3.6666 0-2.0251 1.6416-3.6667 3.6666-3.6667h18.3334Zm11-14.6667c2.025 0 3.6666 1.6416 3.6666 3.6667 0 2.025-1.6416 3.6667-3.6666 3.6667H29.04c-2.025 0-3.6666-1.6417-3.6666-3.6667 0-2.0251 1.6416-3.6667 3.6666-3.6667h29.3334ZM51.04 9.8416v15.8253c0 2.0251 1.6417 3.6667 3.6667 3.6667H70.532a18.2695 18.2695 0 0 0-2.8636-3.85l-12.7747-12.782a18.3684 18.3684 0 0 0-3.8537-2.86Zm36.2837 53.4486c.0667 1.0547-.2825 2.0902-.9707 2.877L69.9157 85.1796c-.5855.658-1.3734 1.0946-2.2357 1.2405l-.5758.1016c-1.0912.024-2.1455-.4186-2.906-1.2197l-9.7439-10.3674c-.7862-.7463-1.2366-1.7873-1.2467-2.8771-.0101-1.089.4217-2.1327 1.1939-2.8838.7712-.752 1.815-1.1455 2.8859-1.0889 1.0712.0575 2.0741.5596 2.7736 1.3894l6.8138 7.2179 13.6429-15.7807c.6775-.7822 1.6332-1.2591 2.6592-1.3277 1.0253-.0674 2.0389.2799 2.8181.9662.7834.6999 1.2611 1.6857 1.3287 2.7403Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
