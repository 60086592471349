import React from 'react';
import { Layout } from '../components/layout/Layout';

const UploadPanel = () => {

    return <div className="bg-white h-full rounded p-2">
        <h3>User Settings</h3>

        {/* Upload image component */}

    </div>
}


export const Settings = () => {
    return <Layout leftPanel={<UploadPanel />}>
    </Layout>
}