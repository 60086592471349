import React, {useState} from 'react';
import {Typography} from 'spenda-ui-react';
import moment from 'moment';

import {ARTable, IARColumns} from '../../../../components/AccountsReceivable/ARTable';
import {CreateServiceJobModal} from '../../../../components/dialog/CreateServiceJobModal';
import {IServiceJob, ServiceJobScope} from '../../../../model/service-management/serviceJob';
import {ServiceContextProvider} from '../../../../context/serviceManagement/ServiceContextProvider';
import {PriceFormat} from '../../../../utils/formatter';
import {ServiceStatus} from '../../../../components/bookingDiary/BookingDiaryList';

interface ICustomerInfoList {
  customerJobDetails: IServiceJob[];
  fetchJobs: (id: number) => void;
}

const CustomerInfoList = (props: ICustomerInfoList) => {
  const {customerJobDetails, fetchJobs} = props;
  // States
  const [openBookingDialog, setOpenBookingDialog] = useState<boolean>(false);
  const [serviceJobID, setServiceJobID] = useState<number>();

  const customerInfoColumns: IARColumns[] = [
    {
      title: 'Ref',
      key: 'ref',
      width: '8%',
      align: 'left',
      rowClassName: 'p-2.5',
      columClassName: '!leading-[14px] py-2',
      rowRenderer: (row: IServiceJob, index: number) => {
        return (
          <Typography variant="small" className="cursor-pointer text-primary underline" data-autoId={`ref-${index}`}>
            <span
              onClick={() => {
                setOpenBookingDialog(true);
                setServiceJobID(row.serviceJobID);
              }}
              data-autoid={`lnk-${row.refNumber}`}
            >
              {row.refNumber ? row.refNumber : '-'}
            </span>
          </Typography>
        );
      },
    },
    {
      title: 'Date range',
      key: 'dateRange',
      width: '12%',
      align: 'left',
      rowClassName: 'p-2.5',
      columClassName: '!leading-[14px] py-2',
      rowRenderer: (row: IServiceJob, index: number) => {
        return (
          <Typography variant="small" className="text-black-800" data-autoId={`dateRange-${index}`}>
            {row?.bookings && row.bookings.length
              ? row?.bookings?.map((book, index: number) => {
                  const isLast = index === row?.bookings?.length! - 1;
                  return (
                    <span key={index}>
                      {moment.utc(book.startDateTime_utc).local().format('DD-MM-YYYY')} {!isLast && ', '}
                    </span>
                  );
                })
              : '-'}
          </Typography>
        );
      },
    },
    {
      title: 'Job description',
      key: 'jobDesc',
      width: '10%',
      align: 'left',
      rowClassName: 'p-2.5',
      columClassName: '!leading-[14px] py-2',
      rowRenderer: (row: IServiceJob, index: number) => {
        return (
          <Typography variant="small" className="text-black-800" data-autoId={`jobDesc-${index}`}>
            {row.description ? row.description : '-'}
          </Typography>
        );
      },
    },
    {
      title: 'Sales ref info',
      key: 'salesRepInfo',
      width: '10%',
      align: 'center',
      rowClassName: 'p-2.5',
      columClassName: '!leading-[14px] py-2',
      rowRenderer: (row: IServiceJob, index: number) => {
        return (
          <Typography variant="small" className="text-black-800" data-autoId={`salesRepInfo-${index}`}>
            {row.repUserName ? row.repUserName : '-'}
          </Typography>
        );
      },
    },
    {
      title: 'Site contact',
      key: 'siteContact',
      width: '10%',
      align: 'center',
      rowClassName: 'p-2.5',
      columClassName: '!leading-[14px] py-2',
      rowRenderer: (row: IServiceJob, index: number) => {
        return (
          <Typography variant="small" className="text-black-800" data-autoId={`siteContact-${index}`}>
            {row.preServiceNote ? row.preServiceNote : '-'}
          </Typography>
        );
      },
    },
    {
      title: 'Assigned to',
      key: 'assignedTo',
      width: '10%',
      align: 'center',
      rowClassName: 'p-2.5',
      columClassName: '!leading-[14px] py-2',
      rowRenderer: (row: IServiceJob, index: number) => {
        return (
          <Typography variant="small" className="text-black-800" data-autoId={`assignedTo-${index}`}>
            {row.primaryTechnicianUserName ? row.primaryTechnicianUserName : '-'}
          </Typography>
        );
      },
    },
    {
      title: 'Act. time (Est. time)',
      key: 'actTime',
      width: '14%',
      align: 'right',
      rowClassName: 'p-2.5',
      columClassName: '!leading-[14px] py-2',
      rowRenderer: (row: IServiceJob, index: number) => {
        return (
          <Typography variant="small" className="text-black-800" data-autoId={`actTime-${index}`}>
            {row.totalBookedJobHours} hours <span className="text-black-300">({row.estimatedLabourHours} hours)</span>
          </Typography>
        );
      },
    },
    {
      title: 'Service amount',
      key: 'serviceAmount',
      align: 'right',
      width: '10%',
      rowClassName: 'p-2.5',
      columClassName: '!leading-[14px] py-2',
      rowRenderer: (row: IServiceJob, index: number) => {
        return (
          <Typography variant="small" className="text-black-800" data-autoId={`serviceAmount-${index}`}>
            {PriceFormat(row?.totalInc)}
          </Typography>
        );
      },
    },
    {
      title: 'Job status',
      key: 'jobStatus',
      width: '8%',
      align: 'right',
      rowClassName: 'p-2.5',
      columClassName: '!leading-[14px] py-2',
      rowRenderer: (row: IServiceJob, index: number) => {
        return (
          <div data-autoId={`jobStatus-${index}`}>
            <ServiceStatus status={row.status} />
          </div>
        );
      },
    },
  ];

  return (
    <div className="h-full w-full">
      <div className="mt-2.5 h-[calc(100vh-400px)]">
        <ARTable
          isHighlightRowOnHover
          columns={customerInfoColumns}
          scope="AR"
          rows={customerJobDetails}
          isLoading={false}
          tableClass="!mx-0"
        />
      </div>
      {openBookingDialog && (
        <ServiceContextProvider>
          <CreateServiceJobModal
            serviceJobId={serviceJobID}
            open={openBookingDialog}
            handleClose={() => {
              setOpenBookingDialog(false);
              setServiceJobID(undefined);
              fetchJobs(customerJobDetails[0].customerID!);
            }}
            scope={ServiceJobScope.QuoteDetails}
          />
        </ServiceContextProvider>
      )}
    </div>
  );
};

export default CustomerInfoList;
