import React from 'react';

export const ApproveRequest = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20px" height="30px" viewBox="0 0 100 100" {...props}>
      <path
        fill="#1C78AD"
        fillRule="evenodd"
        d="M47.646 0A28.993 28.993 0 0 1 68.27 8.542l14.516 14.525a28.964 28.964 0 0 1 8.547 20.62v15.146a4.167 4.167 0 0 1-8.33.208L83 58.833 83 43.688c0-.688-.133-1.346-.196-2.021H62.167c-6.904 0-12.5-5.597-12.5-12.5V8.529c-.675-.062-1.338-.195-2.021-.195H28.833c-6.903 0-12.5 5.596-12.5 12.5v58.333c0 6.903 5.597 12.5 12.5 12.5h29a4.167 4.167 0 0 1 4.162 3.958l.005.208A4.167 4.167 0 0 1 57.833 100h-29C17.333 99.986 8.013 90.667 8 79.167V20.834C8.014 9.334 17.333.014 28.833 0h18.813Zm6.187 70.834a4.167 4.167 0 0 1 0 8.333H33a4.167 4.167 0 1 1 0-8.333h20.833Zm12.5-16.667a4.167 4.167 0 1 1 0 8.333H33a4.167 4.167 0 1 1 0-8.333h33.333ZM58 11.184v17.983a4.167 4.167 0 0 0 4.167 4.167H80.15a20.762 20.762 0 0 0-3.254-4.375L62.379 14.434A20.875 20.875 0 0 0 58 11.184ZM99.231 71.92a4.53 4.53 0 0 1-1.103 3.269L79.45 96.795a4.378 4.378 0 0 1-2.54 1.41l-.655.115a4.422 4.422 0 0 1-3.302-1.386L61.88 85.153a4.57 4.57 0 0 1-1.417-3.27 4.514 4.514 0 0 1 1.357-3.277 4.363 4.363 0 0 1 3.28-1.237 4.436 4.436 0 0 1 3.151 1.579l7.743 8.202 15.503-17.933a4.383 4.383 0 0 1 3.022-1.508 4.409 4.409 0 0 1 3.203 1.098 4.568 4.568 0 0 1 1.51 3.114Z"
      />
    </svg>
  );
};
