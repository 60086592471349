import React from 'react';
import {Switch} from 'react-router-dom';
import {PrivateRoute} from './PrivateRoute';
import {MenuTypes, ModuleTypes} from '../model/constants/Constants';
import {SuppliersList} from '../screens/supplier-management/SuppliersList';
import {FeatureFlagKeys} from '../hooks/useFeatureFlags';
import {SupplierDetails} from '../screens/supplier-management/SupplierDetails';

export const AUTH_BUYING_SUPPLIERS = '/manage-supplier';
export const AUTH_BUYING_NEW_SUPPLIER_ROUTE = '/manage-supplier/detail';
export const AUTH_BUYING_EDIT_SUPPLIER_ROUTE = '/manage-supplier/detail/:supplierId';

export const SupplierManagementRoutes = () => {
  return (
    <Switch>
      <PrivateRoute
        path={AUTH_BUYING_SUPPLIERS}
        exact
        moduleIds={[ModuleTypes.Suppliers]}
        Component={SuppliersList}
        MenuFilters={{
          MenuType: MenuTypes.Buying,
          ModuleID: ModuleTypes.Suppliers,
          ModuleDependency: [ModuleTypes.Suppliers],
        }}
        featureFlag={FeatureFlagKeys.T2TPhase280729}
      />
      <PrivateRoute
        path={AUTH_BUYING_NEW_SUPPLIER_ROUTE}
        exact
        moduleIds={[ModuleTypes.Suppliers]}
        Component={SupplierDetails}
        MenuFilters={{
          MenuType: MenuTypes.Buying,
          ModuleID: ModuleTypes.Suppliers,
          ModuleDependency: [ModuleTypes.Suppliers],
        }}
        featureFlag={FeatureFlagKeys.T2TPhase280729}
      />
      <PrivateRoute
        path={AUTH_BUYING_EDIT_SUPPLIER_ROUTE}
        exact
        moduleIds={[ModuleTypes.Suppliers]}
        Component={SupplierDetails}
        MenuFilters={{
          MenuType: MenuTypes.Buying,
          ModuleID: ModuleTypes.Suppliers,
          ModuleDependency: [ModuleTypes.Suppliers],
        }}
        featureFlag={FeatureFlagKeys.T2TPhase280729}
      />
    </Switch>
  );
};
