import React, {useContext} from 'react';
import {IUser} from '../../model/user/User';
import {ZohoContext} from '../../context/zoho-context/ZohoContext';

export const SupportChat = (props: {user?: IUser; children?: React.ReactElement<any, any>; className?: string}) => {
  const {loadUser, showChatWindow} = useContext(ZohoContext)!;

  const onClickChatButton = () => {
    loadUser(props.user!);
    showChatWindow();
  };

  return (
    <>
      {props.children ? (
        <button
          data-autoid="zoho-chat-button"
          onClick={onClickChatButton}
          type="button"
          className={`${props.className}`}
          style={{flexShrink: 0}}
        >
          {props.children}
        </button>
      ) : (
        <button
          data-autoid="zoho-chat-button"
          onClick={onClickChatButton}
          type="button"
          className="text-center text-base underline"
          style={{flexShrink: 0}}
        >
          Need some help with Spenda? Click here
        </button>
      )}
    </>
  );
};
