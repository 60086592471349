import React from 'react';
import {Button} from 'spenda-ui-react';
import { CatIcon } from '../assets/svg/public-shell/CatIcon';

interface ErrorProps {
  resetErrorBoundary?: () => void;
}

export default function Error(props: ErrorProps) {
  return (
    <div className="flex h-screen w-screen items-center bg-gray-100 font-poppins">
      <div className="container flex flex-col items-center justify-center px-5 text-gray-700 md:flex-row">
        <div className="max-w-md">
          <div className="font-dark text-5xl font-bold">404</div>
          <p className="text-2xl font-light leading-normal md:text-3xl">Sorry we couldn't find this page. </p>
          <p className="mb-8">But dont worry, you can find plenty of other things on our homepage.</p>
          <Button
            onClick={() => {
              props.resetErrorBoundary?.();
            }}
          >
            Go back to home
          </Button>
        </div>
        <div className="max-w-lg">
          <CatIcon />
        </div>
      </div>
    </div>
  );
}

