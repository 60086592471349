import moment, {Moment} from 'moment';
import React, {useState} from 'react';
import {Button, Typography} from 'spenda-ui-react';
import {IUpdateScheduledPaymentPayload, useSupplierTransactionsAPI} from '../../services/useSupplierTransactionsAPI';
import SDatePicker from '../pickers/DatePicker';
import {ARDialogTemplate} from './ARDialogTemplate';

interface IEditPaymentDateDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  transactionID: number;
  datTypeID: number;
  scheduleDatetime: string | null;
  originalScheduleDate: string | null;
  maxAllowedScheduledDate: string | null;
  supplierId: string;
}

export const EditPaymentDateDialog = (props: IEditPaymentDateDialogProps) => {
  //Props
  const {isOpen, supplierId, maxAllowedScheduledDate, onClose, scheduleDatetime, onSuccess, transactionID, datTypeID} =
    props;

  // states
  const maxDate = moment(maxAllowedScheduledDate);
  const minDate = moment();
  const [date, setDate] = useState<Moment>(moment(scheduleDatetime));

  //Hooks
  const {updateScheduledPaymentDate, isLoading} = useSupplierTransactionsAPI();

  const onConfirmScheduledPaymentDate = async () => {
    try {
      const payload: IUpdateScheduledPaymentPayload = {
        transactionID: transactionID,
        datTypeID: datTypeID,
        scheduleDatetime: moment(date)?.format('YYYY-MM-DDT00:00:00'),
      };
      await updateScheduledPaymentDate(supplierId, payload);
      onSuccess();
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <ARDialogTemplate
      dialogProps={{size: 'sm', open: isOpen}}
      isFullScreen
      header={<p className=" font-poppins text-xl font-light text-[#333333]">Edit Payment Date</p>}
      body={
        <div className="my-12 flex flex-col items-center justify-center">
          <Typography variant="paragraph" className="mb-5 text-center font-light text-black-800">
            Select date within 7 day range
          </Typography>
          <div className="flex w-[240px] flex-row items-center justify-center">
            <SDatePicker
              v2
              inputVariant="outlined"
              format="dd/MM/yyyy"
              minDate={minDate}
              maxDate={maxDate}
              autoOk
              fullWidth
              variant="inline"
              id="scheduleDateTime"
              label="Payment Date"
              name="scheduleDateTime"
              value={date ?? minDate}
              onChange={date => setDate(moment(date, 'DD-MM-YYYY'))}
              className="w-full text-left"
              invalidDateMessage="Please enter correct date"
              inputProps={{
                'data-autoid': 'dtpEditPaymentDate',
              }}
              KeyboardButtonProps={
                {
                  'aria-label': 'change date',
                  'data-autoid': 'dtpEditPaymentDateIcon',
                } as any
              }
              PopoverProps={{className: '!z-[9999]'}}
            />
          </div>
        </div>
      }
      footer={
        <div className="flex w-full flex-row items-center justify-between">
          <Button
            data-autoid={`btnCancel`}
            className="bg-white"
            onClick={() => onClose()}
            variant="outlined"
            color="primary"
          >
            Cancel
          </Button>
          <Button
            loading={isLoading}
            data-autoid={`btnConfirm`}
            variant="filled"
            color="primary"
            onClick={onConfirmScheduledPaymentDate}
          >
            Confirm
          </Button>
        </div>
      }
      handleClose={() => onClose()}
    />
  );
};
