import React from 'react';
import {Tab, TabPanel, Tabs, TabsBody, TabsHeader} from 'spenda-ui-react';
import {Layout} from '../../components/layout/Layout';
import {BrushIcon} from '../../assets/svg';
import {CustomisationSettingsForm} from './CustomisationSettings';

export const QuoteSettings = () => {
  const tabData = [
    {
      label: 'Customisation',
      value: 'customisation',
      icon: <BrushIcon />,
      desc: <CustomisationSettingsForm />,
    },
  ];

  const _serviceDetails = (
    <Tabs value="customisation" orientation="vertical" className="h-full gap-2">
      <div className="h-[calc(100vh-120px)] min-w-[180px] bg-white">
        <TabsHeader
          className="w-full bg-transparent px-0 pt-4"
          indicatorProps={{
            className: 'bg-primary/10 rounded-none',
          }}
        >
          {tabData.map(({label, value, icon}) => (
            <Tab
              key={value}
              value={value}
              className="justify-start p-4 text-left font-medium"
              data-autoid={`tab${value}`}
            >
              <div className="flex items-center justify-start gap-4" data-autoid={`tabContent${value}`}>
                {icon}
                {label}
              </div>
            </Tab>
          ))}
        </TabsHeader>
      </div>
      <TabsBody data-autoid="tabsBody">
        {tabData.map(({value, desc}) => (
          <div
            key={value}
            className={`h-full w-full rounded-md bg-white py-6 font-poppins shadow-md`}
            data-autoid={`tabPanelContainer${value}`}
          >
            <TabPanel value={value} data-autoid={`tabPanel${value}`}>
              <h2
                className={`-mt-4 text-[22px] font-light text-spenda-primarytext`}
                data-autoid={`tabPanelHeader${value}`}
              >
                Customisation
              </h2>
              <div className="mt-2 w-full border border-[#ECECEC]" />
              <div
                className="flex h-[calc(100vh-120px)] flex-col items-start justify-between rounded bg-white p-2.5"
                data-autoid={`tabPanelContent${value}`}
              >
                {desc}
              </div>
            </TabPanel>
          </div>
        ))}
      </TabsBody>
    </Tabs>
  );

  return (
    <div className={`relative h-full overflow-hidden bg-[rgba(211,229,239,0.5)]`}>
      <Layout leftPanel={_serviceDetails} splitWidthType={4} />
    </div>
  );
};
