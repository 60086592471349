export const SuccessAnimationData = {
  assets: [
    {
      id: 'comp_Oval_8B200EA7-FA79-4E44-8AFB-6A368FCEEE11',
      layers: [
        {
          ao: 0,
          bm: 0,
          ddd: 0,
          ef: [
            {
              ef: [{ix: 1, mn: 'ADBE Slider Control-0001', nm: 'Slider', ty: 0, v: {a: 0, ix: 1, k: 100}}],
              en: 1,
              ix: 1,
              mn: 'ADBE Slider Control',
              nm: 'transform.scale.x',
              np: 3,
              ty: 5,
            },
            {
              ef: [{ix: 1, mn: 'ADBE Slider Control-0001', nm: 'Slider', ty: 0, v: {a: 0, ix: 1, k: 100}}],
              en: 1,
              ix: 1,
              mn: 'ADBE Slider Control',
              nm: 'transform.scale.y',
              np: 3,
              ty: 5,
            },
          ],
          hasMask: false,
          hd: false,
          ind: 15,
          ip: 0,
          ks: {
            a: {a: 0, ix: 1, k: [104, 104, 0]},
            o: {a: 0, ix: 7, k: 100},
            p: {s: true, x: {a: 0, ix: 3, k: 115}, y: {a: 0, ix: 4, k: 115}},
            r: {a: 0, ix: 10, k: 0},
            s: {a: 0, ix: 6, k: [100, 100]},
          },
          masksProperties: [],
          nm: 'Oval-null',
          op: 46,
          sr: 0,
          st: 0,
          tt: 0,
          ty: 3,
        },
        {
          ao: 0,
          bm: 0,
          ddd: 0,
          ef: [],
          hasMask: false,
          hd: false,
          ind: 18,
          ip: 0,
          ks: {
            a: {a: 0, ix: 1, k: [0, 0]},
            o: {a: 0, ix: 7, k: 100},
            p: {a: 0, ix: 2, k: [0, 0]},
            r: {a: 0, ix: 2, k: 0},
            s: {a: 0, ix: 6, k: [100, 100]},
          },
          masksProperties: [],
          nm: 'Oval-size',
          op: 46,
          shapes: [
            {
              bm: 0,
              cix: 2,
              hd: false,
              it: [
                {
                  d: 1,
                  hd: false,
                  mn: 'ADBE Vector Shape - Rect',
                  nm: 'sizeElement',
                  p: {a: 0, ix: 2, k: [115, 115]},
                  r: {a: 0, ix: 2, k: 0},
                  s: {a: 0, ix: 6, k: [230, 230]},
                  ty: 'rc',
                },
                {
                  bm: 0,
                  c: {a: 0, ix: 4, k: [0, 0, 0, 1]},
                  hd: false,
                  mn: 'ADBE Vector Graphic - Fill',
                  nm: 'Fill 1',
                  o: {a: 0, ix: 5, k: 0},
                  r: 1,
                  ty: 'fl',
                },
                {
                  a: {a: 0, ix: 1, k: [0, 0]},
                  nm: 'Transform',
                  o: {a: 0, ix: 7, k: 100},
                  p: {a: 0, ix: 2, k: [0, 0]},
                  r: {a: 0, ix: 2, k: 0},
                  s: {a: 0, ix: 6, k: [100, 100]},
                  sa: {a: 0, ix: 5, k: 0},
                  sk: {a: 0, ix: 4, k: 0},
                  ty: 'tr',
                },
              ],
              ix: 1,
              mn: 'ADBE Vector Group',
              nm: 'Oval-size',
              np: 3,
              ty: 'gr',
            },
          ],
          sr: 0,
          st: 0,
          tt: 0,
          ty: 4,
        },
        {
          ao: 0,
          bm: 0,
          ddd: 0,
          ef: [],
          hasMask: false,
          hd: false,
          ind: 11,
          ip: 0,
          ks: {
            a: {a: 0, ix: 1, k: [0, 0]},
            o: {a: 0, ix: 7, k: 100},
            p: {a: 0, ix: 2, k: [0, 0]},
            r: {a: 0, ix: 2, k: 0},
            s: {a: 0, ix: 6, k: [100, 100]},
          },
          masksProperties: [],
          nm: 'Oval-stroke',
          op: 46,
          parent: 15,
          shapes: [
            {
              bm: 0,
              cix: 2,
              hd: false,
              it: [
                {
                  hd: false,
                  ind: 0,
                  ix: 1,
                  ks: {
                    a: 0,
                    ix: 2,
                    k: {
                      c: false,
                      i: [
                        [0, 0],
                        [41.317122285129145, -39.899481198080366],
                        [-39.89948119808038, -41.3171222851291],
                        [-41.317122285129074, 39.899481198080366],
                        [-5.2884600594495055, 12.175371349168017],
                        [-0.2364375641685399, 13.545498979290244],
                        [5.038557171651917, 12.805498477637599],
                      ],
                      o: [
                        [-39.89948119808031, -41.3171222851291],
                        [-41.31712228512908, 39.89948119808031],
                        [39.89948119808031, 41.317122285129074],
                        [10.143298041464902, -9.795269058165275],
                        [5.482393270654512, -12.621854604560667],
                        [0.23643756416862516, -13.545498979290315],
                        [0, 0],
                      ],
                      v: [
                        [178.8112677384109, 31.75559851592996],
                        [31.75559851592998, 29.18873226158908],
                        [29.18873226158908, 176.24440148407],
                        [176.24440148407, 178.8112677384109],
                        [199.3985549078758, 145.4819901296856],
                        [207.98406091744403, 105.8150485348137],
                        [200.78814126355275, 65.8726411303263],
                      ],
                    },
                  },
                  mn: 'ADBE Vector Shape - Group',
                  nm: 'Oval-path',
                  ty: 'sh',
                },
                {
                  bm: 0,
                  c: {a: 0, ix: 3, k: [0.23529411764705882, 0.6235294117647059, 0.47058823529411764, 1]},
                  d: [
                    {n: 'd', nm: 'dash0', v: {a: 0, ix: 0, k: 620}},
                    {
                      n: 'o',
                      nm: 'offset',
                      v: {
                        a: 1,
                        ix: 7,
                        k: [
                          {i: {x: [0.58], y: [1]}, o: {x: [0.42], y: [0]}, s: [620], t: 0},
                          {i: {x: [0.58], y: [1]}, o: {x: [0.42], y: [0]}, s: [0], t: 31.8},
                          {i: {x: [1], y: [1]}, o: {x: [0], y: [0]}, s: [0], t: 45},
                        ],
                      },
                    },
                  ],
                  hd: false,
                  lc: 2,
                  lj: 1,
                  ml: 10,
                  mn: 'ADBE Vector Graphic - Stroke',
                  nm: 'Stroke 1',
                  o: {a: 0, ix: 4, k: 100},
                  ty: 'st',
                  w: {a: 0, ix: 5, k: 12},
                },
                {
                  e: {a: 0, ix: 2, k: 100},
                  hd: false,
                  ix: 3,
                  mn: 'ADBE Vector Filter - Trim',
                  nm: 'Trim Paths 1',
                  o: {a: 0, ix: 3, k: 0},
                  s: {a: 0, ix: 1, k: 0},
                  ty: 'tm',
                },
                {
                  a: {a: 0, ix: 1, k: [0, 0]},
                  nm: 'Transform',
                  o: {a: 0, ix: 7, k: 100},
                  p: {a: 0, ix: 2, k: [0, 0]},
                  r: {a: 0, ix: 2, k: 0},
                  s: {a: 0, ix: 6, k: [100, 100]},
                  sa: {a: 0, ix: 5, k: 0},
                  sk: {a: 0, ix: 4, k: 0},
                  ty: 'tr',
                },
              ],
              ix: 1,
              mn: 'ADBE Vector Group',
              nm: 'Oval-stroke',
              np: 3,
              ty: 'gr',
            },
          ],
          sr: 0,
          st: 0,
          tt: 0,
          ty: 4,
        },
        {
          ao: 0,
          bm: 0,
          ddd: 0,
          ef: [],
          hasMask: false,
          hd: false,
          ind: 14,
          ip: 0,
          ks: {
            a: {a: 0, ix: 1, k: [0, 0]},
            o: {a: 0, ix: 7, k: 100},
            p: {a: 0, ix: 2, k: [0, 0]},
            r: {a: 0, ix: 2, k: 0},
            s: {a: 0, ix: 6, k: [100, 100]},
          },
          masksProperties: [],
          nm: 'Oval-content',
          op: 46,
          parent: 15,
          shapes: [
            {
              bm: 0,
              cix: 2,
              hd: false,
              it: [
                {
                  hd: false,
                  ind: 0,
                  ix: 1,
                  ks: {
                    a: 0,
                    ix: 2,
                    k: {
                      c: false,
                      i: [
                        [0, 0],
                        [41.317122285129145, -39.899481198080366],
                        [-39.89948119808038, -41.3171222851291],
                        [-41.317122285129074, 39.899481198080366],
                        [-5.2884600594495055, 12.175371349168017],
                        [-0.2364375641685399, 13.545498979290244],
                        [5.038557171651917, 12.805498477637599],
                      ],
                      o: [
                        [-39.89948119808031, -41.3171222851291],
                        [-41.31712228512908, 39.89948119808031],
                        [39.89948119808031, 41.317122285129074],
                        [10.143298041464902, -9.795269058165275],
                        [5.482393270654512, -12.621854604560667],
                        [0.23643756416862516, -13.545498979290315],
                        [0, 0],
                      ],
                      v: [
                        [178.8112677384109, 31.75559851592996],
                        [31.75559851592998, 29.18873226158908],
                        [29.18873226158908, 176.24440148407],
                        [176.24440148407, 178.8112677384109],
                        [199.3985549078758, 145.4819901296856],
                        [207.98406091744403, 105.8150485348137],
                        [200.78814126355275, 65.8726411303263],
                      ],
                    },
                  },
                  mn: 'ADBE Vector Shape - Group',
                  nm: 'Oval-path',
                  ty: 'sh',
                },
                {
                  bm: 0,
                  c: {a: 0, ix: 4, k: [0, 0, 0]},
                  hd: false,
                  mn: 'ADBE Vector Graphic - Fill',
                  nm: 'Fill 1',
                  o: {a: 0, ix: 5, k: 0},
                  r: 1,
                  ty: 'fl',
                },
                {
                  a: {a: 0, ix: 1, k: [0, 0]},
                  nm: 'Transform',
                  o: {a: 0, ix: 7, k: 100},
                  p: {a: 0, ix: 2, k: [0, 0]},
                  r: {a: 0, ix: 2, k: 0},
                  s: {a: 0, ix: 6, k: [100, 100]},
                  sa: {a: 0, ix: 5, k: 0},
                  sk: {a: 0, ix: 4, k: 0},
                  ty: 'tr',
                },
              ],
              ix: 1,
              mn: 'ADBE Vector Group',
              nm: 'Oval-content',
              np: 3,
              ty: 'gr',
            },
          ],
          sr: 0,
          st: 0,
          tt: 0,
          ty: 4,
        },
      ],
    },
    {
      id: 'comp_Path_2B911DDF-AEC3-40ED-BCA8-982F1DC650F6',
      layers: [
        {
          ao: 0,
          bm: 0,
          ddd: 0,
          ef: [
            {
              ef: [{ix: 1, mn: 'ADBE Slider Control-0001', nm: 'Slider', ty: 0, v: {a: 0, ix: 1, k: 100}}],
              en: 1,
              ix: 1,
              mn: 'ADBE Slider Control',
              nm: 'transform.scale.x',
              np: 3,
              ty: 5,
            },
            {
              ef: [{ix: 1, mn: 'ADBE Slider Control-0001', nm: 'Slider', ty: 0, v: {a: 0, ix: 1, k: 100}}],
              en: 1,
              ix: 1,
              mn: 'ADBE Slider Control',
              nm: 'transform.scale.y',
              np: 3,
              ty: 5,
            },
          ],
          hasMask: false,
          hd: false,
          ind: 25,
          ip: 0,
          ks: {
            a: {a: 0, ix: 1, k: [79.7, 56.07, 0]},
            o: {a: 0, ix: 7, k: 100},
            p: {s: true, x: {a: 0, ix: 3, k: 143}, y: {a: 0, ix: 4, k: 93}},
            r: {a: 0, ix: 10, k: 0},
            s: {a: 0, ix: 6, k: [100, 100]},
          },
          masksProperties: [],
          nm: 'Path-null',
          op: 46,
          sr: 0,
          st: 0,
          tt: 0,
          ty: 3,
        },
        {
          ao: 0,
          bm: 0,
          ddd: 0,
          ef: [],
          hasMask: false,
          hd: false,
          ind: 28,
          ip: 0,
          ks: {
            a: {a: 0, ix: 1, k: [0, 0]},
            o: {a: 0, ix: 7, k: 100},
            p: {a: 0, ix: 2, k: [0, 0]},
            r: {a: 0, ix: 2, k: 0},
            s: {a: 0, ix: 6, k: [100, 100]},
          },
          masksProperties: [],
          nm: 'Path-size',
          op: 46,
          shapes: [
            {
              bm: 0,
              cix: 2,
              hd: false,
              it: [
                {
                  d: 1,
                  hd: false,
                  mn: 'ADBE Vector Shape - Rect',
                  nm: 'sizeElement',
                  p: {a: 0, ix: 2, k: [115, 115]},
                  r: {a: 0, ix: 2, k: 0},
                  s: {a: 0, ix: 6, k: [230, 230]},
                  ty: 'rc',
                },
                {
                  bm: 0,
                  c: {a: 0, ix: 4, k: [0, 0, 0]},
                  hd: false,
                  mn: 'ADBE Vector Graphic - Fill',
                  nm: 'Fill 1',
                  o: {a: 0, ix: 5, k: 0},
                  r: 1,
                  ty: 'fl',
                },
                {
                  a: {a: 0, ix: 1, k: [0, 0]},
                  nm: 'Transform',
                  o: {a: 0, ix: 7, k: 100},
                  p: {a: 0, ix: 2, k: [0, 0]},
                  r: {a: 0, ix: 2, k: 0},
                  s: {a: 0, ix: 6, k: [100, 100]},
                  sa: {a: 0, ix: 5, k: 0},
                  sk: {a: 0, ix: 4, k: 0},
                  ty: 'tr',
                },
              ],
              ix: 1,
              mn: 'ADBE Vector Group',
              nm: 'Path-size',
              np: 3,
              ty: 'gr',
            },
          ],
          sr: 0,
          st: 0,
          tt: 0,
          ty: 4,
        },
        {
          ao: 0,
          bm: 0,
          ddd: 0,
          ef: [],
          hasMask: false,
          hd: false,
          ind: 21,
          ip: 0,
          ks: {
            a: {a: 0, ix: 1, k: [0, 0]},
            o: {a: 0, ix: 7, k: 100},
            p: {a: 0, ix: 2, k: [0, 0]},
            r: {a: 0, ix: 2, k: 0},
            s: {a: 0, ix: 6, k: [100, 100]},
          },
          masksProperties: [],
          nm: 'Path-stroke',
          op: 46,
          parent: 25,
          shapes: [
            {
              bm: 0,
              cix: 2,
              hd: false,
              it: [
                {
                  hd: false,
                  ind: 0,
                  ix: 1,
                  ks: {
                    a: 0,
                    ix: 2,
                    k: {
                      c: false,
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [0, 60.1198978288633],
                        [52.09153709637687, 112.13999999999996],
                        [159.39999999999995, 0],
                      ],
                    },
                  },
                  mn: 'ADBE Vector Shape - Group',
                  nm: 'Path-path',
                  ty: 'sh',
                },
                {
                  bm: 0,
                  c: {a: 0, ix: 3, k: [0.23529411764705882, 0.6235294117647059, 0.47058823529411764, 1]},
                  d: [
                    {n: 'd', nm: 'dash0', v: {a: 0, ix: 0, k: 230}},
                    {
                      n: 'o',
                      nm: 'offset',
                      v: {
                        a: 1,
                        ix: 7,
                        k: [
                          {i: {x: [1], y: [1]}, o: {x: [0], y: [0]}, s: [230], t: 0},
                          {i: {x: [0.58], y: [1]}, o: {x: [0.42], y: [0]}, s: [230], t: 23.1},
                          {i: {x: [0.58], y: [1]}, o: {x: [0.42], y: [0]}, s: [0], t: 45},
                        ],
                      },
                    },
                  ],
                  hd: false,
                  lc: 2,
                  lj: 2,
                  ml: 10,
                  mn: 'ADBE Vector Graphic - Stroke',
                  nm: 'Stroke 1',
                  o: {a: 0, ix: 4, k: 100},
                  ty: 'st',
                  w: {a: 0, ix: 5, k: 12},
                },
                {
                  e: {a: 0, ix: 2, k: 100},
                  hd: false,
                  ix: 3,
                  mn: 'ADBE Vector Filter - Trim',
                  nm: 'Trim Paths 1',
                  o: {a: 0, ix: 3, k: 0},
                  s: {a: 0, ix: 1, k: 0},
                  ty: 'tm',
                },
                {
                  a: {a: 0, ix: 1, k: [0, 0]},
                  nm: 'Transform',
                  o: {a: 0, ix: 7, k: 100},
                  p: {a: 0, ix: 2, k: [0, 0]},
                  r: {a: 0, ix: 2, k: 0},
                  s: {a: 0, ix: 6, k: [100, 100]},
                  sa: {a: 0, ix: 5, k: 0},
                  sk: {a: 0, ix: 4, k: 0},
                  ty: 'tr',
                },
              ],
              ix: 1,
              mn: 'ADBE Vector Group',
              nm: 'Path-stroke',
              np: 3,
              ty: 'gr',
            },
          ],
          sr: 0,
          st: 0,
          tt: 0,
          ty: 4,
        },
        {
          ao: 0,
          bm: 0,
          ddd: 0,
          ef: [],
          hasMask: false,
          hd: false,
          ind: 24,
          ip: 0,
          ks: {
            a: {a: 0, ix: 1, k: [0, 0]},
            o: {a: 0, ix: 7, k: 100},
            p: {a: 0, ix: 2, k: [0, 0]},
            r: {a: 0, ix: 2, k: 0},
            s: {a: 0, ix: 6, k: [100, 100]},
          },
          masksProperties: [],
          nm: 'Path-content',
          op: 46,
          parent: 25,
          shapes: [
            {
              bm: 0,
              cix: 2,
              hd: false,
              it: [
                {
                  hd: false,
                  ind: 0,
                  ix: 1,
                  ks: {
                    a: 0,
                    ix: 2,
                    k: {
                      c: false,
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [0, 60.1198978288633],
                        [52.09153709637687, 112.13999999999996],
                        [159.39999999999995, 0],
                      ],
                    },
                  },
                  mn: 'ADBE Vector Shape - Group',
                  nm: 'Path-path',
                  ty: 'sh',
                },
                {
                  bm: 0,
                  c: {a: 0, ix: 4, k: [0, 0, 0]},
                  hd: false,
                  mn: 'ADBE Vector Graphic - Fill',
                  nm: 'Fill 1',
                  o: {a: 0, ix: 5, k: 0},
                  r: 1,
                  ty: 'fl',
                },
                {
                  a: {a: 0, ix: 1, k: [0, 0]},
                  nm: 'Transform',
                  o: {a: 0, ix: 7, k: 100},
                  p: {a: 0, ix: 2, k: [0, 0]},
                  r: {a: 0, ix: 2, k: 0},
                  s: {a: 0, ix: 6, k: [100, 100]},
                  sa: {a: 0, ix: 5, k: 0},
                  sk: {a: 0, ix: 4, k: 0},
                  ty: 'tr',
                },
              ],
              ix: 1,
              mn: 'ADBE Vector Group',
              nm: 'Path-content',
              np: 3,
              ty: 'gr',
            },
          ],
          sr: 0,
          st: 0,
          tt: 0,
          ty: 4,
        },
      ],
    },
  ],
  ddd: 0,
  fonts: {list: []},
  fr: 30,
  h: 230,
  ip: 0,
  layers: [
    {
      ao: 0,
      bm: 0,
      ddd: 0,
      ef: [
        {
          ef: [
            {ix: 1, mn: 'ADBE Slider Control-0001', nm: 'shadowColor', ty: 2, v: {a: 0, ix: 1, k: [0, 0, 0]}},
            {ix: 2, mn: 'ADBE Slider Control-0001', nm: 'shadowColor.opacity', ty: 0, v: {a: 0, ix: 2, k: 0}},
            {ix: 3, mn: 'ADBE Slider Control-0001', nm: 'shadow.direction', ty: 0, v: {a: 0, ix: 3, k: 90}},
            {ix: 4, mn: 'ADBE Slider Control-0001', nm: 'shadow.distance', ty: 0, v: {a: 0, ix: 3, k: 0}},
            {ix: 5, mn: 'ADBE Slider Control-0001', nm: 'shadowRadius', ty: 0, v: {a: 0, ix: 5, k: 12}},
          ],
          en: 1,
          ix: 1,
          mn: 'ADBE Slider Control',
          nm: 'shadow',
          np: 3,
          ty: 25,
        },
      ],
      h: 230,
      hasMask: false,
      hd: false,
      ind: 20,
      ip: 0,
      ks: {
        a: {a: 0, ix: 1, k: [0, 0]},
        o: {a: 0, ix: 11, k: 100},
        p: {a: 0, ix: 2, k: [0, 0]},
        r: {a: 0, ix: 2, k: 0},
        s: {a: 0, ix: 6, k: [100, 100]},
      },
      masksProperties: [],
      nm: 'Path-composition',
      op: 46,
      refId: 'comp_Path_2B911DDF-AEC3-40ED-BCA8-982F1DC650F6',
      sr: 0,
      st: 0,
      tt: 0,
      ty: 0,
      w: 230,
    },
    {
      ao: 0,
      bm: 0,
      ddd: 0,
      ef: [
        {
          ef: [
            {ix: 1, mn: 'ADBE Slider Control-0001', nm: 'shadowColor', ty: 2, v: {a: 0, ix: 1, k: [0, 0, 0]}},
            {ix: 2, mn: 'ADBE Slider Control-0001', nm: 'shadowColor.opacity', ty: 0, v: {a: 0, ix: 2, k: 0}},
            {ix: 3, mn: 'ADBE Slider Control-0001', nm: 'shadow.direction', ty: 0, v: {a: 0, ix: 3, k: 90}},
            {ix: 4, mn: 'ADBE Slider Control-0001', nm: 'shadow.distance', ty: 0, v: {a: 0, ix: 3, k: 0}},
            {ix: 5, mn: 'ADBE Slider Control-0001', nm: 'shadowRadius', ty: 0, v: {a: 0, ix: 5, k: 12}},
          ],
          en: 1,
          ix: 1,
          mn: 'ADBE Slider Control',
          nm: 'shadow',
          np: 3,
          ty: 25,
        },
      ],
      h: 230,
      hasMask: false,
      hd: false,
      ind: 10,
      ip: 0,
      ks: {
        a: {a: 0, ix: 1, k: [0, 0]},
        o: {a: 0, ix: 11, k: 100},
        p: {a: 0, ix: 2, k: [0, 0]},
        r: {a: 0, ix: 2, k: 0},
        s: {a: 0, ix: 6, k: [100, 100]},
      },
      masksProperties: [],
      nm: 'Oval-composition',
      op: 46,
      refId: 'comp_Oval_8B200EA7-FA79-4E44-8AFB-6A368FCEEE11',
      sr: 0,
      st: 0,
      tt: 0,
      ty: 0,
      w: 230,
    },
    {
      ao: 0,
      bm: 0,
      ddd: 0,
      ef: [],
      hasMask: false,
      hd: false,
      ind: 2,
      ip: 0,
      ks: {
        a: {a: 0, ix: 1, k: [0, 0]},
        o: {a: 0, ix: 7, k: 100},
        p: {a: 0, ix: 2, k: [0, 0]},
        r: {a: 0, ix: 2, k: 0},
        s: {a: 0, ix: 6, k: [100, 100]},
      },
      masksProperties: [],
      nm: 'Scene-background',
      op: 46,
      shapes: [
        {
          bm: 0,
          cix: 2,
          hd: false,
          it: [
            {
              d: 1,
              hd: false,
              mn: 'ADBE Vector Shape - Rect',
              nm: 'Scene-background',
              p: {a: 0, ix: 3, k: [115, 115]},
              r: {a: 0, ix: 1, k: 0},
              s: {a: 0, ix: 2, k: [230, 230]},
              ty: 'rc',
            },
            {
              bm: 0,
              c: {a: 0, ix: 4, k: [1, 1, 1]},
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              o: {a: 0, ix: 5, k: 100},
              r: 1,
              ty: 'fl',
            },
            {
              a: {a: 0, ix: 1, k: [0, 0]},
              nm: 'Transform',
              o: {a: 0, ix: 7, k: 100},
              p: {a: 0, ix: 2, k: [0, 0]},
              r: {a: 0, ix: 2, k: 0},
              s: {a: 0, ix: 6, k: [100, 100]},
              sa: {a: 0, ix: 5, k: 0},
              sk: {a: 0, ix: 4, k: 0},
              ty: 'tr',
            },
          ],
          ix: 1,
          mn: 'ADBE Vector Group',
          nm: 'Scene-background',
          np: 3,
          ty: 'gr',
        },
      ],
      sr: 0,
      st: 0,
      tt: 0,
      ty: 4,
    },
  ],
  markers: [],
  nm: 'SucessDashPattern',
  op: 46,
  v: '5.6.4',
  w: 230,
};

export const ProcessingAnimationData = {
  assets: [
    {
      id: 'maskComp_Path 2 + Oval + Path 2 + Oval + Line 2 + Line + Oval + Oval Mask_47CA0B50-D62B-4DF2-A123-4BB4438A934D',
      layers: [
        {
          ty: 3.0,
          ao: 0.0,
          ddd: 0.0,
          ind: 15.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [105.0, 105.0, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              s: true,
              x: {
                a: 0.0,
                k: 105.0,
              },
              y: {
                a: 0.0,
                k: 105.0,
              },
            },
            r: {
              a: 0.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Path 2 + Oval + Path 2 + Oval + Line 2 + Line + Oval + Oval Mask-null',
          op: 121.0,
          sr: 1.0,
          st: 0.0,
        },
        {
          ty: 4.0,
          ao: 0.0,
          ddd: 0.0,
          ind: 16.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            r: {
              a: 0.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Path 2 + Oval + Path 2 + Oval + Line 2 + Line + Oval + Oval Mask-mask',
          op: 121.0,
          parent: 15.0,
          shapes: [
            {
              ty: 'gr',
              cix: 2.0,
              it: [
                {
                  ty: 'sh',
                  ind: 0.0,
                  ks: {
                    a: 0.0,
                    k: {
                      c: true,
                      i: [
                        [-57.9898977, 0.0],
                        [0.0, 57.9898977],
                        [57.9898977, 0.0],
                        [0.0, -57.9898977],
                      ],
                      o: [
                        [57.9898977, 0.0],
                        [0.0, -57.9898977],
                        [-57.9898977, 0.0],
                        [0.0, 57.9898977],
                      ],
                      v: [
                        [105.0, 210.0],
                        [210.0, 105.0],
                        [105.0, 0.0],
                        [0.0, 105.0],
                      ],
                    },
                  },
                  nm: 'Path 2 + Oval + Path 2 + Oval + Line 2 + Line + Oval + Oval Mask-path',
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0.0,
                    k: [0.0, 0.0, 0.0, 1.0],
                  },
                  nm: 'Fill 1',
                  o: {
                    a: 0.0,
                    k: 100.0,
                  },
                  r: 1.0,
                },
                {
                  ty: 'tr',
                  a: {
                    a: 0.0,
                    k: [0.0, 0.0],
                  },
                  nm: 'Transform',
                  o: {
                    a: 0.0,
                    k: 100.0,
                  },
                  p: {
                    a: 0.0,
                    k: [0.0, 0.0],
                  },
                  r: {
                    a: 0.0,
                    k: 0.0,
                  },
                  s: {
                    a: 0.0,
                    k: [100.0, 100.0],
                  },
                  sa: {
                    a: 0.0,
                    k: 0.0,
                  },
                  sk: {
                    a: 0.0,
                    k: 0.0,
                  },
                },
              ],
              nm: 'Path 2 + Oval + Path 2 + Oval + Line 2 + Line + Oval + Oval Mask-mask',
              np: 3.0,
            },
          ],
          sr: 1.0,
          st: 0.0,
          td: 1.0,
        },
        {
          ty: 0.0,
          ao: 0.0,
          ddd: 0.0,
          h: 212.0,
          ind: 10.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            r: {
              a: 0.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Path 2 + Oval + Path 2 + Oval + Line 2 + Line + Oval + Oval Mask-composition',
          op: 121.0,
          refId:
            'comp_Path 2 + Oval + Path 2 + Oval + Line 2 + Line + Oval + Oval Mask_47CA0B50-D62B-4DF2-A123-4BB4438A934D',
          sr: 1.0,
          st: 0.0,
          tt: 1.0,
          w: 212.0,
        },
      ],
    },
    {
      id: 'comp_Path 2 + Oval + Path 2 + Oval + Line 2 + Line + Oval + Oval Mask_47CA0B50-D62B-4DF2-A123-4BB4438A934D',
      layers: [
        {
          ty: 3.0,
          ao: 0.0,
          ddd: 0.0,
          ind: 15.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [105.0, 105.0, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              s: true,
              x: {
                a: 0.0,
                k: 105.0,
              },
              y: {
                a: 0.0,
                k: 105.0,
              },
            },
            r: {
              a: 0.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Path 2 + Oval + Path 2 + Oval + Line 2 + Line + Oval + Oval Mask-null',
          op: 121.0,
          sr: 1.0,
          st: 0.0,
        },
        {
          ty: 0.0,
          ao: 0.0,
          ddd: 0.0,
          h: 212.0,
          ind: 50.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            r: {
              a: 0.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Oval-composition',
          op: 121.0,
          refId: 'comp_Oval_02159393-3C7B-4BA3-A890-69693FCCB572',
          sr: 1.0,
          st: 0.0,
          w: 212.0,
        },
        {
          ty: 0.0,
          ao: 0.0,
          ddd: 0.0,
          h: 212.0,
          ind: 47.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            r: {
              a: 0.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Group-maskComposition',
          op: 121.0,
          refId: 'maskComp_Group_61732BA6-557E-42B5-9F26-CD143719A7FB',
          sr: 1.0,
          st: 0.0,
          w: 212.0,
        },
        {
          ty: 0.0,
          ao: 0.0,
          ddd: 0.0,
          h: 212.0,
          ind: 37.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            r: {
              a: 0.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Group-maskComposition',
          op: 121.0,
          refId: 'maskComp_Group_D8C1D362-5DE0-4B9A-BFA3-7251420E61DD',
          sr: 1.0,
          st: 0.0,
          w: 212.0,
        },
        {
          ty: 0.0,
          ao: 0.0,
          ddd: 0.0,
          h: 212.0,
          ind: 20.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            r: {
              a: 0.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Mask-composition',
          op: 121.0,
          refId: 'comp_Mask_CB21C5D3-1244-4223-BA38-B11636D93C60',
          sr: 1.0,
          st: 0.0,
          w: 212.0,
        },
      ],
    },
    {
      id: 'comp_Mask_CB21C5D3-1244-4223-BA38-B11636D93C60',
      layers: [
        {
          ty: 3.0,
          ao: 0.0,
          ddd: 0.0,
          ind: 15.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [105.0, 105.0, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              s: true,
              x: {
                a: 0.0,
                k: 105.0,
              },
              y: {
                a: 0.0,
                k: 105.0,
              },
            },
            r: {
              a: 0.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Path 2 + Oval + Path 2 + Oval + Line 2 + Line + Oval + Oval Mask-null',
          op: 121.0,
          sr: 1.0,
          st: 0.0,
        },
        {
          ty: 3.0,
          ao: 0.0,
          ddd: 0.0,
          ind: 25.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [105.0, 105.0, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              s: true,
              x: {
                a: 0.0,
                k: 105.0,
              },
              y: {
                a: 0.0,
                k: 105.0,
              },
            },
            r: {
              a: 0.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Mask-null',
          op: 121.0,
          parent: 15.0,
          sr: 1.0,
          st: 0.0,
        },
        {
          ty: 4.0,
          ao: 0.0,
          ddd: 0.0,
          ind: 28.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            r: {
              a: 0.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Mask-size',
          op: 121.0,
          shapes: [
            {
              ty: 'gr',
              cix: 2.0,
              it: [
                {
                  ty: 'rc',
                  d: 1.0,
                  nm: 'sizeElement',
                  p: {
                    a: 0.0,
                    k: [106.0, 106.0],
                  },
                  r: {
                    a: 0.0,
                    k: 0.0,
                  },
                  s: {
                    a: 0.0,
                    k: [212.0, 212.0],
                  },
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0.0,
                    k: [0.0, 0.0, 0.0, 0.0],
                  },
                  nm: 'Fill 1',
                  o: {
                    a: 0.0,
                    k: 0.0,
                  },
                  r: 1.0,
                },
                {
                  ty: 'tr',
                  a: {
                    a: 0.0,
                    k: [0.0, 0.0],
                  },
                  nm: 'Transform',
                  o: {
                    a: 0.0,
                    k: 100.0,
                  },
                  p: {
                    a: 0.0,
                    k: [0.0, 0.0],
                  },
                  r: {
                    a: 0.0,
                    k: 0.0,
                  },
                  s: {
                    a: 0.0,
                    k: [100.0, 100.0],
                  },
                  sa: {
                    a: 0.0,
                    k: 0.0,
                  },
                  sk: {
                    a: 0.0,
                    k: 0.0,
                  },
                },
              ],
              nm: 'Mask-size',
              np: 3.0,
            },
          ],
          sr: 1.0,
          st: 0.0,
        },
        {
          ty: 4.0,
          ao: 0.0,
          ddd: 0.0,
          ind: 21.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            r: {
              a: 0.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Mask-stroke',
          op: 121.0,
          parent: 25.0,
          shapes: [
            {
              ty: 'gr',
              cix: 2.0,
              it: [
                {
                  ty: 'sh',
                  ind: 0.0,
                  ks: {
                    a: 0.0,
                    k: {
                      c: true,
                      i: [
                        [-57.9898977, 0.0],
                        [0.0, 57.9898977],
                        [57.9898977, 0.0],
                        [0.0, -57.9898977],
                      ],
                      o: [
                        [57.9898977, 0.0],
                        [0.0, -57.9898977],
                        [-57.9898977, 0.0],
                        [0.0, 57.9898977],
                      ],
                      v: [
                        [105.0, 210.0],
                        [210.0, 105.0],
                        [105.0, 0.0],
                        [0.0, 105.0],
                      ],
                    },
                  },
                  nm: 'Mask-path',
                },
                {
                  ty: 'st',
                  c: {
                    a: 0.0,
                    k: [0.11, 0.47100000000000003, 0.678, 1.0],
                  },
                  d: [
                    {
                      n: 'o',
                      nm: 'offset',
                      v: {
                        a: 0.0,
                        k: 0.0,
                      },
                    },
                  ],
                  lc: 1.0,
                  lj: 1.0,
                  ml: 10.0,
                  nm: 'Stroke 1',
                  o: {
                    a: 0.0,
                    k: 100.0,
                  },
                  w: {
                    a: 0.0,
                    k: 8.0,
                  },
                },
                {
                  ty: 'tm',
                  e: {
                    a: 0.0,
                    k: 100.0,
                  },
                  m: 2.0,
                  nm: 'Trim Paths 1',
                  o: {
                    a: 0.0,
                    k: 0.0,
                  },
                  s: {
                    a: 0.0,
                    k: 0.0,
                  },
                },
                {
                  ty: 'tr',
                  a: {
                    a: 0.0,
                    k: [0.0, 0.0],
                  },
                  nm: 'Transform',
                  o: {
                    a: 0.0,
                    k: 100.0,
                  },
                  p: {
                    a: 0.0,
                    k: [0.0, 0.0],
                  },
                  r: {
                    a: 0.0,
                    k: 0.0,
                  },
                  s: {
                    a: 0.0,
                    k: [100.0, 100.0],
                  },
                  sa: {
                    a: 0.0,
                    k: 0.0,
                  },
                  sk: {
                    a: 0.0,
                    k: 0.0,
                  },
                },
              ],
              nm: 'Mask-stroke',
              np: 3.0,
            },
          ],
          sr: 1.0,
          st: 0.0,
        },
        {
          ty: 4.0,
          ao: 0.0,
          ddd: 0.0,
          ind: 24.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            r: {
              a: 0.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Mask-content',
          op: 121.0,
          parent: 25.0,
          shapes: [
            {
              ty: 'gr',
              cix: 2.0,
              it: [
                {
                  ty: 'sh',
                  ind: 0.0,
                  ks: {
                    a: 0.0,
                    k: {
                      c: true,
                      i: [
                        [-57.9898977, 0.0],
                        [0.0, 57.9898977],
                        [57.9898977, 0.0],
                        [0.0, -57.9898977],
                      ],
                      o: [
                        [57.9898977, 0.0],
                        [0.0, -57.9898977],
                        [-57.9898977, 0.0],
                        [0.0, 57.9898977],
                      ],
                      v: [
                        [105.0, 210.0],
                        [210.0, 105.0],
                        [105.0, 0.0],
                        [0.0, 105.0],
                      ],
                    },
                  },
                  nm: 'Mask-path',
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0.0,
                    k: [0.0, 0.0, 0.0, 1.0],
                  },
                  nm: 'Fill 1',
                  o: {
                    a: 0.0,
                    k: 0.0,
                  },
                  r: 1.0,
                },
                {
                  ty: 'tr',
                  a: {
                    a: 0.0,
                    k: [0.0, 0.0],
                  },
                  nm: 'Transform',
                  o: {
                    a: 0.0,
                    k: 100.0,
                  },
                  p: {
                    a: 0.0,
                    k: [0.0, 0.0],
                  },
                  r: {
                    a: 0.0,
                    k: 0.0,
                  },
                  s: {
                    a: 0.0,
                    k: [100.0, 100.0],
                  },
                  sa: {
                    a: 0.0,
                    k: 0.0,
                  },
                  sk: {
                    a: 0.0,
                    k: 0.0,
                  },
                },
              ],
              nm: 'Mask-content',
              np: 3.0,
            },
          ],
          sr: 1.0,
          st: 0.0,
        },
      ],
    },
    {
      id: 'maskComp_Group_D8C1D362-5DE0-4B9A-BFA3-7251420E61DD',
      layers: [
        {
          ty: 3.0,
          ao: 0.0,
          ddd: 0.0,
          ind: 15.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [105.0, 105.0, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              s: true,
              x: {
                a: 0.0,
                k: 105.0,
              },
              y: {
                a: 0.0,
                k: 105.0,
              },
            },
            r: {
              a: 0.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Path 2 + Oval + Path 2 + Oval + Line 2 + Line + Oval + Oval Mask-null',
          op: 121.0,
          sr: 1.0,
          st: 0.0,
        },
        {
          ty: 3.0,
          ao: 0.0,
          ddd: 0.0,
          ind: 35.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [61.865, 61.475, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              s: true,
              x: {
                a: 0.0,
                k: 53.14,
              },
              y: {
                a: 0.0,
                k: 127.08,
              },
            },
            r: {
              a: 0.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Group-null',
          op: 121.0,
          parent: 15.0,
          sr: 1.0,
          st: 0.0,
        },
        {
          ty: 4.0,
          ao: 0.0,
          ddd: 0.0,
          ind: 36.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            r: {
              a: 0.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Group-mask',
          op: 121.0,
          parent: 35.0,
          shapes: [
            {
              ty: 'gr',
              cix: 2.0,
              it: [
                {
                  ty: 'rc',
                  d: 1.0,
                  nm: 'Group-background',
                  p: {
                    a: 0.0,
                    k: [61.865, 61.475],
                  },
                  r: {
                    a: 0.0,
                    k: 0.0,
                  },
                  s: {
                    a: 0.0,
                    k: [123.73, 122.95],
                  },
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0.0,
                    k: [0.0, 0.0, 0.0, 1.0],
                  },
                  nm: 'Fill 1',
                  o: {
                    a: 0.0,
                    k: 0.0,
                  },
                  r: 1.0,
                },
                {
                  ty: 'tr',
                  a: {
                    a: 0.0,
                    k: [0.0, 0.0],
                  },
                  nm: 'Transform',
                  o: {
                    a: 0.0,
                    k: 100.0,
                  },
                  p: {
                    a: 0.0,
                    k: [0.0, 0.0],
                  },
                  r: {
                    a: 0.0,
                    k: 0.0,
                  },
                  s: {
                    a: 0.0,
                    k: [100.0, 100.0],
                  },
                  sa: {
                    a: 0.0,
                    k: 0.0,
                  },
                  sk: {
                    a: 0.0,
                    k: 0.0,
                  },
                },
              ],
              nm: 'Group-mask',
              np: 3.0,
            },
          ],
          sr: 1.0,
          st: 0.0,
          td: 1.0,
        },
        {
          ty: 0.0,
          ao: 0.0,
          ddd: 0.0,
          h: 212.0,
          ind: 30.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            r: {
              a: 0.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Group-composition',
          op: 121.0,
          refId: 'comp_Group_D8C1D362-5DE0-4B9A-BFA3-7251420E61DD',
          sr: 1.0,
          st: 0.0,
          w: 212.0,
        },
      ],
    },
    {
      id: 'comp_Group_D8C1D362-5DE0-4B9A-BFA3-7251420E61DD',
      layers: [
        {
          ty: 3.0,
          ao: 0.0,
          ddd: 0.0,
          ind: 15.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [105.0, 105.0, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              s: true,
              x: {
                a: 0.0,
                k: 105.0,
              },
              y: {
                a: 0.0,
                k: 105.0,
              },
            },
            r: {
              a: 0.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Path 2 + Oval + Path 2 + Oval + Line 2 + Line + Oval + Oval Mask-null',
          op: 121.0,
          sr: 1.0,
          st: 0.0,
        },
        {
          ty: 3.0,
          ao: 0.0,
          ddd: 0.0,
          ind: 35.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [61.865, 61.475, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              s: true,
              x: {
                a: 0.0,
                k: 53.14,
              },
              y: {
                a: 0.0,
                k: 127.08,
              },
            },
            r: {
              a: 0.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Group-null',
          op: 121.0,
          parent: 15.0,
          sr: 1.0,
          st: 0.0,
        },
        {
          ty: 0.0,
          ao: 0.0,
          ddd: 0.0,
          h: 212.0,
          ind: 70.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            r: {
              a: 0.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Oval-composition',
          op: 121.0,
          refId: 'comp_Oval_95D2E32C-90D2-40B8-9E35-12E765845A13',
          sr: 1.0,
          st: 0.0,
          w: 212.0,
        },
        {
          ty: 0.0,
          ao: 0.0,
          ddd: 0.0,
          h: 212.0,
          ind: 60.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            r: {
              a: 0.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Path 2-composition',
          op: 121.0,
          refId: 'comp_Path 2_CC308850-F666-40A4-8F4E-EA5C84CC1BFC',
          sr: 1.0,
          st: 0.0,
          w: 212.0,
        },
      ],
    },
    {
      id: 'maskComp_Group_61732BA6-557E-42B5-9F26-CD143719A7FB',
      layers: [
        {
          ty: 3.0,
          ao: 0.0,
          ddd: 0.0,
          ind: 15.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [105.0, 105.0, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              s: true,
              x: {
                a: 0.0,
                k: 105.0,
              },
              y: {
                a: 0.0,
                k: 105.0,
              },
            },
            r: {
              a: 0.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Path 2 + Oval + Path 2 + Oval + Line 2 + Line + Oval + Oval Mask-null',
          op: 121.0,
          sr: 1.0,
          st: 0.0,
        },
        {
          ty: 3.0,
          ao: 0.0,
          ddd: 0.0,
          ind: 45.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [60.14, 60.245000000000005, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              s: true,
              x: {
                a: 0.0,
                k: 158.69,
              },
              y: {
                a: 0.0,
                k: 82.4,
              },
            },
            r: {
              a: 0.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Group-null',
          op: 121.0,
          parent: 15.0,
          sr: 1.0,
          st: 0.0,
        },
        {
          ty: 4.0,
          ao: 0.0,
          ddd: 0.0,
          ind: 46.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            r: {
              a: 0.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Group-mask',
          op: 121.0,
          parent: 45.0,
          shapes: [
            {
              ty: 'gr',
              cix: 2.0,
              it: [
                {
                  ty: 'rc',
                  d: 1.0,
                  nm: 'Group-background',
                  p: {
                    a: 0.0,
                    k: [60.14, 60.245000000000005],
                  },
                  r: {
                    a: 0.0,
                    k: 0.0,
                  },
                  s: {
                    a: 0.0,
                    k: [120.28, 120.49000000000001],
                  },
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0.0,
                    k: [0.0, 0.0, 0.0, 1.0],
                  },
                  nm: 'Fill 1',
                  o: {
                    a: 0.0,
                    k: 0.0,
                  },
                  r: 1.0,
                },
                {
                  ty: 'tr',
                  a: {
                    a: 0.0,
                    k: [0.0, 0.0],
                  },
                  nm: 'Transform',
                  o: {
                    a: 0.0,
                    k: 100.0,
                  },
                  p: {
                    a: 0.0,
                    k: [0.0, 0.0],
                  },
                  r: {
                    a: 0.0,
                    k: 0.0,
                  },
                  s: {
                    a: 0.0,
                    k: [100.0, 100.0],
                  },
                  sa: {
                    a: 0.0,
                    k: 0.0,
                  },
                  sk: {
                    a: 0.0,
                    k: 0.0,
                  },
                },
              ],
              nm: 'Group-mask',
              np: 3.0,
            },
          ],
          sr: 1.0,
          st: 0.0,
          td: 1.0,
        },
        {
          ty: 0.0,
          ao: 0.0,
          ddd: 0.0,
          h: 212.0,
          ind: 40.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            r: {
              a: 0.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Group-composition',
          op: 121.0,
          refId: 'comp_Group_61732BA6-557E-42B5-9F26-CD143719A7FB',
          sr: 1.0,
          st: 0.0,
          w: 212.0,
        },
      ],
    },
    {
      id: 'comp_Group_61732BA6-557E-42B5-9F26-CD143719A7FB',
      layers: [
        {
          ty: 3.0,
          ao: 0.0,
          ddd: 0.0,
          ind: 15.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [105.0, 105.0, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              s: true,
              x: {
                a: 0.0,
                k: 105.0,
              },
              y: {
                a: 0.0,
                k: 105.0,
              },
            },
            r: {
              a: 0.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Path 2 + Oval + Path 2 + Oval + Line 2 + Line + Oval + Oval Mask-null',
          op: 121.0,
          sr: 1.0,
          st: 0.0,
        },
        {
          ty: 3.0,
          ao: 0.0,
          ddd: 0.0,
          ind: 45.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [60.14, 60.245000000000005, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              s: true,
              x: {
                a: 0.0,
                k: 158.69,
              },
              y: {
                a: 0.0,
                k: 82.4,
              },
            },
            r: {
              a: 0.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Group-null',
          op: 121.0,
          parent: 15.0,
          sr: 1.0,
          st: 0.0,
        },
        {
          ty: 0.0,
          ao: 0.0,
          ddd: 0.0,
          h: 212.0,
          ind: 120.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            r: {
              a: 0.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Oval-composition',
          op: 121.0,
          refId: 'comp_Oval_A8C08D79-DFEB-49C8-B443-08F887689544',
          sr: 1.0,
          st: 0.0,
          w: 212.0,
        },
        {
          ty: 0.0,
          ao: 0.0,
          ddd: 0.0,
          h: 212.0,
          ind: 110.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            r: {
              a: 0.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Line-composition',
          op: 121.0,
          refId: 'comp_Line_CBE91C0B-8627-4105-8C0A-8281841ED454',
          sr: 1.0,
          st: 0.0,
          w: 212.0,
        },
        {
          ty: 0.0,
          ao: 0.0,
          ddd: 0.0,
          h: 212.0,
          ind: 100.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            r: {
              a: 0.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Line 2-composition',
          op: 121.0,
          refId: 'comp_Line 2_FFC194C5-34CD-4E1B-85E8-23F31B13DCA1',
          sr: 1.0,
          st: 0.0,
          w: 212.0,
        },
        {
          ty: 0.0,
          ao: 0.0,
          ddd: 0.0,
          h: 212.0,
          ind: 90.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            r: {
              a: 0.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Oval-composition',
          op: 121.0,
          refId: 'comp_Oval_E353A2AA-86B5-494C-97FC-22066464403B',
          sr: 1.0,
          st: 0.0,
          w: 212.0,
        },
        {
          ty: 0.0,
          ao: 0.0,
          ddd: 0.0,
          h: 212.0,
          ind: 80.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            r: {
              a: 0.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Path 2-composition',
          op: 121.0,
          refId: 'comp_Path 2_9349646B-6CFF-44C3-BA34-582424BB4DC7',
          sr: 1.0,
          st: 0.0,
          w: 212.0,
        },
      ],
    },
    {
      id: 'comp_Oval_02159393-3C7B-4BA3-A890-69693FCCB572',
      layers: [
        {
          ty: 3.0,
          ao: 0.0,
          ddd: 0.0,
          ind: 15.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [105.0, 105.0, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              s: true,
              x: {
                a: 0.0,
                k: 105.0,
              },
              y: {
                a: 0.0,
                k: 105.0,
              },
            },
            r: {
              a: 0.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Path 2 + Oval + Path 2 + Oval + Line 2 + Line + Oval + Oval Mask-null',
          op: 121.0,
          sr: 1.0,
          st: 0.0,
        },
        {
          ty: 3.0,
          ao: 0.0,
          ddd: 0.0,
          ind: 55.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [105.0, 105.0, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              s: true,
              x: {
                a: 0.0,
                k: 105.0,
              },
              y: {
                a: 0.0,
                k: 105.0,
              },
            },
            r: {
              a: 0.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Oval-null',
          op: 121.0,
          parent: 15.0,
          sr: 1.0,
          st: 0.0,
        },
        {
          ty: 4.0,
          ao: 0.0,
          ddd: 0.0,
          ind: 58.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            r: {
              a: 0.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Oval-size',
          op: 121.0,
          shapes: [
            {
              ty: 'gr',
              cix: 2.0,
              it: [
                {
                  ty: 'rc',
                  d: 1.0,
                  nm: 'sizeElement',
                  p: {
                    a: 0.0,
                    k: [106.0, 106.0],
                  },
                  r: {
                    a: 0.0,
                    k: 0.0,
                  },
                  s: {
                    a: 0.0,
                    k: [212.0, 212.0],
                  },
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0.0,
                    k: [0.0, 0.0, 0.0, 0.0],
                  },
                  nm: 'Fill 1',
                  o: {
                    a: 0.0,
                    k: 0.0,
                  },
                  r: 1.0,
                },
                {
                  ty: 'tr',
                  a: {
                    a: 0.0,
                    k: [0.0, 0.0],
                  },
                  nm: 'Transform',
                  o: {
                    a: 0.0,
                    k: 100.0,
                  },
                  p: {
                    a: 0.0,
                    k: [0.0, 0.0],
                  },
                  r: {
                    a: 0.0,
                    k: 0.0,
                  },
                  s: {
                    a: 0.0,
                    k: [100.0, 100.0],
                  },
                  sa: {
                    a: 0.0,
                    k: 0.0,
                  },
                  sk: {
                    a: 0.0,
                    k: 0.0,
                  },
                },
              ],
              nm: 'Oval-size',
              np: 3.0,
            },
          ],
          sr: 1.0,
          st: 0.0,
        },
        {
          ty: 4.0,
          ao: 0.0,
          ddd: 0.0,
          ind: 51.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            r: {
              a: 0.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Oval-stroke',
          op: 121.0,
          parent: 55.0,
          shapes: [
            {
              ty: 'gr',
              cix: 2.0,
              it: [
                {
                  ty: 'sh',
                  ind: 0.0,
                  ks: {
                    a: 0.0,
                    k: {
                      c: true,
                      i: [
                        [-57.9898977, 0.0],
                        [0.0, 57.9898977],
                        [57.9898977, 0.0],
                        [0.0, -57.9898977],
                      ],
                      o: [
                        [57.9898977, 0.0],
                        [0.0, -57.9898977],
                        [-57.9898977, 0.0],
                        [0.0, 57.9898977],
                      ],
                      v: [
                        [105.0, 210.0],
                        [210.0, 105.0],
                        [105.0, 0.0],
                        [0.0, 105.0],
                      ],
                    },
                  },
                  nm: 'Oval-path',
                },
                {
                  ty: 'st',
                  c: {
                    a: 0.0,
                    k: [0.11, 0.47100000000000003, 0.678, 1.0],
                  },
                  d: [
                    {
                      n: 'o',
                      nm: 'offset',
                      v: {
                        a: 0.0,
                        k: 0.0,
                      },
                    },
                  ],
                  lc: 1.0,
                  lj: 1.0,
                  ml: 10.0,
                  nm: 'Stroke 1',
                  o: {
                    a: 0.0,
                    k: 100.0,
                  },
                  w: {
                    a: 0.0,
                    k: 8.0,
                  },
                },
                {
                  ty: 'tm',
                  e: {
                    a: 0.0,
                    k: 100.0,
                  },
                  m: 2.0,
                  nm: 'Trim Paths 1',
                  o: {
                    a: 0.0,
                    k: 0.0,
                  },
                  s: {
                    a: 0.0,
                    k: 0.0,
                  },
                },
                {
                  ty: 'tr',
                  a: {
                    a: 0.0,
                    k: [0.0, 0.0],
                  },
                  nm: 'Transform',
                  o: {
                    a: 0.0,
                    k: 100.0,
                  },
                  p: {
                    a: 0.0,
                    k: [0.0, 0.0],
                  },
                  r: {
                    a: 0.0,
                    k: 0.0,
                  },
                  s: {
                    a: 0.0,
                    k: [100.0, 100.0],
                  },
                  sa: {
                    a: 0.0,
                    k: 0.0,
                  },
                  sk: {
                    a: 0.0,
                    k: 0.0,
                  },
                },
              ],
              nm: 'Oval-stroke',
              np: 3.0,
            },
          ],
          sr: 1.0,
          st: 0.0,
        },
        {
          ty: 4.0,
          ao: 0.0,
          ddd: 0.0,
          ind: 54.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            r: {
              a: 0.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Oval-content',
          op: 121.0,
          parent: 55.0,
          shapes: [
            {
              ty: 'gr',
              cix: 2.0,
              it: [
                {
                  ty: 'sh',
                  ind: 0.0,
                  ks: {
                    a: 0.0,
                    k: {
                      c: true,
                      i: [
                        [-57.9898977, 0.0],
                        [0.0, 57.9898977],
                        [57.9898977, 0.0],
                        [0.0, -57.9898977],
                      ],
                      o: [
                        [57.9898977, 0.0],
                        [0.0, -57.9898977],
                        [-57.9898977, 0.0],
                        [0.0, 57.9898977],
                      ],
                      v: [
                        [105.0, 210.0],
                        [210.0, 105.0],
                        [105.0, 0.0],
                        [0.0, 105.0],
                      ],
                    },
                  },
                  nm: 'Oval-path',
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0.0,
                    k: [0.0, 0.0, 0.0, 1.0],
                  },
                  nm: 'Fill 1',
                  o: {
                    a: 0.0,
                    k: 0.0,
                  },
                  r: 1.0,
                },
                {
                  ty: 'tr',
                  a: {
                    a: 0.0,
                    k: [0.0, 0.0],
                  },
                  nm: 'Transform',
                  o: {
                    a: 0.0,
                    k: 100.0,
                  },
                  p: {
                    a: 0.0,
                    k: [0.0, 0.0],
                  },
                  r: {
                    a: 0.0,
                    k: 0.0,
                  },
                  s: {
                    a: 0.0,
                    k: [100.0, 100.0],
                  },
                  sa: {
                    a: 0.0,
                    k: 0.0,
                  },
                  sk: {
                    a: 0.0,
                    k: 0.0,
                  },
                },
              ],
              nm: 'Oval-content',
              np: 3.0,
            },
          ],
          sr: 1.0,
          st: 0.0,
        },
      ],
    },
    {
      id: 'comp_Path 2_9349646B-6CFF-44C3-BA34-582424BB4DC7',
      layers: [
        {
          ty: 3.0,
          ao: 0.0,
          ddd: 0.0,
          ind: 15.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [105.0, 105.0, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              s: true,
              x: {
                a: 0.0,
                k: 105.0,
              },
              y: {
                a: 0.0,
                k: 105.0,
              },
            },
            r: {
              a: 0.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Path 2 + Oval + Path 2 + Oval + Line 2 + Line + Oval + Oval Mask-null',
          op: 121.0,
          sr: 1.0,
          st: 0.0,
        },
        {
          ty: 3.0,
          ao: 0.0,
          ddd: 0.0,
          ind: 45.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [60.14, 60.245000000000005, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              s: true,
              x: {
                a: 0.0,
                k: 158.69,
              },
              y: {
                a: 0.0,
                k: 82.4,
              },
            },
            r: {
              a: 0.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Group-null',
          op: 121.0,
          parent: 15.0,
          sr: 1.0,
          st: 0.0,
        },
        {
          ty: 3.0,
          ao: 0.0,
          ddd: 0.0,
          ind: 85.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [60.14, 60.245000000000005, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              s: true,
              x: {
                a: 0.0,
                k: 60.14,
              },
              y: {
                a: 0.0,
                k: 60.25,
              },
            },
            r: {
              a: 1.0,
              k: [
                {
                  i: {
                    x: [1.0],
                    y: [1.0],
                  },
                  o: {
                    x: [0.0],
                    y: [0.0],
                  },
                  s: [0.0],
                  t: 0.0,
                },
                {
                  i: {
                    x: [0.5799999833106995],
                    y: [1.0],
                  },
                  o: {
                    x: [0.41999998688697815],
                    y: [0.0],
                  },
                  s: [360.0],
                  t: 120.0,
                },
              ],
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Path 2-null',
          op: 121.0,
          parent: 45.0,
          sr: 1.0,
          st: 0.0,
        },
        {
          ty: 4.0,
          ao: 0.0,
          ddd: 0.0,
          ind: 88.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            r: {
              a: 0.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Path 2-size',
          op: 121.0,
          shapes: [
            {
              ty: 'gr',
              cix: 2.0,
              it: [
                {
                  ty: 'rc',
                  d: 1.0,
                  nm: 'sizeElement',
                  p: {
                    a: 0.0,
                    k: [106.0, 106.0],
                  },
                  r: {
                    a: 0.0,
                    k: 0.0,
                  },
                  s: {
                    a: 0.0,
                    k: [212.0, 212.0],
                  },
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0.0,
                    k: [0.0, 0.0, 0.0, 0.0],
                  },
                  nm: 'Fill 1',
                  o: {
                    a: 0.0,
                    k: 0.0,
                  },
                  r: 1.0,
                },
                {
                  ty: 'tr',
                  a: {
                    a: 0.0,
                    k: [0.0, 0.0],
                  },
                  nm: 'Transform',
                  o: {
                    a: 0.0,
                    k: 100.0,
                  },
                  p: {
                    a: 0.0,
                    k: [0.0, 0.0],
                  },
                  r: {
                    a: 0.0,
                    k: 0.0,
                  },
                  s: {
                    a: 0.0,
                    k: [100.0, 100.0],
                  },
                  sa: {
                    a: 0.0,
                    k: 0.0,
                  },
                  sk: {
                    a: 0.0,
                    k: 0.0,
                  },
                },
              ],
              nm: 'Path 2-size',
              np: 3.0,
            },
          ],
          sr: 1.0,
          st: 0.0,
        },
        {
          ty: 4.0,
          ao: 0.0,
          ddd: 0.0,
          ind: 81.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            r: {
              a: 0.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Path 2-stroke',
          op: 121.0,
          parent: 85.0,
          shapes: [
            {
              ty: 'gr',
              cix: 2.0,
              it: [
                {
                  ty: 'sh',
                  ind: 0.0,
                  ks: {
                    a: 0.0,
                    k: {
                      c: true,
                      i: [
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [-0.050446634799999224, -3.2883287370000005],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [-2.2757144392000015, -2.2092877713999997],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [-3.331019886399993, 0.019851932400001715],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [-2.3279376095999993, 2.400219840100007],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [-0.05582916480001643, 3.3362632737000055],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [2.3724676712000132, 2.2746234739000037],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [3.2753013791999948, 0.010927238100000736],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [2.2882091256000017, -2.3669706246000004],
                        [0.0, 0.0],
                        [0.0, 0.0],
                      ],
                      o: [
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.05044663480000011, 3.2883299418999954],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [2.2757144392000015, 2.2092889763000017],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [3.331019886400007, -0.019851932400001715],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [2.3279376095999993, -2.400218635199991],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.05582916479998801, -3.3362620687999964],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [-2.372466468399992, -2.2746246787999986],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [-3.275300176400002, -0.01092723809999896],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [-2.288210328399998, 2.3669694196999984],
                        [0.0, 0.0],
                        [0.0, 0.0],
                      ],
                      v: [
                        [0.0, 44.389736563700005],
                        [8.905842725200001, 50.9734065557],
                        [7.9770982996, 60.95335348060001],
                        [8.785644515600001, 70.6650197813],
                        [0.3414977732, 77.05982892770001],
                        [6.3591891664, 91.23607264830001],
                        [16.9972815984, 90.00967000290001],
                        [23.4759424832, 97.46492127850001],
                        [31.2326421164, 103.57000619540001],
                        [29.7837131524, 114.55303718990001],
                        [44.370786824, 120.49500033500001],
                        [51.021284528, 111.46177707730001],
                        [60.552662638, 112.24007259310001],
                        [70.4731020444, 111.34584762380001],
                        [76.9973310072, 119.89449865830001],
                        [91.31008929960001, 113.81933020350002],
                        [89.8701801328, 103.11259603290002],
                        [97.2817820124, 96.6500919898],
                        [103.45466828400001, 89.6628443575],
                        [114.6005335612, 90.68540081600001],
                        [120.28, 76.1411360541],
                        [111.5084270416, 69.496282445],
                        [112.40718325760001, 60.5160253864],
                        [111.1792519544, 50.033349600200005],
                        [120.2597063584, 42.8900517542],
                        [114.2420137624, 28.713808033600003],
                        [102.9676160744, 30.688699378600003],
                        [96.46650372479999, 22.9635999039],
                        [88.7725505592, 16.6924689229],
                        [90.4962868476, 5.9778354279000006],
                        [76.1074899448, 0.005000335],
                        [69.15289433080001, 8.8560704254],
                        [59.718893706, 8.1609142145],
                        [49.9264334224, 9.014685871200001],
                        [43.2689811288, 0.6459746027000001],
                        [28.9562228364, 6.7211442624],
                        [30.529355333999998, 17.2479374621],
                        [22.9897743316, 23.750894817800003],
                        [16.796439257200003, 30.8362297444],
                        [5.8777432076, 29.814599854000004],
                      ],
                    },
                  },
                  nm: 'Path 2-path',
                },
                {
                  ty: 'st',
                  c: {
                    a: 0.0,
                    k: [0.11, 0.47100000000000003, 0.678, 1.0],
                  },
                  d: [
                    {
                      n: 'o',
                      nm: 'offset',
                      v: {
                        a: 0.0,
                        k: 0.0,
                      },
                    },
                  ],
                  lc: 2.0,
                  lj: 2.0,
                  ml: 10.0,
                  nm: 'Stroke 1',
                  o: {
                    a: 0.0,
                    k: 100.0,
                  },
                  w: {
                    a: 0.0,
                    k: 5.0,
                  },
                },
                {
                  ty: 'tm',
                  e: {
                    a: 0.0,
                    k: 100.0,
                  },
                  m: 2.0,
                  nm: 'Trim Paths 1',
                  o: {
                    a: 0.0,
                    k: 0.0,
                  },
                  s: {
                    a: 0.0,
                    k: 0.0,
                  },
                },
                {
                  ty: 'tr',
                  a: {
                    a: 0.0,
                    k: [0.0, 0.0],
                  },
                  nm: 'Transform',
                  o: {
                    a: 0.0,
                    k: 100.0,
                  },
                  p: {
                    a: 0.0,
                    k: [0.0, 0.0],
                  },
                  r: {
                    a: 0.0,
                    k: 0.0,
                  },
                  s: {
                    a: 0.0,
                    k: [100.0, 100.0],
                  },
                  sa: {
                    a: 0.0,
                    k: 0.0,
                  },
                  sk: {
                    a: 0.0,
                    k: 0.0,
                  },
                },
              ],
              nm: 'Path 2-stroke',
              np: 3.0,
            },
          ],
          sr: 1.0,
          st: 0.0,
        },
        {
          ty: 4.0,
          ao: 0.0,
          ddd: 0.0,
          ind: 84.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            r: {
              a: 0.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Path 2-content',
          op: 121.0,
          parent: 85.0,
          shapes: [
            {
              ty: 'gr',
              cix: 2.0,
              it: [
                {
                  ty: 'sh',
                  ind: 0.0,
                  ks: {
                    a: 0.0,
                    k: {
                      c: true,
                      i: [
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [-0.050446634799999224, -3.2883287370000005],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [-2.2757144392000015, -2.2092877713999997],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [-3.331019886399993, 0.019851932400001715],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [-2.3279376095999993, 2.400219840100007],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [-0.05582916480001643, 3.3362632737000055],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [2.3724676712000132, 2.2746234739000037],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [3.2753013791999948, 0.010927238100000736],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [2.2882091256000017, -2.3669706246000004],
                        [0.0, 0.0],
                        [0.0, 0.0],
                      ],
                      o: [
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.05044663480000011, 3.2883299418999954],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [2.2757144392000015, 2.2092889763000017],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [3.331019886400007, -0.019851932400001715],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [2.3279376095999993, -2.400218635199991],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.05582916479998801, -3.3362620687999964],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [-2.372466468399992, -2.2746246787999986],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [-3.275300176400002, -0.01092723809999896],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [-2.288210328399998, 2.3669694196999984],
                        [0.0, 0.0],
                        [0.0, 0.0],
                      ],
                      v: [
                        [0.0, 44.389736563700005],
                        [8.905842725200001, 50.9734065557],
                        [7.9770982996, 60.95335348060001],
                        [8.785644515600001, 70.6650197813],
                        [0.3414977732, 77.05982892770001],
                        [6.3591891664, 91.23607264830001],
                        [16.9972815984, 90.00967000290001],
                        [23.4759424832, 97.46492127850001],
                        [31.2326421164, 103.57000619540001],
                        [29.7837131524, 114.55303718990001],
                        [44.370786824, 120.49500033500001],
                        [51.021284528, 111.46177707730001],
                        [60.552662638, 112.24007259310001],
                        [70.4731020444, 111.34584762380001],
                        [76.9973310072, 119.89449865830001],
                        [91.31008929960001, 113.81933020350002],
                        [89.8701801328, 103.11259603290002],
                        [97.2817820124, 96.6500919898],
                        [103.45466828400001, 89.6628443575],
                        [114.6005335612, 90.68540081600001],
                        [120.28, 76.1411360541],
                        [111.5084270416, 69.496282445],
                        [112.40718325760001, 60.5160253864],
                        [111.1792519544, 50.033349600200005],
                        [120.2597063584, 42.8900517542],
                        [114.2420137624, 28.713808033600003],
                        [102.9676160744, 30.688699378600003],
                        [96.46650372479999, 22.9635999039],
                        [88.7725505592, 16.6924689229],
                        [90.4962868476, 5.9778354279000006],
                        [76.1074899448, 0.005000335],
                        [69.15289433080001, 8.8560704254],
                        [59.718893706, 8.1609142145],
                        [49.9264334224, 9.014685871200001],
                        [43.2689811288, 0.6459746027000001],
                        [28.9562228364, 6.7211442624],
                        [30.529355333999998, 17.2479374621],
                        [22.9897743316, 23.750894817800003],
                        [16.796439257200003, 30.8362297444],
                        [5.8777432076, 29.814599854000004],
                      ],
                    },
                  },
                  nm: 'Path 2-path',
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0.0,
                    k: [1.0, 1.0, 1.0, 1.0],
                  },
                  nm: 'Fill 1',
                  o: {
                    a: 0.0,
                    k: 100.0,
                  },
                  r: 1.0,
                },
                {
                  ty: 'tr',
                  a: {
                    a: 0.0,
                    k: [0.0, 0.0],
                  },
                  nm: 'Transform',
                  o: {
                    a: 0.0,
                    k: 100.0,
                  },
                  p: {
                    a: 0.0,
                    k: [0.0, 0.0],
                  },
                  r: {
                    a: 0.0,
                    k: 0.0,
                  },
                  s: {
                    a: 0.0,
                    k: [100.0, 100.0],
                  },
                  sa: {
                    a: 0.0,
                    k: 0.0,
                  },
                  sk: {
                    a: 0.0,
                    k: 0.0,
                  },
                },
              ],
              nm: 'Path 2-content',
              np: 3.0,
            },
          ],
          sr: 1.0,
          st: 0.0,
        },
      ],
    },
    {
      id: 'comp_Oval_E353A2AA-86B5-494C-97FC-22066464403B',
      layers: [
        {
          ty: 3.0,
          ao: 0.0,
          ddd: 0.0,
          ind: 15.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [105.0, 105.0, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              s: true,
              x: {
                a: 0.0,
                k: 105.0,
              },
              y: {
                a: 0.0,
                k: 105.0,
              },
            },
            r: {
              a: 0.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Path 2 + Oval + Path 2 + Oval + Line 2 + Line + Oval + Oval Mask-null',
          op: 121.0,
          sr: 1.0,
          st: 0.0,
        },
        {
          ty: 3.0,
          ao: 0.0,
          ddd: 0.0,
          ind: 45.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [60.14, 60.245000000000005, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              s: true,
              x: {
                a: 0.0,
                k: 158.69,
              },
              y: {
                a: 0.0,
                k: 82.4,
              },
            },
            r: {
              a: 0.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Group-null',
          op: 121.0,
          parent: 15.0,
          sr: 1.0,
          st: 0.0,
        },
        {
          ty: 3.0,
          ao: 0.0,
          ddd: 0.0,
          ind: 95.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [33.07, 33.07, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              s: true,
              x: {
                a: 0.0,
                k: 60.83,
              },
              y: {
                a: 0.0,
                k: 60.43,
              },
            },
            r: {
              a: 1.0,
              k: [
                {
                  i: {
                    x: [1.0],
                    y: [1.0],
                  },
                  o: {
                    x: [0.0],
                    y: [0.0],
                  },
                  s: [0.0],
                  t: 0.0,
                },
                {
                  i: {
                    x: [0.5799999833106995],
                    y: [1.0],
                  },
                  o: {
                    x: [0.41999998688697815],
                    y: [0.0],
                  },
                  s: [360.0],
                  t: 120.0,
                },
              ],
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Oval-null',
          op: 121.0,
          parent: 45.0,
          sr: 1.0,
          st: 0.0,
        },
        {
          ty: 4.0,
          ao: 0.0,
          ddd: 0.0,
          ind: 98.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            r: {
              a: 0.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Oval-size',
          op: 121.0,
          shapes: [
            {
              ty: 'gr',
              cix: 2.0,
              it: [
                {
                  ty: 'rc',
                  d: 1.0,
                  nm: 'sizeElement',
                  p: {
                    a: 0.0,
                    k: [106.0, 106.0],
                  },
                  r: {
                    a: 0.0,
                    k: 0.0,
                  },
                  s: {
                    a: 0.0,
                    k: [212.0, 212.0],
                  },
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0.0,
                    k: [0.0, 0.0, 0.0, 0.0],
                  },
                  nm: 'Fill 1',
                  o: {
                    a: 0.0,
                    k: 0.0,
                  },
                  r: 1.0,
                },
                {
                  ty: 'tr',
                  a: {
                    a: 0.0,
                    k: [0.0, 0.0],
                  },
                  nm: 'Transform',
                  o: {
                    a: 0.0,
                    k: 100.0,
                  },
                  p: {
                    a: 0.0,
                    k: [0.0, 0.0],
                  },
                  r: {
                    a: 0.0,
                    k: 0.0,
                  },
                  s: {
                    a: 0.0,
                    k: [100.0, 100.0],
                  },
                  sa: {
                    a: 0.0,
                    k: 0.0,
                  },
                  sk: {
                    a: 0.0,
                    k: 0.0,
                  },
                },
              ],
              nm: 'Oval-size',
              np: 3.0,
            },
          ],
          sr: 1.0,
          st: 0.0,
        },
        {
          ty: 4.0,
          ao: 0.0,
          ddd: 0.0,
          ind: 91.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            r: {
              a: 0.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Oval-stroke',
          op: 121.0,
          parent: 95.0,
          shapes: [
            {
              ty: 'gr',
              cix: 2.0,
              it: [
                {
                  ty: 'sh',
                  ind: 0.0,
                  ks: {
                    a: 0.0,
                    k: {
                      c: true,
                      i: [
                        [-18.2640563518, 0.0],
                        [0.0, 18.2640563518],
                        [18.2640563518, 0.0],
                        [0.0, -18.2640563518],
                      ],
                      o: [
                        [18.2640563518, 0.0],
                        [0.0, -18.2640563518],
                        [-18.2640563518, 0.0],
                        [0.0, 18.2640563518],
                      ],
                      v: [
                        [33.07, 66.14],
                        [66.14, 33.07],
                        [33.07, 0.0],
                        [0.0, 33.07],
                      ],
                    },
                  },
                  nm: 'Oval-path',
                },
                {
                  ty: 'st',
                  c: {
                    a: 0.0,
                    k: [0.11, 0.47100000000000003, 0.678, 1.0],
                  },
                  d: [
                    {
                      n: 'o',
                      nm: 'offset',
                      v: {
                        a: 0.0,
                        k: 0.0,
                      },
                    },
                  ],
                  lc: 1.0,
                  lj: 1.0,
                  ml: 10.0,
                  nm: 'Stroke 1',
                  o: {
                    a: 0.0,
                    k: 100.0,
                  },
                  w: {
                    a: 0.0,
                    k: 5.0,
                  },
                },
                {
                  ty: 'tm',
                  e: {
                    a: 0.0,
                    k: 100.0,
                  },
                  m: 2.0,
                  nm: 'Trim Paths 1',
                  o: {
                    a: 0.0,
                    k: 0.0,
                  },
                  s: {
                    a: 0.0,
                    k: 0.0,
                  },
                },
                {
                  ty: 'tr',
                  a: {
                    a: 0.0,
                    k: [0.0, 0.0],
                  },
                  nm: 'Transform',
                  o: {
                    a: 0.0,
                    k: 100.0,
                  },
                  p: {
                    a: 0.0,
                    k: [0.0, 0.0],
                  },
                  r: {
                    a: 0.0,
                    k: 0.0,
                  },
                  s: {
                    a: 0.0,
                    k: [100.0, 100.0],
                  },
                  sa: {
                    a: 0.0,
                    k: 0.0,
                  },
                  sk: {
                    a: 0.0,
                    k: 0.0,
                  },
                },
              ],
              nm: 'Oval-stroke',
              np: 3.0,
            },
          ],
          sr: 1.0,
          st: 0.0,
        },
        {
          ty: 4.0,
          ao: 0.0,
          ddd: 0.0,
          ind: 94.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            r: {
              a: 0.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Oval-content',
          op: 121.0,
          parent: 95.0,
          shapes: [
            {
              ty: 'gr',
              cix: 2.0,
              it: [
                {
                  ty: 'sh',
                  ind: 0.0,
                  ks: {
                    a: 0.0,
                    k: {
                      c: true,
                      i: [
                        [-18.2640563518, 0.0],
                        [0.0, 18.2640563518],
                        [18.2640563518, 0.0],
                        [0.0, -18.2640563518],
                      ],
                      o: [
                        [18.2640563518, 0.0],
                        [0.0, -18.2640563518],
                        [-18.2640563518, 0.0],
                        [0.0, 18.2640563518],
                      ],
                      v: [
                        [33.07, 66.14],
                        [66.14, 33.07],
                        [33.07, 0.0],
                        [0.0, 33.07],
                      ],
                    },
                  },
                  nm: 'Oval-path',
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0.0,
                    k: [0.0, 0.0, 0.0, 1.0],
                  },
                  nm: 'Fill 1',
                  o: {
                    a: 0.0,
                    k: 0.0,
                  },
                  r: 1.0,
                },
                {
                  ty: 'tr',
                  a: {
                    a: 0.0,
                    k: [0.0, 0.0],
                  },
                  nm: 'Transform',
                  o: {
                    a: 0.0,
                    k: 100.0,
                  },
                  p: {
                    a: 0.0,
                    k: [0.0, 0.0],
                  },
                  r: {
                    a: 0.0,
                    k: 0.0,
                  },
                  s: {
                    a: 0.0,
                    k: [100.0, 100.0],
                  },
                  sa: {
                    a: 0.0,
                    k: 0.0,
                  },
                  sk: {
                    a: 0.0,
                    k: 0.0,
                  },
                },
              ],
              nm: 'Oval-content',
              np: 3.0,
            },
          ],
          sr: 1.0,
          st: 0.0,
        },
      ],
    },
    {
      id: 'comp_Line 2_FFC194C5-34CD-4E1B-85E8-23F31B13DCA1',
      layers: [
        {
          ty: 3.0,
          ao: 0.0,
          ddd: 0.0,
          ind: 15.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [105.0, 105.0, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              s: true,
              x: {
                a: 0.0,
                k: 105.0,
              },
              y: {
                a: 0.0,
                k: 105.0,
              },
            },
            r: {
              a: 0.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Path 2 + Oval + Path 2 + Oval + Line 2 + Line + Oval + Oval Mask-null',
          op: 121.0,
          sr: 1.0,
          st: 0.0,
        },
        {
          ty: 3.0,
          ao: 0.0,
          ddd: 0.0,
          ind: 45.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [60.14, 60.245000000000005, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              s: true,
              x: {
                a: 0.0,
                k: 158.69,
              },
              y: {
                a: 0.0,
                k: 82.4,
              },
            },
            r: {
              a: 0.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Group-null',
          op: 121.0,
          parent: 15.0,
          sr: 1.0,
          st: 0.0,
        },
        {
          ty: 3.0,
          ao: 0.0,
          ddd: 0.0,
          ind: 105.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [30.5, 1.0, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              s: true,
              x: {
                a: 0.0,
                k: 61.120000000000005,
              },
              y: {
                a: 0.0,
                k: 60.42,
              },
            },
            r: {
              a: 1.0,
              k: [
                {
                  i: {
                    x: [1.0],
                    y: [1.0],
                  },
                  o: {
                    x: [0.0],
                    y: [0.0],
                  },
                  s: [0.0],
                  t: 0.0,
                },
                {
                  i: {
                    x: [0.5799999833106995],
                    y: [1.0],
                  },
                  o: {
                    x: [0.41999998688697815],
                    y: [0.0],
                  },
                  s: [360.0],
                  t: 120.0,
                },
              ],
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Line 2-null',
          op: 121.0,
          parent: 45.0,
          sr: 1.0,
          st: 0.0,
        },
        {
          ty: 4.0,
          ao: 0.0,
          ddd: 0.0,
          ind: 108.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            r: {
              a: 0.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Line 2-size',
          op: 121.0,
          shapes: [
            {
              ty: 'gr',
              cix: 2.0,
              it: [
                {
                  ty: 'rc',
                  d: 1.0,
                  nm: 'sizeElement',
                  p: {
                    a: 0.0,
                    k: [106.0, 106.0],
                  },
                  r: {
                    a: 0.0,
                    k: 0.0,
                  },
                  s: {
                    a: 0.0,
                    k: [212.0, 212.0],
                  },
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0.0,
                    k: [0.0, 0.0, 0.0, 0.0],
                  },
                  nm: 'Fill 1',
                  o: {
                    a: 0.0,
                    k: 0.0,
                  },
                  r: 1.0,
                },
                {
                  ty: 'tr',
                  a: {
                    a: 0.0,
                    k: [0.0, 0.0],
                  },
                  nm: 'Transform',
                  o: {
                    a: 0.0,
                    k: 100.0,
                  },
                  p: {
                    a: 0.0,
                    k: [0.0, 0.0],
                  },
                  r: {
                    a: 0.0,
                    k: 0.0,
                  },
                  s: {
                    a: 0.0,
                    k: [100.0, 100.0],
                  },
                  sa: {
                    a: 0.0,
                    k: 0.0,
                  },
                  sk: {
                    a: 0.0,
                    k: 0.0,
                  },
                },
              ],
              nm: 'Line 2-size',
              np: 3.0,
            },
          ],
          sr: 1.0,
          st: 0.0,
        },
        {
          ty: 4.0,
          ao: 0.0,
          ddd: 0.0,
          ind: 101.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            r: {
              a: 0.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Line 2-stroke',
          op: 121.0,
          parent: 105.0,
          shapes: [
            {
              ty: 'gr',
              cix: 2.0,
              it: [
                {
                  ty: 'sh',
                  ind: 0.0,
                  ks: {
                    a: 0.0,
                    k: {
                      c: false,
                      i: [
                        [0.0, 0.0],
                        [0.0, 0.0],
                      ],
                      o: [
                        [0.0, 0.0],
                        [0.0, 0.0],
                      ],
                      v: [
                        [0.33460208, 0.9322408400000001],
                        [60.33460224, 0.9322408400000001],
                      ],
                    },
                  },
                  nm: 'Line 2-path',
                },
                {
                  ty: 'st',
                  c: {
                    a: 0.0,
                    k: [0.11, 0.47100000000000003, 0.678, 1.0],
                  },
                  d: [
                    {
                      n: 'o',
                      nm: 'offset',
                      v: {
                        a: 0.0,
                        k: 0.0,
                      },
                    },
                  ],
                  lc: 3.0,
                  lj: 1.0,
                  ml: 10.0,
                  nm: 'Stroke 1',
                  o: {
                    a: 0.0,
                    k: 100.0,
                  },
                  w: {
                    a: 0.0,
                    k: 5.0,
                  },
                },
                {
                  ty: 'tm',
                  e: {
                    a: 0.0,
                    k: 100.0,
                  },
                  m: 2.0,
                  nm: 'Trim Paths 1',
                  o: {
                    a: 0.0,
                    k: 0.0,
                  },
                  s: {
                    a: 0.0,
                    k: 0.0,
                  },
                },
                {
                  ty: 'tr',
                  a: {
                    a: 0.0,
                    k: [0.0, 0.0],
                  },
                  nm: 'Transform',
                  o: {
                    a: 0.0,
                    k: 100.0,
                  },
                  p: {
                    a: 0.0,
                    k: [0.0, 0.0],
                  },
                  r: {
                    a: 0.0,
                    k: 0.0,
                  },
                  s: {
                    a: 0.0,
                    k: [100.0, 100.0],
                  },
                  sa: {
                    a: 0.0,
                    k: 0.0,
                  },
                  sk: {
                    a: 0.0,
                    k: 0.0,
                  },
                },
              ],
              nm: 'Line 2-stroke',
              np: 3.0,
            },
          ],
          sr: 1.0,
          st: 0.0,
        },
        {
          ty: 4.0,
          ao: 0.0,
          ddd: 0.0,
          ind: 104.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            r: {
              a: 0.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Line 2-content',
          op: 121.0,
          parent: 105.0,
          shapes: [
            {
              ty: 'gr',
              cix: 2.0,
              it: [
                {
                  ty: 'sh',
                  ind: 0.0,
                  ks: {
                    a: 0.0,
                    k: {
                      c: false,
                      i: [
                        [0.0, 0.0],
                        [0.0, 0.0],
                      ],
                      o: [
                        [0.0, 0.0],
                        [0.0, 0.0],
                      ],
                      v: [
                        [0.33460208, 0.9322408400000001],
                        [60.33460224, 0.9322408400000001],
                      ],
                    },
                  },
                  nm: 'Line 2-path',
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0.0,
                    k: [0.0, 0.0, 0.0, 1.0],
                  },
                  nm: 'Fill 1',
                  o: {
                    a: 0.0,
                    k: 0.0,
                  },
                  r: 1.0,
                },
                {
                  ty: 'tr',
                  a: {
                    a: 0.0,
                    k: [0.0, 0.0],
                  },
                  nm: 'Transform',
                  o: {
                    a: 0.0,
                    k: 100.0,
                  },
                  p: {
                    a: 0.0,
                    k: [0.0, 0.0],
                  },
                  r: {
                    a: 0.0,
                    k: 0.0,
                  },
                  s: {
                    a: 0.0,
                    k: [100.0, 100.0],
                  },
                  sa: {
                    a: 0.0,
                    k: 0.0,
                  },
                  sk: {
                    a: 0.0,
                    k: 0.0,
                  },
                },
              ],
              nm: 'Line 2-content',
              np: 3.0,
            },
          ],
          sr: 1.0,
          st: 0.0,
        },
      ],
    },
    {
      id: 'comp_Line_CBE91C0B-8627-4105-8C0A-8281841ED454',
      layers: [
        {
          ty: 3.0,
          ao: 0.0,
          ddd: 0.0,
          ind: 15.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [105.0, 105.0, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              s: true,
              x: {
                a: 0.0,
                k: 105.0,
              },
              y: {
                a: 0.0,
                k: 105.0,
              },
            },
            r: {
              a: 0.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Path 2 + Oval + Path 2 + Oval + Line 2 + Line + Oval + Oval Mask-null',
          op: 121.0,
          sr: 1.0,
          st: 0.0,
        },
        {
          ty: 3.0,
          ao: 0.0,
          ddd: 0.0,
          ind: 45.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [60.14, 60.245000000000005, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              s: true,
              x: {
                a: 0.0,
                k: 158.69,
              },
              y: {
                a: 0.0,
                k: 82.4,
              },
            },
            r: {
              a: 0.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Group-null',
          op: 121.0,
          parent: 15.0,
          sr: 1.0,
          st: 0.0,
        },
        {
          ty: 3.0,
          ao: 0.0,
          ddd: 0.0,
          ind: 115.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [1.0, 30.5, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              s: true,
              x: {
                a: 0.0,
                k: 60.46,
              },
              y: {
                a: 0.0,
                k: 60.33,
              },
            },
            r: {
              a: 1.0,
              k: [
                {
                  i: {
                    x: [1.0],
                    y: [1.0],
                  },
                  o: {
                    x: [0.0],
                    y: [0.0],
                  },
                  s: [0.0],
                  t: 0.0,
                },
                {
                  i: {
                    x: [0.5799999833106995],
                    y: [1.0],
                  },
                  o: {
                    x: [0.41999998688697815],
                    y: [0.0],
                  },
                  s: [360.0],
                  t: 120.0,
                },
              ],
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Line-null',
          op: 121.0,
          parent: 45.0,
          sr: 1.0,
          st: 0.0,
        },
        {
          ty: 4.0,
          ao: 0.0,
          ddd: 0.0,
          ind: 118.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            r: {
              a: 0.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Line-size',
          op: 121.0,
          shapes: [
            {
              ty: 'gr',
              cix: 2.0,
              it: [
                {
                  ty: 'rc',
                  d: 1.0,
                  nm: 'sizeElement',
                  p: {
                    a: 0.0,
                    k: [106.0, 106.0],
                  },
                  r: {
                    a: 0.0,
                    k: 0.0,
                  },
                  s: {
                    a: 0.0,
                    k: [212.0, 212.0],
                  },
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0.0,
                    k: [0.0, 0.0, 0.0, 0.0],
                  },
                  nm: 'Fill 1',
                  o: {
                    a: 0.0,
                    k: 0.0,
                  },
                  r: 1.0,
                },
                {
                  ty: 'tr',
                  a: {
                    a: 0.0,
                    k: [0.0, 0.0],
                  },
                  nm: 'Transform',
                  o: {
                    a: 0.0,
                    k: 100.0,
                  },
                  p: {
                    a: 0.0,
                    k: [0.0, 0.0],
                  },
                  r: {
                    a: 0.0,
                    k: 0.0,
                  },
                  s: {
                    a: 0.0,
                    k: [100.0, 100.0],
                  },
                  sa: {
                    a: 0.0,
                    k: 0.0,
                  },
                  sk: {
                    a: 0.0,
                    k: 0.0,
                  },
                },
              ],
              nm: 'Line-size',
              np: 3.0,
            },
          ],
          sr: 1.0,
          st: 0.0,
        },
        {
          ty: 4.0,
          ao: 0.0,
          ddd: 0.0,
          ind: 111.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            r: {
              a: 0.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Line-stroke',
          op: 121.0,
          parent: 115.0,
          shapes: [
            {
              ty: 'gr',
              cix: 2.0,
              it: [
                {
                  ty: 'sh',
                  ind: 0.0,
                  ks: {
                    a: 0.0,
                    k: {
                      c: false,
                      i: [
                        [0.0, 0.0],
                        [0.0, 0.0],
                      ],
                      o: [
                        [0.0, 0.0],
                        [0.0, 0.0],
                      ],
                      v: [
                        [1.49466714, 0.51530726],
                        [0.49466714, 60.515306810000006],
                      ],
                    },
                  },
                  nm: 'Line-path',
                },
                {
                  ty: 'st',
                  c: {
                    a: 0.0,
                    k: [0.11, 0.47100000000000003, 0.678, 1.0],
                  },
                  d: [
                    {
                      n: 'o',
                      nm: 'offset',
                      v: {
                        a: 0.0,
                        k: 0.0,
                      },
                    },
                  ],
                  lc: 3.0,
                  lj: 1.0,
                  ml: 10.0,
                  nm: 'Stroke 1',
                  o: {
                    a: 0.0,
                    k: 100.0,
                  },
                  w: {
                    a: 0.0,
                    k: 5.0,
                  },
                },
                {
                  ty: 'tm',
                  e: {
                    a: 0.0,
                    k: 100.0,
                  },
                  m: 2.0,
                  nm: 'Trim Paths 1',
                  o: {
                    a: 0.0,
                    k: 0.0,
                  },
                  s: {
                    a: 0.0,
                    k: 0.0,
                  },
                },
                {
                  ty: 'tr',
                  a: {
                    a: 0.0,
                    k: [0.0, 0.0],
                  },
                  nm: 'Transform',
                  o: {
                    a: 0.0,
                    k: 100.0,
                  },
                  p: {
                    a: 0.0,
                    k: [0.0, 0.0],
                  },
                  r: {
                    a: 0.0,
                    k: 0.0,
                  },
                  s: {
                    a: 0.0,
                    k: [100.0, 100.0],
                  },
                  sa: {
                    a: 0.0,
                    k: 0.0,
                  },
                  sk: {
                    a: 0.0,
                    k: 0.0,
                  },
                },
              ],
              nm: 'Line-stroke',
              np: 3.0,
            },
          ],
          sr: 1.0,
          st: 0.0,
        },
        {
          ty: 4.0,
          ao: 0.0,
          ddd: 0.0,
          ind: 114.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            r: {
              a: 0.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Line-content',
          op: 121.0,
          parent: 115.0,
          shapes: [
            {
              ty: 'gr',
              cix: 2.0,
              it: [
                {
                  ty: 'sh',
                  ind: 0.0,
                  ks: {
                    a: 0.0,
                    k: {
                      c: false,
                      i: [
                        [0.0, 0.0],
                        [0.0, 0.0],
                      ],
                      o: [
                        [0.0, 0.0],
                        [0.0, 0.0],
                      ],
                      v: [
                        [1.49466714, 0.51530726],
                        [0.49466714, 60.515306810000006],
                      ],
                    },
                  },
                  nm: 'Line-path',
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0.0,
                    k: [0.0, 0.0, 0.0, 1.0],
                  },
                  nm: 'Fill 1',
                  o: {
                    a: 0.0,
                    k: 0.0,
                  },
                  r: 1.0,
                },
                {
                  ty: 'tr',
                  a: {
                    a: 0.0,
                    k: [0.0, 0.0],
                  },
                  nm: 'Transform',
                  o: {
                    a: 0.0,
                    k: 100.0,
                  },
                  p: {
                    a: 0.0,
                    k: [0.0, 0.0],
                  },
                  r: {
                    a: 0.0,
                    k: 0.0,
                  },
                  s: {
                    a: 0.0,
                    k: [100.0, 100.0],
                  },
                  sa: {
                    a: 0.0,
                    k: 0.0,
                  },
                  sk: {
                    a: 0.0,
                    k: 0.0,
                  },
                },
              ],
              nm: 'Line-content',
              np: 3.0,
            },
          ],
          sr: 1.0,
          st: 0.0,
        },
      ],
    },
    {
      id: 'comp_Oval_A8C08D79-DFEB-49C8-B443-08F887689544',
      layers: [
        {
          ty: 3.0,
          ao: 0.0,
          ddd: 0.0,
          ind: 15.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [105.0, 105.0, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              s: true,
              x: {
                a: 0.0,
                k: 105.0,
              },
              y: {
                a: 0.0,
                k: 105.0,
              },
            },
            r: {
              a: 0.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Path 2 + Oval + Path 2 + Oval + Line 2 + Line + Oval + Oval Mask-null',
          op: 121.0,
          sr: 1.0,
          st: 0.0,
        },
        {
          ty: 3.0,
          ao: 0.0,
          ddd: 0.0,
          ind: 45.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [60.14, 60.245000000000005, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              s: true,
              x: {
                a: 0.0,
                k: 158.69,
              },
              y: {
                a: 0.0,
                k: 82.4,
              },
            },
            r: {
              a: 0.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Group-null',
          op: 121.0,
          parent: 15.0,
          sr: 1.0,
          st: 0.0,
        },
        {
          ty: 3.0,
          ao: 0.0,
          ddd: 0.0,
          ind: 125.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [13.005, 13.005, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              s: true,
              x: {
                a: 0.0,
                k: 60.53,
              },
              y: {
                a: 0.0,
                k: 60.2,
              },
            },
            r: {
              a: 1.0,
              k: [
                {
                  i: {
                    x: [1.0],
                    y: [1.0],
                  },
                  o: {
                    x: [0.0],
                    y: [0.0],
                  },
                  s: [0.0],
                  t: 0.0,
                },
                {
                  i: {
                    x: [0.5799999833106995],
                    y: [1.0],
                  },
                  o: {
                    x: [0.41999998688697815],
                    y: [0.0],
                  },
                  s: [360.0],
                  t: 120.0,
                },
              ],
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Oval-null',
          op: 121.0,
          parent: 45.0,
          sr: 1.0,
          st: 0.0,
        },
        {
          ty: 4.0,
          ao: 0.0,
          ddd: 0.0,
          ind: 128.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            r: {
              a: 0.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Oval-size',
          op: 121.0,
          shapes: [
            {
              ty: 'gr',
              cix: 2.0,
              it: [
                {
                  ty: 'rc',
                  d: 1.0,
                  nm: 'sizeElement',
                  p: {
                    a: 0.0,
                    k: [106.0, 106.0],
                  },
                  r: {
                    a: 0.0,
                    k: 0.0,
                  },
                  s: {
                    a: 0.0,
                    k: [212.0, 212.0],
                  },
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0.0,
                    k: [0.0, 0.0, 0.0, 0.0],
                  },
                  nm: 'Fill 1',
                  o: {
                    a: 0.0,
                    k: 0.0,
                  },
                  r: 1.0,
                },
                {
                  ty: 'tr',
                  a: {
                    a: 0.0,
                    k: [0.0, 0.0],
                  },
                  nm: 'Transform',
                  o: {
                    a: 0.0,
                    k: 100.0,
                  },
                  p: {
                    a: 0.0,
                    k: [0.0, 0.0],
                  },
                  r: {
                    a: 0.0,
                    k: 0.0,
                  },
                  s: {
                    a: 0.0,
                    k: [100.0, 100.0],
                  },
                  sa: {
                    a: 0.0,
                    k: 0.0,
                  },
                  sk: {
                    a: 0.0,
                    k: 0.0,
                  },
                },
              ],
              nm: 'Oval-size',
              np: 3.0,
            },
          ],
          sr: 1.0,
          st: 0.0,
        },
        {
          ty: 4.0,
          ao: 0.0,
          ddd: 0.0,
          ind: 121.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            r: {
              a: 0.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Oval-stroke',
          op: 121.0,
          parent: 125.0,
          shapes: [
            {
              ty: 'gr',
              cix: 2.0,
              it: [
                {
                  ty: 'sh',
                  ind: 0.0,
                  ks: {
                    a: 0.0,
                    k: {
                      c: true,
                      i: [
                        [-7.1824633038000005, 0.0],
                        [0.0, 7.182463043700002],
                        [7.182463043700002, 0.0],
                        [0.0, -7.1824633038000005],
                      ],
                      o: [
                        [7.182463043700002, 0.0],
                        [0.0, -7.1824633038000005],
                        [-7.1824633038000005, 0.0],
                        [0.0, 7.182463043700002],
                      ],
                      v: [
                        [13.000000097700001, 26.0050000977],
                        [26.0050000977, 13.000000097700001],
                        [13.000000097700001, -0.0049999023],
                        [-0.0049999023, 13.000000097700001],
                      ],
                    },
                  },
                  nm: 'Oval-path',
                },
                {
                  ty: 'st',
                  c: {
                    a: 0.0,
                    k: [0.11, 0.47100000000000003, 0.678, 1.0],
                  },
                  d: [
                    {
                      n: 'o',
                      nm: 'offset',
                      v: {
                        a: 0.0,
                        k: 0.0,
                      },
                    },
                  ],
                  lc: 1.0,
                  lj: 1.0,
                  ml: 10.0,
                  nm: 'Stroke 1',
                  o: {
                    a: 0.0,
                    k: 100.0,
                  },
                  w: {
                    a: 0.0,
                    k: 5.0,
                  },
                },
                {
                  ty: 'tm',
                  e: {
                    a: 0.0,
                    k: 100.0,
                  },
                  m: 2.0,
                  nm: 'Trim Paths 1',
                  o: {
                    a: 0.0,
                    k: 0.0,
                  },
                  s: {
                    a: 0.0,
                    k: 0.0,
                  },
                },
                {
                  ty: 'tr',
                  a: {
                    a: 0.0,
                    k: [0.0, 0.0],
                  },
                  nm: 'Transform',
                  o: {
                    a: 0.0,
                    k: 100.0,
                  },
                  p: {
                    a: 0.0,
                    k: [0.0, 0.0],
                  },
                  r: {
                    a: 0.0,
                    k: 0.0,
                  },
                  s: {
                    a: 0.0,
                    k: [100.0, 100.0],
                  },
                  sa: {
                    a: 0.0,
                    k: 0.0,
                  },
                  sk: {
                    a: 0.0,
                    k: 0.0,
                  },
                },
              ],
              nm: 'Oval-stroke',
              np: 3.0,
            },
          ],
          sr: 1.0,
          st: 0.0,
        },
        {
          ty: 4.0,
          ao: 0.0,
          ddd: 0.0,
          ind: 124.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            r: {
              a: 0.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Oval-content',
          op: 121.0,
          parent: 125.0,
          shapes: [
            {
              ty: 'gr',
              cix: 2.0,
              it: [
                {
                  ty: 'sh',
                  ind: 0.0,
                  ks: {
                    a: 0.0,
                    k: {
                      c: true,
                      i: [
                        [-7.1824633038000005, 0.0],
                        [0.0, 7.182463043700002],
                        [7.182463043700002, 0.0],
                        [0.0, -7.1824633038000005],
                      ],
                      o: [
                        [7.182463043700002, 0.0],
                        [0.0, -7.1824633038000005],
                        [-7.1824633038000005, 0.0],
                        [0.0, 7.182463043700002],
                      ],
                      v: [
                        [13.000000097700001, 26.0050000977],
                        [26.0050000977, 13.000000097700001],
                        [13.000000097700001, -0.0049999023],
                        [-0.0049999023, 13.000000097700001],
                      ],
                    },
                  },
                  nm: 'Oval-path',
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0.0,
                    k: [1.0, 1.0, 1.0, 1.0],
                  },
                  nm: 'Fill 1',
                  o: {
                    a: 0.0,
                    k: 100.0,
                  },
                  r: 1.0,
                },
                {
                  ty: 'tr',
                  a: {
                    a: 0.0,
                    k: [0.0, 0.0],
                  },
                  nm: 'Transform',
                  o: {
                    a: 0.0,
                    k: 100.0,
                  },
                  p: {
                    a: 0.0,
                    k: [0.0, 0.0],
                  },
                  r: {
                    a: 0.0,
                    k: 0.0,
                  },
                  s: {
                    a: 0.0,
                    k: [100.0, 100.0],
                  },
                  sa: {
                    a: 0.0,
                    k: 0.0,
                  },
                  sk: {
                    a: 0.0,
                    k: 0.0,
                  },
                },
              ],
              nm: 'Oval-content',
              np: 3.0,
            },
          ],
          sr: 1.0,
          st: 0.0,
        },
      ],
    },
    {
      id: 'comp_Path 2_CC308850-F666-40A4-8F4E-EA5C84CC1BFC',
      layers: [
        {
          ty: 3.0,
          ao: 0.0,
          ddd: 0.0,
          ind: 15.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [105.0, 105.0, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              s: true,
              x: {
                a: 0.0,
                k: 105.0,
              },
              y: {
                a: 0.0,
                k: 105.0,
              },
            },
            r: {
              a: 0.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Path 2 + Oval + Path 2 + Oval + Line 2 + Line + Oval + Oval Mask-null',
          op: 121.0,
          sr: 1.0,
          st: 0.0,
        },
        {
          ty: 3.0,
          ao: 0.0,
          ddd: 0.0,
          ind: 35.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [61.865, 61.475, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              s: true,
              x: {
                a: 0.0,
                k: 53.14,
              },
              y: {
                a: 0.0,
                k: 127.08,
              },
            },
            r: {
              a: 0.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Group-null',
          op: 121.0,
          parent: 15.0,
          sr: 1.0,
          st: 0.0,
        },
        {
          ty: 3.0,
          ao: 0.0,
          ddd: 0.0,
          ind: 65.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [61.865, 61.475, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              s: true,
              x: {
                a: 0.0,
                k: 61.870000000000005,
              },
              y: {
                a: 0.0,
                k: 61.47,
              },
            },
            r: {
              a: 1.0,
              k: [
                {
                  i: {
                    x: [1.0],
                    y: [1.0],
                  },
                  o: {
                    x: [0.0],
                    y: [0.0],
                  },
                  s: [360.0],
                  t: 0.0,
                },
                {
                  i: {
                    x: [0.5799999833106995],
                    y: [1.0],
                  },
                  o: {
                    x: [0.41999998688697815],
                    y: [0.0],
                  },
                  s: [0.0],
                  t: 120.0,
                },
              ],
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Path 2-null',
          op: 121.0,
          parent: 35.0,
          sr: 1.0,
          st: 0.0,
        },
        {
          ty: 4.0,
          ao: 0.0,
          ddd: 0.0,
          ind: 68.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            r: {
              a: 0.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Path 2-size',
          op: 121.0,
          shapes: [
            {
              ty: 'gr',
              cix: 2.0,
              it: [
                {
                  ty: 'rc',
                  d: 1.0,
                  nm: 'sizeElement',
                  p: {
                    a: 0.0,
                    k: [106.0, 106.0],
                  },
                  r: {
                    a: 0.0,
                    k: 0.0,
                  },
                  s: {
                    a: 0.0,
                    k: [212.0, 212.0],
                  },
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0.0,
                    k: [0.0, 0.0, 0.0, 0.0],
                  },
                  nm: 'Fill 1',
                  o: {
                    a: 0.0,
                    k: 0.0,
                  },
                  r: 1.0,
                },
                {
                  ty: 'tr',
                  a: {
                    a: 0.0,
                    k: [0.0, 0.0],
                  },
                  nm: 'Transform',
                  o: {
                    a: 0.0,
                    k: 100.0,
                  },
                  p: {
                    a: 0.0,
                    k: [0.0, 0.0],
                  },
                  r: {
                    a: 0.0,
                    k: 0.0,
                  },
                  s: {
                    a: 0.0,
                    k: [100.0, 100.0],
                  },
                  sa: {
                    a: 0.0,
                    k: 0.0,
                  },
                  sk: {
                    a: 0.0,
                    k: 0.0,
                  },
                },
              ],
              nm: 'Path 2-size',
              np: 3.0,
            },
          ],
          sr: 1.0,
          st: 0.0,
        },
        {
          ty: 4.0,
          ao: 0.0,
          ddd: 0.0,
          ind: 61.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            r: {
              a: 0.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Path 2-stroke',
          op: 121.0,
          parent: 65.0,
          shapes: [
            {
              ty: 'gr',
              cix: 2.0,
              it: [
                {
                  ty: 'sh',
                  ind: 0.0,
                  ks: {
                    a: 0.0,
                    k: {
                      c: true,
                      i: [
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [1.238396247799999, -3.0467059180000007],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [-1.2314648932000019, -2.9228891205000025],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [-3.0738033558999973, -1.2832389860000006],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [-3.0805800480000016, 1.2998814980000049],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [-1.3549474332000102, 3.0493063105000004],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [1.2949928243999835, 3.020836008500005],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [3.010490714899987, 1.2898020569999975],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [3.031021233800004, -1.2847979920000014],
                        [0.0, 0.0],
                        [0.0, 0.0],
                      ],
                      o: [
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [-1.2383950105000014, 3.046707147499994],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [1.2314661304999994, 2.9228891204999883],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [3.0738033559000044, 1.2832389860000006],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [3.0805800479999874, -1.2998827275000053],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [1.3549461958999842, -3.049305081],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [-1.294992824400012, -3.020836008499998],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [-3.010489477600018, -1.289802057000001],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [-3.03102123379999, 1.284797991999998],
                        [0.0, 0.0],
                        [0.0, 0.0],
                      ],
                      v: [
                        [12.4556627757, 23.356024620000003],
                        [18.0806710865, 32.896209379],
                        [13.326399040400002, 41.720135388500005],
                        [10.276044994100001, 50.9759048305],
                        [0.004999929300000001, 53.563157664],
                        [0.004999929300000001, 68.96403695800001],
                        [10.276044994100001, 71.9916721015],
                        [13.326399040400002, 81.385808244],
                        [18.0806710865, 90.03644237600001],
                        [12.4556627757, 99.580485306],
                        [23.5607061936, 110.7497612535],
                        [33.2116734142, 105.032953874],
                        [41.6807580645, 109.47354640100001],
                        [51.1614480591, 112.52655473600001],
                        [53.8265279195, 122.94499962350001],
                        [69.3744607536, 122.94499962350001],
                        [72.23247280300001, 112.52655473600001],
                        [81.57955790630001, 109.47354640100001],
                        [89.9914953815, 105.453499431],
                        [99.8512000613, 110.7497612535],
                        [110.76167557960001, 99.580485306],
                        [105.28416208990001, 90.03644237600001],
                        [109.62022921060002, 82.1210406375],
                        [112.58581088470001, 71.9916721015],
                        [123.7349999293, 68.96403695800001],
                        [123.7349999293, 53.563157664],
                        [112.58581088470001, 50.9759048305],
                        [109.62022921060002, 41.324594173],
                        [104.9885909167, 32.5456260605],
                        [110.76167557960001, 23.356024620000003],
                        [99.8512000613, 12.235802034],
                        [89.9914953815, 17.6661137825],
                        [81.57955790630001, 13.340102049000002],
                        [72.23247280300001, 10.2998596125],
                        [69.3744607536, -0.0050003765000000006],
                        [53.8265279195, -0.0050003765000000006],
                        [51.1614480591, 10.2998596125],
                        [41.6807580645, 13.340102049000002],
                        [33.2116734142, 17.442451749000004],
                        [23.5607061936, 12.235802034],
                      ],
                    },
                  },
                  nm: 'Path 2-path',
                },
                {
                  ty: 'st',
                  c: {
                    a: 0.0,
                    k: [0.11, 0.47100000000000003, 0.678, 1.0],
                  },
                  d: [
                    {
                      n: 'o',
                      nm: 'offset',
                      v: {
                        a: 0.0,
                        k: 0.0,
                      },
                    },
                  ],
                  lc: 2.0,
                  lj: 2.0,
                  ml: 10.0,
                  nm: 'Stroke 1',
                  o: {
                    a: 0.0,
                    k: 100.0,
                  },
                  w: {
                    a: 0.0,
                    k: 5.0,
                  },
                },
                {
                  ty: 'tm',
                  e: {
                    a: 0.0,
                    k: 100.0,
                  },
                  m: 2.0,
                  nm: 'Trim Paths 1',
                  o: {
                    a: 0.0,
                    k: 0.0,
                  },
                  s: {
                    a: 0.0,
                    k: 0.0,
                  },
                },
                {
                  ty: 'tr',
                  a: {
                    a: 0.0,
                    k: [0.0, 0.0],
                  },
                  nm: 'Transform',
                  o: {
                    a: 0.0,
                    k: 100.0,
                  },
                  p: {
                    a: 0.0,
                    k: [0.0, 0.0],
                  },
                  r: {
                    a: 0.0,
                    k: 0.0,
                  },
                  s: {
                    a: 0.0,
                    k: [100.0, 100.0],
                  },
                  sa: {
                    a: 0.0,
                    k: 0.0,
                  },
                  sk: {
                    a: 0.0,
                    k: 0.0,
                  },
                },
              ],
              nm: 'Path 2-stroke',
              np: 3.0,
            },
          ],
          sr: 1.0,
          st: 0.0,
        },
        {
          ty: 4.0,
          ao: 0.0,
          ddd: 0.0,
          ind: 64.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            r: {
              a: 0.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Path 2-content',
          op: 121.0,
          parent: 65.0,
          shapes: [
            {
              ty: 'gr',
              cix: 2.0,
              it: [
                {
                  ty: 'sh',
                  ind: 0.0,
                  ks: {
                    a: 0.0,
                    k: {
                      c: true,
                      i: [
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [1.238396247799999, -3.0467059180000007],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [-1.2314648932000019, -2.9228891205000025],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [-3.0738033558999973, -1.2832389860000006],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [-3.0805800480000016, 1.2998814980000049],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [-1.3549474332000102, 3.0493063105000004],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [1.2949928243999835, 3.020836008500005],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [3.010490714899987, 1.2898020569999975],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [3.031021233800004, -1.2847979920000014],
                        [0.0, 0.0],
                        [0.0, 0.0],
                      ],
                      o: [
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [-1.2383950105000014, 3.046707147499994],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [1.2314661304999994, 2.9228891204999883],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [3.0738033559000044, 1.2832389860000006],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [3.0805800479999874, -1.2998827275000053],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [1.3549461958999842, -3.049305081],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [-1.294992824400012, -3.020836008499998],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [-3.010489477600018, -1.289802057000001],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [0.0, 0.0],
                        [-3.03102123379999, 1.284797991999998],
                        [0.0, 0.0],
                        [0.0, 0.0],
                      ],
                      v: [
                        [12.4556627757, 23.356024620000003],
                        [18.0806710865, 32.896209379],
                        [13.326399040400002, 41.720135388500005],
                        [10.276044994100001, 50.9759048305],
                        [0.004999929300000001, 53.563157664],
                        [0.004999929300000001, 68.96403695800001],
                        [10.276044994100001, 71.9916721015],
                        [13.326399040400002, 81.385808244],
                        [18.0806710865, 90.03644237600001],
                        [12.4556627757, 99.580485306],
                        [23.5607061936, 110.7497612535],
                        [33.2116734142, 105.032953874],
                        [41.6807580645, 109.47354640100001],
                        [51.1614480591, 112.52655473600001],
                        [53.8265279195, 122.94499962350001],
                        [69.3744607536, 122.94499962350001],
                        [72.23247280300001, 112.52655473600001],
                        [81.57955790630001, 109.47354640100001],
                        [89.9914953815, 105.453499431],
                        [99.8512000613, 110.7497612535],
                        [110.76167557960001, 99.580485306],
                        [105.28416208990001, 90.03644237600001],
                        [109.62022921060002, 82.1210406375],
                        [112.58581088470001, 71.9916721015],
                        [123.7349999293, 68.96403695800001],
                        [123.7349999293, 53.563157664],
                        [112.58581088470001, 50.9759048305],
                        [109.62022921060002, 41.324594173],
                        [104.9885909167, 32.5456260605],
                        [110.76167557960001, 23.356024620000003],
                        [99.8512000613, 12.235802034],
                        [89.9914953815, 17.6661137825],
                        [81.57955790630001, 13.340102049000002],
                        [72.23247280300001, 10.2998596125],
                        [69.3744607536, -0.0050003765000000006],
                        [53.8265279195, -0.0050003765000000006],
                        [51.1614480591, 10.2998596125],
                        [41.6807580645, 13.340102049000002],
                        [33.2116734142, 17.442451749000004],
                        [23.5607061936, 12.235802034],
                      ],
                    },
                  },
                  nm: 'Path 2-path',
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0.0,
                    k: [1.0, 1.0, 1.0, 1.0],
                  },
                  nm: 'Fill 1',
                  o: {
                    a: 0.0,
                    k: 100.0,
                  },
                  r: 1.0,
                },
                {
                  ty: 'tr',
                  a: {
                    a: 0.0,
                    k: [0.0, 0.0],
                  },
                  nm: 'Transform',
                  o: {
                    a: 0.0,
                    k: 100.0,
                  },
                  p: {
                    a: 0.0,
                    k: [0.0, 0.0],
                  },
                  r: {
                    a: 0.0,
                    k: 0.0,
                  },
                  s: {
                    a: 0.0,
                    k: [100.0, 100.0],
                  },
                  sa: {
                    a: 0.0,
                    k: 0.0,
                  },
                  sk: {
                    a: 0.0,
                    k: 0.0,
                  },
                },
              ],
              nm: 'Path 2-content',
              np: 3.0,
            },
          ],
          sr: 1.0,
          st: 0.0,
        },
      ],
    },
    {
      id: 'comp_Oval_95D2E32C-90D2-40B8-9E35-12E765845A13',
      layers: [
        {
          ty: 3.0,
          ao: 0.0,
          ddd: 0.0,
          ind: 15.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [105.0, 105.0, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              s: true,
              x: {
                a: 0.0,
                k: 105.0,
              },
              y: {
                a: 0.0,
                k: 105.0,
              },
            },
            r: {
              a: 0.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Path 2 + Oval + Path 2 + Oval + Line 2 + Line + Oval + Oval Mask-null',
          op: 121.0,
          sr: 1.0,
          st: 0.0,
        },
        {
          ty: 3.0,
          ao: 0.0,
          ddd: 0.0,
          ind: 35.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [61.865, 61.475, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              s: true,
              x: {
                a: 0.0,
                k: 53.14,
              },
              y: {
                a: 0.0,
                k: 127.08,
              },
            },
            r: {
              a: 0.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Group-null',
          op: 121.0,
          parent: 15.0,
          sr: 1.0,
          st: 0.0,
        },
        {
          ty: 3.0,
          ao: 0.0,
          ddd: 0.0,
          ind: 75.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [28.240000000000002, 28.240000000000002, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              s: true,
              x: {
                a: 0.0,
                k: 61.95,
              },
              y: {
                a: 0.0,
                k: 61.44,
              },
            },
            r: {
              a: 1.0,
              k: [
                {
                  i: {
                    x: [1.0],
                    y: [1.0],
                  },
                  o: {
                    x: [0.0],
                    y: [0.0],
                  },
                  s: [360.0],
                  t: 0.0,
                },
                {
                  i: {
                    x: [0.5799999833106995],
                    y: [1.0],
                  },
                  o: {
                    x: [0.41999998688697815],
                    y: [0.0],
                  },
                  s: [0.0],
                  t: 120.0,
                },
              ],
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Oval-null',
          op: 121.0,
          parent: 35.0,
          sr: 1.0,
          st: 0.0,
        },
        {
          ty: 4.0,
          ao: 0.0,
          ddd: 0.0,
          ind: 78.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            r: {
              a: 0.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Oval-size',
          op: 121.0,
          shapes: [
            {
              ty: 'gr',
              cix: 2.0,
              it: [
                {
                  ty: 'rc',
                  d: 1.0,
                  nm: 'sizeElement',
                  p: {
                    a: 0.0,
                    k: [106.0, 106.0],
                  },
                  r: {
                    a: 0.0,
                    k: 0.0,
                  },
                  s: {
                    a: 0.0,
                    k: [212.0, 212.0],
                  },
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0.0,
                    k: [0.0, 0.0, 0.0, 0.0],
                  },
                  nm: 'Fill 1',
                  o: {
                    a: 0.0,
                    k: 0.0,
                  },
                  r: 1.0,
                },
                {
                  ty: 'tr',
                  a: {
                    a: 0.0,
                    k: [0.0, 0.0],
                  },
                  nm: 'Transform',
                  o: {
                    a: 0.0,
                    k: 100.0,
                  },
                  p: {
                    a: 0.0,
                    k: [0.0, 0.0],
                  },
                  r: {
                    a: 0.0,
                    k: 0.0,
                  },
                  s: {
                    a: 0.0,
                    k: [100.0, 100.0],
                  },
                  sa: {
                    a: 0.0,
                    k: 0.0,
                  },
                  sk: {
                    a: 0.0,
                    k: 0.0,
                  },
                },
              ],
              nm: 'Oval-size',
              np: 3.0,
            },
          ],
          sr: 1.0,
          st: 0.0,
        },
        {
          ty: 4.0,
          ao: 0.0,
          ddd: 0.0,
          ind: 71.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            r: {
              a: 0.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Oval-stroke',
          op: 121.0,
          parent: 75.0,
          shapes: [
            {
              ty: 'gr',
              cix: 2.0,
              it: [
                {
                  ty: 'sh',
                  ind: 0.0,
                  ks: {
                    a: 0.0,
                    k: {
                      c: true,
                      i: [
                        [-15.5965210576, 0.0],
                        [0.0, 15.5965210576],
                        [15.5965210576, 0.0],
                        [0.0, -15.5965210576],
                      ],
                      o: [
                        [15.5965210576, 0.0],
                        [0.0, -15.5965210576],
                        [-15.5965210576, 0.0],
                        [0.0, 15.5965210576],
                      ],
                      v: [
                        [28.240000000000002, 56.480000000000004],
                        [56.480000000000004, 28.240000000000002],
                        [28.240000000000002, 0.0],
                        [0.0, 28.240000000000002],
                      ],
                    },
                  },
                  nm: 'Oval-path',
                },
                {
                  ty: 'st',
                  c: {
                    a: 0.0,
                    k: [0.11, 0.47100000000000003, 0.678, 1.0],
                  },
                  d: [
                    {
                      n: 'o',
                      nm: 'offset',
                      v: {
                        a: 0.0,
                        k: 0.0,
                      },
                    },
                  ],
                  lc: 1.0,
                  lj: 1.0,
                  ml: 10.0,
                  nm: 'Stroke 1',
                  o: {
                    a: 0.0,
                    k: 100.0,
                  },
                  w: {
                    a: 0.0,
                    k: 5.0,
                  },
                },
                {
                  ty: 'tm',
                  e: {
                    a: 0.0,
                    k: 100.0,
                  },
                  m: 2.0,
                  nm: 'Trim Paths 1',
                  o: {
                    a: 0.0,
                    k: 0.0,
                  },
                  s: {
                    a: 0.0,
                    k: 0.0,
                  },
                },
                {
                  ty: 'tr',
                  a: {
                    a: 0.0,
                    k: [0.0, 0.0],
                  },
                  nm: 'Transform',
                  o: {
                    a: 0.0,
                    k: 100.0,
                  },
                  p: {
                    a: 0.0,
                    k: [0.0, 0.0],
                  },
                  r: {
                    a: 0.0,
                    k: 0.0,
                  },
                  s: {
                    a: 0.0,
                    k: [100.0, 100.0],
                  },
                  sa: {
                    a: 0.0,
                    k: 0.0,
                  },
                  sk: {
                    a: 0.0,
                    k: 0.0,
                  },
                },
              ],
              nm: 'Oval-stroke',
              np: 3.0,
            },
          ],
          sr: 1.0,
          st: 0.0,
        },
        {
          ty: 4.0,
          ao: 0.0,
          ddd: 0.0,
          ind: 74.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            o: {
              a: 0.0,
              k: 100.0,
            },
            p: {
              a: 0.0,
              k: [0.0, 0.0],
            },
            r: {
              a: 0.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              k: [100.0, 100.0],
            },
          },
          nm: 'Oval-content',
          op: 121.0,
          parent: 75.0,
          shapes: [
            {
              ty: 'gr',
              cix: 2.0,
              it: [
                {
                  ty: 'sh',
                  ind: 0.0,
                  ks: {
                    a: 0.0,
                    k: {
                      c: true,
                      i: [
                        [-15.5965210576, 0.0],
                        [0.0, 15.5965210576],
                        [15.5965210576, 0.0],
                        [0.0, -15.5965210576],
                      ],
                      o: [
                        [15.5965210576, 0.0],
                        [0.0, -15.5965210576],
                        [-15.5965210576, 0.0],
                        [0.0, 15.5965210576],
                      ],
                      v: [
                        [28.240000000000002, 56.480000000000004],
                        [56.480000000000004, 28.240000000000002],
                        [28.240000000000002, 0.0],
                        [0.0, 28.240000000000002],
                      ],
                    },
                  },
                  nm: 'Oval-path',
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0.0,
                    k: [0.0, 0.0, 0.0, 1.0],
                  },
                  nm: 'Fill 1',
                  o: {
                    a: 0.0,
                    k: 0.0,
                  },
                  r: 1.0,
                },
                {
                  ty: 'tr',
                  a: {
                    a: 0.0,
                    k: [0.0, 0.0],
                  },
                  nm: 'Transform',
                  o: {
                    a: 0.0,
                    k: 100.0,
                  },
                  p: {
                    a: 0.0,
                    k: [0.0, 0.0],
                  },
                  r: {
                    a: 0.0,
                    k: 0.0,
                  },
                  s: {
                    a: 0.0,
                    k: [100.0, 100.0],
                  },
                  sa: {
                    a: 0.0,
                    k: 0.0,
                  },
                  sk: {
                    a: 0.0,
                    k: 0.0,
                  },
                },
              ],
              nm: 'Oval-content',
              np: 3.0,
            },
          ],
          sr: 1.0,
          st: 0.0,
        },
      ],
    },
  ],
  ddd: 0.0,
  fonts: {
    list: [],
  },
  fr: 30.0,
  h: 212.0,
  ip: 0.0,
  layers: [
    {
      ty: 0.0,
      ao: 0.0,
      ddd: 0.0,
      h: 212.0,
      ind: 17.0,
      ip: 0.0,
      ks: {
        a: {
          a: 0.0,
          k: [0.0, 0.0],
        },
        o: {
          a: 0.0,
          k: 100.0,
        },
        p: {
          a: 0.0,
          k: [0.0, 0.0],
        },
        r: {
          a: 0.0,
          k: 0.0,
        },
        s: {
          a: 0.0,
          k: [100.0, 100.0],
        },
      },
      nm: 'Path 2 + Oval + Path 2 + Oval + Line 2 + Line + Oval + Oval Mask-maskComposition',
      op: 121.0,
      refId:
        'maskComp_Path 2 + Oval + Path 2 + Oval + Line 2 + Line + Oval + Oval Mask_47CA0B50-D62B-4DF2-A123-4BB4438A934D',
      sr: 1.0,
      st: 0.0,
      w: 212.0,
    },
    {
      ty: 4.0,
      ao: 0.0,
      ddd: 0.0,
      ind: 2.0,
      ip: 0.0,
      ks: {
        a: {
          a: 0.0,
          k: [0.0, 0.0],
        },
        o: {
          a: 0.0,
          k: 100.0,
        },
        p: {
          a: 0.0,
          k: [0.0, 0.0],
        },
        r: {
          a: 0.0,
          k: 0.0,
        },
        s: {
          a: 0.0,
          k: [100.0, 100.0],
        },
      },
      nm: 'Artboard-background',
      op: 121.0,
      shapes: [
        {
          ty: 'gr',
          cix: 2.0,
          it: [
            {
              ty: 'rc',
              d: 1.0,
              nm: 'Artboard-background',
              p: {
                a: 0.0,
                k: [106.0, 106.0],
              },
              r: {
                a: 0.0,
                k: 0.0,
              },
              s: {
                a: 0.0,
                k: [212.0, 212.0],
              },
            },
            {
              ty: 'fl',
              c: {
                a: 0.0,
                k: [1.0, 1.0, 1.0, 1.0],
              },
              nm: 'Fill 1',
              o: {
                a: 0.0,
                k: 100.0,
              },
              r: 1.0,
            },
            {
              ty: 'tr',
              a: {
                a: 0.0,
                k: [0.0, 0.0],
              },
              nm: 'Transform',
              o: {
                a: 0.0,
                k: 100.0,
              },
              p: {
                a: 0.0,
                k: [0.0, 0.0],
              },
              r: {
                a: 0.0,
                k: 0.0,
              },
              s: {
                a: 0.0,
                k: [100.0, 100.0],
              },
              sa: {
                a: 0.0,
                k: 0.0,
              },
              sk: {
                a: 0.0,
                k: 0.0,
              },
            },
          ],
          nm: 'Artboard-background',
          np: 3.0,
        },
      ],
      sr: 1.0,
      st: 0.0,
    },
  ],
  meta: {
    a: '',
    d: 'Timeline',
    g: 'Flow 1.13.0',
    k: '',
    tc: '',
  },
  nm: 'Timeline',
  op: 121.0,
  v: '5.7.14',
  w: 212.0,
};

export const ErrorAnimationData = {
  assets: [
    {
      id: 'comp_Oval Copy_19FD8C86-6E80-4AD6-AFAE-C841A2E404D5',
      layers: [
        {
          ao: 0.0,
          bm: 0.0,
          ddd: 0.0,
          ef: [
            {
              ef: [
                {
                  ix: 1.0,
                  mn: 'ADBE Slider Control-0001',
                  nm: 'Slider',
                  ty: 0.0,
                  v: {
                    a: 0.0,
                    ix: 1.0,
                    k: 100.0,
                  },
                },
              ],
              en: 1.0,
              ix: 1.0,
              mn: 'ADBE Slider Control',
              nm: 'transform.scale.x',
              np: 3.0,
              ty: 5.0,
            },
            {
              ef: [
                {
                  ix: 1.0,
                  mn: 'ADBE Slider Control-0001',
                  nm: 'Slider',
                  ty: 0.0,
                  v: {
                    a: 0.0,
                    ix: 1.0,
                    k: 100.0,
                  },
                },
              ],
              en: 1.0,
              ix: 1.0,
              mn: 'ADBE Slider Control',
              nm: 'transform.scale.y',
              np: 3.0,
              ty: 5.0,
            },
          ],
          hasMask: false,
          hd: false,
          ind: 15.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              ix: 1.0,
              k: [104.0, 104.0, 0.0],
            },
            o: {
              a: 0.0,
              ix: 7.0,
              k: 100.0,
            },
            p: {
              s: true,
              x: {
                a: 0.0,
                ix: 3.0,
                k: 113.0,
              },
              y: {
                a: 0.0,
                ix: 4.0,
                k: 115.0,
              },
            },
            r: {
              a: 0.0,
              ix: 10.0,
              k: -145.0,
            },
            s: {
              a: 0.0,
              ix: 6.0,
              k: [100.0, 100.0],
            },
          },
          masksProperties: [],
          nm: 'Oval Copy-null',
          op: 46.0,
          sr: 0.0,
          st: 0.0,
          tt: 0.0,
          ty: 3.0,
        },
        {
          ao: 0.0,
          bm: 0.0,
          ddd: 0.0,
          ef: [],
          hasMask: false,
          hd: false,
          ind: 18.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              ix: 1.0,
              k: [0.0, 0.0],
            },
            o: {
              a: 0.0,
              ix: 7.0,
              k: 100.0,
            },
            p: {
              a: 0.0,
              ix: 2.0,
              k: [0.0, 0.0],
            },
            r: {
              a: 0.0,
              ix: 2.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              ix: 6.0,
              k: [100.0, 100.0],
            },
          },
          masksProperties: [],
          nm: 'Oval Copy-size',
          op: 46.0,
          shapes: [
            {
              bm: 0.0,
              cix: 2.0,
              hd: false,
              it: [
                {
                  d: 1.0,
                  hd: false,
                  mn: 'ADBE Vector Shape - Rect',
                  nm: 'sizeElement',
                  p: {
                    a: 0.0,
                    ix: 2.0,
                    k: [115.0, 115.0],
                  },
                  r: {
                    a: 0.0,
                    ix: 2.0,
                    k: 0.0,
                  },
                  s: {
                    a: 0.0,
                    ix: 6.0,
                    k: [230.0, 230.0],
                  },
                  ty: 'rc',
                },
                {
                  bm: 0.0,
                  c: {
                    a: 0.0,
                    ix: 4.0,
                    k: [0.0, 0.0, 0.0],
                  },
                  hd: false,
                  mn: 'ADBE Vector Graphic - Fill',
                  nm: 'Fill 1',
                  o: {
                    a: 0.0,
                    ix: 5.0,
                    k: 0.0,
                  },
                  r: 1.0,
                  ty: 'fl',
                },
                {
                  a: {
                    a: 0.0,
                    ix: 1.0,
                    k: [0.0, 0.0],
                  },
                  nm: 'Transform',
                  o: {
                    a: 0.0,
                    ix: 7.0,
                    k: 100.0,
                  },
                  p: {
                    a: 0.0,
                    ix: 2.0,
                    k: [0.0, 0.0],
                  },
                  r: {
                    a: 0.0,
                    ix: 2.0,
                    k: 0.0,
                  },
                  s: {
                    a: 0.0,
                    ix: 6.0,
                    k: [100.0, 100.0],
                  },
                  sa: {
                    a: 0.0,
                    ix: 5.0,
                    k: 0.0,
                  },
                  sk: {
                    a: 0.0,
                    ix: 4.0,
                    k: 0.0,
                  },
                  ty: 'tr',
                },
              ],
              ix: 1.0,
              mn: 'ADBE Vector Group',
              nm: 'Oval Copy-size',
              np: 3.0,
              ty: 'gr',
            },
          ],
          sr: 0.0,
          st: 0.0,
          tt: 0.0,
          ty: 4.0,
        },
        {
          ao: 0.0,
          bm: 0.0,
          ddd: 0.0,
          ef: [],
          hasMask: false,
          hd: false,
          ind: 11.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              ix: 1.0,
              k: [0.0, 0.0],
            },
            o: {
              a: 0.0,
              ix: 7.0,
              k: 100.0,
            },
            p: {
              a: 0.0,
              ix: 2.0,
              k: [0.0, 0.0],
            },
            r: {
              a: 0.0,
              ix: 2.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              ix: 6.0,
              k: [100.0, 100.0],
            },
          },
          masksProperties: [],
          nm: 'Oval Copy-stroke',
          op: 46.0,
          parent: 15.0,
          shapes: [
            {
              bm: 0.0,
              cix: 2.0,
              hd: false,
              it: [
                {
                  hd: false,
                  ind: 0.0,
                  ix: 1.0,
                  ks: {
                    a: 0.0,
                    ix: 2.0,
                    k: {
                      c: true,
                      i: [
                        [-57.437613979199995, 0.0],
                        [0.0, 57.437613979199995],
                        [57.437613979199995, 0.0],
                        [0.0, -57.437613979199995],
                      ],
                      o: [
                        [57.437613979199995, 0.0],
                        [0.0, -57.437613979199995],
                        [-57.437613979199995, 0.0],
                        [0.0, 57.437613979199995],
                      ],
                      v: [
                        [104.0, 208.0],
                        [208.0, 104.0],
                        [104.0, 0.0],
                        [0.0, 104.0],
                      ],
                    },
                  },
                  mn: 'ADBE Vector Shape - Group',
                  nm: 'Oval Copy-path',
                  ty: 'sh',
                },
                {
                  bm: 0.0,
                  c: {
                    a: 0.0,
                    ix: 3.0,
                    k: [0.726, 0.29, 0.219],
                  },
                  d: [
                    {
                      n: 'd',
                      nm: 'dash0',
                      v: {
                        a: 0.0,
                        ix: 0.0,
                        k: 660.0,
                      },
                    },
                    {
                      n: 'o',
                      nm: 'offset',
                      v: {
                        a: 1.0,
                        ix: 7.0,
                        k: [
                          {
                            i: {
                              x: [0.58],
                              y: [1.0],
                            },
                            o: {
                              x: [0.42],
                              y: [0.0],
                            },
                            s: [660.0],
                            t: 0.0,
                          },
                          {
                            i: {
                              x: [0.58],
                              y: [1.0],
                            },
                            o: {
                              x: [0.42],
                              y: [0.0],
                            },
                            s: [0.0],
                            t: 26.1,
                          },
                          {
                            i: {
                              x: [1.0],
                              y: [1.0],
                            },
                            o: {
                              x: [0.0],
                              y: [0.0],
                            },
                            s: [0.0],
                            t: 45.0,
                          },
                        ],
                      },
                    },
                  ],
                  hd: false,
                  lc: 1.0,
                  lj: 1.0,
                  ml: 10.0,
                  mn: 'ADBE Vector Graphic - Stroke',
                  nm: 'Stroke 1',
                  o: {
                    a: 0.0,
                    ix: 4.0,
                    k: 100.0,
                  },
                  ty: 'st',
                  w: {
                    a: 1.0,
                    ix: 5.0,
                    k: [
                      {
                        i: {
                          x: [1.0],
                          y: [1.0],
                        },
                        o: {
                          x: [0.0],
                          y: [0.0],
                        },
                        s: [12.0],
                        t: 0.0,
                      },
                      {
                        i: {
                          x: [0.58],
                          y: [1.0],
                        },
                        o: {
                          x: [0.42],
                          y: [0.0],
                        },
                        s: [12.0],
                        t: 26.1,
                      },
                      {
                        i: {
                          x: [0.58],
                          y: [1.0],
                        },
                        o: {
                          x: [0.42],
                          y: [0.0],
                        },
                        s: [14.0],
                        t: 35.099999999999994,
                      },
                      {
                        i: {
                          x: [0.58],
                          y: [1.0],
                        },
                        o: {
                          x: [0.42],
                          y: [0.0],
                        },
                        s: [12.0],
                        t: 45.0,
                      },
                    ],
                  },
                },
                {
                  e: {
                    a: 0.0,
                    ix: 2.0,
                    k: 100.0,
                  },
                  hd: false,
                  ix: 3.0,
                  mn: 'ADBE Vector Filter - Trim',
                  nm: 'Trim Paths 1',
                  o: {
                    a: 0.0,
                    ix: 3.0,
                    k: 0.0,
                  },
                  s: {
                    a: 0.0,
                    ix: 1.0,
                    k: 0.0,
                  },
                  ty: 'tm',
                },
                {
                  a: {
                    a: 0.0,
                    ix: 1.0,
                    k: [0.0, 0.0],
                  },
                  nm: 'Transform',
                  o: {
                    a: 0.0,
                    ix: 7.0,
                    k: 100.0,
                  },
                  p: {
                    a: 0.0,
                    ix: 2.0,
                    k: [0.0, 0.0],
                  },
                  r: {
                    a: 0.0,
                    ix: 2.0,
                    k: 0.0,
                  },
                  s: {
                    a: 0.0,
                    ix: 6.0,
                    k: [100.0, 100.0],
                  },
                  sa: {
                    a: 0.0,
                    ix: 5.0,
                    k: 0.0,
                  },
                  sk: {
                    a: 0.0,
                    ix: 4.0,
                    k: 0.0,
                  },
                  ty: 'tr',
                },
              ],
              ix: 1.0,
              mn: 'ADBE Vector Group',
              nm: 'Oval Copy-stroke',
              np: 3.0,
              ty: 'gr',
            },
          ],
          sr: 0.0,
          st: 0.0,
          tt: 0.0,
          ty: 4.0,
        },
        {
          ao: 0.0,
          bm: 0.0,
          ddd: 0.0,
          ef: [],
          hasMask: false,
          hd: false,
          ind: 14.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              ix: 1.0,
              k: [0.0, 0.0],
            },
            o: {
              a: 0.0,
              ix: 7.0,
              k: 100.0,
            },
            p: {
              a: 0.0,
              ix: 2.0,
              k: [0.0, 0.0],
            },
            r: {
              a: 0.0,
              ix: 2.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              ix: 6.0,
              k: [100.0, 100.0],
            },
          },
          masksProperties: [],
          nm: 'Oval Copy-content',
          op: 46.0,
          parent: 15.0,
          shapes: [
            {
              bm: 0.0,
              cix: 2.0,
              hd: false,
              it: [
                {
                  hd: false,
                  ind: 0.0,
                  ix: 1.0,
                  ks: {
                    a: 0.0,
                    ix: 2.0,
                    k: {
                      c: true,
                      i: [
                        [-57.437613979199995, 0.0],
                        [0.0, 57.437613979199995],
                        [57.437613979199995, 0.0],
                        [0.0, -57.437613979199995],
                      ],
                      o: [
                        [57.437613979199995, 0.0],
                        [0.0, -57.437613979199995],
                        [-57.437613979199995, 0.0],
                        [0.0, 57.437613979199995],
                      ],
                      v: [
                        [104.0, 208.0],
                        [208.0, 104.0],
                        [104.0, 0.0],
                        [0.0, 104.0],
                      ],
                    },
                  },
                  mn: 'ADBE Vector Shape - Group',
                  nm: 'Oval Copy-path',
                  ty: 'sh',
                },
                {
                  bm: 0.0,
                  c: {
                    a: 0.0,
                    ix: 4.0,
                    k: [0.0, 0.0, 0.0],
                  },
                  hd: false,
                  mn: 'ADBE Vector Graphic - Fill',
                  nm: 'Fill 1',
                  o: {
                    a: 0.0,
                    ix: 5.0,
                    k: 0.0,
                  },
                  r: 1.0,
                  ty: 'fl',
                },
                {
                  a: {
                    a: 0.0,
                    ix: 1.0,
                    k: [0.0, 0.0],
                  },
                  nm: 'Transform',
                  o: {
                    a: 0.0,
                    ix: 7.0,
                    k: 100.0,
                  },
                  p: {
                    a: 0.0,
                    ix: 2.0,
                    k: [0.0, 0.0],
                  },
                  r: {
                    a: 0.0,
                    ix: 2.0,
                    k: 0.0,
                  },
                  s: {
                    a: 0.0,
                    ix: 6.0,
                    k: [100.0, 100.0],
                  },
                  sa: {
                    a: 0.0,
                    ix: 5.0,
                    k: 0.0,
                  },
                  sk: {
                    a: 0.0,
                    ix: 4.0,
                    k: 0.0,
                  },
                  ty: 'tr',
                },
              ],
              ix: 1.0,
              mn: 'ADBE Vector Group',
              nm: 'Oval Copy-content',
              np: 3.0,
              ty: 'gr',
            },
          ],
          sr: 0.0,
          st: 0.0,
          tt: 0.0,
          ty: 4.0,
        },
      ],
    },
    {
      id: 'comp_Path Copy_F6B02D97-1BBA-45C7-A516-9D56ACF249A0',
      layers: [
        {
          ao: 0.0,
          bm: 0.0,
          ddd: 0.0,
          ef: [
            {
              ef: [
                {
                  ix: 1.0,
                  mn: 'ADBE Slider Control-0001',
                  nm: 'Slider',
                  ty: 0.0,
                  v: {
                    a: 0.0,
                    ix: 1.0,
                    k: 100.0,
                  },
                },
              ],
              en: 1.0,
              ix: 1.0,
              mn: 'ADBE Slider Control',
              nm: 'transform.scale.x',
              np: 3.0,
              ty: 5.0,
            },
            {
              ef: [
                {
                  ix: 1.0,
                  mn: 'ADBE Slider Control-0001',
                  nm: 'Slider',
                  ty: 0.0,
                  v: {
                    a: 0.0,
                    ix: 1.0,
                    k: 100.0,
                  },
                },
              ],
              en: 1.0,
              ix: 1.0,
              mn: 'ADBE Slider Control',
              nm: 'transform.scale.y',
              np: 3.0,
              ty: 5.0,
            },
          ],
          hasMask: false,
          hd: false,
          ind: 25.0,
          ip: 0.0,
          ks: {
            a: {
              a: 1.0,
              ix: 1.0,
              k: [
                {
                  i: {
                    x: [1.0],
                    y: [1.0],
                  },
                  o: {
                    x: [0.0],
                    y: [0.0],
                  },
                  s: [0.005, 0.005, 0.0],
                  t: 0.0,
                },
                {
                  i: {
                    x: [0.58],
                    y: [1.0],
                  },
                  o: {
                    x: [0.42],
                    y: [0.0],
                  },
                  s: [0.005, 0.005, 0.0],
                  t: 26.1,
                },
                {
                  i: {
                    x: [0.58],
                    y: [1.0],
                  },
                  o: {
                    x: [0.42],
                    y: [0.0],
                  },
                  s: [65.0, 67.925, 0.0],
                  t: 35.099999999999994,
                },
                {
                  i: {
                    x: [0.58],
                    y: [1.0],
                  },
                  o: {
                    x: [0.42],
                    y: [0.0],
                  },
                  s: [53.655, 56.07, 0.0],
                  t: 45.0,
                },
              ],
            },
            o: {
              a: 0.0,
              ix: 7.0,
              k: 100.0,
            },
            p: {
              s: true,
              x: {
                a: 0.0,
                ix: 3.0,
                k: 112.65,
              },
              y: {
                a: 0.0,
                ix: 4.0,
                k: 115.07000000000001,
              },
            },
            r: {
              a: 0.0,
              ix: 10.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              ix: 6.0,
              k: [100.0, 100.0],
            },
          },
          masksProperties: [],
          nm: 'Path Copy-null',
          op: 46.0,
          sr: 0.0,
          st: 0.0,
          tt: 0.0,
          ty: 3.0,
        },
        {
          ao: 0.0,
          bm: 0.0,
          ddd: 0.0,
          ef: [],
          hasMask: false,
          hd: false,
          ind: 28.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              ix: 1.0,
              k: [0.0, 0.0],
            },
            o: {
              a: 0.0,
              ix: 7.0,
              k: 100.0,
            },
            p: {
              a: 0.0,
              ix: 2.0,
              k: [0.0, 0.0],
            },
            r: {
              a: 0.0,
              ix: 2.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              ix: 6.0,
              k: [100.0, 100.0],
            },
          },
          masksProperties: [],
          nm: 'Path Copy-size',
          op: 46.0,
          shapes: [
            {
              bm: 0.0,
              cix: 2.0,
              hd: false,
              it: [
                {
                  d: 1.0,
                  hd: false,
                  mn: 'ADBE Vector Shape - Rect',
                  nm: 'sizeElement',
                  p: {
                    a: 0.0,
                    ix: 2.0,
                    k: [115.0, 115.0],
                  },
                  r: {
                    a: 0.0,
                    ix: 2.0,
                    k: 0.0,
                  },
                  s: {
                    a: 0.0,
                    ix: 6.0,
                    k: [230.0, 230.0],
                  },
                  ty: 'rc',
                },
                {
                  bm: 0.0,
                  c: {
                    a: 0.0,
                    ix: 4.0,
                    k: [0.0, 0.0, 0.0],
                  },
                  hd: false,
                  mn: 'ADBE Vector Graphic - Fill',
                  nm: 'Fill 1',
                  o: {
                    a: 0.0,
                    ix: 5.0,
                    k: 0.0,
                  },
                  r: 1.0,
                  ty: 'fl',
                },
                {
                  a: {
                    a: 0.0,
                    ix: 1.0,
                    k: [0.0, 0.0],
                  },
                  nm: 'Transform',
                  o: {
                    a: 0.0,
                    ix: 7.0,
                    k: 100.0,
                  },
                  p: {
                    a: 0.0,
                    ix: 2.0,
                    k: [0.0, 0.0],
                  },
                  r: {
                    a: 0.0,
                    ix: 2.0,
                    k: 0.0,
                  },
                  s: {
                    a: 0.0,
                    ix: 6.0,
                    k: [100.0, 100.0],
                  },
                  sa: {
                    a: 0.0,
                    ix: 5.0,
                    k: 0.0,
                  },
                  sk: {
                    a: 0.0,
                    ix: 4.0,
                    k: 0.0,
                  },
                  ty: 'tr',
                },
              ],
              ix: 1.0,
              mn: 'ADBE Vector Group',
              nm: 'Path Copy-size',
              np: 3.0,
              ty: 'gr',
            },
          ],
          sr: 0.0,
          st: 0.0,
          tt: 0.0,
          ty: 4.0,
        },
        {
          ao: 0.0,
          bm: 0.0,
          ddd: 0.0,
          ef: [],
          hasMask: false,
          hd: false,
          ind: 21.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              ix: 1.0,
              k: [0.0, 0.0],
            },
            o: {
              a: 0.0,
              ix: 7.0,
              k: 100.0,
            },
            p: {
              a: 0.0,
              ix: 2.0,
              k: [0.0, 0.0],
            },
            r: {
              a: 0.0,
              ix: 2.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              ix: 6.0,
              k: [100.0, 100.0],
            },
          },
          masksProperties: [],
          nm: 'Path Copy-stroke',
          op: 46.0,
          parent: 25.0,
          shapes: [
            {
              bm: 0.0,
              cix: 2.0,
              hd: false,
              it: [
                {
                  hd: false,
                  ind: 0.0,
                  ix: 1.0,
                  ks: {
                    a: 1.0,
                    ix: 2.0,
                    k: [
                      {
                        i: {
                          x: [1.0],
                          y: [1.0],
                        },
                        o: {
                          x: [0.0],
                          y: [0.0],
                        },
                        s: [
                          {
                            c: false,
                            i: [
                              [0.0, 0.0],
                              [0.0, 0.0],
                            ],
                            o: [
                              [0.0, 0.0],
                              [0.0, 0.0],
                            ],
                            v: [
                              [-4.659398005785587e-7, 0.009999999999999997],
                              [0.009999534060199412, 0.0],
                            ],
                          },
                        ],
                        t: 0.0,
                      },
                      {
                        i: {
                          x: [0.58],
                          y: [1.0],
                        },
                        o: {
                          x: [0.42],
                          y: [0.0],
                        },
                        s: [
                          {
                            c: false,
                            i: [
                              [0.0, 0.0],
                              [0.0, 0.0],
                            ],
                            o: [
                              [0.0, 0.0],
                              [0.0, 0.0],
                            ],
                            v: [
                              [-4.659398005785587e-7, 0.009999999999999997],
                              [0.009999534060199412, 0.0],
                            ],
                          },
                        ],
                        t: 26.1,
                      },
                      {
                        i: {
                          x: [0.58],
                          y: [1.0],
                        },
                        o: {
                          x: [0.42],
                          y: [0.0],
                        },
                        s: [
                          {
                            c: false,
                            i: [
                              [0.0, 0.0],
                              [0.0, 0.0],
                            ],
                            o: [
                              [0.0, 0.0],
                              [0.0, 0.0],
                            ],
                            v: [
                              [-0.006057217407521263, 135.84999999999994],
                              [129.99394278259237, 0.0],
                            ],
                          },
                        ],
                        t: 35.099999999999994,
                      },
                      {
                        i: {
                          x: [0.58],
                          y: [1.0],
                        },
                        o: {
                          x: [0.42],
                          y: [0.0],
                        },
                        s: [
                          {
                            c: false,
                            i: [
                              [0.0, 0.0],
                              [0.0, 0.0],
                            ],
                            o: [
                              [0.0, 0.0],
                              [0.0, 0.0],
                            ],
                            v: [
                              [-0.005000000000008514, 112.13999999999996],
                              [107.3049999999999, 0.0],
                            ],
                          },
                        ],
                        t: 45.0,
                      },
                    ],
                  },
                  mn: 'ADBE Vector Shape - Group',
                  nm: 'Path Copy-path',
                  ty: 'sh',
                },
                {
                  bm: 0.0,
                  c: {
                    a: 0.0,
                    ix: 3.0,
                    k: [0.725, 0.29, 0.22],
                  },
                  d: [
                    {
                      n: 'o',
                      nm: 'offset',
                      v: {
                        a: 0.0,
                        ix: 7.0,
                        k: 0.0,
                      },
                    },
                  ],
                  hd: false,
                  lc: 2.0,
                  lj: 2.0,
                  ml: 10.0,
                  mn: 'ADBE Vector Graphic - Stroke',
                  nm: 'Stroke 1',
                  o: {
                    a: 0.0,
                    ix: 4.0,
                    k: 100.0,
                  },
                  ty: 'st',
                  w: {
                    a: 1.0,
                    ix: 5.0,
                    k: [
                      {
                        i: {
                          x: [1.0],
                          y: [1.0],
                        },
                        o: {
                          x: [0.0],
                          y: [0.0],
                        },
                        s: [12.0],
                        t: 0.0,
                      },
                      {
                        i: {
                          x: [0.58],
                          y: [1.0],
                        },
                        o: {
                          x: [0.42],
                          y: [0.0],
                        },
                        s: [12.0],
                        t: 26.1,
                      },
                      {
                        i: {
                          x: [0.58],
                          y: [1.0],
                        },
                        o: {
                          x: [0.42],
                          y: [0.0],
                        },
                        s: [24.0],
                        t: 35.099999999999994,
                      },
                      {
                        i: {
                          x: [0.58],
                          y: [1.0],
                        },
                        o: {
                          x: [0.42],
                          y: [0.0],
                        },
                        s: [12.0],
                        t: 45.0,
                      },
                    ],
                  },
                },
                {
                  e: {
                    a: 0.0,
                    ix: 2.0,
                    k: 100.0,
                  },
                  hd: false,
                  ix: 3.0,
                  mn: 'ADBE Vector Filter - Trim',
                  nm: 'Trim Paths 1',
                  o: {
                    a: 0.0,
                    ix: 3.0,
                    k: 0.0,
                  },
                  s: {
                    a: 0.0,
                    ix: 1.0,
                    k: 0.0,
                  },
                  ty: 'tm',
                },
                {
                  a: {
                    a: 0.0,
                    ix: 1.0,
                    k: [0.0, 0.0],
                  },
                  nm: 'Transform',
                  o: {
                    a: 0.0,
                    ix: 7.0,
                    k: 100.0,
                  },
                  p: {
                    a: 0.0,
                    ix: 2.0,
                    k: [0.0, 0.0],
                  },
                  r: {
                    a: 0.0,
                    ix: 2.0,
                    k: 0.0,
                  },
                  s: {
                    a: 0.0,
                    ix: 6.0,
                    k: [100.0, 100.0],
                  },
                  sa: {
                    a: 0.0,
                    ix: 5.0,
                    k: 0.0,
                  },
                  sk: {
                    a: 0.0,
                    ix: 4.0,
                    k: 0.0,
                  },
                  ty: 'tr',
                },
              ],
              ix: 1.0,
              mn: 'ADBE Vector Group',
              nm: 'Path Copy-stroke',
              np: 3.0,
              ty: 'gr',
            },
          ],
          sr: 0.0,
          st: 0.0,
          tt: 0.0,
          ty: 4.0,
        },
        {
          ao: 0.0,
          bm: 0.0,
          ddd: 0.0,
          ef: [],
          hasMask: false,
          hd: false,
          ind: 24.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              ix: 1.0,
              k: [0.0, 0.0],
            },
            o: {
              a: 0.0,
              ix: 7.0,
              k: 100.0,
            },
            p: {
              a: 0.0,
              ix: 2.0,
              k: [0.0, 0.0],
            },
            r: {
              a: 0.0,
              ix: 2.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              ix: 6.0,
              k: [100.0, 100.0],
            },
          },
          masksProperties: [],
          nm: 'Path Copy-content',
          op: 46.0,
          parent: 25.0,
          shapes: [
            {
              bm: 0.0,
              cix: 2.0,
              hd: false,
              it: [
                {
                  hd: false,
                  ind: 0.0,
                  ix: 1.0,
                  ks: {
                    a: 1.0,
                    ix: 2.0,
                    k: [
                      {
                        i: {
                          x: [1.0],
                          y: [1.0],
                        },
                        o: {
                          x: [0.0],
                          y: [0.0],
                        },
                        s: [
                          {
                            c: false,
                            i: [
                              [0.0, 0.0],
                              [0.0, 0.0],
                            ],
                            o: [
                              [0.0, 0.0],
                              [0.0, 0.0],
                            ],
                            v: [
                              [-4.659398005785587e-7, 0.009999999999999997],
                              [0.009999534060199412, 0.0],
                            ],
                          },
                        ],
                        t: 0.0,
                      },
                      {
                        i: {
                          x: [0.58],
                          y: [1.0],
                        },
                        o: {
                          x: [0.42],
                          y: [0.0],
                        },
                        s: [
                          {
                            c: false,
                            i: [
                              [0.0, 0.0],
                              [0.0, 0.0],
                            ],
                            o: [
                              [0.0, 0.0],
                              [0.0, 0.0],
                            ],
                            v: [
                              [-4.659398005785587e-7, 0.009999999999999997],
                              [0.009999534060199412, 0.0],
                            ],
                          },
                        ],
                        t: 26.1,
                      },
                      {
                        i: {
                          x: [0.58],
                          y: [1.0],
                        },
                        o: {
                          x: [0.42],
                          y: [0.0],
                        },
                        s: [
                          {
                            c: false,
                            i: [
                              [0.0, 0.0],
                              [0.0, 0.0],
                            ],
                            o: [
                              [0.0, 0.0],
                              [0.0, 0.0],
                            ],
                            v: [
                              [-0.006057217407521263, 135.84999999999994],
                              [129.99394278259237, 0.0],
                            ],
                          },
                        ],
                        t: 35.099999999999994,
                      },
                      {
                        i: {
                          x: [0.58],
                          y: [1.0],
                        },
                        o: {
                          x: [0.42],
                          y: [0.0],
                        },
                        s: [
                          {
                            c: false,
                            i: [
                              [0.0, 0.0],
                              [0.0, 0.0],
                            ],
                            o: [
                              [0.0, 0.0],
                              [0.0, 0.0],
                            ],
                            v: [
                              [-0.005000000000008514, 112.13999999999996],
                              [107.3049999999999, 0.0],
                            ],
                          },
                        ],
                        t: 45.0,
                      },
                    ],
                  },
                  mn: 'ADBE Vector Shape - Group',
                  nm: 'Path Copy-path',
                  ty: 'sh',
                },
                {
                  bm: 0.0,
                  c: {
                    a: 0.0,
                    ix: 4.0,
                    k: [0.0, 0.0, 0.0],
                  },
                  hd: false,
                  mn: 'ADBE Vector Graphic - Fill',
                  nm: 'Fill 1',
                  o: {
                    a: 0.0,
                    ix: 5.0,
                    k: 0.0,
                  },
                  r: 1.0,
                  ty: 'fl',
                },
                {
                  a: {
                    a: 0.0,
                    ix: 1.0,
                    k: [0.0, 0.0],
                  },
                  nm: 'Transform',
                  o: {
                    a: 0.0,
                    ix: 7.0,
                    k: 100.0,
                  },
                  p: {
                    a: 0.0,
                    ix: 2.0,
                    k: [0.0, 0.0],
                  },
                  r: {
                    a: 0.0,
                    ix: 2.0,
                    k: 0.0,
                  },
                  s: {
                    a: 0.0,
                    ix: 6.0,
                    k: [100.0, 100.0],
                  },
                  sa: {
                    a: 0.0,
                    ix: 5.0,
                    k: 0.0,
                  },
                  sk: {
                    a: 0.0,
                    ix: 4.0,
                    k: 0.0,
                  },
                  ty: 'tr',
                },
              ],
              ix: 1.0,
              mn: 'ADBE Vector Group',
              nm: 'Path Copy-content',
              np: 3.0,
              ty: 'gr',
            },
          ],
          sr: 0.0,
          st: 0.0,
          tt: 0.0,
          ty: 4.0,
        },
      ],
    },
    {
      id: 'comp_Path Copy 2_A2D4F335-F921-493B-893D-C204ECDEEDEB',
      layers: [
        {
          ao: 0.0,
          bm: 0.0,
          ddd: 0.0,
          ef: [
            {
              ef: [
                {
                  ix: 1.0,
                  mn: 'ADBE Slider Control-0001',
                  nm: 'Slider',
                  ty: 0.0,
                  v: {
                    a: 0.0,
                    ix: 1.0,
                    k: 100.0,
                  },
                },
              ],
              en: 1.0,
              ix: 1.0,
              mn: 'ADBE Slider Control',
              nm: 'transform.scale.x',
              np: 3.0,
              ty: 5.0,
            },
            {
              ef: [
                {
                  ix: 1.0,
                  mn: 'ADBE Slider Control-0001',
                  nm: 'Slider',
                  ty: 0.0,
                  v: {
                    a: 0.0,
                    ix: 1.0,
                    k: 100.0,
                  },
                },
              ],
              en: 1.0,
              ix: 1.0,
              mn: 'ADBE Slider Control',
              nm: 'transform.scale.y',
              np: 3.0,
              ty: 5.0,
            },
          ],
          hasMask: false,
          hd: false,
          ind: 35.0,
          ip: 0.0,
          ks: {
            a: {
              a: 1.0,
              ix: 1.0,
              k: [
                {
                  i: {
                    x: [1.0],
                    y: [1.0],
                  },
                  o: {
                    x: [0.0],
                    y: [0.0],
                  },
                  s: [0.005, 0.005, 0.0],
                  t: 0.0,
                },
                {
                  i: {
                    x: [0.58],
                    y: [1.0],
                  },
                  o: {
                    x: [0.42],
                    y: [0.0],
                  },
                  s: [0.005, 0.005, 0.0],
                  t: 26.1,
                },
                {
                  i: {
                    x: [0.58],
                    y: [1.0],
                  },
                  o: {
                    x: [0.42],
                    y: [0.0],
                  },
                  s: [65.0, 67.925, 0.0],
                  t: 35.099999999999994,
                },
                {
                  i: {
                    x: [0.58],
                    y: [1.0],
                  },
                  o: {
                    x: [0.42],
                    y: [0.0],
                  },
                  s: [53.655, 56.07, 0.0],
                  t: 45.0,
                },
              ],
            },
            o: {
              a: 0.0,
              ix: 7.0,
              k: 100.0,
            },
            p: {
              s: true,
              x: {
                a: 0.0,
                ix: 3.0,
                k: 112.65,
              },
              y: {
                a: 0.0,
                ix: 4.0,
                k: 115.07000000000001,
              },
            },
            r: {
              a: 0.0,
              ix: 10.0,
              k: 92.26,
            },
            s: {
              a: 0.0,
              ix: 6.0,
              k: [100.0, 100.0],
            },
          },
          masksProperties: [],
          nm: 'Path Copy 2-null',
          op: 46.0,
          sr: 0.0,
          st: 0.0,
          tt: 0.0,
          ty: 3.0,
        },
        {
          ao: 0.0,
          bm: 0.0,
          ddd: 0.0,
          ef: [],
          hasMask: false,
          hd: false,
          ind: 38.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              ix: 1.0,
              k: [0.0, 0.0],
            },
            o: {
              a: 0.0,
              ix: 7.0,
              k: 100.0,
            },
            p: {
              a: 0.0,
              ix: 2.0,
              k: [0.0, 0.0],
            },
            r: {
              a: 0.0,
              ix: 2.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              ix: 6.0,
              k: [100.0, 100.0],
            },
          },
          masksProperties: [],
          nm: 'Path Copy 2-size',
          op: 46.0,
          shapes: [
            {
              bm: 0.0,
              cix: 2.0,
              hd: false,
              it: [
                {
                  d: 1.0,
                  hd: false,
                  mn: 'ADBE Vector Shape - Rect',
                  nm: 'sizeElement',
                  p: {
                    a: 0.0,
                    ix: 2.0,
                    k: [115.0, 115.0],
                  },
                  r: {
                    a: 0.0,
                    ix: 2.0,
                    k: 0.0,
                  },
                  s: {
                    a: 0.0,
                    ix: 6.0,
                    k: [230.0, 230.0],
                  },
                  ty: 'rc',
                },
                {
                  bm: 0.0,
                  c: {
                    a: 0.0,
                    ix: 4.0,
                    k: [0.0, 0.0, 0.0],
                  },
                  hd: false,
                  mn: 'ADBE Vector Graphic - Fill',
                  nm: 'Fill 1',
                  o: {
                    a: 0.0,
                    ix: 5.0,
                    k: 0.0,
                  },
                  r: 1.0,
                  ty: 'fl',
                },
                {
                  a: {
                    a: 0.0,
                    ix: 1.0,
                    k: [0.0, 0.0],
                  },
                  nm: 'Transform',
                  o: {
                    a: 0.0,
                    ix: 7.0,
                    k: 100.0,
                  },
                  p: {
                    a: 0.0,
                    ix: 2.0,
                    k: [0.0, 0.0],
                  },
                  r: {
                    a: 0.0,
                    ix: 2.0,
                    k: 0.0,
                  },
                  s: {
                    a: 0.0,
                    ix: 6.0,
                    k: [100.0, 100.0],
                  },
                  sa: {
                    a: 0.0,
                    ix: 5.0,
                    k: 0.0,
                  },
                  sk: {
                    a: 0.0,
                    ix: 4.0,
                    k: 0.0,
                  },
                  ty: 'tr',
                },
              ],
              ix: 1.0,
              mn: 'ADBE Vector Group',
              nm: 'Path Copy 2-size',
              np: 3.0,
              ty: 'gr',
            },
          ],
          sr: 0.0,
          st: 0.0,
          tt: 0.0,
          ty: 4.0,
        },
        {
          ao: 0.0,
          bm: 0.0,
          ddd: 0.0,
          ef: [],
          hasMask: false,
          hd: false,
          ind: 31.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              ix: 1.0,
              k: [0.0, 0.0],
            },
            o: {
              a: 0.0,
              ix: 7.0,
              k: 100.0,
            },
            p: {
              a: 0.0,
              ix: 2.0,
              k: [0.0, 0.0],
            },
            r: {
              a: 0.0,
              ix: 2.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              ix: 6.0,
              k: [100.0, 100.0],
            },
          },
          masksProperties: [],
          nm: 'Path Copy 2-stroke',
          op: 46.0,
          parent: 35.0,
          shapes: [
            {
              bm: 0.0,
              cix: 2.0,
              hd: false,
              it: [
                {
                  hd: false,
                  ind: 0.0,
                  ix: 1.0,
                  ks: {
                    a: 1.0,
                    ix: 2.0,
                    k: [
                      {
                        i: {
                          x: [1.0],
                          y: [1.0],
                        },
                        o: {
                          x: [0.0],
                          y: [0.0],
                        },
                        s: [
                          {
                            c: false,
                            i: [
                              [0.0, 0.0],
                              [0.0, 0.0],
                            ],
                            o: [
                              [0.0, 0.0],
                              [0.0, 0.0],
                            ],
                            v: [
                              [-4.659398005785587e-7, 0.009999999999999997],
                              [0.009999534060199412, 0.0],
                            ],
                          },
                        ],
                        t: 0.0,
                      },
                      {
                        i: {
                          x: [0.58],
                          y: [1.0],
                        },
                        o: {
                          x: [0.42],
                          y: [0.0],
                        },
                        s: [
                          {
                            c: false,
                            i: [
                              [0.0, 0.0],
                              [0.0, 0.0],
                            ],
                            o: [
                              [0.0, 0.0],
                              [0.0, 0.0],
                            ],
                            v: [
                              [-4.659398005785587e-7, 0.009999999999999997],
                              [0.009999534060199412, 0.0],
                            ],
                          },
                        ],
                        t: 26.1,
                      },
                      {
                        i: {
                          x: [0.58],
                          y: [1.0],
                        },
                        o: {
                          x: [0.42],
                          y: [0.0],
                        },
                        s: [
                          {
                            c: false,
                            i: [
                              [0.0, 0.0],
                              [0.0, 0.0],
                            ],
                            o: [
                              [0.0, 0.0],
                              [0.0, 0.0],
                            ],
                            v: [
                              [-0.006057217407521263, 135.84999999999994],
                              [129.99394278259237, 0.0],
                            ],
                          },
                        ],
                        t: 35.099999999999994,
                      },
                      {
                        i: {
                          x: [0.58],
                          y: [1.0],
                        },
                        o: {
                          x: [0.42],
                          y: [0.0],
                        },
                        s: [
                          {
                            c: false,
                            i: [
                              [0.0, 0.0],
                              [0.0, 0.0],
                            ],
                            o: [
                              [0.0, 0.0],
                              [0.0, 0.0],
                            ],
                            v: [
                              [-0.005000000000008514, 112.13999999999996],
                              [107.3049999999999, 0.0],
                            ],
                          },
                        ],
                        t: 45.0,
                      },
                    ],
                  },
                  mn: 'ADBE Vector Shape - Group',
                  nm: 'Path Copy 2-path',
                  ty: 'sh',
                },
                {
                  bm: 0.0,
                  c: {
                    a: 0.0,
                    ix: 3.0,
                    k: [0.725, 0.29, 0.22],
                  },
                  d: [
                    {
                      n: 'o',
                      nm: 'offset',
                      v: {
                        a: 0.0,
                        ix: 7.0,
                        k: 0.0,
                      },
                    },
                  ],
                  hd: false,
                  lc: 2.0,
                  lj: 2.0,
                  ml: 10.0,
                  mn: 'ADBE Vector Graphic - Stroke',
                  nm: 'Stroke 1',
                  o: {
                    a: 0.0,
                    ix: 4.0,
                    k: 100.0,
                  },
                  ty: 'st',
                  w: {
                    a: 1.0,
                    ix: 5.0,
                    k: [
                      {
                        i: {
                          x: [1.0],
                          y: [1.0],
                        },
                        o: {
                          x: [0.0],
                          y: [0.0],
                        },
                        s: [12.0],
                        t: 0.0,
                      },
                      {
                        i: {
                          x: [0.58],
                          y: [1.0],
                        },
                        o: {
                          x: [0.42],
                          y: [0.0],
                        },
                        s: [12.0],
                        t: 26.1,
                      },
                      {
                        i: {
                          x: [0.58],
                          y: [1.0],
                        },
                        o: {
                          x: [0.42],
                          y: [0.0],
                        },
                        s: [24.0],
                        t: 35.099999999999994,
                      },
                      {
                        i: {
                          x: [0.58],
                          y: [1.0],
                        },
                        o: {
                          x: [0.42],
                          y: [0.0],
                        },
                        s: [12.0],
                        t: 45.0,
                      },
                    ],
                  },
                },
                {
                  e: {
                    a: 0.0,
                    ix: 2.0,
                    k: 100.0,
                  },
                  hd: false,
                  ix: 3.0,
                  mn: 'ADBE Vector Filter - Trim',
                  nm: 'Trim Paths 1',
                  o: {
                    a: 0.0,
                    ix: 3.0,
                    k: 0.0,
                  },
                  s: {
                    a: 0.0,
                    ix: 1.0,
                    k: 0.0,
                  },
                  ty: 'tm',
                },
                {
                  a: {
                    a: 0.0,
                    ix: 1.0,
                    k: [0.0, 0.0],
                  },
                  nm: 'Transform',
                  o: {
                    a: 0.0,
                    ix: 7.0,
                    k: 100.0,
                  },
                  p: {
                    a: 0.0,
                    ix: 2.0,
                    k: [0.0, 0.0],
                  },
                  r: {
                    a: 0.0,
                    ix: 2.0,
                    k: 0.0,
                  },
                  s: {
                    a: 0.0,
                    ix: 6.0,
                    k: [100.0, 100.0],
                  },
                  sa: {
                    a: 0.0,
                    ix: 5.0,
                    k: 0.0,
                  },
                  sk: {
                    a: 0.0,
                    ix: 4.0,
                    k: 0.0,
                  },
                  ty: 'tr',
                },
              ],
              ix: 1.0,
              mn: 'ADBE Vector Group',
              nm: 'Path Copy 2-stroke',
              np: 3.0,
              ty: 'gr',
            },
          ],
          sr: 0.0,
          st: 0.0,
          tt: 0.0,
          ty: 4.0,
        },
        {
          ao: 0.0,
          bm: 0.0,
          ddd: 0.0,
          ef: [],
          hasMask: false,
          hd: false,
          ind: 34.0,
          ip: 0.0,
          ks: {
            a: {
              a: 0.0,
              ix: 1.0,
              k: [0.0, 0.0],
            },
            o: {
              a: 0.0,
              ix: 7.0,
              k: 100.0,
            },
            p: {
              a: 0.0,
              ix: 2.0,
              k: [0.0, 0.0],
            },
            r: {
              a: 0.0,
              ix: 2.0,
              k: 0.0,
            },
            s: {
              a: 0.0,
              ix: 6.0,
              k: [100.0, 100.0],
            },
          },
          masksProperties: [],
          nm: 'Path Copy 2-content',
          op: 46.0,
          parent: 35.0,
          shapes: [
            {
              bm: 0.0,
              cix: 2.0,
              hd: false,
              it: [
                {
                  hd: false,
                  ind: 0.0,
                  ix: 1.0,
                  ks: {
                    a: 1.0,
                    ix: 2.0,
                    k: [
                      {
                        i: {
                          x: [1.0],
                          y: [1.0],
                        },
                        o: {
                          x: [0.0],
                          y: [0.0],
                        },
                        s: [
                          {
                            c: false,
                            i: [
                              [0.0, 0.0],
                              [0.0, 0.0],
                            ],
                            o: [
                              [0.0, 0.0],
                              [0.0, 0.0],
                            ],
                            v: [
                              [-4.659398005785587e-7, 0.009999999999999997],
                              [0.009999534060199412, 0.0],
                            ],
                          },
                        ],
                        t: 0.0,
                      },
                      {
                        i: {
                          x: [0.58],
                          y: [1.0],
                        },
                        o: {
                          x: [0.42],
                          y: [0.0],
                        },
                        s: [
                          {
                            c: false,
                            i: [
                              [0.0, 0.0],
                              [0.0, 0.0],
                            ],
                            o: [
                              [0.0, 0.0],
                              [0.0, 0.0],
                            ],
                            v: [
                              [-4.659398005785587e-7, 0.009999999999999997],
                              [0.009999534060199412, 0.0],
                            ],
                          },
                        ],
                        t: 26.1,
                      },
                      {
                        i: {
                          x: [0.58],
                          y: [1.0],
                        },
                        o: {
                          x: [0.42],
                          y: [0.0],
                        },
                        s: [
                          {
                            c: false,
                            i: [
                              [0.0, 0.0],
                              [0.0, 0.0],
                            ],
                            o: [
                              [0.0, 0.0],
                              [0.0, 0.0],
                            ],
                            v: [
                              [-0.006057217407521263, 135.84999999999994],
                              [129.99394278259237, 0.0],
                            ],
                          },
                        ],
                        t: 35.099999999999994,
                      },
                      {
                        i: {
                          x: [0.58],
                          y: [1.0],
                        },
                        o: {
                          x: [0.42],
                          y: [0.0],
                        },
                        s: [
                          {
                            c: false,
                            i: [
                              [0.0, 0.0],
                              [0.0, 0.0],
                            ],
                            o: [
                              [0.0, 0.0],
                              [0.0, 0.0],
                            ],
                            v: [
                              [-0.005000000000008514, 112.13999999999996],
                              [107.3049999999999, 0.0],
                            ],
                          },
                        ],
                        t: 45.0,
                      },
                    ],
                  },
                  mn: 'ADBE Vector Shape - Group',
                  nm: 'Path Copy 2-path',
                  ty: 'sh',
                },
                {
                  bm: 0.0,
                  c: {
                    a: 0.0,
                    ix: 4.0,
                    k: [0.0, 0.0, 0.0],
                  },
                  hd: false,
                  mn: 'ADBE Vector Graphic - Fill',
                  nm: 'Fill 1',
                  o: {
                    a: 0.0,
                    ix: 5.0,
                    k: 0.0,
                  },
                  r: 1.0,
                  ty: 'fl',
                },
                {
                  a: {
                    a: 0.0,
                    ix: 1.0,
                    k: [0.0, 0.0],
                  },
                  nm: 'Transform',
                  o: {
                    a: 0.0,
                    ix: 7.0,
                    k: 100.0,
                  },
                  p: {
                    a: 0.0,
                    ix: 2.0,
                    k: [0.0, 0.0],
                  },
                  r: {
                    a: 0.0,
                    ix: 2.0,
                    k: 0.0,
                  },
                  s: {
                    a: 0.0,
                    ix: 6.0,
                    k: [100.0, 100.0],
                  },
                  sa: {
                    a: 0.0,
                    ix: 5.0,
                    k: 0.0,
                  },
                  sk: {
                    a: 0.0,
                    ix: 4.0,
                    k: 0.0,
                  },
                  ty: 'tr',
                },
              ],
              ix: 1.0,
              mn: 'ADBE Vector Group',
              nm: 'Path Copy 2-content',
              np: 3.0,
              ty: 'gr',
            },
          ],
          sr: 0.0,
          st: 0.0,
          tt: 0.0,
          ty: 4.0,
        },
      ],
    },
  ],
  ddd: 0.0,
  fonts: {
    list: [],
  },
  fr: 30.0,
  h: 230.0,
  ip: 0.0,
  layers: [
    {
      ao: 0.0,
      bm: 0.0,
      ddd: 0.0,
      ef: [
        {
          ef: [
            {
              ix: 1.0,
              mn: 'ADBE Slider Control-0001',
              nm: 'shadowColor',
              ty: 2.0,
              v: {
                a: 0.0,
                ix: 1.0,
                k: [0.0, 0.0, 0.0],
              },
            },
            {
              ix: 2.0,
              mn: 'ADBE Slider Control-0001',
              nm: 'shadowColor.opacity',
              ty: 0.0,
              v: {
                a: 0.0,
                ix: 2.0,
                k: 0.0,
              },
            },
            {
              ix: 3.0,
              mn: 'ADBE Slider Control-0001',
              nm: 'shadow.direction',
              ty: 0.0,
              v: {
                a: 0.0,
                ix: 3.0,
                k: 90.0,
              },
            },
            {
              ix: 4.0,
              mn: 'ADBE Slider Control-0001',
              nm: 'shadow.distance',
              ty: 0.0,
              v: {
                a: 0.0,
                ix: 3.0,
                k: 0.0,
              },
            },
            {
              ix: 5.0,
              mn: 'ADBE Slider Control-0001',
              nm: 'shadowRadius',
              ty: 0.0,
              v: {
                a: 0.0,
                ix: 5.0,
                k: 12.0,
              },
            },
          ],
          en: 1.0,
          ix: 1.0,
          mn: 'ADBE Slider Control',
          nm: 'shadow',
          np: 3.0,
          ty: 25.0,
        },
      ],
      h: 230.0,
      hasMask: false,
      hd: false,
      ind: 30.0,
      ip: 0.0,
      ks: {
        a: {
          a: 0.0,
          ix: 1.0,
          k: [0.0, 0.0],
        },
        o: {
          a: 1.0,
          ix: 11.0,
          k: [
            {
              i: {
                x: [1.0],
                y: [1.0],
              },
              o: {
                x: [0.0],
                y: [0.0],
              },
              s: [0.0],
              t: 0.0,
            },
            {
              i: {
                x: [0.58],
                y: [1.0],
              },
              o: {
                x: [0.42],
                y: [0.0],
              },
              s: [0.0],
              t: 26.1,
            },
            {
              i: {
                x: [0.58],
                y: [1.0],
              },
              o: {
                x: [0.42],
                y: [0.0],
              },
              s: [100.0],
              t: 27.900000000000002,
            },
            {
              i: {
                x: [1.0],
                y: [1.0],
              },
              o: {
                x: [0.0],
                y: [0.0],
              },
              s: [100.0],
              t: 45.0,
            },
          ],
        },
        p: {
          a: 0.0,
          ix: 2.0,
          k: [0.0, 0.0],
        },
        r: {
          a: 0.0,
          ix: 2.0,
          k: 0.0,
        },
        s: {
          a: 0.0,
          ix: 6.0,
          k: [100.0, 100.0],
        },
      },
      masksProperties: [],
      nm: 'Path Copy 2-composition',
      op: 46.0,
      refId: 'comp_Path Copy 2_A2D4F335-F921-493B-893D-C204ECDEEDEB',
      sr: 0.0,
      st: 0.0,
      tt: 0.0,
      ty: 0.0,
      w: 230.0,
    },
    {
      ao: 0.0,
      bm: 0.0,
      ddd: 0.0,
      ef: [
        {
          ef: [
            {
              ix: 1.0,
              mn: 'ADBE Slider Control-0001',
              nm: 'shadowColor',
              ty: 2.0,
              v: {
                a: 0.0,
                ix: 1.0,
                k: [0.0, 0.0, 0.0],
              },
            },
            {
              ix: 2.0,
              mn: 'ADBE Slider Control-0001',
              nm: 'shadowColor.opacity',
              ty: 0.0,
              v: {
                a: 0.0,
                ix: 2.0,
                k: 0.0,
              },
            },
            {
              ix: 3.0,
              mn: 'ADBE Slider Control-0001',
              nm: 'shadow.direction',
              ty: 0.0,
              v: {
                a: 0.0,
                ix: 3.0,
                k: 90.0,
              },
            },
            {
              ix: 4.0,
              mn: 'ADBE Slider Control-0001',
              nm: 'shadow.distance',
              ty: 0.0,
              v: {
                a: 0.0,
                ix: 3.0,
                k: 0.0,
              },
            },
            {
              ix: 5.0,
              mn: 'ADBE Slider Control-0001',
              nm: 'shadowRadius',
              ty: 0.0,
              v: {
                a: 0.0,
                ix: 5.0,
                k: 12.0,
              },
            },
          ],
          en: 1.0,
          ix: 1.0,
          mn: 'ADBE Slider Control',
          nm: 'shadow',
          np: 3.0,
          ty: 25.0,
        },
      ],
      h: 230.0,
      hasMask: false,
      hd: false,
      ind: 20.0,
      ip: 0.0,
      ks: {
        a: {
          a: 0.0,
          ix: 1.0,
          k: [0.0, 0.0],
        },
        o: {
          a: 1.0,
          ix: 11.0,
          k: [
            {
              i: {
                x: [1.0],
                y: [1.0],
              },
              o: {
                x: [0.0],
                y: [0.0],
              },
              s: [0.0],
              t: 0.0,
            },
            {
              i: {
                x: [0.58],
                y: [1.0],
              },
              o: {
                x: [0.42],
                y: [0.0],
              },
              s: [0.0],
              t: 26.1,
            },
            {
              i: {
                x: [0.58],
                y: [1.0],
              },
              o: {
                x: [0.42],
                y: [0.0],
              },
              s: [100.0],
              t: 27.900000000000002,
            },
            {
              i: {
                x: [1.0],
                y: [1.0],
              },
              o: {
                x: [0.0],
                y: [0.0],
              },
              s: [100.0],
              t: 45.0,
            },
          ],
        },
        p: {
          a: 0.0,
          ix: 2.0,
          k: [0.0, 0.0],
        },
        r: {
          a: 0.0,
          ix: 2.0,
          k: 0.0,
        },
        s: {
          a: 0.0,
          ix: 6.0,
          k: [100.0, 100.0],
        },
      },
      masksProperties: [],
      nm: 'Path Copy-composition',
      op: 46.0,
      refId: 'comp_Path Copy_F6B02D97-1BBA-45C7-A516-9D56ACF249A0',
      sr: 0.0,
      st: 0.0,
      tt: 0.0,
      ty: 0.0,
      w: 230.0,
    },
    {
      ao: 0.0,
      bm: 0.0,
      ddd: 0.0,
      ef: [
        {
          ef: [
            {
              ix: 1.0,
              mn: 'ADBE Slider Control-0001',
              nm: 'shadowColor',
              ty: 2.0,
              v: {
                a: 0.0,
                ix: 1.0,
                k: [0.0, 0.0, 0.0],
              },
            },
            {
              ix: 2.0,
              mn: 'ADBE Slider Control-0001',
              nm: 'shadowColor.opacity',
              ty: 0.0,
              v: {
                a: 0.0,
                ix: 2.0,
                k: 0.0,
              },
            },
            {
              ix: 3.0,
              mn: 'ADBE Slider Control-0001',
              nm: 'shadow.direction',
              ty: 0.0,
              v: {
                a: 0.0,
                ix: 3.0,
                k: 90.0,
              },
            },
            {
              ix: 4.0,
              mn: 'ADBE Slider Control-0001',
              nm: 'shadow.distance',
              ty: 0.0,
              v: {
                a: 0.0,
                ix: 3.0,
                k: 0.0,
              },
            },
            {
              ix: 5.0,
              mn: 'ADBE Slider Control-0001',
              nm: 'shadowRadius',
              ty: 0.0,
              v: {
                a: 0.0,
                ix: 5.0,
                k: 12.0,
              },
            },
          ],
          en: 1.0,
          ix: 1.0,
          mn: 'ADBE Slider Control',
          nm: 'shadow',
          np: 3.0,
          ty: 25.0,
        },
      ],
      h: 230.0,
      hasMask: false,
      hd: false,
      ind: 10.0,
      ip: 0.0,
      ks: {
        a: {
          a: 0.0,
          ix: 1.0,
          k: [0.0, 0.0],
        },
        o: {
          a: 0.0,
          ix: 11.0,
          k: 100.0,
        },
        p: {
          a: 0.0,
          ix: 2.0,
          k: [0.0, 0.0],
        },
        r: {
          a: 0.0,
          ix: 2.0,
          k: 0.0,
        },
        s: {
          a: 0.0,
          ix: 6.0,
          k: [100.0, 100.0],
        },
      },
      masksProperties: [],
      nm: 'Oval Copy-composition',
      op: 46.0,
      refId: 'comp_Oval Copy_19FD8C86-6E80-4AD6-AFAE-C841A2E404D5',
      sr: 0.0,
      st: 0.0,
      tt: 0.0,
      ty: 0.0,
      w: 230.0,
    },
    {
      ao: 0.0,
      bm: 0.0,
      ddd: 0.0,
      ef: [],
      hasMask: false,
      hd: false,
      ind: 2.0,
      ip: 0.0,
      ks: {
        a: {
          a: 0.0,
          ix: 1.0,
          k: [0.0, 0.0],
        },
        o: {
          a: 0.0,
          ix: 7.0,
          k: 100.0,
        },
        p: {
          a: 0.0,
          ix: 2.0,
          k: [0.0, 0.0],
        },
        r: {
          a: 0.0,
          ix: 2.0,
          k: 0.0,
        },
        s: {
          a: 0.0,
          ix: 6.0,
          k: [100.0, 100.0],
        },
      },
      masksProperties: [],
      nm: 'iPhone 8-background',
      op: 46.0,
      shapes: [
        {
          bm: 0.0,
          cix: 2.0,
          hd: false,
          it: [
            {
              d: 1.0,
              hd: false,
              mn: 'ADBE Vector Shape - Rect',
              nm: 'iPhone 8-background',
              p: {
                a: 0.0,
                ix: 3.0,
                k: [115.0, 115.0],
              },
              r: {
                a: 0.0,
                ix: 1.0,
                k: 0.0,
              },
              s: {
                a: 0.0,
                ix: 2.0,
                k: [230.0, 230.0],
              },
              ty: 'rc',
            },
            {
              bm: 0.0,
              c: {
                a: 0.0,
                ix: 4.0,
                k: [1.0, 1.0, 1.0],
              },
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              o: {
                a: 0.0,
                ix: 5.0,
                k: 100.0,
              },
              r: 1.0,
              ty: 'fl',
            },
            {
              a: {
                a: 0.0,
                ix: 1.0,
                k: [0.0, 0.0],
              },
              nm: 'Transform',
              o: {
                a: 0.0,
                ix: 7.0,
                k: 100.0,
              },
              p: {
                a: 0.0,
                ix: 2.0,
                k: [0.0, 0.0],
              },
              r: {
                a: 0.0,
                ix: 2.0,
                k: 0.0,
              },
              s: {
                a: 0.0,
                ix: 6.0,
                k: [100.0, 100.0],
              },
              sa: {
                a: 0.0,
                ix: 5.0,
                k: 0.0,
              },
              sk: {
                a: 0.0,
                ix: 4.0,
                k: 0.0,
              },
              ty: 'tr',
            },
          ],
          ix: 1.0,
          mn: 'ADBE Vector Group',
          nm: 'iPhone 8-background',
          np: 3.0,
          ty: 'gr',
        },
      ],
      sr: 0.0,
      st: 0.0,
      tt: 0.0,
      ty: 4.0,
    },
  ],
  markers: [],
  nm: 'ErrorDashPattern',
  op: 46.0,
  v: '5.6.4',
  w: 230.0,
};
