import {useEffect, useState} from 'react';
import {IActionResults, IActionRes, IPagedActionResults} from '../model/ActionResults';
import {ISynkSaveQueueResponse, ISynkValidation} from '../model/synkd/SynkResponse';
import {IPurchaseOrder} from '../model/purchase-order/PurchaseOrder';
import {ISearchFilter} from '../model/search-filters/SearchFilter';
import {PurchaseOrderStatus} from '../model/constants/Constants';
import {IRequisition} from '../model/requisition/Requisition';
import useHttp from '../hooks/useHttp';

const usePurchaseOrderAPI = () => {
  const {GET, POST, isLoading} = useHttp();

  const search = async (filter?: any): Promise<IPagedActionResults<IRequisition[]>> => {
    return GET('purchaseorders', filter).then((data: IPagedActionResults<IRequisition[]>) => data);
  };

  const getAll = async (filter?: any): Promise<IPagedActionResults<IPurchaseOrder[]>> => {
    return GET('v2/purchaseorders', filter).then((data: IPagedActionResults<IPurchaseOrder[]>) => data);
  };

  const get = async (purchaseOrderId: number): Promise<IPurchaseOrder> => {
    return GET(`purchaseorders/${purchaseOrderId}`).then((data: IActionResults<IPurchaseOrder>) => data.Value);
  };

  const save = async (purchaseOrder: Partial<IPurchaseOrder>): Promise<ISynkValidation> => {
    if (purchaseOrder.BillingAddress) {
      purchaseOrder.BillStreetAddress = purchaseOrder.BillingAddress.StreetAddress;
      purchaseOrder.BillCity = purchaseOrder.BillingAddress.City;
      purchaseOrder.BillCountry = purchaseOrder.BillingAddress.Country;
      purchaseOrder.BillState = purchaseOrder.BillingAddress.State;
      purchaseOrder.BillPostCode = purchaseOrder.BillingAddress.PostCode;
      purchaseOrder.BillingAddress = undefined;
    }

    if (purchaseOrder.ShippingAddress) {
      purchaseOrder.ShipStreetAddress = purchaseOrder.ShippingAddress.StreetAddress;
      purchaseOrder.ShipCity = purchaseOrder.ShippingAddress.City;
      purchaseOrder.ShipCountry = purchaseOrder.ShippingAddress.Country;
      purchaseOrder.ShipState = purchaseOrder.ShippingAddress.State;
      purchaseOrder.ShipPostCode = purchaseOrder.ShippingAddress.PostCode;
      purchaseOrder.ShippingAddress = undefined;
    }

    return POST('purchaseorders', {Object: purchaseOrder}).then((data: ISynkSaveQueueResponse) => data.Value);
  };

  const updateStatus = async (purchaseOrderId: number | undefined, status: PurchaseOrderStatus) => {
    if (!purchaseOrderId) {
      return;
    }

    return POST(`/purchaseorders/${purchaseOrderId}/status`, {Status: status}).then(
      (data: IActionRes) => data.IsSuccess
    );
  };

  const getStatusToDisplay = (status: string): string => {
    switch (status) {
      case 'Review':
        return 'Sent';
      case 'Open':
        return 'Not Sent';
      case 'Cancelled':
        return 'Sent & Cancelled';
      default:
        return status;
    }
  };

  return {
    get,
    getAll,
    search,
    save,
    updateStatus,
    getStatusToDisplay,
    isLoading,
  };
};

export default usePurchaseOrderAPI;

export const useLoadPurchaseOrder = () => {
  const {getAll} = usePurchaseOrderAPI();

  let pageSize = 15;

  const initialFilterState = {
    Status: [
      PurchaseOrderStatus.Approved,
      PurchaseOrderStatus.Cancelled,
      PurchaseOrderStatus.Open,
      PurchaseOrderStatus.Review,
      PurchaseOrderStatus.Sent,
      PurchaseOrderStatus.Sent_as_Unlinked,
    ],
    LastRowNumber: 0,
    MaxResults: pageSize,
    SortAsc: false,
    SortField: 'CreatedDateTime',
  } as ISearchFilter;

  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<IPurchaseOrder[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [moreToGet, setMoreToGet] = useState<boolean>(true);
  const [error, setError] = useState<any>();
  const [searchFilter, setSearchFilter] = useState<ISearchFilter>(initialFilterState);

  const getPurchaseOrderList = async () => {
    setLoading(true);

    try {
      const {Value, TotalRecordCount, MoreToGet, LastRowNumber} = await getAll(searchFilter);
      setItems(Value || []);
      setTotalCount(TotalRecordCount);
      setMoreToGet(MoreToGet);
      setCurrentPage(Math.ceil(LastRowNumber / (searchFilter.MaxResults || pageSize)));
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPurchaseOrderList();
  }, [searchFilter]);

  return {
    loading,
    items,
    totalCount,
    pageSize: searchFilter.MaxResults || pageSize,
    currentPage,
    moreToGet,
    error,
    searchFilter,
    refetch: getPurchaseOrderList,
    setSearchFilter,
  };
};
