import React from 'react';

export const MasterCardNew = (props: {width?: string; height?: string}) => (
  <svg
    width={props.width || '35px'}
    height={props.height || '35px'}
    viewBox="0 0 181 111"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>mastercard</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="mastercard" fillRule="nonzero">
        <g id="XMLID_328_">
          <rect
            id="Rectangle-path"
            fill="#FF5F00"
            x="66.0300428"
            y="11.8708333"
            width="48.9399142"
            height="87.2583334"
          ></rect>
          <path
            d="M69.137339,55.5 C69.137339,37.7708335 77.5270387,22.0458333 90.4223177,11.8708333 C80.9450645,4.47083334 68.9819743,0 55.9313304,0 C25.013734,0 0,24.8208334 0,55.5 C0,86.1791666 25.013734,111 55.9313304,111 C68.9819743,111 80.9450645,106.529167 90.4223177,99.1291667 C77.5270387,89.1083335 69.137339,73.2291668 69.137339,55.5 Z"
            id="XMLID_330_"
            fill="#EB001B"
          ></path>
          <path
            d="M181,55.5 C181,86.1791666 155.986266,111 125.06867,111 C112.018026,111 100.054936,106.529167 90.5776824,99.1291667 C103.628326,88.9541667 111.862661,73.2291668 111.862661,55.5 C111.862661,37.7708335 103.472961,22.0458333 90.5776824,11.8708333 C100.054936,4.47083334 112.018026,0 125.06867,0 C155.986266,0 181,24.9750001 181,55.5 Z"
            id="Shape"
            fill="#F79E1B"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);
