import React from 'react';
import {css} from 'glamor';

interface ProgressBarProps {
  bgcolor?: string;
  completed?: number;
}

const ProgressBar: React.FC<ProgressBarProps> = props => {
  const {bgcolor, completed} = props;

  const containerStyles = css({
    background: `linear-gradient(to right, ${bgcolor} ${completed}%, #eee 0)`,
  });

  const fillerStyles = css({
    background: `linear-gradient(to right, white ${completed}%, #333 0)`,
    webkitBackgroundClip: 'text',
    webkitTextFillColor: 'transparent',
  });

  return (
    <div
      className={`${containerStyles} !relative flex !h-[24px] !w-full items-center justify-center !rounded-[50px] bg-[#EEE] !bg-blend-difference`}
    >
      <div
        className={`${fillerStyles} !h-full !w-full !bg-clip-text bg-[${bgcolor}] !absolute !top-0 !z-[999] !rounded-[inherit] text-center !text-base !font-bold !leading-6 text-transparent`}
      >{`${completed}% Finalising Setup`}</div>
    </div>
  );
};

export default ProgressBar;
