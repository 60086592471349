import React from 'react';
import {Button, IconButton, SearchInput, Typography} from 'spenda-ui-react';

import {EditBlackIcon} from '../../../../assets/svg';
import IconStatusFilter from '../../../../assets/svg/IconStatusFilter';
import {AUTH_INVENTORY_CATALOGUE_MANAGE_SUBSCRIBERS} from '../../../../routes/InventoryRoutes';
import {useHistory} from 'react-router-dom';

export const CreateCatalogueHeader = () => {
  const history = useHistory();
  const goToManageSub = () => {
    history.push(AUTH_INVENTORY_CATALOGUE_MANAGE_SUBSCRIBERS);
  };
  return (
    <header className="flex items-center justify-between border-b border-[#ECECEC] pb-2.5">
      <div className="flex items-center justify-start gap-2.5">
        <Typography variant="h2" className="font-light">
          WA Catalogue
        </Typography>
        <EditBlackIcon className="ml-3 cursor-pointer" data-autoid="lnkEditCustomer" />
      </div>
      <div className="flex items-center gap-2.5">
        <Button className="">Edit Bulk Details</Button>
        <div>
          <SearchInput
            reverse={true}
            clearIcon
            iconButtonProps={{
              name: 'Search',
            }}
            data-autoid="txtSearchInput"
            autoFocus
            placeholder="Search..."
          />
        </div>
        <IconButton variant="outlined" name="statusFilter" className="min-w-10">
          <IconStatusFilter />
        </IconButton>
        <Button onClick={goToManageSub} variant="outlined">
          Manage Subscribers
        </Button>
        <Button variant="outlined">
          Add Products
        </Button>
      </div>
    </header>
  );
};
