import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const FilterButton = (props: {
  label: string;
  children?: any;
  onClick?: () => void;
  isActive?: boolean;
  isFavButton?: boolean;
}) => {
  const useStyles = makeStyles({
    root: {
      borderRadius: 4,
      border: '1px solid #1C78AD',
      backgroundColor: props.isActive ? '#1C78AD' : '#FFF',
      color: props.isActive ? '#FFF' : '#1C78AD',
      height: 38,
      flex: '1 1 20%',
      fontSize: 16,
      padding: 0,
      '&:hover': {
        textDecoration: 'underline',
        backgroundColor: props.isActive ? '#1C78AD' : '#FFF',
        color: props.isActive ? '#FFF' : '#1C78AD',
      },
      label: {
        textTransform: 'capitalize',
      },
      width: '100%',
    },
    label: {
      padding: `${props.isFavButton ? '5px' : ''}`,
    },
  });

  const classes = useStyles();

  return (
    <Button
      data-autoid={props.label?.toLowerCase().split(' ').join('-')}
      classes={{
        root: classes.root,
        label: classes.label,
      }}
      onClick={props.onClick}
    >
      {props.children} {props.label}
    </Button>
  );
};

export default FilterButton;
