import PS_01 from './paymentservices-01.png';
import PS_02 from './paymentservices-02.png';
import PS_03 from './paymentservices-03.png';
import PS_04 from './paymentservices-04.png';
import PS_05 from './paymentservices-05.png';
import PS_06 from './paymentservices-06.png';
import PS_07 from './paymentservices-07.png';
import PS_08 from './paymentservices-08.png';
import PS_09 from './paymentservices-09.png';

import icon1 from './hassleFree.png';
import icon2 from './paymentQuickly.png';
import icon3 from './morePaymentOptions.png';
import icon4 from './secure.png';

export const PSOnboardingImages = {
  PS_01,
  PS_02,
  PS_03,
  PS_04,
  PS_05,
  PS_06,
  PS_07,
  PS_08,
  PS_09,
};

export const PSKeyPointIcons = {
  icon1,
  icon2,
  icon3,
  icon4,
};
