import React, {useState} from 'react';
import {ARDialogTemplate} from '../ARDialogTemplate';
import {Button, Input, Typography} from 'spenda-ui-react';
import {ISelectedRow} from '../../../screens/AccountsReceivable/requires-attention/RequiresAttention';
import {useStagedTransactionsAPI} from '../../../services/useStagedTransactionsAPI';

interface IDismissTransactionModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedRows: ISelectedRow[];
  handleDone: () => void;
}
export const DismissTransactionModal = (props: IDismissTransactionModalProps) => {
  // Props
  const {isOpen, onClose, selectedRows, handleDone} = props;

  // State
  const [confirmText, setConfirmText] = useState('');

  // API
  const {rejectStagedTransactions} = useStagedTransactionsAPI();

  const handleSubmit = async () => {
    try {
      if (selectedRows?.length) {
        const messageIds = selectedRows?.map(selectedRow => selectedRow.messageID);
        await rejectStagedTransactions(messageIds);
      }
    } catch (error) {
      console.error('Dismiss Transaction Failed:', error);
    } finally {
      handleDone?.();
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmText(e?.target?.value);
  };

  return (
    <ARDialogTemplate
      dialogProps={{size: 'xl', open: isOpen, className: '!min-w-[496px] !max-w-[496px]'}}
      isFullScreen
      header={
        <Typography data-autoid={'lblWarningTitle'} className="text-xl font-light text-[#333333]">
          Dismiss Transaction
        </Typography>
      }
      body={
        <div className="mb-12 mt-10">
          <Typography className="px-6 text-center font-light text-black-800">
            Are you sure you would like to dismiss these transactions? This can not be undone. Type 'DISMISS' to
            continue.
          </Typography>
          <div className="flex justify-center">
            <Input
              className="mt-5 !border-t-[#333] focus:!border-t-primary"
              placeholder="DISMISS"
              name="confirm"
              onChange={handleChange}
              containerProps={{className: 'w-2/4'}}
            />
          </div>
        </div>
      }
      footer={
        <div className="flex w-full flex-row items-center justify-between">
          <Button
            data-autoid={`btnCancel`}
            variant="outlined"
            className="bg-white"
            color="primary"
            onClick={() => onClose()}
          >
            Cancel
          </Button>
          <Button
            disabled={confirmText !== 'DISMISS'}
            data-autoid={`btnDismiss`}
            variant="filled"
            color="error"
            onClick={handleSubmit}
          >
            Dismiss
          </Button>
        </div>
      }
    />
  );
};

export default DismissTransactionModal;
