import React from 'react';

import {SModal} from '../modals/modalSpendaMeterialUI';
import {SButton} from '../buttons/Button';
import {Box, makeStyles} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import KYCIcon from '../../assets/svg/accounts-payable/kyc.svg';

const useAmlModalStyles = makeStyles({
  apOnboardingModal: {
    maxWidth: '500px',
    height: '480px',
  },

  apBusinessHeader: {
    borderBottom: '1px solid #CCCCCC',
    '& h2': {
      fontFamily: 'Poppins, sans-serif !important',
      fontWeight: 300,
      fontSize: '28px',
      color: '#333333',
    },
  },

  boxHeight: {
    maxWidth: '372px',
    padding: '60px 0',
    '& img': {
      marginBottom: '55px',
      width: ' 100px',
      height: '100px',
    },
    '& p': {
      color: '#707070',
      fontSize: '14px',
    },
  },
  footer: {
    maxWidth: '477px',
    background: 'rgb(173 173 173 / 20%)',
    borderRadius: '8px',
    left: '12px',
  },
});
export interface IAMLKYCModalProps {
  isLoading: boolean;
  handleClose: () => void;
}

export const AMLKYCModal = (props: IAMLKYCModalProps) => {
  const classes = useAmlModalStyles();

  return (
    <SModal open>
      <div className={`text-xl spenda-color flex justify-center items-center h-full max-w-full`}>
        <div className={`modal-box ${classes.apOnboardingModal} px-5 pt-3 pb-5`}>
          <Box className={`${classes.apBusinessHeader} flex justify-between -mx-5 px-3 pb-3`}>
            <h2 className={`text-center w-full`}>Verify your identity</h2>
            <Box className="text-right flex justify-end">
              <span className="ap-onboarding-close-icon">
                <CloseIcon onClick={props.handleClose} data-autoid="btnClose" />
              </span>
            </Box>
          </Box>
          <div className={`${classes.boxHeight} relative py-8 w-full mx-auto`}>
            <Box className="flex flex-col justify-center items-center text-center">
              <img src={KYCIcon} data-autoid="imgKyc" />
              <p className="font-poppins">
              We've submitted your application for AML / KYC to the sales team. Unfortunately, until your identity has been verified you are unable to make a payment. We will contact you shortly.
              </p>
            </Box>
          </div>
          <Box className={`${classes.footer} w-full flex justify-center items-center absolute bottom-3 p-2`}>
            <SButton
              borderRadius="6px"
              margin="0 10px 0 0"
              textColor="white"
              lineHeight="l"
              height="40px"
              boxShadow="none"
              fontFamily="poppins"
              fontSize="14px"
              border="1px solid #1C78AD"
              width="84px"
              backgroundColor="#1C78AD"
              data-autoid="btnDone"
              onClick={props.handleClose}
            >
              Done
            </SButton>
          </Box>
        </div>
      </div>
    </SModal>
  );
};
