import useConnectedSupplierAPI from '../../../services/useConnectedSuppliersAPI';
import {AccountUsage, PaymentProviderName, PaymentServiceName} from '../../../model/constants/Constants';
import useSpendaPaymentServicesAPI, {
  IAccountResponse,
  INewAccountResponse,
  INewCreditCardAccountRequest,
  INewCreditCardAccountResponse,
  UpdateAccountRequest,
} from '../../../services/useSpendaPaymentServicesAPI';
import {ICreditCardT, ITenantSupplierPaymentMethod} from '../../../model/payment/PaymentMethod';
import {IAddBankAccountDetailsRequest} from '../../../services/useSpendaPaymentServicesAPI';
import {PaymentConverter} from '../../../utils/data-converters/payment-converter';
import usePaymentAuthorityAPI from '../../../services/usePaymentAuthorityAPI';
import {IDbiReply} from '../../../model/payment/AirPlusDBI';

interface IPaymentFromBuyer {
  linkedSupplierId?: number;
  merchantGlobalID?: string;
  accountUsage?: AccountUsage;
  fees88078?: boolean;
}

export const usePaymentMethods = (props?: IPaymentFromBuyer) => {
  const linkedSupplierId = props?.linkedSupplierId;
  const merchantGlobalID = props?.merchantGlobalID;
  const accountUsage = props?.accountUsage || AccountUsage.BUYER;
  const fees88078 = props?.fees88078;

  const {
    deletePaymentMethod: deletePaymentMethodAPI,
    setDefaultPaymentMethod: setDefaultPaymentMethodAPI,
    updatePaymentMethodDetails: updatePaymentMethodDetailsAPI,
  } = useConnectedSupplierAPI();

  const {
    saveCreditCard: saveBPSPCreditCardAPI,
    addBankAccount: addBankTransferPayment,
    getAccount,
    initiateVerification: initiateVerificationAPI,
    verify: codeVerificationAPI,
    deleteBankAccount: deleteSPSPaymentAccount,
    retryVerification: onRetryVerificationAPI,
    updateAccountDetails: updateAccountDetailsAPI,
    addBankAccountDetails: addBankAccountDetailsAPI,
    isLoading: isLoadingSpendaPaymentServices,
    getAccounts: getAccountsAPI,
    getAccountsWithFee,
    getAirPlusDBIConfig,
  } = useSpendaPaymentServicesAPI();

  const {
    savePaymentAuthorisationRules,
    getPaymentMethodAuthorisationRules,
    authorisePayment,
    approvePaymentAuthorisation,
    getPaymentMethodAuthorisationDetails,
    rejectPaymentAuthorisation,
    resetAuthenticationCode,
  } = usePaymentAuthorityAPI();

  const getPaymentMethods: (
    creditPaymentServiceName?: PaymentServiceName,
  ) => Promise<ITenantSupplierPaymentMethod[]> = async (creditPaymentServiceName?: PaymentServiceName) => {
    const paymentMethods = await getAccountsAPI(PaymentProviderName.Spenda_Payment_Services, {
      merchantGlobalID,
      accountUsage,
      creditPaymentServiceName,
    });

    const convertedPaymentMethods = paymentMethods?.map(method =>
      PaymentConverter.SpsToFiserveModel(method),
    ) as ITenantSupplierPaymentMethod[];

    return convertedPaymentMethods;
  };

  const getPaymentMethodsWithFees = async (paymentAmount: number, creditPaymentServiceName?: PaymentServiceName) => {
    const query = {paymentAmount, merchantGlobalID, accountUsage, creditPaymentServiceName};
    const response = await getAccountsWithFee(PaymentProviderName.Spenda_Payment_Services, query);

    const convertedPaymentMethods = response?.map(method =>
      PaymentConverter.SpsToFiserveModel(method, fees88078),
    ) as ITenantSupplierPaymentMethod[];

    return convertedPaymentMethods;
  };

  const saveCreditCard: (
    data: ICreditCardT,
    isBPSPMerchant?: boolean,
  ) => Promise<ITenantSupplierPaymentMethod | INewCreditCardAccountResponse | undefined> = async (
    data: ICreditCardT,
  ) => {
    // Call BPSP endpoint to save card
    // TODO: SaveCard for BPSP has different request payload than Fiserv
    try {
      const bpspres = await saveBPSPCreditCardAPI(PaymentProviderName.Spenda_Payment_Services, {
        cardType: data.CardType,
        cardNumber: data.CardNumber,
        cardholderName: data.CardHolderName,
        expiry: data.ExpiryMMYY,
        ccv: data.CardVerificationNumber,
        isInvigoDefault: data.IsInvigoDefault,
        merchantTenantGlobalID: merchantGlobalID,
        isSingleUse: data.IsSingleUse,
      } as INewCreditCardAccountRequest);

      if (bpspres.accountGUID && !bpspres.error) {
        await updateAccountDetails(bpspres.accountGUID, {
          friendlyName: data.FriendlyName,
          isInvigoDefault: data.IsInvigoDefault || false,
        });
      }

      return bpspres;
    } catch (e) {
      console.error(e);
    }
  };

  const addBankTransfer = async (): Promise<INewAccountResponse> => {
    return addBankTransferPayment(AccountUsage.BUYER, PaymentProviderName.Spenda_Payment_Services);
  };

  const removeCreditCard: (paymentOption: ITenantSupplierPaymentMethod) => Promise<boolean | undefined> = async (
    paymentOption: ITenantSupplierPaymentMethod,
  ) => {
    if (paymentOption.PaymentAccountGUID) {
      return deleteSPSPaymentAccount(PaymentProviderName.Spenda_Payment_Services, paymentOption.PaymentAccountGUID);
    }

    if (!linkedSupplierId || !paymentOption.SupplierPaymentOptionID) return false;
    return deletePaymentMethodAPI(linkedSupplierId, paymentOption.SupplierPaymentOptionID);
  };

  const setDefaultCreditCard: (paymentOptionId: number) => Promise<ITenantSupplierPaymentMethod | undefined> = async (
    paymentOptionId: number,
  ) => {
    if (!linkedSupplierId || !paymentOptionId) return undefined;
    const res = await setDefaultPaymentMethodAPI(linkedSupplierId, paymentOptionId);
    return res.Value;
  };

  const fetchSPSAccount: (accountGUI: string) => Promise<IAccountResponse> = async (accountGUID: string) => {
    return getAccount(PaymentProviderName.Spenda_Payment_Services, accountGUID);
  };

  const fetchAirPlusDBIConfig: (accountGUI: string) => Promise<IDbiReply> = async (accountGUID: string) => {
    return getAirPlusDBIConfig(PaymentProviderName.Spenda_Payment_Services, accountGUID);
  };

  const initiateVerification: (accountGUID: string) => Promise<IAccountResponse> = (accountGUID: string) => {
    return initiateVerificationAPI(PaymentProviderName.Spenda_Payment_Services, accountGUID);
  };

  const onVerifyCode: (code: string, accountGUID: string) => Promise<IAccountResponse> = (
    code: string,
    accountGUID: string,
  ) => {
    return codeVerificationAPI(PaymentProviderName.Spenda_Payment_Services, accountGUID, {verificationCode: code});
  };

  const onRetryVerification: (accountGUID: string) => Promise<IAccountResponse> = (accountGUID: string) => {
    return onRetryVerificationAPI(PaymentProviderName.Spenda_Payment_Services, accountGUID);
  };

  const updatePaymentMethodDetails: (supplierPaymentOptionID: string, req: UpdateAccountRequest) => Promise<any> = (
    supplierPaymentOptionID: string,
    req: UpdateAccountRequest,
  ) => {
    return updatePaymentMethodDetailsAPI(linkedSupplierId!.toString(), supplierPaymentOptionID, req);
  };

  const updateAccountDetails: (accountGUID: string, req: UpdateAccountRequest) => Promise<any> = (
    accountGUID: string,
    req: UpdateAccountRequest,
  ) => {
    return updateAccountDetailsAPI(PaymentProviderName.Spenda_Payment_Services, accountGUID, req);
  };

  const addBankAccountDetails = (payload: IAddBankAccountDetailsRequest): Promise<INewAccountResponse> => {
    return addBankAccountDetailsAPI(payload, AccountUsage.BUYER, PaymentProviderName.Spenda_Payment_Services);
  };

  return {
    addBankTransfer,
    saveCreditCard,
    removeCreditCard,
    setDefaultCreditCard,
    fetchSPSAccount,
    initiateVerification,
    onVerifyCode,
    onRetryVerification,
    updateAccountDetails,
    updatePaymentMethodDetails,
    addBankAccountDetails,
    getPaymentMethods,
    getPaymentMethodAuthorisationRules,
    savePaymentAuthorisationRules,
    authorisePayment,
    approvePaymentAuthorisation,
    getPaymentMethodAuthorisationDetails,
    rejectPaymentAuthorisation,
    resetAuthenticationCode,
    isLoadingSpendaPaymentServices,
    fetchAirPlusDBIConfig,
    getPaymentMethodsWithFees,
  };
};
