import {css} from 'glamor';
import React, {useContext, useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import AppContext from '../../context/app/appContext';
import ARContext from '../../context/ar-context/ARContext';
import PurchasingContext from '../../context/purchasing/purchasingContext';
import {usePsblBatchHook} from '../../hooks/useARHook';
import useConnectedSupplier from '../../hooks/useConnectedSupplier';
import {useFeatureFlags} from '../../hooks/useFeatureFlags';
import {usePaymentUtilities} from '../../hooks/usePaymentUtilities';
import {DatTypes, PaymentServiceName, PaymentWidgetScope} from '../../model/constants/Constants';
import {IGetSurchargeProps} from '../../model/payment-widget/PaymentWidget';
import {ICreditCardT} from '../../model/payment/PaymentMethod';
import {useSupplierTransactionsAPI} from '../../services/useSupplierTransactionsAPI';
import {IDefaultDbiDetails, ISubmitPayment, PaymentWidgetModal} from '../pay-by-link/payment-widget/PaymentWidget';
import {usePaymentMethods} from '../pay-by-link/payment-widget/usePaymentMethods';
import {usePaymentsFromBuyer} from '../pay-by-link/payment-widget/usePaymentsFromBuyer';
import moment from 'moment';
import {IARBatchResponse} from '../../model/accounts-receivable/batch';

const backdropFilterCSS = css({
  background: 'rgb(255, 255, 255)',
  '@supports ((backdrop-filter: blur(10px))) or (-webkit-backdrop-filter: blur(10px))': {
    background: 'rgba(255, 255, 255, 0.5)',
    backdropFilter: 'blur(10px)',
    '& -webkit-backdrop-filter': 'blur(10px)',
  },
});
interface IAuthorisePaymentProps {
  widgetScope: PaymentWidgetScope;
  onClose?: () => void;
  onSuccess?: () => void;
  paybleAmount?: number;
  facilityGuid?: string;
}

export const PSBLWidget = (props: IAuthorisePaymentProps) => {
  const {widgetScope, onClose, onSuccess, paybleAmount, facilityGuid} = props;
  const history = useHistory();
  const params = useParams<{supplierId?: string}>();

  const {
    featLoyaltyPoints,
    prepayments72511,
    fees88078: fees88078Flag,
    scheduledPayments83107,
    scheduledPaymentsV2,
  } = useFeatureFlags().supplierDriven();

  const isPrepaymentScope = PaymentWidgetScope.PREPAYMENT === widgetScope;
  const isPSBLScope = PaymentWidgetScope.PSBL === widgetScope;
  const isSpendaFinanceScope = PaymentWidgetScope.SPENDA_FINANCE === widgetScope;

  const fees88078 =
    fees88078Flag &&
    [PaymentWidgetScope.PSBL, PaymentWidgetScope.PREPAYMENT, PaymentWidgetScope.SPENDA_FINANCE]?.includes(widgetScope);

  const {paymentAuth72488, airplus79131, onboardingUpgradeV1} = useFeatureFlags().tenantOwned();

  const {user, marketplaceSupplier, availablePaymentMethodTypes, tenantInfo, switchSupplier, suppliers} =
    useContext(AppContext);

  const {creditAndClaimsList, setCreditAndClaimsList} = useContext(PurchasingContext);

  const {psblBatch} = useContext(ARContext);
  const {selectedTxList, psblBatchAPIResponse} = psblBatch;
  const defaultDbiDetails: IDefaultDbiDetails | undefined =
    widgetScope === PaymentWidgetScope.PSBL
      ? {
          AU: psblBatchAPIResponse?.invoicePaymentAllocationRefNumber as string,
          IK: 'Batch Payment',
        }
      : widgetScope === PaymentWidgetScope.PREPAYMENT
        ? {
            AU: moment().format('DD/MM/YYYY'),
            IK: 'Prepayment',
          }
        : undefined;

  let _supplierId = marketplaceSupplier?.SupplierID || params.supplierId;

  if (typeof _supplierId === 'string') {
    _supplierId = parseInt(_supplierId);
  }

  //prepayments or repayments
  const [nonInvoicePaymentAmount, setNonInvoicePaymentAmount] = useState(paybleAmount ?? 0);
  const [paymentDescription, setPaymentDescription] = useState('');

  const {
    getPaymentMethods,
    getPaymentMethodsWithFees,
    fetchSPSAccount,
    initiateVerification,
    onRetryVerification,
    onVerifyCode,
    removeCreditCard,
    saveCreditCard,
    updateAccountDetails,
    getPaymentMethodAuthorisationRules,
    savePaymentAuthorisationRules,
    authorisePayment,
    approvePaymentAuthorisation,
    getPaymentMethodAuthorisationDetails,
    resetAuthenticationCode,
    rejectPaymentAuthorisation,
    fetchAirPlusDBIConfig,
  } = usePaymentMethods({
    linkedSupplierId: _supplierId,
    merchantGlobalID: marketplaceSupplier?.GlobalID,
    fees88078,
  });

  const {search: getSupplierStatementSummary} = useSupplierTransactionsAPI();

  const {setBatchPaymentGuid, getSelectedInvoiceTotal, getBatchDetail, retryPSBLBatch} = usePsblBatchHook(_supplierId);

  const {isBPSP} = useConnectedSupplier();

  const {
    ARcheckBatchPaymentStatus,
    getSurcharge: getSurchargeAPI,
    submitPayment: submitPaymentAPI,
    getPSBLSurcharge,
    fetchRepaymentStatus,
  } = usePaymentsFromBuyer({
    linkedSupplierId: _supplierId,
    paymentAuth72488,
    prepayments72511,
    scheduledPayments83107,
    airplus79131,
    fees88078,
  });

  useEffect(() => {
    //TODO: add "changeSupplierMarketplace" into some common function file
    const changeSupplierMarketplace = async () => {
      if (params.supplierId && parseInt(params.supplierId) != marketplaceSupplier?.SupplierID) {
        const s = suppliers?.find(s => s.SupplierID == parseInt(params.supplierId || '0'));
        if (!s) {
          return;
        }
        await switchSupplier(s.AvailableModules, s.MarketplacePath);
        return;
      }
    };

    changeSupplierMarketplace();
  }, [_supplierId]);

  const onDone = () => {
    switch (widgetScope) {
      case PaymentWidgetScope.PSBL:
        onClose && onClose();
        getBatchDetail('SUCCESS');
        onSuccess && onSuccess();
        break;
      case PaymentWidgetScope.SPENDA_FINANCE:
        onSuccess && onSuccess();
        onClose && onClose();
        break;
      case PaymentWidgetScope.PREPAYMENT:
        history.push(`/supplier/${_supplierId}/bills`);
        break;
      default:
        onClose && onClose();
        break;
    }
  };

  const onCloseWidget = () => {
    switch (widgetScope) {
      case PaymentWidgetScope.PSBL:
      case PaymentWidgetScope.SPENDA_FINANCE:
        onClose && onClose();
        break;
      case PaymentWidgetScope.PREPAYMENT:
        history.push(`/supplier/${_supplierId}/bills`);
        break;
      default:
        onClose && onClose();
        break;
    }
  };

  const onSaveCreditCard = (card: ICreditCardT) => {
    const isBPSPMerchant = marketplaceSupplier ? isBPSP(marketplaceSupplier) : false;
    return saveCreditCard(card, isBPSPMerchant);
  };

  const invoices = selectedTxList?.filter(a => a.transactionTypeID === DatTypes.Invoice);
  const _pu = usePaymentUtilities();
  const isBatchPartialPayment = _pu.arBatchPartialPaymentInProgress(invoices ?? []);
  const totalPayableAmount = invoices?.reduce((a, c) => a + (c.amountToPay || c.balance || 0), 0);

  const getSurcharge = async (props: IGetSurchargeProps) => {
    const {paymentAccountGUID, ccType, isPayerFeeApplicable, nonInvoicePaymentAmount, selectedPaymentMethod} = props;
    if (widgetScope === PaymentWidgetScope.PREPAYMENT || widgetScope === PaymentWidgetScope.SPENDA_FINANCE) {
      return getSurchargeAPI({
        ccType,
        paymentAccountGUID,
        refBusTrans: [{AppliedAmount: nonInvoicePaymentAmount}],
        isPayerFeeApplicable,
        nonInvoicePaymentAmount,
      });
    } else if (widgetScope === PaymentWidgetScope.PSBL) {
      const res = await setBatchPaymentGuid({
        invoicePaymentAllocationID: psblBatch?.invoicePaymentAllocationID!,
        paymentAccountGUID: paymentAccountGUID!,
      });
      const result = selectedPaymentMethod && (await getPSBLSurcharge(selectedPaymentMethod, res));
      if ((selectedPaymentMethod && selectedPaymentMethod?.IsPayerFeeApplicable) || isBatchPartialPayment) {
        return result;
      }
      const amountToPay = getSelectedInvoiceTotal(psblBatch?.selectedTxList);
      return {
        PaymentAmount: Number(amountToPay),
        SurchargeAmount: res.merchantSurcharge || 0,
        PayerFeeDescription: '',
        TotalAmount: res.totalAmount,
      } as any;
    }
  };

  const submitPayment = async (submitPaymentPayload: ISubmitPayment) => {
    const {
      pm,
      merchantSurcharge,
      temporalCard,
      isCreditNotesAppliedGreaterThanInvoiceTotal,
      creditStatementSummary,
      isLoyaltyPointsToggleOn,
      authorisationToken,
      scheduledPaymentDate,
      airPlusDbiData,
    } = submitPaymentPayload;

    let psblBatchAPIResponse: IARBatchResponse | undefined;
    if (isPSBLScope && fees88078) {
      const payload = {
        invoicePaymentAllocationID: psblBatch?.invoicePaymentAllocationID!,
        paymentAccountGUID: pm?.PaymentAccountGUID!,
      };
      psblBatchAPIResponse = await setBatchPaymentGuid?.(payload, false);
    }
    return submitPaymentAPI(
      pm,
      merchantSurcharge,
      [], // invoices array
      temporalCard,
      isCreditNotesAppliedGreaterThanInvoiceTotal,
      creditStatementSummary,
      isLoyaltyPointsToggleOn,
      authorisationToken,
      nonInvoicePaymentAmount,
      paymentDescription,
      widgetScope,
      facilityGuid,
      scheduledPaymentDate,
      airPlusDbiData,
      psblBatchAPIResponse,
    );
  };

  const handleFetchRepaymentStatus = (repaymentGUID: string) => {
    return fetchRepaymentStatus(repaymentGUID, facilityGuid!);
  };

  const handleGetPaymentMethods = () => {
    if (fees88078) {
      let paymentAmount = 0;
      if (isPrepaymentScope) {
        paymentAmount = nonInvoicePaymentAmount;
      } else if (isBatchPartialPayment && isPSBLScope) {
        paymentAmount = totalPayableAmount;
      } else if (isPSBLScope && psblBatch?.psblBatchAPIResponse) {
        paymentAmount = psblBatch?.psblBatchAPIResponse?.totalAmount;
      }
      return getPaymentMethodsWithFees(
        paymentAmount,
        isSpendaFinanceScope ? PaymentServiceName.MarketplaceProvider : undefined,
      );
    } else if (widgetScope === PaymentWidgetScope.SPENDA_FINANCE) {
      return getPaymentMethods(PaymentServiceName.MarketplaceProvider);
    } else {
      return getPaymentMethods();
    }
  };

  return (
    <>
      <div
        className={`z-10 h-[inherit] overflow-y-auto ${
          widgetScope === PaymentWidgetScope.PREPAYMENT ? '' : backdropFilterCSS
        }`}
      >
        <PaymentWidgetModal
          isShowWithoutModal={[PaymentWidgetScope.PSBL, PaymentWidgetScope.SPENDA_FINANCE].includes(widgetScope)}
          totalPayableAmount={totalPayableAmount}
          userEmailAddress={user?.UserName}
          userID={user?.UserID}
          fetchSPSAccount={fetchSPSAccount}
          getSurcharge={getSurcharge}
          initiateVerification={initiateVerification}
          onRetryVerification={onRetryVerification}
          loadPaymentOptions={handleGetPaymentMethods}
          onClose={onCloseWidget}
          onVerifyCode={onVerifyCode}
          open={true}
          removeCreditCard={removeCreditCard}
          saveCreditCard={onSaveCreditCard}
          submitPayment={submitPayment}
          updateAccountDetails={updateAccountDetails}
          tenantName={user?.TenantName}
          marketplaceSupplier={marketplaceSupplier}
          checkPaymentStatus={ARcheckBatchPaymentStatus}
          checkRepaymentStatus={handleFetchRepaymentStatus}
          widgetScope={widgetScope}
          isBPSP={isBPSP(marketplaceSupplier)}
          featLoyaltyPoints={featLoyaltyPoints}
          availablePaymentOptions={marketplaceSupplier?.AvailablePaymentMethods}
          availablePaymentMethodTypes={availablePaymentMethodTypes}
          nonInvoicePaymentAmount={nonInvoicePaymentAmount}
          setNonInvoicePaymentAmount={setNonInvoicePaymentAmount}
          paymentDescription={paymentDescription}
          setPaymentDescription={setPaymentDescription}
          onDone={onDone}
          tenantUserDetails={tenantInfo?.TenantUserDetails}
          creditAndClaimsList={creditAndClaimsList}
          setCreditAndClaimsList={setCreditAndClaimsList}
          selectedBatchDetails={psblBatch}
          getSupplierStatementSummary={getSupplierStatementSummary}
          setBatchPaymentGuid={setBatchPaymentGuid}
          retryPSBLBatch={retryPSBLBatch}
          paymentAuth72488={paymentAuth72488}
          getPaymentMethodAuthorisationRules={getPaymentMethodAuthorisationRules}
          savePaymentAuthorisationRules={savePaymentAuthorisationRules}
          authorisePayment={authorisePayment}
          approvePaymentAuthorisation={approvePaymentAuthorisation}
          getPaymentMethodAuthorisationDetails={getPaymentMethodAuthorisationDetails}
          resetAuthenticationCode={resetAuthenticationCode}
          rejectPaymentAuthorisation={rejectPaymentAuthorisation}
          scheduledPayments83107={scheduledPayments83107}
          scheduledPaymentsV2={scheduledPaymentsV2}
          airplus79131={airplus79131}
          isAPOnboardingV1={onboardingUpgradeV1}
          fetchAirPlusDBIConfig={fetchAirPlusDBIConfig}
          defaultDbiDetails={defaultDbiDetails}
          fees88078={fees88078}
        />
      </div>
    </>
  );
};
