import React, {useContext, useState} from 'react';
import {Layout} from '../components/layout/Layout';
import AppContext from '../context/app/appContext';
import {PurchaseNowDialog} from '../components/onboarding/PurchaseNowDialog';
import {ModuleTypes} from '../model/constants/Constants';
import {useHistory} from 'react-router-dom';
import {AUTH_MENU, AUTH_TENANT_SETUP} from '../routes/AuthenticatedRoutes';

const NoModulesOnMessage = () => {
  return (
    <div className="text-xl spenda-color flex justify-center items-center h-full">
      <div className="bg-white p-8 rounded">
        <h3 className="mb-4">Welcome to Spenda!</h3>
        <p>
          You currently do not have the required access to Spenda, but you can still update your user and security
          settings by clicking on the user icon in the top right.
        </p>
        <p>
          If you wish to see what you can do in Spenda, please contact Support on{' '}
          <a className="font-bold" href="tel:1300 682 521">
            1300 682 521
          </a>
          .
        </p>
      </div>
    </div>
  );
};

const Home = () => {
  const {tenantInfo} = useContext(AppContext);
  const history = useHistory();

  const [showDaysLeftDialog, setShowDaysLeftDialog] = useState<boolean>(false);

  const isARModuleSetup =
    Array.isArray(tenantInfo?.Modules) &&
    tenantInfo?.Modules.find(m => {
      return m && m.IsModuleSetup && m.ModuleID === ModuleTypes.SpendaCollectAR;
    });

  if (!isARModuleSetup) {
    if (!tenantInfo?.TenantUserDetails?.AgreedTsAndCs) {
      history.push(AUTH_TENANT_SETUP);
    } else {
      history.push(AUTH_MENU);
    }
  }

  return (
    <Layout
      splitWidthType={4}
      leftPanel={
        showDaysLeftDialog ? (
          <PurchaseNowDialog handleClose={() => setShowDaysLeftDialog(false)} expired />
        ) : (
          <NoModulesOnMessage />
        )
      }
    />
  );
};

export default Home;
