import React from 'react';
import {FavoriteBorder, Favorite} from '@material-ui/icons';

// const ProductCardFavouriteProps = {}

export const ProductCardFavourite = (props: {isFavourite: boolean; setFavourite: () => void}) => {
  return props.isFavourite ? (
    <span title="Remove Favourite">
      <Favorite onClick={props.setFavourite} color="secondary" style={{color: '#1C78AD'}} />
    </span>
  ) : (
    <span title="Add Favourite">
      <FavoriteBorder onClick={props.setFavourite} color="secondary" style={{color: '#1C78AD'}} />
    </span>
  );
};
