import so_create from './Sales-create.png';
import so_home from './Sales-home.png';
import so_pack from './Sales-pack.png';
import so_packagingtype from './Sales-packagingtype.png';
import so_ship from './Sales-ship.png';

import icon1 from './priceManagement.png';
import icon2 from './collaboration.png';
import icon3 from './ManualProcess.png';
import icon4 from './CustomerExp.png';

export const SOOnboardingImages = {
  so_create,
  so_home,
  so_pack,
  so_packagingtype,
  so_ship,
};

export const SOKeyPointIcons = {
  icon1,
  icon2,
  icon3,
  icon4,
};
