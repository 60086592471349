import React, {FunctionComponent, useRef, PropsWithChildren} from 'react';
import useMediaAPI from '../../services/useMediaAPI';
import {FileDetails} from '../../model/FileDetails';
import LoadingOverlay from '../ui/LoadingOverlay';
import {Upload} from 'spenda-ui-react';

interface IMediaUploader extends PropsWithChildren {
  className?: string;
  onChange: (files: FileDetails[]) => void;
  label?: string;
  icon?: JSX.Element;
}

const defaultFileType = ['image/*'];

export const MediaUploader: FunctionComponent<IMediaUploader> = props => {
  const inputFile = useRef<HTMLInputElement>(null);
  const {upload, isLoading} = useMediaAPI();

  const onUploadClick = () => {
    if (inputFile.current !== null) {
      inputFile.current.click();
    }
  };

  const changeHandler = (files: FileList) => {
    // event.stopPropagation();
    // event.preventDefault();
    let file = null;
    if (files !== null) {
      file = files[0];
    }

    if (file && defaultFileType.includes(file.type.split('/')[0] + '/*')) {
      const formData = new FormData();

      formData.append('UploadFiles', file, file.name);

      return upload(formData, {
        'content-type': 'multipart/form-data',
      }).then((files: FileDetails[]) => {
        props.onChange(files);
      });
    }
  };

  return (
    <div className={`flex ${props.className || ''}`} onClick={onUploadClick}>
      <Upload label={props.label ?? ''} className={props.className} icon={props.icon} onChange={changeHandler} />
      <LoadingOverlay isLoading={isLoading}>{props.children}</LoadingOverlay>
    </div>
  );
};
