import React from 'react';
import SpendaFinanceBackground from '../../assets/svg/SpendaFinanceBackground.svg';
import SpendaIcon from '../../assets/svg/SpendaIcon.svg';
import {ITenantSupplierPaymentMethod} from '../../model/payment/PaymentMethod';
import {WidgetTooltip} from '../data-display/WidgetTooltip';
import {InfoRound} from '../../assets/svg/InfoRound';
import {PaymentServiceType} from '../../model/constants/Constants';
export const SpendaFinanceCard = (props: {cardDetails?: ITenantSupplierPaymentMethod}) => {
  const {cardDetails} = props;
  return (
    <div
      className="flex flex-col justify-between  h-[150px] w-[270px]  rounded-[14.5px] shadow-md p-3 mb-5 bg-contain bg-no-repeat bg-center"
      style={{
        backgroundImage: `url(${SpendaFinanceBackground}), linear-gradient(to right, #3E3F5A, #252637)`,
      }}
    >
      <div className="flex items-start justify-between w-full">
        <p className="text-base text-[#fff] font-medium font-poppins mt-1">Spenda Finance</p>
        <img src={SpendaIcon} className="w-[27.77px] h-[42.41px]" />
      </div>
      <div className="flex justify-between items-end mb-[2px]">
        <WidgetTooltip
          title={`Your ${
            [PaymentServiceType.BANK_ACCOUNT].includes(cardDetails?.DefaultRepaymentMethodType as PaymentServiceType)
              ? 'bank account'
              : 'card'
          } ending in ${
            cardDetails?.DefaultRepaymentMethodLast4 || '0000'
          } will be automatically charged on the due date.`}
          backgroundColor="#53546A"
          textColor="#fff"
          arrow={false}
          placement="right"
          maxWidth="220px"
          width="100%"
          fontSize="10px"
          fontWeight={400}
          margin="0 0 25px 5px"
          textAlign="left"
        >
          <div>
            <InfoRound style={{width: '13px', height: '13px', color: '#999999'}} />
          </div>
        </WidgetTooltip>
        <span
          data-autoid="lblFacilityID"
          className="[writing-mode:vertical-lr] font-poppins text-[8px] text-white font-normal mr-[2px]"
        >
          {String(cardDetails?.FacilityID).padStart(5, '0')}
        </span>
      </div>
    </div>
  );
};
