import useHttp from '../hooks/useHttp';
import {IActionResults} from '../model/ActionResults';
import {IConfigGroupDetails, IConfigObject, IGetConfigv2Payload} from '../model/Configs';

const useConfigAPI = () => {
  const {GET, POST, isLoading} = useHttp();

  const setConfig = (configObject: IConfigObject): Promise<IConfigObject> => {
    return POST('config/setConfig', configObject).then(data => data.Value);
  };

  const getGroup = (group: string): Promise<IConfigObject[]> => {
    return GET(`config/getgroup?group=${group}&id=${1}`).then(
      (data: IActionResults<IConfigGroupDetails>) => data.Value.Items
    );
  };

  const saveGroup = (configGroupDetails: IConfigGroupDetails): Promise<void> => {
    return POST('config/savegroup', configGroupDetails);
  };

  const getConfigV2 = (payload: IGetConfigv2Payload): Promise<any> => {
    return POST(`Config/GetConfig`, payload);
  };

  return {
    setConfig,
    getGroup,
    saveGroup,
    getConfigV2,
    isLoading,
  };
};

export default useConfigAPI;
