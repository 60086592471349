import React, {SetStateAction, useState} from 'react';
import {AlertDialogSlideV2} from './AlertDialogSlideV2';
import useQuotesAPI from '../../services/useQuotesAPI';
import {Typography} from 'spenda-ui-react';
import SecurityCode from '../inputs/SecurityCode';
import {IActiveSession} from '../../model/quotes/quotes';

interface IQuoteSessionTimeoutDialog {
  inviteCode: string;
  handleClose: React.Dispatch<SetStateAction<boolean>>;
  handleAcceptQuote: (sessionGUID: string) => void;
  handleResendCode: (acceptAuthorise: boolean, isSessionTimeout: boolean) => void;
  setActiveSession: React.Dispatch<SetStateAction<IActiveSession>>;
  inviteSource: string;
}

export const QuoteSessionTimeoutDialog = (props: IQuoteSessionTimeoutDialog) => {
  const {submitApprovalQuote} = useQuotesAPI();
  const [securityCode, setSecurityCode] = useState<string>('');
  const [securityCodeErrorMsg, setSecuityCodeErrorMsg] = useState('');
  const [isSending, setIsSending] = useState<boolean>(false);

  const onEnterVerificationCode = async () => {
    setSecuityCodeErrorMsg('');
    if (securityCode.trim().length < 6) {
      return;
    }
  };

  const handleChangeSecurityCode = (code: any) => {
    if (securityCodeErrorMsg) setSecuityCodeErrorMsg('');
    return setSecurityCode(code);
  };

  return (
    <AlertDialogSlideV2
      title={'Oops, your session has timed out!'}
      headingTextSize="h2"
      dialogActionsAlignment={'justify-between'}
      dialogBodyClassess="flex flex-col first:gap-y-5  gap-y-2 items-center h-[330px] pt-7 overflow-y-auto max-h-[18rem]"
      actions={[
        {
          label: 'Cancel',
          action: () => props.handleClose(false),
        },
        {
          label: 'Accept Quote',
          disabled: isSending,
          action: () => {
            setIsSending(true);
            submitApprovalQuote(props.inviteCode, securityCode)
              .then(async response => {
                props.setActiveSession({...response, isAuthorised: true});
                props.handleAcceptQuote(response.approvalSessionGUID!);
                props.handleClose(false);
              })
              .catch(error => {
                if (error && error?.length && error?.[0]?.message) {
                  setSecuityCodeErrorMsg(error?.[0]?.message);
                }
              })
              .finally(() => setIsSending(false));
          },
        },
      ]}
    >
      <div className="flex w-full max-w-[400px] flex-col items-center justify-center gap-2 md:max-w-[357px]">
        <Typography className="mb-5 text-center md:mb-9 md:max-w-[196px]" variant="small">
          {`Please enter the 6 digit code sent to you ending in ${props.inviteSource} to accept this quote.`}
        </Typography>
        <div className="mb-4 flex gap-2">
          <SecurityCode
            is2SA={true}
            onChange={code => handleChangeSecurityCode(code)}
            value={securityCode}
            onEnter={onEnterVerificationCode}
            newCodeDesign
            // below key to be used to show invalid code
            invalidCode={!!securityCodeErrorMsg}
          ></SecurityCode>
        </div>
        <button
          type="button"
          className="cursor-pointer"
          onClick={() => props.handleResendCode(false, true)}
          disabled={isSending}
        >
          <Typography className=" mb-3 font-semibold text-primary">Resend Code</Typography>
        </button>
      </div>
    </AlertDialogSlideV2>
  );
};
