import {useEffect, useRef, useState, useMemo} from 'react';
import {FilterCredits} from '../components/dialog/CreditNotesListDialog';
import {IARBatchResponse, IBatchOperation} from '../model/accounts-receivable/batch';
import {IPagedActionResults} from '../model/ActionResults';
import {CreditType, DatTypes, PSBLBatchAction, RequestedList, TransTypes} from '../model/constants/Constants';
import {IAppliedCredit, ICreditClaims} from '../model/credit-notes/CreditClaims';
import {SelectedCreditNotes} from '../model/credit-notes/SelectedCreditNotes';
import {IInvoice} from '../model/invoice/Invoice';
import {ISearchSupplierStatementsFilter} from '../model/search-filters/SearchSupplierStatementsFilter';
import {IConnectedSupplier} from '../model/supplier/ConnectedSupplier';
import {
  IConnectedSupplierStatementSummaries,
  IConnectedSupplierStatementSummary,
} from '../model/supplier/SupplierTransaction';
import {ISupplierMarket} from '../model/SupplierMarket';
import {PaymentWidgetPaymentSteps} from '../screens/pay-by-link/payment-widget/PaymentWidget';
import {usePsblBatchHook} from './useARHook';

interface IUseCreditNotes {
  setCreditsToApply?: ((credit: Partial<SelectedCreditNotes>[]) => void) | undefined;
  invoicesToPay?: IConnectedSupplierStatementSummary[] | Partial<IInvoice>[] | undefined;
  getTotalPaymentAndCreditToApplyValues?:
    | ((
        claim: Partial<SelectedCreditNotes>[],
        invoices: IConnectedSupplierStatementSummary[] | Partial<IInvoice>[],
        credits: Partial<SelectedCreditNotes>[]
      ) => Promise<IAppliedCredit>)
    | undefined;
  setCreditStatementSummary?: ((credit: IAppliedCredit | undefined) => void) | undefined;
  creditStatementSummary?: IAppliedCredit | undefined;
  marketplaceSupplier: ISupplierMarket | undefined;
  search:
    | ((
        filter: Partial<ISearchSupplierStatementsFilter>
      ) => Promise<IPagedActionResults<IConnectedSupplierStatementSummaries>>)
    | undefined;
  suppliers: IConnectedSupplier[] | undefined;
  getClaims: ((supplierId: number) => Promise<ICreditClaims[]>) | undefined;
  handleDialog?: () => void;
  isSingleInvoice?: boolean;
  setStep?: ((step: PaymentWidgetPaymentSteps) => void) | undefined;
  creditsToApply?: Partial<SelectedCreditNotes>[];
  psblBatch?: IARBatchResponse;
  totalInvoiceAmount?: number;
  totalCreditAndClaimsSelected?: number;
  creditAndClaimsList?: Partial<SelectedCreditNotes>[];
  setCreditAndClaimsList?: (creditAndClaims: Partial<SelectedCreditNotes>[]) => void;
}

const selectedListSum = (list: any) => {
  return list?.reduce((acc: number, cur: any) => {
    return (acc = acc + ((cur.Balance ?? cur.balance) || 0));
  }, 0);
};

export const useCreditNotes = (props: IUseCreditNotes) => {
  const {
    creditStatementSummary,
    creditsToApply,
    getTotalPaymentAndCreditToApplyValues,
    handleDialog,
    invoicesToPay,
    isSingleInvoice,
    marketplaceSupplier,
    psblBatch,
    search,
    setCreditStatementSummary,
    setCreditsToApply,
    setStep,
    totalInvoiceAmount: totalInvoiceAmountFromContext,
    totalCreditAndClaimsSelected,
    creditAndClaimsList: allCreditsTypesList,
    setCreditAndClaimsList,
  } = props;

  const considerCreditListFromContext = !isSingleInvoice;

  const {updateBatchTransactions, getBatchDetail: getPSBLBatchDetail} = usePsblBatchHook(
    marketplaceSupplier?.SupplierID!
  );

  const saveBatch = (data: IBatchOperation[]) => {
    return updateBatchTransactions(data);
  };

  const getBatchDetail = () => {
    return getPSBLBatchDetail();
  };

  const [availableCredit, setAvailableCredit] = useState<number>(0);
  const [loadingCreditList, setLoadingCreditList] = useState<boolean>(false);
  const [creditList, setCreditList] = useState<Partial<SelectedCreditNotes>[]>([]);
  const [showClaimDialog, setShowClaimDialog] = useState<boolean>(false);
  const [selectedCredits, setSelectedCredits] = useState<Partial<SelectedCreditNotes>[]>(creditsToApply || []);
  const [claimCode, setClaimCode] = useState('');
  const [claimId, setClaimId] = useState(0);

  const isLoadingApplyCredit = useRef(false);

  const isAutoApprovedClaimEnabled = marketplaceSupplier?.IsAutoApprovedforClaimRequests || false;

  const totalInvoiceAmount = isSingleInvoice
    ? (invoicesToPay &&
        (invoicesToPay as IConnectedSupplierStatementSummary[]).reduce((a, c) => a + (c.Balance || 0), 0)) ||
      0
    : totalInvoiceAmountFromContext || 0;

  const totalCreditSelected = !isSingleInvoice
    ? totalCreditAndClaimsSelected || 0
    : selectedCredits?.reduce((sum, credit) => sum + credit.Balance!, 0);

  const isSelectedCreditGreaterThanInvoiceAmount =
    totalCreditSelected > 0 && Boolean(totalCreditSelected! >= totalInvoiceAmount!);

  useEffect(() => {
    const isAllInvoicesSelected = invoicesToPay?.length !== creditStatementSummary?.Invoices?.length;
    const isCreditSummaryContainsInvoicesToPay = (invoicesToPay as IConnectedSupplierStatementSummary[])?.filter(
      (invoice: IConnectedSupplierStatementSummary) =>
        !creditStatementSummary?.Invoices?.some(
          (statement: IConnectedSupplierStatementSummary) => statement.TransID === invoice.TransID
        )
    )?.length;

    if (isAllInvoicesSelected || (!isSingleInvoice && isCreditSummaryContainsInvoicesToPay)) {
      setCreditsToApply && setCreditsToApply([]);
      setCreditStatementSummary && setCreditStatementSummary(undefined);
    }
  }, [invoicesToPay?.length]);

  useEffect(() => {
    getCreditNotesAndClaims();
  }, [marketplaceSupplier?.SupplierID]);

  useEffect(() => {
    if (considerCreditListFromContext) {
      setAvailableCredit(allCreditsTypesList?.reduce((a, c) => a + (c.Balance || 0), 0) || 0);
    }
  }, [allCreditsTypesList]);

  const getCreditNotesAndClaims = async () => {
    setLoadingCreditList(true)
    const linkedSupplierID = marketplaceSupplier?.SupplierID;
    const filter = {
      LinkedSupplierID: linkedSupplierID?.toString(),
      MaxResults: 1500,
      RequestedList: RequestedList.Credits,
      startRow: 1,
    };

    const result = await (search && search(filter));
    const CreditsClaimsAndPrepayments =
      result?.Value?.CreditResults?.map(cr => ({
        ...cr,
        Type:
          cr.TransType === TransTypes.Claim
            ? CreditType.Claim
            : cr.TransType === TransTypes.Prepayment
            ? CreditType.Prepayment
            : CreditType.CreditNote,
      })) || [];
    setCreditAndClaimsList && setCreditAndClaimsList(CreditsClaimsAndPrepayments);

    const newClaims = result?.Value?.CreditResults?.filter?.(a => a.TransType === TransTypes.Claim) ?? [];
    const prepaymentsAndCredits = result?.Value?.CreditResults?.filter?.(a => a.TransType !== TransTypes.Claim) ?? [];
    const creditsAndClaims = result?.Value?.CreditResults?.filter?.(a => a.TransType !== TransTypes.Prepayment) ?? [];
    const sortedCredits = isSingleInvoice ? [...creditsAndClaims] : [...newClaims, ...prepaymentsAndCredits];

    
     const CreditsAndClaims =
        sortedCredits?.map(cr => ({
          ...cr,
          Type:
            cr.TransType === TransTypes.Claim
              ? CreditType.Claim
              : cr.TransType === TransTypes.Prepayment
              ? CreditType.Prepayment
              : CreditType.CreditNote,
        })) || [];
    

    if (considerCreditListFromContext && setCreditAndClaimsList) {
      setCreditAndClaimsList(CreditsAndClaims);
    } else {
      setCreditList(CreditsAndClaims);
      setAvailableCredit(CreditsAndClaims.reduce((a, c) => a + (c.Balance || 0), 0));
    }
    setLoadingCreditList(false);
    return CreditsAndClaims;
  };

  const applyCredit = async (selectedCredits: Partial<SelectedCreditNotes>[], closeDialog: boolean = false) => {
    if (isLoadingApplyCredit.current) {
      return;
    }

    isLoadingApplyCredit.current = true;

    selectedCredits = selectedCredits.filter(credit =>
      (considerCreditListFromContext ? allCreditsTypesList! : creditList)?.find(
        creditClaim => creditClaim.ClaimCode === credit.ClaimCode || creditClaim.RefNumber === credit.RefNumber
      )
    );

    setCreditsToApply && setCreditsToApply(selectedCredits);

    const credits: Partial<SelectedCreditNotes>[] = selectedCredits.filter(
      credit => credit.TransType === TransTypes.CreditNote
    );
    const claims: Partial<SelectedCreditNotes>[] = selectedCredits.filter(
      credit => credit.TransType === TransTypes.Claim
    );

    const result: IAppliedCredit | undefined = await (getTotalPaymentAndCreditToApplyValues &&
      invoicesToPay &&
      getTotalPaymentAndCreditToApplyValues(claims, invoicesToPay, credits));

    if (result) {
      result.ClaimDetails = claims;
    }
    isLoadingApplyCredit.current = false;
    //TODO get loading check from useHttp hook

    setCreditStatementSummary && setCreditStatementSummary(result);
    if (closeDialog && handleDialog) {
      handleDialog();
    } else if (closeDialog) {
      setStep && setStep(PaymentWidgetPaymentSteps.NEW);
    }
  };

  const prepayments = allCreditsTypesList?.filter?.(a => a.TransType === TransTypes.Prepayment);
  const creditNotesAndClaims = allCreditsTypesList?.filter?.(a => a.TransType !== TransTypes.Prepayment);

  const selectedCreditNotes = psblBatch?.creditNotes || [];
  const selectedclaims = psblBatch?.claims || [];
  const selectedPrePayments = psblBatch?.payments || [];

  const selectedCreditsAndClaims = [...selectedclaims, ...selectedCreditNotes];
  const allCreditsTypesListSelected = [...selectedclaims, ...selectedPrePayments, ...selectedCreditNotes];

  const selectedCreditsAndClaimsLength = selectedCreditNotes.length + selectedclaims.length;
  const totalCreditsLength = selectedCreditsAndClaimsLength + selectedPrePayments.length;

  const isAllTypesCreditsSelected = allCreditsTypesList?.length === totalCreditsLength;
  const isAllPrepaymentsSelected = prepayments?.length === selectedPrePayments.length;
  const isAllCreditsAndClaimsSelected = creditNotesAndClaims?.length === selectedCreditsAndClaimsLength;

  const isAllSelected = {isAllTypesCreditsSelected, isAllPrepaymentsSelected, isAllCreditsAndClaimsSelected};

  const handleSelectAll = (type: FilterCredits) => {
    if (!psblBatch) {
      return;
    }

    let batchPayload: IBatchOperation[] = [];

    if (type === FilterCredits.PREPAYMENTS) {
      if (
        prepayments?.length === selectedPrePayments.length ||
        selectedListSum(allCreditsTypesListSelected) >= totalInvoiceAmount
      ) {
        if (selectedPrePayments.length === 0) {
          return;
        }
        batchPayload = [
          ...selectedPrePayments.map(cr => ({
            datTypeID: DatTypes.Payment,
            id: cr.id,
            action: PSBLBatchAction.REMOVE,
          })),
        ];
      } else {
        prepayments?.reduce(
          (selectedList: Partial<SelectedCreditNotes>[] | undefined, current: Partial<SelectedCreditNotes>) => {
            if (
              selectedListSum(selectedList) < totalInvoiceAmount &&
              !selectedPrePayments.some(a => a.id === current.ID)
            ) {
              batchPayload = [
                ...batchPayload,
                {
                  datTypeID: current.DatTypeID as DatTypes,
                  id: current.TransID!,
                  action: PSBLBatchAction.ADD,
                },
              ];
              return (selectedList = [...selectedList!, current]);
            }
          },
          []
        );
      }
    } else if (type === FilterCredits.CREDIT_AND_CLAIMS) {
      if (
        creditNotesAndClaims?.length === selectedCreditsAndClaimsLength ||
        selectedListSum(allCreditsTypesListSelected) >= totalInvoiceAmount
      ) {
        if (selectedCreditsAndClaimsLength === 0) {
          return;
        }
        batchPayload = [
          ...selectedCreditNotes.map(cr => ({
            datTypeID: DatTypes.CreditNote,
            id: cr.id,
            action: PSBLBatchAction.REMOVE,
          })),
          ...selectedclaims.map(cr => ({
            datTypeID: DatTypes.Claim,
            id: cr.id,
            action: PSBLBatchAction.REMOVE,
          })),
        ];
      } else {
        creditNotesAndClaims?.reduce((selectedList: any, current: any) => {
          if (
            selectedListSum(selectedList) < totalInvoiceAmount &&
            !selectedCreditsAndClaims.some(a => a.id === current.id)
          ) {
            batchPayload = [
              ...batchPayload,
              {
                datTypeID: current.DatTypeID as DatTypes,
                id: current.TransID!,
                action: PSBLBatchAction.ADD,
              },
            ];
            return (selectedList = [...selectedList!, current]);
          }
        }, [] as any);
      }
    } else if (type === FilterCredits.ALL) {
      if (
        allCreditsTypesList?.length === totalCreditsLength ||
        selectedListSum(allCreditsTypesListSelected) >= totalInvoiceAmount
      ) {
        if (totalCreditsLength === 0) {
          return;
        }
        batchPayload = [
          ...selectedPrePayments.map(cr => ({
            datTypeID: DatTypes.Payment,
            id: cr.id,
            action: PSBLBatchAction.REMOVE,
          })),
          ...selectedCreditNotes.map(cr => ({
            datTypeID: DatTypes.CreditNote,
            id: cr.id,
            action: PSBLBatchAction.REMOVE,
          })),
          ...selectedclaims.map(cr => ({
            datTypeID: DatTypes.Claim,
            id: cr.id,
            action: PSBLBatchAction.REMOVE,
          })),
        ];
      } else {
        allCreditsTypesList?.reduce((selectedList: any, current: any) => {
          if (
            selectedListSum(selectedList) < totalInvoiceAmount &&
            !allCreditsTypesListSelected?.some(a => a.id === current.id)
          ) {
            batchPayload = [
              ...batchPayload,
              {
                datTypeID: current.DatTypeID as DatTypes,
                id: current.TransID!,
                action: PSBLBatchAction.ADD,
              },
            ];
            return (selectedList = [...selectedList!, current]);
          }
        }, [] as any);
      }
    }
    saveBatch(batchPayload);
  };

  const handleCreditsToApply = (credit: Partial<SelectedCreditNotes>) => {
    if (isSingleInvoice) {
      let _selectedCredits: Partial<SelectedCreditNotes>[] = [...selectedCredits];
      const crIndex = _selectedCredits.findIndex(si => si.RefNumber === credit.RefNumber);
      if (credit.Balance! >= totalInvoiceAmount && crIndex === -1) {
        _selectedCredits = [credit];
      } else {
        if (crIndex >= 0) {
          _selectedCredits.splice(crIndex, 1);
        } else {
          _selectedCredits.push(credit);
        }
      }

      setSelectedCredits(_selectedCredits);
    } else {
      if (!psblBatch) {
        return;
      }

      const isClaimSelected = credit.TransType === TransTypes.Claim;
      const isPrepaymentSelected = credit.TransType === TransTypes.Prepayment;

      const {creditNotes, claims, payments} = psblBatch;
      let batchPayload: IBatchOperation[] = [];

      let index;
      if (isClaimSelected) {
        index = claims.findIndex(si => si.id === credit.TransID);
      } else if (isPrepaymentSelected) {
        index = payments.findIndex(si => si.id === credit.TransID);
      } else {
        index = creditNotes.findIndex(si => si.id === credit.TransID);
      }

      batchPayload.push({
        datTypeID: credit.DatTypeID as DatTypes,
        id: credit.TransID!,
        action: index > -1 ? PSBLBatchAction.REMOVE : PSBLBatchAction.ADD,
      });

      saveBatch(batchPayload);
    }
  };

  const handleDeleteClaim = async (claimCode: String) => {
    if (isSingleInvoice) {
      const claim = allCreditsTypesList?.find(cr => cr.RefNumber === claimCode);

      if (!claim) return;
      const batchPayload = [
        {
          datTypeID: DatTypes.Claim,
          id: claim.TransID!,
          action: PSBLBatchAction.REMOVE,
        },
      ];

      saveBatch(batchPayload);
    } else {
      if (!creditsToApply) return;
      const updatedCreditsAndClaimsToApply = creditsToApply.filter(credit => credit.ClaimCode !== claimCode);
      setSelectedCredits(updatedCreditsAndClaimsToApply);
      applyCredit(updatedCreditsAndClaimsToApply);
    }
    await getCreditNotesAndClaims();
    handleClaimDialog();
  };

  const handleAddClaim = async (claimCode: string) => {
    const creditsAndClaims = await getCreditNotesAndClaims();
    const claim = creditsAndClaims.find(claim =>
      !isSingleInvoice ? claim.RefNumber === claimCode : claim.RefNumber === claimCode
    );

    if (claim) {
      handleCreditsToApply(claim);
    }
    handleClaimDialog();
  };

  const handleClaimDialog = () => {
    if (showClaimDialog) {
      setClaimCode('');
      setClaimId(0);
    }
    setShowClaimDialog(prevState => !prevState);
  };

  const handleEditClaim = async (claimCode: string) => {
    const creditsAndClaims = await getCreditNotesAndClaims();
    if (!isSingleInvoice) {
      getBatchDetail();
    } else {
      if (!creditsToApply) return;
      const claim = creditsAndClaims.find(claim => claim.RefNumber === claimCode);

      const isClaimSelectedInSelectedCredits = selectedCredits.findIndex(claim => claim.ClaimCode === claimCode);
      if (isClaimSelectedInSelectedCredits > -1 && claim) {
        const _selectedCredits = selectedCredits;
        _selectedCredits[isClaimSelectedInSelectedCredits] = claim;
        setSelectedCredits(_selectedCredits);
      }

      const isClaimSelectedInContextCredits = creditsToApply.findIndex(claim => claim.ClaimCode === claimCode);
      if (isClaimSelectedInSelectedCredits > -1 && claim) {
        const _creditsToApply = creditsToApply;
        _creditsToApply[isClaimSelectedInContextCredits] = claim;
        applyCredit(_creditsToApply);
      }
    }
    handleClaimDialog();
  };

  const editClaim = (credit: Partial<SelectedCreditNotes>) => {
    setClaimCode(!isSingleInvoice ? credit.RefNumber! : credit.ClaimCode!);
    setClaimId(!isSingleInvoice ? credit.TransID! : credit.ID!);
    handleClaimDialog();
  };

  const selectedCreditAndClaimsIds = useMemo(() => {
    if (!psblBatch) return;

    const {claims, creditNotes, payments} = psblBatch;
    return [...claims, ...creditNotes, ...payments].map(c => c.id);
  }, [psblBatch?.claims, psblBatch?.creditNotes]);

  return {
    applyCredit,
    availableCredit,
    claimCode,
    claimId,
    creditList: considerCreditListFromContext ? allCreditsTypesList! : creditList,
    editClaim,
    getCreditNotesAndClaims,
    handleAddClaim,
    handleClaimDialog,
    handleCreditsToApply,
    handleDeleteClaim,
    handleEditClaim,
    isAutoApprovedClaimEnabled,
    isLoadingApplyCredit,
    isSelectedCreditGreaterThanInvoiceAmount,
    selectedCredits,
    showClaimDialog,
    totalCreditSelected,
    totalInvoiceAmount,
    selectedCreditAndClaimsIds,
    handleSelectAll,
    isAllSelected,
    loadingCreditList
  };
};
