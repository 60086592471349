import React from 'react';
import LoadingOverlay from 'react-loading-overlay';

interface ILoadingIndicatorProps {
  isLoading?: boolean;
  children?: any;
  overlayBgColor?: string;
  isAPOnboardingV1?: boolean;
  className?: string;
}

const LoadingIndicator: React.FC<ILoadingIndicatorProps> = (props: ILoadingIndicatorProps) => {
  const {overlayBgColor = 'rgba(0, 0, 0, 0.7)', className} = props;
  return (
    // @ts-ignore
    <LoadingOverlay
      active={props.isLoading}
      spinner
      className={`h-full w-full max-w-full ${props.isAPOnboardingV1 ? '' : 'flex h-full items-center justify-center'} ${className}`}
      styles={{
        overlay: base => ({
          ...base,
          background: overlayBgColor,
        }),
        wrapper: base => base,
        // content: base => base,
        spinner: base => base,
      }}
    >
      {props.children}
    </LoadingOverlay>
  );
};

export default LoadingIndicator;
