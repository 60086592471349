import React from 'react';
import {Box} from '@material-ui/core';
import KYCIcon from '../../../assets/svg/accounts-payable/kyc.svg';
import {APFooter} from '../APFooter';

export interface IAMLKYCProps {
  isLoading?: boolean;
  onNextClick: () => void;
  isAPOnboardingV1?: boolean;
}

export const AMLKYC = (props: IAMLKYCProps) => {
  return (
    <div className={`flex  h-full max-w-full relative wrapper`}>
      <div className={`max-w-[500px] mx-auto text-left`}>
        <Box>
          <h2 className={`text-[24px] text-[#121212] font-light w-full mb-1`}>Request AML/KYC checks</h2>

          <p className="text-sm font-normal text-spenda-labeltext">
            To process payments, we require AML (Anti-Money Laundering) and KYC (Know-Your Customer) checks on your
            business. This helps establish customer identities, monitor financial behaviour, detect and potential money
            laundering or terrorist financing activities.
          </p>
        </Box>
        <div className={`relative py-10 px-16 w-full h-[276px] bg-[#FAFAFA] mt-5`}>
          <Box className="flex flex-col items-center justify-center text-center">
            <img src={KYCIcon} data-autoid="imgKyc" className="mb-10" />
            <p className="font-poppins text-[#707070] text-base">
              To kick the process off, please click ‘Next’ and one of our team members will reach out to you shortly.
              Please note, while your AML/KYC check is in progress, you will not be able to process any payments.
            </p>
          </Box>
        </div>
      </div>
      <APFooter
        onNextClick={() => props.onNextClick()}
        isDisabled={props.isLoading}
        isAPOnboardingV1={props.isAPOnboardingV1}
      />
    </div>
  );
};
