import React from 'react';
import {Typography} from 'spenda-ui-react';
import SelectInvoicePlaceholder from '../../assets/svg/SelectInvoicePlaceholder';

interface IWidgetPlaceHolderProps {
  placeholder: string;
}

export const WidgetPlaceHolder = (props: IWidgetPlaceHolderProps) => {
  // Props
  const {placeholder} = props;

  return (
    <div className="relative flex h-full w-full items-center justify-center rounded-[8px] bg-white">
      <div className="mx-5 rounded-[10px] bg-primary/5 px-7 py-8 text-center">
        <Typography variant="h2" data-autoid="txtPlaceholder" className="font-light text-black-800">
          {placeholder}
        </Typography>
        <SelectInvoicePlaceholder className="mx-auto mt-8" />
      </div>
    </div>
  );
};
