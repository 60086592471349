import * as React from 'react';

export const CatalogueImg = (props: SVGProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={108} height={104} fill="none" {...props}>
      <path
        fill="url(#a)"
        d="M31.605 40.528a2.133 2.133 0 004.254.07c.61.433.968 1.17.89 1.954l-1.787 17.832a2.133 2.133 0 01-2.607 1.865l-14.765-3.44a2.133 2.133 0 01-1.636-1.838l-2.143-18.878a2.135 2.135 0 012.018-2.374l-.218 2.217a2.133 2.133 0 004.246.417l.168-1.712 11.512 2.763.068 1.124z"
      />
      <path
        fill="url(#b)"
        d="M20.462 32.358l10.807 2.594-.117-1.964a5.392 5.392 0 00-3.755-4.817c-3.13-.991-6.372 1.018-6.935 4.187z"
      />
      <path
        fill="url(#c)"
        fillRule="evenodd"
        d="M51.867 9.095l-40.428-7.98C5.784-.003.666 4.168.666 9.501v60.285c0 4.01 2.934 7.556 7.148 8.388l44.052 8.696V9.095zm-16.26 26.898l-.196-3.26a9.659 9.659 0 00-6.725-8.63c-5.559-1.76-11.316 1.764-12.397 7.356-3.86-.23-7.175 3.092-6.718 7.115l2.143 18.879a6.4 6.4 0 004.907 5.51l14.766 3.441c3.76.877 7.435-1.752 7.82-5.595l1.786-17.831c.354-3.524-2.124-6.202-5.387-6.985z"
        clipRule="evenodd"
      />
      <path
        fill="url(#d)"
        d="M67.867 22.396c0-.994.686-1.856 1.655-2.08l21.333-4.914a2.133 2.133 0 012.612 2.079v14.257c0 .993-.686 1.855-1.654 2.078l-21.334 4.915a2.133 2.133 0 01-2.612-2.078V22.396z"
      />
      <path
        fill="url(#e)"
        fillRule="evenodd"
        d="M105.2 72.4c0 1.107-.067 2.198-.198 3.27 1.466-1.543 2.332-3.583 2.332-5.76V9.497c0-5.32-5.102-9.489-10.75-8.388l-40.45 7.882v48.933c4.75-7.337 13.008-12.192 22.4-12.192 14.727 0 26.666 11.94 26.666 26.667zM63.6 22.396v14.257c0 4.114 3.827 7.16 7.837 6.236l21.334-4.915a6.4 6.4 0 004.963-6.236V17.48c0-4.115-3.827-7.16-7.837-6.237L68.563 16.16a6.4 6.4 0 00-4.963 6.237z"
        clipRule="evenodd"
      />
      <path
        fill="url(#f)"
        fillRule="evenodd"
        d="M100.934 72.4c0 5.192-1.767 9.97-4.73 13.77l9.88 9.88a4.264 4.264 0 010 6.033 4.264 4.264 0 01-6.033 0L89.757 91.79a22.293 22.293 0 01-11.223 3.01c-12.371 0-22.4-10.029-22.4-22.4 0-12.371 10.029-22.4 22.4-22.4 12.371 0 22.4 10.029 22.4 22.4zm-8.534 0c0 7.658-6.208 13.867-13.866 13.867-7.659 0-13.867-6.209-13.867-13.867 0-7.658 6.208-13.867 13.867-13.867 7.658 0 13.866 6.209 13.866 13.867z"
        clipRule="evenodd"
      />
      <defs>
        <linearGradient id="a" x1={54.001} x2={54.001} y1={0.932} y2={103.333} gradientUnits="userSpaceOnUse">
          <stop stopColor="#7FC0A6" />
          <stop offset={1} stopColor="#3C9F78" />
        </linearGradient>
        <linearGradient id="b" x1={54} x2={54} y1={0.932} y2={103.333} gradientUnits="userSpaceOnUse">
          <stop stopColor="#7FC0A6" />
          <stop offset={1} stopColor="#3C9F78" />
        </linearGradient>
        <linearGradient id="c" x1={54} x2={54} y1={0.932} y2={103.333} gradientUnits="userSpaceOnUse">
          <stop stopColor="#7FC0A6" />
          <stop offset={1} stopColor="#3C9F78" />
        </linearGradient>
        <linearGradient id="d" x1={54.001} x2={54.001} y1={0.932} y2={103.333} gradientUnits="userSpaceOnUse">
          <stop stopColor="#7FC0A6" />
          <stop offset={1} stopColor="#3C9F78" />
        </linearGradient>
        <linearGradient id="e" x1={54.001} x2={54.001} y1={0.932} y2={103.333} gradientUnits="userSpaceOnUse">
          <stop stopColor="#7FC0A6" />
          <stop offset={1} stopColor="#3C9F78" />
        </linearGradient>
        <linearGradient id="f" x1={54.001} x2={54.001} y1={0.932} y2={103.333} gradientUnits="userSpaceOnUse">
          <stop stopColor="#7FC0A6" />
          <stop offset={1} stopColor="#3C9F78" />
        </linearGradient>
      </defs>
    </svg>
  );
};
