import React, {useEffect, useRef, useState} from 'react';
import {Form, Formik, FormikHelpers, FormikProps} from 'formik';
import {useHistory, useParams} from 'react-router-dom';
import {Button} from 'spenda-ui-react';
import * as Yup from 'yup';

import {AddCustomerClass} from '../../components/form/CustomerClassesForm';
import {Layout} from '../../components/layout/Layout';
import {useFeatureFlags} from '../../hooks/useFeatureFlags';
import {ICreateCustomerClassValues} from '../../model/customer/CustomerClasses';
import {AUTH_CUSTOMER_CUSTOMERS_CLASSES_LIST} from '../../routes/CustomerRoutes';
import {useAutomatedStatementsAPI} from '../../services/useAutomatedStatementsAPI';
import {useCustomerClassesAPI} from '../../services/useCustomerClassesAPI';
import {Toast} from '../../utils/Toast';
import LoadingIndicator from '../../components/ui/LoadingIndicator';
import {AlertDialog} from '../../components/dialog/AlertDialogSlideV2';

const validationSchema = Yup.object({
  Description: Yup.string()
    .trim()

    .max(255, 'Description cannot be longer than 256 characters.'),
  BatchInvoicePrefix: Yup.string()
    .trim()

    .max(4, 'Invoice Prefix cannot be longer than 4 characters.'),
  AccountCustomerRefNumberPrefix: Yup.string()
    .trim()

    .max(10, 'Acc Cus Ref Prefix cannot be longer than 10 characters.'),
  Name: Yup.string()
    .required('Class Name is required')
    .matches(/^^(?=.*\S).+$/, 'Class Name is required')
    .max(50, 'Class Name cannot be longer than 50 characters.'),
  PostingBehaviour: Yup.string(),
  GenericAccountCustomerID: Yup.number()
    .typeError('Please select from dropdown')
    .when(['PostingBehaviour'], (PostingBehaviour: string, schema: any) => {
      return PostingBehaviour === 'Generic'
        ? schema.required(' Generic Debtor Account is required')
        : schema.nullable();
    }),
  TradingTermType: Yup.string().when(['PostingBehaviour'], (PostingBehaviour: string, schema: any) => {
    return PostingBehaviour === 'Account' ? schema.required('Trading Term Type is required') : schema.nullable();
  }),
  CreditLimit: Yup.number().when(['PostingBehaviour', 'TradingTermType'], {
    is: (PostingBehaviour: string, TradingTermType: string) =>
      PostingBehaviour === 'Account' &&
      TradingTermType !== '' &&
      TradingTermType !== undefined &&
      TradingTermType !== 'PrePaid' &&
      TradingTermType !== 'Cash_On_Delivery',
    then: schema =>
      schema
        .min(0, 'Credit Limit must be a equal to or greater than 0.')
        .test('maxDigitsAfterDecimal', 'Only 2 digits after decimal or less is allow', number =>
          /^\d+(\.\d{1,2})?$/.test(number?.toString() || '0'),
        )
        .required('Credit Limit is required'),
    otherwise: schema => schema.nullable(),
  }),
  TradingTermDays: Yup.number().when(['PostingBehaviour', 'TradingTermType'], {
    is: (PostingBehaviour: string, TradingTermType: string) =>
      PostingBehaviour === 'Account' &&
      TradingTermType !== '' &&
      TradingTermType !== undefined &&
      TradingTermType !== 'PrePaid' &&
      TradingTermType !== 'Cash_On_Delivery',
    then: schema =>
      schema
        .required('Trading Term Days is required.')
        .min(1, 'Trading Term Days must be a equal to or greater than 1.'),
    otherwise: schema => schema.nullable(),
  }),
  LoyaltyDescription: Yup.string().max(50, 'Loyalty Description cannot be longer than 50 characters.'),
  automatedScheduledDate: Yup.string().when(['isSendAutomatedStatementEmail'], {
    is: (isSendAutomatedStatementEmail: boolean) => isSendAutomatedStatementEmail === true,
    then: schema => schema.required('Date is required'),
    otherwise: schema => schema.nullable(),
  }),
  alertID: Yup.string().when(['isSendAutomatedStatementEmail'], {
    is: (isSendAutomatedStatementEmail: boolean) => isSendAutomatedStatementEmail === true,
    then: schema => schema.required('Please select a template'),
    otherwise: schema => schema.nullable(),
  }),
});

export interface IAddCustomerClassesFormValues extends ICreateCustomerClassValues {
  automatedScheduledDate?: number;
  isSendAutomatedStatementEmail?: boolean;
  alertName?: string;
  alertID?: number;
  automatedStatementID?: number;
}

export const AddCustomerClasses = () => {
  const {classID} = useParams<{classID?: string | undefined}>();
  const history = useHistory();
  const [postingBehaviour, setPostingBehaviour] = useState<string>();
  const {create, getClass, updateClass, getCustomerClass, deleteClass} = useCustomerClassesAPI();
  const {createAndUpdateAutomatedCustomerStatements} = useAutomatedStatementsAPI();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showDelete, setShowDelete] = useState(false);
  const handleOpenDelete = () => setShowDelete(true);
  const handleCloseDelete = () => setShowDelete(false);
  const [showSaveChangesDialog, setShowSaveChangesDialog] = useState(false);
  const {statementsAndAutomatedStatement83193} = useFeatureFlags().tenantOwned();

  const formRef = useRef<FormikProps<IAddCustomerClassesFormValues>>(null);

  const newClass: IAddCustomerClassesFormValues = {
    IsActive: true,
    Description: '',
    IsApprovedForPOS: false,
    IsApprovedForService: false,
    IsApprovedForWeb: false,
    IsAutoSendPayByLinkInvoice: false,
    IsAutoApprovedforClaimRequests: false,
    IsLoyaltyEligible: false,
    IsBatchInvoice: false,
    Name: '',
    PostingBehaviour: 'None',
    AccountCustomerRefNumberPrefix: '',
    LoyaltyDescription: '',
    GenericAccountCustomerID: '',
    FundingRate: '',
    CreditLimit: 0,
    TradingTermDays: undefined,
    TradingTermID: undefined,
    BatchInvoicePrefix: '',
    TradingTermType: '',
    automatedScheduledDate: 1,
    isSendAutomatedStatementEmail: false,
    alertID: undefined,
    alertName: '',
    automatedStatementID: undefined,
  } as IAddCustomerClassesFormValues;

  const [classToEdit, setClassToEdit] = React.useState<IAddCustomerClassesFormValues>(newClass);

  useEffect(() => {
    fetchClassToEdit();
  }, [classID]);

  const fetchClassToEdit = async () => {
    try {
      // Fetching data for the automated email statement
      if (classID) {
        if (statementsAndAutomatedStatement83193) {
          const res = await getCustomerClass(parseInt(classID));
          if (res) {
            setPostingBehaviour(res.postingBehaviour);

            const _class: IAddCustomerClassesFormValues = {
              IsActive: res?.isActive,
              Description: res?.description,
              IsApprovedForPOS: res?.isApprovedForPOS,
              IsApprovedForService: res?.isApprovedForService,
              IsApprovedForWeb: res?.isApprovedForWeb,
              IsAutoSendPayByLinkInvoice: res?.isAutoSendPayByLinkInvoice,
              IsAutoApprovedforClaimRequests: res?.isAutoApprovedforClaimRequests,
              IsLoyaltyEligible: res?.isLoyaltyEligible,
              IsBatchInvoice: res?.isBatchInvoice,
              Name: res?.name,
              PostingBehaviour: res?.postingBehaviour,
              AccountCustomerRefNumberPrefix: res?.accountCustomerRefNumberPrefix || undefined,
              LoyaltyDescription: res?.loyaltyDescription || '',
              GenericAccountCustomerID: String(res?.genericAccountCustomerID || ''),
              FundingRate: res?.fundingRate || undefined,
              CreditLimit: res?.creditLimit || undefined,
              TradingTermDays: res?.tradingTermDays || undefined,
              TradingTermID: res?.tradingTermID || undefined,
              BatchInvoicePrefix: res?.batchInvoicePrefix,
              TradingTermType: res?.tradingTermType,
              automatedScheduledDate: res?.automatedCustomerStatements?.scheduledDay || undefined,
              isSendAutomatedStatementEmail: res?.automatedCustomerStatements?.scheduledDay ? true : false,
              alertID: res?.automatedCustomerStatements?.alertID || undefined,
              automatedStatementID: res?.automatedCustomerStatements?.automatedCustomerStatementID || undefined,
              alertName: res?.alertName,
            };
            setClassToEdit(_class);
            setIsLoading(false);
          } else {
            history.push(AUTH_CUSTOMER_CUSTOMERS_CLASSES_LIST);
            setIsLoading(false);
          }
        } else {
          const classResponse = await getClass(parseInt(classID));
          if (classResponse) {
            setPostingBehaviour(classResponse.PostingBehaviour);
            const _class = {
              IsActive: classResponse?.IsActive,
              Description: classResponse?.Description,
              IsApprovedForPOS: classResponse?.IsApprovedForPOS,
              IsApprovedForService: classResponse?.IsApprovedForService,
              IsApprovedForWeb: classResponse?.IsApprovedForWeb,
              IsAutoSendPayByLinkInvoice: classResponse?.IsAutoSendPayByLinkInvoice,
              IsAutoApprovedforClaimRequests: classResponse?.IsAutoApprovedforClaimRequests,
              IsLoyaltyEligible: classResponse?.IsLoyaltyEligible,
              IsBatchInvoice: classResponse?.IsBatchInvoice,
              Name: classResponse?.Name,
              PostingBehaviour: classResponse?.PostingBehaviour,
              AccountCustomerRefNumberPrefix: classResponse?.AccountCustomerRefNumberPrefix,
              LoyaltyDescription: classResponse?.LoyaltyDescription,
              GenericAccountCustomerID: classResponse?.GenericAccountCustomerID,
              FundingRate: classResponse?.FundingRate,
              CreditLimit: classResponse?.CreditLimit,
              TradingTermDays: classResponse?.TradingTermDays,
              TradingTermID: classResponse?.TradingTermID,
              BatchInvoicePrefix: classResponse?.BatchInvoicePrefix,
              TradingTermType: classResponse?.TradingTermType,
            } as IAddCustomerClassesFormValues;
            setClassToEdit(_class);
            setIsLoading(false);
          } else {
            history.push(AUTH_CUSTOMER_CUSTOMERS_CLASSES_LIST);
            setIsLoading(false);
          }
        }
      } else {
        setIsLoading(false);
      }
    } catch {}
  };

  const onSubmit = async (
    values: IAddCustomerClassesFormValues,
    helpers: FormikHelpers<IAddCustomerClassesFormValues>,
  ) => {
    try {
      // Deleting Automated Email form values
      const isSendAutomatedStatementEmail = values?.isSendAutomatedStatementEmail;
      const automatedEmailPayload = {
        automatedCustomerStatementID: values?.automatedStatementID,
        scheduledDay: values?.automatedScheduledDate || 0,
        alertID: values?.alertID || 0,
        customerClassID: 0,
      };
      delete values?.alertName;
      delete values?.alertID;
      delete values?.isSendAutomatedStatementEmail;
      delete values?.automatedScheduledDate;
      delete values?.automatedStatementID;

      if (values?.TradingTermType === 'PrePaid' || values?.TradingTermType === 'Cash_On_Delivery') {
        delete values?.TradingTermDays;
        delete values?.CreditLimit;
      }
      if (values?.PostingBehaviour === 'Generic') {
        delete values?.TradingTermDays;
        delete values?.TradingTermID;
        delete values?.IsBatchInvoice;
        delete values?.CreditLimit;
        values.TradingTermType = '';
        values.IsAutoSendPayByLinkInvoice = false;
      }
      if (values?.PostingBehaviour === 'Account') {
        delete values?.GenericAccountCustomerID;
      }
      if (values?.PostingBehaviour === 'None') {
        delete values?.GenericAccountCustomerID;
        delete values?.TradingTermDays;
        delete values?.TradingTermID;
        delete values?.IsBatchInvoice;
        delete values?.CreditLimit;
        values.TradingTermType = '';
        values.IsAutoSendPayByLinkInvoice = false;
      }

      if (classID) {
        await updateClass(parseInt(classID), values, 13);
        helpers.setSubmitting(false);
        Toast.info(`Customer '${values?.Name}' updated`);
        automatedEmailPayload.customerClassID = Number(classID);
      } else {
        const response = await create(values, 13);
        helpers.setSubmitting(false);
        Toast.info(`Customer '${values?.Name}' created`);
        automatedEmailPayload.customerClassID = response?.Object?.ID;
      }
      if (statementsAndAutomatedStatement83193 && isSendAutomatedStatementEmail) {
        await createAndUpdateAutomatedCustomerStatements(automatedEmailPayload);
      }
      history.push(AUTH_CUSTOMER_CUSTOMERS_CLASSES_LIST);
    } catch {}
  };

  const handleDelete = () => {
    if (classID) {
      deleteClass(parseInt(classID)).then(data => {
        if (data.IsSuccess) {
          history.push(AUTH_CUSTOMER_CUSTOMERS_CLASSES_LIST);
          Toast.info('Customer Class has been deleted');
        } else handleCloseDelete();
      });
    }
  };

  const _addProduct = (
    <div
      className={`flex h-full max-h-[calc(100vh-20.5em)] min-h-full w-full flex-col overflow-y-auto rounded-lg bg-white p-4 px-5`}
      id="sales-orders-list"
    >
      {isLoading ? (
        <LoadingIndicator size={'md'} isLoading />
      ) : (
        <Formik
          innerRef={formRef}
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={classToEdit}
          onSubmit={onSubmit}
        >
          {props => (
            <Form onSubmit={props.handleSubmit} className="flex h-full flex-col px-5 py-4">
              <div className={`flex w-full justify-between px-2 py-4`}>
                <p className={`text-2xl font-medium text-spenda-primarytext`}>
                  {classID ? 'Edit Customer Class' : 'Create New Customer Class'}
                </p>
              </div>
              <AddCustomerClass {...props} classId={classID} postingBehaviour={postingBehaviour} />
              <div
                className={`sticky bottom-0 mr-3 flex w-[calc(100%-14px)] justify-end rounded-lg bg-spenda-footerBg p-2.5`}
              >
                <div className="flex gap-x-2">
                  {classID ? (
                    <Button color="error" onClick={handleOpenDelete}>
                      Delete
                    </Button>
                  ) : null}
                  <Button
                    variant="outlined"
                    className="bg-white"
                    type="button"
                    onClick={() => {
                      if (props.dirty) {
                        setShowSaveChangesDialog(true);
                      } else {
                        history.push(AUTH_CUSTOMER_CUSTOMERS_CLASSES_LIST);
                      }
                    }}
                  >
                    Cancel
                  </Button>

                  <Button type="submit" loading={props.isSubmitting} disabled={props.isSubmitting || !props.dirty}>
                    Save & Close
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );

  return (
    <div className={`relative h-full overflow-hidden bg-spenda-newbg`}>
      <Layout leftPanel={_addProduct} splitWidthType={4} />
      {showDelete && (
        <AlertDialog
          size="sm"
          title="Delete Customer Class"
          headingTextSize="h2"
          content="Are you sure you want to delete this Customer Class?"
          actions={[
            {
              label: 'Cancel',
              action: () => handleCloseDelete(),
              variant: 'outlined',
            },
            {
              label: 'Ok',
              action: () => handleDelete(),
              color: 'error',
            },
          ]}
        />
      )}
      {showSaveChangesDialog && (
        <AlertDialog
          title="Discard Changes"
          size="sm"
          headingTextSize="h2"
          content="You have unsaved changes, would you like to discard changes or return to the page?"
          actions={[
            {
              label: 'Cancel',
              action: () => setShowSaveChangesDialog(false),
              variant: 'outlined',
            },
            {
              label: 'Discard',
              action: () => history.push(AUTH_CUSTOMER_CUSTOMERS_CLASSES_LIST),
            },
          ]}
        />
      )}
    </div>
  );
};
