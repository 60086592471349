import React, {useState} from 'react';
import {ARDialogTemplate} from '../ARDialogTemplate';
import {Button, Input, Typography} from 'spenda-ui-react';
interface IRejectClaimModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (reason: string) => void;
  isSubmitting: boolean;
}

export const RejectClaimModal = (props: IRejectClaimModalProps) => {
  //Props
  const {isOpen, onClose, onConfirm} = props;

  // State
  const [rejectReason, setRejectReason] = useState('');
  const [error, setError] = useState('');

  const handleReject = () => {
    if (!rejectReason) {
      setError('Please Enter Reject Reason');
      return;
    }
    onConfirm(rejectReason);
  };

  return (
    <ARDialogTemplate
      dialogProps={{
        size: 'sm',
        open: isOpen,
        className: '!min-w-[460px] !max-w-[460px]',
      }}
      isFullScreen
      header={<p className=" font-poppins text-xl font-light text-[#333333]">Reject claim</p>}
      body={
        <div className="mb-12 mt-[14px] flex w-full flex-col items-start">
          <Typography className="mb-12 text-center font-light text-black-800" variant="paragraph">
            Please specify the reason to reject this claim
          </Typography>
          <div className="h-16 w-full font-poppins text-xs">
            <Input
              maxLength={40}
              displayLength
              label="Reject reason"
              data-autoid={`txtRejectReason`}
              value={rejectReason}
              onChange={e => setRejectReason(e?.target?.value)}
              error={Boolean(error)}
              helperText={error}
              placeholder="Reject reason"
            />
          </div>
        </div>
      }
      footer={
        <div className="flex w-full flex-row items-center justify-between">
          <Button
            data-autoid={`btnCancel`}
            variant="outlined"
            color="primary"
            onClick={() => onClose()}
            className="bg-white"
          >
            Cancel
          </Button>
          <Button data-autoid={`btnRejectClaim`} variant="filled" color="error" onClick={handleReject}>
            Reject Claim
          </Button>
        </div>
      }
      handleClose={() => onClose()}
    />
  );
};
