import * as React from 'react';
import {SVGProps} from 'react';

export const CommentCheck = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <title>{'Icons/Comment/comment_check'}</title>
    <g id="Spenda-Pay-Onboarding" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <g id="Spenda-Collect-Supplier-Interface-Copy-23" transform="translate(-1259.000000, -279.000000)" fill="#8178CF">
        <g id="Icons/Comment/comment_check" transform="translate(1259.000000, 279.000000)">
          <path
            d="M10.5015536,17.0013124 C9.97047101,17.0017153 9.46102901,16.7908869 9.08555364,16.4152978 L5.30155364,12.7162978 C4.90667005,12.3296985 4.89995433,11.6961814 5.28655365,11.3012978 C5.67315297,10.9064142 6.30667004,10.8996985 6.70155364,11.2862978 L10.4945536,14.9932978 L17.3005536,8.2932978 C17.69906,7.94265769 18.3020899,7.96446855 18.6742236,8.34298178 C19.0463574,8.72149501 19.0579161,9.32480843 18.7005536,9.7172978 L11.9075536,16.4242978 C11.5341129,16.7957211 11.0282501,17.0033191 10.5015536,17.0013124 Z M24.0015536,19.0012978 L24.0015536,12.3412978 C24.0789312,5.94686638 19.2090255,0.576185707 12.8375536,0.0292978011 C9.36883378,-0.2133866 5.96539602,1.06047335 3.50846818,3.5210462 C1.05154034,5.98161905 -0.217272747,9.38694153 0.0305536365,12.8552978 C0.472553637,19.2092978 6.08355364,24.0012978 13.0845536,24.0012978 L19.0015536,24.0012978 C21.7616068,23.9979917 23.9982475,21.761351 24.0015536,19.0012978 Z M12.7015536,2.0252978 C18.0255496,2.49627412 22.0831289,6.99713302 22.0015536,12.3412978 L22.0015536,19.0012978 C22.0015536,20.6581521 20.6584079,22.0012978 19.0015536,22.0012978 L13.0845536,22.0012978 C7.05055364,22.0012978 2.40155364,18.1012978 2.02655364,12.7172978 C1.82754103,9.94497996 2.79133066,7.2149047 4.68672634,5.18196904 C6.58212203,3.14903338 9.23810571,1.99666536 12.0175536,2.00128389 C12.2445536,2.00128389 12.4735536,2.0102978 12.7015536,2.0252978 Z"
            id="Shape"
          />
        </g>
      </g>
    </g>
  </svg>
);
