import * as React from 'react';
import {SVGProps} from 'react';

const ErrorIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 0a8 8 0 1 0 8 8 8.009 8.009 0 0 0-8-8Zm0 14.667A6.667 6.667 0 1 1 14.667 8 6.674 6.674 0 0 1 8 14.667Z"
      fill="#C68A19"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 3.333A.667.667 0 0 0 7.333 4v5.333a.667.667 0 1 0 1.334 0V4A.667.667 0 0 0 8 3.333Z"
      fill="#C68A19"
    />
    <rect x={7.333} y={11.333} width={1.333} height={1.333} rx={0.667} fill="#C68A19" />
  </svg>
);

export default ErrorIcon;
