import React, {useContext} from 'react';
import {ITenant} from '../model/Tenant';
import {useHistory} from 'react-router-dom';
import {IUser} from '../model/user/User';
import {TenantSetupComponent} from '../components/tenantSetup/TenantSetupComponent';
import {isWebsiteId17} from '../config';
import {AUTH_MENU} from '../routes/AuthenticatedRoutes';
import AppContext from '../context/app/appContext';

export const TenantSetupScreen = () => {
  const {user, tenant} = useContext(AppContext);

  return <TenantSetup tenant={tenant} user={user} />;
};

export const TenantSetup = (props: {tenant?: ITenant; user?: IUser}) => {
  const history = useHistory();

  const afterSubmitRedirect = () => {
    if (isWebsiteId17) {
      history.replace(`/supplier/module-info/psbl`);
    } else {
      history.push(AUTH_MENU + '?exploreProducts=true');
    }
  };

  return <TenantSetupComponent tenant={props.tenant} user={props.user} onAfterSubmit={afterSubmitRedirect} />;
};
