import DateFnsUtils from '@date-io/date-fns';
import {IconButtonProps, makeStyles} from '@material-ui/core';
import moment from 'moment';
import React, {useContext, useEffect, useState} from 'react';
import ARSelectTransactionsContext from '../../context/ar-context/ARContext';
import {ISendReminderAlert} from '../../model/alert/AlertInfo';
import {ARCustomerStatementOperation, ARStatementDeltasActions} from '../../model/constants/Constants';
import useAlertAPI from '../../services/useAlertAPI';
import {useCustomerStatementsAPI} from '../../services/useCustomerStatementsAPI';
import {Toast} from '../../utils/Toast';
import {PaymentReminderDialog} from '../dialog/PaymentReminderDialog';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {Button, Input} from 'spenda-ui-react';
import {ARDialogTemplate} from './ARDialogTemplate';

const useInputStyles = makeStyles({
  payInvoiceText: {
    minWidth: '170px',
    minHeight: '40px',
    '& .MuiFormControl-root': {
      background: '#fff',
      paddingBottom: '0px',
      height: '100%',
      '& .MuiOutlinedInput-root': {
        height: '42px',
        backgroundColor: 'transparent',
        borderRadius: '6px',
      },
      '& .MuiFormLabel-root': {
        color: '#333',
        fontFamily: 'Poppins, sans-serif !important',
        width: 'auto',
        fontWeight: 500,
        fontSize: '13px',
        top: '-7px',
        left: '-5px',
      },
      '& .MuiInputLabel-shrink': {
        top: '0px',
        left: 0,
        paddingRight: '2px',
        marginLeft: '-4px',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#333',
        '& legend': {
          height: '5px',
        },
      },
      '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#333!important',
      },
      '& .MuiFormLabel-root.Mui-focused': {
        color: '#333',
      },
      '& .MuiInputBase-input': {
        fontSize: '14px',
        fontWeight: 600,
        padding: '0px 8px',
        fontFamily: 'Poppins !important',
        color: '#333',
      },
      '& .MuiIconButton-root': {
        color: 'hsl(var(--primary))',
      },
      '& .MuiInputBase-adornedEnd': {
        paddingRight: '0px',
      },
      '& .MuiOutlinedInput-notchedOutline legend': {
        fontSize: '9px!important',
      },
      '& .MuiFormHelperText-root.Mui-error': {
        color: '#C55D44',
        fontFamily: 'poppins',
        marginLeft: '7px',
      },
    },
  },
  pickerDialog: {
    '& .MuiTypography-root': {
      fontFamily: 'poppins',
    },
    '& .MuiPickersToolbar-toolbar': {
      backgroundColor: 'hsl(var(--primary))',
    },
    '& .MuiPickersCalendarHeader-dayLabel.MuiPickersCalendarHeader-dayLabel': {
      color: '#333',
    },
    '& .MuiPickersCalendar-week': {
      '& .MuiPickersDay-day': {
        color: '#333',
      },
      '& .MuiPickersDay-dayDisabled': {
        color: '#33333350',
      },
      '& .MuiPickersDay-daySelected': {
        backgroundColor: 'hsl(var(--primary))',
        color: '#fff',
      },
    },
  },
  paymentDate: {
    '& legend': {
      width: '76px',
    },
  },
});

type DatePickerKeyboardProps = IconButtonProps & {
  [key: string]: string | undefined;
};

export interface IStatementDialogProps {
  customerId: number;
  handleClose: () => void;
}

export const StatementDialog = (props: IStatementDialogProps) => {
  const {handleClose, customerId} = props;
  const {createStatement, getStatementDetail} = useCustomerStatementsAPI();
  const {psblStatement, setPsblStatement} = useContext(ARSelectTransactionsContext);
  const {send: sendReminder} = useAlertAPI();
  const inputClasses = useInputStyles();

  const [endDate, setEndDate] = useState(moment().add(1, 'months').toString());
  const [statementName, setStatementName] = useState('');
  const [paymentReminderPayload, setPaymentReminderPayload] = useState<ISendReminderAlert>();

  useEffect(() => {
    getStatementDetails();
  }, []);

  const getStatementDetails = async () => {
    const {statementId, accountCustomerId} = psblStatement;
    if (accountCustomerId && statementId) {
      const statementDetail = await getStatementDetail(accountCustomerId, statementId);
      if (!statementDetail?.endDate || !statementDetail?.name) return;
      setEndDate(statementDetail?.endDate);
      setStatementName(statementDetail?.name);
    }
  };

  const handleSendPaymentReminderPayload = (paymentReminderPayload: ISendReminderAlert) => {
    setPaymentReminderPayload(paymentReminderPayload);
  };

  const handleSubmit = async () => {
    try {
      const {statementId, selectedTxList} = psblStatement;
      if (!statementId || !endDate || !statementName) return;

      const statementDeltas = selectedTxList?.map(st => {
        return {
          datTypeID: st.transactionTypeID.toString(),
          id: st.id,
          action: ARStatementDeltasActions.ADD,
        };
      });

      const payload = {
        statementID: statementId,
        accountCustomerID: customerId,
        statementDeltas: statementDeltas,
        name: statementName,
        endDate_string: moment(endDate).format('YYYY-MM-DD'),
        operation: ARCustomerStatementOperation.UPDATE,
        finaliseStatement: true,
      };

      if (!paymentReminderPayload) return;

      const responses = await Promise.all([createStatement(payload), sendReminder(paymentReminderPayload)]);
      if (responses?.length && responses[1]) Toast.info('Payment reminder has been sent.');
      if (responses?.length && !responses[0]) return;

      handleClose();
      setPsblStatement({
        ...psblStatement,
        statementId: undefined,
        selectedTxList: [],
      });
    } catch {}
  };

  const header = <p className="text-center font-poppins text-xl font-light text-black-800">Select Contact</p>;

  const body = (
    <div>
      <div className={`${inputClasses.payInvoiceText} w-full`}>
        <div className="font-poppins text-xs">
          <Input
            value={statementName}
            onChange={e => setStatementName(e?.target?.value)}
            id="batchName"
            variant="outlined"
            name="batchName"
            label="Batch Name"
            data-autoid="txtBatchName"
          />
        </div>
      </div>
      <div className="flex flex-row items-center justify-between gap-2.5">
        <div className={`${inputClasses.payInvoiceText} mb-3 mt-4 w-full`}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              autoOk
              minDate={new Date()}
              minDateMessage="Closing date should be greater than today."
              variant="inline"
              id="supplierCreateBatchEndDate"
              name="supplierCreateBatchEndDate"
              inputVariant="outlined"
              label="Closing Date"
              format="dd/MM/yyyy"
              value={endDate}
              InputAdornmentProps={{position: 'end'}}
              onChange={(date: any) => {
                setEndDate(date);
              }}
              PopoverProps={{className: inputClasses.pickerDialog}}
              className={`${inputClasses.paymentDate}`}
              inputProps={{
                'data-autoid': 'dtpBatchPaymentDate',
              }}
              KeyboardButtonProps={
                {
                  'aria-label': 'change date',
                  'data-autoid': 'dtpBatchPaymentDateIcon',
                } as DatePickerKeyboardProps
              }
              fullWidth
            />
          </MuiPickersUtilsProvider>
        </div>
      </div>
      <PaymentReminderDialog
        isUsingInCreateStatementModal={true}
        customerId={psblStatement?.accountCustomerId?.toString()}
        closeDialog={() => {}}
        handleSendPaymentReminderPayload={handleSendPaymentReminderPayload}
      />
    </div>
  );

  const footer = (
    <div className="flex w-full flex-row items-center justify-between">
      <Button data-autoid={`btnCancel`} className="bg-white" onClick={handleClose} variant="outlined" color="primary">
        Cancel
      </Button>
      <Button
        disabled={!endDate || !statementName}
        variant="filled"
        color="primary"
        data-autoid={`btnSave&Send`}
        onClick={handleSubmit}
      >
        Save & Send
      </Button>
    </div>
  );

  return <ARDialogTemplate containerClass="!w-[424px] !px-2.5" header={header} body={body} footer={footer} />;
};
