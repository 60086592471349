import * as React from 'react';
const APRemainingSteps = (props: React.SVGProps<SVGSVGElement>) => (
  <svg {...props} width="20" height="20" fill="none">
    <circle cx="10" cy="10" r="10" fill="#1C78AD" />
    <circle cx="10" cy="10" r="10" fill="#000" fillOpacity=".01" />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M15.846 9.992a1.667 1.667 0 0 0-.491-1.166L11.78 5.242a.833.833 0 1 0-1.175 1.184l2.741 2.733H5.013a.833.833 0 0 0 0 1.667h8.333l-2.741 2.741a.834.834 0 0 0 1.175 1.184l3.575-3.584c.313-.31.49-.733.491-1.175Z"
      clipRule="evenodd"
    />
  </svg>
);
export default APRemainingSteps;
