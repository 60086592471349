import React from 'react';
import MockARBackground from '../../assets/jpg/HomeBlurBgg.jpg';

import {PSBLWidget} from './PSBLWidget';

import {Box} from '@material-ui/core';
import {css} from 'glamor';
import {PaymentWidgetScope} from '../../model/constants/Constants';

const backdropFilterCSS = css({
  '@supports ((backdrop-filter: blur(0px))) or (-webkit-backdrop-filter: blur(0px))': {
    backdropFilter: 'blur(0px)',
    '& -webkit-backdrop-filter': 'blur(0px)',
  },
});

export const PrePaymentWidget = () => {
  return (
    <>
      <Box>
        <img alt={'background'} src={MockARBackground} className="absolute right-0 left-0 bottom-0 w-full h-full" />
      </Box>
      <Box className={`h-full z-10 overflow-y-auto ${backdropFilterCSS}`}>
        <PSBLWidget widgetScope={PaymentWidgetScope.PREPAYMENT} />
      </Box>
    </>
  );
};
