import React, {useContext, useEffect, useState} from 'react';
import {useFeatureFlags} from '../../../hooks/useFeatureFlags';
import {APFooter} from './../APFooter';
import {IconBank} from '../../../assets/svg/payment-methods/IconBank';
import AirPlusThumbnailIcon from '../../../assets/svg/AirPlusThumbnail.svg';
import CCIcon from '../../../assets/png/CC.png';
import {makeStyles} from '@material-ui/core';
import {PaymentWidgetScope} from '../../../model/constants/Constants';
import {ManagePaymentMethods} from '../../../screens/payment-methods/ManagePaymentMethods';
import {IAccountResponse} from '../../../services/useSpendaPaymentServicesAPI';
import {CommonPaymentMethods} from '../../payments/CommonPaymentMethods';
import {Spinner, Typography} from 'spenda-ui-react';
import {IntegrationContext} from '../../../context/IntegrationContext';
import WalletMockup from '../../../assets/png/AccountsPayable/Wallet-Mockups.png';
import RightArrowIcon from '../../../assets/svg/accounts-payable/RightArrowIcon';
import {Link} from 'react-router-dom';

const usePaymentMethodStyles = makeStyles({
  boxHeight: {
    '& svg': {
      margin: '0px auto 25px',
    },
  },
});
export interface IPaymentMethodProps {
  onNextClick: () => void;
  onSkipClick: () => void;
  isAPOnboardingV1?: boolean;
  accounts?: IAccountResponse[];
  isLoading?: boolean;
  loadPaymentMethods: () => Promise<IAccountResponse[]>;
}

export const PaymentMethod = (props: IPaymentMethodProps) => {
  const classes = usePaymentMethodStyles();
  const {accounts, loadPaymentMethods} = props;
  const {paymentAuth72488, airplus79131} = useFeatureFlags().tenantOwned();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string>('');
  const [openWidget, setOpenWidget] = useState<boolean>(false);
  const [widgetScope, setWidgetScope] = useState<PaymentWidgetScope>(PaymentWidgetScope.ADD_CREDIT_CARD);
  const {financialAdaptor} = useContext(IntegrationContext);
  const {onboardingUpgradeV1} = useFeatureFlags().tenantOwned();

  const handleAuthoriseClick = (selectedMethod: string) => {
    if (selectedMethod === 'card') {
      setWidgetScope(PaymentWidgetScope.ADD_CREDIT_CARD);
      setOpenWidget(true);
    } else if (selectedMethod === 'bank') {
      setWidgetScope(PaymentWidgetScope.ADD_BANK_ACCOUNT);
      setOpenWidget(true);
    } else if (selectedMethod === 'airPlus') {
      setWidgetScope(PaymentWidgetScope.ADD_AIRPLUS);
      setOpenWidget(true);
    }
  };

  useEffect(() => {
    loadPaymentMethods?.();
  }, []);

  return (
    <>
      {openWidget && (
        <ManagePaymentMethods
          widgetScope={widgetScope}
          widgetManageMethods73098={true}
          onClose={() => {
            loadPaymentMethods();
            setOpenWidget(value => !value);
            setSelectedPaymentMethod('');
          }}
          open={openWidget}
          isBPSP={true}
          onSuccess={() => {
            loadPaymentMethods();
            setOpenWidget(value => !value);
            props.onNextClick();
          }}
          paymentAuth72488={paymentAuth72488}
          onboardingUpgradeV1={onboardingUpgradeV1}
        />
      )}
      <div className={`wrapper relative h-full max-w-full`}>
        <div className="jsutify-center absolute right-3 top-[50px] flex h-[395px] w-[220px] flex-col items-center rounded-xl border border-primary/10 bg-[#F7FAFD] px-3 py-7">
          <img style={{width: '110px', height: '140px'}} src={WalletMockup} />
          <Typography className="mb-3 px-8 pt-5 text-center font-semibold text-[#212121]" variant="paragraph">
            Pair it with: Spenda Wallet
          </Typography>
          <Typography className="mb-3 text-center font-medium text-[#666666]" variant="small">
            Your go-to mobile payment authorisation app. Easily edit and refine batch invoices, and approve invoice
            payments on the go. 
          </Typography>
          <Link
            to={{pathname: 'https://spenda.co/spendawallet/'}}
            target="_blank"
            className="cursor-pointer text-primary"
          >
            <Typography
              className="mb-3 flex flex-row items-center justify-center px-8 pt-1 text-center font-semibold text-primary"
              variant="paragraph"
            >
              Download Now <RightArrowIcon className="ml-1" />
            </Typography>
          </Link>
        </div>
        <div className={`mx-auto max-w-[500px] text-left`}>
          <div>
            <h2 className={`mb-1 w-full text-[24px] font-light text-[#121212]`}>Set up payment method</h2>
            <p className="text-sm font-normal text-spenda-labeltext">
              {accounts?.length
                ? 'The following payment methods were already integrated into your XERO account. Click ‘Next’ to continue. If these are not the correct payment methods, please contact us by clicking on ‘Live Chat’.'
                : `The following payment methods were already integrated into your ${
                    financialAdaptor?.Name || ''
                  } account. Click
              ‘Next’ to verify your payment details.`}
            </p>
          </div>
          {accounts?.length ? (
            <div className="mt-5">
              <CommonPaymentMethods
                hideAddPaymentMethod
                loadPaymentMethods={loadPaymentMethods}
                paymentMethods={accounts}
                paymentAuth72488={paymentAuth72488}
                isAccountsPayable={true}
              />
            </div>
          ) : props.isLoading ? (
            <div className="flex h-full w-full items-center justify-center">
              <Spinner className="h-7 w-7 text-primary/20" />
            </div>
          ) : (
            <div className={`${classes.boxHeight} mt-5`}>
              <div className="flex h-full w-full flex-wrap items-center gap-x-5 gap-y-5">
                <div
                  className={`w-[45%] rounded-lg p-4 text-center  ${
                    selectedPaymentMethod === 'card' ? 'border-primary' : 'border-spenda-scream'
                  } cursor-pointer border-2`}
                  onClick={() => {
                    setSelectedPaymentMethod('card');
                    handleAuthoriseClick('card');
                  }}
                  data-autoid="lnkCard"
                >
                  <img
                    src={CCIcon}
                    width={'100px'}
                    alt="Bank Icon"
                    className="mx-auto mb-5"
                    data-autoid="imgCreditCardIcon"
                  />
                  <h3 className={`text-lg`}>Credit Card</h3>
                </div>
                <div
                  className={`w-[45%] rounded-lg  bg-spenda-cream p-4 text-center ${
                    selectedPaymentMethod === 'bank' ? 'border-primary' : 'border-spenda-scream'
                  } cursor-pointer border-2`}
                  onClick={() => {
                    setSelectedPaymentMethod('bank');
                    handleAuthoriseClick('bank');
                  }}
                  data-autoid="lnkBank"
                >
                  <IconBank width="100px" height="60px" />
                  <h3 className={`text-lg`}>Bank Account</h3>
                </div>
                {airplus79131 ? (
                  <div
                    className={`flex min-h-[144.2px] w-[45%] flex-col items-center rounded-lg p-4 text-center ${
                      selectedPaymentMethod === 'airPlus' ? 'border-primary' : 'border-spenda-scream'
                    } cursor-pointer border-2`}
                    onClick={() => {
                      setSelectedPaymentMethod('airPlus');
                      handleAuthoriseClick('airPlus');
                    }}
                    data-autoid="lnkAirPlus"
                  >
                    <img src={AirPlusThumbnailIcon} alt="AirPlus" className=" mb-4 mt-1 h-[60px]" />
                    <h3 className={`text-lg`}>AirPlus</h3>
                  </div>
                ) : null}
              </div>
            </div>
          )}
        </div>
        <APFooter
          isCancelButton={true}
          secondaryButtonLabel={'Skip'}
          handleCancelClick={() => props.onSkipClick()}
          buttonLabel={'Next'}
          secondaryButtonDisable={accounts?.length ? true : false}
          isDisabled={accounts?.length ? false : true}
          onNextClick={() => props.onNextClick()}
          isAPOnboardingV1={props.isAPOnboardingV1}
        />
      </div>
    </>
  );
};
