import React from 'react';
import AirPlusIcon from '../../assets/svg/AirPlus_Icon.svg';
import AirPlusLogo from '../../assets/svg/AirPlus_Logo.svg';

export const AirPlusCard = (props: {tenantName?: string}) => {
  return (
    <div className="flex flex-col  h-[150px] w-[270px]  rounded-[14.5px] shadow-md bg-contain bg-no-repeat bg-center bg-[#160F5B]">
      <div className="relative -right-[19px] flex justify-end w-full">
        <div className=" w-[90px] h-[35px] bg-[#8728D6] rounded-bl-md rounded-tr-[10px] "></div>
        <div className="relative top-3 right-[19px] w-[19px] h-[40px] t-[9.5px] bg-[#78EF48] rounded-l-md"></div>
      </div>
      <div className="px-4 -mt-4">
        <img src={AirPlusIcon} className="!w-[90px]" />
        <div className="flex flex-row justify-between items-end px-1">
          <span className="text-xs font-poppins font-medium text-white">{props?.tenantName}</span>
          <img src={AirPlusLogo} className="!w-[80px]" />
        </div>
      </div>
    </div>
  );
};
