import React, {useState, useRef} from 'react';
import {Formik, FormikProps, FormikHelpers} from 'formik';
import * as Yup from 'yup';
import {cloneDeep} from 'lodash';

import {IBrand} from '../../model/inventory/Brand';
import {BrandForm} from '../form/BrandForm';
import useBrandAPI from '../../services/useBrandAPI';
import {Toast} from '../../utils/Toast';

import {DatTypes} from '../../model/constants/Constants';
import {Button, IconButton, Typography} from 'spenda-ui-react';
import {AlertDialog, AlertDialogSlideV2} from './AlertDialogSlideV2';
import {CloseCross} from '../../assets/svg/CloseCross';

interface IBrandDialogProps {
  brand: IBrand;
  open?: boolean;
  onClose: (refetchList?: boolean, newBrand?: IBrand) => void;
}

const validationSchema = Yup.object({
  Name: Yup.string().required('Brand Name is required.').max(50, 'Brand Name cannot be longer than 50 characters.'),
  Description: Yup.string().max(255, 'Brand Description cannot be longer than 255 characters.'),
});

export const BrandDialog: React.FunctionComponent<IBrandDialogProps> = ({brand, open, onClose}: IBrandDialogProps) => {
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState<boolean>(false);
  const [openActivateDialog, setOpenActivateDialog] = useState<boolean>(false);

  const {isLoading, updateBrand, createBrand, deleteBrand, dashboard} = useBrandAPI();

  const formRef = useRef<FormikProps<IBrand>>(null);

  const handleCloseClick = (props: FormikProps<IBrand>, cancelClick: boolean = true) => {
    if (cancelClick && props.dirty) {
      setOpenConfirmationDialog(true);
    } else {
      onClose();
    }
  };

  const onSave = async (values: IBrand, actions: FormikHelpers<IBrand>): Promise<void> => {
    const brandList = await dashboard({
      DatTypeID: DatTypes.Brands,
      LastRowNumber: 0,
      MaxResults: 1,
      Search: values.Name,
      IsExactMatch: true,
      SearchNameOnly: true,
    });
    if (brand.ID) {
      if (brandList?.TotalRecordCount > 0 && brandList.Value[0]?.Name !== brand.Name) {
        return Promise.reject();
      }
      return updateBrand(values).then(() => {
        onClose(true);
        actions.resetForm();
        Toast.info(`Brand '${values.Name}' updated`);
      });
    } else {
      if (brandList?.TotalRecordCount > 0) {
        return Promise.reject();
      }
      return createBrand(values).then((res: IBrand) => {
        onClose(true, res);
        actions.resetForm();
        Toast.info(`Brand '${values.Name}' created`);
      });
    }
  };

  const handleBrandActivate = (): void => {
    if (brand.ID && brand.IsActive) {
      deleteBrand(brand.ID).then(() => {
        onClose(true);
      });
    } else if (brand.ID && !brand.IsActive) {
      const values = cloneDeep(brand);
      values.IsActive = true;
      updateBrand(values).then(() => {
        onClose(true);
      });
    }
    setOpenActivateDialog(false);
  };

  return (
    <>
      {openConfirmationDialog && (
        <AlertDialogSlideV2
          size="sm"
          title="Discard Changes"
          actions={[
            {label: 'Cancel', color: 'error', action: () => setOpenConfirmationDialog(false), variant: 'outlined'},
            {
              label: 'Discard',
              color: 'primary',
              action: () => {
                if (onClose) {
                  setOpenConfirmationDialog(false);
                  if (formRef && typeof formRef.current?.resetForm === 'function') {
                    const resetForm = formRef.current?.resetForm;
                    resetForm();
                  }
                  onClose();
                }
              },
            },
          ]}
          dialogActionsAlignment="justify-between"
        >
          <Typography className="pb-3 text-center font-medium">
            You have unsaved changes, are you sure you want to discard changes?
          </Typography>
        </AlertDialogSlideV2>
      )}
      {openActivateDialog && (
        <AlertDialog
          size="sm"
          title={brand.IsActive ? 'Delete Brand' : 'Activate Brand'}
          actions={[
            {
              label: 'Cancel',
              action: () => setOpenActivateDialog(false),
              variant: 'outlined',
            },
            {
              label: brand.IsActive ? 'Delete' : 'Activate',
              action: () => handleBrandActivate(),
            },
          ]}
          content={`Are you sure you want to ${brand.IsActive ? 'DELETE' : 'ACTIVATE'} Brand ${brand.Name ? brand.Name : ''}?`}
        />
      )}
      {open && (
        <Formik
          innerRef={formRef}
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={brand}
          onSubmit={onSave}
        >
          {props => (
            <AlertDialogSlideV2
              dialogClassess="!mx-5 !w-full !max-w-[96%] xl:!max-w-[950px]"
              dialogBodyClassess="h-[245px] flex-grow px-11 py-7"
              title={props.values.ID ? 'Edit Brand' : 'Add New Brand'}
              headingClassess="font-semibold justify-center"
              headerChildren={
                <IconButton
                  variant="text"
                  onClick={() => handleCloseClick(props)}
                  className="!absolute right-2 active:bg-transparent"
                  ripple={false}
                  name="btnClose"
                >
                  <CloseCross />
                </IconButton>
              }
              footer={
                <div className="flex w-full justify-between">
                  <Button
                    className="bg-white"
                    color="error"
                    variant="outlined"
                    size="md"
                    type="button"
                    onClick={() => handleCloseClick(props)}
                  >
                    Cancel
                  </Button>
                  <div>
                    {props.values.ID && (
                      <Button
                        variant="outlined"
                        color="error"
                        className={`${props.values.IsActive ? 'border-active border-error text-error' : 'border-inactive '} mr-2 bg-white`}
                        onClick={() => setOpenActivateDialog(true)}
                        disabled={isLoading}
                        loading={isLoading}
                      >
                        {props.values.IsActive ? 'Delete Brand' : 'Activate Brand'}
                      </Button>
                    )}
                    <Button
                      onClick={() => props.handleSubmit()}
                      disabled={props.isSubmitting || !props.dirty}
                      loading={props.isSubmitting || isLoading}
                    >
                      {props.values.ID ? 'Save Brand' : 'Add Brand'}
                    </Button>
                  </div>
                </div>
              }
            >
              <BrandForm {...props} />
            </AlertDialogSlideV2>
          )}
        </Formik>
      )}
    </>
  );
};
