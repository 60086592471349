import React from 'react';

export const BrushIcon = (props: SVGProps) => {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.833 0H4.167A4.172 4.172 0 000 4.167v4.166a5.84 5.84 0 005.833 5.834H7.5V17.5a2.5 2.5 0 005 0v-3.333h1.667A5.84 5.84 0 0020 8.333V4.167A4.172 4.172 0 0015.833 0zM1.667 4.167a2.5 2.5 0 012.5-2.5h4.166V2.5A.833.833 0 0010 2.5v-.833h1.667v2.5a.833.833 0 001.666 0v-2.5H15v4.166a.833.833 0 101.667 0V1.82a2.5 2.5 0 011.666 2.347v4.166H1.667V4.167zm12.5 8.333h-2.5a.833.833 0 00-.834.833V17.5a.833.833 0 01-1.666 0v-4.167a.833.833 0 00-.834-.833h-2.5A4.167 4.167 0 012.02 10h15.96a4.167 4.167 0 01-3.813 2.5z"
        className="fill-primary"
      />
    </svg>
  );
};
