import React, {useState, useEffect} from 'react';
import {FormikProps} from 'formik';
import {IAddress} from '../../model/address/Address';
import {ICustomer} from '../../model/customer/Customer';
import {useLocationAPI} from '../../services/useLocationAPI';
import {SButton} from '../buttons/Button';
import {LocationAutoComplete} from '../location/LocationAutoComplete';
import googleImg from '../../assets/png/SalesOrder/powered-by-google.png';
import {Button, Dialog, DialogBody, DialogFooter, DialogHeader, Input, Switch, Typography} from 'spenda-ui-react';
import {ILocation} from '../../model/address/Location';
interface ILocationNameType {
  ID: string;
  inputValue?: string;
  locationName: string;
}

export const AddOrderLocationForm = (
  props: FormikProps<ICustomer> & {
    locationIndex: number;
    addressIndex: number;
    handleDefaultBilling: any;
    valuesObject: any[];
    setLocations?: any;
    setAddressNumber?: any;
    setLocationNumber?: any;
    addressType?: string;
    isServiceAsset?: boolean;
    onCopyShippingAddress?: () => void;
    collapseAsset?: boolean;
    setValuesObject?: React.Dispatch<React.SetStateAction<ILocation[] | undefined>>;
    setCollapseAsset: React.Dispatch<React.SetStateAction<boolean>>;
    placeholder?: string;
    label?: string;
  },
) => {
  const {
    locationIndex,
    addressIndex,
    valuesObject,
    setLocations,
    setValuesObject,
    setAddressNumber,
    setLocationNumber,
    addressType,
    isServiceAsset,
    onCopyShippingAddress,
    collapseAsset,
    setCollapseAsset,
    placeholder,
    label,
  } = props;
  const {getLocationAddress} = useLocationAPI();
  const [collapse, setCollapse] = useState(false);
  const index = locationIndex;
  const [value, setValue] = useState<ILocationNameType | null>(null);
  const [open, toggleOpen] = useState(false);

  const [dialogValue, setDialogValue] = useState({
    inputValue: '',
    ID: '',
    locationName: '',
  });

  useEffect(() => {
    if (valuesObject && valuesObject.length) {
      const isNewLocation = locationIndex + 1 === valuesObject?.length;
      const _index = isNewLocation ? 0 : index;
      setValue({ID: valuesObject[_index]?.ID, locationName: valuesObject[_index]?.Name});
    }
  }, [valuesObject]);

  const handleChange = (e: React.ChangeEvent<any>, field: string) => {
    const selAddress = [...valuesObject];
    if (field === 'Name') {
      selAddress[index].LocationName = e.target.value;
      selAddress[index].Name = e.target.value;
    } else {
      selAddress[index].Addresses[addressIndex][field] = e.target.value || '';
    }
    setLocations(selAddress);
  };

  const handleClose = () => {
    setDialogValue({
      inputValue: '',
      ID: '',
      locationName: '',
    });
    toggleOpen(false);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setValue({
      ID: dialogValue.ID,
      locationName: dialogValue.locationName,
    });

    handleClose();
  };

  useEffect(() => {
    if (value && value?.locationName !== '' && valuesObject[index]?.ID !== value?.ID) {
      if (value?.ID === '') {
        const selAddress = [...valuesObject];
        selAddress[index].LocationName = value.locationName;
        selAddress[index].Name = value.locationName;
        setLocations(selAddress);
      } else {
        const selAddress = [...valuesObject];
        delete selAddress[index];
        const selectedIndex = valuesObject.findIndex(addr => addr.ID === value?.ID);
        selAddress[selectedIndex].Addresses.push({
          Name: value.locationName,
          Country: '',
          State: '',
          StreetAddress: '',
          City: '',
          PostCode: '',
          FullAddress: '',
          IsDefaultDeliverTo: addressType === 'ShippingAddress' ? true : false,
          IsDefaultBilling: addressType === 'BillingAddress' ? true : false,
          IsActive: true,
          addressFlag: true,
        });
        setLocationNumber(selectedIndex);
        setAddressNumber(selAddress[selectedIndex].Addresses.length - 1);
      }
    }
  }, [value]);

  return (
    <div className="mb-5 flex flex-col items-start justify-start">
      <Dialog open={open} handler={handleClose} aria-labelledby="form-dialog-title">
        <form onSubmit={handleSubmit}>
          <DialogHeader id="form-dialog-title">Add a new location</DialogHeader>
          <DialogBody>
            <Input
              autoFocus
              id="name"
              value={dialogValue.locationName}
              onChange={event => setDialogValue({...dialogValue, locationName: event.target.value})}
              label="name"
              type="text"
            />
          </DialogBody>
          <DialogFooter>
            <SButton onClick={handleClose}>Cancel</SButton>
            <SButton type="submit">Add</SButton>
          </DialogFooter>
        </form>
      </Dialog>
      {isServiceAsset && (
        <div className="relative my-8 mt-6 flex w-full items-center justify-center text-center">
          <div className="flex w-full max-w-[70%] items-center justify-between rounded-lg bg-primary/10 px-4 py-3">
            <Typography className="font-medium text-black-800">
              Is the asset the same as your customers Shipping Address?
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              className="bg-white shadow-none"
              onClick={() => onCopyShippingAddress?.()}
            >
              Copy Address
            </Button>
          </div>
        </div>
      )}
      <div className={'mb-2 flex w-full items-center justify-between'}>
        {!isServiceAsset && (
          <div className="w-2/5">
            <Input
              key="LocationName"
              onChange={e => {
                setValue({
                  inputValue: '',
                  ID: '',
                  locationName: e.target.value,
                });
              }}
              id={'LocationName'}
              name={'LocationName'}
              value={value?.locationName || ''}
              variant={'outlined'}
              label={'Location Name'}
              className="mr-2 w-full"
            />
          </div>
        )}
        {
          <div
            className={`${isServiceAsset ? 'absolute right-0 top-[-42px]' : 'w-3/5'} flex items-center justify-end pr-3`}
          >
            <Switch
              id="custom-switch-component"
              label={<span className="text-lg font-medium text-black-800">Enter Manually</span>}
              checked={isServiceAsset ? collapseAsset : collapse}
              onChange={() => (isServiceAsset ? setCollapseAsset(!collapseAsset) : setCollapse(!collapse))}
              rootContainerProps={{
                className: 'flex flex-row-reverse gap-4',
              }}
            />
          </div>
        }
      </div>
      {(isServiceAsset ? !collapseAsset : !collapse) ? (
        <div className={'relative flex w-full justify-between'}>
          <div className={`${isServiceAsset ? 'mx-auto w-full px-7' : 'w-full'}`}>
            <LocationAutoComplete
              placeholder={placeholder}
              label={label}
              styles={{marginTop: '0'}}
              carouselUI={true}
              isSalesModule={true}
              isLatestDesign
              isServiceAsset={isServiceAsset}
              selected={
                valuesObject?.[index]?.Addresses?.[addressIndex]?.StreetAddress ||
                valuesObject?.[index]?.Addresses?.[addressIndex]?.City ||
                valuesObject?.[index]?.Addresses?.[addressIndex]?.State ||
                valuesObject?.[index]?.Addresses?.[addressIndex]?.Country ||
                valuesObject?.[index]?.Addresses?.[addressIndex]?.PostCode
                  ? {
                      PlaceDescription: `${valuesObject?.[index]?.Addresses?.[addressIndex]?.StreetAddress ?? ''} ${
                        valuesObject?.[index]?.Addresses?.[addressIndex]?.City ?? ''
                      } ${valuesObject?.[index]?.Addresses?.[addressIndex]?.State ?? ''} ${
                        valuesObject?.[index]?.Addresses?.[addressIndex]?.PostCode ?? ''
                      } ${valuesObject?.[index]?.Addresses?.[addressIndex]?.Country ?? ''}`,
                    }
                  : null
              }
              clearClick={() => {
                const selAddress = [...valuesObject];
                selAddress[index].Addresses[addressIndex].StreetAddress = '';
                selAddress[index].Addresses[addressIndex].City = '';
                selAddress[index].Addresses[addressIndex].State = '';
                selAddress[index].Addresses[addressIndex].Country = '';
                selAddress[index].Addresses[addressIndex].PostCode = '';
                selAddress[index].Addresses[addressIndex].FullAddress = '';
                setValuesObject?.(selAddress);
              }}
              onSuggestionSelected={(e, selected) => {
                getLocationAddress(selected.suggestion.PlaceID || '').then((res: IAddress) => {
                  if (res) {
                    const selAddress = [...valuesObject];
                    selAddress[index].Addresses[addressIndex].StreetAddress = res.StreetAddress;
                    selAddress[index].Addresses[addressIndex].City = res.City;
                    selAddress[index].Addresses[addressIndex].State = res.State;
                    selAddress[index].Addresses[addressIndex].Country = res.Country;
                    selAddress[index].Addresses[addressIndex].PostCode = res.PostCode;
                    selAddress[index].Addresses[addressIndex].IsDefaultBilling =
                      valuesObject?.[index]?.Addresses?.[addressIndex]?.IsDefaultBilling;
                    selAddress[index].IsDefaultDeliverTo =
                      valuesObject?.[index]?.Addresses?.[addressIndex]?.IsDefaultDeliverTo;
                    selAddress[index].Addresses[addressIndex].IsActive = true;
                    setLocations(selAddress);
                  }
                });
              }}
            />
          </div>
          <div
            className={`${isServiceAsset ? 'absolute right-[14%] top-[10%] w-auto' : null} flex w-3/5 items-start justify-end`}
          >
            {<img width={122} height={17} src={googleImg} className="mr-4 mt-2" />}
          </div>
        </div>
      ) : null}
      {(isServiceAsset ? collapseAsset : collapse) ? (
        <div className={`${isServiceAsset ? 'px-5' : 'px-0'} w-full`}>
          <div className={'mb-4 flex w-full gap-4'}>
            <div className="w-2/4">
              <Input
                id={`Locations.${index}.Addresses.${addressIndex}.StreetAddress`}
                name={`Locations.${index}.Addresses.${addressIndex}.StreetAddress`}
                key={`Locations.${index}.Addresses.${addressIndex}.StreetAddress`}
                value={valuesObject?.[index]?.Addresses?.[addressIndex]?.StreetAddress}
                onChange={e => handleChange(e, 'StreetAddress')}
                className="w-full"
                label={'Street'}
                variant={'outlined'}
              />
            </div>
            <div className="w-2/4">
              <Input
                id={`Locations.${index}.Addresses.${addressIndex}.City`}
                name={`Locations.${index}.Addresses.${addressIndex}.City`}
                key={`Locations.${index}.Addresses.${addressIndex}.City`}
                value={valuesObject?.[index]?.Addresses?.[addressIndex]?.City}
                onChange={e => handleChange(e, 'City')}
                className="w-full"
                label={'Suburb/City'}
                variant={'outlined'}
              />
            </div>
          </div>
          <div className={'mb-4 flex w-full gap-4'}>
            <div className="w-2/4">
              <Input
                id={`Locations.${index}.Addresses.${addressIndex}.State`}
                name={`Locations.${index}.Addresses.${addressIndex}.State`}
                key={`Locations.${index}.Addresses.${addressIndex}.State`}
                value={valuesObject?.[index]?.Addresses?.[addressIndex]?.State}
                onChange={e => handleChange(e, 'State')}
                className="w-full"
                label={'State'}
                variant={'outlined'}
              />
            </div>
            <div className="w-2/4">
              <Input
                id={`Locations.${index}.Addresses.${addressIndex}.Country`}
                name={`Locations.${index}.Addresses.${addressIndex}.Country`}
                key={`Locations.${index}.Addresses.${addressIndex}.Country`}
                value={valuesObject?.[index]?.Addresses?.[addressIndex]?.Country}
                onChange={e => handleChange(e, 'Country')}
                className="w-full"
                label={'Country'}
                variant={'outlined'}
              />
            </div>
          </div>
          <div className={'mb-4 flex w-full gap-4'}>
            <div className="w-2/4">
              <Input
                id={`Locations.${index}.Addresses.${addressIndex}.PostCode`}
                name={`Locations.${index}.Addresses.${addressIndex}.PostCode`}
                key={`Locations.${index}.Addresses.${addressIndex}.PostCode`}
                value={valuesObject?.[index]?.Addresses?.[addressIndex]?.PostCode}
                onChange={e => handleChange(e, 'PostCode')}
                className="w-full"
                label={'Postcode'}
                variant={'outlined'}
              />
            </div>
            <div className="w-2/4"></div>
          </div>
        </div>
      ) : null}
      <p className={`mt-2 flex w-full items-center justify-center pb-6 text-base text-spenda-labeltext`}>
        <b>*Postal Code and Country are mandatory</b>
      </p>
    </div>
  );
};
