import React, {useEffect, useMemo, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {Button, IconButton, Typography} from 'spenda-ui-react';
import {ManageSchedulerBatchModal} from '../../../components/accountsPayableOnboarding/ManageScheduler';
import {ARTable} from '../../../components/AccountsReceivable/ARTable';
import {IXeroPurchaseInvoice} from '../../../model/purchase-invoice/purchaseInvoice';
import {IAdaptorSummary} from '../../../model/synkd/AdaptorSummary';
import {useAdaptorAPI} from '../../../services/useAdaptorAPI';
import moment from 'moment';
import {PriceFormat} from '../../../utils/formatter';
import {ArrowBackIcon} from '../../../assets/svg/ArrowBackIcon';
import {useFinancialAdaptorHook} from '../../../hooks/useARHook';

interface IARAdaptorViewProps {
  isSyncCompleted: boolean;
  isScheduler: boolean;
  setIsScheduler: React.Dispatch<React.SetStateAction<boolean>>;
  dateFormater: (date: string) => string;
  hanldeSyncInvoice: () => Promise<void>;
}

export const ARAdaptorView = (props: IARAdaptorViewProps) => {
  // Props
  const {isScheduler, isSyncCompleted, setIsScheduler, dateFormater, hanldeSyncInvoice} = props;

  // State
  const [syncDetails, setSyncDetails] = useState<IAdaptorSummary[]>([]);

  // APIs
  const {getAdaptorActionsSummary, isLoading} = useAdaptorAPI();

  // Hooks
  const history = useHistory();
  const {pathname, search} = useLocation();

  // Constant
  const searchParams = new URLSearchParams(search);
  const {financialAdaptorDisplayName} = useFinancialAdaptorHook();

  useEffect(() => {
    isSyncCompleted && loadSyncDetails();
  }, [isSyncCompleted]);

  const loadSyncDetails = async () => {
    const response = await getAdaptorActionsSummary();
    setSyncDetails(response);
  };

  const getRows = useMemo(() => {
    return syncDetails;
  }, [syncDetails]);

  const financialAdaptorColumns = [
    {
      title: 'Last Update',
      align: 'left',
      key: 'lastUpdate',
      width: '40%',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'px-2.5 !bg-white',
      rowRenderer: (row: IAdaptorSummary) => (
        <Typography data-autoid={`lblLastUpdate`} className="inline overflow-ellipsis font-medium">
          {dateFormater(row?.ActionDateTimeUTC)}
        </Typography>
      ),
    },
    {
      title: 'Invoices/batches synced',
      key: 'invoicesOrBatchesSynced',
      width: '50%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: `p-2.5 !bg-[#FAFAFA]`,
      rowRenderer: (row: IAdaptorSummary) => {
        return (
          <Typography
            data-autoid={`lnkInvoicesOrLastBatchSynced`}
            className="inline cursor-pointer overflow-ellipsis font-medium text-primary underline"
            onClick={() => {
              searchParams.set('adaptorLogId', row?.ID?.toString());
              history.push({pathname, search: searchParams?.toString()});
            }}
          >
            {row?.count} invoices
          </Typography>
        );
      },
    },
  ];

  const XeroAdaptorPanel = (
    <div className={`relative flex h-full flex-col overflow-hidden rounded-md bg-primary-background`}>
      <div className={`invoicesList h-full w-full rounded bg-white px-2.5 pb-14`}>
        <div className={`flex items-center justify-between border-b border-[#D8D8D8] px-2.5 py-3 pb-2.5`}>
          <Typography className="font-light" data-autoid={`lblFinancialAdaptor`} variant="h2">
            {financialAdaptorDisplayName} Adaptor
          </Typography>
          <div className="flex flex-row items-center justify-end">
            <Button
              data-autoid={`btnSyncInvoices`}
              type="button"
              variant="outlined"
              color="primary"
              className="font-medium"
              onClick={hanldeSyncInvoice}
              loading={!isSyncCompleted}
            >
              Sync Invoices
            </Button>
            <Button
              data-autoid={`btnManageSchedule`}
              variant="filled"
              color="primary"
              className="ml-5 font-medium"
              onClick={() => setIsScheduler(true)}
            >
              Manage Schedule
            </Button>
          </div>
        </div>
        <div className={`mt-3 flex w-[583px] flex-col overflow-y-auto bg-white`}>
          <div className="w-full overflow-y-auto">
            <ARTable
              isHighlightRowOnHover
              isLoading={isLoading}
              columns={financialAdaptorColumns}
              rows={getRows || []}
              dataAutoId="FinancialAdaptor"
              getRowDataAutoId={(rowData: IAdaptorSummary) => `rowFinancialAdaptor-${rowData?.ID}`}
            />
          </div>
        </div>
      </div>

      {isScheduler && (
        <ManageSchedulerBatchModal open={isScheduler} handleClose={() => setIsScheduler(false)} scope="PSBL" />
      )}
    </div>
  );

  return (
    <div className={`h-full w-full`}>
      <div className="h-full w-full">{XeroAdaptorPanel}</div>
    </div>
  );
};

interface AdaptorSyncedDetailsProps {
  isLoading: boolean;
  syncDetail: IAdaptorSummary | undefined;
  purchaseInvoiceList: IXeroPurchaseInvoice[];
  dateFormater: (date: string) => string;
}

export const SyncedDetailsView = (props: AdaptorSyncedDetailsProps) => {
  // Props
  const {syncDetail, isLoading, purchaseInvoiceList, dateFormater} = props;

  // Hooks
  const {search, pathname} = useLocation();
  const history = useHistory();

  // Constant
  const searchParams = new URLSearchParams(search);

  const navigateToSummary = () => {
    searchParams.delete('adaptorLogId');
    history.push({pathname, search: searchParams?.toString()});
  };

  const getRows = useMemo(() => {
    return purchaseInvoiceList;
  }, [purchaseInvoiceList]);

  const adaptorSyncDetailColumn = [
    {
      title: 'Supplier',
      align: 'left',
      key: 'supplier',
      width: '15%',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      rowRenderer: (row: IXeroPurchaseInvoice) => (
        <Typography
          data-autoid={`lblSupplier`}
          className="inline cursor-pointer overflow-ellipsis font-medium text-black-800"
        >
          {row?.OtherPartyName}
        </Typography>
      ),
    },
    {
      title: 'Purchase invoice ref no.',
      align: 'left',
      key: 'purchaseInvoiceRefNo',
      width: '8%',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      rowRenderer: (row: IXeroPurchaseInvoice) => (
        <Typography
          data-autoid={`lblPurchaseInvoiceRefNo`}
          className="inline cursor-pointer overflow-ellipsis font-medium text-black-800"
        >
          {row?.RefNumber}
        </Typography>
      ),
    },
    {
      title: 'Due Date',
      align: 'center',
      key: 'dueDate',
      width: '8%',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      rowRenderer: (row: IXeroPurchaseInvoice) => (
        <Typography
          data-autoid={`lblDueDate`}
          className="inline cursor-pointer overflow-ellipsis font-medium text-black-800"
        >
          {moment(row?.DueDate).format('Do MMM YYYY')}
        </Typography>
      ),
    },
    {
      title: 'Invoice total',
      align: 'right',
      key: 'invoiceTotal',
      width: '8%',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      rowRenderer: (row: IXeroPurchaseInvoice) => (
        <Typography
          data-autoid={`lblInvoiceTotal`}
          className="inline cursor-pointer overflow-ellipsis font-medium text-black-800"
        >
          {PriceFormat(row?.TotalInc, undefined, 2)}
        </Typography>
      ),
    },
    {
      title: 'Outstanding',
      align: 'right',
      key: 'outstanding',
      width: '8%',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      rowRenderer: (row: IXeroPurchaseInvoice) => (
        <Typography
          data-autoid={`lblOutstanding`}
          className="inline cursor-pointer overflow-ellipsis font-medium text-black-800"
        >
          {PriceFormat(row?.TotalInc, undefined, 2)}
        </Typography>
      ),
    },
    {
      title: 'Paid amount',
      align: 'right',
      key: 'paidAmount',
      width: '8%',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'pl-2.5',
      rowRenderer: (row: IXeroPurchaseInvoice) => (
        <Typography
          data-autoid={`lblPaidAmount`}
          className="inline cursor-pointer overflow-ellipsis font-medium text-black-800"
        >
          {PriceFormat(row?.TotalInc, undefined, 2)}
        </Typography>
      ),
    },
  ];

  return (
    <div className={`h-full w-full rounded bg-white pb-16`}>
      <div className={`flex items-center justify-start gap-3 border-b border-[#D8D8D8] px-2.5 py-3 pb-2.5`}>
        <IconButton variant="outlined" onClick={() => navigateToSummary()} data-autoid={`btnBack`}>
          <ArrowBackIcon />
        </IconButton>

        <Typography className="font-light" data-autoid={`lblSyncedDetails`} variant="h2">
          Synced Details
        </Typography>
      </div>

      <div className={`ml-3 mt-3 h-[78px] !w-[302px] rounded-lg bg-primary/20 p-5`}>
        <div className="flex flex-row justify-evenly gap-3 text-left font-poppins">
          <div className="flex flex-col">
            <Typography className=" text-left text-xs">last update</Typography>
            <Typography data-autoid={`lblDate`}>
              {syncDetail ? dateFormater(syncDetail?.ActionDateTimeUTC) : '-'}
            </Typography>
          </div>
          <div className="flex flex-col">
            <Typography className="text-left text-xs">invoices synced</Typography>
            <Typography data-autoid={`lblInvoicesCount`}>
              {syncDetail?.count ? `${syncDetail?.count} invoices` : '-'}
            </Typography>
          </div>
        </div>
      </div>

      <div className={`mt-3 flex h-full w-full bg-white`}>
        <div className="invoicesList flex h-[calc(100%-60px)] w-full flex-col overflow-y-auto bg-white">
          <ARTable
            tableClass="mb-12"
            isHighlightRowOnHover
            isLoading={isLoading}
            columns={adaptorSyncDetailColumn}
            rows={getRows}
            dataAutoId="financialAdaptorSyncDetails"
            getRowDataAutoId={(rowData: IXeroPurchaseInvoice) => `rowSyncedDetails-${rowData?.ID}`}
          />
        </div>
      </div>
    </div>
  );
};
