// Price and Margin Calculations

export default class PriceCalculator {
  public static roundNumber(num: number) {
    return Math.round((num + Number.EPSILON) * 100) / 100 || 0;
  }

  public static getMarginAmount(cost: number, sell: number): number {
    return this.roundNumber(sell - cost);
  }

  public static getMarginPercent(cost: number, sell: number): number {
    let margin = this.getMarginAmount(cost, sell);

    //Avoid divide by zero errors
    if (margin === 0 || cost === 0) {
      return 0;
    }

    return this.roundNumber((margin / sell) * 100);
  }

  public static getMarkupPercent(cost: number, sell: number): number {
    let margin = this.getMarginAmount(cost, sell);

    //Avoid divide by zero errors
    if (margin === 0 || cost === 0) {
      return 0;
    }

    return this.roundNumber((margin / cost) * 100);
  }

  public static getSellExByMarginAmount(cost: number, marginAmount: number): number {
    return this.roundNumber(cost + marginAmount);
  }

  public static getSellExByMarginPercent(cost: number, marginPercent: number): number {
    return this.roundNumber((cost / (100 - marginPercent)) * 100);
  }

  public static getSellExByMarkupPercent(cost: number, markupPercent: number): number {
    return this.roundNumber(cost * (markupPercent / 100) + cost);
  }

  public static getSellIncFromEx(sellEx: number, taxRate: number): number {
    if (taxRate) {
      return this.roundNumber(sellEx * (1 + taxRate));
    }
    return sellEx;
  }

  public static getSellExFromInc(sellInc: number, taxRate: number): number {
    if (taxRate) {
      return this.roundNumber(sellInc - sellInc / (1 + taxRate * 100));
    }
    return sellInc;
  }
}
