import React from 'react';
import {FormikProps} from 'formik';
import {IAccounts} from '../../model/accounts/Accounts';
import {STextField} from '../inputs/STextField';
import {SSelectSimple} from '../inputs/SSelect';
import {Box, Grid, MenuItem, makeStyles} from '@material-ui/core';

const useAccountFormStyles = makeStyles({
  formLabel: {
    fontSize: '1rem',
    color: '#333333',
    paddingBottom: '1.5rem',
  },
});

export const AccountForm = (props: FormikProps<IAccounts>) => {
  const classes = useAccountFormStyles();

  const {values, errors, touched, handleChange} = props;
  return (
    <Box display="flex" flexDirection="column" justifyContent="flex-start" alignItems="start" marginBottom="20px">
      <Grid container spacing={1}>
        <Grid item sm={2} className="flex items-end">
          <p className={`${classes.formLabel}`}>Account Type:</p>
        </Grid>
        <Grid item sm={3}>
          <SSelectSimple
            id="Type"
            name="Type"
            placeholder="Select Type"
            value={values.Type}
            onChange={handleChange}
            error={touched?.Type && Boolean(errors?.Type)}
            helperText={touched?.Type && errors?.Type}
            fullWidth
          >
            {values.Class === 'Expense'
              ? ['', 'Expense', 'Direct_Costs'].map((type, index) => (
                  <MenuItem className="pr-0" key={index} value={type}>
                    {type}
                  </MenuItem>
                ))
              : ['', 'Revenue', 'Sales'].map((type, index) => (
                  <MenuItem className="pr-0" key={index} value={type}>
                    {type}
                  </MenuItem>
                ))}
          </SSelectSimple>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item sm={2} className="flex items-end">
          <p className={`${classes.formLabel}`}>Chart of Accounts Code:</p>
        </Grid>
        <Grid item sm={3}>
          <STextField
            id="Code"
            name="Code"
            placeholder="Account Code"
            value={values.Code}
            onChange={handleChange}
            helperText={touched?.Code && errors?.Code}
            error={touched?.Code && Boolean(errors?.Code)}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item sm={2} className="flex items-end">
          <p className={`${classes.formLabel}`}>Name:</p>
        </Grid>
        <Grid item sm={3}>
          <STextField
            id="Name"
            name="Name"
            placeholder="Account Name"
            value={values.Name}
            onChange={handleChange}
            helperText={touched?.Name && errors?.Name}
            error={touched?.Name && Boolean(errors?.Name)}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item sm={2} className="flex items-end">
          <p className={`${classes.formLabel}`}>Description:</p>
        </Grid>
        <Grid item sm={10}>
          <STextField
            id="Description"
            name="Description"
            placeholder="Account Description"
            value={values.Description}
            onChange={handleChange}
            helperText={touched?.Description && errors?.Description}
            error={touched?.Description && Boolean(errors?.Description)}
            fullWidth
          />
        </Grid>
      </Grid>
    </Box>
  );
};
