import React from 'react';

export const SameDataArrow = () => {
  return (
    <svg className="cursor-default" width="16px" height="11px" viewBox="0 0 16 11" xmlns="http://www.w3.org/2000/svg">
      <title></title>
      <g
        transform="translate(-125 -136) translate(126 137)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <path stroke="#1C78AD" strokeWidth={1.2} strokeLinecap="round" d="M0.5 0.5L0.5 5.5" />
        <path
          d="M14.45 2.629L12.031.185a.625.625 0 00-.887 0 .625.625 0 000 .881l2.225 2.244H.625a.625.625 0 100 1.25h12.781l-2.262 2.256a.625.625 0 000 .888.625.625 0 00.887 0l2.419-2.425a1.875 1.875 0 000-2.65z"
          transform="translate(0 1.545)"
          fill="#1C78AD"
        />
      </g>
    </svg>
  );
};
