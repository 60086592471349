import React from 'react';

const AddIcon = (props: SVGProps) => (
  <svg width="48px" height="48px" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M24 0C10.745 0 0 10.745 0 24s10.745 24 24 24 24-10.745 24-24C47.986 10.751 37.249.014 24 0zm0 44C12.954 44 4 35.046 4 24S12.954 4 24 4s20 8.954 20 20c-.012 11.04-8.96 19.988-20 20zm10-20a2 2 0 01-2 2h-6v6a2 2 0 11-4 0v-6h-6a2 2 0 110-4h6v-6a2 2 0 114 0v6h6a2 2 0 012 2z"
      transform="translate(-496 -402) translate(496 402)"
      className="fill-primary"
      stroke="none"
      strokeWidth={1}
      fillRule="evenodd"
    />
  </svg>
);

export default AddIcon;
