import * as React from 'react';

const IconPencil = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={10} height={10} fill="none" {...props}>
      <path
        className="fill-primary"
        fillRule="evenodd"
        d="M9.101.899a1.365 1.365 0 00-1.928 0L1.05 7.02A1.87 1.87 0 00.5 8.35v.774c0 .207.169.376.376.376h.773c.5.001.978-.197 1.33-.551l6.122-6.122a1.365 1.365 0 000-1.928zM2.447 8.417c-.212.21-.499.33-.798.33h-.396v-.396a1.12 1.12 0 01.33-.798L6.23 2.907l.866.866-4.648 4.644zM8.57 2.295l-.944.944-.865-.864.943-.944a.611.611 0 11.864.866l.002-.002z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export {IconPencil};
