import React from 'react';

const GoBackIcon = (props: {width?: string; height?: string; className?: string}) => (
  <svg
    className={props.className}
    width="22px"
    height="12px"
    viewBox="0 0 22 12"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Back</title>
    <g id="Main-File" stroke="none" strokeWidth="1" fillRule="evenodd" className="fill-primary">
      <g id="Spenda-Collect-Supplier-Interface-Copy-49" transform="translate(-37.000000, -233.000000)">
        <g id="IconButton-Copy-2" transform="translate(28.000000, 219.000000)">
          <rect id="Background" fillOpacity="0" fill="#000000" x="0" y="0" width="40" height="40" rx="6"></rect>
          <rect id="Background-Overlay" fillOpacity="0" fill="#000000" x="0" y="0" width="40" height="40" rx="6"></rect>
          <rect id="Overlay" fillOpacity="0" fill="#000000" x="0" y="0" width="40" height="40" rx="6"></rect>
          <g id="Icon" transform="translate(9.000000, 14.242187)">
            <path
              d="M0.80424679,7.6869818 L4.35174679,11.2711485 C4.52386617,11.4446851 4.75816238,11.5422969 5.00258012,11.5422969 C5.24699786,11.5422969 5.48129407,11.4446851 5.65341346,11.2711485 L5.65341346,11.2711485 C5.82695005,11.0990291 5.92456192,10.8647329 5.92456192,10.6203151 C5.92456192,10.3758974 5.82695005,10.1416012 5.65341346,9.9694818 L2.39008012,6.68781513 L21.0809135,6.68781513 C21.5871745,6.68781513 21.9975801,6.27740948 21.9975801,5.77114846 L21.9975801,5.77114846 C21.9975801,5.26488744 21.5871745,4.8544818 21.0809135,4.8544818 L2.33508012,4.8544818 L5.65341346,1.53614846 C5.99097979,1.18204169 5.99097979,0.625255234 5.65341346,0.271148464 L5.65341346,0.271148464 C5.48129407,0.0976118674 5.24699786,1.77635684e-15 5.00258012,1.77635684e-15 C4.75816238,1.77635684e-15 4.52386617,0.0976118674 4.35174679,0.271148464 L0.80424679,3.80031513 C-0.268082263,4.87397947 -0.268082263,6.61331746 0.80424679,7.6869818 Z"
              id="Path"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default GoBackIcon;
