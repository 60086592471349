import React from 'react';
const IconQuotesListing = (props: {width?: string; height?: string}) => {
  return (
    <svg width={20} height={21} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.529 0a5.799 5.799 0 014.125 1.708l2.903 2.905a5.792 5.792 0 011.71 4.125v.629a.833.833 0 01-1.666.041l-.002-.041v-.63c0-.137-.026-.269-.039-.404h-4.127a2.5 2.5 0 01-2.5-2.5V1.706c-.135-.013-.268-.04-.404-.04H5.766a2.5 2.5 0 00-2.5 2.5v11.667a2.5 2.5 0 002.5 2.5h2.2c.447 0 .81.351.833.792v.042c0 .46-.373.833-.833.833h-2.2A4.172 4.172 0 011.6 15.833V4.167A4.172 4.172 0 015.766 0H9.53zM8.366 12.967a.833.833 0 110 1.666H5.8a.833.833 0 110-1.666h2.566zm2.5-3.334a.833.833 0 010 1.667H5.8a.833.833 0 110-1.667h5.066zm.734-7.396v3.596c0 .46.373.834.833.834h3.597a4.153 4.153 0 00-.651-.875l-2.904-2.905a4.173 4.173 0 00-.875-.65z"
        fill="#1C78AD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.94 17.84l-1.24 1.238a1.91 1.91 0 01-2.7-2.7l1.24-1.24a.38.38 0 10-.538-.536l-1.238 1.24a2.669 2.669 0 003.775 3.773l1.238-1.239a.38.38 0 00-.537-.537zM18.015 12.066a2.65 2.65 0 00-1.887-.783 2.652 2.652 0 00-1.887.781l-1.24 1.24a.38.38 0 00.537.536l1.24-1.238a1.91 1.91 0 012.699 2.7l-1.24 1.238a.38.38 0 00.538.537l1.239-1.238a2.672 2.672 0 000-3.773z"
        fill="#1C78AD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.11 14.432L12.83 16.71a.38.38 0 10.537.537l2.278-2.278a.38.38 0 00-.537-.537z"
        fill="#1C78AD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.07 11.892a2.895 2.895 0 012.058-.852 2.893 2.893 0 012.059.854 2.915 2.915 0 01-.001 4.117l-1.239 1.238a.623.623 0 01-.881-.88l1.239-1.24a1.666 1.666 0 00-2.355-2.356l-1.24 1.24a.623.623 0 11-.88-.882l1.24-1.239zm2.057-.366a2.409 2.409 0 00-1.713.71l-1.241 1.239a.137.137 0 00.193.194l1.24-1.239a2.152 2.152 0 113.043 3.043l-1.24 1.239a.137.137 0 10.194.194l1.239-1.238a2.43 2.43 0 000-3.43 2.408 2.408 0 00-1.714-.712zm-1.186 2.731a.623.623 0 01.88.88l-.003.004-2.277 2.277a.623.623 0 11-.88-.88l2.28-2.28zm.338.349L13 16.886a.137.137 0 10.193.192l.003-.003 2.277-2.276a.137.137 0 00-.194-.193zm-3.749-.176a.623.623 0 11.881.88l-1.237 1.24a1.666 1.666 0 002.356 2.355l1.309-1.309h.017a.623.623 0 01.796.95l-.003.002-1.238 1.239a2.912 2.912 0 01-4.12-4.117l1.239-1.24zm.344.344l-1.238 1.24a2.426 2.426 0 103.43 3.43l1.238-1.238a.137.137 0 00-.195-.192l-.032.03-1.207 1.208a2.152 2.152 0 01-3.044-3.043l.003-.002 1.238-1.24a.137.137 0 00-.193-.193z"
        fill="#1C78AD"
      />
    </svg>
  );
};

export default IconQuotesListing;
