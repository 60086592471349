import React from 'react';

function UnLockIcon(props: SVGProps) {
  return (
    <svg width={16} height={17} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.915 5.085v.914a3.21 3.21 0 011.927 2.938v3.853a3.214 3.214 0 01-3.21 3.21H4.21A3.214 3.214 0 011 12.79V8.937a3.21 3.21 0 011.926-2.938V4.863c.015-.436.072-.866.17-1.279a.79.79 0 01-.053-.134c.12-1.249.866-2.903 3.079-3.339A3.743 3.743 0 017.167.002a4.034 4.034 0 01.147 0c1.924 0 3.106 1.085 3.777 2.26.53.825.838 1.806.825 2.823zM3.098 3.586c-.11.459-.17.946-.17 1.452v.971h7.478V4.604a6.162 6.162 0 00-.045-.433c-.295-1.11-1.24-3.017-3.046-3.017-1.91 0-2.627 1.28-2.814 2.346-.047.17-.277.586-.715.586-.357 0-.586-.293-.688-.5zm9.46 9.204a1.926 1.926 0 01-1.926 1.926h-6.42a1.926 1.926 0 01-1.927-1.926V8.937c0-1.064.863-1.926 1.927-1.926h6.42c1.064 0 1.927.862 1.927 1.926v3.853z"
        className="fill-primary"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.53 9.58a.644.644 0 00-.653.634v1.27c0 .35.292.634.653.634.36 0 .653-.284.653-.634v-1.27a.644.644 0 00-.653-.635z"
        className="fill-primary"
      />
    </svg>
  );
}

export {UnLockIcon};
