import React, {useEffect, useState} from 'react';
import {Checkbox, Typography} from 'spenda-ui-react';

import {RoundedCheckbox} from '../../../../components/inputs/RoundedCheckbox';
import {ARTable} from '../../../../components/AccountsReceivable/ARTable';
import {SPagination} from '../../../../components/tables/SPagination';
import {Toast} from '../../../../utils/Toast';

interface ProductData {
  sku: string;
  code: string;
  productName: string;
  uom: string;
  costPrice: string;
  sellPrice: string;
  revenueAccount: string;
  expenseAccount: string;
  warningMessage: string;
}

interface StagingAreaTableProps {
  data: ProductData[];
  validationResults: any[];
  pageSize: number;
  totalCount: number;
  currentPage: number;
  handlePageChange: (newPage: number) => void;
  handleRowsPerPageChange: (rows: number) => void;
}

interface FilterCheckboxProps {
  dataAutoid: string;
  label: string;
  name: string;
  value: string;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const FilterCheckbox: React.FC<FilterCheckboxProps> = props => (
  <Checkbox
    {...props}
    labelProps={{className: 'text-sm'}}
    containerProps={{className: '!py-2'}}
    className={
      'h-5 w-5 border-primary bg-white transition-all checked:border-primary checked:bg-primary hover:before:opacity-0'
    }
  />
);

export const StagingAreaTable: React.FC<StagingAreaTableProps> = ({
  data,
  validationResults,
  pageSize,
  totalCount,
  currentPage,
  handlePageChange,
  handleRowsPerPageChange,
}) => {
  const [filters, setFilters] = useState({all: true, valid: true, errors: true, warnings: true});

  const handleFilterChange = (filterKey: string, checked: boolean) => {
    setFilters(prevFilters => ({...prevFilters, [filterKey]: checked}));
  };

  const getBorderClass = (validationResult: string) => {
    if (validationResult === 'missing') {
      return 'border border-error p-6';
    } else if (validationResult === 'invalid') {
      return 'border border-warning p-3';
    }
    return '';
  };

  const getWarningClass = (validationResult: string) => {
    if (Object.values(validationResult).includes('missing')) {
      return 'text-error';
    } else if (Object.values(validationResult).includes('invalid')) {
      return 'text-warning';
    }
    return '';
  };

  const commonRowClass = 'm-0 flex items-center justify-center gap-x-2 p-3 text-lg focus-within:border-primary';

  useEffect(() => {
    const issuesCount = validationResults.filter(
      result => Object.values(result).includes('missing') || Object.values(result).includes('invalid'),
    ).length;

    if (issuesCount > 0) {
      Toast.warning(
        <>
          <Typography className="font-medium text-spenda-primarytext">
            194 products uploaded, {issuesCount} issues found
          </Typography>
          <Typography variant="xsmall" className="text-spenda-primarytext">
            Please address the following issues in your Excel template. Once these issues are resolved, please re-upload
            your file.
          </Typography>
        </>,
        {
          border: '1px solid #C68A19',
          padding: '10px',
          width: '503px',
        },
      );
    }
  }, [validationResults]);

  const columns = [
    {
      title: '',
      key: '',
      width: '1%',
      align: 'left',
      rowClassName: 'p-2.5',
      headerRenderer: () => {
        return <RoundedCheckbox />;
      },
      rowRenderer: (_index: number) => {
        return <RoundedCheckbox />;
      },
    },
    {
      title: 'SKU',
      key: 'sku',
      width: '5%',
      align: 'center',
      rowClassName: '',
      rowRenderer: (rowData: ProductData, rowIndex: number, _columnIndex: number) => {
        const validationResult = validationResults[rowIndex]?.sku;
        return (
          <div className={`${commonRowClass} ${getBorderClass(validationResult)}`}>
            <Typography data-autoid={`txtSku-${rowIndex}`}>{rowData?.sku}</Typography>
          </div>
        );
      },
    },
    {
      title: 'Code',
      key: 'code',
      width: '7%',
      align: 'center',
      rowClassName: '',
      rowRenderer: (rowData: ProductData, rowIndex: number, _columnIndex: number) => {
        const validationResult = validationResults[rowIndex]?.code;
        return (
          <div className={`${commonRowClass} ${getBorderClass(validationResult)}`}>
            <Typography data-autoid={`txtCode-${rowIndex}`}>{rowData?.code}</Typography>
          </div>
        );
      },
    },
    {
      title: 'Product name',
      key: 'productName',
      width: '10%',
      align: 'center',
      rowClassName: '',
      rowRenderer: (rowData: ProductData, rowIndex: number, _columnIndex: number) => {
        const validationResult = validationResults[rowIndex]?.productName;
        return (
          <div className={`${commonRowClass} ${getBorderClass(validationResult)}`}>
            <Typography className="font-medium text-primary" data-autoid={`txtProductName-${rowIndex}`}>
              {rowData?.productName}
            </Typography>
          </div>
        );
      },
    },
    {
      title: 'UoM',
      key: 'uom',
      width: '4%',
      align: 'center',
      rowClassName: '',
      rowRenderer: (rowData: ProductData, rowIndex: number, _columnIndex: number) => {
        const validationResult = validationResults[rowIndex]?.uom;
        return (
          <div className={`${commonRowClass} ${getBorderClass(validationResult)}`}>
            <Typography data-autoid={`txtUoM-${rowIndex}`}>{rowData?.uom}</Typography>
          </div>
        );
      },
    },
    {
      title: 'Cost Price (ex)',
      key: 'costPrice',
      width: '5%',
      align: 'right',
      rowClassName: 'p-2.5',
      rowRenderer: (rowData: ProductData, rowIndex: number, _columnIndex: number) => {
        const validationResult = validationResults[rowIndex]?.costPrice;
        return (
          <div className={`${commonRowClass} ${getBorderClass(validationResult)}`}>
            <Typography data-autoid={`txtCostPrice-${rowIndex}`}>{rowData?.costPrice}</Typography>
          </div>
        );
      },
    },
    {
      title: 'Sell Price (ex)',
      key: 'sellPrice',
      width: '5%',
      align: 'right',
      rowClassName: 'p-2.5',
      rowRenderer: (rowData: ProductData, rowIndex: number, _columnIndex: number) => {
        const validationResult = validationResults[rowIndex]?.sellPrice;
        return (
          <div className={`${commonRowClass} ${getBorderClass(validationResult)}`}>
            <Typography data-autoid={`txtSellPrice-${rowIndex}`}>{rowData?.sellPrice}</Typography>
          </div>
        );
      },
    },
    {
      title: 'Revenue Account',
      key: 'revenueAccount',
      width: '7%',
      align: 'center',
      rowClassName: 'p-2.5',
      rowRenderer: (rowData: ProductData, rowIndex: number, _columnIndex: number) => {
        const validationResult = validationResults[rowIndex]?.revenueAccount;
        return (
          <div className={`${commonRowClass} ${getBorderClass(validationResult)}`}>
            <Typography data-autoid={`txtRevenueAccount-${rowIndex}`}>{rowData?.revenueAccount}</Typography>
          </div>
        );
      },
    },
    {
      title: 'Expense Account',
      key: 'expenseAccount',
      width: '7%',
      align: 'center',
      rowClassName: 'p-2.5',
      rowRenderer: (rowData: ProductData, rowIndex: number, _columnIndex: number) => {
        const validationResult = validationResults[rowIndex]?.expenseAccount;
        return (
          <div className={`${commonRowClass} ${getBorderClass(validationResult)}`}>
            <Typography data-autoid={`txtExpenseAccount-${rowIndex}`}>{rowData?.expenseAccount}</Typography>
          </div>
        );
      },
    },
    {
      title: 'Warning Message',
      key: 'warningMessage',
      width: '15%',
      align: 'center',
      rowClassName: 'p-2.5',
      rowRenderer: (rowData: ProductData, rowIndex: number, _columnIndex: number) => {
        const validationResult = validationResults[rowIndex];
        return (
          <div className={`${commonRowClass}`}>
            <Typography data-autoid={`txtWarningMessage-${rowIndex}`} className={getWarningClass(validationResult)}>
              {rowData?.warningMessage}
            </Typography>
          </div>
        );
      },
    },
  ];

  return (
    <div className="h-full w-full">
      <div className="mb-2">
        <FilterCheckbox
          dataAutoid="chkAllProducts"
          label="All Products (194)"
          name="all"
          value="all"
          checked={filters.all}
          onChange={e => handleFilterChange('all', e.target.checked)}
        />
        <FilterCheckbox
          dataAutoid="chkValidProducts"
          label="Valid (29)"
          name="valid"
          value="valid"
          checked={filters.valid}
          onChange={e => handleFilterChange('valid', e.target.checked)}
        />
        <FilterCheckbox
          dataAutoid="chkErrors"
          label="Errors (2)"
          name="errors"
          value="errors"
          checked={filters.errors}
          onChange={e => handleFilterChange('errors', e.target.checked)}
        />
        <FilterCheckbox
          dataAutoid="chkWarnings"
          label="Warnings (6)"
          name="warnings"
          value="warnings"
          checked={filters.warnings}
          onChange={e => handleFilterChange('warnings', e.target.checked)}
        />
      </div>
      <div className={`h-[calc(100vh-320px)] flex-grow`}>
        <ARTable isLoading={false} rows={data} columns={columns} tableClass="!-mx-2.5 !w-[calc(100%+20px)]" />
      </div>
      <div className="mt-4">
        <SPagination
          currentPage={currentPage}
          totalCount={totalCount}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 15, 25]}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </div>
    </div>
  );
};
