import React from 'react';
export const IconANZ = (props: {width?: string; height?: string}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={props.width || '420'}
      height={props.height || '140'}
      id='svg01'
      version='1.0'
      viewBox='0 0 420 170'
    >
      <g id='g001' transform='matrix(0,0.4391693,-0.4391693,0,438.9744,-76.985248)' fill='#007dba' fillOpacity={1}>
        <path
          id='anztxt01'
          fill='#fff'
          fillOpacity={1}
          fillRule='nonzero'
          stroke='none'
          d='m 420.6564,401.7127 0,69.525 -37.569,-28.5315 0,77.244 36.174,27.5235 c 14.376,10.29 28.8855,11.286 38.955,11.286 l 5.6925,0 c 6.579,0 10.1565,-4.0755 10.1565,-10.1235 l 0,-146.9235 c 0,-5.925 -3.2685,-9.924 -9.957,-9.924 l -33.4605,0 c -6.735,0 -9.9915,3.999 -9.9915,9.924 m -132.723,395.7795 c -6.291,1.107 -9.393,5.3715 -9.393,10.92 l 0,19.6485 c 0,20.4465 10.8435,38.49 27.225,48.6015 0,0 29.9835,18.7515 63.7305,39.7635 l 0,-133.3995 -81.5625,14.466 z m 0.5655,-219.726 c -6.6915,0 -9.9585,3.987 -9.9585,9.957 l 0,39.996 c 0,5.925 3.267,9.924 9.9585,9.924 l 75.804,0 -68.151,37.9125 c -12.5595,7.5765 -17.334,20.3355 -17.6115,35.2215 l 0,29.562 c 0,5.871 3.267,9.8805 9.9585,9.8805 l 81.042,0 0,-49.6875 13.5465,-7.6425 0,57.33 81.021,0 c 6.6885,0 10.0125,-4.0095 10.0125,-9.8805 l 0,-40.095 c 0,-5.958 -3.324,-9.9015 -10.0125,-9.9015 l -76.281,0.0225 68.7045,-38.268 c 12.4935,-7.4985 17.268,-20.3025 17.589,-35.088 l 0,-29.286 c 0,-5.97 -3.324,-9.957 -10.0125,-9.957 l -81.021,0 0,49.332 -13.5465,7.6215 0,-56.9535 -81.042,0 z m 94.5885,202.89 0,65.88 c 9.2145,-1.683 77.7315,-13.8345 81.7185,-14.5545 6.1695,-1.119 9.315,-4.962 9.315,-10.599 l 0,-45.324 c 0,-4.152 -1.3065,-6.246 -3.6765,-8.196 -1.6515,-1.329 -4.155,-1.8825 -7.821,-1.2615 l -79.536,14.055 z m 78.7725,193.4196 c 3.0675,1.79415 6.291,1.79415 8.3835,0.69795 2.448,-1.2627 3.8775,-4.0758 3.8775,-7.11105 l 0,-47.073 c 0,-5.9355 -3.201,-11.2425 -7.986,-14.199 l -83.0475,-51.9465 0,70.46565 c 38.7765,24.1899 77.8755,48.55725 78.7725,49.16595 m -129.9765,-426.7581 0,-66.234 37.6125,28.5645 0,-77.2215 -37.0485,-28.1655 c -11.4525,-8.6955 -21.708,-10.71 -30.78,-10.71 l -12.903,0 c -6.6135,0 -10.224,3.9975 -10.224,10.089 l 0,143.6775 c 0,5.9265 3.267,9.891 9.9585,9.891 l 33.4605,0 c 6.6675,0 9.924,-3.9645 9.924,-9.891'
        />
        <path
          id='symbol01'
          fill='#007dba'
          fillOpacity={1}
          fillRule='nonzero'
          stroke='none'
          d='m 275.86005,218.3392 c 0,-13.524 11.7405,-28.9185 27.9555,-28.9185 9.138,0 16.194,2.913 22.6395,7.1985 4.1085,2.7465 8.838,4.8405 12.261,5.7045 3.8655,0.93 13.224,2.9685 13.38,-8.4735 0.1875,-7.443 -6.291,-25.7625 -24.135,-40.56 -14.3325,-11.385 -32.508,-18.153 -52.3335,-18.153 -46.3305,0 -83.901,37.2045 -83.901,83.202 0,46.0095 37.5705,83.2815 83.901,83.2815 19.8255,0 38.001,-6.7575 52.3335,-18.1545 17.844,-14.82 24.3225,-33.1275 24.135,-40.56 -0.156,-11.442 -9.5145,-9.3705 -13.38,-8.484 -3.423,0.8415 -8.1525,2.946 -12.261,5.715 -6.4455,4.275 -13.5015,7.1985 -22.6395,7.1985 -16.215,-0.033 -27.9555,-15.4065 -27.9555,-28.9965 m 84.798,-147.177 c -2.8245,3.411 -6.945,9.393 -8.8395,21.675 -2.547,17.046 1.4505,32.2545 5.0835,41.4135 4.764,12.039 15.5955,26.871 29.706,37.116 14.9085,10.8105 37.416,16.404 60.8745,11.0415 6.6015,-1.5165 10.323,-3.156 14.952,-5.604 6.291,-3.201 15.075,-11.64 16.659,-19.029 1.2075,-5.8365 -2.1495,-10.3995 -4.686,-12.6375 -17.244,-14.8845 -84.0225,-71.295 -92.55,-77.409 -8.4735,-6.0045 -16.6155,-2.082 -21.1995,3.4335 m -3.756,231.3 c -3.633,9.159 -7.6305,24.3555 -5.0835,41.4015 1.8945,12.2835 6.015,18.3195 8.8395,21.687 4.584,5.5155 12.726,9.4695 21.1995,3.4335 8.5275,-6.081 75.306,-62.4795 92.55,-77.3655 2.5365,-2.238 5.8935,-6.801 4.686,-12.6495 -1.584,-7.4325 -10.368,-15.8385 -16.659,-19.0275 -4.629,-2.4825 -8.3505,-4.0875 -14.952,-5.649 -23.4585,-5.3505 -45.966,0.3315 -60.8745,11.0415 -14.1105,10.257 -24.942,25.11 -29.706,37.128'
        />
      </g>
    </svg>
  );
};
