import React, {useState} from 'react';
import {SModal} from '../modals/modalSpendaMeterialUI';
import {useFeatureFlags} from '../../hooks/useFeatureFlags';
import {APFooter} from './APFooter';
import {IconBank} from '../../assets/svg/payment-methods/IconBank';
import backButton from '../../assets/png/back-button.png';
import CCIcon from '../../assets/png/CC.png';
import {Box, makeStyles} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {PaymentWidgetScope} from '../../model/constants/Constants';
import {ManagePaymentMethods} from '../../screens/payment-methods/ManagePaymentMethods';

const useAuthorizePaymentStyles = makeStyles({
  apOnboardingModal: {
    maxWidth: '800px',
  },
  apBusinessHeader: {
    borderBottom: '1px solid #CCCCCC',
    '& h2': {
      fontFamily: 'Poppins, sans-serif !important',
      fontWeight: 300,
      fontSize: '28px',
      color: '#333333',
    },
  },
  backBtn: {
    maxWidth: '40px',
    height: '40px',
  },
  boxHeight: {
    height: 'calc(100% - 119px)',
    overflow: 'hidden',
    '& button': {
      fontFamily: 'Poppins, sans-serif !important',
    },
    '& p, button, span, h1, h2, h3, h4, h5, h6': {
      fontFamily: 'Poppins, sans-serif !important',
    },
    '& img': {
      height: '90px',
      width: '135px',
    },
    '& svg': {
      margin: '0px auto 25px',
    },
  },
  selectionBoxHeading: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '22px',
    color: '#444343',
  },
});
export interface IAuthorizePaymentProps {
  onNextClick: () => void;
  handleClose: () => void;
  onBackPress: () => void;
}

export const AuthorizePayment = (props: IAuthorizePaymentProps) => {
  const classes = useAuthorizePaymentStyles();
  const {paymentAuth72488} = useFeatureFlags().tenantOwned();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string>('');
  const [openWidget, setOpenWidget] = useState<boolean>(false);
  const [widgetScope, setWidgetScope] = useState<PaymentWidgetScope>(PaymentWidgetScope.ADD_CREDIT_CARD);
  const {onboardingUpgradeV1} = useFeatureFlags().tenantOwned();

  const handleAuthoriseClick = (selectedMethod: string) => {
    if (selectedMethod === 'card') {
      setWidgetScope(PaymentWidgetScope.ADD_CREDIT_CARD);
      setOpenWidget(true);
    } else if (selectedMethod === 'bank') {
      setWidgetScope(PaymentWidgetScope.ADD_BANK_ACCOUNT);
      setOpenWidget(true);
    }
  };

  return (
    <>
      {openWidget && (
        <ManagePaymentMethods
          widgetScope={widgetScope}
          widgetManageMethods73098={true}
          onClose={() => {
            setOpenWidget(value => !value);
            setSelectedPaymentMethod('');
          }}
          open={openWidget}
          isBPSP={true}
          onSuccess={() => {
            setOpenWidget(value => !value);
            props.onNextClick();
          }}
          paymentAuth72488={paymentAuth72488}
          onboardingUpgradeV1={onboardingUpgradeV1}
        />
      )}
      <SModal open>
        <div className={`text-xl spenda-color flex justify-center items-center h-full max-w-full `}>
          <div
            className={`modal-box ${classes.apOnboardingModal} px-3 py-3
          `}
          >
            <Box className={`${classes.apBusinessHeader} flex justify-between -mx-3 px-3 pb-3`}>
              <span className="cursor-pointer" onClick={() => props.onBackPress()} data-autoid="btnBack">
                <img src={backButton} alt="Back Button" className={classes.backBtn} />
              </span>
              <h2 className={`text-center w-full`}>Add Payment Method</h2>
              <Box className="text-right flex justify-end">
                <span className="ap-onboarding-close-icon" onClick={props.handleClose} data-autoid="btnClose">
                  <CloseIcon />
                </span>
              </Box>
            </Box>
            <div className={`${classes.boxHeight}`}>
              <Box className="w-full h-full flex space-x-8 justify-center items-center px-10">
                <Box
                  className={`w-5/12 text-center bg-spenda-cream rounded-lg px-6 py-6 ${
                    selectedPaymentMethod === 'card' ? 'border-spenda-sBlue' : 'border-spenda-scream'
                  } border-2 cursor-pointer`}
                  onClick={() => {
                    setSelectedPaymentMethod('card');
                    handleAuthoriseClick('card');
                  }}
                  data-autoid="lnkCard"
                >
                  <img src={CCIcon} alt="Bank Icon" className="mx-auto mb-5" data-autoid="imgCreditCardIcon" />
                  <h3 className={classes.selectionBoxHeading}>Credit Card</h3>
                </Box>
                <Box
                  className={`w-5/12 text-center  bg-spenda-cream rounded-lg px-6 py-6 ${
                    selectedPaymentMethod === 'bank' ? 'border-spenda-sBlue' : 'border-spenda-scream'
                  } border-2 cursor-pointer`}
                  onClick={() => {
                    setSelectedPaymentMethod('bank');
                    handleAuthoriseClick('bank');
                  }}
                  data-autoid="lnkBank"
                >
                  <IconBank width="135px" height="90px" />
                  <h3 className={classes.selectionBoxHeading}>Bank Account</h3>
                </Box>
              </Box>
            </div>
            <APFooter onNextClick={() => props.onNextClick()} buttonLabel={'Next'} />
          </div>
        </div>
      </SModal>
    </>
  );
};
