import React from 'react';

export const IconCompanyDetailsNew = (props: {width?: string; height?: string}) => {
  return (
    <svg
      width={props.width || '35px'}
      height={props.height || '29px'}
      viewBox="0 0 107 106"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Business Details</title>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Group-3" className="fill-primary">
          <g id="Icons/Shopping/shopping_bag_add">
            <path
              d="M87.5,25 L75,25 C75,11.1928813 63.8071187,0 50,0 C36.1928813,0 25,11.1928813 25,25 L12.5,25 C5.59644063,25 0,30.5964406 0,37.5 L0,79.1666667 C0.0137754847,90.6668881 9.33311187,99.9862245 20.8333333,100 L65.5096465,100 C67.8108329,100 62.5,98.1345198 62.5,95.8333333 C62.5,93.5321469 60.6345198,91.6666667 58.3333333,91.6666667 L20.8333333,91.6666667 C13.929774,91.6666667 8.33333333,86.070226 8.33333333,79.1666667 L8.33333333,37.5 C8.33333333,35.1988135 10.1988135,33.3333333 12.5,33.3333333 L25,33.3333333 L25,41.6666667 C25,43.9678531 26.8654802,45.8333333 29.1666667,45.8333333 C31.4678531,45.8333333 33.3333333,43.9678531 33.3333333,41.6666667 L33.3333333,33.3333333 L66.6666667,33.3333333 L66.6666667,41.6666667 C66.6666667,43.9678531 68.5321469,45.8333333 70.8333333,45.8333333 C73.1345198,45.8333333 75,43.9678531 75,41.6666667 L75,33.3333333 L87.5,33.3333333 C89.8011865,33.3333333 91.6666667,35.1988135 91.6666667,37.5 L91.6666667,58.3333333 C91.6666667,60.6345198 93.5321469,62.5 95.8333333,62.5 C98.1345198,62.5 100,64.8011865 100,62.5 L100,37.5 C100,30.5964406 94.4035594,25 87.5,25 Z M33.3333333,25 C33.3333333,15.7952542 40.7952542,8.33333333 50,8.33333333 C59.2047458,8.33333333 66.6666667,15.7952542 66.6666667,25 L33.3333333,25 Z"
              id="Shape"
            ></path>
          </g>
          <g id="Icons/General/info" transform="translate(54.000000, 53.000000)">
            <path
              d="M26.5,0 C11.8644541,0 0,11.8644541 0,26.5 C0,41.1355459 11.8644541,53 26.5,53 C41.1355459,53 53,41.1355459 53,26.5 C52.9841776,11.871013 41.128987,0.0158223718 26.5,0 Z M26.5,48.5833333 C14.3037118,48.5833333 4.41666667,38.6962882 4.41666667,26.5 C4.41666667,14.3037118 14.3037118,4.41666667 26.5,4.41666667 C38.6962882,4.41666667 48.5833333,14.3037118 48.5833333,26.5 C48.5699456,38.6907385 38.6907385,48.5699456 26.5,48.5833333 L26.5,48.5833333 Z"
              id="Shape"
            ></path>
            <path
              d="M26.5,22.0833333 L24.2916667,22.0833333 C23.0720378,22.0833333 22.0833333,23.0720378 22.0833333,24.2916667 C22.0833333,25.5112955 23.0720378,26.5 24.2916667,26.5 L26.5,26.5 L26.5,39.75 C26.5,40.9696288 27.4887045,41.9583333 28.7083333,41.9583333 C29.9279622,41.9583333 30.9166667,40.9696288 30.9166667,39.75 L30.9166667,26.5 C30.9166667,24.0607424 28.9392576,22.0833333 26.5,22.0833333 Z"
              id="Path"
            ></path>
            <circle id="Oval" cx="26.5" cy="14.3541667" r="3.3125"></circle>
          </g>
        </g>
      </g>
    </svg>
  );
};
