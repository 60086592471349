import React from 'react';

export function RightAngleIcon(props: SVGProps) {
  return (
    <svg width={30} height={30} viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.279 50.008a2.084 2.084 0 01-1.48-3.562l17.022-17.02a6.25 6.25 0 000-8.834L13.8 3.57A2.092 2.092 0 0116.758.613l17.021 17.02a10.417 10.417 0 010 14.75l-17.02 17.021c-.394.39-.926.608-1.48.604z"
        fill={props.fill ?? '#FFFFFF'}
      />
    </svg>
  );
}
