import * as React from 'react';
export const SwapIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" {...props}>
    <path
      className="fill-primary"
      fillRule="evenodd"
      d="m.585 15.606 3.291-3.291a1 1 0 1 1 1.413 1.414l-2.29 2.294a9.331 9.331 0 0 0 6.686-2.8.982.982 0 0 1 1.408.08l.013.02a1 1 0 0 1-.075 1.382 11.177 11.177 0 0 1-8.035 3.318l2.293 2.293a1 1 0 1 1-1.413 1.407L.585 18.432a2 2 0 0 1 0-2.826Z"
      clipRule="evenodd"
    />
    <path
      className="fill-primary"
      fillRule="evenodd"
      d="m2.999 6.023 2.29 2.29c.02.026.038.054.054.082a.992.992 0 0 1-1.467 1.332L2.17 8.023.584 6.438a2 2 0 0 1-.457-2.1 1.97 1.97 0 0 1 .458-.728L3.876.323a1 1 0 1 1 1.413 1.413L2.996 4.028c4.933.012 7.637 2.674 10.089 5.474 2.252-2.542 4.921-5.08 9.434-5.433L23 4.023a1 1 0 0 1 1 1 1.02 1.02 0 0 1-1 1c-.072 0-.287.033-.287.033-3.896.295-6.132 2.489-8.307 4.967 2.162 2.466 4.383 4.7 8.247 4.96l.4.02a.995.995 0 0 1-.124 1.986c-.156-.007-.41-.013-.535-.023-5.02-.387-7.743-3.6-10.171-6.41-2.452-2.833-4.768-5.515-9.224-5.533Z"
      clipRule="evenodd"
    />
  </svg>
);
