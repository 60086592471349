import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {Layout} from '../../components/layout/Layout';
import backButton from '../../assets/png/back-button.png';
import {AUTH_BUYING_AP_REQUIRES_ATTENTION_ROUTE} from '../../routes/AccountsPayableRoutes';
import {useStagedTransactionsAPI} from '../../services/useStagedTransactionsAPI';
import {FinancialSystemPostingBehaviourModal} from '../../components/accountsPayableOnboarding/FinancialSystemPostingBehaviourModal';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@material-ui/core';
import {useRequiresAttentionTableStyle} from '../../components/t2t/RequiresAttentionTable';
import {
  ISelectedInvoices,
  IStagedDebitNoteDetail,
  IStagedDebitNoteDetailLine,
  IStagedPurchaseInvoiceDetail,
  IStagedPurchaseInvoiceDetailLine,
} from '../../model/requires-attention/StagedTransactions';
import {PriceFormat} from '../../utils/formatter';
import {SelectToggler} from './CommonComponents';
import _ from 'lodash';
import {SecondaryButton} from '../../components/buttons/DefaultButtons';
import APTooltip from '../../components/data-display/APTootip';
import {Edit} from '@material-ui/icons';

export const RequiresAttentionInvoiceDetails = () => {
  const classes = useRequiresAttentionTableStyle();
  const history = useHistory();
  const {getStagedPurchaseInvoiceDetails, getStagedDebitNoteDetails} = useStagedTransactionsAPI();
  const {invoiceID} = useParams<{invoiceID?: any}>();
  const [financialBehaviour, setFinancialBehaviour] = useState<boolean>(false);
  const [purchaseInvoiceDetails, setPurchaseInvoiceDetails] = useState<IStagedPurchaseInvoiceDetail>();
  const [debitNoteDetails, setDebitNoteDetails] = useState<IStagedDebitNoteDetail>();
  const [selectedLineItemID, setSelectedLineItemID] = useState<number | null>();
  const [selectedLineItemCode, setSelectedLineItemCode] = useState<string>('');
  const [selectedInvoice, setSelectedInvoice] = useState<ISelectedInvoices[]>([]);
  const [isMultiplePostingSelected, setIsMultiplePostingSelected] = useState<boolean>(false);

  const isInvoice = history.location.pathname.includes('accounts-payable/requires-attention/invoice/');
  const isDebitNote = history.location.pathname.includes('/accounts-payable/requires-attention/debit-notes/');

  const fetchData = async (id: any) => {
    try {
      if (isInvoice) {
        const response = await getStagedPurchaseInvoiceDetails(id);
        setPurchaseInvoiceDetails(response);
      } else if (isDebitNote) {
        const response = await getStagedDebitNoteDetails(id);
        setDebitNoteDetails(response);
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  useEffect(() => {
    fetchData(invoiceID);
  }, [invoiceID]);

  const handleSelect = (id: any, code: string) => {
    setSelectedLineItemID(id);
    setFinancialBehaviour(true);
    setSelectedLineItemCode(code);
  };

  const handleAllInvoiceSelect = () => {
    if (
      (purchaseInvoiceDetails?.lines?.length === selectedInvoice.length && isInvoice) ||
      (debitNoteDetails?.lines?.length === selectedInvoice.length && isDebitNote)
    ) {
      setSelectedInvoice([]);
    } else if (isInvoice) {
      const checkList = purchaseInvoiceDetails?.lines.map(a => {
        return {id: a.stagingPurchaseInvoiceLineID, isInvoice: true};
      });
      setSelectedInvoice([...checkList!]);
    } else {
      const checkList = debitNoteDetails?.lines.map(a => {
        return {id: a.stagingDebitNoteLineID, isInvoice: false};
      });
      setSelectedInvoice([...checkList!]);
    }
  };

  const handleSelectInvoices = (id: number, isInvoice: boolean) => {
    const _idx = selectedInvoice.findIndex(a => a.id === id);
    if (_idx > -1) {
      const checkList = _.cloneDeep(selectedInvoice);
      checkList.splice(_idx, 1);
      setSelectedInvoice(checkList);
    } else {
      setSelectedInvoice([...selectedInvoice, {id, isInvoice}]);
    }
  };

  const leftPanel = (
    <>
      <div
        className={`relative mb-2.5 flex flex-col rounded bg-white p-2.5 font-poppins drop-shadow-[0_0px_6px_rgba(211,229,239,1)]`}
        style={{height: 'calc(100% - 240px)'}}
      >
        <div className={`mb-6 flex items-center justify-between border-[#D8D8D8] border-b-default pb-2.5`}>
          <div className="flex items-center justify-start">
            <span
              className="cursor-pointer"
              data-autoid="btnBack"
              onClick={() => {
                history.push(AUTH_BUYING_AP_REQUIRES_ATTENTION_ROUTE);
              }}
            >
              <img src={backButton} alt="Back Button" className="mr-2.5 h-10 w-10" data-autoid={`imgBack`} />
            </span>
            <h1 className="text-xxl text-left font-light leading-8 text-spenda-primarytext">Invoice 1622728</h1>
          </div>
          <div className="flex items-center justify-start">
            <APTooltip
              title={'Please select more than one invoice to add same inventory code'}
              placement="bottom"
              arrow
              disableHoverListener={selectedInvoice.length > 1}
            >
              <span>
                <SecondaryButton
                  label="Select Post as behaviour"
                  data-autoid="btnApproveInvoice"
                  disabled={selectedInvoice.length < 2}
                  onClick={() => {
                    setFinancialBehaviour(true);
                    setIsMultiplePostingSelected(true);
                  }}
                />
              </span>
            </APTooltip>
          </div>
        </div>

        <div className="">
          <span
            data-autoid={`lnkSelectAllToggler$`}
            className="flex items-center justify-start p-2.5 pt-0"
            onClick={() => handleAllInvoiceSelect()}
          >
            <SelectToggler
              dataAutoId="imgSelectAllToggler"
              className="!mr-2.5 min-w-[22px]"
              isAccountsPayable
              isSelected={
                isInvoice
                  ? purchaseInvoiceDetails?.lines?.length === selectedInvoice.length
                  : debitNoteDetails?.lines?.length === selectedInvoice.length
              }
            />
            <span>Select All</span>
          </span>
        </div>
        <TableContainer className={`${classes.tables} !w-full`}>
          <Table data-autoid={'tblInvoice'}>
            <TableHead>
              <TableRow>
                <TableCell align="left" style={{width: '3%'}}></TableCell>
                <TableCell align="left" style={{width: '10%'}}>
                  Code
                </TableCell>
                <TableCell align="left" style={{width: '23%'}}>
                  Description
                </TableCell>
                <TableCell align="center" style={{width: '11%'}}>
                  Post as
                </TableCell>
                <TableCell align="right" style={{width: '10%'}}>
                  Qty invoiced
                </TableCell>
                <TableCell align="right" style={{width: '10%'}}>
                  Qty received
                </TableCell>
                <TableCell align="right" style={{width: '12%'}}>
                  Unit price(inc tax)
                </TableCell>
                <TableCell align="right" style={{width: '12%'}}>
                  Line Total
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {purchaseInvoiceDetails?.lines?.map((pi: IStagedPurchaseInvoiceDetailLine, index: number) => {
                return (
                  <TableRow className="w-full bg-spenda-cream" key={index}>
                    <TableCell align="left">
                      <span
                        className="flex items-center justify-center"
                        onClick={() => handleSelectInvoices(pi.stagingPurchaseInvoiceLineID, true)}
                      >
                        <SelectToggler
                          dataAutoId="imgSelectAllToggler"
                          className="!m-0 min-w-[22px]"
                          isAccountsPayable
                          isSelected={_.some(selectedInvoice, o => o.id === pi.stagingPurchaseInvoiceLineID)}
                        />
                      </span>
                    </TableCell>
                    <TableCell className="!bg-white !p-2.5 text-xs !font-normal">
                      <span>{pi.code}</span>
                    </TableCell>
                    <TableCell className="bg-spenda-cream !p-2.5 text-xs !font-normal">
                      <span>{pi.description}</span>
                    </TableCell>
                    <TableCell align="center" className="!bg-white !p-2.5 text-sm !font-semibold !text-primary">
                      <span
                        className={`${selectedInvoice.length < 2 ? 'cursor-pointer' : ''}`}
                        onClick={() =>
                          selectedInvoice.length < 2 ? handleSelect(pi.stagingPurchaseInvoiceLineID, pi.code) : null
                        }
                      >
                        {purchaseInvoiceDetails.isSourceSupplierInventoryMappingEnabled && !pi.isInventoryMapped ? (
                          'Select'
                        ) : (
                          <span className="flex items-center justify-center">
                            {pi.postingInventoryCode}{' '}
                            {selectedInvoice.length < 2 && <Edit className="ml-2 !h-3 !w-3" />}
                          </span>
                        )}
                      </span>
                    </TableCell>
                    <TableCell align="right" className="bg-spenda-cream !p-2.5 text-xs !font-normal">
                      <span>{pi.quantity}</span>
                    </TableCell>
                    <TableCell align="right" className="!bg-white !p-2.5 text-xs !font-normal">
                      <span>-</span>
                    </TableCell>
                    <TableCell align="right" className="bg-spenda-cream !p-2.5 text-xs !font-normal">
                      <span>{PriceFormat(pi.buyPriceInc)}</span>
                    </TableCell>
                    <TableCell align="right" className="!bg-white !p-2.5 text-xs !font-normal">
                      <span>{PriceFormat(pi.lineTotalInc)}</span>
                    </TableCell>
                  </TableRow>
                );
              })}
              {debitNoteDetails?.lines?.map((dn: IStagedDebitNoteDetailLine, index: number) => {
                return (
                  <TableRow className="w-full" key={index}>
                    <TableCell align="left">
                      <span
                        className="flex items-center justify-center p-2.5"
                        onClick={() => handleSelectInvoices(dn.stagingDebitNoteLineID, false)}
                      >
                        <SelectToggler
                          dataAutoId="imgSelectAllToggler"
                          className="!m-0 min-w-[22px]"
                          isAccountsPayable
                          isSelected={_.some(selectedInvoice, o => o.id === dn.stagingDebitNoteLineID)}
                        />
                      </span>
                    </TableCell>
                    <TableCell className="!p-2.5 text-xs !font-normal">
                      <span>{dn.code}</span>
                    </TableCell>
                    <TableCell className="!p-2.5 text-xs !font-normal">
                      <span>{dn.description}</span>
                    </TableCell>
                    <TableCell align="center" className="!p-2.5 text-sm !font-semibold !text-primary">
                      <span
                        className={`${selectedInvoice.length < 2 ? 'cursor-pointer' : ''}`}
                        onClick={() =>
                          selectedInvoice.length < 2 ? handleSelect(dn.stagingDebitNoteLineID, dn.code) : null
                        }
                      >
                        {debitNoteDetails.isSourceSupplierInventoryMappingEnabled && !dn.isInventoryMapped ? (
                          'Select'
                        ) : (
                          <span className="flex items-center justify-center">
                            {dn.postingInventoryCode}{' '}
                            {selectedInvoice.length < 2 && <Edit className="ml-2 !h-3 !w-3" />}
                          </span>
                        )}
                      </span>
                    </TableCell>
                    <TableCell align="right" className="!p-2.5 text-xs !font-normal">
                      <span>{dn.quantity}</span>
                    </TableCell>
                    <TableCell align="right" className="!p-2.5 text-xs !font-normal">
                      <span>-</span>
                    </TableCell>
                    <TableCell align="right" className="!p-2.5 text-xs !font-normal">
                      <span>{PriceFormat(dn.buyPriceInc)}</span>
                    </TableCell>
                    <TableCell align="right" className="!p-2.5 text-xs !font-normal">
                      <span>{PriceFormat(dn.lineTotalInc)}</span>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className="relative mb-2.5 flex h-[220px] flex-col rounded bg-white p-2.5 font-poppins drop-shadow-[0_0px_6px_rgba(211,229,239,1)]">
        <TableContainer className={`${classes.tables} !w-full`}>
          <Table data-autoid={'tblInvoice'}>
            <TableBody>
              <TableRow className="w-full">
                <TableCell
                  rowSpan={3}
                  colSpan={5}
                  width="72%"
                  className="!border-transparent !bg-white !p-2.5 text-xs !font-normal"
                ></TableCell>
                <TableCell
                  align="right"
                  width="14%"
                  className="!border-[#EAEAEA] !bg-white !p-2.5 text-xs !font-semibold"
                >
                  <span>Subtotal</span>
                </TableCell>
                <TableCell
                  align="right"
                  width="14%"
                  className="!border-[#EAEAEA] !bg-white !p-2.5 text-base !font-semibold"
                >
                  <span>
                    {purchaseInvoiceDetails
                      ? PriceFormat(purchaseInvoiceDetails.totalEx)
                      : debitNoteDetails
                        ? PriceFormat(debitNoteDetails?.totalEx)
                        : ''}
                  </span>
                </TableCell>
              </TableRow>
              <TableRow className="w-full">
                <TableCell
                  align="right"
                  style={{width: '12%'}}
                  className="!border-[#EAEAEA] !bg-white !p-2.5 text-xs !font-semibold"
                >
                  <span>Gst</span>
                </TableCell>
                <TableCell
                  align="right"
                  style={{width: '12%'}}
                  className="!border-[#EAEAEA] !bg-white !p-2.5 text-base !font-semibold"
                >
                  <span>
                    {purchaseInvoiceDetails
                      ? PriceFormat(purchaseInvoiceDetails.totalTax)
                      : debitNoteDetails
                        ? PriceFormat(debitNoteDetails?.totalTax)
                        : ''}
                  </span>
                </TableCell>
              </TableRow>
              <TableRow className="w-full">
                <TableCell
                  align="right"
                  style={{width: '12%'}}
                  className="!border-[#EAEAEA] !bg-white !p-2.5 text-xs !font-semibold"
                >
                  <span>Total</span>
                </TableCell>
                <TableCell
                  align="right"
                  style={{width: '12%'}}
                  className="!border-[#EAEAEA] !bg-white !p-2.5 text-base !font-semibold"
                >
                  <span>
                    {purchaseInvoiceDetails
                      ? PriceFormat(purchaseInvoiceDetails?.totalInc)
                      : debitNoteDetails
                        ? PriceFormat(debitNoteDetails?.totalInc)
                        : ''}
                  </span>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );

  const handleDialogClose = () => {
    setFinancialBehaviour(false);
    setSelectedLineItemID(null);
    setIsMultiplePostingSelected(false);
    setSelectedInvoice([]);
  };

  return (
    <div className="relative h-full overflow-hidden bg-spenda-newbg">
      <Layout splitWidthType={4} leftPanel={leftPanel} />
      {financialBehaviour && (
        <FinancialSystemPostingBehaviourModal
          purchaseInvoiceDetails={purchaseInvoiceDetails}
          debitNoteDetails={debitNoteDetails}
          inventoryCode={selectedLineItemCode}
          selectedLineItemID={selectedLineItemID}
          handleClose={handleDialogClose}
          handleDone={() => {
            handleDialogClose();
            fetchData(invoiceID);
          }}
          isMultiplePostingSelected={isMultiplePostingSelected}
          selectedInvoices={selectedInvoice}
        />
      )}
    </div>
  );
};
