import {Grid} from '@material-ui/core';
import {FormikProps} from 'formik';
import React, {useState} from 'react';
import clsx from 'clsx';
import {useLocationAPI} from '../../services/useLocationAPI';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import {LocationAutoComplete} from '../location/LocationAutoComplete';
import {ILocation} from '../../model/address/Location';
import {IAddress} from '../../model/address/Address';
import googleImg from '../../assets/png/SalesOrder/powered-by-google.png';
import {Checkbox, Input, Switch, Typography} from 'spenda-ui-react';

export const AddLocationForm = (
  props: FormikProps<{Locations?: ILocation[]}> & {
    locationIndex: number;
    addressIndex: number;
    handleDefaultBilling: any;
    salesOrder?: boolean;
    valuesObject?: any;
    newUI?: boolean;
    isLatestDesign?: boolean;
  },
) => {
  const {getLocationAddress} = useLocationAPI();
  const {
    handleChange,
    values,
    setFieldValue,
    handleBlur,
    locationIndex,
    addressIndex,
    salesOrder,
    valuesObject,
    newUI,
    isLatestDesign,
  } = props;
  const [collapse, setCollapse] = useState(false);
  const index = locationIndex;

  return (
    <div className={newUI ? `relative mb-5 flex flex-col items-start justify-start py-4 font-poppins` : 'font-poppins'}>
      {newUI && (
        <div className="flex w-full justify-end pr-3">
          <Typography variant="h3" className={`text-#333 mr-3 font-poppins font-medium`}>
            Enter Manually
          </Typography>
          <Switch
            checked={collapse}
            onChange={() => setCollapse(!collapse)}
            name="advanced"
            id="advanced"
            ripple={false}
            containerProps={{'data-autoid': 'chkLocationManual'}}
          />
        </div>
      )}
      {salesOrder ? (
        <Grid className="flex" sm={12} container spacing={1} item={true}>
          {!newUI && (
            <Grid item sm={2}>
              <p className="text-#333333 mt-1 text-base">Location Name:</p>
            </Grid>
          )}
          <Grid item sm={newUI ? 6 : 3}>
            <Input
              id={`Locations.${index}.Name`}
              name={`Locations.${index}.Name`}
              key={`Locations.${index}.Name`}
              value={values?.Locations?.[index]?.Name}
              onBlur={handleBlur}
              onChange={handleChange}
              label={newUI ? 'Location Name' : undefined}
              variant={newUI ? 'outlined' : undefined}
            />
          </Grid>
        </Grid>
      ) : null}
      {!collapse ? (
        <Grid className={clsx('flex', {'px-5': newUI})} sm={12} container item={true}>
          {!newUI && (
            <Grid item sm={2}>
              <p className="text-#333333 mt-1 text-base">Address:</p>
            </Grid>
          )}
          <Grid item sm={newUI ? 12 : 7} className={'relative'}>
            {newUI ? (
              <div className="absolute ml-[776px] mt-2">
                <img width={122} height={17} src={googleImg} />
              </div>
            ) : null}
            <LocationAutoComplete
              isLatestDesign={isLatestDesign}
              carouselUI={isLatestDesign ? true : false}
              styles={{marginTop: newUI ? '0' : '-1.5rem'}}
              isSalesModule={newUI}
              selected={
                values?.Locations?.[index]?.Addresses?.[addressIndex]?.StreetAddress ||
                values?.Locations?.[index]?.Addresses?.[addressIndex]?.City ||
                values?.Locations?.[index]?.Addresses?.[addressIndex]?.State ||
                values?.Locations?.[index]?.Addresses?.[addressIndex]?.Country ||
                values?.Locations?.[index]?.Addresses?.[addressIndex]?.PostCode
                  ? {
                      PlaceDescription: `${
                        values?.Locations?.[index]?.Addresses?.[addressIndex]?.StreetAddress ?? ''
                      } ${values?.Locations?.[index]?.Addresses?.[addressIndex]?.City ?? ''} ${
                        values?.Locations?.[index]?.Addresses?.[addressIndex]?.State ?? ''
                      } ${values?.Locations?.[index]?.Addresses?.[addressIndex]?.PostCode ?? ''} ${
                        values?.Locations?.[index]?.Addresses?.[addressIndex]?.Country ?? ''
                      }`,
                    }
                  : null
              }
              clearClick={() => setFieldValue(`Locations.${index}.Addresses.${addressIndex}`, null)}
              onSuggestionSelected={(e, selected) => {
                getLocationAddress(selected.suggestion.PlaceID || '').then((res: IAddress) => {
                  if (res) {
                    setFieldValue(`Locations.${index}.Addresses.${addressIndex}`, {
                      StreetAddress: res.StreetAddress,
                      City: res.City,
                      State: res.State,
                      Country: res.Country,
                      PostCode: res.PostCode,
                      IsDefaultBilling: valuesObject?.[index]?.Addresses?.[addressIndex]?.IsDefaultBilling,
                      IsDefaultDeliverTo: valuesObject?.[index]?.Addresses?.[addressIndex]?.IsDefaultDeliverTo,
                      IsActive: true,
                      addressFlag: true,
                      ID: valuesObject?.[index]?.Addresses?.[addressIndex]?.ID,
                    });
                  }
                });
              }}
            />
          </Grid>
          {!newUI && (
            <Grid item sm={2} className="flex justify-end">
              <p
                className={`className="text-lg cursor-pointer" font-semibold text-primary`}
                onClick={() => setCollapse(!collapse)}
              >
                <>
                  Expand <KeyboardArrowDownIcon />
                </>
              </p>
            </Grid>
          )}
        </Grid>
      ) : null}
      {collapse ? (
        <Grid container className={clsx({'mt-12 px-[139px]': newUI})}>
          <Grid sm={12}>
            <Grid
              className={'flex'}
              sm={12}
              container
              spacing={newUI ? 0 : 1}
              item={true}
              style={{marginBottom: newUI ? '20px' : ''}}
            >
              {!newUI && (
                <Grid item sm={2}>
                  <p className="text-#333333 mt-1 text-base">Street Address:</p>
                </Grid>
              )}
              <Grid item sm={newUI ? 6 : 3} style={{padding: newUI ? '0px 12px 0px 0px' : undefined}}>
                <Input
                  id={`Locations.${index}.Addresses.${addressIndex}.StreetAddress`}
                  name={`Locations.${index}.Addresses.${addressIndex}.StreetAddress`}
                  key={`Locations.${index}.Addresses.${addressIndex}.StreetAddress`}
                  value={values?.Locations?.[index]?.Addresses?.[addressIndex]?.StreetAddress}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  label={newUI ? 'Street address' : undefined}
                  variant={newUI ? 'outlined' : undefined}
                />
              </Grid>
              {!newUI && (
                <>
                  {' '}
                  <Grid item sm={1} />
                  <Grid item sm={2}>
                    <p className="text-#333333 mt-1 text-base">City:</p>
                  </Grid>
                </>
              )}
              <Grid item sm={newUI ? 6 : 3} style={{padding: newUI ? '0px 12px 0px 0px' : undefined}}>
                <Input
                  id={`Locations.${index}.Addresses.${addressIndex}.City`}
                  name={`Locations.${index}.Addresses.${addressIndex}.City`}
                  key={`Locations.${index}.Addresses.${addressIndex}.City`}
                  value={values?.Locations?.[index]?.Addresses?.[addressIndex]?.City}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  label={newUI ? 'City' : undefined}
                  variant={newUI ? 'outlined' : undefined}
                />
              </Grid>
            </Grid>
            <Grid sm={12} container>
              <Grid className="flex" sm={newUI ? 8 : 12} container spacing={newUI ? 0 : 1} item={true}>
                {!newUI && (
                  <Grid item sm={2}>
                    <p className="text-#333333 mt-1 text-base">State:</p>
                  </Grid>
                )}
                <Grid item sm={newUI ? 6 : 3} style={{padding: newUI ? '0px 12px 0px 0px' : undefined}}>
                  <Input
                    id={`Locations.${index}.Addresses.${addressIndex}.State`}
                    name={`Locations.${index}.Addresses.${addressIndex}.State`}
                    key={`Locations.${index}.Addresses.${addressIndex}.State`}
                    value={values?.Locations?.[index]?.Addresses?.[addressIndex]?.State}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label={newUI ? 'State' : undefined}
                    variant={newUI ? 'outlined' : undefined}
                  />
                </Grid>
                {!newUI && (
                  <>
                    <Grid item sm={1} />
                    <Grid item sm={2}>
                      <p className="text-#333333 mt-1 text-base">Country:</p>
                    </Grid>
                  </>
                )}
                <Grid item sm={newUI ? 6 : 3} style={{padding: newUI ? '0px 12px 0px 0px' : undefined}}>
                  <Input
                    id={`Locations.${index}.Addresses.${addressIndex}.Country`}
                    name={`Locations.${index}.Addresses.${addressIndex}.Country`}
                    key={`Locations.${index}.Addresses.${addressIndex}.Country`}
                    value={values?.Locations?.[index]?.Addresses?.[addressIndex]?.Country}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label={newUI ? 'Country' : undefined}
                    variant={newUI ? 'outlined' : undefined}
                  />
                </Grid>
              </Grid>
              <Grid className="flex" sm={newUI ? 4 : 12} container spacing={newUI ? 0 : 1} item={true}>
                {!newUI && (
                  <Grid item sm={2}>
                    <p className="text-#333333 mt-1 text-base">Postcode:</p>
                  </Grid>
                )}
                <Grid item sm={newUI ? 12 : 3} style={{padding: newUI ? '0px 12px 0px 0px' : undefined}}>
                  <Input
                    id={`Locations.${index}.Addresses.${addressIndex}.PostCode`}
                    name={`Locations.${index}.Addresses.${addressIndex}.PostCode`}
                    key={`Locations.${index}.Addresses.${addressIndex}.PostCode`}
                    value={values?.Locations?.[index]?.Addresses?.[addressIndex]?.PostCode}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label={newUI ? 'Postcode' : undefined}
                    variant={newUI ? 'outlined' : undefined}
                  />
                </Grid>
                {!newUI && (
                  <Grid item sm={6} className="flex justify-end">
                    <p
                      className={`className="text-lg cursor-pointer" font-semibold text-primary`}
                      onClick={() => setCollapse(!collapse)}
                    >
                      <>
                        Collapse <KeyboardArrowUpIcon />
                      </>
                    </p>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : null}
      <Typography variant="h3" className=" mt-3 flex self-center text-spenda-labeltext">
        <b>*Postal Code and Country are mandatory</b>
      </Typography>
      {!salesOrder && props.handleDefaultBilling ? (
        <Grid className={`flex font-poppins text-base `} sm={12} container spacing={2} item={true}>
          <Grid item sm={3}>
            <div className={`mt-5 flex`}>
              <Checkbox
                ripple={false}
                label="Make Default Billing"
                checked={values?.Locations?.[index]?.Addresses?.[addressIndex]?.IsDefaultBilling}
                onChange={() => props.handleDefaultBilling(index, addressIndex, 'IsDefaultBilling')}
                className="h-4 w-4 border-primary bg-white transition-all checked:border-primary checked:bg-primary hover:before:opacity-0"
              />
            </div>
          </Grid>
          <Grid item sm={3}>
            <div className="mt-5 flex">
              <Checkbox
                ripple={false}
                label="Make Default Shipping"
                checked={values?.Locations?.[index]?.Addresses?.[addressIndex]?.IsDefaultDeliverTo}
                onChange={() => props.handleDefaultBilling(index, addressIndex, 'IsDefaultDeliverTo')}
                className="h-4 w-4 border-primary bg-white transition-all checked:border-primary checked:bg-primary hover:before:opacity-0"
              />
            </div>
          </Grid>
        </Grid>
      ) : null}
    </div>
  );
};
