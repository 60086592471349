import React from 'react';
import {Box, Grid, makeStyles} from '@material-ui/core';
import {css} from 'glamor';
import {IHeadCell, STableInvoices} from '../../tables/STableInvoices';
import moment from 'moment';
import {PriceFormat, NameInitials} from '../../../utils/formatter';
import {Link, useParams} from 'react-router-dom';
import {DatTypes, InvoicePaymentAllocationStatus} from '../../../model/constants/Constants';
import {
  ICreditNotesSummary,
  IInvoicePaymentAllocation,
  IInvoicesSummary,
  IPaymentAllocationSummary,
} from '../../../model/invoice/InvoicePaymentAllocation';
import {AUTH_SELLING_COLLECT_ROUTE} from '../../../routes/AuthenticatedRoutes';
import {ISupplierWhoRaisedInvoice} from '../../../model/supplier/SupplierWhoRaisedInvoice';
import {LogoTypeEnum} from '../../../model/Tenant';
import Skeleton from 'react-loading-skeleton';

export const BatchPaymentHistoryView = (props: {
  tx: IInvoicePaymentAllocation;
  supplier?: ISupplierWhoRaisedInvoice;
}) => {
  const {
    invoicesSummary: invoices,
    creditNotesSummary,
    invoicePaymentAllocation: summary,
    paymentAllocations,
  } = props?.tx || {};

  const {supplierId, customerId} = useParams<{supplierId?: string; customerId?: string}>();

  const linkPathName = (transactionId: number) => {
    if (supplierId) {
      return `/supplier/${supplierId}/bills/${transactionId}`;
    } else {
      return `${AUTH_SELLING_COLLECT_ROUTE}/${customerId}/${transactionId}`;
    }
  };

  const invoiceHead: IHeadCell<IInvoicesSummary>[] = [
    {
      id: 'invoiceDate',
      numeric: false,
      disablePadding: true,
      sort: true,
      label: 'Date',
      align: 'left',
      render: (row: IInvoicesSummary) => {
        return <span>{moment(row?.invoiceDate).format('DD/MM/YYYY')}</span>;
      },
    },
    {
      id: 'refNumber',
      numeric: true,
      disablePadding: true,
      sort: true,
      label: 'Invoice Ref No.',
      align: 'left',
      linked: true,
      style: {
        innerWidth: '4rem',
      },
      render: (row: IInvoicesSummary) => {
        return (
          <Link
            className="text-primary underline"
            to={{
              pathname: linkPathName(row?.invoiceID),
              search: `?txGuid=${row?.invoiceGUID}&txDatType=${DatTypes.Invoice}`,
            }}
          >
            {row.refNumber}
          </Link>
        );
      },
    },
    {
      id: 'customer_Vendor',
      numeric: true,
      disablePadding: false,
      sort: true,
      label: 'Vendor',
      align: 'left',
      style: {
        maxWidth: '10rem',
      },
    },
    {
      id: 'vendor_RefNumber',
      numeric: true,
      disablePadding: false,
      label: 'Vendor Ref No.',
      align: 'center',
    },
    {
      id: 'totalInc',
      numeric: true,
      disablePadding: false,
      label: 'Totals',
      align: 'center',
      render: (row: IInvoicesSummary) => {
        return <span>{PriceFormat(Number(row?.totalInc))}</span>;
      },
    },
    {
      id: 'applied_Payments',
      numeric: true,
      disablePadding: false,
      label: 'Payments',
      align: 'right',
      render: (row: IInvoicesSummary) => {
        return <span>{PriceFormat(Number(row?.applied_Payments))}</span>;
      },
    },
    {
      id: 'applied_Credits',
      numeric: true,
      disablePadding: false,
      label: 'Credits',
      align: 'right',
      render: (row: IInvoicesSummary) => {
        return <span>{PriceFormat(Number(row?.applied_Credits))}</span>;
      },
    },
    {
      id: 'balance',
      numeric: true,
      disablePadding: false,
      label: 'Balance',
      align: 'right',
      render: (row: IInvoicesSummary) => {
        return <span>{PriceFormat(Number(row?.balance))}</span>;
      },
    },
  ];

  const creditHead: IHeadCell<ICreditNotesSummary>[] = [
    {
      id: 'creditNoteDate',
      numeric: false,
      disablePadding: true,
      sort: true,
      label: 'Date',
      align: 'left',

      render: (row: ICreditNotesSummary) => {
        return <span>{moment(row?.creditNoteDate).format('DD/MM/YYYY')}</span>;
      },
    },
    {
      id: 'refNumber',
      numeric: true,
      disablePadding: true,
      sort: true,
      label: 'Ref No.',
      align: 'left',
      linked: true,
      render: (row: ICreditNotesSummary) => {
        return (
          <Link
            className="text-primary underline"
            to={{
              pathname: linkPathName(row?.creditNoteID),
              search: `?txGuid=${row?.creditNoteGUID}&txDatType=${DatTypes.CreditNote}`,
            }}
          >
            {row.refNumber}
          </Link>
        );
      },
    },
    {
      id: 'customer_Vendor',
      numeric: true,
      disablePadding: false,
      sort: true,
      label: 'Vendor',
      align: 'left',
      style: {
        maxWidth: '10rem',
      },
    },
    {id: 'vendor_RefNumber', numeric: true, disablePadding: false, label: 'Vendor Ref No.', align: 'center'},
    {
      id: 'totalInc',
      numeric: true,
      disablePadding: false,
      label: 'Starting Balance',
      align: 'center',
      render: (row: ICreditNotesSummary) => {
        return <span>{PriceFormat(Number(row?.totalInc))}</span>;
      },
    },
    {
      id: 'credits',
      numeric: true,
      disablePadding: false,
      label: 'Credits',
      align: 'right',
      render: (row: ICreditNotesSummary) => {
        return <span>{PriceFormat(Number(row?.credits))}</span>;
      },
    },
    {
      id: 'refNumber',
      numeric: true,
      disablePadding: true,
      label: 'Invoice Ref No.',
      align: 'right',
      linked: true,
      style: {
        innerWidth: '3rem',
      },
      render: (row: ICreditNotesSummary) => {
        const invGuid = invoices.find((a: IInvoicesSummary) => a.refNumber === row.invoice_RefNumber)?.invoiceGUID;
        const invId = invoices.find((a: IInvoicesSummary) => a.refNumber === row.invoice_RefNumber)?.invoiceID;
        return (
          <Link
            className="flex justify-end text-primary underline"
            to={{
              pathname: linkPathName(invId as number),
              search: `?txGuid=${invGuid}&txDatType=${DatTypes.Invoice}`,
            }}
          >
            {row.invoice_RefNumber}
          </Link>
        );
      },
    },
    {
      id: 'balance',
      numeric: true,
      disablePadding: false,
      label: 'Balance',
      align: 'right',
      render: (row: ICreditNotesSummary) => {
        return <span>{PriceFormat(Number(row?.balance))}</span>;
      },
    },
  ];
  const paymentsHead: IHeadCell<IPaymentAllocationSummary>[] = [
    {
      id: 'paymentDate',
      numeric: false,
      disablePadding: true,
      sort: true,
      label: 'Date',
      align: 'left',

      render: (row: IPaymentAllocationSummary) => {
        return <span>{moment(row?.paymentDate).format('DD/MM/YYYY')}</span>;
      },
    },
    {
      id: 'refNumber',
      numeric: true,
      disablePadding: true,
      sort: true,
      label: 'Ref No.',
      align: 'left',
      linked: true,
      render: (row: IPaymentAllocationSummary) => {
        return (
          <Link
            className="text-primary underline"
            to={{
              pathname: linkPathName(row?.paymentID),
              search: `?txGuid=${row?.paymentGUID}&txDatType=${DatTypes.CreditNote}`,
            }}
          >
            {row.refNumber}
          </Link>
        );
      },
      grouped: true,
    },
    {
      id: 'paymentMethod',
      numeric: true,
      disablePadding: false,
      sort: true,
      label: 'Payment Method',
      align: 'left',
      style: {
        maxWidth: '10rem',
      },
    },
    {
      id: 'payerFeeAmount',
      numeric: true,
      disablePadding: false,
      label: 'Processing Fee',
      align: 'center',
      render: (row: IPaymentAllocationSummary) => {
        return <span>{row?.payerFeeAmount ? PriceFormat(Number(row?.payerFeeAmount)) : null}</span>;
      },
    },
    {
      id: 'totalInc',
      numeric: true,
      disablePadding: false,
      label: 'Starting Balance',
      align: 'center',
      render: (row: IPaymentAllocationSummary) => {
        return <span>{PriceFormat(Number(row?.totalInc))}</span>;
      },
    },
    {
      id: 'credits',
      numeric: true,
      disablePadding: false,
      label: 'Applied Amount',
      align: 'right',
      render: (row: IPaymentAllocationSummary) => {
        return <span>{PriceFormat(Number(row?.credits))}</span>;
      },
    },
    {
      id: 'refNumber',
      numeric: true,
      disablePadding: true,
      label: 'Invoice Ref No.',
      align: 'right',
      linked: true,
      style: {
        innerWidth: '3rem',
      },
      render: (row: IPaymentAllocationSummary) => {
        const invGuid = invoices.find((a: IInvoicesSummary) => a.refNumber === row.invoice_RefNumber)?.invoiceGUID;
        const invId = invoices.find((a: IInvoicesSummary) => a.refNumber === row.invoice_RefNumber)?.invoiceID;
        return (
          <Link
            className="flex justify-end text-primary underline"
            to={{
              pathname: linkPathName(invId as number),
              search: `?txGuid=${invGuid}&txDatType=${DatTypes.Invoice}`,
            }}
          >
            {row.invoice_RefNumber}
          </Link>
        );
      },
    },
    {
      id: 'balance',
      numeric: true,
      disablePadding: false,
      label: 'Balance',
      align: 'right',
      render: (row: IPaymentAllocationSummary) => {
        return <span>{PriceFormat(Number(row?.balance))}</span>;
      },
    },
  ];

  const headerTopStyle = css({
    borderRadius: '1rem 0 1rem 1rem',
    '&:after': {
      content: ' ',
      position: 'absolute',
      top: '0',
      right: '0',
      borderWidth: '32px 0px 0px 32px',
      borderStyle: 'solid',
      borderColor: `#f4f8fb #fff #ffffff00 #d8d8d800`,
      backgroundColor: '#E1E1E1',
      display: 'block',
      width: 0,
      WebkitBorderBottomLeftRadius: '10px',
      overflowY: 'auto',
      borderRadius: '0 0 0 10px',
    },
  });

  const customScrollBarStyle = css({
    '::-webkit-scrollbar': {
      width: '22px',
    },
    '::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
      marginTop: '0.75rem',
      marginBottom: '1.25rem',
    },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: '#CCE6F1',
      borderRadius: '20px',
      border: '6px solid transparent',
      backgroundClip: 'content-box',
    },
    '::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#1C78AD',
    },
  });

  const transactionStatus = (status: string) => {
    switch (status) {
      case 'Paid':
        return 'PAID';
      case 'InProgress':
        return 'IN PROGRESS';
      default:
        return status;
    }
  };

  let statusColor: string = '';
  let statusBorderColor: string = '';
  switch (summary?.status) {
    case InvoicePaymentAllocationStatus.Cleared:
    case InvoicePaymentAllocationStatus.Paid:
      statusColor = '#3C9F78';
      statusBorderColor = '#3C9F7830';
      break;
    case InvoicePaymentAllocationStatus.InProgress:
      statusColor = '#C68A19';
      statusBorderColor = '#C68A1930';
      break;
    case InvoicePaymentAllocationStatus.Failed:
    case InvoicePaymentAllocationStatus.Cancelled:
      statusColor = '#B9624B';
      statusBorderColor = '#B9624B30';
      break;
    default:
      break;
  }

  const paidBorderStyle = css({
    backgroundColor: '#fff',
    borderColor: statusBorderColor,
    color: statusColor,
    justifyContent: 'center',
    minWidth: '10rem',
    minHeight: '3.5rem',
    borderRadius: '14px',
    alignItems: 'center',
  });

  const useBatchPaymentStyles = makeStyles({
    invAllSummary: {
      backgroundColor: '#FAFAFA',
      borderColor: '#E1E1E1',
      borderRadius: '4px',
      padding: '12px 10px 10px 10px',
      lineHeight: '1.25rem',
    },
    headerContainer: {
      padding: '8px 8px 10px 0px',
      display: 'flex',
      justifyContent: 'space-between',
    },
    poppins: {
      fontFamily: 'Poppins, sans-serif !important',
    },
  });

  const classes = useBatchPaymentStyles();
  const LogoURI = props?.supplier?.Logos.find(x => x.LogoTypeID.toString() === LogoTypeEnum.Logo)?.URI;

  return (
    <header className={`relative h-full bg-white ${classes.poppins} ${headerTopStyle}`}>
      <div className={`h-full overflow-y-auto  pb-20 pt-8 sm:px-3  ${customScrollBarStyle}`}>
        <Grid container sm={12} className={classes.headerContainer}>
          <Grid item sm={5}>
            {LogoURI ? (
              <img src={LogoURI} style={{maxWidth: '100px', maxHeight: '100px'}} />
            ) : props?.supplier?.Name ? (
              <p
                {...css({
                  backgroundColor: '#b8b046',
                  color: 'black',
                  border: '1px solid #fff',
                  width: '100px',
                  height: '100px',
                })}
                className="relative ml-4 flex h-8 w-8 items-center justify-center rounded-full pt-1 text-center text-5xl"
              >
                {NameInitials(props?.supplier?.Name)?.toUpperCase()}
              </p>
            ) : (
              <Skeleton circle={true} height={'6rem'} width={'6rem'} />
            )}
          </Grid>
          <Grid container item sm={7} direction="column">
            <Grid className="mr-1 flex justify-end text-base font-bold">
              <span>{'Invoice Allocation Report'}</span>
            </Grid>
            <Grid className="flex justify-end text-base font-bold">
              <div className={`flex  border-4 ${paidBorderStyle} my-2 `}>
                <h1 className="px-2 text-lg font-medium uppercase">{transactionStatus(summary?.status)}</h1>
              </div>
            </Grid>
            <Grid className="mr-1 flex justify-end text-xs font-medium">
              <span>Allocation #: {summary?.refNumber}</span>
            </Grid>
            <Grid className="mr-1 flex justify-end text-xs font-medium">
              <span>Date: {moment(summary?.paymentDate).format('Do MMM YYYY')}</span>
            </Grid>
            <Grid className="mr-1 flex justify-end text-xs font-medium">
              <span>Customer: {summary?.accountCustomerName}</span>
            </Grid>
            <Grid className="mr-1 flex justify-end text-xs font-medium">
              <span>Supplier: {summary?.supplierName}</span>
            </Grid>
          </Grid>
        </Grid>

        <hr />
        <Box mt={1}>
          <h2 className="mb-1 text-base font-medium">Invoice Allocation Summary</h2>
          <Grid container sm={12} className={classes.invAllSummary}>
            <Grid container item sm={4} direction="column">
              <Grid className="flex justify-between">
                <span className="text-xs font-medium">No. of Invoices:</span>
                <span className="text-xs font-semibold">{summary?.invoice_Count}</span>
              </Grid>
              <Grid className="flex justify-between">
                <span className="text-xs font-medium">No. of Credit Notes:</span>
                <span className="text-xs font-semibold">{summary?.creditNote_Count}</span>
              </Grid>
              <Grid className="flex justify-between">
                <span className="text-xs font-medium">No. of Claims Requested:</span>
                <span className="text-xs font-semibold">{summary?.claim_Count}</span>
              </Grid>
              <Grid className="flex justify-between">
                <span className="text-xs font-medium">No. of Payments Applied:</span>
                <span className="text-xs font-semibold">{summary?.payment_Count}</span>
              </Grid>
            </Grid>
            <Grid sm={2}></Grid>
            <Grid container item sm={6} direction="column">
              <Grid className="flex justify-between">
                <span className="text-xs font-medium">Outstanding Invoice Balance:</span>
                <span className="text-xs font-semibold">{PriceFormat(summary?.outstandingInvoiceBalance)}</span>
              </Grid>
              <Grid className="flex justify-between">
                <span className="text-xs font-medium">Less Credits:</span>
                <span className="text-xs font-semibold">({PriceFormat(summary?.appliedCredits)})</span>
              </Grid>
              <Grid className="flex justify-between">
                <span className="text-xs font-medium">(Credits Requested)</span>
                <span className="text-xs font-semibold">({PriceFormat(summary?.requestedClaims)})</span>
              </Grid>
              <Grid className="flex justify-between">
                <span className="text-xs font-medium">Less Payments:</span>
                <span className="text-xs font-semibold">({PriceFormat(summary?.appliedPayments)})</span>
              </Grid>
              <Grid className="flex justify-between">
                <span className="text-xs font-bold">Balance:</span>
                <span className="text-xs font-bold">{PriceFormat(summary?.balance)}</span>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box my={1}>
          <h2 className="mb-1 text-base font-medium">Invoice Allocations</h2>
          <STableInvoices<IInvoicesSummary> headCells={invoiceHead} rows={invoices} orderBy={'refNumber'} />
        </Box>
        {paymentAllocations?.length ? (
          <Box my={2}>
            <h2 className="mb-1 text-base font-medium">Payment Allocations</h2>
            <STableInvoices<IPaymentAllocationSummary>
              headCells={paymentsHead}
              rows={paymentAllocations}
              orderBy={'refNumber'}
            />
          </Box>
        ) : null}
        {creditNotesSummary?.length ? (
          <Box mb={6}>
            <h2 className="mb-1 text-base font-medium">Credit Note Allocations</h2>
            <STableInvoices<ICreditNotesSummary>
              headCells={creditHead}
              rows={creditNotesSummary}
              orderBy={'refNumber'}
            />
          </Box>
        ) : null}
      </div>
    </header>
  );
};
