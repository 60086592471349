import React from 'react';
//Icon
const IconSearch = (props: SVGProps) => {
  return (
    <svg
      width={props.width || '22px'}
      height={props.height || '22px'}
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>Search</title>
      <g id="Icons/General/search" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <path
          d="M98.9125922,93.0209256 L74.0417589,68.1500922 C88.1181472,50.9342721 86.2225352,25.7047567 69.7316948,10.7855961 C53.2408544,-4.13356457 27.9481308,-3.5011671 12.2234819,12.2234819 C-3.5011671,27.9481308 -4.13356457,53.2408544 10.7855961,69.7316948 C25.7047567,86.2225352 50.9342721,88.1181472 68.1500922,74.0417589 L93.0209256,98.9125922 C94.6558376,100.491643 97.2546121,100.46906 98.8618363,98.8618363 C100.46906,97.2546121 100.491643,94.6558376 98.9125922,93.0209256 Z M41.8000922,75.1334256 C23.3906006,75.1334256 8.4667589,60.2095839 8.4667589,41.8000922 C8.4667589,23.3906006 23.3906006,8.4667589 41.8000922,8.4667589 C60.2095839,8.4667589 75.1334256,23.3906006 75.1334256,41.8000922 C75.1127594,60.2010169 60.2010169,75.1127594 41.8000922,75.1334256 L41.8000922,75.1334256 Z"
          id="Shape"
          fill={props.fill || 'hsl(var(--primary))'}
        />
      </g>
    </svg>
  );
};

export default IconSearch;
