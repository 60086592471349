import React from 'react';
import {ARTable} from '../../../components/AccountsReceivable/ARTable';
import {Typography} from 'spenda-ui-react';

export const SettlementView = () => {
  const rows = {
    date: '11-11-11',
    numberOfSales: '20',
    mid: '355',
    totalSales: '$300',
    numberOfRefunds: '300',
    totalRefunds: '$400',
    settlement: '$50000',
  };

  const columns = [
    {
      title: 'Date',
      key: 'date',
      width: '10%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: false,
      rowRenderer: () => (
        <Typography data-autoid={`lblTransDate`} variant="paragraph" className="inline overflow-ellipsis font-medium">
          {rows?.date}
        </Typography>
      ),
    },
    {
      title: 'Number of sales',
      key: 'numberOfSales',
      width: '15%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: false,
      rowRenderer: () => (
        <Typography data-autoid={`lblTransDate`} variant="paragraph" className="inline overflow-ellipsis font-medium">
          {rows?.numberOfSales}
        </Typography>
      ),
    },
    {
      title: 'MID',
      key: 'mid',
      width: '20%',
      align: 'center',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: false,
      rowRenderer: () => (
        <Typography data-autoid={`lblTransDate`} variant="paragraph" className="inline overflow-ellipsis font-medium">
          {rows?.mid}
        </Typography>
      ),
    },
    {
      title: 'Total sales',
      key: 'totalSales',
      width: '15%',
      align: 'center',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: false,
      rowRenderer: () => (
        <Typography data-autoid={`lblTransDate`} variant="paragraph" className="inline overflow-ellipsis font-medium">
          {rows?.totalSales}
        </Typography>
      ),
    },
    {
      title: 'Number of refunds',
      key: 'numberOfRefunds',
      width: '15%',
      align: 'center',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: false,
      rowRenderer: () => (
        <Typography data-autoid={`lblTransDate`} variant="paragraph" className="inline overflow-ellipsis font-medium">
          {rows?.numberOfRefunds}
        </Typography>
      ),
    },
    {
      title: 'Total refunds',
      key: 'totalRefunds',
      width: '10%',
      align: 'right',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: false,
      rowRenderer: () => (
        <Typography data-autoid={`lblTransDate`} variant="paragraph" className="inline overflow-ellipsis font-medium">
          {rows?.totalRefunds}
        </Typography>
      ),
    },
    {
      title: 'Settlement',
      key: 'settlement',
      width: '15%',
      align: 'right',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: false,
      rowRenderer: () => (
        <Typography data-autoid={`lblTransDate`} variant="paragraph" className="inline overflow-ellipsis font-medium">
          {rows?.settlement}
        </Typography>
      ),
    },
  ];

  return (
    <>
      <div className="flex h-full !w-full items-start justify-start rounded-[8px] bg-white pt-2.5">
        <ARTable
          isHighlightRowOnHover
          tableClass="mb-12 !mx-0"
          rows={[rows]}
          columns={columns}
          isLoading={false}
          isPaginated
          dataAutoId="SettlementView"
          getRowDataAutoId={rowData => `rowSettlementView${rowData?.refNumber}`}
        />
        <div className="absolute left-2.5 top-2.5 flex">June</div>
      </div>
    </>
  );
};
