import React from 'react';
import {Box, makeStyles} from '@material-ui/core';
import {SButton} from '../buttons/Button';
import NextArrowIcon from '../../assets/svg/next-arrow.svg';
import {IMasterAdaptor} from '../../model/MasterAdaptor';
import _ from 'lodash';
import BackIcon from '../../assets/svg/back.svg';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import LoadingIndicator from '../ui/LoadingIndicator';
import {SModal} from '../modals/modalSpendaMeterialUI';
import {ChatWidget} from './ChatWidget';
import MyobLogo from '../../assets/png/MyObAccountRightLive-logo.png';
import QuickBookLogo from '../../assets/png/QuickBooksOnline-logo.png';
import XeroLogo from '../../assets/png/xero-logo.png';
import CloseIcon from '@material-ui/icons/Close';
import {GetBackgroundCSS} from './Onboarding';
import {APFooter} from '../accountsPayableOnboarding/APFooter';

const useSelectAccountingSystemStyles = makeStyles({
  apOndoardingTitle: {
    fontFamily: 'Poppins, sans-serif !important',
    fontSize: '28px',
  },
  descriptionNote: {
    fontFamily: 'Poppins, sans-serif !important',
    color: '#707070',
    fontSize: '12px',
  },
  nextBtn: {
    fontFamily: 'Poppins, sans-serif !important',
    position: 'absolute',
    bottom: '0px',
  },
  apCloudBox: {
    fontFamily: 'Poppins, sans-serif !important',
    '& .text-base': {
      fontSize: '14px',
    },
  },
});
interface SelectAccountingSystemProps {
  onSelectAccountingSystem: (account: IMasterAdaptor) => void;
  accounts?: IMasterAdaptor[];
  selectedAccount?: IMasterAdaptor;
  onNextClick: () => void;
  onBackPress?: () => void;
  isAccountSystemLoading?: boolean;
  selectedAccountMasterAdaptorId: number | undefined;
  handleClose: () => void;
  isBuyer: boolean;
  isAPOnboarding?: boolean;
  onCancelClick?: () => void;
  apQboIntegration?: boolean;
}

export const SelectAccountingSystem: React.FunctionComponent<SelectAccountingSystemProps> = ({
  onNextClick,
  accounts,
  selectedAccount,
  onSelectAccountingSystem,
  onBackPress,
  isAccountSystemLoading: isLoading,
  selectedAccountMasterAdaptorId,
  handleClose,
  isBuyer,
  isAPOnboarding,
  onCancelClick,
  apQboIntegration,
}: SelectAccountingSystemProps) => {
  const classes = useSelectAccountingSystemStyles();

  return (
    <SModal open>
      <div className={`text-xl spenda-color flex justify-center items-center h-full max-w-full ${GetBackgroundCSS()}`}>
        <div className={`max-w-3xl modal-box ${isAPOnboarding ? 'px-3 py-3 ' : 'px-5 py-5 select-account-modal'}`}>
          {isAPOnboarding && (
            <Box className="flex justify-between">
              <Box className="text-right w-full flex justify-end">
                <span className="ap-onboarding-close-icon" onClick={handleClose} data-autoid="btnClose">
                  <CloseIcon />
                </span>
              </Box>
            </Box>
          )}

          {isBuyer && !isAPOnboarding && (
            <span className="closeIcon onboarding-close-icon" onClick={handleClose} data-autoid="btnClose">
              <CloseIcon />
            </span>
          )}
          {!isAPOnboarding && (
            <span className="cursor-pointer" onClick={onBackPress} style={{position: 'relative'}} data-autoid="btnBack">
              <img src={BackIcon} alt="Next Arrow Icon" className="back-btn-bg-image" />
              <ChevronLeftIcon className="back-btn-icon" />
            </span>
          )}
          {isAPOnboarding ? (
            <p className={`${classes.apOndoardingTitle} text-center w-9/12 mx-auto mt-16`}>
              “Great! Let’s integrate your financial software?”
            </p>
          ) : (
            <p id="select-system-title" className="simple-modal-title text-center pb-3 mx-10 mb-2 -mt-5">
              "Great, now please select the cloud accounting system you use so we can integrate your transactions with
              Spenda."
            </p>
          )}
          <div className={`${isAPOnboarding ? 'mt-2' : 'mt-4'} flex row justify-center`}>
            <div className="column justify-center">
              {isAPOnboarding ? (
                <p className={`${classes.descriptionNote} text-gray-600 mb-6 text-center text-xs`}>
                  Select from below and click ‘Next’ to continue, or you can skip it for later
                </p>
              ) : (
                <p
                  id="select-system-sm-description"
                  className="simple-modal-description-note text-gray-600 mb-1 text-center text-xs"
                >
                  Select from below, and click <span className="font-semibold"> 'Next'</span> to continue
                </p>
              )}
              <div className={`flex row ${isAPOnboarding ? 'm-0' : 'm-2'}`}>
                {accounts &&
                  accounts.map((account, index) => {
                    return (
                      <React.Fragment key={index}>
                        <SButton
                          color={'lightBlue'}
                          textColor={'blue'}
                          width="m"
                          lineHeight="m"
                          fontSize="s"
                          boxShadow="none"
                          padding="6px 16px!important"
                          backgroundColor="#EFF3F6"
                          borderRadius={isAPOnboarding ? '6px!important' : '10px'}
                          disabled={
                            selectedAccountMasterAdaptorId &&
                            account.MasterAdaptorID !== selectedAccount?.MasterAdaptorID
                              ? true
                              : false
                          }
                          variant={
                            account.MasterAdaptorID === selectedAccount?.MasterAdaptorID ? 'outlined' : 'contained'
                          }
                          border={
                            account.MasterAdaptorID === selectedAccount?.MasterAdaptorID
                              ? isAPOnboarding
                                ? '2px solid #84b6d3'
                                : '2px solid #1C78AD'
                              : '2px solid transparent'
                          }
                          onClick={() => onSelectAccountingSystem(account)}
                          className={
                            selectedAccountMasterAdaptorId &&
                            account.MasterAdaptorID !== selectedAccount?.MasterAdaptorID
                              ? 'opacity-50 cloud-app-box'
                              : 'cloud-app-box'
                          }
                          margin={isAPOnboarding && apQboIntegration ? '10px' : 'auto'}
                          data-autoid={`btnAccount${(account.Name || '').replace(/\s/g, '')}`}
                        >
                          <div className="flex flex-col justify-center items-center pb-1 pr-1 pl-1 pt-1 text-base">
                            {account.Name === 'MyObAccountRightLive' && (
                              <div className="account-img-col">
                                <img style={{height: '40px', width: '100px'}} src={MyobLogo} alt="Account" />
                              </div>
                            )}
                            {account.Name === 'QuickBooksOnline' && (
                              <div className="account-img-col">
                                <img style={{height: '40px', width: '40px'}} src={QuickBookLogo} alt="Account" />
                              </div>
                            )}
                            {account.Name === 'Xero' && (
                              <div className="account-img-col">
                                <img style={{height: '54px', width: '54px'}} src={XeroLogo} alt="Account" />
                              </div>
                            )}
                            {account.Name}
                          </div>
                        </SButton>
                        {!isAPOnboarding && <div className="mr-5"></div>}
                      </React.Fragment>
                    );
                  })}
              </div>
            </div>
          </div>
          {!isAPOnboarding && (
            <p
              id="select-system-description"
              className="simple-modal-description text-center text-lg sm-description my-6 text-gray-600 py-3 mx-10"
            >
              Spenda requires integration with a cloud accounting application. This integration allows Spenda to post
              sales, purchases or payments to your accounting system.
            </p>
          )}
          {!isAPOnboarding && (
            <>
              <Box display="flex" justifyContent="center">
                <SButton
                  className={'common-next-btn'}
                  type="submit"
                  width={'m'}
                  color={'blueShade'}
                  lineHeight={'m'}
                  fontSize={'m'}
                  disabled={(_.isEmpty(selectedAccount) ? true : false) || isLoading}
                  onClick={() => onNextClick()}
                  data-autoid="btnNext"
                >
                  Next{' '}
                  {isLoading ? (
                    <LoadingIndicator isLoading={isLoading} size="sm" color="#FFF" position={{right: '5px'}} />
                  ) : isAPOnboarding ? null : (
                    <img src={NextArrowIcon} alt="Next Arrow Icon" className="" data-autoid="imgNextArrow" />
                  )}
                </SButton>
              </Box>
              <div className="live-chat-section">
                <ChatWidget />
              </div>
            </>
          )}
          {isAPOnboarding && (
            <APFooter
              onNextClick={onNextClick}
              isCancelButton={true}
              secondaryButtonLabel="Skip"
              handleCancelClick={onCancelClick}
              isLoading={isLoading}
            />
          )}
        </div>
      </div>
    </SModal>
  );
};
