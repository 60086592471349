import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Button} from 'spenda-ui-react';
import {AUTH_INVENTORY_MANAGE_CATALOGUE} from '../../../../routes/InventoryRoutes';
import {SendCatalogueDialog} from './SendCatalogueDialog';

export const CreateCatalogueFooter = () => {
  const [showSendCatalogueDialog, setShowSendCatalogueDialog] = useState<boolean>(false);

  const handleCancel = () => {
    setShowSendCatalogueDialog(false);
  }
  const history = useHistory();

  const handleSaveClose = () => {
    history.push(AUTH_INVENTORY_MANAGE_CATALOGUE);
  };

  return (
    <>
      <footer
        className={`fixed bottom-2.5 left-[22px] flex w-[calc(100%-44px)] items-center justify-between rounded-lg bg-[#ECECEC]`}
      >
        <div className="m-2.5 flex gap-x-2.5">
          <Button variant="outlined" className="bg-[#FFFFFF]">
            Cancel
          </Button>
          <Button variant="outlined" className="bg-[#FFFFFF]">
            View History Logs
          </Button>
        </div>
        <div className="m-2.5 flex gap-x-2.5">
          <Button onClick={handleSaveClose}>Save & Close</Button>
          <Button onClick={() => setShowSendCatalogueDialog(true)} type="submit">
            Send Catalogue
          </Button>
        </div>
      </footer>
      {showSendCatalogueDialog && <SendCatalogueDialog handleCancel={handleCancel}/>}
    </>
  );
};
