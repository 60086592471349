import React, {FunctionComponent, ChangeEvent} from 'react';
import {IRequisitionLine} from '../../model/requisition/Requisition';
import {DeleteOutline} from '@material-ui/icons';
import {css} from 'glamor';
import {PriceFormat} from '../../utils/formatter';

interface TransactionLineItemProps {
  line: IRequisitionLine; // TODO: This should be a generic transaction ITrans
  onChangeQuantity: (e: ChangeEvent<HTMLInputElement>, line: IRequisitionLine) => void;
  onRemoveLine: (line: IRequisitionLine, isAvailable?: boolean) => void;
  isCalculateLineTotal: boolean;
  isAvailable?: boolean;
}

export const TransactionLineItem: FunctionComponent<TransactionLineItemProps> = ({
  line,
  isAvailable = true,
  onChangeQuantity,
  onRemoveLine,
  isCalculateLineTotal,
}) => {
  const redBorderTop = css({
    borderTopWidth: '1px',
    borderTopStyle: 'solid',
    borderColor: isAvailable ? 'invisible' : 'red',
  });

  const redBorderBottom = css({
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderColor: isAvailable ? 'invisible' : 'red',
  });

  if (line && !isAvailable) {
    line.IsActive = true;
  }

  return line.IsActive ? (
    <>
      <tr className={`text-center ${redBorderTop} ${!isAvailable ? 'bg-red-200' : ''}`}>
        <td className="text-left">{line.Code}</td>
        <td className="text-left">{line.ShortDescription}</td>
        <td>{isAvailable ? `${PriceFormat(line.CostPriceEx || 0)}` : `--`}</td>
        <td>
          <input
            className="focus:bg-blue-100 my-1 text-center p-2 sm:px-0"
            type="number"
            step="1"
            value={line.Quantity || ''}
            onChange={e => onChangeQuantity(e, line)}
          ></input>
        </td>
        <td className="text-right">
          {isAvailable
            ? isCalculateLineTotal
              ? PriceFormat((line.Quantity || 0) * (line.CostPriceEx || 0))
              : PriceFormat(line.LineTotalEx || 0)
            : `--`}
        </td>
        <td className="text-right">
          <i className="cursor-pointer" title="Remove Line" onClick={() => onRemoveLine(line, isAvailable)}>
            <DeleteOutline />
          </i>
        </td>
      </tr>
      {!isAvailable && (
        <tr className={`${redBorderBottom} text-red-600 text-center bg-red-200`}>
          <td colSpan={6}>This item has been discontinued, please remove it</td>
        </tr>
      )}
    </>
  ) : (
    <></>
  );
};
